import Axios, { AxiosError, AxiosResponse } from 'axios';
import {
    ErrorResult,
    parseValidation,
} from '../../constants/interfaces/ErrorResult';
import { UrlBuilder } from '../url/UrlBuilder';
import { Http, HttpMethod } from './Http';

export interface DefaultHttpOptions {
    urlBuilder: UrlBuilder;
    clientHeader: string;
    getToken(): string | null | undefined;
    onError(error: ErrorResult): void;
}

export class DefaultHttp implements Http {
    private readonly options: DefaultHttpOptions;

    constructor(options: DefaultHttpOptions) {
        this.options = options;
    }

    request(
        method: HttpMethod,
        path: string,
        data?: any,
        responseType?: 'blob',
        isV2?: boolean,
        dataInQuery?: boolean
    ): Promise<any> {
        return Axios({
            method: method,
            responseType: responseType,
            url: this.options.urlBuilder.buildApiUrl({
                path,
                isV2,
                query: dataInQuery ? data : undefined,
            }),
            data: data,
            headers: this.generateHeaders(),
        })
            .then((response: AxiosResponse) => {
                if (responseType) {
                    return response;
                } else {
                    return response.data;
                }
            })
            .catch((error: AxiosError) => {
                var errorResult: ErrorResult;
                if (error.response) {
                    let message = '';
                    switch (error.response.status) {
                        case 401:
                        case 400:
                        case 403:
                        case 404:
                        case 409:
                        case 500:
                            message = error.response.data.message;
                            break;
                        default:
                            message = 'Unexpected Error';
                    }
                    errorResult = {
                        code: error.response.status,
                        message,
                        errorCode: error.response.data
                            ? error.response.data.code
                            : 0,
                        details: error.response.data.details,
                        validation: parseValidation(
                            error.response.data.details
                        ),
                    };
                } else if (error.request) {
                    errorResult = {
                        code: 0,
                        message: 'Check your network connection.',
                    };
                } else {
                    errorResult = {
                        code: 0,
                        message: 'Unexpected Error',
                    };
                }
                this.options.onError(errorResult);
                throw errorResult;
            });
    }

    get(
        path: string,
        data?: any,
        responseType?: 'blob',
        isV2?: boolean
    ): Promise<any> {
        return this.request('get', path, data, responseType, isV2, true);
    }

    post(path: string, data?: any, isV2?: boolean): Promise<any> {
        return this.request('post', path, data, undefined, isV2);
    }

    delete(path: string, data?: any, isV2?: boolean): Promise<any> {
        return this.request('delete', path, data, undefined, isV2);
    }

    put(path: string, data: any, isV2?: boolean): Promise<any> {
        return this.request('put', path, data, undefined, isV2);
    }

    patch(path: string, data: any, isV2?: boolean): Promise<any> {
        return this.request('patch', path, data, undefined, isV2);
    }

    private generateHeaders() {
        let contentType = { 'Content-Type': 'application/json' };
        let client = { Client: this.options.clientHeader };
        let token = this.options.getToken();
        let authorization = token ? { Authorization: 'JWT ' + token } : {};
        return {
            ...contentType,
            ...authorization,
            ...client,
        };
    }
}
