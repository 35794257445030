import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    DeletePractisSetsApiParams,
    GetPractisSetApiParams,
    SearchPractisSetsApiParams,
    UpdatePractisSetApiParams,
    GetPractisSetContentApiParams,
    UpdatePractisSetLabelsApiParams,
    CreateNewPractisSetApiParams,
    AssignPractisSetContentApiParams,
    UpdatePractisSetStatusApiParams,
} from './types';

/**
 * @function useSearchPractisSetsApi
 * @returns { SearchPractisSetsApiParams }
 */
export const useSearchPractisSetsApi = (): SearchPractisSetsApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams => http.get('practisSets', searchParams, undefined, true),
        [http]
    );
};

/**
 * @function useDeletePractisSetsApi
 * @returns { DeletePractisSetsApiParams }
 */
 export const useDeletePractisSetsApi = (): DeletePractisSetsApiParams => {
    const http = useHttp();
    return useCallback(
        (practisSetIds) =>
            http.delete('practisSets/delete', practisSetIds, true),
        [http]
    );
};

/**
 * @function useGetPractisSetApi
 * @returns { GetPractisSetApiParams }
 */
export function useGetPractisSetApi(): GetPractisSetApiParams {
    const http = useHttp();
    return useCallback(
        practisSetId =>
            http.get(`practisSets/${practisSetId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useGetPractisSetContentApi
 * @returns { GetPractisSetContentApiParams }
 */
 export function useGetPractisSetContentApi(): GetPractisSetContentApiParams {
    const http = useHttp();
    return useCallback(
        practisSetId =>
            http.get(
                `practisSets/${practisSetId}/content`,
                undefined,
                undefined,
                true
            ),
        [http]
    );
}
 
/**
 * @function useAssignPractisSetContentApi
 * @returns { AssignPractisSetContentApiParams }
 */
 export function useAssignPractisSetContentApi(): AssignPractisSetContentApiParams {
    const http = useHttp();
    return useCallback(
        (practisSetId, practisSetContentData) =>
            http.post(
                `practisSets/${practisSetId}/content`,
                practisSetContentData,
                true
            ),
        [http]
    );
}

/**
 * @function useUpdatePractisSetApi
 * @returns { UpdatePractisSetApiParams }
 */
export function useUpdatePractisSetApi(): UpdatePractisSetApiParams {
    const http = useHttp();
    return useCallback(
        (practisSetId, data) => http.put(`practisSets/${practisSetId}`, data, true),
        [http]
    );
}
/**
 * @function useAssignPractisSetLabelsApi
 * @returns { UpdatePractisSetLabelsApiParams }
 */
 export function useAssignPractisSetLabelsApi(): UpdatePractisSetLabelsApiParams {
    const http = useHttp();
    return useCallback(
        (practisSetLabels) =>
            http.post('practisSets/labels', practisSetLabels, true),
        [http]
    );
}

/**
 * @function useDeletePractisSetLabelsApi
 * @returns { UpdatePractisSetLabelsApiParams }
 */
export function useDeletePractisSetLabelsApi(): UpdatePractisSetLabelsApiParams {
    const http = useHttp();
    return useCallback(
        (practisSetLabels) =>
            http.delete('practisSets/labels', practisSetLabels, true),
        [http]
    );
}

/**
 * @function useCreateNewPractisSetApi
 * @returns { CreateNewPractisSetApiParams }
 */
 export function useCreateNewPractisSetApi(): CreateNewPractisSetApiParams {
    const http = useHttp();
    return useCallback(
        practisSet => http.post('practisSets', practisSet, true),
        [http]
    );
}

/**
 * @function useUpdatePractisSetStatusApi
 * @returns { UpdatePractisSetStatusApiParams }
 */
 export function useUpdatePractisSetStatusApi(): UpdatePractisSetStatusApiParams {
    const http = useHttp();
    return useCallback(
        (status, practisSetIds) =>
            http.put(`practisSets/${status}`, practisSetIds, true),
        [http]
    );
}