import { UserInterface } from './User';
import { Scenario } from './Scenario';
import { Challenge } from './Challenge';
import { EnrollmentStatusEnum } from './Enrollments';
import { UserInfo } from './UserInfo';

export enum PractisSetStatuses {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    DELETED = 'DELETED',
}

export type PractisSetContent = {
    id: number;
    uniqueId: string;
    type: PractisSetContentEntities;
    minRepsCount: number;
    position: number;
    cover?: {
        id: number;
        fileUrl: string;
    };
    challenge?: Challenge;
    scenario?: Scenario;
};


export type PractisSetContentEntities = 'CHALLENGE' | 'SCENARIO';
export type PractisSetStatusType =
    | PractisSetStatuses.DRAFT
    | PractisSetStatuses.ACTIVE
    | PractisSetStatuses.ARCHIVED
    | PractisSetStatuses.DELETED;

export interface PractisSets {
    id?: number;
    name: string;
    description: string;
    updatedAt?: string;
    lastTraining?: string;
    lastTrainingAt?: string;
    createdAt?: string;
    enrolledAt?: string;
    creatorId?: number;
    summary?: string;
    totalEnrollmentCount?: number;
    acceptedEnrollmentCount?: number;
    pendingEnrollmentCount?: number;
    inProgressEnrollmentCount?: number;
    completedEnrollmentCount?: number;
    completionRate?: number;
    pacing?: {
        id: number;
        type: string;
    };
    scenarioCount: number;
    challengeCount: number;
    countOfScenarios?: number;
    countOfChallenges?: number;
    challengesWithSentSubmissionCount?: number;
    currentRepsCountTotal?: number;
    minRepsCountTotal?: number;
    passedScenarioCount?: number;
    displayStatus?: EnrollmentStatusEnum;
    totalDuration: number | null;
    dueDate?: string | null;
    status: PractisSetStatusType;
    enrollmentStatus?: 'PENDING' | 'ENROLLED' | 'COMPLETED' | '';
    progressValue?: number;
    creator?: UserInterface;
    contentList?: PractisSetContent[];
    content: PractisSetContent[];
    labels?: any[];
    updatedLabels?: number[];
    checked?: boolean;
    enrollmentId?: number;
    trainingStatus?: PractisSetStatusType;
    dailyTrainings?: number;
    monthlyTrainings?: number;
    averageAccuracy?: number;
    completedAt?: string;
    trainingTime?: number;
    minutesPerDay?: number;
    totalTimeInMinutes?: number;
    lastActivity?: any;
    hasOverdue?: boolean;
}

export interface PractisSetV2 {
    id: number;
    name: string;
    description: string;
    status: string;
    companyId: number;
    scenarioCount: number;
    challengeCount: number;
    minRepsCountTotal: number;
    totalDuration: number;
    createdAt: string;
    updatedAt: string;
    labels: number[];
    creator: UserInfo;
    cover: {
        id: number;
        fileUrl: string;
    };
    pacing: {
        id: number;
        type: string;
    };
}

export interface EnrollmentPractisSet extends PractisSetV2 {
    enrollmentId: number;
    dueDate: string;
}