import React, { FC, useEffect, useRef, useState } from 'react';
import {
    practisSetEntityTypeColor,
    practisSetEntityTypeName,
    PractisSetEntityTypes,
} from '../../../../../tools';
import secondsToMin from '../../../../../../../../helpers/functions/convert-time';
import {
    PractisSetContent,
    PractisSetContentEntities,
} from '../../../../../../../../constants/interfaces/PractisSets';
import styled from 'styled-components';
import {
    usePractisContentRepCountDecrease,
    usePractisContentRepCountIncrease,
} from '../../../../../store/services';
import PreviewItem from './PreviewItem/PreviewItem';
import OutsideActionBox from '../../../../../../../../ui/components/OutsideActionBox/OutsideActionBox';
import Trash from '../../../../../../../../ui/icons/Trash';

const StyledBuilderListItem = styled.div<{
    disabled?: boolean;
    isDragging: boolean;
}>`
    background: ${props => props.theme.Colors.white};
    border-radius: 4px;
    padding: 14px 0 14px 32px;
    display: flex;
    margin-bottom: 16px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
`;

const StyledRemoveSection = styled.div`
    margin: 0 24px 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledRemoveIcon = styled.div<{ disabled?: boolean }>`
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    width: 15px;
    height: 16px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &:hover {
        color: ${props =>
            props.disabled
                ? props.theme.Colors.cloudyBlue
                : props.theme.Colors.paleRed};
    }
`;

const InfoSection = styled.div`
    padding: 8px 0;
    flex: 1;
`;

const EntityName = styled.div<{ color: string }>`
    font-size: 11px;
    font-weight: 600;
    color: ${props => props.color};
`;

const EntityItemTitle = styled.div`
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.Colors.black};
    margin-bottom: 4px;
    word-break: break-all;
`;

const EntityItemActions = styled.div`
    display: flex;
`;

const EntityItemDuration = styled.div`
    font-size: 13px;
    font-weight: normal;
    color: ${props => props.theme.Colors.steelGrey};
    margin-right: 8px;
`;

const PreviewButton = styled.div`
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.theme.Colors.darkSkyBlue};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
`;

const SectionDivider = styled.div`
    width: 1px;
    margin: 0 16px;
    background: ${props => props.theme.Colors.paleGrey};
`;

const RepsSection = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const RepsTitle = styled.div<{ disabled?: boolean }>`
    font-size: 11px;
    font-weight: 600;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    margin-bottom: 8px;
`;

const RepsCounter = styled.div`
    display: flex;
    height: 24px;
    justify-content: space-evenly;
    text-align: center;
`;

const RepsCount = styled.div<{ disabled?: boolean }>`
    font-size: 17px;
    font-weight: bold;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.black};
`;

const SetCountContainer = styled.div<{ disabled?: boolean }>`
    width: 24px;
    height: 24px;
    font-size: 15px;
    font-weight: bold;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
    user-select: none;
    &:active {
        opacity: ${props => (props.disabled ? 1 : 0.7)};
    }
`;

const BuilderListItem: FC<{
    item: PractisSetContent;
    provided: any;
    snapshot: any;
    onRemove(id: number | string, type: PractisSetContentEntities): void;
    disabled?: boolean;
    showPreview: any;
    setShowPreview(show: any): void;
    dataTest: string;
}> = ({
    item,
    provided,
    snapshot,
    onRemove,
    disabled,
    showPreview,
    setShowPreview,
    dataTest
}) => {
    const increaseContentMinRepCount = usePractisContentRepCountIncrease();
    const decreaseContentMinRepCount = usePractisContentRepCountDecrease();
    const isScenario = item.type === PractisSetEntityTypes.SCENARIO;
    const itemId = isScenario ? item.scenario!.id : item.challenge!.id;
    const content = isScenario ? item.scenario : item.challenge;
    const itemRef = useRef<any>(null);
    const [position, setPosition] = useState({ y: 0, x: 0 });

    useEffect(() => {
        if (itemRef && itemRef.current) {
            const itemPositions = itemRef.current.getBoundingClientRect();
            setPosition({ y: itemPositions.y, x: itemPositions.x });
        }
    }, [setPosition, snapshot.isDragging]);

    const decreaseContentDisabled = isScenario
        ? !item.minRepsCount || item.minRepsCount < 2
        : true;
    const dataTestPrefix = `${dataTest}-${item.type?.toLowerCase()}`;

    return (
        <StyledBuilderListItem
            provided={provided}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            disabled={disabled}
            isDragging={snapshot.isDragging}
            onMouseDown={() => {
                setShowPreview(null);
            }}
        >
            <InfoSection
                ref={itemRef}
                onDoubleClick={() =>
                    !disabled && onRemove(item?.uniqueId!, item.type!)
                }
            >
                <EntityName
                    color={practisSetEntityTypeColor(item.type)}
                    data-test={`${dataTestPrefix}-label`}
                >
                    {practisSetEntityTypeName(item.type)}
                </EntityName>
                <EntityItemTitle data-test={`${dataTestPrefix}-title`}>
                    {content?.title}
                </EntityItemTitle>
                <EntityItemActions>
                    {isScenario && (
                        <EntityItemDuration
                            data-test={`${dataTestPrefix}-duration`}
                        >
                            {item.scenario?.script?.totalDuration
                                ? secondsToMin(
                                    content?.script?.totalDuration
                                  )
                                : '-'}
                        </EntityItemDuration>
                    )}
                    <PreviewButton
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            setShowPreview(itemId);
                        }}
                        data-test={`${dataTestPrefix}-preview`}
                    >
                        Preview
                    </PreviewButton>
                </EntityItemActions>
            </InfoSection>
            {isScenario && (
                <>
                    <SectionDivider />
                    <RepsSection>
                        <RepsTitle
                            disabled={disabled}
                            data-test={`${dataTestPrefix}-min-reps-label`}
                        >
                            Minimum Reps
                        </RepsTitle>
                        <RepsCounter>
                            <SetCountContainer
                                disabled={decreaseContentDisabled || disabled}
                                onClick={() =>
                                    itemId &&
                                    !decreaseContentDisabled &&
                                    !disabled &&
                                    decreaseContentMinRepCount(itemId)
                                }
                                data-test={`${dataTestPrefix}-min-reps-minus`}
                            >
                                -
                            </SetCountContainer>
                            <RepsCount
                                disabled={disabled}
                                data-test={`${dataTestPrefix}-min-reps-value`}
                            >
                                {item.minRepsCount}
                            </RepsCount>
                            <SetCountContainer
                                disabled={disabled}
                                onClick={() =>
                                    itemId &&
                                    !disabled &&
                                    increaseContentMinRepCount(itemId)
                                }
                                data-test={`${dataTestPrefix}-min-reps-plus`}
                            >
                                +
                            </SetCountContainer>
                        </RepsCounter>
                    </RepsSection>
                </>
            )}
            <SectionDivider />
            <StyledRemoveSection>
                <StyledRemoveIcon
                    onClick={() => {
                        !disabled && onRemove(item?.uniqueId!, item.type!);
                    }}
                    disabled={disabled}
                    data-test={`${dataTestPrefix}-delete`}
                >
                    <Trash />
                </StyledRemoveIcon>
            </StyledRemoveSection>
            <OutsideActionBox
                open={showPreview === itemId}
                toggleOpen={setShowPreview}
            >
                <PreviewItem
                    item={item}
                    position={position}
                    handleClose={() => setShowPreview(null)}
                />
            </OutsideActionBox>
        </StyledBuilderListItem>
    );
};

export default BuilderListItem;