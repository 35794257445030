import React, { memo, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

import { Variables } from '../../../theme/variables';
import Logo from '../../components/Logo/Logo';
import { SideBarItem } from './SideBarItem';
import { OPEN_SIDEBAR_WIDTH } from '../../wrapper/configs';
import CreateLabelsContainer from '../../../features/labels/pages/CreateLabels';
import { useLabelsState } from '../../../features/labels/store/states';
import {
    useCloseLabelCreationPanelService,
    useExpandMultipleLabelsService,
    useOpenLabelCreationPanelService,
} from '../../../features/labels/store/services';
import {
    getProfileState,
    getProfileLoading,
} from '../../../pages/UserProfile/store/reducers';
import {
    useGenerateLabelsConfiguration,
    useGetSavedLabelExpandedState,
    useGetSavedLabelPanelState,
} from '../../../features/labels/config';
import { CheckPermission } from '../../../features/permissions';
import ProfileSectionContainer from './components/ProfileSection/ProfileSection';
import {
    StyledSidebar,
    SidebarBody,
    SidebarLabels,
    ClosedLabels,
    OpenLabels,
    OpenLabelsContent,
    OpenLabelsFooter,
    StyledLabelsIcon,
    StyledRightArrow,
    OpenLabelsButton,
    SidebarContent,
    SidebarMenu,
    SidebarProfile,
    ItemLoading,
    ItemLoadingIcon,
    ItemLoadingText,
} from './styles';
import { PractisSetSupport } from './PractisSupport';
import { SideBarItemProps } from './types';
import { isAdminPortal } from '../../../helpers/functions/general';

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        position: 'unset',
        overflowX: 'hidden',
        overflowY: 'overlay' as 'scroll', // TS issue
        zIndex: 1000,
        whiteSpace: 'nowrap',
        width: OPEN_SIDEBAR_WIDTH,
        background: Variables.Colors.dark,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    logoContainer: {
        marginTop: 48,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
}));

export type SideBarMenuItem = SideBarItemProps;

export const Sidebar: React.FC<{
    menuItems: SideBarMenuItem[];
    fontSize?: string;
}> = memo(
    ({ menuItems, fontSize }) => {
        const classes = useStyles();
        const labels = useLabelsState();
        const open = !!labels && labels.open;
        const openLabelsPanel = useOpenLabelCreationPanelService();
        const closeLabelsPanel = useCloseLabelCreationPanelService();
        const expandMultipleLabels = useExpandMultipleLabelsService();
        const profile = useSelector(getProfileState);
        const profileLoading = useSelector(getProfileLoading);
        const generateLabelsConfiguration = useGenerateLabelsConfiguration();
        const getLabelsPanelState = useGetSavedLabelPanelState();
        const getLabelsExpandedState = useGetSavedLabelExpandedState();
        const isItAdminPortal = isAdminPortal();
 
        useLayoutEffect(() => {
            const expandedLabels = getLabelsExpandedState();
            if (expandedLabels && expandedLabels.length > 0) {
                expandMultipleLabels(expandedLabels);
            }
        }, [getLabelsExpandedState, expandMultipleLabels]);

        useLayoutEffect(() => {
            if (getLabelsPanelState()) {
                openLabelsPanel();
            }
        }, [getLabelsPanelState, openLabelsPanel]);

        useEffect(() => {
            if (labels && profile && profile.id) {
                generateLabelsConfiguration(labels.open, labels.collapseLabels);
            }
        }, [labels, profile, generateLabelsConfiguration]);

        return (
            <StyledSidebar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.logoContainer}>
                        <Logo
                            width={isItAdminPortal ? 110 : 92}
                            height={isItAdminPortal ? 36 : 25}
                        />
                    </div>
                    <SidebarBody>
                        <SidebarContent>
                            <SidebarMenu>
                                <div>
                                    {menuItems.map((item, index) => (
                                        <>
                                            {profileLoading ? (
                                                <ItemLoading isOpen={open}>
                                                    <ItemLoadingIcon />
                                                    {!open && (
                                                        <ItemLoadingText />
                                                    )}
                                                </ItemLoading>
                                            ) : (
                                                <CheckPermission
                                                    key={index}
                                                    permissions={
                                                        item.permissions
                                                    }
                                                >
                                                    <SideBarItem
                                                        {...item}
                                                        shrink={open}
                                                        fontSize={fontSize}
                                                    />
                                                </CheckPermission>
                                            )}
                                        </>
                                    ))}
                                </div>
                                {!isItAdminPortal && (
                                    <PractisSetSupport shrink={open} />
                                )}
                            </SidebarMenu>
                            <SidebarProfile>
                                <ProfileSectionContainer sideBarOpen={open} />
                            </SidebarProfile>
                        </SidebarContent>
                        {labels && (
                            <SidebarLabels isOpen={open}>
                                {!open ? (
                                    <ClosedLabels>
                                        <OpenLabelsButton
                                            onClick={() => openLabelsPanel()}
                                            data-test="sidebar-labels-expand"
                                        >
                                            {profileLoading ? (
                                                <ItemLoadingIcon />
                                            ) : (
                                                <StyledLabelsIcon />
                                            )}
                                        </OpenLabelsButton>
                                    </ClosedLabels>
                                ) : (
                                    <OpenLabels>
                                        <OpenLabelsFooter>
                                            <OpenLabelsButton
                                                onClick={() =>
                                                    closeLabelsPanel()
                                                }
                                                data-test="sidebar-labels-collapse"
                                            >
                                                <StyledRightArrow />
                                            </OpenLabelsButton>
                                        </OpenLabelsFooter>
                                        <OpenLabelsContent>
                                            <CreateLabelsContainer />
                                        </OpenLabelsContent>
                                    </OpenLabels>
                                )}
                            </SidebarLabels>
                        )}
                    </SidebarBody>
                </Drawer>
            </StyledSidebar>
        );
    },
    (prevProps, nextProps) =>
        isEqual(
            prevProps as Record<string, any>,
            nextProps as Record<string, any>
        )
);

export default Sidebar;