import React, { FC } from 'react';
import styled from 'styled-components';
import { VideoPlayer } from '../../../ui/components/VideoPlayer/VideoPlayer';
import DetailsHeader from './DetailsHeader';
import { UserInterface } from '../../../constants/interfaces/User';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';

const StyledDetailsSubmissionInfo = styled.div`
    margin: 8px 10px;
    flex: 20;
    max-width: 416px;
    min-width: 416px;
    margin-bottom: 25px;
 
    display: flex;
    flex-direction: column;

    .video{
        min-height: 421px;
       

        .player-background{
            min-height: 421px;
        }
    }
`;

const StyledInfoSection = styled.div`
    padding: 24px 32px 0 32px;
    margin-top: -5px;
    background-color: ${props => props.theme.Colors.white};
    min-height: 168px;
    border-radius: 0 0 8px 8px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border-top: none;
    z-index: 1;

    @media (max-width: 1440px){
        min-height: 167px;
    }
`;

const Header = styled.div`
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.tomato};
`;

const Title = styled.div`
    color: ${props => props.theme.Colors.black};
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 5px;
    word-break: break-word;

    &:active {
        opacity: 0.7;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

const Description = styled.div`
    color: ${props => props.theme.Colors.black};
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-bottom: 20px;

    &:active {
        opacity: 0.7;
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DetailsSubmissionInfo: FC<{
    url?: string | null;
    challenge: { id: number; title: string; description: string; };
    redirectToChallenge: (challengeId?: number | string) => void;
    user?: UserInterface;
    action: (traineeId?: number) => void;
    handleDownloadVideo: () => void;
    videoLoading?: boolean;
    handleCopyLink: () => void;
}> = ({
    url,
    challenge,
    redirectToChallenge,
    user,
    action,
    handleDownloadVideo,
    videoLoading,
    handleCopyLink,
}) => {
    const profile = useSelector(getProfileState);

    return (
        <StyledDetailsSubmissionInfo>
            <DetailsHeader user={user} profile={profile} action={action} />
            <VideoPlayer
                url={url ? url : 'unknown'}
                onDownloadVideo={handleDownloadVideo}
                videoLoading={videoLoading}
                className='video'
                onCopyLink={handleCopyLink}
            />
            <StyledInfoSection>
                <Row>
                    <Header>Challenge</Header>
                </Row>
                <Row>
                    <Title
                        onClick={() =>
                            challenge.id && redirectToChallenge(challenge.id)
                        }
                    >
                        {challenge?.title}
                    </Title>
                </Row>
                <Row>
                    <Description
                        onClick={() =>
                            challenge.id && redirectToChallenge(challenge.id)
                        }
                    >
                        {challenge?.description}
                    </Description>
                </Row>
            </StyledInfoSection>
        </StyledDetailsSubmissionInfo>
    );
};

export default DetailsSubmissionInfo;
