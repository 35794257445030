import styled from 'styled-components';

export const Title = styled.div`
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--ps-white-1);
    width: 100%;
    text-align: center;
`;

export const BackArrow = styled.img`
    position: absolute;
    top: 7px;
    left: 0;
    cursor: pointer;
    width: 16px;
    height: 14px;
`;

export const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    text-align: center;
    padding-top: 4px;
`;

export const FormContainer = styled.div`
    width: 100%;
`;

export const FieldContainer = styled.div`
    min-height: 78px;
    margin-top: 12px;
    margin-bottom: 16px;

    .login-input-container + div {
        line-height: 18px;
    }
`;
