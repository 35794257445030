import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
    selectYear,
    selectMonth,
    clearYearAndMonth,
    disableMonthFilter
} from './actions';

export const useSelectYear = () => {
    const dispatch = useDispatch();

    return useCallback(
        (year: number) => {
            dispatch(selectYear(year));
        },
        [dispatch]
    );
};

export const useSelectMonth = () => {
    const dispatch = useDispatch();

    return useCallback(
        (year: number, month: number) => {
            dispatch(selectMonth(year, month));
        },
        [dispatch]
    );
};

export const useClearYearAndMonth = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(clearYearAndMonth());
    }, [dispatch]);
};

export const useDisableMonthFilter = () => {
    const dispatch = useDispatch();

    return useCallback((isDisabled: boolean) => {
        dispatch(disableMonthFilter(isDisabled));
    }, [dispatch]);
}