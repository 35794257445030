import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 24px 24px 24px;

    .sales-method-select {
        border: 0px;

        .sales-method-select-input {
            width: calc(100% - 10px);
            div {
                color: var(--ps-black-1);
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .content-area {
            padding: 0px
        }
        .selected-item, .content-area > div  {
            padding: 8px;
            margin: 0px;
            border-radius: 0px;
            display: inline-block;
            font-size: 15px;
            width: 100%;

            .bold-text {
                color: var(--ps-grey-1);

                b {
                    color: var(--ps-black-1)
                }
            }
        }

        .selected-item, .content-area > div:hover {
            background: var(--ps-blue-main);

            .bold-text {
                color: var(--ps-white-1) !important;
                b {
                    color: var(--ps-white-1) !important;
                }

            }
        }
    }
`;

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
`;

export const TitleContainer = styled.div`
    display: flex;
`;
