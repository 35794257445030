import { useDispatch } from 'react-redux'
import { useCallback } from 'react';
import { useGetSubmissionRepLines } from '../../../../../../api/submissions';
import { useShowMessage } from '../../../../../../ui/components/ErrorMessages/ErrorMessages';
import { ListResult } from '../../../../../../constants/interfaces/PaginationResult';
import { getScenarioRepsStart, getScenarioRepsFailure, getScenarioRepsSuccess, clearScenarioReps } from './actions';
import { ErrorResult } from '../../../../../../constants/interfaces/ErrorResult';
import { SearchParams } from '../../../../../../constants/interfaces/filters';
import { RepLineType } from '../types';

/**
 * @function useGetScenarioRepsService
 * @returns { CallableFunction }
 */
export const useGetScenarioRepsService = () => {
    const dispatch = useDispatch();
    const getScenarioRepsApi = useGetSubmissionRepLines();
    const showMessage = useShowMessage();
    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback((searchParams: SearchParams, enrollmentId: string, scenarioId: string) => {
        const sp = {
            limit: searchParams.limit ?? 20,
            offset: searchParams.offset,
            enrollmentId: +enrollmentId,
            scenarioId: +scenarioId
        }
        dispatch(getScenarioRepsStart());
        return getScenarioRepsApi(sp)
            .then((data: ListResult<RepLineType>) => {
                dispatch(getScenarioRepsSuccess(data));
            })
            .catch((error: ErrorResult) => {
                dispatch(getScenarioRepsFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, getScenarioRepsApi, showMessage]);
}

/**
 * @function useClearScenarionRepsService
 * @returns { CallableFunction }
 */
export const useClearScenarionRepsService = () => {
    const dispatch = useDispatch();
    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback(() => {
        dispatch(clearScenarioReps());
    }, [dispatch]);
}