import { UserInterface } from './User';
import { Script } from './Script';
import { Submission } from './Reviews';

export enum ChallengeStatuses {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    DELETED = 'DELETED',
}

export type Status =
    | ChallengeStatuses.DRAFT
    | ChallengeStatuses.ACTIVE
    | ChallengeStatuses.ARCHIVED
    | ChallengeStatuses.DELETED;

export interface Challenge {
    uniqueId?: string;
    id?: number;
    sourceScenarioId?: number | null,
    type?: 'CHALLENGE';
    title: string;
    description: string;
    instructions: string;
    createdAt?: string;
    updatedAt?: string;
    companyId?: string;
    creatorId?: number;
    submissions?: Submission[];
    status: Status;
    scriptId?: number;
    script: Script;
    creator?: UserInterface;
    checked?: boolean;
    minRepsCount?: number | null;
    labels?: any[];
    updatedLabels?: number[];
    tryLimit: number | null;
}

export interface ChallengeSubmission {
    id?: number;
    reviewScore?: number;
    reviewedAt?: string;
    submissionId?: number;
    title?: string;
}