import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ObjectiveIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (<svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#iyfsdcj2ra)" fill="#6D7F8C">
        <path d="M7.678 7.438a1.876 1.876 0 0 0-2.681 1.69c0 1.034.84 1.875 1.874 1.875a1.876 1.876 0 0 0 1.69-2.681l2.004-2.004h2.499L16 3.382l-2.875-.507L12.618 0 9.682 2.936v2.499L7.678 7.438zm-.807 2.316a.625.625 0 1 1 .001-1.251.625.625 0 0 1-.001 1.25zm4.06-6.3.874-.874.242 1.373 1.373.242-.874.874h-1.615V3.454z"/>
        <path d="M12.274 7.567A5.628 5.628 0 0 1 6.87 14.75 5.626 5.626 0 0 1 1.249 9.13a5.628 5.628 0 0 1 7.183-5.402v-1.29A6.879 6.879 0 0 0 0 9.128a6.874 6.874 0 0 0 6.87 6.87 6.879 6.879 0 0 0 6.872-6.87 6.84 6.84 0 0 0-.182-1.562h-1.286z"/>
        <path d="M6.87 6.005c.15 0 .298.011.442.032L8.34 5.01a4.377 4.377 0 0 0-5.84 4.118 4.377 4.377 0 0 0 4.371 4.373A4.377 4.377 0 0 0 10.99 7.66L9.962 8.687a3.127 3.127 0 0 1-3.092 3.565 3.127 3.127 0 0 1-3.123-3.124A3.127 3.127 0 0 1 6.87 6.005z"/>
    </g>
    <defs>
        <clipPath id="iyfsdcj2ra">
            <path fill="#fff" d="M0 0h16v16H0z"/>
        </clipPath>
    </defs>
</svg> );
    }
);

export default ObjectiveIcon;