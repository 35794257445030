import React, { FC } from 'react';
import styled from 'styled-components';
import { ScriptLineSpeaker } from '../../../../../../../../constants/interfaces/ScriptLine';
import LinkText from '../../../../../../../../ui/components/LinkText/link-text';

const StyledEmptyScriptContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
`;

const ScriptAction: FC<{
    handleAdd: any;
}> = ({ handleAdd }) => {
    return (
        <StyledEmptyScriptContainer>
            <LinkText onClick={() => handleAdd(ScriptLineSpeaker.CUSTOMER)} dataTest='add-scenario-customer-line'>
                + Add a customer line
            </LinkText>
            <LinkText onClick={() => handleAdd(ScriptLineSpeaker.REP)} dataTest='add-scenario-rep-line'>
                Add a rep line +
            </LinkText>
        </StyledEmptyScriptContainer>
    );
};

export default ScriptAction;
