import { FC, memo } from 'react';

import ArrowRightIcon from '../../icons/ArrowRight';
import { IconHolder, StyledButton } from './style';
import { ButtonArrowProps } from './type';

export const ButtonArrow: FC<ButtonArrowProps> = ({
    action,
    disabled,
    loading,
    className,
    left,
}) => {
    return (
        <StyledButton
            disabled={disabled || loading}
            onClick={action}
            className={className}
        >
            <IconHolder scaleX={left ? -1 : 1}>
                <ArrowRightIcon />
            </IconHolder>
        </StyledButton>
    );
};

export default memo(ButtonArrow);
