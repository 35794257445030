import styled from 'styled-components';
import NoSearchResult from '../../../../ui/icons/NoSearchResult';

export const Container = styled.div`
    margin-top: 1px;
    padding: 5px 24px;
    max-height: 270px;
    overflow: auto;
`;

export const SkeletonContainer = styled.div`
    padding-top: 13px;
`;

export const EmptyStateContainer = styled.div`
    padding-top: 13px;
`;

export const StyledNoSearchResultIcon = styled(NoSearchResult)`
    width: 32px !important;
    font-size: 32px !important;
    margin: 0 !important;
`;