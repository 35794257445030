import React from 'react';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const WebLandingPage = () => {
    return (
        <>
            <LeftPanel />
            <RightPanel />
        </>
    );
};

export default WebLandingPage;
