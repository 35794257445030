import { useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { isEqual } from 'lodash';

import './assets/styles/fonts.css';
import ErrorMessages from './ui/components/ErrorMessages/ErrorMessages';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Login from './pages/Auth/Login/Login';
import LogOut from './pages/Auth/LogOut/LogOut';
import { Register } from './pages/Auth/Register';
import { Registration } from './pages/Auth/Registration';
import ResetPassword from './pages/Auth/ResetPassword';
import HealthPage from './pages/Health/Health';
import UserProfile from './pages/UserProfile/UserProfile';
import ROUTES from './routes/routes';
import PrivateRoute from './routes/PrivateRouter';
import NotFoundPage from './pages/NotFoud/NotFound';
import LandingPage from './pages/LandingPage/LandingPage';
import Progress from './features/teams/pages/Training/Progress';
import { useLocations } from './tools/router';
import NewUserContainer from './pages/ActionPages/NewUser/pages/NewUser';
import NewScenario from './pages/ActionPages/NewScenario/NewScenario';
import PractisSets from './pages/ActionPages/PractisSets/PractisSets';
import Library from './features/library/pages/Library';
import TermsPage from './pages/Terms/Terms';
import PrivacyPage from './pages/Privacy/Privacy';
import SupportPage from './pages/Support/Support';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileState } from './pages/UserProfile/store/reducers';
import InvitationsContainer from './features/users/pages/Invitations/Invitations';
import UsersContainer from './features/users/pages/Users/Users';
import EditChallenge from './features/challenges/pages/EditChallenge';
import InjectNewRelic from './features/newRelic/index';
import ChallengeSubmissions from './features/submissions/pages/ChallengeSubmissions';
import AccuracyTests from './features/submissions/pages/AccuracyTests'
import Submissions from './features/submissions/pages/Submissions';
import SubmissionDetails from './features/submissions/pages/SubmissionDetails';
import TraineeLogin from './pages/Auth/TraineeLogin/TraineeLogin';
import PerformanceContainer from './features/users/pages/Performance/Performance';
import PublicRoute from './routes/PublicRouter';
import CompanyLogsContainer from './features/logs/pages/Logs';
import MobileNumberLogin from './pages/Auth/MobileNumberLogin/MobileNumberLogin';
import VerifyMobileNumber from './pages/Auth/VerifyMobileNumber';
import Telepromter from './features/telepromter/pages';
import DraftsContainer from './features/users/pages/Drafts/Drafts';
import CompanyAiAssessmentContainer from './features/assessment-logs/pages/AiAssessment';
import TeamsContainer from './features/teams/pages/ListOfTeams/ListOfTeams';
import { OverdueLearners } from './features/teams/pages/OverdueLearners';
import ManageTeamContainer from './features/teams/pages/ManageTeam/ManageTeam';
import CreateTeam from './features/teams/pages/CreateTeam';
import PractisSetReportContainer from './features/teams/pages/Training/PractisSetReport';
import { ScenarioRepsContainer } from './features/teams/pages/Training/ScenarioReps';
import TraineeQuickAssignmentContainer from './features/teams/pages/Training/TraineeQuickAssignment';
import TraineeInvitationContainer from './features/teams/pages/Training/TraineeInvitation';
import { PlaybackScreen } from './ui/components/PlaybackVideo';
import ProgressPage from './features/socketProgress(api1)/progresspage';
import { getSearchState } from './features/searchState/store/reducer';
import { deleteSearchState, setSecondarySearchState } from './features/searchState/store/actions';
import { checkChildrenKeys } from './features/searchState/helpers';
import { SEARCH_STATE } from './features/searchState/constants';
import { deleteSecondarySearchState } from './features/searchState/store/actions';
import { generateColorVariables } from './ui/variables/generate-variables';
import { useRedirectToPhoneVerification } from './helpers/hooks/useRedirectToPhoneVerification';
import Onboarding from './pages/Auth/Onboarding';
import InvalidInvite from './pages/Auth/InvalidInvite';
import AddMobileNumber from './pages/Auth/AddMobileNumber';
import TraineeSplash from './pages/Auth/TraineeSplash';
import BrokenSharedLink from './ui/components/BrokenSharedLink';
import { TeamsPage } from './features/teams/pages';
import { ModalDialogs } from './ui/components/ModalDialogs';
import OldAccuracyTest from './pages/OldSubmission/OldAccuracyTest';
import OldChallenge from './pages/OldSubmission/OldChallenge';
import Storage from './services/Storage';
import { storageKeys } from './constants/variables/general';
import { PractisWebMode } from './constants/enums';
import Reports from './pages/Reports';
import Roleplays from './pages/Roleplays/RoleplaysList';
import { PractisSetSummaryReport } from './pages/Reports/PractisSetSummaryReport';
import { UserActivityReport } from './pages/Reports/UserActivityReport';
import { TeamLeaderActivityReport } from './pages/Reports/TeamLeaderActivityReport';
import { BillingReport } from './pages/Reports/BillingReport';
import { CompanySettings } from './pages/CompanySettings';
import { TrainingTimeReport } from './pages/Reports/TrainingTimeReport';
import RoleplayCreate from './pages/Roleplays/CreateRoleplay';
import RoleplayPage from './pages/Roleplays/RoleplayPage';

const App: React.FC = () => {
    let { rootLocation, modalLocation } = useLocations();
    const user = useSelector(getProfileState, isEqual);
    const searchState = useSelector(getSearchState, isEqual);
    const location = useLocation();
    const dispatch = useDispatch();
    const redirectToPhoneVerification = useRedirectToPhoneVerification();
    const mobileDeepLinkRoutes = Object.keys(ROUTES.MOBILE_DEEP_LINKS);

    useEffect(() => {
        if (
            searchState.key === SEARCH_STATE.TEAMS.name ||
            searchState.key === SEARCH_STATE.TRAINING.name
        ) {
            const keyName =
                searchState.key === SEARCH_STATE.TEAMS.name
                    ? SEARCH_STATE.TEAMS.name
                    : SEARCH_STATE.TRAINING.name;
            dispatch(setSecondarySearchState(keyName, searchState.params));
        }

        if (
            !location.pathname.includes(SEARCH_STATE.MEMBERS.name) &&
            !location.pathname.includes(SEARCH_STATE.TEAMS.name)
        ) {
            dispatch(deleteSecondarySearchState());
        }

        if (!checkChildrenKeys(location.pathname, searchState?.childrenKeys)) {
            dispatch(deleteSearchState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location.pathname]);

    useEffect(() => {
        generateColorVariables();
    }, [])

    useEffect(() => {
        if (user?.id && !user?.phoneNumberVerified) {
            redirectToPhoneVerification({ user }, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, redirectToPhoneVerification])


    useEffect(() => {
        Storage.set(storageKeys.PRACTIS_WEB, PractisWebMode['WEB_PORTAL']);
    }, []);
    
    return (
        <div>
            <ProgressPage />
            <InjectNewRelic />
            <Switch location={rootLocation}>
                <PublicRoute
                    path={ROUTES.AUTH_PAGES.LOGIN}
                    exact
                    component={Login}
                />
                <PublicRoute
                    path={ROUTES.AUTH_PAGES.MOBILE_NUMBER_LOGIN}
                    exact
                    component={MobileNumberLogin}
                />
                <PublicRoute
                    path={ROUTES.AUTH_PAGES.MOBILE_NUMBER_VERIFY}
                    exact
                    component={VerifyMobileNumber}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.TRAINEE_LOGIN}
                    exact
                    component={TraineeLogin}
                />
                <Route path={ROUTES.AUTH_PAGES.LOGOUT} component={LogOut} />
                <Route path={ROUTES.AUTH_PAGES.REGISTER} component={Register} />
                <Route
                    path={ROUTES.AUTH_PAGES.REGISTRATION}
                    component={Registration}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.FORGOT_PASSWORD}
                    exact
                    component={ForgotPassword}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.RESET_PASSWORD}
                    exact
                    component={ResetPassword}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.ONBOARDING}
                    component={Onboarding}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.INVALID_INVITE}
                    component={InvalidInvite}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.ADD_MOBILE_NUMBER}
                    component={AddMobileNumber}
                />
                <Route
                    path={ROUTES.AUTH_PAGES.TRAINEE_SPLASH}
                    component={TraineeSplash}
                />
                <Route path={ROUTES.LANDING} exact component={LandingPage} />
                <Route path={ROUTES.SUPPORT} exact component={SupportPage} />
                <Route path={ROUTES.TERMS} exact component={TermsPage} />
                <Route path={ROUTES.PRIVACY} exact component={PrivacyPage} />
                <Route path={ROUTES.Health} exact component={HealthPage} />
                <Route
                    path={ROUTES.BROKEN_SHARED_LINK}
                    component={BrokenSharedLink}
                />
                <PrivateRoute path={ROUTES.PROGRESS} component={Progress} />
                <PrivateRoute path={ROUTES.PROFILE} component={UserProfile} />
                <PrivateRoute
                    path={ROUTES.AUTH_PAGES.VERIFY_MOBILE_NUMBER_PRIVATE}
                    exact
                    component={VerifyMobileNumber}
                />
                <PrivateRoute
                    path={ROUTES.ORGANIZATION_LOGS}
                    component={CompanyLogsContainer}
                />
                <PrivateRoute
                    path={ROUTES.ORGANIZATION_ASSESSMENT_LOGS}
                    component={CompanyAiAssessmentContainer}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSIONS}
                    exact
                    component={Submissions}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.ALL}
                    exact
                    component={AccuracyTests}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.SINGLE}
                    exact
                    component={PlaybackScreen}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.OLD_SINGLE}
                    exact
                    component={OldAccuracyTest}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL}
                    exact
                    component={ChallengeSubmissions}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.SINGLE}
                    exact
                    component={SubmissionDetails}
                />
                <PrivateRoute
                    path={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.OLD_SINGLE}
                    exact
                    component={OldChallenge}
                />
                <PrivateRoute path={ROUTES.LIBRARY} component={Library} />
                <PrivateRoute
                    path={ROUTES.TELEPROMTER}
                    component={Telepromter}
                />
                <PrivateRoute
                    path={ROUTES.USERS}
                    exact
                    component={UsersContainer}
                />
                <PrivateRoute
                    path={ROUTES.TRAINEE_DETAILS}
                >
                    <PractisSetReportContainer />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.USER_PERFORMANCE}
                    component={PerformanceContainer}
                />
                <PrivateRoute
                    path={ROUTES.INVITATION_PERFORMANCE}
                    exact
                    component={PerformanceContainer}
                    propsData={{ type: 'invitation' }}
                />
                <PrivateRoute
                    path={ROUTES.USER_INVITATIONS}
                    component={InvitationsContainer}
                />
                <PrivateRoute
                    path={ROUTES.DRAFTS}
                    component={DraftsContainer}
                />
                <PrivateRoute path={ROUTES.TEAMS_PAGES.LIST_OF_TEAMS} exact>
                    <TeamsContainer />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.TEAMS_PAGES.MEMBERS} exact>
                    <TeamsPage />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.TEAMS_PAGES.TRAINING}
                    component={TeamsPage}
                />
                <PrivateRoute
                    path={ROUTES.OVERDUE_LEARNERS}
                >
                    <OverdueLearners />
                </PrivateRoute>
                {mobileDeepLinkRoutes.map(key =>
                    <PrivateRoute
                        path={ROUTES.MOBILE_DEEP_LINKS[key as keyof typeof ROUTES.MOBILE_DEEP_LINKS]}
                    >
                        <Redirect to={{ pathname: ROUTES.SUBMISSIONS }} />
                    </PrivateRoute>
                )}
                <PrivateRoute
                    path={ROUTES.REPORTS.LIST_OF_REPORTS}
                    exact
                    component={Reports}
                />
                <PrivateRoute
                    path={ROUTES.ROLEPLAYS.LIST_OF_ROLEPLAYS}
                    exact
                    component={Roleplays}
                />
                <PrivateRoute
                    path={ROUTES.ROLEPLAYS.SINGLE}
                    exact
                    component={RoleplayPage}
                />
                <PrivateRoute
                    path={ROUTES.REPORTS.PRACTIS_SET_SUMMARY_REPORT}
                    component={PractisSetSummaryReport}
                />
                <PrivateRoute
                    path={ROUTES.REPORTS.USER_ACTIVITY_REPORT}
                    component={UserActivityReport}
                />
                <PrivateRoute
                    path={ROUTES.REPORTS.TEAM_LEADER_ACTIVITY_REPORT}
                    component={TeamLeaderActivityReport}
                />
                <PrivateRoute
                    path={ROUTES.REPORTS.BILLING_REPORT}
                    component={BillingReport}
                />
                <PrivateRoute
                    path={ROUTES.REPORTS.TRAINING_TIME_REPORT}
                    component={TrainingTimeReport}
                />
                <PrivateRoute component={NotFoundPage} />
            </Switch>
            <Switch location={modalLocation || rootLocation}>
                <PrivateRoute path={ROUTES.ACTION_ITEMS.NEW_USER} exact>
                    <NewUserContainer
                        closePath={ROUTES.DRAFTS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE}
                    exact
                >
                    <NewScenario
                        closePath={ROUTES.LIBRARY_SETTINGS.SCENARIOS.ALL}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.LIBRARY_SETTINGS.CHALLENGES.SINGLE}
                    exact
                >
                    <EditChallenge
                        closePath={ROUTES.LIBRARY_SETTINGS.CHALLENGES.ALL}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE}
                    exact
                >
                    <PractisSets
                        closePath={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.TEAMS_PAGES.TRAINING_PAGES.TRAINEE_INVITATION}
                    exact
                >
                    <TraineeInvitationContainer
                        closePath={ROUTES.TEAMS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.QUICK_ASSIGNMENT}
                    exact
                >
                    <TraineeQuickAssignmentContainer
                        closePath={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ASSIGN_USERS}
                    exact
                >
                    <TraineeInvitationContainer
                        closePath={ROUTES.TEAMS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.TEAMS_PAGES.MANAGE_TEAM}>
                    <ManageTeamContainer
                        closePath={ROUTES.TEAMS_PAGES.LIST_OF_TEAMS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.TEAMS_PAGES.NEW_TEAM}>
                    <CreateTeam
                        closePath={ROUTES.TEAMS_PAGES.LIST_OF_TEAMS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute
                    path={ROUTES.TRAINEE_DETAILS_REPS}
                    exact
                >
                    <ScenarioRepsContainer
                        closePath={ROUTES.TRAINEE_DETAILS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.COMPANY_SETTINGS.ROOT}>
                    <CompanySettings
                        closePath={ROUTES.SUBMISSIONS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.ROLEPLAYS.NEW_ROLEPLAY}>
                    <RoleplayCreate
                        closePath={ROUTES.ROLEPLAYS.LIST_OF_ROLEPLAYS}
                        closeGoingBack={!!modalLocation}
                    />
                </PrivateRoute>
            </Switch>
            <ErrorMessages />
            <ModalDialogs />
        </div>
    );
};

export default App;
