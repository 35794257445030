import React, { FC } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../../theme/variables';

const Label = styled.div<{ color: string }>`
    font-size: 17px;
    font-weight: 600;
    color: ${props => props.color};
    margin-right: 15px;
    padding: 10px;
`;

const SelectBoxLabel: FC<{
    label: string;
    color?: string;
}> = ({ label, color }) => {
    return (
        <Label color={color ? color : Variables.Colors.coolGray}>{label}</Label>
    );
};

export default SelectBoxLabel;
