import { Keywords } from './Keywords';

export enum ScriptLineSpeaker {
    CUSTOMER = 'CUSTOMER',
    REP = 'REP',
}

export interface ScriptLine {
    id: number | string;
    text: string;
    audioUrl?: string;
    audioId: number;
    speaker: ScriptLineSpeaker;
    position: number;
    duration: number;
    keywords: Keywords[];
}
