import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const SeparatingDot = styled.span`
    display: inline-block;
    background: ${props => props.theme.Colors.black};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0px 5px 2px;
`;

export const useStyles = makeStyles(theme => ({
    customTableCellStyle: {
        padding: '0px !important',
    },
}));
