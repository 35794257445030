import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoMembers = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 48 48"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.2%"
                            x="-14.3%"
                            y="-1.2%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                fill="#212121"
                                d="M45.602 35.997H45.6h.002zm-.002 3.001h.002-.002z"
                                transform="translate(-821 -541) translate(821 541)"
                            />
                            <path
                                d="M846.637 554c11.386 0 13.917 6.857 13.917 15.314 0 6.516-3.304 13.43-8.643 16.286 2.07.498 12.71 3.103 14.024 4.306 2.398 2.198 3.353 8.795.955 12.094l-20.154-.005-20.34.005c-2.417-3.3-1.438-9.896.98-12.094 1.343-1.223 11.657-3.712 14.054-4.277-5.377-2.834-8.71-9.773-8.71-16.315 0-8.457 2.53-15.314 13.917-15.314zm0 2.99c-9.01 0-10.812 5.543-10.812 12.38 0 5.69 2.925 11.981 7.724 13.948v5.19h-1.284c-5.899 1.23-11.32 2.944-12.903 3.716-1.01 1.132-1.655 4.508-1.028 6.777l18.4-.005 18.21.005c.624-2.269-.002-5.635-.997-6.775-1.59-.76-6.919-2.476-12.748-3.718h-1.468v-5.194c4.795-1.971 7.716-8.256 7.716-13.944 0-6.837-1.802-12.38-10.81-12.38z"
                                transform="translate(-825 -554)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoMembers;
