import { FC } from 'react';
import { Slider, withStyles } from '@material-ui/core';

const CustomSlider = withStyles({
    root: {
        height: 3,
        padding: '5px 0',
    },
    rail: {
        height: 3,
        opacity: 0.18,
        borderRadius: 4,
        backgroundColor: 'var(--ps-white-1)',
        color: 'var(--ps-black)'
    },
    track: {
        height: 3,
        borderRadius: 4,
        backgroundColor: 'var(--ps-white-1)',
    },
    thumb: {
        display: 'none',
        height: 9,
        width: 9,
        marginTop: -3,
        marginLeft: -3,
        backgroundColor: 'var(--ps-blue-main)',
        '&:focus': {
            boxShadow: `none`,
        },
        '&:hover': {
            boxShadow: `0 0 0 2px var(--ps-grey-12)`,
        },
        '&:active': {
            boxShadow: `0 0 0 3px var(--ps-grey-12)`,
        },
    },
})(Slider);

const VolumeRangeSlider: FC<{
    volume: number;
    onChange(e: any, newValue: any): void;
    onChangeCommitted(e: any, newValue: any): void;
}> = ({ volume, onChange, onChangeCommitted }) => {
    return (
        <CustomSlider
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
        />
    );
};

export default VolumeRangeSlider;
