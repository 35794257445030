import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ProductIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#o8m0jnx73a)">
        <path d="M12.33 3.75A4.382 4.382 0 0 0 8 0a4.382 4.382 0 0 0-4.33 3.75H1.125v10.375C1.125 15.159 1.966 16 3 16h10a1.877 1.877 0 0 0 1.875-1.875V3.75H12.33zM8 1.25a3.13 3.13 0 0 1 3.062 2.5H4.938A3.13 3.13 0 0 1 8 1.25zm5.625 12.875c0 .345-.28.625-.625.625H3a.626.626 0 0 1-.625-.625V5h11.25v9.125zM8 12.5c2.36 0 4.375-2.17 4.375-5v-.625h-1.25V7.5c0 2.068-1.402 3.75-3.125 3.75S4.875 9.568 4.875 7.5v-.625h-1.25V7.5c0 2.84 2.023 5 4.375 5z" fill="#6D7F8C"/>
    </g>
    <defs>
        <clipPath id="o8m0jnx73a">
            <path fill="#fff" d="M0 0h16v16H0z"/>
        </clipPath>
    </defs>
</svg> );
    }
);

export default ProductIcon;
