import { FC, useCallback, useEffect, useState } from 'react';

import { ActionButton, ActionItem } from '../../../ui/components/ActionButton';
import StackIcon from '../../../ui/icons/Stack';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { formatDate } from '../../../helpers/functions/date-convert';
import { useDispatch, useSelector } from 'react-redux';
import { LogItem } from '../models/LogItem';
import { Details } from '../components/Details';
import { searchLogsDispose } from '../store/actions';
import { useSearchLogsService } from '../store/services';
import { useLogsState } from '../store/states';
import { eventName, userName, userRoleTitle } from '../tools';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import { getCompanyState } from '../../../pages/CompanySettings/store/reducers';
import OutsideActionBox from '../../../ui/components/OutsideActionBox/OutsideActionBox';
import { CheckPermission } from '../../permissions';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { LoadingComponent } from '../../../ui/components/LoadingCopmonent';
import { CalendarValue } from '../../../ui/components/Calendar';
import { useSearchDebounced } from '../../../helpers/hooks/useSearch';
import { LogFilters } from '../components/LogFilters';
import { FiltersPopupButton } from '../../../ui/components/Filters';
import { LogEvent } from '../models/LogEvent';
import NoLogs from '../../../ui/icons/NoLogs';
import { useSession } from '../../../features/common';
import { useHistory } from 'react-router-dom';
import { TableWrapper } from '../../../ui/components/table-wrapper';
import {
    Payload,
    StyledDetails,
    StyledDetailsWrapper,
    useStyles,
} from './styles';
import { useOrderBy } from '../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useFilter } from '../../../ui/components/table-wrapper/table/TableDropdown';
import { useTableStateHelper } from '../../../ui/components/table-wrapper/helper';
import TableTitleOverflowText from '../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import DialogWrapper from '../../../ui/components/DialogWrapper/DialogWrapper';
import { CompanyInterface } from '../../../constants/interfaces/Company';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

export const CompanyLogs: FC<{
    logs?: PaginationResult<LogItem>;
    company: CompanyInterface;
    searchLogs(searchParams: SearchParams): void;
    onUnmount(): void;
    loading?: boolean;
    session?: string;
}> = ({ logs, company, searchLogs, onUnmount, loading, session }) => {
    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        orderBy: { field: 'createdAt', asc: false },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const classes = useStyles();

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        setFilter,
        getFilterByValue,
        setCustom,
    } = useSearchParamsState(initialSearchParams);

    const history = useHistory();
    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const [showPreview, setShowPreview] = useState<any>(null);

    useEffect(() => {
        if (session) {
            searchLogs(searchParams);
        }
    }, [session, searchLogs, searchParams]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useEffect(() => () => onUnmount(), [onUnmount]);

    const orderBy = searchParams.orderBy;
    const hcEvent = useOrderBy('event', orderBy, setOrderBy);
    const hcCreatedAt = useOrderBy('createdAt', orderBy, setOrderBy);
    const hcRole = useOrderBy('creator.role.title', orderBy, setOrderBy);
    const hcCreator = useOrderBy('creator.firstName', orderBy, setOrderBy);

    const events = (getFilterByValue('event') as LogEvent[]) || [];
    const roleIds = (getFilterByValue('creator.roleId') as number[]) || [];

    const handleChangeEvent = useFilter('event', setFilter);
    const handleChangeRole = useFilter('creator.roleId', setFilter);

    const handleChangeStartDate = useCallback(
        (d?: Date | null) => {
            setCustom('startDate', d);
        },
        [setCustom]
    );

    const handleChangeEndDate = useCallback(
        (d?: Date | null) => setCustom('endDate', d),
        [setCustom]
    );

    const handleChangeDate = useCallback(
        (date: CalendarValue<true>) => {
            handleChangeStartDate(date.start);
            handleChangeEndDate(date.end);
        },
        [handleChangeStartDate, handleChangeEndDate]
    );

    const [payload, setPayload] = useState<any>();
    const handleShowDetails = useCallback(
        (event: LogItem) => setPayload(() => event.data && event.data.payload),
        []
    );
    const handleCloseDetails = useCallback(
        () => setPayload(() => undefined),
        []
    );
    const { startDate, endDate } = searchParams;

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters:
            (!!startDate && !!endDate ? 1 : 0) + roleIds.length + events.length,
        itemsCount: logs?.items.length || 0,
    });

    const [lastRefreshed, setLastRefreshed] = useState(new Date());

    const refreshData = useCallback(() => {
        searchLogs(searchParams);
        setLastRefreshed(new Date());
    }, [searchLogs, searchParams]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    return (
        <MainWrapper
            subTitle="Events Log"
            htmlPageTitle={`Events Log ${
                company.name ? `- ${company.name}` : ''
            } - Practis`}
            dataTest="logs"
        >
            {loading && <LoadingComponent />}
            {!!payload && (
                <DialogWrapper
                    modalTitle="Event Details"
                    onCancel={handleCloseDetails}
                    cancelButtonText="Close"
                    customization={{ width: 600, shouldCenterButton: true }}
                >
                    <Payload>{JSON.stringify(payload)}</Payload>
                </DialogWrapper>
            )}

            <TableWrapper
                tableStates={tableStates}
                data={logs?.items}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'logs-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: logs?.totalCount ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            searchParams.filters?.length ||
                            !!searchParams.startDate ||
                            !!searchParams.endDate,
                        dataTest: 'logs-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'logs-search',
                    },
                    filterOptions: {
                        filterComponent: (
                            <FiltersPopupButton
                                disabled={tableStates.disableFilters}
                                filtersCount={roleIds.length + events.length}
                                dataTest="logs-filters"
                            >
                                {({ hide }) => (
                                    <LogFilters
                                        roleIds={roleIds}
                                        events={events}
                                        onApplyFilter={(
                                            selectedRoles,
                                            selectedEvents
                                        ) => {
                                            handleChangeRole(selectedRoles);
                                            handleChangeEvent(selectedEvents);
                                            hide();
                                        }}
                                    />
                                )}
                            </FiltersPopupButton>
                        ),
                    },
                    calendarFilterOptions: {
                        calendarPopUpProps: {
                            value: {
                                start: startDate ? new Date(startDate) : null,
                                end: endDate ? new Date(endDate) : null,
                            },
                            selectRange: true,
                            onChange: handleChangeDate,
                            restrictFuture: true,
                            disabled:
                                tableStates.showEmptyState &&
                                !searchParams.startDate &&
                                !searchParams.endDate,
                            dataTest: 'logs-calendar',
                        },
                    },
                    customization: {
                        rootClassName: classes.tableToolsRootStyle,
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState: true,
                    noEntriesOptions: {
                        icon: StackIcon,
                        text: 'No Logs Yet',
                        dataTest: 'no-logs',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Events Log',
                        dataTest: 'no-found-logs',
                    },
                    noFilterResultsOptions: {
                        icon: NoLogs,
                        noFilterText: 'No Events Log Found',
                        dataTest: 'no-filtered-logs',
                    },
                }}
                configurations={{
                    columns: [
                        {
                            title: 'Events',
                            width: 24,
                            ...hcEvent,
                            disabled: tableStates.disableSorting,
                            className: classes.customTableCellStyle,
                            dataTest: 'name-column',
                        },
                        {
                            title: 'Creator',
                            width: 17,
                            ...hcCreator,
                            disabled: tableStates.disableSorting,
                            dataTest: 'creator-column',
                        },
                        {
                            title: 'Role',
                            width: 17,
                            ...hcRole,
                            disabled: tableStates.disableSorting,
                            dataTest: 'role-column',
                        },
                        {
                            title: 'Date Created',
                            width: 17,
                            ...hcCreatedAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'date-column',
                        },
                        {
                            title: 'Details',
                            width: 25,
                            disabled: tableStates.disableSorting,
                            dataTest: 'details-column',
                        },
                    ],

                    rowConfig: {
                        dataTest: 'log-item',
                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (log: LogItem) =>
                                    !log?.event,
                                fieldProps: {
                                    renderTitle: (log: LogItem) =>
                                        eventName(log?.event),
                                    dataTest: 'log-item-name',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (log: LogItem) =>
                                    !log?.creator,
                                fieldProps: {
                                    renderTitle: (log: LogItem) =>
                                        userName(log?.creator),
                                    dataTest: 'log-item-creator',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (log: LogItem) =>
                                    !log?.creator,
                                fieldProps: {
                                    renderTitle: (log: LogItem) =>
                                        userRoleTitle(log?.creator),
                                    dataTest: 'log-item-role',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (log: LogItem) =>
                                    !log?.createdAt,
                                fieldProps: {
                                    renderTitle: (log: LogItem) =>
                                        formatDate(log?.createdAt),
                                    dataTest: 'log-item-date',
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                shouldShowEmptyCell: (log: LogItem) => !log?.id,
                                getCustomFieldComponent: (log: LogItem) => (
                                    <StyledDetailsWrapper
                                        onMouseEnter={() => {
                                            setShowPreview(log.id);
                                        }}
                                        onMouseLeave={() => {
                                            setShowPreview(null);
                                        }}
                                        onMouseDown={() => {
                                            setShowPreview(null);
                                        }}
                                    >
                                        <TableTitleOverflowText dataTest="log-item-details-truncated">
                                            <Details event={log} />
                                        </TableTitleOverflowText>
                                        <OutsideActionBox
                                            open={showPreview === log.id}
                                            toggleOpen={setShowPreview}
                                        >
                                            <StyledDetails data-test="log-item-details">
                                                <Details event={log} />
                                            </StyledDetails>
                                        </OutsideActionBox>
                                    </StyledDetailsWrapper>
                                ),
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (log: LogItem) => (
                                        <CheckPermission
                                            permissions={[
                                                NEW_PERMISSIONS.VIEW_LOG_DETAILS,
                                            ]}
                                        >
                                            <LogActionButton
                                                event={log}
                                                onShowDetails={
                                                    handleShowDetails
                                                }
                                            />
                                        </CheckPermission>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const CompanyLogsContainer: FC = () => {
    const dispatch = useDispatch();
    const logsState = useLogsState();
    const company = useSelector(getCompanyState);
    const session = useSession();
    const searchLogs = useSearchLogsService();
    const searchCompanyLogs = useCallback(
        (searchParams: SearchParams) => {
            if (company?.id) {
                searchLogs(addCompanyFilter(searchParams, company.id));
            }
        },
        [searchLogs, company?.id]
    );
    const searchCompanyLogsDebounced = useSearchDebounced(searchCompanyLogs);
    const handleUnmount = useCallback(() => dispatch(searchLogsDispose()), [
        dispatch,
    ]);
    return (
        <CompanyLogs
            logs={logsState.data}
            company={company}
            searchLogs={searchCompanyLogsDebounced}
            onUnmount={handleUnmount}
            loading={logsState.loading}
            session={session}
        />
    );
};

export default CompanyLogsContainer;

function addCompanyFilter(
    searchParams: SearchParams,
    companyId: number
): SearchParams {
    return {
        ...searchParams,
        filters: [
            { field: 'companyId', value: companyId },
            ...(searchParams.filters || []),
        ],
    };
}

const LogActionButton: FC<{
    event: LogItem;
    onShowDetails(event: LogItem): void;
}> = ({ event, onShowDetails }) => {
    const handleClick = useCallback(() => onShowDetails(event), [
        event,
        onShowDetails,
    ]);
    return (
        <ActionButton dataTest="log-item-menu">
            <ActionItem onClick={handleClick} dataTest="log-details-action">Details</ActionItem>
        </ActionButton>
    );
};
