import React, { ReactNode } from 'react';
import styled from 'styled-components';

import OutsideActionBox from '../OutsideActionBox/OutsideActionBox';
import { motion } from 'framer-motion';

const Container = styled(OutsideActionBox)`
    position: relative;
`;

const DropDownComponent = styled(motion.div)<{
    marginTop?: number;
    width?: string;
}>`
    background: ${props => props.theme.Colors.white};
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    width: ${props => (props.width ? props.width : '248px')};
    position: absolute;
    border-radius: 8px;
    right: -5px;
    margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '16px')};
    z-index: 1000;
    overflow: hidden;
`;

const ContentArea = styled.div`
    overflow-y: auto;
    max-height: 295px;
    padding: 16px;
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.whiteFive};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.Colors.whiteThree};
    }
`;
export const DropDownList: React.FC<{
    open: boolean;
    toggleOpen: (open: boolean) => void;
    className?: string;
    width?: string;
    marginTop?: number;
    renderItem?: ReactNode;
}> = ({
    children,
    open,
    toggleOpen,
    className,
    marginTop,
    width,
    renderItem,
}) => {
    return (
        <Container
            open={open}
            toggleOpen={toggleOpen}
            styles={{ position: 'relative' }}
        >
            <DropDownComponent
                className={className}
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                marginTop={marginTop}
                width={width}
                data-test='dropDownListContainer'
            >
                {renderItem}
                <ContentArea>{children}</ContentArea>
            </DropDownComponent>
        </Container>
    );
};
