export enum TrainingMode {
    SCRIPT = 'script',
    NO_SCRIPT = 'no_script',
    KEYWORDS = 'keywords',
    TEST_YOURSELF = 'test'
  }

export const trainingModeNames = {
    [TrainingMode.SCRIPT as string]: 'Script',
    [TrainingMode.NO_SCRIPT as string]: 'No Script',
    [TrainingMode.KEYWORDS as string]: 'Keywords',
    [TrainingMode.TEST_YOURSELF as string]: 'Test Yourself',
};

export function trainingModeName(mode: TrainingMode | undefined | null): string {
    return !!mode
        ? (trainingModeNames[mode] || mode || '')
        : '';
}