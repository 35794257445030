import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const EditPencil = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 15 15"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="currentColor" fillRule="evenodd">
                    <g fill="#currentColor">
                        <path
                            d="M1358.3 306.282l-3.585.718-.715-.718.717-3.583 4.657-4.657 3.581-3.581.36-.36.36-.359c.989-.99 2.593-.99 3.583 0 .99.99.99 2.594 0 3.583l-8.958 8.957zm-2.151-3.582l-.499.498-.537 2.69 2.688-.54.498-.498-2.15-2.15zm.716-.717l2.15 2.15 6.45-6.45-2.15-2.15-6.45 6.45zm9.676-7.524c-.593-.593-1.557-.593-2.15 0l-.359.358 2.15 2.15.36-.358c.555-.556.59-1.438.103-2.035z"
                            transform="translate(-1354 -293)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default EditPencil;
