import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoTeams = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 48 48"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.2%"
                            x="-14.3%"
                            y="-1.2%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                fill="#212121"
                                d="M45.602 35.997H45.6h.002zm-.002 3.001h.002-.002z"
                                transform="translate(-821 -541) translate(821 541)"
                            />
                            <path
                                fill="#B1C0CB"
                                d="M17.648 0C26.936 0 29 5.828 29 13.016c0 5.537-2.695 11.415-7.05 13.841.767.193 2.979.755 5.264 1.408l.686.198c2.515.734 4.953 1.543 5.49 2.055 1.955 1.867 2.734 14.677.778 17.482l-16.44-.004L1.139 48c-1.971-2.805-1.172-15.615.8-17.482 1.095-1.04 9.508-3.155 11.463-3.636-4.386-2.409-7.105-8.306-7.105-13.866C6.296 5.828 8.361 0 17.648 0zm0 2.4C10.22 2.4 8.95 7.15 8.95 13.007c0 4.877 2.412 10.266 6.369 11.952v4.447h-1.274C9.18 30.46 5.08 31.928 3.774 32.59c-.832.97-1.365 10.463-.848 12.407l14.803-.004 14.659.004c.514-1.944-.003-11.429-.822-12.405-1.312-.651-5.35-2.122-10.156-3.186h-1.555v-4.45c.927-.397 1.77-.996 2.516-1.745.78-.782 1.455-1.728 2.012-2.774 1.182-2.22 1.834-4.894 1.834-7.43 0-5.858-1.141-10.607-8.569-10.607zM46.021 37.717c1.956 1.868 2.735 7.475.78 10.279l-10.045-.001c.288-.836.503-1.86.65-3l7.452.002c.513-1.944-.003-4.23-.823-5.206-.905-.45-3.445-1.29-6.512-2.117-.074-.95-.182-1.885-.32-2.775 3.415.86 8.085 2.118 8.818 2.818zm-7.136-27.402c2.12 2.388 1.882 5.937 1.882 9.9 0 4.245-1.545 8.689-4.228 11.626-.306-1.046-.674-1.922-1.098-2.543 1.843-2.45 2.888-5.882 2.888-9.091 0-5.713-.255-10.371-7.155-10.6-.096-.841-.23-1.643-.405-2.403 3.892.09 6.46 1.246 8.116 3.111z"
                                transform="translate(-821 -541) translate(821 541)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoTeams;
