import { FC, useRef, useState } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../../../ui/components/ActionButton';
import { Team } from '../../../../../../constants/interfaces/Team';
import { UserInterface } from '../../../../../../constants/interfaces/User';
import { TeamMember } from '../../../../../../constants/interfaces/TeamMember';
import { CheckPermission } from '../../../../../../features/permissions';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { usePractisSetsEnrollmentsService } from '../../../../../users/store/services';
import { AssignPractisSets } from '../../../../../../features/assign-practisSets';

const ListActions: FC<{
    member: TeamMember;
    profile?: UserInterface;
    team?: Team;
    viewProfileHandler: () => void;
    onSendNudge: () => void;
    getMembersReportService: (id?: number) => void;
    onDeleteMember: () => void;
    canRemoveTeamLeader: boolean;
    canNudgeUser: boolean;
    isDefaultTeam?: boolean;
    isHidden?: boolean;
    successApplyPractisSetsCallback: () => void;
}> = ({
    member,
    viewProfileHandler,
    onSendNudge,
    getMembersReportService,
    onDeleteMember,
    canRemoveTeamLeader,
    canNudgeUser,
    isDefaultTeam,
    isHidden,
    successApplyPractisSetsCallback,
}) => {
    const [
        isAssignPractisSetPopupOpen,
        setIsAssignPractisSetPopupOpen,
    ] = useState(false);
    

    const getUserPractisSets = usePractisSetsEnrollmentsService(
        member.user.id.toString()
    );

    const triggerRef = useRef(null);

    /**
     * @function handleOpenAssignPractisSetPopup
     * @returns { void }
     */
    const handleOpenAssignPractisSetPopup = (): void => {
        getUserPractisSets(() => setIsAssignPractisSetPopupOpen(true));
    };

    /**
     * @function handleCloseAssignPractisSetPopup
     * @returns { void }
     */
    const handleCloseAssignPractisSetPopup = (): void => {
        setIsAssignPractisSetPopupOpen(false);
    };

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton customWidth={200} isHidden={isHidden} dataTest="action-menu">
                    <ActionItem
                        onClick={e => {
                            e.stopPropagation();
                            viewProfileHandler();
                        }}
                        dataTest="view-profile-action-item"
                    >
                        View Profile
                    </ActionItem>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.ASSIGN_ENROLLMENT]}
                    >
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleOpenAssignPractisSetPopup();
                            }}
                            dataTest="assign-practisset-action-item"
                        >
                            Assign Practis Sets
                        </ActionItem>
                    </CheckPermission>
                    {canNudgeUser && (<ActionItem
                        onClick={e => {
                            e.stopPropagation();
                            onSendNudge();
                        }}
                        dataTest="nudge-user-action-item"
                    >
                        Nudge User
                    </ActionItem>
                    )}
                    <ActionItem
                        onClick={e => {
                            e.stopPropagation();
                            getMembersReportService(member.user.id);
                        }}
                        dataTest="export-report-action-item"
                    >
                        Export Report
                    </ActionItem>
                    {canRemoveTeamLeader && !isDefaultTeam && (
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                onDeleteMember();
                            }}
                            dataTest="remove-from-team-action-item"
                        >
                            Remove From Team
                        </ActionItem>
                    )}
                </ActionButton>
            </div>
            {isAssignPractisSetPopupOpen && (
                <AssignPractisSets
                    triggerRef={triggerRef}
                    isOpen={isAssignPractisSetPopupOpen}
                    onClose={handleCloseAssignPractisSetPopup}
                    users={[member.user.id]}
                    successApplyPractisSetsCallback={
                        successApplyPractisSetsCallback
                    }
                />
            )}
        </span>
    );
};

export default ListActions;
