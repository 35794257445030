import styled from 'styled-components';

export const MainImage = styled.div`
    width: 416px;
    height: 416px;
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--ps-graphite-3);
`;

export const ErrorMessage = styled.div`
    font-size: 13px;
    color: #b0bdc6;
    padding-top: 8px;
`;

export const NoPlayIcon = styled.div`
    color: var(--ps-grey-1);
`;