import { FC, useState } from 'react';

import { Button } from '../../../../Button';
import TextAreaPlaceholderBox from '../../../../TextAreaPlaceholderBox/TextAreaPlaceholderBox';
import { Variables } from '../../../../../../theme/variables';
import {
    NudgeUserStyledDescription,
    StyledActions,
    StyledContent,
    StyledFormArea,
    StyledInput,
    StyledInputContainer,
    StyledTitle,
} from './styles';

export const NudgeUser: FC<{
    title?: string;
    fromUser?: string;
    practisSetName?: string;
    placeholder?: string;
    onSend?(data: any): void;
    onClose?(): void;
}> = ({ onSend, onClose, title, fromUser, practisSetName, placeholder }) => {
    const [messageData, setMessageData] = useState({
        name: fromUser,
        text: '',
        email: true,
        mobile: true,
    });

    const handleMessageDataChange = (key: string, value: any) => {
        setMessageData(prevState => ({ ...prevState, [key]: value }));
    };

    return (
        <>
            <StyledTitle data-test="nudge-title">Nudge</StyledTitle>
            <StyledContent>
                <NudgeUserStyledDescription data-test="nudge-description">
                    {!!title
                        ? title
                        : 'Send a direct message to the selected Users'}
                </NudgeUserStyledDescription>
                <StyledFormArea>
                    <StyledInputContainer>
                        <StyledInput
                            type="text"
                            name="name"
                            inputPadding="29px 24px 15px 24px"
                            value={messageData.name}
                            label="From"
                            labelLeftPosition="24px"
                            labelFontWeight={500}
                            maxLength={100}
                            height="40px"
                            handleChange={(e: any) =>
                                handleMessageDataChange('name', e.target.value)
                            }
                            dataTest="nudge-from"
                        />
                    </StyledInputContainer>
                    <TextAreaPlaceholderBox
                        value={messageData.text}
                        onChange={(e: any) =>
                            handleMessageDataChange('text', e.target.value)
                        }
                        name="messageToTrainee"
                        placeholder={
                            !!placeholder
                                ? placeholder
                                : practisSetName
                                ? 'Write your message regarding…                                                                              ' +
                                  `Practis Set: ${practisSetName || ''}`
                                : 'Write a message to the selected user(s)'
                        }
                        max={200}
                        minRows={6}
                        fontSize="13px"
                        placeholderFontWeight={500}
                        padding="23px 24px"
                        borderRadius="4px"
                        textAreaMinHeight="90px"
                        counterColor={Variables.Colors.cloudyBlue}
                        dataTest="nudge-message"
                    />
                </StyledFormArea>
                <StyledActions marginTop={15}>
                    <Button
                        width="128px"
                        height="40px"
                        action={onClose}
                        variant="inverse"
                        dataTest="nudge-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        width="128px"
                        height="40px"
                        action={() => !!onSend && onSend(messageData)}
                        disabled={
                            !messageData.name ||
                            !messageData.text ||
                            (!messageData.email && !messageData.mobile)
                        }
                        customDisabledColor={Variables.Colors.cloudyBlue}
                        dataTest="nudge-send"
                    >
                        Send
                    </Button>
                </StyledActions>
            </StyledContent>
        </>
    );
};
