import MainWrapper from '../../ui/wrapper/MainWrapper/MainWrapper';
import { LOADING_ITEMS, ReportInfoMap } from './constants';
import { ReportsViewProps } from './types';
import { Container, Item } from './styles';
import ReportingCard from '../../ui/components/ReportingCard';
import { ReportingCardSkeleton } from '../../ui/components/Skeleton';

function ReportsView({ isLoading, data }: ReportsViewProps) {
    return (
        <MainWrapper subTitle="Reports" contentSize={false} dataTest="reports" htmlPageTitle="Reports - Practis">
            <Container>
                {isLoading &&
                    LOADING_ITEMS.map(() => (
                        <Item>
                            <ReportingCardSkeleton />
                        </Item>
                    ))}
                {!isLoading &&
                    data &&
                    data.items.map(report => (
                        <Item>
                            <ReportingCard {...ReportInfoMap[report.type]} />
                        </Item>
                    ))}
            </Container>
        </MainWrapper>
    );
}

export default ReportsView;