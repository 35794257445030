import { makeStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { AiLogItemStatus } from '../../../constants/interfaces/AiItem';
import ExclamationCircleIcon from '../../../ui/icons/ExclamationCircle';

export const Actions = styled.div`
    display: flex;
    align-items: center;
`;

export const ScenarioWrapper = styled.div<{ status: AiLogItemStatus }>`
    display: flex;
    align-items: center;
    ${({ status, theme }) =>
        status === AiLogItemStatus.DELETED &&
        css`
            color: ${theme.Colors.cloudyBlue};
        `}
    padding-right: 8px;
`;

export const StyledExclamationCircleIcon = styled(ExclamationCircleIcon)`
    margin-left: 4px;
    width: 16px;
    flex-shrink: 0;

    color: ${({ theme }) => theme.Colors.cloudyBlue};
`;

export const useStyles = makeStyles(theme => ({
    customTableCellStyle: {
        paddingLeft: '18px !important',
    },
}));
