import { useEffect } from 'react';
import NewRelic from './generate';
import { useNewRelicApi } from '../../tools/newRelic/NewRelic';

const InjectNewRelic = () => {
    const config = useNewRelicApi();

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = NewRelic({
        applicationID: config.getAppId(),
        licenseKey: config.getLicenseKey(),
    });

    useEffect(() => {
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [script]);

    return null;
};

export default InjectNewRelic;
