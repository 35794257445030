import * as Yup from 'yup';

import { validateEmail } from '../../helpers/functions/validation';

export const INVALID_EMAIL_ERROR = 'Enter a valid Email Address.';

const RULES = {
    fullName: Yup.string().max(100, `Name is too long, maximum 100 characters`),
    companyName: Yup.string().max(
        100,
        `Company Name is too long, maximum 100 characters`
    ),
    companyNameRequired: Yup.string()
        .trim()
        .max(100, `Company Name is too long, maximum 100 characters`)
        .required('Company name is required'),
    companyId: Yup.number(),
    ownerId: Yup.number(),
    accountOwnerName: Yup.string().max(
        100,
        `Owner Name is too long, maximum 100 characters`
    ),
    firstName: Yup.string()
        .trim()
        .max(35, `The First Name shouldn’t be longer than 35 characters.`)
        .required('First name is required'),
    lastName: Yup.string()
        .trim()
        .max(35, `The Last Name shouldn’t be longer than 35 characters.`)
        .required('Last name is required'),
    username: Yup.string()
        .max(100, 'Username is too long, maximum 100 characters')
        .required('Username is required'),
    password: Yup.string()
        .min(8, 'Password must be 8 characters long.')
        .required('The Password field is required.'),
    loginPassword: Yup.string().required('The Password field is required.'),
    confirmPassword: Yup.string()
        .oneOf(
            [Yup.ref('password'), null],
            'Password and Confirm Password does not match.'
        )
        .required('Password confirm is required.'),
    currentPassword: Yup.string()
        .min(8, 'Current password must be at least 8 characters long.')
        .required('Current password is required.'),
    email: Yup.string()
        .max(255, 'Email Address is too long, maximum 255 characters.')
        .test('is-valid-email', INVALID_EMAIL_ERROR, value => {
            return validateEmail(value);
        })
        .required('The Email Address field is required.'),
    authEmail: Yup.string()
        .max(255, 'Email Address is too long, maximum 255 characters.')
        .email(INVALID_EMAIL_ERROR)
        .required('The Email Address field is required.'),
    phoneNumber: Yup.string().required('Make sure you enter a phone number.'),
    timezoneId: Yup.number(),
    invitationCode: Yup.string(),
    organization: Yup.string().required('Organization is required'),
    title: Yup.string(),
    role: Yup.string().required('Role is required'),
    iconFile: Yup.mixed()
        .test('fileSize', 'File Size is too large', value => value.size <= 10)
        .test('fileType', 'Unsupported File Format', value =>
            ['jpg, png, svg, jpeg'].includes(value.type)
        ),
    scenario: {
        description: Yup.string()
            .required('Description is required')
            .max(500, 'Maximum characters has reached'),
        instructions: Yup.string()
            .required('instructions is required')
            .max(160, 'Maximum characters has reached'),
    },
};

export default RULES;
