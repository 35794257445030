import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    @media(max-width:767px) {
        flex-direction: column-reverse;
    }
    @media(min-width: 768px) {
        flex-direction: column-reverse;
    }
    @media(min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const TextSection = styled.div`
    display: flex;
    flex-direction: column;

    @media(max-width:767px) {
        width: 290px;
        padding-top: 24px;
    }
    @media(min-width:768px) {
        width: 432px;
        padding: 32px 33px 0 33px;
    }
    @media(min-width: 1024px) {
        align-items: flex-start;
        justify-content: center;
        width: 345px;
        padding-right: 56px;
    }
`;

export const Title = styled.div`
    font-family: Merriweather;
    color: var(--ps-white-1);
    padding-top: 4px;
    font-weight: 600;

    @media(max-width:767px) {
        font-size: 20px;
        line-height: 25px;
    }
    @media(min-width:768px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
    }
`

export const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    padding-top: 8px;
`

export const DownloadButtons = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    justify-content: space-between;
    width: 286px;
`