import { CompanyUpdateLogoProps } from './types';
import { useDeleteCompanyLogoApi } from '../../../../api';
import CompanyUpdateLogoView from './view';
import { useUploadLogoService } from '../../store/services';
import { useCallback, useEffect, useState } from 'react';

function CompanyUpdateLogo({
    company,
    showGuidelines,
    onNext,
    onCompanyUpdate
} : CompanyUpdateLogoProps) {
    const [isInProgress, setIsInProgress] = useState(false);
    const uploadCompanyLogo = useUploadLogoService();
    const deleteCompanyLogo = useDeleteCompanyLogoApi();

    const setProfileImage = useCallback((image: File) => {
        if (!company?.id) return;
        setIsInProgress(true);
        uploadCompanyLogo(company.id, image)
            .then(onCompanyUpdate);
    }, [company?.id, onCompanyUpdate, uploadCompanyLogo]);

    const deleteLogo = useCallback(() => {
        if (!company?.id) return;
        setIsInProgress(true);
        deleteCompanyLogo(company?.id)
            .then(onCompanyUpdate);
    }, [company?.id, deleteCompanyLogo, onCompanyUpdate]);

    useEffect(() => {
        setIsInProgress(false);
    }, [company]);

    return <CompanyUpdateLogoView
        company={company}
        showGuidelines={showGuidelines}
        isInProgress={isInProgress}
        deleteLogo={deleteLogo}
        setProfileImage={setProfileImage}
        onNext={onNext}
    />;
}

export default CompanyUpdateLogo;