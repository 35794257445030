import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../../../../routes/PrivateRouter';
import ROUTES from '../../../../routes/routes';
import PractisSetProgressContainer from './PractisSetProgress';
import ProgressDetailsContainer from './ProgressDetails';
import { useHistory, useLocations } from '../../../../tools/router';

export const Progress: FC = () => {
    let { rootLocation } = useLocations();
    const history = useHistory();
    return (
        <React.Fragment>
            <Switch location={rootLocation}>
                <Redirect
                    from={ROUTES.TEAMS_PAGES.TRAINING}
                    exact
                    to={{
                        pathname: ROUTES.TEAMS_PAGES.TRAINING_PAGES.PRACTIS_SETS,
                        state: history.location.state
                    }}
                />
                <PrivateRoute
                    path={ROUTES.TEAMS_PAGES.TRAINING_PAGES.PRACTIS_SETS}
                    exact
                    component={PractisSetProgressContainer}
                />
                <PrivateRoute
                    path={
                        ROUTES.TEAMS_PAGES.TRAINING_PAGES.PRACTIS_SETS_DETAILS
                    }
                    exact
                    component={ProgressDetailsContainer}
                />
            </Switch>
        </React.Fragment>
    );
};

export default Progress;
