import { useSelector } from 'react-redux';

import { useWithLabelsContext } from './context/useWithLabelsContext';
import { LabelsState } from './reducer';
import { DEFAULT_LABELS_SCOPE } from './scopes';

export const useLabelsState = (): LabelsState => {
    const { reducerName, scope } = useWithLabelsContext();
    const appState = useSelector(state => state);

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithLabelsContext.Provider props'
        );
    }

    const labelsState: LabelsState | undefined =
        // @ts-ignore
        // TODO implement `_labels?: LabelsState` field into each reducer (create general type)
        // interface GeneralReducer {
        //     _labels?: StateWithLabels;
        //     _practisSets?: StateWithPractisSets;
        // }
        appState[reducerName]?._labels?.[scope || DEFAULT_LABELS_SCOPE];

    if (!labelsState) {
        throw new Error(
            `Wrap your reducer ${reducerName} via "withLabelsReducer" HOR`
        );
    }

    return labelsState;
};
