import { Loading } from '../../../../ui/components/LoadingCopmonent';
import {
    Header,
    Title,
    SubTitle,
    Content,
    UserInfo,
    UserImage,
    UserText,
    MainContent,
    ThumbnailContainer,
    Thumbnail,
    ThumbnailTitle,
    ThumbnailDescription,
    StatsContainer,
    Stats,
    StatsTitle,
    StatsScore,
    StatsDelimiter,
    StatsItem,
    StatsItemTitle,
    StatsItemPoints,
    StatsItemLine,
    StatsItemPoint,
    Buttons,
    GreyButton,
    GreyButtonTitle,
    WhiteButton,
    WhiteButtonTitle,
} from './styles';
import {
    StatsItemCount,
    StatsItemPointCount,
} from './constants';
import { range } from 'lodash';

const StatsItemsList = range(StatsItemCount);
const StatsItemPointList = range(StatsItemPointCount);

function ScreenLoadingView() {
    return <>
        <Header>
            <Title />
            <SubTitle />
        </Header>
        <Content>
            <UserInfo>
                <UserImage />
                <UserText />
            </UserInfo>
            <MainContent>
                <ThumbnailContainer>
                    <Thumbnail>
                        <Loading size={44} />
                    </Thumbnail>
                    <ThumbnailTitle />
                    <ThumbnailDescription />
                </ThumbnailContainer>
                <StatsContainer>
                    <Stats>
                        <StatsTitle />
                        <StatsScore />
                        <StatsDelimiter />
                        {
                            StatsItemsList.map(i =>
                                <StatsItem>
                                    <StatsItemTitle />
                                    <StatsItemPoints>
                                        <StatsItemLine>
                                            {
                                                StatsItemPointList.map(j =>
                                                    <StatsItemPoint index={j} />
                                                )
                                            }
                                        </StatsItemLine>
                                    </StatsItemPoints>
                                </StatsItem>
                            )
                        }
                    </Stats>
                    <Buttons>
                        <WhiteButton>
                            <WhiteButtonTitle />
                        </WhiteButton>
                        <GreyButton>
                            <GreyButtonTitle />
                        </GreyButton>
                    </Buttons>
                </StatsContainer>
            </MainContent>
        </Content>
    </>;
}

export default ScreenLoadingView;