import styled from 'styled-components';

interface RootProps {
    open?: boolean;
    disabled?: boolean;
}

export const Root = styled.button<RootProps>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: ${props => props.theme.Colors.cloudyBlue};
    background: ${({ theme }) => theme.Colors.whiteFive};
    box-shadow: inset 0 0 0 ${({ open }) => (open ? 1 : 0)}px
        ${({ theme }) => theme.Colors.cloudyBlue};
    border: none;
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconHolder = styled.div<{ disabled?: boolean }>`
    width: 16px;
    height: 15px;
    color: ${({ theme, disabled }) =>
        disabled ? theme.Colors.cloudyBlue : theme.Colors.steelGrey};
`;
export const StyledAssignAction = styled.button<{
    open?: boolean;
    disabled?: boolean;
    hasFilters: boolean;
}>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: ${props => props.theme.Colors.cloudyBlue};
    background: ${({ theme }) => theme.Colors.whiteFive};
    box-shadow: inset 0 0 0 ${({ open }) => (open ? 1 : 0)}px
        ${({ theme }) => theme.Colors.cloudyBlue};
    border: none;
`;

export const FiltersDotContainer = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: var(--ps-white-1);
    padding: 9px;
`;

export const FiltersDot = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: var(--ps-blue-main);
`;
