import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ArrowContainer = styled.div<{ isAvailable: boolean }>`
    display: flex;
    cursor: ${props => props.isAvailable ? 'pointer' : 'default'};
    color: ${props => props.isAvailable ? 'var(--ps-blue-main)' : 'var(--ps-grey-2)'};
`

export const Year = styled.div<{ isDisabled: boolean }>`
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.isDisabled ? 'var(--ps-grey-2)' : 'var(--ps-black-main)'};
    padding: 0 12px;
`;