import styled from 'styled-components';

import Checkbox from '../../../Checkbox';

export const CheckboxContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: initial;
    flex-shrink: 0;
    margin-right: 16px;
`;

export const StyledCheckbox = styled(Checkbox)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
`;
