import React from 'react';
import styled from 'styled-components';
import WebLandingPage from './platforms/web';
import MobileLandingPage from './platforms/mobile';
import TabletLandingPage from './platforms/tablet';
import { Platform, useGetPlatform } from './configs';

const LandingPageContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: center;
    background: ${props => props.theme.Colors.dark};
    height: 100vh;
    min-height: 700px;
    flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

const LandingPage = () => {
    const platform = useGetPlatform();
    const isMobile = platform === Platform.MOBILE;
    let content = <WebLandingPage />;

    switch (platform) {
        case Platform.DESKTOP:
            content = <WebLandingPage />;
            break;
        case Platform.LAPTOP:
            content = <WebLandingPage />;
            break;
        case Platform.TABLET:
            content = <TabletLandingPage />;
            break;
        case Platform.MOBILE:
            content = <MobileLandingPage />;
            break;
        default:
            content = <WebLandingPage />;
    }
    return (
        <LandingPageContainer isMobile={isMobile}>
            {content}
        </LandingPageContainer>
    );
};

export default LandingPage;
