import mainImageDesktop from '../../../../../assets/images/Admin-Desktop_img.png';
import mainImageDesktop2x from '../../../../../assets/images/Admin-Desktop_img@2x.png';
import mainImageDesktop3x from '../../../../../assets/images/Admin-Desktop_img@3x.png';
import mainImageTablet from '../../../../../assets/images/Admin-Tablet_img.png';
import mainImageTablet2x from '../../../../../assets/images/Admin-Tablet_img@2x.png';
import mainImageTablet3x from '../../../../../assets/images/Admin-Tablet_img@3x.png';
import mainImagePhone from '../../../../../assets/images/Admin-Phone_img.png';
import mainImagePhone2x from '../../../../../assets/images/Admin-Phone_img@2x.png';
import mainImagePhone3x from '../../../../../assets/images/Admin-Phone_img@3x.png';
import useWindowDimensions from '../../../../../helpers/hooks/useWindowDimensions';
import LoginAuthWrapper from '../../../../../ui/wrapper/LoginAuthWrapper';
import { Variables } from '../../../../../theme/variables';
import { OnboardingViewProps } from '../../types';
import {
    OnboardingContent,
    OnboardingText,
    OnboardingTitle,
    OnboardingDescription,
    OnboardingButton
} from './styles';
import { Button } from '../../../../../ui/components/Button';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

function AdminOnboardingView({ onRedirect }: OnboardingViewProps) {
    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.small;
    const isTablet = !isMobile && windowDimensions.width < Variables.BreakPoints.responsiveSm;
    const isDesktop= windowDimensions.width >= Variables.BreakPoints.responsiveSm;

    useHtmlPageTitle("You're an Admin on Practis");

    return (
        <LoginAuthWrapper heightToShowScroll={700}>
            <OnboardingContent>
                <OnboardingText>
                    <OnboardingTitle>You're an Admin on Practis</OnboardingTitle>
                    <OnboardingDescription>
                        Make sure you bookmark the link to the Practis Admin Portal.
                    </OnboardingDescription>
                    <OnboardingButton>
                        <Button
                            height="40px"
                            width="220px"
                            buttonWidth="100%"
                            fontSize={13}
                            action={onRedirect}
                        >
                            Open the Practis Admin Portal
                        </Button>
                    </OnboardingButton>
                </OnboardingText>
                {isMobile && (
                    <img
                        src={mainImagePhone}
                        srcSet={`${mainImagePhone2x} 2x, ${mainImagePhone3x} 3x`}
                        alt=""
                    />
                )}
                {isTablet && (
                    <img
                        src={mainImageTablet}
                        srcSet={`${mainImageTablet2x} 2x, ${mainImageTablet3x} 3x`}
                        alt=""
                    />
                )}
                {isDesktop && (
                    <img
                        src={mainImageDesktop}
                        srcSet={`${mainImageDesktop2x} 2x, ${mainImageDesktop3x} 3x`}
                        alt=""
                        width="424px"
                        height="470px"
                    />
                )}
            </OnboardingContent>
        </LoginAuthWrapper>
    );
}

export default AdminOnboardingView;