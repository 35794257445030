import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ROUTES from '../../../routes/routes';
import { getCopyrightYear } from '../../../helpers/functions/date-convert';

const StyledFooterContainer = styled.div`
    background: ${props => props.theme.Colors.lighterGray};
    padding: 30px 50px;
`;

const StyledTermsRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const StyledTermsItems = styled(NavLink)`
    color: ${props => props.theme.Colors.coolGray};
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: normal;
    margin-right: 40px;
    text-decoration: none;
`;

const Footer = () => {
    return (
        <StyledFooterContainer>
            <StyledTermsRow>
                <div>
                    <StyledTermsItems to="#">
                        © Practis {getCopyrightYear()}
                    </StyledTermsItems>
                </div>
                <div>
                    <StyledTermsItems to={ROUTES.SUPPORT}>
                        Support
                    </StyledTermsItems>
                    <StyledTermsItems to={ROUTES.TERMS}>
                        Terms & Conditions
                    </StyledTermsItems>
                    <StyledTermsItems to={ROUTES.PRIVACY}>
                        Privacy Policy
                    </StyledTermsItems>
                </div>
            </StyledTermsRow>
        </StyledFooterContainer>
    );
};

export default Footer;
