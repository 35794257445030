import styled from 'styled-components';

import { Button } from '../../../components/Button';
import ROUTES from '../../../../routes/routes';
import Logo from '../../../components/Logo/Logo';
import { Variables } from '../../../../theme/variables';

export interface LandingHeaderInterface {
    withAction?: boolean;
    history?: any;
}

const StyledLandingHeader = styled.div`
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const StyledLogoContainer = styled.div`
    width: 172px;
    height: 42px;
    padding: 24px 0;
`;

const StyledLogo = styled(Logo)`
    cursor: pointer;
    max-width: 100%;
`;

const StyledActionPanel = styled.div`
    padding: 24px 0;
    display: flex;
`;

const LandingHeader = ({ withAction, history }: LandingHeaderInterface) => {
    const handleCreateProfile = () => {
        history.push(ROUTES.AUTH_PAGES.REGISTER);
    };

    const handleLogin = () => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    };

    const handleGoToHome = () => {
        history.push(ROUTES.LANDING);
    };

    return (
        <StyledLandingHeader>
            <StyledLogoContainer>
                <StyledLogo
                    onClick={handleGoToHome}
                    height={40}
                    color={Variables.Colors.black}
                />
            </StyledLogoContainer>
            {withAction && (
                <StyledActionPanel>
                    <Button
                        variant="inverse"
                        style={{
                            minWidth: 130,
                            padding: '0 20px',
                            marginRight: 20,
                        }}
                        action={handleCreateProfile}
                    >
                        Create Profile
                    </Button>
                    <Button
                        style={{ minWidth: 90, fontSize: 14 }}
                        action={handleLogin}
                    >
                        Login
                    </Button>
                </StyledActionPanel>
            )}
        </StyledLandingHeader>
    );
};

export default LandingHeader;
