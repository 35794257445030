export type SearchableFilterHeaderItemState = {
    isSearchActive: boolean;
    searchTerm: string;
}

export enum SearchableFilterHeaderKey {
    SINGLE_TEAM = 'SINGLE_TEAM',
    PRACTIS_SET = 'PRACTIS_SET',
    MULTIPLE_TEAMS = 'MULTIPLE_TEAMS',
    LABELS = 'LABELS',
}

export type SearchableFilterHeaderState = Record<SearchableFilterHeaderKey, SearchableFilterHeaderItemState | undefined>;