import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CompanyDetailsView from './view';
import { ApiValidationError, CompanyAdminSelectItem, CompanyDetailsFormValues, CompanyDetailsProps } from './types';
import { useClearCompanyAdmins, useGetCompanyAdmins } from '../../store/services';
import { useUserRolesState } from '../../../../features/roles/store/states';
import { useLoadUserRolesService } from '../../../../features/roles/store/services';
import { useCompanyAdminsState } from '../../store/states';
import { useUpdateCompanyNameApi, useSetCompanyOwnerApi } from '../../../../api';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { API_ERROR_MESSAGE, NAME_ALREADY_EXISTS_ERROR, NO_ACCOUNT_OWNER_TEXT } from './constants';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';

function CompanyDetails({ company, onCompanyUpdate }: CompanyDetailsProps) {
    const formRef = useRef<any>(null);
    const [nameApiError, setNameApiError] = useState<ApiValidationError | undefined>();
    const companyAdmins = useCompanyAdminsState().data;
    const getCompanyAdmins = useGetCompanyAdmins();
    const userRoles = useUserRolesState();
    const loadUserRoles = useLoadUserRolesService();
    const updateCompanyNameApi = useUpdateCompanyNameApi();
    const setCompanyOwnerApi = useSetCompanyOwnerApi();
    const clearCompanyAdmins = useClearCompanyAdmins();
    const showMessage = useShowMessage();

    const companyAdminItems = useMemo(() => {
        if (!companyAdmins) return;
        const emptyItem = { name: NO_ACCOUNT_OWNER_TEXT, value: null } as CompanyAdminSelectItem;
        const mainItems = companyAdmins.map(admin => ({
            name: admin.firstName + ' ' + admin.lastName,
            value: admin.id,
        }));
        return [emptyItem].concat(mainItems);
    }, [companyAdmins]);

    const initialValues = useMemo(() => {
        return {
            name: company.name,
            workspaceUrl: company.subdomain ? `${company.subdomain}.gopractis.com` : '',
            ownerId: !!company.owner?.id && !!companyAdminItems?.find(item => item.value === company.owner!.id)
                ? company.owner.id
                : null
        } as CompanyDetailsFormValues
    }, [company.name, company.owner, company.subdomain, companyAdminItems]);

    const handleApiResultValidation = useCallback((values: CompanyDetailsFormValues) => {
        const errors: Record<string, string> = {};
        if (values.name === nameApiError?.paramValue) {
            errors.name = nameApiError.error;
        }
        return errors;
    }, [nameApiError]);

    const handleError = useCallback((e: ErrorResult, values: CompanyDetailsFormValues) => {
        switch(e.message) {
            case NAME_ALREADY_EXISTS_ERROR:
                setNameApiError({
                    paramValue: values.name,
                    error: API_ERROR_MESSAGE[NAME_ALREADY_EXISTS_ERROR]
                });
                break;
            default:
                showMessage(e.message, 'error');
                break;
        }
        formRef.current.setSubmitting(false);
        formRef.current.validateForm();
    }, [showMessage]);

    const handleSubmit = useCallback((values: CompanyDetailsFormValues) => {
        const companyNamePromise = company.name?.toLowerCase() !== values.name.toLowerCase()
            ? updateCompanyNameApi(company.id!, values.name)
            : Promise.resolve();
        companyNamePromise
            .then(() => {
                const isOwnerChanged = !!company.owner?.id
                    ? company.owner?.id !== values.ownerId
                    : !!values.ownerId;
                const ownerPromise = isOwnerChanged
                    ? setCompanyOwnerApi(company.id!, values.ownerId!)
                    : Promise.resolve();
                ownerPromise
                    .then(() => {
                        formRef.current.setSubmitting(false);
                        formRef.current.resetForm(values);
                        onCompanyUpdate();
                    })
                    .catch((e: ErrorResult) => {
                        handleError(e, values);
                    });
            })
            .catch((e: ErrorResult) => {
                handleError(e, values);
            });
    }, [company, handleError, onCompanyUpdate, updateCompanyNameApi, setCompanyOwnerApi]);

    useEffect(() => {
        if (!userRoles?.list?.length) {
            loadUserRoles();
        }
    }, [userRoles?.list?.length, loadUserRoles]);

    useEffect(() => {
        if (userRoles?.list?.length) {
            getCompanyAdmins(userRoles.list, company.id!);
        }
    }, [getCompanyAdmins, company.id, userRoles?.list]);

    useEffect(() => {
        formRef.current.resetForm(initialValues);
    }, [initialValues]);

    useEffect(() => {
        return () => {
            clearCompanyAdmins();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CompanyDetailsView
            formRef={formRef}
            initialValues={initialValues}
            companyAdminItems={companyAdminItems}
            onSubmit={handleSubmit}
            onApiResultValidation={handleApiResultValidation}
        />
    )
}

export default CompanyDetails;