import React, { FC, useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import styled from 'styled-components';

import { DraftUser, PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { CheckPermission } from '../../../../../features/permissions';
import { WithPractisSetsContext } from '../../../../../features/portablePractisSets';
import { WithTeamsContext } from '../../../../../features/portableTeams';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import { EditAssignRoles } from './EditAssignRoles';
import EditAssignPractisSets from './EditAssignPractisSets';
import {
    useResetSelectedLabels,
    useSelectLabels,
} from '../../../../../features/portableLabels/store/hors/withLabels/services';
import EditAssignTeams from './EditAssignTeams';
import EditAssignLabels from './EditAssignLabels';
import OutsideActionBox from '../../../../../ui/components/OutsideActionBox/OutsideActionBox';
import ThinClose from '../../../../../ui/icons/ThinClose';
import ThinCheck from '../../../../../ui/icons/ThinCheck';

//region Styles
const TableRow = styled.tr`
    &&:hover {
        background: unset;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 16fr 13fr 18fr 12fr 11fr 15fr 15fr;
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    padding: 7.5px 0;
    position: relative;
`;

const Actions = styled.div`
    position: absolute;
    right: 0;
    bottom: -40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
`;

const ActionButton = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: ${props => props.theme.Colors.whiteFive};
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &&:hover {
        opacity: 0.9;
    }
`;

const ActionIcon = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    color: inherit;
`;

const SectionItem = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background: ${props => props.theme.Colors.whiteFive};
    position: relative;
    & > div {
        width: 100%;
    }
`;

const SectionError = styled.div`
    position: absolute;
    bottom: -20px;
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.tomato};
`;

const Input = styled.input<{ hasError?: boolean }>`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: normal;
    color: ${props => props.theme.Colors.black};
    border: none;
    background: transparent;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    border-radius: 4px;
    box-shadow: 0 0 0 ${props => (props.hasError ? '1px' : '0px')} ${props => props.theme.Colors.tomato};
`;
//endregion

const EditUserItem: FC<{
    user: DraftUser;
    setUser(user: DraftUser): void;
    handleUpdateSelectedUser(): void;
    assignedRole: number | null;
    onAssignRoleCheck(role: number | null): void;
    selectedUserLabels: number[];
    setSelectedUserLabels: (labelIds: number[]) => void;
    selectedUserPractisSets: PractisSetWithDueDate[];
    setSelectedUserPractisSets: (practisSetIds: PractisSetWithDueDate[]) => void;
    selectedUserTeams: number[];
    setSelectedUserTeams: (teamIds: number[]) => void;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => void;
    isRoleDisabled: boolean | undefined;
    dataTest: string;
}> = ({
    user,
    setUser,
    handleUpdateSelectedUser,
    handleInputChange,
    assignedRole,
    onAssignRoleCheck,
    setSelectedUserLabels,
    selectedUserLabels,
    setSelectedUserPractisSets,
    selectedUserPractisSets,
    setSelectedUserTeams,
    selectedUserTeams,
    isRoleDisabled,
    dataTest
}) => {
    const selectLabels = useSelectLabels();
    const resetSelectedLabels = useResetSelectedLabels();
    const [modified, setModified] = useState(false);
    const [hideActions, setHideActions] = useState(false);

    useEffect(() => {
        selectLabels(user.labelIDs);
    }, [selectLabels, user]);

    const resetSelectedUser = () => {
        setUser({
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            labelIDs: [],
            practisSetIDs: [],
            teamIDs: [],
            roleId: null,
        });
        resetSelectedLabels();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => {
        if (!modified) {
            setModified(true);
        }
        handleInputChange(e, field);
    };

    const handleSaveUser = () => {
        setHideActions(true);
        handleUpdateSelectedUser();
    };

    const isFirstNameInvalid = !user.firstName || user.firstName.length < 1;
    const isLastNameInvalid = !user.lastName || user.lastName.length < 1;
    const isEmailInvalid = !EmailValidator.validate(user.email.trim());
    const isRoleInvalid = !assignedRole;

    const isEditFormInvalid =
        isFirstNameInvalid ||
        isLastNameInvalid ||
        isEmailInvalid ||
        isRoleInvalid;

    return (
        <TableRow data-test={`${dataTest}-row`}>
            <td colSpan={9}>
                <OutsideActionBox
                    open={true}
                    toggleOpen={() => {
                        if (!modified) {
                            resetSelectedUser();
                        }
                    }}
                >
                    <Container>
                        <SectionItem>
                            <Input
                                value={user.firstName}
                                onChange={e => {
                                    handleChange(e, 'firstName');
                                }}
                                autoFocus={true}
                                data-test={`${dataTest}-first-name`}
                            />
                        </SectionItem>
                        <SectionItem>
                            <Input
                                value={user.lastName}
                                onChange={e => {
                                    handleChange(e, 'lastName');
                                }}
                                data-test={`${dataTest}-last-name`}
                            />
                        </SectionItem>
                        <SectionItem>
                            <Input
                                value={user.email}
                                onChange={e => {
                                    handleChange(e, 'email');
                                }}
                                hasError={isEmailInvalid}
                                data-test={`${dataTest}-email`}
                            />
                            {isEmailInvalid && (
                                <SectionError>
                                    Enter a valid email address 
                                </SectionError>
                            )}
                        </SectionItem>
                        <SectionItem>
                            <CheckPermission
                                permissions={[
                                    NEW_PERMISSIONS.ASSIGN_INVITATION_ROLE,
                                ]}
                            >
                                <EditAssignRoles
                                    disabled={isRoleDisabled}
                                    assignedRole={assignedRole}
                                    handleRoleCheck={(role: number) => {
                                        onAssignRoleCheck(role);
                                    }}
                                    modified={modified}
                                    setModified={setModified}
                                    dataTest={`${dataTest}-roles`}
                                />
                            </CheckPermission>
                        </SectionItem>
                        <SectionItem>
                            <CheckPermission
                                permissions={[
                                    NEW_PERMISSIONS.ASSIGN_INVITATION_PRACTIS_SET,
                                ]}
                            >
                                <WithTeamsContext.Provider
                                    value={{
                                        reducerName: 'draftEdit',
                                        scope: 'editUser',
                                    }}
                                >
                                    <EditAssignTeams
                                        selectedTeams={selectedUserTeams}
                                        onSelectTeams={setSelectedUserTeams}
                                        modified={modified}
                                        setModified={setModified}
                                        dataTest={`${dataTest}-teams`}
                                    />
                                </WithTeamsContext.Provider>
                            </CheckPermission>
                        </SectionItem>
                        <SectionItem>
                            <CheckPermission
                                permissions={[
                                    NEW_PERMISSIONS.ASSIGN_INVITATION_PRACTIS_SET,
                                ]}
                            >
                                <WithPractisSetsContext.Provider
                                    value={{
                                        reducerName: 'draftEdit',
                                        scope: 'editUser',
                                    }}
                                >
                                    <EditAssignPractisSets
                                        selectedPractisSets={
                                            selectedUserPractisSets
                                        }
                                        onSelectPractisSets={
                                            setSelectedUserPractisSets
                                        }
                                        modified={modified}
                                        setModified={setModified}
                                        dataTest={`${dataTest}-practis-sets`}
                                    />
                                </WithPractisSetsContext.Provider>
                            </CheckPermission>
                        </SectionItem>
                        <SectionItem>
                            <CheckPermission
                                permissions={[
                                    NEW_PERMISSIONS.ASSIGN_INVITATION_LABEL,
                                ]}
                            >
                                <EditAssignLabels
                                    selectedLabels={selectedUserLabels}
                                    onSelectLabels={setSelectedUserLabels}
                                    modified={modified}
                                    setModified={setModified}
                                    dataTest={`${dataTest}-labels`}
                                />
                            </CheckPermission>
                        </SectionItem>
                        {!hideActions && (
                            <Actions>
                                <ActionButton onClick={resetSelectedUser} data-test={`${dataTest}-cancel`}>
                                    <ActionIcon>
                                        <ThinClose />
                                    </ActionIcon>
                                </ActionButton>
                                <ActionButton
                                    onClick={() => {
                                        !isEditFormInvalid && handleSaveUser();
                                    }}
                                    disabled={isEditFormInvalid}
                                    data-test={`${dataTest}-save`}
                                >
                                    <ActionIcon>
                                        <ThinCheck />
                                    </ActionIcon>
                                </ActionButton>
                            </Actions>
                        )}
                    </Container>
                </OutsideActionBox>
            </td>
        </TableRow>
    );
};

export default EditUserItem;
