import { useState, FC, useContext, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { ScenarioList } from './pages/Scenarios';
import { ChallengeList } from './pages/Challenges';
import {
    PractisSetContent,
    PractisSets,
} from '../../../../../../../constants/interfaces/PractisSets';
import { Variables } from '../../../../../../../theme/variables';
import { EditModeValues } from '../../../../../../../constants/enums/EditModeValues';
import { EditModeContext } from '../../../../PractisSets';
import { useModifyPractisSetService, useSearchPractisChallenges, useSearchPractisScenarios } from '../../../../store/services';
import { useSelector } from 'react-redux';
import { getPractisSetState } from '../../../../store/reducers';
import { WithLabelsContext } from '../../../../../../../features/portableLabels';
import { UserProfile } from '../../../../../../../constants/interfaces/User';
import { Scenario } from '../../../../../../../constants/interfaces/Scenario';
import { SearchParams } from '../../../../../../../constants/interfaces/filters';
import { Challenge } from '../../../../../../../constants/interfaces/Challenge';
import { getProfileState } from '../../../../../../../pages/UserProfile/store/reducers';
import { useSearchDebounced } from '../../../../../../../helpers/hooks/useSearch';
import { useLabelsState } from '../../../../../../../features/portableLabels/store/hors/withLabels/states';
import { LibraryItemsStatus } from '../../../../../../../features/library/tools';
import TextAreaBox from '../../../../../../../ui/components/TextAreaBox/TextAreaBox';


const ITEMS_PER_PAGE = 20;

const StyledListContent = styled.div`
    flex: 1;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const StyledDescriptionInfoContent = styled.div`
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    margin-bottom: 16px;
`;

const StyledInputDivs = styled.div``;

const ListContent: FC<{
    practisSet?: PractisSets;
    practisSetContent: PractisSetContent[];
    profile?: UserProfile;
    scenarios: Array<Scenario>;
    isScenariosLoading: boolean;
    isChallengesLoading: boolean;
    getScenarios(searchParams: SearchParams): void;
    challenges: Array<Challenge>;
    getChallenges(searchParams: SearchParams): void;
    addScenarioToBuilder?: (scenarioId?: number | string) => void;
    addChallengeToBuilder?: (challengeId?: number | string) => void;
}> = ({
    practisSet,
    practisSetContent,
    profile,
    scenarios,
    getScenarios,
    challenges,
    isScenariosLoading,
    isChallengesLoading,
    getChallenges,
    addScenarioToBuilder,
    addChallengeToBuilder,
}) => {
    const [page, setPage] = useState<string>('');
    const edit = useContext(EditModeContext);
    const modifyPractisSet = useModifyPractisSetService();
    const labels = useLabelsState();

    const initialSearchParams: SearchParams = useMemo(
        () => ({
            searchTerm: '',
            filters: [{ field: 'status', value: [LibraryItemsStatus.ACTIVE] }],
            orderBy: {
                field: 'updated_at',
                asc: false,
            },
            labelIDs: labels.saved,
            offset: 0,
            limit: ITEMS_PER_PAGE,
            totalCount: 0,
            numberOfPages: 0,
            companyId: profile?.companyId
        }),
        [labels.saved, profile?.companyId]
    );

    const handleChange = useCallback((event: { target: HTMLInputElement }) => {
        modifyPractisSet(event.target.value, 'description');
    }, [modifyPractisSet]);

    useEffect(() => {
        if (!page || page === 'scenarios') {
            getScenarios(initialSearchParams);
        }
        if (!page || page === 'challenges') {
            getChallenges(initialSearchParams);
        }
    }, [page, getScenarios, getChallenges, initialSearchParams]);

    return (
        <StyledListContent>
            <StyledDescriptionInfoContent>
                <StyledInputDivs>
                    <TextAreaBox
                        background={Variables.Colors.whiteFive}
                        value={practisSet && practisSet.description}
                        onChange={handleChange}
                        name="description"
                        placeholder="Description"
                        max={500}
                        minRows={1}
                        padding="6px 12px"
                        readOnly={edit.mode === EditModeValues.VIEW}
                        dataTest='practis-set-description'
                    />
                </StyledInputDivs>
            </StyledDescriptionInfoContent>
            {!page || page === 'scenarios' ? (
                <ScenarioList
                    profile={profile}
                    initialSearchParams={initialSearchParams}
                    scenarios={scenarios}
                    onChangeSearchParams={getScenarios}
                    practisSetContent={practisSetContent}
                    addToBuilder={addScenarioToBuilder}
                    page={page || 'scenarios'}
                    setPage={setPage}
                    isLoading={isScenariosLoading}
                />
            ) : (
                <ChallengeList
                    profile={profile}
                    initialSearchParams={initialSearchParams}
                    challenges={challenges}
                    onChangeSearchParams={getChallenges}
                    practisSetContent={practisSetContent}
                    addToBuilder={addChallengeToBuilder}
                    page={page}
                    setPage={setPage}
                    isLoading={isChallengesLoading}
                />
            )}
        </StyledListContent>
    );
};

export const ListContentContainer: FC<{
    practisSetContent: PractisSetContent[];
    addScenarioToBuilder?: (scenarioId?: number | string) => void;
    addChallengeToBuilder?: (challengeId?: number | string) => void;
}> = ({ practisSetContent, addScenarioToBuilder, addChallengeToBuilder }) => {
    const practisSet = useSelector(getPractisSetState).info;
    const profile = useSelector(getProfileState);
    const scenarios = useSelector(getPractisSetState).scenarios;
    const isScenariosLoading = useSelector(getPractisSetState).isScenariosLoading;
    const isChallengesLoading = useSelector(getPractisSetState).isChallengesLoading;
    const searchPractisScenarios = useSearchPractisScenarios();
    const searchPractisScenariosDebounced = useSearchDebounced(
        searchPractisScenarios
    );
    const challenges = useSelector(getPractisSetState).challenges;
    const searchPractisChallenges = useSearchPractisChallenges();
    const searchPractisChallengesDebounced = useSearchDebounced(
        searchPractisChallenges
    );

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'practisSet',
                scope: 'applyLabels',
            }}
        >
            <ListContent
                practisSet={practisSet}
                practisSetContent={practisSetContent}
                profile={profile}
                scenarios={scenarios}
                isScenariosLoading={isScenariosLoading}
                isChallengesLoading={isChallengesLoading}
                getScenarios={searchPractisScenariosDebounced}
                challenges={challenges}
                getChallenges={searchPractisChallengesDebounced}
                addScenarioToBuilder={addScenarioToBuilder}
                addChallengeToBuilder={addChallengeToBuilder}
            />
        </WithLabelsContext.Provider>
    );
};

export default ListContentContainer;
