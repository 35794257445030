import { useCallback, useMemo } from 'react';
import { YearSelectorProps } from './types';
import YearSelectorView from './view';

function YearSelector({
    selectedYear,
    minYear,
    isDisabled,
    onChange,
}: YearSelectorProps) {
    const presentYear = useMemo(() => new Date().getFullYear(), []);
    const isNextAvailable = selectedYear < presentYear && !isDisabled;
    const isPrevAvailable = selectedYear >= minYear && !isDisabled;

    const handlePrev = useCallback(() => {
        if (isPrevAvailable) {
            onChange(selectedYear - 1);
        }
    }, [isPrevAvailable, onChange, selectedYear]);

    const handleNext = useCallback(() => {
        if (isNextAvailable) {
            onChange(selectedYear + 1);
        }
    }, [isNextAvailable, onChange, selectedYear]);
    
    return (
        <YearSelectorView
            selectedYear={selectedYear}
            isNextAvailable={isNextAvailable}
            isPrevAvailable={isPrevAvailable}
            isDisabled={isDisabled}
            onPrev={handlePrev}
            onNext={handleNext}
        />
    );
}

export default YearSelector;