import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoSearchResult = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 50 50"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M28.318 0C17.465 0 8.636 8.83 8.636 19.682c0 4.753 1.693 9.118 4.508 12.523L0 45.349 2.651 48l13.144-13.145c3.405 2.816 7.77 4.509 12.523 4.509C39.171 39.364 48 30.534 48 19.682 48 8.829 39.17 0 28.318 0zm0 35.615c-8.785 0-15.933-7.148-15.933-15.933S19.533 3.749 28.318 3.749s15.933 7.148 15.933 15.933-7.148 15.933-15.933 15.933zm8.947-22.229l-6.296 6.296 6.296 6.296-2.651 2.65-6.296-6.295-6.296 6.296-2.65-2.651 6.295-6.296-6.296-6.296 2.651-2.65 6.296 6.295 6.296-6.296 2.65 2.651z"
                    transform="translate(-821 -544) matrix(-1 0 0 1 869 544)"
                />
            </svg>
        );
    }
);

export default NoSearchResult;
