import React, { FC, useRef, useState } from 'react';
import { SelectLabel, SelectInput, SelectMenuItem, SelectMenu } from './index';
import styled from 'styled-components';
import OutsideActionBox from '../OutsideActionBox/OutsideActionBox';
import { Variables } from '../../../theme/variables';
import UpArrow from '../../icons/UpArrow';
import DownArrow from '../../icons/DownArrow';
import {
    ALL_FILTER_NAME,
    ALL_FILTER_VALUE,
} from '../../../constants/interfaces/filters';

const COLORS: any = {
    light: {
        background: Variables.Colors.whiteFive,
        color: Variables.Colors.steelGrey,
    },
    dark: {
        background: Variables.Colors.darkTwo,
        color: Variables.Colors.whiteTwo,
    },
    gray: {
        background: Variables.Colors.whiteTwo,
        color: Variables.Colors.black,
    },
    transparent: {
        background: Variables.Colors.transparent,
        color: Variables.Colors.steelGrey,
    },
};

const StyledSelectForm = styled.div<{
    height?: number;
    error?: boolean;
    variant: string;
    border?: boolean;
}>`
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    min-width: 128px;
    cursor: pointer;
    height: ${props => (props.height ? `${props.height}px` : '40px')};
    width: 100%;
    background: ${props => COLORS[props.variant].background};
    color: ${props => COLORS[props.variant].color};
    box-shadow: inset 0 0 0 ${props => (props.error ? '2px' : '-1px')} ${props => props.theme.Colors.coral};
    -webkit-appearance: none;
    
    border: ${props => (props.border ? 1 : 0)}px solid
        ${props => props.theme.Colors.cloudyBlue};
    &.is-disabled {
        color: ${props => props.theme.Colors.cloudyBlue};
        cursor: default;
    }
`;

const Container = styled(OutsideActionBox)`
    position: relative;
`;

const IconHolder = styled.div`
    width: 8px;
    height: 100%;
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const SelectDropdown: FC<{
    label?: string;
    labelColor?: string;
    id?: string;
    items?: Array<any>;
    children?: Array<any>;
    value?: string | number | string[] | number[] | null;
    name?: string;
    defaultValue?: string | number;
    colorTheme?: 'light' | 'dark' | 'gray' | 'transparent';
    onChange?: any;
    disabled?: boolean;
    error?: boolean;
    menuPosition?: 'left' | 'right';
    height?: number;
    fontSize?: string;
    className?: string;
    restrictShowAllName?: boolean;
    openMenuWidth?: string;
}> = ({
    label,
    labelColor,
    items,
    value,
    onChange,
    name,
    colorTheme,
    menuPosition,
    height,
    fontSize,
    disabled,
    className,
    error,
    restrictShowAllName,
    openMenuWidth,
}) => {
    const [open, setOpen] = useState(false);
    const selectedRef = useRef(null);
    const multiSelect = Array.isArray(value);
    const findNameByValue = () => {
        if (items) {
            let selectedItems = items.filter(item =>
                Array.isArray(value)
                    ? value.includes(item.value as never)
                    : item.value === value
            );

            if (selectedItems.length < 2) {
                return selectedItems[0] && selectedItems[0].name;
            } else {
                return (
                    selectedItems &&
                    selectedItems
                        .map(item => {
                            return item.name;
                        })
                        .join(', ')
                );
            }
        }
    };

    const selectedName = findNameByValue();

    return (
        <StyledSelectForm
            variant={colorTheme ? colorTheme : 'light'}
            error={error}
            height={height}
            className={[className, disabled ? 'is-disabled' : undefined].join(
                ' '
            )}
            border={open || (!!value && value !== ALL_FILTER_VALUE)}
        >
            {(label && !value) ||
                (restrictShowAllName &&
                    label &&
                    !!value &&
                    value === ALL_FILTER_VALUE && (
                        <SelectLabel label={label} color={labelColor} />
                    ))}
            <IconHolder>{open ? <UpArrow /> : <DownArrow />}</IconHolder>
            <SelectInput
                value={
                    restrictShowAllName
                        ? selectedName === ALL_FILTER_NAME
                            ? ''
                            : selectedName
                        : selectedName
                }
                toggleSelect={() => !disabled && setOpen(!open)}
                height={height}
                fontSize={fontSize}
            />

            <Container open={open} toggleOpen={setOpen}>
                <SelectMenu menuPosition={menuPosition} width={openMenuWidth}>
                    {items &&
                        items.map((item: { value: string; name: string }) => (
                            <SelectMenuItem
                                forwardedRef={
                                    (
                                        Array.isArray(value)
                                            ? value.includes(
                                                  item.value as never
                                              )
                                            : item.value === value
                                    )
                                        ? selectedRef
                                        : null
                                }
                                key={item.value}
                                value={item.value}
                                name={name}
                                hideAction={() => setOpen(false)}
                                onClick={onChange}
                                selected={
                                    Array.isArray(value)
                                        ? value.includes(item.value as never)
                                        : item.value === value
                                }
                                multiSelect={multiSelect}
                            >
                                {item.name}
                            </SelectMenuItem>
                        ))}
                </SelectMenu>
            </Container>
        </StyledSelectForm>
    );
};

export default SelectDropdown;
