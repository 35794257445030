import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const RemoveUser = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M530.681 162l1.18 1.18c.187.187.187.491 0 .679-.188.187-.492.187-.68 0l-1.179-1.18-1.179 1.18c-.187.187-.49.187-.678 0-.188-.188-.188-.492 0-.68l1.178-1.178-1.178-1.179c-.188-.187-.188-.491 0-.679.187-.188.49-.188.678 0l1.18 1.18 1.178-1.18c.188-.188.492-.188.68 0 .187.188.187.492 0 .68L530.68 162zm-4.937-3.835c.514.128 1.265.32 2.01.53-.35.239-.65.54-.908.875-.605-.163-1.24-.325-1.844-.47v-1.584c1.254-.854 1.997-2.708 1.997-4.393 0-2.28-.583-4.127-3.498-4.127-2.916 0-3.499 1.848-3.499 4.127 0 1.686.744 3.542 2 4.395v1.596c-1.89.445-4.086 1.054-4.591 1.33-.327.412-.612 1.733-.41 2.558h9.142c.093.355.233.688.413.998h-10.105c-.782-1.2-.465-3.599.317-4.398.372-.38 3.165-1.1 4.502-1.428-1.412-1.096-2.273-3.13-2.273-5.07 0-2.819.82-5.104 4.504-5.104 3.685 0 4.503 2.285 4.503 5.105 0 1.933-.856 3.961-2.26 5.06z"
                            transform="translate(-516 -148)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default RemoveUser;
