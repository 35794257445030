import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarTeamsActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1 3h2l1 1.5L8 5l4-.5L13 3h2v12H1V3z" fill="#fff"/>
                <path fill="#222431" d="M4 8h8v1H4zM4 11h6v1H4z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0a2 2 0 0 0-1.732 1H4a1 1 0 0 0-1 1H0v14h16V2h-3a1 1 0 0 0-1-1H9.732A2 2 0 0 0 8 0zm5 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3H1v12h14V3h-2z" fill="#222431"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2a1 1 0 0 0-2 0H4v2h8V2H9z" fill="#fff"/>
            </svg>
        );
    }
);

export default SidebarTeamsActive;
