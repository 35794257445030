import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    AssignLinesToChallengeApiParams,
    CreateChallengeApiParams,
    DeleteChallengesApiParams,
    GetChallengeApiParams,
    SearchChallengesApiParams,
    UpdateChallengeInfoApiParams,
    UpdateChallengeLabelsApiParams,
    UpdateChallengeStatusApiParams,
} from './types';

/**
 * @function useGetChallengeApi
 * @returns { GetChallengeApiParams }
 */
export function useGetChallengeApi(): GetChallengeApiParams {
    const http = useHttp();
    return useCallback(
        challengeId =>
            http.get(`challenges/${challengeId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useSearchChallengesApi
 * @returns { SearchChallengesApiParams }
 */
export const useSearchChallengesApi = (): SearchChallengesApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams => http.get('challenges', searchParams, undefined, true),
        [http]
    );
};

/**
 * @function useDeleteChallengesApi
 * @returns { DeleteChallengesApiParams }
 */
 export const useDeleteChallengesApi = (): DeleteChallengesApiParams => {
    const http = useHttp();
    return useCallback(
        (challengeIds) =>
            http.delete('challenges/delete', challengeIds, true),
        [http]
    );
};

/**
 * @function useAssignChallengeLabelsApi
 * @returns { UpdateChallengeLabelsApiParams }
 */
export const useAssignChallengeLabelsApi = (): UpdateChallengeLabelsApiParams => {
    const http = useHttp();
    return useCallback(
        labels => http.post(`challenges/labels`, labels, true),
        [http]
    );
};

/**
 * @function useDeleteChallengeLabelsApi
 * @returns { UpdateChallengeLabelsApiParams }
 */
 export const useDeleteChallengeLabelsApi = (): UpdateChallengeLabelsApiParams => {
    const http = useHttp();
    return useCallback(
        labels => http.delete(`challenges/labels`, labels, true),
        [http]
    );
};

/**
 * @function useCreateChallengeApi
 * @returns { CreateChallengeApiParams }
 */
 export const useCreateChallengeApi = (): CreateChallengeApiParams => {
    const http = useHttp();
    return useCallback(
        challengeInfo => http.post(`challenges`, challengeInfo, true),
        [http]
    );
};

/**
 * @function useUpdateChallengeInfoApi
 * @returns { UpdateChallengeInfoApiParams }
 */
 export const useUpdateChallengeInfoApi = (): UpdateChallengeInfoApiParams => {
    const http = useHttp();
    return useCallback(
        (challengeId, challengeInfo) =>
            http.put(`challenges/${challengeId}`, challengeInfo, true),
        [http]
    );
};

/**
 * @function useUpdateChallengeStatusApi
 * @returns { UpdateChallengeStatusApiParams }
 */
 export function useUpdateChallengeStatusApi(): UpdateChallengeStatusApiParams {
    const http = useHttp();
    return useCallback(
        (status, challengeIds) =>
            http.put(`challenges/${status}`, challengeIds, true),
        [http]
    );
}

 /**
 * @function useAssignLinesToChallengeApi
 * @returns { AssignLinesToChallengeApiParams }
 */
  export function useAssignLinesToChallengeApi(): AssignLinesToChallengeApiParams {
    const http = useHttp();
    return useCallback(
        (challengeId, lines) => http.post(`challenges/${challengeId}/lines`, lines, true),
        [http]
    );
}