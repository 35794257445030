import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

interface Props {
    className?: string;
    onClick?(): void;
}

export const FiltersApplyButton: FC<Props> = props => {
    const { className, onClick } = props;
    return (
        <Root className={className}>
            <Button width="122px" action={onClick} dataTest="apply-button">
                Apply Filter
            </Button>
        </Root>
    );
};

const Root = styled.div`
    margin-left: 12px;
`;
