import React, { FC, useEffect } from 'react';

import {
    useDeselectAllLabels,
    useResetCollapseAllLabels,
    useResetSelectedLabels,
    useSaveLabels,
    useSelectAllLabels,
    useSelectLabels,
    useToggleCollapseAllLabels,
    useToggleCollapseLabel,
} from '../store/hors/withLabels/services';
import { useLabelsState } from '../store/hors/withLabels/states';
import { PortableLabels, PortableLabelsProps } from './PortableLabels';
import { usePortableLabelsState } from '../store/states';

type Props = Pick<
    PortableLabelsProps,
    | 'filtersTitle'
    | 'onSetSelected'
    | 'showSelectActions'
    | 'showSearch'
    | 'showSave'
> & {
    resetSelectedOnUnmount?: boolean;
};

export const PortableLabelsWithStore: FC<Props> = ({
    onSetSelected,
    resetSelectedOnUnmount = true,
    ...rest
}) => {
    const { selected, collapsed } = useLabelsState();
    const { data, loading } = usePortableLabelsState();
    const collapseLabelHandler = useToggleCollapseLabel();
    const collapseAllLabels = useToggleCollapseAllLabels();
    const resetCollapsedLAbels = useResetCollapseAllLabels()  
    const saveHandler = useSaveLabels();
    const select = useSelectLabels();
    const selectAll = useSelectAllLabels();
    const deselectAll = useDeselectAllLabels();
    const resetSelected = useResetSelectedLabels();

    useEffect(() => {
        return () => {
            if (resetSelectedOnUnmount) {
                resetSelected();
            }
        };
    }, [resetSelectedOnUnmount, resetSelected]);

    return (
        <PortableLabels
            labels={data}
            selectedList={selected}
            collapsedList={collapsed}
            loading={loading}
            onToggleCollapse={collapseLabelHandler}
            onToggleCollapseAll={collapseAllLabels}
            onClearSelected={() => {
                resetCollapsedLAbels();
                select([])
                onSetSelected && onSetSelected([])}}
            onSave={saveHandler}
            onSetSelected={ids => {
                select(ids);
                onSetSelected && onSetSelected(ids);
            }}
            onSelectAll={(selectedList?: number[]) => {
                selectAll(selectedList);
                onSetSelected &&
                    onSetSelected(data.items.map(label => label.id));
            }}
            onDeselectAll={() => {
                deselectAll();
                onSetSelected && onSetSelected([]);
            }}
            {...rest}
        />
    );
};
