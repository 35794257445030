import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const MenuItem = styled(motion.div)<{
    displayOver?: 'bottom' | 'over' | 'top';
}>`
    min-width: 150px;
    position: absolute;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    left: ${props => (props.displayOver === 'over' ? '16px' : '0')};
    right: ${props => (props.displayOver === 'over' ? '16px' : '0')};
    top:  ${props => (props.displayOver === 'top' ? 'unset' : '8px')};
    bottom:  ${props => (props.displayOver === 'top' ? '44px' : 'unset')};
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
`;

const ContentArea = styled.div`
    max-height: 300px;
    overflow-y: auto;
    padding: 16px;
`;

const ANIMATION_SCALE = 5;

export const SelectMenu: FC<{
    children?: ReactNode;
    displayOver?: 'bottom' | 'over' | 'top';
}> = ({ children, displayOver }) => {
    return (
        <MenuItem
            initial={{ scale: 1 / ANIMATION_SCALE }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            displayOver={displayOver}
        >
            <ContentArea className='content-area'>{children}</ContentArea>
        </MenuItem>
    );
};
