const SCROLL_BY = 100;

export const scrollLeft = (element?: any) => {
    if (element) {
        element.scrollLeft += SCROLL_BY;
    }
};

export const scrollRight = (element?: any) => {
    if (element) {
        element.scrollLeft -= SCROLL_BY;
    }
};
