import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

interface Props {
    disabled?: boolean;
    className?: string;
    onClick?(): void;
}

export const FiltersClearButton: FC<Props> = props => {
    const { disabled, className, onClick } = props;
    return (
        <Root className={className}>
            <Button
                disabled={disabled}
                width="122px"
                variant="inverse"
                action={onClick}
                dataTest="clear-button"
            >
                Clear
            </Button>
        </Root>
    );
};

const Root = styled.div`
    margin-left: 12px;
`;
