import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ExclamationCircle = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g
                        transform="translate(-686.000000, -405.000000)"
                        fill="currentColor"
                        fillRule="nonzero"
                    >
                        <g transform="translate(686.000000, 405.000000)">
                            <path
                                d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 Z"
                                id="Oval"
                            />
                            <polygon
                                id="Rectangle"
                                transform="translate(8.000000, 6.410256) rotate(-270.000000) translate(-8.000000, -6.410256) "
                                points="10.6666667 5.74358974 10.6666667 7.07692308 5.33333333 7.07692308 5.33333333 5.74358974"
                            />
                            <polygon
                                id="Rectangle"
                                transform="translate(8.000000, 11.076923) scale(-1, 1) rotate(270.000000) translate(-8.000000, -11.076923) "
                                points="8.66666667 10.4102564 8.66666667 11.7435897 7.33333333 11.7435897 7.33333333 10.4102564"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default ExclamationCircle;
