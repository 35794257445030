import React, { FC } from 'react';
import styled from 'styled-components';

export const FiltersPopupActions: FC = props => {
    const { children } = props;
    return (
        <Root>
            {children}
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 16px;
`;