import React, { FC } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';

const MenuItem = styled.div<{
    selected?: boolean;
    disabled?: boolean;
    destructive?: boolean;
}>`
    min-height: 40px;
    color: ${props =>
        !!props.disabled
            ? props.theme.Colors.cloudyBlue
            : !!props.destructive
            ? props.theme.Colors.tomato
            : props.theme.Colors.black};
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    word-break: break-word;
    background: ${props =>
        props.selected
            ? props.theme.Colors.whiteTwo
            : props.theme.Colors.white};
    cursor: ${props => (props.disabled ? `default` : `pointer`)};
    &:hover {
        ${props =>
            !props.disabled && `background: ${props.theme.Colors.whiteTwo};`}
    }
    &:active {
        ${props => !props.disabled && `opacity: 0.7;`}
    }
`;

export const SelectMenuItem: FC<{
    forwardedRef?: any;
    name?: string;
    value: string | number;
    children: string;
    onClick: any;
    hideAction: () => void;
    selected?: boolean;
    multiSelect?: boolean;
    disabled?: boolean;
    destructive?: boolean;
    hint?: string;
    dataTest?: string;
}> = ({
    children,
    onClick,
    value,
    name,
    hideAction,
    selected,
    forwardedRef,
    multiSelect,
    disabled,
    destructive,
    hint,
    dataTest,
}) => {
    const handleSelectItem = () => {
        if (disabled) return;
        onClick({ target: { value: value, name: name } });
        if (!multiSelect) hideAction();
    };

    return !!hint ? (
        <Tooltip label={hint} preset="button-icon">
            <MenuItem disabled={disabled} destructive={destructive} data-test={dataTest}>
                {children}
            </MenuItem>
        </Tooltip>
    ) : (
        <MenuItem
            onClick={handleSelectItem}
            selected={selected}
            disabled={disabled}
            destructive={destructive}
            ref={forwardedRef}
            data-test={dataTest}
        >
            {children}
        </MenuItem>
    );
};
