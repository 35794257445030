import { FlagToggleViewProps } from './types';
import { FlagHandle, FlagActiveIcon, FlagInactiveIcon } from './styles';

function FlagToggleView ({
    checked,
    onChange,
    dataTest
}: FlagToggleViewProps) {
    return <FlagHandle
        onClick={onChange}
        data-test={dataTest}
    >
        {
            checked
                ? <FlagActiveIcon />
                : <FlagInactiveIcon />
        }
    </FlagHandle>
}

export default FlagToggleView;