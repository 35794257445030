import styled from 'styled-components';

export const StyledAudioPlayer = styled.div<{ color: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
`;

export const StyledActionPanel = styled.div`
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
`;

export const StyledActionButtons = styled.div`
    display: flex;
`;

export const StyledButton = styled.div`
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:last-child {
        margin-left: 8px;
    }
`;

export const AudioDuration = styled.div`
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
`;

export const StyledAudioWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 4px 0;
`;

export const StyledAudioLine = styled.div`
    width: 100%;
    height: 8px;
    border-radius: 12px;
    border: solid 1px ${props => props.theme.Colors.cloudyBlue};
    background: ${props => props.theme.Colors.whiteFive};
    cursor: pointer;
    position: relative;
`;

export const StyledLine = styled.div<{ active: number | null }>`
    width: ${props => (props.active ? `${props.active}%` : 0)};
    max-width: 100%;
    position: absolute;
    top: -1px;
    bottom: -1px;
    border-radius: 12px;
    border: solid 1px ${props => props.theme.Colors.darkSkyBlue};
    background: ${props => props.theme.Colors.darkSkyBlueTwo15Full};
`;
