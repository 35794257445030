import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    handleMessage,
    useShowMessage,
} from '../../../ui/components/ErrorMessages/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { SearchParams } from '../../../constants/interfaces/filters';
import {
    modifyTeamName,
    resetSingleTeamPage,
    searchSingleTeamAllUsersFailure,
    searchSingleTeamAllUsersStart,
    searchSingleTeamAllUsersSuccess,
    searchSingleTeamFailure,
    searchSingleTeamMembersFailure,
    searchSingleTeamMembersStart,
    searchSingleTeamMembersSuccess,
    searchSingleTeamStart,
    searchSingleTeamSuccess,
    searchTeamPractisSetsFailure,
    searchTeamPractisSetsStart,
    searchTeamPractisSetsSuccess,
    updateAllSelectedTeamPractisSetsState,
    updateAllSelectedTeamsState,
    updateSelectedTeamPractisSetState,
    updateSelectedTeamState,
    createSingleTeamSuccess,
    updateSingleTeamFailure,
    updateSingleTeamStart,
    updateSingleTeamSuccess,
    updateTeamActionFailure,
    updateTeamActionStart,
    fetchAllTeamsStart,
    fetchAllTeamsFailure,
    fetchAllTeamsSuccess,
    updateTeamActionSuccess,
    checkAllProgress,
    checkSingleProgress,
    clearLabelFilterAction,
    getPractisSetFailure,
    getPractisSetReportFailure,
    getPractisSetReportStart,
    getPractisSetReportSuccess,
    getPractisSetStart,
    getPractisSetSuccess,
    resetPractisSetReport,
    saveSelectedInvitationLabels,
    searchInvitationUsersFailure,
    searchInvitationUsersStart,
    searchInvitationUsersSuccess,
    searchPractisUsersFailure,
    searchPractisUsersStart,
    searchPractisUsersSuccess,
    searchTrainerFailure,
    searchTrainerStart,
    searchTrainerSuccess,
    sendTraineeInvitationFailure,
    sendTraineeInvitationStart,
    sendTraineeInvitationSuccess,
    setLabelFilterAction,
    toggleCollapseLabelFieldAction,
    updateAllSelectedInvitationTraineesState,
    updateAllSelectedPractisSetsState,
    updatedProgressActionSuccess,
    updateSelectedInvitationTraineesState,
    updateSelectedPractisSetState,
    searchTeamMembersStartAction,
    searchTeamMembersSuccessAction,
    searchTeamMembersFailureAction,
    updateMemberActionSuccess,
    setDeletedLabelsToSingleTeam,
    setAssignedLabelsToSingleTeam,
    searchTeamLeadersStartAction,
    searchTeamLeadersSuccessAction,
    searchTeamLeadersFailureAction,
    selectTeamLeaderAction,
    selectMultipleTeamLeaderAction,
    saveTeamLeaderSelection,
    resetTeamLeadersSelectionAction,
    getTeamInfoStart,
    getTeamInfoSuccess,
    getTeamInfoFailure,
    getTeamInfoTrainingStart,
    getTeamInfoTrainingSuccess,
    getTeamInfoTrainingFailure,
    resetTeamInfo,
    deselectAllTeamLeadersSelectionAction,
    resetPractisSetDetails,
    removeSingleTeamAllUsersLabel,
    removeSingleTeamMemberLabel,
    updateTeamLead,
    clearTrainerPractisSet,
} from './actions';
import {
    useDeleteTeamsApi,
    useGetSingleTeamApi,
    useCreateNewTeamApi,
    useUpdateTeamNameApi,
    useSearchTeamsApi,
    useGetPractisSetsReportApi,
    useMarkTeamAsViewedApi,
    useSearchTeamMembersApi,
    useGetMembersReportApi,
    useSearchTeamLeadersApi,
    useSearchTrainingPractisSetsApi,
    useSearchPractisSetDetailsUsersApi,
    useNudgeUsersApi,
    useDeleteUserLabelsApi,
    useDeletePractisSetLabelsApi,
    useUpdateTeamLeaderApi,
    useDeleteLabelsFromTeamsApi,
    useGetEnrollmentsReportApi,
    useEnrollPractisSetsToUserApi,
    useRemindToStartApi,
    useSearchUsersApi,
    useDeleteEnrollmentsApi,
    useGetEnrollmentApi,
    useGetEnrollmentProgressApi,
    useGetEnrollmentDailyTrainingApi,
} from '../../../api';
import { EnrollmentType } from '../../../api/enrollments/types';
import { Team, TeamV2 } from '../../../constants/interfaces/Team';
import { setAssignLabelsAction } from '../../labels/store/actions';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { fileDownload } from '../../../helpers/functions/file-download';
import { Dispatch } from 'redux';
import { ThunkContext } from '../../../tools/thunk/ThunkContext';
import {
    ListResult,
    PaginationResult,
} from '../../../constants/interfaces/PaginationResult';
import { useGetPractisSetApi } from '../../../api';
import { SEARCH_STATE } from '../../../features/searchState/constants';
import {
    setSearchState,
    setSecondarySearchState,
} from '../../../features/searchState/store/actions';
import {
    SearchTrainingPractisSetsParams,
    ReportTrainingPractisSetsParams,
    ReportMembersParams,
    SearchTeamParams,
} from '../../../api/teams/types';
import { usePortableLabelsState } from '../../../features/portableLabels/store/states';
import { findAllChildIds } from '../../../helpers/functions/tree-helpers';
import {
    createSearchTeamMembersParams,
    createSearchTeamsParams,
    createSearchPractisSetDetailsParams,
} from '../tools';
import { CLIENT_SIDE_ERROR_MESSAGE } from '../../../ui/components/ErrorMessages/constants';
import {
    EnrollmentDailyTraining,
    EnrollmentInterface,
    EnrollmentProgress,
    Enrollments,
} from '../../../constants/interfaces/Enrollments';
import { getSearchSortingValue } from '../../../helpers/functions/search-params-helpers';
import { NudgeData, RemindToStartEnrollment } from '../../../api/alert/types';
import { ENROLLMENT_REMIND_TO_START } from '../../../api/alert/constants';
import { SearchUsersParams } from '../../../api/users/types';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { UserStatus } from '../../users/store/costants';

const uuid = require('uuid/v1');

export const useGetTeamInfoService = () => {
    const dispatch = useDispatch();
    const getSingleTeam = useGetSingleTeamApi();
    const showMessage = useShowMessage();
    return useCallback(
        (draftId: number) => {
            dispatch(getTeamInfoStart());
            getSingleTeam(draftId)
                .then(data => {
                    dispatch(getTeamInfoSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(getTeamInfoFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, getSingleTeam, showMessage]
    );
};

export const useResetTeamInfoService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetTeamInfo());
    }, [dispatch]);
};

export const useGetTeamInfoTrainingService = () => {
    const dispatch = useDispatch();
    const getSingleTeam = useGetSingleTeamApi();
    const showMessage = useShowMessage();
    return useCallback(
        (draftId: number) => {
            dispatch(getTeamInfoTrainingStart());
            getSingleTeam(draftId)
                .then(data => {
                    dispatch(getTeamInfoTrainingSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(getTeamInfoTrainingFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, getSingleTeam, showMessage]
    );
};

export const useGetSingleTeamService = () => {
    const dispatch = useDispatch();
    const getSingleTeam = useGetSingleTeamApi();
    const showMessage = useShowMessage();
    return useCallback(
        (draftId: number) => {
            dispatch(searchSingleTeamStart());
            getSingleTeam(draftId)
                .then(data => {
                    dispatch(searchSingleTeamSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchSingleTeamFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, getSingleTeam, showMessage]
    );
};

export const useSearchTeamsAllUsersService = () => {
    const dispatch = useDispatch();
    const searchTeamsAllUsers = useSearchUsersApi();
    const profile = useSelector(getProfileState);
    const showMessage = useShowMessage();

    return useCallback(
        (searchParams: SearchParams, isRefreshed?: boolean) => {
            if (!profile?.companyId) return;
            const searchAllUsersParams: SearchUsersParams = {
                status:
                    searchParams.filters?.reduce((statuses, filter) => {
                        if (filter.field === 'status') {
                            statuses = String(filter.value);
                        }

                        return statuses;
                    }, '') || `${UserStatus.ACTIVE},${UserStatus.PENDING}`,
                labels: searchParams.labelIDs?.join(','),
                roles: searchParams.roleIDs?.join(','),
                teams: searchParams.teamIds?.join(','),
                companies: profile?.companyId?.toString(),
                limit: searchParams.limit ?? 20,
                offset: searchParams.offset,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
                excludeTeamId: searchParams.teamId?.toString(),
            };
            dispatch(searchSingleTeamAllUsersStart());
            searchTeamsAllUsers(searchAllUsersParams)
                .then(data => {
                    dispatch(
                        searchSingleTeamAllUsersSuccess(
                            data,
                            searchParams.offset,
                            isRefreshed
                        )
                    );
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchSingleTeamAllUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, profile?.companyId, searchTeamsAllUsers, showMessage]
    );
};

export const useSearchSingleTeamMembersService = (teamId: number | string) => {
    const dispatch = useDispatch();
    const searchTeamMembersApi = useSearchTeamMembersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp: SearchParams, isRefreshed?: boolean) => {
            dispatch(searchSingleTeamMembersStart());
            searchTeamMembersApi(teamId, createSearchTeamMembersParams(sp))
                .then(data => {
                    dispatch(
                        searchSingleTeamMembersSuccess(
                            data,
                            sp.offset,
                            isRefreshed
                        )
                    );
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchSingleTeamMembersFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, searchTeamMembersApi, teamId, showMessage]
    );
};

export const useTogglePromoteTeamLeader = () => {
    const updateTeamLeaderApi = useUpdateTeamLeaderApi();
    const dispatch = useDispatch();
    return useCallback(
        (teamUserId: number, isTeamLead: boolean) => {
            dispatch(updateSingleTeamStart());
            updateTeamLeaderApi(teamUserId, isTeamLead)
                .then(() => {
                    dispatch(updateTeamLead(teamUserId, isTeamLead));
                    dispatch(updateSingleTeamSuccess());
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateSingleTeamFailure(error.message));
                });
        },
        [dispatch, updateTeamLeaderApi]
    );
};

export const useSetAssignedLabelsToSingleTeam = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();

    return useCallback(
        (labelIDs: number[]) => {
            dispatch(setAssignedLabelsToSingleTeam(labelIDs));
            showMessage('Labels have been assigned to the team', 'success');
        },
        [dispatch, showMessage]
    );
};

export const useSetDeletedLabelsToSingleTeam = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelIDs: number[]) => {
            dispatch(setDeletedLabelsToSingleTeam(labelIDs));
        },
        [dispatch]
    );
};

export const useResetSingleTeamPageService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetSingleTeamPage());
    }, [dispatch]);
};

export const useCreateNewTeamService = () => {
    const dispatch = useDispatch();
    const createNewTeamApi = useCreateNewTeamApi();
    const showMessage = useShowMessage();
    return useCallback(
        (name: string): Promise<TeamV2> => {
            return createNewTeamApi(name)
                .then(data => {
                    dispatch(createSingleTeamSuccess(data));
                    showMessage('New team has been created', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, createNewTeamApi, showMessage]
    );
};

/**
 * @description updates team name
 * @function useUpdateTeamNameService
 * @param { string | number } teamId
 * @param { string } name
 * @returns { Promise }
 */
export const useUpdateTeamNameService = (teamId: string | number) => {
    const dispatch = useDispatch();
    const updateTeamNameApi = useUpdateTeamNameApi();
    const showMessage = useShowMessage();
    return useCallback(
        (name: string): Promise<void> => {
            dispatch(updateSingleTeamStart());
            return updateTeamNameApi(teamId, name)
                .then(() => {
                    dispatch(updateSingleTeamSuccess({ id: Number(teamId) }));
                    dispatch(modifyTeamName(name));
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateSingleTeamFailure(error.message));
                    error.message &&
                        showMessage(
                            CLIENT_SIDE_ERROR_MESSAGE[error.message],
                            'error'
                        );
                    throw error;
                });
        },
        [teamId, dispatch, updateTeamNameApi, showMessage]
    );
};

export const useSearchTeamsService = (storeSearchParams: boolean = false) => {
    const dispatch = useDispatch();
    const searchTeamsApi = useSearchTeamsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p: SearchParams) => {
            dispatch(fetchAllTeamsStart());
            const params: SearchTeamParams = createSearchTeamsParams(p);

            searchTeamsApi(params)
                .then(data => {
                    if (storeSearchParams) {
                        dispatch(
                            setSecondarySearchState(SEARCH_STATE.TEAMS.name, p)
                        );
                    }
                    dispatch(fetchAllTeamsSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchAllTeamsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchTeamsApi, showMessage, storeSearchParams]
    );
};

export const searchTeamPractisSets = (sp: SearchParams) => {
    return (dispatch: Dispatch<any>, getState: any, context: ThunkContext) => {
        dispatch(searchTeamPractisSetsStart());
        return context.http
            .post('teams/practisSets/search', sp)
            .then((data: PaginationResult<PractisSets>) => {
                dispatch(searchTeamPractisSetsSuccess(data));
                return data;
            })
            .catch((error: ErrorResult) => {
                dispatch(searchTeamPractisSetsFailure(error.message));
                handleMessage(dispatch, error.message, 'error');
                return 'error';
            });
    };
};

/**
 * @description deletes team member label including children labels
 * @function useDeleteMemberLabelService
 * @param { string | number } teamId
 * @param { number } userId
 * @param { number } labelId
 * @returns { void }
 */
export const useDeleteMemberLabelService = (teamId: string | number) => {
    const dispatch = useDispatch();
    const deleteUserLabelsApi = useDeleteUserLabelsApi();
    const showMessage = useShowMessage();
    const labels = usePortableLabelsState();
    return useCallback(
        (userId: number, labelId: number) => {
            const payload: { userId: number; labelId: number }[] = [
                { userId, labelId },
            ];
            const children: any[] = [];
            findAllChildIds(labels.data.items, labelId, children);
            if (children.length > 0)
                children.forEach(item =>
                    payload.push({ userId, labelId: item.id })
                );
            return deleteUserLabelsApi(payload)
                .then(() => {
                    dispatch(
                        updateMemberActionSuccess(
                            { id: teamId } as Team,
                            'labels'
                        )
                    );
                    showMessage('Label removed successfully', 'success');
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [teamId, labels, showMessage, deleteUserLabelsApi, dispatch]
    );
};

/**
 * @description deletes a label of user who can be assigned to a team
 * @function useDeleteSingleTeamAllUsersLabelService
 * @param { string | number } teamId
 * @param { number } userId
 * @param { number } labelId
 * @returns { void }
 */
export const useDeleteSingleTeamAllUsersLabelService = (
    teamId: string | number
) => {
    const dispatch = useDispatch();
    const deleteMemberLabelService = useDeleteMemberLabelService(teamId);
    return useCallback(
        (userId: number, labelId: number) => {
            dispatch(updateSingleTeamStart());
            deleteMemberLabelService(userId, labelId)
                .then(() => {
                    dispatch(removeSingleTeamAllUsersLabel(userId, labelId));
                    dispatch(updateSingleTeamSuccess());
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateSingleTeamFailure(error.message));
                });
        },
        [dispatch, deleteMemberLabelService]
    );
};

/**
 * @description deletes a label of a team member
 * @function useDeleteSingleTeamMemberLabelService
 * @param { string | number } teamId
 * @param { number } userId
 * @param { number } labelId
 * @returns { void }
 */
export const useDeleteSingleTeamMemberLabelService = (
    teamId: string | number
) => {
    const dispatch = useDispatch();
    const deleteMemberLabelService = useDeleteMemberLabelService(teamId);
    return useCallback(
        (userId: number, labelId: number) => {
            dispatch(updateSingleTeamStart());
            deleteMemberLabelService(userId, labelId)
                .then(() => {
                    dispatch(removeSingleTeamMemberLabel(userId, labelId));
                    dispatch(updateSingleTeamSuccess());
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateSingleTeamFailure(error.message));
                });
        },
        [dispatch, deleteMemberLabelService]
    );
};

export const useDeleteLabelFromTeamService = () => {
    const dispatch = useDispatch();
    const deleteLabelFromTeamApi = useDeleteLabelsFromTeamsApi();
    const showMessage = useShowMessage();

    return useCallback(
        (teamRemovedLabels, teamId) => {
            deleteLabelFromTeamApi(teamRemovedLabels)
                .then(() => {
                    dispatch(
                        updateTeamActionSuccess(
                            { id: teamId } as Team,
                            'labels'
                        )
                    );
                    showMessage(`Label removed successfully`, 'success');
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [showMessage, deleteLabelFromTeamApi, dispatch]
    );
};

export const useSetAssignLabelsActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labels: number[]) => {
            dispatch(setAssignLabelsAction(labels));
        },
        [dispatch]
    );
};

export const useUpdateAllSelectedTeamsStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (teamIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(updateAllSelectedTeamsState(teamIds, checked, partial));
        },
        [dispatch]
    );
};

export const useUpdateSelectedTeamStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (teamId: number) => {
            dispatch(updateSelectedTeamState(teamId));
        },
        [dispatch]
    );
};

export const useUpdateAllTeamSelectedPractisSetsStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (setIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllSelectedTeamPractisSetsState(setIds, checked, partial)
            );
        },
        [dispatch]
    );
};

export const useUpdateTeamSelectedPractisSetStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (setId: number) => {
            dispatch(updateSelectedTeamPractisSetState(setId));
        },
        [dispatch]
    );
};


export const useDeleteTeamsService = () => {
    const dispatch = useDispatch();
    const deleteTeamsApi = useDeleteTeamsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (teamIds: number, successCallback?: () => void) => {
            dispatch(updateTeamActionStart());
            return deleteTeamsApi([teamIds])
                .then((data: any) => {
                    dispatch(updateTeamActionSuccess({ id: uuid() }, 'delete'));

                    showMessage('Team has been deleted', 'success');
                    successCallback?.();
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateTeamActionFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, deleteTeamsApi, showMessage]
    );
};

export const useSearchTrainingPractisSets = (teamId: string) => {
    const dispatch = useDispatch();
    const searchTrainingPractisSets = useSearchTrainingPractisSetsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp: SearchParams) => {
            dispatch(searchTrainerStart());
            const overdueFilter = sp.filters?.find(
                f => f.field === 'hasOverdue'
            );
            const params: SearchTrainingPractisSetsParams = {
                enrollmentStatus: sp.filterByStatus?.join(','),
                isOverdue: overdueFilter?.value ? true : undefined,
                labels: sp.labelIDs?.join(','),
                limit: sp.limit ?? 20,
                offset: sp.offset ?? 0,
                query: sp.searchTerm,
                sort: getSearchSortingValue(sp.orderBy, 'name', true),
            };
            return searchTrainingPractisSets(teamId, params)
                .then((data: any) => {
                    dispatch(
                        setSearchState(
                            SEARCH_STATE.TRAINING.name,
                            SEARCH_STATE.TRAINING.childrenKeys,
                            sp
                        )
                    );
                    dispatch(searchTrainerSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchTrainerFailure(error.message));
                    showMessage(error.message, 'error');
                    return 'error';
                });
        },
        [dispatch, searchTrainingPractisSets, showMessage, teamId]
    );
};


export const useClearTrainerPractisSet = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(clearTrainerPractisSet());
    }, [dispatch]);
};

export const useSearchPractisSetDetailsUsersService = (
    teamId: string,
    practisSetId: any
) => {
    const dispatch = useDispatch();
    const searchPractisSetDetailsUsers = useSearchPractisSetDetailsUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp: SearchParams) => {
            const params = createSearchPractisSetDetailsParams(sp);
            dispatch(searchPractisUsersStart());
            return searchPractisSetDetailsUsers(teamId, practisSetId, params)
                .then((data: PaginationResult<EnrollmentInterface>) => {
                    dispatch(
                        setSearchState(
                            SEARCH_STATE.PRACTIS_SET_DETAILS.name,
                            SEARCH_STATE.PRACTIS_SET_DETAILS.childrenKeys,
                            sp
                        )
                    );
                    dispatch(searchPractisUsersSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchPractisUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return 'error';
                });
        },
        [
            dispatch,
            practisSetId,
            searchPractisSetDetailsUsers,
            showMessage,
            teamId,
        ]
    );
};

export const getAssignUsersSearchParams = (searchParams: SearchParams) => {
    return {
        status: (searchParams.filterByRegistrationStatus ?? [UserStatus.ACTIVE, UserStatus.PENDING]).join(','),
        labels: searchParams.labelIDs?.join(','),
        teams: searchParams.teamId?.toString(),
        limit: searchParams.limit ?? 20,
        offset: searchParams.offset ?? 0,
        query: searchParams.searchTerm,
        sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
        excludePSets: searchParams.practisSetId?.toString(),
        companies: searchParams.companyId?.toString(),
    } as SearchUsersParams;
};

export const useSearchAssignUsersService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const searchAssignUsers = useSearchUsersApi();

    return useCallback(
        (searchParams: SearchParams, isRefreshed?: boolean) => {
            dispatch(searchInvitationUsersStart());
            const params = getAssignUsersSearchParams(searchParams);
            return searchAssignUsers(params)
                .then(data => {
                    dispatch(
                        searchInvitationUsersSuccess(
                            data,
                            params.offset,
                            isRefreshed
                        )
                    );
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchInvitationUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, searchAssignUsers, showMessage]
    );
};

export const useGetPractisSetService = () => {
    const dispatch = useDispatch();
    const getPractisSet = useGetPractisSetApi();
    return useCallback(
        (practisSetId: number) => {
            dispatch(getPractisSetStart());
            return getPractisSet(practisSetId)
                .then(data => {
                    dispatch(getPractisSetSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(getPractisSetFailure(error.message));
                    throw error;
                });
        },
        [dispatch, getPractisSet]
    );
};

export const useResetPractisSetDetailsService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetPractisSetDetails());
    }, [dispatch]);
};

export const useEnrollPractisSetsToUsersService = () => {
    const createInvitationApi = useEnrollPractisSetsToUserApi();
    const showMessage = useShowMessage();
    return useCallback(
        async (enrollments: EnrollmentType[]) => {
            try {
                await createInvitationApi(enrollments);
            } catch (error: any) {
                showMessage(error?.message, 'error');
                throw error;
            }
            showMessage(
                `${enrollments.length} Users ${
                    enrollments.length > 1 ? 'have' : 'has'
                } been assigned`,
                'success'
            );
        },
        [createInvitationApi, showMessage]
    );
};

export const useRemindToStartService = () => {
    const dispatch = useDispatch();
    const remindToStart = useRemindToStartApi();
    const showMessage = useShowMessage();
    return useCallback(
        (enrollmentIds: number[]) => {
            dispatch(sendTraineeInvitationStart());

            const enrollments = enrollmentIds.map(
                enrollmentId =>
                    ({
                        enrollmentId,
                        type: ENROLLMENT_REMIND_TO_START,
                    } as RemindToStartEnrollment)
            );

            return remindToStart(enrollments)
                .then(data => {
                    if (enrollments.length === 1) {
                        dispatch(
                            sendTraineeInvitationSuccess(
                                enrollments as unknown as Enrollments,
                                'reInvite'
                            )
                        );
                        showMessage(`Reminder Sent to User`, 'success');
                    }
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(sendTraineeInvitationFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, remindToStart, showMessage]
    );
};

export const usePractisSetReportService = () => {
    const dispatch = useDispatch();
    const getEnrollmentApi = useGetEnrollmentApi();
    const getEnrollmentProgressApi = useGetEnrollmentProgressApi();
    const getEnrollmentDailyTrainingApi = useGetEnrollmentDailyTrainingApi();
    const showMessage = useShowMessage();
    return useCallback(
        (enrollmentId: number) => {
            dispatch(getPractisSetReportStart());
            return Promise.all([
                getEnrollmentApi(enrollmentId),
                getEnrollmentProgressApi(enrollmentId),
                getEnrollmentDailyTrainingApi(enrollmentId),
            ])
                .then(data => {
                    const enrollment = data[0] as EnrollmentInterface;
                    const progress = data[1] as ListResult<EnrollmentProgress>;
                    const dailyTraining = data[2] as EnrollmentDailyTraining[];
                    dispatch(
                        getPractisSetReportSuccess(
                            enrollment,
                            progress,
                            dailyTraining
                        )
                    );
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(getPractisSetReportFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [
            dispatch,
            getEnrollmentApi,
            getEnrollmentProgressApi,
            getEnrollmentDailyTrainingApi,
            showMessage,
        ]
    );
};

export const useResetPractisSetReportService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetPractisSetReport());
    }, [dispatch]);
};

export const useCheckAllProgressService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (progressIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(checkAllProgress(progressIds, checked, partial));
        },
        [dispatch]
    );
};

export const useCheckSingleProgressService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (progressId: number) => {
            dispatch(checkSingleProgress(progressId));
        },
        [dispatch]
    );
};

/**
 * @description deletes team practis set label including children labels
 * @function useDeleteTeamPractisSetLabelService
 * @param { number } practisSetId
 * @param { number } labelId
 * @returns { void }
 */
export const useDeleteTeamPractisSetLabelService = () => {
    const dispatch = useDispatch();
    const deletePractisSetLabelsApi = useDeletePractisSetLabelsApi();
    const showMessage = useShowMessage();
    const labels = usePortableLabelsState();
    return useCallback(
        (practisSetId: number, labelId: number) => {
            const payload: { practisSetId: number; labelId: number }[] = [
                { practisSetId, labelId },
            ];
            const children: any[] = [];
            findAllChildIds(labels.data.items, labelId, children);
            if (children.length > 0)
                children.forEach(item =>
                    payload.push({ practisSetId, labelId: item.id })
                );
            deletePractisSetLabelsApi(payload)
                .then(() => {
                    dispatch(
                        updatedProgressActionSuccess(
                            { id: practisSetId } as PractisSets,
                            'labels'
                        )
                    );
                    showMessage('Label removed successfully', 'success');
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [labels, showMessage, deletePractisSetLabelsApi, dispatch]
    );
};

export const useUpdateAllSelectedInvitationTraineesStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (userIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllSelectedInvitationTraineesState(
                    userIds,
                    checked,
                    partial
                )
            );
        },
        [dispatch]
    );
};

export const useUpdateSelectedInvitationTraineesStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (userId: number) => {
            dispatch(updateSelectedInvitationTraineesState(userId));
        },
        [dispatch]
    );
};

export const useNudgeUsersService = () => {
    const nudgeUsersApi = useNudgeUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (nudgeUserData: NudgeData[], successCallback?: () => void) => {
            nudgeUsersApi(nudgeUserData)
                .then(() => {
                    showMessage(`Message was sent successfully`, 'success');
                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [nudgeUsersApi, showMessage]
    );
};

export const useToggleCollapseLabelFieldService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelId: number) => {
            dispatch(toggleCollapseLabelFieldAction(labelId));
        },
        [dispatch]
    );
};

export const useSetLabelFilterActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labels: number[]) => {
            dispatch(setLabelFilterAction(labels));
        },
        [dispatch]
    );
};

export const useClearLabelFilterActionService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearLabelFilterAction());
    }, [dispatch]);
};

export const useSaveSelectedInvitationLabelsService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(saveSelectedInvitationLabels());
    }, [dispatch]);
};

export const useUnenrollEnrollmentService = () => {
    const unenrollEnrollmentApi = useDeleteEnrollmentsApi();
    const showMessage = useShowMessage();

    return useCallback(
        (enrollmentIds: number[], successCallback?: () => void) => {
            return unenrollEnrollmentApi(enrollmentIds)
                .then(() => {
                    showMessage(
                        `${
                            enrollmentIds.length > 1 ? 'Users have' : 'User has'
                        }  been unassigned`,
                        'success'
                    );
                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [unenrollEnrollmentApi, showMessage]
    );
};

export const useGetMembersReportService = () => {
    const getMembersReportApi = useGetMembersReportApi();
    const showMessage = useShowMessage();
    return useCallback(
        (userIds: number[] | null, teamId: string, sp: SearchParams) => {
            const overdueFilter = sp.filters?.find(
                f => f.field === 'hasOverdue'
            );
            const params: ReportMembersParams = {
                userStatus: sp.filterByRegistrationStatus?.join(','),
                enrollmentStatus: sp.filterByStatus?.join(','),
                isOverdue: overdueFilter?.value ? true : undefined,
                labels: sp.labelIDs?.join(','),
                limit: userIds?.length ? undefined : sp.limit ?? 20,
                offset: sp.offset ?? 0,
                query: sp.searchTerm,
                sort: getSearchSortingValue(sp.orderBy, 'name', true),
                users: userIds?.join(','),
            };
            return getMembersReportApi(teamId, params)
                .then((data: any) => {
                    fileDownload(data, 'report.csv');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [getMembersReportApi, showMessage]
    );
};

export const useGetTraineesReportService = () => {
    const getEnrollmentsReportApi = useGetEnrollmentsReportApi();
    const showMessage = useShowMessage();
    return useCallback(
        (
            enrollmentIds: number[] | null,
            practisSetId: number,
            search: SearchParams,
            teamId: number
        ) => {
            const params = {
                ...createSearchPractisSetDetailsParams(search),
                enrollments: enrollmentIds?.join(','),
                limit: undefined,
                offset: undefined,
            };
            return getEnrollmentsReportApi(params, teamId, practisSetId)
                .then((data: any) => {
                    fileDownload(data, 'report.xlsx');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [getEnrollmentsReportApi, showMessage]
    );
};

export const useGetPractisSetsReportService = () => {
    const getPractisSetsReportApi = useGetPractisSetsReportApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp: SearchParams, practisSetIds: number[] | null, teamId: string) => {
            const overdueFilter = sp.filters?.find(
                f => f.field === 'hasOverdue'
            );
            const params: ReportTrainingPractisSetsParams = {
                enrollmentStatus: sp.filterByStatus?.join(','),
                isOverdue: overdueFilter?.value ? true : undefined,
                labels: sp.labelIDs?.join(','),
                limit: practisSetIds?.length ? undefined : sp.limit ?? 20,
                offset: sp.offset ?? 0,
                query: sp.searchTerm,
                sort: getSearchSortingValue(sp.orderBy, 'name', true),
                psets: practisSetIds?.join(','),
            };
            return getPractisSetsReportApi(params, teamId)
                .then((data: any) => {
                    fileDownload(data, 'practis-set-reports.csv');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [getPractisSetsReportApi, showMessage]
    );
};

export const useUpdateAllSelectedPractisSetsStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (setIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllSelectedPractisSetsState(setIds, checked, partial)
            );
        },
        [dispatch]
    );
};

export const useUpdateSelectedPractisSetStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (setId: number) => {
            dispatch(updateSelectedPractisSetState(setId));
        },
        [dispatch]
    );
};

export const useMarkTeamAsViewedService = () => {
    const dispatch = useDispatch();
    const markTeamAsViewedApi = useMarkTeamAsViewedApi();
    const showMessage = useShowMessage();
    return useCallback(
        (teamId: any) => {
            markTeamAsViewedApi(teamId)
                .then(() => {
                    dispatch(
                        updateTeamActionSuccess(
                            { id: teamId } as Team,
                            'update'
                        )
                    );
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [showMessage, markTeamAsViewedApi, dispatch]
    );
};

export const useSearchTeamMembersService = (teamId: number | string) => {
    const teamMembersApi = useSearchTeamMembersApi();
    const dispatch = useDispatch();

    return useCallback(
        (sp: SearchParams) => {
            dispatch(searchTeamMembersStartAction());
            teamMembersApi(teamId, createSearchTeamMembersParams(sp))
                .then(data => {
                    dispatch(
                        setSearchState(
                            SEARCH_STATE.MEMBERS.name,
                            SEARCH_STATE.MEMBERS.childrenKeys,
                            sp
                        )
                    );
                    dispatch(searchTeamMembersSuccessAction(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchTeamMembersFailureAction(error.message));
                });
        },
        [teamMembersApi, teamId, dispatch]
    );
};

export const useSearchTeamsLeadersService = () => {
    const dispatch = useDispatch();
    const searchTeamLeadersApi = useSearchTeamLeadersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp?: SearchParams) => {
            dispatch(searchTeamLeadersStartAction());
            searchTeamLeadersApi(sp)
                .then(data => {
                    dispatch(searchTeamLeadersSuccessAction(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchTeamLeadersFailureAction(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, searchTeamLeadersApi, showMessage]
    );
};

export const useSelectTeamLeaderService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (leaderId: number) => {
            dispatch(selectTeamLeaderAction(leaderId));
        },
        [dispatch]
    );
};

export const useSelectMultipleTeamLeadersService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (leaderId: number[]) => {
            dispatch(selectMultipleTeamLeaderAction(leaderId));
        },
        [dispatch]
    );
};

export const useResetTeamLeadersSelection = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetTeamLeadersSelectionAction());
    }, [dispatch]);
};

export const useDeselectAllTeamLeadersSelection = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(deselectAllTeamLeadersSelectionAction());
    }, [dispatch]);
};

export const useSaveTeamLeaderSelection = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(saveTeamLeaderSelection());
    }, [dispatch]);
};