import { UserInterface } from '../../../../constants/interfaces/User';
import { Dispatch } from 'redux';
import { ThunkContext } from '../../../../tools/thunk/ThunkContext';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { useCallback } from 'react';
import {
    useAddStagingUsersApi,
    useAssignFiltersToStagingUsersApi,
    useCreateUserStagingApi,
    useDeleteStagingUsersApi,
    useInviteStagingUsersApi,
    useSearchStagingUsersApi,
    useUpdateStagingUsersApi,
} from '../../../../api';
import { NewUserValues } from '../models/NewUserValues';
import { DraftUser, PractisSetWithDueDate } from '../../../../constants/interfaces/Draft';
import { useDispatch } from 'react-redux';
import { SearchParams } from '../../../../constants/interfaces/filters';
import { PaginationResult } from '../../../../constants/interfaces/PaginationResult';
import {
    modifyDraftUsersAction,
    modifyDraftUsersWithoutValidationAction,
    resetDraftsPageAction,
    searchDraftUsersFailure,
    searchDraftUsersStart,
    searchDraftUsersSuccess,
    updateDraftUsersFailure,
    updateDraftUsersStart,
    updateDraftUsersSuccess,
} from './actions';
import { SocketProgressShow } from '../../../../features/socketProgress(api1)/store/actions';
import { AssignFiltersToStagingUsersParams } from '../../../../api/users/types';
import { handleMessage, useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';

const uuid = require('uuid/v1');
export const MAX_DRAFT_USERS_INVITE = 10;
export interface SendInvitationParams {
    id?: number | string;
    email: string;
    firstName: string;
    lastName: string;
    roleId?: number | null;
    labelIDs?: any[];
    practisSetIDs?: PractisSetWithDueDate[];
    teamIDs?: any[];
}

export const sendInvitation = (
    data: DraftUser[],
    assignedPractisSets?: number[]
) => {
    return (dispatch: Dispatch<any>, getState: any, context: ThunkContext) => {
        return context.http
            .post('invitations', {
                items: data,
                practisSets: [...(assignedPractisSets || [])],
            })
            .then((res: any) => {
                let sentEmails: Array<UserInterface> = [];
                let failedEmails: Array<string> = [];
                res.map((response: any) => {
                    if (response.error) {
                        return failedEmails.push(response.error.message);
                    } else {
                        return sentEmails.push(response);
                    }
                });

                if (failedEmails.length < 1) {
                    handleMessage(
                        dispatch,
                        data.length > 1
                            ? 'Invitations sent!'
                            : 'Invitation sent!',
                        'success'
                    );
                } else {
                    handleMessage(dispatch, failedEmails, 'error');
                }
                return sentEmails;
            })
            .catch((error: ErrorResult) => {
                let errorMessage;
                if (error && error.message && error.message.includes('role')) {
                    errorMessage = 'Role is required';
                } else {
                    errorMessage = error.message;
                }
                handleMessage(dispatch, errorMessage, 'error');
                throw error;
            });
    };
};

export const assignLabelsToInvitedUsers = (
    labels: number[],
    entities: number[]
) => {
    return (dispatch: Dispatch<any>, getState: any, context: ThunkContext) => {
        return context.http
            .post(`labels/assign`, {
                labels,
                entities,
                entityType: 'INVITATION',
                deleted: [],
            })
            .then((res: any) => {
                return res;
            })
            .catch((error: ErrorResult) => {
                handleMessage(dispatch, error.message, 'error');
                throw error;
            });
    };
};

export const useSearchStagingUsersService = () => {
    const dispatch = useDispatch();
    const searchStagingUsersApi = useSearchStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (draftId: number, sp: SearchParams) => {
            dispatch(searchDraftUsersStart());
            return searchStagingUsersApi(draftId, sp)
                .then(data => {
                    dispatch(searchDraftUsersSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error;
                });
        },
        [dispatch, searchStagingUsersApi, showMessage]
    );
};

export const useCreateUserStagingService = () => {
    const dispatch = useDispatch();
    const createStagingApi = useCreateUserStagingApi();
    return useCallback(
        (name: string, users: NewUserValues[]) => {
            dispatch(updateDraftUsersStart());
            return createStagingApi(name, users)
                .then((res: any) => {
                    dispatch(
                        updateDraftUsersSuccess({ id: uuid() }, 'draft-created')
                    );
                    return res;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    return error;
                });
        },
        [dispatch, createStagingApi]
    );
};

export const useAddStagingUserService = () => {
    const dispatch = useDispatch();
    const addStagingUsersApi = useAddStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (stagingId: number, user: DraftUser) => {
            dispatch(updateDraftUsersStart());
            return addStagingUsersApi(stagingId, [user])
                .then(() => {
                    dispatch(
                        updateDraftUsersSuccess({ id: uuid() }, 'draft-updated')
                    );
                    return true;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return false;
                });
        },
        [dispatch, addStagingUsersApi, showMessage]
    );
};

export const useAddMultipleStagingUsersService = () => {
    const dispatch = useDispatch();
    const addStagingUsersApi = useAddStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (stagingId: number, user: DraftUser[]) => {
            dispatch(updateDraftUsersStart());
            return addStagingUsersApi(stagingId, user)
                .then(() => {
                    dispatch(
                        updateDraftUsersSuccess({ id: uuid() }, 'draft-updated')
                    );
                    return true;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return false;
                });
        },
        [dispatch, addStagingUsersApi, showMessage]
    );
};

export const useUpdateStagingUserService = () => {
    const dispatch = useDispatch();
    const updateStagingUsersApi = useUpdateStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (stagingId: number, stagingUserId: number, user: DraftUser) => {
            dispatch(updateDraftUsersStart());
            return updateStagingUsersApi(stagingId, stagingUserId, user)
                .then(() => {
                    dispatch(
                        updateDraftUsersSuccess({ id: uuid() }, 'draft-updated')
                    );
                    return true;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return false;
                });
        },
        [dispatch, updateStagingUsersApi, showMessage]
    );
};

export const useDeleteStagingUserService = () => {
    const dispatch = useDispatch();
    const deleteStagingUsersApi = useDeleteStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (stagingId: number, stagingUserIds: number[]) => {
            dispatch(updateDraftUsersStart());
            return deleteStagingUsersApi(stagingId, stagingUserIds)
                .then(() => {
                    dispatch(
                        updateDraftUsersSuccess({ id: uuid() }, 'draft-updated')
                    );
                    return true;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return false;
                });
        },
        [dispatch, deleteStagingUsersApi, showMessage]
    );
};

export const useInviteStagingUserService = () => {
    const dispatch = useDispatch();
    const inviteStagingUsersApi = useInviteStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (stagingId: number, stagingUserIds: number[], selectAll: boolean, search: SearchParams) => {
            dispatch(updateDraftUsersStart());
            return inviteStagingUsersApi(stagingId, stagingUserIds, selectAll, search)
                .then(data => {
                    dispatch(
                        updateDraftUsersSuccess({ id: uuid() }, 'user-invited')
                    );

                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage('Something went wrong', 'error');
                    throw error;
                });
        },
        [dispatch, inviteStagingUsersApi, showMessage]
    );
};

export const useAssignFiltersToStagingUsersService = () => {
    const dispatch = useDispatch();
    const assignFiltersToStagingUsersApi = useAssignFiltersToStagingUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (
            stagingId: number,
            assignFilters: AssignFiltersToStagingUsersParams
        ) => {
            dispatch(updateDraftUsersStart());
            return assignFiltersToStagingUsersApi(stagingId, assignFilters)
                .then(data => {
                    if (assignFilters.selectAll) {
                        dispatch(SocketProgressShow());
                    } else { 
                        dispatch(
                            updateDraftUsersSuccess({ id: uuid() }, 'draft-updated')
                        );
                    }
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return error;
                });
        },
        [dispatch, assignFiltersToStagingUsersApi, showMessage]
    );
};

export const useModifyDraftUsersService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (users: PaginationResult<DraftUser>) => {
            dispatch(modifyDraftUsersAction(users));
        },
        [dispatch]
    );
};

export const useModifyDraftUsersWithoutValidationService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (users: PaginationResult<DraftUser>) => {
            dispatch(modifyDraftUsersWithoutValidationAction(users));
        },
        [dispatch]
    );
};

export const useResetDraftsPageService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetDraftsPageAction());
    }, [dispatch]);
};
