import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useWithLabelsContext } from '../context/useWithLabelsContext';
import { encapsulateAction } from '../../../../../../store/helpers/encapsulateAction';
import { ActionLabel } from '../actions';
import { DEFAULT_LABELS_SCOPE, Scope, WithLabelsScopes } from '../scopes';

export const useEncapsulatedDispatch = () => {
    const dispatch = useDispatch();
    const { reducerName, scope } = useWithLabelsContext();

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithLabelsContext.Provider props'
        );
    }

    const targetScope = (scope || DEFAULT_LABELS_SCOPE) as Scope;
    if (
        targetScope !== DEFAULT_LABELS_SCOPE &&
        !WithLabelsScopes[reducerName]?.includes(targetScope)
    ) {
        throw new Error(
            `Undefined "${targetScope}" scope of portableLabels. Please check config (WithLabelsScopes) for "${reducerName}" reducer`
        );
    }

    return useCallback(
        <T extends ActionLabel>(action: T) => {
            action._scope = targetScope;
            dispatch(encapsulateAction(`${reducerName}/labels/`, action));
        },
        [dispatch, reducerName, targetScope]
    );
};
