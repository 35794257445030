import React, { FC, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

const left = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const right = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const Container = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: ${props => rgba(props.theme.Colors.black, 0.5)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

const Circle = styled.div`
    height: 160px;
    width: 160px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Hold = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0px, 160px, 160px, 80px);
    border-radius: 100%;
    background-color: ${props => props.theme.Colors.whiteTwo};
`;

const Fill = styled.div`
    background-color: ${props => props.theme.Colors.fadedRedTwoMobile};
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    clip: rect(0px, 80px, 160px, 0px);
`;

const Left = styled(Hold)`
    && ${Fill} {
        z-index: 1;
        animation: ${left} 0.5s linear both;
    }
`;

const Right = styled(Hold)`
    z-index: 3;
    transform: rotate(180deg);
    && ${Fill} {
        z-index: 3;
        animation: ${right} 0.5s linear both;
        animation-delay: 0.5s;
    }
`;

const InnerCircle = styled.div`
    height: 128px;
    width: 128px;
    border-radius: 100%;
    background: ${props => props.theme.Colors.white};
    font-size: 56px;
    font-weight: bold;
    color: ${props => props.theme.Colors.fadedRedTwoMobile};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
`;

const Countdown: FC<{
    countdown: number;
    setCountdown(countdown: (prevCountdown: number) => number): any;
}> = ({ countdown, setCountdown }) => {
    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown: number) => {
                return prevCountdown - 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [setCountdown]);

    return (
        <Container>
            <Circle key={countdown}>
                <Left>
                    <Fill />
                </Left>
                <Right>
                    <Fill />
                </Right>
                <InnerCircle>{countdown}</InnerCircle>
            </Circle>
        </Container>
    );
};

export default Countdown;
