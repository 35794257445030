import NotificationWithToggle from '../NotificationWithToggle';
import { NotificationsViewProps } from './types';
import { LoadingContent } from './styles';
import { Loading } from '../../../../ui/components/LoadingCopmonent';

function NotificationsView({
    isLoading,
    items,
    onToggle,
}: NotificationsViewProps) {
    if (isLoading && !items)
        return (
            <LoadingContent>
                <Loading />
            </LoadingContent>
        );
    return (
        <>
            {items?.map(item => {
                if (item.type === 'boolean')
                    return (
                        <NotificationWithToggle
                            notificationKey={item.key}
                            key={item.key}
                            title={item.title}
                            description={item.description}
                            checked={item.value as boolean}
                            disabled={item.isSaving}
                            onChange={() => onToggle(item.key)}
                        />
                    );
                return null;
            })}
        </>
    );
}

export default NotificationsView;