import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import ROUTES from '../../../routes/routes';
import SidebarLibrary from '../../icons/SidebarLibrary';
import SidebarLibraryActive from '../../icons/SidebarLibraryActive';
import SidebarTeams from '../../icons/SidebarTeams';
import SidebarTeamsActive from '../../icons/SidebarTeamsActive';
import SidebarFeed from '../../icons/SidebarFeed';
import SidebarFeedActive from '../../icons/SidebarFeedActive';
import SidebarUsers from '../../icons/SidebarUsers';
import SidebarUsersActive from '../../icons/SidebarUsersActive';
import SidebarAssessment from '../../icons/SidebarAssessment';
import SidebarAssessmentActive from '../../icons/SidebarAssessmentActive';
import SidebarReports from '../../icons/SidebarReports';
import SidebarReportsActive from '../../icons/SidebarReportsActive';
import { SideBarMenuItem } from '../../layout/Sidebar/Sidebar';
import SidebarRoleplays from '../../icons/SidebarRoleplays';
import SidebarRoleplaysActive from '../../icons/SidebarRoleplaysActive';

export const initialDefaultSideBarMenuItems: SideBarMenuItem[] = [
    {
        name: 'Feed',
        icon: SidebarFeed,
        activeIcon: SidebarFeedActive,
        url: ROUTES.SUBMISSIONS,
        permissions: [NEW_PERMISSIONS.LIST_SUBMISSION],
    },
    {
        name: 'Teams',
        icon: SidebarTeams,
        activeIcon: SidebarTeamsActive,
        url: ROUTES.TEAMS,
        permissions: [NEW_PERMISSIONS.LIST_PRACTIS_SET],
    },
    {
        name: 'Training Log',
        icon: SidebarAssessment,
        activeIcon: SidebarAssessmentActive,
        url: ROUTES.ORGANIZATION_ASSESSMENT_LOGS,
        permissions: [NEW_PERMISSIONS.MANAGE_RECORDINGS],
    },
    {
        name: 'Reports',
        icon: SidebarReports,
        activeIcon: SidebarReportsActive,
        url: ROUTES.REPORTS.LIST_OF_REPORTS,
        permissions: [NEW_PERMISSIONS.VIEW_USER_PRACTIS_SET_REPORT],
    },
    {
        name: 'Library',
        icon: SidebarLibrary,
        activeIcon: SidebarLibraryActive,
        url: ROUTES.LIBRARY,
        permissions: [
            NEW_PERMISSIONS.LIST_SCENARIO,
            NEW_PERMISSIONS.LIST_CHALLENGE,
            NEW_PERMISSIONS.LIST_PRACTIS_SET,
        ],
    },
    {
        name: 'Users',
        icon: SidebarUsers,
        activeIcon: SidebarUsersActive,
        url: ROUTES.USERS,
        alternateUrl: [ROUTES.INVITATIONS, ROUTES.DRAFTS],
        permissions: [
            NEW_PERMISSIONS.LIST_USER,
            NEW_PERMISSIONS.LIST_INVITATION,
        ],
    },
    {
        name: 'Roleplay AI',
        icon: SidebarRoleplays,
        activeIcon: SidebarRoleplaysActive,
        url: ROUTES.ROLEPLAYS.LIST_OF_ROLEPLAYS,
    },
];

