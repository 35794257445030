import { TextToSpeechSettings, TextToSpeechSettingsByRoles } from '../../constants/interfaces/CompanyVoiceSettings';
import {
    DEFAULT_CUSTOMER_VOICE_ID,
    DEFAULT_CUSTOMER_VOICE_SIMILARITY,
    DEFAULT_CUSTOMER_VOICE_STABILITY,
    DEFAULT_REP_VOICE_ID,
    DEFAULT_REP_VOICE_SIMILARITY,
    DEFAULT_REP_VOICE_STABILITY,
} from './constants';

export const getTextToSpeechSettingsOrDefault = (settings?: TextToSpeechSettings[]): TextToSpeechSettingsByRoles => {
    const customer = settings?.find(item => item.role === 'customer') ?? {
        role: 'customer',
        voiceId: DEFAULT_CUSTOMER_VOICE_ID,
        stability: DEFAULT_CUSTOMER_VOICE_STABILITY,
        similarityBoost: DEFAULT_CUSTOMER_VOICE_SIMILARITY,
    } as TextToSpeechSettings;
    const representative = settings?.find(item => item.role === 'representative') ?? {
        role: 'representative',
        voiceId: DEFAULT_REP_VOICE_ID,
        stability: DEFAULT_REP_VOICE_STABILITY,
        similarityBoost: DEFAULT_REP_VOICE_SIMILARITY,
    } as TextToSpeechSettings;
    return { customer, representative };
}