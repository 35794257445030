import { UserV2 } from '../../../constants/interfaces/User';
import {
    fetchUserProfile,
    fetchUserProfileFailure,
    fetchUserProfileSuccess,
    updateUserProfile,
    updateUserProfileFailure,
    updateUserProfileSuccess,
} from './actions';
import { useDispatch } from 'react-redux';
import {
    useGetUserApi,
    useUpdateUserInfo,
    useUpdateUserPhoneNumber,
} from '../../../api';
import { useCallback } from 'react';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    useShowMessage,
} from '../../../ui/components/ErrorMessages/ErrorMessages';
import { useRedirectToPhoneVerification } from '../../../helpers/hooks/useRedirectToPhoneVerification';
import { MobileNumberVerificationStep } from '../../Auth/AddMobileNumber/types';
import {
    updateUserFailure,
    updateUserSuccess,
} from '../../../features/users/store/actions';
import {
    CLIENT_SIDE_ERROR_MESSAGE,
    PHONE_ALREADY_EXISTS
} from '../../../ui/components/ErrorMessages/constants';
import { getCurrentUserId, isAuthorized } from '../../../helpers/functions/auth-helpers';
import {
    useGetUserPermissionsApi,
    useGetUserSettingsApi,
    useSetUserPhotoApi
} from '../../../api';
import { UserProfile } from '../../../constants/interfaces/User';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { useUploadMediaService } from '../../../services/MediaService';

/**
 * @function useGetProfileService
 * @description gets user data, permissions and settings by user id
 * and combine them in user profile
 * @returns { Promise }
 */
export const useGetProfileService = () => {
    const dispatch = useDispatch();
    const getUserApi = useGetUserApi();
    const getUserPermissionsApi = useGetUserPermissionsApi();
    const getUserSettingsApi = useGetUserSettingsApi();
    const showMessage = useShowMessage();
    return useCallback(() => {
        if (!isAuthorized())
            return showMessage('User is not authorized to perform this action', 'error');
        const userId = getCurrentUserId()!;
        const requests: Promise<unknown>[] = [
            getUserApi(userId),
            getUserPermissionsApi(userId),
            getUserSettingsApi(userId)
        ];
        dispatch(fetchUserProfile());
        return Promise.all(requests)
            .then((data) => {
                const user = data[0] as UserV2;
                const permissions = data[1] as NEW_PERMISSIONS[];
                const labelFilters = data[2] as number[];
                const userProfile: UserProfile = {
                    ...user,
                    permissions,
                    labelFilters
                };
                dispatch(fetchUserProfileSuccess(userProfile));
                return userProfile;
            })
            .catch((error: ErrorResult) => {
                dispatch(fetchUserProfileFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, getUserApi, getUserPermissionsApi, getUserSettingsApi, showMessage]);
};

/**
 * @description handle update user profile service.
 * if user phone number should be changed it add
 * phone number changes request to the action list.
 * @function useUpdateProfileService
 * @returns { CallableFunction }
 */
export const useUpdateProfileService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();

    const updateUserInfo = useUpdateUserInfo();
    const updateUserPhoneNumber = useUpdateUserPhoneNumber();
    const redirectToPhoneVerification = useRedirectToPhoneVerification();

    /**
     * @function callback
     * @param { UserV2 } data
     * @param { number } userId
     * @param { number } roleId
     * @param { boolean } self
     * @param { boolean } shouldShowMessage
     * @param { Function } successCallback
     * @returns { Promise<void> }
     */
    return useCallback(
        (
            data: UserV2,
            userId: number,
            self: boolean,
            shouldShowMessage = true
        ) => {
            dispatch(updateUserProfile());
            const updateUserInfoActionList = [];

            updateUserInfoActionList.push(
                updateUserInfo(userId ?? data?.id, {
                    firstName: data?.firstName ?? '',
                    lastName: data?.lastName ?? '',
                })
            );

            if (data?.phoneNumber) {
                updateUserInfoActionList.push(
                    updateUserPhoneNumber(
                        userId ?? data?.id ?? 0,
                        data.phoneNumber
                    ).then(() => {
                        redirectToPhoneVerification({
                            user: { ...data, id: userId },
                            verificationStep:
                                MobileNumberVerificationStep.VERIFY,
                        });
                    })
                );
            }

            return Promise.all(updateUserInfoActionList)
                .then(() => {
                    self
                        ? dispatch(updateUserProfileSuccess({ id: userId }))
                        : dispatch(updateUserSuccess({ id: userId }));
                    shouldShowMessage &&
                        showMessage('Profile Updated!', 'success');
                })
                .catch((error: ErrorResult) => {
                    self
                        ? dispatch(updateUserProfileFailure(error))
                        : dispatch(updateUserFailure(error));

                    shouldShowMessage &&
                        showMessage(
                            error.errorCode === 409 ? CLIENT_SIDE_ERROR_MESSAGE[PHONE_ALREADY_EXISTS] : error.message,
                            'error'
                        );
                });
        },
        [
            dispatch,
            redirectToPhoneVerification,
            showMessage,
            updateUserInfo,
            updateUserPhoneNumber,
        ]
    );
};

/**
 * @description uploads user photo and sets its media id to user
 * @function useUploadPhotoService
 * @returns { CallableFunction }
 */
export const useUploadPhotoService = () => {
    const uploadMedia = useUploadMediaService();
    const setUserPhotoApi = useSetUserPhotoApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number } userId
     * @param { File } file
     * @returns { Promise<void> }
     */
     return useCallback(
        (userId: number, file: File) => {
            return uploadMedia(file)
                .then((mediaId) => {
                    return setUserPhotoApi(userId, mediaId);
                })
                .catch((error) => {
                    showMessage(error.message, 'error');
                    return Promise.reject(error);
                });
        },
        [uploadMedia, setUserPhotoApi, showMessage]
    );
};


