import { UserInterface } from './User';
import { Script } from './Script';

export enum ScenarioStatuses {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    DELETED = 'DELETED',
}

export type Status =
    | ScenarioStatuses.DRAFT
    | ScenarioStatuses.ACTIVE
    | ScenarioStatuses.ARCHIVED
    | ScenarioStatuses.DELETED;

export interface Scenario {
    uniqueId?: string;
    id?: number;
    scriptId?: number;
    title: string;
    type?: 'SCENARIO';
    description: string;
    instructions: string;
    createdAt?: string;
    updatedAt?: string;
    companyId?: string;
    creatorId?: number;
    status: Status;
    script: Script;
    creator?: UserInterface;
    checked?: boolean;
    labels?: any[];
    updatedLabels?: number[];
    minRepsCount?: number;
}
