import React, { FC } from 'react';
import styled from 'styled-components';
import { useLabelsState } from '../../../../../features/portableLabels/store/hors/withLabels/states';
import { PortableTeamsWithStore } from '../../../../../features/portableTeams';
import { PortableLabelsWithStore } from '../../../../../features/portableLabels';
import PortableRolesWithMultiSelect from '../../../../../features/portableRoles/portableRolesWithMultiSelect';
import { useTeamsState } from '../../../../../features/portableTeams/store/hors/withTeams/states';
import { usePractisSetsState } from '../../../../../features/portablePractisSets/store/hors/withPractisSets/states';
import { useSaveTeams } from '../../../../../features/portableTeams/store/hors/withTeams/services';
import { useSaveLabels } from '../../../../../features/portableLabels/store/hors/withLabels/services';
import { useSavePractisSets } from '../../../../../features/portablePractisSets/store/hors/withPractisSets/services';
import { PortablePractisSetsWithStore } from '../../../../../features/portablePractisSets';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { Fieldset } from '../../../../../ui/components/Fieldset';
import { Button } from '../../../../../ui/components/Button';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const RolesContainer = styled.div`
    width: 129px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const TeamsContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const PractisSetsContainer = styled.div`
    width: 312px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const ButtonContainer = styled.span`
    margin-right: 12px;
`;

export const ApplyFilters: FC<{
    applyFilters(
        assignedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        assignedTeams: number[],
        roleIds: number[]
    ): Promise<boolean>;
    selectedRoles: number[];
    setSelectedRoles(value: number[]): void;
    onSuccessApply: () => void;
    isRoleDisabled?: boolean;
    hideDueDates?: boolean;
}> = ({
    applyFilters,
    selectedRoles,
    setSelectedRoles,
    onSuccessApply,
    isRoleDisabled,
    hideDueDates,
}) => {
    const { selected: selectedLabels } = useLabelsState();
    const { selected: selectedTeams } = useTeamsState();
    const { selected: selectedPractisSets } = usePractisSetsState();
    const saveLabels = useSaveLabels();
    const saveTeams = useSaveTeams();
    const savePractisSets = useSavePractisSets();

    const handleApplyFilters = () => {
        applyFilters(
            selectedLabels,
            selectedPractisSets,
            selectedTeams,
            selectedRoles
        ).then(() => {
            onSuccessApply();
            saveTeams();
            saveLabels();
            savePractisSets();
        });
    };

    const selectedFiltersCount =
        selectedLabels.filter(id => id > 0).length +
        selectedPractisSets.length +
        selectedTeams.length +
        selectedRoles.length;

    return (
        <Container>
            <Wrapper>
                {!isRoleDisabled && (
                    <Fieldset title={'Roles'} dataTest="roles-section-title">
                        <RolesContainer>
                            <PortableRolesWithMultiSelect
                                selectedRoles={selectedRoles}
                                setSelectedRoles={setSelectedRoles}
                            />
                        </RolesContainer>
                    </Fieldset>
                )}
                <Fieldset title={'Teams'} dataTest="teams-section-title">
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Practis Sets'} dataTest="practis-sets-section-title">
                    <PractisSetsContainer>
                        <PortablePractisSetsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                            hideDueDates={hideDueDates}
                        />
                    </PractisSetsContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption>
                    {`${selectedFiltersCount} ${
                        selectedFiltersCount > 1 ? 'Filters' : 'Filter'
                    } Selected`}
                </SummaryCaption>
                <ButtonContainer>
                    <Button
                        label={'Cancel'}
                        variant={'inverse'}
                        action={() => onSuccessApply()}
                        width={'122px'}
                    />
                </ButtonContainer>
                <Button width={'122px'} action={() => handleApplyFilters()}>
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
