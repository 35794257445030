import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../ui/components/Checkbox';
import { TeamLeader } from '../../../constants/interfaces/TeamLeader';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';

const StyledTeamsList = styled.div`
    overflow: auto;
    height: 100%;
`;

const TopSideList = styled.span``;

const GeneralList = styled.span``;

const TeamsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;

const TeamsItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;

const StyledAdditionalInfo = styled.span`
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.steelGrey};
    margin-left: 3px;
`;

export const PortableTeamLeadersList: FC<{
    teamLeaders?: TeamLeader[];
    selectedTeams: number[];
    setTeamFilters?: (leaderId: number) => void;
}> = ({ teamLeaders, selectedTeams, setTeamFilters }) => {
    const profile = useSelector(getProfileState);
    const isChecked = useCallback(
        (setId?: number) => {
            return !!selectedTeams && !!setId && selectedTeams.includes(setId);
        },
        [selectedTeams]
    );

    const isPartiallyChecked = useCallback(
        (teamId?: number) => {
            return (
                !!selectedTeams &&
                !!teamId &&
                selectedTeams.includes(-Number(teamId))
            );
        },
        [selectedTeams]
    );

    const changeHandler = (teamId?: number) => {
        if (teamId && setTeamFilters) {
            setTeamFilters(teamId);
        }
    };

    const topListTeamLeaders = teamLeaders
        ? teamLeaders.filter(
              team =>
                  team.isDefault || (team.id && selectedTeams.includes(team.id))
          )
        : [];

    const bottomListTeamLeaders = teamLeaders
        ? teamLeaders.filter(
              team =>
                  !team.isDefault && team.id && !selectedTeams.includes(team.id)
          )
        : [];

    return (
        <StyledTeamsList>
            {topListTeamLeaders.length > 0 && (
                <TopSideList>
                    {topListTeamLeaders.map(leader => {
                        return (
                            <TeamsItemWrapper key={leader.id}>
                                <Checkbox
                                    size={12}
                                    checked={
                                        isChecked(leader.id) ||
                                        isPartiallyChecked(leader.id)
                                    }
                                    partial={isPartiallyChecked(leader.id)}
                                    handleChange={() =>
                                        changeHandler(leader.id)
                                    }
                                />
                                <TeamsItem
                                    onClick={() => changeHandler(leader.id)}
                                >
                                    {`${leader.firstName} ${leader.lastName}`}
                                </TeamsItem>
                            </TeamsItemWrapper>
                        );
                    })}
                    {topListTeamLeaders.length <
                        (teamLeaders ? teamLeaders.length : 0) && (
                        <TableDivider width="98%" />
                    )}
                </TopSideList>
            )}
            <GeneralList>
                {bottomListTeamLeaders.map(leader => {
                    return (
                        <TeamsItemWrapper key={leader.id}>
                            <Checkbox
                                size={12}
                                checked={
                                    isChecked(leader.id) ||
                                    isPartiallyChecked(leader.id)
                                }
                                partial={isPartiallyChecked(leader.id)}
                                handleChange={() => changeHandler(leader.id)}
                            />
                            <TeamsItem onClick={() => changeHandler(leader.id)}>
                                {`${leader.firstName} ${leader.lastName}`}
                                {profile && profile.id === leader.id && (
                                    <StyledAdditionalInfo>
                                        (You)
                                    </StyledAdditionalInfo>
                                )}
                            </TeamsItem>
                        </TeamsItemWrapper>
                    );
                })}
            </GeneralList>
        </StyledTeamsList>
    );
};
