import { formatDate } from '../../../../../helpers/functions/date-convert';
import { ModalPageContainer } from '../../../../../ui/components/ModalPage';
import { TableWrapper } from '../../../../../ui/components/table-wrapper';
import { ITEMS_PER_PAGE } from './constants';
import { Title, Subtitle, Container, useStyles, StyledLoading } from './styles';
import { RepLineType, ScenarioRepViewPropsType } from './types';
import { trainingModeNames } from '../../../../../constants/enums/TrainingMode';
import { Loading } from '../../../../../ui/components/LoadingCopmonent';

export const ScenarioRepsView = ({closePath,
    closeGoingBack, 
    scenarioName, 
    tableStates, 
    reps,
    isLoading,
    lastRefreshed, 
    refreshData,
    handlePageChange,
    repGeneralNumber
}: ScenarioRepViewPropsType) => {
    const classes = useStyles();
    return <ModalPageContainer
            closePath={closePath}
            closeGoingBack={closeGoingBack}
            width={'544px'}>
            <Container>
                <Title>Scenario</Title>
                <Subtitle>{scenarioName}</Subtitle>
                {isLoading && !reps?.items.length && (
                    <StyledLoading><Loading /></StyledLoading>
                )}
                <TableWrapper
                    tableWrapperClassName={classes.tableWrapperStyle}
                    tableStates={tableStates}
                    data={reps?.items}
                    tableRefreshConfigurations={{
                        lastRefreshed: lastRefreshed,
                        refreshData: refreshData,
                        dataTest: 'scenario-rep-timestamp',
                    }}
                    tableToolsOptions={{
                        pagingOptions: {
                            totalCount: reps?.count,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onPageChange: handlePageChange,
                            searchOrFiltersApplied: false,
                            noUrlChange: true,
                            dataTest: 'rep-line-paging',
                        },
                        isSelectedItemOptionsVisible: false,
                    }}
                    tableEmptyStateConfigurations={{
                        shouldShowEmptyState: false
                    }}
                    configurations={{
                        columns: [
                            {
                                title: 'Date',
                                width: 20,
                                className: classes.customTableColumnStyle,
                                disabled: tableStates.disableSorting,
                                dataTest: 'date-column',
                            },
                            {
                                title: 'Mode',
                                width: 20,
                                disabled: tableStates.disableSorting,
                                dataTest: 'mode-column',
                            },
                            {
                                title: 'Rep',
                                width: 20,
                                disabled: tableStates.disableSorting,
                                dataTest: 'rep-column',
                            },
                            {
                                title: 'Accuracy',
                                width: 20,
                                disabled: tableStates.disableSorting,
                                dataTest: 'accuracy-column',
                            },
                        ],

                        rowConfig: {
                            cells: [
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (rep: RepLineType) =>
                                        !rep.createdAt,
                                    fieldProps: {
                                        renderTitle: (rep: RepLineType) => formatDate(rep.createdAt),
                                        dataTest: 'rep-line-date-item',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (rep: RepLineType) =>
                                        !rep.accuracy.modeScenario,
                                    fieldProps: {
                                        renderTitle: (rep: RepLineType) => trainingModeNames[rep.accuracy.modeScenario],
                                        dataTest: 'rep-line-mode-item',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (rep: RepLineType) =>
                                        !rep.repNumber || !repGeneralNumber,
                                    fieldProps: {
                                        renderTitle: (rep: RepLineType) => `${rep.repNumber} of ${repGeneralNumber}`,
                                        dataTest: 'rep-line-rep-item',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (rep: RepLineType) =>
                                        !rep.accuracy.accuracy,
                                    fieldProps: {
                                        renderTitle: (rep: RepLineType) => Math.floor(rep.accuracy.accuracy * 100) + '%',
                                        dataTest: 'rep-line-accuracy-item',
                                    },
                                },
                            ],
                        },
                    }}
                />
        </Container>
    </ModalPageContainer>
    ;
}
