import { TableDivider } from '../../../../ui/components/table-wrapper/table-divider';
import { Button } from '../../../../ui/components/Button';
import { CreateTeamViewProps } from './types';
import {
    StyledModalPage,
    Container,
    Title,
    NameContainer,
    NameInput,
    ActionContainer,
    ButtonDelimiter
} from './styles';

function CreateTeamView({
    closePath,
    closeGoingBack,
    name,
    isSubmitting,
    hasError,
    onChangeName,
    onCancel,
    onSave
}: CreateTeamViewProps) {
    return (
        <StyledModalPage
            closePath={closePath}
            closeGoingBack={closeGoingBack}
        >
            <Container>
                <Title data-test="create-team-page-title">Create New Team</Title>
                <TableDivider />
                <NameContainer>
                    <NameInput
                        height="40px"
                        maxLength={50}
                        placeholder="Team Name"
                        handleChange={(event: { target: HTMLInputElement }) =>
                            onChangeName(event.target.value)
                        }
                        value={name}
                        dataTest="create-team-name"
                        error={hasError}
                    />
                </NameContainer>
                <TableDivider />
                <ActionContainer>
                    <Button
                        label="Cancel"
                        variant="inverse"
                        width="126px"
                        action={onCancel}
                        dataTest="create-team-cancel"
                    />
                    <ButtonDelimiter />
                    <Button
                        label={'Create'}
                        width="126px"
                        loading={isSubmitting}
                        disabled={name.trim().length === 0}
                        action={onSave}
                        dataTest="create-team-save"
                    />

                </ActionContainer>
            </Container>
        </StyledModalPage>
    );
}

export default CreateTeamView;