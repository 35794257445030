import { SearchParams } from '../../../../constants/interfaces/filters';
import {
    AccuracyTestSubmissionFilters,
    ChallengeSubmissionFilters,
} from '../../../../constants/interfaces/SubmissionSearchFilters';

export type UpdateSubmissionStatuses = 'Archive' | 'Restore';
export enum SubmissionUpdateActions {
    ARCHIVE = 'Archive',
    RESTORE = 'Restore',
}

export type UpdateSubmissionStatusChunkRequestParameters = {
    searchParams: SearchParams;
    filters: AccuracyTestSubmissionFilters | ChallengeSubmissionFilters;
    onSuccessCallback: (numberOfDoneItems: number) => void;
    totalItems: number
};