import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Stack = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 28 28"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.2%"
                            x="-14.3%"
                            y="-1.2%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M24.322 17.539c.533-.14 1.099.113 1.299.563.166.45-.134.901-.666 1.042L14.3 21.847c-.1.028-.2.028-.3.028-.1 0-.2 0-.3-.028L3.045 19.144c-.532-.113-.832-.591-.666-1.042.134-.45.7-.704 1.232-.563l10.356 2.618zm1.299-3.812c.154.418-.093.837-.556 1.007l-.11.035-10.661 2.703c-.1.028-.2.028-.3.028-.067 0-.133 0-.2-.008l-.1-.02-10.66-2.703c-.533-.113-.8-.591-.667-1.042.124-.418.621-.667 1.118-.587l.115.024 10.36 2.618 10.361-2.618c.533-.14 1.1.113 1.3.563zM14.165 5.849l.12.028 10.68 2.772c.401.115.702.462.702.837 0 .364-.217.658-.576.797l-.125.04-10.681 2.773c-.1.029-.2.029-.3.029-.067 0-.134 0-.2-.009l-.1-.02-10.682-2.772c-.4-.087-.7-.433-.667-.838 0-.363.216-.657.576-.796l.125-.041 10.68-2.772a.902.902 0 0 1 .448-.028z"
                    filter={!!shadow ? 'url(#a)' : undefined}
                />
            </svg>
        );
    }
);

export default Stack;
