import styled from 'styled-components';

export const Title = styled.div`
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--ps-white-1);
    width: 100%;
    text-align: center;
`;

export const BackArrow = styled.img`
    position: absolute;
    top: 7px;
    left: 0;
    cursor: pointer;
    width: 16px;
    height: 14px;
`;

export const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    text-align: center;
    padding-top: 4px;
`;

export const MobileNumberContainer = styled.div`
    padding-top: 12px;
    width: 100%;
`;

export const SecondaryText = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-grey-4);
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
`;

export const ErrorText = styled.div`
    color: var(--ps-red-main);
`;

export const ButtonContainer = styled.div`
    padding-top: 20px;
    width: 100%;
`