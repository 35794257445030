import { useCallback } from 'react';

import { TableHeaderCellProps } from './type';

export const useOrderBy = <T extends Object = {}>(
    field: keyof T | string,
    state: { field?: string; asc?: boolean } | undefined | null,
    onChange: (field: string) => void
): Partial<TableHeaderCellProps> => {
    const asc = state && state.field === field ? state.asc : undefined;
    const onClick = useCallback(() => onChange(field as string), [
        field,
        onChange,
    ]);
    return {
        asc,
        onClick: onClick,
    };
};

