import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { Variables } from '../../../../theme/variables';
import { useLabelsState } from '../../../../features/portableLabels/store/hors/withLabels/states';
import { usePortableLabelsState } from '../../../../features/portableLabels/store/states';
import { AssignLabels } from '../../../../features/portableLabels/templates/AssignLabels';
import {
    useResetLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../features/portableLabels/store/hors/withLabels/services';
import { Popup } from '../../../../ui/components/Popup';
import { Tooltip } from '../../../../ui/components/Tooltip';

//region Styles
const Container = styled.div`
    position: relative;
    height: 100%;
`;

const SelectHead = styled.div<{ disabled?: boolean }>`
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const SelectTitle = styled.div`
    pointer-events: none;
    font-size: 11px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: block;
    align-items: center;
    color: ${props => props.theme.Colors.cloudyBlue};
    font-weight: 600;
    margin-left: 15px;
`;

const SelectLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const SelectLabel = styled.div<{ color?: string; disabled?: boolean }>`
    pointer-events: none;
    font-size: 13px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.disabled ? props.theme.Colors.lightBlueGray : props.color};
    font-weight: 500;
    margin-left: 15px;
    margin-bottom: 1px;
`;

//endregion

export const SelectedUserLabels: FC<{
    selectedLabels: number[];
    onSelectLabels(labelIds?: number[]): void;
    dataTest: string;
}> = ({ selectedLabels, onSelectLabels, dataTest }) => {
    const { saved } = useLabelsState();
    const { data } = usePortableLabelsState();
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
    const resetLabels = useResetLabels();

    useEffect(() => {
        selectLabels(selectedLabels);
        saveLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectLabels, saveLabels]);

    useEffect(() => {
        return () => {
            resetLabels();
        };
    }, [resetLabels]);

    useEffect(() => {
        onSelectLabels(saved);
    }, [onSelectLabels, saved]);

    const labelsDisabled = !data || data.items.length < 1;
    const selectedUserLabelsCount = selectedLabels.filter(id => id > 0).length;

    return (
        <Container>
            <Popup<HTMLDivElement>
                content={({ hide }) => (
                    <AssignLabels
                        onSuccessApply={() => {
                            hide();
                        }}
                    />
                )}
                horizontalOffset={64}
            >
                {(ref, { toggleShown }) => (
                    <span ref={ref}>
                        <Tooltip
                            label={'No labels added yet'}
                            preset={'dropdown'}
                            disabled={!labelsDisabled}
                            dataTest={`${dataTest}-no-result`}
                        >
                            <SelectHead
                                onClick={() => !labelsDisabled && toggleShown()}
                                disabled={labelsDisabled}
                                data-test={dataTest}
                            >
                                {!!selectedUserLabelsCount && (
                                    <SelectTitle>Labels</SelectTitle>
                                )}
                                <SelectLabelWrapper>
                                    <SelectLabel
                                        color={
                                            selectedUserLabelsCount < 1
                                                ? Variables.Colors.cloudyBlue
                                                : Variables.Colors.black
                                        }
                                        disabled={labelsDisabled}
                                    >
                                        {selectedUserLabelsCount < 1
                                            ? 'Labels'
                                            : `${selectedUserLabelsCount} ${
                                                  selectedUserLabelsCount > 1
                                                      ? 'Labels'
                                                      : 'Label'
                                              }`}
                                    </SelectLabel>
                                </SelectLabelWrapper>
                            </SelectHead>
                        </Tooltip>
                    </span>
                )}
            </Popup>
        </Container>
    );
};
