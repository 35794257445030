import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const HourGlass = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow, width, height }, ref) => {
        return (
            <svg width={width || '11'} height={height || '13'} viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h11v13H0V0z" fill="#fff"/>
            <path d="M10.445 3.414V2.36c.33-.173.555-.492.555-.861v-.25c0-.138-.124-.25-.277-.25H1.278C1.124 1 1 1.112 1 1.25v.25c0 .369.225.688.556.86v1.054c0 .99.469 1.939 1.287 2.601L4.06 7l-1.216.985c-.818.662-1.287 1.61-1.287 2.601v1.053c-.33.174-.556.492-.556.861v.25c0 .138.124.25.278.25h9.445c.153 0 .277-.112.277-.25v-.25c0-.369-.225-.688-.555-.86v-1.054c0-.99-.47-1.939-1.288-2.601L7.942 7l1.215-.985c.818-.662 1.288-1.61 1.288-2.601zM8.414 5.272 6.74 6.628A.479.479 0 0 0 6.556 7c0 .142.066.277.184.372l1.674 1.356c.584.473.92 1.15.92 1.858v.914H2.667v-.914c0-.707.335-1.385.92-1.858L5.26 7.372A.479.479 0 0 0 5.445 7a.478.478 0 0 0-.184-.372L3.586 5.272c-.584-.473-.92-1.15-.92-1.858V2.5h6.668v.914c0 .707-.336 1.385-.92 1.858z" fill="#F0A803"/>
            <path d="M7.747 4H4.253c-.1 0-.19.056-.231.142a.229.229 0 0 0 .044.258L5.83 5.938A.26.26 0 0 0 6 6a.26.26 0 0 0 .17-.062L7.934 4.4a.229.229 0 0 0 .044-.258A.254.254 0 0 0 7.747 4zM4.253 12h3.494c.1 0 .19-.084.231-.214a.479.479 0 0 0-.044-.386L6.17 9.093A.217.217 0 0 0 6 9c-.061 0-.122.03-.17.093L4.066 11.4a.479.479 0 0 0-.044.386c.04.13.131.214.231.214z" fill="#F0A803"/>
        </svg>
        
        );
    }
);

export default HourGlass;
