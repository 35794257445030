import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "../../../../tools/router";
import OverdueLearnersView from "./view";
import { useOverdueLearnersState } from './store/states';
import { useGetOverdueLearnersService } from "./store/services";
import { ITEMS_PER_PAGE } from "../../../submissions/pages/ChallengeSubmissions";
import { SearchParams, useSearchParamsState } from "../../../../constants/interfaces/filters";
import { initialSearchParams, OverdueNudgeMessagePrefix } from "./constants";
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { UserV2 } from "../../../../constants/interfaces/User";
import ROUTES from '../../../../routes/routes';
import { WithLabelsContext } from "../../../portableLabels";
import { WithTeamsContext } from "../../../portableTeams";
import { useLabelsState } from "../../../labels/store/states";
import { useSaveUserFiltersService } from "../../../labels/store/services";
import { countAppliedFiltersCount } from "./tools";
import { useDeleteUserLabelService, useNudgeTraineesService } from "../../../users/store/services";
import { getProfileState } from "../../../../pages/UserProfile/store/reducers";
import { NudgeData } from "../../../../api/alert/types";
import { useSessionEffect } from "../../../common";
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { usePortableTeamsState } from "../../../portableTeams/store/states";
import { useNudgeUsersBulkActionService } from '../../../../services/GeneralBulkActionServices';

const qs = require('query-string');

function OverdueLearnersController () {
    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );
    const [showNudgeDialogMemberId, setShowNudgeDialogMemberId] =
        useState<number | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const history = useHistory();
    const { data: users, isLoading: loading } = useOverdueLearnersState();
    const profile = useSelector(getProfileState);

    const searchOverdueLearners = useGetOverdueLearnersService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const deleteUserLabel = useDeleteUserLabelService();
    const nudgeTrainees = useNudgeTraineesService();
    const portableTeams = usePortableTeamsState();

    const onNudgeSingleUserSuccessCallback = () => {
        setShowNudgeDialogMemberId(null);
    };

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page) || 1;

    const initialOffset = (pageIndex - 1) * ITEMS_PER_PAGE;
    const usableParams: SearchParams = initialSearchParams;

    const { searchParams, setSearchTerm, setOffset, setLabelIDs, setOrderBy, setCustom} =
        useSearchParamsState(usableParams);

    const orderBy = searchParams.orderBy;
    const hcUserName = useOrderBy<UserV2>(
        'user_name',
        orderBy,
        setOrderBy
    );
    const hcTeamName = useOrderBy<UserV2>(
        'team_name',
        orderBy,
        setOrderBy
    );

    const handleTableStates = useTableStateHelper();
    const { selected: selectedLabels } = useLabelsState();

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
    }, [initialOffset, pageIndex, setOffset]);

    useEffect(() => {
        if (selectedUsers?.length === users?.count) {
            setIsSelectAll(true);
        }
    }, [users?.count, selectedUsers]);

    useEffect(() => {
        Promise.resolve().then(() => {
            setSelectedUsers([]);
            setIsSelectAll(false);
        });
    }, [users]);

    useEffect(() => {
        return () => setSelectedUsers([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @function handleToggleLabels
     * @param { itemId } number
     * @returns { void }
     */
    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { UserV2 } row
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (row: UserV2): boolean => {
        return labelsOpen === row?.id;
    };
    
    useSessionEffect(() => {
        if (!profile?.companyId) return;
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchOverdueLearners({
            ...searchParams,
            companyId: profile?.companyId,
            labelIDs: selectedLabels,
        });
    }, [searchOverdueLearners, searchParams, profile?.companyId]);

      /**
     * @function teamsChangeHandler
     * @param { number[] } ids
     * @returns { void }
     */
      const teamsChangeHandler = (ids: number[]) => {
        setCustom('teamIds', ids);
    };

    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countAppliedFiltersCount(searchParams),
        itemsCount: users?.items?.length || 0,
    });

    /**
     * @function refreshData
     * @returns { void }
     */
    const refreshData = useCallback(() => {
            searchParams.limit = ITEMS_PER_PAGE;
            searchParams.offset = initialOffset;
            searchOverdueLearners({...searchParams, companyId: profile?.companyId});
            setLastRefreshed(new Date());
            setSelectedUsers([]);
            setIsSelectAll(false);
    }, [initialOffset, searchParams, searchOverdueLearners, profile?.companyId]);

    const nudgeUsersBulkActionService = useNudgeUsersBulkActionService(
        'overdue',
        {
            companyId: profile?.companyId ?? 0,
        },
        searchParams,
        users?.count ?? 0,
        refreshData
    );

    const onNudgeMultipleUsersSuccessCallback = useCallback(() => {
        setIsSelectAll(false);
        setSelectedUsers([]);
    }, [])

    /**
     * @function handleNudgeMultipleUsers
     * @returns { void }
     */
    const handleNudgeMultipleUsers = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;
            
            if (isSelectAll) {
                nudgeUsersBulkActionService({ text: OverdueNudgeMessagePrefix + text, senderName });
            } else {
                if (selectedUsers) {
                    const nudgeUsersDate = selectedUsers.map(
                        user =>
                            ({
                                type: 'overdue',
                                receiverId: user,
                                enrollmentId: null,
                                senderName,
                                text: OverdueNudgeMessagePrefix + text,
                            } as NudgeData)
                    );

                    nudgeTrainees(
                        nudgeUsersDate,
                        onNudgeMultipleUsersSuccessCallback
                    );
                }
            }
        },
        [
            isSelectAll,
            nudgeUsersBulkActionService,
            selectedUsers,
            nudgeTrainees,
            onNudgeMultipleUsersSuccessCallback,
        ]
    );


    /**
     * @function handleViewProfile
     * @param { user } UserV2
     * @returns { void }
     */
    const handleViewProfile = (user: UserV2) => {
        history.push(
            ROUTES.USER_PERFORMANCE.replace(
                ':userId',
                user.id.toString()
            ),
            { useGoBack: true }
        );
    };

    /**
     * @function deleteLabelHandler
     * @param { number } labelId
     * @param { UserV2 } user
     * @returns { void }
     */
    const deleteLabelHandler = (labelId: number, user: UserV2): void => {
        deleteUserLabel(user.id, labelId, refreshData);
    };

    /**
     * @function selectAllHandler
     * @param { e } ChangeEvent<HTMLInputElement>
     * @returns { void }
     */
    const selectAllHandler: ChangeEventHandler<HTMLInputElement> = e => {
        if (
            users && users.count < ITEMS_PER_PAGE &&
            e.target.checked === true
        ) {
            setIsSelectAll(true);
        } else {
            setIsSelectAll(false);
        }

        const isCheckboxPartiallySelected =
        selectedUsers && users && selectedUsers.length < users.items.length;

        if (e.target.checked || isCheckboxPartiallySelected) {
            setSelectedUsers((users && users.items.map(item => item.id)) || []);
        } else {
            setSelectedUsers([]);
        }
    };

    /**
     * @function selectUserHandler
     * @returns { void }
     */
    const selectUserHandler = useCallback(
        (id: number, state: boolean) => {
            if (state) {
                setSelectedUsers(prevState => [...prevState, id]);
            } else {
                setSelectedUsers(prevState =>
                    prevState.filter(item => item !== id)
                );
            }
            setIsSelectAll(false);
        },
        [setSelectedUsers]
    );

    /**
     * @function handleNudgeSingleUser
     * @returns { void }
     */
    const handleNudgeSingleUser = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;
            if (showNudgeDialogMemberId) {
                const nudgeUserDate: NudgeData = {
                    type: 'overdue',
                    enrollmentId: null,
                    receiverId: showNudgeDialogMemberId,
                    senderName,
                    text: OverdueNudgeMessagePrefix + text,
                };

                nudgeTrainees(
                    [nudgeUserDate],
                    onNudgeSingleUserSuccessCallback
                );
            }
        },
        [showNudgeDialogMemberId, nudgeTrainees]
    );

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'teams',
            }}
        >
            <WithTeamsContext.Provider
                value={{
                    reducerName: 'teams',
                }}
            >
            <OverdueLearnersView
                profile={profile}
                users={users}
                tableStates={tableStates}
                searchParams={searchParams}
                loading={loading}
                lastRefreshed={lastRefreshed}
                refreshData={refreshData}
                handlePageChange={setOffset}
                handleSearchChange={setSearchTerm}
                handleViewProfile={handleViewProfile}
                selectedLabels={selectedLabels}
                onLabelsFilterChange={saveGlobalLabelFilters}
                onTeamsFilterChange={teamsChangeHandler}
                onDeleteLabelHandler={deleteLabelHandler}
                onNudgeSingleUser={handleNudgeSingleUser}
                showNudgeDialogMemberId={showNudgeDialogMemberId}
                setShowNudgeDialogMemberId={(id: number | null) => setShowNudgeDialogMemberId(id)}
                selectedUsers={selectedUsers}
                sorting={{hcTeamName, hcUserName}}
                isSelectAll={isSelectAll}
                updateIsSelectAll={setIsSelectAll}
                updateSelectedUsers={setSelectedUsers}
                selectAllHandler={selectAllHandler}
                onSelectUser={selectUserHandler}
                teamsData={portableTeams.data}
                handleNudgeMultipleUsers={handleNudgeMultipleUsers}
                handleToggleLabels={handleToggleLabels}
                checkIsLabelTagsOpen={checkIsLabelTagsOpen}
                labelsOpen={labelsOpen}
            />
         </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};

export default OverdueLearnersController;