import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import RightArrow from '../../icons/RightArrow';
import LabelIcon from '../../icons/Label';

export const PractisSupportWrapper = styled.div<{ shrink: boolean }>`
    position: relative;
    height: 32px;
    padding-left: ${props => (props.shrink ? '20px' : '32px')};
    padding-right: 20px;
    display: flex;
    cursor: pointer;
    color: var(--ps-grey-1);
    text-decoration: none;
`;

export const StyledSidebar = styled.div`
    z-index: 1;
    display: flex;
`;

export const SidebarBody = styled.div`
    display: flex;
    flex: 1;
`;

export const SidebarLabels = styled.div<{ isOpen: boolean }>`
    background: ${props => (props.isOpen ? 'var(--ps-graphite-2)' : 'none')};
    margin-top: 28px;
    border-top-left-radius: 8px;
    display: flex;
    padding-bottom: 24px;
`;

export const ClosedLabels = styled.div`
    width: 48px;
    height: 48px;
    padding: 8px;
    box-sizing: border-box;
    background: var(--ps-graphite-2);
    border-radius: 8px 0 0 8px;
`;

export const OpenLabels = styled.div`
    width: 192px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const OpenLabelsContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const OpenLabelsFooter = styled.div`
    padding: 8px 0 16px 16px;
`;

export const StyledLabelsIcon = styled(LabelIcon)`
    width: 16px;
    height: 12px;
`;

export const StyledRightArrow = styled(RightArrow)`
    width: 12px;
`;

export const OpenLabelsButton = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: var(--ps-graphite-1);
    color: var(--ps-white-1);
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        opacity: 0.7;
    }
`;

export const SidebarContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const SidebarMenu = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 84px 0 105px 0;
`;

export const SidebarProfile = styled.div``;

export const ItemLoading = styled.div<{ isOpen: boolean }>`
    height: 32px;
    margin-bottom: 32px;
    margin-left: ${props => (props.isOpen ? '20px' : '32px')};
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const ItemLoadingIcon = styled.div`
    width: 16px;
    height: 16px;
    background-color: var(--ps-graphite-4);
    border-radius: 8px;
`;

export const ItemLoadingText = styled.div`
    width: 64px;
    height: 8px;
    background-color: var(--ps-graphite-4);
    margin-left: 12px;
`;

export const IconHolder = styled.div`
    width: 16px;
    height: 16px;
    align-self: center;
`;

export const Link = styled(NavLink)<{ shrink: boolean }>`
    height: 32px;
    padding-left: ${props => (props.shrink ? '20px' : '32px')};
    padding-right: 20px;
    margin-bottom: 32px;
    display: flex;
    cursor: pointer;
    color: var(--ps-grey-1);
    text-decoration: none;
    position: relative;
    &.is-active {
        color: var(--ps-white-1);
        font-weight: bold;

        ::before {
            width: 4px;
            height: 32px;
            background: var(--ps-white-1);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0 10px 10px 0;
        }
    }
    ${props =>
        props.shrink &&
        `
        flex-direction: row;
        justify-content: center;
    `}
    &:last-child {
        margin-bottom: 120px;
    }
`;

export const Label = styled.div<{ fontSize?: string }>`
    font-weight: 500;
    font-size: ${props => (!!props.fontSize ? props.fontSize : '15px')};
    text-decoration: none;
    margin-left: 12px;
    align-self: center;
    line-height: 22px;
`;

export const StyledDot = styled.div<{ isShrink?: boolean }>`
    height: 8px;
    width: 8px;
    background-color: var(--ps-red-main);
    border-radius: 50%;
    ${props => !props.isShrink && 'margin: 10px 0 0 0'};
    ${props => !!props.isShrink && 'position: absolute; top: 3px; right: 0'};
`;