import { FC, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Variables } from '../../../../../../theme/variables';
import { useSearchEditedByUsersService } from '../../../../store/services';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../../constants/interfaces/filters';
import { useDraftsState } from '../../../../store/states';
import { EditedByFiltersList } from './EditedByFiltersList';
import { compose } from 'redux';
import { sortLabels } from '../../../../../labels/tools';
import { DraftUser } from '../../../../../../constants/interfaces/Draft';
import { filterUsers } from '../tools';
import { EmptyState } from '../../../../../../ui/components/EmptyStates';
import NoSearchResult from '../../../../../../ui/icons/NoSearchResult';
import { Loading } from '../../../../../../ui/components/LoadingCopmonent/Loading';
import User from '../../../../../../ui/icons/User';
import { TableSearchInput } from '../../../../../../ui/components/table-wrapper/table-tools/table-search-input';

const Container = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const ContainerBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const EmptyTextWrapper = styled.span`
    font-size: 13px;
`;

const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

type Action = 'clear' | 'select';

const actionConfig: Record<
    Action,
    {
        color: string;
        colorHover: string;
        colorActive: string;
    }
> = {
    clear: {
        color: Variables.Colors.darkSkyBlue,
        colorHover: Variables.Colors.lightBlue,
        colorActive: Variables.Colors.windowsBlue,
    },
    select: {
        color: Variables.Colors.darkSkyBlue,
        colorHover: Variables.Colors.lightBlue,
        colorActive: Variables.Colors.windowsBlue,
    },
};

const initialSearchParams: Partial<SearchParams> = {
    searchTerm: '',
};

const EditedByFilters: FC<{
    selectedUsers: number[];
    selectEditedByUsers: (userIds: number[]) => void;
}> = ({ selectedUsers, selectEditedByUsers }) => {
    const fetchUsers = useSearchEditedByUsersService();
    const draftsState = useDraftsState();
    const editedByUsers = useMemo(() => draftsState.editedByUsers || [], [
        draftsState,
    ]);

    const { searchParams, setSearchTerm } = useSearchParamsState(
        initialSearchParams as SearchParams
    );

    useEffect(() => {
        fetchUsers({
            searchTerm: '',
        } as SearchParams);
    }, [fetchUsers]);

    const getProcessedUsers = useCallback(() => {
        if (editedByUsers) {
            return compose<Partial<DraftUser>[]>(
                filterUsers(searchParams.searchTerm),
                sortLabels
            )(editedByUsers);
        } else {
            return [];
        }
    }, [searchParams.searchTerm, editedByUsers]);

    const selectAllHandler = () => {
        selectEditedByUsers(getProcessedUsers().map(user => user.id));
    };

    const clearHandler = () => {
        selectEditedByUsers([]);
    };

    const showEmptyState = !getProcessedUsers().length;
    const selectedUsersCount = selectedUsers.length;

    const isAllSelected = useMemo(
        () => editedByUsers.length === selectedUsersCount,
        [editedByUsers, selectedUsersCount]
    );

    return (
        <Container>
            <ContainerBody>
                <SearchWrapper>
                    <TableSearchInput
                        onChange={setSearchTerm}
                        disabled={editedByUsers.length < 1}
                        dataTest="editors-search"
                    />
                </SearchWrapper>
                <SelectActions>
                    <div>
                        <SelectedCaption data-test="editors-selected-text">
                            {selectedUsersCount > 0
                                ? `${selectedUsersCount} ${
                                      selectedUsersCount > 1
                                          ? ' Users selected'
                                          : ' User selected'
                                  }`
                                : 'No Users selected'}
                        </SelectedCaption>
                    </div>
                    <div>
                        {isAllSelected ? (
                            <PlainAction
                                disabled={
                                    selectedUsers.length === 0 || showEmptyState
                                }
                                color={actionConfig.clear.color}
                                colorActive={actionConfig.clear.colorActive}
                                colorHover={actionConfig.clear.colorHover}
                                onClick={clearHandler}
                                data-test="editors-unselect-all"
                            >
                                Unselect All
                            </PlainAction>
                        ) : (
                            <PlainAction
                                disabled={showEmptyState}
                                color={actionConfig.select.color}
                                colorActive={actionConfig.select.colorActive}
                                colorHover={actionConfig.select.colorHover}
                                onClick={selectAllHandler}
                                data-test="editors-select-all"
                            >
                                Select All
                            </PlainAction>
                        )}
                    </div>
                </SelectActions>
                {showEmptyState ? (
                    draftsState.loadingEditedBy ? (
                        <Loading />
                    ) : searchParams.searchTerm ? (
                        <EmptyStateWrapper>
                            <EmptyState icon={NoSearchResult} dataTest="no-editors-found">
                                <EmptyTextWrapper>
                                    No Users Found
                                </EmptyTextWrapper>
                            </EmptyState>
                        </EmptyStateWrapper>
                    ) : (
                        <EmptyStateWrapper>
                            <EmptyState icon={User} dataTest="no-editors">
                                <EmptyTextWrapper>
                                    No Users Yet
                                </EmptyTextWrapper>
                            </EmptyState>
                        </EmptyStateWrapper>
                    )
                ) : (
                    <EditedByFiltersList
                        users={getProcessedUsers()}
                        selectedUsers={selectedUsers}
                        disabled={false}
                        setUserFilters={selectEditedByUsers}
                    />
                )}
            </ContainerBody>
        </Container>
    );
};

export default EditedByFilters;
