export const NOT_AUTHORIZED_ERROR_MESSAGE =
    'You are not authorized to perform this action.';
export const TOKEN_EXPIRED = 'Token Expired';
export const USER_NOT_FOUND = 'This user wasn’t found.';
export const TEAM_NAME_ALREADY_EXISTS = 'Entity "team:name" already exists';
export const PHONE_ALREADY_EXISTS = 'Entity "phone:number" already exists';
export const PRACTIS_SET_ALREADY_EXISTED_ERROR_MESSAGE =
    'Practis Set with this name already exists!';

//Map for client side error messages
export const CLIENT_SIDE_ERROR_MESSAGE = {
    [TOKEN_EXPIRED]:
        'This link has expired. Click “Forgot Password?” again to try again.',
    [USER_NOT_FOUND]:
        "That account doesn't exist. Enter a different email address.",
    [TEAM_NAME_ALREADY_EXISTS]: 'Entered name already exists.',
    [PHONE_ALREADY_EXISTS]:
        'Oops. Someone else already registered with this number',
    [PRACTIS_SET_ALREADY_EXISTED_ERROR_MESSAGE]:
        'Practis Set with this name already exists!',
} as Record<string, string>;