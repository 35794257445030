import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import { SearchInvitersApiParams } from './types';

/**
 * @function useSearchInvitersApi
 * @returns { SearchInvitersApiParams }
 */
export const useSearchInvitersApi = (): SearchInvitersApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams =>
            http.post('invitations/load-filters-data', searchParams),
        [http]
    );
};

