import { FC, memo } from 'react';

import { EmptyCellDash } from '../EmptyCellDash';
import { Td } from './style';
import { TableCellProps } from './type';

const TableCell: FC<TableCellProps> = memo(props => {
    const {
        width,
        colSpan,
        className,
        children,
        onClick,
        shouldShowEmptyCell,
        dataTest,
    } = props;

    return (
        <Td
            width={width}
            clickable={!!onClick}
            colSpan={colSpan}
            className={className}
            onClick={onClick}
            data-test="table-cell"
            data-test-custom-name={dataTest}
        >
            {!shouldShowEmptyCell ? (
                children
            ) : (
                <EmptyCellDash />
            )}
        </Td>
    );
});

export default TableCell;
