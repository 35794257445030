export enum ACTIONS {
    UPLOAD_CHALLENGE_AUDIO_START = 'UPLOAD_CHALLENGE_AUDIO_START',
    UPLOAD_CHALLENGE_AUDIO_SUCCESS = 'UPLOAD_CHALLENGE_AUDIO_SUCCESS',
    UPLOAD_CHALLENGE_AUDIO_FAILURE = 'UPLOAD_CHALLENGE_AUDIO_FAILURE',

    CREATE_CHALLENGES_SCRIPT_LINE = 'CREATE_CHALLENGES_SCRIPT_LINE',
    UPDATE_CHALLENGES_SCRIPT_LINE = 'UPDATE_CHALLENGES_SCRIPT_LINE',
    DELETE_CHALLENGES_SCRIPT_LINE = 'DELETE_CHALLENGES_SCRIPT_LINE',
    MODIFY_CHALLENGES_SCRIPT_LINE = 'MODIFY_CHALLENGES_SCRIPT_LINE',

    RESET_CHALLENGE = 'RESET_CHALLENGE',
    RESET_CHALLENGE_LOGO = 'RESET_CHALLENGE_LOGO',

    FETCH_CHALLENGE_START = 'FETCH_CHALLENGE_START',
    FETCH_CHALLENGE_SUCCESS = 'FETCH_CHALLENGE_SUCCESS',
    FETCH_CHALLENGE_FAILURE = 'FETCH_CHALLENGE_FAILURE',

    STORE_CHALLENGE_TEMP_COPY = 'STORE_CHALLENGE_TEMP_COPY',
    RESTORE_CHALLENGE_FROM_TEMP = 'RESTORE_CHALLENGE_FROM_TEMP',

    MODIFY_CHALLENGE = 'MODIFY_CHALLENGE',
}

export function createChallengeScriptLineAction(data: any, silent?: boolean) {
    return {
        type: ACTIONS.CREATE_CHALLENGES_SCRIPT_LINE as ACTIONS.CREATE_CHALLENGES_SCRIPT_LINE,
        data,
        silent,
    };
}

export function updateChallengeScriptLineAction(data: any) {
    return {
        type: ACTIONS.UPDATE_CHALLENGES_SCRIPT_LINE as ACTIONS.UPDATE_CHALLENGES_SCRIPT_LINE,
        data,
    };
}

export function modifyChallengeScriptLineAction(
    data: any,
    field: string,
    lineId: number | string
) {
    return {
        type: ACTIONS.MODIFY_CHALLENGES_SCRIPT_LINE as ACTIONS.MODIFY_CHALLENGES_SCRIPT_LINE,
        lineId: lineId,
        data: data,
        field: field,
    };
}

export function deleteChallengeScriptLineAction(data: any) {
    return {
        type: ACTIONS.DELETE_CHALLENGES_SCRIPT_LINE as ACTIONS.DELETE_CHALLENGES_SCRIPT_LINE,
        data,
    };
}

export function uploadChallengeAudioStart() {
    return {
        type: ACTIONS.UPLOAD_CHALLENGE_AUDIO_START as ACTIONS.UPLOAD_CHALLENGE_AUDIO_START,
    };
}

export function uploadChallengeAudioSuccess(
    scriptLineId: string | number,
    response: any
) {
    return {
        type: ACTIONS.UPLOAD_CHALLENGE_AUDIO_SUCCESS as ACTIONS.UPLOAD_CHALLENGE_AUDIO_SUCCESS,
        data: {
            scriptLineId,
            response,
        },
    };
}

export function uploadChallengeAudioFailure(error: any) {
    return {
        type: ACTIONS.UPLOAD_CHALLENGE_AUDIO_FAILURE as ACTIONS.UPLOAD_CHALLENGE_AUDIO_FAILURE,
        error,
    };
}

export function resetChallengeAction() {
    return {
        type: ACTIONS.RESET_CHALLENGE as ACTIONS.RESET_CHALLENGE,
    };
}

export function resetChallengeLogoAction() {
    return {
        type: ACTIONS.RESET_CHALLENGE_LOGO as ACTIONS.RESET_CHALLENGE_LOGO,
    };
}

export function fetchChallengeStart() {
    return {
        type: ACTIONS.FETCH_CHALLENGE_START as ACTIONS.FETCH_CHALLENGE_START,
    };
}

export function fetchChallengeSuccess(data: any) {
    return {
        type: ACTIONS.FETCH_CHALLENGE_SUCCESS as ACTIONS.FETCH_CHALLENGE_SUCCESS,
        data,
    };
}

export function fetchChallengeFailure(error: any) {
    return {
        type: ACTIONS.FETCH_CHALLENGE_FAILURE as ACTIONS.FETCH_CHALLENGE_FAILURE,
        error,
    };
}

export function modifyChallengeAction(
    data: any,
    field: string,
    silent?: boolean
) {
    return {
        type: ACTIONS.MODIFY_CHALLENGE as ACTIONS.MODIFY_CHALLENGE,
        field: field,
        data: data,
        silent: silent,
    };
}

export function storeChallengeTempCopyAction() {
    return {
        type: ACTIONS.STORE_CHALLENGE_TEMP_COPY as ACTIONS.STORE_CHALLENGE_TEMP_COPY,
    };
}

export function restoreChallengeFromTempAction() {
    return {
        type: ACTIONS.RESTORE_CHALLENGE_FROM_TEMP as ACTIONS.RESTORE_CHALLENGE_FROM_TEMP,
    };
}
