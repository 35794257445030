import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    useMemo
} from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import { useTeamEditState } from '../../../../store/states';
import {
    useSearchSingleTeamMembersService,
    useTogglePromoteTeamLeader,
    useDeleteSingleTeamMemberLabelService,
} from '../../../../store/services';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../../constants/interfaces/filters';
import { ListResult } from '../../../../../../constants/interfaces/PaginationResult';
import { UserProfile } from '../../../../../../constants/interfaces/User';
import { TeamMember } from '../../../../../../constants/interfaces/TeamMember';
import Checkbox from '../../../../../../ui/components/Checkbox';
import { ScrollPagination } from '../../../../../../ui/components/ScrollPagination';
import SwitchToggle from '../../../../../../ui/components/SwitchToggle/SwitchToggle';
import Crown from '../../../../../../ui/icons/Crown';
import AvatarPlaceholder from '../../../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../../../pages/UserProfile/store/reducers';
import { handleGenerateTeamLeadersCountText } from '../../tools';
import { pluralize } from '../../../../../../helpers/functions/pluralize';
import { WithLabelsContext } from '../../../../../portableLabels';
import FilterByHandler from '../../../../../../ui/components/Filters/FilterByHandler';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { Popup } from '../../../../../../ui/components/Popup';
import { AllUsersFilter } from '../../components/Filters/AllUsersFilters';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';

import { listToTree } from '../../../../../../helpers/functions/list-to-tree';
import { useHandleSelectLabels } from '../../../../../labels/tools';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import { Label } from '../../../../../../constants/interfaces/Label';
import { EmptyStateContainer } from '../../../../../../ui/components/EmptyStates/components/EmptyStateContainer';
import {
    EmptyState,
    NoFilterResults,
    NoSearchResults,
} from '../../../../../../ui/components/EmptyStates';
import User from '../../../../../../ui/icons/User';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { useUpdateUsersState } from '../../../../../users/store/states';
import { useSearchDebounced } from '../../../../../../helpers/hooks/useSearch';
import { usePortableLabelsState } from '../../../../../portableLabels/store/states';
import { TableHeaderCell } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell';
import AssignedLabelList from '../../../../../../ui/components/table-wrapper/table/TableLabelTags/AssignedLabelList';
import { TableHeaderCellProps } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/type';
import { TableStateValues } from '../../../../../../ui/components/table-wrapper';
import { TableDivider } from '../../../../../../ui/components/table-wrapper/table-divider';
import { TableLabelTags } from '../../../../../../ui/components/table-wrapper/table/TableLabelTags';
import TableTitleOverflowText from '../../../../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import {
    TableHead,
    TableToolsRight,
} from '../../../../../../ui/components/Table';
import { TableToolItem } from '../../../../../../ui/components/table-wrapper/table-tools/table-tool-item';
import { TableHeaderFilter } from '../../../../../../ui/components/table-wrapper/table-header-filter';
import { TableRow } from '../../../../../../ui/components/table-wrapper/table/table-row';
import { TableSearchInput } from '../../../../../../ui/components/table-wrapper/table-tools/table-search-input';
import { SelectAll } from '../../../../../../ui/components/table-wrapper/SelectAll';
import { useTableStateHelper } from '../../../../../../ui/components/table-wrapper/helper';
import { useOrderBy } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { TableRefresh } from '../../../../../../ui/components/table-wrapper/table-refresh';
import {
    useDeleteMembersFromTeamService,
    useUpdateTeamMembersBulkActionService,
} from '../../../../services/TeamBulkActionsService';
import {
    TeamMembersContainerProps,
    TeamMembersExternalInterface,
} from './types';
import { UPDATE_TEAM_ACTION } from '../../../../services/TeamBulkActionsService/constants';

const Container = styled.div`
    padding: 14px 23px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 ${props => props.theme.Colors.blackTwo5};
    border: solid 2px ${props => props.theme.Colors.darkSkyBlue};
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Header = styled.div`
    margin-bottom: 2px;
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    height: 24px;
    margin-bottom: 6px;
`;

const Counter = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
    height: 16px;
    text-align: left;
`;

const TableTools = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 8px 0;
    padding-right: 16px;
    position: relative;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    padding-right: 16px;
`;

const TableBody = styled.div<{ showHeight?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: ${props => (props.showHeight ? '1' : '0')};
    min-height: ${props => (props.showHeight ? '100px' : '0')};
    overflow: auto;
`;

const StyledTablePagination = styled(ScrollPagination)`
    width: 100%;
`;

const ListCell = styled.div<{ flex?: number }>`
    ${props => props.flex && `flex: ${props.flex};`}
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 13px;
`;

const TeamLeaderCell = styled(ListCell)`
    text-align: left;
    width: 110px;
`;

const ListRow = styled.div<{
    selected?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    border-radius: 4px;
    background: ${props =>
        props.selected ? props.theme.Colors.whiteFive : 'unset'};
    ${ListCell} {
        color: ${props =>
            props.disabled
                ? props.theme.Colors.cloudyBlue
                : props.theme.Colors.black};
    }
    margin-right: 12px;
    &:hover {
        background: var(--ps-grey-3);
    }
`;

const TableCheckboxCell = styled(TableHeaderCell)`
    padding: 4px 12px 4px 4px;
`;

const ListCheckboxCell = styled.div`
    height: 48px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
`;

const ManageUsersSection = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
`;

const ManageUsersButton = styled.div<{ disabled?: boolean }>`
    font-size: 13px;
    font-weight: 600;
    color: ${props =>
        props.disabled
            ? props => props.theme.Colors.cloudyBlue
            : props => props.theme.Colors.tomato};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    user-select: none;
    &:active {
        opacity: ${props => (props.disabled ? '1' : '0.7')};
    }
`;

const TeamLeaderIcon = styled.div`
    width: 14px;
    height: 12px;
    display: flex;
    margin-left: 4px;
    margin-right: 8px;
    margin-top: -3px;
    flex-shrink: 0;
    color: ${props => props.theme.Colors.macaroniAndCheese};
`;

const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
`;

const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    max-width: 90%;
`;

const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

const StyledAssignedLabelList = styled(AssignedLabelList)`
    padding-left: 28px;
`;

const SearchContainer = styled.div`
    flex: 1;
`;

const LabelsContainer = styled.div`
    height: 40px;
    width: 40px;
    margin-left: 8px;
`;

const ToggleWrapper = styled.span``;

const FilteredByLabelsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemCount = styled.p`
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
    padding: 0;
    margin: 0;
`;

const TeamMembers: FC<{
    users?: ListResult<TeamMember>;
    teamId: string;
    profile?: UserProfile;
    searchTerm?: string;
    setSearchTerm: (searchTerm: string) => void;
    orderByUserName: Partial<TableHeaderCellProps>;
    orderByTeamLeader: Partial<TableHeaderCellProps>;
    selectedUsers: number[];
    isSelectAll: boolean;
    onSelectUser: (id: number) => void;
    onSelectAllLoadedUsers: (selected: boolean) => void;
    onSelectAll: (selected: boolean) => void;
    handleTeamLeaderChange: (teamUserId: number, isTeamLead: boolean) => void;
    setOffset: (offset: number) => void;
    labelIDs: number[];
    setLabelIDs: (labelIds: number[]) => void;
    totalCount: number;
    labelsOpen?: number | null;
    checkboxDisabled: boolean;
    handleToggleLabels: (open: number) => void;
    tableStates: TableStateValues;
    deleteMemberLabel: (userId: number, labelId: number) => void;
    registrationStatusHandler: (status: string[]) => void;
    searchParams: SearchParams;
    clearAllUsers: () => void;
    onSuccessRemoveUsersBulkActionCallback: () => void;
    lastRefreshed: Date;
    refreshData: () => void;
    leadersCount?: number;
    dataTest: string;
}> = ({
    users,
    teamId,
    profile,
    setSearchTerm,
    orderByUserName,
    orderByTeamLeader,
    selectedUsers,
    isSelectAll,
    onSelectUser,
    onSelectAllLoadedUsers,
    onSelectAll,
    handleTeamLeaderChange,
    setOffset,
    labelIDs,
    setLabelIDs,
    totalCount,
    labelsOpen,
    checkboxDisabled,
    handleToggleLabels,
    tableStates,
    deleteMemberLabel,
    registrationStatusHandler,
    searchParams,
    clearAllUsers,
    onSuccessRemoveUsersBulkActionCallback,
    lastRefreshed,
    refreshData,
    leadersCount,
    dataTest,
}) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const labelsTree = listToTree(labelsList.data.items);
    const handleSelectLabels = useHandleSelectLabels();
    const ifLabelsChanged = useIfChanged(labelIDs);

    const onRemoveLabel = (label: Label) => {
        setLabelIDs(handleSelectLabels(label, labelIDs, labelsTree));
    };

    useEffect(() => {
        if (ifLabelsChanged) {
            setSelectLabels(labelIDs);
            saveLabels();
        }
    }, [labelIDs, ifLabelsChanged, setSelectLabels, saveLabels]);

    useEffect(() => {
        return () => clearAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //TODO: Refactor/remove from every page
    const handleAssignedLabelDelete = (labelId: number, userId: number) => {
        deleteMemberLabel(userId, labelId);
    };

    const scrollableRef = useRef<HTMLDivElement>(null);
    const filterByRegistrationLength: any =
        searchParams?.filterByRegistrationStatus?.length;

    const deleteMembersFromTeamServiceOptions = {
        parameters: {
            teamId: +teamId,
            searchParams: {
                ...searchParams,
                limit: ITEMS_PER_PAGE,
                offset: isSelectAll ? totalCount : selectedUsers.length,
            },
            selectedItemIds: !isSelectAll ? selectedUsers : undefined,
            itemsCount: selectedUsers?.length ?? 0,
        },
        fieldName: !isSelectAll ? 'selectedItemIds' : 'searchParams.offset',
    };

    const deleteMembersFromTeam = useDeleteMembersFromTeamService();

    /**
     * @function deleteMembersFromTeamSuccessCallback
     * @returns { void }
     */
    const deleteMembersFromTeamSuccessCallback = useCallback(() => {
        Promise.resolve().then(() => {
            clearAllUsers();
            refreshData();
            onSuccessRemoveUsersBulkActionCallback();
        });
    }, [clearAllUsers, refreshData, onSuccessRemoveUsersBulkActionCallback]);

    const {
        handleStartBulkActionService:
            handleStartDeleteMembersFromTeamBulkAction,
    } = useUpdateTeamMembersBulkActionService(
        {
            actionName: UPDATE_TEAM_ACTION,
            actionFunction: deleteMembersFromTeam,
            actionFunctionOptions: deleteMembersFromTeamServiceOptions,
        },
        'Removing Users',
        deleteMembersFromTeamSuccessCallback
    );

    /**
     * @function handleRemoveSelectedUsers
     * @returns { void }
     */
    const handleRemoveSelectedUsers = useCallback(() => {
        handleStartDeleteMembersFromTeamBulkAction();
    }, [handleStartDeleteMembersFromTeamBulkAction]);

    return (
        <Container>
            <Header>
                <Title data-test={`${dataTest}-title`}>Team Members</Title>
                <Counter data-test={`${dataTest}-leaders-counter`}>
                    {handleGenerateTeamLeadersCountText(leadersCount ?? 0)}
                </Counter>
            </Header>
            <Header>
                <TableRefresh
                    lastRefreshed={lastRefreshed}
                    refreshData={refreshData}
                    dataTest={`${dataTest}-timestamp`}
                    top={-20}
                    fontSize={11}
                />
            </Header>

            <TableDivider />

            <TableTools>
                <SelectAll
                    style={{
                        width: '100%',
                        height: 60,
                        marginTop: -10,
                    }}
                    isSelectAll={isSelectAll}
                    selectedLength={selectedUsers.length}
                    totalCount={totalCount}
                    itemsPerPage={ITEMS_PER_PAGE}
                    onClearSelecion={() => {
                        clearAllUsers();
                    }}
                    onSelectAll={() => {
                        onSelectAllLoadedUsers(true);
                        onSelectAll(true);
                    }}
                    dataTest={dataTest}
                >
                    <span></span>
                </SelectAll>
                <SearchContainer>
                    <TableSearchInput
                        onChange={setSearchTerm}
                        disabled={tableStates.disableSearch}
                    />
                </SearchContainer>
                <LabelsContainer>
                    <Popup<HTMLButtonElement>
                        content={({ hide }) => (
                            <AllUsersFilter
                                onLabelsChange={setLabelIDs}
                                onSuccessApply={() => hide()}
                                currentLabelIds={labelIDs}
                                registrationStatus={
                                    searchParams.filterByRegistrationStatus ||
                                    []
                                }
                                onRegistrationStatusChange={
                                    registrationStatusHandler
                                }
                            />
                        )}
                    >
                        {(ref, { toggleShown, shown }) => (
                            <FilterByHandler
                                ref={ref}
                                open={shown}
                                disabled={
                                    tableStates.disableFilters ||
                                    !labelsList.data.items.length
                                }
                                toggleOpen={toggleShown}
                                filtersCount={
                                    labelIDs.length + filterByRegistrationLength
                                }
                                dataTest={`${dataTest}-filters`}
                            />
                        )}
                    </Popup>
                </LabelsContainer>
                <TableToolsRight>
                    <TableToolItem>
                        <ItemCount data-test={`${dataTest}-counter`}>
                            {' '}
                            {totalCount}{' '}
                            {totalCount === 1 ? 'Item' : 'Items'}{' '}
                        </ItemCount>
                    </TableToolItem>
                </TableToolsRight>
            </TableTools>
            <TableDivider />
            <TableContainer>
                {labelIDs && (
                    <FilteredByLabelsContainer>
                        <TableHeaderFilter
                            selectedLabels={labelIDs}
                            onRemoveLabel={
                                labels.saved.length > 0
                                    ? onRemoveLabel
                                    : undefined
                            }
                        />
                    </FilteredByLabelsContainer>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCheckboxCell
                                dataTest={`${dataTest}-column-checkbox`}
                            >
                                <Checkbox
                                    checked={!!selectedUsers.length}
                                    size={12}
                                    partial={!isSelectAll}
                                    handleChange={e => {
                                        onSelectAllLoadedUsers(e.target.checked);
                                        onSelectAll(false);
                                    }}
                                    disabled={checkboxDisabled}
                                />
                            </TableCheckboxCell>
                            <TableHeaderCell
                                {...orderByUserName}
                                width={97}
                                disabled={tableStates.disableSorting}
                                dataTest={`${dataTest}-column-users`}
                            >
                                Users
                            </TableHeaderCell>
                            <TableHeaderCell
                                {...orderByTeamLeader}
                                disabled={tableStates.disableSorting}
                                dataTest={`${dataTest}-column-team-leader`}
                            >
                                Team Leader
                            </TableHeaderCell>
                        </TableRow>
                    </TableHead>
                </Table>

                <TableBody
                    ref={scrollableRef}
                    showHeight={!tableStates.showEmptyState}
                >
                    {!tableStates.showEmptyState && (
                        <StyledTablePagination
                            itemsPerPage={ITEMS_PER_PAGE}
                            itemCount={users ? users.items.length : 0}
                            totalCount={totalCount}
                            onOffsetChange={setOffset}
                            scrollableRef={scrollableRef}
                        >
                            {users &&
                                users.items.map(member => {
                                    return (
                                        <React.Fragment key={member.user.id}>
                                            <ListRow
                                                data-test={`${dataTest}-item`}
                                            >
                                                <ListCheckboxCell>
                                                    <Checkbox
                                                        checked={selectedUsers.includes(member.user.id)}
                                                        size={12}
                                                        handleChange={() => {
                                                            onSelectUser(member.user.id);
                                                        }}
                                                        dataTest={`${dataTest}-item-checkbox`}
                                                    />
                                                </ListCheckboxCell>
                                                <ListCell flex={50}>
                                                    <StyledFirstTableContent>
                                                        <TableLabelTags
                                                            open={
                                                                labelsOpen ===
                                                                member.user.id
                                                            }
                                                            toggleOpen={() =>
                                                                handleToggleLabels(
                                                                    member.user
                                                                        .id
                                                                )
                                                            }
                                                            selectedLabels={
                                                                member.labels
                                                            }
                                                            dataTest={`${dataTest}-item-labels`}
                                                        />
                                                        <AvatarTile>
                                                            <Avatar
                                                                customProfile={{
                                                                    firstName:
                                                                        member
                                                                            .user
                                                                            .firstName,
                                                                    lastName:
                                                                        member
                                                                            .user
                                                                            .lastName,
                                                                    picture:
                                                                        member
                                                                            .user
                                                                            .imageUrl,
                                                                    status: member.userStatus,
                                                                }}
                                                                size="sm"
                                                                dataTest={`${dataTest}-item-avatar`}
                                                            />
                                                            <TableTitleOverflowText
                                                                isCurrent={
                                                                    profile &&
                                                                    profile.id ===
                                                                        member
                                                                            .user
                                                                            .id
                                                                }
                                                                renderIcon={
                                                                    member.isTeamLead && (
                                                                        <TeamLeaderIcon data-test={`${dataTest}-item-crown`}>
                                                                            <Crown />
                                                                        </TeamLeaderIcon>
                                                                    )
                                                                }
                                                                dataTest={`${dataTest}-item-name`}
                                                                minWidth="auto"
                                                            >
                                                                {`${member.user.firstName} ${member.user.lastName}`}
                                                            </TableTitleOverflowText>
                                                        </AvatarTile>
                                                    </StyledFirstTableContent>
                                                </ListCell>
                                                <TeamLeaderCell>
                                                    <ToggleWrapper>
                                                        <SwitchToggle
                                                            index={
                                                                member.user.id
                                                            }
                                                            checked={
                                                                member.isTeamLead
                                                            }
                                                            onChange={e => {
                                                                handleTeamLeaderChange(
                                                                    member.id,
                                                                    e.target
                                                                        .checked
                                                                );
                                                            }}
                                                            height={24}
                                                            width={42}
                                                            bulletRadius={16}
                                                            dataTest={`${dataTest}-item-team-lead-toggle`}
                                                        />
                                                    </ToggleWrapper>
                                                </TeamLeaderCell>
                                            </ListRow>
                                            {labelsOpen === member.user.id && (
                                                <StyledAssignedLabelList
                                                    selectedLabels={
                                                        member.labels || []
                                                    }
                                                    deletePermissions={[
                                                        NEW_PERMISSIONS.REMOVE_USER_LABEL,
                                                    ]}
                                                    onDeleteLabel={(
                                                        labelId: any
                                                    ) =>
                                                        handleAssignedLabelDelete(
                                                            labelId,
                                                            member.user.id
                                                        )
                                                    }
                                                />
                                            )}{' '}
                                        </React.Fragment>
                                    );
                                })}
                        </StyledTablePagination>
                    )}
                </TableBody>

                {tableStates.showEmptyState && (
                    <EmptyStateContainer>
                        {tableStates.showNoEntriesState && (
                            <EmptyState
                                icon={User}
                                width={'224px'}
                                dataTest={`${dataTest}-no-entries`}
                            >
                                Add Members
                            </EmptyState>
                        )}
                        {tableStates.showNoSearchResultsState && (
                            <NoSearchResults
                                entityName={'Members'}
                                dataTest={`${dataTest}-no-search-results`}
                            />
                        )}
                        {tableStates.showNoFilterResultsState && (
                            <NoFilterResults
                                dataTest={`${dataTest}-no-filter-results`}
                            />
                        )}
                    </EmptyStateContainer>
                )}
            </TableContainer>
            <TableDivider />
            <ManageUsersSection>
                <ManageUsersButton
                    disabled={selectedUsers.length < 1}
                    onClick={() => {
                        if (selectedUsers.length > 0) {
                            handleRemoveSelectedUsers();
                        }
                    }}
                    data-test={`${dataTest}-remove-selected`}
                >
                    {selectedUsers.length > 0
                        ? `Remove ${pluralize(
                              isSelectAll
                                  ? totalCount
                                  : selectedUsers.length,
                              'Selected User'
                          )}`
                        : 'Remove Selected Users'}
                </ManageUsersButton>
            </ManageUsersSection>
        </Container>
    );
};

const ITEMS_PER_PAGE = 20;

const TeamMembersContainer = forwardRef(
    (
        { onSuccessRemoveUsersBulkActionCallback }: TeamMembersContainerProps,
        ref
    ) => {
        const params: { teamId: string } = useParams();
        const teamEditState = useTeamEditState();
        const profile = useSelector(getProfileState);
        const teamId = params.teamId;
        const searchTeamMembers = useSearchSingleTeamMembersService(teamId);
        const searchTeamMembersDebounced =
            useSearchDebounced(searchTeamMembers);
        const handleTableStates = useTableStateHelper();
        const updatedUsersState = useUpdateUsersState();
        const deleteMemberLabel = useDeleteSingleTeamMemberLabelService(teamId);

        const initialSearchParams: SearchParams = {
            searchTerm: '',
            filters: [],
            labelIDs: [],
            orderBy: null,
            limit: ITEMS_PER_PAGE,
            offset: 0,
            totalCount: 0,
            numberOfPages: 0,
        };

        const {
            searchParams,
            setSearchTerm,
            setOrderBy,
            setLabelIDs,
            setOffset,
            setCustom,
        } = useSearchParamsState(initialSearchParams);

        useEffect(() => {
            searchTeamMembersDebounced(searchParams);
        }, [
            searchParams,
            searchTeamMembersDebounced,
            teamId,
            updatedUsersState.data,
        ]);

        const orderBy = searchParams.orderBy;
        const orderByUserName = useOrderBy('name', orderBy, setOrderBy);
        const orderByTeamLeader = useOrderBy(
            'is_team_lead',
            orderBy,
            setOrderBy
        );

        const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
        const [isSelectAll, setIsSelectAll] = useState(false);
        const userItems = useMemo(
            () => teamEditState.teamMembers ? teamEditState.teamMembers.items : [],
            [teamEditState.teamMembers]
        );
        const totalCount = teamEditState.teamMembers?.count ?? 0;
        const checkboxDisabled = userItems.length < 1;
        const isPartiallySelected = selectedUsers.length < userItems.length;

        /**
         * @function handleSelectUser
         * @param { number } id
         * @returns { void }
         */
        const handleSelectUser = (id: number) => {
            const newSelectedUsers = selectedUsers.includes(id)
                ? selectedUsers.filter(userId => userId !== id)
                : selectedUsers.concat(id);
            setSelectedUsers(newSelectedUsers);
            setIsSelectAll(false);
        };
        /**
         * @function handleSelectAllLoadedUsers
         * @param { boolean } selected
         * @returns { void }
         */
         const handleSelectAllLoadedUsers = (selected: boolean) => {
            setSelectedUsers(
                selected || isPartiallySelected ? userItems.map(item => item.user.id) : []
            );
        };
        /**
         * @function clearAllUsers
         * @returns { void }
         */
         const clearAllUsers = () => {
            setSelectedUsers([]);
            setIsSelectAll(false);
        };

        useEffect(() => {
            if (!isSelectAll && selectedUsers.length === totalCount && totalCount > 0) {
                setIsSelectAll(true);
            }
        }, [isSelectAll, totalCount, selectedUsers]);
    
        useEffect(() => {
            if (
                isSelectAll &&
                !isEmpty(selectedUsers) &&
                selectedUsers.length < userItems.length
            ) {
                setSelectedUsers(userItems.map(item => item.user.id));
            }
        }, [isSelectAll, selectedUsers, userItems]);

        const handleTeamLeaderChange = useTogglePromoteTeamLeader();

        const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
            null
        );

        const handleToggleLabels = (itemId?: number) => {
            if (!itemId) return;
            if (labelsOpen === itemId) {
                setLabelsOpen(null);
            } else {
                setLabelsOpen(itemId);
            }
        };

        const registrationStatusHandler = (values: string[]) => {
            setCustom('filterByRegistrationStatus', values);
        };

        const [lastRefreshed, setLastRefreshed] = useState(new Date());
        /**
         * @description reloads data with the same search params and updates last refreshed date to the current date/time
         * @function refreshData
         * @returns { void }
         */
        const refreshData = useCallback(() => {
            searchTeamMembers(
                {
                    ...searchParams,
                    limit: ITEMS_PER_PAGE + searchParams.offset, // we should increase the limit to fetch all loaded data again
                    offset: 0,
                },
                true
            );
            setLastRefreshed(new Date());
        }, [searchParams, searchTeamMembers]);
        useImperativeHandle<any, TeamMembersExternalInterface>(ref, () => ({
            refreshData,
        }));

        const registrationsStatusLength: any =
            searchParams.filterByRegistrationStatus?.length;

        const tableStates = handleTableStates({
            searchTerm: searchParams.searchTerm,
            appliedFilters:
                searchParams.labelIDs?.length + registrationsStatusLength,
            itemsCount: totalCount,
        });

        return (
            <WithLabelsContext.Provider
                value={{
                    reducerName: 'teamEdit',
                    scope: 'teamMembers',
                }}
            >
                <TeamMembers
                    users={teamEditState.teamMembers}
                    profile={profile}
                    teamId={teamId}
                    searchTerm={searchParams.searchTerm}
                    setSearchTerm={setSearchTerm}
                    orderByUserName={orderByUserName}
                    orderByTeamLeader={orderByTeamLeader}
                    selectedUsers={selectedUsers}
                    isSelectAll={isSelectAll}
                    onSelectUser={handleSelectUser}
                    onSelectAllLoadedUsers={handleSelectAllLoadedUsers}
                    onSelectAll={setIsSelectAll}
                    handleTeamLeaderChange={handleTeamLeaderChange}
                    setOffset={setOffset}
                    labelIDs={searchParams.labelIDs || []}
                    setLabelIDs={setLabelIDs}
                    totalCount={totalCount}
                    labelsOpen={labelsOpen}
                    checkboxDisabled={checkboxDisabled}
                    handleToggleLabels={handleToggleLabels}
                    tableStates={tableStates}
                    deleteMemberLabel={deleteMemberLabel}
                    registrationStatusHandler={registrationStatusHandler}
                    searchParams={searchParams}
                    clearAllUsers={clearAllUsers}
                    onSuccessRemoveUsersBulkActionCallback={
                        onSuccessRemoveUsersBulkActionCallback
                    }
                    lastRefreshed={lastRefreshed}
                    refreshData={refreshData}
                    leadersCount={teamEditState.data?.leaders?.length}
                    dataTest="team-members"
                />
            </WithLabelsContext.Provider>
        );
    }
);

export default TeamMembersContainer;
