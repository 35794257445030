import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { Theme } from '../../../constants/interfaces/Theme';
import { StyleProps } from './types';

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    menuListClassName: {
        width: ({ customWidth }) =>
            customWidth ? `${customWidth}px` : '128px',
        boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.2)',
        padding: '16px 8px',
        borderRadius: '8px !important',
        right: ({ customLeft }) => `${customLeft || '40'}px !important`,
        bottom: ({ customLeft }) => `${customLeft ? '5px': 'unset' } !important`,
        marginTop: ({ marginTop }) =>
            marginTop ? `${marginTop}px !important` : '10px !important',
        background: '#fff',
    },
    popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            zIndex: 2,
            width: '1em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent var(--ps-white-1) transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            zIndex: 2,
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `var(--ps-white-1) transparent transparent transparent`,
                boxShadow: `-3px -4px 5px rgba(0, 0, 0, 0.015),
                0 0px white, 
                -7px -3px 5px  rgba(0, 0, 0, 0.015), 
                0 0px white`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            zIndex: 2,
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent var(--ps-white-1) transparent transparent`,
                boxShadow: `-3px -4px 5px rgba(0, 0, 0, 0.015),
                0 0px white, 
                -7px -3px 5px  rgba(0, 0, 0, 0.015), 
                0 0px white`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            zIndex: 2,
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent var(--ps-white-1)`,
                boxShadow: `-3px -4px 5px rgba(0, 0, 0, 0.015),
                0 0px white, 
                -7px -3px 5px  rgba(0, 0, 0, 0.015), 
                0 0px white`,
            },
        },
    },

    arrow: {
        position: 'absolute',
        fontSize: 10,

        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
}));

export const ActionMenuHandler = styled.div<{
    open: boolean;
    hasCustomComponent: boolean;
}>`
    width: 10px;
    ${({ hasCustomComponent }) => !hasCustomComponent && `height: 24px;`}
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: ${props => props.theme.Colors.steelGrey};
    box-shadow: ${props =>
        props.open && !props.hasCustomComponent
            ? `0 0 0 1px ${props.theme.Colors.cloudyBlue}`
            : 'none'};
    background-color: ${props =>
        props.open && !props.hasCustomComponent
            ? props.theme.Colors.whiteTwo
            : 'unset'};
`;

export const Button = styled.div`
    display: flex;
    justify-content: flex-end;
    cursor: default;
    height: 100%;
`;

export const IconContainer = styled.div`
    height: 100%;
    width: 12px;
    cursor: pointer;
    margin: 0 5px;
    display: flex;
    align-items: center;
    &:active {
        opacity: 0.7;
    }
`;
