import { FC } from 'react';
import styled from 'styled-components';
import Content from './Content/Content';

export type MenuInterface = { key: string; title: string };

const CustomBodyContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding-top: 24px;
`;

const ContainerBody = styled.div`
    display: flex;
    overflow: hidden;
    flex: 1;
`;

const CustomBodyContainer: FC = () => {
    return (
        <CustomBodyContainerWrapper>
            <ContainerBody>
                <Content />
            </ContainerBody>
        </CustomBodyContainerWrapper>
    );
};

export default CustomBodyContainer;
