import { History, Location } from 'history';
import { useHistory as useBaseHistory, useLocation } from 'react-router';

export enum LocationStateType {
    MODAL = 'MODAL',
    EXTERNAL_URL = 'EXTERNAL_URL',
}

export interface LocationState {
    type?: LocationStateType;
    prevLocation?: Location<LocationState>;
    useGoBack?: boolean;
    background?: Location<LocationState>;
    companyId?: any;
    data?: any;
}

export function useLocations() {
    const history = useBaseHistory<LocationState>();
    const location = useLocation<LocationState>(); //current location
    const state = location.state;

    var rootLocation: Location<LocationState> = location;
    var modalLocation: Location<LocationState> | undefined = undefined;

    if (state) {
        if (state.type === LocationStateType.EXTERNAL_URL) {
            history.location = location;
        } else if (
            state.type === LocationStateType.MODAL ||
            !!state.background
        ) {
            rootLocation = findRootLocation(location);
            modalLocation = location;
        }
    }

    return {
        rootLocation,
        modalLocation,
    };
}

export function findRootLocation(location: Location<LocationState>) {
    var result: Location<LocationState> = location;
    var state: LocationState | undefined = location.state;
    while (!!state) {
        if (state.background) result = state.background;
        else if (state.prevLocation) result = state.prevLocation;
        else break;
        state = result.state;
    }
    return result;
}

export function useHistory() {
    return useBaseHistory<LocationState>();
}

export function pushModal(
    history: History<LocationState>,
    path: string,
    data?: any,
) {
    const state = history.location.state;
    return history.push(path, {
        type: LocationStateType.MODAL,
        prevLocation: state?.type === LocationStateType.MODAL
            ? state?.prevLocation 
            : state?.background ?? history.location,
        data,
    });
}

export function replaceModal(
    history: History<LocationState>,
    path: string,
    data?: any,
) {
    const state = history.location.state;
    return history.replace(path, {
        type: LocationStateType.MODAL,
        prevLocation: state?.type === LocationStateType.MODAL
            ? state?.prevLocation 
            : state?.background ?? history.location,
        data
    });
}


