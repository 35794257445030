import { useCallback, useEffect, useState, useRef } from 'react'
import { VoiceSampleStatus, VoiceSampleTextProps } from './types';
import VoiceSampleTextView from './view';
import { generateAudioWithElevenLabs } from '../../../../../../services/ElevenLabs';

function VoiceSampleText({
    settings,
    initialValue,
    isDisabled,
    dataTest,
    onAction,
}: VoiceSampleTextProps) {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState<string | undefined>();
    const [status, setStatus] = useState<VoiceSampleStatus>('stopped');
    const [blobUrl, setBlobUrl] = useState<string | undefined>();
    const audioRef = useRef<HTMLAudioElement>(null);

    const resultValue = value ?? initialValue;
    const canPlay = resultValue.trim().length > 0 && !isDisabled;

    /**
     * @function handleStop
     * @description stops audio play and sets stopped status
     * @returns { void }
     */
    const handleStop = useCallback(() => {
        audioRef.current!.pause();
        audioRef.current!.currentTime = 0;
        setStatus('stopped');
    }, []);

    /**
     * @function handleChange
     * @description stores new value in the state and resets audio data
     * @param { string } val
     * @returns { void }
     */
    const handleChange = useCallback((val: string) => {
        setValue(val);
        handleStop();
        setBlobUrl(undefined);
    }, [handleStop]);

    /**
     * @function handleAudioLoaded
     * @description plays audio and sets playing status
     * @returns { void }
     */
    const handleAudioLoaded = useCallback(() => {
        audioRef.current!.play();
        setStatus('playing');
    }, []);

    /**
     * @function handlePlay
     * @description if audio is already generated, plays it. otherwise, it sends a request to generate a new audio and then plays it
     * @returns { void }
     */
    const handlePlay = useCallback(() => {
        if (!canPlay) return;
        if (blobUrl) {
            handleAudioLoaded();
        } else {
            setStatus('generating');
            generateAudioWithElevenLabs({
                text: resultValue,
                stability: settings.stability,
                similarityBoost: settings.similarity,
                voiceId: settings.voice
            }).then(blob => {
                const url = URL.createObjectURL(blob);
                setBlobUrl(url);
            });
        }
    }, [canPlay, blobUrl, handleAudioLoaded, resultValue, settings]);

    useEffect(() => {
        onAction(status !== 'stopped');
    }, [onAction, status]);

    useEffect(() => {
        handleStop();
        setBlobUrl(undefined);
    }, [handleStop, settings]);

    return (
        <VoiceSampleTextView
            value={resultValue}
            isFocused={isFocused}
            canPlay={canPlay}
            status={status}
            blobUrl={blobUrl}
            audioRef={audioRef}
            dataTest={dataTest}
            onChange={handleChange}
            setIsFocused={setIsFocused}
            onAudioLoaded={handleAudioLoaded}
            onPlay={handlePlay}
            onStop={handleStop}
        />
    )
}

export default VoiceSampleText;