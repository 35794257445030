import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import { SearchAiLogsApiParams } from './types';

/**
 * @function useSearchAiLogsApi
 * @returns { SearchAiLogsApiParams }
 */
export const useSearchAiLogsApi = (): SearchAiLogsApiParams => {
    const http = useHttp();
    return useCallback(
        params =>
            http.get('submissions/assessments', params, undefined, true),
        [http]
    );
};