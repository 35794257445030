import { useCallback } from 'react';

import { useSearchTeamsApi } from './api';
import {
    clearAction,
    saveAction,
    searchFailureAction,
    searchSuccessAction,
    selectAllAction,
    deselectAllAction,
    selectTeamsAction,
    searchStartAction,
    resetSelectedAction,
    resetAction,
    selectMultipleTeamsAction,
} from './actions';

import { useEncapsulatedDispatch } from './helpers/useEncapsulatedDispatch';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import { useDispatch } from 'react-redux';
import { NOT_AUTHORIZED_ERROR_MESSAGE } from '../../../../../ui/components/ErrorMessages/constants';

export const useSearchTeamsService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const searchTeams = useSearchTeamsApi();

    return useCallback(
        (sp?: SearchParams) => {
            dispatch(searchStartAction());

            return searchTeams(sp)
                .then(data => {
                    dispatch(searchSuccessAction(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchFailureAction(error.message));
                    error?.message !== NOT_AUTHORIZED_ERROR_MESSAGE &&
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, showMessage, searchTeams]
    );
};

export const useClearTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(clearAction());
    }, [dispatch]);
};

export const useSaveTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(saveAction());
    }, [dispatch]);
};

export const useSelectTeams = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: number) => {
            dispatch(selectTeamsAction(ids));
        },
        [dispatch]
    );
};

export const useSelectMultipleTeams = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: number[]) => {
            dispatch(selectMultipleTeamsAction(ids));
        },
        [dispatch]
    );
};

export const useSelectAllTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(
        (processedTeams: number[]) => {
            dispatch(selectAllAction(processedTeams));
        },
        [dispatch]
    );
};

export const useDeselectAllTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(deselectAllAction());
    }, [dispatch]);
};

export const useResetSelectedTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetSelectedAction());
    }, [dispatch]);
};

export const useResetTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetAction());
    }, [dispatch]);
};
