import React, { FC } from 'react';
import styled from 'styled-components';

export const FiltersPopupFieldSets: FC = props => {
    const { children } = props;
    return (
        <Root>
            {children}
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;
