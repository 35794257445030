import styled from 'styled-components';

export const Td = styled.td<{
    width?: number;
    clickable?: boolean;
    flex?: { display: string; justifyContent: string; alignItems: string };
}>`
    height: 55px;
    font-size: 13px;
    font-weight: 400;
    padding: 4px 8px 4px 0px;
    ${props => (props.width ? `width: ${props.width}%` : '')};
    &:hover {
        opacity: ${props => (props.clickable ? 0.7 : 1)};
    }
    ${props =>
        props.clickable &&
        `
        cursor: pointer;
    `}
    &:nth-child(1) {
        padding-left: 16px;
    }
`;
