import { forwardRef } from 'react';
import { IconProps } from './types';

export const OverdueLearnersNoResult = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <g clip-path="url(#nwn28ygtwa)">
                    <g clip-path="url(#2v0qqq962b)" fill="#B1C0CB">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 20v25h9.042v3H7.5c-1.193 0-2.656-.156-3.5-1-.844-.844-1-2.306-1-3.5v-33c0-1.193.156-2.656 1-3.5.844-.844 2.307-1 3.5-1h4.418L12 2c.008-.594.08-1.08.5-1.5l.14-.143c.286-.294.34-.35.86-.357.53.006.87.363.978.477L14.5.5c.444.444.464.83.49 1.311.002.062.006.124.01.189l.042 4H33.02l.481-4a2.192 2.192 0 0 0-.1-.6c-.09-.334-.17-.63.1-.9l.14-.143c.286-.294.34-.35.86-.357.494.005.57.08.846.351L35.5.5c.26.246.181.546.094.876-.053.199-.108.409-.094.624l.225 4H40.5c1.173 0 2.66.182 3.5 1 .84.818.969 2.207 1 3.38V15h-3V9H6v8h18v3H6z"/>
                        <path d="M32.342 31.647c-1.394-2.842-1.245-5.775-.39-8.11.429-1.169 1.024-2.155 1.654-2.876.047-.053.093-.105.14-.155 1.023 3.935 3.783 6.726 5.647 8.612l.271.274c2.19 2.225 3.836 3.947 3.836 7.307 0 5.638-4.735 9.801-10.501 9.801-5.768 0-10.5-4.142-10.499-9.801 0-1.608.502-3.094 1.285-4.166.923 1.573 2.151 2.691 3.355 3.396.827.485 1.68.796 2.457.906.387.054.8.065 1.2-.008.39-.07.88-.242 1.274-.645 1.17-1.193 1.03-2.985.27-4.535z" stroke="#B1C0CB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 0h3v6h-3z"/>
                        <path d="M12 1.5a1.5 1.5 0 0 1 3 0V6h-3V1.5zM33 0h3v6h-3z"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="nwn28ygtwa">
                        <path fill="#fff" d="M0 0h48v48H0z"/>
                    </clipPath>
                    <clipPath id="2v0qqq962b">
                        <path fill="#fff" d="M0 0h48v48H0z"/>
                    </clipPath>
                </defs>
            </svg>);
    }
);

export default OverdueLearnersNoResult;