import styled from 'styled-components';

export const CardContainer = styled.div`
    width: 280px;
    height: 184px;
    padding: 24px 16px;
    border-radius: 4px;
    border: solid 1px var(--ps-grey-4);
    background-color: var(--ps-white-1);
`;

export const Icon = styled.div<{size: number}>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: ${props => props.size / 2}px;
    background-color: var(--ps-grey-4);
    animation: pulse 1.5s ease-in-out infinite;

    @keyframes pulse {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 0.2;
        }
    }
`;

export const CardIcon = styled(Icon)`
    margin-bottom: 20px;
`;

export const SingleTextLine = styled.div<{ width: number, height?: number }>`
    width: ${props => props.width}px;
    height: ${props => props.height ?? 8}px;
    background-color: var(--ps-grey-4);
    animation: pulse 1.5s ease-in-out infinite;

    @keyframes pulse {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 0.2;
        }
    }
`;

export const RoundSkeleton = styled(SingleTextLine)`
    border-radius: 50%
`;


export const TextLine = styled(SingleTextLine)`
    margin-bottom: 10px;
`;

export const ResultFormat = styled.div`
    width: 44px;
    height: 20px;
    border-radius: 2px;
    background-color: var(--ps-grey-4);
    display: inline-block;
    margin-top: 2px;

    animation: pulse 1.5s ease-in-out infinite;

    @keyframes pulse {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 0.2;
        }
    }
`;