import { useCallback } from 'react';
import { RoleplayData } from '../../pages/Roleplays/CreateRoleplay/types';
import { useHttp } from '../../tools/http/Http';
import { CreateRoleplayApiParams, GetRoleplayApiParams, GetRoleplaysApiParams, RevertRoleplayApiParams, GenerateRoleplayApiParams, DeleteRoleplayApiParams, DuplicateRoleplayApiParams, UpdateRoleplayApiParams, CreateCommandRoleplayApiParams, GetRoleplayContentApiParams, RegenerateRoleplayApiParams, ResetRoleplayApiParams, GetRoleplayContentByApiParams } from './types';

/**
 * @function useGetRoleplays
 * @returns { GetRoleplaysApiParams }
 */
export function useGetRoleplaysApi(): GetRoleplaysApiParams {
    const http = useHttp();
    return useCallback(
        (params) => http.get('/roleplays', params, undefined, true),
        [http]
    );
}

/**
 * @function useCreateRoleplayApi
 * @returns { Roleplay }
 */
export function useCreateRoleplayApi(): CreateRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        (roleplayData: RoleplayData) =>
            http.post(`/roleplays`, roleplayData, true),
        [http]
    );
}

/**
 * @function useDuplicateRoleplayApi
 * @returns { Roleplay }
 */
export function useDuplicateRoleplayApi(): DuplicateRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId, title}) =>
            http.post(`roleplays/${roleplayId}/clone`, {title}, true),
        [http]
    );
}

/**
 * @function useDeleteRoleplayApi
 * @returns { Roleplay }
 */
export function useDeleteRoleplayApi(): DeleteRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        (rolepayIds) =>
            http.delete(`roleplays`, rolepayIds, true),
        [http]
    );
}

/**
 * @function useGetRoleplay
 * @returns { GetRoleplayApiParams }
 */
export function useGetRoleplayApi(): GetRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId}) => http.get(`roleplays/${roleplayId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useUpdateRoleplayApi
 * @returns { Roleplay }
 */
export function useUpdateRoleplayApi(): UpdateRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        (roleplayId: string, roleplayData: RoleplayData) =>
            http.put(`/roleplays/${roleplayId}`, roleplayData, true),
        [http]
    );
}

/**
 * @function useGetRoleplayContent
 * @returns { RoleplayContent }
 */
export function useGetRoleplayContentApi(): GetRoleplayContentApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId}) => http.get(`roleplays/${roleplayId}/content`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useCreateCommandRoleplayApi
 * @returns { Roleplay }
 */
export function useCreateCommandRoleplayApi(): CreateCommandRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId, text}) =>
            http.post(`roleplays/${roleplayId}/command`, {text}, true),
        [http]
    );
}

/**
 * @function useGenerateRoleplayApi
 * @returns { Roleplay }
 */
export function useGenerateRoleplayApi(): GenerateRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId}) =>
            http.post(`roleplays/${roleplayId}/generate-initial`, undefined, true),
        [http]
    );
}

/**
 * @function useRegenerateRoleplayApi
 * @returns { Roleplay }
 */
export function useRegenerateRoleplayApi(): RegenerateRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId}) =>
            http.post(`roleplays/${roleplayId}/regenerate`, undefined, true),
        [http]
    );
}

/**
 * @function useResetRoleplayApi
 * @returns { Roleplay }
 */
export function useResetRoleplayApi(): ResetRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId}) =>
            http.post(`roleplays/${roleplayId}/clear`, undefined, true),
        [http]
    );
}

/**
 * @function useGetRoleplayContentById
 * @returns { RoleplayContent }
 */
export function useGetRoleplayContentByIdApi(): GetRoleplayContentByApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId, contentId}) => http.get(`roleplays/${roleplayId}/content/${contentId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useRevertRoleplayApi
 * @returns { Roleplay }
 */
export function useRevertRoleplayApi(): RevertRoleplayApiParams {
    const http = useHttp();
    return useCallback(
        ({roleplayId}) =>
            http.post(`roleplays/${roleplayId}/cancel/`, undefined, true),
        [http]
    );
}