import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { ACTIONS, MultipleTeamsFilterActions } from './actions';
import { initialMultipleTeamsFilterState } from './constants';
import { MultipleTeamsFilterState } from './types';

export const multipleTeamsFilterReducer: Reducer<
    MultipleTeamsFilterState,
    MultipleTeamsFilterActions
> = (state = initialMultipleTeamsFilterState, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_MULTIPLE_TEAMS:
            return {
                ...state,
                selectedTeamIds: action.teamIds,
            };
        case ACTIONS.DISABLE_MULTIPLE_TEAMS_FILTER:
            return {
                ...state,
                isDisabled: action.isDisabled,
            }
        default:
            return state;
    }
};

export const useMultipleTeamsFilterState = () =>
    useSelector(state => state.multipleTeamsFilter);