import { Reducer } from 'redux';
import { SocketProgressStateInterface } from './states';
import { ACTIONS, SocketProgressShow, SocketProgressHide } from './actions';
import { AppState } from '../../../store/reducers';

const initialState: SocketProgressStateInterface = {
    show: false,
};

export const getSocketState = (state: AppState) => state.socketProgress.show;

export type Action =
    | ReturnType<typeof SocketProgressShow>
    | ReturnType<typeof SocketProgressHide>;

export const SocketProgressReducer: Reducer<
    SocketProgressStateInterface,
    Action
> = (state = initialState, action: Action): SocketProgressStateInterface => {
    switch (action.type) {
        case ACTIONS.SOCKET_PROGRESS_SHOW:
            return {
                show: true,
            };
        case ACTIONS.SOCKET_PROGRESS_HIDE: {
            return {
                show: false,
            };
        }
        default:
            return state;
    }
};