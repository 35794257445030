import { SET_PERCENTAGE } from './constants';
import { SetPercentageAction } from './types';

/**
 * @function setPercentage
 * @param { number } percentage
 * @returns { SetPercentageAction }
 */
export function setPercentage(percentage: number): SetPercentageAction {
    return {
        type: SET_PERCENTAGE,
        percentage: percentage,
    };
}
