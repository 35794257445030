import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { SearchParams } from '../../../../constants/interfaces/filters';
import {
    searchUserPractisSetsStart,
    searchUserPractisSetsFailure,
    searchUserPractisSetsSuccess,
    fetchUserAllPractisSetsSuccess,
    getUserPerformanceStart,
    getUserPerformanceSuccess,
    getUserPerformanceFailure,
    resetUserPerformanceAction,
    updateAllPractisSetsCheckedState,
    updateTraineeProfilePractisSetCheckedState,
} from '../actions';
import { updateDraftUsersFailure } from '../../../../pages/ActionPages/NewUser/store/actions';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    useSearchUserEnrollmentsApi,
    useDeleteEnrollmentsApi,
    useUserPerformanceApi,
    useAssignTeamsToUserApi,
    useEnrollPractisSetsToUserApi,
    useAssignLabelsApi,
    useCreateUpdateEnrollmentDueDate,
    useRemindToStartApi,
} from '../../../../api';
import {
    transformEnrollmentsToPractisSets,
    getSearchUserPractisSetsParams
} from '../helpers';
import { DATE_FORMAT } from '../../../../constants/interfaces/DueDates';
import { AssignFiltersToUserParams } from '../../types';
import { ENROLLMENT_REMIND_TO_START } from '../../../../api/alert/constants';
import { RemindToStartEnrollment } from '../../../../api/alert/types';

export const useSearchUserPractisSetsService = (userId: string) => {
    const dispatch = useDispatch();
    const searchUserPractisSetsApi = useSearchUserEnrollmentsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams) => {
            if (userId) {
                const params = getSearchUserPractisSetsParams(searchParams);
                dispatch(searchUserPractisSetsStart());
                searchUserPractisSetsApi(userId, params)
                    .then(data => {
                        dispatch(searchUserPractisSetsSuccess(data));
                    })
                    .catch((error: ErrorResult) => {
                        dispatch(searchUserPractisSetsFailure(error.message));
                        showMessage(error.message, 'error');
                    });
            }
        },
        [dispatch, searchUserPractisSetsApi, showMessage, userId]
    );
};

export const useFetchUserAllPractisSets = () => {
    const dispatch = useDispatch();
    const fetchUserPractisSets = useSearchUserEnrollmentsApi();
   
    return useCallback(
        (userId: string) => {
            return fetchUserPractisSets(userId).then(data => {
                const practisSets = transformEnrollmentsToPractisSets(data);
                
                dispatch(fetchUserAllPractisSetsSuccess(practisSets));
            });
        },
        [dispatch, fetchUserPractisSets]
    );
};

export const useUnEnrollPractisSetService = () => {
    const unenrollEnrollmentApi = useDeleteEnrollmentsApi();
    const showMessage = useShowMessage();

    return useCallback(
        (unEnrolledPractisSetIds: number[]) => {
            return unenrollEnrollmentApi(unEnrolledPractisSetIds).then(() => {
                const message = unEnrolledPractisSetIds.length > 1 ? 
                    `${unEnrolledPractisSetIds.length} Practis Sets have been unassigned` : 
                    '1 Practis Set has been unassigned';
                showMessage(message, 'success');
            })
            .catch((error: ErrorResult) => {
                showMessage(error.message, 'error');
            });
        },
        [unenrollEnrollmentApi, showMessage]
    );
};

export const useUserPerformanceService = () => {
    const dispatch = useDispatch();
    const userPerformanceApi = useUserPerformanceApi();
    const showMessage = useShowMessage();
    return useCallback(
        (userId: number) => {
            dispatch(getUserPerformanceStart());
            return userPerformanceApi(userId, false)
                .then(data => {
                    dispatch(getUserPerformanceSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(getUserPerformanceFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, userPerformanceApi, showMessage]
    );
};

export const useResetUserPerformanceService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetUserPerformanceAction());
    }, [dispatch]);
};

export const useUpdateAllPractisSetsCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (practisSetIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllPractisSetsCheckedState(
                    practisSetIds,
                    checked,
                    partial
                )
            );
        },
        [dispatch]
    );
};

export const useUpdateTraineeProfilePractisSetsCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (practisSetId: number) => {
            dispatch(updateTraineeProfilePractisSetCheckedState(practisSetId));
        },
        [dispatch]
    );
};

export const useAssignFiltersToUserService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const assignTeamsToUserApi = useAssignTeamsToUserApi();
    const enrollPractisSets = useEnrollPractisSetsToUserApi();
    const unEnrollPractisSets = useDeleteEnrollmentsApi();
    const assignLabelsApi = useAssignLabelsApi();
    const updateEnrollmentsDueDate = useCreateUpdateEnrollmentDueDate();

    return useCallback(
        (
            userId: number,
            shouldAssignTeams: boolean,
            assignFilters: AssignFiltersToUserParams,
            search?: SearchParams
        ) => {
            return Promise.all([
                shouldAssignTeams &&
                    assignTeamsToUserApi(
                        userId,
                        assignFilters.assignedTeamIDs,
                        assignFilters.deletedTeamIDs
                    ),

                !isEmpty(assignFilters?.assignedPractisSetIDs) &&
                    enrollPractisSets(
                        assignFilters?.assignedPractisSetIDs?.map(item => ({
                            practisSetId: item.practisSetId,
                            dueDate: item?.dueDate
                                ? dayjs(item.dueDate).format(DATE_FORMAT)
                                : null,
                            userId
                        })),
                    ),

                !isEmpty(assignFilters?.deletedEnrollmentIds) &&
                    unEnrollPractisSets(assignFilters.deletedEnrollmentIds),

                assignLabelsApi(
                    assignFilters.assignedLabelsIDs,
                    [userId],
                    assignFilters.deletedLabelIDs,
                    'USER',
                    undefined,
                    search
                ),

                !isEmpty(assignFilters.updatedEnrollments) && updateEnrollmentsDueDate(
                    assignFilters.updatedEnrollments
                )
            ])
                .then((datas: any[]) => {
                    return datas;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftUsersFailure(error.message));
                    showMessage(error.message, 'error');
                    return error;
                });
        },
        [
            assignTeamsToUserApi,
            updateEnrollmentsDueDate,
            enrollPractisSets,
            unEnrollPractisSets,
            assignLabelsApi,
            dispatch,
            showMessage,
        ]
    );
};

export const useRemindUsersService = () => {
    const remindUsersApi = useRemindToStartApi();
    const showMessage = useShowMessage();
    return useCallback(
        (enrollmentIds: number[]) => {
            const enrollments = enrollmentIds.map(
                enrollmentId =>
                    ({
                        enrollmentId,
                        type: ENROLLMENT_REMIND_TO_START,
                    } as RemindToStartEnrollment)
            );
            return remindUsersApi(enrollments)
                .then(() => {
                    showMessage('Remind sent successfully!', 'success');
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [remindUsersApi, showMessage]
    );
};