import { Reducer } from 'redux';

import { AppState } from '../../../store/reducers';
import { initialState, SET_PERCENTAGE } from './constants';
import { ChunkServiceActions, ChunkServiceInterface } from './types';

const chunkServiceReducer: Reducer<
    ChunkServiceInterface,
    ChunkServiceActions
> = (state = initialState, action): ChunkServiceInterface => {
    switch (action.type) {
        case SET_PERCENTAGE:
            return {
                ...state,
                percentage: action.percentage,
            };
        default:
            return state;
    }
};

export const getChunkServiceState = (state: AppState) => state.chunkService;

export default chunkServiceReducer;
