import React from 'react';
import AccuracyTests from './AccuracyTests';
import Challenges from './ChallengeSubmissions';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRouter';
import ROUTES from '../../../routes/routes';

const Submissions = () => (
    <Switch>
        <Redirect
            from={ROUTES.SUBMISSIONS}
            exact
            to={ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.ALL}
        />
        <PrivateRoute
            path={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL}
            component={AccuracyTests}
        />
        <PrivateRoute
            path={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL}
            component={Challenges}
        />
    </Switch>
);

export default Submissions;
