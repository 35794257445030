import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { ACTIONS, MonthFilterActions } from './actions';
import { MonthFilterState } from './types';

export const monthFilterReducer: Reducer<
    MonthFilterState,
    MonthFilterActions
> = (state = { isDisabled: false }, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_YEAR:
            return {
                ...state,
                currentYear: action.year,
            };
        case ACTIONS.SELECT_MONTH:
            return {
                ...state,
                selectedMonth: action.month,
                selectedYear: action.year,
            };
        case ACTIONS.CLEAR_YEAR_AND_MONTH:
            return {
                ...state,
                currentYear: undefined,
                selectedMonth: undefined,
                selectedYear: undefined,
            };
        case ACTIONS.DISABLE_MONTH_FILTER:
            return {
                ...state,
                isDisabled: action.isDisabled,
            }
        default:
            return state;
    }
};

export const useMonthFilterState = () =>
    useSelector(state => state.monthFilter);
