import { ScrollPaginationProps } from '../../ScrollPagination';
import {
    TableConfigurationsType,
    TableEmptyStateConfigurations,
    TableStateValues,
} from '../types';

export enum TableViewMode {
    SIMPLE,
    INFINITE_SCROLL,
}
export interface TableProps {
    data?: unknown[];
    tableStates: TableStateValues;
    tableContainerClassName?: string;
    tableInnerClassName?: string;
    cornered?: boolean;
    addOverflow?: boolean;
    tableEmptyStateConfigurations: TableEmptyStateConfigurations;
    configuration: TableConfigurationsType;
    viewMode?: TableViewMode;
    scrollRef?: any;
    scrollPaginationProps?: ScrollPaginationProps;
}
