import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchParams } from '../../../constants/interfaces/filters';
import {
    searchAiLogsFailure,
    searchAiLogsStart,
    searchAiLogsSuccess,
    updateAiLogsFlagStart,
    updateAiLogsFlagFailure,
    addAiLogsFlagSuccess,
    deleteAiLogsFlagSuccess,
} from './actions';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    useSearchAiLogsApi,
    useAddSubmissionFlag,
    useDeleteSubmissionFlag
} from '../../../api';
import { SubmissionFlag, SUBMISSION_FLAG_TYPE } from '../../../constants/interfaces/Reviews';
import { searchLogsDispose } from '../../logs/store/actions';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { AiLogsParams } from '../../../api/assessment-logs/types';
import { getSearchSortingValue } from '../../../helpers/functions/search-params-helpers';
import { AiLogItemInterface } from '../../../constants/interfaces/AiItem';
import { isTeamLeader } from '../../../constants/enums';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';

export const useSearchAiLogsService = () => {
    const dispatch = useDispatch();
    const searchAiLogsApi = useSearchAiLogsApi();
    const showMessage = useShowMessage();
    const profile = useSelector(getProfileState);

    return useCallback(
        (searchParams: SearchParams, userTeamIds: number[], companyId?: number) => {
            if (!profile?.companyId)
                return;
            dispatch(searchAiLogsStart());
            const teamIds = (searchParams.teamIds ?? []).length > 0
                ? searchParams.teamIds
                : (isTeamLeader(profile.role?.name)
                    ? userTeamIds
                    : undefined);
            const requestParams: AiLogsParams = {
                limit: searchParams.limit ?? 20,
                offset: searchParams.offset ?? 0,
                order: getSearchSortingValue(searchParams.orderBy, ''),
                queryUser: searchParams.searchTerm,
                companies: (companyId ?? profile.companyId).toString(),
                teams: teamIds?.join(','),
                dateStart: searchParams.startDate
                    ? new Date(searchParams.startDate).toISOString()
                    : undefined,
                dateEnd: searchParams.endDate
                    ? new Date(searchParams.endDate).toISOString()
                    : undefined
            };
            searchAiLogsApi(requestParams)
                .then(data => {
                    dispatch(searchAiLogsSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchAiLogsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, showMessage, searchAiLogsApi, profile]
    );
};

export const useAiLogsUnmountService = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(searchLogsDispose());
    }, [dispatch]);
};

/**
 * @function useToggleAiLogsFlagService
 * @returns { CallableFunction }
 */
export const useToggleAiLogsFlagService = () => {
    const dispatch = useDispatch();
    const addSubmissionFlag = useAddSubmissionFlag();
    const deleteSubmissionFlag = useDeleteSubmissionFlag();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { AiLogItemInterface } record
     * @param { Promise }
     */
    return useCallback((record: AiLogItemInterface) => {
        dispatch(updateAiLogsFlagStart(record.id));
        if (record.flagCompanyId) {
            deleteSubmissionFlag(record.id, record.flagCompanyId)
                .then(() => {
                    dispatch(deleteAiLogsFlagSuccess(record.id));
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateAiLogsFlagFailure(error.message));
                    showMessage(error.message, 'success');
                });
        } else {
            addSubmissionFlag(record.id, SUBMISSION_FLAG_TYPE.COMPANY)
                .then((data: SubmissionFlag) => {
                    dispatch(addAiLogsFlagSuccess(record.id, data.id));
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateAiLogsFlagFailure(error.message));
                    showMessage(error.message, 'success');
                });
        }
    },
        [dispatch, addSubmissionFlag, deleteSubmissionFlag, showMessage]
    );
};