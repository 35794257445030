import { Scenario } from '../../../../constants/interfaces/Scenario';
import {
    PractisSetContent,
    PractisSets,
} from '../../../../constants/interfaces/PractisSets';
import { Challenge } from '../../../../constants/interfaces/Challenge';

export enum ACTIONS {
    GET_SINGLE_PRACTIS_SET_START = 'GET_SINGLE_PRACTIS_SET_START',
    GET_SINGLE_PRACTIS_SET_SUCCESS = 'GET_SINGLE_PRACTIS_SET_SUCCESS',
    GET_SINGLE_PRACTIS_SET_FAILURE = 'GET_SINGLE_PRACTIS_SET_FAILURE',

    SEARCH_PRACTIS_SCENARIOS_START = 'SEARCH_PRACTIS_SCENARIOS_START',
    SEARCH_PRACTIS_SCENARIOS_SUCCESS = 'SEARCH_PRACTIS_SCENARIOS_SUCCESS',
    SEARCH_PRACTIS_SCENARIOS_FAILURE = 'SEARCH_PRACTIS_SCENARIOS_FAILURE',

    GET_PRACTIS_SCENARIOS_COUNT_SUCCESS = 'GET_PRACTIS_SCENARIOS_COUNT_SUCCESS',

    SEARCH_PRACTIS_CHALLENGES_START = 'SEARCH_PRACTIS_CHALLENGES_START',
    SEARCH_PRACTIS_CHALLENGES_SUCCESS = 'SEARCH_PRACTIS_CHALLENGES_SUCCESS',
    SEARCH_PRACTIS_CHALLENGES_FAILURE = 'SEARCH_PRACTIS_CHALLENGES_FAILURE',

    GET_PRACTIS_CHALLENGES_COUNT_SUCCESS = 'GET_PRACTIS_CHALLENGES_COUNT_SUCCESS',

    RESET_PRACTIS_SETS = 'RESET_PRACTIS_SETS',
    UPDATE_PRACTIS_CONTENT = 'UPDATE_PRACTIS_CONTENT',
    PRACTIS_CONTENT_REP_COUNT_INCREASE = 'PRACTIS_CONTENT_REP_COUNT_INCREASE',
    PRACTIS_CONTENT_REP_COUNT_DECREASE = 'PRACTIS_CONTENT_REP_COUNT_DECREASE',

    STORE_PRACTIS_SET_TEMP_COPY = 'STORE_PRACTIS_SET_TEMP_COPY',
    RESTORE_PRACTIS_SET_FROM_TEMP = 'RESTORE_PRACTIS_SET_FROM_TEMP',

    MODIFY_PRACTIS_SET = 'MODIFY_PRACTIS_SET',
}

export function searchPractisScenariosStart() {
    return {
        type: ACTIONS.SEARCH_PRACTIS_SCENARIOS_START as ACTIONS.SEARCH_PRACTIS_SCENARIOS_START,
    };
}

export function searchPractisScenariosSuccess(data: Scenario[]) {
    return {
        type: ACTIONS.SEARCH_PRACTIS_SCENARIOS_SUCCESS as ACTIONS.SEARCH_PRACTIS_SCENARIOS_SUCCESS,
        data,
    };
}

export function searchPractisScenariosFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_PRACTIS_SCENARIOS_FAILURE as ACTIONS.SEARCH_PRACTIS_SCENARIOS_FAILURE,
        error,
    };
}

export function getPractisScenarioCountSuccess(count: number) {
    return {
        type: ACTIONS.GET_PRACTIS_SCENARIOS_COUNT_SUCCESS as ACTIONS.GET_PRACTIS_SCENARIOS_COUNT_SUCCESS,
        count,
    };
}

export function searchPractisChallengesStart() {
    return {
        type: ACTIONS.SEARCH_PRACTIS_CHALLENGES_START as ACTIONS.SEARCH_PRACTIS_CHALLENGES_START,
    };
}

export function searchPractisChallengesSuccess(data: Challenge[]) {
    return {
        type: ACTIONS.SEARCH_PRACTIS_CHALLENGES_SUCCESS as ACTIONS.SEARCH_PRACTIS_CHALLENGES_SUCCESS,
        data,
    };
}

export function searchPractisChallengesFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_PRACTIS_CHALLENGES_FAILURE as ACTIONS.SEARCH_PRACTIS_CHALLENGES_FAILURE,
        error,
    };
}

export function getPractisChallengesCountSuccess(count: number) {
    return {
        type: ACTIONS.GET_PRACTIS_CHALLENGES_COUNT_SUCCESS as ACTIONS.GET_PRACTIS_CHALLENGES_COUNT_SUCCESS,
        count,
    };
}

export function updatePractisContentAction(data: PractisSetContent[]) {
    return {
        type: ACTIONS.UPDATE_PRACTIS_CONTENT as ACTIONS.UPDATE_PRACTIS_CONTENT,
        data,
    };
}

export function practisContentRepCountIncrease(itemId: number) {
    return {
        type: ACTIONS.PRACTIS_CONTENT_REP_COUNT_INCREASE as ACTIONS.PRACTIS_CONTENT_REP_COUNT_INCREASE,
        itemId,
    };
}

export function practisContentRepCountDecrease(itemId: number) {
    return {
        type: ACTIONS.PRACTIS_CONTENT_REP_COUNT_DECREASE as ACTIONS.PRACTIS_CONTENT_REP_COUNT_DECREASE,
        itemId,
    };
}

export function resetPractisSetsAction() {
    return {
        type: ACTIONS.RESET_PRACTIS_SETS as ACTIONS.RESET_PRACTIS_SETS,
    };
}

export function getSinglePractisSetStart() {
    return {
        type: ACTIONS.GET_SINGLE_PRACTIS_SET_START as ACTIONS.GET_SINGLE_PRACTIS_SET_START,
    };
}

export function getSinglePractisSetSuccess(data: PractisSets) {
    return {
        type: ACTIONS.GET_SINGLE_PRACTIS_SET_SUCCESS as ACTIONS.GET_SINGLE_PRACTIS_SET_SUCCESS,
        data,
    };
}

export function getSinglePractisSetFailure(error: any) {
    return {
        type: ACTIONS.GET_SINGLE_PRACTIS_SET_FAILURE as ACTIONS.GET_SINGLE_PRACTIS_SET_FAILURE,
        error,
    };
}

export function modifyPractisSetAction(
    data: PractisSets,
    field: string,
    silent?: boolean
) {
    return {
        type: ACTIONS.MODIFY_PRACTIS_SET as ACTIONS.MODIFY_PRACTIS_SET,
        field: field,
        data: data,
        silent: silent,
    };
}

export function storePractisSetTempCopyAction() {
    return {
        type: ACTIONS.STORE_PRACTIS_SET_TEMP_COPY as ACTIONS.STORE_PRACTIS_SET_TEMP_COPY,
    };
}

export function restorePractisSetFromTempAction() {
    return {
        type: ACTIONS.RESTORE_PRACTIS_SET_FROM_TEMP as ACTIONS.RESTORE_PRACTIS_SET_FROM_TEMP,
    };
}
