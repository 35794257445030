import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Variables } from '../../../../theme/variables';

import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { TableCell } from '../../../../ui/components/table-wrapper/table/table-cell';
import OverdueFlag from '../../../../ui/icons/OverdueFlag';
import { Loading } from '../../../../ui/components/LoadingCopmonent';

export const InvitationHeader = styled.div`
    padding: 40px 0 23px 16px;
    display: flex;
    flex-shrink: 0;
    justify-content: start;
`;

export const QuickAssignmentInvitationFooter = styled.div`
    padding: 22px 30px 32px 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const InvitationHeaderWrapper = styled.div`
    display: flex;
`;

export const QuickAssignmentInvitationHeaderText = styled.div`
    font-size: 24px;
    font-weight: 800;
    max-width: 400px;
    color: ${props => props.theme.Colors.black};
    line-height: 1.2;
    margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    color: ${props => props.theme.Colors.topaz};
    margin-left: 12px;
`;

export const InvitationHeaderSubText = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    margin-bottom: 2px;
`;

export const CancelWrapper = styled.div`
    margin-right: 12px;
`;

export const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const Avatar = styled(AvatarPlaceholder)<{ marginRight: number }>`
    width: 24px;
    height: 24px;
    margin-right: ${({ marginRight }) =>
        marginRight ? `${marginRight}px` : '8px'};
`;

export const StyledProgressContainer = styled.div`
    max-width: 268px;
`;

export const ProgressBarContainer = styled.div`
    width: 128px;
`;

export const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    min-width: 200px;
`;

export const DueDateWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const DueDateIcon = styled.span`
    display: flex;
`;

export const OverdueFlagWrapped = styled(OverdueFlag)`
    margin-left: 8px;
`;

export const WrapperTableCell = styled.div<{ isTeamMembers?: boolean }>`
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: no-wrap;

    td {
        display: inline-flex;
        align-items: center;
        justify-content: flex-center;
    }

    td:nth-child(1) {
        padding-left: 0px !important;
        margin-left: -2px;
    }

    td:nth-child(2) {
        padding-left: 6px !important;
    }

    td:nth-child(3) {
        padding-left: 8px !important;
    }

    @media (max-width: 1680px) {
        width: 76%;
    }

    @media (max-width: 1441px) {
        width: 90%;
        td:nth-child(1) {
            padding-left: 10px !important;
        }

        td:nth-child(2) {
            padding-left: 8px !important;
            span {
                width: 92px;
            }
        }

        td:nth-child(3) {
            padding-left: 2px !important;
        }
    }
`;

export const WrapperTableCellUsers = styled.div`
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: no-wrap;

    td {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }

    td:nth-child(1) {
        padding-left: 1px !important;
        span {
            width: 55px;
            text-align: center;
        }
    }

    td:nth-child(2) {
        justify-content: center;
        span {
            width: 70px;
            margin-left: -9px;
        }
    }

    @media (max-width: 1441px) {
        td:nth-child(1) {
            padding-left: 10px !important;
            span {
                text-align: left;
                margin-left: -8px;
            }
        }

        td:nth-child(2) {
            padding-left: 0px !important;
            span {
                margin-left: -3px;
            }
        }
    }
`;

export const StyledTraineeInvitationContainer = styled.div`
    padding: 0 30px;
    height: 100%;
    overflow: auto;
    background: ${props => props.theme.Colors.white};
    display: flex;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const InvitationFooter = styled.div`
    padding: 22px 0 32px 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: ${props => props.theme.Colors.white};
    width: 100%;
`;

export const InvitationHeaderText = styled.div`
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.theme.Colors.steelGrey};
    margin-bottom: 2px;
`;

export const StyledInvitationTitle = styled.div`
    color: ${Variables.Colors.black};
    font-size: 20px;
    font-weight: 800;
`;

export const StyledTableCell = styled(TableCell)`
    font-size: 13px;
    height: 45px;
    padding: 0;
`;

export const StyledFirstTableItemContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    min-width: 200px;
`;

export const useStyles = makeStyles(theme => ({
    customTableCellStyle: {
        padding: '0px !important',
    },
    multiHeaderContainer: {
        justifyContent: 'space-between !important',

        width: '70% !important',
        '@media(min-width:960px)': {
            width: '71% !important',
        },

        '@media(min-width: 1280px)': {
            width: '70% !important',
        },

        '@media(min-width: 1441px)': {
            width: '55% !important',
        },
    },

    teamMemberHeaderContainer: {
        width: '45% !important',
        
        '@media(max-width:1680px)': {
            width: '80% !important',
        },

        '@media(max-width: 1441px)': {
            width: '100% !important',
        },

        '@media(min-width:1681px)': {
            width: '70% !important',
        },

        '@media(min-width: 2041px)': {
            width: '57% !important',
        },
    },

    scrollWrapperStyle: {
        height: '100% !important',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },

    tableWrapperStyle: {
        height: '100% !important',
    },

    tableBodyContainerStyleForInvitation: {
        maxHeight: 'calc(100vh - 358px)',
    },

    tableBodyContainerStyleForAssignment: {
        maxHeight: 'calc(100vh - 368px)',
    },

    customCellStyle: {
        fontSize: '13px',
        height: '45px',
        padding: 0,
    },

    labelsCellStyle: {
        justifyContent: 'flex-end !important',
    },
}));

export const TeamMemberStatusCell = styled(TableCell)`
    justify-content: center;
`;

export const StyledTraineeDetailsContainer = styled.div`
    height: 100%;
    background: ${props => props.theme.Colors.white};
    display: flex;
    flex-direction: column;
`;

export const StyledLoading = styled(Loading)`
    height: calc(100vh - 115px);
`;

export const StyledDetailsHeader = styled.div`
    padding: 24px 0;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
`;

export const ProfileSection = styled.div`
    display: flex;
`;

export const DetailsStatus = styled.div`
    color: ${props => props.theme.Colors.coral};
    font-size: 13px;
    font-weight: normal;
    height: 24px;
    padding: 0px 12px;
    border-radius: 4px;
    align-self: flex-end;
    border-radius: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DetailsStatusPending = styled(DetailsStatus)`
    color: #3a3a3a;
    background: #ebebeb;
`;

export const DetailsStatusInProgress = styled(DetailsStatus)`
    color: #015895;
    background: rgba(74, 169, 226, 0.15);
`;

export const DetailsStatusCompleted = styled(DetailsStatus)`
    background: #d6f7e8;
    color: #066150;
`;

export const StyledDetailsAvatar = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 4px;
    overflow: hidden;
`;

export const StyledUserInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
`;

export const StyledName = styled.div<{ isFlex?: boolean }>`
    ${props => !!props.isFlex && `display: flex; align-items: center;`}
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    margin-top: 4px;
`;

export const StyledEmail = styled.div`
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const GraphSection = styled.div`
    padding: 24px 0;
`;

export const GraphRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledAdditionalInfo = styled.span`
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.steelGrey};
    margin-right: 4px;
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-shrink: 0;
`;

export const StyledPendingTitle = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.Colors.brown};
`;

export const StyledPendingInvitationLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-radius: 20px;
    background-color: ${props => props.theme.Colors.macaroniAndCheeseOpaque};
    padding: 4px 12px;
    margin-left: 10px;
`;

export const SubHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    margin-left: -8px;
`;

export const WarningTitle = styled.span`
    display: flex;
    align-items: center;
    color: ${props => props.theme.Colors.black};
`;

export const DueDateSubTitle = styled.span`
    margin-top: 1px;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
`;

export const StyledOverdueFlag = styled(OverdueFlag)`
    margin-left: 8px;
`;

export const DueDateSkeletonContainer = styled.div`
    margin-left: 7px;
    width: 195px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`