import styled from 'styled-components';
import Checkbox from '../../../../ui/components/Checkbox';

export const Container = styled.div`
    padding: 0 4px 16px 4px;
`;

export const SkeletonContainer = styled.div`
    padding: 16px 20px 0 20px;
`;

export const EmptyStateContainer = styled.div`
    padding-top: 19px;
`;

export const CounterContainer = styled.div`
    font-size: 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 20px 4px 20px;
`;

export const SelectionCounter = styled.div`
    color: var(--ps-grey-1);
    font-weight: 500;
`;

export const ToggleAllSelection = styled.span<{ isDisabled: boolean }>`
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
    color: ${props => props.isDisabled ? 'var(--ps-grey-2)' : 'var(--ps-blue-main)'};
    font-weight: ${props => props.isDisabled ? '400' : '500'};
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    max-height: 266px;
    overflow: auto;
    padding: 0 20px;
`;

export const StyledCheckbox = styled(Checkbox)`
    padding: 4px 0;
`