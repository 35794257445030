import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoTrainings = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 48 48"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.2%"
                            x="-14.3%"
                            y="-1.2%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                d="M866 571.202V596.8c0 1.766-1.343 3.2-3 3.2h-36c-1.657 0-3-1.434-3-3.2v-25.598c-1.658 0-3-1.434-3-3.202v-12.8c0-1.768 1.343-3.2 3-3.2h42c1.657 0 3 1.432 3 3.2V568c0 1.769-1.344 3.202-3 3.202zM827 595.2c0 .884.672 1.6 1.5 1.6h33c.828 0 1.5-.716 1.5-1.6v-22.4c0-.883-.672-1.598-1.5-1.598H854v6.398c0 1.769-1.343 3.2-3 3.2h-12c-1.657 0-3-1.431-3-3.2v-6.398h-7.5c-.828 0-1.5.715-1.5 1.597V595.2zm24-19.2v-4.798h-12v4.799c0 .883.671 1.599 1.5 1.599h9c.828 0 1.5-.717 1.5-1.6zm15-19.2c0-.882-.672-1.6-1.5-1.6h-39c-.828 0-1.5.717-1.5 1.6v9.6c0 .884.671 1.6 1.5 1.6h39c.828 0 1.5-.716 1.5-1.6v-9.6z"
                                transform="translate(-821 -552)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoTrainings;
