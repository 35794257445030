import React, { FC } from 'react';
import styled from 'styled-components';
import KeywordIcon from '../../../../../../../../ui/icons/Keyword';

const StyledTooltip = styled.div<{ top: number; left: number; color?: string }>`
    position: fixed;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    background: ${props => props.theme.Colors.darkTwo};
    font-size: 13px;
    font-weight: 600;
    height: 42px;
    width: 112px;
    color: ${props => (props.color ? props.color : props.theme.Colors.white)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    z-index: 5;
    &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    &:active {
        opacity: 0.95;
    }
`;

const StyledIconContainer = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

const Tooltip: FC<{
    top?: number;
    left?: number;
    label?: string;
    onClick?: (args: any) => void;
    textColor?: string;
    withIcon?: boolean;
}> = ({ top, left, label, onClick, textColor }) => {
    return (
        <StyledTooltip
            top={top ? top : 0}
            left={left ? left : 0}
            onClick={onClick}
            color={textColor}
        >
            <StyledIconContainer>
                <KeywordIcon />
            </StyledIconContainer>

            {label ? label : 'Keyword'}
        </StyledTooltip>
    );
};

export default Tooltip;
