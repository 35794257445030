import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarUsers = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                className={className}
                viewBox="0 0 16 16"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.645 11.969c-.438-.401-3.985-1.27-4.675-1.436 1.78-.951 2.881-3.256 2.881-5.428 0-2.82-.843-5.105-4.639-5.105-3.795 0-4.639 2.286-4.639 5.105 0 2.18 1.111 4.493 2.904 5.438-.8.189-4.237 1.018-4.685 1.426C-.014 12.7-.34 14.9.465 16l6.78-.002 6.718.002c.8-1.1.481-3.299-.318-4.031zM15.501 4h-1.997a.5.5 0 0 1 0-1H15.5a.5.5 0 0 1 0 1zM13.504 7H15.5a.5.5 0 1 0 0-1h-1.997a.5.5 0 0 0 0 1zm.998 3h.999a.5.5 0 0 0 0-1h-.998a.5.5 0 0 0 0 1zM7.212.997c-3.003 0-3.604 1.848-3.604 4.126 0 1.897.975 3.994 2.575 4.65v1.73h-.428c-1.966.41-3.773.981-4.301 1.238-.336.378-.552 1.503-.343 2.26l6.134-.002 6.07.001c.208-.756-.001-1.878-.333-2.258-.53-.253-2.306-.825-4.249-1.24h-.49v-1.73c1.599-.658 2.573-2.753 2.573-4.649 0-2.279-.601-4.126-3.604-4.126z"
                    fill="#6D7F8C"
                />
            </svg>
        );
    }
);

export default SidebarUsers;
