import { ChallengeScriptHeaderViewProps } from './types';
import {
    StyledChallengeScriptHeader,
    StyledScriptHeaderActions,
    StyledButton,
    LeftButtons,
    AttemptsCountContainer,
    AttemptsCountText,
    AttemptsCountValue,
    IconContainer,
} from './styles';
import { ChallengeSettings } from '../ChallengeSettings';
import Generate from '../../../../ui/icons/Generate';
import { ScriptLine } from '../../../../constants/interfaces/ScriptLine';
import { EditModeValues } from '../../../../constants/enums/EditModeValues';
import Play from '../../../../ui/icons/Play';
import { Stop } from '@material-ui/icons';
import SmartGear from '../../../../ui/icons/SmartGear';

function ChallengeScriptHeaderView({
    player,
    challenge,
    generating,
    showChallengeSettings,
    editMode,
    stopPlaylist,
    playAudio,
    textToSpeech,
    setShowChallengeSettings,
}: ChallengeScriptHeaderViewProps) {
    const actionsDisabled = editMode === EditModeValues.VIEW;
    const isAudioProcessing = !!player.recording || generating;
    const isGenerateForAllDisabled = (
            !!challenge.script
            && (
                challenge.script.lines.filter((line: ScriptLine) => line.text).length === 0
                || player.status !== 'off'
                || isAudioProcessing
            )
        )
        || actionsDisabled;
    const isPlayDisabled = (
            !!challenge.script
            && (
                challenge.script.lines.filter((line: ScriptLine) => line.audioUrl).length === 0
                || isAudioProcessing
            )
        );
    return (
        <StyledChallengeScriptHeader>
            {showChallengeSettings && (
                <ChallengeSettings
                    challenge={challenge}
                    onClose={() => setShowChallengeSettings(false)}
                />
            )}
            <AttemptsCountContainer>
                <AttemptsCountText data-test="max-attempts-text">Max. Attempts</AttemptsCountText>
                <AttemptsCountValue data-test="challenge-attempts-limit">
                    {challenge.tryLimit?.toString() || 'Unlimited'}
                </AttemptsCountValue>
            </AttemptsCountContainer>
            <StyledScriptHeaderActions>
                <LeftButtons>
                    <StyledButton
                        label="Generate for All"
                        iconRender={<IconContainer><Generate /></IconContainer>}
                        disabled={isGenerateForAllDisabled}
                        width="127px"
                        height="40px"
                        action={textToSpeech}
                        dataTest='challenge-generate-for-all'
                    />
                    {player.status !== 'playlist' ? (
                        <StyledButton
                            label="Play"
                            iconRender={<IconContainer><Play /></IconContainer>}
                            disabled={isPlayDisabled}
                            action={playAudio}
                            height="40px"
                            width="58px"
                            dataTest='challenge-play-all'
                        />
                    ) : (
                        <StyledButton
                            label="Stop"
                            iconRender={<IconContainer><Stop /></IconContainer>}
                            color="warning"
                            action={stopPlaylist}
                            height="40px"
                            width="58px"
                            dataTest='challenge-stop'
                        />
                    )}
                </LeftButtons>
                <div>
                    <StyledButton
                        label="Settings"
                        iconRender={<IconContainer><SmartGear /></IconContainer>}
                        disabled={actionsDisabled}
                        width="84px"
                        height="40px"
                        action={() => setShowChallengeSettings(true)}
                        dataTest='challenge-settings'
                    />
                </div>
            </StyledScriptHeaderActions>
        </StyledChallengeScriptHeader>
    );
}

export default ChallengeScriptHeaderView;