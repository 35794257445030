import {
    Scenario,
    ScenarioStatuses,
} from '../../../../constants/interfaces/Scenario';
import { ScriptLine } from '../../../../constants/interfaces/ScriptLine';
import { AppState } from '../../../../store/reducers';
import {
    ACTIONS,
    createScriptLineAction,
    deleteScriptLineAction,
    fetchScenarioFailure,
    fetchScenarioStart,
    fetchScenarioSuccess,
    modifyScenarioAction,
    modifyScriptTextAction,
    resetScenarioAction,
    resetScenarioLogoAction,
    restoreScenarioFromTempAction,
    updateScriptLineKeywords,
    storeScenarioTempCopyAction,
    updateScenarioModifyCase,
    updateScriptLineAction,
    uploadScenarioAudioFailure,
    uploadScenarioAudioStart,
    uploadScenarioAudioSuccess,
    clearScriptLinesAction,
} from './actions';
import {
    ACTIONS as LIBRARY_ACTIONS,
    updateLibraryScenarioSuccess,
} from '../../../../features/library/store/actions';

export type ScenarioCases =
    | 'init'
    | 'created'
    | 'modified'
    | 'loaded'
    | 'updated'
    | 'error';

export interface ScenarioInterface {
    info: Scenario;
    temp_info?: Scenario;
    loading: boolean;
    case: ScenarioCases;
    errors: string;
}

export const initialScenarioState: ScenarioInterface = {
    info: {
        title: '',
        description: '',
        instructions: '',
        status: ScenarioStatuses.DRAFT,
        script: {
            totalDuration: null,
            scenarioId: null,
            companyId: null,
            lines: [],
        },
    },
    loading: false,
    case: 'init',
    errors: '',
};

type ScenarioActions =
    | ReturnType<typeof fetchScenarioStart>
    | ReturnType<typeof fetchScenarioSuccess>
    | ReturnType<typeof fetchScenarioFailure>
    | ReturnType<typeof modifyScenarioAction>
    | ReturnType<typeof createScriptLineAction>
    | ReturnType<typeof updateScriptLineAction>
    | ReturnType<typeof modifyScriptTextAction>
    | ReturnType<typeof deleteScriptLineAction>
    | ReturnType<typeof uploadScenarioAudioStart>
    | ReturnType<typeof uploadScenarioAudioSuccess>
    | ReturnType<typeof uploadScenarioAudioFailure>
    | ReturnType<typeof resetScenarioAction>
    | ReturnType<typeof resetScenarioLogoAction>
    | ReturnType<typeof updateScenarioModifyCase>
    | ReturnType<typeof updateLibraryScenarioSuccess>
    | ReturnType<typeof storeScenarioTempCopyAction>
    | ReturnType<typeof restoreScenarioFromTempAction>
    | ReturnType<typeof updateScriptLineKeywords>
    | ReturnType<typeof clearScriptLinesAction>;

export const scenarioReducer = (
    state = initialScenarioState,
    action: ScenarioActions
): ScenarioInterface => {
    switch (action.type) {
        case ACTIONS.FETCH_SCENARIO_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.FETCH_SCENARIO_SUCCESS:
            return {
                info: {
                    ...state.info,
                    ...action.data,
                },
                loading: false,
                case: 'loaded',
                errors: '',
            };
        case ACTIONS.FETCH_SCENARIO_FAILURE:
            return {
                ...state,
                loading: false,
                case: 'error',
                errors: action.error,
            };
        case ACTIONS.UPLOAD_SCENARIO_AUDIO_START:
            return {
                ...state,
            };
        case ACTIONS.UPLOAD_SCENARIO_AUDIO_SUCCESS:
            const updatedLines = state.info.script.lines.map(
                (line: ScriptLine) => {
                    if (line.id === action.data.scriptLineId) {
                        const newLine = { ...line };
                        newLine.audioUrl = action.data.response.url;
                        newLine.audioId = action.data.response.id;
                        newLine.duration =
                            action.data.response.metadata.format.duration;
                        return newLine;
                    } else {
                        return line;
                    }
                }
            );

            return {
                ...state,
                info: {
                    ...state.info,
                    script: {
                        ...state.info.script,
                        totalDuration: updatedLines
                            .map((lines: ScriptLine) =>
                                lines.duration ? lines.duration : 0
                            )
                            .reduce((a, b) => a + b, 0),
                        lines: updatedLines,
                    },
                },
                case: 'modified',
            };
        case ACTIONS.UPLOAD_SCENARIO_AUDIO_FAILURE:
            return {
                ...state,
                errors: action.error,
                case: 'error',
            };
        case ACTIONS.CREATE_SCRIPT_LINE:
            const createdScriptLine = { ...state.info.script };
            createdScriptLine.lines.push(action.data);
            return {
                ...state,
                info: {
                    ...state.info,
                    script: {
                        ...createdScriptLine,
                    },
                },
                case: 'modified',
                errors: '',
            };
        case ACTIONS.UPDATE_SCRIPT_LINE:
            return {
                ...state,
                info: {
                    ...state.info,
                    script: {
                        ...state.info.script,
                        lines: action.data.map(
                            (line: ScriptLine, index: number) => {
                                return { ...line, position: index };
                            }
                        ),
                    },
                },
                case: 'modified',
                errors: '',
            };
        case ACTIONS.MODIFY_SCRIPT_TEXT:
            return {
                ...state,
                case: 'modified',
                info: {
                    ...state.info,
                    script: {
                        ...state.info.script,
                        lines: state.info.script.lines.map(
                            (line: ScriptLine) => {
                                if (line.id === action.lineId) {
                                    return {
                                        ...line,
                                        text: action.value,
                                        audioId: 0,
                                        audioUrl: undefined
                                    };
                                } else {
                                    return line;
                                }
                            }
                        ),
                    },
                },
            };
        case ACTIONS.DELETE_SCRIPT_LINE:
            const newScriptLines = [...state.info.script.lines];
            const newScriptLinesArr = [
                ...newScriptLines.filter(
                    (line: ScriptLine) => line.id !== action.data
                ),
            ];
            const newDurations = newScriptLinesArr
                .filter((line: ScriptLine) => line.audioUrl)
                .map((line: ScriptLine) => line.duration);
            return {
                ...state,
                info: {
                    ...state.info,
                    script: {
                        ...state.info.script,
                        totalDuration:
                            newDurations.length > 0
                                ? newDurations.reduce((a, b) => a + b)
                                : 0,
                        lines: newScriptLinesArr,
                    },
                },
                case: 'modified',
                errors: '',
            };
        case ACTIONS.CLEAR_SCRIPT_LINES:
            return {
                ...state,
                info: {
                    ...state.info,
                    script: {
                        ...state.info.script,
                        lines: []
                    },
                },
                case: 'modified',
                errors: '',
            };
        case ACTIONS.MODIFY_SCENARIO:
            const newScenarioInfo: any = { ...state.info };
            newScenarioInfo[action.field] = action.data;
            return {
                ...state,
                info: {
                    ...newScenarioInfo,
                },
                case: action.silent ? state.case : 'modified',
            };
        case ACTIONS.UPDATE_SCRIPT_LINE_KEYWORDS: {
            const newLines = [...state.info.script.lines];
            return {
                ...state,
                info: {
                    ...state.info,
                    script: {
                        ...state.info.script,
                        lines: newLines.map(line => {
                            if (line.id === action.lineId) {
                                return {
                                    ...line,
                                    keywords: action.keywords,
                                };
                            } else {
                                return line;
                            }
                        }),
                    },
                },
                case: 'modified',
                errors: '',
            };
        }
        case ACTIONS.STORE_SCENARIO_TEMP_COPY: {
            return {
                ...state,
                temp_info: JSON.parse(JSON.stringify(state.info)),
            };
        }
        case ACTIONS.RESTORE_SCENARIO_FROM_TEMP: {
            return {
                ...state,
                info: JSON.parse(JSON.stringify(state.temp_info)),
                case: 'init',
            };
        }
        case ACTIONS.RESET_SCENARIO: {
            return {
                ...initialScenarioState,
                info: {
                    ...initialScenarioState.info,
                    script: {
                        ...initialScenarioState.info.script,
                        lines: [],
                    },
                },
            };
        }
        case ACTIONS.UPDATE_SCENARIO_CASE: {
            return {
                ...state,
                case: action.value,
            };
        }
        case LIBRARY_ACTIONS.UPDATE_LIBRARY_SCENARIO_SUCCESS: {
            return {
                ...state,
                case: 'updated',
            };
        }
        default:
            return state;
    }
};

export const getScenarioState = (state: AppState) => state.scenario;
