import { compose, Reducer } from 'redux';
import * as EmailValidator from 'email-validator';

import { UpdatedDraftsState } from '../../../../features/users/store/states';
import {
    ACTIONS,
    modifyDraftUsersAction,
    searchDraftUsersFailure,
    searchDraftUsersStart,
    searchDraftUsersSuccess,
    updateDraftUsersStart,
    updateDraftUsersSuccess,
    updateDraftUsersFailure,
    resetDraftsPageAction,
    modifyDraftUsersWithoutValidationAction,
} from './actions';
import { DraftEditBaseState, DraftEditState } from './states';
import {
    withLabelsInitialState,
    withLabelsReducer,
} from '../../../../features/portableLabels/store/hors/withLabels';
import {
    withPractisSetsInitialState,
    withPractisSetsReducer,
} from '../../../../features/portablePractisSets/store/hors/withPractisSets';
import {
    withTeamsInitialState,
    withTeamsReducer,
} from '../../../../features/portableTeams/store/hors/withTeams';

const initialDraftEditState = withLabelsInitialState<DraftEditBaseState>(
    withTeamsInitialState(
        withPractisSetsInitialState(
            {
                loading: false,
            },
            'draftEdit'
        ),
        'draftEdit'
    ),
    'draftEdit'
);

export type draftEditAction =
    | ReturnType<typeof searchDraftUsersStart>
    | ReturnType<typeof searchDraftUsersSuccess>
    | ReturnType<typeof searchDraftUsersFailure>
    | ReturnType<typeof modifyDraftUsersAction>
    | ReturnType<typeof modifyDraftUsersWithoutValidationAction>
    | ReturnType<typeof updateDraftUsersStart>
    | ReturnType<typeof updateDraftUsersSuccess>
    | ReturnType<typeof updateDraftUsersFailure>
    | ReturnType<typeof resetDraftsPageAction>;

export const draftEditBaseReducer: Reducer<DraftEditState, draftEditAction> = (
    state = initialDraftEditState,
    action
): DraftEditState => {
    switch (action.type) {
        case ACTIONS.SEARCH_DRAFT_USERS_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.SEARCH_DRAFT_USERS_SUCCESS:
            const updatedDataItems =
                action.data && action.data.items.length
                    ? action.data.items.map((item, index) => {
                          const isEmailDuplicated =
                              !!item.email &&
                              index < action.data.items.length - 1 &&
                              action.data.items
                                  .slice(index + 1)
                                  .filter(user => user.email === item.email)
                                  .length > 0;
                          const isEmailInvalid =
                              !!item.email &&
                              !EmailValidator.validate(item.email);
                          const isFirstNameInvalid = !item.firstName;
                          const isLastNameInvalid = !item.firstName;
                          const isRoleInvalid = !item.roleId;

                          return {
                              ...item,
                              drafted: true,
                              isEntryInvalid:
                                  isEmailDuplicated ||
                                  isEmailInvalid ||
                                  isFirstNameInvalid ||
                                  isLastNameInvalid ||
                                  isRoleInvalid,
                          };
                      })
                    : [];
            return {
                ...state,
                data: {
                    ...action.data,
                    items: updatedDataItems,
                },
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_DRAFT_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.UPDATE_DRAFT_USERS_START:
            return {
                ...state,
                loadingUpdate: true,
            };
        case ACTIONS.UPDATE_DRAFT_USERS_SUCCESS:
            return {
                ...state,
                updateData: action.data,
                updateType: action.updateType,
                loadingUpdate: false,
            };
        case ACTIONS.UPDATE_DRAFT_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ACTIONS.MODIFY_DRAFT_USERS: {
            const updatedDataItems =
                action.data && action.data.items.length
                    ? action.data.items.map((item, index) => {
                          const isEmailDuplicated =
                              !!item.email &&
                              index < action.data.items.length - 1 &&
                              action.data.items
                                  .slice(index + 1)
                                  .filter(user => user.email === item.email)
                                  .length > 0;
                          const isEmailInvalid =
                              !!item.email &&
                              !EmailValidator.validate(item.email);
                          const isFirstNameInvalid = !item.firstName;
                          const isLastNameInvalid = !item.firstName;
                          const isRoleInvalid = !item.roleId;

                          return {
                              ...item,
                              drafted: true,
                              isEntryInvalid:
                                  isEmailDuplicated ||
                                  isEmailInvalid ||
                                  isFirstNameInvalid ||
                                  isLastNameInvalid ||
                                  isRoleInvalid,
                          };
                      })
                    : [];
            return {
                ...state,
                data: {
                    ...action.data,
                    items: updatedDataItems,
                },
            };
        }
        case ACTIONS.MODIFY_DRAFT_USERS_WITHOUT_VALIDATION: {
            return {
                ...state,
                data: {
                    ...action.data,
                    items: action.data ? action.data.items : [],
                },
            };
        }
        case ACTIONS.RESET_DRAFTS_PAGE: {
            return {
                ...state,
                loading: false,
                data: undefined,
            };
        }
        default:
            return state;
    }
};

export const draftEditReducer = compose<
    Reducer<DraftEditState, draftEditAction>
>(
    withLabelsReducer({ reducerName: 'draftEdit' }),
    withTeamsReducer({ reducerName: 'draftEdit' }),
    withPractisSetsReducer({ reducerName: 'draftEdit' })
)(draftEditBaseReducer);

const initialUpdatedDraftsState: UpdatedDraftsState = {};

export type updatedDraftsAction = ReturnType<typeof searchDraftUsersStart>;

export const updatedDraftsReducer: Reducer<
    UpdatedDraftsState,
    updatedDraftsAction
> = (
    state = initialUpdatedDraftsState,
    action: updatedDraftsAction
): UpdatedDraftsState => {
    switch (action.type) {
        default:
            return state;
    }
};
