import { memo, useCallback, useState } from 'react';

import StopBulkActionModalView from './StopBulkActionModal.view';
import FailedModalView from './FailedModal.view';
import ProgressModalView from './ProgressModal.view';

import { getModalDialogsState } from './store/reducers';
import { useSelector } from 'react-redux';
import {
    BulkActionProgressModalViewProps,
    BulkActionStopConfirmationModalViewProps,
    ConfirmationModalViewProps,
    GeneralBulkActionModalInterface,
} from './types';
import ConfirmationModalView from './ConfirmationModal.view';

function ModalDialogsController() {
    const { modalType, modalProps } = useSelector(getModalDialogsState);

    const [isStopBulkActionModalOpen, setIsStopBulkActionModalOpen] =
        useState<boolean>(false);

    /**
     * @function handleStopProgressModal
     * @returns { void }
     */
    const handleStopProgressModal = (): void => {
        setIsStopBulkActionModalOpen(true);
    };

    /**
     * @function handleStopBulkActionServiceModal
     * @returns { void }
     */
    const handleStopBulkActionServiceModal = useCallback(() => {
        Promise.resolve().then(() => {
            (
                modalProps as BulkActionStopConfirmationModalViewProps
            ).onStopBulkActionService();
            setIsStopBulkActionModalOpen(false);
        });
    }, [modalProps]);

    /**
     * @function handleCancelClick
     * @returns { void }
     */
    const handleCancelClick = (): void => {
        setIsStopBulkActionModalOpen(false);
    };

    /**
     * @function handleClickAwayProgressModal
     * @returns { void }
     */
    const handleClickAwayProgressModal = (): void => {
        let confirmAction = window.confirm(
            'Are you sure you want to leave this page?'
        );

        if (confirmAction) {
            handleStopBulkActionServiceModal();
        }
    };

    let modalComponent = <></>;

    if (!modalProps) {
        return modalComponent;
    }

    switch (modalType) {
        case 'BULK_ACTION_PROGRESS_MODAL':
            {
                const { modalTitle, dataTest, onClose } =
                    modalProps as BulkActionProgressModalViewProps;
                modalComponent = (
                    <ProgressModalView
                        modalTitle={modalTitle}
                        dataTest={dataTest}
                        onStopProgressModal={handleStopProgressModal}
                        handleClickAwayProgressModal={
                            handleClickAwayProgressModal
                        }
                        onClose={onClose}
                    />
                );
            }
            break;
        case 'BULK_ACTION_FAILED_MODAL':
            {
                const { modalTitle, onClose, dataTest } =
                    modalProps as GeneralBulkActionModalInterface;
                modalComponent = (
                    <FailedModalView
                        modalTitle={modalTitle}
                        dataTest={dataTest}
                        onClose={onClose}
                    />
                );
            }

            break;

        case 'CONFIRMATION_MODAL':
            {
                const {
                    modalTitle,
                    description,
                    onConfirm,
                    cancelButtonText,
                    confirmButtonText,
                    dialogType,
                    customization,
                    dataTest,
                } = modalProps as ConfirmationModalViewProps;
                modalComponent = (
                    <ConfirmationModalView
                        modalTitle={modalTitle}
                        description={description}
                        onConfirm={onConfirm}
                        confirmButtonText={confirmButtonText}
                        cancelButtonText={cancelButtonText}
                        dialogType={dialogType}
                        customization={customization}
                        dataTest={dataTest}
                    />
                );
            }

            break;
    }

    return (
        <>
            {modalComponent}
            {isStopBulkActionModalOpen && (
                <StopBulkActionModalView
                    modalTitle={modalProps.modalTitle}
                    dataTest={modalProps.dataTest}
                    onStopBulkActionService={handleStopBulkActionServiceModal}
                    onCancel={handleCancelClick}
                    onClose={handleCancelClick}
                />
            )}
        </>
    );
}

export default memo(ModalDialogsController);