import mainImageDesktop from '../../../../../assets/images/TeamLeader-Desktop_img.png';
import mainImageDesktop2x from '../../../../../assets/images/TeamLeader-Desktop_img@2x.png';
import mainImageDesktop3x from '../../../../../assets/images/TeamLeader-Desktop_img@3x.png';
import mainImageTablet from '../../../../../assets/images/TeamLeader-Tablet_img.png';
import mainImageTablet2x from '../../../../../assets/images/TeamLeader-Tablet_img@2x.png';
import mainImageTablet3x from '../../../../../assets/images/TeamLeader-Tablet_img@3x.png';
import mainImagePhone from '../../../../../assets/images/TeamLeader-Phone_img.png';
import mainImagePhone2x from '../../../../../assets/images/TeamLeader-Phone_img@2x.png';
import mainImagePhone3x from '../../../../../assets/images/TeamLeader-Phone_img@3x.png';
import appStoreImage from '../../../../../assets/images/button-app-store.png'
import googlePlayImage from '../../../../../assets/images/button-google-play.png'
import useWindowDimensions from '../../../../../helpers/hooks/useWindowDimensions';
import LoginAuthWrapper from '../../../../../ui/wrapper/LoginAuthWrapper';
import { Variables } from '../../../../../theme/variables';
import {
    OnboardingContent,
    OnboardingText,
    OnboardingGreeting,
    OnboardingTitle,
    OnboardingDescription,
    DownloadTitle,
    DownloadButtons,
    OnboardingButton
} from './styles';
import { Button } from '../../../../../ui/components/Button';
import { OnboardingViewProps } from '../../types';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

function TeamLeaderOnboardingView({ onRedirect }: OnboardingViewProps) {
    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.small;
    const isTablet =
        !isMobile &&
        windowDimensions.width < Variables.BreakPoints.responsiveSm;
    const isDesktop =
        windowDimensions.width >= Variables.BreakPoints.responsiveSm;

    useHtmlPageTitle("Welcome to Practis! You're an Admin on Practis");

    return (
        <LoginAuthWrapper heightToShowScroll={700}>
            <OnboardingContent>
                <OnboardingText>
                    <OnboardingGreeting>Welcome to Practis!</OnboardingGreeting>
                    <OnboardingTitle>You're a Team Leader</OnboardingTitle>
                    <OnboardingDescription>
                        When creating your Learner account on Practis, someone
                        decided that you would also be a great Team Leader and
                        assigned you this role.
                    </OnboardingDescription>
                    <OnboardingDescription>
                        This means that you'll both be able to{' '}
                        <b>train on the Practis Mobile App</b> and manage your
                        team's training on our{' '}
                        <b>web-based Practis Admin Portal</b>.
                    </OnboardingDescription>
                    <OnboardingButton>
                        <Button
                            height="40px"
                            width="290px"
                            buttonWidth="100%"
                            fontSize={13}
                            action={onRedirect}
                        >
                            Open the Practis Admin Portal
                        </Button>
                    </OnboardingButton>
                    <DownloadTitle>The Practis Mobile App</DownloadTitle>
                    <DownloadButtons>
                        <a
                            href="https://apps.apple.com/us/app/practis/id1499329539"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={appStoreImage}
                                width="137px"
                                height="40px"
                                alt="Download on the App Store"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.gopractis.android"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={googlePlayImage}
                                width="137px"
                                height="40px"
                                alt="Get it on Google Play"
                            />
                        </a>
                    </DownloadButtons>
                </OnboardingText>
                {isMobile && (
                    <img
                        src={mainImagePhone}
                        srcSet={`${mainImagePhone2x} 2x, ${mainImagePhone3x} 3x`}
                        alt=""
                    />
                )}
                {isTablet && (
                    <img
                        src={mainImageTablet}
                        srcSet={`${mainImageTablet2x} 2x, ${mainImageTablet3x} 3x`}
                        alt=""
                    />
                )}
                {isDesktop && (
                    <img
                        src={mainImageDesktop}
                        srcSet={`${mainImageDesktop2x} 2x, ${mainImageDesktop3x} 3x`}
                        alt=""
                    />
                )}
            </OnboardingContent>
        </LoginAuthWrapper>
    );
}

export default TeamLeaderOnboardingView;