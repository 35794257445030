import React, { FC } from 'react';
import styled from 'styled-components';

import { pluralize } from '../../../helpers/functions/pluralize';

interface Props {
    selectedItemsCount: number;
    itemName: string;
}

export const FiltersSelectedItemsText: FC<Props> = props => {
    const { selectedItemsCount, itemName } = props;
    return (
        <Root data-test="filters-selected-items-container">
            {selectedItemsCount > 0
                ? `${pluralize(selectedItemsCount, itemName)} selected`
                : `No ${itemName}s selected`}
        </Root>
    );
};

const Root = styled.span`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
`;