import React, { FC } from 'react';
import ChartBox from '../ChartBox/ChartBox';
import ChartBoxTitle from '../ChartBox/ChartBoxTitle';
import ChartBoxBody from '../ChartBox/ChartBoxBody';
import styled from 'styled-components';
import { EnrollmentInterface } from '../../../../../../constants/interfaces/Enrollments';

const StyledChartBox = styled(ChartBox)`
    flex-direction: row;
    max-width: 480px;
`;

const Statistics = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

const BigText = styled.span<{ isEmpty?: boolean }>`
    font-size: 36px;
    line-height: 0.89;
    font-weight: 600;
    ${({isEmpty, theme}) => isEmpty && `color: ${theme.Colors.cloudyBlue}`}
`;

const SmallText = styled.span`
    font-size: 20px;
    line-height: 2;
    font-weight: bold;
`;

const Description = styled.span`
    margin: 0;
    color: ${({ theme }) => theme.Colors.steelGrey};
    font-size: 13px;
`;

const AccuracyTestsContainer = styled(StyledChartBox)`
    border: 0;
    padding: 0;
    flex-direction: column;
    box-shadow: none;
    height: 100%;
    padding-right: 34px;
    max-width: 150px;
`;

const ProgressContainer = styled(StyledChartBox)`
    border: 0;
    padding: 0;
    flex-direction: column;
    box-shadow: none;
    height: 100%;
    flex: 1;
`;

const NumbersChartsBodies = styled(ChartBoxBody)`
    flex-direction: row;
    justify-content: space-between;
    min-width: 170px;
`;

const VerticalBorder = styled.div`
    background: #e0e8ee;
    width: 1px;
    margin-right: 34px;
`;

const Completion: FC<{
    enrollment: EnrollmentInterface;
    createdAt?: string;
    completedAt?: string;
}> = ({ enrollment, createdAt, completedAt }) => {

    return (
        <StyledChartBox>
            {!!enrollment.practisSet.scenarioCount && (
                <>
                    <AccuracyTestsContainer>
                        <ChartBoxTitle dataTest="accuracy-tests-title">Accuracy Tests</ChartBoxTitle>
                        <ChartBoxBody>
                            <Statistics>
                                {!!enrollment.averageAccuracy ? (
                                    <BigText data-test="average-accuracy-percentage">
                                        {Math.floor(
                                            enrollment.averageAccuracy * 100
                                        )}
                                        <SmallText>%</SmallText>
                                    </BigText>
                                ) : (
                                    <BigText isEmpty={true} data-test="empty-average-accuracy">—</BigText>
                                )}
                                <Description data-test="average-accuracy-text">Average</Description>
                            </Statistics>
                        </ChartBoxBody>
                    </AccuracyTestsContainer>
                    <VerticalBorder />
                </>
            )}
            <ProgressContainer>
                <ChartBoxTitle dataTest="progress-title">Progress</ChartBoxTitle>
                <NumbersChartsBodies>
                    {!!enrollment.practisSet.scenarioCount && (
                        <Statistics>
                            <BigText data-test="scenario-progress">
                                <span data-test="passed-scenario-count">{enrollment.passedScenarioCount}</span>
                                <SmallText>
                                    {' '}of <span data-test="total-scenario-count">{enrollment.practisSet.scenarioCount}</span>
                                </SmallText>
                            </BigText>
                            <Description data-test="progress-scenarios-text">Scenarios</Description>
                        </Statistics>
                    )}
                    {!!enrollment.practisSet.challengeCount && (
                        <Statistics>
                            <BigText data-test="challenge-progress">
                                <span data-test="passed-challenge-count">{enrollment.passedChallengeCount}</span>
                                <SmallText>
                                    {' '}of <span data-test="total-challenge-count">{enrollment.practisSet.challengeCount}</span>
                                </SmallText>
                            </BigText>
                            <Description data-test="progress-challenges-text">Challenges</Description>
                        </Statistics>
                    )}
                </NumbersChartsBodies>
            </ProgressContainer>
        </StyledChartBox>
    );
};

export default Completion;
