import React, { FC } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../../../helpers/hooks/useWindowDimensions';
import { Variables } from '../../../../theme/variables';
import RightArrow from '../../../../ui/icons/RightArrow';

const StyledCardContent = styled.div<{ image?: string }>`
    height: 100%;
    width: 100%;
    background-image: url(${props => props.image});
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
`;

const Info = styled.div`
    height: 310px;
    border-radius: 32px;
    align-self: flex-end;
    background: ${props => props.theme.Colors.white};
    padding: 64px;
    box-sizing: border-box;
    position: relative;
`;

const Description = styled.div<{ fontSize: number }>`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.theme.Colors.black};
    margin-top: 8px;
`;

const NextButton = styled.div<{ disabled?: boolean }>`
    height: 64px;
    width: 64px;
    border-radius: 50px;
    background: ${props => props.theme.Colors.whiteTwo};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    bottom: 16px;
    cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
    user-select: none;
    &:active {
        ${props => !props.disabled && ' opacity: 0.7'};
    }
`;

const StyledArrowIcon = styled(RightArrow)`
    height: 20px;
    width: 20px;
    color: ${props => props.theme.Colors.black};
`;

const LandingCard: FC<{
    image?: string;
    description: string;
    goNext?: () => void;
}> = ({ image, description, goNext }) => {
    return (
        <StyledCardContent image={image}>
            <Info>
                <Description
                    fontSize={
                        useWindowDimensions().width < Variables.BreakPoints.mid
                            ? 22
                            : 28
                    }
                >
                    {description}
                </Description>
                <NextButton onClick={goNext} disabled={!goNext}>
                    <StyledArrowIcon />
                </NextButton>
            </Info>
        </StyledCardContent>
    );
};

export default LandingCard;
