import { FC } from 'react';

import OverFlowTextField from './overflow-text-field.view';
import TextField from './text-field.view';
import { TextFieldProps } from './types';

const TextFieldController: FC<TextFieldProps> = props => {
    const { isOverFlowText, ...rest } = props;

    return isOverFlowText ? (
        <OverFlowTextField {...rest} />
    ) : (
        <TextField {...rest} />
    );
};

export default TextFieldController;

