import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Seats = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.441 3.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-3.5 2A3.5 3.5 0 1 1 7.84 8.05a5.507 5.507 0 0 1 3.034 4.083.75.75 0 1 1-1.482.235 4.001 4.001 0 0 0-7.9 0 .75.75 0 0 1-1.482-.236A5.507 5.507 0 0 1 3.043 8.05 3.49 3.49 0 0 1 1.941 5.5zm9-1.5a.75.75 0 1 0 0 1.5 1.5 1.5 0 0 1 .666 2.844.75.75 0 0 0-.416.672v.352a.75.75 0 0 0 .574.73c1.2.29 2.162 1.2 2.522 2.372a.75.75 0 1 0 1.434-.44 5.01 5.01 0 0 0-2.56-3.012A3 3 0 0 0 10.941 4z" fill="currentColor"/>
            </svg>
        );
    }
);

export default Seats;
