import { useCallback } from 'react';
import { CompanyUserLimitProps } from './types';
import CompanyUserLimitView from './view';
import { useHistory } from '../../../../tools/router';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../UserProfile/store/reducers';
import ROUTES from '../../../../routes/routes';
import { sendEmail } from '../../../../helpers/functions/email-helpers';

function CompanyUserLimit({
    company
}: CompanyUserLimitProps) {
    const history = useHistory();
    const profile = useSelector(getProfileState);

    const handleRequestLimitChange = useCallback(() => {
        if (!profile) return;
        sendEmail(
            `Licensed seats limit change request from ${profile.firstName} ${profile.lastName} at ${company.name}`
        );
    }, [company, profile]);

    const handleManageInvitations = useCallback(() => {
        history.push(ROUTES.INVITATIONS);
    }, [history]);

    return (
        <CompanyUserLimitView
            companyId={company.id!}
            onRequestLimitChange={handleRequestLimitChange}
            onManageInvitations={handleManageInvitations}
        />
    );
}

export default CompanyUserLimit;