import { useCallback } from 'react';

import { useSearchScenariosApi } from '../../../../../api';
import {
    clearAction,
    saveAction,
    searchFailureAction,
    searchSuccessAction,
    clearPortableScenariosAction,
    selectAllAction,
    deselectAllAction,
    selectScenariosAction,
    searchStartAction,
    resetSelectedAction,
    resetAction,
    selectMultipleScenariosAction,
} from './actions';

import { useEncapsulatedDispatch } from './helpers/useEncapsulatedDispatch';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import { useDispatch } from 'react-redux';
import { createSearchLibraryParams } from '../../../../library/tools';

export const useSearchScenariosService = (
    initialSearchParams?: SearchParams
) => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const searchScenarios = useSearchScenariosApi();

    return useCallback(
        (searchParams: SearchParams) => {
            const searchScenariosParams = createSearchLibraryParams(
                initialSearchParams || searchParams
            );

            dispatch(searchStartAction());

            return searchScenarios(searchScenariosParams)
                .then(data => {
                    dispatch(searchSuccessAction(data));
                    return data;
                })
                .catch((e: ErrorResult) => {
                    dispatch(searchFailureAction(e.message));
                    showMessage(e.message, 'error');
                });
        },
        [dispatch, searchScenarios, initialSearchParams, showMessage]
    );
};

/**
 * @function useClearPortableScenarios
 * @returns { CallableFunction }
 */
export const useClearPortableScenarios = () => {
    const dispatch = useDispatch();

    /**
     * @function callback
     * @returns { void }
     */
    return useCallback(() => {
        dispatch(clearPortableScenariosAction());
    }, [dispatch]);
}

export const useClearTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(clearAction());
    }, [dispatch]);
};

export const useSaveScenarios = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(saveAction());
    }, [dispatch]);
};

export const useSelectTeams = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: number) => {
            dispatch(selectScenariosAction(ids));
        },
        [dispatch]
    );
};

export const useSelectMultipleTeams = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: number[]) => {
   
            dispatch(selectMultipleScenariosAction(ids));
        },
        [dispatch]
    );
};

export const useSelectAllTeams = () => {
    const dispatch = useEncapsulatedDispatch();
   
    return useCallback(
        (processedTeams: number[]) => {
      
            dispatch(selectAllAction(processedTeams));
        },
        [dispatch]
    );
};

export const useDeselectAllScenarios = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(deselectAllAction());
    }, [dispatch]);
};

export const useResetSelectedTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetSelectedAction());
    }, [dispatch]);
};

export const useResetTeams = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetAction());
    }, [dispatch]);
};
