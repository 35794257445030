import { MenuItem, Select } from '@material-ui/core';
import { DEV_ENV_URLS } from '../../../constants/variables/general';
import { StyledForm } from './styles';
import { ApiPlatformViewProps } from './types';

function ApiPlatformDropdownView({apiPlatform, onPlatformChange, dataTest}: ApiPlatformViewProps) {
    const handleChange = (event: any) => {
        onPlatformChange(event.target.value as string);
      };
    
    return <StyledForm>
        <Select
        value={apiPlatform}
        label="Platform"
        onChange={handleChange}
        placeholder="Choose platform ✈️"
        data-test={dataTest}
        >
            {
                Object.keys(DEV_ENV_URLS).map((value) => {
                    return  <MenuItem value={value}>{value.toUpperCase()}</MenuItem>
                })
            }
        </Select>
    </StyledForm>
}

export default ApiPlatformDropdownView