export enum ACTIONS {
    CREATE_AUDIO_PLAYLIST = 'CREATE_AUDIO_PLAYLIST',
    PLAY_AUDIO = 'PLAY_AUDIO',
    STOP_AUDIO = 'STOP_AUDIO',
    RESET_PLAYLIST = 'RESET_PLAYLIST',
    START_AUDIO_RECORDING = 'START_AUDIO_RECORDING',
    STOP_AUDIO_RECORDING = 'STOP_AUDIO_RECORDING',
    GO_NEXT = 'GO_NEXT',
}

export function createPlaylistAction(data: any) {
    return {
        type: ACTIONS.CREATE_AUDIO_PLAYLIST as ACTIONS.CREATE_AUDIO_PLAYLIST,
        data,
    };
}

export function playAudioAction(scriptLineId: number | string) {
    return {
        type: ACTIONS.PLAY_AUDIO as ACTIONS.PLAY_AUDIO,
        scriptLineId,
    };
}

export function stopAudioAction(scriptLineId: number | string) {
    return {
        type: ACTIONS.STOP_AUDIO as ACTIONS.STOP_AUDIO,
        scriptLineId,
    };
}

export function resetPlaylistAction() {
    return {
        type: ACTIONS.RESET_PLAYLIST as ACTIONS.RESET_PLAYLIST,
    };
}

export function startAudioRecordingAction(scriptLineId: number | string) {
    return {
        type: ACTIONS.START_AUDIO_RECORDING as ACTIONS.START_AUDIO_RECORDING,
        scriptLineId,
    };
}

export function stopAudioRecordingAction() {
    return {
        type: ACTIONS.STOP_AUDIO_RECORDING as ACTIONS.STOP_AUDIO_RECORDING,
    };
}

export function goNextAction() {
    return {
        type: ACTIONS.GO_NEXT as ACTIONS.GO_NEXT,
    };
}
