import { ModalDialogInterface } from './types';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';

export const initialState: ModalDialogInterface = {
    isLoading: true,
    modalType: null,
    modalProps: null,
};