import { memo } from 'react';
import { isEmpty } from 'lodash';

import { TableContainer, TableExtendContainer, Wrapper } from './styles';
import { Table } from './table';
import { TableDivider } from './table-divider';
import { TableHeaderFilter } from './table-header-filter';
import { TableRefresh } from './table-refresh';
import { TableTools } from './table-tools';
import { TableViewMode } from './table/type';
import { TableWrapperProps } from './types';

const TableWrapperView = (props: TableWrapperProps) => {
    const {
        addOverflow,
        tableWrapperClassName,
        tableContainerClassName,
        tableInnerClassName,
        tableToolsOptions,
        cornered,
        shouldHideTableDivider,
        tableRefreshConfigurations,
        tableEmptyStateConfigurations,
        selectedLabels,
        headerFilterPosition = 'TOP',
        onRemoveLabel,
        configurations,
        data,
        tableStates,
        scrollRef,
        scrollPaginationProps,
        viewMode = TableViewMode.SIMPLE,
    } = props;

    return (
        <Wrapper>
            <TableContainer className={tableWrapperClassName}>
                {tableRefreshConfigurations && (
                    <TableRefresh
                        {...tableRefreshConfigurations}
                        top={
                            !isEmpty(selectedLabels) &&
                            headerFilterPosition === 'TOP'
                                ? -42
                                : undefined
                        }
                    />
                )}
                {selectedLabels && headerFilterPosition === 'TOP' && (
                    <TableHeaderFilter
                        selectedLabels={selectedLabels}
                        onRemoveLabel={onRemoveLabel}
                    />
                )}
                <TableTools {...tableToolsOptions} />

                <TableDivider hide={shouldHideTableDivider} />

                {selectedLabels && headerFilterPosition === 'BOTTOM' && (
                    <TableHeaderFilter
                        selectedLabels={selectedLabels}
                        onRemoveLabel={onRemoveLabel}
                    />
                )}

                <TableExtendContainer>
                    <Table
                        data={data}
                        tableStates={tableStates}
                        cornered={cornered}
                        addOverflow={addOverflow}
                        tableContainerClassName={tableContainerClassName}
                        tableInnerClassName={tableInnerClassName}
                        tableEmptyStateConfigurations={
                            tableEmptyStateConfigurations
                        }
                        configuration={configurations}
                        scrollRef={scrollRef}
                        scrollPaginationProps={scrollPaginationProps}
                        viewMode={viewMode}
                    />
                </TableExtendContainer>
            </TableContainer>
        </Wrapper>
    );
};

export default memo(TableWrapperView);
