import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Label } from '../../../../../../../../../constants/interfaces/Label';
import { useHandleSelectLabels } from '../../../../../../../../../features/labels/tools';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../../../../../../../features/portableLabels/store/hors/withLabels/services';
import { useLabelsState } from '../../../../../../../../../features/portableLabels/store/hors/withLabels/states';
import { LabelFilters } from './LabelFilters';
import { listToTree } from '../../../../../../../../../helpers/functions/list-to-tree';
import { usePortableLabelsState } from '../../../../../../../../../features/portableLabels/store/states';
import { TableSearchInput } from '../../../../../../../../../ui/components/table-wrapper/table-tools/table-search-input';
import { TableDivider } from '../../../../../../../../../ui/components/table-wrapper/table-divider';
import { TableHeaderFilter } from '../../../../../../../../../ui/components/table-wrapper/table-header-filter';
import { Popup } from '../../../../../../../../../ui/components/Popup';
import FilterByHandler from '../../../../../../../../../ui/components/Filters/FilterByHandler';

const Container = styled.div`
    padding: 0 24px;
`;

const TableTools = styled.div`
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
`;

const FilteredByWrapper = styled.div`
    padding: 0 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const SearchContainer = styled.div`
    flex: 1;
`;

const LabelsContainer = styled.div`
    height: 40px;
    width: 40px;
    margin-left: 16px;
`;

const TableFilters: FC<{
    setSearchTerm(value: string): void;
    setLabelIDs(labels: number[]): void;
    isViewMode?: boolean;
    dataTest?: string;
}> = ({ setSearchTerm, setLabelIDs, isViewMode, dataTest }) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();

    useEffect(() => {
        setLabelIDs(labels.saved);
    }, [labels.saved, setLabelIDs]);

    const labelsTree = listToTree(labelsList.data.items);
    const handleSelectLabels = useHandleSelectLabels();

    const onRemoveLabel = (label: Label) => {
        setSelectLabels(handleSelectLabels(label, labels.saved, labelsTree));
        saveLabels();
    };

    return (
        <Container>
            <TableTools>
                <SearchContainer>
                    <TableSearchInput
                        onChange={setSearchTerm}
                        disabled={isViewMode}
                        dataTest={dataTest && `${dataTest}-search-input`}
                    />
                </SearchContainer>
                <LabelsContainer>
                    <Popup<HTMLButtonElement>
                        content={({ hide }) => (
                            <LabelFilters
                                onLabelsChange={setLabelIDs}
                                onSuccessApply={() => hide()}
                            />
                        )}
                    >
                        {(ref, { toggleShown, shown }) => (
                            <FilterByHandler
                                ref={ref}
                                open={shown}
                                disabled={isViewMode}
                                toggleOpen={toggleShown}
                                filtersCount={labels.saved.length}
                                dataTest={dataTest && `${dataTest}-filters`}
                            />
                        )}
                    </Popup>
                </LabelsContainer>
            </TableTools>
            {labels.saved && !!labels.saved.length && (
                <FilteredByWrapper>
                    <TableDivider />
                    <TableHeaderFilter
                        selectedLabels={labels.saved}
                        onRemoveLabel={onRemoveLabel}
                    />
                </FilteredByWrapper>
            )}
            <TableDivider />
        </Container>
    );
};

export default TableFilters;
