import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../../../../../constants/interfaces/ScriptLine';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileState } from '../../../../../../../UserProfile/store/reducers';
import { UserProfile } from '../../../../../../../../constants/interfaces/User';
import {
    useDeleteScriptLineService,
    useModifyScriptTextService,
    useUploadScenarioAudioService,
} from '../../../../../store/services';
import {
    useClearVoiceService,
    useTextToSpeechService,
} from '../../../../../../../../features/textToSpeech/store/services';
import { useVoiceGenerationState } from '../../../../../../../../features/textToSpeech/store/states';
import { findItem } from '../../../../../../../../tools/ramda';
import { getCompanyState } from '../../../../../../../CompanySettings/store/reducers';
import { CompanyInterface } from '../../../../../../../../constants/interfaces/Company';
import { CompanyVoiceSettings } from '../../../../../../../../constants/interfaces/CompanyVoiceSettings';
import {
    AudioInterface,
    getAudioState,
} from '../../../../../../../../features/player/store/reducers';
import {
    useGoNextService,
    usePlayAudioService,
    useResetAudioService,
    useStartAudioRecordingService,
    useStopAudioRecordingService,
    useStopAudioService,
} from '../../../../../../../../features/player/store/services';
import { useCompanyVoiceSettingsState } from '../../../../../../../CompanySettings/store/states';
import ScriptItemTextarea from '../ScriptItemTextArea/ScriptItemTextArea';
import { Button } from '../../../../../../../../ui/components/Button';
import {
    Comment,
    CommentBody,
    CommentDescription,
    CommentTitle,
    CommentTools,
} from '../../../../../../../../ui/components/Comment';
import AudioRecorder from '../../../../../../../../ui/components/AudioRecorder/AudioRecorder';
import { AudioPlayer } from '../../../../../../../../ui/components/AudioPlayer/AudioPlayer';
import Generate from '../../../../../../../../ui/icons/Generate';
import Record from '../../../../../../../../ui/icons/Record';
import Play from '../../../../../../../../ui/icons/Play';
import { useShowConfirmModalDialog } from '../../../../../../../../ui/components/ModalDialogs/store/actions';
import { getTextToSpeechSettingsOrDefault } from '../../../../../../../../features/textToSpeech/helpers';

const StyledScriptItem = styled.div`
    width: 100%;
`;

const StyledButton = styled(Button)`
    margin-right: 8px;
    font-size: 11px;
    font-weight: 600;
`;

const StyledScriptAction = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledAudioSection = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const StyledTextarea = styled(ScriptItemTextarea)`
    color: ${props => props.theme.Colors.blackTwo};
`;

const DisabledArea = styled.div`
    position: absolute;
    height: 100%;
    background: transparent;
    width: 100%;
`;

const ScriptItem: FC<{
    script: ScriptLine;
    handleScriptLineDelete: any;
    handleScriptTextModify: (
        value: string,
        id: number | string
    ) => void;
    handleUploadAudio: (
        file: any,
        associatedEntityId: number | string,
        filename?: string
    ) => any;
    profile?: UserProfile;
    company: CompanyInterface;
    player: AudioInterface;
    handlePlayAudio: (scriptLineId: number | string) => void;
    handleStopAudio: (scriptLineId: number | string) => void;
    handleStartRecording: (scriptLineId: number | string) => void;
    handleStopRecording: () => void;
    handleGoNext: () => void;
    handleResetPlaylist: () => void;
    disabled: boolean;
    editable: boolean;
    dispatch: Dispatch;
    voiceSettings?: CompanyVoiceSettings[];
}> = ({
    script,
    handleScriptLineDelete,
    handleScriptTextModify,
    handleUploadAudio,
    editable,
    player,
    handlePlayAudio,
    handleStopAudio,
    handleGoNext,
    handleResetPlaylist,
    handleStartRecording,
    handleStopRecording,
    voiceSettings,
}) => {
    const textToSpeech = useTextToSpeechService();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const clearAudio = useClearVoiceService();
    const voice = useVoiceGenerationState().scenarios;
    const generatingAudio = voice && voice.generating;
    const loadingAudio = !!(
        voice &&
        voice.data &&
        findItem(voice.data, 'id', script.id)
    );
    const voiceData =
        voice &&
        voice.data &&
        findItem(voice.data, 'id', script.id) &&
        findItem(voice.data, 'id', script.id).voice;

    const [record, setRecord] = useState({ state: false, open: false });
    const [save, setSave] = useState(false);
    const [audio, setAudio] = useState<any>({});
    const disabled =
        (!!player.recording && player.recording !== script.id) ||
        generatingAudio;
    const isRepSpeaker = script.speaker === ScriptLineSpeaker.REP;

    useEffect(() => {
        return () => {
            handleClickAway();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (save) {
            if (audio) {
                handleUploadAudio(audio, script.id).then(
                    (result: string) => {
                        if (generatingAudio) {
                            clearAudio({
                                feature: 'scenarios',
                                itemId: script.id,
                            });
                        }
                    }
                );
            }
        }
        // eslint-disable-next-line
    }, [audio]);

    const startRecording = (): void => {
        handleResetPlaylist();
        setTimeout(() => {
            handleStartRecording(script.id);
            setRecord({ open: true, state: true });
        }, 100);
    };

    const generateAudio = () => {
        const settingsOrDefault = getTextToSpeechSettingsOrDefault(voiceSettings);
        const settings = script.speaker === ScriptLineSpeaker.CUSTOMER
            ? settingsOrDefault.customer
            : settingsOrDefault.representative;

        handleResetPlaylist();
        textToSpeech(
            script.text,
            settings,
            { feature: 'scenarios', itemId: script.id }
        );
    };

    useEffect(() => {
        if (voiceData) {
            setSave(true);
            setAudio(voiceData);
        }
    }, [voiceData]);

    const saveRecording = (): void => {
        setSave(true);
        setRecord({ ...record, state: false });
    };

    const handleClickAway = (): void => {
        setSave(false);
        setRecord({ ...record, state: false });
    };

    const handleStop = (audio: any) => {
        setAudio(audio.blob);
        handleStopRecording();
        setRecord({ state: false, open: false });
    };

    const startPlayingAudio = (): void => {
        handleResetPlaylist();
        handlePlayAudio(script.id);
    };

    const myRef = useRef<any>(null);

    const onHandleGoNext = () => {
        if (player.status === 'playlist' && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            handleGoNext();
        }
    };

    const handleRemoveItem = useCallback(() => {
        showConfirmationModalDialog({
            modalTitle: 'Are You Sure?',
            description: 'Are you sure you want to delete the line?',
            onConfirm: () => handleScriptLineDelete(script.id),
        });
    }, [handleScriptLineDelete, script.id, showConfirmationModalDialog]);

    return (
        <>
        <StyledScriptItem ref={myRef}>
            <Comment
                reverse={isRepSpeaker}
                disabled={disabled || generatingAudio}
            >
                <CommentBody>
                    <CommentDescription
                        hideRemoveIcon={!editable}
                        onDelete={handleRemoveItem}
                        dataTest={
                            isRepSpeaker
                                ? 'delete-scenario-rep-line'
                                : 'delete-scenario-customer-line'
                        }
                    >
                        {(!editable || disabled) && <DisabledArea />}
                        <CommentTitle>
                            {isRepSpeaker ? 'Rep' : 'Customer'}
                        </CommentTitle>
                        <StyledTextarea
                            handleValueChange={(value: string) =>
                                handleScriptTextModify(value, script.id)
                            }
                            scriptLineId={script.id}
                            scriptLineKeywords={script.keywords}
                            value={script.text}
                            autoFocus={!script.text}
                            viewMode={!editable || disabled}
                            recording={
                                !!player.recording &&
                                player.recording === script.id
                            }
                            placeholder={
                                isRepSpeaker
                                    ? 'Write representative’s line here'
                                    : 'Write customer’s line here'
                            }
                            dataTest={
                                isRepSpeaker
                                    ? 'scenario-rep-line-input'
                                    : 'scenario-customer-line-input'
                            }
                        />
                    </CommentDescription>
                    <CommentTools>
                        {!record.open && player.current !== script.id && (
                            <StyledScriptAction>
                                <StyledAudioSection>
                                    {editable && (
                                        <StyledButton
                                            label="Record Audio"
                                            action={startRecording}
                                            iconRender={<Record />}
                                            color="warning"
                                            variant="transparent"
                                            buttonSize={12}
                                            width={'90px'}
                                            dataTest={
                                                isRepSpeaker
                                                    ? 'record-scenario-rep-line-audio'
                                                    : 'record-scenario-customer-line-audio'
                                            }
                                        />
                                    )}
                                    {editable && script.text.length > 0 && (
                                        <StyledButton
                                            label="Generate Audio"
                                            iconRender={<Generate />}
                                            action={generateAudio}
                                            variant="transparent"
                                            loading={loadingAudio}
                                            buttonSize={14}
                                            dataTest={
                                                isRepSpeaker
                                                    ? 'generate-scenario-rep-line-audio'
                                                    : 'generate-scenario-customer-line-audio'
                                            }
                                        />
                                    )}
                                    {script.audioUrl && (
                                        <StyledButton
                                            label="Play"
                                            iconRender={<Play />}
                                            action={startPlayingAudio}
                                            variant="transparent"
                                            buttonSize={14}
                                            width={'40px'}
                                            dataTest={
                                                isRepSpeaker
                                                    ? 'play-scenario-rep-line'
                                                    : 'play-scenario-customer-line'
                                            }
                                        />
                                    )}
                                </StyledAudioSection>
                            </StyledScriptAction>
                        )}
                        {record.open && (
                            <AudioRecorder
                                record={record.state}
                                onClickAway={handleClickAway}
                                onStop={handleStop}
                                onSave={saveRecording}
                            />
                        )}
                        {player.current === script.id && (
                            <AudioPlayer
                                audio={script.audioUrl}
                                duration={
                                    script.duration ? script.duration : 10
                                }
                                onStop={() => handleStopAudio(script.id)}
                                autoPlay={true}
                                goNext={onHandleGoNext}
                            />
                        )}
                    </CommentTools>
                </CommentBody>
            </Comment>
        </StyledScriptItem>
        </>
    );
};

export const ScriptItemContainer: FC<{
    script: ScriptLine;
    disabled: boolean;
    editable: boolean;
}> = ({ script, disabled, editable }) => {
    const dispatch = useDispatch();

    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);
    const player = useSelector(getAudioState);

    const resetPlaylist = useResetAudioService();
    const goNext = useGoNextService();
    const playAudio = usePlayAudioService();
    const stopAudio = useStopAudioService();
    const startRecording = useStartAudioRecordingService();
    const stopRecording = useStopAudioRecordingService();

    const deleteScriptLine = useDeleteScriptLineService();
    const modifyScriptText = useModifyScriptTextService();

    const uploadScenarioAudio = useUploadScenarioAudioService();
    const voiceSettings = useCompanyVoiceSettingsState();

    return (
        <ScriptItem
            script={script}
            handleScriptLineDelete={deleteScriptLine}
            handleScriptTextModify={modifyScriptText}
            handleUploadAudio={uploadScenarioAudio}
            profile={profile}
            company={company}
            player={player}
            handlePlayAudio={playAudio}
            handleStopAudio={stopAudio}
            handleStartRecording={startRecording}
            handleStopRecording={stopRecording}
            handleGoNext={goNext}
            handleResetPlaylist={resetPlaylist}
            voiceSettings={voiceSettings.data}
            disabled={disabled}
            editable={editable}
            dispatch={dispatch}
        />
    );
};

export default ScriptItemContainer;