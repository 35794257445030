import { FC } from 'react';

import { Button } from '../../../../Button';
import DialogWrapper from '../../../../DialogWrapper/DialogWrapper';
import { Variables } from '../../../../../../theme/variables';
import {
    Bold,
    StyledActions,
    StyledButtonContainer,
    StyledContent,
    StyledDescription,
    StyledTitle,
} from './styles';

export const BulkActionConfirmation: FC<{
    onProceed?(): void;
    onClose?(): void;
    itemsCount: number;
}> = ({ onProceed, onClose, itemsCount }) => {
    return (
        <DialogWrapper
            customization={{ width: 400, padding: '30px 32px 40px 40px' }}
        >
            <StyledTitle data-test="confirmation-modal-title">Confirm bulk action</StyledTitle>
            <StyledContent>
                <StyledDescription marginTop="9px" data-test="confirmation-modal-description">
                    This action will affect all <Bold>{itemsCount} Items</Bold>.
                    Are you sure you want to continue?
                </StyledDescription>
                <StyledActions marginTop={23}>
                    <Button
                        width="128px"
                        height="40px"
                        action={onClose}
                        color="warning"
                        variant="inverse"
                        dataTest="confirmation-modal-cancel"
                    >
                        Cancel
                    </Button>
                    <StyledButtonContainer>
                        <Button
                            width="128px"
                            height="40px"
                            color="warning"
                            customColor={Variables.Colors.tomato}
                            action={() => !!onProceed && onProceed()}
                            dataTest="confirmation-modal-confirm"
                        >
                            Proceed
                        </Button>
                    </StyledButtonContainer>
                </StyledActions>
            </StyledContent>
        </DialogWrapper>
    );
};
