import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSetFilterHeaderSearchActive } from '../../../searchable-filter-header/store/services';
import { SearchableFilterHeaderKey } from '../../../searchable-filter-header/store/types';

import { disableSingleTeamFilter, selectSingleTeam } from './actions';

export const useSelectSingleTeam = () => {
    const dispatch = useDispatch();

    return useCallback(
        (selectedTeamId: number) => {
            dispatch(selectSingleTeam(selectedTeamId));
        },
        [dispatch]
    );
};

export const useClearSelectedTeam = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(selectSingleTeam(null));
    }, [dispatch]);
};

export const useDisableSingleTeamFilter = () => {
    const dispatch = useDispatch();

    return useCallback((isDisabled: boolean) => {
        dispatch(disableSingleTeamFilter(isDisabled));
    }, [dispatch]);
}

export const useClearSingleTeamFilter = () => {
    const clearSelectedSingleTeam = useClearSelectedTeam();
    const setSingleTeamSearchActive = useSetFilterHeaderSearchActive(SearchableFilterHeaderKey.SINGLE_TEAM);

    return useCallback(() => {
        clearSelectedSingleTeam();
        setSingleTeamSearchActive(false);
    }, [clearSelectedSingleTeam, setSingleTeamSearchActive]);
};
