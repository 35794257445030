import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getChunkServiceState } from '../../../services/ChunkRequestService/store/reducers';
import StopWarning from '../../icons/StopWarning';
import { Button } from '../Button';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { StripedProgressBar } from '../ProgressBar';
import {
    ActionsContainer,
    ActionWarningContainer,
    ProgressBarContainer,
    Title,
    WarningText,
} from './styles';
import { BulkActionProgressModalViewProps } from './types';

function ProgressModalView(props: BulkActionProgressModalViewProps) {
    const { modalTitle, dataTest = 'progress-modal', onStopProgressModal, handleClickAwayProgressModal } =
        props;

    const { percentage } = useSelector(getChunkServiceState);

    /**
     * @function onUnload
     * @param { Event } event
     * @returns { void }
     */
    const onUnload = useCallback(
        (event: Event) => {
            if (percentage > 0 && percentage < 100) {
                event.preventDefault();
                event.returnValue = true;
            }
        },
        [percentage]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);

        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [onUnload]);

    return (
        <DialogWrapper
            customization={{ width: 400, padding: '30px 32px' }}
            onClickAway={handleClickAwayProgressModal}
        >
            <div onClick={event => event.stopPropagation()}>
                <Title data-test={dataTest && `${dataTest}-title`}>{modalTitle}</Title>

                <ProgressBarContainer>
                    <StripedProgressBar percentage={percentage} dataTest={dataTest} />
                </ProgressBarContainer>

                <ActionsContainer justify="space-between">
                    <ActionWarningContainer>
                        <span data-test={dataTest && `${dataTest}-warning-icon`}><StopWarning /></span>
                        <WarningText data-test={dataTest && `${dataTest}-warning`}>
                            Do not refresh or close the page to avoid losing
                            data.
                        </WarningText>
                    </ActionWarningContainer>
                    <Button
                        width="110px"
                        height="40px"
                        action={onStopProgressModal}
                        disabled={percentage === 100}
                        dataTest={dataTest && `${dataTest}-stop`}
                    >
                        Stop
                    </Button>
                </ActionsContainer>
            </div>
        </DialogWrapper>
    );
}

export default ProgressModalView;