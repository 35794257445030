import { FC, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import User from '../../../../ui/icons/User';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    ASSIGN_LABEL_OPTIONS,
    LOAD_ASSIGNED_LABELS,
    isAdminRole,
    isPractisAdminRole,
} from '../../../../constants/enums';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { UserV2, UserProfile } from '../../../../constants/interfaces/User';
import ROUTES from '../../../../routes/routes';
import {
    useAssignTeamsToUsersService,
    useDeleteUserLabelService,
    useDeleteUserService,
    useGetUsersReportService,
    useNudgeTraineesService,
    useSearchUsersService,
    useUpdateAllSelectedUsersStateService,
    useUpdateSelectedUserStateService,
} from '../../store/services';
import { useUsersState } from '../../store/states';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import UsersPageNavigationContainer from '../../components/UsersPageNavigation';
import { formatDate } from '../../../../helpers/functions/date-convert';
import {
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import {
    clearSelectedItemIfNotLoaded,
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabels,
} from '../../../labels/tools';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { usePermissionsState } from '../../../permissions/store/state';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { countAppliedFiltersCount } from '../../../teams/pages/ListOfTeams/tools';
import { Popup } from '../../../../ui/components/Popup';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { UsersFilter } from '../../components/Filters/UsersFilter';
import { WithLabelsContext } from '../../../portableLabels';
import { WithTeamsContext } from '../../../portableTeams';
import UsersListActions from '../../components/ListActions/UsersListActions';
import SelectedUserTeamsComponent from '../../components/SelectedUserTeams';
import { useLoggedInUserPermissionsHelper } from '../../tools';
import { arrayIdsDiff } from '../../../../helpers/functions/array-diff';
import {
    useDeselectAllTeams,
    useSaveTeams,
} from '../../../portableTeams/store/hors/withTeams/services';
import { useSessionEffect } from '../../../common';
import { normalizeSP } from '../../../../helpers/functions/searchparams-normalize';
import {
    useAssignedLabelsIdsApi,
    useAssignUserLabelsApi,
    useDeleteUserLabelsApi,
} from '../../../../api';
import { getSearchState } from '../../../../features/searchState/store/reducer';
import { SearchStateInterface } from '../../../../features/searchState/store/states';
import { SEARCH_STATE } from '../../../../features/searchState/constants';
import { isEmpty } from '../../../../helpers/functions/object-helpers';
import { isEmpty as isLodashEmpty } from 'lodash';
import { WithPractisSetsContext } from '../../../../features/portablePractisSets';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { useStyles } from './styles';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { NudgeUser } from '../../../../ui/components/table-wrapper/table/TableAssignOptionsMenu/components/NudgeUser';
import { EmptyCellDash } from '../../../../ui/components/table-wrapper/table/EmptyCellDash';
import {
    useNudgeUsersBulkActionService,
    useUpdateLabelsBulkActionService,
    useUpdateMultipleEntityLabelsBulkActionService,
} from '../../../../services/GeneralBulkActionServices';
import { useDeleteUserBulkActionService } from '../../services/UserBulkActionsService';
import { onTriggerEvent } from '../../../../helpers/functions/Events';
import {
    CLEAR_PAGING,
    EventNameList,
} from '../../../../helpers/functions/Events/types';
import { NudgeData } from '../../../../api/alert/types';
import { UPDATE_MULTIPLE_LABEL_ACTION } from '../../../../services/GeneralBulkActionServices/constants';
import { UserStatus } from '../../store/costants';
import { useBuildUserName } from '../../tools/hooks';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';
import { CompanyUserStatsText } from '../../../../ui/components/CompanyUserStatsText';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const Users: FC<{
    history: History<any>;
    searchUsers: (
        sp: SearchParams,
        userStatuses: (keyof typeof UserStatus)[],
        companyId: number
    ) => void;
    assignTeamsToUsers: (
        userId: number,
        teams: number[],
        deleted: number[],
        hideMessage?: boolean,
        successCallback?: () => void
    ) => void;
    deleteUserLabel: (
        userId: number,
        labelId: number,
        successCallback?: () => void
    ) => void;
    setAssignLabelsAction(labels: number[]): void;
    onDeleteUser: (
        userIds: number[],
        search?: SearchParams,
        selectAll?: boolean,
        successCallback?: () => void
    ) => void;
    profile?: UserProfile;
    users: ListResult<UserV2>;
    selectedUsers?: number[];
    loading?: boolean;
    selectedLabels?: number[];
    assignedLabels?: number[];
    updateAllSelectedUsers(
        userIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedUser(userId: number): void;
    updatedLabels?: any;
    getUsersReportService?(
        searchParams: SearchParams,
        companyId?: number,
        userIds?: number[],
        totalCount?: number
    ): void;
    onNudgeTrainees(
        nudgeUserDate: NudgeData[],
        successCallback?: () => void
    ): void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    searchState: SearchStateInterface;
}> = ({
    users,
    history,
    searchUsers,
    assignTeamsToUsers,
    setAssignLabelsAction,
    onDeleteUser,
    profile,
    selectedLabels,
    selectedUsers,
    assignedLabels,
    deleteUserLabel,
    updateAllSelectedUsers,
    updateSelectedUser,
    updatedLabels,
    loading,
    getUsersReportService,
    onNudgeTrainees,
    saveGlobalLabelFilters,
    searchState,
}) => {
    const classes = useStyles();

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const buildUserName = useBuildUserName();

    const [showNudgeDialogMemberId, setShowNudgeDialogMemberId] =
        useState<any>(null);
    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const canAccessPage = useHandleAccessPage();
    const loggedInUserPermissions = useLoggedInUserPermissionsHelper();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        labelIDs: selectedLabels,
        orderBy: null,
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState.key === SEARCH_STATE.ACTIVE_USERS.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        refreshSearchParams,
        setCustom,
    } = useSearchParamsState(usableParams);

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);

    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const isCheckboxPartiallySelected =
        users &&
        users.items &&
        selectedUsers &&
        selectedUsers.length < users.items.length;

    const updateAllUsersCheck = useCallback(
        (checked: boolean) => {
            const userIds =
                (users && users.items.map((user: UserV2) => user.id)) || [];
            updateAllSelectedUsers(
                userIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [users, isCheckboxPartiallySelected, updateAllSelectedUsers]
    );

    const permissions = usePermissionsState();

    const handleViewProfile = (user: UserV2) => {
        if (
            !user ||
            !profile ||
            !permissions.includes(NEW_PERMISSIONS.VIEW_USER_PERFORMANCE)
        )
            return;

        history.push(
            ROUTES.USER_PERFORMANCE.replace(':userId', user.id.toString()),
            { useGoBack: true }
        );
    };

    const handleUserSettings = (user: UserV2) => {
        if (
            !user ||
            !profile ||
            !permissions.includes(NEW_PERMISSIONS.VIEW_PROFILE)
        )
            return;

        history.push(ROUTES.PROFILE.replace(':userId', user.id.toString()));
    };

    const handleItemEdit = (user: UserV2) => {
        if (!user) return;
        history.push(ROUTES.PROFILE.replace(':userId', user.id.toString()));
    };

    const handleUserCheck = (e: any, user: UserV2) => {
        e.stopPropagation();
        if (!!user.id) {
            updateSelectedUser(user.id);
        }
    };

    const getDeletedLabels = useCalculateDeletedLabels();
    const assignLabelsToUser = useAssignUserLabelsApi();
    const deleteLabelsFromUser = useDeleteUserLabelsApi();

    const refreshData = useCallback(() => {
        if (!profile?.companyId) return;
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchUsers(searchParams, [UserStatus.ACTIVE], profile.companyId);
        setLastRefreshed(new Date());
        setIsSelectAll(false);
        updateAllSelectedUsers([], true, isCheckboxPartiallySelected);
    }, [
        searchParams,
        initialOffset,
        searchUsers,
        updateAllSelectedUsers,
        isCheckboxPartiallySelected,
        profile?.companyId,
    ]);

    const handleDeleteUserBulkActionService = useDeleteUserBulkActionService(
        searchParams,
        profile?.companyId ?? 0,
        users?.count ?? 0,
        refreshData
    );

    const updateMultipleUsersLabels =
        useUpdateMultipleEntityLabelsBulkActionService(
            assignLabelsToUser,
            deleteLabelsFromUser,
            isSelectAll,
            {
                actionName: UPDATE_MULTIPLE_LABEL_ACTION,
                actionFunction: searchUsers,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            limit: 1,
                            offset: users.count,
                        },
                        userStatuses: [UserStatus.ACTIVE],
                        companyId: profile?.companyId ?? 0,
                        shouldSetStore: false,
                    },
                    fieldName: 'searchParams.offset',
                },
            },
            refreshData
        );

    const assignLabels = async () => {
        if (assignedLabels && selectedUsers) {
            const { preAssignedLabels } = await getAssignedLabelsIds(
                selectedUsers,
                LOAD_ASSIGNED_LABELS.USERS_ACTIVE,
                ASSIGN_LABEL_OPTIONS.USER,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                return calculatePreSelectedLabels(selectedUsers, users, data);
            });

            const deletedLabels = getDeletedLabels(assignedLabels);

            const deletedLabelsFiltered = deletedLabels.filter(
                x =>
                    preAssignedLabels.includes(x) ||
                    preAssignedLabels.includes(-x)
            );
            const selectedLabelsFiltered = assignedLabels.filter(
                x => x > 0 && !preAssignedLabels.includes(x)
            );

            updateMultipleUsersLabels(
                {
                    entityName: 'user',
                    entityIds: selectedUsers,
                },
                selectedLabelsFiltered,
                deletedLabelsFiltered
            );
        }
    };

    const handleManageTeams = useCallback(
        (user: UserV2, selectedTeams) => {
            const diff = arrayIdsDiff(selectedTeams, user.teams ?? []);
            assignTeamsToUsers(
                user.id,
                diff.selected,
                diff.deleted,
                false,
                refreshData
            );
        },
        [assignTeamsToUsers, refreshData]
    );

    const getAssignedLabelsIds = useAssignedLabelsIdsApi();

    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();

    const onNudgeSingleUserSuccessCallback = () => {
        setShowNudgeDialogMemberId(null);
    };

    const handleNudgeSingleUser = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;
            if (showNudgeDialogMemberId) {
                const nudgeUserDate: NudgeData = {
                    type: 'common',
                    enrollmentId: null,
                    receiverId: showNudgeDialogMemberId,
                    senderName,
                    text,
                };

                onNudgeTrainees(
                    [nudgeUserDate],
                    onNudgeSingleUserSuccessCallback
                );
            }
        },
        [showNudgeDialogMemberId, onNudgeTrainees]
    );

    const onNudgeMultipleUsersSuccessCallback = useCallback(() => {
        updateAllSelectedUsers([], true, isCheckboxPartiallySelected);
    }, [isCheckboxPartiallySelected, updateAllSelectedUsers]);

    const nudgeUsersBulkActionService = useNudgeUsersBulkActionService(
        'common',
        {
            companyId: profile?.companyId ?? 0,
        },
        searchParams,
        users.count,
        refreshData
    );

    const handleNudgeMultipleUsers = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;

            if (isSelectAll) {
                nudgeUsersBulkActionService({ ...{ text }, ...{ senderName } });
            } else {
                if (selectedUsers) {
                    const nudgeUserDate = selectedUsers.map(
                        user =>
                            ({
                                type: 'common',
                                enrollmentId: null,
                                receiverId: user,
                                text,
                                senderName,
                            } as NudgeData)
                    );

                    onNudgeTrainees(
                        nudgeUserDate,
                        onNudgeMultipleUsersSuccessCallback
                    );
                }
            }
        },
        [
            isSelectAll,
            nudgeUsersBulkActionService,
            selectedUsers,
            onNudgeTrainees,
            onNudgeMultipleUsersSuccessCallback,
        ]
    );

    const onExportClickedSingle = useCallback(
        (userIds: any[]) => {
            if (profile?.companyId && userIds && getUsersReportService) {
                getUsersReportService(searchParams, profile.companyId, userIds);
            }
        },
        [profile?.companyId, getUsersReportService, searchParams]
    );

    const onExportClicked = useCallback(
        (userIds: any[]) => {
            if (profile?.companyId && userIds && getUsersReportService) {
                getUsersReportService(
                    searchParams,
                    profile.companyId,
                    isSelectAll ? undefined : userIds,
                    isSelectAll ? users.count : undefined
                );
            }
        },
        [
            profile?.companyId,
            getUsersReportService,
            searchParams,
            isSelectAll,
            users,
        ]
    );

    const isOptionsDisabled =
        !selectedUsers || (selectedUsers && !selectedUsers.length);

    const orderBy = searchParams.orderBy;
    const hcUserName = useOrderBy('name', orderBy, setOrderBy);
    const hcRole = useOrderBy('role_name', orderBy, setOrderBy);
    const hcTeam = useOrderBy('count_teams', orderBy, setOrderBy);
    const hcAssigned = useOrderBy('count_psets', orderBy, setOrderBy);
    const hcLastActivity = useOrderBy('date_login', orderBy, setOrderBy);
    const hcRegisteredAt = useOrderBy('date_registered', orderBy, setOrderBy);

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const filterRoleHandler = (roleIds: number[]) => {
        setCustom('roleIDs', roleIds);
    };

    const teamsChangeHandler = (ids: number[]) => {
        setCustom('teamIds', ids);
    };

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countAppliedFiltersCount(searchParams),
        itemsCount: users?.items.length || 0,
    });

    const deselectTeams = useDeselectAllTeams();
    const saveTeams = useSaveTeams();

    /**
     * @function onDeleteUsersSuccessCallback
     * @returns { void }
     */
    const onDeleteUsersSuccessCallback = useCallback(() => {
        if (!pageIndex || pageIndex === 1) {
            refreshData();
        } else {
            // if the current page is not page 1,
            // move paging to page 1
            onTriggerEvent(EventNameList[CLEAR_PAGING]);
        }
    }, [pageIndex, refreshData]);

    /**
     * @function onConfirmDeleteUser
     * @param { number[] } userIds
     * @returns { void }
     */
    const onConfirmDeleteUser = useCallback(
        (userIds: number[]): void => {
            const selectAllState = userIds.length === 1 ? false : isSelectAll;
            onDeleteUser(
                userIds,
                isSelectAll
                    ? normalizeSP(searchParams, users && users.count)
                    : searchParams,
                selectAllState,
                onDeleteUsersSuccessCallback
            );
        },
        [
            isSelectAll,
            onDeleteUser,
            onDeleteUsersSuccessCallback,
            searchParams,
            users,
        ]
    );

    const handleOpenDeleteUsersConfirmDialog = useCallback(
        (userIds: number[]) => {
            const userText = userIds.length === 1 ? 'this user' : 'these users';
            showConfirmationModalDialog({
                modalTitle: `Deactivate ${userText}?`,
                description:
                    `This action will prevent ${userText} from accessing the platform, but it will not delete their data. To re-activate ${userText} you will need to contact Practis Support.`,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Deactivate',
                dialogType: 'DANGER',
                onConfirm: () => onConfirmDeleteUser(userIds),
            });
        },
        [onConfirmDeleteUser, showConfirmationModalDialog]
    );

    /**
     * @function handleDeleteUsers
     * @returns { void }
     */
    const handleDeleteUsers = (ids: number[]): void => {
        isSelectAll
            ? handleDeleteUserBulkActionService()
            : handleOpenDeleteUsersConfirmDialog(ids);
    };

    useSessionEffect(() => {
        if (!profile?.companyId) return;
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchUsers(searchParams, [UserStatus.ACTIVE], profile.companyId);
    }, [searchUsers, searchParams, profile?.companyId]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        return () =>
            updateAllSelectedUsers([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedUsers([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, history.location.pathname]);

    useEffect(() => {
        if (selectedUsers?.length && selectedUsers.length === users.count) {
            setIsSelectAll(true);
        }
    }, [users.count, selectedUsers]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
    }, [initialOffset, pageIndex, setOffset]);

    useEffect(() => {
        const filteredUsers = users
            ? users.items.map(user => Number(user.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedUsers,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllSelectedUsers(usersToClear, false);
        }
    }, [selectedUsers, users, updateAllSelectedUsers]);

    /**
     * @function onOpenAssignLabelAction
     * @returns { void }
     */
    const onOpenAssignLabelAction = (): void => {
        if (users && users.items && selectedUsers) {
            getAssignedLabelsIds(
                selectedUsers,
                LOAD_ASSIGNED_LABELS.USERS_ACTIVE,
                ASSIGN_LABEL_OPTIONS.USER,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                const { preAssignedLabels } = calculatePreSelectedLabels(
                    selectedUsers,
                    users,
                    data
                );

                setAssignLabelsAction(preAssignedLabels);
            });
        }
    };

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_USER]);
    }, [canAccessPage]);

    useEffect(() => {
        return () => {
            deselectTeams();
            saveTeams();
        };
    }, [deselectTeams, saveTeams]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllUsersCheck(event.target.checked);
        setIsSelectAll(false);
    };

    /**
     * @function checkIsRowChecked
     * @param { UserV2 } user
     * @returns { boolean }
     */
    const checkIsRowChecked = (user: UserV2): boolean =>
        !!selectedUsers && !!user.id && selectedUsers.includes(user.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { UserV2 } row
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, user: UserV2) => {
        setIsSelectAll(false);
        handleUserCheck(event, user);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { UserV2 } row
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (row: UserV2): boolean => {
        return labelsOpen === row?.['id'];
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { UserV2 } user
     * @returns { void }
     */
    const onDeleteLabelHandler = (labelId: number, user: UserV2): void => {
        deleteUserLabel(user.id, labelId, refreshData);
    };

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToUser,
        deleteLabelsFromUser,
        refreshData
    );

    /**
     * @function handleUpdateUserLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { number } userId
     * @returns { void }
     */
    const handleUpdateUserLabels = (
        addedLabelIds: number[],
        deletedLabelIds: number[],
        userId: number
    ): void => {
        updateLabelsBulkActionService(
            {
                entityName: 'user',
                entityId: userId,
            },
            addedLabelIds,
            deletedLabelIds
        );
    };

    return (
        <MainWrapper
            subTitle="Users"
            subHeader={profile?.companyId &&
                <CompanyUserStatsText
                    companyId={profile.companyId}
                    showSettingsButton={isAdminRole(profile?.role?.name)}
                />
            }
            tabs={<UsersPageNavigationContainer />}
            htmlPageTitle="Users - Practis"
            dataTest="users"
        >
            {!!showNudgeDialogMemberId && (
                <DialogWrapper
                    customization={{
                        width: 480,
                        padding: '49px 32px 32px 32px',
                    }}
                >
                    <NudgeUser
                        fromUser={
                            !!profile
                                ? `${profile.firstName} ${profile.lastName}`
                                : ''
                        }
                        onSend={handleNudgeSingleUser}
                        onClose={() => setShowNudgeDialogMemberId(null)}
                    />
                </DialogWrapper>
            )}
            {loading && !isSelectAll && <LoadingComponent />}

            <TableWrapper
                tableStates={tableStates}
                cornered={selectedLabels && !!selectedLabels.length}
                data={users?.items}
                selectedLabels={selectedLabels}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'users-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: users.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'users-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                    },
                    isSelectedItemOptionsVisible:
                        !isLodashEmpty(selectedUsers) && !isLodashEmpty(users),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedUsers?.length,
                        totalCount: users.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: () => {
                            updateAllSelectedUsers(
                                [],
                                true,
                                isCheckboxPartiallySelected
                            );
                            setIsSelectAll(false);
                        },
                        onSelectAll: () => {
                            updateAllUsersCheck(true);
                            setIsSelectAll(true);
                        },
                        bulkActionsConfig: {
                            disabled: isOptionsDisabled,
                            onAssignLabelsSubmit: assignLabels,
                            assignPractisSetsAndDueDatePermissions: [
                                NEW_PERMISSIONS.ASSIGN_ENROLLMENT,
                            ],
                            assignLabelsPermissions: [
                                NEW_PERMISSIONS.ASSIGN_USER_LABEL,
                            ],
                            onOpenAssignLabelAction: onOpenAssignLabelAction,
                            sendNudgePermissions: [NEW_PERMISSIONS.SEND_NUDGE],
                            showNudgeWarning: false,
                            nudgeButtonDisabled: isOptionsDisabled,
                            onNudgeSendButtonClicked: handleNudgeMultipleUsers,
                            showExportPermissions: [
                                NEW_PERMISSIONS.EXPORT_LIST_USER,
                            ],
                            exportButtonDisabled: isOptionsDisabled,
                            onExportClicked: () =>
                                onExportClicked(
                                    selectedUsers && selectedUsers.length
                                        ? selectedUsers
                                        : []
                                ),
                            removeItemPermissions: [
                                NEW_PERMISSIONS.DELETE_USER,
                            ],
                            removeItemTitle: 'Deactivate Users',
                            onRemoveItemsSubmit: () =>
                                handleDeleteUsers(
                                    selectedUsers && selectedUsers.length
                                        ? selectedUsers
                                        : []
                                ),
                            isSelectAll: isSelectAll,
                            totalCount: users.count,
                            searchQuery: {
                                query: !isLodashEmpty(searchParams?.searchTerm)
                                    ? searchParams.searchTerm
                                    : null,

                                labelId: !isLodashEmpty(searchParams?.labelIDs)
                                    ? searchParams.labelIDs
                                    : null,
                                roleId: !isLodashEmpty(searchParams?.roleIDs)
                                    ? searchParams.roleIDs
                                    : null,
                                status: !isLodashEmpty(
                                    searchParams?.filterByRegistrationStatus
                                )
                                    ? searchParams.filterByRegistrationStatus
                                    : null,
                            },
                            selectedItems: selectedUsers,
                            successApplyPractisSetsCallback: refreshData,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <UsersFilter
                                        preSelectedLabels={selectedLabels || []}
                                        onRoleChange={filterRoleHandler}
                                        preSelectedRoles={
                                            searchParams.roleIDs
                                                ? searchParams.roleIDs
                                                : []
                                        }
                                        onTeamsChange={teamsChangeHandler}
                                        onLabelsChange={labelChangeHandler}
                                        onSuccessApply={() => hide()}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={countAppliedFiltersCount(
                                            searchParams
                                        )}
                                        disabled={
                                            countAppliedFiltersCount(
                                                searchParams
                                            ) === 0 &&
                                            tableStates.disableFilters
                                        }
                                        dataTest="users-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && users?.items?.length === 0,
                    noEntriesOptions: {
                        icon: User,
                        text: 'No Users Yet',
                        dataTest: 'no-results',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Users',
                        dataTest: 'no-found-results',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'no-filtered-results',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: !!selectedUsers && !!selectedUsers.length,
                        disabled: !users || (users && users.items.length < 1),
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'master-checkbox',
                    },

                    columns: [
                        {
                            title: 'Users',
                            width: 22,
                            ...hcUserName,
                            disabled: tableStates.disableSorting,
                            className: classes.customTableColumnStyle,
                            dataTest: 'name-column',
                        },
                        {
                            title: 'Teams',
                            width: 9,
                            ...hcTeam,
                            disabled: tableStates.disableSorting,
                            dataTest: 'teams-column',
                        },
                        {
                            title: 'Practis Sets',
                            width: 9,
                            ...hcAssigned,
                            disabled: tableStates.disableSorting,
                            dataTest: 'practis-sets-column',
                        },
                        {
                            title: 'Role',
                            width: 9,
                            ...hcRole,
                            disabled: tableStates.disableSorting,
                            dataTest: 'role-column',
                        },
                        {
                            title: 'Registered on',
                            width: 9,
                            ...hcRegisteredAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'registration-date-column',
                        },
                        {
                            title: 'Last Login',
                            width: 9,
                            ...hcLastActivity,
                            disabled: tableStates.disableSorting,
                            dataTest: 'login-date-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],

                    rowConfig: {
                        onRowClick: handleViewProfile,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (user: UserV2) => ({
                            selectedLabels: user.labels,
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_USER_LABEL,
                            ],

                            onDeleteLabel: (labelId: number) =>
                                onDeleteLabelHandler(labelId, user),
                        }),
                        dataTest: 'user-item',
                        cells: [
                            {
                                fieldType: 'AVATAR_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                fieldProps: {
                                    renderTitle: (user: UserV2) =>
                                        buildUserName(user),
                                    getAvatarProps: (user: UserV2) => ({
                                        customProfile: {
                                            firstName: user.firstName,
                                            lastName: user.lastName,
                                            picture: user.imageUrl,
                                            status: user.status,
                                        },
                                        size: 'sm',
                                    }),
                                    dataTest: 'user'
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                cellClassName: classes.teamsTableCellStyle,
                                getCustomFieldComponent: (user: UserV2) => (
                                    <>
                                        {loggedInUserPermissions.hasActionPermission(
                                            NEW_PERMISSIONS.UPDATE_USER
                                        ) ? (
                                            isPractisAdminRole(
                                                user.role?.name
                                            ) ? (
                                                <EmptyCellDash />
                                            ) : (
                                                <SelectedUserTeamsComponent
                                                    noText={true}
                                                    teamsCount={
                                                        user.countTeams || 0
                                                    }
                                                    selectedTeams={
                                                        user.teams ?? []
                                                    }
                                                    onSelectTeams={(
                                                        teamIds: number[]
                                                    ) =>
                                                        handleManageTeams(
                                                            user,
                                                            teamIds
                                                        )
                                                    }
                                                />
                                            )
                                        ) : (
                                            loggedInUserPermissions.isTeamLeader() && (
                                                <span>{user.countTeams} </span>
                                            )
                                        )}
                                    </>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (user: UserV2) =>
                                    !user.countEnrollments,
                                fieldProps: {
                                    renderTitle: (user: UserV2) =>
                                        user.countEnrollments.toString(),
                                    dataTest: 'practis-sets-count',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (user: UserV2) =>
                                    !user.role,
                                fieldProps: {
                                    renderTitle: (user: UserV2) =>
                                        user.role?.title,
                                    dataTest: 'user-role'
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (user: UserV2) =>
                                    !user.registeredAt,
                                fieldProps: {
                                    renderTitle: (user: UserV2) =>
                                        formatDate(user.registeredAt),
                                    dataTest: 'registation-date',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (user: UserV2) =>
                                    !user.lastActivity,
                                fieldProps: {
                                    renderTitle: (user: UserV2) =>
                                        formatDate(user.lastActivity),
                                    dataTest: 'last-activity-date',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (user: UserV2) => {
                                        return {
                                            open: labelsOpen === user?.id,
                                            toggleOpen: () =>
                                                handleToggleLabels(user?.id),
                                            selectedLabels: user.labels,
                                            dataTest: 'user-labels',
                                        };
                                    },
                                },
                            },

                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (user: UserV2) => (
                                        <UsersListActions
                                            user={user}
                                            handleViewProfile={
                                                handleViewProfile
                                            }
                                            handleUserSettings={
                                                handleUserSettings
                                            }
                                            handleItemEdit={handleItemEdit}
                                            setShowNudgeDialogMemberId={
                                                setShowNudgeDialogMemberId
                                            }
                                            onExportClicked={
                                                onExportClickedSingle
                                            }
                                            setUserIdsToDelete={
                                                handleDeleteUsers
                                            }
                                            assignLabelsToUser={(
                                                addedLabelIds,
                                                deletedLabelIds
                                            ) =>
                                                handleUpdateUserLabels(
                                                    addedLabelIds,
                                                    deletedLabelIds,
                                                    user.id
                                                )
                                            }
                                            isHidden={!!selectedUsers?.length}
                                            successApplyPractisSetsCallback={
                                                refreshData
                                            }
                                        />
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const UsersContainer: FC = () => {
    const state = useUsersState();
    const history = useHistory();
    const searchUsers = useSearchUsersService();
    const deleteUser = useDeleteUserService();
    const profile = useSelector(getProfileState);

    const labels = useLabelsState();
    const assignTeamsToUsers = useAssignTeamsToUsersService();
    const deleteUserLabel = useDeleteUserLabelService();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const updateAllSelectedUsers = useUpdateAllSelectedUsersStateService();
    const updateSelectedUser = useUpdateSelectedUserStateService();
    const updatedLabels = useUpdatedLabelsState();
    const getUsersReportService = useGetUsersReportService();
    const nudgeTrainees = useNudgeTraineesService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const searchState = useSelector(getSearchState);

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'users' }}>
            <WithTeamsContext.Provider value={{ reducerName: 'users' }}>
                <WithPractisSetsContext.Provider
                    value={{
                        reducerName: 'performance',
                    }}
                >
                    <Users
                        history={history}
                        searchUsers={searchUsers}
                        onDeleteUser={deleteUser}
                        profile={profile}
                        users={state.list}
                        selectedUsers={state.selectedUsers}
                        loading={state.loading}
                        selectedLabels={labels.selected}
                        assignTeamsToUsers={assignTeamsToUsers}
                        setAssignLabelsAction={setAssignLabelsAction}
                        assignedLabels={labels.assignedLabels}
                        deleteUserLabel={deleteUserLabel}
                        updateAllSelectedUsers={updateAllSelectedUsers}
                        updateSelectedUser={updateSelectedUser}
                        updatedLabels={updatedLabels}
                        getUsersReportService={getUsersReportService}
                        onNudgeTrainees={nudgeTrainees}
                        saveGlobalLabelFilters={saveGlobalLabelFilters}
                        searchState={searchState}
                    />
                </WithPractisSetsContext.Provider>
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};



export default UsersContainer;