import { useCallback } from "react";
import { useRemoveQueryParam, useSetQueryParam } from "../../../../helpers/hooks/useSetQueryParam";
import dayjs from "dayjs";
import { DATE_RANGE_PARAM_NAME } from "./constants";
import { useDateRangeState } from "./store/reducers";
import { useResetDateRange, useSelectDateRange } from "./store/services";
import useQueryParamListener from "../../../../helpers/hooks/useQueryParamListener";
import DateFilterView from "./view";
import { DateRangeType } from "./types";
import { isEqual } from "lodash";

function DateRangeFilter() {
    const { dateRange } = useDateRangeState();
    const selectDateRange = useSelectDateRange();
    const resetReduxDateRange = useResetDateRange();
    const setQueryParam = useSetQueryParam();
    const resetDateRange = useRemoveQueryParam();

    const handleSelectDate = useCallback((date: DateRangeType) => {
        setQueryParam(
            DATE_RANGE_PARAM_NAME,
            `${dayjs(date.start!).format('YYYY-MM-DD')},${dayjs(date.end!).format('YYYY-MM-DD')}`,
        );
        selectDateRange(date);
    }, [selectDateRange, setQueryParam]);

    const resetDate = useCallback(() => {
        resetReduxDateRange();
        resetDateRange(DATE_RANGE_PARAM_NAME);
    }, [resetReduxDateRange, resetDateRange]);

    const isDisabled = !(dateRange?.start || dateRange?.end);
    useQueryParamListener(DATE_RANGE_PARAM_NAME, value => {
        if (value) {
            const [start, end] = value.split(',');
            const startDate = dayjs(start).toDate();
            const endDate = dayjs(end).endOf('d').toDate();
            if (!isEqual(startDate, dateRange?.start) || !isEqual(endDate, dateRange?.end)) {
                selectDateRange({ start: startDate, end: endDate });
            }
        }
    })

    return (
        <DateFilterView dateRange={dateRange} resetDate={resetDate} handleSelectDate={handleSelectDate} isDisabled={isDisabled} />
    );
}

export default DateRangeFilter;