import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../theme/variables';

const Root = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    @media (max-width: ${Variables.BreakPoints.responsiveSmPlus}px) {
        margin-top: 8px;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

export const TableToolsRight: FC<{
    className?: string;
}> = memo(({ children, className }) => (
    <Root className={className}>{children}</Root>
));
