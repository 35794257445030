import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import Checkbox from '../../../ui/components/Checkbox';
import { TeamBasic } from '../../../constants/interfaces/TeamBasic';
import { handleToggleSelectedItems } from '../../../pages/ActionPages/NewUser/tools';
import { Tooltip } from '../../../ui/components/Tooltip';
import Star from '../../../ui/icons/Star';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';

const StyledTeamsList = styled.div`
    overflow: auto;
    height: 100%;
`;

const TopSideList = styled.span``;

const GeneralList = styled.span``;

const TeamsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;

const DefaultTeamIconHolderWrapper = styled.div`
    width: 12px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    background-color: ${props => props.theme.Colors.macaroniAndCheese};
`;

const DefaultTeamIconHolder = styled.div`
    width: 10px;
    height: 12px;
    display: block;
    color: ${props => props.theme.Colors.white};
`;

const TeamsItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    cursor: ${props => props.disabled ? `default` : `pointer`};
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;
export const PortableTeamsList: FC<{
    teams?: TeamBasic[];
    selectedTeams: number[];
    disabled?: boolean;
    showDefaultMembers?: boolean
    setTeamFilters?: (teamIds: number[]) => void;
}> = ({ teams, selectedTeams, showDefaultMembers, setTeamFilters  }) => {
    const isChecked = useCallback(
        (setId?: number) => {
            return !!selectedTeams && !!setId && selectedTeams.includes(setId);
        },
        [selectedTeams]
    );

    const isPartiallyChecked = useCallback(
        (teamId?: number) => {
            return (
                !!selectedTeams &&
                !!teamId &&
                selectedTeams.includes(-Number(teamId))
            );
        },
        [selectedTeams]
    );

    const changeHandler = (teamId?: number) => {
        if (teamId && setTeamFilters) {
            setTeamFilters(handleToggleSelectedItems(selectedTeams, teamId));
        }
    };

    if (!teams || (teams && teams.length === 0)) return null;

    const topListTeams = teams.filter(
        team => team.isDefault || (team.id && selectedTeams.map(id => Math.abs(id)).includes(team.id))
    );

    const bottomListTeams = teams.filter(
        team => !team.isDefault && team.id && !selectedTeams.map(id => Math.abs(id)).includes(team.id)
    );

    const processedTopListTeams = showDefaultMembers ? topListTeams.filter(team => !team.isDefault) : topListTeams;

    return (
        <StyledTeamsList>
            {topListTeams.length > 0 && (
                <TopSideList>
                    {showDefaultMembers &&
                        topListTeams
                            .filter(team => team.isDefault)
                            .map(team => (
                                <TeamsItemWrapper
                                    key={team.id}
                                    data-test="all-members-container"
                                >
                                    <Tooltip
                                        label="This team cannot be modified"
                                        preset="checkbox-icon"
                                    >
                                        <DefaultTeamIconHolderWrapper data-test="all-members-icon">
                                            <DefaultTeamIconHolder>
                                                <Star viewBox="-1 1 10 12" />
                                            </DefaultTeamIconHolder>
                                        </DefaultTeamIconHolderWrapper>
                                    </Tooltip>
                                    <TeamsItem
                                        disabled
                                        data-test="all-members-item"
                                    >
                                        {team.name}
                                    </TeamsItem>
                                </TeamsItemWrapper>
                            ))}
                    {processedTopListTeams.map(team => {
                        return (
                            <TeamsItemWrapper
                                key={team.id}
                                data-test="team-item-container"
                            >
                                <Checkbox
                                    size={12}
                                    checked={
                                        isChecked(team.id) ||
                                        isPartiallyChecked(team.id)
                                    }
                                    partial={isPartiallyChecked(team.id)}
                                    handleChange={() => changeHandler(team.id)}
                                    dataTest="team-item-checkbox"
                                />
                                <TeamsItem
                                    onClick={() => changeHandler(team.id)}
                                    data-test="team-item-title"
                                >
                                    {team.name}
                                </TeamsItem>
                            </TeamsItemWrapper>
                        );
                    })}
                    {topListTeams.length < teams.length && <TableDivider width="98%" />}
                </TopSideList>
            )}
            <GeneralList>
                {bottomListTeams.map(team => {
                    return (
                        <TeamsItemWrapper
                            key={team.id}
                            data-test="team-item-container"
                        >
                            <Checkbox
                                size={12}
                                checked={
                                    isChecked(team.id) ||
                                    isPartiallyChecked(team.id)
                                }
                                partial={isPartiallyChecked(team.id)}
                                handleChange={() => changeHandler(team.id)}
                                dataTest="team-item-checkbox"
                            />
                            <TeamsItem
                                onClick={() => changeHandler(team.id)}
                                data-test="team-item-title"
                            >
                                {team.name}
                            </TeamsItem>
                        </TeamsItemWrapper>
                    );
                })}
            </GeneralList>
        </StyledTeamsList>
    );
};