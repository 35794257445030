import styled from 'styled-components';

export const StyledUserArea = styled.div<{ open: boolean }>`
    display: flex;
    border-radius: 8px;
    margin: 0 24px;
    width: 200px;
    ${props => props.open && `background: var(--ps-graphite-2)`};
    padding: 8px;
    box-sizing: border-box;
`;

export const UserInfo = styled.div`
    width: 150px;
`;

export const UserRole = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: var(--ps-grey-1);
`;

export const UserName = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: var(--ps-white-1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const UserProfilePictureSection = styled.div`
    height: 32px;
    width: 32px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 12px;
`;

export const UserRoleLoading = styled.div`
    width: 66px;
    height: 8px;
    background-color: var(--ps-grey-1);
    margin-top: 12px;
    margin-left: 2px;
    margin-bottom: 4px;
`;

export const UserNameLoading = styled.div`
    width: 76px;
    height: 8px;
    background-color: var(--ps-white-1);
    margin-top: 4px;
    margin-left: 2px;
`;