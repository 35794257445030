import { Popup } from '../../../../ui/components/Popup';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import OverdueLearnersNoResult from '../../../../ui/icons/OverdueLearnersNoResult';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { useBuildUserName } from '../../../users/tools/hooks';
import { TeamsLearnersNavigation } from '../../components/TeamsPageNavigation';
import { useStyles } from '../Training/styles';
import { OverdueLearnersFilter } from './components/filters/OverdueLearnersFilter';
import { ITEMS_PER_PAGE } from './constants';
import { OverdueLearnersViewProps } from './types';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { countAppliedFiltersCount } from './tools';
import { NEW_PERMISSIONS } from '../../../users/tools';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';
import { NudgeUser } from '../../../../ui/components/table-wrapper/table/TableAssignOptionsMenu/components/NudgeUser';
import { OverdueLearnersListActions } from './components/listActions/OverdueListActions';
import { isEmpty as lodashIsEmpty } from 'lodash';
import { TeamBasic } from '../../../../constants/interfaces/TeamBasic';
import { UserV2 } from '../../../../constants/interfaces/User';
import { EmptyCellDash } from '../../../../ui/components/table-wrapper/table/EmptyCellDash';

function OverdueLearnersView({
        profile,
        users,
        searchParams,
        loading,
        tableStates,
        lastRefreshed,
        selectedLabels,
        showNudgeDialogMemberId,
        selectedUsers,
        sorting,
        isSelectAll,
        teamsData,
        labelsOpen,
        handleToggleLabels,
        checkIsLabelTagsOpen,
        refreshData,
        handlePageChange,
        handleSearchChange,
        handleViewProfile,
        onLabelsFilterChange,
        onTeamsFilterChange,
        onDeleteLabelHandler,
        onNudgeSingleUser,
        setShowNudgeDialogMemberId,
        updateIsSelectAll,
        updateSelectedUsers,
        selectAllHandler,
        onSelectUser,
        handleNudgeMultipleUsers,
}: OverdueLearnersViewProps) {

    const classes = useStyles();
    const buildUserName = useBuildUserName();

    const isTableHeadersDisabled =
        !users || (users && !users.items.length);

    return (
        <MainWrapper
                tabs={<TeamsLearnersNavigation />}
                htmlPageTitle={`Overdue Learners`}
                dataTest="overdue-learners"
                subTitle={'Teams'}
            >
                {!!showNudgeDialogMemberId && (
                    <DialogWrapper
                        customization={{
                            width: 480,
                            padding: '49px 32px 32px 32px',
                        }}
                    >
                        <NudgeUser
                            fromUser={
                                !!profile
                                    ? `${profile.firstName} ${profile.lastName}`
                                    : ''
                            }
                            onSend={onNudgeSingleUser}
                            onClose={() => setShowNudgeDialogMemberId(null)}
                        />
                    </DialogWrapper>
                )}
                <TableWrapper
                    tableStates={{...tableStates}}
                    data={users?.items || []}
                    selectedLabels={selectedLabels}
                    cornered={selectedLabels && !!selectedLabels.length}
                    tableRefreshConfigurations={{
                        lastRefreshed: lastRefreshed,
                        refreshData: refreshData,
                        dataTest: 'overdue-learners-timestamp',
                    }}
                    tableToolsOptions={{
                        pagingOptions: {
                            totalCount: users?.count,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onPageChange: handlePageChange,
                            searchOrFiltersApplied: false,
                            dataTest: 'overdue-learners-paging',
                        },
                        searchInputOptions: {
                            initialValue: searchParams.searchTerm,
                            isSearchInputDisabled: tableStates.disableSearch,
                            onSearchChange: handleSearchChange,
                            placeholder: "",
                            dataTest: 'overdue-learners-search',
                        },
                        isSelectedItemOptionsVisible:
                            !lodashIsEmpty(selectedUsers) &&
                            !lodashIsEmpty(users),
                        selectedItemOptions: {
                            isSelectAll: isSelectAll,
                            selectedLength: selectedUsers?.length,
                            totalCount: users?.count,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onClearSelection: () => {
                                updateIsSelectAll(false);
                                updateSelectedUsers([]);
                            },
                            onSelectAll: () => {
                                updateSelectedUsers(users ? users.items.map(item => item.id) : []);
                                updateIsSelectAll(true);
                            },
                            bulkActionsConfig: {
                                disabled: selectedUsers.length === 0,
                                showNudgeWarning: false,
                                onNudgeSendButtonClicked:
                                    handleNudgeMultipleUsers,   
                                sendNudgePermissions: [
                                    NEW_PERMISSIONS.SEND_NUDGE,
                                ],
                                isSelectAll: isSelectAll,
                                totalCount: users?.count,
                                selectedItems: selectedUsers,
                            },
                        },
                        filterOptions: {
                            filterComponent: (
                                <Popup<HTMLButtonElement>
                                    content={({ hide }) => (
                                        <OverdueLearnersFilter
                                            preSelectedLabels={selectedLabels || []}
                                            onTeamsChange={onTeamsFilterChange}
                                            onLabelsChange={onLabelsFilterChange}
                                            onSuccessApply={() => hide()}
                                        />
                                    )}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    horizontalOffset={256}
                                >
                                    {(ref, { toggleShown, shown }) => (
                                        <FilterByHandler
                                            ref={ref}
                                            open={shown}
                                            toggleOpen={toggleShown}
                                            filtersCount={countAppliedFiltersCount(
                                                searchParams
                                            )}
                                            disabled={
                                                countAppliedFiltersCount(
                                                    searchParams
                                                ) === 0 &&
                                                tableStates.disableFilters
                                            }
                                            dataTest="overdue-learners-filters"
                                        />
                                    )}
                                </Popup>
                            ),
                        },
                    }}
                    tableEmptyStateConfigurations={{
                        shouldShowEmptyState:
                            !loading && users?.items?.length === 0,
                        noEntriesOptions: {
                            icon: OverdueLearnersNoResult,
                            text: 'No one is behind',
                            dataTest: 'no-results',
                        },
                        noSearchResultsOptions: {
                            entityName: 'Learners',
                            dataTest: 'no-found-users',
                        },
                        noFilterResultsOptions: {
                            dataTest: 'no-filtered-users',
                        },
                    }}
                    configurations={{
                        masterCheckBoxConfig: {
                            checked: !!selectedUsers.length,
                            disabled: !users?.items.length,
                            partial:
                                (selectedUsers && users && selectedUsers.length < users.items.length) || !isSelectAll,
                            handleCheck: selectAllHandler,
                            dataTest: "members-master-checkbox"
                        },
                        columns: [
                            {
                                title: 'Name',
                                width: 35,
                                disabled: tableStates.disableSorting,
                                ...(isTableHeadersDisabled
                                    ? null
                                    : sorting.hcUserName),
                                dataTest: 'name-column',
                            },
                            {
                                title: 'Teams',
                                width: 35,
                                ...(isTableHeadersDisabled
                                    ? null
                                    : sorting.hcTeamName),
                                disabled: tableStates.disableSorting,
                                dataTest: 'teams-column',
                            },
                            {
                                width: 1,
                            },
                            {
                                width: 1,
                            },
                        ],
                        rowConfig: {
                            onRowClick: handleViewProfile,
                            isLabelTagsOpen: checkIsLabelTagsOpen,
                            onRowCheckHandler: (event: any, user: UserV2) => onSelectUser(user.id, event?.target?.checked),
                            isRowChecked: (user) => (selectedUsers.includes(user.id) || isSelectAll),
                            getLabelTagsProps: (user: UserV2) => ({
                                selectedLabels: user.labels,
                                deletePermissions: [
                                    NEW_PERMISSIONS.REMOVE_USER_LABEL,
                                ],
    
                                onDeleteLabel: (labelId: number) =>
                                    onDeleteLabelHandler(labelId, user),
                            }),
                            cells: [
                                {
                                    fieldType: 'AVATAR_FIELD',
                                    cellClassName: classes.customTableCellStyle,
                                    fieldProps: {
                                        renderTitle: (user: UserV2) =>
                                            buildUserName(user),
                                        getAvatarProps: (user: UserV2) => ({
                                            customProfile: {
                                                firstName: user.firstName,
                                                lastName: user.lastName,
                                                picture: user.imageUrl,
                                                status: user.status,
                                            },
                                            size: 'sm',
                                        }),
                                        dataTest: 'overdue-users-user-item',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (user: UserV2) =>
                                        !user.teams,
                                    fieldProps: {
                                        renderTitle: (user: UserV2) => {
                                            const existingTeams = user.teams?.reduce((teams: string[], teamId: number) => {
                                                const team = teamsData.items.find((team: TeamBasic) => team.id === teamId)
                                                return team ? [...teams, team.name] : teams
                                            }, []);

                                           return existingTeams?.length ? existingTeams.join(', ') : <EmptyCellDash />;
                                        },
                                        dataTest: 'overdue-users-teams-item',
                                    },
                                },
                                {
                                    fieldType: 'LABEL_TAGS',
                                    fieldProps: {
                                        getLabelTagsProps: (user: UserV2) => {
                                            return {
                                                open: labelsOpen === user?.id,
                                                toggleOpen: () =>
                                                    handleToggleLabels(user?.id),
                                                selectedLabels: user.labels,
                                                dataTest: 'overdue-learners-labels',
                                            };
                                        },
                                    },
                                },
                                {
                                    fieldType: 'LIST_ACTIONS',
                                    fieldProps: {
                                        getListActionsComponent: (user: UserV2) => (
                                            <OverdueLearnersListActions
                                                user={user}
                                                handleViewProfile={
                                                    handleViewProfile
                                                }
                                                setShowNudgeDialogMemberId={
                                                    setShowNudgeDialogMemberId
                                                }
                                                isHidden={!!selectedUsers?.length}
                                            />
                                        ),
                                    },
                                },
                            ],
                        },
                    }}
            />
        </MainWrapper>
    );
}

export default OverdueLearnersView;