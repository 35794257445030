import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { motion } from 'framer-motion';

export const useStyles = makeStyles(theme => ({
    portablePractisSetContainer: {
        height: '350px !important',
        width: '450px !important',
    },

    selectAllWrapper: {
        paddingRight: '170px !important',
    },
}));

export const HeaderTitle = styled.div`
    width: 336px;
    height: 27px;
    margin: 14px 0;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.Colors.black};
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

export const ButtonContainer = styled.div`
    margin-right: 16px;
`;

export const Divider = styled.hr<{ margin?: string }>`
    width: 100%;
    height: 1px;
    border: none;
    flex-shrink: 0;
    background-color: ${props => props.theme.Colors.paleGrey};
    margin: ${props => (!!props.margin ? props.margin : '5px 0')};
`;

export const PractisSetsContainer = styled(motion.div)<{
    displayOver?: boolean;
    topMargin: number;
    left?: string;
}>`
    padding: 15px 30px;
    position: absolute;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    left: ${props =>
        props.displayOver ? '16px' : !!props.left ? props.left : '0'};
    top: ${props => (props.displayOver ? `-${props.topMargin}px` : '8px')};
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
`;
