import { plural } from '../../../../helpers/functions/plural';

import {
    Wrapper,
    ClearWrapper,
    SelectAllWrapper,
    SelectedWrapper,
} from './styles';
import { PopupProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SelectAll = <T extends HTMLElement = HTMLElement>({
    totalCount,
    selectedLength,
    itemsPerPage,
    onClearSelecion,
    onSelectAll,
    isSelectAll,
    children,
    style,
    hasAllMembers,
    dataTest
}: PopupProps) => {
    if (!selectedLength) {
        return null;
    }

    const roundToTotal = (count: any) => {
        if (count > totalCount!) {
            return totalCount;
        } else {
            return count;
        }
    };

    return (
        <Wrapper style={style}>
            {children}
            <SelectedWrapper>
                {(isSelectAll || selectedLength === totalCount) &&
                    totalCount! > 1 &&
                    'All'}
                &nbsp;
                <span data-test={dataTest && `${dataTest}-selected-counter`}>
                    {roundToTotal(
                        isSelectAll
                            ? totalCount! < itemsPerPage
                                ? !hasAllMembers
                                    ? totalCount
                                    : totalCount! - 1
                                : selectedLength < itemsPerPage
                                ? totalCount
                                : totalCount! - itemsPerPage + selectedLength
                            : selectedLength
                    )}
                    &nbsp;
                    {isSelectAll
                        ? totalCount && plural(totalCount, 'Item', 'Items')
                        : plural(selectedLength, 'Item', 'Items')}
                </span>{' '}
                selected.
            </SelectedWrapper>
            {!isSelectAll && selectedLength !== totalCount && (
                <SelectAllWrapper onClick={onSelectAll} data-test={dataTest && `${dataTest}-select-all`}>
                    Select all{' '}
                    <span data-test={dataTest && `${dataTest}-select-all-counter`}>
                        {!hasAllMembers ? totalCount : totalCount! - 1}{' '}
                        {totalCount && plural(totalCount, 'Item', 'Items')}
                    </span>
                </SelectAllWrapper>
            )}
            <ClearWrapper onClick={onClearSelecion} data-test={dataTest && `${dataTest}-clear-selection`}>
                Clear Selection
            </ClearWrapper>
        </Wrapper>
    );
};
