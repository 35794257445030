import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Label = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                height="100%"
                viewBox="0 0 16 12"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M208 141v-10c0-.552.448-1 1-1h10.5l4.5 6-4.5 6H209c-.552 0-1-.448-1-1zm11 0l3.75-5-3.75-5h-10v10h10zm1.115-5c0 .86-.698 1.558-1.557 1.558-.86 0-1.558-.698-1.558-1.558 0-.86.697-1.558 1.558-1.558.86 0 1.557.698 1.557 1.558zm-2.336 0c0 .43.348.779.779.779.43 0 .779-.349.779-.779 0-.43-.349-.779-.78-.779-.43 0-.778.349-.778.78z"
                            transform="translate(-208 -130)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default Label;
