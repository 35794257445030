import { FC } from 'react';
import { useParams } from 'react-router';

import ROUTES from '../../../../routes/routes';
import Navigation from '../../../../ui/components/Navigation/Navigation';
import { TrainingTutorial } from '../../pages/Training/components/TrainingTutorial';
import { getPageUrl } from '../../../../routes/helpers';
import { Container } from './styles';

export const TeamsPageNavigation: FC<{ teamName?: string }> = ({
    teamName,
}) => {
    const params = useParams<{ teamId?: string }>();
    return (
        <Container>
            <Navigation
                fontSize="13px"
                dataTest="team"
                menu={[
                    {
                        key: 'training',
                        title: 'Training',
                        url: getPageUrl(ROUTES.TEAMS_PAGES.TRAINING, {
                            teamId: params.teamId,
                        }),
                        hint: ({ dismiss }) => (
                            <TrainingTutorial
                                teamName={teamName || ''}
                                onDismiss={dismiss}
                            />
                        ),
                        hintFirstTime: true,
                    },
                    {
                        key: 'members',
                        title: 'Members',
                        url: getPageUrl(ROUTES.TEAMS_PAGES.MEMBERS, {
                            teamId: params.teamId,
                        }),
                    },
                ]}
            />
        </Container>
    );
};
