import { createContext, useContext } from 'react';

export interface NewRelicApi {
    getAppId(): string;
    getLicenseKey(): string;
}
export const NewRelicApiContext = createContext<NewRelicApi>(undefined!);

export function useNewRelicApi() {
    return useContext(NewRelicApiContext);
}
