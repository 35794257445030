import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const AssessmentLogsBig = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#zftislkp8a)">
                    <path d="M45.9 19.12c.39 1.66.6 3.38.6 5.15 0 12.43-10.07 22.5-22.5 22.5S1.5 36.7 1.5 24.28c0-12.42 10.07-22.5 22.5-22.5 1.38 0 2.72.13 4.03.37l-2.7 2.7c-.44-.03-.88-.07-1.33-.07-10.75 0-19.5 8.74-19.5 19.5s8.75 19.5 19.5 19.5 19.5-8.75 19.5-19.5c0-.87-.08-1.72-.19-2.56l2.59-2.59v-.01zm-10.7 4.94-3.15-1.58c.13.58.2 1.17.2 1.79 0 4.55-3.7 8.25-8.25 8.25s-8.25-3.7-8.25-8.25 3.7-8.25 8.25-8.25c.39 0 .76.04 1.13.09l.17-.17-1.45-2.91c-6.14.08-11.1 5.08-11.1 11.24S17.79 35.52 24 35.52s11.25-5.04 11.25-11.25v-.19c-.01 0-.03-.01-.04-.02h-.01zm11.42-9.9-6.66 6.66a2.945 2.945 0 0 1-3.41.56l-5.42-2.71-7 6.94c-.29.29-.68.44-1.06.44s-.77-.15-1.06-.44a1.49 1.49 0 0 1 0-2.12l2.11-2.11 4.17-4.13h-.03l.71-.72-2.73-5.47c-.57-1.14-.35-2.51.55-3.42L33.44.98c.76-.76 1.84-1.11 2.9-.94 1.06.17 1.98.85 2.46 1.81l1.67 3.33.98-.97a1.5 1.5 0 0 1 2.11 2.13l-.92.91 3.11 1.55c.96.48 1.63 1.4 1.81 2.46.17 1.06-.18 2.14-.94 2.9zm-15.44.13 6.99-6.99-2.06-4.12a.362.362 0 0 0-.25-.19h-.06c-.07 0-.16.02-.24.1L28.9 9.75l2.29 4.53-.01.01zm13.41-2.55a.32.32 0 0 0-.19-.25l-4.02-2.01-7.02 6.96 4.49 2.24 6.64-6.64c.1-.1.11-.22.1-.3z" fill="#B1C0CB"/>
                </g>
                <defs>
                    <clipPath id="zftislkp8a">
                        <path fill="#fff" transform="translate(1.5)" d="M0 0h46.1v46.78H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default AssessmentLogsBig;
