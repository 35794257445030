import React, { FC } from 'react';

import styled from 'styled-components';
import AddNewUser from '../../../../ui/icons/AddNewUser';
import NewUserPath from '../../../../ui/icons/NewUserPath';

const IconsContainer = styled.div`
    display: flex;
    z-index: 1;
    padding-left: 24px;
    position: absolute;
    align-items: flex-end;
    top: 70px;
`;

const PathIconHolder = styled.div`
    margin-right: 8px;
    padding-top: 12px;
    color: ${props => props.theme.Colors.darkSkyBlue};
    transform: rotateX(180deg);
`;

const UserIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const UserIconHolder = styled.div`
    height: 24px;
    width: 24px;
    color: ${props => props.theme.Colors.darkSkyBlue};
`;

const AddNewUserLabel = styled.div`
    color: ${props => props.theme.Fonts.manrope};
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.darkSkyBlue};
    margin-left: 7px;
`;

const AddNewUserTip: FC<{dataTest: string}> = ({ dataTest }) => {
    return (
        <IconsContainer>
            <PathIconHolder>
                <NewUserPath />
            </PathIconHolder>
            <UserIconContainer>
                <UserIconHolder>
                    <AddNewUser />
                </UserIconHolder>
                <AddNewUserLabel data-test={dataTest}>
                    Add users to the table in order to edit or invite them
                </AddNewUserLabel>
            </UserIconContainer>
        </IconsContainer>
    );
};

export default AddNewUserTip;
