import React, {
    cloneElement,
    PropsWithChildren,
    ReactElement,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { createPortal } from 'react-dom';

import { CalendarValue, CustomCalendar } from './Calendar';
import { Button } from '../Button';
import { usePopup } from '../../../helpers/hooks/usePopup';
import { getPortalElement } from '../Popup/helpers/getPortalElement';
import { DATE_FORMAT } from './constants';


//region Types
type ApiProps<T extends boolean> = {
    defaultApplyContent: ReactElement;
    defaultClearContent: ReactElement;
    setClearOnApply: (state: boolean) => void;
    clear: () => void;
    apply: (skipRestriction?: boolean) => void;
    tempValue: CalendarValue<T>;
    resetComponentValue: () => void;
    value: CalendarValue<T>;
    clearState: boolean;
    disabled: boolean;
    shown: boolean;
    hide: () => void;
    show: () => void;
};

export type CalendarPopUpProps<T extends boolean = false> = {
    value: CalendarValue<T>;
    onChange: (value: CalendarValue<T>) => void;
    selectRange: T;
    disabled?: boolean;
    className?: string;
    restrictPast?: boolean;
    restrictFuture?: boolean;
    renderActions?: (props: ApiProps<T>) => ReactElement;
    beforeApply?: (props: ApiProps<T>) => boolean;
    children?:
        | ReactElement
        | {
              (
                  ref: RefObject<HTMLDivElement>,
                  props: ApiProps<T>
              ): ReactElement;
          };
    dataTest?: string;
};
//endregion

//region Styles
const StyledCalendarContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 432px;
    min-height: 392px;
    padding: 32px;

    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    background: ${props => props.theme.Colors.white};
`;

const StyledCalendarSection = styled.div`
    width: 100%;
    padding: 0 28px 0 28px;
    margin-bottom: 24px;
`;

const StyledActionsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & > *:not(:last-child) {
        margin-right: 16px;
    }
`;

const Control = styled.div<{ disabled?: boolean; shown: boolean, hasValue?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 216px;
    height: 40px;
    padding: 8px 16px;

    color: ${props => props.hasValue ? props.theme.Colors.black : props.theme.Colors.steelGrey};
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.whiteFive};

    user-select: none;
    cursor: pointer;

    ${({ shown, theme, disabled }) => {
        if (shown && !disabled) {
            return css`
                border: 1px solid ${theme.Colors.cloudyBlue};
            `;
        }
    }}

    ${({ disabled }) => {
        if (disabled) {
            return css`
                cursor: default;
                color: ${props => props.theme.Colors.cloudyBlue};
            `;
        }
    }}
`;

const DashSeparator = styled.div`
    padding: 0 7.5px;
`;

const StyledValue = styled.div`
    font-size: 13px;
    margin: 0;
`;
//endregion

export const CalendarPopup = <T extends boolean = false>({
    value,
    onChange,
    disabled,
    className,
    selectRange,
    restrictPast = false,
    restrictFuture = false,
    renderActions,
    beforeApply,
    children,
    dataTest
}: PropsWithChildren<CalendarPopUpProps<T>>) => {
    const bodyDOM = document.querySelector('body');
    const contentRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);

    const [clearOnApply, setClearOnApply] = useState(false);
    const [tempValue, setTempValue] = useState<CalendarValue<T>>(value);
    const portalElement = getPortalElement(triggerRef.current, 'popup');

    const { shown, hide, show, style, enabled, disable, enable } = usePopup(
        triggerRef,
        contentRef,
        portalElement,
        {
            interactive: true,
            trigger: 'click',
            position: 'bottom-start',
            offset: {
                y: '8px',
                x: '0px',
            },
        }
    );

    useEffect(() => {
        setTempValue(value);
    }, [value]);

    useEffect(() => {
        if (disabled) {
            disable();
        } else {
            enable();
        }
    }, [disable, disabled, enable]);

    const clearHandler = () => {
        if (selectRange) {
            // @ts-ignore
            setTempValue({ start: null, end: null });
        } else {
            // @ts-ignore
            setTempValue(null);
        }
    };

    const saveHandler = (skipRestriction: boolean = false) => {
        if (beforeApply) {
            const res = beforeApply(getApiProps());
            if (!res && !skipRestriction) {
                return;
            }
        }

        // @ts-ignore
        onChange(clearOnApply ? null : tempValue);
        setClearOnApply(false);
        hide();
    };

    const isClearDisabled = (): boolean => {
        if (
            selectRange &&
            (tempValue as CalendarValue<true>).start === null &&
            (tempValue as CalendarValue<true>).end === null
        ) {
            return true;
        }
        if (
            (!selectRange && (tempValue as CalendarValue<true>) === null) ||
            (tempValue as CalendarValue<true>) === undefined
        ) {
            return true;
        }

        return false;
    };

    const applyContent: ReactElement = (
        <Button
            label={'Apply'}
            height={'48px'}
            width={'128px'}
            action={() => saveHandler()}
            dataTest='apply-button'
        />
    );
    const clearContent: ReactElement = (
        <Button
            label={'Clear'}
            height={'48px'}
            width={'128px'}
            action={clearHandler}
            variant={'inverse'}
            disabled={isClearDisabled()}
            dataTest='clear-button'
        />
    );

    const getApiProps = (): ApiProps<T> => ({
        clear: clearHandler,
        apply: saveHandler,
        setClearOnApply: state => {
            setClearOnApply(state);
        },
        tempValue,
        resetComponentValue: () => setTempValue(value),
        value,
        clearState: clearOnApply,
        defaultApplyContent: applyContent,
        defaultClearContent: clearContent,
        disabled: !!disabled,
        hide,
        show,
        shown,
    });

    const renderActionsContent = (): ReactElement => {
        if (renderActions) {
            return renderActions(getApiProps());
        }

        return (
            <StyledActionsSection data-test="calendar-actions-container">
                {clearContent}
                {applyContent}
            </StyledActionsSection>
        );
    };

    const renderContent = () => {
        return (
            <StyledCalendarContainer
                ref={contentRef}
                style={style}
                className={className}
            >
                <StyledCalendarSection>
                    <CustomCalendar
                        value={tempValue}
                        handleChange={date => {
                            setTempValue(date);
                        }}
                        selectRange={selectRange}
                        restrictPast={restrictPast}
                        restrictFuture={restrictFuture}
                    />
                </StyledCalendarSection>
                {renderActionsContent()}
            </StyledCalendarContainer>
        );
    };

    const formatDate = (date: Date | string): string =>
        dayjs(date).format('MM/DD/YY');

    const renderCustomControl = () => {
        if (typeof children === 'function') {
            return children(triggerRef, getApiProps());
        }
        if (children) {
            // @ts-ignore
            return cloneElement(children, {
                ref: triggerRef,
            });
        }
    };

    const renderControl = () => {
        let startCaption = DATE_FORMAT;
        let endCaption = DATE_FORMAT;

        if (selectRange && value) {
            const _value = value as CalendarValue<true>;
            if (_value.start) {
                startCaption = formatDate(_value.start);
            }
            if (_value.end) {
                endCaption = formatDate(_value.end);
            }

            return (
                <>
                    <StyledValue data-test="start-date-value">
                        {startCaption}
                    </StyledValue>
                    <DashSeparator data-test='date-separator'>—</DashSeparator>
                    <StyledValue data-test="end-date-value">
                        {endCaption}
                    </StyledValue>
                </>
            );
        }

        const _value = value as CalendarValue<false>;
        if (_value) {
            startCaption = endCaption = formatDate(_value);
        }

        return (
            <>
                <StyledValue data-test="start-date-value">
                    {startCaption}
                </StyledValue>
            </>
        );
    };

    if (!bodyDOM) {
        return null;
    }

    const hasNoValue = (!!value && !(value as any)?.start) || !value;

    return (
        <>
            {children ? (
                renderCustomControl()
            ) : (
                <Control
                    disabled={disabled}
                    ref={triggerRef}
                    shown={shown}
                    hasValue={!hasNoValue}
                    data-test={dataTest ?? "calendar-selector"}
                >
                    {renderControl()}
                </Control>
            )}
            {shown && enabled && createPortal(renderContent(), bodyDOM)}
        </>
    );
};
