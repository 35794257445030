import { COLORS } from './constants';

export const generateColorVariables = () => {
    let styles = '';
    const tagId = 'practis-variables';

    for (const [key, value] of Object.entries(COLORS)) {
        styles += `--ps-${key}: ${value}; \n`;
    }

    const colorStyles = `:root {${styles}}`;

    const sheet = document.getElementById(tagId);
    const head = document.head;

    if (sheet) {
        sheet.innerHTML = colorStyles;
    } else {
        const style = document.createElement('style');

        style.id = tagId;
        style.innerHTML = colorStyles;
        head.prepend(style);
    }

    return colorStyles;
};

