const secondsToMin = (tt?: number | null): string => {
    if (!tt) return '0m 0s';
    const time = Number(tt.toFixed());
    const min = ~~(time / 60);
    const sec = time % 60;
    let sec_min = '';
    sec_min += '' + min + 'm ' + (sec < 10 ? '' : '');
    sec_min += '' + sec + 's';
    return sec_min;
};

export const secondsToMinSimple = (tt?: number | null): string => {
    if (!tt) return '0 Min';

    if (tt < 60) {
        return `1 min`;
    } else {
        return `${Math.round((tt / 60)).toFixed()} min`;
    }
};

export const secondsToMinFormatted = (tt?: number | null): string => {
    if (!tt) return '00:00';
    const time = Number(tt.toFixed());
    const hr = ~~(time / 3600);
    const min = ~~((time % 3600) / 60);
    const sec = time % 60;
    let sec_min = '';
    if (hr > 0) {
        sec_min += hr + ':';
    }
    sec_min += `${min < 10 ? '0' : ''}` + min + ':' + (sec < 10 ? '0' : '');
    sec_min += '' + sec + '';
    return sec_min;
};

export default secondsToMin;
