import React, { FC } from 'react';

import Scenarios from '../../../../../../../ui/icons/Scenarios';
import ChallengeIcon from '../../../../../../../ui/icons/ChallengeIcon';
import { formatMMDDYY } from '../../../../../../../helpers/functions/date-convert';
import { TableDivider } from '../../../../../../../ui/components/table-wrapper/table-divider';
import {
    EnrollmentProgress,
    TrainingContentType
} from '../../../../../../../constants/interfaces/Enrollments';
import { TrainingMode } from '../../../../../../../constants/enums';
import {
    StyledListBox,
    StyledScenarioItem,
    ItemType,
    ItemContainer,
    ItemTitle,
    ItemStatusGreen,
    RepsContainer,
    StatisticsValue,
    StatisticsIndicator,
    RepsDescription,
    ArrowContainer,
    ArrowIcon,
    StyledTickIcon,
    ItemStatusBlue,
    ReviewButton,
    PlayScreenContainer,
    PlayScreen,
    AttemptText,
    PlayButton,
    ItemWithIconContainer,
    ItemStatusGrey,
} from './styles';
import PlayTriangle from '../../../../../../../ui/icons/PlayTriangle';
import NoPlay from '../../../../../../../ui/icons/NoPlay';

const ScenarioListItem: FC<{
    enrollmentProgress: EnrollmentProgress;
    redirect: Function;
    handleReviewReps: Function
}> = ({ enrollmentProgress, redirect, handleReviewReps }) => {
    const submission = enrollmentProgress.progress.submissions?.find(
        s => s.accuracy.modeScenario === TrainingMode.TEST_YOURSELF
    );
    const passed = !!enrollmentProgress.progress.completedAt;
    const inProgress = !passed && enrollmentProgress.progress.countReps > 0;
    const notStarted = !enrollmentProgress.progress.countReps;

    return (
        <StyledScenarioItem
            disabled={!submission}
            onClick={() => redirect(submission?.id)}
            data-test="scenario-item"
        >
            <ItemWithIconContainer>
                <div data-test="scenario-item-icon">
                    <Scenarios />
                </div>
                <ItemContainer>
                    <ItemType data-test="scenario-item-text">Scenario</ItemType>
                    <ItemTitle data-test="scenario-item-title">{enrollmentProgress.content.title}</ItemTitle>
                    {passed && (
                        <ItemStatusGreen data-test="scenario-item-status">
                            <StyledTickIcon /> Completed{' '}
                            {submission?.createdAt &&
                                `on ${formatMMDDYY(
                                    new Date(submission?.createdAt ?? '')
                                )}`}
                        </ItemStatusGreen>
                    )}
                    {inProgress && <ItemStatusBlue data-test="scenario-item-status">In Progress</ItemStatusBlue>}
                    {notStarted && <ItemStatusGrey data-test="scenario-item-status">Not Started</ItemStatusGrey>}
                </ItemContainer>
            </ItemWithIconContainer>
            <RepsContainer>
                <RepsDescription data-test="scenario-reps-text">Reps</RepsDescription>
                <StatisticsValue data-test="scenario-reps-value">
                    <span data-test="scenario-reps-count">{enrollmentProgress.progress.countReps}</span>
                    <StatisticsIndicator>
                        {' '}of <span data-test="scenario-reps-total-count">{enrollmentProgress.content.minRepsCount}</span>
                    </StatisticsIndicator>
                </StatisticsValue>
                {enrollmentProgress.progress.countReps > 0 && <ReviewButton data-test="scenario-review-button" onClick={(e) => {
                    e.stopPropagation();
                    handleReviewReps(enrollmentProgress.content.scenarioId)
                }}>Review</ReviewButton>}
            </RepsContainer>
            <PlayScreenContainer>
                {!!submission?.accuracy && (
                    <PlayScreen data-test="practis-set-item-play">
                        <PlayButton data-test="practis-set-item-play-icon">
                            {submission.mediaUrl ? <PlayTriangle /> : <NoPlay width={24} height={24} />}
                        </PlayButton>
                    </PlayScreen>
                )}
            </PlayScreenContainer>
            <RepsContainer>
                <RepsDescription data-test="scenario-accuracy-test-text">Accuracy Test</RepsDescription>
                <StatisticsValue empty={!submission?.accuracy} data-test="scenario-accuracy-value">
                    {!!submission?.accuracy ? (
                        <>
                            {Math.floor(submission.accuracy.accuracy * 100)}
                            % • {!!submission?.accuracy && (
                                <>
                                    {enrollmentProgress.progress.countTries ?? 0}
                                    <AttemptText data-test="scenario-accuracy-attempts-text">
                                        {' '}
                                        {enrollmentProgress.progress.countTries === 1
                                            ? 'Attempt'
                                            : 'Attempts'}
                                    </AttemptText>
                                </>
                            )}
                        </>
                    ) : (
                        <>{'—'}</>
                    )}
                </StatisticsValue>
            </RepsContainer>
            <ArrowContainer data-test="practis-set-item-arrow">
                <ArrowIcon disabled={!submission} />
            </ArrowContainer>
        </StyledScenarioItem>
    );
};

const ChallengeListItem: FC<{
    enrollmentProgress: EnrollmentProgress;
    redirect: Function;
}> = ({ enrollmentProgress, redirect }) => {
    const submission = enrollmentProgress.progress.submission;
    const isSubmitted = submission?.status === 'active';

    return (
        <StyledScenarioItem
            disabled={!isSubmitted}
            onClick={() => isSubmitted && redirect(submission?.id)}
            data-test="challenge-item"
        >
            <ItemWithIconContainer>
                <div data-test="challenge-item-icon">
                    <ChallengeIcon />
                </div>
                <ItemContainer>
                    <ItemType data-test="challenge-item-text">Challenge</ItemType>
                    <ItemTitle data-test="challenge-item-title">{enrollmentProgress.content.title}</ItemTitle>
                    {submission?.reviewedAt && (
                        <ItemStatusGreen data-test="challenge-item-status">
                            <StyledTickIcon /> Reviewed on{' '}
                            {formatMMDDYY(new Date(submission?.reviewedAt))}
                        </ItemStatusGreen>
                    )}
                    {isSubmitted && !submission!.reviewedAt && (
                        <ItemStatusGrey data-test="challenge-item-status">Needs Review</ItemStatusGrey>
                    )}
                    {!isSubmitted && (
                        <ItemStatusGrey data-test="challenge-item-status">Not Submitted</ItemStatusGrey>
                    )}
                </ItemContainer>
            </ItemWithIconContainer>
            <RepsContainer />
            <PlayScreenContainer>
                {!!(submission && isSubmitted) && (
                    <PlayScreen data-test="practis-set-item-play">
                        <PlayButton data-test="practis-set-item-play-icon">
                            {submission.mediaUrl ? <PlayTriangle /> : <NoPlay width={24} height={24} />}
                        </PlayButton>
                    </PlayScreen>
                )}
            </PlayScreenContainer>
            <RepsContainer>
                <RepsDescription data-test="challenge-score-text">Score</RepsDescription>
                <StatisticsValue empty={!submission?.score} data-test="challenge-score-value">
                    {submission?.score ?? '—'}
                </StatisticsValue>
            </RepsContainer>
            <ArrowContainer data-test="practis-set-item-arrow">
                <ArrowIcon disabled={!isSubmitted} />
            </ArrowContainer>
        </StyledScenarioItem>
    );
};

const ReportsItems: FC<{
    content?: EnrollmentProgress[];
    redirectChallenge: Function;
    redirectScenario: Function;
    handleReviewReps: Function;
}> = ({ content, redirectScenario, redirectChallenge, handleReviewReps }) => (
    <StyledListBox>
        {content?.map((item, index) => (
            <>
                {item.content.type === TrainingContentType.CHALLENGE ? (
                    <ChallengeListItem
                        enrollmentProgress={item}
                        redirect={redirectChallenge}
                    />
                ) : item.content.type === TrainingContentType.SCENARIO ? (
                    <ScenarioListItem
                        enrollmentProgress={item}
                        redirect={redirectScenario}
                        handleReviewReps={handleReviewReps}
                    />
                ) : (
                    <></>
                )}
                {index < content.length - 1 && <TableDivider />}
            </>
        ))}
    </StyledListBox>
);

export default ReportsItems;
