import { ErrorResult } from "../../../constants/interfaces/ErrorResult";

export enum ACTIONS {
    VOICE_GENERATION_START = 'VOICE_GENERATION_START',
    VOICE_GENERATION_SUCCESS = 'VOICE_GENERATION_SUCCESS',
    VOICE_GENERATION_FAILURE = 'VOICE_GENERATION_FAILURE',
    VOICE_GENERATION_CLEAR = 'VOICE_GENERATION_CLEAR',
}

export function voiceGenerationStart(feature: string, itemId: number | string) {
    return {
        type: ACTIONS.VOICE_GENERATION_START as ACTIONS.VOICE_GENERATION_START,
        itemId,
        feature,
    };
}

export function voiceGenerationSuccess(
    feature: string,
    itemId: number | string,
    voice: Blob
) {
    return {
        type: ACTIONS.VOICE_GENERATION_SUCCESS as ACTIONS.VOICE_GENERATION_SUCCESS,
        feature,
        itemId,
        voice,
    };
}

export function voiceGenerationFailure(
    feature: string,
    itemId: number | string,
    error: ErrorResult
) {
    return {
        type: ACTIONS.VOICE_GENERATION_FAILURE as ACTIONS.VOICE_GENERATION_FAILURE,
        feature,
        itemId,
        error,
    };
}

export function voiceGenerationClear(feature: string, itemId: number | string) {
    return {
        type: ACTIONS.VOICE_GENERATION_CLEAR as ACTIONS.VOICE_GENERATION_CLEAR,
        feature,
        itemId,
    };
}
