import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { updateLibraryScenarioSuccess } from '../../../features/library/store/actions';
import {
    CREATE_SCENARIO_ACTION,
    UPDATE_SCENARIO_ACTION,
    UPDATE_SCENARIO_STATUS_ACTION,
} from '../../../features/library/services/LibraryBulkActionsService/constants';
import {
    Scenario,
    ScenarioStatuses,
} from '../../../constants/interfaces/Scenario';
import { useHistory } from '../../../tools/router';
import ROUTES from '../../../routes/routes';
import { useUpdateScenarioStatusApi } from '../../../api';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { useModifyScenarioService } from './store/services';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { StatusItems } from './NewScenario';

/**
 * @function useCreateEditScenarioSuccessCallback
 * @param { Function } setLoadingSave
 * @param { Record<string, unknown> | undefined } responses
 * @returns { void }
 */
export const useCreateEditScenarioSuccessCallback = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const showMessage = useShowMessage();

    return useCallback(
        (
            setLoadingSave: (isLoading: boolean) => void,
            responses?: Record<string, unknown>
        ) => {
            setLoadingSave(false);

            if (!isEmpty(responses)) {
                const createScenarioResponse = responses![
                    CREATE_SCENARIO_ACTION
                ] as Scenario | undefined;

                const editScenarioResponse = responses![
                    UPDATE_SCENARIO_ACTION
                ] as Scenario | undefined;

                const updateScenarioResponse = responses![
                    UPDATE_SCENARIO_STATUS_ACTION
                ] as Scenario | undefined;
                if (createScenarioResponse) {
                    dispatch(
                        updateLibraryScenarioSuccess(
                            createScenarioResponse,
                            'update'
                        )
                    );

                    const currentStatus = !isEmpty(updateScenarioResponse)
                        ? ScenarioStatuses.ACTIVE
                        : ScenarioStatuses.DRAFT;

                    const actionMessage = StatusItems.filter(
                        (item: any) => item.value === currentStatus
                    );
                    showMessage(actionMessage[0].message, 'success');
                } else if (editScenarioResponse) {
                    dispatch(
                        updateLibraryScenarioSuccess(
                            editScenarioResponse,
                            'update'
                        )
                    );
                }
            }

            history.push(ROUTES.LIBRARY_SETTINGS.SCENARIOS.ALL);
        },
        [dispatch, history, showMessage]
    );
};

/**
 * @function createEditScenarioFailedCallback
 * @param { Function } setIsLoading
 * @param { ErrorResult | undefined } error
 * @param { Record<string, unknown> | undefined } completedResponses
 * @returns { void }
 */
export const useCreateEditScenarioFailedCallback = () => {
    const modifyScenario = useModifyScenarioService();
    const showMessage = useShowMessage();

    return useCallback(
        (
            setLoadingSave: (isLoading: boolean) => void,
            error?: ErrorResult,
            completedResponses?: Record<string, unknown>
        ) => {
            if (!isEmpty(completedResponses)) {
                const createScenarioResponse = completedResponses![
                    CREATE_SCENARIO_ACTION
                ] as Scenario | undefined;

                const createdScenarioId = createScenarioResponse?.id;

                // The bulk action failed in other steps
                // but new scenario created
                // in the next try it goes to
                // edit scenario action.
                if (createdScenarioId) {
                    modifyScenario(createdScenarioId, 'id');
                }
            }

            error?.message && showMessage(error.message, 'error');
            setLoadingSave(false);
        },
        [modifyScenario, showMessage]
    );
};

/**
 * @function useHandleArchiveScenario
 * @returns { Promise<void> }
 */
export const useHandleArchiveScenario = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const updateScenarioStatus = useUpdateScenarioStatusApi();

    return useCallback(
        (scenarioId: number) => {
            updateScenarioStatus('archive', [scenarioId]).then(response => {
                dispatch(updateLibraryScenarioSuccess(response, 'update'));

                history.goBack();
            });
        },
        [dispatch, history, updateScenarioStatus]
    );
};

/**
 * @function useHandleRestoreArchivedScenario
 * @param { number } scenarioId
 * @returns { void }
 */
export const useHandleRestoreArchivedScenario = () => {
    const dispatch = useDispatch();
    const modifyScenario = useModifyScenarioService();
    const updateScenarioStatus = useUpdateScenarioStatusApi();

    return useCallback(
        (scenarioId: number) => {
            updateScenarioStatus('draft', [scenarioId]).then(
                (response: Scenario) => {
                    dispatch(updateLibraryScenarioSuccess(response, 'update'));
                    modifyScenario(ScenarioStatuses.DRAFT, 'status', true);
                }
            );
        },
        [dispatch, modifyScenario, updateScenarioStatus]
    );
};