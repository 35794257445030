import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Roles } from '../../../constants/interfaces/Roles';

export interface UserRolesState {
    list: Roles[];
    loading: boolean;
    error?: string;
}

export type UserRolesStateSelector = (state: any) => UserRolesState;

export const UserRolesStateSelectorContext = React.createContext<UserRolesStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUserRolesState(): UserRolesState {
    return useSelector(useContext(UserRolesStateSelectorContext));
}
