import styled from 'styled-components';

import AvatarPlaceholder from '../../../../../AvatarPlaceholder/AvatarPlaceholder';

export const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    min-width: 200px;
`;

export const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 4px;
    flex-shrink: 0;
`;

export const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const FlexWrapper = styled.div<{ hasMargin: boolean }>`
    display: flex;
    align-items: center;
    margin-left: ${props => (props.hasMargin ? -12 : 0)}px;
`;
