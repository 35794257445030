import { useSelector } from "react-redux";
import { compose, Reducer } from "redux";
import { withLabelsInitialState, withLabelsReducer } from "../../../../portableLabels/store/hors/withLabels";
import { ACTIONS, LablesFilterActions } from "./actions";
import { LabelsFilterState } from "./types";

const labelsFilterBaseReducer: Reducer<LabelsFilterState, any> = (
    state = withLabelsInitialState({ isDisabled: false }, 'labelsFilter'),
    action: LablesFilterActions
): LabelsFilterState => {
    switch (action.type) {
        case ACTIONS.DISABLE_LABELS_FILTER:
            return {
                ...state,
                isDisabled: action.isDisabled,
            }
        default:
            return state;
    }
};

export const labelsFilterReducer = compose<Reducer<LabelsFilterState, any>>(
    withLabelsReducer({ reducerName: 'labelsFilter' })
)(labelsFilterBaseReducer);

export const useLabelsFilterState = () =>
    useSelector(state => state.labelsFilter);