/* eslint-disable no-useless-escape */
export const getBrowserName = (): string => {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf('edg') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(window as any).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
};

/**
 *
 * @function getBrowserInfo
 * @returns { string }
 */
function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    const browsers = {
        Firefox: /Firefox\/([\d\.]+)/,
        Opera: /(?:Opera|OPR)\/([\d\.]+)/,
        IE: /Trident\/([\d\.]+)/,
        Edge: /Edge\/([\d\.]+)/,
        Chrome: /Chrome\/([\d\.]+)/,
        Safari: /Version\/([\d\.]+)/,
    };
    for (const [browserName, regex] of Object.entries(browsers)) {
        if (regex.test(userAgent)) {
            const browserVersion = userAgent?.match(regex)?.[1] ?? '';
            return `${browserName} ${browserVersion}`;
        }
    }
    return 'Unknown browser';
}

export function isFirefox() {
    return getBrowserName() === 'firefox';
}

export function isChrome() {
    return getBrowserName() === 'chrome';
}

export function isOpera() {
    return getBrowserName() === 'opera';
}

export function isEdge() {
    return getBrowserName() === 'edge';
}

export function isSafari() {
    return getBrowserName() === 'safari';
}

/**
 * @description If the user is on a desktop, returns browser name, ele returns phone model
 * @function getDeviceInfo
 * @returns { string | undefined }
 */
export function getDeviceInfo() {
    const browserInfo = getBrowserInfo();
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        // user is on a mobile device
        const match = navigator.userAgent.match(
            /(iPhone|iPod|iPad|Android)\s([\d_]+)/
        );
        if (match) {
            const [, phoneModel, phoneModelNumber] = match;
            return `${phoneModel} ${phoneModelNumber.replace(
                /_/g,
                '.'
            )}/${browserInfo}`;
        } else {
            return 'Unknown mobile device';
        }
    } else {
        // user is on desktop
        return browserInfo;
    }
}

/**
 * @function getNetworkConnectionType
 * @returns { string }
 */
export function getNetworkConnectionType() {
    const connection =
        (navigator as any)?.connection ||
        (navigator as any)?.mozConnection ||
        (navigator as any)?.webkitConnection;

    if (connection) {
        return connection.effectiveType || 'unknown';
    } else {
        return 'unknown';
    }
}