import { ListResult, PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { Challenge } from '../../../constants/interfaces/Challenge';

export enum ACTIONS {
    SEARCH_LIBRARY_PRACTIS_SETS_START = 'SEARCH_LIBRARY_PRACTIS_SETS_START',
    SEARCH_LIBRARY_PRACTIS_SETS_SUCCESS = 'SEARCH_LIBRARY_PRACTIS_SETS_SUCCESS',
    SEARCH_LIBRARY_PRACTIS_SETS_FAILURE = 'SEARCH_LIBRARY_PRACTIS_SETS_FAILURE',

    GET_FULL_LIBRARY_PRACTIS_SETS_START = 'GET_FULL_LIBRARY_PRACTIS_SETS_START',
    GET_FULL_LIBRARY_PRACTIS_SETS_SUCCESS = 'GET_FULL_LIBRARY_PRACTIS_SETS_SUCCESS',
    GET_FULL_LIBRARY_PRACTIS_SETS_FAILURE = 'GET_FULL_LIBRARY_PRACTIS_SETS_FAILURE',

    GET_TOTAL_LIBRARY_PRACTIS_SETS_COUNT_START = 'GET_TOTAL_LIBRARY_PRACTIS_SETS_COUNT_START',
    GET_TOTAL_LIBRARY_PRACTIS_SETS_COUNT_SUCCESS = 'GET_TOTAL_LIBRARY_PRACTIS_SETS_COUNT_SUCCESS',
    GET_TOTAL_LIBRARY_PRACTIS_SETS_COUNT_FAILURE = 'GET_TOTAL_LIBRARY_PRACTIS_SETS_COUNT_FAILURE',

    UPDATE_LIBRARY_PRACTIS_SET_START = 'UPDATE_LIBRARY_PRACTIS_SET_START',
    UPDATE_LIBRARY_PRACTIS_SET_SUCCESS = 'UPDATE_LIBRARY_PRACTIS_SET_SUCCESS',
    UPDATE_LIBRARY_PRACTIS_SET_FAILURE = 'UPDATE_LIBRARY_PRACTIS_SET_FAILURE',

    UPDATE_ASSIGNED_LIBRARY_PRACTIS_SET = 'UPDATE_ASSIGNED_LIBRARY_PRACTIS_SET',
    UPDATE_SEPARATE_ASSIGNED_LIBRARY_PRACTIS_SET = 'UPDATE_SEPARATE_ASSIGNED_LIBRARY_PRACTIS_SET',
    SET_SEARCH_TERM_FOR_LIBRARY_PRACTIS_SET = 'SET_SEARCH_TERM_FOR_LIBRARY_PRACTIS_SET',

    UPDATE_SELECTED_PRACTIS_SET_STATE = 'UPDATE_SELECTED_PRACTIS_SET_STATE',
    UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE = 'UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE',
    UPDATE_PRACTIS_SET_DELETE_LABEL_SUCCESS = 'UPDATE_PRACTIS_SET_DELETE_LABEL_SUCCESS',

    SEARCH_LIBRARY_SCENARIOS_START = 'SEARCH_LIBRARY_SCENARIOS_START',
    SEARCH_LIBRARY_SCENARIOS_SUCCESS = 'SEARCH_LIBRARY_SCENARIOS_SUCCESS',
    SEARCH_LIBRARY_SCENARIOS_FAILURE = 'SEARCH_LIBRARY_SCENARIOS_FAILURE',

    GET_TOTAL_LIBRARY_SCENARIOS_COUNT_START = 'GET_TOTAL_LIBRARY_SCENARIOS_COUNT_START',
    GET_TOTAL_LIBRARY_SCENARIOS_COUNT_SUCCESS = 'GET_TOTAL_LIBRARY_SCENARIOS_COUNT_SUCCESS',
    GET_TOTAL_LIBRARY_SCENARIOS_COUNT_FAILURE = 'GET_TOTAL_LIBRARY_SCENARIOS_COUNT_FAILURE',

    UPDATE_LIBRARY_SCENARIO_START = 'UPDATE_LIBRARY_SCENARIO_START',
    UPDATE_LIBRARY_SCENARIO_SUCCESS = 'UPDATE_LIBRARY_SCENARIO_SUCCESS',
    UPDATE_LIBRARY_SCENARIO_FAILURE = 'UPDATE_LIBRARY_SCENARIO_FAILURE',

    UPDATE_SELECTED_SCENARIO_STATE = 'UPDATE_SELECTED_SCENARIO_STATE',
    UPDATE_ALL_SELECTED_SCENARIOS_STATE = 'UPDATE_ALL_SELECTED_SCENARIOS_STATE',
    UPDATE_SCENARIOS_DELETE_LABEL_SUCCESS = 'UPDATE_SCENARIOS_DELETE_LABEL_SUCCESS',

    SEARCH_LIBRARY_CHALLENGES_START = 'SEARCH_LIBRARY_CHALLENGES_START',
    SEARCH_LIBRARY_CHALLENGES_SUCCESS = 'SEARCH_LIBRARY_CHALLENGES_SUCCESS',
    SEARCH_LIBRARY_CHALLENGES_FAILURE = 'SEARCH_LIBRARY_CHALLENGES_FAILURE',

    GET_TOTAL_LIBRARY_CHALLENGES_COUNT_START = 'GET_TOTAL_LIBRARY_CHALLENGES_COUNT_START',
    GET_TOTAL_LIBRARY_CHALLENGES_COUNT_SUCCESS = 'GET_TOTAL_LIBRARY_CHALLENGES_COUNT_SUCCESS',
    GET_TOTAL_LIBRARY_CHALLENGES_COUNT_FAILURE = 'GET_TOTAL_LIBRARY_CHALLENGES_COUNT_FAILURE',

    UPDATE_LIBRARY_CHALLENGES_START = 'UPDATE_LIBRARY_CHALLENGES_START',
    UPDATE_LIBRARY_CHALLENGES_SUCCESS = 'UPDATE_LIBRARY_CHALLENGES_SUCCESS',
    UPDATE_LIBRARY_CHALLENGES_FAILURE = 'UPDATE_LIBRARY_CHALLENGES_FAILURE',

    UPDATE_SELECTED_CHALLENGE_STATE = 'UPDATE_SELECTED_CHALLENGE_STATE',
    UPDATE_ALL_SELECTED_CHALLENGES_STATE = 'UPDATE_ALL_SELECTED_CHALLENGES_STATE',
    UPDATE_CHALLENGES_DELETE_LABEL_SUCCESS = 'UPDATE_CHALLENGES_DELETE_LABEL_SUCCESS',
}

export function searchLibraryPractisSetsStart() {
    return {
        type: ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_START as ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_START,
    };
}

export function searchLibraryPractisSetsSuccess(
    practisSets: ListResult<PractisSets>
) {
    return {
        type: ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_SUCCESS as ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function searchLibraryPractisSetsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_FAILURE as ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function getFullLibraryPractisSetsStart() {
    return {
        type: ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_START as ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_START,
    };
}

export function getFullLibraryPractisSetsSuccess(
    practisSets: ListResult<PractisSets>
) {
    return {
        type: ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_SUCCESS as ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function getFullLibraryPractisSetsFailure(error: string) {
    return {
        type: ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_FAILURE as ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function updateLibraryPractisSetStart() {
    return {
        type: ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_START as ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_START,
    };
}

export function updateLibraryPractisSetSuccess(
    updatedPractisSet: PractisSets,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_SUCCESS as ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_SUCCESS,
        updatedPractisSet,
        updateType,
    };
}

export function updateLibraryPractisSetFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_FAILURE as ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_FAILURE,
        error,
    };
}

export function updateAssignedLibraryPractisSet(assignedPractisSets: number[]) {
    return {
        type: ACTIONS.UPDATE_ASSIGNED_LIBRARY_PRACTIS_SET as ACTIONS.UPDATE_ASSIGNED_LIBRARY_PRACTIS_SET,
        assignedPractisSets,
    };
}

export function updateSeparateAssignedLibraryPractisSet(
    assignedPractisSets: number[]
) {
    return {
        type: ACTIONS.UPDATE_SEPARATE_ASSIGNED_LIBRARY_PRACTIS_SET as ACTIONS.UPDATE_SEPARATE_ASSIGNED_LIBRARY_PRACTIS_SET,
        assignedPractisSets,
    };
}

export function setSearchTermForLibraryPractisSet(value: string) {
    return {
        type: ACTIONS.SET_SEARCH_TERM_FOR_LIBRARY_PRACTIS_SET as ACTIONS.SET_SEARCH_TERM_FOR_LIBRARY_PRACTIS_SET,
        value,
    };
}

export function updateLibraryPractisSetCheckedState(id: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_PRACTIS_SET_STATE as ACTIONS.UPDATE_SELECTED_PRACTIS_SET_STATE,
        id,
    };
}

export function updateAllPractisSetsCheckedState(
    selectedPractisSetIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE as ACTIONS.UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE,
        selectedPractisSetIds,
        checked,
        partial,
    };
}

export function updatePractisSetDeleteLabelSuccess(
    labelId: number,
    id: number | string
) {
    return {
        type: ACTIONS.UPDATE_PRACTIS_SET_DELETE_LABEL_SUCCESS as ACTIONS.UPDATE_PRACTIS_SET_DELETE_LABEL_SUCCESS,
        labelId,
        id,
    };
}

export function searchLibraryScenariosStart() {
    return {
        type: ACTIONS.SEARCH_LIBRARY_SCENARIOS_START as ACTIONS.SEARCH_LIBRARY_SCENARIOS_START,
    };
}

export function searchLibraryScenariosSuccess(scenarios: ListResult<Scenario>) {
    return {
        type: ACTIONS.SEARCH_LIBRARY_SCENARIOS_SUCCESS as ACTIONS.SEARCH_LIBRARY_SCENARIOS_SUCCESS,
        scenarios,
    };
}

export function searchLibraryScenariosFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_LIBRARY_SCENARIOS_FAILURE as ACTIONS.SEARCH_LIBRARY_SCENARIOS_FAILURE,
        error,
    };
}

export function updateLibraryScenarioStart() {
    return {
        type: ACTIONS.UPDATE_LIBRARY_SCENARIO_START as ACTIONS.UPDATE_LIBRARY_SCENARIO_START,
    };
}

export function updateLibraryScenarioSuccess(
    updatedScenario: Scenario,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_LIBRARY_SCENARIO_SUCCESS as ACTIONS.UPDATE_LIBRARY_SCENARIO_SUCCESS,
        updatedScenario,
        updateType,
    };
}

export function updateLibraryScenarioFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_LIBRARY_SCENARIO_FAILURE as ACTIONS.UPDATE_LIBRARY_SCENARIO_FAILURE,
        error,
    };
}

export function updateLibraryScenarioCheckedState(id: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_SCENARIO_STATE as ACTIONS.UPDATE_SELECTED_SCENARIO_STATE,
        id,
    };
}

export function updateAllScenarioCheckedState(
    selectedScenarioIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_SCENARIOS_STATE as ACTIONS.UPDATE_ALL_SELECTED_SCENARIOS_STATE,
        selectedScenarioIds,
        checked,
        partial,
    };
}

export function updateScenarioDeleteLabelSuccess(
    labelId: number,
    id: number | string
) {
    return {
        type: ACTIONS.UPDATE_SCENARIOS_DELETE_LABEL_SUCCESS as ACTIONS.UPDATE_SCENARIOS_DELETE_LABEL_SUCCESS,
        labelId,
        id,
    };
}

export function searchLibraryChallengesStart() {
    return {
        type: ACTIONS.SEARCH_LIBRARY_CHALLENGES_START as ACTIONS.SEARCH_LIBRARY_CHALLENGES_START,
    };
}

export function searchLibraryChallengesSuccess(
    challenges: ListResult<Challenge>
) {
    return {
        type: ACTIONS.SEARCH_LIBRARY_CHALLENGES_SUCCESS as ACTIONS.SEARCH_LIBRARY_CHALLENGES_SUCCESS,
        challenges,
    };
}

export function searchLibraryChallengesFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_LIBRARY_CHALLENGES_FAILURE as ACTIONS.SEARCH_LIBRARY_CHALLENGES_FAILURE,
        error,
    };
}

export function getTotalLibraryChallengesCountStart() {
    return {
        type: ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_START as ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_START,
    };
}

export function getTotalLibraryChallengesCountSuccess(
    challenges: PaginationResult<Challenge>
) {
    return {
        type: ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_SUCCESS as ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_SUCCESS,
        challenges,
    };
}

export function getTotalLibraryChallengesCountFailure(error: string) {
    return {
        type: ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_FAILURE as ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_FAILURE,
        error,
    };
}

export function updateLibraryChallengeStart() {
    return {
        type: ACTIONS.UPDATE_LIBRARY_CHALLENGES_START as ACTIONS.UPDATE_LIBRARY_CHALLENGES_START,
    };
}

export function updateLibraryChallengeSuccess(
    updatedChallenge: Challenge,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_LIBRARY_CHALLENGES_SUCCESS as ACTIONS.UPDATE_LIBRARY_CHALLENGES_SUCCESS,
        updatedChallenge,
        updateType,
    };
}

export function updateLibraryChallengeFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_LIBRARY_CHALLENGES_FAILURE as ACTIONS.UPDATE_LIBRARY_CHALLENGES_FAILURE,
        error,
    };
}

export function updateLibraryChallengeCheckedState(id: number | string) {
    return {
        type: ACTIONS.UPDATE_SELECTED_CHALLENGE_STATE as ACTIONS.UPDATE_SELECTED_CHALLENGE_STATE,
        id,
    };
}

export function updateAllChallengesCheckedState(
    selectedChallengeIds: any,
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_CHALLENGES_STATE as ACTIONS.UPDATE_ALL_SELECTED_CHALLENGES_STATE,
        selectedChallengeIds,
        checked,
        partial,
    };
}

export function updateChallengeDeleteLabelSuccess(
    labelId: number,
    id: number | string
) {
    return {
        type: ACTIONS.UPDATE_CHALLENGES_DELETE_LABEL_SUCCESS as ACTIONS.UPDATE_CHALLENGES_DELETE_LABEL_SUCCESS,
        labelId,
        id,
    };
}
