/**
 * @description to extract email addresses from a string
 * EX: input: 'test "test@test.com" it's a test' => output: test@test.com
 * @function extractEmailsFromString
 * @returns { RegExpMatchArray | null }
 */
export function extractEmailsFromString(
    string: string
): RegExpMatchArray | null {
    return string.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
}

/**
 * @description Simple function to convert simple present verb to the simple past form.
 * ex: create => created, cry => cried 
 * @function convertPresentVerbToPastVerb
 * @param { string } string 
 * @returns { string }
 */
export function convertPresentVerbToPastVerb(string: string) {
    return (
        string
            .replace(/([^aeiouy])y$/, '$1i')
            .replace(/([^aeiouy][aeiou])([^aeiouy])$/, '$1$2$2')
            .replace(/e$/, '') + 'ed'
    );
}

/**
 * @description a function which checks if a string is undefined, null, empty or contains white spaces only
 * @function isEmptyOrWhiteSpace
 * @param { string } value 
 * @returns { boolean }
 */
export function isEmptyOrWhiteSpace(value: string) {
    return !value || value.trim().length === 0;
}