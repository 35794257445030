import React, { ComponentType, ForwardRefExoticComponent } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

const StyledDropDownItem = styled.div`
    color: ${props => props.theme.Colors.black};
    margin-top: 8px;

    &:active {
        opacity: 0.7;
    }
`;

const StyledNavLink = styled(NavLink)`
    color: ${props => props.theme.Colors.black};
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    &.--custom {
        color: ${props => props.theme.Colors.tomato};
    }
    &.--active {
        background: ${props => props.theme.Colors.whiteFive};
    }
    &:hover {
        background: ${props => props.theme.Colors.whiteFive};
    }
`;

const StyledA = StyledNavLink.withComponent('a');
const StyledIcon = styled.div<{iconSize?: number}>`
    height: ${props => props.iconSize || 20}px;
    width: ${props => props.iconSize || 20}px;
    margin-left: 6px;
    margin-right: 12px;
`;

export const DropDownListItem: React.FC<{
    name: string;
    url: string;
    custom?: boolean;
    externalUrl?: boolean;
    replaceUrl?: boolean;
    modalUrl?: boolean;
    icon?: ComponentType | ForwardRefExoticComponent<any>;
    iconSize?: number;
    className?: string;
    onClick?(): void;
}> = ({
    name,
    url,
    icon: Icon,
    custom,
    externalUrl,
    replaceUrl,
    modalUrl,
    className,
    iconSize,
    onClick,
}) => {
    let location = useLocation();
    const IconComponent = Icon && (
        <StyledIcon iconSize={iconSize}>
            <Icon />
        </StyledIcon>
    );

    if (externalUrl) {
        return (
            <StyledDropDownItem className={className} data-test='dropDownListItem'>
                <StyledA href={url} className={custom ? '--custom' : ''} target='blank' data-test='dropDownListLink'>
                   {name}{IconComponent}
                </StyledA>
            </StyledDropDownItem>
        );
    } 
    else if (replaceUrl) {
        return (
            <StyledDropDownItem className={className} data-test='dropDownListItem'>
                <StyledA href={url} className={custom ? '--custom' : ''} data-test='dropDownListLink'>
                   {name}{IconComponent}
                </StyledA>
            </StyledDropDownItem>
        );
    }
    else if (modalUrl) {
        return (
            <StyledDropDownItem className={className} data-test='dropDownListItem'>
                <StyledNavLink
                    activeClassName="--active"
                    className={custom ? '--custom' : ''}
                    to={{
                        pathname: url,
                        state: { background: location },
                    }}
                    data-test='dropDownListLink'
                >
                    {IconComponent}
                    {name}
                </StyledNavLink>
            </StyledDropDownItem>
        );
    }

    return (
        <StyledDropDownItem onClick={onClick} data-test='dropDownListItem'>
            <StyledNavLink
                activeClassName="--active"
                className={custom ? '--custom' : ''}
                to={url}
                data-test='dropDownListLink'
            >
                {IconComponent}
                {name}
            </StyledNavLink>
        </StyledDropDownItem>
    );
};
