import styled from 'styled-components';

export const OnboardingContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    @media(max-width:767px) {
        flex-direction: column-reverse;
    }
    @media(min-width: 768px) {
        flex-direction: column-reverse;
    }
    @media(min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const OnboardingText = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media(max-width:767px) {
        width: 290px;
        padding-top: 24px;
    }
    @media(min-width:768px) {
        width: 432px;
        padding: 32px 33px 0 33px;
    }
    @media(min-width: 1024px) {
        align-items: flex-start;
        justify-content: center;
        width: 345px;
        padding: 0;
        margin-right: 56px;
    }
`;

export const OnboardingGreeting = styled.div`
    color: var(--ps-blue-main);

    @media(max-width:767px) {
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
    }
    @media(min-width:768px) {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
    }
    @media(min-width: 1024px) {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
    }
`;

export const OnboardingTitle = styled.div`
    font-family: Merriweather;
    color: var(--ps-white-1);
    padding-top: 4px;
    font-weight: 600;

    @media(max-width:767px) {
        font-size: 20px;
        line-height: 25px;
    }
    @media(min-width:768px) {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
    }
`

export const OnboardingDescription = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    padding-top: 8px;

    b {
        color: var(--ps-white-1);
        font-weight: 600;
    }
`

export const DownloadTitle = styled.div`
    width: 290px;
    padding-top: 16px;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: var(--ps-grey-2);

    @media(max-width:767px) {
        margin: 0 auto;
    }
    @media(min-width: 1024px) {
        margin: 0;
    }
`

export const DownloadButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 290px;
    margin: 0 auto;

    @media(max-width:767px) {
        padding-top: 4px;
        margin: 0 auto;
    }
    @media(min-width:768px) {
        padding-top: 8px;
    }
    @media(min-width: 1024px) {
        margin: 0;
    }
`

export const OnboardingButton = styled.div`
    margin-top: 24px;
`