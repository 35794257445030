import useGoogleAnalyticsEvents, { EventOptions, AnalyticsInformation } from '../../../services/GoogleAnalytics';
import { useCallback } from 'react';
import { getProfileState } from '../../UserProfile/store/reducers';
import { useSelector } from 'react-redux';
import { getCompanyState } from '../../CompanySettings/store/reducers';

// export const EventObjects = {
//     ROLEPLAYS_SCREEN_OPEN: 'roleplays_screen_open',
//     ROLEPLAYS_SCREEN_DUPLICATE: 'roleplays_screen_duplicate',
//     ROLEPLAYS_SCREEN_DELETE: 'roleplays_screen_delete',
//     ROLEPLAYS_SCREEN_SEARCH: 'roleplays_screen_search',
//     ROLEPLAYS_SCREEN_HINT: 'roleplays_screen_hint',
//     ROLEPLAYS_SCREEN_NEW: 'roleplays_screen_new',
//     CONTEXT_WIZARD_SCREEN_OPEN: 'context_wizard_screen_open',
//     CONTEXT_WIZARD_SCREEN_SITUATION_FILLED: 'context_wizard_screen_situation_filled',
//     CONTEXT_WIZARD_SCREEN_SITUATION_HINT: 'context_wizard_screen_situation_hint',
//     CONTEXT_WIZARD_SCREEN_CUSTOMER_FILLED: 'context_wizard_screen_customer_filled',
//     CONTEXT_WIZARD_SCREEN_CUSTOMER_HINT: 'context_wizard_screen_customer_hint',
//     CONTEXT_WIZARD_SCREEN_PRODUCT_FILLED: 'context_wizard_screen_product_filled',
//     CONTEXT_WIZARD_SCREEN_PRODUCT_HINT: 'context_wizard_screen_product_hint',
//     CONTEXT_WIZARD_SCREEN_GOAL_FILLED: 'context_wizard_screen_goal_filled',
//     CONTEXT_WIZARD_SCREEN_GOAL_HINT: 'context_wizard_screen_goal_hint',
//     CONTEXT_WIZARD_SCREEN_NUANCES_FILLED: 'context_wizard_screen_nuances_filled',
//     CONTEXT_WIZARD_SCREEN_NUANCES_HINT: 'context_wizard_screen_nuances_hint',
//     CONTEXT_WIZARD_SCREEN_METHOD_FILLED: 'context_wizard_screen_method_filled',
//     CONTEXT_WIZARD_SCREEN_METHOD_HINT: 'context_wizard_screen_method_hint',
//     CONTEXT_WIZARD_SCREEN_CLOSE: 'context_wizard_screen_close',
//     CONTEXT_WIZARD_SCREEN_CREATE: 'context_wizard_screen_create',
//     CONTEXT_WIZARD_SCREEN_LEFT_EMPTY: 'context_wizard_screen_left_empty',
//     ROLEPLAY_SCREEN_OPEN_NEW: 'roleplay_screen_open_new',
//     ROLEPLAY_SCREEN_OPEN_EDIT: 'roleplay_screen_open_edit',
//     ROLEPLAY_SCREEN_OPEN_READ_ONLY: 'roleplay_screen_open_read_only',
//     ROLEPLAY_SCREEN_SITUATION_UPDATED: 'roleplay_screen_situation_updated',
//     ROLEPLAY_SCREEN_SITUATION_HINT: 'roleplay_screen_situation_hint',
//     ROLEPLAY_SCREEN_CUSTOMER_UPDATED: 'roleplay_screen_customer_updated',
//     ROLEPLAY_SCREEN_CUSTOMER_HINT: 'roleplay_screen_customer_hint',
//     ROLEPLAY_SCREEN_PRODUCT_UPDATED: 'roleplay_screen_product_updated',
//     ROLEPLAY_SCREEN_PRODUCT_HINT: 'roleplay_screen_product_hint',
//     ROLEPLAY_SCREEN_GOAL_UPDATED: 'roleplay_screen_goal_updated',
//     ROLEPLAY_SCREEN_GOAL_HINT: 'roleplay_screen_goal_hint',
//     ROLEPLAY_SCREEN_NUANCES_UPDATED: 'roleplay_screen_nuances_updated',
//     ROLEPLAY_SCREEN_NUANCES_HINT: 'roleplay_screen_nuances_hint',
//     ROLEPLAY_SCREEN_METHOD_UPDATED: 'roleplay_screen_method_updated',
//     ROLEPLAY_SCREEN_METHOD_HINT: 'roleplay_screen_method_hint',
//     ROLEPLAY_SCREEN_GENERATE: 'roleplay_screen_generate',
//     ROLEPLAY_SCREEN_TITLE_UPDATED: 'roleplay_screen_title_updated',
//     ROLEPLAY_SCREEN_SCRIPT_GENERATED: 'roleplay_screen_script_generated',
//     ROLEPLAY_SCREEN_RESET: 'roleplay_screen_reset',
//     ROLEPLAY_SCREEN_REGENERATE: 'roleplay_screen_regenerate',
//     ROLEPLAY_SCREEN_AUDIO_PLAYBACK: 'roleplay_screen_audio_playback',
//     ROLEPLAY_SCREEN_CREATE_SCRENARIO: 'roleplay_screen_create_screnario',
//     ROLEPLAY_SCREEN_CREATE_CHALLENGE: 'roleplay_screen_create_challenge',
//     ROLEPLAY_SCREEN_SAVE_FILE: 'roleplay_screen_save_file',
//     ROLEPLAY_SCREEN_COPY_CLIPBOARD: 'roleplay_screen_copy_clipboard',
//     ROLEPLAY_SCREEN_INSTRUCTION_SENT_MANUALLY: 'roleplay_screen_instruction_sent_manually',
//     ROLEPLAY_SCREEN_INSTRUCTION_SENT_AUTO: 'roleplay_screen_instruction_sent_auto',
//     ROLEPLAY_SCREEN_DUPLICATE: 'roleplay_screen_duplicate',
//     ROLEPLAY_SCREEN_DISCLAIMER: 'roleplay_screen_disclaimer',
//     ROLEPLAY_SCREEN_GENERATE_ERROR: 'roleplay_screen_generate_error',
//   };

const useGoogleAnalyticsWithRoleplay = () => {
  const sendEvent = useGoogleAnalyticsEvents();
  const profile = useSelector(getProfileState);
  const company = useSelector(getCompanyState);

  const trackEventWithRoleplay = useCallback((action: string, additionalData?: AnalyticsInformation) => {
    const userInformation: AnalyticsInformation = {
      UserID:  profile?.id?.toString() || 'N/A',
      UserName: profile ? profile?.firstName + ' ' + profile?.lastName : 'N/A',
      Email:  profile?.email || 'N/A',
      CompanyID: company?.id || 0,
      CompanyName:  company?.name || 'N/A',
      Environment: process.env.REACT_APP_DEV_ENV?.toUpperCase() ?? 'DEV', 
      ...(additionalData || {})
    };

    const eventOptions: EventOptions = {
      category: 'Roleplay',
      action,
      userInformation,
    };

    sendEvent(eventOptions);
  }, [sendEvent, company, profile]);

  return trackEventWithRoleplay;
};

export default useGoogleAnalyticsWithRoleplay;
