import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import { SearchPacingsApiParams } from './types';

/**
 * @function useSearchPacingsApi
 * @returns { SearchPacingsApiParams }
 */
export const useSearchPacingsApi = (): SearchPacingsApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams => http.post('pacings/search', searchParams),
        [http]
    );
};

