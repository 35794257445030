import { useSelector } from 'react-redux';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../constants/interfaces/PractisSets';

type PortablePractisSetsBaseState = {
    data: ListResult<PractisSets>;
    loading: boolean;
};

export type PortablePractisSetsState = PortablePractisSetsBaseState;

export const initialState = {
    data: {
        items: [],
        limit: 0,
        offset: 0,
        count: 0,
        lastUpdated: undefined,
    },
    loading: false,
};

export const usePortablePractisSetsState = (): PortablePractisSetsBaseState => {
    const appState = useSelector(state => state);
    return appState.portablePractisSets;
};
