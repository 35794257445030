import axios from 'axios';

export function fileDownload(response: any, defaultFileName?: string) {
    const octetStreamMime = 'application/octet-stream';
    let fileName = defaultFileName || 'report.csv';
    let contentType = octetStreamMime;
    if (response.headers) {
        const cd = response.headers['content-disposition'];

        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const match: any = regex.exec(cd);

        contentType = response.headers['content-type'];
        fileName = match[1];
    }

    fileName = fileName.replace(/"/g, '');

    try {
        const blob = new Blob([response.data || response], {
            type: contentType,
        });

        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }

        return true;
    } catch (exc) {
        return false;
    }
}

/**
 * @function handleDownloadVideoFile
 * @param { string } url
 * @param  { string } fileName
 * @returns { Promise<void> }
 */
export async function handleDownloadVideoFile(
    url: string,
    fileName: string
): Promise<void> {
    await axios
        .get(url, {
            headers: {
                'Content-Type': 'video/mp4',
            },
            responseType: 'blob',
        })
        .then(response => {
            if (response.data) {
                const href = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute(
                    'download',
                    `${fileName.substring(0, 10)}.mp4`
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                URL.revokeObjectURL(href);
                link.remove();
            }
        });
}