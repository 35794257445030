import { useSelector } from 'react-redux';
import ChallengeScriptHeaderView from './view';
import { useContext, useState } from 'react';
import { ScriptLineSpeaker } from '../../../../constants/interfaces/ScriptLine';
import { getAudioState } from '../../../player/store/reducers';
import { useResetAudioService } from '../../../player/store/services';
import { useTextToSpeechBulkActionService } from '../../../textToSpeech/BulkActionService';
import { useVoiceGenerationState } from '../../../textToSpeech/store/states';
import { EditModeContext, PlayAudioContext } from '../../pages/EditChallenge';
import { useChallengeState } from '../../store/states';

function ChallengeScriptHeader() {
    const edit = useContext(EditModeContext);
    const [showChallengeSettings, setShowChallengeSettings] = useState(false);

    const player = useSelector(getAudioState);
    const challenge = useChallengeState().data;
    const resetPlaylist = useResetAudioService();
    const voice = useVoiceGenerationState().challenges;
    const playAudio = useContext(PlayAudioContext);
    const textToSpeechBulkAction = useTextToSpeechBulkActionService(
        challenge.script.lines.filter(line => line.speaker === ScriptLineSpeaker.CUSTOMER),
        'challenges'
    )

    return (
        <ChallengeScriptHeaderView
            challenge={challenge}
            player={player}
            generating={voice?.generating}
            showChallengeSettings={showChallengeSettings}
            editMode={edit.mode}
            stopPlaylist={resetPlaylist}
            playAudio={playAudio}
            textToSpeech={textToSpeechBulkAction}
            setShowChallengeSettings={setShowChallengeSettings}
        />
    );
}

export default ChallengeScriptHeader;