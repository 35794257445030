import {
    ChangeEventHandler,
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { isEmpty as lodashIsEmpty } from 'lodash';

import { useParams } from 'react-router';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { TeamsPageNavigation } from '../../components/TeamsPageNavigation';
import ROUTES from '../../../../routes/routes';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import {
    useTeamEditState,
    useTeamMembersState,
    useUpdateMembersState,
} from '../../store/states';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import {
    UpdatedLabelsState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import { Popup } from '../../../../ui/components/Popup';
import TeamMembersFilter from './components/Filters/TeamMembersFilter';
import { EnrollmentStatus } from '../../../../constants/interfaces/Enrollments';
import { WithLabelsContext } from '../../../portableLabels';
import {
    useGetMembersReportService,
    useMarkTeamAsViewedService,
    useNudgeUsersService,
    useSearchTeamMembersService,
    useDeleteMemberLabelService,
} from '../../store/services';
import { TeamMember } from '../../../../constants/interfaces/TeamMember';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import { pushModal, useHistory } from '../../../../tools/router';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { TeamV2 } from '../../../../constants/interfaces/Team';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { UserProfile } from '../../../../constants/interfaces/User';
import { getPageUrl } from '../../../../routes/helpers';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { countAppliedFiltersCount } from './tools';
import {
    isCompanyAdminRole,
    isPractisAdminRole,
} from '../../../../constants/enums';
import NoTrainingsFiltering from '../../../../ui/icons/NoTrainingsFiltering';
import NoMembers from '../../../../ui/icons/NoMembers';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import TeamMembersIcon from '../../../../ui/icons/TeamMembersIcon';
import { normalizeSP } from '../../../../helpers/functions/searchparams-normalize';
import { getSearchState } from '../../../../features/searchState/store/reducer';
import { SearchStateInterface } from '../../../../features/searchState/store/states';
import { SEARCH_STATE } from '../../../../features/searchState/constants';
import { isEmpty } from '../../../../helpers/functions/object-helpers';
import { WithPractisSetsContext } from '../../../../features/portablePractisSets';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { Tooltip } from '../../../../ui/components/Tooltip';
import OverdueFlag from '../../../../ui/icons/OverdueFlag';
import { TableCell } from '../../../../ui/components/table-wrapper/table/table-cell';
import dayjs from 'dayjs';
import ListActions from './components/ListActions/ListActions';
import { EmptyCellDash } from '../../../../ui/components/table-wrapper/table/EmptyCellDash';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { NudgeUser } from '../../../../ui/components/table-wrapper/table/TableAssignOptionsMenu/components/NudgeUser';
import {
    Info,
    NameAdditionalInfo,
    StyledAvatarPlaceholder,
    StyledCoronaIcon,
    useStyles,
    WrapperTableCell,
} from './styles';
import { secondsToMinSimple } from '../../../../helpers/functions/convert-time';
import { Subheader } from '../../components/SubHeader';
import {
    onListenEvent,
    onRemoveEvent,
    onTriggerEvent,
} from '../../../../helpers/functions/Events';
import {
    EventNameList,
    REFRESH_TEAM_CONTROLLER,
    REFRESH_TEAM_MEMBERS,
} from '../../../../helpers/functions/Events/types';
import {
    useDeleteMembersFromTeamService,
    useUpdateTeamMembersBulkActionService,
} from '../../services/TeamBulkActionsService';
import { clearTeamUpdateStatus } from '../../store/actions';
import { NudgeData } from '../../../../api/alert/types';
import { useNudgeUsersBulkActionService } from '../../../../services/GeneralBulkActionServices';
import { UPDATE_TEAM_ACTION } from '../../services/TeamBulkActionsService/constants';
import { UserStatus } from '../../../users/store/costants';
import { TextEllipsis } from '../../../../ui/generalStyles/TextStyles';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const TeamMembers: FC<{
    profile?: UserProfile;
    searchHandler: (sp: SearchParams) => void;
    team?: TeamV2;
    members: ListResult<TeamMember>;
    itemsOnPage: number;
    loading: boolean;
    company?: CompanyInterface;
    params: { teamId?: string };
    updatedMembers?: TeamMember;
    teamUpdateState?: Partial<TeamV2>;
    getMembersReportService?(
        userIds: number[] | null,
        teamId: string,
        searchParams: SearchParams
    ): void;
    onNudgeMembers(
        nudgeUsersData: NudgeData[],
        successCallback?: () => void
    ): void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    updatedLabels?: UpdatedLabelsState;
    selectedLabels: number[];
    assignedLabels?: number[];
    setAssignLabelsAction(labels: number[]): void;
    markTeamAsViewed(teamId: any): void;
    deleteMemberLabel: (userId: number, labelId: number) => void;
    searchState: SearchStateInterface;
    locationState: any;
}> = ({
    profile,
    members,
    team,
    itemsOnPage,
    loading,
    selectedLabels,
    searchHandler,
    company,
    params,
    updatedMembers,
    teamUpdateState,
    getMembersReportService,
    onNudgeMembers,
    saveGlobalLabelFilters,
    updatedLabels,
    markTeamAsViewed,
    deleteMemberLabel,
    searchState,
    locationState,
}) => {
    const deleteMembersFromTeamService = useDeleteMembersFromTeamService();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [lastRefreshedDate, setLastRefreshedDate] = useState(new Date());
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [showNudgeDialogMemberId, setShowNudgeDialogMemberId] = useState<
        number | null
    >(null);
    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const canRemoveTeamLeader =
        isPractisAdminRole(profile?.role?.name) ||
        isCompanyAdminRole(profile?.role?.name);

    const canNudgeUser = useCallback(
        (member: TeamMember) => member.userStatus === UserStatus.ACTIVE,
        []
    );

    const bulkRemoveDisabled =
        members &&
        members.items
            .filter(member => member.isTeamLead)
            .some(member => selectedUsers.includes(member.user.id)) &&
        !canRemoveTeamLeader;

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        labelIDs: selectedLabels,
        orderBy: null,
        limit: itemsOnPage,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
        filterByStatus: [],
    };

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState.key === SEARCH_STATE.MEMBERS.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        setCustom,
        refreshSearchParams,
        setFilter,
    } = useSearchParamsState(usableParams);
    const ifSearchParamsChanged = useIfChanged(searchParams);
    const ifCompanyChanged = useIfChanged(company && company.id, true);
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const orderBy = searchParams.orderBy;
    const hcTeamMembers = useOrderBy<TeamMember>('name', orderBy, setOrderBy);
    const hcTrainingTime = useOrderBy<TeamMember>(
        'training_time',
        orderBy,
        setOrderBy
    );
    const hcTraining = useOrderBy<TeamMember>(
        'last_training',
        orderBy,
        setOrderBy
    );

    const hcCompletedEnrollmentCount = useOrderBy<TeamMember>(
        'enrollment_completed',
        orderBy,
        setOrderBy
    );
    const hcProgressEnrollmentCount = useOrderBy<TeamMember>(
        'enrollment_in_progress',
        orderBy,
        setOrderBy
    );
    const hcPendingEnrollmentCount = useOrderBy<TeamMember>(
        'enrollment_not_started',
        orderBy,
        setOrderBy
    );

    const hcAccuracyAvg = useOrderBy('accuracy', orderBy, setOrderBy);
    const hcOverdue = useOrderBy('overdue', orderBy, setOrderBy);

    const refreshHandler = () => {
        setLastRefreshedDate(new Date());
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchHandler(searchParams);
        setSelectedUsers([]);
        setIsSelectAll(false);
    };

    const selectAllHandler: ChangeEventHandler<HTMLInputElement> = e => {
        if (
            members.count < ITEMS_PER_PAGE &&
            e.target.checked === true
        ) {
            setIsSelectAll(true);
        } else {
            setIsSelectAll(false);
        }

        if (e.target.checked || isCheckboxPartiallySelected) {
            setSelectedUsers(members.items.map(item => item.user.id));
        } else {
            setSelectedUsers([]);
        }
    };

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const filterStatusHandler = (status: EnrollmentStatus[]) => {
        setCustom('filterByStatus', status);
    };

    const filterOverdueHandler = (hasOverdue: boolean) => {
        setFilter({
            field: 'hasOverdue',
            value: hasOverdue,
        });
    };

    const registrationStatusHandler = (values: string[]) => {
        setCustom('filterByRegistrationStatus', values);
    };

    const isTableHeadersDisabled =
        !members || (members && !members.items.length);

    const selectUserHandler = useCallback(
        (id: number, state: boolean) => {
            if (state) {
                setSelectedUsers(prevState => [...prevState, id]);
            } else {
                setSelectedUsers(prevState =>
                    prevState.filter(item => item !== id)
                );
            }
            setIsSelectAll(false);
        },
        [setSelectedUsers]
    );

    const handleManageTeam = useCallback(() => {
        if (!team?.id || team?.isDefault === undefined) {
            return;
        }
        if (params && params.teamId) {
            pushModal(
                history,
                ROUTES.TEAMS_PAGES.MANAGE_TEAM.replace(
                    ':teamId',
                    team.id.toString()
                ),
                { isDefaultTeam: team?.isDefault }
            );
        }
    }, [history, params, team]);

    const onNudgeMultipleUsersSuccessCallback = useCallback(() => {
        onClearSelection();
    }, []);

    const nudgeUsersBulkActionService = useNudgeUsersBulkActionService(
        'common',
        {
            companyId: company?.id ?? 0,
        },
        searchParams,
        members?.count ?? 0,
        refreshHandler
    );

    const handleNudgeMultipleUsers = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;

            if (isSelectAll) {
                nudgeUsersBulkActionService({ text, senderName });
            } else {
                if (selectedUsers) {
                    const nudgeUsersDate = selectedUsers.map(
                        user =>
                            ({
                                type: 'common',
                                receiverId: user,
                                enrollmentId: null,
                                senderName,
                                text,
                            } as NudgeData)
                    );

                    onNudgeMembers(
                        nudgeUsersDate,
                        onNudgeMultipleUsersSuccessCallback
                    );
                }
            }
        },
        [
            isSelectAll,
            nudgeUsersBulkActionService,
            selectedUsers,
            onNudgeMembers,
            onNudgeMultipleUsersSuccessCallback,
        ]
    );

    const onSinglePractisExportClicked = useCallback(
        (memberId: any) => {
            if (
                getMembersReportService &&
                params &&
                params.teamId &&
                memberId
            ) {
                getMembersReportService(
                    [memberId],
                    params.teamId,
                    searchParams
                );
            }
        },
        [getMembersReportService, params, searchParams]
    );

    const onNudgeSingleUserSuccessCallback = () => {
        setShowNudgeDialogMemberId(null);
    };

    const handleNudgeSingleUser = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;
            if (showNudgeDialogMemberId) {
                const nudgeUserDate: NudgeData = {
                    type: 'common',
                    enrollmentId: null,
                    receiverId: showNudgeDialogMemberId,
                    text,
                    senderName,
                };

                onNudgeMembers(
                    [nudgeUserDate],
                    onNudgeSingleUserSuccessCallback
                );
            }
        },
        [onNudgeMembers, showNudgeDialogMemberId]
    );

    const onExportClicked = useCallback(() => {
        if (
            selectedUsers &&
            selectedUsers.length &&
            getMembersReportService &&
            params &&
            params.teamId
        ) {
            getMembersReportService(
                !isSelectAll ? selectedUsers : null,
                params.teamId,
                isSelectAll
                    ? normalizeSP(searchParams, members.count)
                    : searchParams
            );
        }
    }, [
        selectedUsers,
        getMembersReportService,
        params,
        isSelectAll,
        searchParams,
        members.count,
    ]);

    /**
     * @function onDeleteTeamMembersSuccessCallback
     * @returns { void }
     */
    const onDeleteTeamMembersSuccessCallback = (): void => {
        Promise.resolve().then(() => {
            setSelectedUsers([]);
            refreshHandler();
            dispatch(clearTeamUpdateStatus());
            onTriggerEvent(EventNameList[REFRESH_TEAM_CONTROLLER]);
        });
    };

    /**
     * @function handleDeleteTeamMembersSingleAction
     * @param { number[] | undefined } selectedUserIds
     * @returns { void }
     */
    const handleDeleteTeamMembersSingleAction = (
        selectedUserIds?: number[]
    ): void => {
        const { teamId = null } = params!;
        if (!isEmpty(selectedUserIds) && teamId) {
            deleteMembersFromTeamService(
                +teamId,
                undefined,
                selectedUserIds
            ).then(() => {
                onDeleteTeamMembersSuccessCallback();
            });
        }
    };

    const deleteMembersFromTeamServiceOptions = {
        parameters: {
            teamId: +(params.teamId ?? 0),
            searchParams: {
                ...searchParams,
                limit: ITEMS_PER_PAGE,
                offset: isSelectAll ? members?.count : selectedUsers.length,
            },
            selectedItemIds: !isSelectAll ? selectedUsers : undefined,
            itemsCount: selectedUsers?.length ?? 0,
        },
        fieldName: !isSelectAll ? 'selectedItemIds' : 'searchParams.offset',
    };

    const {
        handleStartBulkActionService:
            handleStartDeleteMembersFromTeamBulkAction,
    } = useUpdateTeamMembersBulkActionService(
        {
            actionName: UPDATE_TEAM_ACTION,
            actionFunction: deleteMembersFromTeamService,
            actionFunctionOptions: deleteMembersFromTeamServiceOptions,
        },
        'Removing Users',
        onDeleteTeamMembersSuccessCallback
    );

    /**
     * @function handleDeleteTeamMembers
     * @param { number[] } ids
     * @returns { void }
     */
    const handleDeleteTeamMembers = (ids: number[]): void => {
        // we should start chunk service.
        if (isSelectAll) {
            handleStartDeleteMembersFromTeamBulkAction();
        } else {
            showConfirmationModalDialog({
                modalTitle: 'Warning',
                description:
                    'You will remove the selected member(s) from the team. This action cannot be undone. Are you sure?',
                cancelButtonText: 'Go Back',
                confirmButtonText: 'Proceed',
                dialogType: 'DANGER',
                onConfirm: () => handleDeleteTeamMembersSingleAction(ids),
            });
        }
    };

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countAppliedFiltersCount(searchParams),
        itemsCount: members?.items.length || 0,
    });

    useEffect(() => {
        if (ifSearchParamsChanged) {
            searchParams.limit = ITEMS_PER_PAGE;
            searchParams.offset = initialOffset;
            searchHandler(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchHandler, searchParams, ifSearchParamsChanged]);

    useEffect(() => {
        if (ifCompanyChanged) {
            history.push(ROUTES.TEAMS);
        }
    }, [ifCompanyChanged, history]);

    useEffect(() => {
        if (updatedMembers || teamUpdateState) refreshSearchParams();
    }, [updatedMembers, teamUpdateState, refreshSearchParams]);

    useEffect(() => {
        if (selectedUsers.length) {
            setSelectedUsers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.orderBy]);

    useEffect(() => {
        if (team && params && params.teamId) {
            const leaders = team.leaders;

            if (!leaders || (leaders && !leaders.length)) {
                return;
            } else {
                const leader = leaders.find(
                    item => item.user.id === profile?.id
                );
                if (!leader) return;

                if (!(leader && leader.isViewed)) {
                    markTeamAsViewed(parseInt(params.teamId));
                }
            }
        }
    }, [team, params.teamId, profile, markTeamAsViewed, params]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        if (selectedUsers?.length === members?.count) {
            setIsSelectAll(true);
        }
    }, [members?.count, selectedUsers]);

    useEffect(() => {
        return () => setSelectedUsers([]);
    }, []);

    useEffect(() => {
        Promise.resolve().then(() => {
            setSelectedUsers([]);
            setIsSelectAll(false);
        });
    }, [members]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 300);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useEffect(() => {
        onListenEvent(EventNameList[REFRESH_TEAM_MEMBERS], refreshHandler);

        return () => {
            onRemoveEvent(EventNameList[REFRESH_TEAM_MEMBERS], refreshHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isListEmpty = !members || (members && !members.items.length);

    const isFilteredByOverdue = searchParams.filters?.some(
        item => item.field === 'hasOverdue' && item.value === true
    );

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (offset: number) => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        setIsSelectAll(false);
        setSelectedUsers([]);
    };

    /**
     * @function handleAssignedLabelDelete
     * @param { number } labelId
     * @param { TeamMember } member
     * @returns { void }
     */
    const handleAssignedLabelDelete = (
        labelId: number,
        member: TeamMember
    ): void => {
        deleteMemberLabel(member.user.id, labelId);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        setSelectedUsers(members.items.map(item => item.user.id));
        setIsSelectAll(true);
    };

    const isMasterCheckBoxChecked = !!selectedUsers.length;

    const isMasterCheckBoxDisabled = !members.items.length;

    const isCheckboxPartiallySelected =
        selectedUsers && selectedUsers.length < members.items.length;

    /**
     * @function viewProfileHandler
     * @param { TeamMember } member
     * @returns { void }
     */
    const viewProfileHandler = useCallback(
        (member: TeamMember) => {
            history.push(
                getPageUrl(ROUTES.USER_PERFORMANCE, {
                    userId: member.user.id.toString(),
                }),
                { useGoBack: true }
            );
        },
        [history]
    );

    /**
     * @function checkIsRowChecked
     * @param { TeamMember } member
     * @returns { boolean }
     */
    const checkIsRowChecked = (member: TeamMember): boolean =>
        selectedUsers.includes(member.user.id) || isSelectAll;

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { TeamMember } member
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, member: TeamMember) => {
        selectUserHandler(member.user.id, event?.target?.checked);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { TeamMember } member
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (member: TeamMember): boolean => {
        return labelsOpen === member.user.id;
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { TeamMember } member
     * @returns { void }
     */
    const onDeleteLabelHandler = (
        labelId: number,
        member: TeamMember
    ): void => {
        handleAssignedLabelDelete(labelId, member);
    };

    /**
     * @function getAdditionalMemberInfo
     * @returns { ReactNode | null }
     */
    const getAdditionalMemberInfo = useCallback(
        (memberId: number) => {
            if (profile?.id === memberId) {
                return <NameAdditionalInfo>{` (You)`}</NameAdditionalInfo>;
            }

            return null;
        },
        [profile]
    );

    /**
     * @function getFormattedLastTrainingAt
     * @param { Date } lastTrainingAt
     * @returns { string }
     */
    const getFormattedLastTrainingAt = (lastTrainingAt: Date): string => {
        if (!lastTrainingAt) {
            return '';
        }

        const date = dayjs(lastTrainingAt);
        return date.isBefore(Date.now(), 'day')
            ? date.format('M/D/YY')
            : date.format('h:mm A');
    };

    /**
     * @function renderTeamLeadInfo
     * @returns { ReactNode }
     */
    const renderTeamLeadInfo = (): ReactNode => {
        return (
            <Tooltip
                label="User is a team leader"
                position="bottom-start"
                offset={{
                    y: '-2px',
                    x: '4px',
                }}
            >
                <StyledCoronaIcon />
            </Tooltip>
        );
    };

    /**
     * @function handleToggleLabels
     * @param { number } itemId
     * @returns { void }
     */
    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    return (
        <>
            <MainWrapper
                subTitle={team && team.name ? team.name : ''}
                tabs={<TeamsPageNavigation teamName={team?.name} />}
                subHeader={
                    <Subheader
                        practisSetsCount={team?.countPractisSets}
                        usersCount={team?.countMembers}
                        teamLeadersCount={team?.leaders?.length}
                    />
                }
                goBackUrl={ROUTES.TEAMS_PAGES.LIST_OF_TEAMS}
                title="Team"
                useGoBack={locationState?.useGoBack}
                htmlPageTitle={`Teams ${
                    team ? `- ${team.name}` : ''
                } - Practis`}
                dataTest="team"
            >
                {!!showNudgeDialogMemberId && (
                    <DialogWrapper
                        customization={{
                            width: 480,
                            padding: '49px 32px 32px 32px',
                        }}
                    >
                        <NudgeUser
                            fromUser={
                                !!profile
                                    ? `${profile.firstName} ${profile.lastName}`
                                    : ''
                            }
                            onSend={handleNudgeSingleUser}
                            onClose={() => setShowNudgeDialogMemberId(null)}
                        />
                    </DialogWrapper>
                )}
                <TableWrapper
                    tableStates={tableStates}
                    data={members?.items}
                    selectedLabels={selectedLabels}
                    cornered={selectedLabels && !!selectedLabels.length}
                    tableRefreshConfigurations={{
                        lastRefreshed: lastRefreshedDate,
                        refreshData: refreshHandler,
                        dataTest: "team-timestamp",
                        buttons: [
                            {
                                name: 'Manage Team',
                                icon: <TeamMembersIcon />,
                                onPress: handleManageTeam,
                                disabled: !team?.id,
                                dataTest: 'manage-team',
                            },
                        ],
                    }}
                    tableToolsOptions={{
                        pagingOptions: {
                            totalCount: members?.count ?? 0,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onPageChange: handlePageChange,
                            searchOrFiltersApplied:
                                searchParams.searchTerm.length ||
                                countAppliedFiltersCount(searchParams),
                            dataTest: "team-paging",
                        },
                        searchInputOptions: {
                            initialValue: searchParams.searchTerm,
                            onSearchChange: setSearchTerm,
                            isSearchInputDisabled:
                                isListEmpty && !searchParams.searchTerm.length,
                            dataTest: "team-search",
                        },
                        isSelectedItemOptionsVisible:
                            !lodashIsEmpty(selectedUsers) &&
                            !lodashIsEmpty(members),
                        selectedItemOptions: {
                            isSelectAll: isSelectAll,
                            selectedLength: selectedUsers?.length,
                            totalCount: members?.count,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onClearSelection: onClearSelection,
                            onSelectAll: onSelectAll,
                            bulkActionsConfig: {
                                disabled: selectedUsers.length === 0,
                                showNudgeWarning: false,
                                assignPractisSetsAndDueDatePermissions: [
                                    NEW_PERMISSIONS.ASSIGN_ENROLLMENT,
                                ],
                                onNudgeSendButtonClicked:
                                    handleNudgeMultipleUsers,
                                sendNudgePermissions: [
                                    NEW_PERMISSIONS.SEND_NUDGE,
                                ],
                                onExportClicked: onExportClicked,
                                showExportPermissions: [
                                    NEW_PERMISSIONS.EXPORT_LIST_USER,
                                ],
                                removeItemPermissions: !team?.isDefault
                                    ? [NEW_PERMISSIONS.REMOVE_TEAM_MEMBER]
                                    : undefined,
                                removeItemTitle: 'Remove From Team',
                                onRemoveItemsSubmit: () =>
                                    handleDeleteTeamMembers(selectedUsers),
                                removeItemsDisabled: bulkRemoveDisabled,
                                removeItemTooltip:
                                    'Team leaders can’t be removed from team',
                                isSelectAll: isSelectAll,
                                totalCount: members?.count,
                                searchQuery: {
                                    query: !lodashIsEmpty(
                                        searchParams?.searchTerm
                                    )
                                        ? searchParams.searchTerm
                                        : null,
                                    teamId: team?.id,
                                    labelId: !lodashIsEmpty(
                                        searchParams?.labelIDs
                                    )
                                        ? searchParams.labelIDs
                                        : null,
                                    status: !lodashIsEmpty(
                                        searchParams?.filterByRegistrationStatus
                                    )
                                        ? searchParams.filterByRegistrationStatus
                                        : null,
                                    trainingStatus: !lodashIsEmpty(
                                        searchParams?.filterByStatus
                                    )
                                        ? searchParams.filterByStatus
                                        : null,
                                    hasOverdue: isFilteredByOverdue,
                                },
                                selectedItems: selectedUsers,
                                successApplyPractisSetsCallback: refreshHandler,
                            },
                        },
                        filterOptions: {
                            filterComponent: (
                                <Popup<HTMLButtonElement>
                                    content={({ hide }) => (
                                        <TeamMembersFilter
                                            preSelectedLabels={
                                                selectedLabels || []
                                            }
                                            onStatusChange={filterStatusHandler}
                                            onRegistrationStatusChange={
                                                registrationStatusHandler
                                            }
                                            status={
                                                searchParams.filterByStatus as EnrollmentStatus[]
                                            }
                                            registrationStatus={
                                                searchParams.filterByRegistrationStatus ||
                                                []
                                            }
                                            onOverdueChange={
                                                filterOverdueHandler
                                            }
                                            onLabelsChange={labelChangeHandler}
                                            onSuccessApply={() => hide()}
                                            overdue={isFilteredByOverdue}
                                        />
                                    )}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    horizontalOffset={256}
                                >
                                    {(ref, { toggleShown, shown }) => (
                                        <FilterByHandler
                                            ref={ref}
                                            open={shown}
                                            toggleOpen={toggleShown}
                                            filtersCount={countAppliedFiltersCount(
                                                searchParams
                                            )}
                                            disabled={
                                                (searchParams.searchTerm
                                                    .length > 0 ||
                                                    countAppliedFiltersCount(
                                                        searchParams
                                                    ) === 0) &&
                                                isListEmpty
                                            }
                                            dataTest="team-filters"
                                        />
                                    )}
                                </Popup>
                            ),
                        },
                    }}
                    tableEmptyStateConfigurations={{
                        shouldShowEmptyState:
                            !loading && members?.items?.length === 0,
                        noEntriesOptions: {
                            icon: NoMembers,
                            text: 'No Members Yet',
                            dataTest: 'no-members-yet',
                        },
                        noSearchResultsOptions: {
                            entityName: 'Members',
                            dataTest: 'no-members-found',
                        },
                        noFilterResultsOptions: {
                            icon: NoTrainingsFiltering,
                            width: '160px',
                            dataTest: 'no-filtering-criteria',
                        },
                    }}
                    configurations={{
                        masterCheckBoxConfig: {
                            checked: isMasterCheckBoxChecked,
                            disabled: isMasterCheckBoxDisabled,
                            partial:
                                isCheckboxPartiallySelected || !isSelectAll,
                            handleCheck: selectAllHandler,
                            dataTest: "members-master-checkbox"
                        },
                        columns: [
                            {
                                title: 'Team Members',
                                width: 18,
                                ...(isTableHeadersDisabled
                                    ? null
                                    : hcTeamMembers),
                                disabled: isTableHeadersDisabled,
                                dataTest: 'team-members-column',
                            },
                            {
                                title: 'Overdue',
                                width: 1,
                                ...(isTableHeadersDisabled ? null : hcOverdue),
                                disabled: isTableHeadersDisabled,
                                withTooltip: true,
                                dataTest: 'overdue-column',
                            },
                            {
                                title: 'Practis Sets Status',
                                width: 30,
                                dataTest: 'practis-set-status-column',
                                ...(isTableHeadersDisabled
                                    ? null
                                    : hcAccuracyAvg),
                                disabled: isTableHeadersDisabled,
                                titleWidth: 70,
                                multiHeaderContainerClassName:
                                    classes.multiHeaderContainer,
                                subColumns: [
                                    {
                                        title: 'Not Started',
                                        width: 33,
                                        ...(isListEmpty
                                            ? null
                                            : hcPendingEnrollmentCount),
                                        disabled: isListEmpty,
                                        dataTest: 'not-started-column',
                                    },
                                    {
                                        title: 'In Progress',
                                        width: 33,
                                        ...(isListEmpty
                                            ? null
                                            : hcProgressEnrollmentCount),
                                        disabled: isListEmpty,
                                        dataTest: 'in-progress-column',
                                    },
                                    {
                                        title: 'Completed',
                                        width: 33,
                                        ...(isListEmpty
                                            ? null
                                            : hcCompletedEnrollmentCount),
                                        disabled: isListEmpty,
                                        dataTest: 'completed-column',
                                    },
                                ],
                            },
                            {
                                title: 'Accuracy',
                                width: 10,
                                ...(isTableHeadersDisabled
                                    ? null
                                    : hcAccuracyAvg),
                                disabled: isTableHeadersDisabled,
                                withTooltip: true,
                                dataTest: 'accuracy-column',
                                tooltipText:
                                    'The average of the accuracy scores for all submitted scenario accuracy tests.',
                            },
                            {
                                title: 'Training Time',
                                width: 10,
                                ...(isTableHeadersDisabled
                                    ? null
                                    : hcTrainingTime),
                                disabled: isTableHeadersDisabled,
                                withTooltip: true,
                                dataTest: 'training-time-column',
                                tooltipText:
                                    'The total time spent by a user learning material.',
                            },
                            {
                                title: 'Last Training',
                                width: 10,
                                ...(isTableHeadersDisabled ? null : hcTraining),
                                disabled: isTableHeadersDisabled,
                                dataTest: 'last-training-column',
                            },
                            {
                                width: 9,
                            },
                            {
                                width: 3,
                            },
                        ],
                        rowConfig: {
                            onRowClick: viewProfileHandler,
                            onRowCheckHandler: onRowCheckHandler,
                            isRowChecked: checkIsRowChecked,
                            isLabelTagsOpen: checkIsLabelTagsOpen,
                            getLabelTagsProps: (member: TeamMember) => ({
                                selectedLabels: member.labels || [],
                                deletePermissions: [
                                    NEW_PERMISSIONS.REMOVE_USER_LABEL,
                                ],
                                onDeleteLabel: (labelId: any) =>
                                    onDeleteLabelHandler(labelId, member),
                            }),
                            dataTest: 'member-item',
                            cells: [
                                {
                                    fieldType: 'CUSTOM_FIELD',
                                    getCustomFieldComponent: (
                                        member: TeamMember
                                    ) => (
                                        <Info>
                                            <StyledAvatarPlaceholder
                                                customProfile={{
                                                    firstName:
                                                        member.user.firstName,
                                                    lastName:
                                                        member.user.lastName,
                                                    picture:
                                                        member.user.imageUrl,
                                                    status: member.userStatus,
                                                }}
                                                size="sm"
                                                dataTest="member-avatar"
                                            />
                                            <TextEllipsis
                                                title={`${member.user.firstName} ${member.user.lastName}`}
                                                data-test="member-name"
                                            >{`${member.user.firstName} ${member.user.lastName}`}</TextEllipsis>
                                            {getAdditionalMemberInfo(
                                                member.user.id
                                            )}
                                            {member?.isTeamLead &&
                                                renderTeamLeadInfo()}
                                        </Info>
                                    ),
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (member: TeamMember) =>
                                        !member.isOverdue,
                                    fieldProps: {
                                        renderTitle: (member: TeamMember) =>
                                            member.isOverdue && <span data-test="overdue-icon"><OverdueFlag /></span>,
                                    },
                                },
                                {
                                    fieldType: 'CUSTOM_FIELD',
                                    getCustomFieldComponent: (
                                        member: TeamMember
                                    ) => (
                                        <WrapperTableCell>
                                            <TableCell width={33}>
                                                {member.enrollmentNotStarted ? (
                                                    <span data-test="not-started-count">
                                                        {
                                                            member.enrollmentNotStarted
                                                        }
                                                    </span>
                                                ) : (
                                                    <EmptyCellDash />
                                                )}
                                            </TableCell>
                                            <TableCell width={33}>
                                                {member.enrollmentEnrolled ? (
                                                    <span data-test="in-progress-count">
                                                        {
                                                            member.enrollmentEnrolled
                                                        }
                                                    </span>
                                                ) : (
                                                    <EmptyCellDash />
                                                )}
                                            </TableCell>
                                            <TableCell width={33}>
                                                {member.enrollmentCompleted ? (
                                                    <span data-test="completed-count">
                                                        {
                                                            member.enrollmentCompleted
                                                        }
                                                    </span>
                                                ) : (
                                                    <EmptyCellDash />
                                                )}
                                            </TableCell>
                                        </WrapperTableCell>
                                    ),
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (member: TeamMember) =>
                                        !member.accuracy,
                                    fieldProps: {
                                        renderTitle: (member: TeamMember) =>
                                            member.accuracy &&
                                            `${Math.floor(
                                                member.accuracy * 100
                                            )}%`,
                                        dataTest: 'accuracy-value',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (member: TeamMember) =>
                                        !member?.trainingTime,
                                    fieldProps: {
                                        renderTitle: (member: TeamMember) =>
                                            member?.trainingTime &&
                                            secondsToMinSimple(
                                                member.trainingTime
                                            ),
                                        dataTest: 'training-time',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (member: TeamMember) =>
                                        !member?.lastTraining,
                                    fieldProps: {
                                        renderTitle: (member: TeamMember) =>
                                            member?.lastTraining &&
                                            getFormattedLastTrainingAt(
                                                member.lastTraining
                                            ),
                                        dataTest: 'last-training-date'
                                    },
                                },
                                {
                                    fieldType: 'LABEL_TAGS',
                                    fieldProps: {
                                        getLabelTagsProps: (
                                            member: TeamMember
                                        ) => ({
                                            selectedLabels: member.labels || [],
                                            open: labelsOpen === member.user.id,
                                            toggleOpen: () =>
                                                handleToggleLabels(
                                                    member.user.id
                                                ),
                                            dataTest: "member-tags",
                                        }),
                                        className: classes.labelsCellStyle,
                                    },
                                },
                                {
                                    fieldType: 'LIST_ACTIONS',
                                    fieldProps: {
                                        getListActionsComponent: (
                                            member: TeamMember
                                        ) => (
                                            <WithLabelsContext.Provider
                                                value={{
                                                    reducerName: 'teamMembers',
                                                    scope: 'assignLabels',
                                                }}
                                            >
                                                <ListActions
                                                    member={member}
                                                    viewProfileHandler={() =>
                                                        viewProfileHandler(
                                                            member
                                                        )
                                                    }
                                                    onSendNudge={() => {
                                                        setShowNudgeDialogMemberId(
                                                            member.user.id
                                                        );
                                                    }}
                                                    getMembersReportService={() =>
                                                        onSinglePractisExportClicked(
                                                            member.user.id
                                                        )
                                                    }
                                                    onDeleteMember={() => {
                                                        handleDeleteTeamMembers(
                                                            [member.user.id]
                                                        );
                                                    }}
                                                    canRemoveTeamLeader={
                                                        canRemoveTeamLeader
                                                    }
                                                    canNudgeUser={canNudgeUser(
                                                        member
                                                    )}
                                                    isDefaultTeam={
                                                        team?.isDefault
                                                    }
                                                    isHidden={
                                                        !!selectedUsers.length
                                                    }
                                                    successApplyPractisSetsCallback={
                                                        refreshHandler
                                                    }
                                                />
                                            </WithLabelsContext.Provider>
                                        ),
                                    },
                                },
                            ],
                        },
                    }}
                />
            </MainWrapper>
            {loading && !isSelectAll && <LoadingComponent />}
        </>
    );
};

const TeamMembersContainer: FC = () => {
    const profile = useSelector(getProfileState);
    const params: { teamId: string } = useParams();
    const {
        itemsOnPage,
        list,
        data: teamData,
        loading,
    } = useTeamMembersState();
    const updatedMembersState = useUpdateMembersState();
    const teamUpdateState = useTeamEditState().updatedTeam;
    const labels = useLabelsState();
    const teamId = params.teamId;
    const searchTeamMembers = useSearchTeamMembersService(teamId);
    const searchTeamMembersDebounced = useSearchDebounced(searchTeamMembers);
    const company = useSelector(getCompanyState);
    const getMembersReportService = useGetMembersReportService();
    const nudgeUsers = useNudgeUsersService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const updatedLabels = useUpdatedLabelsState();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const markTeamAsViewedService = useMarkTeamAsViewedService();
    const deleteMemberLabel = useDeleteMemberLabelService(teamId);
    const searchState = useSelector(getSearchState);
    const history = useHistory();
    const locationState: any = history.location.state;

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'teamMembers',
            }}
        >
            <WithPractisSetsContext.Provider
                value={{
                    reducerName: 'performance',
                }}
            >
                <TeamMembers
                    profile={profile}
                    members={list}
                    team={teamData}
                    itemsOnPage={itemsOnPage}
                    loading={loading}
                    selectedLabels={labels.selected}
                    searchHandler={searchTeamMembersDebounced}
                    company={company}
                    params={params}
                    updatedMembers={updatedMembersState.data}
                    teamUpdateState={teamUpdateState}
                    getMembersReportService={getMembersReportService}
                    onNudgeMembers={nudgeUsers}
                    saveGlobalLabelFilters={saveGlobalLabelFilters}
                    updatedLabels={updatedLabels}
                    setAssignLabelsAction={setAssignLabelsAction}
                    assignedLabels={labels.assignedLabels}
                    markTeamAsViewed={markTeamAsViewedService}
                    deleteMemberLabel={deleteMemberLabel}
                    searchState={searchState}
                    locationState={locationState}
                />
            </WithPractisSetsContext.Provider>
        </WithLabelsContext.Provider>
    );
};

export { TeamMembersContainer };
