import AIParamTextbox from "../AIParamTextbox/controller"
import { AiParamsProps } from "./types"

function ProspectAiParam({ handleChange, roleplayData, isEditMode, emptyRequiredProps, autoFocus, analyticsKey }: AiParamsProps) {
    return <AIParamTextbox title="Describe the customer's profile and characteristics" tooltip="Provide a detailed description of the customer, including their personality, behavior, and any relevant background information, so AI can tailor the Roleplay to realistic Customer responses."
            isRequired={true}
            isEditMode={isEditMode}
            analyticsKey={`${analyticsKey}_customer`}
            placeholder="For example: A middle-aged homeowner passionate about gardening, yet lacking the time and expertise for regular maintenance. They are friendly but are constrained by a tight budget. The homeowner is cautious in decision-making and prefers cost-effective, proven solutions for yard and garden maintenance, showing some resistance to change and innovation."
            onChange={(val) => handleChange('prospect', val)}
            isInvalid={emptyRequiredProps?.includes('prospect')}
            value={roleplayData?.prospect} autoFocus={autoFocus} dataTest="prospect"
        />
    
}

export default ProspectAiParam