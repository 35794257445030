import { ElevenLabsUrl } from './constants';
import { getConfig } from "../../tools/config";
import { GenerateAudioType, GetVoicesResult } from './types';
import { ErrorResult } from '../../constants/interfaces/ErrorResult';

export const generateAudioWithElevenLabs = async ({text, stability, voiceId, similarityBoost, style}: GenerateAudioType) => {
    const config = getConfig();

    const res = await fetch(ElevenLabsUrl + `/text-to-speech/${voiceId || 'EXAVITQu4vr4xnSDxMaL'}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'xi-api-key': config.ELEVEN_LABS_KEY,
            'accept': 'audio/mpeg'
        },
        body: JSON.stringify({
            text,
            "voice_settings": {
                "stability": stability,
                "similarity_boost": similarityBoost,
                "style": style
            }
        }),
    });

    if (!res.ok) {
        return Promise.reject({
            code: res.status,
            status: res.statusText
        } as ErrorResult);
    }

    return await res.blob();
};

export const getElevenLabsVoices = async () => {
    const config = getConfig();

    const res = await fetch(ElevenLabsUrl + `/voices`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'xi-api-key': config.ELEVEN_LABS_KEY,
            'accept': 'audio/mpeg'
        }
    });
    
    return await res.json() as Promise<GetVoicesResult>;
};