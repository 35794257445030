import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
    useAssignMembersToTeamsApi,
    useDeleteMembersFromTeamsApi,
    useSearchTeamMembersApi,
    useDeleteTeamsApi,
    useSearchTeamsApi,
    useCreateNewTeamApi,
    useAssignLabelsToTeamsApi,
    useSearchUsersApi,
} from '../../../../api';
import {
    AssignedMemberToTeamType,
    DeletedMemberToTeamType,
    SearchTeamMembersParams,
} from '../../../../api/teams/types';
import { SearchParams } from '../../../../constants/interfaces/filters';

import { useChunkRequestsService } from '../../../../services/ChunkRequestService/hooks';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../ui/components/ModalDialogs/store/actions';
import { GeneralBulkActionModalInterface } from '../../../../ui/components/ModalDialogs/types';
import {
    bulkActionFunctionParametersType,
    RemindToStartChunkRequestParameters,
} from './types';
import { ChunkRequestActionInterface } from '../../../../services/ChunkRequestService/hooks/types';
import {
    createSearchTeamMembersParams,
    createSearchTeamsParams,
} from '../../tools';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { TeamMember } from '../../../../constants/interfaces/TeamMember';
import {
    updateSingleTeamStart,
    updateSingleTeamSuccess,
    updateSingleTeamFailure,
    updateSingleTeamStop,
} from '../../store/actions';
import {
    DEFAULT_LIMIT,
    MAX_SELECTED_ALL_ITEMS,
    MIN_ITEMS_TO_SHOW_MODAL,
    REMIND_TO_START_ACTION,
    UPDATE_TEAM_ACTION,
} from './constants';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { CLIENT_SIDE_ERROR_MESSAGE } from '../../../../ui/components/ErrorMessages/constants';
import { Team } from '../../../../constants/interfaces/Team';
import { generateCopyOfEntityName } from '../../../../services/GeneralBulkActionServices/helpers';
import { useHandleSearchEnrollmentsAndRemind } from './helpers';
import { SearchUsersParams } from '../../../../api/users/types';
import { getSearchSortingValue } from '../../../../helpers/functions/search-params-helpers';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { UserStatus } from '../../../users/store/costants';

/**
 * @function useUpdateTeamMembersBulkActionService
 * @param { string } actionName
 * @param { Function } actionFunction
 * @param { Record<string, unknown>} actionFunctionOptions
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns
 */
export function useUpdateTeamMembersBulkActionService(
    action: ChunkRequestActionInterface<bulkActionFunctionParametersType>,
    modalTitle: string,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const { actionFunction, actionFunctionOptions } = action;

    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();

    const {
        parameters: { selectedItemIds = null, itemsCount = 0 },
    } = actionFunctionOptions;

    const shouldShowModals =
        (!selectedItemIds && itemsCount >= MAX_SELECTED_ALL_ITEMS) ||
        (selectedItemIds && selectedItemIds.length >= MIN_ITEMS_TO_SHOW_MODAL);

    /**
     * @function handleSuccessBulkActionServiceCallback
     * @returns { void }
     */
    const handleSuccessBulkActionServiceCallback = useCallback(() => {
        if (shouldShowModals) {
            setTimeout(() => {
                hideModalDialog();
            }, 900);
        }
        dispatch(updateSingleTeamSuccess());

        onSuccessCallback?.();
    }, [hideModalDialog, onSuccessCallback, shouldShowModals, dispatch]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        if (shouldShowModals) {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle,
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );
        }
        dispatch(updateSingleTeamFailure());

        onErrorCallback?.();
    }, [
        dispatch,
        hideModalDialog,
        modalTitle,
        onErrorCallback,
        shouldShowModals,
    ]);

    const { setIsStopped } =
        useChunkRequestsService<bulkActionFunctionParametersType>(
            [
                {
                    actionName: UPDATE_TEAM_ACTION,
                    actionFunction,
                    actionFunctionOptions,
                },
            ],
            handleSuccessBulkActionServiceCallback,
            handleFailedBulkActionServiceCallback
        );

    /**
     * @function handleStopBulkActionService
     * @returns { void }
     */
    const handleStopBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            hideModalDialog();
            dispatch(updateSingleTeamStop());

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, dispatch]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle,
                            onStopBulkActionService:
                                handleStopBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
            dispatch(updateSingleTeamStart());
        });
    }, [
        dispatch,
        handleStopBulkActionService,
        hideModalDialog,
        modalTitle,
        setIsStopped,
        shouldShowModals,
    ]);

    return {
        handleStartBulkActionService,
        handleStopBulkActionService,
    };
}

/**
 * @description function to duplicate a selected team.
 * Create new team by selected team's name at first then assign labels and members to
 * new created team by using chunk service if needed.
 * @function useDuplicateTeamBulActionService
 * @param { Function | undefined } onSuccessCallback
 * @param { Function | undefined } onErrorCallback
 * @returns { CallableFunction }
 */
export function useDuplicateTeamBulActionService(
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const actionList = useRef<any>([]);
    const newTeamDataRef = useRef<Team | null>(null);
    const { countMembers = 0, labels = [] } = newTeamDataRef.current || {};

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const createNewTeam = useCreateNewTeamApi();
    const assignLabelsToTeam = useAssignLabelsToTeamsApi();
    const getCurrentTeamMembers = useSearchTeamMembersApi();
    const assignMembersToTeam = useAssignMembersToTeamsApi();

    const shouldShowModals = countMembers > 0 || !isEmpty(labels);

    /**
     * @description fetch current team 'members' data and immediately
     * assign them to the new created (duplicated) team 20 by 20.
     * @function getCurrentTeamMembersAndAssignToNewTeam
     * @returns { void }
     */
    const getCurrentTeamMembersAndAssignToNewTeam = useCallback(
        async (
            currentTeamId: number,
            newTeamId: number,
            searchParams: SearchTeamMembersParams
        ) => {
            if (currentTeamId && newTeamId) {
                await getCurrentTeamMembers(currentTeamId, searchParams)
                    .then(async (response: ListResult<TeamMember>) => {
                        const members = response?.items?.map(item => ({
                            userId: item.user.id,
                            isTeamLead: item.isTeamLead ?? false,
                            teamId: newTeamId,
                        }));
                        if (!isEmpty(members)) {
                            await assignMembersToTeam(members).catch(
                                (error: unknown) => {
                                    throw error;
                                }
                            );
                        }
                    })
                    .catch((error: unknown) => {
                        throw error;
                    });
            }
        },
        [assignMembersToTeam, getCurrentTeamMembers]
    );

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        if (shouldShowModals) {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: 'Duplicate Team',
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );
        }

        isRunning && setIsRunning(false);

        actionList.current = [];
        onErrorCallback?.();
    }, [
        dispatch,
        hideModalDialog,
        isRunning,
        onErrorCallback,
        shouldShowModals,
    ]);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);
            }

            hideModalDialog();
            isRunning && setIsRunning(false);

            showMessage('1 Team has been copied', 'success');

            actionList.current = [];
            onSuccessCallback?.();
        });
    }, [
        hideModalDialog,
        isRunning,
        onSuccessCallback,
        shouldShowModals,
        showMessage,
    ]);

    const { setIsStopped } = useChunkRequestsService(
        actionList.current,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopDuplicateTeamBulkActionService
     * @returns { void }
     */
    const handleStopDuplicateTeamBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            hideModalDialog();
            onSuccessCallback?.();
            actionList.current = [];
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped]);

    /**
     * @description add assignMembers action item to main action list
     * @function handleAddAssignMembersActionToActionList
     * @param { number } currentTeamId
     * @param { number } newTeamId
     * @returns { void }
     */
    const handleAddAssignMembersActionToActionList = useCallback(
        (currentTeamId: number, newTeamId: number) => {
            const assignMembersToTeamOptions = {
                parameters: {
                    currentTeamId,
                    newTeamId,
                    searchParams: {
                        sort: 'name_asc',
                        limit: DEFAULT_LIMIT,
                        offset: countMembers,
                    },
                },
                fieldName: 'searchParams.offset',
            };

            const AssignMembersToTeamAction = {
                actionFunction: getCurrentTeamMembersAndAssignToNewTeam,
                actionFunctionOptions: assignMembersToTeamOptions,
            };

            actionList.current.push(AssignMembersToTeamAction);
        },
        [countMembers, getCurrentTeamMembersAndAssignToNewTeam]
    );

    /**
     * @description add assignLabels action item to main action list
     * @function handleAddAssignLabelsActionToActionList
     * @returns { void }
     */
    const handleAddAssignLabelsActionToActionList = useCallback(
        (currentTeamLabelIds: number[], newTeamId: number) => {
            const assignedLabelList = currentTeamLabelIds?.map(labelId => ({
                teamId: newTeamId,
                labelId,
            }));

            const assignLabelsToTeamOptions = {
                parameters: {
                    labels: assignedLabelList,
                },
                fieldName: 'labels',
            };

            const AssignLabelsToTeamAction = {
                actionFunction: assignLabelsToTeam,
                actionFunctionOptions: assignLabelsToTeamOptions,
            };

            actionList.current.push(AssignLabelsToTeamAction);
        },
        [assignLabelsToTeam]
    );

    /**
     * @description show progress modal and start chunk service action
     * @function handleStartActionModal
     * @returns { void }
     */
    const handleStartActionModal = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'Duplicate Team',
                        onStopBulkActionService:
                            handleStopDuplicateTeamBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopDuplicateTeamBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    /**
     * @function handleAssignRelationsToNewTeam
     * @param { number } newTeamId
     * @returns { void }
     */
    const handleAssignRelationsToNewTeam = useCallback(
        (newTeamId: number) => {
            const {
                id: currentTeamId = 0,
                countMembers = 0,
                labels: currentTeamLabelIds = [],
            } = newTeamDataRef.current!;

            if (shouldShowModals) {
                if (countMembers > 0) {
                    handleAddAssignMembersActionToActionList(
                        currentTeamId,
                        newTeamId
                    );
                }

                if (!isEmpty(currentTeamLabelIds)) {
                    handleAddAssignLabelsActionToActionList(
                        currentTeamLabelIds,
                        newTeamId
                    );
                }

                handleStartActionModal();
            }
            // There is nothing to assign so stop the runner
            else {
                Promise.resolve().then(() => {
                    onSuccessCallback?.();
                    setIsRunning(false);
                });
            }
        },
        [
            handleAddAssignLabelsActionToActionList,
            handleAddAssignMembersActionToActionList,
            handleStartActionModal,
            onSuccessCallback,
            shouldShowModals,
        ]
    );

    /**
     * @function handleStartDuplicateTeamBulkActionService
     * @returns { void }
     */
    const handleStartDuplicateTeamBulkActionService = useCallback(() => {
        const { name = '' } = newTeamDataRef.current!;

        const generatedCopyOfTeamName = generateCopyOfEntityName(name);

        createNewTeam(generatedCopyOfTeamName)
            .then(response => {
                const { id: newTeamId } = response;

                if (newTeamId) {
                    handleAssignRelationsToNewTeam(newTeamId);
                }
            })
            .catch((error: ErrorResult) => {
                if (error.message) {
                    const errorMessage =
                        CLIENT_SIDE_ERROR_MESSAGE[error.message] ??
                        error.message;

                    showMessage(errorMessage, 'error');
                    setIsRunning(false);
                    throw error;
                }
            });
    }, [createNewTeam, handleAssignRelationsToNewTeam, showMessage]);

    useEffect(() => {
        if (isRunning) {
            handleStartDuplicateTeamBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    /**
     * @description Call the service runner.
     * @function callback
     * @param { Team } teamData
     * @returns { void }
     */
    return useCallback((teamData: Team) => {
        newTeamDataRef.current = teamData;

        setIsRunning(true);
    }, []);
}

/**
 * @function useSearchAndAssignMembersToTeam
 * @returns { void }
 */
const useSearchAndAssignMembersToTeam = () => {
    const searchAllUsers = useSearchUsersApi();
    const assignMembersToTeams = useAssignMembersToTeamsApi();
    const profile = useSelector(getProfileState);

    return useCallback(
        async (teamId: number, searchParams: SearchParams) => {
            if (!profile?.companyId) return;
            const searchAllUsersParams: SearchUsersParams = {
                status:
                    searchParams.filters?.reduce((statuses, filter) => {
                        if (filter.field === 'status') {
                            statuses = String(filter.value);
                        }

                        return statuses;
                    }, '') || `${UserStatus.ACTIVE},${UserStatus.PENDING}`,
                labels: searchParams.labelIDs?.join(','),
                roles: searchParams.roleIDs?.join(','),
                teams: searchParams.teamIds?.join(','),
                companies: profile?.companyId?.toString(),
                limit: searchParams.limit ?? 20,
                offset: 0,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
                excludeTeamId: searchParams.teamId?.toString(),
            };
            await searchAllUsers(searchAllUsersParams)
                .then(async (response: Record<string, any>) => {
                    const members = response?.items?.map(
                        (item: { id: number }) => ({
                            userId: item?.id,
                            isTeamLead: false,
                            teamId: teamId,
                        })
                    );
                    if (!isEmpty(members)) {
                        await assignMembersToTeams(members).catch(
                            (error: unknown) => {
                                throw error;
                            }
                        );
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [assignMembersToTeams, profile?.companyId, searchAllUsers]
    );
};

/**
 * @function useAssignMembersToTeam
 * @returns { void }
 */
const useAssignMembersToTeam = () => {
    const assignMembersToTeams = useAssignMembersToTeamsApi();

    return useCallback(
        async (teamId: number, selectedItemIds: number[]) => {
            const members = selectedItemIds.map((userId: number) => ({
                userId,
                isTeamLead: false,
                teamId,
            })) as AssignedMemberToTeamType[];

            await assignMembersToTeams(members)
                .then(res => {})
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [assignMembersToTeams]
    );
};

/**
 * @function useAssignMembersToTeamService
 * @returns { void }
 */
export const useAssignMembersToTeamService = () => {
    const assignMembersToTeam = useAssignMembersToTeam();
    const searchAndAssignMembersToTeam = useSearchAndAssignMembersToTeam();

    return useCallback(
        async (
            teamId: number,
            searchParams: SearchParams,
            selectedItemIds?: number[]
        ) => {
            if (selectedItemIds) {
                await assignMembersToTeam(teamId, selectedItemIds);
            } else {
                await searchAndAssignMembersToTeam(teamId, searchParams);
            }
        },
        [assignMembersToTeam, searchAndAssignMembersToTeam]
    );
};

/**
 * @function useSearchAndDeleteMembersFromTeam
 * @returns { void }
 */
const useSearchAndDeleteMembersFromTeam = () => {
    const searchTeamsMembersApi = useSearchTeamMembersApi();
    const deleteMembersFromTeams = useDeleteMembersFromTeamsApi();

    return useCallback(
        async (teamId: number, searchParams: SearchParams) => {
            await searchTeamsMembersApi(
                teamId,
                createSearchTeamMembersParams({ ...searchParams, offset: 0 })
            )
                .then(async (response: ListResult<TeamMember>) => {
                    const members = response?.items?.map(item => ({
                        userId: item.user.id,
                        teamId: teamId,
                    }));

                    if (!isEmpty(members)) {
                        await deleteMembersFromTeams(members).catch(
                            (error: unknown) => {
                                throw error;
                            }
                        );
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteMembersFromTeams, searchTeamsMembersApi]
    );
};

/**
 * @function useDeleteMembersFromTeam
 * @returns { void }
 */
const useDeleteMembersFromTeam = () => {
    const deleteMembersFromTeams = useDeleteMembersFromTeamsApi();

    return useCallback(
        async (teamId: number, selectedItemIds: number[]) => {
            const members = selectedItemIds.map((userId: number) => ({
                userId,
                teamId,
            })) as DeletedMemberToTeamType[];

            await deleteMembersFromTeams(members)
                .then(res => {})
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteMembersFromTeams]
    );
};

/**
 * @function useDeleteMembersFromTeamService
 * @returns { void }
 */
export const useDeleteMembersFromTeamService = () => {
    const searchAndDeleteMembersFromTeam = useSearchAndDeleteMembersFromTeam();
    const deleteMembersFromTeam = useDeleteMembersFromTeam();

    return useCallback(
        async (
            teamId: number,
            searchParams?: SearchParams,
            selectedItemIds?: number[]
        ) => {
            if (selectedItemIds) {
                await deleteMembersFromTeam(teamId, selectedItemIds);
            } else {
                searchParams &&
                    (await searchAndDeleteMembersFromTeam(
                        teamId,
                        searchParams
                    ));
            }
        },
        [deleteMembersFromTeam, searchAndDeleteMembersFromTeam]
    );
};

/**
 * @function useUpdateTeamsBulkActionService
 * @param { string } actionName
 * @param { Function } actionFunction
 * @param { Record<string, unknown>} actionFunctionOptions
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
export function useUpdateTeamsBulkActionService(
    action: ChunkRequestActionInterface<bulkActionFunctionParametersType>,
    modalTitle: string,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const { actionFunction, actionFunctionOptions } = action;

    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();

    const {
        parameters: { selectedItemIds = null, itemsCount = 0 },
    } = actionFunctionOptions;

    const shouldShowModals =
        (!selectedItemIds && itemsCount >= MAX_SELECTED_ALL_ITEMS) ||
        (selectedItemIds && selectedItemIds.length >= MIN_ITEMS_TO_SHOW_MODAL);
    /**
     * @function handleSuccessBulkActionServiceCallback
     * @returns { void }
     */
    const handleSuccessBulkActionServiceCallback = useCallback(() => {
        if (shouldShowModals) {
            setTimeout(() => {
                hideModalDialog();
            }, 900);
        }
        dispatch(updateSingleTeamSuccess());

        onSuccessCallback?.();
    }, [hideModalDialog, onSuccessCallback, shouldShowModals, dispatch]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        if (shouldShowModals) {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle,
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );
        }
        dispatch(updateSingleTeamFailure());

        onErrorCallback?.();
    }, [
        dispatch,
        hideModalDialog,
        modalTitle,
        onErrorCallback,
        shouldShowModals,
    ]);

    const { setIsStopped } =
        useChunkRequestsService<bulkActionFunctionParametersType>(
            [
                {
                    actionName: UPDATE_TEAM_ACTION,
                    actionFunction,
                    actionFunctionOptions,
                },
            ],
            handleSuccessBulkActionServiceCallback,
            handleFailedBulkActionServiceCallback
        );

    /**
     * @function handleStopBulkActionService
     * @returns { void }
     */
    const handleStopBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            hideModalDialog();
            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle,
                            onStopBulkActionService:
                                handleStopBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
            dispatch(updateSingleTeamStart());
        });
    }, [
        dispatch,
        handleStopBulkActionService,
        hideModalDialog,
        modalTitle,
        setIsStopped,
        shouldShowModals,
    ]);

    return {
        handleStartBulkActionService,
        handleStopBulkActionService,
    };
}

/**
 * @function useDeleteTeamsService
 * @returns { void }
 */
export const useDeleteTeamsService = () => {
    const searchAndDeleteTeams = useSearchAndDeleteTeams();
    const deleteSelectedTeams = useDeleteSelectedTeams();
    return useCallback(
        async (searchParams: SearchParams, selectedItemIds?: number[]) => {
            if (selectedItemIds) {
                await deleteSelectedTeams(selectedItemIds);
            } else {
                await searchAndDeleteTeams(searchParams);
            }
        },
        [deleteSelectedTeams, searchAndDeleteTeams]
    );
};

/**
 * @function useDeleteSelectedTeams
 * @returns { void }
 */
const useDeleteSelectedTeams = () => {
    const deleteTeams = useDeleteTeamsApi();

    return useCallback(
        async (selectedItemIds: number[]) => {
            await deleteTeams(selectedItemIds)
                .then(res => {})
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteTeams]
    );
};

/**
 * @function useSearchAndDeleteTeams
 * @returns { void }
 */
const useSearchAndDeleteTeams = () => {
    const searchTeamsApi = useSearchTeamsApi();
    const deleteSelectedTeams = useDeleteSelectedTeams();

    return useCallback(
        async (searchParams: SearchParams) => {
            await searchTeamsApi(
                createSearchTeamsParams({ ...searchParams, offset: 0 })
            )
                .then(async (response: ListResult<Team>) => {
                    const teamIds = [] as number[];
                    response?.items?.forEach(item => {
                        if (!item.isDefault && item.id) {
                            teamIds.push(item.id);
                        }
                    });

                    if (!isEmpty(teamIds)) {
                        await deleteSelectedTeams(teamIds).catch(
                            (error: unknown) => {
                                throw error;
                            }
                        );
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteSelectedTeams, searchTeamsApi]
    );
};

/**
 *
 * @description Custom hook to remind to start with chunk service
 * when all items are selected.
 * @function useRemindToStartBulkActionService
 * @param { string } teamId
 * @param { string } practisSetId
 * @param { SearchParams } searchParams
 * @param { number } totalUsersCount
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
export function useRemindToStartBulkActionService(
    teamId: string,
    practisSetId: string,
    searchParams: SearchParams,
    totalUsersCount: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const handleSearchEnrollmentsAndRemind =
        useHandleSearchEnrollmentsAndRemind();

    const shouldShowModals = totalUsersCount > 20;

    const totalCompletedRemindToStartItems = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<RemindToStartChunkRequestParameters>[] =
        [
            {
                actionName: REMIND_TO_START_ACTION,
                actionFunction: handleSearchEnrollmentsAndRemind,
                actionFunctionOptions: {
                    parameters: {
                        teamId,
                        practisSetId,
                        searchParams: {
                            ...searchParams,
                            offset: totalUsersCount,
                        },
                        onSuccessCallback: (numberOfDoneItems: number) =>
                            (totalCompletedRemindToStartItems.current =
                                totalCompletedRemindToStartItems.current +
                                numberOfDoneItems),
                    },
                    fieldName: 'searchParams.offset',
                },
            },
        ];

    /**
     * @function clearTotalCompletedRemindedUsers
     * @returns { void }
     */
    const clearTotalCompletedRemindedUsers = () =>
        (totalCompletedRemindToStartItems.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);
            }

            isRunning && setIsRunning(false);

            showMessage(
                `Reminders Sent to ${totalCompletedRemindToStartItems.current} Users`,
                'success'
            );

            clearTotalCompletedRemindedUsers();
            onSuccessCallback?.();
        });
    }, [
        hideModalDialog,
        isRunning,
        onSuccessCallback,
        shouldShowModals,
        showMessage,
    ]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_FAILED_MODAL',
                        modalProps: {
                            modalTitle: 'Remind Users',
                            onClose: hideModalDialog,
                        } as GeneralBulkActionModalInterface,
                    })
                );
            }

            clearTotalCompletedRemindedUsers();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [
        dispatch,
        hideModalDialog,
        isRunning,
        onErrorCallback,
        shouldShowModals,
    ]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopRemindUsersBulkActionService
     * @returns { void }
     */
    const handleStopRemindUsersBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(
                `Reminders Sent to ${totalCompletedRemindToStartItems.current} Users`,
                'success'
            );

            clearTotalCompletedRemindedUsers();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartRemindUsersBulkActionService
     * @returns { void }
     */
    const handleStartRemindUsersBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle: 'Remind Users',
                            onStopBulkActionService:
                                handleStopRemindUsersBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopRemindUsersBulkActionService,
        hideModalDialog,
        setIsStopped,
        shouldShowModals,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartRemindUsersBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);
}