import React from 'react';

export function getBrowserVisibilityProp() {
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        return 'visibilitychange';
        // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
        return 'msvisibilitychange';
        // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitvisibilitychange';
    } else {
        return 'default';
    }
}
export function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== 'undefined') {
        return 'hidden';
        // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
        return 'msHidden';
        // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitHidden';
    } else {
        return false;
    }
}
export function getIsDocumentHidden() {
    const browserHidden = getBrowserDocumentHiddenProp();

    return browserHidden && !document[browserHidden as 'hidden'];
}

export const usePageVisibility = () => {
    const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());
    React.useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp();
        window.addEventListener(visibilityChange, onVisibilityChange, false);
        return () => {
            window.removeEventListener(visibilityChange, onVisibilityChange);
        };
    });
    return isVisible;
};

export default usePageVisibility;
