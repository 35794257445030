import { Form } from 'formik';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
    height: 100%;
`;

export const Content = styled.div`
    height: calc(100% - 65px);
    padding: 24px;
    box-sizing: border-box;
`;

export const FieldContainer = styled.div`
    height: 84px;
    width: 400px;

    & input:disabled {
        color: var(--ps-grey-1);
    }

    .company-select {
        width: 400px;
        border: solid 1px var(--ps-grey-3);

        &.company-select-open {
            border: solid 1px var(--ps-grey-2);
        }

        .company-select-input > div {
            color: var(--ps-black-main);
        }

        .content-area {
            padding: 8px 0;

            > div {
                padding: 5px 12px;
                border-radius: 0;
                margin-bottom: 0;
                min-height: auto;
                font-weight: 400;

                &.selected-item {
                    color: var(--ps-white-1);
                    background: var(--ps-blue-main);
                }

                &:hover {
                    color: var(--ps-white-1);
                    background: var(--ps-blue-main);
                }
            }
        }
    }

    .company-select-label {
        left: 12px;
    }

    .company-select-arrow {
        right: 12px;
    }

    .company-select-input {
        padding: 0 12px;
    }
`;

export const FieldName = styled.div`
    font-size: 13px;
    font-weight: 800;
    color: var(--ps-black-main);
    padding-bottom: 4px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--ps-grey-4);
    align-items: center;
    padding-right: 24px;
    height: 64px;
`;