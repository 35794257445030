import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../ui/components/Button';
import { Fieldset } from '../../../ui/components/Fieldset';
import { PortableTeamsWithStore } from '../index';
import {
    useSaveTeams,
    useSelectMultipleTeams,
} from '../store/hors/withTeams/services';
import { usePortableTeamsState } from '../store/states';
import { useTeamsState } from '../store/hors/withTeams/states';
import { useParams } from 'react-router';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const TeamsContainer = styled.div`
    width: 264px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 16px;
    width: 100%;
`;

export const AssignTeams: FC<{
    onSuccessApply: (teamIds: number[]) => void;
    onCancel: () => void;
    selectedTeams?: number[];
}> = ({ onSuccessApply, onCancel, selectedTeams }) => {
    const params: any = useParams();

    const teams = useTeamsState();
    const saveTeams = useSaveTeams();
    const selectMultipleTeams = useSelectMultipleTeams();
    const { data: initialTeams } = usePortableTeamsState();
    const [, setTeamIds] = useState<number[]>(teams.selected);
    const [applyDisabled, setApplyDisabled] = useState(true);

    const hasAllMembers = initialTeams.items.some(item => item.isDefault);

    const onApplyHandler = useCallback(() => {
        selectMultipleTeams(teams.selected);
        if (params.draftId) {
            saveTeams();
        }
        onSuccessApply(teams.selected);
    }, [
        onSuccessApply,
        params.draftId,
        saveTeams,
        selectMultipleTeams,
        teams.selected,
    ]); 

    const onTeamChangeHandler = useCallback(
        (ids: number[]) => {
          if(!(initialTeams.totalCount < 1 && hasAllMembers) ){
            setTeamIds(ids);
            setApplyDisabled(false);
          }
        },
        [hasAllMembers, initialTeams.totalCount]
    );



    return (
        <Container>
            <Wrapper>
                <Fieldset withoutBorder>
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                            selectedTeams={selectedTeams}
                            onSetSelected={onTeamChangeHandler}
                            showDefaultMembers={true}
                        />
                    </TeamsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <Button
                    width={'96px'}
                    action={() => onCancel()}
                    variant={'inverse'}
                    dataTest='cancel-button'
                >
                    Cancel
                </Button>
                <Button
                    disabled={applyDisabled}
                    style={{ marginLeft: 16 }}
                    width={'96px'}
                    action={() => onApplyHandler()}
                    dataTest='apply-button'
                >
                    Apply
                </Button>
            </Actions>
        </Container>
    );
};
