import { Reducer } from 'redux';
import { AiLogsState } from './states';
import { ACTIONS, AiLogsActions } from './actions';

export const initialAiLogsState: AiLogsState = {
    data: undefined,
    error: null,
    loading: false,
    loadingFlag: false,
};

export const aiLogsReducer: Reducer<AiLogsState, AiLogsActions> = (
    state = initialAiLogsState,
    action
) => {
    switch (action.type) {
        case ACTIONS.SEARCH_AI_LOGS_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ACTIONS.SEARCH_AI_LOGS_SUCCESS:
            const prevItems = state.data && state.data.items;
            return {
                ...state,
                data:
                    !prevItems
                        ? action.aiLogs
                        : {
                              ...action.aiLogs,
                              items:  action.aiLogs.items,
                                  
                          },
                loading: false,
                error: null,
            };
        case ACTIONS.SEARCH_AI_LOGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.SEARCH_AI_LOGS_DISPOSE:
            return {
                ...state,
                data: undefined,
                loading: false,
                error: null,
            };
        case ACTIONS.UPDATE_AI_LOGS_FLAG_START:
            return {
                ...state,
                loadingFlag: true,
            };
        case ACTIONS.UPDATE_AI_LOGS_FLAG_FAILURE:
            return {
                ...state,
                loadingFlag: false,
                error: action.error,
            };
            case ACTIONS.ADD_AI_LOGS_FLAG_SUCCESS: {
                const { submissionId, flagId } = action;
    
                return state.data?.items.length
                    ? {
                          ...state,
                          data: {
                              ...state.data,
                              items: state.data.items.map(item =>
                                  item.id === submissionId
                                      ? {
                                            ...item,
                                            flagCompanyId: flagId
                                        }
                                      : item
                              ),
                          },
                          loadingFlag: false,
                      }
                    : {
                          ...state,
                          loadingFlag: false,
                      };
            }
            case ACTIONS.DELETE_AI_LOGS_FLAG_SUCCESS: {
                const { submissionId } = action;
    
                return state.data?.items.length
                    ? {
                          ...state,
                          data: {
                              ...state.data,
                              items: state.data.items.map(item =>
                                  item.id === submissionId
                                      ? {
                                            ...item,
                                            flagCompanyId: undefined
                                        }
                                      : item
                              ),
                          },
                          loadingFlag: false,
                      }
                    : {
                          ...state,
                          loadingFlag: false,
                      };
            }
        default:
            return state;
    }
};
