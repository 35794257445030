import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const CancelCross = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className={className}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    d="m1.953 2.02 12 11.988M13.953 2.02l-12 11.988"
                    stroke="currentColor"
                    stroke-width="1.3"
                    stroke-linecap="round"
                />
            </svg>
        );
    }
);

export default CancelCross;
