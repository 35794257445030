import { AppState } from '../../../../../store/reducers';

export type Scope = typeof DEFAULT_LABELS_SCOPE | string;

export const DEFAULT_LABELS_SCOPE = 'primary';

export const WithLabelsScopes: Partial<Record<keyof AppState, Scope[]>> = {
    draftEdit: ['assignFilters', 'addNewUser', 'editUser'],
    performance: ['assignFilters', 'addNewUser', 'editUser'],
    teamEdit: [
        'allUsers',
        'teamMembers',
        'defaultTeamMembers',
        'assignTeamMembers',
    ],
    teams: ['applyLabels', 'assignLabels'],
    teamMembers: ['assignLabels'],
    practisSetsTraining: ['assignLabels'],
    practisSet: ['applyLabels'],
};
