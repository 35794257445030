import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useWithPractisSetsContext } from '../context/useWithPractisSetsContext';
import { encapsulateAction } from '../../../../../../store/helpers/encapsulateAction';
import { ActionPractisSet } from '../actions';
import {
    DEFAULT_PRACTIS_SETS_SCOPE,
    Scope,
    WithPractisSetsScopes,
} from '../scopes';

export const useEncapsulatedDispatch = () => {
    const dispatch = useDispatch();
    const { reducerName, scope } = useWithPractisSetsContext();

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithPractisSetsContext.Provider props'
        );
    }

    const targetScope = (scope || DEFAULT_PRACTIS_SETS_SCOPE) as Scope;
    if (
        targetScope !== DEFAULT_PRACTIS_SETS_SCOPE &&
        !WithPractisSetsScopes[reducerName]?.includes(targetScope)
    ) {
        throw new Error(
            `Undefined "${targetScope}" scope of portablePractisSets. Please check config (WithPractisSetsScopes) for "${reducerName}" reducer`
        );
    }

    return useCallback(
        <T extends ActionPractisSet>(action: T) => {
            action._scope = targetScope;
            dispatch(encapsulateAction(`${reducerName}/practisSets/`, action));
        },
        [dispatch, reducerName, targetScope]
    );
};
