import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { Field } from 'formik';

export const useStyles = makeStyles(theme => ({
    imageUploaderStyles: {
        marginBottom: '60px !important',

        '@media(max-height: 650px)': {
            marginTop: '64px',
        },
    },

    checkboxContainer: {
        alignItems: 'flex-start !important',
        margin: '-10px 0 20px 0',
        gap: '4px',
        userSelect: 'none',
    },

    checkBoxAnchorTag: {
        color: 'var(--ps-blue-main)',
        textDecoration: 'none',
        marginLeft: 5,
    },
}));

export const UserInfoContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

export const UserTitle = styled.div`
    color: var(--ps-white-1);
    font-size: 15px;
`;
export const UserEmail = styled.div`
    font-size: 13px;
    color: var(--ps-grey-2);
`;

export const HeaderTitle = styled.div`
    flex-grow: 0;
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    margin: 8px 0;
    text-align: center;
    color: var(--ps-white-1);
`;

export const FormContainer = styled.div`
    width: 100%;
`;

export const FieldsContainer = styled.div<{ marginBottom?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    > div,
    p {
        margin-bottom: ${props =>
            !!props.marginBottom ? props.marginBottom : '16px'};
    }
`;

export const StyledField = styled(Field)`
    flex: 1;
`;

export const Divider = styled.div`
    width: 39px;
    height: 3px;
    margin: 10px auto 0;
    background-color: var(--ps-blue-main);
`;