import styled from 'styled-components';

export const Text = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: var(--ps-grey-1);
    height: 19px;
    display: flex;
    align-items: center;
`;

export const GearButton = styled.div`
    width: 16px;
    height: 16px;
    margin-left: 4px;
    cursor: pointer;
    color: var(--ps-blue-11);
`;