import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Drag = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 11 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor">
                        <g>
                            <g transform="translate(-1368 -472) translate(1368 472) translate(6.4)">
                                <circle cx="1.6" cy="1.6" r="1.6" />
                                <circle cx="1.6" cy="8" r="1.6" />
                                <circle cx="1.6" cy="14.4" r="1.6" />
                            </g>
                            <g transform="translate(-1368 -472) translate(1368 472)">
                                <circle cx="1.6" cy="1.6" r="1.6" />
                                <circle cx="1.6" cy="8" r="1.6" />
                                <circle cx="1.6" cy="14.4" r="1.6" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default Drag;
