import React, { FC } from 'react';
import styled from 'styled-components';
import AddUser from '../../icons/AddUser';

const StyledUploadContainer = styled.div<{ marginTop?: string }>`
    margin-top: ${props => props.marginTop || '24px'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledUploadImage = styled.div<{
    backgroundColor?: string;
    height: number;
    width: number;
}>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: 8px;
    max-width: 100%;
    background-color: ${props =>
        props.backgroundColor || props.theme.Colors.darkTwo};
    border: 1px solid ${props => props.theme.Colors.paleGrey};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &:active {
        opacity: 0.7;
    }
`;

const StyledImage = styled.img<{ opacity?: number; border?: string }>`
    width: 100%;
    min-width: 25px;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    opacity: 0.7;
`;

const CustomPlaceholderContainer = styled.div`
    width: 40px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const StyledDescription = styled.div<{ fontSize?: number }>`
    font-size: ${props => (props.fontSize ? props.fontSize : 15)}px;
    text-align: center;
    color: ${props => props.theme.Colors.steelGrey};
    margin-top: 11px;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const UploadPhoto: FC<{
    currentImage?: string;
    customPlaceholder?: any;
    handleChange?: any;
    showCustomDescription?: boolean;
    backgroundColor?: string;
    marginTop?: string;
    className?: string;
    fontSize?: number;
    width?: number;
    height?: number;
    disabled?: boolean;
}> = ({
    currentImage,
    customPlaceholder: PlaceholderIcon,
    handleChange,
    showCustomDescription,
    backgroundColor,
    marginTop,
    children,
    className,
    fontSize,
    width,
    height,
    disabled,
}) => {
    let upload: any;
    return (
        <StyledUploadContainer marginTop={marginTop} className={className}>
            <input
                id="myInput"
                type="file"
                ref={ref => (upload = ref)}
                style={{ display: 'none' }}
                onChange={e => handleChange(e)}
                disabled={disabled}
            />
            <StyledUploadImage
                height={height ? height : 136}
                width={width ? width : 136}
                onClick={() => upload.click()}
                backgroundColor={backgroundColor}
                data-test="uploaded-image-section"
            >
                {currentImage ? (
                    <StyledImage src={currentImage} />
                ) : PlaceholderIcon ? (
                    <CustomPlaceholderContainer data-test="upload-placeholder-container">
                        <PlaceholderIcon />
                    </CustomPlaceholderContainer>
                ) : (
                    <CustomPlaceholderContainer>
                        <AddUser />
                    </CustomPlaceholderContainer>
                )}
            </StyledUploadImage>
            {(!!showCustomDescription && (
                <StyledDescription onClick={() => upload.click()}>
                    {children}
                </StyledDescription>
            )) || (
                <StyledDescription
                    onClick={() => upload.click()}
                    fontSize={fontSize}
                >
                    <div>JPG, PNG, BMP only. </div>
                    <div>Less than 10 MB</div>
                </StyledDescription>
            )}
        </StyledUploadContainer>
    );
};

export default UploadPhoto;
