import styled from 'styled-components';

export const StyledFormikField = styled.div`
    width: 100%;
    flex: 1;
`;

export const StyledErrorContainer = styled.div<{
    padding?: string;
    color?: string;
}>`
    color: ${props =>
        !!props.color ? props.color : props.theme.Colors.tomato};
    font-weight: 500;
    font-size: 13px;
    padding: ${props => (props.padding ? props.padding : '8px 5px 0 27px')};
`;

export const HintContainer = styled.div<{
    padding?: string;
    color?: string;
}>`
    color: ${props => (!!props.color ? props.color : 'var(--ps-grey-4)')};
    font-weight: 500;
    font-size: 13px;
    padding: ${props => (props.padding ? props.padding : '8px 5px 0 27px')};
`;

