export enum MobileNumberVerificationStep {
    ENTER = 'enter',
    VERIFY = 'verify'
}

export interface AddMobileNumberViewProps {
    userId?: number;
    mobileNumber: string;
    canSkip: boolean;
    currentStep: MobileNumberVerificationStep;
    onMobileNumberChange: (mobileNumber: string) => void;
    onSendCode: () => void;
    onSkip: () => void;
    onVerify: () => void;
    onBackFromVerify: () => void;
}
