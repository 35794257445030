interface Window {
    attachEvent(event: string, listener: EventListener): boolean;
    detachEvent(event: string, listener: EventListener): void;
}

/**
 * @description -- dispatch an event --
 * ex: onTriggerEvent('fetchUserData')
 *
 * @function onTriggerEvent
 * @param { string } eventName
 */
export function onTriggerEvent(eventName: string) {
    let event;
    if (window.CustomEvent) {
        event = new CustomEvent(eventName);
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, true, true, undefined);
    }
    window.dispatchEvent(event);
}

/**
 * @description -- to listen to an event
 * @function onListenEvent
 * @param { string } eventName
 * @param { EventListener } handler
 */
export function onListenEvent(eventName: string, handler: EventListener) {
    if (window.addEventListener) {
        window.addEventListener(eventName, handler);
    } else {
        (window as unknown as Window).attachEvent('on' + eventName, handler);
    }
}

/**
 * @description -- to remove event listener
 * @function onRemoveEvent
 * @param { string } eventName
 * @param { EventListener } handler
 */
export function onRemoveEvent(eventName: string, handler: EventListener) {
    if (window.removeEventListener) {
        window.removeEventListener(eventName, handler);
    } else {
        (window as unknown as Window).detachEvent('on' + eventName, handler);
    }
}
