import React, { FC, useEffect, useState, ComponentType, memo } from 'react';
import styled from 'styled-components';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import { CompanyInterface } from '../../../../../constants/interfaces/Company';
import { UserProfile } from '../../../../../constants/interfaces/User';
import { useGetCompanyService } from '../../../../../pages/CompanySettings/store/services';
import { useGetProfileService } from '../../../../../pages/UserProfile/store/services';
import ROUTES from '../../../../../routes/routes';
import { DropDownListItem } from '../../../../components/DropdownList';
import { useUpdatedLabelsState } from '../../../../../features/labels/store/states';
import { CheckPermission } from '../../../../../features/permissions';
import { usePermissionsState } from '../../../../../features/permissions/store/state';
import { useSelector } from 'react-redux';
import {
    getProfileLoading,
    getProfileState,
} from '../../../../../pages/UserProfile/store/reducers';
import OutsideActionBox from '../../../../components/OutsideActionBox/OutsideActionBox';
import UserProfileArea from './components/UserProfileArea';
import MinimizedUserProfileArea from './components/MinimizedUserProfileArea';
import { useLoadUserRolesService } from '../../../../../features/roles/store/services';
import { getCompanyState } from "../../../../../pages/CompanySettings/store/reducers";
import { useUserRolesState } from '../../../../../features/roles/store/states';
import { isEqual } from 'lodash';
import { isPractisAdminRole } from '../../../../../constants/enums';
import { useUrlBuilder } from '../../../../../tools/url/UrlBuilder';
import Storage from '../../../../../services/Storage';
import { isAdminPortal } from '../../../../../helpers/functions/general';

const StyledProfileContainer = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    bottom: 0;
    background: var(--ps-graphite-3);
    border-top: 1px solid #343850;
    height: 84px;
    align-items: center;
`;

const StyledMenu = styled.div<{ left: number;}>`
    position: absolute;
    bottom: 95%;
    left: ${props => props.left}px;
    background: ${props => props.theme.Colors.white};
    width: 200px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
`;

export interface ProfileDropDownItem {
    name: string;
    url: string;
    custom?: boolean;
    permissions?: NEW_PERMISSIONS[];
    externalUrl?: boolean;
    replaceUrl?: boolean;
    icon?: ComponentType;
    iconSize?: number;
    modalUrl?: boolean;
}

export const ProfileSection: React.FC<{
    profile: Partial<UserProfile>;
    company?: CompanyInterface;
    loading?: boolean;
    getProfile: () => void;
    getCompany: (companyId: number) => void;
    sideBarOpen: boolean;
}> = memo(({ profile, getProfile, getCompany, sideBarOpen, company, loading }) => {
    const [open, setOpen] = useState(false);
    const updatedLabels = useUpdatedLabelsState();
    const permissions = usePermissionsState();
    const urlBuilder = useUrlBuilder();

    useEffect(() => {
        if (!profile?.id || !!updatedLabels?.data) {
            getProfile();
        }
    }, [getProfile, profile?.id, updatedLabels?.data]);

    useEffect(() => {
        if (profile.companyId) {
            if (!company || company.id !== profile.companyId) {
                getCompany(profile.companyId);
            }
        }
    }, [company, getCompany, permissions, profile]);

    useEffect(() => {
        const isItAdminPortal = isAdminPortal();

        if (
            isPractisAdminRole(profile.role?.name) &&
            !profile.companyId &&
            !isItAdminPortal
        ) {
            window.location.href = urlBuilder.buildAdminUrl({
                absolute: true,
                query: {
                    t: Storage.get('token'),
                    id: profile.id,
                    p: Storage.get('apiPlatform'),
                },
            });
        }
    }, [profile.companyId, profile.id, profile.role?.name, urlBuilder]);
    

    const defaultProfileItems: ProfileDropDownItem[] = [
        { name: 'Log Out', url: ROUTES.AUTH_PAGES.LOGOUT, custom: true },
        {
            name: 'Company Settings',
            url: ROUTES.COMPANY_SETTINGS.ROOT,
            permissions: [NEW_PERMISSIONS.UPDATE_COMPANY_INFO],
            modalUrl: true,
        },
        { name: 'My Settings', url: ROUTES.PROFILE.replace(':userId', 'me') },
    ];

    return (
        <ProfileSectionContext.Consumer>
            {context => (
                <StyledProfileContainer
                    onClick={() => setOpen(!open)}
                >
                    {sideBarOpen ? (
                        <MinimizedUserProfileArea
                            profile={profile}
                            open={open}
                        />
                    ) : (
                        <UserProfileArea profile={profile} open={open} loading={loading} />
                    )}
                    <OutsideActionBox
                        open={open}
                        toggleOpen={() => setOpen(false)}
                    >
                        <StyledMenu
                            left={sideBarOpen ? 8 : 24}
                        >
                            {(context.items || defaultProfileItems).map(
                                item => (
                                    <CheckPermission
                                        key={item.url}
                                        permissions={item.permissions}
                                    >
                                        <DropDownListItem
                                            name={item.name}
                                            url={item.url}
                                            custom={item.custom}
                                            externalUrl={item.externalUrl}
                                            replaceUrl={item?.replaceUrl}
                                            icon={item.icon}
                                            iconSize={item.iconSize}
                                            modalUrl={item.modalUrl}
                                        />
                                    </CheckPermission>
                                )
                            )}
                        </StyledMenu>
                    </OutsideActionBox>
                </StyledProfileContainer>
            )}
        </ProfileSectionContext.Consumer>
    );
});

const ProfileSectionContainer: FC<{ sideBarOpen: boolean }> = ({
    sideBarOpen,
}) => {
    const company = useSelector(getCompanyState, isEqual);
    const getProfile = useGetProfileService();
    const getCompany = useGetCompanyService();
    const profile = useSelector(getProfileState, isEqual);
    const profileLoading = useSelector(getProfileLoading, isEqual);
    const userRoles = useUserRolesState();
    const loadUserRoles = useLoadUserRolesService();
    useEffect(() => {
        if (!userRoles?.list?.length) {
            loadUserRoles();
        }
    }, [userRoles?.list?.length, loadUserRoles]);

    return (
        <ProfileSection
            company={company}
            profile={profile || {}}
            loading={profileLoading}
            getProfile={getProfile}
            getCompany={getCompany}
            sideBarOpen={sideBarOpen}
        />
    );
};

export default ProfileSectionContainer;

export interface ProfileSectionContextValue {
    items?: ProfileDropDownItem[];
}

export const ProfileSectionContext = React.createContext<ProfileSectionContextValue>(
    {}
);
