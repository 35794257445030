import EmptyCalendar from '../../../../icons/EmptyCalendar';
import { EmptyState } from '../EmptyState';
import { NoCalendarResultsProps } from './types';

function NoCalendarResultsView({
    icon,
    noResultsText,
    dataTest
}: NoCalendarResultsProps) {
    return (
        <EmptyState icon={icon || EmptyCalendar} dataTest={dataTest}>
            {noResultsText || 'There is no data for the selected period'}
        </EmptyState>
    );
};

export default NoCalendarResultsView;