import React, { FC, useEffect } from 'react';

import {
    useClearPractisSets,
    useDeselectAllPractisSets,
    useResetSelectedPractisSets,
    useSavePractisSets,
    useSelectAllPractisSets,
    useSelectMultiplePractisSets,
} from '../store/hors/withPractisSets/services';
import { usePractisSetsState } from '../store/hors/withPractisSets/states';
import {
    PortablePractisSets,
    PortablePractisSetsProps,
} from './PortablePractisSets';
import { usePortablePractisSetsState } from '../store/states';
import { PractisSets } from '../../../constants/interfaces/PractisSets';

type Props = Pick<
    PortablePractisSetsProps,
    | 'filtersTitle'
    | 'onSetSelected'
    | 'showSelectActions'
    | 'showSearch'
    | 'showSave'
> & {
    resetSelectedOnUnmount?: boolean;
    hideDueDates?: boolean;
    className?: string;
    selectAllWrapperClassName?: string;
};

export const PortablePractisSetsWithStore: FC<Props> = ({
    onSetSelected,
    resetSelectedOnUnmount = true,
    hideDueDates,
    ...rest
}) => {
    const { selected } = usePractisSetsState();
    const { data, loading } = usePortablePractisSetsState();
    const clearHandler = useClearPractisSets();
    const saveHandler = useSavePractisSets();
    const select = useSelectMultiplePractisSets();
    const selectAll = useSelectAllPractisSets();
    const deselectAll = useDeselectAllPractisSets();
    const resetSelected = useResetSelectedPractisSets();

    useEffect(() => {
        return () => {
            if (resetSelectedOnUnmount) {
                resetSelected();
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <PortablePractisSets
            practisSets={data}
            selectedList={selected}
            loading={loading}
            onClearSelected={() => {
                onSetSelected && onSetSelected([]);
                clearHandler();
            }}
            onSave={saveHandler}
            onSetSelected={(team) => { 
                onSetSelected && onSetSelected(team);
                select(team);
             }}
            onSelectAll={(practisSets: PractisSets[]) => {
                selectAll(practisSets);
                onSetSelected && onSetSelected([]);
            }}
            onDeselectAll={() => {
                deselectAll();
            }}
            hideDueDates={hideDueDates}
            {...rest}
        />
    );
};
