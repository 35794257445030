import { isEmpty } from 'lodash';
import { EmptyState } from '../../../../ui/components/EmptyStates';

import RadioButton from '../../../../ui/components/RadioButton';
import { FilterSkeleton } from '../../../../ui/components/Skeleton';
import NoSearchResult from '../../../../ui/icons/NoSearchResult';
import { FilterContainer } from '../../filter-container';
import { SearchableFilterHeaderKey } from '../../searchable-filter-header/store/types';
import {
    Container,
    SkeletonContainer,
    EmptyStateContainer
} from './styles';
import { SingleSelectTeamFilterViewProps } from './types';

function SingleSelectTeamFilterView({
    selectedTeamId, isLoading, myTeams, isDisabled, onTeamSearch, onTeamSelect
}: SingleSelectTeamFilterViewProps) {
    return (
        <FilterContainer
            headerProps={{
                storeKey: SearchableFilterHeaderKey.SINGLE_TEAM,
                title: 'Team',
                hasSearch: true,
                onSearch: onTeamSearch,
                isLoading,
                isDisabled
            }}
        >
            <Container>
                {isLoading ? (
                    <SkeletonContainer><FilterSkeleton /></SkeletonContainer>
                ) : isEmpty(myTeams?.items) ? (
                    <EmptyStateContainer>
                        <EmptyState
                            fontSize={11}
                            icon={NoSearchResult}
                            iconStyle={{
                                width: 32,
                                height: 32,
                                marginBottom: 4,
                            }}
                            dataTest="no-teams"
                        >
                            No Search Results
                        </EmptyState>
                    </EmptyStateContainer>
                ) : (
                    myTeams?.items.map(item => (
                        <RadioButton
                            key={item.id}
                            handleChange={() => onTeamSelect(item.id!)}
                            checked={selectedTeamId === item.id}
                            size={12}
                            label={item.name}
                            dataTest="team-filter-item"
                            disabled={isDisabled}
                        />
                    ))
                )}
            </Container>
        </FilterContainer>
    );
}

export default SingleSelectTeamFilterView;