import React, { useCallback } from 'react';
import styled from 'styled-components';
import Checkbox from '../Checkbox';

interface Props<TValue = any> {
    checked: boolean;
    label: string;
    value?: TValue;
    partial?: boolean;
    disabled?: boolean;
    size?: number;
    onChange?(value?: TValue): void;
}

export const FiltersCheckbox = function <TValue = any>(props: Props<TValue>) {
    const { checked, label, value, partial, disabled, size, onChange } = props;

    const handleChange = useCallback(() => {
        if (onChange) {
            onChange(value);
        }
    }, [value, onChange]);

    return (
        <Root data-test="filters-items-checkbox-container">
            <Checkbox
                checked={checked}
                partial={partial}
                disabled={disabled}
                size={size}
                handleChange={handleChange}
                dataTest="filter-item-checkbox"
            />
            <Label
                disabled={disabled}
                onClick={handleChange}
                data-test={`filter-item-title`}
            >
                {label}
            </Label>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;

interface LabelProps {
    disabled?: boolean;
}

const Label = styled.div<LabelProps>`
    color: ${props => props.theme.Colors.black};
    cursor: ${props => (props.disabled ? `default` : `pointer`)};
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 0 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;