import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import Validation from '../../../../../services/validation';
import {
    useAddMultipleStagingUsersService,
    useAddStagingUserService,
    useAssignFiltersToStagingUsersService,
    useDeleteStagingUserService,
    useInviteStagingUserService,
    useModifyDraftUsersService,
    useModifyDraftUsersWithoutValidationService,
    useResetDraftsPageService,
    useSearchStagingUsersService,
    useUpdateStagingUserService,
    MAX_DRAFT_USERS_INVITE,
} from '../../store/services';

import { AddNewUserContainer } from '../../components/AddNewUser';
import { CheckPermission } from '../../../../../features/permissions';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { NewUserHeader } from '../../components/Header/NewUserHeader';
import { UsersList } from '../../components/UsersList/UsersList';
import { formatDate } from '../../../../../helpers/functions/date-convert';
import { SaveAsDraftModal } from '../../components/SaveAsDraftModal';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../constants/interfaces/filters';
import { useIfChanged } from '../../../../../helpers/hooks/usePreviousData';
import {
    DraftUser,
    PractisSetWithDueDate,
} from '../../../../../constants/interfaces/Draft';
import { useDraftEditState } from '../../store/states';
import { getCompanyState } from '../../../../CompanySettings/store/reducers';
import { CompanyInterface } from '../../../../../constants/interfaces/Company';
import { useHistory } from '../../../../../tools/router';
import { useGetFullPractisSetsService } from '../../../../../features/library/store/services';
import { AssignFiltersToStagingUsersParams } from '../../../../../api/users/types';
import { LibraryItemsStatus } from '../../../../../features/library/tools';
import ROUTES from '../../../../../routes/routes';
import AddNewUserTip from '../../components/AddNewUserTip';
import useKeyPress from '../../../../../helpers/hooks/useKeyPress';
import { WithLabelsContext } from '../../../../../features/portableLabels';
import { WithTeamsContext } from '../../../../../features/portableTeams';
import { WithPractisSetsContext } from '../../../../../features/portablePractisSets';
import { isAdminRole, isTeamLeader, ROLE_NAMES } from '../../../../../constants/enums';
import { getProfileState } from '../../../../UserProfile/store/reducers';
import { useUserRolesState } from '../../../../../features/roles/store/states';
import uniqBy from 'lodash/uniqBy';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { escapeRegExp } from '../../../../../helpers/functions/escape-reg-exp';
import { ModalPageContainerProps } from '../../../../../ui/components/ModalPage';
import ModalPage from '../../../../../ui/components/ModalPage/ModalPage';
import { useTableStateHelper } from '../../../../../ui/components/table-wrapper/helper';
import { Button } from '../../../../../ui/components/Button';
import { useInviteUsersBulActionService } from '../../services/InviteUsersBulkActionService';
import { ChunkServiceUserDataInterface } from '../../services/InviteUsersBulkActionService/types';
import { onTriggerEvent } from '../../../../../helpers/functions/Events';
import {
    EventNameList,
    REFRESH_PENDING_USERS_PAGE,
} from '../../../../../helpers/functions/Events/types';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import { extractEmailsFromString } from '../../../../../helpers/functions/strings';
import { usePortableTeamsState } from '../../../../../features/portableTeams/store/states';
import { useShowConfirmModalDialog } from '../../../../../ui/components/ModalDialogs/store/actions';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

import {
    StyledContainer,
    StyledHeader,
    StyledFooter,
    StyledFooterActions,
    StyledHeaderInput,
    StyledContent,
    AddNewUserWrapper,
    Divider,
    StyledSavedStatusContainer,
    StyledButtonContainer,
    UserStatsContainer,
} from './styles';
import { CompanyUserStats } from '../../../../../constants/interfaces/CompanyUserStats';
import { NoFreeUsersModal } from './components/NoFreeUsersModal';
import { ExceededFreeUsersModal } from './components/ExceededFreeUsersModal';
import { useGetCompanyUserStatsService } from '../../../../CompanySettings/store/services';
import { CompanyUserStatsText } from '../../../../../ui/components/CompanyUserStatsText';

const validationSchema = Yup.object().shape<DraftUser>({
    email: Validation.email.trim(),
    firstName: Validation.firstName,
    lastName: Validation.lastName,
} as any);

const ITEMS_PER_PAGE = 20;
const NEW_DRAFT_ID = 'new';

const NewUser: FC<{
    users?: PaginationResult<DraftUser>;
    lastUpdated?: string;
    loading?: boolean;
    params: { draftId?: any };
    company: CompanyInterface;
    navigateToInvitations(): void;
    searchDraftUsers(draftId: number, sp: SearchParams): Promise<any>;
    modifyDraftUsers(users: PaginationResult<DraftUser>): void;
    modifyDraftUsersWithoutValidation(users: PaginationResult<DraftUser>): void;
    resetDraftsPage(): void;
    addStagingUser(stagingId: number, users: DraftUser): Promise<boolean>;
    addMultipleStagingUsers(
        stagingId: number,
        users: DraftUser[]
    ): Promise<boolean>;
    updateStagingUser(
        stagingId: number,
        stagingUserId: number,
        users: DraftUser
    ): Promise<boolean>;
    deleteStagingUser(
        stagingId: number,
        stagingUserIds: number[]
    ): Promise<boolean>;
    inviteStagingUser(
        stagingId: number,
        stagingUserIds: number[],
        selectAll: boolean,
        search: SearchParams
    ): Promise<any>;
    assignFiltersToStagingUsers(
        stagingId: number,
        assignFilters: AssignFiltersToStagingUsersParams
    ): Promise<any>;
    setGoBackTwice(enable: boolean): void;
    getCompanyUserStats: (companyId: number, callback?: (result: CompanyUserStats) => void) => void;
}> = ({
    users,
    lastUpdated,
    loading,
    params,
    company,
    navigateToInvitations,
    searchDraftUsers,
    modifyDraftUsers,
    modifyDraftUsersWithoutValidation,
    resetDraftsPage,
    addStagingUser,
    addMultipleStagingUsers,
    updateStagingUser,
    deleteStagingUser,
    inviteStagingUser,
    assignFiltersToStagingUsers,
    setGoBackTwice,
    getCompanyUserStats
}) => {
    const showMessage = useShowMessage();
    const history = useHistory();
    useHtmlPageTitle('Invite Users to the App')

    const existedEmailListRef = useRef<string[]>([]);
    const existedEmailList = existedEmailListRef.current;
    const { data: initialTeams } = usePortableTeamsState();
    const defaultTeamId = initialTeams?.items?.find(item => item.isDefault)?.id;

    const [noFreeUsersModalOpen, setNoFreeUsersModalOpen] = useState(false);
    const [exceededFreeUsersModalOpen, setExceededFreeUsersModalOpen] = useState(false);

    const formRef: any = React.createRef();
    const [loadingInvitation, setLoadingInvitation] = useState(false);
    const [saveDraftModalOpen, setSaveDraftModalOpen] = useState(false);
    const [creatingUser, setCreatingUser] = useState(false);
    const [usersSavedAt, setUsersSavedAt] = useState<Date | null>(null);
    const [draftSavedFirstTimeAt, setDraftSavedFirstTimeAt] =
        useState<Date | null>(null);

    const isDraftSaved = params.draftId !== NEW_DRAFT_ID;
    const profile = useSelector(getProfileState);
    const isRoleDisabled = isTeamLeader(profile?.role?.name);

    const userEmptyState: DraftUser = {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        labelIDs: [],
        practisSetIDs: [],
        teamIDs: [],
        roleId: null,
    };

    const userRoles = useUserRolesState();
    const userRoleId = userRoles.list.filter(
        role => role.name === ROLE_NAMES.USER
    )[0];

    // NEW USER STATE LOGIC
    const [newUser, setNewUser] = useState<DraftUser>(userEmptyState);

    const [newUserRole, setNewUserRole] = useState<number | null>(null);
    const [newUserPractisSets, setNewUserPractisSets] = useState<
        PractisSetWithDueDate[]
    >([]);
    const [newUserTeams, setNewUserTeams] = useState<number[]>([]);
    const [newUserLabels, setNewUserLabels] = useState<number[]>([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const handleSetNewUserLabels = (labelIds: number[]) => {
        setNewUserLabels(labelIds);
    };

    const handleChangeNewUserRole = useCallback(
        (role: number | null) => {
            setNewUserRole(role);
        },
        [setNewUserRole]
    );

    const handleSetNewUserPractisSets = (
        practisSetIds: PractisSetWithDueDate[]
    ) => {
        setNewUserPractisSets(practisSetIds);
    };

    const handleSetNewUserTeams = (teamIds: number[]) => {
        setNewUserTeams(teamIds);
    };

    // SELECTED USER STATE LOGIC
    const [selectedUser, setSelectedUser] = useState<DraftUser>(userEmptyState);
    const [selectedUserRole, setSelectedUserRole] = useState<number | null>(
        null
    );
    const [selectedUserPractisSets, setSelectedUserPractisSets] = useState<
        PractisSetWithDueDate[]
    >([]);
    const [selectedUserTeams, setSelectedUserTeams] = useState<number[]>([]);
    const [selectedUserLabels, setSelectedUserLabels] = useState<number[]>([]);

    const handleSetSelectedUserLabels = (labelIds: number[]) => {
        setSelectedUserLabels(labelIds);
    };
    const handleChangeSelectedUserRole = useCallback(
        (role: number | null) => {
            setSelectedUserRole(role);
        },
        [setSelectedUserRole]
    );

    const handleSetSelectedUserPractisSets = (
        practisSetIds: PractisSetWithDueDate[]
    ) => {
        setSelectedUserPractisSets(practisSetIds);
    };

    const handleSetSelectedUserTeams = (teamIds: number[]) => {
        setSelectedUserTeams(teamIds);
    };

    const isTeamLead = isTeamLeader(profile?.role?.name);
    useEffect(() => {
        if (userRoleId?.id && isTeamLead) {
            setNewUserRole(userRoleId.id);
            setSelectedUserRole(userRoleId.id);
        }
    }, [userRoleId, isTeamLead]);

    // LIST USERS STATE LOGIC
    const [checkedUserIds, setCheckedUserIds] = useState<number[]>([]);

    const [checkedUserRoles, setCheckedUserRoles] = useState<number[]>([]);

    const handleClearCheckedUserRoles = (roleIds: number[]) => {
        setCheckedUserRoles(roleIds);
    };

    const handleAssignFiltersToCheckedUsers = async (
        assignedLabels: number[],
        deletedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        deletedPractisSets: number[],
        assignedTeams: number[],
        deletedTeams: number[],
        roleId: null | number
    ) => {
        if (isDraftSaved) {
            const limit = isSelectAll ? users?.totalCount : searchParams?.limit;

            return assignFiltersToStagingUsers(params.draftId, {
                userIDs: checkedUserIds,
                labelIDs: assignedLabels,
                roleId: isRoleDisabled ? null : roleId,
                deletedLabelIDs: deletedLabels,
                practisSetIDs: assignedPractisSets,
                deletedPractisSetIDs: deletedPractisSets,
                teamIDs: assignedTeams,
                deletedTeamIDs: deletedTeams,
                selectAll: isSelectAll,
                search: { ...searchParams, limit },
            })
                .then(() => {
                    manualRefreshSearchParams();
                    const count =
                        (isSelectAll
                            ? users?.totalCount
                            : checkedUserIds.length) || 0;

                    showMessage(
                        `Changes  ${
                            count > 1 ? 'have' : 'has'
                        } been saved for ${count} ${
                            count > 1 ? 'users' : 'user'
                        }`,
                        'success'
                    );
                    return true;
                })
                .catch(() => {
                    return false;
                });
        } else {
            let filteredUsers = userItemsList ? [...userItemsList] : [];

            if (assignedLabels.length > 0) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        const updatedUserLabels = new Set([
                            ...user.labelIDs,
                            ...assignedLabels,
                        ]);
                        return {
                            ...user,
                            labelIDs: Array.from(updatedUserLabels),
                        };
                    } else {
                        return user;
                    }
                });
            }

            if (deletedLabels.length > 0) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        const updatedUserLabels = user.labelIDs.filter(
                            id => !deletedLabels.includes(id)
                        );
                        return {
                            ...user,
                            labelIDs: updatedUserLabels,
                        };
                    } else {
                        return user;
                    }
                });
            }

            if (assignedPractisSets.length > 0) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        const updatedUserPractisSets = uniqBy(
                            [...assignedPractisSets, ...user.practisSetIDs],
                            practisSet => practisSet.practisSetId
                        );

                        return {
                            ...user,
                            practisSetIDs: updatedUserPractisSets,
                            practisSets: updatedUserPractisSets,
                        };
                    } else {
                        return user;
                    }
                });
            }

            if (deletedPractisSets.length > 0) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        const updatedUserPractisSets =
                            user.practisSetIDs.filter(
                                id =>
                                    !deletedPractisSets.includes(
                                        id.practisSetId
                                    )
                            );
                        return {
                            ...user,
                            practisSetIDs: updatedUserPractisSets,
                        };
                    } else {
                        return user;
                    }
                });
            }

            if (assignedTeams.length > 0) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        const updatedUserTeams = new Set([
                            ...user.teamIDs,
                            ...assignedTeams,
                        ]);
                        return {
                            ...user,
                            teamIDs: Array.from(updatedUserTeams),
                        };
                    } else {
                        return user;
                    }
                });
            }

            if (deletedTeams.length > 0) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        const updatedUserTeams = user.teamIDs.filter(
                            id => !deletedTeams.includes(id)
                        );
                        return {
                            ...user,
                            teamIDs: updatedUserTeams,
                        };
                    } else {
                        return user;
                    }
                });
            }

            if (roleId && !isRoleDisabled) {
                filteredUsers = filteredUsers.map(user => {
                    if (checkedUserIds.includes(user.id)) {
                        return {
                            ...user,
                            roleId: roleId,
                        };
                    } else {
                        return user;
                    }
                });
            }
            showMessage(
                `Changes  ${
                    checkedUserIds.length > 1 ? 'have' : 'has'
                } been saved for ${checkedUserIds.length} ${
                    checkedUserIds.length > 1 ? 'users' : 'user'
                }`,
                'success'
            );
            modifyDraftUsers({
                ...users,
                items: filteredUsers,
            } as PaginationResult<DraftUser>);
            return true;
        }
    };

    // FILTERS
    const [filterRoles, setFilterRoles] = useState<number[]>([]);

    const initialLocalFilterParams: {
        searchTerm: string;
        labelIDs: number[];
        practisSetIDs: number[];
        roleIDs: number[];
        teamIDs: number[];
    } = {
        searchTerm: '',
        labelIDs: [],
        practisSetIDs: [],
        roleIDs: [],
        teamIDs: [],
    };

    const [localFilterParams, setLocalFilterParams] = useState(
        initialLocalFilterParams
    );
    const [filtersCount, setFiltersCount] = useState<number>(0);

    const ifLocalFilterParamsChanged = useIfChanged(localFilterParams);
    const ifUsersChanged = useIfChanged(users);
    const [localUsers, setLocalUsers] = useState<
        PaginationResult<DraftUser> | undefined
    >(users && users);

    useEffect(() => {
        if (users) {
            setLocalUsers(users);
        }
    }, [users]);

    useEffect(() => {
        if ((ifLocalFilterParamsChanged || ifUsersChanged) && !isDraftSaved) {
            let filteredUser: DraftUser[] =
                users && users.items ? users.items : [];
            if (localFilterParams.roleIDs.length > 0) {
                filteredUser = filteredUser.filter(user => {
                    return (
                        user.roleId &&
                        localFilterParams.roleIDs.includes(user.roleId)
                    );
                });
            }

            if (localFilterParams.labelIDs.length > 0) {
                filteredUser = filteredUser.filter(user => {
                    return localFilterParams.labelIDs.some(
                        id => user.labelIDs.indexOf(id) >= 0
                    );
                });
            }

            if (localFilterParams.practisSetIDs.length > 0) {
                filteredUser = filteredUser.filter(user => {
                    return localFilterParams.practisSetIDs.some(
                        //filter to find if filtered practis set is assigned to user
                        id =>
                            !!user.practisSetIDs.some(
                                x => x.practisSetId === id
                            )
                    );
                });
            }

            if (localFilterParams.teamIDs.length > 0) {
                filteredUser = filteredUser.filter(user => {
                    return localFilterParams.teamIDs.some(
                        id => user.teamIDs.indexOf(id) >= 0
                    );
                });
            }

            if (localFilterParams.searchTerm.length > 0) {
                const escapedSearchTerm = escapeRegExp(
                    localFilterParams.searchTerm
                ).toLowerCase();
                const regSearchTerm = new RegExp(escapedSearchTerm);

                const trimmedRegSearchTerm = new RegExp(
                    escapeRegExp(
                        localFilterParams.searchTerm.trim()
                    ).toLowerCase()
                );

                filteredUser = filteredUser.filter(user => {
                    return (
                        (user.firstName &&
                            regSearchTerm.test(user.firstName.toLowerCase())) ||
                        (user.firstName &&
                            user.lastName &&
                            trimmedRegSearchTerm.test(
                                `${user.firstName} ${user.lastName}`
                                    .trim()
                                    .toLowerCase()
                            )) ||
                        (user.lastName &&
                            regSearchTerm.test(user.lastName.toLowerCase())) ||
                        (user.email &&
                            regSearchTerm.test(user.email?.toLowerCase()))
                    );
                });
            }

            setLocalUsers(prevState => {
                if (prevState) {
                    return {
                        ...prevState,
                        items: filteredUser,
                    };
                }
            });
        }
    }, [
        ifLocalFilterParamsChanged,
        isDraftSaved,
        ifUsersChanged,
        localFilterParams,
        modifyDraftUsers,
        users,
    ]);

    const userItemsList = !isDraftSaved
        ? localUsers && localUsers.items
        : users && users.items;

    const setLocalSearchTerm = (value: string) => {
        setLocalFilterParams(prevState => {
            return {
                ...prevState,
                searchTerm: value,
            };
        });
    };

    const handleApplyFiltersToCheckedUsers = async (
        labelIDs: number[],
        practisSetIDs: PractisSetWithDueDate[],
        teamIDs: number[],
        roleIDs: number[]
    ) => {
        setFiltersCount(
            labelIDs.length +
                teamIDs.length +
                practisSetIDs.length +
                roleIDs.length
        );

        if (isDraftSaved) {
            setLabelIDs(labelIDs);
            //PSWDD maybe change type
            setPractisSetIDs(practisSetIDs.map(x => x.practisSetId));
            setTeamIDs(teamIDs);
            if (!isRoleDisabled) {
                setRoleIDs(roleIDs);
            }
        } else {
            setLocalFilterParams((prevState: any) => {
                return {
                    ...prevState,
                    teamIDs: teamIDs,
                    labelIDs: labelIDs,
                    practisSetIDs: practisSetIDs.map(x => x.practisSetId),
                    roleIDs: isRoleDisabled ? [] : roleIDs,
                };
            });
        }
        return true;
    };

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        orderBy: { field: 'lastEditedBy.firstName', asc: false },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setPractisSetIDs,
        setRoleIDs,
        setTeamIDs,
        setLimit,
    } = useSearchParamsState(initialSearchParams);
    const ifSearchParamsChanged = useIfChanged(searchParams);
    const ifDraftIdChanged = useIfChanged(params.draftId);

    const manualRefreshSearchParams = useCallback(async () => {
        return searchDraftUsers(params.draftId, searchParams).then(
            updatedUsers => {
                setUsersSavedAt(new Date());
                setDraftSavedFirstTimeAt(null);
                return updatedUsers;
            }
        );
    }, [searchDraftUsers, params.draftId, searchParams]);

    useEffect(() => {
        if ((ifSearchParamsChanged || ifDraftIdChanged) && isDraftSaved) {
            searchDraftUsers(params.draftId, searchParams).catch(console.error);
        }
    }, [
        params.draftId,
        searchDraftUsers,
        searchParams,
        ifSearchParamsChanged,
        ifDraftIdChanged,
        isDraftSaved,
    ]);

    useEffect(() => {
        if (userItemsList) {
            const userItemListIds: number[] = userItemsList.map(
                user => user.id
            );
            let updatedCheckedUserIds = checkedUserIds.filter(userId =>
                userItemListIds.includes(userId)
            );
            setCheckedUserIds(
                !isSelectAll ? updatedCheckedUserIds : userItemListIds
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userItemsList]);

    const resetNewUserFields = () => {
        setNewUser(JSON.parse(JSON.stringify(userEmptyState)));
        if (!isRoleDisabled) {
            setNewUserRole(null);
        }
        setNewUserPractisSets([]);
        setNewUserTeams([]);
        setNewUserLabels([]);
    };

    const resetEditingUserFields = () => {
        setSelectedUser(JSON.parse(JSON.stringify(userEmptyState)));
        if (!isRoleDisabled) {
            setSelectedUserRole(null);
        }
        setSelectedUserTeams([]);
        setSelectedUserPractisSets([]);
        setSelectedUserLabels([]);
    };

    const handleUpdateSelectedUser = () => {
        const modifiedUser: DraftUser = {
            ...selectedUser,
            email: selectedUser.email.trim(),
            id: selectedUser.id,
            roleId: selectedUserRole,
            labelIDs: selectedUserLabels.filter(labelId => labelId > 0) || [],
            practisSetIDs: selectedUserPractisSets || [],
            practisSets: selectedUserPractisSets || [],
            teamIDs: selectedUserTeams || [],
            emailExistsInSystem: false,
        };

        let updatedUserItems = users ? [...users.items] : [];
        const userExists =
            updatedUserItems.filter(user => user.id === modifiedUser.id)
                .length > 0;

        if (userExists) {
            updatedUserItems = updatedUserItems.map(user => {
                if (user.id === modifiedUser.id) {
                    return modifiedUser;
                } else {
                    return user;
                }
            });
        } else {
            updatedUserItems.unshift(modifiedUser);
        }

        const filteredUser = updatedUserItems.map((item, index) => ({
            ...item,
            isEntryInvalid:
                !!item.email &&
                index < updatedUserItems.length - 1 &&
                updatedUserItems
                    .slice(index + 1)
                    .filter(user => user.email === item.email).length > 0,
        }));

        if (isDraftSaved) {
            if (userExists) {
                updateStagingUser(params.draftId, modifiedUser.id, {
                    firstName: modifiedUser.firstName,
                    lastName: modifiedUser.lastName,
                    email: modifiedUser.email,
                    roleId: modifiedUser.roleId,
                    labelIDs: modifiedUser.labelIDs
                        ? modifiedUser.labelIDs
                        : [],
                    practisSetIDs: modifiedUser.practisSetIDs ?? [],
                    teamIDs: modifiedUser.teamIDs,
                }).then(() => {
                    resetEditingUserFields();
                    manualRefreshSearchParams().catch(console.error);
                });
            } else {
                addStagingUser(params.draftId, {
                    firstName: modifiedUser.firstName,
                    lastName: modifiedUser.lastName,
                    email: modifiedUser.email,
                    roleId: modifiedUser.roleId,
                    labelIDs: modifiedUser.labelIDs
                        ? modifiedUser.labelIDs
                        : [],
                    practisSetIDs: modifiedUser.practisSetIDs ?? [],
                    practisSets: modifiedUser.practisSets ?? [],
                    teamIDs: modifiedUser.teamIDs,
                }).then(() => {
                    resetEditingUserFields();
                    manualRefreshSearchParams().catch(console.error);
                });
            }
        } else {
            modifyDraftUsers({
                ...users,
                items: filteredUser,
            } as PaginationResult<DraftUser>);
            resetEditingUserFields();
        }
    };

    const handleCreateNewUser = (user: DraftUser) => {
        const modifiedUser: DraftUser = {
            ...user,
            id: Date.now(), // random unique number
            roleId: newUserRole,
            labelIDs: newUserLabels.filter(labelId => labelId > 0) || [],
            practisSetIDs: newUserPractisSets || [],
            practisSets: newUserPractisSets || [],
            teamIDs: newUserTeams || [],
            teamsCount: `${(newUserTeams || []).length}`,
        };

        let updatedUserItems = users ? [...users.items] : [];
        const userExists =
            updatedUserItems.filter(user => user.id === modifiedUser.id)
                .length > 0;

        if (userExists) {
            updatedUserItems = updatedUserItems.map(user => {
                if (user.id === modifiedUser.id) {
                    return modifiedUser;
                } else {
                    return user;
                }
            });
        } else {
            updatedUserItems.unshift(modifiedUser);
        }

        const filteredUser = updatedUserItems.map((item, index) => ({
            ...item,
            isEntryInvalid:
                !!item.email &&
                index < updatedUserItems.length - 1 &&
                updatedUserItems
                    .slice(index + 1)
                    .filter(user => user.email === item.email).length > 0,
        }));

        if (isDraftSaved) {
            if (userExists) {
                updateStagingUser(params.draftId, modifiedUser.id, {
                    firstName: modifiedUser.firstName,
                    lastName: modifiedUser.lastName,
                    email: modifiedUser.email,
                    roleId: modifiedUser.roleId,
                    labelIDs: modifiedUser.labelIDs
                        ? modifiedUser.labelIDs
                        : [],
                    practisSetIDs: modifiedUser.practisSetIDs ?? [],
                    teamIDs: modifiedUser.teamIDs,
                }).then(() => {
                    resetNewUserFields();
                    manualRefreshSearchParams().catch(console.error);
                });
            } else {
                addStagingUser(params.draftId, {
                    firstName: modifiedUser.firstName,
                    lastName: modifiedUser.lastName,
                    email: modifiedUser.email,
                    roleId: modifiedUser.roleId,
                    labelIDs: modifiedUser.labelIDs
                        ? modifiedUser.labelIDs
                        : [],
                    practisSetIDs: modifiedUser.practisSetIDs ?? [],
                    teamIDs: modifiedUser.teamIDs,
                }).then(() => {
                    resetNewUserFields();
                    manualRefreshSearchParams().catch(console.error);
                });
            }
        } else {
            modifyDraftUsers({
                ...users,
                items: filteredUser,
            } as PaginationResult<DraftUser>);
            resetNewUserFields();
        }
    };

    const handleSubmitForm = () => {
        if (formRef.current) {
            if (newUserRole) {
                formRef.current.handleSubmit();
            }
        }
    };

    useKeyPress('Enter', handleSubmitForm);

    const onUsersImported = (importedUsers: DraftUser[]) => {
        if (isDraftSaved) {
            const sendImportedUsers = importedUsers.map(user => {
                return {
                    firstName: user.firstName ? user.firstName : '',
                    lastName: user.lastName ? user.lastName : '',
                    email: user.email ? user.email.trim() : '',
                    roleId: user.roleId ? user.roleId : null,
                    practisSetIDs: [],
                    teamIDs: [],
                    labelIDs: [],
                };
            });

            addMultipleStagingUsers(params.draftId, sendImportedUsers).then(
                () => {
                    manualRefreshSearchParams().catch(console.error);
                }
            );
        } else {
            const currentUsers = userItemsList ? [...userItemsList] : [];
            const finalReformattedResults = [
                ...currentUsers.filter((user: any) => !!user.email),
                ...importedUsers,
            ];

            const trimmedFinalResults = finalReformattedResults.map(item => {
                return {
                    ...item,
                    email: item.email?.trim(),
                };
            });

            modifyDraftUsers({
                ...users,
                items: trimmedFinalResults,
            } as PaginationResult<DraftUser>);
        }
    };

    const onEditUser = (user: DraftUser) => {
        if (!user) return;
        setSelectedUser({
            ...user,
            email: user.email || '',
        });

        setSelectedUserRole(user.roleId);
        setSelectedUserLabels(user.labelIDs || []);

        if (isDraftSaved) {
            setSelectedUserPractisSets(user.practisSets || []);
        } else {
            setSelectedUserPractisSets(user.practisSetIDs || []);
        }

        setSelectedUserTeams(user.teamIDs || []);
    };

    const onRemoveUser = (userId: number | string) => {
        const userItems = users && users.items ? users.items : [];
        const updatedList = userItems.filter(user => user.id !== userId);
        const finalResult = updatedList.map((item, index) => ({
            ...item,
            isEntryInvalid:
                !!item.email &&
                index < updatedList.length - 1 &&
                updatedList
                    .slice(index + 1)
                    .filter(user => user.email === item.email).length > 0,
        }));

        if (isDraftSaved) {
            deleteStagingUser(params.draftId, [Number(userId)]).then(() => {
                manualRefreshSearchParams().catch(console.error);
                showMessage('1 User has been removed', 'success');
                if (checkedUserIds.includes(userId as number)) {
                    setCheckedUserIds([]);
                }
            });
        } else {
            modifyDraftUsers({
                ...users,
                items: finalResult,
            } as PaginationResult<DraftUser>);
            showMessage('1 User has been removed', 'success');
            if (checkedUserIds.includes(userId as number)) {
                setCheckedUserIds([]);
            }
        }

        setCheckedUserIds([]);
    };

    const modifySelectedUser = (value: string, field: string) => {
        if (value.length > 100) return;
        setSelectedUser(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const modifyNewUser = (value: string, field: string) => {
        if (value.length > 100) return;
        setNewUser(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleUserCheck = (id?: number | string) => {
        if (!id) return;
        const checkUser = checkedUserIds.find((item: any) => item === id);
        setCheckedUserIds((prevState: any) =>
            checkUser
                ? prevState.filter((item: any) => item !== id)
                : [...prevState, id]
        );
        if (checkUser) {
            setIsSelectAll(false);
        }
    };

    const updateAllUsersCheck = (checked: boolean) => {
        const userItems =
            users && users.items
                ? users.items.filter(
                      item => !item.isEntryInvalid && !item.emailExistsInSystem
                  )
                : [];

        setCheckedUserIds(checked ? userItems.map(item => item.id) : []);
        setIsSelectAll(false);
    };

    const updateSelectAll = (checked: boolean) => {
        setIsSelectAll(checked);
    };

    const clearAllUsers = () => {
        setCheckedUserIds([]);
    };

    const onRemoveSelectedUsers = () => {
        const userItems = users && users.items ? users.items : [];
        const deletedUsersLength = checkedUserIds.length;

        if (isDraftSaved) {
            deleteStagingUser(params.draftId, checkedUserIds).then(() => {
                manualRefreshSearchParams().catch(console.error);

                showMessage(
                    `${deletedUsersLength} ${
                        deletedUsersLength > 1 ? 'Users have' : 'User has'
                    } been removed`,
                    'success'
                );

                setCheckedUserIds([]);
            });
        } else {
            const updatedList = userItems.filter(
                user => !checkedUserIds.includes(user.id)
            );
            showMessage(
                `${deletedUsersLength} ${
                    deletedUsersLength > 1 ? 'Users have' : 'User has'
                } been removed`,
                'success'
            );
            modifyDraftUsers({
                ...users,
                items: updatedList,
            } as PaginationResult<DraftUser>);
            setCheckedUserIds([]);
        }
    };

    const [existingUserIds, setExistingUserIds] = useState<any>([]);

    useEffect(() => {
        if (users && users.items) {
            const userItems = users && users.items ? users.items : [];
            let existingUsers = userItems.filter(
                user => user.emailExistsInSystem
            );
            setExistingUserIds(existingUsers.map(user => user.id));
        }
    }, [users]);

    const onRemoveExistingUsers = () => {
        const userItems = users && users.items ? users.items : [];
        if (existingUserIds.length < 1) return;

        if (isDraftSaved) {
            deleteStagingUser(params.draftId, existingUserIds).then(() => {
                manualRefreshSearchParams().catch(console.error);
                showMessage(
                    `${existingUserIds.length} Existing ${
                        existingUserIds.length > 1 ? 'users have' : 'user has'
                    } been removed`,
                    'success'
                );
                setCheckedUserIds([]);
            });
        } else {
            const updatedList = userItems.filter(
                user => !existingUserIds.includes(user.id)
            );
            showMessage(
                `${existingUserIds.length} Existing ${
                    existingUserIds.length > 1 ? 'users have' : 'user has'
                } been removed`,
                'success'
            );
            modifyDraftUsers({
                ...users,
                items: updatedList,
            } as PaginationResult<DraftUser>);
            setCheckedUserIds([]);
        }
    };

    /**
     * @function checkIsAllItemsSelected
     * @returns { boolean }
     */
    const checkIsAllItemsSelected = () =>
        users?.totalCount === checkedUserIds.length;

    /**
     * @function handleSuccessAllUsersInvited
     * @returns { void }
     */
    const handleSuccessAllUsersInvited = useCallback(() => {
        // if previous page was pending users page, dispatch refresh the page.
        if (
            history?.location?.state?.background?.pathname ===
            ROUTES.INVITATIONS
        ) {
            onTriggerEvent(EventNameList[REFRESH_PENDING_USERS_PAGE]);
        }

        showMessage('All Users have been invited', 'success');
        navigateToInvitations();
    }, [history, navigateToInvitations, showMessage]);

    /**
     * @function handleErrorUsersInvited
     * @returns { void }
     */
    const handleErrorUsersInvited = useCallback(
        (invitedUsers: ChunkServiceUserDataInterface[]) => {
            const alreadyInvitedUserItems: ChunkServiceUserDataInterface[] = [];

            for (const user of invitedUsers) {
                if (!existedEmailList.includes(user.email?.toLowerCase())) {
                    alreadyInvitedUserItems.push(user);
                }
            }
            const remainingUserItems = users?.items
                .filter(
                    user =>
                        !alreadyInvitedUserItems.some(
                            item => item.email === user.email
                        )
                )
                .map(user =>
                    existedEmailList.includes(user.email?.toLowerCase())
                        ? { ...user, emailExistsInSystem: true }
                        : user
                );

            if (!isEmpty(remainingUserItems)) {
                alreadyInvitedUserItems.length > 0 &&
                    (alreadyInvitedUserItems.length > 1
                        ? showMessage(
                              `${alreadyInvitedUserItems?.length} Users have been invited`,
                              'success'
                          )
                        : showMessage(
                              `${alreadyInvitedUserItems?.length} User has been invited`,
                              'success'
                          ));

                modifyDraftUsers({
                    ...users,
                    items: remainingUserItems,
                } as PaginationResult<DraftUser>);
            } else {
                handleSuccessAllUsersInvited();
            }
        },
        [
            existedEmailList,
            handleSuccessAllUsersInvited,
            modifyDraftUsers,
            showMessage,
            users,
        ]
    );

    /**
     * @dev Contains all conditions can error happens.
     * @function checkIfInviteUsersHaveErrors
     * @param { ChunkServiceUserDataInterface[] } invitedUsers
     * @returns { boolean }
     */
    const checkIfInviteUsersHaveErrors = useCallback(
        (invitedUsers: ChunkServiceUserDataInterface[]): boolean => {
            if (
                (!isEmpty(existedEmailList) &&
                    (existedEmailList.length === invitedUsers.length ||
                        invitedUsers.length === 1)) ||
                users?.items?.some(item => item.emailExistsInSystem) ||
                users?.items?.some(item => item.isEntryInvalid)
            ) {
                return true;
            }

            return false;
        },

        [existedEmailList, users?.items]
    );

    /**
     * @function onSuccessInviteUsersCallback
     * @returns { void }
     */
    const onSuccessInviteUsersCallback = useCallback(
        (invitedUsers: ChunkServiceUserDataInterface[]) => {
            if (checkIfInviteUsersHaveErrors(invitedUsers)) {
                handleErrorUsersInvited(invitedUsers);
            } else {
                if (
                    isSelectAll ||
                    invitedUsers.length === users?.items.length ||
                    users?.items.length === 1
                ) {
                    handleSuccessAllUsersInvited();
                } else {
                    invitedUsers.length > 0 &&
                        (invitedUsers.length > 1
                            ? showMessage(
                                  `${invitedUsers?.length} Users have been invited`,
                                  'success'
                              )
                            : showMessage(
                                  `${invitedUsers?.length} User has been invited`,
                                  'success'
                              ));
                    modifyDraftUsers({
                        ...users,
                        items: users?.items.filter(
                            user =>
                                !invitedUsers.some(
                                    invitedUser =>
                                        invitedUser.email?.toLowerCase() ===
                                        user.email?.toLowerCase()
                                )
                        ),
                    } as PaginationResult<DraftUser>);
                }
            }

            Promise.resolve().then(() => {
                setCheckedUserIds([]);
                setIsSelectAll(false);
                setLoadingInvitation(false);
                existedEmailList.length = 0; // clear existedEmailListRef
                getCompanyUserStats(company.id!);
            });
        },
        [checkIfInviteUsersHaveErrors, handleErrorUsersInvited, isSelectAll, users, handleSuccessAllUsersInvited, showMessage, modifyDraftUsers, existedEmailList, getCompanyUserStats, company.id]
    );

    /**
     * @function onFailedInviteUsersCallback
     * @param { ErrorResult | undefined } error
     * @returns { void }
     */
    const onFailedInviteUsersCallback = useCallback(
        (error?: ErrorResult) => {
            if (error?.message) {
                const existedEmail = extractEmailsFromString(
                    error.message
                )?.[0];

                if (existedEmail) {
                    existedEmailList.push(existedEmail);
                }
            }
        },
        [existedEmailList]
    );

    const inviteUsersBulkActionService = useInviteUsersBulActionService(
        onSuccessInviteUsersCallback,
        onFailedInviteUsersCallback
    );

    const onInviteSelectedUsers = () => {
        setLoadingInvitation(true);

        getCompanyUserStats(company.id!, (userStats) => {
            const userItems = users && users.items ? users.items : [];
            const isAllItemsSelected = checkIsAllItemsSelected() || isSelectAll;

            const checkedUsersIds = isAllItemsSelected ? [] : checkedUserIds;

            if (userStats!.limit && userStats!.limit <= userStats!.total) {
                setLoadingInvitation(false);
                setNoFreeUsersModalOpen(true);
                return;
            }

            if (isDraftSaved) {
                const limit = isAllItemsSelected
                    ? users!.totalCount
                    : searchParams.limit;

                const filteredUsers = userItems.filter(user =>
                    checkedUserIds.includes(user.id)
                );

                if (
                    userStats!.limit
                    && userStats!.limit < userStats!.total + (isAllItemsSelected ? users!.totalCount : filteredUsers.length)
                ) {
                    setLoadingInvitation(false);
                    setExceededFreeUsersModalOpen(true);
                    return;
                }

                if (
                    filteredUsers?.length > MAX_DRAFT_USERS_INVITE ||
                    isAllItemsSelected
                ) {
                    showMessage(
                        `We're sending ${
                            filteredUsers?.length || 'all'
                        } invitations. This might take a while.`,
                        'success'
                    );
                    navigateToInvitations();
                }

                inviteStagingUser(
                    params.draftId,
                    checkedUsersIds,
                    isAllItemsSelected,
                    { ...searchParams, limit }
                )
                    .then(invitationResults => {
                        const updatedErroredUsers = userItems.map(user => {
                            if (checkedUserIds.includes(user.id)) {
                                return {
                                    ...user,
                                    emailExistsInSystem:
                                        invitationResults &&
                                        invitationResults[user.id] &&
                                        invitationResults[user.id].error,
                                };
                            } else {
                                return user;
                            }
                        });
                        const updatedFilteredUsers = updatedErroredUsers.filter(
                            user =>
                                (checkedUserIds.includes(user.id) &&
                                    invitationResults &&
                                    invitationResults[user.id] &&
                                    !invitationResults[user.id].id) ||
                                !checkedUserIds.includes(user.id)
                        );
                        setLoadingInvitation(false);

                        const duplicatedUsersCount = updatedErroredUsers.filter(
                            user => user.emailExistsInSystem
                        ).length;

                        const usersInvitedCount =
                            checkedUserIds.length - duplicatedUsersCount;

                        modifyDraftUsersWithoutValidation({
                            ...users,
                            items: updatedErroredUsers,
                        } as PaginationResult<DraftUser>);
                        setCheckedUserIds([]);
                        if (!isAllItemsSelected) {
                            if (updatedFilteredUsers.length < 1) {
                                showMessage(
                                    'All Users have been invited',
                                    'success'
                                );
                                navigateToInvitations();
                            } else {
                                if (
                                    usersInvitedCount > 0 &&
                                    duplicatedUsersCount > 0
                                ) {
                                    showMessage(
                                        `${usersInvitedCount} ${
                                            usersInvitedCount > 1
                                                ? 'Users have'
                                                : 'User has'
                                        } been invited but ${duplicatedUsersCount}  ${
                                            duplicatedUsersCount > 1
                                                ? 'users'
                                                : 'user'
                                        } already exist in our system`,
                                        'error'
                                    );
                                    manualRefreshSearchParams();
                                } else if (
                                    usersInvitedCount > 0 &&
                                    duplicatedUsersCount < 1
                                ) {
                                    showMessage(
                                        `${usersInvitedCount} ${
                                            usersInvitedCount > 1
                                                ? 'Users have'
                                                : 'User has'
                                        } been invited `,
                                        'success'
                                    );
                                    manualRefreshSearchParams();
                                } else if (
                                    duplicatedUsersCount > 0 &&
                                    usersInvitedCount < 1
                                ) {
                                    showMessage(
                                        `${duplicatedUsersCount}  ${
                                            duplicatedUsersCount > 1
                                                ? 'Users'
                                                : 'User'
                                        } already exist in our system`,
                                        'error'
                                    );
                                }
                            }
                        }
                        getCompanyUserStats(company.id!);
                    })
                    .catch(() => {
                        setLoadingInvitation(false);
                    });
            } else {
                const filteredUsers = userItems.filter(
                    user =>
                        checkedUserIds.includes(user.id) &&
                        !user.isEntryInvalid &&
                        !user.emailExistsInSystem
                );

                if (
                    userStats!.limit
                    && userStats!.limit < userStats!.total + filteredUsers.length
                ) {
                    setLoadingInvitation(false);
                    setExceededFreeUsersModalOpen(true);
                    return;
                }

                const invitingUsers = filteredUsers.map(
                    user =>
                        ({
                            firstName: user.firstName.toString(),
                            lastName: user.lastName.toString(),
                            email: user.email,
                            roleId: user.roleId,
                            companyId: company.id,
                            labelIds: user.labelIDs,
                            practisSets: user.practisSets,
                            teamsIds: user.teamIDs?.filter(
                                teamId => teamId !== defaultTeamId
                            ), // Remove default team id.
                        } as ChunkServiceUserDataInterface)
                );

                inviteUsersBulkActionService(invitingUsers);
            }
        });
    };

    const handleSaveAsDraft = () => {
        if (!isDraftSaved) {
            setSaveDraftModalOpen(true);
        }
    };

    const saveAsDraftConfirmed = useCallback(() => {
        setUsersSavedAt(new Date());
        setDraftSavedFirstTimeAt(new Date());
        setSaveDraftModalOpen(false);
        setGoBackTwice(true);
        showMessage('Invitation draft has been saved', 'success');
    }, [showMessage, setGoBackTwice]);

    const saveAsDraftFailed = useCallback(
        (error: string) => {
            setSaveDraftModalOpen(false);
            showMessage(error, 'error');
        },
        [showMessage]
    );

    const startedToFillInitialUser =
        !!newUser.firstName ||
        !!newUser.lastName ||
        !!newUser.email ||
        !!newUser.roleId ||
        !!newUserRole ||
        !!newUserLabels.length ||
        !!newUserPractisSets.length;

    const hideNewUserTipDisplayed =
        (!!userItemsList && userItemsList.length > 0) ||
        filtersCount > 0 ||
        searchParams.searchTerm.length > 0 ||
        localFilterParams.searchTerm.length > 0 ||
        startedToFillInitialUser ||
        loading;

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm || localFilterParams.searchTerm,
        appliedFilters: filtersCount,
        itemsCount: userItemsList?.length,
    });

    const userSavedAtText = draftSavedFirstTimeAt
        ? `Saved ${formatDate(draftSavedFirstTimeAt)}`
        : usersSavedAt
        ? `Automatically Saved ${formatDate(usersSavedAt)}`
        : isDraftSaved
        ? lastUpdated
            ? `Last Updated ${formatDate(lastUpdated)}`
            : 'Not Saved'
        : 'Not Saved';

    useEffect(() => {
        resetDraftsPage();
        return () => {
            resetDraftsPage();
        };
    }, [resetDraftsPage]);

    return (
        <StyledContainer>
            {saveDraftModalOpen && (
                <SaveAsDraftModal
                    newUsers={users && users.items ? users.items : []}
                    onClose={() => setSaveDraftModalOpen(false)}
                    onProceed={saveAsDraftConfirmed}
                    onError={saveAsDraftFailed}
                />
            )}
            {noFreeUsersModalOpen && (
                <NoFreeUsersModal
                    onClose={() => setNoFreeUsersModalOpen(false)}
                />
            )}
            {exceededFreeUsersModalOpen && (
                <ExceededFreeUsersModal
                    onClose={() => setExceededFreeUsersModalOpen(false)}
                />
            )}
            <StyledHeader>
                <div>
                    <StyledHeaderInput data-test="invite-users-page-title">
                        Invite Users to Practis
                    </StyledHeaderInput>
                    {company.id && (
                        <UserStatsContainer>
                            <CompanyUserStatsText
                                companyId={company.id}
                                showSettingsButton={isAdminRole(profile?.role?.name)}
                            />
                        </UserStatsContainer>
                    )}
                </div>
            </StyledHeader>
            <NewUserHeader
                users={users}
                localUsers={localUsers}
                selectedUsers={checkedUserIds}
                existingUserIds={existingUserIds}
                onUsersImported={onUsersImported}
                onRemoveUsers={onRemoveSelectedUsers}
                onRemoveExistingUsers={onRemoveExistingUsers}
                disableSearch={tableStates.disableSearch}
                disableFilters={tableStates.disableFilters}
                setSearchTerm={
                    isDraftSaved ? setSearchTerm : setLocalSearchTerm
                }
                assignFilters={handleAssignFiltersToCheckedUsers}
                selectedRoles={checkedUserRoles}
                setSelectedRoles={handleClearCheckedUserRoles}
                filterRoles={filterRoles}
                setFilterRoles={setFilterRoles}
                applyFilters={handleApplyFiltersToCheckedUsers}
                filtersCount={filtersCount}
                isRoleDisabled={isRoleDisabled}
                updateAllUsersCheck={updateAllUsersCheck}
                clearAllUsers={clearAllUsers}
                userItemsList={userItemsList}
                isSelectAll={isSelectAll}
                updateSelectAll={updateSelectAll}
                dataTest="invite-users"
            />
            <StyledContent>
                <UsersList
                    userList={userItemsList}
                    totalUsersCount={users && users.totalCount}
                    selectedUsers={checkedUserIds}
                    updateAllUsersCheck={updateAllUsersCheck}
                    updateSelectAll={updateSelectAll}
                    handleUserCheck={handleUserCheck}
                    onEditUser={onEditUser}
                    onRemoveUser={onRemoveUser}
                    isDraftSaved={isDraftSaved}
                    searchParams={searchParams}
                    limit={searchParams.limit}
                    setLimit={setLimit}
                    orderBy={searchParams.orderBy}
                    setOrderBy={setOrderBy}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    handleUpdateSelectedUser={handleUpdateSelectedUser}
                    assignedRole={selectedUserRole}
                    onAssignRoleCheck={handleChangeSelectedUserRole}
                    selectedUserLabels={selectedUserLabels}
                    setSelectedUserLabels={handleSetSelectedUserLabels}
                    selectedUserPractisSets={selectedUserPractisSets}
                    setSelectedUserPractisSets={
                        handleSetSelectedUserPractisSets
                    }
                    selectedUserTeams={selectedUserTeams}
                    setSelectedUserTeams={handleSetSelectedUserTeams}
                    handleInputChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        field: string
                    ) => {
                        if (e.target.value.length > 100) return;
                        modifySelectedUser(e.target.value, field);
                    }}
                    showNoSearchResultsState={
                        searchParams.searchTerm.length > 0 ||
                        localFilterParams.searchTerm.length > 0
                    }
                    showNoFilterResultsState={
                        tableStates.showNoFilterResultsState
                    }
                    isRoleDisabled={isRoleDisabled}
                    dataTest="invite-users-table"
                >
                    {!tableStates.showNoFilterResultsState &&
                        !searchParams.searchTerm.length &&
                        !localFilterParams.searchTerm.length && (
                            <AddNewUserWrapper>
                                {!hideNewUserTipDisplayed && (
                                    <AddNewUserTip dataTest="invite-users-table-prompt" />
                                )}
                                <Formik<DraftUser>
                                    ref={formRef}
                                    initialValues={newUser}
                                    onSubmit={values => {
                                        values.email = values.email.trim();
                                        setCreatingUser(true);

                                        setTimeout(() => {
                                            setCreatingUser(false);
                                        }, 2000);
                                        handleCreateNewUser(values);
                                    }}
                                    validationSchema={validationSchema}
                                    enableReinitialize={true}
                                >
                                    {({
                                        values,
                                        handleChange,
                                        errors,
                                        submitCount,
                                    }) => (
                                        <Form
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    return;
                                                }
                                            }}
                                        >
                                            <FieldArray
                                                name="users"
                                                render={() => (
                                                    <AddNewUserContainer
                                                        users={
                                                            userItemsList
                                                                ? userItemsList
                                                                : []
                                                        }
                                                        creatingUser={
                                                            creatingUser
                                                        }
                                                        startedToFillInitialUser={
                                                            startedToFillInitialUser
                                                        }
                                                        hideNewUserTipDisplayed={
                                                            hideNewUserTipDisplayed
                                                        }
                                                        hasError={
                                                            (!!errors.email ||
                                                                !!errors.firstName ||
                                                                !!errors.lastName) &&
                                                            submitCount > 0
                                                        }
                                                        errorMessages={errors}
                                                        isSubmitDisabled={
                                                            !values.firstName ||
                                                            !values.lastName ||
                                                            !values.email ||
                                                            !newUserRole
                                                        }
                                                        assignedRole={
                                                            newUserRole
                                                        }
                                                        onAssignRoleCheck={
                                                            handleChangeNewUserRole
                                                        }
                                                        selectedUserPractisSets={
                                                            newUserPractisSets
                                                        }
                                                        setSelectedUserPractisSets={
                                                            handleSetNewUserPractisSets
                                                        }
                                                        selectedUserTeams={
                                                            newUserTeams
                                                        }
                                                        setSelectedUserTeams={
                                                            handleSetNewUserTeams
                                                        }
                                                        selectedUserLabels={
                                                            newUserLabels
                                                        }
                                                        setSelectedUserLabels={
                                                            handleSetNewUserLabels
                                                        }
                                                        handleInputChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>,
                                                            field: string
                                                        ) => {
                                                            if (
                                                                e.target.value
                                                                    .length >
                                                                100
                                                            )
                                                                return;
                                                            modifyNewUser(
                                                                e.target.value,
                                                                field
                                                            );
                                                            handleChange(e);
                                                        }}
                                                        isRoleDisabled={
                                                            isRoleDisabled
                                                        }
                                                        dataTest="invite-users-new"
                                                    />
                                                )}
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </AddNewUserWrapper>
                        )}
                </UsersList>
            </StyledContent>
            <Divider />
            <StyledFooter>
                <StyledFooterActions>
                    <StyledButtonContainer>
                        <StyledSavedStatusContainer data-test="invite-users-saved-label">
                            {userSavedAtText}
                        </StyledSavedStatusContainer>
                    </StyledButtonContainer>
                    {!isDraftSaved && (
                        <CheckPermission
                            permissions={[NEW_PERMISSIONS.CREATE_STAGING]}
                        >
                            <StyledButtonContainer>
                                <Button
                                    action={handleSaveAsDraft}
                                    label="Save as Draft"
                                    variant="inverse"
                                    width="152px"
                                    height="40px"
                                    buttonSize={16}
                                    disabled={
                                        isEmpty(users?.items) ||
                                        loadingInvitation
                                    }
                                    dataTest="invite-users-save-as-draft"
                                />
                            </StyledButtonContainer>
                        </CheckPermission>
                    )}
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.CREATE_STAGING]}
                    >
                        <Button
                            action={onInviteSelectedUsers}
                            label="Invite Selected Users"
                            width="184px"
                            height="40px"
                            buttonSize={16}
                            disabled={
                                !checkedUserIds.length ||
                                !users ||
                                (!!users && !users.items.length)
                            }
                            loading={loadingInvitation}
                            loadingColor="white"
                            dataTest="invite-selected-users"
                        />
                    </CheckPermission>
                </StyledFooterActions>
            </StyledFooter>
        </StyledContainer>
    );
};

export const NewUserContainer: FC<ModalPageContainerProps> = ({
    closePath,
    closeGoingBack,
}) => {
    const params: any = useParams();
    const history = useHistory();
    const state = useDraftEditState();
    const company = useSelector(getCompanyState);
    const searchDraftUsers = useSearchStagingUsersService();
    const modifyDraftUsers = useModifyDraftUsersService();
    const modifyDraftUsersWithoutValidation =
        useModifyDraftUsersWithoutValidationService();
    const resetDraftsPage = useResetDraftsPageService();
    const addStagingUser = useAddStagingUserService();
    const addMultipleStagingUsers = useAddMultipleStagingUsersService();
    const updateStagingUser = useUpdateStagingUserService();
    const deleteStagingUser = useDeleteStagingUserService();
    const inviteStagingUser = useInviteStagingUserService();
    const assignFiltersToStagingUsers = useAssignFiltersToStagingUsersService();
    const searchPractisSets = useGetFullPractisSetsService();
    const showConfirmationModalDialog = useShowConfirmModalDialog();
    const getCompanyUserStats = useGetCompanyUserStatsService();

    useEffect(() => {
        searchPractisSets({
            searchTerm: '',
            filters: [{ field: 'status', value: [LibraryItemsStatus.ACTIVE] }],
            orderBy: {
                field: 'updated_at',
                asc: false,
            },
            offset: 0,
            totalCount: 0,
            numberOfPages: 0,
        });
    }, [searchPractisSets]);

    const isDraftSaved = params.draftId !== NEW_DRAFT_ID;
    const [goBackTwice, setGoBackTwice] = useState(false);

    const goBack = useCallback(() => {
        if (goBackTwice) {
            history.go(-2);
        } else {
            history.goBack();
        }
    }, [history, goBackTwice]);

    const showClosingWarning = () => {
        if (
            !isDraftSaved &&
            state &&
            state.data &&
            state.data.items &&
            state.data.items.length
        ) {
            showConfirmationModalDialog({
                modalTitle: 'Unsaved Progress',
                description:
                    'Are you sure you want to exit? Any unsaved progress will be lost.',
                cancelButtonText: 'Go Back',
                confirmButtonText: 'Exit Without Saving',
                customization: { width: 400, padding: '32px 40px' },
                onConfirm: goBack,
            });
        } else {
            goBack();
        }
    };

    const navigateToInvitations = () => {
        history.replace(ROUTES.INVITATIONS);
    };

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'draftEdit' }}>
            <WithTeamsContext.Provider value={{ reducerName: 'draftEdit' }}>
                <WithPractisSetsContext.Provider
                    value={{ reducerName: 'draftEdit' }}
                >
                    <ModalPage
                        closePath={closePath}
                        closeGoingBack={closeGoingBack}
                        restrictClosing={true}
                        restrictClosingAction={showClosingWarning}
                    >
                        <NewUser
                            params={params}
                            users={state && state.data}
                            lastUpdated={
                                state && state.data && state.data.lastUpdated
                            }
                            loading={state.loading}
                            company={company}
                            navigateToInvitations={navigateToInvitations}
                            modifyDraftUsers={modifyDraftUsers}
                            modifyDraftUsersWithoutValidation={
                                modifyDraftUsersWithoutValidation
                            }
                            resetDraftsPage={resetDraftsPage}
                            searchDraftUsers={searchDraftUsers}
                            addStagingUser={addStagingUser}
                            addMultipleStagingUsers={addMultipleStagingUsers}
                            updateStagingUser={updateStagingUser}
                            deleteStagingUser={deleteStagingUser}
                            inviteStagingUser={inviteStagingUser}
                            assignFiltersToStagingUsers={
                                assignFiltersToStagingUsers
                            }
                            setGoBackTwice={setGoBackTwice}
                            getCompanyUserStats={getCompanyUserStats}
                        />
                    </ModalPage>
                </WithPractisSetsContext.Provider>
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};
