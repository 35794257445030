import { useCallback } from "react";
import { useSelector } from "react-redux";
import { sendEmail } from "../../../../../../../helpers/functions/email-helpers";
import ROUTES from "../../../../../../../routes/routes";
import { useHistory } from "../../../../../../../tools/router";
import { getCompanyState } from "../../../../../../CompanySettings/store/reducers";
import { getProfileState } from "../../../../../../UserProfile/store/reducers";
import { UserLimitWarningModalProps } from "./types";
import UserLimitWarningModalView from "./view";

function UserLimitWarningModal({
    title, description, quickTip, width, onClose
}: UserLimitWarningModalProps) {
    const history = useHistory();
    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);

    const handleManageInvitations = useCallback(() => {
        history.push(ROUTES.INVITATIONS);
    }, [history]);

    const handleRequestLimitChange = useCallback(() => {
        if (!profile || !company) return;
        sendEmail(
            `Licensed seats limit change request from ${profile.firstName} ${profile.lastName} at ${company.name}`
        );
    }, [company, profile]);

    return (
        <UserLimitWarningModalView
            title={title}
            description={description}
            quickTip={quickTip}
            width={width}
            onClose={onClose}
            onManageInvitations={handleManageInvitations}
            onRequestLimitChange={handleRequestLimitChange}
        />
    );
}

export default UserLimitWarningModal;