import { Reducer } from 'redux';
import {
    ACTIONS,
    createPlaylistAction,
    goNextAction,
    playAudioAction,
    resetPlaylistAction,
    startAudioRecordingAction,
    stopAudioAction,
    stopAudioRecordingAction,
} from './actions';
import { AppState } from '../../../store/reducers';

export interface AudioInterface {
    playlist: number[];
    status: 'single' | 'playlist' | 'off';
    recording: false | number | string;
    current: number | string | null;
    next: number | null;
}

const initialAudioState: AudioInterface = {
    playlist: [],
    recording: false,
    status: 'off',
    current: null,
    next: null,
};

type AudioAction =
    | ReturnType<typeof createPlaylistAction>
    | ReturnType<typeof playAudioAction>
    | ReturnType<typeof stopAudioAction>
    | ReturnType<typeof resetPlaylistAction>
    | ReturnType<typeof startAudioRecordingAction>
    | ReturnType<typeof stopAudioRecordingAction>
    | ReturnType<typeof goNextAction>;

export const audioReducer: Reducer<AudioInterface, AudioAction> = (
    state: AudioInterface = initialAudioState,
    action: AudioAction
): AudioInterface => {
    switch (action.type) {
        case ACTIONS.CREATE_AUDIO_PLAYLIST:
            return {
                ...state,
                status: 'playlist',
                playlist: action.data,
                current: action.data[0],
                next: action.data[1],
            };
        case ACTIONS.PLAY_AUDIO:
            return {
                ...state,
                status: 'single',
                current: action.scriptLineId,
                next: null,
            };
        case ACTIONS.STOP_AUDIO:
            return {
                ...state,
                status: 'off',
                current: null,
            };
        case ACTIONS.START_AUDIO_RECORDING:
            return {
                ...state,
                recording: action.scriptLineId,
            };
        case ACTIONS.STOP_AUDIO_RECORDING:
            return {
                ...state,
                recording: false,
            };
        case ACTIONS.GO_NEXT:
            const newPlaylist = state.playlist;
            let newState = { ...state };
            if (state.status === 'playlist') {
                newPlaylist.shift();
                newState = {
                    ...state,
                    status: newPlaylist[0] ? 'playlist' : 'off',
                    playlist: newPlaylist,
                    current: newPlaylist[0] ? newPlaylist[0] : null,
                    next: newPlaylist[1] ? newPlaylist[1] : null,
                };
            }
            return newState;
        case ACTIONS.RESET_PLAYLIST:
            return {
                ...initialAudioState,
            };
        default:
            return state;
    }
};

export const getAudioState = (state: AppState) => state.player;
