import { Reducer } from 'redux';
import { cloneDeep } from 'lodash';

import { Config } from './types';
import { Actions } from './actions';
import { decapsulateAction } from '../../../../../store/helpers/decapsulateAction';
import { ListResult } from '../../../../../constants/interfaces/PaginationResult';
import { AppState } from '../../../../../store/reducers';
import {
    DEFAULT_PRACTIS_SETS_SCOPE,
    Scope,
    WithPractisSetsScopes,
} from './scopes';
import { toggleItemInArray } from '../../../../../helpers/functions/array-helpers';
import { EnrollmentPractisSet } from '../../../../../constants/interfaces/PractisSets';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';

//region Reducer state
export type StateWithPractisSets = {
    _practisSets: Record<Scope, PractisSetsState>;
};

export type PractisSetsState = {
    data: ListResult<EnrollmentPractisSet>;
    selected: PractisSetWithDueDate[];
    saved: PractisSetWithDueDate[];
    loading: boolean;
    error: string | null;
};

const initialState: PractisSetsState = {
    data: {
        items: [],
        count: 0,
    },
    selected: [],
    saved: [],
    loading: false,
    error: null,
};

export const withPractisSetsInitialState = <T>(
    baseInitialState: T,
    reducerName: keyof AppState
): T & StateWithPractisSets => {
    const scopes =
        WithPractisSetsScopes[reducerName] === undefined
            ? [DEFAULT_PRACTIS_SETS_SCOPE]
            : [
                  ...(WithPractisSetsScopes[reducerName] as string[]),
                  DEFAULT_PRACTIS_SETS_SCOPE,
              ];

    return {
        ...baseInitialState,
        _practisSets: scopes.reduce(
            (acc: Record<string, PractisSetsState>, scope) => {
                acc[scope] = cloneDeep(initialState);
                return acc;
            },
            {}
        ),
    };
};
//endregion

//region HOR
export const withPractisSetsReducer = <S extends StateWithPractisSets>(
    config: Config
) => (baseReducer: Reducer<S>) => (state: S, nativeAction: Actions): S => {
    let newState = state;
    const { reducerName } = config;
    const action = decapsulateAction(
        `${reducerName}/practisSets/`,
        nativeAction
    );
    const scope = action._scope;

    if (!scope) {
        return baseReducer(newState, action);
    }

    switch (action.type) {
        case 'clearPractisSets':
            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: [],
                        partial: [],
                    },
                },
            };
            break;
        case 'savePractisSets':
            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        saved: [...state._practisSets[scope].selected],
                    },
                },
            };
            break;
        case 'selectPractisSets':
            let updatedSelectedState = [...state._practisSets[scope].selected];

            updatedSelectedState = toggleItemInArray(
                updatedSelectedState,
                action.id
            );

            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: updatedSelectedState,
                    },
                },
            };
            break;
        case 'selectMultiplePractisSets': {
            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: action.ids,
                    },
                },
            };
            break;
        }
        case 'selectAllPractisSets':
            const newSelectedList = action.allPractisSets.map(item => ({practisSetId: item.id, dueDate: item.dueDate || null}));

            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: newSelectedList,
                    },
                },
            };
            break;
        case 'deselectAllPractisSets':
            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: [],
                    },
                },
            };
            break;
        case 'resetSelectedPractisSets': {
            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: state._practisSets[scope].saved,
                    },
                },
            };
            break;
        }
        case 'resetPractisSets':
            newState = {
                ...newState,
                _practisSets: {
                    ...state._practisSets,
                    [scope]: {
                        ...state._practisSets[scope],
                        selected: [],
                        saved: [],
                        collapsed: [],
                    },
                },
            };
            break;
    }

    return baseReducer(newState, action);
};
//endregion
