import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
    margin-bottom: 20px;
`;

export const ChartBoxDescriptionValue = styled.div`
    font-size: 11px;
    font-weight: 600;
    text-align: right;
    color: ${props => props.theme.Colors.black};
`;

export const ChartBoxDescriptionTitle = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
`;

const ChartBoxDescription: FC<{ className?: string }> = ({
    children,
    className,
}) => {
    return <Container className={className}>{children}</Container>;
};

export default ChartBoxDescription;
