import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const FiltersIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 17 15"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M1305.248 146.813v1.12h-1.067v-1.12c-1.217-.247-2.133-1.323-2.133-2.613s.916-2.366 2.133-2.613V133h1.067v8.587c1.217.247 2.133 1.323 2.133 2.613s-.916 2.366-2.133 2.613zm-.533-4.213c-.884 0-1.6.717-1.6 1.6 0 .884.716 1.6 1.6 1.6.883 0 1.6-.716 1.6-1.6 0-.883-.717-1.6-1.6-1.6zm-4.8-3.253v8.587h-1.067v-8.587c-1.217-.248-2.133-1.324-2.133-2.613 0-1.29.916-2.366 2.133-2.613V133h1.067v1.12c1.217.247 2.133 1.323 2.133 2.613s-.916 2.365-2.133 2.613zm-.534-4.213c-.884 0-1.6.716-1.6 1.6 0 .883.716 1.6 1.6 1.6.884 0 1.6-.717 1.6-1.6 0-.884-.716-1.6-1.6-1.6zm-4.8 9.546v3.254h-1.067v-3.254c-1.217-.247-2.133-1.323-2.133-2.613s.916-2.366 2.133-2.613V133h1.067v6.454c1.217.247 2.134 1.323 2.134 2.613s-.917 2.366-2.134 2.613zm-.533-4.213c-.884 0-1.6.716-1.6 1.6 0 .883.716 1.6 1.6 1.6.883 0 1.6-.717 1.6-1.6 0-.884-.717-1.6-1.6-1.6z"
                            transform="translate(-1291 -133)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default FiltersIcon;
