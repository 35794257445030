import { useMemo } from 'react';
import ApiPlatformDropdownView from "./view";
import localStorage from '../../../services/Storage';
import { ApiPlatformProps } from './types';

function ApiPlatformDropdownController({ dataTest }: ApiPlatformProps) {
    const apiPlatform = useMemo(() => {
        return localStorage.get('apiPlatform') || 'develop';
    }, []);

    /**
    @function saveApiPlatform - changes platform in localstorage
    @param { string } value
    @returns  { void }
    */
    const saveApiPlatform = (value: string) => {
        localStorage.set('apiPlatform', value || 'develop');
        window.location.reload();
    }

    return <ApiPlatformDropdownView
        apiPlatform={apiPlatform}
        onPlatformChange={saveApiPlatform}
        dataTest={dataTest}
    />
};

export default ApiPlatformDropdownController;