import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoPlay = forwardRef<SVGSVGElement, IconProps>(
    ({ className, width = 32, height = 32 }, ref) => {
        return (
            <svg
                ref={ref}
                width={width}
                height={height}
                viewBox="0 0 32 32"
                className={className}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path d="m26.892 11.846-2.34-1.47L8.055 26.504a5.265 5.265 0 0 0 3.756 1.543 5.462 5.462 0 0 0 2.89-.83l12.19-7.714c1.372-.86 2.191-2.3 2.191-3.829 0-1.528-.82-2.954-2.19-3.828zM19.455 7.13l-4.753-2.998a5.387 5.387 0 0 0-2.891-.83c-2.802 0-5.082 2.082-5.082 4.658v11.615L19.455 7.13z" fill="currentColor"/>
                <path d="m3.5 27 23-22.5" stroke="#EC513D" stroke-width="2" stroke-linecap="round"/>
            </svg>
        );
    }
);

export default NoPlay;
