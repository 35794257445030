import styled, { css } from 'styled-components';

export const Container = styled.div<{
    height: string;
    width: string;
    background: string;
    fontSize: string;
    error?: boolean;
    hasManualError?: boolean;
}>`
    font-family: 'Manrope';
    position: relative;
    height: ${props => props.height};
    width: ${props => props.width};
    background: ${props => props.background};
    font-size: ${props => props.fontSize};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 ${props => (props.error || props.hasManualError ? '2px' : '-1px')} var(--ps-red-main);    
    
    &.grey-border-on-focus {
        border: solid 1px transparent;
        box-shadow: none;

        ${props =>
            (props.error || props.hasManualError) &&
            css`border: solid 1px var(--ps-red-main);`
        }

        &:focus-within {
            border: solid 1px var(${props => (props.error || props.hasManualError) ? '--ps-red-main' : '--ps-grey-1'});
        }
    }
`;

export const InputWrapper = styled.div<{
    color: string;
    disabled?: boolean;
    fontSize: string;
    useTopPadding: boolean;
}>`
    padding: ${props => props.useTopPadding ? '10px' : '0px'} 24px 0px 24px;
    height: 100%;
    display: flex;
    align-items: center;
    & > .PhoneInput > input {
        font-family: 'Manrope';
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border: none;
        background: transparent;
        color: ${props => props.color};
        font-size: ${props => props.fontSize};
        -webkit-appearance: none;
        &:disabled {
            color: ${props => props.theme.Colors.cloudyBlue} !important;
        }
        &::placeholder {
            font-size: ${props => props.fontSize};
            color: ${props =>
                props.disabled
                    ? props.theme.Colors.cloudyBlue
                    : props.theme.Colors.steelGrey};
        }
    }
`;

export const Label = styled.div<{ color: string }>`
    font-size: 11px;
    color: ${props => props.color};
    margin-bottom: 4px;
    position: absolute;
    padding: 10px 0px 0 24px;
`;

export const TrashIconContainer = styled.div`
    color: var(--ps-red-main);
    position: absolute;
    width: 22px;
    height: 24px;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;
