import React, { FC, useCallback, useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Fieldset } from '../../../../../ui/components/Fieldset';
import Checkbox from '../../../../../ui/components/Checkbox';
import { Variables } from '../../../../../theme/variables';
import { toggleItemInArray } from '../../../../../helpers/functions/array-helpers';
import { Button } from '../../../../../ui/components/Button';
import { PRACTIS_SET_STATUS_ITEMS } from '../../../../../constants/enums';
import { DueDateFilters } from '../../../../../constants/interfaces/DueDates';
import OverdueFlag from '../../../../../ui/icons/OverdueFlag';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
    margin-left: 8px;
    margin-right: 8px;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 16px;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 158px;
    background-color: inherit;
    height: 114px;
`;

const StatusItem = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

const CheckBoxWrapper = styled.div`
    margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
    margin-right: 10px;
`;

const OverdueFlagWrapped = styled(OverdueFlag)`
    margin-left: 4px;
`;

const SelectedSubtitle = styled.div`
    font-size: 11px;
    margin-right: 12px;
    color: ${props => props.theme?.Colors?.steelGrey};
    min-width: 55px;
`;

// TODO-REFACTOR: filter contants in one place
const overduesMap: Record<DueDateFilters, { title: string }> = {
    [DueDateFilters.ASSIGNED]: { title: 'Assigned' },
    [DueDateFilters.NO_DUEDATE]: { title: 'No Due Date' },
    [DueDateFilters.OVERDUE]: { title: 'Overdue' },
};

export const UserProfileFilters: FC<{
    selectedStatuses: string[];
    savedStatuses: string[];
    handleSelectStatus: (ids: string[]) => void;
    handleSaveStatus: (ids: string[]) => void;
    onSuccessApply: () => void;
    onOverdueChange: (dueDate: DueDateFilters[]) => void;
    dueDate: DueDateFilters[];
    isInvitation?: boolean;
}> = ({
    selectedStatuses,
    savedStatuses,
    handleSelectStatus,
    handleSaveStatus,
    onSuccessApply,
    onOverdueChange,
    dueDate,
    isInvitation
}) => {
    useEffect(() => {
        handleSelectStatus(savedStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [overdues, setOverdues] = useState(dueDate);

    const handleStatusSelection = useCallback(
        (status: string) => {
            handleSelectStatus(toggleItemInArray(selectedStatuses, status));
        },
        [handleSelectStatus, selectedStatuses]
    );

    const onApplyHandler = useCallback(() => {
        handleSaveStatus(selectedStatuses);
        onOverdueChange(overdues);
        onSuccessApply();
    }, [handleSaveStatus, selectedStatuses, onSuccessApply, overdues, onOverdueChange]);

    // TODO refactor filter state management from one parent component
    const onClearHandler = useCallback(() => {
        handleSelectStatus([]);
        setOverdues([]);
    }, [handleSelectStatus]);

    const onOverdueChangeHandler = useCallback(
        (dueDateIn: DueDateFilters, e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setOverdues([...overdues, dueDateIn]);
            } else {
                setOverdues(overdues.filter(item => item !== dueDateIn));
            }
        },
        [overdues, setOverdues]
    );

    const totalFiltersSelectedCount = selectedStatuses.length + overdues.length;

    return (
        <Container>
            <Wrapper>
               {!isInvitation && (<Fieldset title={'Progress Status'}>
                    <StatusContainer>
                        {PRACTIS_SET_STATUS_ITEMS.map(item => {
                            return (
                                <CheckBoxWrapper key={item.value}>
                                    <Checkbox
                                        key={item.value}
                                        handleChange={() => {
                                            handleStatusSelection(item.value);
                                        }}
                                        checked={selectedStatuses.includes(
                                            item.value
                                        )}
                                        size={12}
                                        label={item.name}
                                        fontSize={13}
                                        fontColor={Variables.Colors.black}
                                        fontWeight={'500'}
                                    />
                                </CheckBoxWrapper>
                            );
                        })}
                    </StatusContainer>
                </Fieldset>)}
                <Fieldset title={'Due Date'}>
                    <StatusContainer>
                        {(Object.keys(
                            overduesMap
                        ) as DueDateFilters[]).map(item => (
                            <StatusItem>
                                <Checkbox
                                    key={item}
                                    checked={overdues.includes(item)}
                                    handleChange={e =>
                                        onOverdueChangeHandler(item, e)
                                    }
                                    label={overduesMap[item].title}
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                />
                                {item === DueDateFilters.OVERDUE && <OverdueFlagWrapped />}
                            </StatusItem>
                        ))}
                    </StatusContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SelectedSubtitle>
                    {totalFiltersSelectedCount} selected
                </SelectedSubtitle>
                <ButtonWrapper>
                    <Button
                        width={'104px'}
                        variant={'inverse'}
                        action={() => onClearHandler()}
                        disabled={totalFiltersSelectedCount < 1}
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button width={'104px'} action={() => onApplyHandler()}>
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
