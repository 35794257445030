import {
    resetProfileInfo,
    updateUserProfileSuccess,
} from '../../UserProfile/store/actions';
import { setTokenAction, userLogoutAction } from './actions';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    useLoginApi,
    useLoginByPhoneCodeApi,
    useTestInvitationApi,
} from '../../../api';
import { LoginParams, LoginByPhoneCodeParams } from '../../../api/auth/types';
import { clearAuthData } from '../../../helpers/functions/auth-helpers';

export const useTestInvitationService = () => {
    const dispatch = useDispatch();
    const testInvitationApi = useTestInvitationApi();
    return useCallback(
        (code: string, failureCallback?: (errorMessages?: string) => void) => {
            return testInvitationApi(code)
                .then((res: any) => {
                    if (res.status === 'error') {
                        throw res.message;
                    }
                    const updateValues = {
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email,
                        invitationCode: res.code,
                        role: res.role,
                    };
                    dispatch(updateUserProfileSuccess(updateValues));
                    return updateValues;
                })
                .catch((error: ErrorResult) => {
                    failureCallback?.(error?.message);
                });
        },
        [dispatch, testInvitationApi]
    );
};

export const useUserLogout = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        clearAuthData();
        dispatch(userLogoutAction());
    }, [dispatch]);
};

export const useLogin = () => {
    const loginApi = useLoginApi();
    return useCallback(
        (params: LoginParams) => {
            return loginApi(params)
                .catch((error: ErrorResult) => {
                    clearAuthData();
                    throw error;
                });
        },
        [loginApi]
    );
};

/**
 * @function useLoginByPhoneCode
 * @param { LoginByPhoneCodeParams } params 
 * @returns { Promise<{ user: UserV2; token: string }> }
 */
export const useLoginByPhoneCode = () => {
    const loginByPhoneCodeApi = useLoginByPhoneCodeApi();
    return useCallback(
        (params: LoginByPhoneCodeParams) => {
            return loginByPhoneCodeApi(params)
                .catch((error: ErrorResult) => {
                    clearAuthData();
                    throw error;
                });
        },
        [loginByPhoneCodeApi]
    );
};

export const resetProfile = () => {
    return (dispatch: any) => {
        dispatch(resetProfileInfo());
    };
};

export const useResetProfileService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetProfileInfo());
    }, [dispatch]);
};

export const useSetTokenService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (token: string) => {
            dispatch(setTokenAction(token));
        },
        [dispatch]
    );
};
