import styled from 'styled-components';
import AvatarPlaceholder from '../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { Button } from '../../../ui/components/Button';

export const HeaderContainer = styled.div`
    height: 68px;
    display: flex;
    justify-content: start;
    border-bottom: 1px solid var(--ps-grey-4);
    align-items: start;
    padding-left: 24px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: 800;
    line-height: 1.4;
    display: flex;
    align-items: center;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 800;
  padding-left: 24px;
`;

export const BodyContainer = styled.div`
    width: 100%;
    display: flex;
`;

export const ContextContainer = styled.div<{ fullHeight: boolean }>`
    width: 528px;
    height: calc(100vh - ${props => props.fullHeight ? 68 : 156}px);
    border-right: 1px solid var(--ps-grey-4);
    padding-top: 24px;
    overflow-y: hidden; 
`;

export const ParamsContainer = styled.div`
    height: calc(100vh - 180px);
    overflow-y: scroll;
`;

export const ContentContainer = styled.div<{ fullHeight: boolean }>`
    height: calc(100vh - ${props => props.fullHeight ? 68 : 152}px);
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 528px);
`;

export const IconContainer = styled.div`
    width: 12px;
    height: 12px;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 17px;
`;

export const NoEntryContainer = styled.div`
    color: var(--ps-grey-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`;

export const IconCountainer = styled.div<{ isDisabled: boolean }>`
    margin-left: 10px;
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
`;

export const ModalBody = styled.div`
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: self-end;
`;

export const ModalText = styled.div`
 font-size: 13px;
 color: var(--ps-grey-1);
 margin-bottom: 32px;
`;

export const ModalHeader = styled.div`
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    margin-bottom: 16px;
    width: 100%;
`

export const Footer = styled.div`
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const ButtonContainer = styled.div`
    height: 88px;
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: start;
    align-items: center;

    button {
        margin-right: 16px;
    }

    .action-button-element {
        padding-left: 16px;
    }
`

export const InfoText = styled.div`
    font-size: 15px;
        margin-left: 8px;
        line-height: 1.33;
`;

export const Container = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`;

export const ContentPanelContainer = styled.div<{height?: number}>`
    width: 100%;
    position: relative;
    overflow: scroll;
    height : ${props => props.height ? `calc(100% - ${props.height + 20}px)` : `calc(100% - 104px)`}
`

export const BtnCont = styled.div`
    display: flex; 
    justify-content: end;
`;

export const Contentbox = styled.div`
    padding: 17px 40px;
    background: var(--ps-grey-3);
    border-bottom: 1px solid var(--ps-grey-4);
    justify-content: center;
    display: flex;
`;

export const ContentBoxContainer = styled.div`
    width: 580px;
`;

export const ContentItem = styled.div`
    display: flex;
    margin-bottom: 18px;
    &.error-item { flex-direction: column }

    .avatar-logo, .customer-logo {
        max-width: 32px;
        min-width: 32px;
        height: 32px;
        margin-right: 12px;
    }

    .avatar-logo.no-border {
        border: 0px;
    }

    .command-author-pic {
        font-weight: normal;
    }
`;

export const Img = styled(AvatarPlaceholder)`
    max-width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 12px;
    border: 1px solid var(--ps-gey-4)
`;

export const TextBox = styled.div`
    display: flex;
`;

export const Author = styled.div`
    font-size: 15px;
    font-weight: 800;
`;

export const Message = styled.p`
    font-size: 15px;
    max-width: 600px;
    min-width: 100px;
    margin: 0px;
    white-space: pre-line;

    b {
        font-weight: 600;
    }
`;

export const Sendbox = styled.div`
    min-height: 100px;
    padding: 8px 40px;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    background: white;
    right: 0px;
    width: calc(100% - 776px);
`;

export const SendBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 580px;
`;

export const DateText = styled.div`
    color: var(--ps-grey-1);
    margin-bottom: 8px;
    font-size: 11px;
    font-weight: 500;
`;

export const BtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
`;

export const PlayButton = styled.div<{ isDisabled: boolean }>`
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    border: 3px solid ${props => props.isDisabled ? 'var(--ps-grey-2) ' : 'var(--ps-blue-main) '}; 
    cursor: ${props => !props.isDisabled && 'pointer'};
    
    .play-btn {
        color: ${props => props.isDisabled ? 'var(--ps-grey-2) ' : 'var(--ps-blue-main) '}; ;
        width: 12px;
        margin-left: 11px;
}`;

export const SpinnerContainer = styled.div`
    width: 36px;
    height: 36px;
    position: relative;
    margin-right: 10px;

    .MuiCircularProgress-colorPrimary {
        color: var(--ps-blue-main);
    }

    .spinner-icn {
        position: absolute;
        top: 9px;
        left: 8px;
    }
`;


export const StopIcon = styled.div`
    width: 12px;
    height: 12px;
    background: var(--ps-blue-main);
    margin: 9px auto;
    cursor: pointer;
`;

export const MenuButton = styled.div<{ isDisabled?: boolean }>`
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    background: ${props => props.isDisabled ? 'var(--ps-grey-2) ' : 'var(--ps-blue-main) '}; ;
    min-width: 36px;
    margin-left: ${props => props.isDisabled ? '0px' : '20px'};

    .dots-btn {
        rotate: 90deg;
        width: 19px;
        color: var(--ps-white-1);
        margin-left: 9px;
    }
}`;

export const RoleplayMenuDots = styled(Button)<{ isDisabled?: boolean }>`
    max-width: 40px;
    padding: 0px;
    .dots-btn {
        rotate: 90deg;
        width: 20px;
        height: 20px;
    }
}`;

export const RegenerateBtn = styled.div<{ isDisabled?: boolean }>`
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.isDisabled ? 'var(--ps-grey-2)' : 'var(--ps-blue-main)'};
    display: flex;
    align-items: center;
    justify-content: end;

    svg {
        margin-right: 4px;
    }

    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
`;

export const CommandContainer = styled.div`
    margin-top: 23px;
    border-bottom: 1px solid var(--ps-grey-4);
    display: flex;
    justify-content: center;
    padding: 0px 40px;
`;

export const CommandBox = styled.div`
    width: 580px;
`;  

export const ErrorContainer = styled.div`
    padding: 17px 41px;
    background-color: rgba(236, 81, 61, 0.1);
    border-bottom: 1px solid var(--ps-grey-4);
    display: flex;
    justify-content: center;
    
    .error-icon {
        margin-right: 10px;
        margin-top: 5px;
        max-width: 32px;
        min-width: 32px;
    }
`;

export const ErrorBox = styled.div`
    width: 580px;
`;

export const WarningBtnContainer = styled.div`
    display: flex;
    margin-right: 12px;
    margin-top: 12px;

    button:disabled  {
        background: rgb(236, 81, 61, 0.4) !important;
    }

    button.inverse:disabled {
        div {
            border: 1px solid rgb(236, 81, 61, 0.4) !important;;
        }
        box-shadow: none;
        color: rgb(236, 81, 61, 0.4) !important;;
        background: transparent !important;
    }
`;

export const BlueDot = styled.span`
    width: 12px;
    height: 12px;
    border-radius: 33px;
    background-color: var(--ps-blue-main);
    display: inline-block;
    margin-left: 5px;

    @keyframes pulse {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    animation: pulse 0.8s infinite;
`;

export const StopGenIcon = styled.div<{ isDisabled?: boolean }>`
    width: 16px;
    height: 16px;
    margin: 1px 4px 1px 0;
    border: 2px solid ${props => props.isDisabled ? 'var(--ps-grey-2) ' : 'var(--ps-blue-main) '}; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 6px;
        height: 6px;
        background: ${props => props.isDisabled ? 'var(--ps-grey-2) ' : 'var(--ps-blue-main) '}; ;
        
        border-radius: 1px;
    }
`