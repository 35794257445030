import { Reducer } from 'redux';
import { UserRolesState } from './states';
import {
    ACTIONS,
    loadUserRolesFailure,
    loadUserRolesStart,
    loadUserRolesSuccess,
} from './actions';

const initialState: UserRolesState = {
    list: [],
    loading: false,
};

export type Action =
    | ReturnType<typeof loadUserRolesStart>
    | ReturnType<typeof loadUserRolesSuccess>
    | ReturnType<typeof loadUserRolesFailure>;

export const userRolesReducer: Reducer<UserRolesState, Action> = (
    state = initialState,
    action: Action
): UserRolesState => {
    switch (action.type) {
        case ACTIONS.LOAD_USER_ROLES_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.LOAD_USER_ROLES_SUCCESS: {
            return {
                list: action.data,
                loading: false,
            };
        }
        case ACTIONS.LOAD_USER_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
