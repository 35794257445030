import { AppState } from '../../../../../store/reducers';

export type Scope = typeof DEFAULT_PRACTIS_SETS_SCOPE | string;

export const DEFAULT_PRACTIS_SETS_SCOPE = 'primary';

export const WithPractisSetsScopes: Partial<Record<keyof AppState, Scope[]>> = {
    draftEdit: ['assignFilters', 'addNewUser', 'editUser'],
    performance: ['assignFilters', 'addNewUser', 'editUser'],
};
