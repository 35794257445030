import { UserInfo } from './UserInfo';

export interface AiLogItemInterface {
    id: number;
    dateCreated: string;
    user: UserInfo;
    accuracy: number;
    scenarioName: string;
    scenarioStatus: string;
    mediaUrl: string;
    submissionAccuracyMode: string;
    flagCompanyId?: number;
    flagAdminId?: number;
    company: {
        id: number;
        name: string;
        imageUrl: string;
    };
    difference: RecognizedRep[];
    repNumber: number;
    minRepsCount: number;
}

export interface RecognizedRep {
    lineId: number;
    original: string;
    recognized: string;
}

export enum AiLogItemStatus {
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
    ARCHIVED = 'ARCHIVED',
}
