import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 16px;
`;

export const MonthSelectorContainer = styled.div`
    padding-top: 12px;
`;