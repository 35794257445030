import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { Label } from '../../../constants/interfaces/Label';
import { useSelector } from 'react-redux';

type PortableLabelsBaseState = {
    data: PaginationResult<Label>;
    loading: boolean;
};

export type PortableLabelsState = PortableLabelsBaseState;

export const initialState = {
    data: {
        items: [],
        limit: 0,
        offset: 0,
        numberOfPages: 0,
        totalCount: 0,
        lastUpdated: undefined,
    },
    loading: false,
};

export const usePortableLabelsState = (): PortableLabelsState => {
    const appState = useSelector(state => state);
    return appState.portableLabels;
};
