import { useCallback, useEffect } from 'react';

import { useSearchTeamsService } from '../../../teams/store/services';
import { useTeamsState } from '../../../teams/store/states';
import { initialSearchParams, TEAMS_QUERY_PARAM_NAME } from './constants';
import { useMultipleTeamsFilterState } from './store/reducers';
import {
    useClearMultipleTeams,
    useSelectMultipleTeams,
} from './store/services';
import useQueryParamListener from '../../../../helpers/hooks/useQueryParamListener';
import { useSetQueryParam } from '../../../../helpers/hooks/useSetQueryParam';
import MultipleSelectTeamsFilterView from './view';
import { isEqual } from 'lodash';

function MultipleSelectTeamsFilterController() {
    const searchTeams = useSearchTeamsService();
    const selectMultipleTeams = useSelectMultipleTeams();
    const clearMultipleTeams = useClearMultipleTeams();
    const { loading, myTeams } = useTeamsState();
    const { selectedTeamIds, isDisabled } = useMultipleTeamsFilterState();
    const setQueryParam = useSetQueryParam();

    const setSelectedTeamsInStore = useCallback((teamIds: number[]) => {
        if (teamIds.length) {
            selectMultipleTeams(teamIds);
        } else {
            clearMultipleTeams();
        }
    }, [clearMultipleTeams, selectMultipleTeams]);

    const setSelectedTeams = useCallback((teamIds: number[]) => {
        const paramValue = teamIds.join(',');
        setQueryParam(TEAMS_QUERY_PARAM_NAME, paramValue);
        setSelectedTeamsInStore(teamIds);
    }, [setQueryParam, setSelectedTeamsInStore]);

    const handleTeamSearch = useCallback(
        (searchTerm: string) => {
            searchTeams({ ...initialSearchParams, searchTerm });
        },
        [searchTeams]
    );

    const handleTeamToggle = useCallback(
        (teamId: number) => {
            const teamIds = selectedTeamIds ?? [];
            const newTeamIds = teamIds.includes(teamId)
                ? teamIds.filter(id => id !== teamId)
                : teamIds.concat(teamId);
            setSelectedTeams(newTeamIds);
        },
        [selectedTeamIds, setSelectedTeams]
    );

    const handleAllToggle = useCallback(() => {
        if (isDisabled) return;
        if (selectedTeamIds && selectedTeamIds?.length === myTeams?.count) {
            setSelectedTeams([]);
        } else if (myTeams) {
            setSelectedTeams(myTeams.items.map(item => item.id!));
        }
    }, [isDisabled, myTeams, selectedTeamIds, setSelectedTeams]);

    useQueryParamListener(TEAMS_QUERY_PARAM_NAME, teamIds => {
        const ids = teamIds?.length ? teamIds.split(',').map(id => Number(id)) : [];
        if (!isEqual(ids, selectedTeamIds)) {
            setSelectedTeamsInStore(ids);
        }
    });

    useEffect(() => {
        searchTeams(initialSearchParams);
    }, [searchTeams]);

    return (
        <MultipleSelectTeamsFilterView
            loading={loading}
            myTeams={myTeams}
            selectedTeamIds={selectedTeamIds}
            isDisabled={isDisabled}
            onTeamSearch={handleTeamSearch}
            onTeamToggle={handleTeamToggle}
            onAllToggle={handleAllToggle}
        />
    );
}

export default MultipleSelectTeamsFilterController;