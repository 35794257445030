import { createContext } from 'react';

import { AppState } from '../../../../../../store/reducers';
import { DEFAULT_SCENARIOS_SCOPE, Scope } from '../scopes';

export type WithScenariosContextProps<T = keyof AppState, K = Scope> = {
    reducerName?: T;
    scope?: K;
};

export const WithScenariosContext = createContext<WithScenariosContextProps>({
    reducerName: 'portableScenarios',
    scope: DEFAULT_SCENARIOS_SCOPE,
});
