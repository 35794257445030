import React, { FC, useCallback, useMemo, useState } from 'react';
import { eventName, events } from '../tools';
import { LogEvent } from '../models/LogEvent';
import {
    FiltersCheckboxList,
    FiltersCheckboxListItem,
    FiltersNoItems,
    FiltersSearchInput,
    FiltersSelectedItems,
    FiltersSelectedItemsText,
    FiltersToggleAllButton,
} from '../../../ui/components/Filters';

interface Props {
    selectedEvents: LogEvent[];
    onChangeSelectedEvents(selectedEvents: LogEvent[]): void;
}

export const LogFiltersEvents: FC<Props> = props => {
    const { selectedEvents, onChangeSelectedEvents } = props;
    const [eventSearchPattern, setEventSearchPattern] = useState<string>('');

    const checkboxListItems: FiltersCheckboxListItem<string>[] = useMemo(() => {
        const pattern = eventSearchPattern.trim().toLowerCase();
        const resultEvents = !!pattern
            ? allEvents.filter(i => i.name.toLowerCase().indexOf(pattern) >= 0)
            : allEvents;
        return resultEvents.map(item => ({
            checked: selectedEvents.includes(item.value),
            label: item.name,
            value: item.value,
        }));
    }, [selectedEvents, eventSearchPattern]);

    const filteredEvents: LogEvent[] = useMemo(
        () =>
            allEvents
                .filter(e => !!checkboxListItems.find(c => c.value === e.value))
                .map(e => e.value),
        [checkboxListItems]
    );

    const setSelectedEvents = useCallback(
        (events: LogEvent[]) => onChangeSelectedEvents(events),
        [onChangeSelectedEvents]
    );

    const handleChangeSearchEventsPattern = useCallback(
        (searchPattern: string) => setEventSearchPattern(searchPattern),
        [setEventSearchPattern]
    );

    const handleChangeSelectedEvent = useCallback(
        (event: LogEvent) => {
            setSelectedEvents(
                selectedEvents.includes(event)
                    ? selectedEvents.filter(r => r !== event)
                    : [...selectedEvents, event]
            );
        },
        [selectedEvents, setSelectedEvents]
    );

    const handleClickToggleAllEvents = useCallback(
        allItemsSelected =>
            setSelectedEvents(allItemsSelected ? [] : filteredEvents),
        [filteredEvents, setSelectedEvents]
    );

    return (
        <>
            <FiltersSearchInput
                onChange={handleChangeSearchEventsPattern}
            />
            <FiltersSelectedItems>
                <FiltersSelectedItemsText
                    itemName="Event"
                    selectedItemsCount={selectedEvents.length}
                />
                <FiltersToggleAllButton
                    allItemsSelected={
                        selectedEvents.length === filteredEvents.length
                    }
                    onToggleAllItems={handleClickToggleAllEvents}
                />
            </FiltersSelectedItems>
            <FiltersCheckboxList<string>
                items={checkboxListItems}
                divisible={true}
                onChange={handleChangeSelectedEvent}
            />
            {!checkboxListItems.length && <FiltersNoItems itemsName="Events" />}
        </>
    );
};

const allEvents = events().map(e => ({ name: eventName(e), value: e }));
