import { Formik, Form } from 'formik';

import { Button } from '../../../ui/components/Button';
import { FormikField } from '../../../ui/components/FormikField';
import {
    HeaderTitle,
    StyledField,
    FormContainer,
    FieldsContainer,
    UserEmail,
    UserInfoContainer,
    UserTitle,
    useStyles,
    Divider,
} from './styles';
import { passwordHintText, RegisterSchema } from './constants';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import { RegistrationProps } from './types';
import { ImageUploader } from '../../../ui/components/ImageUploader';
import { isSubmitButtonDisabled } from './helper';
import Checkbox from '../../../ui/components/Checkbox';

function RegistrationView(props: RegistrationProps) {
    const {
        formRef,
        userInfo,
        setProfileImage,
        handleSubmit,
        isTermsAccepted,
        handleToggleTerms,
    } = props;

    const classes = useStyles();

    return (
        <LoginAuthWrapper contentWidth={343} heightToShowScroll={720} hideFooter={true}>
            <ImageUploader
                setProfileImage={setProfileImage}
                placeHolder={userInfo?.firstName?.charAt(0).toUpperCase()}
                className={classes.imageUploaderStyles}
                dataTest="user-avatar"
            />
            <UserInfoContainer>
                <UserTitle data-test="user-name">
                    {`${userInfo?.firstName ?? ''} ${userInfo?.lastName ?? ''}`}
                </UserTitle>
                <UserEmail data-test="user-email">{userInfo?.email ?? ''}</UserEmail>
                <Divider />
            </UserInfoContainer>

            <HeaderTitle data-test="account-form-title">Create an account</HeaderTitle>
            <FormContainer>
                <Formik
                    ref={formRef}
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={RegisterSchema}
                >
                    {({ values, isSubmitting }) => (
                        <Form>
                            <FieldsContainer>
                                <StyledField
                                    type="password-with-eye"
                                    autoComplete="new-password"
                                    component={FormikField}
                                    name="password"
                                    label="Password"
                                    colorTheme="darkThree"
                                    fontSize="15px"
                                    labelFontSize="15px"
                                    height="56px"
                                    labelFontWeight={600}
                                    className="login-input"
                                    errorPadding="2px 0"
                                    errorColor="var(--ps-red-main)"
                                    hint={passwordHintText}
                                    autoFocus
                                    decreaseLabelFontSize
                                    dataTest="password"
                                />
                                <StyledField
                                    type="password-with-eye"
                                    autoComplete="new-password"
                                    component={FormikField}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    colorTheme="darkThree"
                                    fontSize="15px"
                                    labelFontSize="15px"
                                    height="56px"
                                    labelFontWeight={600}
                                    className="login-input"
                                    errorPadding="2px 0 0 0"
                                    errorColor="var(--ps-red-main)"
                                    decreaseLabelFontSize
                                    dataTest="confirm-password"
                                />
                            </FieldsContainer>

                            <Checkbox
                                className={classes.checkboxContainer}
                                checked={isTermsAccepted}
                                handleChange={handleToggleTerms}
                                background="transparent"
                                label={
                                    <div>
                                        I've read, understood, and agree to the
                                        <a
                                            href="https://www.gopractis.com/document-privacy-policy"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                                classes.checkBoxAnchorTag
                                            }
                                        >
                                            Privacy Policy
                                        </a>,
                                        <a
                                            href="https://www.gopractis.com/document-terms-of-use"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                                classes.checkBoxAnchorTag
                                            }
                                        >
                                            Terms to Use
                                        </a>, and 
                                        <a
                                            href="https://www.gopractis.com/document-terms-of-service"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                                classes.checkBoxAnchorTag
                                            }
                                        >
                                            Terms of Service.
                                        </a>
                                    </div>
                                }
                                size={16}
                                dataTest="terms-checkbox"
                                fontColor="var(--ps-grey-4)"
                            />

                            <Button
                                type="submit"
                                width="100%"
                                height="56px"
                                buttonWidth="100%"
                                fontSize={16}
                                style={{ marginTop: '10px' }}
                                disabled={
                                    isSubmitButtonDisabled(values) ||
                                    !isTermsAccepted
                                }
                                loading={isSubmitting}
                                dataTest="continue-button"
                            >
                                Continue
                            </Button>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </LoginAuthWrapper>
    );
}

export default RegistrationView;
