import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { ACTIONS, DateRangeFilterActions } from './actions';
import { DateRangeFilterState } from './types';

export const dateRangeFilterReducer: Reducer<
    DateRangeFilterState,
    DateRangeFilterActions
> = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_DATE_RANGE:
            return {
                ...state,
                dateRange: action.dateRange,
            };
        case ACTIONS.CLEAR_DATE_RANGE:
            return {
                ...state,
                dateRange: undefined,
            };
        default:
            return state;
    }
};

export const useDateRangeState = () =>
    useSelector(state => state.dateRange);
