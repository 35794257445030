import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import screenfull from 'screenfull';

import { VideoPlayerControls } from './VideoPlayerControls';
import { Variables } from '../../../../theme/variables';
import { Loading } from '../../LoadingCopmonent';
import novideo from '../../../../assets/images/no-video.svg'

const formatDisplayDate = (seconds: any) => {
    if (isNaN(seconds)) {
        return '00:00';
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date
        .getUTCSeconds()
        .toString()
        .padStart(2, '0');

    if (hh) {
        return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }

    return `${mm}:${ss}`;
};

const ReactPlayerContainer = styled.div`
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    max-height: 421px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 416px;
`;

const LoadingContainer = styled.div`
    position: absolute;
    margin: 0 auto;
    left:0;
    right:0;
    width: 32px;
    z-index: 1;
`;

const NoVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    width: 416px;
    font-size: 13px;
    color: #b0bdc6;
`;

export const VideoPlayer: FC<{
    url?: string;
    onDownloadVideo?(): void;
    onCopyLink?(): void;
    videoLoading?: boolean;
    className?: string;
    getDuration?(duration: number): void;
    getPlaying?(playing: boolean): void;
    muteVideo?: boolean;
    autoPlay?: boolean;
}> = ({
    url,
    onDownloadVideo,
    onCopyLink,
    videoLoading,
    className,
    getDuration,
    getPlaying,
    muteVideo,
    autoPlay,
}) => {
        const [showControls, setShowControls] = useState(false);
        const [state, setState] = useState({
            playing: !!autoPlay,
            muted: false,
            volume: 0.5,
            played: 0,
            playedSeconds: 0,
        });
        const [seeking, setSeeking] = useState(false);
        const [showLoading, setShowLoading] = useState(true);

        const { playing, muted, volume, played, playedSeconds } = state;

        const playerRef = useRef<any>(null);
        const playerContainerRef = useRef<any>(null);

        const handlePlayPause = () => {
            setState({ ...state, playing: !state.playing });
        };

        const handleMute = () => {
            setState({ ...state, muted: !state.muted });
        };

        const handleRewind = () => {
            playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
        };

        const handleVolumeChange = (e: any, newValue: any) => {
            setState({
                ...state,
                volume: parseFloat(newValue),
                muted: newValue === 0,
            });
        };

        const handleVolumeSeekDown = (e: any, newValue: any) => {
            setState({
                ...state,
                volume: parseFloat(newValue),
                muted: newValue === 0,
            });
        };

        const handleToggleFullScreen = () => {
            if (screenfull.isEnabled) {
                screenfull.toggle(playerContainerRef.current);
            }
        };

        const handleProgress = (changeState: any) => {
            if (!seeking) {
                setState({ ...state, ...changeState });
                if (changeState.played === 1) {
                    setState({ ...state, playing: false, played: 1 });
                }
            }
        };

        const handleSeekChange = (e: any, newValue: any) => {
            setState({
                ...state,
                playedSeconds: parseFloat(newValue),
            });
        };

        const handleSeekMouseDown = () => {
            setSeeking(true);
        };

        const handleSeekMouseUp = (e: any, newValue: any) => {
            setSeeking(false);
            if (playerRef.current.getDuration() === Infinity) {
                return;
            }
            //This check is needed due to library bug, when value is 1 (1oo%) it jumps to 0:01 time frame instead
            if (newValue === 1) {
                playerRef.current.seekTo(playerRef.current.getDuration());
            } else {
                playerRef.current.seekTo(newValue);
            }
        };

        const currentTime = playerRef.current
            ? playerRef.current.getCurrentTime()
            : '00:00';

        const elapsedTime = formatDisplayDate(currentTime);

        useEffect(() => {
            if (getDuration) {
                getDuration(playedSeconds);
            }
        }, [playedSeconds, getDuration]);

        useEffect(() => {
            if (getPlaying) {
                getPlaying(playing);
            }
        }, [playing, getPlaying]);

        useEffect(() => {
            if(!url){
                setShowLoading(false);
            }
        }, [url])
     

        return (
            <ReactPlayerContainer
                onClick={handlePlayPause}
                onDoubleClick={handleToggleFullScreen}
                ref={playerContainerRef}
                className={className}
            >
                {url && (
                    <ReactPlayer
                        ref={playerRef}
                        config={{
                            file: {
                                attributes: {
                                    preload: 'auto',
                                },
                            },
                        }}
                        width={'100%'}
                        height={'100%'}
                        style={{
                            background: Variables.Colors.backgroundDark,
                            zIndex: 1,
                        }}
                        url={url}
                        className="player-background"
                        playing={playing}
                        muted={muteVideo || muted}
                        volume={volume}
                        onReady={() => {
                            setShowControls(true);
                            setShowLoading(false);
                        }}
                        onProgress={handleProgress}
                    />
                )}
                {showLoading && (
                    <LoadingContainer>
                        <Loading width={'32px'} />
                    </LoadingContainer>
                )}

                {!url && (
                    <NoVideoContainer>
                        <img src={novideo} alt="org" />
                        <Box component="span" mt={1}>
                            This video is not available for playback
                        </Box>
                    </NoVideoContainer>
                )}

                {showControls && url && (
                    <VideoPlayerControls
                        played={played}
                        playing={playing}
                        muted={muted}
                        volume={volume}
                        setPlaying={handlePlayPause}
                        setMuted={handleMute}
                        onRewind={handleRewind}
                        onVolumeChange={handleVolumeChange}
                        onVolumeSeekDown={handleVolumeSeekDown}
                        onToggleFullScreen={handleToggleFullScreen}
                        onSeek={handleSeekChange}
                        onSeekMouseDown={handleSeekMouseDown}
                        onSeekMouseUp={handleSeekMouseUp}
                        onDownloadVideo={onDownloadVideo}
                        onCopyLink={onCopyLink}
                        videoLoading={videoLoading}
                        elapsedTime={elapsedTime}
                    />
                )}
            </ReactPlayerContainer>
        );
    };
