import { SearchParams } from '../../../../constants/interfaces/filters';

export const ITEMS_PER_PAGE = 20;

export const initialSearchParams: SearchParams = {
    searchTerm: '',
    orderBy: null,
    limit: ITEMS_PER_PAGE,
    offset: 0,
    numberOfPages: 0,
    labelIDs: []
};

export const OverdueNudgeMessagePrefix = 'You have overdue Practis sets.\n\n';