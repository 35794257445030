import dayjs from "dayjs";
import { DATE_RANGE_PARAM_NAME } from "../../features/filters/dynamic-filter/date-filter/constants";
import ROUTES from "../../routes/routes"

const getLastWeekDateRangeParam = () =>
    new URLSearchParams({[DATE_RANGE_PARAM_NAME]: `${dayjs(new Date().getTime() - 604800000).format('YYYY-MM-DD')},${dayjs(new Date()).format('YYYY-MM-DD')}`});

export const getTeamLeaderReportUrl = () => ROUTES.REPORTS.TEAM_LEADER_ACTIVITY_REPORT + `?${getLastWeekDateRangeParam()}`;

export const getTrainingTimeReportUrl = () => ROUTES.REPORTS.TRAINING_TIME_REPORT + `?${getLastWeekDateRangeParam()}`;
