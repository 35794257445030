import React, { useContext } from 'react';

export interface BuildUrlOptions {
    path?: string;
    processData?: any;
    params?: {
        [keyof: string]: any;
    };
    query?: any;
    absolute?: boolean;
}

export interface BuildApiUrlOptions extends BuildUrlOptions {
    isV2?: boolean;
}

export interface UrlBuilder {
    buildCompanyUrl(params: BuildUrlOptions): string;
    buildAdminUrl(params: BuildUrlOptions): string;
    buildApiUrl(params: BuildApiUrlOptions): string;
}

export const UrlBuilderContext = React.createContext<UrlBuilder>(
    undefined as any
);

export function useUrlBuilder() {
    return useContext(UrlBuilderContext);
}
