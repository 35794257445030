import styled from 'styled-components';
import ModalPage from '../../../../ui/components/ModalPage/ModalPage';
import { Input } from '../../../../ui/components/input';

export const StyledModalPage = styled(ModalPage)`
    width: 627px;
    min-width: 550px;

    @media (max-width: 1024px) {
        left: unset;
    }
    @media (max-width: 576px) {
        width: 45%;
        left: 47px;
        min-width: calc(100% - 47px);
    }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background: var(--ps-white-1);
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    height: 96px;
    color: var(--ps-black-main);
`;

export const NameContainer = styled.div`
    margin: 16px 0;
    border-radius: 4px;
`;

export const NameInput = styled(Input)`
    padding: 12px 16px;
    font-size: 13px;
    font-weight: normal;
    border: ${props => props.error ? 'none' : '1px solid var(--ps-grey-3)'};
    border-radius: 4px;
    &:focus {
        border: ${props => props.error ? 'none' : '1px solid var(--ps-grey-2)'};
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
`;

export const ButtonDelimiter = styled.div`
    width: 16px;
`;

