import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { History } from 'history';
import * as Yup from 'yup';

import ROUTES from '../../../routes/routes';
import Storage from '../../../services/Storage';
import Validation from '../../../services/validation';
import { UrlBuilder, UrlBuilderContext } from '../../../tools/url/UrlBuilder';
import { StyledActionsDivs } from '../styles';
import { useHistory } from '../../../tools/router';
import { useLogin } from '../store/services';
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { Variables } from '../../../theme/variables';
import {
    setAuthToken,
    redirectAfterLogin,
} from '../../../helpers/functions/auth-helpers';
import { useRedirectToPhoneVerification } from '../../../helpers/hooks/useRedirectToPhoneVerification';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import { FormikField } from '../../../ui/components/FormikField';
import { Button } from '../../../ui/components/Button';
import ApiPlatformDropdown from '../ApiPlatformDropdown';
import {
    StyledInputDivs,
    ForgotPasswordLink,
    StyledLoginContent,
    StyledSeparatorWrapper,
    StyledSeparator,
    StyledSeparatorText,
} from './styles';
import { UserV2 } from '../../../constants/interfaces/User';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

const LoginSchema = Yup.object().shape({
    email: Validation.email,
    password: Validation.loginPassword,
});

const NOT_FOUND_ACCOUNT_ERROR = 'Invalid Email Address or Password';

const Login = () => {
    const [disabled, setDisabled] = useState(false);
    const history: History<any> = useHistory();
    const from = history.location.state?.from as Location;
    const login = useLogin();
    const showMessage = useShowMessage();
    const windowDimensions = useWindowDimensions();
    const redirectToPhoneVerification = useRedirectToPhoneVerification();
    const isMobile = windowDimensions.width < Variables.BreakPoints.smaller;
    const envName = process.env.REACT_APP_DEV_ENV;

    const performLogin = (values: any, urlBuilder: UrlBuilder) => {
        setDisabled(true);
        login({ login: values.email, password: values.password })
            .then((response: { user: UserV2; token: string }) => {
                const user = response.user;
                if (!user.phoneNumberVerified) {
                    Storage.set('temp_token', response.token);
                    redirectToPhoneVerification({ user, from });
                } else {
                    setAuthToken(user, response.token);
                    redirectAfterLogin(
                        history,
                        urlBuilder,
                        user,
                        response.token,
                        from
                    );
                }
            })
            .catch(error => {
                const errorMessage =
                    error.code === 404
                        ? NOT_FOUND_ACCOUNT_ERROR
                        : error.message;
                setDisabled(false);
                showMessage(errorMessage, 'error');
            });
    };

    const forgotPasswordAction = (): void => {
        history.push('/forgot');
    };

    const mobileNumberLoginAction = (): void => {
        history.push(ROUTES.AUTH_PAGES.MOBILE_NUMBER_LOGIN);
    };

    useHtmlPageTitle('Login');

    return (
        <UrlBuilderContext.Consumer>
            {urlBuilder => (
                <LoginAuthWrapper contentWidth={343} heightToShowScroll={650}>
                    <StyledLoginContent>
                        {['dev', 'local'].includes(envName as string) && (
                            <ApiPlatformDropdown dataTest="api-platform-selector" />
                        )}
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={values => {
                                performLogin(values, urlBuilder);
                            }}
                            validationSchema={LoginSchema}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <StyledInputDivs isMobile={isMobile}>
                                        <Field
                                            type="text"
                                            component={FormikField}
                                            name="email"
                                            label="Email Address"
                                            height="56px"
                                            colorTheme="darkThree"
                                            fontSize="15px"
                                            handleChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setFieldValue(
                                                    'email',
                                                    e
                                                        ? e.target.value.trim()
                                                        : ''
                                                );
                                            }}
                                            value={values.email}
                                            labelFontSize="15px"
                                            labelFontWeight={600}
                                            errorPadding="8px 5px 0 26px"
                                            errorColor={Variables.Colors.tomato}
                                            className="login-input"
                                            dataTest="login-email"
                                        />
                                    </StyledInputDivs>

                                    <StyledInputDivs
                                        isMobile={isMobile}
                                        marginBottom="8px"
                                    >
                                        <Field
                                            type="password-with-eye"
                                            component={FormikField}
                                            name="password"
                                            label="Password"
                                            height="56px"
                                            colorTheme="darkThree"
                                            fontSize="15px"
                                            labelFontSize="15px"
                                            labelFontWeight={600}
                                            errorPadding="8px 5px 0 26px"
                                            errorColor={Variables.Colors.tomato}
                                            className="login-input"
                                            dataTest="login-password"
                                        />
                                    </StyledInputDivs>
                                    <ForgotPasswordLink
                                        onClick={forgotPasswordAction}
                                        dataTest="forgot-password"
                                    >
                                        Forgot Password?
                                    </ForgotPasswordLink>
                                    <StyledActionsDivs
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                        marginTop={16}
                                        padding="0"
                                    >
                                        <Button
                                            height="56px"
                                            width="100%"
                                            loading={disabled}
                                            buttonWidth="100%"
                                            fontSize={16}
                                            dataTest="login-button"
                                        >
                                            Log In
                                        </Button>
                                    </StyledActionsDivs>
                                    <StyledActionsDivs
                                        marginTop={20}
                                        padding="0"
                                    >
                                        <StyledSeparatorWrapper>
                                            <StyledSeparator
                                                isMobile={isMobile}
                                            />
                                            <StyledSeparatorText
                                                isMobile={isMobile}
                                                data-test="login-button-separator"
                                            >
                                                or log in with
                                            </StyledSeparatorText>
                                            <StyledSeparator
                                                isMobile={isMobile}
                                            />
                                        </StyledSeparatorWrapper>
                                    </StyledActionsDivs>
                                    <StyledActionsDivs
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                        marginTop={8}
                                        padding="0"
                                    >
                                        <Button
                                            type="button"
                                            height="56px"
                                            width="100%"
                                            buttonWidth="100%"
                                            variant="inverse"
                                            fontSize={15}
                                            action={mobileNumberLoginAction}
                                            dataTest="mobile-number-button"
                                        >
                                            Mobile Number
                                        </Button>
                                    </StyledActionsDivs>
                                </Form>
                            )}
                        </Formik>
                    </StyledLoginContent>
                </LoginAuthWrapper>
            )}
        </UrlBuilderContext.Consumer>
    );
};

export default Login;