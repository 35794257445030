import { FC } from 'react';

import { TableLabelTags } from '../../../TableLabelTags';
import { LabelTagsProps } from './types';

const LabelTags: FC<LabelTagsProps> = props => {
    const { row, getLabelTagsProps, className } = props;

    return (
        <TableLabelTags {...getLabelTagsProps?.(row)} className={className} />
    );
};

export default LabelTags;
