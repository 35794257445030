import NoFiltersIcon from '../../../../icons/NoFiltersIcon';
import { EmptyState } from '../EmptyState';
import { NoFilterResultsProps } from './types';

function NoFilterResultsView({
    icon,
    noFilterText,
    width = '169px',
    dataTest
}: NoFilterResultsProps) {
    return (
        <EmptyState icon={icon || NoFiltersIcon} width={width} dataTest={dataTest}>
            {noFilterText || 'No Results Match the Filter Criteria'}
        </EmptyState>
    );
};

export default NoFilterResultsView;