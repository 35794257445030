import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDuplicateRoleplayApi, useGenerateRoleplayApi, useGetRoleplayContentApi, useResetRoleplayApi, useRevertRoleplayApi } from "../../../api/roleplay";
import { isAdminRole, isPractisAdminRole } from "../../../constants/enums";
import { ContentItem } from "../../../constants/interfaces/Roleplay";
import useHtmlPageTitle from "../../../helpers/hooks/useHtmlPageTitle";
import ROUTES from "../../../routes/routes";
import { generateCopyOfEntityName } from "../../../services/GeneralBulkActionServices/helpers";
import { useHistory } from "../../../tools/router";
import { ActionButton, ActionItem } from "../../../ui/components/ActionButton";
import { Button } from "../../../ui/components/Button";
import { useShowMessage } from "../../../ui/components/ErrorMessages/ErrorMessages";
import { useShowConfirmModalDialog } from "../../../ui/components/ModalDialogs/store/actions";
import Dots from "../../../ui/icons/Dots";
import { InfoIcon } from "../../../ui/icons/InfoIcon";
import MainWrapper from "../../../ui/wrapper/MainWrapper/MainWrapper";
import { getProfileState } from "../../UserProfile/store/reducers";
import { initialRolePlayData, keysForGA, requiredContextParams } from "../CreateRoleplay/constants";
import { RolePlayContextParams, RoleplayData } from "../CreateRoleplay/types";
import useGoogleAnalyticsWithRoleplay from "../GoogleAnalytics";
import useWebSocket from "../websocketV2/service";
import ContentPanel from "./components/ContentPanel";
import ContextPanel from "./components/ContextPanel";
import RoleplayHeader from "./components/RoleplayHeader";
import RoleplayInfoModal from "./components/RoleplayInfoModal";
import { useClearRoleplayService, useGetRoleplayService, useUpdateRoleplayService } from "./store/services";
import { useRoleplayState } from "./store/states";
import { BodyContainer, Container, ContentContainer, ContextContainer, Footer, InfoText, ButtonContainer, RoleplayMenuDots } from "./styles";

function RoleplayPage() {
    const { roleplayId }: { roleplayId: string } = useParams();
    const getRoleplay = useGetRoleplayService();
    const getRoleplayContent = useGetRoleplayContentApi();
    const clearRoleplay = useClearRoleplayService();
    const duplicateRoleplay = useDuplicateRoleplayApi();
    const showMessage = useShowMessage();
    const generateRoleplay = useGenerateRoleplayApi();
    const resetRoleplayApi = useResetRoleplayApi();
    const [emptyRequiredProps, setEmptyRequiredProps] = useState<RolePlayContextParams[]>();
    const updateRoleplay = useUpdateRoleplayService();
    const showConfirmationModalDialog = useShowConfirmModalDialog();
    const { response, sendMessage, closeConnection } = useWebSocket();
    const token = localStorage.getItem('token');
    const [isEditMode, setIsEditMode] = useState(false);
    useHtmlPageTitle('Roleplay AI');
    const infoPanel = localStorage.getItem('infoPanel');

    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [isLastError, setLastError] = useState<boolean>(false);
    const [stopIsDisabled, setStopIsDisabled] = useState<boolean>(false);
    const [actionSent, setActionSent] = useState<boolean>(false);
    const [contentData, setContentData] = useState<ContentItem[]>();
    const history = useHistory();
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();

    const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);
    const profile = useSelector(getProfileState);
    const revertRoleplayApi = useRevertRoleplayApi();
    const [edittedRoleplay, setEdittedRoleplay] = useState<RoleplayData | null>({} || null);

    const handleChange = (key: RolePlayContextParams, value: string) => {
        setEdittedRoleplay({ ...edittedRoleplay, [key]: value });
    }

    const { data: roleplay, isLoading } = useRoleplayState();

    useEffect(() => {
        infoPanel === 'true' && setShowInfoPanel(true)
    }, [infoPanel])

    const isOwner = useMemo(() => {
        return roleplay?.owner.id === profile?.id;
    }, [profile?.id, roleplay?.owner.id]);

    useEffect(() => {
        if (!actionSent && !!profile?.id && !!roleplay?.owner.id && profile?.id !== roleplay?.owner.id) {
            trackEventWithRoleplay('roleplay_screen_open_read_only');
            setActionSent(true);
        } 
    }, [isOwner, trackEventWithRoleplay, profile?.id, roleplay?.owner.id, actionSent])

    const hasContent = useMemo(() => {
        return !!contentData?.length
    }, [
        contentData
    ])

    const getContent = useCallback(() => {
        getRoleplayContent({ roleplayId }).then((content) => {
            if (content) {
                setContentData(content);
                if (content.length && content[content.length - 1].type === 'error') {
                    setLastError(true);
                    setIsGenerating(false);
                } else {
                    setLastError(false);
                }
            }
        })
    }, [roleplayId, setLastError, getRoleplayContent])

    useEffect(() => {
        isLastError && trackEventWithRoleplay('roleplay_screen_generate_error');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastError])

    const isAdmin = useMemo(() => {
        return isAdminRole(profile?.role?.name) || isPractisAdminRole(profile?.role?.name)
    }, [profile?.role?.name])

    useEffect(() => {
        getRoleplay({ roleplayId });
        getContent();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleplayId, getRoleplay]);

    const handleDuplicate = () => {
        roleplay && duplicateRoleplay({
            roleplayId: roleplay.id.toString(),
            title: generateCopyOfEntityName(roleplay.title)
        }).then((roleplay) => {
            trackEventWithRoleplay('roleplay_screen_duplicate');
            showMessage('Roleplay has been duplicated', 'success');
            history.replace(`${ROUTES.ROLEPLAYS.SINGLE.replace(
                ':roleplayId',
                roleplay.id.toString()
            )}`)
            infoPanel === null && localStorage.setItem(
                'infoPanel',
                'true'
            );
        })
    };

    const generateFirstRoleplay = useCallback(() => {
        roleplay?.id && generateRoleplay({ roleplayId: roleplay.id.toString() });
        trackEventWithRoleplay('roleplay_screen_generate');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setEmptyRequiredProps, generateRoleplay, roleplay, showMessage])

    const resetRoleplay = () => {
        showDiscardModal();
    }

    const revertCommand = useCallback((isStop?: boolean) => {
        setStopIsDisabled(true);
        isStop ? trackEventWithRoleplay('roleplay_screen_stop_generation'): trackEventWithRoleplay('roleplay_screen_error_revert');
        roleplay?.id && revertRoleplayApi({ roleplayId: roleplay.id.toString() }).then(() => {
            getContent();
            setIsGenerating(false);
            setStopIsDisabled(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleplay?.id, revertRoleplayApi])

    const showDiscardModal = useCallback(
        () => {
            showConfirmationModalDialog({
                modalTitle: 'Reset Roleplay?',
                description:
                    'It will permanently delete all history of generated replays and custom instructions while keeping your context intact.',
                confirmButtonText: 'Reset',
                cancelButtonText: 'Cancel',
                onConfirm: () => {
                    roleplay?.id && resetRoleplayApi({ roleplayId: roleplay.id.toString() }).then(() => {
                        getContent();
                        trackEventWithRoleplay('roleplay_screen_reset');
                    })
                },
                dataTest: 'reset-roleplay'
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showConfirmationModalDialog, resetRoleplayApi, roleplay, getRoleplay]
    );

    useEffect(() => {
        if (response === 'opened' && roleplayId && isOwner) {
            token && sendMessage({ event: 'authenticate', data: token });
        } else {
            try {
                const socketResponse = JSON.parse(response);
                if (socketResponse.type === 'auth') {
                    sendMessage({
                        "event": "watch",
                        "data": `roleplay:${roleplayId}`
                    })
                }
                if (socketResponse.type === 'roleplay.content') {
                    const dataItem = socketResponse.data;
                    if (dataItem.type === 'conversation') {
                        setIsGenerating(!dataItem.isFinished)
                    }
                    if (dataItem.type === 'conversation' && dataItem.isFinished) {
                        trackEventWithRoleplay('roleplay_screen_script_generated');
                    }
                    if (dataItem.type === 'error') {
                        setLastError(true);
                        setIsGenerating(false);
                    }
                    setContentData((prevState) => {
                        const updatedConversation = [...(prevState || [])];

                        const existingItemIndex = prevState ? prevState.findIndex((item) => {
                            return item.id === dataItem.id
                        }) : -1;
                        if (existingItemIndex !== -1) {
                            updatedConversation[existingItemIndex] = dataItem;
                        } else {
                            updatedConversation.push(dataItem);
                        }
                        return updatedConversation;
                    });

                }
            } catch (e) {}
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, token, roleplayId, setContentData, setLastError, setIsGenerating, isOwner]);

    useEffect(() => {
        return () => {
            clearRoleplay();
            closeConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveChanges = () => {
        if (edittedRoleplay) {
            const arr: RolePlayContextParams[] = [];
            requiredContextParams.forEach((item) => {
                if ({ ...roleplay, ...edittedRoleplay }?.[item] === '') {
                    arr.push(item)
                }
            })
            if (arr?.length) {
                setEmptyRequiredProps(arr?.length ? arr : undefined)
                showMessage('One or more required fields are missing', 'error')
            } else {
                setEmptyRequiredProps(undefined);

                edittedRoleplay && Object.entries(edittedRoleplay).forEach(([key, value]) => {
                    if (value !== initialRolePlayData[key as keyof typeof initialRolePlayData]) {
                        const keyGA = keysForGA[key as keyof typeof keysForGA];
                        keyGA && trackEventWithRoleplay(`roleplay_screen_${keyGA}_updated`);
                    }
                });

                updateRoleplay(roleplay?.id, { ...roleplay, ...edittedRoleplay }, edittedRoleplay, hasContent ? 'command' : undefined).then(() => {
                    cancelChanges();
                });
                trackEventWithRoleplay('roleplay_screen_save_context');
                hasContent && trackEventWithRoleplay('roleplay_screen_instruction_sent_auto', {CharacterLimit: Object.values(edittedRoleplay).join('').length});
            }
        } else {
            cancelChanges();
        }
    }

    const cancelChanges = () => {
        setIsEditMode(false);
        setEdittedRoleplay(null);
        setEmptyRequiredProps(undefined);
    }

    return <MainWrapper subTitle="Roleplay AI" contentSize={false} dataTest="roleplay" hideHeader contentPadding="0px" marginBottom="0px">
        <Container>
            <RoleplayHeader isDisabled={!isOwner || isGenerating || isEditMode} />
            <BodyContainer>
                <ContextContainer fullHeight={isLoading || isOwner}>
                    <ContextPanel emptyRequiredProps={emptyRequiredProps} rpdata={{ ...roleplay, ...edittedRoleplay }} isEditMode={isEditMode} handleChange={handleChange} />
                    {roleplay && <ButtonContainer>
                        {
                            isEditMode ?
                                <>
                                    <Button width="140px" action={saveChanges} dataTest="save-button">
                                        Save
                                    </Button>
                                    <Button dataTest="cancel-button" width="140px" variant='inverse' action={() => {trackEventWithRoleplay('roleplay_screen_cancel_context'); cancelChanges()}}>
                                        Cancel
                                    </Button>
                                </> :
                                <>
                                    {!hasContent &&
                                        <Button width="164px" action={generateFirstRoleplay} dataTest="generate-button">
                                            Generate Roleplay
                                        </Button>
                                    }
                                    <Button dataTest="edit-context-button" width="140px" disabled={isGenerating || isLastError} variant='inverse' action={() => {trackEventWithRoleplay('roleplay_screen_edit_context'); setIsEditMode(true)}}>
                                        Edit Context
                                    </Button>
                                    {hasContent &&
                                        <ActionButton isDisabled={isGenerating || isLastError} customWidth={200} placement={'bottom-start'} isHidden={false} dataTest="roleplay-menu" customLeft={1} button={<RoleplayMenuDots disabled={isGenerating || isLastError} dataTest="roleplay-action-dots" width="40px" variant='inverse'><Dots className="dots-btn" dataTest="dots-menu" /></RoleplayMenuDots>}>
                                            <ActionItem dataTest="reset-button" onClick={resetRoleplay} destructive={true}>
                                                Reset
                                            </ActionItem>
                                        </ActionButton>
                                    }
                                </>
                        }
                    </ButtonContainer>}
                </ContextContainer>
                <ContentContainer fullHeight={isLoading || isOwner}>
                    <ContentPanel isDisabled={isGenerating || isLastError || isEditMode} isGenerating={isGenerating} contentData={contentData} revertCommand={revertCommand} stopIsDisabled={stopIsDisabled} />
                </ContentContainer>
            </BodyContainer>
            {roleplay && !isOwner && <Footer>
                {
                    !isAdmin && <>
                        <InfoIcon dataTest="info-icon" />
                        <InfoText data-test="info-text">
                            Roleplay is in view-only mode. You need the Admin rights to edit it.</InfoText>
                    </>
                }
                {
                    isAdmin &&
                    <Button action={handleDuplicate} width="164px" dataTest="duplicate-button">
                        Duplicate to edit
                    </Button>
                }
            </Footer>}
        </Container>
        {
            showInfoPanel && <RoleplayInfoModal dataTest="roleplay-info-modal" onClose={() => {
                setShowInfoPanel(false);
                trackEventWithRoleplay('roleplay_screen_disclaimer');
                localStorage.setItem(
                    'infoPanel',
                    'false'
                )
            }} />
        }
    </MainWrapper >
}

export default RoleplayPage;