import { FC } from 'react';

import styled from 'styled-components';
import { TableBody } from '../table-body-wrapper/table-body';

const TableRow = styled.tr`
    pointer-events: none;
`;

const StyledTableBody = styled(TableBody)`
    &:before {
        line-height: unset;
    }
`

export const TableNoResultBody: FC<{}> = ({ children }) => (
    <StyledTableBody>
        <TableRow>
            <td colSpan={100}>{children}</td>
        </TableRow>
    </StyledTableBody>
);
