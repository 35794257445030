import { isEmpty } from "lodash";

export const copyTextToClipboard = (text: string) => {
    if(isEmpty(text)) {
        return false
    }

    const textArea: any = document.createElement('textarea');
    let successful: 'silent' | boolean = false;

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    textArea.style.width = '2em';
    textArea.style.height = '2em';

    textArea.style.padding = 0;

    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        successful = document.execCommand('copy');
    } catch (err) {
        successful = false;
    }

    document.body.removeChild(textArea);
    if (!successful) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        prompt(`Press ${copyHotkey}`, text);
        successful = 'silent';
    }

    return successful;
};
