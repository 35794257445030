import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PortableLabelsWithStore } from '../../../../../../portableLabels';
import { useCalculatePreSelectedLabels } from '../../../../../../labels/tools';
import { useIfChanged } from '../../../../../../../helpers/hooks/usePreviousData';
import {
    useResetSelectedLabels,
    useSelectLabels,
} from '../../../../../../portableLabels/store/hors/withLabels/services';
import { useLabelsState } from '../../../../../../portableLabels/store/hors/withLabels/states';
import { getCompanyState } from '../../../../../../../pages/CompanySettings/store/reducers';
import { PerformanceTrainee } from '../../../../../../../constants/interfaces/PerformanceTrainee';

export const FilterLabels: FC<{
    user?: PerformanceTrainee;
}> = ({ user }) => {
    const labelsState = useLabelsState();
    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();
    const company = useSelector(getCompanyState);
    const select = useSelectLabels();
    const resetSelected = useResetSelectedLabels();
    const ifCompanyChanged = useIfChanged(company);

    const getPreselectedLabels = useCallback((): number[] | null => {
        if (user) {
            const processedUser = {
                ...user,
                labels:
                    user?.labels?.map((labelId: number) => ({ id: labelId })) ??
                    [],
            };

            const { preAssignedLabels } = calculatePreSelectedLabels(
                [user.id!],
                {
                    items: [processedUser],
                }
            );
            return preAssignedLabels;
        }

        return null;
    }, [user, calculatePreSelectedLabels]);

    const setDefaultState = useCallback(() => {
        const preselectedLabels = getPreselectedLabels();

        if (preselectedLabels !== null) {
            select(preselectedLabels);
        }
    }, [select, getPreselectedLabels]);

    useEffect(() => {
        setDefaultState();
    }, [setDefaultState, user]);

    useEffect(() => {
        setDefaultState();
    }, [setDefaultState]);

    useEffect(() => {
        if (ifCompanyChanged) {
            resetSelected();
            setDefaultState();

            const preselectedLabels = getPreselectedLabels();

            if (preselectedLabels !== null) {
                select(preselectedLabels);
            }
        }
    }, [
        getPreselectedLabels,
        ifCompanyChanged,
        labelsState.selected,
        select,
        resetSelected,
        setDefaultState,
    ]);

    return (
        <PortableLabelsWithStore
            showSelectActions
            showSave={false}
            showSearch
        />
    );
};
