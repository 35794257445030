import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import styled from 'styled-components';
import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import {
    isCompanyAdminRole,
    isPractisAdminRole,
    pendingUserRoleTitle,
    userRoleTitle,
    isTeamLeader,
} from '../../../../constants/enums';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import ROUTES from '../../../../routes/routes';
import { useHistory, useParams } from 'react-router';
import {
    UserProfile,
    UserInterface,
} from '../../../../constants/interfaces/User';
import { PerformanceTrainee } from '../../../../constants/interfaces/PerformanceTrainee';
import { useUserPerformanceState } from '../../store/states';
import {
    useNudgeTraineesService,
    useAssignFiltersToUserService,
    useFetchUserAllPractisSets,
    useUserPerformanceService,
} from '../../store/services';
import { useLabelsState } from '../../../labels/store/states';
import { useLibraryPractisSetState } from '../../../library/store/states';
import { useUpdateAssignedLibraryPractisSetService } from '../../../library/store/services';
import {
    useSetAssignLabelsActionService,
    useSetPreviouslyAssignedLabelsAction,
} from '../../../labels/store/services';
import { treeToList } from '../../../../helpers/functions/tree-to-list';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useSelector } from 'react-redux';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import { History } from 'history';
import { CheckPermission } from '../../../permissions';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { Button } from '../../../../ui/components/Button';
import { PerformanceSectionContainerRef } from '../../components/PerformanceSection';
import { usePermissionsState } from '../../../permissions/store/state';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { Variables } from '../../../../theme/variables';
import { WithPractisSetsContext } from '../../../portablePractisSets';
import { Popup } from '../../../../ui/components/Popup';
import UpArrow from '../../../../ui/icons/UpArrow';
import DownArrow from '../../../../ui/icons/DownArrow';
import { WithLabelsContext } from '../../../portableLabels';
import { AssignFilters } from '../../components/Filters/UserProfileFilters/AssignFilters';
import { WithTeamsContext } from '../../../portableTeams';
import { PractisSetWithDueDate } from '../../../../constants/interfaces/Draft';
import { TableRefresh } from '../../../../ui/components/table-wrapper/table-refresh';
import { TableDivider } from '../../../../ui/components/table-wrapper/table-divider';
import { NudgeUser } from '../../../../ui/components/table-wrapper/table/TableAssignOptionsMenu/components/NudgeUser';
import { EnrollmentsDueDateType } from '../../../../api/enrollments/types';
import { NudgeData } from '../../../../api/alert/types';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';

const StyledContainer = styled.div``;

const StyledUserDetailsInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const ReportGeneralInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 17px 0 17px 0;
    flex-wrap: wrap;
`;

const StyledDetailsAvatar = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 4px;
    margin-right: 22px;
    overflow: hidden;
`;

const StyledUserInfoWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledUserInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledUserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const UserRole = styled.p`
    font-size: 11px;
    font-weight: 600;
    color: ${props => props.theme.Colors.steelGrey};
    margin: 0;
    line-height: 1.2;
    text-transform: capitalize;
`;

const StyledName = styled.div<{ isFlex?: boolean }>`
    ${props => !!props.isFlex && `display: flex; align-items: center;`}
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.Colors.black};
`;

const StyledEmail = styled.div`
    font-size: 11px;
    font-weight: bold;
    color: ${props => props.theme.Colors.steelGrey};
`;

const Content = styled.div``;

const StyledUserOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;

    > * {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

const StyledPendingTitle = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.Colors.brown};
`;

const StyledPendingInvitationLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-radius: 20px;
    background-color: ${props => props.theme.Colors.macaroniAndCheeseOpaque};
    padding: 4px 12px;
    margin-left: 10px;
`;

const StyledAdditionalInfo = styled.span`
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.steelGrey};
    margin-left: 3px;
`;

const StyledLabelActionPanel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
`;

const ActionDropdownsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledAssignAction = styled.div<{ open?: boolean; disabled?: boolean }>`
    display: flex;
    height: 40px;
    width: 152px;
    border-radius: 4px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background: ${props => props.theme.Colors.whiteFive};
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    border: ${props =>
        props.open
            ? `1px solid ${props.theme.Colors.cloudyBlue}`
            : `1px solid ${props.theme.Colors.whiteFive}`};
    position: relative;
`;

const IconHolder = styled.div`
    width: 8px;
    height: 100%;
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledLabel = styled.div`
    position: absolute;
    top: 0;
    left: 16px;
    pointer-events: none;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props => props.theme.Colors.steelGrey};
`;

const Performance: FC<{
    history: History<any>;
    trainee?: PerformanceTrainee;
    profile?: UserProfile;
    onGetTrainee: (userId: number, isInvitation: boolean) => any;
    params: { userId?: number };
    loading?: boolean;
    assignedLabels?: number[];
    setAssignPractisSetsAction?(practisSetIds: number[]): void;
    setAssignLabelsAction?(labelIds: number[]): void;
    setPreviouslyAssignedLabelsAction?(labels: number[]): void;
    assignedPractisSets?: number[];
    showMessage: any;
    company?: CompanyInterface;
    previouslyAssignedLabels?: number[];
    isInvitation: boolean;
    nudgeFromUser?: string;
    onNudgeTrainees(
        nudgeUserDate: NudgeData[],
        successCallback?: () => void
    ): void;
}> = ({
    history,
    trainee,
    profile,
    onGetTrainee,
    loading,
    params,
    setAssignPractisSetsAction,
    setAssignLabelsAction,
    showMessage,
    company,
    setPreviouslyAssignedLabelsAction,
    previouslyAssignedLabels,
    isInvitation,
    nudgeFromUser,
    onNudgeTrainees,
}) => {
    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [showNudgeDialog, setShowNudgeDialog] = useState(false);
    const [, setPractisSetsCount] = useState(
        trainee && trainee.practisSets && trainee.practisSets.length
            ? trainee.practisSets.length
            : 0
    );
    const [useGoBack, setUseGoBack] = useState(false);

    const fetchUserAllPractisSets = useFetchUserAllPractisSets();

    const permissions = usePermissionsState();

    const handleSettingsClick = useCallback(() => {
        if (trainee && permissions.includes(NEW_PERMISSIONS.VIEW_PROFILE)) {
            history.push(
                ROUTES.PROFILE.replace(':userId', trainee.id!.toString())
            );
        }
    }, [history, trainee, permissions]);

    const onNudgeUserSuccessCallback = () => {
        setShowNudgeDialog(false);
    };

    const onNudgeSendButtonClicked = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;
            if (trainee && trainee.id) {
                const nudgeUserData: NudgeData = {
                    type: 'common',
                    enrollmentId: null,
                    receiverId: trainee.id,
                    text,
                    senderName,
                };
                onNudgeTrainees([nudgeUserData], onNudgeUserSuccessCallback);
            }
        },
        [onNudgeTrainees, trainee]
    );
    const shouldAssignTeams =
        (!isTeamLeader(profile?.role?.name) ||
            isCompanyAdminRole(profile?.role?.name)) &&
        !isPractisAdminRole(trainee?.role?.name);

    const handleAssignFiltersToCheckedUsers = async (
        assignedLabels: number[],
        deletedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        deletedEnrollmentIds: number[],
        assignedTeams: number[],
        deletedTeams: number[],
        updatedEnrollments: EnrollmentsDueDateType[]
    ) => {
        if (!params.userId) return false;

        return assignFiltersToUserService(params.userId, shouldAssignTeams, {
            assignedLabelsIDs: assignedLabels,
            deletedLabelIDs: deletedLabels,
            assignedPractisSetIDs: assignedPractisSets,
            deletedEnrollmentIds: deletedEnrollmentIds,
            assignedTeamIDs: assignedTeams,
            deletedTeamIDs: deletedTeams,
            practisSetsWithEnrollmentIds: assignedPractisSets?.map(
                assignedPractisSet => ({
                    enrollmentId: assignedPractisSet.practisSetId,
                    dueDate: assignedPractisSet.dueDate,
                })
            ),
            updatedEnrollments
        })
            .then(() => {
                showMessage(`Changes have been saved`, 'success');
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    /**
     * @function handleOpenAssignFilter
     * @param { Function } toggleShown
     * @returns { Promise<void> }
     */
    const handleOpenAssignFilter = async (toggleShown: Function) =>
        await fetchUserAllPractisSets(trainee!.id!.toString()).then(() => {
            toggleShown();
        });

    const perfRef = useRef<any>(null);

    const refreshPractisSets = useCallback(() => {
        perfRef.current?.refreshPractisSetsSearch();

        onGetTrainee(params.userId!, isInvitation);
    }, [onGetTrainee, params.userId, isInvitation]);

    useEffect(() => {
        if (params.userId) {
            onGetTrainee(params.userId, isInvitation);
        }
    }, [onGetTrainee, params.userId, isInvitation]);

    const assignFiltersToUserService = useAssignFiltersToUserService();

    const isPending = trainee?.status === 'PENDING';

    const refreshData = useCallback(() => {
        if (params.userId) {
            onGetTrainee(params.userId, isInvitation);
            setLastRefreshed(new Date());
        }
    }, [onGetTrainee, params.userId, isInvitation]);

    const prevCompanyRef = useRef(company);
    useEffect(() => {
        prevCompanyRef.current = company;
    });
    const prevCompany = prevCompanyRef.current;

    useEffect(() => {
        if (!company!.id || !prevCompany!.id) return;
        if (company!.id !== prevCompany!.id) {
            history.push(ROUTES.USERS);
        }
    }, [company, prevCompany, history]);

    useEffect(() => {
        if (
            trainee &&
            trainee.labels &&
            trainee.labels.length &&
            setPreviouslyAssignedLabelsAction &&
            params.userId &&
            trainee.id === parseInt(params.userId.toString())
        ) {
            const flatList = treeToList((trainee && trainee.labels) || []);
            const flatListIds = flatList.map((item: any) => item.id);
            const labelIds = trainee.labels.map(label => label.id);
            const updatedFlatListIds = flatListIds.map((id: any) =>
                labelIds.includes(id) ? id : -id
            );
            setPreviouslyAssignedLabelsAction(updatedFlatListIds);
        }
    }, [trainee, setPreviouslyAssignedLabelsAction, params.userId]);

    useEffect(() => {
        if (
            previouslyAssignedLabels &&
            previouslyAssignedLabels.length &&
            setAssignLabelsAction
        ) {
            setAssignLabelsAction(previouslyAssignedLabels);
        }
    }, [setAssignLabelsAction, previouslyAssignedLabels]);

    useEffect(() => {
        if (isInvitation && trainee && trainee.practisSets) {
            const assignedPractisSets = trainee.practisSets.map(
                (set: any) => set.id
            );

            setAssignPractisSetsAction?.(assignedPractisSets);
        }
    }, [trainee, setAssignPractisSetsAction, isInvitation]);

    useEffect(() => {
        return () => {
            setAssignPractisSetsAction && setAssignPractisSetsAction([]);
            setAssignLabelsAction && setAssignLabelsAction([]);
            setPreviouslyAssignedLabelsAction &&
                setPreviouslyAssignedLabelsAction([]);
        };
    }, [
        setAssignPractisSetsAction,
        setAssignLabelsAction,
        setPreviouslyAssignedLabelsAction,
    ]);

    useEffect(() => {
        // save useGoBack in local state on mount to keep back button working correctly after list paging
        setUseGoBack(!!history.location.state?.useGoBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainWrapper
            subTitle="User Profile"
            goBackUrl={ROUTES.USERS}
            useGoBack={useGoBack}
            height="100%"
            htmlPageTitle={`User Profile ${
                trainee ? `- ${trainee?.firstName} ${trainee?.lastName}` : ''
            } - Practis`}
            dataTest="user-profile"
        >
            <TableRefresh
                lastRefreshed={lastRefreshed}
                refreshData={refreshData}
                dataTest="user-profile-timestamp"
            />
            {loading && <LoadingComponent />}
            <StyledContainer>
                <ReportGeneralInfoWrapper>
                    <StyledUserDetailsInfo>
                        <StyledUserInfoWrapper>
                            <StyledDetailsAvatar>
                                <AvatarPlaceholder
                                    profile={
                                        trainee as unknown as UserInterface
                                    }
                                    imgBorderRadius="4px"
                                    iconWidth={17}
                                    iconHeight={20}
                                    bottomPosition={-6}
                                    noHint={true}
                                    dataTest="user-profile-avatar"
                                />
                            </StyledDetailsAvatar>
                            <StyledUserInfo>
                                {trainee ? (
                                    <UserRole data-test="user-profile-role">
                                        {isInvitation
                                            ? pendingUserRoleTitle(trainee)
                                            : userRoleTitle(trainee)}
                                    </UserRole>
                                ) : (
                                    <Skeleton
                                        variant="text"
                                        width={20}
                                        height={10}
                                        style={{
                                            background: 'var(--ps-grey-4',
                                        }}
                                    />
                                )}

                                {trainee ? (
                                    <StyledName
                                        isFlex={isInvitation || isPending}
                                        data-test="user-profile-full-name"
                                    >
                                        {`${trainee?.firstName ?? ''} ${
                                            trainee?.lastName ?? ''
                                        }`}

                                        {profile?.id === trainee?.id && (
                                            <StyledAdditionalInfo>
                                                (You)
                                            </StyledAdditionalInfo>
                                        )}

                                        {(isPending || isInvitation) && (
                                            <StyledPendingInvitationLabel>
                                                <StyledPendingTitle data-test="pending-registration-label">
                                                    Pending Registration
                                                </StyledPendingTitle>
                                            </StyledPendingInvitationLabel>
                                        )}
                                    </StyledName>
                                ) : (
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        height={10}
                                        style={{
                                            background: 'var(--ps-grey-4',
                                        }}
                                    />
                                )}
                                <StyledEmail data-test="user-profile-email">
                                    {trainee?.email ?? (
                                        <Skeleton
                                            variant="text"
                                            width={150}
                                            height={10}
                                            style={{
                                                background: 'var(--ps-grey-4',
                                            }}
                                        />
                                    )}
                                </StyledEmail>
                            </StyledUserInfo>
                        </StyledUserInfoWrapper>
                        <StyledUserInfoContainer>
                            <StyledUserOptions>
                                <StyledLabelActionPanel>
                                    <ActionDropdownsWrapper>
                                        <WithLabelsContext.Provider
                                            value={{
                                                reducerName: 'draftEdit',
                                                scope: 'assignFilters',
                                            }}
                                        >
                                            <WithPractisSetsContext.Provider
                                                value={{
                                                    reducerName: 'draftEdit',
                                                    scope: 'assignFilters',
                                                }}
                                            >
                                                <Popup<HTMLDivElement>
                                                    content={({ hide }) => (
                                                        <AssignFilters
                                                            onSuccessApply={(
                                                                isCancel?: boolean
                                                            ) => {
                                                                if (!isCancel) {
                                                                    refreshPractisSets();
                                                                }
                                                                hide();
                                                            }}
                                                            user={trainee}
                                                            assignFilters={
                                                                handleAssignFiltersToCheckedUsers
                                                            }
                                                        />
                                                    )}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    {(
                                                        ref,
                                                        { toggleShown, shown }
                                                    ) => (
                                                        <StyledAssignAction
                                                            ref={ref}
                                                            onClick={() =>
                                                                handleOpenAssignFilter(
                                                                    toggleShown
                                                                )
                                                            }
                                                            open={shown}
                                                            data-test="user-profile-assign"
                                                        >
                                                            <StyledLabel>
                                                                Assign...
                                                            </StyledLabel>
                                                            <IconHolder>
                                                                {shown ? (
                                                                    <UpArrow />
                                                                ) : (
                                                                    <DownArrow />
                                                                )}
                                                            </IconHolder>
                                                        </StyledAssignAction>
                                                    )}
                                                </Popup>
                                            </WithPractisSetsContext.Provider>
                                        </WithLabelsContext.Provider>
                                    </ActionDropdownsWrapper>
                                </StyledLabelActionPanel>

                                <Button
                                    width="136px"
                                    height="40px"
                                    action={handleSettingsClick}
                                    variant="inverse"
                                    disabled={isInvitation || isPending}
                                    customDisabledColor={
                                        Variables.Colors.cloudyBlue
                                    }
                                    dataTest="user-settings"
                                >
                                    User Settings
                                </Button>
                                <Button
                                    width="152px"
                                    height="40px"
                                    action={() => setShowNudgeDialog(true)}
                                    customDisabledColor={
                                        Variables.Colors.cloudyBlue
                                    }
                                    disabled={isInvitation || isPending}
                                    dataTest="nudge-user"
                                >
                                    Nudge User
                                </Button>
                            </StyledUserOptions>
                        </StyledUserInfoContainer>
                    </StyledUserDetailsInfo>
                </ReportGeneralInfoWrapper>
                <TableDivider />
                <CheckPermission
                    permissions={[NEW_PERMISSIONS.VIEW_PRACTIS_SET_PROGRESS]}
                >
                    <Content>
                        {trainee && !loading && (
                            <PerformanceSectionContainerRef
                                ref={perfRef}
                                onPractisSetsUpdate={setPractisSetsCount}
                                isInvitation={isInvitation}
                            />
                        )}
                    </Content>
                </CheckPermission>
            </StyledContainer>
            {showNudgeDialog && (
                <DialogWrapper
                    customization={{
                        width: 480,
                        padding: '49px 32px 32px 32px',
                    }}
                >
                    <NudgeUser
                        title={`Send a direct message to ${
                            trainee ? trainee.firstName : ''
                        } ${trainee ? trainee.lastName : ''}`}
                        fromUser={nudgeFromUser}
                        onSend={onNudgeSendButtonClicked}
                        onClose={() => setShowNudgeDialog(false)}
                        placeholder="Write your message here…"
                    />
                </DialogWrapper>
            )}
        </MainWrapper>
    );
};

export const PerformanceContainer: FC<{ type?: string }> = ({ type }) => {
    const isInvitation = type === 'invitation';

    const trainee = useUserPerformanceState();
    const getUserPerformance = useUserPerformanceService();
    const params = useParams();
    const history = useHistory();

    const state = useLabelsState();
    const company = useSelector(getCompanyState);
    const libraryState = useLibraryPractisSetState();
    const setAssignPractisSetsAction =
        useUpdateAssignedLibraryPractisSetService();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const showMessage = useShowMessage();

    const setPreviouslyAssignedLabelsAction =
        useSetPreviouslyAssignedLabelsAction();
    const profile = useSelector(getProfileState);
    const nudgeTrainees = useNudgeTraineesService();

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'draftEdit' }}>
            <WithTeamsContext.Provider value={{ reducerName: 'draftEdit' }}>
                <WithPractisSetsContext.Provider
                    value={{ reducerName: 'draftEdit' }}
                >
                    <Performance
                        history={history}
                        trainee={trainee.data}
                        profile={profile}
                        onGetTrainee={getUserPerformance}
                        params={params}
                        loading={trainee.loading}
                        company={company}
                        assignedLabels={state.assignedLabels}
                        assignedPractisSets={libraryState.assignedPractisSets}
                        setAssignPractisSetsAction={setAssignPractisSetsAction}
                        setAssignLabelsAction={setAssignLabelsAction}
                        showMessage={showMessage}
                        setPreviouslyAssignedLabelsAction={
                            setPreviouslyAssignedLabelsAction
                        }
                        previouslyAssignedLabels={
                            state.previouslyAssignedLabels
                        }
                        isInvitation={isInvitation}
                        nudgeFromUser={
                            !!profile
                                ? `${profile.firstName} ${profile.lastName}`
                                : ''
                        }
                        onNudgeTrainees={nudgeTrainees}
                    />
                </WithPractisSetsContext.Provider>
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};

export default PerformanceContainer;