import { Reducer } from 'redux';
import {
    ACTIONS,
    searchPacingsFailure,
    searchPacingsStart,
    searchPacingsSuccess,
} from './actions';
import { PacingsState } from './states';
import { AppState } from '../../../store/reducers';

const initialPacingsState: PacingsState = {};

export type PacingsAction =
    | ReturnType<typeof searchPacingsStart>
    | ReturnType<typeof searchPacingsSuccess>
    | ReturnType<typeof searchPacingsFailure>;

export const pacingsReducer: Reducer<PacingsState, PacingsAction> = (
    state = initialPacingsState,
    action: PacingsAction
): PacingsState => {
    switch (action.type) {
        case ACTIONS.SEARCH_PACINGS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_PACINGS_SUCCESS:
            return {
                ...state,
                data: action.pacings,
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_PACINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
export const getPacingsState = (state: AppState) => state.pacings;
