export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';

export function userLoginAction() {
    return {
        type: USER_LOGIN,
    };
}

export function userLogoutAction() {
    return {
        type: USER_LOGOUT,
    };
}

export function setTokenAction(token: string) {
    return {
        type: SET_TOKEN,
        token,
    };
}
