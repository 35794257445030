import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const Input = styled.input<{ counter?: number, showError: boolean, suffix?: string }>`
    width: 100%;
    height: 40px;
    padding: 11px 12px;
    padding-right: ${props => props.suffix ? `${8 + props.suffix.length * 8.4}px` : '12px'};
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-black-main);
    border-radius: 4px;
    border: solid 1px ${props => props.showError ? 'var(--ps-red-main)' : 'var(--ps-grey-3)'};
    background-color: var(--ps-grey-3);
    box-sizing: border-box;

    &:focus {
        border: solid 1px ${props => props.showError ? 'var(--ps-red-main)' : 'var(--ps-grey-2)'};
        ${props => typeof props.counter === 'number'
            ? `padding-right: ${props.suffix
                ? `${4 + (props.counter.toString().length + props.suffix.length) * 8.4}px`
                : `${15 + (props.counter.toString().length) * 8.4}px`
                };`
            : ''
        }

        & + div > div {
            display: block;
        }
    }

    &:disabled {
        color: var(--ps-grey-2);
    }
`;

export const SuffixContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    top: 0;
`;

export const Counter = styled.div<{ isMaxLengthExceeded: boolean, hasSuffix: boolean }>`
    display: none;
    margin-top: 11px;
    margin-right: ${props => props.hasSuffix ? '8px' : '12px'};
    font-size: 13px;
    color: ${props => props.isMaxLengthExceeded ? 'var(--ps-red-main)' : 'var(--ps-grey-1)'};
`;

export const Suffix = styled.div<{ isMaxLengthExceeded: boolean }>`
    margin-top: 8px;
    margin-right: 8px;
    height: 24px;
    padding: 3px 0 3px 8px;
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-grey-1);
    border-left: 1px solid ${props => props.isMaxLengthExceeded ? 'var(--ps-red-main)' : 'var(--ps-grey-2)'};
`;

export const Label = styled.div`
    color: var(--ps-grey-1);
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
`;

export const Error = styled.div`
    color: var(--ps-red-main);
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
`;