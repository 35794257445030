import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../../constants/interfaces/ScriptLine';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from '../../../../../constants/interfaces/User';
import {
    useClearVoiceService,
    useTextToSpeechService,
} from '../../../../textToSpeech/store/services';
import { useVoiceGenerationState } from '../../../../textToSpeech/store/states';
import { findItem } from '../../../../../tools/ramda';
import { CompanyInterface } from '../../../../../constants/interfaces/Company';
import { CompanyVoiceSettings } from '../../../../../constants/interfaces/CompanyVoiceSettings';
import {
    AudioInterface,
    getAudioState,
} from '../../../../player/store/reducers';
import {
    useGoNextService,
    usePlayAudioService,
    useResetAudioService,
    useStartAudioRecordingService,
    useStopAudioRecordingService,
    useStopAudioService,
} from '../../../../player/store/services';
import { getProfileState } from '../../../../../pages/UserProfile/store/reducers';
import { getCompanyState } from '../../../../../pages/CompanySettings/store/reducers';
import {
    useDeleteChallengeScriptLineService,
    useModifyChallengeScriptLineService,
    useUploadChallengeAudioService,
} from '../../../store/services';
import { Button } from '../../../../../ui/components/Button';
import {
    Comment,
    CommentBody,
    CommentDescription,
    CommentTitle,
    CommentTools,
} from '../../../../../ui/components/Comment';
import AudioRecorder from '../../../../../ui/components/AudioRecorder/AudioRecorder';
import { AudioPlayer } from '../../../../../ui/components/AudioPlayer/AudioPlayer';
import Play from '../../../../../ui/icons/Play';
import Generate from '../../../../../ui/icons/Generate';
import Record from '../../../../../ui/icons/Record';
import ContentEditableInput from '../../../../../ui/components/ContentEditableDiv/ContentEditableDiv';
import { useCompanyVoiceSettingsState } from '../../../../../pages/CompanySettings/store/states';
import { useShowConfirmModalDialog } from '../../../../../ui/components/ModalDialogs/store/actions';
import { getTextToSpeechSettingsOrDefault } from '../../../../textToSpeech/helpers';

const StyledContainer = styled.div`
    z-index: 1;
    height: 100%;
    min-height: 62px;
`;

const StyledScriptItem = styled.div`
    width: 100%;
`;

const StyledButton = styled(Button)`
    margin-right: 8px;
    font-size: 11px;
    font-weight: 600;
`;

const StyledScriptAction = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledAudioSection = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const DisabledArea = styled.div`
    position: absolute;
    height: 100%;
    background: transparent;
    width: 100%;
    top: 0;
    left: 0;
`;

const CustomerScriptItem: FC<{
    script: ScriptLine;
    handleScriptLineDelete: any;
    handleScriptLineModify: (
        value: any,
        field: string,
        id: number | string
    ) => void;
    handleUploadAudio: (
        file: any,
        associatedEntityId: number | string,
        filename?: string
    ) => any;
    profile?: UserProfile;
    company: CompanyInterface;
    player: AudioInterface;
    handlePlayAudio: (scriptLineId: number | string) => void;
    handleStopAudio: (scriptLineId: number | string) => void;
    handleStartRecording: (scriptLineId: number | string) => void;
    handleStopRecording: () => void;
    handleGoNext: () => void;
    handleResetPlaylist: () => void;
    voiceSettings?: CompanyVoiceSettings[];
    disabled: boolean;
    editable: boolean;
    dispatch: Dispatch;
}> = ({
    script,
    handleScriptLineDelete,
    handleScriptLineModify,
    handleUploadAudio,
    editable,
    player,
    handlePlayAudio,
    handleStopAudio,
    handleGoNext,
    handleResetPlaylist,
    handleStartRecording,
    handleStopRecording,
    voiceSettings,
}) => {
    const textToSpeech = useTextToSpeechService();
    const showConfirmationModalDialog = useShowConfirmModalDialog();
    const clearAudio = useClearVoiceService();
    const voice = useVoiceGenerationState().challenges;
    const generatingAudio = voice && voice.generating;
    const loadingAudio = !!(
        voice &&
        voice.data &&
        findItem(voice.data, 'id', script.id)
    );
    const voiceData =
        voice &&
        voice.data &&
        findItem(voice.data, 'id', script.id) &&
        findItem(voice.data, 'id', script.id).voice;

    const [record, setRecord] = useState({ state: false, open: false });
    const [save, setSave] = useState(false);
    const [audio, setAudio] = useState<any>({});
    const disabled =
        (!!player.recording && player.recording !== script.id) ||
        generatingAudio;

    useEffect(() => {
        return () => {
            handleClickAway();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (save) {
            if (audio) {
                handleUploadAudio(audio, script.id).then(() => {
                    if (generatingAudio) {
                        clearAudio({
                            feature: 'challenges',
                            itemId: script.id,
                        });
                    }
                });
            }
        }
        // eslint-disable-next-line
    }, [audio]);

    const startRecording = (): void => {
        handleResetPlaylist();
        setTimeout(() => {
            handleStartRecording(script.id);
            setRecord({ open: true, state: true });
        }, 100);
    };

    const generateAudio = async (): Promise<any> => {
        const settingsOrDefault = getTextToSpeechSettingsOrDefault(voiceSettings);

        handleResetPlaylist();
        textToSpeech(
            script.text,
            settingsOrDefault.customer,
            { feature: 'challenges', itemId: script.id }
        );
    };

    useEffect(() => {
        if (voiceData) {
            setSave(true);
            setAudio(voiceData);
        }
    }, [voiceData]);

    const saveRecording = (): void => {
        setSave(true);
        setRecord({ ...record, state: false });
    };

    const handleClickAway = (): void => {
        setSave(false);
        setRecord({ ...record, state: false });
    };

    const handleStop = (audio: any) => {
        setAudio(audio.blob);
        handleStopRecording();
        setRecord({ state: false, open: false });
    };

    const startPlayingAudio = (): void => {
        handleResetPlaylist();
        handlePlayAudio(script.id);
    };

    const myRef = useRef<any>(null);

    const onHandleGoNext = () => {
        if (player.status === 'playlist' && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            handleGoNext();
        }
    };


            
    const handleRemoveItem = useCallback(() => {
        showConfirmationModalDialog({
            modalTitle: 'Are You Sure?',
            description: 'Are you sure you want to delete the line?',
            onConfirm: () => handleScriptLineDelete(script.id),
        });
    }, [handleScriptLineDelete, script.id, showConfirmationModalDialog]);
    

    const [localValue, setLocalValue] = useState(script.text);

    useEffect(() => {
        if (localValue !== script.text) {
            setLocalValue(script.text);
        }
    }, [script.text, localValue]);

    return (
        <StyledScriptItem ref={myRef}>
            <Comment
                reverse={script.speaker === ScriptLineSpeaker.REP}
                disabled={disabled || generatingAudio}
            >
                <CommentBody>
                    <CommentDescription
                        hideRemoveIcon={!editable}
                        onDelete={handleRemoveItem}
                        dataTest="delete-challenge-customer-line"
                    >
                        {(!editable || disabled) && <DisabledArea />}
                        <CommentTitle dataTest="challenge-customer-line-title">
                            Customer
                        </CommentTitle>
                        <StyledContainer>
                            <ContentEditableInput
                                value={localValue}
                                handleChange={value => {
                                    handleScriptLineModify(
                                        value,
                                        'text',
                                        script.id
                                    );
                                }}
                                placeholder={'Write customer’s line here'}
                                autoFocus={!localValue}
                                recording={
                                    !!player.recording &&
                                    player.recording === script.id
                                }
                                viewMode={!editable || disabled}
                                dataTest="challenge-customer-line"
                            />
                        </StyledContainer>
                    </CommentDescription>
                    <CommentTools>
                        {!record.open && player.current !== script.id && (
                            <StyledScriptAction>
                                <StyledAudioSection>
                                    {editable && (
                                        <StyledButton
                                            label="Record Audio"
                                            action={startRecording}
                                            iconRender={<Record />}
                                            color="warning"
                                            variant="transparent"
                                            buttonSize={12}
                                            width={'90px'}
                                            dataTest="record-challenge-customer-line-audio"
                                        />
                                    )}
                                    {editable && localValue.length > 0 && (
                                        <StyledButton
                                            label="Generate Audio"
                                            iconRender={<Generate />}
                                            action={generateAudio}
                                            variant="transparent"
                                            loading={loadingAudio}
                                            buttonSize={14}
                                            dataTest="generate-challenge-customer-line-audio"
                                        />
                                    )}
                                    {script.audioUrl && (
                                        <StyledButton
                                            label="Play"
                                            iconRender={<Play />}
                                            action={startPlayingAudio}
                                            variant="transparent"
                                            buttonSize={14}
                                            width={'40px'}
                                            dataTest="play-challenge-customer-line-audio"
                                        />
                                    )}
                                </StyledAudioSection>
                            </StyledScriptAction>
                        )}
                        {record.open && (
                            <AudioRecorder
                                record={record.state}
                                onClickAway={handleClickAway}
                                onStop={handleStop}
                                onSave={saveRecording}
                            />
                        )}
                        {player.current === script.id && (
                            <AudioPlayer
                                audio={script.audioUrl}
                                duration={
                                    script.duration ? script.duration : 10
                                }
                                onStop={() => handleStopAudio(script.id)}
                                autoPlay={true}
                                goNext={onHandleGoNext}
                            />
                        )}
                    </CommentTools>
                </CommentBody>
            </Comment>
        </StyledScriptItem>
    );
};

export const CustomerScriptItemContainer: FC<{
    script: ScriptLine;
    disabled: boolean;
    editable: boolean;
}> = ({ script, disabled, editable }) => {
    const dispatch = useDispatch();

    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);
    const player = useSelector(getAudioState);

    const resetPlaylist = useResetAudioService();
    const goNext = useGoNextService();
    const playAudio = usePlayAudioService();
    const stopAudio = useStopAudioService();
    const startRecording = useStartAudioRecordingService();
    const stopRecording = useStopAudioRecordingService();

    const deleteScriptLine = useDeleteChallengeScriptLineService();
    const modifyScriptLine = useModifyChallengeScriptLineService();

    const uploadChallengeAudio = useUploadChallengeAudioService();
    const voiceSettings = useCompanyVoiceSettingsState();

    return (
        <CustomerScriptItem
            script={script}
            handleScriptLineDelete={deleteScriptLine}
            handleScriptLineModify={modifyScriptLine}
            handleUploadAudio={uploadChallengeAudio}
            profile={profile}
            company={company}
            player={player}
            handlePlayAudio={playAudio}
            handleStopAudio={stopAudio}
            handleStartRecording={startRecording}
            handleStopRecording={stopRecording}
            handleGoNext={goNext}
            handleResetPlaylist={resetPlaylist}
            voiceSettings={voiceSettings.data}
            disabled={disabled}
            editable={editable}
            dispatch={dispatch}
        />
    );
};

export default CustomerScriptItemContainer;