import React, { FC, useCallback, useState, ChangeEvent, } from 'react';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import styled from 'styled-components';
import { Button } from '../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../../../../portableLabels';
import { useSaveLabels, useSelectLabels } from '../../../../../portableLabels/store/hors/withLabels/services';
import { TeamMemberStatusEnum } from '../../../../../../constants/interfaces/TeamMember';
import Checkbox from '../../../../../../ui/components/Checkbox';
import { Variables } from '../../../../../../theme/variables';
import { kebabCase } from 'lodash';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;

    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 16px;
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
    background-color: inherit;
`;

const StatusItem = styled.div`
    margin-bottom: 8px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

export const AllUsersFilter: FC<{
    onLabelsChange: (ids: number[]) => void;
    onSuccessApply: () => void;
    registrationStatus?: any;
    currentLabelIds?: number[];
    onRegistrationStatusChange?: (status: any) => void;
}> = ({
    onLabelsChange,
    onSuccessApply,
    onRegistrationStatusChange,
    currentLabelIds,
    registrationStatus,
}) => {
    const [labelIds, setLabelIds] = useState<number[]>(currentLabelIds ?? []);
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
    const labels = useLabelsState();

    const [registrationStatuses, setRegistrationStatuses] = useState<any>(
        registrationStatus || []
    );

    const onLabelChangeHandler = useCallback(
        (ids: number[]) => setLabelIds(ids),
        [setLabelIds]
    );

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labelIds);
        onRegistrationStatusChange?.(registrationStatuses);
        saveLabels();
        onSuccessApply();
    }, [
        onLabelsChange,
        labelIds,
        onRegistrationStatusChange,
        registrationStatuses,
        saveLabels,
        onSuccessApply,
    ]);

    const onClearHandler = useCallback(() => {
        selectLabels([]);
        setLabelIds([]);
        setRegistrationStatuses([]);
    }, [selectLabels, setRegistrationStatuses]);

    const regStatusesMap: Record<any, { title: string }> = {
        [TeamMemberStatusEnum.PENDING]: { title: 'Pending' },
        [TeamMemberStatusEnum.ACTIVE]: { title: 'Active' },
    };

    const onRegistrationStatusChangeHandler = useCallback(
        (statusIn: TeamMemberStatusEnum, e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setRegistrationStatuses([...registrationStatuses, statusIn]);
            } else {
                setRegistrationStatuses(
                    registrationStatuses.filter(
                        (item: any) => item !== statusIn
                    )
                );
            }
        },
        [setRegistrationStatuses, registrationStatuses]
    );

    const totalFiltersSelected =
        labels.selected.filter(id => id > 0).length +
        registrationStatuses?.length;

    const clearDisabled =
        !labels.selected.length && !registrationStatuses.length;

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Registration Status'} dataTest="registration-status-section">
                    <StatusContainer>
                        {(
                            Object.keys(
                                regStatusesMap
                            ) as TeamMemberStatusEnum[]
                        ).map(item => (
                            <StatusItem data-test="registration-status-item-container">
                                <Checkbox
                                    key={item}
                                    checked={
                                        registrationStatuses &&
                                        registrationStatuses.includes(item)
                                    }
                                    handleChange={e =>
                                        onRegistrationStatusChangeHandler(
                                            item,
                                            e
                                        )
                                    }
                                    label={regStatusesMap[item].title}
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                    dataTest={`${kebabCase(regStatusesMap[item].title)}-checkbox`}
                                />
                            </StatusItem>
                        ))}
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            onSetSelected={onLabelChangeHandler}
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={clearDisabled}
                        variant="inverse"
                        width={'122px'}
                        action={onClearHandler}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={onApplyHandler}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};

