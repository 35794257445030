import { useMemo } from "react";
import { PractisSets, PractisSetStatuses } from "../../../constants/interfaces/PractisSets";
import { UserProfile } from "../../../constants/interfaces/User";
import { useLoggedInUserProfileState } from "../../../features/users/store/states";
import { hasPermission, NEW_PERMISSIONS } from "../../../features/users/tools";
import { getBulkActionAvailabilityItem, getBulkActionsAvailability } from "../../../tools/bulkActions";

export enum PractisSetActions {
    EDIT,
    ASSIGN_LABELS,
    DUPLICATE,
    ARCHIVE,
    RESTORE,
    DELETE,
}

function createPractisSetActionsHelper(
    loggedInUser: UserProfile | undefined
) {
    const canEdit = (practisSet: PractisSets) =>
        practisSet.status !== PractisSetStatuses.DELETED &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.UPDATE_PRACTIS_SET);

    const canAssignLabels = (practisSet: PractisSets) =>
        practisSet.status !== PractisSetStatuses.DELETED &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.ASSIGN_PRACTIS_SET_LABEL);

    const canAssignUsers = (practisSet: PractisSets) => 
        practisSet.status === PractisSetStatuses.ACTIVE &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.ASSIGN_ENROLLMENT);

    const canDuplicate = (practisSet: PractisSets) =>
        (practisSet.status === PractisSetStatuses.DRAFT ||
            practisSet.status === PractisSetStatuses.ACTIVE) &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.COPY_PRACTIS_SET);

    const canArchive = (practisSet: PractisSets) =>
        (practisSet.status === PractisSetStatuses.DRAFT ||
            practisSet.status === PractisSetStatuses.ACTIVE) &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.ARCHIVE_PRACTIS_SET);

    const canRestore = (practisSet: PractisSets) =>
        practisSet.status === PractisSetStatuses.ARCHIVED &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.RESTORE_PRACTIS_SET);

    const canDelete = (practisSet: PractisSets) =>
        practisSet.status === PractisSetStatuses.ARCHIVED &&
        hasPermission(loggedInUser, NEW_PERMISSIONS.DELETE_PRACTIS_SET);

    const getBulkActions = (practisSets: PractisSets[] | undefined) =>
        getBulkActionsAvailability(practisSets, [
            getBulkActionAvailabilityItem(
                PractisSetActions.ASSIGN_LABELS,
                practisSets,
                canAssignLabels
            ),
            getBulkActionAvailabilityItem(
                PractisSetActions.DUPLICATE,
                practisSets,
                canDuplicate
            ),
            getBulkActionAvailabilityItem(
                PractisSetActions.ARCHIVE,
                practisSets,
                canArchive
            ),
            getBulkActionAvailabilityItem(
                PractisSetActions.RESTORE,
                practisSets,
                canRestore
            ),
            getBulkActionAvailabilityItem(
                PractisSetActions.DELETE,
                practisSets,
                canDelete
            ),
        ]);

    return {
        canEdit,
        canAssignUsers,
        canAssignLabels,
        canDuplicate,
        canArchive,
        canRestore,
        canDelete,
        getBulkActions,
    };
}

export type PractisSetActionsHelper = ReturnType<typeof createPractisSetActionsHelper>;

export function usePractisSetActionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createPractisSetActionsHelper(loggedInUser),
        [loggedInUser]
    );
}