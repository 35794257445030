import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { useTeamEditState } from '../../../../store/states';
import {
    useSearchTeamsAllUsersService,
    useDeleteSingleTeamAllUsersLabelService,
} from '../../../../store/services';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../../constants/interfaces/filters';
import { useParams } from 'react-router';
import {
    UserProfile,
    UserV2,
} from '../../../../../../constants/interfaces/User';
import { ListResult } from '../../../../../../constants/interfaces/PaginationResult';
import { formatDate } from '../../../../../../helpers/functions/date-convert';
import Checkbox from '../../../../../../ui/components/Checkbox';
import AddArrow from '../../../../../../ui/icons/AddArrow';
import { ScrollPagination } from '../../../../../../ui/components/ScrollPagination';
import AvatarPlaceholder from '../../../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../../../pages/UserProfile/store/reducers';
import { pluralize } from '../../../../../../helpers/functions/pluralize';
import { WithLabelsContext } from '../../../../../portableLabels';
import FilterByHandler from '../../../../../../ui/components/Filters/FilterByHandler';
import { Popup } from '../../../../../../ui/components/Popup';
import { AllUsersFilter } from '../../components/Filters/AllUsersFilters';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import { useHandleSelectLabels } from '../../../../../labels/tools';
import { Label } from '../../../../../../constants/interfaces/Label';
import { listToTree } from '../../../../../../helpers/functions/list-to-tree';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import User from '../../../../../../ui/icons/User';
import {
    EmptyState,
    NoFilterResults,
    NoSearchResults,
} from '../../../../../../ui/components/EmptyStates';
import { EmptyStateContainer } from '../../../../../../ui/components/EmptyStates/components/EmptyStateContainer';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { useUpdateUsersState } from '../../../../../users/store/states';
import { useSearchDebounced } from '../../../../../../helpers/hooks/useSearch';
import { usePortableLabelsState } from '../../../../../portableLabels/store/states';
import AssignedLabelList from '../../../../../../ui/components/table-wrapper/table/TableLabelTags/AssignedLabelList';
import { TableHeaderCell } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell';
import { TableDivider } from '../../../../../../ui/components/table-wrapper/table-divider';
import { TableRefresh } from '../../../../../../ui/components/table-wrapper/table-refresh';
import { TableHeaderCellProps } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/type';
import { TableStateValues } from '../../../../../../ui/components/table-wrapper';
import { SelectAll } from '../../../../../../ui/components/table-wrapper/SelectAll';
import { TableSearchInput } from '../../../../../../ui/components/table-wrapper/table-tools/table-search-input';
import {
    TableHead,
    TableToolsRight,
} from '../../../../../../ui/components/Table';
import { TableToolItem } from '../../../../../../ui/components/table-wrapper/table-tools/table-tool-item';
import { TableHeaderFilter } from '../../../../../../ui/components/table-wrapper/table-header-filter';
import { TableRow } from '../../../../../../ui/components/table-wrapper/table/table-row';
import { TableLabelTags } from '../../../../../../ui/components/table-wrapper/table/TableLabelTags';
import TableTitleOverflowText from '../../../../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { EmptyCellDash } from '../../../../../../ui/components/table-wrapper/table/EmptyCellDash';
import { useOrderBy } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../../../../ui/components/table-wrapper/helper';
import {
    useAssignMembersToTeamService,
    useUpdateTeamMembersBulkActionService,
} from '../../../../services/TeamBulkActionsService';
import { AllUsersContainerProps, AllUsersExternalInterface } from './types';
import { UPDATE_TEAM_ACTION } from '../../../../services/TeamBulkActionsService/constants';
import { useUserRolesState } from '../../../../../roles/store/states';
import { ROLE_NAMES } from '../../../../../../constants/enums';

const Container = styled.div`
    padding: 15px 24px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 ${props => props.theme.Colors.blackTwo5};
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Header = styled.div`
    margin-bottom: 2px;
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    height: 24px;
    margin-bottom: 6px;
`;

const Counter = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
    height: 16px;
    text-align: right;
`;

const TableTools = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 8px 0;
    padding-right: 16px;
    position: relative;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    padding-right: 16px;
`;

const TableBody = styled.div<{ showHeight?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: ${props => (props.showHeight ? '1' : '0')};
    min-height: ${props => (props.showHeight ? '100px' : '0')};
    overflow: auto;
`;

const StyledTablePagination = styled(ScrollPagination)`
    width: 100%;
`;

const ListCell = styled.div<{ flex?: number }>`
    ${props => props.flex && `flex: ${props.flex};`}
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 13px;
`;

const ListRow = styled.div<{
    selected?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    border-radius: 4px;
    background: ${props =>
        props.selected ? props.theme.Colors.whiteFive : 'unset'};
    ${ListCell} {
        color: ${props =>
            props.disabled
                ? props.theme.Colors.cloudyBlue
                : props.theme.Colors.black};
    }
    margin-right: 12px;
    &:hover {
        background: var(--ps-grey-3);
    }
`;

const TableCheckboxCell = styled(TableHeaderCell)`
    padding: 4px 12px 4px 4px;
`;

const ListCheckboxCell = styled.div`
    height: 48px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
`;

const ManageUsersSection = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
`;

const ManageUsersButton = styled.div<{ disabled?: boolean }>`
    font-size: 13px;
    font-weight: 600;
    color: ${props =>
        props.disabled
            ? props => props.theme.Colors.cloudyBlue
            : props => props.theme.Colors.skyBlueTwo};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    display: flex;
    user-select: none;
    &:active {
        opacity: ${props => (props.disabled ? '1' : '0.7')};
    }
`;

const AddArrowIcon = styled.div`
    color: inherit;
    height: 9px;
    width: 9px;
    margin-left: 8px;
`;

const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
`;

const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

const StyledAssignedLabelList = styled(AssignedLabelList)`
    padding-left: 28px;
`;

const SearchContainer = styled.div`
    flex: 1;
`;

const LabelsContainer = styled.div`
    height: 40px;
    width: 40px;
    margin-left: 8px;
`;

const LastTrainingAtCell = styled(ListCell)`
    text-align: left;
    width: 111px;
`;

const FilteredByLabelsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemCount = styled.p`
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
    padding: 0;
    margin: 0;
`;

const AllUsers: FC<{
    users?: ListResult<UserV2>;
    profile?: UserProfile;
    searchTerm?: string;
    searchParams: SearchParams;
    setSearchTerm: (searchTerm: string) => void;
    orderByUserName: Partial<TableHeaderCellProps>;
    orderByLastTrainingAt: Partial<TableHeaderCellProps>;
    selectedUsers: number[];
    isSelectAll: boolean;
    onSelectUser: (id: number) => void;
    onSelectAllLoadedUsers: (selected: boolean) => void;
    onSelectAll: (selected: boolean) => void;
    setOffset: (offset: number) => void;
    labelIDs: number[];
    setLabelIDs: (labelIds: number[]) => void;
    totalCount: number;
    labelsOpen?: number | null;
    checkboxDisabled: boolean;
    handleToggleLabels: (open: number) => void;
    tableStates: TableStateValues;
    registrationStatusHandler?: (status: string[]) => void;
    deleteUserLabel: (userId: number, labelId: number) => void;
    clearAllUsers: () => void;
    onSuccessAddUsersBulkActionCallback: () => void;
    lastRefreshed: Date;
    refreshData: () => void;
    roleIDs?: number[];
    dataTest: string;
}> = ({
    users,
    profile,
    setSearchTerm,
    searchParams,
    orderByUserName,
    orderByLastTrainingAt,
    selectedUsers,
    isSelectAll,
    onSelectUser,
    onSelectAllLoadedUsers,
    onSelectAll,
    setOffset,
    labelIDs,
    setLabelIDs,
    totalCount,
    labelsOpen,
    checkboxDisabled,
    handleToggleLabels,
    tableStates,
    deleteUserLabel,
    registrationStatusHandler,
    clearAllUsers,
    onSuccessAddUsersBulkActionCallback,
    lastRefreshed,
    refreshData,
    roleIDs,
    dataTest,
}) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const labelsTree = listToTree(labelsList.data.items);
    const handleSelectLabels = useHandleSelectLabels();
    const ifLabelsChanged = useIfChanged(labelIDs);

    const onRemoveLabel = (label: Label) => {
        setLabelIDs(handleSelectLabels(label, labelIDs, labelsTree));
    };
    const [registrationFilters, setRegistrationFilters] = useState<string[]>(
        []
    );

    const assignMembersToTeamService = useAssignMembersToTeamService();

    const assignMembersToTeamServiceOptions = {
        parameters: {
            teamId: +(searchParams?.teamId ?? 0),
            searchParams: {
                ...searchParams,
                roleIDs,
                limit: ITEMS_PER_PAGE,
                offset: (isSelectAll ? totalCount : selectedUsers?.length) ?? 0,
            },
            selectedItemIds: !isSelectAll ? selectedUsers : undefined,
            itemsCount: selectedUsers?.length ?? 0,
        },
        fieldName: !isSelectAll ? 'selectedItemIds' : 'searchParams.offset',
    };

    /**
     * @function addMembersToTeamSuccessCallback
     * @returns { void }
     */
    const addMembersToTeamSuccessCallback = useCallback(() => {
        Promise.resolve().then(() => {
            clearAllUsers();
            refreshData();
            onSuccessAddUsersBulkActionCallback();
        });
    }, [clearAllUsers, onSuccessAddUsersBulkActionCallback, refreshData]);

    const {
        handleStartBulkActionService: handleStartAddMembersToTeamBulkAction,
    } = useUpdateTeamMembersBulkActionService(
        {
            actionName: UPDATE_TEAM_ACTION,
            actionFunction: assignMembersToTeamService,
            actionFunctionOptions: assignMembersToTeamServiceOptions,
        },
        'Adding Users',
        addMembersToTeamSuccessCallback
    );

    /**
     * @function handleAddMembersToTeam
     * @returns { void }
     */
    const handleAddMembersToTeam = useCallback(() => {
        handleStartAddMembersToTeamBulkAction();
    }, [handleStartAddMembersToTeamBulkAction]);

    useEffect(() => {
        return () => clearAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ifLabelsChanged) {
            setSelectLabels(labelIDs);
            saveLabels();
        }
    }, [labelIDs, ifLabelsChanged, setSelectLabels, saveLabels]);

    //TODO: Refactor/remove from every page
    const handleAssignedLabelDelete = (labelId: number, userId: number) => {
        deleteUserLabel(userId, labelId);
    };

    const scrollableRef = useRef<HTMLDivElement>(null);

    /**
     * @function isUserSelected
     * @param { UserV2 } user
     * @returns { boolean }
     */
    const isUserSelected = (user: UserV2): boolean =>
        selectedUsers.includes(user.id);

    return (
        <Container>
            <Header>
                <Title data-test={`${dataTest}-title`}>All Users</Title>
                <Counter />
            </Header>
            <Header>
                <TableRefresh
                    lastRefreshed={lastRefreshed}
                    refreshData={refreshData}
                    dataTest={`${dataTest}-timestamp`}
                    top={-20}
                    fontSize={11}
                />
            </Header>
            <TableDivider />
            <TableTools>
                {users && (
                    <SelectAll
                        style={{
                            width: '100%',
                            height: 60,
                            marginTop: -10,
                        }}
                        isSelectAll={isSelectAll}
                        selectedLength={selectedUsers.length}
                        totalCount={totalCount}
                        itemsPerPage={ITEMS_PER_PAGE}
                        onClearSelecion={() => {
                            clearAllUsers();
                        }}
                        onSelectAll={() => {
                            onSelectAllLoadedUsers(true);
                            onSelectAll(true);
                        }}
                        dataTest={dataTest}
                    >
                        <span></span>
                    </SelectAll>
                )}
                <SearchContainer>
                    <TableSearchInput
                        onChange={setSearchTerm}
                        disabled={tableStates.disableSearch}
                    />
                </SearchContainer>
                <LabelsContainer>
                    <Popup<HTMLButtonElement>
                        content={({ hide }) => (
                            <AllUsersFilter
                                onLabelsChange={setLabelIDs}
                                onSuccessApply={() => hide()}
                                currentLabelIds={labelIDs}
                                registrationStatus={registrationFilters}
                                onRegistrationStatusChange={(
                                    values: string[]
                                ) => {
                                    setRegistrationFilters(values);
                                    registrationStatusHandler?.(values);
                                }}
                            />
                        )}
                    >
                        {(ref, { toggleShown, shown }) => (
                            <FilterByHandler
                                ref={ref}
                                open={shown}
                                disabled={
                                    tableStates.disableFilters ||
                                    !labelsList.data.items.length
                                }
                                toggleOpen={toggleShown}
                                filtersCount={
                                    labelIDs.length + registrationFilters.length
                                }
                                dataTest={`${dataTest}-filters`}
                            />
                        )}
                    </Popup>
                </LabelsContainer>
                <TableToolsRight>
                    <TableToolItem>
                        <ItemCount
                            data-test={`${dataTest}-counter`}
                        >{`${totalCount} ${
                            totalCount === 1 ? 'Item' : 'Items'
                        }`}</ItemCount>
                    </TableToolItem>
                </TableToolsRight>
            </TableTools>
            <TableDivider />
            <TableContainer>
                {labelIDs && (
                    <FilteredByLabelsContainer>
                        <TableHeaderFilter
                            selectedLabels={labelIDs}
                            onRemoveLabel={
                                labels.saved.length > 0
                                    ? onRemoveLabel
                                    : undefined
                            }
                        />
                    </FilteredByLabelsContainer>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCheckboxCell
                                dataTest={`${dataTest}-column-checkbox`}
                            >
                                <Checkbox
                                    checked={!!selectedUsers.length}
                                    size={12}
                                    partial={!isSelectAll}
                                    handleChange={e => {
                                        onSelectAllLoadedUsers(
                                            e.target.checked
                                        );
                                        onSelectAll(false);
                                    }}
                                    disabled={checkboxDisabled}
                                />
                            </TableCheckboxCell>
                            <TableHeaderCell
                                {...orderByUserName}
                                width={88}
                                disabled={tableStates.disableSorting}
                                dataTest={`${dataTest}-column-users`}
                            >
                                Users
                            </TableHeaderCell>
                            <TableHeaderCell
                                {...orderByLastTrainingAt}
                                disabled={tableStates.disableSorting}
                                dataTest={`${dataTest}-column-last-training`}
                            >
                                Last Training
                            </TableHeaderCell>
                        </TableRow>
                    </TableHead>
                </Table>

                <TableBody
                    ref={scrollableRef}
                    showHeight={!tableStates.showEmptyState}
                >
                    {!tableStates.showEmptyState && (
                        <StyledTablePagination
                            itemsPerPage={ITEMS_PER_PAGE}
                            itemCount={users ? users.items.length : 0}
                            totalCount={totalCount}
                            onOffsetChange={setOffset}
                            scrollableRef={scrollableRef}
                        >
                            {users?.items.map(user => {
                                return (
                                    <React.Fragment key={user.id}>
                                        <ListRow
                                            selected={isUserSelected(user)}
                                            data-test={`${dataTest}-item`}
                                        >
                                            <ListCheckboxCell>
                                                <Checkbox
                                                    checked={isUserSelected(
                                                        user
                                                    )}
                                                    size={12}
                                                    handleChange={() => {
                                                        onSelectUser(user.id);
                                                    }}
                                                    dataTest={`${dataTest}-item-checkbox`}
                                                />
                                            </ListCheckboxCell>
                                            <ListCell flex={38}>
                                                <StyledFirstTableContent>
                                                    <TableLabelTags
                                                        open={
                                                            labelsOpen ===
                                                            user.id
                                                        }
                                                        toggleOpen={() =>
                                                            handleToggleLabels(
                                                                user.id
                                                            )
                                                        }
                                                        selectedLabels={
                                                            user.labels
                                                        }
                                                        dataTest={`${dataTest}-item-labels`}
                                                    />
                                                    <AvatarTile>
                                                        <Avatar
                                                            profile={user}
                                                            size="sm"
                                                            dataTest={`${dataTest}-item-avatar`}
                                                        />
                                                        <TableTitleOverflowText
                                                            isCurrent={
                                                                profile &&
                                                                profile.id ===
                                                                    user.id
                                                            }
                                                            dataTest={`${dataTest}-item-name`}
                                                            minWidth="auto"
                                                        >
                                                            {`${user.firstName} ${user.lastName}`}
                                                        </TableTitleOverflowText>
                                                    </AvatarTile>
                                                </StyledFirstTableContent>
                                            </ListCell>
                                            <LastTrainingAtCell
                                                data-test={`${dataTest}-item-last-training`}
                                            >
                                                {user.lastActivity ? (
                                                    formatDate(
                                                        user.lastActivity
                                                    )
                                                ) : (
                                                    <EmptyCellDash />
                                                )}
                                            </LastTrainingAtCell>
                                        </ListRow>
                                        {labelsOpen === user.id && (
                                            <StyledAssignedLabelList
                                                selectedLabels={user.labels}
                                                deletePermissions={[
                                                    NEW_PERMISSIONS.REMOVE_USER_LABEL,
                                                ]}
                                                onDeleteLabel={(labelId: any) =>
                                                    handleAssignedLabelDelete(
                                                        labelId,
                                                        user.id
                                                    )
                                                }
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </StyledTablePagination>
                    )}
                </TableBody>
                {tableStates.showEmptyState && (
                    <EmptyStateContainer>
                        {tableStates.showNoEntriesState && (
                            <EmptyState
                                icon={User}
                                dataTest={`${dataTest}-no-entries`}
                            >
                                No Users Yet
                            </EmptyState>
                        )}
                        {tableStates.showNoSearchResultsState && (
                            <NoSearchResults
                                entityName={'Users'}
                                dataTest={`${dataTest}-no-search-results`}
                            />
                        )}
                        {tableStates.showNoFilterResultsState && (
                            <NoFilterResults
                                dataTest={`${dataTest}-no-filter-results`}
                            />
                        )}
                    </EmptyStateContainer>
                )}
            </TableContainer>
            <TableDivider />
            <ManageUsersSection>
                <ManageUsersButton
                    disabled={selectedUsers.length < 1}
                    onClick={handleAddMembersToTeam}
                    data-test="all-users-add-selected"
                >
                    {selectedUsers.length > 0
                        ? `Add ${pluralize(
                              isSelectAll ? totalCount : selectedUsers.length,
                              'Selected User'
                          )}`
                        : 'Add Selected Users'}
                    <AddArrowIcon>
                        <AddArrow />
                    </AddArrowIcon>
                </ManageUsersButton>
            </ManageUsersSection>
        </Container>
    );
};

const ITEMS_PER_PAGE = 20;
const NEW_TEAM_ID = 'new';

const AllUsersContainer = forwardRef(
    ({ onSuccessAddUsersBulkActionCallback }: AllUsersContainerProps, ref) => {
        const params: { teamId: string } = useParams();
        const teamEditState = useTeamEditState();
        const profile = useSelector(getProfileState);
        const searchTeamAllUsers = useSearchTeamsAllUsersService();
        const searchTeamAllUsersDebounced =
            useSearchDebounced(searchTeamAllUsers);
        const teamId = params.teamId;
        const handleTableStates = useTableStateHelper();
        const deleteUserLabel = useDeleteSingleTeamAllUsersLabelService(teamId);
        const updatedUsersState = useUpdateUsersState();
        const roles = useUserRolesState();

        const roleIDs = useMemo(() => {
            if (!roles.list?.length) return undefined;
            return [
                ROLE_NAMES.USER,
                ROLE_NAMES.TEAM_LEADER,
                ROLE_NAMES.COMPANY_ADMIN
            ].map(roleName => roles.list.find(role => role.name === roleName)!.id);
        }, [roles.list]);

        const initialSearchParams: SearchParams = {
            searchTerm: '',
            teamId: teamId !== NEW_TEAM_ID ? teamId : undefined,
            filters: [],
            orderBy: { field: 'name', asc: true },
            limit: ITEMS_PER_PAGE,
            offset: 0,
            totalCount: 0,
            numberOfPages: 0,
        };

        const {
            searchParams,
            setSearchTerm,
            setOrderBy,
            setLabelIDs,
            setOffset,
            setCustom,
        } = useSearchParamsState(initialSearchParams);

        useEffect(() => {
            if (!roleIDs) return;
            searchTeamAllUsersDebounced({
                ...searchParams,
                roleIDs
            });
        }, [
            searchParams,
            searchTeamAllUsersDebounced,
            teamId,
            updatedUsersState.data,
            roleIDs
        ]);

        const orderBy = searchParams.orderBy;
        const orderByUserName = useOrderBy('name', orderBy, setOrderBy);
        const orderByLastTrainingAt = useOrderBy(
            'date_login',
            orderBy,
            setOrderBy
        );

        const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
        const [isSelectAll, setIsSelectAll] = useState(false);
        const userItems = useMemo(
            () => (teamEditState.allUsers ? teamEditState.allUsers.items : []),
            [teamEditState.allUsers]
        );
        const totalCount = teamEditState.allUsers?.count ?? 0;
        const checkboxDisabled = userItems.length < 1;
        const isPartiallySelected = selectedUsers.length < userItems.length;

        /**
         * @function handleSelectUser
         * @param { number } id
         * @returns { void }
         */
        const handleSelectUser = (id: number) => {
            const newSelectedUsers = selectedUsers.includes(id)
                ? selectedUsers.filter(userId => userId !== id)
                : selectedUsers.concat(id);
            setSelectedUsers(newSelectedUsers);
            setIsSelectAll(false);
        };
        /**
         * @function handleSelectAllLoadedUsers
         * @param { boolean } selected
         * @returns { void }
         */
        const handleSelectAllLoadedUsers = (selected: boolean) => {
            setSelectedUsers(
                selected || isPartiallySelected
                    ? userItems.map(item => item.id)
                    : []
            );
        };
        /**
         * @function clearAllUsers
         * @returns { void }
         */
        const clearAllUsers = () => {
            setSelectedUsers([]);
            setIsSelectAll(false);
        };

        useEffect(() => {
            if (
                !isSelectAll &&
                selectedUsers.length === totalCount &&
                totalCount > 0
            ) {
                setIsSelectAll(true);
            }
        }, [isSelectAll, totalCount, selectedUsers]);

        useEffect(() => {
            if (
                isSelectAll &&
                !isEmpty(selectedUsers) &&
                selectedUsers.length < userItems.length
            ) {
                setSelectedUsers(userItems.map(item => item.id));
            }
        }, [isSelectAll, selectedUsers, userItems]);

        const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
            null
        );

        const registrationStatusHandler = (values: string[]) => {
            setCustom('filters', [
                {
                    field: 'status',
                    value: values,
                },
            ]);
        };

        const handleToggleLabels = (itemId?: number) => {
            if (!itemId) return;
            if (labelsOpen === itemId) {
                setLabelsOpen(null);
            } else {
                setLabelsOpen(itemId);
            }
        };

        const [lastRefreshed, setLastRefreshed] = useState(new Date());
        /**
         * @description reloads data with the same search params and updates last refreshed date to the current date/time
         * @function refreshData
         * @returns { void }
         */
        const refreshData = useCallback(() => {
            searchTeamAllUsersDebounced(
                {
                    ...searchParams,
                    limit: ITEMS_PER_PAGE + searchParams.offset,
                    offset: 0,
                    roleIDs
                },
                true
            );
            setLastRefreshed(new Date());
        }, [searchTeamAllUsersDebounced, searchParams, roleIDs]);
        useImperativeHandle<any, AllUsersExternalInterface>(ref, () => ({
            refreshData,
        }));

        const registrationsStatusLength: any = searchParams.filters?.length;

        const tableStates = handleTableStates({
            searchTerm: searchParams.searchTerm,
            appliedFilters:
                searchParams.labelIDs?.length + registrationsStatusLength,
            itemsCount: totalCount,
        });

        return (
            <WithLabelsContext.Provider
                value={{
                    reducerName: 'teamEdit',
                    scope: 'allUsers',
                }}
            >
                <AllUsers
                    users={teamEditState.allUsers}
                    profile={profile}
                    searchTerm={searchParams.searchTerm}
                    setSearchTerm={setSearchTerm}
                    orderByUserName={orderByUserName}
                    orderByLastTrainingAt={orderByLastTrainingAt}
                    selectedUsers={selectedUsers}
                    isSelectAll={isSelectAll}
                    onSelectUser={handleSelectUser}
                    onSelectAllLoadedUsers={handleSelectAllLoadedUsers}
                    onSelectAll={setIsSelectAll}
                    setOffset={setOffset}
                    labelIDs={searchParams.labelIDs || []}
                    setLabelIDs={setLabelIDs}
                    totalCount={totalCount}
                    labelsOpen={labelsOpen}
                    checkboxDisabled={checkboxDisabled}
                    handleToggleLabels={handleToggleLabels}
                    tableStates={tableStates}
                    deleteUserLabel={deleteUserLabel}
                    searchParams={searchParams}
                    registrationStatusHandler={registrationStatusHandler}
                    clearAllUsers={clearAllUsers}
                    onSuccessAddUsersBulkActionCallback={
                        onSuccessAddUsersBulkActionCallback
                    }
                    lastRefreshed={lastRefreshed}
                    refreshData={refreshData}
                    roleIDs={roleIDs}
                    dataTest="team-all-users"
                />
            </WithLabelsContext.Provider>
        );
    }
);


export default AllUsersContainer;