import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Accuracy = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 49 48"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4.7a1.8 1.8 0 1 0 0 3.6h4.999v15.011c-2.97 1.973-4.64 5.305-4.63 9.002v9.137a1.8 1.8 0 0 0 1.544 1.782l.427.062h.001l.008.001c1.405.204 2.787.405 4.38.405 1.507 0 2.832-.188 4.147-.374l.008-.001.65-.092a1.8 1.8 0 0 0 1.555-1.783v-8.92c0-.394-.019-.785-.057-1.172a35.32 35.32 0 0 0 23.311-1.934 1.8 1.8 0 0 0 1.066-1.644V8.3h5.87a1.8 1.8 0 0 0 0-3.6H3.5zm11.822 22.545c-.762-1.457-1.85-2.71-3.223-3.63V16.26h25.21v10.33a31.718 31.718 0 0 1-21.987.655zm1.174 3.794zM8.8 23.119h-.001V8 23.12zm-.001.355zm-.3.2c-2.776 1.926-4.34 5.104-4.33 8.638v9.138a1.5 1.5 0 0 0 1.287 1.485l.427.062c.706.102 1.4.203 2.114.279-.714-.076-1.407-.177-2.112-.279l-.428-.062A1.5 1.5 0 0 1 4.17 41.45v-9.138c-.01-3.533 1.553-6.711 4.329-8.637v-.001zm15.361 5.485a32.019 32.019 0 0 0 13.749-2.367V15.96v10.832a32.021 32.021 0 0 1-13.749 2.367zM46.871 5.003A1.524 1.524 0 0 0 46.78 5H3.5h43.28l.091.003zM37.31 12.66h-25.21V8.3h25.21v4.36zm-25.51.3h25.81V8v4.96h-25.81zm-1.726 13.277c1.956 1.214 3.216 3.535 3.216 6.293v7.615c-1.061.146-1.998.255-3.06.255a16.177 16.177 0 0 1 .001 0c1.062 0 1.998-.108 3.06-.255V32.53c0-2.758-1.26-5.08-3.216-6.294zM7.47 32.305c-.007-2.392.97-4.417 2.617-5.703 1.76 1.18 2.903 3.343 2.903 5.928v7.353c-.952.128-1.805.217-2.76.217-.954 0-1.812-.086-2.76-.212V32.305z" fill="currentColor"/>
            </svg>
        );
    }
);

export default Accuracy;
