import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PlayTriangle = forwardRef<SVGSVGElement, IconProps>(
    ({ className, width = 24, height = 24 }, ref) => {
        return (
            <svg
                ref={ref}
                width={width}
                height={height}
                viewBox="0 0 24 24"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path d="M4 5.765C4 3.682 5.734 2 7.865 2a3.92 3.92 0 0 1 2.198.67l9.271 6.236A3.74 3.74 0 0 1 21 12c0 1.235-.623 2.4-1.666 3.094l-9.27 6.235a3.975 3.975 0 0 1-2.2.671C5.735 22 4 20.306 4 18.235V5.765z" fill="currentColor"/>
            </svg>
        );
    }
);

export default PlayTriangle;
