import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NewUser = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {shadow && (
                    <defs>
                        <filter
                            id="a"
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <path
                        id="New-User"
                        fill="currentColor"
                        d="M19.376 12.5h-1.248c-.345 0-.624-.28-.624-.625 0-.344.28-.625.624-.625h1.248c.345 0 .624.28.624.625 0 .346-.28.625-.624.625zm0-3.75h-2.497c-.344 0-.624-.28-.624-.625s.28-.625.624-.625h2.497c.345 0 .624.28.624.625 0 .346-.28.625-.624.625zm0-3.75h-2.497c-.344 0-.624-.28-.624-.625s.28-.624.624-.624h2.497c.345 0 .624.28.624.624 0 .346-.28.626-.624.626zm-8.163 8.167c.862.207 5.296 1.293 5.843 1.794 1 .916 1.397 3.664.398 5.039l-8.397-.002L.58 20c-1.007-1.375-.599-4.123.409-5.04.56-.509 4.857-1.546 5.856-1.781-2.24-1.181-3.63-4.072-3.63-6.798C3.216 2.857 4.271 0 9.016 0c4.744 0 5.798 2.857 5.798 6.38 0 2.716-1.377 5.597-3.601 6.787zM9.015 1.246c-3.754 0-4.504 2.31-4.504 5.158 0 2.371 1.218 4.992 3.218 5.812v2.162h-.535c-2.458.513-4.717 1.227-5.376 1.549-.421.471-.69 1.878-.429 2.823l7.667-.002 7.588.002c.26-.945-.002-2.348-.416-2.822-.663-.317-2.883-1.032-5.312-1.55h-.612v-2.164c1.998-.821 3.216-3.44 3.216-5.81 0-2.849-.751-5.158-4.505-5.158zM19.376 15c.345 0 .624.28.624.625s-.28.625-.624.626c-.344-.001-.623-.28-.623-.626 0-.344.279-.625.623-.625zm0 0zm0 1.25z"
                    />
                </g>
            </svg>
        );
    }
);

export default NewUser;
