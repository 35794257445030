import { memo, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';

import ImageUploaderView from './view';
import { ImageUploaderControllerProps } from './types';
import { ImageEditor } from './ImageEditor';
import { getEditedImageFileInfo, useCheckImage } from './helper';
import { MAX_IMAGE_FILE_SIZE } from './ImageEditor/constants';

const  ImageUploaderController = ({
    initialImage,
    setProfileImage,
    placeHolder,
    className,
    dataTest,
    maxImageSize,
    onFileDelete,
    rectStyled,
    isInProgress,
}: ImageUploaderControllerProps) =>  {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const imageEditorRef = useRef<AvatarEditor | null>(null);

    const checkImage = useCheckImage(maxImageSize || MAX_IMAGE_FILE_SIZE);

    const [isImageEditorVisible, setIsImageEditorVisible] = useState<boolean>(
        false
    );

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [editedImage, setEditedImage] = useState<string | null>(null);

    /**
     * @function handleApplyImage
     * @returns { Promise<void> }
     */
    const handleApplyImage = async (): Promise<void> => {
        if (imageEditorRef) {
            const editedImageFileInfo = await getEditedImageFileInfo(
                imageEditorRef
            );

            if (editedImageFileInfo) {
                Promise.resolve().then(() => {
                    setEditedImage(editedImageFileInfo?.url);
                    setProfileImage?.(editedImageFileInfo?.file);
                    handleCloseImageEditor();
                });
            }
        }
    };

    const handleFileDelete = (): void => {
        setEditedImage(null);
        onFileDelete && onFileDelete();
    }

    /**
     * @function handleCloseImageEditor
     * @returns { void }
     */
    const handleCloseImageEditor = (): void => {
        setIsImageEditorVisible(false);
    };

    /**
     * @function handleOpenImageEditor
     * @returns { void }
     */
    const handleOpenImageEditor = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const isAllowedImage = checkImage(event);

        if (isAllowedImage) {
            Promise.resolve().then(() => {
                if (event?.target?.files?.[0]) {
                    setSelectedImage(event.target.files[0]);
                    setIsImageEditorVisible(true);
                }
            });
        }
    };

    /**
     * @description this function is used to handle choose same image again
     * @function handleInputClick
     * @param event
     * @returns { void }
     */
    const handleInputClick = (
        event: React.MouseEvent<HTMLInputElement>
    ): void => {
        const { target = {} } = event || {};
        (target as HTMLInputElement).value = '';
    };

    return (
        <>
            <ImageUploaderView
                initialImage={initialImage}
                fileInputRef={fileInputRef}
                placeHolder={placeHolder}
                className={className}
                editedImage={editedImage}
                handleImageChange={handleOpenImageEditor}
                handleInputClick={handleInputClick}
                isInProgress={isInProgress}
                dataTest={dataTest}
                onFileDelete={handleFileDelete}
                rectStyled={rectStyled}
            />
            {isImageEditorVisible && selectedImage && (
                <ImageEditor
                    editorRef={imageEditorRef}
                    selectedImage={selectedImage}
                    handleClose={handleCloseImageEditor}
                    handleApplyEditedImage={handleApplyImage}
                    rectStyled={rectStyled}
                />
            )}
        </>
    );
}

export default memo(ImageUploaderController);
