import React, { FC, useEffect } from 'react';
import { PaginationResult } from '../../../../../../../constants/interfaces/PaginationResult';
import { DraftUser } from '../../../../../../../constants/interfaces/Draft';
import { useCalculatePreSelectedTeams } from '../../../../../../../features/portableTeams/tools';
import { PortableTeamsWithStore } from '../../../../../../../features/portableTeams';
import { useSelectMultipleTeams } from '../../../../../../../features/portableTeams/store/hors/withTeams/services';

const FilterTeams: FC<{
    users?: PaginationResult<DraftUser>;
    selectedUsers?: number[];
    setInitialTeams?(practisSetId: number[]): void;
    showDefaultMembers?: boolean
}> = ({ users, selectedUsers, showDefaultMembers, setInitialTeams }) => {
    const setTeams = useSelectMultipleTeams();
    const calculatePreSelectedTeams = useCalculatePreSelectedTeams();
    
    useEffect(() => {
        if (users && users.items && selectedUsers && setInitialTeams) {
            const preSelectedTeams = calculatePreSelectedTeams(
                selectedUsers,
                users.items as any
            );
            setInitialTeams(preSelectedTeams);
            setTeams(preSelectedTeams);
        }
    }, [users, selectedUsers, calculatePreSelectedTeams, setInitialTeams, setTeams]);

    return (
        <PortableTeamsWithStore
            showSave={false}
            showSelectActions={true}
            fetchOnMount={false}
            showSearch={true}
            resetSelectedOnUnmount={true}
            showDefaultMembers={showDefaultMembers}
        />
    );
};

export default FilterTeams;
