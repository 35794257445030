import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { Pacings } from '../../../constants/interfaces/Pacings';

export enum ACTIONS {
    SEARCH_PACINGS_START = 'SEARCH_PACINGS_START',
    SEARCH_PACINGS_SUCCESS = 'SEARCH_PACINGS_SUCCESS',
    SEARCH_PACINGS_FAILURE = 'SEARCH_PACINGS_FAILURE',
}

export function searchPacingsStart() {
    return {
        type: ACTIONS.SEARCH_PACINGS_START as ACTIONS.SEARCH_PACINGS_START,
    };
}

export function searchPacingsSuccess(pacings: PaginationResult<Pacings>) {
    return {
        type: ACTIONS.SEARCH_PACINGS_SUCCESS as ACTIONS.SEARCH_PACINGS_SUCCESS,
        pacings,
    };
}

export function searchPacingsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_PACINGS_FAILURE as ACTIONS.SEARCH_PACINGS_FAILURE,
        error,
    };
}
