import { useCallback } from 'react';
import { useTextToSpeechService } from '../store/services';
import { ScriptLine, ScriptLineSpeaker } from '../../../constants/interfaces/ScriptLine';
import { TextToSpeechSettingsByRoles } from '../../../constants/interfaces/CompanyVoiceSettings';

/**
 * @function useTextToSpeechForScriptLine
 * @description Custom hook to generate speech for a separate script line by its index
 * @returns { CallableFunction }
 */
export const useTextToSpeechForScriptLine = () => {
    const textToSpeech = useTextToSpeechService();

    /**
     * @function callback
     * @param { ScriptLine[] } lines
     * @param { number } index
     * @param { string } featureName
     * @param { TextToSpeechSettingsByRoles } settings
     * @returns { Promise }
     */
    return useCallback((
        lines: ScriptLine[],
        index: number,
        featureName: string,
        settings: TextToSpeechSettingsByRoles
    ) => {
        const line = lines[index];
        if (!line?.text?.length) return Promise.resolve();

        const lineSettings = line.speaker === ScriptLineSpeaker.CUSTOMER
            ? settings.customer
            : settings.representative;
        return textToSpeech(
            line.text,
            lineSettings,
            { feature: featureName, itemId: line.id }
        );
    }, [textToSpeech]);
};