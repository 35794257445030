import React, { memo } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const StyledLoadingComponent = styled.div<{
    color?: string;
    width: string;
    height: number;
}>`
    height: ${props => `${props.height}px`};
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    width: ${props => props.width};
    color: ${props =>
        props.color ? props.color : props.theme.Colors.skyBlueTwo};
`;

export const Loading: React.FC<{
    width?: string;
    className?: string;
    color?: string;
    size?: number;
}> = memo(({ width, color, size = 20, className }) => {
    return (
        <StyledLoadingComponent
            width={width ? width : '100%'}
            className={className}
            color={color}
            height={size}
        >
            <CircularProgress size={`${size}px`} color="inherit" />
        </StyledLoadingComponent>
    );
});

export default Loading;
