import { Field, Formik } from 'formik';
import {
    StyledForm,
    FieldContainer,
    FieldName,
    Footer,
    Content,
} from './styles';
import { Button } from '../../../../ui/components/Button';
import { CompanyDetailsViewProps, CompanyDetailsFormValues } from './types';
import { ValidationSchema } from './constants';
import { FormikSelect } from '../../../../ui/components/FormikSelect';
import { CustomizedInput } from '../../../../ui/components/CustomizedInput';

function CompanyDetailsView({
    formRef,
    initialValues,
    companyAdminItems,
    onSubmit,
    onApiResultValidation,
}: CompanyDetailsViewProps) {
    return (
        <Formik<CompanyDetailsFormValues>
            ref={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
            validate={onApiResultValidation}
        >
            {({ values, dirty, isValid, isSubmitting, handleChange }) => {
                return (
                    <StyledForm>
                        <Content>
                            <FieldContainer>
                                <FieldName data-test="company-name-title">Name</FieldName>
                                <Field
                                    name="name"
                                    component={CustomizedInput}
                                    maxLength={80}
                                    height="40px"
                                    disabled={isSubmitting}
                                    dataTest="company-name-input"
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldName data-test="workspace-url-title">Workspace URL</FieldName>
                                <Field
                                    name="workspaceUrl"
                                    component={CustomizedInput}
                                    height="40px"
                                    disabled={true}
                                    dataTest="workspace-url-input"
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FieldName data-test="account-owner-title">Account Owner</FieldName>
                                <Field
                                    type="text"
                                    items={companyAdminItems}
                                    value={values.ownerId}
                                    component={FormikSelect}
                                    onChange={handleChange}
                                    height={40}
                                    variant="outlined"
                                    name="ownerId"
                                    className="company-select"
                                    dataTest="account-owner-dropdown"
                                />
                            </FieldContainer>
                        </Content>
                        <Footer>
                            <Button
                                width="130px"
                                height="40px"
                                disabled={!dirty || !isValid || isSubmitting}
                                loadingColor="white"
                                loadingSize={24}
                                loading={isSubmitting}
                                dataTest="apply-button"
                            >
                                Apply
                            </Button>
                        </Footer>
                    </StyledForm>
                );
            }}
        </Formik>
    )
}

export default CompanyDetailsView;