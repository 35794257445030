import { Variables } from '../../../theme/variables';
import { Theme } from './type';

export const COLORS: Record<
    Theme,
    { light: string; normal: string; dark: string; text: string }
> = {
    default: {
        light: Variables.Colors.darkSkyBlue,
        normal: Variables.Colors.darkSkyBlueTwo,
        dark: Variables.Colors.coolBlue,
        text: Variables.Colors.white,
    },
    warning: {
        light: Variables.Colors.tomato,
        normal: Variables.Colors.coral,
        dark: Variables.Colors.paleRed,
        text: Variables.Colors.white,
    },
    'warning-inverse': {
        light: Variables.Colors.tomato,
        normal: Variables.Colors.salmon,
        dark: Variables.Colors.paleRed,
        text: Variables.Colors.white,
    },
    white: {
        light: Variables.Colors.white,
        normal: Variables.Colors.white,
        dark: Variables.Colors.white,
        text: Variables.Colors.black,
    },
    gray: {
        light: Variables.Colors.steelGrey,
        normal: Variables.Colors.battleshipGrey,
        dark: Variables.Colors.darkerGray,
        text: Variables.Colors.white,
    },
    grayLight: {
        light: Variables.Colors.whiteFive,
        normal: Variables.Colors.lightGray,
        dark: Variables.Colors.whiteFive,
        text: Variables.Colors.steelGrey,
    },
    green: {
        light: Variables.Colors.green,
        normal: Variables.Colors.greenTwo,
        dark: Variables.Colors.greenTwo,
        text: Variables.Colors.white,
    },
};
