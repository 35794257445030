import React, { FC, useCallback } from 'react';
import { PortableTeams } from './PortableTeams';
import { usePortableTeamsState } from '../store/states';

interface Props {
    selectedTeams: number[];
    showDefaultMembers?: boolean
    onChangeSelectedTeams(teamIds: number[]): void;
};

export const PortableTeamsWithStore2: FC<Props> = props => {
    const { selectedTeams, showDefaultMembers, onChangeSelectedTeams } = props;
    const { data, loading } = usePortableTeamsState();

    const handleSelectAll = useCallback(
        () => onChangeSelectedTeams(data?.items.map(t => t.id!)),
        [data?.items, onChangeSelectedTeams]
    );

    const handleDeselectAll = useCallback(
        () => onChangeSelectedTeams([]),
        [onChangeSelectedTeams]
    );

    return (
        <PortableTeams
            showDefaultMembers={showDefaultMembers}
            teams={data}
            selectedList={selectedTeams}
            loading={loading}
            showSave={false}
            showSelectActions={true}
            showSearch={true}
            onSetSelected={onChangeSelectedTeams}
            onSelectAll={handleSelectAll}
            onClearSelected={handleDeselectAll} //nice interface! :(
        />
    );
};
