import { Field } from 'formik';
import styled from 'styled-components';

export const HeaderTitle = styled.div`
    flex-grow: 0;
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    margin: 8px 0;
    text-align: center;
    color: var(--ps-white-1);
`;

export const Content = styled.div`
    width: 90%;

    @media (min-width: 420px) {
        width: 70%;
    }
    @media (min-width: 768px) {
        width: 40%;
    }

    @media (min-width: 1440px) {
        width: 22%;
    }
`;

export const FieldsContainer = styled.div<{ marginBottom?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    > div,
    p {
        margin-bottom: ${props =>
            !!props.marginBottom ? props.marginBottom : '16px'};
    }
`;

export const StyledField = styled(Field)`
    flex: 1;
`;
