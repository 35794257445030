import { FC, useCallback } from 'react';
import { SelectDropdown } from '../../../SelectDropdown';

export const TableDropdown: FC<{
    items?: { value: string | number; name: string }[];
    value?: string | string[] | number | number[];
    defaultValue?: string;
    label?: string;
    disabled?: boolean;
    restrictShowAllName?: boolean;
    onChange?(value: string): void;
    openMenuWidth?: string;
}> = ({
    value,
    defaultValue,
    items,
    label,
    disabled,
    restrictShowAllName,
    onChange,
    openMenuWidth,
}) => {
    const handleChange = useCallback(
        (e: any) => onChange && onChange(e.target.value),
        [onChange]
    );
    return (
        <SelectDropdown
            label={label}
            items={items}
            defaultValue={defaultValue}
            value={value}
            restrictShowAllName={restrictShowAllName}
            onChange={handleChange}
            disabled={disabled}
            openMenuWidth={openMenuWidth}
        />
    );
};

export function useFilter(
    field: string,
    onFilterChange: (filter: {
        field: string;
        value: string | number | string[] | number[];
    }) => void
) {
    const onChange = useCallback(
        (value: string | number | string[] | number[]) =>
            onFilterChange({ field, value }),
        [field, onFilterChange]
    );
    return onChange;
}
