import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const DownloadPDF = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 13 16"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M12 15.999H1c-.552 0-1-.448-1-1v-10c0-.553.448-1 1-1h1.5v1h-1c-.276 0-.5.224-.5.5v9c0 .276.224.5.5.5h10c.276 0 .5-.224.5-.5v-9c0-.276-.224-.5-.5-.5h-1v-1H12c.552 0 1 .447 1 1v10c0 .552-.448 1-1 1zm-5.126-4.184c-.004.005-.003.012-.007.017-.006.006-.003.017-.01.024-.006.007-.017.004-.024.01-.083.075-.19.122-.309.128-.005 0-.01.004-.016.004h-.015c-.009 0-.016-.006-.025-.007-.1-.007-.185-.048-.26-.107-.02-.015-.047-.01-.065-.028-.013-.012-.008-.031-.019-.044-.003-.004-.008-.006-.012-.01l-2.47-2.47c-.19-.19-.19-.5 0-.69.192-.191.5-.191.691 0L6 10.308V.5c0-.276.224-.5.5-.5s.5.224.5.5v9.81l1.667-1.667c.19-.191.5-.191.69 0 .191.19.191.5 0 .69l-2.483 2.483z"
                            transform="translate(-1381 -132) translate(1381 132)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default DownloadPDF;
