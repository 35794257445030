import styled from 'styled-components';

export const FieldContainer = styled.div`
    height: 78px;

    .company-select {
        width: 400px;
        border: solid 1px var(--ps-grey-3);

        &.company-select-open {
            border: solid 1px var(--ps-grey-2);
        }

        .company-select-input > div {
            color: var(--ps-black-main);
        }

        .company-select-input > div[disabled] {
            color: var(--ps-grey-2);
        }

        .content-area {
            padding: 8px 0;

            > div {
                padding: 5px 12px;
                border-radius: 0;
                margin-bottom: 0;
                min-height: auto;
                font-weight: 400;

                &.selected-item {
                    color: var(--ps-white-1);
                    background: var(--ps-blue-main);
                }

                &:hover {
                    color: var(--ps-white-1);
                    background: var(--ps-blue-main);
                }
            }
        }
    }

    .company-select-label {
        left: 12px;
    }

    .company-select-arrow {
        right: 12px;
    }

    .company-select-input {
        padding: 0 12px;
    }
`;

export const FieldName = styled.div`
    font-size: 13px;
    font-weight: 800;
    color: var(--ps-black-main);
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
`;

export const AdvancedSettingsHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 8px;
`;

export const AdvancedSettingsTitle = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: var(--ps-grey-1);
`;

export const ResetButton = styled.div<{ isActive: boolean }>`
    font-size: 13px;
    color: ${props => props.isActive ? 'var(--ps-blue-main)' : 'var(--ps-grey-2)' };
    cursor: ${props => props.isActive ? 'pointer' : 'default' };
`;

export const AdvancedFieldContainer = styled.div`
    height: 68px;

    .input-range__label--min,
    .input-range__label--max {
        font-size: 12px;
        font-weight: bold;
        color: var(--ps-grey-1);
        bottom: -0.9rem;
    }

    .input-range__track {
        height: 2px;
    }

    .input-range__slider {
        background: var(--ps-blue-main) !important;
        width: 12px;
        height: 12px;
        margin-top: -7px;
    }

    .input-range--disabled .input-range__slider {
        background: var(--ps-grey-2) !important;
    }
`;

export const QuestionContainer = styled.div`
    margin-left: 3px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
`;

export const TooltipContainer = styled.div`
    padding: 4px;
`

export const TootlipTitle = styled.div`
    padding-top: 16px;
    font-size: 13px;
    font-weight: 800;
    color: var(--ps-black-main);

    &:first-child {
        padding-top: 0;
    }
`;

export const TootlipContent = styled.div`
    padding-top: 4px;
    font-size: 13px;
    color: var(--ps-black-main);
`;

export const SampleTextContainer = styled.div`
    margin-top: 8px;
`;