import { UserInterface } from "./User";

export interface CompanyInterface {
    id?: number;
    name?: string;
    owner?: UserInterface;
    logoUrl?: string;
    logo?: string;
    createdAt?: string;
    updatedAt?: string;
    activatedAt?: string;
    creator?: UserInterface;
    minTrainingScore?: number;
    status?: string;
    usersLimit?: number | null;
    usersCount?: number | null;
    subdomain?: string;
}

export enum CompanyStatus {
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
}

export const CompanyStatusNames = {
    [CompanyStatus.ACTIVE as string]: 'Active',
    [CompanyStatus.DELETED as string]: 'Inactive',
};