import styled from 'styled-components';
import ArrowRight from '../../../../../../../ui/icons/ArrowRight';
import TickIcon from '../../../../../../../ui/icons/TickIcon';

export const StyledListBox = styled.div`
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px var(--ps-grey-4);
    display: flex;
    flex-direction: column;
`;

export const StyledScenarioItem = styled.div<{ disabled?: boolean }>`
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        background: var(--ps-grey-3);
        ${props => !props.disabled && `cursor: pointer`}
    }
`;

export const ItemType = styled.span`
    font-size: 13px;
    color: var(--ps-grey-1);
`;

export const ItemWithIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 45%;
`;

export const ItemContainer = styled.div`
    flex-direction: column;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
`;

export const ItemTitle = styled.span`
    font-size: 15px;
    font-weight: bold;
    color: var(--ps-black-main);
`;

export const ItemStatus = styled.span`
    font-size: 13px;
    line-height: 16px;
    margin-top: 8px;
`;

export const ItemStatusGreen = styled(ItemStatus)`
    color: #0d8770;
`;

export const ItemStatusBlue = styled(ItemStatus)`
    color: var(--ps-blue-main);
`;

export const ItemStatusGrey = styled(ItemStatus)`
    color: var(--ps-grey-1);
`;

export const RepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 70px;
    margin-left: 16px;
    align-items: flex-start;
    justify-content: center;
    width: 20%
`;

export const StatisticsValue = styled.span<{ empty?: boolean }>`
    font-size: 20px;
    font-weight: 600;
    color: ${({ empty }) => (empty ? 'var(--ps-grey-2)' : 'var(--ps-black-main)')};
    line-height: ${({ empty }) => (empty ? '20px' : `unset`)};
`;

export const StatisticsIndicator = styled.span`
    font-size: 16px;
    font-weight: 800;
`;

export const RepsDescription = styled.span`
    font-size: 13px;
    color: var(--ps-grey-1);
    font-weight: 600;
`;

export const AttemptText = styled.span`
    font-size: 15px;
`;

export const ReviewButton = styled.span`
    font-size: 13px;
    color: var(--ps-blue-main);
    cursor: pointer;
    margin-top: 3px;
`;

export const PlayScreenContainer = styled.div`
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PlayScreen = styled.div`
    width: 90px;
    height: 90px;
    border-radius: 8px;
    background-color: var(--ps-grey-21);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const PlayButton = styled.div`
    color: var(--ps-blue-main);
    width: 24px;
    height: 24px;
`

export const ArrowContainer = styled.div`
    color: var(--ps-blue-main);
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`;

export const ArrowIcon = styled(ArrowRight)<{ disabled?: boolean }>`
    width: 10px;
    height: 16px;
    ${props => props.disabled && `color: #d2dfe6;`}
`;

export const StyledTickIcon = styled(TickIcon)`
    height: 14px;
`;
