import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import styled, { css } from 'styled-components';
import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { Label } from '../../../constants/interfaces/Label';
import { listToTree } from '../../../helpers/functions/list-to-tree';
import {treeToArray, treeToList} from '../../../helpers/functions/tree-to-list';
import { filterLabels, sortLabels } from '../../labels/tools';
import { PortableLabelsList } from './PortableLabelList';
import { Button } from '../../../ui/components/Button';
import EmptyLabels from './EmptyLabels';
import { Variables } from '../../../theme/variables';
import NoResults, { NoResultType } from './NoResultLabels';
import { TableSearchInput } from '../../../ui/components/table-wrapper/table-tools/table-search-input';

//region Types
type Action = 'clear' | 'select';

export type PortableLabelsProps = {
    labels?: PaginationResult<Label>;
    collapsedList: number[];
    selectedList: number[];
    onSave?: () => void;
    onSelectAll?: (selectedList: number[]) => void;
    onDeselectAll?: () => void;
    onClearSelected?: () => void;
    onToggleCollapse: (labelId: number) => void;
    onToggleCollapseAll: () => void;
    onSetSelected?: (labelIds: number[]) => void;
    filtersTitle?: string;
    disabled?: boolean;
    loading?: boolean;
    showSelectActions?: boolean;
    showSearch?: boolean;
    showSave?: boolean;
    noResultType?: NoResultType;
};
//endregion

//region Styles
const StyledCreateLabels = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const CreateLabelsBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const FiltersHeader = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
    flex-shrink: 0;
`;

const FiltersSavePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const StyledPlainActionsWrapper = styled.div`
    padding-right: 14px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
    font-weight: 600;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;
//endregion

const PortableLabels: FC<PortableLabelsProps> = ({
    labels,
    collapsedList,
    selectedList,
    disabled,
    loading,
    onSave,
    onClearSelected,
    onDeselectAll,
    onSelectAll,
    onToggleCollapse,
    onToggleCollapseAll,
    onSetSelected,
    filtersTitle,
    showSelectActions,
    showSearch,
    showSave = true,
    noResultType = 'min',
}) => {
    const actionConfig: Record<
        Action,
        {
            color: string;
            colorHover: string;
            colorActive: string;
        }
    > = {
        clear: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
        select: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [transformedLabels, setTransformedLabels] = useState(
        listToTree(labels && labels.items)
    );
    const [processedSelectedList, setProcessedSelectedList] = useState<
        number[]
    >([]);
    const [processedIds, setProcessedIds] = useState<any>([]);

    const getPrecessedLabels = useMemo(() => {
        const result = compose<Label[]>(
            filterLabels(searchTerm),
            sortLabels
        )(transformedLabels);

        if (result.length > 0) {
            const filteredAndSortedLabelsArray = treeToArray(result);
            const processedDataIds = filteredAndSortedLabelsArray.map((item: any) => item.id!)
            setProcessedIds(processedDataIds);
            setProcessedSelectedList(
                selectedList.filter(item => processedDataIds.includes(Math.abs(item)))
            );
        }

        return result;
    }, [transformedLabels, searchTerm, selectedList]);

    const isAllSelected = useMemo(
        () => selectedList.length === processedIds.length,
        [selectedList, processedIds]
    );

    const clearHandler = useCallback(() => {
        onClearSelected?.();
    }, [onClearSelected]);

    const deSelectAllHandler = useCallback(() => {
        if (isAllSelected && onDeselectAll) {
            onDeselectAll();
        } else {
            if (searchTerm) {
                onSetSelected?.(processedIds);
            }
            else if (onSelectAll) {
                onSelectAll(processedIds);
            }
        }
    }, [onDeselectAll, onSelectAll, onSetSelected, isAllSelected, processedIds, searchTerm]);

    const searchHandler = (value: string) => {
        if(!!value){
            onToggleCollapseAll();
        } else {
            // clearHandler(); //FIXME: check if it's needed
        }
        setSearchTerm(value) 
    };


 
  
    useEffect(() => {
        setTransformedLabels(listToTree(treeToList(labels && labels.items)));
    }, [labels]);

    const showNoItems = !labels?.totalCount;
    const showNoResultsState = !getPrecessedLabels.length;
    const showEmptyState = showNoItems || showNoResultsState || loading;

    const selectedLabelsLength = selectedList.filter(id => id > 0).length

    return (
        <StyledCreateLabels>
            <CreateLabelsBody>
                {filtersTitle && (
                    <FiltersHeader>
                        <FilterTitle>{filtersTitle}</FilterTitle>
                    </FiltersHeader>
                )}
                {showSearch && (
                    <SearchWrapper data-test="labels-searchbox-wrapper">
                        <TableSearchInput
                            onChange={searchHandler}
                            disabled={showNoItems}
                            dataTest="labels-searchbox-field"
                        />
                    </SearchWrapper>
                )}
                {showSelectActions && !showNoItems && (
                    <SelectActions>
                        <div>
                            <SelectedCaption data-test="labels-selected-caption">
                                {selectedLabelsLength > 0
                                    ? `${selectedLabelsLength} ${
                                          selectedLabelsLength > 1
                                              ? ' Labels selected'
                                              : ' Label selected'
                                      }`
                                    : 'No Labels selected'}
                            </SelectedCaption>
                        </div>
                        <StyledPlainActionsWrapper>
                            {isAllSelected ? (
                                <PlainAction
                                    disabled={
                                        processedSelectedList.length === 0 ||
                                        showNoResultsState
                                    }
                                    color={actionConfig.clear.color}
                                    colorActive={actionConfig.clear.colorActive}
                                    colorHover={actionConfig.clear.colorHover}
                                    onClick={clearHandler}
                                    data-test="labels-unselect-all-button"
                                >
                                    Unselect All
                                </PlainAction>
                            ) : (
                                <PlainAction
                                    disabled={showNoResultsState}
                                    color={actionConfig.select.color}
                                    colorActive={
                                        actionConfig.select.colorActive
                                    }
                                    colorHover={actionConfig.select.colorHover}
                                    onClick={deSelectAllHandler}
                                    data-test="labels-select-all-button"
                                >
                                    Select All
                                </PlainAction>
                            )}
                        </StyledPlainActionsWrapper>
                    </SelectActions>
                )}
                {showEmptyState ? (
                    showNoItems ? (
                        <EmptyLabels loading={loading} />
                    ) : (
                        <NoResults version={noResultType} loading={loading} />
                    )
                ) : (
                    <>
                        <PortableLabelsList
                            labels={getPrecessedLabels}
                            allLabels={labels && labels.items}
                            labelsCollapseState={collapsedList}
                            selectedLabels={selectedList}
                            disableLabels={disabled}
                            setLabelFilters={onSetSelected}
                            toggleCollapse={onToggleCollapse}
                        />
                        {onSave && showSave && (
                            <FiltersSavePanel>
                                <Button
                                    type={'button'}
                                    label={'Save'}
                                    action={() => onSave()}
                                    height={'48px'}
                                    width={'160px'}
                                />
                            </FiltersSavePanel>
                        )}
                    </>
                )}
            </CreateLabelsBody>
        </StyledCreateLabels>
    );
};

export { PortableLabels };
