import React, { FC } from 'react';
import styled from 'styled-components';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import NoLabels from '../../../ui/icons/NoLabels';

const Container = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const StyledEmptyContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoTeamsTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const EmptyLabels: FC<{ loading?: boolean }> = ({ loading, children }) => {
    if (loading) {
        return (
            <Container>
                <Loading />
            </Container>
        );
    }

    return (
        <Container>
            <StyledEmptyContent>
                <IconWrapper data-test="labels-searchbox-no-items-icon">
                    <NoLabels />
                </IconWrapper>
                <NoTeamsTitle data-test="labels-searchbox-no-items-text">No Labels yet</NoTeamsTitle>
            </StyledEmptyContent>
        </Container>
    );
};

export default EmptyLabels;
