import { FC, memo } from 'react';

import TableHeaderCellMultiView from './multi.view';
import TableHeaderCellSingleView from './view';
import { TableHeaderCellProps } from './type';

const TableHeaderCellController: FC<TableHeaderCellProps> = props => {
    const { isMultiple, ...rest } = props;

    return isMultiple ? (
        <TableHeaderCellMultiView {...rest} />
    ) : (
        <TableHeaderCellSingleView {...rest} />
    );
};

export default memo(TableHeaderCellController);

