import { FC, useEffect, useState } from 'react';

import Tag from '../../../../icons/Tag';
import { TreeDfsNameMapper } from '../../../../../helpers/classes/TreeDfsNameMapper';
import TagDisabled from '../../../../icons/TagDisabled';
import { usePortableLabelsState } from '../../../../../features/portableLabels/store/states';
import {
    LabelIconWrapper,
    LabelTagsContainer,
    LabelText,
    StyledLabelTag,
} from './styles';

export interface TableLabelTagsProps {
    open?: boolean;
    selectedLabels?: number[];
    toggleOpen?(): void;
    isLabelLess?: boolean;
    className?: string;
    dataTest?: string;
}

export const TableLabelTags: FC<TableLabelTagsProps> = ({
    open,
    toggleOpen,
    selectedLabels,
    isLabelLess,
    className,
    dataTest
}) => {
    const [assignedLabelNames, setAssignedLabelNames] = useState<any>([]);
    const labels = usePortableLabelsState().data;
    const disabled =
        (!assignedLabelNames || assignedLabelNames.length < 1) && !open;

    useEffect(() => {
        if (open && assignedLabelNames.length < 1) {
            toggleOpen?.();
        }
    }, [assignedLabelNames, open, toggleOpen]);

    useEffect(() => {
        const selectedItems = (selectedLabels || []).map(item =>
            item >= 0 ? item : Math.abs(item)
        );

        if (!!labels && labels.items.length > 0 && selectedItems) {
            const treeDfsNameMapper = new TreeDfsNameMapper(
                labels.items,
                selectedItems
            );
            const names = treeDfsNameMapper.getNames();
            setAssignedLabelNames(names);
        }
    }, [labels, selectedLabels]);

    return (
        <LabelTagsContainer
            onClick={e => {
                e.stopPropagation();
                if (disabled) return;
                if (toggleOpen) {
                    toggleOpen();
                }
            }}
            disabled={disabled}
            className={className}
        >
            <StyledLabelTag
                open={open}
                disabled={disabled}
                isLabelLess={isLabelLess}
                data-test={dataTest || 'table-labels'}
            >
                <LabelIconWrapper disabled={disabled}>
                    {isLabelLess ? <TagDisabled /> : <Tag />}
                </LabelIconWrapper>
                <LabelText disabled={disabled}>
                    {isLabelLess
                        ? ''
                        : assignedLabelNames
                        ? assignedLabelNames.length
                        : 0}
                </LabelText>
            </StyledLabelTag>
        </LabelTagsContainer>
    );
};
