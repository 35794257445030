import { useCallback } from 'react';

import {
    clearAction,
    saveAction,
    searchFailureAction,
    searchSuccessAction,
    selectAllAction,
    deselectAllAction,
    selectPractisSetsAction,
    searchStartAction,
    resetSelectedAction,
    resetAction,
    selectMultiplePractisSetsAction,
} from './actions';

import { useEncapsulatedDispatch } from './helpers/useEncapsulatedDispatch';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { useDispatch } from 'react-redux';
import { PractisSets } from '../../../../../constants/interfaces/PractisSets';
import { ListResult } from '../../../../../constants/interfaces/PaginationResult';
import { unionWith } from 'lodash';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { NOT_AUTHORIZED_ERROR_MESSAGE } from '../../../../../ui/components/ErrorMessages/constants';
import { useSearchPractisSetsApi } from '../../../../../api';
import { createSearchLibraryParams } from '../../../../library/tools';

export const useFetchAllPractisSetsService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const searchPractisSets = useSearchPractisSetsApi();

    return useCallback(
        async () => {
            dispatch(searchStartAction());
            try {
                let sp = {
                    offset: 0,
                    limit: 20,
                    orderBy: { field: 'updated_at', asc: false },
                } as SearchParams;
                let data = {
                    items: [] as PractisSets[],
                    count: 0,
                } as ListResult<PractisSets>;
                do {
                    const searchPractisSetsParams =
                        createSearchLibraryParams(sp);

                    const prevItems = data.items;
                    data = await searchPractisSets(searchPractisSetsParams);
                    data =
                        sp.offset === 0 || !prevItems
                            ? data
                            : {
                                  ...data,
                                  items: unionWith(
                                      prevItems,
                                      data.items,
                                      (i1, i2) => i1.id === i2.id
                                  ),
                              };
                    sp = {
                        ...sp,
                        offset: Math.min(data.items.length, data.count),
                    };
                } while (
                    data.items.length < data.count
                );
                dispatch(searchSuccessAction(data));
            } catch (error: any) {
                dispatch(searchFailureAction(error.message));
                error?.message !== NOT_AUTHORIZED_ERROR_MESSAGE &&
                    showMessage(error.message, 'error');
            }
        },
        [dispatch, showMessage, searchPractisSets]
    );
};

export const useClearPractisSets = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(clearAction());
    }, [dispatch]);
};

export const useSavePractisSets = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(saveAction());
    }, [dispatch]);
};

export const useSelectPractisSets = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: number) => {
            dispatch(selectPractisSetsAction(ids));
        },
        [dispatch]
    );
};

export const useSelectMultiplePractisSets = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: PractisSetWithDueDate[]) => {
            dispatch(selectMultiplePractisSetsAction(ids));
        },
        [dispatch]
    );
};

export const useSelectAllPractisSets = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(
        (practisSets: PractisSets[]) => {
            dispatch(selectAllAction(practisSets));
        },
        [dispatch]
    );
};

export const useDeselectAllPractisSets = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(deselectAllAction());
    }, [dispatch]);
};

export const useResetSelectedPractisSets = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetSelectedAction());
    }, [dispatch]);
};

export const useResetPractisSets = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetAction());
    }, [dispatch]);
};
