import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    width: 312px;
    justify-content: space-between;
`;

export const InfoText = styled.div`
    padding-top: 3px;
    font-size: 11px;
    line-height: 16px;
    color: var(--ps-grey-1);
`;

export const EmailText = styled.span`
    font-weight: 600;
`;