import styled from 'styled-components';
import { TableCell } from '../table-cell';

export const TableCheckboxHeaderCell = styled(TableCell)`
    padding: 0;
    border-bottom: 1px solid ${props => props.theme.Colors.paleGrey};
`;

export const StyledCellWrapper = styled.div<{
    width?: number;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => (width ? `${width}%` : '70%')};
    margin: 0 auto;

    th {
        display: flex;
        justify-content: center;
    }
`;
