import { CSSProperties, FC, memo } from 'react';
import styled from 'styled-components';

const StyledNoResults = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledNoResultsContent = styled.div`
    max-width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoResultsTitle = styled.div<{ width?: string; fontSize?: number }>`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '15px')};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
    ${props => !!props.width && `width: ${props.width};`}
`;

const NoResultsIcon = styled.div`
    width: 48px;
    height: 48px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

export const EmptyState: FC<{
    icon?: any;
    iconStyle?: CSSProperties;
    width?: string;
    fontSize?: number;
    dataTest?: string;
}> = memo(({ icon: Icon, iconStyle, width, fontSize, dataTest, children }) => (
    <StyledNoResults>
        <StyledNoResultsContent>
            {Icon && (
                <NoResultsIcon
                    data-test={dataTest && `${dataTest}-icon`}
                    style={iconStyle}
                >
                    <Icon />
                </NoResultsIcon>
            )}
            <NoResultsTitle
                width={width}
                fontSize={fontSize}
                data-test={dataTest && `${dataTest}-label`}
            >
                {children}
            </NoResultsTitle>
        </StyledNoResultsContent>
    </StyledNoResults>
));