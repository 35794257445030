import { useCallback } from 'react';
import { getSearchSortingValue } from '../../helpers/functions/search-params-helpers';

import { useHttp } from '../../tools/http/Http';
import {
    AddStagingUsersApiParams,
    AssignFiltersToStagingUsersApiParams,
    AssignTeamsToUserApiParams,
    CreateUserStagingApiParams,
    DeleteStagingApiParams,
    DeleteStagingUsersApiParams,
    DeleteUserApi,
    GetUserReportApiParams,
    InviteStagingUsersApiParams,
    LoadUserRolesApiParams,
    SearchCreatedByUsersListApiParams,
    SearchEditedByUsersListApiParams,
    SearchStagingDraftsApiParams,
    SearchStagingUsersApiParams,
    SearchUsersApiParams,
    UpdateStagingUsersApiParams,
    GetUserFeaturesApiParams,
    DeleteUserLabelsApiParams,
    UpdateUserInfoApiParams,
    UpdateUserPhoneNumberApiParams,
    ConfirmUserPhoneNumberApiParams,
    AssignUserLabelsApiParams,
    UpdateUserPasswordApiParams,
    RevokePendingUsersApiParams,
    GetUserApiParams,
    UpdateUserRoleParams,
    DeleteUserNumberApi,
    GetUserPermissionsApiParams,
    GetUserSettingsApiParams,
    SaveUserSettingsApiParams,
    SetUserPhotoApiParams,
    SearchUserEnrollmentsApiParams,
    ActivateUsersApiParams,
    GetUserPreferencesApiParams,
    UpdateUserPreferencesApiParams,
    CreateUsersApiParams,
} from './types';

/**
 * @function useGetUserApi
 * @returns { GetUserApiParams }
 */
export function useGetUserApi(): GetUserApiParams {
    const http = useHttp();
    return useCallback((userId) =>
        http.get(`users/${userId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useUpdateUserRole
 * @returns { UpdateUserRoleParams }
 */
export function useUpdateUserRole(): UpdateUserRoleParams {
    const http = useHttp();
    return useCallback((userId, params) =>
        http.put(`users/${userId}/role`, params,  true),
        [http]
    );
}

/**
 * @function useLoadUserRolesApi
 * @returns { LoadUserRolesApiParams }
 */
export const useLoadUserRolesApi = (): LoadUserRolesApiParams => {
    const http = useHttp();
    return useCallback(() => http.get('roles'), [http]);
};

/**
 * @function useSearchUserEnrollmentsApi
 * @returns { SearchUserEnrollmentsApiParams }
 */
export function useSearchUserEnrollmentsApi(): SearchUserEnrollmentsApiParams {
    const http = useHttp();
    return useCallback(
        (userId, params) => {
            const searchParams = params || {
                sort: getSearchSortingValue({}, 'assigned', false),
                limit: 10000
            };
            return http.get(`users/${userId}/enrollments`, searchParams, undefined, true);
        },
        [http]
    );
}

/**
 * @function useSearchUsersApi
 * @returns { SearchUsersApiParams }
 */
export function useSearchUsersApi(): SearchUsersApiParams {
    const http = useHttp();
    return useCallback(
        searchParams => http.get('users', searchParams, undefined, true),
        [http]
    );
}

/**
 * @function useCreateUsersApi
 * @returns { CreateUsersApiParams }
 */
export function useCreateUsersApi(): CreateUsersApiParams {
    const http = useHttp();
    return useCallback(
        usersData => http.post('users', usersData, true),
        [http]
    );
}


/**
 * @function useDeleteUserApi
 * @returns { DeleteUserApi }
 */
export function useDeleteUserApi(): DeleteUserApi {
    const http = useHttp();
    return useCallback(userIds => http.delete(`/users`, userIds, true), [http]);
}

/**
 * @function useDeleteUserNumberApi
 * @returns { DeleteUserNumberApi }
 */
export function useDeleteUserNumberApi(): DeleteUserNumberApi {
    const http = useHttp();
    return useCallback(userIds => http.delete(`/users/phone`, userIds, true), [http]);
}

/**
 * @function useGetUserReportApi
 * @returns { GetUserReportApiParams }
 */
export function useGetUserReportApi(): GetUserReportApiParams {
    const http = useHttp();
    return useCallback(
        searchParams => {
            return http.get('users/report', searchParams, undefined, true);
        },
        [http]
    );
}

/**
 * @function useSearchStagingDraftsApi
 * @returns { SearchStagingDraftsApiParams }
 */
export function useSearchStagingDraftsApi(): SearchStagingDraftsApiParams {
    const http = useHttp();
    return useCallback(
        searchParams => http.post('staging/search', searchParams),
        [http]
    );
}

/**
 * @function useDeleteStagingApi
 * @returns { DeleteStagingApiParams }
 */
export function useDeleteStagingApi(): DeleteStagingApiParams {
    const http = useHttp();
    return useCallback(
        (stagingIds, search, selectAll): Promise<any> =>
            http.delete(`/staging`, {
                stagingIds: stagingIds,
                search,
                selectAll,
            }),
        [http]
    );
}

/**
 * @function useSearchCreatedByUsersListApi
 * @returns { SearchCreatedByUsersListApiParams }
 */
export function useSearchCreatedByUsersListApi(): SearchCreatedByUsersListApiParams {
    const http = useHttp();
    return useCallback(
        searchParams =>
            http.post(`/staging/load-created-filters-data`, searchParams),
        [http]
    );
}

/**
 * @function useSearchEditedByUsersListApi
 * @returns { SearchEditedByUsersListApiParams }
 */
export function useSearchEditedByUsersListApi(): SearchEditedByUsersListApiParams {
    const http = useHttp();
    return useCallback(
        searchParams =>
            http.post(`/staging/load-edited-filters-data`, searchParams),
        [http]
    );
}

/**
 * @function useAssignTeamsToUserApi
 * @returns { AssignTeamsToUserApiParams }
 */
export function useAssignTeamsToUserApi(): AssignTeamsToUserApiParams {
    const http = useHttp();
    return useCallback(
        (userId, addedTeamIds, deletedTeamIds) =>
            http.put(`users/${userId}/manage-teams`, {
                addedTeamIds,
                deletedTeamIds,
            }),
        [http]
    );
}

/**
 * @function useCreateUserStagingApi
 * @returns { CreateUserStagingApiParams }
 */
export function useCreateUserStagingApi(): CreateUserStagingApiParams {
    const http = useHttp();
    return useCallback(
        (name, users) => http.post('staging', { name, users }),
        [http]
    );
}

/**
 * @function useSearchStagingUsersApi
 * @returns { SearchStagingUsersApiParams }
 */
export function useSearchStagingUsersApi(): SearchStagingUsersApiParams {
    const http = useHttp();
    return useCallback(
        (draftId, searchParams) =>
            http.post(`staging/${draftId}/user/search`, searchParams),
        [http]
    );
}

/**
 * @function useAddStagingUsersApi
 * @returns { AddStagingUsersApiParams }
 */
export function useAddStagingUsersApi(): AddStagingUsersApiParams {
    const http = useHttp();
    return useCallback(
        (stagingId, users) =>
            http.post(`staging/${stagingId}/user`, { users: users }),
        [http]
    );
}

/**
 * @function useUpdateStagingUsersApi
 * @returns { UpdateStagingUsersApiParams }
 */
export function useUpdateStagingUsersApi(): UpdateStagingUsersApiParams {
    const http = useHttp();
    return useCallback(
        (stagingId, stagingUserId, user) =>
            http.put(`staging/${stagingId}/user/${stagingUserId}`, user),
        [http]
    );
}

/**
 * @function useDeleteStagingUsersApi
 * @returns { DeleteStagingUsersApiParams }
 */
export function useDeleteStagingUsersApi(): DeleteStagingUsersApiParams {
    const http = useHttp();
    return useCallback(
        (stagingId, stagingUserIds, search, selectAll) =>
            http.delete(`/staging/${stagingId}/user`, {
                stagingUserIds: stagingUserIds,
                search,
                selectAll,
            }),
        [http]
    );
}

/**
 * @function useInviteStagingUsersApi
 * @returns { InviteStagingUsersApiParams }
 */
export function useInviteStagingUsersApi(): InviteStagingUsersApiParams {
    const http = useHttp();
    return useCallback(
        (stagingId, stagingUserIds, selectAll, search) =>
            http.post(`/staging/${stagingId}/user/invite`, {
                stagingUserIds: stagingUserIds,
                selectAll: selectAll,
                search,
            }),
        [http]
    );
}

/**
 * @function useAssignFiltersToStagingUsersApi
 * @returns { AssignFiltersToStagingUsersApiParams }
 */
export function useAssignFiltersToStagingUsersApi(): AssignFiltersToStagingUsersApiParams {
    const http = useHttp();
    return useCallback(
        (stagingId, assignFilters) =>
            http.post(`/staging/${stagingId}/user/assign-data`, assignFilters),
        [http]
    );
}

/**
 * @function useGetUserFeaturesApi
 * @returns { GetUserFeaturesApiParams }
 */
export function useGetUserFeaturesApi(): GetUserFeaturesApiParams {
    const http = useHttp();
    return useCallback(
        (userId: number) =>
            http.get(`users/${userId}/features`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useAssignUserLabelsApi
 * @returns { AssignUserLabelsApiParams }
 */
export function useAssignUserLabelsApi(): AssignUserLabelsApiParams {
    const http = useHttp();
    return useCallback(
        labels => http.post('users/labels', labels, true),
        [http]
    );
}

/**
 * @function useDeleteUserLabelsApi
 * @returns { DeleteUserLabelsApiParams }
 */
export function useDeleteUserLabelsApi(): DeleteUserLabelsApiParams {
    const http = useHttp();
    return useCallback(
        labels => http.delete('users/labels', labels, true),
        [http]
    );
}

/**
 * @function useUpdateUserInfo
 * @returns { UpdateUserInfoApiParams }
 */
export function useUpdateUserInfo(): UpdateUserInfoApiParams {
    const http = useHttp();
    return useCallback(
        (userId, newUserInfo) => http.put(`users/${userId}`, newUserInfo, true),
        [http]
    );
}

/**
 * @function useUpdateUserPhoneNumber
 * @returns { UpdateUserPhoneNumberApiParams }
 */
export function useUpdateUserPhoneNumber(): UpdateUserPhoneNumberApiParams {
    const http = useHttp();
    return useCallback(
        (userId, phoneNumber) =>
            http.put(`users/${userId}/phone`, { phone: phoneNumber }, true),
        [http]
    );
}

/**
 * @function useConfirmUserPhoneNumber
 * @returns { ConfirmUserPhoneNumberApiParams }
 */
export function useConfirmUserPhoneNumber(): ConfirmUserPhoneNumberApiParams {
    const http = useHttp();
    return useCallback(
        (userId, code) =>
            http.post(`users/${userId}/phone/confirm`, { code }, true),
        [http]
    );
}

/**
 * @function useUpdateUserPassword
 * @returns { UpdateUserPasswordApiParams }
 */
export function useUpdateUserPassword(): UpdateUserPasswordApiParams {
    const http = useHttp();
    return useCallback(
        (userId, password) =>
            http.post(`users/${userId}/password`, password, true),
        [http]
    );
}

/**
 * @function useRevokePendingUsers
 * @returns { RevokePendingUsersApiParams }
 */
export function useRevokePendingUsers(): RevokePendingUsersApiParams {
    const http = useHttp();
    return useCallback(
        userIds => http.post(`users/invite/revoke`, userIds, true),
        [http]
    );
}
/**
 * @function useGetUserPermissionsApi
 * @returns { GetUserPermissionsApiParams }
 */
 export function useGetUserPermissionsApi(): GetUserPermissionsApiParams {
    const http = useHttp();
    return useCallback(
        (userId: number) =>
            http.get(`users/${userId}/permissions`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useGetUserSettingsApi
 * @returns { GetUserSettingsApiParams }
 */
 export function useGetUserSettingsApi(): GetUserSettingsApiParams {
    const http = useHttp();
    return useCallback(
        (userId: number) =>
            http.get(`users/${userId}/settings`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useSaveUserSettingsApi
 * @returns { SaveUserSettingsApiParams }
 */
 export function useSaveUserSettingsApi(): SaveUserSettingsApiParams {
    const http = useHttp();
    return useCallback(
        (userId: number, labels: number[] | null) =>
            http.post(`users/${userId}/settings`, labels, true),
        [http]
    );
}
/**
 * @function useSetUserPhotoApi
 * @returns { SetUserPhotoApiParams }
 */
 export function useSetUserPhotoApi(): SetUserPhotoApiParams {
    const http = useHttp();
    return useCallback(
        (userId, mediaId) => http.post(`users/${userId}/picture`, { mediaId }, true), [http]);
}

/**
 * @function useActivateUsersApi
 * @returns { ActivateUsersApiParams }
 */
export function useActivateUsersApi(): ActivateUsersApiParams {
    const http = useHttp();
    return useCallback(
        userIds => http.put(`users/activate`, userIds, true),
        [http]
    );
}

/**
 * @function useGetUserPreferencesApi
 * @returns { GetUserPreferencesApiParams }
 */
export function useGetUserPreferencesApi(): GetUserPreferencesApiParams {
    const http = useHttp();
    return useCallback(
        userId => http.get(`users/${userId}/preferences`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useUpdateUserPreferencesApi
 * @returns { UpdateUserPreferencesApiParams }
 */
export function useUpdateUserPreferencesApi(): UpdateUserPreferencesApiParams {
    const http = useHttp();
    return useCallback(
        (userId, preferences) => http.patch(`users/${userId}/preferences`, preferences, true),
        [http]
    );
}