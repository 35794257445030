import { useCallback } from 'react';
import { useHttp } from '../../tools/http/Http';
import { GetAuditLogsApiParams, SearchLogsApiParams } from './types';

/**
 * @function useSearchLogsApi
 * @returns { SearchLogsApiParams }
 */
export const useSearchLogsApi = (): SearchLogsApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams => http.post('companies/logs', searchParams),
        [http]
    );
};

/**
 * @function useSearchAdminLogsApi
 * @returns { SearchLogsApiParams }
 */
export function useSearchAdminLogsApi(): SearchLogsApiParams {
    const http = useHttp();
    return useCallback(
        searchParams => http.post('admin/logs/search', searchParams),
        [http]
    );
}

/**
 * @function useGetAuditLogs
 * @returns { GetAuditLogsApiParams }
 */
export function useGetAuditLogsApi(): GetAuditLogsApiParams {
    const http = useHttp();
    return useCallback(
        params => http.get('audits', params, undefined, true),
        [http]
    );
}