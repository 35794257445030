import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @function useQueryParamListener
 * @param { string } paramName
 * @param { Function} callback
 * @returns { void }
 */
export default function useQueryParamListener(
    paramName: string,
    callback: (paramValue: string | null) => void
) {
    const location = useLocation();
    const paramValueRef = useRef<string | null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramValue = queryParams.get(paramName);

        if (paramValue !== paramValueRef.current) {
            paramValueRef.current = paramValue;
            callback(paramValue);
        }
    }, [callback, location.search, paramName]);
}