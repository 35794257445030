import { ColorTheme } from './type';
import { Variables } from '../../../theme/variables';

export const Themes: Record<
    ColorTheme,
    {
        background: string;
        color: string;
        labelColor?: string;
        disabledLabelColor?: string;
    }
> = {
    light: {
        background: Variables.Colors.whiteFive,
        color: Variables.Colors.black,
    },
    lightTwo: {
        background: Variables.Colors.white,
        color: Variables.Colors.black,
        labelColor: Variables.Colors.cloudyBlue,
        disabledLabelColor: Variables.Colors.cloudyBlue,
    },
    dark: {
        background: Variables.Colors.darkTwo,
        color: Variables.Colors.whiteTwo,
    },
    darkTwo: {
        background: Variables.Colors.dark,
        color: Variables.Colors.white,
    },
    darkThree: {
        background: Variables.Colors.darkTwo,
        color: Variables.Colors.whiteTwo,
        labelColor: Variables.Colors.cloudyBlue,
    },
    transparent: {
        background: Variables.Colors.transparent,
        color: Variables.Colors.black,
    },
};

