import styled from 'styled-components';

export const HeaderContainer = styled.div<{
    hasBorder?: boolean;
    headerPadding?: string;
    minHeight?: string;
}>`
    font-size: 16px;
    background: ${props => props.theme.Colors.white};
    ${props =>
        !!props.hasBorder &&
        `border-bottom: 2px solid ${props.theme.Colors.paleGrey}`};
    flex-direction: column;
    ${props => !!props.headerPadding && `padding: ${props.headerPadding};`};
    ${props => !!props.minHeight && `min-height: ${props.minHeight}`};
`;

export const SubHeaderContainer = styled.div<{ hasBackButton: boolean }>`
    margin-left: ${props => props.hasBackButton ? '18px' : '0'};
`;

export const NavigationPanel = styled.div<{ useReducedHeight?: boolean }>`
    display: flex;
    width: 100%;
    align-items: flex-start;
    padding: 24px 0 0 20px;
    ${props => (
        typeof props.useReducedHeight === 'undefined'
            ? ''
            : props.useReducedHeight ? 'min-height: 112px' : 'min-height: 119px'
    )};
`;

export const Toolbar = styled.div``;

export const SecondaryNavigation = styled.div`
    width: 100%;
`;

export const LeftPanel = styled.div`
    flex-grow: 1;
    flex: 2;
`;

export const TitleContainer = styled.div`
    font-weight: 600;
    display: flex;
    align-items: center;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const TitleSection = styled.div<{ useTopPadding: boolean }>`
    padding-top: ${props => (props.useTopPadding ? '4px' : '0px')};
`;
export const MainTitle = styled.div`
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    line-height: 24px;
`;

export const SubTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${props => props.theme.Colors.black};
    min-height: 28px;
    line-height: 32px;
`;

export const RightPanel = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
`;

export const CompanySectionWrapper = styled.div`
    border-radius: 4px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
`;

export const TitleIcon = styled.div`
    width: 13px;
    color: ${props => props.theme.Colors.black};
    margin-right: 4px;
    left: -16px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-self: flex-end;
    margin-bottom: 8px;
    &:hover {
        color: ${props => props.theme.Colors.steelGrey};
    }
    &:active {
        color: ${props => props.theme.Colors.cloudyBlue};
    }
`;
