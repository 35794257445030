import React from 'react';
import styled from 'styled-components';
import authBackground from '../../../../assets/images/auth-background.png';
import authBackground2x from '../../../../assets/images/auth-background2x.png';
import authBackground3x from '../../../../assets/images/auth-background3x.png';
import ROUTES from '../../../../routes/routes';
import { useHistory } from 'react-router';
import LandingCard from './LandingCard';
import CardContent from '../../Component/CardContent';
import LinkText from '../../../../ui/components/LinkText/link-text';
import PractisLogo from '../../../../ui/icons/PractisLogo';
import { isAdminPortal } from '../../../../helpers/functions/general';
import PractisAdminPlatformLogo from '../../../../ui/icons/PractisAdminPlatformLogo';

const LeftContentContainer = styled.div`
    flex: 3;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const AuthBackground = styled.img`
    height: 90%;
    right: 0;
    bottom: 0;
    position: absolute;
`;

const StyledHeader = styled.div`
    height: 72px;
`;

const StyledHeaderContent = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
`;

const StyledLogo = styled.div`
    height: 24px;
    width: 94px;
    color: ${props => props.theme.Colors.white};
`;

const StyledLinkText = styled(LinkText)`
    font-size: 13px;
    font-weight: bold;
`;

const LeftPanel = () => {
    const history = useHistory();

    const handleLogin = () => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    };

    return (
        <LeftContentContainer>
            <AuthBackground
                src={authBackground}
                srcSet={`${authBackground2x} 2x, ${authBackground3x} 3x`}
            />
            <StyledHeader>
                <StyledHeaderContent>
                    <StyledLogo>
                        {isAdminPortal() ?
                            <PractisAdminPlatformLogo />
                            :
                            <PractisLogo />}
                    </StyledLogo>
                    <StyledLinkText onClick={handleLogin}>
                        Log In
                    </StyledLinkText>
                </StyledHeaderContent>
            </StyledHeader>
            <CardContent
                card={LandingCard}
                counterHeight={8}
                counterWidth={64}
                counterMargin={16}
            />
        </LeftContentContainer>
    );
};

export default LeftPanel;
