import dayjs from 'dayjs';
import { useMemo } from 'react';
import { ReportingControlPanelProps } from './types';
import ReportingControlPanelView from './view';

function ReportingControlPanelController({
    mode,
    userEmail,
    onSubmit,
    onClear,
    remainingTimeout,
}: ReportingControlPanelProps) {
    const submitText = useMemo(() => {
        switch (mode) {
            case 'loading':
                return '';
            case 'noData':
                return 'No data for a report';
            default:
                if (mode === 'timeout' && remainingTimeout) {
                    const time = dayjs()
                        .startOf('day')
                        .add(remainingTimeout, 'second')
                        .format('mm:ss');
                    return `Generate in ${time}`;
                }
                return 'Generate';
        }
    }, [mode, remainingTimeout]);

    return (
        <ReportingControlPanelView
            isSubmitDisabled={mode !== 'enabled'}
            isSubmitLoading={mode === 'loading'}
            submitText={submitText}
            onSubmit={onSubmit}
            isClearDisabled={mode === 'disabled'}
            clearText="Clear"
            onClear={onClear}
            userEmail={userEmail}
        />
    );
}

export default ReportingControlPanelController;