import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { ModifyCase, useChallengeState } from '../../store/states';
import { Challenge } from '../../../../constants/interfaces/Challenge';
import {
    useModifyChallengeService,
    useResetChallengeLogoService,
} from '../../store/services';
import { EditModeContext } from '../../pages/EditChallenge';
import { EditModeValues } from '../../../../constants/enums/EditModeValues';
import { Variables } from '../../../../theme/variables';
import TextAreaBox from '../../../../ui/components/TextAreaBox/TextAreaBox';

const StyledScenarioInfo = styled.div`
    flex: 2;
    margin-right: 32px;
    padding-top: 24px;
`;

const StyledScenarioInfoContent = styled.div`
    border-radius: 4px;
`;

const StyledInputDivs = styled.div``;

const ChallengeInfo: FC<{
    challenge: Challenge;
    modified: ModifyCase;
    modifyChallenge: (value: any, field: keyof Challenge) => any;
    onRefreshLogo?: () => void;
}> = ({ challenge, modifyChallenge }) => {
    const edit = useContext(EditModeContext);

    return (
        <StyledScenarioInfo>
            <StyledScenarioInfoContent>
                <StyledInputDivs>
                    <TextAreaBox
                        background={Variables.Colors.whiteFive}
                        value={challenge && challenge.description}
                        onChange={(e: any) =>
                            modifyChallenge(e.target.value, 'description')
                        }
                        name="description"
                        placeholder="Description"
                        max={500}
                        minRows={7.5}
                        padding="12px 16px"
                        readOnly={edit.mode === EditModeValues.VIEW}
                        dataTest='chanllenge-description'
                    />
                </StyledInputDivs>
            </StyledScenarioInfoContent>
        </StyledScenarioInfo>
    );
};

export const ChallengeInfoContainer: FC<{
    challenge: Challenge;
}> = ({ challenge }) => {
    const modified = useChallengeState().case;

    const modifyChallenge = useModifyChallengeService();
    const resetChallengeLogo = useResetChallengeLogoService();

    return (
        <ChallengeInfo
            challenge={challenge}
            modified={modified}
            modifyChallenge={modifyChallenge}
            onRefreshLogo={resetChallengeLogo}
        />
    );
};

export default ChallengeInfoContainer;
