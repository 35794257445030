import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelectLabels } from "../../../../portableLabels/store/hors/withLabels/services";
import { useSetFilterHeaderSearchActive } from "../../../searchable-filter-header/store/services";
import { SearchableFilterHeaderKey } from "../../../searchable-filter-header/store/types";
import { disableLabelsFilter } from "./actions";

export const useClearLabelsFilter = () => {
    const selectLabels = useSelectLabels();
    const setLabelsSearchActive = useSetFilterHeaderSearchActive(SearchableFilterHeaderKey.LABELS);

    return useCallback(() => {
        selectLabels([]);
        setLabelsSearchActive(false);
    }, [selectLabels, setLabelsSearchActive]);
};

export const useDisableLabelsFilter = () => {
    const dispatch = useDispatch();

    return useCallback((isDisabled: boolean) => {
        dispatch(disableLabelsFilter(isDisabled));
    }, [dispatch]);
}