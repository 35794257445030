import * as Yup from 'yup';

import Validation from '../../../services/validation';

export const RegisterSchema = Yup.object().shape({
    invitationCode: Validation.invitationCode.required(
        'Invitation Code is required'
    ),
});

