import React, { FC, useState } from 'react';
import { kebabCase } from 'lodash';
import {
    filterInvitationRolesByPermissions,
    getUserRoleNameById,
} from '../tools';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ClickAwayListener } from '@material-ui/core';
import { usePermissionsState } from '../../../../features/permissions/store/state';
import { Variables } from '../../../../theme/variables';
import { useUserRolesState } from '../../../../features/roles/store/states';
import RadioButton from '../../../../ui/components/RadioButton';
import OutsideActionBox from '../../../../ui/components/OutsideActionBox/OutsideActionBox';

const StyledSelectForm = styled.div<{
    disabled?: boolean;
    minWidth?: string;
    height?: number;
    error?: boolean;
    border?: boolean;
}>`
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    width: 129px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    padding-right: 15px;
    height: ${props => (props.height ? `${props.height}px` : '100%')};
    background: ${props => props.theme.Colors.white};
    color: ${props => props.theme.Colors.black};
    &.is-disabled {
        color: ${props => props.theme.Colors.cloudyBlue};
        pointer-events: none;
    }
`;

const StyledLabelWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const SelectContainer = styled(OutsideActionBox)`
    position: relative;
`;

const SelectTitle = styled.div`
    pointer-events: none;
    font-size: 11px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: block;
    align-items: center;
    color: ${props => props.theme.Colors.cloudyBlue};
    font-weight: 600;
    margin-left: 15px;
`;

const SelectLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const SelectLabel = styled.div<{ color?: string }>`
    pointer-events: none;
    font-size: 13px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.color ? props.color : props.theme.Colors.cloudyBlue};
    font-weight: 500;
    margin-left: 15px;
    margin-bottom: 1px;
`;

const RolesContainer = styled(motion.div)`
    padding: 24px 24px 16px 24px;
    width: 129px;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    top: 8px;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
    position: absolute;
`;

const StyledRolesList = styled.div``;

const ScrollableRoleContainer = styled.div``;

const RoleItemWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 8px;
`;

const ANIMATION_SCALE = 5;
const ROLE_PLACEHOLDER = 'Role*';

export const AssignRoles: FC<{
    disabled?: boolean;
    assignedRole: number | null;
    handleRoleCheck: any;
    dataTest: string;
}> = ({ disabled, assignedRole, handleRoleCheck, dataTest }) => {
    const [show, setShow] = useState(false);
    const permissions = usePermissionsState();
    const userRoles = useUserRolesState();
    const roles = filterInvitationRolesByPermissions(
        userRoles.list,
        permissions
    );
    const assignedRoleName = getUserRoleNameById(assignedRole, userRoles.list);

    const updateCheckedRole = (value: number) => {
        setShow(false);
        handleRoleCheck(value);
    };

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <StyledSelectForm
                disabled={disabled}
                border={show || !!assignedRole}
            >
                <StyledLabelWrapper
                    onClick={() => setShow(!show)}
                    data-test={dataTest}
                >
                    {assignedRoleName && (
                        <SelectTitle>{ROLE_PLACEHOLDER}</SelectTitle>
                    )}
                    <SelectLabelWrapper>
                        <SelectLabel
                            color={
                                !assignedRoleName || disabled
                                    ? Variables.Colors.cloudyBlue
                                    : Variables.Colors.black
                            }
                        >
                            {assignedRoleName || ROLE_PLACEHOLDER}
                        </SelectLabel>
                    </SelectLabelWrapper>
                </StyledLabelWrapper>
                <SelectContainer open={show && !disabled}>
                    <RolesContainer
                        initial={{ scale: 1 / ANIMATION_SCALE }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                    >
                        <StyledRolesList>
                            <ScrollableRoleContainer>
                                {roles &&
                                    (roles || []).map(role => (
                                        <RoleItemWrapper
                                            key={role.name}
                                            onClick={() =>
                                                updateCheckedRole(role.value)
                                            }
                                        >
                                            <RadioButton
                                                checked={
                                                    assignedRole === role.value
                                                }
                                                size={12}
                                                handleChange={() =>
                                                    updateCheckedRole(
                                                        role.value
                                                    )
                                                }
                                                label={role.name}
                                                dataTest={`${kebabCase(role.name.toLowerCase())}-role-radio`}
                                            />
                                        </RoleItemWrapper>
                                    ))}
                            </ScrollableRoleContainer>
                        </StyledRolesList>
                    </RolesContainer>
                </SelectContainer>
            </StyledSelectForm>
        </ClickAwayListener>
    );
};
