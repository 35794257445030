import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep, flatten, isEmpty, update as lodashUpdate } from 'lodash';

import {
    calculatePercentage,
    convertObjectToArrayOfValues,
    handleCreateChunks,
} from './helpers';
import {
    ActionFunctionOptions,
    ChunkRequestActionInterface,
    ChunkRequestsServiceReturnType,
} from './types';
import { ITEM_PER_CHUNK_SIZE } from './constants';
import { setPercentage } from '../store/actions';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';

/**
 *
 * @param { ActionFunctionType } actionFunction
 * @param { Record<string, unknown> } actionFunctionOptions -- fieldName can be nested. ex: fieldName: 'teams', ex: fieldName: 'teams.addedMembers'
 * @param { Function } onSuccess
 * @param { Function } onError
 * @param { boolean } shouldStopOnError
 * @returns { ChunkRequestsServiceReturnType }
 */
export function useChunkRequestsService<ParametersType>(
    actionList: ChunkRequestActionInterface<ParametersType>[],
    onSuccess?: (responses?: Record<string, unknown>) => void,
    onError?: (
        error?: ErrorResult,
        completedResponses?: Record<string, unknown>
    ) => void,
    shouldStopOnError = true
): ChunkRequestsServiceReturnType {
    const dispatch = useDispatch();

    const [isStopped, setIsStopped] = useState<boolean>(true);

    const isStoppedRef = useRef(true);
    const hasErrorRef = useRef(false);
    const responsesRef = useRef<Record<string, unknown>>({});
    const isIterationFinished = useRef(false);

    /**
     * @function handleStartAction
     * @returns { void }
     */
    const handleStartAction = (): void => {
        isStoppedRef.current = false;
        responsesRef.current = {};
        isIterationFinished.current = false;
        handleExecuteActions();
    };

    /**
     * @function handleStopAction
     * @returns { void }
     */
    const handleStopAction = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            isStoppedRef.current = true;

            setTimeout(() => {
                dispatch(setPercentage(0));
            }, 1000);
        });
    }, [dispatch]);

    /**
     * @function handleCallAsyncChunks
     * @param { ChunkRequestActionInterface<ParametersType> } chunkedActionList
     * @param { boolean } isFromChildAction
     * @returns { Promise<void> }
     */
    const handleCallAsyncChunks = async (
        chunkedActionList: ChunkRequestActionInterface<ParametersType>[],
        isFromChildAction = false
    ): Promise<void> => {
        if (chunkedActionList) {
            const chunkedActionListLength = chunkedActionList.length;

            for (let index = 0; index < chunkedActionListLength; index++) {
                // to stop the loop if action is stopped
                if (isStoppedRef.current) {
                    break;
                } else {
                    const {
                        actionName,
                        actionFunction,
                        actionFunctionOptions,
                        childActionList = [],
                    } = chunkedActionList[index];

                    const actionFunctionParameters =
                        convertObjectToArrayOfValues(
                            actionFunctionOptions as any
                        );

                    if (actionFunctionParameters) {
                        // use apply to change array to accepted parameters
                        // of function
                        // ex: ['954', {'id': 54, 'name': 'testUser'}] => (954, {'id': 54, 'name': 'testUser'})
                        await actionFunction
                            ?.apply?.(document, actionFunctionParameters)
                            ?.then(async (response: unknown) => {
                                // Pass chunked function responses to the
                                // final success callback.
                                responsesRef.current[actionName] = response;

                                // In some cases we can have child actions.
                                // Child Actions should be invoked after success of main action.
                                // We will pass the response of main action to the child actions.
                                if (!isEmpty(childActionList)) {
                                    // create list of chunks for child action.
                                    const childChunkedActionList =
                                        handleCreateChunkedActionList(
                                            childActionList.map(action => {
                                                const {
                                                    actionFunctionOptions:
                                                        childActionFunctionOptions,
                                                } = action; // destruct child action object parameters

                                                const {
                                                    parameters:
                                                        childActionParameters,
                                                    fieldName:
                                                        childActionFieldName,
                                                    secondaryFieldName:
                                                        childActionSecondaryFieldName,
                                                } = childActionFunctionOptions;

                                                // if the passed value is array of arrays, create chunks by it's inner value
                                                // otherwise create chunks by array itself.
                                                const childActionFieldNameValue =
                                                    Array.isArray(
                                                        (
                                                            childActionParameters as any
                                                        )[
                                                            childActionFieldName
                                                        ][0]
                                                    )
                                                        ? (
                                                              childActionParameters as any
                                                          )[
                                                              childActionFieldName
                                                          ][index]
                                                        : (
                                                              childActionParameters as any
                                                          )[
                                                              childActionFieldName
                                                          ];

                                                const processedChildActionList =
                                                    {
                                                        ...action,
                                                        actionFunctionOptions: {
                                                            ...childActionFunctionOptions,
                                                            parameters: {
                                                                ...childActionParameters,
                                                                [childActionFieldName]:
                                                                    childActionFieldNameValue,
                                                                [childActionSecondaryFieldName!]:
                                                                    response, // Passing response to child action functions.
                                                            },
                                                        },
                                                    };

                                                return processedChildActionList;
                                            })
                                        );

                                    if (childChunkedActionList) {
                                        await handleCallAsyncChunks(
                                            childChunkedActionList,
                                            true
                                        );
                                    }
                                }

                                if (!isFromChildAction && !isStoppedRef.current) {
                                    dispatch(
                                        setPercentage(
                                            calculatePercentage(
                                                chunkedActionListLength,
                                                index + 1
                                            )
                                        )
                                    );
                                }
                            })
                            ?.catch((error: ErrorResult) => {
                                if (shouldStopOnError) {
                                    handleStopAction();
                                    onError?.(error, responsesRef.current);
                                    hasErrorRef.current = true;
                                } else {
                                    // Do not stop on errors.
                                    // Calculate percentage.
                                    dispatch(
                                        setPercentage(
                                            calculatePercentage(
                                                chunkedActionListLength,
                                                index + 1
                                            )
                                        )
                                    );

                                    onError?.(error, responsesRef.current);
                                }
                            });
                    }
                }

                // It's the last iteration of the main loop.
                if (
                    Math.abs(index - chunkedActionListLength) === 1 &&
                    !isFromChildAction
                ) {
                    isIterationFinished.current = true;
                }
            }

            // loop is done -- success section
            if (
                !hasErrorRef.current &&
                isIterationFinished.current &&
                !isStoppedRef.current
            ) {
                handleStopAction();
                onSuccess?.(responsesRef.current);
            }
        }
    };

    /**
     * @description To create chunked action list to be passes to async callable function.
     * @function handleCreateChunkedActionList
     * @param { ChunkRequestActionInterface<ParametersType>[] } actionList
     * @returns { ChunkRequestActionInterface<ParametersType>[] }
     */
    const handleCreateChunkedActionList = (
        actionList: ChunkRequestActionInterface<ParametersType>[]
    ): ChunkRequestActionInterface<ParametersType>[] => {
        const chunkedActionList = [];

        for (const currentAction of actionList) {
            const {
                actionName = '',
                actionFunction = () => {},
                childActionList = [],
                itemPerChunk = ITEM_PER_CHUNK_SIZE,
            } = currentAction || {};

            // to not mutate actionFunctionOptions -- as it's ref under the hood
            const clonedActionFunctionOptions = cloneDeep(
                currentAction?.actionFunctionOptions
            );

            if (!isEmpty(clonedActionFunctionOptions)) {
                const { parameters, fieldName } =
                    clonedActionFunctionOptions as ActionFunctionOptions<ParametersType>;

                const chunkedArrayOfSelectedItems = handleCreateChunks(
                    parameters as Object,
                    fieldName,
                    itemPerChunk
                );

                const newChunkedAction = chunkedArrayOfSelectedItems?.map(
                    (chunkedItem: any) => ({
                        ...{ actionName },
                        ...{ actionFunction },
                        ...{ childActionList },
                        itemPerChunk,
                        // modify the arguments of action function
                        // in every loop to pass chunked argument
                        // to the action function.
                        actionFunctionOptions: {
                            ...(lodashUpdate(
                                cloneDeep(parameters) as Object, // Clone parameters for Mutability
                                fieldName,
                                () => chunkedItem
                            ) as ActionFunctionOptions<ParametersType>),
                        },
                    })
                );

                if (newChunkedAction) {
                    chunkedActionList.push(newChunkedAction);
                }
            }
        }

        return flatten(chunkedActionList);
    };

    /**
     * @description -- handle execute multiple actions
     * @function handleExecuteActions
     * @returns { Promise<void>  }
     */
    const handleExecuteActions = async (): Promise<void> => {
        // clear error
        hasErrorRef.current = false;

        const chunkedActionList = handleCreateChunkedActionList(actionList);

        if (!isEmpty(chunkedActionList)) {
            await handleCallAsyncChunks(chunkedActionList);
        }
    };

    useEffect(() => {
        if (isStopped) {
            handleStopAction();
        } else {
            handleStartAction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStopped]);

    useEffect(() => {
        return () => {
            handleStopAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isStopped,
        setIsStopped,
    };
}