import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { PortableTeamLeadersList } from './PortableTeamLeadersList';
import { Button } from '../../../ui/components/Button';
import { Variables } from '../../../theme/variables';
import { filterTeamLeaders } from '../tools';
import EmptyTeamLeaders from './EmptyTeamLeaders';
import { TeamLeader } from '../../../constants/interfaces/TeamLeader';
import { TableSearchInput } from '../../../ui/components/table-wrapper/table-tools/table-search-input';

//region Types
type Action = 'clear' | 'select';

export type PortableTeamsProps = {
    teamLeaders?: TeamLeader[];
    selectedList: number[];
    onSave?: () => void;
    onSelectAll: (labelIds: number[]) => void;
    onSetSelected?: (leaderId: number) => void;
    resetSelected: () => void;
    filtersTitle?: string;
    loading?: boolean;
    showSelectActions?: boolean;
    showSearch?: boolean;
    showSave?: boolean;
    fetchOnMount?: boolean;
};
//endregion

//region Styles
const StyledCreateTeams = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const CreateTeamsBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const FiltersHeader = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
    flex-shrink: 0;
`;

const FiltersSavePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
    font-weight: 600;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;
//endregion

const PortableTeamLeaders: FC<PortableTeamsProps> = ({
    teamLeaders,
    selectedList,
    loading,
    onSave,
    onSelectAll,
    onSetSelected,
    resetSelected,
    filtersTitle,
    showSelectActions,
    showSearch,
    showSave = true,
}) => {
    const actionConfig: Record<
        Action,
        {
            color: string;
            colorHover: string;
            colorActive: string;
        }
    > = {
        clear: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
        select: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
    };

    const [searchTerm, setSearchTerm] = useState('');
    const searchHandler = (value: string) => setSearchTerm(value);
    const [processedSelectedList, setProcessedSelectedList] = useState<
        number[]
    >([]);

    const clearHandler = useCallback(() => {
        onSelectAll([]);
    }, [onSelectAll]);

    const processedTeamLeaders = useMemo((): TeamLeader[] => {
        if (teamLeaders) {
            const result = filterTeamLeaders(searchTerm, teamLeaders);
            if (result.length > 0) {
                const processedDataIds = result.map(item => item.id!);
                setProcessedSelectedList(
                    selectedList.filter(item => processedDataIds.includes(item))
                );
            }

            return result;
        } else {
            return [];
        }
    }, [searchTerm, teamLeaders, selectedList]);

    const isAllSelected = useMemo(
        () => selectedList.length === teamLeaders?.length,
        [selectedList.length, teamLeaders]
    );

    const toggleAllHandler = useCallback(() => {
        if (isAllSelected) {
            onSelectAll([]);
        } else if (onSelectAll) {
            onSelectAll(
                processedTeamLeaders
                    ? processedTeamLeaders.map(leader => leader.id)
                    : []
            );
        }
    }, [isAllSelected, onSelectAll, processedTeamLeaders]);

    useEffect(() => {
        return () => {
            resetSelected();
        };
    }, [resetSelected]);

    useEffect(() => {
        if (
            !!searchTerm &&
            processedSelectedList.length !== selectedList.length
        ) {
            toggleAllHandler();
        }
    }, [searchTerm, toggleAllHandler, processedSelectedList, selectedList, processedTeamLeaders]);

    const showNoItems = !teamLeaders?.length;
    const showEmptyState = !processedTeamLeaders.length || loading;
    const selectedTeamLeadersLength = processedSelectedList.filter(id => id > 0)
        .length;

    return (
        <StyledCreateTeams>
            <CreateTeamsBody>
                {filtersTitle && (
                    <FiltersHeader>
                        <FilterTitle>{filtersTitle}</FilterTitle>
                    </FiltersHeader>
                )}
                {showSearch && (
                    <SearchWrapper>
                        <TableSearchInput
                            onChange={searchHandler}
                            disabled={showNoItems}
                        />
                    </SearchWrapper>
                )}
                {showSelectActions && !showNoItems && (
                    <SelectActions>
                        <div>
                            <SelectedCaption>
                                {selectedTeamLeadersLength > 0
                                    ? `${selectedTeamLeadersLength} ${
                                          selectedTeamLeadersLength > 1
                                              ? ' Team Leaders selected'
                                              : ' Team Leader selected'
                                      }`
                                    : 'No Team Leaders selected'}
                            </SelectedCaption>
                        </div>
                        <div>
                            {isAllSelected ? (
                                <PlainAction
                                    disabled={
                                        processedSelectedList.length === 0 ||
                                        showEmptyState
                                    }
                                    color={actionConfig.clear.color}
                                    colorActive={actionConfig.clear.colorActive}
                                    colorHover={actionConfig.clear.colorHover}
                                    onClick={clearHandler}
                                >
                                    Unselect All
                                </PlainAction>
                            ) : (
                                <PlainAction
                                    disabled={showEmptyState}
                                    color={actionConfig.select.color}
                                    colorActive={
                                        actionConfig.select.colorActive
                                    }
                                    colorHover={actionConfig.select.colorHover}
                                    onClick={toggleAllHandler}
                                >
                                    Select All
                                </PlainAction>
                            )}
                        </div>
                    </SelectActions>
                )}
                {showEmptyState ? (
                    <EmptyTeamLeaders
                        loading={loading}
                        isEmpty={!teamLeaders?.length}
                    />
                ) : (
                    <>
                        <PortableTeamLeadersList
                            teamLeaders={processedTeamLeaders}
                            selectedTeams={processedSelectedList}
                            setTeamFilters={onSetSelected}
                        />
                        {onSave && showSave && (
                            <FiltersSavePanel>
                                <Button
                                    type={'button'}
                                    label={'Save'}
                                    action={() => onSave()}
                                    height={'48px'}
                                    width={'160px'}
                                />
                            </FiltersSavePanel>
                        )}
                    </>
                )}
            </CreateTeamsBody>
        </StyledCreateTeams>
    );
};

export { PortableTeamLeaders };
