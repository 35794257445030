import React, { FC } from 'react';
import styled from 'styled-components';
import EmptyBox from '../../../ui/icons/EmptyBox';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import NoSearchResult from '../../../ui/icons/NoSearchResult';

const StyledEmptyScenarios = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const StyledEmptyScenariosContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoTeamsTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const EmptyScenarios: FC<{ loading?: boolean; isEmpty?: boolean }> = ({
    loading,
    isEmpty,
}) => {
    if (loading) {
        return (
            <StyledEmptyScenarios>
                <Loading />
            </StyledEmptyScenarios>
        );
    }

    return (
        <StyledEmptyScenarios>
            <StyledEmptyScenariosContent>
                <IconWrapper data-test={isEmpty ? 'no-scenarios-icon' : 'no-scenarios-found-icon'}>
                    {isEmpty ? <EmptyBox /> : <NoSearchResult />}
                </IconWrapper>
                <NoTeamsTitle data-test={isEmpty ? 'no-scenarios-text' : 'no-scenarios-found-text'}>
                    {isEmpty ? 'No Scenarios yet' : 'No Scenarios found'}
                </NoTeamsTitle>
            </StyledEmptyScenariosContent>
        </StyledEmptyScenarios>
    );
};

export default EmptyScenarios;
