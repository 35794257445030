import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Next = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 14 12"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g enableBackground="new">
                    <clipPath id="cp0">
                        <path
                            transform="matrix(1,0,0,-1,0,12)"
                            d="M 13.68551 5.622092 L 8.396375 1.150653 C 8.181871 .9487689 7.834833 .9487689 7.62033 1.150653 C 7.405826 1.352288 7.405826 1.678351 7.62033 1.879736 L 12.48445 5.99213 L 7.603031 10.11976 C 7.388528 10.3209 7.388528 10.64746 7.603031 10.8486 C 7.817535 11.05048 8.164572 11.05048 8.379076 10.8486 L 13.66821 6.377159 C 13.67486 6.370663 13.67194 6.360668 13.67806 6.354172 C 13.68019 6.352423 13.68338 6.353173 13.68524 6.351674 C 13.89975 6.150289 13.89975 5.823476 13.68551 5.622092 Z M 7.306026 5.622092 L .953955 .1512259 C .7394516 -.05040862 .3921475 -.05040862 .177644 .1512259 C -.03659334 .3523606 -.03659334 .6791734 .177644 .880558 L 6.112684 5.992379 L .1608776 11.11894 C -.05362587 11.32058 -.05362587 11.64714 .1608776 11.84877 C .375115 12.05041 .722419 12.05041 .9369225 11.84877 L 7.288994 6.377659 C 7.295647 6.371162 7.292986 6.361168 7.299107 6.354672 C 7.30097 6.352923 7.304429 6.353672 7.306026 6.352173 C 7.520264 6.150289 7.520264 5.823476 7.306026 5.622092 Z "
                        />
                    </clipPath>
                    <g clipPath="url(#cp0)">
                        <clipPath id="cp1">
                            <path
                                transform="matrix(1,0,0,-1,0,12)"
                                d="M -233 -86 L 142 -86 L 142 726 L -233 726 Z "
                            />
                        </clipPath>
                        <g clipPath="url(#cp1)">
                            <path
                                transform="matrix(1,0,0,-1,0,12)"
                                d="M -5 17 L 18.84615 17 L 18.84615 -5 L -5 -5 Z "
                                fill="currentColor"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default Next;
