import { useCallback } from 'react';
import { useLocalStorage } from '../../tools/localStorage/LocalStorage';

export const countParents = (path: string) => {
    return path
        ? path
              .trim()
              .split('/')
              .filter(x => x).length
        : 0;
};

export enum LABELS_CONFIGS {
    LABELS_STATE = 'labels-state',
    SIDE_PANEL = 'side-panel',
    EXPANDED_LABELS = 'expanded-labels',
}

export const useGenerateLabelsConfiguration = () => {
    const localStorage = useLocalStorage();

    return useCallback(
        (sidePanel: boolean, selectedLabels: number[]) => {
            let savedLabelsState: any = localStorage.get(
                LABELS_CONFIGS.LABELS_STATE
            );

            if (savedLabelsState && savedLabelsState) {
                savedLabelsState[LABELS_CONFIGS.SIDE_PANEL] = sidePanel;
                savedLabelsState[
                    LABELS_CONFIGS.EXPANDED_LABELS
                ] = selectedLabels;
            } else {
                savedLabelsState = {};
                savedLabelsState = {
                    [LABELS_CONFIGS.SIDE_PANEL]: sidePanel,
                    [LABELS_CONFIGS.EXPANDED_LABELS]: selectedLabels,
                };
            }

            localStorage.set(LABELS_CONFIGS.LABELS_STATE, savedLabelsState);
        },
        [localStorage]
    );
};

export const useGetSavedLabelPanelState = () => {
    const localStorage = useLocalStorage();

    return useCallback(() => {
        let savedLabelsState: any = localStorage.get(
            LABELS_CONFIGS.LABELS_STATE
        );

        if (savedLabelsState) {
            return savedLabelsState[LABELS_CONFIGS.SIDE_PANEL];
        } else {
            return false;
        }
    }, [localStorage]);
};

export const useGetSavedLabelExpandedState = () => {
    const localStorage = useLocalStorage();

    return useCallback(() => {
        let savedLabelsState: any = localStorage.get(
            LABELS_CONFIGS.LABELS_STATE
        );

        if (savedLabelsState) {
            return savedLabelsState[LABELS_CONFIGS.EXPANDED_LABELS];
        } else {
            return false;
        }
    }, [localStorage]);
};
