import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
    selectedItemsCount: number;
}

export const FiltersSelectedText: FC<Props> = props => {
    const { selectedItemsCount } = props;
    return (
        <Root data-test="selected-items-container">
            {selectedItemsCount} Selected
        </Root>
    );
};

const Root = styled.span`
    font-size: 11px;
    font-weight: 500;
    margin-right: 12px;
    color: ${props => props.theme.Colors.steelGrey};
`;