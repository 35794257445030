import React, { FC, useEffect } from 'react';
import { PaginationResult } from '../../../../../../../constants/interfaces/PaginationResult';
import { DraftUser } from '../../../../../../../constants/interfaces/Draft';
import { useCalculatePreSelectedPractisSets, useFindPractisSetDueDate } from '../../../../../../../features/portablePractisSets/tools';
import { PortablePractisSetsWithStore } from '../../../../../../../features/portablePractisSets';
import { useSelectMultiplePractisSets } from '../../../../../../../features/portablePractisSets/store/hors/withPractisSets/services';

const FilterPractisSets: FC<{
    users?: PaginationResult<DraftUser>;
    selectedUsers?: number[];
    setInitialPractisSets?(practisSetId: number[]): void;
}> = ({ users, selectedUsers, setInitialPractisSets }) => {
    const setPractisSets = useSelectMultiplePractisSets();
    const calculatePreSelectedPractisSets = useCalculatePreSelectedPractisSets();
    const findPractisSetDueDate = useFindPractisSetDueDate()

    useEffect(() => {
        if (users && users.items && selectedUsers && setInitialPractisSets) {
            const preSelectedPractisSets = calculatePreSelectedPractisSets(
                selectedUsers,
                users.items as any
            );
            setInitialPractisSets(preSelectedPractisSets);

            //PSWDD maybe change type
            setPractisSets(
                preSelectedPractisSets.map(id => ({
                    practisSetId: id,
                    dueDate: findPractisSetDueDate(
                        selectedUsers,
                        users.items as any,
                        id
                    ),
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, selectedUsers, calculatePreSelectedPractisSets]);

    return (
        <PortablePractisSetsWithStore
            showSave={false}
            showSelectActions={true}
            showSearch={true}
            resetSelectedOnUnmount={true}
        />
    );
};

export default FilterPractisSets;
