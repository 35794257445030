import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

export interface AuthState {
    token: string;
}

export type AuthStateSelector = (state: any) => AuthState;

export const AuthStateSelectorContext = React.createContext<AuthStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useAuthState(): AuthState {
    return useSelector(useContext(AuthStateSelectorContext));
}
