import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

export interface SocketProgressStateInterface {
    show?: boolean;
}

export type SocketProgressStateSelector = (
    state: any
) => SocketProgressStateInterface;

export const SocketProgressSelectorContext =
    React.createContext<SocketProgressStateSelector>(() => {
        throw new Error('Not implemented');
    });

export function SocketProgressState(): SocketProgressStateInterface {
    return useSelector(useContext(SocketProgressSelectorContext));
}