import React, {
    PropsWithChildren,
    ReactElement,
    ReactNode,
    RefObject,
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { PopoverOrigin } from '@material-ui/core/Popover';
import { SingletonPopup } from './helpers/useSingleton';
import { useDismiss } from '../../../helpers/hooks/useDismiss';
import { PopupComponent } from './PopupComponent';

export type PopupProps = {
    enabled: boolean;
    enable: () => void;
    disable: () => void;
    show: () => void;
    hide: () => void;
    toggleShown: () => void;
    shown: boolean;
};

type Props<T> = {
    content:
        | ReactNode
        | { (props: PopupProps, dismissProps: DismissProps): ReactNode };
    contentWidth?: string;
    padding?: string;
    children: {
        (
            ref: RefObject<T>,
            props: PopupProps,
            dismissProps: DismissProps
        ): ReactElement;
    };
    dismissKey?: string;
    hideOnOutsideClick?: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    singleton?: SingletonPopup<T>;
    arrow?: boolean;
    verticalOffset?: number;
    horizontalOffset?: number;
};

export type DismissProps = {
    dismiss: () => void;
};

/**
 * Absolute auto positioning Popup component
 * @param children render function with Ref object. Append that object on HTMLElement to trigger Popup
 * @param content content of Popup
 * @param dismissKey first time displaying based on useDismiss hook. Popup will always showing on component mount when the value is not dismissed
 * @param hideOnOutsideClick enable or disable hiding on click pout of content
 * @param arrow render arrow on the edge of content
 * @param padding
 * @param singleton
 * @param anchorPosition
 * @param verticalOffset adjust vertical position
 * @param horizontalOffset adjust horizontal position
 * @constructor
 */
export const Popup = <T extends HTMLElement = HTMLElement>({
    children,
    content,
    arrow = false,
    dismissKey,
    hideOnOutsideClick,
    padding,
    anchorOrigin,
    transformOrigin,
    singleton,
    verticalOffset,
    horizontalOffset,
}: PropsWithChildren<Props<T>>) => {
    const [shown, _setShown] = useState(false);

    const shownRef = useRef(shown);
    const setShown = (value: boolean) => {
        shownRef.current = value;
        _setShown(value);
    };
    
    const show = useCallback(() => {
        setShown(true);
    }, []);

    const hide = () => {
        setShown(false);
    };
    const toggleShown = () => {
        if (shownRef.current) {
            hide();
        } else {
            show();
        }
    };

    const [enabled, setEnabled] = useState(true);

    const enable = () => {
        setEnabled(true);
    };
    const disable = () => {
        hide();
        setEnabled(false);
    };

    const [dismissed, setDismissed] = useDismiss(dismissKey);
    useEffect(() => {
        if (dismissKey && !dismissed) {
            show();
        }
    }, [dismissKey, dismissed, show]);

    const dismissProps = useMemo<DismissProps>(() => {
        return {
            dismiss: setDismissed,
        };
    }, [setDismissed]);

    const innerTriggerRef = useRef<T>(null);

    const triggerRef = useMemo(() => {
        if (singleton) {
            return singleton.triggerRef;
        }
        return innerTriggerRef;
    }, [singleton]);

    const getChildren = () => {
        if (children) {
            return children(
                triggerRef,
                {
                    shown,
                    toggleShown,
                    hide,
                    show,
                    enabled,
                    disable,
                    enable,
                },
                dismissProps
            );
        }
        return null;
    };

    const getContent = () => {
        if (typeof content === 'function') {
            return content(
                {
                    shown,
                    toggleShown,
                    hide,
                    show,
                    enabled,
                    disable,
                    enable,
                },
                dismissProps
            );
        }
        return content;
    };

    return (
        <>
            {getChildren()}
            <PopupComponent
                triggerRef={triggerRef}
                open={shown && enabled && !dismissed}
                arrow={arrow}
                padding={padding}
                onDismiss={() => {
                    if (!hideOnOutsideClick) {
                        setShown(false);
                        if (dismissKey) {
                            setDismissed();
                        }
                    }
                }}
                anchorOrigin={
                    anchorOrigin || {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
                transformOrigin={
                    transformOrigin || {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                }
                verticalOffset={verticalOffset}
                horizontalOffset={horizontalOffset}
            >
                {getContent()}
            </PopupComponent>
        </>
    );
};
