import {
    ACTIONS,
    getReportsFailure,
    getReportsStart,
    getReportsSuccess
} from './actions';
import { ReportsState } from './states';

type ReportsActions =
    | ReturnType<typeof getReportsStart>
    | ReturnType<typeof getReportsSuccess>
    | ReturnType<typeof getReportsFailure>;

export const reportsReducer = (
    state = { isLoading: false },
    action: ReportsActions
): ReportsState => {
    switch(action.type) {
        case ACTIONS.GET_REPORTS_START:
            return {
                ...state,
                isLoading: true
            };
        case ACTIONS.GET_REPORTS_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: ''
            };
        case ACTIONS.GET_REPORTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}