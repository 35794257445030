import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoChartData = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 21 20"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <g>
                                <path
                                    d="M79.32 11.055V0h4v11.055h-4zm5.515 1.921l1.179 1.179-5.834 5.833-1.178-1.179 5.833-5.833zm-4.655 0l5.834 5.833-1.179 1.179-5.833-5.833 1.178-1.179zm1.807-11.643h-1.334v8.34h1.334v-8.34zm-9.334 1.334h4V20h-4V2.667zm1.333 16h1.334V4h-1.334v14.667zm-8-9.334h4V20h-4V9.333zm1.334 9.334h1.333v-8H67.32v8z"
                                    transform="translate(-713 -224) translate(472) translate(176 224)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoChartData;
