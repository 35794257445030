import * as Yup from 'yup';

import Validation from '../../../services/validation';

export const RegisterSchema = Yup.object().shape({
    password: Validation.password,
    confirmPassword: Validation.confirmPassword,
});

export const passwordHintText = 'Your password must be 8 characters long.';
