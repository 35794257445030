import { KeyboardArrowUp } from '@material-ui/icons';
import styled from 'styled-components';

export const Th = styled.th<{
    width?: number;
    clickable?: boolean;
    disabled?: boolean;
    isMultiple?: boolean;
}>`
    font-size: 13px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.black};
    font-weight: bold;
    padding: ${props =>
        props.isMultiple ? '12px 8px 12px 0' : '23px 8px 23px 0'};
    text-align: left;
    border-bottom: 1px solid ${props => props.theme.Colors.paleGrey};
    ${props => (props.width ? `width: ${props.width}%` : '')};
`;

export const Content = styled.div<{ clickable?: boolean }>`
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    pointer-events: none;
`;

export const Text = styled.div<{ clickable?: boolean }>`
    display: inline-flex;
    pointer-events: all;
    &:hover {
        opacity: ${props => (props.clickable ? 0.7 : 1)};
    }
    &:active {
        opacity: ${props => (props.clickable ? 0.7 : 1)};
    }
`;

export const MultiViewText = styled.div`
    display: flex;
    pointer-events: all;

    th {
        display: block;
        padding: 10px 0 0;
        border: none;
        text-align: center;
    }
`;

export const EmptyIcon = styled(KeyboardArrowUp)`
    visibility: hidden;
    width: 20px;
    height: 20px;
`;

export const IconContainer = styled.div<{ clickable?: boolean }>`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => (!props.clickable ? 'default' : 'pointer')};
    pointer-events: ${props => (props.clickable ? 'all' : 'none')};
`;

export const IconWrapper = styled.div`
    width: 8px;
    height: 8px;
    display: flex;
`;

export const EmptyArea = styled.span`
    width: 20px;
    height: 20px;
`;

export const QuestionIconWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
`;

export const TitleContainer = styled.div<{
    disabled?: boolean;
    titleWidth?: number;
}>`
    text-align: center;
    width: ${({ titleWidth }) => (titleWidth ? `${titleWidth}%` : '70%')};
    margin: 0 auto;
    border-bottom: 1px solid ${props => props.theme.Colors.paleGrey};
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.black};
`;
