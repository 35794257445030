import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Phone = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 12 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <g>
                                <path
                                    d="M247 28h-6c-1.656 0-3-1.343-3-3V15c0-1.657 1.344-3 3-3h6c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3zm2-13c0-1.103-.897-2-2-2h-6c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2V15zm-3.5-.002h-3c-.276 0-.5-.223-.5-.5 0-.275.224-.5.5-.5h3c.276 0 .5.225.5.5 0 .277-.224.5-.5.5zm-1.5 9c.552 0 1 .449 1 1 0 .553-.448 1-1 1s-1-.447-1-1c0-.551.448-1 1-1z"
                                    transform="translate(-1382 -324) translate(504 103) translate(640 209)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default Phone;
