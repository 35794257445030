import styled from 'styled-components';

export const StyledFormikField = styled.div`
    width: 100%;
    flex: 1;
`;

export const StyledErrorContainer = styled.div`
    color: ${props => props.theme.Colors.coral};
    font-weight: 500;
    font-size: 13px;
    padding: 8px 5px 0 27px;
`;
