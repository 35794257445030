import { FormControl } from '@material-ui/core';

import styled from 'styled-components';

export const StyledForm = styled(FormControl)`
    &&& {
        width: 100%;
        min-width: 100px;
        margin-bottom: 16px;
        background: #171923;
        border-radius: 5px;
        height: 60px;

        .MuiInput-underline:before {
            display: none
        }

        .MuiInputBase-root {
            color: white;
            margin-top: 15px;
            margin-left: 15px
        }
        
    }
`;
