import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLabelsState } from '../../../labels/store/states';
import { useModifyChallengeService } from '../../store/services';
import { EditModeContext } from '../../pages/EditChallenge';
import { EditModeValues } from '../../../../constants/enums/EditModeValues';
import { Challenge } from '../../../../constants/interfaces/Challenge';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { LabelsAction } from '../../../../ui/components/table-wrapper/table/TableAssignOptions/actions/LabelsAction';
import { Input } from '../../../../ui/components/input';

const Container = styled.div`
    padding: 15px 0;
    display: flex;
`;

const TextInputContainer = styled.div`
    flex: 1;
`;

const LabelsContainer = styled.div`
    width: 128px;
    margin-left: 16px;
`;

const TitleTextInput = styled(Input)`
    padding: 12px 16px;
    font-size: 13px;
    font-weight: normal;
`;

const ActionPanel: FC<{ challenge: Challenge, titleError: boolean }> = ({ challenge, titleError }) => {
    const modifyChallenge = useModifyChallengeService();
    const edit = useContext(EditModeContext);
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const showMessage = useShowMessage();

    useEffect(() => {}, []);

    const handleSaveChallengeLabels = () => {
        modifyChallenge(labels.assignedLabels, 'updatedLabels');
        showMessage(`labels have been assigned to Challenge`, 'success');
    };

    return (
        <Container>
            <TextInputContainer>
                <TitleTextInput
                    placeholder="Challenge Title"
                    height={'40px'}
                    maxLength={100}
                    handleChange={(event: any) =>
                        modifyChallenge(event.target.value, 'title')
                    }
                    value={challenge.title}
                    readOnly={edit.mode === EditModeValues.VIEW}
                    error={titleError}
                    dataTest='challenge-title'
                />
            </TextInputContainer>
            <LabelsContainer>
                <LabelsAction
                    title={'Labels'}
                    labels={labelsList}
                    disabled={edit.mode === EditModeValues.VIEW}
                    onAssignLabelsSubmit={handleSaveChallengeLabels}
                    containerLeftPosition={'unset'}
                    dataTest='challenge-labels'
                />
            </LabelsContainer>
        </Container>
    );
};

export default ActionPanel;
