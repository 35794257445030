import { Action } from 'redux';

export enum ACTIONS {
    SELECT_MULTIPLE_TEAMS = 'SELECT_MULTIPLE_TEAMS',
    DISABLE_MULTIPLE_TEAMS_FILTER = 'DISABLE_MULTIPLE_TEAMS_FILTER'
}

export type SelectMultipleTeamsAction = Action<ACTIONS.SELECT_MULTIPLE_TEAMS> & {
    teamIds: number[] | null;
};

export const selectMultipleTeams = (
    teamIds: number[] | null
): SelectMultipleTeamsAction => ({
    type: ACTIONS.SELECT_MULTIPLE_TEAMS,
    teamIds,
});

export type DisableMultipleTeamsFilterAction = Action<ACTIONS.DISABLE_MULTIPLE_TEAMS_FILTER> & {
    isDisabled: boolean;
};

export const disableMultipleTeamsFilter = (
    isDisabled: boolean
): DisableMultipleTeamsFilterAction => ({
    type: ACTIONS.DISABLE_MULTIPLE_TEAMS_FILTER,
    isDisabled,
});

export type MultipleTeamsFilterActions = SelectMultipleTeamsAction | DisableMultipleTeamsFilterAction;