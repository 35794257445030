import React, { FC } from 'react';
import styled from 'styled-components';
import { LinkText } from '../../../ui/components/LinkText/link-text';

const AddLabelActions = styled.div`
    text-align: right;
    height: 16px;
    margin-bottom: 8px;
`;

const CancelLabelButton = styled(LinkText)`
    font-size: 11px;
    color: ${props => props.theme.Colors.steelGrey};
`;

const SaveLabelButton = styled(LinkText)<{ disabled?: boolean }>`
    font-size: 11px;
    margin-left: 8px;
    ${props =>
        props.disabled &&
        `
        pointer-events: none;
        color: ${props.theme.Colors.steelGrey};
    `}
`;

const EditLabelActions: FC<{
    onCancel?: () => void;
    onConfirm?: () => void;
    confirmDisabled?: boolean;
}> = ({ onCancel, onConfirm, confirmDisabled }) => {
    return (
        <AddLabelActions>
            <CancelLabelButton onClick={onCancel} dataTest="sidebar-labels-cancel">Cancel</CancelLabelButton>
            <SaveLabelButton onClick={onConfirm} disabled={confirmDisabled} dataTest="sidebar-labels-save">
                Save
            </SaveLabelButton>
        </AddLabelActions>
    );
};

export default EditLabelActions;
