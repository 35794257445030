import { FC, memo } from 'react';

import { Root } from './style';
import { TableToolItemProps } from './type';

const TableToolItem: FC<TableToolItemProps> = memo(props => {
    const { width, children, className, marginRight, marginLeft } = props;

    return (
        <Root
            width={width}
            className={className}
            marginRight={marginRight}
            marginLeft={marginLeft}
        >
            {children}
        </Root>
    );
});

export default TableToolItem;
