import { motion } from 'framer-motion';
import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledConfirmDialogBlacker = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1200;
    background: ${rgba('#232e3a', 0.8)};
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ps-black-main);
`;

export const StyledConfirmDialog = styled(motion.div)<{
    width?: number;
    height?: number;
    minHeight?: number;
    padding?: string;
}>`
    width: ${props => props.width || '400'}px;
    height: ${props => props.height ? `${props.height}px` : 'auto'};
    min-height: ${props => `${props.minHeight ?? 200}px`};
    box-shadow: 0 5px 20px -5px rgba(166, 174, 190, 0.5);
    background-color: var(--ps-white-1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: ${props =>
        !!props.padding ? props.padding : '32px 32px 32px 40px;'};
`;

export const StyledTitle = styled.div<{
    marginBottom?: number;
    color?: string;
}>`
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0.5px;
    color: ${props =>
        !!props.color ? `${props.color}` : 'var(--ps-black-main)'};
    text-align: left;
    margin-bottom: ${props =>
        !!props.marginBottom ? `${props.marginBottom}px` : '14px'};
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
`;

export const StyledDescription = styled.div<{ marginBottom?: number }>`
    color: var(--ps-grey-1);
    font-size: 13px;
    text-align: left;
    margin-bottom: ${props =>
        !!props.marginBottom ? `${props.marginBottom}px` : '0px'};
    white-space: break-spaces;
`;

export const StyledActions = styled.div<{ center?: boolean }>`
    display: flex;
    justify-content: ${props => (props.center ? 'center' : 'flex-end')};
`;

export const ButtonWrapper = styled.div`
    margin-left: 16px;
`;