import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Document = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {shadow && (
                    <defs>
                        <filter
                            id="a"
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <path
                        id="Document"
                        fill="currentColor"
                        d="M18.571 17.333H4.286c-.79 0-1.429-.596-1.429-1.333V1.333C2.857.597 3.497 0 4.286 0H18.57C19.361 0 20 .597 20 1.333V16c0 .737-.64 1.333-1.429 1.333zm0-15.333c0-.368-.32-.666-.714-.666H5c-.394 0-.714.298-.714.666v13.333c0 .368.32.667.714.667h12.857c.395 0 .714-.299.714-.667V2zM16.43 13.333h-10c-.395 0-.715-.298-.715-.667 0-.368.32-.667.715-.667h10c.394 0 .714.3.714.667 0 .369-.32.667-.714.667zm0-4h-10c-.395 0-.715-.298-.715-.666 0-.368.32-.667.715-.667h10c.394 0 .714.299.714.667 0 .368-.32.666-.714.666zm-4.286-4H6.429c-.395 0-.715-.298-.715-.666 0-.368.32-.667.715-.667h5.714c.394 0 .714.299.714.667 0 .368-.32.666-.714.666zm5 14.667H1.429C.639 20 0 19.403 0 18.666V4c0-.736.64-1.332 1.429-1.332v15.998H18.57c0 .737-.64 1.334-1.428 1.334z"
                    />
                </g>
            </svg>
        );
    }
);

export default Document;
