import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { ScriptLine } from '../../../constants/interfaces/ScriptLine';

export interface TelepromterState {
    scenario: Scenario;
    generatedLines: Partial<ScriptLine>[];
    videoBlob?: string;
    loading?: boolean;
    error?: string;
}

export type TelepromterStateSelector = (state: any) => TelepromterState;

export const TelepromterStateSelectorContext = React.createContext<
    TelepromterStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useTelepromterState(): TelepromterState {
    return useSelector(useContext(TelepromterStateSelectorContext));
}
