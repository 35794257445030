export enum NEW_PERMISSIONS {
    LOGIN_WEB = 'LOGIN_WEB',
    LOGIN_MOBILE = 'LOGIN_MOBILE',
    LOGIN_ADMIN = 'LOGIN_ADMIN',
    SIGNUP_WEB = 'SIGNUP_WEB',
    SIGNUP_MOBILE = 'SIGNUP_MOBILE',
    COMPANY_REPORT = 'COMPANY_REPORT',
    LIST_LOGS = 'LIST_LOGS',
    MANAGE_RECORDINGS = 'MANAGE_RECORDINGS',
    MANAGE_OWN_FILES = 'MANAGE_OWN_FILES',
    UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO',
    UPDATE_TTS_SETTINGS = 'UPDATE_TTS_SETTINGS',
    VIEW_TTS_SETTINGS = 'VIEW_TTS_SETTINGS',
    CREATE_PRACTIS_SET = 'CREATE_PRACTIS_SET',
    CREATE_SCENARIO = 'CREATE_SCENARIO',
    CREATE_CHALLENGE = 'CREATE_CHALLENGE',
    UPDATE_PRACTIS_SET = 'UPDATE_PRACTIS_SET',
    UPDATE_SCENARIO = 'UPDATE_SCENARIO',
    UPDATE_CHALLENGE = 'UPDATE_CHALLENGE',
    ARCHIVE_PRACTIS_SET = 'ARCHIVE_PRACTIS_SET',
    ARCHIVE_SCENARIO = 'ARCHIVE_SCENARIO',
    ARCHIVE_CHALLENGE = 'ARCHIVE_CHALLENGE',
    DELETE_PRACTIS_SET = 'DELETE_PRACTIS_SET',
    DELETE_SCENARIO = 'DELETE_SCENARIO',
    DELETE_CHALLENGE = 'DELETE_CHALLENGE',
    COPY_PRACTIS_SET = 'COPY_PRACTIS_SET',
    COPY_SCENARIO = 'COPY_SCENARIO',
    COPY_CHALLENGE = 'COPY_CHALLENGE',
    GENERATE_CHALLENGE = 'GENERATE_CHALLENGE',
    PUBLISH_PRACTIS_SET = 'PUBLISH_PRACTIS_SET',
    PUBLISH_SCENARIO = 'PUBLISH_SCENARIO',
    PUBLISH_CHALLENGE = 'PUBLISH_CHALLENGE',
    LIST_PRACTIS_SET = 'LIST_PRACTIS_SET',
    LIST_SCENARIO = 'LIST_SCENARIO',
    LIST_CHALLENGE = 'LIST_CHALLENGE',
    SET_DUE_DATE = 'SET_DUEDATE',
    CREATE_INVITATION = 'CREATE_INVITATION',
    INVITE_TRAINEE = 'INVITE_TRAINEE',
    INVITE_TRAINER = 'INVITE_TRAINER',
    INVITE_SUPERVISOR = 'INVITE_SUPERVISOR',
    INVITE_CUSTOM_ROLE = 'INVITE_CUSTOM_ROLE',
    INVITE_COMPANY_ADMIN = 'INVITE_COMPANY_ADMIN',
    UPDATE_INVITATION = 'UPDATE_INVITATION',
    DELETE_INVITATION = 'DELETE_INVITATION',
    ASSIGN_INVITATION_PRACTIS_SET = 'ASSIGN_INVITATION_PRACTIS_SET',
    ASSIGN_INVITATION_TEAM = 'ASSIGN_INVITATION_TEAM',
    ASSIGN_INVITATION_LABEL = 'ASSIGN_INVITATION_LABEL',
    ASSIGN_INVITATION_ROLE = 'ASSIGN_INVITATION_ROLE',
    MANAGE_ROLES = 'MANAGE_ROLES',
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',
    LIST_USER = 'LIST_USER',
    EXPORT_LIST_USER = 'EXPORT_LIST_USER',
    LIST_INVITATION = 'LIST_INVITATION',
    REVOKE_INVITATION = 'REVOKE_INVITATION',
    RESEND_INVITATION = 'RESEND_INVITATION',
    VIEW_USER_PERFORMANCE = 'VIEW_USER_PERFORMANCE',
    CREATE_LABEL = 'CREATE_LABEL',
    UPDATE_LABEL = 'UPDATE_LABEL',
    DELETE_LABEL = 'DELETE_LABEL',
    MANAGE_PACINGS = 'MANAGE_PACINGS',
    ASSIGN_PRACTIS_SET_LABEL = 'ASSIGN_PRACTIS_SET_LABEL',
    ASSIGN_SCENARIO_LABEL = 'ASSIGN_SCENARIO_LABEL',
    ASSIGN_CHALLENGE_LABEL = 'ASSIGN_CHALLENGE_LABEL',
    ASSIGN_USER_LABEL = 'ASSIGN_USER_LABEL',
    REMOVE_PRACTIS_SET_LABEL = 'REMOVE_PRACTIS_SET_LABEL',
    REMOVE_SCENARIO_LABEL = 'REMOVE_SCENARIO_LABEL',
    REMOVE_CHALLENGE_LABEL = 'REMOVE_CHALLENGE_LABEL',
    REMOVE_USER_LABEL = 'REMOVE_USER_LABEL',
    LIST_SUBMISSION = 'LIST_SUBMISSION',
    VIEW_SUBMISSION = 'VIEW_SUBMISSION',
    REVIEW_SUBMISSION = 'REVIEW_SUBMISSION',
    REQUEST_SUBMISSION_REDO = 'REQUEST_SUBMISSION_REDO',
    ARCHIVE_SUBMISSION = 'ARCHIVE_SUBMISSION',
    RESTORE_SUBMISSION = 'RESTORE_SUBMISSION',
    LIST_ACTIVE_PRACTIS_SET = 'LIST_ACTIVE_PRACTIS_SET',
    VIEW_PRACTIS_SET_PROGRESS = 'VIEW_PRACTIS_SET_PROGRESS',
    SEND_NUDGE = 'SEND_NUDGE',
    EXPORT_LIST_PRACTIS_SET = 'EXPORT_LIST_PRACTIS_SET',
    EXPORT_LIST_PRACTIS_SET_USER = 'EXPORT_LIST_PRACTIS_SET_USER',
    ASSIGN_ENROLLMENT = 'ASSIGN_ENROLLMENT',
    VIEW_USER_PRACTIS_SET_REPORT = 'VIEW_USER_PRACTIS_SET_REPORT',
    VIEW_ENROLLMENT = 'VIEW_ENROLLMENT',
    VIEW_NOTIFICATION = 'VIEW_NOTIFICATION',
    VIEW_SUBMISSION_FEEDBACK = 'VIEW_SUBMISSION_FEEDBACK',
    VIEW_PROFILE = 'VIEW_PROFILE',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    RECEIVE_PUSH = 'RECEIVE_PUSH',
    TRACK_ACTIVITY = 'TRACK_ACTIVITY',
    ASSIGN_INVITATION_PERMISSION = 'ASSIGN_INVITATION_PERMISSION',
    ASSIGN_USER_PERMISSION = 'ASSIGN_USER_PERMISSION',
    RESTORE_PRACTIS_SET = 'RESTORE_PRACTIS_SET',
    RESTORE_SCENARIO = 'RESTORE_SCENARIO',
    RESTORE_CHALLENGE = 'RESTORE_CHALLENGE',
    GROUP_NAME = 'GROUP_NAME',
    VIEW_COMPANY = 'VIEW_COMPANY',
    REMOVE_INVITATION_LABEL = 'REMOVE_INVITATION_LABEL',
    UNASSIGN_ENROLLMENT = 'UNASSIGN_ENROLLMENT',
    SAVE_SEARCH_FILTER = 'SAVE_SEARCH_FILTER',
    VIEW_LOG_DETAILS = 'VIEW_LOG_DETAILS',
    MANAGE_STAGING_USERS = 'MANAGE_STAGING_USERS',
    REMOVE_USER_FROM_STAGING = 'REMOVE_USER_FROM_STAGING',
    ADD_USER_IN_STAGING = 'ADD_USER_IN_STAGING',
    LIST_STAGING = 'LIST_STAGING',
    DELETE_STAGING = 'DELETE_STAGING',
    UPDATE_STAGING = 'UPDATE_STAGING',
    CREATE_STAGING = 'CREATE_STAGING',
    VIEW_STAGING = 'VIEW_STAGING',
    LIST_TEAM = 'LIST_TEAM',
    DELETE_TEAM = 'DELETE_TEAM',
    UPDATE_TEAM = 'UPDATE_TEAM',
    CREATE_TEAM = 'CREATE_TEAM',
    VIEW_TEAM = 'VIEW_TEAM',
    REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER',
    DUPLICATE_TEAM = 'DUPLICATE_TEAM',
    ASSIGN_TEAM_LABEL = 'ASSIGN_TEAM_LABEL',
    INVITE_USER = 'INVITE_USER',

    // System permissions
    ACCESS_ANY_COMPANY = 'ACCESS_ANY_COMPANY',
    MANAGE_SYSTEM_FILES = 'MANAGE_SYSTEM_FILES',
    UPDATE_SETTINGS = 'UPDATE_SETTINGS',
    RUN_MIGRATION = 'RUN_MIGRATION',
    TRIGGER_CRON = 'TRIGGER_CRON',
    UPDATE_PRACTIS_ADMIN = 'UPDATE_PRACTIS_ADMIN',
    DELETE_PRACTIS_ADMIN = 'DELETE_PRACTIS_ADMIN',
    CREATE_PRACTIS_ADMIN = 'CREATE_PRACTIS_ADMIN',
    CREATE_COMPANY = 'CREATE_COMPANY',
    LIST_COMPANY = 'LIST_COMPANY',
    LIST_PRACTIS_ADMIN = 'LIST_PRACTIS_ADMIN',
    UPDATE_COMPANY = 'UPDATE_COMPANY',
    DELETE_COMPANY = 'DELETE_COMPANY',
    MANAGE_DICTIONARIES = 'MANAGE_DICTIONARIES',
    MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
    MANAGE_TIMEZONES = 'MANAGE_TIMEZONES',
    MANAGE_DEFAULT_ROLES = 'MANAGE_DEFAULT_ROLES',
    MANAGE_SYSTEM_ROLES = 'MANAGE_SYSTEM_ROLES',
    ASSIGN_SYSTEM_PERMISSIONS = 'ASSIGN_SYSTEM_PERMISSIONS',
    SET_USER_PASSWORD = 'SET_USER_PASSWORD',
}