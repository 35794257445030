import React from 'react';
import styled from 'styled-components';
import NoLabels from '../../../ui/icons/NoLabels';

const StyledEmptyLabels = styled.div`
    display: flex;
    padding-top: 56px;
    flex-direction: column;
    align-items: center;
`;

const StyledEmptyLabelsContent = styled.div`
    max-width: 140px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoLabelsIcon = styled.div`
    width: 30px;
    height: 40px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 16px;
`;

const NoLabelsTitle = styled.div`
    font-weight: bold;
    font-size: 13px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoLabelsDescription = styled.div`
    font-size: 11px;
    color: ${props => props.theme.Colors.cloudyBlue};
    width: 100%;
    white-space: break-spaces;
`;

const EmptyLabels = () => {
    return (
        <StyledEmptyLabels>
            <StyledEmptyLabelsContent>
                <NoLabelsIcon>
                    <NoLabels />
                </NoLabelsIcon>
                <NoLabelsTitle>No Matching Label</NoLabelsTitle>
                <NoLabelsDescription>
                    There are no labels that match your search
                </NoLabelsDescription>
            </StyledEmptyLabelsContent>
        </StyledEmptyLabels>
    );
};

export default EmptyLabels;
