import { FC, useEffect } from 'react';
import { PortableInviters } from './PortableInviters';
import {
    useDeSelectAllInvitersAction,
    useSearchInvitersService,
    useSelectAllInvitersAction,
    useSelectMultipleInvitersAction,
} from '../../inviters/store/services';
import { useSelector } from 'react-redux';
import { getInvitersState } from '../../inviters/store/reducers';

type Props = {
    fetchOnMount?: boolean;
    resetSelectedOnUnmount?: boolean;

    onSetSelected?: (ids: number[]) => void;
    filtersTitle?: string;
    showSelectActions?: boolean;
    showSearch?: boolean;
    showSave?: boolean;
};

export const PortableInvitersWithStore: FC<Props> = ({
    fetchOnMount = true,
    resetSelectedOnUnmount = true,
    ...rest
}) => {
    const invitersState = useSelector(getInvitersState);
    const { data, loading, selected, ready } = invitersState;
    const searchInviters = useSearchInvitersService();
    const selectMultiply = useSelectMultipleInvitersAction();
    const selectAll = useSelectAllInvitersAction();
    const deselectAll = useDeSelectAllInvitersAction();

    useEffect(() => {
        if (!ready || fetchOnMount) {
            searchInviters();
        }
        return () => {
            if (resetSelectedOnUnmount) {
                deselectAll();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deselectAll, ready, fetchOnMount, searchInviters]);

    return (
        <PortableInviters
            inviters={data}
            selectedList={selected}
            loading={loading}
            onSelectMultiply={selectMultiply}
            onSelectAll={selectAll}
            onDeselectAll={deselectAll}
            showSearch={true}
            showSelectActions={true}
            {...rest}
        />
    );
};
