import React, { FC, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { NewUserValues } from '../../models/NewUserValues';
import { getUserRoleNameById, InvitationEmailErrors } from '../../tools';
import {
    DraftUser,
    PractisSetWithDueDate,
} from '../../../../../constants/interfaces/Draft';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { useIfChanged } from '../../../../../helpers/hooks/usePreviousData';
import EditUserItem from './EditUserItem';
import { useUserRolesState } from '../../../../../features/roles/store/states';
import { TableCheckbox } from '../../../../../ui/components/table-wrapper/table/table-checkbox';
import {
    TableCheckboxCell,
    TableCheckboxHeaderCell,
} from '../../../../../ui/components/table-wrapper/table/TableCheckbox';
import { TableHeaderCell } from '../../../../../ui/components/table-wrapper/table/table-header/table-header-cell';
import { TableCell } from '../../../../../ui/components/table-wrapper/table/table-cell';
import { TableBody } from '../../../../../ui/components/table-wrapper/table/table-body-wrapper/table-body';
import { ScrollPagination } from '../../../../../ui/components/ScrollPagination';
import { TableRow } from '../../../../../ui/components/table-wrapper/table/table-row';
import NewUserExclamation from '../../../../../ui/icons/NewUserExclamation';
import { Tooltip } from '../../../../../ui/components/Tooltip';
import { useOrderBy } from '../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { Table, TableHead } from '../../../../../ui/components/Table';
import EditPencil from '../../../../../ui/icons/EditPencil';
import Trash from '../../../../../ui/icons/Trash';
import { TableNoResultBody } from '../../../../../ui/components/table-wrapper/table/TableNoResultBody';
import { TableNoResult } from '../../../../../ui/components/table-wrapper/table/TableNoResult';
import NoTrainingsFiltering from '../../../../../ui/icons/NoTrainingsFiltering';
import NoSearchResult from '../../../../../ui/icons/NoSearchResult';

const ITEMS_PER_PAGE = 20;

//region Styles
const TableHeaderFirstCell = styled(TableHeaderCell)`
    min-width: 120px;
`;

const StyledTableCell = styled(TableCell)`
    padding: 0;
`;

const StyledTable = styled.table`
    flex: 1;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    display: inline-table;
`;

const StyledTableBody = styled(TableBody)`
    width: 100%;
    &:before {
        content: unset;
    }
`;

const StyledTablePagination = styled(ScrollPagination)`
    width: 100%;
`;

const TableBodyScrollContainer = styled.div`
    overflow: auto;
    flex: 1;
    width: 100%;
    padding-bottom: 40px;
    margin-top: -5px;
`;

const FormatTableCellText = styled.span<{ hasError?: boolean }>`
    color: ${props => (props.hasError ? props.theme.Colors.tomato : 'unset')};
`;

const IconsContainer = styled.div`
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    display: none;
    min-width: 50px;
`;

const IconHolder = styled.div`
    width: 14px;
    height: 14px;
    cursor: pointer;
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledTableRow = styled(TableRow)<{ hovered?: boolean }>`
    ${props =>
        !!props.hovered &&
        `
            background: ${props.theme.Colors.whiteTwo};
            & > td {
                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        `}

    &:hover ${IconsContainer} {
        display: flex;
    }
`;

const IconsPlaceholder = styled.div`
    display: block;
    width: 50px;
`;

const StyledIconHint = styled.div`
    display: none;
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.Colors.white};
`;

const ErrorStateCheckbox = styled.div`
    margin-right: 16px;
`;

const ExclamationIconContainer = styled.div`
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1px ${props => props.theme.Colors.tomato};
    background-color: ${props => props.theme.Colors.tomato};
    align-items: center;
    justify-content: center;
    padding-left: 6px;

    &:hover ${StyledIconHint} {
        display: block;
        width: 200px;
        position: absolute;
        left: 8px;
        top: 10px;
    }

    &:hover ${IconsPlaceholder} {
        display: none;
    }
`;

const ExclamationIconHolder = styled.div`
    width: 2px;
    color: ${props => props.theme.Colors.white};
`;

const StyledTableContainer = styled.div`
    flex: 1;
    height: 100%;
`;

const TableContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const TableExtendContainer = styled.div`
    border-radius: 16px;
    background: ${props => props.theme.Colors.white};
    height: 100%;
    display: flex;
    flex-direction: column;
`;
//endregion

export const RenderTableCheckboxCell: FC<{
    user: DraftUser;
    selectedUsers: number[];
    handleUserCheck(userId: number): void;
    dataTest: string;
}> = ({ user, selectedUsers, handleUserCheck, dataTest }) => {
    let content;
    if (
        !user.firstName ||
        !user.lastName ||
        !user.email ||
        user.isEntryInvalid
    ) {
        content = (
            <Tooltip
                label={
                    <span data-test={`${dataTest}-warning-text`}>
                        {InvitationEmailErrors.isEntryInvalid}
                    </span>
                }
                position="bottom-end"
            >
                <ErrorStateCheckbox data-test={`${dataTest}-warning`}>
                    <ExclamationIconContainer>
                        <ExclamationIconHolder>
                            <NewUserExclamation />
                        </ExclamationIconHolder>
                    </ExclamationIconContainer>
                </ErrorStateCheckbox>
            </Tooltip>
        );
    } else if (user.emailExistsInSystem) {
        content = (
            <Tooltip
                label={
                    <span data-test={`${dataTest}-warning-text`}>
                        {InvitationEmailErrors.emailExistsInSystem}
                    </span>
                }
                position="bottom-end"
            >
                <ErrorStateCheckbox data-test={`${dataTest}-warning`}>
                    <ExclamationIconContainer>
                        <ExclamationIconHolder>
                            <NewUserExclamation />
                        </ExclamationIconHolder>
                    </ExclamationIconContainer>
                </ErrorStateCheckbox>
            </Tooltip>
        );
    } else {
        content = (
            <TableCheckbox
                checked={
                    !!selectedUsers &&
                    !!user.id &&
                    selectedUsers.includes(user.id)
                }
                handleCheck={() => handleUserCheck(user.id)}
                dataTest={dataTest}
            />
        );
    }

    return <TableCheckboxCell width={1}>{content}</TableCheckboxCell>;
};

export const UsersList: FC<{
    userList?: DraftUser[];
    totalUsersCount?: number;
    selectedUsers: number[];
    handleUserCheck(id?: number | string): void;
    updateAllUsersCheck(checked: boolean): void;
    onEditUser(user: NewUserValues): void;
    onRemoveUser(userId: number | string): void;
    isDraftSaved?: boolean;
    searchParams: SearchParams;
    limit: any;
    setLimit(limit: number): void;
    orderBy: any;
    setOrderBy(field: string): void;
    selectedUser: DraftUser;
    setSelectedUser(user: DraftUser): void;
    handleUpdateSelectedUser(): void;
    assignedRole: number | null;
    onAssignRoleCheck(role: number | null): void;
    selectedUserLabels: number[];
    setSelectedUserLabels: (labelIds: number[]) => void;
    selectedUserPractisSets: PractisSetWithDueDate[];
    setSelectedUserPractisSets: (
        practisSetIds: PractisSetWithDueDate[]
    ) => void;
    selectedUserTeams: number[];
    setSelectedUserTeams: (teamIds: number[]) => void;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => void;
    showNoSearchResultsState: boolean;
    showNoFilterResultsState: boolean;
    isRoleDisabled: boolean | undefined;
    updateSelectAll: (checked: boolean) => void;
    dataTest: string;
}> = ({
    userList,
    totalUsersCount,
    selectedUsers,
    handleUserCheck,
    updateSelectAll,
    updateAllUsersCheck,
    onEditUser,
    onRemoveUser,
    isDraftSaved,
    searchParams,
    limit,
    setLimit,
    orderBy,
    setOrderBy,
    children,
    selectedUser,
    setSelectedUser,
    handleUpdateSelectedUser,
    handleInputChange,
    assignedRole,
    onAssignRoleCheck,
    setSelectedUserLabels,
    selectedUserLabels,
    setSelectedUserPractisSets,
    selectedUserPractisSets,
    setSelectedUserTeams,
    selectedUserTeams,
    showNoSearchResultsState,
    showNoFilterResultsState,
    isRoleDisabled,
    dataTest
}) => {
    const scrollableRef = useRef<HTMLDivElement>(null);
    const userRoles = useUserRolesState();

    const [usersToDisplay, setUsersToDisplay] = useState<
        DraftUser[] | undefined
    >(userList);

    const isCheckboxPartiallySelected =
        usersToDisplay &&
        selectedUsers &&
        selectedUsers.length < usersToDisplay.length;

    const ifSearchParamsChanged = useIfChanged(searchParams);

    useEffect(() => {
        if (userList) {
            if (isDraftSaved) {
                setUsersToDisplay(userList);
            } else {
                updateDisplayUsers(searchParams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList, limit, ifSearchParamsChanged]);

    //TODO: refactor
    const updateDisplayUsers = (searchParams: SearchParams) => {
        const displayUsers: any = userList ? [...userList] : [];
        if (searchParams.orderBy && searchParams.orderBy.field) {
            const field = searchParams.orderBy.field;
            const reverse = searchParams.orderBy.asc ? 1 : -1;
            if (field === 'labelCount') {
                displayUsers.sort((a: any, b: any) => {
                    const firstEntry = a['labelIDs'];
                    const secondEntry = b['labelIDs'];
                    if (!firstEntry || !secondEntry) return false;
                    return (
                        reverse *
                        (firstEntry.length < secondEntry.length
                            ? -1
                            : firstEntry.length > secondEntry.length
                            ? 1
                            : 0)
                    );
                });
            } else if (field === 'practisSetCount') {
                displayUsers.sort((a: any, b: any) => {
                    const firstEntry = a['practisSetIDs'];
                    const secondEntry = b['practisSetIDs'];
                    if (!firstEntry || !secondEntry) return false;
                    return (
                        reverse *
                        (firstEntry.length < secondEntry.length
                            ? -1
                            : firstEntry.length > secondEntry.length
                            ? 1
                            : 0)
                    );
                });
            } else if (field === 'roleId') {
                displayUsers.sort((a: any, b: any) => {
                    const firstEntry = getRoleName(a[field] as number);
                    const secondEntry = getRoleName(b[field] as number);
                    if (!firstEntry || !secondEntry) return false;
                    if (
                        typeof firstEntry !== 'string' ||
                        typeof secondEntry !== 'string'
                    )
                        return false;
                    return (
                        reverse *
                        (firstEntry.toLowerCase() < secondEntry.toLowerCase()
                            ? -1
                            : firstEntry.toLowerCase() >
                              secondEntry.toLowerCase()
                            ? 1
                            : 0)
                    );
                });
            } else {
                displayUsers.sort((a: any, b: any) => {
                    const firstEntry = a[field];
                    const secondEntry = b[field];
                    if (!firstEntry || !secondEntry) return false;
                    if (
                        typeof firstEntry !== 'string' ||
                        typeof secondEntry !== 'string'
                    )
                        return false;
                    return (
                        reverse *
                        (firstEntry.toLowerCase() < secondEntry.toLowerCase()
                            ? -1
                            : firstEntry.toLowerCase() >
                              secondEntry.toLowerCase()
                            ? 1
                            : 0)
                    );
                });
            }
            setUsersToDisplay(displayUsers);
        }
    };

    const getRoleName = (roleId: number) => {
        if (!roleId) return '';

        const role = getUserRoleNameById(roleId, userRoles.list);
        return role || '';
    };

    const hcUserFirstName = useOrderBy('firstName', orderBy, setOrderBy);
    const hcUserLastName = useOrderBy('lastName', orderBy, setOrderBy);
    const hcUserEmail = useOrderBy('email', orderBy, setOrderBy);
    const hcUserRole = useOrderBy('roleId', orderBy, setOrderBy);
    const hcPractisSets = useOrderBy('practisSetCount', orderBy, setOrderBy);
    const hcTeams = useOrderBy('teamsCount', orderBy, setOrderBy);
    const hcLabels = useOrderBy('labelCount', orderBy, setOrderBy);

    const actionsDisabled = !userList || userList.length < 1;

    return (
        <StyledTableContainer>
            <TableContainer>
                <TableExtendContainer>
                    <Table hideSpacer={true}>
                        <TableHead dataTest={`${dataTest}-head`}>
                            <TableRow>
                                <TableCheckboxHeaderCell width={1} dataTest={`${dataTest}-head-checkbox`}>
                                    <TableCheckbox
                                        checked={
                                            !!selectedUsers &&
                                            !!selectedUsers.length
                                        }
                                        disabled={actionsDisabled}
                                        partial={isCheckboxPartiallySelected}
                                        handleCheck={(event: any) => {
                                            updateAllUsersCheck(
                                                event.target.checked
                                            );

                                            updateSelectAll(
                                                event.target.checked
                                            );
                                        }}
                                        dataTest={`${dataTest}-head-checkbox-input`}
                                    />
                                </TableCheckboxHeaderCell>
                                <TableHeaderFirstCell
                                    width={14}
                                    {...hcUserFirstName}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-first-name`}
                                >
                                    First Name
                                </TableHeaderFirstCell>
                                <TableHeaderCell
                                    width={14}
                                    {...hcUserLastName}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-last-name`}
                                >
                                    Last Name
                                </TableHeaderCell>
                                <TableHeaderCell
                                    width={18}
                                    {...hcUserEmail}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-email`}
                                >
                                    User Email
                                </TableHeaderCell>
                                <TableHeaderCell
                                    width={10}
                                    {...hcUserRole}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-role`}
                                >
                                    Role
                                </TableHeaderCell>
                                <TableHeaderCell
                                    width={13}
                                    {...hcTeams}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-teams`}
                                >
                                    Teams
                                </TableHeaderCell>
                                <TableHeaderCell
                                    width={15}
                                    {...hcPractisSets}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-practis-sets`}
                                >
                                    Practis Sets
                                </TableHeaderCell>
                                <TableHeaderCell
                                    width={10}
                                    {...hcLabels}
                                    disabled={actionsDisabled}
                                    dataTest={`${dataTest}-head-labels`}
                                >
                                    Labels
                                </TableHeaderCell>
                                <TableHeaderCell width={5} />
                            </TableRow>
                        </TableHead>
                    </Table>
                    {children}
                    <TableBodyScrollContainer ref={scrollableRef}>
                        <StyledTablePagination
                            itemsPerPage={ITEMS_PER_PAGE}
                            itemCount={userList ? userList.length : 0}
                            totalCount={
                                isDraftSaved
                                    ? totalUsersCount
                                    : userList && userList.length
                            }
                            onLimitChange={setLimit}
                            scrollableRef={scrollableRef}
                        >
                            <StyledTable>
                                <StyledTableBody>
                                    {usersToDisplay &&
                                        usersToDisplay.map(
                                            (user: DraftUser) => {
                                                if (
                                                    user.id === selectedUser.id
                                                ) {
                                                    return (
                                                        <EditUserItem
                                                            key={user.id}
                                                            user={selectedUser}
                                                            setUser={
                                                                setSelectedUser
                                                            }
                                                            handleUpdateSelectedUser={
                                                                handleUpdateSelectedUser
                                                            }
                                                            handleInputChange={
                                                                handleInputChange
                                                            }
                                                            assignedRole={
                                                                assignedRole
                                                            }
                                                            onAssignRoleCheck={
                                                                onAssignRoleCheck
                                                            }
                                                            setSelectedUserLabels={
                                                                setSelectedUserLabels
                                                            }
                                                            selectedUserLabels={
                                                                selectedUserLabels
                                                            }
                                                            setSelectedUserPractisSets={
                                                                setSelectedUserPractisSets
                                                            }
                                                            selectedUserPractisSets={
                                                                selectedUserPractisSets
                                                            }
                                                            setSelectedUserTeams={
                                                                setSelectedUserTeams
                                                            }
                                                            selectedUserTeams={
                                                                selectedUserTeams
                                                            }
                                                            isRoleDisabled={
                                                                isRoleDisabled
                                                            }
                                                            dataTest={`${dataTest}-edit`}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <StyledTableRow
                                                        key={user.id}
                                                        hovered={
                                                            user.id ===
                                                            selectedUser.id
                                                        }
                                                        dataTest={`${dataTest}-row`}
                                                    >
                                                        <RenderTableCheckboxCell
                                                            user={user}
                                                            selectedUsers={
                                                                selectedUsers
                                                            }
                                                            handleUserCheck={
                                                                handleUserCheck
                                                            }
                                                            dataTest={`${dataTest}-row-checkbox`}
                                                        />
                                                        <StyledTableCell
                                                            width={14}
                                                        >
                                                            {user.firstName && (
                                                                <FormatTableCellText
                                                                    hasError={
                                                                        user.isEntryInvalid ||
                                                                        user.emailExistsInSystem ||
                                                                        !user.firstName ||
                                                                        !user.lastName ||
                                                                        !user.email ||
                                                                        !user.roleId
                                                                    }
                                                                    data-test={`${dataTest}-row-first-name`}
                                                                >
                                                                    {
                                                                        user.firstName
                                                                    }
                                                                </FormatTableCellText>
                                                            )}
                                                        </StyledTableCell>
                                                        <TableCell width={14}>
                                                            {user.lastName && (
                                                                <FormatTableCellText
                                                                    hasError={
                                                                        user.isEntryInvalid ||
                                                                        user.emailExistsInSystem ||
                                                                        !user.firstName ||
                                                                        !user.lastName ||
                                                                        !user.email ||
                                                                        !user.roleId
                                                                    }
                                                                    data-test={`${dataTest}-row-last-name`}
                                                                >
                                                                    {
                                                                        user.lastName
                                                                    }
                                                                </FormatTableCellText>
                                                            )}
                                                        </TableCell>
                                                        <TableCell width={18}>
                                                            {user.email && (
                                                                <FormatTableCellText
                                                                    hasError={
                                                                        user.isEntryInvalid ||
                                                                        user.emailExistsInSystem ||
                                                                        !user.firstName ||
                                                                        !user.lastName ||
                                                                        !user.email ||
                                                                        !user.roleId
                                                                    }
                                                                    data-test={`${dataTest}-row-email`}
                                                                    >
                                                                    {
                                                                        user.email
                                                                    }
                                                                </FormatTableCellText>
                                                            )}
                                                        </TableCell>
                                                        <TableCell width={10}>
                                                            <span data-test={`${dataTest}-row-role`}>
                                                                {!!user.roleId &&
                                                                    getRoleName(
                                                                        user.roleId
                                                                    )}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell
                                                            shouldShowEmptyCell={!user.teamIDs?.length}
                                                            width={13}>
                                                                <span data-test={`${dataTest}-row-teams`}>
                                                                    {user.teamIDs &&
                                                                    !!user.teamIDs
                                                                        .length &&
                                                                    `${
                                                                        user.teamIDs
                                                                            .length
                                                                    } ${
                                                                        user.teamIDs
                                                                            .length >
                                                                        1
                                                                            ? 'Teams'
                                                                            : 'Team'
                                                                    }`}
                                                                </span>
                                                        </TableCell>
                                                        <TableCell
                                                            shouldShowEmptyCell={!user.practisSetIDs?.length}
                                                            width={15}>
                                                                <span data-test={`${dataTest}-row-practis-sets`}>
                                                                    {user.practisSetIDs &&
                                                                        user
                                                                            .practisSetIDs
                                                                            .length &&
                                                                        `${
                                                                            user
                                                                                .practisSetIDs
                                                                                .length
                                                                        } ${
                                                                            user
                                                                                .practisSetIDs
                                                                                .length >
                                                                            1
                                                                                ? 'Practis Sets'
                                                                                : 'Practis Set'
                                                                        }`}
                                                                </span>
                                                        </TableCell>
                                                        <TableCell
                                                            shouldShowEmptyCell={!user.labelIDs?.length}
                                                            width={10}>
                                                                <span data-test={`${dataTest}-row-labels`}>
                                                                    {user.labelIDs &&
                                                                        user.labelIDs
                                                                            .length &&
                                                                        `${
                                                                            user
                                                                                .labelIDs
                                                                                .length
                                                                        } ${
                                                                            user
                                                                                .labelIDs
                                                                                .length >
                                                                            1
                                                                                ? 'Labels'
                                                                                : 'Label'
                                                                        }`}
                                                                </span>
                                                        </TableCell>
                                                        <TableCell width={5}>
                                                            <IconsPlaceholder />
                                                            <IconsContainer>
                                                                <IconHolder
                                                                    onClick={() =>
                                                                        user &&
                                                                        onEditUser(
                                                                            user
                                                                        )
                                                                    }
                                                                    data-test={`${dataTest}-row-edit`}
                                                                >
                                                                    <EditPencil />
                                                                </IconHolder>
                                                                <IconHolder
                                                                    onClick={() =>
                                                                        user.id &&
                                                                        onRemoveUser(
                                                                            user.id
                                                                        )
                                                                    }
                                                                    data-test={`${dataTest}-row-delete`}
                                                                >
                                                                    <Trash />
                                                                </IconHolder>
                                                            </IconsContainer>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            }
                                        )}
                                </StyledTableBody>
                                {usersToDisplay && usersToDisplay.length === 0 && (
                                    <TableNoResultBody>
                                        {showNoSearchResultsState ? (
                                            <TableNoResult
                                                icon={NoSearchResult}
                                                subTitleWidth="180px"
                                                dataTest="no-found-users"
                                            >
                                                No Users Found
                                            </TableNoResult>
                                        ) : showNoFilterResultsState ? (
                                            <TableNoResult
                                                icon={NoTrainingsFiltering}
                                                width="160px"
                                                dataTest="no-filter-results"
                                            >
                                                No Results Match the Filter
                                                Criteria
                                            </TableNoResult>
                                        ) : null}
                                    </TableNoResultBody>
                                )}
                            </StyledTable>
                        </StyledTablePagination>
                    </TableBodyScrollContainer>
                </TableExtendContainer>
            </TableContainer>
        </StyledTableContainer>
    );
};
