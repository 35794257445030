import { SearchParams } from '../../../../constants/interfaces/filters';

export const countAppliedFiltersCount = (searchParams: SearchParams) => {
    let total = 0;

    if (searchParams && searchParams.teamIds && searchParams.teamIds.length) {
        total = total + searchParams.teamIds.length;
    }

    if (searchParams && searchParams.labelIDs && searchParams.labelIDs.length) {
        total = total + searchParams.labelIDs.filter(id => id > 0).length;
    }

    if (searchParams && searchParams.roleIDs && searchParams.roleIDs.length) {
        total = total + searchParams.roleIDs.length;
    }

    if (
        searchParams &&
        searchParams.teamLeadIds &&
        searchParams.teamLeadIds.length
    ) {
        total = total + searchParams.teamLeadIds.length;
    }

    if (
        searchParams?.invitersIDs?.length
    ) {
        total = total + searchParams.invitersIDs.length;
    }

    return total;
};
