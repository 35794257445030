import { useState, useCallback, useRef, useMemo, useEffect } from 'react';

import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { CheckVerificationCodeProps, MobileNumberResendCodeStatus } from './types';
import {
    INVALID_SECURITY_CODE,
    EXPIRED_SECURITY_CODE,
    CODE_TIMEOUT,
} from './constants';
import VerifyMobileNumberView from './view';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';

function CheckVerificationCodeController({
    mobileNumber,
    onBack,
    onVerify,
    onVerifySuccess,
    onResend,
}: CheckVerificationCodeProps) {
    const [code, setCode] = useState<string>('');
    const [remainingTime, setRemainingTime] = useState<number>(CODE_TIMEOUT);
    const remainingTimeInterval: any = useRef();
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [verificationError, setVerificationError] = useState<
        string | undefined
    >();
    const [resendStatus, setResendStatus] =
        useState<MobileNumberResendCodeStatus>('none');
    const showMessage = useShowMessage();

    /**
     * @function setRemainingTimeInterval
     * @description sets remaining time interval
     * @returns { void }
     */
    const setRemainingTimeInterval = () => {
        setRemainingTime(CODE_TIMEOUT);
        remainingTimeInterval.current = setInterval(
            () => setRemainingTime(prevState => prevState - 1),
            1000
        );
    };

    /**
     * @function handleCodeChange 
     * @description sets code to the state and clears error message
     * @param { string } newCode
     * @returns { void }
     */
    const handleCodeChange = (newCode: string) => {
        if (newCode !== code) {
            setCode(newCode);
            setVerificationError(undefined);
        }
    };

    /**
     * @function handleVerify
     * @description calls API to verify entered code,
     * runs success callback or shows a proper message in case of error
     * @returns { void }
     */
    const handleVerify = useCallback(() => {
        setIsVerifying(true);
        setVerificationError(undefined);
        onVerify(code)
            .then((response?: unknown) => {
                setIsVerifying(false);
                setVerificationError(undefined);
                onVerifySuccess(response);
            })
            .catch((error: ErrorResult) => {
                switch(error.errorCode) {
                    case 404:
                    case 472:
                    case 500:
                        setVerificationError(INVALID_SECURITY_CODE);
                        break;
                    case 432:
                        setVerificationError(EXPIRED_SECURITY_CODE);
                        break;
                    default:
                        error?.message && setVerificationError(error.message);
                        break;
                }
                setIsVerifying(false);
            });
    }, [code, onVerify, onVerifySuccess]);

    /**
     * @function handleResend
     * @description calls API to resend a verification code
     * and shows a proper message in case of error
     * @returns { void }
     */
    const handleResend = useCallback(() => {
        setResendStatus('inProgress');
        onResend()
            .then(() => {
                setResendStatus('success');
            })
            .catch((error: any) => {
                setResendStatus('none');
                showMessage(error.message, 'error');
            });
    }, [onResend, showMessage]);

    const isCodeValid = useMemo(() => code.length === 5, [code]);
    const showSent = useMemo(
        () =>
            remainingTime <= CODE_TIMEOUT &&
            remainingTime >= CODE_TIMEOUT - 2 &&
            resendStatus === 'success',
        [remainingTime, resendStatus]
    );
    const showCountDown = useMemo(
        () =>
            remainingTime > 0 &&
            remainingTime <=
                CODE_TIMEOUT - (resendStatus === 'success' ? 3 : 0),
        [remainingTime, resendStatus]
    );

    useEffect(() => {
        if (resendStatus === 'inProgress') {
            clearInterval(remainingTimeInterval.current);
        } else {
            setRemainingTimeInterval();
            return () => clearInterval(remainingTimeInterval.current);
        }
    }, [resendStatus]);

    useEffect(() => {
        if (remainingTime < 0) {
            clearInterval(remainingTimeInterval.current);
        }
    }, [remainingTime]);

    return (
        <VerifyMobileNumberView
            mobileNumber={mobileNumber}
            isVerifying={isVerifying}
            verificationError={verificationError}
            resendStatus={resendStatus}
            remainingTime={remainingTime}
            isCodeValid={isCodeValid}
            showSent={showSent}
            showCountDown={showCountDown}
            onCodeChange={handleCodeChange}
            onBack={onBack}
            onVerify={handleVerify}
            onResend={handleResend}
        />
    );
}


export default CheckVerificationCodeController;