import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const RefreshCircleDisabled = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow, dataTest }, ref) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" data-test={dataTest}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2.5a5.487 5.487 0 0 0-4.131 1.869l1.204 1.204A.25.25 0 0 1 4.896 6H1.25A.25.25 0 0 1 1 5.75V2.104a.25.25 0 0 1 .427-.177l1.38 1.38A7.001 7.001 0 0 1 14.929 7h-1.52A5.502 5.502 0 0 0 8 2.5zM1.07 9a7.001 7.001 0 0 0 12.123 3.693l1.38 1.38a.25.25 0 0 0 .427-.177V10.25a.25.25 0 0 0-.25-.25h-3.646a.25.25 0 0 0-.177.427l1.204 1.204A5.502 5.502 0 0 1 2.591 9H1.07z" fill="#B1C0CB"/>
</svg>);
    }
);

export default RefreshCircleDisabled;