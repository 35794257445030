import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import CompanyVoiceView from './view';
import { getElevenLabsVoices } from '../../../../services/ElevenLabs';
import { CompanyVoiceFormValues, CompanyVoiceProps } from './types';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useSetCompanyVoiceSettingsApi } from '../../../../api';
import { CompanyVoiceSettings, VoiceSettingsRole } from '../../../../constants/interfaces/CompanyVoiceSettings';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { useCompanyVoiceSettingsState } from '../../store/states';
import { useLoadVoiceSettingsService } from '../../store/services';
import { getTextToSpeechSettingsOrDefault } from '../../../../features/textToSpeech/helpers';
import { VoiceData } from '../../../../services/ElevenLabs/types';

function CompanyVoice({ companyId }: CompanyVoiceProps) {
    const [role, setRole] = useState<VoiceSettingsRole>('customer');
    const [voices, setVoices] = useState<VoiceData[]>([]);
    const { data: voiceSettings, loading } = useCompanyVoiceSettingsState();
    const loadVoiceSettings = useLoadVoiceSettingsService();
   const [isSampleInProgress, setIsSampleInProgress] = useState(false);
    const formRef = useRef<any>(null);
    const showMessage = useShowMessage();
    const setCompanyVoiceSettingsApi = useSetCompanyVoiceSettingsApi();

    const initialFormValues = useMemo((): CompanyVoiceFormValues => {
        const settingsOrDefault = getTextToSpeechSettingsOrDefault(voiceSettings);
        return {
            customer: {
                voice: settingsOrDefault.customer.voiceId,
                stability: settingsOrDefault.customer.stability,
                similarity: settingsOrDefault.customer.similarityBoost,
            },
            representative: {
                voice: settingsOrDefault.representative.voiceId,
                stability: settingsOrDefault.representative.stability,
                similarity: settingsOrDefault.representative.similarityBoost,
            }
        };
    }, [voiceSettings]);

    const handleChangeRole = useCallback((role: VoiceSettingsRole) => {
        setRole(role);
    }, []);

    /**
     * @function handleSubmit
     * @description transforms form values to API data, sends API request to save voice settings and resets form with current values
     * @param { CompanyVoiceFormValues } values
     * @returns { void }
     */
    const handleSubmit = useCallback((values: CompanyVoiceFormValues) => {
        const data: CompanyVoiceSettings[] = [{
            companyId,
            role: 'customer',
            voiceId: values.customer.voice,
            stability: values.customer.stability,
            similarityBoost: values.customer.similarity,
            style: 0
        }, {
            companyId,
            role: 'representative',
            voiceId: values.representative.voice,
            stability: values.representative.stability,
            similarityBoost: values.representative.similarity,
            style: 0
        }];
        return setCompanyVoiceSettingsApi(data)
            .then(() => {
                formRef.current.resetForm(values);
                formRef.current.setSubmitting(false);
            })
            .catch((e: ErrorResult) => {
                showMessage(e.message, 'error');
                formRef.current.setSubmitting(false);
            });
    }, [companyId, setCompanyVoiceSettingsApi, showMessage]);
    
    useEffect(() => {
        loadVoiceSettings(companyId);
        getElevenLabsVoices()
            .then(response => {
                setVoices(response.voices);
            })
            .catch(e => {
                showMessage('Voices loading error', 'error');
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formRef.current.resetForm(initialFormValues);
    }, [initialFormValues]);

    return (
        <CompanyVoiceView
            formRef={formRef}
            role={role}
            voices={voices}
            initialFormValues={initialFormValues}
            canChange={!isSampleInProgress}
            isLoading={loading}
            onChangeRole={handleChangeRole}
            onSampleAction={setIsSampleInProgress}
            onSubmit={handleSubmit}
        />
    )
}

export default CompanyVoice;