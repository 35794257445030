import React, { FC } from 'react';
import { EditModeValues } from '../../../../../constants/enums/EditModeValues';
import CustomerScriptItemContainer from '../CustomerScriptLine/CustomerScriptLine';
import TraineeRespondLine from '../TraineeRespondLine/TraineeRespondLine';
import styled from 'styled-components';
import { ScriptLine } from '../../../../../constants/interfaces/ScriptLine';
import Drag from '../../../../../ui/icons/Drag';

const StyledScriptLineWrapper = styled.div<{ reverse?: boolean }>`
    display: flex;
    flex-direction: ${props => (props.reverse ? 'row' : 'row-reverse')};
    margin-bottom: 16px;
`;

const StyledScriptHandle = styled.div<{ reverse?: boolean; hidden?: boolean }>`
    color: #b1c0cb;
    display: flex;
    flex-shrink: 0;
    width: 11px;
    align-items: center;
    height: 30px;
    margin-left: ${props => (props.reverse ? 29 : 8)}px;
    margin-right: ${props => (props.reverse ? 8 : 29)}px;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const CustomerLine: FC<{
    item: ScriptLine;
    editMode: string;
    disabled: boolean;
    dragHandleProps: any;
    hideRepPlaceholder?: boolean;
}> = ({ item, editMode, disabled, dragHandleProps, hideRepPlaceholder }) => {
    return (
        <div>
            <StyledScriptLineWrapper>
                <StyledScriptHandle
                    className="handle"
                    hidden={editMode === EditModeValues.VIEW}
                    {...dragHandleProps}
                    data-test='challenge-customer-line-drag-handle'
                >
                    <Drag />
                </StyledScriptHandle>
                <CustomerScriptItemContainer
                    key={item.id}
                    script={item}
                    editable={editMode === EditModeValues.EDIT}
                    disabled={disabled}
                />
            </StyledScriptLineWrapper>
            {!hideRepPlaceholder && (
                <TraineeRespondLine script={item} disabled={disabled} />
            )}
        </div>
    );
};

export default CustomerLine;
