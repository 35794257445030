import { useCallback, useEffect } from 'react';

import useQueryParamListener from '../../../../helpers/hooks/useQueryParamListener';
import { useSetQueryParam } from '../../../../helpers/hooks/useSetQueryParam';

import { useSearchTeamsService } from '../../../teams/store/services';
import { useTeamsState } from '../../../teams/store/states';
import { initialSearchParams, SINGLE_TEAM_QUERY_PARAM_NAME } from './constants';
import { useSingleTeamFilterState } from './store/reducers';
import { useClearSelectedTeam, useSelectSingleTeam } from './store/services';
import SingleSelectTeamFilterView from './view';

function SingleSelectTeamFilterController() {
    const searchTeams = useSearchTeamsService();
    const selectSingeTeam = useSelectSingleTeam();
    const clearSelectedTeam = useClearSelectedTeam();
    const setQueryParam = useSetQueryParam();

    const { loading, myTeams } = useTeamsState();
    const { selectedTeamId, isDisabled } = useSingleTeamFilterState();

    const setSelectedTeamInStore = useCallback((teamId?: number | null) => {
        if (teamId) {
            selectSingeTeam(teamId);
        } else {
            clearSelectedTeam();
        }
    }, [clearSelectedTeam, selectSingeTeam]);

    const setSelectedTeam = useCallback((teamId?: number) => {
        setQueryParam(SINGLE_TEAM_QUERY_PARAM_NAME, teamId?.toString() ?? '');
        setSelectedTeamInStore(teamId);
    }, [setQueryParam, setSelectedTeamInStore]);

    useQueryParamListener(SINGLE_TEAM_QUERY_PARAM_NAME, teamId => {
        const id = teamId ? +teamId : null;
        const isEqual = (!selectedTeamId && !id) || selectedTeamId === id;
        if (!isEqual) {
            setSelectedTeamInStore(id);
        }
    });

    const handleTeamSearch = useCallback((searchTerm: string) => {
        searchTeams({
            ...initialSearchParams,
            searchTerm,
        });
    }, [searchTeams]);

    useEffect(() => {
        searchTeams(initialSearchParams);
    }, [searchTeams]);

    return (
        <SingleSelectTeamFilterView
            selectedTeamId={selectedTeamId}
            isLoading={loading}
            myTeams={myTeams}
            isDisabled={isDisabled}
            onTeamSearch={handleTeamSearch}
            onTeamSelect={setSelectedTeam}
        />
    );
}

export default SingleSelectTeamFilterController;