import React, { useMemo } from 'react';

import HourGlass from '../../icons/HourGlass';
import Loading from '../LoadingCopmonent/Loading';
import { UserInterface } from '../../../constants/interfaces/User';
import { Tooltip } from '../Tooltip';
import {
    CustomNamePlaceholder,
    CustomPlaceholderContainer,
    PendingWrapper,
    StyledAvatarContainer,
    StyledNavLink,
} from './styles';
import { AvatarPlaceholderProps } from './types';

const AvatarPlaceholder = ({
    profile,
    customProfile,
    loading,
    size,
    link,
    disabled,
    className,
    backgroundColor,
    imgBorderRadius,
    customPlaceholder: CustomPlaceholder,
    customWrapper: CustomWrapper,
    iconWidth,
    iconHeight,
    hidePending,
    bottomPosition,
    noHint,
    dataTest
}: AvatarPlaceholderProps) => {
    const content = useMemo((): React.ReactNode => {
        if (loading) {
            return <Loading />;
        }

        if (customProfile) {
            if (customProfile.picture) {
                return (
                    <img
                        data-test={dataTest}
                        src={customProfile.picture}
                        alt={`${customProfile.firstName} ${customProfile.lastName}`}
                    />
                );
            }

            return (
                <CustomNamePlaceholder data-test={dataTest}>
                    {customProfile.firstName.charAt(0).toUpperCase()}
                </CustomNamePlaceholder>
            );
        }

        if (!profile) {
            return <div />;
        }

        const mediaUrl =
            profile.logoUrl ||
            profile.logo ||
            profile.imageUrl ||
            (profile as UserInterface)?.picture;

        if (mediaUrl) {
            return (
                <img
                    src={mediaUrl}
                    alt={'profile'}
                    data-test={dataTest}
                />
            );
        }

        if (CustomPlaceholder) {
            return (
                <CustomPlaceholderContainer>
                    <CustomPlaceholder />
                </CustomPlaceholderContainer>
            );
        }

        if (CustomWrapper) {
            if (profile.firstName && profile.lastName) {
                return (
                    <CustomWrapper data-test={dataTest}>
                        {profile.firstName.charAt(0).toUpperCase()}
                    </CustomWrapper>
                );
            }
            if (profile.name) {
                return (
                    <CustomWrapper data-test={dataTest}>
                        {profile.name.charAt(0).toUpperCase()}
                    </CustomWrapper>
                );
            }
        }

        if (profile.firstName) {
            return (
                <CustomNamePlaceholder data-test={dataTest}>
                    {profile.firstName.charAt(0).toUpperCase()}
                </CustomNamePlaceholder>
            );
        }

        if (profile.name) {
            return (
                <CustomNamePlaceholder data-test={dataTest}>
                    {profile.name.charAt(0).toUpperCase()}
                </CustomNamePlaceholder>
            );
        }

        return null;
    }, [CustomPlaceholder, CustomWrapper, loading, profile, customProfile, dataTest]);

    return (
        <StyledAvatarContainer
            size={size}
            disabled={disabled}
            className={className}
            backgroundColor={backgroundColor}
            imgBorderRadius={imgBorderRadius}
        >
            {link ? (
                <StyledNavLink to={link}>{content}</StyledNavLink>
            ) : (
                content
            )}

            {!hidePending &&
                (profile?.status === 'PENDING' ||
                    customProfile?.status === 'PENDING') &&
                (noHint ? (
                    <PendingWrapper bottomPosition={bottomPosition} data-test={dataTest && `${dataTest}-pending-icon`}>
                        <HourGlass width={iconWidth} height={iconHeight} />
                    </PendingWrapper>
                ) : (
                    <Tooltip
                        trigger="hover"
                        label={'Pending Registration'}
                        preset="pending-user"
                        dataTest="pending-user-tooltip"
                    >
                        <PendingWrapper bottomPosition={bottomPosition} data-test={dataTest && `${dataTest}-pending-icon`}>
                            <HourGlass width={iconWidth} height={iconHeight} />
                        </PendingWrapper>
                    </Tooltip>
                ))}
        </StyledAvatarContainer>
    );
};

export default AvatarPlaceholder;

