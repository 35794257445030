import { ChangeEvent } from 'react'
import { upperFirst } from 'lodash';
import { PartialVoiceSettingsViewProps } from './types';
import {
    AdvancedFieldContainer,
    AdvancedSettingsHeader,
    AdvancedSettingsTitle,
    FieldContainer,
    FieldName,
    QuestionContainer,
    ResetButton,
    SampleTextContainer,
    TooltipContainer,
    TootlipContent,
    TootlipTitle,
} from './styles';
import { Field } from 'formik';
import { FormikSelect } from '../../../../../../ui/components/FormikSelect';
import { QuestionTooltip } from '../../../../../../ui/components/Tooltip/QuestionTooltip';
import { RangeInput } from '../../../../../../ui/components/RangeInput';
import { formatSimilarityLabel, formatStabilityLabel } from './helpers';
import { VoiceSampleText } from '../VoiceSampleText';

function PartialVoiceSettingsView({
    role,
    voiceItems,
    values,
    initialSampleText,
    isDisabled,
    canReset,
    getFieldName,
    setFieldValue,
    onReset,
    onSampleAction,
}: PartialVoiceSettingsViewProps) {
    return (
        <>
            <FieldContainer>
                <FieldName data-test="voice-title">
                    {upperFirst(role)}'s Voice
                </FieldName>
                <Field
                    items={voiceItems}
                    value={values.voice}
                    component={FormikSelect}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(getFieldName('voice'), e.target.value)}
                    height={40}
                    variant="outlined"
                    name={getFieldName('voice')}
                    className="company-select"
                    disabled={isDisabled}
                    dataTest="voice-dropdown"
                />
            </FieldContainer>
            <AdvancedSettingsHeader>
                <AdvancedSettingsTitle data-test="advanced-settings-title">
                    ADVANCED VOICE SETTINGS
                </AdvancedSettingsTitle>
                <ResetButton
                    isActive={canReset}
                    onClick={onReset}
                    data-test="to-default-button"
                >
                    To Default
                </ResetButton>
            </AdvancedSettingsHeader>
            <AdvancedFieldContainer>
                <FieldName>
                    <span data-test="stability-title">Stability</span>
                    <QuestionContainer>
                        <QuestionTooltip
                            text={<TooltipContainer>
                                <TootlipTitle>More variable</TootlipTitle>
                                <TootlipContent>Increasing variability can make speech more expressive with output varying between re-generations. It can also lead to instabilities.</TootlipContent>
                                <TootlipTitle>More stable</TootlipTitle>
                                <TootlipContent>Increasing stability will make the voice more consistent between re-generations, but it can also make it sounds a bit monotone. On longer text fragments we recommend lowering this value.</TootlipContent>
                            </TooltipContainer>}
                            isHtmlContent={true}
                            dataTest='stability-tooltip'
                        />
                    </QuestionContainer>
                </FieldName>
                <RangeInput
                    min={0}
                    max={1}
                    value={values.stability}
                    onChange={value => setFieldValue(getFieldName('stability'), value as number)}
                    step={0.01}
                    disabled={isDisabled}
                    dataTest="stability-slider"
                    formatLabel={formatStabilityLabel}
                />
            </AdvancedFieldContainer>
            <AdvancedFieldContainer>
                <FieldName>
                    <span data-test="similarity-title">Clarity + Similarity Enhancement</span>
                    <QuestionContainer>
                        <QuestionTooltip
                            text={<TooltipContainer>
                                <TootlipTitle>Low</TootlipTitle>
                                <TootlipContent>Low values are recommended if background artifacts are present in generated speech.</TootlipContent>
                                <TootlipTitle>High</TootlipTitle>
                                <TootlipContent>It boosts overall voice clarity and target speaker similarity. Very high values can cause artifacts, so adjusting this setting to find the optimal value is encouraged.</TootlipContent>
                            </TooltipContainer>}
                            isHtmlContent={true}
                            dataTest='similarity-tooltip'
                        />
                    </QuestionContainer>
                </FieldName>
                <RangeInput
                    min={0}
                    max={1}
                    value={values.similarity}
                    onChange={value => setFieldValue(getFieldName('similarity'), value as number)}
                    step={0.01}
                    disabled={isDisabled}
                    dataTest="similarity-slider"
                    formatLabel={formatSimilarityLabel}
                />
            </AdvancedFieldContainer>
            <SampleTextContainer>
                <FieldName data-test="sample-text-title">Sample Text</FieldName>
                <VoiceSampleText
                    settings={values}
                    initialValue={initialSampleText}
                    isDisabled={isDisabled}
                    dataTest="sample-text-input"
                    onAction={onSampleAction}
                />
            </SampleTextContainer>
        </>
    )
}

export default PartialVoiceSettingsView;