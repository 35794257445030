import { Action } from 'redux';
import {Inviter} from "../../../constants/interfaces/Inviter";
import {InvitersResponse} from "../interfaces";

type ReturnTypeInvitersAction<
    T extends (...args: any) => any
    > = ReturnType<T> & ActionPayload;

type ActionPayload = any;

export type ActionInviter<T = any> = Action<T> & ActionPayload;

export type InvitersAction =
    | ReturnTypeInvitersAction<typeof searchInvitersStartAction>
    | ReturnTypeInvitersAction<typeof searchInvitersSuccessAction>
    | ReturnTypeInvitersAction<typeof searchInvitersFailureAction>
    | ReturnTypeInvitersAction<typeof selectInviterAction>
    | ReturnTypeInvitersAction<typeof deSelectInviterAction>
    | ReturnTypeInvitersAction<typeof selectMultipleInvitersAction>
    | ReturnTypeInvitersAction<typeof selectAllInvitersAction>
    | ReturnTypeInvitersAction<typeof deSelectAllInvitersAction>
    | ReturnTypeInvitersAction<typeof refreshInvitersAction>

export const searchInvitersStartAction = (): ActionInviter<'searchInvitersStartAction'> => ({
    type: 'searchInvitersStartAction',
});

export const searchInvitersSuccessAction = (
    responseData: InvitersResponse<Inviter>
): ActionInviter<'searchInvitersSuccessAction'> & {
    responseData: InvitersResponse<Inviter>;
} => ({
    type: 'searchInvitersSuccessAction',
    responseData,
});

export const searchInvitersFailureAction = (
    message: string
): ActionInviter<'searchInvitersFailureAction'> & {
    message: string;
} => ({
    type: 'searchInvitersFailureAction',
    message,
});

export const selectInviterAction = (
    id: number
): ActionInviter<'selectInviterAction'> & {
    id: number;
} => ({
    type: 'selectInviterAction',
    id,
});

export const deSelectInviterAction = (
    id: number
): ActionInviter<'deSelectInviterAction'> & {
    id: number;
} => ({
    type: 'deSelectInviterAction',
    id,
});

export const selectMultipleInvitersAction = (
    ids: number[]
): ActionInviter<'selectMultipleInvitersAction'> & { ids: number[] } => ({
    type: 'selectMultipleInvitersAction',
    ids,
});

export const selectAllInvitersAction = (): ActionInviter<'selectAllInvitersAction'> => ({
    type: 'selectAllInvitersAction',
});

export const deSelectAllInvitersAction = (): ActionInviter<'deSelectAllInvitersAction'> => ({
    type: 'deSelectAllInvitersAction',
});

export const saveInvitersAction = (): ActionInviter<'saveInvitersAction'> => ({
    type: 'saveInvitersAction',
});

export const refreshInvitersAction = (): ActionInviter<'refreshInvitersAction'> => ({
    type: 'refreshInvitersAction',
});
