import { useCallback } from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';

import { ModalDialogInterface } from './types';
import { HIDE_MODAL, SHOW_MODAL, UPDATE_LOADING_STATUS } from './constants';
import { ConfirmationModalViewProps } from '../types';

export type ShowModalAction = Action<typeof SHOW_MODAL> & {
    modalOptions: ModalDialogInterface;
};

export type UpdateModalLoadingStatusAction = Action<
    typeof UPDATE_LOADING_STATUS
> & {
    isLoading: boolean;
};

/**
 * @function showModalDialog
 * @param { ModalDialogInterface } modalOptions
 * @returns { ShowModalAction }
 */
export function showModalDialog(
    modalOptions: ModalDialogInterface
): ShowModalAction {
    return {
        type: SHOW_MODAL,
        modalOptions,
    };
}

export type HideModalAction = Action<typeof HIDE_MODAL>;

/**
 * @function hideModalDialog
 * @returns { HideModalAction }
 */
export function hideModalDialog(): HideModalAction {
    return {
        type: HIDE_MODAL,
    };
}

/**
 * @function useHideModalDialog
 * @returns { void }
 */
export const useHideModalDialog = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(hideModalDialog());
    }, [dispatch]);
};

/**
 * @function updateModalDialogLoadingStatus
 * @param { boolean } isLoading
 * @returns { UpdateModalLoadingStatusAction }
 */
export function updateModalDialogLoadingStatus(
    isLoading: boolean
): UpdateModalLoadingStatusAction {
    return {
        type: UPDATE_LOADING_STATUS,
        isLoading,
    };
}

/**
 * @function useShowConfirmModalDialog
 * @returns { CallableFunction }
 */
export const useShowConfirmModalDialog = () => {
    const dispatch = useDispatch();

    return useCallback(
        (modalProps: ConfirmationModalViewProps) => {
            dispatch(
                showModalDialog({
                    modalType: 'CONFIRMATION_MODAL',
                    modalProps,
                })
            );
        },
        [dispatch]
    );
};

/**
 * @function useSetModalDialogLoadingStatus
 * @returns { CallableFunction }
 */
export const useSetModalDialogLoadingStatus = () => {
    const dispatch = useDispatch();

    return useCallback(
        (isLoading: boolean) => {
            dispatch(updateModalDialogLoadingStatus(isLoading));
        },
        [dispatch]
    );
};

export type ModalDialogActions =
    | ShowModalAction
    | HideModalAction
    | UpdateModalLoadingStatusAction;
