import { useCallback } from 'react';
import Axios from 'axios';
import { useCreateMediaApi, useVerifyMediaApi } from '../../api';
import { MediaInfo } from '../../constants/interfaces/MediaInfo';
import { MAX_FILE_SIZE } from './constants';

/**
 * @description creates media record, uploads file to AWS
 * performs file verification and returns media id
 * @function useUploadMediaService
 * @returns { CallableFunction }
 */
export const useUploadMediaService = () => {
    const createMediaApi = useCreateMediaApi();
    const verifyMediaApi = useVerifyMediaApi();

    /**
     * @function callback
     * @param { File } file
     * @returns { Promise<number> }
     */
    return useCallback(
        (file: File) => {
            if (file.size > MAX_FILE_SIZE)
                return Promise.reject({ message: 'File size can not exceed 10Mb' });

            let mediaId: number;
            return createMediaApi(file.type)
                .then((media: MediaInfo) => {
                    mediaId = media.id;
                    return Axios.put(media.url, file, { headers: { 'Content-Type': file.type } });
                })
                .then(() => {
                    return verifyMediaApi(mediaId);
                })
                .then((verificationResult: MediaInfo) => {
                    if (verificationResult.status === 'ACTIVE') {
                        return Promise.resolve(mediaId);
                    } else {
                        return Promise.reject({ message: 'Verification of uploaded media failed' });
                    }
                });
        },
        [createMediaApi, verifyMediaApi]
    );
};