import React, { FC } from 'react';
import styled from 'styled-components';
import NoSearchResult from '../../../ui/icons/NoSearchResult';
import LeaderCrown from '../../../ui/icons/LeaderCrown';
import { Loading } from '../../../ui/components/LoadingCopmonent';

const StyledEmptyLabels = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const StyledEmptyLabelsContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoTeamsTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const EmptyTeamLeaders: FC<{ loading?: boolean; isEmpty?: boolean }> = ({
    loading,
    isEmpty,
}) => {
    if (loading) {
        return (
            <StyledEmptyLabels>
                <Loading />
            </StyledEmptyLabels>
        );
    }

    return (
        <StyledEmptyLabels>
            <StyledEmptyLabelsContent>
                <IconWrapper>
                    {isEmpty ? <LeaderCrown /> : <NoSearchResult />}
                </IconWrapper>
                <NoTeamsTitle>
                    {isEmpty ? 'No Team Leaders yet' : 'No Team Leaders found'}
                </NoTeamsTitle>
            </StyledEmptyLabelsContent>
        </StyledEmptyLabels>
    );
};

export default EmptyTeamLeaders;
