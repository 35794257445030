import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { PortableLabelsWithStore } from '../../../../../../../../../features/portableLabels';
import { useDeselectAllLabels, useSaveLabels } from '../../../../../../../../../features/portableLabels/store/hors/withLabels/services';
import { useLabelsState } from '../../../../../../../../../features/portableLabels/store/hors/withLabels/states';
import { Button } from '../../../../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../../../../ui/components/Fieldset';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;

    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 16px;
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

export const LabelFilters: FC<{
    onLabelsChange: (ids: number[]) => void;
    onSuccessApply: () => void;
}> = ({ onLabelsChange, onSuccessApply }) => {
    const [labelIds, setLabelIds] = useState<number[]>([]);
    const saveLabels = useSaveLabels();
    const deselectLabels = useDeselectAllLabels();
    const labels = useLabelsState();

    const onLabelChangeHandler = useCallback(
        (ids: number[]) => setLabelIds(ids),
        [setLabelIds]
    );

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labelIds);
        saveLabels();
        onSuccessApply();
    }, [onLabelsChange, labelIds, onSuccessApply, saveLabels]);

    const onClearHandler = useCallback(() => {
        deselectLabels();
    }, [deselectLabels])

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            onSetSelected={onLabelChangeHandler}
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <ButtonWrapper>
                    <Button disabled={!labels.selected.length} variant='inverse' width={'122px'} action={() => onClearHandler()}>
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button width={'122px'} action={() => onApplyHandler()}>
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
