import { forwardRef } from 'react';

import { Loading } from '../LoadingCopmonent';
import { COLORS } from './constants';
import {
    StyledButton,
    StyledButtonContent,
    StyledButtonIcon,
    StyledButtonIconContainer,
} from './style';
import { ButtonProps } from './type';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            label,
            children,
            action,
            height,
            style,
            color,
            disabled,
            icon,
            iconRender,
            loading,
            loadingSize,
            loadingColor,
            variant,
            width,
            className,
            type,
            onlyIcon,
            buttonSize,
            borderRadius,
            fontSize,
            customColor,
            customDisabledColor,
            buttonWidth,
            dataTest
        },
        ref
    ) => {
        return (
            <StyledButton
                ref={ref}
                title={label}
                type={type || 'submit'}
                disabled={disabled || loading}
                onClick={action}
                width={width}
                buttonWidth={buttonWidth}
                style={style}
                variant={variant}
                className={[
                    icon || iconRender
                        ? variant === 'transparent'
                            ? 'transparent'
                            : 'with-icon'
                        : variant
                        ? variant
                        : 'primary',
                ].join(' ')}
                color={color ? color : 'default'}
                customColor={customColor}
                customDisabledColor={customDisabledColor}
                buttonSize={buttonSize}
                data-test={dataTest}
            >
                {!onlyIcon && (
                    <StyledButtonContent
                        hasIcon={!!icon || !!iconRender}
                        height={height}
                        width={width}
                        color={color ? color : 'default'}
                        borderRadius={borderRadius}
                        fontSize={fontSize}
                        className={
                            variant
                                ? [className, variant].join(' ')
                                : `${className} primary`
                        }
                    >
                        {loading ? (
                            <Loading
                                color={loadingColor ?? COLORS[color ? color : 'default'].dark}
                                size={loadingSize ?? 15}
                            />
                        ) : (
                            children || label
                        )}
                    </StyledButtonContent>
                )}
                {icon && (
                    <StyledButtonIconContainer
                        color={color ? color : 'default'}
                        height={height}
                        onlyIcon={onlyIcon}
                        borderRadius={borderRadius}
                    >
                        <img src={icon} alt={'icon'} />
                    </StyledButtonIconContainer>
                )}
                {iconRender && (
                    <StyledButtonIconContainer
                        color={color ? color : 'default'}
                        height={height}
                        onlyIcon={onlyIcon}
                        borderRadius={borderRadius}
                    >
                        <StyledButtonIcon buttonSize={buttonSize}>
                            {iconRender}
                        </StyledButtonIcon>
                    </StyledButtonIconContainer>
                )}
            </StyledButton>
        );
    }
);

export default Button;
