import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { select, Selection } from 'd3-selection';
import { scaleBand, scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import styled from 'styled-components';
import ToolTip from './ToolTip';
import {
    formatDateChart,
    isToday,
} from '../../../helpers/functions/date-convert';
import { Variables } from '../../../theme/variables';

//region Styles
const Container = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    .tick line {
        visibility: hidden;
    }
    .tick text {
        color: #6d7f8c;
        font-size: 11px;
        font-weight: 500;
    }
    .domain {
        color: ${props => props.theme.Colors.paleGrey};
    }
    .x-axis-filler {
        fill: ${props => props.theme.Colors.paleGrey};
    }
    .x-axis-label {
        pointer-events: none;
        font-size: 11px;
        font-weight: 500;
    }
    .bar-container:hover {
        rect {
            opacity: 0.5;
        }
        rect:hover {
            opacity: 1;
        }
    }
`;
//endregion

export interface LineChartData {
    date: string;
    value: number | null;
}

const BAR_WIDTH = 2;

const LineChart: FC<{
    data: LineChartData[];
    color: string;
    label?: string;
    singleLabel?: string;
    dataTest?: string;
}> = ({ data, color, label, singleLabel, dataTest }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({
        width: 620,
        height: 130,
        margin: 50,
    });

    const ref = useRef<SVGSVGElement>(null);
    const [selection, setSelection] = useState<Selection<
        SVGSVGElement,
        unknown,
        null,
        undefined
    > | null>(null);
    const [showToolTip, setShowToolTip] = useState(false);
    const [toolTipPositions, setToolTipPositions] = useState<{
        x: number | null;
        y: number | null;
    }>({
        x: null,
        y: null,
    });
    const [toolTipContent, setToolTipContent] = useState({
        header: '',
        text: '',
    });

    const handleResize = useCallback(() => {
        if (containerRef.current) {
            setDimensions({
                ...dimensions,
                width: containerRef.current.offsetWidth - 50,
            });
        }
    }, [setDimensions, dimensions]);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize, dimensions]);

    useEffect(() => {
        if (containerRef.current) {
            setDimensions({
                height: 130,
                margin: 30,
                width: containerRef.current.offsetWidth - 50,
            });
        }
    }, [setDimensions]);

    useEffect(() => {
        if (!selection || !data || !data.length) {
            setSelection(ref.current && select(ref.current));
        } else {
            const x = scaleBand()
                .domain(data.map(d => d.date))
                .range([25, dimensions.width - 25]);

            const calculateMaxYValue = max(data, d => Number(d.value)!) || 5;
            const maxYValue = calculateMaxYValue < 5 ? 5 : calculateMaxYValue;

            const y = scaleLinear()
                .domain([0, maxYValue])
                .range([dimensions.height - dimensions.margin, 0]);

            const xAxis = axisBottom(x)
                .tickValues([])
                .tickFormat(d => d)
                .tickSizeOuter(0);

            const yAxis = axisLeft(y)
                .tickValues([0, maxYValue / 2, maxYValue])
                .tickSizeOuter(0);

            selection.selectAll('*').remove();

            const svg = selection
                .append('g')
                .attr(
                    'transform',
                    `translate(${dimensions.margin}, ${dimensions.margin - 10})`
                );
            const rects = svg
                .append('g')
                .attr('class', 'bar-container')
                .selectAll('rect')
                .data(data);

            const rect = rects.enter().append('g');

            rect.append('rect')
                .attr('width', 2)
                .attr('height', 2)
                .attr('display', d => {
                    if (isToday(d.date)) {
                        return 'block';
                    } else {
                        return 'none';
                    }
                })
                .attr('fill', color)
                .attr('x', (d, index) => {
                    if (data.length > 5) {
                        return x(d.date)! + x.bandwidth() / 2 - BAR_WIDTH / 2;
                    } else {
                        return index * 50 + 50;
                    }
                })
                .attr('y', function (d) {
                    return d.value ? y(d.value) : y(0) - 1;
                });

            rect.append('circle')
                .attr('fill', color)
                .attr('display', d => {
                    if (isToday(d.date)) {
                        return 'block';
                    } else {
                        return 'none';
                    }
                })
                .attr('stroke', 'none')
                .attr('cursor', 'pointer')
                .attr('opacity', '0.2')
                .attr('cx', (d, index) => {
                    if (data.length > 5) {
                        return (
                            x(d.date)! + x.bandwidth() / 2 - BAR_WIDTH / 2 + 1
                        );
                    } else {
                        return index * 50 + 50 + 1;
                    }
                })
                .attr('cy', d => {
                    return d.value
                        ? y(d.value)
                        : dimensions.height - dimensions.margin;
                })
                .attr('r', 8);

            rect.append('rect')
                .attr('cursor', 'pointer')
                .attr('width', BAR_WIDTH)
                .attr('height', d =>
                    typeof d.value === 'number' && d.value > 0
                        ? dimensions.height - dimensions.margin - y(d.value)
                        : 1
                )
                .attr('x', (d, index) => {
                    if (data.length > 5) {
                        return x(d.date)! + x.bandwidth() / 2 - BAR_WIDTH / 2;
                    } else {
                        return index * 50 + 50;
                    }
                })
                .attr('y', d => {
                    if (typeof d.value === 'number' && d.value > 0) {
                        return y(d.value);
                    } else {
                        return dimensions.height - dimensions.margin - 1;
                    }
                })
                .attr('fill', color)
                .attr('opacity', d => {
                    if (isToday(d.date)) {
                        return 0.5;
                    } else {
                        return 1;
                    }
                })
                .on('mouseover', (e, data) => {
                    setShowToolTip(true);
                    setToolTipPositions({
                        x: e.clientX - 35,
                        y: e.clientY - 75,
                    });
                    setToolTipContent({
                        header: `${data.value || 0}`,
                        text: data.date,
                    });
                })
                .on('mouseout', () => {
                    setShowToolTip(false);
                    setToolTipPositions({ x: null, y: null });
                });

            rect.append('text')
                .attr('class', 'x-axis-label')
                .attr('fill', Variables.Colors.steelGrey)
                .attr('text-anchor', 'middle')
                .attr('width', x.bandwidth())
                .attr('x', (d, index) => {
                    if (data.length > 5) {
                        return x(d.date)! + x.bandwidth() / 2 - BAR_WIDTH / 2;
                    } else {
                        return index * 50 + 50;
                    }
                })
                .attr('y', () => {
                    return dimensions.height - dimensions.margin + 20;
                })
                .text((d, index) => {
                    if (index === 0 || index === data.length - 1) {
                        return formatDateChart(d.date);
                    } else {
                        return '';
                    }
                });

            svg.append('g')
                .attr(
                    'transform',
                    `translate(0, ${dimensions.height - dimensions.margin})`
                )
                .call(xAxis);

            svg.append('rect')
                .attr('class', 'x-axis-filler')
                .attr('height', '1px')
                .attr('width', dimensions.width)
                .attr(
                    'transform',
                    `translate(0, ${dimensions.height - dimensions.margin})`
                );

            svg.append('g').call(yAxis);
        }
    }, [selection, data, dimensions, color]);

    return (
        <Container ref={containerRef} data-test={dataTest}>
            <svg
                ref={ref}
                width={dimensions.width + dimensions.margin + 10}
                height={dimensions.height + dimensions.margin}
            />
            {showToolTip && (
                <ToolTip
                    text={toolTipContent.header}
                    date={formatDateChart(toolTipContent.text)}
                    positions={toolTipPositions}
                    title={label || ''}
                    singleTitle={singleLabel}
                />
            )}
        </Container>
    );
};

export default LineChart;
