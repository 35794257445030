import { FC, memo } from 'react';

import { Tr } from './style';
import { TableRowProps } from './type';

const TableRow: FC<TableRowProps> = memo(props => {
    const { children, className, onClick, innerRef, dataTest, ...rest } = props;

    return (
        <Tr
            {...rest}
            className={className}
            ref={innerRef}
            onClick={onClick}
            clickable={!!onClick}
            data-test={dataTest || 'table-row'}
        >
            {children}
        </Tr>
    );
});

export default TableRow;

