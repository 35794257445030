import { RoleplaySkeletonContainer, LineSkeleton, RoleplayContainer } from "./styles";
const bodyLineWidths = [
    {
        lineWidths: [232,164,123]
    },
    {
        lineWidths: [232,164,123]
    },
    {
        lineWidths: [232,164,123]
    }
];

function RoleplaySceleton() {
    return <>{bodyLineWidths.map(({lineWidths}, index) => {
        return <RoleplayContainer key={index} data-test="roleplay-content-skeleton">
            <RoleplaySkeletonContainer>
                {
                    lineWidths.map((width, index) => <LineSkeleton width={width} height={8} key={index} marginTop={index === lineWidths.length - 1 ? "40px" : undefined} data-test="roleplay-content-skeleton-line"/>)
                }
            </RoleplaySkeletonContainer>
            </RoleplayContainer>
    })}</>
}

export default RoleplaySceleton;