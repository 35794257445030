import { Challenge } from '../../../../../../../../constants/interfaces/Challenge';
import { Scenario } from '../../../../../../../../constants/interfaces/Scenario';
import { PractisSetContent } from '../../../../../../../../constants/interfaces/PractisSets';
import { PractisSetEntityTypes } from '../../../../../tools';
import { EditModeValues } from '../../../../../../../../constants/enums/EditModeValues';

/**
 * @function isInViewMode
 * @param { string  } edit
 * @returns { boolean }
 */
const isInViewMode = (mode: string): boolean => mode === EditModeValues.VIEW;

/**
 * @function checkIsPractisContentDisabled
 * @param { PractisSetContent[] } practisSetsContent
 * @param { Scenario | Challenge } currentContent
 * @param { string } mode
 * @returns { boolean }
 */
export const checkIsPractisContentDisabled = (
    practisSetsContent: PractisSetContent[],
    currentContent: Scenario | Challenge,
    mode: string
): boolean => {

    const allContents =
        practisSetsContent?.map(
            item =>
                item.scenario
                    ? { ...item.scenario, type: PractisSetEntityTypes.SCENARIO }
                    : { ...item.challenge, type: PractisSetEntityTypes.CHALLENGE }
        ) ?? [];

    return (
        allContents.filter(
            content =>
                content.id === currentContent.id &&
                content.type === currentContent.type
        ).length > 0 || isInViewMode(mode)
    );
};