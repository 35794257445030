import { Field, Formik } from "formik";
import { ChangeEvent } from "react";
import { FormikSelect } from "../../../../ui/components/FormikSelect";
import { QuestionTooltip } from "../../../../ui/components/Tooltip/QuestionTooltip";
import { QuestionContainer, TooltipContainer, TootlipContent } from "../../../CompanySettings/components/CompanyVoice/components/PartialVoiceSettings/styles";
import useGoogleAnalyticsWithRoleplay from "../../GoogleAnalytics";
import { SalesMethologies } from "./constants";
import { Container, Title, TitleContainer } from "./styles";
import { AiParamTexboxProps } from "./types";

function AIParamSelect({ title, tooltip, onChange, value, dataTest, isEditMode, analyticsKey }: AiParamTexboxProps) {
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();

    return <Container>
        <Title>
            <TitleContainer data-test={`${dataTest}-title`}>
                {title}
                <QuestionContainer>
                    <QuestionTooltip
                        position="top"
                        action={() => trackEventWithRoleplay(`${analyticsKey}_hint`)}
                        text={<TooltipContainer>
                            <TootlipContent>{tooltip}</TootlipContent>
                        </TooltipContainer>}
                        isHtmlContent={true}
                        dataTest={`${dataTest}-tooltip`}
                    />
                </QuestionContainer>
            </TitleContainer>
        </Title>
        {!isEditMode ?
            <div className="bold-text" data-test={dataTest + '-text'}><b>{value !== 'null' ? value + ':' : SalesMethologies.find((item) => value === item.value)?.name}</b> {value !== 'null' && SalesMethologies.find((item) => value === item.value)?.name}</div>
            :
            <Formik
                initialValues={{ method: '' }}
                onSubmit={() => {}}
            >
                <Field
                    items={SalesMethologies}
                    value={value}
                    component={FormikSelect}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                    height={40}
                    variant="outlined"
                    name={'method'}
                    className="sales-method-select"
                    dataTest={`${dataTest}-dropdown`}
                    menuPosition='top'
                    customMenuItem={(item: { value: string, name: string }) => <div className="bold-text"><b>{item.value !== 'null' ? item.value + ':' : item.name}</b> {item.value !== 'null' && item.name}</div>}
                />
            </Formik>
        }

    </Container>
}

export default AIParamSelect;