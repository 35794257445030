import { NotificationWithToggleProps } from './types';
import {
    Title,
    Info,
    Description,
    ToggleContainer,
} from './styles';
import SwitchToggle from '../../../../ui/components/SwitchToggle/SwitchToggle';

function NotificationWithToggleView({
    notificationKey,
    title,
    description,
    checked,
    disabled,
    onChange,
}: NotificationWithToggleProps) {
    return <>
        <Title>{title}</Title>
        <Info>
            <Description>{description}</Description>
            <ToggleContainer>
                <SwitchToggle
                    index={notificationKey}
                    width={42}
                    height={24}
                    bulletRadius={16}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                />
            </ToggleContainer>
        </Info>
    </>;
}

export default NotificationWithToggleView;