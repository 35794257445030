import { useRouteMatch } from 'react-router-dom';

const ROUTES = {
    LANDING: '/',
    AUTH_PAGES: {
        LOGIN: '/login',
        MOBILE_NUMBER_LOGIN: '/mobile-number/login',
        MOBILE_NUMBER_VERIFY: '/mobile-number/verify/:phoneNumber',
        VERIFY_MOBILE_NUMBER_PRIVATE: '/number/:actionType/:phoneNumber',
        ADD_MOBILE_NUMBER: '/add-mobile-number',
        TRAINEE_LOGIN: '/trainee-login',
        LOGOUT: '/logout',
        REGISTER: '/register',
        REGISTRATION: '/registration',
        RESET_PASSWORD: '/reset',
        FORGOT_PASSWORD: '/forgot',
        ONBOARDING: '/onboarding/:role',
        INVALID_INVITE: '/invalid-invite',
        TRAINEE_SPLASH: '/trainee-splash/:userName'
    },
    PROFILE: '/users/:userId',
    PROFILE_SETTINGS: {
        SECURITY: '/users/:userId/security',
        NOTIFICATIONS: '/users/:userId/notifications',
    },
    ORGANIZATION_LOGS: '/logs',
    ORGANIZATION_ASSESSMENT_LOGS: '/ai-assessment',
    COMPANY_SETTINGS: {
        ROOT: '/company',
        LOGO: '/company/logo',
        USER_LIMIT: '/company/user-limit',
        VOICE: '/company/voice',
    },
    ACTION_ITEMS: {
        NEW_USER: '/drafts/:draftId',
    },
    PROGRESS: '/progress',
    PROGRESS_PAGES: {
        PRACTIS_SETS: '/progress/practis-sets',
        PRACTIS_SETS_DETAILS: '/progress/practis-sets/:practisSetId',
        TRAINEE_INVITATION: '/progress/practis-sets/:practisSetId/invite',
    },
    SUBMISSIONS: '/feed',
    SUBMISSION_SETTINGS: {
        ACCURACY_TESTS: {
            ALL: '/feed/accuracy',
            SINGLE: '/feed/accuracy/submission/:submissionId',
            OLD_SINGLE: '/feed/accuracy/:submissionId',
        },
        CHALLENGES: {
            ALL: '/feed/challenges',
            SINGLE: '/feed/challenges/submission/:submissionId',
            OLD_SINGLE: '/feed/challenges/:submissionId',
        }
    },
    LIBRARY: '/library',
    LIBRARY_SETTINGS: {
        SCENARIOS: {
            ALL: '/library/scenarios',
            SINGLE: '/library/scenarios/:scenarioId',
        },
        PRACTISSETS: {
            ALL: '/library/practis-sets',
            SINGLE: '/library/practis-sets/:practisSetId',
            QUICK_ASSIGNMENT:
                '/library/practis-sets/:practisSetId/quick-assignment',
            ASSIGN_USERS: '/library/practis-sets/:practisSetId/assign-users',
        },
        CHALLENGES: {
            ALL: '/library/challenges',
            SINGLE: '/library/challenges/:challengeId',
        },
    },
    TELEPROMTER: '/telepromter/:scenarioId',
    TELEPROMTER_PAGES: {
        PLAYBACK: '/telepromter/:scenarioId/playback',
        COMPLETED: '/telepromter/:scenarioId/completed',
        TRAINING: '/telepromter/:scenarioId/train',
        EXIT: '/telepromter/:scenarioId/exit',
    },
    TEAMS: '/teams',
    TEAMS_PAGES: {
        LIST_OF_TEAMS: '/teams',
        MEMBERS: '/teams/:teamId/members',
        NEW_TEAM: '/teams/new',
        MANAGE_TEAM: '/teams/:teamId/members/manage',
        TRAINING: '/teams/:teamId/training',
        TRAINING_PAGES: {
            PRACTIS_SETS: '/teams/:teamId/training/practis-sets',
            PRACTIS_SETS_DETAILS:
                '/teams/:teamId/training/practis-sets/:practisSetId',
            TRAINEE_INVITATION:
                '/teams/:teamId/training/practis-sets/:practisSetId/invite',
        },
    },
    OVERDUE_LEARNERS: '/overdue-learners',
    TRAINEE_DETAILS_REPS: '/user/performance/:userId/enrollments/:enrollmentId/scenario/:scenarioId',
    TRAINEE_DETAILS: '/user/performance/:userId/enrollments/:enrollmentId',
    USERS: '/users',
    USER_INVITATIONS: '/invitations',
    USER_PERFORMANCE: '/user/performance/:userId',
    INVITATION_PERFORMANCE: '/invitation/performance/:userId',
    INVITATIONS: '/invitations',
    DRAFTS: '/drafts',
    PRIVACY: '/privacy',
    TERMS: '/terms',
    SUPPORT: '/support',
    Health: '/health',
    BROKEN_SHARED_LINK: '/broken-shared-link',
    MOBILE_DEEP_LINKS: {
        PRACTIS_SET_INVITATION: '/practis-set-invitation',
        SUBMISSIONS: '/submissions',
        V2_SUBMISSIONS: '/v2/submissions',
        CHALLENGE_FEEDBACK: '/challenge-feedback',
        V2_CHALLENGE_FEEDBACK: '/v2/challenge-feedback',
        PRACTIS_SET_DETAILS: '/practis-set-details',
    },
    REPORTS: {
        LIST_OF_REPORTS: '/reports',
        PRACTIS_SET_SUMMARY_REPORT: '/reports/practis-set-summary',
        USER_ACTIVITY_REPORT: '/reports/user-activity',
        TEAM_LEADER_ACTIVITY_REPORT: '/reports/team-leader-activity',
        BILLING_REPORT: '/reports/billing',
        TRAINING_TIME_REPORT: '/reports/training-time',
    },
    ROLEPLAYS: {
        LIST_OF_ROLEPLAYS: '/roleplays',
        NEW_ROLEPLAY: '/roleplay/create',
        SINGLE: '/roleplay/:roleplayId',
    }
};

export default ROUTES;

export const MEID = 'me';

export function useUserIdMatch(): number | 'me' {
    const {
        params: { userId },
    } = useRouteMatch<{ userId?: string }>();
    return userId && userId !== MEID ? parseInt(userId) : (userId as 'me');
}

export function replaceUserId(path: string, userId: number | 'me') {
    return path.replace(':userId', userId.toString());
}

export function isMe(userId: number | 'me') {
    return userId === MEID;
}
