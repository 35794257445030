import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoFiltersIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 43 40"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M22.857 0v2.98c3.26.658 5.714 3.518 5.714 6.948 0 3.43-2.454 6.29-5.714 6.949V39.71H20V16.877c-3.26-.658-5.715-3.519-5.715-6.949 0-3.43 2.455-6.291 5.715-6.948V0h2.857zM8.57 0v17.162c3.26.657 5.714 3.518 5.714 6.949 0 3.43-2.454 6.29-5.714 6.948v8.651H5.713V31.06C2.454 30.4 0 27.539 0 24.11c0-3.431 2.454-6.293 5.713-6.949V.001h2.858zM7.143 19.857c-2.367 0-4.286 1.904-4.286 4.255 0 2.348 1.919 4.253 4.286 4.253 2.366 0 4.285-1.905 4.285-4.253 0-2.35-1.919-4.255-4.285-4.255zM37.14 0v22.626h-2.856V0h2.856zM21.428 5.674c-2.367 0-4.285 1.904-4.285 4.254 0 2.35 1.918 4.254 4.285 4.254s4.286-1.904 4.286-4.254c0-2.35-1.92-4.254-4.286-4.254zM32.083 40L30 38.137 34.289 33.795 30 29.451 32.083 27.591 36.25 31.81 40.416 27.591 42.5 29.451 38.21 33.795 42.5 38.137 40.416 40 36.25 35.781z"
                                        transform="translate(-699 -583) translate(636 583) translate(63)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoFiltersIcon;
