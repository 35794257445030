import { UserInfo } from './UserInfo';

export interface TeamMember {
    id: number;
    teamId: number;
    user: UserInfo;
    userStatus: string;
    labels: number[];
    accuracy?: number
    isOverdue: boolean
    lastTraining: null | Date;
    trainingTime?: number
    enrollmentCompleted: number;
    enrollmentEnrolled: number;
    enrollmentNotStarted: number;
    isTeamLead?: boolean;
}

export enum TeamMemberStatusEnum {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE'
}