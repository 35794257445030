import { useEffect } from 'react';

import { Button } from '../Button';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { ActionsContainer, Description, Title } from './styles';
import { BulkActionStopConfirmationModalViewProps } from './types';

function StopBulkActionModalView(
    props: BulkActionStopConfirmationModalViewProps
) {
    const { modalTitle, dataTest, onStopBulkActionService, onCancel } = props;


    useEffect(() => {
        return () => {
            onCancel();
        };
    }, [onCancel]);
    

    return (
        <DialogWrapper customization={{ width: 402, padding: '35px 40px' }}>
            <Title
                color="var(--ps-red-main)"
                data-test={dataTest && `${dataTest}-stop-title`}
            >
                Stop: {modalTitle}?
            </Title>

            <Description data-test={dataTest && `${dataTest}-stop-description`}>
                The operation hasn't been completed yet. If you stop performing
                this operation, some data won't have been processed.
            </Description>

            <ActionsContainer>
                <Button
                    width="128px"
                    height="40px"
                    action={onCancel}
                    variant="inverse"
                    color="warning-inverse"
                    customColor="var(--ps-red-main)"
                    dataTest={dataTest && `${dataTest}-cancel-stop`}
                >
                    Cancel
                </Button>
                <Button
                    width="128px"
                    height="40px"
                    action={onStopBulkActionService}
                    color="warning-inverse"
                    customColor="var(--ps-red-main)"
                    style={{ marginLeft: '15px' }}
                    dataTest={dataTest && `${dataTest}-confirm-stop`}
                >
                    Stop
                </Button>
            </ActionsContainer>
        </DialogWrapper>
    );
}

export default StopBulkActionModalView;
