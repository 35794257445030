import { memo } from 'react';
import { useParams } from 'react-router-dom';
import OldSubmissionRouter from '../components/OldSubmissionRouter';
import ROUTES from '../../../routes/routes';

function OldAccuracyTestController() {
    const params = useParams<{ submissionId: string }>();

    return <OldSubmissionRouter
        id={params.submissionId}
        type="scenario"
        singleRoute={ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.SINGLE}
        allRoute={ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.ALL}
    />;
}

export default memo(OldAccuracyTestController)