import { UserProfile } from '../../../constants/interfaces/User';

export const USER_PROFILE = "FETCH_USER_PROFILE";
export const USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";
export const RESET_PROFILE_INFORMATION = "RESET_PROFILE_INFORMATION";

export function fetchUserProfile() {
    return {
        type: USER_PROFILE
    }
}

export function fetchUserProfileSuccess(data: UserProfile) {
    return {
        type: USER_PROFILE_SUCCESS,
        data
    }
}

export function fetchUserProfileFailure(error: any) {
    return {
        type: USER_PROFILE_FAILURE,
        error
    }
}

export function updateUserProfile() {
    return {
        type: UPDATE_USER_PROFILE
    }
}

export function updateUserProfileSuccess(data: any) {
    return {
        type: UPDATE_USER_PROFILE_SUCCESS,
        data
    }
}

export function updateUserProfileFailure(error: any) {
    return {
        type: UPDATE_USER_PROFILE_FAILURE,
        error
    }
}

export function resetProfileInfo() {
    return {
        type: RESET_PROFILE_INFORMATION,
    }
}