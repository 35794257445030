import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../../ui/components/Button';
import { TeamUpdateStatus } from '../../../../store/states';
import UpdateStatus from '../UpdateStatus';

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    height: 95px;
    color: ${props => props.theme.Colors.dark};
    flex-shrink: 0;
`;

const StyledHeaderTitle = styled.div`
    flex: 1;
    min-width: 150px;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 8px;
`;

const HeaderActions = styled.div`
    display: flex;
    align-items: center;
`;

const Header: FC<{
    handleCancel: () => void;
    lastUpdated?: string;
    updateStatus?: TeamUpdateStatus
}> = ({
    handleCancel,
    lastUpdated,
    updateStatus
}) => {
    return (
        <StyledHeader>
            <StyledHeaderTitle data-test='team-page-title'>
                Manage Team
            </StyledHeaderTitle>
            <HeaderActions>
                <UpdateStatus
                    updateStatus={updateStatus}
                    lastUpdated={lastUpdated}
                ></UpdateStatus>
                <Button
                    label={'Close'}
                    variant={'inverse'}
                    width={'128px'}
                    action={handleCancel}
                    dataTest='team-cancel'
                />
            </HeaderActions>
        </StyledHeader>
    );
};

export default Header;
