import React, { MouseEvent, FC } from 'react';
import styled from 'styled-components';
import { ActionContext } from './ActionContext';
import { ACTION_ITEM_HEIGHT } from './constants';

//region Styles
const Item = styled.div<{ disabled?: boolean }>`
    min-height: ${ACTION_ITEM_HEIGHT}px;
    padding: 8px 16px;
    font-weight: normal;
    cursor: pointer;
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    align-items: center;
    ${props => props.disabled && `pointer-events: none`}
    &:hover {
        background: ${props => props.theme.Colors.whiteTwo};
    }
    &:active {
        background: ${props => props.theme.Colors.paleGrey};
    }
`;

const Content = styled.span<{ type?: 'warning'; disabled?: boolean }>`
    font-size: 13px;
    font-weight: 500;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.type === 'warning'
            ? props.theme.Colors.tomato
            : props.theme.Colors.black};
`;
//endregion

export const ActionItem: FC<{
    destructive?: boolean;
    onClick?(e: MouseEvent<HTMLDivElement>): void;
    disabled?: boolean;
    dataTest?: string;
}> = ({ destructive, children, onClick, disabled, dataTest }) => {
    return (
        <ActionContext.Consumer>
            {context => (
                <Item
                    onClick={e => {
                        if (disabled) return;
                        if (context.toggleOpen) context.toggleOpen(false);
                        if (onClick) onClick(e);
                    }}
                    disabled={disabled}
                    data-test={dataTest}
                >
                    <Content
                        type={destructive ? 'warning' : undefined}
                        disabled={disabled}
                    >
                        {children}
                    </Content>
                </Item>
            )}
        </ActionContext.Consumer>
    );
};
