import { FC } from 'react';

import {
    NoResultsIcon,
    NoResultsTitle,
    StyledNoResults,
    StyledNoResultsContent,
} from './styles';

export const TableNoResult: FC<{
    icon?: any;
    width?: string;
    subTitle?: string;
    subTitleWidth?: string;
    dataTest?: string;
}> = ({ icon: Icon, width, dataTest, children }) => (
    <StyledNoResults>
        <StyledNoResultsContent>
            {Icon && (
                <NoResultsIcon data-test={dataTest && `${dataTest}-icon`}>
                    <Icon />
                </NoResultsIcon>
            )}
            <NoResultsTitle
                width={width}
                data-test={dataTest && `${dataTest}-text`}
            >
                {children}
            </NoResultsTitle>
        </StyledNoResultsContent>
    </StyledNoResults>
);
