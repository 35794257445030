import styled from 'styled-components';
import { Copyright } from '../../../../ui/components/Copyright';


const RightContentContainer = styled.div`
    flex: 2;
    background: ${props => props.theme.Colors.white};
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
    z-index: 2;
`;

const Body = styled.div`
    display: flex;
    flex: 1;
`;

const Content = styled.div`
    padding: 40px 40px 0 40px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.div`
    font-size: 24px;
    margin-bottom: 16px;
    color: ${props => props.theme.Colors.black};
    line-height: 1.2;
    font-family: 'Merriweather';
    text-align: center;
`;

const Description = styled.div`
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 24px;
    color: ${props => props.theme.Colors.black};
    line-height: 1.5;
    text-align: center;
`;


const Footer = styled.div`
    font-size: 11px;
    font-weight: 300;
    color: ${props => props.theme.Colors.black};
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
`;

const RightPanel = () => {
    return (
        <RightContentContainer>
            <Body>
                <Content>
                    <Title>You need Practis.</Title>
                    <Description>
                        Practis is a system for large teams to learn, retain,
                        and effectively articulate messaging on the front lines.
                    </Description>
                </Content>
            </Body>
            <Footer><Copyright /></Footer>
        </RightContentContainer>
    );
};

export default RightPanel;
