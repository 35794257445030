import React from 'react';
import styled from 'styled-components';
import ScenarioInfo from './ScenarioInfo/ScenarioInfo';
import ScenarioScripts from './ScenarioScript/ScenarioScripts';
import { Scenario } from '../../../../../constants/interfaces/Scenario';

export interface CustomBodyInterface {
    scenario: Scenario;
    createScriptLine: any;
}

const CustomBodyContainerWrapper = styled.div`
    display: flex;
`;

const CustomBodyContainer = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
`;

const CustomBody = ({ scenario, createScriptLine }: CustomBodyInterface) => {
    return (
        <CustomBodyContainerWrapper>
            <CustomBodyContainer>
                <ScenarioInfo scenario={scenario} />
                <ScenarioScripts
                    scenario={scenario}
                    createScriptLine={createScriptLine}
                />
            </CustomBodyContainer>
        </CustomBodyContainerWrapper>
    );
};

export default CustomBody;
