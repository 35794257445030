import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100%;
`;

export const TableContainer = styled.div`
    width: 100%;
`;

export const TableExtendContainer = styled.div`
    border-radius: 16px;
    display: block;
    background: ${props => props.theme.Colors.white};
`;
