import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useUserRolesState } from '../roles/store/states';
import { useCalculatePreSelectedUserRoles } from './tools';
import { DraftUser } from '../../constants/interfaces/Draft';
import { PaginationResult } from '../../constants/interfaces/PaginationResult';
import { invitationRoles } from '../../pages/ActionPages/NewUser/tools';
import { Variables } from '../../theme/variables';
import Checkbox from '../../ui/components/Checkbox';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const CheckBoxWrapper = styled.div`
    margin-bottom: 8px;
`;

const PortableRolesMultiselect: FC<{
    users?: PaginationResult<DraftUser>;
    selectedUsers?: number[];
    selectedRoles: number[];
    setSelectedRoles(value: number[]): void;
}> = ({ users, selectedUsers, selectedRoles, setSelectedRoles }) => {
    const userRoles = useUserRolesState();
    const roles = invitationRoles(userRoles.list);
    const calculatePreSelectedUserRoles = useCalculatePreSelectedUserRoles();

    useEffect(() => {
        if (users && users.items && selectedUsers) {
            const preSelectedUserRoles = calculatePreSelectedUserRoles(
                selectedUsers,
                users.items as any // TODO: ask
            );

            setSelectedRoles(preSelectedUserRoles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, selectedUsers, calculatePreSelectedUserRoles]);

    const handleSelectItem = (value: number) => {
        if (selectedRoles.includes(value)) {
            setSelectedRoles(selectedRoles.filter(roleId => roleId !== value));
        } else {
            const updatedRoles = [...selectedRoles];
            updatedRoles.push(value);
            setSelectedRoles(updatedRoles);
        }
    };

    return (
        <Container>
            {roles.map(item => {
                return (
                    <CheckBoxWrapper key={item.value}>
                        <Checkbox
                            key={item.value}
                            handleChange={() => handleSelectItem(item.value)}
                            checked={selectedRoles.includes(item.value)}
                            size={12}
                            label={item.name}
                            fontSize={13}
                            fontColor={Variables.Colors.black}
                            fontWeight={'500'}
                            dataTest={`${item.name.toLowerCase()}-role-checkbox`}
                        />
                    </CheckBoxWrapper>
                );
            })}
        </Container>
    );
};

export default PortableRolesMultiselect;
