import { kebabCase } from 'lodash';
import StyledInput from './components/SearchInput';
import Close from '../../../ui/icons/Close';
import {
    Container,
    Title,
    ShowSearch,
    SearchIcon,
    TitleSkeleton,
    IconSkeleton,
    InputContainer,
    SearchButtons,
    SearchClose,
    ClearButton,
    ClearSeparator,
} from './styles';
import { SearchableFilterHeaderViewProps } from './types';

function SearchableFilterHeaderView({
    title,
    isLoading,
    hasSearch,
    isDisabled,
    disableSearch,
    showSearchInput,
    searchTerm,
    onShowSearch,
    onHideSearch,
    onSearch,
    onClear,
    inputRef,
}: SearchableFilterHeaderViewProps) {
    return (
        <Container>
            {showSearchInput ? (
                <InputContainer>
                    <StyledInput
                        inputRef={inputRef}
                        value={searchTerm}
                        onChange={onSearch}
                        onExit={onHideSearch}
                    />
                    <SearchButtons>
                        {!!searchTerm && <>
                            <ClearButton
                                onClick={onClear}
                                data-test="filter-search-input-clear"
                            >
                                Clear
                            </ClearButton>
                            <ClearSeparator />
                        </>}
                        <SearchClose
                            onClick={onHideSearch}
                            data-test="filter-search-input-close"
                        >
                            <Close type='thin' />
                        </SearchClose>
                    </SearchButtons>
                </InputContainer>
            ) : isLoading ? (
                <>
                    <TitleSkeleton width={119} />
                    <IconSkeleton size={16} />
                </>
            ) : (
                <>
                    <Title
                        isDisabled={isDisabled}
                        data-test={`${kebabCase(title)}-filter-title`}
                    >
                        {title}
                    </Title>
                    {hasSearch && (
                        <ShowSearch onClick={onShowSearch} data-test="filter-search-icon">
                            <SearchIcon isDisabled={isDisabled || disableSearch} />
                        </ShowSearch>
                    )}
                </>
            )}
        </Container>
    );
}

export default SearchableFilterHeaderView;