import { CompanyInterface } from '../../../constants/interfaces/Company';
import { ListResult, PaginationResult } from '../../../constants/interfaces/PaginationResult';

export enum ACTIONS {
    SEARCH_COMPANIES_START = 'ADMIN_SEARCH_COMPANIES_START',
    SEARCH_COMPANIES_SUCCESS = 'ADMIN_SEARCH_COMPANIES_SUCCESS',
    SEARCH_COMPANIES_FAILURE = 'ADMIN_SEARCH_COMPANIES_FAILURE',
}

export enum ADMIN_ACTIONS {
    CHANGE_COMPANY = 'CHANGE_COMPANY',
}

export function searchCompaniesStart() {
    return {
        type: ACTIONS.SEARCH_COMPANIES_START as ACTIONS.SEARCH_COMPANIES_START,
    };
}

export function searchCompaniesSuccess(
    companies: PaginationResult<CompanyInterface>
) {
    return {
        type: ACTIONS.SEARCH_COMPANIES_SUCCESS as ACTIONS.SEARCH_COMPANIES_SUCCESS,
        companies,
    };
}

export function searchCompaniesFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_COMPANIES_FAILURE as ACTIONS.SEARCH_COMPANIES_FAILURE,
        error,
    };
}

export const SEARCH_ALL_COMPANIES_START = 'SEARCH_ALL_COMPANIES_START';
export const SEARCH_ALL_COMPANIES_SUCCESS = 'SEARCH_ALL_COMPANIES_SUCCESS';
export const SEARCH_ALL_COMPANIES_FAILURE = 'SEARCH_ALL_COMPANIES_FAILURE';
export const FETCH_ACTIVE_COMPANIES_START = 'FETCH_ACTIVE_COMPANIES_START';
export const FETCH_ACTIVE_COMPANIES_SUCCESS = 'FETCH_ACTIVE_COMPANIES_SUCCESS';
export const FETCH_ACTIVE_COMPANIES_FAILURE = 'FETCH_ACTIVE_COMPANIES_FAILURE';

export function adminChangeCompanyAction() {
    return {
        type: ADMIN_ACTIONS.CHANGE_COMPANY as ADMIN_ACTIONS.CHANGE_COMPANY,
    };
}

export function searchAllCompaniesStartAction() {
    return {
        type: SEARCH_ALL_COMPANIES_START,
    };
}

export function searchAllCompaniesSuccessAction(data: any) {
    return {
        type: SEARCH_ALL_COMPANIES_SUCCESS,
        data,
    };
}

export function searchAllCompaniesFailureAction(error: any) {
    return {
        type: SEARCH_ALL_COMPANIES_FAILURE,
        error,
    };
}

export function fetchActiveCompaniesStart() {
    return {
        type: FETCH_ACTIVE_COMPANIES_START,
    };
}

export function fetchActiveCompaniesSuccess(data: ListResult<CompanyInterface>) {
    return {
        type: FETCH_ACTIVE_COMPANIES_SUCCESS,
        data,
    };
}

export function fetchActiveCompaniesFailure(error: string) {
    return {
        type: FETCH_ACTIVE_COMPANIES_FAILURE,
        error,
    };
}