import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ObjectiveIconActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (<svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#8xe4jq1bga)" fill="#212121">
            <path d="M7.678 7.438a1.876 1.876 0 0 0-2.681 1.69c0 1.034.84 1.875 1.873 1.875a1.876 1.876 0 0 0 1.691-2.681l2.004-2.004h2.498L16 3.382l-2.874-.507L12.618 0 9.68 2.936v2.499L7.678 7.438zM6.87 9.754a.625.625 0 1 1 .002-1.251.625.625 0 0 1-.002 1.25zm4.06-6.3.874-.874.243 1.373 1.372.242-.873.874H10.93V3.454z"/>
            <path d="M12.274 7.567A5.628 5.628 0 0 1 6.87 14.75 5.626 5.626 0 0 1 1.249 9.13a5.628 5.628 0 0 1 7.183-5.402v-1.29A6.879 6.879 0 0 0 0 9.128a6.874 6.874 0 0 0 6.87 6.87 6.879 6.879 0 0 0 6.872-6.87 6.84 6.84 0 0 0-.182-1.562h-1.286z"/>
            <path d="M6.87 6.005c.15 0 .298.011.443.032L8.339 5.01A4.377 4.377 0 0 0 2.5 9.128a4.377 4.377 0 0 0 4.372 4.373 4.377 4.377 0 0 0 4.118-5.841L9.963 8.687a3.127 3.127 0 0 1-3.092 3.565 3.127 3.127 0 0 1-3.123-3.124A3.127 3.127 0 0 1 6.87 6.005z"/>
        </g>
        <defs>
            <clipPath id="8xe4jq1bga">
                <path fill="#fff" d="M0 0h16v16H0z"/>
            </clipPath>
        </defs>
    </svg> );
    }
);

export default ObjectiveIconActive;