import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { listToTree } from '../../../../helpers/functions/list-to-tree';
import {
    useSaveUserFiltersService,
    useSetLabelFilterActionService,
} from '../../../../features/labels/store/services';
import { useHandleSelectLabels } from '../../../../features/labels/tools';
import { usePermissionsState } from '../../../../features/permissions/store/state';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';

import { usePortableLabelsState } from '../../../../features/portableLabels/store/states';
import { TreeDfsNameMapper } from '../../../../helpers/classes/TreeDfsNameMapper';
import { FilteredByNameType, TableHeaderFilterControllerProps } from './type';
import { Label } from '../../../../constants/interfaces/Label';
import TableHeaderFilterView from './view';

const TableHeaderFilterController: FC<TableHeaderFilterControllerProps> = props => {
    const { selectedLabels, onRemoveLabel } = props;

    const labelsList = usePortableLabelsState().data;
    const setLabelFilters = useSetLabelFilterActionService();
    const handleSelectLabels = useHandleSelectLabels();
    const permissions = usePermissionsState();
    const saveUserFilters = useSaveUserFiltersService();
    const [savingLabels, setSavingLabels] = useState(false);
    const [filteredByNames, setFilteredByNames] = useState<
        FilteredByNameType[]
    >([]);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const wrapperRef = useRef<any>(null);

    /**
     * @function handleLabelSelection
     * @param { Label } label
     * @returns { void }
     */
    const handleLabelSelection = (label: Label): void => {
        if (selectedLabels) {
            const labelsTree = listToTree(labelsList?.items);
            setLabelFilters(
                handleSelectLabels(label, selectedLabels, labelsTree)
            );
            setSavingLabels(true);
        }
    };

    /**
     * @function setShowNavArrows
     * @returns { void }
     */
    const setShowNavArrows = useCallback(() => {
        const element = wrapperRef?.current;
        if (!element) return;
        const isLeftArrowVisible = element.scrollLeft > 0;
        const isRightArrowVisible = element.scrollWidth > element.clientWidth
            && element.scrollLeft + element.clientWidth <= element.scrollWidth;
        setShowLeftArrow(isLeftArrowVisible);
        setShowRightArrow(isRightArrowVisible);
    }, []);

    useEffect(() => {
        const selectedItems = (selectedLabels || []).map(item =>
            item >= 0 ? item : Math.abs(item)
        );

        if (!!labelsList && labelsList.items.length > 0 && selectedItems) {
            const treeDfsNameMapper = new TreeDfsNameMapper(
                labelsList.items,
                selectedItems
            );

            const names = treeDfsNameMapper.getNames();
            setFilteredByNames(names);
        }
        setTimeout(setShowNavArrows, 0);
    }, [labelsList, selectedLabels, setShowNavArrows]);

    useEffect(() => {
        setShowNavArrows();
        window.addEventListener('resize', setShowNavArrows);
        return () => {
            window.removeEventListener('resize', setShowNavArrows);
        };
    }, [setShowNavArrows]);

    useEffect(() => {
        if (
            savingLabels &&
            selectedLabels &&
            permissions.includes(NEW_PERMISSIONS.SAVE_SEARCH_FILTER)
        ) {
            saveUserFilters(selectedLabels);
            setSavingLabels(false);
        }
    }, [
        selectedLabels,
        savingLabels,
        setSavingLabels,
        saveUserFilters,
        permissions,
    ]);

    if (!selectedLabels || selectedLabels.length < 1) {
        return <></>;
    }

    return (
        <TableHeaderFilterView
            onLabelRemove={onRemoveLabel ? onRemoveLabel : handleLabelSelection}
            showLeftArrow={showLeftArrow}
            showRightArrow={showRightArrow}
            wrapperRef={wrapperRef}
            handleScroll={setShowNavArrows}
            filteredByNames={filteredByNames}
        />
    );
};

export default TableHeaderFilterController;
