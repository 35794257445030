import { useCallback, useMemo, useState } from 'react';
import { useGenerateReportApi, useGetReportAvailabilityApi } from '../../../api/reports';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ReportData } from '../../../api/reports/types';
import useCountdown from '../../../helpers/hooks/useCountdown';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { isPractisAdminRole } from '../../../constants/enums';

/**
 * @function useGenerateReportService
 * @returns {{
 *    isGenerating: boolean;
 *    generateReport: (reportType: string) => void;
 * }}
 */
export function useGenerateReportService() {
    const { value: remainingTimeout, start: startCountDown } = useCountdown(60);
    const [isGenerating, setIsGenerating] = useState(false);
    const profile = useSelector(getProfileState);

    const generateReportApi = useGenerateReportApi();
    const showMessage = useShowMessage();

    const generateReport = useCallback(
        (reportType: string, reportData: ReportData) => {
            setIsGenerating(true);
            generateReportApi(reportType, reportData)
                .then(() => {
                    showMessage(
                        'The report is being generated. Check your email in a few minutes.',
                        'success'
                    );
                    if (!isPractisAdminRole(profile?.role?.name)) {
                        startCountDown();
                    }
                    setIsGenerating(false);
                })
                .catch((error: ErrorResult) => {
                    setIsGenerating(false);
                    error?.message && showMessage(error.message, 'error');
                });
        },
        [generateReportApi, profile?.role?.name, showMessage, startCountDown]
    );

    return { isGenerating, remainingTimeout, generateReport };
}

const REPORT_AVAILABILITY_DEBOUNCE_DELAY = 250;
/**
 * @function useGetReportAvailabilityService
 * @returns {{
*    isAvailable: boolean;
*    getReportAvailability: (reportType: string, reportData: ReportData) => void;
* }}
*/
export function useGetReportAvailabilityService() {
    const [isAvailable, setIsAvailable] = useState(true);
    const getReportAvailabilityApi = useGetReportAvailabilityApi();
    const showMessage = useShowMessage();

    const getReportAvailability = useMemo(() =>
        debounce((reportType: string, reportData: ReportData) => {
            getReportAvailabilityApi(reportType, reportData)
                .then((result) => {
                    setIsAvailable(result.available);
                })
                .catch((error: ErrorResult) => {
                    error?.message && showMessage(error.message, 'error');
                });
        }, REPORT_AVAILABILITY_DEBOUNCE_DELAY),
        [getReportAvailabilityApi, showMessage]
    );

    return { isAvailable, getReportAvailability };
}