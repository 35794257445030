import styled from 'styled-components';

export const Container = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

export const LabelsContainer = styled.div`
    width: 208px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

export const TeamsContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

export const PractisSetsContainer = styled.div`
    width: 312px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

export const ButtonContainer = styled.div`
    margin-right: 16px;
`;

