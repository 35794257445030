import React from 'react'
import { range } from 'lodash';
import { ReportType } from '../../constants/interfaces/ReportItem';
import { ReportingCardProps } from '../../ui/components/ReportingCard/types';
import PractisSetSummary from '../../ui/icons/PractisSetSummary';
import TeamLeaderSummary from '../../ui/icons/TeamLeaderSummary';
import UserActivity from '../../ui/icons/UserActivity';
import ROUTES from '../../routes/routes';
import BillingReport from '../../ui/icons/BillingReport';
import { getTeamLeaderReportUrl, getTrainingTimeReportUrl } from './helpers';
import TrainingTime from '../../ui/icons/TrainingTime';

export const LOADING_ITEMS_COUNT = 3;
export const LOADING_ITEMS = range(0, LOADING_ITEMS_COUNT);

export const ReportInfoMap: Record<ReportType, ReportingCardProps> = {
    [ReportType.PRACTIS_SET_SUMMARY]: {
        url: ROUTES.REPORTS.PRACTIS_SET_SUMMARY_REPORT,
        icon: React.createElement(PractisSetSummary),
        title: 'Practis Set Summary',
        description: 'Shows more information about a particular practis set.',
        resultFormat: 'Excel'
    },
    [ReportType.USERS_ACTIVITY]: {
        url: ROUTES.REPORTS.USER_ACTIVITY_REPORT,
        icon: React.createElement(UserActivity),
        title: 'User Activity',
        description: 'Understand your team(s) progress on all their Practis Sets.',
        resultFormat: 'Excel'
    },
    [ReportType.TEAM_LEADER_ACTIVITY]: {
        url: getTeamLeaderReportUrl(),
        icon: React.createElement(TeamLeaderSummary),
        title: 'Team Leader Engagement',
        description: 'How engaged are Team Leaders in their learner\'s training.',
        resultFormat: 'Excel'
    },
    [ReportType.BILLING]: {
        url: ROUTES.REPORTS.BILLING_REPORT,
        icon: React.createElement(BillingReport),
        title: 'Billing Report',
        description: 'Generate a list of monthly active users on the platform.',
        resultFormat: 'Excel'
    },
    [ReportType.TRAINING_TIME]: {
        url: getTrainingTimeReportUrl(),
        icon: React.createElement(TrainingTime),
        title: 'Training Time Report',
        description: 'Tracking overall training time by learners by date range.',
        resultFormat: 'Excel'
    },
}