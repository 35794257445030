import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Customer = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 19 20"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M18.194 20H.564c-.977-1.499-.581-4.498.397-5.497.465-.476 3.956-1.376 5.627-1.786-1.765-1.37-2.84-3.912-2.84-6.337C3.748 2.857 4.77 0 9.377 0s5.63 2.857 5.63 6.38c0 2.417-1.07 4.953-2.826 6.326 1.633.407 5.176 1.332 5.627 1.797.97.999 1.356 3.998.386 5.497zm-1.19-4.443c-.64-.344-3.399-1.118-5.75-1.682v-1.98c1.567-1.067 2.496-3.385 2.496-5.491 0-2.85-.729-5.159-4.373-5.159s-4.373 2.31-4.373 5.159c0 2.108.93 4.427 2.5 5.493v1.996c-2.364.556-5.108 1.317-5.74 1.663-.408.515-.764 2.165-.511 3.196h16.251c.252-1.031-.097-2.676-.5-3.195z"
                />
            </svg>
        );
    }
);

export default Customer;
