import { FilterContainer } from '../../filter-container';
import { MonthSelector } from './components/month-selector';
import { YearSelector } from './components/year-selector';
import { MIN_MONTH_FILTER_YEAR } from './constants';
import {
    Container,
    MonthSelectorContainer,
} from './styles';
import { MonthFilterViewProps } from './types';

function MonthFilterView({
    selectedMonth,
    selectedYear,
    currentYear,
    isDisabled,
    onSelectYear,
    onSelectMonth,
}: MonthFilterViewProps) {
    return (
        <FilterContainer
            headerProps={{
                title: 'Month',
                isDisabled
            }}
        >
            <Container>
                <YearSelector
                    selectedYear={currentYear}
                    minYear={MIN_MONTH_FILTER_YEAR}
                    isDisabled={isDisabled}
                    onChange={onSelectYear}
                />
                <MonthSelectorContainer>
                    <MonthSelector
                        selectedYear={selectedYear}
                        selectedMonth={selectedMonth}
                        currentYear={currentYear}
                        isDisabled={isDisabled}
                        onSelect={onSelectMonth}
                    />
                </MonthSelectorContainer>
            </Container>
        </FilterContainer>
    );
}

export default MonthFilterView;