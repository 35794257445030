import { UserV2 } from '../../../constants/interfaces/User';
import { AppState } from '../../../store/reducers';
import {
    ACTIONS,
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_FAILURE,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_USER,
    FETCH_USER_FAILURE,
    FETCH_USER_SUCCESS,
    fetchAllDrafts,
    fetchAllDraftsFailure,
    fetchAllDraftsSuccess,
    fetchCreatedByUsersFailure,
    fetchCreatedByUsersStart,
    fetchCreatedByUsersSuccess,
    fetchEditedByUsersFailure,
    fetchEditedByUsersStart,
    fetchEditedByUsersSuccess,
    getUserPerformanceFailure,
    getUserPerformanceStart,
    getUserPerformanceSuccess,
    resetUserPerformanceAction,
    searchInvitationsFailure,
    searchInvitationsStart,
    searchInvitationsSuccess,
    searchUserPractisSetsFailure,
    searchUserPractisSetsStart,
    searchUserPractisSetsSuccess,
    UPDATE_USER,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,
    updateAllPractisSetsCheckedState,
    updateAllSelectedDraftState,
    updateAllSelectedInvitationsState,
    updateDraftsFailure,
    updateDraftsStart,
    updateDraftsSuccess,
    updateInvitationFailure,
    updateInvitationStart,
    updateInvitationSuccess,
    updateSelectedDraftState,
    updateSelectedInvitationState,
    updateTraineeProfilePractisSetCheckedState,
    updateUserActionFailure,
    updateUserActionStart,
    updateUserActionSuccess,
    updateUserPractisSetsFailure,
    updateUserPractisSetsStart,
    updateUserPractisSetsSuccess,
    userLabelsUpdatedAction,
    fetchPortablePractisSetsStart,
    fetchPortablePractisSetsSuccess,
    fetchPortablePractisSetsFailure,
    fetchUserFeaturesStart,
    fetchUserFeaturesSuccess,
    fetchUserFeaturesFailure,
    removePendingUserLabel,
    fetchUserAllPractisSetsSuccess,
    CLEAR_ALL_USERS,
    fetchUserPreferencesStart,
    fetchUserPreferencesSuccess,
    fetchUserPreferencesFailure,
    updateUserPreferencesStart,
    updateUserPreferencesSuccess,
    updateUserPreferencesFailure,
} from './actions';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { compose, Reducer } from 'redux';
import {
    DraftsState,
    InvitationsState,
    PerformanceState,
    UpdatedDraftsState,
    UpdatedUserPractisSetsState,
    UpdateInvitationState,
    UpdateUsersState,
    UserPerformanceState,
    UserFeaturesState,
    UserPreferencesState
} from './states';
import {
    withLabelsInitialState,
    withLabelsReducer,
} from '../../portableLabels/store/hors/withLabels';
import {
    withTeamsInitialState,
    withTeamsReducer,
} from '../../portableTeams/store/hors/withTeams';
import {
    StateWithPractisSets,
    withPractisSetsInitialState,
    withPractisSetsReducer,
} from '../../portablePractisSets/store/hors/withPractisSets';

export interface AllUsersInterface {
    list: ListResult<UserV2>;
    selectedUsers?: number[];
    loading?: boolean;
    error?: string;
}

const initialState: AllUsersInterface = withLabelsInitialState(
    withTeamsInitialState(
        {
            list: {
                items: [],
                count: 0
            },
            selectedUsers: [],
            loading: false,
            loadingCreatedBy: false,
            loadingEditedBy: false,
            error: '',
        },
        'users'
    ),
    'users'
);

const allUsersBaseReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ALL_USERS:
            return {
                ...state,
                loading: true,
                error: '',
                reload: false,
            };
        case FETCH_ALL_USERS_SUCCESS:
            return {
                ...state,
                list: action.data,
                loading: false,
                error: '',
            };
        case FETCH_ALL_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CLEAR_ALL_USERS:
            return initialState;
            
        case ACTIONS.UPDATE_SELECTED_USER_STATE:
            const selectedUserIds = [...(state.selectedUsers || [])];

            const selectedUserIndex = selectedUserIds.findIndex(
                (item: number) => item === action.userId
            );
            if (selectedUserIndex > -1) {
                selectedUserIds.splice(selectedUserIndex, 1);
            } else {
                selectedUserIds.push(action.userId);
            }

            return {
                ...state,
                selectedUsers: selectedUserIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_USERS_STATE:
            return {
                ...state,
                selectedUsers:
                    action.checked || action.partial ? action.userIds : [],
            };
        case ACTIONS.REMOVE_USER_LABEL:
            const list = {
                ...state.list,
                items: state.list.items.map(item => {
                    if (item.id === action.userId)
                        return {
                            ...item,
                            labels: item.labels?.filter(
                                labelId => labelId !== action.labelId
                            ),
                        };
                    return item;
                }),
            };
            return {
                ...state,
                list,
            };
        default:
            return state;
    }
};

export const allUsersReducer = compose<Reducer<AllUsersInterface, any>>(
    withLabelsReducer({ reducerName: 'users' }),
    withTeamsReducer({ reducerName: 'users' }),
)(allUsersBaseReducer);

export const getAllUsersState = (state: AppState) => state.users.list;
export const getAllUsersLoading = (state: AppState) => state.users.loading;
export const getAllUsersError = (state: AppState) => state.users.error;

const initialUpdateUserState: UpdateUsersState = {};

export type updateUserAction =
    | ReturnType<typeof updateUserActionStart>
    | ReturnType<typeof updateUserActionSuccess>
    | ReturnType<typeof updateUserActionFailure>;

export const updatedUserReducer: Reducer<UpdateUsersState, updateUserAction> = (
    state = initialUpdateUserState,
    action: updateUserAction
): UpdateUsersState => {
    switch (action.type) {
        case ACTIONS.UPDATE_USER_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_USER_SUCCESS:
            return {
                ...state,
                data: action.user,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export interface UserState {
    info?: UserV2;
    isLoading?: boolean;
    error?: any;
}

const userInitialState: UserState = {
    info: undefined,
    isLoading: false,
    error: '',
};

export const userReducer = (state = userInitialState, action: any) => {
    switch (action.type) {
        case FETCH_USER:
            return {
                info: undefined,
                isLoading: true,
                error: null,
            };
        case FETCH_USER_SUCCESS:
            return {
                info: {
                    ...action.data,
                },
                isLoading: false,
                error: null,
            };
        case FETCH_USER_FAILURE:
            return {
                info: undefined,
                isLoading: false,
                error: action.error,
            };
        case UPDATE_USER:
            return {
                ...state,
                error: null,
            };
        case UPDATE_USER_SUCCESS:
            return {
                info: {
                    ...state.info,
                    ...action.data,
                },
                error: null,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export const getUserState = (state: AppState) => state.user.info;
export const getUserLoading = (state: AppState) => state.user.isLoading;
export const getUserError = (state: AppState) => state.user.error;

export interface InvitationsInterface {
    data: ListResult<UserV2>;
    selectedInvitations?: number[];
    loading?: boolean;
    error?: string;
}

const initialInvitationsState: InvitationsInterface = withLabelsInitialState(
    withTeamsInitialState(
        {
            data: {
                items: [],
                count: 0,
            },
            selectedInvitations: [],
            loading: false,
            error: '',
        },
        'invitations'
    ),
    'invitations'
);

export type invitationsAction =
    | ReturnType<typeof searchInvitationsStart>
    | ReturnType<typeof searchInvitationsSuccess>
    | ReturnType<typeof searchInvitationsFailure>
    | ReturnType<typeof updateSelectedInvitationState>
    | ReturnType<typeof updateAllSelectedInvitationsState>
    | ReturnType<typeof userLabelsUpdatedAction>
    | ReturnType<typeof removePendingUserLabel>;

export const invitationsBaseReducer = (
    state = initialInvitationsState,
    action: invitationsAction
): InvitationsState => {
    switch (action.type) {
        case ACTIONS.SEARCH_USER_INVITATIONS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_USER_INVITATIONS_SUCCESS:
            return {
                ...state,
                data: action.users,
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_USER_INVITATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.UPDATE_SELECTED_INVITATION_STATE:
            const selectedInvitationIds = [
                ...(state.selectedInvitations || []),
            ];

            const selectedInvitationIndex = selectedInvitationIds.findIndex(
                (item: number) => item === action.invitationId
            );
            if (selectedInvitationIndex > -1) {
                selectedInvitationIds.splice(selectedInvitationIndex, 1);
            } else {
                selectedInvitationIds.push(action.invitationId);
            }

            return {
                ...state,
                selectedInvitations: selectedInvitationIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_INVITATIONS_STATE:
            return {
                ...state,
                selectedInvitations:
                    action.checked || action.partial
                        ? action.invitationIds
                        : [],
            };
        case ACTIONS.REMOVE_PENDING_USER_LABEL:
            const data = {
                ...state.data,
                items: state.data.items.map(item => {
                    if (item.id === action.userId)
                        return {
                            ...item,
                            labels: item.labels?.filter(
                                labelId => labelId !== action.labelId
                            )
                        };
                    return item;
                })
            };
            return {
                ...state,
                data
            };
        default:
            return state;
    }
};

export const invitationsReducer = compose<Reducer<any, any>>(
    withLabelsReducer({ reducerName: 'invitations' }),
    withTeamsReducer({ reducerName: 'invitations' })
)(invitationsBaseReducer);

const initialUpdateInvitationState: UpdateInvitationState = {};

export type updateInvitationAction =
    | ReturnType<typeof updateInvitationStart>
    | ReturnType<typeof updateInvitationSuccess>
    | ReturnType<typeof updateInvitationFailure>;

export const updateInvitationsReducer: Reducer<
    UpdateInvitationState,
    updateInvitationAction
> = (
    state = initialUpdateInvitationState,
    action: updateInvitationAction
): UpdateInvitationState => {
    switch (action.type) {
        case ACTIONS.UPDATE_USER_INVITATION_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_USER_INVITATION_SUCCESS:
            return {
                ...state,
                data: action.invitation,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_USER_INVITATION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialUserPerformanceState = withLabelsInitialState<
    PerformanceState & StateWithPractisSets
>(
    withTeamsInitialState(
        withPractisSetsInitialState(
            {
                loading: false,
            },
            'performance'
        ),
        'performance'
    ),
    'performance'
);

export type userPerformanceAction =
    | ReturnType<typeof getUserPerformanceStart>
    | ReturnType<typeof getUserPerformanceSuccess>
    | ReturnType<typeof getUserPerformanceFailure>
    | ReturnType<typeof searchUserPractisSetsStart>
    | ReturnType<typeof searchUserPractisSetsSuccess>
    | ReturnType<typeof searchUserPractisSetsFailure>
    | ReturnType<typeof fetchPortablePractisSetsStart>
    | ReturnType<typeof fetchPortablePractisSetsSuccess>
    | ReturnType<typeof fetchPortablePractisSetsFailure>
    | ReturnType<typeof updateTraineeProfilePractisSetCheckedState>
    | ReturnType<typeof updateAllPractisSetsCheckedState>
    | ReturnType<typeof fetchUserAllPractisSetsSuccess>
    | ReturnType<typeof resetUserPerformanceAction>;

export const userPerformanceReducerBase: Reducer<
    UserPerformanceState,
    userPerformanceAction
> = (
    state = initialUserPerformanceState,
    action: userPerformanceAction
): UserPerformanceState => {
    switch (action.type) {
        case ACTIONS.GET_USER_PERFORMANCE_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.GET_USER_PERFORMANCE_SUCCESS:
            return {
                ...state,
                data: action.trainee,
                loading: false,
            };
        case ACTIONS.GET_USER_PERFORMANCE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ACTIONS.SEARCH_USER_PRACTIS_SETS_START:
            return {
                ...state,
                practisSetsLoading: true,
                error: '',
            };
        case ACTIONS.SEARCH_USER_PRACTIS_SETS_SUCCESS:
            return {
                ...state,
                practisSets: action.practisSets,
                practisSetsLoading: false,
                error: '',
            };
        case ACTIONS.SEARCH_USER_PRACTIS_SETS_FAILURE:
            return {
                ...state,
                practisSetsLoading: false,
                error: action.error,
            };
        case ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_START:
            return {
                ...state,
                portablePractisSetsLoading: true,
                error: '',
            };
        case ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_SUCCESS:
            return {
                ...state,
                portablePractisSets: action.practisSets.items,
                portablePractisSetsLoading: false,
                error: '',
            };
        case ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_FAILURE:
            return {
                ...state,
                portablePractisSetsLoading: false,
                error: action.error,
            };
        case ACTIONS.UPDATE_SELECTED_TRAINEE_PROFILE_PRACTIS_SET_STATE:
            const selectedIds = [...(state.selectedPractisSets || [])];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                selectedPractisSets: selectedIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_TRAINEE_PROFILE_PRACTIS_SETS_STATE:
            return {
                ...state,
                selectedPractisSets:
                    action.checked || action.partial
                        ? action.selectedPractisSetIds
                        : [],
            };

        case ACTIONS.FETCH_USER_ALL_PRACTIS_SETS:
            return {
                ...state,
                _practisSets: {
                    ...state._practisSets,
                    assignFilters: {
                        data: action.data,
                        selected: [],
                        saved: [],
                        loading: false,
                        error: '',
                    },
                },
            };
        case ACTIONS.RESET_USER_PERFORMANCE:
            return initialUserPerformanceState;

        default:
            return state;
    }
};

export const userPerformanceReducer = compose<
    Reducer<UserPerformanceState, userPerformanceAction>
>(
    withLabelsReducer({ reducerName: 'performance' }),
    withTeamsReducer({ reducerName: 'performance' }),
    withPractisSetsReducer({ reducerName: 'performance' })
)(userPerformanceReducerBase);

const initialUpdatedUserPractisiSetsState: UpdatedUserPractisSetsState = {};

export type updatedUserPractisSestsAction =
    | ReturnType<typeof updateUserPractisSetsStart>
    | ReturnType<typeof updateUserPractisSetsSuccess>
    | ReturnType<typeof updateUserPractisSetsFailure>;

export const updatedUserPractisSetsReducer: Reducer<
    UpdatedUserPractisSetsState,
    updatedUserPractisSestsAction
> = (
    state = initialUpdatedUserPractisiSetsState,
    action: updatedUserPractisSestsAction
): UpdatedUserPractisSetsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_USER_PRACTIS_SETS_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_USER_PRACTIS_SETS_SUCCESS:
            return {
                ...state,
                data: action.practisSets,
                loading: false,
            };
        case ACTIONS.UPDATE_USER_PRACTIS_SETS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialDraftsState: any = {
    data: {
        items: [],
    },
    draftedUsers: {
        items: [],
    },
    selectedDrafts: [],
    loading: false,
    error: '',
};

export type draftsAction =
    | ReturnType<typeof fetchAllDrafts>
    | ReturnType<typeof fetchAllDraftsSuccess>
    | ReturnType<typeof fetchAllDraftsFailure>
    | ReturnType<typeof updateSelectedDraftState>
    | ReturnType<typeof updateAllSelectedDraftState>
    | ReturnType<typeof fetchCreatedByUsersStart>
    | ReturnType<typeof fetchCreatedByUsersSuccess>
    | ReturnType<typeof fetchCreatedByUsersFailure>
    | ReturnType<typeof fetchEditedByUsersStart>
    | ReturnType<typeof fetchEditedByUsersSuccess>
    | ReturnType<typeof fetchEditedByUsersFailure>;

export const draftsReducer = (
    state = initialDraftsState,
    action: draftsAction
): DraftsState => {
    switch (action.type) {
        case ACTIONS.SEARCH_STAGING_DRAFTS:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_STAGING_DRAFTS_SUCCESS:
            const prevItems = state.data?.items;
            return {
                ...state,
                data:
                    action.data.offset === 0 || !prevItems
                        ? action.data
                        : {
                              ...action.data,
                              items: action.data.items,
                          },
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_STAGING_DRAFTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.UPDATE_SELECTED_DRAFT_STATE:
            const selectedDraftIds = [...(state.selectedDrafts || [])];

            const selectedDraftIndex = selectedDraftIds.findIndex(
                (item: number) => item === action.draftId
            );
            if (selectedDraftIndex > -1) {
                selectedDraftIds.splice(selectedDraftIndex, 1);
            } else {
                selectedDraftIds.push(action.draftId);
            }
            return {
                ...state,
                selectedDrafts: selectedDraftIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_DRAFT_STATE:
            return {
                ...state,
                selectedDrafts:
                    action.checked || action.partial ? action.draftIds : [],
            };
        case ACTIONS.FETCH_CREATED_BY_USERS_START:
            return {
                ...state,
                loadingCreatedBy: true,
            };
        case ACTIONS.FETCH_CREATED_BY_USERS_SUCCESS:
            return {
                ...state,
                createdByUsers: action.data,
                loadingCreatedBy: false,
            };
        case ACTIONS.FETCH_CREATED_BY_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingCreatedBy: false,
            };
        case ACTIONS.FETCH_EDITED_BY_USERS_START:
            return {
                ...state,
                loadingEditedBy: true,
            };
        case ACTIONS.FETCH_EDITED_BY_USERS_SUCCESS:
            return {
                ...state,
                editedByUsers: action.data,
                loadingEditedBy: false,
            };
        case ACTIONS.FETCH_EDITED_BY_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loadingEditedBy: false,
            };

        default:
            return state;
    }
};

const initialUpdatedDraftsState: UpdatedDraftsState = {};

export type updatedDraftsAction =
    | ReturnType<typeof updateDraftsStart>
    | ReturnType<typeof updateDraftsSuccess>
    | ReturnType<typeof updateDraftsFailure>;

export const updatedDraftsReducer: Reducer<
    UpdatedDraftsState,
    updatedDraftsAction
> = (
    state = initialUpdatedDraftsState,
    action: updatedDraftsAction
): UpdatedDraftsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_STAGING_DRAFTS_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_STAGING_DRAFTS_SUCCESS:
            return {
                ...state,
                data: action.draft,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_STAGING_DRAFTS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialUserFeaturesState: UserFeaturesState = {
    data: {},
    loading: false,
    error: ''
};

export type userFeaturesAction =
    | ReturnType<typeof fetchUserFeaturesStart>
    | ReturnType<typeof fetchUserFeaturesSuccess>
    | ReturnType<typeof fetchUserFeaturesFailure>;

export const userFeaturesReducer: Reducer<
    UserFeaturesState,
    userFeaturesAction
> = (
    state = initialUserFeaturesState,
    action: userFeaturesAction
): UserFeaturesState => {
    switch (action.type) {
        case ACTIONS.FETCH_USER_FEATURES_START:
            return {
                ...state,
                loading: true
            };
        case ACTIONS.FETCH_USER_FEATURES_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: ''
            };
        case ACTIONS.FETCH_USER_FEATURES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export const getUserFeaturesState = (state: AppState) => state.userFeatures.data;
export const getUserFeaturesLoading = (state: AppState) => state.userFeatures.loading;
export const getUserFeaturesError = (state: AppState) => state.userFeatures.error;

const initialUserPreferencesState: UserPreferencesState = {
    data: undefined,
    pendingValues: undefined,
    loading: false,
    error: ''
};

export type userPreferencesAction =
    | ReturnType<typeof fetchUserPreferencesStart>
    | ReturnType<typeof fetchUserPreferencesSuccess>
    | ReturnType<typeof fetchUserPreferencesFailure>
    | ReturnType<typeof updateUserPreferencesStart>
    | ReturnType<typeof updateUserPreferencesSuccess>
    | ReturnType<typeof updateUserPreferencesFailure>;

export const userPreferencesReducer: Reducer<
    UserPreferencesState,
    userPreferencesAction
> = (
    state = initialUserPreferencesState,
    action: userPreferencesAction
): UserPreferencesState => {
    switch (action.type) {
        case ACTIONS.FETCH_USER_PREFERENCES_START:
            return {
                ...state,
                loading: true
            };
        case ACTIONS.FETCH_USER_PREFERENCES_SUCCESS:
            return {
                data: action.data,
                pendingValues: undefined,
                loading: false,
                error: ''
            };
        case ACTIONS.FETCH_USER_PREFERENCES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ACTIONS.UPDATE_USER_PREFERENCES_START:
            return {
                ...state,
                pendingValues: state.pendingValues
                    ? state.pendingValues.concat(action.data)
                    : action.data
            };
        case ACTIONS.UPDATE_USER_PREFERENCES_SUCCESS:
            return {
                ...state,
                data: state.data?.map(item =>
                    action.keys.includes(item.key)
                        ? {
                            ...item,
                            value: state.pendingValues?.find(updatedItem =>
                                       item.key === updatedItem.key
                                   )?.value ?? item.value
                        }
                        : item
                ),
                pendingValues: state.pendingValues?.filter(item =>
                    !action.keys.includes(item.key)
                )
            };
        case ACTIONS.UPDATE_USER_PREFERENCES_FAILURE:
            return {
                ...state,
                pendingValues: state.pendingValues?.filter(item =>
                    !action.keys.includes(item.key)
                )
            };
        default:
            return state;
    }
};

export const getUserPreferencesState = (state: AppState) => state.userPreferences.data;
export const getUserPreferencesPendingValues = (state: AppState) => state.userPreferences.pendingValues;
export const getUserPreferencesLoading = (state: AppState) => state.userPreferences.loading;
export const getUserPreferencesError = (state: AppState) => state.userPreferences.error;