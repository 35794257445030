import mainImageDesktop from '../../../assets/images/Learner-Desktop_img.png';
import mainImageDesktop2x from '../../../assets/images/Learner-Desktop_img@2x.png';
import mainImageDesktop3x from '../../../assets/images/Learner-Desktop_img@3x.png';
import mainImageTablet from '../../../assets/images/Learner-Tablet_img.png';
import mainImageTablet2x from '../../../assets/images/Learner-Tablet_img@2x.png';
import mainImageTablet3x from '../../../assets/images/Learner-Tablet_img@3x.png';
import mainImagePhone from '../../../assets/images/Learner-Phone_img.png';
import mainImagePhone2x from '../../../assets/images/Learner-Phone_img@2x.png';
import mainImagePhone3x from '../../../assets/images/Learner-Phone_img@3x.png';
import appStoreImage from '../../../assets/images/button-app-store.png'
import googlePlayImage from '../../../assets/images/button-google-play.png'
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { useParams } from 'react-router';
import { Variables } from '../../../theme/variables';
import {
    Content,
    TextSection,
    Title,
    Description,
    DownloadButtons
} from './styles';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

function TraineeSplashView() {
    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.small;
    const isTablet = !isMobile && windowDimensions.width < Variables.BreakPoints.responsiveSm;
    const isDesktop= windowDimensions.width >= Variables.BreakPoints.responsiveSm;
    const { userName } = useParams<{ userName: string }>();

    useHtmlPageTitle('You don’t have permission to access this page');

    return (
        <LoginAuthWrapper heightToShowScroll={700}>
            <Content>
                <TextSection>
                    <Title>Hi {userName}</Title>
                    <Description>
                    Looks like you don’t have permission to log into the Practis Admin Portal. To access the
                     Learning app, download the Practis Mobile App from the App Store and log in through there.
                    </Description>
                    <DownloadButtons>
                        <a href="https://apps.apple.com/us/app/practis/id1499329539" target="_blank" rel="noreferrer">
                            <img src={appStoreImage} width="139px" height="40px" alt="Download on the App Store" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.gopractis.android" target="_blank" rel="noreferrer">
                            <img src={googlePlayImage} width="139px" height="40px" alt="Get it on Google Play" />
                        </a>
                    </DownloadButtons>
                </TextSection>
                {isMobile && (
                    <img
                        src={mainImagePhone}
                        srcSet={`${mainImagePhone2x} 2x, ${mainImagePhone3x} 3x`}
                        alt=""
                    />
                )}
                {isTablet && (
                    <img
                        src={mainImageTablet}
                        srcSet={`${mainImageTablet2x} 2x, ${mainImageTablet3x} 3x`}
                        alt=""
                    />
                )}
                {isDesktop && (
                    <img
                        src={mainImageDesktop}
                        srcSet={`${mainImageDesktop2x} 2x, ${mainImageDesktop3x} 3x`}
                        alt=""
                    />
                )}
            </Content>
        </LoginAuthWrapper>
    );
}

export default TraineeSplashView;