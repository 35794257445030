import {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';

import { SearchQueryParams } from '../../../../../../api/types';
import { AssignPractisSets } from '../../../../../../features/assign-practisSets';
import { usePractisSetsEnrollmentsService } from '../../../../../../features/users/store/services';

const AssignPractisSetsAndDueDateOptions: FC<{
    users?: number[];
    searchQuery?: SearchQueryParams;
    isSelectAll?: boolean;
    successApplyPractisSetsCallback?: () => void;
    setShowOptions: Dispatch<SetStateAction<boolean>>;
    totalCount?: number;
}> = ({
    users,
    searchQuery,
    isSelectAll,
    successApplyPractisSetsCallback,
    setShowOptions,
    totalCount
}) => {
    const [
        isAssignPractisSetPopupOpen,
        setIsAssignPractisSetPopupOpen,
    ] = useState(false);

    const getUserPractisSets = usePractisSetsEnrollmentsService(
        (users?.[0] as number).toString()
    );

    const triggerRef = useRef(null);

    /**
     * @function handleCloseAssignPractisSetPopup
     * @returns { void }
     */
    const handleCloseAssignPractisSetPopup = (): void => {
        Promise.resolve().then(() => {
            setIsAssignPractisSetPopupOpen(false);
            setShowOptions(false);
        });
    };

    /**
     * @function getUserPractisSetsSuccessCallback
     * @returns { void }
     */
    const getUserPractisSetsSuccessCallback = (): void => {
        setIsAssignPractisSetPopupOpen(true);
    };

    useEffect(() => {
        if (users?.length === 1) {
            getUserPractisSets(getUserPractisSetsSuccessCallback);
        } else {
            setIsAssignPractisSetPopupOpen(true);
        }
    }, [getUserPractisSets, users]);

    if (!isAssignPractisSetPopupOpen) {
        return <></>;
    }

    return (
        <AssignPractisSets
            users={users ?? []}
            searchQuery={searchQuery}
            isSelectAll={isSelectAll}
            successApplyPractisSetsCallback={successApplyPractisSetsCallback}
            triggerRef={triggerRef}
            isOpen={isAssignPractisSetPopupOpen}
            onClose={handleCloseAssignPractisSetPopup}
            isFromBulkAction
            totalCount={totalCount}
        />
    );
};

export default AssignPractisSetsAndDueDateOptions;
