import { FC } from 'react';
import { Slider, withStyles } from '@material-ui/core';

const CustomSlider = withStyles({
    root: {
        height: 4,
        padding: '8px 0',
    },
    rail: {
        height: 4,
        opacity: 0.18,
        borderRadius: 4,
        backgroundColor: 'var(--ps-white-1)',
        color: 'var(--ps-black)'
    },
    track: {
        height: 4,
        borderRadius: 4,
        backgroundColor: 'var(--ps-blue-main)',
    },
    thumb: {
        height: 16,
        width: 16,
        marginTop: -6,
        marginLeft: -7,
        backgroundColor: 'var(--ps-blue-main)',
        '&:focus': {
            boxShadow: `none`,
        },
        '&:hover': {
            boxShadow: `0 0 0 6px var(--ps-grey-12)`,
        },
        '&:active': {
            boxShadow: `0 0 0 8px var(--ps-grey-12)`,
        },
    },
})(Slider);

const VideoRangeSlider: FC<{
    played: number;
    onChange(e: any, newValue: any): void;
    onMouseDown(e: any): void;
    onChangeCommitted(e: any, newValue: any): void;
}> = ({ played, onChange, onChangeCommitted, onMouseDown }) => {
    return (
        <CustomSlider
            min={0}
            max={1}
            step={0.01}
            value={played}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            onMouseDown={onMouseDown}
        />
    );
};

export default VideoRangeSlider;
