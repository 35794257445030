import Gear from '../../icons/Gear';
import { CompanyUserStatsTextViewProps } from './types';
import { GearButton, Text } from './styles';

function CompanyUserStatsTextView({
    userStats,
    showUnlimited,
    showSettingsButton,
    style,
    onSettingsClick,
}: CompanyUserStatsTextViewProps) {
    return (
        <Text style={style}>
            {userStats?.limit ? (
                <>
                <span data-test="user-stats-text">
                    <span data-test="user-stats-total">{userStats.total}</span>
                    &nbsp;of&nbsp;
                    <span data-test="user-stats-limit">{userStats.limit}</span>
                    &nbsp;licensed seats have been used
                </span>
                {showSettingsButton && (
                    <GearButton
                        onClick={onSettingsClick}
                        data-test="user-limit-settings-button"
                    >
                        <Gear />
                    </GearButton>
                )}
                </>
            ) : (showUnlimited && (
                <span data-test="user-stats-text">Unlimited licensed seats</span>
            ))}
        </Text>
    );
}

export default CompanyUserStatsTextView;