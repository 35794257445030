import React, { FC } from 'react';
import styled from 'styled-components';
import { usePortableTeamsState } from '../../portableTeams/store/states';
import { Popup } from '../../../ui/components/Popup';
import { AssignTeams } from '../../portableTeams/templates/AssignTeams';
import { Tooltip } from '../../../ui/components/Tooltip';
import { Variables } from '../../../theme/variables';
import UpArrow from '../../../ui/icons/UpArrow';
import DownArrow from '../../../ui/icons/DownArrow';

const Container = styled.div`
    position: relative;
    height: 100%;
    width: 85px;
`;

const SelectHead = styled.div<{ disabled?: boolean }>`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const SelectButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const SelectLabel = styled.div<{ color?: string; disabled?: boolean }>`
    pointer-events: none;
    font-size: 13px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.disabled ? props.theme.Colors.lightBlueGray : props.color};
    font-weight: 500;
    margin-bottom: 1px;
`;

const SelectInputContainer = styled.div<{ noText?: boolean }>`
    width: ${props =>
        props.noText ? 30 : 80}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const IconHolder = styled.div<{ disabled?: boolean }>`
    width: 8px;
    height: 100%;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    align-self: center;
`;

const SelectedUserTeamsComponent: FC<{
    teamsCount: number;
    selectedTeams: number[];
    saveTeams?(): void;
    onSelectTeams(teamIds?: number[]): void;
    noText?: boolean;
}> = ({ teamsCount, selectedTeams, noText, onSelectTeams }) => {
    const { data } = usePortableTeamsState();

    const teamsDisabled = !data || data.items.length < 1;

    return (
        <Container>
            <Popup<HTMLDivElement>
                content={({ hide }) => (
                    <AssignTeams
                        onSuccessApply={(teamIds: number[]) => {
                            hide();
                            onSelectTeams(teamIds);
                        }}
                        onCancel={() => {
                            hide();
                        }}
                        selectedTeams={selectedTeams}
                    />
                )}
            >
                {(ref, { toggleShown, shown }) => (
                    <span ref={ref}>
                        <Tooltip
                            label={'No Teams added yet'}
                            preset={'table-item'}
                            disabled={!teamsDisabled}
                        >
                            <SelectHead
                                onClick={e => {
                                    e.stopPropagation();
                                    !teamsDisabled && toggleShown();
                                }}
                                disabled={teamsDisabled}
                            >
                                <SelectButtonWrapper>
                                    <SelectInputContainer noText={noText}>
                                        {noText ? <SelectLabel
                                            color={
                                                teamsCount < 1
                                                    ? Variables.Colors
                                                          .cloudyBlue
                                                    : Variables.Colors.black
                                            }
                                            disabled={teamsDisabled}
                                            data-test="teams-count"
                                        >
                                            {teamsCount}
                                        </SelectLabel> : <SelectLabel
                                            color={
                                                teamsCount < 1
                                                    ? Variables.Colors
                                                          .cloudyBlue
                                                    : Variables.Colors.black
                                            }
                                            disabled={teamsDisabled}
                                            data-test="teams-count"
                                        >
                                            {teamsCount < 1
                                                ? 'Teams'
                                                : `${teamsCount} ${
                                                      teamsCount > 1
                                                          ? 'Teams'
                                                          : 'Team'
                                                  }`}
                                        </SelectLabel>}
                                        <IconHolder disabled={teamsDisabled}>
                                            {shown ? (
                                                <UpArrow />
                                            ) : (
                                                <DownArrow />
                                            )}
                                        </IconHolder>
                                    </SelectInputContainer>
                                </SelectButtonWrapper>
                            </SelectHead>
                        </Tooltip>
                    </span>
                )}
            </Popup>
        </Container>
    );
};

export default SelectedUserTeamsComponent;
