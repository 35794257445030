import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSubmissionIdByOldId } from '../../../../api';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { OldSubmissionRouterParams } from './types';

function OldSubmissionRouterController({
    id,
    type,
    singleRoute,
    allRoute,
 }: OldSubmissionRouterParams) {
    const getSubmissionIdByOldId = useGetSubmissionIdByOldId();
    const showMessage = useShowMessage();
    const history = useHistory();
    
    getSubmissionIdByOldId(id, type)
        .then(response => {
            history.push(singleRoute.replace(':submissionId', response.id.toString()));
        })
        .catch((error: ErrorResult) => {
            showMessage(error.message, 'error');
            history.push(allRoute);
        });

    return <></>;
}

export default memo(OldSubmissionRouterController);