import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import CustomHeader from './components/CustomHeader/CustomHeader';
import {
    useGetSinglePractisSetService,
    useResetPractisSetService,
} from './store/services';
import { getPractisSetState } from './store/reducers';
import {
    PractisSets,
    PractisSetStatuses,
    PractisSetStatusType,
} from '../../../constants/interfaces/PractisSets';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import NavigationPrompt from 'react-router-navigation-prompt';
import ROUTES from '../../../routes/routes';
import CustomBodyContainer from './components/CustomBody';
import { PractisSetStatusName } from './tools';
import { buildPageTitle } from '../../../helpers/functions/page-title-helpers';
import { EditModeValues } from '../../../constants/enums/EditModeValues';
import { usePermissionsState } from '../../../features/permissions/store/state';
import { CheckPermission } from '../../../features/permissions';
import { pushModal } from '../../../tools/router';
import { useLabelsState } from '../../../features/labels/store/states';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../features/labels/tools';
import { isTeamLeader } from '../../../constants/enums';
import {
    useSetAssignLabelsActionService,
    useSetPreviouslyAssignedLabelsAction,
} from '../../../features/labels/store/services';
import { usePortableLabelsState } from '../../../features/portableLabels/store/states';
import { UserProfile } from '../../../constants/interfaces/User';
import { getProfileState } from '../../UserProfile/store/reducers';
import { handleMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ModalPageContainer } from '../../../ui/components/ModalPage';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import ActionPanel from './components/ActionPanel/ActionPanel';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';
import { useCreateEditDuplicateLibraryBulActionService } from '../../../features/library/services/LibraryBulkActionsService';
import {
    useGenerateCreatePractisSetActionList,
    useGenerateEditPractisSetActionList,
} from '../../../features/library/services/LibraryBulkActionsService/helpers';
import {
    CreatePractisSetInfo,
    EditPractisSetInfo,
} from '../../../features/library/services/LibraryBulkActionsService/types';
import {
    CreatedByWrapper,
    StyledContainer,
    StyledLoadingContainer,
} from './styles';
import {
    useCreateEditPractisSetFailedCallback,
    useCreateEditPractisSetSuccessCallback,
    useHandleArchivePractisSet,
} from './services';
import DialogWrapper from '../../../ui/components/DialogWrapper/DialogWrapper';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

interface PractisSetsInterface {
    profile?: UserProfile;
    history: any;
    handleFetchPractisSet: (practisSetId: number) => void;
    practisSet: PractisSets;
    practisSetId: string;
    dispatch: Dispatch<any>;
    loading: boolean;
    handleResetPractisSet: () => void;
    isNew: boolean;
    modified: 'init' | 'created' | 'modified' | 'loaded' | 'updated' | 'error';
    closePath: string;
    closeGoingBack: boolean;
}

export const StatusItems: Array<{
    value: string;
    name: string;
    confirm: boolean;
    message: string;
}> = [
    {
        value: PractisSetStatuses.DRAFT,
        name: PractisSetStatusName(PractisSetStatuses.DRAFT),
        message: 'Practis Set saved as Draft',
        confirm: true,
    },
    {
        value: PractisSetStatuses.ACTIVE,
        name: PractisSetStatusName(PractisSetStatuses.ACTIVE),
        message: 'Practis Set published',
        confirm: true,
    },
    {
        value: PractisSetStatuses.ARCHIVED,
        name: PractisSetStatusName(PractisSetStatuses.ARCHIVED),
        message: 'Practis Set archived',
        confirm: true,
    },
    {
        value: PractisSetStatuses.DELETED,
        name: PractisSetStatusName(PractisSetStatuses.DELETED),
        message: 'Practis Set deleted',
        confirm: true,
    },
];

export const LoadingSaveContext = createContext(false);
export const EditModeContext = createContext<{
    mode: string;
    action: (mode: EditModeValues) => void;
}>({
    mode: 'view',
    action: () => {},
});

const PractisSetsComponent: FC<PractisSetsInterface> = ({
    history,
    profile,
    practisSet,
    loading,
    isNew,
    modified,
    closeGoingBack,
    practisSetId,
    closePath,
    dispatch,
    handleFetchPractisSet,
    handleResetPractisSet,
}) => {
    const [editMode, setEditMode] = useState<EditModeValues>(
        EditModeValues.VIEW
    );
    const createEditPractisSetSuccessCallback =
        useCreateEditPractisSetSuccessCallback();
    const createEditPractisSetFailedCallback =
        useCreateEditPractisSetFailedCallback();

    useHtmlPageTitle(
        buildPageTitle('Practis Set', editMode, isNew),
        [practisSet.name]
    );

    const handleArchivePractisSet = useHandleArchivePractisSet();
    const [loadingSave, setLoadingSave] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const isTeamLead = isTeamLeader(profile?.role?.name);
    const isLoaded = !!practisSet.id;
    const permissions = usePermissionsState();
    const onUnload = useCallback(
        (e: any) => {
            e.preventDefault();
            if (
                modified === 'modified' &&
                permissions.includes(NEW_PERMISSIONS.UPDATE_PRACTIS_SET)
            ) {
                e.returnValue = true;
            }
        },
        [modified, permissions]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [onUnload]);

    useEffect(() => {
        setEditMode(
            (isLoaded || isNew) && practisSet.status === PractisSetStatuses.DRAFT && !isTeamLead
                ? EditModeValues.EDIT
                : EditModeValues.VIEW
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, isNew, practisSet.status, setEditMode]);

    useEffect(() => {
        if (practisSetId !== 'new') {
            handleFetchPractisSet(+practisSetId);
        } else {
            handleResetPractisSet();
        }
        return () => {
            handleResetPractisSet();
        };
    }, [handleResetPractisSet, handleFetchPractisSet, practisSetId]);

    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const setPreviousAssignedLabelsAction =
        useSetPreviouslyAssignedLabelsAction();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();

    useEffect(() => {
        if (!isEmpty(practisSet.labels)) {
            const practisSetLabelIds = practisSet.labels ?? [];
            const preAssignedLabels = calculatePreSelectedLabels(
                practisSetLabelIds,
                labelsList
            );
            setAssignLabelsAction(preAssignedLabels);
        } else {
            setAssignLabelsAction([]);
        }
        return () => {
            setAssignLabelsAction([]);
        };
    }, [
        practisSet.labels,
        labelsList,
        calculatePreSelectedLabels,
        setAssignLabelsAction,
    ]);

    //initially set previousAssignedLabels
    useEffect(() => {
        if (!isEmpty(practisSet.labels)) {
            const practisSetLabelIds = practisSet.labels;

            if (practisSetLabelIds) {
                const preAssignedLabels = calculatePreSelectedLabels(
                    practisSetLabelIds,
                    labelsList
                );
                setPreviousAssignedLabelsAction(preAssignedLabels);
            }
        } else {
            setPreviousAssignedLabelsAction([]);
        }

        return () => {
            setPreviousAssignedLabelsAction([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practisSet.labels]);

    /**
     * @function handleViewAssignUsers
     * @param { number } practisSetId
     * @returns { void }
     */
    const handleViewAssignUsers = useCallback(
        (practisSetId?: number): void => {
            if (!practisSetId) return;

            pushModal(
                history,
                ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ASSIGN_USERS.replace(
                    ':practisSetId',
                    practisSetId.toString()
                )
            );
        },
        [history]
    );

    const generateCreatePractisSetActionList =
        useGenerateCreatePractisSetActionList();
    const generateEditPractisSetActionList =
        useGenerateEditPractisSetActionList();

    const createEditPractisSetService =
        useCreateEditDuplicateLibraryBulActionService(
            isNew ? 'create' : 'update',
            'Practis Set',
            responses =>
                createEditPractisSetSuccessCallback(
                    setLoadingSave,
                    practisSet.id,
                    responses
                ),
            (error, completedResponses) =>
                createEditPractisSetFailedCallback(
                    setLoadingSave,
                    setTitleError,
                    error,
                    completedResponses
                )
        );

    const handleUpdatePractisSet = (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: string
    ) => {
        setLoadingSave(true);

        if (!validateUpdate(status ? status : practisSet.status)) {
            setLoadingSave(false);
            callbacks?.onCancel();
            return;
        }

        const newPractisSetInfo: CreatePractisSetInfo = {
            name: practisSet.name,
            description: practisSet.description,
            pacingId: practisSet.pacing?.id ?? 0,
            labelIds: calculateAssignedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            practisSetContents: practisSet.content ?? [],
            status: status as PractisSetStatusType,
        };

        const editedPractisSetInfo: EditPractisSetInfo = {
            practisSetId: practisSet.id ?? 0,
            name: practisSet.name,
            description: practisSet.description,
            pacingId: practisSet.pacing?.id ?? 0,
            addedLabelIds: calculateAssignedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            deletedLabelIds: calculateRemovedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            practisSetContents: practisSet.content ?? [],
            status: status as PractisSetStatusType,
        };

        const createPractisSetListAction =
            generateCreatePractisSetActionList(newPractisSetInfo);

        const editPractisSetListAction =
            generateEditPractisSetActionList(editedPractisSetInfo);

        if (status !== PractisSetStatuses.ARCHIVED) {
            if (!practisSet.id) {
                createEditPractisSetService([createPractisSetListAction]);
            } else {
                createEditPractisSetService(editPractisSetListAction);
            }
        } else {
            practisSet?.id && handleArchivePractisSet(practisSet.id);
        }
    };

    const validateUpdate = useCallback(
        (status: string): boolean => {
            if (
                practisSet &&
                practisSet.content &&
                practisSet.content.length > 99
            ) {
                handleMessage(
                    dispatch,
                    'You’ve reached the limit of adding scenarios and challenges',
                    'error'
                );
                return false;
            }

            let output = true;
            switch (status) {
                case PractisSetStatuses.DELETED:
                    output = true;
                    break;
                case PractisSetStatuses.ACTIVE:
                    if (!practisSet.name?.trim()) {
                        handleMessage(dispatch, 'Title required', 'error');
                        setTitleError(true);
                        output = false;
                    } else if (practisSet.content.length < 1) {
                        handleMessage(
                            dispatch,
                            'Active practis set should have at least one scenario',
                            'error'
                        );
                        output = false;
                        setTitleError(false);
                    } else {
                        setTitleError(false);
                    }
                    break;
                case PractisSetStatuses.DRAFT:
                case PractisSetStatuses.ARCHIVED:
                default:
                    if (practisSet.name.length < 1) {
                        handleMessage(dispatch, 'Title required', 'error');
                        output = false;
                        setTitleError(true);
                    } else {
                        setTitleError(false);
                    }
            }
            return output;
        },
        [dispatch, practisSet]
    );

    if (
        !isNew &&
        (loading || (!practisSet?.id && modified !== 'error'))
    ) {
        return (
            <ModalPageContainer
                closePath={closePath}
                closeGoingBack={closeGoingBack}
            >
                <StyledLoadingContainer>
                    <Loading />
                </StyledLoadingContainer>
            </ModalPageContainer>
        );
    }

    return (
        <ModalPageContainer closePath={closePath} closeGoingBack={true}>
            <StyledContainer>
                <EditModeContext.Provider
                    value={{ mode: editMode, action: setEditMode }}
                >
                    <LoadingSaveContext.Provider value={loadingSave}>
                        <CustomHeader
                            handleUpdatePractisSet={handleUpdatePractisSet}
                            handleViewAssignUsers={handleViewAssignUsers}
                            isViewMode={isTeamLead}
                        />
                    </LoadingSaveContext.Provider>
                    <ActionPanel
                        practisSet={practisSet}
                        titleError={titleError}
                        isTeamLeader={isTeamLead}
                    />
                    {practisSet.creator && (
                        <CreatedByWrapper data-test="practis-set-created-by">
                            Created by {practisSet.creator?.firstName}{' '}
                            {practisSet.creator?.lastName}
                        </CreatedByWrapper>
                    )}
                    <TableDivider />
                    <CustomBodyContainer />
                </EditModeContext.Provider>
                <CheckPermission
                    permissions={[NEW_PERMISSIONS.UPDATE_PRACTIS_SET]}
                >
                    <NavigationPrompt when={modified === 'modified'}>
                        {({ onConfirm, onCancel }) => (
                            <DialogWrapper
                                modalTitle="Discard changes?"
                                description={
                                    'Do you want to save or discard changes?'
                                }
                                cancelButtonText={'Discard'}
                                confirmButtonText={'Save'}
                                onCancel={onConfirm}
                                onConfirm={() => {
                                    handleUpdatePractisSet({
                                        onConfirm: onConfirm,
                                        onCancel: onCancel,
                                    });
                                }}
                                dataTest="confirmation-modal"
                            />
                        )}
                    </NavigationPrompt>
                </CheckPermission>
            </StyledContainer>
        </ModalPageContainer>
    );
};

export interface PractisSetsComponentContainerProps
    extends RouteComponentProps<{ practisSetId: string }> {
    closePath: string;
    closeGoingBack: boolean;
}

export const PractisSetsComponentContainer: FC<
    PractisSetsComponentContainerProps
> = ({ history, match, closePath, closeGoingBack }) => {
    const dispatch = useDispatch();
    const profile = useSelector(getProfileState);
    const isNew = match.params.practisSetId === 'new';
    const {
        info: practisSet,
        case: modified,
        isLoading: loading,
    } = useSelector(getPractisSetState);
    const getPractisSet = useGetSinglePractisSetService();
    const resetPractisSet = useResetPractisSetService();

    return (
        <PractisSetsComponent
            history={history}
            profile={profile}
            practisSet={practisSet}
            practisSetId={match.params.practisSetId}
            isNew={isNew}
            modified={modified}
            loading={loading}
            handleFetchPractisSet={getPractisSet}
            handleResetPractisSet={resetPractisSet}
            dispatch={dispatch}
            closePath={closePath}
            closeGoingBack={closeGoingBack}
        />
    );
};

export default withRouter(PractisSetsComponentContainer);
