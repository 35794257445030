import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import { LogEvent } from '../models/LogEvent';
import {
    FiltersApplyButton,
    FiltersClearButton,
    FiltersFieldSet,
    FiltersPopupActions,
    FiltersPopupBody,
    FiltersPopupFieldSets,
    FiltersSelectedText,
} from '../../../ui/components/Filters';
import { LogFiltersEvents } from './LogFiltersEvents';
import { UserRoleFilters } from '../../../features/users/components/Filters/UserRoleFilters';

interface Props {
    roleIds: number[];
    events: LogEvent[];
    onApplyFilter(selectedRoles: number[], selectedEvents: LogEvent[]): void;
}

export const LogFilters: FC<Props> = props => {
    const { roleIds, events, onApplyFilter } = props;
    const [selectedRoles, setSelectedRoles] = useState<number[]>(roleIds || []);
    const [selectedEvents, setSelectedEvents] = useState<LogEvent[]>(events || []);
    const totalFiltersSelected = selectedRoles.length + selectedEvents.length;

    const handleClickClear = useCallback(
        () => {
            setSelectedRoles([]);
            setSelectedEvents([]);
        },
        [setSelectedRoles, setSelectedEvents]
    );

    const handleClickApplyFilter = useCallback(
        () => onApplyFilter(selectedRoles, selectedEvents),
        [selectedRoles, selectedEvents, onApplyFilter]
    );

    return (
        <FiltersPopupBody>
            <FiltersPopupFieldSets>
                <FiltersFieldSet title="Roles" width={130}>
                    <UserRoleFilters
                        selectedRoles={selectedRoles}
                        onChangeSelectedRoles={setSelectedRoles}
                    />
                </FiltersFieldSet>
                <FiltersFieldSet title="Events" width={208} minHeight={244}>
                    <LogFiltersEvents
                        selectedEvents={selectedEvents}
                        onChangeSelectedEvents={setSelectedEvents}
                    />
                </FiltersFieldSet>
            </FiltersPopupFieldSets>
            <FiltersPopupActions>
                <FiltersSelectedText selectedItemsCount={totalFiltersSelected} />
                <FiltersClearButton disabled={!totalFiltersSelected} onClick={handleClickClear} />
                <FiltersApplyButton onClick={handleClickApplyFilter} />
            </FiltersPopupActions>
        </FiltersPopupBody>
    );
};
