import { FC } from 'react';
import styled from 'styled-components';

import { TableCell } from '../table-cell';

const StyledTableCell = styled(TableCell)`
    padding: 0;
`;

export const TableCheckboxCell: FC<{ width?: number }> = ({
    children,
    width,
}) => {
    return <StyledTableCell width={width}>{children}</StyledTableCell>;
};
