import { ALL_FILTER_VALUE } from '../interfaces/filters';
import { UserWithRole, UserV2 } from '../interfaces/User';
import { NEW_PERMISSIONS } from './permissions';

export * from './TrainingMode';

export const MAIN_ROLES = {
    COMPANY_ADMIN: 'COMPANY_ADMIN',
    USER: 'USER',
}

export const ROLE_NAMES = {
    ...MAIN_ROLES,
    PRACTIS_ADMIN: 'PRACTIS_ADMIN',
    TEAM_LEADER: 'TEAM_LEADER'
};

export const ROLE_INVITE_PERMISSIONS = {
    [ROLE_NAMES.USER]: [NEW_PERMISSIONS.INVITE_TRAINEE],
    [ROLE_NAMES.COMPANY_ADMIN]: [NEW_PERMISSIONS.INVITE_COMPANY_ADMIN],
};

export const PRACTIS_SET_STATUS_ITEMS: Array<{
    name: string;
    value: string;
}> = [
    { name: 'Not Started', value: 'PENDING' },
    { name: 'In Progress', value: 'IN_PROGRESS' },
    { name: 'Completed', value: 'COMPLETED' },
];

export const PRACTIS_SET_PROGRESS_STATUS_ITEMS: Array<{
    name: string;
    value: string;
}> = [
    { name: 'Show All', value: ALL_FILTER_VALUE },
    { name: 'Complete', value: 'Complete' },
    { name: 'Incomplete', value: 'Incomplete' },
];

export const ENROLLMENT_STATUS: Array<{ name: string; value: string }> = [
    { name: 'Show All', value: ALL_FILTER_VALUE },
    { name: 'In Progress', value: 'IN_PROGRESS' },
    { name: 'Completed', value: 'COMPLETED' },
    { name: 'Pending', value: 'PENDING' },
];

export function isUserRole(role?: string): boolean {
    return role === ROLE_NAMES.USER;
}

export function isCompanyAdminRole(role?: string): boolean {
    return role === ROLE_NAMES.COMPANY_ADMIN;
}

export function isPractisAdminRole(role?: string): boolean {
    return role === ROLE_NAMES.PRACTIS_ADMIN;
}

export function isAdminRole(role?: string): boolean {
    return role === ROLE_NAMES.PRACTIS_ADMIN || role === ROLE_NAMES.COMPANY_ADMIN;
}

export function isTeamLeader(role?: string): boolean {
    return role === ROLE_NAMES.TEAM_LEADER;
}

const rolePermissions = {
    [ROLE_NAMES.USER as string]: [
        ROLE_NAMES.USER,
        ROLE_NAMES.COMPANY_ADMIN,
        ROLE_NAMES.PRACTIS_ADMIN,
    ],
    [ROLE_NAMES.COMPANY_ADMIN as string]: [
        ROLE_NAMES.COMPANY_ADMIN,
        ROLE_NAMES.PRACTIS_ADMIN,
    ],
    [ROLE_NAMES.PRACTIS_ADMIN as string]: [ROLE_NAMES.PRACTIS_ADMIN],
};

export function roleViewPermissions(role?: string): string[] {
    if (!role) return [];
    return rolePermissions[role];
}

export function userRoleTitle(user: UserWithRole | Partial<UserV2> | undefined): string {
    return user?.role?.title || '';
}

export function pendingUserRoleTitle(user: UserWithRole | undefined): string {
    if (user?.role?.name === 'COMPANY_ADMIN') {
        return 'Admin';
    }

    return user?.role?.name.replace('_', ' ').toLowerCase() || '';
}

export const ASSIGN_LABEL_OPTIONS = {
    USER: 'USER',
    SCENARIO: 'SCENARIO',
    CHALLENGE: 'CHALLENGE',
    PRACTISSET: 'PRACTIS_SET',
    INVITATION: 'INVITATION',
    SUBMISSIONS: 'SUBMISSIONS',
    PRACTIS_SET: 'PRACTIS_SET',
    TEAM: 'TEAM',
};

export const LOAD_ASSIGNED_LABELS = {
    PRACTIS_SETS_SCREEN_LIBRARY: 'PRACTIS_SETS_SCREEN_LIBRARY',
    SCENARIOS_SCREEN_LIBRARY: 'SCENARIOS_SCREEN_LIBRARY',
    CHALLENGES_SCREEN_LIBRARY: 'CHALLENGES_SCREEN_LIBRARY',
    USERS_ACTIVE: 'USERS_ACTIVE',
    USERS_PENDING: 'USERS_PENDING',
    TEAMS_SCREEN: 'TEAMS_SCREEN',
    MEMBERS_SCREEN: 'MEMBERS_SCREEN',
    TRAINING_SCREEN: 'TRAINING_SCREEN'
};

export const ASSIGNED_AREA_BY_ENTITY = {
    PRACTIS_SET: 'PRACTIS_SETS_SCREEN_LIBRARY',
    SCENARIO: 'SCENARIOS_SCREEN_LIBRARY',
    CHALLENGE: 'CHALLENGES_SCREEN_LIBRARY',
    INVITATION: 'USERS_PENDING',
    USER: 'USERS_ACTIVE'
}

export enum PractisWebMode {
    'WEB_PORTAL',
    'ADMIN_PORTAL',
}