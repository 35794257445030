export const REFRESH_TEAMS_LIST = 'REFRESH_TEAMS_LIST';
export const REFRESH_TEAM_MEMBERS = 'REFRESH_TEAM_MEMBERS';
export const REFRESH_TEAM_CONTROLLER = 'REFRESH_TEAM_CONTROLLER';
export const REFRESH_TRAINING_PAGE = 'REFRESH_TRAINING_PAGE';
export const REFRESH_PENDING_USERS_PAGE = 'REFRESH_PENDING_USERS_PAGE';
export const CLEAR_PAGING = 'CLEAR_PAGING';

export enum EventNameList {
    REFRESH_TEAMS_LIST = 'REFRESH_TEAMS_LIST',
    REFRESH_TEAM_CONTROLLER = 'REFRESH_TEAM_CONTROLLER',
    REFRESH_TEAM_MEMBERS = 'REFRESH_TEAM_MEMBERS',
    REFRESH_TRAINING_PAGE = 'REFRESH_TRAINING_PAGE',
    REFRESH_PENDING_USERS_PAGE = 'REFRESH_PENDING_USERS_PAGE',
    CLEAR_PAGING = 'CLEAR_PAGING',
}