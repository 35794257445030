import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SituationIconActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (<svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#r9v6jdbmua)">
            <path d="M4.375 1.875a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM.625 5.938v1.937h5V5.937c0-.862-.7-1.562-1.563-1.562H2.188c-.862 0-1.562.7-1.562 1.563zM12.875.625h-2.25a2.5 2.5 0 0 0-2.5 2.5v2.5h4.75a2.5 2.5 0 0 0 0-5zm0 7.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5zm-2.5 5.313v1.937h5v-1.938c0-.862-.7-1.562-1.563-1.562h-1.874c-.863 0-1.563.7-1.563 1.563zm-9.75-.563a2.5 2.5 0 0 0 2.5 2.5h4.75v-2.5a2.5 2.5 0 0 0-2.5-2.5h-2.25a2.5 2.5 0 0 0-2.5 2.5z" stroke="#212121" stroke-width="1.3" stroke-miterlimit="10"/>
        </g>
        <defs>
            <clipPath id="r9v6jdbmua">
                <path fill="#fff" d="M0 0h16v16H0z"/>
            </clipPath>
        </defs>
    </svg>);
    }
);

export default SituationIconActive;