import { Action } from 'redux';
import { DateRangeType } from '../types';

export enum ACTIONS {
    SELECT_DATE_RANGE = 'SELECT_DATE_RANGE',
    CLEAR_DATE_RANGE = 'CLEAR_DATE_RANGE',
}

export type SelectDateRangeAction = Action<ACTIONS.SELECT_DATE_RANGE> & {
    dateRange?: DateRangeType;
};

export const selectDateRange = (
    dateRange: DateRangeType
): SelectDateRangeAction => ({
    type: ACTIONS.SELECT_DATE_RANGE,
    dateRange,
});

export type ClearDateRangeAction = Action<ACTIONS.CLEAR_DATE_RANGE> & {};

export const clearDateRange = (
): ClearDateRangeAction => ({
    type: ACTIONS.CLEAR_DATE_RANGE,
});

export type DateRangeFilterActions = SelectDateRangeAction | ClearDateRangeAction;