import styled from 'styled-components';

export const BlockSection = styled.div`
    background: var(--ps-graphite-3);
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Avatar = styled(BlockSection)`
    width: 40px;
    height: 40px;
    border-radius: 4px;
`;

export const TopInfo = styled.div`
    padding-left: 11px;
    padding-top: 7px;
`;

export const TopTitle = styled(BlockSection)`
    width: 157px;
    height: 8px;
`;

export const TopDescription = styled(BlockSection)`
    width: 72px;
    height: 8px;
    margin-top: 8px;
`;

export const BottomTitle = styled(BlockSection)`
    width: 276px;
    height: 13px;
    margin-top: 21px;
`;

export const BottomDescription = styled(BlockSection)`
    width: 147px;
    height: 13px;
    margin-top: 16px;
    margin-bottom: 5px;
`;