import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

import { Variables } from '../../../../theme/variables';
import { useCreateUserStagingService } from '../store/services';
import { NewUserValues } from '../models/NewUserValues';
import ROUTES from '../../../../routes/routes';
import { pushModal, useHistory } from '../../../../tools/router';
import { Input } from '../../../../ui/components/input';
import { Button } from '../../../../ui/components/Button';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';

const StyledTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    width: 100%;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
    margin-top: 15px;
    margin-bottom: -2px;
    width: 100%;
    height: 37px;
`;

const StyledFormArea = styled.div`
    width: 416px;
`;

const StyledActions = styled.div`
    width: 264px;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    align-self: flex-end;
`;

const StyledInput = styled(Input)<{ hasError?: boolean }>`
    font-family: ${props => props.theme.Fonts.manrope};
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.whiteTwo};
    ${props =>
        !!props.hasError && `border: 1px solid ${props.theme.Colors.tomato}`};
`;

const StyledInputContainer = styled.div`
    margin-bottom: 8px;
`;

const StyledErrorMessage = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.tomato};
    width: 100%;
    margin-bottom: 2px;
`;

export const SaveAsDraftModal: FC<{
    newUsers: NewUserValues[];
    onProceed(): void;
    onError(error: string): void;
    onClose(): void;
}> = ({ newUsers, onProceed, onError, onClose }) => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [submitClicked, setSubmitClicked] = useState(false)
    const createUserStagingService = useCreateUserStagingService();
    const history = useHistory();
    const handleTitleChange = (value: any) => {
        if (value.length > 64) return;
        setTitle(value);
        setError('');
    };

    const handleSubmit = useCallback(() => {
        if (!newUsers || (newUsers && !newUsers.length)) return;

        setSubmitClicked(true)
        createUserStagingService(
            title,
            newUsers.map(user => ({
                firstName: user.firstName ? user.firstName.toString() : '',
                lastName: user.lastName ? user.lastName.toString() : '',
                email: user.email ? user.email : '',
                roleId: user.roleId ? user.roleId : null,
                labelIDs: user.labelIDs,
                practisSetIDs: user.practisSetIDs ?? [],
                teamIDs: user.teamIDs,
            }))
        ).then((data: any) => {
            if (data && data.errorCode && data.errorCode === 433) {
                setError('Entered title already exists');
                setSubmitClicked(false);
            } else if (data.id) {
                setError('');
                onProceed();
                pushModal(
                    history,
                    ROUTES.ACTION_ITEMS.NEW_USER.replace(':draftId', data.id)
                );
            } else {
                setError('');
                onError('Draft contains invalid data');
                setSubmitClicked(false);
            }
        });
    }, [
        newUsers,
        createUserStagingService,
        title,
        onProceed,
        history,
        onError,
    ]);

    return (
        <DialogWrapper
            customization={{ width: 480, padding: '32px 40px' }}
            dataTest="save-as-draft"
        >
            <StyledTitle data-test="save-as-draft-title">
                Save as Draft
            </StyledTitle>
            <StyledContent>
                <StyledDescription data-test="save-as-draft-description">
                    You can save your progress as draft until it’s ready to be
                    sent out.
                </StyledDescription>
                <StyledFormArea>
                    <StyledInputContainer>
                        <StyledInput
                            type="text"
                            name="title"
                            inputPadding="15px 24px"
                            value={title}
                            placeholder="Draft Title"
                            labelLeftPosition="24px"
                            labelFontWeight={500}
                            maxLength={100}
                            height="40px"
                            handleChange={(e: any) =>
                                handleTitleChange(e.target.value)
                            }
                            hasError={!!error}
                            dataTest="save-as-draft-input"
                        />
                    </StyledInputContainer>
                </StyledFormArea>
                {error && (
                    <StyledErrorMessage data-test="save-as-draft-input-error">
                        {error}
                    </StyledErrorMessage>
                )}
                <StyledActions>
                    <Button
                        width="128px"
                        height="40px"
                        action={onClose}
                        variant="inverse"
                        dataTest="save-as-draft-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        width="128px"
                        height="40px"
                        action={handleSubmit}
                        disabled={!title}
                        customDisabledColor={Variables.Colors.cloudyBlue}
                        loading={submitClicked}
                        dataTest="save-as-draft-save"
                    >
                        Save
                    </Button>
                </StyledActions>
            </StyledContent>
        </DialogWrapper>
    );
};
