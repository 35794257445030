import { Formik, Form } from 'formik';

import { FormikField } from '../../../ui/components/FormikField';
import { Button } from '../../../ui/components/Button';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import {
    Content,
    FieldsContainer,
    HeaderTitle,
    StyledField,
} from './styles';
import { isSubmitButtonDisabled } from './helper';
import { RegisterViewProps } from './types';
import { RegisterSchema } from './constants';

function RegisterView(props: RegisterViewProps) {
    const { formRef, user, handleSubmit } = props;

    return (
        <LoginAuthWrapper>
            <Content>
                <HeaderTitle>Invitation Code From Email</HeaderTitle>
                <Formik
                    ref={formRef}
                    initialValues={{
                        invitationCode: user?.invitationCode ?? '',
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={RegisterSchema}
                >
                    {({ values, isSubmitting }) => (
                        <Form>
                            <FieldsContainer>
                                <StyledField
                                    type="text"
                                    disabled={!!user?.invitationCode}
                                    component={FormikField}
                                    name="invitationCode"
                                    label="Invite Code"
                                    colorTheme="darkThree"
                                    fontSize="15px"
                                    labelFontSize="15px"
                                    height="56px"
                                    labelFontWeight={600}
                                    errorPadding="2px 0"
                                    errorColor="var(--ps-red-main)"
                                    className="login-input"
                                    decreaseLabelFontSize
                                />
                            </FieldsContainer>
                            <Button
                                type="submit"
                                width="100%"
                                height="56px"
                                buttonWidth="100%"
                                fontSize={16}
                                style={{ marginTop: '10px' }}
                                disabled={isSubmitButtonDisabled(values)}
                                loading={isSubmitting}
                            >
                                Continue
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Content>
        </LoginAuthWrapper>
    );
}

export default RegisterView;
