import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const AddUser = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 40 40"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {shadow && (
                    <defs>
                        <filter
                            id="a"
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M846.8 410.8h-3.6v3.9c0 .717-.538 1.3-1.2 1.3-.662 0-1.2-.582-1.2-1.3v-3.9h-3.6c-.662 0-1.2-.582-1.2-1.3 0-.718.538-1.3 1.2-1.3h3.6v-3.898c0-.719.538-1.302 1.2-1.302.663 0 1.2.582 1.2 1.302v3.899h3.6c.662 0 1.2.581 1.2 1.3 0 .717-.538 1.3-1.2 1.3zm-14.448-9.39c1.286.321 3.161.804 5.021 1.326-.874.597-1.621 1.352-2.266 2.188-1.512-.406-3.098-.813-4.608-1.176v-3.96c3.133-2.134 4.99-6.769 4.99-10.981 0-5.697-1.457-10.317-8.743-10.317-7.285 0-8.743 4.62-8.743 10.317 0 4.216 1.86 8.855 4.998 10.987v3.991c-4.725 1.112-10.211 2.635-11.473 3.326-.817 1.03-1.53 4.33-1.023 6.393h22.846c.23.888.58 1.721 1.03 2.496h-25.253c-1.954-3-1.162-8.998.793-10.995.93-.952 7.909-2.752 11.249-3.571-3.528-2.74-5.678-7.825-5.678-12.673 0-7.048 2.046-12.761 11.254-12.761S838 381.713 838 388.761c0 4.834-2.14 9.903-5.648 12.65z"
                            transform="translate(-808 -376)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default AddUser;
