import { Reducer } from 'redux';
import {InvitersState} from './states';
import { AppState } from '../../../store/reducers';
import {InvitersAction} from "./actions";
import {InvitersResult} from "../interfaces";
import {Inviter} from "../../../constants/interfaces/Inviter";
import {filterUnique} from "../tools";

const initialInvitersState: InvitersState = {
    data: {
        items: [],
    },
    ready: false,
    loading: false,
    error: '',
    selected: [],
    saved: [],
};

export const invitersReducer: Reducer<InvitersState, InvitersAction> = (
    state = initialInvitersState,
    action: InvitersAction
): InvitersState => {
    switch (action.type) {
        case 'searchInvitersStartAction':
            return {
                ...state,
                loading: true,
                error: '',
            };
        case 'searchInvitersSuccessAction':
            const data: InvitersResult<Inviter> = {
                items: action.responseData.createdByUsers,
            };
            return {
                ...state,
                data,
                ready: true,
                loading: false,
                error: '',
            };
        case 'searchInvitersFailureAction':
            return {
                ...state,
                loading: false,
                error: action.message,
            };
        case 'selectInviterAction':
            return {
                ...state,
                selected: filterUnique([...state.selected, action.id]),
            };
        case 'deSelectInviterAction':
            const selectedIds = [...state.selected];
            const foundIdx = selectedIds.findIndex(x => x === action.id);
            if (foundIdx !== -1) {
                selectedIds.splice(foundIdx, 1);
            }
            return {
                ...state,
                selected: selectedIds,
            };
        case 'selectMultipleInvitersAction':
            return {
                ...state,
                selected: action.ids,
            };
        case 'selectAllInvitersAction':
            return {
                ...state,
                selected: state.data.items.map(x => x.id),
            };
        case 'deSelectAllInvitersAction':
            return {
                ...state,
                selected: [],
            };
        case 'saveInvitersAction':
            return {
                ...state,
                saved: state.selected,
            };
        case 'refreshInvitersAction':
            return {
                ...state,
                ready: false,
            };
        default:
            return state;
    }
};
export const getInvitersState = (state: AppState) => state.inviters;
