import styled from 'styled-components';

export const Container = styled.div<{
    isDisabled: boolean;
    isSelected: boolean;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 63px;
    height: 36px;
    color: ${props => 
        props.isSelected
            ? 'var(--ps-white-1)'
            : props.isDisabled
                ? 'var(--ps-grey-2)'
                : 'var(--ps-black-main)'
    };
    border-radius: 2px;
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
    background: ${props => 
        props.isSelected
            ? (props.isDisabled ? 'var(--ps-grey-2)' : 'var(--ps-blue-main)')
            : 'var(--ps-white-1)'
    };

    &:hover {
        ${props => !props.isDisabled && !props.isSelected ? 'background: var(--ps-grey-3);' : ''}
    }
`;

export const Underline = styled.div<{
    isDisabled: boolean;
    isSelected: boolean;
}>`
    width: 27px;
    height: 2px;
    margin: 2px 0 0;
    background: ${props =>
        props.isSelected
            ? 'var(--ps-white-1)'
            : (props.isDisabled ? 'var(--ps-grey-2)' : 'var(--ps-black-main)')
    };
    position: absolute;
    bottom: 5px;
`;