import React, { FC, useRef, useState } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../../../ui/components/ActionButton';
import { CheckPermission } from '../../../../../permissions';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { PopupComponent } from '../../../../../../ui/components/Popup';
import { Team } from '../../../../../../constants/interfaces/Team';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../../../labels/tools';
import { AssignLabels } from '../../../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../../../portableLabels/store/states';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';

const ListActions: FC<{
    team: Team;
    handleViewTeam: (id?: number) => void;
    handleManageTeam: (id?: number, isDefault?: boolean) => void;
    onDuplicateTeam: (team: Team) => void;
    handleDeleteTeam: (teamIds: number) => void;
    assignLabelsToTeams: (
        addedLabelIds: number[],
        deletedLabelIds: number[],
    ) => void;
    isHidden?: boolean;
}> = ({
    team,
    handleViewTeam,
    handleManageTeam,
    handleDeleteTeam,
    onDuplicateTeam,
    assignLabelsToTeams,
    isHidden,
}) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const calculatePreSelectedLabels = useCalculatePreSelectedLabelsForSingleItem();
   
    const handleSetPreselectedLabels = () => {
        const selectedLabelIds = team.labels || [];
        const preAssignedLabels = calculatePreSelectedLabels(
            selectedLabelIds,
            labelsList.data
        );
        setSelectLabels(preAssignedLabels);
        saveLabels();
    };

    const handleSaveSelectedLabels = (labelIDs: number[]) => {
        const addedLabelIds = calculateAssignedLabels(labelIDs, labels.saved);
        const deletedLabelIds = calculateRemovedLabels(labelIDs, labels.saved);

        assignLabelsToTeams(addedLabelIds, deletedLabelIds);
    };

    const [assignSingleLabelOpen, setAssignSingleLabelOpen] = useState<
        null | number | undefined
    >(null);

    const triggerRef = useRef(null);

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton customWidth={224} isHidden={isHidden} dataTest="teams-item-menu">
                    <CheckPermission permissions={[NEW_PERMISSIONS.VIEW_TEAM]}>
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleViewTeam(team.id);
                            }}
                            dataTest="view-team-action"
                        >
                            View Team
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.UPDATE_TEAM]}
                    >
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleManageTeam(team.id, team.isDefault);
                            }}
                            dataTest="manage-team-action"
                        >
                            Manage Team
                        </ActionItem>
                    </CheckPermission>
                    {!team.isDefault && (
                        <>
                            <CheckPermission
                                permissions={[
                                    NEW_PERMISSIONS.ASSIGN_TEAM_LABEL,
                                ]}
                            >
                                <ActionItem
                                    onClick={e => {
                                        e.stopPropagation();
                                        setAssignSingleLabelOpen(team.id);
                                        handleSetPreselectedLabels();
                                    }}
                                    dataTest="assign-labels-team-action"
                                >
                                    Assign Labels
                                </ActionItem>
                            </CheckPermission>
                            <CheckPermission
                                permissions={[NEW_PERMISSIONS.DUPLICATE_TEAM]}
                            >
                                <ActionItem
                                    onClick={e => {
                                        onDuplicateTeam(team);
                                    }}
                                    dataTest="duplicate-team-action"
                                >
                                    Duplicate
                                </ActionItem>
                            </CheckPermission>
                            <CheckPermission
                                permissions={[NEW_PERMISSIONS.DELETE_TEAM]}
                            >
                                <ActionItem
                                    destructive={true}
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleDeleteTeam(team.id!);
                                    }}
                                    dataTest="delete-team-action"
                                >
                                    Delete
                                </ActionItem>
                            </CheckPermission>
                        </>
                    )}
                </ActionButton>
            </div>
            <PopupComponent
                triggerRef={triggerRef}
                open={assignSingleLabelOpen === team.id}
                onDismiss={() => {
                    setAssignSingleLabelOpen(null);
                }}
            >
                <AssignLabels
                    onLabelsChange={handleSaveSelectedLabels}
                    onSuccessApply={() => {
                        setAssignSingleLabelOpen(null);
                    }}
                    withBorder={true}
                />
            </PopupComponent>
        </span>
    );
};

export default ListActions;
