import { ImageUploader } from '../../../../ui/components/ImageUploader';
import { Container, InfoText, LinkInfo, useStyles } from './styles';
import { MAX_COMPANY_LOGO_SIZE } from './constants';
import { CompanyUpdateLogoViewProps } from './types';
import {
    Footer,
    NextButton,
    NextButtonIcon,
    Wrapper
} from './styles';
import ArrowRight from '../../../../ui/icons/ArrowRight';

function CompanyUpdateLogoView({
    company,
    showGuidelines,
    isInProgress,
    onNext,
    setProfileImage,
    deleteLogo
} : CompanyUpdateLogoViewProps ) {
    const classes = useStyles();
    const hasFooter = !!onNext;

    return <Wrapper>
        <Container hasFooter={hasFooter}>
            <ImageUploader
                setProfileImage={setProfileImage}
                initialImage={company?.logoUrl}
                placeHolder={company?.name?.charAt(0).toUpperCase() || ''}
                dataTest="company-logo"
                className={classes.companyLogoUploader}
                maxImageSize={MAX_COMPANY_LOGO_SIZE}
                onFileDelete={deleteLogo}
                rectStyled={true}
                isInProgress={isInProgress}
            />
            <InfoText data-test="company-logo-info">
                PNG, JPG, JPEG • Less than 2 MB
            </InfoText>
            {showGuidelines &&
                <LinkInfo
                    href="https://docs.google.com/document/d/11TjuZ2KiHSpqstobHeAfp9OhOMOa6gpkMAbGcHvU1aY/edit?pli=1"
                    target="_blank"
                    data-test="company-logo-guidelines"
                >
                    Guidelines
                </LinkInfo>
            }
        </Container>
        {hasFooter && (
            <Footer>
                <NextButton onClick={onNext} data-test="next-button">
                    <NextButtonIcon><ArrowRight /></NextButtonIcon>
                </NextButton>
            </Footer>
        )}
    </Wrapper>
}

export default CompanyUpdateLogoView;