import { FC } from 'react';

import { TableProps, TableViewMode } from './type';
import TableSimpleView from './simple.view';
import TableInfiniteScrollView from './infinite-scroll.view';

const TableController: FC<TableProps> = props => {
    const { viewMode, ...rest } = props;

    return viewMode === TableViewMode.SIMPLE ? (
        <TableSimpleView {...rest} />
    ) : (
        <TableInfiniteScrollView {...rest} />
    );
};

export default TableController;

