import { SearchParams } from "../../constants/interfaces/filters";

export const normalizeSP = (
    searchParams: SearchParams,
    totalCount: number | undefined,
    teamId?: any,
    practisSetId?: any,
    userId?: any
) => {
    let newParams = {...searchParams};
    newParams.offset = 0;
    newParams.limit = totalCount;

    if (teamId) {
        newParams.teamId = parseInt(teamId)
    }
    if (practisSetId) {
        newParams.practisSetId = parseInt(practisSetId)
    }

    if (userId) {
        newParams.userId = parseInt(userId)
    }

    return newParams;
}

