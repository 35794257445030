import { ScenarioCases } from './reducers';
import { Keywords } from '../../../../constants/interfaces/Keywords';

export enum ACTIONS {
    UPLOAD_SCENARIO_LOGO_START = 'UPLOAD_SCENARIO_LOGO_START',
    UPLOAD_SCENARIO_LOGO_SUCCESS = 'UPLOAD_SCENARIO_LOGO_SUCCESS',
    UPLOAD_SCENARIO_LOGO_FAILURE = 'UPLOAD_SCENARIO_LOGO_FAILURE',

    UPLOAD_SCENARIO_AUDIO_START = 'UPLOAD_SCENARIO_AUDIO_START',
    UPLOAD_SCENARIO_AUDIO_SUCCESS = 'UPLOAD_SCENARIO_AUDIO_SUCCESS',
    UPLOAD_SCENARIO_AUDIO_FAILURE = 'UPLOAD_SCENARIO_AUDIO_FAILURE',

    CREATE_SCRIPT_LINE = 'CREATE_SCRIPT_LINE',
    UPDATE_SCRIPT_LINE = 'UPDATE_SCRIPT_LINE',
    DELETE_SCRIPT_LINE = 'DELETE_SCRIPT_LINE',
    MODIFY_SCRIPT_TEXT = 'MODIFY_SCRIPT_TEXT',
    CLEAR_SCRIPT_LINES = 'CLEAR_SCRIPT_LINES',

    RESET_SCENARIO = 'RESET_SCENARIO',
    RESET_SCENARIO_LOGO = 'RESET_SCENARIO_LOGO',

    FETCH_SCENARIO_START = 'FETCH_SCENARIO_START',
    FETCH_SCENARIO_SUCCESS = 'FETCH_SCENARIO_SUCCESS',
    FETCH_SCENARIO_FAILURE = 'FETCH_SCENARIO_FAILURE',

    UPDATE_SCRIPT_LINE_KEYWORDS = 'UPDATE_SCRIPT_LINE_KEYWORDS',

    STORE_SCENARIO_TEMP_COPY = 'STORE_SCENARIO_TEMP_COPY',
    RESTORE_SCENARIO_FROM_TEMP = 'RESTORE_SCENARIO_FROM_TEMP',

    MODIFY_SCENARIO = 'MODIFY_SCENARIO',
    UPDATE_SCENARIO_CASE = 'UPDATE_SCENARIO_CASE',
}

export function createScriptLineAction(data: any) {
    return {
        type: ACTIONS.CREATE_SCRIPT_LINE as ACTIONS.CREATE_SCRIPT_LINE,
        data,
    };
}

export function updateScriptLineAction(data: any) {
    return {
        type: ACTIONS.UPDATE_SCRIPT_LINE as ACTIONS.UPDATE_SCRIPT_LINE,
        data,
    };
}

export function modifyScriptTextAction(
    value: string,
    lineId: number | string
) {
    return {
        type: ACTIONS.MODIFY_SCRIPT_TEXT as ACTIONS.MODIFY_SCRIPT_TEXT,
        lineId: lineId,
        value,
    };
}

export function deleteScriptLineAction(data: any) {
    return {
        type: ACTIONS.DELETE_SCRIPT_LINE as ACTIONS.DELETE_SCRIPT_LINE,
        data,
    };
}

export function clearScriptLinesAction() {
    return {
        type: ACTIONS.CLEAR_SCRIPT_LINES as ACTIONS.CLEAR_SCRIPT_LINES,
    };
}

export function uploadScenarioLogoStart() {
    return {
        type: ACTIONS.UPLOAD_SCENARIO_LOGO_START as ACTIONS.UPLOAD_SCENARIO_LOGO_START,
    };
}

export function uploadScenarioLogoSuccess(data: any) {
    return {
        type: ACTIONS.UPLOAD_SCENARIO_LOGO_SUCCESS as ACTIONS.UPLOAD_SCENARIO_LOGO_SUCCESS,
        data,
    };
}

export function uploadScenarioLogoFailure(error: any) {
    return {
        type: ACTIONS.UPLOAD_SCENARIO_LOGO_FAILURE as ACTIONS.UPLOAD_SCENARIO_LOGO_FAILURE,
        error,
    };
}

export function uploadScenarioAudioStart() {
    return {
        type: ACTIONS.UPLOAD_SCENARIO_AUDIO_START as ACTIONS.UPLOAD_SCENARIO_AUDIO_START,
    };
}

export function uploadScenarioAudioSuccess(
    scriptLineId: string | number,
    response: any
) {
    return {
        type: ACTIONS.UPLOAD_SCENARIO_AUDIO_SUCCESS as ACTIONS.UPLOAD_SCENARIO_AUDIO_SUCCESS,
        data: {
            scriptLineId,
            response,
        },
    };
}

export function uploadScenarioAudioFailure(error: any) {
    return {
        type: ACTIONS.UPLOAD_SCENARIO_AUDIO_FAILURE as ACTIONS.UPLOAD_SCENARIO_AUDIO_FAILURE,
        error,
    };
}

export function resetScenarioAction() {
    return {
        type: ACTIONS.RESET_SCENARIO as ACTIONS.RESET_SCENARIO,
    };
}

export function resetScenarioLogoAction() {
    return {
        type: ACTIONS.RESET_SCENARIO_LOGO as ACTIONS.RESET_SCENARIO_LOGO,
    };
}

export function fetchScenarioStart() {
    return {
        type: ACTIONS.FETCH_SCENARIO_START as ACTIONS.FETCH_SCENARIO_START,
    };
}

export function fetchScenarioSuccess(data: any) {
    return {
        type: ACTIONS.FETCH_SCENARIO_SUCCESS as ACTIONS.FETCH_SCENARIO_SUCCESS,
        data,
    };
}

export function fetchScenarioFailure(error: any) {
    return {
        type: ACTIONS.FETCH_SCENARIO_FAILURE as ACTIONS.FETCH_SCENARIO_FAILURE,
        error,
    };
}

export function modifyScenarioAction(
    data: any,
    field: string,
    silent?: boolean
) {
    return {
        type: ACTIONS.MODIFY_SCENARIO as ACTIONS.MODIFY_SCENARIO,
        field: field,
        data: data,
        silent: silent,
    };
}

export function updateScenarioModifyCase(value: ScenarioCases) {
    return {
        type: ACTIONS.UPDATE_SCENARIO_CASE as ACTIONS.UPDATE_SCENARIO_CASE,
        value,
    };
}

export function storeScenarioTempCopyAction() {
    return {
        type: ACTIONS.STORE_SCENARIO_TEMP_COPY as ACTIONS.STORE_SCENARIO_TEMP_COPY,
    };
}

export function restoreScenarioFromTempAction() {
    return {
        type: ACTIONS.RESTORE_SCENARIO_FROM_TEMP as ACTIONS.RESTORE_SCENARIO_FROM_TEMP,
    };
}

export function updateScriptLineKeywords(
    keywords: Keywords[],
    lineId: number | string
) {
    return {
        type: ACTIONS.UPDATE_SCRIPT_LINE_KEYWORDS as ACTIONS.UPDATE_SCRIPT_LINE_KEYWORDS,
        keywords,
        lineId,
    };
}
