import { FC, useContext, useState } from 'react';
import { EditModeContext, LoadingSaveContext } from '../../NewScenario';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
    Scenario,
    ScenarioStatuses,
    Status,
} from '../../../../../constants/interfaces/Scenario';
import { useVoiceGenerationState } from '../../../../../features/textToSpeech/store/states';
import {
    AudioInterface,
    getAudioState,
} from '../../../../../features/player/store/reducers';
import {
    useModifyScenarioService,
    useRestoreScenarioFromTempActionService,
    useStoreScenarioTempCopyService,
} from '../../store/services';
import { useResetAudioService } from '../../../../../features/player/store/services';
import { StatusModalConfirmation } from '../../../StatusModalConfirmation/StatusModalConfirmation';
import { buildPageTitle } from '../../../../../helpers/functions/page-title-helpers';
import { EditModeValues } from '../../../../../constants/enums/EditModeValues';
import { formatDate } from '../../../../../helpers/functions/date-convert';
import { Button } from '../../../../../ui/components/Button';
import { TableDivider } from '../../../../../ui/components/table-wrapper/table-divider';
import { useHandleRestoreArchivedScenario } from '../../services';

const StyledCustomWrapper = styled.div``;

const StyledCustomHeader = styled.div`
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 103px;
`;

const StyledHeaderInput = styled.div`
    flex: 1;
    min-width: 150px;
    margin-right: 16px;
`;

const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${props => props.theme.Colors.black};
`;

const StyledHeaderActions = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap;
`;

const StyledHeaderActionItemsWrapper = styled.div`
    display: flex;
`;

const StyledHeaderActionItems = styled.div`
    margin-left: 16px;
`;

const PublishedDate = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-right: 16px;
`;

const EDIT_MODAL_INFO = {
    id: 'scenario_edit_message',
    message: 'Are you sure you want to edit Scenario.',
    buttonText: 'Proceed',
    cancelButtonText: 'Go Back',
    title: 'Are you sure?',
    notShow: false,
    danger: true,
};

const CANCEL_EDIT_MODAL_INFO = {
    id: 'scenario_cancel_edit_message',
    message: 'Do you want to discard changes?',
    buttonText: 'Discard',
    cancelButtonText: 'No',
    title: 'Discard Changes?',
    notShow: false,
    danger: true,
};

const MODAL_INFO = {
    DELETED: {
        message: 'Are you sure you want to delete?',
        buttonText: 'Yes, Delete',
        title: 'Delete Scenario',
        notShow: false,
        danger: false,
    },
    DRAFT: {
        message: 'Are you sure you want to archive?',
        buttonText: 'Yes, Archive',
        title: 'Archive Scenario',
        notShow: false,
        danger: false,
    },
    ARCHIVED: {
        message:
            'Removing the active items will effectively RESET progress for related Practis Sets for all currently enrolled Trainees. They will receive a notification and will be asked to start again.',
        buttonText: 'Yes, Archive',
        title: 'Archive Scenario',
        notShow: false,
        danger: true,
    },
    ACTIVE: {
        message: 'Are you sure you want to archive?',
        buttonText: 'Yes, Archive',
        title: 'Archive Scenario',
        notShow: false,
        danger: false,
    },
};

const EDIT_VIEW_STATUS = [ScenarioStatuses.ACTIVE];

const CustomHeader: FC<{
    modifyScenario: (
        value: any,
        field: keyof Scenario,
        silent?: boolean
    ) => any;
    storeScenarioTemp: () => void;
    restoreScenarioFromTemp: () => void;
    handleUpdateScenario: (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: Status,
        keepModal?: boolean
    ) => any;
    stopPlaylist: () => void;
    scenario: any;
    modified?: boolean;
    player: AudioInterface;
    isViewMode?: boolean;
}> = ({
    scenario,
    modifyScenario,
    handleUpdateScenario,
    player,
    storeScenarioTemp,
    restoreScenarioFromTemp,
    modified,
    isViewMode,
}) => {
    const handleRestoreArchivedScenario = useHandleRestoreArchivedScenario();
    const voice = useVoiceGenerationState().scenarios;
    const generating = voice && voice.generating;
    const [publishModal, setPublishModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [cancelEditModal, setCancelEditModal] = useState(false);
    const [saveState, setSaveState] = useState<Status>(ScenarioStatuses.ACTIVE);

    const handleSaveWithModalAction = (status: ScenarioStatuses) => {
        setSaveState(status);
        setPublishModal(true);
    };

    const loadingSave = useContext(LoadingSaveContext);
    const edit = useContext(EditModeContext);

    const cancelEditing = () => {
        if (modified) {
            setCancelEditModal(true);
        } else {
            restoreScenarioFromTemp();
            edit.action(EditModeValues.VIEW);
        }
    };

    return (
        <StyledCustomWrapper>
            {publishModal && (
                <StatusModalConfirmation
                    onCancel={() => {
                        setPublishModal(false);
                    }}
                    onConfirm={() => {
                        setPublishModal(false);
                        handleUpdateScenario(null, saveState);
                    }}
                    title={MODAL_INFO[saveState].title}
                    confirmButtonText={MODAL_INFO[saveState].buttonText}
                    displayDontShow={MODAL_INFO[saveState].notShow}
                    danger={MODAL_INFO[saveState].danger}
                >
                    {MODAL_INFO[saveState].message}
                </StatusModalConfirmation>
            )}
            {editModal && (
                <StatusModalConfirmation
                    onCancel={() => {
                        setEditModal(false);
                    }}
                    onConfirm={() => {
                        setEditModal(false);
                        storeScenarioTemp();
                        edit.action(EditModeValues.EDIT);
                    }}
                    title={EDIT_MODAL_INFO.title}
                    confirmButtonText={EDIT_MODAL_INFO.buttonText}
                    cancelButtonText={EDIT_MODAL_INFO.cancelButtonText}
                    displayDontShow={EDIT_MODAL_INFO.notShow}
                    dontShowHandle={EDIT_MODAL_INFO.id}
                    danger={EDIT_MODAL_INFO.danger}
                >
                    {EDIT_MODAL_INFO.message}
                </StatusModalConfirmation>
            )}
            {cancelEditModal && (
                <StatusModalConfirmation
                    onCancel={() => {
                        setCancelEditModal(false);
                    }}
                    onConfirm={() => {
                        setCancelEditModal(false);
                        restoreScenarioFromTemp();
                        edit.action(EditModeValues.VIEW);
                    }}
                    title={CANCEL_EDIT_MODAL_INFO.title}
                    confirmButtonText={CANCEL_EDIT_MODAL_INFO.buttonText}
                    cancelButtonText={CANCEL_EDIT_MODAL_INFO.cancelButtonText}
                    displayDontShow={CANCEL_EDIT_MODAL_INFO.notShow}
                    dontShowHandle={CANCEL_EDIT_MODAL_INFO.id}
                    danger={CANCEL_EDIT_MODAL_INFO.danger}
                >
                    {CANCEL_EDIT_MODAL_INFO.message}
                </StatusModalConfirmation>
            )}
            <StyledCustomHeader>
                <StyledHeaderInput>
                    <HeaderTitle data-test="scenario-page-title">
                        {buildPageTitle('Scenario', edit.mode as EditModeValues, !scenario.id)}
                    </HeaderTitle>
                </StyledHeaderInput>
                <StyledHeaderActions>
                    <StyledHeaderActionItems>
                        <PublishedDate>
                            {scenario.status === ScenarioStatuses.ACTIVE &&
                                edit.mode === EditModeValues.VIEW && (
                                    <span data-test="scenario-publish-date">
                                        Published{' '}
                                        {formatDate(scenario.updatedAt, true)}
                                    </span>
                                )}
                            {scenario.status === ScenarioStatuses.ARCHIVED &&
                                edit.mode === EditModeValues.VIEW && (
                                    <span data-test="scenario-archive-date">
                                        Archived{' '}
                                        {formatDate(scenario.updatedAt, true)}
                                    </span>
                                )}
                            {scenario.status === ScenarioStatuses.DRAFT && (
                                <span data-test="scenario-not-published-yet">
                                    Not Published Yet
                                </span>
                            )}
                        </PublishedDate>
                    </StyledHeaderActionItems>
                    {EDIT_VIEW_STATUS.includes(scenario.status) ? (
                        edit.mode === EditModeValues.VIEW ? (
                            <StyledHeaderActionItemsWrapper>
                                <StyledHeaderActionItems>
                                    <Button
                                        width="144px"
                                        height="40px"
                                        disabled={loadingSave || isViewMode}
                                        action={() =>
                                            handleSaveWithModalAction(
                                                ScenarioStatuses.ARCHIVED
                                            )
                                        }
                                        variant="inverse"
                                        dataTest="archive-scenario"
                                    >
                                        Archive
                                    </Button>
                                </StyledHeaderActionItems>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave || isViewMode}
                                        action={() => setEditModal(true)}
                                        dataTest="edit-scenario"
                                    >
                                        Edit
                                    </Button>
                                </StyledHeaderActionItems>
                            </StyledHeaderActionItemsWrapper>
                        ) : (
                            <StyledHeaderActionItemsWrapper>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="144px"
                                        disabled={loadingSave}
                                        action={() => cancelEditing()}
                                        variant="inverse"
                                        dataTest="cancel-edit"
                                    >
                                        Cancel
                                    </Button>
                                </StyledHeaderActionItems>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave}
                                        action={() =>
                                            handleUpdateScenario(null)
                                        }
                                        dataTest="save-scenario-changes"
                                    >
                                        Save
                                    </Button>
                                </StyledHeaderActionItems>
                            </StyledHeaderActionItemsWrapper>
                        )
                    ) : scenario.status === ScenarioStatuses.ARCHIVED ? (
                        <StyledHeaderActionItemsWrapper>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="128px"
                                    disabled={loadingSave || isViewMode}
                                    action={() =>
                                        handleRestoreArchivedScenario(
                                            scenario.id
                                        )
                                    }
                                    dataTest="restore-scenario"
                                >
                                    Restore
                                </Button>
                            </StyledHeaderActionItems>
                        </StyledHeaderActionItemsWrapper>
                    ) : (
                        <StyledHeaderActionItemsWrapper>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="144px"
                                    disabled={
                                        player.status !== 'off' ||
                                        !!player.recording ||
                                        generating ||
                                        loadingSave ||
                                        isViewMode
                                    }
                                    action={() =>
                                        handleUpdateScenario(
                                            null,
                                            ScenarioStatuses.DRAFT
                                        )
                                    }
                                    variant="inverse"
                                    dataTest="save-scenario-as-draft"
                                >
                                    Save as Draft
                                </Button>
                            </StyledHeaderActionItems>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="128px"
                                    disabled={
                                        player.status !== 'off' ||
                                        !!player.recording ||
                                        generating ||
                                        loadingSave ||
                                        isViewMode
                                    }
                                    action={() =>
                                        handleUpdateScenario(
                                            null,
                                            ScenarioStatuses.ACTIVE
                                        )
                                    }
                                    dataTest="publish-scenario"
                                >
                                    Publish
                                </Button>
                            </StyledHeaderActionItems>
                        </StyledHeaderActionItemsWrapper>
                    )}
                </StyledHeaderActions>
            </StyledCustomHeader>
            <TableDivider />
        </StyledCustomWrapper>
    );
};

export const CustomHeaderContainer: FC<{
    handleUpdateScenario: (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: Status,
        keepModal?: boolean
    ) => any;
    scenario: Scenario;
    modified?: boolean;
    isViewMode?: boolean;
}> = ({ handleUpdateScenario, scenario, modified, isViewMode }) => {
    const player = useSelector(getAudioState);
    const modifyScenario = useModifyScenarioService();
    const resetPlaylist = useResetAudioService();
    const storeScenarioTemp = useStoreScenarioTempCopyService();
    const restoreScenarioFromTemp = useRestoreScenarioFromTempActionService();

    return (
        <CustomHeader
            handleUpdateScenario={handleUpdateScenario}
            scenario={scenario}
            player={player}
            modified={modified}
            modifyScenario={modifyScenario}
            storeScenarioTemp={storeScenarioTemp}
            restoreScenarioFromTemp={restoreScenarioFromTemp}
            stopPlaylist={resetPlaylist}
            isViewMode={isViewMode}
        />
    );
};

export default CustomHeaderContainer;