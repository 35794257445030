import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const LoadingAudio = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow, dataTest }, ref) => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" data-test={dataTest} className={className}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.002 18h.002a.761.761 0 0 0 .771-.75V2.75a.761.761 0 0 0-.771-.75.76.76 0 0 0-.772.75v14.5a.76.76 0 0 0 .77.75zM6.657 5.75A.76.76 0 0 0 5.886 5a.76.76 0 0 0-.772.75v8.5c0 .414.346.75.772.75a.76.76 0 0 0 .771-.75v-8.5zm-4.114 3A.761.761 0 0 0 1.77 8 .76.76 0 0 0 1 8.75v2.5c0 .414.345.75.771.75a.761.761 0 0 0 .772-.75v-2.5zm10.804 5.5c0 .414.345.75.771.75a.761.761 0 0 0 .771-.75v-8.5a.761.761 0 0 0-.77-.75.76.76 0 0 0-.772.75v8.5zm4.114-3c0 .414.345.75.771.75a.761.761 0 0 0 .772-.75v-2.5a.76.76 0 0 0-.772-.75.761.761 0 0 0-.771.75v2.5z" fill="#4AA9E2" />
        </svg>
        );
    }
);

export default LoadingAudio;