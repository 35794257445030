import { RoundSkeleton } from "../../../../ui/components/Skeleton/styles";
import { LineSkeleton, HeaderSkeletonContainer, LineContainer } from "./styles";

function HeaderSkeleton() {
    return <HeaderSkeletonContainer data-test="roleplay-header-skeleton">
        <LineContainer>
            <LineSkeleton width={146} height={12} data-test="roleplay-header-skeleton-line" />
            <RoundSkeleton width={12} height={12} data-test="roleplay-header-skeleton-round"/>
        </LineContainer>
        <LineSkeleton width={117} height={8} data-test="roleplay-header-skeleton-line"/>
    </HeaderSkeletonContainer>

}

export default HeaderSkeleton;