import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ChallengeIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={ref}
                className={className}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.158 2.311v2.95h-1.062v-2.95h1.062zm2.279-.138V1H12.88V9.496l-2.183 3.57-.814-1.31a1 1 0 0 0-1.699 0l-6.033 9.716A1 1 0 0 0 3.002 23h17.776a1 1 0 0 0 .853-1.522L14.02 9.032V6.573h1.138v1.174h6.834V2.173h-4.555zM9.035 12.71l.947 1.525-4.56 7.456H3.459l5.576-8.981zm1.35 8.981v.086h9.804l-6.674-11-6.622 10.914h3.492zM20.803 3.485v2.95h-4.457v-2.95h4.457z"
                    fill="#EC513D"
                />
            </svg>
        );
    }
);

export default ChallengeIcon;
