import { useCallback } from 'react';
import { isEmpty } from 'lodash';

import { useResendInvitationApi, useSearchUsersApi, useDeleteUserApi, useRevokePendingUsers } from '../../../../api';
import { SearchUsersParams } from '../../../../api/users/types';
import { SearchParams } from '../../../../constants/interfaces/filters';
import { getSearchSortingValue } from '../../../../helpers/functions/search-params-helpers';
import { UserStatus } from '../../store/costants';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
/**
 * @function useSearchUsersAndRevokeInvitation
 * @returns { CallableFunction }
 */
 export const useSearchUsersAndRevokeInvitation = () => {
    const searchUsersApi = useSearchUsersApi();
    const revokeInvitations = useRevokePendingUsers();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } searchParams
     * @param { number } companyId
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            searchParams: SearchParams,
            companyId: number,
            onSuccessCallback: () => void
        ) => {
            const searchPendingUsersParams: SearchUsersParams = {
                status: UserStatus.PENDING,
                roles: searchParams.roleIDs?.join(','),
                labels: searchParams.labelIDs?.join(','),
                invitedBy: searchParams.invitersIDs?.join(','),
                companies: companyId.toString(),
                limit: searchParams.limit ?? 20,
                offset: searchParams.offset ?? 0,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
            };

            await searchUsersApi({ ...searchPendingUsersParams })
                .then(async (response: Record<string, any>) => {
                    const userIds = response?.items?.map(
                        (item: { id: number }) => item.id
                    ) as number[];

                    if (!isEmpty(userIds)) {
                        await revokeInvitations(userIds)
                            .then(() => {
                                onSuccessCallback();
                            })
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [revokeInvitations, searchUsersApi, showMessage]
    );
};

/**
 * @function useSearchUsersAndResendInvitation
 * @returns { CallableFunction }
 */
export const useSearchUsersAndResendInvitation = () => {
    const searchUsersApi = useSearchUsersApi();
    const resendInvitation = useResendInvitationApi();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } searchParams
     * @param { number } companyId
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            searchParams: SearchParams,
            companyId: number,
            onSuccessCallback: () => void
        ) => {
            const searchPendingUsersParams: SearchUsersParams = {
                status: UserStatus.PENDING,
                roles: searchParams.roleIDs?.join(','),
                labels: searchParams.labelIDs?.join(','),
                invitedBy: searchParams.invitersIDs?.join(','),
                companies: companyId.toString(),
                limit: searchParams.limit ?? 20,
                offset: searchParams.offset ?? 0,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
            };

            await searchUsersApi({ ...searchPendingUsersParams })
                .then(async (response: Record<string, any>) => {
                    const userIds = response?.items?.map(
                        (item: { id: number }) => item.id
                    ) as number[];

                    if (!isEmpty(userIds)) {
                        await resendInvitation(userIds)
                            .then(() => {
                                onSuccessCallback();
                            })
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [resendInvitation, searchUsersApi, showMessage]
    );
};

/**
 * @function useSearchUsersAndDeleteUser
 * @returns { CallableFunction }
 */
 export const useSearchUsersAndDeleteUser = () => {
    const searchUsersApi = useSearchUsersApi();
    const deleteUser = useDeleteUserApi();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } searchParams
     * @param { number } companyId
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            searchParams: SearchParams,
            companyId: number,
            onSuccessCallback: () => void
        ) => {
            const searchRegisteredUsersParams: SearchUsersParams = {
                status: UserStatus.ACTIVE,
                roles: searchParams.roleIDs?.join(','),
                labels: searchParams.labelIDs?.join(','),
                teams: searchParams.teamIds?.join(','),
                companies: companyId.toString(),
                limit: searchParams.limit ?? 20,
                offset: 0,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
            };

            await searchUsersApi({ ...searchRegisteredUsersParams })
                .then(async (response: Record<string, any>) => {
                    const userIds = response?.items?.map(
                        (item: { id: number }) => item.id
                    ) as number[];

                    if (!isEmpty(userIds)) {
                        await deleteUser(userIds)
                            .then(() => {
                                onSuccessCallback();
                            })
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteUser, searchUsersApi, showMessage]
    );
};

