import { useRef, useState, useEffect, useCallback } from "react";

const COUNTDOWN_DATE_KEY = 'COUNTDOWN_DATE';

export default function useCountdown(from: number, interval: number = 1000) {
    const [value, setValue] = useState<number | undefined>();
    const valueInterval: any = useRef();

    /**
     * @function stop
     * @description clears interval callbacks, session storage key
     * and state value (if it's not called from unmount)
     * @param { boolean } unmount
     * @returns { void }
     */
    const stop = useCallback((unmount = false) => {
        clearInterval(valueInterval.current);
        sessionStorage.removeItem(COUNTDOWN_DATE_KEY);
        if (!unmount) {
            setValue(undefined);
        }
    }, []);

    /**
     * @function start
     * @description starts interval callbacks with 1 second interval.
     * Countdown value is calculated as a difference between "from"
     * and a number of seconds passed from the moment when start function was called.
     * Start date is stored in session storage in order to have a reliable value
     * of passed time and to be independent on possible setInterval() issues
     * @returns { void }
     */
    const start = useCallback(() => {
        setValue(from);
        sessionStorage.setItem(COUNTDOWN_DATE_KEY, new Date().toString());
        valueInterval.current = setInterval(
            () => {
                const startDateFromStorage = sessionStorage.getItem(COUNTDOWN_DATE_KEY);
                if (startDateFromStorage) {
                    const startDate = new Date(startDateFromStorage);
                    const nowDate = new Date();
                    const diffInMiliseconds = nowDate.getTime() - startDate.getTime();
                    const diffInSeconds = Math.round(diffInMiliseconds / 1000);
                    const value = Math.max(from - diffInSeconds, 0);
                    setValue(value);
                } else {
                    stop();
                };
            },
            interval
        );
    }, [from, interval, stop]);

    useEffect(() => {
        if (typeof value === 'number' && value === 0) {
            stop();
        }
    }, [stop, value]);

    useEffect(() => {
        return () => {
            stop(true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { value, start };
}