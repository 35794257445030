import styled from 'styled-components';
import { SingleTextLine } from '../../../../ui/components/Skeleton/styles';

export const Container = styled.div`
    margin-right: 8px;
    margin-top: 7px;
    border: 1px solid var(--ps-grey-4);
    border-radius: 4px;
    padding: 20px 12px;
`;

export const BodySkeletonContainer = styled.div`
    padding: 0px 20px;
    margin-bottom: 32px;
`;

export const RoleplayContainer = styled.div`
    padding: 0px;
`;

export const RoleplaySkeletonContainer = styled(Container)`
    padding: 24px 31px 20px 16px;
`


export const LineSkeleton = styled(SingleTextLine)<{marginTop?: string}>`
    margin-top: ${props => props.marginTop ?? '11px'};
    margin-right: 8px;

    &:first-child {
        margin-top: 0;
    }
`;

export const HeaderSkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 17px;
`;

export const LineContainer = styled.div`
    display: flex;
`;