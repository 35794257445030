import { FC, memo } from 'react';

import { CheckPermission } from '../../../../../features/permissions';
import {
    LabelButton,
    StyledSpan,
    ButtonIcon,
    ButtonText,
    Divider,
} from '../styles';
import { TableRefreshButtonProps } from './type';

const TableRefreshButton: FC<TableRefreshButtonProps> = memo(props => {
    const { permissions, onPress, disabled, icon, name, dataTest } = props;

    return (
        <CheckPermission permissions={permissions}>
            <StyledSpan>
                <LabelButton
                    onClick={onPress}
                    disabled={disabled}
                    data-test={dataTest && `${dataTest}-button`}
                >
                    <ButtonIcon data-test={dataTest && `${dataTest}-icon`}>{icon}</ButtonIcon>
                    <ButtonText data-test={dataTest && `${dataTest}-text`}>{name}</ButtonText>
                </LabelButton>
            </StyledSpan>
            <Divider />
        </CheckPermission>
    );
});

export default TableRefreshButton;

