import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Label } from '../../../constants/interfaces/Label';
import { isEqual } from 'lodash';

export interface LabelsState {
    open: boolean;
    data?: PaginationResult<Label>;
    fullList?: PaginationResult<Label>;
    collapseLabels: number[];
    assignedLabels: number[];
    collapseAssignLabels: number[];
    selected: number[];
    searchResults?: number[];
    selectedModified: boolean;
    totalCount?: number;
    loading?: boolean;
    saveLoading?: boolean;
    error?: string;
    previouslyAssignedLabels?: number[];
}

export type LabelsStateSelector = (state: any) => LabelsState;

export const LabelsStateSelectorContext = React.createContext<
    LabelsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useLabelsState(): LabelsState {
    return useSelector(useContext(LabelsStateSelectorContext), isEqual);
}

export interface EditingLabels {
    id?: number | null;
    mode?: 'edit' | 'new';
}

export interface LabelsConfigState {
    searchTerm: string;
    editingLabels?: EditingLabels;
    error?: string;
}

export type LabelsConfigStateSelector = (state: any) => LabelsConfigState;

export const LabelsConfigStateSelectorContext = React.createContext<
    LabelsConfigStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useLabelsConfigState(): LabelsConfigState {
    return useSelector(useContext(LabelsConfigStateSelectorContext));
}

export interface UpdatedLabelsState {
    data?: Label;
    updateType?: 'create' | 'update' | 'delete';
    loading?: boolean;
}

export type UpdatedLabelsStateSelector = (state: any) => UpdatedLabelsState;

export const UpdatedLabelsStateSelectorContext = React.createContext<
    UpdatedLabelsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useUpdatedLabelsState(): UpdatedLabelsState {
    return useSelector(useContext(UpdatedLabelsStateSelectorContext));
}
