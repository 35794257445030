import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TeamV2 } from '../../../../../../constants/interfaces/Team';
import { WithLabelsContext } from '../../../../../portableLabels';
import { Popup } from '../../../../../../ui/components/Popup';
import {
    useResetLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import {
    useCalculatePreSelectedLabelsForSingleItem,
    calculateAssignedLabels,
    calculateRemovedLabels,
} from '../../../../../labels/tools';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import UpArrow from '../../../../../../ui/icons/UpArrow';
import DownArrow from '../../../../../../ui/icons/DownArrow';
import { AssignLabels } from '../../../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../../../portableLabels/store/states';
import InputWithButtons from '../InputWithButtons';
import { useGetSingleTeamService } from '../../../../store/services';
import { useAssignLabelsToTeamsApi, useDeleteLabelsFromTeamsApi } from '../../../../../../api';
import { useUpdateLabelsBulkActionService } from '../../../../../../services/GeneralBulkActionServices';
import { updateSingleTeamFailure, updateSingleTeamStart, updateSingleTeamSuccess } from '../../../../store/actions';

const StyledActions = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    box-sizing: content-box;
    margin: 16px 0;
`;

const Tools = styled.div`
    height: 100%;
    width: 149px;
`;

const AssignLabelsButton = styled.button<{
    open: boolean;
    selected: boolean;
    disabled: boolean;
}>`
    position: relative;
    height: 100%;
    width: 100%;
    color: ${({ theme, disabled }) =>
        disabled ? theme.Colors.cloudyBlue : theme.Colors.steelGrey};
    background: ${props => props.theme.Colors.whiteFive};
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: inset 0 0 0
        ${({ open, selected }) => (open || selected ? 1 : 0)}px
        ${({ theme }) => theme.Colors.cloudyBlue};
    border: none;
`;

const IconHolder = styled.div`
    width: 8px;
    height: 100%;
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledActionsTitle = styled.div`
    font-size: 13px;
`;

const Actions: FC<{
    team?: TeamV2;
    handleUpdateTeamName: (value: string) => Promise<void>;
}> = ({ team, handleUpdateTeamName }) => {
    const dispatch = useDispatch();

    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const clearLabels = useResetLabels();
    const getPreSelectedLabels = useCalculatePreSelectedLabelsForSingleItem();
    const assignLabelsLocal = useSelectLabels();
    const saveLabels = useSaveLabels();
    const getSingleTeam = useGetSingleTeamService();
    const assignLabelsToTeam = useAssignLabelsToTeamsApi();
    const deleteLabelsFromTeam = useDeleteLabelsFromTeamsApi();

    /**
     * @function onSuccessUpdateLabelsCallback
     * @returns { void }
     */
    const onSuccessUpdateLabelsCallback = useCallback(() => {
        dispatch(updateSingleTeamSuccess());

        if (team?.id) {
            getSingleTeam(team.id);
        }
    }, [dispatch, getSingleTeam, team]);
    

    /**
     * @function onFailedUpdateLabelsCallback
     * @returns { void }
     */
    const onFailedUpdateLabelsCallback = useCallback(() => {
        dispatch(updateSingleTeamFailure());
    }, [dispatch]);
    

    /**
     * @function onStartUpdateLabelsCallback
     * @returns { void }
     */
    const onStartUpdateLabelsCallback = useCallback(() => {
        dispatch(updateSingleTeamStart());
    }, [dispatch]);

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToTeam,
        deleteLabelsFromTeam,
        onSuccessUpdateLabelsCallback,
        onFailedUpdateLabelsCallback,
        onStartUpdateLabelsCallback
    );

    /**
     * @function handleApplyLabels
     * @param { number[]} labelIds
     * @returns { void }
     */
    const handleApplyLabels = (labelIds: number[]) => {
        const deletedLabelIds = calculateRemovedLabels(labelIds, labels.saved);
        const assignedLabelIds = calculateAssignedLabels(
            labelIds,
            labels.saved
        );

        updateLabelsBulkActionService(
            {
                entityName: 'team',
                entityId: team?.id ?? 0,
            },
            assignedLabelIds,
            deletedLabelIds
        );
    };

    useEffect(() => {
        return () => {
            clearLabels();
        };
    }, [clearLabels]);

    useEffect(() => {
        if (team?.labels) {
            const preSelectedLabels = getPreSelectedLabels(
                team.labels,
                labelsList.data
            );

            assignLabelsLocal(preSelectedLabels);
            saveLabels();
        } else {
            assignLabelsLocal([]);
        }
    }, [
        assignLabelsLocal,
        getPreSelectedLabels,
        labelsList.data,
        saveLabels,
        team,
    ]);

    return (
        <StyledActions>
            <InputWithButtons
                height="40px"
                maxLength={50}
                placeholder="Team Name"
                value={team ? team.name : ''}
                dataTest="team-name"
                onSave={handleUpdateTeamName}
            />
            <Tools>
                <Popup<HTMLButtonElement>
                    content={({ hide }) => (
                        <AssignLabels
                            onLabelsChange={handleApplyLabels}
                            onSuccessApply={() => hide()}
                            withBorder={true}
                        />
                    )}
                >
                    {(ref, { toggleShown, shown }) => (
                        <AssignLabelsButton
                            ref={ref}
                            onClick={toggleShown}
                            open={shown}
                            selected={labels.saved.length > 0}
                            disabled={labelsList.data?.totalCount < 1}
                            data-test="team-assign-labels"
                        >
                            <StyledActionsTitle>
                                <IconHolder>
                                    {shown ? <UpArrow /> : <DownArrow />}
                                </IconHolder>
                                Assign Labels
                            </StyledActionsTitle>
                        </AssignLabelsButton>
                    )}
                </Popup>
            </Tools>
        </StyledActions>
    );
};

const ActionsContainer: FC<{
    team?: TeamV2;
    handleUpdateTeamName: (value: string) => Promise<void>;
}> = ({ team, handleUpdateTeamName }) => {
    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'teamEdit',
                scope: 'assignTeamMembers',
            }}
        >
            <Actions team={team} handleUpdateTeamName={handleUpdateTeamName} />
        </WithLabelsContext.Provider>
    );
};

export default ActionsContainer;
