import { SearchSubmissionsParams } from '../../../api/submissions/types';

import { SearchParams } from '../../../constants/interfaces/filters';
import { SUBMISSION_VISIBILITY } from '../../../constants/interfaces/Reviews';
import { SubmissionFilters } from '../../../constants/interfaces/SubmissionSearchFilters';
import { getSearchSortingValue } from '../../../helpers/functions/search-params-helpers';

export const SUBMISSION_STATUS = {
    PENDING: 'pending',
    ACTIVE: 'active',
    ARCHIVED: 'deleted'
};

export const CHALLENGE_SUBMISSION_REVIEW_STATUS = {
    NOT_REVIEWED: 'not_reviewed',
    REVIEWED: 'reviewed'
};

export const CHALLENGE_SUBMISSION_REVIEW_STATUS_ITEMS: Array<{ name: string; value: string }> = [
    {
        name: 'Needs Review',
        value: CHALLENGE_SUBMISSION_REVIEW_STATUS.NOT_REVIEWED,
    },
    {
        name: 'Reviewed',
        value: CHALLENGE_SUBMISSION_REVIEW_STATUS.REVIEWED,
    },
];

export const calculateScoreValue = (items: number[]): number => {
    return items.length && items.reduce((total, amount, index, array) => {
        total += amount;
        if (index === array.length - 1) {
            return total / array.length;
        } else {
            return total;
        }
    });
};

export const generateScoreItemArray = (obj: any, fields: string[]) => {
    const arr = [];
    for (let field of fields) {
        if (!!obj[field]) {
            arr.push(obj[field]);
        }
    }
    return arr;
};

export const countAppliedFiltersCount = (searchParams: SearchParams) => {
    let total = 0;

    if (searchParams && searchParams.teamIds && searchParams.teamIds.length) {
        total = total + searchParams.teamIds.length;
    }

    if (searchParams && searchParams.labelIDs && searchParams.labelIDs.length) {
        total = total + searchParams.labelIDs.filter(id => id > 0).length;
    }

    if (
        searchParams &&
        searchParams.filterByStatus &&
        searchParams.filterByStatus.length
    ) {
        total = total + searchParams.filterByStatus.length;
    }

    return total;
};



export const countAppliedFiltersCountAccuracy = (searchParams: SearchParams) => {
    let total = 0;
    const scenariosFilter: any = searchParams?.filters?.find(item => item.field === 'scenarioId')?.value;


    if (searchParams && searchParams.teamIds && searchParams.teamIds.length) {
        total = total + searchParams.teamIds.length;
    }

    if (searchParams && searchParams.labelIDs && searchParams.labelIDs.length) {
        total = total + searchParams.labelIDs.filter(id => id > 0).length;
    }

    if (searchParams && searchParams.filters && scenariosFilter) {
        total = total + scenariosFilter?.length;
    }

    if (
        searchParams &&
        searchParams.filterByStatus &&
        searchParams.filterByStatus.length
    ) {
        total = total + searchParams.filterByStatus.length;
    }

    return total;
};

/**
 * @function createSearchSubmissionsParams
 * @returns { SearchSubmissionsParams }
 */
export const createSearchSubmissionsParams = (
    searchParams: SearchParams,
    companyId: number,
    type: string,
    filters: SubmissionFilters
): SearchSubmissionsParams => {
    return {
        limit: searchParams.limit ?? 20,
        offset: searchParams.offset ?? 0,
        companyId: companyId?.toString(),
        query: searchParams.searchTerm,
        order: getSearchSortingValue(searchParams.orderBy, ''),
        status: SUBMISSION_STATUS.ACTIVE,
        visibility: filters.showArchived
            ? SUBMISSION_VISIBILITY.ARCHIVED
            : SUBMISSION_VISIBILITY.VISIBLE,
        teamId: filters.teamIds.join(','),
        labelId: filters.labelIds.join(','),
        type
    };
};