class StorageClass {
    storage = localStorage;

    get(key: string) {
        return this.storage.getItem(key);
    }

    set(key: string, value: any) {
        return this.storage.setItem(key, value);
    }

    clear() {
        return this.storage.clear();
    }

    remove(key: string) {
        return this.storage.removeItem(key);
    }
}
const Storage = new StorageClass();
Object.freeze(Storage);

export default Storage;
