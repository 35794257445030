import { useHandleSelectLabels } from '../../../../../labels/tools';
import LabelsFilterItemView from './view'
import { LabelsFilterItemProps } from './types';
import { useCallback } from 'react';

function LabelsFilterItemController({
    label,
    allLabels,
    openLabels,
    selectedLabels,
    isDisabled,
    onToggleCollapse,
    onSelect,
}: LabelsFilterItemProps) {
    const handleSelectLabels = useHandleSelectLabels();

    const isSelected = selectedLabels.includes(label.id);
    const isPartiallySelected = selectedLabels.includes(-label.id);
    const isOpen = openLabels.includes(label.id);
    const hasChildren = !!label.children && label.children.length > 0;

    const handleToggle = useCallback(() => {
        onSelect(
            handleSelectLabels(label, selectedLabels, allLabels)
        );
    }, [allLabels, handleSelectLabels, label, selectedLabels, onSelect]);

    return (
        <>
            <LabelsFilterItemView
                label={label}
                hasChildren={hasChildren}
                isSelected={isSelected}
                isPartiallySelected={isPartiallySelected}
                isOpen={isOpen}
                isDisabled={isDisabled}
                onToggle={handleToggle}
                onToggleCollapse={onToggleCollapse}
            >
                {hasChildren && isOpen && (
                    label.children!.map((childLabel) => (
                        <LabelsFilterItemController
                            key={childLabel.id}
                            label={childLabel}
                            allLabels={allLabels}
                            openLabels={openLabels}
                            selectedLabels={selectedLabels}
                            onToggleCollapse={onToggleCollapse}
                            onSelect={onSelect}
                            isDisabled={isDisabled}
                        />
                    ))
                )}
            </LabelsFilterItemView>
        </>
    );
};

export default LabelsFilterItemController;