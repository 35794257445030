import { Variables } from '../../../../../../theme/variables';
import { Loading } from '../../../../../../ui/components/LoadingCopmonent';
import TextAreaPlaceholderBox from '../../../../../../ui/components/TextAreaPlaceholderBox/TextAreaPlaceholderBox';
import Sound from '../../../../../../ui/icons/Sound';
import StopCircle from '../../../../../../ui/icons/StopCircle';
import { Container, PlayButton } from './styles';
import { VoiceSampleTextViewProps } from './types';

function VoiceSampleTextView({
    value,
    isFocused,
    canPlay,
    status,
    blobUrl,
    audioRef,
    dataTest,
    onChange,
    setIsFocused,
    onAudioLoaded,
    onPlay,
    onStop,
}: VoiceSampleTextViewProps) {
    return (
        <Container isFocused={isFocused}>
            <TextAreaPlaceholderBox
                max={250}
                maxRows={6}
                minHeight="140px"
                placeholder="Type or paste text here. The model works best on longer fragments."
                value={value}
                className="voice-sample-textbox"
                counterColor={Variables.Colors.cloudyBlue}
                disabled={status !== 'stopped'}
                onChange={e => onChange(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                dataTest={dataTest}
            />
            {status === 'stopped' && <>
                <PlayButton
                    isActive={canPlay}
                    onClick={onPlay}
                    data-test={dataTest && `${dataTest}-listen`}
                >
                    <Sound /><span>Listen</span>
                </PlayButton>
            </>}
            {status === 'generating' && <>
                <PlayButton
                    isActive={true}
                    data-test={dataTest && `${dataTest}-generating`}
                >
                    <Loading size={16} /><span>Generating...</span>
                </PlayButton>
            </>}
            {status === 'playing' && <>
                <PlayButton
                    isActive={true}
                    onClick={onStop}
                    data-test={dataTest && `${dataTest}-stop`}
                >
                    <StopCircle /><span>Stop</span>
                </PlayButton>
            </>}
            <audio
                src={blobUrl}
                ref={audioRef}
                onLoadedData={onAudioLoaded}
                onEnded={onStop}
            />
        </Container>
    )
}

export default VoiceSampleTextView;