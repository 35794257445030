import React, { FC, useState } from 'react';
import { OnChangeDateCallback } from 'react-calendar';
import dayjs from 'dayjs';

import TrashBinIcon from '../../icons/TrashBin';
import ArrowRightIcon from '../../icons/ArrowRight';
import { Button } from '../Button';
import { formatDate, getDateWithTZOffset } from '../../../helpers/functions/date-convert';
import {
    IconHolder,
    Header,
    Footer,
    Title,
    Subtitle,
    RemoveDateTitle,
    IconContainer,
    StyledCalendar,
    StyledDialogBlacker,
    StyledDialog,
    CancelButton,
} from './styles';
import { dueDateTitleTypes } from './constants';
import { DATE_FORMAT } from '../../../constants/interfaces/DueDates';
import { DueDateCalendarProps } from './type';

const DueDateCalendar: FC<DueDateCalendarProps> = ({
    data,
    onCancel,
    onApply,
}) => {
    const { count, type, dueDate, isSubTitleVisible } = data || {};
    const [actionsDisabled, setActionsDisabled] = useState(true);
    const [currentDueDate, setCurrentDueDate] = useState<string | null>(
        dueDate ? dayjs(dueDate as Date).format(DATE_FORMAT) : null
    );
    const [currentType, setCurrentType] = useState<string>(type);
    const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());

    /**
     * @function handleDueDateChange
     * @param { Date | Date[] } date
     * @returns { void }
     */
    const handleDueDateChange: OnChangeDateCallback = date => {
        setCurrentDueDate(
            date ? dayjs(date as Date).format(DATE_FORMAT) : null
        );
        setCurrentType('SINGLE');
        setActionsDisabled(false);
    };

    /**
     * @function handleClose
     * @param { React.MouseEvent } e
     * @returns { void }
     */
    const handleClose = (e: React.MouseEvent): void => {
        e.currentTarget === e.target && onCancel();
    };

    /**
     * @function onActiveStartDateChange
     * @param { Date } date
     * @returns { void }
     */
    const onActiveStartDateChange = (date: Date): void => {
        date && setActiveStartDate(date);
    };

    const isRemoveDateTitleDisabled =
        !currentDueDate && currentType !== 'MULTIPLE';

    const isPrevArrowDisabled =
        activeStartDate.getMonth() === new Date().getMonth();

    /**
     * @function getExactDate
     * @param { string } currentDueDate
     * @returns { Date } 
     */
    const getExactDate = (currentDueDate: string) => {
        let date = new Date(currentDueDate);
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    }
    return (
        <StyledDialogBlacker onClick={handleClose}>
            <StyledDialog initial={{ scale: 0.8 }} animate={{ scale: 1 }}>
                <Header>
                    <Title>
                        {
                            dueDateTitleTypes[
                                (currentType ||
                                    type) as keyof typeof dueDateTitleTypes
                            ]
                        }
                        {currentDueDate
                            ? formatDate(currentDueDate, false, false)
                            : null}
                    </Title>
                    {isSubTitleVisible && (
                        <Subtitle>
                            {`${count} user${
                                count > 1 ? 's' : ''
                            } will be affected and notified.`}
                        </Subtitle>
                    )}
                </Header>
                <StyledCalendar
                    value={currentDueDate ? getExactDate(currentDueDate) : null} // use it in controlled way
                    calendarType={'US'}
                    locale="en" // always use US locale
                    formatShortWeekday={(_, date) =>
                        ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
                    }
                    prev2Label={null}
                    next2Label={null}
                    nextLabel={
                        <IconHolder>
                            <ArrowRightIcon />
                        </IconHolder>
                    }
                    prevLabel={
                        <IconHolder scaleX={-1} disabled={isPrevArrowDisabled}>
                            <ArrowRightIcon />
                        </IconHolder>
                    }
                    onChange={handleDueDateChange}
                    showNeighboringMonth={true}
                    onActiveStartDateChange={date =>
                        onActiveStartDateChange(date?.activeStartDate)
                    }
                    minDate={dayjs().add(1, 'day').startOf('day').toDate()}
                />
                <Footer>
                    <RemoveDateTitle
                        disabled={isRemoveDateTitleDisabled}
                        onClick={() => {
                            setCurrentType('NO_DUEDATE');
                            setCurrentDueDate(null);
                            setActionsDisabled(false);
                        }}
                    >
                        <IconContainer>
                            <TrashBinIcon />
                        </IconContainer>
                        Remove Due Date
                    </RemoveDateTitle>
                    <CancelButton
                        label={'Cancel'}
                        height={'40px'}
                        width={'86px'}
                        style={{ marginLeft: '27px' }}
                        action={onCancel}
                    />
                    <Button
                        label={'Apply'}
                        height={'40px'}
                        width={'86px'}
                        disabled={actionsDisabled}
                        action={() => onApply(getDateWithTZOffset(currentDueDate))}
                    />
                </Footer>
            </StyledDialog>
        </StyledDialogBlacker>
    );
};

export default DueDateCalendar;
