import {
    ACTIONS,
    createChallengeScriptLineAction,
    deleteChallengeScriptLineAction,
    fetchChallengeFailure,
    fetchChallengeStart,
    fetchChallengeSuccess,
    modifyChallengeAction,
    modifyChallengeScriptLineAction,
    resetChallengeAction,
    resetChallengeLogoAction,
    restoreChallengeFromTempAction,
    storeChallengeTempCopyAction,
    updateChallengeScriptLineAction,
    uploadChallengeAudioFailure,
    uploadChallengeAudioStart,
    uploadChallengeAudioSuccess,
} from './actions';
import {
    ACTIONS as LIBRARY_ACTIONS,
    updateLibraryChallengeSuccess,
} from '../../library/store/actions';
import { ChallengeState } from './states';
import { ScriptLine } from '../../../constants/interfaces/ScriptLine';
import { ChallengeStatuses } from '../../../constants/interfaces/Challenge';
import { DEFAULT_CHALLENGE_TRY_LIMIT } from '../constants';

export const initialChallengeState: ChallengeState = {
    data: {
        title: '',
        description: '',
        instructions: '',
        status: ChallengeStatuses.DRAFT,
        script: {
            totalDuration: null,
            scenarioId: null,
            companyId: null,
            lines: [],
        },
        tryLimit: DEFAULT_CHALLENGE_TRY_LIMIT,
    },
    loading: false,
    case: 'init',
    error: '',
};

type ChallengeActions =
    | ReturnType<typeof fetchChallengeStart>
    | ReturnType<typeof fetchChallengeSuccess>
    | ReturnType<typeof fetchChallengeFailure>
    | ReturnType<typeof modifyChallengeAction>
    | ReturnType<typeof createChallengeScriptLineAction>
    | ReturnType<typeof updateChallengeScriptLineAction>
    | ReturnType<typeof modifyChallengeScriptLineAction>
    | ReturnType<typeof deleteChallengeScriptLineAction>
    | ReturnType<typeof uploadChallengeAudioStart>
    | ReturnType<typeof uploadChallengeAudioSuccess>
    | ReturnType<typeof uploadChallengeAudioFailure>
    | ReturnType<typeof resetChallengeAction>
    | ReturnType<typeof resetChallengeLogoAction>
    | ReturnType<typeof updateLibraryChallengeSuccess>
    | ReturnType<typeof storeChallengeTempCopyAction>
    | ReturnType<typeof restoreChallengeFromTempAction>;

export const challengeReducer = (
    state = initialChallengeState,
    action: ChallengeActions
): ChallengeState => {
    switch (action.type) {
        case ACTIONS.FETCH_CHALLENGE_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.FETCH_CHALLENGE_SUCCESS:
            return {
                data: {
                    ...state.data,
                    ...action.data,
                },
                loading: false,
                case: 'loaded',
                error: '',
            };
        case ACTIONS.FETCH_CHALLENGE_FAILURE:
            return {
                ...state,
                loading: false,
                case: 'error',
                error: action.error,
            };
        case ACTIONS.UPLOAD_CHALLENGE_AUDIO_START:
            return {
                ...state,
            };
        case ACTIONS.UPLOAD_CHALLENGE_AUDIO_SUCCESS:
            const updatedLines = state.data.script!.lines.map(
                (line: ScriptLine) => {
                    if (line.id === action.data.scriptLineId) {
                        const newLine = { ...line };
                        newLine.audioUrl = action.data.response.url;
                        newLine.audioId = action.data.response.id;
                        newLine.duration =
                            action.data.response.metadata.format.duration;
                        return newLine;
                    } else {
                        return line;
                    }
                }
            );
            return {
                ...state,
                data: {
                    ...state.data,
                    script: {
                        ...state.data.script,
                        totalDuration: updatedLines
                            .map((lines: ScriptLine) =>
                                lines.duration ? lines.duration : 0
                            )
                            .reduce((a, b) => a + b, 0),
                        lines: updatedLines,
                    },
                },
                case: 'modified',
            };
        case ACTIONS.UPLOAD_CHALLENGE_AUDIO_FAILURE:
            return {
                ...state,
                error: action.error,
                case: 'error',
            };
        case ACTIONS.CREATE_CHALLENGES_SCRIPT_LINE:
            const createdScriptLine = { ...state.data.script };
            createdScriptLine.lines.push(action.data);
            return {
                ...state,
                data: {
                    ...state.data,
                    script: {
                        ...createdScriptLine,
                    },
                },
                case: action.silent ? state.case : 'modified',
                error: '',
            };
        case ACTIONS.UPDATE_CHALLENGES_SCRIPT_LINE:
            return {
                ...state,
                data: {
                    ...state.data,
                    script: {
                        ...state.data.script,
                        lines: action.data.map(
                            (line: ScriptLine, index: number) => {
                                return { ...line, position: index };
                            }
                        ),
                    },
                },
                case: 'modified',
                error: '',
            };
        case ACTIONS.MODIFY_CHALLENGES_SCRIPT_LINE:
            return {
                ...state,
                case: 'modified',
                data: {
                    ...state.data,
                    script: {
                        ...state.data.script,
                        lines: state.data.script.lines.map(
                            (line: ScriptLine) => {
                                if (line.id === action.lineId) {
                                    return {
                                        ...line,
                                        [action.field]: action.data,
                                    };
                                } else {
                                    return line;
                                }
                            }
                        ),
                    },
                },
            };
        case ACTIONS.DELETE_CHALLENGES_SCRIPT_LINE:
            const newScriptLines = [...state.data.script.lines];
            const newScriptLinesArr = [
                ...newScriptLines.filter(
                    (line: ScriptLine) => line.id !== action.data
                ),
            ];
            const newDurations = newScriptLinesArr
                .filter((line: ScriptLine) => line.audioUrl)
                .map((line: ScriptLine) => line.duration);
            return {
                ...state,
                data: {
                    ...state.data,
                    script: {
                        ...state.data.script,
                        totalDuration:
                            newDurations.length > 0
                                ? newDurations.reduce((a, b) => a + b)
                                : 0,
                        lines: newScriptLinesArr,
                    },
                },
                case: 'modified',
                error: '',
            };
        case ACTIONS.MODIFY_CHALLENGE:
            const newChallenge: any = { ...state.data };
            newChallenge[action.field] = action.data;
            return {
                ...state,
                data: {
                    ...newChallenge,
                },
                case: action.silent ? state.case : 'modified',
            };
        case ACTIONS.STORE_CHALLENGE_TEMP_COPY: {
            return {
                ...state,
                temp_data: JSON.parse(JSON.stringify(state.data)),
            };
        }
        case ACTIONS.RESTORE_CHALLENGE_FROM_TEMP: {
            return {
                ...state,
                data: JSON.parse(JSON.stringify(state.temp_data)),
                case: 'init',
            };
        }
        case ACTIONS.RESET_CHALLENGE: {
            return {
                ...initialChallengeState,
                data: {
                    ...initialChallengeState.data,
                    script: {
                        ...initialChallengeState.data.script,
                        lines: [],
                    },
                },
            };
        }
        case LIBRARY_ACTIONS.UPDATE_LIBRARY_CHALLENGES_SUCCESS: {
            return {
                ...state,
                case: 'updated',
            };
        }
        default:
            return state;
    }
};
