import { History } from 'history';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { isEmpty as lodashIsEmpty } from 'lodash';

import User from '../../../../ui/icons/User';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import {
    EnrollmentStatus,
    EnrollmentStatusEnum,
} from '../../../../constants/interfaces/Enrollments';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { PaginationResult } from '../../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../../constants/interfaces/PractisSets';
import { EnrollmentInterface } from '../../../../constants/interfaces/Enrollments';
import {
    formatDate,
    formatDateWithTimeZone,
} from '../../../../helpers/functions/date-convert';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import ROUTES from '../../../../routes/routes';
import { pushModal } from '../../../../tools/router';
import {
    useCheckAllProgressService,
    useCheckSingleProgressService,
    useGetPractisSetService,
    useGetTraineesReportService,
    useNudgeUsersService,
    useRemindToStartService,
    useResetPractisSetDetailsService,
    useSearchPractisSetDetailsUsersService,
    useUnenrollEnrollmentService,
} from '../../store/services';
import {
    useCreateUpdateEnrollmentDueDate,
} from '../../../../api';
import {
    selectSelectedProgresses,
    useEnrollmentState,
    useTrainerPractisSetState,
} from '../../store/states';
import { useLabelsState } from '../../../labels/store/states';
import { clearSelectedItemIfNotLoaded } from '../../../labels/tools';
import {
    ActionItem,
    ActionButton,
} from '../../../../ui/components/ActionButton';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { secondsToMinSimple } from '../../../../helpers/functions/convert-time';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { Popup } from '../../../../ui/components/Popup';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { countPractisSetDetailsAppliedFiltersCount } from './tools';
import TrainingDetailsFilters from './components/Filters/TrainingDetailsFilters';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import {
    useProgressActionsHelper,
    ProgressActionsHelper,
    ProgressActions,
} from '../../tools';
import GeneralProgressCol from './components/ProgressDetails/GeneralProgressCol';
import PlusInCircleIcon from '../../../../ui/icons/PlusInCircleIcon';
import ViewPractisSetIcon from '../../../../ui/icons/ViewPractisSetIcon';
import { getSearchState } from '../../../../features/searchState/store/reducer';
import { SearchStateInterface } from '../../../../features/searchState/store/states';
import { SEARCH_STATE } from '../../../../features/searchState/constants';
import { isEmpty } from '../../../../helpers/functions/object-helpers';
import { DueDateFilters } from '../../../../constants/interfaces/DueDates';
import ExportReportIcon from '../../../../ui/icons/ExportReportIcon';
import { DueDateCalendar } from '../../../../ui/components/DueDateCalendar';
import { usePreviousData } from '../../../../helpers/hooks/usePreviousData';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import {
    Avatar,
    AvatarTile,
    DueDateWrapper,
    DueDateIcon,
    OverdueFlagWrapped,
    ProgressBarContainer,
    StyledFirstTableContent,
    StyledProgressContainer,
    useStyles,
} from './styles';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { DueDateCalendarParams } from '../../../../constants/interfaces/dueDate';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import TableTitleOverflowText from '../../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { NudgeUser } from '../../../../ui/components/table-wrapper/table/TableAssignOptionsMenu/components/NudgeUser';
import { EmptyCellDash } from '../../../../ui/components/table-wrapper/table/EmptyCellDash';
import { UserProfile } from '../../../../constants/interfaces/User';
import { NudgeData } from '../../../../api/alert/types';
import { useNudgeUsersBulkActionService } from '../../../../services/GeneralBulkActionServices';
import { useRemindToStartBulkActionService } from '../../services/TeamBulkActionsService';
import { UserStatus } from '../../../users/store/costants';
import {
    useDeleteEnrollmentBulkActionService,
    useUpdateEnrollmentBulkActionService,
} from './services/EnrollmentBulkActionsService';
import {
    onListenEvent,
    onRemoveEvent,
} from '../../../../helpers/functions/Events';
import {
    EventNameList,
    REFRESH_TRAINING_PAGE,
} from '../../../../helpers/functions/Events/types';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';

const qs = require('query-string');

export const RenderStarted: FC<{
    enrollment?: EnrollmentInterface;
    onResendInvitation?: (enrollmentIds: number[]) => Promise<any>;
}> = ({ enrollment, onResendInvitation }) => {
    if (
        !enrollment ||
        !enrollment.startedAt ||
        !enrollment.user ||
        !onResendInvitation
    ) {
        return <EmptyCellDash />;
    }

    if (enrollment.status !== EnrollmentStatusEnum.PENDING) {
        return (
            <StyledProgressContainer data-test="training-item-start-date">
                {formatDate(enrollment.startedAt)}
            </StyledProgressContainer>
        );
    } else {
        return <EmptyCellDash />;
    }
};

const ITEMS_PER_PAGE = 20;

export const ProgressDetails: FC<{
    history: History<any>;
    profile?: UserProfile;
    loading?: boolean;
    params: { teamId: string; practisSetId: string };
    updateTrainees?: Array<any>;
    searchPractisSetDetailsUsers(searchParams: SearchParams): void;
    practisSetsEnrollments?: PaginationResult<EnrollmentInterface>;
    practisSet?: PractisSets;
    company?: CompanyInterface;
    onResendInvitation: (enrollmentIds: number[]) => Promise<any>;
    onGetPractisSet: (practisSetId: number) => void;
    selectedLabels?: number[];
    checkAllProgress(
        progressIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    checkSingleProgress(progressId: number): void;
    selectedProgressIds: number[];
    selectedProgresses?: EnrollmentInterface[];
    actionsHelper: ProgressActionsHelper;
    onNudgeUsers: (
        nudgeUserDate: NudgeData[],
        successCallback?: () => void
    ) => void;
    onUnenrollEnrollment: (
        enrollmentIds: number[],
        successCallback?: () => void
    ) => void;
    getTraineesReportService?(
        userIds: number[] | null,
        practisSetId: number,
        searchParams: SearchParams,
        teamId: number,
        selectAll?: boolean
    ): void;
    searchState?: SearchStateInterface;
    locationState: any;
}> = ({
    profile,
    practisSetsEnrollments,
    loading,
    searchPractisSetDetailsUsers,
    params,
    history,
    updateTrainees,
    company,
    onResendInvitation,
    onGetPractisSet,
    practisSet,
    selectedLabels,
    checkAllProgress,
    checkSingleProgress,
    selectedProgressIds,
    selectedProgresses,
    actionsHelper,
    onNudgeUsers,
    onUnenrollEnrollment,
    getTraineesReportService,
    searchState,
    locationState,
}) => {
    const classes = useStyles();

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<any>(false);

    const [dueDateCalendarParams, setDueDateCalendarParams] =
        useState<DueDateCalendarParams>({
            isOpen: false,
            data: null,
        });

    const [showNudgeDialogProgressId, setShowNudgeDialogProgressId] =
        useState<any>(null);

    const canAccessPage = useHandleAccessPage();
    const updateEnrollmentsDueDate = useCreateUpdateEnrollmentDueDate();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const prevCompany = usePreviousData(company);

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        orderBy: { field: 'enrollment.lastTraining', asc: false },
        filterByStatus: [],
        filters: [],
        filterByRegistrationStatus: [],
        labelIDs: selectedLabels,
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
        filterByDueDate: [],
    };

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState?.key === SEARCH_STATE.PRACTIS_SET_DETAILS.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        setLabelIDs,
        refreshSearchParams,
        setCustom,
    } = useSearchParamsState(usableParams);

    const isCheckboxPartiallySelected =
        practisSetsEnrollments &&
        practisSetsEnrollments.items &&
        selectedProgressIds &&
        selectedProgressIds.length < practisSetsEnrollments.items.length;

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchPractisSetDetailsUsers(searchParams);
        setLastRefreshed(new Date());
        setIsSelectAll(false);
        checkAllProgress([], true, isCheckboxPartiallySelected);
    }, [
        searchParams,
        initialOffset,
        searchPractisSetDetailsUsers,
        checkAllProgress,
        isCheckboxPartiallySelected,
    ]);

    const handleAddTrainees = () => {
        pushModal(
            history,
            ROUTES.TEAMS_PAGES.TRAINING_PAGES.TRAINEE_INVITATION.replace(
                ':teamId',
                params.teamId.toString()
            ).replace(':practisSetId', params.practisSetId.toString())
        );
    };

    const handleEditPractisSet = () => {
        if (!params.practisSetId || !params.teamId) return;
        pushModal(
            history,
            ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE.replace(
                ':practisSetId',
                params.practisSetId.toString()
            )
        );
    };

    const handleViewPractisSetReport = (userId: number, enrollmentId: number) => {
        history.push(ROUTES.TRAINEE_DETAILS
            .replace(
                ':userId',
                userId.toString()
            )
            .replace(
                ':enrollmentId',
                enrollmentId.toString()
            ),
            { useGoBack: true }
        );
    };

    const filterOverdueHandler = (dueDates: DueDateFilters[]) => {
        setCustom('filterByDueDate', dueDates);
    };

    const handleViewProfile = (userId: number) => {
        history.push(
            ROUTES.USER_PERFORMANCE.replace(':userId', userId.toString()),
            { useGoBack: true }
        );
    };

    const updateAllProgressCheck = useCallback(
        (checked: boolean) => {
            const practisSetIds =
                (practisSetsEnrollments &&
                    practisSetsEnrollments.items.map(
                        (practisSetsEnrollment: EnrollmentInterface) =>
                            Number(practisSetsEnrollment.id)
                    )) ||
                [];
            checkAllProgress(
                practisSetIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [practisSetsEnrollments, isCheckboxPartiallySelected, checkAllProgress]
    );

    const handleProgressCheck = (e: any, trainee: EnrollmentInterface) => {
        e.stopPropagation();
        if (!!trainee.id) {
            checkSingleProgress(trainee.id);
        }
    };

    const onNudgeSingleUserSuccessCallback = () => {
        setShowNudgeDialogProgressId(null);
    };

    const handleNudgeSingleUser = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;

            if (showNudgeDialogProgressId) {
                const enrollmentId = practisSetsEnrollments?.items.find(
                    item => item.user.id === showNudgeDialogProgressId
                )?.id;

                if (enrollmentId) {
                    const nudgeUserData: NudgeData = {
                        type: 'enrollment',
                        enrollmentId,
                        receiverId: showNudgeDialogProgressId,
                        text,
                        senderName,
                    };
                    onNudgeUsers(
                        [nudgeUserData],
                        onNudgeSingleUserSuccessCallback
                    );
                }
            }
        },
        [onNudgeUsers, practisSetsEnrollments?.items, showNudgeDialogProgressId]
    );

    const onNudgeMultipleUserSuccessCallback = useCallback(() => {
        checkAllProgress([], true, isCheckboxPartiallySelected);
    }, [checkAllProgress, isCheckboxPartiallySelected]);

    const nudgeUsersBulkActionService = useNudgeUsersBulkActionService(
        'enrollment',
        {
            teamId: params.teamId,
            practisSetId: params.practisSetId,
        },
        searchParams,
        practisSetsEnrollments?.count ?? 0,
        refreshData
    );

    const handleNudgeMultipleUser = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;

            if (isSelectAll) {
                nudgeUsersBulkActionService({ ...{ text }, ...{ senderName } });
            } else {
                const users = [] as { id: number; enrollmentId: number }[];
                practisSetsEnrollments?.items.forEach(element => {
                    if (selectedProgressIds.includes(element.id)) {
                        users.push({
                            id: element.user.id,
                            enrollmentId: element.id,
                        });
                    }
                });
                if (selectedProgressIds) {
                    const nudgeUsersDate = users.map(
                        user =>
                            ({
                                type: 'enrollment',
                                enrollmentId: user.enrollmentId,
                                receiverId: user.id,
                                text,
                                senderName,
                            } as NudgeData)
                    );

                    onNudgeUsers(
                        nudgeUsersDate,
                        onNudgeMultipleUserSuccessCallback
                    );
                }
            }
        },
        [
            isSelectAll,
            nudgeUsersBulkActionService,
            practisSetsEnrollments?.items,
            selectedProgressIds,
            onNudgeUsers,
            onNudgeMultipleUserSuccessCallback,
        ]
    );

    const showMessage = useShowMessage();

    const handleSingleRemind = useCallback(
        (progressId?: any) => {
            if (
                !!onResendInvitation &&
                !!practisSetsEnrollments &&
                !!practisSetsEnrollments.items &&
                !!practisSetsEnrollments.items.length
            ) {
                const selectedEnrollmentId = [progressId];

                onResendInvitation(selectedEnrollmentId).then(() => {
                    showMessage(`Reminder Sent to User`, 'success');
                });
            }
        },
        [onResendInvitation, practisSetsEnrollments, showMessage]
    );

    const remindToStartBulkActionService = useRemindToStartBulkActionService(
        params.teamId,
        params.practisSetId,
        searchParams,
        practisSetsEnrollments?.count ?? 0,
        refreshData
    );

    const handleBulkRemind = useCallback(() => {
        if (isSelectAll) {
            remindToStartBulkActionService();
        } else {
            if (
                !!onResendInvitation &&
                !!selectedProgressIds?.length &&
                !!practisSetsEnrollments?.items?.length
            ) {
                const enrollmentIds = practisSetsEnrollments?.items
                    .filter(
                        enrollment =>
                            selectedProgressIds.includes(enrollment.id) &&
                            enrollment.user.status === UserStatus.ACTIVE &&
                            (enrollment.status === EnrollmentStatus.PENDING ||
                                enrollment.status === EnrollmentStatus.ENROLLED)
                    )
                    .map(enrollment => enrollment.id);

                onResendInvitation(enrollmentIds).then(() => {
                    showMessage(
                        `Reminder(s) Sent to ${
                            selectedProgressIds.length > 1 ? 'Users' : 'User'
                        }`,
                        'success'
                    );
                });
            }
        }
    }, [
        isSelectAll,
        remindToStartBulkActionService,
        selectedProgressIds,
        practisSetsEnrollments,
        onResendInvitation,
        showMessage,
    ]);

    const orderBy = searchParams.orderBy;
    const enFirstName = useOrderBy('firstName', orderBy, setOrderBy);
    const enAssigned = useOrderBy('enrollment.createdAt', orderBy, setOrderBy);
    const enStarted = useOrderBy('enrollment.enrolledAt', orderBy, setOrderBy);
    const enDueDate = useOrderBy('enrollment.dueDate', orderBy, setOrderBy);
    const enTime = useOrderBy('enrollment.trainingTime', orderBy, setOrderBy);
    const enLastTrainingAt = useOrderBy(
        'enrollment.lastTraining',
        orderBy,
        setOrderBy
    );
    const enProgressValue = useOrderBy(
        'enrollment.progressValue',
        orderBy,
        setOrderBy
    );

    const enAccuracyAvg = useOrderBy(
        'enrollment.averageAccuracy',
        orderBy,
        setOrderBy
    );

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countPractisSetDetailsAppliedFiltersCount(searchParams),
        itemsCount: practisSetsEnrollments?.items.length || 0,
    });

    /**
     * @function onExportReportButtonClick
     * @returns { void }
     */
    const onExportReportButtonClick = useCallback(() => {
        history.push(
            `${ROUTES.REPORTS.PRACTIS_SET_SUMMARY_REPORT}?team=${params.teamId}&practisSet=${params.practisSetId}`);
    }, [history, params]);

    const filterStatusHandler = (status: EnrollmentStatusEnum[]) => {
        setCustom('filterByStatus', status);
    };

    const registrationStatusHandler = (values: string[]) => {
        setCustom('filterByRegistrationStatus', values);
    };

    const isListEmpty = lodashIsEmpty(practisSetsEnrollments?.items);

    const headersDisabled =
        !practisSetsEnrollments ||
        (practisSetsEnrollments && practisSetsEnrollments.items.length < 1);

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedProgresses),
        [selectedProgresses, actionsHelper]
    );

    const isExportButtonDisabled: boolean =
        !practisSetsEnrollments ||
        isEmpty(practisSetsEnrollments) ||
        practisSetsEnrollments?.count === 0;

    /**
     * @function openDueDatePopup
     * @param { number | undefined } enrollmentId
     * @returns { Promise<void> }
     */
    const openDueDatePopup = async (enrollmentId?: number) => {
        const enrollmentIds = enrollmentId
            ? [enrollmentId]
            : selectedProgresses?.map(item => item?.id || 0) || [];

        //temporary solution for calendar data 

        let dueDateDataManual: any  = {}
        if (isSelectAll) {
            dueDateDataManual = {"type":"MULTIPLE", "dueDate": null , "count": practisSetsEnrollments?.count}
        } else if (enrollmentIds.length > 1 ) {
            dueDateDataManual = {"type":"MULTIPLE", "dueDate": null , "count": enrollmentIds.length}
        } else if (enrollmentIds.length === 1) {
            const dueDate = practisSetsEnrollments ? practisSetsEnrollments.items.find((item) => item.id === enrollmentIds[0])?.dueDate : null;
            dueDateDataManual = {"type" : "SINGLE", "dueDate": dueDate, "count": 1}
        } 
        
        setDueDateCalendarParams(prev => ({
            isOpen: true,
            data: {
                ...dueDateDataManual,
                enrollmentIds,
                isSubTitleVisible: !enrollmentId && true,
            },
        }));
    };

    /**
     * @function onCloseDueDateCalendar
     * @returns { void }
     */
    const onCloseDueDateCalendar = (): void => {
        setDueDateCalendarParams(prev => ({ isOpen: false, data: null }));
    };

    const updateSuccessCallback = () => {
        setDueDateCalendarParams(prev => ({ ...prev, isOpen: false }));
        refreshData();
    };

    const handleUpdateEnrollmentsBulkActionService =
        useUpdateEnrollmentBulkActionService(
            {
                searchParams: {
                    ...searchParams,
                    teamId: params.teamId,
                    practisSetId: +params.practisSetId,
                },
            },
            practisSetsEnrollments?.count,
            updateSuccessCallback
        );

    const updateDueDate = async (dueDate: string | null) => {
        const { enrollmentIds, type } = dueDateCalendarParams?.data!;

        const updateDueDateSuccessMessage =
            type === 'NO_DUEDATE'
                ? 'Due date assigned'
                : dueDate
                ? 'Due date changed'
                : 'Due date removed';

        const enrollments = enrollmentIds.map(enrollmentId => ({
            enrollmentId,
            dueDate,
        }));

        if (isSelectAll) {
            handleUpdateEnrollmentsBulkActionService(dueDate);
        } else {
            await updateEnrollmentsDueDate(enrollments);
            showMessage(updateDueDateSuccessMessage, 'success');
        }

        updateSuccessCallback();
    };

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.VIEW_PRACTIS_SET_PROGRESS]);
    }, [canAccessPage]);

    useEffect(() => {
        params.practisSetId && onGetPractisSet(+params.practisSetId);
    }, [onGetPractisSet, params.practisSetId]);

    useEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchParams.labelIDs = selectedLabels;
        searchPractisSetDetailsUsers(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPractisSetDetailsUsers, searchParams]);

    useEffect(() => {
        if (!company?.id || !prevCompany?.id) return;
        if (company?.id !== prevCompany?.id) {
            history.push(ROUTES.TEAMS_PAGES.TRAINING);
        }
    }, [company, prevCompany, history]);

    useEffect(() => {
        if (updateTrainees && updateTrainees.length > 0) {
            refreshSearchParams();
        }
    }, [updateTrainees, refreshSearchParams]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
    }, [initialOffset, pageIndex, setOffset]);

    // NOTE: no need to add second parameter for this useEffect hook.
    // We need to clear selectedProgressIds when the component did mount
    useEffect(() => {
        if (selectedProgressIds && selectedProgressIds.length) {
            checkAllProgress(selectedProgressIds, false, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => checkAllProgress([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkAllProgress([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practisSetsEnrollments]);

    useEffect(() => {
        const filteredPractisSetsEnrollments = practisSetsEnrollments
            ? practisSetsEnrollments.items.map(enrollment =>
                  Number(enrollment.id)
              )
            : [];
        const enrollmentsToClear = clearSelectedItemIfNotLoaded(
            selectedProgressIds,
            filteredPractisSetsEnrollments
        );
        if (enrollmentsToClear && enrollmentsToClear.length > 0) {
            checkAllProgress(enrollmentsToClear, false);
        }
    }, [selectedProgressIds, practisSetsEnrollments, checkAllProgress]);

    const handleDeleteEnrollmentsBulkActionService =
        useDeleteEnrollmentBulkActionService(
            {
                searchParams: {
                    ...searchParams,
                    teamId: params.teamId,
                    practisSetId: +params.practisSetId,
                },
            },
            'user',
            practisSetsEnrollments?.count,
            refreshData
        );

    /**
     * @function onConfirmUnassign
     * @param { number[] } unAssignedPractisSetIds
     * @returns { void }
     */
    const onConfirmUnassign = (unAssignedPractisSetIds: number[]): void => {
        isSelectAll &&
        practisSetsEnrollments?.count !== unAssignedPractisSetIds.length
            ? handleDeleteEnrollmentsBulkActionService()
            : onUnenrollEnrollment(unAssignedPractisSetIds, refreshData);
    };

    /**
     * @function handleUnassign
     * @param { number[] } ids
     * @returns { void }
     */
    const handleUnassign = (ids: number[]) => {
        showConfirmationModalDialog({
            modalTitle: 'Are you sure?',
            description:
                selectedProgressIds.length > 1
                    ? 'Users will get notified about this change.'
                    : 'User will get notified about this change.',
            onConfirm: () => onConfirmUnassign(ids),
        });
    };

    /**
     * @function handleMultipleUserUnassign
     * @returns { void }
     */
    const handleMultipleUserUnassign = (): void => {
        if (!practisSetsEnrollments || !selectedProgressIds) return;
        const selectedEntities = practisSetsEnrollments.items.filter(
            item => item.id && selectedProgressIds.includes(item.id)
        );

        const selectedEntityIds: number[] = selectedEntities.map(entity =>
            entity ? entity.id || 0 : 0
        );

        handleUnassign(selectedEntityIds);
    };

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (offset: number) => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        checkAllProgress([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        const practisSetIds =
            (practisSetsEnrollments &&
                practisSetsEnrollments.items.map(
                    (practisSetsEnrollment: EnrollmentInterface) =>
                        Number(practisSetsEnrollment.id)
                )) ||
            [];
        checkAllProgress(practisSetIds, true, isCheckboxPartiallySelected);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllProgressCheck(event.target.checked);
        setIsSelectAll(false);
    };

    const isMasterCheckBoxChecked =
        !!selectedProgressIds && !!selectedProgressIds.length;

    const isMasterCheckBoxDisabled = headersDisabled;

    /**
     * @function onRowClick
     * @param { EnrollmentInterface } trainee
     * @returns { void }
     */
    const onRowClick = (trainee: EnrollmentInterface) => {
        handleViewPractisSetReport(trainee.user.id, trainee.id);
    };

    /**
     * @function checkIsRowChecked
     * @param { EnrollmentInterface } trainee
     * @returns { boolean }
     */
    const checkIsRowChecked = (trainee: EnrollmentInterface): boolean =>
        !!selectedProgressIds &&
        !!trainee.id &&
        selectedProgressIds.includes(trainee.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { EnrollmentInterface } set
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, trainee: EnrollmentInterface) => {
        handleProgressCheck(event, trainee);
        setIsSelectAll(false);
    };

    // Listen To Assign Users And Due Date -- Refresh the page after assign users
    useEffect(() => {
        onListenEvent(EventNameList[REFRESH_TRAINING_PAGE], refreshData);

        return () => {
            onRemoveEvent(EventNameList[REFRESH_TRAINING_PAGE], refreshData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            practisSetsEnrollments?.items.length &&
            selectedProgressIds.length === practisSetsEnrollments?.count
        ) {
            setIsSelectAll(true);
        }
    }, [practisSetsEnrollments?.count, practisSetsEnrollments?.items.length, selectedProgressIds.length]);

    return (
        <>
            <MainWrapper
                title="Practis Set Details"
                goBackUrl={ROUTES.TEAMS_PAGES.TRAINING_PAGES.PRACTIS_SETS.replace(
                    ':teamId',
                    params && params.teamId ? params.teamId.toString() : ''
                )}
                subTitle={practisSet?.name ?? ''}
                useGoBack={locationState?.useGoBack}
                htmlPageTitle={`Practis Set Details ${
                    practisSet ? `- ${practisSet?.name}` : ''
                } - Practis`}
                dataTest="training-details"
            >
                {!!showNudgeDialogProgressId && (
                    <DialogWrapper
                        customization={{
                            width: 480,
                            padding: '49px 32px 32px 32px',
                        }}
                    >
                        <NudgeUser
                            practisSetName={
                                practisSet && practisSet.name
                                    ? practisSet.name
                                    : ''
                            }
                            fromUser={
                                !!profile
                                    ? `${profile.firstName} ${profile.lastName}`
                                    : ''
                            }
                            onSend={handleNudgeSingleUser}
                            onClose={() => setShowNudgeDialogProgressId(null)}
                        />
                    </DialogWrapper>
                )}
                {loading && !isSelectAll && (
                    <LoadingComponent />
                )}

                <TableWrapper
                    tableStates={tableStates}
                    data={practisSetsEnrollments?.items}
                    selectedLabels={selectedLabels}
                    cornered={selectedLabels && !!selectedLabels.length}
                    tableRefreshConfigurations={{
                        lastRefreshed: lastRefreshed,
                        refreshData: refreshData,
                        dataTest: 'training-timestamp',
                        buttons: [
                            {
                                name: 'Assign Users',
                                icon: <PlusInCircleIcon />,
                                onPress: handleAddTrainees,
                                permissions: [
                                    NEW_PERMISSIONS.ASSIGN_ENROLLMENT,
                                ],
                                dataTest: 'assign-users',
                            },
                            {
                                name: 'Generate Report',
                                icon: <ExportReportIcon />,
                                onPress: onExportReportButtonClick,
                                permissions:
                                    [
                                        NEW_PERMISSIONS.EXPORT_LIST_PRACTIS_SET_USER,
                                    ] || [],
                                disabled: isExportButtonDisabled,
                                dataTest: 'export-report',
                            },
                            {
                                name: 'View Practis Set',
                                icon: <ViewPractisSetIcon />,
                                onPress: handleEditPractisSet,
                                permissions: [
                                    NEW_PERMISSIONS.UPDATE_PRACTIS_SET,
                                ],
                                dataTest: 'view-practis-set',
                            },
                        ],
                    }}
                    tableToolsOptions={{
                        pagingOptions: {
                            totalCount: practisSetsEnrollments?.count ?? 0,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onPageChange: handlePageChange,
                            searchOrFiltersApplied:
                                searchParams.searchTerm.length ||
                                searchParams.labelIDs?.length,
                            dataTest: 'training-paging',
                        },
                        searchInputOptions: {
                            initialValue: searchParams.searchTerm,
                            onSearchChange: setSearchTerm,
                            isSearchInputDisabled: isListEmpty && lodashIsEmpty(searchParams.searchTerm),
                            dataTest: 'training-search'
                        },
                        isSelectedItemOptionsVisible:
                            !lodashIsEmpty(selectedProgresses) &&
                            !lodashIsEmpty(practisSetsEnrollments),
                        selectedItemOptions: {
                            isSelectAll: isSelectAll,
                            selectedLength: selectedProgresses?.length,
                            totalCount: practisSetsEnrollments?.count,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onClearSelection: onClearSelection,
                            onSelectAll: onSelectAll,
                            bulkActionsConfig: {
                                disabled: bulkActions.areBulkActionsDisabled(),
                                disabledLabel: bulkActions.areItemsEmpty()
                                    ? undefined
                                    : "Bulk actions can't be applied",
                                practisSetName: practisSet?.name || '',
                                nudgeTitle: 'Nudge Users',
                                showNudgeWarning: false,
                                onNudgeSendButtonClicked:
                                    handleNudgeMultipleUser,
                                sendNudgePermissions:
                                    bulkActions.isBulkActionVisible(
                                        ProgressActions.NUDGE_USER
                                    )
                                        ? [NEW_PERMISSIONS.SEND_NUDGE]
                                        : [],
                                nudgeButtonDisabled:
                                    bulkActions.isBulkActionDisabled(
                                        ProgressActions.NUDGE_USER
                                    ),
                                showRemindPermission:
                                    bulkActions.isBulkActionVisible(
                                        ProgressActions.REMIND_TO_START
                                    )
                                        ? [NEW_PERMISSIONS.RESEND_INVITATION]
                                        : [],
                                onRemindSubmit: handleBulkRemind,
                                remindDisabled:
                                    bulkActions.isBulkActionDisabled(
                                        ProgressActions.REMIND_TO_START
                                    ),
                                removeItemTitle: 'Unassign Users',
                                removeItemPermissions:
                                    bulkActions.isBulkActionVisible(
                                        ProgressActions.UNASSIGN_USER
                                    )
                                        ? [NEW_PERMISSIONS.UNASSIGN_ENROLLMENT]
                                        : [],
                                onRemoveItemsSubmit: handleMultipleUserUnassign,
                                removeItemsDisabled:
                                    bulkActions.isBulkActionDisabled(
                                        ProgressActions.UNASSIGN_USER
                                    ),
                                dueDatesPermissions:
                                    bulkActions.isBulkActionVisible(
                                        ProgressActions.SET_DUEDATE
                                    )
                                        ? [NEW_PERMISSIONS.SET_DUE_DATE]
                                        : [],
                                isDueDatesDisabled:
                                    bulkActions.isBulkActionDisabled(
                                        ProgressActions.SET_DUEDATE
                                    ),
                                isSelectAll: isSelectAll,
                                totalCount: practisSetsEnrollments?.count,
                                onUpdateDueDatesSubmit: openDueDatePopup,
                            },
                        },
                        filterOptions: {
                            filterComponent: (
                                <Popup<HTMLButtonElement>
                                    content={({ hide }) => (
                                        <TrainingDetailsFilters
                                            onStatusChange={filterStatusHandler}
                                            status={
                                                searchParams.filterByStatus as EnrollmentStatusEnum[]
                                            }
                                            onSuccessApply={() => hide()}
                                            registrationStatus={
                                                searchParams.filterByRegistrationStatus ||
                                                []
                                            }
                                            onRegistrationStatusChange={
                                                registrationStatusHandler
                                            }
                                            onOverdueChange={
                                                filterOverdueHandler
                                            }
                                            dueDate={
                                                searchParams.filterByDueDate as DueDateFilters[]
                                            }
                                        />
                                    )}
                                    padding={'40px 32px 32px 32px'}
                                    horizontalOffset={256}
                                >
                                    {(ref, { toggleShown, shown }) => (
                                        <FilterByHandler
                                            ref={ref}
                                            open={shown}
                                            toggleOpen={toggleShown}
                                            filtersCount={countPractisSetDetailsAppliedFiltersCount(
                                                searchParams
                                            )}
                                            disabled={
                                                searchParams.searchTerm.length > 0 &&
                                                isListEmpty
                                            }
                                            dataTest="training-filters"
                                        />
                                    )}
                                </Popup>
                            ),
                        },
                    }}
                    tableEmptyStateConfigurations={{
                        shouldShowEmptyState:
                            !loading &&
                            practisSetsEnrollments?.items?.length === 0,
                        noEntriesOptions: {
                            icon: User,
                            text: 'No Users Yet',
                            dataTest: 'training-no-entries',
                        },
                        noSearchResultsOptions: {
                            entityName: <>Users</>,
                            dataTest: 'training-no-found-entries',
                        },
                        noFilterResultsOptions: {
                            dataTest: 'training-no-filtered-entries',
                        },
                    }}
                    configurations={{
                        masterCheckBoxConfig: {
                            checked: isMasterCheckBoxChecked,
                            disabled: isMasterCheckBoxDisabled,
                            partial:
                                isCheckboxPartiallySelected || !isSelectAll,
                            handleCheck: onCheckMasterCheckbox,
                            dataTest: 'training-master-checkbox',
                        },
                        columns: [
                            {
                                title: 'Users',
                                width: 20,
                                ...enFirstName,
                                disabled: headersDisabled,
                                dataTest: 'training-user-name-column',
                            },
                            {
                                title: 'Due Date',
                                width: 10,
                                ...enDueDate,
                                disabled: headersDisabled,
                                dataTest: 'training-due-date-column',
                            },
                            {
                                title: 'Progress',
                                width: 10,
                                ...enProgressValue,
                                disabled: headersDisabled,
                                withTooltip: true,
                                tooltipText:
                                    'The percentage completion of all the items in a Practis Set.',
                                dataTest: 'training-progress-column',
                            },
                            {
                                title: 'Accuracy',
                                width: 10,
                                ...enAccuracyAvg,
                                disabled: headersDisabled,
                                withTooltip: true,
                                tooltipText:
                                    'The accuracy scores for all submitted scenario accuracy tests.',
                                dataTest: 'training-accuracy-column',
                            },
                            {
                                title: 'Training Time',
                                width: 10,
                                ...enTime,
                                disabled: headersDisabled,
                                withTooltip: true,
                                tooltipText:
                                    'The total time spent by a user learning material.',
                                dataTest: 'training-time-column',
                            },
                            {
                                title: 'Assigned',
                                width: 10,
                                ...enAssigned,
                                disabled: headersDisabled,
                                dataTest: 'assign-date-column',
                            },
                            {
                                title: 'Started',
                                width: 10,
                                ...enStarted,
                                disabled: headersDisabled,
                                dataTest: 'start-date-column',
                            },
                            {
                                title: 'Last Training',
                                width: 10,
                                ...enLastTrainingAt,
                                disabled: headersDisabled,
                                dataTest: 'last-training-column',
                            },
                            {
                                width: 5,
                            },
                        ],

                        rowConfig: {
                            onRowClick: onRowClick,
                            onRowCheckHandler: onRowCheckHandler,
                            isRowChecked: checkIsRowChecked,
                            dataTest: 'training-item',

                            cells: [
                                {
                                    fieldType: 'CUSTOM_FIELD',
                                    cellClassName: classes.customTableCellStyle,
                                    getCustomFieldComponent: (
                                        trainee: EnrollmentInterface
                                    ) => (
                                        <StyledFirstTableContent>
                                            <AvatarTile>
                                                <Avatar
                                                    profile={{
                                                        ...trainee.user,
                                                        picture:
                                                            trainee.user
                                                                .imageUrl,
                                                    }}
                                                    size="sm"
                                                    marginRight={15}
                                                    dataTest="training-item-user-avatar"
                                                />
                                                <TableTitleOverflowText
                                                    isCurrent={
                                                        profile &&
                                                        profile.id ===
                                                            trainee.user.id
                                                    }
                                                    dataTest="training-item-user-name"
                                                >{`${trainee.user.firstName} ${trainee.user.lastName}`}</TableTitleOverflowText>
                                            </AvatarTile>
                                        </StyledFirstTableContent>
                                    ),
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (
                                        trainee: EnrollmentInterface
                                    ) => !trainee?.dueDate,
                                    fieldProps: {
                                        renderTitle: (
                                            trainee: EnrollmentInterface
                                        ) =>
                                            trainee?.dueDate ? (
                                                <DueDateWrapper>
                                                    <span data-test="training-item-due-date">
                                                        {formatDateWithTimeZone(
                                                            trainee?.dueDate
                                                        )}
                                                    </span>
                                                    {trainee.hasOverdue && (
                                                        <DueDateIcon data-test="training-item-due-date-icon">
                                                            <OverdueFlagWrapped />
                                                        </DueDateIcon>
                                                    )}
                                                </DueDateWrapper>
                                            ) : (
                                                ''
                                            ),
                                    },
                                },
                                {
                                    fieldType: 'CUSTOM_FIELD',
                                    getCustomFieldComponent: (
                                        trainee: EnrollmentInterface
                                    ) => (
                                        <ProgressBarContainer>
                                            <GeneralProgressCol
                                                count={Math.round(
                                                    trainee?.progress * 100
                                                )}
                                                max={100}
                                                completedAt={
                                                    trainee?.completedAt
                                                }
                                                status={trainee.status}
                                                dataTest="training-item-progress"
                                            />
                                        </ProgressBarContainer>
                                    ),
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (
                                        trainee: EnrollmentInterface
                                    ) => !trainee?.averageAccuracy,
                                    fieldProps: {
                                        renderTitle: (
                                            trainee: EnrollmentInterface
                                        ) =>
                                            `${Math.floor(
                                                trainee?.averageAccuracy * 100
                                            )}%`,
                                        dataTest: 'training-item-accuracy',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (
                                        trainee: EnrollmentInterface
                                    ) => !trainee?.trainingTime,
                                    fieldProps: {
                                        renderTitle: (
                                            trainee: EnrollmentInterface
                                        ) =>
                                            secondsToMinSimple(
                                                trainee!.trainingTime
                                            ),
                                        dataTest: 'training-item-time',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (
                                        trainee: EnrollmentInterface
                                    ) => !trainee?.createdAt,
                                    fieldProps: {
                                        renderTitle: (
                                            trainee: EnrollmentInterface
                                        ) => formatDate(trainee?.createdAt),
                                        dataTest: 'training-item-assign-date',
                                    },
                                },
                                {
                                    fieldType: 'CUSTOM_FIELD',
                                    getCustomFieldComponent: (
                                        trainee: EnrollmentInterface
                                    ) => (
                                        <RenderStarted
                                            onResendInvitation={
                                                onResendInvitation
                                            }
                                            enrollment={trainee}
                                        />
                                    ),
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    shouldShowEmptyCell: (
                                        trainee: EnrollmentInterface
                                    ) => !trainee?.lastTrainingAt,

                                    fieldProps: {
                                        renderTitle: (
                                            trainee: EnrollmentInterface
                                        ) =>
                                            formatDate(trainee?.lastTrainingAt),
                                        dataTest: 'training-item-last-date',
                                    },
                                },
                                {
                                    fieldType: 'LIST_ACTIONS',
                                    fieldProps: {
                                        getListActionsComponent: (
                                            trainee: EnrollmentInterface
                                        ) => (
                                            <ActionButton
                                                customWidth={224}
                                                isHidden={
                                                    !!selectedProgresses?.length
                                                }
                                                dataTest="training-item-menu"
                                            >
                                                {actionsHelper.canViewPractisSetReport() && (
                                                    <ActionItem
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleViewPractisSetReport(
                                                                trainee.user.id,
                                                                trainee.id
                                                            );
                                                        }}
                                                        dataTest="view-practis-set-report-item-action"
                                                    >
                                                        View Practis Set Report
                                                    </ActionItem>
                                                )}
                                                {actionsHelper.canViewProfile() && (
                                                    <ActionItem
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleViewProfile(
                                                                trainee.user.id!
                                                            );
                                                        }}
                                                        dataTest="view-profile-item-action"
                                                    >
                                                        View Profile
                                                    </ActionItem>
                                                )}

                                                {actionsHelper.canChangeDueDate() && (
                                                    <ActionItem
                                                        onClick={() =>
                                                            openDueDatePopup(
                                                                trainee?.id //enroll id
                                                            )
                                                        }
                                                        dataTest="due-date-item-action"
                                                    >
                                                        Due Date
                                                    </ActionItem>
                                                )}

                                                {actionsHelper.canNudgeUser(
                                                    trainee.user
                                                ) && (
                                                    <ActionItem
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            setShowNudgeDialogProgressId(
                                                                trainee.user.id!
                                                            );
                                                        }}
                                                        dataTest="nudge-user-item-action"
                                                    >
                                                        Nudge User
                                                    </ActionItem>
                                                )}

                                                {actionsHelper.canRemindToStart(
                                                    trainee
                                                ) && (
                                                    <ActionItem
                                                        onClick={e => {
                                                            handleSingleRemind(
                                                                trainee.id!
                                                            );
                                                        }}
                                                        dataTest="remind-to-start-item-action"
                                                    >
                                                        Remind to Start
                                                    </ActionItem>
                                                )}
                                                {actionsHelper.canUnassignUser() && (
                                                    <ActionItem
                                                        destructive={true}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            trainee &&
                                                                handleUnassign([
                                                                    trainee.id!,
                                                                ]);
                                                        }}
                                                        dataTest="unassign-user-item-action"
                                                    >
                                                        Unassign User
                                                    </ActionItem>
                                                )}
                                            </ActionButton>
                                        ),
                                    },
                                },
                            ],
                        },
                    }}
                />
            </MainWrapper>
            {dueDateCalendarParams.isOpen && dueDateCalendarParams?.data && (
                <DueDateCalendar
                    data={dueDateCalendarParams?.data}
                    onCancel={onCloseDueDateCalendar}
                    onApply={updateDueDate}
                />
            )}
        </>
    );
};

export const ProgressDetailsContainer: FC = () => {
    const state = useEnrollmentState();
    const profile = useSelector(getProfileState);
    const practisSetsState = useTrainerPractisSetState();
    const history = useHistory();
    const params: { teamId: string; practisSetId: string } = useParams();
    const company = useSelector(getCompanyState);
    const searchPractisSetDetailsUsers = useSearchPractisSetDetailsUsersService(
        params.teamId,
        params.practisSetId
    );
    const searchPractisSetDetailsUsersDebounced = useSearchDebounced(
        searchPractisSetDetailsUsers
    );
    const resendInvitation = useRemindToStartService();
    const getPractisSet = useGetPractisSetService();
    const resetPractisSetDetails = useResetPractisSetDetailsService();
    const labels = useLabelsState();
    const checkAllProgress = useCheckAllProgressService();
    const checkSingleProgress = useCheckSingleProgressService();
    const nudgeUsers = useNudgeUsersService();
    const unenrollEnrollment = useUnenrollEnrollmentService();
    const getTraineesReportService = useGetTraineesReportService();
    const actionsHelper = useProgressActionsHelper();

    const selectedProgresses = useMemo(
        () => selectSelectedProgresses(state, practisSetsState.checked),
        [practisSetsState.checked, state]
    );
    const searchState = useSelector(getSearchState);

    const locationState: any = history.location.state;

    useEffect(() => {
        return () => {
            resetPractisSetDetails();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ProgressDetails
            history={history}
            profile={profile}
            params={params}
            company={company}
            searchPractisSetDetailsUsers={searchPractisSetDetailsUsersDebounced}
            practisSetsEnrollments={state.data}
            updateTrainees={state.update}
            loading={state.loading}
            practisSet={state.practisSet}
            onResendInvitation={resendInvitation}
            onGetPractisSet={getPractisSet}
            selectedLabels={labels.selected}
            checkAllProgress={checkAllProgress}
            checkSingleProgress={checkSingleProgress}
            selectedProgressIds={practisSetsState.checked}
            selectedProgresses={selectedProgresses}
            actionsHelper={actionsHelper}
            onNudgeUsers={nudgeUsers}
            onUnenrollEnrollment={unenrollEnrollment}
            getTraineesReportService={getTraineesReportService}
            locationState={locationState}
            searchState={searchState}
        />
    );
};

export default ProgressDetailsContainer;