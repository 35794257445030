import { useCallback } from 'react';

import { useHistory } from '../../../tools/router';
import { useParams } from 'react-router';
import ROUTES from '../../../routes/routes';
import { useLoginByPhoneCode } from '../store/services';
import { UrlBuilder, UrlBuilderContext } from '../../../tools/url/UrlBuilder';
import {
    redirectAfterLogin,
    setAuthToken,
} from '../../../helpers/functions/auth-helpers';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import { useGetPhoneCodeApi } from '../../../api';
import CheckVerificationCode from '../AddMobileNumber/components/CheckVerificationCode';
import { UserV2 } from '../../../constants/interfaces/User';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

function VerifyMobileNumberController() {
    const params: { phoneNumber?: any } = useParams();
    const phoneNumber = params.phoneNumber ?? '';
    const history = useHistory();
    const loginByPhoneCode = useLoginByPhoneCode();
    const getPhoneCode = useGetPhoneCodeApi();

    useHtmlPageTitle('Verify Mobile Number');

    /**
     * @function handleVerify
     * @description calls API to verify entered code
     * @param { string } code
     * @returns { Promise }
     */
    const handleVerify = useCallback(
        (code: string) => {
            return loginByPhoneCode({ phone: phoneNumber, code });
        },
        [phoneNumber, loginByPhoneCode]
    );

    /**
     * @function handleVerifySuccess
     * @description calls API to verify entered code
     * @param { UrlBuilder } urlBuilder
     * @param { { user: UserV2, token: string } } response
     * @returns { void }
     */
    const handleVerifySuccess = useCallback(
        (urlBuilder: UrlBuilder, response: { user: UserV2; token: string }) => {
            setAuthToken(response.user, response.token);
            redirectAfterLogin(
                history,
                urlBuilder,
                response.user,
                response.token
            );
        },
        [history]
    );

    /**
     * @function handleResend
     * @description calls API to resend a verification code
     * @returns { Promise }
     */
    const handleResend = useCallback(() => {
        return getPhoneCode(phoneNumber);
    }, [getPhoneCode, phoneNumber]);

    /**
     * @function redirectToMobileNumberLogin
     * @description redirects to mobile number login
     * @returns { void }
     */
    const redirectToMobileNumberLogin = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.MOBILE_NUMBER_LOGIN);
    }, [history]);

    return (
        <UrlBuilderContext.Consumer>
            {urlBuilder => (
                <LoginAuthWrapper contentWidth={343} heightToShowScroll={500}>
                    <CheckVerificationCode
                        mobileNumber={phoneNumber}
                        onBack={redirectToMobileNumberLogin}
                        onVerify={handleVerify}
                        onVerifySuccess={response =>
                            handleVerifySuccess(
                                urlBuilder,
                                response as { user: UserV2; token: string }
                            )
                        }
                        onResend={handleResend}
                    />
                </LoginAuthWrapper>
            )}
        </UrlBuilderContext.Consumer>
    );
}

export default VerifyMobileNumberController;
