import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../ui/components/Button';
import { Fieldset } from '../../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import { PortableTeamsWithStore } from '../../../portableTeams';
import { useDeselectAllTeams, useSaveTeams } from '../../../portableTeams/store/hors/withTeams/services';
import { useTeamsState } from '../../../portableTeams/store/hors/withTeams/states';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import PortableRolesWithMultiSelect from '../../../portableRoles/portableRolesWithMultiSelect';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const TeamsContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const RoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
    background-color: inherit;
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

export const UsersFilter: FC<{
    preSelectedLabels: number[];
    onLabelsChange: (ids: number[]) => void;
    onTeamsChange: (ids: number[]) => void;
    onRoleChange: (status: number[]) => void;
    onSuccessApply: () => void;
    preSelectedRoles: number[];
}> = ({
    preSelectedLabels,
    onLabelsChange,
    onTeamsChange,
    onRoleChange,
    onSuccessApply,
    preSelectedRoles,
}) => {
    const [selectedRoles, setSelectedRoles] = useState(preSelectedRoles);
    const teams = useTeamsState();
    const labels = useLabelsState();
    const selectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const saveTeams = useSaveTeams();
    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labels.selected);
        onTeamsChange(teams.selected);
        onRoleChange(selectedRoles);
        saveLabels();
        saveTeams();
        onSuccessApply();
    }, [
        onLabelsChange,
        labels.selected,
        onTeamsChange,
        teams.selected,
        onRoleChange,
        selectedRoles,
        saveLabels,
        saveTeams,
        onSuccessApply,
    ]);

    const totalFiltersSelected =
        teams.selected.length +
        labels.selected.filter((id: any) => id > 0).length +
        selectedRoles.length;

    const deselectLabels = useDeselectAllLabels();
    const deselectTeams = useDeselectAllTeams();

    const onClearHandler = () => {
        setSelectedRoles([]);
        deselectLabels();
        deselectTeams();
    }

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Role'} dataTest="roles-section-title">
                    <RoleContainer>
                        <PortableRolesWithMultiSelect
                            selectedRoles={selectedRoles}
                            setSelectedRoles={setSelectedRoles}
                        />
                    </RoleContainer>
                </Fieldset>
                <Fieldset title={'Teams'} dataTest="teams-section-title">
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant='inverse'
                        width={'122px'}
                        action={() => onClearHandler()}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={() => onApplyHandler()}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
