import { History } from 'history';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { isEmpty as lodashIsEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { isTeamLeader } from '../../../../constants/enums';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import {
    PaginationResult,
    ListResult
} from '../../../../constants/interfaces/PaginationResult';
import { Team, TeamV2 } from '../../../../constants/interfaces/Team';
import {
    useDeleteLabelFromTeamService,
    useDeselectAllTeamLeadersSelection,
    useSaveTeamLeaderSelection,
    useSearchTeamsLeadersService,
    useSearchTeamsService,
    useUpdateAllSelectedTeamsStateService,
    useUpdateSelectedTeamStateService,
} from '../../store/services';
import {
    TeamLeadersState,
    useTeamEditState,
    useTeamsState,
    useUpdateTeamsState,
} from '../../store/states';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import {
    UpdatedLabelsState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import {
    useAssignLabelsToTeamsApi,
    useDeleteLabelsFromTeamsApi,
} from '../../../../api';
import { clearSelectedItemIfNotLoaded } from '../../../labels/tools';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { findAllChildIds } from '../../../../helpers/functions/tree-helpers';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { usePermissionsState } from '../../../permissions/store/state';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import {
    UserProfile,
    LeaderDataType,
} from '../../../../constants/interfaces/User';
import { pushModal } from '../../../../tools/router';
import ROUTES from '../../../../routes/routes';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { Popup } from '../../../../ui/components/Popup';
import { MyTeamsFilters } from './components/Filters/MyTeamsFilters';
import { WithLabelsContext } from '../../../portableLabels';
import { WithTeamsContext } from '../../../portableTeams';
import { countAppliedFiltersCount } from './tools';
import NoTrainingsFiltering from '../../../../ui/icons/NoTrainingsFiltering';
import NoTeams from '../../../../ui/icons/NoTeams';
import { useTeamsState as usePortableTeamsState } from '../../../portableTeams/store/hors/withTeams/states';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import ListActions from './components/ListActions/ListActions';
import { useSessionEffect } from '../../../../features/common';
import {
    useDeselectAllTeams,
    useSaveTeams,
} from '../../../portableTeams/store/hors/withTeams/services';
import { useSearchTeamsService as useSearchTeamsPortableService } from '../../../portableTeams/store/hors/withTeams/services';
import { getSecondarySearchState } from '../../../../features/searchState/store/reducer';
import { SEARCH_STATE } from '../../../../features/searchState/constants';
import { isEmpty } from '../../../../helpers/functions/object-helpers';
import { SecondarySearchStateInterface } from '../../../../features/searchState/store/states';
import { Avatar, FlexWrapper, useStyles } from './styles';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import TableTitleOverflowText from '../../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { Label } from '../../../../constants/interfaces/Label';
import {
    useDuplicateTeamBulActionService,
    useUpdateTeamsBulkActionService,
    useDeleteTeamsService,
} from '../../services/TeamBulkActionsService';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { useDeleteTeamsService as useSingleDeleteTeamService } from '../../../../features/teams/store/services';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import {
    onListenEvent,
    onRemoveEvent,
} from '../../../../helpers/functions/Events';
import {
    EventNameList,
    REFRESH_TEAMS_LIST,
} from '../../../../helpers/functions/Events/types';
import { useUpdateLabelsBulkActionService } from '../../../../services/GeneralBulkActionServices';
import { UPDATE_TEAM_ACTION } from '../../services/TeamBulkActionsService/constants';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import { TeamsLearnersNavigation } from '../../components/TeamsPageNavigation';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const ListOfTeams: FC<{
    history: History<any>;
    searchTeams: (sp: SearchParams) => void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    deleteLabelFromTeam: (
        data: { teamId: number; labelId: number }[],
        teamId: number
    ) => void;
    setAssignLabelsAction(labels: number[]): void;
    profile?: UserProfile;
    company?: CompanyInterface;
    teams?: ListResult<Team>;
    teamLeaders?: TeamLeadersState;
    updatedState?: Partial<TeamV2>;
    selectedTeams?: number[];
    updatedTeam?: Team;
    loading?: boolean;
    selectedLabels?: number[];
    updateAllSelectedTeams(
        teamIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedTeam(teamId: number): void;
    updatedLabels?: UpdatedLabelsState;
    clearAllSelected?: () => void;
    searchState: SecondarySearchStateInterface;
    allLabels?: PaginationResult<Label>;
}> = ({
    teams,
    teamLeaders,
    updatedState,
    history,
    searchTeams,
    saveGlobalLabelFilters,
    profile,
    company,
    updatedTeam,
    selectedLabels,
    selectedTeams,
    deleteLabelFromTeam,
    updateAllSelectedTeams,
    updateSelectedTeam,
    updatedLabels,
    loading,
    searchState,
    allLabels,
}) => {
    const classes = useStyles();
    const showMessage = useShowMessage();
    const teamMembers = usePortableTeamsState();
    const fetchTeamLeaders = useSearchTeamsLeadersService();
    const searchTeamsForFilters = useSearchTeamsPortableService();
    const deselectTeamLeaders = useDeselectAllTeamLeadersSelection();
    const saveTeamLeaders = useSaveTeamLeaderSelection();
    const deselectTeams = useDeselectAllTeams();
    const saveTeams = useSaveTeams();
    const canAccessPage = useHandleAccessPage();
    const singleTeamDelete = useSingleDeleteTeamService();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const permissions = usePermissionsState();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        teamIds: teamMembers.saved,
        teamLeadIds: teamLeaders ? teamLeaders.saved : [],
        labelIDs: selectedLabels,
        orderBy: null,
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<any>(false);

    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState.key === SEARCH_STATE.TEAMS.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        setCustom,
        refreshSearchParams,
    } = useSearchParamsState(usableParams);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countAppliedFiltersCount(searchParams),
        itemsCount: teams?.items.length || 0,
    });

    const ifSearchParamsChanged = useIfChanged(searchParams);
    const ifCompanyChanged = useIfChanged(company);
    const ifTeamsUpdateChanged = useIfChanged(updatedState);

    const teamsWithoutDefault = teams
        ? teams.items.filter((item: Team) => !item.isDefault)
        : [];

    const isCheckboxPartiallySelected =
        selectedTeams && selectedTeams.length < teamsWithoutDefault.length;

    const orderBy = searchParams.orderBy;
    const hcTeams = useOrderBy('name', orderBy, setOrderBy);
    const hcMembers = useOrderBy('members', orderBy, setOrderBy);
    const hcPractisSets = useOrderBy('pset', orderBy, setOrderBy);

    const clearAllTeamsCheck = useCallback(() => {
        updateAllSelectedTeams([], false, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    }, [isCheckboxPartiallySelected, updateAllSelectedTeams]);

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchTeams(searchParams);
        setLastRefreshed(new Date());
        clearAllTeamsCheck();
        setIsSelectAll(false);
    }, [searchParams, initialOffset, searchTeams, clearAllTeamsCheck]);

    const updateAllTeamsCheck = useCallback(
        (checked: boolean) => {
            const teamIds =
                (teams &&
                    teams.items
                        .filter((item: Team) => !item.isDefault)
                        .map((team: Team) => Number(team.id))) ||
                [];
            updateAllSelectedTeams(
                teamIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [teams, isCheckboxPartiallySelected, updateAllSelectedTeams]
    );

    /**
     * @function handleTeamCheck
     * @param { React.ChangeEvent }  event
     * @param { Team } team
     */
    const handleTeamCheck = (event: React.ChangeEvent, team: Team) => {
        event.stopPropagation();
        if (!!team.id) {
            updateSelectedTeam(team.id);
        }
    };

    /**
     * @function handleAssignedLabelDelete
     * @param { number } labelId
     * @param { number } teamId
     * @returns { void }
     */
    const handleAssignedLabelDelete = (labelId: number, teamId?: number) => {
        if (!!labelId && !!teamId) {
            const team = teams && teams.items.find(item => item.id === teamId);
            const labels = getTeamLabels(team?.labels || []);
            const teamRemovedLabels = [];

            if (!!team && labels) {
                const label = labels.find(label => label.id === labelId);
                if (!!label) {
                    const children: any[] = [];
                    findAllChildIds(labels, label.id, children);

                    if (!!children && !!children.length) {
                        children.forEach(item => {
                            teamRemovedLabels.push({
                                teamId: teamId,
                                labelId: item.id,
                            });
                        });
                    }

                    teamRemovedLabels.push({ teamId: teamId, labelId });
                }
            }
            deleteLabelFromTeam(teamRemovedLabels, teamId);
        }
    };

    /**
     * @function isOptionsDisabled
     * @returns { boolean }
     */
    const isOptionsDisabled =
        !selectedTeams || (selectedTeams && !selectedTeams.length);

    /**
     * @function handleToggleLabels
     * @param { number } itemId
     * @returns { void }
     */
    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    /**
     * @function handleManageTeam
     * @param { number } teamId
     * @param { boolean } isDefault
     * @returns { void }
     */
    const handleManageTeam = (teamId?: number, isDefaultTeam?: boolean) => {
        if (!teamId) return;
        pushModal(
            history,
            ROUTES.TEAMS_PAGES.MANAGE_TEAM.replace(
                ':teamId',
                teamId.toString()
            ),
            { isDefaultTeam }
        );
    };

    /**
     * @function getTeamLeaderPicture
     * @param { LeaderDataType[] } leaders
     * @returns { string | JSX.Element[] }
     */
    const getTeamLeaderPicture = useCallback(
        (leaders: LeaderDataType[], pictureCount: number) => {
            let result = leaders.slice(0, pictureCount).map(leader => {
                const profile = {
                    ...leader,
                    ...leader.user,
                    picture: leader.user.imageUrl,
                };

                return <Avatar profile={profile} size="sm" />;
            });

            return result;
        },
        []
    );

    /**
     * @function isNewlyAssigned
     * @param { LeaderDataType[] } leaders
     * @returns { boolean }
     */
    const isNewlyAssigned = useCallback(
        (leaders?: LeaderDataType[]) => {
            if (!leaders || (leaders && !leaders.length)) return false;

            const leader = leaders.find(
                item => Number(item.id) === Number(profile?.id)
            );

            return (
                leader && isTeamLeader(profile?.role?.name) && !leader.isViewed
            );
        },
        [profile]
    );

    /**
     * @function handleViewTeam
     * @param {number} id
     * @returns {void}
     */
    const handleViewTeam = (id?: number) => {
        if (!id) return;

        if (permissions.includes(NEW_PERMISSIONS.VIEW_TEAM)) {
            history.push(
                ROUTES.TEAMS_PAGES.TRAINING.replace(':teamId', id.toString()),
                {
                    useGoBack: true,
                }
            );
        }
    };

    /**
     * @function isListEmpty
     * @returns { boolean }
     */
    const isListEmpty = !teams || (teams && !teams.items.length);

    /**
     * @function labelChangeHandler
     * @param { number[]} ids
     * @returns { void }
     */
    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    /**
     * @function teamsChangeHandler
     * @param { number[] } ids
     * @returns { void }
     */
    const teamsChangeHandler = (ids: number[]) => {
        setCustom('teamIds', ids);
    };

    /**
     * @function teamsLeadersChangeHandler
     * @param { number[] } ids
     * @returns { void }
     */
    const teamsLeadersChangeHandler = (ids: number[]) => {
        setCustom('teamLeadIds', ids);
    };

    /**
     * @function hasAllMembers
     * @return { boolean }
     */
    const hasAllMembers = teams && teams?.items?.some(item => item.isDefault);

    useSessionEffect(() => {
        fetchTeamLeaders();
    }, [fetchTeamLeaders, updatedTeam, ifTeamsUpdateChanged]);

    useEffect(() => {
        if (ifSearchParamsChanged || ifCompanyChanged || ifTeamsUpdateChanged) {
            searchParams.limit = ITEMS_PER_PAGE;
            searchParams.offset = initialOffset;
            searchTeams(searchParams);
        }
    }, [
        searchTeams,
        searchParams,
        ifCompanyChanged,
        ifSearchParamsChanged,
        ifTeamsUpdateChanged,
        initialOffset,
    ]);

    useEffect(() => {
        if (updatedTeam) refreshSearchParams();
    }, [updatedTeam, refreshSearchParams]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        const filteredTeams = teams
            ? teams.items.map(team => Number(team.id))
            : [];
        const teamsToClear = clearSelectedItemIfNotLoaded(
            selectedTeams,
            filteredTeams
        );
        if (teamsToClear && teamsToClear.length > 0) {
            updateAllSelectedTeams(teamsToClear, false);
        }
    }, [selectedTeams, teams, updateAllSelectedTeams]);

    const deleteTeamsServiceOptions = {
        parameters: {
            searchParams: {
                ...searchParams,
                limit: ITEMS_PER_PAGE,
                offset:
                    (isSelectAll ? teams?.count : selectedTeams?.length) ?? 0,
            },
            selectedItemIds: !isSelectAll ? selectedTeams : undefined,
            itemsCount:
                (!isSelectAll ? selectedTeams?.length : teams?.count) ?? 0,
        },
        fieldName: !isSelectAll ? 'selectedItemIds' : 'searchParams.offset',
    };

    const deleteTeams = useDeleteTeamsService();

    /**
     * @function onDeleteTeamsSuccessCallback
     * @returns { void }
     */
    const onDeleteTeamsSuccessCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setIsSelectAll(false);
            refreshData();
            setTimeout(searchTeamsForFilters, 500);
            showMessage('Teams have been deleted.', 'success');
        });
    }, [refreshData, searchTeamsForFilters, showMessage]);

    const {
        handleStartBulkActionService:
            handleStartDeleteMembersFromTeamBulkAction,
    } = useUpdateTeamsBulkActionService(
        {
            actionName: UPDATE_TEAM_ACTION,
            actionFunction: deleteTeams,
            actionFunctionOptions: deleteTeamsServiceOptions,
        },
        'Removing Teams',
        onDeleteTeamsSuccessCallback
    );

    /**
     * @function handleRemoveSingleTeam
     * @param { number | undefined } singleTeamId
     * @returns { void }
     */
    const handleRemoveSingleTeam = useCallback(
        (singleTeamId?: number) => {
            const deletedTeamId =
                singleTeamId ||
                (selectedTeams?.length === 1 && selectedTeams[0]);

            deletedTeamId && singleTeamDelete(deletedTeamId);
        },
        [selectedTeams, singleTeamDelete]
    );

    /**
     * @function handleConfirmDeleteDialog
     * @param { number | undefined } singleTeamId
     * @returns { void }
     */
    const handleConfirmDeleteDialog = useCallback(
        (singleTeamId?: number) => {
            if (isSelectAll || (selectedTeams && selectedTeams.length > 1)) {
                handleStartDeleteMembersFromTeamBulkAction();
            } else {
                handleRemoveSingleTeam(singleTeamId);
            }
        },
        [
            handleRemoveSingleTeam,
            handleStartDeleteMembersFromTeamBulkAction,
            isSelectAll,
            selectedTeams,
        ]
    );

    /**
     * @function handleOpenDeleteTeamConfirmDialog
     * @param { number | undefined } teamId
     * @returns { void }
     */
    const handleOpenDeleteTeamConfirmDialog = useCallback(
        (teamId?: number): void => {
            showConfirmationModalDialog({
                modalTitle: 'Warning',
                description:
                    'You will delete the selected team(s) from the system. This action cannot be undone. Are you sure?',
                cancelButtonText: 'Go Back',
                confirmButtonText: 'Proceed',
                dialogType: 'DANGER',
                onConfirm: () => handleConfirmDeleteDialog(teamId),
            });
        },
        [handleConfirmDeleteDialog, showConfirmationModalDialog]
    );

    /**
     * @function handleRemoveSelectedTeams
     * @returns { void }
     */
    const handleRemoveSelectedTeams = useCallback(() => {
        // all the teams are selected
        // and bulk action has it's own confirmation.
        if (isSelectAll) {
            handleStartDeleteMembersFromTeamBulkAction();
        } else {
            handleOpenDeleteTeamConfirmDialog();
        }
    }, [
        handleOpenDeleteTeamConfirmDialog,
        handleStartDeleteMembersFromTeamBulkAction,
        isSelectAll,
    ]);

    useEffect(() => {
        return () =>
            updateAllSelectedTeams([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedTeams([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    useEffect(() => {
        if (!searchState.key) {
            setCustom('teamIds', teamMembers.saved);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamMembers.saved, setCustom]);

    useEffect(() => {
        if (teamLeaders?.saved && !searchState.key) {
            setCustom('teamLeadIds', teamLeaders.saved);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamLeaders, setCustom]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_TEAM]);
    }, [canAccessPage]);

    useEffect(() => {
        return () => {
            deselectTeamLeaders();
            saveTeamLeaders();
            deselectTeams();
            saveTeams();
        };
    }, [deselectTeamLeaders, saveTeamLeaders, deselectTeams, saveTeams]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (offset: number) => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        clearAllTeamsCheck();
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        updateAllTeamsCheck(true);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        const TotalCountForChecking = hasAllMembers
            ? ITEMS_PER_PAGE
            : ITEMS_PER_PAGE - 1;
        const count = teams?.count || 0;
        if (count < TotalCountForChecking) {
            setIsSelectAll(true);
        } else {
            setIsSelectAll(false);
        }

        updateAllTeamsCheck(event.target.checked);
    };

    const isMasterCheckBoxChecked = !!selectedTeams && !!selectedTeams.length;

    const isMasterCheckBoxDisabled =
        !teams ||
        (teams && teams.items.length < 1) ||
        (teams && teams.count === (hasAllMembers ? 1 : 0));

    /**
     * @function onRowClick
     * @param { Team } team
     * @returns { void }
     */
    const onRowClick = (team: Team) => {
        handleViewTeam(team.id);
    };

    /**
     * @function checkIsRowChecked
     * @param { Team } team
     * @returns { boolean }
     */
    const checkIsRowChecked = (team: Team): boolean =>
        !!selectedTeams && !!team.id && selectedTeams.includes(team.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { Team } team
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, team: Team) => {
        handleTeamCheck(event, team);
        setIsSelectAll(false);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { Team } team
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (team: Team): boolean => {
        return labelsOpen === team?.['id'];
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { Team } team
     * @returns { void }
     */
    const onDeleteLabelHandler = (labelId: number, team: Team): void => {
        handleAssignedLabelDelete(labelId, team.id);
    };

    const getTeamLabels = (labelIds: number[]) => {
        return (
            allLabels?.items?.filter(label => labelIds.includes(label.id)) || []
        );
    };

    const assignLabelsToTeam = useAssignLabelsToTeamsApi();
    const deleteLabelsFromTeam = useDeleteLabelsFromTeamsApi();

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToTeam,
        deleteLabelsFromTeam,
        refreshData
    );

    /**
     * @function handleUpdateTeamLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { number } teamId
     * @returns { void }
     */
    const handleUpdateTeamLabels = (
        addedLabelIds: number[],
        deletedLabelIds: number[],
        teamId: number
    ): void => {
        updateLabelsBulkActionService(
            {
                entityName: 'team',
                entityId: teamId,
            },
            addedLabelIds,
            deletedLabelIds
        );
    };

    const duplicateTeamBulkActionService = useDuplicateTeamBulActionService(
        refreshData,
        refreshData
    );

    /**
     * @function handleDuplicateTeam
     * @param { Team } team
     * @returns { void }
     */
    const handleDuplicateTeam = (team: Team) => {
        if (team) {
            duplicateTeamBulkActionService(team);
        }
    };

    // Listen to ManageTeam Screen Refresh dispatch
    useEffect(() => {
        onListenEvent(EventNameList[REFRESH_TEAMS_LIST], () => {
            refreshData();
            fetchTeamLeaders();
        });

        return () => {
            onRemoveEvent(EventNameList[REFRESH_TEAMS_LIST], () => {
                refreshData();
                fetchTeamLeaders();
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainWrapper
            subTitle="Teams"
            hideDivider={!!selectedTeams?.length}
            tabs={<TeamsLearnersNavigation />}
            htmlPageTitle='Teams - Practis'
            dataTest="teams"
        >
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={teams?.items}
                selectedLabels={selectedLabels}
                cornered={selectedLabels && !!selectedLabels.length}
                shouldHideTableDivider={!!selectedTeams?.length}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'teams-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: teams?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'teams-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled:
                            countAppliedFiltersCount(searchParams) > 0 &&
                            isListEmpty &&
                            searchParams.searchTerm.length === 0,
                    },
                    isSelectedItemOptionsVisible:
                        !lodashIsEmpty(selectedTeams) && !lodashIsEmpty(teams),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedTeams?.length,
                        totalCount: teams?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled:
                                isOptionsDisabled ||
                                !permissions.includes(
                                    NEW_PERMISSIONS.DELETE_TEAM
                                ),
                            disabledLabel: "Bulk actions can't be applied",
                            removeItemPermissions: [
                                NEW_PERMISSIONS.DELETE_TEAM,
                            ],
                            removeItemTitle: 'Delete Teams',
                            onRemoveItemsSubmit: () =>
                                selectedTeams && handleRemoveSelectedTeams(),
                            isSelectAll: isSelectAll,
                            totalCount: teams?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <MyTeamsFilters
                                        preSelectedLabels={selectedLabels || []}
                                        onLabelsChange={labelChangeHandler}
                                        onTeamsChange={teamsChangeHandler}
                                        onTeamLeadersChange={
                                            teamsLeadersChangeHandler
                                        }
                                        onSuccessApply={() => hide()}
                                    />
                                )}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={countAppliedFiltersCount(
                                            searchParams
                                        )}
                                        disabled={
                                            (searchParams.searchTerm.length >
                                                0 ||
                                                countAppliedFiltersCount(
                                                    searchParams
                                                ) === 0) &&
                                            isListEmpty
                                        }
                                        dataTest="teams-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && teams?.items?.length === 0,
                    noEntriesOptions: {
                        icon: NoTeams,
                        text: 'No Trainings Yet',
                        dataTest: 'no-teams',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Teams',
                        dataTest: 'no-found-teams',
                    },
                    noFilterResultsOptions: {
                        icon: NoTrainingsFiltering,
                        width: '160px',
                        dataTest: 'no-filtered-teams',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial:
                            isCheckboxPartiallySelected ||
                            ((teams?.count ?? 0) > ITEMS_PER_PAGE &&
                                !isSelectAll),
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'teams-master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Teams',
                            width: 23,
                            ...(isListEmpty ? null : hcTeams),
                            disabled: isListEmpty,
                            className: classes.customTableCellStyle,
                            dataTest: 'teams-name-column',
                        },
                        {
                            title: 'Members',
                            width: 12,
                            ...(isListEmpty ? null : hcMembers),
                            disabled: isListEmpty,
                            dataTest: 'teams-members-column',
                        },
                        {
                            title: 'Practis Sets',
                            width: 12,
                            ...(isListEmpty ? null : hcPractisSets),
                            disabled: isListEmpty,
                            dataTest: 'teams-practis-sets-column',
                        },
                        {
                            title: 'Team Leaders',
                            width: 22,
                            disabled: isListEmpty,
                            dataTest: 'teams-team-leaders-column',
                        },
                        {
                            width: 9,
                        },
                        {
                            width: 3,
                        },
                    ],

                    rowConfig: {
                        onRowClick: onRowClick,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (team: Team) => ({
                            selectedLabels: team?.labels,
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_USER_LABEL,
                            ],
                            onDeleteLabel: (labelId: number) =>
                                onDeleteLabelHandler(labelId, team),
                        }),
                        getClassName: (team: Team) =>
                            team.isDefault ? 'is-default' : '',
                        shouldShowAllMembers: (team: Team) =>
                            team?.isDefault ?? false,
                        getDataTest: (team: Team) =>
                            team.isDefault ? 'all-members-item' : 'teams-item',

                        cells: [
                            {
                                fieldType: 'CUSTOM_FIELD',
                                getCustomFieldComponent: (team: Team) => (
                                    <TableTitleOverflowText
                                        isNew={isNewlyAssigned(team.leaders)}
                                        dataTest="teams-item-name"
                                    >
                                        {team.name !== null ? team.name : ''}
                                    </TableTitleOverflowText>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (team: Team) =>
                                    !team?.countMembers ||
                                    team?.countMembers === 0,
                                fieldProps: {
                                    renderTitle: (team: Team) =>
                                        team.countMembers !== null &&
                                        team.countMembers !== 0 &&
                                        team.countMembers,
                                    dataTest: 'teams-item-members',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (team: Team) =>
                                    !team?.countPractisSets ||
                                    team?.countPractisSets === 0,
                                fieldProps: {
                                    renderTitle: (team: Team) =>
                                        team.countPractisSets !== null &&
                                        team.countPractisSets !== 0 &&
                                        team.countPractisSets,
                                    dataTest: 'teams-item-practis-sets',
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                shouldShowEmptyCell: (team: Team) =>
                                    !team?.leaders || team.leaders.length === 0,
                                getCustomFieldComponent: (team: Team) => {
                                    const leaders: LeaderDataType[] =
                                        team.leaders!;
                                    const pictureCount: number = 3;
                                    return (
                                        <FlexWrapper data-test="teams-item-team-leaders">
                                            {getTeamLeaderPicture(
                                                leaders,
                                                pictureCount
                                            )}{' '}
                                            {leaders.length > pictureCount
                                                ? ` +${
                                                      leaders.length -
                                                      pictureCount
                                                  }`
                                                : ''}
                                        </FlexWrapper>
                                    );
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (team: Team) => ({
                                        open: labelsOpen === team.id,
                                        toggleOpen: () =>
                                            handleToggleLabels(team.id),
                                        selectedLabels: team.labels,
                                        isLabelLess: team.isDefault,
                                        dataTest: 'teams-item-labels',
                                    }),
                                    className: classes.labelsCellStyle,
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (team: Team) => (
                                        <WithLabelsContext.Provider
                                            value={{
                                                reducerName: 'teams',
                                                scope: 'assignLabels',
                                            }}
                                        >
                                            <ListActions
                                                team={team}
                                                handleViewTeam={handleViewTeam}
                                                handleManageTeam={
                                                    handleManageTeam
                                                }
                                                handleDeleteTeam={
                                                    handleOpenDeleteTeamConfirmDialog
                                                }
                                                onDuplicateTeam={
                                                    handleDuplicateTeam
                                                }
                                                assignLabelsToTeams={(
                                                    addedLabelIds,
                                                    deletedlabelIds
                                                ) =>
                                                    handleUpdateTeamLabels(
                                                        addedLabelIds,
                                                        deletedlabelIds,
                                                        team?.id ?? 0
                                                    )
                                                }
                                                isHidden={
                                                    !!selectedTeams?.length
                                                }
                                            />
                                        </WithLabelsContext.Provider>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const ListOfTeamsContainer: FC = () => {
    const state = useTeamsState();
    const updatedState = useTeamEditState().updatedTeam;
    const updatedTeamsState = useUpdateTeamsState();
    const history = useHistory();
    const searchTeams = useSearchTeamsService(true);
    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);
    const searchTeamsDebounced = useSearchDebounced(searchTeams);
    const portableLabels = usePortableLabelsState();
    const labels = useLabelsState();
    const deleteLabelFromTeam = useDeleteLabelFromTeamService();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const updateAllSelectedTeams = useUpdateAllSelectedTeamsStateService();
    const updateSelectedTeam = useUpdateSelectedTeamStateService();
    const updatedLabels = useUpdatedLabelsState();
    const teamSearchState = useSelector(getSecondarySearchState);

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'teams',
            }}
        >
            <WithTeamsContext.Provider
                value={{
                    reducerName: 'teams',
                }}
            >
                <ListOfTeams
                    history={history}
                    searchTeams={searchTeamsDebounced}
                    profile={profile}
                    teams={state.myTeams}
                    teamLeaders={state.teamLeaders}
                    updatedState={updatedState}
                    company={company}
                    selectedTeams={state.selectedMyTeamsItems}
                    loading={state.loading}
                    updatedTeam={updatedTeamsState.data}
                    selectedLabels={labels.selected}
                    setAssignLabelsAction={setAssignLabelsAction}
                    updatedLabels={updatedLabels}
                    saveGlobalLabelFilters={saveGlobalLabelFilters}
                    deleteLabelFromTeam={deleteLabelFromTeam}
                    updateAllSelectedTeams={updateAllSelectedTeams}
                    updateSelectedTeam={updateSelectedTeam}
                    searchState={teamSearchState}
                    allLabels={portableLabels.data}
                />
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};

export default ListOfTeamsContainer;