import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty as lodashIsEmpty, difference } from 'lodash';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    selectSelectedSubmissions,
    useSubmissionsState,
    useUpdatedSubmissionsState,
} from '../store/states';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { SubmissionV2 } from '../../../constants/interfaces/Reviews';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import {
    useArchiveSubmissionsService,
    useCheckAllSubmissionsService,
    useCheckSingleSubmissionService,
    useRestoreSubmissionsService,
    useSearchChallengeSubmissionsService,
} from '../store/services';
import { useSelector } from 'react-redux';
import { CHALLENGE_SUBMISSION_REVIEW_STATUS } from '../tools';
import { formatDate } from '../../../helpers/functions/date-convert';
import { useHistory } from 'react-router';
import { History } from 'history';
import ROUTES from '../../../routes/routes';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { Challenge } from '../../../constants/interfaces/Challenge';
import {
    UpdatedLabelsState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../../labels/store/states';
import { LoadingComponent } from '../../../ui/components/LoadingCopmonent';
import { ActionButton, ActionItem } from '../../../ui/components/ActionButton';
import { CheckPermission } from '../../permissions';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../helpers/hooks/usePagePermissions';
import { UserProfile } from '../../../constants/interfaces/User';
import { usePermissionsState } from '../../permissions/store/state';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { Popup } from '../../../ui/components/Popup';
import FilterByHandler from '../../../ui/components/Filters/FilterByHandler';
import { useSaveUserFiltersService } from '../../labels/store/services';
import { SubmissionFilters } from '../components/SubmissionFilters/SubmissionFilters';
import { Team } from '../../../constants/interfaces/Team';
import { useUpdateTeamsState } from '../../teams/store/states';
import { WithLabelsContext } from '../../portableLabels';
import { WithTeamsContext } from '../../portableTeams';
import Files from '../../../ui/icons/Files';
import {
    SubmissionActions,
    SubmissionActionsHelper,
    useSubmissionActionsHelper,
} from '../tools';
import { useSessionEffect } from '../../../features/common';
import SubmissionsNavigationContainer from '../components/SubmissionsNavigation';
import CheckMark from '../../../ui/icons/CheckMark';
import { getSearchState } from '../../../features/searchState/store/reducer';
import { SEARCH_STATE } from '../../../features/searchState/constants';
import { isEmpty } from '../../../helpers/functions/object-helpers';
import { SearchStateInterface } from '../../../features/searchState/store/states';
import { TableWrapper } from '../../../ui/components/table-wrapper';
import {
    Avatar,
    AvatarTile,
    FlexWrapper,
    StyledFirstTableContent,
    useStyles,
} from './styles';
import { useOrderBy } from '../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../ui/components/table-wrapper/helper';
import TableTitleOverflowText from '../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { ChallengeSubmissionFilters } from '../../../constants/interfaces/SubmissionSearchFilters';
import { useUpdateSubmissionStatusBulkActionService } from '../services/SubmissionsBulkActionsService';

const qs = require('query-string');

export const ITEMS_PER_PAGE = 20;
const DEFAULT_REVIEW_STATUSES = [
    CHALLENGE_SUBMISSION_REVIEW_STATUS.NOT_REVIEWED,
    CHALLENGE_SUBMISSION_REVIEW_STATUS.REVIEWED,
];

const ChallengeSubmissions: FC<{
    profile?: UserProfile;
    submissions?: ListResult<SubmissionV2>;
    challenges?: ListResult<Challenge>;
    practisSets?: ListResult<PractisSets>;
    searchSubmissions(
        searchParams: SearchParams,
        filters: ChallengeSubmissionFilters
    ): void;
    loading?: boolean;
    updatedSubmissions?: number[];
    history: History<any>;
    selectedLabels?: number[];
    onArchiveSubmission?(submissionIds: number[]): Promise<void>;
    onRestoreSubmission?(submissionIds: number[]): Promise<void>;
    selectedSubmissionIds?: number[];
    selectedSubmissions?: SubmissionV2[];
    actionsHelper: SubmissionActionsHelper;
    updateAllSelectedSubmissions(
        submissionIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedSubmission(submissionId: number): void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    updatedLabels?: UpdatedLabelsState;
    updatedTeams?: Team;
    searchState: SearchStateInterface;
}> = ({
    profile,
    submissions,
    searchSubmissions,
    loading,
    updatedSubmissions,
    history,
    selectedLabels,
    onArchiveSubmission,
    selectedSubmissionIds,
    selectedSubmissions,
    actionsHelper,
    updateAllSelectedSubmissions,
    updateSelectedSubmission,
    onRestoreSubmission,
    saveGlobalLabelFilters,
    searchState,
}) => {
    const initialSearchParams: SearchParams = {
        searchTerm: '',
        orderBy: {},
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState.params &&
        searchState.key === SEARCH_STATE.SUBMISSIONS.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        refreshSearchParams,
    } = useSearchParamsState(usableParams);

    const classes = useStyles();
    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [labelsOpen, setLabelsOpen] = useState<number | undefined>();

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(undefined);
        } else {
            setLabelsOpen(itemId);
        }
    };
    const [isSelectAll, setIsSelectAll] = useState<any>(false);
    const initialFilters = searchParams.customFilters as ChallengeSubmissionFilters ?? {
        reviewStatuses: DEFAULT_REVIEW_STATUSES,
        showArchived: false,
        teamIds: [],
        labelIds: selectedLabels ?? [],
    };
    const [filters, setFilters] = useState<ChallengeSubmissionFilters>(initialFilters);

    const canAccessPage = useHandleAccessPage();

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchSubmissions(searchParams, filters);
        setLastRefreshed(new Date());
        updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchSubmissions, searchParams, filters]);

    /**
     * @function handleViewSubmissionDetails
     * @param { Submission } submission
     * @returns { void }
     */
    const handleViewSubmissionDetails = (submission: SubmissionV2) => {
        if (!submission) return;
        history.push(
            ROUTES.SUBMISSION_SETTINGS.CHALLENGES.SINGLE.replace(
                ':submissionId',
                submission.id?.toString()
            ),
            {
                useGoBack: true,
                submissionId: submission.id,
                isNew: !submission.relation.isViewed,
                searchParams,
                filters
            }
        );
    };

    const handleViewPractisSetReport = (
        userId: number,
        enrollmentId: number
    ) => {
            history.push(
                ROUTES.TRAINEE_DETAILS
                    .replace(
                        ':userId',
                        userId.toString()
                    )
                    .replace(
                        ':enrollmentId',
                        enrollmentId.toString()
                    ),
                { useGoBack: true }
            );
    };

    const permissions = usePermissionsState();

    const handleViewProfile = (userId: number) => {
        if (permissions.includes(NEW_PERMISSIONS.VIEW_USER_PERFORMANCE)) {
            history.push(
                ROUTES.USER_PERFORMANCE.replace(':userId', userId!.toString()),
                { useGoBack: true }
            );
        } else {
            return;
        }
    };

    const archiveSubmission = (submissionIds: number[]) => {
        if (!submissionIds || (!!submissionIds && !submissionIds.length))
            return;
        onArchiveSubmission && onArchiveSubmission(submissionIds);
    };

    const restoreSubmission = (submissionIds: number[]) => {
        if (!submissionIds || (!!submissionIds && !submissionIds.length))
            return;
        onRestoreSubmission && onRestoreSubmission(submissionIds);
    };

    const isCheckboxPartiallySelected =
        submissions &&
        submissions.items &&
        selectedSubmissionIds &&
        selectedSubmissionIds.length < submissions.items.length;

    const updateAllSubmissionsCheck = useCallback(
        (checked: boolean) => {
            const submissionIds =
                (submissions &&
                    submissions.items.map((submission: SubmissionV2) =>
                        Number(submission.id)
                    )) ||
                [];
            updateAllSelectedSubmissions(
                submissionIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [submissions, isCheckboxPartiallySelected, updateAllSelectedSubmissions]
    );

    const handleSubmissionCheck = (e: any, submission: SubmissionV2) => {
        e.stopPropagation();
        if (!!submission.id) {
            updateSelectedSubmission(submission.id);
        }
    };

    const updateSubmissionStatusBulkActionService =
        useUpdateSubmissionStatusBulkActionService(
            'CHALLENGE',
            searchParams,
            filters,
            submissions?.count ?? 0,
            refreshData
        );

    const archiveSubmissions = useCallback(() => {
        if (isSelectAll) {
            updateSubmissionStatusBulkActionService('Archive');
        } else {
            if (
                !selectedSubmissionIds ||
                (!!selectedSubmissionIds && !selectedSubmissionIds.length) ||
                !onArchiveSubmission
            ) {
                return;
            }

            onArchiveSubmission(selectedSubmissionIds).then((result: any) => {
                if (!!result) {
                    updateAllSelectedSubmissions(
                        selectedSubmissionIds,
                        false,
                        false
                    );
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSubmissionIds,
        onArchiveSubmission,
        updateAllSelectedSubmissions,
    ]);

    const restoreSubmissions = useCallback(() => {
        if (isSelectAll) {
            updateSubmissionStatusBulkActionService('Restore');
        } else {
            if (
                !selectedSubmissionIds ||
                (!!selectedSubmissionIds && !selectedSubmissionIds.length) ||
                !onRestoreSubmission
            ) {
                return;
            }

            onRestoreSubmission(selectedSubmissionIds).then((result: any) => {
                if (!!result) {
                    updateAllSelectedSubmissions(
                        selectedSubmissionIds,
                        false,
                        false
                    );
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSubmissionIds,
        onRestoreSubmission,
        updateAllSelectedSubmissions,
    ]);

    const handleApplyFilters = useCallback(
        (newFilters: ChallengeSubmissionFilters) => {
            saveGlobalLabelFilters(newFilters.labelIds);
            setFilters(newFilters);
        },
        [saveGlobalLabelFilters]
    );

    const orderBy = searchParams.orderBy;
    const sbFirstName = useOrderBy('user_name', orderBy, setOrderBy);
    const sbPractisSetName = useOrderBy('pset_name', orderBy, setOrderBy);
    const sbChallengeTitle = useOrderBy('challenge_title', orderBy, setOrderBy);
    const sbReviewedAt = useOrderBy('reviewed', orderBy, setOrderBy);
    const sbScore = useOrderBy('score', orderBy, setOrderBy);
    const sbSubmittedAt = useOrderBy('submission_date', orderBy, setOrderBy);

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const appliedFiltersCount =
        filters.labelIds.length +
        filters.teamIds.length +
        (filters.showArchived ? 1 : 0) +
        (!lodashIsEmpty(filters.reviewStatuses) 
            ? 1
            : 0);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: appliedFiltersCount,
        itemsCount: submissions?.items.length || 0,
        filtersAreAlwaysEnabled: true,
    });

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedSubmissions),
        [selectedSubmissions, actionsHelper]
    );

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchSubmissions(searchParams, filters);
    }, [searchSubmissions, searchParams, filters]);

    useEffect(() => {
        if (updatedSubmissions) refreshSearchParams();
    }, [updatedSubmissions, refreshSearchParams]);

    useEffect(() => {
        if (
            selectedLabels &&
            (selectedLabels.length !== filters.labelIds.length ||
                difference(selectedLabels, filters.labelIds).length > 0)
        ) {
            setFilters({
                ...filters,
                labelIds: selectedLabels,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLabels, setFilters]);

    useEffect(() => {
        if (
            selectedSubmissions?.length &&
            selectedSubmissions.length === submissions?.count
        ) {
            setIsSelectAll(true);
        }
    }, [submissions?.count, selectedSubmissions]);

    useEffect(() => {
        return () =>
            updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissions]);

    useEffect(() => {
        if (pageIndex > 1) {
            setOffset(initialOffset);
        } else if (pageIndex === 1) {
            setOffset(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_SUBMISSION]);
    }, [canAccessPage]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (offset: number) => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        updateAllSubmissionsCheck(true);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        setIsSelectAll(false);
        updateAllSubmissionsCheck(event.target.checked);
    };

    const isMasterCheckBoxChecked =
        !!selectedSubmissionIds && !!selectedSubmissionIds.length;

    const isMasterCheckBoxDisabled =
        !submissions || (submissions && submissions.items.length < 1);

    /**
     * @functiononRowClick
     * @param { AccuracyScore } submission
     * @returns { void }
     */
    const onRowClick = (submission: SubmissionV2) => {
        handleViewSubmissionDetails(submission);
    };

    /**
     * @function checkIsRowChecked
     * @param { Submission } submission
     * @returns { boolean }
     */
    const checkIsRowChecked = (submission: SubmissionV2): boolean =>
        !!selectedSubmissionIds &&
        !!submission.id &&
        selectedSubmissionIds.includes(submission.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { Submission } submission
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, submission: SubmissionV2) => {
        setIsSelectAll(false);
        handleSubmissionCheck(event, submission);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { Submission } accuracyScore
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (submission: SubmissionV2): boolean => {
        return labelsOpen === submission.id;
    };

    return (
        <MainWrapper
            subTitle="Feed"
            tabs={<SubmissionsNavigationContainer dataTest="challenges-nav" />}
            hideDivider={!!selectedSubmissions?.length}
            htmlPageTitle="Feed - Practis"
            dataTest="challenges"
        >
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={submissions?.items}
                cornered={selectedLabels && !!selectedLabels.length}
                shouldHideTableDivider={!!selectedSubmissions?.length}
                selectedLabels={selectedLabels}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'challenges-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: submissions?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            tableStates.showNoFilterResultsState,
                        dataTest: 'challenges-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'challenges-search-input',
                    },
                    isSelectedItemOptionsVisible:
                        !lodashIsEmpty(selectedSubmissions) &&
                        !lodashIsEmpty(submissions),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedSubmissions?.length,
                        totalCount: submissions?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled: bulkActions.areBulkActionsDisabled(),
                            disabledLabel: bulkActions.areItemsEmpty()
                                ? undefined
                                : "Bulk actions can't be applied",
                            archivePermissions: bulkActions.isBulkActionVisible(
                                SubmissionActions.ARCHIVE
                            )
                                ? [NEW_PERMISSIONS.ARCHIVE_SUBMISSION]
                                : [],
                            isArchiveDisabled: bulkActions.isBulkActionDisabled(
                                SubmissionActions.ARCHIVE
                            ),
                            onArchiveSubmissionsSubmit: archiveSubmissions,
                            restorePermissions: bulkActions.isBulkActionVisible(
                                SubmissionActions.RESTORE
                            )
                                ? [NEW_PERMISSIONS.RESTORE_SUBMISSION]
                                : [],
                            isRestoreDisabled: bulkActions.isBulkActionDisabled(
                                SubmissionActions.RESTORE
                            ),
                            onRestoreSubmissionsSubmit: restoreSubmissions,
                            isSelectAll: isSelectAll,
                            totalCount: submissions?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <SubmissionFilters
                                        filters={filters}
                                        onSuccessApply={newFilters => {
                                            handleApplyFilters(newFilters);
                                            hide();
                                        }}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={
                                            submissions
                                                ? appliedFiltersCount
                                                : 0
                                        }
                                        disabled={tableStates.disableFilters}
                                        dataTest="challenges-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && submissions?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Files,
                        text: (
                            <>
                                {' '}
                                No Challenge
                                <br />
                                Submissions Yet
                            </>
                        ),
                        dataTest: 'challenges-no-entries',
                    },
                    noSearchResultsOptions: {
                        entityName: (
                            <>
                                Challenge
                                <br />
                                Submissions
                            </>
                        ),
                        dataTest: 'challenges-no-search-results',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'challenges-no-filter-results',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'challenges-master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Users',
                            width: 20,
                            ...sbFirstName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'challenges-users-column',
                        },
                        {
                            title: 'Challenge',
                            width: 17,
                            ...sbChallengeTitle,
                            disabled: tableStates.disableSorting,
                            dataTest: 'challenge-column',
                        },
                        {
                            title: 'Review Status',
                            width: 12,
                            ...sbReviewedAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'challenges-review-status-column',
                        },
                        {
                            title: 'Score',
                            width: 12,
                            ...sbScore,
                            disabled: tableStates.disableSorting,
                            dataTest: 'challenges-score-column',
                        },
                        {
                            title: 'Submitted',
                            width: 12,
                            ...sbSubmittedAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'challenges-submitted-column',
                        },
                        {
                            title: 'Practis Set',
                            width: 17,
                            ...sbPractisSetName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'challenges-practis-set-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: onRowClick,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (submission: SubmissionV2) => ({
                            selectedLabels: submission.userLabels || [],
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_CHALLENGE_LABEL,
                            ],
                        }),
                        dataTest: 'challenges-item',

                        cells: [
                            {
                                fieldType: 'CUSTOM_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                getCustomFieldComponent: (
                                    submission: SubmissionV2
                                ) => (
                                    <StyledFirstTableContent>
                                        <AvatarTile>
                                            <Avatar
                                                profile={submission.user}
                                                size="sm"
                                                dataTest="challenges-item-user-avatar"
                                            />
                                            <TableTitleOverflowText
                                                isCurrent={
                                                    submission.user &&
                                                    profile &&
                                                    submission.user.id ===
                                                        profile.id
                                                }
                                                isNew={
                                                    !submission.relation
                                                        .isViewed
                                                }
                                                dataTest="challenges-item-user-full-name"
                                            >
                                                {submission.user &&
                                                    submission.user.firstName +
                                                        ' ' +
                                                        submission.user
                                                            .lastName}
                                            </TableTitleOverflowText>
                                        </AvatarTile>
                                    </StyledFirstTableContent>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    submission: SubmissionV2
                                ) => !submission.challenge?.title,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (submission: SubmissionV2) =>
                                        submission.challenge?.title,
                                    dataTest: 'challenges-item-challenge',
                                },
                            },
                            {
                                fieldType: 'BADGE_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    submission: SubmissionV2
                                ) => !submission.reviewedAt,
                                fieldProps: {
                                    getBadgeFieldProps: (
                                        submission: SubmissionV2
                                    ) => ({
                                        status: 'ACTIVE',
                                        className: classes.customTableCellStyle,
                                    }),

                                    renderTitle: (submission: SubmissionV2) => (
                                        <FlexWrapper data-test="challenges-item-review-status">
                                            <CheckMark />
                                            {formatDate(submission.reviewedAt)}
                                        </FlexWrapper>
                                    ),
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    submission: SubmissionV2
                                ) => !submission.score,
                                fieldProps: {
                                    renderTitle: (submission: SubmissionV2) =>
                                        submission.score,
                                    dataTest: 'challenges-item-score',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    submission: SubmissionV2
                                ) => !submission.createdAt,
                                fieldProps: {
                                    renderTitle: (submission: SubmissionV2) =>
                                        formatDate(submission.createdAt),
                                    dataTest: 'challenges-item-submission-date',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    submission: SubmissionV2
                                ) => !submission.practisSet?.name,
                                fieldProps: {
                                    isOverFlowText: true,
                                    className: classes.minWidthTableCell,
                                    renderTitle: (submission: SubmissionV2) =>
                                        submission.practisSet?.name,
                                    dataTest: 'challenges-item-practis-set',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (
                                        submission: SubmissionV2
                                    ) => ({
                                        open: labelsOpen === submission.id,
                                        toggleOpen: () =>
                                            handleToggleLabels(submission.id),
                                        selectedLabels:
                                            submission.userLabels || [],
                                        dataTest: 'challenges-item-labels',
                                    }),
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (
                                        submission: SubmissionV2
                                    ) => (
                                        <ActionButton
                                            customWidth={224}
                                            isHidden={
                                                !!selectedSubmissions?.length
                                            }
                                            dataTest="challenges-item-menu"
                                        >
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_SUBMISSION,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewSubmissionDetails(
                                                            submission
                                                        );
                                                    }}
                                                    dataTest="view-submission-action"
                                                >
                                                    View Submission
                                                </ActionItem>
                                            </CheckPermission>

                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_USER_PRACTIS_SET_REPORT,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewPractisSetReport(
                                                            submission.user.id,
                                                            submission.enrollmentId
                                                        );
                                                    }}
                                                    dataTest="view-practis-set-report-action"
                                                >
                                                    View Practis Set Report
                                                </ActionItem>
                                            </CheckPermission>

                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_USER_PERFORMANCE,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewProfile(
                                                            submission.user.id
                                                        );
                                                    }}
                                                    dataTest="view-profile-action"
                                                >
                                                    View Profile
                                                </ActionItem>
                                            </CheckPermission>

                                            {actionsHelper.canRestore(
                                                submission
                                            ) && (
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        restoreSubmission([
                                                            submission.id,
                                                        ]);
                                                    }}
                                                    dataTest="restore-action"
                                                >
                                                    Restore
                                                </ActionItem>
                                            )}
                                            {actionsHelper.canArchive(
                                                submission
                                            ) && (
                                                <ActionItem
                                                    destructive={true}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        archiveSubmission([
                                                            submission.id,
                                                        ]);
                                                    }}
                                                    dataTest="archive-action"
                                                >
                                                    Archive
                                                </ActionItem>
                                            )}
                                        </ActionButton>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

const ChallengeSubmissionsContainer = () => {
    const history = useHistory();
    const profile = useSelector(getProfileState);
    const submissions = useSubmissionsState();
    const loading = useSubmissionsState().loading;
    const updatedSubmissions = useUpdatedSubmissionsState();

    const searchSubmissions = useSearchChallengeSubmissionsService();
    const archiveSubmissions = useArchiveSubmissionsService(false);
    const restoreSubmissions = useRestoreSubmissionsService(false);
    const updateAllSelectedSubmissions = useCheckAllSubmissionsService();
    const updateSelectedSubmission = useCheckSingleSubmissionService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const updatedLabels = useUpdatedLabelsState();
    const updatedTeamsState = useUpdateTeamsState();

    const actionsHelper = useSubmissionActionsHelper();

    const labels = useLabelsState();

    const selectedSubmissions = useMemo(
        () => selectSelectedSubmissions(submissions),
        [submissions]
    );
    const searchState = useSelector(getSearchState);

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'submissions' }}>
            <WithTeamsContext.Provider value={{ reducerName: 'submissions' }}>
                <ChallengeSubmissions
                    profile={profile}
                    submissions={submissions.data}
                    challenges={submissions.submissionChallenges}
                    practisSets={submissions.submissionPractisSets}
                    selectedSubmissionIds={submissions.checked}
                    selectedSubmissions={selectedSubmissions}
                    actionsHelper={actionsHelper}
                    loading={loading || labels.saveLoading}
                    searchSubmissions={searchSubmissions}
                    updatedSubmissions={updatedSubmissions.data}
                    history={history}
                    selectedLabels={labels.selected}
                    onArchiveSubmission={archiveSubmissions}
                    onRestoreSubmission={restoreSubmissions}
                    updateAllSelectedSubmissions={updateAllSelectedSubmissions}
                    updateSelectedSubmission={updateSelectedSubmission}
                    saveGlobalLabelFilters={saveGlobalLabelFilters}
                    updatedLabels={updatedLabels}
                    updatedTeams={updatedTeamsState.data}
                    searchState={searchState}
                />
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};

export default ChallengeSubmissionsContainer;