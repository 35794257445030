import { ChangeEvent } from 'react'
import { Formik } from 'formik';
import { CompanyVoiceFormValues, CompanyVoiceViewProps } from './types';
import { DEFAULT_CUSTOMER_SAMPLE_TEXT, DEFAULT_REP_SAMPLE_TEXT, RoleItems } from './constants';
import { Button } from '../../../../ui/components/Button';
import Select from '../../../../ui/components/Select/Select';
import {
    Content,
    FieldContainer,
    FieldName,
    Footer,
    StyledForm,
} from './styles';
import { PartiaVoiceSettings } from './components/PartialVoiceSettings';
import { VoiceSettingsSkeleton } from './components/VoiceSettingsSkeleton';
import { VoiceSettingsRole } from '../../../../constants/interfaces/CompanyVoiceSettings';
import {
    DEFAULT_CUSTOMER_VOICE_SIMILARITY,
    DEFAULT_CUSTOMER_VOICE_STABILITY,
    DEFAULT_REP_VOICE_SIMILARITY,
    DEFAULT_REP_VOICE_STABILITY
} from '../../../../features/textToSpeech/constants';

function CompanyVoiceView({
    formRef,
    role,
    voices,
    initialFormValues,
    canChange,
    isLoading,
    onChangeRole,
    onSampleAction,
    onSubmit,
}: CompanyVoiceViewProps) {
    return (
        <Formik<CompanyVoiceFormValues>
            ref={formRef}
            initialValues={initialFormValues}
            onSubmit={onSubmit}
        >
            {({ values, dirty, isValid, isSubmitting, setFieldValue }) => {
                return (
                    <StyledForm>
                        <Content>
                            <FieldContainer>
                                <FieldName data-test="role-title">Role</FieldName>
                                <Select
                                    items={RoleItems}
                                    value={role}
                                    height={40}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeRole(e.target.value as VoiceSettingsRole)}
                                    className="company-select"
                                    disabled={!canChange || isSubmitting}
                                    dataTest="role-dropdown"
                                />
                            </FieldContainer>
                            {isLoading && <VoiceSettingsSkeleton />}
                            {!isLoading && (
                                <>
                                    <div style={{ display: role === 'customer' ? 'block' : 'none' }}>
                                        <PartiaVoiceSettings
                                            role={role}
                                            voices={voices}
                                            defaultSimilarity={DEFAULT_CUSTOMER_VOICE_SIMILARITY}
                                            defaultStability={DEFAULT_CUSTOMER_VOICE_STABILITY}
                                            initialSampleText={DEFAULT_CUSTOMER_SAMPLE_TEXT}
                                            values={values.customer}
                                            isDisabled={!canChange || isSubmitting}
                                            setFieldValue={setFieldValue}
                                            onSampleAction={onSampleAction}
                                        />
                                    </div>
                                    <div style={{ display: role === 'representative' ? 'block' : 'none' }}>
                                        <PartiaVoiceSettings
                                            role={role}
                                            voices={voices}
                                            defaultSimilarity={DEFAULT_REP_VOICE_SIMILARITY}
                                            defaultStability={DEFAULT_REP_VOICE_STABILITY}
                                            initialSampleText={DEFAULT_REP_SAMPLE_TEXT}
                                            values={values.representative}
                                            isDisabled={!canChange || isSubmitting}
                                            setFieldValue={setFieldValue}
                                            onSampleAction={onSampleAction}
                                        />
                                    </div>
                                </>
                            )}
                        </Content>
                        <Footer>
                            <Button
                                width="130px"
                                height="40px"
                                disabled={!dirty || !isValid || isSubmitting}
                                loadingColor="white"
                                loadingSize={24}
                                loading={isSubmitting}
                                dataTest="apply-button"
                            >
                                Apply
                            </Button>
                        </Footer>
                    </StyledForm>
                );
            }}
        </Formik>
    );
}

export default CompanyVoiceView;