import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { TeamBasic } from '../../../constants/interfaces/TeamBasic';
import { PortableTeamsList } from './PortableTeamsList';
import { Button } from '../../../ui/components/Button';
import { Variables } from '../../../theme/variables';
import EmptyTeams from './EmptyTeams';
import { sortBy } from 'lodash';
import { TableSearchInput } from '../../../ui/components/table-wrapper/table-tools/table-search-input';

//region Types
type Action = 'clear' | 'select';

export type PortableTeamsProps = {
    teams?: PaginationResult<TeamBasic>;
    selectedList: number[];
    onSave?: () => void;
    onSelectAll?: (processedTeams: number[]) => void;
    onDeselectAll?: () => void;
    onClearSelected?: () => void;
    onSetSelected?: (teamIds: number[]) => void;
    filtersTitle?: string;
    disabled?: boolean;
    loading?: boolean;
    showSelectActions?: boolean;
    showSearch?: boolean;
    showSave?: boolean;
    showDefaultMembers?: boolean;
};
//endregion

//region Styles
const StyledCreateTeams = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const CreateTeamsBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const FiltersHeader = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
    flex-shrink: 0;
`;

const FiltersSavePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
    font-weight: 600;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;

const StyledPlainActionsWrapper = styled.div`
    padding-right: 15px;
`;
//endregion

const PortableTeams: FC<PortableTeamsProps> = ({
    teams,
    selectedList,
    disabled,
    loading,
    onSave,
    onClearSelected,
    onDeselectAll,
    onSelectAll,
    onSetSelected,
    filtersTitle,
    showSelectActions,
    showSearch,
    showDefaultMembers,
    showSave = true,
}) => {
    const actionConfig: Record<
        Action,
        {
            color: string;
            colorHover: string;
            colorActive: string;
        }
    > = {
        clear: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
        select: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
    };

    const clearHandler = useCallback(() => {
        onClearSelected?.();
    }, [onClearSelected]);

    const [processedSelectedList, setProcessedSelectedList] = useState<
        number[]
    >([]);

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const searchHandler = (value: string) => setSearchTerm(value);
    const [, setAllSelected] = useState<boolean>(false);
    const defaultTeam = teams?.items.find(item => item.isDefault);
    const hasAllMembers = !!defaultTeam;
    
    const processedTeams = useMemo(() => {
        let result = teams?.items || [];
        if (!!searchTerm) {
            result = result.filter(
                item =>
                    item.name
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase()) !== -1
            );
        }
        if (result.length > 0) {
            result = sortBy(result, t => t.isDefault ? '' : t.name?.toLowerCase());
            const processedDataIds = result.map(item => item.id!);
        
            setProcessedSelectedList(
                selectedList.filter(item =>
                    processedDataIds.includes(Math.abs(item))
                )
            );
        }
        return result;
    }, [teams?.items, searchTerm, selectedList]);

    const isAllSelected = useMemo(
        () =>
            selectedList.length === teams?.items?.length &&
            !(teams?.items.length === 1 && hasAllMembers),
        [selectedList.length, teams?.items.length, hasAllMembers]
    );

    //TODO: [MM]: Refactor: Use `deSelectAllHandler` either for both `Select All` and `Unselect All` buttons and delete `clearHandler`
    //or rename confusing `deSelectAllHandler` to `handleClickSelectAll` and get rid of `if (isAllSelected && onDeselectAll` statement.
    const toggleAllHandler = useCallback(() => {
        if (isAllSelected && onDeselectAll) {
            onDeselectAll();
            setAllSelected(false)
        } else if (onSelectAll) {
            setAllSelected(true)
            onSelectAll(processedTeams.map(item => item.id!));
        }
    }, [onDeselectAll, onSelectAll, isAllSelected, processedTeams]);

    useEffect(() => {
        if (
            !!searchTerm &&
            processedSelectedList.length !== selectedList.length
        ) {
            // toggleAllHandler();
        }
    }, [searchTerm, processedSelectedList, selectedList, processedTeams]);

    const showNoItems = !teams?.totalCount;
    const showEmptyState = !processedTeams.length || loading;
    const selectedTeamsLength = selectedList?.filter(
        id => id > 0 && id !== defaultTeam?.id)?.length;

    return (
        <StyledCreateTeams>
            <CreateTeamsBody>
                {filtersTitle && (
                    <FiltersHeader>
                        <FilterTitle>{filtersTitle}</FilterTitle>
                    </FiltersHeader>
                )}
                {showSearch && (
                    <SearchWrapper data-test="teams-searchbox-wrapper">
                        <TableSearchInput
                            onChange={searchHandler}
                            disabled={showNoItems}
                            dataTest="teams-searchbox-field"
                        />
                    </SearchWrapper>
                )}
                {showSelectActions && !showNoItems && (
                    <SelectActions>
                        <div>
                            <SelectedCaption data-test="teams-selected-caption">
                                {selectedTeamsLength > 0
                                    ? `${selectedTeamsLength} ${
                                          selectedTeamsLength > 1
                                              ? ' Teams selected'
                                              : ' Team selected'
                                      }`
                                    : 'No Teams selected'}
                            </SelectedCaption>
                        </div>
                        <StyledPlainActionsWrapper>
                            {isAllSelected ? (
                                <PlainAction
                                    disabled={
                                        selectedList.length === 0 ||
                                        showEmptyState ||
                                        (processedTeams.length < 1 &&
                                            hasAllMembers)
                                    }
                                    color={actionConfig.clear.color}
                                    colorActive={actionConfig.clear.colorActive}
                                    colorHover={actionConfig.clear.colorHover}
                                    onClick={clearHandler}
                                    data-test="teams-unselect-all-button"
                                >
                                    Unselect All
                                </PlainAction>
                            ) : (
                                <PlainAction
                                    disabled={
                                        showEmptyState ||
                                        (processedTeams.length === 1 &&
                                            hasAllMembers &&
                                            !searchTerm)
                                    }
                                    color={actionConfig.select.color}
                                    colorActive={
                                        actionConfig.select.colorActive
                                    }
                                    colorHover={actionConfig.select.colorHover}
                                    onClick={toggleAllHandler}
                                    data-test="teams-select-all-button"
                                >
                                    Select All
                                </PlainAction>
                            )}
                        </StyledPlainActionsWrapper>
                    </SelectActions>
                )}
                {showEmptyState ? (
                    <EmptyTeams
                        loading={loading}
                        isEmpty={!teams?.totalCount}
                    />
                ) : (
                    <>
                        <PortableTeamsList
                            showDefaultMembers={showDefaultMembers}
                            teams={processedTeams}
                            selectedTeams={selectedList}
                            disabled={disabled}
                            setTeamFilters={onSetSelected}
                        />
                        {onSave && showSave && (
                            <FiltersSavePanel>
                                <Button
                                    type={'button'}
                                    label={'Save'}
                                    action={() => onSave()}
                                    height={'48px'}
                                    width={'160px'}
                                />
                            </FiltersSavePanel>
                        )}
                    </>
                )}
            </CreateTeamsBody>
        </StyledCreateTeams>
    );
};

export { PortableTeams };
