import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Search = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 20 20"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    fillRule="evenodd"
                    fill="currentColor"
                    d="M19.825 19.826c-.232.232-.609.232-.841 0l-5.573-5.572c-1.424 1.23-3.256 1.999-5.285 1.999C3.64 16.253 0 12.615 0 8.126 0 3.638 3.639 0 8.126 0c4.488 0 8.127 3.638 8.127 8.126 0 2.03-.77 3.861-2 5.286l5.572 5.572c.232.232.232.609 0 .842zm-4.823-11.7c0-3.797-3.078-6.876-6.876-6.876-3.797 0-6.876 3.08-6.876 6.876 0 3.798 3.079 6.877 6.876 6.877 3.798 0 6.876-3.078 6.876-6.877z"
                />
            </svg>
        );
    }
);

export default Search;
