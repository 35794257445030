import { CompanyInterface } from '../../../constants/interfaces/Company';
import {
    SEARCH_ALL_COMPANIES_START,
    SEARCH_ALL_COMPANIES_SUCCESS,
    SEARCH_ALL_COMPANIES_FAILURE,
    FETCH_ACTIVE_COMPANIES_START,
    FETCH_ACTIVE_COMPANIES_SUCCESS,
    FETCH_ACTIVE_COMPANIES_FAILURE,
} from './actions';
import { ListResult } from "../../../constants/interfaces/PaginationResult";

export interface AllCompaniesInterface {
    allCompanies?: ListResult<CompanyInterface>;
    loadingAllCompanies?: boolean;
    errorAllCompanies?: string;
    activeCompanies?: ListResult<CompanyInterface>;
    isActiveCompaniesLoading?: boolean;
    activeCompaniesError?: string;
}

export const allCompaniesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SEARCH_ALL_COMPANIES_START:
            return {
                ...state,
                errorAllCompanies: '',
                loadingAllCompanies: true,
            };
        case SEARCH_ALL_COMPANIES_SUCCESS:
            return {
                ...state,
                allCompanies: action.data,
                errorAllCompanies: '',
                loadingAllCompanies: false,
            };
        case SEARCH_ALL_COMPANIES_FAILURE:
            return {
                ...state,
                errorAllCompanies: action.error,
                loadingAllCompanies: false,
            };
        case FETCH_ACTIVE_COMPANIES_START:
            return {
                ...state,
                isActiveCompaniesLoading: true,
                activeCompaniesError: undefined,
            };
        case FETCH_ACTIVE_COMPANIES_SUCCESS:
            return {
                activeCompanies: action.data,
                isActiveCompaniesLoading: false,
                activeCompaniesError: undefined,
            };
        case FETCH_ACTIVE_COMPANIES_FAILURE:
            return {
                ...state,
                isActiveCompaniesLoading: false,
                activeCompaniesError: action.error,
            };
        default:
            return state;
    }
};
