import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { PortableLabels } from '../../../../../../features/portableLabels';
import { useToggleCollapseAssignLabelFieldService } from '../../../../../../features/labels/store/services';
import { useLabelsState } from '../../../../../../features/labels/store/states';
import { Fieldset } from '../../../../Fieldset';
import { Button } from '../../../../Button';
import { usePortableLabelsState } from '../../../../../../features/portableLabels/store/states';
import { useToggleCollapseAllLabels } from '../../../../../../features/portableLabels/store/hors/withLabels/services';
import {
    Actions,
    CancelButtonWrapper,
    LabelsContainer,
    StyledSelectForm,
    Wrapper,
} from './styles';

const ANIMATION_SCALE = 5;

export const AssignLabelOptions: FC<{
    disabled?: boolean;
    onAssignLabelsSubmit?(): void;
    setAssignLabelsAction?(labelIds: number[]): void;
    className?: string;
    containerLeftPosition?: string;
    setShowOptions: Dispatch<SetStateAction<boolean>>;
}> = ({
    disabled,
    onAssignLabelsSubmit,
    setAssignLabelsAction,
    className,
    containerLeftPosition,
    setShowOptions,
}) => {
    const [show, setShow] = useState(true);
    const onToggleCollapse = useToggleCollapseAssignLabelFieldService();
    const onToggleCollapseAll = useToggleCollapseAllLabels();
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const [applyDisabled, setApplyDisabled] = useState(true);

    const handleSelectAll = () => {
        if (labelsList && setAssignLabelsAction) {
            setAssignLabelsAction(labelsList.items.map(label => label.id));
        }
        setApplyDisabled(false);
    };

    const handleClear = () => {
        if (labelsList && setAssignLabelsAction) {
            setAssignLabelsAction([]);
        }
        setApplyDisabled(false);
    };

    /**
     * @function onCloseHandler
     * @returns { void }
     */
    const onCloseHandler = useCallback((): void => {
        Promise.resolve().then(() => {
            setShow(false);
            setShowOptions(false);
        });
    }, [setShowOptions]);

    const onApplyHandler = useCallback(() => {
        onAssignLabelsSubmit && onAssignLabelsSubmit();
        onCloseHandler();
    }, [onAssignLabelsSubmit, onCloseHandler]);

    if (!show) return <span />;

    const setAssignLabelsAndEnableApply = (labelIds: number[]) => {
        if (labelsList && setAssignLabelsAction) {
            setAssignLabelsAction(labelIds);
            setApplyDisabled(false);
        }
    };

    return (
        <ClickAwayListener onClickAway={onCloseHandler}>
            <StyledSelectForm disabled={disabled} className={className}>
                <LabelsContainer
                    initial={{ scale: 1 / ANIMATION_SCALE }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    topMargin={5}
                    left={containerLeftPosition}
                >
                    <Wrapper>
                        <Fieldset title={'Labels'} dataTest="labels-section-title">
                            <PortableLabels
                                labels={labelsList}
                                collapsedList={labels.collapseAssignLabels}
                                selectedList={labels.assignedLabels}
                                onToggleCollapse={onToggleCollapse}
                                onToggleCollapseAll={onToggleCollapseAll}
                                onSetSelected={setAssignLabelsAndEnableApply}
                                onSelectAll={handleSelectAll}
                                onClearSelected={handleClear}
                                onSave={onApplyHandler}
                                showSave={false}
                                showSearch
                                showSelectActions
                                noResultType={'min'}
                            />
                        </Fieldset>
                    </Wrapper>
                    <Actions>
                        <CancelButtonWrapper>
                            <Button
                                width={'120px'}
                                action={onCloseHandler}
                                variant={'inverse'}
                                dataTest="cancel-button"
                            >
                                Cancel
                            </Button>
                        </CancelButtonWrapper>
                        <Button
                            width={'120px'}
                            action={() => onApplyHandler()}
                            disabled={applyDisabled}
                            dataTest="apply-button"
                        >
                            Apply
                        </Button>
                    </Actions>
                </LabelsContainer>
            </StyledSelectForm>
        </ClickAwayListener>
    );
};
