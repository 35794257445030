import React, {
    FC,
    useCallback,
    useMemo,
} from 'react';
import {
    ROLE_NAMES as Roles,
} from '../../../../constants/enums';
import {
    FiltersCheckboxList,
    FiltersCheckboxListItem,
    FiltersNoItems,
} from '../../../../ui/components/Filters';
import { useLoggedInUserRolesHelper } from '../../tools';
import { useUserRolesService } from '../../../../features/roles/store/services';

interface Props {
    selectedRoles: number[];
    onChangeSelectedRoles(selectedRoles: number[]): void;
}

export const UserRoleFilters: FC<Props> = props => {
    const { selectedRoles, onChangeSelectedRoles } = props;
    const rolesHelper = useLoggedInUserRolesHelper();
    const { loading, getRoleByName } = useUserRolesService();
    
    const allRoles = useMemo(
        () => loading
            ? [] 
            : [Roles.PRACTIS_ADMIN, Roles.COMPANY_ADMIN, Roles.USER].map(
                roleName => {
                    const role = getRoleByName(roleName);
                    return {
                        // temporary solution, possibly needs to be changed from backend
                        name: role?.id === 4 ? "Company Admin" : role?.title,
                        value: role?.id,
                    };
                }
        ),
        [loading, getRoleByName]
    );

    const roleCheckboxItems: FiltersCheckboxListItem<number>[] = useMemo(
        () => allRoles
            .filter(r => rolesHelper.canViewRole(r.name))
            .map(item => ({
                checked: selectedRoles.includes(item.value),
                label: item.name,
                value: item.value,
            })),
        [allRoles, selectedRoles, rolesHelper]
    );

    const setSelectedRoles = useCallback(
        (roleIds: number[]) => onChangeSelectedRoles(roleIds),
        [onChangeSelectedRoles]
    );

    const handleSelectedRoleChange = useCallback(
        (roleId: number) => {
            setSelectedRoles(
                selectedRoles.includes(roleId)
                    ? selectedRoles.filter(r => r !== roleId)
                    : [...selectedRoles, roleId]
            );
        },
        [selectedRoles, setSelectedRoles]
    );

    return (
        <>
            {(loading || allRoles.length === 0) && (
                <FiltersNoItems
                    itemsName="Roles"
                    loading={loading}
                    empty={allRoles.length === 0}
                />
            )}
            <FiltersCheckboxList<number>
                items={roleCheckboxItems}
                onChange={handleSelectedRoleChange}
            />
        </>
    );
};
