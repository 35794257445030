import styled, { css } from 'styled-components';

export const LabelTagsContainer = styled.div<{ disabled: boolean }>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 16px;

    ${props =>
        props.disabled &&
        css`
            cursor: default;
        `}
`;

export const StyledLabelTag = styled.div<{
    open?: boolean;
    disabled?: boolean;
    isLabelLess?: boolean;
}>`
    width: 44px;
    height: 24px;
    border-radius: 4px;
    color: ${props => props.theme.Colors.cloudyBlue};
    border: solid 1px
        ${props =>
            props.disabled
                ? props.theme.Colors.paleGrey
                : props.theme.Colors.cloudyBlue};
    background-color: ${props => props.theme.Colors.whiteFive};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 11px;
    &:hover {
        color: ${props => !props.disabled && props.theme.Colors.darkSkyBlue};
        border: solid 1px
            ${props => !props.disabled && props.theme.Colors.darkSkyBlue};
    }
    ${props =>
        props.open &&
        `
    color: ${props.theme.Colors.darkSkyBlue};
    border: solid 1px ${props.theme.Colors.darkSkyBlue}; 
    background-color: ${props.theme.Colors.darkSkyBlue15};
`}
    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
            border: solid 1px ${props.theme.Colors.paleGrey};
            cursor: default;
        `}
${props =>
        props.isLabelLess &&
        `
        border: solid 1px ${props.theme.Colors.paleGrey};
`}
`;

export const LabelIconWrapper = styled.div<{ disabled: boolean }>`
    width: 16px;
    height: 12px;
    display: flex;

    ${props =>
        props.disabled &&
        css`
            color: ${props => props.theme.Colors.paleGrey};
        `}
`;

export const LabelText = styled.div<{ disabled: boolean }>`
    height: 12px;
    display: flex;
    margin-left: 4px;
    line-height: 1.1;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.4;
        `}
`;

