import { NewRelicApi } from './NewRelic';

export interface DefaultNewRelicOptions {
    newRelicLicenceKey: string;
    newRelicAppId: string;
}

export class DefaultNewRelic implements NewRelicApi {
    private readonly options: DefaultNewRelicOptions;

    constructor(options: DefaultNewRelicOptions) {
        this.options = options;
    }

    getLicenseKey = () => {
        return this.options.newRelicLicenceKey;
    };

    getAppId = () => {
        return this.options.newRelicAppId;
    };
}
