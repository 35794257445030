import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { ACTIONS, SingleTeamFilterActions } from './actions';
import { initialSingleTeamFilterState } from './constants';
import { SingleTeamFilterState } from './types';

export const singleTeamFilterReducer: Reducer<
    SingleTeamFilterState,
    SingleTeamFilterActions
> = (state = initialSingleTeamFilterState, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_SINGLE_TEAM:
            return {
                ...state,
                selectedTeamId: action.selectedTeamId,
            };
        case ACTIONS.DISABLE_SINGLE_TEAM_FILTER:
            return {
                ...state,
                isDisabled: action.isDisabled,
            }

        default:
            return state;
    }
};

export const useSingleTeamFilterState = () =>
    useSelector(state => state.singleTeamFilterReducer);