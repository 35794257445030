import { FC } from 'react';

import AvatarField from './avatar-field.view';
import MinimizedAvatarField from './minimized-avatar-field.view';
import { AvatarFieldProps } from './types';

const AvatarFieldController: FC<AvatarFieldProps> = props => {
    const { isMinimized, ...rest } = props;

    return isMinimized ? (
        <MinimizedAvatarField {...rest} />
    ) : (
        <AvatarField {...rest} />
    );
};

export default AvatarFieldController;

