import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../../../../../constants/interfaces/ScriptLine';
import ScriptItem from '../ScriptItem/ScriptItem';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import { useUpdateScriptLineService } from '../../../../../store/services';
import { useSelector } from 'react-redux';
import {
    AudioInterface,
    getAudioState,
} from '../../../../../../../../features/player/store/reducers';
import {
    EditModeContext,
    ScenarioContainerContext,
} from '../../../../../NewScenario';
import { EditModeValues } from '../../../../../../../../constants/enums/EditModeValues';
import Drag from '../../../../../../../../ui/icons/Drag';


const StyledScriptLineContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledDroppableContainer = styled.div<{ isDraggingOver: boolean }>``;

const StyledScriptHandle = styled.div<{ reverse?: boolean; hidden?: boolean }>`
    color: #b1c0cb;
    display: flex;
    flex-shrink: 0;
    width: 11px;
    align-items: center;
    height: 30px;
    margin-left: ${props => (props.reverse ? 29 : 8)}px;
    margin-right: ${props => (props.reverse ? 8 : 29)}px;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const StyledDraggableItem = styled.div<{ reverse?: boolean }>`
    display: flex;
    flex-direction: ${props => (props.reverse ? 'row' : 'row-reverse')};
    margin-bottom: 16px;
`;

const ScriptLineContent: FC<{
    script: Array<ScriptLine>;
    player?: AudioInterface;
    handleScriptLineUpdate: (value: any) => void;
}> = ({ script, player, handleScriptLineUpdate }) => {
    const [scriptLines, setScriptLines] = useState(script);
    const parentRef = useContext(ScenarioContainerContext);
    const edit = useContext(EditModeContext);

    useEffect(() => {
        parentRef && parentRef.current.scrollTo(0, 0);
    }, [parentRef]);

    useEffect(() => {
        setScriptLines(script);
    }, [script, setScriptLines]);

    const Reorder = (startIndex: number, endIndex: number) => {
        const result = Array.from(scriptLines);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        setScriptLines(result);
        handleScriptLineUpdate(result);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        Reorder(result.source.index, result.destination.index);
    };

    return (
        <StyledScriptLineContent>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`scenario-drop-list`}>
                    {(provided: any, snapshot) => (
                        <StyledDroppableContainer
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {scriptLines.map((item: ScriptLine, index) => {
                                let disabled = false;
                                if (
                                    player &&
                                    player.recording &&
                                    player.recording !== item.id
                                ) {
                                    disabled = true;
                                }
                                return (
                                    <Draggable
                                        key={`draggable-item_${item.id}`}
                                        draggableId={item.id.toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <StyledDraggableItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                reverse={
                                                    item.speaker ===
                                                    ScriptLineSpeaker.REP
                                                }
                                            >
                                                <StyledScriptHandle
                                                    className="handle"
                                                    reverse={
                                                        item.speaker ===
                                                        ScriptLineSpeaker.REP
                                                    }
                                                    hidden={
                                                        edit.mode ===
                                                        EditModeValues.VIEW
                                                    }
                                                    {...provided.dragHandleProps}
                                                    data-test={
                                                        item.speaker === ScriptLineSpeaker.REP
                                                            ? 'scenario-rep-line-drag-handle'
                                                            : 'scenario-customer-line-drag-handle'
                                                    }
                                                >
                                                    <Drag />
                                                </StyledScriptHandle>
                                                <ScriptItem
                                                    key={item.id}
                                                    script={item}
                                                    disabled={disabled}
                                                    editable={
                                                        edit.mode ===
                                                        EditModeValues.EDIT
                                                    }
                                                />
                                            </StyledDraggableItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </StyledDroppableContainer>
                    )}
                </Droppable>
            </DragDropContext>
        </StyledScriptLineContent>
    );
};

export const ScriptLineContentContainer: FC<{
    script: ScriptLine[];
}> = ({ script }) => {
    const player = useSelector(getAudioState);
    const updateScriptLine = useUpdateScriptLineService();

    return (
        <ScriptLineContent
            script={script}
            player={player}
            handleScriptLineUpdate={updateScriptLine}
        />
    );
};

export default ScriptLineContentContainer;
