import React from 'react';
import ROUTES from '../../../routes/routes';
import Navigation from '../../../ui/components/Navigation/Navigation';
import styled from 'styled-components';

const StyledLibraryNavigationContainer = styled.div``;

const LibraryNavigation = () => {
    return (
        <StyledLibraryNavigationContainer>
            <Navigation
                menu={[
                    {
                        key: 'practisSets',
                        title: `Practis Sets`,
                        url: ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL,
                    },
                    {
                        key: 'scenarios',
                        title: `Scenarios`,
                        url: ROUTES.LIBRARY_SETTINGS.SCENARIOS.ALL,
                    },
                    {
                        key: 'challenges',
                        title: `Challenges`,
                        url: ROUTES.LIBRARY_SETTINGS.CHALLENGES.ALL,
                    },
                ]}
                dataTest='library-nav'
            />
        </StyledLibraryNavigationContainer>
    );
};

export const LibraryNavigationContainer = () => {
    return <LibraryNavigation />;
};

export default LibraryNavigationContainer;
