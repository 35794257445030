import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { UserV2 } from '../../../../constants/interfaces/User';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../constants/interfaces/DueDates';
/**
 * @description find selected trainee due date
 * @function findSelectedTraineeDueDate
 * @param { PaginationResult<UserInterface> | undefined } trainees
 * @param { number } selectedTraineeId
 * @returns { string | null }
 */
export const findSelectedTraineeDueDate = (
    trainees: ListResult<UserV2 & {dueDate?: Date}> | undefined,
    selectedTraineeId: number
): string | null => {
    const date = trainees?.items?.find(trainee => trainee.id === selectedTraineeId)?.dueDate;

    return date ? dayjs(date as Date).format(DATE_FORMAT) : null;
};
