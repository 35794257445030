import { EnrollmentInterface } from "../../../constants/interfaces/Enrollments";
import { SearchParams } from "../../../constants/interfaces/filters";
import { ListResult } from "../../../constants/interfaces/PaginationResult";
import { EnrollmentPractisSet } from "../../../constants/interfaces/PractisSets";
import { userStatusFilters } from "../../../constants/interfaces/User";
import { getSearchSortingValue } from "../../../helpers/functions/search-params-helpers";
import { dueDateFiltersValues } from "../../../ui/components/DueDateCalendar/constants";

export const transformEnrollmentsToPractisSets = (data: ListResult<EnrollmentInterface>): ListResult<EnrollmentPractisSet> => {
    const practisSets = data.items?.map((item) => {
        return {...item.practisSet, dueDate: item.dueDate, enrollmentId: item.id}
    });

    return { ...data, items: practisSets };
}

export const getSearchUserPractisSetsParams = (searchParams: SearchParams) => {
    return {
        status: searchParams.filterByStatus?.map((item) => userStatusFilters[item as keyof typeof userStatusFilters]).join(','),
        dueDate: searchParams.filterByDueDate?.map((item) => dueDateFiltersValues[item as keyof typeof dueDateFiltersValues]).join(','),
        limit: searchParams.limit ?? 20,
        offset: searchParams.offset ?? 0,
        query: searchParams.searchTerm,
        sort: getSearchSortingValue(searchParams.orderBy, 'assigned', false)
    };
}