import React, { FC } from 'react';
import styled from 'styled-components';

const Label = styled.div<{ color?: string }>`
    position: absolute;
    top: 0px;
    left: 16px;
    pointer-events: none;
    font-size: 13px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.color ? props.color : props.theme.Colors.steelGrey};
    font-weight: normal;
    cursor: pointer;
`;

export const SelectLabel: FC<{
    label: string;
    children?: string;
    color?: string;
    dataTest?: string;
}> = ({ children, label, color, dataTest }) => {
    return <Label color={color} data-test={dataTest}>{children || label}</Label>;
};
