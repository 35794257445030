import { useCallback } from 'react';

import { useHttp } from '../../../../../tools/http/Http';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { TeamBasic } from '../../../../../constants/interfaces/TeamBasic';

export const useSearchTeamsApi = () => {
    const http = useHttp();
    return useCallback(
        (sp?: SearchParams): Promise<PaginationResult<TeamBasic>> =>
            http.post('teams/search/basic', sp),
        [http]
    );
};
