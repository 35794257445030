import styled from 'styled-components';
import FlagActive from '../../../../ui/icons/FlagActive';
import FlagInactive from '../../../../ui/icons/FlagInactive';

export const FlagHandle = styled.a`
    cursor: pointer;
    display: flex;
`;

export const FlagActiveIcon = styled(FlagActive)`
    color: var(--ps-red-main);

    &:hover {
        color: #DF1F1F;
    }
`;

export const FlagInactiveIcon = styled(FlagInactive)`
    color: var(--ps-grey-2);

    &:hover {
        color: #DF1F1F;
    }
`;