import { ALL_FILTER_VALUE } from '../../constants/interfaces/filters';

export const distinct = (array: any, field: string) => {
    return array.filter(
        (item: any, index: number, arr: any) =>
            arr.findIndex((t: any) => t[field] === item[field]) === index
    );
};

export const appendAllFilterValue = (array: any) => {
    return [{ name: 'All', value: ALL_FILTER_VALUE }, ...array];
};

export const toggleItemInArray = (array: any, item: any) => {
    let arrayItems = array ? [...array] : [];

    if (arrayItems.includes(item)) {
        arrayItems = arrayItems.filter(x => x !== item);
    } else {
        arrayItems.push(item);
    }

    return arrayItems;
};

export const appendIfEmpty = (array: any, item: any) => {
    let arrayItems = array ? [...array] : [];

    if (!arrayItems.includes(item)) {
        arrayItems.push(item);
    }

    return arrayItems;
};

export const removeItemFromArray = (array: any, item: any) => {
    let arrayItems = array ? [...array] : [];

    if (arrayItems.includes(item)) {
        arrayItems = arrayItems.filter(x => x !== item);
    }

    return arrayItems;
};

export const combineArray = (list: any[], size: number) => {
    const matrix: any = [];
    let i;
    let k;

    for (i = 0, k = -1; i < list.length; i++) {
        if (i % size === 0) {
            k++;
            matrix[k] = [];
        }

        matrix[k].push(list[i]);
    }

    return matrix;
};