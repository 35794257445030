import { memo } from 'react';
import { useParams } from 'react-router-dom';
import OldSubmissionRouter from '../components/OldSubmissionRouter';
import ROUTES from '../../../routes/routes';

function OldChallengeController() {
    const params = useParams<{ submissionId: string }>();

    return <OldSubmissionRouter
        id={params.submissionId}
        type="challenge"
        singleRoute={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.SINGLE}
        allRoute={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL}
    />;
}

export default memo(OldChallengeController)