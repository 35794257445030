import { useEffect } from 'react';

export default function useKeypress(key: string, action: any) {
    useEffect(() => {
        function onKeyup(e: any) {
            if (e.key === key) action && action();
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [action, key]);
}
