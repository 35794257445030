import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import { NudgeUsersApiParams, RemindToStartApiParams } from './types';

/**
 * @function useNudgeUsersApi
 * @returns { NudgeUsersApiParams }
 */
export function useNudgeUsersApi(): NudgeUsersApiParams {
    const http = useHttp();
    return useCallback(
        nudgeDateList => http.post('nudges/users', nudgeDateList, true),
        [http]
    );
}

/**
 * @function useRemindToStartApi
 * @returns { RemindToStartApiParams }
 */
export function useRemindToStartApi(): RemindToStartApiParams {
    const http = useHttp();
    return useCallback(
        enrollments => http.post('alerts', enrollments, true),
        [http]
    );
}