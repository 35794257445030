import { VoiceSettingsRole } from '../../../../constants/interfaces/CompanyVoiceSettings';
import { VoiceSettingsSelectItem } from './types';

export const DEFAULT_CUSTOMER_SAMPLE_TEXT = 'Hello can you help me with something? I bought these wireless headphones online last week. Sound is horrible. Total disappointment. Just want someone to set me up with a quality pair of headphones.';
export const DEFAULT_REP_SAMPLE_TEXT = 'Sorry to hear that. The headphone market is so saturated these days. It can be really difficult to make an informed decision. Especially when buying online. I can help. These are the new headphones by InTunes. Everybody loves these!';

export const RoleItems: VoiceSettingsSelectItem<VoiceSettingsRole>[] = [
    {
        name: 'Customer',
        value: 'customer'
    },
    {
        name: 'Representative',
        value: 'representative'
    }
];