import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SearchParams } from '../../../constants/interfaces/filters';

export interface SearchStateInterface {
    key: string,
    params?: SearchParams,
    childrenKeys: string[]
}

export interface SecondarySearchStateInterface {
    key: string
    params?: SearchParams | undefined
}

export type GlobalSearchStateSelector = (state:  SearchStateInterface) =>  SearchStateInterface;

export const searchStateSelectorContext = React.createContext<GlobalSearchStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function SearchState(): SearchStateInterface {
    return useSelector(useContext(searchStateSelectorContext));
}


export type GlobalSecondarySearchStateSelector = (state:  SearchStateInterface) =>  SearchStateInterface;

export function SecondarySearchState(): SearchStateInterface {
    return useSelector(useContext(secondarySearchStateSelectorContext));
}

export const secondarySearchStateSelectorContext = React.createContext<GlobalSearchStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);
