import styled from 'styled-components';

export const Title = styled.div`
    font-size: 15px;
    color: var(--ps-black-main);
    font-weight: 600;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--ps-grey-4);
    margin-bottom: 16px;
`;

export const Description = styled.div`
    font-size: 13px;
    color: var(--ps-grey-1);
    padding-top: 4px;
    width: 70%;
`;

export const ToggleContainer = styled.div`
    width: 42px;
`