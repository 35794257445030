import { FilterHeader } from '../filter-header';
import SearchableFilterHeader from '../searchable-filter-header';
import { SearchableFilterHeaderProps } from '../searchable-filter-header/types';
import { Wrapper } from './styles';
import { FilterContainerViewProps } from './types';

function FilterContainerView(props: FilterContainerViewProps) {
    const { headerProps, children } = props;
    const searchableHeaderProps = headerProps as SearchableFilterHeaderProps;

    return (
        <Wrapper>
            {searchableHeaderProps.storeKey
                ? <SearchableFilterHeader {...searchableHeaderProps} />
                : <FilterHeader {...headerProps} />
            } {children}
        </Wrapper>
    );
}

export default FilterContainerView;