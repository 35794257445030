export enum LogEvent {
    CREATE_COMPANY = 'CREATE_COMPANY',
    DELETE_COMPANY = 'DELETE_COMPANY',
    UPDATE_COMPANY = 'UPDATE_COMPANY',
    CREATE_PRACTIS_ADMIN = 'CREATE_PRACTIS_ADMIN',
    DELETE_PRACTIS_ADMIN = 'DELETE_PRACTIS_ADMIN',
    UPDATE_PRACTIS_ADMIN = 'UPDATE_PRACTIS_ADMIN',
    CREATE_SCENARIO = 'CREATE_SCENARIO',
    DELETE_SCENARIO = 'DELETE_SCENARIO',
    UPDATE_SCENARIO = 'UPDATE_SCENARIO',
    CREATE_CHALLENGE = 'CREATE_CHALLENGE',
    DELETE_CHALLENGE = 'DELETE_CHALLENGE',
    UPDATE_CHALLENGE = 'UPDATE_CHALLENGE',
    GENERATE_CHALLENGE_FROM_SCENARIO = 'GENERATE_CHALLENGE_FROM_SCENARIO',
    CREATE_PRACTIS_SET = 'CREATE_PRACTIS_SET',
    DELETE_PRACTIS_SET = 'DELETE_PRACTIS_SET',
    UPDATE_PRACTIS_SET = 'UPDATE_PRACTIS_SET',
    INVITE_USER = 'INVITE_USER',
    REVOKE_INVITATION = 'REVOKE_INVITATION',
    RESEND_INVITATION = 'RESEND_INVITATION',
    UPDATE_SETTING = 'UPDATE_SETTING',
    CREATE_SUBMISSION = 'CREATE_SUBMISSION',
    UPDATE_SUBMISSION = 'UPDATE_SUBMISSION',
    DELETE_SUBMISSION = 'DELETE_SUBMISSION',
    REVIEW_SUBMISSION = 'REVIEW_SUBMISSION',
    CREATE_SCORE = 'CREATE_SCORE',
    UPDATE_SCORE = 'UPDATE_SCORE',
    DELETE_SCORE = 'DELETE_SCORE',
    DELETE_USER = 'DELETE_USER',
    CREATE_STAGING = 'CREATE_STAGING',
    UPDATE_STAGING = 'UPDATE_STAGING',
    DELETE_STAGING = 'DELETE_STAGING',
    CREATE_TEAM = 'CREATE_TEAM',
    DUPLICATE_TEAM = 'DUPLICATE_TEAM',
    UPDATE_TEAM = 'UPDATE_TEAM',
    DELETE_TEAM = 'DELETE_TEAM',
    USER_TEAM_LEAD_PROMOTION = 'USER_TEAM_LEAD_PROMOTION',
    USER_TEAM_LEAD_REVOKE = 'USER_TEAM_LEAD_REVOKE',
    USER_ADDED_INTO_TEAM = 'USER_ADDED_INTO_TEAM',
    CREATE_THEME = 'CREATE_THEME',
    ADD_COVER_INTO_THEME = 'ADD_COVER_INTO_THEME',
    DELETE_THEME = 'DELETE_THEME',
    UPDATE_THEME = 'UPDATE_THEME',
    REMOVE_COVER = 'REMOVE_COVER',
    ASSIGN_THEME_TO_PRACTISE_SET = 'ASSIGN_THEME_TO_PRACTISE_SET',
    ASSIGN_COVER_TO_PRACTISE_SET = 'ASSIGN_COVER_TO_PRACTISE_SET',
    ASSIGN_COVER_TO_SCENARIO = 'ASSIGN_COVER_TO_SCENARIO',
}
