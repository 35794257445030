import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PlusInCircleIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.343 2.343a8 8 0 1 0 11.314 11.314A8 8 0 0 0 2.343 2.343zm1.06 10.253a6.5 6.5 0 1 1 9.193-9.192 6.5 6.5 0 0 1-9.192 9.192z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.25 7.25V4h1.5v3.25H12v1.5H8.75V12h-1.5V8.75H4v-1.5h3.25z"
                    fill="currentColor"
                />
            </svg>
        );
    }
);

export default PlusInCircleIcon;
