import { createGlobalStyle } from 'styled-components';
import { Variables } from './variables';

export const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Variables.Colors.paleGrey};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Variables.Colors.paleGrey};
  }

  textarea:focus, input:focus {
    outline: none;
  }

  * {
    scrollbar-color: ${Variables.Colors.whiteThree};
    scrollbar-width: thin !important;
  }

  *:focus {
    outline: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  body {
    font-family: Manrope !important;
    margin: 0;
    overflow: hidden;
    background-color: ${Variables.Colors.white} !important;
  }

  textarea {
    font-family: Manrope !important;
  }

  .inline-block{
    display: inline-block;
  }

  .inline-flex{
    display: inline-flex;
  }

  .tooltip-question {
    padding: 20px;
    width: 280px;
    word-break: break-word;
    white-space: normal;
    min-height: 84px;
    border-radius: 8px;

    &:before{
      content: '';
      width: 16px;
      height: 16px;
      background: #fff;
      position: absolute;
      left: 132px;
      top: -6px;
      transform: rotate(45deg);
      box-shadow: -3px -4px 5px rgba(0, 0, 0, 0.015),
      0 0px white, 
      -7px -3px 5px  rgba(0, 0, 0, 0.015), 
      0 0px white;
      z-index: -1;
    }
  }

  .tooltip-question.top {
    &:before {
      bottom: -6px;
      top: unset;
      box-shadow: unset;
    }
  }

  // striped-progress-bar styles

  .progress-container {
    margin: auto;
    width: 100%;
    }


  .progress-title {
    font-size: 13px;
    color: var(--ps-grey-1);
    margin-bottom: 10px;
  }

  .progress {
    background-color: var(--ps-grey-4);
    border-radius: 58px;
    box-shadow: none;
  }


  .progress-bar {
    background-color: var(--ps-blue-main);
    height: 16px;
    border-radius: 58px;
    box-shadow: none;
  }

  .progress-bar.text-right span {
    margin-right: 10px;
  }

  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }

    to {
      background-position: 0 0;
    }
  }

  @keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }

    to {
      background-position: 0 0;
    }
  }

  .progress.active .progress-bar,
  .progress-bar.active {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
  }

  .progress-striped .progress-bar,
  .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
  }

  .progress-bar-default {
    background-color: #b0bec5;
  }
`;