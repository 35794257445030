import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoReportingPractisSets = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 33 32"
                className={className}
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5 29.867V12.8c1.104 0 2-.955 2-2.134V2.133C32.5.955 31.605 0 30.5 0h-28C1.395 0 .5.955.5 2.133v8.534c0 1.178.895 2.134 2 2.134v17.066c0 1.177.896 2.133 2 2.133h24c1.105 0 2-.956 2-2.133zm-25 0c-.552 0-1-.478-1-1.067V13.866c0-.588.448-1.064 1-1.064h5v4.265c0 1.179.896 2.133 2 2.133h8c1.105 0 2-.954 2-2.133v-4.265h5c.552 0 1 .476 1 1.064V28.8c0 .59-.448 1.067-1 1.067h-22zM20.5 16v-3.198h-8V16c0 .59.447 1.067 1 1.067h6c.552 0 1-.478 1-1.067zm9-13.867c.552 0 1 .479 1 1.067v6.4c0 .59-.448 1.067-1 1.067h-26c-.553 0-1-.478-1-1.066V3.2c0-.589.448-1.067 1-1.067h26z" fill="#B1C0CB"/>
            </svg>
        );
    }
);

export default NoReportingPractisSets;
