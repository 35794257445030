import { FC, memo } from 'react';
import { EmptyStateContainer } from './EmptyStateContainer';
import styled from 'styled-components';

const TableBody = styled.tbody``;
const TableEmptyTd = styled.td`
    height: 50vh;
`;

const EmptyStateTableWrapper: FC = memo(({ children }) => {
    return (
        <TableBody>
            <tr>
                <TableEmptyTd colSpan={100}>
                    <EmptyStateContainer>{children}</EmptyStateContainer>
                </TableEmptyTd>
            </tr>
        </TableBody>
    );
});

export default EmptyStateTableWrapper;
