import { FC, useState } from 'react';
import Checkbox from '../../../../../Checkbox';
import { Label } from '../../../../../../../constants/interfaces/Label';
import TriangleDown from '../../../../../../icons/TriangleDown';
import TriangleRight from '../../../../../../icons/TriangleRight';
import { Variables } from '../../../../../../../theme/variables';
import {
    useSetAssignLabelsActionService,
    useToggleCollapseAssignLabelFieldService,
} from '../../../../../../../features/labels/store/services';
import { useLabelsState } from '../../../../../../../features/labels/store/states';
import { useHandleSelectLabels } from '../../../../../../../features/labels/tools';
import { listToTree } from '../../../../../../../helpers/functions/list-to-tree';
import { usePortableLabelsState } from '../../../../../../../features/portableLabels/store/states';
import {
    ArrowContainer,
    ChildLabelsContainer,
    CollapseArrow,
    LabelItemActions,
    LabelListItemLabel,
    LabelListItemNameContainer,
    StyledLabelListItem,
    StyledLabelListItemContainer,
    StyledTitleContainer,
} from './styles';

export const LabelsActionItem: FC<{
    label: Label;
    labelsCollapseState: number[];
    onToggleLabelsCollapse(labelId: number): void;
    openActions: boolean;
    toggleOpenActions: (open: boolean) => void;
    selectedLabels: number[];
    setAssignLabelsAction(labelIds: number[]): void;
    allLabels?: Label[];
    dataTest?: string;
}> = ({
    label,
    labelsCollapseState,
    onToggleLabelsCollapse,
    openActions,
    selectedLabels,
    setAssignLabelsAction,
    allLabels,
    dataTest
}) => {
    const isLabelSelected = selectedLabels.includes(label.id);
    const isLabelPartiallySelected = selectedLabels.includes(-label.id);

    const hasChildren = (label?: Label) => {
        return label && label.children && label.children.length > 0;
    };

    const handleSelectLabels = useHandleSelectLabels();

    const handleLabelSelection = () => {
        const labelsTree = listToTree(allLabels);
        setAssignLabelsAction(
            handleSelectLabels(label, selectedLabels, labelsTree)
        );
    };

    return (
        <>
            <StyledLabelListItemContainer data-test={dataTest}>
                <StyledLabelListItem
                    selected={isLabelSelected || isLabelPartiallySelected}
                >
                    <StyledTitleContainer
                        onClick={() => onToggleLabelsCollapse(label.id)}
                        disabled={!hasChildren(label)}
                    >
                        <ArrowContainer>
                            {hasChildren(label) && (
                                <CollapseArrow>
                                    {labelsCollapseState.includes(label.id) ? (
                                        <TriangleDown />
                                    ) : (
                                        <TriangleRight />
                                    )}
                                </CollapseArrow>
                            )}
                        </ArrowContainer>
                        <LabelListItemNameContainer>
                            <LabelListItemLabel data-test={dataTest && `${dataTest}-name`}>
                                {label.name}
                            </LabelListItemLabel>
                        </LabelListItemNameContainer>
                    </StyledTitleContainer>
                    <LabelItemActions menuOpen={openActions}>
                        <Checkbox
                            checked={
                                isLabelSelected || isLabelPartiallySelected
                            }
                            partial={isLabelPartiallySelected}
                            handleChange={handleLabelSelection}
                            size={12}
                            border={Variables.Colors.cloudyBlue}
                            dataTest={dataTest && `${dataTest}-checkbox`}
                        />
                    </LabelItemActions>
                </StyledLabelListItem>
            </StyledLabelListItemContainer>
            {hasChildren(label) && (
                <ChildLabelsContainer
                    expanded={labelsCollapseState.includes(label.id)}
                >
                    {label.children!.map(childLabel => (
                        <LabelsActionItemContainer
                            key={childLabel.id}
                            label={childLabel}
                            dataTest={dataTest}
                        />
                    ))}
                </ChildLabelsContainer>
            )}
        </>
    );
};

const LabelsActionItemContainer: FC<{
    label: Label;
    dataTest?: string;
}> = ({ label, dataTest }) => {
    const [open, setOpen] = useState(false);
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const onToggleCollapse = useToggleCollapseAssignLabelFieldService();
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();

    return (
        <LabelsActionItem
            label={label}
            openActions={open}
            toggleOpenActions={setOpen}
            selectedLabels={labels.assignedLabels}
            allLabels={labelsList.data.items}
            labelsCollapseState={labels.collapseAssignLabels}
            onToggleLabelsCollapse={onToggleCollapse}
            setAssignLabelsAction={setAssignLabelsAction}
            dataTest={dataTest}
        />
    );
};

export default LabelsActionItemContainer;
