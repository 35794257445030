import { useDispatch } from 'react-redux'
import { useCallback } from 'react';
import { useGetOverdueLearners } from '../../../../../api/enrollments';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { ListResult } from '../../../../../constants/interfaces/PaginationResult';
import { getOverdueLearnersStart, getOverdueLearnersFailure, getOverdueLearnersSuccess } from './actions';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { getSearchSortingValue } from '../../../../../helpers/functions/search-params-helpers';
import { UserV2 } from '../../../../../constants/interfaces/User';

/**
 * @function useGetOverdueLearnersService
 * @returns { CallableFunction }
 */
export const useGetOverdueLearnersService = () => {
    const dispatch = useDispatch();
    const getOverdueLearnersApi = useGetOverdueLearners();
    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback((searchParams: SearchParams) => {
        const sp = {
            teams: searchParams.teamIds?.join(','),
            labels: searchParams.labelIDs?.join(','),
            limit: searchParams.limit ?? 20,
            offset: searchParams.offset,
            query: searchParams.searchTerm,
            sort: getSearchSortingValue(searchParams.orderBy, 'user_name', true),
            companies: searchParams.companyId
        }
        dispatch(getOverdueLearnersStart());
        return getOverdueLearnersApi(sp)
            .then((data: ListResult<UserV2>) => {
                dispatch(getOverdueLearnersSuccess(data));
            })
            .catch((error: ErrorResult) => {
                dispatch(getOverdueLearnersFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, getOverdueLearnersApi, showMessage]);
}
