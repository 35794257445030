import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Folder = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {shadow && (
                    <defs>
                        <filter
                            id="a"
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <path
                        id="Document"
                        fill="currentColor"
                        d="M18.75 20H1.25C.56 20 0 19.44 0 18.75V1.25C0 .56.56 0 1.25 0H7.5c.69 0 1.25.56 1.25 1.25V2.5h10c.69 0 1.25.56 1.25 1.25v15c0 .69-.56 1.25-1.25 1.25zm-.625-16.25H7.5V1.875c0-.345-.28-.625-.625-.625h-5c-.345 0-.625.28-.625.625V5h16.875c.345 0 .625-.28.625-.626 0-.345-.28-.624-.625-.624zm.625 3.75V6.25H1.25v11.876c0 .345.28.624.625.624h16.25c.346 0 .625-.279.625-.624V7.5z"
                    />
                </g>
            </svg>
        );
    }
);

export default Folder;
