import {
    Container,
    InputWrapper,
    StyledInput,
    IconContainer,
    SearchIcon,
} from './styles';
import { SearchInputViewProps } from './types';

function SearchInputView({
    value,
    onChange,
    onBlur,
    onKeyDown,
    inputRef,
}: SearchInputViewProps) {
    return (
        <Container>
            <IconContainer data-test="filter-search-icon">
                <SearchIcon />
            </IconContainer>
            <InputWrapper>
                <StyledInput
                    ref={inputRef}
                    placeholder="Search"
                    handleChange={onChange}
                    value={value}
                    fontSize="13px"
                    height="24px"
                    autoFocus={true}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    dataTest="filter-search-input"
                />
            </InputWrapper>
        </Container>
    );
}

export default SearchInputView;