import React, { FC } from 'react';
import styled from 'styled-components';

const StyledSelect = styled.div<{
    height?: number;
    padding?: string;
    fontSize?: string;
}>`
    width: 100%;
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: ${props => (!!props.fontSize ? props.fontSize : '13px')};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;

const StyledSelectValue = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const SelectInput: FC<{
    children?: any;
    items?: any;
    value?: string | number;
    height?: number;
    padding?: string;
    toggleSelect?: (e?: any) => void;
    fontSize?: string;
    dataTest?: string;
}> = ({ value, toggleSelect, height, padding, fontSize, dataTest }) => {
    return (
        <StyledSelect
            height={height}
            onClick={toggleSelect}
            padding={padding}
            fontSize={fontSize}
            data-test={dataTest}
        >
            <StyledSelectValue>{value}</StyledSelectValue>
        </StyledSelect>
    );
};
