import { ListResult } from "../../../../../../constants/interfaces/PaginationResult";
import { RepLineType } from "../types";

export enum ACTIONS {
    GET_SCENARIO_REPS_START = 'GET_SCENARIO_REPS_START',
    GET_SCENARIO_REPS_SUCCESS = 'GET_SCENARIO_REPS_SUCCESS',
    GET_SCENARIO_REPS_FAILURE = 'GET_SCENARIO_REPS_FAILURE',
    CLEAR_SCENARIO_REPS = 'CLEAR_SCENARIO_REPS',
}
export function getScenarioRepsStart() {
    return {
        type: ACTIONS.GET_SCENARIO_REPS_START as ACTIONS.GET_SCENARIO_REPS_START,
    };
}
export function getScenarioRepsSuccess(data: ListResult<RepLineType>) {
    return {
        type: ACTIONS.GET_SCENARIO_REPS_SUCCESS as ACTIONS.GET_SCENARIO_REPS_SUCCESS,
        data
    };
}
export function getScenarioRepsFailure(error: string) {
    return {
        type: ACTIONS.GET_SCENARIO_REPS_FAILURE as ACTIONS.GET_SCENARIO_REPS_FAILURE,
        error
    };
}
export function clearScenarioReps() {
    return {
        type: ACTIONS.CLEAR_SCENARIO_REPS as ACTIONS.CLEAR_SCENARIO_REPS,
    };
}