import { FiltersSidebar } from '../../../features/filters/filters-sidebar';
import ROUTES from '../../../routes/routes';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import { UserActivityReportFiltersConfig } from './constants';
import { SubHeader } from './styles';
import { UserActivityReportViewProps } from './types';

function UserActivityReportView({
    onClearFilters, onGenerateReport, controlPanelMode, remainingTimeout
}: UserActivityReportViewProps) {
    return (
        <MainWrapper
            title="Reports"
            subTitle="User Activity"
            subHeader={
                <SubHeader data-test="user-activity-report-page-description">
                    Understand your team(s) progress on all their Practis Sets.
                </SubHeader>
            }
            htmlPageTitle="Reports - Practis"
            goBackUrl={ROUTES.REPORTS.LIST_OF_REPORTS}
            headerPadding="0 24px"
            headerMinHeight="124px"
            contentPadding="0"
            isScrollable={false}
            contentSize={false}
            dataTest="user-activity-report"
        >
            <FiltersSidebar
                filtersConfig={UserActivityReportFiltersConfig}
                onClearFilters={onClearFilters}
                onGenerateReport={onGenerateReport}
                controlPanelMode={controlPanelMode}
                remainingTimeout={remainingTimeout}
                headerHeight="124px"
            />
        </MainWrapper>
    );
}

export default UserActivityReportView;