import styled from 'styled-components';
import LinkText from '../../../ui/components/LinkText/link-text';

export const StyledInputDivs = styled.div<{
    isMobile: boolean;
    marginBottom?: string;
}>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
        flex: 1;
        min-width: 100px;
        margin-bottom: ${props =>
            props.isMobile
                ? '8px'
                : !!props.marginBottom
                ? props.marginBottom
                : '16px'};
    }
`;

export const ForgotPasswordLink = styled(LinkText)`
    padding: 0 0 0 5px;
    text-align: right;
    font-size: 13px;
    display: block;
    height: auto!important;
`;

export const StyledLoginContent = styled.div`
    width: 100%;

    @media(max-height:650px) {
        padding-top: 30px;
    }
`;

export const StyledSeparatorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledSeparator = styled.span<{ isMobile: boolean }>`
    height: 1.2px;
    background: ${props => props.theme.Colors.steelGrey};
    display: inline-block;
    width: ${props => (props.isMobile ? '81px' : '96px')};
`;

export const StyledSeparatorText = styled.span<{ isMobile: boolean }>`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: ${props => (props.isMobile ? '13px' : '15px')};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0 12px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;
