import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    width: calc(100% - 48px);
    height: 72px;
    margin-top: -16px;
    z-index: 9;
    display: flex;
    align-items: center;
    padding: 16px;
    color: ${props => props.theme.Colors.steelGrey};
    background: red;
    border-radius: 4px;
    border: solid 1px #eef0f0;
    background: #eef0f0;
    border-radius: 4px;
`;

export const SelectedWrapper = styled.div`
    margin-left: 16px;
    color: black;
    font-size: 13px;
    line-height: 19px;

    span {
        font-weight: 800;
    }
`;

export const SelectAllWrapper = styled.button`
    color: ${props => props.theme.Colors.mainBlue};
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 4px;
    font-size: 13px;

    span {
        font-weight: 800;
    }

    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
`;

export const ClearWrapper = styled.button`
    color: ${props => props.theme.Colors.mainBlue};
    border: none;
    border-left: 1px solid #cacfcf;
    padding: 1px 0 0 8px;
    margin-left: 8px;
    line-height: 18px;
    font-size: 13px;
    cursor: pointer;
    background: transparent;

    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
`;
