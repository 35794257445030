import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';

import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { useHistory } from 'react-router';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { PractisSets as PractisSetsInterface } from '../../../../constants/interfaces/PractisSets';
import { History } from 'history';
import {
    LIBRARY_STATUS,
    libraryItemsStatusName,
    usePractisSetActionsHelper,
    PractisSetActionsHelper,
    PractisSetActions,
} from '../../tools';
import ROUTES from '../../../../routes/routes';
import { pushModal } from '../../../../tools/router';
import {
    useArchivePractisSetService,
    useDeleteLibraryLabelTag,
    useDeletePractisSetsService,
    useRestorePractisSetService,
    useSearchPractisSetsService,
    useUpdateAllPractisSetsCheckedStateService,
    useUpdateLibraryPractisSetsCheckedStateService,
} from '../../store/services';
import {
    useLibraryPractisSetState,
    useUpdatedLibraryPractisSetsState,
    selectSelectedPractisSets,
} from '../../store/states';
import LibraryNavigationContainer from '../../components/LibraryNavigation';
import { formatDate } from '../../../../helpers/functions/date-convert';
import {
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import {
    ASSIGN_LABEL_OPTIONS,
    LOAD_ASSIGNED_LABELS,
} from '../../../../constants/enums';
import {
    clearSelectedItemIfNotLoaded,
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabels,
} from '../../../labels/tools';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import Box from '../../../../ui/icons/Box';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import { Popup } from '../../../../ui/components/Popup';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { LibraryFilters } from '../../components/Filters/LibraryFilters';
import { WithLabelsContext } from '../../../portableLabels';
import PractisSetsListActions from '../../components/ListActions/PractisSetsListActions';
import { useSessionEffect } from '../../../../features/common';
import { countAppliedFiltersCount } from '../../../../features/teams/pages/ListOfTeams/tools';
import { plural } from '../../../../helpers/functions/plural';
import {
    useAssignedLabelsIdsApi,
    useAssignPractisSetLabelsApi,
    useDeletePractisSetLabelsApi,
} from '../../../../api';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { SeparatingDot, useStyles } from './styles';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useFilter } from '../../../../ui/components/table-wrapper/table/TableDropdown';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import {
    useUpdateLabelsBulkActionService,
    useUpdateMultipleEntityLabelsBulkActionService,
} from '../../../../services/GeneralBulkActionServices';
import { useCreateEditDuplicateLibraryBulActionService } from '../../services/LibraryBulkActionsService';
import { useGenerateDuplicatePractisSetActionList } from '../../services/LibraryBulkActionsService/helpers';
import { generateCopyOfEntityName } from '../../../../services/GeneralBulkActionServices/helpers';
import { NewPractisSetInfo } from '../../services/LibraryBulkActionsService/types';
import { UPDATE_MULTIPLE_LABEL_ACTION } from '../../../../services/GeneralBulkActionServices/constants';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;
const DEFAULT_FILTER_VALUE = [LIBRARY_STATUS.ACTIVE, LIBRARY_STATUS.DRAFT];

const PractisSets: FC<{
    practisSets?: ListResult<PractisSetsInterface>;
    updatedPractisSets?: PractisSetsInterface;
    searchPractisSets(searchParams: SearchParams): void;
    history: History<any>;
    onDeletePractisSets: (
        practisSetIds: number[],
        successCallback?: () => void
    ) => Promise<unknown>;
    onArchivePractisSet: (practisSetIds: number[]) => void;
    onRestorePractisSet: (practisSetIds: number[]) => void;
    selectedLabels?: number[];
    selectedPractisSetIds?: number[];
    selectedPractisSets?: PractisSetsInterface[];
    actionsHelper: PractisSetActionsHelper;
    loading?: boolean;
    setAssignLabelsAction(labels: number[]): void;
    assignedLabels?: number[];
    updateAllPractisSetsCheckedState(
        practisSetIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedPractisSetCheckedState(practisSetId: number): void;
    updatedLabels?: any;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
}> = ({
    practisSets,
    searchPractisSets,
    history,
    onDeletePractisSets,
    onArchivePractisSet,
    onRestorePractisSet,
    updatedPractisSets,
    selectedLabels,
    selectedPractisSetIds,
    selectedPractisSets,
    actionsHelper,
    setAssignLabelsAction,
    assignedLabels,
    updateAllPractisSetsCheckedState,
    updateSelectedPractisSetCheckedState,
    updatedLabels,
    loading,
    saveGlobalLabelFilters,
}) => {
    const location = history.location;
    const classes = useStyles();
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const deleteLibraryLabelTag = useDeleteLibraryLabelTag();
    const assignLabelsToPractisSet = useAssignPractisSetLabelsApi();
    const deleteLabelsFromPractisSet = useDeletePractisSetLabelsApi();

    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const [selectedStatuses, setSelectedStatuses] =
        useState<string[]>(DEFAULT_FILTER_VALUE);
    const [savedStatuses, setSavedStatuses] =
        useState<string[]>(DEFAULT_FILTER_VALUE);

    const canAccessPage = useHandleAccessPage();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [{ field: 'status', value: DEFAULT_FILTER_VALUE }],
        labelIDs: selectedLabels,
        orderBy: {
            field: 'updated_at',
            asc: false,
        },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        refreshSearchParams,
        setFilter,
    } = useSearchParamsState(initialSearchParams);

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchPractisSets(searchParams);
        setLastRefreshed(new Date());
        updateAllPractisSetsCheckedState([], false, false);
        setIsSelectAll(false);
    }, [
        searchParams,
        initialOffset,
        searchPractisSets,
        updateAllPractisSetsCheckedState,
    ]);

    const handleItemEdit = (practisSet: PractisSetsInterface) => {
        updateAllPractisSetsCheckedState([], false, false);
        pushModal(
            history,
            ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE.replace(
                ':practisSetId',
                practisSet.id!.toString()
            )
        );
    };

    const handleViewPractisSetProgress = (practisSet: PractisSetsInterface) => {
        history.push(
            ROUTES.PROGRESS_PAGES.PRACTIS_SETS_DETAILS.replace(
                ':practisSetId',
                practisSet.id!.toString()
            )
        );
    };

    /**
     * @function onConfirmDeletePractisSet
     * @param { number } practisSetIdToDelete
     * @returns { void }
     */
    const onConfirmDeletePractisSet = useCallback(
        (practisSetIdToDelete: number): void => {
            onDeletePractisSets(
                [practisSetIdToDelete!],
                refreshSearchParams
            );
        },
        [onDeletePractisSets, refreshSearchParams]
    );

    /**
     * @function handleDeletePractisSet
     * @returns { void }
     */
    const handleDeletePractisSet = useCallback((id: number): void => {
        showConfirmationModalDialog({
            modalTitle: 'Are You Sure?',
            description:
                'Are you sure you want to delete this item? This can’t be undone',
            onConfirm: () => onConfirmDeletePractisSet(id),
        });
    }, [onConfirmDeletePractisSet, showConfirmationModalDialog]);

    const isCheckboxPartiallySelected =
        practisSets &&
        practisSets.items &&
        selectedPractisSets &&
        selectedPractisSets.length < practisSets.items.length;

    const updateAllPractisSetsCheck = useCallback(
        (checked: boolean) => {
            const ids =
                (practisSets &&
                    practisSets.items.map(
                        (item: PractisSetsInterface) => item.id as number
                    )) ||
                ([] as number[]);
            updateAllPractisSetsCheckedState(
                ids,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [
            practisSets,
            isCheckboxPartiallySelected,
            updateAllPractisSetsCheckedState,
        ]
    );

    const handlePractisSetCheck = (e: any, set: PractisSetsInterface) => {
        e.stopPropagation();
        if (!!set.id) {
            updateSelectedPractisSetCheckedState(set.id);
        }
    };

    const getDeletedLabels = useCalculateDeletedLabels();

    const updateMultiplePractisSetLabels =
        useUpdateMultipleEntityLabelsBulkActionService(
            assignLabelsToPractisSet,
            deleteLabelsFromPractisSet,
            isSelectAll,
            {
                actionName: UPDATE_MULTIPLE_LABEL_ACTION,
                actionFunction: searchPractisSets,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            limit: 1,
                            offset: practisSets?.count ?? 0,
                        },
                        shouldSetStore: false,
                    },
                    fieldName: 'searchParams.offset',
                },
            },
            refreshData
        );

    const assignLabels = async () => {
        if (assignedLabels && selectedPractisSetIds) {
            const { preAssignedLabels } = await getAssignedLabelsIds(
                selectedPractisSetIds,
                LOAD_ASSIGNED_LABELS.PRACTIS_SETS_SCREEN_LIBRARY,
                ASSIGN_LABEL_OPTIONS.PRACTIS_SET,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                return calculatePreSelectedLabels(
                    selectedPractisSetIds,
                    practisSets,
                    data
                );
            });

            const deletedLabels = getDeletedLabels(assignedLabels);

            const deletedLabelsFiltered = deletedLabels.filter(
                x =>
                    preAssignedLabels.includes(x) ||
                    preAssignedLabels.includes(-x)
            );
            const selectedLabelsFiltered = assignedLabels.filter(
                x => x > 0 && !preAssignedLabels.includes(x)
            );

            updateMultiplePractisSetLabels(
                {
                    entityName: 'practisSet',
                    entityIds: selectedPractisSetIds,
                },
                selectedLabelsFiltered,
                deletedLabelsFiltered
            );
        }
    };

    const orderBy = searchParams.orderBy;
    const hcName = useOrderBy('name', orderBy, setOrderBy);
    const hcStatus = useOrderBy('status', orderBy, setOrderBy);
    const hcTotalCount = useOrderBy('content_count', orderBy, setOrderBy);
    const hcLastUpdated = useOrderBy('updated_at', orderBy, setOrderBy);
    const handleChangeEvent = useFilter('status', setFilter);

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const onSelectStatus = (status: string[]) => {
        setSelectedStatuses(status);
    };
    const onSaveStatus = (status: string[]) => {
        setSavedStatuses(status);
    };

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters:
            countAppliedFiltersCount(searchParams) +
            (savedStatuses?.length || 0),
        itemsCount: practisSets?.items.length || 0,
    });

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedPractisSets),
        [selectedPractisSets, actionsHelper]
    );

    /**
     * @function handleViewAssignUsers
     * @param { number } practisSetId
     * @returns { void }
     */
    const handleViewAssignUsers = useCallback(
        (practisSetId?: number): void => {
            if (!practisSetId) return;

            pushModal(
                history,
                ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ASSIGN_USERS.replace(
                    ':practisSetId',
                    practisSetId.toString()
                )
            );
        },
        [history]
    );

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchPractisSets(searchParams);
    }, [searchPractisSets, searchParams]);

    useEffect(() => {
        if (updatedPractisSets) refreshSearchParams();
    }, [updatedPractisSets, refreshSearchParams, updatedLabels]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (
            selectedPractisSets?.length &&
            selectedPractisSets.length === practisSets?.count
        ) {
            setIsSelectAll(true);
        }
    }, [practisSets?.count, selectedPractisSets]);

    useEffect(() => {
        const filteredUsers = practisSets
            ? practisSets.items.map(practisSet => Number(practisSet.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedPractisSetIds,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllPractisSetsCheckedState(usersToClear, false);
        }
    }, [selectedPractisSetIds, practisSets, updateAllPractisSetsCheckedState]);

    const getAssignedLabelsIds = useAssignedLabelsIdsApi();

    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToPractisSet,
        deleteLabelsFromPractisSet,
        refreshData
    );

    /**
     * @function handleUpdatePractisSetLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { number } practisSetId
     * @returns { void }
     */
    const handleUpdatePractisSetLabels = (
        addedLabelIds: number[],
        deletedLabelIds: number[],
        practisSetId: number
    ): void => {
        updateLabelsBulkActionService(
            {
                entityName: 'practisSet',
                entityId: practisSetId,
            },
            addedLabelIds,
            deletedLabelIds
        );
    };

    /**
     * @function onOpenAssignLabelAction
     * @returns { void }
     */
    const onOpenAssignLabelAction = (): void => {
        if (practisSets && practisSets.items && selectedPractisSetIds) {
            getAssignedLabelsIds(
                selectedPractisSetIds,
                LOAD_ASSIGNED_LABELS.PRACTIS_SETS_SCREEN_LIBRARY,
                ASSIGN_LABEL_OPTIONS.PRACTIS_SET,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                const { preAssignedLabels } = calculatePreSelectedLabels(
                    selectedPractisSetIds,
                    practisSets,
                    data
                );

                setAssignLabelsAction(preAssignedLabels);
            });
        }
    };

    useEffect(() => {
        return () => updateAllPractisSetsCheckedState([], false, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllPractisSetsCheckedState([], false, false);
    }, [practisSets, updateAllPractisSetsCheckedState]);

    useEffect(() => {
        handleChangeEvent(savedStatuses);
    }, [savedStatuses, handleChangeEvent]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_PRACTIS_SET]);
    }, [canAccessPage]);

    useEffect(() => {
        updateAllPractisSetsCheckedState([], false, false);
        setIsSelectAll(false);
    }, [location.pathname, updateAllPractisSetsCheckedState]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllPractisSetsCheckedState([], false, false);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        const ids =
            (practisSets &&
                practisSets.items.map(
                    (item: PractisSetsInterface) => item.id as number
                )) ||
            ([] as number[]);
        updateAllPractisSetsCheckedState(
            ids,
            true,
            isCheckboxPartiallySelected
        );
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllPractisSetsCheck(event.target.checked);
        setIsSelectAll(false);
    };

    const isMasterCheckBoxChecked =
        !!selectedPractisSets && !!selectedPractisSets.length;

    const isMasterCheckBoxDisabled =
        !practisSets || (practisSets && practisSets.items.length < 1);

    /**
     * @function checkIsRowChecked
     * @param { PractisSetsInterface } set
     * @returns { boolean }
     */
    const checkIsRowChecked = (set: PractisSetsInterface): boolean =>
        !!selectedPractisSetIds &&
        !!set.id &&
        selectedPractisSetIds.includes(set.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { PractisSetsInterface } set
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, set: PractisSetsInterface) => {
        setIsSelectAll(false);
        handlePractisSetCheck(event, set);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { PractisSetsInterface } set
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (set: PractisSetsInterface): boolean => {
        return labelsOpen === set?.['id'];
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { PractisSetsInterface } set
     * @returns { void }
     */
    const onDeleteLabelHandler = useCallback(
        (labelId: number, set: PractisSetsInterface): void => {
            const practisSetItem =
                practisSets &&
                practisSets.items.find(item => item.id === set.id);

            if (!isEmpty(practisSetItem)) {
                deleteLibraryLabelTag(
                    labelId,
                    {
                        entityItem: practisSetItem!,
                        entityName: 'practisSet',
                    },
                    refreshData
                );
            }
        },
        [deleteLibraryLabelTag, practisSets, refreshData]
    );

    const generateDuplicatePractisSetActionList =
        useGenerateDuplicatePractisSetActionList();

    const duplicateLibraryBulkActionService =
        useCreateEditDuplicateLibraryBulActionService(
            'duplicate',
            'Practis Set',
            refreshData
        );

    /**
     * @function handleDuplicatePractisSet
     * @param { PractisSetsInterface } practisSet
     * @returns { void }
     */
    const handleDuplicatePractisSet = useCallback(
        (practisSet: PractisSetsInterface) => {
            const newPractisSet: NewPractisSetInfo = {
                currentPractisSetId: practisSet.id ?? 0,
                name: generateCopyOfEntityName(practisSet.name),
                description: practisSet.description,
                pacingId: practisSet?.pacing?.id ?? 0,
                labelIds: practisSet?.labels ?? [],
                scenarioCount: practisSet.scenarioCount,
                challengeCount: practisSet.challengeCount,
            };

            const duplicatePractisSetActionList =
                generateDuplicatePractisSetActionList(newPractisSet);

            !isEmpty(duplicatePractisSetActionList) &&
                duplicateLibraryBulkActionService([
                    duplicatePractisSetActionList,
                ]);
        },
        [
            duplicateLibraryBulkActionService,
            generateDuplicatePractisSetActionList,
        ]
    );

    return (
        <MainWrapper
            subTitle="Library"
            tabs={<LibraryNavigationContainer />}
            hideDivider={!!selectedPractisSets?.length}
            htmlPageTitle="Library - Practis"
            dataTest="library"
        >
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={practisSets?.items}
                selectedLabels={selectedLabels}
                cornered={selectedLabels && !!selectedLabels.length}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'library-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: practisSets?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'library-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'library-search-input',
                    },
                    isSelectedItemOptionsVisible:
                        !isEmpty(selectedPractisSets) && !isEmpty(practisSets),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedPractisSets?.length,
                        totalCount: practisSets?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled: bulkActions.areBulkActionsDisabled(),
                            disabledLabel: bulkActions.areItemsEmpty()
                                ? undefined
                                : "Bulk actions can't be applied",
                            onOpenAssignLabelAction: onOpenAssignLabelAction,
                            assignLabelsPermissions:
                                bulkActions.isBulkActionVisible(
                                    PractisSetActions.ASSIGN_LABELS
                                )
                                    ? [NEW_PERMISSIONS.ASSIGN_PRACTIS_SET_LABEL]
                                    : [],
                            isAssignLabelsDisabled:
                                bulkActions.isBulkActionDisabled(
                                    PractisSetActions.ASSIGN_LABELS
                                ),
                            onAssignLabelsSubmit: assignLabels,
                            isSelectAll: isSelectAll,
                            totalCount: practisSets?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <LibraryFilters
                                        selectedStatuses={
                                            practisSets ? selectedStatuses : []
                                        }
                                        savedStatuses={savedStatuses}
                                        handleSelectStatus={onSelectStatus}
                                        handleSaveStatus={onSaveStatus}
                                        preSelectedLabels={selectedLabels || []}
                                        onLabelsChange={labelChangeHandler}
                                        onSuccessApply={() => hide()}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={250}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={
                                            practisSets
                                                ? savedStatuses.length +
                                                  (searchParams.labelIDs
                                                      ?.length || 0)
                                                : 0
                                        }
                                        disabled={tableStates.disableFilters}
                                        dataTest="library-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && practisSets?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Box,
                        text: 'No Practis Sets Yet',
                        dataTest: 'library-no-practis-sets',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Practis Sets',
                        dataTest: 'library-no-found-practis-sets',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'library-no-filtered-practis-sets',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'library-practis-sets-master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Practis Sets',
                            width: 25,
                            ...hcName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-practis-sets-title-column',
                        },
                        {
                            title: 'Status',
                            width: 11,
                            ...hcStatus,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-practis-sets-status-column',
                        },
                        {
                            title: 'Content',
                            width: 11,
                            ...hcTotalCount,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-practis-sets-content-column',
                        },
                        {
                            title: 'Last Updated',
                            width: 10,
                            ...hcLastUpdated,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-practis-sets-date-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],

                    rowConfig: {
                        onRowClick: handleItemEdit,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (set: PractisSetsInterface) => ({
                            selectedLabels: set?.labels ?? [],
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_PRACTIS_SET_LABEL,
                            ],
                            onDeleteLabel: (labelId: number) =>
                                onDeleteLabelHandler(labelId, set),
                        }),
                        dataTest: 'library-practis-sets-item',

                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (set: PractisSetsInterface) =>
                                        set?.name,
                                    dataTest: 'library-practis-sets-item-title',
                                },
                            },
                            {
                                fieldType: 'BADGE_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    set: PractisSetsInterface
                                ) => !set?.status,
                                fieldProps: {
                                    getBadgeFieldProps: (
                                        set: PractisSetsInterface
                                    ) => ({
                                        status: set?.status,
                                        className: classes.customTableCellStyle,
                                        dataTest:
                                            'library-practis-sets-item-status',
                                    }),
                                    renderTitle: (set: PractisSetsInterface) =>
                                        libraryItemsStatusName(set?.status),
                                },
                            },

                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    set: PractisSetsInterface
                                ) =>
                                    !set?.scenarioCount && !set?.challengeCount,
                                fieldProps: {
                                    renderTitle: (
                                        set: PractisSetsInterface
                                    ) => (
                                        <>
                                            {set.scenarioCount
                                                ? `${
                                                      set.scenarioCount
                                                  } ${plural(
                                                      set.scenarioCount,
                                                      'Scenario',
                                                      'Scenarios'
                                                  )}`
                                                : null}
                                            {!!set.scenarioCount &&
                                                !!set.challengeCount && (
                                                    <SeparatingDot></SeparatingDot>
                                                )}
                                            {set.challengeCount
                                                ? `${
                                                      set.challengeCount
                                                  } ${plural(
                                                      set.challengeCount,
                                                      'Challenge',
                                                      'Challenges'
                                                  )}`
                                                : null}
                                        </>
                                    ),
                                    dataTest:
                                        'library-practis-sets-item-content',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    set: PractisSetsInterface
                                ) => !set?.updatedAt,
                                fieldProps: {
                                    renderTitle: (set: PractisSetsInterface) =>
                                        formatDate(set?.updatedAt),
                                    dataTest: 'library-practis-sets-item-date',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (
                                        set: PractisSetsInterface
                                    ) => ({
                                        open: labelsOpen === set.id,
                                        toggleOpen: () =>
                                            handleToggleLabels(set.id),
                                        selectedLabels: set.labels ?? [],
                                        dataTest:
                                            'library-practis-sets-item-labels',
                                    }),
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (
                                        set: PractisSetsInterface
                                    ) => (
                                        <PractisSetsListActions
                                            practisSet={set}
                                            actionsHelper={actionsHelper}
                                            onRestorePractisSet={
                                                onRestorePractisSet
                                            }
                                            handleItemEdit={handleItemEdit}
                                            handleViewPractisSet={
                                                handleItemEdit
                                            }
                                            handleViewPractisSetProgress={
                                                handleViewPractisSetProgress
                                            }
                                            handleDeletePractisSet={
                                                handleDeletePractisSet
                                            }
                                            onDuplicatePractisSet={
                                                handleDuplicatePractisSet
                                            }
                                            onArchivePractisSet={
                                                onArchivePractisSet
                                            }
                                            handleViewAssignUsers={
                                                handleViewAssignUsers
                                            }
                                            assignLabelsToLibrary={(
                                                addedLabelIds,
                                                deletedLabelIds
                                            ) =>
                                                handleUpdatePractisSetLabels(
                                                    addedLabelIds,
                                                    deletedLabelIds,
                                                    set?.id ?? 0
                                                )
                                            }
                                            isHidden={
                                                !!selectedPractisSets?.length
                                            }
                                        />
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const PractisSetsContainer: FC = () => {
    const state = useLibraryPractisSetState();
    const history = useHistory();
    const deletePractisSets = useDeletePractisSetsService();
    const archivePractisSet = useArchivePractisSetService();
    const restorePractisSet = useRestorePractisSetService();
    const searchPractisSets = useSearchPractisSetsService();
    const updatedPractisSets = useUpdatedLibraryPractisSetsState();
    const searchPractisSetsDebounced = useSearchDebounced(searchPractisSets);

    const labels = useLabelsState();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const updateAllPractisSetsCheckedState =
        useUpdateAllPractisSetsCheckedStateService();
    const updateSelectedPractisSetCheckedState =
        useUpdateLibraryPractisSetsCheckedStateService();
    const updatedLabels = useUpdatedLabelsState();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const actionsHelper = usePractisSetActionsHelper();

    const selectedPractisSets = useMemo(
        () => selectSelectedPractisSets(state),
        [state]
    );

    return (
        <WithLabelsContext.Provider
            value={{ reducerName: 'libraryPractisSets' }}
        >
            <PractisSets
                onDeletePractisSets={deletePractisSets}
                onArchivePractisSet={archivePractisSet}
                onRestorePractisSet={restorePractisSet}
                history={history}
                searchPractisSets={searchPractisSetsDebounced}
                practisSets={state.data}
                loading={state.loading}
                selectedPractisSetIds={state.selectedPractisSets}
                selectedPractisSets={selectedPractisSets}
                actionsHelper={actionsHelper}
                updatedPractisSets={updatedPractisSets.data}
                selectedLabels={labels.selected}
                setAssignLabelsAction={setAssignLabelsAction}
                assignedLabels={labels.assignedLabels}
                updateAllPractisSetsCheckedState={
                    updateAllPractisSetsCheckedState
                }
                updateSelectedPractisSetCheckedState={
                    updateSelectedPractisSetCheckedState
                }
                updatedLabels={updatedLabels}
                saveGlobalLabelFilters={saveGlobalLabelFilters}
            />
        </WithLabelsContext.Provider>
    );
};


export default PractisSetsContainer;