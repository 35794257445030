import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Textarea } from '../Textarea/Textarea';
import { Variables } from '../../../theme/variables';
import { getIn } from 'formik';

const StyledFormikField = styled.div<{ marginRight?: string }>`
    ${props => !!props.marginRight && `margin-right: ${props.marginRight};`}
`;

const StyledTextareaContainer = styled.div<{
    height?: string;
    width?: string;
    disabled?: boolean;
    color: string;
    background?: string;
    padding?: string;
    maxHeight?: string;
    hasError?: boolean;
}>`
    ${props => (!!props.width ? `min-width: ${props.width}` : '')};
    position: relative;
    border-radius: 4px;
    padding: ${props => props.padding || '15px 23px'};
    background: ${props =>
        !!props.background
            ? props.background
            : props.disabled
            ? Variables.Colors.whiteTwo
            : props.color};
    border: 1px solid ${props =>
        props.hasError
            ? 'var(--ps-red-main)'
            : !!props.background
                ? props.background
                : props.disabled
                ? Variables.Colors.whiteTwo
                : props.color};
    max-height:  ${props => props.maxHeight || 'auto'};
`;

const StyledLabel = styled.div<{
    focused: boolean;
    titleFontColor?: string;
    titleFontWeight?: string;
}>`
    font-size: 13px;
    margin-bottom: 2px;
    color: ${props =>
        !!props.titleFontColor
            ? props.titleFontColor
            : props.theme.Colors.steelGrey};
    font-weight: ${props =>
        !!props.titleFontWeight ? props.titleFontWeight : 'normal'};
`;

const StyledCounter = styled.div<{ fontSize?: string }>`
    font-size: ${props => (!!props.fontSize ? props.fontSize : '11px')};
    font-weight: normal;
    text-align: right;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const ErrorContainer = styled.div<{ fontSize?: string }>`
    font-size: ${props => (!!props.fontSize ? props.fontSize : '13px')};
    color: var(--ps-red-main);
    font-weight: 500;
`;

export const TextAreaBox: FC<{
    label?: string;
    value?: string;
    field?: any;
    form?: { touched: any; errors: any };
    name?: any;
    onChange?: any;
    type?: string;
    max?: number;
    width?: string;
    onBlur?: any;
    background?: string;
    color?: string;
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    readOnly?: boolean;
    padding?: string;
    fontSize?: string;
    marginTop?: string;
    titleFontColor?: string;
    titleFontWeight?: string;
    minRows?: number;
    marginRight?: string;
    className?: string;
    hideCounter?: boolean;
    counterFontSize?: string;
    maxHeight?: string
    dataTest?: string
}> = props => {
    const [focused, setFocused] = useState(false);
    const error = props.form && props.field ? getIn(props.form.errors, props.field.name) : undefined;
    const touched = props.form && props.field ? getIn(props.form.touched, props.field.name) : undefined;

    const onFocus = () => {
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) props.onBlur();
    };

    const TextAreaStyles = {
        flex: 1,
        fontSize: `${!!props.fontSize ? props.fontSize : '13px'}`,
        marginTop: `${!!props.marginTop ? props.marginTop : '0'}`,
    };

    return (
        <StyledFormikField marginRight={props.marginRight}>
            <StyledTextareaContainer
                width={props.width}
                disabled={props.disabled}
                color={props.color ? props.color : Variables.Colors.white}
                background={props.background}
                padding={props.padding}
                maxHeight={props.maxHeight}
                hasError={!!error && !!touched}
            >
                <StyledLabel
                    focused={focused}
                    titleFontColor={props.titleFontColor}
                    titleFontWeight={props.titleFontWeight}
                >
                    {props.label}
                </StyledLabel>
                <Textarea
                    value={props.value}
                    styles={TextAreaStyles}
                    {...props}
                    {...props.field}
                    label={props.label}
                    placeholder={props.placeholder}
                    max={props.max}
                    onChange={props.onChange ?? props.field.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    readOnly={props.readOnly}
                    minRows={props.minRows}
                    fontSize={props.fontSize}
                    dataTest={props.dataTest}
                />
                {props.max && !props.hideCounter && (
                    <StyledCounter fontSize={props.counterFontSize} data-test={`${props.dataTest}-counter`}>
                        {props.value ? props.value.length : '0'}/{props.max}
                    </StyledCounter>
                )}
            </StyledTextareaContainer>
            {error && touched && (
                <ErrorContainer data-test={`${props.dataTest}-error`}>
                    {error}
                </ErrorContainer>
            )}
        </StyledFormikField>
    );
};

export default TextAreaBox;
