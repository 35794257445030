import styled from 'styled-components';
import Checkbox from '../../../../../../ui/components/Checkbox';

export const ChildContainer = styled.div`
    margin-left: 15px;
    width: calc(100% - 15px);
`;

export const StyledCheckbox = styled(Checkbox)`
    height: 100%;
`;

export const Container = styled.div`
    padding: 4px 0;
    display: flex;
    align-items: center;
    width: 100%;
`;

export const ArrowContainer = styled.div`
    width: 8px;
    margin-right: 9px;
    cursor: pointer;
`;

export const CollapseArrow = styled.div`
    height: 8px;
    display: flex;
    color: var(--ps-grey-2)
`;

export const NameInput = styled.input<{ isSelected: boolean, isDisabled: boolean, hasChildren: boolean }>`
    color: ${props => props.isDisabled ? 'var(--ps-grey-1)' : 'var(--ps-black-main)'};
    font-size: 13px;
    font-weight: ${props => props.isSelected ? 'bold' : 'normal'};
    text-overflow: ellipsis;
    margin-left: 8px;
    border: 0;
    white-space: nowrap;
    cursor: ${props => props.hasChildren ? 'pointer' : 'default'};
    width: 100%;
`;
