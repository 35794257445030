import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const TriangleDown = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 8 5"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M3.6 4.8l-3-4C.434.58.48.266.7.1.787.035.892 0 1 0h6c.276 0 .5.224.5.5 0 .108-.035.213-.1.3l-3 4c-.166.22-.48.266-.7.1-.038-.028-.072-.062-.1-.1z"
                />
            </svg>
        );
    }
);

export default TriangleDown;
