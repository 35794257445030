import { Action } from 'redux';
import { SearchableFilterHeaderKey } from './types';

export enum ACTIONS {
    SET_FILTER_HEADER_SEARCH_ACTIVE = 'SET_FILTER_HEADER_SEARCH_ACTIVE',
    SET_FILTER_HEADER_SEARCH_TERM = 'SET_FILTER_HEADER_SEARCH_TERM',
}

export type SetFilterHeaderSearchActiveAction = Action<ACTIONS.SET_FILTER_HEADER_SEARCH_ACTIVE> & {
    key: SearchableFilterHeaderKey;
    isSearchActive: boolean;
};

export const setFilterHeaderSearchActive = (
    key: SearchableFilterHeaderKey,
    isSearchActive: boolean
): SetFilterHeaderSearchActiveAction => ({
    type: ACTIONS.SET_FILTER_HEADER_SEARCH_ACTIVE,
    key,
    isSearchActive,
});

export type SetFilterHeaderSearchTermAction = Action<ACTIONS.SET_FILTER_HEADER_SEARCH_TERM> & {
    key: SearchableFilterHeaderKey;
    searchTerm: string;
};

export const setFilterHeaderSearchTerm = (
    key: SearchableFilterHeaderKey,
    searchTerm: string
): SetFilterHeaderSearchTermAction => ({
    type: ACTIONS.SET_FILTER_HEADER_SEARCH_TERM,
    key,
    searchTerm,
});


export type SearchableFilterHeaderActions =
    SetFilterHeaderSearchActiveAction | SetFilterHeaderSearchTermAction;