import styled, { css } from 'styled-components';

export const PagerCount = styled.div`
    display: flex;
    align-items: center;
    color: var(--ps-grey-1);
    margin-right: 8px;
    font-size: 13px;
`;

export const Button = styled.button<{ disabled: boolean }>`
    width: 40px;
    height: 40px;
    margin: 0 0 0 16px;
    border-radius: 4px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
    flex-shrink: 0;
    background: #fff;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${props => (props.disabled ? '0.6' : '1')};

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                border: solid 1px ${props => props.theme.Colors.cloudyBlue};
            }

            &:active {
                border: solid 1px ${props => props.theme.Colors.steelGrey};
            }
        `}
`;

export const IconContainer = styled.div`
    width: 6px;
    height: 12px;

    path {
        stroke: ${props => props.theme.Colors.steelGrey};
    }

    .rotate {
        transform: rotate(180deg);
    }
`;