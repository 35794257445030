import styled from 'styled-components';

export const Wrapper = styled.div<{
    withoutBorder?: boolean;
}>`
    position: relative;
    padding: ${props => (props.withoutBorder ? `0px` : `28px 24px 24px 24px`)};
    border: solid ${props => (props.withoutBorder ? `0px` : `1px`)} ${props => props.theme.Colors.paleGrey};  
    border-radius: ${props => (props.withoutBorder ? `0px` : `4px`)};
    background-color: inherit;
    width: 100%;
    ${props =>
        props.withoutBorder &&
        `border-bottom: solid 1px ${props.theme.Colors.paleGrey};`}
`;

export const Title = styled.span`
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: -10px;
    left: 18px;
    z-index: 1;
    padding: 0 6px;
    font-weight: bold;
    background-color: inherit;
    color: ${props => props.theme.Colors.black};
`;
