import { isEmpty } from 'lodash';

import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import {
    getFullLibraryPractisSetsFailure,
    getFullLibraryPractisSetsStart,
    getFullLibraryPractisSetsSuccess,
    searchLibraryChallengesFailure,
    searchLibraryChallengesStart,
    searchLibraryChallengesSuccess,
    searchLibraryPractisSetsFailure,
    searchLibraryPractisSetsStart,
    searchLibraryPractisSetsSuccess,
    searchLibraryScenariosFailure,
    searchLibraryScenariosStart,
    searchLibraryScenariosSuccess,
    setSearchTermForLibraryPractisSet,
    updateAllChallengesCheckedState,
    updateAllPractisSetsCheckedState,
    updateAllScenarioCheckedState,
    updateAssignedLibraryPractisSet,
    updateLibraryChallengeCheckedState,
    updateLibraryChallengeFailure,
    updateLibraryChallengeStart,
    updateLibraryChallengeSuccess,
    updateLibraryPractisSetCheckedState,
    updateLibraryPractisSetFailure,
    updateLibraryPractisSetStart,
    updateLibraryPractisSetSuccess,
    updateLibraryScenarioCheckedState,
    updateLibraryScenarioFailure,
    updateLibraryScenarioStart,
    updateLibraryScenarioSuccess,
    updateSeparateAssignedLibraryPractisSet,
} from './actions';
import { SearchParams } from '../../../constants/interfaces/filters';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    useDeleteChallengesApi,
    useDeletePractisSetsApi,
    useDeleteScenariosApi,
    useDownloadScenarioReportApi,
    useSearchChallengesApi,
    useSearchPractisSetsApi,
    useSearchScenariosApi,
    useDeletePractisSetLabelsApi,
    useDeleteScenarioLabelApi,
    useDeleteChallengeLabelsApi,
    useUpdatePractisSetStatusApi,
    useUpdateScenarioStatusApi,
    useUpdateChallengeStatusApi,
} from '../../../api';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { Challenge } from '../../../constants/interfaces/Challenge';
import { useHistory } from 'react-router';
import ROUTES from '../../../routes/routes';
import { LocationState, pushModal } from '../../../tools/router';
import { History } from 'history';
import { fileDownload } from '../../../helpers/functions/file-download';
import {plural} from "../../../helpers/functions/plural";
import { createSearchLibraryParams } from '../tools';
import { LibraryEntity } from './types';
import { usePortableLabelsState } from '../../portableLabels/store/states';
import { findAllChildIds } from '../../../helpers/functions/tree-helpers';
import { CREATE_CHALLENGE_ACTION } from '../services/LibraryBulkActionsService/constants';

export const useSearchPractisSetsService = () => {
    const dispatch = useDispatch();
    const searchPractisSetsApi = useSearchPractisSetsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams, shouldSetStore = true) => {

            const searchPractisSetsParams =
                createSearchLibraryParams(searchParams);

            shouldSetStore && dispatch(searchLibraryPractisSetsStart());
            return searchPractisSetsApi(searchPractisSetsParams)
                .then(data => {
                    if (shouldSetStore) {
                        dispatch(searchLibraryPractisSetsSuccess(data));
                    }

                    return data.items;
                })
                .catch((error: ErrorResult) => {
                    if (shouldSetStore) {
                        dispatch(
                            searchLibraryPractisSetsFailure(error.message)
                        );
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, searchPractisSetsApi, showMessage]
    );
};

export const useGetFullPractisSetsService = () => {
    const dispatch = useDispatch();
    const searchPractisSetsApi = useSearchPractisSetsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams) => {
            const searchPractisSetsParams =
            createSearchLibraryParams(searchParams);

            dispatch(getFullLibraryPractisSetsStart());
            searchPractisSetsApi(searchPractisSetsParams)
                .then(data => {
                    dispatch(getFullLibraryPractisSetsSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(getFullLibraryPractisSetsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchPractisSetsApi, showMessage]
    );
};

export const useUpdateAssignedLibraryPractisSetService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (assignedPractisSets: number[]) => {
            dispatch(updateAssignedLibraryPractisSet(assignedPractisSets));
        },
        [dispatch]
    );
};

export const useUpdateSeparateAssignedLibraryPractisSetService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (assignedPractisSets: number[]) => {
            dispatch(
                updateSeparateAssignedLibraryPractisSet(assignedPractisSets)
            );
        },
        [dispatch]
    );
};

export const useSetSearchTermForLibraryPractisSetService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: string) => {
            dispatch(setSearchTermForLibraryPractisSet(value));
        },
        [dispatch]
    );
};

/**
 * @function useDeletePractisSetsService
 * @returns { CallableFunction }
 */
export const useDeletePractisSetsService = () => {
    const deletePractisSetsApi = useDeletePractisSetsApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number[] } practisSetIds
     * @param { Function } successCallback
     * @returns { Promise }
     */
     return useCallback(
        (practisSetIds: number[], successCallback?: () => void) => {
            return deletePractisSetsApi(practisSetIds)
                .then(() => {
                    const message = `${practisSetIds.length} Practis ${plural(practisSetIds.length, 'Set has', 'Sets have')} been deleted`;
                    showMessage(message, 'success');

                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [deletePractisSetsApi, showMessage]
    );
};

export const useArchivePractisSetService = () => {
    const dispatch = useDispatch();
    const updatePractisSetStatusApi = useUpdatePractisSetStatusApi();
    const showMessage = useShowMessage();
    return useCallback(
        (practisSetIds: number[]) => {
            dispatch(updateLibraryPractisSetStart());
            return updatePractisSetStatusApi('archive', practisSetIds)
                .then(data => {
                    dispatch(
                        dispatch(updateLibraryPractisSetSuccess(data, 'update'))
                    );

                    showMessage('Practis Set has been archived', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLibraryPractisSetFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updatePractisSetStatusApi, showMessage]
    );
};

export const useRestorePractisSetService = () => {
    const dispatch = useDispatch();
    const updatePractisSetStatusApi = useUpdatePractisSetStatusApi();
    const showMessage = useShowMessage();
    return useCallback(
        (practisSetIds: number[]) => {
            dispatch(updateLibraryPractisSetStart());
            return updatePractisSetStatusApi('draft', practisSetIds)
                .then(data => {
                    dispatch(
                        dispatch(updateLibraryPractisSetSuccess(data, 'update'))
                    );

                    showMessage('Practis Set has been restored', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLibraryPractisSetFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updatePractisSetStatusApi, showMessage]
    );
};

export const useSearchScenariosService = () => {
    const dispatch = useDispatch();
    const searchScenariosApi = useSearchScenariosApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams, shouldSetStore = true) => {
            const searchScenariosParams =
                createSearchLibraryParams(searchParams);

            shouldSetStore && dispatch(searchLibraryScenariosStart());

            return searchScenariosApi(searchScenariosParams)
                .then(data => {
                    if (shouldSetStore) {
                        dispatch(searchLibraryScenariosSuccess(data));
                    }

                    return data.items;
                })
                .catch((error: ErrorResult) => {
                    if (shouldSetStore) {
                        dispatch(searchLibraryScenariosFailure(error.message));
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, searchScenariosApi, showMessage]
    );
};

/**
 * @function useDeleteScenariosService
 * @returns { CallableFunction }
 */
export const useDeleteScenariosService = () => {
    const deleteScenariosApi = useDeleteScenariosApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number[] } scenarioIds
     * @param { Function } successCallback
     * @returns { Promise }
     */
     return useCallback(
        (scenarioIds: number[], successCallback?: () => void) => {
            return deleteScenariosApi(scenarioIds)
                .then(data => {
                    const message = `${scenarioIds.length} ${plural(scenarioIds.length, 'Scenario has', 'Scenarios have')} been deleted`;
                    showMessage(message, 'success');

                    successCallback?.();
                    return data;
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [deleteScenariosApi, showMessage]
    );
};


export const useArchiveScenarioService = () => {
    const dispatch = useDispatch();
    const updateScenarioStatusApi = useUpdateScenarioStatusApi();
    const showMessage = useShowMessage();
    return useCallback(
        (scenarioIds: number[]) => {
            dispatch(updateLibraryScenarioStart());
            return updateScenarioStatusApi('archive', scenarioIds)
                .then(data => {
                    dispatch(
                        dispatch(updateLibraryScenarioSuccess(data, 'update'))
                    );

                    showMessage('Scenario has been archived', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLibraryScenarioFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updateScenarioStatusApi, showMessage]
    );
};

export const useRestoreScenarioService = () => {
    const dispatch = useDispatch();
    const updateScenarioStatusApi = useUpdateScenarioStatusApi();
    const showMessage = useShowMessage();
    return useCallback(
        (scenarioIds: number[]) => {
            dispatch(updateLibraryScenarioStart());
            return updateScenarioStatusApi('draft', scenarioIds)
                .then(data => {
                    dispatch(
                        dispatch(updateLibraryScenarioSuccess(data, 'update'))
                    );

                    showMessage('Scenario has been restored', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLibraryScenarioFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updateScenarioStatusApi, showMessage]
    );
};

export const useSearchChallengesService = () => {
    const dispatch = useDispatch();
    const searchChallengesApi = useSearchChallengesApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams, shouldSetStore = true) => {
            const searchChallengesParams =
                createSearchLibraryParams(searchParams);
            shouldSetStore && dispatch(searchLibraryChallengesStart());

            return searchChallengesApi(searchChallengesParams)
                .then(data => {
                    if (shouldSetStore) {
                        dispatch(searchLibraryChallengesSuccess(data));
                    }

                    return data.items;
                })
                .catch((error: ErrorResult) => {
                    if (shouldSetStore) {
                        dispatch(searchLibraryChallengesFailure(error.message));
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, searchChallengesApi, showMessage]
    );
};

/**
 * @function useDeleteChallengesService
 * @returns { CallableFunction }
 */
export const useDeleteChallengesService = () => {
    const deleteChallengesApi = useDeleteChallengesApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number[] } challengeIds
     * @param { Function } successCallback
     * @returns { Promise }
     */
    return useCallback(
        (challengeIds: number[], successCallback?: () => void) => { 
            return deleteChallengesApi(challengeIds)
                .then(() => {
                    const message = `${challengeIds.length} ${plural(challengeIds.length, 'Challenge has', 'Challenges have')} been deleted`;
                    showMessage(message, 'success');

                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [deleteChallengesApi, showMessage]
    );
};

export const useArchiveChallengeService = () => {
    const dispatch = useDispatch();
    const updateChallengeStatusApi = useUpdateChallengeStatusApi();
    const showMessage = useShowMessage();
    return useCallback(
        (challengeIds: number[]) => {
            dispatch(updateLibraryChallengeStart());
            return updateChallengeStatusApi('archive', challengeIds)
                .then(data => {
                    dispatch(
                        dispatch(updateLibraryChallengeSuccess(data, 'update'))
                    );

                    showMessage('Challenge has been archived', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLibraryChallengeFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updateChallengeStatusApi, showMessage]
    );
};

export const useRestoreChallengeService = () => {
    const dispatch = useDispatch();
    const updateChallengeStatusApi = useUpdateChallengeStatusApi();
    const showMessage = useShowMessage();
    return useCallback(
        (challengeIds: number[]) => {
            dispatch(updateLibraryChallengeStart());
            return updateChallengeStatusApi('draft', challengeIds)
                .then(data => {
                    dispatch(
                        dispatch(updateLibraryChallengeSuccess(data, 'update'))
                    );

                    showMessage('Challenge has been restored', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLibraryChallengeFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updateChallengeStatusApi, showMessage]
    );
};

export const useGenerateChallengeSuccessCallback = () => {
    const dispatch = useDispatch();
    const history: History<LocationState> = useHistory();
    const showMessage = useShowMessage();
    return useCallback(
        (responses?: Record<string, unknown>) => {
            if (!isEmpty(responses)) {
                const createChallengeResponse =
                    (responses?.[CREATE_CHALLENGE_ACTION] as Challenge) ||
                    undefined;

                if (createChallengeResponse) {
                    dispatch(
                        updateLibraryChallengeSuccess(
                            createChallengeResponse,
                            'update'
                        )
                    );

                    pushModal(
                        history,
                        ROUTES.LIBRARY_SETTINGS.CHALLENGES.SINGLE.replace(
                            ':challengeId',
                            createChallengeResponse.id!.toString()
                        )
                    );
                }
            }
            showMessage('Challenge generated!', 'success');
        },
        [dispatch, showMessage, history]
    );
};

export const useUpdateAllScenarioCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scenarioIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllScenarioCheckedState(scenarioIds, checked, partial)
            );
        },
        [dispatch]
    );
};

export const useUpdateLibraryScenarioCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scenarioId: number) => {
            dispatch(updateLibraryScenarioCheckedState(scenarioId));
        },
        [dispatch]
    );
};

export const useUpdateAllChallengeCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (challengeId: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllChallengesCheckedState(challengeId, checked, partial)
            );
        },
        [dispatch]
    );
};

export const useUpdateLibraryChallengeCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (challengeId: number) => {
            dispatch(updateLibraryChallengeCheckedState(challengeId));
        },
        [dispatch]
    );
};

export const useUpdateAllPractisSetsCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (practisSetIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllPractisSetsCheckedState(
                    practisSetIds,
                    checked,
                    partial
                )
            );
        },
        [dispatch]
    );
};

export const useUpdateLibraryPractisSetsCheckedStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (practisSetId: number) => {
            dispatch(updateLibraryPractisSetCheckedState(practisSetId));
        },
        [dispatch]
    );
};

export const useDownloadScenarioReportService = () => {
    const downloadScenarioReportApi = useDownloadScenarioReportApi();
    const showMessage = useShowMessage();
    return useCallback(
        (scenarioId: number | string) => {
            downloadScenarioReportApi(scenarioId)
                .then((data: Scenario) => {
                    fileDownload(data);
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [downloadScenarioReportApi, showMessage]
    );
};


/**
 * @function useDeleteLibraryLabelTag
 * @returns { CallableFunction }
 */
export const useDeleteLibraryLabelTag = () => {
    const showMessage = useShowMessage();
    const portableLabels = usePortableLabelsState();

    const deleteLabelsFromPractisSet = useDeletePractisSetLabelsApi();
    const deleteLabelsFromScenario = useDeleteScenarioLabelApi();
    const deleteLabelsFromChallenge = useDeleteChallengeLabelsApi();

    /**
     * @function getEntityItemLabels
     * @param { number[] } labelIds
     * @returns { Label[] }
     */
    const getEntityItemLabels = useCallback(
        (labelIds: number[]) => {
            return (
                portableLabels.data?.items?.filter(label =>
                    labelIds.includes(label.id)
                ) || []
            );
        },
        [portableLabels]
    );

    /**
     * @dev Use this to delete label tags from 'practisSet' | 'scenario' | 'challenge' pages.
     * @param { number } labelId
     * @param { entityData } LibraryEntity
     * @param { Function | undefined } successCallback
     */
    return useCallback(
        (
            labelId: number,
            entityData: LibraryEntity,
            successCallback?: () => void
        ) => {
            const deleteLabelFromLibraryActionList = {
                practisSet: deleteLabelsFromPractisSet,
                scenario: deleteLabelsFromScenario,
                challenge: deleteLabelsFromChallenge,
            };

            if (!!labelId) {
                const { entityName, entityItem } = entityData;

                const labels = getEntityItemLabels(entityItem?.labels || []);

                const entityRemovedLabels = [];

                if (!isEmpty(labels)) {
                    const currentLabel = labels.find(
                        label => label.id === labelId
                    );

                    if (!isEmpty(currentLabel)) {
                        const children: any[] = [];
                        findAllChildIds(labels, currentLabel!.id, children);

                        if (!!children && !!children.length) {
                            children.forEach(label => {
                                entityRemovedLabels.push({
                                    [`${entityName}Id`]: entityItem.id,
                                    labelId: label.id,
                                });
                            });
                        }

                        entityRemovedLabels.push({
                            [`${entityName}Id`]: entityItem.id,
                            labelId,
                        });
                    }

                    const deleteLabelFromLibraryAction =
                        deleteLabelFromLibraryActionList[entityName];

                    deleteLabelFromLibraryAction(
                        entityRemovedLabels as any
                    ).then(() => {
                        showMessage('Label removed successfully', 'success');

                        successCallback?.();
                    });
                }
            }
        },
        [
            deleteLabelsFromChallenge,
            deleteLabelsFromPractisSet,
            deleteLabelsFromScenario,
            getEntityItemLabels,
            showMessage,
        ]
    );
};