import React, { ChangeEventHandler, FocusEventHandler, FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Textarea } from '../Textarea/Textarea';
import { Variables } from '../../../theme/variables';

const StyledFormikField = styled.div<{ minHeight?: string }>`
    min-height: ${props => (!!props.minHeight ? props.minHeight : '176px')};
`;

const StledTextareaContainer = styled.div<{
    height?: string;
    disabled?: boolean;
    color: string;
    background?: string;
    padding?: string;
    borderRadius?: string;
}>`
    position: relative;
    border-radius: ${props =>
        !!props.borderRadius ? props.borderRadius : '16px'};
    padding: ${props => (!!props.padding ? props.padding : '23px 32px')};
    background: ${props => props.background ?? Variables.Colors.whiteTwo};
`;

const StyledTextarea = styled(Textarea)<{
    fontSize?: string;
    fontWeight?: number;
    minHeight?: string;
}>`
    ${props => (!!props.fontSize ? `font-size: ${props.fontSize};` : '')}
    ${props =>
        !!props.minHeight
            ? `min-height: ${props.minHeight};`
            : ''}
    &::placeholder {
        font-size: ${props => (!!props.fontSize ? props.fontSize : '15px')};
        font-weight: ${props =>
            !!props.fontWeight ? props.fontWeight : '600'};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }
`;

const StyledCounter = styled.div<{ color?: string, counterPosition?: string }>`
    font-size: 13px;
    text-align: ${props => props.counterPosition ? props.counterPosition :  'right'};
    color: ${props =>
        !!props.color ? props.color : props.theme.Colors.coolGray};
`;

export const TextAreaPlaceholderBox: FC<{
    value?: string;
    field?: any;
    form?: { touched: any; errors: any };
    name?: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    max?: number;
    width?: string;
    background?: string;
    color?: string;
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    readOnly?: boolean;
    padding?: string;
    fontSize?: string;
    minRows?: number;
    maxRows?: number;
    borderRadius?: string;
    placeholderFontWeight?: number;
    minHeight?: string;
    counterColor?: string;
    textAreaMinHeight?: string;
    dataTest?: string;
    className?: string;
    counterPosition?: string;
    counterWarningText?: ReactElement
}> = ({
    field,
    value,
    disabled,
    max,
    background,
    autoFocus,
    color,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    readOnly,
    fontSize,
    minRows,
    maxRows,
    padding,
    borderRadius,
    placeholderFontWeight,
    minHeight,
    counterColor,
    textAreaMinHeight,
    dataTest,
    className,
    counterPosition,
    counterWarningText
}) => {
    const TextAreaStyles = {
        flex: 1,
        lineHeight: 1.13,
        letterSpacing: '0.4px',
    };

    return (
        <StyledFormikField minHeight={minHeight}>
            <StledTextareaContainer
                disabled={disabled}
                color={color ? color : Variables.Colors.white}
                background={background}
                padding={padding}
                borderRadius={borderRadius}
                className={className}
            >
                <StyledTextarea
                    value={value}
                    styles={TextAreaStyles}
                    {...field}
                    placeholder={placeholder}
                    max={max}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                    fontSize={fontSize}
                    minRows={minRows}
                    maxRows={maxRows}
                    fontWeight={placeholderFontWeight}
                    minHeight={textAreaMinHeight}
                    dataTest={dataTest}
                />
                {max && (
                    <StyledCounter color={counterColor} data-test={dataTest && `${dataTest}-counter`} counterPosition={counterPosition}>
                        {value ? value.length : '0'}/{max} <span data-test={dataTest && `${dataTest}-error-text`}>{counterWarningText}</span>
                    </StyledCounter>
                )}
            </StledTextareaContainer>
        </StyledFormikField>
    );
};

export default TextAreaPlaceholderBox;
