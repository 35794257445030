export enum BulkActionAvailability {
    UNAVAILABLE_FOR_ALL,
    AVAILABLE_FOR_SOME,
    AVAILABLE_FOR_ALL,
}

export interface BulkActionAvailabilityItem<TAction> {
    action: TAction;
    availability: BulkActionAvailability;
}

export function getBulkActionAvailability<TItem>(
    items: TItem[] | undefined,
    canPerformAction: (item: TItem) => boolean
): BulkActionAvailability {
    if (!!items?.length) {
        const availableFor = items.filter(canPerformAction);
        if (availableFor.length === items.length) {
            return BulkActionAvailability.AVAILABLE_FOR_ALL;
        }
        else if (availableFor.length > 0 && availableFor.length < items.length) {
            return BulkActionAvailability.AVAILABLE_FOR_SOME;
        }
    }
    return BulkActionAvailability.UNAVAILABLE_FOR_ALL;
}

export function getBulkActionAvailabilityItem<TItem, TAction>(
    action: TAction,
    items: TItem[] | undefined,
    canPerformAction: (item: TItem) => boolean
): BulkActionAvailabilityItem<TAction> {
    return {
        action,
        availability: getBulkActionAvailability(items, canPerformAction),
    };
}

export function getBulkActionsAvailability<TItem, TAction extends string | number>(
    items: TItem[] | undefined,
    actions: BulkActionAvailabilityItem<TAction>[]
) {
    const actionsMap = getBulkActionsMap(actions);
    
    const areItemsEmpty = () =>
        !items?.length;

    const areBulkActionsDisabled = () =>
        areItemsEmpty()
            || actions.every(a => a.availability !== BulkActionAvailability.AVAILABLE_FOR_ALL);
    
    const isBulkActionVisible = (action: TAction) =>
        actionsMap[action]?.availability === BulkActionAvailability.AVAILABLE_FOR_ALL
            || actionsMap[action]?.availability === BulkActionAvailability.AVAILABLE_FOR_SOME;

    const isBulkActionDisabled = (action: TAction) =>
        actionsMap[action]?.availability === BulkActionAvailability.AVAILABLE_FOR_SOME
            || actionsMap[action]?.availability === BulkActionAvailability.UNAVAILABLE_FOR_ALL;

    return {
        areItemsEmpty,
        areBulkActionsDisabled,
        isBulkActionVisible,
        isBulkActionDisabled,
    };
}

function getBulkActionsMap<TAction extends string | number>(
    actions: BulkActionAvailabilityItem<TAction>[]
) {
    return actions.reduce((result, a) => {
        result[a.action] = a;
        return result;
    }, {} as Record<TAction, BulkActionAvailabilityItem<TAction>>);
}
