import { FC, useCallback, useRef } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../../../ui/components/ActionButton';
import { CheckPermission } from '../../../../../permissions';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { isTeamLeader } from '../../../../../../constants/enums';
import ROUTES from '../../../../../../routes/routes';
import { TeamV2 } from '../../../../../../constants/interfaces/Team';
import { pushModal, useHistory } from '../../../../../../tools/router';
import { UserProfile } from '../../../../../../constants/interfaces/User';
import { TrainingPractisSet } from '../../../../../../constants/interfaces/TrainingPractisSet';

const ListActions: FC<{
    practisSet: TrainingPractisSet;
    profile?: UserProfile;
    team?: TeamV2;
    handleRedirection: (id?: number) => void;
    onExportSinglePractisClicked: (id?: number) => void;
    isHidden?: boolean;
}> = ({
    practisSet,
    team,
    profile,
    handleRedirection,
    onExportSinglePractisClicked,
    isHidden,
}) => {

    const history = useHistory();
    const triggerRef = useRef(null);

    const handleViewAssignUsers = useCallback(
        (id?: number) => {
            if (!id || !team || (team && !team.id)) return;

            pushModal(
                history,
                ROUTES.TEAMS_PAGES.TRAINING_PAGES.TRAINEE_INVITATION.replace(
                    ':teamId',
                    team.id ? team.id.toString() : ''
                ).replace(':practisSetId', id.toString())
            );
        },
        [history, team]
    );

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton customWidth={168} isHidden={isHidden} dataTest="action-menu">
                    <CheckPermission
                        permissions={[
                            NEW_PERMISSIONS.VIEW_PRACTIS_SET_PROGRESS,
                        ]}
                    >
                        <ActionItem
                            onClick={() =>
                                handleRedirection(practisSet.practisSetId)
                            }
                            dataTest="view-progress-action-item"
                        >
                            View Progress
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.ASSIGN_ENROLLMENT]}
                    >
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                if (
                                    practisSet.practisSetId &&
                                    team &&
                                    team.id
                                ) {
                                    handleViewAssignUsers(
                                        practisSet.practisSetId
                                    );
                                }
                            }}
                            dataTest="assign-users-action-item"
                        >
                            Assign Users
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.EXPORT_LIST_PRACTIS_SET]}
                    >
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                onExportSinglePractisClicked(
                                    practisSet.practisSetId
                                );
                            }}
                            dataTest="export-report-action-item"
                        >
                            Export Report
                        </ActionItem>
                    </CheckPermission>
                    {isTeamLeader(profile?.role?.name) ? (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                if (practisSet.practisSetId)
                                    history.push(
                                        ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE.replace(
                                            ':practisSetId',
                                            practisSet.practisSetId.toString()
                                        )
                                    );
                            }}
                            dataTest="view-practisset-action-item"
                        >
                            View Practis Set
                        </ActionItem>
                    ) : (
                        <CheckPermission
                            permissions={[NEW_PERMISSIONS.UPDATE_PRACTIS_SET]}
                        >
                            <ActionItem
                                onClick={e => {
                                    e.stopPropagation();
                                    if (practisSet.practisSetId)
                                        history.push(
                                            ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE.replace(
                                                ':practisSetId',
                                                practisSet.practisSetId.toString()
                                            )
                                        );
                                }}
                                dataTest="edit-practisset-action-item"
                            >
                                Edit Practis Set
                            </ActionItem>
                        </CheckPermission>
                    )}
                </ActionButton>
            </div>
        </span>
    );
};

export default ListActions;
