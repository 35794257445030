import React, { memo, useCallback } from 'react';
import { match as Match, useLocation } from 'react-router-dom';
import { kebabCase } from 'lodash';

import { SideBarItemProps } from './types';
import { IconHolder, Label, Link, StyledDot } from './styles';

export const SideBarItem: React.FC<SideBarItemProps> = memo(
    ({
        name,
        icon: Icon,
        activeIcon: ActiveIcon,
        url,
        shrink,
        alternateUrl,
        defaultItem,
        fontSize,
        hasNewItems,
    }) => {
        const location = useLocation();
        const isActive = useCallback(
            (isMatch: boolean) =>
                isMatch ||
                (!!defaultItem && location.pathname === '/') ||
                (!!alternateUrl && alternateUrl.includes(location.pathname)),
            [defaultItem, location, alternateUrl]
        );

        const handleIsActive = useCallback(
            (match: Match<any>) => isActive(!!match),
            [isActive]
        );
        return (
            <Link
                to={url}
                activeClassName={'is-active'}
                isActive={handleIsActive}
                shrink={!!shrink}
                data-test={`sidebar-${kebabCase(name)}`}
            >
                <IconHolder title={name}>
                    {isActive(location.pathname.indexOf(url) > -1) ? (
                        <ActiveIcon />
                    ) : (
                        <Icon />
                    )}
                </IconHolder>
                {!shrink && <Label fontSize={fontSize}>{name}</Label>}
                {hasNewItems && <StyledDot isShrink={!shrink} />}
            </Link>
        );
    }
);
