import { TelepromterState } from './state';
import { ScenarioStatuses } from '../../../constants/interfaces/Scenario';
import {
    ACTIONS,
    addGeneratedScriptLineAction,
    fetchTelepromterScenarioFailure,
    fetchTelepromterScenarioStart,
    fetchTelepromterScenarioSuccess,
    resetTelepromterAction,
    setRecordedVideoAction,
} from './actions';

export const initialTelepromterState: TelepromterState = {
    scenario: {
        title: '',
        description: '',
        instructions: '',
        status: ScenarioStatuses.DRAFT,
        script: {
            totalDuration: null,
            scenarioId: null,
            companyId: null,
            lines: [],
        },
    },
    generatedLines: [],
    loading: true,
    error: '',
};

type TelepromterActions =
    | ReturnType<typeof fetchTelepromterScenarioStart>
    | ReturnType<typeof fetchTelepromterScenarioSuccess>
    | ReturnType<typeof fetchTelepromterScenarioFailure>
    | ReturnType<typeof addGeneratedScriptLineAction>
    | ReturnType<typeof setRecordedVideoAction>
    | ReturnType<typeof resetTelepromterAction>;

export const telepromterReducer = (
    state = initialTelepromterState,
    action: TelepromterActions
): TelepromterState => {
    switch (action.type) {
        case ACTIONS.FETCH_TELEPROMTER_SCENARIO_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.FETCH_TELEPROMTER_SCENARIO_SUCCESS:
            return {
                scenario: {
                    ...state.scenario,
                    ...action.data,
                },
                generatedLines: [],
                loading: false,
                error: '',
            };
        case ACTIONS.FETCH_TELEPROMTER_SCENARIO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.ADD_GENERATED_SCRIPT_LINE:
            const generatedLines = [...state.generatedLines];
            generatedLines.push(action.scriptLine);
            return {
                ...state,
                generatedLines: generatedLines,
            };
        case ACTIONS.SET_RECORDED_VIDEO:
            return {
                ...state,
                videoBlob: action.videoBlob,
            };
        case ACTIONS.RESET_TELEPROMTER:
            return {
                ...state,
                generatedLines: [],
                videoBlob: undefined,
            };
        default:
            return state;
    }
};
