import { kebabCase } from 'lodash';
import {
    Link,
    Title,
    Description,
    ResultFormat,
} from './styles';
import { ReportingCardProps } from './types';

function ReportingCardView({
    url,
    icon,
    title,
    description,
    resultFormat
}: ReportingCardProps) {
    const dataTest = kebabCase(title);
    return <Link to={url} data-test={`${dataTest}-link`}>
        <span data-test={`${dataTest}-icon`}>{icon}</span>
        <Title data-test={`${dataTest}-title`}>{title}</Title>
        <Description data-test={`${dataTest}-description`}>{description}</Description>
        <ResultFormat data-test={`${dataTest}-excel`}>{resultFormat}</ResultFormat>
    </Link>
}

export default ReportingCardView;