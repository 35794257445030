import { useDispatch } from 'react-redux';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    fetchAllDrafts,
    fetchAllDraftsFailure,
    fetchAllDraftsSuccess,
    updateDraftsFailure,
    updateDraftsStart,
    updateDraftsSuccess,
    updateAllSelectedDraftState,
    updateSelectedDraftState,
    fetchCreatedByUsersFailure,
    fetchCreatedByUsersStart,
    fetchCreatedByUsersSuccess,
    fetchEditedByUsersFailure,
    fetchEditedByUsersStart,
    fetchEditedByUsersSuccess,
} from '../actions';
import {
    useSearchStagingDraftsApi,
    useDeleteStagingApi,
    useSearchCreatedByUsersListApi,
    useSearchEditedByUsersListApi,
} from '../../../../api';
import { SearchParams } from '../../../../constants/interfaces/filters';
import { Draft } from '../../../../constants/interfaces/Draft';

export const useSearchDraftsService = () => {
    const dispatch = useDispatch();
    const searchStagingApi = useSearchStagingDraftsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p?: SearchParams) => {
            dispatch(fetchAllDrafts());
            searchStagingApi(p)
                .then(data => {
                    dispatch(fetchAllDraftsSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchAllDraftsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchStagingApi, showMessage]
    );
};

export const useDeleteStagingService = () => {
    const dispatch = useDispatch();
    const deleteStagingApi = useDeleteStagingApi();
    const showMessage = useShowMessage();
    return useCallback(
        (stagingIds: number[], search?: SearchParams, selectAll?: boolean, successCallback?: () => void) => {
            dispatch(updateDraftsStart());
            return deleteStagingApi(stagingIds, search, selectAll)
                .then(data => {
                    dispatch(
                        updateDraftsSuccess(
                            { id: stagingIds.reduce((a, b) => a + b) } as Draft,
                            'delete'
                        )
                    );
                    showMessage(
                        `${
                            stagingIds.length > 1
                                ? 'Invitation drafts has been deleted'
                                : 'Invitation draft has been deleted'
                        }`,
                        'success'
                    );

                    successCallback?.();
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateDraftsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, deleteStagingApi, showMessage]
    );
};

export const useUpdateAllSelectedDraftStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (draftIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(updateAllSelectedDraftState(draftIds, checked, partial));
        },
        [dispatch]
    );
};

export const useUpdateSelectedDraftStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (draftId: number) => {
            dispatch(updateSelectedDraftState(draftId));
        },
        [dispatch]
    );
};

export const useSearchCreatedByUsersService = () => {
    const dispatch = useDispatch();
    const searchCreatedByUsersListApi = useSearchCreatedByUsersListApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p: Partial<SearchParams>) => {
            dispatch(fetchCreatedByUsersStart());
            searchCreatedByUsersListApi(p)
                .then(data => {
                    dispatch(fetchCreatedByUsersSuccess(data.createdByUsers));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchCreatedByUsersFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchCreatedByUsersListApi, showMessage]
    );
};

export const useSearchEditedByUsersService = () => {
    const dispatch = useDispatch();
    const searchEditedByUsersListApi = useSearchEditedByUsersListApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p: SearchParams) => {
            dispatch(fetchEditedByUsersStart());
            searchEditedByUsersListApi(p)
                .then(data => {
                    dispatch(fetchEditedByUsersSuccess(data.lastEditedBy));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchEditedByUsersFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchEditedByUsersListApi, showMessage]
    );
};