import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { History } from 'history';
import { useHistory } from 'react-router';
import { isEmpty as lodashIsEmpty, isEqual } from 'lodash';

import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    ASSIGN_LABEL_OPTIONS,
    LOAD_ASSIGNED_LABELS,
    isAdminRole,
} from '../../../../constants/enums';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import {
    useCopyInviteTextService,
    useResendInvitationService,
    useRevokeInvitationService,
    useSearchInvitationsService,
    useUpdateAllSelectedInvitationsStateService,
    useUpdateSelectedInvitationStateService,
    useDeleteUserLabelService,
} from '../../store/services';
import {
    selectSelectedInvitations,
    useInvitationsState,
    useUpdateInvitationsState,
} from '../../store/states';
import {
    hasPermission,
    NEW_PERMISSIONS,
} from '../../../../features/users/tools';
import UsersPageNavigationContainer from '../../components/UsersPageNavigation';
import { UserV2, UserProfile } from '../../../../constants/interfaces/User';
import { formatDate } from '../../../../helpers/functions/date-convert';
import {
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import {
    clearSelectedItemIfNotLoaded,
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabels,
} from '../../../labels/tools';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import ROUTES from '../../../../routes/routes';
import { usePermissionsState } from '../../../permissions/store/state';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { countAppliedFiltersCount } from '../../../teams/pages/ListOfTeams/tools';
import { Popup } from '../../../../ui/components/Popup';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { InvitationsFilter } from '../../components/Filters/InvitationsFilter';
import { WithLabelsContext } from '../../../portableLabels';
import { WithTeamsContext } from '../../../portableTeams';
import InvitationsListActions from '../../components/ListActions/InvitationsListActions';
import {
    InvitationActions,
    InvitationActionsHelper,
    useInvitationActionsHelper,
} from '../../tools';
import { useSessionEffect } from '../../../common';
import {
    useDeselectAllTeams,
    useSaveTeams,
} from '../../../portableTeams/store/hors/withTeams/services';
import {
    useDeSelectAllInvitersAction,
    useRefreshInviters,
} from '../../../inviters/store/services';
import {
    useDraftEditState,
    DraftEditState,
} from '../../../../pages/ActionPages/NewUser/store/states';
import Pending from '../../../../ui/icons/Pending';
import { normalizeSP } from '../../../../helpers/functions/searchparams-normalize';
import {
    useAssignedLabelsIdsApi,
    useAssignUserLabelsApi,
    useDeleteUserLabelsApi,
} from '../../../../api';
import { getSearchState } from '../../../../features/searchState/store/reducer';
import { SearchStateInterface } from '../../../../features/searchState/store/states';
import { SEARCH_STATE } from '../../../../features/searchState/constants';
import { isEmpty } from '../../../../helpers/functions/object-helpers';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { useStyles } from './styles';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import {
    onListenEvent,
    onRemoveEvent,
    onTriggerEvent,
} from '../../../../helpers/functions/Events';
import {
    CLEAR_PAGING,
    EventNameList,
    REFRESH_PENDING_USERS_PAGE,
} from '../../../../helpers/functions/Events/types';
import { usePreviousData } from '../../../../helpers/hooks/usePreviousData';
import {
    useUpdateLabelsBulkActionService,
    useUpdateMultipleEntityLabelsBulkActionService,
} from '../../../../services/GeneralBulkActionServices';
import {
    useResendInvitationBulkActionService,
    useRevokeInvitationBulkActionService,
} from '../../services/UserBulkActionsService';
import { useGetCompanyService, useGetCompanyUserStatsService } from '../../../../pages/CompanySettings/store/services';
import { UPDATE_MULTIPLE_LABEL_ACTION } from '../../../../services/GeneralBulkActionServices/constants';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import { CompanyUserStatsText } from '../../../../ui/components/CompanyUserStatsText';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const Invitations: FC<{
    history: History<any>;
    searchInvitations: (sp: SearchParams, companyId: number) => void;
    deleteUserLabel: (userId: number, labelId: number) => void;
    setAssignLabelsAction(labels: number[]): void;
    onResendInvitation: (
        invitationIds: number[],
        isRowResend?: boolean,
        search?: SearchParams,
        selectAll?: boolean
    ) => void;
    onRevokeInvitation: (
        invitationIds: number[],
        successCallback?: () => void
    ) => void;
    onCopyInviteText: (invitation: UserV2) => void;
    users?: ListResult<UserV2>;
    selectedInvitationIds?: number[];
    selectedInvitations?: UserV2[];
    actionsHelper: InvitationActionsHelper;
    updatedInvitations?: { id: number };
    loading?: boolean;
    selectedLabels?: number[];
    assignedLabels?: number[];
    updateAllSelectedInvitations(
        invitationIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedInvitation(invitationId: number): void;
    updatedLabels?: any;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    draftEdit?: DraftEditState;
    searchState: SearchStateInterface;
    profile?: UserProfile;
    getCompanyUserStats: (companyId: number) => void;
}> = ({
    users,
    searchInvitations,
    setAssignLabelsAction,
    onResendInvitation,
    onRevokeInvitation,
    onCopyInviteText,
    updatedInvitations,
    selectedLabels,
    selectedInvitationIds,
    selectedInvitations,
    actionsHelper,
    assignedLabels,
    updateAllSelectedInvitations,
    updateSelectedInvitation,
    deleteUserLabel,
    updatedLabels,
    loading,
    history,
    saveGlobalLabelFilters,
    draftEdit,
    searchState,
    profile,
    getCompanyUserStats,
}) => {
    const classes = useStyles();

    const [lastRefreshed, setLastRefreshed] = useState(new Date());

    const refreshInvitersList = useRefreshInviters();

    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );
    const prevCompanyId = usePreviousData(profile?.companyId);

    const canAccessPage = useHandleAccessPage();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        labelIDs: selectedLabels,
        filters: [],
        orderBy: {
            field: 'date_created',
            asc: false,
        },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState.key === SEARCH_STATE.PENDING_USERS.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        refreshSearchParams,
        setCustom,
        setFilter,
    } = useSearchParamsState(usableParams);

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;
    const isCheckboxPartiallySelected =
        users &&
        users.items &&
        selectedInvitationIds &&
        selectedInvitationIds.length < users.items.length;

    const showMessage = useShowMessage();

    const handleTableStates = useTableStateHelper();

    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: searchParams.labelIDs?.length,
        itemsCount: users?.items.length || 0,
    });

    const refreshData = useCallback(() => {
        if (!profile?.companyId) return;
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchInvitations(searchParams, profile.companyId);
        setLastRefreshed(new Date());
        updateAllSelectedInvitations([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    }, [
        profile?.companyId,
        initialOffset,
        searchInvitations,
        searchParams,
        updateAllSelectedInvitations,
        isCheckboxPartiallySelected,
    ]);

    /**
     * @function onConfirmRevokeInvitationsSuccessCallback
     * @param { number[] } ids
     * @returns { void }
     */
    const onConfirmRevokeInvitationsSuccessCallback = (ids: number[]): void => {
        let message;

        Promise.resolve().then(() => {
            if (!pageIndex || pageIndex === 1) {
                refreshData();
            } else {
                // if the current page is not page 1,
                // move paging to page 1
                onTriggerEvent(EventNameList[CLEAR_PAGING]);
            }
        });

        // refresh inviters list on filters.
        refreshInvitersList();
        getCompanyUserStats(profile!.companyId);

        if (ids?.length === 1) {
            message = 'Invite has been revoked';
        } else {
            message =
                ids.length > 1
                    ? `${
                          isSelectAll ? users?.count : ids.length
                      } Invites have been revoked`
                    : '1 Invite has been revoked';
        }
        showMessage(message, 'success');
    };

    /**
     * @function onConfirmRevokeInvitations
     * @param { number[] } revokeInvitationIds
     * @returns { void }
     */
    const onConfirmRevokeInvitations = (
        revokeInvitationIds: number[]
    ): void => {
        onRevokeInvitation(revokeInvitationIds, () =>
            onConfirmRevokeInvitationsSuccessCallback(revokeInvitationIds)
        );
    };

    /**
     * @function handleRevokeInvitation
     * @param { number[] } revokeInvitationIds
     * @returns { void }
     */
    const handleRevokeInvitation = (revokeInvitationIds: number[]): void => {
        showConfirmationModalDialog({
            modalTitle: 'Revoke Invitation',
            description: `Are you sure you want to revoke the ${
                revokeInvitationIds.length > 1
                    ? 'invitations for these users'
                    : 'invitation for this user'
            }?`,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Yes, Revoke',
            onConfirm: () => onConfirmRevokeInvitations(revokeInvitationIds),
            customization: {
                padding: '45px 32px 40px 40px',
                descriptionMarginBottom: 9
            },
        });
    };

    const onSuccessRevokeInvitationBulkActionService = useCallback(() => {
        refreshData();
        // refresh inviters list on filters.
        refreshInvitersList();
    }, [refreshData, refreshInvitersList]);

    const revokeInvitationBulkActionService =
        useRevokeInvitationBulkActionService(
            searchParams,
            profile?.companyId ?? 0,
            users?.count ?? 0,
            onSuccessRevokeInvitationBulkActionService
        );

    /**
     * @function handleRevokeMultipleInvitations
     * @returns { void }
     */
    const handleRevokeMultipleInvitations = (): void => {
        selectedInvitationIds &&
            (isSelectAll
                ? revokeInvitationBulkActionService()
                : handleRevokeInvitation(selectedInvitationIds));
    };

    /**
     * @function handleResendInvitation
     * @returns { void }
     */
    const handleResendInvitation = useCallback(
        (
            invitationIds: number[],
            search?: SearchParams,
            isSelectAll?: boolean
        ) => {
            onResendInvitation(
                invitationIds,
                invitationIds.length === 1,
                isSelectAll
                    ? normalizeSP(searchParams, users!.count)
                    : searchParams,
                isSelectAll
            );
        },
        [onResendInvitation, searchParams, users]
    );

    const handleResendInvitationBulkActionService =
        useResendInvitationBulkActionService(
            searchParams,
            profile?.companyId ?? 0,
            users?.count ?? 0,
            refreshData
        );

    /**
     * @function handleResendMultipleInvitation
     * @returns { void }
     */
    const handleResendMultipleInvitation = (): void => {
        if (
            !selectedInvitationIds ||
            (!!selectedInvitationIds && !selectedInvitationIds.length)
        )
            return;

        isSelectAll
            ? handleResendInvitationBulkActionService()
            : handleResendInvitation(
                  selectedInvitationIds,
                  searchParams,
                  isSelectAll
              );
    };

    const getDeletedLabels = useCalculateDeletedLabels();
    const assignLabelsToUser = useAssignUserLabelsApi();
    const deleteLabelsFromUser = useDeleteUserLabelsApi();

    const updateMultipleUsersLabels =
        useUpdateMultipleEntityLabelsBulkActionService(
            assignLabelsToUser,
            deleteLabelsFromUser,
            isSelectAll,
            {
                actionName: UPDATE_MULTIPLE_LABEL_ACTION,
                actionFunction: searchInvitations,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            limit: 1,
                            offset: users?.count ?? 0,
                        },
                        companyId: profile?.companyId ?? 0,
                        shouldSetStore: false,
                    },
                    fieldName: 'searchParams.offset',
                },
            },
            refreshData
        );

    const assignLabels = async () => {
        if (assignedLabels && selectedInvitationIds) {
            const { preAssignedLabels } = await getAssignedLabelsIds(
                selectedInvitationIds,
                LOAD_ASSIGNED_LABELS.USERS_PENDING,
                ASSIGN_LABEL_OPTIONS.USER,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                return calculatePreSelectedLabels(
                    selectedInvitationIds,
                    users,
                    data
                );
            });

            const deletedLabels = getDeletedLabels(assignedLabels);

            const deletedLabelsFiltered = deletedLabels.filter(
                x =>
                    preAssignedLabels.includes(x) ||
                    preAssignedLabels.includes(-x)
            );
            const selectedLabelsFiltered = assignedLabels.filter(
                x => x > 0 && !preAssignedLabels.includes(x)
            );

            updateMultipleUsersLabels(
                {
                    entityName: 'user',
                    entityIds: selectedInvitationIds,
                },
                selectedLabelsFiltered,
                deletedLabelsFiltered
            );
        }
    };

    const getAssignedLabelsIds = useAssignedLabelsIdsApi();

    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();

    const updateAllUsersCheck = useCallback(
        (checked: boolean) => {
            const userIds =
                (users && users.items.map((user: UserV2) => Number(user.id))) ||
                [];
            updateAllSelectedInvitations(
                userIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [users, isCheckboxPartiallySelected, updateAllSelectedInvitations]
    );

    const handleUserCheck = (e: any, user: UserV2) => {
        e.stopPropagation();
        if (!!user.id) {
            updateSelectedInvitation(user.id);
        }
    };

    const permissions = usePermissionsState();

    const handleViewProfile = (user: UserV2) => {
        if (permissions.includes(NEW_PERMISSIONS.VIEW_USER_PERFORMANCE)) {
            history.push(
                ROUTES.INVITATION_PERFORMANCE.replace(
                    ':userId',
                    user.id!.toString()
                ),
                { useGoBack: true }
            );
        } else {
            return false;
        }
    };

    const orderBy = searchParams.orderBy;
    const hcFirstName = useOrderBy('name', orderBy, setOrderBy);
    const hcEmail = useOrderBy('email', orderBy, setOrderBy);
    const hcRole = useOrderBy('role_name', orderBy, setOrderBy);
    const hcInvitedOn = useOrderBy('date_created', orderBy, setOrderBy);
    const hcInvitedby = useOrderBy('invited_by', orderBy, setOrderBy);

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const isListEmpty = !users || (users && !users.items.length);

    const filterRoleHandler = (roleIds: number[]) => {
        setCustom('roleIDs', roleIds);
    };

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const filterInvitersHandler = (invitersIDs: number[]) => {
        setCustom('invitersIDs', invitersIDs);
        setFilter({ field: 'creatorId', value: invitersIDs });
    };

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedInvitations),
        [selectedInvitations, actionsHelper]
    );

    const assignedFiltersCount = countAppliedFiltersCount(searchParams);

    const deselectTeams = useDeselectAllTeams();
    const deselectAllInviters = useDeSelectAllInvitersAction();
    const saveTeams = useSaveTeams();

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllUsersCheck(event.target.checked);
        setIsSelectAll(false);
    };

    /**
     * @function checkIsRowChecked
     * @param { UserV2 } row
     * @returns { boolean }
     */
    const checkIsRowChecked = (row: UserV2): boolean =>
        !!selectedInvitationIds &&
        !!row.id &&
        selectedInvitationIds.includes(row.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { UserV2 } row
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, row: UserV2) => {
        handleUserCheck(event, row);
        setIsSelectAll(false);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { UserV2 } row
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (row: UserV2): boolean => {
        return labelsOpen === row.id;
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { number } userId
     * @returns { void }
     */
    const onDeleteLabelHandler = (labelId: number, userId: number): void => {
        deleteUserLabel(userId, labelId);
    };

    useSessionEffect(() => {
        if (!profile?.companyId) return;
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchInvitations(searchParams, profile.companyId);
    }, [profile?.companyId, searchInvitations, searchParams, history]);

    useEffect(() => {
        if (updatedInvitations || !!draftEdit?.updateData?.id) {
            refreshSearchParams();
        }
    }, [updatedInvitations, refreshSearchParams, draftEdit?.updateData?.id]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        setLastRefreshed(new Date());
    }, [users]);

    useEffect(() => {
        return () =>
            updateAllSelectedInvitations([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedInvitations([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, history.location.pathname]);

    useEffect(() => {
        if (
            selectedInvitations?.length &&
            selectedInvitations.length === users?.count
        ) {
            setIsSelectAll(true);
        }
    }, [users?.count, selectedInvitations]);

    /**
     * @function onOpenAssignLabelAction
     * @returns { void }
     */
    const onOpenAssignLabelAction = (): void => {
        if (users?.items && !lodashIsEmpty(selectedInvitationIds)) {
            getAssignedLabelsIds(
                selectedInvitationIds as number[],
                LOAD_ASSIGNED_LABELS.USERS_PENDING,
                ASSIGN_LABEL_OPTIONS.USER,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                const { preAssignedLabels } = calculatePreSelectedLabels(
                    selectedInvitationIds as number[],
                    users,
                    data
                );

                setAssignLabelsAction(preAssignedLabels);
            });
        }
    };

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
    }, [initialOffset, pageIndex, setOffset]);

    useEffect(() => {
        const filteredUsers = users
            ? users.items.map(user => Number(user.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedInvitationIds,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllSelectedInvitations(usersToClear, false);
        }
    }, [selectedInvitationIds, users, updateAllSelectedInvitations]);

    useEffect(() => {
        return () => {
            deselectTeams();
            saveTeams();
            deselectAllInviters();
        };
    }, [deselectTeams, saveTeams, deselectAllInviters]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_INVITATION]);
    }, [canAccessPage]);

    // Listen to Invite Users Screen Refresh dispatch
    useEffect(() => {
        onListenEvent(EventNameList[REFRESH_PENDING_USERS_PAGE], () => {
            if (
                profile?.companyId &&
                !isEqual(prevCompanyId, profile.companyId)
            ) {
                refreshData();
            }
        });

        return () => {
            onRemoveEvent(
                EventNameList[REFRESH_PENDING_USERS_PAGE],
                refreshData
            );
        };
    }, [prevCompanyId, profile, refreshData]);

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToUser,
        deleteLabelsFromUser,
        refreshData
    );

    /**
     * @function handleUpdateUserLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { number } userId
     * @returns { void }
     */
    const handleUpdateUserLabels = (
        addedLabelIds: number[],
        deletedLabelIds: number[],
        userId: number
    ): void => {
        updateLabelsBulkActionService(
            {
                entityName: 'user',
                entityId: userId,
            },
            addedLabelIds,
            deletedLabelIds
        );
    };

    return (
        <MainWrapper
            subTitle="Users"
            subHeader={profile?.companyId &&
                <CompanyUserStatsText
                    companyId={profile.companyId}
                    showSettingsButton={isAdminRole(profile?.role?.name)}
                />
            }
            tabs={<UsersPageNavigationContainer />}
            htmlPageTitle="Users - Practis"
            dataTest="users"
        >
            {loading && !isSelectAll && <LoadingComponent />}

            <TableWrapper
                tableStates={tableStates}
                data={users?.items}
                selectedLabels={selectedLabels}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'users-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: users?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'users-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled:
                            isListEmpty && searchParams.searchTerm.length === 0,
                    },
                    isSelectedItemOptionsVisible:
                        !lodashIsEmpty(selectedInvitations) &&
                        !lodashIsEmpty(users),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedInvitations?.length,
                        totalCount: users?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: () => {
                            updateAllSelectedInvitations(
                                [],
                                true,
                                isCheckboxPartiallySelected
                            );
                            setIsSelectAll(false);
                        },
                        onSelectAll: () => {
                            setIsSelectAll(true);
                            updateAllUsersCheck(true);
                        },
                        bulkActionsConfig: {
                            disabled: bulkActions.areBulkActionsDisabled(),
                            disabledLabel: bulkActions.areItemsEmpty()
                                ? undefined
                                : "Bulk actions can't be applied",
                            assignLabelsPermissions:
                                bulkActions.isBulkActionVisible(
                                    InvitationActions.ASSIGN_INVITATION_LABEL
                                )
                                    ? [NEW_PERMISSIONS.ASSIGN_INVITATION_LABEL]
                                    : [],
                            isAssignLabelsDisabled:
                                bulkActions.isBulkActionDisabled(
                                    InvitationActions.ASSIGN_INVITATION_LABEL
                                ),
                            onOpenAssignLabelAction: onOpenAssignLabelAction,
                            onAssignLabelsSubmit: assignLabels,
                            resendInvitesPermissions:
                                bulkActions.isBulkActionVisible(
                                    InvitationActions.RESEND_INVITATION
                                )
                                    ? [NEW_PERMISSIONS.RESEND_INVITATION]
                                    : [],
                            isResendDisabled: bulkActions.isBulkActionDisabled(
                                InvitationActions.RESEND_INVITATION
                            ),
                            onResendInvitesSubmit:
                                handleResendMultipleInvitation,

                            revokePermissions: bulkActions.isBulkActionVisible(
                                InvitationActions.REVOKE_INVITATION
                            )
                                ? [NEW_PERMISSIONS.REVOKE_INVITATION]
                                : [],
                            isRevokeDisabled: !hasPermission(
                                profile,
                                NEW_PERMISSIONS.REVOKE_INVITATION
                            ),
                            onRevokeSubmit: handleRevokeMultipleInvitations,
                            isSelectAll: isSelectAll,
                            totalCount: users?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <InvitationsFilter
                                        preSelectedLabels={selectedLabels || []}
                                        onInvitersChange={filterInvitersHandler}
                                        onRoleChange={filterRoleHandler}
                                        preSelectedRoles={
                                            searchParams.roleIDs
                                                ? searchParams.roleIDs
                                                : []
                                        }
                                        onLabelsChange={labelChangeHandler}
                                        onSuccessApply={() => hide()}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={assignedFiltersCount}
                                        disabled={
                                            (searchParams.searchTerm.length >
                                                0 ||
                                                assignedFiltersCount === 0) &&
                                            isListEmpty
                                        }
                                        dataTest="users-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && users?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Pending,
                        text: 'No Pending Users Yet',
                        dataTest: 'no-results',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Users',
                        dataTest: 'no-found-results',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'no-filtered-results',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: !!selectedInvitationIds?.length,
                        disabled: !users || users?.items.length < 1,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Users',
                            width: 28,
                            ...(isListEmpty ? null : hcFirstName),
                            disabled: isListEmpty,
                            className: classes.customTableColumnStyle,
                            dataTest: 'name-column',
                        },
                        {
                            title: 'Email Address',
                            width: 15,
                            ...(isListEmpty ? null : hcEmail),
                            disabled: isListEmpty,
                            className: classes.customTableColumnStyle,
                            dataTest: 'email-column',
                        },
                        {
                            title: 'Role',
                            width: 15,
                            ...(isListEmpty ? null : hcRole),
                            disabled: isListEmpty,
                            dataTest: 'role-column',
                        },
                        {
                            title: 'Invited by',
                            width: 15,
                            ...(isListEmpty ? null : hcInvitedby),
                            disabled: isListEmpty,
                            dataTest: 'inviter-column',
                        },
                        {
                            title: 'Invited on',
                            width: 12,
                            ...(isListEmpty ? null : hcInvitedOn),
                            disabled: isListEmpty,
                            dataTest: 'invitation-date-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: handleViewProfile,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (user: UserV2) => ({
                            selectedLabels: user.labels,
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_INVITATION_LABEL,
                            ],

                            onDeleteLabel: (labelId: number) =>
                                onDeleteLabelHandler(labelId, user.id),
                        }),
                        dataTest: 'user-item',
                        cells: [
                            {
                                fieldType: 'AVATAR_FIELD',
                                fieldProps: {
                                    renderTitle: (item: UserV2) =>
                                        `${item.firstName} ${item.lastName}`,
                                    getAvatarProps: (user: UserV2) => ({
                                        customProfile: {
                                            firstName: user.firstName,
                                            lastName: user.lastName,
                                            picture: user.imageUrl,
                                            status: user.status,
                                        },
                                        size: 'sm',
                                        hidePending: true,
                                    }),
                                    dataTest: 'user',
                                    className:
                                        classes.avatarTableCellCustomStyle,
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (item: UserV2) => item.email,
                                    dataTest: 'user-email',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (item: UserV2) =>
                                        item.role?.title,
                                    dataTest: 'user-role',
                                },
                            },
                            {
                                fieldType: 'AVATAR_FIELD',
                                shouldShowEmptyCell: (item: UserV2) =>
                                    !item.invitedBy,
                                fieldProps: {
                                    renderTitle: (item: UserV2) =>
                                        `${item.invitedBy!.firstName} ${
                                            item.invitedBy!.lastName
                                        }`,
                                    getAvatarProps: (user: UserV2) => ({
                                        customProfile: {
                                            firstName:
                                                user.invitedBy!.firstName,
                                            lastName: user.invitedBy!.lastName,
                                            picture: user.invitedBy!.imageUrl,
                                            status: user.invitedBy!.status,
                                        },
                                        size: 'sm',
                                    }),
                                    dataTest: 'inviter',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (item: UserV2) =>
                                        formatDate(item.createdAt),
                                    dataTest: 'invitation-date',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (item: UserV2) => {
                                        return {
                                            open: labelsOpen === item.id,
                                            toggleOpen: () =>
                                                handleToggleLabels(item.id),
                                            selectedLabels: item.labels,
                                            dataTest: 'user-labels',
                                        };
                                    },
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (row: UserV2) => (
                                        <InvitationsListActions
                                            invitation={row}
                                            actionsHelper={actionsHelper}
                                            onViewProfile={handleViewProfile}
                                            onCopyInviteText={onCopyInviteText}
                                            handleRevokeInvitation={
                                                handleRevokeInvitation
                                            }
                                            assignLabelsToUser={(
                                                addedLabelIds,
                                                deletedLabelIds
                                            ) =>
                                                handleUpdateUserLabels(
                                                    addedLabelIds,
                                                    deletedLabelIds,
                                                    row.id
                                                )
                                            }
                                            handleResendInvitation={
                                                handleResendInvitation
                                            }
                                            isHidden={
                                                !!selectedInvitations?.length
                                            }
                                        />
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const InvitationsContainer: FC = () => {
    const state = useInvitationsState();
    const updatedInvitationsState = useUpdateInvitationsState();
    const history = useHistory();
    const company: CompanyInterface = useSelector(getCompanyState);
    const searchInvitationUsers = useSearchInvitationsService();
    const resendInvitation = useResendInvitationService();
    const copyInviteText = useCopyInviteTextService();
    const revokeInvitation = useRevokeInvitationService();

    const labels = useLabelsState();
    const deleteUserLabel = useDeleteUserLabelService(true);
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const updateAllSelectedInvitations =
        useUpdateAllSelectedInvitationsStateService();
    const updateSelectedInvitation = useUpdateSelectedInvitationStateService();
    const updatedLabels = useUpdatedLabelsState();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const actionsHelper = useInvitationActionsHelper();
    const draftEdit = useDraftEditState();
    const getCompany = useGetCompanyService();
    const getCompanyUserStats = useGetCompanyUserStatsService();

    const selectedInvitations = useMemo(
        () => selectSelectedInvitations(state),
        [state]
    );
    const searchState = useSelector(getSearchState);
    const profile = useSelector(getProfileState, isEqual);

    const handleCopyInviteText = useCallback(
        (invitation: UserV2) => {
            copyInviteText(invitation, company);
        },
        [company, copyInviteText]
    );

    useEffect(() => {
        if (!company.id && profile?.companyId) {
            getCompany(profile.companyId!);
        }
    }, [company?.id, profile?.companyId, getCompany]);

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'invitations' }}>
            <WithTeamsContext.Provider value={{ reducerName: 'invitations' }}>
                <Invitations
                    history={history}
                    searchInvitations={searchInvitationUsers}
                    onResendInvitation={resendInvitation}
                    onCopyInviteText={handleCopyInviteText}
                    onRevokeInvitation={revokeInvitation}
                    users={state.data}
                    selectedInvitationIds={state.selectedInvitations}
                    selectedInvitations={selectedInvitations}
                    actionsHelper={actionsHelper}
                    loading={state.loading}
                    updatedInvitations={updatedInvitationsState.data}
                    selectedLabels={labels.selected}
                    setAssignLabelsAction={setAssignLabelsAction}
                    assignedLabels={labels.assignedLabels}
                    updateAllSelectedInvitations={updateAllSelectedInvitations}
                    updateSelectedInvitation={updateSelectedInvitation}
                    deleteUserLabel={deleteUserLabel}
                    updatedLabels={updatedLabels}
                    saveGlobalLabelFilters={saveGlobalLabelFilters}
                    draftEdit={draftEdit}
                    searchState={searchState}
                    profile={profile}
                    getCompanyUserStats={getCompanyUserStats}
                />
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};


export default InvitationsContainer;