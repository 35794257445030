import { useCallback, useMemo, useState } from 'react';
import { compose } from 'redux';
import { Label } from '../../../../constants/interfaces/Label';
import { listToTree } from '../../../../helpers/functions/list-to-tree';
import { treeToArray } from '../../../../helpers/functions/tree-to-list';
import useQueryParamListener from '../../../../helpers/hooks/useQueryParamListener';
import { useSetQueryParam } from '../../../../helpers/hooks/useSetQueryParam';
import { filterLabels, sortLabels } from '../../../labels/tools';
import {
    useResetCollapseAllLabels,
    useSelectLabels,
    useToggleCollapseAllLabels,
    useToggleCollapseLabel,
} from '../../../portableLabels/store/hors/withLabels/services';
 import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
 import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { useFilterHeaderState } from '../../searchable-filter-header/store/reducers';
import { SearchableFilterHeaderKey } from '../../searchable-filter-header/store/types';
import { LABELS_QUERY_PARAM_NAME } from './constants';
import { useLabelsFilterState } from './store/reducers';
import LabelsFilterView from './view';
import { isEqual } from 'lodash';

function LabelsFilterController() {
    const { selected: selectedLabels, collapsed: openLabels } = useLabelsState();
    const { isDisabled } = useLabelsFilterState();
    const { data, loading } = usePortableLabelsState();
    const { searchTerm } = useFilterHeaderState()[SearchableFilterHeaderKey.LABELS] ?? {};
    const toggleCollapse = useToggleCollapseLabel();
    const toggleCollapseAll = useToggleCollapseAllLabels();
    const resetCollapsed = useResetCollapseAllLabels()  
    const select = useSelectLabels();
    const setQueryParam = useSetQueryParam();

    const [processedIds, setProcessedIds] = useState<number[]>([]);

    const processedLabels = useMemo(() => {
        const result = compose<Label[]>(
            filterLabels(searchTerm ?? ''),
            sortLabels
        )(listToTree(data?.items));

        if (result.length > 0) {
            const filteredAndSortedLabelsArray = treeToArray(result);
            const processedDataIds = filteredAndSortedLabelsArray.map((item: any) => item.id!)
            setProcessedIds(processedDataIds);
        }

        return result;
    }, [data?.items, searchTerm]);

    const isAllSelected = selectedLabels.length === processedIds.length;
    const isInSearch = !!searchTerm && searchTerm.length > 0;

    const setSelectedLabels = useCallback((labelIds: number[]) => {
        const paramValue = labelIds.join(',');
        setQueryParam(LABELS_QUERY_PARAM_NAME, paramValue);
        select(labelIds);
    }, [select, setQueryParam]);

    const handleToggleAll = useCallback(() => {
        if (isDisabled) return;

        if (isAllSelected) {
            resetCollapsed();
            setSelectedLabels([]);
        } else {
            if (searchTerm) {
                setSelectedLabels(processedIds);
            }
            else {
                setSelectedLabels(processedIds);
            }
        }
    }, [isDisabled, isAllSelected, resetCollapsed, setSelectedLabels, searchTerm, processedIds]);

    const handleSearch = useCallback((term: string) => {
        if (term) {
            toggleCollapseAll();
        }
    }, [toggleCollapseAll]);

    useQueryParamListener(LABELS_QUERY_PARAM_NAME, labelIds => {
        const ids = labelIds?.length ? labelIds.split(',').map(id => Number(id)) : []
        if (!isEqual(selectedLabels, ids)) {
            select(ids);
        }
    });

    return (
        <LabelsFilterView
            loading={loading}
            labels={processedLabels}
            allLabels={data.items}
            openLabels={openLabels}
            selectedLabels={selectedLabels}
            isAllSelected={isAllSelected}
            isInSearch={isInSearch}
            isDisabled={isDisabled}
            onToggleCollapse={toggleCollapse}
            onSelect={setSelectedLabels}
            onToggleAll={handleToggleAll}
            onSearch={handleSearch}
        />
    );
}

export default LabelsFilterController;