import { useDispatch } from 'react-redux'
import { useCallback } from 'react';
import { useGetRoleplaysApi } from '../../../../api/roleplay';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { Roleplay } from '../../../../constants/interfaces/Roleplay';
import {
    getRoleplaysStart,
    getRoleplaysSuccess,
    getRoleplaysFailure,
} from './actions';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';

/**
 * @function useGetRoleplaysService
 * @returns { CallableFunction }
 */
export const useGetRoleplaysService = () => {
    const dispatch = useDispatch();
    const getRoleplaysApi = useGetRoleplaysApi();
    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback((searchParams) => {
        dispatch(getRoleplaysStart());
        const sp = {
            offset: searchParams.offset,
            limit: searchParams.limit,
            query: searchParams.searchTerm,
            companyId: searchParams.companyId
        }
        return getRoleplaysApi(sp)
            .then((data: ListResult<Roleplay>) => {                
                dispatch(getRoleplaysSuccess(data));
            })
            .catch((error: ErrorResult) => {
                dispatch(getRoleplaysFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, getRoleplaysApi, showMessage]);
}