import { SidebarFiltersConfig } from '../../../features/filters/filters-sidebar/types';

export const TeamLeaderActivityReportFiltersConfig: SidebarFiltersConfig[] = [
    {
        filterType: 'MULTIPLE_SELECT_TEAMS',
    },
    {
        filterType: 'LABELS',
    },
    {
        filterType: 'DATE',
    }
];