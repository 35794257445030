import { useCallback } from 'react';
import { useHistory } from '../../../../../tools/router';
import ROUTES from '../../../../../routes/routes';
import FurtherInstructionsView from './view';

function FurtherInstructionsController () {
    const history = useHistory();

    /**
     * @function handleSubmit - redirects to login page
     * @returns { void }
     */    
    const handleSubmit = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    }, [history]);

    return <FurtherInstructionsView onSubmit={handleSubmit} />
}

export default FurtherInstructionsController;