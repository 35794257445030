import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const OverdueFlag = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow, dataTest }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-test={dataTest}
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.5 7.13v7.33h2.514V16H2.5A1.5 1.5 0 0 1 1 14.5v-11A1.5 1.5 0 0 1 2.5 2h1.25V.75A.76.76 0 0 1 4.5 0a.76.76 0 0 1 .75.71V2h5.5V.75A.76.76 0 0 1 11.5 0a.76.76 0 0 1 .75.71V2h1.25A1.5 1.5 0 0 1 15 3.46V5h-1.5V3.46h-11v2.17H8v1.5H2.5z"
                    fill="#EC513D"
                />
                <path
                    d="M11.62 6c-1.051.283-2.356 2.59-1.288 4.77.211.431.175.764.001.94-.284.29-1.728-.164-2.235-1.7C7.42 10.424 7 11.314 7 12.234 7 14.438 8.845 16 11 16c2.154 0 4-1.57 4-3.767 0-1.342-.694-2.046-1.422-2.786-.758-.77-1.865-1.845-1.957-3.447z"
                    fill="#EC513D"
                />
            </svg>
        );
    }
);

export default OverdueFlag;
