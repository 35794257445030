import styled from 'styled-components';

export const Title = styled.div`
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--ps-white-1);
    width: 100%;
    text-align: center;
    padding-top: 12px;
`;

export const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    text-align: center;
    padding-top: 8px;
    padding-bottom: 24px;
`;
