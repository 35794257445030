import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import AvatarPlaceholder from '../../../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';

export const Container = styled.div`
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 ${props => props.theme.Colors.blackTwo5};
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Header = styled.div`
    margin-bottom: 6px;
`;

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    height: 24px;
`;

export const TeamLeaderIcon = styled.div`
    width: 14px;
    height: 12px;
    display: flex;
    margin-left: 4px;
    margin-right: 8px;
    margin-top: -3px;
    flex-shrink: 0;
    color: ${props => props.theme.Colors.macaroniAndCheese};
`;

export const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
`;

export const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

export const ToggleWrapper = styled.span``;

export const TeamLeaders = styled.p`
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 11px;
    padding: 0;
    margin: 0;
`;

export const useStyles = makeStyles(theme => ({
    scrollWrapperStyle: {
        height: '100% !important',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },

    tableWrapperStyle: {
        height: '70vh',
    },

    tableBodyContainerStyle: {
        maxHeight: 'calc(100vh - 358px)',
    },

    customCellStyle: {
        padding: '0px !important',
        width: '97%',
    },

    tableToolsWrapperStyle: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },

    tableToolsRootStyle: {
        padding: '8px 0px !important',
    },

    tableToolsLeftClassName: {
        flex: '1 1 0%',
    },

    searchInputStyle: {
        flex: '1 1 0%',
    },
}));
