import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Pencil = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 12 12"
                className={className}
                width="12"
                height="12"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="none"
            >
                <path 
                    fillRule="evenodd"
                    clipRule="evenodd" 
                    d="M11.364.636a2.173 2.173 0 0 0-3.072 0l-.307.307-.614.614-2.765 2.765L.614 8.313 0 11.385.613 12l3.073-.615.613-.614.615-.613 2.764-2.765.614-.614 3.072-3.072a2.172 2.172 0 0 0 0-3.071zM4.299 9.543l2.765-2.765.614-.614 2.15-2.15L7.987 2.17 5.22 4.935 2.456 7.7l1.843 1.843zM1.842 8.314l-.427.427-.46 2.305 2.303-.462.427-.427-1.843-1.843zM8.6 1.557 10.443 3.4l.307-.307a1.305 1.305 0 0 0 0-1.843 1.305 1.305 0 0 0-1.843 0l-.307.307z" 
                    fill="#6D7F8C"/>
            </svg>
        );
    }
);

export default Pencil;
