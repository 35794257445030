import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    flex: 1;
    position: relative;
`;

const ChartBoxBody: FC<{ className?: string }> = ({ children, className }) => {
    return <Container className={className}>{children}</Container>;
};

export default ChartBoxBody;
