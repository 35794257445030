import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { Cross } from '../../icons/Cross';

import {
    ModalBackground,
    ModalContainer,
    ModalTitle,
    CrossContainer,
    ModalHeader,
} from './styles';
import { ModalWithCrossProps } from './types';

const ModalWithCrossView: FC<ModalWithCrossProps> = props => {
    const {
        title,
        titleStyle,
        titleContent,
        width,
        onClose,
        children,
        dataTest,
        zIndex,
        hideCross
    } = props;
    const container = document.querySelector('body') as HTMLElement;

    return createPortal(
        <ModalBackground zIndex={zIndex}>
            <ModalContainer
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                width={width}
                data-test={dataTest}
            >
                {!hideCross && <ModalHeader>
                    {title ? (
                        <ModalTitle style={titleStyle} data-test={dataTest && `${dataTest}-title`}>
                            {title}
                        </ModalTitle>
                    ) : titleContent}
                    <CrossContainer onClick={onClose} data-test={dataTest && `${dataTest}-close`}>
                        <Cross />
                    </CrossContainer>
                </ModalHeader>}
                {children}
            </ModalContainer>
        </ModalBackground>,
        container
    );
};

export default ModalWithCrossView;