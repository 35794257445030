import React, { FC } from 'react';
import styled from 'styled-components';
import {
    getCountries,
    getCountryCallingCode,
    // @ts-ignore
} from 'react-phone-number-input/input';
import { en } from './locales';
import { Variables } from '../../../../theme/variables';
import DownArrow from '../../../icons/DownArrow';
import UpArrow from '../../../icons/UpArrow';
import { CountryList } from './CountryList';

const Container = styled.div<{ disabled?: boolean }>`
    ${props =>
        props.disabled &&
        `
        pointer-events: none;
    `}
`;

const StyledDropDownList = styled(CountryList)``;

const ListItem = styled.div`
    height: 32px;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    &:active {
        opacity: 0.7;
    }
    &:hover {
        background: ${props => props.theme.Colors.whiteFive};
    }
    &.--active {
        background: ${props => props.theme.Colors.whiteFive};
    }
`;

const ListCountryFlag = styled.div`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

const Flag = styled.img`
    width: 100%;
`;

const ListCountryNameContainer = styled.div`
    position: relative;
    margin-left: 8px;
    min-width: 90px;
    flex: 1;
    display: flex;
    align-items: center;
`;

const ListCountryName = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.Colors.black};
    display: flex;
    align-items: center;
`;

const ListCountryCode = styled.div`
    width: 32px;
    margin-left: 8px;
    flex-shrink: 0;
    color: ${props => props.theme.Colors.steelGrey};
`;

const CountrySelectorWrapper = styled.div`
    height: 100%;
    margin-right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const FlagContainer = styled.div`
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const CountryCode = styled.div<{ fontColor: string; fontSize: string }>`
    height: 16px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.fontColor};
    font-size: ${props => props.fontSize};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

const ArrowsContainer = styled.div`
    height: 100%;
    margin-left: 4px;
`;

const ArrowIcon = styled.div<{ disabled?: boolean }>`
    height: 6px;
    width: 6px;
    display: flex;
    align-items: center;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

const StyledDownArrow = styled(DownArrow)``;

const StyledUpArrow = styled(UpArrow)``;

const countries = getCountries().filter((country: string) => country !== 'US');
countries.unshift('US');

const CountrySelector: FC<{
    open: boolean;
    disabled?: boolean;
    setOpen(open: boolean): void;
    selectedCountry: keyof typeof en;
    setSelectedCountry(country: string): void;
    fontColor?: string;
    fontSize?: string;
}> = ({
    open,
    disabled,
    setOpen,
    selectedCountry,
    setSelectedCountry,
    fontColor,
    fontSize,
}) => {
    return (
        <Container disabled={disabled}>
            <CountrySelectorWrapper onClick={() => setOpen(true)}>
                <FlagContainer>
                    <Flag
                        alt="United States"
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountry.toString()}.svg`}
                    />
                </FlagContainer>
                <CountryCode
                    fontSize={fontSize ? fontSize : '13px'}
                    fontColor={
                        disabled
                            ? Variables.Colors.cloudyBlue
                            : fontColor
                            ? fontColor
                            : Variables.Colors.white
                    }
                >
                    +{getCountryCallingCode(selectedCountry as any)}
                </CountryCode>
                <ArrowsContainer>
                    <ArrowIcon disabled={disabled}>
                        {open ? <StyledUpArrow /> : <StyledDownArrow />}
                    </ArrowIcon>
                </ArrowsContainer>
            </CountrySelectorWrapper>
            <StyledDropDownList open={open} toggleOpen={setOpen}>
                {countries.map((country: string) => (
                    <ListItem
                        key={country}
                        onClick={() => {
                            setSelectedCountry(country);
                            setOpen(false);
                        }}
                        className={
                            country === selectedCountry ? '--active' : undefined
                        }
                    >
                        <ListCountryFlag>
                            <Flag
                                alt={country}
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country.toString()}.svg`}
                            />
                        </ListCountryFlag>
                        <ListCountryNameContainer>
                            <ListCountryName>{en[country]}</ListCountryName>
                        </ListCountryNameContainer>
                        <ListCountryCode>
                            +{getCountryCallingCode(country as any)}
                        </ListCountryCode>
                    </ListItem>
                ))}
            </StyledDropDownList>
        </Container>
    );
};

export default CountrySelector;
