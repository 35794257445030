import * as Yup from 'yup';

export const COMPANY_NAME_MAX_LENGTH = 80;

export const MAX_LENGTH_EXCEEDED_TEXT = 'Character limit reached'

export const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Company name is required')
        .max(COMPANY_NAME_MAX_LENGTH, MAX_LENGTH_EXCEEDED_TEXT),
});

export const NAME_ALREADY_EXISTS_ERROR = 'Entity "company:name" already exists';

export const API_ERROR_MESSAGE = {
    [NAME_ALREADY_EXISTS_ERROR]: 'The company name is taken',
}

export const NO_ACCOUNT_OWNER_TEXT = 'No Account Owner';