import { FC, memo, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import dayjs from 'dayjs';
import { PractisSetWithDueDate } from '../../constants/interfaces/Draft';
import { usePractisSetsState } from '../portablePractisSets/store/hors/withPractisSets/states';
import {
    calculateCreatedUpdatedEnrollmentIds,
    useCalculateDeletedEnrollments,
} from '../portablePractisSets/tools';
import { useUserPerformanceState } from '../users/store/states';
import AssignPractisSetsView from './single-users.view';
import { AssignPractisSetsControllerProps } from './type';
import { DATE_FORMAT } from '../../constants/interfaces/DueDates';
import { useSelectMultiplePractisSets } from '../portablePractisSets/store/hors/withPractisSets/services';
import AssignPractisSetsMultipleUsersView from './multiple-users.view';
import {
    useCreateUpdateEnrollmentDueDate,
    useDeleteEnrollmentsApi,
    useEnrollPractisSetsToUserApi,
} from '../../api/enrollments';
import { EnrollmentType } from '../../api/enrollments/types';
import { useShowMessage } from '../../ui/components/ErrorMessages/ErrorMessages';
import {
    useEnrollUnenrollAllUsersBulkActionService,
    useEnrollUnrollMultipleUsersBulkActionService,
} from './services/EnrollmentBulkService';
import { SearchParams } from '../../constants/interfaces/filters';

const AssignPractisSetsController: FC<
    AssignPractisSetsControllerProps
> = props => {
    const {
        isFromBulkAction,
        triggerRef,
        horizontalOffset,
        verticalOffset,
        isOpen,
        onClose,
        users,
        searchQuery,
        isSelectAll,
        successApplyPractisSetsCallback,
        totalCount,
    } = props;

    const performanceState = useUserPerformanceState();
    const { selected: selectedPractisSetsState } = usePractisSetsState();
    const setPractisSets = useSelectMultiplePractisSets();
    const enrollPractisSets = useEnrollPractisSetsToUserApi();
    const updateEnrollmentsDueDate = useCreateUpdateEnrollmentDueDate();
    const unEnrollPractisSets = useDeleteEnrollmentsApi();
    const getDeletedEnrollmentIds = useCalculateDeletedEnrollments();
    const showMessage = useShowMessage();

    const selectedPractisSets: any[] =
        (performanceState?.portablePractisSets as any)?.map(
            (practisSet: {
                id: any;
                dueDate: dayjs.ConfigType | undefined;
            }): any => {
                return {
                    practisSetId: practisSet.id ?? 0,
                    dueDate: practisSet?.dueDate
                        ? dayjs(practisSet?.dueDate).format(DATE_FORMAT)
                        : null,
                };
            }
        ) ?? [];

    const [initialPractisSets, setInitialPractisSets] =
        useState<PractisSetWithDueDate[]>(selectedPractisSets);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

    const isMultipleUsersSelected =
        (isFromBulkAction && users?.length !== 1) || users?.length !== 1;

    const successCallback = () => {
        successApplyPractisSetsCallback?.();
        onClose();
        setIsSaving(false);
    };

    const handleEnrollUnenrollAllUsersBulkActionService =
        useEnrollUnenrollAllUsersBulkActionService(
            {
                searchParams: searchQuery as SearchParams,
                teamId: searchQuery?.teamId || null,
                selectedPractisSets: selectedPractisSetsState,
                users: isSelectAll ? [] : (users as number[]),
            },
            totalCount,
            successCallback
        );

    const handleEnrollUnenrollMultipleUsersBulkActionService =
        useEnrollUnrollMultipleUsersBulkActionService(
            users ?? [],
            selectedPractisSetsState,
            successCallback
        );

    /**
     * @function handleApplyPractisSets
     * @returns { void }
     */
    const handleApplyPractisSets = (): void => {
        if (!isMultipleUsersSelected) {
            const enrollUnEnrollPromiseChainArray = [];
            const deletedEnrollmentIds = getDeletedEnrollmentIds(
                performanceState.portablePractisSets,
                selectedPractisSetsState.map(
                    practisSetWithDueDate => practisSetWithDueDate.practisSetId
                ),
                initialPractisSets.map(
                    practisSetWithDueDate => practisSetWithDueDate.practisSetId
                )
            );

            const { addedPractisSets, updatedEnrollments } =
                calculateCreatedUpdatedEnrollmentIds(
                    performanceState.portablePractisSets!,
                    selectedPractisSetsState,
                    initialPractisSets
                );

            const assignedPractisSets = addedPractisSets.map(item => ({
                practisSetId: item.practisSetId,
                dueDate: item?.dueDate
                    ? dayjs(item.dueDate).format(DATE_FORMAT)
                    : null,
                userId: users?.[0],
            })) as EnrollmentType[];

            if (!isEmpty(assignedPractisSets)) {
                enrollUnEnrollPromiseChainArray.push(
                    enrollPractisSets(assignedPractisSets)
                );
            }

            if (!isEmpty(deletedEnrollmentIds)) {
                enrollUnEnrollPromiseChainArray.push(
                    unEnrollPractisSets(deletedEnrollmentIds)
                );
            }

            if (!isEmpty(updatedEnrollments)) {
                enrollUnEnrollPromiseChainArray.push(
                    updateEnrollmentsDueDate(updatedEnrollments)
                );
            }

            setIsSaving(true);

            Promise.all(enrollUnEnrollPromiseChainArray)
                .then(() => {
                    showMessage(`Changes have been saved`, 'success');
                    successApplyPractisSetsCallback?.();
                })
                .catch(error => {
                    showMessage(error, 'error');
                });

            onClose();
            setIsSaving(false);
        } else if (
            isFromBulkAction &&
            isSelectAll &&
            users?.length !== totalCount
        ) {
            handleEnrollUnenrollAllUsersBulkActionService();
        } else {
            handleEnrollUnenrollMultipleUsersBulkActionService();
        }
    };

    useEffect(() => {
        if (!isMultipleUsersSelected) {
            let practisSetsToSet = selectedPractisSets ?? [];
            setInitialPractisSets(practisSetsToSet);
            setPractisSets(practisSetsToSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEqual(selectedPractisSetsState, initialPractisSets)) {
            setIsApplyButtonDisabled(false);
        } else {
            setIsApplyButtonDisabled(true);
        }
    }, [initialPractisSets, selectedPractisSetsState]);

    useEffect(() => {
        return () => {
            setInitialPractisSets([]);
            setPractisSets([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isFromBulkAction ? (
        <AssignPractisSetsMultipleUsersView
            users={users}
            triggerRef={triggerRef}
            isOpen={isOpen}
            isSaving={isSaving}
            isApplyButtonDisabled={isApplyButtonDisabled}
            onClose={onClose}
            handleApplyPractisSets={handleApplyPractisSets}
        />
    ) : (
        <AssignPractisSetsView
            users={users}
            triggerRef={triggerRef}
            horizontalOffset={horizontalOffset}
            verticalOffset={verticalOffset}
            isOpen={isOpen}
            isSaving={isSaving}
            isApplyButtonDisabled={isApplyButtonDisabled}
            onClose={onClose}
            handleApplyPractisSets={handleApplyPractisSets}
        />
    );
};

export default memo(AssignPractisSetsController);