import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ViewPractisSetIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.573 2.573A.25.25 0 0 1 1.75 2.5h12.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H1.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .073-.177zM1.75 1A1.75 1.75 0 0 0 0 2.75v1.5A1.75 1.75 0 0 0 1.75 6h12.5A1.75 1.75 0 0 0 16 4.25v-1.5A1.75 1.75 0 0 0 14.25 1H1.75zm0 6h.75v6.25c0 .138.112.25.25.25h10.5a.25.25 0 0 0 .25-.25V7H15v6.25A1.75 1.75 0 0 1 13.25 15H2.75A1.75 1.75 0 0 1 1 13.25V7h.75zm8 2.5H11V8H5v1.5h4.75z"
                    fill="currentColor"
                />
            </svg>
        );
    }
);

export default ViewPractisSetIcon;
