import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ScriptAction from './components/ScriptAction/ScriptAction';
import ScriptLineContent from './components/ScriptLineContent/ScriptLineContent';
import { Scenario } from '../../../../../../constants/interfaces/Scenario';
import { getProfileState } from '../../../../../UserProfile/store/reducers';
import { getCompanyState } from '../../../../../CompanySettings/store/reducers';
import { useGetCompanyService } from '../../../../../CompanySettings/store/services';
import { useSelector } from 'react-redux';
import ScenarioScriptHeader from './ScenarioScriptHeader/ScenarioScriptHeader';
import { EditModeContext } from '../../../NewScenario';
import { EditModeValues } from '../../../../../../constants/enums/EditModeValues';
import AvatarPlaceholder from '../../../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import Customer from '../../../../../../ui/icons/Customer';
import { CompanyInterface } from '../../../../../../constants/interfaces/Company';

export interface RightPanelInterface {
    scenario: Scenario;
    createScriptLine: any;
    company?: CompanyInterface;
}

const StyledScenarioScript = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    min-width: 400px;
`;

const UserAvatarPanel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const StyledCustomer = styled.div<{ reverse?: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
`;

const ImageContainer = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 4px;
    overflow: hidden;
    background: ${props => props.theme.Colors.white};
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledImage = styled.img`
    height: 100%;
    width: 100%;
`;

const StyledCustomerImage = styled(Customer)`
    height: 20px;
    width: 20px;
`;

const StyledText = styled.p`
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    margin: 0 8px;
`;

const ScriptsBody = styled.div``;

const PlaceholderWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ScenarioScripts = ({
    scenario,
    createScriptLine,
    company,
}: RightPanelInterface) => {
    const edit = useContext(EditModeContext);
    let logo;
    if (company?.logoUrl) {
        logo = <StyledImage src={company.logoUrl} alt="company" data-test="scenario-rep-logo" />;
    } else if (company) {
        logo = (
            <AvatarPlaceholder
                profile={company}
                size={'sm'}
                customWrapper={PlaceholderWrapper}
                dataTest="scenario-rep-logo"
            />
        );
    }
    return (
        <StyledScenarioScript>
            <ScenarioScriptHeader
                totalDuration={
                    scenario && scenario.script && scenario.script.totalDuration
                }
                scriptLines={scenario.script && scenario.script.lines}
            />
            <ScriptsBody>
                <UserAvatarPanel>
                    <StyledCustomer>
                        <ImageContainer data-test="scenario-customer-avatar">
                            <StyledCustomerImage />
                        </ImageContainer>
                        <StyledText data-test="scenario-customer-label">Customer</StyledText>
                    </StyledCustomer>
                    <StyledCustomer reverse>
                        <ImageContainer>{logo}</ImageContainer>
                        <StyledText data-test="scenario-rep-label">Rep</StyledText>
                    </StyledCustomer>
                </UserAvatarPanel>
                {scenario.script && (
                    <ScriptLineContent script={scenario.script.lines} />
                )}
                {edit.mode === EditModeValues.EDIT && (
                    <ScriptAction handleAdd={createScriptLine} />
                )}
            </ScriptsBody>
        </StyledScenarioScript>
    );
};

export const ScenarioScriptsContainer: FC<{
    scenario: Scenario;
    createScriptLine: any;
}> = ({ scenario, createScriptLine }) => {
    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);
    const getCompany = useGetCompanyService();

    useEffect(() => {
        if (!company?.id && profile?.companyId) {
            getCompany(profile.companyId);
        }
    }, [company?.id, getCompany, profile?.companyId]);

    return (
        <ScenarioScripts
            scenario={scenario}
            createScriptLine={createScriptLine}
            company={company}
        />
    );
};

export default ScenarioScriptsContainer;
