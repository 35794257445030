import React, { FC } from 'react';
import styled from 'styled-components';
import RightArrow from '../../../../ui/icons/RightArrow';


const StyledCardContent = styled.div<{ image?: string }>`
    height: 100%;
    width: 100%;
    background-image: url(${props => props.image});
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
`;

const Info = styled.div`
    height: 144px;
    border-radius: 32px;
    align-self: flex-end;
    background: ${props => props.theme.Colors.white};
    padding: 32px;
    box-sizing: border-box;
    position: relative;
`;

const Description = styled.div`
    font-size: 17px;
    color: ${props => props.theme.Colors.black};
`;

const NextButton = styled.div<{ disabled?: boolean }>`
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background: ${props => props.theme.Colors.whiteTwo};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    bottom: 16px;
    cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
    user-select: none;
    &:active {
        ${props => !props.disabled && ' opacity: 0.7'};
    }
`;

const StyledArrowIcon = styled(RightArrow)`
    height: 20px;
    width: 20px;
    color: ${props => props.theme.Colors.black};
`;

const LandingCard: FC<{
    image?: string;
    description: string;
    goNext?: () => void;
}> = ({ image, description, goNext }) => {
    return (
        <StyledCardContent image={image}>
            <Info>
                <Description>{description}</Description>
                <NextButton onClick={goNext} disabled={!goNext}>
                    <StyledArrowIcon />
                </NextButton>
            </Info>
        </StyledCardContent>
    );
};

export default LandingCard;
