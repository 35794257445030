import React, { FC, useEffect } from 'react';

import {
    useClearTeams,
    useDeselectAllTeams,
    useResetSelectedTeams,
    useSaveTeams,
    useSearchTeamsService,
    useSelectAllTeams,
    useSelectMultipleTeams,
} from '../store/hors/withTeams/services';
import { useTeamsState } from '../store/hors/withTeams/states';
import { PortableTeams, PortableTeamsProps } from './PortableTeams';
import { usePortableTeamsState } from '../store/states';

type Props = Pick<
    PortableTeamsProps,
    | 'filtersTitle'
    | 'onSetSelected'
    | 'showSelectActions'
    | 'showSearch'
    | 'showSave'
> & {
    fetchOnMount?: boolean;
    resetSelectedOnUnmount?: boolean;
    showDefaultMembers?: boolean;
    selectedTeams?: number[];
};

export const PortableTeamsWithStore: FC<Props> = ({
    onSetSelected,
    selectedTeams,
    fetchOnMount = true,
    resetSelectedOnUnmount = true,
    showDefaultMembers,
    ...rest
}) => {
    const search = useSearchTeamsService();
    const { selected } = useTeamsState();
    const { data, loading } = usePortableTeamsState();
    const clearHandler = useClearTeams();
    const saveHandler = useSaveTeams();
    const select = useSelectMultipleTeams();
    const selectAll = useSelectAllTeams();
    const deselectAll = useDeselectAllTeams();
    const resetSelected = useResetSelectedTeams();

    useEffect(() => {
        if (fetchOnMount) {
            search();
        }

        return () => {
            if (resetSelectedOnUnmount) {
                resetSelected();
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedTeams) {
            selectAll(selectedTeams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PortableTeams
            teams={data}
            selectedList={selected}
            loading={loading}
            onClearSelected={() => {
                onSetSelected && onSetSelected([]);
                clearHandler();
            }}
            onSave={saveHandler}
            onSetSelected={(team) => { 
                onSetSelected && onSetSelected(team);
                select(team);
             }}
            showDefaultMembers={showDefaultMembers}
            onSelectAll={(teams) => { 
                onSetSelected && onSetSelected(teams);
                selectAll(teams);
             }}
            onDeselectAll={deselectAll}
            {...rest}
        />
    );
};
