import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Voice = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g clip-path="url(#7jt8yiv5ra)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="m4.852.878 2.852 4.888a.25.25 0 0 1-.147.366l-2.807.802V8.25H.25V9a4.759 4.759 0 0 0 3.18 4.487A4.269 4.269 0 0 1 1 14.25v1.5c1.797 0 3.51-.848 4.592-2.288a.757.757 0 0 0-.606-1.212 3.246 3.246 0 0 1-3.149-2.5H6.25V8.066l1.719-.491a1.75 1.75 0 0 0 1.03-2.565L6.15.122 4.851.878zM1 2.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm9.508 8.163c0-1.018-.32-1.959-.864-2.731l1.227-.864a6.225 6.225 0 0 1 1.137 3.595c0 1.51-.537 2.897-1.43 3.978l-1.156-.955a4.727 4.727 0 0 0 1.086-3.023zm1.379-4.231c.63.896 1.62 2.327 1.62 4.231 0 2.257-.86 3.482-1.585 4.36l1.156.955c.847-1.025 1.93-2.579 1.93-5.315 0-2.405-1.248-4.177-1.882-5.076l-.013-.019-1.226.864z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="7jt8yiv5ra">
                        <path fill="#fff" transform="matrix(-1 0 0 1 15 0)" d="M0 0h15v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default Voice;
