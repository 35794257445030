import { useCallback } from 'react';

export const useGetSelectionCharacterOffsetWithin = () => {
    return useCallback((element: any) => {
        let start = 0;
        let end = 0;
        const doc = element.ownerDocument || element.document;
        const win = doc.defaultView || doc.parentWindow;
        let sel: any;
        let selectionRect;
        if (typeof win.getSelection !== 'undefined') {
            sel = win.getSelection();
            if (sel.rangeCount > 0) {
                const range = win.getSelection().getRangeAt(0);
                selectionRect = range.getBoundingClientRect();
                const preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.startContainer, range.startOffset);
                start = preCaretRange.toString().length;
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                end = preCaretRange.toString().length;
            }
        } else if (sel && sel === doc.selection && sel.type !== 'Control') {
            const textRange = sel.createRange();
            const preCaretTextRange = doc.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint('EndToStart', textRange);
            start = preCaretTextRange.text.length;
            preCaretTextRange.setEndPoint('EndToEnd', textRange);
            end = preCaretTextRange.text.length;
        }
        return { start: start, end: end, selectionRect: selectionRect };
    }, []);
};

export function getCaretPosition(context: any) {
    const selection = window.getSelection();
    if (!selection) return null;
    const range: any = selection.getRangeAt(0);
    range.setStart(context, 0);
    return range.toString().length;
}

export function getTextNodeAtPosition(root: any, index: any) {
    const NODE_TYPE = NodeFilter.SHOW_TEXT;
    // @ts-ignore
    const treeWalker = document.createTreeWalker(root, NODE_TYPE, function next(
        elem: any
    ): number {
        if (index > root.textContent.length) {
            index -= elem.textContent.length;
            return NodeFilter.FILTER_REJECT;
        }
        return NodeFilter.FILTER_ACCEPT;
    });

    const c = treeWalker.nextNode();
    return {
        node: c ? c : root,
        position: index,
    };
}

export function useSetCaretPosition() {
    return useCallback((element?: any, offset?: any) => {
        const range: any = document.createRange();
        const sel: any = window.getSelection();
        let currentNode = null;
        let currentNodeLength = null;
        let previousNodeLength = null;
        for (let i = 0; i < element.childNodes.length; i++) {
            previousNodeLength = currentNodeLength;
            currentNode = element.childNodes[i];
            currentNodeLength = (
                element.childNodes[i].innerText ||
                element.childNodes[i].textContent
            ).length;
            while (currentNode.childNodes.length > 0) {
                currentNode = currentNode.childNodes[0];
            }

            //calc offset in current node
            if (previousNodeLength) {
                offset -= previousNodeLength;
            }
            //check whether current node has enough length
            if (offset <= currentNodeLength) {
                break;
            }
        }
        //move caret to specified offset
        if (currentNode != null) {
            if (offset > currentNode.length) {
                offset = currentNode.length;
            }
            range.setStart(currentNode, offset);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }, []);
}
