import { useCompanyUserStatsState } from "../../../../../../CompanySettings/store/states";
import { UserLimitWarningModal } from "../UserLimitWarningModal";
import { NoFreeUsersModalViewProps } from "./types";

function NoFreeUsersModalView({
    onClose
}: NoFreeUsersModalViewProps) {
    const userStats = useCompanyUserStatsState().data;

    if (!userStats) return <></>;

    return (
        <UserLimitWarningModal
            title="You can't invite new users"
            description={<>You've reached your company's licensed seats limit of <b data-test="user-limit-value">{userStats.limit}</b>. You may be able to find unaccepted invitations and revoke them, which will free up some seats.<br />
            Alternatively, contact your Practis Support to request an increase.</>}
            quickTip="you can save all these invitations as a Draft for now until the limitation issue is resolved."
            width={388}
            onClose={onClose}
        />
    );
}

export default NoFreeUsersModalView;