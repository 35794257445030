import { FC, memo } from 'react';
import AvatarPlaceholder from '../../../../../components/AvatarPlaceholder/AvatarPlaceholder';
import { userRoleTitle } from '../../../../../../constants/enums';
import { UserProfile } from '../../../../../../constants/interfaces/User';
import {
    StyledUserArea,
    UserInfo,
    UserRole,
    UserName,
    UserProfilePictureSection,
    UserRoleLoading,
    UserNameLoading,
} from './styles';

const UserProfileArea: FC<{
    profile: Partial<UserProfile>;
    open: boolean;
    loading?: boolean;
}> = memo(({ profile, open, loading }) => {
    const dataTest = 'user-profile-area';

    return (
        <StyledUserArea open={open}>
            <UserProfilePictureSection>
                <AvatarPlaceholder profile={profile} size={'sm'} dataTest={`${dataTest}-avatar`}/>
            </UserProfilePictureSection>
            <UserInfo>
                {
                    loading
                    ? <>
                        <UserNameLoading />
                        <UserRoleLoading />
                    </>
                    :
                    <>
                        <UserName data-test={`${dataTest}-name`}>
                            {profile.firstName} {profile.lastName}
                        </UserName>
                        <UserRole data-test={`${dataTest}-role`}>
                            {userRoleTitle(profile)}
                        </UserRole>
                    </>
                }
            </UserInfo>
        </StyledUserArea>
    );
});
export default UserProfileArea;
