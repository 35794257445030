import styled from 'styled-components';

import { Variables } from '../../../../theme/variables';
import { SIZES } from '../constants';

export const Root = styled.div`
    min-width: ${SIZES.MIN_WIDTH}px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 16px 0;
`;

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    @media (max-width: ${Variables.BreakPoints.responsiveSmPlus}px) {
        flex-direction: column;
    }
`;

export const TableToolsLeft = styled.div`
    display: flex;
`;

export const TableToolsRight = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    @media (max-width: ${Variables.BreakPoints.responsiveSmPlus}px) {
        margin-top: 8px;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

export const ItemCount = styled.p`
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
    padding: 0;
    margin: 0;
`;
