import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import NoChartData from '../../../../../../ui/icons/NoChartData';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const Text = styled.div`
    color: ${props => props.theme.Colors.cloudyBlue};
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    max-width: 244px;
    height: 20px;
    margin-bottom: 8px;
    min-width: 155px;
`;

const IconContainer = styled.div<{ customIcon: boolean }>`
    color: ${props => props.theme.Colors.cloudyBlue};
    ${({ customIcon }) =>
        !customIcon &&
        `
            width: 20px;
            height: 20px;
        `}
    margin-bottom: 8px;
`;

const EmptySection: FC<{
    className?: string;
    emptyText?: string;
    icon?: ReactElement;
    dataTest?: string;
}> = ({ children, className, emptyText, icon, dataTest }) => {
    return (
        <Container className={className}>
            <IconContainer customIcon={!!icon} data-test={dataTest && `${dataTest}-icon`}>
                {icon ?? <NoChartData />}
            </IconContainer>
            {emptyText ? (
                <Text data-test={dataTest && `${dataTest}-text`}>{emptyText}</Text>
            ) : (
                <Text data-test={dataTest && `${dataTest}-text`}>Nothing to show here</Text>
            )}
        </Container>
    );
};

export default EmptySection;
