import { useSelector } from 'react-redux';

import { useWithPractisSetsContext } from './context/useWithPractisSetsContext';
import { PractisSetsState } from './reducer';
import { DEFAULT_LABELS_SCOPE } from '../../../../portableLabels/store/hors/withLabels/scopes';
import { isObject } from 'lodash';

export const usePractisSetsState = (): PractisSetsState => {
    const { reducerName, scope } = useWithPractisSetsContext();
    const appState = useSelector(state => state);

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithPractisSetsContext.Provider props'
        );
    }

    const labelsState: PractisSetsState | undefined =
        // @ts-ignore
        // TODO implement `_practisSets?: PractisSetsState` field into each reducer (create general type)
        // interface GeneralReducer {
        //     ...
        //     _practisSets?: StateWithPractisSets;
        // }
        appState[reducerName]?._practisSets?.[scope || DEFAULT_LABELS_SCOPE];

    if (labelsState) {
        labelsState.selected = labelsState?.selected.map((item) => isObject(item) ? item : {practisSetId: item, dueDate: null}) || []
    }

    if (!labelsState) {
        throw new Error(
            `Wrap your reducer ${reducerName} via "withPractisSetsReducer" HOR`
        );
    }

    return labelsState;
};
