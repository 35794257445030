import React, { FC, useCallback, useState } from 'react';
import { PractisSetWithDueDate } from '../../../constants/interfaces/Draft';
import styled from 'styled-components';
import { Button } from '../../../ui/components/Button';
import { Fieldset } from '../../../ui/components/Fieldset';
import { PortablePractisSetsWithStore } from '../index';
import { useSavePractisSets } from '../store/hors/withPractisSets/services';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const PractisSetsContainer = styled.div`
    width: 348px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 16px;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin-right: 16px;
`;

export const AssignPractisSets: FC<{
    onSuccessApply: () => void;
}> = ({ onSuccessApply }) => {
    const savePractisSets = useSavePractisSets();
    const [, setPractisSetIds] = useState<PractisSetWithDueDate[]>([]);
    const [applyDisabled, setApplyDisabled] = useState(true);

    const onPractissetHandler = useCallback(
        (ids: PractisSetWithDueDate[]) => {
            setPractisSetIds(ids);
            setApplyDisabled(false);
        },
        [setPractisSetIds]
    );


    const onApplyHandler = useCallback(() => {
        savePractisSets();
        onSuccessApply();
    }, [onSuccessApply, savePractisSets]);

    return (
        <Container>
            <Wrapper>
                <Fieldset withoutBorder>
                    <PractisSetsContainer>
                        <PortablePractisSetsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                            onSetSelected={onPractissetHandler}
                        />
                    </PractisSetsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <ButtonWrapper>
                    <Button
                        width="96px"
                        action={() => onSuccessApply()}
                        variant={'inverse'}
                        dataTest="cancel-button"
                    >
                        Cancel
                    </Button>
                </ButtonWrapper>
                <Button
                    width="96px"
                    disabled={applyDisabled}
                    action={() => onApplyHandler()}
                    dataTest="apply-button"
                >
                    Apply
                </Button>
            </Actions>
        </Container>
    );
};
