import { createContext, useContext } from 'react';

export interface LocalStorage {
    get(key: string): any;
    set(key: string, data: any): string | null;
    clear(): boolean;
    remove(key: string): boolean;
}

export const LocalStorageContext = createContext<LocalStorage>(undefined!);

export function useLocalStorage() {
    return useContext(LocalStorageContext);
}
