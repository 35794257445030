import { useMemo } from 'react';
import { UserProfile } from '../../../constants/interfaces/User';
import { ROLE_NAMES } from '../../../constants/enums';
import { useLoggedInUserProfileState } from "../store/states";

export enum UserRoleLevel {
    NONE = 0,
    USER = 1,
    COMPANY_ADMIN = 2,
    PRACTIS_ADMIN = 3,
};

const userRoleLevelMap = {
    [ROLE_NAMES.USER]: UserRoleLevel.USER,
    [ROLE_NAMES.COMPANY_ADMIN]: UserRoleLevel.COMPANY_ADMIN,
    [ROLE_NAMES.PRACTIS_ADMIN]: UserRoleLevel.PRACTIS_ADMIN,
};

export function getRoleLevel(roleName: string | undefined): UserRoleLevel {
    return !!roleName
        ? (userRoleLevelMap[roleName] || UserRoleLevel.NONE)
        : UserRoleLevel.NONE;
}

export function getUserRoleLevel(user: UserProfile | undefined): UserRoleLevel {
    return getRoleLevel(user?.role?.name);
}

function createUserRolesHelper(
    loggedInUser: UserProfile | undefined
) {
    const loggedInUserRoleLevel = getUserRoleLevel(loggedInUser);

    const canViewRole = (roleName: string | undefined) =>
        loggedInUserRoleLevel >= getRoleLevel(roleName);
    
    return {
        canViewRole,
    };
}

export type UserRolesHelper = ReturnType<typeof createUserRolesHelper>;

export function useLoggedInUserRolesHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createUserRolesHelper(loggedInUser),
        [loggedInUser]
    );
}
