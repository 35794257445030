import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { CompanyInterface } from '../constants/interfaces/Company';
import { UserInterface } from '../constants/interfaces/User';
import Storage from '../services/Storage';
import ROUTES from './routes';

const isAuthorized = () => {
    return !!Storage.get('token');
};

const RedirectToUrl: React.FC<{ url: string }> = ({ url }) => {
    window.location.href = url;
    return null;
};

export interface PrivateRouterProps extends RouteProps {
    component?: any;
    customPath?: string;
    path?: string;
    profile?: UserInterface;
    company?: CompanyInterface;
    editable?: boolean;
    url?: string;
    propsData?: any;
}

const PrivateRoute: FC<PrivateRouterProps> = ({
    component: Component,
    customPath,
    url,
    children,
    propsData,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return isAuthorized() ? (
                    Component ? (
                        <Component {...props} {...rest} {...propsData} />
                    ) : (
                        <>{children}</>
                    )
                ) : url ? (
                    <RedirectToUrl url={url} />
                ) : (
                    <Redirect
                        to={{
                            pathname: customPath
                                ? customPath
                                : ROUTES.AUTH_PAGES.LOGIN,
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};

export default PrivateRoute;
