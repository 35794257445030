import dayjs from "dayjs";
import { useState } from "react";
import ROUTES from "../../../../routes/routes";
import { useHistory } from "../../../../tools/router";
import TextInputWithButtons from "../../components/TextInputWithButtons/TextInputWithButtons";
import BackArrow from "../../../../ui/icons/BackArrow";
import PencilIcon from "../../../../ui/icons/PencilIcon";
import PencilIconActive from "../../../../ui/icons/PencilIconActive";
import { NameContainer, OwnerName } from "../../RoleplaysList/styles";
import { useUpdateRoleplayService } from "../store/services";
import { useRoleplayState } from "../store/states";
import { Header, HeaderContainer, IconContainer, IconCountainer, Title } from "../styles";
import HeaderSkeleton from "../../components/Skeletons/HeaderSkeleton";
import useGoogleAnalyticsWithRoleplay from "../../GoogleAnalytics";

function RoleplayHeader({ isDisabled }: { isDisabled: boolean }) {
    const history = useHistory();
    const { data: roleplay, isLoading } = useRoleplayState();
    const updateRoleplay = useUpdateRoleplayService();
    const [editmode, setEditMode] = useState<{ [key: string]: string }>({});
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();

    const closeEditMode = () => {
        setEditMode({});
    }

    const handleNameChange = () => {
        roleplay && editmode['title'] && updateRoleplay(roleplay?.id.toString(), {
            ...roleplay,
            title: editmode['title'],
        }).then(() => {
            closeEditMode();
            trackEventWithRoleplay('roleplay_screen_title_updated');
        });
    }

    const isRequiredTitle = editmode['title'] === '';
    
    return <HeaderContainer>{editmode['title'] === undefined ?
        <>
            <IconContainer onClick={() => {
                history.push(ROUTES.ROLEPLAYS.LIST_OF_ROLEPLAYS)
            }} data-test="back-button"><BackArrow /></IconContainer>
            {
                isLoading ? <HeaderSkeleton /> : <Header>
                <Title>
                    <span data-test="roleplay-title">{roleplay?.title}</span>
                    <IconCountainer onClick={() => !isDisabled && roleplay?.title && setEditMode({ 'title': roleplay?.title })} isDisabled={isDisabled} data-test={`rename-edit-icon`}>
                        {!isDisabled ? <PencilIconActive /> : <PencilIcon />}
                    </IconCountainer>
                </Title>
                <NameContainer>
                    {roleplay &&
                        <><OwnerName data-test="roleplay-owner-name">
                            {roleplay?.owner?.firstName + ' ' + roleplay?.owner?.lastName}
                        </OwnerName> •
                            <span data-test="roleplay-createdAt">{dayjs(roleplay?.dateCreated).format('MMM D, YY hh:mm A')}</span></>
                    }
                </NameContainer>
            </Header>
            }
            
        </>
        :
        <TextInputWithButtons width={'681px'} max={80} value={editmode['title']} onChange={(e) => setEditMode({ 'title': e.target.value })} autoFocus={true} onClose={closeEditMode} onApply={handleNameChange} dataTest="rename" isRequired={isRequiredTitle} />
    }</HeaderContainer>
}

export default RoleplayHeader;