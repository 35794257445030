import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ top: number; left: number }>`
    padding: 8px 16px;
    box-sizing: border-box;
    min-width: 72px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
`;

const Text = styled.div`
    font-family: Manrope;
    font-size: 11px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 3px;
`;

const Date = styled.div`
    font-family: Manrope;
    font-size: 11px;
    color: #6d7f8c;
`;

const ToolTip: FC<{
    text: string;
    date: string;
    positions: { x: number | null; y: number | null };
    title: string;
    singleTitle?: string;
}> = ({ text, date, positions, title, singleTitle }) => {
    if (!positions.x || !positions.y) {
        return <div />;
    }
    return (
        <Container top={positions.y} left={positions.x}>
            <Text>
                {Math.round(Number(text))} {Number(text) === 1 ? singleTitle || title : title}
            </Text>
            <Date>{date}</Date>
        </Container>
    );
};

export default ToolTip;
