import { UserV2, UserInterface } from '../../../../constants/interfaces/User';
import { Http } from '../../../../services/Http';
import {
    updateUserAction,
    updateUserFailure,
    updateUserSuccess,
    fetchUserAction,
    fetchUserFailure,
    fetchUserSuccess,
    fetchUserFeaturesStart,
    fetchUserFeaturesSuccess,
    fetchUserFeaturesFailure,
    fetchUserPreferencesStart,
    fetchUserPreferencesSuccess,
    fetchUserPreferencesFailure,
    updateUserPreferencesStart,
    updateUserPreferencesSuccess,
    updateUserPreferencesFailure,
} from '../actions';
import { updateUserProfileSuccess } from '../../../../pages/UserProfile/store/actions';
import { useDispatch } from 'react-redux';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    useGetUserFeaturesApi,
    useGetUserPreferencesApi,
    useUpdateUserPreferencesApi,
    useGetUserApi,
} from '../../../../api';
import { USER_NOT_FOUND_ERROR_MESSAGE } from '../costants';
import { handleMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';

export const useFetchUser = () => {
    const dispatch = useDispatch();
    const getUser = useGetUserApi();
    const showMessage = useShowMessage();

    return useCallback(
        (id: number, successCallback?: (response: unknown) => void) => {
            dispatch(fetchUserAction());
            return getUser(id)
                .then((response: UserV2) => {
                    dispatch(fetchUserSuccess(response));
                    successCallback?.(response);
                    return response;
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchUserFailure(error.message));
                    if (error.code === 404) {
                        showMessage(USER_NOT_FOUND_ERROR_MESSAGE, 'error');
                    } else {
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, getUser, showMessage]
    );
};

export const updateUser = (
    data: UserInterface,
    userId: number,
    self?: boolean,
    successMessage?: string,
    successCallback?: () => void,
): any => {
    return (dispatch: any) => {
        dispatch(updateUserAction());
        return Http.putProtected(
            `admin/users/${userId}`,
            data
        )
            .then((res: any) => {
                if (res.status === 'error') {
                    throw res.message;
                }
                self
                    ? dispatch(updateUserProfileSuccess(res))
                    : dispatch(updateUserSuccess(res));
                handleMessage(
                    dispatch,
                    successMessage || 'Profile Updated!',
                    'success'
                );
                successCallback?.();
                return { status: 'success', data: res };
            })
            .catch((error: any) => {
                self
                    ? updateUserFailure(error)
                    : dispatch(updateUserFailure(error));
                handleMessage(dispatch, error, 'error');
                return { status: 'error', data: error };
            });
    };
};

export const useGetUserFeaturesService = () => {
    const dispatch = useDispatch();
    const getUserFeaturesApi = useGetUserFeaturesApi();
    const showMessage = useShowMessage();
    return useCallback(
        (userId: number) => {
            dispatch(fetchUserFeaturesStart());
            return getUserFeaturesApi(userId)
                .then(data => {
                    dispatch(fetchUserFeaturesSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchUserFeaturesFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getUserFeaturesApi, showMessage]
    );
};

/**
 * @function useGetUserPreferencesService
 * @returns { CallableFunction }
 */
export const useGetUserPreferencesService = () => {
    const dispatch = useDispatch();
    const getUserPreferencesApi = useGetUserPreferencesApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number } userId
     * @returns { Promise }
     */
    return useCallback(
        (userId: number) => {
            dispatch(fetchUserPreferencesStart());
            return getUserPreferencesApi(userId)
                .then(data => {
                    dispatch(fetchUserPreferencesSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchUserPreferencesFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getUserPreferencesApi, showMessage]
    );
};

/**
 * @function useUpdateUserPreferenceService
 * @returns { CallableFunction }
 */
export const useUpdateUserPreferenceService = () => {
    const dispatch = useDispatch();
    const updateUserPreferencesApi = useUpdateUserPreferencesApi()
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number } userId
     * @param { string } key
     * @param { boolean } value
     * @param { Function } onSuccess
     * @returns { Promise }
     */
    return useCallback(
        (
            userId: number,
            key: string,
            value: boolean,
            onSuccess: () => void
        ) => {
            dispatch(updateUserPreferencesStart([{ key, value }]));
            return updateUserPreferencesApi(userId, { [key]: value })
                .then(() => {
                    dispatch(updateUserPreferencesSuccess([key]));
                    onSuccess();
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateUserPreferencesFailure([key]));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, updateUserPreferencesApi, showMessage]
    );
};