import { FC, useRef, useState } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../ui/components/ActionButton';
import { CheckPermission } from '../../../permissions';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { isTeamLeader } from '../../../../constants/enums';
import { PopupComponent } from '../../../../ui/components/Popup';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../labels/tools';
import { AssignLabels } from '../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { Scenario as ScenariosInterface } from '../../../../constants/interfaces/Scenario';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { useSelector } from 'react-redux';
import { ScenarioActionsHelper } from '../../tools';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';

const ScenariosListActions: FC<{
    scenario: ScenariosInterface;
    actionsHelper: ScenarioActionsHelper;
    restoreScenario: (scenarioIds: number[], isRowAction?: boolean) => void;
    handleItemEdit: (scenario: ScenariosInterface) => void;
    handleViewScenario: (scenario: ScenariosInterface) => void;
    handleGenerateChallenge: (scenario: ScenariosInterface) => void;
    onDuplicateScenario: (scenario: ScenariosInterface) => void;
    handleDeleteScenario: (scenarioId: number) => void;
    handleDownloadAsPDF: (scenarioId?: number) => void;
    archiveScenario: (scenarioIds: number[], isRowAction?: boolean) => void;
    assignLabelsToLibrary: (
        addedLabelIds: number[],
        deletedLabelIds: number[]
    ) => void;
    isHidden?: boolean;
}> = ({
    scenario,
    actionsHelper,
    restoreScenario,
    handleItemEdit,
    handleViewScenario,
    handleGenerateChallenge,
    handleDeleteScenario,
    onDuplicateScenario,
    handleDownloadAsPDF,
    archiveScenario,
    assignLabelsToLibrary,
    isHidden,
}) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();
    const profile = useSelector(getProfileState);

    const handleSetPreselectedLabels = () => {
        const selectedLabelIds = scenario?.labels || [];
        const preAssignedLabels = calculatePreSelectedLabels(
            selectedLabelIds,
            labelsList.data
        );
        setSelectLabels(preAssignedLabels);
        saveLabels();
    };

    const handleSaveSelectedLabels = (labelIDs: number[]) => {
        const addedLabelIds = calculateAssignedLabels(labelIDs, labels.saved);
        const deletedLabelIds = calculateRemovedLabels(labelIDs, labels.saved);

        assignLabelsToLibrary(addedLabelIds, deletedLabelIds);
    };

    const [assignSingleLabelOpen, setAssignSingleLabelOpen] = useState<
        null | number | undefined
    >(null);

    const triggerRef = useRef(null);

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton
                    customWidth={192}
                    isHidden={isHidden}
                    dataTest="library-scenarios-item-menu"
                >
                    {actionsHelper.canEdit(scenario) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleItemEdit(scenario);
                            }}
                            dataTest="edit-scenario-action"
                        >
                            View
                        </ActionItem>
                    )}
                    {actionsHelper.canAssignLabels(scenario) &&
                        !!labelsList.data.items.length && (
                            <ActionItem
                                onClick={e => {
                                    e.stopPropagation();
                                    setAssignSingleLabelOpen(scenario.id);
                                    handleSetPreselectedLabels();
                                }}
                                dataTest="assign-labels-scenario-action"
                            >
                                Assign Labels
                            </ActionItem>
                        )}
                    {isTeamLeader(profile?.role?.name) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleViewScenario(scenario);
                            }}
                            dataTest="view-scenario-action"
                        >
                            View
                        </ActionItem>
                    )}
                    {actionsHelper.canDuplicate(scenario) && (
                        <ActionItem
                            onClick={e => {
                                onDuplicateScenario(scenario);
                            }}
                            dataTest="duplicate-scenario-action"
                        >
                            Duplicate
                        </ActionItem>
                    )}
                    {actionsHelper.canGenerateChallenge(scenario) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleGenerateChallenge(scenario);
                            }}
                            dataTest="generate-challenge-action"
                        >
                            Generate Challenge
                        </ActionItem>
                    )}
                    {actionsHelper.canRestore(scenario) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                restoreScenario([scenario.id!], true);
                            }}
                            dataTest="restore-scenario-action"
                        >
                            Restore
                        </ActionItem>
                    )}
                    {actionsHelper.canDelete(scenario) && (
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                scenario?.id &&
                                    handleDeleteScenario(scenario.id);
                            }}
                            dataTest="delete-scenario-action"
                        >
                            Delete
                        </ActionItem>
                    )}
                    {actionsHelper.canDownloadPdf(scenario) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleDownloadAsPDF(scenario.id);
                            }}
                            dataTest="download-pdf-scenario-action"
                        >
                            Download as PDF
                        </ActionItem>
                    )}
                    {actionsHelper.canArchive(scenario) && (
                        <CheckPermission
                            permissions={[NEW_PERMISSIONS.ARCHIVE_SCENARIO]}
                        >
                            <ActionItem
                                destructive={true}
                                onClick={e => {
                                    e.stopPropagation();
                                    archiveScenario([scenario.id!], true);
                                }}
                                dataTest="archive-scenario-action"
                            >
                                Archive
                            </ActionItem>
                        </CheckPermission>
                    )}
                </ActionButton>
            </div>
            <PopupComponent
                triggerRef={triggerRef}
                open={assignSingleLabelOpen === scenario.id}
                onDismiss={() => {
                    setAssignSingleLabelOpen(null);
                }}
            >
                <AssignLabels
                    onLabelsChange={handleSaveSelectedLabels}
                    onSuccessApply={() => {
                        setAssignSingleLabelOpen(null);
                    }}
                    withBorder={true}
                />
            </PopupComponent>
        </span>
    );
};

export default ScenariosListActions;
