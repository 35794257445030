import React, { FC } from 'react';
import AvatarPlaceholder from '../../../../../components/AvatarPlaceholder/AvatarPlaceholder';
import { UserProfile, UserInterface } from '../../../../../../constants/interfaces/User';
import styled from 'styled-components';

const StyledUserArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    flex-shrink: 0;
`;

const UserProfilePictureSection = styled.div<{ open?: boolean }>`
    height: 38px;
    width: 38px;
    padding: 6px;
    overflow: hidden;
    border-radius: 8px;
    ${props => props.open && `background: var(--ps-graphite-2)`};
`;

const MinimizedUserProfileArea: FC<{
    profile: Partial<UserProfile | UserInterface>;
    open?: boolean;
    dataTest?: string;
}> = ({ profile, open, dataTest }) => {
    return (
        <StyledUserArea>
            <UserProfilePictureSection open={open}>
                <AvatarPlaceholder profile={profile} size={'sm'} dataTest={dataTest} />
            </UserProfilePictureSection>
        </StyledUserArea>
    );
};

export default MinimizedUserProfileArea;
