import { PractisSetFilter } from './practis-set-filter';
import { SingleSelectTeamFilter } from './single-team-filter';
import { MultipleSelectTeamsFilter } from './multiple-teams-filter';
import {
    DynamicFilterComponentListType,
    DynamicFilterControllerProps,
} from './types';
import { LabelsFilter } from './labels-filter';
import { MonthFilter } from './month-filter';
import { DateFilter } from './date-filter';

const DynamicFilterComponentList: DynamicFilterComponentListType = {
    SINGLE_SELECT_TEAM: <SingleSelectTeamFilter />,
    MULTIPLE_SELECT_TEAMS: <MultipleSelectTeamsFilter />,
    PRACTIS_SET: <PractisSetFilter />,
    LABELS: <LabelsFilter />,
    MONTH: <MonthFilter />,
    DATE: <DateFilter />
};

function DynamicFilterController(props: DynamicFilterControllerProps) {
    const { filterType } = props;

    return (
        DynamicFilterComponentList[filterType] || (
            <div>[DEV]: Please define a correct filter type!</div>
        )
    );
}

export default DynamicFilterController;