import React from 'react';

import { Variables } from '../../../theme/variables';
import { secondsToMinFormatted } from '../../../helpers/functions/convert-time';
import PlayCircle from '../../icons/PlayCircle';
import PauseCircle from '../../icons/PauseCircle';
import StopCircle from '../../icons/StopCircle';
import {
    AudioDuration,
    StyledActionButtons,
    StyledActionPanel,
    StyledAudioLine,
    StyledAudioPlayer,
    StyledAudioWrapper,
    StyledButton,
    StyledLine,
} from './style';
import { AudioPlayerInterface } from './type';

export class AudioPlayer extends React.Component<AudioPlayerInterface> {
    state = {
        duration: this.props.duration,
        current: null,
        isPaused: false,
    };

    _isMounted = false;
    audio: any;
    customAudioUiPlayer: any;
    slider: any;
    currentTimeInterval: any;

    handlePlay = () => {
        this.audio.play();
    };

    handlePause = () => {
        this.audio.pause();
    };

    handleStop = () => {
        if (!this._isMounted) return;
        this.audio.currentTime = 0;
        this.slider.value = 0;
        this.setState({
            current: 0,
        });
        this.audio.pause();
        if (this.props.onStop) {
            this.props.onStop();
        }
    };

    handleRewind = (time?: number) => {
        if (!time) return;
        this.audio.currentTime = time;
        this.audio.play();
    };

    getCurrentAudioClickDuration = (e: any) => {
        if (!this.audio) return;
        const clickPosition = e.clientX - this.getSliderLeftPosition();
        const totalWidth = this.getSliderTotalWidth();
        const clickFraction = (clickPosition / totalWidth).toFixed(2);

        return this.state.duration * Number(clickFraction);
    };

    getSliderLeftPosition = () => {
        return this.customAudioUiPlayer.getBoundingClientRect().left;
    };

    getSliderTotalWidth = () => {
        return this.customAudioUiPlayer.getBoundingClientRect().width;
    };

    componentDidMount = () => {
        this._isMounted = true;

        this.slider.value = 0;
        this.currentTimeInterval = null;
        if (this.props.autoPlay) {
            this.audio.play();
        }
        this.audio.onplay = () => {
            this.currentTimeInterval = setInterval(() => {
                if (this.audio) {
                    this.slider.value = this.audio.currentTime;
                    this.setState({
                        current:
                            (this.audio.currentTime * 100) /
                            this.state.duration,
                    });
                }
            }, 500);
            this.setState({ isPaused: false });
        };

        this.audio.onpause = () => {
            if (!this._isMounted) return;
            this.setState({ isPaused: true });
            clearInterval(this.currentTimeInterval);
        }

        this.audio.onended = () => {
            this.setState({
                current: 100,
                isPaused: true,
            });
            if (this.props.goNext) {
                this.props.goNext();
            }
            clearInterval(this.currentTimeInterval);
        };

        this.slider.onchange = (e: any) => {
            clearInterval(this.currentTimeInterval);
            this.audio.currentTime = e.target.value;
        };
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const src = this.props.audio;

        return (
            <StyledAudioPlayer
                color={
                    this.props.color
                        ? this.props.color
                        : Variables.Colors.softBlue
                }
            >
                <audio
                    ref={audio => {
                        this.audio = audio;
                    }}
                    src={src}
                />
                <StyledAudioWrapper>
                    <StyledAudioLine
                        ref={customAudioPlayer => {
                            this.customAudioUiPlayer = customAudioPlayer;
                        }}
                        onClick={e =>
                            this.handleRewind(
                                this.getCurrentAudioClickDuration(e)
                            )
                        }
                    >
                        <StyledLine
                            ref={slider => {
                                this.slider = slider;
                            }}
                            active={this.state.current}
                        />
                    </StyledAudioLine>
                </StyledAudioWrapper>
                <StyledActionPanel>
                    <StyledActionButtons>
                        {
                            this.state.isPaused
                                ? <StyledButton onClick={this.handlePlay}><PlayCircle /></StyledButton>
                                : <StyledButton onClick={this.handlePause}><PauseCircle /></StyledButton>
                        }
                        <StyledButton onClick={this.handleStop}><StopCircle /></StyledButton>
                    </StyledActionButtons>
                    <AudioDuration>
                        {secondsToMinFormatted(
                            this.audio && this.audio.currentTime
                        )}
                    </AudioDuration>
                </StyledActionPanel>
            </StyledAudioPlayer>
        );
    }
}
