import React, { FC } from 'react';
import styled from 'styled-components';
import EmptyBox from '../../../ui/icons/EmptyBox';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import NoSearchResult from '../../../ui/icons/NoSearchResult';

const StyledEmptyLabels = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const StyledEmptyLabelsContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoLabelsIcon = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoPractisSetsTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const EmptyPractisSets: FC<{ loading?: boolean; isEmpty?: boolean }> = ({
    loading,
    isEmpty,
}) => {
    if (loading) {
        return (
            <StyledEmptyLabels>
                <Loading />
            </StyledEmptyLabels>
        );
    }

    return (
        <StyledEmptyLabels>
            <StyledEmptyLabelsContent>
                <NoLabelsIcon data-test="practisset-searchbox-empty-result-icon">
                    {isEmpty ? <EmptyBox /> : <NoSearchResult />}
                </NoLabelsIcon>
                <NoPractisSetsTitle data-test="practisset-searchbox-empty-result-label">
                    {isEmpty ? 'No Practis Sets yet' : 'No Practis Sets found'}
                </NoPractisSetsTitle>
            </StyledEmptyLabelsContent>
        </StyledEmptyLabels>
    );
};

export default EmptyPractisSets;
