import ObjectiveAiParam from "../../components/AiParamsComponents/Objective";
import ProductAiParam from "../../components/AiParamsComponents/ProductService";
import ProspectAiParam from "../../components/AiParamsComponents/Prospect";
import SituationAiParam from "../../components/AiParamsComponents/Situation";
import BodySkeleton from "../../components/Skeletons/BodySkeleton";
import { RolePlayContextParams, RoleplayData } from "../../CreateRoleplay/types";
import { useRoleplayState } from "../store/states";
import { ParamsContainer, SubTitle } from "../styles";

function ContextPanel({ emptyRequiredProps, isEditMode, handleChange, rpdata } : { emptyRequiredProps?: RolePlayContextParams[], isEditMode: boolean, handleChange: (key: RolePlayContextParams, value: string) => void; rpdata: RoleplayData }) {
    const { isLoading } = useRoleplayState();
    return <ParamsContainer>
        {
            isLoading ? <BodySkeleton /> : <>
                <SubTitle data-test="sitation-title">Situation</SubTitle>
                <SituationAiParam handleChange={handleChange} roleplayData={rpdata} isEditMode={isEditMode} autoFocus={true}  emptyRequiredProps={emptyRequiredProps} analyticsKey={'roleplay_screen'}/>
                <SubTitle data-test="prospect-title" >Customer</SubTitle>
                <ProspectAiParam handleChange={handleChange} roleplayData={rpdata} isEditMode={isEditMode} emptyRequiredProps={emptyRequiredProps} analyticsKey={'roleplay_screen'}/>
                <SubTitle data-test="product-title" >Product</SubTitle>
                <ProductAiParam handleChange={handleChange} roleplayData={rpdata} isEditMode={isEditMode} emptyRequiredProps={emptyRequiredProps} analyticsKey={'roleplay_screen'}/>
                <SubTitle data-test="objective-title" >Approach</SubTitle>
                <ObjectiveAiParam handleChange={handleChange} roleplayData={rpdata} isEditMode={isEditMode} analyticsKey={'roleplay_screen'}/>
            </>
        }
    </ParamsContainer>
}

export default ContextPanel;