import { SearchParams } from "../../../constants/interfaces/filters";

export enum ACTIONS {
    SET_SEARCH_STATE = 'SET_SEARCH_STATE',
    DELETE_SEARCH_STATE = 'DELETE_SEARCH_STATE',
    SET_SECONDARY_SEARCH_STATE = 'SET_SECONDARY_SEARCH_STATE',
    DELETE_SECONDARY_SEARCH_STATE = 'DELETE_SECONDARY_SEARCH_STATE',
}

export function setSearchState(key: string, childrenKeys: string[], params?: SearchParams ) {
    return {
        type: ACTIONS.SET_SEARCH_STATE as ACTIONS.SET_SEARCH_STATE,
        key,
        params,
        childrenKeys
    };
}

export function deleteSearchState() {
    return {
        type: ACTIONS.DELETE_SEARCH_STATE as ACTIONS.DELETE_SEARCH_STATE
    };
}


export function setSecondarySearchState(key: string, params?: SearchParams) {
    return {
        type: ACTIONS.SET_SECONDARY_SEARCH_STATE as ACTIONS.SET_SECONDARY_SEARCH_STATE,
        key,
        params
    };
}

export function deleteSecondarySearchState( params?: SearchParams) {
    return {
        type: ACTIONS.DELETE_SECONDARY_SEARCH_STATE as ACTIONS.DELETE_SECONDARY_SEARCH_STATE,
        params
    };
}