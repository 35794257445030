import { SearchParams } from '../../../../constants/interfaces/filters';

export const countAppliedFiltersCount = (searchParams: SearchParams) => {
    let total = 0;

    if (searchParams?.filterByStatus?.length) {
        total = total + searchParams.filterByStatus.length;
    }

    if (searchParams?.filterByRegistrationStatus?.length) {
        total = total + searchParams.filterByRegistrationStatus.length;
    }

    if (searchParams?.labelIDs?.length) {
        total = total + searchParams.labelIDs.filter((id: number) => id > 0).length;
    }

    if(searchParams?.filters?.some(item => item.field === 'hasOverdue' && item.value === true)) {
        total += 1;
    }

    if(searchParams?.filterByDueDate?.length) {
        total+=searchParams?.filterByDueDate?.length;
    }

    return total;
};

