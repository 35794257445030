import { useEffect, useState } from "react";
import TextAreaPlaceholderBox from "../../../../ui/components/TextAreaPlaceholderBox/TextAreaPlaceholderBox";
import { QuestionTooltip } from "../../../../ui/components/Tooltip/QuestionTooltip";
import { QuestionContainer, TooltipContainer, TootlipContent } from "../../../CompanySettings/components/CompanyVoice/components/PartialVoiceSettings/styles";
import useGoogleAnalyticsWithRoleplay from "../../GoogleAnalytics";
import { Container, TextContainer, RequiredText, ParamsContainer, TextAreaContainer, Title, TitleContainer, WarningText } from "./styles";
import { AiParamTexboxProps } from "./types";

function AIParamTextbox({ title, placeholder, isRequired, isInvalid, tooltip, onChange, value, autoFocus, dataTest, isEditMode, onFocus, isDisabled, analyticsKey }: AiParamTexboxProps) {
    const [isFocused, setIsFocused] = useState(false);
    const [warningText, setWarningText] = useState('');
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();
    const max = 500;

    useEffect(() => {
        setWarningText(isInvalid ? 'This field can’t be empty' :  '' )
    }, [isInvalid]);

    return <Container isFocused={isFocused}>
        <Title>
            <TitleContainer data-test={`${dataTest}-title`}>
                <span>{title}
                {isRequired &&
                    <RequiredText data-test={`${dataTest}-required`}>
                        {` (required)`}
                    </RequiredText>}</span>
                <QuestionContainer>
                    <QuestionTooltip
                        action={() => trackEventWithRoleplay(`${analyticsKey}_hint`)}
                        text={<TooltipContainer>
                            <TootlipContent>{tooltip}</TootlipContent>
                        </TooltipContainer>}
                        isHtmlContent={true}
                        dataTest={`${dataTest}-tooltip`}
                    />
                </QuestionContainer>
            </TitleContainer>
        </Title>
        {!isEditMode ? <TextContainer isEmpty={!value} data-test={dataTest + '-text'}>{value ? value : 'Not specified. Click the Edit Context to change it.'}</TextContainer> : <ParamsContainer isDisabled={isDisabled}>
            <TextAreaContainer>
                <TextAreaPlaceholderBox
                    max={max}
                    maxRows={10}
                    minHeight="140px"
                    placeholder={placeholder}
                    value={value}
                    className={`rolename-textbox ${!!warningText ? 'warning' : ''}`}
                    onChange={e => {
                        if (e.target.value === '' || e.target.value.length === max) {
                            isRequired && e.target.value === '' && setWarningText('This field can’t be empty'); 
                            e.target.value.length === max && setWarningText('Character limit reached'); 
                        } else {
                            setWarningText('');
                        }
                        
                        onChange(e.target.value);
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={(e) => {
                            let val = e.target.value;
                            e.target.value = '';
                            e.target.value = val;
                            onFocus?.(e);
                            setIsFocused(true)
                    }}
                    dataTest={`${dataTest}-textbox`}
                    autoFocus={autoFocus}
                    counterPosition="left"
                    key="rolename-focus"
                    counterWarningText={!!warningText ? <WarningText> • {warningText} </WarningText> : undefined}
                />
            </TextAreaContainer>
        </ParamsContainer>}
    </Container>
}

export default AIParamTextbox;