import Skeleton from '@material-ui/lab/Skeleton';
import { CompanyUserStatsInfoViewProps } from './types';
import {
    Counts
} from './styles';
import CompanyUserStatsTextView from '../CompanyUserStatsText/view';

function CompanyUserStatsInfoView({
    userStats
}: CompanyUserStatsInfoViewProps) {
    return (
        <>
            {userStats ? (<>
                <CompanyUserStatsTextView
                    userStats={userStats}
                    showUnlimited={true}
                    style={{ fontSize: '15px', fontWeight: 'bolder', color: 'var(--ps-black-main)', height: 'auto' }}
                />
                <Counts>
                    <span data-test="registered-users-count">{userStats.registered} Registered</span>&nbsp;•&nbsp;
                    <span data-test="pending-users-count">{userStats.pending} Pending Registration</span>&nbsp;•&nbsp;
                    <span data-test="deactivated-users-count">{userStats.deactivated} Deactivated</span>
                </Counts>
            </>) : (<>
                <Skeleton
                    variant="text"
                    style={{ background: 'var(--ps-grey-3)' }}
                    width={170}
                    height={20}
                />
                <Skeleton
                    variant="text"
                    style={{ background: 'var(--ps-grey-3)' }}
                    width={320}
                    height={20}
                />
            </>)}
        </>
    );
}

export default CompanyUserStatsInfoView;