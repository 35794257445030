import { createContext, ReactNode } from 'react';

import CompanySection from '../../../features/companies/components/CompanySection/CompanySection';

export interface HeaderProps {
    title?: string;
    subTitle?: string;
    tabs?: ReactNode;
    subHeader?: ReactNode;
    hasBorder?: boolean;
    headerPadding?: string;
    goBackUrl?: string;
    useGoBack?: boolean;
    dataTest?: string;
    minHeight?: string;
}

export interface HeaderContextValue {
    companySectionComponent: any;
}

export const HeaderContext = createContext<HeaderContextValue>({
    companySectionComponent: CompanySection,
});
