import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const LeaderCrown = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                height="100%"
                viewBox="0 0 38 32"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M33.085 25.415V32h-28.2v-6.585h28.2zm-2.431 2.372H7.315v1.84h23.339v-1.84zM19 0c2.194 0 3.973 1.735 3.973 3.876 0 .966-.363 1.85-.962 2.529l3.988 6.02 4.057-1.989c.053-2.096 1.81-3.779 3.971-3.779 2.194 0 3.973 1.736 3.973 3.876 0 1.975-1.514 3.606-3.472 3.846l-1.59 9.153H5.062l-1.591-9.153C1.514 14.139 0 12.509 0 10.533c0-2.14 1.779-3.876 3.973-3.876s3.972 1.736 3.972 3.876l-.006-.098 3.97 1.945 3.974-6.101c-.536-.66-.856-1.495-.856-2.403C15.027 1.736 16.806 0 19 0zm-1.1 7.602l-5.118 7.861-5.546-2.718c-.353.494-.82.904-1.365 1.194l1.281 7.22h23.696l1.287-7.217c-.547-.29-1.017-.701-1.37-1.197L25.14 15.5l-5.207-7.857c-.3.07-.612.108-.934.108-.381 0-.75-.052-1.1-.15zm16.127 1.427c-.85 0-1.541.674-1.541 1.504s.69 1.504 1.541 1.504c.852 0 1.542-.673 1.542-1.504 0-.83-.69-1.504-1.542-1.504zm-30.054 0c-.852 0-1.542.674-1.542 1.504s.69 1.504 1.542 1.504c.85 0 1.541-.673 1.541-1.504 0-.83-.69-1.504-1.541-1.504zM19 2.372c-.851 0-1.541.673-1.541 1.504 0 .83.69 1.504 1.541 1.504s1.541-.673 1.541-1.504c0-.83-.69-1.504-1.541-1.504z"
                                            transform="translate(-816 -380) translate(0 184) translate(700 32) translate(49 164) translate(67)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default LeaderCrown;
