import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Trash = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 15 16"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.3"
                    d="M14.5 3H14v12c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V3H.5C.224 3 0 2.776 0 2.5S.224 2 .5 2H5V.5c0-.276.224-.5.5-.5h4c.276 0 .5.224.5.5V2h4.5c.276 0 .5.224.5.5s-.224.5-.5.5zM9 1H6v1h3V1zm4 2H2v11.5c0 .276.224.5.5.5h10c.276 0 .5-.224.5-.5V4v.001V3zm-3 3.5h1v6h-1v-6zm-3-1h1v7H7v-7zm-3 1h1v6H4v-6z"
                />
            </svg>
        );
    }
);

export default Trash;
