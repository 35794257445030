import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { UserV2 } from '../../../constants/interfaces/User';
import { CompanyUserStats } from '../../../constants/interfaces/CompanyUserStats';
import { CompanyVoiceSettings } from '../../../constants/interfaces/CompanyVoiceSettings';

export interface CompanyVoiceSettingsState {
    data?: CompanyVoiceSettings[];
    loading: boolean;
    error?: string;
}

export type CompanyVoiceSettingsStateSelector = (
    state: any
) => CompanyVoiceSettingsState;

export const CompanyVoiceSettingsStateSelectorContext = React.createContext<
    CompanyVoiceSettingsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useCompanyVoiceSettingsState(): CompanyVoiceSettingsState {
    return useSelector(useContext(CompanyVoiceSettingsStateSelectorContext));
}

export interface CompanyAdminsState {
    data?: UserV2[];
    loading: boolean;
    error?: string;
}

export type CompanyAdminsStateSelector = (
    state: any
) => CompanyAdminsState;

export const CompanyAdminsStateSelectorContext = React.createContext<
    CompanyAdminsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useCompanyAdminsState(): CompanyAdminsState {
    return useSelector(useContext(CompanyAdminsStateSelectorContext));
}

export interface CompanyUserStatsState {
    data?: CompanyUserStats;
    loading: boolean;
    error?: string;
}

export type CompanyUserStatsStateSelector = (
    state: any
) => CompanyUserStatsState;

export const CompanyUserStatsStateSelectorContext = React.createContext<
    CompanyUserStatsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useCompanyUserStatsState(): CompanyUserStatsState {
    return useSelector(useContext(CompanyUserStatsStateSelectorContext));
}