import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Input } from '../../../../../../ui/components/input';

export const useStyles = makeStyles(theme => ({
    disabledButton: {
        cursor: 'default!important',
        color: 'var(--ps-grey-2)!important'
    }
}));

export const StyledInput = styled(Input)`
    padding: 12px 16px;
    font-size: 13px;
    font-weight: normal;
    border: ${props => props.error ? 'none' : '1px solid var(--ps-grey-3)'};
    border-radius: 4px;
    &:focus {
        border: ${props => props.error ? 'none' : '1px solid var(--ps-grey-2)'};
    }
`;

export const InputWrapper = styled.div`
    flex: 1;
    height: 100%;
    border-radius: 4px;
    margin-right: 8px;
`;

const ActionButton = styled.div`
    width: 40px;
    height: 40px;
    background: var(--ps-grey-3);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CancelButton = styled(ActionButton)`
    margin-right: 8px;
    color: var(--ps-red-main);
`;

export const SaveButton = styled(ActionButton)`
    margin-right: 32px;
    color: var(--ps-green-main);
`;
