import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { AudioInterface, getAudioState } from '../../../player/store/reducers';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../constants/interfaces/ScriptLine';
import { useUpdateChallengeScriptLineService } from '../../store/services';
import {
    ChallengeContainerContext,
    EditModeContext,
} from '../../pages/EditChallenge';
import RepLine from './RepScriptLine';
import CustomerLine from './CustomerScriptLine';

const StyledScriptLineContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledDroppableContainer = styled.div<{ isDraggingOver: boolean }>``;

const StyledDraggableItem = styled.div<{ reverse?: boolean }>`
    margin-bottom: 16px;
`;

const ScriptLineContent: FC<{
    script: Array<ScriptLine>;
    player?: AudioInterface;
    handleScriptLineUpdate: (value: any) => void;
}> = ({ script, player, handleScriptLineUpdate }) => {
    const [scriptLines, setScriptLines] = useState(script);
    const parentRef = useContext(ChallengeContainerContext);
    const edit = useContext(EditModeContext);

    useEffect(() => {
        parentRef && parentRef.current.scrollTo(0, 0);
    }, [parentRef]);

    useEffect(() => {
        setScriptLines(script);
    }, [script, setScriptLines]);

    const Reorder = (startIndex: number, endIndex: number) => {
        const result = Array.from(scriptLines);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        setScriptLines(result);
        handleScriptLineUpdate(result);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        Reorder(result.source.index, result.destination.index);
    };

    const repLineCount = scriptLines.filter(
        item => item.speaker === ScriptLineSpeaker.REP
    ).length;

    return (
        <StyledScriptLineContent>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`challenge-drop-list`}>
                    {(provided: any, snapshot) => (
                        <StyledDroppableContainer
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {scriptLines.map((item: ScriptLine, index) => {
                                let disabled = false;
                                if (
                                    player &&
                                    player.recording &&
                                    player.recording !== item.id
                                ) {
                                    disabled = true;
                                }
                                return (
                                    <Draggable
                                        key={`draggable-item_${item.id}`}
                                        draggableId={item.id.toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <StyledDraggableItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                {item.speaker ===
                                                ScriptLineSpeaker.REP ? (
                                                    <RepLine
                                                        item={item}
                                                        editMode={edit.mode}
                                                        disabled={disabled}
                                                        dragHandleProps={
                                                            provided.dragHandleProps
                                                        }
                                                    />
                                                ) : (
                                                    <CustomerLine
                                                        item={item}
                                                        editMode={edit.mode}
                                                        disabled={disabled}
                                                        dragHandleProps={
                                                            provided.dragHandleProps
                                                        }
                                                        hideRepPlaceholder={
                                                            repLineCount > 0
                                                        }
                                                    />
                                                )}
                                            </StyledDraggableItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </StyledDroppableContainer>
                    )}
                </Droppable>
            </DragDropContext>
        </StyledScriptLineContent>
    );
};

export const ScriptLineContentContainer: FC<{
    script: Array<ScriptLine>;
}> = ({ script }) => {
    const player = useSelector(getAudioState);
    const updateScriptLine = useUpdateChallengeScriptLineService();

    return (
        <ScriptLineContent
            script={script}
            player={player}
            handleScriptLineUpdate={updateScriptLine}
        />
    );
};

export default ScriptLineContentContainer;
