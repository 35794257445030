import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { usePractisSetsState } from '../../../../../features/portablePractisSets/store/hors/withPractisSets/states';
import {
    useResetPractisSets,
    useSavePractisSets,
    useSelectMultiplePractisSets,
} from '../../../../../features/portablePractisSets/store/hors/withPractisSets/services';
import { AssignPractisSets } from '../../../../../features/portablePractisSets/templates/AssignPractisSets';
import { usePortablePractisSetsState } from '../../../../../features/portablePractisSets/store/states';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { Popup } from '../../../../../ui/components/Popup';
import { Tooltip } from '../../../../../ui/components/Tooltip';
import UpArrow from '../../../../../ui/icons/UpArrow';
import DownArrow from '../../../../../ui/icons/DownArrow';
import { PractisSetStatuses } from '../../../../../constants/interfaces/PractisSets';

const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

const SelectLabelWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const SelectLabel = styled.div<{ disabled?: boolean }>`
    font-size: 13px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.lightBlueGray
            : props.theme.Colors.black};
`;

const IconHolder = styled.div<{ disabled?: boolean }>`
    width: 8px;
    height: 100%;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.lightBlueGray
            : props.theme.Colors.black};
`;

const EditAssignPractisSets: FC<{
    selectedPractisSets: PractisSetWithDueDate[];
    onSelectPractisSets(practisSetIds?: PractisSetWithDueDate[]): void;
    modified: boolean;
    setModified(modified: boolean): void;
    dataTest: string;
}> = ({ selectedPractisSets, onSelectPractisSets, modified, setModified, dataTest }) => {
    const { saved } = usePractisSetsState();
    const { data } = usePortablePractisSetsState();
    const savePractisSets = useSavePractisSets();
    const selectMultiplePractisSets = useSelectMultiplePractisSets();
    const resetPractisSets = useResetPractisSets();

    useEffect(() => {
        selectMultiplePractisSets(selectedPractisSets);
        savePractisSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMultiplePractisSets, savePractisSets]);

    useEffect(() => {
        return () => {
            resetPractisSets();
        };
    }, [resetPractisSets]);

    useEffect(() => {
        onSelectPractisSets(saved);
    }, [onSelectPractisSets, saved]);

    const practisSetsDisabled = !data || data.items.filter(item => item.status === PractisSetStatuses.ACTIVE).length < 1;

    return (
        <Container>
            <Popup<HTMLDivElement>
                content={({ hide }) => (
                    <AssignPractisSets
                        onSuccessApply={() => {
                            hide();
                        }}
                    />
                )}
            >
                {(ref, { toggleShown, shown }) => (
                    <span ref={ref}>
                        <Tooltip
                            label={'No practis sets added yet'}
                            preset={'dropdown'}
                            disabled={!practisSetsDisabled}
                        >
                            <SelectLabelWrapper
                                onClick={() => {
                                    if (!modified && !practisSetsDisabled) {
                                        setModified(true);
                                    }
                                    !practisSetsDisabled && toggleShown();
                                }}
                                disabled={practisSetsDisabled}
                                data-test={dataTest}
                            >
                                <>
                                    <SelectLabel disabled={practisSetsDisabled}>
                                        {saved.length < 1
                                            ? 'Practis Sets'
                                            : `${saved.length} ${
                                                  saved.length > 1
                                                      ? 'Practis Sets'
                                                      : 'Practis Set'
                                              }`}
                                    </SelectLabel>
                                    <IconHolder disabled={practisSetsDisabled}>
                                        {shown ? <UpArrow /> : <DownArrow />}
                                    </IconHolder>
                                </>
                            </SelectLabelWrapper>
                        </Tooltip>
                    </span>
                )}
            </Popup>
        </Container>
    );
};

export default EditAssignPractisSets;
