import { SearchParams } from '../../../../constants/interfaces/filters';

export const initialSearchParams: SearchParams = {
    searchTerm: '',
    filters: [],
    orderBy: null,
    limit: 100000,
    offset: 0,
    totalCount: 0,
    numberOfPages: 0,
};

export const TEAMS_QUERY_PARAM_NAME = 'teams';