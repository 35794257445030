/**
 * @description - Post Message by Broadcast channel api
 * @function postBroadCastChannelMessage
 * @param { T } message
 * @returns { void }
 */
export function postBroadCastChannelMessage<T>(message: T): void {
    try {
        const broadcastChannel = new BroadcastChannel('app-data');

        broadcastChannel.postMessage(message);
    } catch (error) {
        console.log(
            'broadcast-channel.ts: postBroadCastChannelMessage: ',
            error
        );
    }
}

/**
 * @function listenBroadCastChannelMessage
 * @param { Function } callback
 * @returns { void }
 */
export function listenBroadCastChannelMessage<T>(
    callback: (message: T) => void
): void {
    try {
        const broadcastChannel = new BroadcastChannel('app-data');

        broadcastChannel.onmessage = event => {
            callback(event.data);
        };
    } catch (error) {
        console.log(
            'broadcast-channel.ts: listenBroadCastChannelMessage: ',
            error
        );
    }
}

