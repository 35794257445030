import XLSX from 'xlsx';

export const parseXlsFile = (file: any, onSuccess?: any) => {
    const reader = new FileReader();
    let generatedResults: any = [];

    reader.onload = e => {
        const bstr = e.target!.result;
        const wb = XLSX.read(bstr, {
            type: 'binary',
            bookVBA: true,
        });

        wb.SheetNames.forEach(function(sheetName: string) {
            const XL_row_object = XLSX.utils.sheet_to_json(
                wb.Sheets[sheetName]
            );
            const json_object = JSON.stringify(XL_row_object);
            let sheetResults = JSON.parse(json_object);
            generatedResults = [...generatedResults, ...sheetResults];
        });
        onSuccess && onSuccess(generatedResults);
        return generatedResults;
    };
    reader.readAsBinaryString(file);
};
