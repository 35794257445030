import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Challenge } from '../../../constants/interfaces/Challenge';

export type EditMode = 'create' | 'edit' | 'view';
export type ModifyCase =
    | 'init'
    | 'created'
    | 'modified'
    | 'loaded'
    | 'updated'
    | 'error';

export interface ChallengeState {
    data: Challenge;
    temp_data?: Challenge;
    loading?: boolean;
    case: ModifyCase;
    error?: string;
}

export type ChallengeStateStateSelector = (state: any) => ChallengeState;

export const ChallengeStateSelectorContext = React.createContext<
    ChallengeStateStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useChallengeState(): ChallengeState {
    return useSelector(useContext(ChallengeStateSelectorContext));
}
