import { Button } from "../../../../../../../ui/components/Button";
import { ModalWithCross } from "../../../../../../../ui/components/ModalWithCross";
import {
    Container,
    Description,
    QuickTip,
    Footer
} from "./styles";
import { UserLimitWarningModalViewProps } from "./types";

function UserLimitWarningModalView({
    title,
    description,
    quickTip,
    width,
    onClose,
    onManageInvitations,
    onRequestLimitChange,
}: UserLimitWarningModalViewProps) {
    return (
        <ModalWithCross
            title={title}
            width={width}
            titleStyle={{padding: '14px 24px', fontSize: '15px'}}
            onClose={onClose}
            dataTest="limit-warning"
        >
            <Container>
                <Description data-test="limit-warning-description">{description}</Description>
                <QuickTip>
                    <span data-test="quick-tip-icon">💡</span>&nbsp;
                    <b data-test="quick-tip-title">Quick tip:</b>&nbsp;
                    <span data-test="quick-tip-text">{quickTip}</span>
                </QuickTip>
                <Footer>
                    <Button
                        action={onManageInvitations}
                        label="Manage Invitations"
                        width="130px"
                        height="40px"
                        variant="inverse"
                        dataTest="manage-invitations-button"
                    />
                    <Button
                        action={onRequestLimitChange}
                        label="Request Limit Change"
                        width="130px"
                        height="40px"
                        dataTest="request-limit-change-button"
                    />
                </Footer>
            </Container>
        </ModalWithCross>
    );
}

export default UserLimitWarningModalView;