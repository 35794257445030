import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import { ListResult } from '../../../../../../constants/interfaces/PaginationResult';
import {
    PractisSets,
    PractisSetStatuses,
} from '../../../../../../constants/interfaces/PractisSets';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../../constants/interfaces/filters';
import { ClickAwayListener } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import { useLibraryPractisSetState } from '../../../../../../features/library/store/states';
import {
    useGetFullPractisSetsService,
    useSetSearchTermForLibraryPractisSetService,
    useUpdateAssignedLibraryPractisSetService,
} from '../../../../../../features/library/store/services';
import OutsideActionBox from '../../../../OutsideActionBox/OutsideActionBox';
import Checkbox from '../../../../Checkbox';
import { Variables } from '../../../../../../theme/variables';
import { Button } from '../../../../Button';
import { Input } from '../../../../input';
import { scrollDown, scrollUp } from '../../../../Select/tools';
import { useShowMessage } from '../../../../ErrorMessages/ErrorMessages';
import { useSearchDebounced } from '../../../../../../helpers/hooks/useSearch';
import UpArrow from '../../../../../icons/UpArrow';
import Search from '../../../../../icons/Search';
import DownArrow from '../../../../../icons/DownArrow';

const StyledSelectForm = styled.div<{ disabled?: boolean; minWidth?: string }>`
    box-sizing: border-box;
    position: relative;
    border-radius: 16px;
    ${props => !!props.minWidth && `min-width: ${props.minWidth};`}
    cursor: pointer;
    background: ${props => props.theme.Colors.white};
    color: ${props =>
        !!props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.black};
    font-weight: 600;
    height: 100%;
`;

const SelectContainer = styled(OutsideActionBox)`
    position: relative;
`;

const PractisSetsContainer = styled(motion.div)<{
    displayOver?: boolean;
    topMargin: number;
    left?: string;
}>`
    padding: 18px;
    min-width: 312px;
    position: absolute;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    left: ${props =>
        props.displayOver ? '16px' : !!props.left ? props.left : '0'};
    right: ${props => (props.displayOver ? '16px' : '0')};
    top: ${props => (props.displayOver ? `-${props.topMargin}px` : '8px')};
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
`;

const StyledPractisSetsList = styled.div``;

const ScrollablePractisSetsContainer = styled.div`
    max-height: 312px;
    overflow: auto;
    overflow-y: scroll;
`;

const PractisSetItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;
`;

const PractisSetItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 6px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;

const StyledButtonContainer = styled.div`
    margin: 21px auto 13px auto;
    width: 264px;
`;

const TitleContainer = styled.div`
    margin: 8px 0 15px 7px;
    font-size: 13px;
    font-weight: bold;
`;

const SearchInputContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

const SearchInput = styled(Input)`
    height: 48px;
    border-radius: 8px;
    padding: 0 52px;
    font-size: 13px;
    font-weight: 600;
    background-color: ${props => props.theme.Colors.whiteTwo};

    &::placeholder {
        color: ${props => props.theme.Colors.cloudyBlue};
    }
`;

const Icon = styled.div`
    width: 64px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
`;
const SearchIcon = styled(Search)`
    width: 16px;
    color: ${props => props.theme.Colors.battleshipGrey};
`;

const ScrollBar = styled.div<{ disabled?: boolean }>`
    height: 25px;
    color: ${props => props.theme.Colors.black};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    user-select: none;
    &:hover {
        background: ${props => !props.disabled && props.theme.Colors.whiteTwo};
    }
    &:active {
        background: ${props =>
            !props.disabled && props.theme.Colors.whiteThree};
    }
`;

const ScrollBarTop = styled(ScrollBar)`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

const ScrollBarBottom = styled(ScrollBar)`
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const ScrollBarIcon = styled(motion.div)`
    display: flex;
    width: 12px;
    align-items: center;
`;

const ANIMATION_SCALE = 5;

export const AssignPractisSetOptions: FC<{
    practisSets?: ListResult<PractisSets>;
    assignedPractisSets?: number[];
    searchTerm?: string;
    disabled?: boolean;
    title?: string;
    minWidth?: string;
    titlePadding?: string;
    iconRightPosition?: string;
    searchPractisSets(sp?: SearchParams): void;
    setSearchTermForLibraryPractisSetService(value: string): void;
    setAssignPractisSetsAction?(practisSetIds: number[]): void;
    onAssignPractisSetsSubmit?(): void;
    previouslyCheckedPractisSets?: number[];
    className?: string;
    showMessage: any;
    textAlignedLeft?: boolean;
    allowWithoutPreviousData?: boolean;
    containerLeftPosition?: string;
    setShowOptions: Dispatch<SetStateAction<boolean>>;
}> = ({
    practisSets,
    searchPractisSets,
    searchTerm,
    disabled,
    minWidth,
    assignedPractisSets,
    setAssignPractisSetsAction,
    setSearchTermForLibraryPractisSetService,
    className,
    onAssignPractisSetsSubmit,
    previouslyCheckedPractisSets,
    showMessage,
    allowWithoutPreviousData,
    containerLeftPosition,
    setShowOptions,
}) => {
    const initialSearchParams: SearchParams = {
        searchTerm: searchTerm || '',
        filters: [],
        orderBy: {},
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };
    const { searchParams, setSearchTerm } =
        useSearchParamsState(initialSearchParams);
    const [show, setShow] = useState(true);
    const scrollRef = useRef<any>(null);
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(true);

    useEffect(() => {
        setSearchTerm('');
        setSearchTermForLibraryPractisSetService('');
    }, [setSearchTerm, setSearchTermForLibraryPractisSetService]);

    useEffect(() => {
        setSearchTerm(searchTerm || '');
    }, [searchTerm, setSearchTerm]);

    useEffect(() => {
        searchPractisSets(searchParams);
    }, [searchPractisSets, searchParams]);

    const handleScroll = (e: any) => {
        handleScrollUp(e.target.scrollTop);
        handleScrollDown(e.target);
    };

    const handleScrollUp = (top: number) => {
        if (top > 25 && !showScrollUp) {
            setShowScrollUp(true);
        } else if (top <= 25 && showScrollUp) {
            setShowScrollUp(false);
        }
    };

    const handleScrollDown = (element: any) => {
        const condition =
            element.scrollTop === element.scrollHeight - element.offsetHeight;

        if (condition && showScrollDown) {
            setShowScrollDown(false);
        } else if (!condition && !showScrollDown) {
            setShowScrollDown(true);
        }
    };

    const onAssignPractisSets = useCallback(
        (id: any) => {
            let updatedAssignedPractisSets = [];
            if (assignedPractisSets && assignedPractisSets.includes(id)) {
                updatedAssignedPractisSets = assignedPractisSets.filter(
                    item => item !== id
                );
            } else {
                updatedAssignedPractisSets = [
                    ...(assignedPractisSets || []),
                    id,
                ];
            }

            setAssignPractisSetsAction &&
                setAssignPractisSetsAction(updatedAssignedPractisSets);
        },
        [assignedPractisSets, setAssignPractisSetsAction]
    );

    const handleAssignPractisSets = useCallback(() => {
        const length = allowWithoutPreviousData
            ? (assignedPractisSets || []).length
            : previouslyCheckedPractisSets &&
              previouslyCheckedPractisSets.length &&
              assignedPractisSets &&
              assignedPractisSets.length
            ? assignedPractisSets.length - previouslyCheckedPractisSets.length
            : assignedPractisSets && assignedPractisSets.length
            ? assignedPractisSets.length
            : 0;

        if (!!setAssignPractisSetsAction) {
            setAssignPractisSetsAction(assignedPractisSets || []);
            if (showMessage && assignedPractisSets && !!length) {
                showMessage(
                    `${
                        length > 1
                            ? `${length} Practis Sets have been assigned`
                            : '1 Practis Set has been assigned'
                    }`,
                    'success'
                );
            }
        }

        if (!!onAssignPractisSetsSubmit) {
            onAssignPractisSetsSubmit();
        }
    }, [
        assignedPractisSets,
        setAssignPractisSetsAction,
        previouslyCheckedPractisSets,
        showMessage,
        onAssignPractisSetsSubmit,
        allowWithoutPreviousData,
    ]);

    const isChecked = useCallback(
        (setId: any) => {
            return (
                (!!assignedPractisSets &&
                    !!setId &&
                    assignedPractisSets.includes(setId)) ||
                (!allowWithoutPreviousData &&
                    !!previouslyCheckedPractisSets &&
                    !!setId &&
                    previouslyCheckedPractisSets.includes(setId))
            );
        },
        [
            assignedPractisSets,
            previouslyCheckedPractisSets,
            allowWithoutPreviousData,
        ]
    );

    const isPreviouslyChecked = useCallback(
        (setId: any) => {
            return (
                !allowWithoutPreviousData &&
                !!previouslyCheckedPractisSets &&
                !!setId &&
                previouslyCheckedPractisSets.includes(setId)
            );
        },
        [previouslyCheckedPractisSets, allowWithoutPreviousData]
    );

    /**
     * @function onCloseHandler
     * @returns { void }
     */
    const onCloseHandler = (): void => {
        Promise.resolve().then(() => {
            setShow(false);
            setShowOptions(false);
        });
    };

    return (
        <ClickAwayListener onClickAway={onCloseHandler}>
            <StyledSelectForm
                disabled={disabled}
                minWidth={minWidth}
                className={className}
            >
                <SelectContainer open={show && !disabled}>
                    <PractisSetsContainer
                        initial={{ scale: 1 / ANIMATION_SCALE }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        topMargin={5}
                        left={containerLeftPosition}
                    >
                        <StyledPractisSetsList>
                            <TitleContainer>Assign Practis Sets</TitleContainer>
                            <SearchInputContainer>
                                <Icon>
                                    <SearchIcon />
                                </Icon>
                                <SearchInput
                                    placeholder={
                                        !practisSets ||
                                        (!!practisSets &&
                                            !practisSets.items.length)
                                            ? 'No Practis Sets to Search'
                                            : 'Search Practics Sets…'
                                    }
                                    height={'48px'}
                                    containerWidth="100%"
                                    clearTopPosition="20px"
                                    value={searchTerm}
                                    handleChange={(e: any) => {
                                        setSearchTermForLibraryPractisSetService(
                                            e.target.value
                                        );
                                    }}
                                    disabled={disabled}
                                    clearInput={() => {
                                        setSearchTermForLibraryPractisSetService(
                                            ''
                                        );
                                    }}
                                />
                            </SearchInputContainer>
                            {showScrollUp && (
                                <ScrollBarTop
                                    onClick={() => scrollUp(scrollRef.current)}
                                    disabled={!showScrollUp}
                                >
                                    <AnimatePresence>
                                        {showScrollUp && (
                                            <ScrollBarIcon
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1 }}
                                                exit={{ scale: 0 }}
                                            >
                                                <UpArrow />
                                            </ScrollBarIcon>
                                        )}
                                    </AnimatePresence>
                                </ScrollBarTop>
                            )}
                            <ScrollablePractisSetsContainer
                                ref={scrollRef}
                                onScroll={handleScroll}
                            >
                                {practisSets &&
                                    (practisSets || []).items
                                        .filter(
                                            item =>
                                                item.status ===
                                                PractisSetStatuses.ACTIVE
                                        )
                                        .map(set => (
                                            <PractisSetItemWrapper key={set.id}>
                                                <Checkbox
                                                    size={12}
                                                    checked={isChecked(set.id)}
                                                    disabled={isPreviouslyChecked(
                                                        set.id
                                                    )}
                                                    checkedBackground={
                                                        isPreviouslyChecked(
                                                            set.id
                                                        )
                                                            ? Variables.Colors
                                                                  .cloudyBlue
                                                            : isChecked(set.id)
                                                            ? Variables.Colors
                                                                  .softBlue
                                                            : Variables.Colors
                                                                  .white
                                                    }
                                                    border={
                                                        isPreviouslyChecked(
                                                            set.id
                                                        )
                                                            ? Variables.Colors
                                                                  .steelGrey
                                                            : Variables.Colors
                                                                  .cloudyBlue
                                                    }
                                                    handleChange={(e: any) =>
                                                        onAssignPractisSets(
                                                            set.id
                                                        )
                                                    }
                                                />
                                                <PractisSetItem
                                                    disabled={isPreviouslyChecked(
                                                        set.id
                                                    )}
                                                    onClick={() =>
                                                        onAssignPractisSets(
                                                            set.id
                                                        )
                                                    }
                                                >
                                                    {set.name}
                                                </PractisSetItem>
                                            </PractisSetItemWrapper>
                                        ))}
                            </ScrollablePractisSetsContainer>
                            <ScrollBarBottom
                                onClick={() => scrollDown(scrollRef.current)}
                                disabled={!showScrollDown}
                            >
                                <AnimatePresence>
                                    {showScrollDown && (
                                        <ScrollBarIcon
                                            initial={{ scale: 0 }}
                                            animate={{ scale: 1 }}
                                            exit={{ scale: 0 }}
                                        >
                                            <DownArrow />
                                        </ScrollBarIcon>
                                    )}
                                </AnimatePresence>
                            </ScrollBarBottom>
                            <StyledButtonContainer>
                                <Button
                                    width="264px"
                                    height="48px"
                                    action={() => {
                                        onCloseHandler();
                                        handleAssignPractisSets();
                                    }}
                                    dataTest='save-button'
                                >
                                    Save
                                </Button>
                            </StyledButtonContainer>
                        </StyledPractisSetsList>
                    </PractisSetsContainer>
                </SelectContainer>
            </StyledSelectForm>
        </ClickAwayListener>
    );
};

const AssignPractisSetOptionsContainer: FC<{
    title?: string;
    minWidth?: string;
    titlePadding?: string;
    disabled?: boolean;
    iconRightPosition?: string;
    onAssignPractisSetsSubmit?(): void;
    previouslyCheckedPractisSets?: number[];
    className?: string;
    textAlignedLeft?: boolean;
    allowWithoutPreviousData?: boolean;
    containerLeftPosition?: string;
    setShowOptions: Dispatch<SetStateAction<boolean>>;
}> = ({
    title,
    minWidth,
    titlePadding,
    disabled,
    iconRightPosition,
    onAssignPractisSetsSubmit,
    previouslyCheckedPractisSets,
    className,
    textAlignedLeft,
    allowWithoutPreviousData,
    containerLeftPosition,
    setShowOptions,
}) => {
    const state = useLibraryPractisSetState();

    const setAssignPractisSetsAction =
        useUpdateAssignedLibraryPractisSetService();
    const setSearchTermForLibraryPractisSetService =
        useSetSearchTermForLibraryPractisSetService();

    const searchPractisSets = useGetFullPractisSetsService();
    const searchPractisSetsDebounced = useSearchDebounced(searchPractisSets);

    const showMessage = useShowMessage();

    return (
        <AssignPractisSetOptions
            practisSets={state.fullList}
            assignedPractisSets={state.assignedPractisSets}
            searchTerm={state.searchTerm}
            disabled={disabled}
            title={title}
            minWidth={minWidth}
            titlePadding={titlePadding}
            searchPractisSets={searchPractisSetsDebounced}
            setAssignPractisSetsAction={setAssignPractisSetsAction}
            setSearchTermForLibraryPractisSetService={
                setSearchTermForLibraryPractisSetService
            }
            iconRightPosition={iconRightPosition}
            onAssignPractisSetsSubmit={onAssignPractisSetsSubmit}
            previouslyCheckedPractisSets={previouslyCheckedPractisSets}
            showMessage={showMessage}
            className={className}
            textAlignedLeft={textAlignedLeft}
            allowWithoutPreviousData={allowWithoutPreviousData}
            containerLeftPosition={containerLeftPosition}
            setShowOptions={setShowOptions}
        />
    );
};

export default AssignPractisSetOptionsContainer;
