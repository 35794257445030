import { useCompanyUserStatsState } from '../../../../../../CompanySettings/store/states';
import { UserLimitWarningModal } from '../UserLimitWarningModal';
import { ExceededFreeUsersModalViewProps } from './types';

function ExceededFreeUsersModalView({
    onClose
}: ExceededFreeUsersModalViewProps) {
    const userStats = useCompanyUserStatsState().data;

    if (!userStats) return <></>;

    return (
        <UserLimitWarningModal
            title="You need more seats to invite these users"
            description={<>You're trying to invite more users than is allowed under your existing license. Your company's account on Practis is limited to <b><span data-test="user-limit-value">{userStats.limit}</span> licensed seats</b> and you've used up <b data-test="existing-users-count">{userStats.total}</b>.<br /> 
You can either modify the invitations here to a lower number, find unaccepted invitations and revoke them, which will free up some seats.<br />
Alternatively, contact your Practis Support to request an increase.</>}
            quickTip="you can save all these invitations as a Draft for now until the limitation issue is resolved."
            width={392}
            onClose={onClose}
        />
    );
}

export default ExceededFreeUsersModalView;