import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ListResult } from '../../../../../../constants/interfaces/PaginationResult';
import { RepLineType } from '../types';

export interface ScenarioRepsState {
    data?: ListResult<RepLineType>;
    isLoading: boolean;
    error?: string;
}
export type ScenarioRepsStateSelector = (state: any) => ScenarioRepsState;

export const ScenarioRepsStateSelectorContext = React.createContext<ScenarioRepsStateSelector>(
    (e) => {
        throw new Error('Not implemented');
    }
);
export function useScenarioRepsState(): ScenarioRepsState {
    return useSelector(useContext(ScenarioRepsStateSelectorContext));
}
