import { isEmpty } from 'lodash';
import { FC, memo } from 'react';

import { Paging } from '../PagingNavigation';
import { SelectAll } from '../SelectAll/SelectAll';
import {
    ItemCount,
    Root,
    TableToolsLeft,
    TableToolsRight,
    Wrapper,
} from './style';
import { TableToolItem } from './table-tool-item';
import { TableSearchInput } from './table-search-input';
import { TableToolsProps } from './types';
import { TableAssignOptionsMenuContainer } from '../table/TableAssignOptionsMenu';
import { CalendarPopup, CalendarPopUpProps } from '../../Calendar';

const TableTools: FC<TableToolsProps> = memo(props => {
    const {
        customization,
        isSelectedItemOptionsVisible,
        selectedItemOptions,
        searchInputOptions,
        filterOptions,
        calendarFilterOptions,
        pagingOptions,
        itemCount,
        itemCountDataTest,
    } = props;

    const {
        rootClassName,
        wrapperClassName,
        tableToolsLeftClassName,
        tableToolsRightClassName,
    } = customization || {};

    const {
        initialValue,
        onSearchChange,
        isSearchInputDisabled,
        className,
        dataTest = 'table-search-input',
        placeholder = undefined,
    } = searchInputOptions || {};

    const { filterComponent = undefined } = filterOptions || {};
    const { calendarPopUpProps = undefined } = calendarFilterOptions || {};

    return (
        <Root className={rootClassName}>
            <Wrapper className={wrapperClassName}>
                <TableToolsLeft className={tableToolsLeftClassName}>
                    {isSelectedItemOptionsVisible && selectedItemOptions && (
                        <SelectAll
                            isSelectAll={selectedItemOptions.isSelectAll}
                            selectedLength={
                                selectedItemOptions?.selectedLength ?? 0
                            }
                            totalCount={selectedItemOptions.totalCount}
                            itemsPerPage={selectedItemOptions.itemsPerPage}
                            onClearSelecion={
                                selectedItemOptions.onClearSelection
                            }
                            onSelectAll={selectedItemOptions.onSelectAll}
                            style={selectedItemOptions?.style}
                            dataTest="table"
                        >
                            {selectedItemOptions?.bulkActionsConfig && (
                                <TableToolItem width={153} marginRight={16}>
                                    <TableAssignOptionsMenuContainer
                                        {...selectedItemOptions.bulkActionsConfig}
                                    />
                                </TableToolItem>
                            )}
                        </SelectAll>
                    )}
                    {searchInputOptions && (
                        <TableToolItem
                            width={224}
                            marginRight={16}
                            className={className}
                        >
                            <TableSearchInput
                                initialValue={initialValue}
                                placeholder={placeholder}
                                onChange={onSearchChange}
                                disabled={isSearchInputDisabled}
                                dataTest={dataTest}
                            />
                        </TableToolItem>
                    )}
                    {!isEmpty(filterOptions) && (
                        <TableToolItem marginRight={16}>
                            {filterComponent}
                        </TableToolItem>
                    )}
                    {!isEmpty(calendarFilterOptions) && (
                        <TableToolItem marginRight={16}>
                            <CalendarPopup
                                {...(calendarPopUpProps as CalendarPopUpProps<true>)}
                            />
                        </TableToolItem>
                    )}
                </TableToolsLeft>
                <TableToolsRight className={tableToolsRightClassName}>
                    {pagingOptions && (
                        <TableToolItem>
                            <Paging {...pagingOptions} />
                        </TableToolItem>
                    )}
                    {itemCount && (
                        <TableToolItem>
                            <ItemCount data-test={itemCountDataTest ?? 'table-selected-item'}>
                                {itemCount}
                            </ItemCount>
                        </TableToolItem>
                    )}
                </TableToolsRight>
            </Wrapper>
        </Root>
    );
});

export default TableTools;


