import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import Sidebar from '../../layout/Sidebar/Sidebar';
import Loading from '../../components/LoadingCopmonent/Loading';
import { ScrollPaginationContext } from '../../components/ScrollPagination';
import Header from '../../layout/Header/Header';
import { TableDivider } from '../../components/table-wrapper/table-divider';
import { MainWrapperProps } from './types';
import {
    Content,
    StyledContainer,
    StyledTest,
    WrapperContainer,
} from './styles';
import { initialDefaultSideBarMenuItems } from './constants';

export const MainWrapper: React.FC<MainWrapperProps> = memo(
    ({
        children,
        contentSize,
        headerPadding,
        headerMinHeight,
        contentPadding,
        title,
        subTitle,
        subHeader,
        hideHeader,
        loading,
        sideBarMenuItems,
        tabs,
        goBackUrl,
        useGoBack,
        height,
        headerHasBorder,
        refreshComponent: RefreshComponent,
        fontSize,
        isScrollable = true,
        htmlPageTitle = 'Practis Web Portal',
        dataTest,
        marginBottom
    }) => {
        const location = useLocation()
        const mainRef = useRef<any>();
        const spContext = useMemo(
            () => ({ scrollableRef: mainRef }),
            [mainRef]
        );

        
        useEffect(() => {
            document.title = htmlPageTitle;
        }, [htmlPageTitle, location]);
        

        return (
            <WrapperContainer data-test={dataTest}>
                <CssBaseline />
                <Sidebar
                    menuItems={
                        sideBarMenuItems || initialDefaultSideBarMenuItems
                    }
                    fontSize={fontSize}
                />
                <StyledTest
                    id="MainScrollableContainer"
                    ref={mainRef}
                    isScrollable={isScrollable}
                >
                    <Content height={height} contentPadding={contentPadding}>
                        {!hideHeader && (
                            <Header
                                title={title}
                                subTitle={subTitle}
                                tabs={tabs}
                                subHeader={subHeader}
                                goBackUrl={goBackUrl}
                                useGoBack={useGoBack}
                                hasBorder={headerHasBorder}
                                headerPadding={headerPadding}
                                dataTest={dataTest}
                                minHeight={headerMinHeight}
                            />
                        )}
                        <MainWrapperContainer
                            contentSize={contentSize}
                            loading={loading}
                            height={height}
                            marginBottom={marginBottom}
                        >
                            {!hideHeader && <TableDivider hide={false} />}
                            <ScrollPaginationContext.Provider value={spContext}>
                                {children}
                            </ScrollPaginationContext.Provider>
                        </MainWrapperContainer>
                    </Content>
                </StyledTest>
            </WrapperContainer>
        );
    }
);

export const MainWrapperContainer: React.FC<{
    contentSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    loading?: boolean;
    height?: string;
    marginBottom?: string;
}> = memo(({ contentSize, loading, height, children, marginBottom }) => {
    return (
        <StyledContainer
            maxWidth={typeof contentSize !== 'undefined' ? contentSize : 'xl'}
            height={height}
            marginBottom={marginBottom}
        >
            <div>{loading ? <Loading /> : children}</div>
        </StyledContainer>
    );
});

export default MainWrapper;