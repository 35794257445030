import { AuthLogoProps } from './types';
import {
    StyledLogoContainer,
    StyledLogo
} from './styles';

function AuthLogoView({ width, marginBottom }: AuthLogoProps) {
    return (
        <StyledLogoContainer
            width={width ? width : 153}
            marginBottom={marginBottom}
            data-test="practis-logo"
        >
            <StyledLogo />
        </StyledLogoContainer>
    );
}

export default AuthLogoView;
