import {
    fetchCompanyInfo,
    fetchCompanyInfoFailure,
    fetchCompanyInfoSuccess,
    loadVoiceSettingsFailure,
    loadVoiceSettingsStart,
    loadVoiceSettingsSuccess,
    fetchCompanyAdminsStart,
    fetchCompanyAdminsSuccess,
    fetchCompanyAdminsFailure,
    fetchCompanyUserStatsStart,
    fetchCompanyUserStatsSuccess,
    fetchCompanyUserStatsFailure,
    clearCompanyAdmins,
} from './actions';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
    useGetCompanyApi,
    useSearchUsersApi,
    useGetCompanyUserStatsApi,
    useSetCompanyLogoApi,
    useGetCompanyVoiceSettingsApi,
} from '../../../api';
import { AxiosError } from 'axios';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { handleMessage, useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { Roles } from '../../../constants/interfaces/Roles';
import { ROLE_NAMES } from '../../../constants/enums';
import { SearchUsersParams } from '../../../api/users/types';
import { UserStatus } from '../../../features/users/store/costants';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { UserV2 } from '../../../constants/interfaces/User';
import { CompanyUserStats } from '../../../constants/interfaces/CompanyUserStats';
import { useUploadMediaService } from '../../../services/MediaService';

export const useGetCompanyService = () => {
    const dispatch = useDispatch();
    const getCompanyApi = useGetCompanyApi();
    const showMessage = useShowMessage();
    return useCallback(
        (companyId: number) => {
            dispatch(fetchCompanyInfo());
            return getCompanyApi(companyId)
                .then(data => {
                    dispatch(fetchCompanyInfoSuccess(data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchCompanyInfoFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getCompanyApi, showMessage]
    );
};

export const useLoadVoiceSettingsService = () => {
    const getCompanyVoiceSettingsApi = useGetCompanyVoiceSettingsApi();
    const showMessage = useShowMessage();
    const dispatch = useDispatch();
    return useCallback((companyId: number) => {
        dispatch(loadVoiceSettingsStart());
        return getCompanyVoiceSettingsApi(companyId)
            .then(data => {
                dispatch(loadVoiceSettingsSuccess(data));
                return data;
            })
            .catch((error: AxiosError) => {
                showMessage(error.message, 'error');
                dispatch(loadVoiceSettingsFailure(error.message));
                throw error;
            });
    }, [dispatch, getCompanyVoiceSettingsApi, showMessage]);
};

export const useGetCompanyAdmins = () => {
    const dispatch = useDispatch();
    const searchUsersApi = useSearchUsersApi();
    return useCallback(
        (userRoles: Roles[], companyId: number) => {
            dispatch(fetchCompanyAdminsStart());
            const roleId = userRoles.find(role => role.name === ROLE_NAMES.COMPANY_ADMIN)?.id;
            if (!roleId) {
                return fetchCompanyAdminsFailure(`${ROLE_NAMES.COMPANY_ADMIN} role id could not be found`);
            }
            const params: SearchUsersParams = {
                status: `${UserStatus.ACTIVE},${UserStatus.PENDING}`,
                roles: roleId.toString(),
                companies: companyId.toString(),
                sort: 'name_asc',
                limit: 100,
                offset: 0
            };
            return searchUsersApi(params)
                .then((res: ListResult<UserV2>) => {
                    dispatch(fetchCompanyAdminsSuccess(res.items));
                    return res;
                })
                .catch((error: any) => {
                    dispatch(fetchCompanyAdminsFailure(error));
                    handleMessage(dispatch, error, 'error');
                    return 'error';
                });
            },
        [dispatch, searchUsersApi]
    );
};

export const useClearCompanyAdmins = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearCompanyAdmins());
    }, [dispatch]);
}

/**
 * @function useGetCompanyUserStatsService
 * @returns { CallableFunction }
 */
export const useGetCompanyUserStatsService = () => {
    const dispatch = useDispatch();
    const getCompanyUserStatsApi = useGetCompanyUserStatsApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number } companyId
     * @returns { void }
     */
    return useCallback(
        (companyId: number, callback?: (result: CompanyUserStats) => void) => {
            dispatch(fetchCompanyUserStatsStart());
            getCompanyUserStatsApi(companyId)
                .then((result: CompanyUserStats) => {
                    dispatch(fetchCompanyUserStatsSuccess(result));
                    callback?.(result);
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchCompanyUserStatsFailure(error.message));
                    showMessage(error.message, 'error');
                });
            },
        [dispatch, getCompanyUserStatsApi, showMessage]
    );
};

/**
 * @description uploads company logo and sets its media id to company
 * @function useUploadLogoService
 * @returns { CallableFunction }
 */
export const useUploadLogoService = () => {
    const uploadMedia = useUploadMediaService();
    const setCompanyLogoApi = useSetCompanyLogoApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number } companyId
     * @param { File } file
     * @returns { Promise<void> }
     */
     return useCallback(
        (companyId: number, file: File) => {
            return uploadMedia(file)
                .then((mediaId) => {
                    return setCompanyLogoApi(companyId, mediaId);
                })
                .catch((error) => {
                    showMessage(error.message, 'error');
                    return Promise.reject(error);
                });
        },
        [uploadMedia, setCompanyLogoApi, showMessage]
    );
};