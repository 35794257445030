import { UserProfile } from '../../../constants/interfaces/User';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import {useLoggedInUserProfileState} from "../store/states";
import {useMemo} from "react";
import { isTeamLeader as isTeamLead } from '../../../constants/enums';

export {
    NEW_PERMISSIONS
};

export function hasPermission(user: UserProfile | undefined, permission: NEW_PERMISSIONS) {
    return user?.permissions?.includes(permission) === true;
}

function createLoggedInUserPermissionsHelper(
    loggedInUser: UserProfile | undefined
) {
    const hasActionPermission = (permission: NEW_PERMISSIONS) =>
        hasPermission(loggedInUser, permission);

    const isTeamLeader = () => isTeamLead(loggedInUser?.role?.name);

    return {
        isTeamLeader,
        hasActionPermission,
    };
}

export function useLoggedInUserPermissionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createLoggedInUserPermissionsHelper(loggedInUser),
        [loggedInUser]
    );
}

// export function hasPermissionToLoginWeb(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LOGIN_WEB);
// }

// export function hasPermissionToLoginMobile(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LOGIN_MOBILE);
// }

// export function hasPermissionToLoginAdmin(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LOGIN_ADMIN);
// }

// export function hasPermissionToSignupWeb(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.SIGNUP_WEB);
// }

// export function hasPermissionToSignupMobile(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.SIGNUP_MOBILE);
// }

// export function hasPermissionToCompanyReport(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.COMPANY_REPORT);
// }

// export function hasPermissionToListLogs(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_LOGS);
// }

// export function hasPermissionToManageRecordings(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_RECORDINGS);
// }

// export function hasPermissionToManageOwnFiles(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_OWN_FILES);
// }

// export function hasPermissionToUpdateCompanyInfo(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_COMPANY_INFO);
// }

// export function hasPermissionToUpdateTtsSettings(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_TTS_SETTINGS);
// }

// export function hasPermissionToViewTtsSettings(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_TTS_SETTINGS);
// }

// export function hasPermissionToCreatePractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_PRACTIS_SET);
// }

// export function hasPermissionToCreateScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_SCENARIO);
// }

// export function hasPermissionToCreateChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_CHALLENGE);
// }

// export function hasPermissionToUpdatePractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_PRACTIS_SET);
// }

// export function hasPermissionToUpdateScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_SCENARIO);
// }

// export function hasPermissionToUpdateChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_CHALLENGE);
// }

// export function hasPermissionToArchivePractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ARCHIVE_PRACTIS_SET);
// }

// export function hasPermissionToArchiveScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ARCHIVE_SCENARIO);
// }

// export function hasPermissionToArchiveChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ARCHIVE_CHALLENGE);
// }

// export function hasPermissionToDeletePractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_PRACTIS_SET);
// }

// export function hasPermissionToDeleteScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_SCENARIO);
// }

// export function hasPermissionToDeleteChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_CHALLENGE);
// }

// export function hasPermissionToCopyPractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.COPY_PRACTIS_SET);
// }

// export function hasPermissionToCopyScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.COPY_SCENARIO);
// }

// export function hasPermissionToCopyChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.COPY_CHALLENGE);
// }

// export function hasPermissionToGenerateChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.GENERATE_CHALLENGE);
// }

// export function hasPermissionToPublishPractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.PUBLISH_PRACTIS_SET);
// }

// export function hasPermissionToPublishScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.PUBLISH_SCENARIO);
// }

// export function hasPermissionToPublishChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.PUBLISH_CHALLENGE);
// }

// export function hasPermissionToListPractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_PRACTIS_SET);
// }

// export function hasPermissionToListScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_SCENARIO);
// }

// export function hasPermissionToListChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_CHALLENGE);
// }

// export function hasPermissionToUpdateDueDate(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_DUE_DATE);
// }

// export function hasPermissionToCreateInvitation(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_INVITATION);
// }

// export function hasPermissionToInviteTrainee(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.INVITE_TRAINEE);
// }

// export function hasPermissionToInviteTrainer(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.INVITE_TRAINER);
// }

// export function hasPermissionToInviteSupervisor(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.INVITE_SUPERVISOR);
// }

// export function hasPermissionToInviteCustomRole(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.INVITE_CUSTOM_ROLE);
// }

// export function hasPermissionToInviteCompanyAdmin(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.INVITE_COMPANY_ADMIN);
// }

// export function hasPermissionToUpdateInvitation(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_INVITATION);
// }

// export function hasPermissionToDeleteInvitation(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_INVITATION);
// }

// export function hasPermissionToAssignInvitationPractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_INVITATION_PRACTIS_SET);
// }

// export function hasPermissionToAssignInvitationTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_INVITATION_TEAM);
// }

// export function hasPermissionToAssignInvitationLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_INVITATION_LABEL);
// }

// export function hasPermissionToAssignInvitationRole(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_INVITATION_ROLE);
// }

// export function hasPermissionToManageRoles(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_ROLES);
// }

// export function hasPermissionToCreateUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_USER);
// }

// export function hasPermissionToUpdateUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_USER);
// }

// export function hasPermissionToDeleteUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_USER);
// }

// export function hasPermissionToListUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_USER);
// }

// export function hasPermissionToExportListUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.EXPORT_LIST_USER);
// }

// export function hasPermissionToListInvitation(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_INVITATION);
// }

// export function hasPermissionToRevokeInvitation(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REVOKE_INVITATION);
// }

// export function hasPermissionToResendInvitation(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RESEND_INVITATION);
// }

// export function hasPermissionToViewUserPerformance(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_USER_PERFORMANCE);
// }

// export function hasPermissionToCreateLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_LABEL);
// }

// export function hasPermissionToUpdateLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_LABEL);
// }

// export function hasPermissionToDeleteLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_LABEL);
// }

// export function hasPermissionToManagePacings(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_PACINGS);
// }

// export function hasPermissionToAssignPractisSetLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_PRACTIS_SET_LABEL);
// }

// export function hasPermissionToAssignScenarioLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_SCENARIO_LABEL);
// }

// export function hasPermissionToAssignChallengeLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_CHALLENGE_LABEL);
// }

// export function hasPermissionToAssignUserLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_USER_LABEL);
// }

// export function hasPermissionToRemovePractisSetLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_PRACTIS_SET_LABEL);
// }

// export function hasPermissionToRemoveScenarioLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_SCENARIO_LABEL);
// }

// export function hasPermissionToRemoveChallengeLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_CHALLENGE_LABEL);
// }

// export function hasPermissionToRemoveUserLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_USER_LABEL);
// }

// export function hasPermissionToListSubmission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_SUBMISSION);
// }

// export function hasPermissionToViewSubmission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_SUBMISSION);
// }

// export function hasPermissionToReviewSubmission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REVIEW_SUBMISSION);
// }

// export function hasPermissionToRequestSubmissionRedo(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REQUEST_SUBMISSION_REDO);
// }

// export function hasPermissionToArchiveSubmission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ARCHIVE_SUBMISSION);
// }

// export function hasPermissionToRestoreSubmission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RESTORE_SUBMISSION);
// }

// export function hasPermissionToListActivePractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_ACTIVE_PRACTIS_SET);
// }

// export function hasPermissionToViewPractisSetProgress(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_PRACTIS_SET_PROGRESS);
// }

// export function hasPermissionToSendNudge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.SEND_NUDGE);
// }

// export function hasPermissionToExportListPractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.EXPORT_LIST_PRACTIS_SET);
// }

// export function hasPermissionToExportListPractisSetUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.EXPORT_LIST_PRACTIS_SET_USER);
// }

// export function hasPermissionToAssignEnrollment(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_ENROLLMENT);
// }

// export function hasPermissionToViewUserPractisSetReport(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_USER_PRACTIS_SET_REPORT);
// }

// export function hasPermissionToViewEnrollment(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_ENROLLMENT);
// }

// export function hasPermissionToViewNotification(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_NOTIFICATION);
// }

// export function hasPermissionToViewSubmissionFeedback(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_SUBMISSION_FEEDBACK);
// }

// export function hasPermissionToViewProfile(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_PROFILE);
// }

// export function hasPermissionToUpdateProfile(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_PROFILE);
// }

// export function hasPermissionToReceivePush(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RECEIVE_PUSH);
// }

// export function hasPermissionToTrackActivity(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.TRACK_ACTIVITY);
// }

// export function hasPermissionToAssignInvitationPermission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_INVITATION_PERMISSION);
// }

// export function hasPermissionToAssignUserPermission(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_USER_PERMISSION);
// }

// export function hasPermissionToRestorePractisSet(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RESTORE_PRACTIS_SET);
// }

// export function hasPermissionToRestoreScenario(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RESTORE_SCENARIO);
// }

// export function hasPermissionToRestoreChallenge(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RESTORE_CHALLENGE);
// }

// export function hasPermissionToGroupName(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.GROUP_NAME);
// }

// export function hasPermissionToViewCompany(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_COMPANY);
// }

// export function hasPermissionToRemoveInvitationLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_INVITATION_LABEL);
// }

// export function hasPermissionToUnassignEnrollment(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UNASSIGN_ENROLLMENT);
// }

// export function hasPermissionToSaveSearchFilter(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.SAVE_SEARCH_FILTER);
// }

// export function hasPermissionToViewLogDetails(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_LOG_DETAILS);
// }

// export function hasPermissionToManageStagingUsers(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_STAGING_USERS);
// }

// export function hasPermissionToRemoveUserFromStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_USER_FROM_STAGING);
// }

// export function hasPermissionToAddUserInStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ADD_USER_IN_STAGING);
// }

// export function hasPermissionToListStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_STAGING);
// }

// export function hasPermissionToDeleteStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_STAGING);
// }

// export function hasPermissionToUpdateStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_STAGING);
// }

// export function hasPermissionToCreateStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_STAGING);
// }

// export function hasPermissionToViewStaging(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_STAGING);
// }

// export function hasPermissionToListTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_TEAM);
// }

// export function hasPermissionToDeleteTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_TEAM);
// }

// export function hasPermissionToUpdateTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_TEAM);
// }

// export function hasPermissionToCreateTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_TEAM);
// }

// export function hasPermissionToViewTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.VIEW_TEAM);
// }

// export function hasPermissionToRemoveTeamMember(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.REMOVE_TEAM_MEMBER);
// }

// export function hasPermissionToDuplicateTeam(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DUPLICATE_TEAM);
// }

// export function hasPermissionToAssignTeamLabel(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_TEAM_LABEL);
// }

// export function hasPermissionToInviteUser(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.INVITE_USER);
// }

// // System permissions

// export function hasPermissionToAccessAnyCompany(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ACCESS_ANY_COMPANY);
// }

// export function hasPermissionToManageSystemFiles(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_SYSTEM_FILES);
// }

// export function hasPermissionToUpdateSettings(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_SETTINGS);
// }

// export function hasPermissionToRunMigration(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.RUN_MIGRATION);
// }

// export function hasPermissionToTriggerCron(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.TRIGGER_CRON);
// }

// export function hasPermissionToUpdatePractisAdmin(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_PRACTIS_ADMIN);
// }

// export function hasPermissionToDeletePractisAdmin(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_PRACTIS_ADMIN);
// }

// export function hasPermissionToCreatePractisAdmin(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_PRACTIS_ADMIN);
// }

// export function hasPermissionToCreateCompany(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.CREATE_COMPANY);
// }

// export function hasPermissionToListCompany(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_COMPANY);
// }

// export function hasPermissionToListPractisAdmin(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.LIST_PRACTIS_ADMIN);
// }

// export function hasPermissionToUpdateCompany(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.UPDATE_COMPANY);
// }

// export function hasPermissionToDeleteCompany(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.DELETE_COMPANY);
// }

// export function hasPermissionToManageDictionaries(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_DICTIONARIES);
// }

// export function hasPermissionToManagePermissions(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_PERMISSIONS);
// }

// export function hasPermissionToManageTimezones(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_TIMEZONES);
// }

// export function hasPermissionToManageDefaultRoles(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_DEFAULT_ROLES);
// }

// export function hasPermissionToManageSystemRoles(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.MANAGE_SYSTEM_ROLES);
// }

// export function hasPermissionToAssignSystemPermissions(user: UserInterface | undefined) {
//     return hasPermission(user, NEW_PERMISSIONS.ASSIGN_SYSTEM_PERMISSIONS);
// }