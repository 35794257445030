import styled from 'styled-components';

export const ListNameWrapper = styled.div<{ minWidth?: string }>`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 8px;
    min-width: ${props => props.minWidth ?? '120px'};
`;

export const ListItemName = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 4px 0;
`;

export const StyledText = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledAdditionalInfo = styled.span`
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.black};
    margin-left: 3px;
`;

export const StyledIsNew = styled.span`
    width: 39px;
    height: 25px;
    border-radius: 4px;
    border: solid 1px ${props => props.theme.Colors.mainBlue};
    padding: 4px 7px;

    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.mainBlue};
    margin-left: 9px;
    margin-right: 20px;
`;
