import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Published = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                d="M448.782 65.759l-10.473 10.414c-.016.02-.01.048-.027.066-.15.15-.347.215-.544.21-.2.007-.4-.055-.552-.208-.022-.021-.014-.055-.033-.078l-4.43-4.405c-.29-.288-.29-.756 0-1.046.29-.288.76-.288 1.051 0l3.959 3.937 9.995-9.938c.29-.29.763-.29 1.054 0 .29.289.29.759 0 1.048zm-11.785-4.26c-5.799 0-10.5 4.701-10.5 10.5 0 5.8 4.701 10.5 10.5 10.5s10.5-4.7 10.5-10.5c0-.227-.053-.438-.067-.661l1.383-1.344c.11.654.18 1.32.18 2.005 0 6.628-5.37 12-11.996 12S425 78.627 425 71.999C425 65.372 430.37 60 436.997 60c3.04 0 5.808 1.14 7.923 3.005l-1.103 1.072c-1.838-1.585-4.203-2.578-6.82-2.578z"
                                transform="translate(-1167 -60) translate(742)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default Published;
