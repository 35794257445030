import styled from 'styled-components';
import { motion } from 'framer-motion';
import Calendar from 'react-calendar';
import { Button } from '../Button';

export const IconHolder = styled.div<{ scaleX?: number; disabled?: boolean }>`
    width: 7px;
    height: 14px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.blackThree
            : props.theme.Colors?.skyBlueTwo};
    transform: scaleX(${props => props.scaleX || 1});

    &:hover {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.lightBlue};
    }
    &:active {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.windowBlue};
    }
`;

export const Header = styled.div`
    width: 100%;
    height: 103px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${props => props.theme.Colors?.darkSkyBlueThree};
    border-radius: 8px 8px 0px 0px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px;
`;

export const Title = styled.div`
    font-size: 24px;
    font-weight: 800;
    color: ${props => props.theme.Colors?.white};
`;

export const Subtitle = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors?.white};
`;

export const RemoveDateTitle = styled.div<{ disabled?: boolean }>`
    font-size: 13px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.skyBlueTwo};
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};

    &:hover {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.lightBlue};
    }
    &:active {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.windowBlue};
    }
`;

export const IconContainer = styled.div`
    margin-right: 4px;
    font-size: 5px;
`;

export const StyledCalendar = styled(Calendar)`
    width: calc(100% - 30px);
    min-height: 302px;
    margin: 0 auto;
    .react-calendar__navigation {
        height: 32px;
        margin: 22px 0px;
        justify-content: center;

        .react-calendar__navigation__label {
            font-size: 18px;
            font-weight: bold;
            background: ${props => props.theme.Colors.white};
            color: ${props => props.theme.Colors.blackThree};
            border: none;
            flex-grow: 0 !important;
            font-family: 'Manrope';
            pointer-events: none;
            margin: 0px 3px;
        }

        .react-calendar__navigation__arrow {
            background: ${props => props.theme.Colors.white};
            border: none;
            cursor: pointer;

            &[disabled] {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }

    .react-calendar__month-view__weekdays {
        margin-bottom: 12px;

        .react-calendar__month-view__weekdays__weekday {
            background: ${props => props.theme.Colors.white};
            color: ${props => props.theme.Colors.blackThree};
            border: none;
            width: 25px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Manrope';
            abbr[title] {
                text-decoration: none;
            }
        }
    }

    .react-calendar__month-view__days {
        .react-calendar__month-view__days__day {
            background: ${props => props.theme.Colors.white};
            border: none;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            font-family: 'Manrope';
            padding: 0;
            abbr {
                text-decoration: none;
                color: ${props => props.theme.Colors.black};
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            &:hover {
                background: ${props => props.theme.Colors.whiteFive};
                border-radius: 2px;
            }
            flex-basis: unset !important;
            margin: 0px 7px;
            &:disabled {
                cursor: default;
                pointer-events: none;
                abbr {
                    color: ${props => props.theme.Colors.cloudyBlue};
                }
            }
        }
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        abbr {
            color: #6f7f8a;
        }
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:disabled {
        abbr {
            color: white;
            pointer-events: none;
        }
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
        abbr {
            color: ${props => props.theme.Colors.black};
            border-bottom: 2px solid ${props => props.theme.Colors.black};
            width: 24px;
            height: 20px;
        }
    }

    .react-calendar__month-view__days {
        justify-content: space-between;
    }

    .react-calendar__tile.react-calendar__tile--active {
        border-radius: 2px;
        background: ${props => props.theme.Colors.skyBlueTwo};
        abbr {
            color: ${props => props.theme.Colors.white};
        }
        &:hover {
            background: ${props => props.theme.Colors.skyBlueTwo};
            cursor: auto;
        }
        &.react-calendar__tile--now abbr {
            color: var(--ps-white-1);
            border-bottom: 2px solid var(--ps-white-1);
        }
    }
`;

export const StyledDialogBlacker = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1200;
    background: rgba(35, 46, 58, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledDialog = styled(motion.div)<{
    width?: number;
    padding?: string;
}>`
    width: ${props => props.width || '390'}px;
    border-radius: 2px;
    box-shadow: 0 5px 20px -5px rgba(166, 174, 190, 0.5);
    background-color: ${props => props.theme.Colors.white};
    border-radius: 8px;
`;

export const CancelButton = styled(Button)`
    background: #fff;
    color: #4aa9e2;
    box-shadow: inset 0 0 0 1px #4aa9e2;

    &:hover {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.lightBlue};
    }
    &:active {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.windowBlue};
    }
`;
