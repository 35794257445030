import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { ReportItem } from '../../../constants/interfaces/ReportItem';

export interface ReportsState {
    data?: ListResult<ReportItem>;
    isLoading: boolean;
    error?: string;
}

export type ReportsStateSelector = (state: any) => ReportsState;

export const ReportsStateSelectorContext = React.createContext<ReportsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useReportsState(): ReportsState {
    return useSelector(useContext(ReportsStateSelectorContext));
}
