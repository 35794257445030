import React, { FC } from 'react';
import styled from 'styled-components';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import AssignedLabelList from './AssignedLabelList';

const Tr = styled.tr`
    background: unset !important;
`;

export interface TableAssignedLabelTagsProps {
    selectedLabels?: number[];
    onDeleteLabel?(id: number): void;
    hideDeleteButton?: boolean;
    padding?: string;
    deletePermissions?: NEW_PERMISSIONS[];
    colSpan?: number;
}

const TableAssignedLabelTags: FC<TableAssignedLabelTagsProps> = ({
    selectedLabels,
    colSpan = 100,
    onDeleteLabel,
    hideDeleteButton,
    deletePermissions,
}) => {
    return (
        <Tr>
            <td colSpan={colSpan}>
                <AssignedLabelList
                    selectedLabels={selectedLabels}
                    hideDeleteButton={hideDeleteButton}
                    deletePermissions={deletePermissions}
                    onDeleteLabel={onDeleteLabel}
                />
            </td>
        </Tr>
    );
};

export default TableAssignedLabelTags;
