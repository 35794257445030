import { FC } from 'react';

import ArrowRight from '../../../icons/ArrowRight';
import { scrollLeft, scrollRight } from './helper';
import Close from '../../../icons/Close';

import { TableDivider } from '../table-divider';
import {
    ArrowLeft,
    Container,
    FilteredByWrapper,
    IconHolderForClose,
    ScrollIconContainer,
    ScrollWrapper,
    StyledFilteredByContainer,
    StyledFilteredByItem,
    StyledLabel,
} from './style';
import { TableHeaderFilterViewProps } from './type';

const TableHeaderFilterView: FC<TableHeaderFilterViewProps> = props => {
    const {
        onLabelRemove,
        showLeftArrow,
        showRightArrow,
        wrapperRef,
        handleScroll,
        filteredByNames,
    } = props;

    return (
        <>
            <Container>
                <StyledLabel>Filtered by:</StyledLabel>
                {showLeftArrow && (
                    <ScrollWrapper
                        onClick={() => {
                            scrollRight(wrapperRef?.current);
                        }}
                    >
                        <ScrollIconContainer>
                            <ArrowLeft />
                        </ScrollIconContainer>
                    </ScrollWrapper>
                )}
                <FilteredByWrapper>
                    <StyledFilteredByContainer
                        ref={wrapperRef}
                        onScroll={e => handleScroll(e)}
                    >
                        {filteredByNames?.map((item: any) => (
                            <StyledFilteredByItem key={item.id}>
                                {item.title}
                                <IconHolderForClose
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        onLabelRemove && onLabelRemove(item);
                                    }}
                                >
                                    <Close />
                                </IconHolderForClose>
                            </StyledFilteredByItem>
                        ))}
                    </StyledFilteredByContainer>
                </FilteredByWrapper>
                {showRightArrow && (
                    <ScrollWrapper
                        onClick={() => {
                            scrollLeft(wrapperRef && wrapperRef.current);
                        }}
                    >
                        <ScrollIconContainer>
                            <ArrowRight />
                        </ScrollIconContainer>
                    </ScrollWrapper>
                )}
            </Container>
            <TableDivider />
        </>
    );
};

export default TableHeaderFilterView;
