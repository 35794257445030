import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarLibraryActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="#fff"
                    d="M1 1h14v5H1zM2 6h12v9H2z"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 14.933V6.401c.552 0 1-.478 1-1.068V1.067C16 .477 15.552 0 15 0H1C.448 0 0 .477 0 1.067v4.266c0 .59.448 1.068 1 1.068v8.532C1 15.523 1.448 16 2 16h12c.552 0 1-.478 1-1.067zm-12.5 0c-.276 0-.5-.238-.5-.533V6.933c0-.294.224-.532.5-.532H5v2.132c0 .59.448 1.067 1 1.067h4c.552 0 1-.477 1-1.067V6.401h2.5c.276 0 .5.238.5.532V14.4c0 .295-.224.533-.5.533h-11zM10 8V6.401H6V8c0 .295.224.533.5.533h3c.276 0 .5-.239.5-.533zm4.5-6.933c.276 0 .5.239.5.533v3.2c0 .295-.224.534-.5.534h-13c-.276 0-.5-.24-.5-.534V1.6c0-.294.224-.533.5-.533h13z"
                    fill="#222431"
                />
            </svg>
        );
    }
);

export default SidebarLibraryActive;
