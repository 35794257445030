import { CompanyInterface } from '../../constants/interfaces/Company';
import { UserInterface } from '../../constants/interfaces/User';
import { formatDate, formatTime } from '../../helpers/functions/date-convert';
import { LogEvent } from './models/LogEvent';

export {
    userRoleTitle,
} from '../../constants/enums';

interface LogEventInfo {
    event: LogEvent;
    name: string;
    isAdminOnly?: true;
}

function eventInfo(event: LogEvent, name: string, isAdminOnly?: true): LogEventInfo {
    return {
        event,
        name,
        isAdminOnly,
    };
}

const eventInfos = [
    eventInfo(LogEvent.CREATE_COMPANY, 'Create Company', true),
    eventInfo(LogEvent.UPDATE_COMPANY, 'Update Company', true),
    eventInfo(LogEvent.DELETE_COMPANY, 'Delete Company', true),
    eventInfo(LogEvent.CREATE_PRACTIS_ADMIN, 'Create Practis Admin', true),
    eventInfo(LogEvent.UPDATE_PRACTIS_ADMIN, 'Update Practis Admin', true),
    eventInfo(LogEvent.DELETE_PRACTIS_ADMIN, 'Delete Practis Admin', true),
    eventInfo(LogEvent.CREATE_SCENARIO, 'Create Scenario'),
    eventInfo(LogEvent.UPDATE_SCENARIO, 'Update Scenario'),
    eventInfo(LogEvent.DELETE_SCENARIO, 'Delete Scenario'),
    eventInfo(LogEvent.CREATE_PRACTIS_SET, 'Create Practis Set'),
    eventInfo(LogEvent.UPDATE_PRACTIS_SET, 'Update Practis Set'),
    eventInfo(LogEvent.DELETE_PRACTIS_SET, 'Delete Practis Set'),
    eventInfo(LogEvent.CREATE_CHALLENGE, 'Create Challenge'),
    eventInfo(LogEvent.UPDATE_CHALLENGE, 'Update Challenge'),
    eventInfo(LogEvent.DELETE_CHALLENGE, 'Delete Challenge'),
    eventInfo(LogEvent.GENERATE_CHALLENGE_FROM_SCENARIO, 'Generate Challenge From Scenario'),
    eventInfo(LogEvent.INVITE_USER, 'Invite User'),
    eventInfo(LogEvent.RESEND_INVITATION, 'Resend Invitation'),
    eventInfo(LogEvent.REVOKE_INVITATION, 'Revoke Invitation'),
    eventInfo(LogEvent.DELETE_USER, 'Delete User'),
    eventInfo(LogEvent.CREATE_STAGING, 'Create Staging'),
    eventInfo(LogEvent.UPDATE_STAGING, 'Update Staging'),
    eventInfo(LogEvent.DELETE_STAGING, 'Delete Staging'),
    eventInfo(LogEvent.CREATE_SUBMISSION, 'Create Submission'),
    eventInfo(LogEvent.UPDATE_SUBMISSION, 'Update Submission'),
    eventInfo(LogEvent.DELETE_SUBMISSION, 'Delete Submission'),
    eventInfo(LogEvent.REVIEW_SUBMISSION, 'Review Submission'),
    eventInfo(LogEvent.CREATE_SCORE, 'Create Score'),
    eventInfo(LogEvent.UPDATE_SCORE, 'Update Score'),
    eventInfo(LogEvent.DELETE_SCORE, 'Delete Score'),
    eventInfo(LogEvent.UPDATE_SETTING, 'Update Setting'),
    eventInfo(LogEvent.CREATE_TEAM, 'Create Team'),
    eventInfo(LogEvent.UPDATE_TEAM, 'Update Team'),
    eventInfo(LogEvent.DELETE_TEAM, 'Delete Team'),
    eventInfo(LogEvent.DUPLICATE_TEAM, 'Duplicate Team'),
    eventInfo(LogEvent.USER_ADDED_INTO_TEAM, 'User Added Into Team'),
    eventInfo(LogEvent.USER_TEAM_LEAD_PROMOTION, 'User Team Lead Promotion'),
    eventInfo(LogEvent.USER_TEAM_LEAD_REVOKE, 'User Team Lead Revoke'),
    eventInfo(LogEvent.CREATE_THEME, 'Create Theme'),
    eventInfo(LogEvent.UPDATE_THEME, 'Update Theme'),
    eventInfo(LogEvent.DELETE_THEME, 'Delete Theme'),
    eventInfo(LogEvent.ASSIGN_THEME_TO_PRACTISE_SET, 'Assign Theme To Practis Set'),
    eventInfo(LogEvent.ADD_COVER_INTO_THEME, 'Add Cover Into Theme'),
    eventInfo(LogEvent.REMOVE_COVER, 'Remove Cover'),
    eventInfo(LogEvent.ASSIGN_COVER_TO_PRACTISE_SET, 'Assign Cover To Practis Set'),
    eventInfo(LogEvent.ASSIGN_COVER_TO_SCENARIO, 'Assign Cover To Scenario'),
];

const eventInfosMap = eventInfos.reduce((result, info) => {
    result[info.event] = info;
    return result;
}, {} as Record<LogEvent, LogEventInfo>);

export function events(includeAdminOnly?: true): LogEvent[] {
    return eventInfos.filter(i => !!includeAdminOnly || !i.isAdminOnly).map(i => i.event);
}

export function eventName(event: LogEvent): string {
    return eventInfosMap[event]?.name || event;
}

export function userName(
    user: UserInterface | undefined,
    withEmail: boolean = true
): string {
    const result = user ? `${user.firstName} ${user.lastName}` : '';
    return withEmail ? `${result} ${userEmail(user)}` : result;
}

export function userEmail(user: UserInterface | undefined): string {
    return user ? `(${user.email})` : '';
}

export function companyName(company: CompanyInterface | undefined): string {
    return company?.name || '';
}

export function companyAndUserNames(company: CompanyInterface | undefined, user: UserInterface | undefined, withEmail: boolean = true): string {
    return `${companyName(company)} ${userName(user, withEmail)}`;
}

export function atTime(date: any): string {
    return `at ${formatTime(date, true)} ${formatDate(date)}`;
}

export enum PlatformVersions {
    IOS_VERSION = 'IOS_VERSION',
    ANDROID_VERSION = 'ANDROID_VERSION',
}

const platformVersionNames = {
    [PlatformVersions.IOS_VERSION]: 'IOS version',
    [PlatformVersions.ANDROID_VERSION]: 'Android version',
};

export function platformVersionName(action: PlatformVersions): string {
    return platformVersionNames[action] || '';
}