import { ChangeEvent } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Validation from '../../../../../services/validation';
import { Variables } from '../../../../../theme/variables';
import { FormikField } from '../../../../../ui/components/FormikField';
import { Button } from '../../../../../ui/components/Button';
import LoginAuthWrapper from '../../../../../ui/wrapper/LoginAuthWrapper';
import BackArrowImage from '../../../../../assets/images/icons/back-arrow.svg';
import { EnterEmailAddressViewProps } from './types';
import {
    Title,
    BackArrow,
    Description,
    FormContainer,
    FieldContainer,
} from './styles';

const ForgotPasswordSchema = Yup.object().shape({
    email: Validation.authEmail,
});

function EnterEmailAddressView ({
    isSending,
    errorMessage,
    onClearError,
    onSend,
    onBack
}: EnterEmailAddressViewProps) {
    return (
        <LoginAuthWrapper contentWidth={343} heightToShowScroll={470}>
            <Title>
                <BackArrow src={BackArrowImage} onClick={onBack} />
                Forgot Password
            </Title>
            <Description>
                Enter the email associated with your account.<br />We will email you a link to reset your password.
            </Description>
            <FormContainer>
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    onSubmit={values => onSend(values.email)}
                    validationSchema={ForgotPasswordSchema}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FieldContainer>
                                <Field
                                    type='text'
                                    component={FormikField}
                                    name='email'
                                    label='Email Address'
                                    height='56px'
                                    colorTheme='darkThree'
                                    fontSize='15px'
                                    value={values.email}
                                    labelFontSize='15px'
                                    labelFontWeight={600}
                                    customError={errorMessage}
                                    errorColor={Variables.Colors.tomato}
                                    className='login-input'
                                    dataTest='forgot-password-email'
                                    autoFocus={true}
                                    handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('email', e.target.value);
                                        onClearError();
                                    }}
                                />
                            </FieldContainer>
                            <Button
                                disabled={!values.email}
                                height='56px'
                                width='343px'
                                fontSize={15}
                                loading={isSending}
                            >
                                Send Link
                            </Button>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </LoginAuthWrapper>
    );
};

export default EnterEmailAddressView;
