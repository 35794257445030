import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Play = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 7"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="currentColor" fillRule="evenodd">
                    <path d="M12.5 7h-9C1.567 7 0 5.433 0 3.5S1.567 0 3.5 0 7 1.567 7 3.5c0 .98-.406 1.865-1.056 2.5h4.112C9.406 5.365 9 4.48 9 3.5 9 1.567 10.567 0 12.5 0S16 1.567 16 3.5 14.433 7 12.5 7zM6 3.5C6 2.119 4.88 1 3.5 1S1 2.119 1 3.5C1 4.88 2.12 6 3.5 6S6 4.88 6 3.5zM12.5 1C11.12 1 10 2.12 10 3.5 10 4.881 11.12 6 12.5 6 13.881 6 15 4.881 15 3.5S13.881 1 12.5 1z" />
                </g>
            </svg>
        );
    }
);

export default Play;
