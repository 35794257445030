import { useMemo } from "react";
import { UserProfile, UserV2 } from "../../../constants/interfaces/User";
import { useLoggedInUserProfileState } from "../../../features/users/store/states";
import { hasPermission, NEW_PERMISSIONS } from "../../../features/users/tools";
import { getBulkActionAvailabilityItem, getBulkActionsAvailability } from "../../../tools/bulkActions";

export enum TraineeInvitationActions {
    SET_DUEDATE,
}


function createTraineeInvitationActionHelper(loggedInUser: UserProfile | undefined) {    
    const canUpdateDueDate = () => hasPermission(loggedInUser, NEW_PERMISSIONS.SET_DUE_DATE);

    const getBulkActions = (trainees?: (UserV2 & {dueDate?: Date})[]) =>
        getBulkActionsAvailability(
            trainees,
            [
                getBulkActionAvailabilityItem(
                    TraineeInvitationActions.SET_DUEDATE,
                    trainees,
                    canUpdateDueDate
                ),
            ]
        );

    return {
        canUpdateDueDate,
        getBulkActions,
    };
}

export type TraineeInvitationActionHelper = ReturnType<typeof createTraineeInvitationActionHelper>;

export function useTraineeInvitationActionHelpers() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createTraineeInvitationActionHelper(loggedInUser),
        [loggedInUser]
    );
}
 