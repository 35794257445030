import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
    useArchiveSubmissionsApi,
    useRestoreSubmissionsApi,
    useSearchSubmissionsApi,
} from '../../../../api';

import { SearchParams } from '../../../../constants/interfaces/filters';
import { SUBMISSION_TYPE } from '../../../../constants/interfaces/Reviews';
import {
    AccuracyTestSubmissionFilters,
    ChallengeSubmissionFilters,
} from '../../../../constants/interfaces/SubmissionSearchFilters';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { createSearchSubmissionsParams } from '../../tools';
import {
    SubmissionUpdateActions,
    UpdateSubmissionStatuses,
} from './types';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';

/**
 * @function useHandleSearchSubmissionsAndUpdateStatus
 * @param { keyof typeof SUBMISSION_TYPE } scope
 * @param { UpdateSubmissionStatuses } status
 * @returns { CallableFunction }
 */
export const useHandleSearchSubmissionsAndUpdateStatus = (
    scope: keyof typeof SUBMISSION_TYPE,
    status: UpdateSubmissionStatuses
) => {
    const searchSubmissionsApi = useSearchSubmissionsApi();
    const archiveSubmissionApi = useArchiveSubmissionsApi();
    const restoreSubmissionApi = useRestoreSubmissionsApi();
    const profile = useSelector(getProfileState);
    const showMessage = useShowMessage();

    /**
     * @param { SearchParams } searchParams
     * @param { AccuracyTestSubmissionFilters | ChallengeSubmissionFilters } filters
     * @param { Function } onSuccessCallback
     */
    return useCallback(
        async (
            searchParams: SearchParams,
            filters: AccuracyTestSubmissionFilters | ChallengeSubmissionFilters,
            onSuccessCallback: (numberOfDoneItems: number) => void
        ) => {
            const UpdateSubmissionActionList = {
                [SubmissionUpdateActions.ARCHIVE]: archiveSubmissionApi,
                [SubmissionUpdateActions.RESTORE]: restoreSubmissionApi,
            };

            const searchSubmissionsParams = createSearchSubmissionsParams(
                searchParams,
                profile?.companyId ?? 0,
                scope === 'SCENARIO'
                    ? SUBMISSION_TYPE.SCENARIO
                    : SUBMISSION_TYPE.CHALLENGE,
                filters
            );

            if (
                (filters as AccuracyTestSubmissionFilters)?.scenarioIds
                    ?.length > 0
            ) {
                searchSubmissionsParams.scenarioId = (
                    filters as AccuracyTestSubmissionFilters
                ).scenarioIds.join(',');
            }

            if (
                (filters as ChallengeSubmissionFilters)?.reviewStatuses
                    ?.length === 1
            ) {
                searchSubmissionsParams.filterChallenge = (
                    filters as ChallengeSubmissionFilters
                ).reviewStatuses[0];
            }

            const updateSubmissionAction = UpdateSubmissionActionList[status];

            await searchSubmissionsApi(searchSubmissionsParams)
                .then(async response => {
                    if (!isEmpty(response.items)) {
                        const submissionIds = response.items.map(
                            item => item.id
                        );

                        await updateSubmissionAction(submissionIds)
                            .then(() => onSuccessCallback(submissionIds.length))
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [
            archiveSubmissionApi,
            profile?.companyId,
            restoreSubmissionApi,
            scope,
            searchSubmissionsApi,
            showMessage,
            status,
        ]
    );
};