import { Variables } from '../../../../../../theme/variables';
import TriangleDown from '../../../../../../ui/icons/TriangleDown';
import TriangleRight from '../../../../../../ui/icons/TriangleRight';
import {
    Container,
    StyledCheckbox,
    ArrowContainer,
    CollapseArrow,
    NameInput,
    ChildContainer,
} from './styles';
import { LabelsFilterItemViewProps } from './types';

function LabelsFilterItemView({
    label,
    hasChildren,
    children,
    isSelected,
    isPartiallySelected,
    isOpen,
    isDisabled,
    onToggle,
    onToggleCollapse,
}: LabelsFilterItemViewProps) {
    return (
        <>
            <Container
                onClick={e => {
                    e.stopPropagation();
                    onToggleCollapse(label.id);
                }}
                data-test="label-filter-item"
            >
                <ArrowContainer>
                    {hasChildren && (
                        <CollapseArrow data-test={`label-filter-item-${isOpen ? 'expanded' : 'collapsed'}-arrow`}>
                            {isOpen ? (
                                <TriangleDown />
                            ) : (
                                <TriangleRight />
                            )}
                        </CollapseArrow>
                    )}
                </ArrowContainer>
                <StyledCheckbox
                    checked={isSelected || isPartiallySelected}
                    partial={isPartiallySelected}
                    handleChange={onToggle}
                    size={12}
                    border="var(--ps-grey-2)"
                    dataTest="label-filter-item-checkbox"
                    disabled={isDisabled}
                    disabledCheckedBackground={Variables.Colors.cloudyBlue}
                />
                <NameInput
                    isSelected={isSelected || isPartiallySelected}
                    isDisabled={isDisabled}
                    hasChildren={hasChildren}
                    readOnly={true}
                    value={label.name}
                    title={label.name}
                    data-test="label-filter-item-name"
                />
            </Container>
            {!!children && <ChildContainer>{children}</ChildContainer>}
        </>
    );
};

export default LabelsFilterItemView;
