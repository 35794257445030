import React, {
    FC,
    FocusEvent,
    KeyboardEvent,
    MouseEvent,
    RefObject,
    ChangeEvent,
} from 'react';
import styled from 'styled-components';

const KEYWORDS_CLASS = '__keywords';

//region Styles
const StyledContentEditable = styled.div<{
    fontSize: string;
    disabled?: boolean;
}>`
    text-align: left;
    font-size: ${props => props.fontSize};
    white-space: break-spaces;
    word-break: break-word;
    overflow-wrap: break-word;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.steelGrey
            : props.theme.Colors.dark};
    height: 100%;
    .${KEYWORDS_CLASS} {
        color: ${props => props.theme.Colors.softBlue};
        font-weight: bold;
        cursor: pointer;
        display: inline;

        &:hover {
            opacity: 0.85;
        }
        &:active {
            opacity: 0.7;
        }
    }
    &[contenteditable='true']:empty:before {
        content: attr(placeholder);
        display: block;
        font-size: 13px;
        color: ${props => props.theme.Colors.cloudyBlue};
    }
    &::placeholder {
        color: ${props => props.theme.Colors.cloudyBlue};
        font-size: 13px;
    }
`;
//endregion

export const ContentEditable: FC<{
    value: string;
    onChange: (value: string) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
    onMouseUp?: (event: MouseEvent<HTMLDivElement>) => void;
    onFocus?: (e: FocusEvent<HTMLDivElement>) => void;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    fontSize?: string;
    placeholder?: string;
    disabled?: boolean;
    innerRef?: RefObject<HTMLDivElement>;
    dataTest?: string;
}> = ({
    value,
    onChange,
    onKeyDown,
    onMouseUp,
    onFocus,
    onClick,
    fontSize,
    placeholder,
    disabled,
    innerRef,
    dataTest
}) => {
    const handleInput = (event: ChangeEvent<HTMLDivElement>) => {
        if (onChange) {
            onChange(event.target.innerText);
        }
    };

    return (
        <StyledContentEditable
            ref={innerRef}
            fontSize={fontSize ? fontSize : '11px'}
            contentEditable={!disabled}
            onInput={handleInput}
            dangerouslySetInnerHTML={{ __html: value }}
            onKeyDown={onKeyDown}
            onMouseUp={onMouseUp}
            placeholder={placeholder}
            onFocus={onFocus}
            onClick={onClick}
            disabled={disabled}
            data-test={dataTest}
        />
    );
};
