import React, { FC } from 'react';
import styled from 'styled-components';
import InputRange from 'react-input-range';
import { Variables } from '../../../../theme/variables';
import { countDecimals } from '../../../../helpers/functions/count-decimals';

export interface Range {
    max: number;
    min: number;
}

const RangeSliderContainer = styled.div<{ color: string; disabled?: boolean }>`
    margin-bottom: 24px;
    font-family: 'Manrope';
    .input-range {
        height: 1rem;
        position: relative;
        width: 100%;
    }
    .input-range__slider {
        appearance: none;
        border-radius: 100%;
        cursor: pointer;
        display: block;
        height: 16px;
        width: 16px;
        margin-left: -8px;
        margin-top: -9px;
        outline: none;
        position: absolute;
        top: 50%;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    }
    .input-range__slider:active {
        transform: scale(1.05);
    }
    .input-range__slider:focus {
        box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
    }
    .input-range--disabled .input-range__slider {
        background: #cccccc;
        border: 1px solid #cccccc;
        box-shadow: none;
        transform: none;
    }

    .input-range__slider-container {
        transition: left 0.3s ease-out;
    }

    .input-range__label {
        color: #aaaaaa;
        font-family: 'Helvetica Neue', san-serif;
        font-size: 0.8rem;
        transform: translateZ(0);
        white-space: nowrap;
    }

    .input-range__label--min,
    .input-range__label--max {
        bottom: -1.4rem;
        position: absolute;
    }

    .input-range__label--min {
        left: 0;
        span {
            left: 0;
        }
    }

    .input-range__label--max {
        right: 0;
        span {
            left: 0 !important;
        }
    }

    .input-range__label--value {
        position: absolute;
        top: -1.8rem;
    }

    .input-range__label-container {
        left: -50%;
        position: relative;
        color: ${props => props.theme.Colors.steelGrey};
        font-size: 11px;
        font-family: 'Manrope';
    }
    .input-range__label--max .input-range__label-container {
        left: 50%;
    }

    .input-range__track {
        background: #eeeeee;
        border-radius: 0.3rem;
        cursor: pointer;
        display: block;
        height: 1px;
        position: relative;
        transition: left 0.3s ease-out, width 0.3s ease-out;
    }
    .input-range--disabled .input-range__track {
        background: #eeeeee;
    }

    .input-range__track--background {
        left: 0;
        margin-top: -0.15rem;
        position: absolute;
        right: 0;
        top: 50%;
    }

    .input-range__track--active {
        background: #3f51b5;
    }

    && .input-range__track--active {
        background: ${props =>
            props.disabled ? Variables.Colors.cloudyBlue : props.color};
    }

    && .input-range__slider {
        background: ${props =>
            props.disabled ? Variables.Colors.cloudyBlue : '#e4f2fb'};
        border: 1px solid
            ${props =>
                props.disabled ? Variables.Colors.cloudyBlue : props.color};
    }
`;

const Label = styled.div`
    font-size: 13px;
    color: ${Variables.Colors.steelGrey};
    margin-bottom: 23px;
`;

export const RangeInput: FC<{
    min: number;
    max: number;
    value: number | { min: number; max: number };
    onChange: (value: number | Range) => void;
    step?: number;
    color?: string;
    label?: string;
    disabled?: boolean;
    formatLabel?: (value: number, type: string) => string;
    dataTest?: string;
}> = ({
    min,
    max,
    value,
    onChange,
    color,
    label,
    step,
    disabled,
    formatLabel,
    dataTest,
}) => {
    const decimals = step ? countDecimals(step) : 0;
    return (
        <RangeSliderContainer
            disabled={disabled}
            color={color ? color : Variables.Colors.darkSkyBlue}
        >
            {label && <Label data-test={dataTest && `${dataTest}-label`}>{label}</Label>}
            <span data-test={dataTest && `${dataTest}-slider`}>
                <InputRange
                    disabled={disabled}
                    maxValue={max}
                    minValue={min}
                    onChange={onChange}
                    value={value}
                    step={step}
                    draggableTrack={false}
                    formatLabel={formatLabel ?? (value => `${value.toFixed(decimals)}`)}
                />
            </span>
        </RangeSliderContainer>
    );
};
