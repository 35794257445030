import styled from 'styled-components';

export const Container = styled.div`
    @media(max-height:600px) {
        padding-top: 30px;
    }
`;

export const Title = styled.div`
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--ps-white-1);
    width: 100%;
    text-align: center;
`;

export const BackArrow = styled.img`
    position: absolute;
    top: 7px;
    left: 0;
    cursor: pointer;
    width: 16px;
    height: 14px;
`;

export const GeneralError = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-red-main);
    padding-top: 16px;
    text-align: center;
    width: 90%;
`

export const FormContainer = styled.div`
    width: 100%;
`;

export const FieldContainer = styled.div`
    margin-top: 16px;
    min-height: 78px;

    .login-input-container + div {
        line-height: 18px;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 16px;
`;

export const FooterText = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-white-1);
    padding-top: 20px;
    text-align: center;

    a {
        color: var(--ps-blue-main);
        text-decoration: none;
    }
`;