import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Upload = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 18"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="currentColor" fillRule="evenodd">
                    <path
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth=".1"
                        d="M13.923 17H2.077C1.482 17 1 16.552 1 16V6c0-.552.482-1 1.077-1h1.615v1H2.615c-.297 0-.538.224-.538.5v9c0 .276.241.5.538.5h10.77c.297 0 .538-.224.538-.5v-9c0-.276-.241-.5-.538-.5h-1.077V5h1.615C14.518 5 15 5.448 15 6v10c0 .552-.482 1-1.077 1zm-3.61-12.642L8.564 2.69v9.81c0 .275-.234.499-.524.499-.29 0-.525-.224-.525-.5V2.69l-1.75 1.668c-.2.19-.525.19-.725 0-.2-.191-.2-.5 0-.691l2.592-2.47.014-.01c.01-.013.006-.032.019-.044.019-.018.048-.013.069-.027.078-.06.168-.102.274-.108.009 0 .015-.006.024-.006L8.04 1l.008.002c.006 0 .01.003.017.003.125.006.237.053.325.128.007.006.018.003.025.01.007.007.004.017.01.024.005.005.004.012.008.017l2.607 2.483c.2.19.2.5 0 .691-.2.19-.525.19-.725 0z"
                    />
                </g>
            </svg>
        );
    }
);

export default Upload;
