import { TeamLeader } from '../../constants/interfaces/TeamLeader';

export const filterTeamLeaders = (
    term: string,
    teamLeaders: TeamLeader[]
): TeamLeader[] => {
    if (!term) {
        return teamLeaders;
    }

    return teamLeaders.filter(
        leader =>
            leader.firstName.toLowerCase().includes(term.toLowerCase()) ||
            leader.lastName.toLowerCase().includes(term.toLowerCase()) ||
            `${leader.firstName} ${leader.lastName}`.trim().toLowerCase().includes(term.toLowerCase().trim())
    );
};
