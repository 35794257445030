export const formatStabilityLabel = (value: number, type: string) => {
    switch(type) {
        case 'min':
            return 'More variable';
        case 'max':
            return 'More stable';
        default:
            return '';
    }
};

export const formatSimilarityLabel = (value: number, type: string) => {
    switch(type) {
        case 'min':
            return 'Low';
        case 'max':
            return 'High';
        default:
            return '';
    }
};