import { ListResult } from "../../../constants/interfaces/PaginationResult";
import { ReportItem } from "../../../constants/interfaces/ReportItem";

export enum ACTIONS {
    GET_REPORTS_START = 'GET_REPORTS_START',
    GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS',
    GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE',
}

export function getReportsStart() {
    return {
        type: ACTIONS.GET_REPORTS_START as ACTIONS.GET_REPORTS_START,
    };
}

export function getReportsSuccess(data: ListResult<ReportItem>) {
    return {
        type: ACTIONS.GET_REPORTS_SUCCESS as ACTIONS.GET_REPORTS_SUCCESS,
        data
    };
}

export function getReportsFailure(error: string) {
    return {
        type: ACTIONS.GET_REPORTS_FAILURE as ACTIONS.GET_REPORTS_FAILURE,
        error
    };
}