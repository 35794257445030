import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { DATE_TIME_FORMAT } from '../../ui/components/Calendar/constants';
import { SearchParams } from '../../constants/interfaces/filters';
import { SearchUsersParams } from '../../api/users/types';
import { getSearchSortingValue } from '../../helpers/functions/search-params-helpers';
import { UserStatus } from '../../features/users/store/costants';
import {
    useNudgeUsersApi,
    useSearchPractisSetDetailsUsersApi,
    useSearchUsersApi,
    useGetOverdueLearners
} from '../../api';
import { useShowMessage } from '../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../constants/interfaces/ErrorResult';
import { NudgeData, NudgeDateType } from '../../api/alert/types';
import { NudgeUserSenderData, SearchUsersFunctionParams } from './types';
import { createSearchPractisSetDetailsParams } from '../../features/teams/tools';

/**
 * @function generateCopyOfEntityName
 * @param { string } entityName
 * @returns { string }
 */
export const generateCopyOfEntityName = (entityName: string): string => {
    const newDataTimeFormatPrefix = `[${dayjs(new Date()).format(
        DATE_TIME_FORMAT
    )}]`;

    const splittedTeamName = entityName.split('-');

    // It already contains DateTime prefix.
    if (
        splittedTeamName?.[0].includes('AM') ||
        splittedTeamName?.[0].includes('PM')
    ) {
        const newTeamName = splittedTeamName.pop();
        return `${newDataTimeFormatPrefix} - ${newTeamName}`;
    }

    return `${newDataTimeFormatPrefix} - ${entityName}`;
};

/**
 * @function useSearchUsersWithEnrollmentAndNudge
 * @returns { CallableFunction }
 */
const useSearchUsersWithEnrollmentAndNudge = () => {
    const searchPractisSetDetailsUsers = useSearchPractisSetDetailsUsersApi();
    const nudgeUsers = useNudgeUsersApi();
    const showMessage = useShowMessage();

    return useCallback(
        async (
            searchParams: SearchParams,
            teamId: string,
            practisSetId: string,
            senderData: NudgeUserSenderData,
            onSuccessCallback: () => void
        ) => {
            const searchPractisSetDetailsParams =
                createSearchPractisSetDetailsParams(searchParams);

            await searchPractisSetDetailsUsers(teamId, practisSetId, {
                ...searchPractisSetDetailsParams,
            })
                .then(async response => {
                    if (!isEmpty(response.items)) {
                        const nudgeUsersData = response.items.map(
                            item =>
                                ({
                                    type: 'enrollment',
                                    enrollmentId: item.id,
                                    receiverId: item.user.id,
                                    ...senderData,
                                } as NudgeData)
                        );

                        await nudgeUsers(nudgeUsersData)
                            .then(onSuccessCallback)
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [nudgeUsers, searchPractisSetDetailsUsers, showMessage]
    );
};

/**
 * @function useSearchCommonUsersAndNudge
 * @returns { CallableFunction }
 */
const useSearchCommonUsersAndNudge = () => {
    const searchUsersApi = useSearchUsersApi();
    const nudgeUsers = useNudgeUsersApi();
    const showMessage = useShowMessage();

    return useCallback(
        async (
            searchParams: SearchParams,
            companyId: number,
            senderData: NudgeUserSenderData,
            onSuccessCallback: () => void
        ) => {
            const searchRegisteredUsersParams: SearchUsersParams = {
                status: UserStatus.ACTIVE,
                roles: searchParams.roleIDs?.join(','),
                labels: searchParams.labelIDs?.join(','),
                teams: searchParams.teamIds?.join(','),
                companies: companyId.toString(),
                limit: searchParams.limit ?? 20,
                offset: searchParams.offset,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'name', true),
            };

            await searchUsersApi({ ...searchRegisteredUsersParams })
                .then(async response => {
                    if (!isEmpty(response.items)) {
                        const nudgeUsersData = response.items.map(
                            item =>
                                ({
                                    type: 'common',
                                    enrollmentId: null,
                                    receiverId: item.id,
                                    ...senderData,
                                } as NudgeData)
                        );

                        await nudgeUsers(nudgeUsersData)
                            .then(onSuccessCallback)
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [nudgeUsers, searchUsersApi, showMessage]
    );
};

/**
 * @function useSearchOverdueLearnersAndNudge
 * @returns { CallableFunction }
 */
const useSearchOverdueLearnersAndNudge = () => {
    const searchOverdueLearnersApi = useGetOverdueLearners();
    const nudgeUsers = useNudgeUsersApi();
    const showMessage = useShowMessage();

    return useCallback(
        async (
            searchParams: SearchParams,
            companyId: number,
            senderData: NudgeUserSenderData,
            onSuccessCallback: () => void
        ) => {
            const sp = {
                teams: searchParams.teamIds?.join(','),
                labels: searchParams.labelIDs?.join(','),
                limit: searchParams.limit ?? 20,
                offset: searchParams.offset,
                query: searchParams.searchTerm,
                sort: getSearchSortingValue(searchParams.orderBy, 'user_name', true),
                companies: companyId.toString()
            }
            await searchOverdueLearnersApi(sp)
                .then(async response => {
                    if (!isEmpty(response.items)) {
                        const nudgeUsersData = response.items.map(
                            item =>
                                ({
                                    type: 'overdue',
                                    enrollmentId: null,
                                    receiverId: item.id,
                                    ...senderData,
                                } as NudgeData)
                        );

                        await nudgeUsers(nudgeUsersData)
                            .then(onSuccessCallback)
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [nudgeUsers, searchOverdueLearnersApi, showMessage]
    );
};

/**
 * @function useHandleSearchUsersAndNudge
 * @param { NudgeDateType } type
 * @returns { CallableFunction }
 */
export const useHandleSearchUsersAndNudge = (type: NudgeDateType) => {
    const searchCommonUsersAndNudge = useSearchCommonUsersAndNudge();
    const searchUsersWithEnrollmentAndNudge =
        useSearchUsersWithEnrollmentAndNudge();
    const searchOverdueLearnersAndNudge = useSearchOverdueLearnersAndNudge();

    return useCallback(
        async (
            searchParams: SearchParams,
            searchUsersFunctionParams: SearchUsersFunctionParams<typeof type>,
            senderData: NudgeUserSenderData,
            onSuccessCallback: () => void
        ) => {
            if (type === 'common') {
                const { companyId } =
                    searchUsersFunctionParams as SearchUsersFunctionParams<'common'>;

                await searchCommonUsersAndNudge(
                    searchParams,
                    companyId,
                    senderData,
                    onSuccessCallback
                );
            } else if (type === 'overdue') {
                const { companyId } =
                    searchUsersFunctionParams as SearchUsersFunctionParams<'overdue'>;

                await searchOverdueLearnersAndNudge(
                    searchParams,
                    companyId,
                    senderData,
                    onSuccessCallback);
            } else {
                const { teamId, practisSetId } =
                    searchUsersFunctionParams as SearchUsersFunctionParams<'enrollment'>;
                await searchUsersWithEnrollmentAndNudge(
                    searchParams,
                    teamId,
                    practisSetId,
                    senderData,
                    onSuccessCallback
                );
            } 
        },
        [searchCommonUsersAndNudge, searchUsersWithEnrollmentAndNudge, type, searchOverdueLearnersAndNudge]
    );
};