export function searchTree(leaf: any, id: any): any {
    if (leaf.id === id || leaf.id === -id) {
        return leaf;
    } else if (leaf.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < leaf.children.length; i++) {
            result = searchTree(leaf.children[i], id);
        }
        return result;
    }
    return null;
}
