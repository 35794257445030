import React, { FC, useState } from 'react';
import {
    filterInvitationRolesByPermissions,
    getUserRoleNameById,
} from '../../tools';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ClickAwayListener } from '@material-ui/core';
import { usePermissionsState } from '../../../../../features/permissions/store/state';
import { useUserRolesState } from '../../../../../features/roles/store/states';
import { Variables } from '../../../../../theme/variables';
import OutsideActionBox from '../../../../../ui/components/OutsideActionBox/OutsideActionBox';
import RadioButton from '../../../../../ui/components/RadioButton';
import UpArrow from '../../../../../ui/icons/UpArrow';
import DownArrow from '../../../../../ui/icons/DownArrow';

const StyledSelectForm = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
`;

const StyledLabelWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const SelectContainer = styled(OutsideActionBox)`
    position: relative;
`;

const SelectLabel = styled.div<{ color?: string }>`
    font-size: 13px;
    color: ${props => (props.color ? props.color : props.theme.Colors.black)};
`;

const IconHolder = styled.div<{ disabled?: boolean }>`
    width: 8px;
    height: 100%;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${({ theme, disabled }) =>
        disabled ? theme.Colors.cloudyBlue : theme.Colors.black};
`;

const RolesContainer = styled(motion.div)`
    padding: 24px 24px 16px 24px;
    width: 129px;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    top: 8px;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
    position: absolute;
`;

const StyledRolesList = styled.div``;

const ScrollableRoleContainer = styled.div``;

const RoleItemWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 8px;
`;

const StyledRadioButton = styled(RadioButton)`
    margin-bottom: 0;
`;

const ANIMATION_SCALE = 5;

export const EditAssignRoles: FC<{
    disabled?: boolean;
    assignedRole: number | null;
    handleRoleCheck(role: number | null): void;
    modified: boolean;
    setModified(modified: boolean): void;
    dataTest: string;
}> = ({ disabled, assignedRole, handleRoleCheck, modified, setModified, dataTest }) => {
    const [open, setOpen] = useState(false);
    const permissions = usePermissionsState();
    const userRoles = useUserRolesState();
    const roles = filterInvitationRolesByPermissions(
        userRoles.list,
        permissions
    );
    const assignedRoleName = getUserRoleNameById(assignedRole, userRoles.list);

    const updateCheckedRole = (value: number) => {
        setOpen(false);
        handleRoleCheck(value);
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <StyledSelectForm>
                <StyledLabelWrapper
                    onClick={() => {
                        if (!modified) {
                            setModified(true);
                        }
                        setOpen(!open);
                    }}
                    disabled={disabled}
                    data-test={dataTest}
                >
                    <SelectLabel
                        color={
                            !assignedRoleName || disabled
                                ? Variables.Colors.cloudyBlue
                                : Variables.Colors.black
                        }
                    >
                        {assignedRoleName}
                    </SelectLabel>
                    <IconHolder disabled={disabled}>
                        {!disabled && open ? <UpArrow /> : <DownArrow />}
                    </IconHolder>
                </StyledLabelWrapper>
                <SelectContainer open={open && !disabled}>
                    <RolesContainer
                        initial={{ scale: 1 / ANIMATION_SCALE }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                    >
                        <StyledRolesList>
                            <ScrollableRoleContainer>
                                {roles &&
                                    (roles || []).map(role => (
                                        <RoleItemWrapper
                                            key={role.value}
                                            onClick={() => {
                                                updateCheckedRole(role.value);
                                            }}
                                            data-test={`role-${role.name.toLowerCase()}`}
                                        >
                                            <StyledRadioButton
                                                checked={
                                                    assignedRole === role.value
                                                }
                                                size={12}
                                                handleChange={() =>
                                                    updateCheckedRole(
                                                        role.value
                                                    )
                                                }
                                                label={role.name}
                                            />
                                        </RoleItemWrapper>
                                    ))}
                            </ScrollableRoleContainer>
                        </StyledRolesList>
                    </RolesContainer>
                </SelectContainer>
            </StyledSelectForm>
        </ClickAwayListener>
    );
};
