import {
    createContext,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { isEmpty } from 'lodash';

import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import {
    Scenario,
    ScenarioStatuses,
    Status,
} from '../../../constants/interfaces/Scenario';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../constants/interfaces/ScriptLine';
import CustomBody from './components/CustomBody';
import CustomHeader from './components/CustomHeader/CustomHeader';
import { getScenarioState, ScenarioCases } from './store/reducers';
import {
    useClearScriptLinesService,
    useCreateScriptLineService,
    useGetRoleplayForScenario,
    useGetScenarioService,
    useModifyScenarioService,
    useResetScenarioService,
} from './store/services';
import {
    useCreatePlaylistService,
    useResetAudioService,
} from '../../../features/player/store/services';
import { validationService } from './tools';
import { buildPageTitle } from '../../../helpers/functions/page-title-helpers';
import { EditModeValues } from '../../../constants/enums/EditModeValues';
import { useLoadVoiceSettingsService } from '../../CompanySettings/store/services';
import styled from 'styled-components';
import { usePermissionsState } from '../../../features/permissions/store/state';
import { CheckPermission } from '../../../features/permissions';
import ActionPanel from './components/ActionPanel/ActionPanel';
import { useLabelsState } from '../../../features/labels/store/states';
import {
    useSetAssignLabelsActionService,
    useSetPreviouslyAssignedLabelsAction,
} from '../../../features/labels/store/services';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../features/labels/tools';
import { isTeamLeader } from '../../../constants/enums';
import { usePortableLabelsState } from '../../../features/portableLabels/store/states';
import { UserProfile } from '../../../constants/interfaces/User';
import { getProfileState } from '../../UserProfile/store/reducers';
import { ModalPageContainer } from '../../../ui/components/ModalPage';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';
import {
    useGenerateCreateDuplicateScenarioActionList,
    useGenerateEditScenarioActionList,
} from '../../../features/library/services/LibraryBulkActionsService/helpers';
import { useCreateEditDuplicateLibraryBulActionService } from '../../../features/library/services/LibraryBulkActionsService';
import {
    CreateNewScenarioInfo,
    EditScenarioInfo,
} from '../../../features/library/services/LibraryBulkActionsService/types';
import {
    useCreateEditScenarioFailedCallback,
    useCreateEditScenarioSuccessCallback,
    useHandleArchiveScenario,
} from './services';
import DialogWrapper from '../../../ui/components/DialogWrapper/DialogWrapper';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

const uuid = require('uuid/v1');
const qs = require('query-string');

export const StyledContainer = styled.div`
    overflow: auto;
    height: 100%;
    display: block;
    padding: 0 32px;
    background: ${props => props.theme.Colors.white};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const StyledLoadingContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const CreatedByWrapper = styled.div`
    padding: 0 16px 10px;
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
`;

export const StatusItems: Array<{
    value: string;
    name: string;
    confirm: boolean;
    message: string;
}> = [
        {
            value: 'DRAFT',
            name: 'Draft',
            message: 'Scenario saved as Draft',
            confirm: false,
        },
        {
            value: 'ACTIVE',
            name: 'Active',
            message: 'Scenario published',
            confirm: false,
        },
        {
            value: 'ARCHIVED',
            name: 'Archived',
            message: 'Scenario archived',
            confirm: true,
        },
        {
            value: 'DELETED',
            name: 'Deleted',
            message: 'Scenario deleted',
            confirm: true,
        },
    ];

export const LoadingSaveContext = createContext(false);
export const ScenarioContainerContext = createContext<any>(null);
export const PlayAudioContext = createContext<any>(null);
export const EditModeContext = createContext<{
    mode: string;
    action: (mode: EditModeValues) => void;
}>({
    mode: 'view',
    action: () => { },
});

export interface NewScenarioInterface extends RouteComponentProps {
    profile?: UserProfile;
    scenario: Scenario;
    scenarioId: string;
    loading: boolean;
    isNew: boolean;
    scenarioCase: ScenarioCases;
    handleFetchScenario: (scenarioId: number) => void;
    handleCreateScriptLine?: any;
    handleCreatePlaylist?: any;
    handleResetPlaylist: () => void;
    handleResetScenario: () => void;
    handleLoadVoiceSettings: () => void;
    itemId?: number;
    location: any;
    match: any;
    dispatch?: any;
    closePath: string;
    closeGoingBack: boolean;
    showMessage: (
        message: string | string[],
        status: 'error' | 'success'
    ) => void;
    clearScriptLines: () => void;
}

const NewScenario: FC<NewScenarioInterface> = ({
    history,
    profile,
    scenario,
    scenarioId,
    loading,
    isNew,
    scenarioCase,
    closeGoingBack,
    handleCreateScriptLine,
    handleResetScenario,
    handleFetchScenario,
    handleResetPlaylist,
    handleCreatePlaylist,
    handleLoadVoiceSettings,
    closePath,
    showMessage,
    clearScriptLines,
}) => {
    const createEditScenarioSuccessCallback =
        useCreateEditScenarioSuccessCallback();
    const createEditScenarioFailedCallback =
        useCreateEditScenarioFailedCallback();
    const handleArchiveScenario = useHandleArchiveScenario();
    const getRoleplayForScenario = useGetRoleplayForScenario()
    const location = history.location;

    const [editMode, setEditMode] = useState<EditModeValues>(
        EditModeValues.VIEW
    );
    const [loadingSave, setLoadingSave] = useState(false);
    const parentRef = useRef(null);
    const permissions = usePermissionsState();
    const isTeamLead = isTeamLeader(profile?.role?.name);
    const [titleError, setTitleError] = useState(false);
    const roleplayId = qs.parse(location.search)?.roleplayId;
    const contentId = qs.parse(location.search)?.contentId;
    const modifyScenario = useModifyScenarioService();

    useHtmlPageTitle(
        buildPageTitle('Scenario', editMode, isNew),
        [scenario.title]
    );

    const isLoaded = !!scenario.id;

    const onUnload = useCallback(
        (e: any) => {
            e.preventDefault();
            if (
                scenarioCase === 'modified' &&
                permissions.includes(NEW_PERMISSIONS.UPDATE_SCENARIO)
            ) {
                e.returnValue = true;
            }
        },
        [scenarioCase, permissions]
    );

    useEffect(() => {
        setEditMode(
            (isLoaded || isNew) && scenario.status === ScenarioStatuses.DRAFT && !isTeamLead
                ? EditModeValues.EDIT
                : EditModeValues.VIEW
        );
    }, [isLoaded, isNew, scenario.status, setEditMode, isTeamLead]);

    useEffect(() => {
        if (!isTeamLeader(profile?.role?.name)) {
            handleLoadVoiceSettings();
        }
    }, [handleLoadVoiceSettings, profile?.role?.name]);

    useEffect(() => {
        if (scenarioId !== 'new') {
            handleFetchScenario(Number(scenarioId));
        } else {
            handleResetScenario();
            handleResetPlaylist();
        }
        return () => {
            handleResetScenario();
            handleResetPlaylist();
        };
    }, [
        handleResetScenario,
        handleFetchScenario,
        handleResetPlaylist,
        scenarioId,
    ]);

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [onUnload]);

    const createScriptLine = (speaker: ScriptLineSpeaker, text?: string) => {
        const sendValues = {
            id: `temp_${uuid()}`,
            text: text ?? '',
            speaker: speaker,
            position: scenario.script ? scenario.script.lines.length + 1 : 1,
            scriptId: scenario.scriptId,
            keywords: [],
        };

        handleCreateScriptLine(sendValues);
    };

    const playAllAudio = () => {
        handleResetPlaylist();
        const filterItems = scenario.script.lines.filter(
            (script: ScriptLine) => script.audioUrl
        );
        const playlistItems = filterItems.map(
            (script: ScriptLine) => script.id
        );
        setTimeout(() => {
            handleCreatePlaylist(playlistItems);
        }, 150);
    };

    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const setPreviouslyAssignedLabelsAction =
        useSetPreviouslyAssignedLabelsAction();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();

    const generateCreateScenarioActionList =
        useGenerateCreateDuplicateScenarioActionList();

    const generateEditScenarioActionList = useGenerateEditScenarioActionList();

    const createLibraryBulkActionService =
        useCreateEditDuplicateLibraryBulActionService(
            isNew ? 'create' : 'update',
            'Scenario',
            responses =>
                createEditScenarioSuccessCallback(setLoadingSave, responses),
            (error, completedResponses) =>
                createEditScenarioFailedCallback(
                    setLoadingSave,
                    error,
                    completedResponses
                )
        );

    const handleUpdateScenario = (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: Status
    ) => {
        setLoadingSave(true);
        const response = validationService(
            scenario,
            status ? status : scenario.status
        );

        if (!response.valid) {
            callbacks && callbacks.onCancel();
            showMessage(response.message, 'error');
            setLoadingSave(false);

            if (response.name === 'name') {
                setTitleError(true);
            } else {
                setTitleError(false);
            }
            return;
        } else {
            setTitleError(false);
        }

        const newScenarioInfo: CreateNewScenarioInfo = {
            title: scenario.title,
            description: scenario.description,
            labelIds: calculateAssignedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            lines: scenario.script?.lines ? scenario.script?.lines.map((line, index) => ({ ...line, position: index + 1 })) : [],
            status: status ?? scenario.status,
        };

        const editScenarioInfo: EditScenarioInfo = {
            scenarioId: scenario.id ?? 0,
            title: scenario.title,
            description: scenario.description,
            addedLabelIds: calculateAssignedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            deletedLabelIds: calculateRemovedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            lines: newScenarioInfo.lines ?? [],
            status: status ?? scenario.status,
        };

        const createScenarioActionList =
            generateCreateScenarioActionList(newScenarioInfo);

        const editScenarioActionList =
            generateEditScenarioActionList(editScenarioInfo);

        if (status !== ScenarioStatuses.ARCHIVED) {
            if (!scenario.id) {
                createLibraryBulkActionService([createScenarioActionList]);
            } else {
                createLibraryBulkActionService(editScenarioActionList);
            }
        } else {
            scenario?.id && handleArchiveScenario(scenario.id);
        }
    };

    useEffect(() => {
        if (scenario.labels && scenario.labels.length > 0) {
            const selectedLabelIds = scenario.labels;
            const preAssignedLabels = calculatePreSelectedLabels(
                selectedLabelIds,
                labelsList
            );
            setAssignLabelsAction(preAssignedLabels);
        } else {
            setAssignLabelsAction([]);
        }
        return () => {
            setAssignLabelsAction([]);
        };
    }, [
        scenario.labels,
        labelsList,
        calculatePreSelectedLabels,
        setAssignLabelsAction,
    ]);

    //initially set previousAssignedLabels
    useEffect(() => {
        if (!isEmpty(scenario.labels)) {
            const selectedLabelIds = scenario.labels ?? [];
            const preAssignedLabels = calculatePreSelectedLabels(
                selectedLabelIds,
                labelsList
            );
            setPreviouslyAssignedLabelsAction(preAssignedLabels);
        } else {
            setPreviouslyAssignedLabelsAction([]);
        }
        return () => {
            setPreviouslyAssignedLabelsAction([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenario.labels]);

    useEffect(() => {
        if (roleplayId && contentId) {
            getRoleplayForScenario(roleplayId, contentId).then(({ aiLines, title, description }) => {
                aiLines.forEach(line => createScriptLine(line.speaker, line.text));

                modifyScenario(title || '', 'title');
                modifyScenario(description || '', 'description');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleplayId, contentId, getRoleplayForScenario])

    const willBeLoaded = !isNew && scenarioCase === 'init' && !scenario?.id;
    const showEmptyScreen = loading || willBeLoaded;
    if (showEmptyScreen)
        return (
            <ModalPageContainer
                closePath={closePath}
                closeGoingBack={closeGoingBack}
            >
                <StyledLoadingContainer>
                    {loading && <Loading />}
                </StyledLoadingContainer>
            </ModalPageContainer>
        );
    return (
        <ModalPageContainer
            closePath={closePath}
            closeGoingBack={closeGoingBack}
        >
            <StyledContainer ref={parentRef}>
                <EditModeContext.Provider
                    value={{ mode: editMode, action: setEditMode }}
                >
                    <LoadingSaveContext.Provider value={loadingSave}>
                        <CustomHeader
                            scenario={scenario}
                            handleUpdateScenario={handleUpdateScenario}
                            modified={scenarioCase === 'modified'}
                            isViewMode={isTeamLead}
                        />
                    </LoadingSaveContext.Provider>
                    <ActionPanel scenario={scenario} titleError={titleError} />
                    {scenario.creator && (
                        <CreatedByWrapper data-test="created-by-subtitle">
                            Created by {scenario.creator?.firstName}{' '}
                            {scenario.creator?.lastName}
                        </CreatedByWrapper>
                    )}
                    <TableDivider />
                    <ScenarioContainerContext.Provider value={parentRef}>
                        <PlayAudioContext.Provider value={playAllAudio}>
                            <CustomBody
                                createScriptLine={createScriptLine}
                                scenario={scenario}
                            />
                        </PlayAudioContext.Provider>
                    </ScenarioContainerContext.Provider>
                </EditModeContext.Provider>
                <CheckPermission
                    permissions={[NEW_PERMISSIONS.UPDATE_SCENARIO]}
                >
                    <NavigationPrompt when={scenarioCase === 'modified'}>
                        {({ onConfirm, onCancel }) => (
                            <DialogWrapper
                                modalTitle="Discard changes?"
                                description={
                                    'Do you want to save or discard changes?'
                                }
                                cancelButtonText={'Discard'}
                                confirmButtonText={'Save'}
                                onCancel={onConfirm}
                                onConfirm={() => {
                                    handleUpdateScenario({
                                        onConfirm: onConfirm,
                                        onCancel: onCancel,
                                    });
                                }}
                                dataTest="confirmation-modal"
                            />
                        )}
                    </NavigationPrompt>
                </CheckPermission>
            </StyledContainer>
        </ModalPageContainer>
    );
};

export interface NewScenarioContainerProps
    extends RouteComponentProps<{ scenarioId: string }> {
    closePath: string;
    closeGoingBack: boolean;
}
export const NewScenarioContainer: FC<NewScenarioContainerProps> = ({
    closePath,
    closeGoingBack,
    location,
    match,
}) => {
    const history = useHistory();
    const profile = useSelector(getProfileState);
    const isNew = match.params.scenarioId === 'new';

    const scenario = useSelector(getScenarioState).info;
    const loading = useSelector(getScenarioState).loading;
    const scenarioCase = useSelector(getScenarioState).case;

    const getScenario = useGetScenarioService();
    const createScriptLine = useCreateScriptLineService();
    const clearScriptLines = useClearScriptLinesService();
    const resetScenario = useResetScenarioService();
    const createScriptPlaylist = useCreatePlaylistService();
    const resetPlaylist = useResetAudioService();
    const showMessage = useShowMessage();
    const loadVoiceSettings = useLoadVoiceSettingsService();
    const handleLoadVoiceSettings = useCallback(
        () => profile?.companyId && loadVoiceSettings(profile?.companyId),
        [loadVoiceSettings, profile?.companyId]
    );

    return (
        <NewScenario
            location={location}
            history={history}
            profile={profile}
            match={match}
            closeGoingBack={closeGoingBack}
            closePath={closePath}
            scenario={scenario}
            scenarioId={match.params.scenarioId}
            isNew={isNew}
            loading={loading}
            scenarioCase={scenarioCase}
            handleFetchScenario={getScenario}
            handleCreateScriptLine={createScriptLine}
            handleCreatePlaylist={createScriptPlaylist}
            handleResetPlaylist={resetPlaylist}
            handleResetScenario={resetScenario}
            handleLoadVoiceSettings={handleLoadVoiceSettings}
            showMessage={showMessage}
            clearScriptLines={clearScriptLines}
        />
    );
};

export default withRouter(NewScenarioContainer);
