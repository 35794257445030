import { FC, useCallback, useState } from 'react';

import CompaniesDropdownContainer from './CompaniesDropdown';
import { CompaniesDropdownButtonProps, CompanyItem } from './types';
import { useCollapseAllLabelsService } from '../../../labels/store/services';
import NamePlaceholder from '../../../../ui/components/AvatarPlaceholder';
import {
    DownIcon,
    DropdownButton,
    DropdownButtonLabel,
    LogoContainer,
    Root,
    StyledLogo,
    UpIcon,
} from './styles';

export const CompaniesDropdownButton: FC<CompaniesDropdownButtonProps> = ({
    company,
    updatedCompany,
    onChangeCompany,
}) => {
    const [open, setOpen] = useState(false);
    const collapseLabels = useCollapseAllLabelsService();
    const toggleOpen = useCallback(
        (newOpen?: boolean) =>
            setOpen(prevOpen =>
                typeof newOpen === 'boolean' ? newOpen : !prevOpen
            ),
        [setOpen]
    );
    const handleChangeCompany = useCallback(
        (company: CompanyItem) => {
            setOpen(false);
            collapseLabels();
            onChangeCompany(company);
        },
        [setOpen, onChangeCompany, collapseLabels]
    );

    const mediaUrl = company.logo || company.logoUrl;

    return (
        <Root open={open}>
            <DropdownButton
                onClick={() => toggleOpen(!open)}
                data-test="companyDropDownToggleButton"
            >
                <LogoContainer practis={company.practis}>
                    {mediaUrl ? (
                        <StyledLogo src={mediaUrl} alt="org" />
                    ) : (
                        <NamePlaceholder
                            image={mediaUrl}
                            name={company.name}
                        />
                    )}
                </LogoContainer>
                <DropdownButtonLabel>{company.name}</DropdownButtonLabel>
                {open ? (
                    <UpIcon data-test="companyDropDownUpIcon" />
                ) : (
                    <DownIcon data-test="companyDropDownDownIcon" />
                )}
            </DropdownButton>
            <CompaniesDropdownContainer
                updatedCompany={updatedCompany}
                open={open}
                toggleOpen={toggleOpen}
                onChangeCompany={handleChangeCompany}
            />
        </Root>
    );
};

export default CompaniesDropdownButton;
