import { Button } from '../Button';
import { Container, Buttons, InfoText, EmailText } from './styles';
import { ReportingControlPanelViewProps } from './types';

function ReportingControlPanelView({
    isSubmitDisabled,
    isSubmitLoading,
    submitText,
    onSubmit,
    isClearDisabled,
    clearText,
    onClear,
    userEmail,
}: ReportingControlPanelViewProps) {
    return (
        <Container>
            <Buttons>
                <Button
                    color={isSubmitDisabled ? 'gray' : 'default'}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                    loadingSize={24}
                    loadingColor="white"
                    label={submitText}
                    action={onSubmit}
                    width="186px"
                    dataTest="generate-report-button"
                />
                <Button
                    color={isClearDisabled ? 'gray' : 'default'}
                    disabled={isClearDisabled}
                    label={clearText}
                    action={() => onClear()}
                    variant="inverse"
                    width="110px"
                    dataTest="clear-report-button"
                />
            </Buttons>
            <InfoText data-test="report-email-info">
                Report will be sent to <EmailText>{userEmail}</EmailText>
            </InfoText>
        </Container>
    );
}

export default ReportingControlPanelView;