import { useCallback, useMemo } from 'react';
import { PartialVoiceSettingsProps } from './types';
import PartialVoiceSettingsView from './view';
import { VoiceSettingsSelectItem } from '../../types';
import { DEFAULT_CUSTOMER_VOICE_ID, DEFAULT_REP_VOICE_ID } from '../../../../../../features/textToSpeech/constants';

function PartialVoiceSettings({
    role,
    voices,
    defaultSimilarity,
    defaultStability,
    initialSampleText,
    values,
    isDisabled,
    setFieldValue,
    onSampleAction,
}: PartialVoiceSettingsProps) {
    const canReset = !isDisabled
        && (values.similarity !== defaultSimilarity
            || values.stability !== defaultStability);
    const defaultVoiceId = role === 'customer' ? DEFAULT_CUSTOMER_VOICE_ID : DEFAULT_REP_VOICE_ID;

    const voiceItems = useMemo(() => {
        return voices
            .map(voice => {
                return {
                    name: voice.name + (voice.voice_id === defaultVoiceId ? ' (Default)' : ''),
                    value: voice.voice_id
                } as VoiceSettingsSelectItem<string>
            })
            .sort((item1, item2) => {
                if (item1.value === defaultVoiceId) {
                    return -1;
                }
                if (item2.value === defaultVoiceId) {
                    return 1;
                }
                return item1.name >= item2.name ? 1 : -1
            });
    }, [defaultVoiceId, voices]);

    /**
     * @function getFieldName
     * @description builds form field name based on current role
     * @param { string } name
     * @returns { boolean }
     */
    const getFieldName = useCallback(
        (name: string) => `${role}.${name}`,
        [role]
    );

    /**
     * @function handleReset
     * @description sets advanced fields to default values if reset is available
     * @returns { void }
     */
    const handleReset = useCallback(() => {
        if (!canReset) return;
        setFieldValue(getFieldName('stability'), defaultStability);
        setFieldValue(getFieldName('similarity'), defaultSimilarity);
    }, [canReset, defaultSimilarity, defaultStability, getFieldName, setFieldValue]);

    return (
        <PartialVoiceSettingsView
            role={role}
            voiceItems={voiceItems}
            values={values}
            initialSampleText={initialSampleText}
            isDisabled={isDisabled}
            canReset={canReset}
            getFieldName={getFieldName}
            setFieldValue={setFieldValue}
            onReset={handleReset}
            onSampleAction={onSampleAction}
        />
    );
}

export default PartialVoiceSettings;