export enum BulkActionAvailability {
    AVAILABLE_FOR_ALL,
    AVAILABLE_FOR_SOME,
    UNAVAILABLE_FOR_ALL,
}

export function canPerformBulkAction<TItem>(items: TItem[] | undefined, canPerformAction: (item: TItem) => boolean): BulkActionAvailability {
    if (!!items?.length) {
        const availableFor = items.filter(canPerformAction);
        if (availableFor.length === items.length) {
            return BulkActionAvailability.AVAILABLE_FOR_ALL;
        }
        else if (availableFor.length > 0 && availableFor.length < items.length) {
            return BulkActionAvailability.AVAILABLE_FOR_SOME;
        }
    }
    return BulkActionAvailability.UNAVAILABLE_FOR_ALL;
}

export function bulkAvailabilityToFlags(bulkActionAvailability: BulkActionAvailability) {
    const visibleAvailabilities = [BulkActionAvailability.AVAILABLE_FOR_ALL, BulkActionAvailability.AVAILABLE_FOR_SOME];
    const disabledAvailabilities = [BulkActionAvailability.AVAILABLE_FOR_SOME, BulkActionAvailability.UNAVAILABLE_FOR_ALL];
    return {
        visible: visibleAvailabilities.includes(bulkActionAvailability),
        disabled: disabledAvailabilities.includes(bulkActionAvailability),
    };
}