import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../ui/components/Button';
import { Fieldset } from '../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../portableLabels';
import { useSaveLabels } from '../store/hors/withLabels/services';
import { useLabelsState } from '../store/hors/withLabels/states';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div<{ withBorder?: boolean }>`
    width: ${({withBorder}) => withBorder ? `272px` : '208px'};
    max-height: 432px;
    min-height: 200px;
    display: flex;
    ${({withBorder}) => withBorder && `padding: 16px 8px 0 8px;`};
    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div<{ withBorder?: boolean }>`
    width: ${({withBorder}) => withBorder ? `272px` : '208px'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    margin-top: 16px;
    ${({withBorder}) => withBorder && `padding: 0 8px;`};
`;

export const AssignLabels: FC<{
    onLabelsChange?: (ids: number[]) => void;
    onSuccessApply: () => void;
    withBorder?: boolean;
}> = ({ onLabelsChange, onSuccessApply, withBorder }) => {
    const saveLabels = useSaveLabels();
    const labels = useLabelsState();

    const [labelIds, setLabelIds] = useState<number[]>(labels.selected);
    const [applyDisabled, setApplyDisabled] = useState(true);

    const onLabelChangeHandler = useCallback(
        (ids: number[]) => {
            setLabelIds(ids);
            setApplyDisabled(false);
        },
        [setLabelIds]
    );

    const onApplyHandler = useCallback(() => {
        onLabelsChange?.(labelIds);
        saveLabels();
        onSuccessApply();
    }, [onLabelsChange, labelIds, onSuccessApply, saveLabels]);

    return (
        <Container>
            <Wrapper withBorder={withBorder}>
                <Fieldset
                    withoutBorder={!withBorder}
                    title={withBorder ? 'Labels' : ''}
                    dataTest="labels-section-title"
                >
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            onSetSelected={onLabelChangeHandler}
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions withBorder={withBorder}>
                <Button
                    width={withBorder ? '120px' : '96px'}
                    action={onSuccessApply}
                    variant="inverse"
                    dataTest="cancel-button"
                >
                    Cancel
                </Button>
                <Button
                    width={withBorder ? '120px' : '96px'}
                    action={onApplyHandler}
                    disabled={applyDisabled}
                    dataTest="apply-button"
                >
                    Apply
                </Button>
            </Actions>
        </Container>
    );
};
