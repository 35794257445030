import React, { FC } from 'react';
import styled from 'styled-components';
import { Label } from '../../../constants/interfaces/Label';
import PortableLabelListItem from './PortableLabelListItem';

const StyledLabelsList = styled.div`
    margin-bottom: 16px;
    overflow: auto;
`;

const ScrollableLabelContainer = styled.div`
    height: 100%;
`;

export const PortableLabelsList: FC<{
    labels: Label[];
    allLabels?: Label[];
    labelsCollapseState: number[];
    selectedLabels: number[];
    disableLabels?: boolean;
    toggleCollapse(labelId: number): void;
    setLabelFilters?: (labelIds: number[]) => void;
}> = ({
    labels,
    allLabels,
    labelsCollapseState,
    selectedLabels,
    disableLabels,
    setLabelFilters,
    toggleCollapse,
}) => {
    if (!labels || (labels && labels.length === 0)) return null;
    return (
        <StyledLabelsList>
            <ScrollableLabelContainer>
                {labels &&
                    labels.map(label => {
                        return (
                            <PortableLabelListItem
                                allLabels={allLabels}
                                key={label.id}
                                label={label}
                                labelsCollapseState={labelsCollapseState}
                                selectedLabels={selectedLabels}
                                disableLabels={disableLabels}
                                setLabelFilters={setLabelFilters}
                                toggleCollapse={toggleCollapse}
                            />
                        );
                    })}
            </ScrollableLabelContainer>
        </StyledLabelsList>
    );
};
