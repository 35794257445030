import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';

export const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 4px;
    display: inline-flex;
`;

export const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const WrapperTableCell = styled.div`
    width: 70%;
    margin: 0 auto;

    td {
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        span {
            width: 71px !important;
        }
    }

    td:first-child {
        span {
            width: 66px !important;
        }
    }

    @media (max-width: 1441px) {
        td:first-child {
            padding-left: 8px !important;
        }
    }
`;

export const useStyles = makeStyles(theme => ({
    customTableCellStyle: {
        padding: '0px !important',
    },
    multiTableCellStyle: {
        padding: '10px 0 0 22px !important',
    },
    labelsCellStyle: {
        justifyContent: 'flex-end !important'
    }
}));
