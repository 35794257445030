export const Variables = {
    Colors: {
        white: '#ffffff',
        softBlue: '#4aa9e2',
        skyBlueTwo: '#4aa9e2',
        darkSkyBlue: '#4aa9e2',
        mainBlue: '#4aa9e2',
        darkSkyBlueTwo15Full: '#e4f2fb',
        steelGrey: '#6d7f8c',
        cloudyBlue: '#b1c0cb',
        whiteFive: '#f8f8f8',
        paleGrey: '#e1e8ee',
        tomato: '#ec513d',
        brown: '#a47200',
        topaz: '#15cba9',
        greenTwo: '#15cba9',
        darkGreen: '#066150',
        dark: '#222431',
        darkTwo: '#171923',
        black: '#212121',
        transparent: 'transparent',
        // give list below
        backgroundGray: '#f4f6f7',
        backgroundDark: '#181923',
        lightGray: '#ebf1f3',
        lighterGray: '#f6f6f6',
        iconGray: '#9aa6b6',
        iconGrayBg: '#f2f2f2',
        coolGray: '#9da2a8',
        labelGray: '#0000008a',
        hoverGray: '#77777740',
        primaryBlue: '#1eb0ff',
        dodgerBlue: '#3fbcff',
        primaryRed: '#fe5c6c',
        validGreen: '#51d76a',
        softerBlue: '#73bbe5',
        cornflowerBlue: '#5faad6',
        skyBlue: '#5cc7ff',
        coolBlue: '#4893bf',
        whiteTwo: '#f4f4f4',
        whiteThree: '#e2e2e2',
        whiteSix: '#f7f8f9',
        paleGreyTwo: '#f7f8f9',
        blackTwo: '#000000',
        blackThree: '#323232',
        darkThree: 'rgb(34 36 49)',
        coral: '#fa6653',
        salmon: '#ff7a69',
        paleRed: '#e55340',
        fadedRed: '#e14a59',
        fadedRedMobile: '#cb3a48',
        fadedRedTwoMobile: '#e14a59',
        lightMint: '#72dbc8',
        green: '#72dbc8',
        lightBlueGray: '#d2dfe6',
        windowsBlue: '#3d93c7',
        lightBlue: '#5eb7ed',
        blueyGray: '#99a9b4',
        steelGrey40: 'rgba(109, 127, 140, 0.4)',
        blueyGreyTwo: '#a5b5c0',
        battleshipGrey: '#64717b',
        darkerGray: '#626365',
        transparentSoftBlueTwo: 'rgba(74,169,226,0.5)',
        darkSkyBlueTwo: '#409ed6',
        darkSkyBlueThree: '#31a4e9',
        darkSkyBlue15: 'rgba(74, 169, 226, 0.15)',
        darkSkyBlue50: 'rgba(74, 169, 226, 0.5)',
        cloudyBlue15: 'rgba(177, 192, 203, 0.15)',
        blackTwo5: 'rgba(0, 0, 0, 0.05)',
        macaroniAndCheese: '#f5ba33',
        macaroniAndCheeseOpaque: 'rgba(245, 186, 51, 0.24)',
    },
    Breaks: {
        sm: '576px',
        md: '768px',
        ld: '992px',
        xl: '1200px',
    },
    BreakPoints: {
        mid: 1368,
        small: 768,
        smaller: 640,
        responsiveLg: 1920,
        responsiveMd: 1440,
        responsiveSm: 1024,
        responsiveSmPlus: 1245,
    },
    Spacing: 8,
    Fonts: {
        manrope: 'Manrope',
    },
} as const;
