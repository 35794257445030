import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const BackArrow = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 14"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    fillRule="evenodd"
                    fill="currentColor"
                    d="M303.972 50.656L302.447 49 296 56 302.447 63 303.972 61.344 300.161 57.206 312 57.208 312 54.818 300.138 54.818z"
                    transform="translate(-296 -49)"
                />
            </svg>
        );
    }
);

export default BackArrow;
