import { Challenge } from './Challenge';
import { UserInterface } from './User';
import { UserInfo } from './UserInfo';
import { CompanyInterface } from './Company';
import { PractisSets } from './PractisSets';
import { Score } from './Score';
import { Label } from './Label';

export interface Submission {
    id: number;
    challengeId?: number;
    title: '';
    status: 'PENDING' | 'REVIEWED' | 'ARCHIVED' | 'UPLOADING';
    video: string | null;
    note: string;
    reviewedAt: string;
    createdAt: string;
    updatedAt: string;
    score: Score;
    scoreId?: string;
    challenge: Challenge;
    user: UserInterface;
    company: CompanyInterface;
    practisSet: PractisSets;
    submittedAt: string;
    labels: Label[];
    isViewed?: boolean;
}

export enum SUBMISSION_TYPE {
    SCENARIO = 'scenario',
    CHALLENGE = 'challenge',
};

export enum SUBMISSION_VISIBILITY {
    VISIBLE = 'visible',
    ARCHIVED = 'archived'
}

export enum SUBMISSION_FLAG_TYPE {
    ADMIN = 'admin',
    COMPANY = 'company',
}

export interface SubmissionFlag {
    id: number;
    type: SUBMISSION_FLAG_TYPE;
    createdAt: string;
    userId: number;
}

export interface SubmissionV2 {
    id: number;
    type: SUBMISSION_TYPE.CHALLENGE | SUBMISSION_TYPE.SCENARIO;
    status: string;
    enrollmentId: number;
    challenge?: {
        id: number;
        title: string;
        description: string;
        status: string;
        companyId: number;
        sourceScenarioId?: number;
        createdAt: string;
        updatedAt: string;
    };
    scenario?: {
        id: number;
        title: string;
        description: string;
        status: string;
        companyId: number;
        createdAt: string;
        updatedAt: string;
    };
    practisSet?: {
        id: number;
        name: string;
        description: string;
        status: string;
        companyId: number;
        createdAt: string;
        updatedAt: string;
    };
    user: UserInfo;
    userLabels?: number[];
    company: {
        id: number;
        name: string;
        logo: string;
    };
    createdAt: string;
    updatedAt: string;
    mediaUrl?: string;
    placeholderUrl?: string;
    accuracy: {
        accuracyId: number;
        state: string;
        modeAccuracy: string;
        modeScenario: string;
        accuracy: number;
    };
    flags: SubmissionFlag[];
    counters: {
        dislikesCount: number;
        likesCount: number;
        viewsCount: number;
        feedbacksCount: number;
    };
    relation: {
        isLiked: boolean;
        isDisliked: boolean;
        isViewed: boolean;
    };
    score: number;
    reviewedAt: string;
    isVisible: boolean;
}

export interface SubmissionReview {
    id: number;
    submissionId: number;
    date: string;
    user: UserInfo;
    clarity: number;
    knowledge: number;
    confidence: number;
    enthusiasm: number;
    goalAchieved: number;
    value: number;
    note: string;
}