import { FC } from 'react';

import { TextFieldProps } from './types';

const TextField: FC<TextFieldProps> = props => {
    const { row, renderTitle, dataTest } = props;

    return <span data-test={dataTest}>{renderTitle?.(row)}</span>;
};

export default TextField;

