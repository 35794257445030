import { useDispatch } from 'react-redux';
import { useGetScenarioApi } from '../../../api';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    addGeneratedScriptLineAction,
    fetchTelepromterScenarioFailure,
    fetchTelepromterScenarioStart,
    fetchTelepromterScenarioSuccess,
    resetTelepromterAction,
    setRecordedVideoAction,
} from './actions';
import { ScriptLine } from '../../../constants/interfaces/ScriptLine';

export const useGetTelepromterScenarioService = () => {
    const dispatch = useDispatch();
    const getScenarioApi = useGetScenarioApi();
    const showMessage = useShowMessage();
    return useCallback(
        (scenarioId: number | string) => {
            dispatch(fetchTelepromterScenarioStart());
            getScenarioApi(scenarioId)
                .then(data => {
                    dispatch(fetchTelepromterScenarioSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchTelepromterScenarioFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getScenarioApi, showMessage]
    );
};

export const useAddGeneratedScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scriptLine: Partial<ScriptLine>) => {
            dispatch(addGeneratedScriptLineAction(scriptLine));
        },
        [dispatch]
    );
};

export const useSetRecordedVideoService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (videoBlob: string) => {
            dispatch(setRecordedVideoAction(videoBlob));
        },
        [dispatch]
    );
};

export const useResetTelepromterService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetTelepromterAction());
    }, [dispatch]);
};
