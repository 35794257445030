import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { CompanyInterface } from '../constants/interfaces/Company';
import { UserInterface } from '../constants/interfaces/User';
import Storage from '../services/Storage';
import ROUTES from './routes';

const isAuthorized = () => {
    return !!Storage.get('token');
};

export interface PublicRouterProps extends RouteProps {
    component?: any;
    customPath?: string;
    path?: string;
    profile?: UserInterface;
    company?: CompanyInterface;
    editable?: boolean;
    url?: string;
}

const PublicRoute: FC<PublicRouterProps> = ({
    component: Component,
    customPath,
    url,
    children,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthorized() ? (
                    <Redirect
                        to={{
                            pathname: customPath ? customPath : ROUTES.SUBMISSIONS,
                            state: { from: props.location },
                        }}
                    />
                ) : (
                    <Component {...props} {...rest} />
                )
            }
        />
    );
};

export default PublicRoute;
