import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { createSelectorContext, selectItemsByIds } from '../../../tools/redux';
import { Challenge } from '../../../constants/interfaces/Challenge';


export interface LibraryPractisSetsState {
    data?: ListResult<PractisSets>;
    fullList?: ListResult<PractisSets>;
    selectedPractisSets?: number[];
    assignedPractisSets?: number[];
    separateAssignedPractisSets?: number[];
    searchTerm?: string;
    totalCount?: number;
    loading?: boolean;
    error?: string;
}

export type LibraryPractisSetsStateSelector = (
    state: any
) => LibraryPractisSetsState;

export const LibraryPractisSetsStateSelectorContext = React.createContext<
    LibraryPractisSetsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useLibraryPractisSetState(): LibraryPractisSetsState {
    return useSelector(useContext(LibraryPractisSetsStateSelectorContext));
}

export function selectSelectedPractisSets(state: LibraryPractisSetsState): PractisSets[] {
    return selectItemsByIds<PractisSets>(state?.selectedPractisSets, state?.data?.items);
}

export interface UpdatedLibraryPractisSetsState {
    data?: PractisSets;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export const UpdatedLibraryPractisSetsSelectorContext = createSelectorContext<
    UpdatedLibraryPractisSetsState
>();

export function useUpdatedLibraryPractisSetsState() {
    return useSelector(useContext(UpdatedLibraryPractisSetsSelectorContext));
}

export interface LibraryScenariosState {
    data?: ListResult<Scenario>;
    selectedScenarios?: number[];
    totalCount?: number;
    loading?: boolean;
    error?: string;
}

export type LibraryScenariosStateSelector = (
    state: any
) => LibraryScenariosState;

export const LibraryScenariosStateSelectorContext = React.createContext<
    LibraryScenariosStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useLibraryScenariosState(): LibraryScenariosState {
    return useSelector(useContext(LibraryScenariosStateSelectorContext));
}

export function selectSelectedScenarios(state: LibraryScenariosState): Scenario[] {
    return selectItemsByIds<Scenario>(state?.selectedScenarios, state?.data?.items);
}

export interface UpdatedLibraryScenariosState {
    data?: Scenario;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export const UpdatedLibraryScenariosSelectorContext = createSelectorContext<
    UpdatedLibraryScenariosState
>();

export function useUpdatedLibraryScenariosState() {
    return useSelector(useContext(UpdatedLibraryScenariosSelectorContext));
}

export interface LibraryChallengesState {
    data?: ListResult<Challenge>;
    selectedChallenges?: number[];
    totalCount?: number;
    loading?: boolean;
    error?: string;
}

export type LibraryChallengesStateSelector = (
    state: any
) => LibraryChallengesState;

export const LibraryChallengesStateSelectorContext = React.createContext<
    LibraryChallengesStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function useLibraryChallengesState(): LibraryChallengesState {
    return useSelector(useContext(LibraryChallengesStateSelectorContext));
}

export function selectSelectedChallenges(state: LibraryChallengesState): Challenge[] {
    return selectItemsByIds<Challenge>(state?.selectedChallenges, state?.data?.items);
}

export interface UpdatedLibraryChallengesState {
    data?: Challenge;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export const UpdatedLibraryChallengesStateSelectorContext = createSelectorContext<
    UpdatedLibraryChallengesState
>();

export function useUpdatedLibraryChallengesState(): UpdatedLibraryChallengesState {
    return useSelector(
        useContext(UpdatedLibraryChallengesStateSelectorContext)
    );
}
