import React, { FC, useCallback, useEffect } from 'react';
import { Variables } from '../../../../../theme/variables';
import styled from 'styled-components';
import { Button } from '../../../../../ui/components/Button';
import { Fieldset } from '../../../../../ui/components/Fieldset';
import CreatedByFilters from './CreatedByFilters/CreatedByFilters';
import EditedByFilters from './EditedByFilters/EditedByFilters';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

const SectionContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

export const DraftsFilter: FC<{
    createdBySelectedUsers: number[];
    createdBySavedUsers: number[];
    selectCreatedByUsers: (userIds: number[]) => void;
    saveCreatedByUsers: (userIds: number[]) => void;
    editedBySelectedUsers: number[];
    editedBySavedUsers: number[];
    selectEditedByUsers: (userIds: number[]) => void;
    saveEditedByUsers: (userIds: number[]) => void;
    onSuccessApply: () => void;
}> = ({
    createdBySelectedUsers,
    createdBySavedUsers,
    selectCreatedByUsers,
    saveCreatedByUsers,
    editedBySavedUsers,
    editedBySelectedUsers,
    selectEditedByUsers,
    saveEditedByUsers,
    onSuccessApply,
}) => {
    useEffect(() => {
        selectCreatedByUsers(createdBySavedUsers);
        selectEditedByUsers(editedBySavedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onApplyHandler = useCallback(() => {
        saveCreatedByUsers(createdBySelectedUsers);
        saveEditedByUsers(editedBySelectedUsers);
        onSuccessApply();
    }, [
        createdBySelectedUsers,
        editedBySelectedUsers,
        onSuccessApply,
        saveCreatedByUsers,
        saveEditedByUsers,
    ]);

    const onClearHandler = useCallback(() => {
        selectCreatedByUsers([]);
        selectEditedByUsers([]);
    }, [selectCreatedByUsers, selectEditedByUsers]);

    const selectedFiltersCount =
        createdBySelectedUsers.length + editedBySelectedUsers.length;

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Created by'} dataTest="creators-section-title">
                    <SectionContainer>
                        <CreatedByFilters
                            selectedUsers={createdBySelectedUsers}
                            selectCreatedByUsers={selectCreatedByUsers}
                        />
                    </SectionContainer>
                </Fieldset>
                <Fieldset title={'Edited by'} dataTest="editors-section-title">
                    <SectionContainer>
                        <EditedByFilters
                            selectedUsers={editedBySelectedUsers}
                            selectEditedByUsers={selectEditedByUsers}
                        />
                    </SectionContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {selectedFiltersCount} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        width={'122px'}
                        variant={'inverse'}
                        action={() => onClearHandler()}
                        disabled={selectedFiltersCount < 1}
                        customDisabledColor={Variables.Colors.cloudyBlue}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={() => onApplyHandler()}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
