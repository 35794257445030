import { AppState } from '../../../../../store/reducers';

export type Scope = typeof DEFAULT_TEAMS_SCOPE | string;

export const DEFAULT_TEAMS_SCOPE = 'primary';

export const WithTeamsScopes: Partial<Record<keyof AppState, Scope[]>> = {
    draftEdit: ['assignFilters', 'addNewUser', 'editUser'],
    performance: ['assignFilters', 'addNewUser', 'editUser'],
};
