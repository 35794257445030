import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSetFilterHeaderSearchActive } from '../../../searchable-filter-header/store/services';
import { SearchableFilterHeaderKey } from '../../../searchable-filter-header/store/types';
import {
    disableMultipleTeamsFilter,
    selectMultipleTeams,
} from './actions';

export const useSelectMultipleTeams = () => {
    const dispatch = useDispatch();

    return useCallback(
        (selectedTeamIds: number[]) => {
            dispatch(selectMultipleTeams(selectedTeamIds));
        },
        [dispatch]
    );
};

export const useClearMultipleTeams = () => {
    const dispatch = useDispatch();

    return useCallback(
        () => {
            dispatch(selectMultipleTeams([]));
        },
        [dispatch]
    );
};

export const useDisableMultipleTeamsFilter = () => {
    const dispatch = useDispatch();

    return useCallback((isDisabled: boolean) => {
        dispatch(disableMultipleTeamsFilter(isDisabled));
    }, [dispatch]);
}

export const useClearMultipleTeamsFilter = () => {
    const clearMultipleTeams = useClearMultipleTeams();
    const setMultipleTeamsSearchActive = useSetFilterHeaderSearchActive(SearchableFilterHeaderKey.MULTIPLE_TEAMS);

    return useCallback(() => {
        clearMultipleTeams();
        setMultipleTeamsSearchActive(false);
    }, [clearMultipleTeams, setMultipleTeamsSearchActive]);
};
