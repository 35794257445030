import { Context } from 'react';
import { WEB_SITE_ID, WebSiteIdContext } from '../tools/constants/WebSiteId';
import { UrlBuilder, UrlBuilderContext } from '../tools/url/UrlBuilder';
import {
    EnrollmentStateSelectorContext,
    PractisSetReportStateSelectorContext,
    TrainerInvitationStateSelectorContext,
    TrainerStateSelectorContext,
    UpdatedProgressStateSelectorContext,
    UpdateMembersStateStateSelectorContext,
} from '../features/teams/store/states';
import {
    ChallengeState,
    CompanyVoiceSettingsState,
    InvitationsState,
    LibraryChallengesState,
    LibraryPractisSetsState,
    LibraryScenariosState,
    PractisSetsEnrollmentState,
    selectTainerPractisSetState,
    SubmissionDetailsState,
    SubmissionsState,
    SubmissionPaginationState,
    AccuracyScoreState,
    PractisSetReportsState,
    TrainerInvitationState,
    UpdatedInvitationsState,
    UpdatedLibraryChallengesState,
    UpdatedLibraryPractisSetsState,
    UpdatedLibraryScenariosState,
    UpdatedSubmissionsState,
    UsersState,
    VoiceGenerationState,
    UserPerformanceState,
    LabelsState,
    UpdatedLabelsState,
    LabelsConfigState,
    UpdatedUsersState,
    selectUpdatedProgressState,
    SelectPermissionsState,
    UpdatedUserPractisSetsState,
    SelectLogsState,
    AuthState,
    SelectTelepromterState,
    SelectAiLogsState,
    DraftsState,
    UpdatedDraftsState,
    DraftEditState,
    TeamsState,
    UpdatedTeamsState,
    TeamMembersState,
    TeamsEditState,
    UpdatedMembersState,
    RolesState,
    CompanyAdminsState,
    ReportsState,
    OverdueLearnersState,
    ScenarioRepsState,
    CompanyUserStatsState,
    RoleplaysState,
    RoleplayState
} from '../store/selectors';
import {
    InvitationsStateSelectorContext,
    UpdateInvitationStateStateSelectorContext,
    UpdateUsersStateStateSelectorContext,
    UserPerformanceStateSelectorContext,
    UsersStateSelectorContext,
    UpdatedUserPractisSetsStateSelectorContext,
    UpdateDraftsStateStateSelectorContext,
    DraftsStateSelectorContext,
} from '../features/users/store/states';
import { VoiceGenerationSelectorContext } from '../features/textToSpeech/store/states';
import {
    LibraryPractisSetsStateSelectorContext,
    LibraryScenariosStateSelectorContext,
    LibraryChallengesStateSelectorContext,
    UpdatedLibraryPractisSetsSelectorContext,
    UpdatedLibraryScenariosSelectorContext,
    UpdatedLibraryChallengesStateSelectorContext,
} from '../features/library/store/states';
import { ChallengeStateSelectorContext } from '../features/challenges/store/states';
import { NewRelicApi, NewRelicApiContext } from '../tools/newRelic/NewRelic';
import {
    SubmissionDetailsStateSelectorContext,
    SubmissionsStateSelectorContext,
    SubmissionPaginationStateSelectorContext,
    UpdatedSubmissionsStateSelectorContext,
    AccuracyScoreStateSelectorContext,
} from '../features/submissions/store/states';
import {
    CompanyAdminsStateSelectorContext,
    CompanyUserStatsStateSelectorContext,
    CompanyVoiceSettingsStateSelectorContext,
} from '../pages/CompanySettings/store/states';
import {
    LabelsConfigStateSelectorContext,
    LabelsStateSelectorContext,
    UpdatedLabelsStateSelectorContext,
} from '../features/labels/store/states';
import {
    LocalStorage,
    LocalStorageContext,
} from '../tools/localStorage/LocalStorage';
import { PermissionsStateSelectorContext } from '../features/permissions/store/state';
import { LogsStateSelectorContext } from '../features/logs/store/states';
import { AuthStateSelectorContext } from '../pages/Auth/store/states';
import { TelepromterStateSelectorContext } from '../features/telepromter/store/state';
import { AiLogsStateSelectorContext } from '../features/assessment-logs/store/states';
import { DraftEditStateSelectorContext } from '../pages/ActionPages/NewUser/store/states';
import {
    TeamMembersStateSelectorContext,
    TeamEditSelectorContext,
    TeamsStateSelectorContext,
    UpdateTeamsStateStateSelectorContext,
} from '../features/teams/store/states';
import { UserRolesStateSelectorContext } from '../features/roles/store/states';
import {
    SocketProgressSelectorContext,
    SocketProgressState,
} from '../features/socketProgress(api1)/store/states';
import { SearchState, searchStateSelectorContext } from '../features/searchState/store/states';
import { ReportsStateSelectorContext } from '../pages/Reports/store/states';
import { OverdueLearnersStateSelectorContext } from '../features/teams/pages/OverdueLearners/store/states';
import { ScenarioRepsStateSelectorContext } from '../features/teams/pages/Training/ScenarioReps/store/states';
import { RoleplaysStateSelectorContext } from '../pages/Roleplays/RoleplaysList/store/states';
import { RoleplayStateSelectorContext } from '../pages/Roleplays/RoleplayPage/store/states';

export interface ContextItem<TValue> {
    context: Context<TValue>;
    value: TValue;
}

export function contextItem<TValue>(
    context: Context<TValue>,
    value: TValue
): ContextItem<TValue> {
    return {
        context,
        value,
    };
}

export function createContexts(
    webSiteId: WEB_SITE_ID,
    urlBuilder: UrlBuilder,
    localStorage: LocalStorage,
    newRelicApi: NewRelicApi
): ContextItem<any>[] {
    return [
        contextItem(WebSiteIdContext, webSiteId),
        contextItem(UrlBuilderContext, urlBuilder),
        contextItem(LocalStorageContext, localStorage),
        contextItem(NewRelicApiContext, newRelicApi),
        contextItem(TrainerStateSelectorContext, selectTainerPractisSetState),
        contextItem(
            UpdatedProgressStateSelectorContext,
            selectUpdatedProgressState
        ),
        contextItem(EnrollmentStateSelectorContext, PractisSetsEnrollmentState),
        contextItem(
            TrainerInvitationStateSelectorContext,
            TrainerInvitationState
        ),
        contextItem(
            PractisSetReportStateSelectorContext,
            PractisSetReportsState
        ),
        contextItem(UsersStateSelectorContext, UsersState),
        contextItem(AuthStateSelectorContext, AuthState),
        contextItem(UpdateUsersStateStateSelectorContext, UpdatedUsersState),
        contextItem(InvitationsStateSelectorContext, InvitationsState),
        contextItem(
            UpdateInvitationStateStateSelectorContext,
            UpdatedInvitationsState
        ),
        contextItem(DraftsStateSelectorContext, DraftsState),
        contextItem(DraftEditStateSelectorContext, DraftEditState),
        contextItem(UpdateDraftsStateStateSelectorContext, UpdatedDraftsState),
        contextItem(UserPerformanceStateSelectorContext, UserPerformanceState),
        contextItem(
            UpdatedUserPractisSetsStateSelectorContext,
            UpdatedUserPractisSetsState
        ),
        contextItem(VoiceGenerationSelectorContext, VoiceGenerationState),
        contextItem(
            LibraryPractisSetsStateSelectorContext,
            LibraryPractisSetsState
        ),
        contextItem(
            LibraryScenariosStateSelectorContext,
            LibraryScenariosState
        ),
        contextItem(
            LibraryChallengesStateSelectorContext,
            LibraryChallengesState
        ),
        contextItem(
            UpdatedLibraryPractisSetsSelectorContext,
            UpdatedLibraryPractisSetsState
        ),
        contextItem(
            UpdatedLibraryScenariosSelectorContext,
            UpdatedLibraryScenariosState
        ),
        contextItem(
            UpdatedLibraryChallengesStateSelectorContext,
            UpdatedLibraryChallengesState
        ),
        contextItem(ChallengeStateSelectorContext, ChallengeState),
        contextItem(SubmissionsStateSelectorContext, SubmissionsState),
        contextItem(SubmissionPaginationStateSelectorContext, SubmissionPaginationState),
        contextItem(
            SubmissionDetailsStateSelectorContext,
            SubmissionDetailsState
        ),
        contextItem(
            UpdatedSubmissionsStateSelectorContext,
            UpdatedSubmissionsState
        ),
        contextItem(
            AccuracyScoreStateSelectorContext,
            AccuracyScoreState
        ),
        contextItem(
            AccuracyScoreStateSelectorContext,
            AccuracyScoreState
        ),
        contextItem(
            CompanyVoiceSettingsStateSelectorContext,
            CompanyVoiceSettingsState
        ),
        contextItem(LabelsStateSelectorContext, LabelsState),
        contextItem(LabelsConfigStateSelectorContext, LabelsConfigState),
        contextItem(UpdatedLabelsStateSelectorContext, UpdatedLabelsState),
        contextItem(PermissionsStateSelectorContext, SelectPermissionsState),
        contextItem(LogsStateSelectorContext, SelectLogsState),
        contextItem(TelepromterStateSelectorContext, SelectTelepromterState),
        contextItem(AiLogsStateSelectorContext, SelectAiLogsState),
        contextItem(TeamsStateSelectorContext, TeamsState),
        contextItem(
            UpdateMembersStateStateSelectorContext,
            UpdatedMembersState
        ),
        contextItem(UpdateTeamsStateStateSelectorContext, UpdatedTeamsState),
        contextItem(TeamMembersStateSelectorContext, TeamMembersState),
        contextItem(TeamEditSelectorContext, TeamsEditState),
        contextItem(UserRolesStateSelectorContext, RolesState),
        contextItem(SocketProgressSelectorContext, SocketProgressState),
        contextItem(searchStateSelectorContext, SearchState),
        contextItem(CompanyAdminsStateSelectorContext, CompanyAdminsState),
        contextItem(CompanyUserStatsStateSelectorContext, CompanyUserStatsState),
        contextItem(ReportsStateSelectorContext, ReportsState),
        contextItem(OverdueLearnersStateSelectorContext, OverdueLearnersState),
        contextItem(ScenarioRepsStateSelectorContext, ScenarioRepsState),
        contextItem(RoleplaysStateSelectorContext, RoleplaysState),
        contextItem(RoleplayStateSelectorContext, RoleplayState)

    ];
}
