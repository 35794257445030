import { Author, ContentPanelContainer, DateText, Contentbox, ErrorContainer, ErrorBox, ContentBoxContainer, SendBoxContainer, CommandBox, RegenerateBtn, BtnContainer, PlayButton, ContentItem, Img, Message, BtnCont, Sendbox, TextBox, MenuButton, CommandContainer, NoEntryContainer, WarningBtnContainer, BlueDot, StopIcon, StopGenIcon, SpinnerContainer } from "../styles";
import dayjs from "dayjs";
import { ActionButton, ActionItem } from "../../../../ui/components/ActionButton";
import PlayIcon from "../../../../ui/icons/PlayIcon";
import Dots from "../../../../ui/icons/Dots";
import TextInputWithButtons from "../../components/TextInputWithButtons/TextInputWithButtons";
import RefreshCircle from "../../../../ui/icons/RefreshCircle";
import { KeyboardEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCreateCommandRoleplayApi, useRegenerateRoleplayApi } from "../../../../api/roleplay";
import { getProfileState } from "../../../UserProfile/store/reducers";
import { useSelector } from "react-redux";
import { Button } from "../../../../ui/components/Button";
import WarningIcon from "../../../../ui/icons/WarningIcon";
import { useHistory } from "../../../../tools/router";
import ROUTES from "../../../../routes/routes";
import { useRoleplayState } from "../store/states";
import NamePlaceholder from '../../../../ui/components/AvatarPlaceholder';
import { getCompanyState } from "../../../CompanySettings/store/reducers";
import SidebarRoleplaysLarge from "../../../../ui/icons/SidebarRoleplaysLarge";
import { Text } from "../../RoleplaysList/styles";
import { commandPattern, downloadTxtFile, formatConversationToClipboard } from "../constants";
import CustomerAvatar from "../../../../ui/icons/CustomerAvatar";
import ContentLoading from "../../components/Skeletons/ContentLoading";
import { ContentItem as ContentItemType, ConversationItem } from "../../../../constants/interfaces/Roleplay";
import RefreshCircleDisabled from "../../../../ui/icons/RefreshCircleDisables";
import { useShowMessage } from "../../../../ui/components/ErrorMessages/ErrorMessages";
import { copyTextToClipboard } from '../../../../helpers/functions/copy-to-clipboard';
import { useGenerateAudioForRoleplay } from "../store/services";
import LoadingAudio from "../../../../ui/icons/LoadingAudio";
import { CircularProgress } from "@material-ui/core";
import { useLoadVoiceSettingsService } from "../../../CompanySettings/store/services";
import { isTeamLeader } from "../../../../constants/enums";
import useGoogleAnalyticsWithRoleplay from "../../GoogleAnalytics";

function ContentPanel({ isGenerating, contentData, revertCommand, isDisabled, stopIsDisabled }: { isGenerating: boolean, contentData?: ContentItemType[], revertCommand: (isStop?: boolean) => void, isDisabled?: boolean, stopIsDisabled?: boolean }) {
    const { data: roleplay, isLoading } = useRoleplayState();

    const sendCommand = useCreateCommandRoleplayApi();
    const regenerateRoleplayApi = useRegenerateRoleplayApi();
    const generateAudio = useGenerateAudioForRoleplay();
    const history = useHistory();
    const [message, setMessage] = useState('');
    const profile = useSelector(getProfileState);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const lastItemRef = useRef<HTMLDivElement>(null);
    const observedDivRef = useRef<HTMLDivElement>(null);
    const [observedHeight, setObservedHeight] = useState<number>(0);
    const [userScrolled, setUserScrolled] = useState(false);
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();

    const handleUserScroll = useCallback(() => {
        if (messagesContainerRef.current) {
            const container = messagesContainerRef.current;
            const lastItem = lastItemRef.current;

            if (container && lastItem) {
                const containerBottom = container.scrollTop + container.clientHeight;
                const lastItemBottom = lastItem.offsetTop + lastItem.clientHeight;

                const isAtBottom = lastItemBottom - 10 <= containerBottom;

                isGenerating ? setUserScrolled(!isAtBottom) : setUserScrolled(false);
            }
        }
    }, [isGenerating]);

    const roleplayId = useMemo(() => roleplay?.id, [roleplay]);
    const company = useSelector(getCompanyState);
    const showMessage = useShowMessage();
    const [audioFiles, setAudioFiles] = useState<{ [id: number]: Blob[] } | null>(null);
    const [audioStatus, setAudioStatus] = useState<{ id: number, status: string, currentAudio?: HTMLAudioElement } | null>(null);
    const loadVoiceSettings = useLoadVoiceSettingsService();
    const handleLoadVoiceSettings = useCallback(
        () => profile?.companyId && !isTeamLeader(profile.role?.name) && loadVoiceSettings(profile?.companyId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadVoiceSettings, profile?.companyId]
    );

    const sendCustomCommand = () => {
        roleplay?.id && sendCommand({ roleplayId: roleplay.id.toString(), text: message }).then(() => {
            setMessage('');
            trackEventWithRoleplay('roleplay_screen_instruction_sent_manually', { CharacterLimit: message.length });
        })
    }

    const isOwner = useMemo(() => {
        return roleplay?.owner.id === profile?.id
    }, [
        profile?.id, roleplay?.owner.id
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { handleLoadVoiceSettings() }, [isOwner]);

    useEffect(() => {return () => {
        audioRef.current?.pause()
    }}, [])


    const handleKeyDownChange = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendCustomCommand();
        }
    }

    const regenerateRoleplay = (isRetry?: boolean) => {
        roleplay?.id && regenerateRoleplayApi({ roleplayId: roleplay.id.toString() });
        isRetry ? trackEventWithRoleplay('roleplay_screen_error_retry') : trackEventWithRoleplay('roleplay_screen_regenerate');
    }

    const copyClipboard = (roleplayConversation: ContentItemType) => {
        const roleplayText = roleplay ? formatConversationToClipboard(roleplay?.title, roleplayConversation.conversation, roleplayConversation?.summary || '') : ''
        const copy: 'silent' | boolean = copyTextToClipboard(roleplayText);
        trackEventWithRoleplay('roleplay_screen_copy_clipboard');
        if (copy !== 'silent') {
            if (copy) {
                showMessage(
                    'Copied to the clipboard',
                    'success'
                );
            } else {
                showMessage('Could not copy to clipboard', 'error');
            }
        }
    }

    const downloadFile = (roleplayConversation: ContentItemType) => {
        const roleplayText = roleplay ? formatConversationToClipboard(roleplay?.title, roleplayConversation.conversation, roleplayConversation?.summary || '') : '';
        downloadTxtFile(roleplayText, `Roleplay - ${roleplay?.title}`);
        showMessage('Saved as File', "success");
        trackEventWithRoleplay('roleplay_screen_save_file');
    }

    const playAudio = useCallback((conversation: ConversationItem[], conversationId: number) => {
        stopAudioPlaying();
        trackEventWithRoleplay('roleplay_screen_audio_playback');
        if (audioFiles?.[conversationId]) {
            setAudioStatus({ id: conversationId, status: 'playing' })
            playAudioBlobs(audioFiles?.[conversationId]);
        } else {
            setAudioStatus({ id: conversationId, status: 'generating' })
            generateAudio(conversation).then((data) => {
                setAudioFiles((oldState) => ({ ...oldState, [conversationId]: data }));
                setAudioStatus({ id: conversationId, status: 'playing' })
                playAudioBlobs(data);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateAudio, audioFiles, audioStatus])

    const playAudioBlobs = useCallback((blobs: Blob[], currentAudioIndex = 0) => {
        if (currentAudioIndex >= blobs.length) {
            setAudioStatus(null);
            return;
        }

        const audioBlob = blobs[currentAudioIndex];
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.preload = 'metadata';
        audioRef.current = audio;
        
        audio.play().then(() => setAudioStatus((oldState) => (oldState && { ...oldState, currentAudio: audio }))).catch(error => console.error('Error playing audio:', error));

        audio.onended = () => {
            URL.revokeObjectURL(audioUrl);
            playAudioBlobs(blobs, currentAudioIndex + 1);
        };
    }, []);

    const stopAudioPlaying = () => {
        if (audioStatus?.status === 'playing') {
            if (audioStatus.currentAudio) {
                audioStatus.currentAudio.pause();
            }
            setAudioStatus(null);
        }
    }

    const getHighlightedText = (text: string) => {
        const splitText = text.replace(commandPattern, '%%').split('%%').filter((val) => val);
        const matches = text.match(commandPattern);
        if (!matches) return [<>{text}</>];

        const elements: ReactElement[] = [];
        splitText.forEach((part: string, index: number) => {
            matches && elements.push(<b key={index}>{matches[index]}</b>);
            elements.push(<>{part}</>);
        });

        return elements;
    };

    useEffect(() => {
        if (!isLoading && contentData?.length && !userScrolled && messagesContainerRef.current) {
            const lastItem = lastItemRef.current;
            if (lastItem) {
                const topPosition = lastItem.offsetTop;
                messagesContainerRef.current.scrollTo({ top: topPosition, behavior: 'smooth' });
                setUserScrolled(false)
            }
        }
    }, [isLoading, contentData, userScrolled]);

    useEffect(() => {
        const observedDiv = observedDivRef.current;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setObservedHeight(entry.contentRect.height);
            }
        });

        if (observedDiv) {
            resizeObserver.observe(observedDiv);
        }

        return () => {
            if (observedDiv) {
                resizeObserver.unobserve(observedDiv);
            }
        };
    }, [message]);

    return roleplay && !contentData?.length ?
        <NoEntryContainer>
            <SidebarRoleplaysLarge dataTest="roleplay-icon" />
            <Text data-test="no-roleplay-text">No Roleplays Yet</Text>
        </NoEntryContainer>
        : <>
            {isLoading ? <ContentLoading /> :
                <ContentPanelContainer ref={messagesContainerRef} height={!isOwner ? -20 : observedHeight} onScroll={handleUserScroll}>
                    <div>
                        {contentData && contentData?.map((contentItem) => {
                            if (contentItem.type === 'conversation' && contentItem.conversation.length) {
                                return <Contentbox data-test="conversation-container" key={contentItem.id}>
                                    <ContentBoxContainer>
                                    <DateText data-test="conversation-date">{dayjs(contentItem.createdAt).format('MMM D, YY hh:mm A')}</DateText>
                                    {contentItem.conversation?.map((item, index) => {
                                        return <ContentItem>
                                            {
                                                item.role === 'salesperson' ?
                                                    <NamePlaceholder
                                                        image={company.logo || company.logoUrl}
                                                        name={company.name}
                                                        dataTest={`salesperson-company-logo`}
                                                        className={`avatar-logo ${(company.logo || company.logoUrl) && 'no-border'}`}
                                                    /> :
                                                    <CustomerAvatar
                                                        dataTest={`customer-pic`}
                                                        className="customer-logo"
                                                    />
                                            }
                                            <div>
                                                <Author data-test={`${item.role}-title`}>
                                                    {item.role === 'prospect' ? 'Customer ' : 'Salesperson'}
                                                </Author>
                                                <Message data-test={`${item.role}-text`}>
                                                    {item.text}
                                                    {!contentItem.isFinished && index === contentItem.conversation.length - 1 && <BlueDot />}
                                                </Message>
                                            </div>
                                        </ContentItem>
                                    })}
                                    {contentItem.isFinished && <BtnContainer>
                                        {audioStatus?.id !== contentItem.id && <PlayButton data-test="play-btn" isDisabled={!isOwner} onClick={() => isOwner && playAudio(contentItem.conversation, contentItem.id)}><PlayIcon className="play-btn" /></PlayButton>}
                                        {audioStatus?.id === contentItem.id && audioStatus?.status === 'playing' && <PlayButton data-test="stop-btn" isDisabled={!isOwner} onClick={stopAudioPlaying}><StopIcon /></PlayButton>}
                                        {audioStatus?.id === contentItem.id && audioStatus?.status === 'generating' && <SpinnerContainer data-test="Loading-btn" onClick={stopAudioPlaying}><CircularProgress
                                            size={36}
                                        /><LoadingAudio className="spinner-icn" /></SpinnerContainer>}
                                        {!isOwner ?
                                            <MenuButton isDisabled={!isOwner}><Dots className="dots-btn" dataTest="dots-menu" /></MenuButton>
                                            : <ActionButton customWidth={200} isHidden={false} dataTest="roleplay-menu" button={<MenuButton><Dots className="dots-btn" dataTest="dots-menu" /></MenuButton>}>
                                                <ActionItem dataTest="challenge-action" onClick={() => {
                                                    history.push(`${ROUTES.LIBRARY_SETTINGS.CHALLENGES.SINGLE.replace(
                                                        ':challengeId',
                                                        'new'
                                                    )}?roleplayId=${roleplayId}&contentId=${contentItem.id.toString()}`)
                                                    trackEventWithRoleplay('roleplay_screen_create_challenge');
                                                }}>
                                                    Create Challenge
                                                </ActionItem>
                                                <ActionItem dataTest="scenario-action" onClick={() => {
                                                    history.push(`${ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE.replace(
                                                        ':scenarioId',
                                                        'new'
                                                    )}?roleplayId=${roleplayId}&contentId=${contentItem.id.toString()}`)
                                                    trackEventWithRoleplay('roleplay_screen_create_scenario');
                                                }} >
                                                    Create Scenario
                                                </ActionItem>
                                                <ActionItem dataTest="save-file-action" onClick={() => downloadFile(contentItem)}>
                                                    Save as File
                                                </ActionItem>
                                                <ActionItem dataTest="copy-action" onClick={() => copyClipboard(contentItem)} >
                                                    Copy to Clipboard
                                                </ActionItem>
                                            </ActionButton>
                                        }
                                    </BtnContainer>}
                                    </ContentBoxContainer>
                                </Contentbox>
                            }
                            if (contentItem.type === 'command') {
                                return <CommandContainer data-test="command-container" key={contentItem.id}>
                                    <CommandBox>
                                    <DateText data-test="command-date">{dayjs(contentItem.createdAt).format('MMM D, YY hh:mm A')}</DateText>
                                    <ContentItem>
                                        <Img
                                            profile={roleplay?.owner}
                                            loading={isLoading}
                                            size="default"
                                            dataTest="command-author-pic"
                                            className="command-author-pic"
                                        />
                                        <div>
                                            <Author data-test="command-author-name">
                                                {roleplay?.owner.firstName + ' ' + roleplay?.owner.lastName}
                                            </Author>
                                            <Message data-test="command-text">
                                                {getHighlightedText(contentItem.text)}
                                            </Message>
                                        </div>
                                    </ContentItem>
                                    </CommandBox>
                                </CommandContainer>
                            }
                            if (contentItem.type === 'error') {
                                return <ErrorContainer data-test="command-container" key={contentItem.id}>
                                    <ErrorBox>
                                    <DateText data-test="error-date" >{dayjs(contentItem.createdAt).format('MMM D, YY hh:mm A')}</DateText>
                                    <TextBox>
                                        <WarningIcon dataTest="error-icon" className="error-icon"></WarningIcon>
                                        <ContentItem className="error-item">
                                            <div>
                                                <Author data-test="error-title">
                                                    Roleplay Generation Error
                                                </Author>
                                                <Message data-test="error-text">
                                                    An error occurred while generating the Roleplay, please try again in a few seconds.
                                                </Message>
                                            </div>
                                            <WarningBtnContainer>
                                                <Button color={'warning'} disabled={!isOwner} width="140px" dataTest="retry-btn" className="retry-btn" action={() => regenerateRoleplay(true)}>Try again</Button>
                                                <Button color={'warning-inverse'} variant='inverse' disabled={!isOwner} width="140px" dataTest="revert-btn" className="revert-btn" style={{ marginLeft: '12px' }} action={() => revertCommand()}>Revert</Button>
                                            </WarningBtnContainer>
                                        </ContentItem>
                                    </TextBox>
                                    </ErrorBox>
                                </ErrorContainer>
                            }
                            return <></>
                        })}
                        <div ref={lastItemRef}></div>
                    </div>

                    {isOwner && <Sendbox ref={observedDivRef}>
                        <SendBoxContainer>
                        {isGenerating ?
                            <BtnCont>
                                <RegenerateBtn isDisabled={stopIsDisabled} data-test="stop-generation-btn" onClick={() => {isGenerating && !stopIsDisabled && revertCommand(true)}}><StopGenIcon data-test="stop-generation-icon" isDisabled={stopIsDisabled}><div /></StopGenIcon>Stop generating</RegenerateBtn>
                            </BtnCont>
                            :
                            <BtnCont>
                                <RegenerateBtn isDisabled={isDisabled} data-test="regenerate-btn" onClick={() => !isGenerating && !isDisabled && regenerateRoleplay()}>{isDisabled ? <RefreshCircleDisabled dataTest="regenerate-icon" /> : <RefreshCircle dataTest="regenerate-icon" />} Regenerate</RegenerateBtn>
                            </BtnCont>
                        }
                        <TextInputWithButtons max={500} disabled={isDisabled} maxRows={7} value={message} textAreaMinHeight={'25px'} onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyDownChange} dataTest="command" onApply={sendCustomCommand} placeholder="Tell Roleplay AI what to do..." />
                        </SendBoxContainer>
                    </Sendbox>}
                </ContentPanelContainer>
            }
        </>
}

export default ContentPanel