import { useCallback, ChangeEvent } from 'react';
import SearchInputView from './view';
import { SearchInputProps } from './types';
import { escPressed } from '../../../../../helpers/functions/key-down-helpers';

function SearchInputController({ value, onChange, onExit, inputRef }: SearchInputProps) {
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const value = e.target.value;
            onChange(value);
        },
        [onChange]
    );

    /**
     * @description handles ESC events on input key down
     * @function handleKeyDown
     * @param { KeyboardEvent } event
     * @returns { void }
     */
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const target = event.currentTarget as HTMLInputElement;
            if (escPressed(event)) {
                onExit();
                target.blur();
            }
        },
        [onExit]
    );

    const handleBlur = useCallback(() => {
        if (!value) {
            onExit();
        }
    }, [onExit, value]);

    return (
        <SearchInputView
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
        />
    );
}

export default SearchInputController;