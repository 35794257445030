// src/hooks/useGoogleAnalyticsEvents.ts

import { useEffect } from 'react';

export interface AnalyticsInformation {
  UserID?: string;
  UserName?: string;
  Email?: string;
  CompanyID?: number;
  CompanyName?: string;
  Environment?: string;
  CharacterLimit?: number;
}

export interface EventOptions {
  category: string;
  action: string;
  label?: string;
  value?: number;
  userInformation?: AnalyticsInformation;
}


const useGoogleAnalyticsEvents = () => {
  if (window && window.gtag) { 
    window.gtag('config', 'G-QB6SGQF7VJ', { 'allow_enhanced_measurement': false }); 
  }

  const sendEvent = ({ category, action, value, userInformation }: EventOptions) => {
    const eventPayload = {
      event_category: category,
      value: value,
      ...userInformation,
    };

    if (window && window.gtag) {
      window.gtag('event', action, eventPayload);
    }
  };

  useEffect(() => {
    if (typeof window.gtag !== 'function') {
      console.error("Google Analytics 'gtag' function not found. Make sure Google Analytics is properly set up.");
    }
  }, []);

  return sendEvent;
};

export default useGoogleAnalyticsEvents;
