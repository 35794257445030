import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Sound = forwardRef<SVGSVGElement, IconProps>(
    ({ className, width, height }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width={width ?? 16}
                height={height ?? 16}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.563 2.069A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.238.57L3.472 11H1.75A1.75 1.75 0 0 1 0 9.25v-2.5C0 5.784.784 5 1.75 5h1.723l3.289-2.82a.75.75 0 0 1 .801-.111zM6.5 4.38 4.238 6.319a.75.75 0 0 1-.488.181h-2a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2a.75.75 0 0 1 .488.18L6.5 11.62V4.38zm6.096-2.038a.75.75 0 0 1 1.06 0 8 8 0 0 1 0 11.314.75.75 0 0 1-1.06-1.06 6.5 6.5 0 0 0 0-9.193.75.75 0 0 1 0-1.06v-.001zm-1.06 2.121a.75.75 0 1 0-1.061 1.061 3.5 3.5 0 0 1 0 4.95.75.75 0 1 0 1.06 1.06 5 5 0 0 0 0-7.07l.001-.001z" fill="currentColor"/>
            </svg>
        );
    }
);

export default Sound;
