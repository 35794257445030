import React, { FC } from 'react';
import { IconProps } from './types';

export const ThinCheck: FC<IconProps> = ({ className }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 16"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <path
                            d="M0 0H16V16H0z"
                            transform="translate(-1389 -404) translate(1389 404)"
                        />
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            d="M0.98 9.032L5.024 13.076 15.099 3"
                            transform="translate(-1389 -404) translate(1389 404)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ThinCheck;
