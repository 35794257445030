import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DateRangeType } from '../types';

import {selectDateRange, clearDateRange} from './actions';

export const useSelectDateRange = () => {
    const dispatch = useDispatch();

    return useCallback(
        (dateRange: DateRangeType) => {
            dispatch(selectDateRange(dateRange));
        },
        [dispatch]
    );
};

export const useResetDateRange = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(clearDateRange());
    }, [dispatch]);
};
