import styled from 'styled-components';

export const StyledActionsDivs = styled.div<{
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    marginTop?: number;
    padding?: string;
}>`
    display: flex;
    flex-direction: ${props => props.flexDirection || 'row-reverse'};
    justify-content: ${props => props.justifyContent || 'space-between'};
    align-items: ${props => props.alignItems || 'normal'};
    margin-top: ${props => props.marginTop || 23}px;
    padding: ${props => (!!props.padding ? props.padding : '0px 5px')};
    flex-wrap: wrap-reverse;
`;

export const StyledActionsLinks = styled.div<any>`
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.marginTop || 0}px;
`;

export const StyledActionsText = styled.div<{
    fontSize: number;
    fontWeight: string;
}>`
    font-size: ${props => props.fontSize || 14}px;
    font-weight: ${props => props.fontWeight || 'normal'};
    color: ${props => props.theme.Colors.white};
`;
