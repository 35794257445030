import React, { FC } from 'react';
import styled from 'styled-components';
import { Input } from '../../../ui/components/input';

const LabelInput = styled(Input)`
    font-size: 13px;
    padding: 0 8px;
    &::placeholder {
        font-size: 11px;
    }
`;

const AddNewLabelInput: FC<{
    name: string;
    setName: (name: string) => void;
    onKeyPress?: (e: KeyboardEvent) => void;
}> = ({ name, setName, onKeyPress }) => {
    return (
        <LabelInput
            height="24px"
            colorTheme="dark"
            autoFocus={true}
            placeholder="Label name"
            maxLength={144}
            value={name}
            handleChange={e => setName(e.target.value)}
            onKeyPress={onKeyPress}
            dataTest='sidebar-labels-new-input'
        />
    );
};

export default AddNewLabelInput;
