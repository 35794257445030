import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { isAdminPortal } from '../../../helpers/functions/general';
import PractisAdminPlatformLogo from '../../icons/PractisAdminPlatformLogo';
import PractisLogo from '../../icons/PractisLogo';

const StyledPractisIcon = styled(isAdminPortal() ? PractisAdminPlatformLogo : PractisLogo)<{ color?: string }>`
    color: ${props => (props.color ? props.color : props.theme.Colors.white)};
`;

const StyledLogo = styled.div<{ height: number; width?: number }>`
    height: ${props => props.height}px;
    width: ${props => (props.width ? props.width + 'px' : 'auto')};
    & img {
        height: 100%;
    }
    &:active {
        opacity: 0.7;
    }
`;

const Logo: FC<{
    height?: number;
    width?: number;
    color?: string;
    className?: string;
    onClick?: () => void;
}> = memo(({ height, width, color, className, onClick }) => {
    return (
        <StyledLogo
            height={height ? height : 25}
            width={width}
            onClick={onClick}
            className={className}
        >
            <StyledPractisIcon color={color} />
        </StyledLogo>
    );
});

export default Logo;
