import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const MenuItem = styled(motion.div)<{
    menuPosition: 'left' | 'right';
    width?: string;
}>`
    min-width: ${props => (props.width ? props.width : '192px')};
    position: absolute;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    ${props => props.menuPosition}: 0;
    top: 8px;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
`;

const ContentArea = styled.div`
    max-height: 300px;
    overflow-y: auto;
    padding: 16px;
`;

const ANIMATION_SCALE = 5;

export const SelectMenu: FC<{
    menuPosition?: 'left' | 'right';
    width?: string;
}> = ({ children, menuPosition, width }) => {
    return (
        <MenuItem
            initial={{ scale: 1 / ANIMATION_SCALE }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            menuPosition={menuPosition ? menuPosition : 'right'}
            width={width}
        >
            <ContentArea>{children}</ContentArea>
        </MenuItem>
    );
};
