import { FC, useCallback, useEffect } from 'react';
import { Button } from '../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { PortableTeamsWithStore } from '../../../../../portableTeams';
import { useDeselectAllTeams, useSaveTeams } from '../../../../../portableTeams/store/hors/withTeams/services';
import { useTeamsState } from '../../../../../portableTeams/store/hors/withTeams/states';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import { Container, TeamsContainer, Wrapper, LabelsContainer, Actions, SummaryCaption, ButtonWrapper } from './styles';

export const OverdueLearnersFilter: FC<{
    preSelectedLabels: number[];
    onLabelsChange: (ids: number[]) => void;
    onTeamsChange: (ids: number[]) => void;
    onSuccessApply: () => void;
}> = ({
    preSelectedLabels,
    onLabelsChange,
    onTeamsChange,
    onSuccessApply
}) => {
    const teams = useTeamsState();
    const labels = useLabelsState();
    const selectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const saveTeams = useSaveTeams();
    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labels.selected);
        onTeamsChange(teams.selected);
        saveLabels();
        saveTeams();
        onSuccessApply();
    }, [
        onLabelsChange,
        labels.selected,
        onTeamsChange,
        teams.selected,
        saveLabels,
        saveTeams,
        onSuccessApply,
    ]);

    const totalFiltersSelected =
        teams.selected.length +
        labels.selected.filter((id: any) => id > 0).length;

    const deselectLabels = useDeselectAllLabels();
    const deselectTeams = useDeselectAllTeams();

    const onClearHandler = () => {
        deselectLabels();
        deselectTeams();
    }

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Teams'} dataTest="teams-section-title">
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant='inverse'
                        width={'122px'}
                        action={onClearHandler}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={onApplyHandler}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
