import { useCallback, useState } from 'react';

import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { EnterMobileNumberProps } from './types';
import EnterMobileNumberView from './view';
import { useUpdateUserPhoneNumber } from '../../../../../api';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import {
    CLIENT_SIDE_ERROR_MESSAGE,
    PHONE_ALREADY_EXISTS
} from '../../../../../ui/components/ErrorMessages/constants';

function EnterMobileNumberController({
    userId,
    mobileNumber,
    canSkip,
    onMobileNumberChange,
    onSendCode,
    onSkip,
}: EnterMobileNumberProps) {
    const showMessage = useShowMessage();
    const updateUserPhoneNumber = useUpdateUserPhoneNumber();

    const [isSending, setIsSending] = useState(false);

    /**
     * @function handleSendCode - calls API to save mobile number in user profile
     * and send verification code to a user cell phone
     * @returns { void }
     */
    const handleSendCode = useCallback(() => {
        if (userId) {
            setIsSending(true);

            updateUserPhoneNumber(userId, mobileNumber)
                .then((result: any) => {
                    setIsSending(false);
                    if (result.status === 'error') {
                        showMessage(result.message ?? result.data, 'error');
                    } else {
                        onSendCode();
                    }
                })
                .catch((error: ErrorResult) => {
                    error.message && showMessage(
                        error.errorCode === 409 ? CLIENT_SIDE_ERROR_MESSAGE[PHONE_ALREADY_EXISTS] : error.message,
                        'error'
                    );
                    setIsSending(false);
                });
        }
    }, [
        userId,
        updateUserPhoneNumber,
        mobileNumber,
        showMessage,
        onSendCode,
    ]);

    return (
        <EnterMobileNumberView
            mobileNumber={mobileNumber}
            isSending={isSending}
            canSkip={canSkip}
            onSendCode={handleSendCode}
            onSkip={onSkip}
            onMobileNumberChange={onMobileNumberChange}
        />
    );
}


export default EnterMobileNumberController;