import { LocalStorage } from './LocalStorage';

export class DefaultLocalStorage implements LocalStorage {
    private readonly storage = localStorage;

    get(key: string): any {
        const item = this.storage.getItem(key);

        if (item) {
            return JSON.parse(item);
        } else {
            return null;
        }
    }

    set(key: string, data: any): string | null {
        this.storage.setItem(key, JSON.stringify(data));
        return this.storage.getItem(key);
    }

    clear(): any {
        return this.storage.clear();
    }

    remove(key: string): boolean {
        if (this.storage.getItem(key) !== null) {
            this.storage.removeItem(key);
            return true;
        } else {
            return false;
        }
    }
}
