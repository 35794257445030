import { ScriptLine } from '../../../constants/interfaces/ScriptLine';

export enum ACTIONS {
    FETCH_TELEPROMTER_SCENARIO_START = 'FETCH_TELEPROMTER_SCENARIO_START',
    FETCH_TELEPROMTER_SCENARIO_SUCCESS = 'FETCH_TELEPROMTER_SCENARIO_SUCCESS',
    FETCH_TELEPROMTER_SCENARIO_FAILURE = 'FETCH_TELEPROMTER_SCENARIO_FAILURE',

    ADD_GENERATED_SCRIPT_LINE = 'ADD_GENERATED_SCRIPT_LINE',
    SET_RECORDED_VIDEO = 'SET_RECORDED_VIDEO',
    RESET_TELEPROMTER = 'RESET_TELEPROMTER',
}

export function fetchTelepromterScenarioStart() {
    return {
        type: ACTIONS.FETCH_TELEPROMTER_SCENARIO_START as ACTIONS.FETCH_TELEPROMTER_SCENARIO_START,
    };
}

export function fetchTelepromterScenarioSuccess(data: any) {
    return {
        type: ACTIONS.FETCH_TELEPROMTER_SCENARIO_SUCCESS as ACTIONS.FETCH_TELEPROMTER_SCENARIO_SUCCESS,
        data,
    };
}

export function fetchTelepromterScenarioFailure(error: any) {
    return {
        type: ACTIONS.FETCH_TELEPROMTER_SCENARIO_FAILURE as ACTIONS.FETCH_TELEPROMTER_SCENARIO_FAILURE,
        error,
    };
}

export function addGeneratedScriptLineAction(scriptLine: Partial<ScriptLine>) {
    return {
        type: ACTIONS.ADD_GENERATED_SCRIPT_LINE as ACTIONS.ADD_GENERATED_SCRIPT_LINE,
        scriptLine,
    };
}

export function setRecordedVideoAction(videoBlob: string) {
    return {
        type: ACTIONS.SET_RECORDED_VIDEO as ACTIONS.SET_RECORDED_VIDEO,
        videoBlob,
    };
}

export function resetTelepromterAction() {
    return {
        type: ACTIONS.RESET_TELEPROMTER as ACTIONS.RESET_TELEPROMTER,
    };
}
