import { memo, useCallback, useEffect, useRef } from 'react';
import { FormikActions } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import { UserProfile } from '../../../constants/interfaces/User';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../UserProfile/store/reducers';
import RegisterView from './view';
import { RegisterFormValuesType } from './types';
import { useTestInvitationService } from '../store/services';
import ROUTES from '../../../routes/routes';
import { LocationState } from '../../../tools/router';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

const qs = require('query-string');

function RegisterController() {
    const formRef = useRef(null);
    const history = useHistory();
    const location = useLocation<LocationState>();

    const user: UserProfile | undefined = useSelector(getProfileState);
    const handleTestInvitation = useTestInvitationService();

    useHtmlPageTitle("Invitation Code From Email");


    /**
     * @function failureTestInvitationCallback
     * @param { function } setFieldError
     * @param { string } errorMessage
     * @returns { void }
     */
    const failureTestInvitationCallback = (
        setFieldError: (field: string, message: string) => void,
        errorMessage?: string
    ) => {
        errorMessage && setFieldError('invitationCode', errorMessage);
    };

    /**
     * @function handleSubmit
     * @param { RegisterFormValuesType } values
     * @param { FormikActions<RegisterFormValuesType> } formikActions
     */
    const handleSubmit = useCallback(
        (
            values: RegisterFormValuesType,
            formikActions: FormikActions<RegisterFormValuesType>
        ) => {
            const { invitationCode } = values;
            const { setSubmitting, setFieldError } = formikActions;

            if (invitationCode) {
                handleTestInvitation(invitationCode, errorMessage =>
                    failureTestInvitationCallback(setFieldError, errorMessage)
                ).then(response => {
                    if (response) {
                        history.push(
                            `${ROUTES.AUTH_PAGES.REGISTRATION}?token=${invitationCode}`
                        );
                    }
                });

                setSubmitting(false);
            }
        },
        [handleTestInvitation, history]
    );

    /**
     * @function handleRedirectToInvalidInvitationPage
     * @returns { void }
     */
    const handleRedirectToInvalidInvitationPage = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.INVALID_INVITE);
    }, [history]);

    useEffect(() => {
        const invitationToken = qs.parse(location.search).code ?? null;

        if (invitationToken) {
            handleTestInvitation(
                invitationToken,
                handleRedirectToInvalidInvitationPage
            );
        }
    }, [handleRedirectToInvalidInvitationPage, handleTestInvitation, location]);

    return (
        <RegisterView
            formRef={formRef}
            user={user}
            handleSubmit={handleSubmit}
        />
    );
}

export default memo(RegisterController);
