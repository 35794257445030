import {
    EnrollmentStatus,
    EnrollmentStatusEnum,
} from '../../../constants/interfaces/Enrollments';
import { SearchParams } from '../../../constants/interfaces/filters';
import { SearchTeamMembersParams, SearchTeamParams } from '../../../api/teams/types';
import { dueDateFiltersValues } from '../../../ui/components/DueDateCalendar/constants';
import { SearchTrainingPractisSetProgressParams } from '../../../api/teams/types';
import { getSearchSortingValue } from '../../../helpers/functions/search-params-helpers';
import { userStatusFilters } from '../../../constants/interfaces/User';

const enrollmentStatusNames = {
    [EnrollmentStatus.ENROLLED as string]: 'Added',
    [EnrollmentStatus.PENDING as string]: 'Pending',
    [EnrollmentStatus.COMPLETED as string]: 'Completed',
};

const enrollmentStatuses = {
    [EnrollmentStatusEnum.IN_PROGRESS as string]: 'In Progress',
    [EnrollmentStatusEnum.PENDING as string]: 'Pending',
    [EnrollmentStatusEnum.COMPLETED as string]: 'Completed',
};

export function enrollmentStatusName(status?: string): string {
    return (status && enrollmentStatusNames[status]) || status || '';
}

export function getEnrollmentStatusName(status?: string): string {
    return (status && enrollmentStatuses[status]) || status || '';
}

/**
 * @description converts SearchParams to SearchTeamMembersParams
 * @function createSearchTeamMembersParams
 * @param { SearchParams } searchParams
 * @returns { SearchTeamMembersParams }
 */
export const createSearchTeamMembersParams = (searchParams: SearchParams) => {
    const overdueFilter = searchParams.filters?.find(f => f.field === 'hasOverdue');
    const params: SearchTeamMembersParams = {
        userStatus: searchParams.filterByRegistrationStatus?.join(','),
        enrollmentStatus: searchParams.filterByStatus?.join(','),
        isOverdue: overdueFilter?.value ? true : undefined,
        labels: searchParams.labelIDs?.join(','),
        limit: searchParams.limit ?? 20,
        offset: searchParams.offset ?? 0,
        query: searchParams.searchTerm,
        sort: getSearchSortingValue(searchParams.orderBy, 'name', true)
    };
    return params;    
}


export const createSearchPractisSetDetailsParams = (sp: SearchParams) => {
    const sortFields = {
        'firstName': 'user_name',
        'enrollment.createdAt': 'assigned',
        'enrollment.enrolledAt': 'started',
        'enrollment.dueDate': 'due_date',
        'enrollment.trainingTime': 'training_time',
        'enrollment.lastTraining': 'last_training',
        'enrollment.progressValue': 'progress',
        'enrollment.averageAccuracy': 'accuracy',
    }
    const statusValues = Object.values(userStatusFilters) as string[];
    const params: SearchTrainingPractisSetProgressParams = {
        dueDate: sp.filterByDueDate?.map((item) => dueDateFiltersValues[item as keyof typeof dueDateFiltersValues]).join(','),
        userStatus: sp.filterByRegistrationStatus?.join(','),
        enrollmentStatus: (sp.filterByStatus?.length ? sp.filterByStatus?.map((item) => userStatusFilters[item as keyof typeof userStatusFilters]): statusValues).join(','),
        labels: sp.labelIDs?.join(','),
        limit: sp.limit ?? 20,
        offset: sp.offset ?? 0,
        query: sp.searchTerm,
        sort: sp.orderBy
            ? `${sortFields[sp.orderBy.field as keyof typeof sortFields]}_${sp.orderBy.asc ? 'asc' : 'desc'}`
            : 'user_name_asc',
    };
    
    return params;
}

/**
 * @description converts SearchParams to SearchTeamMembersParams
 * @function createSearchTeamMembersParams
 * @param { SearchParams } searchParams
 * @returns { SearchTeamParams }
 */
 export const createSearchTeamsParams = (p: SearchParams) => {
    const params: SearchTeamParams = {
        labels: p.labelIDs?.join(','),
        teams: p.teamIds?.join(','),
        leaders: p.teamLeadIds?.join(','),
        limit: p.limit ?? 20,
        offset: p.offset ?? 0,
        query: p.searchTerm,
        sort: getSearchSortingValue(p.orderBy, 'default')
    };
    return params;    
}