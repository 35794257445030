import React, { FC, CSSProperties, ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { Variables } from '../../../theme/variables';

const radioButtonSize = 20;

const COLORS = {
    background: Variables.Colors.white,
    checkedBackground: Variables.Colors.white,
    checkedColor: Variables.Colors.white,
    borderColor: Variables.Colors.iconGrayBg,
    disabled: {
        background: Variables.Colors.steelGrey,
        checkedBackground: Variables.Colors.iconGrayBg,
        checkedColor: Variables.Colors.white,
    },
};

const StyledWrapper = styled.label<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    ${props => props.disabled && 'pointer-events: none'};
    margin-bottom: 8px;
    cursor: pointer;
`;

const StyledLabel = styled.div<{
    color?: string;
    size?: number;
    disabled?: boolean;
}>`
    font-size: ${props => (!!props.size ? props.size : 13)}px;
    margin-left: 8px;
    font-weight: 500;
    color: ${props => (
        props.color
            ? props.color
            : props.disabled
                ? Variables.Colors.steelGrey
                : Variables.Colors.black)};
    cursor: pointer;
`;

const HiddenRadioButton = styled.input`
    visibility: hidden;
    position: absolute;
`;

const StyledRadioButton = styled.div<{
    color?: string;
    background?: string;
    size: number;
    checked?: boolean;
    partial?: boolean;
    borderWidth?: number;
    disabled?: boolean;
}>`
    cursor: pointer;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border: ${props =>
            !!props.checked && !!props.borderWidth ? props.borderWidth : 1}px
        solid
        ${props =>
            props.checked
                ? props.disabled
                    ? Variables.Colors.cloudyBlue
                    : props.partial
                        ? Variables.Colors.cloudyBlue
                        : Variables.Colors.darkSkyBlue
                : Variables.Colors.cloudyBlue};
    background: ${props => props.background};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    border-radius: 50%;
`;

export const RadioButton: FC<{
    checked: boolean;
    partial?: boolean;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    label?: string;
    color?: string;
    background?: string;
    size?: number;
    fontColor?: string;
    fontSize?: number;
    className?: string;
    labelStyles?: CSSProperties;
    checkedBackground?: string;
    dataTest?: string;
}> = ({
    checked,
    partial,
    disabled,
    handleChange,
    label,
    background,
    size,
    fontColor,
    fontSize,
    className,
    labelStyles,
    checkedBackground,
    dataTest,
}) => {
    return (
        <StyledWrapper className={className} disabled={disabled}>
            <HiddenRadioButton
                type="checkbox"
                onChange={e => {
                    handleChange(e);
                }}
                data-test={
                    dataTest
                    && `${dataTest}${partial ? '-partially-checked' : (checked ? '-checked' : '')}`
                }
            />
            <StyledRadioButton
                size={size ? size : radioButtonSize}
                checked={checked}
                disabled={disabled}
                partial={partial}
                background={
                    checked
                        ? !!checkedBackground
                            ? checkedBackground
                            : COLORS.checkedBackground
                        : background || COLORS.background
                }
                borderWidth={size ? size / 3 : 4}
                data-test={dataTest && `${dataTest}-view`}
            />
            {label && (
                <StyledLabel
                    color={fontColor}
                    size={fontSize}
                    style={labelStyles}
                    data-test={dataTest && `${dataTest}-label`}
                    disabled={disabled}
                >
                    {label}
                </StyledLabel>
            )}
        </StyledWrapper>
    );
};

export default RadioButton;
