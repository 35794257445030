import ConfirmCheckMark from '../../../../../../ui/icons/ConfirmCheckMark';
import CancelCross from '../../../../../../ui/icons/CancelCross';
import { InputWithButtonsViewProps } from './types';
import {
    useStyles,
    StyledInput,
    InputWrapper,
    CancelButton,
    SaveButton
} from './styles';

function InputWithButtonsView(props: InputWithButtonsViewProps) {
    const {
        height,
        maxLength,
        placeholder,
        value,
        hasError,
        showButtons,
        isDisabled,
        isSaveDisabled,
        dataTest,
        onChange,
        onKeyDown,
        onFocus,
        onCancel,
        onSave
    } = props;
    const classes = useStyles();

    return <>
        <InputWrapper>
            <StyledInput
                height={height}
                maxLength={maxLength}
                placeholder={placeholder}
                value={value}
                error={hasError}
                disabled={isDisabled}
                dataTest={`${dataTest}-input`}
                handleChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
            />
        </InputWrapper>
        {
            showButtons && (
                <>
                    <CancelButton
                        onClick={isDisabled ? undefined : onCancel}
                        className={isDisabled ? classes.disabledButton : undefined}
                        data-test={dataTest && `${dataTest}-cancel`}
                    >
                        <CancelCross />
                    </CancelButton>
                    <SaveButton
                        onClick={isSaveDisabled ? undefined : onSave}
                        className={isSaveDisabled ? classes.disabledButton : undefined}
                        data-test={dataTest && `${dataTest}-save`}
                    >
                        <ConfirmCheckMark />
                    </SaveButton>
                </>
            )
        }
    </>;
}

export default InputWithButtonsView;