import React, { createContext, FC } from 'react';
import styled from 'styled-components';

const CommentInner = styled.div<{ reverse?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
`;

export const CommentContext = createContext<{
    reverse?: boolean;
    disabled?: boolean;
    background?: string;
}>({
    reverse: false,
    disabled: false,
});
const CommentProvider = CommentContext.Provider;

export const Comment: FC<{
    className?: string;
    reverse?: boolean;
    disabled?: boolean;
    background?: string;
}> = ({ children, className, reverse, disabled, background }) => {
    return (
        <CommentInner className={className} reverse={reverse}>
            <CommentProvider
                value={{
                    reverse: reverse,
                    disabled: disabled,
                    background: background,
                }}
            >
                {children}
            </CommentProvider>
        </CommentInner>
    );
};
