import { useState, useCallback } from 'react';
import { useForgotPasswordApi } from '../../../../../api';
import ROUTES from '../../../../../routes/routes';
import { useHistory } from '../../../../../tools/router';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import EnterEmailAddressView from './view';
import { EnterEmailAddressProps } from './types';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

const NOT_FOUND_ACCOUNT_ERROR = 'That account doesn\'t exist. Enter a different email address.';
const INVALID_EMAIL_ERROR = 'The format you used to enter this email isn\'t correct';

function EnterEmailAddressController ({ onSuccess }: EnterEmailAddressProps) {
    const history = useHistory();
    const forgotPassword = useForgotPasswordApi();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    /**
     * @function handleClearError - clears error message
     * @returns { void }
     */    
    const handleClearError = () => {
        setErrorMessage('');
    };

    /**
     * @function getErrorByCode - returns error message based on error code
     * @param { number? } code
     * @returns { string? }
     */    
    const getErrorByCode = (code?: number) => {
        switch(code) {
            case 400:
                return INVALID_EMAIL_ERROR;
            case 404:
                return NOT_FOUND_ACCOUNT_ERROR;
            default:
                return null;
        }        
    }

    /**
     * @function handleSend - sends email to forgot-password API and
     * shows error message if it's returned by API
     * @param { string } email
     * @returns { void }
     */    
    const handleSend = useCallback((email: string) => {
        setIsSending(true);
        forgotPassword(email)
        .then(
            (response: any) => {
                setIsSending(false);
                setErrorMessage('');
                onSuccess();
            }
        )
        .catch((error: ErrorResult) => {
            setIsSending(false);
            setErrorMessage(getErrorByCode(error.code) || error.message);
        });
    }, [forgotPassword, onSuccess]);

    /**
     * @function handleBack - redirects to login page
     * @returns { void }
     */    
    const handleBack = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    }, [history]);

    useHtmlPageTitle('Forgot Password');

    return <EnterEmailAddressView
        isSending={isSending}
        errorMessage={errorMessage}
        onClearError={handleClearError}
        onSend={handleSend}
        onBack={handleBack}
    />
}

export default EnterEmailAddressController;