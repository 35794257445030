import React, { ChangeEvent, ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';

export interface SwitchToggleInterface {
    index: number | string;
    checked?: boolean;
    label?: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    height?: number;
    width?: number;
    bulletRadius?: number;
    dataTest?: string;
}

const StyledSwitchContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledSwitchCheckboxContainer = styled.div`
    position: relative;
`;

const StyledSpan = styled.span<{
    checked?: boolean;
    disabled?: boolean;
    bulletRadius: number;
}>`
    content: '';
    position: absolute;
    left: 4px;
    width: ${props => props.bulletRadius}px;
    height: ${props => props.bulletRadius}px;
    border-radius: 45px;
    transition: 0.2s;
    background: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue15
            : props.checked
            ? props.theme.Colors.darkSkyBlue15
            : props.theme.Colors.cloudyBlue15};
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    border: solid 1px
        ${props =>
            props.disabled
                ? props.theme.Colors.paleGrey
                : props.checked
                ? props.theme.Colors.darkSkyBlue
                : props.theme.Colors.cloudyBlue};
`;

const StyledLabel = styled.label<{
    checked?: boolean;
    disabled?: boolean;
    width: number;
    height: number;
    bulletRadius: number;
}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: 100px;
    border: solid 1px
        ${props =>
            props.disabled
                ? props.theme.Colors.paleGrey
                : props.checked
                ? props.theme.Colors.darkSkyBlue
                : props.theme.Colors.cloudyBlue};
    background-color: ${props => props.theme.Colors.whiteFive};
    position: relative;
    transition: background-color 0.2s;

    &:active ${StyledSpan} {
        width: ${props =>
            props.disabled ? props.bulletRadius : props.bulletRadius + 10}px;
    }
`;

const StyledInput = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    top: 15px;
    left: 10px;

    &:checked + ${StyledLabel} ${StyledSpan} {
        left: calc(100% - 4px);
        transform: translateX(-100%);
    }
`;

const StyledLabelText = styled.div`
    margin-left: 12px;
    font-size: 13px;
`;

const SwitchToggle: FC<SwitchToggleInterface> = ({
    index,
    label,
    onChange,
    checked,
    disabled,
    height,
    width,
    bulletRadius,
    dataTest
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        onChange(e);
    };

    return (
        <StyledSwitchContainer>
            <StyledSwitchCheckboxContainer>
                <StyledInput
                    checked={checked}
                    id={`toggle-switch-${index}`}
                    type="checkbox"
                    onChange={handleChange}
                    data-test={dataTest}
                />
                <StyledLabel
                    htmlFor={`toggle-switch-${index}`}
                    checked={checked}
                    height={height ? height : 32}
                    width={width ? width : 56}
                    bulletRadius={bulletRadius ? bulletRadius : 20}
                    disabled={disabled}
                    data-test={dataTest && `${dataTest}-label`}
                >
                    <StyledSpan
                        checked={checked}
                        bulletRadius={bulletRadius ? bulletRadius : 20}
                        disabled={disabled}
                    />
                </StyledLabel>
            </StyledSwitchCheckboxContainer>
            <StyledLabelText>{label}</StyledLabelText>
        </StyledSwitchContainer>
    );
};

export default SwitchToggle;
