import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { CompanyStatus } from '../../../constants/interfaces/Company';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { SearchParams } from '../../../constants/interfaces/filters';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import ROUTES from '../../../routes/routes';
import Storage from '../../../services/Storage';
import { WEB_SITE_ID } from '../../../tools/constants/WebSiteId';
import { ThunkContext } from '../../../tools/thunk/ThunkContext';
import {
    adminChangeCompanyAction,
    fetchActiveCompaniesFailure,
    fetchActiveCompaniesStart,
    fetchActiveCompaniesSuccess,
    searchAllCompaniesFailureAction,
    searchAllCompaniesStartAction,
    searchAllCompaniesSuccessAction,
} from './actions';
import { updateUser } from '../../users/store/services';
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {useSearchCompaniesApi} from "../../../api";

export const changeCompanyOfPractisAdmin = (
    companyId?: number,
    successCallback?: () => void,
    openInNewTab?: boolean,
) => {
    return (dispatch: any, getState: any, tc: ThunkContext) => {
        dispatch(adminChangeCompanyAction());
        const { webSiteId, urlBuilder } = tc;
        const user = getProfileState(getState());
        adminChangeCompanyAction();
        return updateUser(
            { companyId: companyId || null } as any,
            user!.id!,
            true,
            'Company Changed!',
            () => {
                let url = null;
                if (companyId) {
                    const isCompanyPortal =
                        webSiteId === WEB_SITE_ID.COMPANY_PORTAL;
                    url = urlBuilder.buildCompanyUrl({
                        path: ROUTES.SUBMISSIONS,
                        query: {
                            t: isCompanyPortal
                                ? undefined
                                : Storage.get('token'),
                            id: Storage.get('userId'),
                            p: Storage.get('apiPlatform')
                        },
                        absolute: true,
                    });
                } else if (webSiteId !== WEB_SITE_ID.ADMIN_PORTAL) {
                    url = urlBuilder.buildAdminUrl({
                        query: {
                            t: Storage.get('token'),
                            id: Storage.get('userId'),
                            p: Storage.get('apiPlatform')
                        },
                        absolute: true,
                    });
                }
                if (url) {
                    if (openInNewTab) {
                        window.open(url, '_blank');
                    } else {
                        window.location.href = url;
                    }
                }

                successCallback?.();
            }
        )(dispatch);
    };
};

export const useSearchAllCompaniesService = () => {
    const dispatch = useDispatch();
    const searchCompaniesApi = useSearchCompaniesApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp?: SearchParams) => {
            dispatch(searchAllCompaniesStartAction());
            return searchCompaniesApi(sp)
                .then(data => {
                    dispatch(searchAllCompaniesSuccessAction(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchAllCompaniesFailureAction(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchCompaniesApi, showMessage]
    );
};

/**
 * @function useFetchActiveCompaniesService
 * @returns { CallableFunction }
 */
export const useFetchActiveCompaniesService = () => {
    const dispatch = useDispatch();
    const searchCompaniesApi = useSearchCompaniesApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @returns { Promise }
     */
    return useCallback(
        () => {
            dispatch(fetchActiveCompaniesStart());
            return searchCompaniesApi(undefined, [ CompanyStatus.ACTIVE ])
                .then(data => {
                    dispatch(fetchActiveCompaniesSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchActiveCompaniesFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchCompaniesApi, showMessage]
    );
};
