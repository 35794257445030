import { DEFAULT_CHALLENGE_TRY_LIMIT } from '../../../challenges/constants';
import { GenerateChallengeData } from './types';

// -------- PRACTIS_SET --------
export const ASSIGN_LABELS_TO_PRACTIS_SET_ACTION =
    'ASSIGN_LABELS_TO_PRACTIS_SET_ACTION';
export const DELETE_LABELS_FROM_PRACTIS_SET_ACTION =
    'DELETE_LABELS_FROM_PRACTIS_SET_ACTION';
export const GET_PRACTIS_SET_CONTENT_ACTION = 'GET_PRACTIS_SET_CONTENT_ACTION';
export const ASSIGN_CONTENT_TO_PRACTIS_SET_ACTION =
    'ASSIGN_CONTENT_TO_PRACTIS_SET_ACTION';
export const UPDATE_PRACTIS_SET_STATUS_ACTION =
    'UPDATE_PRACTIS_SET_STATUS_ACTION';
export const CREATE_PRACTIS_SET_ACTION = 'CREATE_PRACTIS_SET_ACTION';
export const UPDATE_PRACTIS_SET_ACTION = 'UPDATE_PRACTIS_SET_ACTION';

// -------- SCENARIO --------
export const CREATE_SCENARIO_ACTION = 'CREATE_SCENARIO_ACTION';
export const UPDATE_SCENARIO_ACTION = 'UPDATE_SCENARIO_ACTION';
export const ASSIGN_LABELS_TO_SCENARIO_ACTION =
    'ASSIGN_LABELS_TO_SCENARIO_ACTION';
export const DELETE_LABELS_FROM_SCENARIO_ACTION =
    'DELETE_LABELS_FROM_SCENARIO_ACTION';
export const ASSIGN_LINES_TO_SCENARIO_ACTION =
    'ASSIGN_LINES_TO_SCENARIO_ACTION';
export const UPDATE_SCENARIO_STATUS_ACTION = 'UPDATE_SCENARIO_STATUS_ACTION';
export const DELETE_LINES_FROM_SCENARIO_ACTION =
    'DELETE_LINES_FROM_SCENARIO_ACTION';

// -------- CHALLENGE --------
export const CREATE_CHALLENGE_ACTION = 'CREATE_CHALLENGE_ACTION';
export const UPDATE_CHALLENGE_ACTION = 'UPDATE_CHALLENGE_ACTION';
export const ASSIGN_LABELS_TO_CHALLENGE_ACTION =
    'ASSIGN_LABELS_TO_CHALLENGE_ACTION';
export const DELETE_LABELS_FROM_CHALLENGE_ACTION =
    'DELETE_LABELS_FROM_CHALLENGE_ACTION';
export const ASSIGN_LINES_TO_CHALLENGE_ACTION =
    'ASSIGN_LINES_TO_CHALLENGE_ACTION';
export const UPDATE_CHALLENGE_STATUS_ACTION = 'UPDATE_CHALLENGE_STATUS_ACTION';

export const initialGenerateChallengeData: GenerateChallengeData = {
    title: '',
    description: '',
    sourceScenarioId: null,
    lines: [],
    labelIds: [],
    tryLimit: DEFAULT_CHALLENGE_TRY_LIMIT,
};