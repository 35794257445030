import { EnvironmentConfigs } from './tools/config';
import { getDevApiUrl } from './helpers/functions/url-helpers';

export const APP_CONFIG: EnvironmentConfigs = {
    default: {
        APP_VERSION: '5.0.0',
        API_CLIENT: 'WEB',
        API_BASE_URL: 'NOT_CONFIGURED',
        API_V2_BASE_URL: 'NOT_CONFIGURED',
        CHATGPT_API_KEY: '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',
        ADMIN_PORTAL_BASE_URL: 'NOT_CONFIGURED',
        COMPANY_PORTAL_BASE_URL: 'NOT_CONFIGURED',
        COMMIT_ID: '',
        BUILD_ID: '',
        S3_FILES_BASE_URL:
            'https://practis-files-dev.s3-us-west-1.amazonaws.com/',
        NEW_RELIC_LICENSE_KEY: 'NRJS-7064dc987d35d780d7c',
        NEW_RELIC_APP_ID: 'NOT_CONFIGURED',
        API_SOCKET_ENDPOINT: 'NOT_CONFIGURED',
        googleClientId: '999094876822-i3029amhpv2pm9a4qj0fnvtuvn8d76np.apps.googleusercontent.com',
        ELEVEN_LABS_KEY: 'd757da3c3c9659a55b5aa5d66e0d9d6f',
    },
    local: {
        API_BASE_URL: `https://${getDevApiUrl('v1')}/api/`,
        API_V2_BASE_URL: `https://${getDevApiUrl('v2')}`,
        ADMIN_PORTAL_BASE_URL: 'http://localhost:4000/',
        COMPANY_PORTAL_BASE_URL: 'http://localhost:3000/',
        NEW_RELIC_APP_ID: '255699936',
        API_SOCKET_ENDPOINT: `wss://${getDevApiUrl('v2')}`,
        googleClientId: '999094876822-i3029amhpv2pm9a4qj0fnvtuvn8d76np.apps.googleusercontent.com'
    },
    dev: {
        API_BASE_URL: `https://${getDevApiUrl('v1')}/api/`,
        API_V2_BASE_URL: `https://${getDevApiUrl('v2')}`,
        ADMIN_PORTAL_BASE_URL: 'https://admin-dev.gopractis.com/',
        COMPANY_PORTAL_BASE_URL: 'https://portal-dev.gopractis.com/',
        NEW_RELIC_APP_ID: '255699936',
        API_SOCKET_ENDPOINT: `wss://${getDevApiUrl('v2')}`,
        googleClientId: '999094876822-i3029amhpv2pm9a4qj0fnvtuvn8d76np.apps.googleusercontent.com'
    },
    beta: {
        API_BASE_URL: 'https://api-beta.gopractis.com/api/',
        API_V2_BASE_URL: 'https://api-beta-v2.gopractis.com/',
        ADMIN_PORTAL_BASE_URL: 'https://admin-beta.gopractis.com/',
        COMPANY_PORTAL_BASE_URL: 'https://portal-beta.gopractis.com/',
        S3_FILES_BASE_URL:
            'https://practis-files-beta.s3-us-west-1.amazonaws.com/',
        NEW_RELIC_APP_ID: '255708755',
        API_SOCKET_ENDPOINT: 'wss://api-beta-v2.gopractis.com',
        googleClientId: '999094876822-o4ocaic126v332htbagjak5035057i0r.apps.googleusercontent.com'
    },
    prod: {
        API_BASE_URL: 'https://api.gopractis.com/api/',
        API_V2_BASE_URL: 'https://api-v2.gopractis.com/',
        ADMIN_PORTAL_BASE_URL: 'https://admin.gopractis.com/',
        COMPANY_PORTAL_BASE_URL: 'https://portal.gopractis.com/',
        S3_FILES_BASE_URL:
            'https://practis-files-prod.s3-us-west-1.amazonaws.com/',
        NEW_RELIC_APP_ID: '255709065',
        API_SOCKET_ENDPOINT: 'wss://api-v2.gopractis.com',
        googleClientId: '999094876822-vtnll7dbk74sblvdfmmuks9j10aot7jc.apps.googleusercontent.com'
    }
};
