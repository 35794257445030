import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import {
    AudioInterface,
    getAudioState,
} from '../../../../../../../features/player/store/reducers';
import { useSelector } from 'react-redux';
import { EditModeContext, PlayAudioContext } from '../../../../NewScenario';
import { useResetAudioService } from '../../../../../../../features/player/store/services';
import { ScriptLine } from '../../../../../../../constants/interfaces/ScriptLine';
import {
    Scenario,
    ScenarioStatuses,
} from '../../../../../../../constants/interfaces/Scenario';
import { useVoiceGenerationState } from '../../../../../../../features/textToSpeech/store/states';
import { getScenarioState } from '../../../../store/reducers';
import { EditModeValues } from '../../../../../../../constants/enums/EditModeValues';
import { useHistory } from '../../../../../../../tools/router';
import ROUTES from '../../../../../../../routes/routes';
import { Button } from '../../../../../../../ui/components/Button';
import Generate from '../../../../../../../ui/icons/Generate';
import Play from '../../../../../../../ui/icons/Play';
import Stop from '../../../../../../../ui/icons/Stop';
import Phone from '../../../../../../../ui/icons/Phone';
import { isProductionEnvironment } from '../../../../../../../helpers/functions/general';
import { useTextToSpeechBulkActionService } from '../../../../../../../features/textToSpeech/BulkActionService';

const StyledScriptHeaderActions = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const StyledButton = styled(Button)<{ width?: string }>`
    min-width: 100px;
    ${props => (!!props.width ? `width: ${props.width}` : '')};
`;

const ScriptHeaderActions: FC<{
    scenario: Scenario;
    player: AudioInterface;
    stopPlaylist: () => void;
    generating?: boolean;
    playAudio?: () => void;
    textToSpeech: () => void;
}> = ({
    player,
    stopPlaylist,
    scenario,
    generating,
    playAudio,
    textToSpeech
}) => {
    const edit = useContext(EditModeContext);
    const history = useHistory();

    const handlePreviewScenario = () => {
        if (scenario.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.TRAINING.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    };

    return (
        <>
            <StyledScriptHeaderActions>
                <StyledButton
                    label="Generate for All"
                    iconRender={<Generate />}
                    disabled={
                        (!!scenario.script &&
                            (scenario.script.lines.filter(
                                (line: ScriptLine) => line.text
                            ).length === 0 ||
                                player.status !== 'off' ||
                                !!player.recording ||
                                generating)) ||
                        edit.mode === EditModeValues.VIEW
                    }
                    width={'136px'}
                    height="40px"
                    action={textToSpeech}
                    dataTest='scenario-generate-for-all'
                />
                {player.status !== 'playlist' ? (
                    <StyledButton
                        label="Play All"
                        iconRender={<Play />}
                        disabled={
                            !!scenario.script &&
                            (scenario.script.lines.filter(
                                (line: ScriptLine) => line.audioUrl
                            ).length === 0 ||
                                !!player.recording ||
                                generating)
                        }
                        action={playAudio}
                        height="40px"
                        dataTest='scenario-play-all'
                    />
                ) : (
                    <StyledButton
                        label="Stop"
                        iconRender={<Stop />}
                        color="warning"
                        action={stopPlaylist}
                        height="40px"
                        dataTest='scenario-stop'
                    />
                )}
                {!isProductionEnvironment() && (
                    <StyledButton
                        label="Preview"
                        iconRender={<Phone />}
                        disabled={scenario.status !== ScenarioStatuses.ACTIVE}
                        height="40px"
                        action={handlePreviewScenario}
                        dataTest='scenario-preview'
                    />
                )}
            </StyledScriptHeaderActions>
        </>
    );
};

export const ScriptHeaderActionsContainer: FC<{
}> = () => {
    const player = useSelector(getAudioState);
    const scenario = useSelector(getScenarioState).info;
    const resetPlaylist = useResetAudioService();
    const voice = useVoiceGenerationState().scenarios;
    const generating = voice && voice.generating;
    const playAudio = useContext(PlayAudioContext);
    const textToSpeechBulkAction = useTextToSpeechBulkActionService(
        scenario.script.lines,
        'scenarios'
    );

    return (
        <ScriptHeaderActions
            player={player}
            stopPlaylist={resetPlaylist}
            scenario={scenario}
            generating={generating}
            playAudio={playAudio}
            textToSpeech={textToSpeechBulkAction}
        />
    );
};

export default ScriptHeaderActionsContainer;
