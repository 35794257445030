import { FC, useContext, useEffect, useRef } from 'react';

import { Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { PRACTIS_SET_SCENARIO_LIST_DROP_ID } from '../../Content';
import { ScrollPagination } from '../../../../../../../../ui/components/ScrollPagination';
import styled from 'styled-components';
import { Variables } from '../../../../../../../../theme/variables';
import { GenerateTableCells } from './GenerateTableCells';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../../../../constants/interfaces/filters';
import { PractisSetContent } from '../../../../../../../../constants/interfaces/PractisSets';
import { Scenario } from '../../../../../../../../constants/interfaces/Scenario';
import PractisContentNavigationContainer from '../../../../PractisContentNavigation/PractisContentNavigation';
import { EditModeContext } from '../../../../../PractisSets';
import StackIcon from '../../../../../../../../ui/icons/Stack';
import TableFilters from '../components/Filters/TableFilters';
import { UserProfile } from '../../../../../../../../constants/interfaces/User';
import { isTeamLeader } from '../../../../../../../../constants/enums';
import { TableRow } from '../../../../../../../../ui/components/table-wrapper/table/table-row';
import { TableContainer } from '../../../../../../../../ui/components/table-wrapper/styles';
import { getPractisSetState } from '../../../../../store/reducers';
import { useOrderBy } from '../../../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { TableNoResult } from '../../../../../../../../ui/components/table-wrapper/table/TableNoResult';
import { TableHead } from '../../../../../../../../ui/components/Table';
import { TableHeaderCell } from '../../../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell';

const ITEMS_PER_PAGE = 20;

const StyledScenarioList = styled.div`
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const StyledTable = styled.table`
    border: none;
    overflow: hidden;
    width: 100%;
    border-spacing: 0;
`;

const StyledTableHeaderRow = styled(TableRow)`
    border-radius: 2px;
    & > th {
        font-size: 13px;
        padding: 24px 14px 15px 14px;
    }
`;

const StyledBodyWrapper = styled.div`
    border-radius: 0px 4px 4px 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const StyledBody = styled.div`
    background: ${props => props.theme.Colors.white};
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 0 24px;
    flex: 1;
    overflow: auto;
`;

const StyledTableContainer = styled(TableContainer)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const StyledBodyContainer = styled.div`
    overflow: auto;
    flex: 1;
    height: 100%;
    padding-bottom: 16px;
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.paleGrey};
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
`;

const StyledTablePagination = styled(ScrollPagination)`
    width: 100%;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
    padding: 16px !important;
`;

const TableBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100px;
    overflow: auto;
`;

export const ScenarioList: FC<{
    profile?: UserProfile;
    scenarios: Array<Scenario>;
    practisSetContent: PractisSetContent[];
    initialSearchParams: SearchParams;
    onChangeSearchParams(searchParams: SearchParams): void;
    page: string;
    setPage: (value: string) => void;
    addToBuilder?: (scenarioId?: number | string) => void;
    isLoading: boolean;
}> = ({
    profile,
    onChangeSearchParams,
    scenarios,
    practisSetContent,
    initialSearchParams,
    page,
    setPage,
    addToBuilder,
    isLoading,
}) => {
    const isViewMode = isTeamLeader(profile?.role?.name);
    const totalScenarios = useSelector(getPractisSetState).totalScenarios;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLimit,
        setLabelIDs,
    } = useSearchParamsState(initialSearchParams);

    const edit = useContext(EditModeContext);
    const scrollableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (searchParams !== initialSearchParams) {
            onChangeSearchParams(searchParams as SearchParams);
        }
    }, [searchParams, initialSearchParams, onChangeSearchParams]);

    const orderBy = searchParams.orderBy;
    const sortName = useOrderBy('title', orderBy, setOrderBy);
    const sortDuration = useOrderBy(
        'total_duration',
        orderBy,
        setOrderBy
    );

    return (
        <StyledScenarioList>
            <PractisContentNavigationContainer
                page={page}
                setPage={setPage}
                scenarioCountFiltered={totalScenarios}
            />
            <StyledBodyWrapper>
                <TableFilters
                    setSearchTerm={setSearchTerm}
                    setLabelIDs={setLabelIDs}
                    isViewMode={isViewMode}
                    dataTest="practis-set-scenarios"
                />
                <StyledBody>
                    <StyledTableContainer>
                        <StyledTable>
                            <TableHead>
                                <StyledTableHeaderRow>
                                    <StyledTableHeaderCell
                                        width={60}
                                        {...sortName}
                                        dataTest="practis-set-scenarios-column"
                                    >
                                        Scenarios
                                    </StyledTableHeaderCell>
                                    <StyledTableHeaderCell
                                        width={40}
                                        {...sortDuration}
                                        dataTest="practis-set-scenarios-duration-column"
                                    >
                                        Duration
                                    </StyledTableHeaderCell>
                                </StyledTableHeaderRow>
                            </TableHead>
                        </StyledTable>
                        {!isLoading && scenarios.length < 1 ? (
                            <TableNoResult icon={StackIcon}>
                                {searchParams.searchTerm.length > 0
                                    ? 'No Scenarios were found'
                                    : 'No Scenarios'}
                            </TableNoResult>
                        ) : (
                            <TableBody ref={scrollableRef}>
                                <StyledTablePagination
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    itemCount={scenarios ? scenarios.length : 0}
                                    totalCount={totalScenarios}
                                    onLimitChange={setLimit}
                                    scrollableRef={scrollableRef}
                                    hideLoading={
                                        scenarios.length < ITEMS_PER_PAGE
                                    }
                                >
                                    <Droppable
                                        droppableId={
                                            PRACTIS_SET_SCENARIO_LIST_DROP_ID
                                        }
                                    >
                                        {(provided: any) => {
                                            return (
                                                <StyledBodyContainer
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        <GenerateTableCells
                                                            content={scenarios}
                                                            mode={edit.mode}
                                                            practisSetContent={
                                                                practisSetContent
                                                            }
                                                            background={
                                                                Variables.Colors
                                                                    .white
                                                            }
                                                            addToBuilder={
                                                                addToBuilder
                                                            }
                                                            dataTest="practis-set-scenario-item"
                                                        />
                                                    }
                                                    {provided.placeholder}
                                                </StyledBodyContainer>
                                            );
                                        }}
                                    </Droppable>
                                </StyledTablePagination>
                            </TableBody>
                        )}
                    </StyledTableContainer>
                </StyledBody>
            </StyledBodyWrapper>
        </StyledScenarioList>
    );
};
