export const SalesMethologies = [{
    name: "None (default)",
    value: 'null'
},{
    name: "Focuses on teaching, tailoring, and taking control. Challenge the customer's thinking by offering unique insights and pushing them out of their comfort zone. Best for complex sales environments where customers are well-informed.",
    value: 'The Challenger Sale',
}, {
    name: "Emphasizes building a relationship as a trusted advisor. Uses a non-traditional approach by avoiding hard selling and instead encourages the customer to discover their own needs through guided questioning. Suitable for creating long-term customer relationships.",
    value: 'Sandler Sales Training'
}, {
    name: "Centers around four types of questions — Situation, Problem, Implication, Need-payoff. Aims to lead the customer through a discovery process, identifying and understanding their problems and the impact of solving these. Effective for high-value, consultative sales.",
    value: 'SPIN Selling'
}, {
    name: "Focuses on understanding and managing complex sales processes. This methodology involves identifying key decision-makers, understanding customer organization, and strategically aligning sales efforts. Ideal for B2B sales with multiple stakeholders.",
    value: 'Miller-Heiman'
}, {
    name: "Based on the principle of diagnosing the customer's needs and then offering the best solution. Involves active listening, problem identification, and addressing specific pain points with tailored solutions. Suitable for selling products or services that require customization to client needs.",
    value: 'Solution Selling'
}]