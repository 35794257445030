import { FC, MouseEvent } from 'react';
import styled from 'styled-components';

const StyledButton = styled.div<{
    color?: string;
    disabled?: boolean;
}>`
    display: flex;
    cursor: pointer;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 4px;
    font-family: Manrope !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.Colors.darkSkyBlue};
    color: ${props => props.theme.Colors.white};
    &:hover {
        background: ${props => props.theme.Colors.cornflowerBlue};
    }
    &:active {
        background: ${props => props.theme.Colors.coolBlue};
    }
    &:disabled {
        background: transparent;
        color: ${props => props.theme.Colors.blueyGray};
        box-shadow: inset 0px 0px 0px 1px
            ${props => props.theme.Colors.blueyGray};
    }
`;

export const SmallButton: FC<{
    action?: (e: MouseEvent<HTMLDivElement>) => void;
    disabled?: boolean;
    loading?: boolean;
    icon?: string;
    className?: string;
}> = ({ children, action, disabled, className }) => {
    return (
        <StyledButton
            disabled={disabled}
            onClick={action}
            className={className}
        >
            {children}
        </StyledButton>
    );
};

export default SmallButton;
