import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import Checkbox from '../../../ui/components/Checkbox';
import { handleToggleSelectedItems } from '../../../pages/ActionPages/NewUser/tools';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';

const StyledTeamsList = styled.div`
    overflow: auto;
    height: 100%;
`;

const TopSideList = styled.span``;

const GeneralList = styled.span``;

const TeamsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;


const TeamsItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    cursor: ${props => props.disabled ? `default` : `pointer`};
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;
export const PortableScenariosList: FC<{
    teams?: Scenario[];
    selectedTeams: number[];
    disabled?: boolean;
    showDefaultMembers?: boolean
    setTeamFilters?: (teamIds: number[]) => void;
}> = ({ teams, selectedTeams, showDefaultMembers, setTeamFilters  }) => {
    const isChecked = useCallback(
        (setId?: number) => {
            return !!selectedTeams && !!setId && selectedTeams.includes(setId);
        },
        [selectedTeams]
    );



    const isPartiallyChecked = useCallback(
        (teamId?: number) => {
            return (
                !!selectedTeams &&
                !!teamId &&
                selectedTeams.includes(-Number(teamId))
            );
        },
        [selectedTeams]
    );

    const changeHandler = (teamId?: number) => {
        if (teamId && setTeamFilters) {        
            setTeamFilters(handleToggleSelectedItems(selectedTeams, teamId));
          
        }
    };

    if (!teams || (teams && teams.length === 0)) return null;
 
    const topListScenarios = teams.filter(
        team => (team.id && selectedTeams.map(id => Math.abs(id)).includes(team.id))
    );

    const bottomListScenarios = teams.filter(
        team =>  team.id && !selectedTeams.map(id => Math.abs(id)).includes(team.id)
    );

    const processedTopListTeams = topListScenarios;

    
    return (
        <StyledTeamsList>
            {topListScenarios.length > 0 && (
                <TopSideList>
                    {processedTopListTeams.map(team => {
                        return (
                            <TeamsItemWrapper key={team.id}>
                                <Checkbox
                                    size={12}
                                    checked={
                                        isChecked(team.id) ||
                                        isPartiallyChecked(team.id)
                                    }
                                    partial={isPartiallyChecked(team.id)}
                                    handleChange={() =>
                                        changeHandler(team.id)
                                    }
                                />
                                <TeamsItem
                                    onClick={() => changeHandler(team.id)}
                                >
                                    {team.title}
                                </TeamsItem>
                            </TeamsItemWrapper>
                        );
                    })}
                    {topListScenarios.length < teams.length && <TableDivider width="98%" />}
                </TopSideList>
            )}
            <GeneralList>
                {bottomListScenarios.map(team => {
                    return (
                        <TeamsItemWrapper key={team.id}>
                            <Checkbox
                                size={12}
                                checked={
                                    isChecked(team.id) ||
                                    isPartiallyChecked(team.id)
                                }
                                partial={isPartiallyChecked(team.id)}
                                handleChange={() => changeHandler(team.id)}
                            />
                            <TeamsItem onClick={() => changeHandler(team.id)}>
                                {team.title}
                            </TeamsItem>
                        </TeamsItemWrapper>
                    );
                })}
            </GeneralList>
        </StyledTeamsList>
    );
};