import SidebarRoleplaysLarge from "../../../../ui/icons/SidebarRoleplaysLarge";
import { NoEntryContainer } from "../../RoleplayPage/styles";
import { Text } from "../../RoleplaysList/styles";

function ContentLoading() {
    return <NoEntryContainer data-test="roleplay-loading-container">
        <SidebarRoleplaysLarge dataTest="roleplay-loading-icon" />
        <Text data-test="roleplay-loading-text">Loading Roleplays...</Text>
    </NoEntryContainer>
}

export default ContentLoading;