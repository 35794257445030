import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ChartBoxHeader: FC<{ className?: string }> = ({
    children,
    className,
}) => {
    return <Container className={className}>{children}</Container>;
};

export default ChartBoxHeader;
