import React, {
    ChangeEventHandler,
    MouseEventHandler,
    CSSProperties,
    FocusEventHandler,
    KeyboardEvent,
} from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { Variables } from '../../../theme/variables';

export interface TextareaProps {
    label?: string;
    value?: string;
    defaultValue?: string;
    name?: string;
    field?: any;
    autoComplete?: string;
    disabled?: boolean;
    disableUnderline?: boolean;
    placeholder?: string;
    max?: number;
    styles?: CSSProperties;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    autoFocus?: boolean;
    className?: string;
    readOnly?: boolean;
    onMouseUp?: MouseEventHandler<HTMLTextAreaElement>;
    minRows?: number;
    maxRows?: number;
    fontSize?: string;
    dataTest?: string;
}

const StyledInput = styled(TextareaAutosize)<{
    color?: string;
    fontSize?: string;
}>`
    width: 100%;
    resize: none;
    border: none;
    background: transparent;
    color: ${props => props.theme.Colors.black};
    &::placeholder {
        color: ${props => props.theme.Colors.steelGrey};
        font-size: ${props => (!!props.fontSize ? props.fontSize : '13px')};
    }
    :focus {
        outline: none;
    }
    :disabled {
        background: ${Variables.Colors.backgroundGray};
    }
`;

export const Textarea = ({
    field,
    styles,
    disabled,
    autoFocus,
    autoComplete,
    label,
    value,
    defaultValue,
    placeholder,
    max,
    onFocus,
    onBlur,
    onChange,
    className,
    readOnly,
    onMouseUp,
    minRows,
    maxRows,
    fontSize,
    onKeyDown,
    dataTest
}: TextareaProps) => {
    return (
        <StyledInput
            minRows={minRows ?? 1}
            maxRows={maxRows ?? 150}
            style={styles}
            {...field}
            disabled={disabled}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            label={label}
            defaultValue={defaultValue ? defaultValue : undefined}
            value={value || value === '' ? value : undefined}
            placeholder={placeholder}
            onChange={onChange}
            className={[className, readOnly ? '-view-only' : ''].join(' ')}
            margin="normal"
            maxLength={max}
            onFocus={onFocus}
            onBlur={onBlur}
            readOnly={readOnly}
            onMouseUp={onMouseUp}
            fontSize={fontSize}
            data-test={dataTest}
            onKeyDown={onKeyDown}
        />
    );
};

export default Textarea;
