import React, { Children } from 'react';
import styled from 'styled-components';
import OutsideActionBox from '../OutsideActionBox/OutsideActionBox';

const Menu = styled(OutsideActionBox)`
    position: absolute;
`;

const Items = styled.div<{
    placement: 'top' | 'bottom';
    itemCount: number;
    extendHeight: number;
    marginTop?: number;
    customWidth?: number;
}>`
    width: ${props => (props.customWidth ? props.customWidth : 128)}px;
    position: fixed;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 8px;
    border-radius: 8px;
    background: ${props => props.theme.Colors.white};
    right: 20px;
    z-index: 100;
    margin-top: 40px;
`;

export const ActionMenu: React.FC<{
    open: boolean;
    extendHeight: number;
    placement?: 'top' | 'bottom';
    marginTop?: number;
    customWidth?: number;
    toggleOpen(open?: boolean): void;
    className?: string;
    itemsClassName?: string;
}> = ({
    open,
    placement,
    children,
    marginTop,
    toggleOpen,
    extendHeight,
    customWidth,
    className,
    itemsClassName,
}) => {
    const itemCount = Children.count(children);
    return (
        <Menu
            open={open}
            toggleOpen={toggleOpen}
            styles={{ position: 'relative' }}
            className={className}
        >
            <Items
                placement={placement || 'top'}
                itemCount={itemCount}
                extendHeight={extendHeight}
                marginTop={marginTop}
                customWidth={customWidth}
                className={itemsClassName}
            >
                {children}
            </Items>
        </Menu>
    );
};
