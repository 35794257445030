import { Reducer } from 'redux';
import { cloneDeep } from 'lodash';

import { Config } from './types';
import { Actions } from './actions';
import { decapsulateAction } from '../../../../../store/helpers/decapsulateAction';
import { AppState } from '../../../../../store/reducers';
import { DEFAULT_LABELS_SCOPE, Scope, WithLabelsScopes } from './scopes';

//region Reducer state
export type StateWithLabels = {
    _labels: Record<Scope, LabelsState>;
};

export type LabelsState = {
    collapsed: number[];
    selected: number[];
    saved: number[];
    error: string | null;
};

const initialState: LabelsState = {
    collapsed: [],
    selected: [],
    saved: [],
    error: null,
};

export const withLabelsInitialState = <T>(
    baseInitialState: T,
    reducerName: keyof AppState
): T & StateWithLabels => {
    const scopes =
        WithLabelsScopes[reducerName] === undefined
            ? [DEFAULT_LABELS_SCOPE]
            : [
                  ...(WithLabelsScopes[reducerName] as string[]),
                  DEFAULT_LABELS_SCOPE,
              ];

    return {
        ...baseInitialState,
        _labels: scopes.reduce((acc: Record<string, LabelsState>, scope) => {
            acc[scope] = cloneDeep(initialState);
            return acc;
        }, {}),
    };
};
//endregion

//region HOR
export const withLabelsReducer = <S extends StateWithLabels>(
    config: Config
) => (baseReducer: Reducer<S>) => (state: S, nativeAction: Actions): S => {
    let newState = state;
    const { reducerName } = config;
    const action = decapsulateAction(`${reducerName}/labels/`, nativeAction);
    const scope = action._scope;

    if (!scope) {
        return baseReducer(newState, action);
    }

    switch (action.type) {
        case 'clearLabels':
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        selected: [],
                        partial: [],
                    },
                },
            };
            break;
        case 'saveLabels':
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        saved: [...state._labels[scope].selected],
                    },
                },
            };
            break;
        case 'selectLabels':
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        selected: action.ids,
                    },
                },
            };
            break;
        case 'toggleCollapseLabel':
            let newCollapseLabels = [...state._labels[scope].collapsed];

            if (newCollapseLabels.includes(action.id)) {
                newCollapseLabels = newCollapseLabels.filter(
                    id => id !== action.id
                );
            } else {
                newCollapseLabels.push(action.id);
            }

            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        collapsed: newCollapseLabels,
                    },
                },
            };
            break;
        case 'toggleCollapseAllLabels':
            let collapsedLabelIDs = action.ids;

            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        collapsed: collapsedLabelIDs,
                    },
                },
            };
            break;
        case 'resetCollapseAllLabels':
             
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        collapsed: [],
                    },
                },
            };
            break;
        case 'selectAllLabels':
            const newSelectedList = action.allLabels;
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        selected: newSelectedList,
                    },
                },
            };
            break;
        case 'deselectAllLabels':
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        selected: [],
                    },
                },
            };
            break;
        case 'resetSelectedLabels': {
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        selected: state._labels[scope].saved,
                    },
                },
            };
            break;
        }
        case 'resetLabels':
            newState = {
                ...newState,
                _labels: {
                    ...state._labels,
                    [scope]: {
                        ...state._labels[scope],
                        selected: [],
                        saved: [],
                        collapsed: [],
                    },
                },
            };
            break;
    }

    return baseReducer(newState, action);
};
//endregion
