import { Reducer } from 'redux';
import {
    ACTIONS,
    voiceGenerationClear,
    voiceGenerationFailure,
    voiceGenerationStart,
    voiceGenerationSuccess,
} from './actions';
import { VoiceGenerationState } from './states';
import { appendItem, modifyArrayItem, removeItem } from '../../../tools/ramda';

const initialState: VoiceGenerationState = {};

export type Action =
    | ReturnType<typeof voiceGenerationStart>
    | ReturnType<typeof voiceGenerationSuccess>
    | ReturnType<typeof voiceGenerationClear>
    | ReturnType<typeof voiceGenerationFailure>;

export const voiceGenerationReducer: Reducer<VoiceGenerationState, Action> = (
    state = initialState,
    action: Action
): VoiceGenerationState => {
    switch (action.type) {
        case ACTIONS.VOICE_GENERATION_START: {
            const newData = appendItem(
                state[action.feature] ? state[action.feature].data : [],
                { id: action.itemId }
            );
            return {
                ...state,
                [action.feature]: {
                    ...state[action.feature],
                    generating: true,
                    data: newData,
                },
            };
        }
        case ACTIONS.VOICE_GENERATION_SUCCESS: {
            return {
                ...state,
                [action.feature]: {
                    ...state[action.feature],
                    data: modifyArrayItem(
                        state[action.feature].data,
                        action.itemId,
                        'voice',
                        action.voice
                    ),
                },
            };
        }
        case ACTIONS.VOICE_GENERATION_FAILURE: {
            const newData = removeItem(state[action.feature].data, {
                id: action.itemId,
            });
            return {
                ...state,
                [action.feature]: {
                    ...state[action.feature],
                    generating: newData.length > 0,
                    error: action.error,
                    data: newData,
                },
            };
        }
        case ACTIONS.VOICE_GENERATION_CLEAR:
            const newData = removeItem(state[action.feature].data, {
                id: action.itemId,
            });
            return {
                ...state,
                [action.feature]: {
                    ...state[action.feature],
                    generating: newData.length > 0,
                    data: newData,
                },
            };
        default:
            return state;
    }
};
