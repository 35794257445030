export const getPageUrl = (
    route: string,
    params?: Record<string, string | undefined>
) => {
    if (!params) {
        return route;
    }

    return Object.keys(params).reduce((acc, param) => {
        const value = params[param];
        if (value) {
            return acc.replace(`:${param}`, value);
        }

        return acc;
    }, route);
};
