import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const TriangleRight = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 5 8"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M4.8 4.067l-4 3c-.22.165-.534.12-.7-.1-.065-.087-.1-.192-.1-.3v-6c0-.276.224-.5.5-.5.108 0 .213.035.3.1l4 3c.22.165.266.479.1.7-.028.038-.062.071-.1.1z"
                />
            </svg>
        );
    }
);

export default TriangleRight;
