import dayjs from 'dayjs';
import { ordinalSuffix } from './number-sufix';
const momentTZ = require('moment-timezone');

export function formatTime(date: any, withSeconds?: boolean) {
    if (!date) return '';

    const d = new Date(date);
    var hours = d.getHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();
    var ampm = 'am';
    if (hours > 12) {
        hours -= 12;
        ampm = 'pm';
    }
    const secondsStr = withSeconds ? `:${leadZero(seconds)}` : '';
    return `${hours}:${leadZero(minutes)}${secondsStr} ${ampm}`;
}

function leadZero(value: number) {
    return value < 10 ? `0${value}` : `${value}`;
}

const quoteRegEx = /"/gi;

export function stringifyDate(date: Date | null | undefined) {
    return date ? JSON.stringify(date).replace(quoteRegEx, '') : undefined;
}

export const formatAMPM = (date: Date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formatedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + formatedMinutes + ' ' + ampm;
};

export function formatMMDDYY(date: Date) {
    if (!date) return '';
    const d = dayjs(date).toDate();
    const day = d.getDate();
    const month = d.getMonth() + 1;
    let year = d.getFullYear().toString();

    return `${('0' + month).slice(-2)}/${('0' + day).slice(
        -2
    )}/${year.substring(2)}`;
}

export const formatHour = (
    date?: number | string | Date
) => {
    if (!date) return '';

    const intDate = new Date(date);
    return formatAMPM(intDate);
    
};

/**
 * @function formatDate
 * @param { number | string | Date } date 
 * @param { boolean } showToday - defines if today should be shown
 * @param { boolean } shouldShowTime - defines if the time should be shown
 * @returns { string }
 */
export const formatDate = (
    date?: number | string | Date,
    showToday?: boolean,
    shouldShowTime: boolean = true,
): string => {
    if (!date) return '';

    const intDate = dayjs(date).toDate();
    if (isToday(date) && shouldShowTime) {
        return `${showToday ? 'Today at ' : ''} ${formatAMPM(
            new Date(intDate)
        )}`;
    } else {
        return formatMMDDYY(intDate);
    }
};

/**
 * @function formatDateWithTimeZone
 * @param { string | Date } date 
 * @returns { string }
 */
export const formatDateWithTimeZone = (date: string | Date): string => {
    const stringifiedDate = typeof date === 'string' ? date : date.toString();
    const splittedDate = new Date(stringifiedDate);

    return dayjs(splittedDate.toDateString()).format('MM/DD/YY');
};

/**
 * @function getDateWithTZOffset
 * @param { string | null } date 
 * @returns { string }
 */
export const getDateWithTZOffset = (date: string | null): string | null => {
    if (!date) return null;
    const timezone = momentTZ.tz.guess();
    const dayWithTimezone = momentTZ.tz(date, timezone).startOf("day").format();
    return dayWithTimezone
}
export const formatDateChart = (date?: number | string | Date) => {
    if (!date) return '';

    const intDate = new Date(date);
    if (isToday(date)) {
        return `Today`;
    } else {
        return formatMMDDYY(intDate);
    }
};

export const isToday = (date: any) => {
    if (!date) return false;
    date = new Date(date);
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

const getShortMonth = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = { month: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const formatMonDDYear = (date: any) => {
    if (!date) return '';
    date = new Date(date);

    return `${getShortMonth(date)} ${ordinalSuffix(
        date.getDate()
    )}, ${date.getFullYear()}
   `;
};

export const countDaysBetweenDates = (first: any, second: any) => {
    const diff = Math.abs(
        new Date(second).getTime() - new Date(first).getTime()
    );

    const dayDiff = Math.ceil(diff / (1000 * 3600 * 24));
    return dayDiff < 1 ? 1 : dayDiff;
};

export const getCopyrightYear = () => {
    return new Date().getFullYear();
};
