import styled from 'styled-components';

export const ActionMenuHandler = styled.div<{
    open: boolean;
    hasCustomComponent: boolean;
}>`
    width: 10px;
    ${({ hasCustomComponent }) => !hasCustomComponent && `height: 24px;`}
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: ${props => props.theme.Colors.steelGrey};
    box-shadow: ${props =>
        props.open && !props.hasCustomComponent
            ? `0 0 0 1px ${props.theme.Colors.cloudyBlue}`
            : 'none'};
    background-color: ${props =>
        props.open && !props.hasCustomComponent
            ? props.theme.Colors.whiteTwo
            : 'unset'};
`;

export const IconContainer = styled.div`
    height: 100%;
    width: 12px;
    cursor: pointer;
    margin: 0 5px;
    display: flex;
    align-items: center;
    &:active {
        opacity: 0.7;
    }
`;

export const menuPaperStyle = {
    width: 168,
    boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1)',
    padding: '8px',
    borderRadius: 8,
    background: '#fff',
    right: 20,
    marginTop: 40,
};
