import React, { FC } from 'react';
import ROUTES from '../../../routes/routes';
import Navigation from '../../../ui/components/Navigation/Navigation';
import styled from 'styled-components';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';

const StyledUsersPageNavigationContainer = styled.div`
    max-width: 400px;

    a.is-active:before{
        max-width: 148px;
    }
`;

const UsersPageNavigation: React.FC<{}> = () => {
    return (
        <StyledUsersPageNavigationContainer>
            <Navigation
                fontSize="13px"
                menu={[
                    {
                        key: 'users',
                        title: 'Registered',
                        url: ROUTES.USERS,
                        permissions: [NEW_PERMISSIONS.LIST_USER],
                    },
                    {
                        key: 'invitations',
                        title: 'Pending',
                        url: ROUTES.INVITATIONS,
                        permissions: [NEW_PERMISSIONS.LIST_INVITATION],
                    },
                    {
                        key: 'drafts',
                        title: 'Drafts',
                        url: ROUTES.DRAFTS,
                        permissions: [NEW_PERMISSIONS.LIST_STAGING],
                    },
                ]}
                dataTest="users-tab"
            />
        </StyledUsersPageNavigationContainer>
    );
};

export const UsersPageNavigationContainer: FC = () => {
    return <UsersPageNavigation />;
};

export default UsersPageNavigationContainer;
