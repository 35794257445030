import { FC, useState } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../theme/variables';
import { useLocalStorage } from '../../../tools/localStorage/LocalStorage';
import { Button } from '../../../ui/components/Button';
import Checkbox from '../../../ui/components/Checkbox';
import DialogWrapper from '../../../ui/components/DialogWrapper/DialogWrapper';

const StyledTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    color: var(--ps-black-main);
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledDescription = styled.div<{ marginTop?: string }>`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
    ${props => !!props.marginTop && `margin-top: ${props.marginTop};`}
    align-self: flex-start;
`;

const StyledActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 37px;
`;

const StyledButtonContainer = styled.div`
    margin-left: 16px;
`;

const StyledCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 11px;
    margin-bottom: 5px;
    align-self: flex-start;
`;

const StyledReminderTitle = styled.div`
    font-size: 13px;
    font-weight: 500;
    margin-left: 11px;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const StatusModalConfirmation: FC<{
    onCancel(): void;
    onConfirm(): void;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    displayDontShow?: boolean;
    dontShowHandle?: string;
    danger?: boolean;
}> = ({
    onCancel,
    onConfirm,
    title,
    confirmButtonText,
    cancelButtonText,
    children,
    displayDontShow,
    dontShowHandle,
    danger,
}) => {
    const [notShow, setNotShow] = useState(false);
    const localStorage = useLocalStorage();

    const handleConfirm = () => {
        if (notShow) {
            localStorage.set(
                `${dontShowHandle ? dontShowHandle : 'default'}_dont_show`,
                'true'
            );
        }
        onConfirm();
    };

    if (
        localStorage.get(
            `${dontShowHandle ? dontShowHandle : 'default'}_dont_show`
        )
    ) {
        onConfirm();
        return null;
    }

    return (
        <DialogWrapper
            customization={{ width: 400, padding: '22px 32px' }}
        >
            <StyledTitle data-test="confirmation-modal-title">
                {title || 'Are You Sure?'}
            </StyledTitle>
            <StyledContent>
                <StyledDescription
                    marginTop="15px"
                    data-test="confirmation-modal-description"
                >
                    {children}
                </StyledDescription>
                {displayDontShow && (
                    <StyledCheckboxContainer>
                        <Checkbox
                            size={16}
                            checked={notShow}
                            border={Variables.Colors.coral}
                            checkedBackground={Variables.Colors.coral}
                            handleChange={() => setNotShow(!notShow)}
                            dataTest="confirmation-modal-checkbox"
                        />
                        <StyledReminderTitle data-test="confirmation-modal-checkbox-label">
                            Don’t show this message again
                        </StyledReminderTitle>
                    </StyledCheckboxContainer>
                )}
                <StyledActions>
                    <Button
                        width="128px"
                        height="40px"
                        action={onCancel}
                        color="warning"
                        variant="inverse"
                        dataTest="confirmation-modal-cancel"
                    >
                        Go Back
                    </Button>
                    <StyledButtonContainer>
                        <Button
                            width="128px"
                            height="40px"
                            color="warning"
                            action={() => !!handleConfirm && handleConfirm()}
                            dataTest="confirmation-modal-confirm"
                        >
                            {confirmButtonText || 'Confirm'}
                        </Button>
                    </StyledButtonContainer>
                </StyledActions>
            </StyledContent>
        </DialogWrapper>
    );
};
