import ObjectiveIcon from "../../../ui/icons/ObjectiveIcon";
import ObjectiveIconActive from "../../../ui/icons/ObjectiveIconActive";
import ProductIcon from "../../../ui/icons/ProductIcon";
import ProductIconActive from "../../../ui/icons/ProductIconActive";
import ProspectIcon from "../../../ui/icons/ProspectIcon";
import ProspectIconActive from "../../../ui/icons/ProspectIconActive";
import SituationIcon from "../../../ui/icons/SitationIcon";
import SituationIconActive from "../../../ui/icons/SitationIconActive";
import ObjectiveAiParam from "../components/AiParamsComponents/Objective";
import ProductAiParam from "../components/AiParamsComponents/ProductService";
import ProspectAiParam from "../components/AiParamsComponents/Prospect";
import SituationAiParam from "../components/AiParamsComponents/Situation";
import { RolePlayContextParams, RoleplayRouteTypes } from "./types";

export const RouteSettings: {
    text: string;
    icon: any;
    iconActive: any;
    name: RoleplayRouteTypes
}[] = [
        {
            text: "Situation",
            icon: SituationIcon,
            iconActive: SituationIconActive,
            name: 'situation'
        },
        {
            text: "Customer",
            icon: ProspectIcon,
            iconActive: ProspectIconActive,
            name: 'prospect'
        },
        {
            text: "Product",
            icon: ProductIcon,
            iconActive: ProductIconActive,
            name: "product"
        },
        {
            text: "Approach",
            icon: ObjectiveIcon,
            iconActive: ObjectiveIconActive,
            name: "objective"
        }
    ]

export const routeComponents = {
    'situation': SituationAiParam,
    'prospect': ProspectAiParam,
    "product": ProductAiParam,
    "objective": ObjectiveAiParam,
}

export const initialRolePlayData = {
    title: 'Untitled',
    salesMethod: 'null',
    'situation': '',
    'prospect': '',
    "product": '',
    'objectiveRep': '',
    'objectiveNuances': ''
}

// keys for google analytics
export const keysForGA = {
    'salesMethod': 'method',
    'situation': 'situation',
    'prospect': 'customer',
    "product": 'product',
    'objectiveRep': 'goal',
    'objectiveNuances': 'nuances'
}

export const requiredContextParams: RolePlayContextParams[] = ['situation', 'prospect', 'product']