import React, { FC } from 'react';
import { NEW_PERMISSIONS } from '../../constants/enums/permissions';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../pages/UserProfile/store/reducers';

export const CheckPermission: FC<{
    permissions?: NEW_PERMISSIONS[];
    alternateContent?: any;
    matchAll?: boolean;
}> = ({ permissions, children, alternateContent, matchAll }) => {
    const profile = useSelector(getProfileState);
    if (!permissions) return <>{children}</>;
    let hasPermission;

    const permissionList =
        profile && profile.permissions ? profile.permissions : [];

    if (matchAll) {
        hasPermission = permissions.every(r => permissionList.includes(r));
    } else {
        hasPermission = permissions.some(r => permissionList.includes(r));
    }

    if (hasPermission) {
        return <>{children}</>;
    } else {
        return alternateContent ? alternateContent : null;
    }
};
