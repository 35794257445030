import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../constants/interfaces/filters';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    usePerformSubmissionActionApi,
    useRequestSubmissionRedoApi,
    useArchiveSubmissionsApi,
    useRestoreSubmissionsApi,
    useSearchSubmissionsApi,
    useGetSubmissionDetailsApi,
    useGetSubmissionReviewApi,
    usePerformSubmissionReviewApi,
} from '../../../api';
import {
    checkAllAccuracyScores,
    checkAllSubmissions,
    checkSingleAccuracyScore,
    checkSingleSubmission,
    getSubmissionDetailsFailure,
    getSubmissionDetailsStart,
    getSubmissionDetailsSuccess,
    getSubmissionReviewSuccess,
    modifyScore,
    searchAccuracyScoreFailure,
    searchAccuracyScoreStart,
    searchAccuracyScoreSuccess,
    searchSubmissionPaginationFailure,
    searchSubmissionPaginationStart,
    searchSubmissionPaginationSuccess,
    searchChallengeSubmissionsFailure,
    searchChallengeSubmissionsStart,
    searchChallengeSubmissionsSuccess,
    updateChallengeSubmissionFailure,
    updateChallengeSubmissionStart,
    updateChallengeSubmissionSuccess,
} from './actions';
import { SubmissionReview, SubmissionV2, SUBMISSION_TYPE } from '../../../constants/interfaces/Reviews';
import { setSearchState } from '../../../features/searchState/store/actions';
import { SEARCH_STATE } from '../../../features/searchState/constants';
import { SearchSubmissionsParams, SubmissionRedoParams, SubmissionReviewParams } from '../../../api/submissions/types';
import { copyTextToClipboard } from '../../../helpers/functions/copy-to-clipboard';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { createSearchSubmissionsParams } from '../tools';
import { AccuracyTestSubmissionFilters, ChallengeSubmissionFilters } from '../../../constants/interfaces/SubmissionSearchFilters';
import {
    UNAVAILABLE_SUBMISSION_ERROR,
    ALREADY_REQUESTED_REDO_ERROR
} from './constants';
import { pluralize } from '../../../helpers/functions/pluralize';

/**
 * @function useSearchChallengeSubmissionsService
 * @returns { CallableFunction }
 */
export const useSearchChallengeSubmissionsService = () => {
    const dispatch = useDispatch();
    const searchSubmissionsApi = useSearchSubmissionsApi();
    const profile = useSelector(getProfileState);
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { SearchParams } searchParams
     * @param { ChallengeSubmissionFilters } filters
     * @param { boolean? } shouldRemainFilterState
     * @param { void }
     */
    return useCallback(
        (
            searchParams: SearchParams,
            filters: ChallengeSubmissionFilters,
            shouldRemainFilterState?: boolean
        ) => {
            dispatch(searchChallengeSubmissionsStart());

            const searchSubmissionsParams = createSearchSubmissionsParams(
                searchParams,
                profile?.companyId ?? 0,
                SUBMISSION_TYPE.CHALLENGE,
                filters
            );

            if (filters.reviewStatuses.length === 1) {
                searchSubmissionsParams.filterChallenge = filters.reviewStatuses[0];
            }

            searchSubmissionsApi(searchSubmissionsParams)
                .then(data => {
                    if(!shouldRemainFilterState){
                        const combinedParams: SearchParams = {
                            ...searchParams,
                            customFilters: filters
                        };
                        dispatch(setSearchState(SEARCH_STATE.SUBMISSIONS.name, SEARCH_STATE.SUBMISSIONS.childrenKeys, combinedParams));
                    }
                    dispatch(searchChallengeSubmissionsSuccess(data));
                    dispatch(
                        searchSubmissionPaginationSuccess(
                            data.items.map(submission => submission.id),
                            data.count
                    ));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchChallengeSubmissionsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, profile?.companyId, searchSubmissionsApi, showMessage]
    );
};

/**
 * @function useSearchChallengeSubmissionPaginationService
 * @returns { CallableFunction }
 */
export const useSearchChallengeSubmissionPaginationService = () => {
    const dispatch = useDispatch();
    const searchSubmissionsApi = useSearchSubmissionsApi();
    const profile = useSelector(getProfileState);
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { SearchParams } searchParams
     * @param { ChallengeSubmissionFilters } filters
     * @param { Function } successCallback
     * @param { void }
     */
    return useCallback(
        (
            searchParams: SearchParams,
            filters: ChallengeSubmissionFilters,
            successCallback?: ((data: number[]) => void)
        ) => {
            dispatch(searchSubmissionPaginationStart());

            const searchSubmissionsParams = createSearchSubmissionsParams(
                searchParams,
                profile?.companyId ?? 0,
                SUBMISSION_TYPE.CHALLENGE,
                filters
            );

            if (filters.reviewStatuses.length === 1) {
                searchSubmissionsParams.filterChallenge = filters.reviewStatuses[0];
            }

            searchSubmissionsApi(searchSubmissionsParams)
                .then(data => {
                    const submissionIds = data.items.map(submission => submission.id);
                    dispatch(
                        searchSubmissionPaginationSuccess(
                            submissionIds,
                            data.count
                    ));
                    successCallback?.(submissionIds);
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchSubmissionPaginationFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, profile?.companyId, searchSubmissionsApi, showMessage]
    );
};

/**
 * @function useGetSubmissionDetailsService
 * @returns { CallableFunction }
 */
export const useGetSubmissionDetailsService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const getSubmissionDetailsApi = useGetSubmissionDetailsApi();
    const getSubmissionReviewApi = useGetSubmissionReviewApi();

    /**
     * @function callback
     * @param { string | number } submissionId
     * @param { Function } successCallback
     * @param { void }
     */
     return useCallback(
        (
            submissionId: number | string,
            successCallback?: (data: SubmissionV2) => void
        ) => {
            let submission: SubmissionV2;
            dispatch(getSubmissionDetailsStart());
            getSubmissionDetailsApi(submissionId)
                .then(data => {
                    submission = data;
                    if (submission.reviewedAt) {
                        return getSubmissionReviewApi(submissionId)
                            .catch((error: ErrorResult) => {
                                showMessage(error.message, 'error');
                                return Promise.resolve(undefined);
                            });
                    } else {
                        return Promise.resolve(undefined);
                    }
                })
                .then((review?: SubmissionReview) => {
                    dispatch(
                        getSubmissionDetailsSuccess(submission, review)
                    );
                    successCallback?.(submission);
                })
                .catch((error: ErrorResult) => {
                    if (error.code === 404) {
                        dispatch(getSubmissionDetailsFailure(
                            UNAVAILABLE_SUBMISSION_ERROR
                        ));
                    } else {
                        dispatch(getSubmissionDetailsFailure(error.message));
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, getSubmissionDetailsApi, getSubmissionReviewApi, showMessage]
    );
};

/**
 * @function useGetSubmissionReviewService
 * @returns { CallableFunction }
 */
export const useGetSubmissionReviewService = () => {
    const dispatch = useDispatch();
    const getSubmissionReviewApi = useGetSubmissionReviewApi();

    /**
     * @function callback
     * @param { number } submissionId
     * @param { Promise }
     */
     return useCallback((submissionId: number) => {
        return getSubmissionReviewApi(submissionId)
            .then((review: SubmissionReview) => {
                dispatch(
                    getSubmissionReviewSuccess(review)
                );
                return Promise.resolve(review);
            });
        },
        [dispatch, getSubmissionReviewApi]
    );
};

/**
 * @function usePerformSubmissionReviewService
 * @returns { CallableFunction }
 */
export const usePerformSubmissionReviewService = () => {
    const dispatch = useDispatch();
    const performSubmissionReviewApi = usePerformSubmissionReviewApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { number } submissionId
     * @param { Promise }
     */
    return useCallback(
        (submissionId: number, data: SubmissionReviewParams) => {
            dispatch(updateChallengeSubmissionStart());
            return performSubmissionReviewApi(submissionId, data)
                .then(data => {
                    dispatch(updateChallengeSubmissionSuccess([submissionId], 'update'));
                    showMessage('Review created!', 'success');
                    return Promise.resolve(data);
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateChallengeSubmissionFailure(error.message));
                    return Promise.reject(error);
                });
        },
        [dispatch, performSubmissionReviewApi, showMessage]
    );
};

export const useRequestSubmissionRedoService = () => {
    const dispatch = useDispatch();
    const requestSubmissionRedoApi = useRequestSubmissionRedoApi();
    const showMessage = useShowMessage();
    return useCallback(
        (submissionId: number, redo: SubmissionRedoParams) => {
            dispatch(updateChallengeSubmissionStart());

            return requestSubmissionRedoApi(submissionId, redo)
                .then(data => {
                    dispatch(updateChallengeSubmissionSuccess([submissionId], 'update'));
                    showMessage('Request Submitted', 'success');
                    return 'success';
                })
                .catch((error: ErrorResult) => {
                    if (error.code === 404) { // redo request was already submitted
                        dispatch(updateChallengeSubmissionSuccess([submissionId], 'update'));
                        showMessage(ALREADY_REQUESTED_REDO_ERROR, 'error');
                        return 'success';
                    } else {
                        dispatch(updateChallengeSubmissionFailure(error.message));
                        showMessage(error.message, 'error');
                        return 'error';
                    }
                });
        },
        [dispatch, requestSubmissionRedoApi, showMessage]
    );
};

export const useModifyScoreService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: any, field?: string) => {
            dispatch(modifyScore(value, field ? field : 'clarity'));
        },
        [dispatch]
    );
};

export const useCheckAllSubmissionsService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (submissionId: number[], checked: boolean, partial?: boolean) => {
            dispatch(checkAllSubmissions(submissionId, checked, partial));
        },
        [dispatch]
    );
};

export const useCheckSingleSubmissionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (submissionId: number) => {
            dispatch(checkSingleSubmission(submissionId));
        },
        [dispatch]
    );
};

export const useCheckSingleAccuracyScoreService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (submissionId: number) => {
            dispatch(checkSingleAccuracyScore(submissionId));
        },
        [dispatch]
    );
};

export const useCheckAllAccuracyScoresService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (submissionId: number[], checked: boolean, partial?: boolean) => {
            dispatch(checkAllAccuracyScores(submissionId, checked, partial));
        },
        [dispatch]
    );
};


export const useMarkSubmissionAsViewedService = () => {
    const dispatch = useDispatch();
    const performSubmissionActionApi = usePerformSubmissionActionApi();
    const showMessage = useShowMessage();
    return useCallback(
        (submissionId: any) => {
            performSubmissionActionApi(submissionId, 'view')
                .then(() => {
                    dispatch(
                        updateChallengeSubmissionSuccess(
                            [submissionId],
                            'update'
                        )
                    );
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [showMessage, performSubmissionActionApi, dispatch]
    );
};

export const useSearchAccuracyScoreService = () => {
    const dispatch = useDispatch();
    const searchSubmissionsApi = useSearchSubmissionsApi();
    const profile = useSelector(getProfileState);
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams, filters: AccuracyTestSubmissionFilters) => {
            dispatch(searchAccuracyScoreStart());

            const searchSubmissionsParams = createSearchSubmissionsParams(
                searchParams,
                profile?.companyId ?? 0,
                SUBMISSION_TYPE.SCENARIO,
                filters
            );
            if (filters.scenarioIds.length > 0) {
                searchSubmissionsParams.scenarioId = filters.scenarioIds.join(',');
            }

            searchSubmissionsApi(searchSubmissionsParams)
                .then(data => {
                    const combinedParams: SearchParams = {
                        ...searchParams,
                        customFilters: filters
                    };
                    dispatch(
                        setSearchState(
                            SEARCH_STATE.ACCURACY.name,
                            SEARCH_STATE.ACCURACY.childrenKeys,
                            combinedParams
                        )
                    );
                    dispatch(searchAccuracyScoreSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchAccuracyScoreFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [
            dispatch,
            profile?.companyId,
            searchSubmissionsApi,
            showMessage,
        ]
    );
};

/**
 * @function useGetSubmissionFromFeedService
 * @returns { CallableFunction }
 */
export const useGetSubmissionFromFeedService = () => {
    const dispatch = useDispatch();
    const searchSubmissionsApi = useSearchSubmissionsApi();
    const getSubmissionReviewApi = useGetSubmissionReviewApi();
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { string | number } submissionId
     * @param { boolean } loadReview
     * @param { Function } successCallback
     * @param { void }
     */
    return useCallback(
        (
            submissionId: number | string,
            successCallback?: (data: SubmissionV2) => void
        ) => {
            dispatch(getSubmissionDetailsStart());
            const params: SearchSubmissionsParams = {
                submissionId: submissionId.toString(),
                limit: 1,
                offset: 0
            };
            let submission: SubmissionV2;
            searchSubmissionsApi(params)
                .then((data) => {
                    if (data.items.length > 0) {
                        submission = data.items[0];
                        if (submission.reviewedAt) {
                            return getSubmissionReviewApi(submissionId)
                                .catch((error: ErrorResult) => {
                                    showMessage(error.message, 'error');
                                    return Promise.resolve(undefined);
                                });
                        } else {
                            return Promise.resolve(undefined);
                        }
                    } else {
                        return Promise.reject({ message: UNAVAILABLE_SUBMISSION_ERROR });
                    }
                })
                .then((review) => {
                    successCallback?.(submission);
                    dispatch(
                        getSubmissionDetailsSuccess(submission, review)
                    );
                })
                .catch((error: ErrorResult) => {
                    dispatch(getSubmissionDetailsFailure(error.message));
                    if (error.message !== UNAVAILABLE_SUBMISSION_ERROR) {
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, searchSubmissionsApi, getSubmissionReviewApi, showMessage]
    );
};

export const useArchiveSubmissionsService = (isAccuracyTest: boolean) => {
    const dispatch = useDispatch();
    const archiveSubmissionsApi = useArchiveSubmissionsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (submissionIds: number[]) => {
            dispatch(updateChallengeSubmissionStart());
            return archiveSubmissionsApi(submissionIds)
                .then(data => {
                    dispatch(
                        updateChallengeSubmissionSuccess(submissionIds, 'update')
                    );
                    const countText = pluralize(submissionIds.length, isAccuracyTest ? 'Accuracy Test' : 'Challenge Submission');
                    const message =
                        submissionIds.length > 1
                            ? `${countText} have been archived`
                            : `${countText} has been archived`;
                    showMessage(message, 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateChallengeSubmissionFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, archiveSubmissionsApi, isAccuracyTest, showMessage]
    );
};

export const useRestoreSubmissionsService = (isAccuracyTest: boolean) => {
    const dispatch = useDispatch();
    const restoreSubmissionsApi = useRestoreSubmissionsApi();
  
    const showMessage = useShowMessage();
    return useCallback(
        (submissionIds: number[]) => {
            dispatch(updateChallengeSubmissionStart());
            return restoreSubmissionsApi(submissionIds)
                .then(data => {
                    dispatch(
                        updateChallengeSubmissionSuccess(submissionIds, 'update')
                    );
                    const countText = pluralize(submissionIds.length, isAccuracyTest ? 'Accuracy Test' : 'Challenge Submission');
                    const message =
                        submissionIds.length > 1
                            ? `${countText} have been restored`
                            : `${countText} has been restored`;
                    showMessage(message, 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateChallengeSubmissionFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, isAccuracyTest, restoreSubmissionsApi, showMessage]
    );
};

/**
 * @function useCopyAccuracyLink
 * @returns { CallableFunction }
 */
export const useCopyAccuracyLink = () => {
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { SubmissionV2 } submission
     * @param { void }
     */
    return useCallback((submission: SubmissionV2) => {
        const content = 'This is an Accuracy Test'
            + (submission.user ? ` from ${submission.user.firstName} ${submission.user.lastName}` : '')
            + (submission.scenario ? ` for Scenario ${submission.scenario.title}` : '')
            + `. Check it out: ${window.location.href}`;
        if (copyTextToClipboard(content)) {
            showMessage('Link copied to clipboard', 'success');
        }
    }, [showMessage]);
}

/**
 * @function useCopyChallengeLink
 * @returns { CallableFunction }
 */
 export const useCopyChallengeLink = () => {
    const showMessage = useShowMessage();

    /**
     * @function callback
     * @param { SubmissionV2 } submission
     * @param { void }
     */
    return useCallback((submission: SubmissionV2) => {
        const content = 'This is a Challenge'
            + (submission.user ? ` from ${submission.user.firstName} ${submission.user.lastName}` : '')
            + (submission.practisSet ? ` for Practis Set ${submission.practisSet.name}` : '')
            + `. Check it out: ${window.location.href}`;
        if (copyTextToClipboard(content)) {
            showMessage('Link copied to clipboard', 'success');
        }
    }, [showMessage]);
}