import React, { FC, useCallback, useEffect, useState } from 'react';
import { useGetSelectionCharacterOffsetWithin } from '../../../../../../../../features/keywords/hooks';
import Tooltip from './Tooltip';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';
import { Keywords } from '../../../../../../../../constants/interfaces/Keywords';
import { Variables } from '../../../../../../../../theme/variables';
import { useSaveScenarioKeywordsService } from '../../../../../store/services';
import ContentEditableInput from '../../../../../../../../ui/components/ContentEditableDiv/ContentEditableDiv';


const StyledContainer = styled.div`
    z-index: 1;
    height: 100%;
    min-height: 62px;
`;

const StyledScriptItemTextarea = styled.div`
    height: 100%;
`;

export const ScriptItemTextarea: FC<{
    value: string;
    handleValueChange: (text: string) => void;
    viewMode?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
    scriptLineKeywords?: Keywords[];
    scriptLineId: number | string;
    recording?: boolean;
    dataTest?: string;
}> = ({
    value,
    handleValueChange,
    viewMode,
    autoFocus,
    placeholder,
    scriptLineKeywords,
    scriptLineId,
    recording,
    dataTest
}) => {
    const getSelectionCharacterOffsetWithin = useGetSelectionCharacterOffsetWithin();

    const [createToolTip, setCreateTooltip] = useState<{
        visible: boolean;
        top?: number;
        left?: number;
    }>({
        visible: false,
        top: 0,
        left: 0,
    });

    const [removeToolTip, setRemoveTooltip] = useState<{
        visible: boolean;
        top?: number;
        left?: number;
    }>({
        visible: false,
        top: 0,
        left: 0,
    });

    const [keywords, setKeywords] = useState({ start: 0, end: 0 });
    const [saveKeyword, setSaveKeyword] = useState(false);
    const [removeKeyword, setRemoveKeyword] = useState(false);
    const [keywordIdToRemove, setKeywordIdToRemove] = useState('');
    const [selections, setSelections] = useState<Keywords[]>(
        scriptLineKeywords ? scriptLineKeywords : []
    );

    useEffect(() => {
        if (scriptLineKeywords) {
            setSelections(scriptLineKeywords);
        }
    }, [scriptLineKeywords, setSelections]);

    const reportSelection = (refs: any) => {
        const selection = getSelectionCharacterOffsetWithin(refs.current);
        if (selection.start === selection.end) {
            return;
        }
        setRemoveTooltip({ visible: false });
        setCreateTooltip({
            visible: true,
            top: selection.selectionRect.top - 50,
            left:
                selection.selectionRect.left +
                selection.selectionRect.width * 0.5 -
                56,
        });
        setKeywords({ start: selection.start, end: selection.end - 1 });
    };

    const clearSelection = () => {
        const doc: any = document;
        const win: any = window;
        if (doc.selection) {
            doc.selection.empty();
        } else if (window.getSelection()) {
            win.getSelection().removeAllRanges();
        }
    };

    const handleModifyText = (text: string, caretPosition: number) => {
        if (viewMode) {
            return false;
        }
        const valueLength = text.length;
        const valueDiff = text.length - value.length;
        handleValueChange(text);
        let updatedSelections = [...selections];
        updatedSelections = updatedSelections.filter(selection => {
            return selection.end < valueLength;
        });
        updatedSelections = updatedSelections.filter(selection => {
            return (
                caretPosition < selection.start || caretPosition > selection.end
            );
        });

        updatedSelections = updatedSelections.map(selection => {
            if (selection.start >= caretPosition) {
                const start = selection.start + valueDiff;
                const end = selection.end + valueDiff;
                return { start: start, end: end, id: `${start}-${end}` };
            } else {
                return selection;
            }
        });
        setSelections(updatedSelections);
    };

    const handleAddKeyword = (id: string, start: number, end: number) => {
        let newSelections: Keywords[] = [...selections];
        let startIndex = start;
        let endIndex = end;
        const selectionConflict = selections.filter((selection: Keywords) => {
            return (
                (start >= selection.start && start <= selection.end) ||
                (end >= selection.start && end <= selection.end) ||
                (selection.end <= end && selection.start >= start)
            );
        });

        if (selectionConflict.length > 0) {
            for (let conflict of selectionConflict) {
                startIndex = Math.min(startIndex, conflict.start);
                endIndex = Math.max(endIndex, conflict.end);
                newSelections = newSelections.filter(
                    selection => selection.id !== conflict.id
                );
            }
        }

        newSelections.push({ start: startIndex, end: endIndex, id: id });
        setSelections(newSelections);
        setSaveToScenario(true);
    };

    const handleRemoveKeyword = useCallback(
        (id: string) => {
            setSelections(
                selections.filter((selection: any) => {
                    return selection.id !== id;
                })
            );
        },
        [selections]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleToggleKeyword = (start: number, end: number) => {
        const findSelection = selections.filter(
            (selection: any) => selection.id === `${start}-${end}`
        );
        if (findSelection[0]) {
            handleRemoveKeyword(`${start}-${end}`);
        } else {
            handleAddKeyword(`${start}-${end}`, start, end);
        }
    };

    useEffect(() => {
        if (saveKeyword) {
            handleToggleKeyword(keywords.start, keywords.end);
            setSaveKeyword(false);
        }
    }, [keywords, saveKeyword, selections, handleToggleKeyword]);

    useEffect(() => {
        if (removeKeyword) {
            handleRemoveKeyword(keywordIdToRemove);
            setSaveToScenario(true);
            setRemoveKeyword(false);
        }
    }, [keywordIdToRemove, removeKeyword, selections, handleRemoveKeyword]);

    // Handle case when deleting text to remove keywords that would exceed the boundaries
    useEffect(() => {
        const newSelections = [...selections].filter((selection: Keywords) => {
            return selection.end <= value.length;
        });
        if (newSelections.length !== selections.length) {
            setSelections(newSelections);
            setSaveToScenario(true);
        }
    }, [value, selections, setSelections]);

    const saveScenarioKeyword = useSaveScenarioKeywordsService();
    const [saveToScenario, setSaveToScenario] = useState(false);

    useEffect(() => {
        if (saveToScenario) {
            saveScenarioKeyword(selections, scriptLineId);
            setSaveToScenario(false);
        }
    }, [
        selections,
        saveScenarioKeyword,
        scriptLineId,
        saveToScenario,
        setSaveToScenario,
    ]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setCreateTooltip({ visible: false });
                setRemoveTooltip({ visible: false });
            }}
        >
            <StyledContainer>
                {createToolTip.visible && (
                    <Tooltip
                        top={createToolTip.top}
                        left={createToolTip.left}
                        onClick={() => {
                            setCreateTooltip({ visible: false });
                            setSaveKeyword(true);
                            clearSelection();
                        }}
                    />
                )}
                {removeToolTip.visible && (
                    <Tooltip
                        top={removeToolTip.top}
                        left={removeToolTip.left}
                        onClick={() => {
                            setRemoveTooltip({ visible: false });
                            setRemoveKeyword(true);
                            clearSelection();
                        }}
                        textColor={Variables.Colors.softBlue}
                    />
                )}
                <StyledScriptItemTextarea>
                    <ContentEditableInput
                        value={value}
                        selections={selections}
                        setSelections={setSelections}
                        handleChange={(text, caretPosition) =>
                            handleModifyText(text, caretPosition)
                        }
                        viewMode={viewMode}
                        deleteKeyword={handleRemoveKeyword}
                        autoFocus={autoFocus}
                        setCreateTooltip={setCreateTooltip}
                        setRemoveTooltip={setRemoveTooltip}
                        setKeywordIdToRemove={setKeywordIdToRemove}
                        placeholder={placeholder}
                        recording={recording}
                        scriptLineId={scriptLineId}
                        onMouseUp={reportSelection}
                        dataTest={dataTest}
                    />
                </StyledScriptItemTextarea>
            </StyledContainer>
        </ClickAwayListener>
    );
};

export default ScriptItemTextarea;
