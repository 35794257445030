import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const UpArrow = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 512.011 512.011"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fillRule="evenodd" fill="currentColor">
                    <g>
                        <path
                            d="M505.755,358.256L271.088,123.589c-8.341-8.341-21.824-8.341-30.165,0L6.256,358.256c-8.341,8.341-8.341,21.824,0,30.165
			s21.824,8.341,30.165,0l219.584-219.584l219.584,219.584c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251
			C514.096,380.08,514.096,366.597,505.755,358.256z"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default UpArrow;
