import {
    ACTIONS,
    getRoleplaysFailure,
    getRoleplaysStart,
    getRoleplaysSuccess
} from './actions';
import { RoleplaysState } from './states';

type RoleplaysActions =
    | ReturnType<typeof getRoleplaysStart>
    | ReturnType<typeof getRoleplaysSuccess>
    | ReturnType<typeof getRoleplaysFailure>;

export const roleplaysReducer = (
    state = { isLoading: false },
    action: RoleplaysActions
): RoleplaysState => {
    switch(action.type) {
        case ACTIONS.GET_ROLEPLAYS_START:
            return {
                ...state,
                isLoading: true
            };
        case ACTIONS.GET_ROLEPLAYS_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: ''
            };
        case ACTIONS.GET_ROLEPLAYS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}