import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SearchParams } from '../../../../constants/interfaces/filters';
import { SUBMISSION_TYPE } from '../../../../constants/interfaces/Reviews';
import {
    AccuracyTestSubmissionFilters,
    ChallengeSubmissionFilters,
} from '../../../../constants/interfaces/SubmissionSearchFilters';
import { convertPresentVerbToPastVerb } from '../../../../helpers/functions/strings';
import { useChunkRequestsService } from '../../../../services/ChunkRequestService/hooks';
import { ChunkRequestActionInterface } from '../../../../services/ChunkRequestService/hooks/types';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../ui/components/ModalDialogs/store/actions';
import { GeneralBulkActionModalInterface } from '../../../../ui/components/ModalDialogs/types';
import { UPDATE_SUBMISSION_STATUS_ACTION } from './constants';
import { useHandleSearchSubmissionsAndUpdateStatus } from './helpers';
import {
    UpdateSubmissionStatusChunkRequestParameters,
    UpdateSubmissionStatuses,
} from './types';

/**
 *
 * @description Custom hook to update submission status with chunk service
 * when all items are selected.
 * @function useUpdateSubmissionStatusBulkActionService
 * @param { SearchParams } searchParams
 * @param { number } companyId
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
export function useUpdateSubmissionStatusBulkActionService(
    scope: keyof typeof SUBMISSION_TYPE,
    searchParams: SearchParams,
    filters: AccuracyTestSubmissionFilters | ChallengeSubmissionFilters,
    totalUsersCount: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const updateActionRef = useRef<UpdateSubmissionStatuses>('Archive');

    const dispatch = useDispatch();

    const searchSubmissionsAndUpdateStatus =
        useHandleSearchSubmissionsAndUpdateStatus(
            scope,
            updateActionRef.current
        );

    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const totalCompletedItems = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<UpdateSubmissionStatusChunkRequestParameters>[] =
        [
            {
                actionName: UPDATE_SUBMISSION_STATUS_ACTION,
                actionFunction: searchSubmissionsAndUpdateStatus,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            offset: 0,
                        },
                        filters,
                        onSuccessCallback: numberOfDoneItems =>
                            (totalCompletedItems.current =
                                totalCompletedItems.current +
                                numberOfDoneItems),
                        totalItems: totalUsersCount,
                    },
                    fieldName: 'totalItems',
                },
            },
        ];

    /**
     * @function clearTotalCompletedItems
     * @returns { void }
     */
    const clearTotalCompletedItems = () => (totalCompletedItems.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            clearTotalCompletedItems();

            onSuccessCallback?.();
        });

        showMessage(
            `${
                totalCompletedItems.current
            } submissions have been ${convertPresentVerbToPastVerb(
                updateActionRef.current
            )}`,
            'success'
        );
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: `${updateActionRef.current} submission`,
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );

            clearTotalCompletedItems();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopBulkActionService
     * @returns { void }
     */
    const handleStopBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            clearTotalCompletedItems();

            onSuccessCallback?.();
        });

        showMessage(
            `${
                totalCompletedItems.current
            } submissions have been ${convertPresentVerbToPastVerb(
                updateActionRef.current
            )}`,
            'success'
        );
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: `${updateActionRef.current} submission`,
                        onStopBulkActionService: handleStopBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [dispatch, handleStopBulkActionService, hideModalDialog, setIsStopped]);

    useEffect(() => {
        if (isRunning) {
            handleStartBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback((action: UpdateSubmissionStatuses) => {
        updateActionRef.current = action;

        setIsRunning(true);
    }, []);
}