import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Payload = styled.div`
    overflow: auto;
    height: 400px;
    color: ${props => props.theme.Colors.dark};
    text-align: left;
`;

export const StyledDetailsWrapper = styled.div`
    position: relative;
`;

export const StyledDetails = styled.div`
    position: absolute;
    top: 10px;
    left: -146px;
    z-index: 1000;
    width: 312px;
    background: ${props => props.theme.Colors.white};
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const useStyles = makeStyles(theme => ({
    customTableCellStyle: {
        paddingLeft: '18px !important',
    },

    tableToolsRootStyle: {
        marginLeft: 16,
    },
}));
