import { Reducer } from 'redux';
import Storage from '../services/Storage';
import {
    buildConfig,
    EnvironmentConfigs,
    registerConfig,
} from '../tools/config';
import { WEB_SITE_ID } from '../tools/constants/WebSiteId';
import { DefaultHttp } from '../tools/http/DefaultHttp';
import { Http } from '../tools/http/Http';
import { ThunkContext } from '../tools/thunk/ThunkContext';
import { DefaultUrlBuilder } from '../tools/url/DefaultUrlBuilder';
import { UrlBuilder } from '../tools/url/UrlBuilder';
import { createStore } from './store';
import { NewRelicApi } from '../tools/newRelic/NewRelic';
import { DefaultNewRelic } from '../tools/newRelic/DefaultNewRelic';
import { DefaultLocalStorage } from '../tools/localStorage/DefaultLocalStorage';
import { LocalStorage } from '../tools/localStorage/LocalStorage';
import ROUTES from '../routes/routes';
import {
    clearAuthData,
    isAuthorized,
    getCurrentUserId
} from '../helpers/functions/auth-helpers';
import { UserV2 } from '../constants/interfaces/User';
import { isUserRole } from '../constants/enums';

const loggedOutRoutes = [
    ROUTES.AUTH_PAGES.LOGIN,
    ROUTES.AUTH_PAGES.MOBILE_NUMBER_VERIFY.replace(':phoneNumber', ''),
    ROUTES.AUTH_PAGES.RESET_PASSWORD
]

function logOut() {
    clearAuthData();
    const isLoggedOutRoute = loggedOutRoutes.some(
        route => window.location.pathname.indexOf(route) === 0
    );
    if (!isLoggedOutRoute) {
        window.location.href = ROUTES.AUTH_PAGES.LOGIN;
    }
}

export function configServices(
    webSiteId: WEB_SITE_ID,
    appConfig: EnvironmentConfigs,
    rootReducer: Reducer
) {
    const config = buildConfig(appConfig);
    registerConfig(config);

    const urlBuilder: UrlBuilder = new DefaultUrlBuilder({
        adminBaseUrl: config.ADMIN_PORTAL_BASE_URL!,
        apiBaseUrl: config.API_BASE_URL!,
        apiV2BaseUrl: config.API_V2_BASE_URL!,
        companyBaseUrl: config.COMPANY_PORTAL_BASE_URL!,
    });

    const http: Http = new DefaultHttp({
        urlBuilder: urlBuilder,
        clientHeader: config.API_CLIENT!,
        getToken: () => Storage.get('token') || Storage.get('temp_token'),
        onError: error => {
            switch (error?.code) {
                case 401:
                    logOut();
                    break;
                case 403:
                    // if user doesn't have permissions, we need to check if their role has not been changed to USER
                    if (isAuthorized()) {
                        http.get(`users/${getCurrentUserId()}`, undefined, undefined, true)
                            .then((user: UserV2) => {
                                if (isUserRole(user.role?.name)) {
                                    logOut();
                                }
                            });
                    }
                    break;
            }
        },
    });

    const localStorage: LocalStorage = new DefaultLocalStorage();

    const newRelicApi: NewRelicApi = new DefaultNewRelic({
        newRelicLicenceKey: config.NEW_RELIC_LICENSE_KEY,
        newRelicAppId: config.NEW_RELIC_APP_ID,
    });

    const thunkContext: ThunkContext = {
        webSiteId,
        http,
        localStorage,
        urlBuilder,
        newRelicApi,
    };

    const store = createStore(rootReducer, thunkContext);

    return {
        webSiteId,
        urlBuilder,
        http,
        localStorage,
        newRelicApi,
        store,
    };
}

