import { isEmpty } from 'lodash';
import { Variables } from '../../../../theme/variables';
import { EmptyState } from '../../../../ui/components/EmptyStates';
import { FilterSkeleton } from '../../../../ui/components/Skeleton';
import NoSearchResult from '../../../../ui/icons/NoSearchResult';
import { FilterContainer } from '../../filter-container';
import { SearchableFilterHeaderKey } from '../../searchable-filter-header/store/types';
import {
    Container,
    SkeletonContainer,
    EmptyStateContainer,
    ListContainer,
    StyledCheckbox,
    CounterContainer,
    SelectionCounter,
    ToggleAllSelection,
} from './styles';
import { MultipleSelectTeamsFilterViewProps } from './types';
import { pluralize } from '../../../../helpers/functions/pluralize';

function MultipleSelectTeamsFilterView({
    loading, myTeams, selectedTeamIds, isDisabled, onTeamSearch, onTeamToggle, onAllToggle
}: MultipleSelectTeamsFilterViewProps) {
    const isAllSelected = selectedTeamIds?.length === myTeams?.count;
    return (
        <FilterContainer
            headerProps={{
                storeKey: SearchableFilterHeaderKey.MULTIPLE_TEAMS,
                title: 'Teams',
                hasSearch: true,
                onSearch: onTeamSearch,
                isLoading: loading,
                isDisabled,
            }}
        >
            <Container>
                {loading ? (
                    <SkeletonContainer>
                        <FilterSkeleton />
                    </SkeletonContainer>
                ) : isEmpty(myTeams?.items) ? (
                    <EmptyStateContainer>
                        <EmptyState
                            fontSize={11}
                            icon={NoSearchResult}
                            iconStyle={{
                                width: 32,
                                height: 32,
                                marginBottom: 4,
                            }}
                            dataTest="no-teams"
                        >
                            No Search Results
                        </EmptyState>
                    </EmptyStateContainer>
                ) : (
                    <>
                        <CounterContainer>
                            <SelectionCounter data-test="teams-selection-counter">
                                {selectedTeamIds?.length
                                    ? `${pluralize(
                                          selectedTeamIds.length,
                                          'item'
                                      )} selected`
                                    : 'No Items selected'}
                            </SelectionCounter>
                            <ToggleAllSelection
                                onClick={onAllToggle}
                                data-test={
                                    isAllSelected
                                        ? 'teams-unselect-all'
                                        : 'teams-select-all'
                                }
                                isDisabled={isDisabled}
                            >
                                {isAllSelected ? 'Unselect All' : 'Select All'}
                            </ToggleAllSelection>
                        </CounterContainer>
                        <ListContainer>
                            {myTeams?.items.map(item => {
                                const isSelected =
                                    selectedTeamIds?.includes(item.id!) ??
                                    false;
                                return (
                                    <StyledCheckbox
                                        key={item.id}
                                        handleChange={() =>
                                            onTeamToggle(item.id!)
                                        }
                                        checked={isSelected}
                                        size={12}
                                        label={item.name}
                                        fontColor={isDisabled ? Variables.Colors.steelGrey : Variables.Colors.black}
                                        labelStyles={{
                                            fontWeight: isSelected
                                                ? 'bold'
                                                : 'normal',
                                        }}
                                        dataTest="team-filter-item-checkbox"
                                        disabled={isDisabled}
                                        disabledCheckedBackground={Variables.Colors.cloudyBlue}
                                    />
                                );
                            })}
                        </ListContainer>
                    </>
                )}
            </Container>
        </FilterContainer>
    );
}

export default MultipleSelectTeamsFilterView;