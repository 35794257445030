import React, { useState } from 'react';
import styled from 'styled-components';

import ROUTES from '../../../../../routes/routes';
import { SmallButton } from '../../../../components/Button';
import { DropDownList, DropDownListItem } from '../../../../components/DropdownList';
import Plus from '../../../../icons/Plus';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import { CheckPermission } from '../../../../../features/permissions';
import FolderIcon from '../../../../icons/Folder';
import NewUserIcon from '../../../../icons/NewUser';
import DocumentIcon from '../../../../icons/Document';
import MountainsIcon from '../../../../icons/Mountains';
import TeamIcon from '../../../../icons/Team';

const StyledActionsContainer = styled.div`
    position: relative;
    margin-left: 16px;
`;

const StyledActionsButton = styled.div`
    user-select: none;
`;

const StyledPlus = styled(Plus)`
    width: 12px;
`;

export interface ActionsDropdownItem {
    icon: any;
    title: string;
    url: string;
    permissions: NEW_PERMISSIONS[];
}

const defaultItems: ActionsDropdownItem[] = [
    {
        title: 'Practis Set',
        icon: FolderIcon,
        url: ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE.replace(
            ':practisSetId',
            'new'
        ),
        permissions: [NEW_PERMISSIONS.CREATE_PRACTIS_SET],
    },
    {
        title: 'Scenario',
        icon: DocumentIcon,
        url: ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE.replace(
            ':scenarioId',
            'new'
        ),
        permissions: [NEW_PERMISSIONS.CREATE_SCENARIO],
    },
    {
        title: 'Challenge',
        icon: MountainsIcon,
        url: ROUTES.LIBRARY_SETTINGS.CHALLENGES.SINGLE.replace(
            ':challengeId',
            'new'
        ),
        permissions: [NEW_PERMISSIONS.CREATE_CHALLENGE],
    },
    {
        title: 'User',
        icon: NewUserIcon,
        url: ROUTES.ACTION_ITEMS.NEW_USER.replace(':draftId', 'new'),
        permissions: [NEW_PERMISSIONS.CREATE_STAGING],
    },
    {
        title: 'Team',
        icon: TeamIcon,
        url: ROUTES.TEAMS_PAGES.NEW_TEAM,
        permissions: [NEW_PERMISSIONS.CREATE_TEAM],
    },
];

export const ActionsDropdown: React.FC = () => {
    const [open, toggleOpen] = useState(false);
    
    return (
        <ActionsDropdownContext.Consumer>
            {context => {
                const items = context.items || defaultItems;
                return (
                    <StyledActionsContainer data-test="actionDropDownContainer">
                        <StyledActionsButton
                            onClick={() => toggleOpen(!open)}
                            data-test="actionDropDownToggleButton"
                        >
                            <SmallButton>
                                <StyledPlus />
                            </SmallButton>
                        </StyledActionsButton>
                        {open && (
                            <DropDownList
                                open={open}
                                toggleOpen={toggleOpen}
                                marginTop={8}
                            >
                                {items.map(item => (
                                    <CheckPermission
                                        key={item.title}
                                        permissions={item.permissions}
                                    >
                                        <DropDownListItem
                                            name={item.title}
                                            modalUrl={true}
                                            icon={item.icon}
                                            url={item.url}
                                        />
                                    </CheckPermission>
                                ))}
                            </DropDownList>
                        )}
                    </StyledActionsContainer>
                );
            }}
        </ActionsDropdownContext.Consumer>
    );
};

export default ActionsDropdown;

export interface ActionsDropdownContextValue {
    items?: ActionsDropdownItem[];
}

export const ActionsDropdownContext = React.createContext<ActionsDropdownContextValue>(
    {}
);
