import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { CheckPermission } from '../../../../features/permissions';
import { AssignRoles } from './AssignRoles';
import { NewUserValues } from '../models/NewUserValues';
import { SelectedUserLabels } from './SelectedUserLabels';
import SelectedUserPractisSetsComponent from './SelectedUserPractisSets';
import { WithLabelsContext } from '../../../../features/portableLabels';
import { WithPractisSetsContext } from '../../../../features/portablePractisSets';
import { WithTeamsContext } from '../../../../features/portableTeams';
import { useResetLabels } from '../../../../features/portableLabels/store/hors/withLabels/services';
import { useResetPractisSets } from '../../../../features/portablePractisSets/store/hors/withPractisSets/services';
import SelectedUserTeamsComponent from './SelectedUserTeams';
import { useResetTeams } from '../../../../features/portableTeams/store/hors/withTeams/services';
import { FormikField } from '../../../../ui/components/FormikField';
import Plus from '../../../../ui/icons/Plus';
import { PractisSetWithDueDate } from '../../../../constants/interfaces/Draft';

//region Styles
const StyledUserListItem = styled.div`
    background: ${props => props.theme.Colors.white};
    height: 64px;
    margin-bottom: 16px;
`;

const StyledRow = styled.div<{
    hasBlueBorder?: boolean;
    hasNormalBorder?: boolean;
    hasGreyBorder: boolean;
    hasError: boolean;
}>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 5%);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    ${props =>
        props.hasBlueBorder &&
        `border: solid 2px ${props.theme.Colors.darkSkyBlue};`}
    ${props =>
        props.hasNormalBorder &&
        `border-color: ${props.theme.Colors.paleGrey};`}
    ${props =>
        props.hasGreyBorder &&
        `border-color: ${props.theme.Colors.blueyGreyTwo};`}
    ${props => props.hasError && `border-color: ${props.theme.Colors.tomato};`}
    
    background-color: ${props => props.theme.Colors.white};
`;

const StyledField = styled.div<{ flex: number }>`
    flex: ${props => props.flex};
    padding-right: 1px;
`;

const AddIconArea = styled.div`
    flex: 5;
    display: flex;
    justify-content: flex-end;
`;

const StyledAddUserIconContainer = styled.button<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    align-self: center;
    border-radius: 4px;
    margin-right: 17px;
    background-color: ${props =>
        !!props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.darkSkyBlue};
    ${props => !!props.disabled && 'pointer-events: none;'}
    outline: none;
    border: none;
    cursor: pointer;
`;

const StyledAddUserIconHolder = styled.div<{ disabled?: boolean }>`
    cursor: pointer;
    width: 12px;
    height: 16px;
    color: ${props => props.theme.Colors.white};
    &:active {
        opacity: 0.7;
    }
`;

const BorderSeparator = styled.div`
    height: 33px;
    width: 1px;
    align-self: center;
    flex-shrink: 0;
    border-left: 1px solid ${props => props.theme.Colors.paleGrey};
`;

const StyledPlusIconContainer = styled.div<{ padding?: string }>`
    padding-left: 20px;
    display: flex;
    align-items: center;
    flex: 1;
`;

const PlusIconHolder = styled.div`
    width: 10px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const ErrorLabel = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 5px 24px;
    color: ${props => props.theme.Colors.tomato};
    position: absolute;
    z-index: 1;
    width: 100%;
`;
//endregion

export type AddNewUserProps = {
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => void;
    assignedRole: number | null;
    hasError?: boolean;
    selectedUserLabels: number[];
    setSelectedUserLabels: (labelIds: number[]) => void;
    selectedUserPractisSets: PractisSetWithDueDate[];
    setSelectedUserPractisSets: (practisSetIds: PractisSetWithDueDate[]) => void;
    selectedUserTeams: number[];
    setSelectedUserTeams: (teamIds: number[]) => void;
    onAssignRoleCheck(role: number | null): void;
    users: NewUserValues[];
    isSubmitDisabled: boolean;
    errorMessages: any;
    startedToFillInitialUser?: boolean;
    hideNewUserTipDisplayed?: boolean;
    creatingUser: boolean;
    isRoleDisabled: boolean | undefined;
    dataTest: string;
};

export const AddNewUser: FC<AddNewUserProps> = ({
    handleInputChange,
    assignedRole,
    onAssignRoleCheck,
    hasError,
    setSelectedUserLabels,
    selectedUserLabels,
    setSelectedUserPractisSets,
    selectedUserPractisSets,
    selectedUserTeams,
    setSelectedUserTeams,
    users,
    isSubmitDisabled,
    errorMessages,
    startedToFillInitialUser,
    hideNewUserTipDisplayed,
    creatingUser,
    isRoleDisabled,
    dataTest
}) => {
    const clearLabels = useResetLabels();
    const clearPractisSets = useResetPractisSets();
    const clearTeams = useResetTeams();

    useEffect(() => {
        if (creatingUser) {
            clearLabels();
            clearPractisSets();
            clearTeams();
        }
        return () => {
            clearLabels();
            clearPractisSets();
            clearTeams();
        };
    }, [clearLabels, clearPractisSets, clearTeams, creatingUser]);

    return (
        <StyledUserListItem>
            <StyledRow
                hasBlueBorder={!hideNewUserTipDisplayed}
                hasNormalBorder={!!users.length && !startedToFillInitialUser}
                hasGreyBorder={!!startedToFillInitialUser}
                hasError={!!hasError}
                data-test={`${dataTest}-row`}
            >
                <StyledPlusIconContainer>
                    <PlusIconHolder>
                        <Plus />
                    </PlusIconHolder>
                </StyledPlusIconContainer>
                <StyledField flex={14}>
                    <Field
                        maxLength={100}
                        autoFocus={!startedToFillInitialUser}
                        type="text"
                        component={FormikField}
                        name="firstName"
                        label="First Name*"
                        colorTheme="lightTwo"
                        height="64px"
                        labelFontSize="13px"
                        decreaseLabelFontSize={true}
                        inputPadding="27px 13px 14px 13px"
                        labelFontWeight={600}
                        labelLeftPosition={'12px'}
                        hideError
                        handleChange={(e: any) =>
                            handleInputChange(e, 'firstName')
                        }
                        dataTest={`${dataTest}-first-name`}
                    />
                </StyledField>
                <BorderSeparator />
                <StyledField flex={14}>
                    <Field
                        maxLength={100}
                        type="text"
                        component={FormikField}
                        name="lastName"
                        label="Last Name*"
                        colorTheme="lightTwo"
                        height="64px"
                        labelFontSize="13px"
                        decreaseLabelFontSize={true}
                        inputPadding="27px 13px 14px 13px"
                        labelFontWeight={600}
                        labelLeftPosition={'12px'}
                        hideError={true}
                        handleChange={(e: any) =>
                            handleInputChange(e, 'lastName')
                        }
                        dataTest={`${dataTest}-last-name`}
                    />
                </StyledField>
                <BorderSeparator />
                <StyledField flex={18}>
                    <Field
                        type="text"
                        component={FormikField}
                        name="email"
                        label="Email*"
                        colorTheme="lightTwo"
                        labelFontSize="13px"
                        decreaseLabelFontSize={true}
                        height="64px"
                        inputPadding="27px 13px 14px 13px"
                        labelFontWeight={600}
                        labelLeftPosition={'12px'}
                        hideError={true}
                        handleChange={(e: any) => handleInputChange(e, 'email')}
                        dataTest={`${dataTest}-email`}
                    />
                </StyledField>
                <BorderSeparator />
                <StyledField flex={10}>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.ASSIGN_INVITATION_ROLE]}
                    >
                        <AssignRoles
                            disabled={isRoleDisabled}
                            assignedRole={assignedRole}
                            handleRoleCheck={(role: number) =>
                                onAssignRoleCheck(role)
                            }
                            dataTest={`${dataTest}-role`}
                        />
                    </CheckPermission>
                </StyledField>
                <BorderSeparator />
                <StyledField flex={13}>
                    <CheckPermission
                        permissions={[
                            NEW_PERMISSIONS.ASSIGN_INVITATION_PRACTIS_SET,
                        ]}
                    >
                        <SelectedUserTeamsComponent
                            selectedTeams={selectedUserTeams}
                            onSelectTeams={setSelectedUserTeams}
                            dataTest={`${dataTest}-teams`}
                        />
                    </CheckPermission>
                </StyledField>
                <BorderSeparator />
                <StyledField flex={15}>
                    <CheckPermission
                        permissions={[
                            NEW_PERMISSIONS.ASSIGN_INVITATION_PRACTIS_SET,
                        ]}
                    >
                        <SelectedUserPractisSetsComponent
                            selectedPractisSets={selectedUserPractisSets}
                            onSelectPractisSets={setSelectedUserPractisSets}
                            dataTest={`${dataTest}-practis-sets`}
                        />
                    </CheckPermission>
                </StyledField>
                <BorderSeparator />
                <StyledField flex={10}>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.ASSIGN_INVITATION_LABEL]}
                    >
                        <SelectedUserLabels
                            selectedLabels={selectedUserLabels}
                            onSelectLabels={setSelectedUserLabels}
                            dataTest={`${dataTest}-labels`}
                        />
                    </CheckPermission>
                </StyledField>
                <AddIconArea>
                    <StyledAddUserIconContainer
                        disabled={
                            creatingUser || !assignedRole || isSubmitDisabled
                        }
                        data-test={`${dataTest}-add`}
                    >
                        <StyledAddUserIconHolder>
                            <Plus />
                        </StyledAddUserIconHolder>
                    </StyledAddUserIconContainer>
                </AddIconArea>
            </StyledRow>
            {!!hasError &&
                (!!errorMessages.email ||
                    !!errorMessages.firstName ||
                    !!errorMessages.lastName) && (
                    <ErrorLabel data-test={`${dataTest}-error`}>
                        {errorMessages.email ||
                            errorMessages.firstName ||
                            errorMessages.lastName}
                    </ErrorLabel>
                )}
        </StyledUserListItem>
    );
};

export const AddNewUserContainer: FC<AddNewUserProps> = props => {
    return (
        <WithLabelsContext.Provider
            value={{ reducerName: 'draftEdit', scope: 'addNewUser' }}
        >
            <WithPractisSetsContext.Provider
                value={{ reducerName: 'draftEdit', scope: 'addNewUser' }}
            >
                <WithTeamsContext.Provider
                    value={{
                        reducerName: 'draftEdit',
                        scope: 'addNewUser',
                    }}
                >
                    <AddNewUser {...props} />
                </WithTeamsContext.Provider>
            </WithPractisSetsContext.Provider>
        </WithLabelsContext.Provider>
    );
};
