import { MutableRefObject, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';

import { useShowMessage } from '../ErrorMessages/ErrorMessages';

const uuid = require('uuid/v1');

/**
 * @function useCheckImage
 * @param { React.ChangeEvent<HTMLInputElement> } event
 * @returns { void }
 */
export const useCheckImage = (maxImageSize: number) => {
    const showMessage = useShowMessage();

    return useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            let file = event?.target?.files?.[0];

            if (!file) return false;
            if (!['image/jpeg', 'image/png', 'image/bmp'].includes(file.type)) {
                showMessage('Uploaded file is not a picture', 'error');
                return false;
            }
            if (file.size >= maxImageSize) {
                showMessage(`The image file size must be less than ${(maxImageSize/1048576).toFixed()} MB`, 'error');
                return false;
            } else if (!file.type.includes('image')) {
                showMessage('File must be an image', 'error');
                return false;
            }

            return true;
        },
        [showMessage, maxImageSize]
    );
};

/**
 * @function getEditedImageFileInfo
 * @param { MutableRefObject<AvatarEditor | null> } imageEditorRef
 */
export const getEditedImageFileInfo = async (
    imageEditorRef: MutableRefObject<AvatarEditor | null>
) => {
    const dataUrl = imageEditorRef?.current?.getImage()?.toDataURL();

    if (!dataUrl) return null;

    const result = await fetch(dataUrl);
    const blob = await result.blob();

    return {
        url: URL.createObjectURL(blob),
        file: new File([blob], `image${uuid()}.png`, { type: 'image/png' }),
    };
};

