import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    background: ${props => props.theme.Colors.white};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const StyledLoadingContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

export const CreatedByWrapper = styled.div`
    padding: 0 16px 10px;
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
`;