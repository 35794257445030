import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { SIZES } from '../table-wrapper/constants';

const Root = styled.div`
    min-width: ${SIZES.MIN_WIDTH}px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 16px 0;
`;

export const TableTools: FC<{
    className?: string;
}> = memo(({ children, className }) => (
    <Root className={className}>{children}</Root>
));
