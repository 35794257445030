import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    useLabelsConfigState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../../../features/labels/store/states';
import { useSetAssignLabelsActionService } from '../../../../../features/labels/store/services';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../constants/interfaces/filters';
import { AssignLabelOptions } from './components/AssignLabelOptions';
import { useSelector } from 'react-redux';
import { getCompanyState } from '../../../../../pages/CompanySettings/store/reducers';
import { getProfileState } from '../../../../../pages/UserProfile/store/reducers';
import { TableAssignOptionTypes } from './enums/TableAssignOptionTypes';
import OutsideActionBox from '../../../OutsideActionBox/OutsideActionBox';
import { TableAssignOptionsSelectDropdown } from './components/TableAssignOptionsSelectDropdown';
import { TableAssignOptionsMenuItem } from './interfaces/TableAssignOptionsMenuItem';
import { TableAssignOptionsInterface } from './interfaces/TableAssignOptionsInterface';
import { TableAssignOptionsContainerInterface } from './interfaces/TableAssignOptionsContainerInterface';
import AssignPractisSetOptions from './components/AssignPractisSetOptions';
import DialogWrapper from '../../../DialogWrapper/DialogWrapper';
import { NudgeUser } from './components/NudgeUser';
import { NudgeWarning } from './components/NudgeWarning';
import { useLibraryPractisSetState } from '../../../../../features/library/store/states';
import { usePortableLabelsState } from '../../../../../features/portableLabels/store/states';
import { BulkActionConfirmation } from './components/BulkActionConfirmation';
import AssignPractisSetsAndDueDateOptions from './components/AssignPractisSetsAndDueDateOptions';

const Container = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: ${props => props.theme.Colors.white};
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
`;

const OptionContainer = styled(OutsideActionBox)`
    position: relative;
    width: 100%;
`;

const ContentArea = styled.div`
    max-height: 300px;
`;

const TableAssignOptionsMenu: FC<TableAssignOptionsInterface> = ({
    labels,
    practisSets,
    disabled,
    searchTerm,
    updatedLabels,
    company,
    onAssignLabelsSubmit,
    onNudgeSendButtonClicked,
    assignLabelsTitle,
    nudgeFromUser,
    onOpenAssignLabelAction,
    setAssignLabelsAction,
    disabledPractisSets,
    onExportClicked,
    exportButtonDisabled,
    practisSetName,
    showNudgeWarning,
    nudgeButtonDisabled,
    onArchiveSubmissionsSubmit,
    onResendInvitesSubmit,
    onRevokeSubmit,
    onRestoreSubmissionsSubmit,
    onDuplicateSubmit,
    assignLabelsPermissions,
    sendNudgePermissions,
    showExportPermissions,
    assignPractisSetsAndDueDatePermissions,
    assignPractisSetPermissions,
    archivePermissions,
    onUpdateDueDatesSubmit,
    isDueDatesDisabled,
    dueDatesPermissions,
    resendInvitesPermissions,
    revokePermissions,
    removePractisSetPermission,
    onRemovePractisSetSubmit,
    onRemindSubmit,
    showRemindPermission,
    nudgeTitle,
    remindDisabled,
    removeItemsDisabled,
    restorePermissions,
    removeItemTitle,
    duplicateItemTitle,
    duplicateItemPermissions,
    removeItemPermissions,
    onRemoveItemsSubmit,
    disabledLabel,
    hideHint,
    removeItemTooltip,
    isAssignLabelsDisabled,
    isArchiveDisabled,
    isRestoreDisabled,
    isRevokeDisabled,
    isResendDisabled,
    isDuplicateDisabled,
    isSelectAll,
    totalCount,
    selectedItems,
    searchQuery,
    successApplyPractisSetsCallback,
}) => {
    const initialSearchParams: SearchParams = {
        searchTerm: searchTerm,
        filters: [],
        orderBy: {},
        offset: 0,
        count: 0,
        numberOfPages: 0,
    };
    const { refreshSearchParams } = useSearchParamsState(initialSearchParams);

    const [showNudgeDialog, setShowNudgeDialog] = useState(false);
    const [showNudgeWarningDialog, setShowNudgeWarningDialog] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [
        showOptionType,
        setShowOptionType,
    ] = useState<TableAssignOptionTypes>();

    const nudgeCloseButtonClicked = () => {
        setShowNudgeDialog(false);
    };

    const nudgeOpenButtonClicked = () => {
        if (!disabled && !nudgeButtonDisabled) {
            if (!!showNudgeWarning) {
                setShowNudgeWarningDialog(true);
            } else {
                setShowNudgeDialog(true);
            }
        }
    };

    const onNudgeSendClicked = (data: any) => {
        if (onNudgeSendButtonClicked) {
            onNudgeSendButtonClicked(data);
            setShowNudgeDialog(false);
        }
    };

    const onNudgeWarningProceed = () => {
        setShowNudgeWarningDialog(false);
        setShowNudgeDialog(true);
    };

    useEffect(() => {
        if (updatedLabels) refreshSearchParams();
    }, [updatedLabels, refreshSearchParams, company]);

    const TableAssignOptionMenu: Array<TableAssignOptionsMenuItem> = [
        {
            name: 'Due Date',
            value: TableAssignOptionTypes.DUE_DATES,
            permissions: dueDatesPermissions || [],
            show: !!dueDatesPermissions && !!onUpdateDueDatesSubmit,
            disabled: !!disabled || !!isDueDatesDisabled,
            destructive: false,
            dataTest: 'due-date-table-action',
        },
        {
            name: 'Assign Practis Sets',
            value: TableAssignOptionTypes.ASSIGN_PRACTIS_SETS_AND_DUE_DATE,
            permissions: assignPractisSetsAndDueDatePermissions || [],
            show: !!assignPractisSetsAndDueDatePermissions,
            disabled: !!disabled,
            dataTest: 'assign-practis-sets-table-action',
        },
        {
            name: assignLabelsTitle || 'Assign Labels',
            value: TableAssignOptionTypes.ASSIGN_LABELS,
            permissions: assignLabelsPermissions || [],
            show: !!assignLabelsPermissions,
            disabled:
                !!disabled ||
                !labels ||
                (!!labels && !!labels.items && !labels.items.length) ||
                !!isAssignLabelsDisabled,
            dataTest: 'assign-labels-table-action',
        },
        {
            name: 'Practis Set',
            value: TableAssignOptionTypes.ASSIGN_PRACTIS_SETS,
            permissions: assignPractisSetPermissions || [],
            show: !!assignPractisSetPermissions,
            disabled:
                !!disabled ||
                !practisSets ||
                (!!practisSets &&
                    !!practisSets.items &&
                    !practisSets.items.length),
            dataTest: 'practis-set-table-action',
        },
        {
            name: nudgeTitle || 'Nudge Users',
            value: TableAssignOptionTypes.NUDGE,
            permissions: sendNudgePermissions || [],
            show: !!sendNudgePermissions,
            disabled: !!nudgeButtonDisabled || !!disabled,
            dataTest: 'nudge-users-table-action',
        },
        {
            name: 'Remind to Start',
            value: TableAssignOptionTypes.REMIND,
            permissions: showRemindPermission || [],
            show: !!showRemindPermission,
            disabled: !!disabled || !!remindDisabled,
            dataTest: 'remind-to-start-table-action',
        },
        {
            name: 'Export Report',
            value: TableAssignOptionTypes.EXPORT,
            permissions: showExportPermissions || [],
            show: !!showExportPermissions,
            disabled: !!exportButtonDisabled || !!disabled,
            dataTest: 'export-report-table-action',
        },
        {
            name: 'Resend Invites',
            value: TableAssignOptionTypes.RESEND_INVITES,
            permissions: resendInvitesPermissions || [],
            show: !!resendInvitesPermissions,
            disabled: !!disabled || !!isResendDisabled,
            dataTest: 'resend-invites-table-action',
        },
        {
            name: 'Revoke',
            value: TableAssignOptionTypes.REVOKE,
            permissions: revokePermissions || [],
            show: !!revokePermissions,
            disabled: !!disabled || !!isRevokeDisabled,
            destructive: true,
            hint:
                !!disabled || !!isRevokeDisabled
                    ? "You can't revoke the admins' invitations"
                    : undefined,
            dataTest: 'revoke-table-action',
        },
        {
            name: 'Unassign Practis Sets',
            value: TableAssignOptionTypes.REMOVE_PRACTIS_SET,
            permissions: removePractisSetPermission || [],
            show: !!removePractisSetPermission,
            disabled: !!disabled,
            destructive: true,
            dataTest: 'unassign-practis-sets-table-action',
        },
        {
            name: duplicateItemTitle ? duplicateItemTitle : 'Duplicate',
            value: TableAssignOptionTypes.DUPLICATE,
            permissions: duplicateItemPermissions || [],
            show: !!duplicateItemPermissions,
            disabled: !!disabled || !!isDuplicateDisabled,
            dataTest: 'duplicate-table-action',
        },
        {
            name: 'Restore',
            value: TableAssignOptionTypes.RESTORE,
            permissions: restorePermissions || [],
            show: !!restorePermissions && !!onRestoreSubmissionsSubmit,
            disabled: !!disabled || !!isRestoreDisabled,
            dataTest: 'restore-table-action',
        },
        {
            name: removeItemTitle ? removeItemTitle : 'Delete Items',
            value: TableAssignOptionTypes.REMOVE_ITEMS,
            permissions: removeItemPermissions || [],
            show: !!removeItemPermissions,
            disabled: !!removeItemsDisabled || !!disabled,
            hint: !!removeItemsDisabled ? removeItemTooltip : '',
            destructive: true,
            dataTest: 'delete-table-action',
        },
        {
            name: 'Archive',
            value: TableAssignOptionTypes.ARCHIVE,
            permissions: archivePermissions || [],
            show: !!archivePermissions && !!onArchiveSubmissionsSubmit,
            disabled: !!disabled || !!isArchiveDisabled,
            destructive: true,
            dataTest: 'archive-table-action',
        },
    ];

    const performSwitch = (value: string) => {
        switch (value) {
            case TableAssignOptionTypes.ASSIGN_LABELS:
                onAssignLabelsSubmit && onAssignLabelsSubmit();
                break;
            case TableAssignOptionTypes.ASSIGN_PRACTIS_SETS:
                break;
            case TableAssignOptionTypes.NUDGE:
                onNudgeSendClicked && onNudgeSendClicked(payload);
                break;
            case TableAssignOptionTypes.REMIND:
                !!onRemindSubmit && onRemindSubmit();
                break;
            case TableAssignOptionTypes.ARCHIVE:
                !!onArchiveSubmissionsSubmit && onArchiveSubmissionsSubmit();
                break;
            case TableAssignOptionTypes.RESTORE:
                !!onRestoreSubmissionsSubmit && onRestoreSubmissionsSubmit();
                break;
            case TableAssignOptionTypes.RESEND_INVITES:
                !!onResendInvitesSubmit && onResendInvitesSubmit();
                break;
            case TableAssignOptionTypes.REVOKE:
                !!onRevokeSubmit && onRevokeSubmit();
                break;
            case TableAssignOptionTypes.REMOVE_PRACTIS_SET:
                !!onRemovePractisSetSubmit && onRemovePractisSetSubmit();
                break;
            case TableAssignOptionTypes.DUPLICATE:
                !!onDuplicateSubmit && onDuplicateSubmit();
                break;
            case TableAssignOptionTypes.REMOVE_ITEMS:
                !!onRemoveItemsSubmit && onRemoveItemsSubmit();
                break;
            case TableAssignOptionTypes.DUE_DATES:
                !!onUpdateDueDatesSubmit && onUpdateDueDatesSubmit();
                break;
            default:
                setShowOptions(false);
                setPayload(null);
        }
    };

    const handleMenuChange = (event: any) => {
        const value =
            event && event.target && event.target.value
                ? event.target.value
                : '';
        if (!value) return;

        switch (value) {
            case TableAssignOptionTypes.ASSIGN_LABELS:
                if (labels && labels.items && labels.items.length) {
                    onOpenAssignLabelAction?.();
                    setShowOptions(true);
                    setShowOptionType(value);
                }
                break;
            case TableAssignOptionTypes.ASSIGN_PRACTIS_SETS:
                if (
                    practisSets &&
                    practisSets.items &&
                    practisSets.items.length
                ) {
                    setShowOptions(true);
                    setShowOptionType(value);
                }
                break;
            case TableAssignOptionTypes.ASSIGN_PRACTIS_SETS_AND_DUE_DATE:
                setShowOptions(true);
                setShowOptionType(value);

                break;
            case TableAssignOptionTypes.NUDGE:
                nudgeOpenButtonClicked();
                break;
            case TableAssignOptionTypes.EXPORT:
                !!onExportClicked && onExportClicked();
                break;
            case TableAssignOptionTypes.DUE_DATES:
                !!onUpdateDueDatesSubmit && onUpdateDueDatesSubmit();
                break;
            default:
                if (isSelectAll) {
                    setAction(value);
                } else {
                    performSwitch(value);
                }
                break;
        }
    };

    const [action, setAction] = useState<string | null>(null);
    const [payload, setPayload] = useState<any>(null);

    return (
        <Container>
            {!!action && (
                <BulkActionConfirmation
                    onClose={() => setAction(null)}
                    onProceed={() => {
                        performSwitch(action);
                        setAction(null);
                    }}
                    itemsCount={totalCount || 0}
                />
            )}
            <TableAssignOptionsSelectDropdown
                items={TableAssignOptionMenu}
                onChange={handleMenuChange}
                showUpArrow={showOptions}
                disabled={disabled}
                disabledLabel={disabledLabel}
                hideHint={hideHint}
            />
            <OptionContainer open={showOptions}>
                <ContentArea>
                    {showOptionType ===
                        TableAssignOptionTypes.ASSIGN_LABELS && (
                        <AssignLabelOptions
                            disabled={disabled}
                            onAssignLabelsSubmit={() => {
                                onAssignLabelsSubmit && onAssignLabelsSubmit();
                            }}
                            setAssignLabelsAction={setAssignLabelsAction}
                            setShowOptions={setShowOptions}
                        />
                    )}

                    {showOptionType ===
                        TableAssignOptionTypes.ASSIGN_PRACTIS_SETS && (
                        <AssignPractisSetOptions
                            disabled={disabledPractisSets}
                            setShowOptions={setShowOptions}
                        />
                    )}

                    {showOptionType ===
                        TableAssignOptionTypes.ASSIGN_PRACTIS_SETS_AND_DUE_DATE && (
                        <AssignPractisSetsAndDueDateOptions
                            users={selectedItems}
                            searchQuery={searchQuery}
                            isSelectAll={isSelectAll}
                            setShowOptions={setShowOptions}
                            successApplyPractisSetsCallback={
                                successApplyPractisSetsCallback
                            }
                            totalCount={totalCount}
                        />
                    )}
                </ContentArea>
            </OptionContainer>

            {showNudgeDialog && (
                <DialogWrapper
                    customization={{
                        width: 480,
                        padding: '31px 32px 32px 40px',
                    }}
                >
                    <NudgeUser
                        fromUser={nudgeFromUser}
                        practisSetName={practisSetName}
                        onSend={data => {
                            if (isSelectAll) {
                                setAction(TableAssignOptionTypes.NUDGE);
                                setPayload(data);
                                nudgeCloseButtonClicked();
                            } else {
                                onNudgeSendClicked && onNudgeSendClicked(data);
                            }
                        }}
                        onClose={nudgeCloseButtonClicked}
                    />
                </DialogWrapper>
            )}
            {showNudgeWarningDialog && (
                <DialogWrapper
                    customization={{
                        width: 400,
                        padding: '36px 32px 35px 32px',
                    }}
                >
                    <NudgeWarning
                        onProceed={onNudgeWarningProceed}
                        onClose={() => setShowNudgeWarningDialog(false)}
                    />
                </DialogWrapper>
            )}
        </Container>
    );
};

export const TableAssignOptionsMenuContainer: FC<TableAssignOptionsContainerInterface> = ({
    disabled,
    renderAdditionalAction,
    onOpenAssignLabelAction,
    onAssignLabelsSubmit,
    onNudgeSendButtonClicked,
    assignLabelsTitle,
    assignLabelsMinWidth,
    hideSelectAll,
    assignedLabelsCount,
    disabledPractisSets,
    assignLabelButtonTitle,
    onExportClicked,
    exportButtonDisabled,
    practisSetName,
    showNudgeWarning,
    nudgeButtonDisabled,
    onArchiveSubmissionsSubmit,
    onUpdateDueDatesSubmit,
    onResendInvitesSubmit,
    onRevokeSubmit,
    onRemindSubmit,
    onRestoreSubmissionsSubmit,
    onDuplicateSubmit,
    assignLabelsPermissions,
    sendNudgePermissions,
    showExportPermissions,
    assignPractisSetsAndDueDatePermissions,
    assignPractisSetPermissions,
    archivePermissions,
    resendInvitesPermissions,
    revokePermissions,
    removePractisSetPermission,
    duplicateItemPermissions,
    onRemovePractisSetSubmit,
    showRemindPermission,
    restorePermissions,
    nudgeTitle,
    remindDisabled,
    removeItemsDisabled,
    removeItemTitle,
    removeItemPermissions,
    onRemoveItemsSubmit,
    disabledLabel,
    hideHint,
    duplicateItemTitle,
    removeItemTooltip,
    isAssignLabelsDisabled,
    isArchiveDisabled,
    isDueDatesDisabled,
    isRestoreDisabled,
    isRevokeDisabled,
    isResendDisabled,
    isDuplicateDisabled,
    isSelectAll,
    dueDatesPermissions,
    totalCount,
    selectedItems,
    searchQuery,
    successApplyPractisSetsCallback,
}) => {
    const state = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const practisState = useLibraryPractisSetState();
    const labelsConfigState = useLabelsConfigState();
    const updatedLabels = useUpdatedLabelsState();
    const company = useSelector(getCompanyState);
    const profile = useSelector(getProfileState);
    const setAssignLabelsAction = useSetAssignLabelsActionService();

    return (
        <TableAssignOptionsMenu
            labels={labelsList}
            practisSets={practisState.fullList}
            updatedLabels={updatedLabels.data}
            disabled={disabled}
            searchTerm={labelsConfigState.searchTerm}
            renderAdditionalAction={renderAdditionalAction}
            company={company}
            onOpenAssignLabelAction={onOpenAssignLabelAction}
            onAssignLabelsSubmit={onAssignLabelsSubmit}
            nudgeFromUser={
                !!profile ? `${profile.firstName} ${profile.lastName}` : ''
            }
            onNudgeSendButtonClicked={onNudgeSendButtonClicked}
            assignLabelsTitle={assignLabelsTitle}
            assignLabelsMinWidth={assignLabelsMinWidth}
            hideSelectAll={hideSelectAll}
            assignedLabelsCount={assignedLabelsCount}
            setAssignLabelsAction={setAssignLabelsAction}
            disabledPractisSets={disabledPractisSets}
            assignLabelButtonTitle={assignLabelButtonTitle}
            onExportClicked={onExportClicked}
            exportButtonDisabled={exportButtonDisabled}
            practisSetName={practisSetName}
            showNudgeWarning={showNudgeWarning}
            nudgeButtonDisabled={nudgeButtonDisabled}
            onArchiveSubmissionsSubmit={onArchiveSubmissionsSubmit}
            onUpdateDueDatesSubmit={onUpdateDueDatesSubmit}
            onRemindSubmit={onRemindSubmit}
            onRestoreSubmissionsSubmit={onRestoreSubmissionsSubmit}
            onDuplicateSubmit={onDuplicateSubmit}
            assignLabelsPermissions={assignLabelsPermissions}
            sendNudgePermissions={sendNudgePermissions}
            showExportPermissions={showExportPermissions}
            assignPractisSetsAndDueDatePermissions={assignPractisSetsAndDueDatePermissions}
            assignPractisSetPermissions={assignPractisSetPermissions}
            archivePermissions={archivePermissions}
            duplicateItemPermissions={duplicateItemPermissions}
            resendInvitesPermissions={resendInvitesPermissions}
            dueDatesPermissions={dueDatesPermissions}
            revokePermissions={revokePermissions}
            onResendInvitesSubmit={onResendInvitesSubmit}
            onRevokeSubmit={onRevokeSubmit}
            removePractisSetPermission={removePractisSetPermission}
            onRemovePractisSetSubmit={onRemovePractisSetSubmit}
            showRemindPermission={showRemindPermission}
            nudgeTitle={nudgeTitle}
            remindDisabled={remindDisabled}
            restorePermissions={restorePermissions}
            assignedLabels={state.assignedLabels}
            removeItemsDisabled={removeItemsDisabled}
            removeItemTitle={removeItemTitle}
            onRemoveItemsSubmit={onRemoveItemsSubmit}
            removeItemPermissions={removeItemPermissions}
            disabledLabel={disabledLabel}
            hideHint={hideHint}
            duplicateItemTitle={duplicateItemTitle}
            removeItemTooltip={removeItemTooltip}
            isAssignLabelsDisabled={isAssignLabelsDisabled}
            isArchiveDisabled={isArchiveDisabled}
            isDueDatesDisabled={isDueDatesDisabled}
            isRestoreDisabled={isRestoreDisabled}
            isRevokeDisabled={isRevokeDisabled}
            isResendDisabled={isResendDisabled}
            isDuplicateDisabled={isDuplicateDisabled}
            isSelectAll={isSelectAll}
            totalCount={totalCount}
            selectedItems={selectedItems}
            searchQuery={searchQuery}
            successApplyPractisSetsCallback={successApplyPractisSetsCallback}
        />
    );
};

export default TableAssignOptionsMenuContainer;
