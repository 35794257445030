import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { useHistory } from '../../tools/router';
import { useShowMessage } from '../../ui/components/ErrorMessages/ErrorMessages';
import {
    CREATE_CHALLENGE_ACTION,
    UPDATE_CHALLENGE_ACTION,
    UPDATE_CHALLENGE_STATUS_ACTION,
} from '../library/services/LibraryBulkActionsService/constants';
import {
    Challenge,
    ChallengeStatuses,
} from '../../constants/interfaces/Challenge';
import { updateLibraryChallengeSuccess } from '../library/store/actions';
import { StatusItems } from './pages/EditChallenge';
import ROUTES from '../../routes/routes';
import { useModifyChallengeService } from './store/services';
import { ErrorResult } from '../../constants/interfaces/ErrorResult';
import { useUpdateChallengeStatusApi } from '../../api';

/**
 * @function useCreateEditChallengeSuccessCallback
 * @param { Function } setLoadingSave
 * @param { Record<string, unknown> | undefined } responses
 * @returns { void }
 */
export const useCreateEditChallengeSuccessCallback = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const showMessage = useShowMessage();

    return useCallback(
        (
            setLoadingSave: (isLoading: boolean) => void,
            responses?: Record<string, unknown>
        ) => {
            setLoadingSave(false);

            if (!isEmpty(responses)) {
                const createChallengeResponse = responses![
                    CREATE_CHALLENGE_ACTION
                ] as Challenge | undefined;

                const editChallengeResponse = responses![
                    UPDATE_CHALLENGE_ACTION
                ] as Challenge | undefined;

                const updateChallengeResponse = responses![
                    UPDATE_CHALLENGE_STATUS_ACTION
                ] as Challenge | undefined;
                if (createChallengeResponse) {
                    dispatch(
                        updateLibraryChallengeSuccess(
                            createChallengeResponse,
                            'update'
                        )
                    );

                    const currentStatus = !isEmpty(updateChallengeResponse)
                        ? ChallengeStatuses.ACTIVE
                        : ChallengeStatuses.DRAFT;

                    const actionMessage = StatusItems.filter(
                        (item: any) => item.value === currentStatus
                    );
                    showMessage(actionMessage[0].message, 'success');
                } else if (editChallengeResponse) {
                    dispatch(
                        updateLibraryChallengeSuccess(
                            editChallengeResponse,
                            'update'
                        )
                    );
                }
            }

            history.push(ROUTES.LIBRARY_SETTINGS.CHALLENGES.ALL);
        },
        [dispatch, history, showMessage]
    );
};

/**
 * @function useCreateEditChallengeFailedCallback
 * @param { Function } setIsLoading
 * @param { ErrorResult | undefined } error
 * @param { Record<string, unknown> | undefined } completedResponses
 * @returns { void }
 */
export const useCreateEditChallengeFailedCallback = () => {
    const modifyChallenge = useModifyChallengeService();
    const showMessage = useShowMessage();

    return useCallback(
        (
            setLoadingSave: (isLoading: boolean) => void,
            error?: ErrorResult,
            completedResponses?: Record<string, unknown>
        ) => {
            if (!isEmpty(completedResponses)) {
                const createChallengeResponse = completedResponses![
                    CREATE_CHALLENGE_ACTION
                ] as Challenge | undefined;

                const createdChallengeId = createChallengeResponse?.id;

                // The bulk action failed in other steps
                // but new challenge created
                // in the next try it goes to
                // edit challenge action.
                if (createdChallengeId) {
                    modifyChallenge(createdChallengeId, 'id');
                }
            }

            error?.message && showMessage(error.message, 'error');
            setLoadingSave(false);
        },
        [modifyChallenge, showMessage]
    );
};

/**
 * @function useHandleArchiveChallenge
 * @returns { Promise<void> }
 */
export const useHandleArchiveChallenge = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const updateChallengeStatus = useUpdateChallengeStatusApi();

    return useCallback(
        (challengeId: number) => {
            updateChallengeStatus('archive', [challengeId]).then(response => {
                dispatch(updateLibraryChallengeSuccess(response, 'update'));

                history.goBack();
            });
        },
        [dispatch, history, updateChallengeStatus]
    );
};

/**
 * @function useHandleRestoreArchivedChallenge
 * @param { number } challengeId
 * @returns { void }
 */
export const useHandleRestoreArchivedChallenge = () => {
    const dispatch = useDispatch();
    const modifyChallenge = useModifyChallengeService();
    const updateChallengeStatus = useUpdateChallengeStatusApi();

    return useCallback(
        (challengeId?: number) => {
            if (challengeId) {
                updateChallengeStatus('draft', [challengeId]).then(
                    (response: Challenge) => {
                        dispatch(
                            updateLibraryChallengeSuccess(response, 'update')
                        );
                        modifyChallenge(
                            ChallengeStatuses.DRAFT,
                            'status',
                            true
                        );
                    }
                );
            }
        },
        [dispatch, modifyChallenge, updateChallengeStatus]
    );
};