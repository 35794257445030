import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Variables } from '../../../theme/variables';

interface Props {
    allItemsSelected?: boolean;
    disabled?: boolean;
    onToggleAllItems?(allItemsSelected?: boolean): void;
}

export const FiltersToggleAllButton: FC<Props> = props => {
    const {
        allItemsSelected,
        disabled,
        onToggleAllItems,
    } = props;

    const clickHandler = useCallback(
        () => {
            if (!disabled && onToggleAllItems) {
                onToggleAllItems(!!allItemsSelected);
            }
        },
        [disabled, allItemsSelected, onToggleAllItems]
    ); 

    return (
        <Root
            disabled={disabled}
            colorsId={allItemsSelected ? 'unselect' : 'select'}
            onClick={clickHandler}
            data-test="filters-toggle-select-button"
        >
            {allItemsSelected ? 'Unselect All' : 'Select All'}
        </Root>
    );
};

type ColorsId = 'select' | 'unselect';

type ColorsInfo = {
    color: string;
    colorHover: string;
    colorActive: string;
};

const colorsInfos: Record<ColorsId, ColorsInfo> = {
    select: {
        color: Variables.Colors.darkSkyBlue,
        colorHover: Variables.Colors.lightBlue,
        colorActive: Variables.Colors.windowsBlue,
    },
    unselect: {
        color: Variables.Colors.darkSkyBlue,
        colorHover: Variables.Colors.lightBlue,
        colorActive: Variables.Colors.windowsBlue,
    },
};

const Root = styled.span<{
    colorsId: ColorsId;
    disabled?: boolean;
}>`
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${colorsInfos[props.colorsId].color};
              `}

    &:hover {
        color: ${props => colorsInfos[props.colorsId].colorHover};
    }
    &:active {
        color: ${props => colorsInfos[props.colorsId].colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;