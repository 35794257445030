import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Roleplay } from '../../../../constants/interfaces/Roleplay';

export interface RoleplayState {
    data?: Roleplay;
    isLoading: boolean;
    error?: string;
}

export type RoleplayStateSelector = (state: any) => RoleplayState;

export const RoleplayStateSelectorContext = React.createContext<RoleplayStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useRoleplayState(): RoleplayState {
    return useSelector(useContext(RoleplayStateSelectorContext));
}
