import styled from 'styled-components';

export const Header = styled.div`
    height: 145px;
    border-bottom: 1px solid var(--ps-grey-4);
    padding-left: 16px;
    padding-top: 32px;
`;

export const Title = styled.div`
    width: 137px;
    height: 8px;
    background-color: var(--ps-grey-4);
`;

export const SubTitle = styled.div`
    width: 296px;
    height: 12px;
    background-color: var(--ps-grey-4);
    margin-top: 20px;
`;

export const Content = styled.div`
    padding: 22px 24px 0 10px;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const UserImage = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 8px;
    background-color: var(--ps-grey-4);
`;

export const UserText = styled.div`
    width: 162px;
    height: 8px;
    background-color: var(--ps-grey-4);
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`;

export const ThumbnailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Thumbnail = styled.div`
    width: 416px;
    height: 416px;
    border-radius: 8px;
    background-color: var(--ps-grey-4);
    display: flex;
    align-items: center;
`

export const ThumbnailTitle = styled.div`
    width: 312px;
    height: 8px;
    margin-top: 16px;
    background-color: var(--ps-grey-4);
`;

export const ThumbnailDescription = styled.div`
    width: 244px;
    height: 8px;
    margin-top: 12px;
    background-color: var(--ps-grey-4);
`;

export const StatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 34px;
    flex: 54 1 0%;
`;

export const Stats = styled.div`
    border-radius: 8px;
    border: 1px solid var(--ps-grey-4);
    box-shadow: rgb(0 0 0 / 5%) 0px 3px 10px 0px;
    width: 100%;
    height: 591px;
    padding: 48px 33px;
`

export const StatsTitle = styled.div`
    width: 133px;
    height: 12px;
    background-color: var(--ps-grey-4);
    margin-left: 15px;
`;

export const StatsScore = styled.div`
    width: 63px;
    height: 32px;
    background-color: var(--ps-grey-4);
    margin-top: 21px;
    margin-left: 15px;
`;

export const StatsDelimiter = styled.div`
    height: 2px;
    background-color: var(--ps-grey-4);
    width: 100%;
    margin-top: 34px;
    margin-bottom: 10px;
`;

export const StatsItem = styled.div`
    padding: 0 25px 0 15px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StatsItemTitle = styled.div`
    width: 100px;
    height: 8px;
    background-color: var(--ps-grey-4);
    margin-right: 49px;
`;

export const StatsItemPoints = styled.div`
    flex: 1 0 0%;
    display: flex;
`;

export const StatsItemLine = styled.div`
    width: 100%;
    position: relative;
    height: 2px;
    background-color: var(--ps-grey-4);
    z-index: 1;
`;

export const StatsItemPoint = styled.div<{ index: number }>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--ps-white-4);
    border: 1px solid var(--ps-grey-2);
    position: absolute;
    z-index: 2;
    left: calc(${props => `${props.index * 25}% - 8px`});
    top: -7px;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 24px;
`;

export const WhiteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    border-radius: 4px;
    border: solid 2px var(--ps-grey-4);
    background-color: var(--ps-white-1);
`;

export const WhiteButtonTitle = styled.div`
    width: 85px;
    height: 8px;
    background-color: var(--ps-grey-4);
`;


export const GreyButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 40px;
    border-radius: 4px;
    border: solid 2px var(--ps-grey-4);
    background-color: var(--ps-grey-4);
    margin-left: 24px;
`;

export const GreyButtonTitle = styled.div`
    width: 85px;
    height: 8px;
    background-color: var(--ps-white-1);
`;