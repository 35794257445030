import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const AddUser = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="currentColor" fillRule="evenodd">
                    <g fill="#currentColor">
                        <g>
                            <path
                                fillRule="evenodd"
                                d="M23.253 21h-2.25v2.25c0 .414-.336.75-.75.75s-.75-.336-.75-.75V21h-2.25c-.414 0-.75-.336-.75-.75 0-.415.336-.75.75-.75h2.25v-2.25c0-.415.336-.751.75-.751s.75.336.75.751v2.25h2.25c.414 0 .75.335.75.75 0 .414-.336.75-.75.75zm-8.637-5.754c.771.192 1.897.482 3.014.795-.525.358-.974.81-1.36 1.313-.908-.244-1.86-.488-2.767-.706v-2.376c1.881-1.28 2.996-4.06 2.996-6.588 0-3.418-.875-6.19-5.248-6.19s-5.247 2.772-5.247 6.19c0 2.53 1.116 5.313 3 6.592v2.394c-2.837.667-6.13 1.581-6.887 1.996-.49.618-.918 2.597-.614 3.835h13.712c.138.533.349 1.033.619 1.498H.677c-1.173-1.8-.697-5.399.476-6.597.558-.57 4.747-1.65 6.751-2.142-2.117-1.645-3.407-4.695-3.407-7.604C4.497 3.428 5.725 0 11.25 0c5.527 0 6.755 3.428 6.755 7.656 0 2.9-1.284 5.942-3.39 7.59z"
                                transform="translate(-392 -692) translate(392 692)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default AddUser;
