import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { PaginationResult, ListResult } from '../../../constants/interfaces/PaginationResult';
import { UserV2 } from '../../../constants/interfaces/User';
import {
    EnrollmentDailyTraining,
    EnrollmentInterface,
    EnrollmentProgress
} from '../../../constants/interfaces/Enrollments';
import { Team, TeamV2 } from '../../../constants/interfaces/Team';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { AppState } from '../../../store/reducers';
import { StateWithLabels } from '../../portableLabels/store/hors/withLabels';
import { TeamMember } from '../../../constants/interfaces/TeamMember';
import { TeamLeader } from '../../../constants/interfaces/TeamLeader';
import { TrainingPractisSet } from '../../../constants/interfaces/TrainingPractisSet';
import { selectItemsByIds } from '../../../tools/redux';
import { isEqual } from 'lodash';

export interface TeamLeadersState {
    data?: PaginationResult<TeamLeader>;
    selected: number[];
    saved: number[];
}

export interface TeamsState {
    myTeams?: ListResult<Team>;
    selectedMyTeamsItems?: number[];
    teamLeaders: TeamLeadersState;
    loading?: boolean;
    error?: string;
}

export type TeamsStateSelector = (state: any) => TeamsState;

export const TeamsStateSelectorContext = React.createContext<TeamsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useTeamsState(): TeamsState {
    return useSelector(useContext(TeamsStateSelectorContext), isEqual);
}

export interface UpdateTeamsState {
    data?: any;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdateTeamsStateStateSelector = (state: any) => UpdateTeamsState;

export const UpdateTeamsStateStateSelectorContext = React.createContext<UpdateTeamsStateStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdateTeamsState(): UpdateTeamsState {
    return useSelector(useContext(UpdateTeamsStateStateSelectorContext));
}

export enum TeamUpdateStatus {
    IN_PROGRESS = 'inProgress',
    COMPLETED = 'completed',
    ERROR = 'error'
}

export interface TeamEditState {
    data?: TeamV2;
    allUsers?: ListResult<UserV2>;
    teamMembers?: ListResult<TeamMember>;
    assignedLabels: number[];
    deletedLabels: number[];
    updatedTeam?: Partial<TeamV2>;
    loading: boolean;
    error?: string;
    updateStatus?: TeamUpdateStatus
}

export type TeamEditSelector = (state: any) => TeamEditState;

export const TeamEditSelectorContext = React.createContext<TeamEditSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useTeamEditState(): TeamEditState {
    return useSelector(useContext(TeamEditSelectorContext));
}

//region Team members
export type TeamMembersStateSelector = (state: AppState) => TeamMembersState;

export type TeamMembersBaseState = {
    itemsOnPage: number;
    list: ListResult<TeamMember>;
    data?: TeamV2;
    loading: boolean;
    error: string | null;
};

export interface UpdateMembersState {
    data?: any;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdateMembersStateStateSelector = (
    state: any
) => UpdateMembersState;

export const UpdateMembersStateStateSelectorContext = React.createContext<UpdateMembersStateStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdateMembersState(): UpdateMembersState {
    return useSelector(useContext(UpdateMembersStateStateSelectorContext));
}

export type TeamMembersState = StateWithLabels & TeamMembersBaseState;

export const TeamMembersStateSelectorContext = React.createContext<TeamMembersStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useTeamMembersState(): TeamMembersState {
    return useSelector(useContext(TeamMembersStateSelectorContext));
}
//endregion

export interface ProgressState {
    updatedProgress: UpdatedProgressState;
    enrollments: EnrollmentState;
    report: PractisSetReportState;
}

export interface PractisSetsTrainingState {
    data?: ListResult<TrainingPractisSet>;
    teamData?: TeamV2;
    selectedPractisSets?: number[];
    checked: number[];
    loading?: boolean;
    error?: string;
}

export type PractisSetsStateSelector = (state: any) => PractisSetsTrainingState;

export const TrainerStateSelectorContext = React.createContext<PractisSetsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useTrainerPractisSetState(): PractisSetsTrainingState {
    return useSelector(useContext(TrainerStateSelectorContext));
}

export interface UpdatedProgressState {
    data?: PractisSets;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdatedProgressStateSelector = (state: any) => UpdatedProgressState;

export const UpdatedProgressStateSelectorContext = React.createContext<UpdatedProgressStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdatedProgressState(): UpdatedProgressState {
    return useSelector(useContext(UpdatedProgressStateSelectorContext));
}

export interface EnrollmentState {
    data?: PaginationResult<EnrollmentInterface>;
    loading?: boolean;
    practisSet?: PractisSets;
    update?: Array<'invite' | 'reInvite' | 'unassign'>;
    error?: string;
}

export type EnrollmentStateSelector = (state: any) => EnrollmentState;

export const EnrollmentStateSelectorContext = React.createContext<EnrollmentStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useEnrollmentState(): EnrollmentState {
    return useSelector(useContext(EnrollmentStateSelectorContext));
}

export function selectSelectedProgresses(state: EnrollmentState, selectedProgressIds: number[] | undefined): EnrollmentInterface[] {
    return selectItemsByIds<EnrollmentInterface>(selectedProgressIds, state?.data?.items);
}

export interface AssignUsersState {
    data?: ListResult<UserV2 & {dueDate?: Date}>;
    selectedUsers?: number[];
    loading?: boolean;
    update?: Array<'invite' | 'reInvite' | 'unassign'>;
    error?: string;
}

export type TrainerInvitationStateSelector = (state: any) => AssignUsersState;

export const TrainerInvitationStateSelectorContext = React.createContext<TrainerInvitationStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useTrainerInvitationState(): [AssignUsersState, Dispatch<any>] {
    const selector = useContext(TrainerInvitationStateSelectorContext);
    const state = useSelector(selector);
    const dispatch = useDispatch();
    return [state, dispatch];
}

export interface PractisSetReportState {
    enrollment?: EnrollmentInterface;
    progress?: ListResult<EnrollmentProgress>;
    dailyTraining?: EnrollmentDailyTraining[];
    loading?: boolean;
    error?: string;
}

export type PractisSetReportStateSelector = (
    state: any
) => PractisSetReportState;

export const PractisSetReportStateSelectorContext = React.createContext<PractisSetReportStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function usePractisSetReportState(): PractisSetReportState {
    return useSelector(useContext(PractisSetReportStateSelectorContext));
}
