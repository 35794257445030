import { Field, Form, Formik } from 'formik';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { UserV2 } from '../../../constants/interfaces/User';
import Validation from '../../../services/validation';
import { StyledActionsDivs, StyledActionsLinks } from '../../Auth/styles';
import { Content, PhotoLoadingContainer } from './styles';
import { Variables } from '../../../theme/variables';
import UploadPhoto from '../../../ui/components/UploadPhoto/UploadPhoto';
import LinkText from '../../../ui/components/LinkText/link-text';
import { FormikField } from '../../../ui/components/FormikField';
import { Button } from '../../../ui/components/Button';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import { useShowConfirmModalDialog } from '../../../ui/components/ModalDialogs/store/actions';
import { isPractisAdminRole } from '../../../constants/enums';

const ProfilePageContainer = styled.div``;

const StyledContentHeader = styled.div``;

const UserInfoForm = styled.div`
    padding: 24px 0 0 0;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    border: none;
    margin: 0;
    flex-shrink: 0;
    background-color: ${props => props.theme.Colors.paleGrey};
`;

const StyledUploadPhoto = styled(UploadPhoto)`
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
`;

const StyledDeleteButton = styled.div`
    margin-right: 8px;
`;

const UploadPictureLink = styled(LinkText)`
    text-align: left;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    display: block;
`;

const StyledDescription = styled.div`
    width: 192px;
    height: 40px;
    color: ${props => props.theme.Colors.steelGrey};
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
`;

const StyledUploadContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

const StyledUploadLink = styled.div`
    margin-left: 38px;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

const NoticeWrapper = styled.div`
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
`;

const Notice = styled.div`
    width: calc(50% - 20px);
    height: 40px;
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
`;

const Separator = styled.div`
    height: 1px;
    width: 16px;
`;

interface Values {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email?: string;
}

export const ProfileInfo: FC<{
    profile: UserV2;
    editable?: boolean;
    updateUserProfile(
        data: Partial<UserV2>,
        userId: number
    ): void;
    uploadUserPicture(userId: number, file: File): void;
    deleteUser?(userId: number): void;
    self?: boolean;
    dataTest?: string;
    isPhotoUploading: boolean;
}> = ({
    profile,
    editable,
    updateUserProfile,
    uploadUserPicture,
    deleteUser,
    self,
    dataTest,
    isPhotoUploading,
}) => {
    let validationSchema;
    const isPractisAdmin = isPractisAdminRole(profile.role?.name);

    if (self && profile.phoneNumber && profile.phoneNumber.length > 0) {
        validationSchema = Yup.object().shape<Values>({
            firstName: Validation.firstName,
            lastName: Validation.lastName,
            phoneNumber: Validation.phoneNumber,
            email: Validation.email,
        });
    } else {
        validationSchema = Yup.object().shape<Values>({
            firstName: Validation.firstName,
            lastName: Validation.lastName,
        });
    }

    const handleUpload = useCallback(
        (e: any) => {
            e.persist();
            let file = e.target.files[0] as File;
            uploadUserPicture(profile.id!, file);
        },
        [profile, uploadUserPicture]
    );

   const showConfirmationModalDialog = useShowConfirmModalDialog();

    const handelDelete = useCallback(() => {
        showConfirmationModalDialog({
            modalTitle: 'Deactivate Practis Admin account?',
            description:
                'Deactivating this account can not be undone. Are you sure?',
            onConfirm: () => deleteUser?.(profile.id!),
            confirmButtonText: 'Yes, Deactivate',
        });
    }, [showConfirmationModalDialog, deleteUser, profile.id]);

    return (
        <ProfilePageContainer>
            <Content>
                <StyledContentHeader>
                    {editable && (
                        <StyledUploadContainer>
                            {isPhotoUploading &&
                                <PhotoLoadingContainer>
                                    <Loading size={30} />
                                </PhotoLoadingContainer>
                            }
                            <StyledUploadPhoto
                                marginTop="0px"
                                currentImage={
                                    profile?.imageUrl
                                }
                                handleChange={handleUpload}
                                showCustomDescription={true}
                                backgroundColor={Variables.Colors.whiteFive}
                                disabled={isPhotoUploading}
                                data-test={`${dataTest}-upload-photo`}
                            >
                                <StyledUploadLink>
                                    <UploadPictureLink
                                        dataTest={`${dataTest}-upload-text`}
                                    >
                                        Upload a new picture
                                    </UploadPictureLink>
                                    <StyledDescription
                                        data-test={`${dataTest}-upload-description`}
                                    >
                                        <div>JPG, PNG, BMP only.</div>
                                        <div>Less than 10 MB</div>
                                    </StyledDescription>
                                </StyledUploadLink>
                            </StyledUploadPhoto>
                        </StyledUploadContainer>
                    )}
                </StyledContentHeader>
                <Divider />
                <Formik<Values>
                    initialValues={{
                        firstName: profile.firstName ? profile.firstName : '',
                        lastName: profile.lastName ? profile.lastName : '',
                        phoneNumber: profile.phoneNumber
                            ? profile.phoneNumber
                            : '',
                        email: profile.email ? profile.email : '',
                    }}
                    enableReinitialize={true}
                    onSubmit={values => {
                        let sendValues;
                        if (
                            self &&
                            profile.phoneNumber !== values.phoneNumber
                        ) {
                            sendValues = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                phoneNumber: values.phoneNumber,
                            };
                        } else {
                            sendValues = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                            };
                        }
                        updateUserProfile(sendValues, profile.id!);
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue }) => {
                        const profilePhoneNumber = profile.phoneNumber
                            ? profile.phoneNumber
                            : '';
                        const valuesUpdated =
                            values.email !== profile.email ||
                            values.firstName !== profile.firstName ||
                            values.lastName !== profile.lastName ||
                            values.phoneNumber !== profilePhoneNumber;

                        const valuesMissing =
                            !values.firstName || !values.lastName;

                        return (
                            <Form>
                                <UserInfoForm>
                                    <InputWrapper>
                                        <Field
                                            type="text"
                                            component={FormikField}
                                            maxLength={100}
                                            name="firstName"
                                            label="First Name"
                                            disabled={isPractisAdmin || !editable}
                                            background={
                                                Variables.Colors.fadedRed
                                            }
                                        />
                                        <Separator />
                                        <Field
                                            type="text"
                                            component={FormikField}
                                            maxLength={100}
                                            name="lastName"
                                            label="Last Name"
                                            disabled={isPractisAdmin || !editable}
                                        />
                                    </InputWrapper>
                                    <InputWrapper>
                                        <Field
                                            type="email"
                                            component={FormikField}
                                            name="email"
                                            label="Email"
                                            disabled={true}
                                            background={Variables.Colors.white}
                                        />
                                        <Separator />
                                        <Field
                                            type="phone"
                                            component={FormikField}
                                            onChange={(e: string) => {
                                                setFieldValue(
                                                    'phoneNumber',
                                                    e ? e : ''
                                                );
                                            }}
                                            name="phoneNumber"
                                            label="Mobile Number"
                                            background={
                                                Variables.Colors.whiteFive
                                            }
                                            fontColor={Variables.Colors.black}
                                            labelColor={
                                                Variables.Colors.steelGrey
                                            }
                                            disabled={!editable || !self}
                                            dataTest={`${dataTest}-phone-number`}
                                        />
                                    </InputWrapper>
                                    {self && (
                                        <NoticeWrapper>
                                            <Notice>
                                                Updating your number requires
                                                verification via SMS. Carrier
                                                fees may apply.
                                            </Notice>
                                        </NoticeWrapper>
                                    )}
                                </UserInfoForm>

                                <Divider />
                                {editable && (
                                    <StyledActionsDivs justifyContent="initial">
                                        <Button
                                            height={'40px'}
                                            width={'120px'}
                                            disabled={
                                                !valuesUpdated || valuesMissing
                                            }
                                            dataTest={`${dataTest}-update`}
                                        >
                                            Update
                                        </Button>
                                        {!!deleteUser && (
                                            <StyledDeleteButton>
                                                <Button
                                                    height={'40px'}
                                                    width={'120px'}
                                                    type="button"
                                                    color="warning"
                                                    action={handelDelete}
                                                    dataTest={`${dataTest}-delete`}
                                                >
                                                    Deactivate
                                                </Button>
                                            </StyledDeleteButton>
                                        )}
                                        <StyledActionsLinks />
                                    </StyledActionsDivs>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </Content>
        </ProfilePageContainer>
    );
};

export const ProfileInfoContainer: FC<{
    profile: UserV2;
    editable: boolean;
    updateUserProfile(data: UserV2, userId: number): void;
    uploadUserPicture(userId: number, file: File): void;
    deleteUser?(userId: number): void;
    self?: boolean;
    dataTest?: string;
    isPhotoUploading: boolean;
}> = ({
    profile,
    editable,
    updateUserProfile,
    uploadUserPicture,
    deleteUser,
    self,
    dataTest,
    isPhotoUploading,
}) => {
    return (
        <ProfileInfo
            profile={profile}
            editable={editable}
            updateUserProfile={updateUserProfile}
            uploadUserPicture={uploadUserPicture}
            deleteUser={deleteUser}
            self={self}
            dataTest={dataTest}
            isPhotoUploading={isPhotoUploading}
        />
    );
};

export default ProfileInfoContainer;

