import { useCallback, useMemo } from 'react';
import { ChallengeAttemptsLimitFormValues, ChallengeAttemptsLimitProps } from './types';
import ChallengeAttemptsLimitView from './view';
import { useModifyChallengeService } from '../../../../store/services';

function ChallengeAttemptsLimit({
    challenge
}: ChallengeAttemptsLimitProps) {
    const modifyChallenge = useModifyChallengeService();

    const initialSettings = useMemo((): ChallengeAttemptsLimitFormValues => {
        return {
            isLimited: !!challenge.tryLimit,
            limitValue: challenge.tryLimit?.toString() || null
        };
    }, [challenge.tryLimit]);

    const isValidLimitValue = (value: string) => Number(value) > 0;

    const validateSettings = (settings: ChallengeAttemptsLimitFormValues) => {
        const errors: Record<string, string> = {};
        if (settings.isLimited && !settings.limitValue) {
            errors.limitValue = 'Limit value is required';
        }
        return errors;
    };

    const handleSubmit = useCallback((settings: ChallengeAttemptsLimitFormValues) => {
        modifyChallenge(
            settings.isLimited ? Number(settings.limitValue) : null,
            'tryLimit'
        );
    }, [modifyChallenge]);

    return (
        <ChallengeAttemptsLimitView
            initialSettings={initialSettings}
            isValidLimitValue={isValidLimitValue}
            validateSettings={validateSettings}
            onSubmit={handleSubmit}
        />
    );
}

export default ChallengeAttemptsLimit;