import styled from 'styled-components';

export const StyledCalendarSection = styled.div`
    width: 100%;
    padding: 0 18px 0 18px;
`;

export const StyledCalendarContainer = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;

    width: 310px;
    min-height: 326px;
    padding-top: 25px;

    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    background: var(--ps-white-1);

    .react-calendar__navigation .react-calendar__navigation__label {
        font-size: 18px;
        padding-left: 14px;
        padding-right: 14px;
        width: 200px;
    }

    .react-calendar__navigation__arrow react-calendar__navigation__prev-button, .react-calendar__navigation__arrow react-calendar__navigation__next-button {
        width: 6px;
        height: 12px;    
    }

    .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
        font-size: 15px;
        font-weight: 800;
    }

    .react-calendar__month-view__days .react-calendar__month-view__days__day {
        font-size: 15px;
        abbr {
            color: var(--ps-black-main);
        }
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
        abbr {
            color: var(--ps-black-main);
            border-bottom: 2px solid  var(--ps-black-main);
            width: 24px;
            height: 20px;
            background: none;
        }
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__tile--rangeEnd {
        background: var(--ps-blue-main);
        abbr {
            color: var(--ps-white-1);
            border-bottom: 2px solid var(--ps-white-1)
        };
    }

    .react-calendar__month-view__days .react-calendar__month-view__days__day:disabled {
        background: none;
        abbr {
            color: var(--ps-grey-2);
        }
    }

    .react-calendar__month-view__days .react-calendar__tile--range {
        abbr {
            color: var(--ps-white-1);
        }
    }
`;

export const ClearText = styled.div<{isDisabled: boolean}>`
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 500;
    cursor: ${({isDisabled}) => isDisabled ? 'default': 'pointer'};
    color: ${({isDisabled}) => isDisabled ? 'var(--ps-grey-2)': 'var(--ps-blue-main)'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
    width: 52px;
`;