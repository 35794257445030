import { FC } from 'react';

import { Button } from '../../../../Button';
import {
    NudgeWarningStyledDescription,
    StyledActions,
    StyledContent,
    StyledTitle,
} from './styles';

export const NudgeWarning: FC<{
    onProceed?(): void;
    onClose?(): void;
}> = ({ onProceed, onClose }) => {
    return (
        <>
            <StyledTitle>Reminder</StyledTitle>
            <StyledContent>
                <NudgeWarningStyledDescription>
                    Nudge messages will only be sent to selected trainees.
                    Selected trainers and admins will not be notified.
                </NudgeWarningStyledDescription>
                <StyledActions marginTop={30}>
                    <Button
                        width="128px"
                        height="48px"
                        action={onClose}
                        variant="inverse"
                    >
                        Go Back
                    </Button>
                    <Button
                        width="128px"
                        height="48px"
                        action={() => !!onProceed && onProceed()}
                    >
                        Proceed
                    </Button>
                </StyledActions>
            </StyledContent>
        </>
    );
};
