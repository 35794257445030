import { CompanyUserLimitViewProps } from './types';
import { Buttons, Container, TipText } from './styles';
import { CompanyUserStatsInfo } from '../../../../ui/components/CompanyUserStatsInfo';
import { Button } from '../../../../ui/components/Button';

function CompanyUserLimitView({
    companyId,
    onRequestLimitChange,
    onManageInvitations,
}: CompanyUserLimitViewProps) {
    return (
        <Container>
            <CompanyUserStatsInfo companyId={companyId} />
            <TipText data-test="limit-tip">
                💡 <b>Quick tip:</b> You may be able to find unaccepted invitations and revoke them, which will free up some seats.
            </TipText>
            <Buttons>
                <Button
                    height="40px"
                    action={onRequestLimitChange}
                    dataTest="request-limit-change-button"
                >
                    Request Limit Change
                </Button>
                <Button
                    height="40px"
                    variant="inverse"
                    action={onManageInvitations}
                    dataTest="manage-invitations-button"
                >
                    Manage Invitations
                </Button>
            </Buttons>
        </Container>
    );
}

export default CompanyUserLimitView;