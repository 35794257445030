import { ReactElement } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import {
    PortablePractisSetsWithStore,
    WithPractisSetsContext,
} from '../portablePractisSets';
import { AssignPractisSetsViewProps } from './type';
import {
    Actions,
    ButtonContainer,
    HeaderTitle,
    Divider,
    useStyles,
    PractisSetsContainer,
} from './styles';
import { Button } from '../../ui/components/Button';

const AssignPractisSetsMultipleUsersView = (
    props: AssignPractisSetsViewProps
): ReactElement => {
    const {
        isOpen,
        isSaving,
        isApplyButtonDisabled,
        onClose,
        handleApplyPractisSets,
    } = props;

    const classes = useStyles();

    if (!isOpen) return <></>;

    return (
        <WithPractisSetsContext.Provider
            value={{
                reducerName: 'performance',
            }}
        >
            <ClickAwayListener onClickAway={onClose}>
                <PractisSetsContainer
                    initial={{ scale: 1 / 5 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    topMargin={5}
                >
                    <HeaderTitle data-test="assign-practis-sets-header">Assign Practis Sets and Due Dates</HeaderTitle>
                    <PortablePractisSetsWithStore
                        showSave={false}
                        showSelectActions={true}
                        showSearch={true}
                        resetSelectedOnUnmount={true}
                        className={classes.portablePractisSetContainer}
                        selectAllWrapperClassName={classes.selectAllWrapper}
                    />

                    <Divider />
                    <Actions>
                        <ButtonContainer>
                            <Button
                                label={'Cancel'}
                                variant={'inverse'}
                                action={onClose}
                                width={'112px'}
                                disabled={isSaving}
                                dataTest='cancel-button'
                            />
                        </ButtonContainer>
                        <Button
                            label={'Apply'}
                            action={handleApplyPractisSets}
                            width={'112px'}
                            loading={isSaving}
                            disabled={isApplyButtonDisabled}
                            dataTest='apply-button'
                        />
                    </Actions>
                </PractisSetsContainer>
            </ClickAwayListener>
        </WithPractisSetsContext.Provider>
    );
};

export default AssignPractisSetsMultipleUsersView;
