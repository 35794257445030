import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const BillingReport = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 40 40"
                className={className}
                width="40"
                height="40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M5.781 17.781h3.438v3.438H5.781z"/>
                <path stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M5.781 17.781h3.438v3.438H5.781zM5.781 24.781h3.438v3.438H5.781zM12.781 24.781h3.438v3.438h-3.438zM5.781 31.781h3.438v3.438H5.781zM12.781 31.781h3.438v3.438h-3.438zM19.781 24.781h3.438v10.438h-3.438zM12.781 17.781h3.438v3.438h-3.438zM19.781 17.781h3.438v3.438h-3.438z"/>
                <rect x="2.781" y="5.781" width="23.438" height="33.438" rx="1.219" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 6.343V5a4 4 0 0 1 4-4h18.5a4 4 0 0 1 4 4v30h1.643A4.857 4.857 0 0 0 38 30.143h-6.5M27 35h4" stroke="#222431" stroke-width="1.56"/>
                <path d="M24 6V5a4 4 0 0 1 4-4" stroke="#222431" stroke-width="1.56"/>
                <path fill="#4AA9E2" d="M5 8h19v7H5z"/>
            </svg>
        );
    }
);

export default BillingReport;