import React, { FC } from 'react';
import styled from 'styled-components';

const Title = styled.div`
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
    margin-bottom: 8px;
`;

export const CommentTitle: FC<{
    className?: string;
    dataTest?: string;
}> = ({ children, className, dataTest }) => (
    <Title className={className} data-test={dataTest}>{children}</Title>
);
