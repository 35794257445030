import { makeStyles } from '@material-ui/core';
import { Form } from 'formik';
import { Input } from '../../../../../../ui/components/input';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Content = styled.div`
    height: 551px;
    box-sizing: border-box;
    padding: 24px 100px 24px 24px;
`;

export const UnlimitedSetting = styled.div`
    padding-top: 16px;
`;

export const SettingDescription = styled.div`
    padding-left: 20px;
    color: var(--ps-grey-1);
`;

export const UnlimitedSettingDescription = styled(SettingDescription)`
    padding-top: 8px;
    font-size: 13px;
`;

export const LimitedSetting = styled.div`
    font-size: 13px;
`;

export const LimitedSettingValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const LimitNumberContainer = styled.div`
    padding-left: 4px;
`;

export const LimitInput = styled(Input)<{ ref?: any; }>`
    width: 49px;
    padding: 4px 8px;
    font-weight: bold;
    border: 1px solid var(--ps-grey-3);
    border-radius: 4px;

    &:focus {
        border: 1px solid var(--ps-grey-2);
    }
`;

export const LimitedSettingDescription = styled(SettingDescription)`
    padding-top: 3px;
`;

export const LimitWarning = styled.div`
    margin-top: 24px;
    margin-left: 18px;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--ps-blue-12);
    font-size: 13px;
    color: var(--ps-blue-1);
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--ps-grey-4);
    padding: 23px 5px 0 5px;
    width: 100%;
    box-sizing: border-box;
    height: 64px;
    align-items: center;
    padding: 0 24px 0 0;
`;

export const useStyles = makeStyles(theme => ({
    customRadioButton: {
        marginBottom: '0!important',
    },
}));