import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PractisSetSummary = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 40 40"
                className={className}
                width="40"
                height="40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path fill="#4AA9E2" d="M.833 5h38.333v4.167H.833z"/>
                <path d="M33.473 35.33H6.526a3.482 3.482 0 0 1-3.482-3.483V9.721h33.911v22.126a3.482 3.482 0 0 1-3.482 3.482z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="m13.333 10 2.34 2.631a3.334 3.334 0 0 0 2.49 1.119h4.09c.952 0 1.859-.407 2.491-1.119L27.084 10" stroke="#222431" stroke-width="1.567"/>
                <path d="M39.219 4.67H.78v5.051H39.22v-5.05zM10 29.167h1.5M10 20h1.5M10 24.167h1.5M15 29.166h6.5M15 20h10M15 24.166h15" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        );
    }
);

export default PractisSetSummary;
