import styled from 'styled-components';

export const StyledContainer = styled.div`
    overflow: auto;
    height: 100%;
    display: block;
    padding: 0 28px;
    background-color: var(--ps-white-1);
    min-width: 1024px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const StyledHeader = styled.div<{ marginBottom?: string }>`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    height: 95px;
    background-color: var(--ps-white-1);
    color: var(--ps-graphite-1);
`;

export const UserStatsContainer = styled.div`
    margin-left: 16px;
`;

export const StyledFooter = styled.div`
    height: 79px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
`;

export const StyledFooterActions = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledHeaderInput = styled.div`
    flex: 1;
    min-width: 150px;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 16px;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 257px);
    justify-content: space-between;
    min-height: 300px;
`;

export const AddNewUserWrapper = styled.div`
    margin-top: 16px;
    position: relative;
`;

export const Divider = styled.hr`
    width: 100%;
    height: 1px;
    border: none;
    flex-shrink: 0;
    background-color: var(--ps-grey-4);
    margin: 0;
`;

export const StyledSavedStatusContainer = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--ps-grey-1);
    align-self: flex-end;
`;

export const StyledButtonContainer = styled.div`
    margin-right: 16px;
`;