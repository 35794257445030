import React from 'react';
import styled from 'styled-components';

const StyledWarning = styled.div`
    padding: 0 8px;
    margin-bottom: 8px;
    opacity: 0.9;
`;

const StyledWarningLine = styled.div`
    height: 1px;
    border-top: 1px solid ${props => props.theme.Colors.coral};
    margin-bottom: 4px;
`;

const StyledWarningText = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.coral};
`;

const LabelInputWarning = () => {
    return (
        <StyledWarning>
            <StyledWarningLine />
            <StyledWarningText>Label name is not unique</StyledWarningText>
        </StyledWarning>
    );
};

export default LabelInputWarning;
