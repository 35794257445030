import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
export const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

export const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;

    overflow: auto;
    background-color: inherit;
`;

export const TeamsContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: inherit;

    > div:last-child {
        flex-grow: 1;
        margin-top: 28px;
    }
`;

export const StatusSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
`;

export const StatusItem = styled.div`
    margin-bottom: 8px;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

export const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;
    margin-right: 24px;
    color: var(--ps-grey-1);
`;

export const ButtonWrapper = styled.div`
    margin-right: 12px;
`;