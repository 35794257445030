import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 230px;
    box-sizing: border-box;
    flex: 1;
    padding: 24px 32px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    display: flex;
    flex-direction: column;
`;

const ChartBox: FC<{ className?: string }> = ({ children, className }) => {
    return <Container className={className}>{children}</Container>;
};

export default ChartBox;
