import { useDispatch } from 'react-redux'
import { useCallback } from 'react';
import { useCreateCommandRoleplayApi, useGetRoleplayApi, useGetRoleplayContentApi, useUpdateRoleplayApi } from '../../../../api/roleplay';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { ConversationItem, Roleplay } from '../../../../constants/interfaces/Roleplay';
import {
    getRoleplayStart,
    getRoleplaySuccess,
    getRoleplayFailure,
    updateRoleplayStart,
    updateRoleplaySuccess,
    updateRoleplayFailure,
    clearRoleplay,
} from './actions';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { RoleplayData } from '../../CreateRoleplay/types';
import { commandTexts } from '../constants';
import { useCompanyVoiceSettingsState } from '../../../CompanySettings/store/states';
import { getTextToSpeechSettingsOrDefault } from '../../../../features/textToSpeech/helpers';
import { useTextToSpeechService } from '../../../../features/textToSpeech/store/services';

/**
 * @function useGetRoleplayService
 * @returns { CallableFunction }
 */
export const useGetRoleplayService = () => {
    const dispatch = useDispatch();
    const getRoleplayApi = useGetRoleplayApi();
    const showMessage = useShowMessage();
    const getRoleplayContent = useGetRoleplayContentApi();

    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback((roleplayId) => {
        dispatch(getRoleplayStart());
        return getRoleplayApi(roleplayId)
            .then((data: Roleplay) => {
                if (data) {
                    getRoleplayContent(roleplayId).then((content) => {
                        const rp = { ...data, content: content || null }
                        dispatch(getRoleplaySuccess(rp));
                    })
                }
            })
            .catch((error: ErrorResult) => {
                dispatch(getRoleplayFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, getRoleplayApi, showMessage, getRoleplayContent]);
}


export const useUpdateRoleplayService = () => {
    const dispatch = useDispatch();
    const updateRoleplayApi = useUpdateRoleplayApi();
    const showMessage = useShowMessage();
    const createCommand = useCreateCommandRoleplayApi()

    return useCallback(
        (roleplayId, roleplay, keys?: RoleplayData, type?) => {
            dispatch(updateRoleplayStart());
            const rp = {
                title: roleplay.title,
                salesMethod: roleplay.salesMethod,
                'situation': roleplay.situation,
                'prospect': roleplay.prospect,
                "product": roleplay.product,
                'objectiveRep': roleplay.objectiveRep,
                'objectiveNuances': roleplay.objectiveNuances
            }
            return updateRoleplayApi(roleplayId, rp)
                .then(data => {
                    dispatch(updateRoleplaySuccess({ ...roleplay, ...data }))

                    if (type === 'command') {
                        let str = '';
                        if (keys) {
                            str = Object.entries(keys).reduce((acc, [key, value]) => {
                                const keyStr = commandTexts[key as keyof typeof commandTexts];
                                return acc + `Change ${keyStr} to: ${value && value !== 'null' ? value : 'N/A'} \n`;
                            }, '');
                        }

                        createCommand({ roleplayId, text: str });
                    }
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateRoleplayFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, showMessage, updateRoleplayApi, createCommand]
    );
};

/**
 * @function useClearRoleplayService
 * @returns { CallableFunction }
 */
export const useClearRoleplayService = () => {
    const dispatch = useDispatch();
    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback(() => {
        dispatch(clearRoleplay());
    }, [dispatch]);
}

export const useGenerateAudioForRoleplay = () => {
    const voiceSettings = useCompanyVoiceSettingsState().data;
    const textToSpeech = useTextToSpeechService();
    const settings = getTextToSpeechSettingsOrDefault(voiceSettings)
    return useCallback(async (lines: ConversationItem[]) => {
        const reportedLines = [];
        for (let line of lines) {
            const lineSettings = line.role === 'prospect'
                ? settings.customer
                : settings.representative;

            reportedLines.push(await textToSpeech(
                line.text,
                lineSettings,
                { feature: 'scenario', itemId: lines.indexOf(line) }
            ))
        }
        return reportedLines;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings])
}