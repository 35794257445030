import { useContext } from 'react';

import { WithPractisSetsContextProps, WithPractisSetsContext } from './context';

export const useWithPractisSetsContext = (): WithPractisSetsContextProps => {
    const context = useContext(WithPractisSetsContext);

    if (!context) {
        throw new Error(
            'Cannot find WithPractisSets context provider. Please make a sure that you are located on the children of WithPractisSetsProvider'
        );
    }
    return context;
};
