import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { ACTIONS, SearchableFilterHeaderActions } from './actions';
import { SearchableFilterHeaderState } from './types';
import { initialFilterHeaderState } from './constants';

export const filterHeaderReducer: Reducer<
    SearchableFilterHeaderState,
    SearchableFilterHeaderActions
> = (state = initialFilterHeaderState, action) => {
    const stateByKey = state[action.key] ?? {};
    switch (action.type) {
        case ACTIONS.SET_FILTER_HEADER_SEARCH_ACTIVE:
            return {
                ...state,
                [action.key]: {
                    ...stateByKey,
                    isSearchActive: action.isSearchActive,
                }
            };
        case ACTIONS.SET_FILTER_HEADER_SEARCH_TERM:
            return {
                ...state,
                [action.key]: {
                    ...stateByKey,
                    searchTerm: action.searchTerm
                }
            }

        default:
            return state;
    }
};

export const useFilterHeaderState = () =>
    useSelector(state => state.filterHeader);