import equal from 'deep-equal';
import { useCallback, useState } from 'react';
import { toggleItemInArray } from '../../helpers/functions/array-helpers';
import { escapeRegExp } from '../../helpers/functions/escape-reg-exp';

export interface SearchParams {
    searchTerm: string;
    companyId?: number;
    teamId?: string | number;
    teamIds?: number[];
    teamLeadIds?: number[];
    labelIDs?: number[];
    practisSetIDs?: number[];
    roleIDs?: number[];
    invitersIDs?: number[];
    filters?: { field: string; value: string | string[] | number | number[] | boolean }[];
    filterByStatus?: string[] | null;
    filterByRegistrationStatus?: string[] | null;
    completion?: string;
    filterByStatusValue?: string;
    practisSetId?: number;
    orderBy: {
        field?: string;
        asc?: boolean;
    } | null;
    limit?: number;
    offset: number;
    totalCount?: number;
    count?: number;
    numberOfPages: number;
    refreshRate?: number;
    startDate?: string;
    endDate?: string;
    scenarioIds?: number[];
    userId?: string | number;
    filterByDueDate?: string[] | null;
    customFilters?: object;
}

export interface SearchQuery {
    searchTerm: string;
}

function ifChanged(
    prevSearchParams: SearchParams,
    newSearchParams: SearchParams
) {
    return equal(prevSearchParams, newSearchParams)
        ? prevSearchParams
        : newSearchParams;
}

export const ALL_FILTER_NAME = 'All';
export const ALL_FILTER_VALUE = '--all--';

const ENROLLMENT_OPTIONS = {
    [ALL_FILTER_VALUE]: ['ENROLLED', 'PENDING', 'COMPLETED', 'IN_PROGRESS'],
    PENDING: ['PENDING'],
    ENROLLED: ['ENROLLED'],
    COMPLETED: ['COMPLETED'],
    IN_PROGRESS: ['IN_PROGRESS'],
};

const COMPLETION_OPTIONS = {
    [ALL_FILTER_VALUE]: '',
    Complete: 'Complete',
    Incomplete: 'Incomplete',
};

export function useSearchParams(
    searchParams: SearchParams,
    setSearchParams: (value: (prevState: SearchParams) => SearchParams) => void
) {
    const setSearchTerm = useCallback(
        (searchTerm: string) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    searchTerm,
                    offset: 0,
                })
            ),
        [setSearchParams]
    );

    const setFilter = useCallback(
        (filter: {
            field: string;
            value: string | number | string[] | number[] | boolean;
        }) =>
            setSearchParams(prevSearchParams => {
                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    filters: [
                        ...(prevSearchParams.filters
                            ? prevSearchParams.filters.filter(
                                  f => f.field !== filter.field
                              )
                            : []),
                        ...(filter.value === ALL_FILTER_VALUE ? [] : [filter]),
                    ].filter(
                        filter =>
                            !Array.isArray(filter.value) ||
                            filter.value.length > 0
                    ), // TODO: refactor and simplify this logic, remove ALL_FILTER_VALUE when no longer needed in other places
                    offset: 0,
                });
            }),
        [setSearchParams]
    );

    const setOverrideFilter = useCallback(
        (filter: { field: string; value: string | number }) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    filters: [
                        ...(filter.value === ALL_FILTER_VALUE ? [] : [filter]), //TODO: remove ALL_FILTER_VALUE when not needed
                    ],
                    offset: 0,
                })
            ),
        [setSearchParams]
    );

    const setMultipleFilter = useCallback(
        (filter: {
            field: string;
            value: string | number | string[] | number[] | boolean;
        }) => {
            setSearchParams(prevSearchParams => {
                const prevMatchedFieldFilters = JSON.parse(
                    JSON.stringify(
                        prevSearchParams.filters
                            ? prevSearchParams.filters.filter(
                                  f => f.field === filter.field
                              )
                            : []
                    )
                );

                const newMatchedFieldValues =
                    prevMatchedFieldFilters && prevMatchedFieldFilters[0]
                        ? prevMatchedFieldFilters[0]['value']
                        : [];

                const newFilters = {
                    field: filter.field,
                    value: toggleItemInArray(
                        newMatchedFieldValues,
                        filter.value
                    ),
                };

                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    filters: [
                        ...(prevSearchParams.filters
                            ? prevSearchParams.filters.filter(
                                  f => f.field !== filter.field
                              )
                            : []),
                        ...(newFilters.value.length > 0 ? [newFilters] : []),
                    ],
                    offset: 0,
                });
            });
        },
        [setSearchParams]
    );

    const setFilterByStatus = useCallback(
        (filterByStatus: 'ENROLLED' | 'PENDING' | typeof ALL_FILTER_VALUE) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    filterByStatus: ENROLLMENT_OPTIONS[filterByStatus],
                    filterByStatusValue: filterByStatus,
                    offset: 0,
                })
            ),
        [setSearchParams]
    );

    const setCompletion = useCallback(
        (
            completionStatus:
                | 'Complete'
                | 'Incomplete'
                | typeof ALL_FILTER_VALUE
        ) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    completion:
                        completionStatus === ALL_FILTER_VALUE
                            ? ''
                            : COMPLETION_OPTIONS[completionStatus],
                    offset: 0,
                })
            ),
        [setSearchParams]
    );

    const setOrderBy = useCallback(
        (field: string) =>
            setSearchParams(prevSearchParams => {
                const prevOrderBy = prevSearchParams.orderBy;
                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    orderBy: prevOrderBy
                        ? {
                              ...prevOrderBy,
                              field,
                              asc:
                                  field === prevOrderBy.field
                                      ? !prevOrderBy.asc
                                      : true,
                          }
                        : {
                              field,
                              asc: true,
                          },
                    offset: 0,
                });
            }),
        [setSearchParams]
    );

    const setLabelIDs = useCallback(
        (labels: number[]) =>
            setSearchParams(prevSearchParams => {
                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    labelIDs: labels,
                });
            }),
        [setSearchParams]
    );

    const setPractisSetIDs = useCallback(
        (practisSets: number[]) =>
            setSearchParams(prevSearchParams => {
                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    practisSetIDs: practisSets,
                });
            }),
        [setSearchParams]
    );

    const setRoleIDs = useCallback(
        (roles: number[]) =>
            setSearchParams(prevSearchParams => {
                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    roleIDs: roles,
                });
            }),
        [setSearchParams]
    );

    const setTeamIDs = useCallback(
        (teams: number[]) =>
            setSearchParams(prevSearchParams => {
                return ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    teamIds: teams,
                });
            }),
        [setSearchParams]
    );

    const setLimit = useCallback(
        (limit: number) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    limit,
                })
            ),
        [setSearchParams]
    );

    const setOffset = useCallback(
        (offset: number) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    offset,
                })
            ),
        [setSearchParams]
    );

    const setCustom = useCallback(
        (name: keyof SearchParams, value: any) =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, {
                    ...prevSearchParams,
                    [name]: value,
                    offset: 0,
                })
            ),
        [setSearchParams]
    );

    const getFilterByValue = useCallback(
        (field?: string) => {
            if (
                searchParams &&
                searchParams.filters &&
                searchParams.filters.length
            ) {
                if (!field) {
                    return (
                        searchParams.filters[0] && searchParams.filters[0].value
                    );
                }

                const filterValue = searchParams.filters.filter(
                    filter => field === filter.field
                )[0];

                return !!filterValue ? filterValue.value : null;
            }
            return;
        },
        [searchParams]
    );

    const getCompletionValue = useCallback(() => {
        return searchParams.completion;
    }, [searchParams]);

    const refreshSearchParams = useCallback(
        () =>
            setSearchParams(prevSearchParams => ({
                ...prevSearchParams,
                offset: 0,
                refreshRate: Math.random(),
            })),
        [setSearchParams]
    );

    const applyLocalSearch = useCallback(
        <T extends { [key: string]: any }>(fields: string[], data: T[]) => {
            if (searchParams.searchTerm.length < 1) return data;
            return data.filter((item: { [key: string]: number | string }) => {
                if (fields.includes('firstName') && fields.includes('lastName')) {
                    return (
                        item['firstName'] && item['lastName'] &&
                         new RegExp(
                            escapeRegExp(
                                searchParams.searchTerm
                            ).trim().toLowerCase()
                        ).test(`${item['firstName']} ${item['lastName']}`.trim().toLowerCase())
                    );
                }
                
                return fields
                    .map((field: string) => {
                        return (
                            item[field] &&
                             new RegExp(
                                escapeRegExp(
                                    searchParams.searchTerm
                                ).toLowerCase()
                            ).test(item[field].toString().toLowerCase())
                        );
                    })
                    .reduce((a, b) => a || b);
            });
        },
        [searchParams.searchTerm]
    );

    const applyLocalFiltering = useCallback(
        <T extends { [key: string]: any }>(
            fields: ('practisSetIDs' | 'labelIDs' | 'roleIDs')[],
            data: T[],
            statusFilter?: any[] | null,
            filterLength?: number | null,
            labelFilterLength?: number | null
        ) => { 
            let filteredData: T[] = data;
            for (let field of fields) {
                const filterParam =
                    (searchParams[field] && searchParams[field]) || [];       
                if (field === 'roleIDs') {
                    if (filterParam.length > 0) {
                        filteredData = filteredData.filter(item => {
                            return (
                                item.roleId && filterParam.includes(item.roleId)
                            );
                        });
                    }
                } else {
                    if (filterParam.length > 0) {
                            filteredData = filteredData.filter(item => {
                                return filterParam.some(id => {
                                    return (
                                        item[field] && item[field].indexOf(id) >= 0
                                    );
                                });
                            });
                    }
                }

                if(statusFilter && filterLength){
                    if(labelFilterLength){
                        filteredData = filteredData.filter(item => {
                            return filterParam.some(id => {
                                return (
                                    item[field] && item[field].indexOf(id) >= 0
                                );
                            });
                        }).filter(item => statusFilter!.includes(item.status))
                    } else {
                        filteredData = filteredData.filter(item => statusFilter.includes(item.status))
                    }
                } else if (!filterLength){
                //   filteredData = data;
                }

            }

          
            return filteredData;
        },
        [searchParams]
    );

    type sortFieldValues<T extends string> = {
        [field in T]: string;
    };

    const applyLocalSorting = useCallback(
        <T extends { [key: string]: any }>(
            mapItems: sortFieldValues<string>,
            data: T[]
        ) => {
            let sortableItems = [...data];
            if (searchParams.orderBy && searchParams.orderBy.field) {
                const sortKey = searchParams.orderBy.field;
                const sortDirection = searchParams.orderBy.asc;

                sortableItems.sort((first, second) => {
                    const a = first[mapItems[sortKey]];
                    const b = second[mapItems[sortKey]];

                    if (a !== Boolean(a)) {
                        if (a === b) {
                            return 0;
                        } else if (!a) {
                            return 1;
                        } else if (!b) {
                            return -1;
                        }
                    }

                    if (sortDirection) {
                        return a < b ? -1 : 1;
                    } else {
                        return a < b ? 1 : -1;
                    }
                });
            }
            return sortableItems;
        },
        [searchParams.orderBy]
    );

    return {
        searchParams,
        setSearchTerm,
        setFilter,
        setOverrideFilter,
        setMultipleFilter,
        setFilterByStatus,
        setCompletion,
        setOrderBy,
        setLabelIDs,
        setPractisSetIDs,
        setRoleIDs,
        setTeamIDs,
        setLimit,
        setOffset,
        setCustom,
        refreshSearchParams,
        getFilterByValue,
        getCompletionValue,
        applyLocalSearch,
        applyLocalFiltering,
        applyLocalSorting,
    };
}

export function useSearchParamsState(initialSearchParams: SearchParams) {
    const [searchParams, setSearchParams] = useState(initialSearchParams);

    const setInitial = useCallback(
        () =>
            setSearchParams(prevSearchParams =>
                ifChanged(prevSearchParams, initialSearchParams)
            ),
        [setSearchParams, initialSearchParams]
    );

    return {
        ...useSearchParams(searchParams, setSearchParams),
        setInitial,
    };
}
