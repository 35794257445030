import { Label } from '../../../constants/interfaces/Label';
import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { EditingLabels } from './states';

export enum ACTIONS {
    OPEN_LABEL_CREATION_PANEL = 'OPEN_LABEL_CREATION_PANEL',
    CLOSE_LABEL_CREATION_PANEL = 'CLOSE_LABEL_CREATION_PANEL',

    SET_EDITING_LABEL = 'ADDING_LABELS',
    SET_LABELS_SEARCH_TERM = 'SET_LABELS_SEARCH_TERM',

    TOGGLE_COLLAPSE_LABEL_FIELD = 'TOGGLE_COLLAPSE_LABEL_FIELD',
    EXPAND_LABEL_FIELD_BY_ID = 'EXPAND_LABEL_FIELD_BY_ID',

    COLLAPSE_ALL_LABELS = 'COLLAPSE_ALL_LABELS',
    EXPAND_ALL_LABELS = 'EXPAND_ALL_LABELS',
    EXPAND_MULTIPLE_LABELS = 'EXPAND_MULTIPLE_LABELS',

    COLLAPSE_ALL_TABLE_LABELS = 'COLLAPSE_ALL_TABLE_LABELS',

    SELECT_LABEL_FILTER = 'SELECT_LABEL_FILTER',
    SET_LABEL_FILTERS = 'SET_LABEL_FILTERS',
    CLEAR_LABEL_FILTERS = 'CLEAR_LABEL_FILTERS',

    SET_ASSIGN_LABELS = 'SET_ASSIGN_LABELS',
    CLEAR_ASSIGN_LABELS = 'CLEAR_ASSIGN_LABELS',
    TOGGLE_COLLAPSE_ASSIGN_LABEL_FIELD = 'TOGGLE_COLLAPSE_ASSIGN_LABEL_FIELD',

    SAVE_LABEL_FILTERS_START = 'SAVE_LABEL_FILTERS_START',
    SAVE_LABEL_FILTERS_SUCCESS = 'SAVE_LABEL_FILTERS_SUCCESS',
    SAVE_LABEL_FILTERS_FAILURE = 'SAVE_LABEL_FILTERS_FAILURE',

    SEARCH_LABELS_START = 'SEARCH_LABELS_START',
    SEARCH_LABELS_SUCCESS = 'SEARCH_LABELS_SUCCESS',
    SEARCH_LABELS_FAILURE = 'SEARCH_LABELS_FAILURE',

    FETCH_FULL_LABELS_LIST_START = 'FETCH_FULL_LABELS_LIST_START',
    FETCH_FULL_LABELS_LIST_SUCCESS = 'FETCH_FULL_LABELS_LIST_SUCCESS',
    FETCH_FULL_LABELS_LIST_FAILURE = 'FETCH_FULL_LABELS_LIST_FAILURE',

    UPDATE_LABEL_START = 'UPDATE_LABEL_START',
    UPDATE_LABEL_SUCCESS = 'UPDATE_LABEL_SUCCESS',
    UPDATE_LABEL_FAILURE = 'UPDATE_LABEL_FAILURE',

    MODIFY_LABEL_NAME = 'MODIFY_LABEL_NAME',

    SET_PREVIOUSLY_ASSIGNED_LABELS = 'SET_PREVIOUSLY_ASSIGNED_LABELS',
}

export function selectLabelFilterAction(labelId: number) {
    return {
        type: ACTIONS.SELECT_LABEL_FILTER as ACTIONS.SELECT_LABEL_FILTER,
        labelId,
    };
}

export function setPreviouslyAssignedLabels(labels: number[]) {
    return {
        type: ACTIONS.SET_PREVIOUSLY_ASSIGNED_LABELS as ACTIONS.SET_PREVIOUSLY_ASSIGNED_LABELS,
        labels,
    };
}

export function setLabelFilterAction(labels: number[]) {
    return {
        type: ACTIONS.SET_LABEL_FILTERS as ACTIONS.SET_LABEL_FILTERS,
        labels,
    };
}

export function clearLabelFilterAction() {
    return {
        type: ACTIONS.CLEAR_LABEL_FILTERS as ACTIONS.CLEAR_LABEL_FILTERS,
    };
}

export function setAssignLabelsAction(labels: number[]) {
    return {
        type: ACTIONS.SET_ASSIGN_LABELS as ACTIONS.SET_ASSIGN_LABELS,
        labels,
    };
}

export function clearAssignLabelsAction() {
    return {
        type: ACTIONS.CLEAR_ASSIGN_LABELS as ACTIONS.CLEAR_ASSIGN_LABELS,
    };
}

export function saveLabelFiltersStart(filters: number[] | null) {
    return {
        type: ACTIONS.SAVE_LABEL_FILTERS_START as ACTIONS.SAVE_LABEL_FILTERS_START,
        filters,
    };
}

export function saveLabelFiltersSuccess(filters: number[] | null) {
    return {
        type: ACTIONS.SAVE_LABEL_FILTERS_SUCCESS as ACTIONS.SAVE_LABEL_FILTERS_SUCCESS,
        filters,
    };
}

export function saveLabelFiltersFailure(error: string) {
    return {
        type: ACTIONS.SAVE_LABEL_FILTERS_FAILURE as ACTIONS.SAVE_LABEL_FILTERS_FAILURE,
        error,
    };
}

export function toggleCollapseLabelFieldAction(labelId: number) {
    return {
        type: ACTIONS.TOGGLE_COLLAPSE_LABEL_FIELD as ACTIONS.TOGGLE_COLLAPSE_LABEL_FIELD,
        labelId,
    };
}

export function toggleCollapseAssignLabelFieldAction(labelId: number) {
    return {
        type: ACTIONS.TOGGLE_COLLAPSE_ASSIGN_LABEL_FIELD as ACTIONS.TOGGLE_COLLAPSE_ASSIGN_LABEL_FIELD,
        labelId,
    };
}

export function expandLabelFieldByIdAction(labelId: number) {
    return {
        type: ACTIONS.EXPAND_LABEL_FIELD_BY_ID as ACTIONS.EXPAND_LABEL_FIELD_BY_ID,
        labelId,
    };
}

export function collapseAllLabelsAction() {
    return {
        type: ACTIONS.COLLAPSE_ALL_LABELS as ACTIONS.COLLAPSE_ALL_LABELS,
    };
}

export function collapseAllTableLabelsAction() {
    return {
        type: ACTIONS.COLLAPSE_ALL_TABLE_LABELS as ACTIONS.COLLAPSE_ALL_TABLE_LABELS,
    };
}

export function expandAllLabelsAction() {
    return {
        type: ACTIONS.EXPAND_ALL_LABELS as ACTIONS.EXPAND_ALL_LABELS,
    };
}

export function expandMultipleLabelsAction(labelIds: number[]) {
    return {
        type: ACTIONS.EXPAND_MULTIPLE_LABELS as ACTIONS.EXPAND_MULTIPLE_LABELS,
        labelIds,
    };
}

export function modifyLabelNameAction(labelId: number, value: string) {
    return {
        type: ACTIONS.MODIFY_LABEL_NAME as ACTIONS.MODIFY_LABEL_NAME,
        labelId,
        value,
    };
}

export function setEditingLabelAction(configs?: EditingLabels) {
    return {
        type: ACTIONS.SET_EDITING_LABEL as ACTIONS.SET_EDITING_LABEL,
        configs,
    };
}

export function setLabelsSearchTerm(value: string) {
    return {
        type: ACTIONS.SET_LABELS_SEARCH_TERM as ACTIONS.SET_LABELS_SEARCH_TERM,
        value,
    };
}

export function openLabelCreationPanelAction() {
    return {
        type: ACTIONS.OPEN_LABEL_CREATION_PANEL as ACTIONS.OPEN_LABEL_CREATION_PANEL,
    };
}

export function closeLabelCreationPanelAction() {
    return {
        type: ACTIONS.CLOSE_LABEL_CREATION_PANEL as ACTIONS.CLOSE_LABEL_CREATION_PANEL,
    };
}

export function searchLabelsStart() {
    return {
        type: ACTIONS.SEARCH_LABELS_START as ACTIONS.SEARCH_LABELS_START,
    };
}

export function searchLabelsSuccess(
    data: PaginationResult<Label>,
    searchTerm?: boolean
) {
    return {
        type: ACTIONS.SEARCH_LABELS_SUCCESS as ACTIONS.SEARCH_LABELS_SUCCESS,
        data,
        searchTerm,
    };
}

export function searchLabelsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_LABELS_FAILURE as ACTIONS.SEARCH_LABELS_FAILURE,
        error,
    };
}

export function fetchFullLabelsListStart() {
    return {
        type: ACTIONS.FETCH_FULL_LABELS_LIST_START as ACTIONS.FETCH_FULL_LABELS_LIST_START,
    };
}

export function fetchFullLabelsListSuccess(data: PaginationResult<Label>) {
    return {
        type: ACTIONS.FETCH_FULL_LABELS_LIST_SUCCESS as ACTIONS.FETCH_FULL_LABELS_LIST_SUCCESS,
        data,
    };
}

export function fetchFullLabelsListFailure(error: string) {
    return {
        type: ACTIONS.FETCH_FULL_LABELS_LIST_FAILURE as ACTIONS.FETCH_FULL_LABELS_LIST_FAILURE,
        error,
    };
}

export function updateLabelStart() {
    return {
        type: ACTIONS.UPDATE_LABEL_START as ACTIONS.UPDATE_LABEL_START,
    };
}

export function updateLabelSuccess(
    updatedLabel: Label,
    updateType: 'create' | 'update' | 'delete'
) {
    return {
        type: ACTIONS.UPDATE_LABEL_SUCCESS as ACTIONS.UPDATE_LABEL_SUCCESS,
        updatedLabel,
        updateType,
    };
}

export function updateLabelFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_LABEL_FAILURE as ACTIONS.UPDATE_LABEL_FAILURE,
        error,
    };
}
