import styled from 'styled-components';
import BoldDownArrow from '../../../../ui/icons/BoldDownArrow';
import BoldUpArrow from '../../../../ui/icons/BoldUpArrow';

export const TEXT_STYLES = `
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #909ba7;
    display: inline-block;
`;

export const Root = styled.div<{ open?: boolean }>`
    height: 40px;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    padding: 0 10px;
    background: ${props =>
        props.open ? props.theme.Colors.whiteTwo : props.theme.Colors.white};
`;

export const DownIcon = styled(BoldDownArrow)`
    color: ${props => props.theme.Colors.black};
    cursor: pointer;
    height: 10px;
    width: 8px;
    margin-left: 10px;
`;

export const UpIcon = styled(BoldUpArrow)`
    color: ${props => props.theme.Colors.black};
    cursor: pointer;
    height: 10px;
    width: 8px;
    margin-left: 10px;
`;

export const DropdownButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        opacity: 0.7;
    }
`;

export const DropdownButtonLabel = styled.div`
    color: ${props => props.theme.Colors.dark};
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
`;

export const LogoContainer = styled.div<{ practis?: boolean }>`
    height: ${props => (props.practis ? '20px' : '24px')};
    width: ${props => (props.practis ? '20px' : '24px')};
    border-radius: 4px;
    overflow: hidden;
`;

export const StyledLogo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;
