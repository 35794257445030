import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    useSubmissionDetailsState,
    useSubmissionPaginationState,
} from '../store/states';
import { SubmissionReview, SubmissionV2 } from '../../../constants/interfaces/Reviews';
import { CompanyInterface } from '../../../constants/interfaces/Company';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { History } from 'history';
import ROUTES from '../../../routes/routes';
import { pushModal } from '../../../tools/router';
import {
    useGetSubmissionDetailsService,
    useGetSubmissionFromFeedService,
    useMarkSubmissionAsViewedService,
    useModifyScoreService,
    useRequestSubmissionRedoService,
    usePerformSubmissionReviewService,
    useGetSubmissionReviewService,
    useSearchChallengeSubmissionPaginationService,
} from '../store/services';
import DetailsSubmissionInfo from '../components/DetailsSubmissionInfo';
import DetailsSubmissionReview from '../components/DetailsSubmissionReview';
import styled from 'styled-components';
import { getCompanyState } from '../../../pages/CompanySettings/store/reducers';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import {
    LocalStorage,
    useLocalStorage,
} from '../../../tools/localStorage/LocalStorage';
import { RequestRedo } from '../components/RequestRedo';
import { RequestReminder } from '../components/RequestReminder';
import { UserProfile } from '../../../constants/interfaces/User';
import { useGetChallengeService } from '../../challenges/store/services';
import { useChallengeState } from '../../challenges/store/states';
import { Challenge } from '../../../constants/interfaces/Challenge';
import { useCopyChallengeLink } from '../store/services';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import CopyLinkNotification from '../../../ui/components/CopyLinkNotification';
import UnavailableChallenge from '../components/UnavailableChallenge';
import ScreenLoading from '../components/ScreenLoading';
import { SubmissionRedoParams, SubmissionReviewParams } from '../../../api/submissions/types';
import { handleDownloadVideoFile } from '../../../helpers/functions/file-download';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../constants/interfaces/filters';
import { ChallengeSubmissionFilters } from '../../../constants/interfaces/SubmissionSearchFilters';
import { ITEMS_PER_PAGE } from './ChallengeSubmissions';
import DialogWrapper from '../../../ui/components/DialogWrapper/DialogWrapper';

const StyledDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const StyledDetailsContainerInner = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 6px;
`;

const ChallengeCopiedKeyName = 'challengeLinkWasCopied';

const SubmissionDetails: FC<{
    submission?: SubmissionV2;
    review?: Partial<SubmissionReview>;
    challenge?: Challenge;
    loading?: boolean;
    modifyScore: (value: string | number, field?: string) => void;
    company: CompanyInterface;
    history: History<any>;
    handleSaveSubmissionReview: (
        submissionId: number,
        data: SubmissionReviewParams
    ) => any;
    changeSubmission: (next: boolean) => void;
    itemsTotalCount?: number;
    currentItemPosition: number;
    localStorage?: LocalStorage;
    requestSubmissionRedo: (
        submissionId: number,
        redo: SubmissionRedoParams
    ) => Promise<string>;
    profile?: UserProfile;
    markSubmissionAsViewedService(teamId: any): void;
    handleCopyChallengeLink: () => void;
    showCopyLinkNotification: boolean;
    handleHideCopyLinkNotification: () => void;
    getSubmissionReview: (submissionId: number) => Promise<SubmissionReview>;
}> = ({
    loading,
    submission,
    review,
    challenge,
    history,
    company,
    modifyScore,
    handleSaveSubmissionReview,
    changeSubmission,
    itemsTotalCount,
    currentItemPosition,
    localStorage,
    requestSubmissionRedo,
    profile,
    markSubmissionAsViewedService,
    handleCopyChallengeLink,
    showCopyLinkNotification,
    handleHideCopyLinkNotification,
    getSubmissionReview
}) => {
    const prevCompanyRef = useRef(company);
    const DO_NOT_SHOW_WARNINGS = 'do_not_show_request_redo_warnings';

    const [requestRedoOpened, setRequestRedoOpened] = useState(false);
    const [requestRedoReminderOpened, setRequestRedoReminderOpened] =
        useState(false);
    const [doNotShowWarning, setDoNotShowWarning] = useState(false);
    const [reviewPermissionDenied, setReviewPermissionDenied] = useState(false);

    const showMessage = useShowMessage();

    useEffect(() => {
        prevCompanyRef.current = company;
    });
    const prevCompany = prevCompanyRef.current;

    useEffect(() => {
        if ((company && !company.id) || (prevCompany && !prevCompany.id))
            return;
        if (company.id !== prevCompany.id) {
            history.push(ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL);
        }
    }, [company, prevCompany, history]);

    useEffect(() => {
        const isNew = history.location.state?.isNew;
        const submissionId = history.location.state?.submissionId;

        if (isNew && submissionId) {
            markSubmissionAsViewedService(submissionId);
        }
    }, [markSubmissionAsViewedService, history]);

    const saveReview = useCallback(() => {
        if (!review) return;

        const data: SubmissionReviewParams = {
            note: review.note ?? '',
            clarity: review.clarity ?? 0,
            knowledge: review.knowledge ?? 0,
            confidence: review.confidence ?? 0,
            enthusiasm: review.enthusiasm ?? 0,
            goalAchieved: review.goalAchieved ?? 0
        };
        handleSaveSubmissionReview(submission!.id, data)
            .then(() => history.push(ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL))
            .catch((error: ErrorResult) => {
                switch (error.code) {
                    case 404:
                        showMessage('Challenge has been requested to redo', 'error');
                        history.push(ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL);
                        break;
                    case 409:
                        getSubmissionReview(submission!.id)
                            .then((newReview) => {
                                setRequestRedoReminderOpened(false);
                                showMessage(
                                    `Challenge has been already reviewed by ${newReview.user.firstName} ${newReview.user.lastName}`,
                                    'error'
                                );
                            });
                        break;
                    case 500:
                    case 403:
                        setReviewPermissionDenied(true);
                        showMessage(error.message, 'error');
                        break;
                    default:
                        showMessage(error.message, 'error');
                        break;
                }
            }
        );

        if (doNotShowWarning && !!localStorage && !!profile) {
            const restrictedUsersList = localStorage.get(DO_NOT_SHOW_WARNINGS);
            let finalUsersList = [];
            if (restrictedUsersList) {
                finalUsersList = JSON.parse(restrictedUsersList);
            }

            finalUsersList.push(profile.id);
            localStorage.set(
                DO_NOT_SHOW_WARNINGS,
                JSON.stringify(finalUsersList)
            );
        }
    }, [
        localStorage,
        doNotShowWarning,
        handleSaveSubmissionReview,
        submission,
        history,
        profile,
        review,
        getSubmissionReview,
        showMessage
    ]);

    const onCompleteReviewClicked = useCallback(() => {
        let doNotShowRequestRedoWarning = false;
        const restrictedUsersList =
            localStorage && localStorage.get(DO_NOT_SHOW_WARNINGS);
        if (restrictedUsersList && !!profile) {
            const finalUsersList = JSON.parse(restrictedUsersList);
            doNotShowRequestRedoWarning = finalUsersList.includes(profile.id);
        }

        if (doNotShowRequestRedoWarning) {
            saveReview();
        } else {
            setRequestRedoReminderOpened(true);
        }
    }, [localStorage, saveReview, profile]);

    const requestRedoSubmitted = useCallback(
        (data: any) => {
            if (!submission || !data) return;
            setRequestRedoOpened(false);
            const redo: SubmissionRedoParams = {
                senderName: data.name,
                text: data.text
            };
            requestSubmissionRedo(submission.id, redo).then(
                (response: string) => {
                    if (response === 'success') {
                        history.push(ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL);
                    }
                }
            );
        },
        [submission, requestSubmissionRedo, history]
    );

    const handleViewTraineeDetails = (traineeId?: number) => {
        if (!traineeId) return;
        history.push(
            ROUTES.USER_PERFORMANCE.replace(':userId', traineeId.toString()),
            { useGoBack: true }
        );
    };

    const handleViewChallenge = (challengeId?: number | string) => {
        if (!challengeId) return;
        pushModal(
            history,
            ROUTES.LIBRARY_SETTINGS.CHALLENGES.SINGLE.replace(
                ':challengeId',
                challengeId.toString()
            )
        );
    };

    const [videoLoading, setVideoLoading] = useState(false);

    const handleDownloadVideo = () => {
        if (!submission) return;
        setVideoLoading(true);
        const url = submission?.mediaUrl;
        const fileName = submission.practisSet?.name;
        
        if (url && fileName) {
            handleDownloadVideoFile(url, fileName).then(() =>
                setVideoLoading(false)
            );
        }
    };

    if (!submission || loading) {
        return (
            <MainWrapper hideHeader={true}>
                <ScreenLoading />
            </MainWrapper>
        );
    }

    const mode = !submission.reviewedAt ? 'edit' : 'view';

    return (
        <MainWrapper
            title="Submission Details"
            subTitle={submission.practisSet ? submission.practisSet.name : ''}
            goBackUrl={ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL}
            useGoBack={!!history.location.state?.useGoBack}
            htmlPageTitle={`Challenge - ${
                submission.practisSet ? submission.practisSet.name : ''
            } - ${
                submission.user &&
                `${submission.user.firstName} ${submission.user.lastName}`
            } - Practis`}
        >
            <StyledDetailsContainer>
                <StyledDetailsContainerInner>
                    <DetailsSubmissionInfo
                        url={submission.mediaUrl}
                        challenge={
                            challenge &&
                            submission.challenge?.id === challenge.id
                                ? (challenge as any)
                                : submission.challenge!
                        }
                        redirectToChallenge={handleViewChallenge}
                        user={submission.user}
                        action={handleViewTraineeDetails}
                        handleDownloadVideo={handleDownloadVideo}
                        videoLoading={videoLoading}
                        handleCopyLink={handleCopyChallengeLink}
                    />
                    <DetailsSubmissionReview
                        review={review}
                        mode={mode}
                        submitted={submission.createdAt}
                        canRedo={mode === 'edit'}
                        modifyScore={modifyScore}
                        onCompleteReviewClicked={onCompleteReviewClicked}
                        changeSubmission={changeSubmission}
                        itemsTotalCount={itemsTotalCount}
                        currentItemPosition={currentItemPosition}
                        requestRedoClicked={() => setRequestRedoOpened(true)}
                        reviewPermissionDenied={reviewPermissionDenied}
                    />
                    {requestRedoOpened && (
                        <DialogWrapper
                            customization={{
                                width: 480,
                                padding: '31px 32px 27px 40px',
                            }}
                        >
                            <RequestRedo
                                fromUser={
                                    !!profile
                                        ? `${profile.firstName} ${profile.lastName}`
                                        : ''
                                }
                                onSend={requestRedoSubmitted}
                                onClose={() => setRequestRedoOpened(false)}
                            />
                        </DialogWrapper>
                    )}
                    {requestRedoReminderOpened && !reviewPermissionDenied && (
                        <DialogWrapper
                            customization={{
                                width: 400,
                                padding: '30px 32px 43px 40px',
                            }}
                        >
                            <RequestReminder
                                onProceed={saveReview}
                                doNotShowWarning={doNotShowWarning}
                                onShowWarningClicked={(e: any) =>
                                    setDoNotShowWarning(e.target.checked)
                                }
                                onClose={() =>
                                    setRequestRedoReminderOpened(false)
                                }
                            />
                        </DialogWrapper>
                    )}
                </StyledDetailsContainerInner>
            </StyledDetailsContainer>
            {showCopyLinkNotification && (
                <CopyLinkNotification
                    onClose={handleHideCopyLinkNotification}
                />
            )}
        </MainWrapper>
    );
};

const SubmissionDetailsContainer = () => {
    const submission = useSubmissionDetailsState();
    const [localOffset, setLocalOffset] = useState(0);

    const history = useHistory();
    const submissionPagination = useSubmissionPaginationState().submissionIds;
    const submissionPaginationLoading = useSubmissionPaginationState().loading;
    const submissionTotalCount = useSubmissionPaginationState().totalCount;
    const loading = useSubmissionDetailsState().loading;
    const params = useParams<{ submissionId?: string }>();
    const modifyScore = useModifyScoreService();
    const saveSubmissionReview = usePerformSubmissionReviewService();
    const requestSubmissionRedo = useRequestSubmissionRedoService();
    const company = useSelector(getCompanyState);
    const profile = useSelector(getProfileState);
    const localStorage = useLocalStorage();
    const markSubmissionAsViewedService = useMarkSubmissionAsViewedService();
    const getSubmissionDetails = useGetSubmissionDetailsService();
    const getSubmissionFromFeed = useGetSubmissionFromFeedService();
    const getChallenge = useGetChallengeService();
    const { data: challenge, loading: challengeLoading } = useChallengeState();
    const [showCopyLinkNotification, setShowCopyLinkNotification] = useState(false);
    const copyChallengeLink = useCopyChallengeLink();
    const getSubmissionReview = useGetSubmissionReviewService();
    const searchChallengeSubmissionPaginationService =
        useSearchChallengeSubmissionPaginationService();

    /**
     * @function successGetSubmissionDetailCallback
     * @returns { void }
     */
    const successGetSubmissionDetailCallback = useCallback(
        (submission: SubmissionV2) => {
            if (submission) {
                const challengeId = submission.challenge?.id;
                if (challengeId) {
                    getChallenge(String(challengeId));
                }
            }
        },
        [getChallenge]
    );

    const state: any = history.location.state;
    const isDirectLink = !state?.useGoBack;
    const locationSearchParams = state?.searchParams as SearchParams | undefined;
    const locationFilters = state?.filters as ChallengeSubmissionFilters | undefined;

    useEffect(() => {
        if (params.submissionId) {
            const isOwnRoute = history.location.pathname.indexOf(
                ROUTES.SUBMISSION_SETTINGS.CHALLENGES.SINGLE
                    .replace(':submissionId', params.submissionId)
            ) > -1;
            if (!isOwnRoute) return;
            if (isDirectLink) {
                getSubmissionFromFeed(params.submissionId, successGetSubmissionDetailCallback);
            } else {
                getSubmissionDetails(params.submissionId, successGetSubmissionDetailCallback);
            }
        }
    }, [
        history.location.pathname,
        params.submissionId,
        isDirectLink,
        getSubmissionDetails,
        getSubmissionFromFeed,
        successGetSubmissionDetailCallback
    ]);

    const currentItemPosition = useMemo(() => {
        if (
            submissionPagination &&
            submission?.details &&
            locationSearchParams
        ) {
            const submissionIndex = submissionPagination.findIndex(
                submissionId => submissionId === submission.details!.id
            );
            return locationSearchParams.offset + localOffset + submissionIndex;
        }
        return 0;
    }, [submissionPagination, submission?.details, locationSearchParams, localOffset]);

    /**
     * @function changeSubmissionHandler
     * @description loads neighbor challenge submission by next/prev id from the submission list
     * if required submission is outside of the loaded list, it increases/decreases offset
     * and loads the next/prev list of submissions and then loads submission details by proper
     * submission id from the new list
     * @param { boolean } next
     * @returns { void }
     */
    const changeSubmissionHandler = useCallback((next: boolean) => {
        if (submissionPagination && !loading && !challengeLoading && locationSearchParams) {
            const submissionIndex = currentItemPosition - locationSearchParams.offset - localOffset;
            if (next) {
                if (currentItemPosition === submissionTotalCount! - 1)
                    return;
                if (submissionIndex === ITEMS_PER_PAGE - 1) {
                    searchChallengeSubmissionPaginationService(
                        {
                            ...locationSearchParams,
                            offset: locationSearchParams.offset + localOffset + ITEMS_PER_PAGE
                        },
                        locationFilters!,
                        (data) => {
                            setLocalOffset(localOffset + ITEMS_PER_PAGE);
                            const nextSubmissionId = data[0];
                            getSubmissionDetails(nextSubmissionId, successGetSubmissionDetailCallback);
                        });
                } else {
                    const nextSubmissionId = submissionPagination[submissionIndex + 1];
                    getSubmissionDetails(nextSubmissionId, successGetSubmissionDetailCallback);
                }
            } else {
                if (currentItemPosition === 0)
                    return;
                if (submissionIndex === 0) {
                    searchChallengeSubmissionPaginationService(
                        {
                            ...locationSearchParams,
                            offset: locationSearchParams.offset + localOffset - ITEMS_PER_PAGE
                        },
                        locationFilters!,
                        (data) => {
                            setLocalOffset(localOffset - ITEMS_PER_PAGE);
                            const nextSubmissionId = data[data.length - 1];
                            getSubmissionDetails(nextSubmissionId, successGetSubmissionDetailCallback);
                        });
                } else {
                    const nextSubmissionId = submissionPagination[submissionIndex - 1];
                    getSubmissionDetails(nextSubmissionId, successGetSubmissionDetailCallback);
                }
            }
        }
    }, [
        currentItemPosition,
        localOffset,
        locationSearchParams,
        locationFilters,
        loading,
        challengeLoading,
        submissionPagination,
        submissionTotalCount,
        searchChallengeSubmissionPaginationService,
        getSubmissionDetails,
        successGetSubmissionDetailCallback
    ]);

    /**
     * @function handleCopyChallengeLink
     * @description shows a notification modal for the first time or copies link to a clipboard
     * @returns { void }
     */
     const handleCopyChallengeLink = useCallback(() => {
        if (localStorage.get(ChallengeCopiedKeyName)) {
            copyChallengeLink(submission.details!);
        } else {
            setShowCopyLinkNotification(true);
        }
    }, [submission?.details, localStorage, copyChallengeLink]);

    /**
     * @function handleHideCopyLinkNotification
     * @description hides a notification modal and copies link to a clipboard
     * @returns { void }
     */
     const handleHideCopyLinkNotification = useCallback(() => {
        setShowCopyLinkNotification(false);
        copyChallengeLink(submission.details!);
        localStorage.set(ChallengeCopiedKeyName, true);
    }, [submission?.details, copyChallengeLink, localStorage]);

    return (
        submission?.error
        ? <UnavailableChallenge />
        : <SubmissionDetails
            submission={submission.details}
            review={submission.review}
            challenge={challenge}
            loading={loading || submissionPaginationLoading}
            history={history}
            company={company}
            modifyScore={modifyScore}
            handleSaveSubmissionReview={saveSubmissionReview}
            changeSubmission={changeSubmissionHandler}
            itemsTotalCount={submissionTotalCount}
            currentItemPosition={currentItemPosition}
            profile={profile}
            localStorage={localStorage}
            requestSubmissionRedo={requestSubmissionRedo}
            markSubmissionAsViewedService={markSubmissionAsViewedService}
            handleCopyChallengeLink={handleCopyChallengeLink}
            showCopyLinkNotification={showCopyLinkNotification}
            handleHideCopyLinkNotification={handleHideCopyLinkNotification}
            getSubmissionReview={getSubmissionReview}
        />
    );
};

export default SubmissionDetailsContainer;
