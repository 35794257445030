import { useCallback } from 'react';
import { SearchParams } from '../../constants/interfaces/filters';

const MIN_CHARACTERS = 0;

export const useSearchDebounced = (
    searchEntity: (searchParams: SearchParams, isRefreshed?: boolean) => void,
    minChars: number = MIN_CHARACTERS
) => {
    return useCallback(
        (searchParams?: SearchParams, isRefreshed?: boolean) => {
            if (!searchParams) {
                return searchEntity(null as unknown as SearchParams);
            }
            const searchTermLength = searchParams.searchTerm.length;
            if (searchTermLength > minChars || searchTermLength < 1) {
                return searchEntity(searchParams, isRefreshed);
            }
        },
        [searchEntity, minChars]
    );
};
