import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { Variables } from '../../../theme/variables';
import OutsideActionBox from '../OutsideActionBox/OutsideActionBox';

export const Container = styled.div<{ itemsCount: number }>`
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
`;

export const Input = styled.input<{ isMobile: boolean; activeIndex: number }>`
    position: absolute;
    top: 0;
    left: ${props =>
        `calc(${props.activeIndex} * 2.5rem + 1rem * ${props.activeIndex})`};
    opacity: 0;
    width: ${props => (props.isMobile ? '48px' : '64px')};
    height: ${props => (props.isMobile ? '48px' : '64px')};
`;

export const Item = styled.div<{
    isMobile: boolean;
    hasBorder: boolean;
    backgroundColor?: string;
    fontColor?: string;
}>`
    width: ${props => (props.isMobile ? '48px' : '64px')};
    height: ${props => (props.isMobile ? '48px' : '64px')};
    padding: 0;
    border-radius: 4px;
    font-size: ${props => (props.isMobile ? '15px' : '17px')};
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    text-align: center;
    border: 0;
    margin: ${props => (props.isMobile ? '0 6px' : '0 8px')};
    color: ${props =>
        props.fontColor ? props.fontColor : props.theme.Colors.white};
    background-color: ${props =>
        props.backgroundColor
            ? props.backgroundColor
            : props.theme.Colors.darkTwo};
    ${props =>
        !!props.hasBorder &&
        `box-shadow: inset 0 0 0 1px ${props.theme.Colors.cloudyBlue};`};
    cursor: text;
`;

function blinkingEffect() {
    return keyframes`
        50% {
          opacity: 0;
        }
  `;
}

export const StyledCursorText = styled.span`
    animation: ${blinkingEffect} 1s linear infinite;
`;

const KEY_CODE = {
    BACKSPACE: 8,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
    DELETE: 46,
};

type Props = {
    length?: number;
    onChange: (data: string) => any;
    placeholder?: string;
    value?: string;
    backgroundColor?: string;
    fontColor?: string;
};

const VerificationCodeInput = ({
    length = 5,
    onChange,
    placeholder = '',
    value: pValue,
    backgroundColor,
    fontColor,
}: Props) => {
    const emptyValue = new Array(length).fill(placeholder);

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [value, setValue] = useState<string[]>(
        pValue ? pValue.split('') : emptyValue
    );

    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.smaller;

    const codeInputRef = createRef<HTMLInputElement>();
    const itemsRef = useMemo(
        () =>
            new Array(length)
                .fill(null)
                .map(() => createRef<HTMLDivElement>()),
        [length]
    );

    const isCodeRegex = new RegExp(`^[0-9]{${length}}$`);


    

    const focusOrBlurItem = useCallback(
        (index: number, action: any = null) => {
            const currentItem: any = itemsRef ? itemsRef[index].current : null;
            action && currentItem && currentItem[action]();
            return currentItem;
        },
        [itemsRef]
    );

    const focusItem = (index: number): void => {
        focusOrBlurItem(index, 'focus');
    };
    const blurItem = (index: number): void => {
        focusOrBlurItem(index, 'blur');
    };

    const onItemFocus = (index: number) => () => {
        setActiveIndex(index);
        if (codeInputRef.current) codeInputRef.current.focus();
    };

    const onInputKeyUp = ({ key, keyCode }: React.KeyboardEvent) => {
        const newValue = [...value];
        const nextIndex = activeIndex + 1;
        const prevIndex = activeIndex - 1;

        const codeInput = codeInputRef.current;

        const isLast = nextIndex === length;
        const isDeleting =
            keyCode === KEY_CODE.DELETE || keyCode === KEY_CODE.BACKSPACE;

        // keep items focus in sync
        onItemFocus(activeIndex);

        // on delete, replace the current value
        // and focus on the previous item
        if (isDeleting) {
            if (
                (activeIndex > 0 &&
                    activeIndex !== length - 1 &&
                    newValue[activeIndex] === placeholder) ||
                (activeIndex === length - 1 &&
                    newValue[activeIndex] === placeholder)
            ) {
                setActiveIndex(prevIndex);
                newValue[prevIndex] = placeholder;
                focusItem(prevIndex);
            }
            newValue[activeIndex] = placeholder;
            setValue(newValue);

            return;
        }

        // if the key pressed is not a number
        // don't do anything
        if (Number.isNaN(+key)) return;

        // reset the current value
        // and set the new one
        if (codeInput) codeInput.value = '';
        newValue[activeIndex] = key;
        setValue(newValue);

        if (!isLast) {
            setActiveIndex(nextIndex);
            focusItem(nextIndex);
            return;
        }
    };

    // handle mobile autocompletion
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: changeValue } = e.target;
        const isCode = isCodeRegex.test(changeValue);

        if (!isCode) return;

        setValue(changeValue.split(''));
        blurItem(activeIndex);
    };

    const onInputBlur = () => {
        if (activeIndex === -1) return;

        blurItem(activeIndex);
        // setActiveIndex(-1);
    };

    useEffect(() => {
        if (activeIndex === 0) {
            focusOrBlurItem(0, 'focus');
        }
    }, [activeIndex, focusOrBlurItem]);

    // handle pasting
    useEffect(() => {
        const codeInput = codeInputRef.current;
        if (!codeInput) return;

        const onPaste = (e: ClipboardEvent) => {
            e.preventDefault();

            const pastedString = e.clipboardData
                ? e.clipboardData.getData('text')
                : '';
            if (!pastedString) return;

            const isNumber = !Number.isNaN(+pastedString);
            if (isNumber) setValue(pastedString.split(''));
        };

        codeInput.addEventListener('paste', onPaste);
        return () => codeInput.removeEventListener('paste', onPaste);
    }, [codeInputRef]);

    useEffect(() => {
        onChange(value.join(''));
    }, [value, onChange]);

    useEffect(() => {
        if (typeof pValue !== 'string') return;

        // avoid infinite loop
        if (pValue === '' && value.join('') === emptyValue.join('')) return;

        // keep internal and external states in sync
        if (pValue !== value.join('')) setValue(pValue.split(''));
    }, [pValue, emptyValue, value]);

    return (
        <>
            <OutsideActionBox open={true} toggleOpen={() => setActiveIndex(-1)}>
                <Container
                    className="ReactInputVerificationCode__container"
                    // needed for styling
                    itemsCount={length}
                >
                    <Input
                        ref={codeInputRef}
                        autoComplete={isMobile ? 'one-time-code' : 'off'}
                        type="text"
                        inputMode="decimal"
                        id="one-time-code"
                        // use onKeyUp rather than onChange for a better control
                        // onChange is still needed to handle the autocompletion
                        // when receiving a code by SMS
                        onChange={onInputChange}
                        onKeyUp={onInputKeyUp}
                        onBlur={onInputBlur}
                        isMobile={isMobile}
                        activeIndex={activeIndex}
                        data-test="verification-code-input"
                    />

                    {itemsRef.map((ref, i) => (
                        <Item
                            key={i}
                            ref={ref}
                            role="button"
                            tabIndex={0}
                            isMobile={isMobile}
                            hasBorder={!!value[i]}
                            onFocus={onItemFocus(i)}
                            backgroundColor={backgroundColor}
                            fontColor={fontColor}
                            className={'ReactInputVerificationCode__item' + (activeIndex === i ? ' focused' : '')}
                        >
                            <span data-test={`verification-code-digit-${i}`}>{value[i]}</span>
                            {activeIndex === i && (
                                <StyledCursorText>|</StyledCursorText>
                            )}
                        </Item>
                    ))}
                </Container>
            </OutsideActionBox>
        </>
    );
};

export default VerificationCodeInput;
