import { Reducer } from 'redux';
import { PortablePractisSetsState, initialState } from './states';
import { PortablePractisSetsActions } from './actions';

export const portablePractisSetsReducer: Reducer<
    PortablePractisSetsState,
    PortablePractisSetsActions
> = (
    state = initialState,
    action: PortablePractisSetsActions
): PortablePractisSetsState => {
    switch (action.type) {
        case 'searchStartPractisSets':
            return {
                ...state,
                loading: true,
            };
        case 'searchSuccessPractisSets':
            return {
                data: action.result,
                loading: false,
            };
        case 'searchFailurePractisSets':
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
