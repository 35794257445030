import styled from 'styled-components';

export const Root = styled.div<{
    width?: number;
    marginRight?: number;
    marginLeft?: number;
}>`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    ${props => props.width && `width: ${props.width}px`};
    flex-shrink: 0;
    ${props => !!props.marginRight && `margin-right: ${props.marginRight}px;`}
    ${props => !!props.marginLeft && `margin-left: ${props.marginLeft}px;`}
`;

