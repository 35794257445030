import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoDrafts = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="100%" 
                height="100%" 
                fill="none" 
                viewBox="0 0 48 48"
                className={className}
                ref={ref}
                >
                <rect width="34" height="3" x="7" y="7" fill="currentColor" rx="1.5"/>
                <rect width="25" height="3" x="11" y="1" fill="currentColor" rx="1.5"/>
                <path fill="currentColor" fillRule="evenodd" d="M43 13c1.105 0 2 .895 2 2v30c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V15c0-1.105.895-2 2-2h38zm-1 3H6v3.565L23.032 30.35 42 19.491V16zm0 6.948L22.954 33.85 6 23.116V44h36V22.948z" clipRule="evenodd"/>
            </svg>        
        );
    }
);

export default NoDrafts;
