import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarAssessmentActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M15.99 4.138a1.207 1.207 0 0 0-.64-.884l-1.714-.863L12.78.665a1.2 1.2 0 0 0-.877-.644 1.181 1.181 0 0 0-1.032.332L8.423 2.817c-.32.323-.402.811-.196 1.227L9.24 6.082l-.248.25-1.672 1.683a.517.517 0 0 0 0 .739.517.517 0 0 0 .361.156.5.5 0 0 0 .361-.156l1.673-1.685.247-.25 2.024 1.02a1.1 1.1 0 0 0 .474.114c.269 0 .537-.104.744-.312l2.446-2.464a1.21 1.21 0 0 0 .33-1.04h.01zm-.537 3.451c.02.208.03.406.03.614 0 4.304-3.468 7.797-7.741 7.797C3.468 16 0 12.507 0 8.203S3.468.405 7.742.405c.279 0 .557.021.836.052l-.929.936C3.954 1.435.97 4.47.97 8.203c0 3.732 3.035 6.82 6.772 6.82s6.575-2.88 6.761-6.477l.95-.957zm-3.768 1.799c-.506 1.715-2.064 2.973-3.943 2.973-2.281 0-4.13-1.86-4.13-4.158A4.146 4.146 0 0 1 6.649 4.21c.041.177.103.354.186.53l.216.427a3.125 3.125 0 0 0-2.405 3.036c0 1.715 1.394 3.119 3.097 3.119a3.092 3.092 0 0 0 2.994-2.37l.557.28c.124.062.248.114.382.156h.01z" fill="#fff"/>
                <path d="m11 5 1.5-1.5" stroke="#222431" stroke-linecap="round"/>
            </svg>
        );
    }
);

export default SidebarAssessmentActive;
