import { FC, useEffect, useState, useCallback } from 'react';
import { History } from 'history';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import AvatarPlaceholder from '../AvatarPlaceholder/AvatarPlaceholder';
import ROUTES from '../../../routes/routes';

import ModalPage from '../ModalPage/ModalPage';
import { VideoPlayer } from '../VideoPlayer';
import {
    useGetSubmissionFromFeedService,
    useGetSubmissionDetailsService,
    useCopyAccuracyLink,
} from '../../../features/submissions/store/services';
import { useSubmissionDetailsState } from '../../../features/submissions/store/states';
import Close from '../../icons/Close';
import {
    formatHour,
    formatMMDDYY,
} from '../../../helpers/functions/date-convert';
import useKeypress from '../../../helpers/hooks/useKeyPress';
import { SubmissionV2 } from '../../../constants/interfaces/Reviews';
import ScreenLoading from './components/ScreenLoading';
import { useLocalStorage } from '../../../tools/localStorage/LocalStorage';
import BrokenSharedLink from '../BrokenSharedLink';
import CopyLinkNotification from '../CopyLinkNotification';
import BlankVideo from './components/BlankVideo';
import { handleDownloadVideoFile } from '../../../helpers/functions/file-download';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';
import { TextEllipsis } from '../../generalStyles/TextStyles';

const MainWrapper = styled.div`
    background: ${props => props.theme.Colors.backgroundDark};
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    p {
        color: white;
    }

    .video {
        min-width: 416px;
        max-height: 416px;
        min-height: 416px;
        background: #292b39;
        border-radius: 8px;

        .player-background {
            background: #292b39 !important;
        }

        .MuiSlider-rail {
            box-shadow: 1px 1px 1px;
        }
    }

    video {
        margin-top: 5px;
        border-radius: 8px;
    }
`;

const CloseWrapper = styled.div`
    position: absolute;
    right: -72px;
    top: 54px;
    width: 40px;
    height: 40px;
    padding: 11px 12px 12px;
    box-sizing: border-box;
    border-radius: 75px;
    cursor: pointer;
    background-color: var(--ps-graphite-1);

    g {
        fill: #fff;
    }
`;

const TopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ScoreWrapper = styled.div`
    color: white;
    display: flex;
    align-items: center;
    max-height: 40px;

    span {
        color: white;
        font-size: 32px;
        margin-right: 4px;
    }

    div {
        line-height: 16px;
        font-size: 12px;
    }
`;

const UserWrapper = styled.div`
    display: flex;
    max-height: 40px;
    margin-bottom: 16px;
    flex: 1;

    .avatar {
        max-width: 40px;
        margin-right: 12px;
        overflow: hidden;

        & > div {
            font-size: 20px;
        }
    }
`;

const Title = styled.div`
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 6px;
    line-height: 27px;
`;

const PractisSetTitle = styled.div`
    color: #8ea6b7;
    font-size: 15px;

    span {
        color: ${props => props.theme.Colors.mainBlue};
        text-decoration: none;
        cursor: pointer;
        word-break: break-all;
    }
`;

const UserTextWrapper = styled.div`
    color: white;
    font-size: 15px;
`;

const DateWrapper = styled.div`
    color: #8ea6b7;
    font-size: 15px;
    line-height: 20px;
`;

export const PlaybackVideo: FC<{
    handleDownloadVideo?: any;
    handleCopyLink?(): void;
    handleClose: () => void;
    submission?: SubmissionV2;
    videoDownloading: boolean;
    loading: boolean;
    isLoadingError: boolean;
    history: History<any>;
    showCopyLinkNotification: boolean;
    handleHideCopyLinkNotification: () => void;
}> = ({
    handleDownloadVideo,
    handleCopyLink,
    handleClose,
    submission,
    videoDownloading,
    loading,
    isLoadingError,
    history,
    showCopyLinkNotification,
    handleHideCopyLinkNotification,
}) => {
    const handleViewPractisSetReport = (
        userId: number,
        enrollmentId: number
    ) => {
        history.push(
            ROUTES.TRAINEE_DETAILS.replace(
                ':userId',
                userId.toString()
            ).replace(':enrollmentId', enrollmentId.toString()),
            { useGoBack: true }
        );
    };

    const formatDate = (submission: SubmissionV2) => {
        const date = new Date(submission.createdAt);
        return `${formatMMDDYY(date)} at ${formatHour(date)}`;
    };

    useHtmlPageTitle('Accuracy Test', [
        `${submission?.practisSet?.name}`,
        `${submission?.user.firstName} ${submission?.user.lastName}`,
    ]);

    const userFullName = `${submission?.user.firstName} ${submission?.user.lastName}`;

    return (
        <ModalPage
            closePath={'submissions/accuracy'}
            closeGoingBack={true}
            width="100%"
            handleManualClose={handleClose}
        >
            <MainWrapper title="Accuracy Test Details">
                {!submission || loading ? (
                    <ScreenLoading isError={isLoadingError} />
                ) : (
                    <div style={{ position: 'relative' }}>
                        <TopWrapper>
                            <UserWrapper>
                                <AvatarPlaceholder
                                    profile={submission.user}
                                    size="sm"
                                    className="avatar"
                                />
                                <UserTextWrapper style={{ color: '#fff' }}>
                                    <TextEllipsis
                                        width={200}
                                        title={userFullName}
                                    >
                                        {userFullName}
                                    </TextEllipsis>
                                    <DateWrapper>
                                        Submitted {formatDate(submission)}
                                    </DateWrapper>
                                </UserTextWrapper>
                            </UserWrapper>
                            <ScoreWrapper>
                                <span>
                                    {submission.accuracy
                                        ? Math.floor(
                                              submission.accuracy.accuracy * 100
                                          )
                                        : 0}
                                </span>
                                <div>
                                    <div>%</div>
                                    <div>Accuracy</div>
                                </div>
                            </ScoreWrapper>
                        </TopWrapper>
                        {submission.mediaUrl && (
                            <VideoPlayer
                                className="video"
                                url={submission.mediaUrl}
                                onDownloadVideo={() =>
                                    handleDownloadVideo(submission.id)
                                }
                                onCopyLink={handleCopyLink}
                                videoLoading={videoDownloading}
                            />
                        )}
                        {!submission.mediaUrl && (
                            <BlankVideo
                                isLoading={false}
                                errorMessage="This video is not available for playback"
                            />
                        )}
                        <div style={{ maxWidth: 416 }}>
                            <Title>{submission.scenario?.title}</Title>
                            <PractisSetTitle>
                                Practis Set Report:{' '}
                                <span
                                    onClick={() =>
                                        handleViewPractisSetReport(
                                            submission.user.id,
                                            submission.enrollmentId
                                        )
                                    }
                                >
                                    {submission.practisSet?.name}
                                </span>
                            </PractisSetTitle>
                        </div>
                        <CloseWrapper onClick={handleClose}>
                            <Close />
                        </CloseWrapper>
                        {showCopyLinkNotification && (
                            <CopyLinkNotification
                                onClose={handleHideCopyLinkNotification}
                            />
                        )}
                    </div>
                )}
            </MainWrapper>
        </ModalPage>
    );
};

export const PlaybackScreen: FC = () => {
    const submissionDetails = useSubmissionDetailsState();
    const history = useHistory();
    const params: { submissionId: string } = useParams();
    const [videoDownloading, setVideoDownloading] = useState(false);
    const [showCopyLinkNotification, setShowCopyLinkNotification] =
        useState(false);
    const copyAccuracyLink = useCopyAccuracyLink();
    const localStorage = useLocalStorage();

    const getSubmissionFromFeed = useGetSubmissionFromFeedService();
    const getSubmissionDetails = useGetSubmissionDetailsService();

    const handleDownloadVideo = () => {
        setVideoDownloading(true);

        const url = submissionDetails.details?.mediaUrl;
        const fileName = submissionDetails.details?.scenario?.title;

        if (url && fileName) {
            handleDownloadVideoFile(url, fileName)
                .then(() => setVideoDownloading(false))
                .catch(() => setVideoDownloading(false));
        }
    };

    const state: any = history.location.state;
    const isDirectLink = !state?.useGoBack;

    useEffect(() => {
        if (isDirectLink) {
            getSubmissionFromFeed(params.submissionId);
        } else {
            getSubmissionDetails(params.submissionId);
        }
    }, [
        getSubmissionDetails,
        getSubmissionFromFeed,
        params.submissionId,
        isDirectLink,
    ]);

    const handleClose = (goBack: boolean) => {
        if (goBack) {
            history.goBack();
        } else {
            history.push('/feed/accuracy');
        }
    };

    useKeypress('Escape', () => handleClose(!isDirectLink));

    /**
     * @function handleCopyLink
     * @description shows a notification modal for the first time or copies link to a clipboard
     * @returns { void }
     */
    const handleCopyLink = useCallback(() => {
        if (submissionDetails.details) {
            if (localStorage.get('submissionLinkWasCopied')) {
                copyAccuracyLink(submissionDetails.details);
            } else {
                setShowCopyLinkNotification(true);
            }
        }
    }, [submissionDetails.details, copyAccuracyLink, localStorage]);

    /**
     * @function handleHideCopyLinkNotification
     * @description hides a notification modal and copies link to a clipboard
     * @returns { void }
     */
    const handleHideCopyLinkNotification = useCallback(() => {
        setShowCopyLinkNotification(false);
        copyAccuracyLink(submissionDetails.details!);
        localStorage.set('submissionLinkWasCopied', true);
    }, [submissionDetails.details, copyAccuracyLink, localStorage]);

    useKeypress('Escape', () => handleClose(!!state?.useGoBack));

    return submissionDetails.error && isDirectLink ? (
        <BrokenSharedLink />
    ) : (
        <PlaybackVideo
            submission={submissionDetails.details}
            handleDownloadVideo={handleDownloadVideo}
            handleCopyLink={handleCopyLink}
            handleClose={() => handleClose(!isDirectLink)}
            history={history}
            videoDownloading={videoDownloading}
            loading={!!submissionDetails.loading || !!submissionDetails.error}
            isLoadingError={!!submissionDetails.error}
            showCopyLinkNotification={showCopyLinkNotification}
            handleHideCopyLinkNotification={handleHideCopyLinkNotification}
        />
    );
};

export default PlaybackScreen;