import { FC, memo } from 'react';

import { formatDate } from '../../../../helpers/functions/date-convert';
import {
    ButtonIcon,
    Container,
    LabelButton,
    StyledSpan,
    Wrapper,
} from './styles';
import { TableRefreshProps } from './types';
import { TableRefreshButton } from './table-refresh-button';
import TableRefreshIcon from '../../../icons/TableRefresh';

const TableRefresh: FC<TableRefreshProps> = memo(props => {
    const { lastRefreshed, refreshData, buttons, top, fontSize, dataTest = 'table-timestamp' } =
        props;

    return (
        <Container>
            <Wrapper top={top} fontSize={fontSize}>
                {buttons?.map(button => (
                    <TableRefreshButton {...button} />
                ))}
                <StyledSpan data-test={dataTest && `${dataTest}-label`}>
                    {`Updated`}
                    {formatDate(lastRefreshed)}
                </StyledSpan>
                <span>
                    <LabelButton
                        onClick={refreshData}
                        data-test={dataTest && `${dataTest}-refresh`}
                    >
                        <ButtonIcon>
                            <TableRefreshIcon />
                        </ButtonIcon>
                    </LabelButton>
                </span>
            </Wrapper>
        </Container>
    );
});

export default TableRefresh;
