import React, { FC } from 'react';
import styled from 'styled-components';
import { EditModeValues } from '../../../../../constants/enums/EditModeValues';
import { ScriptLine } from '../../../../../constants/interfaces/ScriptLine';
import RepScriptItemContainer from './RepScriptLine';
import Drag from '../../../../../ui/icons/Drag';

const StyledScriptLineWrapper = styled.div<{ reverse?: boolean }>`
    display: flex;
    flex-direction: ${props => (props.reverse ? 'row' : 'row-reverse')};
    margin-bottom: 16px;
`;

const StyledScriptHandle = styled.div<{ reverse?: boolean; hidden?: boolean }>`
    color: #b1c0cb;
    display: flex;
    flex-shrink: 0;
    width: 11px;
    align-items: center;
    height: 30px;
    margin-left: ${props => (props.reverse ? 29 : 8)}px;
    margin-right: ${props => (props.reverse ? 8 : 29)}px;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
    display: none;
`;

const RepLine: FC<{
    item: ScriptLine;
    editMode: string;
    disabled: boolean;
    dragHandleProps: any;
}> = ({ item, editMode, dragHandleProps }) => {
    return (
        <div>
            <StyledScriptLineWrapper>
                <StyledScriptHandle
                    className="handle"
                    hidden={editMode === EditModeValues.VIEW}
                    {...dragHandleProps}
                    data-test='challenge-rep-line-drag-handle'
                >
                    <Drag />
                </StyledScriptHandle>
                <RepScriptItemContainer key={item.id} script={item} />
            </StyledScriptLineWrapper>
        </div>
    );
};

export default RepLine;
