import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { History } from 'history';
import { isEmpty } from 'lodash';

import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import {
    LIBRARY_STATUS,
    libraryItemsStatusName,
    useScenarioActionsHelper,
    ScenarioActionsHelper,
    ScenarioActions,
} from '../../tools';
import secondsToMin from '../../../../helpers/functions/convert-time';
import ROUTES from '../../../../routes/routes';
import { pushModal } from '../../../../tools/router';
import {
    useArchiveScenarioService,
    useDeleteLibraryLabelTag,
    useDeleteScenariosService,
    useDownloadScenarioReportService,
    useGenerateChallengeSuccessCallback,
    useRestoreScenarioService,
    useSearchScenariosService,
    useUpdateAllScenarioCheckedStateService,
    useUpdateLibraryScenarioCheckedStateService,
} from '../../store/services';
import {
    selectSelectedScenarios,
    useLibraryScenariosState,
    useUpdatedLibraryScenariosState,
} from '../../store/states';
import { Scenario as ScenarioInterface } from '../../../../constants/interfaces/Scenario';
import { formatDate } from '../../../../helpers/functions/date-convert';
import LibraryNavigationContainer from '../../components/LibraryNavigation';
import {
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import {
    ASSIGN_LABEL_OPTIONS,
    LOAD_ASSIGNED_LABELS,
} from '../../../../constants/enums';
import {
    clearSelectedItemIfNotLoaded,
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabels,
} from '../../../labels/tools';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import { WithLabelsContext } from '../../../portableLabels';
import { Popup } from '../../../../ui/components/Popup';
import { LibraryFilters } from '../../components/Filters/LibraryFilters';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import ScenariosListActions from '../../components/ListActions/ScenariosListActions';
import Document from '../../../../ui/icons/Document';
import { useSessionEffect } from '../../../../features/common';
import { countAppliedFiltersCount } from '../../../../features/teams/pages/ListOfTeams/tools';
import {
    useAssignedLabelsIdsApi,
    useAssignScenarioLabelApi,
    useDeleteScenarioLabelApi,
} from '../../../../api';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { useStyles } from './styles';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useFilter } from '../../../../ui/components/table-wrapper/table/TableDropdown';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { useFetchAllPractisSetsService } from '../../../portablePractisSets/store/hors/withPractisSets/services';
import {
    useUpdateLabelsBulkActionService,
    useUpdateMultipleEntityLabelsBulkActionService,
} from '../../../../services/GeneralBulkActionServices';
import { generateCopyOfEntityName } from '../../../../services/GeneralBulkActionServices/helpers';
import { useGenerateCreateDuplicateScenarioActionList } from '../../services/LibraryBulkActionsService/helpers';
import { CreateNewScenarioInfo, GenerateChallengeData } from '../../services/LibraryBulkActionsService/types';
import { useCreateEditDuplicateLibraryBulActionService, useGenerateChallengeBulActionService } from '../../services/LibraryBulkActionsService';
import { UPDATE_MULTIPLE_LABEL_ACTION } from '../../../../services/GeneralBulkActionServices/constants';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import { DEFAULT_CHALLENGE_TRY_LIMIT } from '../../../challenges/constants';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;
const DEFAULT_FILTER_VALUE = [LIBRARY_STATUS.ACTIVE, LIBRARY_STATUS.DRAFT];

const Scenarios: FC<{
    scenarios?: ListResult<ScenarioInterface>;
    updatedScenarios?: ScenarioInterface;
    searchScenarios(searchParams: SearchParams): void;
    history: History<any>;
    onDeleteScenarios: (
        scenarioIds: number[],
        successCallback?: () => void
    ) => void;
    onArchiveScenario: (scenarioIds: number[]) => void;
    onRestoreScenario: (scenarioIds: number[]) => void;
    selectedLabels?: number[];
    selectedScenarioIds?: number[];
    selectedScenarios?: ScenarioInterface[];
    actionsHelper: ScenarioActionsHelper;
    loading?: boolean;
    setAssignLabelsAction(labels: number[]): void;
    assignedLabels?: number[];
    updateAllScenariosCheckedState(
        scenarioIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedScenarioCheckedState(scenarioId: number): void;
    updatedLabels?: any;
    downloadScenario(scenarioId: number): void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
}> = ({
    scenarios,
    updatedScenarios,
    history,
    onDeleteScenarios,
    onArchiveScenario,
    onRestoreScenario,
    searchScenarios,
    selectedLabels,
    selectedScenarioIds,
    selectedScenarios,
    actionsHelper,
    setAssignLabelsAction,
    assignedLabels,
    updateAllScenariosCheckedState,
    updateSelectedScenarioCheckedState,
    updatedLabels,
    loading,
    downloadScenario,
    saveGlobalLabelFilters,
}) => {
    const classes = useStyles();
    const fetchAllPractisSets = useFetchAllPractisSetsService();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );
    const deleteLibraryLabelTag = useDeleteLibraryLabelTag();
    const assignLabelsToScenarios = useAssignScenarioLabelApi();
    const deleteLabelsFromScenarios = useDeleteScenarioLabelApi();

    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
        'active',
        'draft',
    ]);
    const [savedStatuses, setSavedStatuses] = useState<string[]>([
        'active',
        'draft',
    ]);

    const canAccessPage = useHandleAccessPage();

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [{ field: 'status', value: DEFAULT_FILTER_VALUE }],
        labelIDs: selectedLabels,
        orderBy: {
            field: 'updated_at',
            asc: false,
        },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        refreshSearchParams,
        setFilter,
    } = useSearchParamsState(initialSearchParams);

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchScenarios(searchParams);
        setLastRefreshed(new Date());
        setIsSelectAll(false);
        updateAllScenariosCheckedState([], false, false);
    }, [
        searchParams,
        initialOffset,
        searchScenarios,
        updateAllScenariosCheckedState,
    ]);

    useEffect(() => {
        updateAllScenariosCheckedState([], false, false);
        setIsSelectAll(false);
    }, [location.pathname, updateAllScenariosCheckedState]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchScenarios(searchParams);
    }, [searchScenarios, searchParams]);

    useEffect(() => {
        if (updatedScenarios) refreshSearchParams();
    }, [updatedScenarios, refreshSearchParams]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        if (
            selectedScenarios?.length &&
            scenarios?.count &&
            selectedScenarios.length === +scenarios.count
        ) {
            setIsSelectAll(true);
        }
    }, [scenarios?.count, selectedScenarios]);

    const handleItemEdit = (scenarios: ScenarioInterface) => {
        updateAllScenariosCheckedState([], false, false);
        pushModal(
            history,
            ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE.replace(
                ':scenarioId',
                scenarios.id!.toString()
            )
        );
    };

    const generateChallengeSuccessCallback =
        useGenerateChallengeSuccessCallback();

    const generateChallengeService = useGenerateChallengeBulActionService(
        generateChallengeSuccessCallback
    );

    const handleGenerateChallenge = (scenario: ScenarioInterface) => {
        if (!scenario.id) {
            return;
        }

        const generateChallengeData: GenerateChallengeData = {
            title: `Challenge - ${scenario.title}`,
            description: scenario.description,
            sourceScenarioId: scenario.id,
            labelIds: (scenario.labels as number[]) ?? [],
            lines: scenario.script.lines ?? [],
            tryLimit: DEFAULT_CHALLENGE_TRY_LIMIT,
        };

        generateChallengeService(generateChallengeData);
    };

    const handleDownloadAsPDF = (id?: number) => {
        if (!id) return;
        downloadScenario(id);
    };

    const isCheckboxPartiallySelected =
        scenarios &&
        scenarios.items &&
        selectedScenarios &&
        selectedScenarios.length < scenarios.items.length;

    const updateAllScenariosCheck = useCallback(
        (checked: boolean) => {
            const ids =
                (scenarios &&
                    scenarios.items.map(
                        (item: ScenarioInterface) => item.id as number
                    )) ||
                ([] as number[]);
            updateAllScenariosCheckedState(
                ids,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [scenarios, isCheckboxPartiallySelected, updateAllScenariosCheckedState]
    );

    const handleScenarioCheck = (e: any, sc: ScenarioInterface) => {
        e.stopPropagation();
        if (!!sc.id) {
            updateSelectedScenarioCheckedState(sc.id);
        }
    };

    const getDeletedLabels = useCalculateDeletedLabels();

    const updateMultipleScenariosLabels =
        useUpdateMultipleEntityLabelsBulkActionService(
            assignLabelsToScenarios,
            deleteLabelsFromScenarios,
            isSelectAll,
            {
                actionName: UPDATE_MULTIPLE_LABEL_ACTION,
                actionFunction: searchScenarios,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            limit: 1,
                            offset: Number(scenarios?.count) ?? 0,
                        },
                        shouldSetStore: false,
                    },
                    fieldName: 'searchParams.offset',
                },
            },
            refreshData
        );

    const assignLabels = async () => {
        if (assignedLabels && selectedScenarioIds) {
            const { preAssignedLabels } = await getAssignedLabelsIds(
                selectedScenarioIds,
                LOAD_ASSIGNED_LABELS.SCENARIOS_SCREEN_LIBRARY,
                ASSIGN_LABEL_OPTIONS.SCENARIO,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                return calculatePreSelectedLabels(
                    selectedScenarioIds,
                    scenarios,
                    data
                );
            });

            const deletedLabels = getDeletedLabels(assignedLabels);

            const deletedLabelsFiltered = deletedLabels.filter(
                x =>
                    preAssignedLabels.includes(x) ||
                    preAssignedLabels.includes(-x)
            );
            const selectedLabelsFiltered = assignedLabels.filter(
                x => x > 0 && !preAssignedLabels.includes(x)
            );

            updateMultipleScenariosLabels(
                {
                    entityName: 'scenario',
                    entityIds: selectedScenarioIds,
                },
                selectedLabelsFiltered,
                deletedLabelsFiltered
            );
        }
    };

    const getAssignedLabelsIds = useAssignedLabelsIdsApi();

    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();

    const orderBy = searchParams.orderBy;
    const hcName = useOrderBy('title', orderBy, setOrderBy);
    const hcStatus = useOrderBy('status', orderBy, setOrderBy);
    const hcDuration = useOrderBy('total_duration', orderBy, setOrderBy);
    const hcUpdatedAt = useOrderBy('updated_at', orderBy, setOrderBy);
    const handleChangeEvent = useFilter('status', setFilter);

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const onSelectStatus = (status: string[]) => {
        setSelectedStatuses(status);
    };
    const onSaveStatus = (status: string[]) => {
        setSavedStatuses(status);
    };

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters:
            countAppliedFiltersCount(searchParams) +
            (savedStatuses?.length || 0),
        itemsCount: scenarios?.items.length || 0,
    });

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedScenarios),
        [selectedScenarios, actionsHelper]
    );

    /**
     * @function successDeleteScenarioCallback
     * @returns { void }
     */
    const successDeleteScenarioCallback = useCallback((): void => {
        fetchAllPractisSets();
        refreshSearchParams();
    }, [fetchAllPractisSets, refreshSearchParams]);

    /**
     * @function onConfirmDeleteScenario
     * @param { number } scenarioIdToDelete
     * @returns { void }
     */
    const onConfirmDeleteScenario = useCallback(
        (scenarioIdToDelete: number): void => {
            onDeleteScenarios(
                [scenarioIdToDelete!],
                successDeleteScenarioCallback
            );
        },
        [onDeleteScenarios, successDeleteScenarioCallback]
    );

    /**
     * @function handleDeleteScenario
     * @param { number } id
     * @returns { void }
     */
    const handleDeleteScenario = useCallback(
        (id: number): void => {
            showConfirmationModalDialog({
                modalTitle: 'Are You Sure?',
                description:
                    'Are you sure you want to delete this item? This can’t be undone',
                onConfirm: () => onConfirmDeleteScenario(id),
            });
        },
        [onConfirmDeleteScenario, showConfirmationModalDialog]
    );

    /**
     * @function onOpenAssignLabelAction
     * @returns { void }
     */
    const onOpenAssignLabelAction = (): void => {
        if (scenarios && scenarios.items && selectedScenarioIds) {
            getAssignedLabelsIds(
                selectedScenarioIds,
                LOAD_ASSIGNED_LABELS.SCENARIOS_SCREEN_LIBRARY,
                ASSIGN_LABEL_OPTIONS.SCENARIO,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                const { preAssignedLabels } = calculatePreSelectedLabels(
                    selectedScenarioIds,
                    scenarios,
                    data
                );

                setAssignLabelsAction(preAssignedLabels);
            });
        }
    };

    useEffect(() => {
        const filteredUsers = scenarios
            ? scenarios.items.map(scenario => Number(scenario.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedScenarioIds,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllScenariosCheckedState(usersToClear, false);
        }
    }, [selectedScenarioIds, scenarios, updateAllScenariosCheckedState]);

    useEffect(() => {
        return () => updateAllScenariosCheckedState([], false, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllScenariosCheckedState([], false, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarios]);

    useEffect(() => {
        handleChangeEvent(savedStatuses);
    }, [savedStatuses, handleChangeEvent]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_SCENARIO]);
    }, [canAccessPage]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllScenariosCheckedState([], false, false);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        const ids =
            (scenarios &&
                scenarios.items.map(
                    (item: ScenarioInterface) => item.id as number
                )) ||
            ([] as number[]);
        updateAllScenariosCheckedState(ids, true, isCheckboxPartiallySelected);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllScenariosCheck(event.target.checked);
        setIsSelectAll(false);
    };

    const isMasterCheckBoxChecked = !!selectedScenarioIds?.length;

    const isMasterCheckBoxDisabled =
        !scenarios || (scenarios && scenarios.items.length < 1);

    /**
     * @function checkIsRowChecked
     * @param { ScenarioInterface } scenario
     * @returns { boolean }
     */
    const checkIsRowChecked = (scenario: ScenarioInterface): boolean =>
        !!selectedScenarioIds &&
        !!scenario.id &&
        selectedScenarioIds.includes(scenario.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { ScenarioInterface } scenario
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, scenario: ScenarioInterface) => {
        setIsSelectAll(false);
        handleScenarioCheck(event, scenario);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { ScenarioInterface } scenario
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (scenario: ScenarioInterface): boolean => {
        return labelsOpen === scenario?.['id'];
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { ScenarioInterface } scenario
     * @returns { void }
     */
    const onDeleteLabelHandler = useCallback(
        (labelId: number, scenario: ScenarioInterface): void => {
            const scnerioItem = scenarios?.items.find(
                item => item.id === scenario.id
            );

            if (!isEmpty(scnerioItem)) {
                deleteLibraryLabelTag(
                    labelId,
                    {
                        entityItem: scnerioItem!,
                        entityName: 'scenario',
                    },
                    refreshData
                );
            }
        },
        [deleteLibraryLabelTag, refreshData, scenarios]
    );

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToScenarios,
        deleteLabelsFromScenarios,
        refreshData
    );

    /**
     * @function handleUpdateScenariosLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { number } scenarioId
     * @returns { void }
     */
    const handleUpdateScenariosLabels = (
        addedLabelIds: number[],
        deletedLabelIds: number[],
        scenarioId: number
    ): void => {
        updateLabelsBulkActionService(
            {
                entityName: 'scenario',
                entityId: scenarioId,
            },
            addedLabelIds,
            deletedLabelIds
        );
    };

    const generateDuplicateScenarioActionList =
        useGenerateCreateDuplicateScenarioActionList();

    const duplicateLibraryBulkActionService =
        useCreateEditDuplicateLibraryBulActionService(
            'duplicate',
            'Scenario',
            refreshData
        );

    /**
     * @function handleDuplicateScenario
     * @param { ScenarioInterface } scenario
     * @returns { void }
     */
    const handleDuplicateScenario = useCallback(
        (scenario: ScenarioInterface) => {
            const newScenario: CreateNewScenarioInfo = {
                title: generateCopyOfEntityName(scenario.title),
                description: scenario.description,
                labelIds: scenario?.labels ?? [],
                lines: scenario?.script?.lines ?? [],
            };

            const duplicateScenarioActionList =
                generateDuplicateScenarioActionList(newScenario);

            !isEmpty(duplicateScenarioActionList) &&
                duplicateLibraryBulkActionService([
                    duplicateScenarioActionList,
                ]);
        },
        [duplicateLibraryBulkActionService, generateDuplicateScenarioActionList]
    );

    return (
        <MainWrapper
            subTitle="Library"
            tabs={<LibraryNavigationContainer />}
            hideDivider={!!selectedScenarios?.length}
            htmlPageTitle="Library - Practis"
            dataTest="library"
        >
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={scenarios?.items}
                cornered={selectedLabels && !!selectedLabels.length}
                selectedLabels={selectedLabels}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'library-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: scenarios?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'library-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'library-search-input',
                    },
                    isSelectedItemOptionsVisible:
                        !isEmpty(selectedScenarios) && !isEmpty(scenarios),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedScenarios?.length,
                        totalCount: scenarios?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled: bulkActions.areBulkActionsDisabled(),
                            disabledLabel: bulkActions.areItemsEmpty()
                                ? undefined
                                : "Bulk actions can't be applied",
                            assignLabelsPermissions:
                                bulkActions.isBulkActionVisible(
                                    ScenarioActions.ASSIGN_LABELS
                                )
                                    ? [NEW_PERMISSIONS.ASSIGN_SCENARIO_LABEL]
                                    : [],
                            isAssignLabelsDisabled:
                                bulkActions.isBulkActionDisabled(
                                    ScenarioActions.ASSIGN_LABELS
                                ),
                            onOpenAssignLabelAction: onOpenAssignLabelAction,
                            onAssignLabelsSubmit: assignLabels,
                            isSelectAll: isSelectAll,
                            totalCount: scenarios?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <LibraryFilters
                                        selectedStatuses={
                                            scenarios ? selectedStatuses : []
                                        }
                                        savedStatuses={savedStatuses}
                                        handleSelectStatus={onSelectStatus}
                                        handleSaveStatus={onSaveStatus}
                                        preSelectedLabels={selectedLabels || []}
                                        onLabelsChange={labelChangeHandler}
                                        onSuccessApply={() => hide()}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={250}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={
                                            scenarios
                                                ? savedStatuses.length +
                                                  (searchParams.labelIDs
                                                      ?.length || 0)
                                                : 0
                                        }
                                        disabled={tableStates.disableFilters}
                                        dataTest="library-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && scenarios?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Document,
                        text: 'No Scenarios Yet',
                        dataTest: 'library-no-scenarios',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Scenarios',
                        dataTest: 'library-no-found-scenarios',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'library-no-filtered-scenarios',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'library-scenarios-master-checkbox',
                    },

                    columns: [
                        {
                            title: 'Scenarios',
                            width: 28,
                            ...hcName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-scenarios-title-column',
                        },
                        {
                            title: 'Status',
                            width: 17,
                            ...hcStatus,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-scenarios-status-column',
                        },
                        {
                            title: 'Duration',
                            width: 17,
                            ...hcDuration,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-scenarios-duration-column',
                        },
                        {
                            title: ' Last Updated',
                            width: 17,
                            ...hcUpdatedAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-scenarios-date-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: handleItemEdit,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (scenario: ScenarioInterface) => ({
                            selectedLabels: scenario?.labels || [],
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_CHALLENGE_LABEL,
                            ],

                            onDeleteLabel: (labelId: number) =>
                                onDeleteLabelHandler(labelId, scenario),
                        }),
                        dataTest: 'library-scenarios-item',
                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        scenario: ScenarioInterface
                                    ) => scenario.title,
                                    dataTest: 'library-scenarios-item-title',
                                },
                            },
                            {
                                fieldType: 'BADGE_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    scenario: ScenarioInterface
                                ) => !scenario?.status,
                                fieldProps: {
                                    getBadgeFieldProps: (
                                        scenario: ScenarioInterface
                                    ) => ({
                                        status: scenario?.status,
                                        className: classes.customTableCellStyle,
                                        dataTest:
                                            'library-scenarios-item-status',
                                    }),
                                    renderTitle: (
                                        scenario: ScenarioInterface
                                    ) =>
                                        libraryItemsStatusName(
                                            scenario?.status
                                        ),
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    scenario: ScenarioInterface
                                ) => !scenario?.script?.totalDuration,
                                fieldProps: {
                                    renderTitle: (
                                        scenario: ScenarioInterface
                                    ) =>
                                        secondsToMin(
                                            scenario?.script?.totalDuration
                                        ),
                                    dataTest: 'library-scenarios-item-duration',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    scenario: ScenarioInterface
                                ) => !scenario?.updatedAt,
                                fieldProps: {
                                    renderTitle: (
                                        scenario: ScenarioInterface
                                    ) => formatDate(scenario?.updatedAt),
                                    dataTest: 'library-scenarios-item-date',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (
                                        scenario: ScenarioInterface
                                    ) => ({
                                        open: labelsOpen === scenario.id,
                                        toggleOpen: () =>
                                            handleToggleLabels(
                                                Number(scenario.id)
                                            ),
                                        selectedLabels: scenario?.labels ?? [],
                                        dataTest:
                                            'library-scenarios-item-labels',
                                    }),
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (
                                        scenario: ScenarioInterface
                                    ) => (
                                        <ScenariosListActions
                                            scenario={scenario}
                                            actionsHelper={actionsHelper}
                                            restoreScenario={onRestoreScenario}
                                            handleItemEdit={handleItemEdit}
                                            handleViewScenario={handleItemEdit}
                                            handleGenerateChallenge={
                                                handleGenerateChallenge
                                            }
                                            handleDeleteScenario={
                                                handleDeleteScenario
                                            }
                                            onDuplicateScenario={
                                                handleDuplicateScenario
                                            }
                                            handleDownloadAsPDF={
                                                handleDownloadAsPDF
                                            }
                                            archiveScenario={onArchiveScenario}
                                            assignLabelsToLibrary={(
                                                addedLabelIds,
                                                deletedLabelIds
                                            ) =>
                                                handleUpdateScenariosLabels(
                                                    addedLabelIds,
                                                    deletedLabelIds,
                                                    scenario?.id ?? 0
                                                )
                                            }
                                            isHidden={
                                                !!selectedScenarios?.length
                                            }
                                        />
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const ScenariosContainer: FC = () => {
    const state = useLibraryScenariosState();
    const history = useHistory();
    const deleteScenarios = useDeleteScenariosService();
    const archiveScenario = useArchiveScenarioService();
    const restoreScenario = useRestoreScenarioService();
    const searchScenarios = useSearchScenariosService();
    const updatedScenarios = useUpdatedLibraryScenariosState();
    const searchScenariosDebounced = useSearchDebounced(searchScenarios);
    const labels = useLabelsState();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const updateAllScenariosCheckedState =
        useUpdateAllScenarioCheckedStateService();
    const updateSelectedScenarioCheckedState =
        useUpdateLibraryScenarioCheckedStateService();
    const updatedLabels = useUpdatedLabelsState();
    const downloadScenario = useDownloadScenarioReportService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const actionsHelper = useScenarioActionsHelper();

    const selectedScenarios = useMemo(
        () => selectSelectedScenarios(state),
        [state]
    );

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'libraryScenarios' }}>
            <Scenarios
                onDeleteScenarios={deleteScenarios}
                onArchiveScenario={archiveScenario}
                onRestoreScenario={restoreScenario}
                history={history}
                searchScenarios={searchScenariosDebounced}
                scenarios={state.data}
                loading={state.loading}
                selectedScenarioIds={state.selectedScenarios}
                selectedScenarios={selectedScenarios}
                actionsHelper={actionsHelper}
                updatedScenarios={updatedScenarios.data}
                selectedLabels={labels.selected}
                setAssignLabelsAction={setAssignLabelsAction}
                assignedLabels={labels.assignedLabels}
                updateAllScenariosCheckedState={updateAllScenariosCheckedState}
                updateSelectedScenarioCheckedState={
                    updateSelectedScenarioCheckedState
                }
                updatedLabels={updatedLabels}
                downloadScenario={downloadScenario}
                saveGlobalLabelFilters={saveGlobalLabelFilters}
            />
        </WithLabelsContext.Provider>
    );
};


export default ScenariosContainer;