import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { useChunkRequestsService } from '../../../../../services/ChunkRequestService/hooks';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../../ui/components/ModalDialogs/store/actions';
import { AssignedMemberToTeamType } from '../../../../../api/teams/types';
import {
    useAssignMembersToTeamsApi,
    useAssignUserLabelsApi,
    useEnrollPractisSetsToUserApi,
    useInviteUsers,
} from '../../../../../api';
import {
    ChunkServiceUserDataInterface,
    InviteUsersParametersType,
} from './types';
import { InviteUser } from '../../../../../api/invitations/types';
import { UserInterface } from '../../../../../constants/interfaces/User';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { EnrollmentType } from '../../../../../api/enrollments/types';
import { ChunkRequestActionInterface } from '../../../../../services/ChunkRequestService/hooks/types';
import {
    ASSIGN_LABELS_TO_USERS_ACTION,
    INVITE_USERS_ACTION,
    INVITE_USERS_ITEM_PER_CHUNK_SIZE,
} from './constants';
import { ITEM_PER_CHUNK_SIZE } from '../../../../../services/ChunkRequestService/hooks/constants';
import { ErrorResult } from '../../../../../constants/interfaces/ErrorResult';
import { UserLabelType } from '../../../../../api/users/types';

/**
 * @description function to invite users.
 * Create new user at first then assign labels, teams, practisSets to
 * new created user by using chunk service if needed.
 * @function useInviteUsersBulActionService
 * @param { Function | undefined } onSuccessCallback
 * @param { Function | undefined } onErrorCallback
 * @returns { CallableFunction }
 */
export function useInviteUsersBulActionService(
    onSuccessCallback?: (inviteUsers: ChunkServiceUserDataInterface[]) => void,
    onErrorCallback?: (error?: ErrorResult) => void
) {
    const actionList = useRef<
        ChunkRequestActionInterface<InviteUsersParametersType>[]
    >([]);
    const usersDataRef = useRef<ChunkServiceUserDataInterface[] | null>(null);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();

    const inviteUsers = useInviteUsers();
    const assignLabelsToUsers = useAssignUserLabelsApi();
    const assignPractisSetsToUsers = useEnrollPractisSetsToUserApi();
    const assignMembersToTeams = useAssignMembersToTeamsApi();

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(
        (error?: ErrorResult) => {
            isRunning && setIsRunning(false);

            actionList.current = [];

            onErrorCallback?.(error);
        },
        [isRunning, onErrorCallback]
    );

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);

            actionList.current = [];
            onSuccessCallback?.(usersDataRef?.current ?? []);
        });
    }, [hideModalDialog, isRunning, onSuccessCallback]);

    const { setIsStopped } = useChunkRequestsService(
        actionList.current,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback,
        false
    );

    /**
     * @function handleStopInviteUsersBulkActionService
     * @returns { void }
     */
    const handleStopInviteUsersBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            hideModalDialog();

            setIsRunning(false);
            actionList.current = [];
        });
    }, [hideModalDialog, setIsStopped]);

    /**
     * @description show progress modal and start chunk service action
     * @function handleStartActionModal
     * @returns { void }
     */
    const handleStartActionModal = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'Inviting Users',
                        onStopBulkActionService:
                            handleStopInviteUsersBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopInviteUsersBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    /**
     * @function assignLabelsToUsersService
     * @param { UserInterface[] } users
     * @param { number[] } usersLabelIds
     * @return { Promise<void> }
     */
    const assignLabelsToUsersService = useCallback(
        async (users: UserInterface[], usersLabelIds: number[]) => {
            if (!isEmpty(users) && !isEmpty(usersLabelIds)) {
                const userId = users[0]?.id;

                const processedUsersLabels = usersLabelIds.map(
                    labelId =>
                        ({
                            userId,
                            labelId,
                        } as UserLabelType)
                );

                !isEmpty(processedUsersLabels) &&
                    (await assignLabelsToUsers(processedUsersLabels));
            }
        },
        [assignLabelsToUsers]
    );

    /**
     * @function assignPractisSetsToUsersService
     * @param { UserInterface[] } users
     * @param { PractisSetWithDueDate[] } usersPractisSets
     * @return { Promise<void> }
     */
    const assignPractisSetsToUsersService = useCallback(
        async (
            users: UserInterface[],
            usersPractisSets: PractisSetWithDueDate[]
        ) => {
            if (!isEmpty(users) && !isEmpty(usersPractisSets)) {
                const userId = users[0]?.id;

                const processedUsersPractisSet = usersPractisSets.map(
                    practisSet =>
                        ({
                            userId,
                            ...practisSet,
                        } as EnrollmentType)
                );

                !isEmpty(processedUsersPractisSet) &&
                    (await assignPractisSetsToUsers(processedUsersPractisSet));
            }
        },
        [assignPractisSetsToUsers]
    );

    /**
     * @function assignTeamsToUsersService
     * @param { UserInterface[] } users
     * @param { number[] } usersTeamIds
     * @return { Promise<void> }
     */
    const assignTeamsToUsersService = useCallback(
        async (users: UserInterface[], usersTeamIds: number[]) => {
            if (!isEmpty(users) && !isEmpty(usersTeamIds)) {
                const userId = users[0]?.id;

                const processedUsersTeams = usersTeamIds.map(
                    teamId =>
                        ({
                            userId,
                            teamId,
                        } as AssignedMemberToTeamType)
                );

                !isEmpty(processedUsersTeams) &&
                    (await assignMembersToTeams(processedUsersTeams));
            }
        },
        [assignMembersToTeams]
    );

    /**
     * @description create new users.
     * @function inviteUsersService
     * @param { InviteUser[] } users
     * @returns { Promise<void | number[]> }
     */
    const inviteUsersService = useCallback(
        (users: InviteUser[]) => {
            return inviteUsers(users).then((response: UserInterface[]) => {
                if (!isEmpty(response)) {
                    return response;
                }
            });
        },
        [inviteUsers]
    );

    /**
     * @function handleStartInviteUsersBulkActionService
     * @returns { void }
     */
    const handleStartInviteUsersBulkActionService = useCallback(() => {
        if (usersDataRef.current) {
            const inviteUsersOptions = {
                parameters: {
                    users: usersDataRef?.current?.map(user => ({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        roleId: user.roleId,
                        companyId: user.companyId,
                    })) as InviteUser[],
                },
                fieldName: 'users',
            };

            const InviteUsersAction = {
                actionName: INVITE_USERS_ACTION,
                actionFunction: inviteUsersService,
                actionFunctionOptions: inviteUsersOptions,
                itemPerChunk: INVITE_USERS_ITEM_PER_CHUNK_SIZE,
                childActionList: [
                    {
                        actionName: ASSIGN_LABELS_TO_USERS_ACTION,
                        actionFunction: assignLabelsToUsersService,
                        actionFunctionOptions: {
                            parameters: {
                                users: [],
                                labelIds: usersDataRef.current.map(
                                    user => user.labelIds
                                ),
                            },
                            fieldName: 'labelIds',
                            secondaryFieldName: 'users',
                        },
                        itemPerChunk: ITEM_PER_CHUNK_SIZE,
                    },
                    {
                        actionFunction: assignTeamsToUsersService,
                        actionFunctionOptions: {
                            parameters: {
                                users: [],
                                teamIds: usersDataRef.current.map(
                                    user => user.teamsIds
                                ),
                            },
                            fieldName: 'teamIds',
                            secondaryFieldName: 'users',
                        },
                        itemPerChunk: ITEM_PER_CHUNK_SIZE,
                    },
                    {
                        actionFunction: assignPractisSetsToUsersService,
                        actionFunctionOptions: {
                            parameters: {
                                users: [],
                                practisSets: usersDataRef.current.map(
                                    user => user.practisSets
                                ),
                            },
                            fieldName: 'practisSets',
                            secondaryFieldName: 'users',
                        },
                        itemPerChunk: ITEM_PER_CHUNK_SIZE,
                    },
                ],
            } as ChunkRequestActionInterface<any>;

            actionList.current.push(InviteUsersAction);

            !isEmpty(actionList.current) && handleStartActionModal();
        }
    }, [
        assignLabelsToUsersService,
        assignPractisSetsToUsersService,
        assignTeamsToUsersService,
        handleStartActionModal,
        inviteUsersService,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartInviteUsersBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    /**
     * @description Call the service runner.
     * @function callback
     * @param { ChunkServiceUserDataInterface[] } usersData
     * @returns { void }
     */
    return useCallback((usersData: ChunkServiceUserDataInterface[]) => {
        usersDataRef.current = usersData;

        setIsRunning(true);
    }, []);
}