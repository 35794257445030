import { PRACTIS_SUPPORT_EMAIL } from "../../ui/layout/Sidebar/constants";
import { isSafari } from "./detect-browsers";
import { getOS } from "./general";
import * as qs from 'query-string';

const WINDOW_OPTIONS = 'height=1000,width=1000,left=-1000,top=-1000';

/**
 * @function sendEmail
 * @description opens email app with a prefilled email form including subject and body
 * @param { string } subject
 * @param { string? } body
 * @returns { void }
 */
export function sendEmail(subject: string, body?: string) {
    const isItSafari = isSafari();
    const userOS = getOS();

    const emailLink = qs.stringifyUrl({
        url: `mailto:${PRACTIS_SUPPORT_EMAIL}`,
        query: { subject, body, format: 'html' }
    });

    if (isItSafari || userOS === 'Win') {
        window.location.href = emailLink;
    } else {
        window.open(emailLink, '_blank', WINDOW_OPTIONS);
    }
}