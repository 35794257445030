import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const UserActivity = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 40 40"
                className={className}
                width="40"
                height="40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g clip-path="url(#1ox9uh36ua)">
                    <path d="M32.917 23.75 35 21.667l2.5 1.25 2.5 3.75v5h-9.167v-5l2.084-2.917zM12.917 10.833h3.75l2.083 1.25 1.667 4.584V22.5h-7.5l1.25-5.417-1.667-3.75.417-2.5zM13.333 30.833 15 29.583l2.083-.833L20 34.583v4.584h-8.75l.417-5 .416-2.084 1.25-1.25z" fill="#4AA9E2"/>
                    <path d="M25 6.12v-.705C25 2.89 22.757.833 20 .833s-5 2.056-5 4.582v.706c0 1.632.937 3.067 2.344 3.879h5.312C24.063 9.188 25 7.753 25 6.12zM20 14.9v5.542M23.9 22.86a4.685 4.685 0 0 1 2.768-.899h7.844c2.6 0 4.707 2.107 4.707 4.707v4.707H27.43M12.569 31.375H.781v-4.707c0-2.6 2.107-4.707 4.707-4.707h7.844c1.035 0 1.991.334 2.768.9M23.53 10.194 20 14.901l-3.53-4.707h7.06z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30.59 21.961a4.314 4.314 0 0 1-4.315-4.314v-.785a4.314 4.314 0 0 1 8.63 0v.785a4.314 4.314 0 0 1-4.315 4.314z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="m34.12 21.961-3.53 4.707-3.53-4.707h7.06zM30.59 26.668v4.706M9.41 21.961a4.314 4.314 0 0 1-4.314-4.314v-.785a4.314 4.314 0 0 1 8.628 0v.785A4.314 4.314 0 0 1 9.41 21.96zM12.94 21.961l-3.53 4.707-3.53-4.707h7.06zM9.41 26.668v4.706" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.689 13.198a4.708 4.708 0 0 1 4.389-3.004h7.844a4.708 4.708 0 0 1 4.389 3.004" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.096 25.491v-.785A5.102 5.102 0 0 0 20 19.611a5.102 5.102 0 0 0-5.096 5.095v.785c0 1.816.956 3.412 2.39 4.314h5.412a5.095 5.095 0 0 0 2.39-4.314z" fill="#fff" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M23.53 29.805 20 34.512l-3.53-4.707h7.06zM20 34.512v4.707" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M23.922 29.805c2.6 0 4.707 2.108 4.707 4.707v4.707H11.37v-4.707c0-2.6 2.107-4.707 4.707-4.707h7.844z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="1ox9uh36ua">
                        <path fill="#fff" d="M0 0h40v40H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default UserActivity;