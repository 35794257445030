import { ChangeEvent } from 'react';
import { Field, Form, Formik } from 'formik';
import { Variables } from '../../../theme/variables';
import { FormikField } from '../../../ui/components/FormikField';
import { Button } from '../../../ui/components/Button';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import BackArrowImage from '../../../assets/images/icons/back-arrow.svg';
import { ResetPasswordSchema } from './constants';
import { ResetPasswordViewProps } from './types';
import {
    Container,
    Title,
    BackArrow,
    GeneralError,
    FormContainer,
    FieldContainer,
    ButtonContainer,
    FooterText
} from './styles';

function ResetPasswordView ({
    isSubmitting,
    errorMessage,
    onClearError,
    onSubmit,
    onBack
}: ResetPasswordViewProps) {
    return (
        <LoginAuthWrapper contentWidth={343} heightToShowScroll={600}>
            <Container>
                <Title>
                    <BackArrow src={BackArrowImage} onClick={onBack} />
                    Reset Password
                </Title>
                {
                    !!errorMessage && (
                        <GeneralError>{errorMessage}</GeneralError>
                    )
                }
                <FormContainer>
                    <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: ''
                        }}
                        onSubmit={values => onSubmit(values.password)}
                        validationSchema={ResetPasswordSchema}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <FieldContainer>
                                    <Field
                                        type="password-with-eye"
                                        component={FormikField}
                                        name="password"
                                        label="New Password"
                                        height="56px"
                                        colorTheme="darkThree"
                                        fontSize="15px"
                                        value={values.password}
                                        labelFontSize="15px"
                                        labelFontWeight={600}
                                        errorColor={Variables.Colors.tomato}
                                        className="login-input"
                                        dataTest="new-password"
                                        hint='Password must be 8 characters long.'
                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('password', e.target.value);
                                            onClearError();
                                        }}
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <Field
                                        type="password-with-eye"
                                        component={FormikField}
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        height="56px"
                                        colorTheme="darkThree"
                                        fontSize="15px"
                                        value={values.confirmPassword}
                                        labelFontSize="15px"
                                        labelFontWeight={600}
                                        errorColor={Variables.Colors.tomato}
                                        className="login-input"
                                        dataTest="confirm-password"
                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('confirmPassword', e.target.value);
                                            onClearError();
                                        }}
                                    />
                                </FieldContainer>
                                <ButtonContainer>
                                    <Button
                                        height='56px'
                                        width='343px'
                                        fontSize={15}
                                        loading={isSubmitting}
                                        disabled={!values.password || !values.confirmPassword}
                                    >
                                        Reset Password
                                    </Button>
                                </ButtonContainer>
                                <FooterText>
                                    If you're having trouble accessing the product, please let us know at <a href='mailto:info@gopractis.com'>info@gopractis.com</a>.
                                </FooterText>
                            </Form>
                        )}
                    </Formik>
                </FormContainer>
            </Container>
        </LoginAuthWrapper>
    );
};

export default ResetPasswordView;
