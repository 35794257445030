import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const DownloadIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                d="M351.467 192h-14.934c-.294 0-.533-.257-.533-.574v-4.592c0-.317.239-.574.533-.574.295 0 .534.257.534.574v4.018h13.866v-4.018c0-.317.24-.574.534-.574.294 0 .533.257.533.574v4.592c0 .318-.239.574-.533.574zm-7.085-4.687c-.005.006-.014.004-.02.01-.09.091-.211.148-.345.153h-.003c-.143.005-.287-.045-.396-.163l-3.197-4.014c-.21-.226-.21-.593 0-.82.21-.225.551-.225.761 0l2.285 2.87v-8.775c0-.317.239-.574.533-.574.295 0 .533.257.533.574v8.775l2.285-2.87c.21-.225.55-.225.76 0 .211.227.211.594 0 .82l-3.196 4.014z"
                                transform="translate(-656 -576) translate(320 400)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default DownloadIcon;
