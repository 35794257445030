import React, { ChangeEventHandler, FC } from 'react';
import Checkbox from '../../../Checkbox';
import { Variables } from '../../../../../theme/variables';
import styled from 'styled-components';
import { DEFAULT_SIZE } from './constants';

const CheckboxContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: initial;
    flex-shrink: 0;
    margin-right: 16px;
`;

const StyledCheckbox = styled(Checkbox)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
`;

export const TableCheckbox: FC<{
    checked: boolean;
    handleCheck: ChangeEventHandler<HTMLInputElement>;
    partial?: boolean;
    disabled?: boolean;
    size?: number;
}> = ({ checked, partial, disabled, handleCheck, size }) => {
    return (
        <CheckboxContainer
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <StyledCheckbox
                size={size ? size : DEFAULT_SIZE}
                checked={checked}
                partial={partial}
                disabled={disabled}
                background={Variables.Colors.white}
                handleChange={handleCheck}
                border={Variables.Colors.cloudyBlue}
            />
        </CheckboxContainer>
    );
};
