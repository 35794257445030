export const commandTexts = {
    situation: 'Situation',
    prospect: 'Customer',
    product: 'Product',
    objectiveRep: 'Objective',
    objectiveNuances: 'Nuances & Tactics',
    salesMethod: 'Sales Methodology'
}

export const commandPattern = /Change\s(Situation|Customer|Product|Objective|Nuances & Tactics|Sales Methodology)\sto:/g

export const formatConversationToClipboard = (title: string, conversation: Array<{ role: string, text: string }>, summary: string): string => {
    const formattedConversation = conversation.map(({ role, text }) => `[${role.toUpperCase()}] ${text}`).join('\n\n');
    const formattedRoleplay = `${title}\n---\n${formattedConversation}\n---\n${summary}`;
    return formattedRoleplay;
}


export function downloadTxtFile(text: string, filename: string) {
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
  
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
  
    link.click();
  
    document.body.removeChild(link);
  
    URL.revokeObjectURL(url);
}

  