import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import { useTelepromterState } from '../../store/state';
import RepLine from './RepLine';
import CustomerLine from './CustomerLine';
import RecordRTC from 'recordrtc';
import { ScriptLineSpeaker } from '../../../../constants/interfaces/ScriptLine';
import ScriptLineDuration from './ScriptLineDuration';
import { Variables } from '../../../../theme/variables';
import { Loading } from '../../../../ui/components/LoadingCopmonent';
import Button from '../../Components/Button';
import Countdown from '../../Components/Countdown';
import {
    useAddGeneratedScriptLineService,
    useResetTelepromterService,
    useSetRecordedVideoService,
} from '../../store/services';
import { useHistory } from '../../../../tools/router';
import ROUTES from '../../../../routes/routes';
import Close from '../../../../ui/icons/Close';

const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${props => props.theme.Colors.darkThree};
`;

const ExitIcon = styled.div`
    height: 14px;
    width: 14px;
    color: ${props => props.theme.Colors.black};
    position: absolute;
    top: 74px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
    user-select: none;
    &:hover {
        opacity: 0.7;
    }
    &:active {
        opacity: 0.6;
    }
`;

const Hidden = styled.div`
    visibility: hidden;
`;

const ScriptLineContainer = styled.div`
    flex: 1;
    width: 100%;
    background: ${props => props.theme.Colors.white};
    overflow: auto;
`;

const VideoContainer = styled.div`
    height: 376px;
    width: 100%;
    position: relative;
`;

const GoNextButton = styled.div`
    position: absolute;
    height: 184px;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Training = () => {
    const video = useRef<any>(null);
    let recorder: any = useRef<any>(null);
    const history = useHistory();
    const resetTelepromter = useResetTelepromterService();

    const useCaptureCamera = () =>
        useCallback((callback: any) => {
            return navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                    video: {
                        height: 375,

                        width: 380,
                    },
                })
                .then(function(camera) {
                    return callback(camera);
                })
                .catch(err => console.log(err));
        }, []);

    const captureCamera = useCaptureCamera();
    const addGeneratedScriptLine = useAddGeneratedScriptLineService();
    const setRecordedVideo = useSetRecordedVideoService();

    const useInitializeCamera = () =>
        useCallback(() => {
            return captureCamera(function(camera: any) {
                if (!video.current || recorder.current) return;
                video.current.muted = true;
                video.current.volume = 0;
                video.current.srcObject = camera;
                recorder.current = new RecordRTC(camera, {
                    type: 'video',
                });
            });
        }, []);

    const initializeCamera = useInitializeCamera();

    useEffect(() => {
        if (video.current) {
            initializeCamera().then();
        }
    }, [initializeCamera]);

    const startRecord = useCallback(() => {
        return captureCamera(function(camera: any) {
            recorder.current.startRecording();
            recorder.current.camera = camera;
        });
    }, [captureCamera]);

    const stopRecordingCallback = useCallback(() => {
        setRecordedVideo(URL.createObjectURL(recorder.current.getBlob()));
        recorder.current.camera.getTracks().forEach(function (track: any) {
            track.stop();
        });
        recorder.current.destroy();
        recorder.current = null;
    }, [setRecordedVideo]);

    const useStopRecordService = () =>
        useCallback(() => {
            if (recorder && recorder.current) {
                recorder.current.stopRecording(stopRecordingCallback);
            }
            if (video.current) {
                video.current.srcObject
                    .getTracks()
                    .forEach(function(track: any) {
                        track.stop();
                    });
            }
        }, []);

    useEffect(() => {
        return () => {
            if (recorder.current) {
                recorder.current.stopRecording();
                recorder.current.camera
                    .getTracks()
                    .forEach(function(track: any) {
                        track.stop();
                    });

                recorder.current.destroy();
                recorder.current = null;
            }

            if (video.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                video.current.srcObject
                    .getTracks()
                    .forEach(function(track: any) {
                        track.stop();
                    });
            }
        };
    }, []);

    const stopRecord = useStopRecordService();
    const renderVideo = useMemo(() => {
        return <StyledVideo ref={video} autoPlay={true} playsInline={true} />;
    }, [video]);

    const [countdown, setCountdown] = useState(3);
    const [startTraining, setStartTraining] = useState(false);

    const telepromterState = useTelepromterState();
    const scenario = telepromterState.scenario;

    const [tempScriptLines, setTempScriptLines] = useState(
        scenario.script.lines
    );

    useEffect(() => {
        setTempScriptLines(scenario.script.lines);
    }, [scenario.script.lines]);

    const currentScriptLine = tempScriptLines[0];

    // Start training / recording
    useEffect(() => {
        if (countdown === 0) {
            resetTelepromter();
            setStartTraining(true);
            startRecord().then();
        }
    }, [countdown, setStartTraining, startRecord, resetTelepromter]);

    const [repLineDuration, setRepLineDuration] = useState(0);

    useEffect(() => {
        if (
            !currentScriptLine ||
            (currentScriptLine &&
                currentScriptLine.speaker !== ScriptLineSpeaker.REP)
        ) {
            return;
        }
        const timer = setInterval(() => {
            setRepLineDuration(prevState => prevState + 0.1);
        }, 100);
        return () => {
            clearInterval(timer);
        };
    }, [setRepLineDuration, currentScriptLine]);

    const redirectToExit = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.EXIT.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    const redirectToCompleted = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.COMPLETED.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    const handleGoNext = useCallback(() => {
        if (currentScriptLine.speaker === ScriptLineSpeaker.CUSTOMER) {
            addGeneratedScriptLine({
                id: currentScriptLine.id,
                text: currentScriptLine.text,
                duration: currentScriptLine.duration,
                audioUrl: currentScriptLine.audioUrl,
                position: currentScriptLine.position,
                speaker: currentScriptLine.speaker,
            });
            setRepLineDuration(0);
        } else {
            addGeneratedScriptLine({
                id: currentScriptLine.id,
                text: currentScriptLine.text,
                duration: parseFloat(repLineDuration.toFixed(2)),
                audioUrl: currentScriptLine.audioUrl,
                position: currentScriptLine.position,
                speaker: currentScriptLine.speaker,
            });
            setRepLineDuration(0);
        }
        if (
            currentScriptLine.id ===
            tempScriptLines[tempScriptLines.length - 1].id
        ) {
            stopRecord();
            const timeout = setTimeout(() => {
                redirectToCompleted();
            }, 300);

            return () => {
                clearInterval(timeout);
            };
        }
        setTempScriptLines(prevLines => {
            const prevScriptLines = [...prevLines];
            prevScriptLines.shift();
            return prevScriptLines;
        });
    }, [
        addGeneratedScriptLine,
        currentScriptLine,
        stopRecord,
        tempScriptLines,
        setRepLineDuration,
        repLineDuration,
        redirectToCompleted,
    ]);

    useEffect(() => {
        if (!currentScriptLine || !startTraining) return;
        let lineTimer = 0;

        const lineDurationTimer = setInterval(() => {
            lineTimer += 0.3;
            if (
                lineTimer >= currentScriptLine.duration &&
                currentScriptLine.speaker === ScriptLineSpeaker.CUSTOMER
            ) {
                handleGoNext();
                lineTimer = 0;
            }
        }, 300);

        return () => {
            clearInterval(lineDurationTimer);
        };
    }, [currentScriptLine, tempScriptLines, startTraining, handleGoNext]);

    if (telepromterState.loading || !currentScriptLine) {
        return (
            <Container>
                <Loading />
                <Hidden>{renderVideo}</Hidden>
            </Container>
        );
    }

    return (
        <Container>
            <ExitIcon onClick={redirectToExit}>
                <Close />
            </ExitIcon>
            {countdown > 0 && (
                <Countdown countdown={countdown} setCountdown={setCountdown} />
            )}
            <ScriptLineDuration
                key={currentScriptLine.id}
                duration={currentScriptLine.duration}
                color={
                    currentScriptLine.speaker === ScriptLineSpeaker.CUSTOMER
                        ? Variables.Colors.cloudyBlue
                        : Variables.Colors.paleRed
                }
                started={startTraining}
            />
            {startTraining && (
                <audio
                    src={currentScriptLine.audioUrl}
                    muted={currentScriptLine.speaker === ScriptLineSpeaker.REP}
                    autoPlay={true}
                />
            )}
            <ScriptLineContainer>
                {tempScriptLines.map(line => {
                    if (line.speaker === ScriptLineSpeaker.CUSTOMER) {
                        return (
                            <CustomerLine
                                key={line.id}
                                text={line.text}
                                disabled={
                                    line.id !== currentScriptLine.id ||
                                    !startTraining
                                }
                            />
                        );
                    } else {
                        return (
                            <RepLine
                                key={line.id}
                                text={line.text}
                                disabled={
                                    line.id !== currentScriptLine.id ||
                                    !startTraining
                                }
                            />
                        );
                    }
                })}
            </ScriptLineContainer>
            <VideoContainer>
                {renderVideo}
                {currentScriptLine.speaker === ScriptLineSpeaker.REP && (
                    <GoNextButton>
                        <Button onClick={handleGoNext} />
                    </GoNextButton>
                )}
            </VideoContainer>
        </Container>
    );
};

export default Training;
