import { forwardRef, useCallback } from 'react';

import FiltersIcon from '../../icons/FiltersIcon';
import { Tooltip } from '../Tooltip';
import {
    FiltersDot,
    FiltersDotContainer,
    IconContainer,
    IconHolder,
    Root,
} from './styles';

interface Props {
    open: boolean;
    disabled?: boolean;
    filtersCount?: number;
    toggleOpen(): void;
    dataTest?: string;
}

export const FiltersButton = forwardRef<HTMLButtonElement, Props>(
    (props, ref) => {
        const {
            toggleOpen,
            open,
            disabled = false,
            filtersCount = 0,
            dataTest,
        } = props;

        const handleClick = useCallback(
            () => !disabled && toggleOpen(),
            [disabled, toggleOpen]
        );

        return (
            <Root
                ref={ref}
                open={open}
                disabled={disabled}
                onClick={handleClick}
                data-test={dataTest}
            >
                <Tooltip label="Filters" preset="button-icon" dataTest="filters-tooltip">
                    <IconContainer>
                        <IconHolder disabled={disabled}>
                            <FiltersIcon />
                        </IconHolder>
                        {filtersCount > 0 && (
                            <FiltersDotContainer>
                                <FiltersDot
                                    data-test={
                                        dataTest && `${dataTest}-counter`
                                    }
                                />
                            </FiltersDotContainer>
                        )}
                    </IconContainer>
                </Tooltip>
            </Root>
        );
    }
);
