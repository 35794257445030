import React, { FC } from 'react';
import { LogEvent } from '../models/LogEvent';
import { LogItem } from '../models/LogItem';
import {
    atTime,
    companyName,
    userName,
    userRoleTitle,
    companyAndUserNames,
    platformVersionName,
} from '../tools';
import { Team } from '../../../constants/interfaces/Team';
import { TeamMembership } from '../../../constants/interfaces/TeamMembership';
import { CompanyInterface } from '../../../constants/interfaces/Company';
import { UserInterface } from '../../../constants/interfaces/User';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { Challenge } from '../../../constants/interfaces/Challenge';
import { Submission } from '../../../constants/interfaces/Reviews';
import { Theme } from '../../../constants/interfaces/Theme';

export const Details: FC<{
    event: LogItem;
}> = ({ event: e }) => {
    switch (e.event) {
        case LogEvent.CREATE_COMPANY:
        {
            const c: CompanyInterface | undefined = e.data.payload;
            //ex: Practis Admin Max Rogozhin (maxim@tula.co) created a Company - TulaCo at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} {userName(e.creator)}{' '}
                    created a Company -{' '}
                    {companyName(c)} {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_COMPANY:
        {
            const c: CompanyInterface | undefined = e.data.payload;
            //ex: Practis Admin Max Rogozhin (maxim@tula.co) deleted a Company - TulaCo at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} {userName(e.creator)}{' '}
                    deleted a Company -{' '}
                    {companyName(c)} {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_COMPANY:
        {
            const c: CompanyInterface | undefined = e.data.payload;
            //ex: Practis Admin Max Rogozhin (maxim@tula.co) updated a Company - TulaCo at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} {userName(e.creator)}{' '}
                    updated a Company -{' '}
                    {companyName(c)} {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_PRACTIS_ADMIN:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Practis Admin Max Rogozhin (maxim@tula.co) created a Practis Admin - Vano Khuroshvili (VKhuroshvili@tula.co) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} {userName(e.creator)}{' '}
                    created a {userRoleTitle(user)} -{' '}
                    {userName(user)} {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_PRACTIS_ADMIN:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Practis Admin Max Rogozhin (maxim@tula.co) deleted a Practis Admin - Vano Khuroshvili (VKhuroshvili@tula.co) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} {userName(e.creator)}{' '}
                    deleted a {userRoleTitle(user)} -{' '}
                    {userName(user)} {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_PRACTIS_ADMIN:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Practis Admin Max Rogozhin (maxim@tula.co) updated a Practis Admin - Vano Khuroshvili (VKhuroshvili@tula.co) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} {userName(e.creator)}{' '}
                    updated a {userRoleTitle(user)} -{' '}
                    {userName(user)} {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_SCENARIO:
        {
            const scenario: Scenario | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) created a Scenario - <Scenario name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Scenario - {scenario?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_SCENARIO:
        {
            const scenario: Scenario | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) deleted a Scenario - <Scenario name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Scenario - {scenario?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_SCENARIO:
        {
            const scenario: Scenario | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) updated a Scenario - <Scenario name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Scenario - {scenario?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_PRACTIS_SET:
        {
            const practisSet: PractisSets | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) created a Practis Set - <Practis Set name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Practis Set - {practisSet?.name || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_PRACTIS_SET:
        {
            const practisSet: PractisSets | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) deleted a Practis Set - <Practis Set name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Practis Set - {practisSet?.name || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_PRACTIS_SET:
        {
            const practisSet: PractisSets | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) updated a Practis Set - <Practis Set name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Practis Set - {practisSet?.name || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_CHALLENGE:
        {
            const challenge: Challenge | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) created a Challenge - <Challenge name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Challenge - {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_CHALLENGE:
        {
            const challenge: Challenge | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) updated a Challenge - <Challenge name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Challenge - {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_CHALLENGE:
        {
            const challenge: Challenge | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) deleted a Challenge - <Challenge name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Challenge - {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.GENERATE_CHALLENGE_FROM_SCENARIO:
        {
            const challenge: Challenge | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) generated a Challenge - <Challenge name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    generated a Challenge - {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.INVITE_USER:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) invited Vasya Pupkin (vpupkin@gmail.com) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    invited {userName(user)}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.RESEND_INVITATION:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) resent invitation to a Trainer - Vasya Pupkin (vpupkin@gmail.com) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    resent invitation to a {userRoleTitle(user)} - {userName(user)}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.REVOKE_INVITATION:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) revoked invitation of a Trainer - Vasya Pupkin (vpupkin@gmail.com) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    revoked invitation of a {userRoleTitle(user)} - {userName(user)}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_USER:
        {
            const user: UserInterface | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) deleted a Trainer - Vasya Pupkin (vpupkin@gmail.com) at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a {userRoleTitle(user)} - {userName(user)}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_STAGING:
        {
            const staging: { name: string } | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) created a Staging - <Staging name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Staging - {staging?.name || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_STAGING:
        {
            const staging: { name: string } | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) updated a Staging - <Staging name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Staging - {staging?.name || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_STAGING:
        {
            const staging: { name: string } | undefined = e.data.payload;
            //ex: Company Admin from <Company Name> Alen Korbut (akorbut@tula.co) deleted a Staging - <Staging name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Staging - {staging?.name || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_SUBMISSION:
        {
            const practisSet: PractisSets | undefined = e.data.payload.practisSet;
            const challenge: Challenge | undefined = e.data.payload.challenge;
            //ex: Company Admin from Poetry Edgar Allan Poe (edgarallanpoe@mailinator.com) created a Submission for Practis Set: Poems by Edgar Allan Poe and Challenge: Eldorado at 7:20:18 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Submission for Practis Set: {practisSet?.name || ''} and
                    Challenge: {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_SUBMISSION:
        {
            const practisSet: PractisSets | undefined = e.data.payload.practisSet;
            const challenge: Challenge | undefined = e.data.payload.challenge;
            //ex: Company Admin from Poetry Edgar Allan Poe (edgarallanpoe@mailinator.com) updated a Submission for Practis Set: Poems by Edgar Allan Poe and Challenge: Eldorado at 7:20:18 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Submission for Practis Set: {practisSet?.name || ''} and
                    Challenge: {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_SUBMISSION:
        {
            const practisSet: PractisSets | undefined = e.data.payload.practisSet;
            const challenge: Challenge | undefined = e.data.payload.challenge;
            //ex: Company Admin from Poetry Edgar Allan Poe (edgarallanpoe@mailinator.com) deleted a Submission for Practis Set: Poems by Edgar Allan Poe and Challenge: Eldorado at 7:20:18 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Submission for Practis Set: {practisSet?.name || ''} and
                    Challenge: {challenge?.title || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        // case LogEvent.REVIEW_SUBMISSION:
        // {
        //     //Not tracked yet on BE side 
        // }
        case LogEvent.CREATE_SCORE:
        {
            const submission: Submission | undefined = e.data.payload;
            //ex: Practis Admin from TestCo Anna Toloraia (atoloraia5@tula.co) created a review for a Submission with id: 21: at 5:22:46 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a review for a Submission with id: {submission?.id || ''}:{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_SCORE:
        {
            const submission: Submission | undefined = e.data.payload;
            //ex: Practis Admin from TestCo Anna Toloraia (atoloraia5@tula.co) updated a review for a Submission with id: 21: at 5:22:46 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a review for a Submission with id: {submission?.id || ''}:{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_SCORE:
        {
            const submission: Submission | undefined = e.data.payload;
            //ex: Practis Admin from TestCo Anna Toloraia (atoloraia5@tula.co) deleted a review for a Submission with id: 21: at 5:22:46 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a review for a Submission with id: {submission?.id || ''}:{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_SETTING:
        {
            const settings: any | undefined = e.data.payload;
            //ex: Practis Admin from TestCo Anna Toloraia (atoloraia5@tula.co) updated IOS version at 5:22:46 pm Apr 1st, 2020
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated {platformVersionName(settings.key) || settings.key || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.CREATE_TEAM:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) created a Team - <Team name> at 15:00 12.09.2019
            const t: Team | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Team - {t?.name}{' '}
                    {atTime(t?.createdAt || e.createdAt)}
                </>
            ); 
        }
        case LogEvent.UPDATE_TEAM:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) updated a Team - <Team name> at 15:00 12.09.2019
            const t: Team | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Team - {t?.name}{' '}
                    {atTime(t?.createdAt || e.createdAt)}
                </>
            ); 
        }
        case LogEvent.DELETE_TEAM:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) deleted a Team - <Team name> at 15:00 12.09.2019
            const t: Team | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Team - {t?.name}{' '}
                    {atTime(t?.createdAt || e.createdAt)}
                </>
            ); 
        }
        case LogEvent.DUPLICATE_TEAM:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) duplicated a Team - <Team name> at 15:00 12.09.2019
            const t: Team | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    duplicated a Team - {t?.name}{' '}
                    {atTime(t?.createdAt || e.createdAt)}
                </>
            ); 
        }
        case LogEvent.USER_ADDED_INTO_TEAM:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) added a User with id: <User Id> into a Team with id: <Team Id> at 15:00 12.09.2019
            const tm: TeamMembership | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    added a User with id: {tm?.userId} into a Team with id: {tm?.teamId}{' '}
                    {atTime(tm?.createdAt || e.createdAt)}
                </>
            ); 
        }
        case LogEvent.USER_TEAM_LEAD_PROMOTION:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) promoted a User to Team Lead at 15:00 12.09.2019
            //const tmIds: number[] | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    promoted a User to Team Lead{' '}
                    {atTime(e.createdAt)}
                </>
            ); 
        }
        case LogEvent.USER_TEAM_LEAD_REVOKE:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) revoked Team Lead of a User at 15:00 12.09.2019
            //const tmIds: number[] | undefined = e.data.payload;
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    revoked Team Lead of a User{' '}
                    {atTime(e.createdAt)}
                </>
            ); 
        }
        case LogEvent.CREATE_THEME:
        {
            const theme: Theme | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) created a Theme - <Theme name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    created a Theme - {theme?.title || ''}{' '}
                    {atTime(theme?.createdAt || e.createdAt)}
                </>
            );
        }
        case LogEvent.UPDATE_THEME:
        {
            //const theme: Theme | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) updated a Theme - <Theme name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    updated a Theme{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.DELETE_THEME:
        {
            const theme: Theme | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) deleted a Theme - <Theme name> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    deleted a Theme - {theme?.title || ''}{' '}
                    {atTime(theme?.createdAt || e.createdAt)}
                </>
            );
        }
        case LogEvent.ASSIGN_THEME_TO_PRACTISE_SET:
        {
            const assignment: { practisSetId: number; } | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) assigned a Theme to a Practis Set with id: <Practis Set Id> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    assigned a Theme to a Practis Set with id: {assignment?.practisSetId || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.ADD_COVER_INTO_THEME:
        {
            const assignment: { coverId: number; themeId: number } | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) added a Cover into a Theme with id: <Theme Id> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    added a Cover into a Theme with id: {assignment?.themeId || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.REMOVE_COVER:
        {
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) removed a Cover at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    removed a Cover{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.ASSIGN_COVER_TO_PRACTISE_SET:
        {
            const assignment: { coverId: number; practisSetId: number } | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) assigned a Cover to a Practis Set with id: <Practis Set Id> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    assigned a Cover to a Practis Set with id: {assignment?.practisSetId || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
        case LogEvent.ASSIGN_COVER_TO_SCENARIO:
        {
            const assignment: { coverId: number; scenarioId: number; } | undefined = e.data.payload;
            //ex: Trainer from <Company Name> Daniil Kurchanov (dkurchanov@tula.co) assigned a Cover to a Scenario with id: <Scenario Id> at 15:00 12.09.2019
            return (
                <>
                    {userRoleTitle(e.creator)} from {companyAndUserNames(e.company, e.creator)}{' '}
                    assigned a Cover to a Scenario with id: {assignment?.scenarioId || ''}{' '}
                    {atTime(e.createdAt)}
                </>
            );
        }
    }
    return <>{JSON.stringify(e.data.payload)}</>;
};