import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import styled from 'styled-components';
import { EnrollmentStatus } from '../../../../../../constants/interfaces/Enrollments';
import { Variables } from '../../../../../../theme/variables';
import { Button } from '../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../ui/components/Fieldset';
import Checkbox from '../../../../../../ui/components/Checkbox';
import { PortableLabelsWithStore } from '../../../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import { TeamMemberStatusEnum } from '../../../../../../constants/interfaces/TeamMember';
import OverdueFlag from '../../../../../../ui/icons/OverdueFlag';

//region Styles
const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;

    overflow: auto;
    background-color: inherit;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
    background-color: inherit;
`;

const StatusItem = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

const OverdueFlagWrapped = styled(OverdueFlag)`
    margin-left: 4px;
`;
//endregion

export const TeamMembersFilter: FC<{
    preSelectedLabels: number[];
    status: EnrollmentStatus[];
    registrationStatus: any;
    onLabelsChange: (ids: number[]) => void;
    onStatusChange: (status: EnrollmentStatus[]) => void;
    onSuccessApply: () => void;
    onRegistrationStatusChange: (status: any) => void;
    onOverdueChange: (hasOverdue: boolean) => void;
    overdue?: boolean;
}> = ({
    preSelectedLabels,
    onLabelsChange,
    onStatusChange,
    status,
    registrationStatus,
    onSuccessApply,
    onRegistrationStatusChange,
    onOverdueChange,
    overdue,
}) => {
    const [statuses, setStatuses] = useState(status);
    const [hasOverdue, setHasOverdue] = useState(!!overdue);
    const [registrationStatuses, setRegistrationStatuses] = useState<any>(registrationStatus)
    const labels = useLabelsState();
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
 
    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    const regStatusesMap: Record<any, { title: string, dataTest: string }> = {
        [TeamMemberStatusEnum.PENDING]: { title: 'Pending', dataTest: 'pending' },
        [TeamMemberStatusEnum.ACTIVE]: { title: 'Active', dataTest: 'active' }
    };

    const statusesMap: Record<
        EnrollmentStatus,
        { title: string; dataTest: string }
    > = {
        [EnrollmentStatus.PENDING]: {
            title: 'Not Started',
            dataTest: 'not-started',
        },
        [EnrollmentStatus.ENROLLED]: {
            title: 'In Progress',
            dataTest: 'in-progress',
        },
        [EnrollmentStatus.COMPLETED]: {
            title: 'Completed',
            dataTest: 'completed',
        },
    };

    const onStatusChangeHandler = useCallback(
        (statusIn: EnrollmentStatus, e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setStatuses([...statuses, statusIn]);
            } else {
                setStatuses(statuses.filter(item => item !== statusIn));
            }
        },
        [setStatuses, statuses]
    );

    const onRegistrationStatusChangeHandler = useCallback(
        (statusIn: TeamMemberStatusEnum, e: ChangeEvent<HTMLInputElement>) => {
           
            if (e.target.checked) {
                setRegistrationStatuses([...registrationStatuses, statusIn]);
            } else {
                setRegistrationStatuses(registrationStatuses.filter((item: any) => item !== statusIn));
            }
        },
        [setRegistrationStatuses, registrationStatuses]
    );

    const onApplyHandler = useCallback(() => {
        onStatusChange(statuses);
        onRegistrationStatusChange(registrationStatuses);
        onLabelsChange(labels.selected);
        saveLabels();
        onSuccessApply();
        onOverdueChange(hasOverdue);
    }, [
        onStatusChange,
        statuses,
        registrationStatuses,
        onLabelsChange,
        labels.selected,
        onSuccessApply,
        saveLabels,
        onRegistrationStatusChange,
        onOverdueChange,
        hasOverdue
    ]);

    const totalFiltersSelected =
        labels.selected.filter(id => id > 0).length +
        statuses.length +
        registrationStatuses?.length +
        (hasOverdue ? 1 : 0);

    const deselectLabels = useDeselectAllLabels();

    const onClearHandler = () => {
        deselectLabels();
        setStatuses([]);
        setRegistrationStatuses([]);
        setHasOverdue(false);
    }
 
    return (
        <Container>
            <Wrapper>
                <Fieldset
                    title={'Registration Status'}
                    dataTest="registration-status"
                >
                    <StatusContainer>
                        {(
                            Object.keys(
                                regStatusesMap
                            ) as TeamMemberStatusEnum[]
                        ).map(item => (
                            <StatusItem data-test="registration-status-item-container">
                                <Checkbox
                                    key={item}
                                    checked={
                                        registrationStatuses &&
                                        registrationStatuses.includes(item)
                                    }
                                    handleChange={e =>
                                        onRegistrationStatusChangeHandler(
                                            item,
                                            e
                                        )
                                    }
                                    label={regStatusesMap[item].title}
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                    dataTest={regStatusesMap[item].dataTest}
                                />
                            </StatusItem>
                        ))}
                    </StatusContainer>
                </Fieldset>
                <Fieldset
                    title={'Practis Set Status'}
                    dataTest="practisset-status"
                >
                    <StatusContainer>
                        {(Object.keys(statusesMap) as EnrollmentStatus[]).map(
                            item => (
                                <StatusItem data-test="practisset-status-item-container">
                                    <Checkbox
                                        key={item}
                                        checked={statuses.includes(item)}
                                        handleChange={e =>
                                            onStatusChangeHandler(item, e)
                                        }
                                        label={statusesMap[item].title}
                                        border={Variables.Colors.steelGrey}
                                        size={12}
                                        fontColor={Variables.Colors.black}
                                        dataTest={statusesMap[item].dataTest}
                                    />
                                </StatusItem>
                            )
                        )}
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Overdue'} dataTest="overdue">
                    <StatusContainer>
                        <StatusItem>
                            <Checkbox
                                checked={hasOverdue}
                                handleChange={e =>
                                    setHasOverdue(e.target.checked)
                                }
                                label="Overdue"
                                border={Variables.Colors.steelGrey}
                                size={12}
                                fontColor={Variables.Colors.black}
                                dataTest="overdue"
                            />
                            <OverdueFlagWrapped dataTest="overdue-flag-icon" />
                        </StatusItem>
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-filters-counter">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant="inverse"
                        width={'122px'}
                        action={() => onClearHandler()}
                        dataTest="clear-filters-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={() => onApplyHandler()}
                    dataTest="apply-filters-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};

export default TeamMembersFilter;
