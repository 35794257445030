import { Reducer } from 'redux';
import { SearchStateInterface, SecondarySearchStateInterface } from './states';
import {
    ACTIONS,
    setSearchState,
    deleteSearchState,
    setSecondarySearchState,
    deleteSecondarySearchState
} from './actions';
import { AppState } from '../../../store/reducers';


export const getSearchState = (state: AppState) => state.searchState;

export type Action =
    | ReturnType<typeof setSearchState>
    | ReturnType<typeof deleteSearchState>;

export const SearchStateReducer: Reducer<SearchStateInterface, Action> = (
    state = {
        key: '',
        params: undefined,
        childrenKeys: []
    },
    action: Action
): SearchStateInterface => {
    switch (action.type) {
        case ACTIONS.SET_SEARCH_STATE:
            return {
                key: action.key,
                params: action.params,
                childrenKeys: action.childrenKeys
            };
        case ACTIONS.DELETE_SEARCH_STATE: {
            return {
                key: '',
                params: undefined,
                childrenKeys: []
            };
        }
        default:
            return state;
    }
};

export const getSecondarySearchState = (state: AppState) => state.secondarySearchState;

export type SecondaryAction =
    | ReturnType<typeof setSecondarySearchState>
    | ReturnType<typeof deleteSecondarySearchState>;

export const secondarySearchStateReducer: Reducer<SecondarySearchStateInterface, SecondaryAction> = (
    state = {
        key: '',
        params: undefined
    },
    action: SecondaryAction
): SecondarySearchStateInterface => {
    switch (action.type) {
        case ACTIONS.SET_SECONDARY_SEARCH_STATE:
            return {
                key: action.key,
                params: action.params
            };
        case ACTIONS.DELETE_SECONDARY_SEARCH_STATE: {
            return {
                key: '',
                params: undefined
            };
        }
        default:
            return state;
    }
};
