import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


/**
 * @function useSetQueryParam
* @returns { CallableFunction }
 */
export function useSetQueryParam() {
    const location = useLocation();
    const history = useHistory();

    return useCallback(
        (paramName: string, paramValue: string) => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set(paramName, paramValue);

            // Update the URL with the new query params
            history.replace({ search: queryParams.toString() });
        },
        [history, location.search]
    );
}

/**
 * @function useRemoveQueryParam
* @returns { CallableFunction }
 */
export function useRemoveQueryParam() {
    const location = useLocation();
    const history = useHistory();

    return useCallback(
        (paramName: string) => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete(paramName);

            // Update the URL with the new query params
            history.replace({ search: queryParams.toString() });
        },
        [history, location.search]
    );
}
