import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { UserV2 } from '../../../constants/interfaces/User';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';

/**
 * @function handleBuildUserName - builds user name by first name, last name
 * and (you) if user is logged in
 * @param { UserV2 } user
 * @returns { string }
 */
export const useBuildUserName = () => {
    const profile = useSelector(getProfileState);

    return useCallback(
        (user: UserV2): string => {
            const name = `${user.firstName} ${user.lastName}`;
            return profile?.id === user.id ? `${name} (You)` : name;
        },
        [profile]
    );
};