import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 215px;
`;

export const ModalSidebar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--ps-grey-3);
    width: 190px;
    background-color: var(--ps-grey-3);
    border-right: 1px solid var(--ps-grey-4);
    border-bottom-left-radius: 8px;
`;

export const NavigationItem = styled.div<{ isActive: boolean }>`
    margin-top: 18px;
    padding-left: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: ${props => props.isActive ? 'var(--ps-black-main)' :  'var(--ps-grey-1)'};
    display: flex;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    cursor: pointer;

    &:first-child {
        margin-top: 24px;
    }

    &:hover {
        color: var(--ps-black-main);
        & .act-icon {
            display: block;
        }
        & .icon {
            display: none;
        }
    }

    .act-icon {
        display: none
    }

    .icon {
        display: block;
    }

    ${props => props.isActive && `&::before {
        width: 4px;
        height: 24px;
        background: var(--ps-black-main);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 10px 10px 0;
    } 
    
    .act-icon {
        display: block;
    }
    .icon {
        display: none;
    }
    `}
`;

export const NavigationIcon = styled.div`
    width: 16px;
    height: 16px;
    color: var(--ps-black-main);
    margin-right: 12px;
`;

export const TabContent = styled.div`
    width: 627px;
    min-height: 636px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Title = styled.div`
    font-size: 15px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--ps-grey-4);
    padding: 23px 5px 0 5px;
    width: 100%;
    box-sizing: border-box;
    height: 64px;
    align-items: center;
    padding: 0 24px 0 0;
`;