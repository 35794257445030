import styled from 'styled-components';

export const Divider = styled.hr<{ height?: number; hide?: boolean; width?: string; }>`
    width: ${props => props.width ?? '100%'};
    height: ${props => (props.height ? props.height : 1)}px;
    border: none;
    margin: 0;
    flex-shrink: 0;
    background-color: ${props =>
        !props.hide ? props.theme.Colors.paleGrey : 'transparent'};
`;
