import { FC, memo } from 'react';

import { TableProps } from './type';
import { InfiniteTableBodyContainer, StyledTable } from './style';
import { TableHeader } from './table-header';
import { TableBodyWrapper } from './table-body-wrapper';
import { TableDivider } from '../table-divider';
import { ScrollPagination } from '../../ScrollPagination';
import { EmptyStateWrapper } from './empty-state';

const TableInfiniteScrollView: FC<TableProps> = props => {
    const {
        data,
        tableStates,
        tableContainerClassName,
        scrollPaginationProps,
        scrollRef,
        tableEmptyStateConfigurations,
        configuration,
    } = props;

    const { rowConfig } = configuration;

    const {
        shouldShowEmptyState,
        noEntriesOptions,
        noSearchResultsOptions,
        noFilterResultsOptions,
        noCalendarResultsOptions,
    } = tableEmptyStateConfigurations || {};

    return (
        <>
            <TableHeader headerConfiguration={configuration} />
            <InfiniteTableBodyContainer
                ref={scrollRef}
                className={tableContainerClassName}
            >
                <ScrollPagination {...scrollPaginationProps}>
                    <StyledTable>
                        {!tableStates.showEmptyState && (
                            <TableBodyWrapper
                                data={data}
                                rowConfig={rowConfig}
                            />
                        )}
                        {shouldShowEmptyState && tableStates.showEmptyState && (
                            <EmptyStateWrapper
                                tableStates={tableStates}
                                noEntriesOptions={noEntriesOptions}
                                noSearchResultsOptions={noSearchResultsOptions}
                                noFilterResultsOptions={noFilterResultsOptions}
                                noCalendarResultsOptions={noCalendarResultsOptions}
                            />
                        )}
                    </StyledTable>
                </ScrollPagination>
            </InfiniteTableBodyContainer>
            <TableDivider />
        </>
    );
};

export default memo(TableInfiniteScrollView);
