import { useSelector } from 'react-redux';

import { useWithTeamsContext } from './context/useWithTeamsContext';
import { TeamsState } from './reducer';
import { DEFAULT_LABELS_SCOPE } from '../../../../portableLabels/store/hors/withLabels/scopes';

export const useTeamsState = (): TeamsState => {
    const { reducerName, scope } = useWithTeamsContext();
    const appState = useSelector(state => state);

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithTeamsContext.Provider props'
        );
    }

    const labelsState: TeamsState | undefined =
        // @ts-ignore
        // TODO implement `_teams?: TeamsState` field into each reducer (create general type)
        // interface GeneralReducer {
        //     ...
        //     _teams?: StateWithTeams;
        // }
        appState[reducerName]?._teams?.[scope || DEFAULT_LABELS_SCOPE];
    if (!labelsState) {
        throw new Error(
            `Wrap your reducer ${reducerName} via "withTeamsReducer" HOR`
        );
    }

    return labelsState;
};
