import React, { FC } from 'react';
import { IconProps } from './types';

export const ThinClose: FC<IconProps> = ({ className }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 16 16"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <path
                            d="M0 0H16V16H0z"
                            transform="translate(-1342 -404) translate(1342 404)"
                        />
                        <g stroke="currentColor" strokeLinecap="round">
                            <path
                                d="M0 0L12 12M12 0L0 12"
                                transform="translate(-1342 -404) translate(1342 404) translate(1.953 2.021)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ThinClose;
