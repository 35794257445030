import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { LogItem } from '../models/LogItem';

export enum ACTIONS {
    SEARCH_LOGS_START = 'ADMIN_SEARCH_LOGS_START',
    SEARCH_LOGS_SUCCESS = 'ADMIN_SEARCH_LOGS_SUCCESS',
    SEARCH_LOGS_FAILURE = 'ADMIN_SEARCH_LOGS_FAILURE',
    SEARCH_LOGS_DISPOSE = 'ADMIN_SEARCH_LOGS_DISPOSE',
}

export function searchLogsStart() {
    return {
        type: ACTIONS.SEARCH_LOGS_START as ACTIONS.SEARCH_LOGS_START,
    };
}

export function searchLogsSuccess(logs: PaginationResult<LogItem>) {
    return {
        type: ACTIONS.SEARCH_LOGS_SUCCESS as ACTIONS.SEARCH_LOGS_SUCCESS,
        logs,
    };
}

export function searchLogsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_LOGS_FAILURE as ACTIONS.SEARCH_LOGS_FAILURE,
        error,
    };
}

export function searchLogsDispose() {
    return {
        type: ACTIONS.SEARCH_LOGS_DISPOSE as ACTIONS.SEARCH_LOGS_DISPOSE,
    };
}
