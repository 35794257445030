import { motion } from 'framer-motion';
import styled from 'styled-components';
import { rgba } from 'polished';

export const ModalBackground = styled(motion.div)<{
    zIndex?: number;
}>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${props => props.zIndex || '1200'};
    background: ${rgba('#232e3a', 0.8)};
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ps-black-main);
`;

export const ModalContainer = styled(motion.div)<{
    width: number;
}>`
    width: ${props => props.width || '400'}px;
    height: auto;
    min-height: 200px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    background-color: var(--ps-white-1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    min-height: 50px;
    border-bottom: 1px solid var(--ps-grey-4);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ModalTitle = styled.div`
    padding: 8px 50px 8px 24px;
    font-size: 18px;
    font-weight: 800;
    color: var(--ps-black-main);
`;

export const CrossContainer = styled.div`
    width: 12px;
    height: 12px;
    color: #24292E;
    position: absolute;
    top: 19px;
    right: 28px;
    cursor: pointer;
    display: flex;
`;