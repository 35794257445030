import { SCENARIO_IMAGES } from '../../../../constants/enums/scenarios';
import { Scenario } from '../../../../constants/interfaces/Scenario';
import { ScriptLine, ScriptLineSpeaker } from '../../../../constants/interfaces/ScriptLine';
import { randomArrayItem } from '../../../../helpers/functions/get-random-from-array';
import { getConfig } from '../../../../tools/config';
import {
    createScriptLineAction,
    deleteScriptLineAction,
    modifyScriptTextAction,
    updateScriptLineAction,
    uploadScenarioAudioStart,
    uploadScenarioAudioSuccess,
    uploadScenarioAudioFailure,
    uploadScenarioLogoFailure,
    uploadScenarioLogoStart,
    uploadScenarioLogoSuccess,
    modifyScenarioAction,
    fetchScenarioStart,
    fetchScenarioSuccess,
    fetchScenarioFailure,
    resetScenarioAction,
    resetScenarioLogoAction,
    updateScenarioModifyCase,
    restoreScenarioFromTempAction,
    storeScenarioTempCopyAction,
    updateScriptLineKeywords,
    clearScriptLinesAction,
} from './actions';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    useFileUploadApi,
    useGetScenarioApi,
} from '../../../../api';
import { ScenarioCases } from './reducers';
import { Keywords } from '../../../../constants/interfaces/Keywords';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useGetRoleplayApi, useGetRoleplayContentByIdApi } from '../../../../api/roleplay';

export const useCreateScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: ScriptLine) => {
            dispatch(createScriptLineAction(data));
        },
        [dispatch]
    );
};

export const useDeleteScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (lineId: number) => {
            dispatch(deleteScriptLineAction(lineId));
        },
        [dispatch]
    );
};

export const useModifyScriptTextService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: string, lineId: number | string) => {
            dispatch(modifyScriptTextAction(value, lineId));
        },
        [dispatch]
    );
};

export const useUpdateScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scriptLines: Array<ScriptLine>) => {
            dispatch(updateScriptLineAction(scriptLines));
        },
        [dispatch]
    );
};

export const updateScriptLine = (scriptLines: Array<ScriptLine>) => {
    return (dispatch: any) => {
        dispatch(updateScriptLineAction(scriptLines));
    };
};

export const useClearScriptLinesService = () => {
    const dispatch = useDispatch();
    return useCallback(
        () => {
            dispatch(clearScriptLinesAction());
        },
        [dispatch]
    );
};

export const useGetScenarioService = () => {
    const dispatch = useDispatch();
    const getScenarioApi = useGetScenarioApi();
    const showMessage = useShowMessage();
    return useCallback(
        (scenarioId: number) => {
            dispatch(fetchScenarioStart());
            getScenarioApi(scenarioId)
                .then(data => {
                    dispatch(fetchScenarioSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchScenarioFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getScenarioApi, showMessage]
    );
};

export const useUploadScenarioImageService = () => {
    const dispatch = useDispatch();
    const fileUploadApi = useFileUploadApi();
    const showMessage = useShowMessage();
    return useCallback(
        (file: any, associatedEntityId?: number, filename?: string) => {
            dispatch(uploadScenarioLogoStart());
            const formData: FormData = new FormData();
            formData.set('file', file);
            formData.set('type', 'IMAGE');
            formData.set('associatedEntityType', 'Scenario');
            if (associatedEntityId)
                formData.set(
                    'associatedEntityId',
                    associatedEntityId.toString()
                );
            if (filename) formData.set('filename', filename);

            fileUploadApi(formData)
                .then(data => {
                    dispatch(uploadScenarioLogoSuccess(data));
                    return 'success';
                })
                .catch((error: ErrorResult) => {
                    dispatch(uploadScenarioLogoFailure(error.message));
                    showMessage(error.message, 'error');
                    return 'error';
                });
        },
        [dispatch, fileUploadApi, showMessage]
    );
};

export const useUploadScenarioAudioService = () => {
    const dispatch = useDispatch();
    const fileUploadApi = useFileUploadApi();
    const showMessage = useShowMessage();
    return useCallback(
        (file: any, associatedEntityId: string | number, filename?: string) => {
            dispatch(uploadScenarioAudioStart());
            const formData: FormData = new FormData();
            formData.set('file', file);
            formData.set('type', 'AUDIO');
            formData.set('associatedEntityType', 'Line');
            if (!associatedEntityId.toString().includes('temp_'))
                formData.set(
                    'associatedEntityId',
                    associatedEntityId.toString()
                );
            if (filename) formData.set('filename', filename);

            return fileUploadApi(formData)
                .then(data => {
                    dispatch(
                        uploadScenarioAudioSuccess(associatedEntityId, data)
                    );
                    return 'success';
                })
                .catch((error: ErrorResult) => {
                    dispatch(uploadScenarioAudioFailure(error.message));
                    showMessage(error.message, 'error');
                    return 'error';
                });
        },
        [dispatch, fileUploadApi, showMessage]
    );
};

export const generateRandomImageUrl = () => {
    const fileName = randomArrayItem(SCENARIO_IMAGES);
    return (
        getConfig().S3_FILES_BASE_URL + `scenarios/default-logos/${fileName}`
    );
};

export const useModifyScenarioService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: any, field: keyof Scenario, silent?: boolean) => {
            dispatch(modifyScenarioAction(value, field, silent));
        },
        [dispatch]
    );
};

export const useResetScenarioService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetScenarioAction());
    }, [dispatch]);
};

export const useResetScenarioLogoService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetScenarioLogoAction());
    }, [dispatch]);
};

export const useStoreScenarioTempCopyService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(storeScenarioTempCopyAction());
    }, [dispatch]);
};

export const useRestoreScenarioFromTempActionService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(restoreScenarioFromTempAction());
    }, [dispatch]);
};

export const useUpdateScenarioModifyCase = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: ScenarioCases) => {
            dispatch(updateScenarioModifyCase(value));
        },
        [dispatch]
    );
};

export const useSaveScenarioKeywordsService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (keywords: Keywords[], lineId: number | string) => {
            dispatch(updateScriptLineKeywords(keywords, lineId));
        },
        [dispatch]
    );
};

export const useGetRoleplayForScenario = () => {
    const getRoleplayContentByIdApi = useGetRoleplayContentByIdApi();
    const getRoleplayApi = useGetRoleplayApi();

    return useCallback(
        async (roleplayId: string, contentId: string) => {
            return getRoleplayContentByIdApi({roleplayId, contentId}).then(async ({conversation, summary}) => {
                const aiLines = conversation.map(({text, role}) => {
                    return {
                        text: text,
                        speaker: role === 'prospect' ? ScriptLineSpeaker.CUSTOMER : ScriptLineSpeaker.REP
                    }
                })
                const {title} = await getRoleplayApi({roleplayId});

                return {aiLines, title, description: summary};
            })
        },
        [getRoleplayContentByIdApi, getRoleplayApi]
    );
};
