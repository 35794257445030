import React, { FC, useRef, useState } from 'react';
import styled, { css} from 'styled-components';
import OutsideActionBox from '../OutsideActionBox/OutsideActionBox';
import { Variables } from '../../../theme/variables';
import UpArrow from '../../icons/UpArrow';
import DownArrow from '../../icons/DownArrow';
import { SelectLabel } from './SelectLabel';
import { SelectMenu } from './SelectMenu';
import { SelectMenuItem } from './SelectMenuItem';
import { SelectInput } from './SelectInput';

const COLORS: any = {
    light: {
        background: Variables.Colors.whiteFive,
        color: Variables.Colors.black,
    },
    dark: {
        background: Variables.Colors.darkTwo,
        color: Variables.Colors.whiteTwo,
    },
    gray: {
        background: Variables.Colors.whiteTwo,
        color: Variables.Colors.black,
    },
    transparent: {
        background: Variables.Colors.transparent,
        color: Variables.Colors.black,
    },
};

const StyledSelectForm = styled.div<{
    height?: number;
    error?: boolean;
    borderRadius?: number;
    variant: string;
    border?: boolean;
    disabled?: boolean;
}>`
    box-sizing: border-box;
    position: relative;
    border-radius: ${props => (props.borderRadius ? props.borderRadius : 4)}px;
    border: ${props => (props.border ? 1 : 0)}px solid
        ${props => props.theme.Colors.cloudyBlue};
    min-width: 128px;
    cursor: pointer;
    height: ${props => (props.height ? `${props.height}px` : '56px')};
    width: 100%;
    background: ${props => COLORS[props.variant].background};
    color: ${props => COLORS[props.variant].color};
    box-shadow: inset 0 0 0 ${props => (props.error ? '2px' : '-1px')};
        ${props => props.theme.Colors.coral};
    -webkit-appearance: none;
    
    ${({ disabled, theme }) => disabled && css`
        color: ${theme.Colors.cloudyBlue};
        cursor: default;
    `}
`;

const Container = styled(OutsideActionBox)`
    position: relative;
`;

const IconHolder = styled.div<{
    disabled?: boolean;
}>`
    position: absolute;
    right: 24px;
    width: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${props => props.disabled ? props.theme.Colors.cloudyBlue : props.theme.Colors.steelGrey};
`;

export const Select: FC<{
    label?: string;
    placeholder?: string;
    labelColor?: string;
    id?: string;
    items?: Array<any>;
    children?: Array<any>;
    value?: string | number | string[] | number[] | null;
    name?: string;
    defaultValue?: string | number;
    colorTheme?: 'light' | 'dark' | 'gray' | 'transparent';
    valueColor?: string;
    onChange?: any;
    disabled?: boolean;
    error?: boolean;
    menuPosition?: 'bottom' | 'over' | 'top';
    height?: number;
    fontSize?: string;
    className?: string;
    borderRadius?: number;
    dataTest?: string;
    customMenuItem?: (item: {name: string, value: string}) => JSX.Element
}> = ({
    label,
    placeholder,
    labelColor,
    items,
    value,
    onChange,
    name,
    colorTheme,
    valueColor,
    menuPosition,
    height,
    fontSize,
    disabled,
    className,
    error,
    borderRadius,
    customMenuItem,
    dataTest
}) => {
    const [show, setShow] = useState(false);
    const selectedRef = useRef(null);
    const multiSelect = Array.isArray(value);

    const findNameByValue = () => {
        if (items) {
            let selectedItems = items.filter(item =>
                Array.isArray(value)
                    ? value.includes(item.value as never)
                    : item.value === value
            );

            if (selectedItems.length < 2) {
                return selectedItems[0] && selectedItems[0].name;
            } else {
                return (
                    selectedItems &&
                    selectedItems
                        .map(item => {
                            return item.name;
                        })
                        .join(', ')
                );
            }
        }
    };

    const selectedName = findNameByValue();

    return (
        <StyledSelectForm
            variant={colorTheme ? colorTheme : 'light'}
            error={error}
            height={height}
            className={className && `${className} ${show ? `${className}-open` : ''}`}
            borderRadius={borderRadius}
            border={(show || !!value) && !disabled}
            disabled={disabled}
        >
            {label && (
                <SelectLabel
                    focused={Array.isArray(value) ? value.length > 0 : !!value}
                    label={label}
                    color={labelColor}
                    className={className && `${className}-label`}
                    dataTest={dataTest && `${dataTest}-label`}
                />
            )}
            {placeholder && !value && (
                <SelectLabel
                    label={placeholder}
                    color={labelColor}
                    className={className && `${className}-label`}
                    dataTest={dataTest && `${dataTest}-label`}
                />
            )}
            <IconHolder
                disabled={disabled}
                className={className && `${className}-arrow`}
                onClick={() => !disabled && setShow(!show)}
            >
                {show ? <UpArrow /> : <DownArrow />}
            </IconHolder>
            <SelectInput
                value={customMenuItem?.({name: selectedName, value: value as string}) || selectedName}
                toggleSelect={() => !disabled && setShow(!show)}
                height={height}
                fontSize={fontSize}
                padding={!label ? '0px 16px' : undefined}
                disabled={disabled}
                color={valueColor}
                className={className && `${className}-input`}
                dataTest={dataTest && `${dataTest}-selected-value`}
            />
            <Container open={show} toggleOpen={setShow}>
                <SelectMenu
                    displayOver={menuPosition}
                >
                    {items &&
                        items.map((item: { value: string; name: string }) => (
                            <SelectMenuItem
                                forwardedRef={
                                    (
                                        Array.isArray(value)
                                            ? value.includes(
                                                  item.value as never
                                              )
                                            : item.value === value
                                    )
                                        ? selectedRef
                                        : null
                                }
                                key={item.value}
                                value={item.value}
                                name={name}
                                hideAction={() => setShow(false)}
                                onClick={onChange}
                                selected={
                                    Array.isArray(value)
                                        ? value.includes(item.value as never)
                                        : item.value === value
                                }
                                multiSelect={multiSelect}
                                dataTest={dataTest && `${dataTest}-select-item`}
                            >                              
                                {customMenuItem?.(item) ||  item.name}
                            </SelectMenuItem>
                        ))}
                </SelectMenu>
            </Container>
        </StyledSelectForm>
    );
};

export default Select;
