import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';

import { isAdminPortal } from '../functions/general';
export const PRACTIS_ADMIN_SUFFIX = 'Practis Admin Portal';
export const PRACTIS_WEB_SUFFIX = 'Practis';

export default function useHtmlPageTitle(
    title?: string,
    dependencies?: (string | undefined)[]
) {
    const isItAdminPortal = isAdminPortal();

    const suffix = isItAdminPortal ? PRACTIS_ADMIN_SUFFIX : PRACTIS_WEB_SUFFIX;

    const generateHtmlTitle = useCallback(() => {
        let htmlTitle = suffix;

        if (!isEmpty(dependencies)) {
            for (const dependency of dependencies!.reverse()) {
                // the dependency can be a string of multiple values
                // that each of them can be undefined.
                // ex: `${enrollment?.user.firstName} ${enrollment?.user.lastName}`
                // result: 'undefined undefined'
                if (dependency && !dependency.includes('undefined')) {
                    htmlTitle = `${dependency} - ${htmlTitle}`;
                }
            }
        }

        if (title) {
            htmlTitle = `${title} - ${htmlTitle}`;
        }

        return htmlTitle;
    }, [dependencies, suffix, title]);

    useEffect(() => {
        const htmlTitle = generateHtmlTitle();

        document.title = htmlTitle;
    }, [dependencies, generateHtmlTitle]);
}