import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useWithScenariosContext } from '../context/useWithTeamsContext';
import { encapsulateAction } from '../../../../../../store/helpers/encapsulateAction';
import { ActionScenario } from '../actions';
import { DEFAULT_SCENARIOS_SCOPE, Scope, WithScenarioScopes } from '../scopes';

export const useEncapsulatedDispatch = () => {
    const dispatch = useDispatch();
    const { reducerName, scope } =  useWithScenariosContext();

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithScenariosContext.Provider props'
        );
    }

    const targetScope = (scope || DEFAULT_SCENARIOS_SCOPE) as Scope;
    if (
        targetScope !== DEFAULT_SCENARIOS_SCOPE &&
        !WithScenarioScopes[reducerName]?.includes(targetScope)
    ) {
        throw new Error(
            `Undefined "${targetScope}" scope of portableTeams. Please check config (WithScenarioScopes) for "${reducerName}" reducer`
        );
    }

    return useCallback(
        <T extends ActionScenario>(action: T) => {
            action._scope = targetScope;
            dispatch(encapsulateAction(`accuracyscore/scenarios/`, action));
        },
        [dispatch, targetScope]
    );
};
