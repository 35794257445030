import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NewUserPath = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                xmlns="http://www.w3.org/2000/svg"
                width="68"
                height="74"
                viewBox="0 0 68 74"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="#4AA9E2">
                        <g>
                            <path
                                fillRule="nonzero"
                                d="M68 0v1.5C33.417 1.5 5.317 29.254 4.758 63.704L4.75 64.75h-1.5C3.25 29.347 31.663.58 66.93.009L68 0z"
                                transform="translate(-316 -703) translate(316 703.25)"
                            />
                            <path
                                stroke="#4AA9E2"
                                d="M4 65.868l3.191 6.382H.809L4 65.868z"
                                transform="translate(-316 -703) translate(316 703.25) rotate(-180 4 68.75)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NewUserPath;
