import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { kebabCase } from 'lodash';
import { Variables } from '../../../../theme/variables';
import { Button } from '../../../../ui/components/Button';
import { Fieldset } from '../../../../ui/components/Fieldset';
import Checkbox from '../../../../ui/components/Checkbox';
import { PortableLabelsWithStore } from '../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
import {
    CHALLENGE_SUBMISSION_REVIEW_STATUS_ITEMS
} from '../../tools';
import { PortableTeamsWithStore } from '../../../portableTeams';
import { useTeamsState } from '../../../portableTeams/store/hors/withTeams/states';
import {
    useDeselectAllTeams,
    useSaveTeams,
} from '../../../portableTeams/store/hors/withTeams/services';
import { toggleItemInArray } from '../../../../helpers/functions/array-helpers';
import { ChallengeSubmissionFilters } from '../../../../constants/interfaces/SubmissionSearchFilters';
import {
    Container,
    Wrapper,
    LabelsContainer,
    TeamsContainer,
    StatusContainer,
    StatusSection,
    StatusItem,
    Actions,
    SummaryCaption,
    ButtonWrapper
} from './styles';

export const SubmissionFilters: FC<{
    filters: ChallengeSubmissionFilters;
    onSuccessApply: (filters: ChallengeSubmissionFilters) => void;
}> = ({
    filters,
    onSuccessApply,
}) => {
    const labels = useLabelsState();
    const teams = useTeamsState();
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
    const saveTeams = useSaveTeams();
    const ifPreselectedLabelsChanged = useIfChanged(filters.labelIds);

    const [reviewStatuses, setReviewStatuses] = useState<string[] | undefined>();
    const [showArchived, setShowArchived] = useState<boolean | undefined>();

    const handleReviewStatusSelection = useCallback(
        (reviewStatus: string) => {
            const statuses = toggleItemInArray(
                reviewStatuses ?? filters.reviewStatuses, reviewStatus);
            setReviewStatuses(statuses);
        },
        [setReviewStatuses, reviewStatuses, filters.reviewStatuses]
    );

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(filters.labelIds);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        filters.labelIds,
        saveLabels,
        selectLabels,
    ]);

    const onApplyHandler = useCallback(() => {
        saveLabels();
        saveTeams();
        onSuccessApply({
            reviewStatuses: reviewStatuses ?? filters.reviewStatuses,
            showArchived: showArchived ?? filters.showArchived,
            labelIds: labels.selected,
            teamIds: teams.selected
        })
    }, [
        reviewStatuses,
        showArchived,
        labels.selected,
        teams.selected,
        saveLabels,
        saveTeams,
        onSuccessApply,
        filters
    ]);

    const totalFiltersSelected =
        teams.selected.length +
        labels.selected.filter(id => id > 0).length +
        ((showArchived ?? filters.showArchived) ? 1 : 0) +
        (reviewStatuses ?? filters.reviewStatuses).length;

    const deselectTeams = useDeselectAllTeams();
    const deselectLabels = useDeselectAllLabels();

    const onClearHandler = () => {
        setShowArchived(false);
        setReviewStatuses([]);
        deselectTeams();
        deselectLabels();
    };

    return (
        <Container>
            <Wrapper>
                <StatusContainer>
                    <Fieldset title={'Review Status'} dataTest="review-status-section-title">
                        <StatusSection>
                            {CHALLENGE_SUBMISSION_REVIEW_STATUS_ITEMS.map(item => (
                                <StatusItem key={item.value} data-test="review-status-item-container">
                                    <Checkbox
                                        key={item.value}
                                        checked={
                                            (reviewStatuses ?? filters.reviewStatuses)
                                                .includes(item.value)
                                        }
                                        handleChange={() => {
                                            handleReviewStatusSelection(item.value);
                                        }}
                                        label={item.name}
                                        border={Variables.Colors.steelGrey}
                                        size={12}
                                        fontColor={Variables.Colors.black}
                                        dataTest={`${kebabCase(item.name)}-checkbox`}
                                    />
                                </StatusItem>
                            ))}
                        </StatusSection>
                    </Fieldset>
                    <Fieldset title={'Status'} dataTest="status-section-title">
                        <StatusSection>
                            <StatusItem data-test="status-item-container">
                                <Checkbox
                                    checked={showArchived ?? filters.showArchived}
                                    handleChange={e => {
                                        setShowArchived(e.target.checked);
                                    }}
                                    label="Archived"
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                    dataTest="archived-checkbox"
                                />
                            </StatusItem>
                        </StatusSection>
                    </Fieldset>
                </StatusContainer>
                <Fieldset title={'Teams'} dataTest="teams-section-title">
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant={'inverse'}
                        width={'122px'}
                        action={() => onClearHandler()}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={onApplyHandler}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
