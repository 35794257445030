import { PropsWithChildren, ReactElement } from 'react';
import { OnChangeDateCallback } from 'react-calendar';
import dayjs from 'dayjs';

import ArrowRightIcon from '../../icons/ArrowRight';
import { IconHolder, StyledCalendar } from './styles';

export type CalendarValue<T extends boolean = false> = T extends true
    ? { start: Date | null; end: Date | null }
    : Date | null | undefined;

type Props<T extends boolean> = {
    value: CalendarValue<T>;
    handleChange(date: CalendarValue<T>): void;
    selectRange: T;
    restrictPast?: boolean;
    restrictFuture?: boolean;
};

export const CustomCalendar = <T extends boolean = false>({
    value,
    handleChange,
    selectRange,
    restrictPast = false,
    restrictFuture = false,
}: PropsWithChildren<Props<T>>): ReactElement => {
    const getLibraryValue = (): Date | Date[] | null | undefined => {
        if (selectRange) {
            const _value = value as CalendarValue<true>;
            // react-calendar cannot receive an empty array
            const outArray = [_value.start, _value.end]
                .filter(item => item !== null)
                .map(item => dayjs(item as Date | string).toDate());

            return outArray.length > 0 ? outArray : null;
        }

        const _value = value as CalendarValue<false>;

        return _value ? dayjs(_value).toDate() : null;
    };

    const transformLibraryToComponentValue = (
        date: Date | Date[]
    ): CalendarValue<true | false> => {
        if (Array.isArray(date) && selectRange) {
            return {
                start: date[0] || null,
                end: date[1] || null,
            };
        }
        return date as CalendarValue<false>;
    };

    const changeHandler: OnChangeDateCallback = date => {
        // @ts-ignore
        handleChange(transformLibraryToComponentValue(date));
    };

    return (
        <StyledCalendar
            calendarType={'US'}
            formatShortWeekday={(locale, date) =>
                ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
            }
            prev2Label={null}
            next2Label={null}
            nextLabel={
                <IconHolder data-test='calendar-next-button'>
                    <ArrowRightIcon />
                </IconHolder>
            }
            prevLabel={
                <IconHolder scaleX={-1} data-test='calendar-prev-button'>
                    <ArrowRightIcon />
                </IconHolder>
            }
            value={getLibraryValue() as any}
            onChange={changeHandler}
            selectRange={selectRange}
            minDate={restrictPast ? new Date() : undefined}
            maxDate={restrictFuture ? new Date() : undefined}
            allowPartialRange={false}
        />
    );
};

export default CustomCalendar;
