import styled from 'styled-components';

export const IconHolder = styled.div<{ scaleX?: number }>`
    width: 7px;
    height: 14px;
    color: ${props => props.theme.Colors.steelGrey};
    transform: scaleX(${props => props.scaleX || 1});
`;

export const StyledButton = styled.div<{ disabled?: boolean }>`
    width: 48px;
    height: 48px;
    align-items: center;
    border-radius: 4px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    background-color: ${props => props.theme.Colors.white};
    cursor: pointer;
    display: flex;
    justify-content: center;
    user-select: none;
    &:hover ${IconHolder} {
        color: ${props => props.theme.Colors.black};
    }
    &:active ${IconHolder} {
        color: ${props => props.theme.Colors.black};
    }
    &:active {
        border: solid 1px ${props => props.theme.Colors.cloudyBlue};
    }
`;

