import {
    Container,
    Underline,
} from './styles';
import { MonthItemViewProps } from './types';

function MonthItemView({
    title, isDisabled, isSelected, isCurrent, onSelect
}: MonthItemViewProps) {
    return (
        <Container
            isDisabled={isDisabled}
            isSelected={isSelected}
            onClick={onSelect}
            data-test={
                isSelected
                    ? "selected-month"
                    : isCurrent
                        ? "current-month"
                        : "month-item"
            }
        >
            {title}
            {isCurrent && <Underline
                isSelected={isSelected}
                isDisabled={isDisabled}
                data-text="current-month-underline"
            />}
        </Container>
    );
}

export default MonthItemView;