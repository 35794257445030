import { Formik } from 'formik';
import { Button } from '../../../../../../ui/components/Button';
import { RadioButton } from '../../../../../../ui/components/RadioButton';
import { useFocus } from '../../../../../../helpers/hooks/useFocus';
import { ChallengeAttemptsLimitViewProps, ChallengeAttemptsLimitFormValues } from './types';
import {
    Content,
    Footer,
    LimitedSetting,
    LimitedSettingDescription,
    LimitedSettingValue,
    LimitInput,
    LimitNumberContainer,
    LimitWarning,
    StyledForm,
    UnlimitedSetting,
    UnlimitedSettingDescription,
    useStyles,
} from './styles';

function ChallengeAttemptsLimitView({
    initialSettings,
    isValidLimitValue,
    validateSettings,
    onSubmit,
}: ChallengeAttemptsLimitViewProps) {
    const [inputRef, setFocus] = useFocus();
    const classes = useStyles();

    return (
        <Formik<ChallengeAttemptsLimitFormValues>
            initialValues={initialSettings}
            validate={validateSettings}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ values, dirty, isValid, setFieldValue }) => {
                return (
                    <StyledForm>
                        <Content>
                            <LimitedSetting>
                                <LimitedSettingValue>
                                    <RadioButton
                                        checked={values.isLimited === true}
                                        size={12}
                                        handleChange={() => {
                                            setFieldValue('isLimited', true);
                                            setTimeout(setFocus, 0);
                                        }}
                                        label="Limit Attempts to"
                                        disabled={false}
                                        labelStyles={{ fontWeight: 'bold' }}
                                        className={classes.customRadioButton}
                                        dataTest="limited-attempts-radio"
                                    />
                                    <LimitNumberContainer>
                                        <LimitInput
                                            ref={inputRef}
                                            height="26px"
                                            disabled={!values.isLimited}
                                            value={values.limitValue ?? ''}
                                            handleChange={e =>
                                                (!e.target.value || isValidLimitValue(e.target.value)) && setFieldValue('limitValue', e.target.value)
                                            }
                                            dataTest="limited-attempts-input"
                                        />
                                    </LimitNumberContainer>
                                </LimitedSettingValue>
                                <LimitedSettingDescription data-test="limited-attempts-description">
                                    Users have limited attempts. After reaching the limit, the App force them to submit last recording. Recommended value is 3 attempts.
                                </LimitedSettingDescription>
                            </LimitedSetting>
                            <UnlimitedSetting>
                                <RadioButton
                                    checked={values.isLimited === false}
                                    size={12}
                                    label="Unlimited attempts"
                                    handleChange={() => {
                                        setFieldValue('isLimited', false);
                                        if (values.limitValue !== initialSettings.limitValue) {
                                            setFieldValue('limitValue', null);
                                        }
                                    }}
                                    disabled={false}
                                    labelStyles={{ fontWeight: 'bold' }}
                                    className={classes.customRadioButton}
                                    dataTest="unlimited-attempts"
                                />
                                <UnlimitedSettingDescription data-test="unlimited-attempts-description">
                                    Learners can record as many times as they want before they submit the challenge.
                                </UnlimitedSettingDescription>
                            </UnlimitedSetting>
                            <LimitWarning data-test="attempts-warning">
                                <b>Important:</b> The new limitation will affect all newly assigned Learners and Learners who haven't submitted the challenge yet. This change won't affect users who already submitted the challenge.
                            </LimitWarning>
                        </Content>
                        <Footer>
                            <Button
                                width="130px"
                                height="40px"
                                disabled={!dirty || !isValid}
                                loadingColor="white"
                                loadingSize={24}
                                dataTest="apply-button"
                            >
                                Apply
                            </Button>
                        </Footer>
                    </StyledForm>
                );
            }}
        </Formik>
    );
}

export default ChallengeAttemptsLimitView;