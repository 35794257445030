import styled from 'styled-components';

export const Container = styled.div`
    padding: 16px 24px;
`;

export const Description = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-black-main);
`;

export const QuickTip = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-black-main);
    padding-top: 8px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 32px;
`;