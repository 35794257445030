import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Attempts = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g clip-path="url(#v0f9vgv80a)" fill="currentColor">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="m14.357 3.143 1.216-1.216a.25.25 0 0 1 .427.177V5.75a.25.25 0 0 1-.25.25h-3.646a.25.25 0 0 1-.177-.427l1.358-1.358a6.5 6.5 0 1 0 1.18 4.458.75.75 0 0 1 1.493.154 8.001 8.001 0 1 1-1.6-5.684z"/>
                    <path d="M6 5.75A.75.75 0 0 1 6.75 5h1.5a.75.75 0 0 1 .75.75V9.5h.75a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1 0-1.5h.75v-3h-.75A.75.75 0 0 1 6 5.75z"/>
                </g>
                <defs>
                    <clipPath id="v0f9vgv80a">
                        <path fill="#fff" transform="matrix(-1 0 0 1 16 0)" d="M0 0h16v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default Attempts;
