import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Documents = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 64 64"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    fillRule="evenodd"
                    fill="currentColor"
                    d="M59.429 55.465H13.714c-2.524 0-4.571-1.907-4.571-4.266V4.267C9.143 1.909 11.19 0 13.714 0H59.43C61.953 0 64 1.91 64 4.267v46.932c0 2.36-2.047 4.266-4.571 4.266zm0-49.064c0-1.179-1.023-2.133-2.286-2.133H16c-1.262 0-2.286.954-2.286 2.133v42.666c0 1.176 1.024 2.133 2.286 2.133h41.143c1.263 0 2.286-.956 2.286-2.133V6.4zM52.57 42.667h-32c-1.262 0-2.285-.956-2.285-2.135 0-1.178 1.024-2.134 2.285-2.134h32c1.262 0 2.286.956 2.286 2.134 0 1.18-1.023 2.135-2.286 2.135zm0-12.8h-32c-1.262 0-2.285-.957-2.285-2.134 0-1.178 1.024-2.133 2.285-2.133h32c1.262 0 2.286.955 2.286 2.133 0 1.177-1.023 2.133-2.286 2.133zm-13.714-12.8H20.571c-1.262 0-2.285-.955-2.285-2.133s1.024-2.133 2.285-2.133h18.286c1.262 0 2.286.955 2.286 2.133s-1.023 2.132-2.286 2.132zm16 46.933H4.571C2.047 64 0 62.09 0 59.732V12.801c0-2.356 2.047-4.265 4.571-4.265v51.196H59.43c0 2.358-2.047 4.268-4.572 4.268z"
                />
            </svg>
        );
    }
);

export default Documents;
