import styled from 'styled-components';

import { TableCell } from '../table-cell';

export const TableCheckboxCell = styled(TableCell)`
    padding: 0;
`;

export const DefaultTeamIconHolderWrapper = styled.div`
    width: 16px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    background-color: ${props => props.theme.Colors.macaroniAndCheese};
`;

export const DefaultTeamIconHolder = styled.div`
    width: 10px;
    height: 16px;
    display: block;
    color: ${props => props.theme.Colors.white};
`;
