import { createContext } from 'react';

import { AppState } from '../../../../../../store/reducers';
import { DEFAULT_TEAMS_SCOPE, Scope } from '../scopes';

export type WithTeamsContextProps<T = keyof AppState, K = Scope> = {
    reducerName?: T;
    scope?: K;
};

export const WithTeamsContext = createContext<WithTeamsContextProps>({
    reducerName: 'portableTeams',
    scope: DEFAULT_TEAMS_SCOPE,
});
