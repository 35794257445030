import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import OutsideActionBox from '../../OutsideActionBox/OutsideActionBox';

const Container = styled(OutsideActionBox)`
    position: relative;
`;

const DropDownComponent = styled(motion.div)`
    background: ${props => props.theme.Colors.white};
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    width: 256px;
    height: 192px;
    position: absolute;
    border-radius: 8px;
    top: 16px;
    left: -32px;
    z-index: 1000;
    overflow: hidden;
`;

const ContentArea = styled.div`
    padding: 16px 8px;
    flex: 1;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
`;

const ScrollableArea = styled.div`
    overflow-y: auto;
    max-height: 295px;
    padding: 0 8px;
    height: 100%;
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.whiteFive};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.Colors.whiteThree};
    }
`;

export const CountryList: React.FC<{
    open: boolean;
    toggleOpen: (open: boolean) => void;
    className?: string;
}> = ({ children, open, toggleOpen, className }) => {
    return (
        <Container
            open={open}
            toggleOpen={toggleOpen}
            styles={{ position: 'relative' }}
        >
            <DropDownComponent
                className={className}
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
            >
                <ContentArea>
                    <ScrollableArea>{children}</ScrollableArea>
                </ContentArea>
            </DropDownComponent>
        </Container>
    );
};
