import { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { PortablePractisSetsList } from './PortablePractisSetsList';
import { Button } from '../../../ui/components/Button';
import { Variables } from '../../../theme/variables';
import { compose } from 'redux';
import { sortLabels } from '../../labels/tools';
import { filterPractisSets } from '../tools';
import EmptyPractisSets from './EmptyPractisSets';
import {
    PractisSets,
    PractisSetStatuses,
} from '../../../constants/interfaces/PractisSets';
import { PractisSetWithDueDate } from '../../../constants/interfaces/Draft';
import { TableSearchInput } from '../../../ui/components/table-wrapper/table-tools/table-search-input';

//region Types
type Action = 'clear' | 'select';

export type PortablePractisSetsProps = {
    practisSets?: ListResult<PractisSets>;
    selectedList: PractisSetWithDueDate[];
    onSave?: () => void;
    onSelectAll?: (practisSets: PractisSets[]) => void;
    onDeselectAll?: () => void;
    onClearSelected?: () => void;
    onSetSelected?: (practisSetIds: PractisSetWithDueDate[]) => void;
    filtersTitle?: string;
    disabled?: boolean;
    loading?: boolean;
    showSelectActions?: boolean;
    showSearch?: boolean;
    showSave?: boolean;
    hideDueDates?: boolean;
    className?: string;
    selectAllWrapperClassName?: string;
};
//endregion

//region Styles
const StyledCreatePractisSets = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const CreatePractisSetsBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const FiltersHeader = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
    flex-shrink: 0;
`;

const FiltersSavePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const DueDatesSelectedCaption = styled(SelectedCaption)`
    width: 68px;
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
    font-weight: 600;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;

const StyledPlainActionsWrapper = styled.div`
    padding-right: 15px;
`;
//endregion

const PortablePractisSets: FC<PortablePractisSetsProps> = ({
    practisSets,
    selectedList,
    disabled,
    loading,
    onSave,
    onClearSelected,
    onDeselectAll,
    onSelectAll,
    onSetSelected,
    filtersTitle,
    showSelectActions,
    showSearch,
    showSave = true,
    hideDueDates,
    className,
    selectAllWrapperClassName,
}) => {
    const actionConfig: Record<
        Action,
        {
            color: string;
            colorHover: string;
            colorActive: string;
        }
    > = {
        clear: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
        select: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
    };

    const clearHandler = useCallback(() => {
        onClearSelected?.();
    }, [onClearSelected]);

    const [searchTerm, setSearchTerm] = useState('');
    const searchHandler = (value: string) => setSearchTerm(value);
    const [processedSelectedList, setProcessedSelectedList] = useState<
        PractisSetWithDueDate[]
    >([]);

    const processedPractisSets = useMemo(() => {
        if (practisSets) {
            const result = compose<PractisSets[]>(
                filterPractisSets(searchTerm, [PractisSetStatuses.ACTIVE]),
                sortLabels
            )(practisSets.items);

            if (result.length > 0) {
                const processedDataIds = result.map(item => item.id!);
                setProcessedSelectedList(
                    selectedList.filter(item =>
                        processedDataIds.includes(Math.abs(item.practisSetId))
                    )
                );
            }

            return result;
        } else {
            return [];
        }
    }, [searchTerm, practisSets, selectedList]);

    const isAllSelected = useMemo(
        () => selectedList.length === processedPractisSets?.length,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedList.length]
    );

    const toggleAllHandler = useCallback(() => {
        if (isAllSelected && onDeselectAll) {
            onDeselectAll();
        } else if (onSelectAll) {
            processedPractisSets.forEach(item => {
                item.dueDate =
                    selectedList.find(
                        ({ practisSetId }) => practisSetId === item.id
                    )?.dueDate || null;
            });

            onSelectAll(processedPractisSets);
        }
    }, [
        onDeselectAll,
        onSelectAll,
        isAllSelected,
        processedPractisSets,
        selectedList,
    ]);

    const showNoItems = !practisSets?.count;
    const showEmptyState = !processedPractisSets.length || loading;
    const selectedPractisSetsLength = selectedList.filter(
        ps => ps.practisSetId > 0
    ).length;

    return (
        <StyledCreatePractisSets className={className}>
            <CreatePractisSetsBody>
                {filtersTitle && (
                    <FiltersHeader>
                        <FilterTitle>{filtersTitle}</FilterTitle>
                    </FiltersHeader>
                )}
                {showSearch && (
                    <SearchWrapper data-test="practisset-searchbox-wrapper">
                        <TableSearchInput
                            onChange={searchHandler}
                            disabled={showNoItems}
                            dataTest="practisset-searchbox-field"
                        />
                    </SearchWrapper>
                )}
                {showSelectActions && !showNoItems && (
                    <SelectActions>
                        <div>
                            <SelectedCaption data-test="practisset-selected-caption">
                                {selectedPractisSetsLength > 0
                                    ? `${selectedPractisSetsLength} ${
                                          selectedPractisSetsLength > 1
                                              ? ' Practis Sets selected'
                                              : ' Practis Set selected'
                                      }`
                                    : 'No Practis Sets selected'}
                            </SelectedCaption>
                        </div>
                        <StyledPlainActionsWrapper
                            className={selectAllWrapperClassName}
                        >
                            {isAllSelected ? (
                                <PlainAction
                                    disabled={
                                        processedSelectedList.length === 0 ||
                                        showEmptyState
                                    }
                                    color={actionConfig.clear.color}
                                    colorActive={actionConfig.clear.colorActive}
                                    colorHover={actionConfig.clear.colorHover}
                                    onClick={clearHandler}
                                    data-test="practisset-unselect-all-button"
                                >
                                    Unselect All
                                </PlainAction>
                            ) : (
                                <PlainAction
                                    disabled={showEmptyState}
                                    color={actionConfig.select.color}
                                    colorActive={
                                        actionConfig.select.colorActive
                                    }
                                    colorHover={actionConfig.select.colorHover}
                                    onClick={toggleAllHandler}
                                    data-test="practisset-select-all-button"
                                >
                                    Select All
                                </PlainAction>
                            )}
                        </StyledPlainActionsWrapper>
                        {!hideDueDates && (
                            <DueDatesSelectedCaption data-test="due-dates-column-title">
                                Due Dates
                            </DueDatesSelectedCaption>
                        )}
                    </SelectActions>
                )}
                {showEmptyState ? (
                    <EmptyPractisSets
                        loading={loading}
                        isEmpty={!practisSets?.count}
                    />
                ) : (
                    <>
                        <PortablePractisSetsList
                            practisSets={processedPractisSets}
                            selectedPractisSets={selectedList}
                            disabled={disabled}
                            setPractisSetFilters={onSetSelected}
                            hideDueDates={hideDueDates}
                        />
                        {onSave && showSave && (
                            <FiltersSavePanel>
                                <Button
                                    type={'button'}
                                    label={'Save'}
                                    action={() => onSave()}
                                    height={'48px'}
                                    width={'160px'}
                                />
                            </FiltersSavePanel>
                        )}
                    </>
                )}
            </CreatePractisSetsBody>
        </StyledCreatePractisSets>
    );
};

export { PortablePractisSets };
