import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ScenariosIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={ref}
                className={className}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.286 19.333h14.286c.79 0 1.429-.595 1.429-1.333V3.333C22 2.597 21.36 2 20.572 2H6.286c-.789 0-1.428.597-1.428 1.333V18c0 .737.64 1.333 1.428 1.333zM19.858 3.334c.395 0 .714.298.714.667v13.333c0 .368-.32.667-.714.667H7c-.394 0-.714-.3-.714-.667V4.001c0-.369.32-.667.715-.667h12.857zm-1.43 12h-10c-.394 0-.714-.3-.714-.668 0-.368.32-.667.714-.667h10c.395 0 .715.299.715.667 0 .369-.32.667-.714.667zm-10-4h10c.395 0 .715-.3.715-.667 0-.368-.32-.667-.714-.667h-10c-.395 0-.715.299-.715.667 0 .368.32.667.714.667zm5.715-4H8.428c-.394 0-.714-.3-.714-.667 0-.369.32-.667.714-.667h5.715c.394 0 .714.298.714.667 0 .368-.32.666-.714.666zM3.429 22h15.714c.79 0 1.429-.597 1.429-1.334H3.429V4.668C2.639 4.668 2 5.264 2 6.001v14.666c0 .737.64 1.334 1.429 1.334z"
                    fill="#4AA9E2"
                />
            </svg>
        );
    }
);

export default ScenariosIcon;
