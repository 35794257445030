import { useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import {
    getUserPreferencesState,
    getUserPreferencesLoading,
    getUserPreferencesPendingValues,
} from '../../../../features/users/store/reducers';
import { NOTIFICATION_SETTING_TITLE } from './constants';
import {
    useGetUserPreferencesService,
    useUpdateUserPreferenceService,
} from '../../../../features/users/store/services';
import NotificationsView from './view';
import { NotificationsProps } from './types';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';

function NotificationsController({ userId }: NotificationsProps) {
    const preferences = useSelector(getUserPreferencesState);
    const pendingValues = useSelector(getUserPreferencesPendingValues);
    const isLoading = useSelector(getUserPreferencesLoading);
    const getPreferences = useGetUserPreferencesService();
    const updatePreference = useUpdateUserPreferenceService();
    const showMessage = useShowMessage();

    const items = useMemo(
        () =>
            preferences?.map(preference => {
                const pendingValue = pendingValues?.find(
                    item => item.key === preference.key
                )?.value;
                return {
                    key: preference.key,
                    type: preference.type,
                    title: NOTIFICATION_SETTING_TITLE[preference.key] ?? '',
                    description: preference.description,
                    value: pendingValue ?? preference.value,
                    isSaving: typeof pendingValue !== 'undefined',
                };
            }),
        [preferences, pendingValues]
    );

    const handleToggle = useCallback(
        (key: string) => {
            const item = items?.find(item => item.key === key);
            if (!item) return;
            const newValue = !item.value;
            updatePreference(userId, key, newValue, () => {
                showMessage(
                    `Notifications have been ${
                        newValue ? 'enabled' : 'disabled'
                    }`,
                    'success'
                );
            });
        },
        [userId, items, updatePreference, showMessage]
    );

    useEffect(() => {
        getPreferences(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NotificationsView
            isLoading={isLoading}
            items={items}
            onToggle={handleToggle}
        />
    );
}

export default NotificationsController;