import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-size: 15px;
    font-weight: 800;
    color: ${props => props.theme.Colors.dark};
    margin-bottom: 20px;
`;

const ChartBoxTitle: FC<{ className?: string, dataTest?: string }> = ({ children, className, dataTest }) => {
    return <Container className={className} data-test={dataTest}>{children}</Container>;
};

export default ChartBoxTitle;
