import { ReactElement } from 'react';

import {
    PortablePractisSetsWithStore,
    WithPractisSetsContext,
} from '../portablePractisSets';
import { AssignPractisSetsViewProps } from './type';
import {
    Actions,
    ButtonContainer,
    HeaderTitle,
    Divider,
    useStyles,
} from './styles';
import { PopupComponent } from '../../ui/components/Popup';
import { Button } from '../../ui/components/Button';

const AssignPractisSetsView = (
    props: AssignPractisSetsViewProps
): ReactElement => {
    const {
        triggerRef,
        horizontalOffset,
        verticalOffset,
        isOpen,
        isSaving,
        isApplyButtonDisabled,
        onClose,
        handleApplyPractisSets,
    } = props;

    const classes = useStyles();

    return (
        <WithPractisSetsContext.Provider
            value={{
                reducerName: 'performance',
            }}
        >
            <PopupComponent
                triggerRef={triggerRef}
                open={isOpen}
                onDismiss={onClose}
                padding="10px 24px 24px 24px"
                horizontalOffset={horizontalOffset}
                verticalOffset={verticalOffset}
            >
                <HeaderTitle data-test="assign-practis-sets-header">Assign Practis Sets and Due Dates</HeaderTitle>
                <PortablePractisSetsWithStore
                    showSave={false}
                    showSelectActions={true}
                    showSearch={true}
                    resetSelectedOnUnmount={true}
                    className={classes.portablePractisSetContainer}
                    selectAllWrapperClassName={classes.selectAllWrapper}
                />
                <Divider />
                <Actions>
                    <ButtonContainer>
                        <Button
                            label={'Cancel'}
                            variant={'inverse'}
                            action={onClose}
                            width={'112px'}
                            disabled={isSaving}
                            dataTest='cancel-button'
                        />
                    </ButtonContainer>
                    <Button
                        label={'Apply'}
                        action={handleApplyPractisSets}
                        width={'112px'}
                        loading={isSaving}
                        disabled={isApplyButtonDisabled}
                        dataTest='apply-button'
                    />
                </Actions>
            </PopupComponent>
        </WithPractisSetsContext.Provider>
    );
};

export default AssignPractisSetsView;
