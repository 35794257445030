import { FC, memo } from 'react';

import { Title, Wrapper } from './style';
import { FieldSetProps } from './type';

const Fieldset: FC<FieldSetProps> = ({
    children,
    title,
    withoutBorder,
    dataTest,
}) => {
    return (
        <Wrapper withoutBorder={withoutBorder} data-test={dataTest}>
            {title && <Title data-test={`${dataTest}-title`}>{title}</Title>}
            {children}
        </Wrapper>
    );
};

export default memo(Fieldset);