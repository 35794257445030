import styled from 'styled-components';

export const Container = styled.div`
    box-sizing: content-box;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: 800;
    line-height: 1.25;
    color: var(--ps-black-main);
    padding-top: 32px;
`;

export const Description = styled.div`
    font-size: 13px;
    color: var(--ps-grey-1);
    padding-top: 16px;
    padding-right: 16px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: right;
    padding-top: 32px;
`;