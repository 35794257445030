import styled from 'styled-components';

export const Title = styled.div`
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--ps-white-1);
    width: 100%;
    text-align: center;
`;

export const BackArrow = styled.img`
    position: absolute;
    top: 7px;
    left: 0;
    cursor: pointer;
    width: 16px;
    height: 14px;
`;

export const Description = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    text-align: center;
    padding-top: 4px;
`;

export const MobileNumber = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--ps-blue-main);
    padding-top: 8px;
`;

export const VerificationCodeContainer = styled.div`
    padding-top: 20px;
    min-height: 116px;
    box-sizing: border-box;

    .ReactInputVerificationCode__item {
        width: 62px;
        height: 62px;
        margin: 0 4px;
        border: 1px solid var(--ps-graphite-2);
        box-sizing: border-box;
        box-shadow: none!important;

        &.focused {
            border-color: var(--ps-grey-1);
        }

        span {
            font-size: 20px;
        }
    }

    &.has-error {
        .ReactInputVerificationCode__item {
            border-color: var(--ps-red-main);
        }
    }


`;

export const VerificationError = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-red-main);
    padding-top: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
`

export const ButtonContainer = styled.div`
    width: 100%;
`

export const FooterText = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-grey-2);
    padding-top: 12px;
`

export const SentLabel = styled.span`
    color: var(--ps-green-main);
`

export const CountdownTimer = styled.span`
    color: #535f6a;
`