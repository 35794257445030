import { forwardRef } from 'react';

import FiltersIcon from '../../icons/FiltersIcon';
import { Tooltip } from '../Tooltip';
import {
    FiltersDot,
    FiltersDotContainer,
    IconContainer,
    IconHolder,
    StyledAssignAction,
} from './styles';

const TeamMembersFilterHandler = forwardRef<
    HTMLButtonElement,
    {
        toggleOpen: () => void;
        open: boolean;
        disabled?: boolean;
        filtersCount?: number;
        dataTest?: string;
    }
>(({ toggleOpen, open, disabled = false, filtersCount = 0, dataTest }, ref) => {
    return (
        <StyledAssignAction
            ref={ref}
            onClick={() => !disabled && toggleOpen()}
            open={open}
            disabled={disabled}
            hasFilters={filtersCount > 0}
            data-test={dataTest && `${dataTest}-button`}
        >
            <Tooltip label={'Filters'} preset={'button-icon'} dataTest="filters-tooltip">
                <IconContainer>
                    <IconHolder disabled={disabled}>
                        <FiltersIcon />
                    </IconHolder>
                    {filtersCount > 0 && (
                        <FiltersDotContainer>
                            <FiltersDot
                                data-test={dataTest && `${dataTest}-counter`}
                            />
                        </FiltersDotContainer>
                    )}
                </IconContainer>
            </Tooltip>
        </StyledAssignAction>
    );
});

export default TeamMembersFilterHandler;
