import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const RightArrow = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 21 18"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        d="M7 4L15 12 23 4"
                        transform="translate(0 1) rotate(-90 15 8)"
                    />
                    <path d="M19 8L0 8" transform="translate(0 1)" />
                </g>
            </svg>
        );
    }
);

export default RightArrow;
