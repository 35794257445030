import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: var(--ps-grey-1);
`;

export const Subtitle = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--ps-black-main);
    margin-bottom: 35px;
`;

export const Container = styled.div`
    background: var(--ps-white-1);
    padding: 25px;
    height: 100vh;
    overflow-y: auto;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    position: relative;
`;

export const StyledLoading = styled.div`
    position: absolute;
    top: calc(230px + (100vh - 230px) / 2);
    left: 0;
    width: 100%;
`;

export const useStyles = makeStyles(theme => ({
    tableWrapperStyle: {
        borderTop: '1px solid var(--ps-grey-4)',
    },
    customTableColumnStyle: {
        paddingLeft: '15px'
    }
}))