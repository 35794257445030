import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useGetUserFeaturesService } from '../../features/users/store/services';
import { UserInterface, UserV2 } from '../../constants/interfaces/User';
import { MobileNumberVerificationStep } from '../../pages/Auth/AddMobileNumber/types';
import ROUTES from '../../routes/routes';

export interface PhoneVerificationParams {
    user: UserInterface | UserV2;
    isNewUser?: boolean;
    verificationStep?: MobileNumberVerificationStep;
    from?: Location
}

export const useRedirectToPhoneVerification = () => {
    const history = useHistory();
    const getUserFeatures = useGetUserFeaturesService();
    return useCallback((params: PhoneVerificationParams, cancelIfCanSkip: boolean = false) => {
        getUserFeatures(params.user.id)
            .then((data) => {
                if (cancelIfCanSkip && !!data && !!data.phoneSkip?.allow)
                    return;
                history.push(ROUTES.AUTH_PAGES.ADD_MOBILE_NUMBER, params)
            });
    }, [history, getUserFeatures]);
};
