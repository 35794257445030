import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../ui/components/Button';
import ROUTES from '../../../../routes/routes';
import { useTelepromterState } from '../../store/state';
import { useHistory } from '../../../../tools/router';
import CompletedIcon from '../../../../ui/icons/Completed';
import Close from '../../../../ui/icons/Close';

const Container = styled.div`
    background: ${props => props.theme.Colors.darkThree};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 72px 32px 0 32px;
    box-sizing: border-box;
    position: relative;
`;

const ExitIcon = styled.div`
    height: 14px;
    width: 14px;
    color: ${props => props.theme.Colors.white};
    position: absolute;
    top: 40px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
    user-select: none;
    &:hover {
        opacity: 0.7;
    }
    &:active {
        opacity: 0.6;
    }
`;

const IconContainer = styled.div`
    height: 72px;
    width: 72px;
    color: ${props => props.theme.Colors.lightMint};
    margin-bottom: 32px;
    align-self: center;
`;

const Title = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors.lightMint};
    margin-bottom: 4px;
`;

const Text = styled.div`
    font-size: 17px;
    color: ${props => props.theme.Colors.white};
    margin-bottom: 32px;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
`;

const Actions = styled.div`
    height: 180px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    margin-bottom: 8px;
`;

const Completed: FC = () => {
    const scenario = useTelepromterState().scenario;
    const history = useHistory();

    const redirectToScenario = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    const redirectToPlayback = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.PLAYBACK.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    const redirectToTraining = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.TRAINING.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    return (
        <Container>
            <ExitIcon onClick={redirectToScenario}>
                <Close />
            </ExitIcon>
            <Content>
                <IconContainer>
                    <CompletedIcon />
                </IconContainer>
                <Title>Success!</Title>
                <Text>Scenario Complete</Text>
            </Content>
            <Actions>
                <ButtonWrapper>
                    <Button
                        label="Playback"
                        width="152px"
                        height="48px"
                        color={'green'}
                        action={redirectToPlayback}
                        variant={'inverse'}
                    />
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button
                        label="Practis Again"
                        width="152px"
                        height="48px"
                        color={'green'}
                        action={redirectToTraining}
                    />
                </ButtonWrapper>
            </Actions>
        </Container>
    );
};

const PreviewContainer = () => {
    return <Completed />;
};

export default PreviewContainer;
