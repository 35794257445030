import BlankVideo from '../BlankVideo';
import { ScreenLoadingProps } from './types';
import {
    Container,
    TopContainer,
    Avatar,
    TopInfo,
    TopTitle,
    TopDescription,
    BottomTitle,
    BottomDescription
} from './styles';

function ScreenLoadingView({ isError }: ScreenLoadingProps) {
    return <Container>
        <TopContainer>
            <Avatar />
            <TopInfo>
                <TopTitle />
                <TopDescription />
            </TopInfo>
        </TopContainer>
        <BlankVideo
            isLoading={!isError}
            errorMessage="Something went wrong. Please try again."
        />
        <BottomTitle />
        <BottomDescription />
    </Container>;
}

export default ScreenLoadingView;