import React from 'react';
import styled from 'styled-components';
import NotFoundImage from '../../assets/images/404.svg';
import ROUTES from '../../routes/routes';
import { Button } from '../../ui/components/Button';
import LandingWrapper from '../../ui/wrapper/LandingWrapper/LandingWrapper';

export interface NotFoundPageInterface {
    history?: any;
}

const NotFoundContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 450px;
    justify-content: center;
    display: flex;
    height: 100%;
    padding: 0 50px;
    text-align: center;
`;
const StyledNotFoundImageContainer = styled.div`
    max-width: 600px;
    margin-bottom: 40px;
`;
const StyledNotFoundImage = styled.img`
    max-width: 100%;
`;
const StyledNotFoundHeader = styled.h1`
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: ${props => props.theme.Colors.dark};
    margin: 0;
    margin-bottom: 30px;
`;
const StyledActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const NotFoundPage = ({ history }: NotFoundPageInterface) => {
    const handleGoBack = () => {
        history.push(ROUTES.TEAMS);
    };

    return (
        <LandingWrapper height={'450px'}>
            <NotFoundContainer>
                <StyledNotFoundImageContainer>
                    <StyledNotFoundImage src={NotFoundImage} />
                </StyledNotFoundImageContainer>
                <StyledNotFoundHeader>
                    Looks like you found a dead end, we’ll try to fix this.
                </StyledNotFoundHeader>
                <StyledActionContainer>
                    <Button
                        style={{
                            minWidth: 110,
                            padding: '0 15px',
                            marginRight: 20,
                        }}
                        action={handleGoBack}
                    >
                        Go Back
                    </Button>
                </StyledActionContainer>
            </NotFoundContainer>
        </LandingWrapper>
    );
};

export default NotFoundPage;
