import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Mountains = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M15.171 0v.999h4.332v5.075h-6.428v-1h-.978v2.503L20 20H3.076l.001-.001L0 20 7.346 8.491l1.528 2.395 2.105-3.309V0h4.192zM7.346 10.61l-5.231 8.196 1.721-.001 4.363-6.858-.853-1.336zm4.192-1.786l-6.35 9.982h12.7l-6.35-9.982zm6.847-6.63h-4.192V4.88h4.192V2.193zm-5.31 1.686V1.194h-.978v2.687l.978-.001z"
                        id="Mountains"
                        fill="currentColor"
                    />
                </g>
            </svg>
        );
    }
);

export default Mountains;
