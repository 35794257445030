import { useCallback } from 'react';
import {Inviter} from "../../constants/interfaces/Inviter";

export const filterInviters = (term: string) => (
    inviters: Inviter[]
): Inviter[] => {
    if (!term) {
        return inviters;
    }

    return inviters.filter(
        item => item.firstName.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
        item.lastName.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
        `${item.firstName} ${item.lastName}`.trim().toLowerCase().indexOf(term.trim().toLowerCase()) !== -1
    );
};

type Entity = {
    id: number;
    invitersIDs: number[];
};

export const useCalculatePreSelectedInviters = <T extends Entity>() => {
    return useCallback((selectedItems: number[], entity: T[]) => {
        let countSelectedItems: Record<number, number> = {};
        let outputArray: number[] = [];

        const filteredEntities = entity.filter(entityItem => {
            return selectedItems.includes(entityItem.id);
        });
        for (let entity of filteredEntities) {
            for (let practisSetId of entity.invitersIDs) {
                countSelectedItems[practisSetId] =
                    countSelectedItems[practisSetId] + 1 || 1;
            }
        }

        for (const key in countSelectedItems) {
            if (countSelectedItems[key] === selectedItems.length) {
                outputArray.push(Number(key));
            } else {
                outputArray.push(-Number(key));
            }
        }

        return outputArray;
    }, []);
};

export const useCalculateDeletedPractisSets = () => {
    return useCallback((assignedPractisSets: number[], initialPractisSets: number[]) => {
        const deletedPractisSets: number[] = [];

        for (let practisSet of initialPractisSets) {
            if (
                !assignedPractisSets.includes(practisSet) &&
                !assignedPractisSets.includes(-practisSet)
            ) {
                deletedPractisSets.push(
                    practisSet < 0 ? -practisSet : practisSet
                );
            }
        }

        return deletedPractisSets;
    }, []);
};
