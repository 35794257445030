import React from 'react';
import { getConfig } from '../../tools/config';
import LoginAuthWrapper from '../../ui/wrapper/LoginAuthWrapper';
import {
    StyledCard,
    Item,
    Label,
    Value
} from './styles';

function tryParseBuildId(buildId: string | undefined) {
    if (buildId) {
        try {
            return parseInt(buildId);
        } catch {}
    }
    return buildId;
}

export const Health: React.FC = () => {
    const config = getConfig();
    return (
        <LoginAuthWrapper hideLogo={true}>
            <StyledCard>
                <h1>About App</h1>
                <Item>
                    <Label>Version:</Label>
                    <Value id="app_version">{config.APP_VERSION}</Value>
                </Item>
                <Item>
                    <Label>Build #:</Label>
                    <Value id="build_number">
                        {tryParseBuildId(config.BUILD_ID)}
                    </Value>
                </Item>
                <Item>
                    <Label>Commit:</Label>
                    <Value id="commit_id">{config.COMMIT_ID}</Value>
                </Item>
            </StyledCard>
        </LoginAuthWrapper>
    );
};

export default Health;
