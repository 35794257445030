import { CompanyInterface } from './Company';
import { Enrollments } from './Enrollments';
import { Label } from './Label';
import { Roles } from './Roles';
import { NEW_PERMISSIONS } from '../enums/permissions';
import { UserInfo } from './UserInfo';

export interface UserInterface extends UserWithRole {
    id: number;
    email?: string;
    password?: string;
    firstName?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    lastName?: string;
    picture?: string;
    companyId?: number;
    isTeamLead?: boolean;
    invitationCode?: string;
    acceptedEnrollmentCount?: number;
    searchFilter?: number[];
    totalEnrollmentCount?: number;
    pendingEnrollmentCount?: number;
    inProgressEnrollmentCount?: number;
    completedEnrollmentCount?: number;
    lastTrainingAt?: string;
    updatedAt?: string;
    company?: CompanyInterface;
    creator?: UserInterface;
    createdAt?: string;
    enrollment?: Enrollments;
    labels?: Label[];
    permissions?: NEW_PERMISSIONS[];
    weeklyActiveDays?: number;
    monthlyActiveDays?: number;
    teamsCount?: number;
    averageAccuracy?: number;
    status?: string;
    registeredAt?: string;
    lastActivity?: any;
    dueDate?: Date;
    roleId?: number;
    imageUrl?: string;
}

export interface UserWithRole {
    role?: Roles;
}

export type LeaderDataType = {
    isViewed: boolean;
    id: number;
    isTeamLead: boolean;
    user: {
        id: number;
        firstName: string;
        lastName: string;
        imageUrl: string
    }
}

export interface UserV2 extends UserInfo {
    companyId: number;
    roleId: number;
    createdAt: string;
    updatedAt: string;
    role?: {
        id: number;
        name: string;
        title: string;
    };
    countEnrollments: number;
    countTeams: number;
    lastActivity?: string;
    labels: number[];
    teams?: number[];
    registeredAt?: string;
    invitedBy?: UserInfo;
    invitationCode?: string;
    phoneNumber?: string;
    phoneNumberVerified: boolean;
}

export interface UserProfile extends UserV2 {
    permissions?: NEW_PERMISSIONS[];
    labelFilters?: number[];
}

export const userStatusFilters = {
    'PENDING': 'PENDING', 
    'IN_PROGRESS': 'ENROLLED',
    'COMPLETED': 'COMPLETED'
}

export interface AdminDataType {
    email: string;
    firstName: string;
    lastName: string;
    roleId: number;
}
