import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { Corona } from '../../../../ui/icons/Corona';

export const useStyles = makeStyles(theme => ({
    multiHeaderContainer: {
        width: '45% !important',
        
        '@media(max-width:1680px)': {
            width: '85% !important',
        },

        '@media(max-width: 1441px)': {
            width: '100% !important',
        },

        '@media(min-width:1681px)': {
            width: '70% !important',
        },

        '@media(min-width: 2041px)': {
            width: '57% !important',
        },
    },

    labelsCellStyle: {
        justifyContent: 'flex-end !important'
    }
}));

export const Info = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledAvatarPlaceholder = styled(AvatarPlaceholder)`
    height: 24px;
    width: 24px;
    margin-right: 8px;
`;

export const StyledCoronaIcon = styled(Corona)`
    width: 14px;
    margin-left: 4px;
    margin-bottom: 2px;

    color: ${props => props.theme.Colors.macaroniAndCheese};
`;

export const NameAdditionalInfo = styled.span`
    color: ${props => props.theme.Colors.black};
    margin-left: 3px;
`;

export const WrapperTableCell = styled.div`
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: no-wrap;

    td {
        display: inline-flex;
        align-items: center;
        padding: 0 !important;
        justify-content: flex-end;

        span {
            width: 95px;
        }
    }

    td:nth-child(1) {
        span {
            padding-left: 7px !important;
        }
    }

    td:nth-child(2) {
        span {
            padding-left: 7px !important;
            width: 92px;
        }
    }

    td:nth-child(3) {
        justify-content: flex-start;

        span {
            padding-left: 7px !important;
        }
    }

    @media (max-width: 1680px) {
        width: 90%;
    }

    @media (max-width: 1441px) {
        width: 100%;
    }
`;
