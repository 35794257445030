import { Challenge } from '../../constants/interfaces/Challenge';
import { ScriptLine } from '../../constants/interfaces/ScriptLine';

export const validationService = (challenge: Challenge, status: string) => {
    const output = {
        valid: true,
        message: '',
        name: ''
    };
    
    switch (status) {
        case 'DELETED':
            output.valid = true;
            break;
        case 'ACTIVE':
            if (!challenge.title?.trim()) {
                output.valid = false;
                output.message = 'Title required';
                output.name = 'name';
            } else if (challenge.script.lines.length < 1) {
                output.valid = false;
                output.message = 'Challenge should have at least one line';
            } else if (
                !challenge.script.totalDuration ||
                challenge.script.totalDuration < 0.1
            ) {
                output.valid = false;
                output.message = 'Audio records required';
            } else {
                challenge.script.lines.map((line: ScriptLine) => {
                    if (!line.audioUrl && line.speaker === 'CUSTOMER') {
                        output.valid = false;
                        output.message = 'Audio records required';
                    }
                    return output;
                });
            }
            break;
        case 'DRAFT':
            if (!challenge.title?.trim()) {
                output.valid = false;
                output.message = 'Title required';
                output.name = 'name';
            } else if (challenge.script.lines.some(line => !line.audioId)) {
                output.valid = false;
                output.message = 'Audio records required';
            }
            break;
        case 'ARCHIVED':
        default:
            if (challenge.title.length < 1) {
                output.valid = false;
                output.message = 'Title required';
            }
    }
    return output;
};

export enum ScriptLineSpeakers {
    EDIT = 'EDIT',
    VIEW = 'VIEW',
}