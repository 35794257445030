import React, { FC, ChangeEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { parseXlsFile } from '../../../../../tools/parseXlsx';
import { DraftUser, PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { CheckPermission } from '../../../../../features/permissions';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import { WithLabelsContext } from '../../../../../features/portableLabels';
import { WithPractisSetsContext } from '../../../../../features/portablePractisSets';
import { getUserRoleIdByName } from '../../tools';
import { useUserRolesState } from '../../../../../features/roles/store/states';

import { AssignFilters } from '../Filters/AssignFilters';
import { isTeamLeader } from '../../../../../constants/enums';
import { UserInterface } from '../../../../../constants/interfaces/User';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../UserProfile/store/reducers';
import { Divider } from '@material-ui/core';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { SelectAll } from '../../../../../ui/components/table-wrapper/SelectAll';
import { Popup } from '../../../../../ui/components/Popup';
import UpArrow from '../../../../../ui/icons/UpArrow';
import DownArrow from '../../../../../ui/icons/DownArrow';
import { Tooltip } from '../../../../../ui/components/Tooltip';
import Trash from '../../../../../ui/icons/Trash';
import RemoveUser from '../../../../../ui/icons/RemoveUser';
import { TableSearchInput } from '../../../../../ui/components/table-wrapper/table-tools/table-search-input';
import { ApplyFilters } from '../Filters/ApplyFilters';
import FilterByHandler from '../../../../../ui/components/Filters/FilterByHandler';
import FileDownload from '../../../../../ui/icons/FileDownload';
import Upload from '../../../../../ui/icons/Upload';
import { isExcelFile } from '../../../../../helpers/functions/file-helpers';
import { isEmptyOrWhiteSpace } from '../../../../../helpers/functions/strings';

const uuid = require('uuid/v1');

const StyledLabelActionPanel = styled.div<{ isActionPanelOpen?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${({ isActionPanelOpen }) => (isActionPanelOpen ? '16px 0 0 0' : '16px 0')};
`;

const StyledLabelActionButtons = styled.div`
    display: flex;
`;

const StyledIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    padding: 11px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.Colors.steelGrey};
    background: ${props => props.theme.Colors.whiteFive};
    &:active {
        opacity: 0.7;
    }
`;

const ActionDropdownsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledHeaderActionItems = styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
`;

const StyledIconContainer = styled.div`
    position: relative;
`;

const HeaderToolsItem = styled.div`
    z-index: 1;
`;

const IconWrapper = styled.div<{ disabled?: boolean }>`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    background: var(--ps-white-1);
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    margin-left: 8px;
    z-index: 1;
    &:active {
        opacity: ${props => (props.disabled ? '1' : '0.7')};
    }
`;

const DeleteUsersIcon = styled.div`
    height: 16px;
    width: 14px;
`;

const UploadTemplateIcon = styled.div`
    width: 14px;
    height: 16px;
`;

const DownloadTemplateIcon = styled.div`
    width: 16px;
    height: 16px;
`;

const RemoveUsersIcon = styled.div`
    height: 16px;
    width: 14px;
    color: ${props => props.theme.Colors.tomato};
`;

const SearchPanel = styled.div`
    height: 40px;
    width: 224px;
    margin-right: 8px;
`;

type UserFromExcelDoc = {
    'First Name': string;
    'Last Name': string;
    Email: string;
    Role: string;
};

const StyledAssignAction = styled.div<{ open?: boolean; disabled?: boolean }>`
    display: flex;
    height: 40px;
    width: 152px;
    border-radius: 4px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background: var(--ps-white-1);
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
    border: ${props => (props.open ? 1 : 0)}px solid
        ${props => props.theme.Colors.cloudyBlue};
    position: relative;
`;

const IconHolder = styled.div<{ disabled: boolean }>`
    width: 8px;
    height: 100%;
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

const Label = styled.div<{ disabled: boolean }>`
    position: absolute;
    top: 0;
    left: 16px;
    pointer-events: none;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

const TEMPLATE_URL_ADMIN =
    'https://docs.google.com/uc?id=15Ojf5QCjC-d0g-_Qzsmho_6gWl2oUMDm&export=download&hl=en_US';
const TEMPLATE_URL_TEAM_LEADER =
    'https://docs.google.com/uc?id=1dvshvYy5OC6_YgNwmX_jtS9oacNqyIFZ&export=download&hl=en_US';

const StyledUserCounterContainer = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.steelGrey};
    align-self: center;
`;

export const NewUserHeader: FC<{
    profile?: UserInterface;
    users?: PaginationResult<DraftUser>;
    localUsers?: any;
    selectedUsers: number[];
    existingUserIds: number[];
    onUsersImported(users: any[]): void;
    onRemoveUsers(): void;
    onRemoveExistingUsers(): void;
    disableFilters: boolean;
    disableSearch: boolean;
    setSearchTerm(value: string): void;
    assignFilters(
        assignedLabels: number[],
        deletedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        //PSWDD we dont need due date for deleted practis set, because it will be removed anyway
        deletedPractisSets: number[],
        assignedTeams: number[],
        deletedTeams: number[],
        roleId: null | number
    ): Promise<boolean>;
    applyFilters(
        assignedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        assignedTeams: number[],
        roleIds: number[]
    ): Promise<boolean>;
    selectedRoles: number[];
    setSelectedRoles(value: number[]): void;
    filterRoles: number[];
    setFilterRoles(value: number[]): void;
    filtersCount: number;
    isRoleDisabled: boolean | undefined;
    updateAllUsersCheck(checked: boolean): void;
    userItemsList: DraftUser[] | undefined;
    clearAllUsers: () => void;
    isSelectAll: boolean;
    updateSelectAll: (checked: boolean) => void;
    dataTest: string;
}> = ({
    users,
    localUsers,
    selectedUsers,
    existingUserIds,
    onUsersImported,
    onRemoveUsers,
    onRemoveExistingUsers,
    disableFilters,
    disableSearch,
    setSearchTerm,
    assignFilters,
    applyFilters,
    selectedRoles,
    setSelectedRoles,
    filterRoles,
    setFilterRoles,
    filtersCount,
    isRoleDisabled,
    updateAllUsersCheck,
    userItemsList,
    clearAllUsers,
    isSelectAll,
    updateSelectAll,
    dataTest
}) => {
        let upload: any;
        const showMessage = useShowMessage();
        const userRoles = useUserRolesState();
        const profile = useSelector(getProfileState);

        const handleDownloadTemplate = useCallback(() => {
            const isUserTeamLeader = isTeamLeader(profile?.role?.name);

            window.open(
                isUserTeamLeader ? TEMPLATE_URL_TEAM_LEADER : TEMPLATE_URL_ADMIN,
                '_blank'
            );
        }, [profile]);

        const getRoleId = useCallback( (roleName: string) => {
            return getUserRoleIdByName(roleName, userRoles.list);
        }, [userRoles.list]);

        /**
         * function showFileFormatErrorMessage - shows invalid file format message
         * @returns { void }
        */
        const showFileFormatErrorMessage = useCallback(() =>
            showMessage('No valid scheme found', 'error')
        , [showMessage]);

        /**
         * function handleParsedUsersData - filters out parsed data and converts it to user info,
         * shows error if data format is incorrect
         * @param { UserFromExcelDoc[] } data
         * @returns { void }
        */
        const handleParsedUsersData = useCallback(
            (data: UserFromExcelDoc[]) => {
                const parsedResults = data
                    .filter((user: UserFromExcelDoc) =>
                        !(isEmptyOrWhiteSpace(user['First Name'])
                            && isEmptyOrWhiteSpace(user['Last Name'])
                            && isEmptyOrWhiteSpace(user['Email'])
                            && isEmptyOrWhiteSpace(user['Role']))
                    ).map((user: UserFromExcelDoc) => {
                        return {
                            id: uuid(),
                            firstName: user['First Name'],
                            lastName: user['Last Name'],
                            email: user['Email'],
                            roleId: getRoleId(!!user['Role'] ? user['Role'] : ''),
                            practisSetIDs: [],
                            labelIDs: [],
                            practisSets: [],
                            isEntryInvalid: false,
                            teamIDs: [],
                        };
                });

                if (parsedResults.length > 0) {
                    onUsersImported(parsedResults);
                } else {
                    showFileFormatErrorMessage();
                }
            },
            [showFileFormatErrorMessage, onUsersImported, getRoleId]
        );

        /**
         * function handleImportUsers - perform file parsing if it's Excel file, otherwise shows error
         * @param { Event } e
         * @returns { void }
        */
        const handleImportUsers = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                const files: FileList | null = e.target.files;
                if (files && files.length > 0) {
                    if (isExcelFile(files[0].name)) {
                        parseXlsFile(files[0], handleParsedUsersData);
                    } else {
                        showFileFormatErrorMessage();
                    }
                }
                 // @ts-ignore
                e.target.value = null;
            },
            [handleParsedUsersData, showFileFormatErrorMessage]
        );

        useEffect(() => {
            return () => clearAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const isDisabled = selectedUsers.length < 1;

        const count = (!isEmpty(localUsers?.items) &&
            localUsers.items.filter(
                (item: any) => !item.isEntryInvalid
            ).length);

        const userItemsText =
            count
            ? `${count} ${
                count > 1 ? 'items' : 'item'
              }`
            : '0 items';

        return (
            <>
                {isEmpty(selectedUsers) && <Divider />}
                <StyledLabelActionPanel isActionPanelOpen={!isEmpty(selectedUsers)}>
                    {users && selectedUsers && (
                        <SelectAll
                            isSelectAll={isSelectAll}
                            selectedLength={selectedUsers.length}
                            totalCount={
                                users.totalCount ||
                                (localUsers?.items?.length &&
                                    localUsers?.items?.filter(
                                        (item: any) => !item.isEntryInvalid
                                    ).length)
                            }
                            itemsPerPage={20}
                            onClearSelecion={() => {
                                clearAllUsers();
                                updateAllUsersCheck(false);
                                updateSelectAll(false);
                            }}
                            onSelectAll={() => {
                                updateAllUsersCheck(true);
                                updateSelectAll(true);
                            }}
                            style={{
                                position: 'relative',
                                width: '100%',
                            }}
                            dataTest={dataTest}
                        >
                            <ActionDropdownsWrapper>
                                <WithLabelsContext.Provider
                                    value={{
                                        reducerName: 'draftEdit',
                                        scope: 'assignFilters',
                                    }}
                                >
                                    <WithPractisSetsContext.Provider
                                        value={{
                                            reducerName: 'draftEdit',
                                            scope: 'assignFilters',
                                        }}
                                    >
                                        <Popup<HTMLDivElement>
                                            content={({ hide }) => (
                                                <AssignFilters
                                                    onSuccessApply={() =>
                                                        hide()
                                                    }
                                                    users={users}
                                                    selectedUsers={
                                                        selectedUsers
                                                    }
                                                    assignFilters={
                                                        assignFilters
                                                    }
                                                    selectedRoles={
                                                        selectedRoles
                                                    }
                                                    setSelectedRoles={
                                                        setSelectedRoles
                                                    }
                                                    isRoleDisabled={
                                                        isRoleDisabled
                                                    }
                                                />
                                            )}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            {(ref, { toggleShown, shown }) => (
                                                <StyledAssignAction
                                                    ref={ref}
                                                    onClick={() =>
                                                        !isDisabled &&
                                                        toggleShown()
                                                    }
                                                    open={shown}
                                                    disabled={isDisabled}
                                                    data-test={`${dataTest}-assign-selected`}
                                                >
                                                    <Label
                                                        disabled={isDisabled}
                                                    >
                                                        Assign...
                                                    </Label>
                                                    <IconHolder
                                                        disabled={isDisabled}
                                                    >
                                                        {shown ? (
                                                            <UpArrow />
                                                        ) : (
                                                            <DownArrow />
                                                        )}
                                                    </IconHolder>
                                                </StyledAssignAction>
                                            )}
                                        </Popup>
                                    </WithPractisSetsContext.Provider>
                                </WithLabelsContext.Provider>
                                <HeaderToolsItem>
                                    <CheckPermission
                                        permissions={[
                                            NEW_PERMISSIONS.REMOVE_USER_FROM_STAGING,
                                        ]}
                                    >
                                        <Tooltip
                                            label={'Select users to delete'}
                                            preset={'button-icon'}
                                            disabled={!isDisabled}
                                        >
                                            <IconWrapper
                                                onClick={() => {
                                                    !isDisabled &&
                                                        onRemoveUsers();
                                                }}
                                                disabled={isDisabled}
                                                data-test={`${dataTest}-delete-selected`}
                                            >
                                                <DeleteUsersIcon>
                                                    <Trash />
                                                </DeleteUsersIcon>
                                            </IconWrapper>
                                        </Tooltip>
                                    </CheckPermission>
                                </HeaderToolsItem>
                                {existingUserIds.length > 0 && (
                                    <CheckPermission
                                        permissions={[
                                            NEW_PERMISSIONS.REMOVE_USER_FROM_STAGING,
                                        ]}
                                    >
                                        <Tooltip
                                            preset={'button-icon'}
                                            label={'Remove all existing users'}
                                            dataTest="remove-existing-users-tooltip"
                                        >
                                            <IconWrapper
                                                onClick={onRemoveExistingUsers}
                                                data-test={`${dataTest}-delete-existing`}
                                            >
                                                <RemoveUsersIcon>
                                                    <RemoveUser />
                                                </RemoveUsersIcon>
                                            </IconWrapper>
                                        </Tooltip>
                                    </CheckPermission>
                                )}
                            </ActionDropdownsWrapper>
                        </SelectAll>
                    )}

                    {isEmpty(selectedUsers) && (
                        <StyledLabelActionButtons>
                            <SearchPanel>
                                <TableSearchInput
                                    onChange={setSearchTerm}
                                    disabled={disableSearch}
                                />
                            </SearchPanel>

                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <ApplyFilters
                                        onSuccessApply={() => hide()}
                                        applyFilters={applyFilters}
                                        selectedRoles={filterRoles}
                                        setSelectedRoles={setFilterRoles}
                                        isRoleDisabled={isRoleDisabled}
                                        hideDueDates={true}
                                    />
                                )}
                                transformOrigin={{
                                    horizontal: 272,
                                    vertical: 0,
                                }}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        disabled={disableFilters}
                                        toggleOpen={toggleShown}
                                        filtersCount={filtersCount}
                                        dataTest={`${dataTest}-filters`}
                                    />
                                )}
                            </Popup>
                            <StyledIconContainer>
                                <StyledHeaderActionItems>
                                    <Tooltip
                                        label={'Download template'}
                                        preset={'button-icon'}
                                        dataTest="download-template-tooltip"
                                    >
                                        <StyledIconWrapper
                                            onClick={() =>
                                                handleDownloadTemplate()
                                            }
                                            data-test={`${dataTest}-download-template`}
                                        >
                                            <DownloadTemplateIcon>
                                                <FileDownload />
                                            </DownloadTemplateIcon>
                                        </StyledIconWrapper>
                                    </Tooltip>
                                </StyledHeaderActionItems>
                            </StyledIconContainer>
                            <StyledIconContainer>
                                <StyledHeaderActionItems>
                                    <Tooltip<HTMLDivElement>
                                        label={'Upload template'}
                                        preset={'button-icon'}
                                        dataTest="upload-template-tooltip"
                                    >
                                        {tooltipRef => (
                                            <>
                                                <input
                                                    id="myInput"
                                                    type="file"
                                                    ref={ref => (upload = ref)}
                                                    style={{ display: 'none' }}
                                                    onChange={e =>
                                                        handleImportUsers(e)
                                                    }
                                                />
                                                <StyledIconWrapper
                                                    ref={tooltipRef}
                                                    onClick={() =>
                                                        upload.click()
                                                    }
                                                    data-test={`${dataTest}-upload-template`}
                                                >
                                                    <UploadTemplateIcon>
                                                        <Upload />
                                                    </UploadTemplateIcon>
                                                </StyledIconWrapper>
                                            </>
                                        )}
                                    </Tooltip>
                                </StyledHeaderActionItems>
                            </StyledIconContainer>
                        </StyledLabelActionButtons>
                    )}
                    {!!users?.items.length && isEmpty(selectedUsers) && (
                        <StyledUserCounterContainer data-test={`${dataTest}-counter`}>
                            {userItemsText}
                        </StyledUserCounterContainer>
                    )}
                </StyledLabelActionPanel>
                {isEmpty(selectedUsers) && <Divider />}
            </>
        );
    };
