import { DraftUser } from '../../../../../constants/interfaces/Draft';

export const filterUsers = (term: string) => (
    users: Partial<DraftUser>[]
): Partial<DraftUser>[] => {
    if (!term) {
        return users;
    }

    return users.filter(
        item =>
            (item &&
                item.firstName &&
                item.firstName.toLowerCase().indexOf(term.toLowerCase()) !==
                    -1) ||
            (item &&
                item.lastName &&
                item.lastName.toLowerCase().indexOf(term.toLowerCase()) !==
                    -1) ||
            (item?.firstName && item.lastName &&
                `${item.firstName} ${item.lastName}`.trim().toLowerCase().indexOf(term.toLowerCase().trim()) !== -1)
    );
};
