import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ disabled: boolean }>`
    background: ${props =>
        props.disabled
            ? props.theme.Colors.whiteThree
            : props.theme.Colors.white};
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const Title = styled.div`
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 8px;
`;

const Text = styled.div<{ disabled: boolean }>`
    color: ${props =>
        props.disabled
            ? props.theme.Colors.steelGrey
            : props.theme.Colors.black};
    font-size: 17px;
    padding-left: 8px;
`;

const CustomerLine: FC<{
    text: string;
    disabled: boolean;
}> = ({ text, disabled }) => {
    return (
        <Container disabled={disabled}>
            <Title>Customer</Title>
            <Text disabled={disabled}>{text}</Text>
        </Container>
    );
};

export default CustomerLine;
