import { FC, memo } from 'react';

import { TBody } from './style';
import { TableBodyProps } from './type';

const TableBody: FC<TableBodyProps> = memo(props => {
    const { children, className, innerRef, ...rest } = props;
    return (
        <TBody className={className} ref={innerRef} {...rest}>
            {children}
        </TBody>
    );
});

export default TableBody;

