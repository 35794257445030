import { FC, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { PaginationResult } from '../../../../../../../constants/interfaces/PaginationResult';
import { Label } from '../../../../../../../constants/interfaces/Label';
import LabelsActionItem from './LabelsActionItem';
import { listToTree } from '../../../../../../../helpers/functions/list-to-tree';
import { Button } from '../../../../../Button';
import NoAddedLabels from './NoLabels';
import { SelectInput, SelectLabel } from '../../../../../SelectDropdown';
import UpArrow from '../../../../../../icons/UpArrow';
import DownArrow from '../../../../../../icons/DownArrow';

import { Variables } from '../../../../../../../theme/variables';
import {
    ClearSelectedLabels,
    IconHolder,
    LabelsContainer,
    ScrollableLabelContainer,
    SelectContainer,
    SelectedLabelsStyle,
    SelectedPanel,
    StyledButtonContainer,
    StyledLabelsList,
    StyledSelectForm,
    TitleContainer,
} from './styles';
import { ANIMATION_SCALE } from './constants';

export const LabelsAction: FC<{
    labels?: PaginationResult<Label>;
    disabled?: boolean;
    onAssignLabelsSubmit?(): void;
    title?: string;
    minWidth?: string;
    assignedLabelsCount?: number;
    setAssignLabelsAction?(labelIds: number[]): void;
    showSelectedPanel?: boolean;
    buttonTitle?: string;
    className?: string;
    textAlignedLeft?: boolean;
    containerLeftPosition?: string;
    height?: number;
    error?: boolean;
    isInput?: boolean;
    labelColor?: string;
    dataTest?: string;
}> = ({
    labels,
    disabled,
    onAssignLabelsSubmit,
    title,
    minWidth,
    assignedLabelsCount,
    setAssignLabelsAction,
    showSelectedPanel,
    buttonTitle,
    className,
    containerLeftPosition,
    height,
    error,
    isInput,
    labelColor,
    dataTest
}) => {
    const [show, setShow] = useState(false);

    const isDropdownDisabled =
        disabled || !labels || (!!labels && !labels.items.length);

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <StyledSelectForm
                disabled={isDropdownDisabled}
                border={show}
                minWidth={minWidth}
                className={[
                    className,
                    isDropdownDisabled ? 'is-disabled' : undefined,
                ].join(' ')}
                height={height}
                error={error}
                isInput={isInput}
            >
                <SelectLabel
                    label={title || 'Assign Labels'}
                    color={
                        (!!isInput && !assignedLabelsCount) ||
                        isDropdownDisabled
                            ? Variables.Colors.cloudyBlue
                            : !!labelColor
                            ? labelColor
                            : Variables.Colors.steelGrey
                    }
                    dataTest={dataTest && `${dataTest}-label`}
                />
                <IconHolder disabled={disabled}>
                    {show && !disabled ? <UpArrow /> : <DownArrow />}
                </IconHolder>
                <SelectInput toggleSelect={() => setShow(!show)} dataTest={dataTest && `${dataTest}-button`} />
                <SelectContainer open={show && !disabled}>
                    <LabelsContainer
                        initial={{ scale: 1 / ANIMATION_SCALE }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        topMargin={5}
                        left={containerLeftPosition}
                        isInput={isInput}
                    >
                        {labels && labels.items.length < 1 ? (
                            <NoAddedLabels />
                        ) : (
                            <StyledLabelsList>
                                {!!showSelectedPanel && (
                                    <>
                                        <TitleContainer>
                                            Assign Labels
                                        </TitleContainer>
                                        <SelectedPanel>
                                            <SelectedLabelsStyle>
                                                {assignedLabelsCount &&
                                                assignedLabelsCount > 0
                                                    ? `${assignedLabelsCount} ${
                                                          assignedLabelsCount >
                                                          1
                                                              ? ' labels selected'
                                                              : ' label selected'
                                                      }`
                                                    : 'No label selected'}
                                            </SelectedLabelsStyle>
                                            <ClearSelectedLabels
                                                onClick={() => {
                                                    setAssignLabelsAction &&
                                                        setAssignLabelsAction(
                                                            []
                                                        );
                                                }}
                                                disabled={
                                                    !(
                                                        assignedLabelsCount &&
                                                        assignedLabelsCount > 0
                                                    )
                                                }
                                            >
                                                Clear
                                            </ClearSelectedLabels>
                                        </SelectedPanel>
                                    </>
                                )}
                                <ScrollableLabelContainer>
                                    {labels &&
                                        listToTree(labels.items || []).map(
                                            label => {
                                                return (
                                                    <LabelsActionItem
                                                        key={label.id}
                                                        label={label}
                                                        dataTest={`${dataTest}-item`}
                                                    />
                                                );
                                            }
                                        )}
                                </ScrollableLabelContainer>
                                <StyledButtonContainer>
                                    <Button
                                        width="160px"
                                        height="40px"
                                        action={() => {
                                            setShow(false);
                                            onAssignLabelsSubmit &&
                                                onAssignLabelsSubmit();
                                        }}
                                        dataTest={`${dataTest}-save-changes`}
                                    >
                                        {buttonTitle || 'Save Changes'}
                                    </Button>
                                </StyledButtonContainer>
                            </StyledLabelsList>
                        )}
                    </LabelsContainer>
                </SelectContainer>
            </StyledSelectForm>
        </ClickAwayListener>
    );
};
