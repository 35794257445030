import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PauseCircle = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="7.5" stroke="#4AA9E2"/>
                <path d="M6 5v6M10 5v6" stroke="#4AA9E2" stroke-linecap="round"/>
            </svg>
        );
    }
);

export default PauseCircle;
