import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../../../../UserProfile/store/reducers';
import { getCompanyState } from '../../../../../../../CompanySettings/store/reducers';
import { useGetCompanyService } from '../../../../../../../CompanySettings/store/services';

//region Styles
const StyledBuilderConfigs = styled.div`
    flex: 1;
    background: ${props => props.theme.Colors.dark};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const StyledValue = styled.div<{ disabled?: boolean }>`
    font-size: 17px;
    font-weight: bold;
    color: ${props => props.theme.Colors.white};
    margin: 0;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const MinimumAccuracy = () => {
    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);
    const getCompany = useGetCompanyService();

    useEffect(() => {
        if (!company?.id && profile?.companyId) {
            getCompany(profile.companyId);
        }
    }, [company?.id, profile?.companyId, getCompany]);
    
    const minimumAccuracy = company?.minTrainingScore || 65;

    return (
        <StyledBuilderConfigs>
            <StyledDescription data-test='practis-set-minimum-accuracy-text'>
                Minimum Accuracy
            </StyledDescription>
            <StyledValue
                disabled={true}
                data-test='practis-set-minimum-accuracy-value'>
                    {minimumAccuracy}%
            </StyledValue>
        </StyledBuilderConfigs>
    );
};

export default MinimumAccuracy;
