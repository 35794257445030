import { RegisterFormValuesType } from './types';

/**
 * @function isSubmitButtonDisabled
 * @param { RegisterFormValuesType } values
 * @returns { boolean }
 */
export const isSubmitButtonDisabled = (
    values: RegisterFormValuesType
): boolean => {
    if (!values) return true;

    const keys = Object.keys(values);
    const formValues: any = values;
    return keys.filter(key => formValues[key]).length < keys.length;
};

