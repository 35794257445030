import React, {
    FC,
    useCallback,
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';
import { Variables } from '../../../../theme/variables';
import { Button } from '../../../../ui/components/Button';
import { Fieldset } from '../../../../ui/components/Fieldset';
import Checkbox from '../../../../ui/components/Checkbox';
import { PortableLabelsWithStore } from '../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
import { PortableTeamsWithStore } from '../../../portableTeams';
import { useTeamsState } from '../../../portableTeams/store/hors/withTeams/states';
import {
    useDeselectAllTeams,
    useSaveTeams,
} from '../../../portableTeams/store/hors/withTeams/services';
import { PortableScenariosWithStore } from '../../../../features/portableScenarios/';
import { useScenariosState } from '../../../../features/portableScenarios/store/hors/withTeams/states';
import { useDeselectAllScenarios, useSaveScenarios } from '../../../../features/portableScenarios/store/hors/withTeams/services';
import { AccuracyTestSubmissionFilters } from '../../../../constants/interfaces/SubmissionSearchFilters';

//region Styles
const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;

    overflow: auto;
    background-color: inherit;
`;

const TeamsContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
    background-color: inherit;
`;

const StatusItem = styled.div`
    margin-bottom: 8px;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;
//endregion

export const AccuracyTestsFilters: FC<{
    filters: AccuracyTestSubmissionFilters;
    onSuccessApply: (filters: AccuracyTestSubmissionFilters) => void;
}> = ({
    filters,
    onSuccessApply,
}) => {
    const labels = useLabelsState();
    const teams = useTeamsState();
    const scenarios = useScenariosState();
 
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
    const saveTeams = useSaveTeams();
    const saveScenarios = useSaveScenarios();
    
    const [showArchived, setShowArchived] = useState<boolean | undefined>();
    const ifPreselectedLabelsChanged = useIfChanged(filters.labelIds);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(filters.labelIds);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        filters.labelIds,
        saveLabels,
        selectLabels,
    ]);

    const onApplyHandler = useCallback(() => {
        saveLabels();
        saveTeams();
        saveScenarios();
        onSuccessApply({
            showArchived: showArchived ?? filters.showArchived,
            labelIds: labels.selected,
            teamIds: teams.selected,
            scenarioIds: scenarios.selected
        });
    }, [
        saveLabels,
        saveTeams,
        saveScenarios,
        showArchived,
        labels.selected,
        teams.selected,
        scenarios.selected,
        onSuccessApply,
        filters
    ]);

    const totalFiltersSelected =
        labels &&
        teams.selected.length + scenarios.selected.length +
            labels.selected.filter(id => id > 0).length +
            ((showArchived ?? filters.showArchived) ? 1 : 0);

    const deselectTeams = useDeselectAllTeams();
    const deselectLabels = useDeselectAllLabels();
    const deselectScenarios = useDeselectAllScenarios();

    const onClearHandler = () => {
        setShowArchived(false);
        deselectTeams();
        deselectLabels();
        deselectScenarios();
    };

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Status'} dataTest="status-section-title">
                    <StatusContainer>
                        <StatusItem data-test="status-item-container">
                            <Checkbox
                                checked={showArchived ?? filters.showArchived}
                                handleChange={e => {
                                    setShowArchived(e.target.checked);
                                }}
                                label="Archived"
                                border={Variables.Colors.steelGrey}
                                size={12}
                                fontColor={Variables.Colors.black}
                                dataTest="archived-checkbox"
                            />
                        </StatusItem>
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Scenarios'} dataTest="scenarios-section-title">
                    <TeamsContainer>
                        <PortableScenariosWithStore
                            showSearch
                            showSelectActions
                            showSave={false}
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Teams'} dataTest="teams-section-title">
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant={'inverse'}
                        width={'122px'}
                        action={() => onClearHandler()}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={onApplyHandler}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
