import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const TagDisabled = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 12"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                d="M14 13V7h-1V3L8-.75 3 3v10h4v.998h6.006L13 14H3c-.552 0-1-.448-1-1V2.5L8-2l6 4.5V13zM8 6c-1.105 0-2-.895-2-2s.895-2 2-2c1.104 0 2 .895 2 2s-.896 2-2 2zm0-3c-.553 0-1 .447-1 1 0 .552.447 1 1 1 .552 0 1-.447 1-1 0-.553-.448-1-1-1zm1.836 10.996l-.832-.749 1.713-1.747-1.713-1.747.832-.749 1.663 1.697 1.664-1.697.832.749-1.713 1.747 1.713 1.747-.832.75-1.664-1.698-1.663 1.697z"
                                transform="translate(-318 -294) translate(304 288) rotate(90 12 16)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default TagDisabled;
