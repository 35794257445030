import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Box = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 20 20"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    fillRule="evenodd"
                    fill="currentColor"
                    d="M18.75 8v10.667c0 .736-.56 1.333-1.25 1.333h-15c-.69 0-1.25-.597-1.25-1.333V8C.56 8 0 7.403 0 6.667V1.333C0 .597.56 0 1.25 0h17.5C19.44 0 20 .597 20 1.333v5.334C20 7.404 19.44 8 18.75 8zM2.5 18c0 .368.28.667.625.667h13.75c.345 0 .625-.299.625-.667V8.666c0-.367-.28-.665-.625-.665H13.75v2.666c0 .737-.56 1.333-1.25 1.333h-5c-.69 0-1.25-.596-1.25-1.333V8H3.125c-.345 0-.625.298-.625.665V18zm10-8V8.001h-5V10c0 .368.28.667.625.667h3.75c.345 0 .625-.299.625-.667zm6.25-8c0-.368-.28-.667-.625-.667H1.875c-.345 0-.625.299-.625.667v4c0 .368.28.667.625.667h16.25c.345 0 .625-.299.625-.667V2z"
                />
            </svg>
        );
    }
);

export default Box;
