import { FC } from 'react';
import { UserProfile } from '../../../../../../../constants/interfaces/User';

import MinimizedUserProfileArea from '../../../../../../layout/Sidebar/components/ProfileSection/components/MinimizedUserProfileArea';
import { FlexWrapper } from './styles';
import { AvatarFieldProps } from './types';

const MinimizedAvatarField: FC<AvatarFieldProps> = props => {
    const { row, renderTitle, getAvatarProps, hasMargin, className, dataTest } = props;

    return (
        <FlexWrapper
            hasMargin={hasMargin?.(row) ?? false}
            className={className}
        >
            <MinimizedUserProfileArea
                profile={getAvatarProps?.(row)?.profile ?? {} as Partial<UserProfile>}
                dataTest={getAvatarProps?.(row)?.dataTest ?? (dataTest ? `${dataTest}-avatar` : undefined)}
            />
            <span data-test={dataTest && `${dataTest}-title`}>{renderTitle?.(row)}</span>
        </FlexWrapper>
    );
};

export default MinimizedAvatarField;

