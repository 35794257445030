import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { DraftUser, PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import { useCalculateDeletedLabels } from '../../../../../features/labels/tools';
import PortableRoles from '../../../../../features/portableRoles/portableRoles';
import { FilterLabels } from './components/FilterLabels/FilterLabels';
import FilterPractisSets from './components/FilterPractisSets/FilterPractisSets';
import { useCalculateDeletedPractisSets } from '../../../../../features/portablePractisSets/tools';
import { usePractisSetsState } from '../../../../../features/portablePractisSets/store/hors/withPractisSets/states';
import { useLabelsState } from '../../../../../features/portableLabels/store/hors/withLabels/states';
import FilterTeams from './components/FilterTeams/FilterTeams';
import { useTeamsState } from '../../../../../features/portableTeams/store/hors/withTeams/states';
import { useCalculateDeletedTeams } from '../../../../../features/portableTeams/tools';
import { Fieldset } from '../../../../../ui/components/Fieldset';
import { Button } from '../../../../../ui/components/Button';

const Container = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const RolesContainer = styled.div`
    width: 129px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const TeamsContainer = styled.div`
    width: 264px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const PractisSetsContainer = styled.div`
    width: 312px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const ButtonContainer = styled.div`
    margin-right: 16px;
`;

export const AssignFilters: FC<{
    users?: PaginationResult<DraftUser>;
    selectedUsers: number[];
    assignFilters(
        assignedLabels: number[],
        deletedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        deletedPractisSets: number[],
        assignedTeams: number[],
        deletedTeams: number[],
        roleId: null | number
    ): Promise<boolean>;
    selectedRoles: number[];
    setSelectedRoles(value: number[]): void;
    onSuccessApply: (isCancel?: boolean) => void;
    isRoleDisabled?: boolean;
}> = ({
    users,
    selectedUsers,
    assignFilters,
    selectedRoles,
    setSelectedRoles,
    onSuccessApply,
    isRoleDisabled,
}) => {
    const [saving, setSaving] = useState(false);

    const { selected: selectedLabels } = useLabelsState();
    const { selected: selectedPractisSets } = usePractisSetsState();
    const { selected: selectedTeams } = useTeamsState();
    const getDeletedLabels = useCalculateDeletedLabels();
    const getDeletedPractisSets = useCalculateDeletedPractisSets();
    const getDeletedTeams = useCalculateDeletedTeams();
    const [initialPractisSets, setInitialPractisSets] = useState<number[]>([]);
    const [initialTeams, setInitialTeams] = useState<number[]>([]);

    const handleApplyFilters = () => {
        const assignedLabels = selectedLabels.filter(x => x > 0);
        const deletedLabels = getDeletedLabels(selectedLabels);
        const assignedPractisSets = selectedPractisSets.filter(x => x.practisSetId > 0);
        const deletedPractisSets = getDeletedPractisSets(
            selectedPractisSets.map(item => item.practisSetId),
            initialPractisSets
        );

        const assignedTeams = selectedTeams.filter(x => x > 0);
        const deletedTeams = getDeletedTeams(selectedTeams, initialTeams);
   
        setSaving(true);
        assignFilters(
            assignedLabels,
            deletedLabels,
            assignedPractisSets,
            deletedPractisSets,
            assignedTeams,
            deletedTeams,
            selectedRoles.length > 1 ? null : selectedRoles[0]
        ).then(updated => {
            if (updated) {
                onSuccessApply();
            }
            setSaving(false);
        });
    };

    return (
        <Container>
            <Wrapper>
                {!isRoleDisabled && (
                    <Fieldset title={'Roles'} dataTest="roles-section-title">
                        <RolesContainer>
                            <PortableRoles
                                users={users}
                                selectedUsers={selectedUsers}
                                selectedRoles={selectedRoles}
                                setSelectedRoles={setSelectedRoles}
                            />
                        </RolesContainer>
                    </Fieldset>
                )}
                <Fieldset title={'Teams'} dataTest="teams-section-title">
                    <TeamsContainer>
                        <FilterTeams
                            showDefaultMembers={true}
                            users={users}
                            selectedUsers={selectedUsers}
                            setInitialTeams={setInitialTeams}
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Practis Sets'} dataTest="practis-sets-section-title">
                    <PractisSetsContainer>
                        <FilterPractisSets
                            users={users}
                            selectedUsers={selectedUsers}
                            setInitialPractisSets={setInitialPractisSets}
                        />
                    </PractisSetsContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <FilterLabels
                            users={users}
                            selectedUsers={selectedUsers}
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <ButtonContainer>
                    <Button
                        label={'Cancel'}
                        variant={'inverse'}
                        action={() => onSuccessApply(true)}
                        width={'112px'}
                        disabled={saving}
                    />
                </ButtonContainer>
                <Button
                    label={'Apply'}
                    action={handleApplyFilters}
                    width={'112px'}
                    loading={saving}
                />
            </Actions>
        </Container>
    );
};
