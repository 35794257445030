import CSS from 'csstype';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledActionBox = styled.div``;

export const OutsideActionBox: React.FC<{
    open: boolean;
    toggleOpen?: Function;
    styles?: CSS.Properties;
    className?: string;
}> = ({ children, open, toggleOpen, styles, className }) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            if (!!toggleOpen) {
                if (open) toggleOpen(false);
            }
        }
    };

    const checkIfInView = (element: any) => {
        var offset = ref.current?.offsetTop || 0 - window.scrollY;

        if (offset > window.innerHeight) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        open && !checkIfInView(ref.current) && ref.current?.scrollIntoView();
    }, [open]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });

    return (
        <StyledActionBox
            ref={ref}
            style={styles ? styles : undefined}
            className={className}
        >
            {open ? children : ''}
        </StyledActionBox>
    );
};

export default OutsideActionBox;
