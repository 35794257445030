import BrokenVideo from '../../icons/BrokenVideo';
import {
    ErrorTitle,
    ErrorDescription
} from './styles';
import LoginAuthWrapper from '../../wrapper/LoginAuthWrapper';
import { UnavailableContentProps } from './types';

function UnavailableContentView({ title, description }: UnavailableContentProps) {
    return (
        <LoginAuthWrapper contentWidth={324} heightToShowScroll={500}>
            <BrokenVideo />
            <ErrorTitle>{title}</ErrorTitle>
            <ErrorDescription>
                {description}
            </ErrorDescription>
            <ErrorDescription>
                You can also <a href='mailto:info@gopractis.com'>contact us</a> at Practis and we may be able to help.
            </ErrorDescription>
        </LoginAuthWrapper>
    );
}

export default UnavailableContentView;