import { Button } from '../Button';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { ActionsContainer, Description, Title } from './styles';
import { GeneralBulkActionModalInterface } from './types';

function FailedModalView(props: GeneralBulkActionModalInterface) {
    const { modalTitle, dataTest = 'failed-progress-modal', onClose } = props;

    return (
        <DialogWrapper
            customization={{ width: 402, padding: '35px 40px' }}
        >
            <Title
                color="var(--ps-red-main)"
                data-test={dataTest && `${dataTest}-title`}
            >
                Failed: {modalTitle}
            </Title>

            <Description data-test={dataTest && `${dataTest}-description`}>
                Oops! Something went wrong, please try again or contact our
                support team.
            </Description>

            <ActionsContainer>
                <Button
                    width="128px"
                    height="40px"
                    action={onClose}
                    color="warning-inverse"
                    customColor="var(--ps-red-main)"
                    style={{ marginLeft: '15px' }}
                    dataTest={dataTest && `${dataTest}-got-it`}
                >
                    Got it
                </Button>
            </ActionsContainer>
        </DialogWrapper>
    );
}

export default FailedModalView;
