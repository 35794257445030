import {
    ACTIONS,
    COMPANY_INFO,
    COMPANY_INFO_FAILURE,
    COMPANY_INFO_SUCCESS,
    loadVoiceSettingsFailure,
    loadVoiceSettingsStart,
    loadVoiceSettingsSuccess,
    RESET_COMPANY_INFORMATION,
    fetchCompanyAdminsStart,
    fetchCompanyAdminsSuccess,
    fetchCompanyAdminsFailure,
    fetchCompanyUserStatsStart,
    fetchCompanyUserStatsSuccess,
    fetchCompanyUserStatsFailure,
    clearCompanyAdmins,
} from './actions';
import { AppState } from '../../../store/reducers';
import { CompanyInterface } from '../../../constants/interfaces/Company';
import { Reducer } from 'redux';
import {
    CompanyVoiceSettingsState,
    CompanyAdminsState,
    CompanyUserStatsState,
} from './states';

export interface CompanyProfileInterface {
    info: CompanyInterface;
    isLoading?: boolean;
    error?: string;
}

const initialState: any = {
    info: {},
    isLoading: false,
    error: '',
};

const companyProfileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPANY_INFO:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case COMPANY_INFO_SUCCESS:
            return {
                info: {
                    ...action.data,
                },
                isLoading: false,
                error: null,
            };
        case COMPANY_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case RESET_COMPANY_INFORMATION:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const getCompanyState = (state: AppState) => state.company.info;
export const getCompanyLoading = (state: AppState) => state.company.isLoading;
export const getCompanyError = (state: AppState) => state.company.error;

export default companyProfileReducer;

const initialCompanyVoiceSettingsState: CompanyVoiceSettingsState = {
    data: [],
    loading: false,
};

export type CompanyVoiceSettingsAction =
    | ReturnType<typeof loadVoiceSettingsStart>
    | ReturnType<typeof loadVoiceSettingsSuccess>
    | ReturnType<typeof loadVoiceSettingsFailure>;

export const companyVoiceSettingsReducer: Reducer<
    CompanyVoiceSettingsState,
    CompanyVoiceSettingsAction
> = (
    state = initialCompanyVoiceSettingsState,
    action: CompanyVoiceSettingsAction
): CompanyVoiceSettingsState => {
    switch (action.type) {
        case ACTIONS.LOAD_VOICE_SETTINGS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.LOAD_VOICE_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.voiceSettings,
                loading: false,
                error: '',
            };
        case ACTIONS.LOAD_VOICE_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

const initialCompanyAdminsState: CompanyAdminsState = {
    data: undefined,
    loading: false,
    error: ''
};

export type companyAdminsAction =
    | ReturnType<typeof fetchCompanyAdminsStart>
    | ReturnType<typeof fetchCompanyAdminsSuccess>
    | ReturnType<typeof fetchCompanyAdminsFailure>
    | ReturnType<typeof clearCompanyAdmins>;

export const companyAdminsReducer: Reducer<
    CompanyAdminsState,
    companyAdminsAction
> = (
    state = initialCompanyAdminsState,
    action: companyAdminsAction
): CompanyAdminsState => {
    switch (action.type) {
        case ACTIONS.FETCH_COMPANY_ADMINS_START:
            return {
                ...state,
                loading: true
            };
        case ACTIONS.FETCH_COMPANY_ADMINS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: ''
            };
        case ACTIONS.FETCH_COMPANY_ADMINS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case ACTIONS.CLEAR_COMPANY_ADMINS:
            return initialCompanyAdminsState;
        default:
            return state;
    }
};

const initialCompanyUserStatsState: CompanyUserStatsState = {
    data: undefined,
    loading: false,
    error: ''
};

export type companyUserStatsAction =
    | ReturnType<typeof fetchCompanyUserStatsStart>
    | ReturnType<typeof fetchCompanyUserStatsSuccess>
    | ReturnType<typeof fetchCompanyUserStatsFailure>;

export const companyUserStatsReducer: Reducer<
    CompanyUserStatsState,
    companyUserStatsAction
> = (
    state = initialCompanyUserStatsState,
    action: companyUserStatsAction
): CompanyUserStatsState => {
    switch (action.type) {
        case ACTIONS.FETCH_COMPANY_USER_STATS_START:
            return {
                ...state,
                loading: true
            };
        case ACTIONS.FETCH_COMPANY_USER_STATS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: ''
            };
        case ACTIONS.FETCH_COMPANY_USER_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};