import { Reducer } from 'redux';
import { cloneDeep } from 'lodash';

import { Config } from './types';
import { Actions } from './actions';
import { decapsulateAction } from '../../../../../store/helpers/decapsulateAction';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { AppState } from '../../../../../store/reducers';
import { DEFAULT_SCENARIOS_SCOPE, Scope, WithScenarioScopes } from './scopes';
import { Scenario } from '../../../../../constants/interfaces/Scenario';
import { toggleItemInArray } from '../../../../../helpers/functions/array-helpers';

//region Reducer state
export type StateWithScenarios = {
    _scenarios: Record<Scope, ScenariosState>;
};

export type ScenariosState = {
    data: PaginationResult<Scenario>;
    selected: number[];
    saved: number[];
    loading: boolean;
    error: string | null;
};

const initialState: ScenariosState = {
    data: {
        items: [],
        limit: 0,
        offset: 0,
        numberOfPages: 0,
        totalCount: 0,
        lastUpdated: undefined,
    },
    selected: [],
    saved: [],
    loading: false,
    error: null,
};

export const withScenariosInitialState = <T>(
    baseInitialState: T,
    reducerName: keyof AppState
): T & StateWithScenarios => {
    const scopes =
        WithScenarioScopes[reducerName] === undefined
            ? [DEFAULT_SCENARIOS_SCOPE]
            : [
                  ...(WithScenarioScopes[reducerName] as string[]),
                  DEFAULT_SCENARIOS_SCOPE,
              ];

    return {
        ...baseInitialState,
        _scenarios: scopes.reduce((acc: Record<string, ScenariosState>, scope) => {
            acc[scope] = cloneDeep(initialState);
            return acc;
        }, {}),
    };
};
//endregion

//region HOR
export const withScenariosReducer = <S extends StateWithScenarios>(config: Config) => (
    baseReducer: Reducer<S>
) => (state: S, nativeAction: Actions): S => {
    let newState = state;
    const { reducerName } = config;
   
    const action = decapsulateAction(`${reducerName}/scenarios/`, nativeAction);
    const scope = action._scope;
   
    if (!scope) {
        return baseReducer(newState, action);
    }



    switch (action.type) {
        case 'clearScenarios':
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: [],
                        partial: [],
                    },
                },
            };
            break;
        case 'saveScenarios':
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        saved: [...state._scenarios[scope].selected],
                    },
                },
            };
            break;
        case 'selectScenarios':
            let updatedSelectedState = [...state._scenarios[scope].selected];

            updatedSelectedState = toggleItemInArray(
                updatedSelectedState,
                action.id
            );

            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: updatedSelectedState,
                    },
                },
            };
            break;
        case 'selectMultipleScenarios': {
         
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: action.ids,
                    },
                },
            };
            break;
        }
        case 'selectAllScenarios':
            const newSelectedList = action.allScenarios;
            
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: newSelectedList,
                    },
                },
            };
            break;
        case 'deselectAllScenarios':
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: [],
                    },
                },
            };
            break;
        case 'resetSelectedScenarios': {
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: state._scenarios[scope].saved,
                    },
                },
            };
            break;
        }
        case 'resetScenarios':
            newState = {
                ...newState,
                _scenarios: {
                    ...state._scenarios,
                    [scope]: {
                        ...state._scenarios[scope],
                        selected: [],
                        saved: [],
                        collapsed: [],
                    },
                },
            };
            break;
    }

    return baseReducer(newState, action);
};
//endregion
