import { motion } from 'framer-motion';
import styled from 'styled-components';

import OutsideActionBox from '../../../../../OutsideActionBox/OutsideActionBox';

export const StyledSelectForm = styled.div<{
    disabled?: boolean;
    border?: boolean;
    minWidth?: string;
    height?: number;
    error?: boolean;
    isInput?: boolean;
}>`
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    min-width: 128px;
    cursor: pointer;
    height: ${props =>
        props.height ? `${props.height}px` : !!props.isInput ? '100%' : '40px'};
    width: 100%;
    background: ${props =>
        !!props.isInput
            ? props.theme.Colors.white
            : props.theme.Colors.whiteFive};
    color: ${props => props.theme.Colors.black};
    ${props =>
        !props.isInput &&
        `
        box-shadow: inset 0 0 0 ${props.error ? '2px' : '-1px'}
            ${props.theme.Colors.coral};
        ${props.border && `border: 1px solid ${props.theme.Colors.cloudyBlue}`};
        -webkit-appearance: none;
    `}

    &.is-disabled {
        color: ${props => props.theme.Colors.cloudyBlue};
        pointer-events: none;
    }
`;

export const SelectContainer = styled(OutsideActionBox)`
    position: relative;
    width: 100%;
`;

export const IconHolder = styled.div<{
    disabled?: boolean;
}>`
    width: 8px;
    height: 100%;
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

export const StyledButtonContainer = styled.div`
    margin-top: 32px;
`;

export const LabelsContainer = styled(motion.div)<{
    displayOver?: boolean;
    topMargin: number;
    left?: string;
    isInput?: boolean;
}>`
    padding: 24px;
    min-width: 208px;
    position: absolute;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    left: ${props =>
        props.displayOver ? '16px' : !!props.left ? props.left : '0'};
    right: ${props => (props.displayOver ? '16px' : '0')};
    ${props =>
        !!props.isInput
            ? 'bottom: 55px;'
            : `top: ${props.displayOver ? `-${props.topMargin}px` : '8px'}`};
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
`;

export const StyledLabelsList = styled.div``;

export const ScrollableLabelContainer = styled.div`
    max-height: 312px;
    overflow: auto;
    overflow-y: scroll;
`;

export const TitleContainer = styled.div`
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
`;

export const SelectedPanel = styled.div`
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
`;

export const SelectedLabelsStyle = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const ClearSelectedLabels = styled.div<{ disabled?: boolean }>`
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.steelGrey
            : props.theme.Colors.darkSkyBlue};
    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
    ${props => props.disabled && 'pointer-events: none'}
`;

export const StyledLabelListItemContainer = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

export const ChildLabelsContainer = styled.div<{
    expanded: boolean;
}>`
    display: ${props => (props.expanded ? 'block' : 'none')};
    margin-left: 8px;
`;

export const LabelItemActions = styled.div<{ menuOpen?: boolean }>`
    display: flex;
    user-select: none;
    align-items: center;
    height: 100%;
    width: 30px;
`;

export const LabelListItemNameContainer = styled.div`
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const LabelListItemLabel = styled.label`
    font-size: 13px;
    padding: 0 4px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    width: 100%;
`;

export const ArrowContainer = styled.div`
    width: 8px;
    margin-right: 4px;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

export const CollapseArrow = styled.div`
    height: 8px;
    display: flex;
`;

export const StyledTitleContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    flex: 1;
    &:active {
        opacity: 0.7;
    }
    ${props => props.disabled && 'pointer-events: none'}
`;

export const StyledLabelListItem = styled.div<{
    selected?: boolean;
}>`
    height: 32px;
    color: ${props =>
        props.selected
            ? props.theme.Colors.black
            : props.theme.Colors.steelGrey};
    border-radius: 4px;
    font-size: 13px;
    align-items: center;
    font-weight: 600;
    display: flex;
    ${LabelItemActions} {
        display: flex;
    }
    ${ArrowContainer} ${StyledTitleContainer} {
        color: ${props =>
            props.selected
                ? props.theme.Colors.black
                : props.theme.Colors.steelGrey};
    }
    &:hover,
    :hover ${ArrowContainer} ${StyledTitleContainer} {
        background: ${props => props.theme.Colors.whiteTwo};
        color: ${props => props.theme.Colors.black};
    }
`;

export const StyledEmptyLabels = styled.div`
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    align-items: center;
`;

export const StyledEmptyLabelsContent = styled.div`
    max-width: 140px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoLabelsIcon = styled.div`
    width: 30px;
    height: 40px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 16px;
`;

export const NoLabelsTitle = styled.div`
    font-weight: bold;
    font-size: 13px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

export const NoLabelsDescription = styled.div`
    font-size: 11px;
    color: ${props => props.theme.Colors.cloudyBlue};
    width: 100%;
    white-space: break-spaces;
`;
