import { FC } from 'react';
import styled from 'styled-components';

export const FiltersSelectedItems: FC = props => {
    const { children } = props;
    return <Root>{children}</Root>;
};

const Root = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;
