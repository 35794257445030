import { FC, memo } from 'react';

import { Divider } from './style';
import { TableDividerProps } from './type';

const TableDivider: FC<TableDividerProps> = memo(props => {
    const { height, hide, width} = props;

    return <Divider height={height} hide={hide} width={width} />;
});

export default TableDivider;
