import { useCallback, useMemo } from "react";
import { MonthSelectorProps } from "./types";
import MonthSelectorView from "./view";

function MonthSelector({
    selectedYear,
    selectedMonth,
    currentYear,
    isDisabled,
    onSelect
}: MonthSelectorProps) {
    const presentYear = useMemo(() => new Date().getFullYear(), []);
    const presentMonth = useMemo(() => new Date().getMonth(), []);

    const isDisabledMonth = useCallback((month: number) => {
        return currentYear > presentYear || (currentYear === presentYear && month > presentMonth) || isDisabled;
    }, [currentYear, isDisabled, presentMonth, presentYear]);

    const isSelectedMonth = useCallback((month: number) => {
        return month === selectedMonth && currentYear === selectedYear;
    }, [currentYear, selectedMonth, selectedYear]);

    const isCurrentMonth = useCallback((month: number) => {
        return month === presentMonth && currentYear === presentYear;
    }, [presentMonth, currentYear, presentYear]);

    const handleSelectMonth = useCallback((month: number) => {
        if (!isDisabledMonth(month)) {
            onSelect(month);
        }
    }, [isDisabledMonth, onSelect]);
    
    return (
        <MonthSelectorView
            isDisabledMonth={isDisabledMonth}
            isSelectedMonth={isSelectedMonth}
            isCurrentMonth={isCurrentMonth}
            onSelectMonth={handleSelectMonth}
        />
    );
}

export default MonthSelector;