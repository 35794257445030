import { isEmpty, get as lodashGet, chunk } from 'lodash';

/**
 * @function convertObjectToArrayOfValues
 * @param { Record<string, unknown> } object
 * @returns { unknown[] | null }
 */
export const convertObjectToArrayOfValues = (
    object: Record<string, unknown>
): unknown[] | null => {
    if (isEmpty(object)) {
        return null;
    }
    return Object.entries(object)?.map(item => item[1]);
};

/**
 * @function calculatePercentage
 * @param { number } totalItems
 * @param { number } currentIndex
 * @returns { number }
 */
export const calculatePercentage = (
    totalItems: number,
    currentIndex: number
): number => +((currentIndex * 100) / totalItems).toFixed(0);

/**
 * @description It creates chunks from input options
 * whether the input is offset it creates chunks of
 * offsets.
 * @function handleCreateChunks
 * @param { Record<string, any> } options
 * @param { string } fieldName
 * @returns { Record<string, any>[] | number[] | null }
 */
export const handleCreateChunks = (
    options: Record<string, any>,
    fieldName: string,
    itemPerChunk: number
) => {
    const totalSelectedItems = lodashGet(options, fieldName, null);

    if (Array.isArray(totalSelectedItems)) {
        return chunk(totalSelectedItems, itemPerChunk);
    } else if (typeof totalSelectedItems === 'number') {
        const offsetListArray = [0];

        for (let i = 1; i < totalSelectedItems / itemPerChunk; i++) {
            offsetListArray.push(offsetListArray[i - 1] + itemPerChunk);
        }

        return offsetListArray;
    }

    return null;
};

