import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const TeamLeaderSummary = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 40 40"
                className={className}
                width="40"
                height="40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path d="M3.323 14.563v16.873h33.354V14.583" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="m19.159 10.402-7.247 8.218-7.046-4.562M35.148 14.05l-7.06 4.57-7.24-8.21" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <mask id="uup75c9s0a" mask-type="luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <path d="M0 0h40v40H0V0z" fill="#fff"/>
                </mask>
                <g mask="url(#uup75c9s0a)">
                    <path d="M38.483 36.127H1.517v-4.69h36.966v4.69zM23.258 7.16c0 1.815-1.459 3.287-3.258 3.287-1.8 0-3.258-1.472-3.258-3.287 0-1.816 1.459-3.287 3.258-3.287 1.8 0 3.258 1.471 3.258 3.287zM39.219 11.912c0 1.416-1.138 2.565-2.542 2.565s-2.542-1.149-2.542-2.565 1.138-2.564 2.542-2.564 2.542 1.148 2.542 2.564zM5.865 11.912c0 1.416-1.138 2.565-2.542 2.565S.78 13.328.78 11.912 1.92 9.348 3.323 9.348s2.542 1.148 2.542 2.564z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M32.188 25.395a2.194 2.194 0 1 1-4.389 0 2.194 2.194 0 0 1 4.389 0zM24.131 23.7a4.131 4.131 0 1 1-8.262 0 4.131 4.131 0 0 1 8.262 0zM12.2 25.395a2.194 2.194 0 1 1-4.388 0 2.194 2.194 0 0 1 4.389 0z" fill="#4AA9E2"/>
                </g>
            </svg>
        );
    }
);

export default TeamLeaderSummary;