import { useMemo } from "react";
import { SubmissionV2 } from "../../../constants/interfaces/Reviews";
import { UserProfile } from "../../../constants/interfaces/User";
import { useLoggedInUserProfileState } from "../../../features/users/store/states";
import { hasPermission, NEW_PERMISSIONS } from "../../../features/users/tools";
import { getBulkActionAvailabilityItem, getBulkActionsAvailability } from "../../../tools/bulkActions";

export enum SubmissionActions {
    ARCHIVE,
    RESTORE,
}

function createSubmissionActionsHelper(loggedInUser: UserProfile | undefined) {    
    const canArchive = (submission: SubmissionV2) =>
        submission.isVisible
            && hasPermission(loggedInUser, NEW_PERMISSIONS.ARCHIVE_SUBMISSION);
    
    const canRestore = (submission: SubmissionV2) =>
        !submission.isVisible
            && hasPermission(loggedInUser, NEW_PERMISSIONS.RESTORE_SUBMISSION);
    

    const getBulkActions = (submissions: SubmissionV2[] | undefined) =>
        getBulkActionsAvailability(
            submissions,
            [
                getBulkActionAvailabilityItem(
                    SubmissionActions.ARCHIVE,
                    submissions,
                    canArchive
                ),
                getBulkActionAvailabilityItem(
                    SubmissionActions.RESTORE,
                    submissions,
                    canRestore
                ),
            ]
        );

    return {
        canArchive,
        canRestore,
        getBulkActions,
    };
}

export type SubmissionActionsHelper = ReturnType<typeof createSubmissionActionsHelper>;

export function useSubmissionActionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createSubmissionActionsHelper(loggedInUser),
        [loggedInUser]
    );
}