import React from 'react';
import styled from 'styled-components';
import ROUTES from '../../../../routes/routes';
import { useHistory } from 'react-router';
import { Button } from '../../../../ui/components/Button';
import { Copyright } from '../../../../ui/components/Copyright';

const RightContentContainer = styled.div`
    flex: 0 0 50%;
    background: ${props => props.theme.Colors.white};
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
    z-index: 2;
`;

const Header = styled.div`
    height: 104px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 64px;
`;

const Body = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    width: 208px;
    padding: 16px;
`;

const Title = styled.div`
    font-size: 40px;
    margin-bottom: 24px;
    color: ${props => props.theme.Colors.black};
    line-height: 1.2;
    font-family: 'Merriweather';
`;

const Description = styled.div`
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 48px;
    color: ${props => props.theme.Colors.black};
    line-height: 1.5;
`;

const Footer = styled.div`
    height: 64px;
    font-size: 13px;
    font-weight: 300;
    padding: 0 24px;
    color: ${props => props.theme.Colors.black};
    text-align: right;
`;

const RightPanel = () => {
    const history = useHistory();

    const handleLogin = () => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    };

    return (
        <RightContentContainer>
            <Header>
                <Button
                    label="Log In"
                    action={handleLogin}
                    width="128px"
                    height="48px"
                />
            </Header>
            <Body>
                <Content>
                    <Title>You need Practis.</Title>
                    <Description>
                        Practis is a system for large teams to learn, retain,
                        and effectively articulate messaging on the front lines.
                    </Description>
                </Content>
            </Body>
            <Footer><Copyright /></Footer>
        </RightContentContainer>
    );
};

export default RightPanel;
