import { Action } from 'redux';

import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { Scope } from './scopes';
import { TeamBasic } from '../../../../../constants/interfaces/TeamBasic';

//region Types
type ReturnTypeTeamAction<T extends (...args: any) => any> = ReturnType<T> &
    ActionPayload;

type ActionPayload = { _scope?: Scope };

export type ActionTeam<T = any> = Action<T> & ActionPayload;

export type Actions =
    | ReturnTypeTeamAction<typeof selectAllAction>
    | ReturnTypeTeamAction<typeof deselectAllAction>
    | ReturnTypeTeamAction<typeof resetSelectedAction>
    | ReturnTypeTeamAction<typeof selectTeamsAction>
    | ReturnTypeTeamAction<typeof selectMultipleTeamsAction>
    | ReturnTypeTeamAction<typeof clearAction>
    | ReturnTypeTeamAction<typeof saveAction>
    | ReturnTypeTeamAction<typeof resetAction>;

//endregion

export const searchStartAction = (): ActionTeam<'searchStartTeams'> => ({
    type: 'searchStartTeams',
});

export const searchSuccessAction = (
    result: PaginationResult<TeamBasic>
): ActionTeam<'searchSuccessTeams'> & {
    result: PaginationResult<TeamBasic>;
} => ({
    type: 'searchSuccessTeams',
    result,
});

export const searchFailureAction = (
    message: string
): ActionTeam<'searchFailureTeams'> & { message: string } => ({
    type: 'searchFailureTeams',
    message,
});

export const selectAllAction = (
    allTeams: number[]
): ActionTeam<'selectAllTeams'> & { allTeams: number[] } => ({
    type: 'selectAllTeams',
    allTeams,
});

export const deselectAllAction = (): ActionTeam<'deselectAllTeams'> => ({
    type: 'deselectAllTeams',
});

export const resetSelectedAction = (): ActionTeam<'resetSelectedTeams'> => ({
    type: 'resetSelectedTeams',
});

export const selectTeamsAction = (
    id: number
): ActionTeam<'selectTeams'> & { id: number } => ({
    type: 'selectTeams',
    id,
});

export const selectMultipleTeamsAction = (
    ids: number[]
): ActionTeam<'selectMultipleTeams'> & { ids: number[] } => ({
    type: 'selectMultipleTeams',
    ids,
});

export const clearAction = (): ActionTeam<'clearTeams'> => ({
    type: 'clearTeams',
});

export const saveAction = (): ActionTeam<'saveTeams'> => ({
    type: 'saveTeams',
});

export const resetAction = (): ActionTeam<'resetTeams'> => ({
    type: 'resetTeams',
});
