import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Slider, withStyles } from '@material-ui/core';

export const CustomSlider = withStyles({
    root: {
        color: 'var(--ps-blue-main)',
        height: 5,
        padding: '5px 0',
    },
    rail: {
       height: 4,
       background: 'var(--ps-grey-2)',
       border: '1px solid var(--ps-grey-2)',
       borderRadius: 8,
    },
    track: {
        height: 5,
        borderRadius: 2,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: 'var(--ps-blue-main)',
        border: '1px solid currentColor',
        marginTop: -9,
        marginLeft: -11,
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.12)',
        '&:focus, &:hover, &$active': {
            boxShadow: 'var(--ps-grey-2) 0 2px 3px 1px',
        },
        color: 'var(--ps-grey-2)',
    },
})(Slider);

export const StyledDialogBlacker = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1200;
    background: rgba(35, 46, 58, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledDialog = styled(motion.div)`
    width: 355px;
    border-radius: 2px;
    box-shadow: 0 5px 20px -5px rgba(166, 174, 190, 0.5);
    background-color: ${props => props.theme.Colors.white};
    border-radius: 8px;
`;

export const SliderContainer = styled.div`
    margin: 0 20px 0 20px;
`;
export const SliderLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: var(--ps-grey-1);
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 800;
    padding: 0 20px;
    height: 54px;
`;
export const Footer = styled.div`
    height: 150px;
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 20px 0 20px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 0 20px 0;
    background-color: var(--ps-grey-4);
`;
