import React, { FC } from 'react';
import styled from 'styled-components';

export const FiltersPopupBody: FC = props => {
    const { children } = props;
    return (
        <Root>
            {children}
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;
