import { useContext } from 'react';

import { WithScenariosContextProps, WithScenariosContext } from './context';

export const useWithScenariosContext = (): WithScenariosContextProps => {
    const context = useContext(WithScenariosContext);

    if (!context) {
        throw new Error(
            'Cannot find WithTeams context provider. Please make a sure that you are located on the children of WithTeamsProvider'
        );
    }
    return context;
};
