import styled from 'styled-components';
import { SingleTextLine } from '../../../ui/components/Skeleton/styles';

export const Container = styled.div`
    width: 100%;
    height: 40px;
    border-bottom: 1px solid var(--ps-grey-4);
    background: var(--ps-grey-3);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.div<{ isDisabled?: boolean }>`
    padding-left: 24px;
    font-size: 13px;
    line-height: 18px;
    color: ${props => props.isDisabled ? 'var(--ps-grey-1)' : 'var(--ps-black-main)'};
    font-weight: 800;
`;

export const TitleSkeleton = styled(SingleTextLine)`
    margin-left: 24px;
    background-color: var(--ps-grey-2);
`;

export const SubHeader = styled.div`
    font-size: 13px;
    font-weight: normal;
    color: var(--ps-grey-1);
    margin-right: 25px;
`;