import { isEmpty } from 'lodash';

import { EmptyState } from '../../../../ui/components/EmptyStates';
import RadioButton from '../../../../ui/components/RadioButton';
import { FilterSkeleton } from '../../../../ui/components/Skeleton';
import NoReportingPractisSets from '../../../../ui/icons/NoReportingPractisSets';
import NoSearchResult from '../../../../ui/icons/NoSearchResult';
import { FilterContainer } from '../../filter-container';
import { SearchableFilterHeaderKey } from '../../searchable-filter-header/store/types';
import {
    Container,
    EmptyStateContainer,
    SkeletonContainer
} from './styles';
import { PractisSetFilterViewProps } from './types';

function PractisSetFilterView(props: PractisSetFilterViewProps) {
    const {
        practisSetData,
        loading,
        isNoSearchResultState,
        selectedTeamId,
        selectedPractisSetId,
        isDisabled,
        onPractisSetSearch,
        handleSelectPractisSet,
    } = props;

    return (
        <FilterContainer
            headerProps={{
                storeKey: SearchableFilterHeaderKey.PRACTIS_SET,
                title: 'Practis Set',
                hasSearch: !isEmpty(practisSetData?.items),
                onSearch: onPractisSetSearch,
                isLoading: loading,
                isDisabled: isDisabled,
            }}
        >
            <Container>
                {loading ? (
                    <SkeletonContainer><FilterSkeleton /></SkeletonContainer>
                ) : isEmpty(practisSetData?.items) ? (
                    <EmptyStateContainer>
                        <EmptyState
                            fontSize={11}
                            icon={
                                isNoSearchResultState
                                    ? NoSearchResult
                                    : NoReportingPractisSets
                            }
                            iconStyle={{
                                width: 32,
                                height: 32,
                                marginBottom: 4,
                            }}
                            dataTest={'no-practis-sets'}
                        >
                            {isNoSearchResultState ? (
                                'No Search Results'
                            ) : selectedTeamId ? (
                                'No Practis Sets yet'
                            ) : (
                                <span data-test="select-team-to-see-practis-sets">
                                    Select the team to see Practis Sets
                                </span>
                            )}
                        </EmptyState>
                    </EmptyStateContainer>
                ) : (
                    practisSetData?.items.map(item => (
                        <RadioButton
                            key={item.practisSetId}
                            handleChange={() =>
                                handleSelectPractisSet(item.practisSetId)
                            }
                            checked={selectedPractisSetId === item.practisSetId}
                            size={12}
                            label={item.name}
                            dataTest="practis-set-filter-item"
                            disabled={isDisabled}
                        />
                    ))
                )}
            </Container>
        </FilterContainer>
    );
}

export default PractisSetFilterView;