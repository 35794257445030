import { Action } from 'redux';

export enum ACTIONS {
    SELECT_SINGLE_TEAM = 'SELECT_SINGLE_TEAM',
    DISABLE_SINGLE_TEAM_FILTER = 'DISABLE_SINGLE_TEAM_FILTER'
}

export type SelectSingleTeamAction = Action<ACTIONS.SELECT_SINGLE_TEAM> & {
    selectedTeamId: number | null;
};

export const selectSingleTeam = (
    selectedTeamId: number | null
): SelectSingleTeamAction => ({
    type: ACTIONS.SELECT_SINGLE_TEAM,
    selectedTeamId,
});

export type DisableSingleTeamFilterAction = Action<ACTIONS.DISABLE_SINGLE_TEAM_FILTER> & {
    isDisabled: boolean;
};

export const disableSingleTeamFilter = (
    isDisabled: boolean
): DisableSingleTeamFilterAction => ({
    type: ACTIONS.DISABLE_SINGLE_TEAM_FILTER,
    isDisabled,
});

export type SingleTeamFilterActions = SelectSingleTeamAction | DisableSingleTeamFilterAction;