import _ from 'lodash';
import { BuildUrlOptions, BuildApiUrlOptions, UrlBuilder } from './UrlBuilder';
import { setQueryObjectToUrl } from './UrlUtils';

interface DefaultUrlBuilderOptions {
    companyBaseUrl: string;
    adminBaseUrl: string;
    apiBaseUrl: string;
    apiV2BaseUrl: string;
}

export class DefaultUrlBuilder implements UrlBuilder {
    private readonly options: DefaultUrlBuilderOptions;

    constructor(options: DefaultUrlBuilderOptions) {
        this.options = options;
    }

    buildCompanyUrl(o: BuildUrlOptions): string {
        return buildUrl(this.options.companyBaseUrl, o);
    }

    buildAdminUrl(o: BuildUrlOptions): string {
        return buildUrl(this.options.adminBaseUrl, o);
    }

    buildApiUrl(o: BuildApiUrlOptions): string {
        const apiBaseUrl = o.isV2
            ? this.options.apiV2BaseUrl
            : this.options.apiBaseUrl;
        return buildUrl(apiBaseUrl, { ...o, absolute: true });
    }
}

function buildUrl(baseUrl: string, o: BuildUrlOptions): string {
    var path = o.path || '';
    if (path && o.params) {
        for (var name in o.params) {
            path = replaceParam(path, name, o.params[name]);
        }
    }
    const result = !!o.absolute
        ? joinSegments([baseUrl, path])
        : joinSegments([path]);
    return o.query ? setQueryObjectToUrl(result, o.query) : result;
}

function replaceParam(path: string, param: string, value: string): string {
    return _.replace(path, `:${param}`, value);
}

function joinSegments(segments: string[]): string {
    var result = segments
        .filter(s => !!s)
        .map(s => _.trim(s, '/'))
        .join('/');
    return result + '/';
}
