import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const FullScreenIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 16 16"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M0 11.296l1.434-.002v2.354c0 .506.396.933.918 1.029l.114.015.124.006 3.057-.001V16H2.59c-1.308 0-2.398-.883-2.568-2.046l-.016-.153L0 13.648v-2.352zm14.566 2.352v-2.354l1.434.002v2.352c0 1.187-.972 2.178-2.252 2.332l-.169.015-.168.005h-3.058v-1.303h3.058c.557 0 1.027-.36 1.132-.834l.017-.103.006-.112zM5.647 0v1.324H2.59c-.557 0-1.028.366-1.133.848l-.017.105-.006.114v3.255L0 5.647V2.391C0 1.184.972.177 2.252.021l.169-.016L2.59 0h3.057zm7.764 0c1.307 0 2.397.897 2.567 2.08l.016.155.006.156v3.256l-1.434-.001V2.39c0-.514-.395-.949-.918-1.046l-.114-.015-.123-.006h-3.058V0h3.058z"
                />
            </svg>
        );
    }
);

export default FullScreenIcon;
