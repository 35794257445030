import { pickBy } from 'lodash';

export const isEmpty = (obj: any) => {
    if (!obj) {
        return false;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * @description check if all object values are empty
 * @function isAllObjectValuesEmpty
 * @param { Record<string, unknown> } object
 * @returns { boolean }
 */
export const isAllObjectValuesEmpty = (
    object: Record<string, unknown>
): boolean => {
    return Object.keys(object as any).every(function (item) {
        return object[item] === '' || object[item] === null;
    });
};

/**
 * @function removeNullValues
 * @param { Record<string, unknown> } object 
 * @returns { Record<string, unknown> }
 */
export const removeNullValues = (
    object: Record<string, unknown>
): Record<string, unknown> => {
    return pickBy(object, value => value);
};
