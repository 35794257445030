import { CustomCalendar } from "../../../../ui/components/Calendar";
import { FilterContainer } from "../../filter-container";
import { ClearText, StyledCalendarContainer, StyledCalendarSection } from './styles';
import { TrashBin } from '../../../../ui/icons/TrashBin';
import { DateFilterViewProps } from './types';
import dayjs from "dayjs";

function DateFilterView({
    dateRange,
    handleSelectDate,
    isDisabled,
    resetDate
}: DateFilterViewProps) {
    const subHeader = dateRange?.start && dateRange?.end ? `${dayjs(new Date(dateRange.start)).format('MM/DD/YY')} - ${dayjs(new Date(dateRange.end)).format('MM/DD/YY')}` : 'MM/DD/YY – MM/DD/YY';
    
    return <FilterContainer
        headerProps={{
            title: 'Date Range',
            subHeader
        }}>
        <StyledCalendarContainer>
            <StyledCalendarSection>
                <CustomCalendar
                    value={dateRange || { start: null, end: null }}
                    handleChange={handleSelectDate}
                    selectRange={true}
                    restrictPast={false}
                    restrictFuture={true}
                />
            </StyledCalendarSection>
            <ClearText
                onClick={(e) => {
                    if (isDisabled) return;
                    e.stopPropagation();
                    resetDate();
                }}
                isDisabled={isDisabled}
                data-test="date-range-filter-clear"
            >
                <TrashBin />Clear
            </ClearText>
        </StyledCalendarContainer>
    </FilterContainer>
}

export default DateFilterView;