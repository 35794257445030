import { FC, useCallback, useEffect, useState } from 'react';
import { kebabCase } from 'lodash';

import { ActionItem } from '../../../ui/components/ActionButton';
import {
    useCreateLabelService,
    useDeleteLabelService,
    useExpandLabelFieldByIdService,
    useSaveUserFiltersService,
    useSelectLabelFilterActionService,
    useSetEditingLabelActionService,
    useSetLabelFilterActionService,
    useToggleCollapseLabelFieldService,
    useUpdateLabelService,
} from '../store/services';
import { Label } from '../../../constants/interfaces/Label';
import EditLabelActions from './EditLabelActions';
import { useFocus } from '../../../helpers/hooks/useFocus';
import { useLabelsConfigState, useLabelsState } from '../store/states';
import AddNewLabelInput from './AddNewLabelInput';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import TriangleDown from '../../../ui/icons/TriangleDown';
import TriangleRight from '../../../ui/icons/TriangleRight';
import { Variables } from '../../../theme/variables';
import LabelInputWarning from './LabelInputWarning';
import { enterPressed } from '../../../helpers/functions/key-down-helpers';
import { listToTree } from '../../../helpers/functions/list-to-tree';
import { useHandleSelectLabels } from '../tools';
import { CheckPermission } from '../../permissions';
import { usePermissionsState } from '../../permissions/store/state';
import LabelListActions from './LabelListActions';
import {
    AddLabelSection,
    ArrowContainer,
    ChildLabelsContainer,
    CollapseArrow,
    EditLabelActionsContainer,
    LabelItemActions,
    LabelListItemInput,
    LabelListItemInputContainer,
    LabelNameContainer,
    NoPermissionPlaceHolder,
    StyledCheckbox,
    StyledLabelListItem,
    StyledLabelListItemContainer,
} from './styles';
import { LabelListItemProps } from './types';
import { useShowConfirmModalDialog } from '../../../ui/components/ModalDialogs/store/actions';

export const LabelListItem: FC<LabelListItemProps> = ({
    label,
    allLabels,
    searchResults,
    openActions,
    toggleOpenActions,
    deleteLabel,
    editingLabels,
    setEditingLabel,
    createLabel,
    updateLabel,
    toggleCollapse,
    labelsCollapseState,
    setLabelFilters,
    selectedLabels,
    saveUserFilters,
    expandLabelById,
    disableLabels,
}) => {
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const [savingLabels, setSavingLabels] = useState(false);
    const [labelName, setLabelName] = useState(label.name);
    const [newLabelName, setNewLabelName] = useState('');

    const isLabelSelected = selectedLabels.includes(label.id);
    const isLabelPartiallySelected = selectedLabels.includes(-label.id);

    const editMode =
        !!editingLabels && editingLabels.id === label.id
            ? editingLabels.mode
            : 'view';

    const isLabelListActionDisabled = editingLabels?.mode === 'edit';       
    const isExpanded = labelsCollapseState.includes(label.id);

    const [inputRef, setFocus] = useFocus();

    const hasChildren = (label?: Label) => {
        return label && label.children && label.children.length > 0;
    };

    const checkForNotUniqueLabelName = (
        labelName: string,
        labels?: Label,
        labelId?: number
    ) => {
        if (!labels || !labels.children) return false;
        const children = labels.children;
        const filteredLabels = children.filter(
            (label: Label) => label.name === labelName && label.id !== labelId
        );
        return filteredLabels.length > 0;
    };

    const childLabelNotUnique = checkForNotUniqueLabelName(newLabelName, label);
    const addNewLabelDisabled = childLabelNotUnique || newLabelName.length < 1;

    const editLabelNotUnique = checkForNotUniqueLabelName(
        labelName,
        allLabels &&
            allLabels.filter(parent => parent.id === label.parentId)[0],
        label.id
    );
    const editLabelDisabled = editLabelNotUnique || labelName.length < 1;

    const handleSelectLabels = useHandleSelectLabels();

    const handleLabelSelection = () => {
        const labelsTree = listToTree(allLabels);
        setLabelFilters(handleSelectLabels(label, selectedLabels, labelsTree));
        setSavingLabels(true);
    };

    const permissions = usePermissionsState();
    useEffect(() => {
        if (
            savingLabels &&
            permissions.includes(NEW_PERMISSIONS.SAVE_SEARCH_FILTER)
        ) {
            saveUserFilters(selectedLabels);
            setSavingLabels(false);
        }
    }, [
        selectedLabels,
        savingLabels,
        setSavingLabels,
        saveUserFilters,
        permissions,
    ]);

    const updateLabelName = () => {
        updateLabel(label.id, {
            name: labelName,
            parentId: label.parentId,
        })
            .then(() => {
                setEditingLabel();
            })
            .catch(() => {
                setLabelName(label.name);
                setEditingLabel();
            });
    };

    const addSubLabelField = () => {
        toggleOpenActions(false);
        setEditingLabel({
            id: label.id,
            mode: 'new',
        });
        expandLabelById(label.id);
    };

    const createSubLabel = () => {
        createLabel({
            name: newLabelName,
            parentId: label.id,
        });
        setNewLabelName('');
        setEditingLabel();
    };


    const handleDeleteLabel = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            showConfirmationModalDialog({
                modalTitle: 'Are You Sure?',
                description:
                    'Deleting the Label will remove it from all related content items and users. It cannot be undone.',
                confirmButtonText: 'Proceed',
                cancelButtonText: 'Cancel',
                onConfirm: () => {
                    deleteLabel(label);
                    toggleOpenActions(false);
                },
            });
            toggleOpenActions(false);
        },
        [deleteLabel, label, showConfirmationModalDialog, toggleOpenActions]
    );    

    return (
        <>
            <StyledLabelListItemContainer>
                <StyledLabelListItem
                    title={labelName}
                    selected={
                        isLabelSelected ||
                        isLabelPartiallySelected ||
                        openActions
                    }
                    modified={editMode !== 'view'}
                    searched={searchResults && searchResults.includes(label.id)}
                >
                    <LabelNameContainer
                        onClick={() => toggleCollapse(label.id)}
                        disabled={!hasChildren(label)}
                        data-test={`sidebar-labels-item-${kebabCase(
                            labelName
                        )}`}
                    >
                        <ArrowContainer>
                            {hasChildren(label) && (
                                <CollapseArrow>
                                    {labelsCollapseState.includes(label.id) ? (
                                        <TriangleDown />
                                    ) : (
                                        <TriangleRight />
                                    )}
                                </CollapseArrow>
                            )}
                        </ArrowContainer>
                        <LabelListItemInputContainer>
                            <LabelListItemInput
                                ref={inputRef}
                                height={'auto'}
                                value={labelName}
                                handleChange={(e: any) =>
                                    setLabelName(e.target.value)
                                }
                                onKeyPress={e => {
                                    if (enterPressed(e) && !editLabelDisabled) {
                                        updateLabelName();
                                    }
                                }}
                                colorTheme="transparent"
                                maxLength={144}
                                readOnly={editMode !== 'edit'}
                            />
                        </LabelListItemInputContainer>
                    </LabelNameContainer>
                    <LabelItemActions menuOpen={openActions}>
                        <StyledCheckbox
                            checked={
                                isLabelSelected || isLabelPartiallySelected
                            }
                            partial={isLabelPartiallySelected}
                            handleChange={handleLabelSelection}
                            size={12}
                            disabled={disableLabels}
                            background={Variables.Colors.dark}
                            border={Variables.Colors.steelGrey}
                        />
                        <CheckPermission
                            permissions={[
                                NEW_PERMISSIONS.UPDATE_LABEL,
                                NEW_PERMISSIONS.CREATE_LABEL,
                                NEW_PERMISSIONS.DELETE_LABEL,
                            ]}
                            alternateContent={<NoPermissionPlaceHolder />}
                        >
                            <LabelListActions
                                isMenuListOpen={openActions}
                                handleOpen={() =>
                                    !isLabelListActionDisabled &&
                                    toggleOpenActions(true)
                                }
                                handleClose={() => toggleOpenActions(false)}
                                disabled={isLabelListActionDisabled}
                            >
                                {label.parentsCount < 4 && (
                                    <CheckPermission
                                        permissions={[
                                            NEW_PERMISSIONS.CREATE_LABEL,
                                        ]}
                                    >
                                        <ActionItem
                                            onClick={e => {
                                                e.stopPropagation();
                                                addSubLabelField();
                                            }}
                                        >
                                            Add a Sub Label
                                        </ActionItem>
                                    </CheckPermission>
                                )}
                                <CheckPermission
                                    permissions={[NEW_PERMISSIONS.UPDATE_LABEL]}
                                >
                                    <ActionItem
                                        onClick={e => {
                                            e.stopPropagation();
                                            toggleOpenActions(false);
                                            setEditingLabel({
                                                id: label.id,
                                                mode: 'edit',
                                            });
                                            setFocus();
                                        }}
                                    >
                                        Edit Name
                                    </ActionItem>
                                </CheckPermission>
                                <CheckPermission
                                    permissions={[NEW_PERMISSIONS.DELETE_LABEL]}
                                >
                                    <ActionItem
                                        destructive={true}
                                        onClick={handleDeleteLabel}
                                    >
                                        Delete Label
                                    </ActionItem>
                                </CheckPermission>
                            </LabelListActions>
                        </CheckPermission>
                    </LabelItemActions>
                </StyledLabelListItem>
                {editMode === 'edit' && (
                    <div>
                        {editLabelNotUnique && <LabelInputWarning />}
                        <EditLabelActionsContainer>
                            <EditLabelActions
                                onConfirm={updateLabelName}
                                confirmDisabled={editLabelDisabled}
                                onCancel={() => {
                                    setLabelName(label.name);
                                    setEditingLabel();
                                }}
                            />
                        </EditLabelActionsContainer>
                    </div>
                )}
                {editMode === 'new' && (
                    <AddLabelSection>
                        <AddNewLabelInput
                            name={newLabelName}
                            setName={setNewLabelName}
                            onKeyPress={e => {
                                if (enterPressed(e) && !addNewLabelDisabled) {
                                    createSubLabel();
                                }
                            }}
                        />
                        {childLabelNotUnique && <LabelInputWarning />}
                        <EditLabelActions
                            onConfirm={createSubLabel}
                            confirmDisabled={addNewLabelDisabled}
                            onCancel={() => {
                                setNewLabelName('');
                                setEditingLabel();
                            }}
                        />
                    </AddLabelSection>
                )}
            </StyledLabelListItemContainer>
            {hasChildren(label) && (
                <ChildLabelsContainer
                    expanded={isExpanded}
                >
                    {label.children!.map((childLabel: Label) => (
                        <LabelListItemContainer
                            key={childLabel.id}
                            label={childLabel}
                            labelsCollapseState={labelsCollapseState}
                            selectedLabels={selectedLabels}
                            siblingIds={
                                label.children &&
                                label.children.map(label => label.id)
                            }
                            disableLabels={disableLabels}
                        />
                    ))}
                </ChildLabelsContainer>
            )}
        </>
    );
};

const LabelListItemContainer: FC<{
    label: Label;
    labelsCollapseState: number[];
    selectedLabels: number[];
    siblingIds?: number[];
    disableLabels?: boolean;
}> = ({ label, labelsCollapseState, selectedLabels, disableLabels }) => {
    const [open, setOpen] = useState(false);
    const deleteLabel = useDeleteLabelService();

    const allLabels = useLabelsState();

    const labelsConfigState = useLabelsConfigState();
    const setEditingLabel = useSetEditingLabelActionService();
    const createLabel = useCreateLabelService();
    const updateLabel = useUpdateLabelService();

    const toggleCollapse = useToggleCollapseLabelFieldService();
    const expandLabelById = useExpandLabelFieldByIdService();
    const selectLabelFilter = useSelectLabelFilterActionService();
    const setLabelFilters = useSetLabelFilterActionService();
    const saveUserFilters = useSaveUserFiltersService();

    return (
        <LabelListItem
            label={label}
            searchResults={allLabels.searchResults}
            allLabels={allLabels.data && allLabels.data.items}
            openActions={open}
            deleteLabel={deleteLabel}
            toggleOpenActions={setOpen}
            expandLabelById={expandLabelById}
            editingLabels={labelsConfigState.editingLabels}
            setEditingLabel={setEditingLabel}
            createLabel={createLabel}
            updateLabel={updateLabel}
            toggleCollapse={toggleCollapse}
            labelsCollapseState={labelsCollapseState}
            selectLabelFilter={selectLabelFilter}
            setLabelFilters={setLabelFilters}
            saveUserFilters={saveUserFilters}
            selectedLabels={selectedLabels}
            disableLabels={disableLabels}
        />
    );
};

export default LabelListItemContainer;