import Storage from '../services/Storage';
import {
    getCurrentUrl,
    getQueryStringFromUrl,
    setCurrentUrl,
    setQueryParameterToUrl,
} from '../tools/url/UrlUtils';

export function configStorage() {
    const currentUrl = getCurrentUrl();
    const {t: token, id: userId, p: platform = 'develop'} = getQueryStringFromUrl(currentUrl);

    if (token) {
        Storage.set('token', token);
        Storage.set('userId', userId);
        Storage.set('apiPlatform', platform);

        setCurrentUrl(
            setQueryParameterToUrl(
                setQueryParameterToUrl(
                    setQueryParameterToUrl(currentUrl, 'p'),
                    'id'
                ),
                't'
            )
        );
    }

    return { isLoggedIn: !!Storage.get('token') };
}
