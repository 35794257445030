import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { capitalize, isEmpty, uniqBy } from 'lodash';

import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../ui/components/ModalDialogs/store/actions';
import { ChunkRequestActionInterface } from '../../../../services/ChunkRequestService/hooks/types';
import { useChunkRequestsService } from '../../../../services/ChunkRequestService/hooks';
import { GeneralBulkActionModalInterface } from '../../../../ui/components/ModalDialogs/types';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { GenerateChallengeData, LibraryScope } from './types';
import { convertPresentVerbToPastVerb } from '../../../../helpers/functions/strings';
import {
    ASSIGN_LABELS_TO_CHALLENGE_ACTION,
    ASSIGN_LINES_TO_CHALLENGE_ACTION,
    CREATE_CHALLENGE_ACTION,
    initialGenerateChallengeData,
} from './constants';
import {
    useAssignLinesToChallengeApi,
    useCreateChallengeApi,
} from '../../../../api';
import { ScriptLine } from '../../../../constants/interfaces/ScriptLine';
import { ChallengeLine } from '../../../../api/challenges/types';
import { useAssignLibraryEntityLabelsService } from './helpers';
import {
    CLIENT_SIDE_ERROR_MESSAGE,
    PRACTIS_SET_ALREADY_EXISTED_ERROR_MESSAGE,
} from '../../../../ui/components/ErrorMessages/constants';

/**
 * @description function to create/edit/duplicate a selected library entity (PractisSet. Challenge, Scenario).
 * Create new entity by selected entity's details at first then assign relations to the result.
 * @function useCreateEditDuplicateLibraryBulActionService
 * @param { LibraryScope } scope
 * @param { string } entityTitle
 * @param { Function | undefined } onSuccessCallback
 * @param { Function | undefined } onErrorCallback
 * @returns { CallableFunction }
 */
export function useCreateEditDuplicateLibraryBulActionService(
    scope: LibraryScope,
    entityTitle: string,
    onSuccessCallback?: (responses?: Record<string, unknown>) => void,
    onErrorCallback?: (
        error?: ErrorResult,
        completedResponses?: Record<string, unknown>
    ) => void
) {
    const actionList = useRef<ChunkRequestActionInterface<any>[]>([]);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const shouldShowModals = !isEmpty(actionList.current);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @param { ErrorResult | undefined } error
     * @param { Record<string, unknown> | undefined  } completedResponses
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(
        (error?: ErrorResult, completedResponses?: Record<string, unknown>) => {
            const isAlreadyExistedErrorMessage =
                error?.message.includes('already exists');

            if (shouldShowModals) {
                if (isAlreadyExistedErrorMessage) {
                    hideModalDialog();
                } else {
                    dispatch(
                        showModalDialog({
                            modalType: 'BULK_ACTION_FAILED_MODAL',
                            modalProps: {
                                modalTitle: `${capitalize(
                                    scope
                                )} ${entityTitle}`,
                                onClose: hideModalDialog,
                            } as GeneralBulkActionModalInterface,
                        })
                    );
                }
            }

            actionList.current = [];
            isRunning && setIsRunning(false);

            if (error?.message) {
                const message = isAlreadyExistedErrorMessage
                    ? CLIENT_SIDE_ERROR_MESSAGE[
                          PRACTIS_SET_ALREADY_EXISTED_ERROR_MESSAGE
                      ]
                    : error.message;

                showMessage(message, 'error');
            }

            onErrorCallback?.(error, completedResponses);
        },
        [
            dispatch,
            entityTitle,
            hideModalDialog,
            isRunning,
            onErrorCallback,
            scope,
            shouldShowModals,
            showMessage,
        ]
    );

    /**
     * @function handleSuccessServiceCallback
     * @param { Record<string, unknown> | undefined } responses
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(
        (responses?: Record<string, unknown>) => {
            Promise.resolve().then(() => {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);

                actionList.current = [];
                isRunning && setIsRunning(false);
                showMessage(
                    `${entityTitle} has been ${convertPresentVerbToPastVerb(
                        scope
                    )}`,
                    'success'
                );

                onSuccessCallback?.(responses);
            });
        },
        [
            entityTitle,
            hideModalDialog,
            isRunning,
            onSuccessCallback,
            scope,
            showMessage,
        ]
    );

    const { setIsStopped } = useChunkRequestsService(
        actionList.current,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopDuplicateLibraryBulkActionService
     * @returns { void }
     */
    const handleStopDuplicateLibraryBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            hideModalDialog();
            isRunning && setIsRunning(false);
            actionList.current = [];

            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, setIsStopped]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle: `${capitalize(scope)} ${entityTitle}`,
                            onStopBulkActionService:
                                handleStopDuplicateLibraryBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
        });
    }, [
        shouldShowModals,
        setIsStopped,
        dispatch,
        scope,
        entityTitle,
        handleStopDuplicateLibraryBulkActionService,
        hideModalDialog,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    /**
     * @description Call the service runner.
     * @function callback
     * @param { ChunkRequestActionInterface<any>[] } action
     * @returns { void }
     */
    return useCallback(
        (duplicateLibraryActionList: ChunkRequestActionInterface<any>[]) => {
            actionList.current = duplicateLibraryActionList;

            setIsRunning(true);
        },
        []
    );
}

/**
 * @description function to generate challenge from scenario.
 * @function useGenerateChallengeBulActionService
 * @param { Function | undefined } onSuccessCallback
 * @param { Function | undefined } onErrorCallback
 * @returns { CallableFunction }
 */
export function useGenerateChallengeBulActionService(
    onSuccessCallback?: (responses?: Record<string, unknown>) => void,
    onErrorCallback?: (
        error?: ErrorResult,
        completedResponses?: Record<string, unknown>
    ) => void
) {
    const generateChallengeData = useRef<GenerateChallengeData>(
        initialGenerateChallengeData
    );

    const { title, description, sourceScenarioId, lines, labelIds, tryLimit } =
        generateChallengeData.current;

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const createNewChallenge = useCreateChallengeApi();
    const assignLibraryLabels = useAssignLibraryEntityLabelsService();
    const assignLinesToChallenge = useAssignLinesToChallengeApi();

    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    let actionList: ChunkRequestActionInterface<any> = {
        actionName: CREATE_CHALLENGE_ACTION,
        actionFunction: createNewChallenge,
        actionFunctionOptions: {
            parameters: {
                challengeInfo: {
                    title,
                    description,
                    sourceScenarioId,
                    tryLimit,
                },
                itemPerChunk: 1,
            },
            fieldName: 'itemPerChunk',
        },
    };

    const childActionList: ChunkRequestActionInterface<any>[] = [];

    /**
     * @function handleAssignLinesToChallengeService
     * @param { { id: number} } entityData
     * @param { ScriptLine[] } currentLines
     * @returns { Promise<void> }
     */
    const handleAssignLinesToChallengeService = useCallback(
        async (entityData: { id: number }, currentLines: ScriptLine[]) => {
            const createdChallengeId = entityData?.id;

            if (createdChallengeId) {
                const createdChallengeLines = currentLines?.map(line => {
                    const {
                        audioId = 0,
                        duration = 0,
                        position = null,
                        text = '',
                        speaker = '',
                        keywords = [],
                    } = line || {};

                    return {
                        audioId,
                        duration,
                        position,
                        text,
                        speaker,
                        keywords,
                    } as ChallengeLine;
                });

                await assignLinesToChallenge(
                    createdChallengeId,
                    createdChallengeLines
                );
            }
        },
        [assignLinesToChallenge]
    );

    /**
     * @function handleAssignLinesToChallenge
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLinesToChallenge = useCallback(
        (
            currentLines: ScriptLine[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLinesToChallengeOptions = {
                parameters: {
                    entityData: [],
                    currentLines,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: ASSIGN_LINES_TO_CHALLENGE_ACTION,
                actionFunction: handleAssignLinesToChallengeService,
                actionFunctionOptions: assignLinesToChallengeOptions as any,
            });
        },
        [handleAssignLinesToChallengeService]
    );

    /**
     * @function handleAssignLabelsToChallenge
     * @param { number[] } labelIds
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLabelsToChallenge = useCallback(
        (
            labelIds: number[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLabelsToEntityServiceOptions = {
                parameters: {
                    labels: uniqBy(labelIds, id => id),
                    entityId: [],
                    entityName: 'challenge',
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityId',
            };

            childActionList.push({
                actionName: ASSIGN_LABELS_TO_CHALLENGE_ACTION,
                actionFunction: assignLibraryLabels,
                actionFunctionOptions:
                    assignLabelsToEntityServiceOptions as any,
            });
        },
        [assignLibraryLabels]
    );

    if (!isEmpty(lines)) {
        handleAssignLinesToChallenge(lines, childActionList);
    }

    if (!isEmpty(labelIds)) {
        handleAssignLabelsToChallenge(labelIds, childActionList);
    }

    if (!isEmpty(childActionList)) {
        actionList = { ...actionList, ...{ childActionList } };
    }

    const shouldShowModals = !isEmpty(generateChallengeData.current);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @param { ErrorResult | undefined } error
     * @param { Record<string, unknown> | undefined  } completedResponses
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(
        (error?: ErrorResult, completedResponses?: Record<string, unknown>) => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_FAILED_MODAL',
                        modalProps: {
                            modalTitle: `Generating Challenge`,
                            onClose: hideModalDialog,
                        } as GeneralBulkActionModalInterface,
                    })
                );
            }

            generateChallengeData.current = initialGenerateChallengeData;
            isRunning && setIsRunning(false);
            error?.message && showMessage(error.message, 'error');

            onErrorCallback?.(error, completedResponses);
        },
        [
            dispatch,
            hideModalDialog,
            isRunning,
            onErrorCallback,
            shouldShowModals,
            showMessage,
        ]
    );

    /**
     * @function handleSuccessServiceCallback
     * @param { Record<string, unknown> | undefined } responses
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(
        (responses?: Record<string, unknown>) => {
            Promise.resolve().then(() => {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);

                generateChallengeData.current = initialGenerateChallengeData;

                isRunning && setIsRunning(false);

                onSuccessCallback?.(responses);
            });
        },
        [hideModalDialog, isRunning, onSuccessCallback]
    );

    const { setIsStopped } = useChunkRequestsService(
        [actionList],
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopDuplicateLibraryBulkActionService
     * @returns { void }
     */
    const handleStopDuplicateLibraryBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            hideModalDialog();
            isRunning && setIsRunning(false);
            generateChallengeData.current = initialGenerateChallengeData;

            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, setIsStopped]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle: `Generating Challenge`,
                            onStopBulkActionService:
                                handleStopDuplicateLibraryBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
        });
    }, [
        shouldShowModals,
        setIsStopped,
        dispatch,
        handleStopDuplicateLibraryBulkActionService,
        hideModalDialog,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    /**
     * @description Call the service runner.
     * @function callback
     * @param { GenerateChallengeData } generateChallengeData
     * @returns { void }
     */
    return useCallback((data: GenerateChallengeData) => {
        generateChallengeData.current = data;

        setIsRunning(true);
    }, []);
}
