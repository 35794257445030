import { Reducer } from 'redux';

import {
    FETCH_TIMEZONES,
    FETCH_TIMEZONES_FAILURE,
    FETCH_TIMEZONES_SUCCESS,
    GlobalActions,
} from '../actions';
import { AppState } from './index';
import { TimeZoneInterface } from '../../constants/interfaces/general';

export interface GlobalReducerInterface {
    timeZones: {
        list: TimeZoneInterface[];
        isLoading: boolean;
        errors: string;
    };
}

const initialState: GlobalReducerInterface = {
    timeZones: {
        list: [],
        isLoading: true,
        errors: '',
    },
};

const globalReducers: Reducer<GlobalReducerInterface, GlobalActions> = (
    state = initialState,
    action
): GlobalReducerInterface => {
    switch (action.type) {
        case FETCH_TIMEZONES:
            return {
                timeZones: {
                    ...state.timeZones,
                    isLoading: true,
                },
            };
        case FETCH_TIMEZONES_SUCCESS:
            const newTimeZoneList = action.data.map((timeZone: any) => ({
                value: timeZone.id,
                name: timeZone.name,
            }));
            return {
                timeZones: {
                    list: newTimeZoneList,
                    errors: '',
                    isLoading: false,
                },
            };
        case FETCH_TIMEZONES_FAILURE:
            return {
                timeZones: {
                    list: [...state.timeZones.list],
                    isLoading: false,
                    errors: action.error,
                },
            };
        default:
            return state;
    }
};

export const getTimeZoneState = (state: AppState) => state.global.timeZones;

export default globalReducers;
