import { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarFeed = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <g clip-path="url(#3q8huv9lja)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.777 5.336 8 0 .223 5.336a.5.5 0 0 0-.124.715c.172.23.505.283.744.119l.224-.153V16h4.8V9.34c0-.283.239-.513.533-.513h3.2c.295 0 .534.23.534.513V16h4.8V6.017l.223.153c.24.164.572.111.744-.12a.5.5 0 0 0-.124-.714zm-1.91-.051L8 1.259 2.134 5.285v9.69H4.8V9.34c0-.85.717-1.538 1.6-1.538h3.2c.884 0 1.6.689 1.6 1.538v5.635h2.667v-9.69z"
                        fill="#6D7F8C"
                    />
                </g>
                <defs>
                    <clipPath id="3q8huv9lja">
                        <path fill="#fff" d="M0 0h16v16H0z" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default SidebarFeed;

