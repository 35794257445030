import AIParamTextbox from "../AIParamTextbox/controller"
import { AiParamsProps } from "./types"

function ProductAiParam({ handleChange, roleplayData, emptyRequiredProps, isEditMode, autoFocus, analyticsKey }: AiParamsProps) {
    return <AIParamTextbox title="Describe the product or service, highlighting its features, price, unique selling points" tooltip="Provide a concise description of the product or service, focusing on its key features, pricing, and unique selling points." isRequired={true} isEditMode={isEditMode}
        isInvalid={emptyRequiredProps?.includes('product')}
        analyticsKey={`${analyticsKey}_product`}
        placeholder="For example: Eco-friendly Lawn Care Package. Priced at $150 per month, this comprehensive service includes bi-weekly lawn mowing, seasonal fertilization, and organic weed control. Emphasizing sustainability and efficiency, this package offers long-term garden health with minimal environmental impact, designed to fit within a modest budget."
        onChange={(val) => handleChange('product', val)} value={roleplayData?.product} autoFocus={autoFocus} dataTest="product"></AIParamTextbox>
}

export default ProductAiParam