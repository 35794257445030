import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    AssignedLabelsIdsApiParams,
    AssignLabelsApiParams,
    CreateLabelApiParams,
    DeleteLabelApiParams,
    SearchLabelsApiParams,
    UpdateLabelApiParams,
} from './types';


/**
 * @function useCreateLabelApi
 * @returns { CreateLabelApiParams }
 */
export const useCreateLabelApi = (): CreateLabelApiParams => {
    const http = useHttp();
    return useCallback(params => http.post('labels', params), [http]);
};

/**
 * @function useSearchLabelsApi
 * @returns { SearchLabelsApiParams }
 */
export const useSearchLabelsApi = (): SearchLabelsApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams => http.post('labels/search', searchParams),
        [http]
    );
};

/**
 * @function useDeleteLabelApi
 * @returns { DeleteLabelApiParams }
 */
export const useDeleteLabelApi = (): DeleteLabelApiParams => {
    const http = useHttp();
    return useCallback(labelId => http.delete(`labels/${labelId}`), [http]);
};

/**
 * @function useUpdateLabelApi
 * @returns { UpdateLabelApiParams }
 */
export const useUpdateLabelApi = (): UpdateLabelApiParams => {
    const http = useHttp();
    return useCallback(
        (labelId, params) => http.put(`labels/${labelId}`, params),
        [http]
    );
};

/**
 * @function useAssignLabelsApi
 * @returns { AssignLabelsApiParams }
 */
export const useAssignLabelsApi = (): AssignLabelsApiParams => {
    const http = useHttp();
    return useCallback(
        (
            labels,
            entities,
            deleted,
            entityType,
            selectAll,
            search,
            assignedArea
        ) =>
            http.post(`labels/assign`, {
                labels,
                entities,
                entityType: entityType,
                deleted,
                selectAll,
                search,
                assignedArea,
            }),
        [http]
    );
};

/**
 * @function useAssignedLabelsIdsApi
 * @returns { AssignedLabelsIdsApiParams }
 */
export const useAssignedLabelsIdsApi = (): AssignedLabelsIdsApiParams => {
    const http = useHttp();
    return useCallback(
        (entityIds, assignedArea, entityType, search, selectAll) =>
            http.post(`labels/load-assigned`, {
                entityIds,
                assignedArea,
                entityType,
                search,
                selectAll,
            }),
        [http]
    );
};

