import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ProspectIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (<svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#k19nog47na)">
        <path d="M13.657 10.343a7.97 7.97 0 0 0-3.04-1.907 4.623 4.623 0 0 0 2.008-3.811A4.63 4.63 0 0 0 8 0a4.63 4.63 0 0 0-4.625 4.625c0 1.58.796 2.977 2.008 3.811a7.968 7.968 0 0 0-3.04 1.907A7.947 7.947 0 0 0 0 16h1.25a6.751 6.751 0 0 1 3.159-5.713l1.638 1.97 1.328-1.12V16h1.25v-4.877l1.365 1.131 1.614-1.959A6.751 6.751 0 0 1 14.75 16H16a7.948 7.948 0 0 0-2.343-5.657zm-7.454.147-.65-.781a6.704 6.704 0 0 1 2.113-.45L6.203 10.49zM4.625 4.625A3.379 3.379 0 0 1 8 1.25a3.379 3.379 0 0 1 3.375 3.375A3.379 3.379 0 0 1 8 8a3.379 3.379 0 0 1-3.375-3.375zm5.197 5.867-1.49-1.234a6.705 6.705 0 0 1 2.13.457l-.64.777z" fill="#6D7F8C"/>
    </g>
    <defs>
        <clipPath id="k19nog47na">
            <path fill="#fff" d="M0 0h16v16H0z"/>
        </clipPath>
    </defs>
</svg>);
    }
);

export default ProspectIcon;