import styled from 'styled-components';

export const BackgroundWrapper = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    background: var(--ps-graphite-1);
    overflow: auto;
    z-index: 1;
`;

export const BackgroundImage = styled.img`
    max-height: 90%;
    max-width: 100%;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
`;

export const PageContainer = styled.div`
    position: relative;
    z-index: 2;
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
`

export const LogoContainer = styled.div<{
    desktopMinWidth: number;
    heightToShowScroll: number
}>`
    height: 44px;

    @media(max-width:767px) {
        padding-top: 24px;
    }
    @media(min-width:768px) {
        padding-top: 56px;
    }
    @media(min-width: ${props => props.desktopMinWidth}px) {
        padding-top: 48px;
    }
    @media(max-height: ${props => props.heightToShowScroll}px) {
        padding-bottom: 20px;
    }
`

export const PageContent = styled.div<{
    desktopMinWidth: number;
    heightToShowScroll: number;
    contentWidth?: number;
}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    box-sizing: border-box;
    ${props => props.contentWidth && `width: ${props.contentWidth}px;`}
   
    @media(max-width:767px) {
        height: calc(100vh - 69px);
        padding-bottom: 69px;
    }
    @media(min-width: 768px) {
        height: calc(100vh - 101px);
        padding-bottom: 101px;
    }
    @media(min-width: ${props => props.desktopMinWidth}px) {
        height: calc(100vh - 93px);
        padding-bottom: 93px;
    }
    @media(max-height: ${props => props.heightToShowScroll}px) {
        height: auto!important;
        padding-bottom: 142px;
    }
`;

export const Footer = styled.div`
    position: absolute;
    bottom: 24px;
    display: flex;
`;

export const FooterLink = styled.a`
    font-size: 13px;
    color: var(--ps-grey-1);
    text-decoration: none;
`;

export const FooterLinkSeparator = styled.div`
    font-size: 13px;
    color: var(--ps-grey-1);
    padding: 0 3px;
`;