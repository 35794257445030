import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import {
    StyledResetContent,
    Header
} from './styles';

const TraineeLogin = () => {
    return (
        <LoginAuthWrapper contentWidth={510} hideLogo={true}>
            <Header>Reset Password</Header>
            <StyledResetContent>
                Password has been reset, Open mobile app to log in
            </StyledResetContent>
        </LoginAuthWrapper>
    );
};

export default TraineeLogin;
