import React, { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, MenuList, Popper } from '@material-ui/core';
import classNames from 'classnames';

import Dots from '../../icons/Dots';
import { ActionMenuHandler, Button, IconContainer, useStyles } from './styles';
import { ActionButtonProps } from './types';

const uuid = require('uuid/v1');

export const ActionButton: React.FC<ActionButtonProps> = ({
    children,
    marginTop,
    customWidth,
    customLeft,
    active,
    button,
    hideArrow = true,
    isHidden,
    menuItemsClassName,
    dataTest,
    placement,
    isDisabled
}) => {
    const classes = useStyles({
        customWidth,
        marginTop,
        customLeft
    });
    const menuListAnchorRef = useRef<HTMLDivElement>(null);
    const customRandomId = uuid();

    const [isMenuListOpen, setIsMenuListOpen] = useState<boolean>(false);
    const [arrowRef, setArrowRef] = useState<unknown>(null);

    /**
     * @function handleToggle
     * @param { React.MouseEvent<HTMLElement> } event
     * @returns { void }
     */
    const handleToggle = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setIsMenuListOpen(prevOpen => !prevOpen);
    };

    /**
     * @function handleClose
     * @param { React.MouseEvent<Document, MouseEvent> | undefined } event
     * @returns { void }
     */
    const handleClose = (
        event?: React.MouseEvent<Document, MouseEvent>
    ): void => {
        if (
            menuListAnchorRef.current &&
            menuListAnchorRef.current.contains(event?.target as HTMLElement)
        ) {
            return;
        }

        setIsMenuListOpen(false);
    };

    // to close the menu while scrolling outside
    useEffect(() => {
        const scrollableContainer = document.getElementById(
            'MainScrollableContainer'
        );

        scrollableContainer?.addEventListener('scroll', event => {
            handleClose();
        });

        return () => {
            scrollableContainer?.removeEventListener('scroll', event => {
                handleClose();
            });
        };
    }, []);

    return (
        <>
            <Button
                ref={menuListAnchorRef}
                id={`action-button-${customRandomId}`}
                aria-controls={
                    isMenuListOpen ? `action-menu-${customRandomId}` : undefined
                }
                aria-expanded={isMenuListOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => !isDisabled && handleToggle(e)}
                className="action-button-element"
                data-test={dataTest && `${dataTest}-button`}
            >
                <IconContainer>
                    {!isHidden && (
                        <ActionMenuHandler
                            open={!!active || isMenuListOpen}
                            hasCustomComponent={!!button}
                            className="dot-icon-container"
                        >
                            {button ? button : <Dots />}
                        </ActionMenuHandler>
                    )}
                </IconContainer>
            </Button>
            <Popper
                open={isMenuListOpen}
                anchorEl={menuListAnchorRef.current}
                className={classes.popper}
                modifiers={{
                    arrow: {
                        enabled: !hideArrow,
                        element: arrowRef,
                    },
                }}
                placement={placement ? placement : customLeft ? 'bottom-end': undefined}
            >
                {({ TransitionProps }) => (
                    <>
                        {!hideArrow ? (
                            <span className={classes.arrow} ref={setArrowRef} />
                        ) : null}
                        <Grow {...TransitionProps}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={isMenuListOpen}
                                    id={`action-menu-${customRandomId}`}
                                    aria-labelledby={`action-button-${customRandomId}`}
                                    data-test={`${dataTest}-menu`}
                                    className={classNames(
                                        classes.menuListClassName,
                                        menuItemsClassName
                                    )}
                                    onClick={(event: React.MouseEvent) => {
                                        event.stopPropagation();
                                        handleClose(event as any);
                                    }
                                    }
                                >
                                    {children}
                                </MenuList>
                            </ClickAwayListener>
                        </Grow>
                    </>
                )}
            </Popper>
        </>
    );
};
