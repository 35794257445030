import React, { FC } from 'react';
import { formatMMDDYY } from '../../../../../../helpers/functions/date-convert';
import styled from 'styled-components';
import { ProgressBar } from '../../../../../../ui/components/ProgressBar';
import { EnrollmentStatus, EnrollmentStatusEnum } from '../../../../../../constants/interfaces/Enrollments';
import { Variables } from '../../../../../../theme/variables';
import { statusesMap } from '../../../../../../constants/variables/general';


const ProgressBarContainer = styled.div`
    width: 100%;
`;

const InfoWrapper = styled.div<{calculatePercent?: number}>`
    display: flex;
    align-items: center;
    color: ${props => props.calculatePercent === 100  ? props.theme.Colors.darkGreen : 'auto'};
`;

const CompletedAtWrapper = styled.div`
   color: ${props => props.theme.Colors.darkGreen};
  
`;

const GreenDot = styled.div`
    background: ${props => props.theme.Colors.darkGreen};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 5px 0 6px;
`;

const GeneralProgressCol: FC<{
    count?: number;
    max?: number;
    passedScenarios?: number;
    totalScenarios?: number;
    status?: string | '';
    completedAt?: any,
    dataTest?: string,
}> = ({ count, max, status, completedAt, dataTest }) => {
    
    if (status === EnrollmentStatus.PENDING) {
        return <>{statusesMap[EnrollmentStatus.PENDING]?.title}</>;
    }
    
    if (!max || count === 0) {
        return (
            <ProgressBarContainer data-test={dataTest}>
                   <InfoWrapper>
                <span data-test={dataTest && `${dataTest}-count`}>0%</span>
            </InfoWrapper>
                <ProgressBar newProgressBar value={0} color={Variables.Colors.mainBlue}  />
            </ProgressBarContainer>
        );
    }

    return (
        <ProgressBarContainer>
            <InfoWrapper calculatePercent={count || 0} data-test={dataTest}>
                <span data-test={dataTest && `${dataTest}-count`}>{count || 0}%</span>
                {completedAt && <GreenDot></GreenDot>}
                <CompletedAtWrapper>
                    {formatMMDDYY(completedAt)}
                </CompletedAtWrapper>
            </InfoWrapper>
            <ProgressBar
                newProgressBar
                color={Variables.Colors.mainBlue}
                completedColor={Variables.Colors.topaz}
                value={count || 0}
                isFull={(count || 0) >= max}
                isCompleted={
                    status === EnrollmentStatusEnum.COMPLETED
                        ? true
                        : count === max
                }
            />
        </ProgressBarContainer>
    );
};

export default GeneralProgressCol;
