import { useCallback } from 'react';
import { CompanyStatus } from '../../constants/interfaces/Company';

import { useHttp } from '../../tools/http/Http';
import {
    ActiveDeActivateCompaniesApiParams,
    CreateCompanyApiParams,
    DownloadCompanyUsersReportApiParams,
    GetCompanyApiParams,
    GetCompanyUserStatsApiParams,
    SearchCompaniesApiParams,
    SetCompanyUsersLimitApiParams,
    SetCompanyLogoApiParams,
    DeleteCompanyLogoApiParams,
    SetCompanyOwnerApiParams,
    UpdateCompanyNameApiParams,
    GetCompanyVoiceSettingsApiParams,
    SetCompanyVoiceSettingsApiParams,
} from './types';

/**
 * @function useSearchCompaniesApi
 * @returns { SearchCompaniesApiParams }
 */
export const useSearchCompaniesApi = (): SearchCompaniesApiParams => {
    const http = useHttp();
    return useCallback(
        (searchParams, statuses) => {
            const params = {
                limit: searchParams?.limit ?? 2000,
                offset: searchParams?.offset ?? 0,
                query: searchParams?.searchTerm,
                sort: searchParams?.orderBy
                    ? `${searchParams.orderBy.field || 'name'}_${searchParams.orderBy.asc ? 'asc' : 'desc'}`
                    : 'name_asc',
                status: statuses?.join(',') || `${CompanyStatus.ACTIVE},${CompanyStatus.DELETED}`
            };
            return http.get('companies', params, undefined, true);
        },
        [http]
    );
};

/**
 * @function useSearchListCompaniesApi
 * @returns { SearchCompaniesApiParams }
 */
export const useSearchListCompaniesApi = (): SearchCompaniesApiParams => {
    const http = useHttp();
    return useCallback(
        (searchParams, statuses) => {
            const params = {
                limit: searchParams?.limit ?? 2000,
                offset: searchParams?.offset ?? 0,
                query: searchParams?.searchTerm,
                sort: searchParams?.orderBy
                    ? `${searchParams.orderBy.field || 'name'}_${searchParams.orderBy.asc ? 'asc' : 'desc'}`
                    : 'name_asc',
                status: statuses?.join(',') || `${CompanyStatus.ACTIVE},${CompanyStatus.DELETED}`
            };
            return http.get('companies/admin-search', params, undefined, true);
        },
        [http]
    );
};

/**
 * @function useGetCompanyApi
 * @returns { GetCompanyApiParams }
 */
export function useGetCompanyApi(): GetCompanyApiParams {
    const http = useHttp();
    return useCallback(
        companyId =>
            http.get(`companies/${companyId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useCreateCompanyApi
 * @returns { CreateCompanyApiParams }
 */
export function useCreateCompanyApi(): CreateCompanyApiParams {
    const http = useHttp();
    return useCallback(
        (name, subdomain) => http.post('companies/create-company', { name, subdomain }, true),
        [http]
    );
}

/**
 * @function useUpdateCompanyNameApi
 * @returns { UpdateCompanyNameApiParams }
 */
export function useUpdateCompanyNameApi(): UpdateCompanyNameApiParams {
    const http = useHttp();
    return useCallback(
        (companyId, name) => http.put(`companies/${companyId}`, { name }, true),
        [http]
    );
}

/**
 * @function useDownloadCompanyUsersReportApi
 * @returns { DownloadCompanyUsersReportApiParams }
 */
export function useDownloadCompanyUsersReportApi(): DownloadCompanyUsersReportApiParams {
    const http = useHttp();
    return useCallback(
        companyId =>
            http.post(`users/export-company-users`, {
                companyId,
            }),
        [http]
    );
}

/**
 * @function useActivateCompany
 * @returns { ActiveDeActivateCompaniesApiParams }
 */
export function useActivateCompanies(): ActiveDeActivateCompaniesApiParams {
    const http = useHttp();
    return useCallback(
        companyIds => http.put(`companies/active`, companyIds, true),
        [http]
    );
}

/**
 * @function useDeActivateCompanies
 * @returns { ActiveDeActivateCompaniesApiParams }
 */
export function useDeActivateCompanies(): ActiveDeActivateCompaniesApiParams {
    const http = useHttp();
    return useCallback(
        companyIds => http.put(`companies/archive`, companyIds, true),
        [http]
    );
}

/**
 * @function useGetCompanyUserStatsApi
 * @returns { GetCompanyUserStatsApiParams }
 */
export function useGetCompanyUserStatsApi(): GetCompanyUserStatsApiParams {
    const http = useHttp();
    return useCallback(
        companyId =>
            http.get(`companies/${companyId}/users-stats`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useSetCompanyUsersLimitApi
 * @returns { SetCompanyUsersLimitApiParams }
 */
export function useSetCompanyUsersLimitApi(): SetCompanyUsersLimitApiParams {
    const http = useHttp();
    return useCallback(
        (companyId, usersLimit) =>
            http.post(`companies/${companyId}/set-users-limit`, { usersLimit }, true),
        [http]
    );
}

/**
 * @function useSetCompanyOwnerApi
 * @returns { SetCompanyOwnerApiParams }
 */
export function useSetCompanyOwnerApi(): SetCompanyOwnerApiParams {
    const http = useHttp();
    return useCallback(
        (companyId, ownerId) =>
            http.post(`companies/${companyId}/set-owner`, { ownerId }, true),
        [http]
    );
}

/**
 * @function useSetCompanyLogoApi
 * @returns { SetCompanyLogoApiParams }
 */
export function useSetCompanyLogoApi(): SetCompanyLogoApiParams {
    const http = useHttp();
    return useCallback(
        (companyId, mediaId) =>
            http.post(`companies/${companyId}/update-logo`, { mediaId }, true),
        [http]
    );
}

/**
 * @function useDeleteCompanyLogoApi
 * @returns { DeleteCompanyLogoApiParams }
 */
export function useDeleteCompanyLogoApi(): DeleteCompanyLogoApiParams {
    const http = useHttp();
    return useCallback(
        (companyId) =>
            http.delete(`companies/${companyId}/delete-logo`, undefined, true),
        [http]
    );
}

/**
 * @function useGetCompanyVoiceSettingsApi
 * @returns { GetCompanyVoiceSettingsApiParams }
 */
export function useGetCompanyVoiceSettingsApi(): GetCompanyVoiceSettingsApiParams {
    const http = useHttp();
    return useCallback(
        (companyId, role) =>
            http.get('voiceSettings', { companyId, role }, undefined, true),
        [http]
    );
}

/**
 * @function useSetCompanyVoiceSettingsApi
 * @returns { SetCompanyVoiceSettingsApiParams }
 */
export function useSetCompanyVoiceSettingsApi(): SetCompanyVoiceSettingsApiParams {
    const http = useHttp();
    return useCallback(
        (settings) =>
            http.post('voiceSettings/update', settings, true),
        [http]
    );
}