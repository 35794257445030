import { useCallback } from 'react';
import { useHttp } from '../../tools/http/Http';
import {
    GenerateReportApiParams,
    GetReportAvailabilityApiParams,
    GetReportsApiParams
} from './types';

/**
 * @function useGetReports
 * @returns { GetReportsApiParams }
 */
export function useGetReportsApi(): GetReportsApiParams {
    const http = useHttp();
    return useCallback(
        () => http.get('/reports', undefined, undefined, true),
        [http]
    );
}

/**
 * @function useGenerateReportApi
 * @returns { GetReportsApiParams }
 */
export function useGenerateReportApi(): GenerateReportApiParams {
    const http = useHttp();
    return useCallback(
        (reportType, reportData) =>
            http.post(`/reports/${reportType}/request`, reportData, true),
        [http]
    );
}

/**
 * @function useGenerateReportApi
 * @returns { GetReportsApiParams }
 */
export function useGetReportAvailabilityApi(): GetReportAvailabilityApiParams {
    const http = useHttp();
    return useCallback(
        (reportType, reportData) =>
            http.post(`/reports/${reportType}/request/availability`, reportData, true),
        [http]
    );
}