import React, { ChangeEventHandler, FocusEventHandler, FC, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Textarea } from '../../../../ui/components/Textarea/Textarea';
import { Cross } from '../../../../ui/icons/Cross';
import { Check } from '@material-ui/icons';
import { Button } from '../../../../ui/components/Button';
import SendActive from '../../../../ui/icons/SendActive';
import SendIcon from '../../../../ui/icons/SendIcon';

const StyledFormikField = styled.div`
    margin-top: 10px;
`;

const WarningText = styled.div`
    display: inline;
    color: var(--ps-red-main);
`;

const StledTextareaContainer = styled.div<{
    isWarning?: boolean;
    width?: string;
    disabled?: boolean
}>`
    position: relative;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    width: ${props => props.width || 'auto'};
    min-height: 40px;
    padding: 8px 8px 8px 12px;
    border-radius: 4px;
    border: 1px solid ${props => props.disabled ? 'none' : (!props.isWarning ? 'var(--ps-grey-2)' : 'var(--ps-red-main)')};
    background: ${props => props.disabled ? 'var(--ps-grey-3)' : 'none'};

    .cross-button {
        width: 28px;
        height: 24px;
        padding: 0px;
        min-width: unset;
    }
`;

export const CrossContainer = styled.div`
    width: 10px;
    height: 16px
`;

export const BtnContainer = styled.div`
    margin-right: 8px
`;

export const CheckContainer = styled.div`
    svg {
        width: 16px;
        margin-top: 3px;
    }
`;

export const SendContainer = styled.div`
    height: 24px;

    .send-btn {
        color: var(--ps-blue-main);
    }

    .send-btn.active {
        cursor: pointer;
    }
`;

const StyledTextarea = styled(Textarea) <{
    fontSize?: string;
    fontWeight?: number;
    minHeight?: string;
    disabled?: boolean;
}>`
    min-height: ${props => props.minHeight};
    color: ${props => props.disabled  && 'var(--ps-grey-2)'};
    &:disabled {
        background: transparent !important;
    }
    &::placeholder {
        font-size: '15px';
        font-weight: 600;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }
`;

const StyledCounter = styled.div<{disabled?: boolean}>`
    text-align: left;
    color: var(--ps-grey-10);
    font-size: 11px;
    margin-left: 12px;

    visibility: ${props => props.disabled ? 'hidden' : 'visible'}
`;

export const TextInputWithButtons: FC<{
    value?: string;
    field?: any;
    form?: { touched: any; errors: any };
    name?: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    max?: number;
    width?: string;
    background?: string;
    color?: string;
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    readOnly?: boolean;
    padding?: string;
    fontSize?: string;
    minRows?: number;
    maxRows?: number;
    borderRadius?: string;
    placeholderFontWeight?: number;
    minHeight?: string;
    counterColor?: string;
    textAreaMinHeight?: string;
    dataTest?: string;
    className?: string;
    onClose?: () => void;
    onApply?: () => void;
    isRequired?: boolean
}> = ({
    field,
    value,
    disabled,
    max,
    autoFocus,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    placeholder,
    readOnly,
    minRows,
    placeholderFontWeight,
    textAreaMinHeight,
    dataTest,
    className,
    onClose,
    onApply,
    isRequired,
    maxRows,
    width
}) => {
        const TextAreaStyles = {
            flex: 1,
            lineHeight: 1.13,
            letterSpacing: '0.4px',
            fontSize: '15px',
        };

        const isLimitReached = value?.length === max;

        return (
            <StyledFormikField>
                <StledTextareaContainer
                    className={className}
                    isWarning={isLimitReached || isRequired}
                    data-test={`${dataTest}-container`}
                    width={width}
                    disabled={disabled}
                >
                    <StyledTextarea
                        value={value}
                        styles={TextAreaStyles}
                        {...field}
                        placeholder={placeholder}
                        max={max}
                        onChange={onChange}
                        onFocus={
                            (e) => {
                                let val = e.target.value;
                                e.target.value = '';
                                e.target.value = val;
                                onFocus?.(e);
                            }
                        }
                        onBlur={onBlur}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                        fontSize={'15px'}
                        minRows={minRows}
                        maxRows={maxRows || 1}
                        fontWeight={placeholderFontWeight}
                        minHeight={textAreaMinHeight}
                        dataTest={dataTest}
                        onKeyDown={onKeyDown}
                    />
                    {!!onClose ? <>
                        <BtnContainer>
                            <Button variant='inverse' className='cross-button' action={onClose} dataTest={`${dataTest}-close-icon`}>
                                <CrossContainer><Cross /></CrossContainer>
                            </Button>
                        </BtnContainer>
                        <Button action={onApply} variant="primary" className='cross-button' dataTest={`${dataTest}-apply-icon`} ><CheckContainer><Check /></CheckContainer></Button>
                    </> :
                        <>{
                            disabled || value === ''  ?
                                <SendContainer><SendIcon className='send-btn' dataTest={`${dataTest}-send-icon`} /></SendContainer> :
                                <SendContainer onClick={onApply}><SendActive className='send-btn active' dataTest={`${dataTest}-send-icon`} /></SendContainer>
                        }
                        </>
                    }
                </StledTextareaContainer>
                {max &&  (
                    <StyledCounter data-test={dataTest && `${dataTest}-counter`} disabled={disabled}>
                        {value ? value.length : '0'}/{max}
                        {isLimitReached &&
                            <>
                                <span data-test={`${dataTest}-text-dot`}>•</span>
                                <WarningText data-test={`${dataTest}-warming-text`}> Character limit reached</WarningText>
                            </>
                        }
                        {
                            isRequired &&
                            <>
                                <span data-test={`${dataTest}-text-dot`}>•</span>
                                <WarningText data-test={`${dataTest}-required-text`}> Title required</WarningText>
                            </>
                        }
                    </StyledCounter>
                )}
            </StyledFormikField>
        );
    };

export default TextInputWithButtons;
