import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
    createPlaylistAction,
    goNextAction,
    playAudioAction,
    resetPlaylistAction,
    startAudioRecordingAction,
    stopAudioAction,
    stopAudioRecordingAction,
} from './actions';

export const useCreatePlaylistService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: any) => {
            dispatch(createPlaylistAction(data));
        },
        [dispatch]
    );
};

export const usePlayAudioService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scriptLineId: number | string) => {
            dispatch(playAudioAction(scriptLineId));
        },
        [dispatch]
    );
};

export const useStopAudioService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scriptLineId: number | string) => {
            dispatch(stopAudioAction(scriptLineId));
        },
        [dispatch]
    );
};

export const useResetAudioService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetPlaylistAction());
    }, [dispatch]);
};

export const useStartAudioRecordingService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scriptLineId: number | string) => {
            dispatch(startAudioRecordingAction(scriptLineId));
        },
        [dispatch]
    );
};

export const useStopAudioRecordingService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(stopAudioRecordingAction());
    }, [dispatch]);
};

export const useGoNextService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(goNextAction());
    }, [dispatch]);
};
