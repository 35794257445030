import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../constants/variables/component-settings';
import { useFocus } from '../../../helpers/hooks/useFocus';
import { usePreviousData } from '../../../helpers/hooks/usePreviousData';
import { useFilterHeaderState } from './store/reducers';
import {
    useSetFilterHeaderSearchActive,
    useSetFilterHeaderSearchTerm
} from './store/services';
import { SearchableFilterHeaderProps } from './types';
import SearchableFilterHeaderView from './view';

function SearchableFilterHeader({
    storeKey,
    title,
    isLoading,
    hasSearch,
    isDisabled,
    disableSearch,
    onSearch,
}: SearchableFilterHeaderProps) {
    const { searchTerm, isSearchActive } = useFilterHeaderState()[storeKey] ?? {};
    const setSearchActive = useSetFilterHeaderSearchActive(storeKey);
    const setSearchTerm = useSetFilterHeaderSearchTerm(storeKey);
    const prevSearchTerm = usePreviousData(searchTerm);
    const [inputRef, setFocus] = useFocus();

    const handleShowSearch = useCallback(() => {
        if (hasSearch && !isDisabled && !disableSearch) {
            setSearchActive(true);
        }
    }, [hasSearch, isDisabled, disableSearch, setSearchActive]);

    const handleHideSearch = useCallback(() => {
        setSearchActive(false);
    }, [setSearchActive]);

    const handleClear = useCallback(() => {
        setSearchTerm('');
        setFocus();
    }, [setFocus, setSearchTerm]);

    const onSearchDebounced = useMemo(
        () => debounce(onSearch, SEARCH_INPUT_DEBOUNCE_DELAY),
        [onSearch]
    );

    useEffect(() => {
        if ((!prevSearchTerm && !searchTerm) || prevSearchTerm === searchTerm) return;
        onSearchDebounced(searchTerm!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return (
        <SearchableFilterHeaderView
            title={title}
            isLoading={isLoading}
            hasSearch={hasSearch}
            isDisabled={isDisabled}
            disableSearch={disableSearch}
            showSearchInput={isSearchActive ?? false}
            searchTerm={searchTerm ?? ''}
            onShowSearch={handleShowSearch}
            onHideSearch={handleHideSearch}
            onSearch={setSearchTerm}
            onClear={handleClear}
            inputRef={inputRef}
        />
    );
}

export default SearchableFilterHeader;