import styled from 'styled-components';
import { Input } from '../../../../../ui/components/input';
import Search from '../../../../../ui/icons/Search';

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    padding-right: 4px;
`;

export const InputWrapper = styled.div`
    flex: 1;
`;

export const StyledInput = styled(Input)<{ ref?: any }>`
    padding: 0;
`;

export const IconContainer = styled.div`
    width: 14px;
    height: 100%;
    margin-left: 24px;
    margin-right: 9px;
`;

export const SearchIcon = styled(Search)`
    color: var(--ps-grey-1);
`;
