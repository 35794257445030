import { Action } from 'redux';

export enum ACTIONS {
    SELECT_PRACTIS_SET = 'SELECT_PRACTIS_SET',
    DISABLE_PRACTIS_SET_FILTER = 'DISABLE_PRACTIS_SET_FILTER'
}

export type SelectPractisSetAction = Action<ACTIONS.SELECT_PRACTIS_SET> & {
    selectedPractisSetId: number | null;
};

export const selectPractisSet = (
    selectedPractisSetId: number | null
): SelectPractisSetAction => ({
    type: ACTIONS.SELECT_PRACTIS_SET,
    selectedPractisSetId,
});


export type DisablePractisSetFilterAction = Action<ACTIONS.DISABLE_PRACTIS_SET_FILTER> & {
    isDisabled: boolean;
};

export const disablePractisSetFilter = (
    isDisabled: boolean
): DisablePractisSetFilterAction => ({
    type: ACTIONS.DISABLE_PRACTIS_SET_FILTER,
    isDisabled,
});

export type PractisSetFilterActions = SelectPractisSetAction | DisablePractisSetFilterAction;