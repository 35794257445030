import { FC, useEffect, ReactNode, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { usePractisSetReportState } from '../../store/states';
import {
    usePractisSetReportService,
    useResetPractisSetReportService,
} from '../../store/services';
import {
    formatDateWithTimeZone,
    formatMMDDYY,
} from '../../../../helpers/functions/date-convert';
import ROUTES from '../../../../routes/routes';
import {
    EnrollmentProgress,
    EnrollmentStatus,
    EnrollmentDailyTraining,
    EnrollmentInterface,
} from '../../../../constants/interfaces/Enrollments';
import Completion from './components/Sections/Completion';
import DailyTimeSpent from './components/Sections/DailyTimeSpent';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { UserProfile } from '../../../../constants/interfaces/User';
import ReportsItems from './components/Sections/ReportItems/ReportsItems';
import { TableDivider } from '../../../../ui/components/table-wrapper/table-divider';
import {
    StyledTraineeDetailsContainer,
    GraphSection,
    GraphRow,
    StyledDetailsHeader,
    ProfileSection,
    StyledDetailsAvatar,
    StyledUserInfo,
    StyledEmail,
    StyledName,
    StyledAdditionalInfo,
    StyledPendingInvitationLabel,
    StyledPendingTitle,
    DueDateSubTitle,
    SubHeaderContainer,
    StatusContainer,
    DetailsStatusCompleted,
    DetailsStatusInProgress,
    DetailsStatusPending,
    WarningTitle,
    StyledOverdueFlag,
    StyledLoading,
    DueDateSkeletonContainer,
} from './styles';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { toFixedNumber } from '../../../../helpers/functions/math';
import { UserStatus } from '../../../users/store/costants';
import useHtmlPageTitle from '../../../../helpers/hooks/useHtmlPageTitle';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { Button } from '../../../../ui/components/Button';
import DialogWrapper from '../../../../ui/components/DialogWrapper/DialogWrapper';
import { NudgeUser } from '../../../../ui/components/table-wrapper/table/TableAssignOptionsMenu/components/NudgeUser';
import { useNudgeTraineesService } from '../../../users/store/services';
import { NudgeData } from '../../../../api/alert/types';
import { pushModal } from '../../../../tools/router';
import { History } from 'history';
import { Skeleton } from '@material-ui/lab';

export enum SubmissionsStatus {
    PENDING = 'PENDING',
    REVIEWED = 'REVIEWED',
    UPLOADING = 'UPLOADING',
    DELETED = 'DELETED',
}

const submissionsStatusNames = {
    [SubmissionsStatus.PENDING as string]: 'Submitted',
    [SubmissionsStatus.REVIEWED as string]: 'Reviewed',
    [SubmissionsStatus.UPLOADING as string]: 'Uploading',
    [SubmissionsStatus.DELETED as string]: 'Deleted',
};

export function submissionsStatusName(status?: string): string {
    return (status && submissionsStatusNames[status]) || status || '';
}

const PractisSetReport: FC<{
    profile?: UserProfile;
    enrollment?: EnrollmentInterface;
    progress?: ListResult<EnrollmentProgress>;
    dailyTraining?: EnrollmentDailyTraining[];
    params: { userId: string, enrollmentId: string };
    loading?: boolean;
    useGoBack: boolean;
    goBackUrl: string;
    onGetPractisSetReport: (enrollmentId: number) => void;
    onResetPractisSetReport: () => void;
    onNudgeTrainees(nudgeUserDate: NudgeData[], successCallback?: () => void): void;
}> = ({
    profile,
    enrollment,
    progress,
    dailyTraining,
    params,
    loading,
    useGoBack,
    goBackUrl,
    onGetPractisSetReport,
    onResetPractisSetReport,
    onNudgeTrainees,
}) => {
    const history = useHistory() as History<any>;
    const [showNudgeDialog, setShowNudgeDialog] = useState(false);
    const isPending = enrollment?.user.status === UserStatus.PENDING;

    useEffect(() => {
        if (params.enrollmentId) {
            onGetPractisSetReport!(+params.enrollmentId!);
        }

        return () => {
            onResetPractisSetReport();
        };
    }, [onGetPractisSetReport, onResetPractisSetReport, params.enrollmentId]);

    const redirectToViewSubmission = (submissionId?: string) => {
        if (!submissionId) return;

        history.push(
            ROUTES.SUBMISSION_SETTINGS.CHALLENGES.SINGLE.replace(
                ':submissionId',
                submissionId
            ),
            {
                useGoBack: true,
            }
        );
    };

    const redirectToViewScenario = (submissionId?: string) => {
        if (!submissionId) return;

        history.push(
            ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.SINGLE.replace(
                ':submissionId',
                submissionId
            ),
            {
                useGoBack: true,
            }
        );
    };

    const handleReviewReps = (submissionId?: string) => {
        if (!submissionId || !enrollment) return;
        
        pushModal(
            history,
            ROUTES.TRAINEE_DETAILS_REPS
                .replace(
                    ':userId',
                    enrollment.user.id.toString()
                )
                .replace(
                    ':enrollmentId',
                    enrollment.id.toString()
                ).replace(
                    ':scenarioId',
                    submissionId.toString()
                )
        );
    };

    /**
     * Returns Due Date status title
     * @returns ReactNode | string
     */
    const getDueDateTitle = (): ReactNode | string => {
        const { hasOverdue, dueDate, user } = enrollment ?? {};

        if (!dueDate) {
            return 'No Due Date';
        } else if (hasOverdue) {
            return (
                <WarningTitle>
                    {`${user!.firstName} ${user!.lastName}
                        missed the due date of ${formatDateWithTimeZone(
                            dueDate
                        )}`}
                    <StyledOverdueFlag />
                </WarningTitle>
            );
        } else {
            return `Due Date: ${formatDateWithTimeZone(dueDate)}`;
        }
    };

    const closeNudgeDialog = useCallback(() => {
        setShowNudgeDialog(false);
    }, []);

    const handleNudge = useCallback(
        (data: any) => {
            const { text, name: senderName } = data;
            if (enrollment?.user.id) {
                const nudgeUserData: NudgeData = {
                    type: 'enrollment',
                    enrollmentId: enrollment.id,
                    receiverId: enrollment.user.id,
                    text,
                    senderName,
                };
                onNudgeTrainees([nudgeUserData], closeNudgeDialog);
            }
        },
        [enrollment?.user.id, enrollment?.id, onNudgeTrainees, closeNudgeDialog]
    );

    return (
        <MainWrapper
            title="Practis Set Report"
            subTitle={enrollment?.practisSet?.name}
            subHeader={
                <SubHeaderContainer>
                    {enrollment ? (
                        <>
                            <StatusContainer>
                                {enrollment?.status ===
                                    EnrollmentStatus.PENDING && (
                                    <DetailsStatusPending data-test="practis-set-status">
                                        Not Started
                                    </DetailsStatusPending>
                                )}
                                {enrollment?.status ===
                                    EnrollmentStatus.ENROLLED && (
                                    <DetailsStatusInProgress data-test="practis-set-status">
                                        In Progress
                                    </DetailsStatusInProgress>
                                )}
                                {enrollment?.status ===
                                    EnrollmentStatus.COMPLETED && (
                                    <DetailsStatusCompleted data-test="practis-set-status">
                                        Completed on{' '}
                                        {formatMMDDYY(
                                            new Date(enrollment.completedAt)
                                        )}
                                    </DetailsStatusCompleted>
                                )}
                            </StatusContainer>
                            <DueDateSubTitle data-test="practis-set-due-date">
                                {getDueDateTitle()}
                            </DueDateSubTitle>
                        </>
                    ) : (
                        <DueDateSkeletonContainer>
                            <Skeleton
                                variant="text"
                                width={100}
                                height={24}
                                style={{
                                    background: 'var(--ps-grey-3)',
                                }}
                            />
                            <Skeleton
                                variant="text"
                                width={80}
                                height={24}
                                style={{
                                    background: 'var(--ps-grey-3)',
                                }}
                            />
                        </DueDateSkeletonContainer>
                    )}
                </SubHeaderContainer>
            }
            useGoBack={useGoBack}
            goBackUrl={goBackUrl}
            dataTest="practis-set-report"
            headerMinHeight="114px"
        >
            {loading || !enrollment
                ? <StyledLoading />
                : (
                    <StyledTraineeDetailsContainer>
                        <StyledDetailsHeader>
                            <ProfileSection>
                                <StyledDetailsAvatar>
                                    <AvatarPlaceholder
                                        profile={enrollment.user}
                                        iconWidth={17}
                                        iconHeight={20}
                                        bottomPosition={-6}
                                        noHint={true}
                                        dataTest="practis-set-user-avatar"
                                    />
                                </StyledDetailsAvatar>
                                <StyledUserInfo>
                                    <StyledName isFlex={isPending}>
                                        <span data-test="practis-set-user-name">
                                            {`${enrollment.user.firstName || ''} ${
                                                enrollment.user.lastName || ''
                                            }`}
                                            {profile && enrollment.user.id === profile.id && (
                                                <StyledAdditionalInfo>
                                                    {' '}(You)
                                                </StyledAdditionalInfo>
                                            )}
                                        </span>
                                        {isPending && (
                                            <StyledPendingInvitationLabel>
                                                <StyledPendingTitle data-test="practis-set-pending-user-label">
                                                    Pending Registration
                                                </StyledPendingTitle>
                                            </StyledPendingInvitationLabel>
                                        )}
                                    </StyledName>
                                    <StyledEmail data-test="practis-set-user-email">
                                        {enrollment.user.email}
                                    </StyledEmail>
                                </StyledUserInfo>
                            </ProfileSection>
                            {!isPending && (
                                <Button
                                    width="152px"
                                    height="40px"
                                    action={() => setShowNudgeDialog(true)}
                                    dataTest="nudge-button"
                                >
                                    Nudge
                                </Button>
                            )}
                        </StyledDetailsHeader>
                        <TableDivider />
                        <GraphSection>
                            <GraphRow>
                                <Completion
                                    enrollment={enrollment}
                                    createdAt={enrollment?.startedAt}
                                    completedAt={enrollment.completedAt}
                                />
                                <DailyTimeSpent
                                    data={dailyTraining!.map(rep => {
                                        return {
                                            date: rep.date,
                                            value: toFixedNumber(rep.timeSpent / 60),
                                        };
                                    })}
                                    totalTime={enrollment.trainingTime}
                                />
                            </GraphRow>
                        </GraphSection>
                        <ReportsItems
                            content={progress?.items}
                            redirectChallenge={redirectToViewSubmission}
                            redirectScenario={redirectToViewScenario}
                            handleReviewReps={handleReviewReps}
                        />
                    </StyledTraineeDetailsContainer>
                )
            }
            {showNudgeDialog && (
                <DialogWrapper
                    customization={{
                        width: 480,
                        padding: '49px 32px 32px 32px',
                    }}
                >
                    <NudgeUser
                        title={`Send a direct message to ${enrollment!.user.firstName} ${enrollment!.user.lastName}`}
                        fromUser={`${profile?.firstName} ${profile?.lastName}`}
                        onSend={handleNudge}
                        onClose={closeNudgeDialog}
                        practisSetName={enrollment!.practisSet?.name ?? ''}
                    />
                </DialogWrapper>
            )}
        </MainWrapper>
    );
};

export const PractisSetReportContainer = () => {
    const data = usePractisSetReportState();
    const params = useParams<{ userId: string; enrollmentId: string }>();
    const profile = useSelector(getProfileState);
    const onGetPractisSetReport = usePractisSetReportService();
    const resetPractisSetReport = useResetPractisSetReportService();
    const nudgeTrainees = useNudgeTraineesService();
    const history = useHistory();

    const locationState: any = history.location.state;

    useHtmlPageTitle('Practis Set Report', [
        `${data.enrollment?.user.firstName} ${data.enrollment?.user.lastName}`,
        data.enrollment?.practisSet?.name,
    ]);

    return (
        <PractisSetReport
            profile={profile}
            enrollment={data.enrollment}
            progress={data.progress}
            dailyTraining={data.dailyTraining}
            loading={data.loading}
            params={params}
            useGoBack={!!locationState?.useGoBack}
            goBackUrl={ROUTES.USER_PERFORMANCE.replace(':userId', params.userId)}
            onGetPractisSetReport={onGetPractisSetReport}
            onResetPractisSetReport={resetPractisSetReport}
            onNudgeTrainees={nudgeTrainees}
        />
    );
};

export default PractisSetReportContainer;