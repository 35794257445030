import InputPhone from '../../../../../ui/components/input/PhoneInput/PhoneInput';
import { Button } from '../../../../../ui/components/Button';
import { EnterMobileNumberViewProps } from './types';
import {
    Title,
    Description,
    MobileNumberContainer,
    SecondaryText,
    ButtonContainer
} from './styles';

function EnterMobileNumberView({ 
    mobileNumber,
    isSending,
    canSkip,
    onSendCode,
    onSkip,
    onMobileNumberChange
}: EnterMobileNumberViewProps) {
    return <>
        <Title data-test="add-mobile-number-title">Add Mobile Number</Title>
        <Description data-test="add-mobile-number-description">
            Adding a mobile number improves security and gives you more options to log in later.
        </Description>
        <MobileNumberContainer>
            <InputPhone
                value={mobileNumber}
                onChange={onMobileNumberChange}
                height='56px'
                fontSize='15px'
                autoFocus={true}
                className='grey-border-on-focus'
                label=''
                dataTest='mobile-number-input'
            />
        </MobileNumberContainer>
        <SecondaryText data-test="mobile-number-input-text">
            We will send an SMS to verify your mobile number. Carrier fees may apply.
        </SecondaryText>
        <ButtonContainer>
            <Button
                height='56px'
                width='100%'
                buttonWidth='100%'
                fontSize={15}
                disabled={!mobileNumber || mobileNumber.length < 3}
                loading={isSending}
                type='button'
                action={onSendCode}
                dataTest='send-code-button'
            >
                Send Code
            </Button>
        </ButtonContainer>
        {canSkip && (
            <ButtonContainer>
                <Button
                    height='56px'
                    width='100%'
                    buttonWidth='100%'
                    fontSize={15}
                    variant='inverse'
                    type='button'
                    action={onSkip}
                    dataTest='add-later-button'
                >
                    Add Later
                </Button>
            </ButtonContainer>
        )}
    </>
}

export default EnterMobileNumberView;