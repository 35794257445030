import { FC, ChangeEvent, CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../theme/variables';
import Checked from '../../icons/Checked';
import Dash from '../../icons/Dash';

const checkboxSize = 20;

const COLORS = {
    background: Variables.Colors.white,
    checkedBackground: Variables.Colors.darkSkyBlue,
    checkedColor: Variables.Colors.white,
    borderColor: Variables.Colors.iconGrayBg,
    partial: {
        checkedBackground: Variables.Colors.steelGrey,
    },
    disabled: {
        background: Variables.Colors.lightBlueGray,
        checkedBackground: Variables.Colors.iconGrayBg,
        checkedColor: Variables.Colors.white,
    },
};

const StyledWrapper = styled.label<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;
    ${props => props.disabled && 'pointer-events: none'}
`;

const StyledLabel = styled.div<{
    color?: string;
    weight?: string;
    size?: number;
}>`
    font-size: ${props => (!!props.size ? props.size : 13)}px;
    margin-left: 8px;
    color: ${props => (props.color ? props.color : Variables.Colors.labelGray)};
    font-weight: ${props => (props.weight ? props.weight : 'normal')};
`;

const SelectedIconContainer = styled.div<{ size: number; marginLeft?: string }>`
    width: ${props => props.size}px;
    max-width: 12px;
    display: flex;
    color: ${props => props.theme.Colors.white};
    ${props => !!props.marginLeft && `margin-left: ${props.marginLeft};`}
`;

const PartialIconContainer = styled.div`
    height: 1px;
    min-width: 4px;
    display: flex;
    color: ${props => props.theme.Colors.white};
`;

const HiddenCheckbox = styled.input`
    visibility: hidden;
    position: absolute;
`;

const StyledCheckbox = styled.div<{
    color?: string;
    background?: string;
    size: number;
    checked?: boolean;
    disabled?: boolean;
}>`
    cursor: pointer;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border: ${({ disabled, checked }) => (disabled && checked ? 0 : 1)}px solid
        ${props =>
            props.disabled
                ? props.theme.Colors.lightBlueGray
                : props.checked
                ? props.background
                : props.theme.Colors.cloudyBlue};
    border-radius: 2px;
    background: ${props => props.background};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
`;

export const Checkbox: FC<{
    checked: boolean;
    partial?: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    label?: ReactElement | string;
    color?: string;
    background?: string;
    border?: string;
    size?: number;
    fontColor?: string;
    fontWeight?: string;
    fontSize?: number;
    className?: string;
    labelStyles?: CSSProperties;
    checkedBackground?: string;
    disabledCheckedBackground?: string;
    checkboxMarginLeft?: string;
    dataTest?: string;
}> = ({
    checked,
    disabled,
    handleChange,
    label,
    background,
    border,
    size,
    partial,
    fontColor,
    fontWeight,
    fontSize,
    className,
    labelStyles,
    checkedBackground,
    disabledCheckedBackground,
    checkboxMarginLeft,
    dataTest,
}) => {
    return (
        <StyledWrapper className={className} disabled={disabled}>
            <HiddenCheckbox
                type="checkbox"
                checked={checked}
                onChange={e => {
                    handleChange(e);
                }}
                data-test={
                    dataTest
                    && `${dataTest}${partial ? '-partially-checked' : (checked ? '-checked' : '')}`
                }
            />
            {label && (
                <StyledLabel
                    color={fontColor}
                    weight={fontWeight}
                    size={fontSize}
                    style={labelStyles}
                    data-test={dataTest && `${dataTest}-label`}
                >
                    {label}
                </StyledLabel>
            )}
            <StyledCheckbox
                size={size ? size : checkboxSize}
                checked={checked}
                background={
                    checked
                        ? partial
                            ? border || COLORS.partial.checkedBackground
                            : disabled
                                ? (disabledCheckedBackground ?? COLORS.checkedBackground)
                                : (checkedBackground ?? COLORS.checkedBackground)
                        : background || COLORS.background
                }
                disabled={disabled}
                data-test={dataTest && `${dataTest}-view`}
            >
                {checked ? (
                    partial ? (
                        <PartialIconContainer>
                            <Dash />
                        </PartialIconContainer>
                    ) : (
                        <SelectedIconContainer
                            size={size ? size / 2 : 7}
                            marginLeft={checkboxMarginLeft}
                        >
                            <Checked />
                        </SelectedIconContainer>
                    )
                ) : (
                    ''
                )}
            </StyledCheckbox>
        </StyledWrapper>
    );
};

export default Checkbox;
