import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarRoleplays = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#y25b9jn7ba)" stroke="#6D7F8C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                <path d="M.469 2.797v8.11l2.577-1.84c.294-.21.647-.322 1.009-.322h5.668c.74 0 1.34-.6 1.34-1.34V1.455H1.81c-.74 0-1.34.6-1.34 1.341z"/>
                <path d="M12.938 5.093h1.253c.74 0 1.34.6 1.34 1.34v8.11l-2.577-1.838a1.737 1.737 0 0 0-1.008-.323H6.277c-.74 0-1.34-.6-1.34-1.341v-.42M8.787 4.012H3.37M8.787 6.2H3.37"/>
            </g>
            <defs>
                <clipPath id="y25b9jn7ba">
                    <path fill="#fff" d="M0 0h16v16H0z"/>
                </clipPath>
            </defs>
        </svg>
        );
    }
);

export default SidebarRoleplays;
