const existsInArray = (list: any[], id: number) => {
    return list.filter(existing => existing && existing.id === id).length > 0;
};

const buildFlatArrayParents = (
    item: any,
    combined: any[],
    wholeArray: any[]
) => {
    if (item.parent) {
        if (!existsInArray(wholeArray, item.parent.id)) {
            combined.push(item.parent);
        }
        buildFlatArrayParents(item.parent, combined, wholeArray);
    }
    return combined;
};

export const treeToList = (list?: any[]) => {
    if (!list) return [];
    let flatArray: any = [];
    for (let item of list) {
        if (!existsInArray(flatArray, item.id)) {
            flatArray.push(item);
        }

        if (item.parent) {
            flatArray = [
                ...flatArray,
                ...buildFlatArrayParents(item, [], flatArray),
            ];
        }
        if (item.children) {
            for (let child of item.children) {
                if (!existsInArray(flatArray, child.id)) {
                    flatArray.push(child);
                }
            }
        }
    }

    return flatArray;
};

// Alternative realization of ^^^ treeToList are missing nodes on 2+ level
export const treeToArray = (tree: any[], array: any[] = []): any[] => {
    if (!tree?.length) return [];
    for (let node of tree) {
        if (!existsInArray(array, node.id)) {
            array.push(node);
            if (node.parent) {
                array = treeToArray([node.parent], array);
            }
            if (node.children?.length) {
                array = treeToArray(node.children, array);
            }
        }
    }
    return array;
};
