import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import { CompaniesDropdownItem } from './CompaniesDropdownItem';
import { CompanyItem, practisCompanyItem } from './types';
import { DropDownList } from '../../../../ui/components/DropdownList';
import { PractisDropdownItem } from './PractisDropdownItem';
import { useCompaniesGlobalState } from '../../store/states';
import { useFetchActiveCompaniesService } from '../../store/services';
import { usePreviousData } from '../../../../helpers/hooks/usePreviousData';
import { isEmpty } from 'lodash';
import equal from 'deep-equal';

const StyledListCard = styled(DropDownList)`
    background: ${props => props.theme.Colors.white};
    right: -5px;
    margin-top: 65px;
    min-width: 200px;
`;

export const CompaniesDropdown: React.FC<{
    companies?: CompanyItem[];
    open: boolean;
    toggleOpen(open?: boolean): void;
    onChangeCompany(company: CompanyItem): void;
    practisCompanyItem?: any;
}> = ({ companies, open, toggleOpen, onChangeCompany, practisCompanyItem }) => {
    return (
        <StyledListCard
            open={open}
            toggleOpen={toggleOpen}
            width={'auto'}
            renderItem={
                <PractisDropdownItem
                    company={practisCompanyItem}
                    onClick={onChangeCompany}
                />
            }
        >
            {companies?.map((company: any) => (
                <CompaniesDropdownItem
                    key={'company_' + (company.id || '')}
                    company={company}
                    onClick={onChangeCompany}
                    dataTest={'company-' + (company.id || '')}
                />
            ))}
        </StyledListCard>
    );
};

const CompaniesDropdownContainer: FC<{
    open: boolean;
    updatedCompany?: CompanyInterface;
    toggleOpen(open?: boolean): void;
    onChangeCompany(company: CompanyItem): void;
}> = ({ updatedCompany, open, toggleOpen, onChangeCompany }) => {
    const fetchActiveCompanies = useFetchActiveCompaniesService();
    const { activeCompanies, isActiveCompaniesLoading } = useCompaniesGlobalState();

    const prevUpdatedCompany = usePreviousData(updatedCompany);

    useEffect(() => {
        if (!activeCompanies?.items && !isActiveCompaniesLoading) {
            fetchActiveCompanies();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEmpty(prevUpdatedCompany) || equal(updatedCompany, prevUpdatedCompany)) return;
        fetchActiveCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedCompany]);

    return (
        <CompaniesDropdown
            companies={activeCompanies?.items}
            open={open}
            toggleOpen={toggleOpen}
            onChangeCompany={onChangeCompany}
            practisCompanyItem={practisCompanyItem}
        />
    );
};

export default CompaniesDropdownContainer;
