import { FC } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '../Button';

import {
    ButtonWrapper,
    StyledActions,
    StyledConfirmDialog,
    StyledConfirmDialogBlacker,
    StyledDescription,
    StyledTitle,
} from './styles';
import { DialogWrapperProps } from './types';

const DialogWrapper: FC<DialogWrapperProps> = ({
    modalTitle,
    description,
    dialogType = 'WARNING',
    confirmButtonText,
    cancelButtonText,
    onConfirm,
    onCancel,
    customization,
    children,
    onClickAway,
    isLoading,
    isConfirmDisabled,
    dataTest = 'dialog-wrapper',
}) => {
    const container = document.querySelector('body') as HTMLElement;

    const {
        width = 400,
        height = undefined,
        minHeight = undefined,
        padding = '32px 32px 32px 40px',
        titleMarginBottom = 14,
        descriptionMarginBottom = 30,
        shouldCenterButton = false,
    } = customization || {};

    return createPortal(
        <StyledConfirmDialogBlacker onClick={onClickAway}>
            <StyledConfirmDialog
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                width={width}
                height={height}
                minHeight={minHeight}
                padding={padding}
                data-test={dataTest}
            >
                {!!modalTitle && (
                    <StyledTitle
                        marginBottom={titleMarginBottom}
                        color={
                            dialogType === 'DANGER'
                                ? 'var(--ps-red-main)'
                                : undefined
                        }
                        data-test={dataTest && `${dataTest}-title`}
                    >
                        {modalTitle}
                    </StyledTitle>
                )}
                {!!description ? (
                    <StyledDescription
                        marginBottom={descriptionMarginBottom}
                        data-test={dataTest && `${dataTest}-description`}
                    >
                        {description}
                    </StyledDescription>
                ) : (
                    <div>{children}</div>
                )}
                {!!onCancel || !!onConfirm ? (
                    <StyledActions center={shouldCenterButton}>
                        {!!onCancel && (
                            <ButtonWrapper>
                                <Button
                                    height="40px"
                                    width="128px"
                                    action={onCancel}
                                    variant="inverse"
                                    color={
                                        dialogType === 'INFO'
                                            ? 'default'
                                            : 'warning'
                                    }
                                    dataTest={dataTest && `${dataTest}-cancel`}
                                >
                                    {cancelButtonText
                                        ? cancelButtonText
                                        : 'Cancel'}
                                </Button>
                            </ButtonWrapper>
                        )}
                        {!!onConfirm && (
                            <ButtonWrapper>
                                <Button
                                    height="40px"
                                    width="128px"
                                    action={onConfirm}
                                    color={
                                        dialogType === 'INFO'
                                            ? 'default'
                                            : 'warning'
                                    }
                                    loading={isLoading}
                                    disabled={isConfirmDisabled}
                                    dataTest={dataTest && `${dataTest}-confirm`}
                                >
                                    {confirmButtonText
                                        ? confirmButtonText
                                        : 'Yes, Delete'}
                                </Button>
                            </ButtonWrapper>
                        )}
                    </StyledActions>
                ) : null}
            </StyledConfirmDialog>
        </StyledConfirmDialogBlacker>,
        container
    );
};



export default DialogWrapper;