

import AIParamSelect from "../AIParamSelect";
import AIParamTextbox from "../AIParamTextbox/controller";
import { AiParamsProps } from "./types"

function ObjectiveAiParam({ handleChange, roleplayData, isEditMode, autoFocus, analyticsKey }: AiParamsProps) {
    return <>
        <AIParamTextbox title="Describe the objective you want the Salesperson to achieve in this situation"
         tooltip="Provide a clear and specific goal for the salesperson in this scenario. This could be closing a deal, scheduling a follow-up meeting, introducing a new product, or addressing a client's concern. The more precise your objective, the more tailored the AI-generated script will be."
            isRequired={false}
            isEditMode={isEditMode}
            placeholder="For example: Salesperson should convince the homeowner to subscribe to Eco-friendly Lawn Care Package. Emphasize its affordability, efficiency, and how it aligns with their desire for a beautiful garden with minimal environmental impact. Address their budget concerns by highlighting the long-term cost-effectiveness and proven results of this package, overcoming their resistance to new solutions."
            onChange={(val) => handleChange('objectiveRep', val)}
            value={roleplayData?.objectiveRep} autoFocus={autoFocus}
            analyticsKey={`${analyticsKey}_goal`}
            dataTest="rep-objective" />
        <AIParamTextbox title="List key recommendations, tactics, or nuances the Salesperson should consider"
            tooltip="Detail any specific strategies, communication styles, or persuasive tactics that the salesperson should use in this situation. Consider the customer's personality, preferences, and potential objections."
            isRequired={false}
            isEditMode={isEditMode}
            analyticsKey={`${analyticsKey}_nuances`}
            placeholder="For example: Highlight the time-saving and expertise aspects of the Eco-friendly Lawn Care Package, appealing to the homeowner's need for convenience. Use empathetic communication to acknowledge their budget concerns, suggesting the long-term savings of the package. Avoid aggressive sales tactics; instead, use testimonials or case studies to build trust and demonstrate the effectiveness and value of the service."
            onChange={(val) => handleChange('objectiveNuances', val)} value={roleplayData?.objectiveNuances} dataTest="nuances" />
        <AIParamSelect title="Choose a sales methodology"
            tooltip="Select a sales approach that aligns with the situation. Each methodology has unique principles and tactics that can be more effective depending on the customer's needs, decision-making style, and the nature of the product or service being offered."
            onChange={(val) => handleChange('salesMethod', val)}
            value={roleplayData?.salesMethod}
            dataTest="sales-method" 
            analyticsKey={`${analyticsKey}_method`}
            isEditMode={isEditMode}
            />
    </>
}

export default ObjectiveAiParam