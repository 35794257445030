import React, { FC } from 'react';
import styled from 'styled-components';
import secondsToMin from '../../../../../../../../helpers/functions/convert-time';

const StyledBuilderDuration = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const StyledValue = styled.div`
    font-size: 17px;
    font-weight: bold;
    color: ${props => props.theme.Colors.white};
    margin: 0;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const BuilderDuration: FC<{ duration?: number | null }> = ({ duration }) => {
    return (
        <StyledBuilderDuration>
            <StyledDescription data-test='practis-set-total-duration-text'>
                Total Duration
            </StyledDescription>
            <StyledValue data-test='practis-set-total-duration-value'>
                {secondsToMin(duration)}
            </StyledValue>
        </StyledBuilderDuration>
    );
};

export default BuilderDuration;
