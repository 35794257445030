import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Plus = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 12 12"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M684.835 62L684.835 56.827 690 56.827 690 55.173 684.835 55.173 684.835 50 683.179 50 683.179 55.173 678 55.173 678 56.827 683.179 56.827 683.179 62z"
                            transform="translate(-678 -50)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default Plus;
