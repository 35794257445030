import { FC, memo } from 'react';

import { Td, Text } from './styles';
import { TableStatusCellProps } from './types';

const TableStatusCell: FC<TableStatusCellProps> = ({
    width,
    colSpan,
    className,
    children,
    status,
    onClick,
    dataTest
}) => (
    <Td
        width={width}
        clickable={!!onClick}
        colSpan={colSpan}
        className={className}
        onClick={onClick}
    >
        <Text status={status} data-test={dataTest}> {children}</Text>
    </Td>
);

export default memo(TableStatusCell);

export const TableCellRoot = Td;

