import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { sendEmail } from '../../../helpers/functions/email-helpers';
import { getCompanyState } from '../../../pages/CompanySettings/store/reducers';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import SupportIcon from '../../icons/Support';
import { generateEmailBody } from './helpers';
import { IconHolder, Label, PractisSupportWrapper } from './styles';
import { PractisSupportProps } from './types';

export const PractisSetSupport: FC<PractisSupportProps> = ({ shrink }) => {
    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);

    const handleSendEmail = useCallback(() => {
        const body = generateEmailBody();
        const subject = `Request from ${profile?.firstName} ${
            profile?.lastName
        } at ${company.name ?? ''}`;

        if (body) {
            sendEmail(subject, body);
        }
    }, [company.name, profile]);

    return (
        <PractisSupportWrapper onClick={handleSendEmail} shrink={!!shrink}>
            <IconHolder title="Practis Support">
                <SupportIcon />
            </IconHolder>
            {!shrink ? <Label fontSize="15px">Practis Support</Label> : null}
        </PractisSupportWrapper>
    );
};