import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    practisSetEntityTypeName,
    PractisSetEntityTypes,
} from '../../../../../../tools';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../../../../../../constants/interfaces/ScriptLine';
import { PractisSetContent } from '../../../../../../../../../constants/interfaces/PractisSets';
import useWindowDimensions from '../../../../../../../../../helpers/hooks/useWindowDimensions';
import Close from '../../../../../../../../../ui/icons/Close';
import { Keywords } from '../../../../../../../../../constants/interfaces/Keywords';


const PreviewItemContainer = styled.div<{
    top: number;
    left: number;
    bottom?: number;
}>`
    padding: 15px 12px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    background: ${props => props.theme.Colors.white};
    width: 344px;
    max-height: 496px;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: ${props => props.left}px;
    top: ${props => (props.bottom ? 'unset' : `${props.top}px`)};
    bottom: ${props => (props.bottom ? `${props.bottom}px` : 'unset')};
    border-radius: 8px;

    .keyword-item {
        color: var(--ps-blue-main);
        font-weight: bold;
        display: inline;
    }
`;

const ScrollContainer = styled.div`
    padding: 15px 12px;
    height: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
`;

const PreviewHeader = styled.div`
    padding: 0 8px;
    margin-bottom: 18px;
`;

const IconContainer = styled.div`
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    color: ${props => props.theme.Colors.paleGrey};
    &:active {
        opacity: 0.7;
    }
`;

const Content = styled.div`
    flex: 1;
    overflow: auto;
`;

const Type = styled.div`
    font-size: 11px;
    font-weight: 600;
`;

const ChallengeType = styled(Type)`
    color: ${props => props.theme.Colors.coral};
`;

const ScenarioType = styled(Type)`
    color: ${props => props.theme.Colors.darkSkyBlue};
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.Colors.black};
    margin-bottom: 8px;
`;

const Description = styled.div`
    font-size: 13px;
    font-weight: normal;
    color: ${props => props.theme.Colors.black};
`;

const ScriptLineCard = styled.div`
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 16px 24px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    margin-bottom: 8px;
`;

const LineTitle = styled.div`
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 8px;
`;

const LineDescription = styled.div`
    font-size: 11px;
    font-weight: normal;
    color: ${props => props.theme.Colors.black};
`;

const LineCustomerTitle = styled(LineTitle)`
    color: ${props => props.theme.Colors.steelGrey};
`;

const LineRepTitle = styled(LineTitle)`
    color: ${props => props.theme.Colors.darkSkyBlue};
`;

const PreviewItem: FC<{
    item: PractisSetContent;
    position: { y: number; x: number };
    handleClose(): void;
}> = ({ item, position, handleClose }) => {
    const windowDimensions = useWindowDimensions();
    const cardRef = useRef<any>(null);
    const [cardPositions, setCardPositions] = useState<{
        top: number | undefined;
        left: number | undefined;
        bottom: number | undefined;
    }>({
        top: position.y - 150,
        left: position.x - 600,
        bottom: undefined,
    });

    const isScenario = item.type === PractisSetEntityTypes.SCENARIO;
    const content = isScenario ? item.scenario : item.challenge;

    const buildText = useCallback((line: ScriptLine) => {
        if (!line.keywords.length || !isScenario) {
            return line.text;
        }

        return line.keywords
            .reduce((str: any, keyword: Keywords) => {
                str[
                    keyword.start
                ] = `<span class="keyword-item">${str[keyword.start]}`;
                str[keyword.end] = `${str[keyword.end]}</span>`;
                return str;
            }, line.text.split(''))
            .join('');
    }, [isScenario]);

    useEffect(() => {
        if (cardRef.current) {
            const cardPosition = cardRef.current.getBoundingClientRect();
            const bottom =
                position.y + cardPosition.height > windowDimensions.height
                    ? 100
                    : undefined;
            const top = position.y - 150;
            const left = position.x - 600;

            setCardPositions({
                top: top,
                left: left,
                bottom: bottom,
            });
        }
    }, [position.x, position.y, windowDimensions.height]);

    if (!cardPositions.top || !cardPositions.left) {
        return <div />;
    }
    return (
        <PreviewItemContainer
            top={cardPositions.top}
            left={cardPositions.left}
            bottom={cardPositions.bottom}
            ref={cardRef}
        >
            <ScrollContainer>
                <IconContainer onClick={handleClose}>
                    <Close />
                </IconContainer>
                <PreviewHeader>
                    {!isScenario ? (
                        <ChallengeType>
                            {practisSetEntityTypeName(item.type)}
                        </ChallengeType>
                    ) : (
                        <ScenarioType>
                            {practisSetEntityTypeName(item.type)}
                        </ScenarioType>
                    )}
                    <Title>{content?.title}</Title>
                    <Description>{content?.description}</Description>
                </PreviewHeader>
                <Content>
                    {content?.script?.lines?.map((line: ScriptLine) => {
                        return (
                            <ScriptLineCard key={line.id}>
                                {line.speaker === ScriptLineSpeaker.CUSTOMER ? (
                                    <LineCustomerTitle>
                                        Customer
                                    </LineCustomerTitle>
                                ) : (
                                    <LineRepTitle>Rep</LineRepTitle>
                                )}
                                <LineDescription
                                    dangerouslySetInnerHTML={{ __html: buildText(line) }}
                                />
                            </ScriptLineCard>
                        );
                    })}
                </Content>
            </ScrollContainer>
        </PreviewItemContainer>
    );
};

export default PreviewItem;
