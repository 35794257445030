import { SearchParams } from '../../../../constants/interfaces/filters';
import { UserV2 } from '../../../../constants/interfaces/User';
import {
    searchInvitationsFailure,
    searchInvitationsStart,
    searchInvitationsSuccess,
    updateAllSelectedInvitationsState,
    updateInvitationFailure,
    updateInvitationStart,
    updateInvitationSuccess,
    updateSelectedInvitationState,
} from '../actions';
import { useDispatch } from 'react-redux';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    useResendInvitationApi,
    useSearchUsersApi,
    useRevokePendingUsers,
} from '../../../../api';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import { UserRoles } from '../../../../pages/ActionPages/NewUser/tools';
import { copyTextToClipboard } from '../../../../helpers/functions/copy-to-clipboard';
import { setSearchState } from '../../../searchState/store/actions';
import { SEARCH_STATE } from '../../../searchState/constants';
import { buildRegistrationUrl } from '../../../../helpers/functions/url-helpers';
import { 
    InviteTextParams,
    buildUserInviteText,
    buildAdminInviteText
} from '../../../../helpers/functions/invitation-templates';
import { SearchUsersParams } from '../../../../api/users/types';
import { getSearchSortingValue } from '../../../../helpers/functions/search-params-helpers';
import { UserStatus } from '../costants';

export const useSearchInvitationsService = () => {
    const dispatch = useDispatch();
    const searchUsersApi = useSearchUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p: SearchParams, companyId: number, shouldSetStore = true) => {
            shouldSetStore && dispatch(searchInvitationsStart());

            const params: SearchUsersParams = {
                status: UserStatus.PENDING,
                roles: p.roleIDs?.join(','),
                labels: p.labelIDs?.join(','),
                invitedBy: p.invitersIDs?.join(','),
                companies: companyId.toString(),
                limit: p.limit ?? 20,
                offset: p.offset ?? 0,
                query: p.searchTerm,
                sort: getSearchSortingValue(p.orderBy, 'name', true),
            };
            return searchUsersApi(params)
                .then(data => {
                    if (shouldSetStore) {
                        dispatch(
                            setSearchState(
                                SEARCH_STATE.PENDING_USERS.name,
                                SEARCH_STATE.PENDING_USERS.childrenKeys,
                                p
                            )
                        );
                        dispatch(searchInvitationsSuccess(data));
                    }
                    return data.items;
                })
                .catch((error: ErrorResult) => {
                    if (shouldSetStore) {
                        dispatch(searchInvitationsFailure(error.message));
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, searchUsersApi, showMessage]
    );
};

export const useResendInvitationService = () => {
    const dispatch = useDispatch();
    const resendInvitationApi = useResendInvitationApi();
    const showMessage = useShowMessage();
    return useCallback(
        (invitationIds: number[], isRowResend?: boolean, search?: SearchParams, selectAll?: boolean) => {
            dispatch(updateInvitationStart());
            resendInvitationApi(invitationIds)
                .then((response: any) => {
                    const error = Object.values(response).find(
                        (item: any) => item?.error
                    );
                    if (invitationIds.length === 1 && error) {
                        throw (error as any).error;
                    }
                    let message = '';

                    if (isRowResend) {
                        message = 'Invite has been sent';
                    } else {
                        message =
                            invitationIds.length > 1
                                ? `${
                                      selectAll
                                          ? search?.limit
                                          : invitationIds.length
                                  } Invites have been sent`
                                : '1 Invite has been sent';
                    }
                    showMessage(message, 'success');
                    dispatch(
                        updateInvitationSuccess(
                            { id: invitationIds[0] },
                            'update'
                        )
                    );
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateInvitationFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, resendInvitationApi, showMessage]
    );
};

/**
 * @function useCopyInviteTextService - copies user/admin invite text to a clipboard
 * @param { UserV2 } invitation
 * @param { CompanyInterface } company
 * @returns { void }
 */
export const useCopyInviteTextService = () => {
    const showMessage = useShowMessage();
    return useCallback(
        (invitation: UserV2, company: CompanyInterface) => {
            if (!invitation.invitationCode || !company.name) {
                showMessage('Invalid invitation data', 'error');
                return;
            }
            const inviteTextParams: InviteTextParams = {
                name: invitation.firstName,
                inviter: invitation.invitedBy
                    ? `${invitation.invitedBy.firstName} ${invitation.invitedBy.lastName}`
                    : 'Someone',
                companyName: company.name,
                registrationUrl: buildRegistrationUrl(invitation.invitationCode),
            };
            const inviteText: string = invitation.role?.name === UserRoles.USER
                ? buildUserInviteText(inviteTextParams)
                : buildAdminInviteText(inviteTextParams);
            const copy: 'silent' | boolean = copyTextToClipboard(inviteText);

            if (copy !== 'silent') {
                if (copy) {
                    showMessage(
                        'Invite text has been copied',
                        'success'
                    );
                } else {
                    showMessage('Could not copy to clipboard', 'error');
                }
            }
        },
        [showMessage]
    );
};

export const useRevokeInvitationService = () => {
    const dispatch = useDispatch();
    const revokeInvitationApi = useRevokePendingUsers();
    const showMessage = useShowMessage();
    return useCallback(
        (invitationIds: number[], successCallback?: () => void) => {
            dispatch(updateInvitationStart());
            revokeInvitationApi(invitationIds)
                .then(() => {
                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateInvitationFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, revokeInvitationApi, showMessage]
    );
};

export const useUpdateAllSelectedInvitationsStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (invitationIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(
                updateAllSelectedInvitationsState(
                    invitationIds,
                    checked,
                    partial
                )
            );
        },
        [dispatch]
    );
};

export const useUpdateSelectedInvitationStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (invitationId: number) => {
            dispatch(updateSelectedInvitationState(invitationId));
        },
        [dispatch]
    );
};