import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../constants/interfaces/filters';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchLogsApi } from '../../../api';
import {
    searchLogsFailure,
    searchLogsStart,
    searchLogsSuccess,
} from './actions';

export const useSearchLogsService = () => {
    const dispatch = useDispatch();
    const searchLogsApi = useSearchLogsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p: SearchParams) => {
            dispatch(searchLogsStart());
            searchLogsApi(p)
                .then(data => {
                    dispatch(searchLogsSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchLogsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchLogsApi, showMessage]
    );
};
