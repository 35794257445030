import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import AvatarPlaceholder from '../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';

export const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    min-width: 200px;
`;

export const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

export const FlexWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 4px;
        transform: translateY(-1px);
    }
`;

export const useStyles = makeStyles(theme => ({
    customTableCellStyle: {
        padding: '0px !important',
    },

    minWidthTableCell: {
        padding: '0px !important',
        minWidth: 200,
    },
}));
