import { randomArrayItem } from '../../../helpers/functions/get-random-from-array';
import { SCENARIO_IMAGES } from '../../../constants/enums/scenarios';
import { getConfig } from '../../../tools/config';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
    createChallengeScriptLineAction,
    deleteChallengeScriptLineAction,
    fetchChallengeFailure,
    fetchChallengeStart,
    fetchChallengeSuccess,
    modifyChallengeAction,
    modifyChallengeScriptLineAction,
    resetChallengeAction,
    resetChallengeLogoAction,
    restoreChallengeFromTempAction,
    storeChallengeTempCopyAction,
    updateChallengeScriptLineAction,
    uploadChallengeAudioFailure,
    uploadChallengeAudioStart,
    uploadChallengeAudioSuccess,
} from './actions';
import { Challenge } from '../../../constants/interfaces/Challenge';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ScriptLine } from '../../../constants/interfaces/ScriptLine';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    useFileUploadApi,
    useGetChallengeApi,
} from '../../../api';

export const useGetChallengeService = () => {
    const dispatch = useDispatch();
    const getChallengeApi = useGetChallengeApi();
    const showMessage = useShowMessage();
    return useCallback(
        (scenarioId: number | string) => {
            dispatch(fetchChallengeStart());
            getChallengeApi(scenarioId)
                .then(data => {
                    dispatch(fetchChallengeSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchChallengeFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getChallengeApi, showMessage]
    );
};

export const useUploadChallengeAudioService = () => {
    const dispatch = useDispatch();
    const fileUploadApi = useFileUploadApi();
    const showMessage = useShowMessage();
    return useCallback(
        (file: any, associatedEntityId: string | number, filename?: string) => {
            dispatch(uploadChallengeAudioStart());
            const formData: FormData = new FormData();
            formData.set('file', file);
            formData.set('type', 'AUDIO');
            formData.set('associatedEntityType', 'Line');
            if (!associatedEntityId.toString().includes('temp_'))
                formData.set(
                    'associatedEntityId',
                    associatedEntityId.toString()
                );
            if (filename) formData.set('filename', filename);

            return fileUploadApi(formData)
                .then(data => {
                    dispatch(
                        uploadChallengeAudioSuccess(associatedEntityId, data)
                    );
                    return 'success';
                })
                .catch((error: ErrorResult) => {
                    dispatch(uploadChallengeAudioFailure(error.message));
                    showMessage(error.message, 'error');
                    return 'error';
                });
        },
        [dispatch, fileUploadApi, showMessage]
    );
};

export const useCreateChallengeScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: Partial<ScriptLine>, silent?: boolean) => {
            dispatch(createChallengeScriptLineAction(data, silent));
        },
        [dispatch]
    );
};

export const useDeleteChallengeScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (lineId: number) => {
            dispatch(deleteChallengeScriptLineAction(lineId));
        },
        [dispatch]
    );
};

export const useModifyChallengeScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: any, field: string, lineId: number | string) => {
            dispatch(modifyChallengeScriptLineAction(value, field, lineId));
        },
        [dispatch]
    );
};

export const useUpdateChallengeScriptLineService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (scriptLines: Array<ScriptLine>) => {
            dispatch(updateChallengeScriptLineAction(scriptLines));
        },
        [dispatch]
    );
};

export const generateRandomImageUrl = () => {
    const fileName = randomArrayItem(SCENARIO_IMAGES);
    return (
        getConfig().S3_FILES_BASE_URL + `scenarios/default-logos/${fileName}`
    );
};

export const useModifyChallengeService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: any, field: keyof Challenge, silent?: boolean) => {
            dispatch(modifyChallengeAction(value, field, silent));
        },
        [dispatch]
    );
};

export const useResetChallengeService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetChallengeAction());
    }, [dispatch]);
};

export const useResetChallengeLogoService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetChallengeLogoAction());
    }, [dispatch]);
};

export const useStoreChallengeTempCopyService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(storeChallengeTempCopyAction());
    }, [dispatch]);
};

export const useRestoreChallengeFromTempActionService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(restoreChallengeFromTempAction());
    }, [dispatch]);
};
