import React, { FC } from 'react';
import ChartBox from '../ChartBox/ChartBox';
import ChartBoxTitle from '../ChartBox/ChartBoxTitle';
import ChartBoxBody from '../ChartBox/ChartBoxBody';
import styled from 'styled-components';
import { Variables } from '../../../../../../theme/variables';
import EmptySection from './EmptySection';
import LineChart from '../../../../../../ui/components/Charts/LineChart';
import ChartBoxHeader from '../ChartBox/ChartBoxHeader';
import ChartBoxDescription, {
    ChartBoxDescriptionValue,
} from '../ChartBox/ChartBoxDescription';
import DailyTimeSpentIcon from '../../../../../../ui/icons/DailyTimeSpentIcon';
import { secondsToMinSimple } from '../../../../../../helpers/functions/convert-time';

const StyledChartBox = styled(ChartBox)`
    padding-right: 40px;
    padding-left: 35px;
    margin-left: 20px;
`;

interface DailyTimeSpentData {
    date: string;
    value: number;
}

const ChartBoxDescriptionValueStyled = styled(ChartBoxDescriptionValue)`
    font-size: 13px;
`;

const ChartBoxDescriptionValueNormal = styled.span`
    font-weight: normal;
`;

const DailyTimeSpent: FC<{
    data?: DailyTimeSpentData[];
    totalTime?: number;
}> = ({ data, totalTime }) => {
    return (
        <StyledChartBox>
            <ChartBoxHeader>
                <ChartBoxTitle dataTest="time-spent-title">Time Spent</ChartBoxTitle>
                {Number(totalTime) > 0 &&
                    <ChartBoxDescription>
                        <ChartBoxDescriptionValueStyled data-test="total-time-value">
                            {secondsToMinSimple(totalTime)}{' '}
                            <ChartBoxDescriptionValueNormal>
                                in total
                            </ChartBoxDescriptionValueNormal>
                        </ChartBoxDescriptionValueStyled>
                    </ChartBoxDescription>
                }
            </ChartBoxHeader>
            <ChartBoxBody>
                {data?.length ?
                    <LineChart
                        data={data}
                        color={Variables.Colors.darkSkyBlue}
                        label={'min'}
                        dataTest="time-spent-chart"
                    /> :
                    <EmptySection
                        icon={<DailyTimeSpentIcon/>}
                        emptyText="Shows the time spent by a learner on this Practis Set"
                        dataTest="empty-time-spent"
                    />
                }
            </ChartBoxBody>
        </StyledChartBox>
    );
};

export default DailyTimeSpent;
