import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { Roleplay } from '../../../../constants/interfaces/Roleplay';

export interface RoleplaysState {
    data?: ListResult<Roleplay>;
    isLoading: boolean;
    error?: string;
}

export type RoleplaysStateSelector = (state: any) => RoleplaysState;

export const RoleplaysStateSelectorContext = React.createContext<RoleplaysStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useRoleplaysState(): RoleplaysState {
    return useSelector(useContext(RoleplaysStateSelectorContext));
}
