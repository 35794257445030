import { Action } from 'redux';

export enum ACTIONS {
    SELECT_YEAR = 'SELECT_YEAR',
    SELECT_MONTH = 'SELECT_MONTH',
    CLEAR_YEAR_AND_MONTH = 'CLEAR_YEAR_AND_MONTH',
    DISABLE_MONTH_FILTER = 'DISABLE_MONTH_FILTER'
}

export type SelectYearAction = Action<ACTIONS.SELECT_YEAR> & {
    year: number;
};

export const selectYear = (
    year: number
): SelectYearAction => ({
    type: ACTIONS.SELECT_YEAR,
    year,
});

export type SelectMonthAction = Action<ACTIONS.SELECT_MONTH> & {
    year: number;
    month: number;
};

export const selectMonth = (
    year: number,
    month: number
): SelectMonthAction => ({
    type: ACTIONS.SELECT_MONTH,
    year,
    month
});

export type ClearYearAndMonthAction = Action<ACTIONS.CLEAR_YEAR_AND_MONTH> & {};

export const clearYearAndMonth = (
): ClearYearAndMonthAction => ({
    type: ACTIONS.CLEAR_YEAR_AND_MONTH,
});

export type DisableMonthFilterAction = Action<ACTIONS.DISABLE_MONTH_FILTER> & {
    isDisabled: boolean;
};

export const disableMonthFilter = (
    isDisabled: boolean
): DisableMonthFilterAction => ({
    type: ACTIONS.DISABLE_MONTH_FILTER,
    isDisabled,
});

export type MonthFilterActions = SelectYearAction
    | SelectMonthAction
    | ClearYearAndMonthAction
    | DisableMonthFilterAction;