import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';
import Checkbox from '../../../ui/components/Checkbox';
import { Input } from '../../../ui/components/input';

import { StyleProps } from './types';

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    menuListClassName: {
        width: ({ customWidth }) =>
            customWidth ? `${customWidth}px` : '168px',
        boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1)',
        padding: '16px 8px',
        borderRadius: '8px !important',
        right: '40px !important',
        marginTop: ({ marginTop }) =>
            marginTop ? `${marginTop}px !important` : '5px !important',
        marginLeft: '240px !important',
        background: 'var(--ps-white-1)',
    },
    popper: {
        zIndex: 999999,
    },
}));

export const DotsIconContainer = styled.div<{ disabled: boolean }>`
    height: 100%;
    padding: 0 6px 0 4px;
    width: 20px;
    cursor: pointer;
    color: ${props =>
        props.disabled ? 'var(--ps-grey-1)' : 'var(--ps-white-1)'};
    &:hover {
        color: ${props =>
            props.disabled ? 'var(--ps-grey-1)' : 'var(--ps-white-1)'};
    }
`;

export const StyledLabelListItemContainer = styled.div``;

export const ChildLabelsContainer = styled.div<{
    expanded: boolean;
}>`
    display: ${props => (props.expanded ? 'block' : 'none')};
    margin-left: 8px;
`;

export const EditLabelActionsContainer = styled.div`
    margin: 0 16px;
`;

export const LabelItemActions = styled.div<{ menuOpen?: boolean }>`
    display: ${props => (props.menuOpen ? 'flex' : 'none')};
    user-select: none;
    align-items: center;
    height: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
    height: 100%;
    padding-left: 6px;
`;

export const LabelNameContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
    ${props => props.disabled && 'pointer-events: none'}
`;

export const ArrowContainer = styled.div`
    width: 8px;
    margin-right: 4px;
    cursor: pointer;
    color: var(--ps-grey-1);
`;

export const CollapseArrow = styled.div`
    height: 8px;
    display: flex;
`;

export const LabelListItemInputContainer = styled.div`
    flex: 1;
`;

export const LabelListItemInput = styled(Input)<{
    ref?: any;
    readOnly?: boolean;
}>`
    height: 100%;
    color: var(--ps-grey-1);
    font-size: 13px;
    font-weight: 500;
    padding: 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${props => props.readOnly && `pointer-events: none`}
`;

export const NoPermissionPlaceHolder = styled.div`
    width: 16px;
`;

export const StyledLabelListItem = styled.div<{
    selected?: boolean;
    modified?: boolean;
    searched?: boolean;
}>`
    height: 32px;
    background: var(--ps-graphite-2);
    color: var(--ps-grey-1);
    border-radius: 4px;
    font-size: 13px;
    align-items: center;
    display: flex;
    padding-left: 8px;
    padding-right: 6px;
    background: var(--ps-graphite-2);
    ${LabelItemActions} {
        display: ${props => (props.selected ? 'flex' : 'none')};
    }
    ${LabelListItemInput}, ${ArrowContainer} {
        color: ${props =>
            props.selected || props.modified || props.searched
                ? 'var(--ps-white-1)'
                : 'unset'};
    }
    &:hover ${LabelItemActions} {
        display: flex;
    }
    &:hover ${LabelListItemInput}, :hover ${ArrowContainer} {
        color: var(--ps-white-1);
    }
    &:hover {
        background: var(--ps-graphite-1);
    }
`;

export const AddLabelSection = styled.div`
    margin-bottom: 8px;
    padding: 0 16px;
`;