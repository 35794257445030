import {
    getDeviceInfo,
    getNetworkConnectionType,
} from '../../../helpers/functions/detect-browsers';
import { getConfig } from '../../../tools/config';

/**
 * @function generateEmailBody
 * @returns { string }
 */
export function generateEmailBody() {
    const deviceInfo = getDeviceInfo();
    const config = getConfig();
    const networkConnectionType = getNetworkConnectionType();

    return `\n\n\n\nDevice/Browser: ${deviceInfo}\nInternet Connection: ${networkConnectionType.toUpperCase()}\nWeb App Version: ${
        config.APP_VERSION
    }`;
}