import { forwardRef } from 'react';

import { IconProps } from './types';

export const Person = forwardRef<SVGSVGElement, IconProps>(
    ({ className, dataTest }, ref) => {
        return (
            <svg
                ref={ref}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                data-test={dataTest}
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.5 5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm.061 3.072a4 4 0 1 0-5.123 0 6.004 6.004 0 0 0-3.43 5.142.75.75 0 0 0 1.497.07 4.5 4.5 0 0 1 8.99 0 .75.75 0 1 0 1.498-.07 6.005 6.005 0 0 0-3.432-5.142z"
                    fill="#6D7F8C"
                />
            </svg>
        );
    }
);

export default Person;

