import { useCallback } from 'react';
import { useHttp } from '../../tools/http/Http';
import {
    EnrollPractisSetsToUserApiParams,
    DeleteEnrollmentsParams,
    EnrollmentsDueDateType,
    EnrollmentSearchParams,
    GetEnrollmentsParams,
    GetEnrollmentApiParams,
    GetEnrollmentProgressApiParams,
    GetEnrollmentDailyTrainingApiParams,
    OverdueLearnersSearchParams,
    getOverdueLearnersParams
} from './types';


/**
 * @function useEnrollPractisSetsToUserApi
 * @returns { EnrollPractisSetsToUserApiParams }
 */
 export function useEnrollPractisSetsToUserApi() {
    const http = useHttp();
    return useCallback(
        enrollments => http.post('enrollments', enrollments, true),
        [http]
    ) as EnrollPractisSetsToUserApiParams;
}

/**
 * @function useDeleteEnrollmentsApi
 * @param { number[] } enrollmentIds
 * @returns { Promise<void> }
 */
 export function useDeleteEnrollmentsApi() {
    const http = useHttp();
    return useCallback(
        (enrollmentIds) =>
            http.delete(`enrollments`, enrollmentIds, true),
        [http]
    ) as DeleteEnrollmentsParams;
}

/**
 * @function useCreateUpdateEnrollmentDueDate
 * @param { EnrollmentsDueDateType[] } enrollmentDueDates
 * @returns { Promise<void> }
 */
export function useCreateUpdateEnrollmentDueDate() {
    const http = useHttp();
    return useCallback(
        (enrollmentDueDates: EnrollmentsDueDateType[]) =>
            http.post(`/enrollments/dueDate`, enrollmentDueDates, true),
        [http]
    );
}

/**
 * @function useGetEnrollments
 * @param { searchParams[] } EnrollmentSearchParams
 * @returns { Promise<void> }
 */
export function useGetEnrollments() {
    const http = useHttp();
    return useCallback(
        (searchParams: EnrollmentSearchParams) => http.get('enrollments', searchParams, undefined, true),
        [http]
    ) as GetEnrollmentsParams;
}

/**
 * @function useGetEnrollmentApi
 * @returns { GetEnrollmentApiParams }
 */
export function useGetEnrollmentApi(): GetEnrollmentApiParams {
    const http = useHttp();
    return useCallback(
        (enrollmentId) =>
            http.get(`/enrollments/${enrollmentId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useGetEnrollmentProgressApi
 * @returns { GetEnrollmentProgressApiParams }
 */
export function useGetEnrollmentProgressApi(): GetEnrollmentProgressApiParams {
    const http = useHttp();
    return useCallback(
        (enrollmentId) =>
            http.get(`/enrollments/${enrollmentId}/progress_status`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useGetEnrollmentDailyTrainingApi
 * @returns { GetEnrollmentProgressApiParams }
 */
export function useGetEnrollmentDailyTrainingApi(): GetEnrollmentDailyTrainingApiParams {
    const http = useHttp();
    return useCallback(
        (enrollmentId) =>
            http.get(`/enrollments/${enrollmentId}/daily_training`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useGetOverdueLearners
 * @param { searchParams[] } OverdueLearnersSearchParams
 * @returns { getOverdueLearnersParams }
 */
export function useGetOverdueLearners() {
    const http = useHttp();
    return useCallback(
        (searchParams: OverdueLearnersSearchParams) => http.get('/enrollments/overdue-users', searchParams, undefined, true),
        [http]
    ) as getOverdueLearnersParams;
}
