import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getProfileState } from '../../pages/UserProfile/store/reducers';

/**
 * Manual dismissing (based on local storage)
 * @param key - unique key value
 * @param forCurrentUser - dismiss for current authorized user (from redux storage: profile.id)
 * @return array with 2 items: the first item is a stateful boolean value, the second item is function for dismissing of current key
 */
export const useDismiss = (
    key: string | undefined,
    forCurrentUser = true
): [boolean, () => void] => {
    const dismissKey = 'dismissedItems';

    const profile = useSelector(getProfileState);

    const itemKey = useMemo(() => {
        if (forCurrentUser && profile?.id !== undefined) {
            return `${key}&userId=${profile.id}`;
        } else if (forCurrentUser && !profile?.id) {
            console.warn(
                "Cannot get ID of current authorized user. Seems like for now it's a guest user. Please make for sure you are doing good :)"
            );
        }
        return key;
    }, [key, profile, forCurrentUser]);

    const getValue = useCallback((): boolean => {
        if (
            itemKey === undefined ||
            (forCurrentUser && profile?.id === undefined)
        ) {
            return true;
        }

        try {
            const dismissedHashString = localStorage.getItem(dismissKey);
            if (dismissedHashString === null) {
                throw new Error();
            }
            const dismissedItemsHash = JSON.parse(dismissedHashString);
            return dismissedItemsHash[itemKey];
        } catch {
            localStorage.setItem(
                dismissKey,
                JSON.stringify({ [itemKey]: false })
            );
            return false;
        }
    }, [itemKey, profile, forCurrentUser]);

    const [isDismissed, setDismiss] = useState(getValue());

    const onDismissHandler = useCallback(() => {
        if (
            itemKey === undefined ||
            (forCurrentUser && profile?.id === undefined)
        ) {
            return;
        }

        try {
            const dismissedHashString = localStorage.getItem(dismissKey);
            if (dismissedHashString === null) {
                throw new Error();
            }
            const dismissedItemsHash = JSON.parse(dismissedHashString);
            dismissedItemsHash[itemKey] = true;
            localStorage.setItem(
                dismissKey,
                JSON.stringify(dismissedItemsHash)
            );
        } catch {
            localStorage.setItem(
                dismissKey,
                JSON.stringify({ [itemKey]: true })
            );
        }
        setDismiss(true);
    }, [setDismiss, itemKey, profile, forCurrentUser]);

    // update state when authorized user was changed
    useEffect(() => {
        if (profile?.id !== undefined) {
            setDismiss(getValue());
        }
    }, [profile, setDismiss, getValue]);

    return [isDismissed, onDismissHandler];
};
