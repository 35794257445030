import { History } from 'history';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { isCompanyAdminRole, isPractisAdminRole, isTeamLeader, userRoleTitle } from '../../constants/enums';
import { NEW_PERMISSIONS } from '../../constants/enums/permissions';
import { UserV2 } from '../../constants/interfaces/User';
import PrivateRoute from '../../routes/PrivateRouter';
import ROUTES, { replaceUserId, useUserIdMatch } from '../../routes/routes';
import ProfileInfo from './components/ProfileInfo';
import Security from './components/Security';
import Notifications from './components/Notifications';
import { getProfileState } from './store/reducers';
import { useUploadPhotoService, useUpdateProfileService, useGetProfileService } from './store/services';

import { useFetchUser } from '../../features/users/store/services';
import {
    getUserLoading,
    getUserState,
} from '../../features/users/store/reducers';
import { CompanyInterface } from '../../constants/interfaces/Company';
import { getCompanyState } from '../CompanySettings/store/reducers';
import { usePermissionsState } from '../../features/permissions/store/state';
import { WEB_SITE_ID, WebSiteIdContext } from '../../tools/constants/WebSiteId';
import ArrowRight from '../../ui/icons/ArrowRight';
import AvatarPlaceholder from '../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { Button } from '../../ui/components/Button';
import { TableDivider } from '../../ui/components/table-wrapper/table-divider';
import { Loading } from '../../ui/components/LoadingCopmonent';
import MainWrapper from '../../ui/wrapper/MainWrapper/MainWrapper';
import { useUpdateUserPassword } from '../../api';
import { UpdatePasswordType } from '../../api/users/types';
import { useShowMessage } from '../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../constants/interfaces/ErrorResult';
import { removeNullValues } from '../../helpers/functions/object-helpers';

const StyledUserProfile = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledProfileDescription = styled.div`
    display: flex;
    padding: 27px 0;
    justify-content: space-between;
`;

export const ProfileSection = styled.div`
    display: flex;
    align-items: center;
    margin-left: 32px;
`;

export const ViewProfile = styled.div``;

export const StyledAvatar = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.whiteFive};
    img {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
`;

const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 24px;
    flex: 1;
`;

const StyledProfileInfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 22px 0;
`;

const StyledProfileInfo = styled.div`
    display: flex;
    flex: 3;
    min-width: 200px;
    margin-right: 32px;
`;

const StyledProfileNavigation = styled.div`
    width: 100%;
`;

const NavigationItem = styled(NavLink)`
    align-items: center;
    padding: 0 20px 0 32px;
    height: 64px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.steelGrey};
    display: flex;
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.white};
    margin-bottom: 16px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    &.is-active {
        color: ${props => props.theme.Colors.black};
        border: solid 1px ${props => props.theme.Colors.cloudyBlue};
    }
`;

const NavLinkText = styled.div`
    flex: 1;
    margin: auto;
`;

const NavLinkIcon = styled(ArrowRight)`
    cursor: pointer;
    margin-left: 15px;
    width: 8px;
    height: 8px;
    display: flex;
`;

const StyledProfileContent = styled.div`
    flex: 5;
    padding: 40px 39.5px;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    min-height: 600px;
`;

const StyledLoadingContent = styled.div`
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UserRole = styled.div`
    font-size: 11px;
    font-weight: 600;
    color: ${props => props.theme.Colors.steelGrey};
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.Colors.black};
`;

const Address = styled.div`
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    color: var(--ps-grey-1);
`;

export const UserProfile: FC<{
    history: History;
    userId: number | 'me';
    profile?: UserV2;
    loading?: boolean;
    self?: boolean;
    profileRoute: string;
    securityRoute: string;
    notificationsRoute: string;
    company?: CompanyInterface;
    isPhotoUploading: boolean;
    updateUserProfile(data: UserV2, userId: number): void;
    uploadUserPicture(userId: number, file: File): void;
    deleteUser?(userId: number): void;
}> = ({
    history,
    company,
    userId,
    profile,
    loading,
    self,
    profileRoute,
    securityRoute,
    notificationsRoute,
    isPhotoUploading,
    updateUserProfile,
    uploadUserPicture,
    deleteUser,
}) => {
    const updateUserPassword = useUpdateUserPassword();
    const showMessage = useShowMessage();
    const portal = useContext(WebSiteIdContext);
    const permissions = usePermissionsState();
    const editable = self || permissions.includes(NEW_PERMISSIONS.UPDATE_USER);
    const canUpdatePassword =
        self || permissions.includes(NEW_PERMISSIONS.SET_USER_PASSWORD);
    const isPractisAdmin = isPractisAdminRole(profile?.role?.name);

    const notificationsAllowed =
        isTeamLeader(profile?.role?.name) || isCompanyAdminRole(profile?.role?.name);
    const isLoading =
        self || (profile && userId === profile.id) ? false : loading;

    const prevCompanyRef = useRef(company);
    useEffect(() => {
        prevCompanyRef.current = company;
    });
    const prevCompany = prevCompanyRef.current;

    useEffect(() => {
        if (
            !company ||
            !(company && company.id) ||
            !prevCompany ||
            !(prevCompany && prevCompany.id)
        )
            return;
        if (company.id !== prevCompany.id && userId !== 'me') {
            history.push(ROUTES.USERS);
        }
    }, [company, prevCompany, history, userId]);

    const handleViewProfile = useCallback(() => {
        if (!profile) return;

        if (permissions.includes(NEW_PERMISSIONS.VIEW_USER_PERFORMANCE)) {
            history.push(
                ROUTES.USER_PERFORMANCE.replace(
                    ':userId',
                    profile.id!.toString()
                ),
                { useGoBack: true }
            );
        }
    }, [profile, history, permissions]);

    /**
     * @function handleUpdateUserPassword
     * @param { UpdatePasswordType } data
     * @param { number } userId
     * @returns { Promise<void>  }
     */
    const handleUpdateUserPassword = async (
        data: UpdatePasswordType,
        userId: number
    ): Promise<void> => {
        await updateUserPassword(
            userId,
            removeNullValues(data) as UpdatePasswordType
        )
            .then(() => {
                showMessage('Profile Updated!', 'success');
            })
            .catch((error: ErrorResult) => {
                error.message && showMessage(error.message, 'error');
            });
    };

    return (
        <StyledUserProfile>
            <StyledProfileDescription>
                <ProfileSection>
                    <StyledAvatar>
                        <AvatarPlaceholder
                            profile={profile}
                            loading={isLoading}
                            size="default"
                            dataTest="user-profile-avatar"
                        />
                    </StyledAvatar>
                    <StyledDescription>
                        {!isLoading && (
                            <UserRole data-test="user-profile-role">
                                {userRoleTitle(profile)}
                            </UserRole>
                        )}
                        {!isLoading && (
                            <Title data-test="user-profile-full-name">
                                {profile && profile.firstName}{' '}
                                {profile && profile.lastName}
                            </Title>
                        )}
                        {!isLoading && (
                            <Address data-test="user-profile-email">
                                {profile && profile.email}
                            </Address>
                        )}
                    </StyledDescription>
                </ProfileSection>
                {portal === WEB_SITE_ID.COMPANY_PORTAL && (
                    <ViewProfile>
                        <Button
                            label={'View Profile'}
                            width={'136px'}
                            height={'40px'}
                            action={handleViewProfile}
                        />
                    </ViewProfile>
                )}
            </StyledProfileDescription>
            <TableDivider />
            <StyledProfileInfoWrapper>
                <StyledProfileInfo>
                    <StyledProfileNavigation>
                        <NavigationItem
                            to={replaceUserId(profileRoute, userId)}
                            activeClassName={'is-active'}
                            exact
                            data-test="edit-details"
                        >
                            <NavLinkText>Edit User Details</NavLinkText>
                            <NavLinkIcon />
                        </NavigationItem>
                        {editable && canUpdatePassword && (profile && !isPractisAdmin) && (
                            <NavigationItem
                                to={replaceUserId(securityRoute, userId)}
                                activeClassName={'is-active'}
                                exact
                                data-test="change-password"
                            >
                                <NavLinkText>Change Password</NavLinkText>
                                <NavLinkIcon />
                            </NavigationItem>
                        )}
                        {editable && notificationsAllowed && (
                            <NavigationItem
                                to={replaceUserId(notificationsRoute, userId)}
                                activeClassName={'is-active'}
                                exact
                                data-test="notifications"
                            >
                                <NavLinkText>Notifications</NavLinkText>
                                <NavLinkIcon />
                            </NavigationItem>
                        )}
                    </StyledProfileNavigation>
                </StyledProfileInfo>
                <StyledProfileContent>
                    {!isLoading && profile ? (
                        <Switch>
                            <PrivateRoute path={profileRoute} exact>
                                <ProfileInfo
                                    profile={profile}
                                    editable={editable}
                                    updateUserProfile={updateUserProfile}
                                    uploadUserPicture={uploadUserPicture}
                                    deleteUser={deleteUser}
                                    self={self}
                                    dataTest={'user-profile'}
                                    isPhotoUploading={isPhotoUploading}
                                />
                            </PrivateRoute>
                            {editable && (
                                <PrivateRoute path={securityRoute} exact>
                                    <Security
                                        profile={profile}
                                        updateUserPassword={
                                            handleUpdateUserPassword
                                        }
                                        dataTest={'change-password'}
                                    />
                                </PrivateRoute>
                            )}
                            {editable && notificationsAllowed && (
                                <PrivateRoute path={notificationsRoute} exact>
                                    <Notifications userId={profile.id} />
                                </PrivateRoute>
                            )}
                        </Switch>
                    ) : (
                        <StyledLoadingContent>
                            <Loading />
                        </StyledLoadingContent>
                    )}
                </StyledProfileContent>
            </StyledProfileInfoWrapper>
        </StyledUserProfile>
    );
};

export const UserProfileContainer: FC = () => {
    const history = useHistory();
    const company = useSelector(getCompanyState);
    const userId = useUserIdMatch();
    const meProfile = useSelector(getProfileState);
    const user = useSelector(getUserState);
    const userLoading = useSelector(getUserLoading);
    const getProfile = useGetProfileService();
    const updateProfileService = useUpdateProfileService();
    const fetchUser = useFetchUser();
    const uploadPhoto = useUploadPhotoService();
    const showMessage = useShowMessage();
    const [isPhotoUploading, setIsPhotoUploading] = useState(false);

    let self =
        (meProfile && user && meProfile.id === user.id) ?? false;

    let targetUser = self ? meProfile || user : user;

    if (typeof userId === 'number' && targetUser && targetUser.id !== userId) {
        targetUser = undefined;
        self = false;
    }

    /**
     * @function fetchUserSettingData
     * @returns { void }
     */
    const fetchUserSettingData = useCallback(() => {
        fetchUser(userId === 'me' ? meProfile?.id! : (userId as number));
    }, [fetchUser, meProfile?.id, userId]);

    /**
     * @function handleUpdateUserSuccess
     * @param { number } userId
     * @returns { void }
     */
     const handleUpdateUserSuccess = useCallback((userId: number) => {
        if (userId === meProfile?.id) {
            return getProfile();
        }
        return fetchUser(userId);
    }, [meProfile?.id, getProfile, fetchUser]);


    /**
     * @function handleUpdateUserProfileInfo
     * @returns { void }
     */
    const handleUpdateUserProfileInfo = useCallback(
        (data: UserV2, userId: number) => {
            updateProfileService(data, userId, self, true)
                .then(() => handleUpdateUserSuccess(userId));
        },
        [updateProfileService, self, handleUpdateUserSuccess]
    );

    /**
     * @function handleUpdateUserProfilePicture
     * @returns { void }
     */
    const handleUpdateUserProfilePicture = useCallback(
        (userId: number, file: File) => {
            setIsPhotoUploading(true);
            uploadPhoto(userId, file)
                .then(() => handleUpdateUserSuccess(userId))
                .catch(() => {
                    setIsPhotoUploading(false);
                    return Promise.reject();
                })
                .then(() => {
                    setIsPhotoUploading(false);
                    showMessage('Image Uploaded!', 'success');
                });
        },
        [uploadPhoto, handleUpdateUserSuccess, showMessage]
    );

    useEffect(() => {
        if (typeof userId === 'number' || meProfile?.id) fetchUserSettingData();
    }, [fetchUserSettingData, meProfile?.id, userId]);

    return (
        <MainWrapper
            goBackUrl={ROUTES.USERS}
            subTitle="User Settings"
            htmlPageTitle={`User Settings ${
                targetUser
                    ? `- ${targetUser.firstName} ${targetUser.lastName}`
                    : ''
            } - Practis`}
            useGoBack={true}
            dataTest="user-settings"
        >
            <UserProfile
                history={history}
                company={company}
                userId={userId}
                profile={targetUser}
                loading={userLoading}
                self={self}
                profileRoute={ROUTES.PROFILE}
                securityRoute={ROUTES.PROFILE_SETTINGS.SECURITY}
                notificationsRoute={ROUTES.PROFILE_SETTINGS.NOTIFICATIONS}
                isPhotoUploading={isPhotoUploading}
                updateUserProfile={handleUpdateUserProfileInfo}
                uploadUserPicture={handleUpdateUserProfilePicture}
            />
        </MainWrapper>
    );
};;

export default UserProfileContainer;
