import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import { EnrollmentStatusEnum } from '../../../../../../constants/interfaces/Enrollments';
import { Variables } from '../../../../../../theme/variables';
import { Button } from '../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../ui/components/Fieldset';
import Checkbox from '../../../../../../ui/components/Checkbox';
import { TeamMemberStatusEnum } from '../../../../../../constants/interfaces/TeamMember';
import OverdueFlag from '../../../../../../ui/icons/OverdueFlag';
import { DueDateFilters } from '../../../../../../constants/interfaces/DueDates';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 166px;
    background-color: inherit;
`;

const StatusItem = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const OverdueFlagWrapped = styled(OverdueFlag)`
    margin-left: 6px;
`;

const overduesMap: Record<DueDateFilters, { title: string }> = {
    [DueDateFilters.ASSIGNED]: { title: 'Assigned' },
    [DueDateFilters.NO_DUEDATE]: { title: 'No Due Date' },
    [DueDateFilters.OVERDUE]: { title: 'Overdue' },
};

const statusesMap: Record<EnrollmentStatusEnum, { title: string }> = {
    [EnrollmentStatusEnum.PENDING]: { title: 'Not Started' },
    [EnrollmentStatusEnum.IN_PROGRESS]: { title: 'In Progress' },
    [EnrollmentStatusEnum.COMPLETED]: { title: 'Completed' },
};

const regStatusesMap: Record<any, { title: string }> = {
    [TeamMemberStatusEnum.PENDING]: { title: 'Pending' },
    [TeamMemberStatusEnum.ACTIVE]: { title: 'Active' }
};

export const TrainingDetailsFilters: FC<{
    status: EnrollmentStatusEnum[];
    registrationStatus: any;
    onStatusChange: (status: EnrollmentStatusEnum[]) => void;
    onSuccessApply: () => void;
    onRegistrationStatusChange: (status: any) => void;
    onOverdueChange: (dueDate: DueDateFilters[]) => void;
    dueDate: DueDateFilters[];
}> = ({ onStatusChange, status, registrationStatus, onSuccessApply, onRegistrationStatusChange, onOverdueChange, dueDate }) => {
    const [statuses, setStatuses] = useState(status);
    const [overdues, setOverdues] = useState(dueDate);
    const [registrationStatuses, setRegistrationStatuses] = useState<any>(registrationStatus)

    const onStatusChangeHandler = useCallback(
        (statusIn: EnrollmentStatusEnum, e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setStatuses([...statuses, statusIn]);
            } else {
                setStatuses(statuses.filter(item => item !== statusIn));
            }
        },
        [setStatuses, statuses]
    );

    const onOverdueChangeHandler = useCallback(
        (dueDateIn: DueDateFilters, e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setOverdues([...overdues, dueDateIn]);
            } else {
                setOverdues(overdues.filter(item => item !== dueDateIn));
            }
        },
        [overdues, setOverdues]
    );

    const onRegistrationStatusChangeHandler = useCallback(
        (statusIn: TeamMemberStatusEnum, e: ChangeEvent<HTMLInputElement>) => {
           
            if (e.target.checked) {
                setRegistrationStatuses([...registrationStatuses, statusIn]);
            } else {
                setRegistrationStatuses(registrationStatuses.filter((item: any) => item !== statusIn));
            }
        },
        [setRegistrationStatuses, registrationStatuses]
    );

    const onApplyHandler = useCallback(() => {
        onStatusChange(statuses);
        onRegistrationStatusChange(registrationStatuses)
        onSuccessApply();
        onOverdueChange(overdues);
    }, [onStatusChange, statuses, onRegistrationStatusChange, registrationStatuses, onSuccessApply, onOverdueChange, overdues]);

    const onClearHandler = () => {
        setStatuses([]);
        setRegistrationStatuses([]);
        setOverdues([]);
    }

    const totalFiltersSelected = statuses.length + 
    registrationStatuses.length + overdues.length;

    return (
        <Container>
            <Wrapper>
            <Fieldset title={'Registration Status'} dataTest="registration-status-section-title">
                    <StatusContainer>
                        {(Object.keys(
                            regStatusesMap
                        ) as TeamMemberStatusEnum[]).map(item => (
                            <StatusItem data-test="registration-status-item-container">
                                <Checkbox
                                    key={item}
                                    checked={registrationStatuses && registrationStatuses.includes(item)}
                                    handleChange={e =>
                                        onRegistrationStatusChangeHandler(item, e)
                                    }
                                    label={regStatusesMap[item].title}
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                    dataTest={`${regStatusesMap[item].title.toLowerCase()}-status-checkbox`}
                                />
                            </StatusItem>
                        ))}
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Progress Status'} dataTest="progress-status-section-title">
                    <StatusContainer>
                        {(Object.keys(
                            statusesMap
                        ) as EnrollmentStatusEnum[]).map(item => (
                            <StatusItem data-test="progress-status-item-container">
                                <Checkbox
                                    key={item}
                                    checked={statuses.includes(item)}
                                    handleChange={e =>
                                        onStatusChangeHandler(item, e)
                                    }
                                    label={statusesMap[item].title}
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                    dataTest={`${kebabCase(statusesMap[item].title)}-status-checkbox`}
                                />
                            </StatusItem>
                        ))}
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Due Date'} dataTest="due-date-section-title">
                    <StatusContainer>
                        {(Object.keys(
                            overduesMap
                        ) as DueDateFilters[]).map(item => (
                            <StatusItem>
                                <Checkbox
                                    key={item}
                                    checked={overdues.includes(item)}
                                    handleChange={e =>
                                        onOverdueChangeHandler(item, e)
                                    }
                                    label={overduesMap[item].title}
                                    border={Variables.Colors.steelGrey}
                                    size={12}
                                    fontColor={Variables.Colors.black}
                                    dataTest={`${kebabCase(overduesMap[item].title)}-checkbox`}
                                />
                                {item === DueDateFilters.OVERDUE && <span data-test="overdue-checkbox-icon"><OverdueFlagWrapped /></span>}
                            </StatusItem>
                        ))}
                    </StatusContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant={'inverse'}
                        width={'122px'}
                        action={() => onClearHandler()}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={() => onApplyHandler()}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};

export default TrainingDetailsFilters;
