import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import styled from 'styled-components';
import { EnrollmentStatus } from '../../../../../../constants/interfaces/Enrollments';
import { Variables } from '../../../../../../theme/variables';
import { Button } from '../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../ui/components/Fieldset';
import Checkbox from '../../../../../../ui/components/Checkbox';
import { PortableLabelsWithStore } from '../../../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import OverdueFlag from '../../../../../../ui/icons/OverdueFlag';
import { statusesMap } from '../../../../../../constants/variables/general';

//region Styles
const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: inherit;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;

    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    height: 100%;

    overflow: auto;
    background-color: inherit;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
    background-color: inherit;
`;

const StatusItem = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

const OverdueFlagWrapped = styled(OverdueFlag)`
    margin-left: 4px;
`;

//endregion

export const TrainingFilters: FC<{
    preSelectedLabels: number[];
    status: EnrollmentStatus[];
    onLabelsChange: (ids: number[]) => void;
    onStatusChange: (status: EnrollmentStatus[]) => void;
    onSuccessApply: () => void;
    onOverdueChange: (hasOverdue: boolean) => void;
    overdue?: boolean;
}> = ({
    preSelectedLabels,
    onLabelsChange,
    onStatusChange,
    status,
    onSuccessApply,
    onOverdueChange,
    overdue,
}) => {
    const [statuses, setStatuses] = useState(status);
    const labels = useLabelsState();
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
    const [hasOverdue, setHasOverdue] = useState(!!overdue);

    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    

    const onStatusChangeHandler = useCallback(
        (statusIn: EnrollmentStatus, e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setStatuses([...statuses, statusIn]);
            } else {
                setStatuses(statuses.filter(item => item !== statusIn));
            }
        },
        [setStatuses, statuses]
    );

    const onApplyHandler = useCallback(() => {
        onStatusChange(statuses);
        onLabelsChange(labels.selected);
        saveLabels();
        onSuccessApply();
        onOverdueChange(hasOverdue);
    }, [
        onStatusChange,
        statuses,
        onLabelsChange,
        labels.selected,
        onSuccessApply,
        saveLabels,
        onOverdueChange,
        hasOverdue
    ]);

    const totalFiltersSelected =
        labels?.selected.filter(id => id > 0).length + statuses.length + (hasOverdue ? 1 : 0);

    const deselectLabels = useDeselectAllLabels();

        
    const onClearHandler = () => {
        deselectLabels();
        setStatuses([]);
        setHasOverdue(false);
    }

    return (
        <Container>
            <Wrapper>
                <Fieldset
                    title={'Team Member Status'}
                    dataTest="team-member-status"
                >
                    <StatusContainer>
                        {(Object.keys(statusesMap) as EnrollmentStatus[]).map(
                            item => (
                                <StatusItem key={item} data-test="team-member-status-item-container">
                                    <Checkbox
                                        checked={statuses.includes(item)}
                                        handleChange={e =>
                                            onStatusChangeHandler(item, e)
                                        }
                                        label={statusesMap[item].title}
                                        border={Variables.Colors.steelGrey}
                                        size={12}
                                        fontColor={Variables.Colors.black}
                                        dataTest={statusesMap[item]?.dataTest}
                                    />
                                </StatusItem>
                            )
                        )}
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Overdue'} dataTest="overdue">
                    <StatusContainer>
                        <StatusItem>
                            <Checkbox
                                checked={hasOverdue}
                                handleChange={e =>
                                    setHasOverdue(e.target.checked)
                                }
                                label="Overdue"
                                border={Variables.Colors.steelGrey}
                                size={12}
                                fontColor={Variables.Colors.black}
                                dataTest="overdue-check-box"
                            />
                            <OverdueFlagWrapped dataTest="overdue-flag-icon" />
                        </StatusItem>
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-filters-counter">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        disabled={!totalFiltersSelected}
                        variant="inverse"
                        width={'122px'}
                        action={() => onClearHandler()}
                        dataTest="clear-filters-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={() => onApplyHandler()}
                    dataTest="apply-filters-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};

export default TrainingFilters;
