import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { Button } from '../../../ui/components/Button';
import { Variables } from '../../../theme/variables';
import Checkbox from '../../../ui/components/Checkbox';

const StyledTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    width: 100%;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
    margin-top: 7px;
`;

const StyledActions = styled.div`
    width: 272px;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    align-self: flex-end;
`;

const StyledCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 11px;
    margin-bottom: 5px;
    align-self: flex-start;
`;

const StyledReminderTitle = styled.div`
    font-size: 13px;
    font-weight: 500;
    margin-left: 11px;
    color: ${props => props.theme.Colors.steelGrey};
`;

export const RequestReminder: FC<{
    doNotShowWarning?: boolean;
    onProceed?(): void;
    onClose?(): void;
    onShowWarningClicked: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ doNotShowWarning, onProceed, onClose, onShowWarningClicked }) => {
    return (
        <>
            <StyledTitle>Reminder</StyledTitle>
            <StyledContent>
                <StyledDescription>
                    Once you submit this review, you won't be able to request a
                    redo for this submission.
                </StyledDescription>
                <StyledCheckboxContainer>
                    <Checkbox
                        size={16}
                        checked={!!doNotShowWarning}
                        border={Variables.Colors.coral}
                        checkedBackground={Variables.Colors.coral}
                        handleChange={onShowWarningClicked}
                        checkboxMarginLeft="1px"
                    />
                    <StyledReminderTitle>
                        Don’t show this message again
                    </StyledReminderTitle>
                </StyledCheckboxContainer>
                <StyledActions>
                    <Button
                        width="128px"
                        height="40px"
                        action={onClose}
                        variant="inverse"
                        color="warning"
                    >
                        Go Back
                    </Button>
                    <Button
                        width="128px"
                        height="40px"
                        action={() => !!onProceed && onProceed()}
                        color="warning"
                    >
                        Proceed
                    </Button>
                </StyledActions>
            </StyledContent>
        </>
    );
};
