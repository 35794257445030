import React, {
    createContext,
    FC,
    RefObject,
    useCallback,
    useContext,
    useMemo,
    useRef,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { Loading } from '../LoadingCopmonent';

const Root = styled.div<{ scrollable?: boolean; height?: number }>`
    overflow-y: ${props => (!!props.scrollable ? 'auto' : 'initial')};
    ${props => (props.height ? `height: ${props.height}px;` : '')}
`;


export interface ScrollPaginationProps {
    itemsPerPage?: number;
    itemCount?: number;
    totalCount?: number;
    scrollable?: boolean;
    scrollableRef?: RefObject<HTMLElement>;
    scrollableWindow?: boolean; //for storybook
    className?: string;
    height?: number;
    onLimitChange?(limit: number): void;
    onOffsetChange?(offset: number): void;
    onLocalLimitChange?(limit: number): void;
    hideLoading?: boolean;
}

export const ScrollPagination: FC<ScrollPaginationProps> = ({
    itemsPerPage,
    itemCount,
    totalCount,
    scrollable,
    scrollableRef,
    scrollableWindow,
    className,
    height,
    children,
    onLimitChange,
    onOffsetChange,
    onLocalLimitChange,
    hideLoading,
}) => {
    const context = useContext(ScrollPaginationContext);
    const rootRef = useRef<any>();
    const parentRef = useMemo(
        () =>
            (!!scrollable && rootRef?.current) ||
            scrollableRef?.current ||
            context.scrollableRef?.current ||
            null,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            scrollable,
            rootRef?.current,
            scrollableRef?.current,
            context.scrollableRef?.current,
        ]
    );
    const enabled =
        !!parentRef &&
        typeof itemCount === 'number' &&
        typeof totalCount === 'number' &&
        (!!onLimitChange || !!onOffsetChange || !!onLocalLimitChange);

    const getScrollParent = useCallback(() => parentRef, [parentRef]);

    const handleLoadMore = useCallback(() => {
        if (enabled && itemCount! < totalCount!) {
            const limit = Math.min(totalCount!, itemCount! + itemsPerPage!);
            const offset = Math.min(totalCount!, itemCount!);
            if (onLimitChange) onLimitChange(limit);
            if (onOffsetChange) onOffsetChange(offset);
            if (onLocalLimitChange) onLocalLimitChange(limit);
        }
    }, [
        enabled,
        itemsPerPage,
        itemCount,
        totalCount,
        onLimitChange,
        onOffsetChange,
        onLocalLimitChange,
    ]);

    return (
        <Root
            className={className}
            ref={rootRef}
            scrollable={scrollable}
            height={height}
        >
            <InfiniteScroll
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={enabled && itemCount! < totalCount!}
                loader={hideLoading ? <></> : <Loading key="TableScrollPaginationLoader" />}
                useWindow={!!scrollableWindow}
                getScrollParent={
                    !!scrollableWindow ? undefined : getScrollParent
                }
                threshold={100}
                className={className}
            >
                {children}
            </InfiniteScroll>
        </Root>
    );
};

export interface ScrollPaginationContextValue {
    scrollableRef?: RefObject<HTMLElement>;
}

export const ScrollPaginationContext = createContext<
    ScrollPaginationContextValue
>({});
