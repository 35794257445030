import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PencilIconActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.085a1.75 1.75 0 0 1 0 2.475l-8.61 8.61c-.21.21-.47.363-.756.445l-3.25.929a.75.75 0 0 1-.928-.927l.929-3.251a1.75 1.75 0 0 1 .445-.757l8.61-8.61zm1.414 1.06a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354l-1.086-1.086zM11.189 6.25 9.75 4.81l-6.286 6.287a.25.25 0 0 0-.064.108l-.558 1.953 1.953-.558a.249.249 0 0 0 .108-.064l6.286-6.286z" fill="#4AA9E2"/>
</svg>
        );
    }
);

export default PencilIconActive;
