import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getPractisSetState } from '../../store/reducers';
import Navigation from '../CustomBody/Content/ListContent/components/Navigation/Navigation';

const StyledLibraryNavigationContainer = styled.div``;

const PractisContentNavigation: React.FC<{
    scenariosCount?: number;
    scenarioCountFiltered?: number;
    challengeCountFiltered?: number;
    challengesCount?: number;
    page: string;
    setPage: (value: string) => void;
}> = ({
    scenariosCount,
    scenarioCountFiltered,
    challengeCountFiltered,
    challengesCount,
    page,
    setPage,
}) => {
    const scenarios =
        scenarioCountFiltered !== undefined
            ? scenarioCountFiltered
            : scenariosCount
            ? scenariosCount
            : '0';

    const challenges =
        challengeCountFiltered !== undefined
            ? challengeCountFiltered
            : challengesCount
            ? challengesCount
            : '0';

    return (
        <StyledLibraryNavigationContainer>
            <Navigation
                menu={[
                    {
                        key: 'scenarios',
                        title: `Scenarios (${scenarios})`,
                        space: true
                    },
                    {
                        key: 'challenges',
                        title: `Challenges (${challenges})`
                    },
                ]}
                active={page}
                setActive={setPage}
                dataTest='practis-set-tab'
            />
        </StyledLibraryNavigationContainer>
    );
};

export const PractisContentNavigationContainer: FC<{
    page: string;
    setPage: (value: string) => void;
    scenarioCountFiltered?: number;
    challengeCountFiltered?: number;
}> = ({ page, setPage, scenarioCountFiltered, challengeCountFiltered }) => {
    const scenarios = useSelector(getPractisSetState).totalScenarios;
    const challenges = useSelector(getPractisSetState).totalChallenges;

    return (
        <PractisContentNavigation
            scenariosCount={scenarios}
            scenarioCountFiltered={scenarioCountFiltered}
            challengeCountFiltered={challengeCountFiltered}
            challengesCount={challenges}
            page={page}
            setPage={setPage}
        />
    );
};

export default PractisContentNavigationContainer;
