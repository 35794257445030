import { Action } from 'redux';

export enum ACTIONS {
    DISABLE_LABELS_FILTER = 'DISABLE_LABELS_FILTER'
}

export type DisableLabelsFilterAction = Action<ACTIONS.DISABLE_LABELS_FILTER> & {
    isDisabled: boolean;
};

export const disableLabelsFilter = (
    isDisabled: boolean
): DisableLabelsFilterAction => ({
    type: ACTIONS.DISABLE_LABELS_FILTER,
    isDisabled,
});

export type LablesFilterActions = DisableLabelsFilterAction;