export enum TableAssignOptionTypes {
    ASSIGN_LABELS = 'ASSIGN_LABELS',
    ASSIGN_PRACTIS_SETS = 'ASSIGN_PRACTIS_SETS',
    ASSIGN_PRACTIS_SETS_AND_DUE_DATE= 'ASSIGN_PRACTIS_SETS_AND_DUE_DATE',
    NUDGE = 'Nudge',
    EXPORT = 'EXPORT',
    ARCHIVE = 'ARCHIVE',
    RESTORE = 'RESTORE',
    REMIND = 'REMIND',
    RESEND_INVITES = 'RESEND_INVITES',
    REVOKE = 'REVEOKE',
    REMOVE_PRACTIS_SET = 'REMOVE_PRACTIS_SET',
    DUPLICATE = 'DUPLICATE',
    REMOVE_ITEMS = 'REMOVE_ITEMS',
    DUE_DATES = 'DUE_DATES'
}
