import styled from 'styled-components';

export const TBody = styled.tbody`
    color: ${props => props.theme.Colors.black};
    & > tr {
        &:hover {
            background: ${props => props.theme.Colors.whiteTwo};
            & > td {
                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
    &:before {
        content: '.';
        display: block;
        line-height: 8px;
        color: transparent;
    }
`;


