import styled, { css } from 'styled-components';

import { Themes } from './constants';
import { ColorTheme } from './type';
import ShowIcon from '../../../assets/images/eye.svg';
import HideIcon from '../../../assets/images/eye-open.svg';
import { isSafari } from '../../../helpers/functions/detect-browsers';

export const Container = styled.div<{
    height: string;
    colorTheme: ColorTheme;
    width?: string;
    hideError?: boolean;
    error?: boolean;
}>`
    position: relative;

    display: flex;
    align-items: center;
    height: ${props => props.height};
    ${props => !!props.width && `width: ${props.width};`}

    border-radius: 4px;
    overflow: hidden;
    background-color: ${({ colorTheme }) => Themes[colorTheme].background};

    ${props =>
        !props.hideError &&
        css`
            box-shadow: inset 0 0 0 ${props.error ? '2px' : '-1px'}
                ${props.theme.Colors.tomato};
        `};
    -webkit-appearance: none;

    .custom-border-container {
        ${props =>
            !props.hideError &&
            css`
                box-shadow: inset 0 0 0 ${props.error ? '1px' : '-1px'}
                    ${props.theme.Colors.tomato};
            `};

        border-radius: 4px;
    }

    &.login-input-container {
        box-sizing: border-box;
        border: solid 1px transparent;
        box-shadow: none;

        ${props =>
            !props.hideError && !!props.error &&
            css`border: solid 1px var(--ps-red-main);`
        }

        &:focus-within {
            border: solid 1px var(${props => !props.hideError && !!props.error ? '--ps-red-main' : '--ps-grey-1'});
        }
    }

    &.login-input-container + div {
        padding: 4px 0 0 0;  
    }
`;

export const StyledInput = styled.input<{
    height: string;
    colorTheme: ColorTheme;
    error: boolean;
    padding?: string;
    fontSize?: string;
    fontFamily?: string;
    hideError?: boolean;
}>`
    font-family: ${props => props.fontFamily || props.theme.Fonts.manrope};
    font-size: ${props => (props.fontSize ? props.fontSize : '13px')};

    height: ${props => props.height};
    width: 100%;
    flex-grow: 1;
    padding: ${props =>
        !!props.padding ? props.padding : '29px 24px 15px 24px'};

    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: ${props => Themes[props.colorTheme].color};

    // hack for built-in-browser autofill
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: ${props => Themes[props.colorTheme].color};
        -webkit-box-shadow: 0 0 0px 1000px
            ${props => Themes[props.colorTheme].background} inset;
        transition: background-color 5000s ease-in-out 0s;
        caret-color: ${props => Themes[props.colorTheme].color};
    }
    &:disabled {
        color: ${() => isSafari() ? 'var(--ps-grey-1)' : 'var(--ps-grey-14)'};

        &::placeholder {
            font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
            color: ${props => props.theme.Colors.cloudyBlue};
        }
    }
    &::placeholder {
        font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
        color: ${props => props.theme.Colors.steelGrey};
    }
`;

export const Label = styled.label<{
    filled: boolean;
    top?: string;
    left?: string;
    fontWeight?: number;
    disabled?: boolean;
    fontSize?: string;
    colorTheme: ColorTheme;
    decreaseLabelFontSize?: boolean;
}>`
    position: absolute;
    top: ${props => (props.filled ? (props.top ? props.top : '-8px') : '0')};
    left: ${props => props.left || '24px'};

    font-size: ${({ fontSize }) => fontSize || '12px'};
    font-weight: ${({ fontWeight, colorTheme }) => {
        if (fontWeight) {
            return fontWeight;
        }
        if (colorTheme === 'lightTwo') {
            return 600;
        }

        return 400;
    }};

    display: flex;
    align-items: center;
    height: 100%;

    transition: all 0.18s ease;
    transform-origin: top left;
    pointer-events: none;
    color: ${props =>
        !!Themes[props.colorTheme].labelColor
            ? Themes[props.colorTheme].labelColor
            : props.theme.Colors.steelGrey};
    ${({ filled }) =>
        filled &&
        css`
            transform: scale(0.9);
        `}

    ${StyledInput}:focus ~ & {
        transform: scale(0.9);
        top: -8px;
        ${props =>
            !!props.decreaseLabelFontSize && `font-size: 11px; top: -6px;`}
    }
    &.is-disabled {
        color: ${props =>
            !!Themes[props.colorTheme].disabledLabelColor
                ? Themes[props.colorTheme].disabledLabelColor
                : props.theme.Colors.cloudyBlue};
    }

    &.login-input-label {
        font-size: ${props => props.filled ? '11px' : props.fontSize};
        top: ${props => (props.filled ? '-12px' : '0')};
        transform: none;
    }
    ${StyledInput}.login-input:-webkit-autofill ~ & {
        font-size: 11px;
        top: -12px;
    }
    ${StyledInput}.login-input:focus ~ & {
        transform: none;
        font-size: 11px;
        top: -12px;
    }
`;

export const ShowPassword = styled.div<{ fontSize?: string }>`
    font-size: ${props => (props.fontSize ? props.fontSize : '11px')};
    font-weight: 600;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
    padding: 0 24px 0 12px;

    cursor: pointer;
    user-select: none;
    color: ${props => props.theme.Colors.darkSkyBlue};

    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
`;

export const ClearInput = styled.div<{
    colorTheme: ColorTheme;
    shown: boolean;
    top?: string;
}>`
    display: ${({ shown }) => (shown ? 'flex' : 'none')};
    flex-basis: 12px;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
    padding: 0 16px 0 12px;
    cursor: pointer;
    box-sizing: content-box;
    color: ${({ colorTheme }) => Themes[colorTheme].color};

    &:focus {
        opacity: 0.7;
    }
`;

export const PasswordEye = styled.div<{ shouldShowPassword: boolean }>`
    flex-shrink: 0;
    height: 100%;
    width: 48px;
    cursor: pointer;
    user-select: none;
    background-image: ${({ shouldShowPassword }) =>
        shouldShowPassword ? `url(${HideIcon})` : `url(${ShowIcon})`};
    background-repeat: no-repeat;
    background-position: center left;
`;
