import styled from 'styled-components';

export const Container = styled.div`
    padding: 24px;
    width: 440px;
`;

export const TipText = styled.div`
    padding-top: 8px;
    color: var(--ps-black-main);
    font-size: 13px;
`;

export const Buttons = styled.div`
    padding-top: 24px;
    width: 330px;
    display: flex;
    justify-content: space-between;
`;
