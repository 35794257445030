import { FC } from 'react';

import { ListActionsFieldProps } from './types';

const ListActionsField: FC<ListActionsFieldProps> = props => {
    const { row, getListActionsComponent } = props;

    return <>{getListActionsComponent?.(row)}</> ?? <></>;
};

export default ListActionsField;

