import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../../theme/variables';

import {
    useResetPractisSets,
    useSavePractisSets,
    useSelectMultiplePractisSets,
} from '../../../../features/portablePractisSets/store/hors/withPractisSets/services';
import { AssignPractisSets } from '../../../../features/portablePractisSets/templates/AssignPractisSets';
import { usePractisSetsState } from '../../../../features/portablePractisSets/store/hors/withPractisSets/states';
import { usePortablePractisSetsState } from '../../../../features/portablePractisSets/store/states';
import { Popup } from '../../../../ui/components/Popup';
import { Tooltip } from '../../../../ui/components/Tooltip';
import { PractisSetWithDueDate } from '../../../../constants/interfaces/Draft';
import { PractisSetStatuses } from '../../../../constants/interfaces/PractisSets';


const Container = styled.div`
    position: relative;
    height: 100%;
`;

const SelectHead = styled.div<{ disabled?: boolean }>`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const SelectTitle = styled.div`
    pointer-events: none;
    font-size: 11px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: block;
    align-items: center;
    color: ${props => props.theme.Colors.cloudyBlue};
    font-weight: 600;
    margin-left: 15px;
`;

const SelectButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const SelectLabel = styled.div<{ color?: string; disabled?: boolean }>`
    pointer-events: none;
    font-size: 13px;
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.disabled ? props.theme.Colors.lightBlueGray : props.color};
    font-weight: 500;
    margin-left: 15px;
    margin-bottom: 1px;
`;

const SelectedUserPractisSetsComponent: FC<{
    selectedPractisSets: PractisSetWithDueDate[];
    onSelectPractisSets(practisSetIds?: PractisSetWithDueDate[]): void;
    dataTest: string;
}> = ({ selectedPractisSets, onSelectPractisSets, dataTest }) => {
    const { saved } = usePractisSetsState();
    const { data } = usePortablePractisSetsState();
    const savePractisSets = useSavePractisSets();
    const selectMultiplePractisSets = useSelectMultiplePractisSets();
    const resetPractisSets = useResetPractisSets();

    useEffect(() => {
        selectMultiplePractisSets(selectedPractisSets);
        savePractisSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMultiplePractisSets]);

    useEffect(() => {
        return () => {
            resetPractisSets();
        };
    }, [resetPractisSets]);

    useEffect(() => {
        onSelectPractisSets(saved);
    }, [onSelectPractisSets, saved]);

    const practisSetsDisabled = !data || data.items.filter(item => item.status === PractisSetStatuses.ACTIVE).length < 1;

    return (
        <Container>
            <Popup<HTMLDivElement>
                content={({ hide }) => (
                    <AssignPractisSets
                        onSuccessApply={() => {
                            hide();
                        }}
                    />
                )}
            >
                {(ref, { toggleShown }) => (
                    <span ref={ref}>
                        <Tooltip
                            label={'No practis sets added yet'}
                            preset={'table-item'}
                            disabled={!practisSetsDisabled}
                            dataTest={`${dataTest}-no-result`}
                        >
                            <SelectHead
                                onClick={() =>
                                    !practisSetsDisabled && toggleShown()
                                }
                                disabled={practisSetsDisabled}
                                data-test={dataTest}
                            >
                                {selectedPractisSets &&
                                    !!selectedPractisSets.length && (
                                        <SelectTitle>Practis Sets</SelectTitle>
                                    )}
                                <SelectButtonWrapper>
                                    <SelectLabel
                                        color={
                                            selectedPractisSets.length < 1
                                                ? Variables.Colors.cloudyBlue
                                                : Variables.Colors.black
                                        }
                                        disabled={practisSetsDisabled}
                                    >
                                        {selectedPractisSets.length < 1
                                            ? 'Practis Sets'
                                            : `${selectedPractisSets.length} ${
                                                  selectedPractisSets.length > 1
                                                      ? 'Practis Sets'
                                                      : 'Practis Set'
                                              }`}
                                    </SelectLabel>
                                </SelectButtonWrapper>
                            </SelectHead>
                        </Tooltip>
                    </span>
                )}
            </Popup>
        </Container>
    );
};

export default SelectedUserPractisSetsComponent;
