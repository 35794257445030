import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { PRACTIS_SET_DRAGGABLE_ID } from '../../Content';
import secondsToMin from '../../../../../../../../helpers/functions/convert-time';
import styled from 'styled-components';
import { Variables } from '../../../../../../../../theme/variables';
import { PractisSetContent } from '../../../../../../../../constants/interfaces/PractisSets';
import { checkIsPractisContentDisabled } from './helpers';
import { Scenario } from '../../../../../../../../constants/interfaces/Scenario';
import { Challenge } from '../../../../../../../../constants/interfaces/Challenge';

const StyledListItemRow = styled.div<{
    disabled?: boolean;
    isDragging: boolean;
    background?: string;
}>`
    background: ${props =>
        !!props.background
            ? props.background
            : props.disabled
            ? Variables.Colors.lighterGray
            : Variables.Colors.white};
    border-radius: ${props => (props.isDragging ? '16px' : '2px')};
    display: flex;
    height: 48px;
    align-items: center;
    font-size: 13px;
    border-radius: 4px;
    &:hover {
        background: ${props => props.theme.Colors.whiteTwo};
    }
`;

const StyledListTitleItemCell = styled.div<{
    disabled?: boolean;
    isDragging: boolean;
    flex: number;
}>`
    color: ${props =>
        props.disabled
            ? props.theme.Colors.coolGray
            : props.theme.Colors.black};
    ${props =>
        !props.disabled &&
        `
    cursor: grab;
    `};
    padding: 8px 16px 8px 17px;
    flex: ${props => props.flex};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledListItemCell = styled.div<{
    disabled?: boolean;
    isDragging: boolean;
    flex: number;
}>`
    color: ${props =>
        props.disabled
            ? props => props.theme.Colors.coolGray
            : props.theme.Colors.black};
    ${props =>
        !props.disabled &&
        `
    cursor: grab;
    `};
    padding: 8px 16px 8px 17px;
    flex: ${props => props.flex};
`;

export const GenerateTableCells: FC<{
    content: Scenario[] | Challenge[];
    practisSetContent: PractisSetContent[];
    mode: string;
    background?: string;
    addToBuilder?: (itemId?: number | string) => void;
    dataTest?: string;
}> = ({
    content,
    practisSetContent,
    mode,
    background,
    addToBuilder,
    dataTest
}): any => {
    return content.map((item: Scenario | Challenge, index: number) => {
        const isDisabled =
            checkIsPractisContentDisabled(practisSetContent, item, mode) ??
            false;


        return (
            <Draggable
                key={item.id}
                draggableId={`${PRACTIS_SET_DRAGGABLE_ID}-${item.id}`}
                index={index}
                isDragDisabled={isDisabled}
            >
                {(provided: any, snapshot: any) => {
                    return (
                        <StyledListItemRow
                            provided={provided}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            disabled={isDisabled}
                            isDragging={snapshot.isDragging}
                            background={background}
                            onDoubleClick={() =>
                                !isDisabled &&
                                addToBuilder &&
                                addToBuilder(item.id)
                            }
                            data-test={dataTest}
                        >
                            <StyledListTitleItemCell
                                disabled={isDisabled}
                                isDragging={snapshot.isDragging}
                                flex={62}
                                data-test={dataTest && `${dataTest}-title`}
                            >
                                {item.title}
                            </StyledListTitleItemCell>
                            <StyledListItemCell
                                disabled={isDisabled}
                                isDragging={snapshot.isDragging}
                                flex={38}
                                data-test={dataTest && `${dataTest}-duration`}
                            >
                                {item.script.totalDuration
                                    ? secondsToMin(item.script.totalDuration)
                                    : '-'}
                            </StyledListItemCell>
                        </StyledListItemRow>
                    );
                }}
            </Draggable>
        );
    });
};
