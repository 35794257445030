import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../ui/components/Button';
import { Fieldset } from '../../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../../portableLabels';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import { useSaveTeams } from '../../../portableTeams/store/hors/withTeams/services';
import { useTeamsState } from '../../../portableTeams/store/hors/withTeams/states';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import PortableRolesWithMultiSelect from '../../../portableRoles/portableRolesWithMultiSelect';
import { PortableInvitersWithStore } from '../../../portableInviters';
import { getInvitersState } from '../../../inviters/store/reducers';
import { useSelector } from 'react-redux';
import { selectMultipleInvitersAction } from '../../../inviters/store/actions';
import { useDeSelectAllInvitersAction, useSaveInvitersAction } from '../../../inviters/store/services';
import { useInvitersState } from '../../../inviters/store/states';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const CustomFieldContainer = styled.div`
    width: 208px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const RoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 152px;
    background-color: inherit;
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

export const InvitationsFilter: FC<{
    preSelectedLabels: number[];
    onLabelsChange: (ids: number[]) => void;
    onRoleChange: (status: number[]) => void;
    onInvitersChange: (ids: number[]) => void;
    onSuccessApply: () => void;
    preSelectedRoles: number[];
}> = ({
    preSelectedLabels,
    onLabelsChange,
    onRoleChange,
    onInvitersChange,
    onSuccessApply,
    preSelectedRoles,
}) => {
    const [selectedRoles, setSelectedRoles] = useState(preSelectedRoles);
    const teams = useTeamsState();
    const labels = useLabelsState();
    const inviters = useInvitersState();
    const invitersState = useSelector(getInvitersState);
    const selectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const saveTeams = useSaveTeams();
    const saveInviters = useSaveInvitersAction();
    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);
    const selectMultipleInviters = selectMultipleInvitersAction;
    const deselectAllInviters = useDeSelectAllInvitersAction();

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labels.selected);
        onRoleChange(selectedRoles);
        onInvitersChange(inviters.selected);
        saveLabels();
        saveTeams();
        saveInviters();
        onSuccessApply();
    }, [
        onLabelsChange,
        labels.selected,
        onRoleChange,
        selectedRoles,
        onInvitersChange,
        inviters.selected,
        saveLabels,
        saveTeams,
        saveInviters,
        onSuccessApply,
    ]);

    const totalFiltersSelected =
        teams.selected.length +
        labels.selected.filter((id: any) => id > 0).length +
        selectedRoles.length +
        invitersState.selected.length;

    const onClearHandler = useCallback(() => {
        setSelectedRoles([]);
        selectLabels([]);
        selectMultipleInviters([]);
        deselectAllInviters();
    }, [selectLabels, selectMultipleInviters, deselectAllInviters]);

    return (
        <Container>
            <Wrapper>
                <Fieldset title="Role" dataTest="roles-section-title">
                    <RoleContainer>
                        <PortableRolesWithMultiSelect
                            selectedRoles={selectedRoles}
                            setSelectedRoles={setSelectedRoles}
                        />
                    </RoleContainer>
                </Fieldset>
                <Fieldset title="Invited by" dataTest="inviters-section-title">
                    <CustomFieldContainer>
                        <PortableInvitersWithStore
                            fetchOnMount={false}
                            onSetSelected={selectMultipleInviters}
                            resetSelectedOnUnmount={false}
                        />
                    </CustomFieldContainer>
                </Fieldset>
                <Fieldset title="Labels" dataTest="labels-section-title">
                    <CustomFieldContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </CustomFieldContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="total-selected-filters-text">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        width="122px"
                        variant="inverse"
                        action={onClearHandler}
                        disabled={!totalFiltersSelected}
                        dataTest="clear-button"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width="122px"
                    action={onApplyHandler}
                    dataTest="apply-filter-button"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
