export enum PacingType {
    SEQUENTIAL = 'SEQUENTIAL',
    FREE = 'FREE',
}

const pacingTypeNames = {
    [PacingType.SEQUENTIAL as string]: 'Sequential',
    [PacingType.FREE as string]: 'Free-Form',
};

export function pacingTypeName(type?: string): string {
    return (type && pacingTypeNames[type]) || type || '';
}
