import { FC, useCallback, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSearchParamsState } from '../../../../../constants/interfaces/filters';
import { useTableStateHelper } from '../../../../../ui/components/table-wrapper/helper';
import { initialSearchParams } from './constants';
import { useClearScenarionRepsService, useGetScenarioRepsService } from './store/services';
import { useScenarioRepsState } from './store/states';
import { usePractisSetReportState } from '../../../store/states';
import { ScenarioRepsView } from './view';
import { ScenarioRepPropsType } from './types';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

const ScenarioRepsController: FC<ScenarioRepPropsType> = ({closePath, closeGoingBack}) => {
    const { data: reps, isLoading } = useScenarioRepsState();
    const handleTableStates = useTableStateHelper();
    const [lastRefreshed, setLastRefreshed] = useState(new Date());

    const params: {
        userId?: string;
        enrollmentId?: string;
        scenarioId?: string;
    } = useParams();

    const { userId, enrollmentId, scenarioId } = params;
    const { enrollment, progress: setProgress } = usePractisSetReportState();

    let repGeneralNumber: number = 0;
    let scenarioName = '';
    let creator = '';

    if (enrollment && enrollmentId && scenarioId && enrollment.id === +enrollmentId) {
        const submission = setProgress?.items.find(({ content }) => content.scenarioId === +scenarioId);

        if (submission) {
            const { content, progress } = submission;

            scenarioName = content.scenario?.title || '';
            repGeneralNumber = progress?.countReps || 0;
            creator = content.scenario?.creator?.firstName + ' ' + content.scenario?.creator?.lastName;
        }
    }

    const tableStates = handleTableStates({
        itemsCount: reps?.items?.length || 0,
    });

    const { searchParams, setOffset } =
        useSearchParamsState(initialSearchParams);

    const searchScenarioReps = useGetScenarioRepsService();
    const clearScenarioReps = useClearScenarionRepsService();

    useEffect(() => {
        if (enrollmentId && scenarioId) {
            searchScenarioReps(searchParams, enrollmentId, scenarioId);
        }
        return () => {
            clearScenarioReps();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = useCallback(
        (_, offset: number): void => {
            setOffset(offset);
            enrollmentId && scenarioId && searchScenarioReps({ ...searchParams, offset }, enrollmentId, scenarioId);
        },
        [searchParams, setOffset, searchScenarioReps, enrollmentId, scenarioId]
    );

    /**
    * @function refreshData
    * @returns { void }
    */
   const refreshData = useCallback(() => {
           enrollmentId && scenarioId && searchScenarioReps(searchParams, enrollmentId, scenarioId);
           setLastRefreshed(new Date());
   }, [searchScenarioReps, searchParams, enrollmentId, scenarioId]);

   useHtmlPageTitle('Rep log', [creator, scenarioName]);

    return <ScenarioRepsView
        closePath={closePath
            .replace(':userId', userId ?? '')
            .replace(':enrollmentId', enrollmentId ?? '')
            .replace(':scenarioId', scenarioId ?? '')
        }
        closeGoingBack={closeGoingBack}
        scenarioName={scenarioName}
        tableStates={tableStates}
        reps={reps}
        isLoading={isLoading}
        lastRefreshed={lastRefreshed}
        refreshData={refreshData}
        handlePageChange={handlePageChange}
        repGeneralNumber={repGeneralNumber}
    />;
}

export default withRouter(ScenarioRepsController);