import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Fieldset } from '../Fieldset';

interface Props {
    title?: string;
    width?: number;
    minHeight?: number;
    dataTest?: string;
}

export const FiltersFieldSet: FC<Props> = props => {
    const { title, width, minHeight, dataTest, children } = props;
    return (
        <Fieldset title={title} dataTest={dataTest}>
            <Body
                width={width}
                minHeight={minHeight}
                data-test={dataTest && `${dataTest}-body`}
            >
                <Content data-test={dataTest && `${dataTest}-content`}>
                    {children}
                </Content>
            </Body>
        </Fieldset>
    );
};

interface BodyProps {
    width?: number;
    minHeight?: number;
}

const Body = styled.div<BodyProps>`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    ${props =>
        !!props.width &&
        css`
            width: ${props.width}px;
        `}

    ${props =>
        !!props.minHeight &&
        css`
            min-height: ${props.minHeight}px;
        `}
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
