import { FiltersSidebar } from '../../../features/filters/filters-sidebar';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import { PractisSetSummaryReportFiltersConfig } from './constants';
import { PractisSetSummaryReportSubHeader } from './styles';
import { PractisSetSummaryReportViewProps } from './types';

function PractisSetSummaryReportView(props: PractisSetSummaryReportViewProps) {
    const {
        onClearFilters,
        onGenerateReport,
        controlPanelMode,
        remainingTimeout,
    } = props;

    return (
        <MainWrapper
            title="Reports"
            subTitle="Practis Set Summary"
            subHeader={
                <PractisSetSummaryReportSubHeader data-test="practis-set-summary-page-description">
                    Understand progress for a specific Practis Set.
                </PractisSetSummaryReportSubHeader>
            }
            htmlPageTitle="Reports - Practis"
            useGoBack
            headerMinHeight="124px"
            headerPadding="0 24px"
            contentPadding="0"
            isScrollable={false}
            contentSize={false}
            dataTest="practis-set-summary"
        >
            <FiltersSidebar
                filtersConfig={PractisSetSummaryReportFiltersConfig}
                onClearFilters={onClearFilters}
                onGenerateReport={onGenerateReport}
                controlPanelMode={controlPanelMode}
                remainingTimeout={remainingTimeout}
                headerHeight="124px"
            />
        </MainWrapper>
    );
}

export default PractisSetSummaryReportView;