import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../ui/components/Checkbox';
import { handleToggleSelectedItems } from '../../../pages/ActionPages/NewUser/tools';
import { Inviter } from '../../../constants/interfaces/Inviter';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';

const StyledInvitersList = styled.div`
    overflow: auto;
    height: 100%;
`;

const TopSideList = styled.span``;

const GeneralList = styled.span``;

const InviterItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;
const YouWrapper = styled.span`
    font-size: 13px;
    color: ${({ theme }) => theme.Colors.steelGrey};
`;
const InviterItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;
export const PortableInvitersList: FC<{
    inviters?: Inviter[];
    selectedInviters: number[];
    disabled?: boolean;
    setInvitersFilters?: (inviterId: any[]) => void;
}> = ({ inviters, selectedInviters, setInvitersFilters }) => {
    const isChecked = useCallback(
        (setId?: number) => {
            return (
                !!selectedInviters &&
                !!setId &&
                selectedInviters.includes(setId)
            );
        },
        [selectedInviters]
    );

    const isPartiallyChecked = useCallback(
        (invitersId?: number) => {
            return (
                !!selectedInviters &&
                !!invitersId &&
                selectedInviters.includes(-Number(invitersId))
            );
        },
        [selectedInviters]
    );

    const profile = useSelector(getProfileState);

    const changeHandler = (inviterId: number) => {
        if (inviterId && setInvitersFilters) {
            setInvitersFilters(
                handleToggleSelectedItems(selectedInviters, inviterId)
            );
        }
    };

    if (!inviters || (inviters && inviters.length === 0)) return null;

    const topListInviters = inviters.filter(
        inviter => inviter.id && selectedInviters.includes(inviter.id)
    );

    const bottomListInviters = inviters.filter(
        inviter => inviter.id && !selectedInviters.includes(inviter.id)
    );

    return (
        <StyledInvitersList>
            {topListInviters.length > 0 && (
                <TopSideList>
                    {topListInviters.map(inviter => {
                        return (
                            <InviterItemWrapper key={inviter.id}>
                                <Checkbox
                                    size={12}
                                    checked={
                                        isChecked(inviter.id) ||
                                        isPartiallyChecked(inviter.id)
                                    }
                                    partial={isPartiallyChecked(inviter.id)}
                                    handleChange={() =>
                                        changeHandler(inviter.id)
                                    }
                                    dataTest="selected-inviters-item-checkbox"
                                />
                                <InviterItem
                                    onClick={() => changeHandler(inviter.id)}
                                    data-test="selected-inviters-item-text"
                                >
                                    {inviter.firstName} {inviter.lastName}
                                </InviterItem>
                            </InviterItemWrapper>
                        );
                    })}
                    {topListInviters.length < inviters.length && (
                        <TableDivider width="98%" />
                    )}
                </TopSideList>
            )}
            <GeneralList>
                {bottomListInviters.map(inviter => {
                    return (
                        <InviterItemWrapper key={inviter.id}>
                            <Checkbox
                                size={12}
                                checked={
                                    isChecked(inviter.id) ||
                                    isPartiallyChecked(inviter.id)
                                }
                                partial={isPartiallyChecked(inviter.id)}
                                handleChange={() => changeHandler(inviter.id)}
                                dataTest="inviters-item-checkbox"
                            />
                            <InviterItem
                                onClick={() => changeHandler(inviter.id)}
                                data-test="inviters-item-text"
                            >
                                {inviter.firstName} {inviter.lastName}
                                {profile && profile.id === inviter.id && (
                                    <YouWrapper>(You)</YouWrapper>
                                )}
                            </InviterItem>
                        </InviterItemWrapper>
                    );
                })}
            </GeneralList>
        </StyledInvitersList>
    );
};
