import { useCallback, useMemo } from "react";
import { useMonthFilterState } from "./store/reducers";
import { useSelectMonth, useSelectYear } from "./store/services";
import MonthFilterView from "./view";
import { useSetQueryParam } from "../../../../helpers/hooks/useSetQueryParam";
import { MONTH_DATE_QUERY_PARAM_NAME } from "./constants";
import useQueryParamListener from "../../../../helpers/hooks/useQueryParamListener";
import dayjs from "dayjs";

function MonthFilter() {
    const { selectedYear, selectedMonth, currentYear, isDisabled } = useMonthFilterState();
    const selectYear = useSelectYear();
    const selectMonth = useSelectMonth();
    const setQueryParam = useSetQueryParam();

    const presentYear = useMemo(() => new Date().getFullYear(), []);
    const currentOrPresentYear = currentYear ?? selectedYear ?? presentYear;

    const handleSelectMonth = useCallback((month) => {
        setQueryParam(
            MONTH_DATE_QUERY_PARAM_NAME,
            dayjs(new Date(currentOrPresentYear, month, 1)).format('YYYY-MM-DD')
        );
        selectMonth(currentOrPresentYear, month);
    }, [currentOrPresentYear, selectMonth, setQueryParam]);

    useQueryParamListener(MONTH_DATE_QUERY_PARAM_NAME, value => {
        if (value) {
            const date = dayjs(value);
            if (date.isValid() && (date.year() !== selectedYear || date.month() !== selectedMonth)) {
                selectMonth(date.year(), date.month());
            }
        }
    })
    
    return (
        <MonthFilterView
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            currentYear={currentOrPresentYear}
            isDisabled={isDisabled}
            onSelectYear={selectYear}
            onSelectMonth={handleSelectMonth}
        />
    );
}

export default MonthFilter;