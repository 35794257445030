import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from '../../../tools/router';
import { isEmpty } from 'lodash';

import { ReportType } from '../../../constants/interfaces/ReportItem';
import { useClearPractisSetFilter, useDisablePractisSetFilter } from '../../../features/filters/dynamic-filter/practis-set-filter/store/services';
import { useSingleTeamFilterState } from '../../../features/filters/dynamic-filter/single-team-filter/store/reducers';
import { useClearSingleTeamFilter, useDisableSingleTeamFilter } from '../../../features/filters/dynamic-filter/single-team-filter/store/services';
import { useGenerateReportService } from '../../../features/filters/filters-sidebar/services';
import { useClearTrainerPractisSet } from '../../../features/teams/store/services';
import { useTrainerPractisSetState } from '../../../features/teams/store/states';
import { ReportingControlPanelMode } from '../../../ui/components/ReportingControlPanel/types';
import PractisSetSummaryReportView from './view';
import { usePractisSetFilterState } from '../../../features/filters/dynamic-filter/practis-set-filter/store/reducers';
import ROUTES from '../../../routes/routes';

function PractisSetSummaryReportController() {
    const { isGenerating, remainingTimeout, generateReport } =
        useGenerateReportService();
    const clearTrainerPractisSetData = useClearTrainerPractisSet();
    const clearSingleTeamFilter = useClearSingleTeamFilter();
    const disableSingleTeamFilter = useDisableSingleTeamFilter();
    const clearPractisSetFilter = useClearPractisSetFilter();
    const disablePractisSetFilter = useDisablePractisSetFilter();
    const { selectedTeamId } = useSingleTeamFilterState();
    const { selectedPractisSetId } = usePractisSetFilterState();
    const { data } = useTrainerPractisSetState();
    const history = useHistory();
    
    const onClearFilters = useCallback(
        (isCalledFromUnmount?: boolean) => {
            Promise.resolve().then(() => {
                clearTrainerPractisSetData();
                clearSingleTeamFilter();
                clearPractisSetFilter();
            });
            
            if (!isCalledFromUnmount) {
                history.replace(ROUTES.REPORTS.PRACTIS_SET_SUMMARY_REPORT);
            }
        },
        [clearTrainerPractisSetData, clearSingleTeamFilter, clearPractisSetFilter, history]
    );

    const controlPanelMode: ReportingControlPanelMode = useMemo(() => {
        const statusMap = {
            timeout: () => remainingTimeout,
            disabled: () => !selectedTeamId || !selectedPractisSetId,
            loading: () => isGenerating,
            noData: () => isEmpty(data?.items),
        };

        for (const [status, condition] of Object.entries(statusMap)) {
            if (condition()) {
                return status as ReportingControlPanelMode;
            }
        }

        return 'enabled';
    }, [
        remainingTimeout,
        selectedTeamId,
        selectedPractisSetId,
        isGenerating,
        data?.items,
    ]);

    const handleGeneratePractisSetSummaryReport = useCallback(() => {
        if (selectedTeamId && selectedPractisSetId) {
            generateReport(ReportType.PRACTIS_SET_SUMMARY, {
                team: selectedTeamId,
                practisSet: selectedPractisSetId,
            });
        }
    }, [generateReport, selectedPractisSetId, selectedTeamId]);

    useEffect(() => {
        clearTrainerPractisSetData();

        return () => {
            onClearFilters(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        disableSingleTeamFilter(isGenerating);
        disablePractisSetFilter(isGenerating);
    }, [disablePractisSetFilter, disableSingleTeamFilter, isGenerating]);

    return (
        <PractisSetSummaryReportView
            onClearFilters={onClearFilters}
            onGenerateReport={handleGeneratePractisSetSummaryReport}
            controlPanelMode={controlPanelMode}
            remainingTimeout={remainingTimeout}
        />
    );
}

export default PractisSetSummaryReportController;