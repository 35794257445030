import { FC } from 'react';
import styled from 'styled-components';

import { TableCell } from '../table-cell';

const StyledTableCell = styled(TableCell)`
    padding: 0;
    border-bottom: 1px solid ${props => props.theme.Colors.paleGrey};
`;

export const TableCheckboxHeaderCell: FC<{ width?: number, dataTest?: string; }> = ({
    children,
    width,
    dataTest
}) => {
    return <StyledTableCell width={width} dataTest={dataTest}>{children}</StyledTableCell>;
};
