import React, { FC } from 'react';
import styled from 'styled-components';
import { CompanyInterface } from '../../../constants/interfaces/Company';
import { UserInterface } from '../../../constants/interfaces/User';

const StyledAvatarContainer = styled.div<{
    disabled?: boolean;
}>`
    font-size: 13px;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: ${props => props.theme.Colors.whiteFive};
    color: ${props => props.theme.Colors.black};
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    border: solid 1px ${props => props.theme.Colors.cloudyBlue};
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
`;

export interface AvatarPlaceholderInterface {
    profile: (UserInterface & CompanyInterface) | undefined;
    loading?: boolean;
    link?: string;
    disabled?: boolean;
    className?: string;
    backgroundColor?: string;
}

const NamePlaceholder: FC<{
    image?: string;
    name?: string;
    disabled?: boolean;
    className?: string;
    dataTest?: string;
}> = ({ image, name, disabled, className, dataTest }) => {
    if (image) {
        return (
            <StyledAvatarContainer disabled={disabled} className={className}>
                <StyledImage src={image} alt={'profile'} data-test={dataTest} />
            </StyledAvatarContainer>
        );
    } else {
        return (
            <StyledAvatarContainer disabled={disabled} className={className} data-test={dataTest}>
                {name && name[0].toUpperCase()}
            </StyledAvatarContainer>
        );
    }
};

export default NamePlaceholder;
