import { useSelector } from 'react-redux';

import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { FiltersSidebarControllerProps } from './types';
import FiltersSidebarView from './view';

function FiltersSidebarController(props: FiltersSidebarControllerProps) {
    const {
        filtersConfig,
        controlPanelMode,
        onClearFilters,
        onGenerateReport,
        remainingTimeout,
        headerHeight,
    } = props;
    const meProfile = useSelector(getProfileState);

    return (
        <FiltersSidebarView
            filtersConfig={filtersConfig}
            onGenerateReport={onGenerateReport}
            onClearFilters={onClearFilters}
            userEmail={meProfile?.email || ''}
            controlPanelMode={controlPanelMode}
            remainingTimeout={remainingTimeout}
            headerHeight={headerHeight}
        />
    );
}

export default FiltersSidebarController;