import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Team = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 20 20"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <g>
                                <path
                                    d="M19.002 15H19zM19 16.25zM7.354 0c3.87 0 4.73 2.429 4.73 5.424 0 2.307-1.123 4.756-2.938 5.768.704.176 4.32 1.099 4.767 1.525.815.778 1.14 6.116.324 7.285L7.387 20l-6.913.002c-.821-1.169-.488-6.507.333-7.285.457-.433 3.963-1.315 4.777-1.515-1.828-1.004-2.96-3.461-2.96-5.778 0-2.995.86-5.424 4.73-5.424zm0 1C4.259 1 3.729 2.98 3.729 5.42c0 2.032 1.005 4.278 2.654 4.98v1.854h-.531c-2.027.439-3.735 1.05-4.28 1.326-.346.405-.568 4.36-.353 5.17l6.168-.001 6.109.001c.214-.81-.001-4.762-.343-5.169-.547-.271-2.23-.884-4.232-1.327h-.648v-1.855c.387-.165.738-.415 1.049-.727.325-.326.606-.72.838-1.156.493-.925.764-2.04.764-3.096 0-2.441-.475-4.42-3.57-4.42zM19.176 15.717c.815.778 1.14 3.115.325 4.283l-4.186.002c.12-.349.21-.776.272-1.252h3.105c.214-.81-.001-1.762-.343-2.169-.377-.187-1.436-.537-2.714-.882-.03-.397-.075-.786-.133-1.157 1.422.359 3.369.883 3.674 1.175zM12.822 3.004L12.617 3c1.737 0 2.867.489 3.586 1.298.883.995.784 2.474.784 4.126 0 1.769-.644 3.62-1.762 4.845-.128-.438-.281-.803-.458-1.06.768-1.02 1.204-2.45 1.204-3.789 0-2.38-.106-4.322-2.982-4.416-.04-.351-.095-.684-.167-1z"
                                    transform="translate(-1200 -302) translate(1168 80) translate(32 222)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default Team;
