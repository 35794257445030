import { MonthItem } from '../month-item';
import { MONTHS } from './constants';
import { Container } from './styles';
import { MonthSelectorViewProps } from './types';

function MonthSelectorView({
    isDisabledMonth,
    isSelectedMonth,
    isCurrentMonth,
    onSelectMonth,
}: MonthSelectorViewProps) {
    return (
        <Container>
            {MONTHS.map((title, index) => (
                <MonthItem
                    title={title}
                    isDisabled={isDisabledMonth(index)}
                    isSelected={isSelectedMonth(index)}
                    onSelect={() => onSelectMonth(index)}
                    isCurrent={isCurrentMonth(index)}
                />
            ))}
        </Container>
    );
}

export default MonthSelectorView;