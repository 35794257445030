import { memo } from 'react';

import { StripedProgressBarInterface } from './types';

function StripedProgressBarView(props: StripedProgressBarInterface) {
    const { percentage = 0, dataTest } = props;

    return (
        <div className="progress-container">
            <div className="progress-title" data-test={dataTest && `${dataTest}-completed-value`}>Completed {percentage}%</div>
            <div className="progress progress-striped active">
                <div
                    style={{ width: `${percentage}%` }}
                    className="progress-bar"
                    data-test={dataTest && `${dataTest}-bar`}
                ></div>
            </div>
        </div>
    );
}

export default memo(StripedProgressBarView);
