import { FC, memo } from 'react';

import { TableProps } from './type';
import { TableCont, ScrollContainer, TableInner } from './style';
import { TableHeader } from './table-header';
import { TableBodyWrapper } from './table-body-wrapper';
import { EmptyStateWrapper } from './empty-state';

const TableSimpleView: FC<TableProps> = props => {
    const {
        data,
        tableStates,
        addOverflow,
        tableContainerClassName,
        tableInnerClassName,
        tableEmptyStateConfigurations,
        configuration,
    } = props;

    const { rowConfig } = configuration;

    const {
        shouldShowEmptyState,
        noEntriesOptions,
        noSearchResultsOptions,
        noFilterResultsOptions,
        noCalendarResultsOptions,
    } = tableEmptyStateConfigurations || {};

    return (
        <TableCont className={tableContainerClassName}>
            <ScrollContainer addOverflow={addOverflow}>
                <TableInner className={tableInnerClassName}>
                    <TableHeader headerConfiguration={configuration} />

                    {!tableStates.showEmptyState && (
                        <TableBodyWrapper data={data} rowConfig={rowConfig} />
                    )}

                    {shouldShowEmptyState && tableStates.showEmptyState && (
                        <EmptyStateWrapper
                            tableStates={tableStates}
                            noEntriesOptions={noEntriesOptions}
                            noSearchResultsOptions={noSearchResultsOptions}
                            noFilterResultsOptions={noFilterResultsOptions}
                            noCalendarResultsOptions={noCalendarResultsOptions}
                        />
                    )}
                </TableInner>
            </ScrollContainer>
        </TableCont>
    );
};

export default memo(TableSimpleView);
