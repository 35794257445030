import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { TeamBasic } from '../../../constants/interfaces/TeamBasic';
import { useSelector } from 'react-redux';

type PortableTeamsBaseState = {
    data: PaginationResult<TeamBasic>;
    loading: boolean;
};

export type PortableTeamsState = PortableTeamsBaseState;

export const initialState = {
    data: {
        items: [],
        limit: 0,
        offset: 0,
        numberOfPages: 0,
        totalCount: 0,
        lastUpdated: undefined,
    },
    loading: false,
};

export const usePortableTeamsState = (): PortableTeamsBaseState => {
    const appState = useSelector(state => state);
    return appState.portableTeams;
};
