import React, { FC } from 'react';
import { UserInterface, UserProfile } from '../../../constants/interfaces/User';
import AvatarPlaceholder from '../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import styled from 'styled-components';

const StyledDetailsHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 8px;
`;

const StyledAvatar = styled.div`
    width: 32px;
    height: 32px;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const StyledName = styled.div`
    margin-left: 16px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: normal;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const StyledAdditionalInfo = styled.span`
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.steelGrey};
    margin-left: 3px;
`;

const DetailsHeader: FC<{
    user?: UserInterface;
    profile?: UserProfile;
    action: (traineeId?: number) => void;
}> = ({ user, profile, action }) => {
    return (
        <StyledDetailsHeader>
            <StyledAvatar onClick={() => user && action(user.id)}>
                <AvatarPlaceholder size="sm" profile={user} />
            </StyledAvatar>
            <StyledName onClick={() => user && action(user.id)}>
                {user && `${user.firstName} ${user.lastName}`}
                {profile && user && user.id === profile.id && (
                    <StyledAdditionalInfo> (You)</StyledAdditionalInfo>
                )}
            </StyledName>
        </StyledDetailsHeader>
    );
};

export default DetailsHeader;
