import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Keywords } from '../../../constants/interfaces/Keywords';
import {
    useGetSelectionCharacterOffsetWithin,
    useSetCaretPosition,
} from '../../../features/keywords/hooks';
import { ContentEditable } from './ContentEditable';

const KEYWORDS_CLASS = '__keywords';

const StyledContainer = styled.div`
    flex: 1;
    height: 100%;
`;

export const ContentEditableInput: FC<{
    value: string;
    handleChange?: (value: string, caretPosition: number) => void;
    placeholder?: string;
    max?: number;
    onChange?: any;
    autoFocus?: boolean;
    viewMode?: boolean;
    selections?: Keywords[];
    setSelections?: (keyword: Keywords[]) => void;
    setCreateTooltip?: (arg: any) => void;
    setRemoveTooltip?: (arg: any) => void;
    setKeywordIdToRemove?: (arg: string) => void;
    deleteKeyword?: (id: string) => void;
    recording?: boolean;
    scriptLineId?: number | string;
    className?: string;
    onMouseUp?(ref: any): void;
    dataTest?: string;
}> = ({
    value,
    handleChange,
    placeholder,
    selections,
    autoFocus,
    viewMode,
    setCreateTooltip,
    setRemoveTooltip,
    setKeywordIdToRemove,
    recording,
    scriptLineId,
    className,
    onMouseUp,
    dataTest
}) => {
    const textareaRef = useRef<any>(null);
    const [localValue, setLocalValue] = useState(value);
    const [caretPosition, saveCaretPosition] = useState<null | number>(null);
    const setCaretPosition = useSetCaretPosition();

    const getSelectionCharacterOffsetWithin = useGetSelectionCharacterOffsetWithin();
    const timeoutRef = useRef<any>(null);
    const selectionRef = useRef<any>(null);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleTextChange = (value: string) => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (setRemoveTooltip) {
            setRemoveTooltip({
                visible: false,
            });
        }
        if (setCreateTooltip) {
            setCreateTooltip({
                visible: false,
            });
        }

        const selection = getSelectionCharacterOffsetWithin(
            textareaRef.current
        );
        saveCaretPosition(selection.end);

        selectionRef.current = selectionRef.current
            ? selection.end < selectionRef.current
                ? selection.end
                : selectionRef.current
            : selection.end;
        timeoutRef.current = setTimeout(() => {
            setLocalValue(value);
            if (handleChange) {
                handleChange(value, selectionRef.current);
            }
            setCaretPosition(textareaRef.current, selection.end);
            timeoutRef.current = null;
            selectionRef.current = selection.end;
        }, 100);
    };

    const handleTextFocus = () => {
        const selection = getSelectionCharacterOffsetWithin(
            textareaRef.current
        );
        selectionRef.current = selection.end;
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            document.execCommand('insertLineBreak');
            event.preventDefault();
        }
        if (
            event.keyCode === 37 ||
            event.keyCode === 38 ||
            event.keyCode === 39 ||
            event.keyCode === 40
        ) {
            const selection = getSelectionCharacterOffsetWithin(
                textareaRef.current
            );
            selectionRef.current = selection.end;
        }
    };

    const selectedValues = selections
        ? selections.map((selection: Keywords) => {
              return [selection.start, selection.end, selection.id];
          })
        : [];

    useEffect(() => {
        if (autoFocus) {
            textareaRef.current.focus();
        }
    }, [autoFocus]);

    useEffect(() => {
        const handleKeywordClick = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const selectionRect = e.target.getBoundingClientRect();
            setCreateTooltip && setCreateTooltip({ visible: false });
            setRemoveTooltip &&
                setRemoveTooltip({
                    visible: true,
                    top: selectionRect.top - 50,
                    left: selectionRect.left + selectionRect.width * 0.5 - 56,
                });
            if (setKeywordIdToRemove) {
                setKeywordIdToRemove(e.target.dataset.id);
            }
        };

        const keywords = document.getElementsByClassName(
            `${KEYWORDS_CLASS}_${scriptLineId}`
        );
        if (viewMode) {
            for (let keyword of Array.from(keywords)) {
                keyword.removeEventListener('click', handleKeywordClick);
            }
        } else {
            for (let keyword of Array.from(keywords)) {
                keyword.addEventListener('click', handleKeywordClick);
            }
        }
        return () => {
            for (let keyword of Array.from(keywords)) {
                keyword.removeEventListener('click', handleKeywordClick);
            }
        };
    }, [
        setCreateTooltip,
        setKeywordIdToRemove,
        setRemoveTooltip,
        viewMode,
        selections,
        scriptLineId,
    ]);

    function renderDetails(data: string): string {
        return selectedValues
            .reduce((str: any, [start, end, id]: any) => {
                str[
                    start
                ] = `<span class="${KEYWORDS_CLASS} ${KEYWORDS_CLASS}_${scriptLineId}" data-id="${id}">${str[start]}`;
                str[end] = `${str[end]}</span>`;
                return str;
            }, data.split(''))
            .join('');
    }

    useEffect(() => {
        if (caretPosition && textareaRef.current) {
            setCaretPosition(textareaRef.current, caretPosition);
        }
    }, [caretPosition, setCaretPosition]);

    return (
        <StyledContainer>
            <ContentEditable
                onKeyDown={handleKeyPress}
                innerRef={textareaRef}
                disabled={viewMode}
                value={renderDetails(localValue)}
                fontSize={recording ? '15px' : '13px'}
                onChange={handleTextChange}
                placeholder={placeholder}
                onMouseUp={() =>
                    onMouseUp && !viewMode && onMouseUp(textareaRef)
                }
                onClick={handleTextFocus}
                dataTest={dataTest}
            />
        </StyledContainer>
    );
};

export default ContentEditableInput;
