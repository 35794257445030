import { ModalWithCross } from '../../../../ui/components/ModalWithCross';
import Attempts from '../../../../ui/icons/Attempts';
import { ChallengeAttemptsLimit } from './components/ChallengeAttemptsLimit';
import {
    ModalContent,
    ModalSidebar,
    NavigationIcon,
    NavigationItem,
    TabContent
} from './styles';
import { ChallengeSettingsViewProps } from './types';

function ChallengeSettingsView({
    challenge,
    onClose
}: ChallengeSettingsViewProps) {

    return (
        <ModalWithCross
            title="Challenge Settings"
            onClose={onClose}
            width={790}
            dataTest="challenge-settings-modal"
        >
            <ModalContent>
                <ModalSidebar>
                    <NavigationItem
                        data-test="attempts-tab"
                    >
                        <NavigationIcon><Attempts /></NavigationIcon>
                        Attempts
                    </NavigationItem>
                </ModalSidebar>
                <TabContent>
                    <ChallengeAttemptsLimit challenge={challenge} />
                </TabContent>
            </ModalContent>
        </ModalWithCross>
    );
}

export default ChallengeSettingsView;