import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PauseCircle = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="7.5" stroke="#4AA9E2"/>
                <path d="M6 5.267c0-.207.222-.335.397-.229l4.476 2.733a.27.27 0 0 1 0 .458l-4.476 2.732A.262.262 0 0 1 6 10.733V5.267z" fill="#4AA9E2"/>
            </svg>
        );
    }
);

export default PauseCircle;
