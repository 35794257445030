import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoLogs = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                className={className}
                height="100%"
                fill="currentColor"
                viewBox="0 0 48 48"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M3 48h24.75v-3H4.5c-.828 0-1.5-.67-1.5-1.502V31.5c0-.828.671-1.5 1.5-1.5h9v6h14.25v-3H16.5v-6H3L9 3h30l6 24H31.5v3H48v-3L42 0H6L0 27v18c0 1.657 1.343 3 3 3zm10.5-27h21c.829 0 1.5-.672 1.5-1.5 0-.829-.671-1.5-1.5-1.5h-21c-.829 0-1.5.671-1.5 1.5 0 .828.671 1.5 1.5 1.5zm18-9h-15c-.829 0-1.5-.671-1.5-1.502C15 9.671 15.672 9 16.5 9h15c.829 0 1.5.672 1.5 1.498 0 .83-.671 1.502-1.5 1.502z"
                    clipRule="evenodd"
                />
                <path
                    fill="currentColor"
                    d="M35.5 48L33 45.749l5.147-5.249L33 35.25 35.5 33l5 5.1 5-5.1 2.5 2.25-5.148 5.25L48 45.749 45.5 48l-5-5.1-5 5.1z"
                />
            </svg>
        );
    }
);

export default NoLogs;
