import React, { FC, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ROUTES from '../../../routes/routes';
import PrivateRoute from '../../../routes/PrivateRouter';
import Completed from './Completed/Completed';
import Playback from './Playback/Playback';
import Training from './Training/Training';
import styled from 'styled-components';
import { useGetTelepromterScenarioService } from '../store/services';
import { useParams } from 'react-router-dom';
import Exit from './Exit/Exit';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    background: ${props => props.theme.Colors.darkTwo};
    justify-content: center;
    overflow: auto;
`;

const PhoneEmulator = styled.div`
    height: 810px;
    width: 375px;
    border-radius: 8px;
    margin: 48px 0;
    overflow: hidden;
`;

const Telepromter: FC = () => {
    const getScenario = useGetTelepromterScenarioService();
    const params = useParams<{ scenarioId?: string }>();
    useEffect(() => {
        if (params.scenarioId) {
            getScenario(params.scenarioId);
        }
    }, [getScenario, params.scenarioId]);

    return (
        <Container>
            <PhoneEmulator>
                <Switch>
                    <Redirect
                        from={ROUTES.TELEPROMTER}
                        exact
                        to={ROUTES.TELEPROMTER_PAGES.TRAINING}
                    />
                    <PrivateRoute
                        path={ROUTES.TELEPROMTER_PAGES.COMPLETED}
                        component={Completed}
                    />
                    <PrivateRoute
                        path={ROUTES.TELEPROMTER_PAGES.PLAYBACK}
                        component={Playback}
                    />
                    <PrivateRoute
                        path={ROUTES.TELEPROMTER_PAGES.TRAINING}
                        component={Training}
                    />
                    <PrivateRoute
                        path={ROUTES.TELEPROMTER_PAGES.EXIT}
                        component={Exit}
                    />
                </Switch>
            </PhoneEmulator>
        </Container>
    );
};

export default Telepromter;
