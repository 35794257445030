import { FC } from 'react';

import TableTitleOverflowText from '../../../TableTitleOverflowText/TableTitleOverflowText';
import { Avatar, AvatarTile, StyledFirstTableContent } from './styles';
import { AvatarFieldProps } from './types';

const AvatarField: FC<AvatarFieldProps> = props => {
    const { row, renderTitle, getAvatarProps, className, dataTest } = props;

    return (
        <StyledFirstTableContent className={className}>
            <AvatarTile>
                <Avatar dataTest={dataTest && `${dataTest}-avatar`} {...getAvatarProps?.(row)} />
                <TableTitleOverflowText dataTest={dataTest && `${dataTest}-title`}>
                    {renderTitle?.(row)}
                </TableTitleOverflowText>
            </AvatarTile>
        </StyledFirstTableContent>
    );
};

export default AvatarField;

