export interface EnvironmentConfig {
    API_BASE_URL: string;
    API_V2_BASE_URL: string;
    API_CLIENT: string;
    ADMIN_PORTAL_BASE_URL: string;
    COMPANY_PORTAL_BASE_URL: string;
    CHATGPT_API_KEY: String;
    NEW_RELIC_LICENSE_KEY: string;
    NEW_RELIC_APP_ID: string;
    APP_VERSION: string;
    COMMIT_ID?: string;
    BUILD_ID?: string;
    S3_FILES_BASE_URL?: string;
    API_SOCKET_ENDPOINT: string;
    googleClientId: string;
    ELEVEN_LABS_KEY: string;
}

export interface EnvironmentConfigs {
    default: EnvironmentConfig;
    local: Partial<EnvironmentConfig>;
    dev: Partial<EnvironmentConfig>;
    beta: Partial<EnvironmentConfig>;
    prod: Partial<EnvironmentConfig>;
    [index: string]: Partial<EnvironmentConfig>;
}

export function buildConfig(configs: EnvironmentConfigs): EnvironmentConfig {
    const envName = process.env.REACT_APP_DEV_ENV;
    return Object.assign(
        configs.default,
        (envName ? configs[envName] : {}) || {},
        {
            COMMIT_ID: process.env.REACT_APP_COMMIT_ID,
            BUILD_ID: process.env.REACT_APP_BUILD_ID,
        }
    );
}
//TODO: [MM]: Refactor: replace everything below with React Context API

var _config: EnvironmentConfig;

export function registerConfig(config: EnvironmentConfig) {
    _config = config;
}

export function getConfig(): EnvironmentConfig {
    return _config;
}
