import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { handleToggleSelectedItems } from '../../../../../../pages/ActionPages/NewUser/tools';
import Checkbox from '../../../../../../ui/components/Checkbox';
import { DraftUser } from '../../../../../../constants/interfaces/Draft';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../../../pages/UserProfile/store/reducers';
import { TableDivider } from '../../../../../../ui/components/table-wrapper/table-divider';

const StyledCreatedByList = styled.div`
    overflow: auto;
    height: 100%;
`;

const TopSideList = styled.span``;

const GeneralList = styled.span``;

const CreatedByItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
`;

const CreatedByItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
`;
const YouWrapper = styled.span`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
`;
export const EditedByFiltersList: FC<{
    users?: Partial<DraftUser>[];
    selectedUsers: number[];
    disabled?: boolean;
    setUserFilters?: (userId: number[]) => void;
}> = ({ users, selectedUsers, setUserFilters }) => {
    const isChecked = useCallback(
        (setId?: number) => {
            return !!selectedUsers && !!setId && selectedUsers.includes(setId);
        },
        [selectedUsers]
    );

    const profile = useSelector(getProfileState);
    
    const isPartiallyChecked = useCallback(
        (userId?: number) => {
            return (
                !!selectedUsers &&
                !!userId &&
                selectedUsers.includes(-Number(userId))
            );
        },
        [selectedUsers]
    );

    const changeHandler = (userId?: number) => {
        if (userId && setUserFilters) {
            setUserFilters(handleToggleSelectedItems(selectedUsers, userId));
        }
    };

    if (!users || (users && users.length === 0)) return null;

    const topListCreatedBy = users.filter(
        user => user.id && selectedUsers.includes(user.id)
    );

    const bottomListCreatedBy = users.filter(
        user => user.id && !selectedUsers.includes(user.id)
    );

    return (
        <StyledCreatedByList>
            {topListCreatedBy.length > 0 && (
                <TopSideList>
                    {topListCreatedBy.map(user => {
                        return (
                            <CreatedByItemWrapper key={user.id}>
                                <Checkbox
                                    size={12}
                                    checked={
                                        isChecked(user.id) ||
                                        isPartiallyChecked(user.id)
                                    }
                                    partial={isPartiallyChecked(user.id)}
                                    handleChange={() => changeHandler(user.id)}
                                    dataTest="selected-editors-item-checkbox"
                                />
                                <CreatedByItem
                                    onClick={() => changeHandler(user.id)}
                                    data-test="selected-editors-item-text"
                                >
                                    {user.firstName} {user.lastName} {user.id === profile?.id && <YouWrapper>(you)</YouWrapper>}
                                </CreatedByItem>
                            </CreatedByItemWrapper>
                        );
                    })}
                    {topListCreatedBy.length < users.length && <TableDivider width="98%" />}
                </TopSideList>
            )}
            <GeneralList>
                {bottomListCreatedBy.map(user => {
                    return (
                        <CreatedByItemWrapper key={user.id}>
                            <Checkbox
                                size={12}
                                checked={
                                    isChecked(user.id) ||
                                    isPartiallyChecked(user.id)
                                }
                                partial={isPartiallyChecked(user.id)}
                                handleChange={() => changeHandler(user.id)}
                                dataTest="editors-item-checkbox"
                            />
                            <CreatedByItem
                                onClick={() => changeHandler(user.id)}
                                data-test="editors-item-text"
                            >
                                {user.firstName} {user.lastName} {user.id === profile?.id && <YouWrapper>(you)</YouWrapper>}
                            </CreatedByItem>
                        </CreatedByItemWrapper>
                    );
                })}
            </GeneralList>
        </StyledCreatedByList>
    );
};
