import Skeleton from '@material-ui/lab/Skeleton';

import { Tooltip } from '../../../../ui/components/Tooltip';
import PersonIcon from '../../../../ui/icons/Person';
import PractisSetIcon from '../../../../ui/icons/PractisSet';
import TeamLeadersIcon from '../../../../ui/icons/TeamLeader';
import { Container, IconContainer, Title } from './styles';
import { SubheaderProps } from './types';

function SubheaderView(props: SubheaderProps) {
    const { practisSetsCount, usersCount, teamLeadersCount } = props;

    return (
        <Container data-test="subheader-container">
            {practisSetsCount! >= 0 ? (
                <Tooltip label="Practis Sets" position="bottom-start">
                    <IconContainer data-test="practisset-counter-container">
                        <PractisSetIcon dataTest="practisset-counter-icon" />
                        <Title data-test="practisset-counter-title">
                            {practisSetsCount}
                        </Title>
                    </IconContainer>
                </Tooltip>
            ) : (
                <Skeleton
                    variant="text"
                    style={{
                        background: 'var(--ps-grey-3)',
                        marginRight: 14,
                    }}
                    width={20}
                    height={18.5}
                />
            )}
            {usersCount! >= 0 ? (
                <Tooltip label="Users" position="bottom-start">
                    <IconContainer data-test="users-counter-container">
                        <PersonIcon dataTest="users-counter-icon" />
                        <Title data-test="users-counter-title">
                            {usersCount}
                        </Title>
                    </IconContainer>
                </Tooltip>
            ) : (
                <Skeleton
                    variant="text"
                    style={{
                        background: 'var(--ps-grey-3)',
                        marginRight: 14,
                    }}
                    width={20}
                    height={18.5}
                />
            )}

            {teamLeadersCount! >= 0 ? (
                <Tooltip label="Team Leaders" position="bottom-start">
                    <IconContainer data-test="team-leaders-counter-container">
                        <TeamLeadersIcon dataTest="team-leaders-counter-icon" />
                        <Title data-test="team-leaders-counter-title">
                            {teamLeadersCount}
                        </Title>
                    </IconContainer>
                </Tooltip>
            ) : (
                <Skeleton
                    variant="text"
                    style={{
                        background: 'var(--ps-grey-3)',
                        marginRight: 14,
                    }}
                    width={20}
                    height={18.5}
                />
            )}
        </Container>
    );
}

export default SubheaderView;
