import { ListResult } from '../../../constants/interfaces/PaginationResult';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
    SubmissionReview,
    SubmissionV2
} from '../../../constants/interfaces/Reviews';
import { createSelectorContext, selectItemsByIds } from '../../../tools/redux';
import { Challenge } from '../../../constants/interfaces/Challenge';
import { PractisSets } from '../../../constants/interfaces/PractisSets';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ExtraPaginationResult<TData = any> extends ListResult {
    timestamp: number;
}

export interface SubmissionsState {
    data?: ListResult<SubmissionV2>;
    checked: number[];
    submissionChallenges?: ExtraPaginationResult<Challenge>;
    submissionPractisSets?: ExtraPaginationResult<PractisSets>;
    loading?: boolean;
    error?: string;
}

export interface SubmissionPaginationState {
    submissionIds?: number[];
    totalCount?: number;
    loading?: boolean;
    error?: string;
}

export interface AccuracyScoreState {
    data?: ListResult<SubmissionV2>;
    checked: number[];
    loading?: boolean;
    error?: string;
}

export type SubmissionsStateSelector = (state: any) => SubmissionsState;

export type SubmissionPaginationStateSelector = (state: any) => SubmissionPaginationState;

export type AccuracyScoreStateSelector = (state: any) => AccuracyScoreState;

export const SubmissionsStateSelectorContext = React.createContext<SubmissionsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export const SubmissionPaginationStateSelectorContext = React.createContext<SubmissionPaginationStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export const AccuracyScoreStateSelectorContext = React.createContext<AccuracyScoreStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useSubmissionsState(): SubmissionsState {
    return useSelector(useContext(SubmissionsStateSelectorContext));
}

export function useSubmissionPaginationState(): SubmissionPaginationState {
    return useSelector(useContext(SubmissionPaginationStateSelectorContext));
}

export function selectSelectedSubmissions(
    state: SubmissionsState
): SubmissionV2[] {
    return selectItemsByIds<SubmissionV2>(state?.checked, state?.data?.items);
}

export function selectSelectedAccuracyScores(
    state: AccuracyScoreState
): SubmissionV2[] {
    return selectItemsByIds<SubmissionV2>(state?.checked, state?.data?.items);
}

export function useAccuracyScoreState(): AccuracyScoreState {
    return useSelector(useContext(AccuracyScoreStateSelectorContext));
}

export interface UpdatedSubmissionsState {
    data?: number[];
    updateType?: 'create' | 'update' | 'delete';
    loading?: boolean;
}

export const UpdatedSubmissionsStateSelectorContext = createSelectorContext<UpdatedSubmissionsState>();

export function useUpdatedSubmissionsState(): UpdatedSubmissionsState {
    return useSelector(useContext(UpdatedSubmissionsStateSelectorContext));
}

export interface SubmissionDetailsState {
    details?: SubmissionV2;
    review?: Partial<SubmissionReview>;
    loading?: boolean;
    error?: string;
}

export const SubmissionDetailsStateSelectorContext = createSelectorContext<SubmissionDetailsState>();

export function useSubmissionDetailsState(): SubmissionDetailsState {
    return useSelector(useContext(SubmissionDetailsStateSelectorContext));
}
export interface UpdatedAccuracyScoreState {
    data?: SubmissionV2;
    updateType?: 'create' | 'update' | 'delete';
    loading?: boolean;
}

export const UpdatedAccuracyScoreStateSelectorContext = createSelectorContext<UpdatedAccuracyScoreState>();

