import { Reducer } from 'redux';
import { PortableTeamsState, initialState } from './states';
import { PortableTeamsActions } from './actions';

export const portableTeamsReducer: Reducer<
    PortableTeamsState,
    PortableTeamsActions
> = (
    state = initialState,
    action: PortableTeamsActions
): PortableTeamsState => {
    switch (action.type) {
        case 'searchStartTeams':
            return {
                ...state,
                loading: true,
            };
        case 'searchSuccessTeams':
            return {
                data: action.result,
                loading: false,
            };
        case 'searchFailureTeams':
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
