import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PortableLabelsWithStore } from '../../../../../../../features/portableLabels';
import { useCalculatePreSelectedLabels } from '../../../../../../../features/labels/tools';
import { PaginationResult } from '../../../../../../../constants/interfaces/PaginationResult';
import { DraftUser } from '../../../../../../../constants/interfaces/Draft';
import { getCompanyState } from '../../../../../../CompanySettings/store/reducers';
import { useIfChanged } from '../../../../../../../helpers/hooks/usePreviousData';
import {
    useResetSelectedLabels,
    useSelectLabels,
} from '../../../../../../../features/portableLabels/store/hors/withLabels/services';
import { useLabelsState } from '../../../../../../../features/portableLabels/store/hors/withLabels/states';

export const FilterLabels: FC<{
    users?: PaginationResult<DraftUser>;
    selectedUsers?: number[];
}> = ({ users, selectedUsers }) => {
    const labelsState = useLabelsState();
    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();
    const company = useSelector(getCompanyState);
    const select = useSelectLabels();
    const resetSelected = useResetSelectedLabels();
    const ifCompanyChanged = useIfChanged(company);

    const getPreselectedLabels = useCallback((): number[] | null => {
        if (users?.items && selectedUsers) {
            const { preAssignedLabels } = calculatePreSelectedLabels(
                selectedUsers,
                users
            );
            return preAssignedLabels;
        }

        return null;
    }, [selectedUsers, users, calculatePreSelectedLabels]);

    const setDefaultState = useCallback(() => {
        const preselectedLabels = getPreselectedLabels();

        if (preselectedLabels !== null) {
            select(preselectedLabels);
        }
    }, [select, getPreselectedLabels]);

    useEffect(() => {
        setDefaultState();
    }, [users, selectedUsers, setDefaultState]);

    useEffect(() => {
        setDefaultState();
    }, [setDefaultState]);

    useEffect(() => {
        if (ifCompanyChanged) {
            resetSelected();
            setDefaultState();

            const preselectedLabels = getPreselectedLabels();

            if (preselectedLabels !== null) {
                select(preselectedLabels);
            }
        }
    }, [
        getPreselectedLabels,
        ifCompanyChanged,
        labelsState.selected,
        select,
        resetSelected,
        setDefaultState,
    ]);

    return (
        <PortableLabelsWithStore
            showSelectActions
            showSave={false}
            showSearch
        />
    );
};
