import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const TrainingTime = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 40 40"
                className={className}
                width="40"
                height="40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g clip-path="url(#bur0pzbema)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.266 14.897h34.267V7.283H23.767v1.332c0 1.026-.856 1.866-1.903 1.866h-7.615c-1.047 0-1.904-.84-1.904-1.866V7.282H1.266v7.615z" fill="#4AA9E2"/>
                    <path clip-rule="evenodd" d="M31.615 23c4.188 0 7.615 3.427 7.615 7.615 0 4.188-3.427 7.615-7.615 7.615h0c-4.188 0-7.615-3.427-7.615-7.615C24 26.427 27.427 23 31.615 23z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.5 35.5H1.266V7.282h10.698m12.184 0h11.384V24M35.423 30.615h-3.808v-3.807" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path clip-rule="evenodd" d="M12.345 6.747h1.906c-.084-2.118 1.64-3.882 3.805-3.882 2.164 0 3.89 1.764 3.804 3.882h1.907v1.867c0 1.026-.857 1.866-1.904 1.866H14.25c-1.048 0-1.904-.84-1.904-1.866V6.747z" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.056 6.748h.002M8.88 26.32H5.073m0 3.806H8.88m3.808 0h3.807m3.807 0H24m1-3.807h-4.698m-3.807 0h-3.807M8.88 18.704H5.073m0 3.808H8.88m3.807 0h3.808m3.807 0h3.808m7.614-3.808h-3.807m-3.807 0h-3.808m-3.807 0h-3.807" stroke="#222431" stroke-width="1.563" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="bur0pzbema">
                        <path fill="#fff" d="M0 0h40v40H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default TrainingTime;