import { FC, useRef, useState } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../ui/components/ActionButton';
import { isTeamLeader } from '../../../../constants/enums';
import { PopupComponent } from '../../../../ui/components/Popup';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../labels/tools';
import { AssignLabels } from '../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { PractisSets as PractisSetsInterface } from '../../../../constants/interfaces/PractisSets';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { useSelector } from 'react-redux';
import { PractisSetActionsHelper } from '../../tools';
import { useSetAssignLabelsActionService } from '../../../labels/store/services';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';

const PractisSetsListActions: FC<{
    practisSet: PractisSetsInterface;
    actionsHelper: PractisSetActionsHelper;
    onRestorePractisSet: (
        practisSetIds: number[]
    ) => void;
    handleItemEdit: (practisSet: PractisSetsInterface) => void;
    handleViewPractisSet: (practisSet: PractisSetsInterface) => void;
    handleViewAssignUsers: (id: number) => void;
    handleViewPractisSetProgress: (practisSet: PractisSetsInterface) => void;
    handleDeletePractisSet: (practisSetId: number) => void;
    onDuplicatePractisSet: (
        practisSet: PractisSetsInterface
    ) => void;
    onArchivePractisSet: (
        practisSetIds: number[],
    ) => void;
    assignLabelsToLibrary: (
        addedLabelIds: number[],
        deletedLabelIds: number[]
    ) => void;
    isHidden?: boolean;
}> = ({
    practisSet,
    actionsHelper,
    onRestorePractisSet,
    handleItemEdit,
    handleViewPractisSet,
    handleViewAssignUsers,
    handleViewPractisSetProgress,
    handleDeletePractisSet,
    onDuplicatePractisSet,
    onArchivePractisSet,
    assignLabelsToLibrary,
    isHidden,
}) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();
    const profile = useSelector(getProfileState);
    const setAssignLabelsAction = useSetAssignLabelsActionService();

    const handleSetPreselectedLabels = () => {
        const selectedLabelIds = practisSet.labels || [];
        const preAssignedLabels = calculatePreSelectedLabels(
            selectedLabelIds,
            labelsList.data
        );
        setSelectLabels(preAssignedLabels);
        setAssignLabelsAction(preAssignedLabels);
        saveLabels();
    };

    const handleSaveSelectedLabels = (labelIDs: number[]) => {
        const addedLabelIds = calculateAssignedLabels(labelIDs, labels.saved);
        const deletedLabelIds = calculateRemovedLabels(labelIDs, labels.saved);

        assignLabelsToLibrary(addedLabelIds, deletedLabelIds);
    };

    const [assignSingleLabelOpen, setAssignSingleLabelOpen] = useState<
        null | number | undefined
    >(null);

    const triggerRef = useRef(null);

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton
                    customWidth={168}
                    isHidden={isHidden}
                    dataTest="library-practis-sets-item-menu"
                >
                    {actionsHelper.canEdit(practisSet) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleItemEdit(practisSet);
                            }}
                            dataTest="edit-practis-set-action"
                        >
                            View
                        </ActionItem>
                    )}
                    {isTeamLeader(profile?.role?.name) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleViewPractisSet(practisSet);
                            }}
                            dataTest="view-practis-set-action"
                        >
                            View
                        </ActionItem>
                    )}
                    {actionsHelper.canAssignUsers(practisSet) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                if (practisSet?.id) {
                                    handleViewAssignUsers(practisSet?.id);
                                }
                            }}
                            dataTest="assign-users-practis-set-action"
                        >
                            Assign Users
                        </ActionItem>
                    )}
                    {actionsHelper.canAssignLabels(practisSet) &&
                        !!labelsList.data.items.length && (
                            <ActionItem
                                onClick={e => {
                                    e.stopPropagation();
                                    setAssignSingleLabelOpen(practisSet.id);
                                    handleSetPreselectedLabels();
                                }}
                                dataTest="assign-labels-practis-set-action"
                            >
                                Assign Labels
                            </ActionItem>
                        )}
                    {actionsHelper.canDuplicate(practisSet) && (
                        <ActionItem
                            onClick={e => {
                                onDuplicatePractisSet(practisSet);
                            }}
                            dataTest="duplicate-practis-set-action"
                        >
                            Duplicate
                        </ActionItem>
                    )}
                    {actionsHelper.canArchive(practisSet) && (
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                onArchivePractisSet([practisSet.id!]);
                            }}
                            dataTest="archive-practis-set-action"
                        >
                            Archive
                        </ActionItem>
                    )}
                    {actionsHelper.canRestore(practisSet) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                onRestorePractisSet([practisSet.id!]);
                            }}
                            dataTest="restore-practis-set-action"
                        >
                            Restore
                        </ActionItem>
                    )}
                    {actionsHelper.canDelete(practisSet) && (
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                practisSet?.id &&
                                    handleDeletePractisSet(practisSet.id);
                            }}
                            dataTest="delete-practis-set-action"
                        >
                            Delete
                        </ActionItem>
                    )}
                </ActionButton>
            </div>
            <PopupComponent
                triggerRef={triggerRef}
                open={assignSingleLabelOpen === practisSet.id}
                onDismiss={() => {
                    setAssignSingleLabelOpen(null);
                }}
            >
                <AssignLabels
                    onLabelsChange={handleSaveSelectedLabels}
                    onSuccessApply={() => {
                        setAssignSingleLabelOpen(null);
                    }}
                    withBorder={true}
                />
            </PopupComponent>
        </span>
    );
};

export default PractisSetsListActions;


