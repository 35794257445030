import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';

export interface VoiceGenerationState {
    [key: string]: {
        generating?: boolean;
        error?: ErrorResult;
        data: Array<{
            id?: number | string;
            voice?: Blob;
        }>;
    };
}

export type VoiceGenerationSelector = (state: any) => VoiceGenerationState;

export const VoiceGenerationSelectorContext = React.createContext<
    VoiceGenerationSelector
>(() => {
    throw new Error('Not implemented');
});

export function useVoiceGenerationState(): VoiceGenerationState {
    return useSelector(useContext(VoiceGenerationSelectorContext));
}
