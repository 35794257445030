import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoLabels = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 24 32"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor">
                        <g>
                            <g>
                                <g>
                                    <path
                                        fillRule="nonzero"
                                        d="M24 30.001V17.998h-2V10L12 2.5 2 10v20h8v1.997h12.013l-.012.003H2C.895 32 0 31.105 0 30V9l12-9 12 9v21.001zM12 15.998c-2.209 0-4-1.791-4-4 0-2.21 1.791-4 4-4 2.21 0 4 1.79 4 4 0 2.209-1.79 4-4 4zm0-6.001c-1.105 0-2 .895-2 2 0 1.106.895 2 2 2 1.104 0 2-.894 2-2 0-1.105-.896-2-2-2z"
                                        transform="translate(-1104 -380) translate(0 184) translate(1029 196) translate(75)"
                                    />
                                    <path
                                        d="M8 32v-2h14V17h2v13c0 1.054-.816 1.918-1.85 1.995L22 32H8z"
                                        transform="translate(-1104 -380) translate(0 184) translate(1029 196) translate(75)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoLabels;
