import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const CustomerAvatar = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow, dataTest }, ref) => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" data-test={dataTest} className={className}>
            <rect width="32" height="32" rx="4" fill="#B1C0CB" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.481 25H23.52c.828-1.274.498-3.823-.33-4.673-.384-.395-3.407-1.18-4.8-1.527 1.498-1.167 2.41-3.322 2.41-5.377C20.8 10.428 19.928 8 16 8c-3.93 0-4.802 2.428-4.802 5.423 0 2.06.917 4.222 2.423 5.387-1.426.348-4.403 1.113-4.8 1.517-.835.85-1.172 3.399-.339 4.673zm9.118-5.206c2.006.48 4.36 1.137 4.906 1.43.343.44.641 1.839.426 2.716H9.07c-.217-.877.088-2.28.436-2.717.539-.294 2.88-.941 4.895-1.414v-1.696c-1.338-.906-2.132-2.878-2.132-4.67 0-2.421.622-4.385 3.73-4.385 3.11 0 3.731 1.964 3.731 4.385 0 1.79-.792 3.76-2.13 4.667v1.684z" fill="#fff" />
        </svg>
        );
    }
);

export default CustomerAvatar;