import { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { PortableScenariosList } from './PortableScenariosList';
import { Button } from '../../../ui/components/Button';
import { Variables } from '../../../theme/variables';
import EmptyScenarios from './EmptyScenarios';
import { sortBy } from 'lodash';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { TableSearchInput } from '../../../ui/components/table-wrapper/table-tools/table-search-input';

//region Types
type Action = 'clear' | 'select';

export type PortableScenariosProps = {
    teams?: ListResult<Scenario>;
    selectedList: number[];
    onSave?: () => void;
    onSelectAll?: (processedTeams: number[]) => void;
    onDeselectAll?: () => void;
    onClearSelected?: () => void;
    onSetSelected?: (teamIds: number[]) => void;
    filtersTitle?: string;
    disabled?: boolean;
    loading?: boolean;
    showSelectActions?: boolean;
    showSearch?: boolean;
    showSave?: boolean;
    showDefaultMembers?: boolean;
};
//endregion

//region Styles
const StyledCreateTeams = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const CreateTeamsBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const FiltersHeader = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
    flex-shrink: 0;
`;

const FiltersSavePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
    font-weight: 600;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;

const StyledPlainActionsWrapper = styled.div`
    padding-right: 15px;
`;
//endregion

const PortableScenarios: FC<PortableScenariosProps> = ({
    teams,
    selectedList,
    disabled,
    loading,
    onSave,
    onClearSelected,
    onDeselectAll,
    onSelectAll,
    onSetSelected,
    filtersTitle,
    showSelectActions,
    showSearch,
    showDefaultMembers,
    showSave = true,
}) => {
    const actionConfig: Record<
        Action,
        {
            color: string;
            colorHover: string;
            colorActive: string;
        }
    > = {
        clear: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
        select: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
    };

    const clearHandler = useCallback(() => {
        onClearSelected?.();
    }, [onClearSelected]);

    const [processedSelectedList, setProcessedSelectedList] = useState<
        number[]
    >([]);

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const searchHandler = (value: string) => setSearchTerm(value);
    const [, setAllSelected] = useState<boolean>(false);
    
    const processedTeams = useMemo(() => {
        let result = teams?.items || [];
        if (!!searchTerm) {
            result = result.filter(
                item =>
                    item.title
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase()) !== -1
            );
        }
        if (result.length > 0) {
            result = sortBy(result, t => t.title?.toLowerCase());
            const processedDataIds = result.map(item => item.id!);   
     
            setProcessedSelectedList(
                selectedList.filter(item =>
                    processedDataIds.includes(Math.abs(item))
                )
            );
        }
        return result;
    }, [teams?.items, searchTerm, selectedList]);

    const isAllSelected = useMemo(
        () => selectedList && selectedList.length === teams?.items?.length,
        [selectedList, teams]
    );

    //TODO: [MM]: Refactor: Use `deSelectAllHandler` either for both `Select All` and `Unselect All` buttons and delete `clearHandler`
    //or rename confusing `deSelectAllHandler` to `handleClickSelectAll` and get rid of `if (isAllSelected && onDeselectAll` statement.
    const toggleAllHandler = useCallback(() => {
        if (isAllSelected && onDeselectAll) {
            onDeselectAll();
            setAllSelected(false)
        } else if (onSelectAll) {
            setAllSelected(true)
            onSelectAll(processedTeams.map(item => item.id!));
        }
    }, [onDeselectAll, onSelectAll, isAllSelected, processedTeams]);


    const showNoItems = !teams?.count;
    const showEmptyState = !processedTeams.length || loading;
    const selectedTeamsLength = processedSelectedList.filter(id => id > 0)
        .length;
   
    return (
        <StyledCreateTeams>
            <CreateTeamsBody>
                {filtersTitle && (
                    <FiltersHeader>
                        <FilterTitle>{filtersTitle}</FilterTitle>
                    </FiltersHeader>
                )}
                {showSearch && (
                    <SearchWrapper>
                        <TableSearchInput
                            onChange={searchHandler}
                            disabled={showNoItems}
                            dataTest="scenarios-search"
                        />
                    </SearchWrapper>
                )}
                {showSelectActions && !showNoItems && (
                    <SelectActions>
                        <div>
                            <SelectedCaption data-test="scenarios-selected-text">
                                {selectedTeamsLength > 0
                                    ? `${selectedTeamsLength} ${selectedTeamsLength > 1
                                        ? ' Scenarios selected'
                                        : ' Scenario selected'
                                    }`
                                    : 'No Scenarios selected'}
                            </SelectedCaption>
                        </div>
                        <StyledPlainActionsWrapper>
                            {isAllSelected ? (
                                <PlainAction
                                    disabled={
                                        selectedList.length === 0 ||
                                        showEmptyState
                                    }
                                    color={actionConfig.clear.color}
                                    colorActive={actionConfig.clear.colorActive}
                                    colorHover={actionConfig.clear.colorHover}
                                    onClick={clearHandler}
                                    data-test="scenarios-unselect-all"
                                >
                                    Unselect All
                                </PlainAction>
                            ) : (
                                <PlainAction
                                    disabled={showEmptyState}
                                    color={actionConfig.select.color}
                                    colorActive={
                                        actionConfig.select.colorActive
                                    }
                                    colorHover={actionConfig.select.colorHover}
                                    onClick={toggleAllHandler}
                                    data-test="scenarios-select-all"
                                >
                                    Select All
                                </PlainAction>
                            )}
                        </StyledPlainActionsWrapper>
                    </SelectActions>
                )}
                {showEmptyState ? (
                    <EmptyScenarios
                        loading={loading}
                        isEmpty={!teams?.count}
                    />
                ) : (
                    <>
                        <PortableScenariosList
                            showDefaultMembers={showDefaultMembers}
                            teams={processedTeams}
                            selectedTeams={selectedList}
                            disabled={disabled}
                            setTeamFilters={onSetSelected}
                        />
                        {onSave && showSave && (
                            <FiltersSavePanel>
                                <Button
                                    type={'button'}
                                    label={'Save'}
                                    action={() => onSave()}
                                    height={'48px'}
                                    width={'160px'}
                                    dataTest="scenarios-save"
                                />
                            </FiltersSavePanel>
                        )}
                    </>
                )}
            </CreateTeamsBody>
        </StyledCreateTeams>
    );
};

export { PortableScenarios };

