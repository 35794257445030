import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { CommentContext } from './Comment';

const Avatar = styled.div<{ disabled?: boolean }>`
    width: 28px;
    height: 28px;
    opacity: ${props => (props.disabled ? 0.4 : 1)};
    margin: 0 8px;
`;

const AvatarImage = styled.img<{ disabled?: boolean }>`
    height: 100%;
    width: 100%;
    opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

export const CommentAvatar: FC<{
    image?: string;
}> = ({ children, image }) => {
    const params = useContext(CommentContext);

    if (image) {
        return (
            <Avatar disabled={params.disabled}>
                <AvatarImage src={image} alt="company" />
            </Avatar>
        );
    }
    return <Avatar disabled={params.disabled}>{children}</Avatar>;
};
