import { combineReducers, compose, Reducer } from 'redux';
import {
    EnrollmentState,
    PractisSetReportState,
    TeamEditState,
    TeamMembersBaseState,
    TeamMembersState,
    TeamsState,
    AssignUsersState,
    UpdatedProgressState,
    UpdateMembersState,
    UpdateTeamsState,
    PractisSetsTrainingState,
    TeamUpdateStatus
} from './states';
import {
    ACTIONS,
    checkAllProgress,
    checkSingleProgress,
    deselectAllTeamLeadersSelectionAction,
    fetchAllTeamsFailure,
    fetchAllTeamsStart,
    fetchAllTeamsSuccess,
    getPractisSetFailure,
    getPractisSetReportFailure,
    getPractisSetReportStart,
    getPractisSetReportSuccess,
    getPractisSetStart,
    getPractisSetSuccess,
    getTeamInfoFailure,
    getTeamInfoStart,
    getTeamInfoSuccess,
    getTeamInfoTrainingFailure,
    getTeamInfoTrainingStart,
    getTeamInfoTrainingSuccess,
    modifyTeamName,
    removeSingleTeamAllUsersLabel,
    removeSingleTeamMemberLabel,
    resetPractisSetReport,
    resetSingleTeamPage,
    resetTeamInfo,
    resetTeamLeadersSelectionAction,
    saveTeamLeaderSelection,
    searchInvitationUsersFailure,
    searchInvitationUsersStart,
    searchInvitationUsersSuccess,
    searchPractisUsersFailure,
    searchPractisUsersStart,
    searchPractisUsersSuccess,
    searchSingleTeamAllUsersFailure,
    searchSingleTeamAllUsersStart,
    searchSingleTeamAllUsersSuccess,
    searchSingleTeamFailure,
    searchSingleTeamMembersFailure,
    searchSingleTeamMembersStart,
    searchSingleTeamMembersSuccess,
    searchSingleTeamStart,
    searchSingleTeamSuccess,
    searchTeamLeadersFailureAction,
    searchTeamLeadersStartAction,
    searchTeamLeadersSuccessAction,
    searchTeamMembersFailureAction,
    searchTeamMembersStartAction,
    searchTeamMembersSuccessAction,
    searchTrainerFailure,
    searchTrainerStart,
    searchTrainerSuccess,
    selectMultipleTeamLeaderAction,
    selectTeamLeaderAction,
    sendTraineeInvitationSuccess,
    setAssignedLabelsToSingleTeam,
    setDeletedLabelsToSingleTeam,
    updateAllSelectedInvitationTraineesState,
    updateAllSelectedPractisSetsState,
    updateAllSelectedTeamsState,
    updatedProgressActionFailure,
    updatedProgressActionStart,
    updatedProgressActionSuccess,
    updateMemberActionFailure,
    updateMemberActionStart,
    updateMemberActionSuccess,
    updateSelectedInvitationTraineesState,
    updateSelectedPractisSetState,
    updateSelectedTeamState,
    createSingleTeamSuccess,
    updateSingleTeamFailure,
    updateSingleTeamStart,
    updateSingleTeamSuccess,
    updateTeamActionFailure,
    updateTeamActionStart,
    updateTeamActionSuccess,
    updateTraineeDueDateAction,
    resetPractisSetDetails,
    updateTeamLead,
    clearTeamUpdateStatus,
    updateSingleTeamStop,
    clearTrainerPractisSet,
} from './actions';
import {
    withLabelsInitialState,
    withLabelsReducer,
} from '../../portableLabels/store/hors/withLabels';
import { toggleItemInArray } from '../../../helpers/functions/array-helpers';
import {
    withTeamsInitialState,
    withTeamsReducer,
} from '../../portableTeams/store/hors/withTeams';
import { TeamV2 } from '../../../constants/interfaces/Team';

const initialTeamsState: TeamsState = withLabelsInitialState<TeamsState>(
    withTeamsInitialState<TeamsState>(
        {
            teamLeaders: {
                selected: [],
                saved: [],
            },
        },
        'teams'
    ),
    'teams'
);

export type TeamsAction =
    | ReturnType<typeof fetchAllTeamsStart>
    | ReturnType<typeof fetchAllTeamsSuccess>
    | ReturnType<typeof fetchAllTeamsFailure>
    | ReturnType<typeof updateSelectedTeamState>
    | ReturnType<typeof updateAllSelectedTeamsState>
    | ReturnType<typeof searchTeamLeadersStartAction>
    | ReturnType<typeof searchTeamLeadersSuccessAction>
    | ReturnType<typeof searchTeamLeadersFailureAction>
    | ReturnType<typeof selectTeamLeaderAction>
    | ReturnType<typeof selectMultipleTeamLeaderAction>
    | ReturnType<typeof saveTeamLeaderSelection>
    | ReturnType<typeof resetTeamLeadersSelectionAction>
    | ReturnType<typeof deselectAllTeamLeadersSelectionAction>;

export const allTeamsBaseReducer: Reducer<TeamsState, TeamsAction> = (
    state = initialTeamsState,
    action: TeamsAction
): TeamsState => {
    switch (action.type) {
        case ACTIONS.FETCH_ALL_TEAMS:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.FETCH_ALL_TEAMS_SUCCESS:
            return {
                ...state,
                myTeams: action.data,
                loading: false,
                error: '',
            };
        case ACTIONS.FETCH_ALL_TEAMS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ACTIONS.UPDATE_SELECTED_TEAM_STATE:
            const selectedTeamIds = [...(state.selectedMyTeamsItems || [])];

            const selectedTeamIndex = selectedTeamIds.findIndex(
                (item: number) => item === action.teamId
            );
            if (selectedTeamIndex > -1) {
                selectedTeamIds.splice(selectedTeamIndex, 1);
            } else {
                selectedTeamIds.push(action.teamId);
            }

            return {
                ...state,
                selectedMyTeamsItems: selectedTeamIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_TEAMS_STATE:
            return {
                ...state,
                selectedMyTeamsItems:
                    action.checked || action.partial ? action.teamIds : [],
            };
        case ACTIONS.SEARCH_TEAM_LEADERS_START:
            return {
                ...state,
            };
        case ACTIONS.SEARCH_TEAM_LEADERS_SUCCESS:
            return {
                ...state,
                teamLeaders: {
                    ...state.teamLeaders,
                    data: action.data,
                },
            };
        case ACTIONS.SEARCH_TEAM_LEADERS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ACTIONS.SELECT_TEAM_LEADER: {
            const updatedSelections = [...state.teamLeaders.selected];
            return {
                ...state,
                teamLeaders: {
                    ...state.teamLeaders,
                    selected: toggleItemInArray(
                        updatedSelections,
                        action.leaderId
                    ),
                },
            };
        }
        case ACTIONS.SELECT_MULTIPLE_TEAM_LEADERS: {
            return {
                ...state,
                teamLeaders: {
                    ...state.teamLeaders,
                    selected: action.leaderIds,
                },
            };
        }
        case ACTIONS.SAVE_TEAM_LEADERS_SELECTION: {
            return {
                ...state,
                teamLeaders: {
                    ...state.teamLeaders,
                    saved: state.teamLeaders.selected,
                },
            };
        }
        case ACTIONS.RESET_TEAM_LEADERS_SELECTION: {
            return {
                ...state,
                teamLeaders: {
                    ...state.teamLeaders,
                    selected: state.teamLeaders.saved || [],
                },
            };
        }
        case ACTIONS.DESELECT_ALL_TEAM_LEADERS_SELECTION: {
            return {
                ...state,
                teamLeaders: {
                    ...state.teamLeaders,
                    selected: [],
                },
            };
        }
        default:
            return state;
    }
};

export const allTeamsReducer = compose<Reducer<TeamsState, TeamsAction>>(
    withLabelsReducer({ reducerName: 'teams' }),
    withTeamsReducer({ reducerName: 'teams' })
)(allTeamsBaseReducer);

const initialTeamEditState = withLabelsInitialState<TeamEditState>(
    {
        data: undefined,
        assignedLabels: [],
        deletedLabels: [],
        updatedTeam: undefined,
        loading: false,
        updateStatus: undefined,
    },
    'teamEdit'
);

export type TeamEditAction =
    | ReturnType<typeof searchSingleTeamStart>
    | ReturnType<typeof searchSingleTeamSuccess>
    | ReturnType<typeof searchSingleTeamFailure>
    | ReturnType<typeof searchSingleTeamAllUsersStart>
    | ReturnType<typeof searchSingleTeamAllUsersSuccess>
    | ReturnType<typeof searchSingleTeamAllUsersFailure>
    | ReturnType<typeof searchSingleTeamMembersStart>
    | ReturnType<typeof searchSingleTeamMembersSuccess>
    | ReturnType<typeof searchSingleTeamMembersFailure>
    | ReturnType<typeof modifyTeamName>
    | ReturnType<typeof setAssignedLabelsToSingleTeam>
    | ReturnType<typeof setDeletedLabelsToSingleTeam>
    | ReturnType<typeof removeSingleTeamAllUsersLabel>
    | ReturnType<typeof removeSingleTeamMemberLabel>
    | ReturnType<typeof resetSingleTeamPage>
    | ReturnType<typeof updateTeamLead>
    | ReturnType<typeof createSingleTeamSuccess>
    | ReturnType<typeof updateSingleTeamStart>
    | ReturnType<typeof updateSingleTeamSuccess>
    | ReturnType<typeof updateSingleTeamFailure>
    | ReturnType<typeof updateSingleTeamStop>
    | ReturnType<typeof clearTeamUpdateStatus>

const teamEditBaseReducer: Reducer<TeamEditState, TeamEditAction> = (
    state = initialTeamEditState,
    action: TeamEditAction
): TeamEditState => {
    switch (action.type) {
        case ACTIONS.SEARCH_SINGLE_TEAM_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_SUCCESS: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case ACTIONS.CREATE_SINGLE_TEAM_SUCCESS: {
            return {
                ...state,
                loading: false,
                updatedTeam: action.team,
            };
        }
        case ACTIONS.UPDATE_SINGLE_TEAM_START: {
            return {
                ...state,
                updateStatus: TeamUpdateStatus.IN_PROGRESS,
            };
        }
        case ACTIONS.UPDATE_SINGLE_TEAM_SUCCESS: {
            const team: TeamV2 = { ...state.data! };
            team.updatedAt = new Date().toUTCString();
            return {
                ...state,
                data: {
                    ...team
                },
                updateStatus: TeamUpdateStatus.COMPLETED,
                updatedTeam: action.team,
            };
        }
        case ACTIONS.UPDATE_SINGLE_TEAM_FAILURE: {
            return {
                ...state,
                updateStatus: TeamUpdateStatus.ERROR,
                error: action.error,
            };
        }
        case ACTIONS.UPDATE_SINGLE_TEAM_STOP: {
            const team = {
                ...state.data!,
                updatedAt: new Date().toUTCString()
            };
            return {
                ...state,
                data: team,
                updateStatus: undefined,
                updatedTeam: team,
            }
        }
        case ACTIONS.CLEAR_TEAM_UPDATE_STATUS: {
            return {
                ...state,
                updateStatus: undefined,
            };
        }
        case ACTIONS.MODIFY_TEAM_NAME: {
            const team: TeamV2 = { ...state.data! };
            team.name = action.value;

            return {
                ...state,
                data: {
                    ...team,
                },
            };
        }

        case ACTIONS.UPDATE_TEAM_LEAD: {
            const { teamMembers: currentTeamMembers } = state;
            const updatedTeamMembers = {
                count: currentTeamMembers?.count ?? 0,
                items:
                    currentTeamMembers?.items?.map(item =>
                        item.id === action.teamUserId
                            ? { ...item, isTeamLead: action.isTeamLead }
                            : item
                    ) ?? [],
            };
            const { data: currentTeam } = state;
            const member = currentTeamMembers?.items?.find(item => item.id === action.teamUserId);
            let updatedTeam = currentTeam;

            if (member && currentTeam) {
                if (action.isTeamLead) {
                    const teamLeader = currentTeam.leaders?.find(item => item.id === member.id);
                    if (!teamLeader) {
                        updatedTeam = {
                            ...currentTeam,
                            leaders: (currentTeam.leaders ?? []).concat({
                                id: member.id,
                                user: member.user,
                                isViewed: false
                            })
                        };
                    }
                } else {
                    updatedTeam = {
                        ...currentTeam,
                        leaders: currentTeam.leaders?.filter(item => item.id !== member.id)
                    };
                }
            }

            return {
                ...state,
                data: updatedTeam,
                teamMembers: updatedTeamMembers,
            };
        }
        case ACTIONS.SET_ASSIGNED_LABELS_TO_SINGLE_TEAM: {
            return {
                ...state,
                assignedLabels: action.labelIDs,
            };
        }
        case ACTIONS.SET_DELETED_LABELS_TO_SINGLE_TEAM: {
            return {
                ...state,
                deletedLabels: action.labelIDs,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                allUsers: action?.isRefreshed
                    ? action.data
                    : action.offset > 0 && state.allUsers?.items
                    ? {
                          ...action.data,
                          items: state.allUsers.items.concat(action.data.items),
                      }
                    : action.data,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                teamMembers: action?.isRefreshed
                    ? action.data
                    : action.offset > 0 && state.teamMembers?.items
                    ? {
                          ...action.data,
                          items: state.teamMembers.items.concat(
                              action.data.items
                          ),
                      }
                    : action.data,
            };
        }

        case ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case ACTIONS.REMOVE_SINGLE_TEAM_ALL_USERS_LABEL: {
            if (!state.allUsers) return state;
            const allUsers = {
                ...state.allUsers,
                items: state.allUsers?.items.map(item => {
                    if (item.id === action.userId)
                        return {
                            ...item,
                            labels: item.labels?.filter(
                                label => label !== action.labelId
                            ),
                        };
                    return item;
                }),
            };
            return {
                ...state,
                allUsers,
            };
        }
        case ACTIONS.REMOVE_SINGLE_TEAM_MEMBER_LABEL: {
            if (!state.teamMembers) return state;
            const teamMembers = {
                ...state.teamMembers,
                items: state.teamMembers.items.map(item => {
                    if (item.user.id === action.userId)
                        return {
                            ...item,
                            labels: item.labels?.filter(
                                labelId => labelId !== action.labelId
                            ),
                        };
                    return item;
                }),
            };
            return {
                ...state,
                teamMembers,
            };
        }
        case ACTIONS.RESET_SINGLE_TEAM_PAGE: {
            return {
                ...state,
                data: undefined,
                teamMembers: undefined,
                allUsers: undefined,
                assignedLabels: [],
                deletedLabels: [],
                updatedTeam: undefined,
                loading: false,
                updateStatus: undefined,
            };
        }
        default:
            return state;
    }
};

export const teamEditReducer = compose<Reducer<TeamEditState, TeamEditAction>>(
    withLabelsReducer({ reducerName: 'teamEdit' })
)(teamEditBaseReducer);

const initialUpdateTeamState: UpdateTeamsState = {};

export type updateTeamAction =
    | ReturnType<typeof updateTeamActionStart>
    | ReturnType<typeof updateTeamActionSuccess>
    | ReturnType<typeof updateTeamActionFailure>;

export const updatedTeamReducer: Reducer<UpdateTeamsState, updateTeamAction> = (
    state = initialUpdateTeamState,
    action: updateTeamAction
): UpdateTeamsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_TEAM_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_TEAM_SUCCESS:
            return {
                ...state,
                data: action.team,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialUpdateMemberState: UpdateTeamsState = {};

export type updateMemberAction =
    | ReturnType<typeof updateMemberActionStart>
    | ReturnType<typeof updateMemberActionSuccess>
    | ReturnType<typeof updateMemberActionFailure>;

export const updatedMemberReducer: Reducer<
    UpdateMembersState,
    updateMemberAction
> = (
    state = initialUpdateMemberState,
    action: updateMemberAction
): UpdateTeamsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_MEMBER_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_MEMBER_SUCCESS:
            return {
                ...state,
                data: action.team,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_MEMBER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

//region Team members
const teamMembersInitialState = withLabelsInitialState<TeamMembersBaseState>(
    {
        itemsOnPage: 20,
        list: {
            items: [],
            count: 0,
        },
        loading: false,
        error: null,
    },
    'teamMembers'
);

export type TeamMembersAction =
    | ReturnType<typeof getTeamInfoStart>
    | ReturnType<typeof getTeamInfoSuccess>
    | ReturnType<typeof getTeamInfoFailure>
    | ReturnType<typeof resetTeamInfo>
    | ReturnType<typeof searchTeamMembersStartAction>
    | ReturnType<typeof searchTeamMembersSuccessAction>
    | ReturnType<typeof searchTeamMembersFailureAction>;

const teamMembersBaseReducer: Reducer<TeamMembersState, TeamMembersAction> = (
    state = teamMembersInitialState,
    action: TeamMembersAction
): TeamMembersState => {
    switch (action.type) {
        case ACTIONS.GET_TEAM_INFO_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTIONS.GET_TEAM_INFO_SUCCESS: {
            return {
                ...state,
                data: action.data,
                loading: false,
            };
        }
        case ACTIONS.GET_TEAM_INFO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case ACTIONS.RESET_TEAM_INFO: {
            return {
                ...state,
                data: undefined,
            };
        }
        case ACTIONS.SEARCH_TEAM_MEMBERS_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ACTIONS.SEARCH_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data,
            };
        case ACTIONS.SEARCH_TEAM_MEMBERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export const teamMembersReducer = compose<
    Reducer<TeamMembersState, TeamMembersAction>
>(withLabelsReducer({ reducerName: 'teamMembers' }))(teamMembersBaseReducer);

//endregion

const initialState: PractisSetsTrainingState = withLabelsInitialState<PractisSetsTrainingState>(
    {
        checked: [],
    },
    'practisSetsTraining'
);

export type PractisSetsTrainingAction =
    | ReturnType<typeof getTeamInfoTrainingStart>
    | ReturnType<typeof getTeamInfoTrainingSuccess>
    | ReturnType<typeof getTeamInfoTrainingFailure>
    | ReturnType<typeof searchTrainerStart>
    | ReturnType<typeof searchTrainerSuccess>
    | ReturnType<typeof searchTrainerFailure>
    | ReturnType<typeof checkSingleProgress>
    | ReturnType<typeof checkAllProgress>
    | ReturnType<typeof updateAllSelectedPractisSetsState>
    | ReturnType<typeof clearTrainerPractisSet>
    | ReturnType<typeof updateSelectedPractisSetState>;

const trainerPractisSetsBaseReducer: Reducer<
    PractisSetsTrainingState,
    PractisSetsTrainingAction
> = (
    state = initialState,
    action: PractisSetsTrainingAction
): PractisSetsTrainingState => {
    switch (action.type) {
        case ACTIONS.GET_TEAM_INFO_TRAINING_START: {
            return {
                ...state,
            };
        }
        case ACTIONS.GET_TEAM_INFO_TRAINING_SUCCESS: {
            return {
                ...state,
                teamData: action.data,
            };
        }
        case ACTIONS.GET_TEAM_INFO_TRAINING_FAILURE: {
            return {
                ...state,
                error: action.error,
            };
        }
        case ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_SUCCESS:
            return {
                ...state,
                data: {
                    ...action.practisSets,
                    items: action.practisSets.items,
                },
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.CHECK_SINGLE_PROGRESS: {
            const selectedIds = [...state.checked];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                checked: selectedIds,
            };
        }
        case ACTIONS.CHECK_ALL_PROGRESS:
            return {
                ...state,
                checked:
                    action.checked || action.partial ? action.progressId : [],
            };
        case ACTIONS.UPDATE_SELECTED_PRACTIS_SET_STATE:
            const selectedPractisSetIds = [
                ...(state.selectedPractisSets || []),
            ];

            const selectedPractisSetIndex = selectedPractisSetIds.findIndex(
                (item: number) => item === action.setId
            );
            if (selectedPractisSetIndex > -1) {
                selectedPractisSetIds.splice(selectedPractisSetIndex, 1);
            } else {
                selectedPractisSetIds.push(action.setId);
            }

            return {
                ...state,
                selectedPractisSets: selectedPractisSetIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE:
            return {
                ...state,
                selectedPractisSets:
                    action.checked || action.partial ? action.setIds : [],
            };
        case ACTIONS.CLEAR_TRAINER_PRACTIS_SETS_SUCCESS:
            return {
                ...state,
                data: {
                    count: 0,
                    items: [],
                },
                loading: false,
                error: '',
            }
        default:
            return state;
    }
};

export const trainerPractisSetsReducer = compose<
    Reducer<PractisSetsTrainingState, PractisSetsTrainingAction>
>(withLabelsReducer({ reducerName: 'practisSetsTraining' }))(
    trainerPractisSetsBaseReducer
);

const initialEnrollmentsState: EnrollmentState = {};

export type EnrollmentsAction =
    | ReturnType<typeof searchPractisUsersStart>
    | ReturnType<typeof searchPractisUsersSuccess>
    | ReturnType<typeof searchPractisUsersFailure>
    | ReturnType<typeof getPractisSetStart>
    | ReturnType<typeof getPractisSetSuccess>
    | ReturnType<typeof getPractisSetFailure>
    | ReturnType<typeof resetPractisSetDetails>
    | ReturnType<typeof sendTraineeInvitationSuccess>;

const practisSetsEnrollmentReducer: Reducer<
    EnrollmentState,
    EnrollmentsAction
> = (
    state = initialEnrollmentsState,
    action: EnrollmentsAction
): EnrollmentState => {
    switch (action.type) {
        case ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_SUCCESS:
            if (
                state.data &&
                state.data.offset !== action.users.offset &&
                action.users.offset !== 0
            ) {
                return {
                    ...state,
                    data: {
                        ...action.users,
                        items: state.data ? action.users.items : [],
                    },
                    loading: false,
                    error: '',
                };
            } else {
                return {
                    ...state,
                    data: {
                        ...action.users,
                        items: action.users.items,
                    },
                    loading: false,
                    error: '',
                };
            }
        case ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.GET_PRACTIS_SET_START:
            return {
                ...state,
            };
        case ACTIONS.GET_PRACTIS_SET_SUCCESS:
            return {
                ...state,
                loading: false,
                practisSet: action.practisSet,
                error: '',
            };
        case ACTIONS.GET_PRACTIS_SET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case ACTIONS.RESET_PRACTIS_SET_DETAILS:
            return initialEnrollmentsState;
        case ACTIONS.SEND_TRAINEE_INVITATION_SUCCESS: {
            const updatedArray = state.update ? [...state.update] : [];
            updatedArray.push(action.inviteType);
            return {
                ...state,
                update: updatedArray,
            };
        }
        default:
            return state;
    }
};

const initialPractisSetReportState: PractisSetReportState = {};

export type PractisSetReportActions =
    | ReturnType<typeof getPractisSetReportStart>
    | ReturnType<typeof getPractisSetReportSuccess>
    | ReturnType<typeof getPractisSetReportFailure>
    | ReturnType<typeof resetPractisSetReport>;

const practisSetReportsReducer: Reducer<
    PractisSetReportState,
    PractisSetReportActions
> = (
    state = initialPractisSetReportState,
    action: PractisSetReportActions
): PractisSetReportState => {
    switch (action.type) {
        case ACTIONS.GET_PRACTIS_SET_REPORT_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.GET_PRACTIS_SET_REPORT_SUCCESS:
            return {
                ...state,
                enrollment: action.enrollment,
                progress: action.progress,
                dailyTraining: action.dailyTraining,
                loading: false,
                error: '',
            };
        case ACTIONS.GET_PRACTIS_SET_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.RESET_PRACTIS_SET_REPORT:
            return {
                loading: false,
            };
        default:
            return state;
    }
};

const initialUpdatedProgressState: UpdatedProgressState = {};

export type updatedProgressAction =
    | ReturnType<typeof updatedProgressActionStart>
    | ReturnType<typeof updatedProgressActionSuccess>
    | ReturnType<typeof updatedProgressActionFailure>;

export const updatedProgressReducer: Reducer<
    UpdatedProgressState,
    updatedProgressAction
> = (
    state = initialUpdatedProgressState,
    action: updatedProgressAction
): UpdatedProgressState => {
    switch (action.type) {
        case ACTIONS.UPDATE_PROGRESS_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_PROGRESS_SUCCESS:
            return {
                ...state,
                data: action.progress,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_PROGRESS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialInvitationState: AssignUsersState = withLabelsInitialState<AssignUsersState>(
    {},
    'assignUsers'
);

export type AssignUsersAction =
    | ReturnType<typeof searchInvitationUsersStart>
    | ReturnType<typeof searchInvitationUsersSuccess>
    | ReturnType<typeof searchInvitationUsersFailure>
    | ReturnType<typeof sendTraineeInvitationSuccess>
    | ReturnType<typeof updateSelectedInvitationTraineesState>
    | ReturnType<typeof updateAllSelectedInvitationTraineesState>
    | ReturnType<typeof updateTraineeDueDateAction>;

const assignUsersBaseReducer: Reducer<AssignUsersState, AssignUsersAction> = (
    state = initialInvitationState,
    action: AssignUsersAction
): AssignUsersState => {
    switch (action.type) {
        case ACTIONS.SEARCH_TRAINER_INVITATION_USERS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_TRAINER_INVITATION_USERS_SUCCESS:
            return {
                ...state,
                data: action?.isRefreshed
                    ? action.users
                    : action.offset > 0 && state.data?.items
                    ? {
                          ...action.users,
                          items: state.data.items.concat(action.users.items),
                      }
                    : action.users,

                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_TRAINER_INVITATION_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.SEND_TRAINEE_INVITATION_SUCCESS: {
            const updatedArray = state.update ? [...state.update] : [];
            updatedArray.push(action.inviteType);
            return {
                ...state,
                update: updatedArray,
            };
        }
        case ACTIONS.UPDATE_TRAINEE_DUE_DATE: {
            const traineeItems = state.data?.items.map(trainee => {
                let dueDate = trainee.dueDate

                if (action.trainees.includes(trainee.id)) {
                    dueDate = action.dueDate ?? undefined
                }

                return {
                    ...trainee,
                    dueDate
                }
            })
            
            return {
                ...state,
                data: state.data ? { ...state.data, items: traineeItems ?? state.data?.items } : state.data
            }
        }
        case ACTIONS.UPDATE_SELECTED_INVITATION_TRAINEES_STATE: {
            const selectedUserIds = [...(state.selectedUsers || [])];

            const selectedUserIndex = selectedUserIds.findIndex(
                (item: number) => item === action.userId
            );
            if (selectedUserIndex > -1) {
                selectedUserIds.splice(selectedUserIndex, 1);
            } else {
                selectedUserIds.push(action.userId);
            }

            return {
                ...state,
                selectedUsers: selectedUserIds,
            };
        }
        case ACTIONS.UPDATE_ALL_SELECTED_INVITATION_TRAINEES_STATE:
            return {
                ...state,
                selectedUsers:
                    action.checked || action.partial ? action.userIds : [],
            };
        default:
            return state;
    }
};

export const assignUsersReducer = compose<
    Reducer<AssignUsersState, AssignUsersAction>
>(withLabelsReducer({ reducerName: 'assignUsers' }))(assignUsersBaseReducer);

export const progressReducer = combineReducers({
    updatedProgress: updatedProgressReducer,
    enrollments: practisSetsEnrollmentReducer,
    report: practisSetReportsReducer,
});
