import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyState } from '../../pages/CompanySettings/store/reducers';
import { getProfileState } from '../../pages/UserProfile/store/reducers';

export function useSession() {
    const profile = useSelector(getProfileState);
    const company = useSelector(getCompanyState);

    return useMemo(
        () => !!profile?.id && (!!company?.id || !!profile?.companyId) 
            ? `${profile.id}/${company?.id || profile.companyId}`
            : '',
        [profile?.id, profile?.companyId, company?.id]
    );
};

export function useSessionEffect(effect: () => void, deps: any[] = []) {
    const session = useSession();

    useEffect(() => {
        if (session) {
            effect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, ...deps]);
};

