import { useCallback } from 'react';

import { TableStateHelper, TableStateValues } from './types';

export const useTableStateHelper = () =>
    useCallback((params: TableStateHelper) => {
        const {
            itemsCount,
            appliedFilters,
            searchTerm,
            startDate,
            endDate,
            filtersAreAlwaysEnabled
        } = params;

        let tableState: TableStateValues = {
            disableFilters: true,
            disableSearch: true,
            disableSorting: true,
            showEmptyState: true,
            showNoEntriesState: true,
            showNoSearchResultsState: true,
            showNoFilterResultsState: true,
            showNoCalendarResultsState: true,
        };

        const noItems = !itemsCount || itemsCount < 1;
        const noSearchTerm = !searchTerm || searchTerm.length < 1;
        const noFilters = !appliedFilters || appliedFilters < 1;
        const noCalendarFilters = !startDate && !endDate;

        if (noItems && noSearchTerm && noFilters && noCalendarFilters) {
            tableState.disableFilters = true;
            tableState.disableSearch = true;
            tableState.disableSorting = true;
            tableState.showEmptyState = true;
            tableState.showNoEntriesState = true;
            tableState.showNoSearchResultsState = false;
            tableState.showNoFilterResultsState = false;
            tableState.showNoCalendarResultsState = false;
        } else if (noItems && !noSearchTerm && noFilters && noCalendarFilters) {
            tableState.disableFilters = true;
            tableState.disableSearch = false;
            tableState.disableSorting = true;
            tableState.showEmptyState = true;
            tableState.showNoEntriesState = false;
            tableState.showNoSearchResultsState = true;
            tableState.showNoFilterResultsState = false;
            tableState.showNoCalendarResultsState = false;
        } else if (noItems && noSearchTerm && noFilters && !noCalendarFilters) {
            tableState.disableFilters = true;
            tableState.disableSearch = true;
            tableState.disableSorting = true;
            tableState.showEmptyState = true;
            tableState.showNoEntriesState = false;
            tableState.showNoSearchResultsState = false;
            tableState.showNoFilterResultsState = false;
            tableState.showNoCalendarResultsState = true;
        } else if (noItems && noSearchTerm && !noFilters && !noCalendarFilters) {
            tableState.disableFilters = false;
            tableState.disableSearch = true;
            tableState.disableSorting = true;
            tableState.showEmptyState = true;
            tableState.showNoEntriesState = false;
            tableState.showNoSearchResultsState = false;
            tableState.showNoFilterResultsState = false;
            tableState.showNoCalendarResultsState = true;
        } else if (noItems && noSearchTerm && !noFilters && noCalendarFilters) {
            tableState.disableFilters = false;
            tableState.disableSearch = true;
            tableState.disableSorting = true;
            tableState.showEmptyState = true;
            tableState.showNoEntriesState = false;
            tableState.showNoSearchResultsState = false;
            tableState.showNoFilterResultsState = true;
            tableState.showNoCalendarResultsState = false;
        } else if (noItems && !noSearchTerm && (!noFilters || !noCalendarFilters)) {
            tableState.disableFilters = false;
            tableState.disableSearch = false;
            tableState.disableSorting = true;
            tableState.showEmptyState = true;
            tableState.showNoEntriesState = false;
            tableState.showNoSearchResultsState = true;
            tableState.showNoFilterResultsState = false;
            tableState.showNoCalendarResultsState = false;
        } else {
            tableState.disableFilters = false;
            tableState.disableSearch = false;
            tableState.disableSorting = false;
            tableState.showEmptyState = false;
            tableState.showNoEntriesState = false;
            tableState.showNoSearchResultsState = false;
            tableState.showNoFilterResultsState = false;
            tableState.showNoCalendarResultsState = false;
        }
        if (filtersAreAlwaysEnabled) {
            tableState.disableFilters = false;
        }
        return tableState;
    }, []);

