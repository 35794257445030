import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarRoleplaysLarge = forwardRef<SVGSVGElement, IconProps>(
    ({ className, dataTest}, ref) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56" fill="none" data-test={dataTest}>
            <g clip-path="url(#kifedtetla)">
                <mask id="aiohq7s5hb" maskUnits="userSpaceOnUse" x="0" y="-1" width="57" height="57">
                    <path d="M.5-.002h56v56H.5v-56z" fill="#fff"/>
                </mask>
                <g mask="url(#aiohq7s5hb)" stroke="#B1C0CB" stroke-width="3.379" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M2.14 9.792v28.382l9.02-6.435a6.078 6.078 0 0 1 3.53-1.13h19.84c2.59 0 4.692-2.1 4.692-4.692V5.099H6.833a4.692 4.692 0 0 0-4.692 4.693z"/>
                    <path d="M45.785 17.823h4.383a4.693 4.693 0 0 1 4.692 4.694v28.381l-9.02-6.434a6.078 6.078 0 0 0-3.53-1.13H22.47a4.693 4.693 0 0 1-4.692-4.693v-1.47M31.252 14.037H12.297M31.252 21.693H12.297"/>
                </g>
            </g>
            <defs>
                <clipPath id="kifedtetla">
                    <path fill="#fff" transform="translate(.5)" d="M0 0h56v56H0z"/>
                </clipPath>
            </defs>
        </svg>)
    }
);

export default SidebarRoleplaysLarge;
