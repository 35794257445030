import { useMemo } from "react";
import { EnrollmentInterface, EnrollmentStatus } from "../../../constants/interfaces/Enrollments";
import { UserProfile } from "../../../constants/interfaces/User";
import { UserInfo } from "../../../constants/interfaces/UserInfo";
import { useLoggedInUserProfileState } from "../../../features/users/store/states";
import { hasPermission, NEW_PERMISSIONS } from "../../../features/users/tools";
import { getBulkActionAvailabilityItem, getBulkActionsAvailability } from "../../../tools/bulkActions";
import { UserStatus } from '../../../features/users/store/costants';

export enum ProgressActions {
    VIEW_PRACTIS_SET_REPORT,
    VIEW_PROFILE,
    NUDGE_USER,
    REMIND_TO_START,
    EXPORT_REPORT,
    UNASSIGN_USER,
    SET_DUEDATE
}

function createProgressActionsHelper(loggedInUser: UserProfile | undefined) {
    const canViewPractisSetReport = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.VIEW_ENROLLMENT);
    
    const canViewProfile = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.VIEW_PROFILE);
    
    const canNudgeUser = (user: UserInfo) =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.SEND_NUDGE)
        && user.status === UserStatus.ACTIVE;

    const canChangeDueDate = () => 
        hasPermission(loggedInUser, NEW_PERMISSIONS.SET_DUE_DATE)
    
    const canRemindToStart = (trainee: EnrollmentInterface) => {
       return trainee.status !== EnrollmentStatus.COMPLETED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.RESEND_INVITATION)
            && trainee.user.status === UserStatus.ACTIVE
    };
    
    const canExportReport = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.EXPORT_LIST_PRACTIS_SET_USER);
    
    const canUnassignUser = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.UNASSIGN_ENROLLMENT);
    
    const getBulkActions = (trainees?: EnrollmentInterface[]) => {
        const users = trainees?.map(trainee => trainee.user) ?? [];
        return getBulkActionsAvailability(
            trainees,
            [
                getBulkActionAvailabilityItem(
                    ProgressActions.NUDGE_USER,
                    users,
                    canNudgeUser
                ),
                getBulkActionAvailabilityItem(
                    ProgressActions.REMIND_TO_START,
                    trainees,
                    canRemindToStart
                ),
                getBulkActionAvailabilityItem(
                    ProgressActions.EXPORT_REPORT,
                    users,
                    canExportReport
                ),
                getBulkActionAvailabilityItem(
                    ProgressActions.UNASSIGN_USER,
                    users,
                    canUnassignUser
                ),
                getBulkActionAvailabilityItem(
                    ProgressActions.SET_DUEDATE,
                    users,
                    canChangeDueDate
                ),
            ]
        );
    }

    return {
        canViewPractisSetReport,
        canViewProfile,
        canNudgeUser,
        canRemindToStart,
        canExportReport,
        canUnassignUser,
        canChangeDueDate,
        getBulkActions,
    };
}

export type ProgressActionsHelper = ReturnType<typeof createProgressActionsHelper>;

export function useProgressActionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createProgressActionsHelper(loggedInUser),
        [loggedInUser]
    );
}