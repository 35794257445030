import styled from "styled-components";

export const ProfilePageContainer = styled.div`
    
`;

export const Title = styled.div`
    font-size: 20px;
    color: ${props => props.theme.Colors.dark};
    margin-bottom: 40px;
`;

export const Content = styled.div`

`;
export const Actions = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
`;

export const PhotoLoadingContainer = styled.div`
    position: absolute;
    z-index: 1;
    display: flex;
    width: 134px;
    height: 134px;
    justify-content: center;
    align-items: center;
`