import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Roles } from '../../../constants/interfaces/Roles';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { useLoadUserRolesApi } from '../../../api';
import {
    loadUserRolesFailure,
    loadUserRolesStart,
    loadUserRolesSuccess,
} from './actions';
import { useUserRolesState } from './states';

export const useLoadUserRolesService = () => {
    const dispatch = useDispatch();
    const loadUserRolesApi = useLoadUserRolesApi();
    const showMessage = useShowMessage();
    return useCallback(() => {
        dispatch(loadUserRolesStart());
        loadUserRolesApi()
            .then(data => {
                dispatch(loadUserRolesSuccess(data));
            })
            .catch((error: ErrorResult) => {
                dispatch(loadUserRolesFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, loadUserRolesApi, showMessage]);
};

export const useUserRolesService = () => {
    const state = useUserRolesState();

    const loading = state.loading;

    const rolesByName = useMemo(
        () => state.list?.reduce(
            (result, role) => {
                result[role.name] = role;
                return result;
            },
            {} as Record<string, Roles>
        ),
        [state.list]
    );

    const getRoleByName = useCallback(
        (roleName: string) => rolesByName[roleName],
        [rolesByName]
    );

    return useMemo(() => ({
        loading,
        getRoleByName,
    }), [
        loading,
        getRoleByName,
    ]);
};
