export enum UserStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
}

export const UserItemsStatusNames = {
    [UserStatus.ACTIVE as string]: 'Registered',
    [UserStatus.DELETED as string]: 'Inactive',
    [UserStatus.PENDING as string]: 'Pending Registration',
};

export const USER_NOT_FOUND_ERROR_MESSAGE = 'User can\'t be found!';