import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ExportReportIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className={className}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.893 1.6a.316.316 0 0 0-.195.075.307.307 0 0 0-.076.192v12.266a.272.272 0 0 0 .27.267h9.194a.262.262 0 0 0 .253-.163.254.254 0 0 0 .017-.104V6.4h-2.974a1.904 1.904 0 0 1-1.342-.544 1.855 1.855 0 0 1-.55-1.323V1.6H4.892zm6.218.597v2.336a.272.272 0 0 0 .27.267h2.37l-2.64-2.603zM3 1.867c0-.495.2-.97.554-1.32C3.91.197 4.391 0 4.893 0h5.526a1.883 1.883 0 0 1 1.33.544l3.7 3.648A1.845 1.845 0 0 1 16 5.504v8.63a1.834 1.834 0 0 1-.559 1.33 1.885 1.885 0 0 1-1.355.536H4.893a1.905 1.905 0 0 1-1.342-.544A1.856 1.856 0 0 1 3 14.133V1.867z"
                    fill="currentColor"
                />
                <path
                    d="M1.048 5h5.904A1.048 1.048 0 0 1 8 6.048v5.903A1.048 1.048 0 0 1 6.952 13H1.258A1.258 1.258 0 0 1 0 11.742V6.048A1.048 1.048 0 0 1 1.048 5z"
                    fill="currentColor"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.055 9.584 2.758 12H1l2.085-3.017L1.23 6h1.806l1.019 2.315L5.388 6H7L5.048 8.983 7 11.933 5.303 12 4.055 9.584z"
                    fill="#fff"
                />
            </svg>
        );
    }
);

export default ExportReportIcon;
