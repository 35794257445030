import { useCallback, useState } from 'react';
import ROUTES from '../../../routes/routes';
import { useHistory } from 'react-router';
import { Button } from '../../../ui/components/Button';
import InputPhone from '../../../ui/components/input/PhoneInput/PhoneInput';
import BackArrowImage from '../../../assets/images/icons/back-arrow.svg';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import { useGetPhoneCodeApi } from '../../../api';
import {
    NOT_FOUND_ERROR_MESSAGE,
    TOO_MANY_REQUESTS_MESSAGE,
} from './constants';
import {
    Title,
    BackArrow,
    Description,
    MobileNumberContainer,
    SecondaryText,
    ErrorText,
    ButtonContainer,
} from './styles';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

const MobileNumberLogin = () => {
    const history = useHistory();
    const getPhoneCode = useGetPhoneCodeApi();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showLoginWithEmail, setShowLoginWithEmail] = useState(false);
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [isSending, setIsSending] = useState<boolean>(false);

    useHtmlPageTitle('Log In With Mobile Number');

    /**
     * @description saves changed mobile number and clears error message
     * @function handleMobileNumberChange
     * @param { string } value
     * @returns { void }
     */
    const handleMobileNumberChange = (value: string) => {
        setMobileNumber(value);
        setErrorMessage(null);
    };

    /**
     * @description returns error message based on error code
     * @function getErrorMessage
     * @returns { string }
     */
    const getErrorMessage = useCallback((error: ErrorResult) => {
        switch (error.code) {
            case 404:
                return NOT_FOUND_ERROR_MESSAGE;
            case 429:
                return TOO_MANY_REQUESTS_MESSAGE;
            default:
                return error.message;
        }
    }, []);

    /**
     * @description sends API request to ask for a verification code
     * @function handleSendCode
     * @returns { void }
     */
    const handleSendCode = useCallback(() => {
        setIsSending(true);
        getPhoneCode(mobileNumber)
            .then((response: any) => {
                setIsSending(false);
                history.push(
                    ROUTES.AUTH_PAGES.MOBILE_NUMBER_VERIFY.replace(
                        ':phoneNumber',
                        mobileNumber.toString()
                    )
                );
            })
            .catch((error: ErrorResult) => {
                setShowLoginWithEmail(true);
                setIsSending(false);
                setErrorMessage(getErrorMessage(error));
            });
    }, [mobileNumber, history, getPhoneCode, getErrorMessage]);

    /**
     * @description redirects to login page
     * @function redirectToLogin
     * @returns { void }
     */
    const redirectToLogin = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    }, [history]);

    return (
        <LoginAuthWrapper contentWidth={343} heightToShowScroll={500}>
            <Title>
                <BackArrow src={BackArrowImage} onClick={redirectToLogin} />
                Log In With Mobile Number
            </Title>
            <Description>
                Enter the mobile number associated with your account. We will
                send you a text message with a verification code. Carrier fees
                may apply.
            </Description>
            <MobileNumberContainer>
                <InputPhone
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    height="56px"
                    fontSize="15px"
                    autoFocus={true}
                    className="grey-border-on-focus"
                    label=""
                    hasManualError={!!errorMessage}
                />
            </MobileNumberContainer>
            <SecondaryText>
                {!!errorMessage ? (
                    <ErrorText>{errorMessage}</ErrorText>
                ) : (
                    'If you have not added your mobile number to your account yet, please log in with email first.'
                )}
            </SecondaryText>
            <ButtonContainer>
                <Button
                    height="56px"
                    width="100%"
                    buttonWidth="100%"
                    fontSize={15}
                    disabled={!mobileNumber || mobileNumber.length < 3}
                    loading={isSending}
                    type="button"
                    action={handleSendCode}
                >
                    Send Code
                </Button>
            </ButtonContainer>
            {showLoginWithEmail && (
                <ButtonContainer>
                    <Button
                        height="56px"
                        width="100%"
                        buttonWidth="100%"
                        fontSize={15}
                        type="button"
                        action={redirectToLogin}
                        variant="inverse"
                    >
                        Log In With Email
                    </Button>
                </ButtonContainer>
            )}
        </LoginAuthWrapper>
    );
};

export default MobileNumberLogin;
