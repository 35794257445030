import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const StyledLoadingComponent = styled.div`
    width: 136px;
    height: 40px;
    display: flex;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
`;

const LoadingIcon = styled.div`
    width: 40px;
    background: ${props => props.theme.Colors.darkTwo};
    color: ${props => props.theme.Colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingText = styled.div`
    flex: 1;
    background: ${props => props.theme.Colors.dark};
    color: ${props => props.theme.Colors.white};
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)``;

export const LoadingComponent: React.FC<{ shouldDelay?: boolean }> = memo(({ shouldDelay = true }) => {
    const [show, setShow] = useState(!shouldDelay)

    useEffect(() => {
        const timeoutId = setTimeout(() => setShow(true), shouldDelay ? 2000 : 0)

        return () => {
            clearTimeout(timeoutId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (show ?
        <StyledLoadingComponent>
            <LoadingIcon>
                <StyledCircularProgress size={16} color="inherit" />
            </LoadingIcon>
            <LoadingText>Loading...</LoadingText>
        </StyledLoadingComponent>
     : <></>);
});
