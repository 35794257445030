import { FC, useState } from 'react';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input';
import { Variables } from '../../../../theme/variables';
import CountrySelector from './CountrySelector';
import { en } from './locales';
import Trash from '../../../../ui/icons/Trash';
import { Container, Label, TrashIconContainer, InputWrapper } from './styles';

const InputPhone: FC<{
    value: number | string;
    onChange(value?: number | string): void;
    label: string;
    placeholder?: string;
    width?: string;
    height?: string;
    background?: string;
    fontColor?: string;
    labelColor?: string;
    disabledLabelColor?: string;
    fontSize?: string;
    className?: string;
    field?: any;
    error?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    hasManualError?: boolean;
    dataTest?: string;
    onDeleteNumber?: () => void;
    showDeleteNumberBtn?: boolean;
}> = ({
    value,
    onChange,
    label,
    placeholder,
    width,
    height,
    background,
    fontColor,
    labelColor,
    disabledLabelColor,
    className,
    fontSize,
    field,
    error,
    disabled,
    autoFocus,
    hasManualError,
    dataTest,
    onDeleteNumber,
    showDeleteNumberBtn
}) => {
    const [open, setOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<keyof typeof en>(
        'US'
    );

    const handleCountryChange = (value?: string) => {
        if (value) {
            setSelectedCountry(value);
            onChange();
        }
    };

    return (
        <Container
            width={width ? width : '100%'}
            height={height ? height : '56px'}
            background={background ? background : Variables.Colors.darkTwo}
            fontSize={fontSize ? fontSize : '13px'}
            className={className}
            error={error}
            hasManualError={hasManualError}
            data-test={dataTest}
        >
            <Label
                color={
                    disabled
                        ? disabledLabelColor ?? Variables.Colors.cloudyBlue
                        : labelColor ?? Variables.Colors.cloudyBlue
                }
            >
                {label}
            </Label>
            <InputWrapper
                fontSize={fontSize ? fontSize : '13px'}
                color={fontColor ? fontColor : Variables.Colors.white}
                disabled={disabled}
                useTopPadding={!!label}
            >
                <Input
                    {...field}
                    autoFocus={autoFocus}
                    country={selectedCountry}
                    countryCallingCodeEditable={false}
                    defaultCountry={selectedCountry}
                    initialValueFormat="national"
                    countrySelectComponent={({ value }) => {
                        if (value) {
                            setSelectedCountry(value);
                        }
                        return (
                            <CountrySelector
                                open={open}
                                disabled={disabled}
                                setOpen={setOpen}
                                fontColor={fontColor}
                                fontSize={fontSize ? fontSize : '13px'}
                                selectedCountry={selectedCountry}
                                setSelectedCountry={handleCountryChange}
                            />
                        );
                    }}
                    maxLength={50}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                />
            </InputWrapper>
            {showDeleteNumberBtn && 
                <TrashIconContainer onClick={e => {
                    e.stopPropagation();
                    onDeleteNumber && onDeleteNumber()}}
                    data-test={dataTest && `${dataTest}-delete`}
                >
                    <Trash/>
                </TrashIconContainer>
            }
        </Container>
    );
};

export default InputPhone;
