import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 1px;
    padding: 5px 24px;
    max-height: 270px;
    overflow: auto;
    overflow-x: hidden;
`;

export const SkeletonContainer = styled.div`
    padding-top: 13px;
`;

export const EmptyStateContainer = styled.div`
    padding-top: 13px;
`;
