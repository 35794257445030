import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    customTableColumnStyle: {
        minWidth: 250,
    },

    avatarTableCellCustomStyle: {
        padding: 0,
    },
}));
