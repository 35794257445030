import { FC, memo } from 'react';

import { QuestionTooltip } from '../../../../Tooltip/QuestionTooltip';
import { MultiViewText, Th, TitleContainer } from './style';
import { TableHeaderCellProps } from './type';

const TableHeaderCellMultiView: FC<TableHeaderCellProps> = props => {
    const {
        width,
        disabled,
        onClick,
        children,
        className,
        title,
        withTooltip,
        tooltipText,
        isHtmlTooltip,
        titleWidth,
        dataTest
    } = props;

    return (
        <Th
            width={width}
            clickable={!!onClick}
            className={className}
            disabled={disabled}
            isMultiple
            data-test={dataTest}
        >
            <TitleContainer titleWidth={titleWidth} disabled={disabled}>
                {' '}
                {title}{' '}
                {withTooltip && tooltipText && (
                    <div style={{ cursor: 'pointer' }}>
                        <QuestionTooltip
                            isHtmlContent={isHtmlTooltip}
                            text={tooltipText}
                        />
                    </div>
                )}
            </TitleContainer>
            <MultiViewText>{children}</MultiViewText>
        </Th>
    );
};

export default memo(TableHeaderCellMultiView);
