import {
    applyMiddleware,
    createStore as reduxCreateStore,
    Reducer,
    compose as composeSource,
    StoreEnhancer,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ThunkContext } from '../tools/thunk/ThunkContext';

export const createStore = (
    rootReducer: Reducer,
    thunkContext: ThunkContext
) => {
    const middlewareEnhancer = applyMiddleware(
        thunk.withExtraArgument(thunkContext)
    );
    let compose = composeSource;
    if (process.env.REACT_APP_DEV_ENV === 'local') {
        compose = composeWithDevTools({
            trace: true,
        });
    }
    const composedEnhancer: StoreEnhancer = compose(middlewareEnhancer);

    return reduxCreateStore(rootReducer, composedEnhancer);
};
