import { ChangeEvent, useEffect, useState } from 'react';
import {
    enterPressed,
    escPressed
} from '../../../../../../helpers/functions/key-down-helpers';
import { InputWithButtonsProps } from './types';
import InputWithButtonsView from './view';

function InputWithButtonsController({
    height,
    maxLength,
    placeholder,
    value,
    dataTest,
    onSave
}: InputWithButtonsProps) {
    const [tempValue, setTempValue] = useState(value);
    const [showButtons, setShowButtons] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [hasError, setHasError] = useState(false);

    /**
    * @description handles input focus event
    * @function handleFocus
    * @returns { void }
    */
    const handleFocus = () => setShowButtons(true);

    /**
    * @description handles input value change
    * @function handleValueChange
    * @param { ChangeEvent<HTMLInputElement> } event
    * @returns { void }
    */
    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setTempValue(newValue);
        setHasError(false);
    }

    /**
    * @description checks if save action is disabled
    * @function isSaveDisabled
    * @returns { boolean }
    */
    const isSaveDisabled = () => !tempValue || isDisabled;

    /**
    * @description handles ENTER and ESC events on input key down
    * @function handleKeyDown
    * @param { KeyboardEvent } event
    * @returns { void }
    */
    const handleKeyDown = (event: KeyboardEvent) => {
        const target = event.currentTarget as HTMLInputElement;
        if (enterPressed(event) && !isSaveDisabled()) {
            handleSave();
            target.blur();
        } else if (escPressed(event) && !isDisabled) {
            handleCancel();
            target.blur();
        }
    };

    /**
    * @description handles cancel action
    * @function handleCancel
    * @returns { void }
    */
    const handleCancel = () => {
        Promise.resolve().then(() => {
            setShowButtons(false);
            setTempValue(value);
            setHasError(false);
        });
    }

    /**
    * @description handles save action
    * @function handleSave
    * @returns { void }
    */
    const handleSave = () => {
        if (tempValue !== value) {
            Promise.resolve().then(() => {
                setIsDisabled(true);
                setHasError(false);
            });
            onSave(tempValue!)
                .then(() => {
                    Promise.resolve().then(() => {
                        setIsDisabled(false);
                        setShowButtons(false);
                    });
                    })
                .catch(() => {
                    Promise.resolve().then(() => {
                        setIsDisabled(false);
                        setHasError(true);
                    });
                });
        } else {
            setShowButtons(false);
        }
    }

    useEffect(() => {
        setTempValue(value);
    }, [value]);
    
    return <InputWithButtonsView
        height={height}
        maxLength={maxLength}
        placeholder={placeholder}
        value={tempValue}
        hasError={hasError}
        dataTest={dataTest}
        showButtons={showButtons}
        isDisabled={isDisabled}
        isSaveDisabled={isSaveDisabled()}
        onChange={handleValueChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onCancel={handleCancel}
        onSave={handleSave}
    />
}

export default InputWithButtonsController;