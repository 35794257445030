import { AppState } from './reducers';

function selectAppState(state: AppState) {
    return state as AppState;
}

export function selectTrainerState(state: AppState) {
    return selectAppState(state).progress;
}

export function selectTainerPractisSetState(state: AppState) {
    return selectAppState(state).practisSetsTraining;
}

export function selectUpdatedProgressState(state: AppState) {
    return selectTrainerState(state).updatedProgress;
}

export function PractisSetsEnrollmentState(state: AppState) {
    return selectTrainerState(state).enrollments;
}

export function TrainerInvitationState(state: AppState) {
    return selectAppState(state).assignUsers;
}

export function PractisSetReportsState(state: AppState) {
    return selectTrainerState(state).report;
}

export function UsersState(state: AppState) {
    return selectAppState(state).users;
}

export function AuthState(state: AppState) {
    return selectAppState(state).auth;
}

export function InvitationsState(state: AppState) {
    return selectAppState(state).invitations;
}

export function UserPerformanceState(state: AppState) {
    return selectAppState(state).performance;
}

export function UpdatedUsersState(state: AppState) {
    return selectAppState(state).updatedUsers;
}

export function UpdatedInvitationsState(state: AppState) {
    return selectAppState(state).updatedInvitations;
}

export function UpdatedUserPractisSetsState(state: AppState) {
    return selectAppState(state).updatedUserPractisSets;
}

export function VoiceGenerationState(state: AppState) {
    return selectAppState(state).voiceGeneration;
}

export function LibraryPractisSetsState(state: AppState) {
    return selectAppState(state).libraryPractisSets;
}

export function UpdatedLibraryPractisSetsState(state: AppState) {
    return selectAppState(state).libraryUpdatedPractisSets;
}

export function LibraryScenariosState(state: AppState) {
    return selectAppState(state).libraryScenarios;
}

export function UpdatedLibraryScenariosState(state: AppState) {
    return selectAppState(state).libraryUpdatedScenarios;
}

export function LibraryChallengesState(state: AppState) {
    return selectAppState(state).libraryChallenges;
}

export function UpdatedLibraryChallengesState(state: AppState) {
    return selectAppState(state).libraryUpdatedChallenges;
}

export function ChallengeState(state: AppState) {
    return selectAppState(state).challenge;
}

export function SubmissionsState(state: AppState) {
    return selectAppState(state).submissions;
}

export function SubmissionPaginationState(state: AppState) {
    return selectAppState(state).submissionPagination;
}

export function SubmissionDetailsState(state: AppState) {
    return selectAppState(state).submissionDetails;
}

export function AccuracyScoreState(state: AppState) {
    return selectAppState(state).accuracyscore;
}

export function UpdatedSubmissionsState(state: AppState) {
    return selectAppState(state).updatedSubmissions;
}

export function CompanyVoiceSettingsState(state: AppState) {
    return selectAppState(state).companyVoiceSettings;
}

export function LabelsState(state: AppState) {
    return selectAppState(state).labels;
}

export function LabelsConfigState(state: AppState) {
    return selectAppState(state).labelsConfig;
}

export function UpdatedLabelsState(state: AppState) {
    return selectAppState(state).updatedLabels;
}

export function SelectPermissionsState(state: AppState) {
    const profile = selectAppState(state).profile.info;
    return profile && profile.permissions ? profile.permissions : [];
}

export function SelectLogsState(state: AppState) {
    return selectAppState(state).logs;
}
export function SelectTelepromterState(state: AppState) {
    return selectAppState(state).telepromter;
}

export function DraftsState(state: AppState) {
    return selectAppState(state).drafts;
}

export function DraftEditState(state: AppState) {
    return selectAppState(state).draftEdit;
}

export function UpdatedDraftsState(state: AppState) {
    return selectAppState(state).updatedDrafts;
}

export function SelectAiLogsState(state: AppState) {
    return selectAppState(state).aiLogs;
}

export function TeamsState(state: AppState) {
    return selectAppState(state).teams;
}

export function UpdatedMembersState(state: AppState) {
    return selectAppState(state).updatedMembers;
}

export function UpdatedTeamsState(state: AppState) {
    return selectAppState(state).updatedTeams;
}

export function TeamMembersState(state: AppState) {
    return selectAppState(state).teamMembers;
}

export function TeamsEditState(state: AppState) {
    return selectAppState(state).teamEdit;
}

export function RolesState(state: AppState) {
    return selectAppState(state).roles;
}

export function CompanyAdminsState(state: AppState) {
    return selectAppState(state).companyAdmins;
}

export function CompanyUserStatsState(state: AppState) {
    return selectAppState(state).companyUserStats;
}

export function ReportsState(state: AppState) {
    return selectAppState(state).reports;
}

export function OverdueLearnersState(state: AppState) {
    return selectAppState(state).overdueLearners;
}

export function ScenarioRepsState(state: AppState) {
    return selectAppState(state).scenarioReps;
}

export function RoleplaysState(state: AppState) {
    return selectAppState(state).roleplays
}

export function RoleplayState(state: AppState) {
    return selectAppState(state).roleplay
}