import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import {
    PractisSets,
    PractisSetStatuses,
} from '../../../constants/interfaces/PractisSets';
import {
    CREATE_PRACTIS_SET_ACTION,
    UPDATE_PRACTIS_SET_ACTION,
    UPDATE_PRACTIS_SET_STATUS_ACTION,
} from '../../../features/library/services/LibraryBulkActionsService/constants';
import { updateLibraryPractisSetSuccess } from '../../../features/library/store/actions';
import ROUTES from '../../../routes/routes';
import { pushModal, useHistory } from '../../../tools/router';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import { useModifyPractisSetService } from './store/services';
import { useUpdatePractisSetStatusApi } from '../../../api';

/**
 * @function useCreateEditPractisSetSuccessCallback
 * @param { Function } setLoadingSave
 * @param { number | undefined } existedPractisSetId
 * @param { Record<string, unknown> | undefined } responses
 * @returns { void }
 */
export const useCreateEditPractisSetSuccessCallback = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const showMessage = useShowMessage();

    return useCallback(
        (
            setLoadingSave: (isLoading: boolean) => void,
            existedPractisSetId?: number,
            responses?: Record<string, unknown>
        ) => {
            let newPractisSetId: number | null = null;

            setLoadingSave(false);

            if (!isEmpty(responses)) {
                const createPractisSetResponse = responses![
                    CREATE_PRACTIS_SET_ACTION
                ] as PractisSets | undefined;

                const editPractisSetResponse = responses![
                    UPDATE_PRACTIS_SET_ACTION
                ] as PractisSets | undefined;

                const updatePractisSetStatusResponse = responses![
                    UPDATE_PRACTIS_SET_STATUS_ACTION
                ] as PractisSets[] | undefined;

                if (createPractisSetResponse) {
                    dispatch(
                        updateLibraryPractisSetSuccess(
                            createPractisSetResponse,
                            'create'
                        )
                    );

                    newPractisSetId = createPractisSetResponse?.id ?? null;
                }

                if (editPractisSetResponse) {
                    dispatch(
                        updateLibraryPractisSetSuccess(
                            editPractisSetResponse,
                            'update'
                        )
                    );
                }

                const isPublished =
                    updatePractisSetStatusResponse?.[0]?.status ===
                    PractisSetStatuses.ACTIVE;

                const isEditMode = existedPractisSetId;

                if (!isPublished) {
                    !isEditMode &&
                        showMessage('Practis Set Saved as Draft', 'success');
                    history.goBack();
                } else {
                    history.push(ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL);
                    if (newPractisSetId) {
                        pushModal(
                            history,
                            ROUTES.LIBRARY_SETTINGS.PRACTISSETS.QUICK_ASSIGNMENT.replace(
                                ':practisSetId',
                                newPractisSetId.toString()
                            )
                        );
                    }
                    !isEditMode &&
                        showMessage('Practis Set Published', 'success');
                }
            }
        },
        [dispatch, history, showMessage]
    );
};

/**
 * @function useCreateEditPractisSetFailedCallback
 * @param { Function } setLoadingSave
 * @param { Function } setTitleError
 * @param { ErrorResult | undefined } error
 * @param { Record<string, unknown> | undefined } completedResponses
 * @returns { void }
 */
export const useCreateEditPractisSetFailedCallback = () => {
    const modifyPractisSet = useModifyPractisSetService();

    return useCallback(
        (
            setLoadingSave: (isLoading: boolean) => void,
            setTitleError: (isError: boolean) => void,
            error?: ErrorResult,
            completedResponses?: Record<string, unknown>
        ) => {
            if (!isEmpty(completedResponses)) {
                const createPractisSetResponse = completedResponses![
                    CREATE_PRACTIS_SET_ACTION
                ] as PractisSets | undefined;

                const createdPractisSetId = createPractisSetResponse?.id;

                // The bulk action failed in other steps
                // but new practisSet created
                // in the next try it goes to
                // edit practisSet action.
                if (createdPractisSetId) {
                    modifyPractisSet(createdPractisSetId, 'id');
                }
            }

            setLoadingSave(false);

            if (error?.message?.includes('already exists')) {
                setTitleError(true);
            }
        },
        [modifyPractisSet]
    );
};

/**
 * @function useHandleArchivePractisSet
 * @returns { void }
 */
export const useHandleArchivePractisSet = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const updatePractisSetStatus = useUpdatePractisSetStatusApi();

    return useCallback(
        (practisSetId: number) => {
            updatePractisSetStatus('archive', [practisSetId]).then(response => {
                dispatch(updateLibraryPractisSetSuccess(response, 'update'));

                history.goBack();
            });
        },
        [dispatch, history, updatePractisSetStatus]
    );
};

/**
 * @function useHandleRestoreArchivedPractisSet
 * @param { number } practisSetId
 * @returns
 */
export const useHandleRestoreArchivedPractisSet = () => {
    const dispatch = useDispatch();
    const updatePractisSetStatus = useUpdatePractisSetStatusApi();
    const modifyPractisSet = useModifyPractisSetService();

    return useCallback(
        (practisSetId?: number) => {
            if (practisSetId) {
                updatePractisSetStatus('draft', [practisSetId]).then(
                    (response: PractisSets) => {
                        dispatch(
                            updateLibraryPractisSetSuccess(response, 'update')
                        );
                        modifyPractisSet(
                            PractisSetStatuses.DRAFT,
                            'status',
                            true
                        );
                    }
                );
            }
        },
        [dispatch, modifyPractisSet, updatePractisSetStatus]
    );
};