import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { CARDS, useDynamicCardHeight, useDynamicCardWidth } from '../configs';
import usePageVisibility from '../../../helpers/hooks/usePageVisibility';
import {
    isSafari,
    isOpera,
    isEdge,
    isChrome,
    isFirefox,
} from '../../../helpers/functions/detect-browsers';

const StyledCardContent = styled(motion.div)`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    justify-content: center;
`;

const StyledCard = styled(motion.div)<{ width: number; height: number }>`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
`;

const StyledNextCard = styled(motion.div)<{
    width: number;
    height: number;
    margin: number;
}>`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    margin-top: -${props => props.margin}px;
    margin-left: ${props =>
        isSafari() || isOpera() || isEdge()
            ? props.width + 80
            : (props.width + 80) * 2}px;
    position: absolute;
`;

const StyledCounter = styled(motion.div)<{
    percentage: number;
    width: number;
    height: number;
    margin?: number;
}>`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    background: ${props => props.theme.Colors.white};
    opacity: 0.8;
    border-radius: 6px;
    z-index: 2;
    margin: ${props => (props.margin ? props.margin : 32)}px 0;
    position: relative;
    overflow: hidden;
    &:before {
        display: block;
        position: absolute;
        content: '';
        background: ${props => props.theme.Colors.softBlue};
        bottom: 0;
        width: ${props => props.percentage}%;
        height: 100%;
        transition: ${props =>
            props.percentage > 0 ? 'width 1s linear' : 'width 0.2s linear'};
        left: 0;
        border-radius: 6px;
    }
`;

const currentCardAnimation = {
    initial: { opacity: 1, scale: 1 },
    center: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.5 },
};

const nextCardAnimations = {
    enter: {
        x: 528,
        opacity: 0,
    },
    center: {
        x: 0,
        opacity: 0.5,
    },
    exit: (width: number) => {
        return {
            x: -(width + 80),
            opacity: 1,
        };
    },
};

const CardContent: FC<{
    card: any;
    counterHeight: number;
    counterWidth: number;
    counterMargin?: number;
}> = ({ card: LandingCard, counterHeight, counterWidth, counterMargin }) => {
    const [card, setCard] = useState({ current: 0, next: 1 });
    const [counter, setCounter] = useState(0);
    let intervalId = useRef<any>();
    const browserTabVisible = usePageVisibility();

    const goNext = useCallback(() => {
        if (card.next === CARDS.length - 1) {
            setCard({ current: card.next, next: 0 });
        } else {
            setCard({ current: card.next, next: card.next + 1 });
        }
    }, [card.next]);

    useEffect(() => {
        intervalId.current = setTimeout(() => {
            if (browserTabVisible) {
                if (counter === 5) {
                    setCounter(0);
                    goNext();
                } else {
                    setCounter(counter + 1);
                }
            }
        }, 1000);
        return () => {
            clearInterval(intervalId.current);
        };
    }, [goNext, counter, browserTabVisible]);

    const clearCounter = () => {
        clearInterval(intervalId.current);
        setCounter(0);
    };

    const cardWidth = useDynamicCardWidth();
    const cardHeight = useDynamicCardHeight();

    return (
        <StyledCardContent>
            <AnimatePresence initial={false} exitBeforeEnter>
                <StyledCard
                    width={cardWidth}
                    height={cardHeight}
                    key={card.current}
                    initial="initial"
                    animate="visible"
                    exit={{
                        scale: [1, 0.2, 0.2, 0, 0],
                        opacity: [1, 0.2, 0, 0, 0],
                    }}
                    variants={currentCardAnimation}
                    transition={{
                        duration: 1,
                    }}
                >
                    <LandingCard
                        image={CARDS[card.current].image}
                        description={CARDS[card.current].description}
                        goNext={() => {
                            clearCounter();
                            goNext();
                        }}
                    />
                </StyledCard>
            </AnimatePresence>
            <AnimatePresence initial={false} custom={cardWidth}>
                <StyledNextCard
                    width={cardWidth}
                    height={cardHeight}
                    margin={
                        counterMargin
                            ? isChrome() || isFirefox()
                                ? 40
                                : 20
                            : isChrome() || isFirefox()
                            ? 76
                            : 38
                    }
                    key={card.next}
                    custom={cardWidth}
                    variants={nextCardAnimations}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: 'spring', stiffness: 100, damping: 16 },
                        duration: 0.2,
                    }}
                >
                    <LandingCard
                        image={CARDS[card.next].image}
                        description={CARDS[card.next].description}
                    />
                </StyledNextCard>
            </AnimatePresence>
            <StyledCounter
                percentage={(counter / 5) * 100}
                width={counterWidth}
                height={counterHeight}
                margin={counterMargin}
            />
        </StyledCardContent>
    );
};

export default CardContent;
