import Camera from '../../icons/Camera';
import TrashBin from '../../icons/TrashBin';
import { Loading } from '../LoadingCopmonent';
import {
    BadgeContainer,
    Container,
    StyledImageContainer,
    TrashContainer,
    LoadingContainer
} from './styles';
import { ImageUploaderViewProps } from './types';

function ImageUploaderView(props: ImageUploaderViewProps) {
    const {
        initialImage,
        fileInputRef,
        className,
        placeHolder,
        editedImage,
        handleImageChange,
        handleInputClick,
        isInProgress,
        dataTest,
        onFileDelete,
        rectStyled
    } = props;
    const imageSrc = editedImage || initialImage;

    return (
        <Container
            className={className}
            onClick={() => fileInputRef?.current?.click()}
            data-test={dataTest && `${dataTest}-container`}
        >
            <input
                id="myInput"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/jpeg, image/png, image/bmp"
                onChange={handleImageChange}
                onClick={handleInputClick}
                data-test={dataTest && `${dataTest}-input`}
            />
            <StyledImageContainer src={!isInProgress && imageSrc} data-test={dataTest && `${dataTest}-image`} rectStyled={rectStyled}>
                {!imageSrc && !isInProgress && placeHolder}
                {isInProgress &&
                    <LoadingContainer>
                        <Loading size={24} />
                    </LoadingContainer>
                }
            </StyledImageContainer>
            {rectStyled && imageSrc ?
                <TrashContainer data-test={dataTest && `${dataTest}-delete`} onClick={(e) => {
                    e.stopPropagation();
                    onFileDelete && onFileDelete();
                }}>
                    <TrashBin />
                </TrashContainer> :
                <BadgeContainer data-test={dataTest && `${dataTest}-camera`} rectStyled={rectStyled}>
                     <Camera />
                </BadgeContainer>
            }
        </Container>
    );
}

export default ImageUploaderView;
