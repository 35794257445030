import { getIn } from 'formik';

import PractisSelect from '../Select/Select';
import { StyledErrorContainer, StyledFormikField } from './style';
import { SelectProps } from './type';

const FormikSelect = ({
    form,
    field,
    items,
    label,
    disabled,
    height,
    fontSize,
    dataTest,
    ...props
}: SelectProps) => {
    let errorContent;
    const error = getIn(form.errors, field.name);
    const touch = getIn(form.touched, field.name);

    errorContent = touch && error && (
        <StyledErrorContainer>{error}</StyledErrorContainer>
    );

    return (
        <StyledFormikField data-test={dataTest}>
            <PractisSelect
                {...field}
                field={field}
                items={items}
                label={label}
                disabled={disabled}
                height={height}
                fontSize={fontSize}
                error={touch && error}
                dataTest={dataTest}
                {...props}
            />
            {errorContent}
        </StyledFormikField>
    );
};

export default FormikSelect;
