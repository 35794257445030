import styled from 'styled-components';

export const StatusText = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: var(--ps-grey-1);
    margin-right: 16px;
`;

export const LastUpdatedText = styled(StatusText)`
    color: var(--ps-grey-2);
    font-weight: 400;
`

export const StatusIcon = styled.div`
    margin-right: 4px;
`