import { CompanyUserStats } from '../../../constants/interfaces/CompanyUserStats';
import { UserV2 } from '../../../constants/interfaces/User';
import { CompanyVoiceSettings } from '../../../constants/interfaces/CompanyVoiceSettings';

export const COMPANY_INFO = 'FETCH_COMPANY_INFO';
export const COMPANY_INFO_SUCCESS = 'FETCH_COMPANY_INFO_SUCCESS';
export const COMPANY_INFO_FAILURE = 'FETCH_COMPANY_INFO_FAILURE';
export const RESET_COMPANY_INFORMATION = 'RESET_COMPANY_INFORMATION';

export enum ACTIONS {
    LOAD_VOICE_SETTINGS_START = 'LOAD_VOICE_SETTINGS_START',
    LOAD_VOICE_SETTINGS_SUCCESS = 'LOAD_VOICE_SETTINGS_SUCCESS',
    LOAD_VOICE_SETTINGS_FAILURE = 'LOAD_VOICE_SETTINGS_FAILURE',

    FETCH_COMPANY_ADMINS_START = 'FETCH_COMPANY_ADMINS_START',
    FETCH_COMPANY_ADMINS_SUCCESS = 'FETCH_COMPANY_ADMINS_SUCCESS',
    FETCH_COMPANY_ADMINS_FAILURE = 'FETCH_COMPANY_ADMINS_FAILURE',
    CLEAR_COMPANY_ADMINS = 'CLEAR_COMPANY_ADMINS',

    FETCH_COMPANY_USER_STATS_START = 'FETCH_COMPANY_USER_STATS_START',
    FETCH_COMPANY_USER_STATS_SUCCESS = 'FETCH_COMPANY_USER_STATS_SUCCESS',
    FETCH_COMPANY_USER_STATS_FAILURE = 'FETCH_COMPANY_USER_STATS_FAILURE',
}

export function fetchCompanyInfo() {
    return {
        type: COMPANY_INFO,
    };
}

export function fetchCompanyInfoSuccess(data: any) {
    return {
        type: COMPANY_INFO_SUCCESS,
        data,
    };
}

export function fetchCompanyInfoFailure(error: any) {
    return {
        type: COMPANY_INFO_FAILURE,
        error,
    };
}

export function loadVoiceSettingsStart() {
    return {
        type: ACTIONS.LOAD_VOICE_SETTINGS_START as ACTIONS.LOAD_VOICE_SETTINGS_START,
    };
}

export function loadVoiceSettingsSuccess(voiceSettings: CompanyVoiceSettings[]) {
    return {
        type: ACTIONS.LOAD_VOICE_SETTINGS_SUCCESS as ACTIONS.LOAD_VOICE_SETTINGS_SUCCESS,
        voiceSettings,
    };
}

export function loadVoiceSettingsFailure(error: string) {
    return {
        type: ACTIONS.LOAD_VOICE_SETTINGS_FAILURE as ACTIONS.LOAD_VOICE_SETTINGS_FAILURE,
        error,
    };
}

export function fetchCompanyAdminsStart() {
    return {
        type: ACTIONS.FETCH_COMPANY_ADMINS_START as ACTIONS.FETCH_COMPANY_ADMINS_START,
    };
}

export function fetchCompanyAdminsSuccess(data: UserV2[]) {
    return {
        type: ACTIONS.FETCH_COMPANY_ADMINS_SUCCESS as ACTIONS.FETCH_COMPANY_ADMINS_SUCCESS,
        data,
    };
}

export function fetchCompanyAdminsFailure(error: any) {
    return {
        type: ACTIONS.FETCH_COMPANY_ADMINS_FAILURE as ACTIONS.FETCH_COMPANY_ADMINS_FAILURE,
        error,
    };
}

export function clearCompanyAdmins() {
    return {
        type: ACTIONS.CLEAR_COMPANY_ADMINS as ACTIONS.CLEAR_COMPANY_ADMINS,
    };
}


export function fetchCompanyUserStatsStart() {
    return {
        type: ACTIONS.FETCH_COMPANY_USER_STATS_START as ACTIONS.FETCH_COMPANY_USER_STATS_START,
    };
}

export function fetchCompanyUserStatsSuccess(data: CompanyUserStats) {
    return {
        type: ACTIONS.FETCH_COMPANY_USER_STATS_SUCCESS as ACTIONS.FETCH_COMPANY_USER_STATS_SUCCESS,
        data,
    };
}

export function fetchCompanyUserStatsFailure(error: string) {
    return {
        type: ACTIONS.FETCH_COMPANY_USER_STATS_FAILURE as ACTIONS.FETCH_COMPANY_USER_STATS_FAILURE,
        error,
    };
}

