import DialogWrapper from '../DialogWrapper/DialogWrapper';
import { Button } from '../Button';
import { CopyLinkNotificationViewProps } from './types';
import {
    Container,
    Title,
    Description,
    ButtonContainer
} from './styles';

function CopyLinkNotificationView({ onClose }: CopyLinkNotificationViewProps) {
    return (
        <Container>
            <DialogWrapper
                customization={{ width: 328, padding: '0 32px 22px 40px' }}
            >
                <Title>Heads Up</Title>
                <Description>
                    Only Admins or Team Leaders of this specific Team will be
                    able to view this submission you're sharing.
                </Description>
                <ButtonContainer>
                    <Button
                        width="128px"
                        height="40px"
                        action={onClose}
                        color="default"
                    >
                        Got it
                    </Button>
                </ButtonContainer>
            </DialogWrapper>
        </Container>
    );
}

export default CopyLinkNotificationView;