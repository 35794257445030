import { FC } from 'react';
import { isEmpty } from 'lodash';

import { TableCheckbox } from '../table-checkbox';
import { TableRow } from '../table-row';
import { StyledCellWrapper, TableCheckboxHeaderCell } from './style';
import { TableHeaderCell } from './table-header-cell';
import { TableHeaderProps } from './type';

const TableHeader: FC<TableHeaderProps> = props => {
    const { headerConfiguration } = props;

    const { masterCheckBoxConfig, columns } = headerConfiguration!;

    return (
        <thead>
            <TableRow>
                {masterCheckBoxConfig && (
                    <TableCheckboxHeaderCell width={0.1} dataTest={masterCheckBoxConfig.dataTest && `${masterCheckBoxConfig.dataTest}-column`}>
                        <TableCheckbox
                            checked={masterCheckBoxConfig.checked}
                            disabled={masterCheckBoxConfig.disabled}
                            partial={masterCheckBoxConfig.partial}
                            handleCheck={masterCheckBoxConfig.handleCheck}
                            size={masterCheckBoxConfig.size}
                            dataTest={masterCheckBoxConfig.dataTest && `${masterCheckBoxConfig.dataTest}-input`}
                        />
                    </TableCheckboxHeaderCell>
                )}
                {!isEmpty(columns) &&
                    columns.map(column => {
                        const { title, children, subColumns = undefined } = column;

                        return isEmpty(subColumns) ? (
                            <TableHeaderCell key={title} {...column}>
                                {children ?? title ?? ''}
                            </TableHeaderCell>
                        ) : (
                            <TableHeaderCell key={title} {...column} isMultiple>
                                <StyledCellWrapper
                                    width={column?.titleWidth}
                                    className={
                                        column?.multiHeaderContainerClassName
                                    }
                                >
                                    {subColumns?.map(subColumn => {
                                        const { title, ...rest } = subColumn;
                                        return (
                                            <TableHeaderCell
                                                {...rest}
                                                key={title}
                                            >
                                                {title ?? ''}
                                            </TableHeaderCell>
                                        );
                                    })}
                                </StyledCellWrapper>
                            </TableHeaderCell>
                        );
                    })}
            </TableRow>
        </thead>
    );
};

export default TableHeader;
