import { LabelsConfigState, LabelsState, UpdatedLabelsState } from './states';
import { Reducer } from 'redux';
import {
    ACTIONS,
    closeLabelCreationPanelAction,
    openLabelCreationPanelAction,
    searchLabelsFailure,
    searchLabelsStart,
    searchLabelsSuccess,
    setEditingLabelAction,
    setLabelsSearchTerm,
    collapseAllLabelsAction,
    expandAllLabelsAction,
    toggleCollapseLabelFieldAction,
    toggleCollapseAssignLabelFieldAction,
    updateLabelFailure,
    updateLabelStart,
    updateLabelSuccess,
    modifyLabelNameAction,
    selectLabelFilterAction,
    saveLabelFiltersStart,
    saveLabelFiltersSuccess,
    saveLabelFiltersFailure,
    setLabelFilterAction,
    clearLabelFilterAction,
    fetchFullLabelsListStart,
    fetchFullLabelsListSuccess,
    fetchFullLabelsListFailure,
    expandLabelFieldByIdAction,
    expandMultipleLabelsAction,
    setAssignLabelsAction,
    clearAssignLabelsAction,
    collapseAllTableLabelsAction,
    setPreviouslyAssignedLabels,
} from './actions';
import { Label } from '../../../constants/interfaces/Label';
import {
    appendIfEmpty,
    toggleItemInArray,
} from '../../../helpers/functions/array-helpers';

const initialLabelsState: LabelsState = {
    open: false,
    loading: true,
    saveLoading: false,
    collapseLabels: [],
    assignedLabels: [],
    collapseAssignLabels: [],
    selected: [],
    selectedModified: false,
    previouslyAssignedLabels: [],
};

type LabelsAction =
    | ReturnType<typeof openLabelCreationPanelAction>
    | ReturnType<typeof closeLabelCreationPanelAction>
    | ReturnType<typeof toggleCollapseLabelFieldAction>
    | ReturnType<typeof toggleCollapseAssignLabelFieldAction>
    | ReturnType<typeof expandLabelFieldByIdAction>
    | ReturnType<typeof collapseAllLabelsAction>
    | ReturnType<typeof collapseAllTableLabelsAction>
    | ReturnType<typeof expandAllLabelsAction>
    | ReturnType<typeof expandMultipleLabelsAction>
    | ReturnType<typeof modifyLabelNameAction>
    | ReturnType<typeof searchLabelsStart>
    | ReturnType<typeof searchLabelsSuccess>
    | ReturnType<typeof searchLabelsFailure>
    | ReturnType<typeof fetchFullLabelsListStart>
    | ReturnType<typeof fetchFullLabelsListSuccess>
    | ReturnType<typeof fetchFullLabelsListFailure>
    | ReturnType<typeof selectLabelFilterAction>
    | ReturnType<typeof setLabelFilterAction>
    | ReturnType<typeof clearLabelFilterAction>
    | ReturnType<typeof setAssignLabelsAction>
    | ReturnType<typeof clearAssignLabelsAction>
    | ReturnType<typeof saveLabelFiltersStart>
    | ReturnType<typeof saveLabelFiltersSuccess>
    | ReturnType<typeof saveLabelFiltersFailure>
    | ReturnType<typeof setPreviouslyAssignedLabels>;

export const labelsReducer: Reducer<LabelsState, LabelsAction> = (
    state: LabelsState = initialLabelsState,
    action: LabelsAction
): LabelsState => {
    switch (action.type) {
        case ACTIONS.OPEN_LABEL_CREATION_PANEL:
            return {
                ...state,
                open: true,
            };
        case ACTIONS.CLOSE_LABEL_CREATION_PANEL:
            return {
                ...state,
                open: false,
            };
        case ACTIONS.SEARCH_LABELS_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.SEARCH_LABELS_SUCCESS: {
            const labelsData = {
                ...action.data,
                items: action.data.items,
            };
            return {
                ...state,
                loading: false,
                searchResults: action.searchTerm
                    ? action.data.items.map((label: Label) => label.id)
                    : [],
                data: labelsData,
            };
        }
        case ACTIONS.SEARCH_LABELS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.FETCH_FULL_LABELS_LIST_START:
            return {
                ...state,
            };
        case ACTIONS.FETCH_FULL_LABELS_LIST_SUCCESS: {
            const labelsData = {
                ...action.data,
                items: action.data.items,
            };
            return {
                ...state,
                fullList: labelsData,
            };
        }
        case ACTIONS.FETCH_FULL_LABELS_LIST_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ACTIONS.TOGGLE_COLLAPSE_LABEL_FIELD: {
            let newLabels = toggleItemInArray(
                state.collapseLabels,
                action.labelId
            );
            return {
                ...state,
                collapseLabels: newLabels,
            };
        }
        case ACTIONS.TOGGLE_COLLAPSE_ASSIGN_LABEL_FIELD: {
            let newLabels = toggleItemInArray(
                state.collapseAssignLabels,
                action.labelId
            );
            return {
                ...state,
                collapseAssignLabels: newLabels,
            };
        }
        case ACTIONS.EXPAND_LABEL_FIELD_BY_ID: {
            let newLabels = appendIfEmpty(state.collapseLabels, action.labelId);

            return {
                ...state,
                collapseLabels: newLabels,
            };
        }
        case ACTIONS.SELECT_LABEL_FILTER: {
            let selectedLabels = toggleItemInArray(
                state.selected,
                action.labelId
            );

            return {
                ...state,
                selected: selectedLabels,
                selectedModified: true,
            };
        }
        case ACTIONS.CLEAR_LABEL_FILTERS: {
            return {
                ...state,
                selected: [],
                selectedModified: true,
            };
        }
        case ACTIONS.SET_LABEL_FILTERS: {
            return {
                ...state,
                selected: action.labels,
            };
        }
        case ACTIONS.CLEAR_ASSIGN_LABELS: {
            return {
                ...state,
                assignedLabels: [],
            };
        }
        case ACTIONS.SET_ASSIGN_LABELS: {
            return {
                ...state,
                assignedLabels: action.labels,
            };
        }
        case ACTIONS.EXPAND_ALL_LABELS: {
            if (!state.data) return state;
            let newLabels = [
                ...state.data.items
                    .filter(
                        (label: Label) =>
                            label.id &&
                            label.children &&
                            label.children.length > 0
                    )
                    .map((label: Label) => label.id),
            ];

            return {
                ...state,
                collapseLabels: newLabels,
            };
        }
        case ACTIONS.EXPAND_MULTIPLE_LABELS: {
            return {
                ...state,
                collapseLabels: action.labelIds,
            };
        }
        case ACTIONS.COLLAPSE_ALL_LABELS: {
            return {
                ...state,
                collapseLabels: [],
            };
        }
        case ACTIONS.COLLAPSE_ALL_TABLE_LABELS: {
            return {
                ...state,
                collapseAssignLabels: [],
            };
        }
        case ACTIONS.MODIFY_LABEL_NAME: {
            if (!state.data) return state;
            return {
                ...state,
                collapseLabels: [],
            };
        }
        case ACTIONS.SAVE_LABEL_FILTERS_START: {
            return {
                ...state,
                saveLoading: true,
            };
        }
        case ACTIONS.SAVE_LABEL_FILTERS_SUCCESS: {
            return {
                ...state,
                saveLoading: false,
                selectedModified: false,
            };
        }
        case ACTIONS.SAVE_LABEL_FILTERS_FAILURE: {
            return {
                ...state,
                saveLoading: false,
                error: action.error,
            };
        }
        case ACTIONS.SET_PREVIOUSLY_ASSIGNED_LABELS: {
            return {
                ...state,
                previouslyAssignedLabels: action.labels,
            };
        }
        default:
            return state;
    }
};
const initialLabelsConfigState: LabelsConfigState = {
    searchTerm: '',
};
type LabelsConfigAction =
    | ReturnType<typeof setLabelsSearchTerm>
    | ReturnType<typeof setEditingLabelAction>;

export const labelsConfigReducer: Reducer<
    LabelsConfigState,
    LabelsConfigAction
> = (
    state: LabelsConfigState = initialLabelsConfigState,
    action: LabelsConfigAction
): LabelsConfigState => {
    switch (action.type) {
        case ACTIONS.SET_LABELS_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.value,
            };
        case ACTIONS.SET_EDITING_LABEL:
            return {
                ...state,
                editingLabels: {
                    id: action.configs && action.configs.id,
                    mode: action.configs && action.configs.mode,
                },
            };
        default:
            return state;
    }
};

const initialUpdatedLabelsState: UpdatedLabelsState = {};
type UpdatedLabelsAction =
    | ReturnType<typeof updateLabelStart>
    | ReturnType<typeof updateLabelSuccess>
    | ReturnType<typeof updateLabelFailure>;

export const updatedLabelsReducer: Reducer<
    UpdatedLabelsState,
    UpdatedLabelsAction
> = (
    state: UpdatedLabelsState = initialUpdatedLabelsState,
    action: UpdatedLabelsAction
): UpdatedLabelsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_LABEL_START:
            return {
                ...state,
            };
        case ACTIONS.UPDATE_LABEL_SUCCESS:
            return {
                ...state,
                data: action.updatedLabel,
                updateType: action.updateType,
            };
        case ACTIONS.UPDATE_LABEL_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
};
