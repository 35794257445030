import { useCallback, useEffect } from 'react';
import { useGetCompanyUserStatsService } from '../../../pages/CompanySettings/store/services';
import { useCompanyUserStatsState } from '../../../pages/CompanySettings/store/states';
import { CompanyUserStatsTextProps } from './types';
import CompanyUserStatsTextView from './view';
import { pushModal, useHistory } from '../../../tools/router';
import ROUTES from '../../../routes/routes';

function CompanyUserStatsText({
    companyId,
    showUnlimited,
    showSettingsButton,
    style,
}: CompanyUserStatsTextProps) {
    const userStatsState = useCompanyUserStatsState();
    const getCompanyUserStats = useGetCompanyUserStatsService();
    const history = useHistory();

    /**
     * @function handleGearClick
     * @description opens company settings modal with user limits tab selected
     * @returns { void }
     */
    const handleSettingClick = useCallback(() => {
        pushModal(history, ROUTES.COMPANY_SETTINGS.USER_LIMIT);
    }, [history]);

    useEffect(() => {
        getCompanyUserStats(companyId);
    }, [companyId, getCompanyUserStats]);

    return (
        <CompanyUserStatsTextView
            userStats={userStatsState.data}
            showUnlimited={showUnlimited}
            showSettingsButton={showSettingsButton}
            style={style}
            onSettingsClick={handleSettingClick}
        />
    );
}

export default CompanyUserStatsText;