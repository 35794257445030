import {
    FC,
    forwardRef,
    MutableRefObject,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    ReactNode,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { isEmpty as isLodashEmpty } from 'lodash';

import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { EnrollmentInterface } from '../../../constants/interfaces/Enrollments';
import { History } from 'history';
import { ActionButton, ActionItem } from '../../../ui/components/ActionButton';
import ROUTES from '../../../routes/routes';
import { pushModal } from '../../../tools/router';
import {
    formatDate,
    formatDateWithTimeZone,
} from '../../../helpers/functions/date-convert';
import {
    UpdatedLabelsState,
    useUpdatedLabelsState,
} from '../../labels/store/states';
import styled from 'styled-components';
import { clearSelectedItemIfNotLoaded } from '../../labels/tools';
import { LoadingComponent } from '../../../ui/components/LoadingCopmonent';
import { CheckPermission } from '../../permissions';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../helpers/hooks/usePagePermissions';
import { useUpdateAssignedLibraryPractisSetService } from '../../library/store/services';
import {
    usePractisSetsEnrollmentsService,
    useRemindUsersService,
    useResetUserPerformanceService,
    useSearchUserPractisSetsService,
    useUnEnrollPractisSetService,
    useUpdateAllPractisSetsCheckedStateService,
    useUpdateTraineeProfilePractisSetsCheckedStateService,
    useUserPerformanceService,
} from '../store/services';
import {
    useUpdatedUserPractisSetsState,
    useUserPerformanceState,
} from '../store/states';
import { PerformanceTrainee } from '../../../constants/interfaces/PerformanceTrainee';
import { EnrollmentStatusEnum } from '../../../constants/interfaces/Enrollments';
import Box from '../../../ui/icons/Box';
import { useSearchDebounced } from '../../../helpers/hooks/useSearch';
import FilterByHandler from '../../../ui/components/Filters/FilterByHandler';
import { Popup } from '../../../ui/components/Popup';
import { UserProfileFilters } from './Filters/UserProfileFilters/UserProfileFilters';
import { isTeamLeader } from '../../../constants/enums';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { UserInterface } from '../../../constants/interfaces/User';
import { useSessionEffect } from '../../../features/common';
import GeneralProgressCol from '../../../features/teams/pages/Training/components/ProgressDetails/GeneralProgressCol';
import { secondsToMinSimple } from '../../../helpers/functions/convert-time';
import OverdueFlag from '../../../ui/icons/OverdueFlag';
import { DueDateFilters } from '../../../constants/interfaces/DueDates';
import { isEmpty } from 'lodash';
import { TableWrapper } from '../../../ui/components/table-wrapper';
import { useStyles } from '../pages/Performance/styles';
import { useOrderBy } from '../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../ui/components/table-wrapper/helper';
import { useDeleteEnrollmentBulkActionService } from '../../teams/pages/Training/services/EnrollmentBulkActionsService';
import { useShowConfirmModalDialog } from '../../../ui/components/ModalDialogs/store/actions';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
`;

const ProgressBarContainer = styled.div`
    width: 128px;
`;

const DueDateWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledOverdueFlag = styled(OverdueFlag)`
    margin-left: 8px;
`;

const PerformanceSectionRef = forwardRef((props: any, ref) => {
    return <PerformanceSection reff={ref} {...props} />;
});

const PerformanceSection: FC<{
    trainee?: PerformanceTrainee;
    practisSets?: PaginationResult<EnrollmentInterface>;
    onPractisSetsUpdate(practisSetsCount: number): void;
    updatedPractisSets?: EnrollmentInterface;
    searchUserPractisSets(searchParams: SearchParams): void;
    fetchPortablePractisSets(): void;
    params: { userId: string };
    history: History<any>;
    profile?: UserInterface;
    selectedPractisSets?: number[];
    loading?: boolean;
    updateAllPractisSetsCheckedState(
        practisSetIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedPractisSetCheckedState(practisSetId: number): void;
    updatedLabels?: UpdatedLabelsState;
    unEnrollPractisSetService(
        unEnrolledPractisSets: number[],
        search?: SearchParams,
        selectAll?: boolean
    ): any;
    isInvitation: boolean;
    onGetTrainee: (userId: number, isInvitation: boolean) => void;
    remindUsers: (enrollmentIds: number[]) => any;
    setAssignPractisSetsAction?(practisSetIds: number[]): void;
    reff?: MutableRefObject<any>;
}> = ({
    trainee,
    practisSets,
    params,
    onPractisSetsUpdate,
    searchUserPractisSets,
    history,
    profile,
    updatedPractisSets,
    selectedPractisSets,
    updateAllPractisSetsCheckedState,
    updateSelectedPractisSetCheckedState,
    updatedLabels,
    loading,
    unEnrollPractisSetService,
    isInvitation,
    onGetTrainee,
    remindUsers,
    setAssignPractisSetsAction,
    reff,
}) => {
    const classes = useStyles();

    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [savedStatuses, setSavedStatuses] = useState<string[]>([]);

    const canAccessPage = useHandleAccessPage();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        labelIDs: [],
        orderBy: {},
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
        filterByStatus: null,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        refreshSearchParams,
        setCustom,
    } = useSearchParamsState(initialSearchParams);

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const handleItemEdit = (enrollment: EnrollmentInterface) => {
        pushModal(
            history,
            ROUTES.LIBRARY_SETTINGS.PRACTISSETS.SINGLE.replace(
                ':practisSetId',
                enrollment.practisSet.id!.toString()
            )
        );
    };

    const isCheckboxPartiallySelected =
        practisSets &&
        practisSets.items &&
        selectedPractisSets &&
        selectedPractisSets.length < practisSets.items.length;

    const updateAllPractisSetCheck = useCallback(
        (checked: boolean) => {
            const setIds =
                (practisSets &&
                    practisSets.items.map((set: EnrollmentInterface) =>
                        Number(set.id)
                    )) ||
                [];
            updateAllPractisSetsCheckedState(
                setIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [
            practisSets,
            isCheckboxPartiallySelected,
            updateAllPractisSetsCheckedState,
        ]
    );

    const handlePractisSetCheck = (e: any, enrollment: EnrollmentInterface) => {
        e.stopPropagation();
        if (!!enrollment.id) {
            updateSelectedPractisSetCheckedState(enrollment.id);
        }
    };

    const handleDeleteEnrollmentsSuccess = useCallback(() => {
        if (!!trainee && !!trainee.id) {
            setAssignPractisSetsAction?.([]);
            onGetTrainee(trainee.id, isInvitation);
        }
    }, [trainee, setAssignPractisSetsAction, onGetTrainee, isInvitation]);

    const handleDeleteEnrollmentsBulkActionService =
        useDeleteEnrollmentBulkActionService(
            {
                userId: (trainee?.id || 0).toString(),
                searchParams,
            },
            'practisSet',
            practisSets?.count,
            handleDeleteEnrollmentsSuccess
        );

    const handleUnEnrollPractisSet = useCallback(
        (enrollmentIds: number[]) => {
            if (isSelectAll) {
                handleDeleteEnrollmentsBulkActionService();
            } else {
                unEnrollPractisSetService(enrollmentIds).then(() =>
                    handleDeleteEnrollmentsSuccess()
                );
            }
        },
        [
            handleDeleteEnrollmentsBulkActionService,
            handleDeleteEnrollmentsSuccess,
            unEnrollPractisSetService,
            isSelectAll,
        ]
    );

    /**
     * Set filter list with filterByDueDate
     * @param dueDates - DueDateFilters[], due date types array
     * Returns void
     */
    const filterOverdueHandler = (dueDates: DueDateFilters[]) => {
        setCustom('filterByDueDate', dueDates);
    };

    const handleViewPractisSetReport = (userId: number, enrollmentId: number) => {
        updateAllPractisSetsCheckedState([], false, false);
        history.push(
            ROUTES.TRAINEE_DETAILS
                .replace(
                    ':userId',
                    userId.toString()
                )
                .replace(
                    ':enrollmentId',
                    enrollmentId.toString()
                ),
            { useGoBack: true }
        );
    };

    const isOptionsDisabled =
        !selectedPractisSets ||
        (selectedPractisSets && !selectedPractisSets.length);

    const orderBy = searchParams.orderBy;
    const hcName = useOrderBy('pset_name', orderBy, setOrderBy);

    const hcLastTrainingAt = useOrderBy('last_training', orderBy, setOrderBy);
    const hcAssigned = useOrderBy('assigned', orderBy, setOrderBy);
    const hcStarted = useOrderBy('started', orderBy, setOrderBy);
    const hcAccuracyAvg = useOrderBy('accuracy', orderBy, setOrderBy);
    const hcTrainingTime = useOrderBy('training_time', orderBy, setOrderBy);
    const hcProgressValue = useOrderBy('progress', orderBy, setOrderBy);
    const hcOverdue = useOrderBy('due_date', orderBy, setOrderBy);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters:
            (searchParams.labelIDs?.length || 0) +
            (searchParams.filterByStatus?.length || 0) +
            (searchParams.filterByDueDate?.length || 0),
        itemsCount: practisSets?.items.length || 0,
    });

    const onSelectStatus = (status: string[]) => {
        setSelectedStatuses(status);
    };
    const onSaveStatus = (status: string[]) => {
        setSavedStatuses(status);
    };

    const isTeamLead = isTeamLeader(profile?.role?.name);

    useImperativeHandle(reff, () => {
        return {
            refreshSearchParams,
        };
    });

    /**
     * Returns due date column data.
     * @param { Boolean } hasOverdue - Boolean, describes practis set is overdue or not
     * @param { Date } dueDate - Date, due date of practis set
     * @param { string } completedAt - String, date of completion
     * @returns { ReactNode | String }
     */
    const showDueDateData = (
        hasOverdue?: boolean,
        dueDate?: string | null,
        completedAt?: string
    ): ReactNode | string => {
        const Flag = hasOverdue && !completedAt ? <StyledOverdueFlag /> : null;
        const dueDateData = dueDate ? (
            <DueDateWrapper>
                {formatDateWithTimeZone(dueDate)}
                {Flag}
            </DueDateWrapper>
        ) : null;

        return dueDateData;
    };

    const filterIconCount =
        savedStatuses.length +
        (searchParams.labelIDs?.length || 0) +
        (searchParams?.filterByDueDate?.length || 0);

    /**
     * @function onConfirmDeletePractisSet
     * @param { number[] } practisSetIds
     * @returns { void }
     */
    const onConfirmDeletePractisSet = (practisSetIds: number[]): void => {
        !isEmpty(practisSetIds) && handleUnEnrollPractisSet(practisSetIds);
    };

    /**
     * @function handleDeletePractisSet
     * @param { number[] } ids
     * @returns { void }
     */
    const handleDeletePractisSets = (ids: number[]) => {
        showConfirmationModalDialog({
            modalTitle: 'Are you sure?',
            description: 'User will get notified about this change.',
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Proceed',
            onConfirm: () => onConfirmDeletePractisSet(ids),
            customization: {
                padding: '32px 32px 32px 40px',
                titleMarginBottom: 14,
                descriptionMarginBottom: 37,
            },
        });
    };

    /**
     * @function handleDeleteMultiplePractisSets
     * @returns { void }
     */
    const handleDeleteMultiplePractisSets = (): void => {
        if (
            !isLodashEmpty(practisSets?.items) &&
            !isLodashEmpty(selectedPractisSets)
        ) {
            const enrollmentIds = practisSets?.items
                .filter(set => selectedPractisSets?.includes(set.id!))
                .map(item => item.id!);

            enrollmentIds && handleDeletePractisSets(enrollmentIds);
        }
    };

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchUserPractisSets(searchParams);
    }, [searchUserPractisSets, searchParams]);

    useEffect(() => {
        if (practisSets && practisSets.items) {
            onPractisSetsUpdate(practisSets.items.length);
        }
    }, [onPractisSetsUpdate, practisSets, practisSets?.items]);

    useEffect(() => {
        if (updatedPractisSets || updatedLabels?.data) {
            refreshSearchParams();
        }
    }, [updatedPractisSets, updatedLabels?.data, refreshSearchParams]);

    useEffect(() => {
        const filteredUsers = practisSets
            ? practisSets.items.map(practisSet => Number(practisSet.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedPractisSets,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllPractisSetsCheckedState(usersToClear, false);
        }
    }, [selectedPractisSets, practisSets, updateAllPractisSetsCheckedState]);

    useEffect(() => {
        return () => updateAllPractisSetsCheckedState([], false, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllPractisSetsCheckedState([], false, false);
    }, [
        practisSets,
        history.location.pathname,
        updateAllPractisSetsCheckedState,
    ]);

    useEffect(() => {
        if (
            selectedPractisSets?.length &&
            selectedPractisSets.length === practisSets?.count
        ) {
            setIsSelectAll(true);
        }
    }, [practisSets?.count, selectedPractisSets]);

    useEffect(() => {
        if (pageIndex >= 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 100);
        }
    }, [initialOffset, pageIndex, setOffset]);

    useEffect(() => {
        setCustom(
            'filterByStatus',
            savedStatuses.length > 0 ? savedStatuses : null
        );
    }, [savedStatuses, setCustom]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_PRACTIS_SET]);
    }, [canAccessPage]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllPractisSetCheck(event.target.checked);
        setIsSelectAll(false);
    };

    /**
     * @function onRowClick
     * @param { EnrollmentInterface } enrollment
     * @returns { void }
     */
    const onRowClick = (enrollment: EnrollmentInterface) => {
        handleViewPractisSetReport(enrollment.user.id, enrollment.id);
    };

    /**
     * @function checkIsRowChecked
     * @param { EnrollmentInterface } enrollment
     * @returns { boolean }
     */
    const checkIsRowChecked = (enrollment: EnrollmentInterface): boolean =>
        !!selectedPractisSets &&
        !!enrollment.practisSet.id &&
        selectedPractisSets.includes(enrollment.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { EnrollmentInterface } enrollment
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, enrollment: EnrollmentInterface) => {
        handlePractisSetCheck(event, enrollment);
        setIsSelectAll(false);
    };

    return (
        <>
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={practisSets?.items}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: practisSets?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            searchParams.labelIDs?.length,
                        dataTest: 'user-profile-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'user-profile-search-input',
                    },
                    isSelectedItemOptionsVisible:
                        !isLodashEmpty(selectedPractisSets) &&
                        !isLodashEmpty(practisSets),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedPractisSets?.length,
                        totalCount: practisSets?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: () => {
                            updateAllPractisSetsCheckedState([], false, false);
                            setIsSelectAll(false);
                        },
                        onSelectAll: () => {
                            const ids =
                                (practisSets &&
                                    practisSets.items.map(
                                        (item: EnrollmentInterface) =>
                                            item.id as number
                                    )) ||
                                ([] as number[]);
                            updateAllPractisSetsCheckedState(
                                ids,
                                true,
                                isCheckboxPartiallySelected
                            );
                            setIsSelectAll(true);
                        },
                        bulkActionsConfig: {
                            disabled: isOptionsDisabled,
                            removePractisSetPermission: [
                                NEW_PERMISSIONS.UNASSIGN_ENROLLMENT,
                            ],
                            onRemovePractisSetSubmit:
                                handleDeleteMultiplePractisSets,
                            isSelectAll: isSelectAll,
                            totalCount: practisSets?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <UserProfileFilters
                                        selectedStatuses={selectedStatuses}
                                        savedStatuses={savedStatuses}
                                        handleSelectStatus={onSelectStatus}
                                        handleSaveStatus={onSaveStatus}
                                        onSuccessApply={() => hide()}
                                        onOverdueChange={filterOverdueHandler}
                                        dueDate={
                                            (searchParams.filterByDueDate as DueDateFilters[]) ||
                                            []
                                        }
                                        isInvitation={isInvitation}
                                    />
                                )}
                                horizontalOffset={216}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={filterIconCount}
                                        disabled={tableStates.disableFilters}
                                        dataTest="user-profile-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && practisSets?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Box,
                        text: 'No Practis Sets Assigned Yet',
                        dataTest: 'no-user-practis-sets',
                        width: '140px'
                    },
                    noSearchResultsOptions: {
                        entityName: 'Practis Sets',
                        dataTest: 'no-found-user-practis-sets',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked:
                            !!selectedPractisSets &&
                            !!selectedPractisSets.length,
                        disabled:
                            !practisSets || practisSets?.items?.length < 1,
                        partial:
                            (practisSets?.count &&
                                practisSets.count > ITEMS_PER_PAGE &&
                                !isSelectAll) ||
                            isCheckboxPartiallySelected ||
                            false,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'user-profile-master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Practis Sets',
                            width: 19,
                            ...hcName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'practis-sets-column',
                        },
                        {
                            title: 'Due Date',
                            width: 8,
                            ...hcOverdue,
                            disabled: tableStates.disableSorting,
                            dataTest: 'due-date-column',
                        },
                        {
                            title: 'Progress',
                            width: 12,
                            ...(isInvitation ? null : hcProgressValue),
                            disabled: tableStates.disableSorting,
                            withTooltip: true,
                            tooltipText:
                                'The percentage completion of all the items in a Practis Set.',
                            dataTest: 'progress-column',
                        },
                        {
                            title: 'Accuracy',
                            width: 12,
                            ...(isInvitation ? null : hcAccuracyAvg),
                            disabled: tableStates.disableSorting,
                            withTooltip: true,
                            tooltipText:
                                'The average of the accuracy scores for all submitted scenario accuracy tests.',
                            dataTest: 'accuracy-column',
                        },
                        {
                            title: 'Training Time',
                            width: 15,
                            ...(isInvitation ? null : hcTrainingTime),
                            disabled: tableStates.disableSorting,
                            withTooltip: true,
                            tooltipText:
                                'The total time spent by a user learning material.',
                            dataTest: 'training-time-column',
                        },
                        {
                            title: 'Assigned',
                            width: 15,
                            ...hcAssigned,
                            disabled: tableStates.disableSorting,
                            dataTest: 'assigned-column',
                        },
                        {
                            title: 'Started',
                            width: 15,
                            ...(isInvitation ? null : hcStarted),
                            disabled: tableStates.disableSorting,
                            dataTest: 'started-column',
                        },
                        {
                            title: 'Last Training',
                            width: 8,
                            ...(isInvitation ? null : hcLastTrainingAt),
                            disabled: tableStates.disableSorting,
                            dataTest: 'last-training-column',
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: onRowClick,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        dataTest: 'user-practis-set-item',

                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.practisSet.name,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) => enrollment?.practisSet.name,
                                    dataTest: 'user-practis-set-name',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.dueDate,
                                fieldProps: {
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) =>
                                        showDueDateData(
                                            enrollment.hasOverdue,
                                            enrollment.dueDate,
                                            enrollment.completedAt
                                        ) ?? '',
                                    dataTest: 'user-practis-set-due-date',
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                getCustomFieldComponent: (
                                    enrollment: EnrollmentInterface
                                ) => (
                                    <StyledFirstTableContent data-test="user-practis-set-progress">
                                        {!isInvitation ? (
                                            <ProgressBarContainer>
                                                <GeneralProgressCol
                                                    count={Math.round(
                                                        enrollment.progress *
                                                            100
                                                    )}
                                                    max={100}
                                                    completedAt={
                                                        enrollment.completedAt
                                                    }
                                                    status={enrollment.status}
                                                />
                                            </ProgressBarContainer>
                                        ) : (
                                            'Not Started'
                                        )}
                                    </StyledFirstTableContent>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.averageAccuracy,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) =>
                                        enrollment?.averageAccuracy &&
                                        `${Math.floor(
                                            enrollment.averageAccuracy * 100
                                        )}%`,
                                    dataTest: 'user-practis-set-accuracy',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.trainingTime,
                                fieldProps: {
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) =>
                                        enrollment.trainingTime &&
                                        `${secondsToMinSimple(
                                            enrollment.trainingTime
                                        )}`,
                                    dataTest: 'user-practis-set-training-time',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.createdAt,
                                fieldProps: {
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) => formatDate(enrollment?.createdAt),
                                    dataTest: 'user-practis-set-creation-date',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.startedAt,
                                fieldProps: {
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) =>
                                        enrollment.status ===
                                            EnrollmentStatusEnum.PENDING ||
                                        isInvitation
                                            ? ''
                                            : enrollment.startedAt
                                            ? formatDate(enrollment.startedAt)
                                            : '',
                                    dataTest: 'user-practis-set-enroll-date',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    enrollment: EnrollmentInterface
                                ) => !enrollment?.lastTrainingAt,
                                fieldProps: {
                                    renderTitle: (
                                        enrollment: EnrollmentInterface
                                    ) => formatDate(enrollment?.lastTrainingAt),
                                    dataTest: 'user-practis-set-last-training',
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                cellClassName: classes.customTableCellStyle,
                                fieldProps: {
                                    getListActionsComponent: (
                                        enrollment: EnrollmentInterface
                                    ) => (
                                        <ActionButton
                                            customWidth={227}
                                            isHidden={
                                                !!selectedPractisSets?.length
                                            }
                                            dataTest="user-practis-set-menu"
                                        >
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_USER_PRACTIS_SET_REPORT,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewPractisSetReport(
                                                            enrollment.user.id,
                                                            enrollment.id
                                                        );
                                                    }}
                                                    dataTest="view-practis-set-report"
                                                >
                                                    View Practis Set Report
                                                </ActionItem>
                                            </CheckPermission>
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_USER_PRACTIS_SET_REPORT,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleItemEdit(
                                                            enrollment
                                                        );
                                                    }}
                                                    dataTest={`${
                                                        isTeamLead
                                                            ? 'view'
                                                            : 'edit'
                                                    }-practis-set`}
                                                >
                                                    {isTeamLead
                                                        ? 'View'
                                                        : 'Edit'}{' '}
                                                    Practis Set
                                                </ActionItem>
                                            </CheckPermission>

                                            {!(enrollment.progress !== null) &&
                                                !enrollment.startedAt &&
                                                !isInvitation && (
                                                    <ActionItem
                                                        onClick={e => {
                                                            remindUsers([
                                                                enrollment.id ??
                                                                    0,
                                                            ]);
                                                        }}
                                                        dataTest="remind-practis-set"
                                                    >
                                                        Remind
                                                    </ActionItem>
                                                )}
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.UNASSIGN_ENROLLMENT,
                                                ]}
                                            >
                                                <ActionItem
                                                    destructive={true}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleDeletePractisSets(
                                                            [enrollment.id!]
                                                        );
                                                    }}
                                                    dataTest="unassign-practis-set"
                                                >
                                                    Unassign Practis Set
                                                </ActionItem>
                                            </CheckPermission>
                                        </ActionButton>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </>
    );
};

export const PerformanceSectionContainerRef = forwardRef((props: any, ref) => {
    return <PerformanceSectionContainer reff={ref} {...props} />;
});

export const PerformanceSectionContainer: FC<{
    isInvitation: boolean;
    onPractisSetsUpdate(practisSetsCount: number): void;
    reff?: MutableRefObject<any>;
}> = ({ isInvitation, onPractisSetsUpdate, reff }) => {
    const trainee = useUserPerformanceState();
    const params: { userId: string } = useParams();

    const history = useHistory();
    const profile = useSelector(getProfileState);
    const getUserPerformance = useUserPerformanceService();
    const remindUsers = useRemindUsersService();
    const userId = trainee?.data?.id?.toString() || '';
    const getUserPractisSets = useSearchUserPractisSetsService(userId);
    const fetchPortablePractis = usePractisSetsEnrollmentsService(userId);

    const searchUserPractisSets = getUserPractisSets;
    const updatedUserPractisSetsState = useUpdatedUserPractisSetsState();
    const searchUserPractisSetsDebounced = useSearchDebounced(
        searchUserPractisSets
    );

    const updateAllPractisSetsCheckedState =
        useUpdateAllPractisSetsCheckedStateService();
    const updateSelectedPractisSetCheckedState =
        useUpdateTraineeProfilePractisSetsCheckedStateService();
    const unEnrollPractisSetService = useUnEnrollPractisSetService();
    const updatedLabels = useUpdatedLabelsState();
    const setAssignPractisSetsAction =
        useUpdateAssignedLibraryPractisSetService();
    const resetUserPerformance = useResetUserPerformanceService();

    const perfSecConRef = useRef<any>(null);

    useImperativeHandle(reff, () => {
        return {
            refreshPractisSetsSearch: () =>
                perfSecConRef.current?.refreshSearchParams(),
        };
    });

    useEffect(() => {
        return () => {
            resetUserPerformance();
        };
    }, [resetUserPerformance]);

    return (
        <PerformanceSectionRef
            trainee={trainee.data}
            params={params}
            history={history}
            profile={profile}
            searchUserPractisSets={searchUserPractisSetsDebounced}
            fetchPortablePractisSets={fetchPortablePractis}
            practisSets={trainee.practisSets}
            loading={trainee.loading || trainee.practisSetsLoading}
            selectedPractisSets={trainee.selectedPractisSets}
            updatedPractisSets={updatedUserPractisSetsState.data}
            updateAllPractisSetsCheckedState={updateAllPractisSetsCheckedState}
            updateSelectedPractisSetCheckedState={
                updateSelectedPractisSetCheckedState
            }
            setAssignPractisSetsAction={setAssignPractisSetsAction}
            updatedLabels={updatedLabels}
            unEnrollPractisSetService={unEnrollPractisSetService}
            isInvitation={isInvitation}
            onGetTrainee={getUserPerformance}
            remindUsers={remindUsers}
            ref={perfSecConRef}
            onPractisSetsUpdate={onPractisSetsUpdate}
        />
    );
};

export default PerformanceSectionContainer;