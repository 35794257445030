import React, { FC } from 'react';
import styled from 'styled-components';
import {
    Comment,
    CommentBody,
    CommentDescription,
} from '../../../../../ui/components/Comment/Comment';
import { Variables } from '../../../../../theme/variables';
import { ScriptLine } from '../../../../../constants/interfaces/ScriptLine';

const StyledScriptItem = styled.div`
    margin-left: 24px;
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledCommentBody = styled(CommentBody)`
    min-height: 0;
`;

const TraineeRespondLine: FC<{
    disabled: boolean;
    script?: ScriptLine;
}> = ({ disabled }) => {
    return (
        <StyledScriptItem>
            <Comment
                disabled={disabled}
                reverse={true}
                background={Variables.Colors.whiteFive}
            >
                <StyledCommentBody>
                    <CommentDescription hideRemoveIcon={true}>
                        <span data-test='challenge-user-auto-reply'>User will respond here</span>
                    </CommentDescription>
                </StyledCommentBody>
            </Comment>
        </StyledScriptItem>
    );
};

export default TraineeRespondLine;
