import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { VideoPlayer } from '../../../../ui/components/VideoPlayer/VideoPlayer';
import RepLine from './RepLine';
import CustomerLine from './CustomerLine';
import { useTelepromterState } from '../../store/state';
import { Button } from '../../../../ui/components/Button';
import { useHistory } from '../../../../tools/router';
import ROUTES from '../../../../routes/routes';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../constants/interfaces/ScriptLine';
import { Loading } from '../../../../ui/components/LoadingCopmonent';
import Close from '../../../../ui/icons/Close';

const Container = styled.div`
    background: ${props => props.theme.Colors.darkThree};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
`;

const ExitIcon = styled.div`
    height: 14px;
    width: 14px;
    color: ${props => props.theme.Colors.white};
    position: absolute;
    top: 40px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
    user-select: none;
    &:hover {
        opacity: 0.7;
    }
    &:active {
        opacity: 0.6;
    }
`;

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VideoContainer = styled.div`
    height: 376px;
    width: 100%;
    flex-shrink: 0;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
    height: 100%;
`;

const LineContainer = styled.div`
    flex: 1;
    width: 100%;
    background: ${props => props.theme.Colors.whiteThree};
`;

const ButtonContainer = styled.div`
    position: absolute;
    bottom: 68px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const ErrorText = styled.div`
    color: ${props => props.theme.Colors.paleRed};
    font-size: 14px;
    align-items: center;
    margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
    margin-bottom: 8px;
`;

const Playback: FC = () => {
    const telepromterState = useTelepromterState();
    const scenarios = telepromterState.scenario;
    const generatedLines = telepromterState.generatedLines;
    const playbackVideo = telepromterState.videoBlob;
    const history = useHistory();
    const [duration, setDuration] = useState(0);
    const [playing, setPlaying] = useState(false);

    const getVideoDuration = (value: number) => {
        setDuration(value);
    };

    const getVideoPlaying = (value: boolean) => {
        setPlaying(value);
    };

    const redirectToScenario = useCallback(() => {
        if (scenarios.id) {
            history.push(
                ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE.replace(
                    ':scenarioId',
                    scenarios.id.toString()
                )
            );
        }
    }, [history, scenarios.id]);

    const redirectToTraining = useCallback(() => {
        if (scenarios.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.TRAINING.replace(
                    ':scenarioId',
                    scenarios.id.toString()
                )
            );
        }
    }, [history, scenarios.id]);

    const redirectToCompleted = useCallback(() => {
        if (scenarios.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.COMPLETED.replace(
                    ':scenarioId',
                    scenarios.id.toString()
                )
            );
        }
    }, [history, scenarios.id]);

    const mapScriptLines = useCallback((scriptLines: Partial<ScriptLine>[]) => {
        let prevLineDuration = 0;
        return scriptLines.map(line => {
            const minDurationValue = prevLineDuration;
            const maxDurationValue = prevLineDuration + line.duration!;
            prevLineDuration = maxDurationValue;

            return {
                id: line.id,
                text: line.text,
                speaker: line.speaker,
                audioUrl: line.audioUrl,
                min: minDurationValue,
                max: maxDurationValue,
            };
        });
    }, []);

    const [localScriptLines, setLocalScriptLines] = useState(
        mapScriptLines(generatedLines)
    );

    useEffect(() => {
        setLocalScriptLines(mapScriptLines(generatedLines));
    }, [generatedLines, mapScriptLines]);

    const currentScriptLine = localScriptLines[0];

    useEffect(() => {
        setLocalScriptLines(
            mapScriptLines(generatedLines).filter(
                (line: any) => line && line.max >= duration
            )
        );
    }, [setLocalScriptLines, duration, mapScriptLines, generatedLines]);

    if (!playbackVideo) {
        return (
            <Container>
                <ExitIcon onClick={redirectToScenario}>
                    <Close />
                </ExitIcon>
                <ErrorContainer>
                    <ErrorText>No playback video found.</ErrorText>
                    <ButtonWrapper>
                        <Button
                            label="Go Practis"
                            width="152px"
                            height="48px"
                            action={redirectToTraining}
                        />
                    </ButtonWrapper>
                </ErrorContainer>
            </Container>
        );
    }

    if (telepromterState.loading) {
        return (
            <Container>
                <ExitIcon onClick={redirectToCompleted}>
                    <Close />
                </ExitIcon>
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            </Container>
        );
    }

    return (
        <Container>
            <ExitIcon onClick={redirectToCompleted}>
                <Close />
            </ExitIcon>
            <VideoContainer>
                <StyledVideoPlayer
                    url={playbackVideo}
                    getDuration={getVideoDuration}
                    getPlaying={getVideoPlaying}
                    autoPlay={true}
                    muteVideo={
                        !currentScriptLine ||
                        currentScriptLine.speaker === ScriptLineSpeaker.CUSTOMER
                    }
                />
            </VideoContainer>
            {currentScriptLine && playing && (
                <audio
                    src={currentScriptLine.audioUrl}
                    muted={currentScriptLine.speaker === ScriptLineSpeaker.REP}
                    autoPlay={true}
                />
            )}
            <LineContainer>
                {currentScriptLine &&
                    localScriptLines.map(scriptLine => {
                        if (scriptLine.speaker === ScriptLineSpeaker.REP) {
                            return (
                                <RepLine
                                    key={scriptLine.id}
                                    text={scriptLine.text!}
                                    disabled={
                                        scriptLine.id !== currentScriptLine.id
                                    }
                                />
                            );
                        } else {
                            return (
                                <CustomerLine
                                    key={scriptLine.id}
                                    text={scriptLine.text!}
                                    disabled={
                                        scriptLine.id !== currentScriptLine.id
                                    }
                                />
                            );
                        }
                    })}
            </LineContainer>
            <ButtonContainer>
                <Button
                    label={'Close Playback'}
                    width={'168px'}
                    height={'48px'}
                    action={redirectToCompleted}
                />
            </ButtonContainer>
        </Container>
    );
};

export default Playback;
