import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ArchiveIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className={className}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.5 1.75a.25.25 0 0 1 .25-.25h3a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h2.086a.25.25 0 0 1 .177.073l2.914 2.914a.25.25 0 0 1 .073.177v8.586a.25.25 0 0 1-.25.25h-.5a.75.75 0 0 0 0 1.5h.5A1.75 1.75 0 0 0 14 13.25V4.664c0-.464-.184-.909-.513-1.237L10.573.513A1.75 1.75 0 0 0 9.336 0H3.75A1.75 1.75 0 0 0 2 1.75v11.5c0 .649.353 1.214.874 1.515a.75.75 0 1 0 .752-1.298.25.25 0 0 1-.126-.217V1.75zM8.75 3a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5zM6 5.25a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 6 5.25zm2 1.5A.75.75 0 0 1 8.75 6h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 8 6.75zm-1.25.75a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5zM8 9.75A.75.75 0 0 1 8.75 9h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 8 9.75zm-.75.75a1.75 1.75 0 0 0-1.75 1.75v3c0 .414.336.75.75.75h2.5a.75.75 0 0 0 .75-.75v-3a1.75 1.75 0 0 0-1.75-1.75h-.5zM7 12.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v2.25H7v-2.25z"
                    fill="currentColor"
                />
            </svg>
        );
    }
);

export default ArchiveIcon;
