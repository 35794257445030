import styled from 'styled-components';

export const Container = styled.div<{ isFocused: boolean }>`
    padding: 0 24px 24px 24px;
    
    .rolename-textbox {
        border-radius: 4px;
        padding: 7px 11px;
        background: var(--ps-grey-3);
        border: 1px solid ${props => props.isFocused ? 'var(--ps-grey-2)' : 'var(--ps-grey-3)'};

        textarea {
            padding: 0;
            min-height: 140px;
            font-size: 15px;
            line-height: 18px !important;
            background: transparent !important;

            &:disabled {
                color: var(--ps-grey-2);
            }
            
            ::placeholder {
                color: var(--ps-grey-22) !important;
            }
        }
    }

    .rolename-textbox.warning {
        border: 1px solid var(--ps-red-main);
    }
`;

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
`;

export const TitleContainer = styled.div`
    display: inline-block;
`;

export const RequiredText = styled.span`
    color: var(--ps-grey-1)
`;

export const TextAreaContainer = styled.div `
    cursor: pointer;
`

export const ParamsContainer = styled.div<{isDisabled?: boolean}>`
    position: relative;
    cursor: pointer;
    ${props => props.isDisabled && 'pointer-events: none;'}
    :hover {
        .pencil-container {
            visibility: visible
        }
    }
`

export const TextContainer = styled.div<{isEmpty: boolean}>`
    max-width: 472px;
    font-family: Manrope;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.33;
    text-align: left;
    color: ${props => !props.isEmpty ? 'var(--ps-black-1)' : 'var(--ps-grey-2)'};
`;

export const WarningText = styled.span`
    color: var(--ps-red-main);
    font-size: 11px;
`;