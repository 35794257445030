import styled from "styled-components";
import { ReactMic } from "@cleandersonlobo/react-mic";

//region Styles
export const StyledScriptPlayingAudioAction = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    height: 48px;
`;

export const StyledPlayingAudioButton = styled.div`
    height: 100%;
    background: ${props => props.theme.Colors.darkTwo};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 16px;
`;

export const StyledButton = styled.div`
    font-size: 11px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

export const IconContainer = styled.div`
    width: 14px;
    height: 14px;
    color: ${props => props.theme.Colors.darkSkyBlue};
    margin-right: 8px;
`;

export const ButtonText = styled.div`
    color: ${props => props.theme.Colors.darkSkyBlue};
    font-size: 11px;
    font-weight: 600;
`;

export const StyledReactMicContainer = styled.div`
    height: 100%;
    flex: 1;
    padding-left: 16px;
    background: ${props => props.theme.Colors.darkTwo};
`;

export const StyledReactMic = styled(ReactMic)`
    height: 100%;
    width: 100%;
`;
//endregion