import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const CopyLink = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path d="M9.06 7.44a.498.498 0 0 1-.353-.147 2.002 2.002 0 0 0-2.828 0 .5.5 0 1 1-.707-.707 3.003 3.003 0 0 1 4.242 0 .5.5 0 0 1-.353.853zM8.707 10.292a2.99 2.99 0 0 1-2.121-.878.5.5 0 1 1 .707-.707c.78.78 2.049.78 2.828 0a.5.5 0 1 1 .707.707 2.992 2.992 0 0 1-2.12.878z" fill="currentColor"/>
                <path d="M10.475 9.56a.5.5 0 0 1-.354-.854l2.793-2.792a.5.5 0 1 1 .707.707l-2.793 2.793a.498.498 0 0 1-.353.147zM6.268 13.768a.5.5 0 0 1-.354-.854l1.562-1.561a.5.5 0 1 1 .707.707L6.62 13.621a.497.497 0 0 1-.353.147zM2.732 10.232a.5.5 0 0 1-.353-.854l2.793-2.792a.5.5 0 1 1 .707.707l-2.793 2.793a.5.5 0 0 1-.354.146zM8.171 4.794a.499.499 0 0 1-.354-.854L9.38 2.38a.5.5 0 0 1 .707.707L8.525 4.647a.5.5 0 0 1-.354.147z" fill="currentColor"/>
                <path d="M4.5 14.484a2.991 2.991 0 0 1-2.121-.863c-1.15-1.15-1.15-3.093 0-4.243a.5.5 0 1 1 .707.707c-.767.767-.767 2.063 0 2.829.766.767 2.062.767 2.828 0a.5.5 0 1 1 .707.707 2.99 2.99 0 0 1-2.121.863zM13.268 6.768a.5.5 0 0 1-.354-.853c.767-.767.767-2.063 0-2.83-.766-.766-2.062-.766-2.828 0a.5.5 0 1 1-.707-.706c1.15-1.15 3.093-1.15 4.242 0 1.15 1.15 1.15 3.092 0 4.242a.5.5 0 0 1-.353.147z" fill="currentColor"/>
            </svg>
        );
    }
);

export default CopyLink;
