import { Action } from 'redux';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { AiLogItemInterface } from '../../../constants/interfaces/AiItem';

export enum ACTIONS {
    SEARCH_AI_LOGS_START = 'ADMIN_SEARCH_AI_START',
    SEARCH_AI_LOGS_SUCCESS = 'ADMIN_SEARCH_AI_SUCCESS',
    SEARCH_AI_LOGS_FAILURE = 'ADMIN_SEARCH_AI_FAILURE',
    SEARCH_AI_LOGS_DISPOSE = 'ADMIN_SEARCH_AI_DISPOSE',
    UPDATE_AI_LOGS_FLAG_START = 'UPDATE_AI_LOGS_FLAG_START',
    UPDATE_AI_LOGS_FLAG_FAILURE = 'UPDATE_FLAG_AI_LOGS_FAILURE',
    ADD_AI_LOGS_FLAG_SUCCESS = 'ADD_AI_LOGS_FLAG_SUCCESS',
    DELETE_AI_LOGS_FLAG_SUCCESS = 'DELETE_AI_LOGS_FLAG_SUCCESS',
}

export type SearchAiLogsStartAction = Action<ACTIONS.SEARCH_AI_LOGS_START>;
export const searchAiLogsStart = (): SearchAiLogsStartAction => ({
    type: ACTIONS.SEARCH_AI_LOGS_START,
});

export type SearchAiLogsSuccessAction = Action<
    ACTIONS.SEARCH_AI_LOGS_SUCCESS
> & {
    aiLogs: ListResult<AiLogItemInterface>;
};
export const searchAiLogsSuccess = (
    aiLogs: ListResult<AiLogItemInterface>
): SearchAiLogsSuccessAction => ({
    type: ACTIONS.SEARCH_AI_LOGS_SUCCESS,
    aiLogs,
});

export type SearchAiLogsFailureAction = Action<
    ACTIONS.SEARCH_AI_LOGS_FAILURE
> & { error: string };
export const searchAiLogsFailure = (
    error: string
): SearchAiLogsFailureAction => ({
    type: ACTIONS.SEARCH_AI_LOGS_FAILURE,
    error,
});

export type UpdateAiLogsFlagFailureAction = Action<
    ACTIONS.UPDATE_AI_LOGS_FLAG_FAILURE
> & { error: string };

export const updateAiLogsFlagFailure = (
    error: string
): UpdateAiLogsFlagFailureAction => ({
    type: ACTIONS.UPDATE_AI_LOGS_FLAG_FAILURE,
    error,
});

export type SearchAiLogsDisposeAction = Action<ACTIONS.SEARCH_AI_LOGS_DISPOSE>;
export const searchAiLogsDispose = (): SearchAiLogsDisposeAction => ({
    type: ACTIONS.SEARCH_AI_LOGS_DISPOSE,
});

export type UpdateAiLogsFlagStartAction = Action<
    ACTIONS.UPDATE_AI_LOGS_FLAG_START
> & {
    submissionId: number;
};
export const updateAiLogsFlagStart = (
    submissionId: number,
): UpdateAiLogsFlagStartAction => ({
    type: ACTIONS.UPDATE_AI_LOGS_FLAG_START,
    submissionId,
});

export type AddAiLogsFlagSuccessAction = Action<
    ACTIONS.ADD_AI_LOGS_FLAG_SUCCESS
> & {
    submissionId: number;
    flagId: number;
};
export const addAiLogsFlagSuccess = (
    submissionId: number,
    flagId: number
): AddAiLogsFlagSuccessAction => ({
    type: ACTIONS.ADD_AI_LOGS_FLAG_SUCCESS,
    submissionId,
    flagId,
});

export type DeleteAiLogsFlagSuccessAction = Action<
    ACTIONS.DELETE_AI_LOGS_FLAG_SUCCESS
> & {
    submissionId: number
};
export const deleteAiLogsFlagSuccess = (
    submissionId: number
): DeleteAiLogsFlagSuccessAction => ({
    type: ACTIONS.DELETE_AI_LOGS_FLAG_SUCCESS,
    submissionId,
});

export type AiLogsActions =
    | SearchAiLogsStartAction
    | SearchAiLogsSuccessAction
    | SearchAiLogsFailureAction
    | SearchAiLogsDisposeAction
    | UpdateAiLogsFlagStartAction
    | AddAiLogsFlagSuccessAction
    | DeleteAiLogsFlagSuccessAction
    | UpdateAiLogsFlagFailureAction;
