import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { SubmissionReview, SubmissionV2 } from '../../../constants/interfaces/Reviews';

export enum ACTIONS {
    SEARCH_CHALLENGE_SUBMISSIONS_START = 'SEARCH_CHALLENGE_SUBMISSIONS_START',
    SEARCH_CHALLENGE_SUBMISSIONS_SUCCESS = 'SEARCH_CHALLENGE_SUBMISSIONS_SUCCESS',
    SEARCH_CHALLENGE_SUBMISSIONS_FAILURE = 'SEARCH_CHALLENGE_SUBMISSIONS_FAILURE',

    SEARCH_SUBMISSION_PAGINATION_START = 'SEARCH_SUBMISSION_PAGINATION_START',
    SEARCH_SUBMISSION_PAGINATION_SUCCESS = 'SEARCH_SUBMISSION_PAGINATION_SUCCESS',
    SEARCH_SUBMISSION_PAGINATION_FAILURE = 'SEARCH_SUBMISSION_PAGINATION_FAILURE',

    SEARCH_CHALLENGES_FOR_SUBMISSIONS_START = 'SEARCH_CHALLENGES_FOR_SUBMISSIONS_START',
    SEARCH_CHALLENGES_FOR_SUBMISSIONS_SUCCESS = 'SEARCH_CHALLENGES_FOR_SUBMISSIONS_SUCCESS',
    SEARCH_CHALLENGES_FOR_SUBMISSIONS_FAILURE = 'SEARCH_CHALLENGES_FOR_SUBMISSIONS_FAILURE',

    SEARCH_PRACTIS_SETS_FOR_SUBMISSIONS_START = 'SEARCH_PRACTIS_SETS_FOR_SUBMISSIONS_START',
    SEARCH_PRACTIS_SETS_FOR_SUBMISSIONS_SUCCESS = 'SEARCH_PRACTIS_SETS_FOR_SUBMISSIONS_SUCCESS',
    SEARCH_PRACTIS_SETS_FOR_SUBMISSIONS_FAILURE = 'SEARCH_PRACTIS_SETS_FOR_SUBMISSIONS_FAILURE',

    UPDATE_CHALLENGE_SUBMISSION_START = 'UPDATE_CHALLENGE_SUBMISSION_START',
    UPDATE_CHALLENGE_SUBMISSION_SUCCESS = 'UPDATE_CHALLENGE_SUBMISSION_SUCCESS',
    UPDATE_CHALLENGE_SUBMISSION_FAILURE = 'UPDATE_CHALLENGE_SUBMISSION_FAILURE',

    GET_SUBMISSION_DETAILS_START = 'GET_SUBMISSION_DETAILS_START',
    GET_SUBMISSION_DETAILS_SUCCESS = 'GET_SUBMISSION_DETAILS_SUCCESS',
    GET_SUBMISSION_DETAILS_FAILURE = 'GET_SUBMISSION_DETAILS_FAILURE',
    GET_SUBMISSION_REVIEW_SUCCESS = 'GET_SUBMISSION_REVIEW_SUCCESS',

    UPDATE_SUBMISSION_DETAILS_START = 'UPDATE_SUBMISSION_DETAILS_START',
    UPDATE_SUBMISSION_DETAILS_SUCCESS = 'UPDATE_SUBMISSION_DETAILS_SUCCESS',
    UPDATE_SUBMISSION_DETAILS_FAILURE = 'UPDATE_SUBMISSION_DETAILS_FAILURE',

    UPDATE_SCORE_START = 'UPDATE_SCORE_START',
    UPDATE_SCORE_SUCCESS = 'UPDATE_SCORE_SUCCESS',
    UPDATE_SCORE_FAILURE = 'UPDATE_SCORE_FAILURE',

    MODIFY_SCORE = 'MODIFY_SCORE',

    CHECK_ALL_SUBMISSIONS = 'CHECK_ALL_SUBMISSIONS',
    CHECK_SINGLE_SUBMISSION = 'CHECK_SINGLE_SUBMISSION',

    UPDATE_SUBMISSION_DELETE_LABEL_SUCCESS = 'UPDATE_SUBMISSION_DELETE_LABEL_SUCCESS',

    SEARCH_ACCURACY_SCORE_START = 'SEARCH_ACCURACY_SCORE_START',
    SEARCH_ACCURACY_SCORE_SUCCESS = 'SEARCH_ACCURACY_SCORE_SUCCESS',
    SEARCH_ACCURACY_SCORE_FAILURE = 'SEARCH_ACCURACY_SCORE_FAILURE',

    CHECK_ALL_ACCURACY_SCORES = 'CHECK_ALL_ACCURACY_SCORES',
    CHECK_SINGLE_ACCURACY_SCORE = 'CHECK_SINGLE_ACCURACY_SCORE',
}

export function searchAccuracyScoreStart() {
    return {
        type: ACTIONS.SEARCH_ACCURACY_SCORE_START as ACTIONS.SEARCH_ACCURACY_SCORE_START,
    };
}

export function searchAccuracyScoreSuccess(
    accuracyScores: ListResult<SubmissionV2>
) {
    return {
        type: ACTIONS.SEARCH_ACCURACY_SCORE_SUCCESS as ACTIONS.SEARCH_ACCURACY_SCORE_SUCCESS,
        accuracyScores,
    };
}

export function searchAccuracyScoreFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_ACCURACY_SCORE_FAILURE as ACTIONS.SEARCH_ACCURACY_SCORE_FAILURE,
        error,
    };
}

export function searchChallengeSubmissionsStart() {
    return {
        type: ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_START as ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_START,
    };
}

export function searchChallengeSubmissionsSuccess(
    submissions: ListResult<SubmissionV2>
) {
    return {
        type: ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_SUCCESS as ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_SUCCESS,
        submissions,
    };
}

export function searchChallengeSubmissionsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_FAILURE as ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_FAILURE,
        error,
    };
}

export function searchSubmissionPaginationStart() {
    return {
        type: ACTIONS.SEARCH_SUBMISSION_PAGINATION_START as ACTIONS.SEARCH_SUBMISSION_PAGINATION_START,
    };
}

export function searchSubmissionPaginationSuccess(
    submissionIds: number[], totalCount: number
) {
    return {
        type: ACTIONS.SEARCH_SUBMISSION_PAGINATION_SUCCESS as ACTIONS.SEARCH_SUBMISSION_PAGINATION_SUCCESS,
        submissionIds,
        totalCount
    };
}

export function searchSubmissionPaginationFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_SUBMISSION_PAGINATION_FAILURE as ACTIONS.SEARCH_SUBMISSION_PAGINATION_FAILURE,
        error,
    };
}

export function updateChallengeSubmissionStart() {
    return {
        type: ACTIONS.UPDATE_CHALLENGE_SUBMISSION_START as ACTIONS.UPDATE_CHALLENGE_SUBMISSION_START,
    };
}

export function updateChallengeSubmissionSuccess(
    submissionIds: number[],
    updateType: 'create' | 'update' | 'delete'
) {
    return {
        type: ACTIONS.UPDATE_CHALLENGE_SUBMISSION_SUCCESS as ACTIONS.UPDATE_CHALLENGE_SUBMISSION_SUCCESS,
        submissionIds,
        updateType,
    };
}

export function updateChallengeSubmissionFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_CHALLENGE_SUBMISSION_FAILURE as ACTIONS.UPDATE_CHALLENGE_SUBMISSION_FAILURE,
        error,
    };
}

export function getSubmissionDetailsStart() {
    return {
        type: ACTIONS.GET_SUBMISSION_DETAILS_START as ACTIONS.GET_SUBMISSION_DETAILS_START,
    };
}

export function getSubmissionDetailsFailure(error: string) {
    return {
        type: ACTIONS.GET_SUBMISSION_DETAILS_FAILURE as ACTIONS.GET_SUBMISSION_DETAILS_FAILURE,
        error,
    };
}

export function getSubmissionDetailsSuccess(submission: SubmissionV2, review?: SubmissionReview) {
    return {
        type: ACTIONS.GET_SUBMISSION_DETAILS_SUCCESS as ACTIONS.GET_SUBMISSION_DETAILS_SUCCESS,
        submission,
        review,
    };
}

export function getSubmissionReviewSuccess(review: SubmissionReview) {
    return {
        type: ACTIONS.GET_SUBMISSION_REVIEW_SUCCESS as ACTIONS.GET_SUBMISSION_REVIEW_SUCCESS,
        review,
    };
}

export function modifyScore(value: string | number, field: string) {
    return {
        type: ACTIONS.MODIFY_SCORE as ACTIONS.MODIFY_SCORE,
        field: field,
        value: value,
    };
}

export function checkSingleSubmission(id: number) {
    return {
        type: ACTIONS.CHECK_SINGLE_SUBMISSION as ACTIONS.CHECK_SINGLE_SUBMISSION,
        id,
    };
}

export function checkAllSubmissions(
    submissionId: any,
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.CHECK_ALL_SUBMISSIONS as ACTIONS.CHECK_ALL_SUBMISSIONS,
        submissionId,
        checked,
        partial,
    };
}

export function checkAllAccuracyScores(
    submissionId: any,
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.CHECK_ALL_ACCURACY_SCORES as ACTIONS.CHECK_ALL_ACCURACY_SCORES,
        submissionId,
        checked,
        partial,
    };
}

export function checkSingleAccuracyScore(id: number) {
    return {
        type: ACTIONS.CHECK_SINGLE_ACCURACY_SCORE as ACTIONS.CHECK_SINGLE_ACCURACY_SCORE,
        id,
    };
}

export function updateSubmissionDeleteLabelSuccess(
    labelId: number,
    id: number
) {
    return {
        type: ACTIONS.UPDATE_SUBMISSION_DELETE_LABEL_SUCCESS as ACTIONS.UPDATE_SUBMISSION_DELETE_LABEL_SUCCESS,
        labelId,
        id,
    };
}
