import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 1px 0 25px 0;
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 14px;
`;

export const Title = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: var(--ps-grey-1);
    margin-left: 5px;
`;
