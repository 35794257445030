import { FC, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
    ActionButton,
    ActionItem,
} from '../../../../ui/components/ActionButton';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { PaginationResult } from '../../../../constants/interfaces/PaginationResult';
import { UserProfile } from '../../../../constants/interfaces/User';
import {
    useDeleteStagingService,
    useSearchDraftsService,
    useUpdateAllSelectedDraftStateService,
    useUpdateSelectedDraftStateService,
} from '../../store/services';
import { useDraftsState, useUpdatedDraftsState } from '../../store/states';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import UsersPageNavigationContainer from '../../components/UsersPageNavigation';
import { clearSelectedItemIfNotLoaded } from '../../../labels/tools';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { Draft, DraftUser } from '../../../../constants/interfaces/Draft';
import { formatDate } from '../../../../helpers/functions/date-convert';
import { CheckPermission } from '../../../permissions';
import ROUTES from '../../../../routes/routes';
import { usePermissionsState } from '../../../permissions/store/state';
import { useDraftEditState } from '../../../../pages/ActionPages/NewUser/store/states';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import { Popup } from '../../../../ui/components/Popup';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { DraftsFilter } from '../../components/Filters/DraftsFilter/DraftsFilter';

import NoDrafts from '../../../../ui/icons/NoDrafts';
import { isAdminRole, isTeamLeader } from '../../../../constants/enums';
import { useSessionEffect } from '../../../common';
import { normalizeSP } from '../../../../helpers/functions/searchparams-normalize';
import { getSocketState } from '../../../socketProgress(api1)/store/reducer';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { isEmpty } from 'lodash';
import { useStyles } from './styles';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useFilter } from '../../../../ui/components/table-wrapper/table/TableDropdown';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import { CompanyUserStatsText } from '../../../../ui/components/CompanyUserStatsText';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const CREATOR_FIELD = 'creatorId';
const EDITOR_FIELD = 'lastEditedById';

const Drafts: FC<{
    history: History<any>;
    draftEditUpdated?: Partial<DraftUser>;
    searchDrafts: (sp: SearchParams) => void;
    onDeleteDraft: (
        draftIds: number[],
        search?: SearchParams,
        selectAll?: boolean,
        successCallback?: () => void
    ) => void;
    profile?: UserProfile;
    drafts?: PaginationResult<Draft>;
    selectedDrafts?: number[];
    updatedDrafts?: Draft;
    updateAllSelectedDrafts(
        userIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedDraft(draftId: number): void;
    loading?: boolean;
}> = ({
    drafts,
    draftEditUpdated,
    searchDrafts,
    onDeleteDraft,
    profile,
    updatedDrafts,
    selectedDrafts,
    updateAllSelectedDrafts,
    updateSelectedDraft,
    loading,
    history,
}) => {
    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const [createdBySelectedUsers, setCreatedBySelectedUsers] = useState<
        number[]
    >([]);
    const [createdBySavedUsers, setCreatedBySavedUsers] = useState<number[]>(
        []
    );
    const [editedBySelectedUsers, setEditedBySelectedUsers] = useState<
        number[]
    >([]);
    const [editedBySavedUsers, setEditedBySavedUsers] = useState<number[]>([]);

    const canAccessPage = useHandleAccessPage();
    const showConfirmationModalDialog = useShowConfirmModalDialog();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        orderBy: { field: 'lastEditedBy.firstName', asc: false },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        setFilter,
        refreshSearchParams,
    } = useSearchParamsState(initialSearchParams);

    const classes = useStyles();
    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const isCheckboxPartiallySelected =
        drafts &&
        drafts.items &&
        selectedDrafts &&
        selectedDrafts.length < drafts.items.length;

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchDrafts(searchParams);
        setLastRefreshed(new Date());
        setIsSelectAll(false);

        updateAllSelectedDrafts([], true, isCheckboxPartiallySelected);
    }, [
        initialOffset,
        isCheckboxPartiallySelected,
        searchDrafts,
        searchParams,
        updateAllSelectedDrafts,
    ]);

    const socketLoaderState = useSelector(getSocketState);

    const handleItemEdit = useCallback(
        (draftId: any) => {
            history.push(
                ROUTES.ACTION_ITEMS.NEW_USER.replace(
                    ':draftId',
                    draftId.toString()
                )
            );
        },
        [history]
    );

    const updateAllUsersCheck = useCallback(
        (checked: boolean) => {
            const draftIds =
                (drafts &&
                    drafts.items.map((draft: Draft) => Number(draft.id))) ||
                [];
            updateAllSelectedDrafts(
                draftIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [drafts, isCheckboxPartiallySelected, updateAllSelectedDrafts]
    );

    const handleDraftCheck = (e: any, draft: Draft) => {
        e.stopPropagation();
        if (!!draft.id) {
            updateSelectedDraft(draft.id);
        }
    };

    const isOptionsDisabled =
        !selectedDrafts || (selectedDrafts && !selectedDrafts.length);

    const orderBy = searchParams.orderBy;
    const srtName = useOrderBy('name', orderBy, setOrderBy);
    const srtUsersCount = useOrderBy('usersCount', orderBy, setOrderBy);
    const srtCreatedBy = useOrderBy('creator.firstName', orderBy, setOrderBy);
    const srtCreatedAt = useOrderBy('createdAt', orderBy, setOrderBy);
    const srtLastEditedBy = useOrderBy(
        'lastEditedBy.firstName',
        orderBy,
        setOrderBy
    );
    const srtUpdatedAt = useOrderBy('updatedAt', orderBy, setOrderBy);

    const handleCreatorIdChangeEvent = useFilter(CREATOR_FIELD, setFilter);
    const handleEditorIdChangeEvent = useFilter(EDITOR_FIELD, setFilter);

    const selectCreatedByUsers = (userIds: number[]) => {
        setCreatedBySelectedUsers(userIds);
    };
    const saveCreatedByUsers = (userIds: number[]) => {
        setCreatedBySavedUsers(userIds);
    };

    const selectEditedByUsers = (userIds: number[]) => {
        setEditedBySelectedUsers(userIds);
    };
    const saveEditedByUsers = (userIds: number[]) => {
        setEditedBySavedUsers(userIds);
    };

    const permissions = usePermissionsState();
    const handleViewStaging = (draftId: any) => {
        if (!draftId || !permissions.includes(NEW_PERMISSIONS.UPDATE_STAGING))
            return;

        handleItemEdit && handleItemEdit(draftId);
    };

    const isListEmpty = !drafts || (drafts && !drafts.items.length);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: searchParams.filters?.length,
        itemsCount: drafts?.items.length || 0,
    });

    /**
     * @function onConfirmDeleteDrafts
     * @param { number[] } draftIds
     * @returns { void }
     */
    const onConfirmDeleteDrafts = (draftIds: number[]): void => {
        const selectAllState = draftIds.length === 1 ? false : isSelectAll;

        onDeleteDraft(
            draftIds,
            isSelectAll
                ? normalizeSP(searchParams, drafts?.totalCount)
                : searchParams,
            selectAllState
        );
    };

    /**
     * @function handleDeleteDrafts
     * @returns { void }
     */
    const handleDeleteDrafts = (draftIds: number[]): void => {
        showConfirmationModalDialog({
            modalTitle: 'Warning',
            description:
                'This action cannot be undone. You will erase this draft and all the saved information.',
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Proceed',
            dialogType: 'DANGER',
            onConfirm: () => onConfirmDeleteDrafts(draftIds),
        });
    };

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchDrafts(searchParams);
    }, [searchDrafts, searchParams]);

    useEffect(() => {
        if (updatedDrafts || draftEditUpdated?.id) {
            refreshSearchParams();
        }
    }, [updatedDrafts, draftEditUpdated?.id, refreshSearchParams]);

    useEffect(() => {
        const filteredUsers = drafts
            ? drafts.items.map(draft => Number(draft.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedDrafts,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllSelectedDrafts(usersToClear, false);
        }
    }, [selectedDrafts, drafts, updateAllSelectedDrafts]);

    useEffect(() => {
        handleCreatorIdChangeEvent(createdBySavedUsers);
        handleEditorIdChangeEvent(editedBySavedUsers);
    }, [
        createdBySavedUsers,
        editedBySavedUsers,
        handleCreatorIdChangeEvent,
        handleEditorIdChangeEvent,
    ]);

    useEffect(() => {
        return () => {
            selectCreatedByUsers([]);
            selectEditedByUsers([]);
            updateAllSelectedDrafts([], true, isCheckboxPartiallySelected);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedDrafts([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateAllSelectedDrafts]);

    useEffect(() => {
        if (
            selectedDrafts?.length &&
            selectedDrafts.length === drafts?.totalCount
        ) {
            setIsSelectAll(true);
        }
    }, [drafts?.totalCount, selectedDrafts]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
    }, [initialOffset, pageIndex, setOffset]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_USER]);
    }, [canAccessPage]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        setIsSelectAll(false);
        updateAllUsersCheck(event.target.checked);
    };

    /**
     * @function checkIsRowChecked
     * @param { Draft } draft
     * @returns { boolean }
     */
    const checkIsRowChecked = (draft: Draft): boolean =>
        !!selectedDrafts && !!draft.id && selectedDrafts.includes(draft.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { Draft } row
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, draft: Draft) => {
        setIsSelectAll(false);
        handleDraftCheck(event, draft);
    };

    return (
        <MainWrapper
            subTitle="Users"
            subHeader={profile?.companyId &&
                <CompanyUserStatsText
                    companyId={profile.companyId}
                    showSettingsButton={isAdminRole(profile?.role?.name)}
                />
            }
            tabs={<UsersPageNavigationContainer />}
            htmlPageTitle="Users - Practis"
            dataTest="users"
        >
            {loading && !isSelectAll && !socketLoaderState && (
                <LoadingComponent />
            )}
            <TableWrapper
                tableStates={tableStates}
                data={drafts?.items}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'users-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: drafts?.totalCount ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            searchParams.filters?.length,
                        dataTest: 'users-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                    },
                    isSelectedItemOptionsVisible:
                        !isEmpty(selectedDrafts) && !isEmpty(drafts),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedDrafts?.length,
                        totalCount: drafts?.totalCount,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: () => {
                            updateAllSelectedDrafts(
                                [],
                                true,
                                isCheckboxPartiallySelected
                            );
                            setIsSelectAll(false);
                        },
                        onSelectAll: () => {
                            const draftIds =
                                (drafts &&
                                    drafts.items.map((draft: Draft) =>
                                        Number(draft.id)
                                    )) ||
                                [];
                            updateAllSelectedDrafts(
                                draftIds,
                                true,
                                isCheckboxPartiallySelected
                            );
                            setIsSelectAll(true);
                        },
                        bulkActionsConfig: {
                            disabled: isOptionsDisabled,
                            removeItemTitle: 'Delete Drafts',
                            removeItemPermissions: [
                                NEW_PERMISSIONS.DELETE_STAGING,
                            ],
                            onRemoveItemsSubmit: () => {
                                selectedDrafts &&
                                    handleDeleteDrafts(selectedDrafts);
                            },
                            isSelectAll: isSelectAll,
                            totalCount: drafts?.totalCount,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <DraftsFilter
                                        createdBySelectedUsers={
                                            createdBySelectedUsers
                                        }
                                        createdBySavedUsers={
                                            createdBySavedUsers
                                        }
                                        selectCreatedByUsers={
                                            selectCreatedByUsers
                                        }
                                        saveCreatedByUsers={saveCreatedByUsers}
                                        editedBySelectedUsers={
                                            editedBySelectedUsers
                                        }
                                        editedBySavedUsers={editedBySavedUsers}
                                        selectEditedByUsers={
                                            selectEditedByUsers
                                        }
                                        saveEditedByUsers={saveEditedByUsers}
                                        onSuccessApply={() => {
                                            hide();
                                        }}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={
                                            createdBySavedUsers.length +
                                            editedBySavedUsers.length
                                        }
                                        disabled={
                                            tableStates.disableFilters ||
                                            isTeamLeader(profile?.role?.name)
                                        }
                                        dataTest="users-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && drafts?.items?.length === 0,
                    noEntriesOptions: {
                        icon: NoDrafts,
                        text: 'No Drafts Yet',
                        dataTest: 'no-results',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Drafts',
                        dataTest: 'no-found-results',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'no-filtered-results',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: !!selectedDrafts && !!selectedDrafts.length,
                        disabled:
                            !drafts || (drafts && drafts.items.length < 1),
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Drafts',
                            width: 20,
                            ...(isListEmpty ? null : srtName),
                            disabled: isListEmpty,
                            className: classes.customTableColumnStyle,
                            dataTest: 'name-column',
                        },
                        {
                            title: 'Users',
                            width: 16,
                            ...(isListEmpty ? null : srtUsersCount),
                            disabled: isListEmpty,
                            dataTest: 'users-count-column',
                        },
                        {
                            title: 'Created by',
                            width: 16,
                            ...(isListEmpty ? null : srtCreatedBy),
                            disabled: isListEmpty,
                            dataTest: 'creator-column',
                        },
                        {
                            title: 'Created on',
                            width: 16,
                            ...(isListEmpty ? null : srtCreatedAt),
                            disabled: isListEmpty,
                            dataTest: 'creation-date-column',
                        },
                        {
                            title: 'Edited by',
                            width: 15,
                            ...(isListEmpty ? null : srtLastEditedBy),
                            disabled: isListEmpty,
                            dataTest: 'editor-column',
                        },
                        {
                            title: 'Edited on',
                            width: 15,
                            ...(isListEmpty ? null : srtUpdatedAt),
                            disabled: isListEmpty,
                            dataTest: 'edit-date-column',
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: (draft: Draft) =>
                            handleViewStaging(draft.id),
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        dataTest: 'draft-item',
                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (draft: Draft) =>
                                        `${draft.name}`,
                                    isOverFlowText: true,
                                    getTextFieldProps: (draft: Draft) => ({
                                        isCurrent:
                                            profile?.id === draft?.id ?? false,
                                    }),
                                    dataTest: 'draft-name',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (draft: Draft) =>
                                        draft?.usersCount?.toString(),
                                    dataTest: 'users-count',
                                },
                            },
                            {
                                fieldType: 'AVATAR_FIELD',
                                shouldShowEmptyCell: (draft: Draft) =>
                                    !draft?.creator,
                                fieldProps: {
                                    hasMargin: (draft: Draft) =>
                                        !!draft.creator,
                                    isMinimized: true,
                                    renderTitle: (draft: Draft) =>
                                        `${draft?.creator?.firstName} ${draft?.creator?.lastName}`,
                                    getAvatarProps: (draft: Draft) => ({
                                        profile: draft?.creator,
                                    }),
                                    dataTest: 'creator',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (draft: Draft) =>
                                        formatDate(draft?.createdAt),
                                    dataTest: 'creation-date',
                                },
                            },
                            {
                                fieldType: 'AVATAR_FIELD',
                                shouldShowEmptyCell: (draft: Draft) =>
                                    !draft?.lastEditedBy,
                                fieldProps: {
                                    hasMargin: (draft: Draft) =>
                                        !!draft.lastEditedBy,
                                    isMinimized: true,
                                    renderTitle: (draft: Draft) =>
                                        `${draft?.lastEditedBy?.firstName} ${draft?.lastEditedBy?.lastName}`,
                                    getAvatarProps: (draft: Draft) => ({
                                        profile: draft?.lastEditedBy,
                                    }),
                                    dataTest: 'editor',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                fieldProps: {
                                    renderTitle: (draft: Draft) =>
                                        formatDate(draft?.updatedAt),
                                    dataTest: 'update-date',
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (draft: Draft) => (
                                        <ActionButton
                                            customWidth={168}
                                            isHidden={!!selectedDrafts?.length}
                                            dataTest="list-item-menu"
                                        >
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.UPDATE_STAGING,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={() =>
                                                        handleItemEdit(draft.id)
                                                    }
                                                    dataTest="edit-action"
                                                >
                                                    Edit
                                                </ActionItem>
                                            </CheckPermission>
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.DELETE_STAGING,
                                                ]}
                                            >
                                                <ActionItem
                                                    destructive={true}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleDeleteDrafts([
                                                            draft.id!,
                                                        ]);
                                                    }}
                                                    dataTest="delete-draft-action"
                                                >
                                                    Delete Draft
                                                </ActionItem>
                                            </CheckPermission>
                                        </ActionButton>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const DraftsContainer: FC = () => {
    const state = useDraftsState();
    const updatedDraftsState = useUpdatedDraftsState();
    const history = useHistory();
    const searchDrafts = useSearchDraftsService();
    const profile = useSelector(getProfileState);
    const searchDraftsDebounced = useSearchDebounced(searchDrafts);
    const updateAllSelectedDrafts = useUpdateAllSelectedDraftStateService();
    const updateSelectedDraft = useUpdateSelectedDraftStateService();
    const deleteDraft = useDeleteStagingService();
    const draftEditUpdated = useDraftEditState().updateData;

    return (
        <Drafts
            history={history}
            draftEditUpdated={draftEditUpdated}
            searchDrafts={searchDraftsDebounced}
            onDeleteDraft={deleteDraft}
            profile={profile}
            drafts={state.data}
            selectedDrafts={state.selectedDrafts}
            loading={state.loading}
            updatedDrafts={updatedDraftsState.data}
            updateAllSelectedDrafts={updateAllSelectedDrafts}
            updateSelectedDraft={updateSelectedDraft}
        />
    );
};


export default DraftsContainer;