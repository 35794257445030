import { useEffect, useRef } from 'react';
import { getIn } from 'formik';

import { Input } from '../input';
import InputPhone from '../input/PhoneInput/PhoneInput';
import { InputProps } from './types';
import {
    HintContainer,
    StyledErrorContainer,
    StyledFormikField,
} from './styles';

const FormikField = ({
    form,
    field,
    type,
    label,
    height,
    fontSize,
    disabled,
    labelProps,
    autoComplete,
    background,
    labelLeftPosition,
    inputPadding,
    fontFamily,
    errorPadding,
    errorColor,
    hasManualError,
    hideError,
    autoFocus,
    customError,
    hint,
    dataTest,
    ...props
}: InputProps) => {
    let errorContent;
    const error = getIn(form.errors, field.name) || customError;
    const touch = getIn(form.touched, field.name);
    const inputRef = useRef<any>(null);

    useEffect(() => {
        if (autoFocus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    errorContent = touch && error && (
        <StyledErrorContainer
            padding={errorPadding}
            color={errorColor}
            data-test={dataTest && `${dataTest}-error`}
            className={'formik-field-error'}
        >
            {error}
        </StyledErrorContainer>
    );

    if (type && type === 'phone') {
        return (
            <StyledFormikField>
                <InputPhone
                    {...field}
                    field={field}
                    type={type ? type : 'text'}
                    label={label}
                    disabled={disabled}
                    error={!!errorContent}
                    hasManualError={hasManualError}
                    background={background}
                    height={height}
                    fontSize={fontSize}
                    dataTest={dataTest}
                    {...props}
                />
                {errorContent}
            </StyledFormikField>
        );
    }

    return (
        <StyledFormikField>
            <Input
                {...field}
                field={field}
                ref={inputRef}
                inputPadding={inputPadding}
                type={type ? type : 'text'}
                label={label}
                labelLeftPosition={labelLeftPosition}
                labelProps={labelProps}
                disabled={disabled}
                autoComplete={autoComplete}
                error={!!errorContent}
                background={background}
                height={height}
                fontSize={fontSize}
                fontFamily={fontFamily}
                hideError={hideError}
                dataTest={dataTest}
                {...props}
            />
            {!hideError && errorContent}
            {hint && !errorContent && <HintContainer>{hint}</HintContainer>}
        </StyledFormikField>
    );
};

export default FormikField;
