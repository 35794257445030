import { useState, FC, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getCompanyState } from '../../../pages/CompanySettings/store/reducers';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import { useAiLogsState } from '../store/states';
import {
    useAiLogsUnmountService,
    useSearchAiLogsService,
    useToggleAiLogsFlagService,
} from '../store/services';

import { countAppliedFiltersCount } from '../../../features/teams/pages/ListOfTeams/tools';
import { AiAssessmentFilters } from '../components/AiAssessmentFilters';
import { usePreviousData } from '../../../helpers/hooks/usePreviousData';
import { useHistory } from 'react-router-dom';
import { TableWrapper } from '../../../ui/components/table-wrapper';
import {
    Actions,
    ScenarioWrapper,
    StyledExclamationCircleIcon,
    useStyles,
} from './styles';
import {
    AiLogItemInterface,
    AiLogItemStatus,
} from '../../../constants/interfaces/AiItem';

import { TrainingMode, trainingModeName } from '../../../constants/enums';
import dayjs from 'dayjs';
import { useOrderBy } from '../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { LoadingComponent } from '../../../ui/components/LoadingCopmonent';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import { useTableStateHelper } from '../../../ui/components/table-wrapper/helper';
import { FiltersPopupButton } from '../../../ui/components/Filters';
import { Tooltip } from '../../../ui/components/Tooltip';
import {
    useCalendarFilterOptions,
    useHeaderOptions,
    useEmptyStateOptions
} from './hooks';
import FlagIcon from '../../../ui/icons/Flag';
import FlagToggle from '../components/FlagToggle';
import { isEqual } from 'lodash';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { usePortableTeamsState } from '../../portableTeams/store/states';
import { TeamBasic } from '../../../constants/interfaces/TeamBasic';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const CompanyAiAssessment: FC<{
    companyId?: number;
    companyName?: string;
    aiLogs?: ListResult<AiLogItemInterface>;
    loading?: boolean;
    portableTeams?: TeamBasic[];
    searchHandler: (
        p: SearchParams,
        userTeamIds: number[],
        companyId?: number
    ) => void;
    unMount: () => void;
    toggleFlag: (record: AiLogItemInterface) => void;
}> = ({
    companyId,
    companyName,
    loading,
    aiLogs,
    portableTeams,
    searchHandler,
    unMount,
    toggleFlag,
}) => {
    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        setCustom,
        setTeamIDs,
    } = useSearchParamsState({
        searchTerm: '',
        filters: [],
        orderBy: { field: 'date', asc: false },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    });

    const history = useHistory();
    const classes = useStyles();
    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const prevSearchParams = usePreviousData(searchParams);
    const prevCompanyId = usePreviousData(companyId);

    /**
     * @function doSearch
     * @returns { void }
     */
    const doSearch = useCallback(() => {
        const userTeamIds = portableTeams!.map(team => team.id!);
        searchParams.offset = initialOffset;
        searchHandler(searchParams, userTeamIds, companyId);
    }, [searchHandler, searchParams, portableTeams, companyId, initialOffset]);

    useEffect(() => {
        if (
            !isFirstLoad &&
            (!isEqual(prevSearchParams, searchParams) ||
                companyId !== prevCompanyId)
        ) {
            doSearch();
        }
    }, [
        doSearch,
        searchParams,
        prevSearchParams,
        companyId,
        prevCompanyId,
        isFirstLoad,
    ]);

    // make a first data load once user teams are ready
    useEffect(() => {
        if (isFirstLoad && portableTeams?.length) {
            setIsFirstLoad(false);
            doSearch();
        }
    }, [isFirstLoad, portableTeams, doSearch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => unMount(), []);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    const { startDate, endDate, orderBy, teamIds = [] } = searchParams;
    const handleRefresh = useCallback(() => {
        doSearch();
        setLastRefreshed(new Date());
    }, [doSearch]);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: teamIds.length,
        itemsCount: aiLogs?.items.length || 0,
        startDate,
        endDate,
    });

    const headerOptions = {
        ...useHeaderOptions(searchParams, setOrderBy),
        flag: useOrderBy('flag_company', orderBy, setOrderBy),
    };
    const calendarFilterOptions = useCalendarFilterOptions(
        searchParams,
        tableStates.showEmptyState,
        setCustom,
        'assessment-calendar'
    );
    const emptyStateOptions = useEmptyStateOptions(
        !loading && aiLogs?.items?.length === 0
    );

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    return (
        <MainWrapper
            subTitle="Training Log"
            htmlPageTitle={`Training Log ${
                companyName ? `- ${companyName}` : ''
            } - Practis`}
            dataTest="assessment"
        >
            {loading && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={aiLogs?.items}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: handleRefresh,
                    dataTest: 'assessment-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: aiLogs?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'assessment-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'assessment-search',
                    },
                    filterOptions: {
                        filterComponent: (
                            <FiltersPopupButton
                                disabled={tableStates.disableFilters}
                                filtersCount={teamIds.length}
                                dataTest="assessment-filters"
                            >
                                {({ hide }) => (
                                    <AiAssessmentFilters
                                        teamIds={teamIds}
                                        onApplyFilter={selectedTeams => {
                                            setTeamIDs(selectedTeams);
                                            hide();
                                        }}
                                    />
                                )}
                            </FiltersPopupButton>
                        ),
                    },
                    calendarFilterOptions,
                }}
                tableEmptyStateConfigurations={emptyStateOptions}
                configurations={{
                    columns: [
                        {
                            title: '#',
                            width: 10,
                            ...headerOptions.id,
                            disabled: tableStates.disableSorting,
                            className: classes.customTableCellStyle,
                            dataTest: 'id-column',
                        },
                        {
                            title: 'Users',
                            width: 15,
                            ...headerOptions.user,
                            disabled: tableStates.disableSorting,
                            dataTest: 'users-column',
                        },
                        {
                            title: 'Scenario',
                            width: 20,
                            ...headerOptions.scenario,
                            disabled: tableStates.disableSorting,
                            dataTest: 'scenario-column',
                        },
                        {
                            title: 'Rep',
                            width: 10,
                            disabled: tableStates.disableSorting,
                            dataTest: 'rep-column',
                        },
                        {
                            title: 'Date',
                            width: 13.8,
                            ...headerOptions.date,
                            disabled: tableStates.disableSorting,
                            dataTest: 'date-column',
                        },
                        {
                            title: 'Mode',
                            width: 10,
                            ...headerOptions.mode,
                            disabled: tableStates.disableSorting,
                            dataTest: 'mode-column',
                        },
                        {
                            title: 'Accuracy',
                            width: 10,
                            ...headerOptions.accuracy,
                            disabled: tableStates.disableSorting,
                            dataTest: 'accuracy-column',
                        },
                        {
                            children: <FlagIcon />,
                            width: 5,
                            ...headerOptions.flag,
                            disabled: tableStates.disableSorting,
                            dataTest: 'flag-column',
                        },
                    ],
                    rowConfig: {
                        dataTest: 'assessment-item',
                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record?.id,
                                fieldProps: {
                                    renderTitle: (record: AiLogItemInterface) =>
                                        record?.id?.toString(),
                                    dataTest: 'assessment-item-id',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record.user,
                                fieldProps: {
                                    renderTitle: (record: AiLogItemInterface) =>
                                        `${record.user.firstName} ${record.user.lastName}`,
                                    dataTest: 'assessment-item-user',
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record.scenarioName,
                                getCustomFieldComponent: (
                                    record: AiLogItemInterface
                                ) => (
                                    <ScenarioWrapper
                                        status={
                                            record.scenarioStatus as AiLogItemStatus
                                        }
                                    >
                                        <div data-test="assessment-item-scenario">
                                            {record.scenarioName}
                                        </div>
                                        {record.scenarioStatus ===
                                            AiLogItemStatus.DELETED && (
                                            <Tooltip
                                                label="Scenario has been deleted"
                                                position="center-right--bottom"
                                            >
                                                <span data-test="assessment-item-scenario-icon">
                                                    <StyledExclamationCircleIcon />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </ScenarioWrapper>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record.repNumber
                                        && (record.submissionAccuracyMode as TrainingMode) !== TrainingMode.TEST_YOURSELF,
                                fieldProps: {
                                    renderTitle: (record: AiLogItemInterface) =>
                                        record.repNumber
                                            ? `${record.repNumber} of ${record.minRepsCount}`
                                            : 'N/A',
                                    dataTest: 'assessment-item-rep',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record.dateCreated,
                                fieldProps: {
                                    renderTitle: (record: AiLogItemInterface) =>
                                        dayjs(record.dateCreated).format(
                                            'MM/DD/YY h:mm A'
                                        ),
                                    dataTest: 'assessment-item-date',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record.submissionAccuracyMode,
                                fieldProps: {
                                    renderTitle: (record: AiLogItemInterface) =>
                                        trainingModeName(
                                            record.submissionAccuracyMode as TrainingMode
                                        ),
                                    dataTest: 'assessment-item-mode',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    record: AiLogItemInterface
                                ) => !record.accuracy,
                                fieldProps: {
                                    renderTitle: (record: AiLogItemInterface) =>
                                        `${Math.floor(record.accuracy * 100)}%`,
                                    dataTest: 'assessment-item-accuracy',
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                getCustomFieldComponent: (
                                    record: AiLogItemInterface
                                ) => (
                                    <Actions>
                                        <FlagToggle
                                            checked={!!record.flagCompanyId}
                                            onChange={() => toggleFlag(record)}
                                            dataTest="assessment-item-flag"
                                        />
                                    </Actions>
                                ),
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

const CompanyAiAssessmentContainer: FC = () => {
    const searchAiLogs = useSearchAiLogsService();
    const unMount = useAiLogsUnmountService();
    const toggleAiLogsFlag = useToggleAiLogsFlagService();

    const company = useSelector(getCompanyState);
    const aiLogsState = useAiLogsState();
    const portableTeams = usePortableTeamsState();

    return (
        <CompanyAiAssessment
            aiLogs={aiLogsState.data}
            companyId={company?.id}
            companyName={company.name}
            loading={aiLogsState.loading || aiLogsState.loadingFlag}
            portableTeams={portableTeams.data?.items}
            searchHandler={searchAiLogs}
            unMount={unMount}
            toggleFlag={toggleAiLogsFlag}
        />
    );
};

export default CompanyAiAssessmentContainer;
