import { forwardRef } from 'react';
import { IconProps } from './types';

export const Saving = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#xvo0q78pwa)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="m14.357 3.143 1.216-1.216a.25.25 0 0 1 .427.177V5.75a.25.25 0 0 1-.25.25h-3.646a.25.25 0 0 1-.177-.427l1.358-1.358a6.5 6.5 0 1 0 1.18 4.458.75.75 0 0 1 1.493.154 8.001 8.001 0 1 1-1.6-5.684z"
                        fill="#6D7F8C"
                    />
                </g>
                <defs>
                    <clipPath id="xvo0q78pwa">
                        <path
                            fill="#fff"
                            transform="matrix(-1 0 0 1 16 0)"
                            d="M0 0h16v16H0z"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default Saving;
