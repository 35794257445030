import { useCallback } from 'react';
import { isEmpty, uniqBy } from 'lodash';

import {
    useAssignChallengeLabelsApi,
    useAssignLinesToChallengeApi,
    useAssignLinesToScenarioApi,
    useAssignPractisSetContentApi,
    useAssignPractisSetLabelsApi,
    useAssignScenarioLabelApi,
    useCreateChallengeApi,
    useCreateNewPractisSetApi,
    useCreateScenarioApi,
    useDeleteChallengeLabelsApi,
    useDeletePractisSetLabelsApi,
    useDeleteScenarioLabelApi,
    useGetPractisSetContentApi,
    useUpdateChallengeInfoApi,
    useUpdateChallengeStatusApi,
    useUpdatePractisSetApi,
    useUpdatePractisSetStatusApi,
    useUpdateScenarioApi,
    useUpdateScenarioStatusApi,
} from '../../../../api';
import { ChunkRequestActionInterface } from '../../../../services/ChunkRequestService/hooks/types';
import { LibraryEntityName } from '../../store/types';
import {
    CreatePractisSetInfo,
    CreateNewChallengeInfo,
    CreateNewScenarioInfo,
    NewPractisSetInfo,
    EditPractisSetInfo,
    EditScenarioInfo,
    EditChallengeInfo,
} from './types';
import {
    PractisSetContent,
    PractisSetStatuses,
    PractisSetStatusType,
} from '../../../../constants/interfaces/PractisSets';
import { ScriptLine } from '../../../../constants/interfaces/ScriptLine';
import { ScenarioLabel, ScenarioLine } from '../../../../api/scenarios/types';
import {
    ChallengeLabel,
    ChallengeLine,
} from '../../../../api/challenges/types';
import {
    NewPractisSetType,
    PractisSetLabel,
    UpdatePractisSetStatusType,
} from '../../../../api/practis-set/types';
import {
    ASSIGN_CONTENT_TO_PRACTIS_SET_ACTION,
    ASSIGN_LABELS_TO_CHALLENGE_ACTION,
    ASSIGN_LABELS_TO_PRACTIS_SET_ACTION,
    ASSIGN_LABELS_TO_SCENARIO_ACTION,
    ASSIGN_LINES_TO_CHALLENGE_ACTION,
    ASSIGN_LINES_TO_SCENARIO_ACTION,
    CREATE_CHALLENGE_ACTION,
    CREATE_PRACTIS_SET_ACTION,
    CREATE_SCENARIO_ACTION,
    DELETE_LABELS_FROM_CHALLENGE_ACTION,
    DELETE_LABELS_FROM_PRACTIS_SET_ACTION,
    DELETE_LABELS_FROM_SCENARIO_ACTION,
    DELETE_LINES_FROM_SCENARIO_ACTION,
    GET_PRACTIS_SET_CONTENT_ACTION,
    UPDATE_CHALLENGE_ACTION,
    UPDATE_CHALLENGE_STATUS_ACTION,
    UPDATE_PRACTIS_SET_ACTION,
    UPDATE_PRACTIS_SET_STATUS_ACTION,
    UPDATE_SCENARIO_ACTION,
    UPDATE_SCENARIO_STATUS_ACTION,
} from './constants';
import {
    ScenarioStatuses,
    Status as ScenarioStatusType,
} from '../../../../constants/interfaces/Scenario';
import {
    ChallengeStatuses,
    Status as ChallengeStatusType,
} from '../../../../constants/interfaces/Challenge';

/**
 * @dev Use this to assign labels to 'practisSet' | 'scenario' | 'challenge' entities.
 * @function useAssignLibraryEntityLabelsService
 * @returns { CallableFunction }
 */
export function useAssignLibraryEntityLabelsService() {
    const assignPractisSetLabels = useAssignPractisSetLabelsApi();
    const assignScenarioLabels = useAssignScenarioLabelApi();
    const assignChallengeLabels = useAssignChallengeLabelsApi();

    /**
     * @function callback
     * @param { { id: number, labels: number[] }[] } entityData
     * @param { LibraryEntityName } entityName
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            labels: number[],
            entityData: { id: number },
            entityName: LibraryEntityName
        ) => {
            const assignLabelToLibraryActionList = {
                practisSet: assignPractisSetLabels,
                scenario: assignScenarioLabels,
                challenge: assignChallengeLabels,
            };

            const entityId = entityData?.id;

            if (entityId) {
                const processedAssignedLabelIds = labels.map(labelId => ({
                    [`${entityName}Id`]: entityId,
                    labelId,
                }));

                if (!isEmpty(processedAssignedLabelIds)) {
                    const assignLabelFromLibraryAction =
                        assignLabelToLibraryActionList[entityName];

                    return await assignLabelFromLibraryAction?.(
                        processedAssignedLabelIds as any
                    );
                }
            }
        },
        [assignChallengeLabels, assignPractisSetLabels, assignScenarioLabels]
    );
}

/**
 * @dev Use this to update status of 'practisSet' | 'scenario' | 'challenge' entities.
 * @function useUpdateLibraryStatusService
 * @returns { CallableFunction }
 */
export const useUpdateLibraryStatusService = () => {
    const updatePractisSetStatus = useUpdatePractisSetStatusApi();
    const updateScenarioStatus = useUpdateScenarioStatusApi();
    const updateChallengeStatus = useUpdateChallengeStatusApi();

    /**
     * @param { {id: number} } entityData
     * @param { LibraryEntityName } entityName
     * @param { PractisSetStatusType } currentStatus
     */
    return useCallback(
        async (
            entityData: { id: number },
            entityName: LibraryEntityName,
            currentStatus: PractisSetStatusType
        ) => {
            const updateLibraryStatusActionList = {
                practisSet: updatePractisSetStatus,
                scenario: updateScenarioStatus,
                challenge: updateChallengeStatus,
            };

            const createdPractisSetId = entityData?.id;

            if (createdPractisSetId) {
                const updateLibraryStatusAction =
                    updateLibraryStatusActionList[entityName];

                return await updateLibraryStatusAction?.(
                    currentStatus?.toLowerCase() as UpdatePractisSetStatusType,
                    [createdPractisSetId]
                );
            }
        },
        [updatePractisSetStatus, updateScenarioStatus, updateChallengeStatus]
    );
};

/**
 * @description Generates action list to pass to create practisSet service.
 * @function useGenerateCreatePractisSetActionList
 * @returns { CallableFunction }
 */
export function useGenerateCreatePractisSetActionList() {
    const createNewPractisSet = useCreateNewPractisSetApi();
    const assignLibraryLabels = useAssignLibraryEntityLabelsService();
    const assignPractisSetContent = useAssignPractisSetContentApi();
    const updatePractisSetStatusService = useUpdateLibraryStatusService();

    /**
     * @function handleAssignPractisSetContent
     * @param { { id: number } } entityData
     * @returns { Promise<void> }
     */
    const handleAssignPractisSetContent = useCallback(
        async (
            practisSetContents: PractisSetContent[],
            entityData: {
                id: number;
            }
        ) => {
            const { id: practisSetId = 0 } = entityData || {};

            if (!isEmpty(practisSetContents)) {
                const practisSetContent = practisSetContents?.map(item => ({
                    contentType: item.type,
                    scenarioId: item.scenario?.id ?? null,
                    challengeId: item.challenge?.id ?? null,
                    minRepsCount:
                        item.type === 'CHALLENGE'
                            ? null
                            : item.minRepsCount ?? null,
                    position: item.position ?? 0,
                }));

                return await assignPractisSetContent(
                    practisSetId,
                    practisSetContent as any
                );
            }
        },
        [assignPractisSetContent]
    );

    /**
     * @function handleAssignLabelsToPractisSet
     * @param { number[] } labelIds
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLabelsToPractisSet = useCallback(
        (
            labelIds: number[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLabelsToEntityServiceOptions = {
                parameters: {
                    labels: uniqBy(labelIds, id => id),
                    entityId: [],
                    entityName: 'practisSet',
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityId',
            };

            childActionList.push({
                actionName: ASSIGN_LABELS_TO_PRACTIS_SET_ACTION,
                actionFunction: assignLibraryLabels,
                actionFunctionOptions:
                    assignLabelsToEntityServiceOptions as any,
            });
        },
        [assignLibraryLabels]
    );

    /**
     * @function handleAssignContentToPractisSet
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignContentToPractisSet = useCallback(
        (
            practisSetContents: PractisSetContent[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignPractisSetContentOptions = {
                parameters: {
                    practisSetContents,
                    entityData: [],
                },
                fieldName: 'practisSetContents',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: ASSIGN_CONTENT_TO_PRACTIS_SET_ACTION,
                actionFunction: handleAssignPractisSetContent,
                actionFunctionOptions: assignPractisSetContentOptions as any,
                itemPerChunk: 10000
            });
        },
        [handleAssignPractisSetContent]
    );

    /**
     * @function handleUpdatePractisSetStatus
     * @param { PractisSetStatusType } currentStatus
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleUpdatePractisSetStatus = useCallback(
        (
            currentStatus: PractisSetStatusType,
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updatePractisSetStatusServiceOptions = {
                parameters: {
                    entityData: [],
                    entityName: 'practisSet',
                    currentStatus,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: UPDATE_PRACTIS_SET_STATUS_ACTION,
                actionFunction: updatePractisSetStatusService,
                actionFunctionOptions:
                    updatePractisSetStatusServiceOptions as any,
            });
        },
        [updatePractisSetStatusService]
    );

    /**
     * @param { CreatePractisSetInfo } newPractisSetInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newPractisSetInfo: CreatePractisSetInfo) => {
            const {
                name,
                description,
                pacingId,
                labelIds,
                status,
                practisSetContents,
            } = newPractisSetInfo;

            let actionList: ChunkRequestActionInterface<any> = {
                actionName: CREATE_PRACTIS_SET_ACTION,
                actionFunction: createNewPractisSet,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        practisSet: { name, description, pacingId },
                        itemPerChunk: 1,
                    },
                    fieldName: 'itemPerChunk',
                },
            };

            const childActionList: ChunkRequestActionInterface<any>[] = [];

            if (!isEmpty(labelIds)) {
                handleAssignLabelsToPractisSet(labelIds, childActionList);
            }

            if (!isEmpty(practisSetContents)) {
                handleAssignContentToPractisSet(
                    practisSetContents,
                    childActionList
                );
            }

            if (status && status !== PractisSetStatuses.DRAFT) {
                handleUpdatePractisSetStatus(status, childActionList);
            }

            actionList = { ...actionList, ...{ childActionList } };

            return actionList;
        },
        [
            createNewPractisSet,
            handleAssignContentToPractisSet,
            handleAssignLabelsToPractisSet,
            handleUpdatePractisSetStatus,
        ]
    );
}

/**
 * @description Generates action list to pass to edit practisSet service.
 * @function useGenerateEditPractisSetActionList
 * @returns { CallableFunction }
 */
export function useGenerateEditPractisSetActionList() {
    const updatePractisSetInfo = useUpdatePractisSetApi();
    const assignPractisSetContent = useAssignPractisSetContentApi();
    const assignLabelsToPractisSet = useAssignPractisSetLabelsApi();
    const deleteLabelsFromPractisSet = useDeletePractisSetLabelsApi();
    const updatePractisSetStatusService = useUpdatePractisSetStatusApi();

    /**
     * @function handleUpdatePractisSetInfo
     * @param { number } practisSetId
     * @param { NewPractisSetType } practisSetInfo
     * @param { ChunkRequestActionInterface<any>[] } actionList
     */
    const handleUpdatePractisSetInfo = useCallback(
        async (
            practisSetId: number,
            practisSetInfo: NewPractisSetType,
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updatePractisSetInfoActionOptions = {
                parameters: {
                    practisSetId,
                    data: practisSetInfo,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
            };

            actionList.push({
                actionName: UPDATE_PRACTIS_SET_ACTION,
                actionFunction: updatePractisSetInfo,
                actionFunctionOptions: updatePractisSetInfoActionOptions,
            });
        },
        [updatePractisSetInfo]
    );

    /**
     * @function handleAssignPractisSetContent
     * @param { number } practisSetId
     * @param { PractisSetContent[] } practisSetContents
     * @param { ChunkRequestActionInterface<any>[] } actionList
     */
    const handleAssignPractisSetContent = useCallback(
        async (
            practisSetId: number,
            practisSetContents: PractisSetContent[],
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            if (!isEmpty(practisSetContents)) {
                const practisSetContentData = practisSetContents?.map(item => ({
                    contentType: item.type,
                    scenarioId: item.scenario?.id ?? null,
                    challengeId: item.challenge?.id ?? null,
                    minRepsCount:
                        item.type === 'CHALLENGE'
                            ? null
                            : item.minRepsCount ?? null,
                    position: item.position ?? 0,
                }));

                if (!isEmpty(practisSetContentData)) {
                    actionList.push({
                        actionName: ASSIGN_CONTENT_TO_PRACTIS_SET_ACTION,
                        actionFunction: assignPractisSetContent,
                        actionFunctionOptions: {
                            parameters: {
                                practisSetId,
                                practisSetContentData,
                            },
                            fieldName: 'practisSetContentData',
                        },
                        itemPerChunk: 10000
                    });
                }
            }
        },
        [assignPractisSetContent]
    );

    /**
     * @function handleUpdatePractisSetLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleUpdatePractisSetLabels = useCallback(
        (
            addedLabelIds: number[],
            deletedLabelIds: number[],
            practisSetId: number,
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            if (!isEmpty(addedLabelIds)) {
                const addedLabels = addedLabelIds.map(
                    labelId =>
                        ({
                            labelId,
                            practisSetId,
                        } as PractisSetLabel)
                );

                const assignLabelsToPractisSetServiceOptions = {
                    parameters: {
                        addedPractisSetLabels: addedLabels,
                    },
                    fieldName: 'addedPractisSetLabels',
                };

                actionList.push({
                    actionName: ASSIGN_LABELS_TO_PRACTIS_SET_ACTION,
                    actionFunction: assignLabelsToPractisSet,
                    actionFunctionOptions:
                        assignLabelsToPractisSetServiceOptions as any,
                });
            }

            if (!isEmpty(deletedLabelIds)) {
                const deletedLabels = deletedLabelIds.map(
                    labelId =>
                        ({
                            labelId,
                            practisSetId,
                        } as PractisSetLabel)
                );

                const deleteLabelsFromPractisSetServiceOptions = {
                    parameters: {
                        deletedPractisSetLabels: deletedLabels,
                    },
                    fieldName: 'deletedPractisSetLabels',
                };

                actionList.push({
                    actionName: DELETE_LABELS_FROM_PRACTIS_SET_ACTION,
                    actionFunction: deleteLabelsFromPractisSet,
                    actionFunctionOptions:
                        deleteLabelsFromPractisSetServiceOptions as any,
                });
            }
        },
        [assignLabelsToPractisSet, deleteLabelsFromPractisSet]
    );

    /**
     * @function handleUpdatePractisSetStatus
     * @param { number } practisSetId
     * @param { string } status
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleUpdatePractisSetStatus = useCallback(
        (
            practisSetId: number,
            status: string,
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updatePractisSetStatusServiceOptions = {
                parameters: {
                    status,
                    practisSetIds: [practisSetId],
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
            };

            actionList.push({
                actionName: UPDATE_PRACTIS_SET_STATUS_ACTION,
                actionFunction: updatePractisSetStatusService,
                actionFunctionOptions:
                    updatePractisSetStatusServiceOptions as any,
            });
        },
        [updatePractisSetStatusService]
    );

    /**
     * @param { EditPractisSetInfo } newPractisSetInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newPractisSetInfo: EditPractisSetInfo) => {
            const {
                practisSetId,
                name,
                description,
                pacingId,
                addedLabelIds,
                deletedLabelIds,
                practisSetContents,
                status,
            } = newPractisSetInfo;

            let actionList: ChunkRequestActionInterface<any>[] = [];

            if (!isEmpty(name) || !isEmpty(description) || !isEmpty(pacingId)) {
                handleUpdatePractisSetInfo(
                    practisSetId,
                    {
                        name,
                        description,
                        pacingId,
                    },
                    actionList
                );
            }

            if (!isEmpty(addedLabelIds) || !isEmpty(deletedLabelIds)) {
                handleUpdatePractisSetLabels(
                    addedLabelIds,
                    deletedLabelIds,
                    practisSetId,
                    actionList
                );
            }

            if (!isEmpty(practisSetContents)) {
                handleAssignPractisSetContent(
                    practisSetId,
                    practisSetContents,
                    actionList
                );
            }

            if (status && status !== PractisSetStatuses.DRAFT) {
                handleUpdatePractisSetStatus(
                    practisSetId,
                    status!.toLowerCase(),
                    actionList
                );
            }

            return actionList;
        },
        [
            handleAssignPractisSetContent,
            handleUpdatePractisSetInfo,
            handleUpdatePractisSetLabels,
            handleUpdatePractisSetStatus,
        ]
    );
}

/**
 * @description Generates action list to pass to duplicate practisSet service.
 * @function useGenerateDuplicatePractisSetActionList
 * @returns { CallableFunction }
 */
export function useGenerateDuplicatePractisSetActionList() {
    const createNewPractisSet = useCreateNewPractisSetApi();
    const assignLibraryLabels = useAssignLibraryEntityLabelsService();
    const getPractisSetContent = useGetPractisSetContentApi();
    const assignPractisSetContent = useAssignPractisSetContentApi();

    /**
     * @function handleGetPractisSetContent
     * @param { {id: number} } entityData
     * @param { number } currentPractisSetId
     * @returns { Promise<unknown> }
     */
    const handleGetPractisSetContent = useCallback(
        async (entityData: { id: number }, currentPractisSetId: number) => {
            const createdPractisSetId = entityData?.id;

            let newPractisSetContentData = {} as {
                content: PractisSetContent[];
                practisSetId: number;
            };

            if (createdPractisSetId) {
                await getPractisSetContent(currentPractisSetId).then(
                    (response: PractisSetContent[]) => {
                        newPractisSetContentData = {
                            content: response,
                            practisSetId: createdPractisSetId,
                        };
                    }
                );
            }

            return new Promise(resolve => {
                setTimeout(() => {
                    resolve([newPractisSetContentData]);
                }, 0);
            });
        },
        [getPractisSetContent]
    );

    /**
     * @function handleAssignPractisSetContent
     * @param { { content: PractisSetContent[]; practisSetId: number }[] } entityData
     * @returns { Promise<void> }
     */
    const handleAssignPractisSetContent = useCallback(
        async (
            entityData: {
                content: PractisSetContent[];
                practisSetId: number;
            }[]
        ) => {
            const { content = [], practisSetId = 0 } = entityData?.[0] || {};

            if (!isEmpty(content)) {
                const practisSetContent = content?.map(item => ({
                    contentType: item.type,
                    scenarioId: item.scenario?.id ?? null,
                    challengeId: item.challenge?.id ?? null,
                    minRepsCount:
                        item.type === 'CHALLENGE'
                            ? null
                            : item.minRepsCount ?? null,
                    position: item.position ?? null,
                }));

                return assignPractisSetContent(
                    practisSetId,
                    practisSetContent as any
                );
            }
        },
        [assignPractisSetContent]
    );

    /**
     * @function handleAssignLabelsToPractisSet
     * @param { number[] } labelIds
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLabelsToPractisSet = useCallback(
        (
            labelIds: number[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLabelsToEntityServiceOptions = {
                parameters: {
                    labels: uniqBy(labelIds, id => id),
                    entityId: [],
                    entityName: 'practisSet',
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityId',
            };

            childActionList.push({
                actionName: ASSIGN_LABELS_TO_PRACTIS_SET_ACTION,
                actionFunction: assignLibraryLabels,
                actionFunctionOptions:
                    assignLabelsToEntityServiceOptions as any,
            });
        },
        [assignLibraryLabels]
    );

    /**
     * @function handleAssignContentToPractisSet
     * @param { number } currentPractisSetId
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignContentToPractisSet = useCallback(
        (
            currentPractisSetId: number,
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const getPractisSetContentOptions = {
                parameters: {
                    entityData: [],
                    currentPractisSetId,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
                secondaryFieldName: 'entityData',
            };

            const assignPractisSetContentOptions = {
                parameters: {
                    entityData: [],
                },
                fieldName: 'entityData',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: GET_PRACTIS_SET_CONTENT_ACTION,
                actionFunction: handleGetPractisSetContent,
                actionFunctionOptions: getPractisSetContentOptions as any,
                itemPerChunk: 1,
                childActionList: [
                    {
                        actionName: ASSIGN_CONTENT_TO_PRACTIS_SET_ACTION,
                        actionFunction: handleAssignPractisSetContent,
                        actionFunctionOptions: assignPractisSetContentOptions,
                        itemPerChunk: 10000
                    },
                ],
            });
        },
        [handleAssignPractisSetContent, handleGetPractisSetContent]
    );

    /**
     * @param { NewPractisSetInfo } newPractisSetInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newPractisSetInfo: NewPractisSetInfo) => {
            const {
                currentPractisSetId,
                name,
                description,
                pacingId,
                labelIds,
                scenarioCount,
                challengeCount,
            } = newPractisSetInfo;

            let actionList: ChunkRequestActionInterface<any> = {
                actionName: CREATE_PRACTIS_SET_ACTION,
                actionFunction: createNewPractisSet,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        practisSet: { name, description, pacingId },
                        itemPerChunk: 1,
                    },
                    fieldName: 'itemPerChunk',
                },
            };

            const childActionList: ChunkRequestActionInterface<any>[] = [];

            if (!isEmpty(labelIds)) {
                handleAssignLabelsToPractisSet(labelIds, childActionList);
            }

            if (scenarioCount > 0 || challengeCount > 0) {
                handleAssignContentToPractisSet(
                    currentPractisSetId,
                    childActionList
                );
            }

            actionList = { ...actionList, ...{ childActionList } };

            return actionList;
        },
        [
            createNewPractisSet,
            handleAssignContentToPractisSet,
            handleAssignLabelsToPractisSet,
        ]
    );
}

/**
 * @description Generates action list to pass to create/duplicate scenario service.
 * @function useGenerateCreateDuplicateScenarioActionList
 * @returns { CallableFunction }
 */
export function useGenerateCreateDuplicateScenarioActionList() {
    const createNewScenario = useCreateScenarioApi();
    const assignLibraryLabels = useAssignLibraryEntityLabelsService();
    const assignLinesToScenario = useAssignLinesToScenarioApi();
    const updateScenarioStatusService = useUpdateLibraryStatusService();

    /**
     * @function handleUpdateScenarioStatus
     * @param { ScenarioStatusType } currentStatus
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleUpdateScenarioStatus = useCallback(
        (
            currentStatus: ScenarioStatusType,
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updateScenarioStatusServiceOptions = {
                parameters: {
                    entityData: [],
                    entityName: 'scenario',
                    currentStatus,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: UPDATE_SCENARIO_STATUS_ACTION,
                actionFunction: updateScenarioStatusService,
                actionFunctionOptions:
                    updateScenarioStatusServiceOptions as any,
            });
        },
        [updateScenarioStatusService]
    );

    /**
     * @function handleAssignLabelsToScenario
     * @param { number[] } labelIds
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLabelsToScenario = useCallback(
        (
            labelIds: number[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLabelsToEntityServiceOptions = {
                parameters: {
                    labels: uniqBy(labelIds, id => id),
                    entityId: [],
                    entityName: 'scenario',
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityId',
            };

            childActionList.push({
                actionName: ASSIGN_LINES_TO_SCENARIO_ACTION,
                actionFunction: assignLibraryLabels,
                actionFunctionOptions:
                    assignLabelsToEntityServiceOptions as any,
            });
        },
        [assignLibraryLabels]
    );

    /**
     * @function handleAssignLinesToScenarioService
     * @param { { id: number} } entityData
     * @param { ScriptLine[] } currentLines
     * @returns { Promise<void> }
     */
    const handleAssignLinesToScenarioService = useCallback(
        async (entityData: { id: number }, currentLines: ScriptLine[]) => {
            const createdScenarioId = entityData?.id;

            if (createdScenarioId) {
                const createdScenarioLines = currentLines?.map(line => {
                    const {
                        audioId = 0,
                        duration = 0,
                        position = null,
                        text = '',
                        speaker = '',
                        keywords = [],
                    } = line || {};

                    return {
                        audioId,
                        duration,
                        position,
                        text,
                        speaker,
                        keywords,
                    } as ScenarioLine;
                });

                await assignLinesToScenario(
                    createdScenarioId,
                    createdScenarioLines
                );
            }
        },
        [assignLinesToScenario]
    );

    /**
     * @function handleAssignLinesToScenario
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLinesToScenario = useCallback(
        (
            currentLines: ScriptLine[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLinesToScenarioOptions = {
                parameters: {
                    entityData: [],
                    currentLines,
                },
                fieldName: 'currentLines',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: ASSIGN_LINES_TO_SCENARIO_ACTION,
                actionFunction: handleAssignLinesToScenarioService,
                actionFunctionOptions: assignLinesToScenarioOptions as any,
                itemPerChunk: 10000
            });
        },
        [handleAssignLinesToScenarioService]
    );

    /**
     * @param { CreateNewScenarioInfo } newScenarioInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newScenarioInfo: CreateNewScenarioInfo) => {
            const { title, description, labelIds, lines, status } =
                newScenarioInfo;

            let actionList: ChunkRequestActionInterface<any> = {
                actionName: CREATE_SCENARIO_ACTION,
                actionFunction: createNewScenario,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        scenarioInfo: { title, description },
                        itemPerChunk: 1,
                    },
                    fieldName: 'itemPerChunk',
                },
            };

            const childActionList: ChunkRequestActionInterface<any>[] = [];

            if (!isEmpty(labelIds)) {
                handleAssignLabelsToScenario(labelIds, childActionList);
            }

            if (!isEmpty(lines)) {
                handleAssignLinesToScenario(lines, childActionList);
            }

            if (status && status !== ScenarioStatuses.DRAFT) {
                handleUpdateScenarioStatus(status, childActionList);
            }

            actionList = { ...actionList, ...{ childActionList } };

            return actionList;
        },
        [
            createNewScenario,
            handleAssignLabelsToScenario,
            handleAssignLinesToScenario,
            handleUpdateScenarioStatus,
        ]
    );
}

/**
 * @description Generates action list to pass to edit scenario service.
 * @function useGenerateEditScenarioActionList
 * @returns { CallableFunction }
 */
export function useGenerateEditScenarioActionList() {
    const updateScenarioInfo = useUpdateScenarioApi();
    const assignLabelsToScenario = useAssignScenarioLabelApi();
    const deleteLabelsFromScenario = useDeleteScenarioLabelApi();
    const assignLinesToScenario = useAssignLinesToScenarioApi();
    const updateScenarioStatusService = useUpdateScenarioStatusApi();

    /**
     * @function handleUpdateScenarioStatus
     * @param { number } scenarioId
     * @param { ScenarioStatusType } currentStatus
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleUpdateScenarioStatus = useCallback(
        (
            scenarioId: number,
            currentStatus: ScenarioStatusType,
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updateScenarioStatusServiceOptions = {
                parameters: {
                    currentStatus: currentStatus?.toLowerCase(),
                    scenarioIds: [scenarioId],
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
            };

            actionList.push({
                actionName: UPDATE_SCENARIO_STATUS_ACTION,
                actionFunction: updateScenarioStatusService,
                actionFunctionOptions:
                    updateScenarioStatusServiceOptions as any,
            });
        },
        [updateScenarioStatusService]
    );

    /**
     * @function handleAssignLabelsToScenario
     * @param { number } scenarioId
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleAssignLabelsToScenario = useCallback(
        (
            scenarioId: number,
            addedLabelIds: number[],
            deletedLabelIds: number[],
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            if (!isEmpty(addedLabelIds)) {
                const addedLabels = addedLabelIds.map(
                    labelId =>
                        ({
                            scenarioId,
                            labelId,
                        } as ScenarioLabel)
                );

                const assignLabelsToScenarioServiceOptions = {
                    parameters: {
                        labels: addedLabels,
                    },
                    fieldName: 'labels',
                };

                actionList.push({
                    actionName: ASSIGN_LABELS_TO_SCENARIO_ACTION,
                    actionFunction: assignLabelsToScenario,
                    actionFunctionOptions:
                        assignLabelsToScenarioServiceOptions as any,
                });
            }

            if (!isEmpty(deletedLabelIds)) {
                const deletedLabels = deletedLabelIds.map(
                    labelId =>
                        ({
                            scenarioId,
                            labelId,
                        } as ScenarioLabel)
                );

                const deleteLabelsFromScenarioServiceOptions = {
                    parameters: {
                        labels: deletedLabels,
                    },
                    fieldName: 'labels',
                };

                actionList.push({
                    actionName: DELETE_LABELS_FROM_SCENARIO_ACTION,
                    actionFunction: deleteLabelsFromScenario,
                    actionFunctionOptions:
                        deleteLabelsFromScenarioServiceOptions as any,
                });
            }
        },
        [assignLabelsToScenario, deleteLabelsFromScenario]
    );

    /**
     * @function handleAssignLinesToScenarioService
     * @param { number } scenarioId
     * @param { ScriptLine[] } currentLines
     * @returns { Promise<void> }
     */
    const handleAssignLinesToScenarioService = useCallback(
        async (scenarioId: number, currentLines: ScriptLine[]) => {
            if (scenarioId) {
                const createdScenarioLines = currentLines?.map(line => {
                    const {
                        audioId = 0,
                        duration = 0,
                        position = null,
                        text = '',
                        speaker = '',
                        keywords = [],
                    } = line || {};

                    return {
                        audioId,
                        duration,
                        position,
                        text,
                        speaker,
                        keywords,
                    } as ScenarioLine;
                });

                await assignLinesToScenario(scenarioId, createdScenarioLines);
            }
        },
        [assignLinesToScenario]
    );

    /**
     * @function handleAssignLinesToScenario
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleAssignLinesToScenario = useCallback(
        (
            scenarioId: number,
            currentLines: ScriptLine[],
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLinesToScenarioOptions = {
                parameters: {
                    scenarioId,
                    currentLines,
                },
                fieldName: 'currentLines',
            };

            actionList.push({
                actionName: ASSIGN_LINES_TO_SCENARIO_ACTION,
                actionFunction: handleAssignLinesToScenarioService,
                actionFunctionOptions: assignLinesToScenarioOptions as any,
                itemPerChunk: 10000,
            });
        },
        [handleAssignLinesToScenarioService]
    );

    /**
     * @function handleDeleteScenarioLines
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleDeleteScenarioLines = useCallback(
        (
            scenarioId: number,
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLinesToScenarioOptions = {
                parameters: {
                    scenarioId,
                    currentLines: [],
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
            };

            actionList.push({
                actionName: DELETE_LINES_FROM_SCENARIO_ACTION,
                actionFunction: handleAssignLinesToScenarioService,
                actionFunctionOptions: assignLinesToScenarioOptions as any,
            });
        },
        [handleAssignLinesToScenarioService]
    );

    /**
     * @param { EditScenarioInfo } newScenarioInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newScenarioInfo: EditScenarioInfo) => {
            const {
                scenarioId,
                title,
                description,
                addedLabelIds,
                deletedLabelIds,
                lines,
                status,
            } = newScenarioInfo;

            let actionList: ChunkRequestActionInterface<any>[] = [
                {
                    actionName: UPDATE_SCENARIO_ACTION,
                    actionFunction: updateScenarioInfo,
                    itemPerChunk: 1,
                    actionFunctionOptions: {
                        parameters: {
                            scenarioId,
                            scenarioInfo: { title, description },
                            itemPerChunk: 1,
                        },
                        fieldName: 'itemPerChunk',
                    },
                },
            ];

            if (!isEmpty(addedLabelIds) || !isEmpty(deletedLabelIds)) {
                handleAssignLabelsToScenario(
                    scenarioId,
                    addedLabelIds,
                    deletedLabelIds,
                    actionList
                );
            }

            if (!isEmpty(lines)) {
                handleAssignLinesToScenario(scenarioId, lines, actionList);
            } else if (status === ScenarioStatuses.DRAFT) {
                handleDeleteScenarioLines(scenarioId, actionList);
            }

            if (status && status !== ScenarioStatuses.DRAFT) {
                handleUpdateScenarioStatus(scenarioId, status, actionList);
            }

            return actionList;
        },
        [
            handleAssignLabelsToScenario,
            handleAssignLinesToScenario,
            handleDeleteScenarioLines,
            handleUpdateScenarioStatus,
            updateScenarioInfo,
        ]
    );
}

/**
 * @description Generates action list to pass to create/duplicate challenge service.
 * @function useGenerateCreateDuplicateChallengeActionList
 * @returns { CallableFunction }
 */
export function useGenerateCreateDuplicateChallengeActionList() {
    const createNewChallenge = useCreateChallengeApi();
    const assignLibraryLabels = useAssignLibraryEntityLabelsService();
    const assignLinesToChallenge = useAssignLinesToChallengeApi();
    const updateChallengeStatusService = useUpdateLibraryStatusService();

    /**
     * @function handleUpdateChallengeStatus
     * @param { ChallengeStatusType } currentStatus
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleUpdateChallengeStatus = useCallback(
        (
            currentStatus: ChallengeStatusType,
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updateChallengeStatusServiceOptions = {
                parameters: {
                    entityData: [],
                    entityName: 'challenge',
                    currentStatus,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: UPDATE_CHALLENGE_STATUS_ACTION,
                actionFunction: updateChallengeStatusService,
                actionFunctionOptions:
                    updateChallengeStatusServiceOptions as any,
            });
        },
        [updateChallengeStatusService]
    );

    /**
     * @function handleAssignLabelsToChallenge
     * @param { number[] } labelIds
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLabelsToChallenge = useCallback(
        (
            labelIds: number[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLabelsToEntityServiceOptions = {
                parameters: {
                    labels: uniqBy(labelIds, id => id),
                    entityId: [],
                    entityName: 'challenge',
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityId',
            };

            childActionList.push({
                actionName: ASSIGN_LABELS_TO_CHALLENGE_ACTION,
                actionFunction: assignLibraryLabels,
                actionFunctionOptions:
                    assignLabelsToEntityServiceOptions as any,
            });
        },
        [assignLibraryLabels]
    );

    /**
     * @function handleAssignLinesToChallengeService
     * @param { { id: number} } entityData
     * @param { ScriptLine[] } currentLines
     * @returns { Promise<void> }
     */
    const handleAssignLinesToChallengeService = useCallback(
        async (entityData: { id: number }, currentLines: ScriptLine[]) => {
            const createdChallengeId = entityData?.id;

            if (createdChallengeId) {
                const createdChallengeLines = currentLines?.map(line => {
                    const {
                        audioId = 0,
                        duration = 0,
                        position = null,
                        text = '',
                        speaker = '',
                        keywords = [],
                    } = line || {};

                    return {
                        audioId,
                        duration,
                        position,
                        text,
                        speaker,
                        keywords,
                    } as ChallengeLine;
                });

                await assignLinesToChallenge(
                    createdChallengeId,
                    createdChallengeLines
                );
            }
        },
        [assignLinesToChallenge]
    );

    /**
     * @function handleAssignLinesToChallenge
     * @param { ChunkRequestActionInterface<any>[] } childActionList
     * @returns { void }
     */
    const handleAssignLinesToChallenge = useCallback(
        (
            currentLines: ScriptLine[],
            childActionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLinesToChallengeOptions = {
                parameters: {
                    entityData: [],
                    currentLines,
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
                secondaryFieldName: 'entityData',
            };

            childActionList.push({
                actionName: ASSIGN_LINES_TO_CHALLENGE_ACTION,
                actionFunction: handleAssignLinesToChallengeService,
                actionFunctionOptions: assignLinesToChallengeOptions as any,
                itemPerChunk: 10000,
            });
        },
        [handleAssignLinesToChallengeService]
    );

    /**
     * @param { CreateNewChallengeInfo } newChallengeInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newChallengeInfo: CreateNewChallengeInfo) => {
            const {
                title,
                description,
                sourceScenarioId,
                lines,
                labelIds,
                status,
                tryLimit,
            } = newChallengeInfo;

            let actionList: ChunkRequestActionInterface<any> = {
                actionName: CREATE_CHALLENGE_ACTION,
                actionFunction: createNewChallenge,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        challengeInfo: { title, description, sourceScenarioId, tryLimit },
                        itemPerChunk: 1,
                    },
                    fieldName: 'itemPerChunk',
                },
            };

            const childActionList: ChunkRequestActionInterface<any>[] = [];

            if (!isEmpty(labelIds)) {
                handleAssignLabelsToChallenge(labelIds, childActionList);
            }

            if (!isEmpty(lines)) {
                handleAssignLinesToChallenge(lines, childActionList);
            }

            if (status && status !== ChallengeStatuses.DRAFT) {
                handleUpdateChallengeStatus(status, childActionList);
            }

            actionList = { ...actionList, ...{ childActionList } };

            return actionList;
        },
        [
            createNewChallenge,
            handleAssignLabelsToChallenge,
            handleAssignLinesToChallenge,
            handleUpdateChallengeStatus,
        ]
    );
}

/**
 * @description Generates action list to pass to edit challenge service.
 * @function useGenerateEditChallengeActionList
 * @returns { CallableFunction }
 */
export function useGenerateEditChallengeActionList() {
    const updateChallengeInfo = useUpdateChallengeInfoApi();
    const assignLabelsToChallenge = useAssignChallengeLabelsApi();
    const deleteLabelsFromChallenge = useDeleteChallengeLabelsApi();
    const assignLinesToChallenge = useAssignLinesToChallengeApi();
    const updateChallengeStatusService = useUpdateChallengeStatusApi();

    /**
     * @function handleUpdateChallengeStatus
     * @param { number } challengeId
     * @param { ScenarioStatusType } currentStatus
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleUpdateChallengeStatus = useCallback(
        (
            challengeId: number,
            currentStatus: ChallengeStatusType,
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const updateChallengeStatusServiceOptions = {
                parameters: {
                    currentStatus: currentStatus?.toLowerCase(),
                    challengeIds: [challengeId],
                    itemPerChunk: 1,
                },
                fieldName: 'itemPerChunk',
            };

            actionList.push({
                actionName: UPDATE_CHALLENGE_STATUS_ACTION,
                actionFunction: updateChallengeStatusService,
                actionFunctionOptions:
                    updateChallengeStatusServiceOptions as any,
            });
        },
        [updateChallengeStatusService]
    );

    /**
     * @function handleAssignLabelsToChallenge
     * @param { number } challengeId
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleAssignLabelsToChallenge = useCallback(
        (
            challengeId: number,
            addedLabelIds: number[],
            deletedLabelIds: number[],
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            if (!isEmpty(addedLabelIds)) {
                const addedLabels = addedLabelIds.map(
                    labelId =>
                        ({
                            challengeId,
                            labelId,
                        } as ChallengeLabel)
                );

                const assignLabelsToChallengeServiceOptions = {
                    parameters: {
                        labels: addedLabels,
                    },
                    fieldName: 'labels',
                };

                actionList.push({
                    actionName: ASSIGN_LABELS_TO_CHALLENGE_ACTION,
                    actionFunction: assignLabelsToChallenge,
                    actionFunctionOptions:
                        assignLabelsToChallengeServiceOptions as any,
                });
            }

            if (!isEmpty(deletedLabelIds)) {
                const deletedLabels = deletedLabelIds.map(
                    labelId =>
                        ({
                            challengeId,
                            labelId,
                        } as ChallengeLabel)
                );

                const deleteLabelsFromChallengeServiceOptions = {
                    parameters: {
                        labels: deletedLabels,
                    },
                    fieldName: 'labels',
                };

                actionList.push({
                    actionName: DELETE_LABELS_FROM_CHALLENGE_ACTION,
                    actionFunction: deleteLabelsFromChallenge,
                    actionFunctionOptions:
                        deleteLabelsFromChallengeServiceOptions as any,
                });
            }
        },
        [assignLabelsToChallenge, deleteLabelsFromChallenge]
    );

    /**
     * @function handleAssignLinesToChallengeService
     * @param { number } challengeId
     * @param { ScriptLine[] } currentLines
     * @returns { Promise<void> }
     */
    const handleAssignLinesToChallengeService = useCallback(
        async (challengeId: number, currentLines: ScriptLine[]) => {
            if (challengeId) {
                const createdChallengeLines = currentLines?.map(line => {
                    const {
                        audioId = 0,
                        duration = 0,
                        position = null,
                        text = '',
                        speaker = '',
                        keywords = [],
                    } = line || {};

                    return {
                        audioId,
                        duration,
                        position,
                        text,
                        speaker,
                        keywords,
                    } as ScenarioLine;
                });

                await assignLinesToChallenge(
                    challengeId,
                    createdChallengeLines
                );
            }
        },
        [assignLinesToChallenge]
    );

    /**
     * @function handleAssignLinesToChallenge
     * @param { ChunkRequestActionInterface<any>[] } actionList
     * @returns { void }
     */
    const handleAssignLinesToChallenge = useCallback(
        (
            challengeId: number,
            currentLines: ScriptLine[],
            actionList: ChunkRequestActionInterface<any>[]
        ) => {
            const assignLinesToChallengeOptions = {
                parameters: {
                    challengeId,
                    currentLines,
                },
                fieldName: 'currentLines',
            };

            actionList.push({
                actionName: ASSIGN_LINES_TO_CHALLENGE_ACTION,
                actionFunction: handleAssignLinesToChallengeService,
                actionFunctionOptions: assignLinesToChallengeOptions as any,
                itemPerChunk: 10000,
            });
        },
        [handleAssignLinesToChallengeService]
    );

    /**
     * @param { EditChallengeInfo } newChallengeInfo
     * @returns { ChunkRequestActionInterface<any> }
     */
    return useCallback(
        (newChallengeInfo: EditChallengeInfo) => {
            const {
                challengeId,
                title,
                description,
                sourceScenarioId,
                addedLabelIds,
                deletedLabelIds,
                lines,
                status,
                tryLimit,
            } = newChallengeInfo;

            let actionList: ChunkRequestActionInterface<any>[] = [
                {
                    actionName: UPDATE_CHALLENGE_ACTION,
                    actionFunction: updateChallengeInfo,
                    itemPerChunk: 1,
                    actionFunctionOptions: {
                        parameters: {
                            challengeId,
                            challengeInfo: {
                                title,
                                description,
                                sourceScenarioId,
                                tryLimit,
                            },
                            itemPerChunk: 1,
                        },
                        fieldName: 'itemPerChunk',
                    },
                },
            ];

            if (!isEmpty(addedLabelIds) || !isEmpty(deletedLabelIds)) {
                handleAssignLabelsToChallenge(
                    challengeId,
                    addedLabelIds,
                    deletedLabelIds,
                    actionList
                );
            }

            if (!isEmpty(lines)) {
                handleAssignLinesToChallenge(challengeId, lines, actionList);
            }

            if (status && status !== ChallengeStatuses.DRAFT) {
                handleUpdateChallengeStatus(challengeId, status, actionList);
            }

            return actionList;
        },
        [
            handleAssignLabelsToChallenge,
            handleAssignLinesToChallenge,
            handleUpdateChallengeStatus,
            updateChallengeInfo,
        ]
    );

}