/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { flatten, isEmpty } from 'lodash';

import {
    showModalDialog,
    useHideModalDialog,
} from '../../ui/components/ModalDialogs/store/actions';
import { useChunkRequestsService } from '../ChunkRequestService/hooks';
import { ChunkRequestActionInterface } from '../ChunkRequestService/hooks/types';
import {
    NudgeUsersChunkRequestParameters,
    NudgeUserSenderData,
    SearchUsersFunctionParams,
    UpdateLabelEntityType,
    updateLabelFetchEntityActionFunctionParametersType,
    UpdateLabelMultipleEntityType,
    UpdateLabelsParametersType,
} from './types';
import { GeneralBulkActionModalInterface } from '../../ui/components/ModalDialogs/types';
import { MIN_ITEMS_TO_SHOW_MODAL, NUDGE_USERS_ACTION, UPDATE_LABEL_ACTION } from './constants';
import { ErrorResult } from '../../constants/interfaces/ErrorResult';
import { useShowMessage } from '../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../constants/interfaces/filters';
import { NudgeDateType } from '../../api/alert/types';
import { useHandleSearchUsersAndNudge } from './helpers';
import { ITEM_PER_CHUNK_SIZE } from '../ChunkRequestService/hooks/constants';

/**
 * @function useUpdateLabelsBulkActionService
 * @param { Function } addLabelActionFunction
 * @param { Function } deleteLabelActionFunction
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @param { Function } onStartCallback
 * @returns { void }
 */
export function useUpdateLabelsBulkActionService(
    addLabelActionFunction: Function,
    deleteLabelActionFunction: Function,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
    onStartCallback?: () => void
) {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const hideModalDialog = useHideModalDialog();

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const entityRef = useRef<UpdateLabelEntityType | null>(null);
    const { entityName = '', entityId = 0 } = entityRef.current! || {};

    const assignedLabelIdsRef = useRef<number[]>([]);
    const deletedLabelIdsRef = useRef<number[]>([]);

    const assignedLabelIds = assignedLabelIdsRef?.current;
    const deletedLabelIds = deletedLabelIdsRef.current;

    const shouldShowModals =
        assignedLabelIds.length + deletedLabelIds.length >=
        MIN_ITEMS_TO_SHOW_MODAL;

    const assignedLabels = assignedLabelIds?.map(labelId => ({
        [`${entityName}Id`]: entityId,
        labelId,
    }));

    const deletedLabels = deletedLabelIds?.map(labelId => ({
        [`${entityName}Id`]: entityId,
        labelId,
    }));

    const assignLabelsToEntityOptions = {
        parameters: {
            labels: assignedLabels,
        },
        fieldName: 'labels',
    };

    const deleteLabelsFromEntityOptions = {
        parameters: {
            labels: deletedLabels,
        },
        fieldName: 'labels',
    };

    const actionList: ChunkRequestActionInterface<UpdateLabelsParametersType>[] =
        [];

    if (!isEmpty(assignedLabelIds)) {
        actionList.push({
            actionName: UPDATE_LABEL_ACTION,
            actionFunction: addLabelActionFunction,
            actionFunctionOptions: assignLabelsToEntityOptions,
        });
    }

    if (!isEmpty(deletedLabelIds)) {
        actionList.push({
            actionName: UPDATE_LABEL_ACTION,
            actionFunction: deleteLabelActionFunction,
            actionFunctionOptions: deleteLabelsFromEntityOptions,
        });
    }

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        if (shouldShowModals) {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: 'Processing Labels',
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );
        }

        setIsRunning(false);
        onErrorCallback?.();
    }, [dispatch, hideModalDialog, onErrorCallback, shouldShowModals]);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);
            }

            hideModalDialog();
            isRunning && setIsRunning(false);

            onSuccessCallback?.();
        });

        showMessage('Changes have been saved', 'success');
    }, [
        dispatch,
        hideModalDialog,
        isRunning,
        onSuccessCallback,
        shouldShowModals,
    ]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopBulkActionService
     * @returns { void }
     */
    const handleStopBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (!isEmpty(assignedLabelIds)) {
                setIsStopped(true);
            }

            isRunning && setIsRunning(false);
            hideModalDialog();

            onSuccessCallback?.();
        });
    }, [assignedLabelIds, hideModalDialog, isRunning, setIsStopped, dispatch]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle: 'Processing Labels',
                            onStopBulkActionService:
                                handleStopBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
            onStartCallback?.();
        });
    }, [
        dispatch,
        handleStopBulkActionService,
        hideModalDialog,
        setIsStopped,
        shouldShowModals,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(
        (
            entity: UpdateLabelEntityType,
            assignedLabelIds: number[],
            deletedLabelIds: number[]
        ) => {
            entityRef.current = entity;
            assignedLabelIdsRef.current = assignedLabelIds;
            deletedLabelIdsRef.current = deletedLabelIds;

            setIsRunning(true);
        },
        []
    );
}

/**
 * @dev Use this hook to assign labels to multiple users/practisSets/teams.
 * @function useUpdateMultipleEntityLabelsBulkActionService
 * @param { Function } addLabelActionFunction
 * @param { Function } deleteLabelActionFunction
 * @param { boolean } isSelectAll
 * @param { Function } fetchEntityActionFunction
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { void }
 */
export function useUpdateMultipleEntityLabelsBulkActionService(
    addLabelActionFunction: Function,
    deleteLabelActionFunction: Function,
    isSelectAll: boolean,
    fetchEntityAction: ChunkRequestActionInterface<updateLabelFetchEntityActionFunctionParametersType>,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const hideModalDialog = useHideModalDialog();

    const [isRunning, setIsRunning] = useState<boolean>(false);

    let actionList: ChunkRequestActionInterface<
        | UpdateLabelsParametersType
        | updateLabelFetchEntityActionFunctionParametersType
    >[] = [];

    const entityRef = useRef<UpdateLabelMultipleEntityType | null>(null);
    const { entityName = '', entityIds = [] } = entityRef.current! || {};
    const totalCompletedItems = useRef<number>(0);

    const assignedLabelIdsRef = useRef<number[]>([]);
    const deletedLabelIdsRef = useRef<number[]>([]);

    const assignedLabelIds = assignedLabelIdsRef?.current;
    const deletedLabelIds = deletedLabelIdsRef.current;

    const shouldShowModals =
        isSelectAll ||
        (!isSelectAll &&
            (assignedLabelIds.length + deletedLabelIds.length) *
                entityIds.length >=
                MIN_ITEMS_TO_SHOW_MODAL);

    const assignedLabels = entityIds.map(entityId => {
        return assignedLabelIds.map(labelId => ({
            [`${entityName}Id`]: entityId,
            labelId,
        }));
    });

    const deletedLabels = entityIds.map(entityId => {
        return deletedLabelIds.map(labelId => ({
            [`${entityName}Id`]: entityId,
            labelId,
        }));
    });

    const assignLabelsToEntityOptions = {
        parameters: {
            labels: flatten(assignedLabels),
        },
        fieldName: 'labels',
    };

    const deleteLabelsFromEntityOptions = {
        parameters: {
            labels: flatten(deletedLabels),
            entityIds: [],
        },
        fieldName: 'labels',
    };

    /**
     * @function assignLabelsToEntityService
     * @param { { id: number, labels: number[] }[] } entityData
     * @returns { Promise<void> }
     */
    const assignLabelsToEntityService = useCallback(
        async (
            labels: number[],
            entityData: { id: number; labels: number[] }[]
        ) => {
            const entityIds = entityData?.map(entity => entity.id);
            const entityLabelIds = entityData?.map(
                entity => entity.labels
            )?.[0];

            const filteredAssignedLabelIds = labels.filter(
                labelId => !entityLabelIds.includes(labelId)
            );

            if (!isEmpty(entityIds) && !isEmpty(filteredAssignedLabelIds)) {
                const processedAssignedLabelIds = entityIds.map(entityId => {
                    return filteredAssignedLabelIds.map(labelId => ({
                        [`${entityName}Id`]: entityId,
                        labelId,
                    }));
                });

                if (!isEmpty(processedAssignedLabelIds)) {
                    await addLabelActionFunction(
                        flatten(processedAssignedLabelIds)
                    ).then(
                        () => (totalCompletedItems.current += entityIds.length)
                    );
                }
            }
        },
        [addLabelActionFunction, entityName]
    );

    /**
     * @function deleteLabelsFromEntityService
     * @param { { id: number, labels: number[] }[] } entityData
     * @returns { Promise<void> }
     */
    const deleteLabelsFromEntityService = useCallback(
        async (
            labels: number[],
            entityData: { id: number; labels: number[] }[]
        ) => {
            const entityIds = entityData?.map(entity => entity.id);
            const entityLabelIds = entityData?.map(
                entity => entity.labels
            )?.[0];

            const filteredDeletedLabelIds = labels.filter(labelId =>
                entityLabelIds.includes(labelId)
            );

            if (!isEmpty(entityIds) && !isEmpty(filteredDeletedLabelIds)) {
                const processedDeletedLabelIds = entityIds.map(entityId => {
                    return filteredDeletedLabelIds.map(labelId => ({
                        [`${entityName}Id`]: entityId,
                        labelId,
                    }));
                });

                if (!isEmpty(processedDeletedLabelIds)) {
                    await deleteLabelActionFunction(
                        flatten(processedDeletedLabelIds)
                    ).then(
                        () => (totalCompletedItems.current += entityIds.length)
                    );
                }
            }
        },
        [deleteLabelActionFunction, entityName]
    );

    /**
     * @function handleShowCompletedItemsMessage
     * @returns { void }
     */
    const handleShowCompletedItemsMessage = () => {
        const numberOfCompletedItems = isSelectAll
            ? totalCompletedItems.current
            : entityIds.length;

        const isSingleItem = numberOfCompletedItems === 1;

        showMessage(
            `Changes ${
                isSingleItem ? 'has' : 'have'
            } been saved for ${numberOfCompletedItems} item${
                !isSingleItem ? 's' : ''
            }`,
            'success'
        );
    };

    if (isSelectAll) {
        const childActionList: ChunkRequestActionInterface<UpdateLabelsParametersType>[] =
            [];

        if (!isEmpty(assignedLabelIds)) {
            const assignLabelsToEntityServiceOptions = {
                parameters: {
                    labels: assignedLabelIds,
                    entityIds: [],
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityIds',
            };

            childActionList.push({
                actionName: UPDATE_LABEL_ACTION,
                actionFunction: assignLabelsToEntityService,
                actionFunctionOptions:
                    assignLabelsToEntityServiceOptions as any,
            });
        }

        if (!isEmpty(deletedLabelIds)) {
            const deleteLabelsFromEntityServiceOptions = {
                parameters: {
                    labels: deletedLabelIds,
                    entityIds: [],
                },
                fieldName: 'labels',
                secondaryFieldName: 'entityIds',
            };

            childActionList.push({
                actionName: UPDATE_LABEL_ACTION,
                actionFunction: deleteLabelsFromEntityService,
                actionFunctionOptions:
                    deleteLabelsFromEntityServiceOptions as any,
            });
        }

        actionList.push({
            ...fetchEntityAction,
            childActionList: childActionList,
        });
    } else {
        if (!isEmpty(assignedLabelIds)) {
            actionList.push({
                actionName: UPDATE_LABEL_ACTION,
                actionFunction: addLabelActionFunction,
                actionFunctionOptions: assignLabelsToEntityOptions,
            });
        }

        if (!isEmpty(deletedLabelIds)) {
            actionList.push({
                actionName: UPDATE_LABEL_ACTION,
                actionFunction: deleteLabelActionFunction,
                actionFunctionOptions: deleteLabelsFromEntityOptions,
            });
        }
    }

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(
        (error?: ErrorResult) => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_FAILED_MODAL',
                        modalProps: {
                            modalTitle: 'Processing Labels',
                            onClose: hideModalDialog,
                        } as GeneralBulkActionModalInterface,
                    })
                );
            }

            setIsRunning(false);

            actionList = [];
            handleShowCompletedItemsMessage();

            totalCompletedItems.current = 0;

            onErrorCallback?.();
        },
        [dispatch, hideModalDialog, onErrorCallback, shouldShowModals]
    );

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);
            }

            hideModalDialog();
            isRunning && setIsRunning(false);

            actionList = [];
            
            onSuccessCallback?.();
        });
        
        handleShowCompletedItemsMessage();

        totalCompletedItems.current = 0;
    }, [hideModalDialog, isRunning, onSuccessCallback, shouldShowModals]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopBulkActionService
     * @returns { void }
     */
    const handleStopBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);

            isRunning && setIsRunning(false);
            hideModalDialog();

            actionList = [];
            
            onSuccessCallback?.();
        });

        handleShowCompletedItemsMessage();

        totalCompletedItems.current = 0;
    }, [
        assignedLabelIds,
        isRunning,
        hideModalDialog,
        onSuccessCallback,
        setIsStopped,
    ]);

    /**
     * @function handleStartBulkActionService
     * @returns { void }
     */
    const handleStartBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle: 'Processing Labels',
                            onStopBulkActionService:
                                handleStopBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopBulkActionService,
        hideModalDialog,
        setIsStopped,
        shouldShowModals,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(
        (
            entity: UpdateLabelMultipleEntityType,
            assignedLabelIds: number[],
            deletedLabelIds: number[]
        ) => {
            entityRef.current = entity;
            assignedLabelIdsRef.current = assignedLabelIds;
            deletedLabelIdsRef.current = deletedLabelIds;

            setIsRunning(true);
        },
        []
    );
}

/**
 *
 * @description Custom hook to nudge users with chunk service
 * when all items are selected.
 * @function useNudgeUsersBulkActionService
 * @param { NudgeDateType } type
 * @param { SearchParams } searchParams
 * @param { number } totalUsersCount
 * @param { number } companyId
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
export function useNudgeUsersBulkActionService(
    type: NudgeDateType,
    searchUsersFunctionParams: SearchUsersFunctionParams<typeof type>,
    searchParams: SearchParams,
    totalUsersCount: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const handleSearchUsersAndNudge = useHandleSearchUsersAndNudge(type);

    const senderDataRef = useRef<NudgeUserSenderData>({
        senderName: '',
        text: '',
    });

    const shouldShowModals = totalUsersCount > 20;

    const totalCompletedNudgedUsers = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<
        NudgeUsersChunkRequestParameters<typeof type>
    >[] = [
        {
            actionName: NUDGE_USERS_ACTION,
            actionFunction: handleSearchUsersAndNudge,
            actionFunctionOptions: {
                parameters: {
                    searchParams: {
                        ...searchParams,
                        offset: totalUsersCount,
                    },
                    searchUsersFunctionParams,
                    senderData: senderDataRef.current,
                    onSuccessCallback: () =>
                        (totalCompletedNudgedUsers.current =
                            totalCompletedNudgedUsers.current + 1),
                },
                fieldName: 'searchParams.offset',
            },
        },
    ];

    /**
     * @function clearTotalCompletedNudgedUsers
     * @returns { void }
     */
    const clearTotalCompletedNudgedUsers = () =>
        (totalCompletedNudgedUsers.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                setTimeout(() => {
                    hideModalDialog();
                }, 900);
            }

            isRunning && setIsRunning(false);
            clearTotalCompletedNudgedUsers();

            showMessage('Messages were sent successfully', 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_FAILED_MODAL',
                        modalProps: {
                            modalTitle: 'Nudge Users',
                            onClose: hideModalDialog,
                        } as GeneralBulkActionModalInterface,
                    })
                );
            }

            clearTotalCompletedNudgedUsers();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopNudgeUsersBulkActionService
     * @returns { void }
     */
    const handleStopNudgeUsersBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(
                `${
                    totalCompletedNudgedUsers.current * ITEM_PER_CHUNK_SIZE
                } Messages were sent successfully`,
                'success'
            );

            clearTotalCompletedNudgedUsers();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartNudgeUsersBulkActionService
     * @returns { void }
     */
    const handleStartNudgeUsersBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            if (shouldShowModals) {
                dispatch(
                    showModalDialog({
                        modalType: 'BULK_ACTION_PROGRESS_MODAL',
                        modalProps: {
                            modalTitle: 'Nudge Users',
                            onStopBulkActionService:
                                handleStopNudgeUsersBulkActionService,
                            onClose: hideModalDialog,
                        },
                    })
                );
            }

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopNudgeUsersBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartNudgeUsersBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    /**
     * @param { NudgeUserSenderData } senderData
     */
    return useCallback((senderData: NudgeUserSenderData) => {
        senderDataRef.current = senderData;
        setIsRunning(true);
    }, []);
}