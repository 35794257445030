import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const PractisIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 40 40"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <path
                        fill="#4aa9e2"
                        d="M16.526 0C7.399 0 0 7.407 0 16.545V40l8.353-6.91h8.173c9.127 0 16.525-7.407 16.525-16.545C33.051 7.407 25.653 0 16.526 0zm-.106 8c4.651 0 8.421 3.817 8.421 8.526v.141c-.075 4.644-3.816 8.386-8.42 8.386H8v-8.527C8 11.817 11.77 8 16.42 8z"
                    />
                </g>
            </svg>
        );
    }
);

export default PractisIcon;
