import { Scenario, ScenarioStatuses } from '../../../constants/interfaces/Scenario';
import { UserProfile } from "../../../constants/interfaces/User";
import { hasPermission, NEW_PERMISSIONS } from "../../../features/users/tools";
import { getBulkActionAvailabilityItem, getBulkActionsAvailability } from "../../../tools/bulkActions";
import { useLoggedInUserProfileState } from '../../../features/users/store/states';
import { useMemo } from 'react';

export enum ScenarioActions {
    EDIT,
    ASSIGN_LABELS,
    DUPLICATE,
    ARCHIVE,
    RESTORE,
    DELETE,
    GENERATE_CHALLENGE,
    DOWNLOAD_PDF,
}

function createScenarioActionsHelper(loggedInUser: UserProfile | undefined) {
    const canEdit = (scenario: Scenario) =>
        scenario.status !== ScenarioStatuses.DELETED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.UPDATE_SCENARIO);
    
    const canAssignLabels = (scenario: Scenario) =>
        scenario.status !== ScenarioStatuses.DELETED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.ASSIGN_SCENARIO_LABEL);
    
    const canDuplicate = (scenario: Scenario) =>
        (scenario.status === ScenarioStatuses.DRAFT || scenario.status === ScenarioStatuses.ACTIVE)
            && hasPermission(loggedInUser, NEW_PERMISSIONS.COPY_SCENARIO);
    
    const canArchive = (scenario: Scenario) =>
        (scenario.status === ScenarioStatuses.DRAFT || scenario.status === ScenarioStatuses.ACTIVE)
            && hasPermission(loggedInUser, NEW_PERMISSIONS.ARCHIVE_SCENARIO);
    
    const canRestore = (scenario: Scenario) =>
        scenario.status === ScenarioStatuses.ARCHIVED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.RESTORE_SCENARIO);
    
    const canDelete = (scenario: Scenario) =>
        scenario.status === ScenarioStatuses.ARCHIVED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.DELETE_SCENARIO);

    const canGenerateChallenge = (scenario: Scenario) =>
        scenario.status !== ScenarioStatuses.DELETED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.GENERATE_CHALLENGE);

    const canDownloadPdf = (scenario: Scenario) =>
        scenario.status === ScenarioStatuses.DRAFT || scenario.status === ScenarioStatuses.ACTIVE;
    
    const getBulkActions = (scenarios: Scenario[] | undefined) =>
        getBulkActionsAvailability(
            scenarios,
            [
                getBulkActionAvailabilityItem(
                    ScenarioActions.ASSIGN_LABELS,
                    scenarios,
                    canAssignLabels
                ),
                getBulkActionAvailabilityItem(
                    ScenarioActions.DUPLICATE,
                    scenarios,
                    canDuplicate
                ),
                getBulkActionAvailabilityItem(
                    ScenarioActions.ARCHIVE,
                    scenarios,
                    canArchive
                ),
                getBulkActionAvailabilityItem(
                    ScenarioActions.RESTORE,
                    scenarios,
                    canRestore
                ),
                getBulkActionAvailabilityItem(
                    ScenarioActions.DELETE,
                    scenarios,
                    canDelete
                ),
            ]
        );

    return {
        canEdit,
        canAssignLabels,
        canDuplicate,
        canArchive,
        canRestore,
        canDelete,
        getBulkActions,
        canGenerateChallenge,
        canDownloadPdf,
    };
}

export type ScenarioActionsHelper = ReturnType<typeof createScenarioActionsHelper>;

export function useScenarioActionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createScenarioActionsHelper(loggedInUser),
        [loggedInUser]
    );
}