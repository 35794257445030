import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchParams } from '../../../../../../constants/interfaces/filters';

import { useChunkRequestsService } from '../../../../../../services/ChunkRequestService/hooks';
import { ITEM_PER_CHUNK_SIZE } from '../../../../../../services/ChunkRequestService/hooks/constants';
import { ChunkRequestActionInterface } from '../../../../../../services/ChunkRequestService/hooks/types';
import { useShowMessage } from '../../../../../../ui/components/ErrorMessages/ErrorMessages';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../../../ui/components/ModalDialogs/store/actions';
import { GeneralBulkActionModalInterface } from '../../../../../../ui/components/ModalDialogs/types';
import { CREATE_ENROLLMENT_ACTION, DELETE_ENROLLMENT_ACTION, UPDATE_ENROLLMENT_ACTION } from './constants';
import { useSearchEnrollmentsAndDelete, useSearchEnrollmentsAndUpdate, useSearchPsetEnrollmentsAndDelete, useSearchUsersAndCreateEnrollment } from './helpers';
import { CreateEnrollmentChunkRequestParameters, CreateEnrollmentsParams, DeleteEnrollmentChunkRequestParameters, DeleteEnrollmentsParams, UpdateEnrollmentChunkRequestParameters } from './types';

/**
 *
 * @description Custom hook to create enrollments with chunk service
 * @function useCreateEnrollmentBulkActionService
 * @param { createEnrollmentsParams } CreateEnrollmentsParams
 * @param { Number } totalUsersCount
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { Void }
 */
 export function useCreateEnrollmentBulkActionService(
     createEnrollmentsParams: CreateEnrollmentsParams,
     totalUsersCount?: number,
     onSuccessCallback?: () => void,
     onErrorCallback?: () => void
 ) {
     const dispatch = useDispatch();
     const searchUsersAndCreateEnrollment = useSearchUsersAndCreateEnrollment();
     const hideModalDialog = useHideModalDialog();
     const showMessage = useShowMessage();

     const totalCompletedEnrollments = useRef<number>(0);

     const [isRunning, setIsRunning] = useState<boolean>(false);

     const actionList: ChunkRequestActionInterface<CreateEnrollmentChunkRequestParameters>[] =
         [
             {
                 actionName: CREATE_ENROLLMENT_ACTION,
                 actionFunction: searchUsersAndCreateEnrollment,
                 actionFunctionOptions: {
                    parameters: {
                         searchParams: {
                             ...createEnrollmentsParams,
                             offset: totalUsersCount
                         },
                         onSuccessCallback: () =>
                             (totalCompletedEnrollments.current =
                                totalCompletedEnrollments.current + 1),
                     },
                     fieldName: 'searchParams.offset',
                 },
             },
         ];

     /**
      * @function clearTotalCompletedEnrollments
      * @returns { void }
      */
     const clearTotalCompletedEnrollments = () =>
         (totalCompletedEnrollments.current = 0);

     /**
      * @function handleSuccessServiceCallback
      * @returns { void }
      */
     const handleSuccessServiceCallback = useCallback(() => {
         Promise.resolve().then(() => {
             setTimeout(() => {
                 hideModalDialog();
             }, 900);

             isRunning && setIsRunning(false);
             clearTotalCompletedEnrollments();

             showMessage('All Users have been assigned', 'success');
             onSuccessCallback?.();
         });
     }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

     /**
      * @function handleFailedBulkActionServiceCallback
      * @returns { void }
      */
     const handleFailedBulkActionServiceCallback = useCallback(() => {
         Promise.resolve().then(() => {
             dispatch(
                 showModalDialog({
                     modalType: 'BULK_ACTION_FAILED_MODAL',
                     modalProps: {
                         modalTitle: 'Assign Users',
                         onClose: hideModalDialog,
                     } as GeneralBulkActionModalInterface,
                 })
             );

             clearTotalCompletedEnrollments();

             isRunning && setIsRunning(false);

             onErrorCallback?.();
         });
     }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

     const { setIsStopped } = useChunkRequestsService(
         actionList,
         handleSuccessServiceCallback,
         handleFailedBulkActionServiceCallback
     );

     /**
      * @function handleStopCreateEnrollmentsBulkActionService
      * @returns { void }
      */
     const handleStopCreateEnrollmentsBulkActionService = useCallback(() => {
         Promise.resolve().then(() => {
             setIsStopped(true);
             setIsRunning(false);
             hideModalDialog();

             showMessage(
                 `${
                    totalCompletedEnrollments.current *
                     ITEM_PER_CHUNK_SIZE
                 } Users have been assigned`,
                 'success'
             );

             clearTotalCompletedEnrollments();

             onSuccessCallback?.();
         });
     }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

     /**
      * @function handleStartCreateEnrollmentBulkActionService
      * @returns { void }
      */
     const handleStartCreateEnrollmentBulkActionService = useCallback(() => {
         Promise.resolve().then(() => {
             dispatch(
                 showModalDialog({
                     modalType: 'BULK_ACTION_PROGRESS_MODAL',
                     modalProps: {
                         modalTitle: 'Assign Users',
                         onStopBulkActionService:
                             handleStopCreateEnrollmentsBulkActionService,
                         onClose: hideModalDialog,
                     },
                 })
             );

             setIsStopped(false);
         });
     }, [
         dispatch,
         handleStopCreateEnrollmentsBulkActionService,
         hideModalDialog,
         setIsStopped,
     ]);

     useEffect(() => {
         if (isRunning) {
             handleStartCreateEnrollmentBulkActionService();
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [isRunning]);

     return useCallback(() => {
         setIsRunning(true);
     }, []);
}


/**
 *
 * @description Custom hook to get enrollments and delete with chunk service
 * @function useDeleteEnrollmentBulkActionService
 * @param { deleteEnrollmentsParams } DeleteEnrollmentsParams
 * @param { Number } totalEnrollmentsCount
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { Void }
 */
 export function useDeleteEnrollmentBulkActionService(
    deleteEnrollmentsParams: DeleteEnrollmentsParams,
    entityType: string,
    totalEnrollmentsCount?: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const searchEnrollmentsAndDelete = useSearchEnrollmentsAndDelete();
    const searchPsetEnrollmentsAndDelete = useSearchPsetEnrollmentsAndDelete();
    const actionFunction = entityType === 'practisSet' ? searchEnrollmentsAndDelete : searchPsetEnrollmentsAndDelete;
    const entityExpression =  entityType === 'practisSet' ? 'Practis Sets' : 'Users';
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const totalCompletedEnrollments = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<DeleteEnrollmentChunkRequestParameters>[] =
        [
            {
                actionName: DELETE_ENROLLMENT_ACTION,
                actionFunction,
                actionFunctionOptions: {
                   parameters: {
                        searchParams: {
                            ...deleteEnrollmentsParams,
                            offset: totalEnrollmentsCount
                        },
                        onSuccessCallback: () =>
                            (totalCompletedEnrollments.current =
                               totalCompletedEnrollments.current + 1),
                    },
                    fieldName: 'searchParams.offset',
                },
            },
        ];

    /**
     * @function clearTotalCompletedEnrollments
     * @returns { void }
     */
    const clearTotalCompletedEnrollments = () =>
        (totalCompletedEnrollments.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            clearTotalCompletedEnrollments();

            showMessage(`All ${entityExpression} have been unassigned`, 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage, entityExpression]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: `Unassign ${entityExpression}`,
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );

            clearTotalCompletedEnrollments();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onErrorCallback, entityExpression]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopDeleteEnrollmentsBulkActionService
     * @returns { void }
     */
    const handleStopDeleteEnrollmentsBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(
                `${
                   totalCompletedEnrollments.current *
                    ITEM_PER_CHUNK_SIZE
                } ${entityExpression} have been unassigned`,
                'success'
            );

            clearTotalCompletedEnrollments();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage, entityExpression]);

    /**
     * @function handleStartDeleteEnrollmentBulkActionService
     * @returns { void }
     */
    const handleStartDeleteEnrollmentBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: `Unassign ${entityExpression}`,
                        onStopBulkActionService:
                        handleStopDeleteEnrollmentsBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopDeleteEnrollmentsBulkActionService,
        hideModalDialog,
        setIsStopped,
        entityExpression
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartDeleteEnrollmentBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);
}

/**
 *
 * @description Custom hook to get enrollments and update with chunk service
 * @function useUpdateEnrollmentBulkActionService
 * @param { UpdateEnrollmentsParams }  { searchParams: SearchParams }
 * @param { Number } totalEnrollmentsCount
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { Void }
 */
export function useUpdateEnrollmentBulkActionService(
    updateEnrollmentsParams: { searchParams: SearchParams },
    totalEnrollmentsCount?: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const searchEnrollmentsAndUpdate = useSearchEnrollmentsAndUpdate();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();
    const dueDateRef = useRef<string | null>(null);

    const totalCompletedEnrollments = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);
    const actionList: ChunkRequestActionInterface<UpdateEnrollmentChunkRequestParameters>[] =
        [
            {
                actionName: UPDATE_ENROLLMENT_ACTION,
                actionFunction: searchEnrollmentsAndUpdate,
                actionFunctionOptions: {
                   parameters: {
                        searchParams: {
                            ...updateEnrollmentsParams,
                            dueDate: dueDateRef.current,
                            offset: totalEnrollmentsCount as number
                        },
                        onSuccessCallback: () =>
                            (totalCompletedEnrollments.current =
                               totalCompletedEnrollments.current + 1),
                    },
                    fieldName: 'searchParams.offset',
                },
            },
        ];

    /**
     * @function clearTotalCompletedEnrollments
     * @returns { void }
     */
    const clearTotalCompletedEnrollments = () =>
        (totalCompletedEnrollments.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            clearTotalCompletedEnrollments();

            showMessage(`Due Date has changed`, 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: `Change Due Date`,
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );

            clearTotalCompletedEnrollments();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopUpdateEnrollmentsBulkActionService
     * @returns { void }
     */
    const handleStopUpdateEnrollmentsBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(
                `Due Date has changed`,
                'success'
            );

            clearTotalCompletedEnrollments();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartUpdateEnrollmentBulkActionService
     * @returns { void }
     */
    const handleStartUpdateEnrollmentBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: `Change Due Date`,
                        onStopBulkActionService:
                        handleStopUpdateEnrollmentsBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopUpdateEnrollmentsBulkActionService,
        hideModalDialog,
        setIsStopped
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartUpdateEnrollmentBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback((dueDate: string | null) => {
        dueDateRef.current = dueDate;
        setIsRunning(true);
    }, []);
}