import AIParamTextbox from "../AIParamTextbox/controller"
import { AiParamsProps } from "./types"

function SituationAiParam({ handleChange, roleplayData, isEditMode, emptyRequiredProps, autoFocus, analyticsKey }: AiParamsProps) {
    return <AIParamTextbox title="Describe the sales situation you want to explore" tooltip="Provide a brief overview of the situation you're interested in. Include key elements like the overall setting and sales environment. This description will shape the AI-generated Roleplay, creating a tailored sales simulation for your practice."
        isRequired={true}
        isEditMode={isEditMode}
        analyticsKey={`${analyticsKey}_situation`}
        isInvalid={emptyRequiredProps?.includes('situation')}
        placeholder="For example: A homeowner wants to improve their yard and garden. A salesperson from a local landscaping company visits their home to assess the area and provide expert advice. They discuss various options for lawn care, garden maintenance, and the best equipment and services to create a beautiful and thriving outdoor space."
        onChange={(val) => handleChange('situation', val)} value={roleplayData?.situation} autoFocus={autoFocus} dataTest="situation"></AIParamTextbox>
}

export default SituationAiParam