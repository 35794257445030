import { FC, useEffect } from 'react';

import {
    useClearTeams,
    useDeselectAllScenarios,
    useResetSelectedTeams,
    useSaveScenarios,
    useSearchScenariosService,
    useSelectAllTeams,
    useSelectMultipleTeams,
} from '../store/hors/withTeams/services';
import { useScenariosState } from '../store/hors/withTeams/states';
import { PortableScenarios, PortableScenariosProps } from './PortableScenarios';
import { usePortableScenariosState } from '../store/states';
import { LIBRARY_STATUS } from '../../library/tools';

type Props = Pick<
    PortableScenariosProps,
    | 'filtersTitle'
    | 'onSetSelected'
    | 'showSelectActions'
    | 'showSearch'
    | 'showSave'
> & {
    fetchOnMount?: boolean;
    resetSelectedOnUnmount?: boolean;
    showDefaultMembers?: boolean;
    selectedTeams?: number[];
};

const initialSearchParams: any = {
    searchTerm: '',
    labelIDs: [],
    filters: [{ field: 'status', value: [LIBRARY_STATUS.ACTIVE] }],
    orderBy: {
        field: 'title',
        asc: false,
    },
    limit: 100,
    offset: 0,
};

export const PortableScenariosWithStore: FC<Props> = ({
    onSetSelected,
    selectedTeams,
    fetchOnMount = true,
    resetSelectedOnUnmount = true,
    showDefaultMembers,
    ...rest
}) => {
    const search = useSearchScenariosService();
    const { selected } = useScenariosState();
    const { data, loading } = usePortableScenariosState();
    const clearHandler = useClearTeams();
    const saveHandler = useSaveScenarios();
    const select = useSelectMultipleTeams();
    const selectAll = useSelectAllTeams();
    const deselectAll = useDeselectAllScenarios();
    const resetSelected = useResetSelectedTeams();

    useEffect(() => {
        if (fetchOnMount && !data.items.length) {
            search(initialSearchParams);
        }

        return () => {
            if (resetSelectedOnUnmount) {
                resetSelected();
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    useEffect(() => {
        if (selectedTeams) {
            selectAll(selectedTeams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PortableScenarios
            teams={data}
            selectedList={selected}
            loading={loading}
            onClearSelected={() => {
                onSetSelected && onSetSelected([]);
                clearHandler();
            }}
            onSave={saveHandler}
            onSetSelected={(team) => { 
                onSetSelected && onSetSelected(team);
                select(team);
             }}
            showDefaultMembers={showDefaultMembers}
            onSelectAll={(teams) => { 
                onSetSelected && onSetSelected(teams);
                selectAll(teams);
             }}
            onDeselectAll={deselectAll}
            {...rest}
        />
    );
};
