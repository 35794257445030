import React, { Component } from 'react';
import styled from 'styled-components';
import authBackground from '../../../../assets/images/auth-background.png';
import authBackground2x from '../../../../assets/images/auth-background2x.png';
import authBackground3x from '../../../../assets/images/auth-background3x.png';
import { isAdminPortal } from '../../../../helpers/functions/general';
import PractisAdminPlatformLogo from '../../../../ui/icons/PractisAdminPlatformLogo';
import PractisLogo from '../../../../ui/icons/PractisLogo';
import CardContent from '../../Component/CardContent';
import LandingCard from './LandingCard';

const LeftContentContainer = styled.div`
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const AuthBackground = styled.img`
    height: 90%;
    right: -10%;
    bottom: 0;
    position: absolute;
`;

const StyledLogo = styled.div`
    height: 32px;
    width: 125px;
    color: ${props => props.theme.Colors.white};
    margin-top: 70px;
    margin-left: 73px;
`;

class LeftPanel extends Component {
    render() {
        return (
            <LeftContentContainer>
                <AuthBackground
                    src={authBackground}
                    srcSet={`${authBackground2x} 2x, ${authBackground3x} 3x`}
                />
                <StyledLogo>
                    {isAdminPortal() ?
                        <PractisAdminPlatformLogo />
                        :
                        <PractisLogo />}
                </StyledLogo>
                <CardContent
                    card={LandingCard}
                    counterHeight={12}
                    counterWidth={112}
                />
            </LeftContentContainer>
        );
    }
}

export default LeftPanel;
