import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    AssignMembersToTeamsApiParams,
    CreateNewTeamApiParams,
    DeleteMembersToTeamsApiParams,
    DeleteTeamsApiParams,
    GetMembersReportApiParams,
    GetPractisSetsReportApiParams,
    GetEnrollmentsReportApiParams,
    GetSingleTeamApiParams,
    MarkTeamAsViewedApiParams,
    SearchPractisSetDetailsUsersApiParams,
    SearchTeamLeadersApiParams,
    SearchTeamMembersApiParams,
    SearchTeamsApiParams,
    SearchTrainingPractisSetsApiParams,
    UpdateLabelsTeamsApiParams,
    UpdateTeamLeaderApiParams,
    UpdateTeamNameApiParams,
} from './types';

/**
 * @function useSearchTeamsApi
 * @returns { SearchTeamsApiParams }
 */
export function useSearchTeamsApi(): SearchTeamsApiParams {
    const http = useHttp();
    return useCallback(searchParams => http.get(`teams`, searchParams, undefined, true), [http]);
}

/**
 * @function useSearchTeamLeadersApi
 * @returns { SearchTeamLeadersApiParams }
 */
export function useSearchTeamLeadersApi(): SearchTeamLeadersApiParams {
    const http = useHttp();
    return useCallback(
        searchParams => http.post('teams/leaders-search', searchParams),
        [http]
    );
}

/**
 * @function useGetSingleTeamApi
 * @returns { GetSingleTeamApiParams }
 */
export function useGetSingleTeamApi(): GetSingleTeamApiParams {
    const http = useHttp();
    return useCallback(
        teamId => http.get(`teams/${teamId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useSearchTrainingPractisSetsApi
 * @returns { SearchTrainingPractisSetsApiParams }
 */
export function useSearchTrainingPractisSetsApi(): SearchTrainingPractisSetsApiParams {
    const http = useHttp();
    return useCallback(
        (teamId, searchParams) =>
            http.get(
                `teams/${teamId}/practisSets`,
                searchParams,
                undefined,
                true
            ),
        [http]
    );
}

/**
 * @function useSearchPractisSetDetailsUsersApi
 * @returns { SearchPractisSetDetailsUsersApiParams }
 */
export function useSearchPractisSetDetailsUsersApi(): SearchPractisSetDetailsUsersApiParams {
    const http = useHttp();
    return useCallback(
        (teamId, practisSetId, searchParams) =>
            http.get(
                `teams/${teamId}/practisSets/${practisSetId}/enrollments`,
                searchParams,
                undefined,
                true
            ),
        [http]
    );
}

/**
 * @function useDeleteTeamsApi
 * @returns { DeleteTeamsApiParams }
 */
export function useDeleteTeamsApi(): DeleteTeamsApiParams {
    const http = useHttp();
    return useCallback(
        (teamIds) =>
            http.delete(`teams`, teamIds, true),
        [http]
    );
}

/**
 * @function useGetMembersReportApi
 * @returns { GetMembersReportApiParams }
 */
export function useGetMembersReportApi(): GetMembersReportApiParams {
    const http = useHttp();
    return useCallback(
        (teamId, searchParams) => {
                return http.get(`teams/${teamId}/reports/members`, searchParams, "blob", true);
        },
        [http]
    );
}

/**
 * @function useGetPractisSetsReportApi
 * @returns { GetPractisSetsReportApiParams }
 */
export function useGetPractisSetsReportApi(): GetPractisSetsReportApiParams {
    const http = useHttp();
    return useCallback(
        (searchParams, teamId) => {
            return http.get(`teams/${teamId}/reports/practisSets`, searchParams, "blob", true);
        },
        [http]
    );
}

/**
 * @function useGetEnrollmentsReportApi
 * @returns { GetEnrollmentsReportApiParams }
 */
 export function useGetEnrollmentsReportApi(): GetEnrollmentsReportApiParams {
    const http = useHttp();
    return useCallback(
        (searchParams, teamId, psetId) => {
            return http.get(`teams/${teamId}/reports/practisSets/${psetId}/enrollments`, searchParams, "blob", true);
        },
        [http]
    );
}

/**
 * @function useSearchTeamMembersApi
 * @returns { SearchTeamMembersApiParams }
 */
export const useSearchTeamMembersApi = (): SearchTeamMembersApiParams => {
    const http = useHttp();

    return useCallback(
        (teamId, searchParams) => {
            return http.get(
                `/teams/${teamId}/members`,
                searchParams,
                undefined,
                true
            );
        },
        [http]
    );
};

/**
 * @function useMarkTeamAsViewedApi
 * @returns { MarkTeamAsViewedApiParams }
 */
export function useMarkTeamAsViewedApi(): MarkTeamAsViewedApiParams {
    const http = useHttp();
    return useCallback(
        teamId => http.put(`teams/${teamId}/user/mark-as-viewed`),
        [http]
    );
}

/**
 * @function useCreateNewTeamApi
 * @returns { CreateNewTeamApiParams }
 */
export function useCreateNewTeamApi(): CreateNewTeamApiParams {
    const http = useHttp();
    return useCallback(name => http.post('teams', { name }, true), [http]);
}

/**
 * @function useAssignMembersToTeamsApi
 * @returns { AssignMembersToTeamsApiParams }
 */
export function useAssignMembersToTeamsApi(): AssignMembersToTeamsApiParams {
    const http = useHttp();
    return useCallback(
        members => http.post('teams/members', members, true),
        [http]
    );
}


/**
 * @function useDeleteMembersFromTeamsApi
 * @returns { DeleteMembersToTeamsApiParams }
 */
export function useDeleteMembersFromTeamsApi(): DeleteMembersToTeamsApiParams {
    const http = useHttp();
    return useCallback(
        members => http.delete('teams/members', members, true),
        [http]
    );
}

/**
 * @function useAssignLabelsToTeamsApi
 * @returns { UpdateLabelsTeamsApiParams }
 */
export function useAssignLabelsToTeamsApi(): UpdateLabelsTeamsApiParams {
    const http = useHttp();
    return useCallback(
        labels => http.post('teams/labels', labels, true),
        [http]
    );
}
/**
 * @function useDeleteLabelsFromTeamsApi
 * @returns { UpdateLabelsTeamsApiParams }
 */
export function useDeleteLabelsFromTeamsApi(): UpdateLabelsTeamsApiParams {
    const http = useHttp();
    return useCallback(
        labels => http.delete('teams/labels', labels, true),
        [http]
    );
}
/**
 * @function useUpdateTeamNameApi
 * @returns { UpdateTeamNameApiParams }
 */
export function useUpdateTeamNameApi(): UpdateTeamNameApiParams {
    const http = useHttp();
    return useCallback(
        (teamId, name) => http.put(`teams/${teamId}`, { name }, true),
        [http]
    );
}

/**
 * @function useUpdateTeamLeaderApi
 * @returns { UpdateTeamLeaderApiParams }
 */
export function useUpdateTeamLeaderApi(): UpdateTeamLeaderApiParams {
    const http = useHttp();
    return useCallback(
        (teamUserId, isTeamLead) =>
            http.put(`teams/members/${teamUserId}`, { isTeamLead }, true),
        [http]
    );
}
