import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchParams } from '../../../../constants/interfaces/filters';

import { useChunkRequestsService } from '../../../../services/ChunkRequestService/hooks';
import { ITEM_PER_CHUNK_SIZE } from '../../../../services/ChunkRequestService/hooks/constants';
import { ChunkRequestActionInterface } from '../../../../services/ChunkRequestService/hooks/types';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../ui/components/ModalDialogs/store/actions';
import { GeneralBulkActionModalInterface } from '../../../../ui/components/ModalDialogs/types';
import { DELETE_USER_ACTION, RESEND_INVITATION_ACTION, REVOKE_INVITATION_ACTION } from './constants';
import { useSearchUsersAndResendInvitation, useSearchUsersAndDeleteUser, useSearchUsersAndRevokeInvitation } from './helpers';
import { ResendInvitationChunkRequestParameters, DeleteUserChunkRequestParameters, RevokeInvitationChunkRequestParameters } from './types';
/**
 *
 * @description Custom hook to revoke invitations with chunk service
 * when all items are selected.
 * @function useRevokeInvitationBulkActionService
 * @param { SearchParams } searchParams
 * @param { number } companyId
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
 export function useRevokeInvitationBulkActionService(
     searchParams: SearchParams,
     companyId: number,
     totalUsersCount: number,
     onSuccessCallback?: () => void,
     onErrorCallback?: () => void
 ) {
     const dispatch = useDispatch();
     const searchUsersAndRevokeInvitation = useSearchUsersAndRevokeInvitation();
     const hideModalDialog = useHideModalDialog();
     const showMessage = useShowMessage();

     const totalCompletedRevokeInvitations = useRef<number>(0);

     const [isRunning, setIsRunning] = useState<boolean>(false);

     const actionList: ChunkRequestActionInterface<RevokeInvitationChunkRequestParameters>[] =
         [
             {
                actionName: REVOKE_INVITATION_ACTION,
                 actionFunction: searchUsersAndRevokeInvitation,
                 actionFunctionOptions: {
                     parameters: {
                         searchParams: {
                             ...searchParams,
                             offset: totalUsersCount,
                         },
                         companyId,
                         onSuccessCallback: () =>
                             (totalCompletedRevokeInvitations.current =
                                 totalCompletedRevokeInvitations.current + 1),
                     },
                     fieldName: 'searchParams.offset',
                 },
             },
         ];

     /**
      * @function clearTotalCompletedRevokeInvitations
      * @returns { void }
      */
     const clearTotalCompletedRevokeInvitations = () =>
         (totalCompletedRevokeInvitations.current = 0);

     /**
      * @function handleSuccessServiceCallback
      * @returns { void }
      */
     const handleSuccessServiceCallback = useCallback(() => {
         Promise.resolve().then(() => {
             setTimeout(() => {
                 hideModalDialog();
             }, 900);

             isRunning && setIsRunning(false);
             clearTotalCompletedRevokeInvitations();

             showMessage('All Invites have been revoked', 'success');
             onSuccessCallback?.();
         });
     }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

     /**
      * @function handleFailedBulkActionServiceCallback
      * @returns { void }
      */
     const handleFailedBulkActionServiceCallback = useCallback(() => {
         Promise.resolve().then(() => {
             dispatch(
                 showModalDialog({
                     modalType: 'BULK_ACTION_FAILED_MODAL',
                     modalProps: {
                         modalTitle: 'Revoke Invitations',
                         onClose: hideModalDialog,
                     } as GeneralBulkActionModalInterface,
                 })
             );

             clearTotalCompletedRevokeInvitations();

             isRunning && setIsRunning(false);

             onErrorCallback?.();
         });
     }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

     const { setIsStopped } = useChunkRequestsService(
         actionList,
         handleSuccessServiceCallback,
         handleFailedBulkActionServiceCallback
     );

     /**
      * @function handleStopRevokeInvitationsBulkActionService
      * @returns { void }
      */
     const handleStopRevokeInvitationsBulkActionService = useCallback(() => {
         Promise.resolve().then(() => {
             setIsStopped(true);
             setIsRunning(false);
             hideModalDialog();

             showMessage(
                 `${
                     totalCompletedRevokeInvitations.current *
                     ITEM_PER_CHUNK_SIZE
                 } Invites have been revoked`,
                 'success'
             );

             clearTotalCompletedRevokeInvitations();

             onSuccessCallback?.();
         });
     }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

     /**
      * @function handleStartRevokeInvitationBulkActionService
      * @returns { void }
      */
     const handleStartRevokeInvitationBulkActionService = useCallback(() => {
         Promise.resolve().then(() => {
             dispatch(
                 showModalDialog({
                     modalType: 'BULK_ACTION_PROGRESS_MODAL',
                     modalProps: {
                         modalTitle: 'Revoke Invitations',
                         onStopBulkActionService:
                             handleStopRevokeInvitationsBulkActionService,
                         onClose: hideModalDialog,
                     },
                 })
             );

             setIsStopped(false);
         });
     }, [
         dispatch,
         handleStopRevokeInvitationsBulkActionService,
         hideModalDialog,
         setIsStopped,
     ]);

     useEffect(() => {
         if (isRunning) {
             handleStartRevokeInvitationBulkActionService();
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [isRunning]);

     return useCallback(() => {
         setIsRunning(true);
     }, []);
 }

/**
 *
 * @description Custom hook to resend invitations with chunk service
 * when all items are selected.
 * @function useResendInvitationBulkActionService
 * @param { SearchParams } searchParams
 * @param { number } companyId
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
export function useResendInvitationBulkActionService(
    searchParams: SearchParams,
    companyId: number,
    totalUsersCount: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const searchUsersAndResendInvitation = useSearchUsersAndResendInvitation();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const totalCompletedResendInvitations = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<ResendInvitationChunkRequestParameters>[] =
        [
            {
                actionName: RESEND_INVITATION_ACTION,
                actionFunction: searchUsersAndResendInvitation,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            offset: totalUsersCount,
                        },
                        companyId,
                        onSuccessCallback: () =>
                            (totalCompletedResendInvitations.current =
                                totalCompletedResendInvitations.current + 1),
                    },
                    fieldName: 'searchParams.offset',
                },
            },
        ];

    /**
     * @function clearTotalCompletedResendInvitations
     * @returns { void }
     */
    const clearTotalCompletedResendInvitations = () =>
        (totalCompletedResendInvitations.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            clearTotalCompletedResendInvitations();

            showMessage('All Invites have been sent', 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: 'Resend Invitations',
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );

            clearTotalCompletedResendInvitations();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopDuplicateTeamBulkActionService
     * @returns { void }
     */
    const handleStopDuplicateTeamBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(
                `${
                    totalCompletedResendInvitations.current *
                    ITEM_PER_CHUNK_SIZE
                } Invites have been sent`,
                'success'
            );

            clearTotalCompletedResendInvitations();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartResendInvitationBulkActionService
     * @returns { void }
     */
    const handleStartResendInvitationBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'Resend Invitations',
                        onStopBulkActionService:
                            handleStopDuplicateTeamBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopDuplicateTeamBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartResendInvitationBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);
}


/**
 *
 * @description Custom hook to delete users with chunk service
 * when all items are selected.
 * @function useDeleteUserBulkActionService
 * @param { SearchParams } searchParams
 * @param { number } companyId
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { CallableFunction }
 */
 export function useDeleteUserBulkActionService(
    searchParams: SearchParams,
    companyId: number,
    totalUsersCount: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const searchUsersAndDeleteUser = useSearchUsersAndDeleteUser();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const deletedUsersTotalCount = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<DeleteUserChunkRequestParameters>[] =
        [
            {
                actionName: DELETE_USER_ACTION,
                actionFunction: searchUsersAndDeleteUser,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            offset: totalUsersCount,
                        },
                        companyId,
                        onSuccessCallback: () =>
                            (deletedUsersTotalCount.current =
                                deletedUsersTotalCount.current + 1),
                    },
                    fieldName: 'searchParams.offset',
                },
            },
        ];

    /**
     * @function ResetDeletedUsersTotalCount
     * @returns { void }
     */
    const ResetDeletedUsersTotalCount = () =>
        (deletedUsersTotalCount.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            ResetDeletedUsersTotalCount();

            showMessage('Users have been deactivated.', 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: 'Deactivate Users',
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );

            ResetDeletedUsersTotalCount();

            isRunning && setIsRunning(false);

            onErrorCallback?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onErrorCallback]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback
    );

    /**
     * @function handleStopDuplicateTeamBulkActionService
     * @returns { void }
     */
    const handleStopDuplicateTeamBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(
                `${
                    deletedUsersTotalCount.current *
                    ITEM_PER_CHUNK_SIZE
                } Users have been deactivated.`,
                'success'
            );

            ResetDeletedUsersTotalCount();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartDeleteUserBulkActionService
     * @returns { void }
     */
    const handleStartDeleteUserBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'Deactivate Users',
                        onStopBulkActionService:
                            handleStopDuplicateTeamBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopDuplicateTeamBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartDeleteUserBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);


}