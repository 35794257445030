import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    LoginApiParams,
    ForgotPasswordApiParams,
    LoginByPhoneCodeApiParams,
    ResetPasswordApiParams,
    GetPhoneCodeApiParams,
    RegistrationApiParams,
    GetAccessTokenApiParams
} from './types';

/**
 * @function useLoginApi
 * @returns { LoginApiParams }
 */
export function useLoginApi(): LoginApiParams {
    const http = useHttp();
    return useCallback(params => http.post('auth/login', params, true), [http]);
}

/**
 * @function useRegistrationApi
 * @returns { RegistrationApiParams }
 */
 export function useRegistrationApi(): RegistrationApiParams {
    const http = useHttp();
    return useCallback(params => http.post('auth/signup', params, true), [http]);
}

/**
 * @function useLoginByPhoneCodeApi
 * @returns { LoginByPhoneCodeApiParams }
 */
export function useLoginByPhoneCodeApi(): LoginByPhoneCodeApiParams {
    const http = useHttp();
    return useCallback(
        params => http.post('/auth/phone/confirm', params, true),
        [http]
    );
}

/**
 * @function useForgotPasswordApi
 * @returns { ForgotPasswordApiParams }
 */
export function useForgotPasswordApi(): ForgotPasswordApiParams {
    const http = useHttp();
    return useCallback(
        (email: string): any => http.post(`auth/password/forgot`, { email }, true),
        [http]
    );
}

/**
 * @function useResetPasswordApi
 * @returns { ResetPasswordApiParams }
 */
 export function useResetPasswordApi(): ResetPasswordApiParams {
    const http = useHttp();
    return useCallback(
        (password: string, token: string) =>
            http.post('auth/password/reset', { password, token }, true),
        [http]
    );
}

/**
 * @function useGetPhoneCodeApi
 * @returns { GetPhoneCodeApiParams }
 */
 export function useGetPhoneCodeApi(): GetPhoneCodeApiParams {
    const http = useHttp();
    return useCallback(
        (phone: string) =>
            http.post('auth/phone', { phone }, true),
        [http]
    );
}

/**
 * @function useGetAccessTokenApi
 * @returns { GetAccessTokenApiParams }
 */
export function useGetAccessTokenApi(): GetAccessTokenApiParams {
    const http = useHttp();
    return useCallback(
        code =>
            http.get(`/auth/google/code`, { code }, undefined, true),
        [http]
    );
}

