import { forwardRef } from 'react';

import { IconProps } from './types';

export const TeamLeaders = forwardRef<SVGSVGElement, IconProps>(
    ({ className, dataTest }, ref) => {
        return (
            <svg
                ref={ref}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                data-test={dataTest}
            >
                <g clip-path="url(#9l12if48ma)">
                    <g clip-path="url(#5blw8zx8gb)">
                        <path
                            d="m14.33 6.71-1.38 5.73H3.08L1.69 6.71"
                            stroke="#6D7F8C"
                            stroke-width="1.2"
                            stroke-miterlimit="10"
                        />
                        <path
                            d="m8.01 3.55 1.66 3.11a1.82 1.82 0 0 0 2.62.64l1.13-.78M2.57 6.52l1.13.77a1.81 1.81 0 0 0 2.62-.64l1.65-3.1"
                            stroke="#6D7F8C"
                            stroke-width="1.2"
                            stroke-miterlimit="10"
                        />
                        <path
                            d="M8.01 3.88a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38zM14.33 6.95a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38zM1.69 6.95a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38z"
                            fill="#6D7F8C"
                            stroke="#6D7F8C"
                            stroke-miterlimit="10"
                        />
                        <path
                            d="M13.33 13.99H2.61v1.2h10.72v-1.2z"
                            fill="#6D7F8C"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="9l12if48ma">
                        <path fill="#fff" d="M0 0h16v16H0z" />
                    </clipPath>
                    <clipPath id="5blw8zx8gb">
                        <path
                            fill="#fff"
                            transform="translate(0 1)"
                            d="M0 0h16.02v14.19H0z"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default TeamLeaders;

