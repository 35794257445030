import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Footer from '../../layout/Footer/Footer';
import styled from 'styled-components';
import LandingHeader from './LandingHeader/LandingHeader';

export interface LandingWrapperInterface {
    children: any;
    contentSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    title?: string;
    withAction?: boolean;
    height?: string;
    className?: string;
}

const StyledLandingContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    height: 100vh;
    background: ${props => props.theme.Colors.white};
    overflow: auto;
`;
const StyledLandingContent = styled.div<any>`
    flex: 1;
    display: flex;
`;

const LandingWrapper = ({
    children,
    history,
    withAction,
    className,
}: LandingWrapperInterface & RouteComponentProps) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <StyledLandingContainer>
            <LandingHeader withAction={withAction} history={history} />
            <StyledLandingContent className={className}>
                {children}
            </StyledLandingContent>
            <Footer />
        </StyledLandingContainer>
    );
};

export default withRouter(LandingWrapper);
