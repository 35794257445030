import ReportingControlPanel from '../../../ui/components/ReportingControlPanel';
import { DynamicFilter } from '../dynamic-filter';
import {
    Wrapper,
    DynamicFiltersContainer,
    DynamicFilterWrapper,
    StyledDivider,
    ControlPanelContainer,
} from './styles';
import { FiltersSidebarViewProps } from './types';

function FiltersSidebarView(props: FiltersSidebarViewProps) {
    const {
        filtersConfig,
        onGenerateReport,
        onClearFilters,
        controlPanelMode,
        userEmail,
        remainingTimeout,
        headerHeight,
    } = props;

    return (
        <Wrapper headerHeight={headerHeight}>
            <DynamicFiltersContainer>
                {filtersConfig.map(({ filterType }, index) => (
                    <DynamicFilterWrapper key={index}>
                        <DynamicFilter filterType={filterType} />
                    </DynamicFilterWrapper>
                ))}
            </DynamicFiltersContainer>
            <div>
                <StyledDivider />
                <ControlPanelContainer>
                    <ReportingControlPanel
                        mode={controlPanelMode}
                        userEmail={userEmail}
                        onSubmit={onGenerateReport}
                        onClear={onClearFilters}
                        remainingTimeout={remainingTimeout}
                    />
                </ControlPanelContainer>
            </div>
        </Wrapper>
    );
}

export default FiltersSidebarView;