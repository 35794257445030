import { Action } from 'redux';

import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { Label } from '../../../../../constants/interfaces/Label';
import { Scope } from './scopes';

//region Types
type ReturnTypeLabelAction<T extends (...args: any) => any> = ReturnType<T> &
    ActionPayload;

type ActionPayload = { _scope?: Scope };

export type ActionLabel<T = any> = Action<T> & ActionPayload;

export type Actions =
    | ReturnTypeLabelAction<typeof selectAllAction>
    | ReturnTypeLabelAction<typeof deselectAllAction>
    | ReturnTypeLabelAction<typeof resetSelectedAction>
    | ReturnTypeLabelAction<typeof selectLabelsAction>
    | ReturnTypeLabelAction<typeof clearAction>
    | ReturnTypeLabelAction<typeof saveAction>
    | ReturnTypeLabelAction<typeof toggleCollapseLabelAction>
    | ReturnTypeLabelAction<typeof resetAction>
    | ReturnTypeLabelAction<typeof toggleCollapseAllLabelsAction>
    | ReturnTypeLabelAction<typeof resetCollapseAllLabelsAction>;
//endregion

export const searchStartAction = (): ActionLabel<'searchStartLabels'> => ({
    type: 'searchStartLabels',
});

export const searchSuccessAction = (
    result: PaginationResult<Label>
): ActionLabel<'searchSuccessLabels'> & {
    result: PaginationResult<Label>;
} => ({
    type: 'searchSuccessLabels',
    result,
});

export const searchFailureAction = (
    message: string
): ActionLabel<'searchFailureLabels'> & { message: string } => ({
    type: 'searchFailureLabels',
    message,
});

export const selectAllAction = (
    allLabels: Label[]
): ActionLabel<'selectAllLabels'> & { allLabels: Label[] } => ({
    type: 'selectAllLabels',
    allLabels,
});

export const deselectAllAction = (): ActionLabel<'deselectAllLabels'> => ({
    type: 'deselectAllLabels',
});

export const resetSelectedAction = (): ActionLabel<'resetSelectedLabels'> => ({
    type: 'resetSelectedLabels',
});

export const selectLabelsAction = (
    ids: number[]
): ActionLabel<'selectLabels'> & { ids: number[] } => ({
    type: 'selectLabels',
    ids,
});

export const clearAction = (): ActionLabel<'clearLabels'> => ({
    type: 'clearLabels',
});

export const saveAction = (): ActionLabel<'saveLabels'> => ({
    type: 'saveLabels',
});

export const resetAction = (): ActionLabel<'resetLabels'> => ({
    type: 'resetLabels',
});

export const toggleCollapseLabelAction = (
    id: number
): ActionLabel<'toggleCollapseLabel'> & { id: number } => ({
    type: 'toggleCollapseLabel',
    id,
});

export const toggleCollapseAllLabelsAction = (
    ids: number[]
): ActionLabel<'toggleCollapseAllLabels'> & { ids: number[] } => ({
    type: 'toggleCollapseAllLabels',
    ids,
});


export const resetCollapseAllLabelsAction = (): ActionLabel<'resetCollapseAllLabels'> & {} => ({
    type: 'resetCollapseAllLabels',
});