import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { PerformanceTrainee } from '../../../constants/interfaces/PerformanceTrainee';
import { UserInterface, UserV2 } from '../../../constants/interfaces/User';
import { PractisSets, EnrollmentPractisSet } from '../../../constants/interfaces/PractisSets';
import { Draft, DraftUser } from '../../../constants/interfaces/Draft';
import { UserFeatures } from '../../../constants/interfaces/UserFeatures';
import { EnrollmentInterface } from '../../../constants/interfaces/Enrollments';
import { UserPreference } from '../../../constants/interfaces/UserPreference';

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAILURE = 'FETCH_ALL_USERS_FAILURE';
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER_DPR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_DPR_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_DPR_FAILURE';

export enum ACTIONS {
    UPDATE_USER_START = 'UPDATE_USER_START',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE',

    SEARCH_USER_INVITATIONS_START = 'SEARCH_USER_INVITATIONS_START',
    SEARCH_USER_INVITATIONS_SUCCESS = 'SEARCH_USER_INVITATIONS_SUCCESS',
    SEARCH_USER_INVITATIONS_FAILURE = 'SEARCH_USER_INVITATIONS_FAILURE',
    UPDATE_USER_INVITATION_START = 'UPDATE_USER_INVITATION_START',
    UPDATE_USER_INVITATION_SUCCESS = 'UPDATE_USER_INVITATION_SUCCESS',
    UPDATE_USER_INVITATION_FAILURE = 'UPDATE_USER_INVITATION_FAILURE',

    GET_USER_PERFORMANCE_START = 'GET_USER_PERFORMANCE_START',
    GET_USER_PERFORMANCE_SUCCESS = 'GET_USER_PERFORMANCE_SUCCESS',
    GET_USER_PERFORMANCE_FAILURE = 'GET_USER_PERFORMANCE_FAILURE',
    RESET_USER_PERFORMANCE = 'RESET_USER_PERFORMANCE',

    UPDATE_SELECTED_USER_STATE = 'UPDATE_SELECTED_USER_STATE',
    UPDATE_ALL_SELECTED_USERS_STATE = 'UPDATE_ALL_SELECTED_USERS_STATE',

    UPDATE_SELECTED_INVITATION_STATE = 'UPDATE_SELECTED_INVITATION_STATE',
    UPDATE_ALL_SELECTED_INVITATIONS_STATE = 'UPDATE_ALL_SELECTED_INVITATIONS_STATE',

    USER_LABELS_UPDATED = 'USER_LABELS_UPDATED',

    SEARCH_USER_PRACTIS_SETS_START = 'SEARCH_USER_PRACTIS_SETS_START',
    SEARCH_USER_PRACTIS_SETS_SUCCESS = 'SEARCH_USER_PRACTIS_SETS_SUCCESS',
    SEARCH_USER_PRACTIS_SETS_FAILURE = 'SEARCH_USER_PRACTIS_SETS_FAILURE',
    FETCH_PORTABLE_PRACTIS_SETS_START = 'FETCH_PORTABLE_PRACTIS_SETS_START',
    FETCH_PORTABLE_PRACTIS_SETS_SUCCESS = 'FETCH_PORTABLE_PRACTIS_SETS_SUCCESS',
    FETCH_PORTABLE_PRACTIS_SETS_FAILURE = 'FETCH_PORTABLE_PRACTIS_SETS_FAILURE',
    UPDATE_USER_PRACTIS_SETS_SUCCESS = 'UPDATE_USER_PRACTIS_SETS_SUCCESS',
    UPDATE_USER_PRACTIS_SETS_START = 'UPDATE_USER_PRACTIS_SETS_START',
    UPDATE_USER_PRACTIS_SETS_FAILURE = 'UPDATE_USER_PRACTIS_SETS_FAILURE',
    UPDATE_ALL_SELECTED_TRAINEE_PROFILE_PRACTIS_SETS_STATE = 'UPDATE_ALL_SELECTED_TRAINEE_PROFILE_PRACTIS_SETS_STATE',
    UPDATE_SELECTED_TRAINEE_PROFILE_PRACTIS_SET_STATE = 'UPDATE_SELECTED_TRAINEE_PROFILE_PRACTIS_SET_STATE',

    SEARCH_STAGING_DRAFTS = 'SEARCH_STAGING_DRAFTS',
    SEARCH_STAGING_DRAFTS_SUCCESS = 'SEARCH_STAGING_DRAFTS_SUCCESS',
    SEARCH_STAGING_DRAFTS_FAILURE = 'SEARCH_STAGING_DRAFTS_FAILURE',
    UPDATE_STAGING_DRAFTS_START = 'UPDATE_STAGING_DRAFTS_START',
    UPDATE_STAGING_DRAFTS_SUCCESS = 'UPDATE_STAGING_DRAFTS_SUCCESS',
    UPDATE_STAGING_DRAFTS_FAILURE = 'UPDATE_STAGING_DRAFTS_FAILURE',
    UPDATE_SELECTED_DRAFT_STATE = 'UPDATE_SELECTED_DRAFT_STATE',
    UPDATE_ALL_SELECTED_DRAFT_STATE = 'UPDATE_ALL_SELECTED_DRAFT_STATE',

    FETCH_CREATED_BY_USERS_START = 'FETCH_CREATED_BY_USERS_START',
    FETCH_CREATED_BY_USERS_SUCCESS = 'FETCH_CREATED_BY_USERS_SUCCESS',
    FETCH_CREATED_BY_USERS_FAILURE = 'FETCH_CREATED_BY_USERS_FAILURE',
    FETCH_EDITED_BY_USERS_START = 'FETCH_EDITED_BY_USERS_START',
    FETCH_EDITED_BY_USERS_SUCCESS = 'FETCH_EDITED_BY_USERS_SUCCESS',
    FETCH_EDITED_BY_USERS_FAILURE = 'FETCH_EDITED_BY_USERS_FAILURE',

    FETCH_USER_FEATURES_START = 'FETCH_USER_FEATURES_START',
    FETCH_USER_FEATURES_SUCCESS = 'FETCH_USER_FEATURES_SUCCESS',
    FETCH_USER_FEATURES_FAILURE = 'FETCH_USER_FEATURES_FAILURE',

    REMOVE_USER_LABEL = 'REMOVE_USER_LABEL',
    REMOVE_PENDING_USER_LABEL = 'REMOVE_PENDING_USER_LABEL',

    FETCH_USER_ALL_PRACTIS_SETS = 'FETCH_USER_ALL_PRACTIS_SETS',

    FETCH_USER_PREFERENCES_START = 'FETCH_USER_PREFERENCES_START',
    FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS',
    FETCH_USER_PREFERENCES_FAILURE = 'FETCH_USER_PREFERENCES_FAILURE',

    UPDATE_USER_PREFERENCES_START = 'UPDATE_USER_PREFERENCES_START',
    UPDATE_USER_PREFERENCES_SUCCESS = 'UPDATE_USER_PREFERENCES_SUCCESS',
    UPDATE_USER_PREFERENCES_FAILURE = 'UPDATE_USER_PREFERENCES_FAILURE',
}

export function searchUserPractisSetsStart() {
    return {
        type: ACTIONS.SEARCH_USER_PRACTIS_SETS_START as ACTIONS.SEARCH_USER_PRACTIS_SETS_START,
    };
}

export function searchUserPractisSetsSuccess(
    practisSets: ListResult<EnrollmentInterface>
) {
    return {
        type: ACTIONS.SEARCH_USER_PRACTIS_SETS_SUCCESS as ACTIONS.SEARCH_USER_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function searchUserPractisSetsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_USER_PRACTIS_SETS_FAILURE as ACTIONS.SEARCH_USER_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function fetchPortablePractisSetsStart() {
    return {
        type: ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_START as ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_START,
    };
}

export function fetchPortablePractisSetsSuccess(
    practisSets: ListResult<EnrollmentPractisSet>
) {
    return {
        type: ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_SUCCESS as ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function fetchPortablePractisSetsFailure(error: string) {
    return {
        type: ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_FAILURE as ACTIONS.FETCH_PORTABLE_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function updateTraineeProfilePractisSetCheckedState(id: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_TRAINEE_PROFILE_PRACTIS_SET_STATE as ACTIONS.UPDATE_SELECTED_TRAINEE_PROFILE_PRACTIS_SET_STATE,
        id,
    };
}

export function updateAllPractisSetsCheckedState(
    selectedPractisSetIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_TRAINEE_PROFILE_PRACTIS_SETS_STATE as ACTIONS.UPDATE_ALL_SELECTED_TRAINEE_PROFILE_PRACTIS_SETS_STATE,
        selectedPractisSetIds,
        checked,
        partial,
    };
}

export function updateUserPractisSetsStart() {
    return {
        type: ACTIONS.UPDATE_USER_PRACTIS_SETS_START as ACTIONS.UPDATE_USER_PRACTIS_SETS_START,
    };
}

export function updateUserPractisSetsSuccess(practisSets: PractisSets) {
    return {
        type: ACTIONS.UPDATE_USER_PRACTIS_SETS_SUCCESS as ACTIONS.UPDATE_USER_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function updateUserPractisSetsFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_USER_PRACTIS_SETS_FAILURE as ACTIONS.UPDATE_USER_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function fetchAllUsers() {
    return {
        type: FETCH_ALL_USERS,
    };
}

export function fetchAllUsersSuccess(data: ListResult<UserV2>) {
    return {
        type: FETCH_ALL_USERS_SUCCESS,
        data,
    };
}

export function fetchAllUsersFailure(error: any) {
    return {
        type: FETCH_ALL_USERS_FAILURE,
        error,
    };
}

export function clearAllUsers() {
    return {
        type: CLEAR_ALL_USERS,
    };
}

export function updateUserActionStart() {
    return {
        type: ACTIONS.UPDATE_USER_START as ACTIONS.UPDATE_USER_START,
    };
}

export function updateUserActionSuccess(
    user: UserInterface,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_USER_SUCCESS as ACTIONS.UPDATE_USER_SUCCESS,
        user,
        updateType,
    };
}

export function updateUserActionFailure(error: any) {
    return {
        type: ACTIONS.UPDATE_USER_FAILURE as ACTIONS.UPDATE_USER_FAILURE,
        error,
    };
}

export function fetchUserAction() {
    return {
        type: FETCH_USER,
    };
}

export function fetchUserSuccess(data: UserV2) {
    return {
        type: FETCH_USER_SUCCESS,
        data,
    };
}

export function fetchUserFailure(error: string) {
    return {
        type: FETCH_USER_FAILURE,
        error,
    };
}

export function updateUserAction() {
    return {
        type: UPDATE_USER,
    };
}

export function updateUserSuccess(data: any) {
    return {
        type: UPDATE_USER_SUCCESS,
        data,
    };
}

export function updateUserFailure(error: any) {
    return {
        type: UPDATE_USER_FAILURE,
        error,
    };
}

export function searchInvitationsStart() {
    return {
        type: ACTIONS.SEARCH_USER_INVITATIONS_START as ACTIONS.SEARCH_USER_INVITATIONS_START,
    };
}

export function searchInvitationsSuccess(users: ListResult<UserV2>) {
    return {
        type: ACTIONS.SEARCH_USER_INVITATIONS_SUCCESS as ACTIONS.SEARCH_USER_INVITATIONS_SUCCESS,
        users,
    };
}

export function searchInvitationsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_USER_INVITATIONS_FAILURE as ACTIONS.SEARCH_USER_INVITATIONS_FAILURE,
        error,
    };
}

export function updateInvitationStart() {
    return {
        type: ACTIONS.UPDATE_USER_INVITATION_START as ACTIONS.UPDATE_USER_INVITATION_START,
    };
}

export function updateInvitationSuccess(
    invitation: { id: number },
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_USER_INVITATION_SUCCESS as ACTIONS.UPDATE_USER_INVITATION_SUCCESS,
        invitation,
        updateType,
    };
}

export function updateInvitationFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_USER_INVITATION_FAILURE as ACTIONS.UPDATE_USER_INVITATION_FAILURE,
        error,
    };
}

export function getUserPerformanceStart() {
    return {
        type: ACTIONS.GET_USER_PERFORMANCE_START as ACTIONS.GET_USER_PERFORMANCE_START,
    };
}

export function getUserPerformanceSuccess(trainee: PerformanceTrainee) {
    return {
        type: ACTIONS.GET_USER_PERFORMANCE_SUCCESS as ACTIONS.GET_USER_PERFORMANCE_SUCCESS,
        trainee,
    };
}

export function getUserPerformanceFailure(error: string) {
    return {
        type: ACTIONS.GET_USER_PERFORMANCE_FAILURE as ACTIONS.GET_USER_PERFORMANCE_FAILURE,
        error,
    };
}

export function resetUserPerformanceAction() {
    return {
        type: ACTIONS.RESET_USER_PERFORMANCE as ACTIONS.RESET_USER_PERFORMANCE,
    };
}

export function fetchUserAllPractisSetsSuccess(
    data: ListResult<EnrollmentPractisSet>
) {
    return {
        type: ACTIONS.FETCH_USER_ALL_PRACTIS_SETS as ACTIONS.FETCH_USER_ALL_PRACTIS_SETS,
        data,
    };
}

export function updateSelectedUserState(userId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_USER_STATE as ACTIONS.UPDATE_SELECTED_USER_STATE,
        userId,
    };
}

export function updateAllSelectedUsersState(
    userIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_USERS_STATE as ACTIONS.UPDATE_ALL_SELECTED_USERS_STATE,
        userIds,
        checked,
        partial,
    };
}

export function updateSelectedInvitationState(invitationId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_INVITATION_STATE as ACTIONS.UPDATE_SELECTED_INVITATION_STATE,
        invitationId,
    };
}

export function updateAllSelectedInvitationsState(
    invitationIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_INVITATIONS_STATE as ACTIONS.UPDATE_ALL_SELECTED_INVITATIONS_STATE,
        invitationIds,
        checked,
        partial,
    };
}

export function userLabelsUpdatedAction() {
    return {
        type: ACTIONS.USER_LABELS_UPDATED as ACTIONS.USER_LABELS_UPDATED,
    };
}

export function fetchAllDrafts() {
    return {
        type: ACTIONS.SEARCH_STAGING_DRAFTS as ACTIONS.SEARCH_STAGING_DRAFTS,
    };
}

export function fetchAllDraftsSuccess(data: any) {
    return {
        type: ACTIONS.SEARCH_STAGING_DRAFTS_SUCCESS as ACTIONS.SEARCH_STAGING_DRAFTS_SUCCESS,
        data,
    };
}

export function fetchAllDraftsFailure(error: any) {
    return {
        type: ACTIONS.SEARCH_STAGING_DRAFTS_FAILURE as ACTIONS.SEARCH_STAGING_DRAFTS_FAILURE,
        error,
    };
}

export function updateDraftsStart() {
    return {
        type: ACTIONS.UPDATE_STAGING_DRAFTS_START as ACTIONS.UPDATE_STAGING_DRAFTS_START,
    };
}

export function updateDraftsSuccess(
    draft: Draft,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_STAGING_DRAFTS_SUCCESS as ACTIONS.UPDATE_STAGING_DRAFTS_SUCCESS,
        draft,
        updateType,
    };
}

export function updateDraftsFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_STAGING_DRAFTS_FAILURE as ACTIONS.UPDATE_STAGING_DRAFTS_FAILURE,
        error,
    };
}

export function updateSelectedDraftState(draftId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_DRAFT_STATE as ACTIONS.UPDATE_SELECTED_DRAFT_STATE,
        draftId,
    };
}

export function updateAllSelectedDraftState(
    draftIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_DRAFT_STATE as ACTIONS.UPDATE_ALL_SELECTED_DRAFT_STATE,
        draftIds,
        checked,
        partial,
    };
}

export function fetchCreatedByUsersStart() {
    return {
        type: ACTIONS.FETCH_CREATED_BY_USERS_START as ACTIONS.FETCH_CREATED_BY_USERS_START,
    };
}

export function fetchCreatedByUsersSuccess(data: Partial<DraftUser>[]) {
    return {
        type: ACTIONS.FETCH_CREATED_BY_USERS_SUCCESS as ACTIONS.FETCH_CREATED_BY_USERS_SUCCESS,
        data,
    };
}

export function fetchCreatedByUsersFailure(error: any) {
    return {
        type: ACTIONS.FETCH_CREATED_BY_USERS_FAILURE as ACTIONS.FETCH_CREATED_BY_USERS_FAILURE,
        error,
    };
}

export function fetchEditedByUsersStart() {
    return {
        type: ACTIONS.FETCH_EDITED_BY_USERS_START as ACTIONS.FETCH_EDITED_BY_USERS_START,
    };
}

export function fetchEditedByUsersSuccess(data: Partial<DraftUser>[]) {
    return {
        type: ACTIONS.FETCH_EDITED_BY_USERS_SUCCESS as ACTIONS.FETCH_EDITED_BY_USERS_SUCCESS,
        data,
    };
}

export function fetchEditedByUsersFailure(error: any) {
    return {
        type: ACTIONS.FETCH_EDITED_BY_USERS_FAILURE as ACTIONS.FETCH_EDITED_BY_USERS_FAILURE,
        error,
    };
}

export function fetchUserFeaturesStart() {
    return {
        type: ACTIONS.FETCH_USER_FEATURES_START as ACTIONS.FETCH_USER_FEATURES_START
    };
}

export function fetchUserFeaturesSuccess(data: UserFeatures) {
    return {
        type: ACTIONS.FETCH_USER_FEATURES_SUCCESS as ACTIONS.FETCH_USER_FEATURES_SUCCESS,
        data
    };
}

export function fetchUserFeaturesFailure(error: string) {
    return {
        type: ACTIONS.FETCH_USER_FEATURES_FAILURE as ACTIONS.FETCH_USER_FEATURES_FAILURE,
        error
    };
}

export function removeUserLabel(userId: number, labelId: number) {
    return {
        type: ACTIONS.REMOVE_USER_LABEL as ACTIONS.REMOVE_USER_LABEL,
        userId,
        labelId
    };
}

export function removePendingUserLabel(userId: number, labelId: number) {
    return {
        type: ACTIONS.REMOVE_PENDING_USER_LABEL as ACTIONS.REMOVE_PENDING_USER_LABEL,
        userId,
        labelId
    };
}

export function fetchUserPreferencesStart() {
    return {
        type: ACTIONS.FETCH_USER_PREFERENCES_START as ACTIONS.FETCH_USER_PREFERENCES_START
    };
}

export function fetchUserPreferencesSuccess(data: UserPreference[]) {
    return {
        type: ACTIONS.FETCH_USER_PREFERENCES_SUCCESS as ACTIONS.FETCH_USER_PREFERENCES_SUCCESS,
        data
    };
}

export function fetchUserPreferencesFailure(error: string) {
    return {
        type: ACTIONS.FETCH_USER_PREFERENCES_FAILURE as ACTIONS.FETCH_USER_PREFERENCES_FAILURE,
        error
    };
}

export function updateUserPreferencesStart(data: { key: string, value: unknown }[]) {
    return {
        type: ACTIONS.UPDATE_USER_PREFERENCES_START as ACTIONS.UPDATE_USER_PREFERENCES_START,
        data
    };
}

export function updateUserPreferencesSuccess(keys: string[]) {
    return {
        type: ACTIONS.UPDATE_USER_PREFERENCES_SUCCESS as ACTIONS.UPDATE_USER_PREFERENCES_SUCCESS,
        keys
    };
}

export function updateUserPreferencesFailure(keys: string[]) {
    return {
        type: ACTIONS.UPDATE_USER_PREFERENCES_FAILURE as ACTIONS.UPDATE_USER_PREFERENCES_FAILURE,
        keys
    };
}