import { EditModeValues } from "../../constants/enums/EditModeValues";

export const buildPageTitle = (
    entityName: 'Challenge' | 'Scenario' | 'Practis Set',
    editMode: EditModeValues,
    isNew: boolean) => {
        return isNew
            ? `Add New ${entityName}`
            : editMode === EditModeValues.EDIT
                ? `Edit ${entityName}`
                : `View ${entityName}`;
}