import styled from 'styled-components';

import Input from '../../../input/view';
import Search from '../../../../icons/Search';

export const Root = styled.div`
    flex: 3;
    height: 40px;
    max-height: 100%;
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    background: ${props => props.theme.Colors.whiteFive};
    border: 1px solid ${props => props.theme.Colors.whiteFive};

    &:focus-within {
        border: 1px solid ${props => props.theme.Colors.cloudyBlue};
    }
`;

export const InputWrapper = styled.div`
    flex: 1;
`;
export const StyledInput = styled(Input)`
    padding: 0;
`;
export const Icon = styled.div`
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px 0 16px;
`;
export const SearchIcon = styled(Search)<{ disabled?: boolean }>`
    width: 12px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

