import { useEffect, useRef, useState } from 'react';
import { getConfig } from '../../../tools/config';

interface WebSocketHook {
  sendMessage: (message: Message) => void;
  closeConnection: () => void;
  response: string;
}

type Message = {
    event: 'authenticate' | 'watch',
    data: string
}

const useWebSocket = (): WebSocketHook => {
  const [response, setResponse] = useState<string>('');
  const socketRef = useRef<WebSocket | null>(null);
  const currentConnection = useRef<number | null>(null);

  const config = getConfig();
  const url = config.API_SOCKET_ENDPOINT;

  const createWebSocket = () => {
    const newSocket = new WebSocket(url);
    socketRef.current = newSocket;

    newSocket.addEventListener('open', () => {
      setResponse('opened');
      if (currentConnection.current !== null) {
        currentConnection.current = null;
      }
    });

    newSocket.addEventListener('message', (event) => {
      setResponse(event.data);
    });

    newSocket.addEventListener('close', (event) => {
      if (!currentConnection.current) {
        currentConnection.current = 1
          createWebSocket();
      }
    });
  };

  const closeConnection = () => {
    if (currentConnection.current !== null) {
      currentConnection.current = null;
    }
    if (socketRef.current !== null) {
      socketRef.current.close();
    }
  }

  useEffect(() => {
    createWebSocket();

    return () => {
      closeConnection()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = (message: Message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    }
  };

  return { sendMessage, response, closeConnection };
};

export default useWebSocket;

