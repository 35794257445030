import styled from 'styled-components';

export const Container = styled.div<{ isFocused: boolean }>`
    position: relative;

    .voice-sample-textbox {
        border-radius: 4px;
        padding: 7px 11px;
        background: var(--ps-grey-3);
        border: 1px solid ${props => props.isFocused ? 'var(--ps-grey-2)' : 'var(--ps-grey-3)'};

        textarea {
            padding: 0;
            min-height: 108px;
            font-size: 13px;
            line-height: 18px!important;
            background: transparent!important;;

            &:disabled {
                color: var(--ps-grey-2);
            }
        }
    }
`;

export const PlayButton = styled.div<{ isActive: boolean }>`
    position: absolute;
    left: 12px;
    bottom: 8px;
    color: ${props => props.isActive ? 'var(--ps-blue-main)' : 'var(--ps-grey-2)'};
    cursor: ${props => props.isActive && props.onClick ? 'pointer' : 'default'};
    display: flex;
    align-items: center;

    span {
        padding-left: 4px;
    }
`