import { createContext } from 'react';

import { AppState } from '../../../../../../store/reducers';
import { DEFAULT_PRACTIS_SETS_SCOPE, Scope } from '../scopes';

export type WithPractisSetsContextProps<T = keyof AppState, K = Scope> = {
    reducerName?: T;
    scope?: K;
};

export const WithPractisSetsContext = createContext<WithPractisSetsContextProps>(
    {
        reducerName: 'portablePractisSets',
        scope: DEFAULT_PRACTIS_SETS_SCOPE,
    }
);
