import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const LogoNav = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g clip-path="url(#shsp2nykna)" fill-rule="evenodd" clip-rule="evenodd" fill="currentColor">
                    <path d="M0 14.25C0 15.216.784 16 1.75 16h12.5A1.75 1.75 0 0 0 16 14.25V1.75A1.75 1.75 0 0 0 14.25 0H1.75A1.75 1.75 0 0 0 0 1.75v12.5zm1.75.25a.25.25 0 0 1-.25-.25V1.75a.25.25 0 0 1 .25-.25h12.5a.25.25 0 0 1 .25.25v12.5a.25.25 0 0 1-.25.25H1.75z"/>
                    <path d="M4 7.18C4 4.873 5.79 3 8 3s4 1.872 4 4.18c0 2.31-1.79 4.182-4 4.182H6.022L4 13.108V7.18zm6.013-.004c0-1.19-.913-2.154-2.038-2.154-1.126 0-2.039.964-2.039 2.154v2.155h2.039c1.114 0 2.02-.946 2.037-2.12v-.035z"/>
                </g>
                <defs>
                    <clipPath id="shsp2nykna">
                        <path fill="#fff" transform="rotate(-90 8 8)" d="M0 0h16v16H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default LogoNav;
