/* eslint-disable no-useless-escape */
export const validEmailTLDs = [
    '.com',
    '.net',
    '.org',
    '.edu',
    '.gov',
    '.mil',
    '.aero',
    '.asia',
    '.biz',
    '.cat',
    '.coop',
    '.info',
    '.jobs',
    '.mobi',
    '.museum',
    '.name',
    '.pro',
    '.tel',
    '.travel',
    '.ac',
    '.ad',
    '.ae',
    '.af',
    '.ag',
    '.ai',
    '.al',
    '.am',
    '.an',
    '.ao',
    '.aq',
    '.ar',
    '.as',
    '.at',
    '.au',
    '.aw',
    '.ax',
    '.az',
    '.ba',
    '.bb',
    '.bd',
    '.be',
    '.bf',
    '.bg',
    '.bh',
    '.bi',
    '.bj',
    '.bm',
    '.bn',
    '.bo',
    '.br',
    '.bs',
    '.bt',
    '.bv',
    '.bw',
    '.by',
    '.bz',
    '.ca',
    '.cc',
    '.cd',
    '.cf',
    '.cg',
    '.ch',
    '.ci',
    '.ck',
    '.cl',
    '.cm',
    '.cn',
    '.co',
    '.cr',
    '.cu',
    '.cv',
    '.cx',
    '.cy',
    '.cz',
    '.de',
    '.dj',
    '.dk',
    '.dm',
    '.do',
    '.dz',
    '.ec',
    '.ee',
    '.eg',
    '.er',
    '.es',
    '.et',
    '.eu',
    '.fi',
    '.fj',
    '.fk',
    '.fm',
    '.fo',
    '.fr',
    '.ga',
    '.gb',
    '.gd',
    '.ge',
    '.gf',
    '.gg',
    '.gh',
    '.gi',
    '.gl',
    '.gm',
    '.gn',
    '.gp',
    '.gq',
    '.gr',
    '.gs',
    '.gt',
    '.gu',
    '.gw',
    '.gy',
    '.hk',
    '.hm',
    '.hn',
    '.hr',
    '.ht',
    '.hu',
    '.id',
    '.ie',
    '.il',
    '.im',
    '.in',
    '.io',
    '.iq',
    '.ir',
    '.is',
    '.it',
    '.je',
    '.jm',
    '.jo',
    '.jp',
    '.ke',
    '.kg',
    '.kh',
    '.ki',
    '.km',
    '.kn',
    '.kp',
    '.kr',
    '.kw',
    '.ky',
    '.kz',
    '.la',
    '.lb',
    '.lc',
    '.li',
    '.lk',
    '.lr',
    '.ls',
    '.lt',
    '.lu',
    '.lv',
    '.ly',
    '.ma',
    '.mc',
    '.md',
    '.me',
    '.mg',
    '.mh',
    '.mk',
    '.ml',
    '.mm',
    '.mn',
    '.mo',
    '.mp',
    '.mq',
    '.mr',
    '.ms',
    '.mt',
    '.mu',
    '.mv',
    '.mw',
    '.mx',
    '.my',
    '.mz',
    '.na',
    '.nc',
    '.ne',
    '.nf',
    '.ng',
    '.ni',
    '.nl',
    '.no',
    '.np',
    '.nr',
    '.nu',
    '.nz',
    '.om',
    '.pa',
    '.pe',
    '.pf',
    '.pg',
    '.ph',
    '.pk',
    '.pl',
    '.pm',
    '.pn',
    '.pr',
    '.ps',
    '.pt',
    '.pw',
    '.py',
    '.qa',
    '.re',
    '.ro',
    '.rs',
    '.ru',
    '.rw',
    '.sa',
    '.sb',
    '.sc',
    '.sd',
    '.se',
    '.sg',
    '.sh',
    '.si',
    '.sj',
    '.sk',
    '.uk',
    '.us',
];

export const validEmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
