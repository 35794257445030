import { find } from 'lodash';

import { SearchLibraryParams } from '../../../api/types';
import { SearchParams } from "../../../constants/interfaces/filters";
import { getSearchSortingValue } from "../../../helpers/functions/search-params-helpers";

export enum LibraryItemsStatus {
    ACTIVE = 'ACTIVE',
    DRAFT = 'DRAFT',
    ARCHIVED = 'ARCHIVED',
    DELETED = 'DELETED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
}

const libraryItemsStatusNames = {
    [LibraryItemsStatus.ACTIVE as string]: 'Active',
    [LibraryItemsStatus.DRAFT as string]: 'Draft',
    [LibraryItemsStatus.ARCHIVED as string]: 'Archived',
    [LibraryItemsStatus.DELETED as string]: 'Deleted',
    [LibraryItemsStatus.IN_PROGRESS as string]: 'In Progress',
    [LibraryItemsStatus.COMPLETED as string]: 'Completed',
    [LibraryItemsStatus.PENDING as string]: 'Pending',
};

export function libraryItemsStatusName(status?: string): string {
    return (status && libraryItemsStatusNames[status]) || status || '';
}

export const LIBRARY_STATUS = {
    ACTIVE: 'active',
    DRAFT: 'draft',
    ARCHIVED: 'archived',
};

export const LIBRARY_STATUS_NAMES = {
    [LIBRARY_STATUS.ACTIVE]: 'Active',
    [LIBRARY_STATUS.DRAFT]: 'Draft',
    [LIBRARY_STATUS.ARCHIVED]: 'Archived',
};

export const LIBRARY_STATUS_ITEMS: Array<{ name: string; value: string }> = [
    {
        name: LIBRARY_STATUS_NAMES[LIBRARY_STATUS.ACTIVE],
        value: LIBRARY_STATUS.ACTIVE,
    },
    {
        name: LIBRARY_STATUS_NAMES[LIBRARY_STATUS.DRAFT],
        value: LIBRARY_STATUS.DRAFT,
    },
    {
        name: LIBRARY_STATUS_NAMES[LIBRARY_STATUS.ARCHIVED],
        value: LIBRARY_STATUS.ARCHIVED,
    },
];

/**
 * @function createSearchLibraryParams
 * @param { SearchParams } searhParams
 * @returns { SearchLibraryParams }
 */
 export const createSearchLibraryParams = (
    searchParams: SearchParams
): SearchLibraryParams => {
    return {
        limit: searchParams.limit ?? 20,
        offset: searchParams.offset ?? 0,
        query: searchParams.searchTerm,
        sort: getSearchSortingValue(searchParams.orderBy, ''),
        status: (
            find(searchParams?.filters, { field: 'status' })?.value as string[]
        )
            ?.map(status => status.toUpperCase())
            ?.join(','),
        labels: searchParams?.labelIDs?.join(','),
        companies: searchParams.companyId?.toString()
    };
};