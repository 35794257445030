import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Star = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow, viewBox }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox={!!viewBox ? viewBox : '-1 -1 10 10'}
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M4 6L1.649 7.236 2.098 4.618 0.196 2.764 2.824 2.382 4 0 5.176 2.382 7.804 2.764 5.902 4.618 6.351 7.236z"
                    transform="translate(-276 -296) translate(276 296)"
                />
            </svg>
        );
    }
);

export default Star;
