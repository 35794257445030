import authBackground from '../../../assets/images/auth-background.png';
import authBackground2x from '../../../assets/images/auth-background2x.png';
import authBackground3x from '../../../assets/images/auth-background3x.png';
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { Variables } from '../../../theme/variables';
import AuthLogo from '../AuthLogo';
import { LoginAuthWrapperProps } from './types';
import {
    BackgroundWrapper,
    BackgroundImage,
    PageContainer,
    LogoContainer,
    PageContent,
    Footer,
    FooterLink,
    FooterLinkSeparator,
} from './styles';

function LoginAuthWrapperView({
    desktopMinWidth = 1024,
    heightToShowScroll = 500,
    contentWidth,
    hideLogo,
    hideFooter,
    children
}: LoginAuthWrapperProps) {
    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.small;

    return <>
        <BackgroundWrapper>
            <BackgroundImage
                src={authBackground}
                srcSet={`${authBackground2x} 2x, ${authBackground3x} 3x`}
            />
        </BackgroundWrapper>
        <PageContainer>
            <LogoContainer desktopMinWidth={desktopMinWidth} heightToShowScroll={heightToShowScroll}>
                {!hideLogo && <AuthLogo width={isMobile ? 110 : 141} marginBottom={0} />}
            </LogoContainer>
            <PageContent
                desktopMinWidth={desktopMinWidth}
                heightToShowScroll={heightToShowScroll}
                contentWidth={contentWidth}
            >
                {children}
                {!hideFooter && <Footer>
                    <FooterLink
                        href="https://www.gopractis.com/document-privacy-policy"
                        target="_blank"
                        data-test="privacy-policy-link"
                    >
                        Privacy Policy
                    </FooterLink>
                    <FooterLinkSeparator>•</FooterLinkSeparator>
                    <FooterLink
                        href="https://www.gopractis.com/document-terms-of-use"
                        target="_blank"
                        data-test="terms-of-use-link"
                    >
                        Terms of Use
                    </FooterLink>
                    <FooterLinkSeparator>•</FooterLinkSeparator>
                    <FooterLink
                        href="https://www.gopractis.com/document-terms-of-service"
                        target="_blank"
                        data-test="terms-of-service-link"
                    >
                        Terms of Service
                    </FooterLink>
                </Footer>}
            </PageContent>
        </PageContainer>
    </>
}

export default LoginAuthWrapperView;