import React, { FC } from 'react';
import ROUTES from '../../../routes/routes';
import Navigation from '../../../ui/components/Navigation/Navigation';
import styled from 'styled-components';

const StyledSubmissionsNavigationContainer = styled.div`
  *:before{
      max-width: 107px;
  }
`;


const SubmissionsNavigation: FC<{
    notReviewedCount?: number;
    dataTest?: string;
}> = ({
    notReviewedCount,
    dataTest
})  => {
    return (
        <StyledSubmissionsNavigationContainer>
            <Navigation
                menu={[
                    {
                        key: 'accuracytests',
                        title: 'Accuracy Tests',
                        url: ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.ALL,
                    },
                    {
                        key: 'challenges',
                        title: 'Challenges',
                        url: ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL,
                        notReviewedCount: notReviewedCount
                    }
                ]}
                dataTest={dataTest}
            />
        </StyledSubmissionsNavigationContainer>
    );
};

export const SubmissionsNavigationContainer: FC<{
    notReviewedCount?: number;
    dataTest?: string;
}> = ({
    notReviewedCount,
    dataTest
})  => {
    return <SubmissionsNavigation notReviewedCount={notReviewedCount} dataTest={dataTest} />;
};

export default SubmissionsNavigationContainer;