import {
    USER_PROFILE,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAILURE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,
    RESET_PROFILE_INFORMATION,
} from './actions';
import { AppState } from '../../../store/reducers';
import { UserProfile } from '../../../constants/interfaces/User';
import { ACTIONS } from '../../../features/labels/store/actions';
import { toggleItemInArray } from '../../../helpers/functions/array-helpers';

export interface UserProfileState {
    info?: UserProfile;
    isLoading?: boolean;
    error?: any;
}

const initialState: any = {
    info: {},
    isLoading: false,
    error: null,
};

const userProfileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_PROFILE:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case USER_PROFILE_SUCCESS:
            return {
                info: {
                    ...state.info,
                    ...action.data,
                },
                isLoading: false,
                error: null,
            };
        case USER_PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                error: null,
            };
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                info: {
                    ...state.info,
                    ...action.data,
                },
                error: null,
            };
        case UPDATE_USER_PROFILE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case RESET_PROFILE_INFORMATION:
            return {
                ...initialState,
            };
        case ACTIONS.SAVE_LABEL_FILTERS_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    labelFilters: action.filters,
                },
            };
        case ACTIONS.SELECT_LABEL_FILTER: {
            let selectedLabels = toggleItemInArray(
                state.info.searchFilter,
                action.labelId
            );

            return {
                ...state,
                info: {
                    ...state.info,
                    searchFilter: selectedLabels,
                },
            };
        }
        case ACTIONS.CLEAR_LABEL_FILTERS: {
            return {
                ...state,
                info: {
                    ...state.info,
                    searchFilter: [],
                },
            };
        }
        default:
            return state;
    }
};

export const getProfileState = (state: AppState) => state.profile.info;
export const getProfileLoading = (state: AppState) => state.profile.isLoading;
export const getProfileError = (state: AppState) => state.profile.error;

export default userProfileReducer;
