import { Button } from "../../../../ui/components/Button"
import { ModalWithCross } from "../../../../ui/components/ModalWithCross"
import { ModalBody, ModalHeader, ModalText } from "../styles"

function RoleplayInfoModal({onClose, dataTest} : {dataTest: string, onClose: () => void} ) {

    return <ModalWithCross
        onClose={() => {}}
        width={400}
        dataTest={dataTest}
        hideCross={true}
    >
        <ModalBody data-test="modal-body">
            <ModalHeader data-test="modal-header">Disclaimer: Roleplay AI</ModalHeader>
            <ModalText data-test="modal-text">Please note, these roleplays are generated by AI and may contain inaccuracies or misleading information. The Roleplay AI is continuously learning and enhancing, aiming to provide more realistic and effective sales situations over time.</ModalText>
            <Button variant="primary" action={onClose} dataTest="got-it-button">Got it</Button>
        </ModalBody>
    </ModalWithCross>
}

export default RoleplayInfoModal;