import { PaginationResult } from '../../../../constants/interfaces/PaginationResult';
import { DraftUser } from '../../../../constants/interfaces/Draft';

export enum ACTIONS {
    SEARCH_DRAFT_USERS_START = 'SEARCH_DRAFT_USERS_START',
    SEARCH_DRAFT_USERS_SUCCESS = 'SEARCH_DRAFT_USERS_SUCCESS',
    SEARCH_DRAFT_USERS_FAILURE = 'SEARCH_DRAFT_USERS_FAILURE',
    MODIFY_DRAFT_USERS = 'MODIFY_DRAFT_USERS',
    MODIFY_DRAFT_USERS_WITHOUT_VALIDATION = 'MODIFY_DRAFT_USERS_WITHOUT_VALIDATION',
    RESET_DRAFTS_PAGE = 'RESET_DRAFTS_PAGE',

    UPDATE_DRAFT_USERS_START = 'UPDATE_DRAFT_USERS_START',
    UPDATE_DRAFT_USERS_SUCCESS = 'UPDATE_DRAFT_USERS_SUCCESS',
    UPDATE_DRAFT_USERS_FAILURE = 'UPDATE_DRAFT_USERS_FAILURE',
}

export function searchDraftUsersStart() {
    return {
        type: ACTIONS.SEARCH_DRAFT_USERS_START as ACTIONS.SEARCH_DRAFT_USERS_START,
    };
}

export function searchDraftUsersSuccess(data: PaginationResult<DraftUser>) {
    return {
        type: ACTIONS.SEARCH_DRAFT_USERS_SUCCESS as ACTIONS.SEARCH_DRAFT_USERS_SUCCESS,
        data,
    };
}

export function searchDraftUsersFailure(error: any) {
    return {
        type: ACTIONS.SEARCH_DRAFT_USERS_FAILURE as ACTIONS.SEARCH_DRAFT_USERS_FAILURE,
        error,
    };
}

export function modifyDraftUsersAction(data: PaginationResult<DraftUser>) {
    return {
        type: ACTIONS.MODIFY_DRAFT_USERS as ACTIONS.MODIFY_DRAFT_USERS,
        data,
    };
}

export function modifyDraftUsersWithoutValidationAction(
    data: PaginationResult<DraftUser>
) {
    return {
        type: ACTIONS.MODIFY_DRAFT_USERS_WITHOUT_VALIDATION as ACTIONS.MODIFY_DRAFT_USERS_WITHOUT_VALIDATION,
        data,
    };
}

export function resetDraftsPageAction() {
    return {
        type: ACTIONS.RESET_DRAFTS_PAGE as ACTIONS.RESET_DRAFTS_PAGE,
    };
}

export function updateDraftUsersStart() {
    return {
        type: ACTIONS.UPDATE_DRAFT_USERS_START as ACTIONS.UPDATE_DRAFT_USERS_START,
    };
}

export function updateDraftUsersSuccess(
    data: Partial<DraftUser>,
    updateType:
        | 'draft-created'
        | 'draft-updated'
        | 'draft-deleted'
        | 'user-invited'
) {
    return {
        type: ACTIONS.UPDATE_DRAFT_USERS_SUCCESS as ACTIONS.UPDATE_DRAFT_USERS_SUCCESS,
        data,
        updateType,
    };
}

export function updateDraftUsersFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_DRAFT_USERS_FAILURE as ACTIONS.UPDATE_DRAFT_USERS_FAILURE,
        error,
    };
}
