import React from 'react';
import Scenarios from './Scenarios/Scenarios';
import PractisSets from './PractisSets/PractisSets';
import Challenges from './Challenges/Challenges';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRouter';
import ROUTES from '../../../routes/routes';

const Library = () => (
    <Switch>
        <Redirect
            from={ROUTES.LIBRARY}
            exact
            to={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL}
        />
        <PrivateRoute
            path={ROUTES.LIBRARY_SETTINGS.PRACTISSETS.ALL}
            component={PractisSets}
        />
        <PrivateRoute
            path={ROUTES.LIBRARY_SETTINGS.SCENARIOS.ALL}
            component={Scenarios}
        />
        <PrivateRoute
            path={ROUTES.LIBRARY_SETTINGS.CHALLENGES.ALL}
            component={Challenges}
        />
    </Switch>
);

export default Library;
