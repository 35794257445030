import { FC, useState } from 'react';
import { useCalculateDeletedLabels } from '../../../../labels/tools';
import { Button } from '../../../../../ui/components/Button';
import { Fieldset } from '../../../../../ui/components/Fieldset';
import { FilterLabels } from './components/FilterLabels/FilterLabels';
import FilterPractisSets from './components/FilterPractisSets/FilterPractisSets';
import {
    useCalculateDeletedEnrollments,
    calculateCreatedUpdatedEnrollmentIds,
} from '../../../../portablePractisSets/tools';
import { usePractisSetsState } from '../../../../portablePractisSets/store/hors/withPractisSets/states';
import { useLabelsState } from '../../../../portableLabels/store/hors/withLabels/states';
import FilterTeams from './components/FilterTeams/FilterTeams';
import { useTeamsState } from '../../../../portableTeams/store/hors/withTeams/states';
import { useCalculateDeletedTeams } from '../../../../portableTeams/tools';
import { PerformanceTrainee } from '../../../../../constants/interfaces/PerformanceTrainee';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../../pages/UserProfile/store/reducers';
import {
    isCompanyAdminRole,
    isPractisAdminRole,
    isTeamLeader,
} from '../../../../../constants/enums';
import { useUserPerformanceState } from '../../../../../features/users/store/states';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../../constants/interfaces/DueDates';
import {
    Actions,
    ButtonContainer,
    Container,
    LabelsContainer,
    PractisSetsContainer,
    TeamsContainer,
    Wrapper,
} from './styles';
import { EnrollmentsDueDateType } from '../../../../../api/enrollments/types';

export const AssignFilters: FC<{
    user?: PerformanceTrainee;
    assignFilters(
        assignedLabels: number[],
        deletedLabels: number[],
        assignedPractisSets: PractisSetWithDueDate[],
        deletedEnrollmentIds: number[],
        assignedTeams: number[],
        deletedTeams: number[],
        updatedEnrollments: EnrollmentsDueDateType[]
    ): Promise<boolean>;
    onSuccessApply: (isCancel?: boolean) => void;
}> = ({ user, assignFilters, onSuccessApply }) => {
    const [saving, setSaving] = useState(false);

    const { selected: selectedLabels } = useLabelsState();
    const trainee = useUserPerformanceState();

    const selectedPractisSets: PractisSetWithDueDate[] =
        trainee?._practisSets?.assignFilters?.data?.items?.map(practisSet => ({
            practisSetId: practisSet.id ?? 0,
            dueDate: practisSet?.dueDate
                ? dayjs(practisSet.dueDate).format(DATE_FORMAT)
                : null,
        })) ?? [];

    const { selected: selectedPractisSetsState } = usePractisSetsState();
    const { selected: selectedTeams } = useTeamsState();
    const getDeletedLabels = useCalculateDeletedLabels();
    const getDeletedEnrollmentIds = useCalculateDeletedEnrollments();

    const getDeletedTeams = useCalculateDeletedTeams();
    const [initialPractisSets, setInitialPractisSets] =
        useState<PractisSetWithDueDate[]>(selectedPractisSets);
    const profile = useSelector(getProfileState);

    const handleApplyFilters = () => {
        const assignedLabels = selectedLabels.filter(x => x > 0);
        const deletedLabels = getDeletedLabels(selectedLabels);

        const deletedEnrollmentIds = getDeletedEnrollmentIds(
            trainee?._practisSets?.assignFilters?.data?.items, 
            selectedPractisSetsState.map(
                practisSetWithDueDate => practisSetWithDueDate.practisSetId
            ),
            initialPractisSets.map(
                practisSetWithDueDate => practisSetWithDueDate.practisSetId
        ));

        const { addedPractisSets, updatedEnrollments } = calculateCreatedUpdatedEnrollmentIds(
            trainee?._practisSets?.assignFilters?.data?.items,
            selectedPractisSetsState,
            initialPractisSets);
        
        const assignedTeams = selectedTeams.filter(x => x > 0);
        const initialTeamIds = user?.teams.map((teamId: number) => teamId) || [];
        const deletedTeams = getDeletedTeams(selectedTeams, initialTeamIds);
        setSaving(true);

        assignFilters(
            assignedLabels,
            deletedLabels,
            addedPractisSets,
            deletedEnrollmentIds,
            assignedTeams,
            deletedTeams,
            updatedEnrollments
        ).then(() => {
            onSuccessApply();
            setSaving(false);
        });
    };

    return (
        <Container>
            <Wrapper>
                {(!isTeamLeader(profile?.role?.name) ||
                    isCompanyAdminRole(profile?.role?.name)) &&
                    !isPractisAdminRole(user?.role?.name) && (
                        <Fieldset
                            title={'Teams'}
                            dataTest="user-profile-assign-teams"
                        >
                            <TeamsContainer>
                                <FilterTeams
                                    user={user}
                                    setInitialTeams={true}
                                    showDefaultMembers={true}
                                />
                            </TeamsContainer>
                        </Fieldset>
                    )}
                <Fieldset
                    title={'Practis Sets'}
                    dataTest="user-profile-assign-practis-sets"
                >
                    <PractisSetsContainer>
                        <FilterPractisSets
                            practisSetDueDates={selectedPractisSets}
                            setInitialPractisSets={setInitialPractisSets}
                        />
                    </PractisSetsContainer>
                </Fieldset>
                <Fieldset
                    title={'Labels'}
                    dataTest="labels-section-title"
                >
                    <LabelsContainer>
                        <FilterLabels user={user} />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <ButtonContainer>
                    <Button
                        label={'Cancel'}
                        variant={'inverse'}
                        action={() => onSuccessApply(true)}
                        width={'112px'}
                        disabled={saving}
                    />
                </ButtonContainer>
                <Button
                    label={'Apply'}
                    action={handleApplyFilters}
                    width={'112px'}
                    loading={saving}
                />
            </Actions>
        </Container>
    );
};
