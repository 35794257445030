import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { getPractisSetState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import {
    PractisSetContent,
    PractisSetContentEntities,
    PractisSets,
} from '../../../../../../../constants/interfaces/PractisSets';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PRACTIS_SET_BUILDER_DROP_ID } from '../Content';
import { useModifyPractisSetService } from '../../../../store/services';
import { PractisSetEntityTypes } from '../../../../tools';
import { EditModeValues } from '../../../../../../../constants/enums/EditModeValues';
import { EditModeContext } from '../../../../PractisSets';
import MinimumAccuracy from './components/MinimumAccuracy';
import BuilderDuration from './components/BuilderDuration';
import BuilderListItem from './components/BuilderListItem';
import RepsRequired from './components/RepsRequired';
import Documents from '../../../../../../../ui/icons/Documents';
import { TableContainer } from '../../../../../../../ui/components/table-wrapper/styles';

const StyledBuilderContent = styled.div`
    flex: 1;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 32px;
`;

const BuilderContent = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const BuilderActions = styled.div`
    height: 96px;
    display: flex;
    background: ${props => props.theme.Colors.darkTwo};
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    flex-shrink: 0;
`;

const BuilderBody = styled.div`
    overflow: hidden;
    display: flex;
    flex: 1;
`;

const StyledTableContainer = styled(TableContainer)`
    overflow: auto;
    flex-grow: 1;
    display: flex;
    && > div {
        overflow: auto;
    }
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.paleGrey};
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
`;

const StyledBuilderBodyArea = styled.div`
    flex: 1;
    height: 100%;
`;

const NoResultContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 100px 0;
    font-size: 20px;
    color: ${props => props.theme.Colors.coolGray};
    flex-direction: column;
    align-items: center;
`;

const NoResultLogo = styled.div`
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const NoResultHeader = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin: 8px 0;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const NoResultText = styled.div`
    font-size: 15px;
    text-align: center;
    margin: 8px 0;
    max-width: 224px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const StyledEmptyDroppableArea = styled.div``;

export const StyledTableBody = styled('tbody')<{
    emptyDropArea?: boolean;
    isDraggingOver?: any;
}>`
    min-height: 50px;
`;

interface PractisSetBuilderProps {
    practisSetContent: PractisSetContent[];
    practisSet: PractisSets;
    handleRemoveContent: (
        index: number | string,
        type: PractisSetContentEntities
    ) => void;
    modifyPractisSet: (
        value: string | number,
        field: keyof PractisSets,
        silent?: boolean
    ) => void;
}

const GenerateTableCells: FC<{
    content: PractisSetContent[];
    handleRemoveContent: (
        index: number | string,
        type: PractisSetContentEntities
    ) => void;
    disabled?: boolean;
}> = ({ content, handleRemoveContent, disabled }): any => {
    const [showPreview, setShowPreview] = useState<any>(null);

    return content.map((item: PractisSetContent, index: number) => (
        <Draggable
            key={item?.uniqueId}
            draggableId={`${PRACTIS_SET_BUILDER_DROP_ID}-${item?.uniqueId}`}
            index={index}
            isDragDisabled={disabled}
        >
            {(provided: any, snapshot: any) => {
                return (
                    <BuilderListItem
                        item={item}
                        provided={provided}
                        snapshot={snapshot}
                        disabled={disabled}
                        onRemove={handleRemoveContent}
                        showPreview={showPreview}
                        setShowPreview={setShowPreview}
                        dataTest="practis-set-content"
                    />
                );
            }}
        </Draggable>
    ));
};

const PractisSetBuilder: React.FC<PractisSetBuilderProps> = ({
    practisSetContent,
    practisSet,
    handleRemoveContent,
    modifyPractisSet,
}) => {
    const edit = useContext(EditModeContext);

    // Change to minRepsCountTotal TODO
    const totalRepsReqD = practisSet.content
        .filter(
            (item: PractisSetContent) =>
                item.type === PractisSetEntityTypes.SCENARIO
        )
        .reduce((prev: any, cur: any) => {
            return prev + cur.minRepsCount;
        }, 0);

    return (
        <StyledBuilderContent>
            <BuilderContent>
                <BuilderActions>
                    <BuilderDuration duration={practisSet.totalDuration} />
                    <RepsRequired reps={totalRepsReqD} />
                    <MinimumAccuracy

                    />
                </BuilderActions>
                <BuilderBody>
                    <StyledTableContainer>
                        <Droppable
                            droppableId={
                                practisSetContent &&
                                practisSetContent.length < 1
                                    ? 'empty-drop'
                                    : PRACTIS_SET_BUILDER_DROP_ID
                            }
                        >
                            {(provided: any) => {
                                if (
                                    practisSetContent &&
                                    practisSetContent.length < 1
                                ) {
                                    return (
                                        <StyledBuilderBodyArea
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <StyledEmptyDroppableArea>
                                                <NoResultContainer>
                                                    <NoResultLogo data-test='practis-set-no-result-logo'>
                                                        <Documents />
                                                    </NoResultLogo>
                                                    <NoResultHeader data-test='practis-set-no-result-text'>
                                                        No Content
                                                    </NoResultHeader>
                                                    <NoResultText data-test='practis-set-no-result-description'>
                                                        Add Scenarios &
                                                        Challenges from the left
                                                        in order to build this
                                                        practis set.
                                                    </NoResultText>
                                                </NoResultContainer>
                                            </StyledEmptyDroppableArea>
                                            {provided.placeholder}
                                        </StyledBuilderBodyArea>
                                    );
                                }
                                return (
                                    <StyledBuilderBodyArea
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <GenerateTableCells
                                            content={practisSetContent}
                                            disabled={
                                                edit.mode ===
                                                EditModeValues.VIEW
                                            }
                                            handleRemoveContent={
                                                handleRemoveContent
                                            }
                                        />
                                        {provided.placeholder}
                                    </StyledBuilderBodyArea>
                                );
                            }}
                        </Droppable>
                    </StyledTableContainer>
                </BuilderBody>
            </BuilderContent>
        </StyledBuilderContent>
    );
};

export const PractisSetBuilderContainer: FC<{
    practisSetContent: PractisSetContent[];
    handleRemoveContent: (
        index: number | string,
        type: PractisSetContentEntities
    ) => void;
}> = ({ practisSetContent, handleRemoveContent }) => {
    const practisSet = useSelector(getPractisSetState).info;
    const modifyPractisSet = useModifyPractisSetService();
    return (
        <PractisSetBuilder
            handleRemoveContent={handleRemoveContent}
            practisSetContent={practisSetContent}
            practisSet={practisSet}
            modifyPractisSet={modifyPractisSet}
        />
    );
};

export default PractisSetBuilderContainer;
