import React, { FC } from 'react';
import styled from 'styled-components';
import { Label } from '../../../constants/interfaces/Label';
import LabelListItem from './LabelListtem';

const StyledLabelsList = styled.div`
    padding-left: 8px;
    margin-bottom: 16px;
`;

const ScrollableLabelContainer = styled.div`
    height: 100%;
`;

const LabelsList: FC<{
    labels?: Label[];
    labelsCollapseState: number[];
    selectedLabels: number[];
    disableLabels?: boolean;
}> = ({ labels, labelsCollapseState, selectedLabels, disableLabels }) => {
    if (!labels || (labels && labels.length === 0)) return null;
    return (
        <StyledLabelsList>
            <ScrollableLabelContainer>
                {labels &&
                    labels.map(label => {
                        return (
                            <LabelListItem
                                key={label.id}
                                label={label}
                                labelsCollapseState={labelsCollapseState}
                                selectedLabels={selectedLabels}
                                disableLabels={disableLabels}
                            />
                        );
                    })}
            </ScrollableLabelContainer>
        </StyledLabelsList>
    );
};

export const LabelsListContainer: FC<{
    labels?: Label[];
    labelsCollapseState: number[];
    selectedLabels: number[];
    disableLabels?: boolean;
}> = ({ labels, labelsCollapseState, selectedLabels, disableLabels }) => {
    return (
        <LabelsList
            labels={labels}
            labelsCollapseState={labelsCollapseState}
            selectedLabels={selectedLabels}
            disableLabels={disableLabels}
        />
    );
};

export default LabelsListContainer;
