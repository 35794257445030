import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../../ui/components/Button';
import { Fieldset } from '../../../../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../../../../portableLabels';
import {
    useDeselectAllLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { PortableTeamsWithStore } from '../../../../../portableTeams';
import { useDeselectAllTeams, useSaveTeams } from '../../../../../portableTeams/store/hors/withTeams/services';
import { useTeamsState } from '../../../../../portableTeams/store/hors/withTeams/states';
import { useTeamsState as useMyTeamsState } from '../../../../store/states';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import { PortableTeamLeaders } from '../../../../../portableTeamLeaders';
import {
    useResetTeamLeadersSelection,
    useSaveTeamLeaderSelection,
    useSelectMultipleTeamLeadersService,
    useSelectTeamLeaderService,
    useDeselectAllTeamLeadersSelection
} from '../../../../store/services';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 464px;
    flex-grow: 1;
    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    min-height: 254px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const TeamsContainer = styled.div`
    width: 264px;
    min-height: 254px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const TeamLeadersContainer = styled.div`
    width: 224px;
    min-height: 254px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

export const MyTeamsFilters: FC<{
    preSelectedLabels: number[];
    onLabelsChange: (ids: number[]) => void;
    onTeamsChange: (ids: number[]) => void;
    onTeamLeadersChange: (ids: number[]) => void;
    onSuccessApply: () => void;
}> = ({
    preSelectedLabels,
    onLabelsChange,
    onTeamsChange,
    onTeamLeadersChange,
    onSuccessApply,
}) => {
    const teams = useTeamsState();
    const labels = useLabelsState();
    const selectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const saveTeams = useSaveTeams();
    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    //Team Leaders state logic
    const teamLeaders = useMyTeamsState().teamLeaders;

    const onSelectTeamLeader = useSelectTeamLeaderService();
    const onSelectAllTeamLeaders = useSelectMultipleTeamLeadersService();
    const saveTeamLeaders = useSaveTeamLeaderSelection();
    const resetTeamLeaders = useResetTeamLeadersSelection();

    const deselectTeams = useDeselectAllTeams();
    const deselectLabels = useDeselectAllLabels();
    const deselectTeamLeaders = useDeselectAllTeamLeadersSelection();

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labels.selected);
        onTeamsChange(teams.selected);
        onTeamLeadersChange(teamLeaders.selected);
        saveLabels();
        saveTeams();
        saveTeamLeaders();
        onSuccessApply();
    }, [
        onLabelsChange,
        labels.selected,
        onTeamsChange,
        teams.selected,
        onTeamLeadersChange,
        teamLeaders.selected,
        saveLabels,
        saveTeams,
        saveTeamLeaders,
        onSuccessApply,
    ]);

    const onClearHandler = useCallback(() => {
        deselectLabels();
        deselectTeamLeaders();
        deselectTeams();
    }, [deselectLabels, deselectTeams, deselectTeamLeaders])

    const totalFiltersSelected =
        teams.selected.length +
        labels.selected.filter(id => id > 0).length +
        teamLeaders.selected.length;

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Teams'}>
                    <TeamsContainer>
                        <PortableTeamsWithStore
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamsContainer>
                </Fieldset>
                <Fieldset title={'Team Leaders'}>
                    <TeamLeadersContainer>
                        <PortableTeamLeaders
                            teamLeaders={teamLeaders.data?.items}
                            selectedList={teamLeaders.selected}
                            onSetSelected={onSelectTeamLeader}
                            onSelectAll={onSelectAllTeamLeaders}
                            resetSelected={resetTeamLeaders}
                            showSave={false}
                            fetchOnMount={false}
                            showSelectActions
                            showSearch
                        />
                    </TeamLeadersContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption>
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button disabled={!totalFiltersSelected} variant='inverse' width={'122px'} action={() => onClearHandler()}>
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button width={'122px'} action={() => onApplyHandler()}>
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
