import ROUTES from '../../../../routes/routes';
import Navigation from '../../../../ui/components/Navigation/Navigation';
import { getPageUrl } from '../../../../routes/helpers';
import { Container } from './styles';

export const TeamsLearnersNavigation = () => {
    return (
        <Container>
            <Navigation
                fontSize="13px"
                dataTest="team"
                menu={[
                    {
                        key: 'teams',
                        title: 'Teams',
                        url: ROUTES.TEAMS_PAGES.LIST_OF_TEAMS
                    },
                    {
                        key: 'overdueLearners',
                        title: 'Overdue Learners',
                        url: getPageUrl(ROUTES.OVERDUE_LEARNERS),
                    },
                ]}
            />
        </Container>
    );
};
