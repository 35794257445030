import React, { FC } from 'react';
import styled from 'styled-components';

const Label = styled.div<{ focused?: boolean; color?: string }>`
    position: absolute;
    top: ${props => (props.focused ? '-10px' : '0')};
    left: 24px;
    pointer-events: none;
    font-size: ${props => (props.focused ? '11px' : '12px')};
    -webkit-transition: all 0.18s ease;
    transition: all 0.18s ease;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props =>
        props.color ? props.color : props.theme.Colors.steelGrey};
    font-weight: 500;
`;

export const SelectLabel: FC<{
    label: string;
    children?: string;
    focused?: boolean;
    color?: string;
    className?: string;
    dataTest?: string;
}> = ({ children, label, focused, color, className, dataTest }) => {
    return (
        <Label focused={focused} color={color} className={className} data-test={dataTest}>
            {children || label}
        </Label>
    );
};
