export const SEARCH_STATE = {
    ACCURACY: {
        name: '/feed/accuracy',
        childrenKeys: ['/feed/accuracy', 'teams/training/practis-sets', 'user/performance/']
    },
    SUBMISSIONS: {
        name: '/feed/challenges',
        childrenKeys: ['/feed/challenges', 'library/challenges/', 'teams/training/practis-sets', 'user/performance/']
    },
    TEAMS: {
        name: '/teams',
        childrenKeys: ['/members']
    },
    MEMBERS: {
        name: '/members',
        childrenKeys: ['user/performance/', 'teams/training/practis-sets/'],
        parentKey: ['/teams']
    },
    TRAINING: {
        name: '/training/practis-sets',
        childrenKeys: ['/training/practis-sets/', 'teams/training/practis-sets/'],
        parentKey: ['/teams']
    },
    PRACTIS_SET_DETAILS: {
        name: '/training/practis-sets/',
        childrenKeys: ['user/performance/']
    },
    ACTIVE_USERS: {
        name: '/users',
        childrenKeys: ['user/performance/', 'teams/training/practis-sets/', '/users/']
    },
    PENDING_USERS: {
        name: '/invitations',
        childrenKeys: ['invitation/performance/', 'teams/training/practis-sets/']
    },
    
}