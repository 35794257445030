import { useMemo } from "react";
import { UserProfile } from '../../../constants/interfaces/User';
import { useLoggedInUserProfileState } from "../store/states";
import {
    hasPermission,
    NEW_PERMISSIONS,
} from "./UserPermissionHelpers";
import { getUserRoleLevel, getRoleLevel } from "./UserRoleHelpers";
import {
    getBulkActionsAvailability,
    getBulkActionAvailabilityItem,
} from '../../../tools/bulkActions';

export enum InvitationActions {
    ASSIGN_INVITATION_LABEL,
    RESEND_INVITATION,
    REVOKE_INVITATION,
}

function createInvitationActionsHelper(
    loggedInUser: UserProfile | undefined
) {
    const loggedInUserRoleLevel = getUserRoleLevel(loggedInUser);

    const canAssignInvitationLabel = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.ASSIGN_INVITATION_LABEL);

    const canResendInvitation = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.RESEND_INVITATION);
    
    const canRevokeInvitation = (invitation: UserProfile) =>
        loggedInUserRoleLevel >= getRoleLevel(invitation.role?.name)
            && hasRevokeInvitationPermission();

    const hasRevokeInvitationPermission = () =>
        hasPermission(loggedInUser, NEW_PERMISSIONS.REVOKE_INVITATION);
    
    const getBulkActions = (invitations: UserProfile[] | undefined) => {
        const base = getBulkActionsAvailability(
            invitations,
            [
                getBulkActionAvailabilityItem(
                    InvitationActions.ASSIGN_INVITATION_LABEL,
                    invitations,
                    canAssignInvitationLabel
                ),
                getBulkActionAvailabilityItem(
                    InvitationActions.RESEND_INVITATION,
                    invitations,
                    canResendInvitation
                ),
                getBulkActionAvailabilityItem(
                    InvitationActions.REVOKE_INVITATION,
                    invitations,
                    canRevokeInvitation
                ),
            ]
        );

        return {
            ...base,
            isBulkActionVisible: (action: InvitationActions) =>
                action === InvitationActions.REVOKE_INVITATION
                    ? hasRevokeInvitationPermission()
                    : base.isBulkActionVisible(action),
        };
    }

    return {
        canAssignInvitationLabel,
        canResendInvitation,
        canRevokeInvitation,
        getBulkActions,
    };
}

export type InvitationActionsHelper = ReturnType<typeof createInvitationActionsHelper>;

export function useInvitationActionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createInvitationActionsHelper(loggedInUser),
        [loggedInUser]
    );
}