export type TreeDfsItem = {
    id: number;
    title: string;
};

interface VisitedBranches {
    [key: number]: boolean | null | undefined;
}

export class TreeDfsNameMapper<
    T extends {
        id: number;
        parentId: number | null;
        name: string;
        children?: T[];
    }
> {
    readonly treeData: T[] = [];
    readonly selectedLabels: number[] = [];
    private visited: VisitedBranches = {};
    private visitedSubTreeVertexes: VisitedBranches = [];
    private answer: TreeDfsItem[] = [];
    private mp: number[] = [];
    private currentAnswer: TreeDfsItem[] = [];
    private subTreeChecker = true;

    constructor(public data: T[], public selectedItems: number[]) {
        this.treeData = data;
        this.selectedLabels = selectedItems;
    }

    checkSubTreeSelection(id: number) {
        this.visitedSubTreeVertexes[id] = true;
        const itemId = this.treeData.findIndex(item => item.id === id);
        const children = this.treeData[itemId].children || [];

        for (let i = 0; i < children.length; i++) {
            let childId = children[i].id;

            if (
                this.visited[childId] === null ||
                typeof this.visited[childId] === 'undefined' ||
                !this.visited[childId]
            ) {
                if (!this.selectedLabels.includes(childId)) {
                    this.subTreeChecker = false;
                    return;
                } else {
                    this.checkSubTreeSelection(childId);
                }
            }
        }

        return;
    }

    dfs(id: number, name = '') {
        const children: T[] = [];
        this.visited[id] = true;
        const itemId = this.treeData.findIndex(item => item.id === id);
        this.visitedSubTreeVertexes = [];
        this.subTreeChecker = true;
        const treeChildren = this.treeData[itemId].children || [];

        if (treeChildren.length <= 0 && !this.selectedLabels.includes(id))
            return;
        this.checkSubTreeSelection(id);

        if (
            this.subTreeChecker ||
            (treeChildren.length <= 0 && this.selectedLabels.includes(id))
        ) {
            let pathId = this.mp[id];
            let toReverseAnswer = [];
            if (this.selectedLabels.includes(id)) {
                name = this.treeData[itemId].name;
                toReverseAnswer.push(name);
            }

            while (pathId !== null && typeof pathId !== 'undefined') {
                // eslint-disable-next-line no-loop-func
                let findIndex = this.treeData.findIndex(el => el.id === pathId);
                name = this.treeData[findIndex].name;
                toReverseAnswer.push(name);
                pathId = this.mp[pathId];
            }

            let localAnswer = '';
            for (let i = toReverseAnswer.length - 1; i >= 0; i--) {
                localAnswer += toReverseAnswer[i] + (i === 0 ? '' : '/');
                if (i === 0) {
                    this.currentAnswer.push({
                        id: id,
                        title: localAnswer,
                    });
                }
            }

            return;
        }

        if (treeChildren) {
            for (let j = 0; j < treeChildren.length; j++) {
                children.push(treeChildren[j]);
            }
        }

        for (let j = 0; j < children.length; j++) {
            this.mp[children[j].id] = id;
            if (!this.visited[children[j].id]) {
                this.dfs(children[j].id, name);
            }
        }
        return;
    }

    getNames() {
        for (let i = 0; i < this.selectedItems.length; i++) {
            if (
                this.visited[this.selectedLabels[i]] === undefined ||
                !this.visited[this.selectedLabels[i]]
            ) {
                this.currentAnswer = [];

                let curObj = this.treeData.find(
                    item => item.id === this.selectedLabels[i]
                );
                if (!curObj) {
                    return this.answer;
                }
                let curObjParId = curObj.parentId;
                while (
                    curObjParId !== null &&
                    // eslint-disable-next-line no-loop-func
                    this.selectedLabels.find(x => x === curObjParId) !== null
                ) {
                    curObj = this.treeData.find(
                        // eslint-disable-next-line no-loop-func
                        item => item.id === curObjParId
                    );
                    curObjParId = curObj?.parentId || null;
                }
                if (curObj && !this.visited[curObj.id]) {
                    this.visited[curObj.id] = true;
                    this.dfs(curObj.id, curObj.name);
                    if (this.currentAnswer.length <= 0) {
                        this.currentAnswer.push({
                            id: curObj.id,
                            title: curObj.name,
                        });
                    }
                    this.currentAnswer.map(ans => this.answer.push(ans));
                }
            }
        }
        return this.answer;
    }
}
