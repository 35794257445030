import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ListResult } from '../../../../../constants/interfaces/PaginationResult';
import { UserV2 } from '../../../../../constants/interfaces/User';

export interface OverdueLearnersState {
    data?: ListResult<UserV2>;
    isLoading: boolean;
    error?: string;
}

export type OverdueLearnersStateSelector = (state: any) => OverdueLearnersState;

export const OverdueLearnersStateSelectorContext = React.createContext<OverdueLearnersStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useOverdueLearnersState(): OverdueLearnersState {
    return useSelector(useContext(OverdueLearnersStateSelectorContext));
}
