import { memo, useContext } from 'react';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';

import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import ActionsDropdown from './components/ActionsDropdown/ActionsDropdown';
import BackArrow from '../../icons/BackArrow';
import { CheckPermission } from '../../../features/permissions';
import {
    CompanySectionWrapper,
    HeaderContainer,
    LeftPanel,
    MainTitle,
    NavigationPanel,
    RightPanel,
    SecondaryNavigation,
    SubHeaderContainer,
    SubTitle,
    TitleContainer,
    TitleIcon,
    TitleSection,
    Toolbar,
} from './styles';
import { HeaderContext, HeaderProps } from './types';

export const Header: React.FC<HeaderProps> = memo(
    ({
        title,
        subTitle,
        tabs,
        subHeader,
        hasBorder,
        goBackUrl,
        useGoBack,
        headerPadding,
        dataTest,
        minHeight,
    }) => {
        const history = useHistory();
        const { companySectionComponent: CompanySection } =
            useContext(HeaderContext);
        const hasTabs = !!tabs;
        const hasBackButton = !!goBackUrl || !!useGoBack;

        const handleGoBack = () => {
            if (!!useGoBack) {
                history.goBack();
            } else if (!!goBackUrl) {
                history.push(goBackUrl);
            }
        };

        return (
            <HeaderContainer
                hasBorder={hasBorder}
                headerPadding={headerPadding}
                minHeight={minHeight ?? (hasTabs ? undefined : '144px')}
            >
                <Toolbar>
                    <NavigationPanel useReducedHeight={minHeight ? undefined : hasTabs}>
                        <LeftPanel>
                            <TitleContainer>
                                {hasBackButton && (
                                    <TitleIcon
                                        onClick={handleGoBack}
                                        data-test="back-arrow-button"
                                    >
                                        <BackArrow />
                                    </TitleIcon>
                                )}
                                <TitleSection useTopPadding={!title}>
                                    {title && (
                                        <MainTitle
                                            data-test={
                                                dataTest &&
                                                `${dataTest}-page-title`
                                            }
                                        >
                                            {title}
                                        </MainTitle>
                                    )}

                                    {subTitle ? (
                                        <SubTitle
                                            data-test={
                                                dataTest &&
                                                `${dataTest}-page-subtitle`
                                            }
                                        >
                                            {subTitle}
                                        </SubTitle>
                                    ) : (
                                        <Skeleton
                                            variant="text"
                                            width={140}
                                            height={32}
                                            style={{
                                                background: 'var(--ps-grey-3)',
                                            }}
                                        />
                                    )}
                                </TitleSection>
                            </TitleContainer>
                            {subHeader && (
                                <SubHeaderContainer hasBackButton={hasBackButton}>
                                    {subHeader}
                                </SubHeaderContainer>
                            )}
                        </LeftPanel>
                        <RightPanel>
                            <CompanySectionWrapper data-test="companyDropDownSection">
                                <CompanySection />
                            </CompanySectionWrapper>
                            <CheckPermission
                                permissions={[
                                    NEW_PERMISSIONS.CREATE_PRACTIS_SET,
                                    NEW_PERMISSIONS.CREATE_SCENARIO,
                                    NEW_PERMISSIONS.CREATE_CHALLENGE,
                                    NEW_PERMISSIONS.CREATE_STAGING,
                                ]}
                            >
                                <ActionsDropdown />
                            </CheckPermission>
                        </RightPanel>
                    </NavigationPanel>
                    <SecondaryNavigation>
                        {tabs}
                    </SecondaryNavigation>
                </Toolbar>
            </HeaderContainer>
        );
    },
    (prevProps, nextProps) =>
        isEqual(
            prevProps as Record<string, any>[],
            nextProps as Record<string, any>[]
        )
);

export default Header;