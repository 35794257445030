import { Action } from 'redux';

import { ListResult } from '../../../../../constants/interfaces/PaginationResult';
import { Scope } from './scopes';
import { Scenario } from '../../../../../constants/interfaces/Scenario';

//region Types
type ReturnTypeScenarioAction<T extends (...args: any) => any> = ReturnType<T> &
    ActionPayload;

type ActionPayload = { _scope?: Scope };

export type ActionScenario<T = any> = Action<T> & ActionPayload;

export type Actions =
    | ReturnTypeScenarioAction<typeof selectAllAction>
    | ReturnTypeScenarioAction<typeof deselectAllAction>
    | ReturnTypeScenarioAction<typeof resetSelectedAction>
    | ReturnTypeScenarioAction<typeof selectScenariosAction>
    | ReturnTypeScenarioAction<typeof selectMultipleScenariosAction>
    | ReturnTypeScenarioAction<typeof clearAction>
    | ReturnTypeScenarioAction<typeof saveAction>
    | ReturnTypeScenarioAction<typeof resetAction>;

//endregion

export const searchStartAction = (): ActionScenario<'searchStartScenarios'> => ({
    type: 'searchStartScenarios',
});

export const searchSuccessAction = (
    result: ListResult<Scenario>
): ActionScenario<'searchSuccessScenarios'> & {
    result: ListResult<Scenario>;
} => ({
    type: 'searchSuccessScenarios',
    result,
});

export const searchFailureAction = (
    message: string
): ActionScenario<'searchFailureScenarios'> & { message: string } => ({
    type: 'searchFailureScenarios',
    message,
});

export const clearPortableScenariosAction = (): ActionScenario<'clearPortableScenarios'> => ({
    type: 'clearPortableScenarios',
});

export const selectAllAction = (
    allScenarios: number[]
): ActionScenario<'selectAllScenarios'> & { allScenarios: number[] } => ({
    type: 'selectAllScenarios',
    allScenarios,
});

export const deselectAllAction = (): ActionScenario<'deselectAllScenarios'> => ({
    type: 'deselectAllScenarios',
});

export const resetSelectedAction = (): ActionScenario<'resetSelectedScenarios'> => ({
    type: 'resetSelectedScenarios',
});

export const selectScenariosAction = (
    id: number
): ActionScenario<'selectScenarios'> & { id: number } => ({
    type: 'selectScenarios',
    id,
});

export const selectMultipleScenariosAction = (
    ids: number[]
): ActionScenario<'selectMultipleScenarios'> & { ids: number[] } => ({
    type: 'selectMultipleScenarios',
    ids,
});

export const clearAction = (): ActionScenario<'clearScenarios'> => ({
    type: 'clearScenarios',
});

export const saveAction = (): ActionScenario<'saveScenarios'> => ({
    type: 'saveScenarios',
});

export const resetAction = (): ActionScenario<'resetScenarios'> => ({
    type: 'resetScenarios',
});
