import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const QuestionMark = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
                <svg 
                width="16" 
                height="17"
                 fill="none" 
                 xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8.5" r="8" fill="#F1F1F1" />
                    <path d="M6.572 8.768c0-.512.104-.88.312-1.104a.999.999 0 0 1 .288-.204c.112-.048.264-.104.456-.168.128-.04.236-.08.324-.12a.528.528 0 0 0 .204-.132.318.318 0 0 0 .072-.168 1.14 1.14 0 0 0 .024-.264v-2.46c.36.008.68.044.96.108.28.056.524.16.732.312.472.352.708.956.708 1.812 0 .648-.104 1.12-.312 1.416a1.094 1.094 0 0 1-.324.312c-.128.072-.284.14-.468.204l-.6.204c-.096.032-.164.06-.204.084a.192.192 0 0 0-.084.108.553.553 0 0 0-.036.216 4.036 4.036 0 0 0-.012.396v.228h-2.04v-.78zM5.42 4.4c.384-.08.852-.144 1.404-.192l.676-.06c.264-.023.886.004.886.004L8.5 6.056c-.04-.008-.856-.072-.944-.072h-.24c-.344 0-.764.024-1.26.072l-.636.072V4.4zm.972 6.144h2.424V12.5H6.392v-1.956z" fill="#6D7F8C" fillOpacity=".72" />
                </svg>
        );
    }
);

export default QuestionMark;
