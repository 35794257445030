export const dfsUpdate = (
    tree: any[],
    id: number,
    field: string,
    value: any
) => {
    for (let i = 0; i < tree.length; i++) {
        let node = tree[i];

        if (!!node && node.id === id) {
            tree[i] = {
                ...node,
                [field]: value,
            };

            return i;
        }

        const len = node.children.length;

        for (let j = 0; j < len; j++) {
            const foundNodeIndex: any = dfsUpdate(
                node.children,
                id,
                field,
                value
            );
            let foundNode = node.children[foundNodeIndex];

            if (!!foundNode) {
                node.children[foundNodeIndex] = {
                    ...foundNode,
                    [field]: value,
                };
            }
        }
    }
    return null;
};

export const findAllChildIds = (
    tree: any[],
    rootId: any,
    answer: any[]
): any => {
    let children = tree.filter(item => item.parentId === rootId);

    if (!!children && !!children.length) {
        answer.push(...children);
        for (let i = 0; i < children.length; i++) {
            findAllChildIds(tree, children[i].id, answer);
        }
    }
};
