import { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { EditModeValues } from '../../../../../constants/enums/EditModeValues';
import { useLabelsState } from '../../../../../features/labels/store/states';
import { useModifyPractisSetService } from '../../store/services';
import { EditModeContext } from '../../PractisSets';
import { PractisSets } from '../../../../../constants/interfaces/PractisSets';
import PractisSelect from '../../../../../ui/components/Select/Select';
import { PacingType, pacingTypeName } from '../../../../../features/pacings/tools';
import { useSelector } from 'react-redux';
import { getPacingsState } from '../../../../../features/pacings/store/reducers';
import { useSearchPacingsService } from '../../../../../features/pacings/store/services';
import { useSearchDebounced } from '../../../../../helpers/hooks/useSearch';
import { usePortableLabelsState } from '../../../../../features/portableLabels/store/states';
import { LabelsAction } from '../../../../../ui/components/table-wrapper/table/TableAssignOptions/actions/LabelsAction';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { Input } from '../../../../../ui/components/input';

const Container = styled.div`
    padding: 15px 0;
    display: flex;
`;

const TextInputContainer = styled.div`
    flex: 1;
`;

const LabelsContainer = styled.div`
    width: 128px;
    margin-left: 16px;
`;

const TitleTextInput = styled(Input)`
    padding: 12px 16px;
    font-size: 13px;
    font-weight: normal;
`;

const ActionPanel: FC<{
    practisSet: PractisSets;
    titleError: boolean;
    isTeamLeader?: boolean;
}> = ({ practisSet, titleError, isTeamLeader }) => {
    const modifyPractisSet = useModifyPractisSetService();
    const edit = useContext(EditModeContext);
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const showMessage = useShowMessage();
    const pacings = useSelector(getPacingsState).data;
    const searchPacings = useSearchPacingsService();
    const handleSearchPacings = useSearchDebounced(searchPacings);

    useEffect(() => {}, []);

    const handleSavePractisSetLabels = () => {
        modifyPractisSet(labels.assignedLabels, 'updatedLabels');
        showMessage(`labels have been assigned to Practis Set`, 'success');
    };

    useEffect(() => {
        handleSearchPacings();
    }, [handleSearchPacings]);

    useEffect(() => {
        if (!practisSet.pacing?.id && pacings) {
            const pacingId =
                pacings.items && pacings.items[1] && pacings.items[1].id;
 
            modifyPractisSet({ id: pacingId }, 'pacing', true);
        }
    }, [practisSet, pacings, modifyPractisSet]);

    const pacingItems =
        pacings &&
        pacings.items.map(pacing => {
            return { value: pacing.id, name: pacingTypeName(pacing.type) };
        });

    const handlePacingChange = (e: any) => {
        const newPacing = { id: e.target.value, type: e.target.name };
        modifyPractisSet(newPacing, 'pacing');
    };

    return (
        <Container>
            <TextInputContainer>
                <TitleTextInput
                    placeholder="Practis Set Title"
                    height={'40px'}
                    maxLength={90}
                    handleChange={(event: any) =>
                        modifyPractisSet(event.target.value, 'name')
                    }
                    value={practisSet.name}
                    readOnly={edit.mode === EditModeValues.VIEW || isTeamLeader}
                    error={titleError}
                    dataTest="practis-set-title"
                />
            </TextInputContainer>
            <LabelsContainer>
                <LabelsAction
                    title={'Labels'}
                    labels={labelsList}
                    disabled={edit.mode === EditModeValues.VIEW || isTeamLeader}
                    onAssignLabelsSubmit={handleSavePractisSetLabels}
                    containerLeftPosition={'unset'}
                    dataTest="practis-set-labels"
                />
            </LabelsContainer>
            <LabelsContainer>
                <PractisSelect
                    items={pacingItems}
                    value={practisSet.pacing?.id ?? ''}
                    name={
                        practisSet.pacing?.id === 1
                            ? pacingTypeName(PacingType.FREE)
                            : pacingTypeName(PacingType.SEQUENTIAL)
                    }
                    placeholder={'Pacings'}
                    height={40}
                    onChange={handlePacingChange}
                    disabled={edit.mode === EditModeValues.VIEW || isTeamLeader}
                    dataTest="practis-set-pacing"
                />
            </LabelsContainer>
        </Container>
    );
};

export default ActionPanel;