import styled from 'styled-components';

export const StyledResetContent = styled.div`
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ps-grey-10);
    font-weight: bold;
    font-size: 14px;
    padding: 0 5px;
    line-height: 1.14;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    flex-wrap: wrap;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
    color: var(--ps-white-1);
`;