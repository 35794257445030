import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Input } from '../../../../input';
import OutsideActionBox from '../../../../OutsideActionBox/OutsideActionBox';

export const StyledSelectForm = styled.div<{ disabled?: boolean }>`
    box-sizing: border-box;
    position: relative;
    border-radius: 16px;
    cursor: pointer;
    background: ${props => props.theme.Colors.white};
    color: ${props =>
        !!props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.black};
    font-weight: 600;
    height: 100%;
`;

export const Wrapper = styled.div`
    width: 272px;
    max-height: 432px;
    min-height: 200px;
    display: flex;
    padding: 16px 8px 0 8px;
    background: ${props => props.theme.Colors.white};
`;

export const LabelsContainer = styled(motion.div)<{
    displayOver?: boolean;
    topMargin: number;
    left?: string;
}>`
    padding: 24px;
    position: absolute;
    background: ${props => props.theme.Colors.white};
    z-index: 1100;
    left: ${props =>
        props.displayOver ? '16px' : !!props.left ? props.left : '0'};
    top: ${props => (props.displayOver ? `-${props.topMargin}px` : '8px')};
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    transition: max-height 1s;
    overflow: hidden;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    margin-top: 16px;
    width: 100%;
    padding: 0 8px;
`;
export const CancelButtonWrapper = styled.div`
    margin-right: 16px;
`;

export const StyledTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    color: ${props => props.theme.Colors.black};
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledDescription = styled.div<{ marginTop?: string }>`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
    ${props => !!props.marginTop && `margin-top: ${props.marginTop};`}
    width: 100%;
`;

export const StyledButtonContainer = styled.div`
    width: 128px;
    height: 40px;

    & > button {
        &:active {
            background: ${props => props.theme.Colors.tomato}!important;
        }
    }
`;

export const Bold = styled.span`
    font-weight: 800;
    color: ${props => props.theme.Colors.black};
`;

export const StyledEmptyLabels = styled.div`
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    align-items: center;
`;

export const StyledEmptyLabelsContent = styled.div`
    max-width: 140px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoLabelsIcon = styled.div`
    width: 30px;
    height: 40px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 16px;
`;

export const NoLabelsTitle = styled.div`
    font-weight: bold;
    font-size: 13px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

export const NoLabelsDescription = styled.div`
    font-size: 11px;
    color: ${props => props.theme.Colors.cloudyBlue};
    width: 100%;
    white-space: break-spaces;
`;

export const NudgeUserStyledDescription = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
    margin-top: 13px;
    margin-bottom: 4px;
    width: 100%;
    height: 37px;
`;

export const NudgeWarningStyledDescription = styled.div`
    font-size: 15px;
    color: ${props => props.theme.Colors.steelGrey};
    margin-top: 18px;
    padding: 0 10px;
    text-align: center;
`;

export const StyledFormArea = styled.div`
    width: 416px;
`;

export const StyledActions = styled.div<{ marginTop: number }>`
    width: 272px;
    display: flex;
    justify-content: space-between;
    margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : 0)};
    align-self: flex-end;
`;

export const StyledInput = styled(Input)`
    font-family: ${props => props.theme.Fonts.manrope};
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.whiteTwo};
`;

export const StyledInputContainer = styled.div`
    margin-bottom: 12px;
`;

export const TableAssignOptionsStyledSelectForm = styled.div<{
    height?: number;
    error?: boolean;
    variant: string;
    open?: boolean;
    disabled?: boolean;
}>`
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    min-width: 128px;
    cursor: pointer;
    height: ${props => (props.height ? `${props.height}px` : '40px')};
    width: 100%;
    background: ${props => props.theme.Colors.white};
    color: ${props => props.theme.Colors.black};
    box-shadow: inset 0 0 0 ${props => (props.error ? '2px' : '-1px')} ${props => props.theme.Colors.coral};
        
    -webkit-appearance: none;
    border: ${props =>
        props.open
            ? `1px solid ${props.theme.Colors.cloudyBlue}`
            : `1px solid ${props.theme.Colors.whiteFive}`};
    &.is-disabled {
        cursor: default;
        color: ${props => props.theme.Colors.cloudyBlue};
    }
`;

export const Container = styled(OutsideActionBox)`
    position: relative;
`;

export const IconHolder = styled.div<{ disabled?: boolean }>`
    width: 8px;
    height: 100%;
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

