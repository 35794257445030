import { PractisSetV2 } from "./PractisSets";
import { SubmissionV2 } from "./Reviews";
import { Scenario } from "./Scenario";
import { UserInfo } from "./UserInfo";

export enum EnrollmentStatus {
    ENROLLED = 'ENROLLED',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
}

export enum EnrollmentStatusEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
}

export enum TrainingContentType {
    SCENARIO = 'SCENARIO',
    CHALLENGE = 'CHALLENGE',
}

export interface Enrollments {
    id?: number;
    userId?: number;
    practisSetId?: number;
    status?: EnrollmentStatus | '';
    progressValue?: number;
    enrolledAt?: string;
    averageAccuracy?: number;
    declinedAt?: string;
    createdAt?: string;
    completedAt?: string;
    submissionsAverageScore?: number;
    updatedAt?: string;
    progress?: {
        id: number;
        type: 'CHALLENGE' | 'SCENARIO';
        status: 'AVAILABLE' | 'PASSED' | 'UNAVAILABLE';
    }[];
    daysActive: number;
    displayStatus?: EnrollmentStatusEnum | '';
    challengesWithSentSubmissionCount?: number | null;
    weeklyActiveDays: number;
    monthlyActiveDays: number;
    totalActiveDays: number;
    trainingTime: number;
    lastTrainingAt?: string;
    lastTraining?: string;
    currentRepsCountTotal?: number;
    minRepsCountTotal?: number;
    passedScenarioCount?: number;
    minutesPerDay?: number;
    totalTimeInMinutes?: number;
    lastActivity?: any;
    dueDate?: string;
    hasOverdue?: boolean;
}

export interface EnrollmentInterface {
    id: number;
    practisSet: PractisSetV2;
    user: UserInfo;
    dueDate: string;
    status: string;
    progress: number;
    averageAccuracy: number;
    trainingTime: number;
    createdAt: string;
    startedAt: string;
    lastTrainingAt: string;
    completedAt: string;
    hasOverdue: boolean;
    passedScenarioCount: number;
    passedChallengeCount: number;
}

export interface EnrollmentProgress {
    content: {
        title: string;
        scenarioId?: number;
        scenario?: Scenario;
        challengeId?: number;
        type: string;
        minRepsCount: number;
        position: number;
        cover: {
            id: number;
            fileUrl: string;
        };
    };
    progress: {
        submissions?: SubmissionV2[];
        submission?: SubmissionV2;
        completedAt?: string;
        countReps: number;
        countTries?: number;
    };
}

export interface EnrollmentDailyTraining {
    date: string;
    timeSpent: number;
}

