import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../ui/components/Button';
import { Fieldset } from '../../../../ui/components/Fieldset';
import { PortableLabelsWithStore } from '../../../portableLabels';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import { LIBRARY_STATUS_ITEMS } from '../../tools';
import Checkbox from '../../../../ui/components/Checkbox';
import { Variables } from '../../../../theme/variables';
import { toggleItemInArray } from '../../../../helpers/functions/array-helpers';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    max-height: 416px;
    flex-grow: 1;
    background-color: inherit;
`;

const LabelsContainer = styled.div`
    width: 208px;
    min-height: 244px;
    height: 100%;
    overflow: auto;
    background-color: inherit;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-shrink: 0;
    margin-top: 16px;
`;

const SummaryCaption = styled.span`
    font-size: 11px;
    font-weight: 500;

    margin-right: 24px;

    color: ${props => props.theme.Colors.steelGrey};
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 158px;
    background-color: inherit;
`;

const CheckBoxWrapper = styled.div`
    margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
    margin-right: 12px;
`;

export const LibraryFilters: FC<{
    selectedStatuses: string[];
    savedStatuses: string[];
    handleSelectStatus: (ids: string[]) => void;
    handleSaveStatus: (ids: string[]) => void;
    preSelectedLabels: number[];
    onLabelsChange: (ids: number[]) => void;
    onSuccessApply: () => void;
}> = ({
    selectedStatuses,
    savedStatuses,
    handleSelectStatus,
    handleSaveStatus,
    preSelectedLabels,
    onLabelsChange,
    onSuccessApply,
}) => {
    
    useEffect(() => {
        handleSelectStatus(savedStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedStatuses]);

    const labels = useLabelsState();
    const selectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const ifPreselectedLabelsChanged = useIfChanged(preSelectedLabels);

    useEffect(() => {
        if (ifPreselectedLabelsChanged) {
            selectLabels(preSelectedLabels);
            saveLabels();
        }
    }, [
        ifPreselectedLabelsChanged,
        preSelectedLabels,
        saveLabels,
        selectLabels,
    ]);

    const handleStatusSelection = useCallback(
        (status: string) => {
            handleSelectStatus(toggleItemInArray(selectedStatuses, status));
        },
        [handleSelectStatus, selectedStatuses]
    );

    const onApplyHandler = useCallback(() => {
        onLabelsChange(labels.selected);
        handleSaveStatus(selectedStatuses);
        saveLabels();
        onSuccessApply();
    }, [
        onLabelsChange,
        labels.selected,
        handleSaveStatus,
        selectedStatuses,
        saveLabels,
        onSuccessApply,
    ]);

    const onClearHandler = useCallback(() => {
        handleSelectStatus([]);
        selectLabels([]);
    }, [handleSelectStatus, selectLabels]);

    const totalFiltersSelected =
        selectedStatuses.length +
        labels.selected.filter((id: any) => id > 0).length;

    return (
        <Container>
            <Wrapper>
                <Fieldset title={'Status'} dataTest="library-filters-status-title">
                    <StatusContainer>
                        {LIBRARY_STATUS_ITEMS.map(item => {
                            return (
                                <CheckBoxWrapper key={item.value} data-test="status-item-container">
                                    <Checkbox
                                        key={item.value}
                                        handleChange={() => {
                                            handleStatusSelection(item.value);
                                        }}
                                        checked={selectedStatuses.includes(
                                            item.value
                                        )}
                                        size={12}
                                        label={item.name}
                                        fontSize={13}
                                        fontColor={Variables.Colors.black}
                                        fontWeight={'500'}
                                        dataTest={`library-${item.name.toLowerCase()}-checkbox`}
                                    />
                                </CheckBoxWrapper>
                            );
                        })}
                    </StatusContainer>
                </Fieldset>
                <Fieldset title={'Labels'} dataTest="labels-section-title">
                    <LabelsContainer>
                        <PortableLabelsWithStore
                            showSave={false}
                            showSelectActions
                            showSearch
                        />
                    </LabelsContainer>
                </Fieldset>
            </Wrapper>
            <Actions>
                <SummaryCaption data-test="library-filters-selected-counter">
                    {totalFiltersSelected} Selected
                </SummaryCaption>
                <ButtonWrapper>
                    <Button
                        width={'122px'}
                        variant={'inverse'}
                        action={() => onClearHandler()}
                        disabled={totalFiltersSelected < 1}
                        dataTest="library-filters-clear"
                    >
                        Clear
                    </Button>
                </ButtonWrapper>
                <Button
                    width={'122px'}
                    action={() => onApplyHandler()}
                    dataTest="library-filters-apply"
                >
                    Apply Filter
                </Button>
            </Actions>
        </Container>
    );
};
