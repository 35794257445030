import brokenLink from '../../../assets/images/broken-link.svg';
import {
    ErrorTitle,
    ErrorDescription
} from './styles';
import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

function InvalidInvite() {

    useHtmlPageTitle('Hmm. This didn’t work.');

    return (
        <LoginAuthWrapper contentWidth={324} heightToShowScroll={500}>
            <img src={brokenLink} width='72px' height='72px' alt='Invalid invite' data-test="error-image" />
            <ErrorTitle data-test="error-title">Hmm. This didn't work.</ErrorTitle>
            <ErrorDescription data-test="error-description">
                Looks like this link has been revoked, is broken, or has already been used. Either way, life goes on. But ask your manager or trainer if they were somehow trying to invite you to training.
            </ErrorDescription>
            <ErrorDescription data-test="contact-us">
                You can also <a href='mailto:info@gopractis.com'>contact us</a> at Practis and we may be able to help.
            </ErrorDescription>
        </LoginAuthWrapper>
    );
}

export default InvalidInvite;