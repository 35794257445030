import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarReports = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 15H2V1h8v4h4v10zm-.45-11L11 1.571V4h2.55zM10.8 0H1v16h14V4l-4.2-4z" fill="#6D7F8C"/>
                <path fill="#6D7F8C" d="M4 3h3v2H4zM4 8h2v1H4zM4 11h2v1H4zM8 8h4v1H8zM8 11h4v1H8z"/>
            </svg>
        );
    }
);

export default SidebarReports;
