import React, { useCallback, useEffect, useState } from 'react';

import Storage from '../../services/Storage';
import { ProgressModal } from '../../ui/components/ProgressModal';

import { w3cwebsocket as WebSocket } from 'websocket';
import { useDispatch, useSelector } from 'react-redux';
import { getSocketState } from './store/reducer';
import { SocketProgressHide } from './store/actions';
import { useShowMessage } from '../../ui/components/ErrorMessages/ErrorMessages';

let wssurl: any;

if (window.location.href.includes('portal-dev.gopractis')) {
    wssurl = 'wss://api-dev.gopractis.com/';
} else if (window.location.href.includes('portal-beta.gopractis')) {
    wssurl = 'wss://api-beta.gopractis.com/'
} else if (window.location.href.includes('gopractis.com')) {
    wssurl = 'wss://api.gopractis.com/';
} else {
    wssurl = 'wss://api-dev.gopractis.com/';
}

const socket = Storage.get('token') ? new WebSocket(
    `${wssurl}socket?token=${Storage.get('token')}`
) : null;

const PAGE_KEYS = [
    {
        key: 'REMOVE_STAGING',
        route: '/drafts'
    },
    {
        key: 'CREATE_STAGING_INVITATION',
        route: '/drafts'
    },
    {
        key: 'CREATE_INVITATION',
        route: '/drafts'
    },
    {
        key: 'STAGING_ASSIGN_DATA',
        route: '/drafts'
    }
]

const ProgressPage = () => {
    const [percentage, setPercentage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const socketLoaderState = useSelector(getSocketState);
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const token = Storage.get('token');
    const [socketConnetion, setSocketConnection] = useState<any>();

    const onUnload = useCallback(
        (e: any) => {
            if (
                percentage > 0 && percentage < 100
            ) {
                e.preventDefault();
                e.returnValue = true;
            }
        },
        [percentage]
    );


    useEffect(() => {
        if (!token && socket) {
            socket.close();
        } else if (token && !socket) {
            let keyArray = PAGE_KEYS.map(item => item?.key);

            const socket = new WebSocket(
                `${wssurl}socket?token=${Storage.get('token')}`
            );

            setSocketConnection(socket);
            socket.onmessage = (eventName: any) => {
                const data = JSON.parse(eventName.data);
                let perc = data.payload.percentage;
                let key = data.event;

                if (keyArray.includes(key)) {
                    setPercentage(perc)
                }
            }

            socket.onerror = function (event) {
                showMessage(`Something went wrong. Try again`, 'error');
            };
        }  else if(!token){
            socket && socket.close();
            socketConnetion && socketConnetion.close();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    useEffect(() => {
        let keyArray = PAGE_KEYS.map(item => item?.key);

        if (socket?.readyState === 1) {

            socket.onmessage = (eventName: any) => {
                const data = JSON.parse(eventName.data);
                let perc = data.payload.percentage;
                let key = data.event;

                if (keyArray.includes(key)) {
                    setPercentage(perc)
                }
            }

            socket.onerror = function (event) {
                showMessage(`Something went wrong. Try again`, 'error');
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, socket?.readyState])
  
    
    useEffect(() => {
        window.addEventListener('beforeunload', onUnload);
        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [onUnload]);



    useEffect(() => {
        if (percentage > 0 && percentage < 100) {
            setIsOpen(true);
        } else if (percentage === 100) {
            setTimeout(() => {
                dispatch(SocketProgressHide())
                setTimeout(() => {
                    setPercentage(0);
                }, 2100)
              
            }, 2000)
        };
    }, [dispatch, percentage])


    if(!isOpen && !socketLoaderState){
        return null;
    }

    return (
        <div>
            {socketLoaderState && isOpen && <ProgressModal
                progressValue={percentage}
                open={true}
            />}
        </div>

    );
};

export default ProgressPage;