import { useCallback, useMemo } from 'react';
import { SearchParams } from '../../../constants/interfaces/filters';
import { CalendarValue } from '../../../ui/components/Calendar';
import { CalendarFilterOptions } from '../../../ui/components/table-wrapper/table-tools/types';
import { useOrderBy } from '../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import AssessmentLogsBig from '../../../ui/icons/AssessmentLogsBig';

/**
 * @function useCalendarFilterOptions
 * @param { SearchParams } searchParams
 * @param { boolean } isEmptyState
 * @param { (name: keyof SearchParams, value: any) => void } setCustom
 * @returns { CalendarFilterOptions }
 */
export const useCalendarFilterOptions = (
    searchParams: SearchParams,
    isEmptyState: boolean,
    setCustom: (name: keyof SearchParams, value: any) => void,
    dataTest?: string,
): CalendarFilterOptions => {
    const { startDate, endDate } = searchParams;
    const handleChangeStartDate = useCallback(
        (d?: Date | null) => setCustom('startDate', d),
        [setCustom]
    );
    const handleChangeEndDate = useCallback(
        (d?: Date | null) => setCustom('endDate', d),
        [setCustom]
    );
    const handleChangeDate = useCallback(
        (date: CalendarValue<true>) => {
            handleChangeStartDate(date.start);
            handleChangeEndDate(date.end);
        },
        [handleChangeStartDate, handleChangeEndDate]
    );

    return {
        calendarPopUpProps: {
            value: {
                start: startDate ? new Date(startDate) : null,
                end: endDate ? new Date(endDate) : null,
            },
            selectRange: true,
            onChange: handleChangeDate,
            restrictFuture: true,
            disabled:
                isEmptyState &&
                !startDate &&
                !endDate,
            dataTest,
        }
    };
};

/**
 * @function useHeaderOptions
 * @param { SearchParams } searchParams
 * @param { (field: string) => void } setOrderBy
 * @returns { object }
 */
export const useHeaderOptions = (
    searchParams: SearchParams,
    setOrderBy: (field: string) => void
) => {
    const { orderBy } = searchParams;
    return {
        id: useOrderBy('id', orderBy, setOrderBy),
        user: useOrderBy('user', orderBy, setOrderBy),
        scenario: useOrderBy('scenario', orderBy, setOrderBy),
        date: useOrderBy('date', orderBy, setOrderBy),
        mode: useOrderBy('mode', orderBy, setOrderBy),
        accuracy: useOrderBy('accuracy', orderBy, setOrderBy)
    };
}

/**
 * @function useEmptyStateOptions
 * @param { boolean } isEmptyState
 * @returns { object }
 */
export const useEmptyStateOptions = (isEmptyState: boolean) => {
    return useMemo(() => {
        return {
            shouldShowEmptyState: isEmptyState,
            noEntriesOptions: {
                icon: AssessmentLogsBig,
                text: 'No Training Log Yet',
                dataTest: 'no-ai-assessment'
            },
            noSearchResultsOptions: {
                entityName: 'Training Log',
                dataTest: 'no-found-ai-assessment'
            }
        }
    }, [isEmptyState]);
};
