import { useCallback, useEffect, useMemo } from 'react';

import { useMultipleTeamsFilterState } from '../../../features/filters/dynamic-filter/multiple-teams-filter/store/reducers';
import { useGenerateReportService, useGetReportAvailabilityService } from '../../../features/filters/filters-sidebar/services';
import { ReportingControlPanelMode } from '../../../ui/components/ReportingControlPanel/types';
import TeamLeaderActivityReportView from './view';
import { useTeamsState } from '../../../features/teams/store/states';
import { usePortableLabelsState } from '../../../features/portableLabels/store/states';
import { useLabelsState } from '../../../features/portableLabels/store/hors/withLabels/states';
import { WithLabelsContext } from '../../../features/portableLabels';
import { ReportType } from '../../../constants/interfaces/ReportItem';
import { useHistory } from '../../../tools/router';
import ROUTES from '../../../routes/routes';
import { useClearMultipleTeamsFilter, useDisableMultipleTeamsFilter } from '../../../features/filters/dynamic-filter/multiple-teams-filter/store/services';
import { useClearLabelsFilter, useDisableLabelsFilter } from '../../../features/filters/dynamic-filter/labels-filter/store/services';
import { useDateRangeState } from '../../../features/filters/dynamic-filter/date-filter/store/reducers';
import { useResetDateRange } from '../../../features/filters/dynamic-filter/date-filter/store/services';
import { DATE_FORMAT } from '../BillingReport/constants';
import dayjs from 'dayjs';

function TeamLeaderActivityReportController() {
    const { isGenerating, remainingTimeout, generateReport } = useGenerateReportService();
    const { isAvailable, getReportAvailability } = useGetReportAvailabilityService();
    const { selectedTeamIds } = useMultipleTeamsFilterState();
    const { loading: isTeamsLoading } = useTeamsState();
    const { loading: isLabelsLoading } = usePortableLabelsState();
    const { selected: selectedLabels } = useLabelsState();
    const { dateRange } = useDateRangeState();
    const clearMultipleTeamsFilter = useClearMultipleTeamsFilter();
    const disableMultipleTeamsFilter = useDisableMultipleTeamsFilter();
    const clearLabelsFilter = useClearLabelsFilter();
    const clearDateRangeFilter = useResetDateRange();
    const disableLabelsFilter = useDisableLabelsFilter();
    const history = useHistory();

    const isDisabled = (!selectedTeamIds?.length && !selectedLabels.length)
        || !(dateRange?.start && dateRange?.end);

    const handleClearFilters = useCallback(() => {
        clearMultipleTeamsFilter();
        clearLabelsFilter();
        clearDateRangeFilter()
        history.replace(ROUTES.REPORTS.TEAM_LEADER_ACTIVITY_REPORT);
    }, [clearLabelsFilter, clearMultipleTeamsFilter, clearDateRangeFilter, history]);

    const controlPanelMode: ReportingControlPanelMode = useMemo(() => {
        if (remainingTimeout) {
            return 'timeout';
        }
        
        if (isTeamsLoading || isLabelsLoading || isGenerating) {
            return 'loading';
        }

        if (isDisabled) {
            return 'disabled';
        }

        if (!isAvailable) {
            return 'noData';
        }

        return 'enabled';
    }, [remainingTimeout, isTeamsLoading, isLabelsLoading, isGenerating, isDisabled, isAvailable]);

    const getReportParams = useCallback(() => {
        if (isDisabled) return;
        return {
            teams: selectedTeamIds ?? [],
            labels: selectedLabels?.length
                        ? selectedLabels.filter(id => id > 0)
                        : selectedLabels,
            dateStart: dateRange?.start && dayjs(new Date(dateRange?.start)).format(DATE_FORMAT),
            dateEnd: dateRange?.end && dayjs(new Date(dateRange?.end)).format(DATE_FORMAT)
        }
    }, [selectedLabels, selectedTeamIds, dateRange, isDisabled]);

    const handleGenerateReport = useCallback(() => {
        const params = getReportParams();
        if (params) {
            generateReport(ReportType.TEAM_LEADER_ACTIVITY, params);
        }
    }, [generateReport, getReportParams]);

    useEffect(() => {
        return () => {
            clearMultipleTeamsFilter();
            clearLabelsFilter();
            clearDateRangeFilter();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const params = getReportParams();
        if (params) {
            getReportAvailability(ReportType.TEAM_LEADER_ACTIVITY, params);
        }
    }, [getReportAvailability, getReportParams]);

    useEffect(() => {
        disableMultipleTeamsFilter(isGenerating);
        disableLabelsFilter(isGenerating);
    }, [disableLabelsFilter, disableMultipleTeamsFilter, isGenerating]);

    return (
        <TeamLeaderActivityReportView
            onClearFilters={handleClearFilters}
            onGenerateReport={handleGenerateReport}
            controlPanelMode={controlPanelMode}
            remainingTimeout={remainingTimeout}
        />
    );
}

function UserActivityReportWithLabelsContext() {
    return (
        <WithLabelsContext.Provider value={{ reducerName: 'labelsFilter' }}>
            <TeamLeaderActivityReportController />
        </WithLabelsContext.Provider>
    );
}

export default UserActivityReportWithLabelsContext;