import React, { FC, useEffect, useRef, useState } from 'react';
import { ScriptLine } from '../../../../../constants/interfaces/ScriptLine';
import styled from 'styled-components';
import {
    Comment,
    CommentBody,
    CommentDescription,
    CommentTitle,
} from '../../../../../ui/components/Comment/Comment';
import ContentEditableInput from '../../../../../ui/components/ContentEditableDiv/ContentEditableDiv';
import { Variables } from '../../../../../theme/variables';

const StyledScriptItem = styled.div`
    width: 100%;
    margin-left: 48px;
`;

const DisabledArea = styled.div`
    position: absolute;
    height: 100%;
    background: transparent;
    width: 100%;
    top: 0;
    left: 0;
`;

const StyledCommentTitle = styled(CommentTitle)`
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledContentEditableInput = styled(ContentEditableInput)`
    color: ${props => props.theme.Colors.steelGrey};
`;

const RepScriptItem: FC<{
    script: ScriptLine;
}> = ({ script }) => {
    const myRef = useRef<any>(null);
    const [localValue, setLocalValue] = useState(script.text);

    useEffect(() => {
        if (localValue !== script.text) {
            setLocalValue(script.text);
        }
    }, [script.text, localValue]);

    return (
        <StyledScriptItem ref={myRef}>
            <Comment
                reverse={true}
                disabled={true}
                background={Variables.Colors.whiteFive}
            >
                <CommentBody>
                    <CommentDescription hideRemoveIcon={true}>
                        <DisabledArea />
                        <StyledCommentTitle dataTest="challenge-rep-line-title">Rep</StyledCommentTitle>
                        <StyledContentEditableInput
                            value={localValue}
                            placeholder={'Write rep’s line here'}
                            viewMode={true}
                            dataTest="challenge-rep-line"
                        />
                    </CommentDescription>
                </CommentBody>
            </Comment>
        </StyledScriptItem>
    );
};

export const RepScriptItemContainer: FC<{
    script: ScriptLine;
}> = ({ script }) => {
    return <RepScriptItem script={script} />;
};

export default RepScriptItemContainer;
