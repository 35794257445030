import { useHistory } from '../../tools/router';
import { useCallback, useEffect } from 'react';
import { CompanySettingsProps } from './types';
import CompanySettingsView from './view';
import { useSelector } from 'react-redux';
import { getCompanyState } from './store/reducers';
import { useGetCompanyService } from './store/services';
import { getProfileState } from '../UserProfile/store/reducers';

function CompanySettings({
    closeGoingBack,
    closePath,
}: CompanySettingsProps) {
    const company = useSelector(getCompanyState);
    const profile = useSelector(getProfileState);
    const getCompany = useGetCompanyService();
    const history = useHistory();
    const prevLocation = history.location.state?.prevLocation ?? history.location.state?.background;

    const handleClose = useCallback(() => {
        if (closeGoingBack && prevLocation) {
            history.replace(prevLocation.pathname);
        } else {
            history.replace(closePath);
        }
    }, [closeGoingBack, closePath, history, prevLocation]);

    const createNavigationDescriptor = useCallback((route: string) => {
        return {
            pathname: route,
            state: { background: prevLocation },
        }
    }, [prevLocation]);

    const loadCompany = useCallback(
        () => getCompany(profile!.companyId),
        [getCompany, profile]
    );

    useEffect(() => {
        if (profile?.companyId) {
            loadCompany();
        }
    }, [getCompany, loadCompany, profile?.companyId]);

    return (
        <CompanySettingsView
            company={company}
            onCompanyUpdate={loadCompany}
            onClose={handleClose}
            createNavigationDescriptor={createNavigationDescriptor}
        />
    )
}

export default CompanySettings;