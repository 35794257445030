import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarRoleplaysActive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#gbq6l76f4a)" fill-rule="evenodd" clip-rule="evenodd" fill="#fff">
                    <path d="M15.791 14.988a.5.5 0 0 0 .271-.445v-8.11a1.84 1.84 0 0 0-1.84-1.84H12.97a.5.5 0 1 0 0 1h1.252a.84.84 0 0 1 .84.84v7.14l-1.786-1.276a2.237 2.237 0 0 0-1.3-.415H6.309a.84.84 0 0 1-.84-.841v-.42a.5.5 0 0 0-1 0v.42a1.84 1.84 0 0 0 1.84 1.84h5.669c.257 0 .508.081.718.23l2.577 1.839a.5.5 0 0 0 .52.038z" />
                    <path d="M.79 11.357A.5.5 0 0 1 0 10.95V2.84A1.84 1.84 0 0 1 1.84 1h9.255a.5.5 0 0 1 .5.5v5.948a1.84 1.84 0 0 1-1.841 1.84H4.086c-.258 0-.509.08-.718.23L.79 11.357zm2.612-6.845a.5.5 0 0 1 0-1h5.416a.5.5 0 1 1 0 1H3.402zm0 2.188a.5.5 0 0 1 0-1h5.416a.5.5 0 1 1 0 1H3.402z" />
                </g>
                <defs>
                    <clipPath id="gbq6l76f4a">
                        <path fill="#fff" d="M0 0h16v16H0z" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default SidebarRoleplaysActive;
