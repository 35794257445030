import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const fill = keyframes`
  from {
    width: 0;
  }

  to {
    width: 375px;
  }
`;

const Container = styled.div<{ duration: number; color: string }>`
    height: 48px;
    width: 375px;
    background: ${props => props.theme.Colors.whiteTwo};
    position: relative;
    &::before {
        content: '';
        height: 100%;
        background: ${props => props.color};
        animation: ${fill} ${props => props.duration}s linear;
        animation-fill-mode: forwards;
        position: absolute;
        left: 0;
        top: 0;
    }
`;

const EmptyContainer = styled.div<{ color: string }>`
    height: 48px;
    width: 375px;
    background: ${props => props.theme.Colors.whiteTwo};
    position: relative;
    &::before {
        content: '';
        height: 100%;
        background: ${props => props.color};
        position: absolute;
        left: 0;
        top: 0;
    }
`;

const ScriptLineDuration: FC<{
    duration: number;
    color: string;
    started: boolean;
}> = ({ duration, color, started }) => {
    if (!started) {
        return <EmptyContainer color={color} />;
    }
    return <Container duration={duration} color={color} />;
};

export default ScriptLineDuration;
