import { History } from 'history';
import { isPractisAdminRole, isUserRole } from '../../constants/enums';
import { UrlBuilder } from '../../tools/url/UrlBuilder';
import Storage from '../../services/Storage';
import { UserInterface, UserV2 } from '../../constants/interfaces/User';
import ROUTES from '../../routes/routes';
import { isSharedLink } from './url-helpers';

/**
 * @function setAuthToken - saves user token to Storage if needed
 * @param { UserInterface } userInfo
 * @param { string } token
 * @returns { void }
 */    
export const setAuthToken = (
    userInfo: UserInterface | UserV2,
    token: string
) => {
    const isPractisAdmin = isPractisAdminRole(userInfo.role?.name) && !userInfo.companyId;
    const isUser = isUserRole(userInfo.role?.name);
    if (!isPractisAdmin && !isUser)
        Storage.set('token', token);
    Storage.set('userId', userInfo.id);
}

/**
 * @function redirectAfterLogin - redirects to a proper page after login
 * @param { History } history
 * @param { UrlBuilder } urlBuilder
 * @param { UserInterface } userInfo
 * @param { string } token
 * @returns { void }
 */    
export const redirectAfterLogin = (
    history: History,
    urlBuilder: UrlBuilder,
    userInfo: UserInterface | UserV2,
    token: string,
    redirectTo?: Location
) => {
    if (isPractisAdminRole(userInfo.role?.name) && !userInfo.companyId) {
        window.location.href = urlBuilder.buildAdminUrl(
            {
                absolute: true,
                query: {
                    t: token,
                    id: userInfo.id,
                    p: Storage.get('apiPlatform')
                }
            }
        );
    } else if (isUserRole(userInfo.role?.name)) {
        if (redirectTo && isSharedLink(redirectTo.pathname)) {
            history.push(ROUTES.BROKEN_SHARED_LINK);
        } else {
            history.push(
                `/trainee-splash/${userInfo.firstName}`
            );
        }
    } else {
        history.push(redirectTo ?? ROUTES.SUBMISSIONS);
    }
}

/**
 * @function isAuthorized - checks if user has a saved token
 * @returns { boolean }
 */    
export const isAuthorized = () =>
    !!Storage.get('token') && !!Storage.get('userId');

/**
 * @function getCurrentUserId
 * @returns { number? }
 */    
export const getCurrentUserId = () => {
    const userId = Storage.get('userId');
    if (!!userId)
        return parseInt(userId);
    return null;
}

/**
 * @function clearAuthData
 */
export const clearAuthData = () => {
    Storage.remove('token');
    Storage.remove('userId');
}