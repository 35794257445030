import { useEffect } from 'react';
import { useGetCompanyUserStatsService } from '../../../pages/CompanySettings/store/services';
import { useCompanyUserStatsState } from '../../../pages/CompanySettings/store/states';
import { CompanyUserStatsInfoProps } from './types';
import CompanyUserStatsInfoView from './view';

function CompanyUserStatsInfo({
    companyId,
}: CompanyUserStatsInfoProps) {
    const userStatsState = useCompanyUserStatsState();
    const getCompanyUserStats = useGetCompanyUserStatsService();

    useEffect(() => {
        getCompanyUserStats(companyId);
    }, [companyId, getCompanyUserStats]);

    return (
        <CompanyUserStatsInfoView
            userStats={userStatsState.data}
        />
    );
}

export default CompanyUserStatsInfo;