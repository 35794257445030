import React from 'react';
import styled from 'styled-components';

const StyledNavigationItemContainer = styled.div<{
    isActive: boolean;
}>`
    height: 43px;
    margin-bottom: 2px;
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${props => props.theme.Colors.paleGrey};
    border-radius: 4px;
    color: ${props =>
        props.isActive
            ? props.theme.Colors.black
            : props.theme.Colors.steelGrey};
    background: ${props =>
        props.isActive ? 'unset' : props.theme.Colors.whiteFive};
    position: relative;
`;

const NavigationCover = styled.div`
    position: absolute;
    left: -1px;
    right: -1px;
    height: 10px;
    background: ${props => props.theme.Colors.white};
    bottom: -4px;
    border-left: 1px solid ${props => props.theme.Colors.paleGrey};
    border-right: 1px solid ${props => props.theme.Colors.paleGrey};
`;

const NavigationItem: React.FC<{
    title: string;
    action?: () => void;
    active?: boolean;
    dataTest?: string;
}> = ({ title, action, active, dataTest }) => {
    return (
        <StyledNavigationItemContainer onClick={action} isActive={!!active} data-test={dataTest}>
            {title}
            {active && <NavigationCover />}
        </StyledNavigationItemContainer>
    );
};

export default NavigationItem;
