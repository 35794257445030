import React, { FC, useRef, useState } from 'react';

import {
    ActionButton,
    ActionItem,
} from '../../../../ui/components/ActionButton';
import { CheckPermission } from '../../../permissions';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { PopupComponent } from '../../../../ui/components/Popup';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import {
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../labels/tools';
import { AssignLabels } from '../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { UserV2 } from '../../../../constants/interfaces/User';
import { usePractisSetsEnrollmentsService } from '../../store/services';
import { AssignPractisSets } from '../../../../features/assign-practisSets';

const UsersListActions: FC<{
    user: UserV2;
    handleViewProfile: (user: UserV2) => void;
    handleUserSettings: (user: UserV2) => void;
    handleItemEdit: (user: UserV2) => void;
    setShowNudgeDialogMemberId: (userId: number) => void;
    onExportClicked: (userIds: number[]) => void;
    setUserIdsToDelete: (userIds: number[]) => void;
    assignLabelsToUser: (
        addedLabelIds: number[],
        deletedLabelIds: number[]
    ) => void;
    isHidden?: boolean;
    successApplyPractisSetsCallback: () => void;
}> = ({
    user,
    handleViewProfile,
    handleUserSettings,
    handleItemEdit,
    setShowNudgeDialogMemberId,
    onExportClicked,
    setUserIdsToDelete,
    assignLabelsToUser,
    isHidden,
    successApplyPractisSetsCallback,
}) => {
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();
    const getDeletedLabels = useCalculateDeletedLabels();
    const getUserPractisSets = usePractisSetsEnrollmentsService(
        user.id?.toString()
    );

    const [isAssignPractisSetPopupOpen, setIsAssignPractisSetPopupOpen] =
        useState(false);

    const handleSetPreselectedLabels = () => {
        const selectedLabelIds = user.labels;
        const preAssignedLabels = calculatePreSelectedLabels(
            selectedLabelIds,
            labelsList.data
        );
        setSelectLabels(preAssignedLabels);
        saveLabels();
    };

    const handleSaveSelectedLabels = (labelIDs: number[]) => {
        const addedLabelIds = labelIDs.filter(id => id > 0);
        const deletedLabelIds = getDeletedLabels(labelIDs);

        assignLabelsToUser(addedLabelIds, deletedLabelIds);
    };

    const [assignSingleLabelOpen, setAssignSingleLabelOpen] = useState<
        null | number | undefined
    >(null);

    const triggerRef = useRef(null);

    /**
     * @function handleOpenAssignPractisSetPopup
     * @returns { void }
     */
    const handleOpenAssignPractisSetPopup = (): void => {
        getUserPractisSets(() => setIsAssignPractisSetPopupOpen(true));
    };

    /**
     * @function handleCloseAssignPractisSetPopup
     * @returns { void }
     */
    const handleCloseAssignPractisSetPopup = (): void => {
        setIsAssignPractisSetPopupOpen(false);
    };

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton customWidth={200} isHidden={isHidden} dataTest="list-item-menu">
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.VIEW_USER_PERFORMANCE]}
                    >
                        <ActionItem onClick={() => handleViewProfile(user)} dataTest="view-profile-action">
                            View Profile
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.VIEW_USER_PERFORMANCE]}
                    >
                        <ActionItem onClick={() => handleUserSettings(user)} dataTest="user-settings-action">
                            User Settings
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.ASSIGN_ENROLLMENT]}
                    >
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleOpenAssignPractisSetPopup();
                            }}
                            dataTest="assign-practis-sets-action"
                        >
                            Assign Practis Sets
                        </ActionItem>
                    </CheckPermission>
                    {!!labelsList.data.items.length && (
                        <CheckPermission
                            permissions={[NEW_PERMISSIONS.ASSIGN_USER_LABEL]}
                        >
                            <ActionItem
                                onClick={e => {
                                    e.stopPropagation();
                                    setAssignSingleLabelOpen(user.id);
                                    handleSetPreselectedLabels();
                                }}
                                dataTest="assign-labels-action"
                            >
                                Assign Labels
                            </ActionItem>
                        </CheckPermission>
                    )}
                    <CheckPermission permissions={[NEW_PERMISSIONS.SEND_NUDGE]}>
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                setShowNudgeDialogMemberId(user.id!);
                            }}
                            dataTest="nudge-user-action"
                        >
                            Nudge User
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.EXPORT_LIST_USER]}
                    >
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                onExportClicked([user.id!]);
                            }}
                            dataTest="export-report-action"
                        >
                            Export Report
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.DELETE_USER]}
                    >
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                setUserIdsToDelete([user.id!]);
                            }}
                            dataTest="delete-user-action"
                        >
                            Deactivate User
                        </ActionItem>
                    </CheckPermission>
                </ActionButton>
            </div>
            <PopupComponent
                triggerRef={triggerRef}
                open={assignSingleLabelOpen === user.id}
                onDismiss={() => {
                    setAssignSingleLabelOpen(null);
                }}
            >
                <AssignLabels
                    onLabelsChange={handleSaveSelectedLabels}
                    onSuccessApply={() => {
                        setAssignSingleLabelOpen(null);
                    }}
                    withBorder={true}
                />
            </PopupComponent>

            {isAssignPractisSetPopupOpen && (
                <AssignPractisSets
                    triggerRef={triggerRef}
                    isOpen={isAssignPractisSetPopupOpen}
                    onClose={handleCloseAssignPractisSetPopup}
                    users={[user.id]}
                    successApplyPractisSetsCallback={
                        successApplyPractisSetsCallback
                    }
                />
            )}
        </span>
    );
};

export default UsersListActions;



