import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    FileUploadApiParams,
    CreateMediaApiParams,
    VerifyMediaApiParams,
} from './types';

/**
 * @function useFileUploadApi
 * @returns { FileUploadApiParams }
 */
export const useFileUploadApi = (): FileUploadApiParams => {
    const http = useHttp();
    return useCallback(data => http.post('files', data), [http]);
};

/**
 * @function useCreateMedia
 * @returns { CreateMediaApiParams }
 */
export function useCreateMediaApi(): CreateMediaApiParams {
    const http = useHttp();
    return useCallback(mimeType => http.post('media', { mimeType }, true), [http]);
}

/**
 * @function useVerifyMedia
 * @returns { VerifyMediaApiParams }
 */
export function useVerifyMediaApi(): VerifyMediaApiParams {
    const http = useHttp();
    return useCallback(mediaId => http.post(`media/${mediaId}/verify`, undefined, true), [http]);
}