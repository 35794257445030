import { Team, TeamV2 } from '../../../constants/interfaces/Team';
import { PaginationResult, ListResult } from '../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { TrainingPractisSet } from '../../../constants/interfaces/TrainingPractisSet';
import { UserV2 } from '../../../constants/interfaces/User';
import {
    EnrollmentDailyTraining,
    EnrollmentInterface,
    EnrollmentProgress,
    Enrollments,
} from '../../../constants/interfaces/Enrollments';
import { Label } from '../../../constants/interfaces/Label';
import { Action } from 'redux';
import { TeamMember } from '../../../constants/interfaces/TeamMember';
import { TeamLeader } from '../../../constants/interfaces/TeamLeader';

export enum ACTIONS {
    // Create new team start
    SEARCH_SINGLE_TEAM_START = 'SEARCH_SINGLE_TEAM_START',
    SEARCH_SINGLE_TEAM_SUCCESS = 'SEARCH_SINGLE_TEAM_SUCCESS',
    SEARCH_SINGLE_TEAM_FAILURE = 'SEARCH_SINGLE_TEAM_FAILURE',
    SEARCH_SINGLE_TEAM_ALL_USERS_START = 'SEARCH_SINGLE_TEAM_ALL_USERS_START',
    SEARCH_SINGLE_TEAM_ALL_USERS_SUCCESS = 'SEARCH_SINGLE_TEAM_ALL_USERS_SUCCESS',
    SEARCH_SINGLE_TEAM_ALL_USERS_FAILURE = 'SEARCH_SINGLE_TEAM_ALL_USERS_FAILURE',
    SEARCH_SINGLE_TEAM_MEMBERS_START = 'SEARCH_SINGLE_TEAM_MEMBERS_START',
    SEARCH_SINGLE_TEAM_MEMBERS_SUCCESS = 'SEARCH_SINGLE_TEAM_MEMBERS_SUCCESS',
    SEARCH_SINGLE_TEAM_MEMBERS_FAILURE = 'SEARCH_SINGLE_TEAM_MEMBERS_FAILURE',
    MODIFY_TEAM_NAME = 'MODIFY_TEAM_NAME',
    UPDATE_TEAM_LEAD = 'UPDATE_TEAM_LEAD',
    SET_ASSIGNED_LABELS_TO_SINGLE_TEAM = 'SET_ASSIGNED_LABELS_TO_SINGLE_TEAM',
    SET_DELETED_LABELS_TO_SINGLE_TEAM = 'SET_DELETED_LABELS_TO_SINGLE_TEAM',
    RESET_SINGLE_TEAM_PAGE = 'RESET_SINGLE_TEAM_PAGE',
    REMOVE_SINGLE_TEAM_ALL_USERS_LABEL = 'REMOVE_SINGLE_TEAM_ALL_USERS_LABEL',
    REMOVE_SINGLE_TEAM_MEMBER_LABEL = 'REMOVE_SINGLE_TEAM_MEMBER_LABEL',

    CREATE_SINGLE_TEAM_SUCCESS = 'CREATE_SINGLE_TEAM_SUCCESS',

    UPDATE_SINGLE_TEAM_START = 'UPDATE_SINGLE_TEAM_START',
    UPDATE_SINGLE_TEAM_SUCCESS = 'UPDATE_SINGLE_TEAM_SUCCESS',
    UPDATE_SINGLE_TEAM_FAILURE = 'UPDATE_SINGLE_TEAM_FAILURE',
    UPDATE_SINGLE_TEAM_STOP = 'UPDATE_SINGLE_TEAM_STOP',

    CLEAR_TEAM_UPDATE_STATUS = 'CLEAR_TEAM_UPDATE_STATUS',

    FETCH_ALL_TEAMS = 'FETCH_ALL_TEAMS',
    FETCH_ALL_TEAMS_SUCCESS = 'FETCH_ALL_TEAMS_SUCCESS',
    FETCH_ALL_TEAMS_FAILURE = 'FETCH_ALL_TEAMS_FAILURE',

    UPDATE_SELECTED_TEAM_STATE = 'UPDATE_SELECTED_TEAM_STATE',
    UPDATE_ALL_SELECTED_TEAMS_STATE = 'UPDATE_ALL_SELECTED_TEAMS_STATE',

    UPDATE_TEAM_START = 'UPDATE_TEAM_START',
    UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS',
    UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE',

    UPDATE_TRAINEE_DUE_DATE = 'UPDATE_TRAINEE_DUE_DATE',

    UPDATE_ALL_SELECTED_TEAM_PRACTIS_SETS_STATE = 'UPDATE_ALL_SELECTED_TEAM_PRACTIS_SETS_STATE',
    UPDATE_SELECTED_TEAM_PRACTIS_SET_STATE = 'UPDATE_SELECTED_TEAM_PRACTIS_SET_STATE',
    UPDATE_TEAM_PROGRESS_SUCCESS = 'UPDATE_TEAM_PROGRESS_SUCCESS',

    SEARCH_TEAM_PRACTIS_SETS_START = 'SEARCH_TEAM_PRACTIS_SETS_START',
    SEARCH_TEAM_PRACTIS_SETS_SUCCESS = 'SEARCH_TEAM_PRACTIS_SETS_SUCCESS',
    SEARCH_TEAM_PRACTIS_SETS_FAILURE = 'SEARCH_TEAM_PRACTIS_SETS_FAILURE',

    SEARCH_TRAINER_PRACTIS_SETS_START = 'SEARCH_TRAINER_PRACTIS_SETS',
    SEARCH_TRAINER_PRACTIS_SETS_SUCCESS = 'SEARCH_TRAINER_PRACTIS_SETS_SUCCESS',
    SEARCH_TRAINER_PRACTIS_SETS_FAILURE = 'SEARCH_TRAINER_PRACTIS_SETS_FAILURE',

    UPDATE_PROGRESS_START = 'UPDATE_PROGRESS_START',
    UPDATE_PROGRESS_SUCCESS = 'UPDATE_PROGRESS_SUCCESS',
    UPDATE_PROGRESS_FAILURE = 'UPDATE_PROGRESS_FAILURE',

    SEARCH_TRAINER_PRACTIS_USERS_START = 'SEARCH_TRAINER_PRACTIS_USERS_START',
    SEARCH_TRAINER_PRACTIS_USERS_SUCCESS = 'SEARCH_TRAINER_PRACTIS_USERS_SUCCESS',
    SEARCH_TRAINER_PRACTIS_USERS_FAILURE = 'SEARCH_TRAINER_PRACTIS_USERS_FAILURE',
    CLEAR_TRAINER_PRACTIS_SETS_SUCCESS = 'CLEAR_TRAINER_PRACTIS_SETS_SUCCESS',

    SEARCH_TRAINER_INVITATION_USERS_START = 'SEARCH_TRAINER_INVITATION_USERS_START',
    SEARCH_TRAINER_INVITATION_USERS_SUCCESS = 'SEARCH_TRAINER_INVITATION_USERS_SUCCESS',
    SEARCH_TRAINER_INVITATION_USERS_FAILURE = 'SEARCH_TRAINER_INVITATION_USERS_FAILURE',

    SEND_TRAINEE_INVITATION_START = 'SEND_TRAINEE_INVITATION_START',
    SEND_TRAINEE_INVITATION_SUCCESS = 'SEND_TRAINEE_INVITATION_SUCCESS',
    SEND_TRAINEE_INVITATION_FAILURE = 'SEND_TRAINEE_INVITATION_FAILURE',
    UPDATE_SELECTED_INVITATION_TRAINEES_STATE = 'UPDATE_SELECTED_INVITATION_TRAINEES_STATE',
    UPDATE_ALL_SELECTED_INVITATION_TRAINEES_STATE = 'UPDATE_ALL_SELECTED_INVITATION_TRAINEES_STATE',

    GET_PRACTIS_SET_REPORT_START = 'GET_PRACTIS_SET_REPORT_START',
    GET_PRACTIS_SET_REPORT_SUCCESS = 'GET_PRACTIS_SET_REPORT_SUCCESS',
    GET_PRACTIS_SET_REPORT_FAILURE = 'GET_PRACTIS_SET_REPORT_FAILURE',
    RESET_PRACTIS_SET_REPORT = 'RESET_PRACTIS_SET_REPORT',
    RESET_PRACTIS_SET_DETAILS = 'RESET_PRACTIS_SET_DETAILS',

    GET_PRACTIS_SET_START = 'GET_PRACTIS_SET_START',
    GET_PRACTIS_SET_SUCCESS = 'GET_PRACTIS_SET_SUCCESS',
    GET_PRACTIS_SET_FAILURE = 'GET_PRACTIS_SET_FAILURE',

    CHECK_ALL_PROGRESS = 'CHECK_ALL_PROGRESS',
    CHECK_SINGLE_PROGRESS = 'CHECK_SINGLE_PROGRESS',

    UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE = 'UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE',
    UPDATE_SELECTED_PRACTIS_SET_STATE = 'UPDATE_SELECTED_PRACTIS_SET_STATE',

    UPDATE_MEMBER_START = 'UPDATE_MEMBER_START',
    UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS',
    UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE',

    GET_TEAM_INFO_START = 'GET_TEAM_INFO_START',
    GET_TEAM_INFO_SUCCESS = 'GET_TEAM_INFO_SUCCESS',
    GET_TEAM_INFO_FAILURE = 'GET_TEAM_INFO_FAILURE',
    RESET_TEAM_INFO = 'RESET_TEAM_INFO',

    GET_TEAM_INFO_TRAINING_START = 'GET_TEAM_INFO_TRAINING_START',
    GET_TEAM_INFO_TRAINING_SUCCESS = 'GET_TEAM_INFO_TRAINING_SUCCESS',
    GET_TEAM_INFO_TRAINING_FAILURE = 'GET_TEAM_INFO_TRAINING_FAILURE',

    SEARCH_TEAM_MEMBERS_START = 'SEARCH_TEAM_MEMBERS_START',
    SEARCH_TEAM_MEMBERS_SUCCESS = 'SEARCH_TEAM_MEMBERS_SUCCESS',
    SEARCH_TEAM_MEMBERS_FAILURE = 'SEARCH_TEAM_MEMBERS_FAILURE',

    SEARCH_TEAM_LEADERS_START = 'SEARCH_TEAM_LEADERS_START',
    SEARCH_TEAM_LEADERS_SUCCESS = 'SEARCH_TEAM_LEADERS_SUCCESS',
    SEARCH_TEAM_LEADERS_FAILURE = 'SEARCH_TEAM_LEADERS_FAILURE',
    SELECT_TEAM_LEADER = 'SELECT_TEAM_LEADER',
    SELECT_MULTIPLE_TEAM_LEADERS = 'SELECT_MULTIPLE_TEAM_LEADERS',
    RESET_TEAM_LEADERS_SELECTION = 'RESET_TEAM_LEADERS_SELECTION',
    DESELECT_ALL_TEAM_LEADERS_SELECTION = 'DESELECT_ALL_TEAM_LEADERS_SELECTION',
    SAVE_TEAM_LEADERS_SELECTION = 'SAVE_TEAM_LEADERS_SELECTION',
}

export function searchSingleTeamStart() {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_START as ACTIONS.SEARCH_SINGLE_TEAM_START,
    };
}

export function searchSingleTeamSuccess(data: TeamV2) {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_SUCCESS as ACTIONS.SEARCH_SINGLE_TEAM_SUCCESS,
        data,
    };
}

export function searchSingleTeamFailure(error: any) {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_FAILURE as ACTIONS.SEARCH_SINGLE_TEAM_FAILURE,
        error,
    };
}

export function getTeamInfoStart() {
    return {
        type: ACTIONS.GET_TEAM_INFO_START as ACTIONS.GET_TEAM_INFO_START,
    };
}

export function getTeamInfoSuccess(data: TeamV2) {
    return {
        type: ACTIONS.GET_TEAM_INFO_SUCCESS as ACTIONS.GET_TEAM_INFO_SUCCESS,
        data,
    };
}

export function getTeamInfoFailure(error: any) {
    return {
        type: ACTIONS.GET_TEAM_INFO_FAILURE as ACTIONS.GET_TEAM_INFO_FAILURE,
        error,
    };
}

export function resetTeamInfo() {
    return {
        type: ACTIONS.RESET_TEAM_INFO as ACTIONS.RESET_TEAM_INFO,
    };
}

export function getTeamInfoTrainingStart() {
    return {
        type: ACTIONS.GET_TEAM_INFO_TRAINING_START as ACTIONS.GET_TEAM_INFO_TRAINING_START,
    };
}

export function getTeamInfoTrainingSuccess(data: TeamV2) {
    return {
        type: ACTIONS.GET_TEAM_INFO_TRAINING_SUCCESS as ACTIONS.GET_TEAM_INFO_TRAINING_SUCCESS,
        data,
    };
}

export function getTeamInfoTrainingFailure(error: any) {
    return {
        type: ACTIONS.GET_TEAM_INFO_TRAINING_FAILURE as ACTIONS.GET_TEAM_INFO_TRAINING_FAILURE,
        error,
    };
}

export function searchSingleTeamAllUsersStart() {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_START as ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_START,
    };
}

export function searchSingleTeamAllUsersSuccess(
    data: ListResult<UserV2>,
    offset: number,
    isRefreshed?: boolean
) {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_SUCCESS as ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_SUCCESS,
        data,
        offset,
        isRefreshed,
    };
}

export function searchSingleTeamAllUsersFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_FAILURE as ACTIONS.SEARCH_SINGLE_TEAM_ALL_USERS_FAILURE,
        error,
    };
}

export function searchSingleTeamMembersStart() {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_START as ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_START,
    };
}

export function searchSingleTeamMembersSuccess(
    data: ListResult<TeamMember>,
    offset: number,
    isRefreshed?: boolean
) {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_SUCCESS as ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_SUCCESS,
        data,
        offset,
        isRefreshed,
    };
}

export function searchSingleTeamMembersFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_FAILURE as ACTIONS.SEARCH_SINGLE_TEAM_MEMBERS_FAILURE,
        error,
    };
}

export function modifyTeamName(value: string) {
    return {
        type: ACTIONS.MODIFY_TEAM_NAME as ACTIONS.MODIFY_TEAM_NAME,
        value,
    };
}

export function updateTeamLead(teamUserId: number, isTeamLead: boolean) {
    return {
        type: ACTIONS.UPDATE_TEAM_LEAD as ACTIONS.UPDATE_TEAM_LEAD,
        teamUserId,
        isTeamLead,
    };
}

export function setAssignedLabelsToSingleTeam(labelIDs: number[]) {
    return {
        type: ACTIONS.SET_ASSIGNED_LABELS_TO_SINGLE_TEAM as ACTIONS.SET_ASSIGNED_LABELS_TO_SINGLE_TEAM,
        labelIDs,
    };
}

export function setDeletedLabelsToSingleTeam(labelIDs: number[]) {
    return {
        type: ACTIONS.SET_DELETED_LABELS_TO_SINGLE_TEAM as ACTIONS.SET_DELETED_LABELS_TO_SINGLE_TEAM,
        labelIDs,
    };
}

export function resetSingleTeamPage() {
    return {
        type: ACTIONS.RESET_SINGLE_TEAM_PAGE as ACTIONS.RESET_SINGLE_TEAM_PAGE,
    };
}

export function removeSingleTeamAllUsersLabel(userId: number, labelId: number) {
    return {
        type: ACTIONS.REMOVE_SINGLE_TEAM_ALL_USERS_LABEL as ACTIONS.REMOVE_SINGLE_TEAM_ALL_USERS_LABEL,
        userId,
    labelId
    };
}

export function removeSingleTeamMemberLabel(userId: number, labelId: number) {
    return {
        type: ACTIONS.REMOVE_SINGLE_TEAM_MEMBER_LABEL as ACTIONS.REMOVE_SINGLE_TEAM_MEMBER_LABEL,
        userId,
        labelId
    };
}

export function createSingleTeamSuccess(team: Partial<TeamV2>) {
    return {
        type: ACTIONS.CREATE_SINGLE_TEAM_SUCCESS as ACTIONS.CREATE_SINGLE_TEAM_SUCCESS,
        team,
    };
}

export function updateSingleTeamStart() {
    return {
        type: ACTIONS.UPDATE_SINGLE_TEAM_START as ACTIONS.UPDATE_SINGLE_TEAM_START,
    };
}

export function updateSingleTeamSuccess(team?: Partial<TeamV2>) {
    return {
        type: ACTIONS.UPDATE_SINGLE_TEAM_SUCCESS as ACTIONS.UPDATE_SINGLE_TEAM_SUCCESS,
        team,
    };
}

export function updateSingleTeamFailure(error?: string) {
    return {
        type: ACTIONS.UPDATE_SINGLE_TEAM_FAILURE as ACTIONS.UPDATE_SINGLE_TEAM_FAILURE,
        error,
    };
}

export function updateSingleTeamStop() {
    return {
        type: ACTIONS.UPDATE_SINGLE_TEAM_STOP as ACTIONS.UPDATE_SINGLE_TEAM_STOP,
    };
}

export function clearTeamUpdateStatus() {
    return {
        type: ACTIONS.CLEAR_TEAM_UPDATE_STATUS as ACTIONS.CLEAR_TEAM_UPDATE_STATUS,
    };
}

export function searchTeamPractisSetsStart() {
    return {
        type: ACTIONS.SEARCH_TEAM_PRACTIS_SETS_START as ACTIONS.SEARCH_TEAM_PRACTIS_SETS_START,
    };
}

export function searchTeamPractisSetsSuccess(
    practisSets: PaginationResult<PractisSets>
) {
    return {
        type: ACTIONS.SEARCH_TEAM_PRACTIS_SETS_SUCCESS as ACTIONS.SEARCH_TEAM_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function searchTeamPractisSetsFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_TEAM_PRACTIS_SETS_FAILURE as ACTIONS.SEARCH_TEAM_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function fetchAllTeamsStart() {
    return {
        type: ACTIONS.FETCH_ALL_TEAMS as ACTIONS.FETCH_ALL_TEAMS,
    };
}

export function fetchAllTeamsSuccess(data: ListResult<Team>) {
    return {
        type: ACTIONS.FETCH_ALL_TEAMS_SUCCESS as ACTIONS.FETCH_ALL_TEAMS_SUCCESS,
        data,
    };
}

export function fetchAllTeamsFailure(error: string) {
    return {
        type: ACTIONS.FETCH_ALL_TEAMS_FAILURE as ACTIONS.FETCH_ALL_TEAMS_FAILURE,
        error,
    };
}

export function updateSelectedTeamState(teamId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_TEAM_STATE as ACTIONS.UPDATE_SELECTED_TEAM_STATE,
        teamId,
    };
}

export function updateAllSelectedTeamsState(
    teamIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_TEAMS_STATE as ACTIONS.UPDATE_ALL_SELECTED_TEAMS_STATE,
        teamIds,
        checked,
        partial,
    };
}

export function updateMemberActionStart() {
    return {
        type: ACTIONS.UPDATE_MEMBER_START as ACTIONS.UPDATE_MEMBER_START,
    };
}

export function updateMemberActionSuccess(
    team: any,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_MEMBER_SUCCESS as ACTIONS.UPDATE_MEMBER_SUCCESS,
        team,
        updateType,
    };
}

export function updateMemberActionFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_MEMBER_FAILURE as ACTIONS.UPDATE_MEMBER_FAILURE,
        error,
    };
}

export function updateTeamActionStart() {
    return {
        type: ACTIONS.UPDATE_TEAM_START as ACTIONS.UPDATE_TEAM_START,
    };
}

export function updateTeamActionSuccess(
    team: any,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_TEAM_SUCCESS as ACTIONS.UPDATE_TEAM_SUCCESS,
        team,
        updateType,
    };
}

export function updateTeamActionFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_TEAM_FAILURE as ACTIONS.UPDATE_TEAM_FAILURE,
        error,
    };
}

export function updateTraineeDueDateAction(trainees: number[], dueDate: Date | null) {
    return {
        type: ACTIONS.UPDATE_TRAINEE_DUE_DATE as ACTIONS.UPDATE_TRAINEE_DUE_DATE,
        trainees,
        dueDate,
    };
}

export function updateAllSelectedTeamPractisSetsState(
    setIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_TEAM_PRACTIS_SETS_STATE as ACTIONS.UPDATE_ALL_SELECTED_TEAM_PRACTIS_SETS_STATE,
        setIds,
        checked,
        partial,
    };
}

export function updateSelectedTeamPractisSetState(setId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_TEAM_PRACTIS_SET_STATE as ACTIONS.UPDATE_SELECTED_TEAM_PRACTIS_SET_STATE,
        setId,
    };
}

export function updateAllSelectedPractisSetsState(
    setIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE as ACTIONS.UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE,
        setIds,
        checked,
        partial,
    };
}

export function updateSelectedPractisSetState(setId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_PRACTIS_SET_STATE as ACTIONS.UPDATE_SELECTED_PRACTIS_SET_STATE,
        setId,
    };
}

export function searchTrainerStart() {
    return {
        type: ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_START as ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_START,
    };
}

export function searchTrainerSuccess(
    practisSets: ListResult<TrainingPractisSet>
) {
    return {
        type: ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_SUCCESS as ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_SUCCESS,
        practisSets,
    };
}

export function clearTrainerPractisSet() {
    return {
        type: ACTIONS.CLEAR_TRAINER_PRACTIS_SETS_SUCCESS as ACTIONS.CLEAR_TRAINER_PRACTIS_SETS_SUCCESS,
    };
}

export function searchTrainerFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_FAILURE as ACTIONS.SEARCH_TRAINER_PRACTIS_SETS_FAILURE,
        error,
    };
}

export function updatedProgressActionStart() {
    return {
        type: ACTIONS.UPDATE_PROGRESS_START as ACTIONS.UPDATE_PROGRESS_START,
    };
}

export function updatedProgressActionSuccess(
    progress: PractisSets,
    updateType: 'create' | 'update' | 'delete' | 'labels'
) {
    return {
        type: ACTIONS.UPDATE_PROGRESS_SUCCESS as ACTIONS.UPDATE_PROGRESS_SUCCESS,
        progress,
        updateType,
    };
}

export function updatedProgressActionFailure(error: any) {
    return {
        type: ACTIONS.UPDATE_PROGRESS_FAILURE as ACTIONS.UPDATE_PROGRESS_FAILURE,
        error,
    };
}

export function searchPractisUsersStart() {
    return {
        type: ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_START as ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_START,
    };
}

export function searchPractisUsersSuccess(
    users: PaginationResult<EnrollmentInterface>
) {
    return {
        type: ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_SUCCESS as ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_SUCCESS,
        users,
    };
}

export function searchPractisUsersFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_FAILURE as ACTIONS.SEARCH_TRAINER_PRACTIS_USERS_FAILURE,
        error,
    };
}

export function searchInvitationUsersStart() {
    return {
        type: ACTIONS.SEARCH_TRAINER_INVITATION_USERS_START as ACTIONS.SEARCH_TRAINER_INVITATION_USERS_START,
    };
}

export function searchInvitationUsersSuccess(
    users: ListResult<UserV2>,
    offset: number,
    isRefreshed?: boolean,
) {
    return {
        type: ACTIONS.SEARCH_TRAINER_INVITATION_USERS_SUCCESS as ACTIONS.SEARCH_TRAINER_INVITATION_USERS_SUCCESS,
        users,
        offset,
        isRefreshed,
    };
}

export function searchInvitationUsersFailure(error: string) {
    return {
        type: ACTIONS.SEARCH_TRAINER_INVITATION_USERS_FAILURE as ACTIONS.SEARCH_TRAINER_INVITATION_USERS_FAILURE,
        error,
    };
}

export function sendTraineeInvitationStart() {
    return {
        type: ACTIONS.SEND_TRAINEE_INVITATION_START as ACTIONS.SEND_TRAINEE_INVITATION_START,
    };
}

export function sendTraineeInvitationSuccess(
    enrollments: Enrollments,
    inviteType: 'invite' | 'reInvite' | 'unassign'
) {
    return {
        type: ACTIONS.SEND_TRAINEE_INVITATION_SUCCESS as ACTIONS.SEND_TRAINEE_INVITATION_SUCCESS,
        enrollments,
        inviteType,
    };
}

export function sendTraineeInvitationFailure(error: string) {
    return {
        type: ACTIONS.SEND_TRAINEE_INVITATION_FAILURE as ACTIONS.SEND_TRAINEE_INVITATION_FAILURE,
        error,
    };
}
export function getPractisSetStart() {
    return {
        type: ACTIONS.GET_PRACTIS_SET_START as ACTIONS.GET_PRACTIS_SET_START,
    };
}

export function getPractisSetSuccess(practisSet: PractisSets) {
    return {
        type: ACTIONS.GET_PRACTIS_SET_SUCCESS as ACTIONS.GET_PRACTIS_SET_SUCCESS,
        practisSet,
    };
}

export function getPractisSetFailure(error: string) {
    return {
        type: ACTIONS.GET_PRACTIS_SET_FAILURE as ACTIONS.GET_PRACTIS_SET_FAILURE,
        error,
    };
}

export function getPractisSetReportStart() {
    return {
        type: ACTIONS.GET_PRACTIS_SET_REPORT_START as ACTIONS.GET_PRACTIS_SET_REPORT_START,
    };
}

export function getPractisSetReportSuccess(
    enrollment: EnrollmentInterface,
    progress: ListResult<EnrollmentProgress>,
    dailyTraining: EnrollmentDailyTraining[]
) {
    return {
        type: ACTIONS.GET_PRACTIS_SET_REPORT_SUCCESS as ACTIONS.GET_PRACTIS_SET_REPORT_SUCCESS,
        enrollment,
        progress,
        dailyTraining,
    };
}

export function getPractisSetReportFailure(error: string) {
    return {
        type: ACTIONS.GET_PRACTIS_SET_REPORT_FAILURE as ACTIONS.GET_PRACTIS_SET_REPORT_FAILURE,
        error,
    };
}

export function resetPractisSetDetails() {
    return {
        type: ACTIONS.RESET_PRACTIS_SET_DETAILS as ACTIONS.RESET_PRACTIS_SET_DETAILS,
    };
}

export function resetPractisSetReport() {
    return {
        type: ACTIONS.RESET_PRACTIS_SET_REPORT as ACTIONS.RESET_PRACTIS_SET_REPORT,
    };
}

export function checkSingleProgress(id: number) {
    return {
        type: ACTIONS.CHECK_SINGLE_PROGRESS as ACTIONS.CHECK_SINGLE_PROGRESS,
        id,
    };
}

export function updateAllSelectedInvitationTraineesState(
    userIds: number[],
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.UPDATE_ALL_SELECTED_INVITATION_TRAINEES_STATE as ACTIONS.UPDATE_ALL_SELECTED_INVITATION_TRAINEES_STATE,
        userIds,
        checked,
        partial,
    };
}

export function updateSelectedInvitationTraineesState(userId: number) {
    return {
        type: ACTIONS.UPDATE_SELECTED_INVITATION_TRAINEES_STATE as ACTIONS.UPDATE_SELECTED_INVITATION_TRAINEES_STATE,
        userId,
    };
}

export function checkAllProgress(
    progressId: any,
    checked: boolean,
    partial?: boolean
) {
    return {
        type: ACTIONS.CHECK_ALL_PROGRESS as ACTIONS.CHECK_ALL_PROGRESS,
        progressId,
        checked,
        partial,
    };
}

export enum INVITATION_LABEL_ACTIONS {
    SEARCH_LABELS_START = 'SEARCH_INVITATION_LABELS_START',
    SEARCH_LABELS_SUCCESS = 'SEARCH__INVITATION_LABELS_SUCCESS',
    SEARCH_LABELS_FAILURE = 'SEARCH_INVITATION_LABELS_FAILURE',

    TOGGLE_COLLAPSE_INVITATION_LABEL_FIELD = 'TOGGLE_COLLAPSE_INVITATION_LABEL_FIELD',
    SAVE_SELECTED_INVITATION_LABELS = 'SAVE_SELECTED_INVITATION_LABELS',

    SET_INVITATION_LABEL_FILTERS = 'SET_INVITATION_LABEL_FILTERS',
    CLEAR_LABEL_FILTERS = 'CLEAR_INVITATION_LABEL_FILTERS',
}

export function searchLabelsStart(withLoading?: boolean) {
    return {
        type: INVITATION_LABEL_ACTIONS.SEARCH_LABELS_START as INVITATION_LABEL_ACTIONS.SEARCH_LABELS_START,
        withLoading,
    };
}

export function searchLabelsSuccess(
    data: PaginationResult<Label>,
    searchTerm?: boolean
) {
    return {
        type: INVITATION_LABEL_ACTIONS.SEARCH_LABELS_SUCCESS as INVITATION_LABEL_ACTIONS.SEARCH_LABELS_SUCCESS,
        data,
        searchTerm,
    };
}

export function searchLabelsFailure(error: string) {
    return {
        type: INVITATION_LABEL_ACTIONS.SEARCH_LABELS_FAILURE as INVITATION_LABEL_ACTIONS.SEARCH_LABELS_FAILURE,
        error,
    };
}

export function toggleCollapseLabelFieldAction(labelId: number) {
    return {
        type: INVITATION_LABEL_ACTIONS.TOGGLE_COLLAPSE_INVITATION_LABEL_FIELD as INVITATION_LABEL_ACTIONS.TOGGLE_COLLAPSE_INVITATION_LABEL_FIELD,
        labelId,
    };
}

export function setLabelFilterAction(labels: number[]) {
    return {
        type: INVITATION_LABEL_ACTIONS.SET_INVITATION_LABEL_FILTERS as INVITATION_LABEL_ACTIONS.SET_INVITATION_LABEL_FILTERS,
        labels,
    };
}

export function saveSelectedInvitationLabels() {
    return {
        type: INVITATION_LABEL_ACTIONS.SAVE_SELECTED_INVITATION_LABELS as INVITATION_LABEL_ACTIONS.SAVE_SELECTED_INVITATION_LABELS,
    };
}

export function clearLabelFilterAction() {
    return {
        type: INVITATION_LABEL_ACTIONS.CLEAR_LABEL_FILTERS as INVITATION_LABEL_ACTIONS.CLEAR_LABEL_FILTERS,
    };
}

export const searchTeamMembersStartAction = (): Action<ACTIONS.SEARCH_TEAM_MEMBERS_START> => {
    return {
        type: ACTIONS.SEARCH_TEAM_MEMBERS_START,
    };
};

export const searchTeamMembersSuccessAction = (
    data: ListResult<TeamMember>
): Action<ACTIONS.SEARCH_TEAM_MEMBERS_SUCCESS> & {
    data: ListResult<TeamMember>;
} => {
    return {
        type: ACTIONS.SEARCH_TEAM_MEMBERS_SUCCESS,
        data,
    };
};

export const searchTeamMembersFailureAction = (
    error: string
): Action<ACTIONS.SEARCH_TEAM_MEMBERS_FAILURE> & { error: string } => {
    return {
        type: ACTIONS.SEARCH_TEAM_MEMBERS_FAILURE,
        error,
    };
};

export const searchTeamLeadersStartAction = () => {
    return {
        type: ACTIONS.SEARCH_TEAM_LEADERS_START as ACTIONS.SEARCH_TEAM_LEADERS_START,
    };
};

export const searchTeamLeadersSuccessAction = (
    data: PaginationResult<TeamLeader>
) => {
    return {
        type: ACTIONS.SEARCH_TEAM_LEADERS_SUCCESS as ACTIONS.SEARCH_TEAM_LEADERS_SUCCESS,
        data,
    };
};

export const searchTeamLeadersFailureAction = (error: string) => {
    return {
        type: ACTIONS.SEARCH_TEAM_LEADERS_FAILURE as ACTIONS.SEARCH_TEAM_LEADERS_FAILURE,
        error,
    };
};

export const selectTeamLeaderAction = (leaderId: number) => {
    return {
        type: ACTIONS.SELECT_TEAM_LEADER as ACTIONS.SELECT_TEAM_LEADER,
        leaderId,
    };
};

export const selectMultipleTeamLeaderAction = (leaderIds: number[]) => {
    return {
        type: ACTIONS.SELECT_MULTIPLE_TEAM_LEADERS as ACTIONS.SELECT_MULTIPLE_TEAM_LEADERS,
        leaderIds,
    };
};

export const resetTeamLeadersSelectionAction = () => {
    return {
        type: ACTIONS.RESET_TEAM_LEADERS_SELECTION as ACTIONS.RESET_TEAM_LEADERS_SELECTION,
    };
};

export const deselectAllTeamLeadersSelectionAction = () => {
    return {
        type: ACTIONS.DESELECT_ALL_TEAM_LEADERS_SELECTION as ACTIONS.DESELECT_ALL_TEAM_LEADERS_SELECTION,
    };
};

export const saveTeamLeaderSelection = () => {
    return {
        type: ACTIONS.SAVE_TEAM_LEADERS_SELECTION as ACTIONS.SAVE_TEAM_LEADERS_SELECTION,
    };
};
