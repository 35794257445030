import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { useSelector } from 'react-redux';
import { Scenario } from '../../../constants/interfaces/Scenario';

type PortableScenariosBaseState = {
    data: ListResult<Scenario>;
    loading: boolean;
};

export type PortableScenariosState = PortableScenariosBaseState;

export const initialState = {
    data: {
        items: [],
        limit: 0,
        offset: 0,
        numberOfPages: 0,
        count: 0,
        lastUpdated: undefined,
    },
    loading: false,
};

export const usePortableScenariosState = (): PortableScenariosBaseState => {
    const appState = useSelector(state => state);
    return appState.portableScenarios;
};
