import { Action } from 'redux';
import { TimeZoneInterface } from '../../constants/interfaces/general';

export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const FETCH_TIMEZONES_SUCCESS = 'FETCH_TIMEZONES_SUCCESS';
export const FETCH_TIMEZONES_FAILURE = 'FETCH_TIMEZONES_FAILURE';

export type FetchTimeZonesAction = Action<typeof FETCH_TIMEZONES>;
export function fetchTimeZonesAction(): FetchTimeZonesAction {
    return {
        type: FETCH_TIMEZONES,
    };
}

export type FetchTimeZonesSuccessAction = Action<
    typeof FETCH_TIMEZONES_SUCCESS
> & { data: TimeZoneInterface[] };
export function fetchTimeZonesActionSuccess(
    data: any
): FetchTimeZonesSuccessAction {
    return {
        type: FETCH_TIMEZONES_SUCCESS,
        data,
    };
}

export type FetchTimeZonesFailureAction = Action<
    typeof FETCH_TIMEZONES_FAILURE
> & { error: string };
export function fetchTimeZonesActionFailure(
    error: any
): FetchTimeZonesFailureAction {
    return {
        type: FETCH_TIMEZONES_FAILURE,
        error,
    };
}

export type GlobalActions =
    | FetchTimeZonesAction
    | FetchTimeZonesSuccessAction
    | FetchTimeZonesFailureAction;
