import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { CommentContext } from './Comment';
import { Variables } from '../../../../theme/variables';

const Body = styled.div<{ reverse?: boolean; background: string }>`
    flex: 1;
    position: relative;
    background: ${props => props.background};
    border-top-right-radius: ${props => (props.reverse ? '0' : '8px')};
    border-top-left-radius: ${props => (props.reverse ? '8px' : '0')};
    border-bottom-right-radius: ${props => (props.reverse ? '16px' : '8px')};
    border-bottom-left-radius: ${props => (props.reverse ? '8px' : '16px')};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    display: flex;
    flex-direction: column;
    word-break: break-word;
    overflow: hidden;
`;

const Blacker = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const CommentBody: FC<{ className?: string }> = ({
    children,
    className,
}) => {
    const params = useContext(CommentContext);

    return (
        <Body
            reverse={params.reverse}
            background={
                params.background ? params.background : Variables.Colors.white
            }
            className={className}
        >
            {params.disabled && <Blacker />}
            {children}
        </Body>
    );
};
