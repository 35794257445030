import { flatten, range } from 'lodash';

const imageColors = ['1EB0FF', '51D76A', '96DAFF', 'FF7836'];
const imageCount = 10;

export const SCENARIO_IMAGES: string[] = flatten(
    range(1, imageCount + 1).map(n =>
        imageColors.map(c => `image_${n}_${c}.png`)
    )
);
