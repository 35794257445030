import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledAvatarContainer = styled.div<{
    size?: string;
    disabled?: boolean;
    backgroundColor?: string;
    imgBorderRadius?: string;
}>`
    font-size: ${props => (props.size === 'sm' ? '13px' : '18px')};
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: ${props =>
        props.backgroundColor
            ? props.backgroundColor
            : props.theme.Colors.whiteFive};
    color: ${props => props.theme.Colors.black};
    opacity: ${props => (props.disabled ? 0.7 : 1)};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        border-radius: ${props =>
            !!props.imgBorderRadius ? props.imgBorderRadius : '4px'};
        object-fit: cover;
    }
`;

export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: ${props => props.theme.Colors.dark};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    &:active {
        opacity: 0.7;
    }
`;

export const CustomPlaceholderContainer = styled.div`
    height: 16px;
    width: 16px;
    align-self: center;
    display: flex;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

export const CustomNamePlaceholder = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid ${props => props.theme.Colors.cloudyBlue};
    box-sizing: border-box;
`;

export const PendingWrapper = styled.div<{
    bottomPosition?: number;
}>`
    position: absolute;
    pointer-events: all;
    right: 0px;
    bottom: ${props =>
        !!props.bottomPosition ? props.bottomPosition : '-5'}px;
`;
