import React from 'react';
import styled from 'styled-components';
import ChallengeInfo from './ChallengeInfo';
import ChallengeScripts from './ChallengeScripts';
import { Challenge } from '../../../../constants/interfaces/Challenge';
import { ScriptLineSpeaker } from '../../../../constants/interfaces/ScriptLine';

export interface CustomBodyInterface {
    challenge: Challenge;
    createScriptLine: (speaker: ScriptLineSpeaker) => void;
}

const CustomBodyContainerWrapper = styled.div`
    display: flex;
    min-height: calc(100vh - 75px);
`;

const CustomBodyContainer = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
`;

const ChallengeBody = ({
    challenge,
    createScriptLine,
}: CustomBodyInterface) => {
    return (
        <CustomBodyContainerWrapper>
            <CustomBodyContainer>
                <ChallengeInfo challenge={challenge} />
                <ChallengeScripts
                    challenge={challenge}
                    createScriptLine={createScriptLine}
                />
            </CustomBodyContainer>
        </CustomBodyContainerWrapper>
    );
};

export default ChallengeBody;
