import styled from 'styled-components';

export const StyledCard = styled.div`
    background: var(--ps-white-1);
    border-radius: 24px;
    width: 520px;
    padding: 8px 24px 24px 24px;
`;

export const Item = styled.div`
    padding: 4px 0;
    display: flex;
`;

export const Label = styled.strong`
    display: block;
    width: 70px;
`;

export const Value = styled.div`
    margin-left: 8px;
`;