import { forwardRef } from 'react';
import { IconProps } from './types';

export const Flag = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#w14giu5aca)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.814 0a3.31 3.31 0 0 0-1.172.25L1.607 1.375 1.52 1.13A.785.785 0 0 0 1.221.75.753.753 0 0 0 .76.623a.807.807 0 0 0-.263 0 .776.776 0 0 0-.432.423.817.817 0 0 0-.012.614l5.012 13.831c.054.149.15.277.276.368.127.091.277.14.431.14a.792.792 0 0 0 .263-.051.745.745 0 0 0 .445-.418.785.785 0 0 0 0-.62L4.538 9.516l1.276-.478a3.533 3.533 0 0 1 1.172-.208c.667.01 1.315.228 1.86.623.57.403 1.247.62 1.94.622.4 0 .797-.074 1.172-.218L16 8.332 13.17.477l-2.02.768a3.307 3.307 0 0 1-1.173.218 3.32 3.32 0 0 1-2.02-.716A3.368 3.368 0 0 0 5.813 0z"
                        fill="currentColor"
                    />
                </g>
                <defs>
                    <clipPath id="w14giu5aca">
                        <path
                            fill="#fff"
                            d="M0 0h16v16H0z"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default Flag;
