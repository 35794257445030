import { useCallback } from 'react';

type Entity = {
    id: number;
    practisSetIDs: number[];
    roleId: number;
};

export const useCalculatePreSelectedUserRoles = <T extends Entity>() => {
    return useCallback((selectedItems: number[], entity: T[]) => {
        let outputArray: number[] = [];
        const filteredEntities = entity.filter(entityItem => {
            return selectedItems.includes(entityItem.id);
        });
        for (let entity of filteredEntities) {
            if (!outputArray.includes(entity.roleId)) {
                outputArray.push(entity.roleId);
            }
        }

        return outputArray;
    }, []);
};
