import { CardContainer, CardIcon, TextLine, ResultFormat } from './styles';

function ReportingCardSkeletonView() {
    return (
        <CardContainer>
            <CardIcon size={40} />
            <TextLine width={164} />
            <TextLine width={232} />
            <TextLine width={103} />
            <ResultFormat />
        </CardContainer>
    );
}

export default ReportingCardSkeletonView;