import { SearchParams } from '../../../constants/interfaces/filters';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    searchPacingsFailure,
    searchPacingsStart,
    searchPacingsSuccess,
} from './actions';
import { useDispatch } from 'react-redux';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import { useSearchPacingsApi } from '../../../api';

export const useSearchPacingsService = () => {
    const dispatch = useDispatch();
    const searchPacingsApi = useSearchPacingsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p?: SearchParams) => {
            dispatch(searchPacingsStart());
            searchPacingsApi(p)
                .then(data => {
                    dispatch(searchPacingsSuccess(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchPacingsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchPacingsApi, showMessage]
    );
};
