import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Scenario } from '../../../../../../constants/interfaces/Scenario';
import { getScenarioState } from '../../../store/reducers';
import { useModifyScenarioService } from '../../../store/services';
import { EditModeContext } from '../../../NewScenario';
import { EditModeValues } from '../../../../../../constants/enums/EditModeValues';
import { Variables } from '../../../../../../theme/variables';
import TextAreaBox from '../../../../../../ui/components/TextAreaBox/TextAreaBox';

const StyledScenarioInfo = styled.div`
    flex: 2;
    margin-right: 32px;
    padding-top: 24px;
`;

const StyledScenarioInfoContent = styled.div`
    border-radius: 4px;
`;

const StyledInputDivs = styled.div``;

const ScenarioInfo: FC<{
    scenario: Scenario;
    modified: 'init' | 'created' | 'modified' | 'updated' | 'error' | 'loaded';
    modifyScenario: (value: any, field: keyof Scenario) => any;
}> = ({ scenario, modifyScenario }) => {
    const edit = useContext(EditModeContext);

    return (
        <StyledScenarioInfo>
            <StyledScenarioInfoContent>
                <StyledInputDivs>
                    <TextAreaBox
                        background={Variables.Colors.whiteFive}
                        value={scenario && scenario.description}
                        onChange={(e: any) =>
                            modifyScenario(e.target.value, 'description')
                        }
                        name="description"
                        placeholder="Description"
                        max={500}
                        minRows={7.5}
                        padding="12px 16px"
                        readOnly={edit.mode === EditModeValues.VIEW}
                        dataTest='scenario-description'
                    />
                </StyledInputDivs>
            </StyledScenarioInfoContent>
        </StyledScenarioInfo>
    );
};

export const ScenarioInfoContainer: FC<{
    scenario: Scenario;
}> = ({ scenario }) => {
    const modified = useSelector(getScenarioState).case;

    const modifyScenario = useModifyScenarioService();

    return (
        <ScenarioInfo
            scenario={scenario}
            modified={modified}
            modifyScenario={modifyScenario}
        />
    );
};

export default ScenarioInfoContainer;
