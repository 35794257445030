/**
 * function getFileExtension - extracts file extension from a file name
 * @param { string } filename
 * @returns { string }
*/
export const getFileExtension = (filename: string): string => {
    if (!filename)
        return "";
    const parts: string[] = filename.split(".");
    return parts.length > 1 ? (parts.pop() as string) : "";
}

const excelExtensions: string[] = ['xls', 'xlt', 'xla', 'xlsx', 'xltx', 'xlsm', 'xltm', 'xlam', 'xlsb'];

/**
 * function isExcelFile - checks if a file name is of Excel file
 * @param { string } filename
 * @returns { boolean }
*/
export const isExcelFile = (filename: string): boolean => {
    const fileExtension: string = getFileExtension(filename).toLowerCase();
    return !!fileExtension && excelExtensions.includes(fileExtension);
}