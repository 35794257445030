import { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import ROUTES from '../../../routes/routes';
import { useResetPasswordApi } from '../../../api';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import ResetPasswordView from './view';
import { EXPIRED_TOKEN_MESSAGE } from './constants';
import { ROLE_NAMES } from '../../../constants/enums';

const qs = require('query-string');

function ResetPasswordController () {
    const history = useHistory();
    const location = useLocation();
    const queryParams = qs.parse(location.search);
    const resetPasswordApi = useResetPasswordApi();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    /**
     * @function handleSubmit - sends reset password request to API
     * @param { string } password
     * @returns { void }
     */    
     const handleSubmit = useCallback((password: string) => {
        setIsSubmitting(true);
        resetPasswordApi(password, queryParams.token)
        .then(() => {
            history.push(queryParams.role?.trim() === ROLE_NAMES.USER
                ? ROUTES.AUTH_PAGES.TRAINEE_LOGIN
                : ROUTES.AUTH_PAGES.LOGIN);
        })
        .catch((error: ErrorResult) => {
            setIsSubmitting(false);
            setErrorMessage(
                error.code === 401
                    ? EXPIRED_TOKEN_MESSAGE
                    : error.message
            );
        });
    }, [history, queryParams, resetPasswordApi]);

    /**
     * @function handleClearErrorMessage - clears general error message
     * @returns { void }
     */    
     const handleClearErrorMessage = () => {
        setIsSubmitting(false);
        setErrorMessage('');
    };

    /**
     * @function handleBack - redirects to login page
     * @returns { void }
     */    
    const handleBack = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.LOGIN);
    }, [history]);
    
    return <ResetPasswordView
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
        onBack={handleBack}
        onClearError={handleClearErrorMessage}
        onSubmit={handleSubmit}
    />
}

export default ResetPasswordController;
