import React, { FC } from 'react';
import styled from 'styled-components';
import SelectBoxItem from './SelectBoxItem';
import SelectBoxLabel from './SelectBoxLabel';

const StyledSelectBoxContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const BoxArea = styled.div<{ marginLeft?: string }>`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: ${props => (!!props.marginLeft ? props.marginLeft : '43px')};
    margin-right: 4px;
    max-height: 50px;
    min-width: 100px;
    flex-shrink: 0;
    cursor: pointer;
`;

type Item = string | number;

export const SelectBox: FC<{
    items: Item[];
    name?: string;
    value?: Item | null;
    label?: string;
    handleSelect?: (value: string | number, field?: string) => void;
    formatValueLabel?: string;
    labelColor?: string;
    color?: string;
    isRound?: boolean;
    readOnly?: boolean;
    spacing?: string;
    className?: string;
    marginLeft?: string;
}> = ({
    name,
    items,
    label,
    value,
    handleSelect,
    formatValueLabel,
    labelColor,
    color,
    spacing,
    isRound,
    readOnly,
    className,
    marginLeft,
}) => {
    return (
        <StyledSelectBoxContainer className={className}>
            {label && (
                <SelectBoxLabel
                    color={labelColor && labelColor}
                    label={label}
                />
            )}
            <BoxArea marginLeft={marginLeft}>
                {items.map((item: Item, index: number) => (
                    <SelectBoxItem
                        key={`${item}_${index}`}
                        selected={!!value && item <= value}
                        finalSpot={item === value}
                        finalItem={item === items.length}
                        firstItem={index === 0}
                        name={`${item}${
                            formatValueLabel ? formatValueLabel : ''
                        }`}
                        handleSelect={() =>
                            !readOnly &&
                            handleSelect &&
                            handleSelect(item, name)
                        }
                        color={color}
                        spacing={spacing}
                        isRound={isRound}
                    />
                ))}
            </BoxArea>
        </StyledSelectBoxContainer>
    );
};
