import React, { FC, useContext, useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { EditModeContext, LoadingSaveContext } from '../../PractisSets';
import { getPractisSetState } from '../../store/reducers';
import {
    PractisSets,
    PractisSetStatuses,
} from '../../../../../constants/interfaces/PractisSets';
import { StatusModalConfirmation } from '../../../StatusModalConfirmation/StatusModalConfirmation';
import {
    useModifyPractisSetService,
    useRestorePractisSetFromTempActionService,
    useStorePractisSetTempCopyService,
} from '../../store/services';
import { buildPageTitle } from '../../../../../helpers/functions/page-title-helpers';
import { EditModeValues } from '../../../../../constants/enums/EditModeValues';
import { formatDate } from '../../../../../helpers/functions/date-convert';
import { Button } from '../../../../../ui/components/Button';
import ArchiveIcon from '../../../../../ui/icons/ArchiveIcon';
import { TableDivider } from '../../../../../ui/components/table-wrapper/table-divider';
import { useHandleRestoreArchivedPractisSet } from '../../services';

const StyledCustomWrapper = styled.div``;

const StyledCustomHeader = styled.div`
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 90px;
`;

const StyledHeaderInput = styled.div`
    flex: 1;
    min-width: 150px;
    margin-right: 16px;
`;

const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${props => props.theme.Colors.black};
`;

const PublishedDate = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledHeaderActionItems = styled.div`
    margin-left: 16px;
`;

const StyledHeaderBottomActionItemsWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 6px;
`;

const StyledHeaderActionItemsWrapper = styled.div`
    display: flex;
`;

const StyledHeaderActions = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap;
`;

const EDIT_MODAL_INFO = {
    id: 'practis_set_edit_message',
    message:
        'Editing the items or the sequence of content in the Practis Set will effect all currently enrolled Users. They will receive a notification.',
    buttonText: 'Proceed',
    cancelButtonText: 'Go Back',
    title: 'Are you sure?',
    notShow: false,
    danger: true,
};

const CANCEL_EDIT_MODAL_INFO = {
    id: 'practis_set_cancel_edit_message',
    message: 'Do you want to discard changes?',
    buttonText: 'Discard',
    cancelButtonText: 'No',
    title: 'Discard Changes?',
    notShow: false,
    danger: true,
};

const VerticalDivider = styled.span`
    width: 1px;
    height: 18px;
    background-color: ${({ theme }) => theme.Colors.paleGrey};
    margin: 0 8px;
`;

const ButtonIcon = styled.div<{ disabled?: boolean }>`
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

const ButtonText = styled.span`
    margin-left: 4px;
    line-height: 18px;
`;

const ArchiveButtonContainer = styled.button`
    background: none;
    display: flex;
    outline: none;
    border: none;
    font-size: 13px;
    font-family: ${props => props.theme.Fonts.manrope};
    font-weight: normal;
    color: ${({ theme, disabled }) =>
        disabled ? theme.Colors.cloudyBlue : theme.Colors.darkSkyBlue};
    cursor: pointer;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

    &:hover {
        color: ${({ theme }) => theme.Colors.lightBlue};
    }
    &:active {
        color: ${({ theme }) => theme.Colors.windowBlue};
    }

    &:hover ${ButtonIcon} {
        color: ${({ theme }) => theme.Colors.lightBlue};
    }
    &:active ${ButtonIcon} {
        color: ${({ theme }) => theme.Colors.windowBlue};
    }

    padding: 0;
`;

const MODAL_INFO = (pacing?: string) => {
    return {
        DELETED: {
            id: 'practis_set_delete_message',
            message: 'Are you sure you want to delete?',
            buttonText: 'Yes, Delete',
            title: 'Delete Practis Set',
            notShow: false,
            danger: false,
        },
        DRAFT: {
            id: 'practis_set_draft_message',
            message: '',
            buttonText: 'Proceed',
            title: 'Are you sure?',
            notShow: false,
            danger: true,
        },
        ARCHIVED: {
            id: 'practis_set_archive_message',
            message: 'Are you sure you want to archive?',
            buttonText: 'Yes, Archive',
            title: 'Archive Practis Set',
            notShow: false,
            danger: true,
        },
        ACTIVE: {
            id: 'practis_set_active_message',
            message: `You're about to publish a Practis Set that has a '${
                pacing ? pacing : 'free-form'
            }' pacing setting. This means that once you publish it you will not be able to add, remove, or change the scenarios in the set.`,
            buttonText: 'Publish',
            title: 'Publish Practis Set',
            notShow: true,
            danger: false,
        },
    };
};

const EDIT_VIEW_STATUS = [PractisSetStatuses.ACTIVE];

const CustomHeader: React.FC<{
    practisSet: PractisSets;
    modified?: boolean;
    modifyPractisSet: (
        value: any,
        field: keyof PractisSets,
        silent?: boolean
    ) => void;
    storePractisSetTemp: () => void;
    restorePractisSetFromTemp: () => void;
    handleUpdatePractisSet: (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: string,
        keepModal?: boolean
    ) => any;
    handleViewAssignUsers?: (id: number | undefined) => void;
    isViewMode?: boolean;
}> = ({
    practisSet,
    modified,
    modifyPractisSet,
    handleUpdatePractisSet,
    handleViewAssignUsers,
    storePractisSetTemp,
    restorePractisSetFromTemp,
    isViewMode,
}) => {
    const [publishModal, setPublishModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [cancelEditModal, setCancelEditModal] = useState(false);
    const [saveState, setSaveState] = useState<PractisSetStatuses>(
        PractisSetStatuses.ACTIVE
    );

    const handleRestoreArchivedPractisSet =
        useHandleRestoreArchivedPractisSet();

    const handleSaveWithModalAction = (status: PractisSetStatuses) => {
        setSaveState(status);
        setPublishModal(true);
    };

    const loadingSave = useContext(LoadingSaveContext);
    const edit = useContext(EditModeContext);

    const cancelEditing = () => {
        if (modified) {
            setCancelEditModal(true);
        } else {
            restorePractisSetFromTemp();
            edit.action(EditModeValues.VIEW);
        }
    };

    return (
        <StyledCustomWrapper>
            <StyledCustomHeader>
                {publishModal && (
                    <StatusModalConfirmation
                        onCancel={() => {
                            setPublishModal(false);
                        }}
                        onConfirm={() => {
                            setPublishModal(false);
                            handleUpdatePractisSet(null, saveState);
                        }}
                        title={MODAL_INFO()[saveState].title}
                        confirmButtonText={MODAL_INFO()[saveState].buttonText}
                        displayDontShow={MODAL_INFO()[saveState].notShow}
                        dontShowHandle={MODAL_INFO()[saveState].id}
                        danger={MODAL_INFO()[saveState].danger}
                    >
                        {
                            MODAL_INFO(
                                practisSet?.pacing?.type?.toLowerCase()
                            )?.[saveState]?.message
                        }
                    </StatusModalConfirmation>
                )}
                {editModal && (
                    <StatusModalConfirmation
                        onCancel={() => {
                            setEditModal(false);
                        }}
                        onConfirm={() => {
                            setEditModal(false);
                            storePractisSetTemp();
                            edit.action(EditModeValues.EDIT);
                        }}
                        title={EDIT_MODAL_INFO.title}
                        confirmButtonText={EDIT_MODAL_INFO.buttonText}
                        cancelButtonText={EDIT_MODAL_INFO.cancelButtonText}
                        displayDontShow={EDIT_MODAL_INFO.notShow}
                        dontShowHandle={EDIT_MODAL_INFO.id}
                        danger={EDIT_MODAL_INFO.danger}
                    >
                        {EDIT_MODAL_INFO.message}
                    </StatusModalConfirmation>
                )}
                {cancelEditModal && (
                    <StatusModalConfirmation
                        onCancel={() => {
                            setCancelEditModal(false);
                        }}
                        onConfirm={() => {
                            setCancelEditModal(false);
                            restorePractisSetFromTemp();
                            edit.action(EditModeValues.VIEW);
                        }}
                        title={CANCEL_EDIT_MODAL_INFO.title}
                        confirmButtonText={CANCEL_EDIT_MODAL_INFO.buttonText}
                        cancelButtonText={
                            CANCEL_EDIT_MODAL_INFO.cancelButtonText
                        }
                        displayDontShow={CANCEL_EDIT_MODAL_INFO.notShow}
                        dontShowHandle={CANCEL_EDIT_MODAL_INFO.id}
                        danger={CANCEL_EDIT_MODAL_INFO.danger}
                    >
                        {CANCEL_EDIT_MODAL_INFO.message}
                    </StatusModalConfirmation>
                )}
                <StyledHeaderInput>
                    <HeaderTitle data-test="practis-set-page-title">
                        {buildPageTitle('Practis Set', edit.mode as EditModeValues, !practisSet.id)}
                    </HeaderTitle>
                </StyledHeaderInput>
                <StyledHeaderActions>
                    {EDIT_VIEW_STATUS.includes(practisSet.status) ? (
                        edit.mode === EditModeValues.VIEW ? (
                            <StyledHeaderActionItemsWrapper>
                                <StyledHeaderActionItems>
                                    <Button
                                        width="144px"
                                        height="40px"
                                        disabled={loadingSave}
                                        action={() =>
                                            handleViewAssignUsers?.(
                                                practisSet?.id
                                            )
                                        }
                                        variant="inverse"
                                        dataTest="assign-practis-set-users"
                                    >
                                        Assign Users
                                    </Button>
                                </StyledHeaderActionItems>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave || isViewMode}
                                        action={() => setEditModal(true)}
                                        dataTest="edit-practis-set"
                                    >
                                        Edit
                                    </Button>
                                </StyledHeaderActionItems>
                            </StyledHeaderActionItemsWrapper>
                        ) : (
                            <StyledHeaderActionItemsWrapper>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave}
                                        action={() => cancelEditing()}
                                        variant="inverse"
                                        dataTest="cancel-practis-set"
                                    >
                                        Cancel
                                    </Button>
                                </StyledHeaderActionItems>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave || isViewMode}
                                        action={() =>
                                            handleUpdatePractisSet(null)
                                        }
                                        dataTest="save-practis-set"
                                    >
                                        Save
                                    </Button>
                                </StyledHeaderActionItems>
                            </StyledHeaderActionItemsWrapper>
                        )
                    ) : practisSet.status === PractisSetStatuses.ARCHIVED ? (
                        <StyledHeaderActionItemsWrapper>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="128px"
                                    disabled={loadingSave || isViewMode}
                                    action={() =>
                                        handleRestoreArchivedPractisSet(
                                            practisSet.id
                                        )
                                    }
                                    dataTest="restore-practis-set"
                                >
                                    Restore
                                </Button>
                            </StyledHeaderActionItems>
                        </StyledHeaderActionItemsWrapper>
                    ) : (
                        <StyledHeaderActionItemsWrapper>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="144px"
                                    disabled={loadingSave || isViewMode}
                                    action={() =>
                                        handleUpdatePractisSet(
                                            null,
                                            PractisSetStatuses.DRAFT
                                        )
                                    }
                                    variant="inverse"
                                    dataTest="save-practis-set-as-draft"
                                >
                                    Save as Draft
                                </Button>
                            </StyledHeaderActionItems>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="128px"
                                    disabled={loadingSave || isViewMode}
                                    action={() =>
                                        handleSaveWithModalAction(
                                            PractisSetStatuses.ACTIVE
                                        )
                                    }
                                    dataTest="publish-practis-set"
                                >
                                    Publish
                                </Button>
                            </StyledHeaderActionItems>
                        </StyledHeaderActionItemsWrapper>
                    )}
                </StyledHeaderActions>
            </StyledCustomHeader>

            <StyledHeaderBottomActionItemsWrapper>
                <PublishedDate>
                    {practisSet.status === PractisSetStatuses.ACTIVE &&
                        edit.mode === EditModeValues.VIEW && (
                            <span data-test="practis-set-publish-date">
                                Published{' '}
                                {formatDate(practisSet.updatedAt, true)}
                            </span>
                        )}
                    {practisSet.status === PractisSetStatuses.ARCHIVED &&
                        edit.mode === EditModeValues.VIEW && (
                            <span data-test="practis-set-archive-date">
                                Archived{' '}
                                {formatDate(practisSet.updatedAt, true)}
                            </span>
                        )}
                    {practisSet.status === PractisSetStatuses.DRAFT && (
                        <span data-test="practis-set-not-published-yet">
                            Not Published Yet
                        </span>
                    )}
                </PublishedDate>
                {practisSet.status === PractisSetStatuses.ACTIVE && (
                    <>
                        {edit.mode === EditModeValues.VIEW && (
                            <VerticalDivider />
                        )}

                        <ArchiveButtonContainer
                            onClick={() =>
                                handleSaveWithModalAction(
                                    PractisSetStatuses.ARCHIVED
                                )
                            }
                            disabled={loadingSave || isViewMode}
                            data-test="archive-practis-set"
                        >
                            <ButtonIcon>
                                <ArchiveIcon />
                            </ButtonIcon>
                            <ButtonText>Archive</ButtonText>
                        </ArchiveButtonContainer>
                    </>
                )}
            </StyledHeaderBottomActionItemsWrapper>

            <TableDivider />
        </StyledCustomWrapper>
    );
};

export const CustomHeaderContainer: FC<{
    handleUpdatePractisSet: (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: string,
        keepModal?: boolean
    ) => void;
    handleViewAssignUsers?: (id: number | undefined) => void;
    isViewMode?: boolean;
}> = ({ handleUpdatePractisSet, handleViewAssignUsers, isViewMode }) => {
    const practisSet = useSelector(getPractisSetState);
    const modifyPractisSet = useModifyPractisSetService();
    const storePractisSetTemp = useStorePractisSetTempCopyService();
    const restorePractisSetFromTemp =
        useRestorePractisSetFromTempActionService();

    return (
        <CustomHeader
            practisSet={practisSet.info}
            modified={practisSet.case === 'modified'}
            handleUpdatePractisSet={handleUpdatePractisSet}
            handleViewAssignUsers={handleViewAssignUsers}
            modifyPractisSet={modifyPractisSet}
            storePractisSetTemp={storePractisSetTemp}
            restorePractisSetFromTemp={restorePractisSetFromTemp}
            isViewMode={isViewMode}
        />
    );
};

export default CustomHeaderContainer;
