import React, { FC } from 'react';
import styled from 'styled-components';
import EmptyBox from '../../../ui/icons/EmptyBox';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import NoSearchResult from '../../../ui/icons/NoSearchResult';

const StyledEmptyInviters = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const StyledEmptyInvitersContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoLabelsIcon = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoInvitersTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const EmptyInviters: FC<{ loading?: boolean; isEmpty?: boolean }> = ({
    loading,
    isEmpty,
}) => {
    if (loading) {
        return (
            <StyledEmptyInviters>
                <Loading />
            </StyledEmptyInviters>
        );
    }

    return (
        <StyledEmptyInviters>
            <StyledEmptyInvitersContent>
                <NoLabelsIcon data-test={isEmpty ? 'no-inviters-icon' : 'no-inviters-found-icon'}>
                    {isEmpty ? <EmptyBox /> : <NoSearchResult />}
                </NoLabelsIcon>
                <NoInvitersTitle data-test={isEmpty ? 'no-inviters-text' : 'no-inviters-found-text'}>
                    {isEmpty ? 'No Inviters yet' : 'No Inviters found'}
                </NoInvitersTitle>
            </StyledEmptyInvitersContent>
        </StyledEmptyInviters>
    );
};

export default EmptyInviters;
