import { FC } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { Variables } from '../../../theme/variables';
import { useShowMessage } from '../ErrorMessages/ErrorMessages';
import Save from '../../icons/Save';
import {
    ButtonText,
    IconContainer,
    StyledButton,
    StyledPlayingAudioButton,
    StyledReactMic,
    StyledReactMicContainer,
    StyledScriptPlayingAudioAction,
} from './style';
import { AudioRecorderProps } from './type';

const AudioRecorder: FC<AudioRecorderProps> = ({
    record,
    onClickAway,
    onSave,
    onStop,
    onData,
    backgroundColor,
    strokeColor,
}) => {
    const showMessage = useShowMessage();

    const handleNoPermission = () => {
        onStop(null);
        showMessage('Microphone permission has been denied', 'error');
    };

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(
        stream => {
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
        },
        () => {
            handleNoPermission();
        }
    );

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <StyledScriptPlayingAudioAction>
                <StyledReactMicContainer>
                    <StyledReactMic
                        record={record}
                        className="sound-wave"
                        onStop={onStop}
                        onData={onData}
                        mimeType={'audio/mp3'}
                        strokeColor={
                            strokeColor
                                ? strokeColor
                                : Variables.Colors.softBlue
                        }
                        backgroundColor={
                            backgroundColor
                                ? backgroundColor
                                : Variables.Colors.darkTwo
                        }
                    />
                </StyledReactMicContainer>
                <StyledPlayingAudioButton>
                    <StyledButton onClick={onSave}>
                        <IconContainer>
                            <Save />
                        </IconContainer>
                        <ButtonText>Save</ButtonText>
                    </StyledButton>
                </StyledPlayingAudioButton>
            </StyledScriptPlayingAudioAction>
        </ClickAwayListener>
    );
};

export default AudioRecorder;
