import styled from 'styled-components';
import { Button } from '../../../../ui/components/Button';

export const StyledChallengeScriptHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding-top: 24px;
`;

export const AttemptsCountContainer = styled.div`
    padding: 16px 24px;
    background: var(--ps-graphite-2);
    border-radius: 4px;
`;

export const AttemptsCountText = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: var(--ps-grey-2);
`;

export const AttemptsCountValue = styled.div`
    padding-top: 4px;
    font-size: 20px;
    font-weight: bold;
    color: var(--ps-white-1);
`;

export const StyledScriptHeaderActions = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
`;

export const StyledButton = styled(Button)<{ width?: string }>`
    ${props => (!!props.width ? `width: ${props.width}` : '')};
`;

export const IconContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 16px;
        height: 16px;
    }
`;

export const LeftButtons = styled.div`
    display: flex;
    flex-direction: row;

    & button {
        margin-right: 8px;
    }
`;