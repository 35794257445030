import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { useHistory } from 'react-router';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { History } from 'history';
import {
    LIBRARY_STATUS,
    libraryItemsStatusName,
    useChallengeActionsHelper,
    ChallengeActionsHelper,
    ChallengeActions,
} from '../../tools';
import ROUTES from '../../../../routes/routes';
import { pushModal } from '../../../../tools/router';
import {
    useArchiveChallengeService,
    useDeleteChallengesService,
    useDeleteLibraryLabelTag,
    useRestoreChallengeService,
    useSearchChallengesService,
    useUpdateAllChallengeCheckedStateService,
    useUpdateLibraryChallengeCheckedStateService,
} from '../../store/services';
import {
    useLibraryChallengesState,
    useUpdatedLibraryChallengesState,
    selectSelectedChallenges,
} from '../../store/states';
import { Challenge as ChallengeInterface } from '../../../../constants/interfaces/Challenge';
import { formatDate } from '../../../../helpers/functions/date-convert';
import LibraryNavigationContainer from '../../components/LibraryNavigation';
import {
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import {
    ASSIGN_LABEL_OPTIONS,
    LOAD_ASSIGNED_LABELS,
} from '../../../../constants/enums';
import {
    clearSelectedItemIfNotLoaded,
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabels,
} from '../../../labels/tools';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import { WithLabelsContext } from '../../../portableLabels';
import { Popup } from '../../../../ui/components/Popup';
import { LibraryFilters } from '../../components/Filters/LibraryFilters';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import ChallengesListActions from '../../components/ListActions/ChallengesListActions';
import { useSessionEffect } from '../../../../features/common';
import { countAppliedFiltersCount } from '../../../../features/teams/pages/ListOfTeams/tools';
import {
    useAssignChallengeLabelsApi,
    useAssignedLabelsIdsApi,
    useDeleteChallengeLabelsApi,
} from '../../../../api';
import { isEmpty } from 'lodash';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { useStyles } from './styles';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useFilter } from '../../../../ui/components/table-wrapper/table/TableDropdown';
import { useFetchAllPractisSetsService } from '../../../portablePractisSets/store/hors/withPractisSets/services';
import {
    useUpdateLabelsBulkActionService,
    useUpdateMultipleEntityLabelsBulkActionService,
} from '../../../../services/GeneralBulkActionServices';
import { CreateNewChallengeInfo } from '../../services/LibraryBulkActionsService/types';
import { generateCopyOfEntityName } from '../../../../services/GeneralBulkActionServices/helpers';
import { useCreateEditDuplicateLibraryBulActionService } from '../../services/LibraryBulkActionsService';
import { useGenerateCreateDuplicateChallengeActionList } from '../../services/LibraryBulkActionsService/helpers';
import { UPDATE_MULTIPLE_LABEL_ACTION } from '../../../../services/GeneralBulkActionServices/constants';
import Document from '../../../../ui/icons/Document';
import { useShowConfirmModalDialog } from '../../../../ui/components/ModalDialogs/store/actions';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;
const DEFAULT_FILTER_VALUE = [LIBRARY_STATUS.ACTIVE, LIBRARY_STATUS.DRAFT];

const Challenges: FC<{
    challenges?: ListResult<ChallengeInterface>;
    updatedChallenges?: ChallengeInterface;
    searchChallenges(searchParams: SearchParams): void;
    history: History<any>;
    onDeleteChallenges: (
        challengeIds: number[],
        successCallback?: () => void
    ) => Promise<unknown>;
    onArchiveChallenge: (challengeIds: number[]) => void;
    onRestoreChallenge: (challengeIds: number[]) => void;
    selectedLabels?: number[];
    selectedChallengeIds?: number[];
    selectedChallenges?: ChallengeInterface[];
    actionsHelper: ChallengeActionsHelper;
    loading?: boolean;
    setAssignLabelsAction(labels: number[]): void;
    assignedLabels?: number[];
    updateAllChallengesCheckedState(
        challengeId: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedChallengeCheckedState(challengeId: number): void;
    updatedLabels?: any;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    companyId?: number;
}> = ({
    challenges,
    updatedChallenges,
    history,
    onDeleteChallenges,
    onArchiveChallenge,
    onRestoreChallenge,
    searchChallenges,
    selectedLabels,
    selectedChallengeIds,
    selectedChallenges,
    actionsHelper,
    setAssignLabelsAction,
    assignedLabels,
    updateAllChallengesCheckedState,
    updateSelectedChallengeCheckedState,
    updatedLabels,
    loading,
    saveGlobalLabelFilters,
    companyId
}) => {
    const showConfirmationModalDialog = useShowConfirmModalDialog();
    const fetchAllPractisSets = useFetchAllPractisSetsService();
    const assignLabelsToChallenges = useAssignChallengeLabelsApi();
    const deleteLabelsFromChallenges = useDeleteChallengeLabelsApi();
    const deleteLibraryLabelTag = useDeleteLibraryLabelTag();

    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
        'active',
        'draft',
    ]);
    const [savedStatuses, setSavedStatuses] = useState<string[]>([
        'active',
        'draft',
    ]);

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const canAccessPage = useHandleAccessPage();
    const classes = useStyles();

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [{ field: 'status', value: DEFAULT_FILTER_VALUE }],
        labelIDs: selectedLabels,
        orderBy: {
            field: 'updated_at',
            asc: false,
        },
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        refreshSearchParams,
        setFilter,
    } = useSearchParamsState(initialSearchParams);

    const isCheckboxPartiallySelected =
        challenges &&
        challenges.items &&
        selectedChallengeIds &&
        selectedChallengeIds.length < challenges.items.length;

    const updateAllChallengesCheck = useCallback(
        (checked: boolean) => {
            const ids =
                (challenges &&
                    challenges.items.map((item: ChallengeInterface) =>
                        Number(item.id)
                    )) ||
                ([] as number[]);
            updateAllChallengesCheckedState(
                ids,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [
            challenges,
            isCheckboxPartiallySelected,
            updateAllChallengesCheckedState,
        ]
    );

    const handleChallengeCheck = (e: any, challenge: ChallengeInterface) => {
        e.stopPropagation();
        if (!!challenge.id) {
            updateSelectedChallengeCheckedState(Number(challenge.id));
        }
    };
    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchChallenges(searchParams);
        setLastRefreshed(new Date());
        setIsSelectAll(false);
        updateAllChallengesCheckedState([], false, false);
    }, [
        initialOffset,
        searchChallenges,
        searchParams,
        updateAllChallengesCheckedState,
    ]);

    const getDeletedLabels = useCalculateDeletedLabels();

    const updateMultipleChallengesLabels =
        useUpdateMultipleEntityLabelsBulkActionService(
            assignLabelsToChallenges,
            deleteLabelsFromChallenges,
            isSelectAll,
            {
                actionName: UPDATE_MULTIPLE_LABEL_ACTION,
                actionFunction: searchChallenges,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        searchParams: {
                            ...searchParams,
                            limit: 1,
                            offset: Number(challenges?.count) ?? 0,
                        },
                        shouldSetStore: false,
                    },
                    fieldName: 'searchParams.offset',
                },
            },
            refreshData
        );

    const assignLabels = async () => {
        if (assignedLabels && selectedChallengeIds) {
            const { preAssignedLabels } = await getAssignedLabelsIds(
                selectedChallengeIds,
                LOAD_ASSIGNED_LABELS.CHALLENGES_SCREEN_LIBRARY,
                ASSIGN_LABEL_OPTIONS.CHALLENGE,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                return calculatePreSelectedLabels(
                    selectedChallengeIds,
                    challenges,
                    data
                );
            });

            const deletedLabels = getDeletedLabels(assignedLabels);

            const deletedLabelsFiltered = deletedLabels.filter(
                x =>
                    preAssignedLabels.includes(x) ||
                    preAssignedLabels.includes(-x)
            );
            const selectedLabelsFiltered = assignedLabels.filter(
                x => x > 0 && !preAssignedLabels.includes(x)
            );

            updateMultipleChallengesLabels(
                {
                    entityName: 'challenge',
                    entityIds: selectedChallengeIds,
                },
                selectedLabelsFiltered,
                deletedLabelsFiltered
            );
        }
    };

    const orderBy = searchParams.orderBy;
    const chName = useOrderBy('title', orderBy, setOrderBy);
    const chStatus = useOrderBy('status', orderBy, setOrderBy);
    const chUpdatedAt = useOrderBy('updated_at', orderBy, setOrderBy);
    const handleChangeEvent = useFilter('status', setFilter);

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const onSelectStatus = (status: string[]) => {
        setSelectedStatuses(status);
    };
    const onSaveStatus = (status: string[]) => {
        setSavedStatuses(status);
    };

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters:
            countAppliedFiltersCount(searchParams) +
            (savedStatuses?.length || 0),
        itemsCount: challenges?.items.length || 0,
    });

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedChallenges),
        [selectedChallenges, actionsHelper]
    );

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchParams.companyId = companyId;
        searchChallenges(searchParams);
    }, [searchChallenges, searchParams]);

    useEffect(() => {
        updateAllChallengesCheckedState([], false, false);
        setIsSelectAll(false);
    }, [location.pathname, updateAllChallengesCheckedState]);

    useEffect(() => {
        if (updatedChallenges) refreshSearchParams();
    }, [updatedChallenges, refreshSearchParams]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    useEffect(() => {
        return () => updateAllChallengesCheckedState([], false, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllChallengesCheckedState([], false, false);
    }, [challenges, updateAllChallengesCheckedState]);

    useEffect(() => {
        if (
            selectedChallenges?.length &&
            challenges?.count &&
            selectedChallenges.length === +challenges?.count
        ) {
            setIsSelectAll(true);
        }
    }, [challenges?.count, selectedChallenges]);

    const handleItemEdit = (challenges: ChallengeInterface) => {
        updateAllChallengesCheckedState([], false, false);
        pushModal(
            history,
            ROUTES.LIBRARY_SETTINGS.CHALLENGES.SINGLE.replace(
                ':challengeId',
                challenges.id!.toString()
            )
        );
    };

    /**
     * @function successDeleteChallengeCallback
     * @returns { void }
     */
    const successDeleteChallengeCallback = useCallback((): void => {
        fetchAllPractisSets();
        refreshSearchParams();
    }, [fetchAllPractisSets, refreshSearchParams]);

    /**
     * @function onConfirmDeleteChallenge
     * @param { number } challengeIdToDelete
     * @returns { void }
     */
    const onConfirmDeleteChallenge = useCallback(
        (challengeIdToDelete: number): void => {
            onDeleteChallenges(
                [challengeIdToDelete!],
                successDeleteChallengeCallback
            );
        },
        [onDeleteChallenges, successDeleteChallengeCallback]
    );

    /**
     * @function handleDeleteChallenge
     * @param { number } id
     * @returns { void }
     */
    const handleDeleteChallenge = useCallback(
        (id: number) => {
            showConfirmationModalDialog({
                modalTitle: 'Are You Sure?',
                description:
                    'Are you sure you want to delete this item? This can’t be undone',
                onConfirm: () => onConfirmDeleteChallenge(id),
            });
        },
        [onConfirmDeleteChallenge, showConfirmationModalDialog]
    );

    useEffect(() => {
        const filteredUsers = challenges
            ? challenges.items.map(challenge => Number(challenge.id))
            : [];
        const usersToClear = clearSelectedItemIfNotLoaded(
            selectedChallengeIds,
            filteredUsers
        );
        if (usersToClear && usersToClear.length > 0) {
            updateAllChallengesCheckedState(usersToClear, false);
        }
    }, [selectedChallengeIds, challenges, updateAllChallengesCheckedState]);

    const getAssignedLabelsIds = useAssignedLabelsIdsApi();

    const calculatePreSelectedLabels = useCalculatePreSelectedLabels();

    /**
     * @function onOpenAssignLabelAction
     * @returns { void }
     */
    const onOpenAssignLabelAction = (): void => {
        if (challenges && challenges.items && selectedChallengeIds) {
            getAssignedLabelsIds(
                selectedChallengeIds,
                LOAD_ASSIGNED_LABELS.CHALLENGES_SCREEN_LIBRARY,
                ASSIGN_LABEL_OPTIONS.CHALLENGE,
                { ...searchParams, orderBy: {} },
                isSelectAll
            ).then((data: any) => {
                const { preAssignedLabels } = calculatePreSelectedLabels(
                    selectedChallengeIds,
                    challenges,
                    data
                );

                setAssignLabelsAction(preAssignedLabels);
            });
        }
    };

    useEffect(() => {
        handleChangeEvent(savedStatuses);
    }, [savedStatuses, handleChangeEvent]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_CHALLENGE]);
    }, [canAccessPage]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (limit: number, offset: number): void => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllChallengesCheckedState([], false, false);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        const ids =
            (challenges &&
                challenges.items.map((item: ChallengeInterface) =>
                    Number(item.id)
                )) ||
            ([] as number[]);
        updateAllChallengesCheckedState(ids, true, isCheckboxPartiallySelected);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllChallengesCheck(event.target.checked);
        setIsSelectAll(false);
    };

    const isMasterCheckBoxChecked =
        !!selectedChallengeIds && !!selectedChallengeIds.length;

    const isMasterCheckBoxDisabled =
        !challenges || (challenges && challenges.items.length < 1);

    /**
     * @function checkIsRowChecked
     * @param { ChallengeInterface } challenge
     * @returns { boolean }
     */
    const checkIsRowChecked = (challenge: ChallengeInterface): boolean =>
        !!selectedChallengeIds &&
        !!challenge.id &&
        selectedChallengeIds.includes(challenge.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { ChallengeInterface } challenge
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, challenge: ChallengeInterface) => {
        setIsSelectAll(false);
        handleChallengeCheck(event, challenge);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { ChallengeInterface } challenge
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (challenge: ChallengeInterface): boolean => {
        return labelsOpen === challenge?.['id'];
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { ChallengeInterface } challenge
     * @returns { void }
     */
    const onDeleteLabelHandler = (
        labelId: number,
        challenge: ChallengeInterface
    ): void => {
        const challengeItem = challenges?.items.find(
            item => item.id === challenge.id
        );

        if (!isEmpty(challengeItem)) {
            deleteLibraryLabelTag(
                labelId,
                {
                    entityItem: challengeItem!,
                    entityName: 'challenge',
                },
                refreshData
            );
        }
    };

    const updateLabelsBulkActionService = useUpdateLabelsBulkActionService(
        assignLabelsToChallenges,
        deleteLabelsFromChallenges,
        refreshData
    );

    /**
     * @function handleUpdateChallengesLabels
     * @param { number[] } addedLabelIds
     * @param { number[] } deletedLabelIds
     * @param { number } challengeId
     * @returns { void }
     */
    const handleUpdateChallengesLabels = (
        addedLabelIds: number[],
        deletedLabelIds: number[],
        challengeId: number
    ): void => {
        updateLabelsBulkActionService(
            {
                entityName: 'challenge',
                entityId: challengeId,
            },
            addedLabelIds,
            deletedLabelIds
        );
    };

    const generateDuplicateChallengeActionList =
        useGenerateCreateDuplicateChallengeActionList();

    const duplicateLibraryBulkActionService =
        useCreateEditDuplicateLibraryBulActionService(
            'duplicate',
            'Challenge',
            refreshData
        );

    /**
     * @function handleDuplicateChallenge
     * @param { ChallengeInterface } challenge
     * @returns { void }
     */
    const handleDuplicateChallenge = useCallback(
        (challenge: ChallengeInterface) => {
            const newChallenge: CreateNewChallengeInfo = {
                title: generateCopyOfEntityName(challenge.title),
                description: challenge.description,
                sourceScenarioId: challenge?.sourceScenarioId ?? null,
                lines: challenge?.script?.lines ?? [],
                labelIds: challenge?.labels ?? [],
                tryLimit: challenge.tryLimit
            };

            const duplicateChallengeActionList =
                generateDuplicateChallengeActionList(newChallenge);

            !isEmpty(duplicateChallengeActionList) &&
                duplicateLibraryBulkActionService([
                    duplicateChallengeActionList,
                ]);
        },
        [
            duplicateLibraryBulkActionService,
            generateDuplicateChallengeActionList,
        ]
    );

    return (
        <MainWrapper
            subTitle="Library"
            tabs={<LibraryNavigationContainer />}
            hideDivider={!!selectedChallenges?.length}
            htmlPageTitle="Library - Practis"
            dataTest="library"
        >
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={challenges?.items}
                cornered={selectedLabels && !!selectedLabels.length}
                selectedLabels={selectedLabels}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'library-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: challenges?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: 'library-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'library-search-input',
                    },
                    isSelectedItemOptionsVisible:
                        !isEmpty(selectedChallenges) && !isEmpty(challenges),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedChallenges?.length,
                        totalCount: challenges?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled: bulkActions.areBulkActionsDisabled(),
                            disabledLabel: bulkActions.areItemsEmpty()
                                ? undefined
                                : "Bulk actions can't be applied",
                            assignLabelsPermissions:
                                bulkActions.isBulkActionVisible(
                                    ChallengeActions.ASSIGN_LABELS
                                )
                                    ? [NEW_PERMISSIONS.ASSIGN_CHALLENGE_LABEL]
                                    : [],
                            isAssignLabelsDisabled:
                                bulkActions.isBulkActionDisabled(
                                    ChallengeActions.ASSIGN_LABELS
                                ),
                            onOpenAssignLabelAction: onOpenAssignLabelAction,
                            onAssignLabelsSubmit: assignLabels,
                            isSelectAll: isSelectAll,
                            totalCount: challenges?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <LibraryFilters
                                        selectedStatuses={
                                            challenges ? selectedStatuses : []
                                        }
                                        savedStatuses={savedStatuses}
                                        handleSelectStatus={onSelectStatus}
                                        handleSaveStatus={onSaveStatus}
                                        preSelectedLabels={selectedLabels || []}
                                        onLabelsChange={labelChangeHandler}
                                        onSuccessApply={() => hide()}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={250}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={
                                            challenges
                                                ? savedStatuses.length +
                                                  (searchParams.labelIDs
                                                      ?.length || 0)
                                                : 0
                                        }
                                        disabled={tableStates.disableFilters}
                                        dataTest="library-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && challenges?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Document,
                        text: 'No Challenges Yet',
                        dataTest: 'library-no-challenges',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Challenges',
                        dataTest: 'library-no-found-challenges',
                    },
                    noFilterResultsOptions: {
                        dataTest: 'library-no-filtered-challenges',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'library-challenges-master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Challenges',
                            width: 25,
                            ...chName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-challenges-title-column',
                        },
                        {
                            title: 'Status',
                            width: 20,
                            ...chStatus,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-challenges-status-column',
                        },
                        {
                            title: 'Last Updated',
                            width: 20,
                            ...chUpdatedAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'library-challenges-date-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: handleItemEdit,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        dataTest: 'library-challenges-item',

                        getLabelTagsProps: (challenge: ChallengeInterface) => ({
                            selectedLabels: challenge.labels ?? [],
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_CHALLENGE_LABEL,
                            ],
                            onDeleteLabel: (labelId: number) =>
                                onDeleteLabelHandler(labelId, challenge),
                        }),

                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        challenge: ChallengeInterface
                                    ) => challenge?.title,
                                    dataTest: 'library-challenges-item-title',
                                },
                            },
                            {
                                fieldType: 'BADGE_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    challenge: ChallengeInterface
                                ) => !challenge?.status,
                                fieldProps: {
                                    getBadgeFieldProps: (
                                        challenge: ChallengeInterface
                                    ) => ({
                                        status: challenge?.status,
                                        className: classes.customTableCellStyle,
                                        dataTest:
                                            'library-challenges-item-status',
                                    }),
                                    renderTitle: (
                                        challenge: ChallengeInterface
                                    ) =>
                                        libraryItemsStatusName(
                                            challenge?.status
                                        ),
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    challenge: ChallengeInterface
                                ) => !challenge?.updatedAt,
                                fieldProps: {
                                    renderTitle: (
                                        challenge: ChallengeInterface
                                    ) => formatDate(challenge.updatedAt),
                                    dataTest: 'library-challenges-item-date',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (
                                        challenge: ChallengeInterface
                                    ) => ({
                                        open: labelsOpen === challenge.id,
                                        toggleOpen: () =>
                                            handleToggleLabels(
                                                Number(challenge.id)
                                            ),
                                        selectedLabels: challenge.labels ?? [],
                                        dataTest:
                                            'library-challenges-item-labels',
                                    }),
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (
                                        challenge: ChallengeInterface
                                    ) => (
                                        <ChallengesListActions
                                            challenge={challenge}
                                            actionsHelper={actionsHelper}
                                            restoreChallenge={
                                                onRestoreChallenge
                                            }
                                            handleItemEdit={handleItemEdit}
                                            handleViewChallenge={handleItemEdit}
                                            handleDeleteChallenge={
                                                handleDeleteChallenge
                                            }
                                            onDuplicateChallenge={
                                                handleDuplicateChallenge
                                            }
                                            archiveChallenge={
                                                onArchiveChallenge
                                            }
                                            assignLabelsToLibrary={(
                                                addedLabelIds,
                                                deletedLabelIds
                                            ) =>
                                                handleUpdateChallengesLabels(
                                                    addedLabelIds,
                                                    deletedLabelIds,
                                                    challenge?.id ?? 0
                                                )
                                            }
                                            isHidden={
                                                !!selectedChallenges?.length
                                            }
                                        />
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const ChallengesContainer: FC = () => {
    const state = useLibraryChallengesState();
    const history = useHistory();
    const deleteChallenges = useDeleteChallengesService();
    const archiveChallenge = useArchiveChallengeService();
    const restoreChallenge = useRestoreChallengeService();
    const searchChallenges = useSearchChallengesService();
    const updatedChallenges = useUpdatedLibraryChallengesState();
    const searchChallengesDebounced = useSearchDebounced(searchChallenges);

    const labels = useLabelsState();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const updateAllChallengesCheckedState =
        useUpdateAllChallengeCheckedStateService();
    const updateSelectedChallengeCheckedState =
        useUpdateLibraryChallengeCheckedStateService();
    const updatedLabels = useUpdatedLabelsState();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const actionsHelper = useChallengeActionsHelper();
    const profile = useSelector(getProfileState);

    const selectedChallenges = useMemo(
        () => selectSelectedChallenges(state),
        [state]
    );

    return (
        <WithLabelsContext.Provider
            value={{ reducerName: 'libraryChallenges' }}
        >
            <Challenges
                onDeleteChallenges={deleteChallenges}
                onArchiveChallenge={archiveChallenge}
                onRestoreChallenge={restoreChallenge}
                history={history}
                searchChallenges={searchChallengesDebounced}
                challenges={state.data}
                loading={state.loading}
                selectedChallengeIds={state.selectedChallenges}
                selectedChallenges={selectedChallenges}
                actionsHelper={actionsHelper}
                updatedChallenges={updatedChallenges.data}
                selectedLabels={labels.selected}
                setAssignLabelsAction={setAssignLabelsAction}
                assignedLabels={labels.assignedLabels}
                updateAllChallengesCheckedState={
                    updateAllChallengesCheckedState
                }
                updateSelectedChallengeCheckedState={
                    updateSelectedChallengeCheckedState
                }
                updatedLabels={updatedLabels}
                saveGlobalLabelFilters={saveGlobalLabelFilters}
                companyId={profile?.companyId}
            />
        </WithLabelsContext.Provider>
    );
};



export default ChallengesContainer;