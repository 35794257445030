import {
    useState,
    RefObject,
    useEffect,
    useLayoutEffect,
    useRef,
    CSSProperties,
    useMemo,
} from 'react';

import useWindowDimensions from './useWindowDimensions';
import { useOutsideClick } from './useOutsideClick';

//region Types
export type PopupPosition =
    | 'center-right--bottom'
    | 'center-left--bottom'
    | 'center-right--top'
    | 'center-left--top'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'left-start'
    | 'top-start'
    | 'top-end';

export type PopupTrigger = 'hover' | 'click';

export type PopupOffset = { x?: string; y?: string };

export type PopupConfig = {
    position?: PopupPosition;
    offset?: PopupOffset;
    trigger?: PopupTrigger;
    interactive?: boolean;
    autoBind?: boolean;
    hideOnOutsideClick?: boolean;
};

export type PopupProps = {
    enabled: boolean;
    enable: () => void;
    disable: () => void;
    style: CSSProperties;
    show: () => void;
    hide: () => void;
    toggleShown: () => void;
    shown: boolean;
    position: PopupPosition;
};
//endregion

//region Config
const positionsConfig: Record<
    PopupPosition,
    {
        overflowHorizontal?: PopupPosition;
        overflowVertical?: PopupPosition;
        defaultOffset: PopupOffset;
    }
> = {
    'bottom-end': {
        overflowVertical: 'top-end',
        overflowHorizontal: 'bottom-start',
        defaultOffset: {
            x: '0px',
            y: '5px',
        },
    },
    'bottom-start': {
        overflowVertical: 'top-start',
        overflowHorizontal: 'bottom-end',
        defaultOffset: {
            x: '0px',
            y: '5px',
        },
    },
    'top-start': {
        overflowVertical: 'bottom-start',
        overflowHorizontal: 'top-end',
        defaultOffset: {
            x: '0px',
            y: '-5px',
        },
    },
    'top-end': {
        overflowVertical: 'bottom-end',
        overflowHorizontal: 'top-start',
        defaultOffset: {
            x: '0px',
            y: '-5px',
        },
    },
    'left-start': {
        overflowHorizontal: 'right-start',
        defaultOffset: {
            x: '-5px',
            y: '0px',
        },
    },
    'right-start': {
        overflowHorizontal: 'left-start',
        defaultOffset: {
            x: '5px',
            y: '0px',
        },
    },
    'center-right--bottom': {
        overflowHorizontal: 'center-left--bottom',
        overflowVertical: 'center-right--top',
        defaultOffset: {
            x: '0px',
            y: '0px',
        },
    },
    'center-left--bottom': {
        overflowHorizontal: 'center-right--bottom',
        overflowVertical: 'center-left--top',
        defaultOffset: {
            x: '0px',
            y: '0px',
        },
    },
    'center-right--top': {
        overflowHorizontal: 'center-left--top',
        overflowVertical: 'center-right--bottom',
        defaultOffset: {
            x: '0px',
            y: '0px',
        },
    },
    'center-left--top': {
        overflowHorizontal: 'center-right--bottom',
        overflowVertical: 'center-left--bottom',
        defaultOffset: {
            x: '0px',
            y: '0px',
        },
    },
};
//endregion

/**
 * Hook for absolute positioning blocks
 * @param triggerRef Element which will be trigger events
 * @param contentRef Element with exiting content
 * @param containerElement
 * @param config "autoBind" add event listeners for triggerRef. "interactive" - content can be react to user actions
 */
export const usePopup = (
    triggerRef: RefObject<HTMLElement>,
    contentRef: RefObject<HTMLElement>,
    containerElement: HTMLElement,
    config?: PopupConfig
): PopupProps => {
    const targetConfig: Required<PopupConfig> = {
        offset: { x: '0px', y: '0px' },
        position: 'bottom-start',
        trigger: 'hover',
        interactive: false,
        autoBind: true,
        hideOnOutsideClick: true,
        ...config,
    };

    const window = useWindowDimensions();
    const containerElementPositionChange = containerElement.getBoundingClientRect();

    const bounds = useMemo(() => {
        const containerRects = containerElement.getBoundingClientRect();
        const triggerRects = triggerRef.current?.getBoundingClientRect();
        return {
            top: (triggerRects?.top || 0) - containerRects.top,
            left: (triggerRects ? triggerRects.left : 0) - containerRects.left,
            right:
                (triggerRects ? triggerRects.right : 0) - containerRects.right,
            bottom: (triggerRects?.bottom || 0) - containerRects.bottom,
            height: triggerRects?.height || 0,
            width: triggerRects?.width || 0,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        containerElement,
        containerElementPositionChange,
        triggerRef,
        triggerRef.current,
        window,
    ]);

    // Set Handlers
    useEffect(() => {
       const currentTriggerRef = triggerRef?.current;

        if (currentTriggerRef && targetConfig.autoBind) {
            switch (targetConfig.trigger) {
                case 'hover':
                    currentTriggerRef.addEventListener('mouseenter', show);
                    currentTriggerRef.addEventListener('mouseleave', hide);
                    break;
                case 'click':
                    currentTriggerRef.addEventListener('click', toggleShown);
                    break;
            }
        }

        return () => {
            if (currentTriggerRef && targetConfig.autoBind) {
                switch (targetConfig.trigger) {
                    case 'hover':
                        currentTriggerRef.removeEventListener(
                            'mouseenter',
                            show
                        );
                        currentTriggerRef.removeEventListener(
                            'mouseleave',
                            hide
                        );
                        break;
                    case 'click':
                        currentTriggerRef.removeEventListener(
                            'click',
                            toggleShown
                        );
                        break;
                }
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Positioning
    const [position, setPosition] = useState<PopupPosition>(
        targetConfig.position
    );

    const [shown, _setShown] = useState(false);
    // addEventListener doesn't updating on re-rendering
    const shownRef = useRef(shown);
    const setShown = (value: boolean) => {
        shownRef.current = value;
        _setShown(value);
    };
    const show = () => {
        setShown(true);
    };
    const hide = () => {
        setShown(false);
    };
    const toggleShown = () => {
        if (shownRef.current) {
            hide();
        } else {
            show();
        }
    };

    useOutsideClick(
        [contentRef, triggerRef],
        hide,
        targetConfig.hideOnOutsideClick
    );

    // Detecting when content is out of viewport
    useLayoutEffect(() => {
        if (shown && contentRef.current) {
            const contentRects = contentRef.current.getBoundingClientRect();
            const isOutOfHorizontal =
                contentRects.right > window.width || contentRects.left < 0;

            if (
                isOutOfHorizontal &&
                positionsConfig[position].overflowHorizontal
            ) {
                setPosition(
                    positionsConfig[position]
                        .overflowHorizontal as PopupPosition
                );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shown]);

    const [enabled, setEnabled] = useState(true);
    const enable = () => {
        setEnabled(true);
    };
    const disable = () => {
        hide();
        setEnabled(false);
    };

    if (triggerRef.current === null) {
        return {
            style: {
                left: '0px',
                top: '0px',
            },
            shown,
            hide,
            show,
            enabled,
            enable,
            disable,
            toggleShown,
            position,
        };
    }

    const getOffset = (): PopupOffset => {
        if (config && config.offset) {
            return {
                x: config.offset.x || positionsConfig[position].defaultOffset.x,
                y: config.offset.y || positionsConfig[position].defaultOffset.y,
            };
        }

        return positionsConfig[position].defaultOffset;
    };

    const getPositionStyles = (): CSSProperties => {
        const offset = getOffset();
        switch (position) {
            case 'bottom-start':
                return {
                    top: `calc(${(bounds.top + bounds.height).toString()}px + ${
                        offset.y
                    })`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                };
            case 'bottom-end':
                return {
                    top: `calc(${(bounds.top + bounds.height).toString()}px + ${
                        offset.y
                    })`,
                    left: `calc(${bounds.left.toString()}px + ${
                        bounds.width
                    }px + ${offset.x})`,
                    transform: 'translateX(-100%)',
                };
            case 'right-start':
                return {
                    top: `calc(${bounds.top.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${
                        bounds.width
                    }px + ${offset.x})`,
                };
            case 'left-start':
                return {
                    top: `calc(${bounds.top.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                    transform: 'translateX(-100%)',
                };
            case 'top-start':
                return {
                    top: `calc(${bounds.top.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                    transform: 'translateY(-100%)',
                };
            case 'top-end':
                return {
                    top: `calc(${bounds.top.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${
                        bounds.width
                    }px + ${offset.x})`,
                    transform: 'translateY(-100%) translateX(-100%)',
                };
            case 'center-right--bottom':
                return {
                    top: `calc(${bounds.top.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                    transform: `translateY(${bounds.height / 2}px) translateX(${
                        bounds.width / 2
                    }px)`,
                };
            case 'center-left--bottom':
                return {
                    top: `calc(${bounds.top.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                    transform: `translateY(${
                        bounds.height / 2
                    }px) translateX(calc(-100% + ${bounds.width / 2}px))`,
                };
            case 'center-right--top':
                return {
                    top: `calc(${bounds.bottom.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                    transform: `translateY(calc(-100% - ${
                        bounds.height / 2
                    }px + ${offset.y})) translateX(${bounds.width / 2}px)`,
                };
            case 'center-left--top':
                return {
                    top: `calc(${bounds.bottom.toString()}px + ${offset.y})`,
                    left: `calc(${bounds.left.toString()}px + ${offset.x})`,
                    transform: `translateY(calc(-100% - ${
                        bounds.height / 2
                    }px + ${offset.y})) translateX(calc(-100% + ${
                        bounds.width / 2
                    }px))`,
                };
        }
    };

    const getAdditionalStyles = (): CSSProperties => {
        const styles: CSSProperties = {
            position: 'absolute',
            zIndex: 1500,
        };

        if (!targetConfig.interactive) {
            styles.pointerEvents = 'none';
        }

        return styles;
    };

    return {
        style: { ...getPositionStyles(), ...getAdditionalStyles() },
        shown,
        hide,
        show,
        enabled,
        enable,
        disable,
        toggleShown,
        position,
    };
};
