import { Button } from '../../../../../ui/components/Button';
import LoginAuthWrapper from '../../../../../ui/wrapper/LoginAuthWrapper';
import CheckEmailImage from '../../../../../assets/images/check-email.svg';
import { FurtherInstructionsViewProps } from './types';
import {
    Title,
    Description,
} from './styles';

function FurtherInstructionsView ({ onSubmit }: FurtherInstructionsViewProps) {
    return (
        <LoginAuthWrapper contentWidth={289} heightToShowScroll={350}>
            <img src={CheckEmailImage} width='48px' height='48px' alt='Check your email' />
            <Title>
                Check Your Email
            </Title>
            <Description>
                If you can’t find our message with reset instructions, look in your spam folder.
            </Description>
            <Button
                height='56px'
                width='289px'
                fontSize={15}
                action={onSubmit}
            >
                Got it
            </Button>

        </LoginAuthWrapper>
    );
};

export default FurtherInstructionsView;
