import { Action } from 'redux';

import { ListResult } from '../../../../../constants/interfaces/PaginationResult';
import { Scope } from './scopes';
import { PractisSets } from '../../../../../constants/interfaces/PractisSets';
import { PractisSetWithDueDate } from '../../../../../constants/interfaces/Draft';

//region Types
type ReturnTypePractisSetAction<
    T extends (...args: any) => any
> = ReturnType<T> & ActionPayload;

type ActionPayload = { _scope?: Scope };

export type ActionPractisSet<T = any> = Action<T> & ActionPayload;

export type Actions =
    | ReturnTypePractisSetAction<typeof selectAllAction>
    | ReturnTypePractisSetAction<typeof deselectAllAction>
    | ReturnTypePractisSetAction<typeof resetSelectedAction>
    | ReturnTypePractisSetAction<typeof selectPractisSetsAction>
    | ReturnTypePractisSetAction<typeof selectMultiplePractisSetsAction>
    | ReturnTypePractisSetAction<typeof clearAction>
    | ReturnTypePractisSetAction<typeof saveAction>
    | ReturnTypePractisSetAction<typeof resetAction>;

//endregion

export const searchStartAction = (): ActionPractisSet<'searchStartPractisSets'> => ({
    type: 'searchStartPractisSets',
});

export const searchSuccessAction = (
    result: ListResult<PractisSets>
): ActionPractisSet<'searchSuccessPractisSets'> & {
    result: ListResult<PractisSets>;
} => ({
    type: 'searchSuccessPractisSets',
    result,
});

export const searchFailureAction = (
    message: string
): ActionPractisSet<'searchFailurePractisSets'> & { message: string } => ({
    type: 'searchFailurePractisSets',
    message,
});

export const selectAllAction = (
    allPractisSets: PractisSets[]
): ActionPractisSet<'selectAllPractisSets'> & {
    allPractisSets: PractisSets[];
} => ({
    type: 'selectAllPractisSets',
    allPractisSets,
});

export const deselectAllAction = (): ActionPractisSet<'deselectAllPractisSets'> => ({
    type: 'deselectAllPractisSets',
});

export const resetSelectedAction = (): ActionPractisSet<'resetSelectedPractisSets'> => ({
    type: 'resetSelectedPractisSets',
});

export const selectPractisSetsAction = (
    id: number
): ActionPractisSet<'selectPractisSets'> & { id: number } => ({
    type: 'selectPractisSets',
    id,
});

export const selectMultiplePractisSetsAction = (
    ids: PractisSetWithDueDate[]
): ActionPractisSet<'selectMultiplePractisSets'> & { ids: PractisSetWithDueDate[] } => ({
    type: 'selectMultiplePractisSets',
    ids,
});

export const clearAction = (): ActionPractisSet<'clearPractisSets'> => ({
    type: 'clearPractisSets',
});

export const saveAction = (): ActionPractisSet<'savePractisSets'> => ({
    type: 'savePractisSets',
});

export const resetAction = (): ActionPractisSet<'resetPractisSets'> => ({
    type: 'resetPractisSets',
});
