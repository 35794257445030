import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
    useCreateUpdateEnrollmentDueDate,
    useEnrollPractisSetsToUserApi,
    useGetEnrollments,
    useSearchTeamMembersApi,
    useSearchUsersApi,
} from '../../../../api';
import { EnrollUnenrollParams, UserEnrollmentType } from './types';
import {
    EnrollmentsDueDateType,
    EnrollmentType,
} from '../../../../api/enrollments/types';
import { getSearchSortingValue } from '../../../../helpers/functions/search-params-helpers';
import { createSearchTeamMembersParams } from '../../../teams/tools';
import { PractisSetWithDueDate } from '../../../../constants/interfaces/Draft';
import { UserStatus } from '../../../users/store/costants';
import { SearchUsersParams } from '../../../../api/users/types';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import { EnrollmentStatus } from '../../../../constants/interfaces/Enrollments';
import { ChunkRequestActionInterface } from '../../../../services/ChunkRequestService/hooks/types';
import {
    ENROLL_UNENROLL_ACTION,
    FETCH_USER_ENROLLMENT_ACTION,
    SEARCH_USERS_ACTION,
    UPDATE_ENROLLMENT_ACTION,
} from './constants';
import { SearchTeamMembersParams } from '../../../../api/teams/types';

/**
 * @function useFetchUserEnrollmentsService
 * @returns { CallableFunction }
 */
const useFetchUserEnrollmentsService = () => {
    const getEnrollments = useGetEnrollments();

    /**
     * @param { PractisSetWithDueDate[] } selectedPractisSets
     * @param { number[] } userIds
     */
    return useCallback(
        async (
            selectedPractisSets: PractisSetWithDueDate[],
            userIds: number[]
        ) => {
            if (!isEmpty(userIds)) {
                const getEnrollmentSearchParams = {
                    users: userIds?.join(','),
                    enrollmentStatus: Object.keys(EnrollmentStatus).join(','),
                    sort: getSearchSortingValue(null, 'pset_name', true),
                    limit: 10000,
                };

                const usersEnrollmentsData = await getEnrollments(
                    getEnrollmentSearchParams as any
                );

                if (!isEmpty(usersEnrollmentsData)) {
                    const userEnrollmentResult = selectedPractisSets.reduce(
                        (result: UserEnrollmentType[], selectedPractisSet) => {
                            const {
                                isAlreadyExist,
                                isDueDateUpdated,
                                enrollmentId,
                            } = usersEnrollmentsData.items.reduce(
                                (result, item) => {
                                    if (
                                        !result.isAlreadyExist &&
                                        item.practisSet.id ===
                                            selectedPractisSet.practisSetId
                                    ) {
                                        result.isAlreadyExist = true;
                                        result.enrollmentId = null;
                                    }

                                    if (
                                        !result.isDueDateUpdated &&
                                        item.practisSet.id ===
                                            selectedPractisSet.practisSetId &&
                                        item.dueDate !==
                                            selectedPractisSet.dueDate
                                    ) {
                                        result.isDueDateUpdated = true;
                                        result.enrollmentId = item.id;
                                    }

                                    return result;
                                },
                                {
                                    isAlreadyExist: false,
                                    isDueDateUpdated: false,
                                    enrollmentId: null as number | null,
                                }
                            );

                            if (!isAlreadyExist) {
                                result.push({
                                    ...selectedPractisSet,
                                    userId: userIds[0],
                                    isDueDateUpdated: false,
                                });
                            }

                            if (isDueDateUpdated && enrollmentId) {
                                result.push({
                                    enrollmentId,
                                    dueDate: selectedPractisSet.dueDate,
                                    isDueDateUpdated: true,
                                });
                            }

                            return result;
                        },
                        []
                    );

                    return userEnrollmentResult;
                }
            }
        },
        [getEnrollments]
    );
};

/**
 * @function useHandleEnrollmentService
 * @returns { CallableFunction }
 */
const useHandleEnrollmentService = () => {
    const enrollPractisSets = useEnrollPractisSetsToUserApi();

    /**
     * @param { UserEnrollmentType[] } userEnrollments
     * @returns { Promise<void> }
     */
    return useCallback(
        async (userEnrollments: UserEnrollmentType[]) => {
            const assignedPractisSets = userEnrollments.reduce(
                (
                    result: EnrollmentType[],
                    { userId, practisSetId, dueDate, isDueDateUpdated }
                ) => {
                    if (!isDueDateUpdated) {
                        result.push({
                            userId: userId!,
                            practisSetId: practisSetId!,
                            dueDate,
                        });
                    }
                    return result;
                },
                []
            );

            if (!isEmpty(assignedPractisSets)) {
                await enrollPractisSets(assignedPractisSets);
            }
        },
        [enrollPractisSets]
    );
};

/**
 * @function useHandleUpdateEnrollmentService
 * @returns { CallableFunction }
 */
const useHandleUpdateEnrollmentService = () => {
    const updateEnrollmentsDueDate = useCreateUpdateEnrollmentDueDate();

    /**
     * @param { UserEnrollmentType[] } userEnrollments
     * @returns { Promise<void> }
     */
    return useCallback(
        async (userEnrollments: UserEnrollmentType[]) => {
            const updatedEnrollments = userEnrollments.reduce(
                (
                    result: EnrollmentsDueDateType[],
                    { enrollmentId, dueDate, isDueDateUpdated }
                ) => {
                    if (isDueDateUpdated) {
                        result.push({
                            enrollmentId: enrollmentId!,
                            dueDate,
                        });
                    }
                    return result;
                },
                []
            );

            if (!isEmpty(updatedEnrollments)) {
                await updateEnrollmentsDueDate(updatedEnrollments);
            }
        },
        [updateEnrollmentsDueDate]
    );
};

/**
 * @function useGenerateAllUsersEnrollmentActionList
 * @returns { CallableFunction }
 */
export function useGenerateAllUsersEnrollmentActionList() {
    const searchUsersWithTeamApi = useSearchTeamMembersApi();
    const searchUsersApi = useSearchUsersApi();
    const company = useSelector(getCompanyState);
    const handleFetchUserEnrollmentsService = useFetchUserEnrollmentsService();
    const handleEnrollmentService = useHandleEnrollmentService();
    const handleUpdateEnrollmentService = useHandleUpdateEnrollmentService();

    /**
     * @function handleSearchUserWithTeamService
     * @param { number } teamId
     * @param { SearchTeamMembersParams } searchParams
     * @param { PractisSetWithDueDate[] } selectedPractisSets
     * @returns { Promise<UserEnrollmentType[] }
     */
    const handleSearchUserWithTeamService = useCallback(
        async (
            teamId: number,
            searchParams: SearchTeamMembersParams,
            selectedPractisSets: PractisSetWithDueDate[]
        ) => {
            if (teamId) {
                const teamMembers = await searchUsersWithTeamApi(
                    teamId,
                    searchParams
                );

                if (!isEmpty(teamMembers)) {
                    const userIds = teamMembers.items?.map(
                        ({ user }) => user.id
                    );

                    if (!isEmpty(userIds)) {
                        return await handleFetchUserEnrollmentsService(
                            selectedPractisSets,
                            userIds
                        );
                    }
                }
            }
        },
        [handleFetchUserEnrollmentsService, searchUsersWithTeamApi]
    );

    /**
     * @function handleSearchRegisteredUsersService
     * @param { SearchUsersParams } searchParams
     * @param { PractisSetWithDueDate[] } selectedPractisSet
     * @returns { Promise<UserEnrollmentType[] }
     */
    const handleSearchRegisteredUsersService = useCallback(
        async (
            searchParams: SearchUsersParams,
            selectedPractisSets: PractisSetWithDueDate[]
        ) => {
            if (!isEmpty(searchParams)) {
                const registeredUsers = await searchUsersApi(searchParams);

                if (!isEmpty(registeredUsers)) {
                    const userIds = registeredUsers.items?.map(user => user.id);

                    if (!isEmpty(userIds)) {
                        return await handleFetchUserEnrollmentsService(
                            selectedPractisSets,
                            userIds
                        );
                    }
                }
            }
        },
        [handleFetchUserEnrollmentsService, searchUsersApi]
    );

    /**
     * @param { EnrollUnenrollParams } EnrollUnenrollParams
     */
    return useCallback(
        ({
            teamId,
            searchParams,
            selectedPractisSets,
            totalUsers,
        }: EnrollUnenrollParams) => {
            let searchUsersActionFunction = null;
            let searchUsersActionParameters = null;

            if (teamId) {
                searchUsersActionFunction = handleSearchUserWithTeamService;
                const searchTeamMembersParams = createSearchTeamMembersParams({
                    ...searchParams,
                    limit: 1,
                    offset: totalUsers || 0,
                });

                searchUsersActionParameters = {
                    teamId,
                    searchParams: searchTeamMembersParams,
                    selectedPractisSets,
                };
            } else {
                const searchRegisteredUsersParams: SearchUsersParams = {
                    status: UserStatus.ACTIVE,
                    labels: searchParams?.labelIDs?.join(','),
                    roles: searchParams?.roleIDs?.join(','),
                    teams: searchParams?.teamIds?.join(','),
                    companies: company.id?.toString(),
                    limit: 1,
                    offset: totalUsers || 0,
                    query: searchParams?.searchTerm,
                    sort: getSearchSortingValue(
                        searchParams?.orderBy,
                        'name',
                        true
                    ),
                };

                searchUsersActionFunction = handleSearchRegisteredUsersService;
                searchUsersActionParameters = {
                    searchParams: searchRegisteredUsersParams,
                    selectedPractisSets,
                };
            }

            let actionList: ChunkRequestActionInterface<any> = {
                actionName: SEARCH_USERS_ACTION,
                actionFunction: searchUsersActionFunction,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: searchUsersActionParameters,
                    fieldName: 'searchParams.offset',
                },
                childActionList: [
                    {
                        actionName: ENROLL_UNENROLL_ACTION,
                        actionFunction: handleEnrollmentService,
                        actionFunctionOptions: {
                            parameters: {
                                data: [],
                            },
                            fieldName: 'data',
                            secondaryFieldName: 'data',
                        },
                    },
                    {
                        actionName: UPDATE_ENROLLMENT_ACTION,
                        actionFunction: handleUpdateEnrollmentService,
                        actionFunctionOptions: {
                            parameters: {
                                data: [],
                            },
                            fieldName: 'data',
                            secondaryFieldName: 'data',
                        },
                    },
                ],
            };

            return actionList;
        },
        [
            company.id,
            handleEnrollmentService,
            handleSearchRegisteredUsersService,
            handleSearchUserWithTeamService,
            handleUpdateEnrollmentService,
        ]
    );
}

/**
 * @function useGenerateMultipleUsersEnrollmentActionList
 * @returns { CallableFunction }
 */
export function useGenerateMultipleUsersEnrollmentActionList() {
    const handleFetchUserEnrollmentsService = useFetchUserEnrollmentsService();
    const handleEnrollmentService = useHandleEnrollmentService();
    const handleUpdateEnrollmentService = useHandleUpdateEnrollmentService();

    /**
     * @param { number[] } selectedUserIds
     * @param { PractisSetWithDueDate[] } selectedPractisSets
     */
    return useCallback(
        (
            selectedUserIds: number[],
            selectedPractisSets: PractisSetWithDueDate[]
        ) => {
            let actionList: ChunkRequestActionInterface<any> = {
                actionName: FETCH_USER_ENROLLMENT_ACTION,
                actionFunction: handleFetchUserEnrollmentsService,
                itemPerChunk: 1,
                actionFunctionOptions: {
                    parameters: {
                        selectedPractisSets,
                        userIds: selectedUserIds,
                    },
                    fieldName: 'userIds',
                },
                childActionList: [
                    {
                        actionName: ENROLL_UNENROLL_ACTION,
                        actionFunction: handleEnrollmentService,
                        actionFunctionOptions: {
                            parameters: {
                                data: [],
                            },
                            fieldName: 'data',
                            secondaryFieldName: 'data',
                        },
                    },
                    {
                        actionName: UPDATE_ENROLLMENT_ACTION,
                        actionFunction: handleUpdateEnrollmentService,
                        actionFunctionOptions: {
                            parameters: {
                                data: [],
                            },
                            fieldName: 'data',
                            secondaryFieldName: 'data',
                        },
                    },
                ],
            };

            return actionList;
        },
        [
            handleEnrollmentService,
            handleFetchUserEnrollmentsService,
            handleUpdateEnrollmentService,
        ]
    );
}