import { Roles } from '../../../constants/interfaces/Roles';

export enum ACTIONS {
    LOAD_USER_ROLES_START = 'LOAD_USER_ROLES_START =',
    LOAD_USER_ROLES_SUCCESS = 'LOAD_USER_ROLES_SUCCESS',
    LOAD_USER_ROLES_FAILURE = 'LOAD_USER_ROLES_FAILURE',
}

export function loadUserRolesStart() {
    return {
        type: ACTIONS.LOAD_USER_ROLES_START as ACTIONS.LOAD_USER_ROLES_START,
    };
}

export function loadUserRolesSuccess(data: Roles[]) {
    return {
        type: ACTIONS.LOAD_USER_ROLES_SUCCESS as ACTIONS.LOAD_USER_ROLES_SUCCESS,
        data,
    };
}

export function loadUserRolesFailure(error: string) {
    return {
        type: ACTIONS.LOAD_USER_ROLES_FAILURE as ACTIONS.LOAD_USER_ROLES_FAILURE,
        error,
    };
}
