const SCROLL_BY = 100;

export const scrollUp = (element: any) => {
    if (element) {
        element.scrollTop -= SCROLL_BY;
    }
};

export const scrollDown = (element: any) => {
    if (element) {
        element.scrollTop += SCROLL_BY;
    }
};
