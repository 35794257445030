import styled from 'styled-components';
import { isAdminPortal } from '../../../helpers/functions/general';
import PractisAdminPlatformLogo from '../../icons/PractisAdminPlatformLogo';
import PractisLogo from '../../icons/PractisLogo';

export const StyledLogoContainer = styled.div<{ width: number, marginBottom?: number }>`
    width: ${props => props.width}px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: ${props => `${props.marginBottom ?? 57}px`};
`;

export const StyledLogo = styled(isAdminPortal() ? PractisAdminPlatformLogo : PractisLogo)`
    width: 100%;
    color: ${props => props.theme.Colors.white};
`;