import { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { InfoSnackbar, variantIcon } from '../InfoSnackbar/InfoSnackbar';
import { displayMessage, hideMessage } from './store/actions';
import { getErrorMessages } from './store/reducers';
import { AppState } from '../../../store/reducers';
import { CLIENT_SIDE_ERROR_MESSAGE } from './constants';


export const handleMessage = (
    dispatch: Dispatch,
    error: string | Array<string>,
    status: keyof typeof variantIcon = 'error'
) => {
    const clientSideErrorMessage: string | Array<string> =
        (!Array.isArray(error) && CLIENT_SIDE_ERROR_MESSAGE[error]) ||
        error;


        dispatch(
        displayMessage({
            message: clientSideErrorMessage,
            status: status,
        })
    );
    setTimeout(() => {
        dispatch(hideMessage());
    }, 5000);
};

export const useShowMessage = () => {
    const dispatch = useDispatch();

    return useCallback(
        (message: string | string[], status: keyof typeof variantIcon) =>
            handleMessage(dispatch, message, status),
        [dispatch]
    );
};

export interface ErrorMessagesInterface {
    status: keyof typeof variantIcon | null;
    message: string | Array<string>;
}

const ErrorMessages = ({
    messages,
    hideMessage,
}: {
    messages: ErrorMessagesInterface;
    hideMessage: Function;
}) => {
    return (
        <>
            {!!messages.status && (
                <InfoSnackbar
                    open={!!messages.status}
                    variant={messages.status ? messages.status : 'error'}
                    message={messages.message}
                    handleClose={() => hideMessage()}
                />
            )}
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        messages: getErrorMessages(state),
    };
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            hideMessage: hideMessage,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessages);
