import * as qs from 'query-string';

export type TQueryParamValue = string | undefined;
export type TRawQueryParamValue = TQueryParamValue | string[];

export interface IQueryString {
    [key: string]: TQueryParamValue;
}

export interface IRawQueryString {
    [key: string]: TRawQueryParamValue;
}

interface IParseResult {
    url: string;
    query: IQueryString;
}

interface IRawParseResult {
    url: string;
    query: IRawQueryString;
}

function parseRawQueryString(url: string): IRawParseResult {
    return qs.parseUrl(url) as IRawParseResult;
}

function parseQueryString(url: string): IParseResult {
    const rawQuery = parseRawQueryString(url).query;
    const query: IQueryString = {};
    Object.keys(rawQuery).map(key => {
        query[key] = flatQueryValue(key, rawQuery[key]);
        return key;
    });
    return {
        url,
        query,
    };
}

function flatQueryValue(
    key: string,
    value: TRawQueryParamValue
): TQueryParamValue {
    if (Array.isArray(value)) {
        const result = value.filter(v => !!v);
        return result.length ? result[0] : undefined;
    }
    return value;
}

export function getRawQueryStringFromUrl(url: string): IRawQueryString {
    return parseRawQueryString(url).query;
}

export function getQueryStringFromUrl(url: string): IQueryString {
    return parseQueryString(url).query;
}

export function getQueryStringFromCurrentUrl(): IQueryString {
    return getQueryStringFromUrl(getCurrentUrl());
}

export function setQueryParameterToUrl(
    url: string,
    name: string,
    value?: string
) {
    // TODO: [MM]: Add Array value support
    const { url: parsedUrl, query } = parseRawQueryString(url);
    query[name] = value;
    const resultQuery = qs.stringify(query);
    return parsedUrl + (resultQuery ? `?${resultQuery}` : '');
}

export function setQueryObjectToUrl(url: string, obj: any) {
    const { url: parsedUrl, query } = parseRawQueryString(url);
    Object.keys(obj).map(key => {
        const v = obj[key];
        query[key] = typeof v !== 'boolean' && typeof v !== 'number' && !v ? undefined : v;
        return key;
    });
    const resultQuery = qs.stringify(query);
    return parsedUrl + (resultQuery ? `?${resultQuery}` : '');
}

export function removeQueryObjectFromUrl(url: string, obj: any) {
    const emptyObj: any = {};
    Object.keys(obj).map(key => {
        emptyObj[key] = null;
        return key;
    });
    return setQueryObjectToUrl(url, emptyObj);
}

export function getCurrentUrl(): string {
    return window.location ? window.location.href : '';
}

export function setCurrentUrl(url: string) {
    if (window.history.replaceState) {
        window.history.replaceState({ path: url }, '', url);
    }
}
