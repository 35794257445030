import styled from 'styled-components';

export const StyledNoResults = styled.div`
    display: flex;
    padding-top: 136px;
    padding-bottom: 88px;
    flex-direction: column;
    align-items: center;
`;

export const StyledNoResultsContent = styled.div`
    max-width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoResultsTitle = styled.div<{ width?: string }>`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
    ${props => !!props.width && `width: ${props.width};`}
`;

export const NoResultsIcon = styled.div`
    width: 48px;
    height: 48px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 10px;
`;

