import React, {
    FC, memo, ReactNode,
} from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
import QuestionMark from '../../icons/QuestionMark';

const TooltipHeaderWrapper = styled.div`
   display: flex;

   svg{
       max-width: 16px;
       margin-left: 4px;

       &:hover{
           circle{
            fill: ${props => props.theme.Colors.whiteThree};
           }
       }
   }

   .tooltip{
       padding: 24px !important;
       width: 280px;
   }
`;

const FlexWrapper = styled.div`
    display: flex;
`;

export const QuestionTooltip: FC<{
    text: ReactNode | string,
    isHtmlContent?: boolean,
    dataTest?: string,
    position?: string;
    action?: () => void;
}> = memo(({
    text,
    isHtmlContent,
    dataTest,
    action,
    position
}) => {
        return (
            <>
                <TooltipHeaderWrapper onClick={(e) => {e.stopPropagation(); action?.()}}>
                    <Tooltip
                        label={text}
                        preset={position === 'top' ? 'question-icon-top': 'question-icon'}
                        className={`${position  === 'top' ? 'top' : ''} tooltip-question`}
                        trigger={'click'}
                        isHtmlContent={isHtmlContent}
                        dataTest={dataTest && `${dataTest}-text`}
                    >
                        <FlexWrapper data-test={dataTest && `${dataTest}-icon`}>
                            <QuestionMark />
                        </FlexWrapper>
                    </Tooltip>
                </TooltipHeaderWrapper>
            </>
        );
    });