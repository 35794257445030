import { History } from 'history';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import ModalPage, {
    ModalPageContainerProps,
} from '../../../../ui/components/ModalPage/ModalPage';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../../constants/interfaces/PractisSets';
import { UserInterface, UserProfile, UserV2 } from '../../../../constants/interfaces/User';
import { PractisSetsEnrollmentState } from '../../../../store/selectors';
import {
    useClearLabelFilterActionService,
    useEnrollPractisSetsToUsersService,
    useGetPractisSetService,
    useSaveSelectedInvitationLabelsService,
    useSearchAssignUsersService,
    useUpdateAllSelectedInvitationTraineesStateService,
    useUpdateSelectedInvitationTraineesStateService,
} from '../../store/services';
import { useTrainerInvitationState } from '../../store/states';
import { Button } from '../../../../ui/components/Button';
import {
    formatDate,
    formatDateWithTimeZone,
} from '../../../../helpers/functions/date-convert';
import { useLabelsState } from '../../../labels/store/states';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import {
    clearSelectedItemIfNotLoaded,
    useHandleSelectLabels,
} from '../../../labels/tools';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { Label } from '../../../../constants/interfaces/Label';
import { listToTree } from '../../../../helpers/functions/list-to-tree';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { WithLabelsContext } from '../../../portableLabels';
import Published from '../../../../ui/icons/Published';
import { useLabelsState as useLocalLabelsState } from '../../../portableLabels/store/hors/withLabels/states';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import { Popup } from '../../../../ui/components/Popup';
import { AllUsersFilter } from '../ManageTeam/components/Filters/AllUsersFilters';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { useSearchDebounced } from '../../../../helpers/hooks/useSearch';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import User from '../../../../ui/icons/User';
import {
    TraineeInvitationActionHelper,
    TraineeInvitationActions,
    useTraineeInvitationActionHelpers,
} from '../../tools/TraineeInvitationActionHelpers';
import { findSelectedTraineeDueDate } from './helper';
import { DueDateCalendar } from '../../../../ui/components/DueDateCalendar';
import DueDateListAction from './components/ListActions/DueDateListAction';
import { isEmpty as lodashIsEmpty, uniqBy } from 'lodash';
import {
    DueDateType,
} from '../../../../constants/interfaces/DueDates';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import {
    Avatar,
    AvatarTile,
    CancelWrapper,
    IconWrapper,
    InvitationHeader,
    InvitationHeaderSubText,
    InvitationHeaderWrapper,
    QuickAssignmentInvitationFooter,
    QuickAssignmentInvitationHeaderText,
    StyledFirstTableItemContent,
    StyledTableCell,
    StyledTraineeInvitationContainer,
    useStyles,
} from './styles';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { TableDivider } from '../../../../ui/components/table-wrapper/table-divider';
import { TableLabelTags } from '../../../../ui/components/table-wrapper/table/TableLabelTags';
import TableTitleOverflowText from '../../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { TableViewMode } from '../../../../ui/components/table-wrapper/table/type';
import { countAppliedFiltersCount } from './tools';
import { useCreateEnrollmentBulkActionService } from './services/EnrollmentBulkActionsService';
import { EnrollmentType } from '../../../../api/enrollments/types';
import useHtmlPageTitle from '../../../../helpers/hooks/useHtmlPageTitle';

const ITEMS_PER_PAGE = 20;

const TraineeQuickAssignment: FC<{
    profile?: UserProfile;
    history: History<any>;
    searchTrainees(searchParams: SearchParams, isRefreshed?: boolean): void;
    params: { practisSetId?: number };
    trainees?: ListResult<UserV2 & {dueDate?: Date}>;
    selectedTrainees?: number[];
    updateSelectedTrainees(userId: number): void;
    updateAllSelectedTrainees(
        userIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    practisSet?: PractisSets;
    updateTrainees?: Array<any>;
    loading?: boolean;
    onEnrollPractisSetsToUsers: (params: EnrollmentType[]) => Promise<void>;
    onGetPractisSet: (practisSetId: number) => void;
    selectedLabels?: number[];
    actionsHelper: TraineeInvitationActionHelper;
}> = ({
    profile,
    trainees,
    selectedTrainees,
    updateSelectedTrainees,
    updateAllSelectedTrainees,
    params,
    searchTrainees,
    loading,
    updateTrainees,
    onEnrollPractisSetsToUsers,
    history,
    onGetPractisSet,
    practisSet,
    selectedLabels,
    actionsHelper,
}) => {
    const classes = useStyles();
    const localLabels = useLocalLabelsState();
    const labelsList = usePortableLabelsState();
    const saveLabels = useSaveLabels();
    const setLabelSelect = useSelectLabels();
    const handleSelectLabels = useHandleSelectLabels();
    const labelsTree = listToTree(labelsList.data.items);
    const [isDueDateCalendarVisible, setIsDueDateCalendarVisible] =
        useState<boolean>(false);

    const [dueDateData, setDueDateData] = useState<any>();
    const [dueDates, setDueDates] = useState<DueDateType>();

    useHtmlPageTitle('Assign Users and Due Dates', [practisSet?.name]);

    const localSelectedLabels = useMemo(
        () =>
            localLabels.saved.length > 0
                ? localLabels.saved
                : selectedLabels || [],
        [localLabels.saved, selectedLabels]
    );

    const isCheckboxPartiallySelected =
        trainees &&
        trainees.items &&
        selectedTrainees &&
        selectedTrainees.length < trainees.items.length;

    const isListEmpty = !trainees || !trainees?.items?.length;

    useEffect(() => {
        if (localLabels.saved.length < 1 && selectedLabels) {
            setLabelSelect(selectedLabels);
        }
    }, [selectedLabels, localLabels.saved, setLabelSelect]);

    const onLocalRemoveLabels = (label: Label) => {
        if (localSelectedLabels.length > 0) {
            setLabelSelect(
                handleSelectLabels(label, localSelectedLabels, labelsTree)
            );
            saveLabels();
        }
    };

    const scrollRef = useRef<any>(null);

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        orderBy: { field: 'name', asc: true },
        practisSetId: params.practisSetId,
        filters: [],
        labelIDs: localSelectedLabels,
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
        companyId: profile?.companyId
    };

    const {
        searchParams,
        setSearchTerm,
        setOffset,
        setLabelIDs,
        refreshSearchParams,
        setOrderBy,
        setCustom,
    } = useSearchParamsState(initialSearchParams);

    const [lastRefreshed, setLastRefreshed] = useState(new Date());

    const refreshData = useCallback(() => {
        searchTrainees(
            {
                ...searchParams,
                offset: 0,
                limit: ITEMS_PER_PAGE + searchParams.offset, // we should increase the limit to fetch all loaded data again
            },
            true
        );
        setLastRefreshed(new Date());
        updateAllSelectedTrainees([], true, isCheckboxPartiallySelected);
    }, [
        searchParams,
        searchTrainees,
        updateAllSelectedTrainees,
        isCheckboxPartiallySelected,
    ]);

    const ifSearchParamsChanged = useIfChanged(searchParams);

    useEffect(() => {
        params.practisSetId && onGetPractisSet(params.practisSetId);
    }, [onGetPractisSet, params.practisSetId]);

    useEffect(() => {
        if (ifSearchParamsChanged) {
            searchParams.limit = ITEMS_PER_PAGE;
            searchTrainees(searchParams);
        }
    }, [searchTrainees, searchParams, ifSearchParamsChanged]);

    useEffect(() => {
        localSelectedLabels && setLabelIDs(localSelectedLabels);
    }, [localSelectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updateTrainees && updateTrainees.length > 0) {
            refreshSearchParams();
        }
    }, [updateTrainees, refreshSearchParams]);

    useEffect(() => {
        return () =>
            updateAllSelectedTrainees([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedTrainees([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainees?.count]);

    /**
     * @function onChangeOrderBy
     * @param { string } field
     */
    const onChangeOrderBy = (field: string): void => {
        setOrderBy(field);
        onClearSelection();
    };

    const orderBy = searchParams.orderBy;
    const atFirstName = useOrderBy('name', orderBy, onChangeOrderBy);
    const atRegisteredOn = useOrderBy('date_registered', orderBy, onChangeOrderBy);

    const [disabledAssign, setDisabledAssign] = useState(true);
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [isSelectAll, setIsSelectAll] = useState(false);

    useEffect(() => {
        if (selectedTrainees && selectedTrainees.length > 0) {
            setDisabledAssign(false);
        } else {
            setDisabledAssign(true);
        }
    }, [selectedTrainees]);

    const updateAllTraineesCheck = useCallback(
        (checked: boolean) => {
            const userIds =
                (trainees &&
                    trainees.items.map(({id}: UserV2) => id)) ||
                [];
            updateAllSelectedTrainees(
                userIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [trainees, isCheckboxPartiallySelected, updateAllSelectedTrainees]
    );

    const handleTraineeCheck = (e: any, user: UserInterface) => {
        e.stopPropagation();
        if (!!user.id) {
            updateSelectedTrainees(user.id);
        }
    };

    const enrollmentParams = {
        practisSetId: params.practisSetId as number,
        enrollments:
            selectedTrainees?.map(selectedTrainee => ({
                userId: selectedTrainee,
                practisSetId: params.practisSetId as number,
                dueDate: findSelectedTraineeDueDate(trainees, selectedTrainee) ??
                    dueDates?.[selectedTrainee]
                        ? dueDates?.[selectedTrainee] as string
                        : null,
            })) ?? [],
        search: searchParams,
        dueDate: isSelectAll ? dueDateData?.selectAllDueDate : null
    }

    const handleCreateEnrollmentsBulkActionSuccess = () => {
        refreshData()
        updateAllSelectedTrainees([], true, false);
        history.goBack();
    }

    const handleCreateEnrollmentsBulkActionService =
        useCreateEnrollmentBulkActionService(
            enrollmentParams,
            trainees?.count,
            handleCreateEnrollmentsBulkActionSuccess
        );

    const handleTraineeInvitation = () => {
        setLoadingAssign(true);
        if (isSelectAll) {
            handleCreateEnrollmentsBulkActionService();
        } else {
            onEnrollPractisSetsToUsers(enrollmentParams.enrollments)
                .then(() => handleCreateEnrollmentsBulkActionSuccess())
        }
        setLoadingAssign(false);
    };

    useEffect(() => {
        const filteredPractisSetsEnrollments = trainees
            ? trainees.items.map(trainee => trainee.id)
            : [];
        const traineesToClear = clearSelectedItemIfNotLoaded(
            selectedTrainees,
            filteredPractisSetsEnrollments
        );
        if (traineesToClear && traineesToClear.length > 0) {
            updateAllSelectedTrainees(traineesToClear, false);
        }
    }, [selectedTrainees, trainees, updateAllSelectedTrainees]);

    useEffect(() => {
        if (
            selectedTrainees?.length &&
            selectedTrainees.length === trainees?.count
        ) {
            setIsSelectAll(true);
        }
    }, [trainees?.count, selectedTrainees]);

    useEffect(() => {
        if (isSelectAll) {
            const userIds =
                (trainees &&
                    trainees.items.map(({id}: UserV2) => id)) ||
                [];
            updateAllSelectedTrainees(
                userIds,
                true,
                isCheckboxPartiallySelected
            );
        }

        if (dueDateData?.selectAllDueDate) {
            const changedDueDates: DueDateType = {};
            (trainees?.items || []).forEach(({ id }) => {
                changedDueDates[id] = dueDateData?.selectAllDueDate;
            });
            setDueDates({ ...dueDates, ...changedDueDates });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainees]);

    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );
    const filterByRegistrationLength: any =
        searchParams?.filterByRegistrationStatus?.length;

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const clearLabelFilters = useClearLabelFilterActionService();
    const saveLabelFilters = useSaveSelectedInvitationLabelsService();
    useEffect(() => {
        return () => {
            clearLabelFilters();
            saveLabelFilters();
        };
    }, [clearLabelFilters, saveLabelFilters]);

    const labelChangeHandler = (ids: number[]) => {
        setLabelIDs(ids);
    };

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countAppliedFiltersCount(searchParams),
        itemsCount: trainees?.items.length || 0,
    });

    const registrationStatusHandler = (values: string[]) => {
        setCustom('filterByRegistrationStatus', values);
    };

    const bulkActions = useMemo(
        () =>
            actionsHelper.getBulkActions(
                trainees?.items.filter(x => selectedTrainees?.includes(x.id))
            ),
        [selectedTrainees, actionsHelper, trainees?.items]
    );

    const handleCancel = () => {
        history.goBack();
    };

    const getUserDueDate = (userId: number) => {
        const user = trainees?.items.find(item => item.id === userId);
        return user && (dueDates?.[user.id] || user?.dueDate)
            ? formatDateWithTimeZone(
                  dueDates?.[user?.id] || (user?.dueDate as Date)
              )
            : null;
    };

    const openDueDatePopup = async (userId?: number) => {
        let dueDate = null;
        let type = '';

        const dueDates = userId
            ? [getUserDueDate(userId)]
            : selectedTrainees?.map(id => {
                  return getUserDueDate(id);
              });

        const dueDateSet = uniqBy(dueDates, item => item);

        if (dueDateSet.length > 1) {
            type = 'MULTIPLE';
        } else {
            type = dueDateSet[0]
                ? 'SINGLE'
                : isSelectAll && dueDateData?.selectAllDueDate
                ? 'MULTIPLE'
                : 'NO_DUEDATE';
            [dueDate] = dueDateSet;
        }

        const data = {
            count: selectedTrainees?.length,
            type,
            dueDate,
        };

        setDueDateData({
            ...data,
            enrollmentIds: userId ? [userId] : selectedTrainees,
        });
        setIsDueDateCalendarVisible(true);
    };

    const updateDueDate = async (date: string | null) => {
        if (isSelectAll) {
            setDueDateData({ ...dueDateData, selectAllDueDate: date });
        }

        const { enrollmentIds } = dueDateData;

        const changedDueDates: DueDateType = {};
        enrollmentIds.forEach((id: number) => {
            changedDueDates[id] = date;
        });

        setDueDates({ ...dueDates, ...changedDueDates });
        setIsDueDateCalendarVisible(false);
        refreshData();
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllSelectedTrainees([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        const userIds =
            (trainees &&
                trainees.items.map(({id}: UserV2) => id)) ||
            [];
        updateAllSelectedTrainees(userIds, false, true);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllTraineesCheck(event.target.checked);
        setIsSelectAll(false);
    };

    const isMasterCheckBoxChecked =
        !!selectedTrainees && !!selectedTrainees.length;

    const isMasterCheckBoxDisabled = tableStates.disableSorting;

    /**
     * @function checkIsRowChecked
     * @param { UserInterface } user
     * @returns { boolean }
     */
    const checkIsRowChecked = (user: UserInterface): boolean =>
        !!selectedTrainees && !!user.id && selectedTrainees.includes(user.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { UserInterface } user
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, user: UserInterface) => {
        handleTraineeCheck(event, user);
        setIsSelectAll(false);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { UserInterface } user
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (user: UserInterface): boolean => {
        return labelsOpen === user?.['id'];
    };

    return (
        <>
            <StyledTraineeInvitationContainer>
                <InvitationHeader>
                    <div>
                        <InvitationHeaderWrapper>
                            <QuickAssignmentInvitationHeaderText data-test="practis-set-published-title">
                                Practis Set has been published
                            </QuickAssignmentInvitationHeaderText>
                            <IconWrapper>
                                <Published />
                            </IconWrapper>
                        </InvitationHeaderWrapper>
                        <InvitationHeaderSubText data-test="assign-users-title">
                            Assign Users and Due Dates to {practisSet?.name}
                        </InvitationHeaderSubText>
                    </div>
                </InvitationHeader>
                <TableDivider />
                {loading && !isSelectAll && <LoadingComponent />}
                <TableWrapper
                    tableStates={tableStates}
                    data={trainees?.items}
                    viewMode={TableViewMode.INFINITE_SCROLL}
                    headerFilterPosition="BOTTOM"
                    scrollRef={scrollRef}
                    scrollPaginationProps={{
                        itemsPerPage: ITEMS_PER_PAGE,
                        itemCount: trainees?.items?.length,
                        totalCount: trainees?.count,
                        onOffsetChange: setOffset,
                        scrollableRef: scrollRef,
                        hideLoading: false,
                        height: 300,
                        className: classes.scrollWrapperStyle,
                    }}
                    tableWrapperClassName={classes.tableWrapperStyle}
                    tableContainerClassName={
                        classes.tableBodyContainerStyleForAssignment
                    }
                    selectedLabels={localSelectedLabels}
                    onRemoveLabel={
                        localLabels.saved.length > 0
                            ? label => {
                                  onLocalRemoveLabels(label);
                              }
                            : undefined
                    }
                    tableRefreshConfigurations={{
                        lastRefreshed: lastRefreshed,
                        refreshData: refreshData,
                        dataTest: 'assign-users-timestamp',
                    }}
                    tableToolsOptions={{
                        searchInputOptions: {
                            initialValue: searchParams.searchTerm,
                            onSearchChange: setSearchTerm,
                            isSearchInputDisabled: tableStates.disableSearch,
                            dataTest: 'assign-users-search',
                        },
                        isSelectedItemOptionsVisible:
                            !lodashIsEmpty(selectedTrainees) &&
                            !lodashIsEmpty(trainees),
                        selectedItemOptions: {
                            isSelectAll: isSelectAll,
                            selectedLength: selectedTrainees?.length,
                            totalCount: trainees?.count,
                            itemsPerPage: ITEMS_PER_PAGE,
                            onClearSelection: onClearSelection,
                            onSelectAll: onSelectAll,
                            bulkActionsConfig: {
                                disabled: bulkActions.areBulkActionsDisabled(),
                                disabledLabel: bulkActions.areItemsEmpty()
                                    ? undefined
                                    : "Bulk actions can't be applied",
                                dueDatesPermissions:
                                    bulkActions.isBulkActionVisible(
                                        TraineeInvitationActions.SET_DUEDATE
                                    )
                                        ? [NEW_PERMISSIONS.SET_DUE_DATE]
                                        : [],
                                isDueDatesDisabled:
                                    bulkActions.isBulkActionDisabled(
                                        TraineeInvitationActions.SET_DUEDATE
                                    ),
                                isSelectAll: isSelectAll,
                                totalCount: trainees?.count,
                                onUpdateDueDatesSubmit: openDueDatePopup,
                            },
                        },
                        filterOptions: {
                            filterComponent: (
                                <Popup<HTMLButtonElement>
                                    content={({ hide }) => (
                                        <AllUsersFilter
                                            onLabelsChange={labelChangeHandler}
                                            onSuccessApply={() => hide()}
                                            registrationStatus={
                                                searchParams.filterByRegistrationStatus ||
                                                []
                                            }
                                            onRegistrationStatusChange={
                                                registrationStatusHandler
                                            }
                                        />
                                    )}
                                >
                                    {(ref, { toggleShown, shown }) => (
                                        <FilterByHandler
                                            ref={ref}
                                            open={shown}
                                            disabled={
                                                (searchParams.searchTerm
                                                    .length > 0 ||
                                                    countAppliedFiltersCount(
                                                        searchParams
                                                    ) === 0) &&
                                                isListEmpty
                                            }
                                            toggleOpen={toggleShown}
                                            filtersCount={
                                                localSelectedLabels &&
                                                localSelectedLabels.length +
                                                    filterByRegistrationLength
                                            }
                                            dataTest="assign-users-filters"
                                        />
                                    )}
                                </Popup>
                            ),
                        },
                        itemCount: trainees?.count
                            ? `${trainees?.count} ${
                                  trainees?.count === 1 ? 'item' : 'items'
                              }`
                            : '',
                        itemCountDataTest: 'assign-users-total-count',
                    }}
                    tableEmptyStateConfigurations={{
                        shouldShowEmptyState:
                            !loading && trainees?.items?.length === 0,
                        noEntriesOptions: {
                            icon: User,
                            text: 'No Users Yet',
                            dataTest: 'no-users-yet',
                        },
                        noSearchResultsOptions: {
                            entityName: 'Users',
                            dataTest: 'no-users-found'
                        },
                    }}
                    configurations={{
                        masterCheckBoxConfig: {
                            checked: isMasterCheckBoxChecked,
                            disabled: isMasterCheckBoxDisabled,
                            partial:
                                isCheckboxPartiallySelected || !isSelectAll,
                            handleCheck: onCheckMasterCheckbox,
                            size: 12,
                            dataTest: 'assign-users-master-checkbox',
                        },
                        columns: [
                            {
                                title: 'Users',
                                width: 47,
                                ...atFirstName,
                                disabled: tableStates.disableSorting,
                                dataTest: 'users-column',
                            },
                            {
                                title: 'Due Date',
                                width: 18,
                                disabled: tableStates.disableSorting,
                                dataTest: 'due-date-column',
                            },
                            {
                                title: 'Registered on',
                                width: 21,
                                ...atRegisteredOn,
                                disabled: tableStates.disableSorting,
                                dataTest: 'registration-date-column',
                            },
                        ],

                        rowConfig: {
                            onRowCheckHandler: onRowCheckHandler,
                            isRowChecked: checkIsRowChecked,
                            size: 12,
                            isLabelTagsOpen: checkIsLabelTagsOpen,

                            getLabelTagsProps: (user: UserV2) => ({
                                selectedLabels: user?.labels || [],
                                deletePermissions: [
                                    NEW_PERMISSIONS.REMOVE_INVITATION_LABEL,
                                ],
                            }),
                            dataTest: 'user-item',

                            cells: [
                                {
                                    fieldType: 'CUSTOM_FIELD',
                                    width: 58,
                                    cellClassName: classes.customCellStyle,
                                    getCustomFieldComponent: (user: UserV2) => (
                                        <StyledFirstTableItemContent>
                                            <TableLabelTags
                                                open={labelsOpen === user.id}
                                                toggleOpen={() =>
                                                    handleToggleLabels(user.id)
                                                }
                                                selectedLabels={
                                                    user?.labels || []
                                                }
                                                dataTest="user-item-labels"
                                            />
                                            <AvatarTile>
                                                <Avatar
                                                    profile={user}
                                                    size="sm"
                                                    marginRight={8}
                                                    dataTest="user-item-avatar"
                                                />
                                                <TableTitleOverflowText
                                                    isCurrent={
                                                        profile &&
                                                        profile.id === user.id
                                                    }
                                                    dataTest="user-item-name"
                                                >{`${user.firstName} ${user.lastName}`}</TableTitleOverflowText>
                                            </AvatarTile>
                                        </StyledFirstTableItemContent>
                                    ),
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    cellClassName: classes.customCellStyle,
                                    width: 24,
                                    shouldShowEmptyCell: (
                                        user: UserInterface
                                    ) => !dueDates?.[user.id] && !user.dueDate,
                                    fieldProps: {
                                        renderTitle: (user: UserInterface) =>
                                            (dueDates?.[user.id] ||
                                                user.dueDate) &&
                                            formatDateWithTimeZone(
                                                dueDates?.[user.id] ||
                                                    (user.dueDate as Date)
                                            ),
                                        dataTest: 'user-item-due-date',
                                    },
                                },
                                {
                                    fieldType: 'TEXT_FIELD',
                                    cellClassName: classes.customCellStyle,
                                    width: 30,
                                    shouldShowEmptyCell: (
                                        user: UserInterface
                                    ) => !user.registeredAt,
                                    fieldProps: {
                                        renderTitle: (user: UserInterface) =>
                                            formatDate(user?.registeredAt),
                                        dataTest: 'user-item-registration-date',
                                    },
                                },
                                {
                                    fieldType: 'LIST_ACTIONS',
                                    fieldProps: {
                                        getListActionsComponent: (
                                            user: UserInterface
                                        ) => (
                                            <StyledTableCell>
                                                {!selectedTrainees?.length &&
                                                    <DueDateListAction
                                                        actions={[
                                                            {
                                                                title: 'Due Date',
                                                                onClick: () =>
                                                                    openDueDatePopup(
                                                                        user.id
                                                                    ),
                                                                permissions: [
                                                                    NEW_PERMISSIONS.SET_DUE_DATE,
                                                                ],
                                                                dataTest: 'due-date-action'
                                                            },
                                                        ]}
                                                    />
                                                }
                                            </StyledTableCell>
                                        ),
                                    },
                                },
                            ],
                        },
                    }}
                />
                <QuickAssignmentInvitationFooter>
                    <CancelWrapper>
                        <Button
                            label={'Cancel'}
                            variant={'inverse'}
                            width={'122px'}
                            action={handleCancel}
                            dataTest='cancel-button'
                        />
                    </CancelWrapper>
                    <Button
                        label={'Assign Selected Users'}
                        height={'40px'}
                        width={'169px'}
                        disabled={disabledAssign}
                        loading={loadingAssign}
                        action={handleTraineeInvitation}
                        dataTest="assign-users-button"
                    />
                </QuickAssignmentInvitationFooter>
            </StyledTraineeInvitationContainer>
            {isDueDateCalendarVisible && (
                <DueDateCalendar
                    data={{ ...dueDateData, isSubTitleVisible: false }}
                    onCancel={() => setIsDueDateCalendarVisible(false)}
                    onApply={updateDueDate}
                />
            )}
        </>
    );
};

export const TraineeQuickAssignmentContainer: FC<ModalPageContainerProps> = ({
    closePath,
    closeGoingBack,
}) => {
    const [state] = useTrainerInvitationState();
    const profile = useSelector(getProfileState);
    const params = useParams();
    const history = useHistory();
    const enrollment = useSelector(PractisSetsEnrollmentState);
    const searchAssignUsers = useSearchAssignUsersService();
    const searchAssignUsersDebounced = useSearchDebounced(searchAssignUsers);
    const enrollPractisSetsToUsers = useEnrollPractisSetsToUsersService();
    const getPractisSet = useGetPractisSetService();
    const labels = useLabelsState();
    const updateSelectedTrainees = useUpdateSelectedInvitationTraineesStateService();
    const updateAllSelectedInvitationTrainees = useUpdateAllSelectedInvitationTraineesStateService();
    const actionsHelper = useTraineeInvitationActionHelpers();

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'assignUsers',
            }}
        >
            <ModalPage
                closePath={closePath}
                closeGoingBack={closeGoingBack}
                width="50%"
            >
                <TraineeQuickAssignment
                    profile={profile}
                    history={history}
                    params={params}
                    searchTrainees={searchAssignUsersDebounced}
                    trainees={state.data}
                    selectedTrainees={state.selectedUsers}
                    updateSelectedTrainees={updateSelectedTrainees}
                    updateAllSelectedTrainees={
                        updateAllSelectedInvitationTrainees
                    }
                    practisSet={enrollment.practisSet}
                    updateTrainees={state.update}
                    loading={state.loading}
                    onGetPractisSet={getPractisSet}
                    onEnrollPractisSetsToUsers={enrollPractisSetsToUsers}
                    selectedLabels={labels.selected}
                    actionsHelper={actionsHelper}
                />
            </ModalPage>
        </WithLabelsContext.Provider>
    );
};

export default TraineeQuickAssignmentContainer;
