const R = require('ramda');

export const appendItem = (array: any, action: any) => R.append(action, array);
export const removeItem = (array: Array<any>, action: any, field?: string) =>
    array.filter((item: any) =>
        field ? item[field] === action[field] : item.id !== action.id
    );

export const findItem = (array: any, field: string, value: any) => {
    if (!array) return null;
    return R.find(R.propEq(field, value))(array);
};

export const modifyArrayItem = (
    array: Array<any>,
    index: number | string,
    field: string,
    value: any
) => {
    const itemIdx = (id: number | string) =>
        R.findIndex(R.propEq('id', id), array);
    const fieldLens = (idx: string) => R.lensPath([idx, field]);
    const wField = fieldLens(itemIdx(index));
    return R.over(wField, (item: any) => value, array);
};
