import { useCallback } from 'react';
import { isEmpty } from 'lodash';

import {
    useRemindToStartApi,
    useSearchPractisSetDetailsUsersApi,
} from '../../../../api';
import { SearchParams } from '../../../../constants/interfaces/filters';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { createSearchPractisSetDetailsParams } from '../../tools';
import { RemindToStartEnrollment } from '../../../../api/alert/types';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import { UserStatus } from '../../../users/store/costants';
import { ENROLLMENT_REMIND_TO_START } from '../../../../api/alert/constants';

/**
 * @function useHandleSearchEnrollmentsAndRemind
 * @returns { CallableFunction }
 */
export const useHandleSearchEnrollmentsAndRemind = () => {
    const searchPractisSetDetailsUsers = useSearchPractisSetDetailsUsersApi();
    const remindToStart = useRemindToStartApi();
    const showMessage = useShowMessage();

    return useCallback(
        async (
            teamId: string,
            practisSetId: string,
            searchParams: SearchParams,
            onSuccessCallback: (numberOdDoneItems: number) => void
        ) => {
            const searchPractisSetDetailsParams =
                createSearchPractisSetDetailsParams({
                    ...searchParams,
                    filterByStatus: searchParams.filterByStatus?.length
                        ? searchParams.filterByStatus
                        : ['PENDING', 'IN_PROGRESS'],
                });

            await searchPractisSetDetailsUsers(teamId, practisSetId, {
                ...searchPractisSetDetailsParams,
            })
                .then(async response => {
                    if (!isEmpty(response.items)) {
                        const enrollments = response.items
                            // Pending users can't be reminded.
                            .filter(
                                item => item.user.status === UserStatus.ACTIVE
                            )
                            .map(
                                item =>
                                    ({
                                        enrollmentId: item.id,
                                        type: ENROLLMENT_REMIND_TO_START,
                                    } as RemindToStartEnrollment)
                            );

                        await remindToStart(enrollments)
                            .then(() => {
                                onSuccessCallback(enrollments.length);
                            })
                            .catch((error: ErrorResult) => {
                                error?.message &&
                                    showMessage(error.message, 'error');
                                throw error;
                            });
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [remindToStart, searchPractisSetDetailsUsers, showMessage]
    );
};