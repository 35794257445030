import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const DailyTimeSpentIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={ref}
                className={className}
            >
                <g clip-path="url(#a)" fill="#B1C0CB">
                    <rect x="2.5" y="23.938" width="2" height="4.063" rx="1" />
                    <rect x="8.5" y="9" width="2" height="19" rx="1" />
                    <rect x="14.5" y="18" width="2" height="10" rx="1" />
                    <rect x="20.5" y="13" width="2" height="15" rx="1" />
                    <rect x="26.5" y="2" width="2" height="26" rx="1" />
                    <path d="M.5 30h32v2H.5z" />
                </g>
                <defs>
                    <clipPath id="a">
                        <path
                            fill="#fff"
                            transform="translate(.5)"
                            d="M0 0h32v32H0z"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default DailyTimeSpentIcon;
