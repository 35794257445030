import { useCallback } from 'react';

import { useHttp } from '../../../../../tools/http/Http';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { Label } from '../../../../../constants/interfaces/Label';

export const useSearchLabelsApi = () => {
    const http = useHttp();
    return useCallback(
        (sp?: SearchParams): Promise<PaginationResult<Label>> =>
            http.post('labels/search', sp),
        [http]
    );
};
