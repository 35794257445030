import { createContext } from 'react';

import { AppState } from '../../../../../../store/reducers';
import { DEFAULT_LABELS_SCOPE, Scope } from '../scopes';

export type WithLabelsContextProps<T = keyof AppState, K = Scope> = {
    reducerName?: T;
    scope?: K;
};

export const WithLabelsContext = createContext<WithLabelsContextProps>({
    reducerName: 'portableLabels',
    scope: DEFAULT_LABELS_SCOPE,
});
