import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
    onListenEvent,
    onRemoveEvent,
} from '../../../helpers/functions/Events';
import {
    EventNameList,
    REFRESH_TEAM_CONTROLLER,
} from '../../../helpers/functions/Events/types';

import { usePreviousData } from '../../../helpers/hooks/usePreviousData';
import {
    useGetTeamInfoService,
    useResetTeamInfoService,
} from '../store/services';
import TeamMembers from './TeamMembers';
import Progress from './Training/Progress';

function TeamsPageController() {
    const location = useLocation();

    const { teamId = '' as string } = useParams() as Record<string, unknown>;
    const prevTeamId = usePreviousData(teamId);
    const searchTeam = useGetTeamInfoService();
    const resetTeamInfo = useResetTeamInfoService();

    /**
     * @dev -- it should be callback function while it's passed to event listener
     * @function fetchTeamData
     * @return { void }
     */
    const fetchTeamData = useCallback(() => {
        searchTeam(parseInt(teamId as string));
    }, [searchTeam, teamId]);

    useEffect(() => {
        if (teamId && prevTeamId !== teamId) {
            fetchTeamData();
        }
    }, [searchTeam, teamId, prevTeamId, fetchTeamData]);

    // Listen to ManageTeam Screen Refresh dispatch
    useEffect(() => {
        onListenEvent(EventNameList[REFRESH_TEAM_CONTROLLER], fetchTeamData);

        return () => {
            onRemoveEvent(
                EventNameList[REFRESH_TEAM_CONTROLLER],
                fetchTeamData
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            resetTeamInfo();
        };
    }, [resetTeamInfo]);

    if (location?.pathname?.includes('members')) {
        return <TeamMembers />;
    }

    return <Progress />;
}

export default TeamsPageController;
