import AvatarEditor from 'react-avatar-editor';

import { Button } from '../../Button';
import {
    ButtonContainer,
    CustomSlider,
    Divider,
    Footer,
    Header,
    SliderContainer,
    SliderLabelContainer,
    StyledDialog,
    StyledDialogBlacker,
} from './styles';
import { ImageEditorViewProps } from './types';

function ImageEditorView(props: ImageEditorViewProps) {
    const {
        selectedImage,
        handleClose,
        editorRef,
        handleApplyEditedImage,
        scale,
        onScaleChange,
        rectStyled
    } = props;

    return (
        <StyledDialogBlacker onClick={handleClose}>
            <StyledDialog
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                }}
            >
                <Header data-test="edit-photo-title">Edit Photo</Header>
                    <AvatarEditor
                        ref={editorRef}
                        image={selectedImage}
                        width={255}
                        height={255}
                        border={rectStyled ? [50, 50] : [50,20]}
                        borderRadius={rectStyled ? 5 : 500}
                        color={[0, 0, 0, 0.38]}
                        scale={scale}
                    />
                <Footer>
                    <SliderContainer>
                        <SliderLabelContainer>
                            <span data-test="edit-photo-zoom-label">Zoom</span>
                            <span data-test="edit-photo-zoom-value">{scale}</span>
                        </SliderLabelContainer>
                        <span data-test="edit-photo-zoom-slider">
                            <CustomSlider
                                aria-label="small"
                                onChange={onScaleChange}
                                value={scale}
                                step={0.1}
                                marks={false}
                                min={1}
                                max={2}
                            />
                        </span>
                    </SliderContainer>
                    <Divider />
                    <ButtonContainer>
                        <Button
                            action={handleClose}
                            variant="inverse"
                            buttonWidth="47.5%"
                            dataTest="edit-photo-cancel-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            action={handleApplyEditedImage}
                            buttonWidth="47.5%"
                            dataTest="edit-photo-save-button"
                        >
                            Save
                        </Button>
                    </ButtonContainer>
                </Footer>
            </StyledDialog>
        </StyledDialogBlacker>
    );
}

export default ImageEditorView;
