import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { createSelectorContext } from '../../../tools/redux';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LogItem } from '../models/LogItem';

export interface LogsState {
    data?: PaginationResult<LogItem>;
    loading?: boolean;
    error?: string;
}

export const LogsStateSelectorContext = createSelectorContext<LogsState>();

export function useLogsState() {
    return useSelector(useContext(LogsStateSelectorContext));
}
