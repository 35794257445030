import { useCallback } from 'react';

import { useHttp } from './../../tools/http/Http';
import {
    GetSubmissionDetailsApiParams,
    SubmissionActionApiParams,
    RequestSubmissionRedoApiParams,
    SearchSubmissionsApiParams,
    ArchiveSubmissionsApiParams,
    RestoreSubmissionsApiParams,
    GetSubmissionReviewApiParams,
    PerformSubmissionReviewApiParams,
    AddSubmissionFlagApiParams,
    DeleteSubmissionFlagApiParams,
    GetSubmissionIdByOldIdParams,
    GetSubmissionRepLinesParams,
} from './types';

/**
 * @function useSearchSubmissionsApi
 * @returns { SearchSubmissionsApiParams }
 */
 export function useSearchSubmissionsApi(): SearchSubmissionsApiParams {
    const http = useHttp();
    return useCallback(
        searchParams => http.get('submissions', searchParams, undefined, true),
        [http]
    );
}

/**
 * @function useGetSubmissionDetailsApi
 * @returns { GetSubmissionDetailsApiParams }
 */
export function useGetSubmissionDetailsApi(): GetSubmissionDetailsApiParams {
    const http = useHttp();
    return useCallback(
        submissionId => http.get(`submissions/${submissionId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useRequestSubmissionRedoApi
 * @returns { RequestSubmissionRedoApiParams }
 */
export function useRequestSubmissionRedoApi(): RequestSubmissionRedoApiParams {
    const http = useHttp();
    return useCallback(
        (submissionId, { senderName, text }) =>
            http.put(`submissions/${submissionId}/redo`, {
                senderName,
                text,
            }, true),
        [http]
    );
}

/**
 * @function usePerformSubmissionActionApi
 * @returns { SubmissionActionApiParams }
 */
export function usePerformSubmissionActionApi(): SubmissionActionApiParams {
    const http = useHttp();
    return useCallback(
        (submissionId: number, action: string) =>
            http.post(`submissions/${submissionId}/actions`, { action }, true),
        [http]
    );
}

/**
 * @function useArchiveSubmissionsApi
 * @returns { ArchiveSubmissionsApiParams }
 */
 export function useArchiveSubmissionsApi(): ArchiveSubmissionsApiParams {
    const http = useHttp();
    return useCallback(
        (submissionsIds) =>
            http.put('submissions/archive', submissionsIds, true),
        [http]
    );
}

/**
 * @function useRestoreSubmissionsApi
 * @returns { RestoreSubmissionsApiParams }
 */
 export function useRestoreSubmissionsApi(): RestoreSubmissionsApiParams {
    const http = useHttp();
    return useCallback(
        (submissionsIds) =>
            http.put('submissions/active', submissionsIds, true),
        [http]
    );
}

/**
 * @function useGetSubmissionReviewApi
 * @returns { GetSubmissionReviewApiParams }
 */
export function useGetSubmissionReviewApi(): GetSubmissionReviewApiParams {
    const http = useHttp();
    return useCallback(
        (submissionId) =>
            http.get(`submissions/${submissionId}/reviews`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function usePerformSubmissionReviewApi
 * @returns { PerformSubmissionReviewApiParams }
 */
 export function usePerformSubmissionReviewApi(): PerformSubmissionReviewApiParams {
    const http = useHttp();
    return useCallback(
        (submissionId, params) => http.post(`submissions/${submissionId}/reviews`, params, true),
        [http]
    );
}

/**
 * @function useAddSubmissionFlag
 * @returns { AddSubmissionFlagApiParams }
 */
export function useAddSubmissionFlag(): AddSubmissionFlagApiParams {
    const http = useHttp();
    return useCallback(
        (submissionId, type) =>
            http.post(`submissions/${submissionId}/flags`, { type }, true),
        [http]
    );
}

/**
 * @function useDeleteSubmissionFlag
 * @returns { AddSubmissionFlagApiParams }
 */
export function useDeleteSubmissionFlag(): DeleteSubmissionFlagApiParams {
    const http = useHttp();
    return useCallback(
        (submissionId, flagId) =>
            http.delete(`submissions/${submissionId}/flags/${flagId}`, undefined, true),
        [http]
    );
}

/**
 * @function useGetSubmissionIdByOldId
 * @returns { GetSubmissionIdByOldIdParams }
 */
export function useGetSubmissionIdByOldId(): GetSubmissionIdByOldIdParams {
    const http = useHttp();
    return useCallback(
        (oldSubmissionId, type) =>
            http.get(`submissions/old/${oldSubmissionId}`, { type }, undefined, true),
        [http]
    );
}

/**
 * @function useGetSubmissionRepLines
 * @returns { GetSubmissionRepLinesParams }
 */
export function useGetSubmissionRepLines(): GetSubmissionRepLinesParams {
    const http = useHttp();
    return useCallback(
        (params) =>
            http.get(`/submissions/rep-log`, params, undefined, true),
        [http]
    );
}