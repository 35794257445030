import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const Wrapper = styled.div<{ top?: number; fontSize?: number }>`
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: ${({ top }) => (top ? `${top}px` : '-26px')};
    text-align: right;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '13px')};
    font-weight: normal;
    color: ${props => props.theme.Colors.steelGrey};
    display: flex;
    align-items: center;
    line-height: 18px;
    height: 18px;
`;

export const ButtonIcon = styled.div<{ disabled?: boolean }>`
    width: 16px;
    height: 16px;
`;

export const LabelButton = styled.button`
    background: none;
    display: flex;
    outline: none;
    border: none;
    font-size: 13px;
    font-family: ${props => props.theme.Fonts.manrope};
    font-weight: normal;
    color: ${({ theme, disabled }) =>
        disabled ? theme.Colors.cloudyBlue : theme.Colors.darkSkyBlue};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.lightBlue};
    }
    &:active {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.windowBlue};
    }

    &:hover ${ButtonIcon} {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.lightBlue};
    }
    &:active ${ButtonIcon} {
        color: ${({ theme, disabled }) =>
            disabled ? theme.Colors.cloudyBlue : theme.Colors.windowBlue};
    }

    padding: 0;
`;

export const Divider = styled.span`
    width: 1px;
    height: 18px;
    background-color: ${({ theme }) => theme.Colors.paleGrey};
    margin-left: 4px;
    margin-right: 4px;
`;

export const ButtonText = styled.span`
    margin-left: 4px;
    line-height: 18px;
`;

export const StyledSpan = styled.span`
    padding-left: 4px;
    padding-right: 4px;
`;
