import React, { FC } from 'react';
import styled from 'styled-components';

const Actions = styled.div`
    display: flex;
    padding: 8px 24px 16px 24px;
`;

export const CommentTools: FC<{
    className?: string;
}> = ({ children, className }) => (
    <Actions className={className}>{children}</Actions>
);
