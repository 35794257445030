import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 215px;
`;

export const ModalSidebar = styled.div`
    width: 190px;
    background-color: var(--ps-grey-3);
    border-right: 1px solid var(--ps-grey-4);
    border-bottom-left-radius: 8px;
`;

export const NavigationItem = styled.div`
    margin-top: 18px;
    padding-left: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--ps-black-main);
    display: flex;
    text-decoration: none;
    position: relative;
    font-weight: bold;

    &:first-child {
        margin-top: 24px;
    }

    &::before {
        width: 4px;
        height: 24px;
        background: var(--ps-black-main);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 10px 10px 0;
    }
`;

export const NavigationIcon = styled.div`
    width: 16px;
    height: 16px;
    color: var(--ps-black-main);
    margin-right: 12px;
`;

export const TabContent = styled.div`
    width: 600px;
    min-height: 615px;
`;