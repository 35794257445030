import React, { Component } from 'react';
import styled from 'styled-components';

import { Variables } from '../../theme/variables';
import LandingWrapper from '../../ui/wrapper/LandingWrapper/LandingWrapper';

const SupportContainer = styled.div`
    margin: 50px 10%;
    width: 100%;
`;

const ContentArea = styled.div`
    text-align: center;
    width: 100%;
    font-size: 22px;
    padding: 100px 0;
`;

const SupportMail = styled.a`
    display: block;
    text-decoration: none;
    color: ${Variables.Colors.dodgerBlue};
    font-weight: bold;
    padding: 25px 0;
`;

class SupportPage extends Component {
    render() {
        return (
            <LandingWrapper>
                <SupportContainer>
                    <ContentArea>
                        To get support, please, send us an email to
                        <SupportMail href="mailto:support@gopractis.com">
                            support@gopractis.com
                        </SupportMail>
                    </ContentArea>
                </SupportContainer>
            </LandingWrapper>
        );
    }
}

export default SupportPage;
