import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Checkbox from '../../../ui/components/Checkbox';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { handleToggleSelectedItemsForPractisSets } from '../../../pages/ActionPages/NewUser/tools';
import Pencil from '../../../ui/icons/Pencil';
import { formatDateWithTimeZone } from '../../../helpers/functions/date-convert';
import dayjs from 'dayjs';
import { PractisSetWithDueDate } from '../../../constants/interfaces/Draft';
import { practisSetWithDueDatesArrayToDueDatesKeyValues } from '../tools';
import { DueDateType } from '../../../constants/interfaces/DueDates';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';
import { EmptyCellDash } from '../../../ui/components/table-wrapper/table/EmptyCellDash';
import { DueDateCalendar } from '../../../ui/components/DueDateCalendar';

const StyledPractisSetsList = styled.div`
    overflow: auto;
    height: 100%;
`;

const TopSideList = styled.span``;

const GeneralList = styled.span``;

const PractisSetsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    flex-direction: row;
`;

const PractisSetsItem = styled.div<{ disabled?: boolean }>`
    color: ${props => props.theme.Colors.black};
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 12px;
    align-items: center;
    background: ${props => props.theme.Colors.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${props => props.disabled && 'pointer-events: none'}
    &:active {
        opacity: 0.7;
    }
    width: 100%;
`;

const Date = styled.div`
    min-width: 56px;
`;

const PencilWrapper = styled.div`
    position: flex;
    transform: translateX(-20px);
    padding-left: 6px;
    padding-right: 6px;
    display: none;
    align-items: center;
    justify-content: center;

    height: 26px;
    width: 24px;

    background: ${({ theme }) => theme.Colors.whiteFive};
    margin-top: -4px;
    right: 8px;
    cursor: pointer;
`;

const DueDateContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 68px;
    max-width: 68px;
    font-size: 13px;
    font-family: 'Manrope';
    color: ${({ theme }) => theme.Colors.black};
    padding: 4px 8px;
    height: 26px;
    margin-right: 4px;
    background: transparent;

    &:hover {
        background: ${({theme}) => theme.Colors.whiteFive};

        ${PencilWrapper} {
            display: flex;
        }
    }

    cursor: default;
    border-radius: 2px;
`

const StyledEmpty = styled.span`
    color: ${({ theme }) => theme.Colors.cloudyBlue};
`

export const PortablePractisSetsList: FC<{
    practisSets?: PractisSets[];
    selectedPractisSets: PractisSetWithDueDate[];
    disabled?: boolean;
    setPractisSetFilters?: (practisSetId: PractisSetWithDueDate[]) => void;
    dueDateAssignments?: DueDateType;
    onUpdateDueDates?: (dueDates?: DueDateType) => void;
    hideDueDates?: boolean;
}> = ({ practisSets, selectedPractisSets, setPractisSetFilters, hideDueDates }) => {
    
    const [
        isDueDateCalendarVisible,
        setIsDueDateCalendarVisible,
    ] = useState<boolean>(false);
    const [dueDateData, setDueDateData] = useState<any>();
    const [dueDates, setDueDates] = useState<DueDateType>();

    useEffect(() => {
        setDueDates(practisSetWithDueDatesArrayToDueDatesKeyValues(selectedPractisSets));
    }, [selectedPractisSets])

    /**
    @function getUserDueDate
    @param { number } practisSetId
    @returns { string | null }
    */
    const getUserDueDate = (practisSetId: number) => {
        const practisSet = practisSets?.find((item) => item.id === practisSetId);

        return practisSet?.id &&
            (dueDates?.[practisSet.id])
            ? formatDateWithTimeZone(
                  dueDates?.[practisSet.id] as string
              )
            : null;
    }
    
    /**
    @function openDueDatePopup
    @param { number } practisSetId
    @returns { void }
    */
    const openDueDatePopup = async(practisSetId: number) => {
        const dueDate = getUserDueDate(practisSetId);
        
        let type = dueDate ? 'SINGLE' : 'NO_DUEDATE';

        const data = {
            count: 1,
            type,
            dueDate
        }

        setDueDateData({...data, enrollmentIds: practisSetId});
        setIsDueDateCalendarVisible(true);
    };

    /**
     * @function updateDueDate
     * 
     * @param { string | null } date 
     * 
     * @returns { void }
     */
    const updateDueDate = async (date: string | null) : Promise<void> => {
        const { enrollmentIds } = dueDateData;
        
        if(date) {
            const changedDueDates: DueDateType = {};

            changedDueDates[enrollmentIds] = date
            setDueDates(prevState => ({...prevState, ...changedDueDates}));
        } else {
            setDueDates((prevState) => {
                if (prevState) {
                    prevState[enrollmentIds] = null
                }

                return prevState
            })
        }

        if (isChecked(enrollmentIds)) {
            setPractisSetFilters?.(selectedPractisSets.map(selectedPractisSet => {
                if (selectedPractisSet.practisSetId === enrollmentIds) {
                    return {
                        ...selectedPractisSet,
                        dueDate: date
                    }
                } else {
                    return selectedPractisSet;
                }
            }));
        } else {
            setPractisSetFilters?.([ ...selectedPractisSets, {
                practisSetId: enrollmentIds,
                dueDate: date
            }])
        }

        setIsDueDateCalendarVisible(false);
    };

    const isChecked = useCallback(
        (setId?: number) => {
            return (
                !!selectedPractisSets &&
                !!setId &&
                !!selectedPractisSets.filter(x => x.practisSetId === setId).length
            );
        },
        [selectedPractisSets]
    );

    const isPartiallyChecked = useCallback(
        (practisSetId?: number) => {
            return (
                !!selectedPractisSets &&
                !!practisSetId &&
                !!selectedPractisSets.filter(x => x.practisSetId === -Number(practisSetId)).length
            );
        },
        [selectedPractisSets]
    );

    const changeHandler = (practisSetId?: number) => {
        if (practisSetId && setPractisSetFilters) {
            setPractisSetFilters(
                handleToggleSelectedItemsForPractisSets(selectedPractisSets, practisSetId)
            );
        }
    };

    if (!practisSets || (practisSets && practisSets.length === 0)) return null;

    const topListPractisSets = practisSets.filter(
        practisSet =>
            practisSet.id && selectedPractisSets.map(ps => Math.abs(ps.practisSetId)).includes(practisSet.id)
    );

    const bottomListPractisSets = practisSets.filter(
        practisSet =>
            practisSet.id && !selectedPractisSets.map(ps => Math.abs(ps.practisSetId)).includes(practisSet.id)
    );

    return (
        <StyledPractisSetsList>
            {topListPractisSets.length > 0 && (
                <TopSideList>
                    {topListPractisSets.map(practisSet => {
                        return (
                            <PractisSetsItemWrapper
                                key={practisSet.id}
                                data-test="practisset-item-container"
                            >
                                <Checkbox
                                    size={12}
                                    checked={
                                        isChecked(practisSet.id) ||
                                        isPartiallyChecked(practisSet.id)
                                    }
                                    partial={isPartiallyChecked(practisSet.id)}
                                    handleChange={() =>
                                        changeHandler(practisSet.id)
                                    }
                                    dataTest="practisset-item-checkbox"
                                />
                                <PractisSetsItem
                                    onClick={() => changeHandler(practisSet.id)}
                                    data-test="practisset-item-title"
                                >
                                    {practisSet.name}
                                </PractisSetsItem>
                                {!hideDueDates && (
                                    <DueDateContainer data-test="due-date-container">
                                        <Date data-test="due-date-value">
                                            {practisSet.id &&
                                            dueDates?.[practisSet.id] ? (
                                                formatDateWithTimeZone(
                                                    dueDates[
                                                        practisSet.id
                                                    ] as string
                                                )
                                            ) : (
                                                <EmptyCellDash />
                                            )}
                                        </Date>
                                        <PencilWrapper
                                            onClick={() =>
                                                practisSet.id &&
                                                openDueDatePopup(practisSet.id)
                                            }
                                            data-test="due-date-edit-button"
                                        >
                                            <Pencil />
                                        </PencilWrapper>
                                    </DueDateContainer>
                                )}
                            </PractisSetsItemWrapper>
                        );
                    })}
                    {topListPractisSets.length < practisSets.length && (
                        <TableDivider width="98%" />
                    )}
                </TopSideList>
            )}
            <GeneralList>
                {bottomListPractisSets.map(practisSet => {
                    return (
                        <PractisSetsItemWrapper
                            key={practisSet.id}
                            data-test="practisset-item-container"
                        >
                            <Checkbox
                                size={12}
                                checked={
                                    isChecked(practisSet.id) ||
                                    isPartiallyChecked(practisSet.id)
                                }
                                partial={isPartiallyChecked(practisSet.id)}
                                handleChange={() =>
                                    changeHandler(practisSet.id)
                                }
                                dataTest="practisset-item-checkbox"
                            />
                            <PractisSetsItem
                                onClick={() => changeHandler(practisSet.id)}
                                data-test="practisset-item-title"
                            >
                                {practisSet.name}
                            </PractisSetsItem>
                            {!hideDueDates && (
                                <DueDateContainer data-test="due-date-container">
                                    <Date data-test="due-date-value">
                                        <StyledEmpty>
                                            {practisSet.id &&
                                            dueDates &&
                                            dueDates[practisSet.id] ? (
                                                dayjs(
                                                    dueDates[
                                                        practisSet.id
                                                    ] as string
                                                ).format('MM/DD/YY')
                                            ) : (
                                                <EmptyCellDash />
                                            )}
                                        </StyledEmpty>
                                    </Date>
                                    <PencilWrapper
                                        onClick={() =>
                                            practisSet.id &&
                                            openDueDatePopup(practisSet.id)
                                        }
                                        data-test="due-date-edit-button"
                                    >
                                        <Pencil />
                                    </PencilWrapper>
                                </DueDateContainer>
                            )}
                        </PractisSetsItemWrapper>
                    );
                })}
            </GeneralList>
            {isDueDateCalendarVisible && (
                <DueDateCalendar
                    data={{...dueDateData, isSubTitleVisible: false}}
                    onCancel={() => setIsDueDateCalendarVisible(false)}
                    onApply={updateDueDate}
                />
            )}
        </StyledPractisSetsList>
    );
};
