import { PractisWebMode } from '../../constants/enums';
import { storageKeys } from '../../constants/variables/general';
import Storage from '../../services/Storage';

/**
 * @description To detect the portal mode: 'WEB' | 'ADMIN'
 * @function isAdminPortal
 * @returns { boolean }
 */
export function isAdminPortal() {
    const practisWebMode = Storage.get(storageKeys.PRACTIS_WEB);
    if (
        practisWebMode &&
        practisWebMode === String(PractisWebMode['ADMIN_PORTAL'])
    ) {
        return true;
    }

    return false;
}

/**
 * @description To detect user OS
 * @function getOS
 * @returns { string }
 */
export function getOS() {
    const platform = window.navigator.platform;
    const osMap = {
        Mac: 'Mac OS',
        Win: 'Windows',
        Linux: 'Linux',
    };
    const os =
        Object.keys(osMap).find(key => platform.includes(key)) || 'Unknown';
    return os;
}

export function isProductionEnvironment () {
    return process.env.REACT_APP_DEV_ENV === 'prod';
}