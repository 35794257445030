import React, { FC } from 'react';
import styled from 'styled-components';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';

const Arrow = styled.div<{
    width: number;
    height: number;
}>`
    position: absolute;
    top: 0;
    left: 7px;
    transform: translateY(-100%);
    width: 38px;
    height: 9px;
    overflow: hidden;
    box-shadow: 0 0 10px -17px rgba(166, 174, 190, 0.5);

    :after {
        content: '';
        position: absolute;
        width: 18px;
        height: 36px;
        background: white;
        transform: rotate(45deg);
        top: 0;
        left: 0;
        box-shadow: -1px -1px 10px -2px rgba(166, 174, 190, 0.5);
    }
`;

const StyledPopover = styled(Popover)<{
    verticaloffset: number;
    horizontaloffset: number;
}>`
    && > .MuiPopover-paper {
        overflow: auto;
        margin-top: ${({ verticaloffset }) => verticaloffset}px;
        margin-left: ${({ horizontaloffset }) => horizontaloffset}px;
    }
    && > .MuiPaper-elevation8 {
        border-radius: 0;
        box-shadow: none;
        background: transparent;
        overflow: visible;
    }
`;

const Wrapper = styled.div<{
    padding?: string;
}>`
    padding: ${({ padding }) => (padding ? padding : '32px 24px 24px 24px')};
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    background: ${({ theme }) => theme.Colors.white};
    overflow: hidden;
`;

export const PopupComponent: FC<{
    triggerRef: any;
    open: boolean;
    onDismiss: () => void;
    arrow?: boolean;
    hideOnOutsideClick?: () => void;
    padding?: string;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    verticalOffset?: number;
    horizontalOffset?: number;
}> = ({
    triggerRef,
    open,
    onDismiss,
    children,
    arrow = false,
    hideOnOutsideClick,
    padding,
    anchorOrigin,
    transformOrigin,
    verticalOffset,
    horizontalOffset,
}) => {
    return (
        <StyledPopover
            anchorEl={triggerRef.current}
            open={open}
            onClose={() => {
                if (!hideOnOutsideClick) {
                    onDismiss();
                }
            }}
            anchorOrigin={
                anchorOrigin || {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            transformOrigin={
                transformOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            onClick={e => {
                e.stopPropagation();
            }}
            verticaloffset={verticalOffset ? verticalOffset : 8}
            horizontaloffset={horizontalOffset ? horizontalOffset : 0}
        >
            <Wrapper padding={padding}>
                {arrow && (
                    <Arrow
                        width={triggerRef?.current?.clientWidth || 0}
                        height={triggerRef?.current?.clientHeight || 0}
                    />
                )}
                {children}
            </Wrapper>
        </StyledPopover>
    );
};
