import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FiltersCheckbox } from './FiltersCheckbox';
import { FiltersDivider } from './FiltersDivider';

interface Props<TValue = any> {
    items: FiltersCheckboxListItem<TValue>[];
    size?: number;
    divisible?: boolean;
    onChange?(value?: TValue): void;
}

export const FiltersCheckboxList = function <TValue = any>(props: Props<TValue>) {
    const {
        items,
        size = 12,
        divisible,
        onChange,
    } = props;

    const topItems = useMemo(() => divisible ? items.filter(i => i.checked) : [], [divisible, items]);
    const bottomItems = useMemo(() => divisible ? items.filter(i => !i.checked) : [], [divisible, items]);
    const renderCheckbox = (item: FiltersCheckboxListItem<TValue>) => (
        <FiltersCheckbox
            key={String(item.value)}
            checked={item.checked}
            label={item.label}
            value={item.value}
            partial={item.partial}
            disabled={item.disabled}
            size={size}
            onChange={onChange}
        />
    );

    if (divisible) {
        return (
            <Root data-test="filter-items-container">
                <List data-test="filters-items-list-container">
                    {topItems.map(renderCheckbox)}
                    {!!topItems.length && topItems.length < items.length && <FiltersDivider />}
                    {bottomItems.map(renderCheckbox)}
                </List>
            </Root>
        );
    }

    return (
        <Root data-test="filter-items-container">
            <List data-test="filters-items-list-container">
                {items.map(renderCheckbox)}
            </List>
        </Root>
    );
};

export interface FiltersCheckboxListItem<TValue = any> {
    checked: boolean;
    partial?: boolean;
    disabled?: boolean;
    label: string;
    value?: TValue;
}

const Root = styled.div`
    overflow: auto;
    height: 100%;
`;

const List = styled.span``; 