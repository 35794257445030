import { useCallback } from 'react';

import { useSearchLabelsApi } from './api';
import {
    clearAction,
    saveAction,
    searchFailureAction,
    searchSuccessAction,
    selectAllAction,
    deselectAllAction,
    selectLabelsAction,
    toggleCollapseLabelAction,
    toggleCollapseAllLabelsAction,
    searchStartAction,
    resetSelectedAction,
    resetAction,
    resetCollapseAllLabelsAction,
} from './actions';

import { useEncapsulatedDispatch } from './helpers/useEncapsulatedDispatch';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { SearchParams } from '../../../../../constants/interfaces/filters';
import { useDispatch } from 'react-redux';
import { usePortableLabelsState } from '../../states';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { Label } from '../../../../../constants/interfaces/Label';
import { unionWith } from 'lodash';
import { NOT_AUTHORIZED_ERROR_MESSAGE } from '../../../../../ui/components/ErrorMessages/constants';

export const useFetchAllLabelsService = () => {
    const dispatch = useDispatch();
    const showMessage = useShowMessage();
    const searchLabels = useSearchLabelsApi();
    return useCallback(
        async () => {
            dispatch(searchStartAction());
            try {
                let sp = { offset: 0, limit: 20, orderBy: { field: "id", asc: true } } as SearchParams;
                let data = { items: [] as Label[], totalCount: 0 } as PaginationResult<Label>;
                let pageNum = 0;
                do {
                    const prevItems = data.items;
                    data = await searchLabels(sp);
                    data = sp.offset === 0 || !prevItems
                        ? data
                        : {
                            ...data,
                            items: unionWith(
                                prevItems,
                                data.items,
                                (i1, i2) => i1.id === i2.id
                            ),
                        };
                    sp = {
                        ...sp,
                        offset: Math.min(data.items.length, data.totalCount)
                    };
                    pageNum++;
                } while(data.items.length < data.totalCount && pageNum < data.numberOfPages);
                dispatch(searchSuccessAction(data));
            } catch(error: any) {
                dispatch(searchFailureAction(error.message));
                error?.message !== NOT_AUTHORIZED_ERROR_MESSAGE &&
                    showMessage(error.message, 'error');
            }
        },
        [dispatch, showMessage, searchLabels]
    );
};

export const useToggleCollapseLabel = () => {
    const dispatch = useEncapsulatedDispatch();
 
    return useCallback(
        (id: number) => {
            dispatch(toggleCollapseLabelAction(id));
        },
        [dispatch]
    );
};

export const useToggleCollapseAllLabels = () => {
    const dispatch = useEncapsulatedDispatch();
    const collapsedLabelsList = usePortableLabelsState().data.items.filter(item => item.children?.length).map(item => item.id);
    return useCallback(
        () => {
            dispatch(toggleCollapseAllLabelsAction(collapsedLabelsList));
        },
        [dispatch, collapsedLabelsList]
    );
};

export const useResetCollapseAllLabels = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        () => {
            dispatch(resetCollapseAllLabelsAction());
        },
        [dispatch]
    );
};

export const useClearLabels = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(clearAction());
    }, [dispatch]);
};

export const useSaveLabels = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(saveAction());
    }, [dispatch]);
};

export const useSelectLabels = () => {
    const dispatch = useEncapsulatedDispatch();
    return useCallback(
        (ids: number[]) => {
            dispatch(selectLabelsAction(ids));
        },
        [dispatch]
    );
};

export const useSelectAllLabels = () => {
    
    const dispatch = useEncapsulatedDispatch();
    const labelsList = usePortableLabelsState().data.items;
    
    return useCallback((selectedList: any) => {
        dispatch(selectAllAction(selectedList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, labelsList]);
};

export const useDeselectAllLabels = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(deselectAllAction());
    }, [dispatch]);
};

export const useResetSelectedLabels = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetSelectedAction());
    }, [dispatch]);
};

export const useResetLabels = () => {
    const dispatch = useEncapsulatedDispatch();

    return useCallback(() => {
        dispatch(resetAction());
    }, [dispatch]);
};
