import React, { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Input } from '../../../ui/components/input';
import styled from 'styled-components';
import Dots from '../../../ui/icons/Dots';
import LabelsListContainer from '../components/LabelsList';
import {
    EditingLabels,
    useLabelsConfigState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../store/states';
import { useSelector } from 'react-redux';
import { getCompanyState } from '../../../pages/CompanySettings/store/reducers';
import {
    useClearLabelFilterActionService,
    useCollapseAllLabelsService,
    useCreateLabelService,
    useExpandAllLabelsService,
    useExpandLabelFieldByIdService,
    useSaveUserFiltersService,
    useSearchLabelsService,
    useSetAssignLabelsActionService,
    useSetEditingLabelActionService,
    useSetLabelFilterActionService,
    useSetLabelsSearchTermService,
} from '../store/services';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import { PaginationResult } from '../../../constants/interfaces/PaginationResult';
import { Label } from '../../../constants/interfaces/Label';
import { MutateLabelsParams } from '../../../api/labels/types';
import { CompanyInterface } from '../../../constants/interfaces/Company';
import AddLabels from '../components/AddLabels';
import { Button } from '../../../ui/components/Button';
import { ClickAwayListener } from '@material-ui/core';
import { listToTree } from '../../../helpers/functions/list-to-tree';
import { treeToList } from '../../../helpers/functions/tree-to-list';
import { ActionItem } from '../../../ui/components/ActionButton';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import EmptyLabels from '../components/EmptyLabels';
import { Variables } from '../../../theme/variables';
import { sortLabels, useRenewLabelsStateOnUpdate } from '../tools';
import { useIfChanged } from '../../../helpers/hooks/usePreviousData';
import { getAllUsersLoading } from '../../users/store/reducers';
import { useInvitationsState } from '../../users/store/states';
import {
    useLibraryChallengesState,
    useLibraryPractisSetState,
    useLibraryScenariosState,
} from '../../library/store/states';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import { CheckPermission } from '../../permissions';
import { usePermissionsState } from '../../permissions/store/state';
import {
    useTeamMembersState,
    useTeamsState,
    useTrainerPractisSetState,
} from '../../teams/store/states';
import { useSearchDebounced } from '../../../helpers/hooks/useSearch';
import { usePortableLabelsState } from '../../portableLabels/store/states';
import Search from '../../../ui/icons/Search';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../constants/variables/component-settings';

const StyledCreateLabels = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const CreateLabelsHeader = styled.div`
    padding: 0 16px 19px 16px;
`;

const SearchInput = styled(Input) <{ placeholderColor?: string }>`
    height: 40px;
    padding: 0 16px 0 0;
    font-size: 11px;
    font-weight: 600;

    ${props =>
        !!props.placeholderColor &&
        `
        &:: placeholder {
        color: ${props.placeholderColor};
        font-size: 11px;
    }`}
`;

const CreateLabelsBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const FiltersHeader = styled.div`
    padding: 0 12px 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
`;

const FiltersSelectedPanel = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const SelectedLabelsStyle = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.steelGrey};
`;

const ClearSelectedLabels = styled.div<{ disabled?: boolean }>`
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.steelGrey
            : props.theme.Colors.darkSkyBlue};
    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
    ${props => props.disabled && 'pointer-events: none'}
`;

const FilterActionsMenu = styled.div<{ disabled?: boolean }>`
    width: 12px;
    height: 18px;
    border-radius: 6px;
    color: var(--ps-grey-1);
    cursor: pointer;
    padding: 1px;
    &:active {
        background: ${props => props.theme.Colors.dark};
        opacity: 0.7;
    }
    ${props => props.disabled && `pointer-events: none`}
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.white};
`;

const StyledAddLabels = styled.div`
    padding: 0 16px 100px 16px;
`;

const StyledLinkText = styled(Button)`
    font-size: 13px;
    font-weight: 500;
    margin: 0 !important;
    & span {
        font-weight: bold;
        margin-right: 10px;
    }
`;

const LabelsActionMenu = styled.div`
    width: 184px;
    position: fixed;
    background: ${props => props.theme.Colors.white};
    padding: 16px 8px;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-left: 150px;
    margin-top: 80px;
`;

const LabelsScrollableContainer = styled.div`
    overflow: auto;
    flex: 1 1 auto;
    overflow-x: hidden;
    height: 0;

    &::-webkit-scrollbar-thumb {
        background: ${Variables.Colors.steelGrey40};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${Variables.Colors.steelGrey40};
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    background: ${props => props.theme.Colors.dark};
    border: 1px solid ${props => props.theme.Colors.dark};

    &:focus-within {
        border: 1px solid ${props => props.theme.Colors.steelGrey};
    }
`;

const Icon = styled.div`
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

const SearchIcon = styled(Search) <{ disabled?: boolean }>`
    width: 12px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.battleshipGrey
            : props.theme.Colors.steelGrey};
`;

export const LabelsListScrollContext = createContext<number>(0);

const CreateLabels: FC<{
    labels?: PaginationResult<Label>;
    allLabels?: Label[];
    labelsCollapseState: number[];
    selectedLabels: number[];
    assignedLabels: number[];
    labelsModified?: boolean;
    searchLabels(searchParams: SearchParams): void;
    saveUserFilters(filters: number[] | null): void;
    updatedLabels?: Label;
    updateType?: 'create' | 'update' | 'delete';
    createLabel: (params: MutateLabelsParams) => Promise<any>;
    searchTerm: string;
    setLabelsSearchTerm: (value: string) => void;
    company?: CompanyInterface;
    setLabels(labels: number[]): void;
    clearLabels(): void;
    editingLabels?: EditingLabels;
    setEditingLabel: (configs?: EditingLabels) => void;
    setLabelFilters: (labelIds: number[]) => void;
    setAssignedLabels: (labelIds: number[]) => void;
    expandAll: () => void;
    collapseAll: () => void;
    expandLabel: (labelId: number) => void;
    disableLabels?: boolean;
    loading?: boolean;
}> = ({
    labels,
    allLabels,
    labelsCollapseState,
    selectedLabels,
    assignedLabels,
    createLabel,
    searchLabels,
    updatedLabels,
    updateType,
    company,
    clearLabels,
    searchTerm,
    setLabelsSearchTerm,
    editingLabels,
    setEditingLabel,
    setLabelFilters,
    setAssignedLabels,
    expandAll,
    collapseAll,
    expandLabel,
    saveUserFilters,
    disableLabels,
    loading,
}) => {
        const [open, setOpen] = useState(false);
        const [savingLabels, setSavingLabels] = useState(false);
        
        const transformedLabels = useMemo(
            () => listToTree(treeToList(labels && labels.items)),
            [labels]
        );

        const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
        const setLabelsSearchTermDebounced = useMemo(
            () => debounce(setLabelsSearchTerm, SEARCH_INPUT_DEBOUNCE_DELAY),
            [setLabelsSearchTerm]
        );
        const handleSearchTermChange = useCallback(
            (value, useDebounce) => {
                setLocalSearchTerm(value);
                if (useDebounce)
                    setLabelsSearchTermDebounced(value);
                else
                    setLabelsSearchTerm(value);
            },
            [setLabelsSearchTermDebounced, setLabelsSearchTerm]
        );

        const editMode =
            !!editingLabels && !editingLabels.id ? editingLabels.mode : 'view';

        const initialSearchParams: SearchParams = {
            searchTerm: searchTerm,
            filters: [],
            orderBy: {},
            offset: 0,
            totalCount: 0,
            numberOfPages: 0,
        };

        const {
            searchParams,
            setSearchTerm,
            refreshSearchParams,
        } = useSearchParamsState(initialSearchParams);
        const ifSearchParamsChanged = useIfChanged(searchParams);

        useEffect(() => {
            if (ifSearchParamsChanged) {
                searchLabels(searchParams);
            } 
        }, [searchLabels, searchParams, company, ifSearchParamsChanged]);

        useEffect(() => {
            setSearchTerm(searchTerm);
        }, [searchTerm, setSearchTerm]);

        const permissions = usePermissionsState();
        useEffect(() => {
            if (
                savingLabels &&
                permissions.includes(NEW_PERMISSIONS.SAVE_SEARCH_FILTER)
            ) {
                saveUserFilters(selectedLabels);
                setSavingLabels(false);
            }
        }, [
            selectedLabels,
            savingLabels,
            setSavingLabels,
            saveUserFilters,
            permissions,
        ]);

        useEffect(() => {
            if (searchTerm.length > 0) {
                const parentLabelPaths: any =
                    labels &&
                    labels.items
                        .filter((label: Label) => {
                            return !!label.path;
                        })
                        .map((label: Label) => label.path && label.path.split('/'));
                if (parentLabelPaths && parentLabelPaths.length > 0) {
                    const flattened = [].concat.apply([], parentLabelPaths);

                    const uniqueIds = new Set(
                        flattened.filter((item: string) => item !== '')
                    );

                    for (let id of Array.from(uniqueIds)) {
                        expandLabel(Number(id));
                    }
                }
            }
        }, [searchTerm, labels, expandLabel]);

        useEffect(() => {
            if (updatedLabels) refreshSearchParams();
        }, [updatedLabels, refreshSearchParams]);

        const handleSelectLabels = useRenewLabelsStateOnUpdate();
        const ifAllLabelsChanged = useIfChanged(allLabels);

        useEffect(() => {
            const labelsTree = listToTree(allLabels);
            if (
                ifAllLabelsChanged &&
                updatedLabels &&
                (updateType === 'delete' || updateType === 'create')
            ) {
                setLabelFilters(
                    handleSelectLabels(
                        updatedLabels,
                        selectedLabels,
                        labelsTree,
                        updateType
                    )
                );

                setSavingLabels(true);
            }
        }, [
            allLabels,
            updatedLabels,
            updateType,
            handleSelectLabels,
            selectedLabels,
            setLabelFilters,
            ifAllLabelsChanged,
        ]);
        useEffect(() => {
            if (ifAllLabelsChanged && updatedLabels && updateType === 'delete') {
                if (updateType === 'delete') {
                    setAssignedLabels(
                        assignedLabels.filter(
                            labelId => labelId !== updatedLabels.id
                        )
                    );
                }
            }
        }, [
            updatedLabels,
            updateType,
            assignedLabels,
            setAssignedLabels,
            ifAllLabelsChanged,
        ]);

        /**
         * @function isSearchDisabled
         * @returns { boolean }
         */
        const isSearchDisabled = (): boolean => {
            return (
                !!labels &&
                labels.items.length < 1 &&
                localSearchTerm.length < 1 &&
                !loading
            );
        }

        if (loading && !labels && searchTerm.length < 1) {
            return <Loading />;
        }

        return (
            <StyledCreateLabels>
                <CreateLabelsHeader>
                    <Container>
                        <Icon>
                            <SearchIcon disabled={isSearchDisabled()} />
                        </Icon>
                        <SearchInput
                            colorTheme="darkTwo"
                            height={'40px'}
                            value={localSearchTerm}
                            handleChange={(e: any) => {
                                handleSearchTermChange(e.target.value, true);
                            }}
                            placeholderColor={
                                isSearchDisabled()
                                    ? Variables.Colors.steelGrey
                                    : Variables.Colors.cloudyBlue
                            }
                            disabled={isSearchDisabled()}
                            clearInput={() => {
                                handleSearchTermChange('', false);
                            }}
                            dataTest='sidebar-labels-search-input'
                        />
                    </Container>
                </CreateLabelsHeader>
                <CreateLabelsBody>
                    <FiltersHeader>
                        <FilterTitle data-test='sidebar-labels-filter-text'>Filter by</FilterTitle>
                        {labels && labels.items.length > 0 && (
                            <FilterActionsMenu
                                onClick={() => setOpen(true)}
                                data-test="sidebar-labels-menu"
                            >
                                <Dots />
                            </FilterActionsMenu>
                        )}
                        {open && (
                            <ClickAwayListener
                                onClickAway={() => setOpen(false)}
                            >
                                <LabelsActionMenu>
                                    <ActionItem
                                        onClick={e => {
                                            e.stopPropagation();
                                            collapseAll();
                                            setOpen(false);
                                        }}
                                    >
                                        Collapse All
                                    </ActionItem>
                                    <ActionItem
                                        onClick={e => {
                                            e.stopPropagation();
                                            expandAll();
                                            setOpen(false);
                                        }}
                                    >
                                        Expand All
                                    </ActionItem>
                                </LabelsActionMenu>
                            </ClickAwayListener>
                        )}
                    </FiltersHeader>
                    <FiltersSelectedPanel>
                        <SelectedLabelsStyle data-test='sidebar-labels-selected-counter'>
                            {selectedLabels && selectedLabels.length > 0
                                ? selectedLabels.filter(x => x > 0).length
                                : 'No'}{' '}
                            Labels selected
                        </SelectedLabelsStyle>
                        <ClearSelectedLabels
                            onClick={() => {
                                clearLabels();
                                setSavingLabels(true);
                            }}
                            disabled={
                                !(selectedLabels && selectedLabels.length > 0)
                            }
                            data-test="sidebar-labels-clear"
                        >
                            Clear
                        </ClearSelectedLabels>
                    </FiltersSelectedPanel>
                    {searchTerm.length > 0 &&
                    labels &&
                    labels.items.length < 1 ? (
                        <EmptyLabels />
                    ) : (
                        <LabelsScrollableContainer id="labels-scrollable-container">
                            <LabelsListContainer
                                labels={sortLabels(transformedLabels)}
                                labelsCollapseState={labelsCollapseState}
                                selectedLabels={selectedLabels}
                                disableLabels={disableLabels}
                            />
                            <CheckPermission
                                permissions={[NEW_PERMISSIONS.CREATE_LABEL]}
                            >
                                <StyledAddLabels>
                                    {editMode === 'edit' && (
                                        <AddLabels
                                            saveLabel={createLabel}
                                            setEditing={setEditingLabel}
                                            labels={labels && labels.items}
                                        />
                                    )}
                                    <div data-test="sidebar-labels-add">
                                        <StyledLinkText
                                            variant="transparent"
                                            action={() =>
                                                setEditingLabel({
                                                    id: null,
                                                    mode: 'edit',
                                                })
                                            }
                                            disabled={editMode === 'edit'}
                                        >
                                            <span>+</span>Add Label
                                        </StyledLinkText>
                                    </div>
                                </StyledAddLabels>
                            </CheckPermission>
                        </LabelsScrollableContainer>
                    )}
                </CreateLabelsBody>
            </StyledCreateLabels>
        );
    };

export const CreateLabelsContainer: FC = () => {
    const state = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const labelsConfigState = useLabelsConfigState();
    const updatedLabels = useUpdatedLabelsState();
    const company = useSelector(getCompanyState);

    const createLabel = useCreateLabelService();

    const searchLabels = useSearchLabelsService();
    const searchLabelsDebounced = useSearchDebounced(searchLabels, 0);

    const setLabelsSearchTerm = useSetLabelsSearchTermService();
    const setEditingLabel = useSetEditingLabelActionService();
    const saveUserFilters = useSaveUserFiltersService();
    const setLabels = useSetLabelFilterActionService();
    const clearLabels = useClearLabelFilterActionService();

    const expandAll = useExpandAllLabelsService();
    const expandLabel = useExpandLabelFieldByIdService();
    const collapseAll = useCollapseAllLabelsService();
    const setLabelFilters = useSetLabelFilterActionService();
    const setAssignedLabels = useSetAssignLabelsActionService();

    const loadingUsers = useSelector(getAllUsersLoading);
    const loadingInvitations = useInvitationsState().loading;
    const loadingScenarios = useLibraryScenariosState().loading;
    const loadingChallenges = useLibraryChallengesState().loading;
    const loadingPractisSets = useLibraryPractisSetState().loading;
    const loadingMyTeams = useTeamsState().loading;
    const loadingTeamMembers = useTeamMembersState().loading;
    const loadingPractisSetTraining = useTrainerPractisSetState().loading;

    const labelsShouldBeDisabledOnLoading =
        loadingUsers ||
        loadingInvitations ||
        loadingScenarios ||
        loadingChallenges ||
        loadingPractisSets ||
        loadingMyTeams ||
        loadingTeamMembers ||
        loadingPractisSetTraining;

    return (
        <CreateLabels
            labels={state.data}
            allLabels={labelsList.items}
            labelsCollapseState={state.collapseLabels}
            selectedLabels={state.selected || []}
            assignedLabels={state.assignedLabels || []}
            labelsModified={state.selectedModified}
            searchLabels={searchLabelsDebounced}
            saveUserFilters={saveUserFilters}
            updatedLabels={updatedLabels.data}
            updateType={updatedLabels.updateType}
            company={company}
            setLabels={setLabels}
            clearLabels={clearLabels}
            createLabel={createLabel}
            searchTerm={labelsConfigState.searchTerm}
            setLabelsSearchTerm={setLabelsSearchTerm}
            editingLabels={labelsConfigState.editingLabels}
            setEditingLabel={setEditingLabel}
            setLabelFilters={setLabelFilters}
            setAssignedLabels={setAssignedLabels}
            expandAll={expandAll}
            collapseAll={collapseAll}
            expandLabel={expandLabel}
            disableLabels={labelsShouldBeDisabledOnLoading}
            loading={state.loading}
        />
    );
};

export default CreateLabelsContainer;
