import { kebabCase } from 'lodash';
import {
    Container,
    Title,
    TitleSkeleton,
    SubHeader
} from './styles';
import { FilterHeaderProps } from './types';

function FilterHeaderView({
    title,
    isLoading,
    isDisabled,
    subHeader
}: FilterHeaderProps) {
    return (
        <Container>
            {isLoading ? (
                <>
                    <TitleSkeleton width={119} />
                </>
            ) : (
                <>
                    <Title
                        isDisabled={isDisabled}
                        data-test={`${kebabCase(title)}-filter-title`}
                    >
                        {title}
                    </Title>
                    {subHeader && <SubHeader data-test={`${kebabCase(title)}-filter-subtitle`}>{subHeader}</SubHeader>}
                </>
            )}
        </Container>
    );
}

export default FilterHeaderView;