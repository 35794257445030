import { FC, useRef, useState } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../ui/components/ActionButton';
import { isTeamLeader } from '../../../../constants/enums';
import { PopupComponent } from '../../../../ui/components/Popup';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../labels/tools';
import { AssignLabels } from '../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { Challenge as ChallengesInterface } from '../../../../constants/interfaces/Challenge';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import { ChallengeActionsHelper } from '../../tools';
import { useLabelsState } from '../../../portableLabels/store/hors/withLabels/states';

const ChallengesListActions: FC<{
    challenge: ChallengesInterface;
    actionsHelper: ChallengeActionsHelper;
    restoreChallenge: (challengeIds: number[]) => void;
    handleItemEdit: (challenge: ChallengesInterface) => void;
    handleViewChallenge: (challenge: ChallengesInterface) => void;
    onDuplicateChallenge: (challenge: ChallengesInterface) => void;
    handleDeleteChallenge: (challengeId: number) => void;
    archiveChallenge: (challengeId: number[]) => void;
    assignLabelsToLibrary: (
        addedLabelIds: number[],
        deletedLabelIds: number[]
    ) => void;
    isHidden?: boolean;
}> = ({
    challenge,
    actionsHelper,
    restoreChallenge,
    handleItemEdit,
    handleViewChallenge,
    handleDeleteChallenge,
    onDuplicateChallenge,
    archiveChallenge,
    assignLabelsToLibrary,
    isHidden,
}) => {
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();
    const profile = useSelector(getProfileState);

    const handleSetPreselectedLabels = () => {
        const selectedLabelIds = challenge?.labels || [];
        const preAssignedLabels = calculatePreSelectedLabels(
            selectedLabelIds,
            labelsList.data
        );
        setSelectLabels(preAssignedLabels);
        saveLabels();
    };

    const handleSaveSelectedLabels = (labelIDs: number[]) => {
        const addedLabelIds = calculateAssignedLabels(labelIDs, labels.saved);
        const deletedLabelIds = calculateRemovedLabels(labelIDs, labels.saved);

        assignLabelsToLibrary(addedLabelIds, deletedLabelIds);
    };

    const [assignSingleLabelOpen, setAssignSingleLabelOpen] = useState<
        null | number | undefined
    >(null);

    const triggerRef = useRef(null);

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton
                    customWidth={168}
                    isHidden={isHidden}
                    dataTest="library-challenges-item-menu"
                >
                    {actionsHelper.canEdit(challenge) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleItemEdit(challenge);
                            }}
                            dataTest="edit-challenge-action"
                        >
                            View
                        </ActionItem>
                    )}
                    {actionsHelper.canAssignLabels(challenge) &&
                        !!labelsList.data.items.length && (
                            <ActionItem
                                onClick={e => {
                                    e.stopPropagation();
                                    setAssignSingleLabelOpen(challenge.id);
                                    handleSetPreselectedLabels();
                                }}
                                dataTest="assign-labels-challenge-action"
                            >
                                Assign Labels
                            </ActionItem>
                        )}
                    {isTeamLeader(profile?.role?.name) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                handleViewChallenge(challenge);
                            }}
                            dataTest="view-challenge-action"
                        >
                            View
                        </ActionItem>
                    )}
                    {actionsHelper.canRestore(challenge) && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                restoreChallenge([challenge.id!]);
                            }}
                            dataTest="restore-challenge-action"
                        >
                            Restore
                        </ActionItem>
                    )}
                    {actionsHelper.canDelete(challenge) && (
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                challenge?.id &&
                                    handleDeleteChallenge(challenge.id);
                            }}
                            dataTest="delete-challenge-action"
                        >
                            Delete
                        </ActionItem>
                    )}
                    {actionsHelper.canDuplicate(challenge) && (
                        <ActionItem
                            onClick={e => {
                                onDuplicateChallenge(challenge);
                            }}
                            dataTest="duplicate-challenge-action"
                        >
                            Duplicate
                        </ActionItem>
                    )}
                    {actionsHelper.canArchive(challenge) && (
                        <ActionItem
                            destructive={true}
                            onClick={e => {
                                e.stopPropagation();
                                archiveChallenge([challenge.id!]);
                            }}
                            dataTest="archive-challenge-action"
                        >
                            Archive
                        </ActionItem>
                    )}
                </ActionButton>
            </div>
            <PopupComponent
                triggerRef={triggerRef}
                open={assignSingleLabelOpen === challenge.id}
                onDismiss={() => {
                    setAssignSingleLabelOpen(null);
                }}
            >
                <AssignLabels
                    onLabelsChange={handleSaveSelectedLabels}
                    onSuccessApply={() => {
                        setAssignSingleLabelOpen(null);
                    }}
                    withBorder={true}
                />
            </PopupComponent>
        </span>
    );
};

export default ChallengesListActions;
