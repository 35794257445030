import { Reducer } from 'redux';
import { PortableLabelsState, initialState } from './states';
import { PortableLabelsActions } from './actions';

const portableLabelsBaseReducer: Reducer<
    PortableLabelsState,
    PortableLabelsActions
> = (
    state = initialState,
    action: PortableLabelsActions
): PortableLabelsState => {
    switch (action.type) {
        case 'searchStartLabels':
            return {
                ...state,
                loading: true,
            };
        case 'searchSuccessLabels':
            return {
                data: action.result,
                loading: false,
            };
        case 'searchFailureLabels':
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export const portableLabelsReducer = portableLabelsBaseReducer;
