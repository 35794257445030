import { ChangeEvent, memo, useState } from 'react';

import { ImageEditorControllerProps } from './types';
import ImageEditorView from './view';

function ImageEditorController(props: ImageEditorControllerProps) {
    const {
        editorRef,
        selectedImage,
        handleClose,
        handleApplyEditedImage,
        rectStyled
    } = props;

    const [scale, setScale] = useState<number>(1.2);

    const onScaleChange = (
        event: ChangeEvent<{}>,
        scale: number | number[]
    ) => {
        setScale(scale as number);
    };

    return (
        <ImageEditorView
            editorRef={editorRef}
            selectedImage={selectedImage}
            handleClose={handleClose}
            handleApplyEditedImage={handleApplyEditedImage}
            onScaleChange={onScaleChange}
            scale={scale}
            rectStyled={rectStyled}
        />
    );
}

export default memo(ImageEditorController);

