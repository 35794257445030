import { LineSkeleton, BodySkeletonContainer } from "./styles";
const bodyLineWidths = [
    {
        headerWidth: 72,
        subHeaderWidth: 137,
        lineWidths: [448,327,177]
    },
    {
        headerWidth: 149,
        subHeaderWidth: 84,
        lineWidths: [327,448,291]
    },
    {
        headerWidth: 109,
        subHeaderWidth: 95,
        lineWidths: [282,219,342]
    }
];

function BodySkeleton() {
    return <>{bodyLineWidths.map(({headerWidth, subHeaderWidth, lineWidths}, index) => {
        return <BodySkeletonContainer key={index} data-test="roleplay-context-skeleton" >
            <LineSkeleton width={headerWidth} height={12} data-test="roleplay-context-skeleton-line" />
            <LineSkeleton width={subHeaderWidth} height={8} marginTop={'17px'} data-test="roleplay-context-skeleton-line"/>
                {
                    lineWidths.map((width, index) => <LineSkeleton width={width} height={8} key={index} data-test="roleplay-context-skeleton-line"/>)
                }
            </BodySkeletonContainer>
    })}</>
}

export default BodySkeleton;