import { useCallback } from 'react';
import { listToTree } from '../../helpers/functions/list-to-tree';
import { searchTree } from '../../helpers/functions/search-tree';
import { Label } from '../../constants/interfaces/Label';
import { PaginationResult } from '../../constants/interfaces/PaginationResult';
import { usePortableLabelsState } from '../portableLabels/store/states';

const recursiveParent = (label: Label, selected: any, labelsTree: any) => {
    if (label && label.parentId) {
        for (let leaf of labelsTree) {
            const parentLabel = searchTree(leaf, label.parentId);

            if (parentLabel) {
                const siblingIds = parentLabel.children.map((x: Label) => x.id);

                if (siblingIds.every((x: number) => selected.has(x))) {
                    if (!selected.has(parentLabel.id))
                        selected.add(parentLabel.id);
                    if (selected.has(-parentLabel.id))
                        selected.delete(-parentLabel.id);
                } else if (
                    siblingIds.every(
                        (x: number) => selected.has(x) || selected.has(-x)
                    )
                ) {
                    selected.delete(parentLabel.id);
                    selected.add(-parentLabel.id);
                } else if (
                    siblingIds.some(
                        (x: number) => selected.has(x) || selected.has(-x)
                    )
                ) {
                    if (selected.has(parentLabel.id)) {
                        selected.delete(parentLabel.id);
                    }
                    if (!selected.has(-parentLabel.id))
                        selected.add(-parentLabel.id);
                } else {
                    if (selected.has(parentLabel.id))
                        selected.delete(parentLabel.id);
                    if (selected.has(-parentLabel.id))
                        selected.delete(-parentLabel.id);
                }

                recursiveParent(parentLabel, selected, labelsTree);
            }
        }
    }
};

const recursiveMarkChildren = (label: Label, selected: any) => {
    if (label && label.children) {
        for (let child of label.children) {
            selected.delete(-child.id);
            selected.add(child.id);
            recursiveMarkChildren(child, selected);
        }
    }
};

const recursiveUnmarkChildren = (label: Label, selected: any) => {
    if (label && label.children) {
        for (let child of label.children) {
            selected.delete(child.id);
            selected.delete(-child.id);
            recursiveUnmarkChildren(child, selected);
        }
    }
};

export const useCalculatePreSelectedLabels = () => {
    const labelsList = usePortableLabelsState().data;

    return useCallback(
        (
            selectedItems: number[],
            entity?: any,
            assignedLabels?: number[]
        ): { preAssignedLabels: number[] } => {
            let preAssignedLabelsSet = new Set<number>(assignedLabels?.length ? assignedLabels: []);

            if (!entity) {
                return {
                    preAssignedLabels: Array.from(preAssignedLabelsSet),
                };
            }

            // the case when assigned labels are not coming from BE 
            if (assignedLabels === undefined) {
                let countLabels: any = {};
                const selectedItemEntities = entity.items.filter(
                    (item: { id: number }) => selectedItems.includes(item.id)
                );
    
                for (let selectedEntity of selectedItemEntities) {
                    if (selectedEntity.labels) {
                        selectedEntity.labels.forEach((x: { id: number }) => {
                            countLabels[x.id] = (countLabels[x.id] || 0) + 1;
                        });
                    } else if (selectedEntity.labelIDs) {
                        selectedEntity.labelIDs.forEach((x: number) => {
                            countLabels[x] = (countLabels[x] || 0) + 1;
                        });
                    }
                }
    
                for (const labelId in countLabels) {
                    if (countLabels.hasOwnProperty(labelId)) {
                        if (
                            Number(countLabels[labelId]) ===
                            selectedItemEntities.length
                        ) {
                            preAssignedLabelsSet.add(Number(labelId));
                        } else {
                            preAssignedLabelsSet.add(-Number(labelId));
                        }
                    }
                }
    
            }
            
            if (labelsList && labelsList.items) {
                const labelsTree = listToTree(labelsList.items);

                for (let labelId of Array.from(preAssignedLabelsSet)) {
                    for (let leaf of labelsTree) {
                        const matchedLabel = searchTree(leaf, labelId);
                        if (matchedLabel && matchedLabel.parentId) {
                            if (
                                !preAssignedLabelsSet.has(matchedLabel.parentId)
                            ) {
                                preAssignedLabelsSet.add(
                                    -matchedLabel.parentId
                                );
                            }
                        }
                        if (matchedLabel && matchedLabel.parentId) {
                            recursiveParent(
                                matchedLabel,
                                preAssignedLabelsSet,
                                labelsTree
                            );
                        }
                    }
                }
            }

            const preAssignedLabels = Array.from(preAssignedLabelsSet);

            return { preAssignedLabels };
        },
        [labelsList]
    );
};
export const useCalculatePreSelectedLabelsForSingleItem = () => {
    return useCallback(
        (selectedItems: number[], labelsList?: PaginationResult<Label>) => {
            let preAssignedLabelsSet = new Set(selectedItems);
            if (labelsList && labelsList.items) {
                const labelsTree = listToTree(labelsList.items);

                for (let labelId of Array.from(preAssignedLabelsSet)) {
                    for (let leaf of labelsTree) {
                        const matchedLabel = searchTree(leaf, labelId);
                        if (matchedLabel && matchedLabel.parentId) {
                            if (
                                !preAssignedLabelsSet.has(matchedLabel.parentId)
                            ) {
                                preAssignedLabelsSet.add(
                                    -matchedLabel.parentId
                                );
                            }
                        }
                        if (matchedLabel && matchedLabel.parentId) {
                            recursiveParent(
                                matchedLabel,
                                preAssignedLabelsSet,
                                labelsTree
                            );
                        }
                    }
                }
            }
            return Array.from(preAssignedLabelsSet);
        },
        []
    );
};

export const useHandleSelectLabels = () => {
    return useCallback((label, selectedLabels, labelsTree) => {
        const newSelectedLabels = new Set([...selectedLabels]);

        if (!newSelectedLabels.has(label.id)) {
            newSelectedLabels.delete(-label.id);
            newSelectedLabels.add(label.id);
        } else if (newSelectedLabels.has(-label.id)) {
            newSelectedLabels.delete(-label.id);
            newSelectedLabels.add(label.id);
        } else {
            newSelectedLabels.delete(-label.id);
            newSelectedLabels.delete(label.id);
        }

        for (let leaf of labelsTree) {
            const selectedLabel = searchTree(leaf, label.id);
            if (selectedLabel) {
                if (!newSelectedLabels.has(selectedLabel.id)) {
                    recursiveUnmarkChildren(selectedLabel, newSelectedLabels);
                    newSelectedLabels.delete(-selectedLabel.id);
                    if (selectedLabel.parentId) {
                        recursiveParent(
                            selectedLabel,
                            newSelectedLabels,
                            labelsTree
                        );
                    }
                } else {
                    if (selectedLabel.children) {
                        recursiveMarkChildren(selectedLabel, newSelectedLabels);
                    }

                    if (selectedLabel.parentId) {
                        recursiveParent(
                            selectedLabel,
                            newSelectedLabels,
                            labelsTree
                        );
                    }
                }
            }
        }

        return Array.from(newSelectedLabels);
    }, []);
};

export const useCalculateDeletedLabels = () => {
    const labelsList = usePortableLabelsState().data;

    return useCallback(
        assignedLabels => {
            return (
                labelsList?.items
                    .map(x => x.id)
                    .filter(
                        x =>
                            !assignedLabels.includes(x) &&
                            !assignedLabels.includes(-x)
                    ) ?? []
            );
        },
        [labelsList]
    );
};

/**
 * @function calculateRemovedLabels
 * @param { number[] } selectedLabelIds 
 * @param { number[] } initialSelectedIds 
 * @returns { number[] }
 */
export const calculateRemovedLabels = (
    selectedLabelIds: number[],
    initialSelectedIds: number[]
) => {
    return (
        initialSelectedIds?.filter(
            id =>
                !selectedLabelIds.includes(id) &&
                !selectedLabelIds.includes(-id)
        ) ?? []
    );
};

/**
 * @function calculateAssignedLabels
 * @param { number[] } selectedLabelIds 
 * @param { number[] } initialSelectedIds 
 * @returns { number[] }
 */
export const calculateAssignedLabels = (
    selectedLabelIds: number[],
    initialSelectedIds: number[]
) => {
    return (
        selectedLabelIds
            ?.filter(
                id =>
                    !initialSelectedIds.includes(id) &&
                    !initialSelectedIds.includes(-id)
            )
            ?.filter(id => id > 0) ?? []
    );
};


export const clearSelectedItemIfNotLoaded = (
    selectedList?: number[],
    loadedList?: number[]
) => {
    if (!selectedList || !loadedList) return selectedList;
    return selectedList.filter((selected: number) => {
        return !loadedList.includes(selected);
    });
};

export const mergeLabelArrays = (
    labels: number[],
    selected: number[],
    deleted: number[]
) => {
    for (let selectedLabel of selected) {
        if (!labels.includes(selectedLabel)) {
            labels.push(selectedLabel);
        }
    }

    return labels.filter(label => !deleted.includes(label));
};

export const useRenewLabelsStateOnUpdate = () => {
    return useCallback(
        (
            label,
            selectedLabels,
            labelsTree,
            updateType: 'delete' | 'create'
        ) => {
            const newSelectedLabels = new Set([...selectedLabels]);
            for (let leaf of labelsTree) {
                if (updateType === 'create') {
                    const selectedLabel = searchTree(leaf, label.id);
                    if (selectedLabel && selectedLabel.parentId) {
                        recursiveParent(
                            selectedLabel,
                            newSelectedLabels,
                            labelsTree
                        );
                    }
                } else {
                    const selectedLabel = searchTree(leaf, label.parentId);
                    if (selectedLabel) {
                        recursiveParent(
                            selectedLabel && selectedLabel.children[0],
                            newSelectedLabels,
                            labelsTree
                        );
                    }
                }
            }
            return Array.from(newSelectedLabels);
        },
        []
    );
};

const hasChildren = (label?: Label) => {
    return label && label.children && label.children.length > 0;
};

function compare(a: any, b: any) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export function sortLabels(nodes: any) {
    nodes.sort(compare);
    nodes.forEach(function (node: any) {
        if (hasChildren(node)) {
            sortLabels(node.children);
        }
    });

    return nodes;
}

export const filterLabels = (term: string) => (labels: Label[]): Label[] => {
    if (!term) {
        return labels;
    }
    const getNodes = (result: Label[], object: Label) => {
        if (object.name.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
            result.push(object);
            return result;
        }
        if (Array.isArray(object.children)) {
            const children = object.children.reduce(getNodes, []);
            if (children.length) result.push({ ...object, children });
        }
        return result;
    };

    return labels.reduce(getNodes, []);
};
