import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Crown = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 14 12"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <g>
                                <path
                                    d="M12.19 9.53V12H1.8V9.53h10.39zm-.897.89H2.695v.69h8.598v-.69zM7 0c.808 0 1.464.65 1.464 1.453 0 .363-.134.694-.355.949L9.58 4.66l1.494-.746c.02-.786.667-1.418 1.463-1.418.809 0 1.464.651 1.464 1.454 0 .74-.558 1.352-1.28 1.442l-.585 3.432H1.865L1.28 5.392C.558 5.302 0 4.691 0 3.95c0-.803.655-1.454 1.464-1.454.808 0 1.463.651 1.463 1.454l-.002-.037 1.463.73 1.464-2.288c-.198-.248-.316-.561-.316-.902C5.536.651 6.192 0 7 0zm-.405 2.85L4.71 5.8l-2.043-1.02c-.13.186-.302.34-.503.448l.472 2.708h8.73l.474-2.707c-.201-.109-.374-.263-.505-.449L9.263 5.813 7.344 2.867c-.11.026-.225.04-.344.04-.14 0-.276-.02-.405-.056zm5.941.536c-.313 0-.567.252-.567.564 0 .311.254.564.567.564.314 0 .568-.253.568-.564 0-.312-.254-.564-.568-.564zm-11.072 0c-.314 0-.568.252-.568.564 0 .311.254.564.568.564.313 0 .567-.253.567-.564 0-.312-.254-.564-.567-.564zM7 .89c-.314 0-.568.252-.568.563 0 .312.254.564.568.564.314 0 .568-.252.568-.564 0-.311-.254-.563-.568-.563z"
                                    transform="translate(-1214 -532) translate(985.875 526) translate(228.125 6)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default Crown;
