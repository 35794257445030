import { useHistory, useLocation } from 'react-router';
import ROUTES from '../../../routes/routes';
import EnterEmailAddress from './components/EnterEmailAddress';
import FurtherInstructions from './components/FurtherInstructions';

function ForgotPasswordController () {
    const history = useHistory();
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    /**
     * @function handleSuccess - shows instructions view
     * @returns { void }
     */    
    const handleSuccess = () =>
        history.push(`${ROUTES.AUTH_PAGES.FORGOT_PASSWORD}?instructions=true`);

    if (query.get('instructions') === 'true')
        return <FurtherInstructions />;
    return <EnterEmailAddress onSuccess={handleSuccess} />
}

export default ForgotPasswordController;
