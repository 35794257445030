import { FC } from 'react';

import { Variables } from '../../../../../theme/variables';
import { CheckboxContainer, StyledCheckbox } from './style';
import { TableCheckboxProps } from './type';

const TableCheckbox: FC<TableCheckboxProps> = props => {
    const { checked, partial, disabled, handleCheck, size, dataTest } = props;

    return (
        <CheckboxContainer
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <StyledCheckbox
                size={size ? size : 16}
                checked={checked}
                partial={partial}
                disabled={disabled}
                background={Variables.Colors.white}
                handleChange={handleCheck}
                border={Variables.Colors.cloudyBlue}
                dataTest={dataTest}
            />
        </CheckboxContainer>
    );
};

export default TableCheckbox;
