import styled from 'styled-components';

export const Tr = styled.tr<{ clickable?: boolean }>`
    ${props =>
        props.clickable &&
        `
        cursor: pointer;
    `}

    &.is-default {
        font-weight: 500;
    }
`;

