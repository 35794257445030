import React, { FC } from 'react';
import styled from 'styled-components';
import secondsToMin from '../../../../../../../helpers/functions/convert-time';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../../../../../constants/interfaces/ScriptLine';
import ScriptHeaderActionsContainer from './ScriptHeaderActions';

const StyledScenarioScriptHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding-top: 24px;
`;

const BuilderActions = styled.div`
    height: 96px;
    display: flex;
    background: ${props => props.theme.Colors.dark};
    border-radius: 4px;
    margin-bottom: 16px;
`;

const BuilderConfigs = styled.div`
    flex: 2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: ${props => props.theme.Colors.darkTwo};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

const ConfigItem = styled.div`
    text-align: center;
`;

const BuilderDuration = styled.div`
    flex: 1;
    text-align: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const StyledValue = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.Colors.white};
    margin: 0;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const ScenarioScriptHeader: FC<{
    totalDuration?: number | null;
    customerLineCount?: number;
    repLineCount?: number;
}> = ({ totalDuration, customerLineCount, repLineCount }) => {
    return (
        <StyledScenarioScriptHeader>
            <BuilderActions>
                <BuilderConfigs>
                    <ConfigItem>
                        <StyledDescription data-test='scenario-customer-lines-text'>Customer Lines</StyledDescription>
                        <StyledValue data-test='scenario-customer-lines-value'>{customerLineCount}</StyledValue>
                    </ConfigItem>
                    <ConfigItem>
                        <StyledDescription data-test='scenario-rep-lines-text'>Rep Lines</StyledDescription>
                        <StyledValue data-test='scenario-rep-lines-value'>{repLineCount}</StyledValue>
                    </ConfigItem>
                </BuilderConfigs>
                <BuilderDuration>
                    <StyledDescription data-test='scenario-total-duration-text'>Total Duration</StyledDescription>
                    <StyledValue data-test='scenario-total-duration-value'>{secondsToMin(totalDuration)}</StyledValue>
                </BuilderDuration>
            </BuilderActions>
            <ScriptHeaderActionsContainer/>
        </StyledScenarioScriptHeader>
    );
};

export const ScenarioScriptHeaderContainer: FC<{
    totalDuration?: number | null;
    scriptLines?: ScriptLine[];
}> = ({ totalDuration, scriptLines }) => {
    const customerLineCount =
        scriptLines &&
        scriptLines.filter(
            (line: ScriptLine) => line.speaker === ScriptLineSpeaker.CUSTOMER
        ).length;
    const repLineCount =
        scriptLines &&
        scriptLines.filter(
            (line: ScriptLine) => line.speaker === ScriptLineSpeaker.REP
        ).length;

    return (
        <ScenarioScriptHeader
            totalDuration={totalDuration}
            customerLineCount={customerLineCount}
            repLineCount={repLineCount}
        />
    );
};

export default ScenarioScriptHeaderContainer;
