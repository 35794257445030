import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Generate = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 13 12"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="currentColor" fillRule="evenodd">
                    <path d="M6.625 0v12h-1V0h1zM8.5 1.688V10.5h-1V1.687h1zm-5.625 0V10.5h-1V1.687h1zM4.75 2.813v6.485h-1V2.812h1zm5.625 1.312v3.75h-1v-3.75h1zm1.875.375v3h-1v-3h1zM1 4.5v3H0v-3h1z" />
                </g>
            </svg>
        );
    }
);

export default Generate;
