import { useSelector } from 'react-redux';

import {  useWithScenariosContext } from './context/useWithTeamsContext';
import { ScenariosState } from './reducer';
import { DEFAULT_LABELS_SCOPE } from '../../../../portableLabels/store/hors/withLabels/scopes';

export const useScenariosState = (): ScenariosState => {
    const { reducerName, scope } = useWithScenariosContext();
    const appState = useSelector(state => state);

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithTeamsContext.Provider props'
        );
    }

    const labelsState: ScenariosState | undefined =
        // @ts-ignore
        // TODO implement `_teams?: TeamsState` field into each reducer (create general type)
        // interface GeneralReducer {
        //     ...
        //     _teams?: StateWithTeams;
        // }
        appState['accuracyscore']?._scenarios?.[scope || DEFAULT_LABELS_SCOPE];
        // appState[reducerName].data.items;
        // console.log(appState)
    
    if (!labelsState) {
        throw new Error(
            `Wrap your reducer ${reducerName} via "withScenariosReducer" HOR`
        );
    }

    return labelsState;
};
