import React, {
    useRef,
    cloneElement,
    ReactElement,
    RefObject,
    useEffect,
    forwardRef,
    PropsWithChildren,
    CSSProperties,
    ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import {
    PopupOffset,
    PopupPosition,
    PopupTrigger,
    usePopup,
} from '../../../helpers/hooks/usePopup';
import { getPortalElement } from '../Popup/helpers/getPortalElement';

//region Types
type Preset = 'button-icon' | 'dropdown' | 'checkbox-icon' | 'table-item' | 'question-icon' | 'question-icon-top' | 'pending-user';

type Props<T> = {
    label: ReactNode | string;
    disabled?: boolean;
    className?: string;
    position?: PopupPosition;
    offset?: PopupOffset;
    preset?: Preset;
    children: ReactElement | { (ref: RefObject<T>): ReactElement };
    trigger?: PopupTrigger;
    isHtmlContent?: boolean;
    dataTest?: string;
    style?: CSSProperties;
};
//endregion

//region Styles
const TooltipLabel = styled.div`
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 40px;
    flex-wrap: wrap;
    white-space: nowrap;
    user-select: none;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    color: ${props => props.theme.Colors.black};
    background: ${props => props.theme.Colors.white};
`;
//endregion

const TooltipContent = forwardRef<
    HTMLDivElement,
    {
        label: ReactNode | string;
        style: CSSProperties;
        className?: string;
        portalElement: HTMLElement;
        isHtmlContent?: boolean;
        dataTest?: string;
    }
>(({ className, style, label, portalElement, isHtmlContent, dataTest }, ref) => {
    if(isHtmlContent){
        return createPortal(
            <TooltipLabel ref={ref} className={className} style={style} data-test={dataTest}>
             <div style={{width: 264}}>{label}</div>
            </TooltipLabel>,
            portalElement
        );
    } else {
        return createPortal(
            <TooltipLabel ref={ref} className={className} style={style} data-test={dataTest}>
                {label}
            </TooltipLabel>,
            portalElement
        );
    }
});

/**
 * Can be only one child. Make for sure that child component is forwarded by forwardRef or it's just JSX markup.
 * Ref object can be obtained using render props from Children Prop
 * T - Ref object
 */
export const Tooltip = <T extends HTMLElement = HTMLElement>({
    label,
    position = 'right-start',
    offset,
    className,
    children,
    disabled,
    preset,
    trigger,
    isHtmlContent,
    dataTest,
    style: propStyle
}: PropsWithChildren<Props<T>>): ReactElement => {
    const presetsConfig: Record<
        Preset,
        {
            position: PopupPosition;
            offset: PopupOffset;
            
        }
    > = {
        'button-icon': {
            position: 'center-right--bottom',
            offset: {
                x: '0px',
                y: '10px',
            },
        },
        dropdown: {
            position: 'center-right--bottom',
            offset: {
                x: '0px',
                y: '4px',
            },
        },
        'checkbox-icon': {
            position: 'center-right--bottom',
            offset: {
                x: '-8px',
                y: '12px',
            },
        },
        'table-item': {
            position: 'center-left--bottom',
            offset: {
                x: '9%',
                y: '12px',
            },
        },
        'question-icon': {
            position: 'center-left--bottom',
            offset: {
                x: '140px',
                y: '22px',
            }
        },
        'question-icon-top': {
            position: 'center-left--bottom',
            offset: {
                x: '140px',
                y: '-205px',
            }
        },
        'pending-user': {
            position: 'center-left--bottom',
            offset: {
                x: '160px',
                y: '0px',
            }
        }
    };

    const triggerRef = useRef<T>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const portalElement = getPortalElement(triggerRef.current, 'tooltip');
    const { style, shown, enable, disable, enabled } = usePopup(
        triggerRef,
        contentRef,
        portalElement,
        {
            offset: preset ? presetsConfig[preset].offset : offset,
            position: preset ? presetsConfig[preset].position : position,
            trigger: trigger || 'hover',
        }
    );

    useEffect(() => {
        if (disabled) {
            disable();
        } else {
            enable();
        }
    }, [disable, disabled, enable]);

    const getChildren = () => {
        if (typeof children === 'function') {
            return children(triggerRef);
        }
        if (children) {
            // @ts-ignore
            return cloneElement(children, {
                ref: triggerRef,
            });
        }

        return null;
    };

    return (
        <>
            {getChildren()}
            {shown && enabled && (
                <TooltipContent
                    ref={contentRef}
                    className={className}
                    style={{...style, ...propStyle}}
                    label={label}
                    portalElement={portalElement}
                    isHtmlContent={isHtmlContent}
                    dataTest={dataTest}
                />
            )}
        </>
    );
};
