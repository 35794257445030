import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import {
    FiltersApplyButton,
    FiltersClearButton,
    FiltersFieldSet,
    FiltersPopupActions,
    FiltersPopupBody,
    FiltersPopupFieldSets,
} from '../../../ui/components/Filters';
import { PortableTeamsWithStore2 } from '../../portableTeams';

interface Props {
    teamIds: number[];
    onApplyFilter(selectedTeams: number[]): void;
}

export const AiAssessmentFilters: FC<Props> = props => {
    const { teamIds, onApplyFilter } = props;
    const [selectedTeams, setSelectedTeams] = useState<number[]>(teamIds || []);
    const totalFiltersSelected = selectedTeams.length;

    const handleClickClear = useCallback(
        () => setSelectedTeams([]),
        [setSelectedTeams]
    );

    const handleClickApplyFilter = useCallback(
        () => onApplyFilter(selectedTeams),
        [selectedTeams, onApplyFilter]
    );

    return (
        <FiltersPopupBody>
            <FiltersPopupFieldSets>
                <FiltersFieldSet title="Teams" width={264} minHeight={244} dataTest="teams-section">
                    <PortableTeamsWithStore2
                        selectedTeams={selectedTeams}
                        onChangeSelectedTeams={setSelectedTeams}
                    />
                </FiltersFieldSet>
            </FiltersPopupFieldSets>
            <FiltersPopupActions>
                <FiltersClearButton disabled={!totalFiltersSelected} onClick={handleClickClear} />
                <FiltersApplyButton onClick={handleClickApplyFilter} />
            </FiltersPopupActions>
        </FiltersPopupBody>
    );
};
