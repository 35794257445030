import styled from 'styled-components';

export const Subheader = styled.div`
    display: flex;
    font-size: 13px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 10px;
`;

export const Title = styled.div`
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-1);
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const RoleplayCard = styled.div<{isDotMenuVisible: boolean}>`
  width: 290px;
  height: 120px;
  margin-right: 8px;
  margin-top: 8px;
  padding: 16px;
  padding-right: 12px;
  border-radius: 4px;
  border: solid 1px var(--ps-grey-4);
  background-color: var(--ps-white-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .dot-container {
    visibility: hidden;

    .dot-icon-container {
        svg {
            min-width: 16px !important;
            height: 16px;
        }

        width: 11px;
        height: 24px;
    }
  }
  
  :hover {
     box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);

     .dot-container {
        visibility: ${props => props.isDotMenuVisible ? 'visible' : 'hidden'};
     }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between
`;

export const SelectionTitle = styled.div`
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-black-main);
    padding-bottom: 8px;
`;

export const SingleTextLine = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    height: 8px;
    background-color: var(--ps-grey-4);
    animation: pulse 1.5s ease-in-out infinite;

    @keyframes pulse {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 0.2;
        }
    }
`;

export const SkeletonContainer = styled.div`
    display: flex;
`;

export const SelectionTitleSkeleton = styled(SingleTextLine)`
    margin-top: 6px;
    margin-bottom: 12px;
`;

export const NoRoleplayLink = styled.div`
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    margin-left: 15px;
    align-items: center;
`;

export const Text = styled.div`
font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  margin-top: 17px;
`;

export const OwnerName = styled.div`
    max-width: 93px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const DotsIconContainer = styled.div<{ disabled: boolean }>`
    height: 100%;
    padding: 0 6px 0 4px;
    width: 22px;
    cursor: pointer;
    color: ${props =>
        props.disabled ? 'var(--ps-grey-1)' : 'var(--ps-white-1)'};
    &:hover {
        color: ${props =>
            props.disabled ? 'var(--ps-grey-1)' : 'var(--ps-white-1)'};
    }
    visibility: hidden;
`;

export const NameContainer = styled.div`
font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: var(--ps-grey-1);
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;

`;