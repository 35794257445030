import React, { FC, useEffect } from 'react';
import { useCalculatePreSelectedPractisSets } from '../../../../../../portablePractisSets/tools';
import { PortablePractisSetsWithStore } from '../../../../../../portablePractisSets';
import { useSelectMultiplePractisSets } from '../../../../../../portablePractisSets/store/hors/withPractisSets/services';
import { PractisSetWithDueDate } from '../../../../../../../constants/interfaces/Draft';

const FilterPractisSets: FC<{
    practisSetDueDates: PractisSetWithDueDate[] | undefined;
    setInitialPractisSets?(practisSetId: PractisSetWithDueDate[]): void;
}> = ({ practisSetDueDates, setInitialPractisSets }) => {
    const setPractisSets = useSelectMultiplePractisSets();
    const calculatePreSelectedPractisSets = useCalculatePreSelectedPractisSets();

    useEffect(() => {
        if (setInitialPractisSets) {
            let practisSetsToSet = practisSetDueDates ?? []
            setInitialPractisSets(practisSetsToSet);
            setPractisSets(practisSetsToSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatePreSelectedPractisSets]);

    return (
        <PortablePractisSetsWithStore
            showSave={false}
            showSelectActions={true}
            showSearch={true}
            resetSelectedOnUnmount={true}
        />
    );
};

export default FilterPractisSets;
