import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarReports = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0h9v5h5v11H1V0zm10 .19V4h4L11 .19zM4 3h4v2H4V3zm2 5H4v1h2V8zm-2 3h2v1H4v-1zm9-3H8v1h5V8zm-5 3h3v1H8v-1z" fill="#fff"/>
            </svg>
        );
    }
);

export default SidebarReports;
