import React, { useCallback } from 'react';
import styled from 'styled-components';
import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { TEXT_STYLES } from './styles';
import { CompanyItem } from './types';

const Root = styled.div<{ practis: boolean }>`
    height: 56px;
    width: 272px;
    color: ${props => props.theme.Colors.dark};
    display: flex;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    border-radius: 4px;
    &:hover {
        background: ${props => props.theme.Colors.whiteTwo};
    }
    &:active {
        opacity: 0.7;
    }
`;

const Logo = styled.div<{ practis: boolean }>`
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
        height: ${props => (props.practis ? '31px' : '100%')};
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
`;

const Title = styled.div`
    color: ${props => props.theme.Colors.dark};
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 600;
`;

const SubTitle = styled.span`
    ${TEXT_STYLES}
    margin-left: 12px;
`;

export const CompaniesDropdownItem: React.FC<{
    company: CompanyItem;
    dataTest?:string;
    onClick(company: CompanyItem): void;
}> = ({ company, onClick, dataTest }) => {

    const handleClick = useCallback(() => onClick(company), [company, onClick]);
    const { logo, logoUrl, name, subTitle } = company;
    const mediaUrl = logo || logoUrl;
    return (
        <Root data-test={dataTest} practis={!!company.practis} onClick={handleClick}>
            <Logo practis={!!company.practis}>
                {mediaUrl ? (
                    <img src={mediaUrl} alt="org" />
                ) : (
                    <AvatarPlaceholder profile={company} size="sm" />
                )}
            </Logo>
            <Title>{name}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Root>
    );
};
