import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Scenario } from '../../../../../constants/interfaces/Scenario';
import { useModifyScenarioService } from '../../store/services';
import { EditModeContext } from '../../NewScenario';
import { EditModeValues } from '../../../../../constants/enums/EditModeValues';
import { useLabelsState } from '../../../../../features/labels/store/states';
import { useDownloadScenarioReportService } from '../../../../../features/library/store/services';
import { usePortableLabelsState } from '../../../../../features/portableLabels/store/states';
import { useShowMessage } from '../../../../../ui/components/ErrorMessages/ErrorMessages';
import { LabelsAction } from '../../../../../ui/components/table-wrapper/table/TableAssignOptions/actions/LabelsAction';
import { Tooltip } from '../../../../../ui/components/Tooltip';
import DownloadPDF from '../../../../../ui/icons/DownloadPDF';
import { Input } from '../../../../../ui/components/input';

const Container = styled.div`
    padding: 15px 0;
    display: flex;
`;

const TextInputContainer = styled.div`
    flex: 1;
`;

const LabelsContainer = styled.div`
    width: 128px;
    margin-left: 8px;
`;

const DownloadContainer = styled.div<{ disabled?: boolean }>`
    width: 40px;
    height: 40px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: ${props => props.theme.Colors.whiteFive};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &:active {
        opacity: ${props => (props.disabled ? '1' : '0.7')};
    }
`;

const DownloadIcon = styled.div<{ disabled?: boolean }>`
    width: 13px;
    height: 16px;
    display: flex;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.theme.Colors.steelGrey};
`;

const TitleTextInput = styled(Input)`
    padding: 12px 16px;
    font-size: 13px;
    font-weight: normal;
`;

const ActionPanel: FC<{ scenario: Scenario, titleError: boolean }> = ({ scenario, titleError }) => {
    const modifyScenario = useModifyScenarioService();
    const edit = useContext(EditModeContext);
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const showMessage = useShowMessage();
    const downloadScenario = useDownloadScenarioReportService();
    const disableDownload = !scenario.id;

    useEffect(() => {}, []);

    const handleSaveScenarioLabels = () => {
        modifyScenario(labels.assignedLabels, 'updatedLabels');
        showMessage(`labels have been assigned to Scenario`, 'success');
    };

    const handleDownloadAsPDF = (id?: number) => {
        if (!id) return;
        downloadScenario(id);
    };

    return (
        <Container>
            <TextInputContainer>
                <TitleTextInput
                    placeholder="Scenario Title"
                    height={'40px'}
                    maxLength={100}
                    handleChange={(event: any) =>
                        modifyScenario(event.target.value, 'title')
                    }
                    value={scenario.title}
                    readOnly={edit.mode === EditModeValues.VIEW}
                    error={titleError}
                    dataTest='scenario-title'
                />
            </TextInputContainer>
            <LabelsContainer>
                <LabelsAction
                    title={'Labels'}
                    labels={labelsList}
                    disabled={edit.mode === EditModeValues.VIEW}
                    onAssignLabelsSubmit={handleSaveScenarioLabels}
                    containerLeftPosition={'unset'}
                    dataTest='scenario-labels'
                />
            </LabelsContainer>
            <Tooltip label={'Download as PDF'} preset={'button-icon'}>
                <DownloadContainer
                    disabled={disableDownload}
                    onClick={() => {
                        handleDownloadAsPDF(scenario.id);
                    }}
                    data-test='download-scenario-as-pdf'
                >
                    <DownloadIcon disabled={disableDownload}>
                        <DownloadPDF />
                    </DownloadIcon>
                </DownloadContainer>
            </Tooltip>
        </Container>
    );
};

export default ActionPanel;
