import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const User = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 48 48"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path fill="currentColor" fillRule="evenodd" d="M43.616 35.906c-1.337-1.203-12.154-3.808-14.258-4.306 5.428-2.856 8.786-9.77 8.787-16.286C38.145 6.857 35.572 0 23.996 0 12.421 0 9.848 6.857 9.848 15.314c0 6.542 3.388 13.48 8.855 16.315-2.436.565-12.923 3.054-14.288 4.277C1.957 38.104.962 44.701 3.42 48l20.678-.005 20.49.005c2.437-3.3 1.466-9.896-.971-12.094zM13.006 15.37c0-6.836 1.831-12.38 10.99-12.38s10.99 5.543 10.99 12.38c0 5.688-2.97 11.973-7.844 13.944v5.194h1.493c5.925 1.242 11.343 2.958 12.96 3.718 1.01 1.14 1.647 4.506 1.014 6.775l-18.514-.005-18.706.005c-.637-2.269.02-5.645 1.046-6.777 1.61-.772 7.12-2.486 13.117-3.716h1.306v-5.19c-4.88-1.967-7.853-8.257-7.853-13.948z" clipRule="evenodd"/>
            </svg>
        );
    }
);

export default User;
