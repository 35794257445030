import { getConfig } from '../tools/config';
import Storage from './Storage';
import { clearAuthData } from '../helpers/functions/auth-helpers';

const axios = require('axios');

export interface LoginInterface {
    email: string;
    password: string;
}

export class Http {
    static get(path: string, data?: any) {
        return this.request('get', path, data);
    }

    static getProtected(path: string, data?: any) {
        const token = Storage.get('token');
        return this.request('get', path, data, token);
    }

    static postProtected(path: string, data?: any) {
        const token = Storage.get('token') || Storage.get('temp_token');
        return this.request('post', path, data, token);
    }

    static deleteProtected(path: string, data?: any) {
        const token = Storage.get('token');
        return this.request('delete', path, data, token);
    }

    static post(path: string, data?: any, token?: boolean) {
        return this.request('post', path, data, token);
    }

    static put(path: string, data: any) {
        return this.request('put', path, data);
    }

    static putProtected(path: string, data?: any, useTempToken = false) {
        const token = useTempToken
            ? Storage.get('temp_token')
            : Storage.get('token');
        return this.request('put', path, data, token);
    }

    static request(method: string, path: string, data: any, token?: any) {
        return axios({
            method: method,
            url: `${getConfig().API_BASE_URL}${path}`,
            data: data,
            headers: this.generateHeaders(token),
        })
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                if (error.response) {
                    let message = '';
                    switch (error.response.status) {
                        case 401:
                            clearAuthData();
                            message = error.response.data.message;
                            break;
                        case 400:
                        case 403:
                        case 404:
                        case 500:
                            message = error.response.data.message;
                            break;
                        default:
                            message = 'Unexpected Error';
                    }
                    return {
                        status: 'error',
                        code: error.response.status,
                        errorCode: error.response.data
                            ? error.response.data.code
                            : 0,
                        message,
                        details: error.response.data
                            ? error.response.data.details
                            : [],
                    };
                } else if (error.request) {
                    return {
                        status: 'error',
                        code: 504,
                        message: 'Connection Error',
                    };
                } else {
                    return {
                        status: 'error',
                        code: error.response.status,
                        message: 'Unexpected Error',
                    };
                }
            });
    }

    static generateHeaders = (token?: any, customHeaders?: Object) => {
        let contentType = { 'Content-Type': 'application/json' };
        let Client = { Client: 'WEB' };
        let authorization = token ? { Authorization: 'JWT ' + token } : {};
        let additionalHeaders = customHeaders ? customHeaders : {};
        return {
            ...contentType,
            ...authorization,
            ...Client,
            ...additionalHeaders,
        };
    };
}
