import {
    ACTIONS,
    clearScenarioReps,
    getScenarioRepsFailure,
    getScenarioRepsStart,
    getScenarioRepsSuccess
} from './actions';
import { ScenarioRepsState } from './states';

type ScenarioRepsActions =
    | ReturnType<typeof getScenarioRepsStart>
    | ReturnType<typeof getScenarioRepsSuccess>
    | ReturnType<typeof getScenarioRepsFailure>
    | ReturnType<typeof clearScenarioReps>;

export const scenarioRepsReducer = (
    state = { isLoading: false },
    action: ScenarioRepsActions
): ScenarioRepsState => {

    switch(action.type) {
        case ACTIONS.GET_SCENARIO_REPS_START:
            return {
                ...state,
                isLoading: true
            };
        case ACTIONS.GET_SCENARIO_REPS_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: ''
            };
        case ACTIONS.GET_SCENARIO_REPS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case ACTIONS.CLEAR_SCENARIO_REPS:
            return {
                isLoading: false
            };
        default:
            return state;
    }
}