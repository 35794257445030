export const TEXT_FIELD = 'TEXT_FIELD';
export const AVATAR_FIELD = 'AVATAR_FIELD';
export const BADGE_FIELD = 'BADGE_FIELD';
export const CUSTOM_FIELD = 'CUSTOM_FIELD';
export const LABEL_TAGS = 'LABEL_TAGS';
export const LIST_ACTIONS = 'LIST_ACTIONS';

export const fieldTypes = {
    TEXT_FIELD,
    AVATAR_FIELD,
    BADGE_FIELD,
    CUSTOM_FIELD,
    LABEL_TAGS,
    LIST_ACTIONS,
};
