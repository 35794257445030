import { SearchParams } from '../../../../constants/interfaces/filters';
import {
    getPractisChallengesCountSuccess,
    getPractisScenarioCountSuccess,
    getSinglePractisSetFailure,
    getSinglePractisSetStart,
    getSinglePractisSetSuccess,
    modifyPractisSetAction,
    practisContentRepCountDecrease,
    practisContentRepCountIncrease,
    resetPractisSetsAction,
    restorePractisSetFromTempAction,
    searchPractisChallengesFailure,
    searchPractisChallengesStart,
    searchPractisChallengesSuccess,
    searchPractisScenariosFailure,
    searchPractisScenariosStart,
    searchPractisScenariosSuccess,
    storePractisSetTempCopyAction,
    updatePractisContentAction,
} from './actions';
import {
    PractisSetContent,
    PractisSets,
} from '../../../../constants/interfaces/PractisSets';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    useGetPractisSetApi,
    useSearchChallengesApi,
    useSearchScenariosApi,
    useGetPractisSetContentApi,
} from '../../../../api';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { createSearchLibraryParams } from '../../../../features/library/tools';

export const useSearchPractisScenarios = () => {
    const dispatch = useDispatch();
    const searchScenariosApi = useSearchScenariosApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams) => {
            const searchScenariosParams =
                createSearchLibraryParams(searchParams);

            dispatch(searchPractisScenariosStart());
            searchScenariosApi(searchScenariosParams)
                .then(data => {
                    dispatch(searchPractisScenariosSuccess(data.items));
                    if (
                        !searchParams?.searchTerm &&
                        !searchParams?.labelIDs?.length
                    ) {
                        dispatch(getPractisScenarioCountSuccess(data.count));
                    }
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchPractisScenariosFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchScenariosApi, showMessage]
    );
};

export const useSearchPractisChallenges = () => {
    const dispatch = useDispatch();
    const searchChallengesApi = useSearchChallengesApi();
    const showMessage = useShowMessage();
    return useCallback(
        (searchParams: SearchParams) => {
            const searchChallengesParams =
                createSearchLibraryParams(searchParams);
            dispatch(searchPractisChallengesStart());
            searchChallengesApi(searchChallengesParams)
                .then(data => {
                    dispatch(searchPractisChallengesSuccess(data.items));
                    if (
                        !searchParams?.searchTerm &&
                        !searchParams?.labelIDs?.length
                    ) {
                        dispatch(getPractisChallengesCountSuccess(data.count));
                    }
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchPractisChallengesFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchChallengesApi, showMessage]
    );
};

export const useGetSinglePractisSetService = () => {
    const dispatch = useDispatch();
    const getPractisSetApi = useGetPractisSetApi();
    const getPractisSetContent = useGetPractisSetContentApi();
    const showMessage = useShowMessage();
    return useCallback(
        (practisSetid: number) => {
            dispatch(getSinglePractisSetStart());
            getPractisSetApi(practisSetid)
                .then(data => {
                    getPractisSetContent(practisSetid)
                        .then(practisSetContentData => {
                            const practisSetData = {
                                ...data,
                                content: practisSetContentData,
                            };
                            dispatch(
                                getSinglePractisSetSuccess(practisSetData)
                            );
                        })
                        .catch(() => {
                            getSinglePractisSetSuccess(data);
                        });
                })
                .catch((error: ErrorResult) => {
                    dispatch(getSinglePractisSetFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, getPractisSetApi, getPractisSetContent, showMessage]
    );
};

export const useUpdatePractisContentService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (data: PractisSetContent[]) => {
            dispatch(updatePractisContentAction(data));
        },
        [dispatch]
    );
};

export const usePractisContentRepCountIncrease = () => {
    const dispatch = useDispatch();
    return useCallback(
        (itemId: number) => {
            dispatch(practisContentRepCountIncrease(itemId));
        },
        [dispatch]
    );
};

export const usePractisContentRepCountDecrease = () => {
    const dispatch = useDispatch();
    return useCallback(
        (itemId: number) => {
            dispatch(practisContentRepCountDecrease(itemId));
        },
        [dispatch]
    );
};

export const useResetPractisSetService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(resetPractisSetsAction());
    }, [dispatch]);
};

export const useStorePractisSetTempCopyService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(storePractisSetTempCopyAction());
    }, [dispatch]);
};

export const useRestorePractisSetFromTempActionService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(restorePractisSetFromTempAction());
    }, [dispatch]);
};

export const useModifyPractisSetService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: any, field: keyof PractisSets, silent?: boolean) => {
            dispatch(modifyPractisSetAction(value, field, silent));
        },
        [dispatch]
    );
};