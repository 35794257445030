import { SET_TOKEN } from './actions';

export interface AuthInterface {
    token: string;
}

const initialState: any = {
    token: '',
};

export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        default:
            return state;
    }
};
