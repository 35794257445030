import { forwardRef } from 'react';

import { IconProps } from './types';

export const Camera = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#p0w82laf1a)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="m15.25 6.083-1.583-1.916H7.751L6.167 6.083l-.5.584H3.251v9.166h15V6.667h-2.417l-.583-.584zM13.72 2.5c.495 0 .964.22 1.28.6L16.584 5h1.667c.92 0 1.666.746 1.666 1.667v9.166c0 .92-.746 1.667-1.666 1.667h-15c-.92 0-1.667-.746-1.667-1.667V6.667C1.584 5.747 2.33 5 3.251 5h1.666l1.584-1.9c.317-.38.786-.6 1.28-.6h5.939zm-.886 7.917a2.063 2.063 0 0 0-2.083-2.084 2.063 2.063 0 0 0-2.084 2.084c0 1.166.917 2.083 2.084 2.083a2.063 2.063 0 0 0 2.083-2.083zm-5.833 0a3.734 3.734 0 0 1 3.75-3.75 3.734 3.734 0 0 1 3.75 3.75 3.734 3.734 0 0 1-3.75 3.75A3.734 3.734 0 0 1 7 10.417z"
                        fill="#fff"
                    />
                </g>
                <defs>
                    <clipPath id="p0w82laf1a">
                        <path
                            fill="#fff"
                            transform="translate(1.584 2.5)"
                            d="M0 0h18.333v15H0z"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default Camera;

