import React, { FC, useRef, useState } from 'react';
import {
    ActionButton,
    ActionItem,
} from '../../../../ui/components/ActionButton';
import { CheckPermission } from '../../../permissions';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { PopupComponent } from '../../../../ui/components/Popup';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../portableLabels/store/hors/withLabels/services';
import {
    useCalculateDeletedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../../labels/tools';
import { AssignLabels } from '../../../portableLabels/templates/AssignLabels';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { InvitationActionsHelper } from '../../tools';
import { UserV2 } from '../../../../constants/interfaces/User';

const InvitationsListActions: FC<{
    invitation: UserV2;
    actionsHelper: InvitationActionsHelper;
    onViewProfile: (invitation: UserV2) => void;
    onCopyInviteText: (invitation: UserV2) => void;
    handleRevokeInvitation: (invitationIds: number[]) => void;
    handleResendInvitation: (invitationIds: number[]) => void;
    assignLabelsToUser: (
        addedLabelIds: number[],
        deletedLabelIds: number[]
    ) => void;
    isHidden?: boolean;
}> = ({
    invitation,
    actionsHelper,
    onViewProfile,
    onCopyInviteText,
    handleRevokeInvitation,
    assignLabelsToUser,
    handleResendInvitation,
    isHidden,
}) => {
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const calculatePreSelectedLabels = useCalculatePreSelectedLabelsForSingleItem();
    const getDeletedLabels = useCalculateDeletedLabels();

    const handleSetPreselectedLabels = () => {
        const selectedLabelIds = invitation.labels || [];
        const preAssignedLabels = calculatePreSelectedLabels(
            selectedLabelIds,
            labelsList.data
        );
        setSelectLabels(preAssignedLabels);
        saveLabels();
    };

    const handleSaveSelectedLabels = (labelIDs: number[]) => {
        const addedLabelIds = labelIDs.filter(id => id > 0);
        const deletedLabelIds = getDeletedLabels(labelIDs)
            .filter(labelId => invitation.labels.includes(labelId));

        assignLabelsToUser(addedLabelIds, deletedLabelIds);
    };

    const [assignSingleLabelOpen, setAssignSingleLabelOpen] = useState<
        null | number | undefined
    >(null);

    const triggerRef = useRef(null);

    return (
        <span>
            <div ref={triggerRef}>
                <ActionButton customWidth={168} isHidden={isHidden} dataTest="list-item-menu">
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.VIEW_USER_PERFORMANCE]}
                    >
                        <ActionItem onClick={() => onViewProfile(invitation)} dataTest="view-profile-action">
                            View Profile
                        </ActionItem>
                    </CheckPermission>
                    {actionsHelper.canAssignInvitationLabel() && !!labelsList.data.items.length && (
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                setAssignSingleLabelOpen(invitation.id);
                                handleSetPreselectedLabels();
                            }}
                            dataTest="assign-labels-action"
                        >
                            Assign Labels
                        </ActionItem>
                    )}
                    {actionsHelper.canResendInvitation() && (
                        <ActionItem
                            onClick={() => {
                                handleResendInvitation([invitation.id]);
                            }}
                            dataTest="resend-invite-action"
                        >
                            Resend Invite
                        </ActionItem>
                    )}
                    <ActionItem onClick={() => onCopyInviteText(invitation)} dataTest="copy-invite-text-action">
                        Copy Invite Text
                    </ActionItem>
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.REVOKE_INVITATION]}
                    >
                        <ActionItem
                            destructive={true}
                            onClick={() => {
                                handleRevokeInvitation([invitation.id]);
                            }}
                            dataTest="revoke-action"
                        >
                            Revoke
                        </ActionItem>
                    </CheckPermission>
                </ActionButton>
            </div>
            <PopupComponent
                triggerRef={triggerRef}
                open={assignSingleLabelOpen === invitation.id}
                onDismiss={() => {
                    setAssignSingleLabelOpen(null);
                }}
            >
                <AssignLabels
                    onLabelsChange={handleSaveSelectedLabels}
                    onSuccessApply={() => {
                        setAssignSingleLabelOpen(null);
                    }}
                    withBorder={true}
                />
            </PopupComponent>
        </span>
    );
};

export default InvitationsListActions;
