import { Reducer } from 'redux';
import { useSelector } from 'react-redux';

import { ACTIONS, PractisSetFilterActions } from './actions';
import { initialPractisSetFilterState } from './constants';
import { PractisSetFilterState } from './types';

export const practisSetFilterReducer: Reducer<
    PractisSetFilterState,
    PractisSetFilterActions
> = (state = initialPractisSetFilterState, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_PRACTIS_SET:
            return {
                ...state,
                selectedPractisSetId: action.selectedPractisSetId,
            };
        case ACTIONS.DISABLE_PRACTIS_SET_FILTER:
            return {
                ...state,
                isDisabled: action.isDisabled,
            }

        default:
            return state;
    }
};

export const usePractisSetFilterState = () =>
    useSelector(state => state.practisSetFilterReducer);
