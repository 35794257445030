import React, { forwardRef } from 'react';
import { IconProps } from './types';

type CloseType = 'thin' | 'solid';

export const Close = forwardRef<
    SVGSVGElement,
    IconProps & { type?: CloseType }
>(({ className, shadow, type = 'solid' }, ref) => {
    const iconContent: Record<CloseType, JSX.Element> = {
        solid: (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 492 492"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    fill="currentColor"
                    fillRule="evenodd"
                    width="100%"
                    height="100%"
                >
                    <path
                        d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
			c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
			c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
			L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
			c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
			c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
			c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                    />
                </g>
            </svg>
        ),
        thin: (
            <svg
                ref={ref}
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 12 12"
            >
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M0 0H12V12H0z"
                        transform="translate(-636 -150) translate(636 150)"
                    />
                    <path
                        fill="currentColor"
                        fillRule="nonzero"
                        d="M10.902 1.098c.116.115.129.295.039.424l-.039.047L6.471 6l4.431 4.431c.13.13.13.341 0 .471-.115.116-.295.129-.425.039l-.046-.039L6 6.471l-4.431 4.431c-.13.13-.341.13-.471 0-.116-.115-.129-.295-.039-.424l.039-.047L5.529 6 1.098 1.569c-.13-.13-.13-.341 0-.471.115-.116.295-.129.425-.039l.046.039L6 5.529l4.431-4.431c.13-.13.341-.13.471 0z"
                        transform="translate(-636 -150) translate(636 150)"
                    />
                </g>
            </svg>
        ),
    };

    return iconContent[type];
});

export default Close;
