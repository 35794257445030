 export const COLORS: {[key: string]: string} = {
    'white-1': '#ffffff',
    'white-2': '#f4f4f4',
    'white-3': '#e2e2e2',
    'white-4': '#f7f8f9', // also paleGreyTwo
    'white-5': '#0000000d', // rgb blackTwo5
    'white-6': '#e4f2fb',
    'blue-main': '#4aa9e2', // also primaryBlue,1eb0ff dodgerBlue,3fbcff
    'blue-1': '#015895', //search by code
    'blue-2': '#73bbe5', // '#4aa9e280' rgb  darkSkyBlue50
    'blue-3': '#31a4e9',
    'blue-4': '#5faad6', 
    'blue-5': '#5cc7ff',
    'blue-6': '#4893bf',
    'blue-7': '#3d93c7',
    'blue-8': '#5eb7ed',
    'blue-10': '#4aa9e280', // rgb transparentSoftBlueTwo
    'blue-11': '#409ed6',
    'blue-12': '#4aa9e226', // also darkSkyBlue15
    'grey-1': '#6d7f8c',
    'grey-2': '#b1c0cb',
    'grey-3': '#f8f8f8',
    'grey-4': '#e1e8ee',
    'grey-5': '#f4f6f7',
    'grey-6': '#ebf1f3',
    'grey-7': '#f6f6f6',
    'grey-8': '#9aa6b6',
    'grey-9': '#f2f2f2',
    'grey-10': '#9da2a8',
    'grey-11': '#0000008a',
    'grey-12': '#77777740',
    'grey-13': '#d2dfe6',
    'grey-14': '#6d7f8c66', // steelGrey40 rgb 
    'grey-15': '#a5b5c0',
    'grey-16': '#64717b',
    'grey-17': '#626365',
    'grey-18': '#b1c0cb26', //rgb cloudyBlue15
    'grey-19': '#99a9b4',
    'grey-20': '#0000001e',
    'grey-21': '#EAEAEA',
    'grey-22': '#93a6b4',
    'red-main': '#ec513d', // '#e55340'
    'red-1': '#fa6653', 
    'red-2': '#ff7a69',
    'orange-main': '#f0a803', // not used yet
    'orange-1': '#a47200', // '#f5ba33'
    'orange-2': '#ffebc3', // not used yet '#f5ba333d', // rgb macaroniAndCheeseOpaque
    'green-main': '#15cba9',
    'green-1': '#066150',
    'green-2': '#d6f7e8', // search by code
    'green-3': '#51d76a',
    'green-4': '#72dbc8', //also green, mint
    'graphite-1': '#222431', // also darkThree, rgb 
    'graphite-2': '#171923',
    'graphite-3': '#292b39', // search by code
    'graphite-4': '#343850',
    'black-main': '#212121',
    'black-1': '#181923',  
    'black-2': '#323232',
    'black': '#000000', 
    'pink-1': '#fe5c6c',
    'pink-2': '#e14a59', // also fadedRedTwoMobile '#e14a59'
    'pink-3': '#cb3a48',
};

export const SCREEN_SIZES = {
    sm: '576px',
    md: '768px',
    ld: '992px',
    xl: '1200px',
};

export const BREAK_POINTS = {
    mid: 1368,
    small: 768,
    smaller: 640,
    responsiveLg: 1920,
    responsiveMd: 1440,
    responsiveSm: 1024,
    responsiveSmPlus: 1245,
}

export const SPACINGS = {
    md: 8
}

export const FONTS = {
    manrope: 'Manrope'
}