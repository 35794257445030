import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MutateLabelsParams } from '../../../api/labels/types';
import EditLabelActions from './EditLabelActions';
import AddNewLabelInput from './AddNewLabelInput';
import LabelInputWarning from './LabelInputWarning';
import { Label } from '../../../constants/interfaces/Label';
import { enterPressed } from '../../../helpers/functions/key-down-helpers';

const AddLabelSection = styled.div`
    margin-bottom: 8px;
`;

const AddLabels: FC<{
    saveLabel?: (params: MutateLabelsParams) => Promise<any>;
    setEditing: () => void;
    labels?: Label[];
}> = ({ saveLabel, setEditing, labels }) => {
    const [labelName, setLabelName] = useState('');
    const [labelUnique, setLabelUnique] = useState(true);
    const addLabelDisabled = !labelUnique || labelName.trim().length < 1;
    useEffect(() => {
        if (!labels) return;
        const filteredLabels = labels.filter(
            (label: Label) => label.name === labelName && !label.parentId
        );
        setLabelUnique(filteredLabels.length < 1);
    }, [labels, labelName]);

    const handleCreateLabel = () => {
        saveLabel &&
            saveLabel({ name: labelName })
                .then(result => {
                    setEditing();
                    setLabelName('');
                })
                .catch(e => {});
    };

    return (
        <AddLabelSection>
            <AddNewLabelInput
                name={labelName}
                setName={setLabelName}
                onKeyPress={e => {
                    if (enterPressed(e) && !addLabelDisabled) {
                        handleCreateLabel();
                    }
                }}
            />
            {!labelUnique && <LabelInputWarning />}
            <EditLabelActions
                onCancel={() => {
                    setLabelName('');
                    setEditing();
                }}
                confirmDisabled={addLabelDisabled}
                onConfirm={handleCreateLabel}
            />
        </AddLabelSection>
    );
};

export default AddLabels;
