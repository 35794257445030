import { FC, useState } from 'react';
import {
    SelectLabel,
    SelectInput,
    SelectMenuItem,
    SelectMenu,
} from '../../../../SelectDropdown';

import UpArrow from '../../../../../icons/UpArrow';
import DownArrow from '../../../../../icons/DownArrow';
import { TableAssignOptionsMenuItem } from '../interfaces/TableAssignOptionsMenuItem';
import { Variables } from '../../../../../../theme/variables';
import { Tooltip } from '../../../../Tooltip';
import { CheckPermission } from '../../../../../../features/permissions';
import {
    Container,
    IconHolder,
    TableAssignOptionsStyledSelectForm,
} from './styles';

export const TableAssignOptionsSelectDropdown: FC<{
    items?: Array<any>;
    showUpArrow?: boolean;
    onChange?: any;
    disabled?: boolean;
    disabledLabel?: string;
    hideHint?: boolean;
}> = ({
    items,
    showUpArrow,
    disabled,
    disabledLabel = 'Select one or more items below first',
    hideHint,
    onChange,
}) => {
    const [show, setShow] = useState(false);
    const selectToggleHandler = () => {
        if (!disabled) {
            setShow(!show);
        }
    };

    const render = () => {
        return (
            <TableAssignOptionsStyledSelectForm
                variant="light"
                open={show}
                className={disabled ? 'is-disabled' : undefined}
                disabled={disabled}
            >
                <SelectLabel
                    label="Action"
                    color={disabled ? Variables.Colors.cloudyBlue : undefined}
                    dataTest="assign-table-action-label"
                />
                <IconHolder disabled={disabled}>
                    {show || showUpArrow ? <UpArrow /> : <DownArrow />}
                </IconHolder>
                <SelectInput toggleSelect={() => selectToggleHandler()} />

                <Container open={show} toggleOpen={setShow}>
                    <SelectMenu menuPosition="left" width="200px">
                        {items &&
                            items.map((item: TableAssignOptionsMenuItem) => {
                                return (
                                    item.show && (
                                        <CheckPermission
                                            key={item.name}
                                            permissions={item.permissions}
                                        >
                                            <SelectMenuItem
                                                key={item.value}
                                                value={item.value}
                                                hideAction={() =>
                                                    setShow(false)
                                                }
                                                onClick={onChange}
                                                disabled={item.disabled}
                                                destructive={item.destructive}
                                                hint={item.hint}
                                                dataTest={item.dataTest}
                                            >
                                                {item.name}
                                            </SelectMenuItem>
                                        </CheckPermission>
                                    )
                                );
                            })}
                    </SelectMenu>
                </Container>
            </TableAssignOptionsStyledSelectForm>
        );
    };

    if (disabled) {
        return !hideHint ? (
            <Tooltip label={disabledLabel} preset={'dropdown'}>
                {render()}
            </Tooltip>
        ) : (
            render()
        );
    }

    return render();
};
