import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { PractisSetWithDueDate } from '../../../constants/interfaces/Draft';
import { Roles } from '../../../constants/interfaces/Roles';

export enum UserRoles {
    USER = 'USER',
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    PRACTIS_ADMIN = 'PRACTIS_ADMIN',
}

export const InviteRoleItems = [
    {
        name: UserRoles.USER,
        permissions: NEW_PERMISSIONS.INVITE_USER,
    },
    {
        name: UserRoles.COMPANY_ADMIN,
        permissions: NEW_PERMISSIONS.INVITE_COMPANY_ADMIN,
    },
];

export const filterInvitationRolesByPermissions = (
    rolesList: Roles[],
    permissions: NEW_PERMISSIONS[]
) => {
    return rolesList
        .filter(role => {
            return InviteRoleItems.find(
                item =>
                    item.name === role.name &&
                    permissions.includes(item.permissions)
            );
        })
        .map(role => {
            return {
                value: role.id,
                name: role.title,
            };
        }).reverse();
};

export const invitationRoles = (
    rolesList: Roles[]
) => {
    return rolesList
        .filter(role => {
            return InviteRoleItems.find(
                item =>
                    item.name === role.name
            );
        })
        .map(role => {
            return {
                value: role.id,
                name: role.title,
            };
        }).reverse();
};

export const getUserRoleNameById = (
    roleId: number | null,
    rolesList: Roles[]
): string | undefined => {
    if (!roleId || !rolesList.length) return undefined;

    const role = rolesList.find(item => item.id === roleId);
    if (!role) return undefined;

    return role.title;
};

export const getUserRoleIdByName = (
    roleName: string | null,
    rolesList: Roles[]
): number | undefined => {
    if (!roleName || !rolesList.length) return undefined;

    const role = rolesList.find(item => item.title === roleName);
    if (!role) return undefined;

    return role.id;
};

export enum UserFields {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    role = 'role',
}

const UserFieldNames = {
    [UserFields.firstName as string]: 'First Name',
    [UserFields.lastName as string]: 'Last Name',
    [UserFields.email as string]: 'Email',
    [UserFields.role as string]: 'Role',
};

const UserFieldValuesByName = {
    [UserFieldNames.firstName as string]: UserFields.firstName,
    [UserFieldNames.lastName as string]: UserFields.lastName,
    [UserFieldNames.email as string]: UserFields.email,
    [UserFieldNames.role as string]: UserFields.role,
};

export function UserFieldValueByName(name?: string): string {
    return (name && UserFieldValuesByName[name]) || name || '';
}

export enum InvitationEmailErrors {
    isEntryInvalid = 'Please edit before selecting',
    emailExistsInSystem = 'User’s email exists in our system',
}

export const handleToggleSelectedItems = (array: any, item: any) => {
    let arrayItems = array ? [...array] : [];

    if (arrayItems.includes(item)) {
        arrayItems = arrayItems.filter(x => x !== item);
    } else if (arrayItems.includes(-item)) {
        arrayItems = arrayItems.filter(x => x !== -item);
        arrayItems.push(item);
    } else {
        arrayItems.push(item);
    }

    return arrayItems;
};

/**
 * 
 * @function handlepsToggleSelectedItems
 * 
 * @param { PractisSetWithDueDate } currentSelectedPractisSets 
 * 
 * @param { number } practisSetIdToToggle 
 * 
 * @returns { PractisSetWithDueDate[] }
 */
export const handleToggleSelectedItemsForPractisSets = (currentSelectedPractisSets: PractisSetWithDueDate[], practisSetIdToToggle: number) => {
    let filteredPractisSetsToReturn = currentSelectedPractisSets ? [...currentSelectedPractisSets] : [];

    if (filteredPractisSetsToReturn.map(x => x.practisSetId).includes(practisSetIdToToggle)) {
        filteredPractisSetsToReturn = filteredPractisSetsToReturn.filter(x => x.practisSetId !== practisSetIdToToggle);
    } else if (filteredPractisSetsToReturn.map(x => x.practisSetId).includes(-practisSetIdToToggle)) {
        filteredPractisSetsToReturn = filteredPractisSetsToReturn.filter(x => x.practisSetId !== -practisSetIdToToggle);
        filteredPractisSetsToReturn.push({ practisSetId: practisSetIdToToggle, dueDate: null });
    } else {
        filteredPractisSetsToReturn.push({ practisSetId: practisSetIdToToggle, dueDate: null });
    }

    return filteredPractisSetsToReturn;
};