import styled from 'styled-components';
import { SIZES } from '../constants';

export const ScrollContainer = styled.div<{ addOverflow?: boolean }>`
    ${props => props.addOverflow && `overflow-y: hidden;`}
    ${props => props.addOverflow && `overflow-x: auto;`}
    transform: rotateZ(180deg);
    direction: rtl;
    display: flex;

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.whiteTwo};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.Colors.whiteThree};
    }
`;

export const TableInner = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    display: inline-table;
    transform: rotateZ(180deg);
    direction: ltr;
    min-width: ${SIZES.MIN_WIDTH}px;

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.whiteTwo};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.Colors.whiteThree};
    }
`;

export const TableCont = styled.div`
    display: block;
    border-radius: 8px;
    background: ${props => props.theme.Colors.white};
    position: relative;
`;

export const InfiniteTableBodyContainer = styled.div`
    overflow: auto;
    min-height: 100%;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
`;
