import lodash from 'lodash';

import { PractisSets, EnrollmentPractisSet } from '../../constants/interfaces/PractisSets';
import { useCallback } from 'react';
import { PractisSetWithDueDate } from '../../constants/interfaces/Draft';
import { DueDateType } from '../../constants/interfaces/DueDates';

export const filterPractisSets = (term: string, statuses?: string[]) => (
    practisSets: PractisSets[]
): PractisSets[] => {
    let filteredItems = [...practisSets];
    if (statuses && statuses.length) {
        filteredItems = filteredItems.filter(item =>
            statuses.includes(item.status)
        );
    }

    if (!term) {
        return filteredItems;
    }

    return filteredItems.filter(
        item => item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
    );
};

type Entity = {
    id: number;
    practisSetIDs: PractisSetWithDueDate[];
    practisSets: PractisSetWithDueDate[];
    roleId: number;
};

export const useCalculatePreSelectedPractisSets = () => {
    return useCallback((selectedItems: number[], entity: Entity[]) => {
        let countSelectedItems: Record<number, number> = {};
        let outputArray: number[] = [];

        const filteredEntities = entity.filter(entityItem => {
            return selectedItems.includes(entityItem.id);
        });
        for (let entity of filteredEntities) {
            for (let practisSetId of entity.practisSets) {
                countSelectedItems[practisSetId.practisSetId] =
                    countSelectedItems[practisSetId.practisSetId] + 1 || 1;
            }
        }

        for (const practisSetId in countSelectedItems) {
            if (countSelectedItems[practisSetId] === selectedItems.length) {
                outputArray.push(Number(practisSetId));
            } else {
                outputArray.push(-Number(practisSetId));
            }
        }

        return outputArray;
    }, []);
};


/**
 * @function isAllPractisSetHaveEqualDueDates
 * @param { PractisSetWithDueDate[] } practisSets 
 * @returns { boolean}
 */
const isAllPractisSetHaveEqualDueDates = (
    practisSets: PractisSetWithDueDate[]
): boolean => {
    const firstPractisSet = practisSets[0];

    return practisSets.every(item => item?.dueDate === firstPractisSet.dueDate);
};


/**
 * @function useFindPractisSetDueDate
 * @param { PractisSetWithDueDate[] } selectedPractisSets
 * @param { number } practisSetId
 * @returns { string | null }
 */
export const useFindPractisSetDueDate = <T extends Entity>() => {
    return useCallback(
        (
            selectedItems: number[],
            entity: T[],
            practisSetId: number
        ): string | null => {
            const filteredEntities = entity.filter(entityItem => {
                return selectedItems.includes(entityItem.id);
            });

            const selectedItemsPractisSets = filteredEntities.map(
                entityItem => entityItem.practisSets
            );

            const selectedItemsArray = lodash.flatten(selectedItemsPractisSets);

            const filteredSelectedItemsArray = selectedItemsArray?.filter(
                practisSet => practisSet.practisSetId === Math.abs(practisSetId)
            );

            // more than one result without same due date
            if (
                filteredSelectedItemsArray?.length > 1 &&
                !isAllPractisSetHaveEqualDueDates(filteredSelectedItemsArray)
            ) {
                return null;
            } else {
                return (
                    filteredSelectedItemsArray.find(item => item?.dueDate)
                        ?.dueDate ?? null
                );
            }
        },
        []
    );
};

export const useCalculateDeletedPractisSets = () => {
    return useCallback((assignedPractisSets: number[], initialPractisSets: number[]) => {
        const deletedPractisSets: number[] = [];

        for (let practisSet of initialPractisSets) {
            if (
                !assignedPractisSets.includes(practisSet) &&
                !assignedPractisSets.includes(-practisSet)
            ) {
                deletedPractisSets.push(
                    practisSet < 0 ? -practisSet : practisSet
                );
            }
        }

        return deletedPractisSets;
    }, []);
};

/**
 * useCalculateDeletedEnrollments
 * @returns Callable function
 */
export const useCalculateDeletedEnrollments = () => {
    return useCallback((enrollmentsData, assignedPractisSets: number[], initialPractisSets: number[]) => {
        const deletedPractisSets: number[] = lodash.xor(assignedPractisSets, initialPractisSets);
        const deletedEnrollmentIds: number[] = []
        
        enrollmentsData.forEach((pset: PractisSets) => {
            if (deletedPractisSets.includes(pset.id as number)) {
                deletedEnrollmentIds.push(pset.enrollmentId as number)
            }
        });

        return deletedEnrollmentIds;
    }, []);
};

/**
 * Returns created and updated enrollments lists with dueDate
 * @param enrollmentsData PractisSets[]
 * @param assignedPractisSets PractisSetWithDueDate[]
 * @param initialPractisSets PractisSetWithDueDate[]
 * @returns  addedPractisSets, updatedEnrollments 
 */
export const calculateCreatedUpdatedEnrollmentIds = (
    enrollmentsData: EnrollmentPractisSet[], 
    assignedPractisSets: PractisSetWithDueDate[], 
    initialPractisSets: PractisSetWithDueDate[]
) => {
    const addedPractisSets: PractisSetWithDueDate[] = [];
    const updatedEnrollments = [];
    
    for (let practisSet of assignedPractisSets) {
        const existingItem = initialPractisSets.find(
            item =>
                item.practisSetId === practisSet.practisSetId
        );

        if (!existingItem) {
            addedPractisSets.push(practisSet);
        } else if (existingItem.dueDate !== practisSet.dueDate) {
            const enrollmentId = enrollmentsData.find((pset) => pset.id === practisSet.practisSetId)?.enrollmentId;
            updatedEnrollments.push({
                enrollmentId: enrollmentId as number,
                dueDate: practisSet.dueDate
            })
        }
    }

    return { addedPractisSets, updatedEnrollments };
}

/**
 *
 * @function practisSetWithDueDatesArrayToDueDatesKeyValues
 *
 * @param { PractisSetWithDueDate[] } practisSetsWithDueDates
 *
 * @returns { DueDateType }
 */
export const practisSetWithDueDatesArrayToDueDatesKeyValues = (
    practisSetsWithDueDates: PractisSetWithDueDate[]
) => {
    const dueDates: DueDateType = {};

    const keys = practisSetsWithDueDates.map(x => x.practisSetId);

    keys.forEach(key => {
        dueDates[Math.abs(key)] =
            practisSetsWithDueDates?.filter(x => x.practisSetId === key)[0]
                ?.dueDate ?? '';
    });

    return dueDates;
};

