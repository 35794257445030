import { FC } from 'react';

import NoLabels from '../../../../../../icons/NoLabels';
import {
    NoLabelsDescription,
    NoLabelsIcon,
    NoLabelsTitle,
    StyledEmptyLabels,
    StyledEmptyLabelsContent,
} from './styles';

const NoAddedLabels: FC = () => {
    return (
        <StyledEmptyLabels>
            <StyledEmptyLabelsContent>
                <NoLabelsIcon>
                    <NoLabels />
                </NoLabelsIcon>
                <NoLabelsTitle>No Labels</NoLabelsTitle>
                <NoLabelsDescription>
                    There are no labels created
                </NoLabelsDescription>
            </StyledEmptyLabelsContent>
        </StyledEmptyLabels>
    );
};

export default NoAddedLabels;
