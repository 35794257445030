import React, { FC, useState } from 'react';
import styled from 'styled-components';
import TextAreaPlaceholderBox from '../../../ui/components/TextAreaPlaceholderBox/TextAreaPlaceholderBox';
import { Button } from '../../../ui/components/Button';
import { Input } from '../../../ui/components/input';
import { Variables } from '../../../theme/variables';

const StyledTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors.steelGrey};
    margin-top: 9px;
    height: 37px;
    margin-bottom: 16px;
`;

const StyledFormArea = styled.div`
    width: 410px;
`;

const StyledActions = styled.div`
    width: 272px;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 8px;
    align-self: flex-end;
`;

const StyledInput = styled(Input)`
    font-family: ${props => props.theme.Fonts.manrope};
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.whiteTwo};
`;

const StyledInputContainer = styled.div`
    margin-bottom: 12px;
`;

export const RequestRedo: FC<{
    fromUser?: string;
    onSend?(data: any): void;
    onClose?(): void;
}> = ({ onSend, onClose, fromUser }) => {
    const [messageData, setMessageData] = useState({
        name: fromUser,
        text: '',
    });

    const handleMessageDataChange = (key: string, value: any) => {
        setMessageData(prevState => ({ ...prevState, [key]: value }));
    };

    return (
        <>
            <StyledTitle>Request a Redo</StyledTitle>
            <StyledContent>
                <StyledDescription>
                    Clicking "Submit" will delete the submission from the list
                    and the User will be asked to redo this challenge.
                </StyledDescription>
                <StyledFormArea>
                    <StyledInputContainer>
                        <StyledInput
                            type="text"
                            name="name"
                            inputPadding="29px 24px 15px 24px"
                            value={messageData.name}
                            label="From"
                            labelFontSize="13px"
                            labelLeftPosition="24px"
                            labelFontWeight={500}
                            maxLength={100}
                            height="40px"
                            handleChange={(e: any) =>
                                handleMessageDataChange('name', e.target.value)
                            }
                        />
                    </StyledInputContainer>
                    <TextAreaPlaceholderBox
                        value={messageData.text}
                        onChange={(e: any) =>
                            handleMessageDataChange('text', e.target.value)
                        }
                        name="messageToTrainee"
                        placeholder="Message to User"
                        max={200}
                        minRows={7}
                        fontSize="13px"
                        padding="23px 24px"
                        borderRadius="8px"
                        minHeight="178px"
                        placeholderFontWeight={500}
                    />
                </StyledFormArea>
                <StyledActions>
                    <Button
                        width="128px"
                        height="40px"
                        action={onClose}
                        variant="inverse"
                        color="warning"
                        customColor={Variables.Colors.tomato}
                    >
                        Cancel
                    </Button>
                    <Button
                        width="128px"
                        height="40px"
                        action={() => !!onSend && onSend(messageData)}
                        disabled={!messageData.name || !messageData.text}
                        color="warning"
                        customColor={Variables.Colors.tomato}
                    >
                        Submit
                    </Button>
                </StyledActions>
            </StyledContent>
        </>
    );
};
