export const dueDateTitleTypes = {
    MULTIPLE: 'Select Due Date',
    SINGLE: 'Due Date: ',
    NO_DUEDATE: 'No Due Date',
};

export const dueDateFiltersValues =  {
    'NO_DUEDATE': 'not_assigned',
    'OVERDUE': 'overdue',
    'ASSIGNED': 'assigned'
}