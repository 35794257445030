import { rgba } from 'polished';
import React, {
    createContext,
    FC,
    MouseEvent,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { useHistory } from '../../../tools/router';

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;
    background: ${props => rgba(props.theme.Colors.dark, 0.8)};
    overflow-x: auto;
    overflow-y: hidden;
`;

const Modal = styled(motion.div)<{ width?: string }>`
    position: absolute;
    width: ${props => (!!props.width ? props.width : '80%')};
    top: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.Colors.whiteTwo};
    box-shadow: 0 5px 20px -5px rgba(166, 174, 190, 0.5);
    transition: left 0.3s linear;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    @media (max-width: ${props => props.theme.BreakPoints.responsiveSm}px) {
        left: 47px;
        min-width: 375px;
        width: 968px;
    }
`;

export const ModalPage: FC<{
    onClose?(): void;
    className?: string;
    width?: string;
}> = ({ children, onClose, className, width }) => {
    const preventClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    const closeModalPage = useCallback(() => {
        if (onClose) onClose();
    }, [onClose]);
    const context = useMemo(() => ({ closeModalPage }), [closeModalPage]);

    return (
        <Background onClick={closeModalPage} data-test="modal-background">
            <Modal onClick={preventClick} className={className} width={width}>
                <ModalPageContext.Provider value={context}>
                    {children}
                </ModalPageContext.Provider>
            </Modal>
        </Background>
    );
};

export interface ModalPageContainerProps {
    closePath: string;
    closeGoingBack: boolean;
    restrictClosing?: boolean;
    restrictClosingAction?(): void;
    handleManualClose?(): void;
    className?: string;
    width?: string;
}

export const ModalPageContainer: FC<ModalPageContainerProps> = ({
    closePath,
    closeGoingBack,
    restrictClosing,
    restrictClosingAction,
    children,
    className,
    handleManualClose,
    width,
}) => {
    const history = useHistory();
    const handleClose = useCallback(() => {
        if (restrictClosing) {
            restrictClosingAction && restrictClosingAction();
        } else {
            if (closeGoingBack) history.goBack();
            else history.replace(closePath);
        }
    }, [
        history,
        closeGoingBack,
        closePath,
        restrictClosing,
        restrictClosingAction,
    ]);

    return (
        <ModalPage
            onClose={handleManualClose || handleClose}
            className={className}
            width={width}
        >
            {children}
        </ModalPage>
    );
};

export const ModalPageContext = createContext<{ closeModalPage?(): void }>({});

export function useModalPageContext() {
    return useContext(ModalPageContext);
}

export default ModalPageContainer;
