import NoPlay from '../../../../icons/NoPlay';
import { Loading } from '../../../LoadingCopmonent';
import { BlankVideoProps } from './types';
import {
    MainImage,
    ErrorMessage,
    NoPlayIcon,
} from './styles';

function BlankVideoView({
    errorMessage,
    isLoading
}: BlankVideoProps) {
    return <MainImage>
        {
            isLoading
                ? <Loading size={40} />
                : <>
                    <NoPlayIcon><NoPlay /></NoPlayIcon>
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                </> 
        }
    </MainImage>;
}

export default BlankVideoView;