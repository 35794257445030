import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    voiceGenerationClear,
    voiceGenerationFailure,
    voiceGenerationStart,
    voiceGenerationSuccess,
} from './actions';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { TextToSpeechSettings } from '../../../constants/interfaces/CompanyVoiceSettings';
import { generateAudioWithElevenLabs } from '../../../services/ElevenLabs';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';

export const useTextToSpeechService = () => {
    const showMessage = useShowMessage();
    const dispatch = useDispatch();
    return useCallback(
        (
            text: string,
            settings: TextToSpeechSettings,
            params: { feature: string; itemId: number | string }
        ) => {
            dispatch(voiceGenerationStart(params.feature, params.itemId));
            return generateAudioWithElevenLabs({
                text,
                voiceId: settings.voiceId,
                stability: settings.stability,
                similarityBoost: settings.similarityBoost,
            })
                .then(data => {
                    dispatch(voiceGenerationSuccess(params.feature, params.itemId, data));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    const errorMessage = error.code === 429
                        ? 'Our voice generation system is experiencing high load. Please try again in a few minutes.'
                        : 'Something went wrong while generating audio';
                    showMessage(errorMessage, 'error');
                    dispatch(
                        voiceGenerationFailure(params.feature, params.itemId, error)
                    );
                    throw error;
                });
        },
        [dispatch, showMessage]
    );
};

export const useClearVoiceService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (s: { feature: string; itemId: number | string }) => {
            dispatch(voiceGenerationClear(s.feature, s.itemId));
        },
        [dispatch]
    );
};
