import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Link = styled(NavLink)`
    display: inline-block;
    width: 280px;
    height: 184px;
    padding: 24px 16px;
    border-radius: 4px;
    border: solid 1px var(--ps-grey-4);
    background-color: var(--ps-white-1);
    text-decoration: none;

    &:hover {
        box-shadow: 0 4px 24px 0 var(--ps-grey-20);
    }
`;

export const Title = styled.div`
    font-size: 15px;
    font-weight: 800;
    line-height: 20px;
    padding-top: 6px;
    color: var(--ps-black);
`;

export const Description = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: var(--ps-black-main);
`;

export const ResultFormat = styled.div`
    font-size: 11px;
    font-weight: 600;
    color: var(--ps-black-main);
    padding: 2px 8px;
    border-radius: 2px;
    background-color: var(--ps-grey-3);
    display: inline-block;
    margin-top: 8px;
`;