import { useCallback } from 'react';
import { useShowMessage } from '../../../../../../ui/components/ErrorMessages/ErrorMessages';
import { CreateEnrollmentsParams, DeleteEnrollmentsParams, UpdateEnrollmentsParams } from './types';
import { EnrollmentsDueDateType, EnrollmentType } from '../../../../../../api/enrollments/types';
import { getSearchUserPractisSetsParams } from '../../../../../users/store/helpers';
import { getAssignUsersSearchParams } from '../../../../store/services';
import { useCreateUpdateEnrollmentDueDate, useDeleteEnrollmentsApi, useEnrollPractisSetsToUserApi, useSearchPractisSetDetailsUsersApi, useSearchUserEnrollmentsApi, useSearchUsersApi } from '../../../../../../api';
import { createSearchPractisSetDetailsParams } from '../../../../tools';

/**
 * @function useSearchUsersAndCreateEnrollment
 * @returns { CallableFunction }
 */
 export const useSearchUsersAndCreateEnrollment = () => {
    const searchUsersApi = useSearchUsersApi();
    const enrollPractisSetsToUser = useEnrollPractisSetsToUserApi();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } CreateEnrollmentsParams
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            { search, practisSetId, dueDate, enrollments: selectedEnrollments }: CreateEnrollmentsParams,
            onSuccessCallback: () => void
        ) => {
            const params = { ...getAssignUsersSearchParams(search), offset: 0 };
            await searchUsersApi(params)
                .then(async (response) => {
                    const enrollments: EnrollmentType[] = response.items.map((item) => {
                        return {
                            userId: item.id, 
                            practisSetId: practisSetId as number, 
                            dueDate: dueDate
                                || selectedEnrollments.find(e => e.userId === item.id)?.dueDate
                                || null
                        }
                    });

                    if (enrollments.length) {
                        await enrollPractisSetsToUser(enrollments).catch(error => {
                            error?.message &&
                                showMessage(error.message, 'error');
                            throw error;
                        })
                        onSuccessCallback();
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [enrollPractisSetsToUser, searchUsersApi, showMessage]
    );
};

/**
 * @function useSearchEnrollmentsAndDelete
 * @returns { CallableFunction }
 */
 export const useSearchEnrollmentsAndDelete = () => {
    const searchUserEnrollmentsApi = useSearchUserEnrollmentsApi();
    const deleteEnrollments = useDeleteEnrollmentsApi();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } DeleteEnrollmentsParams
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            { userId, searchParams }: DeleteEnrollmentsParams,
            onSuccessCallback: () => void
        ) => {
            const params = getSearchUserPractisSetsParams(searchParams);
            userId && await searchUserEnrollmentsApi(userId, params)
                .then(async (response) => {
                    const enrollmentIds: number[] = response?.items.map((item) => item.id) || [];

                    if (enrollmentIds.length) {
                        await deleteEnrollments(enrollmentIds).catch(error => {
                            error?.message &&
                                showMessage(error.message, 'error');
                            throw error;
                        })
                        onSuccessCallback();
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteEnrollments, searchUserEnrollmentsApi, showMessage]
    );
};

/**
 * @function useSearchPsetEnrollmentsAndDelete
 * @returns { CallableFunction }
 */
export const useSearchPsetEnrollmentsAndDelete = () => {
    const searchUserEnrollmentsApi = useSearchPractisSetDetailsUsersApi();
    const deleteEnrollments = useDeleteEnrollmentsApi();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } DeleteEnrollmentsParams
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            { searchParams }: DeleteEnrollmentsParams,
            onSuccessCallback: () => void
        ) => {
            const params = createSearchPractisSetDetailsParams(searchParams);
            const { teamId, practisSetId } = searchParams;

            teamId && practisSetId && await searchUserEnrollmentsApi(
                teamId.toString(), 
                practisSetId.toString(),
                params
                ).then(async (response) => {
                    const enrollmentIds: number[] = response?.items.map((item) => item.id) || [];

                    if (enrollmentIds.length) {
                        await deleteEnrollments(enrollmentIds).catch(error => {
                            error?.message &&
                                showMessage(error.message, 'error');
                            throw error;
                        })
                        onSuccessCallback();
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [deleteEnrollments, searchUserEnrollmentsApi, showMessage]
    );
};

/**
 * @function useSearchEnrollmentsAndUpdate
 * @returns { CallableFunction }
 */
export const useSearchEnrollmentsAndUpdate = () => {
    const searchUserEnrollmentsApi = useSearchPractisSetDetailsUsersApi();
    const updateEnrollmentsDueDate = useCreateUpdateEnrollmentDueDate();

    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @param { SearchParams } UpdateEnrollmentsParams
     * @returns { Promise<void> }
     */
    return useCallback(
        async (
            { searchParams, dueDate, offset }: UpdateEnrollmentsParams,
            onSuccessCallback: () => void
        ) => {
            const params = createSearchPractisSetDetailsParams({...searchParams, offset});
            const { teamId, practisSetId } = searchParams;

            teamId && practisSetId && await searchUserEnrollmentsApi(
                teamId.toString(), 
                practisSetId.toString(),
                params
                ).then(async (response) => {
                    const enrollments: EnrollmentsDueDateType[] = response?.items.map((item) => ({ enrollmentId: item.id, dueDate })) || [];

                    if (enrollments.length) {
                        await updateEnrollmentsDueDate(enrollments).catch(error => {
                            error?.message &&
                                showMessage(error.message, 'error');
                            throw error;
                        })
                        onSuccessCallback();
                    }
                })
                .catch((error: unknown) => {
                    throw error;
                });
        },
        [updateEnrollmentsDueDate, searchUserEnrollmentsApi, showMessage]
    );
}