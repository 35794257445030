import {InvitersResult} from "../interfaces";
import {Inviter} from "../../../constants/interfaces/Inviter";
import {useSelector} from "react-redux";

export interface InvitersState {
    data: InvitersResult<Inviter>;
    ready: boolean,
    loading: boolean;
    error: string;
    selected: number[];
    saved: number[];
}

export const useInvitersState = (): InvitersState => {
    const appState = useSelector(state => state);
    return appState.inviters;
};