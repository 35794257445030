import React, { FC, useEffect } from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../routes/routes';
import { useUserLogout } from '../store/services';

interface LogoutPageProps {
    history: History;
    loginUrl: string;
    onUserLogout: () => any;
}
const LogoutPage: FC<LogoutPageProps> = ({
    history,
    loginUrl,
    onUserLogout,
}) => {
    useEffect(() => {
        onUserLogout();
        history.push(loginUrl);
    }, [onUserLogout, loginUrl, history]);

    return null;
};

export const LogoutPageContainer: FC<{
    loginUrl?: string;
}> = ({ loginUrl }) => {
    const history = useHistory();
    const userLogout = useUserLogout();
    return (
        <LogoutPage
            history={history}
            loginUrl={loginUrl || ROUTES.AUTH_PAGES.LOGIN}
            onUserLogout={userLogout}
        />
    );
};

export default LogoutPageContainer;
