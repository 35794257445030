import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import ROUTES from '../../../routes/routes';
import { OnboardingRole } from './types';
import UserOnboardingView from './components/UserOnboarding';
import AdminOnboardingView from './components/AdminOnboarding';
import TeamLeaderOnboardingView from './components/TeamLeaderOnboarding';

function Onboarding () {
    const { role } = useParams<{ role: string }>();
    const history = useHistory();

    /**
     * @function handleRedirect - redirects to feeds route
     * @returns { void }
     */    
     const handleRedirect = useCallback(() => {
        history.push(ROUTES.SUBMISSIONS);
    }, [history]);

    if (role === OnboardingRole.ADMIN)
        return <AdminOnboardingView
            onRedirect={handleRedirect}
        />;
    if (role === OnboardingRole.TEAM_LEAD)
        return <TeamLeaderOnboardingView
            onRedirect={handleRedirect}
        />;

    return <UserOnboardingView />;
}

export default Onboarding;