import NoSearchResult from '../../../../icons/NoSearchResult';
import { EmptyState } from '../EmptyState';
import { NoSearchResultsProps } from './types';

function NoSearchResults(
    { entityName, dataTest }: NoSearchResultsProps
) {
    return <EmptyState icon={NoSearchResult} dataTest={dataTest}>No {entityName} Found</EmptyState>;
};

export default NoSearchResults;