import React, { FC } from 'react';

import {
    ActionButton,
    ActionItem,
} from '../../../../../../ui/components/ActionButton';
import { CheckPermission } from '../../../../../permissions';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { UserV2 } from '../../../../../../constants/interfaces/User';

export const OverdueLearnersListActions: FC<{
    user: UserV2;
    handleViewProfile: (user: UserV2) => void;
    setShowNudgeDialogMemberId: (userId: number) => void;
    isHidden?: boolean;
}> = ({
    user,
    handleViewProfile,
    setShowNudgeDialogMemberId,
    isHidden,
}) => {
    return (
        <span>
            <div>
                <ActionButton customWidth={200} isHidden={isHidden} dataTest="list-item-menu">
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.VIEW_USER_PERFORMANCE]}
                    >
                        <ActionItem onClick={() => handleViewProfile(user)} dataTest="view-profile-action">
                            View Profile
                        </ActionItem>
                    </CheckPermission>
                    <CheckPermission permissions={[NEW_PERMISSIONS.SEND_NUDGE]}>
                        <ActionItem
                            onClick={e => {
                                e.stopPropagation();
                                setShowNudgeDialogMemberId(user.id);
                            }}
                            dataTest="nudge-user-action"
                        >
                            Nudge User
                        </ActionItem>
                    </CheckPermission>
                </ActionButton>
            </div>
        </span>
    );
};



