export const getSearchSortingValue = (
    orderBy: {
        field?: string;
        asc?: boolean;
    } | null,
    defaultField: string,
    isAscByDefault?: boolean) => {
        if (orderBy?.field)
            return `${orderBy.field}_${orderBy.asc ? 'asc' : 'desc'}`;
        if (typeof isAscByDefault === 'undefined')
            return defaultField;
        return `${defaultField}_${isAscByDefault ? 'asc' : 'desc'}`;
}