import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
    clearAssignLabelsAction,
    clearLabelFilterAction,
    closeLabelCreationPanelAction,
    collapseAllLabelsAction,
    collapseAllTableLabelsAction,
    expandAllLabelsAction,
    expandLabelFieldByIdAction,
    expandMultipleLabelsAction,
    modifyLabelNameAction,
    openLabelCreationPanelAction,
    saveLabelFiltersFailure,
    saveLabelFiltersStart,
    saveLabelFiltersSuccess,
    searchLabelsFailure,
    searchLabelsStart,
    searchLabelsSuccess,
    selectLabelFilterAction,
    setAssignLabelsAction,
    setEditingLabelAction,
    setLabelFilterAction,
    setLabelsSearchTerm,
    toggleCollapseAssignLabelFieldAction,
    toggleCollapseLabelFieldAction,
    updateLabelFailure,
    updateLabelStart,
    updateLabelSuccess,
    setPreviouslyAssignedLabels,
} from './actions';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    useCreateLabelApi,
    useDeleteLabelApi,
    useSearchLabelsApi,
    useUpdateLabelApi,
} from '../../../api';
import { MutateLabelsParams } from '../../../api/labels/types';
import { Label } from '../../../constants/interfaces/Label';
import { SearchParams } from '../../../constants/interfaces/filters';
import { EditingLabels } from './states';
import { useSaveUserSettingsApi } from '../../../api';
import { isAuthorized, getCurrentUserId } from '../../../helpers/functions/auth-helpers';

export const useCreateLabelService = () => {
    const dispatch = useDispatch();
    const createLabelApi = useCreateLabelApi();
    const showMessage = useShowMessage();
    return useCallback(
        (params: MutateLabelsParams) => {
            dispatch(updateLabelStart());
            return createLabelApi(params)
                .then(data => {
                    dispatch(
                        dispatch(updateLabelSuccess(data as Label, 'create'))
                    );
                    showMessage('Label created', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLabelFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error.message;
                });
        },
        [dispatch, createLabelApi, showMessage]
    );
};

export const useSearchLabelsService = () => {
    const dispatch = useDispatch();
    const searchLabelsApi = useSearchLabelsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (sp?: SearchParams) => {
            dispatch(searchLabelsStart());
            return searchLabelsApi(sp)
                .then(data => {
                    dispatch(
                        searchLabelsSuccess(
                            data,
                            sp && sp.searchTerm.length > 0
                        )
                    );
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchLabelsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchLabelsApi, showMessage]
    );
};

export const useUpdateLabelService = () => {
    const dispatch = useDispatch();
    const updateLabelApi = useUpdateLabelApi();
    const showMessage = useShowMessage();
    return useCallback(
        (labelId: number, params: MutateLabelsParams) => {
            dispatch(updateLabelStart());
            return updateLabelApi(labelId, params)
                .then(data => {
                    dispatch(
                        updateLabelSuccess({ id: data.id } as Label, 'update')
                    );
                    showMessage('Label updated', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLabelFailure(error.message));
                    showMessage(error.message, 'error');
                    throw error.message;
                });
        },
        [dispatch, updateLabelApi, showMessage]
    );
};

export const useDeleteLabelService = () => {
    const dispatch = useDispatch();
    const deleteLabelApi = useDeleteLabelApi();
    const showMessage = useShowMessage();
    return useCallback(
        (label: Label) => {
            dispatch(updateLabelStart());
            return deleteLabelApi(label.id)
                .then(data => {
                    dispatch(dispatch(updateLabelSuccess(label, 'delete')));
                    showMessage('Label deleted', 'success');
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateLabelFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, deleteLabelApi, showMessage]
    );
};

/**
 * @function useSaveUserFiltersService
 * @description saves labels selected by current user as filters
 * @param { number[] | null } labels
 * @returns { Promise }
 */
export const useSaveUserFiltersService = () => {
    const dispatch = useDispatch();
    const saveUserSettingsApi = useSaveUserSettingsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (labels: number[] | null) => {
            if (!isAuthorized()) {
                showMessage('User is not authorized to perform this action', 'error');
                return Promise.reject();
            }
            dispatch(saveLabelFiltersStart(labels));
            return saveUserSettingsApi(getCurrentUserId()!, labels)
                .then(data => {
                    dispatch(saveLabelFiltersSuccess(labels));
                    dispatch(setLabelFilterAction(labels ?? []));
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(saveLabelFiltersFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, saveUserSettingsApi, showMessage]
    );
};

export const useSelectLabelFilterActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelId: number) => {
            dispatch(selectLabelFilterAction(labelId));
        },
        [dispatch]
    );
};

export const useSetPreviouslyAssignedLabelsAction = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labels: number[]) => {
            dispatch(setPreviouslyAssignedLabels(labels));
        },
        [dispatch]
    );
};

export const useToggleCollapseLabelFieldService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelId: number) => {
            dispatch(toggleCollapseLabelFieldAction(labelId));
        },
        [dispatch]
    );
};

export const useToggleCollapseAssignLabelFieldService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelId: number) => {
            dispatch(toggleCollapseAssignLabelFieldAction(labelId));
        },
        [dispatch]
    );
};

export const useExpandLabelFieldByIdService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelId: number) => {
            dispatch(expandLabelFieldByIdAction(labelId));
        },
        [dispatch]
    );
};

export const useSetLabelFilterActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labels: number[]) => {
            dispatch(setLabelFilterAction(labels));
        },
        [dispatch]
    );
};

export const useClearLabelFilterActionService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearLabelFilterAction());
    }, [dispatch]);
};

export const useSetAssignLabelsActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labels: number[]) => {
            dispatch(setAssignLabelsAction(labels));
        },
        [dispatch]
    );
};

export const useClearAssignLabelsActionService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(clearAssignLabelsAction());
    }, [dispatch]);
};

export const useCollapseAllLabelsService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(collapseAllLabelsAction());
    }, [dispatch]);
};

export const useCollapseAllTableLabelsService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(collapseAllTableLabelsAction());
    }, [dispatch]);
};

export const useExpandAllLabelsService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(expandAllLabelsAction());
    }, [dispatch]);
};

export const useExpandMultipleLabelsService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelIds: number[]) => {
            dispatch(expandMultipleLabelsAction(labelIds));
        },
        [dispatch]
    );
};

export const useModifyLabelNameActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (labelId, value: string) => {
            dispatch(modifyLabelNameAction(labelId, value));
        },
        [dispatch]
    );
};

export const useOpenLabelCreationPanelService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(openLabelCreationPanelAction());
    }, [dispatch]);
};

export const useCloseLabelCreationPanelService = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(closeLabelCreationPanelAction());
    }, [dispatch]);
};

export const useSetLabelsSearchTermService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (value: string) => {
            dispatch(setLabelsSearchTerm(value));
        },
        [dispatch]
    );
};

export const useSetEditingLabelActionService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (configs?: EditingLabels) => {
            dispatch(setEditingLabelAction(configs));
        },
        [dispatch]
    );
};
