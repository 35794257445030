import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import AvatarPlaceholder from '../../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import {
    getCompanyLoading,
    getCompanyState,
} from '../../../../pages/CompanySettings/store/reducers';
import ROUTES from '../../../../routes/routes';
import { changeCompanyOfPractisAdmin } from '../../store/services';
import CompaniesDropdownButton from '../CompaniesDropdownButton/CompaniesDropdownButton';
import { CompanyItem } from '../CompaniesDropdownButton/types';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { CheckPermission } from '../../../permissions';
import { useSaveUserFiltersService } from '../../../labels/store/services';
import {
    listenBroadCastChannelMessage,
    postBroadCastChannelMessage,
} from '../../../../helpers/functions/broadcast-channel';
import { pushModal, useHistory } from '../../../../tools/router';


const StyledCompanySection = styled.div`
    height: 40px;
    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
`;

const StyledCompanyLogo = styled.div`
    height: 100%;
    width: 100%;
`;

const LogoButton = styled.div`
    text-decoration: none;
    cursor: pointer;
`;

const LogoContainer = styled.div<{ practis?: boolean }>`
    height: ${props => (props.practis ? '20px' : '24px')};
    width: ${props => (props.practis ? '20px' : '24px')};
    border-radius: 4px;
    overflow: hidden;
`;

const Root = styled.div<{ open?: boolean }>`
    height: 40px;
    display: flex;
    position: relative;
    border-radius: 4px;
    padding: 0 10px;
    background: ${props =>
        props.open ? props.theme.Colors.whiteTwo : props.theme.Colors.white};
`;

const DropdownButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DropdownButtonLabel = styled.div`
    color: ${props => props.theme.Colors.dark};
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
`;

const StyledLogo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const NoLogoButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar-placeholder {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }

    span {
        color: #212121;
        font-size: 13px;
    }
`;

export const CompanySection: React.FC<{
    companies: boolean;
    company?: CompanyInterface;
    loading?: boolean;
    changeCompany?: (
        companyId?: number,
        successCallback?: <T>(args?: T) => void
    ) => Promise<void>;
}> = ({ company, changeCompany }) => {
    const saveUserFilters = useSaveUserFiltersService();
    const history = useHistory();

    /**
     * @function clearUserFilters
     * @returns { void }
     */
    const clearUserFilters = useCallback(() => {
        saveUserFilters([]);
    }, [saveUserFilters]);

    /**
     * @function successChangeCompanyCallback
     * @param { CompanyItem } company
     */
    const successChangeCompanyCallback = useCallback(
        company => {
            debounce(clearUserFilters, 200);
            postBroadCastChannelMessage(company.id);
        },
        [clearUserFilters]
    );

    /**
     * @function handleChangeCompany
     * @param { CompanyItem } company
     * @returns { void }
     */

    const handleChangeCompany = useCallback(
        async (company: CompanyItem) => {
            if (changeCompany) {
                await changeCompany(company.id, () =>
                    successChangeCompanyCallback(company)
                );
            }
        },
        [changeCompany, successChangeCompanyCallback]
    );

    /**
     * @function listenBroadCastChannelCallback
     * @returns { void }
     */
    const listenBroadCastChannelCallback = useCallback(
        async message => {
            await changeCompany?.(message, debounce(clearUserFilters, 200));
        },
        [changeCompany, clearUserFilters]
    );

    const openCompanySettings = useCallback(() => {
        pushModal(history, ROUTES.COMPANY_SETTINGS.ROOT);
    }, [history]);

    // handle change company for other open tabs
    useEffect(() => {
        listenBroadCastChannelMessage(listenBroadCastChannelCallback);
    }, [listenBroadCastChannelCallback]);

    if (!company) {
        return null;
    }

    const mediaUrl = company?.logoUrl || company?.logo;

    const logo =
        mediaUrl ? (
            <Root>
                <DropdownButton>
                    <LogoContainer practis={false}>
                        <StyledLogo src={mediaUrl} alt="org" />
                    </LogoContainer>
                    <DropdownButtonLabel>
                        {company?.name}
                    </DropdownButtonLabel>
                </DropdownButton>
            </Root>
        ) : (
            <Root>
                <NoLogoButton>
                    <AvatarPlaceholder
                        profile={company}
                        size="sm"
                        className="avatar-placeholder"
                    />
                    <span>{company?.name}</span>
                </NoLogoButton>
            </Root>
        );

    return (
        <CheckPermission
            permissions={[NEW_PERMISSIONS.ACCESS_ANY_COMPANY]}
            alternateContent={
                <StyledCompanySection>
                    <StyledCompanyLogo>
                        <CheckPermission
                            permissions={[NEW_PERMISSIONS.VIEW_COMPANY]}
                            alternateContent={logo}
                        >
                            <LogoButton onClick={openCompanySettings}>
                                {logo}
                            </LogoButton>
                        </CheckPermission>
                    </StyledCompanyLogo>
                </StyledCompanySection>
            }
        >
            <CompaniesDropdownButton
                company={company}
                updatedCompany={company}
                onChangeCompany={handleChangeCompany}
            />
        </CheckPermission>
    );
};

function mapStateToProps(state: any, container: any) {
    return {
        webSiteId: container.webSiteId,
        urlBuilder: container.urlBuilder,
        company: getCompanyState(state),
        loading: getCompanyLoading(state),
    };
}

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            changeCompany: changeCompanyOfPractisAdmin,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySection as any);
