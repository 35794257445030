import { SearchParams } from '../../../constants/interfaces/filters';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';
import {
    deSelectAllInvitersAction, refreshInvitersAction, saveInvitersAction,
    searchInvitersFailureAction,
    searchInvitersStartAction, searchInvitersSuccessAction, selectAllInvitersAction, selectMultipleInvitersAction,
} from './actions';
import { useDispatch } from 'react-redux';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import {useSearchInvitersApi} from '../../../api';

export const useSearchInvitersService = () => {
    const dispatch = useDispatch();
    const searchInvitersApi = useSearchInvitersApi();
    const showMessage = useShowMessage();
    
    return useCallback(
        (sp?: SearchParams) => {
            dispatch(searchInvitersStartAction());
            searchInvitersApi(sp)
                .then(data => {
                    dispatch(searchInvitersSuccessAction(data));
                })
                .catch((error: ErrorResult) => {
                    dispatch(searchInvitersFailureAction(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchInvitersApi, showMessage]
    );
};


export const useSelectMultipleInvitersAction = () => {
    const dispatch = useDispatch();
    return useCallback((ids: number[]) => {
        dispatch(selectMultipleInvitersAction(ids));
    }, [dispatch]);
};

export const useSelectAllInvitersAction = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(selectAllInvitersAction());
    }, [dispatch]);
};

export const useDeSelectAllInvitersAction = () => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(deSelectAllInvitersAction());
    }, [dispatch]);
};

export const useSaveInvitersAction = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(saveInvitersAction());
    }, [dispatch]);
};


export const useRefreshInviters = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(refreshInvitersAction());
    }, [dispatch]);
};