import { ADD_MESSAGE, ErrorMessagesActions, REMOVE_MESSAGE } from './actions';
import { ErrorMessagesInterface } from '../ErrorMessages';
import { AppState } from '../../../../store/reducers';
import { Reducer } from 'redux';

const initialState: ErrorMessagesInterface = {
    status: null,
    message: '',
};

const messageReducer: Reducer<ErrorMessagesInterface, ErrorMessagesActions> = (
    state = initialState,
    action
): ErrorMessagesInterface => {
    switch (action.type) {
        case ADD_MESSAGE:
            return {
                status: action.error.status,
                message: action.error.message,
            };
        case REMOVE_MESSAGE:
            return initialState;
        default:
            return state;
    }
};

export const getErrorMessages = (state: AppState) => state.messages;

export default messageReducer;
