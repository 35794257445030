import { FC } from 'react';

import styled from 'styled-components';
import { TableSearchInput } from '../table-wrapper/table-tools/table-search-input';

interface Props {
    disabled?: boolean;
    onChange?(value: string): void;
}

export const FiltersSearchInput: FC<Props> = props => {
    const { disabled, onChange } = props;
    return (
        <Root data-test="filters-search-input-container">
            <TableSearchInput
                disabled={disabled}
                onChange={onChange}
                dataTest="filters-search-input"
            />
        </Root>
    );
};

const Root = styled.div`
    margin-bottom: 16px;
`;