import React, {
    createRef,
    CSSProperties,
    FC,
    ReactNode,
    RefObject,
    useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { kebabCase } from 'lodash';

import { Popup } from '../Popup';
import { NavigationHint } from './Navigation';

//region Styles
const StyledNavigationItemContainer = styled.div<{
    isActive: boolean;
    height?: number;
    widthPercent?: number;
    url?: string;
    disablehovereffect?: boolean;
    fontSize?: string;
}>`
    height: ${props => (props.height ? `${props.height}px` : '32px')};
    padding: 0 15px 0 15px;
    padding-bottom: 12px;
    margin-right: 32px;
    font-size: ${props => (!!props.fontSize ? props.fontSize : '13px')};
    font-weight: 600;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${props =>
        props.isActive
            ? props.theme.Colors.black
            : props.theme.Colors.steelGrey};
    &:before {
        display: block;
        position: absolute;
        content: '';
        background: ${props =>
            props.isActive
                ? props.theme.Colors.mainBlue
                : props.theme.Colors.mainBlue};
        height: ${props => (props.disablehovereffect ? '0' : '2px')};
        border-radius: 1px;
        bottom: 0;
        width: 0px;
        transition: width 0.2s ease-in;
    }
    &:hover {
        &:before {
            width: ${props => props.widthPercent || 100}%;
        }
    }
    ${props =>
        props.isActive &&
        css`
            &:before {
                display: block;
                position: absolute;
                content: '';
                background: ${props.theme.Colors.mainBlue};
                height: ${props.disablehovereffect ? '0' : '2px'};
                border-radius: 1px;
                bottom: 0;
                width: ${props.widthPercent || 100}%;
                left: 0;
                right: 0;
                margin: auto;
            }
        `}
`;
const StyledNavigationItemRouteContainer = styled(NavLink)<{
    height?: number;
    url?: string;
    disablehovereffect?: boolean;
    fontSize?: string;
}>`
    height: ${props => (props.height ? `${props.height}px` : '32px')};
    padding: 0 15px 0 15px;
    padding-bottom: 12px;
    margin-right: 32px;
    font-size: ${props => (!!props.fontSize ? props.fontSize : '13px')};
    font-weight: 600;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: ${props => props.theme.Colors.steelGrey};
    &:before {
        display: block;
        position: absolute;
        content: '';
        background: ${props => props.theme.Colors.mainBlue};
        height: ${props => (props.disablehovereffect ? '0' : '2px')};
        border-radius: 1px;
        bottom: 0;
        width: 0px;
        transition: width 0.2s ease-in;
    }
    &:hover {
        &:before {
            width: 100%;
        }
    }
    &.is-active {
        color: ${props => props.theme.Colors.black};
        &:before {
            display: block;
            position: absolute;
            content: '';
            background: ${props => props.theme.Colors.mainBlue};
            height: ${props => (props.disablehovereffect ? '0' : '2px')};
            border-radius: 1px;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
`;

const StyledDot = styled.div<{ isShrink?: boolean }>`
    height: 8px;
    width: 8px;
    background-color: ${props => props.theme.Colors.tomato};
    border-radius: 50%;
    margin-top: -10px;
    position: absolute;
    margin-left: 6px;
    right: 4px;
`;
//endregion

const HintWrapper: FC<{
    firstTime?: boolean;
    content?: NavigationHint;
    storageKey: string;
    children: (ref: RefObject<any>) => ReactNode;
}> = ({ children, content, storageKey, firstTime }) => {
    if (content) {
        return (
            <Popup<HTMLDivElement>
                content={({ hide }, { dismiss }) =>
                    content({ dismiss: firstTime ? dismiss : hide })
                }
                dismissKey={firstTime ? storageKey : undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                arrow={true}
                padding={'28px 24px 28px 24px'}
                verticalOffset={3}
                horizontalOffset={16}
            >
                {ref => <>{children(ref)}</>}
            </Popup>
        );
    }

    return <>{children(createRef())}</>;
};

const NavigationItem: FC<{
    title: string;
    action?: () => void;
    active?: boolean;
    height?: number;
    widthPercent?: number;
    url?: string;
    navigationItemStyles?: CSSProperties;
    activeNavigationItemStyles?: CSSProperties;
    disableHoverEffect?: boolean;
    fontSize?: string;
    hint?: NavigationHint;
    hintFirstTime?: boolean;
    name: string;
    notReviewedCount?: number;
    dataTest?: string;
}> = ({
    title,
    action,
    active,
    height,
    widthPercent,
    url,
    navigationItemStyles,
    activeNavigationItemStyles,
    disableHoverEffect,
    fontSize,
    hint,
    hintFirstTime,
    name,
    notReviewedCount,
    dataTest
}) => {
    const storageKey = useMemo(() => {
        if (url) {
            return name;
        }
        return name;
    }, [name, url]);

    if (!url) {
        return (
            <HintWrapper
                content={hint}
                firstTime={hintFirstTime}
                storageKey={storageKey}
            >
                {ref => (
                    <StyledNavigationItemContainer
                        ref={ref}
                        onClick={action}
                        isActive={!!active}
                        height={height}
                        widthPercent={widthPercent}
                        disablehovereffect={disableHoverEffect}
                        fontSize={fontSize}
                        style={
                            active
                                ? {
                                      ...navigationItemStyles,
                                      ...activeNavigationItemStyles,
                                  }
                                : {
                                      ...navigationItemStyles,
                                  }
                        }
                        data-test={dataTest && `${dataTest}-${kebabCase(title)}`}
                    >
                        {title}  {!!notReviewedCount && <StyledDot />}
                    </StyledNavigationItemContainer>
                )}
            </HintWrapper>
        );
    } else {
        return (
            <HintWrapper
                content={hint}
                firstTime={hintFirstTime}
                storageKey={storageKey}
            >
                {ref => (
                    <StyledNavigationItemRouteContainer
                        ref={ref}
                        to={url}
                        height={height}
                        activeClassName={'is-active'}
                        disablehovereffect={disableHoverEffect}
                        style={
                            active
                                ? {
                                      ...navigationItemStyles,
                                      ...activeNavigationItemStyles,
                                  }
                                : {
                                      ...navigationItemStyles,
                                  }
                        }
                        fontSize={fontSize}
                        data-test={dataTest && `${dataTest}-${kebabCase(title)}`}
                    >
                   
                        {title} {!!notReviewedCount && <StyledDot />}
                    </StyledNavigationItemRouteContainer>
                )}
            </HintWrapper>
        );
    }
};

export default NavigationItem;
