import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const EmptyCalendar = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#ggh0pyd7xa)">
                    <path d="M42.97 5.452h-6.067v-2.81c0-.435-.18-.853-.499-1.161A1.733 1.733 0 0 0 35.201 1h-.287c-.451 0-.884.173-1.203.481-.32.308-.498.726-.498 1.161v2.81h-18.45v-2.81c0-.435-.18-.853-.499-1.161A1.733 1.733 0 0 0 13.061 1h-.287c-.451 0-.884.173-1.203.481-.32.308-.498.726-.498 1.161v2.81H6.05c-.544 0-1.065.208-1.45.58-.384.37-.6.873-.6 1.398v37.592c0 .524.216 1.028.6 1.398.385.372.906.58 1.45.58h36.9c.544 0 1.065-.208 1.45-.58.384-.37.6-.874.6-1.398V7.43c0-.521-.213-1.021-.593-1.392a2.087 2.087 0 0 0-1.436-.586zm-1.024 2.967v7.726H7.096V8.42h34.85zM7.096 44.032V19.4h34.85v24.632H7.096z" fill="#B1C0CB"/>
                    <path fill="#fff" d="M26 28h19v19H26z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="m30 35 3-3 4.5 4.5L42 32l3 3-4.5 4.5L45 44l-3 3-4.5-4.5L33 47l-3-3 4.5-4.5L30 35z" fill="#B1C0CB"/>
                </g>
                <defs>
                    <clipPath id="ggh0pyd7xa">
                        <path fill="#fff" transform="translate(4 1)" d="M0 0h41v46H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default EmptyCalendar;
