import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PractisSetWithDueDate } from '../../../../constants/interfaces/Draft';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';

import { useChunkRequestsService } from '../../../../services/ChunkRequestService/hooks';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import {
    showModalDialog,
    useHideModalDialog,
} from '../../../../ui/components/ModalDialogs/store/actions';
import { GeneralBulkActionModalInterface } from '../../../../ui/components/ModalDialogs/types';
import {
    useGenerateAllUsersEnrollmentActionList,
    useGenerateMultipleUsersEnrollmentActionList,
} from './helpers';
import { EnrollUnenrollParams } from './types';

/**
 *
 * @description Custom hook to create , update, delete enrollments with chunk service
 * @function useEnrollUnenrollAllUsersBulkActionService
 * @param { EnrollUnenrollParams } EnrollUnenrollParams
 * @param { Number } totalUsersCount
 * @param { Function } onSuccessCallback
 * @param { Function } onErrorCallback
 * @returns { Void }
 */
export function useEnrollUnenrollAllUsersBulkActionService(
    enrollUnenrollParams: EnrollUnenrollParams,
    totalUsersCount?: number,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const totalCompletedEnrollments = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const generateEnrollmentActionList =
        useGenerateAllUsersEnrollmentActionList();

    const actionList = generateEnrollmentActionList({
        ...enrollUnenrollParams,
        totalUsers: totalUsersCount,
    });

    /**
     * @function clearTotalCompletedEnrollments
     * @returns { void }
     */
    const clearTotalCompletedEnrollments = () =>
        (totalCompletedEnrollments.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            clearTotalCompletedEnrollments();

            showMessage('Changes have been saved', 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(
        (error?: ErrorResult) => {
            if (!error?.message.includes('already exists')) {
                Promise.resolve().then(() => {
                    dispatch(
                        showModalDialog({
                            modalType: 'BULK_ACTION_FAILED_MODAL',
                            modalProps: {
                                modalTitle: 'Assign Practis Sets and Due Dates',
                                onClose: hideModalDialog,
                            } as GeneralBulkActionModalInterface,
                        })
                    );

                    clearTotalCompletedEnrollments();

                    isRunning && setIsRunning(false);

                    onErrorCallback?.();
                });
            }
        },
        [dispatch, hideModalDialog, isRunning, onErrorCallback]
    );

    const { setIsStopped } = useChunkRequestsService(
        [actionList],
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback,
        false // should not stop on error
    );

    /**
     * @function handleStopEnrollUnenrollsBulkActionService
     * @returns { void }
     */
    const handleStopEnrollUnenrollsBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(`Changes have been saved`, 'success');

            clearTotalCompletedEnrollments();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartEnrollUnenrollBulkActionService
     * @returns { void }
     */
    const handleStartEnrollUnenrollBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'Assign Practis Sets and Due Dates',
                        onStopBulkActionService:
                            handleStopEnrollUnenrollsBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopEnrollUnenrollsBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartEnrollUnenrollBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);
}

export function useEnrollUnrollMultipleUsersBulkActionService(
    selectedUserIds: number[],
    selectedPractisSets: PractisSetWithDueDate[],
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) {
    const dispatch = useDispatch();
    const hideModalDialog = useHideModalDialog();
    const showMessage = useShowMessage();

    const totalCompletedEnrollments = useRef<number>(0);

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const generateMultipleUsersActionList =
        useGenerateMultipleUsersEnrollmentActionList();

    const actionList = generateMultipleUsersActionList(
        selectedUserIds,
        selectedPractisSets
    );

    /**
     * @function clearTotalCompletedEnrollments
     * @returns { void }
     */
    const clearTotalCompletedEnrollments = () =>
        (totalCompletedEnrollments.current = 0);

    /**
     * @function handleSuccessServiceCallback
     * @returns { void }
     */
    const handleSuccessServiceCallback = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);
            clearTotalCompletedEnrollments();

            showMessage('Changes have been saved', 'success');
            onSuccessCallback?.();
        });
    }, [hideModalDialog, isRunning, onSuccessCallback, showMessage]);

    /**
     * @function handleFailedBulkActionServiceCallback
     * @returns { void }
     */
    const handleFailedBulkActionServiceCallback = useCallback(
        (error?: ErrorResult) => {
            if (!error?.message.includes('already exists')) {
                Promise.resolve().then(() => {
                    dispatch(
                        showModalDialog({
                            modalType: 'BULK_ACTION_FAILED_MODAL',
                            modalProps: {
                                modalTitle: 'Assign Practis Sets and Due Dates',
                                onClose: hideModalDialog,
                            } as GeneralBulkActionModalInterface,
                        })
                    );

                    clearTotalCompletedEnrollments();

                    isRunning && setIsRunning(false);

                    onErrorCallback?.();
                });
            }
        },
        [dispatch, hideModalDialog, isRunning, onErrorCallback]
    );

    const { setIsStopped } = useChunkRequestsService(
        [actionList],
        handleSuccessServiceCallback,
        handleFailedBulkActionServiceCallback,
        false // should not stop on error
    );

    /**
     * @function handleStopEnrollUnenrollsBulkActionService
     * @returns { void }
     */
    const handleStopEnrollUnenrollsBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();

            showMessage(`Changes have been saved`, 'success');

            clearTotalCompletedEnrollments();

            onSuccessCallback?.();
        });
    }, [hideModalDialog, onSuccessCallback, setIsStopped, showMessage]);

    /**
     * @function handleStartEnrollUnenrollBulkActionService
     * @returns { void }
     */
    const handleStartEnrollUnenrollBulkActionService = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'Assign Practis Sets and Due Dates',
                        onStopBulkActionService:
                            handleStopEnrollUnenrollsBulkActionService,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStopEnrollUnenrollsBulkActionService,
        hideModalDialog,
        setIsStopped,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStartEnrollUnenrollBulkActionService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);
}