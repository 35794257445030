import ROUTES from '../../routes/routes';
import localStorage from '../../services/Storage';
import { DEV_ENV_URLS } from '../../constants/variables/general';
/**
 * @function buildRegistrationUrl - builds url of registration page by invitation code
 * @param { string } code
 * @returns { string }
 */
export const buildRegistrationUrl = (code: string): string =>
    `${document.location.origin}${ROUTES.AUTH_PAGES.REGISTRATION}?token=${code}`;

export const getDevApiUrl = (version: string) => {
    const apiPlatform = (localStorage.get('apiPlatform') || 'develop');

    return DEV_ENV_URLS[apiPlatform][version];
}

/**
 * @function isSharedLink
 * @param { string } url
 * @returns { boolean }
 */
export const isSharedLink = (url: string) => {
    var accuracyTestRegex = new RegExp(`${ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.ALL}/\\d+`);
    var challengeRegex = new RegExp(`${ROUTES.SUBMISSION_SETTINGS.CHALLENGES.ALL}/\\d+`);
    return !!url.match(accuracyTestRegex)
        || !!url.match(challengeRegex);
}