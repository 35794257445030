import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const WarningIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, dataTest }) => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className={className} data-test={dataTest}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.046.327C9.255.117 9.539 0 9.835 0h12.33c.296 0 .58.118.79.327l8.718 8.719c.21.209.327.493.327.789v12.33c0 .296-.118.58-.327.79l-8.719 8.718c-.209.21-.493.327-.789.327H9.835c-.296 0-.58-.118-.79-.327L.328 22.954A1.116 1.116 0 0 1 0 22.165V9.835c0-.296.118-.58.327-.79L9.046.328z" fill="#EC513D" />
            <path d="M16 8.559c.617 0 1.116.5 1.116 1.116v6.697a1.116 1.116 0 1 1-2.232 0V9.675c0-.617.5-1.116 1.116-1.116zM16 23.442a1.488 1.488 0 1 0 0-2.977 1.488 1.488 0 0 0 0 2.977z" fill="#fff" />
        </svg>);
    }
);

export default WarningIcon;
