import React, { FC } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../../theme/variables';

const StyledSelectBoxItemContainer = styled.div<{ widthPercent?: number }>`
    position: relative;
    width: ${props => props.widthPercent || 24}%;
`;

const StyledSelectBoxItemWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const StyledBulletBackground = styled.div`
    width: 16px;
    height: 2px;
    background: ${props => props.theme.Colors.white};
    position: absolute;
    right: 0;
`;

const StyledSelectBoxItem = styled.div<{
    selected: boolean;
    color: string;
    round?: boolean;
    spacing?: string;
}>`
    width: 100%;
    background: ${props =>
        props.selected ? props.color : Variables.Colors.paleGrey};
    color: ${props =>
        props.selected ? Variables.Colors.white : Variables.Colors.coolGray};
    height: 2px;
    margin: 25px 0;
    cursor: pointer;
    user-select: none;
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 0.7;
    }
`;
const StyledBullet = styled.div<{
    alignRight?: boolean;
    selected?: boolean;
    color?: string;
}>`
    width: 16px;
    height: 16px;
    background: ${props =>
        props.selected
            ? props.theme.Colors.darkSkyBlue15
            : props.theme.Colors.paleGreyTwo};
    border: solid 1px
        ${props =>
            props.selected
                ? props.theme.Colors.darkSkyBlue
                : props.theme.Colors.cloudyBlue};
    position: absolute;
    ${props => (props.alignRight ? `right: 0` : `left: 0`)};
    top: 18px;
    border-radius: 50%;
`;

const SelectBoxItem: FC<{
    selected: boolean;
    finalSpot: boolean;
    finalItem: boolean;
    firstItem: boolean;
    name: string | number;
    color?: string;
    handleSelect: (value: string | number) => void;
    isRound?: boolean;
    spacing?: string;
}> = ({
    name,
    selected,
    finalSpot,
    finalItem,
    firstItem,
    color,
    handleSelect,
    isRound,
    spacing,
}) => {
    return (
        <StyledSelectBoxItemContainer
            onClick={() => handleSelect(name)}
            widthPercent={firstItem ? 1.8 : 24}
        >
            <StyledSelectBoxItemWrapper>
                <StyledSelectBoxItem
                    selected={selected || (finalItem && finalSpot)}
                    color={
                        color ? color : Variables.Colors.transparentSoftBlueTwo
                    }
                    round={isRound}
                    spacing={spacing}
                />
                <StyledBulletBackground />
            </StyledSelectBoxItemWrapper>
            <StyledBullet
                alignRight={true}
                selected={selected}
                color={color ? color : Variables.Colors.transparentSoftBlueTwo}
            />
        </StyledSelectBoxItemContainer>
    );
};

export default SelectBoxItem;
