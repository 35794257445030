import { Field, Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import styled, { css } from 'styled-components';

import { UserProfile } from '../../../constants/interfaces/User';
import Validation from '../../../services/validation';
import { StyledActionsDivs, StyledActionsLinks } from '../../Auth/styles';
import { getProfileError, getProfileState } from '../store/reducers';
import { useSelector } from 'react-redux';
import { FormikField } from '../../../ui/components/FormikField';
import { Button } from '../../../ui/components/Button';
import { UpdatePasswordType } from '../../../api/users/types';

const ProfilePageContainer = styled.div`
    min-width: 200px;
`;

const UserInfoForm = styled.div`
    padding-bottom: 8px;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    border: none;
    margin: 0;
    flex-shrink: 0;
    background-color: ${props => props.theme.Colors.paleGrey};
`;

const Separator = styled.div`
    height: 1px;
    width: 16px;
`;

const InputItem = styled.div`
    min-width: 150px;
    flex: 1;
    margin-bottom: 16px;
`;

const InputWrapper = styled.div<{ marginBottom?: number }>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${({ marginBottom }) => css`margin-bottom: ${marginBottom}px;`}
`;

const Content = styled.div``;

const validationSchemaDefault = Yup.object().shape({
    password: Validation.password,
    confirmPassword: Validation.confirmPassword,
});

const validationSchemaWithCurrentPassword = Yup.object().shape({
    password: Validation.password,
    confirmPassword: Validation.confirmPassword,
    currentPassword: Validation.currentPassword,
});

export const Security: FC<{
    profile: UserProfile;
    updateUserPassword(data: UpdatePasswordType, userId: number): void;
    dataTest?: string;
}> = ({ profile, updateUserPassword, dataTest }) => {
    const selfProfile = useSelector(getProfileState);
    const isSelf = selfProfile?.id === profile?.id;
    const error = useSelector(getProfileError);
    
    const [showCurrentPasswordError, setShowCurrentPasswordError] = useState(true);
    const currentPasswordError = isSelf && error?.errorCode === 409 && showCurrentPasswordError && 'Incorrect password.';


    return (
        <ProfilePageContainer>
            <Content>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                        currentPassword: '',
                    }}
                    onSubmit={(values, actions) => {
                        isSelf && setShowCurrentPasswordError(true);
                        const updateValues = {
                            password: values.password,
                            currentPassword: values.currentPassword,
                        };
                        updateUserPassword(updateValues, profile.id!);
                    }}
                    validationSchema={isSelf ? validationSchemaWithCurrentPassword : validationSchemaDefault}
                >
                    {({ values }) => (
                        <Form>
                            <UserInfoForm>
                                {isSelf && (
                                    <InputWrapper marginBottom={16}>
                                        <InputItem>
                                            <Field
                                                type="password"
                                                component={FormikField}
                                                autoComplete="current-password"
                                                name="currentPassword"
                                                label="Current Password"
                                                customError={currentPasswordError}
                                                validate={() => setShowCurrentPasswordError(false)}
                                            />
                                        </InputItem>
                                        <Separator />
                                        <InputItem />
                                    </InputWrapper>
                                )}
                                <InputWrapper marginBottom={20}>
                                    <InputItem>
                                        <Field
                                            type="password"
                                            component={FormikField}
                                            autoComplete="new-password"
                                            name="password"
                                            label="New Password"
                                            dataTest={`${dataTest}-new-password`}
                                        />
                                    </InputItem>
                                    <Separator />
                                    <InputItem>
                                        <Field
                                            type="password"
                                            component={FormikField}
                                            autoComplete="confirm-new-password"
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            dataTest={`${dataTest}-confirm-password`}
                                        />
                                    </InputItem>
                                </InputWrapper>
                            </UserInfoForm>
                            <Divider />
                            <StyledActionsDivs>
                                <Button
                                    height={'40px'}
                                    width={'120px'}
                                    disabled={
                                        !values.password ||
                                        !values.confirmPassword ||
                                        (isSelf && !values.currentPassword)
                                    }
                                    dataTest={`${dataTest}-update`}
                                >
                                    Update
                                </Button>
                                <StyledActionsLinks />
                            </StyledActionsDivs>
                        </Form>
                    )}
                </Formik>
            </Content>
        </ProfilePageContainer>
    );
};

export default Security;
