import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const StyledSkeleton = styled(Skeleton)`
    background: var(--ps-grey-4);
    border-radius: 0!important;
    height: 8px!important;
    transform: none!important;
`;

export const InputSkeleton = styled.div<{ padding: string }>`
    background: var(--ps-grey-3);
    padding: ${props => props.padding};
`;

export const TwoElementsContainer = styled.div<{ padding: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.padding};
`;