import React, { Context, FC } from 'react';

export interface MultiContextProviderItem<TValue = any> {
    context: Context<TValue>;
    value: TValue;
}

export function contextItem<TValue>(
    context: Context<TValue>,
    value: TValue
): MultiContextProviderItem<TValue> {
    return {
        context,
        value,
    };
}

export const MultiContextProvider: FC<{
    items: MultiContextProviderItem[];
}> = ({ items, children }) => (
    <>
        {items.reverse().reduce(
            (prev, item, idx) => (
                <item.context.Provider key={idx} value={item.value}>
                    {prev}
                </item.context.Provider>
            ),
            children
        )}
    </>
);
