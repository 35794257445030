import { Reducer } from 'redux';
import { PortableScenariosState, initialState } from './states';
import { PortableScenariosActions } from './actions';

export const portableScenariosReducer: Reducer<
PortableScenariosState,
    PortableScenariosActions
> = (
    state = initialState,
    action: PortableScenariosActions
): PortableScenariosState => {
    switch (action.type) {
        case 'searchStartScenarios':
            return {
                ...state,
                loading: true,
            };
        case 'searchSuccessScenarios':
            return {
                data: action.result,
                loading: false,
            };
        case 'searchFailureScenarios':
            return {
                ...state,
                loading: false,
            };
        case 'clearPortableScenarios':
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
