import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../ui/components/Button';
import ROUTES from '../../../../routes/routes';
import { useTelepromterState } from '../../store/state';
import { useHistory } from '../../../../tools/router';

const Container = styled.div`
    background: ${props => props.theme.Colors.darkThree};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 72px 32px 0 32px;
    box-sizing: border-box;
`;

const Text = styled.div`
    font-size: 17px;
    color: ${props => props.theme.Colors.white};
    margin-bottom: 32px;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Actions = styled.div`
    height: 180px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    margin-bottom: 8px;
`;

const Exit: FC = () => {
    const scenario = useTelepromterState().scenario;
    const history = useHistory();

    const redirectToScenario = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.LIBRARY_SETTINGS.SCENARIOS.SINGLE.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    const redirectToTraining = useCallback(() => {
        if (scenario.id) {
            history.push(
                ROUTES.TELEPROMTER_PAGES.TRAINING.replace(
                    ':scenarioId',
                    scenario.id.toString()
                )
            );
        }
    }, [history, scenario.id]);

    return (
        <Container>
            <Content>
                <Text>Are you sure you want to exit?</Text>
                <Actions>
                    <ButtonWrapper>
                        <Button
                            label="Go Back to Scenario"
                            width="152px"
                            height="48px"
                            action={redirectToTraining}
                        />
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <Button
                            label="Yes Exit"
                            width="152px"
                            height="48px"
                            action={redirectToScenario}
                            variant={'inverse'}
                        />
                    </ButtonWrapper>
                </Actions>
            </Content>
        </Container>
    );
};

export default Exit;
