import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setFilterHeaderSearchActive, setFilterHeaderSearchTerm } from "./actions";
import { SearchableFilterHeaderKey } from "./types";

export const useSetFilterHeaderSearchActive = (key: SearchableFilterHeaderKey) => {
    const dispatch = useDispatch();

    return useCallback((isSearchActive: boolean) => {
        dispatch(setFilterHeaderSearchActive(key, isSearchActive));
        if (!isSearchActive) {
            dispatch(setFilterHeaderSearchTerm(key, ''));
        }
    }, [key, dispatch]);
};

export const useSetFilterHeaderSearchTerm = (key: SearchableFilterHeaderKey) => {
    const dispatch = useDispatch();

    return useCallback((searchTerm: string) => {
        dispatch(setFilterHeaderSearchTerm(key, searchTerm));
    }, [key, dispatch]);
};