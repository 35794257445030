import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';

export type PermissionsStateSelector = (state: any) => NEW_PERMISSIONS[];

export const PermissionsStateSelectorContext = React.createContext<
    PermissionsStateSelector
>(() => {
    throw new Error('Not implemented');
});

export function usePermissionsState(): NEW_PERMISSIONS[] {
    return useSelector(useContext(PermissionsStateSelectorContext));
}
