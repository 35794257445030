import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SidebarTeams = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.268 1a2 2 0 0 1 3.464 0H12a1 1 0 0 1 1 1h3v14H0V2h3a1 1 0 0 1 1-1h2.268zM12 2v2H4V2h3a1 1 0 0 1 2 0h3zM3 4V3H1v12h14V3h-2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1zm1 5V8h8v1H4zm0 3v-1h6v1H4z"
                    fill="#6D7F8C"
                />
            </svg>
        );
    }
);

export default SidebarTeams;
