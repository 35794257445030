import { FC, CSSProperties, memo } from 'react';
import styled from 'styled-components';

import { SIZES } from '../table-wrapper/constants';

const ScrollContainer = styled.div<{ addOverflow?: boolean }>`
    ${props => props.addOverflow && `overflow-y: hidden;`}
    ${props => props.addOverflow && `overflow-x: auto;`}
    transform: rotateZ(180deg);
    direction: rtl;

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.whiteTwo};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.Colors.whiteThree};
    }
`;

const TableInner = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    display: inline-table;
    transform: rotateZ(180deg);
    direction: ltr;
    min-width: ${SIZES.MIN_WIDTH}px;

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.Colors.whiteTwo};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.Colors.whiteThree};
    }
`;

const TableCont = styled.div`
    display: block;
    border-radius: 8px;
    background: ${props => props.theme.Colors.white};
    position: relative;
`;

export const Table: FC<{
    className?: string;
    containerStyles?: CSSProperties;
    cornered?: boolean;
    hideSpacer?: boolean;
    addOverflow?: boolean;
}> = memo(
    ({ addOverflow, children, className, containerStyles }) => {
        return (
            <TableCont style={containerStyles}>
                <ScrollContainer addOverflow={addOverflow}>
                    <TableInner className={className}>{children}</TableInner>
                </ScrollContainer>
            </TableCont>
        );
    }
);
