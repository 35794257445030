import { FC } from 'react';

import {
    ListItemName,
    ListNameWrapper,
    StyledAdditionalInfo,
    StyledIsNew,
    StyledText,
} from './styles';
import { TableTitleOverflowTextProps } from './types';

const TableTitleOverflowText: FC<TableTitleOverflowTextProps> = ({
    isCurrent,
    isNew,
    children,
    renderIcon,
    dataTest,
    minWidth
}) => {
    return (
        <ListNameWrapper minWidth={minWidth}>
            <ListItemName data-test={dataTest}>
                <StyledText
                    title={typeof children === 'string' ? children : ''}
                >
                    {children}
                </StyledText>
                {isCurrent && (
                    <StyledAdditionalInfo>(You)</StyledAdditionalInfo>
                )}
                {renderIcon && renderIcon}
                {isNew && <StyledIsNew>New</StyledIsNew>}
            </ListItemName>
        </ListNameWrapper>
    );
};

export default TableTitleOverflowText;
