import {
    InputSkeleton,
    StyledSkeleton,
    TwoElementsContainer
} from './styles';

function VoiceSettingsSkeleton() {
    return (
        <>
            <StyledSkeleton
                width={38}
                style={{ marginTop: '7px', marginBottom: '8px' }}
            />
            <InputSkeleton padding="17px 12px 15px 12px">
                <StyledSkeleton width={114} />
            </InputSkeleton>
            <TwoElementsContainer padding="23px 0 0 0">
                <StyledSkeleton width={163} />
                <StyledSkeleton width={62} />
            </TwoElementsContainer>
            <StyledSkeleton
                width={77}
                style={{ marginTop: '18px' }}
            />
            <StyledSkeleton
                width={400}
                style={{ marginTop: '12px' }}
            />
            <StyledSkeleton
                width={123}
                style={{ marginTop: '40px' }}
            />
            <StyledSkeleton
                width={400}
                style={{ marginTop: '12px' }}
            />
            <StyledSkeleton
                width={77}
                style={{ marginTop: '49px', marginBottom: '8px' }}
            />
            <InputSkeleton padding="14px 12px 12px 12px">
                <StyledSkeleton width={376} />
                <StyledSkeleton
                    width={348}
                    style={{ marginTop: '10px' }}
                />
                <StyledSkeleton
                    width={265}
                    style={{ marginTop: '10px' }}
                />
                <TwoElementsContainer padding="70px 0 0 0">
                    <StyledSkeleton width={58} />
                    <StyledSkeleton width={58} />
                </TwoElementsContainer>
            </InputSkeleton>
        </>
    );
}

export default VoiceSettingsSkeleton;