import React, { FC, useEffect } from 'react';
import { PortableTeamsWithStore } from '../../../../../../portableTeams';
import { PerformanceTrainee } from '../../../../../../../constants/interfaces/PerformanceTrainee';
import { useSelectMultipleTeams } from '../../../../../../portableTeams/store/hors/withTeams/services';

const FilterTeams: FC<{
    user?: PerformanceTrainee;
    setInitialTeams?: boolean;
    showDefaultMembers?: boolean;
}> = ({ user, showDefaultMembers, setInitialTeams }) => {
    const setTeams = useSelectMultipleTeams();

    useEffect(() => {
        if (setInitialTeams) {
            const preSelectedTeams = user?.teams ?? [];
        
            setTeams(preSelectedTeams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PortableTeamsWithStore
            showDefaultMembers={showDefaultMembers}
            showSave={false}
            showSelectActions={true}
            fetchOnMount={false}
            showSearch={true}
            resetSelectedOnUnmount={true}
        />
    );
};

export default FilterTeams;
