import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { Variables } from '../../../../theme/variables';

const Container = styled.div`
    border-radius: 100px;
    overflow: hidden;
    display: block;
`;

const Tray = styled.div<{
    value: number;
    completed?: boolean;
    full?: boolean;
    color: string;
    completedColor: string;
    newProgressBar?: boolean
}>`

    height: ${props => props.newProgressBar ? '4px' : '24px'};
    width: 100%;
    position: relative;
    background: ${props => props.newProgressBar ? props.theme.Colors.whiteThree : props.theme.Colors.whiteFive};
    border-radius: 12px;
    border: solid 1px ${props => !props.newProgressBar ? props.theme.Colors.cloudyBlue : 'transparent'};
    &&:before {
        background: none repeat scroll 0 0
            ${props =>
                !!props.completed
                    ? rgba(props.completedColor, props.newProgressBar ? 1 : 0.15)
                    : rgba(props.color, props.newProgressBar ? 1 :0.15)};
        content: '';
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        ${props =>
            props.value  && !props.newProgressBar &&
            `border: solid 1px ${
                !!props.completed ? props.completedColor : props.color
            };`}
        ${props =>
            props.full || props.completed
                ? `
                    border-radius: 12px;`
                : `
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  `};
        ${props =>
            (!props.full && !props.completed) && ` width: ${props.value ? props.value : 0}%;`}

        ${props =>
            props.newProgressBar && props.value === 0 && `width: 4px !important;`}
    }
`;

const ValueLabel = styled.div<{
    color?: string;
    isSmallerScreen?: boolean;
    completed?: boolean;
}>`
    font-size: 11px;
    color: ${props =>
        props.completed ? props.color : props.theme.Colors.steelGrey};
    height: 100%;
    top: 0;
    z-index: 1;
    ${props => !props.isSmallerScreen && 'position: absolute; right: 16px;'}
    display: flex;
    ${props => !!props.isSmallerScreen && 'justify-content: center;'}
    align-items: center;
    padding: 0 5px;
    font-weight: 500;
`;

export const ProgressBar: FC<{
    value: number;
    text?: string;
    color?: string;
    isCompleted?: boolean;
    isFull?: boolean;
    completedColor?: string;
    isSmallerScreen?: boolean;
    newProgressBar?: boolean
}> = ({
    value,
    text,
    color,
    isCompleted,
    isFull,
    completedColor,
    isSmallerScreen,
    newProgressBar 
}) => {
    return (
        <Container>
            <Tray
                value={value}
                color={color || Variables.Colors.skyBlueTwo}
                completed={isCompleted}
                full={isFull}
                completedColor={completedColor || Variables.Colors.topaz}
                newProgressBar={newProgressBar}
            >
                <ValueLabel
                    color={completedColor || Variables.Colors.topaz}
                    isSmallerScreen={isSmallerScreen}
                    completed={isCompleted}
                >
                    {newProgressBar ? null : text
                        ? text
                        : isCompleted
                        ? `Completed on ${value}%`
                        : `${value}%`}
                </ValueLabel>
            </Tray>
        </Container>
    );
};
