import { FC, Fragment } from 'react';

import { Tooltip } from '../../../Tooltip';

import { TableBodyWrapperProps } from './types';
import { TableBody } from './table-body';
import { TableRow } from '../table-row';
import { TableCheckbox } from '../table-checkbox';
import {
    DefaultTeamIconHolder,
    DefaultTeamIconHolderWrapper,
    TableCheckboxCell,
} from './styles';
import Star from '../../../../icons/Star';
import { TableCell } from '../table-cell';
import { DynamicTableField } from './dynamic-field';
import TableAssignedLabelTags from '../TableLabelTags/TableAssignedLabelTags';

const TableBodyWrapper: FC<TableBodyWrapperProps> = props => {
    const { data, rowConfig } = props;

    const {
        cells,
        onRowClick,
        isRowChecked,
        onRowCheckHandler,
        isLabelTagsOpen,
        getLabelTagsProps,
        getClassName,
        getDataTest,
        dataTest,
        size = undefined,
        shouldShowAllMembers = false,
    } = rowConfig;

    return (
        <TableBody>
            {data?.map((row: any) => {
                const dataTestValue = getDataTest?.(row) ?? dataTest;
                return (
                    <Fragment key={row?.id}>
                        <TableRow
                            key={row?.id}
                            onClick={
                                onRowClick ? () => onRowClick?.(row) : undefined
                            }
                            className={getClassName?.(row)}
                            dataTest={dataTestValue}
                        >
                            {onRowCheckHandler && isRowChecked && (
                                <TableCheckboxCell>
                                    {shouldShowAllMembers &&
                                    shouldShowAllMembers?.(row) ? (
                                        <Tooltip
                                            label="This team cannot be modified"
                                            preset="checkbox-icon"
                                        >
                                            <DefaultTeamIconHolderWrapper>
                                                <DefaultTeamIconHolder data-test={dataTestValue && `${dataTestValue}-checkbox-star`}>
                                                    <Star />
                                                </DefaultTeamIconHolder>
                                            </DefaultTeamIconHolderWrapper>
                                        </Tooltip>
                                    ) : (
                                        <TableCheckbox
                                            checked={
                                                isRowChecked?.(row) ?? false
                                            }
                                            handleCheck={e =>
                                                onRowCheckHandler?.(e, row)
                                            }
                                            size={size}
                                            dataTest={dataTestValue && `${dataTestValue}-checkbox`}
                                        />
                                    )}
                                </TableCheckboxCell>
                            )}
                            {cells?.map(cell => (
                                <TableCell
                                    className={cell?.cellClassName}
                                    shouldShowEmptyCell={cell?.shouldShowEmptyCell?.(
                                        row
                                    )}
                                    width={cell?.width}
                                >
                                    <DynamicTableField
                                        {...cell}
                                        currentRowData={row}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>

                        {isLabelTagsOpen?.(row) && (
                            <TableAssignedLabelTags
                                {...getLabelTagsProps?.(row)}
                            />
                        )}
                    </Fragment>
                );
            })}
        </TableBody>
    );
};

export default TableBodyWrapper;
