import React, { FC, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { Loading } from '../LoadingCopmonent';

const StyledLinkText = styled.a<{
    fontSize?: number;
    weight?: string;
    color?: string;
    disabled?: boolean;
}>`
    color: ${props =>
        props.disabled
            ? props.theme.Colors.cloudyBlue
            : props.color
            ? props.color
            : props.theme.Colors.darkSkyBlue};
    font-size: ${props => props.fontSize || 13}px;
    cursor: pointer;
    font-weight: ${props => props.weight || 600};
    user-select: none;
    max-width: 100%;
    word-break: keep-all;
    height: 25px;
    &:hover {
        color: ${props => props.theme.Colors.lightBlue};
    }
    &:active {
        color: ${props => props.theme.Colors.windowsBlue};
    }
    ${props => props.disabled && 'pointer-events: none'}
`;

export const LinkText: FC<{
    weight?: string;
    fontSize?: number;
    children: ReactNode;
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
    className?: string;
    color?: string;
    loading?: boolean;
    disabled?: boolean;
    dataTest?: string;
}> = ({
    weight,
    fontSize,
    children,
    onClick,
    className,
    color,
    loading,
    disabled,
    dataTest
}) => {
    return (
        <StyledLinkText
            className={className}
            weight={weight}
            fontSize={fontSize}
            onClick={onClick}
            color={color}
            disabled={disabled}
            data-test={dataTest}
        >
            {loading ? <Loading /> : children}
        </StyledLinkText>
    );
};

export default LinkText;
