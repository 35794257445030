import { ErrorMessagesInterface } from '../ErrorMessages';
import { Action } from 'redux';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export type DisplayMessageAction = Action<typeof ADD_MESSAGE> & {
    error: ErrorMessagesInterface;
};
export function displayMessage(
    error: ErrorMessagesInterface
): DisplayMessageAction {
    return {
        type: ADD_MESSAGE,
        error,
    };
}

export type HideMessageAction = Action<typeof REMOVE_MESSAGE>;
export function hideMessage(): HideMessageAction {
    return {
        type: REMOVE_MESSAGE,
    };
}

export type ErrorMessagesActions = DisplayMessageAction | HideMessageAction;
