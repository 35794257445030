import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ListContentContainer from './ListContent';
import PractisSetBuilder from './PractisSetBuilder';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { getPractisSetState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import {
    PractisSetContent,
    PractisSetContentEntities,
} from '../../../../../../constants/interfaces/PractisSets';
import { Scenario } from '../../../../../../constants/interfaces/Scenario';
import { useUpdatePractisContentService } from '../../../store/services';
import { Challenge } from '../../../../../../constants/interfaces/Challenge';
import { PractisSetEntityTypes } from '../../../tools';

export const PRACTIS_SET_BUILDER_DROP_ID = 'PRACTIS_SET_BUILDER_DROP_ID';
export const PRACTIS_SET_SCENARIO_LIST_DROP_ID =
    'PRACTIS_SET_SCENARIO_LIST_DROP_ID';
export const PRACTIS_SET_CHALLENGER_LIST_DROP_ID =
    'PRACTIS_SET_CHALLENGER_LIST_DROP_ID';
export const PRACTIS_SET_DRAGGABLE_ID = 'PRACTIS_SET_DRAGGABLE_ID';

const Content: React.FC<{
    allScenarios: Scenario[];
    allChallenges: Challenge[];
    practisSetContent: PractisSetContent[];
    handleUpdatePractisContent: (data: PractisSetContent[]) => void;
}> = ({
    allScenarios,
    allChallenges,
    practisSetContent,
    handleUpdatePractisContent,
}) => {
    const [content, setContent] =
        useState<PractisSetContent[]>(practisSetContent);

    const positionRef = useRef(0);    

    useEffect(() => {
        setContent(practisSetContent);
    }, [practisSetContent]);

    const reorderAndUpdate = useCallback((result: PractisSetContent[]) => {
        const reOrderedResult = result.map((item, index) => ({
            ...item,
            position: index,
        }));
        setContent(reOrderedResult);
        handleUpdatePractisContent(reOrderedResult);
    }, [handleUpdatePractisContent]);

    const changePosition = useCallback((startIndex: number, endIndex: number) => {
        const result = Array.from(content);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        reorderAndUpdate(result);
    }, [reorderAndUpdate, content]);

    const addScenarioToPractisSet = useCallback((startIndex: number, endIndex: number) => {
        const addedScenario = allScenarios[startIndex];
        
        if (positionRef.current === endIndex) {
            positionRef.current++;
        }

        const addedContent = {
            id: addedScenario?.id ?? 0,
            uniqueId: addedScenario?.uniqueId ?? '',
            type: PractisSetEntityTypes.SCENARIO,
            position: endIndex,
            minRepsCount: 1,
            scenario: addedScenario,
        } as PractisSetContent;

        const result = Array.from(content);

        result.splice(endIndex, 0, addedContent);
        reorderAndUpdate(result);
    }, [allScenarios, content, reorderAndUpdate]);

    const addScenarioAsLastToPractisSet = useCallback((
        scenarioId: number | string | undefined
    ) => {

        if (!scenarioId) return;
        const result = Array.from(content);

        const addedScenario = allScenarios.filter(
            item => item.id === scenarioId
        )[0];

        const addedContent = {
            id: addedScenario?.id ?? 0,
            uniqueId: addedScenario?.uniqueId ?? '',
            type: PractisSetEntityTypes.SCENARIO,
            minRepsCount: 1,
            scenario: addedScenario,
            position: positionRef.current,
        } as PractisSetContent;

        positionRef.current++;
        result.splice(content.length, 0, addedContent);
        reorderAndUpdate(result);
    }, [allScenarios, content, reorderAndUpdate]);

    const addChallengeAsLastToPractisSet = useCallback((
        challengeId: number | string | undefined
    ) => {
        if (!challengeId) return;

        const addedChallenge = allChallenges.filter(
            item => item.id === challengeId
        )[0];

        const addedContent = {
            id: addedChallenge?.id ?? 0,
            uniqueId: addedChallenge?.uniqueId ?? '',
            type: PractisSetEntityTypes.CHALLENGE,
            minRepsCount: 1,
            challenge: addedChallenge,
            position: positionRef.current,
        } as PractisSetContent;

        positionRef.current++;
        const result = Array.from(content);
        result.splice(content.length, 0, addedContent);
        reorderAndUpdate(result);
    }, [allChallenges, content, reorderAndUpdate]);

    const addChallengeToPractisSet = useCallback((startIndex: number, endIndex: number) => {
        if (positionRef.current === endIndex) {
            positionRef.current++;
        }

        const result = Array.from(content);
        const addedChallenge = allChallenges[startIndex];
        const addedContent = {
            id: addedChallenge?.id ?? 0,
            uniqueId: addedChallenge?.uniqueId ?? '',
            type: PractisSetEntityTypes.CHALLENGE,
            minRepsCount: 1,
            challenge: addedChallenge,
            position: endIndex,
        } as PractisSetContent;

        result.splice(endIndex, 0, addedContent);
        reorderAndUpdate(result);
    }, [allChallenges, content, reorderAndUpdate]);

    const removeFromPractisSet = useCallback((startIndex: number) => {
        const result = Array.from(content);
        result.splice(result.indexOf(result[startIndex]), 1);
        reorderAndUpdate(result);
    }, [content, reorderAndUpdate]);

    const removePractisSetWithId = useCallback((
        uniqueId: number | string,
        type: PractisSetContentEntities
    ) => {
        const result = Array.from(content).filter(content => {
            return !(content.uniqueId === uniqueId && content.type === type);
        });
        reorderAndUpdate(result);
    }, [content, reorderAndUpdate]);

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (
            result.destination.droppableId === PRACTIS_SET_BUILDER_DROP_ID &&
            result.source.droppableId === result.destination.droppableId
        ) {
            changePosition(result.source.index, result.destination.index);
        }

        if (
            (result.destination.droppableId === PRACTIS_SET_BUILDER_DROP_ID ||
                result.destination.droppableId === 'empty-drop') &&
            result.source.droppableId === PRACTIS_SET_SCENARIO_LIST_DROP_ID
        ) {
            addScenarioToPractisSet(
                result.source.index,
                result.destination.index
            );
        }

        if (
            result.destination.droppableId ===
                PRACTIS_SET_SCENARIO_LIST_DROP_ID &&
            result.source.droppableId !== result.destination.droppableId
        ) {
            removeFromPractisSet(result.source.index);
        }

        if (
            (result.destination.droppableId === PRACTIS_SET_BUILDER_DROP_ID ||
                result.destination.droppableId === 'empty-drop') &&
            result.source.droppableId === PRACTIS_SET_CHALLENGER_LIST_DROP_ID
        ) {
            addChallengeToPractisSet(
                result.source.index,
                result.destination.index
            );
        }

        if (
            result.destination.droppableId ===
                PRACTIS_SET_CHALLENGER_LIST_DROP_ID &&
            result.source.droppableId !== result.destination.droppableId
        ) {
            removeFromPractisSet(result.source.index);
        }
    }, [addChallengeToPractisSet, addScenarioToPractisSet, changePosition, removeFromPractisSet]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <ListContentContainer
                practisSetContent={content}
                addScenarioToBuilder={addScenarioAsLastToPractisSet}
                addChallengeToBuilder={addChallengeAsLastToPractisSet}
            />
            <PractisSetBuilder
                practisSetContent={content}
                handleRemoveContent={removePractisSetWithId}
            />
        </DragDropContext>
    );
};

export const ContentContainer: FC = () => {
    const practisSetContent = useSelector(getPractisSetState).info.content;
    const allScenarios = useSelector(getPractisSetState).scenarios;
    const allChallenges = useSelector(getPractisSetState).challenges;
    const updatePractisContent = useUpdatePractisContentService();
    const uuid = require('uuid/v1');

    return (
        <Content
            practisSetContent={practisSetContent.map(item => ({
                ...item,
                uniqueId: uuid(),
            }))}
            allScenarios={allScenarios}
            allChallenges={allChallenges}
            handleUpdatePractisContent={updatePractisContent}
        />
    );
};

export default ContentContainer;
