import { EnrollmentStatus } from "../interfaces/Enrollments";

export const DEV_ENV_URLS: {[key: string]: {[key: string]: string}} = {
    babylon: {'v1': 'api-dev-babylon.gopractis.com', 'v2': 'api-dev-v2-babylon.gopractis.com'},
    rome: {'v1': 'api-dev-rome.gopractis.com', 'v2': 'api-dev-v2-rome.gopractis.com'},
    develop: {'v1': 'api-dev.gopractis.com', 'v2': 'api-dev-v2.gopractis.com'}
};

export const statusesMap: Record<
EnrollmentStatus,
{ title: string; dataTest?: string }
> = {
    [EnrollmentStatus.PENDING]: {
        title: 'Not Started',
        dataTest: 'not-started',
    },
    [EnrollmentStatus.ENROLLED]: {
        title: 'In Progress',
        dataTest: 'in-progress',
    },
    [EnrollmentStatus.COMPLETED]: {
        title: 'Completed',
        dataTest: 'completed',
    },
};

export const storageKeys = {
    PRACTIS_WEB: 'PRACTIS_WEB',
};