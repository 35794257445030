import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const NoTrainingsFiltering = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 50 50"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.2%"
                            x="-14.3%"
                            y="-1.2%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                d="M27.428 0v3.576c3.912.79 6.857 4.222 6.857 8.338 0 4.116-2.945 7.548-6.857 8.338v27.4H24v-27.4c-3.912-.79-6.857-4.222-6.857-8.338 0-4.116 2.945-7.55 6.857-8.338V.001h3.428zM10.286 0v20.595c3.911.788 6.857 4.22 6.857 8.338 0 4.115-2.946 7.548-6.857 8.337v10.382h-3.43V37.27C2.946 36.48 0 33.047 0 28.933c0-4.118 2.945-7.551 6.856-8.338V0h3.43zM8.57 23.828c-2.84 0-5.142 2.285-5.142 5.106 0 2.818 2.302 5.104 5.142 5.104 2.84 0 5.143-2.286 5.143-5.104 0-2.82-2.303-5.106-5.143-5.106zM44.57 0l-.001 27.152h-3.427V0h3.428zM25.714 6.808c-2.84 0-5.143 2.286-5.143 5.106 0 2.819 2.302 5.104 5.143 5.104 2.84 0 5.143-2.284 5.143-5.104 0-2.82-2.304-5.106-5.143-5.106zM38.5 48L36 45.765 41.147 40.554 36 35.341 38.5 33.109 43.5 38.172 48.5 33.109 51 35.341 45.852 40.554 51 45.765 48.5 48 43.5 42.937z"
                                transform="translate(-818 -574) translate(759 574) translate(59)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default NoTrainingsFiltering;
