import styled from 'styled-components';

export const ErrorTitle = styled.div`
    font-family: Merriweather;
    color: var(--ps-white-1);
    padding-top: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
`;

export const ErrorDescription = styled.div`
    font-size: 15px;
    line-height: 20px;
    color: var(--ps-grey-2);
    padding-top: 8px;
    padding-bottom: 4px;

    &:last-child {
        padding-bottom: 0;
    }

    a {
        color: var(--ps-blue-main);
        text-decoration: none;
    }
`;