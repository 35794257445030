import { FC } from 'react';

import { AvatarField } from './avatar-field';
import { BadgeField } from './badge-field';
import { fieldTypes } from './constants';
import { LabelTags } from './label-tags';
import { ListActionsField } from './list-actions-field';
import { TextField } from './text-field';
import { DynamicTableFieldProps } from './types';

const DynamicTableField: FC<DynamicTableFieldProps> = props => {
    const {
        fieldType,
        fieldProps,
        currentRowData,
        getCustomFieldComponent,
    } = props;

    let component = <div />;

    switch (fieldType) {
        case fieldTypes.TEXT_FIELD:
            component = <TextField {...fieldProps} row={currentRowData} />;
            break;

        case fieldTypes.AVATAR_FIELD:
            component = <AvatarField {...fieldProps} row={currentRowData} />;
            break;
        case fieldTypes.BADGE_FIELD:
            component = <BadgeField {...fieldProps} row={currentRowData} />;
            break;
        case fieldTypes.CUSTOM_FIELD:
            component = getCustomFieldComponent?.(currentRowData) ?? <div />;
            break;
        case fieldTypes.LABEL_TAGS:
            component = <LabelTags {...fieldProps} row={currentRowData} />;
            break;

        case fieldTypes.LIST_ACTIONS:
            component = (
                <ListActionsField {...fieldProps} row={currentRowData} />
            );
            break;

        default:
            break;
    }

    return component;
};

export default DynamicTableField;
