import { forwardRef } from 'react';
import { IconProps } from './types';

export const YearRightArrow = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="m5 3 6 6-6 6" stroke="currentColor" stroke-width="1.5"/>
            </svg>
        );
    }
);

export default YearRightArrow;
