export const ITEMS_PER_PAGE = 20;

export const initialSearchParams = {
    searchTerm: '',
    orderBy: null,
    limit: ITEMS_PER_PAGE,
    numberOfPages: 0,
    offset: 0,
};

