import { History } from 'history';
import { FC, useCallback, useEffect, useState } from 'react';
import { isEmpty as lodashIsEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import MainWrapper from '../../../../ui/wrapper/MainWrapper/MainWrapper';
import { CompanyInterface } from '../../../../constants/interfaces/Company';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../constants/interfaces/filters';
import { ListResult } from '../../../../constants/interfaces/PaginationResult';
import { PractisSets } from '../../../../constants/interfaces/PractisSets';
import { TrainingPractisSet } from '../../../../constants/interfaces/TrainingPractisSet';
import { getCompanyState } from '../../../../pages/CompanySettings/store/reducers';
import ROUTES from '../../../../routes/routes';
import {
    useDeleteTeamPractisSetLabelService,
    useGetPractisSetsReportService,
    useGetTeamInfoTrainingService,
    useSearchTrainingPractisSets,
    useUpdateAllSelectedPractisSetsStateService,
    useUpdateSelectedPractisSetStateService,
} from '../../store/services';
import {
    useTeamMembersState,
    useTrainerPractisSetState,
    useUpdatedProgressState,
} from '../../store/states';
import { formatDate } from '../../../../helpers/functions/date-convert';
import {
    UpdatedLabelsState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../../../labels/store/states';
import { useIfChanged } from '../../../../helpers/hooks/usePreviousData';
import { LoadingComponent } from '../../../../ui/components/LoadingCopmonent';
import { usePermissionsState } from '../../../permissions/store/state';
import { NEW_PERMISSIONS } from '../../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../../helpers/hooks/usePagePermissions';
import { clearSelectedItemIfNotLoaded } from '../../../labels/tools';
import {
    useSaveUserFiltersService,
    useSetAssignLabelsActionService,
} from '../../../labels/store/services';
import { WithLabelsContext } from '../../../portableLabels';
import { TeamsPageNavigation } from '../../components/TeamsPageNavigation';
import { TeamV2 } from '../../../../constants/interfaces/Team';
import { UserProfile } from '../../../../constants/interfaces/User';
import { getProfileState } from '../../../../pages/UserProfile/store/reducers';
import NoTrainings from '../../../../ui/icons/NoTrainings';
import NoTrainingsFiltering from '../../../../ui/icons/NoTrainingsFiltering';
import { Popup } from '../../../../ui/components/Popup';
import { EnrollmentStatus } from '../../../../constants/interfaces/Enrollments';
import FilterByHandler from '../../../../ui/components/Filters/FilterByHandler';
import { countAppliedFiltersCount } from '../TeamMembers/tools';
import TrainingFilters from './components/Filters/TrainingFilters';
import ListActions from './components/ListActions/ListActions';
import { SecondarySearchStateInterface } from '../../../../features/searchState/store/states';
import { SEARCH_STATE } from '../../../../features/searchState/constants';
import { isEmpty } from '../../../../helpers/functions/object-helpers';
import { getSecondarySearchState } from '../../../../features/searchState/store/reducer';
import OverdueFlag from '../../../../ui/icons/OverdueFlag';
import { useStyles, WrapperTableCell, TeamMemberStatusCell } from './styles';
import { TableWrapper } from '../../../../ui/components/table-wrapper';
import { useOrderBy } from '../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../../ui/components/table-wrapper/helper';
import { EmptyCellDash } from '../../../../ui/components/table-wrapper/table/EmptyCellDash';
import { Subheader } from '../../components/SubHeader';
import { normalizeSP } from '../../../../helpers/functions/searchparams-normalize';
import {
    onListenEvent,
    onRemoveEvent,
} from '../../../../helpers/functions/Events';
import { EventNameList, REFRESH_TRAINING_PAGE } from '../../../../helpers/functions/Events/types';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

export const PractisSetProgress: FC<{
    profile?: UserProfile;
    history: History<any>;
    params: { teamId?: string };
    updatedProgress?: PractisSets;
    searchPractisSets(searchParams: SearchParams): void;
    practisSets?: ListResult<TrainingPractisSet>;
    team?: TeamV2;
    company?: CompanyInterface;
    selectedLabels?: number[];
    deleteTeamPractisSetLabel: (practisSetId: number, labelId: number) => void;
    loading?: boolean;
    selectedPractisSets?: number[];
    updateSelectedPractisSet(setId: number): void;
    assignedLabels?: number[];
    updateAllSelectedPractisSets(
        setIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    setAssignLabelsAction(labels: number[]): void;
    getPractisSetReports(
        searchParams: SearchParams,
        practisSetIds: number[] | null,
        teamId: string
    ): void;
    getSingleTeam(teamId: any): void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    updatedLabels?: UpdatedLabelsState;
    searchState: SecondarySearchStateInterface;
}> = ({
    profile,
    history,
    params,
    practisSets,
    team,
    updatedProgress,
    searchPractisSets,
    company,
    selectedLabels,
    deleteTeamPractisSetLabel,
    loading,
    selectedPractisSets,
    updateSelectedPractisSet,
    updateAllSelectedPractisSets,
    getPractisSetReports,
    saveGlobalLabelFilters,
    updatedLabels,
    searchState,
}) => {
    const canAccessPage = useHandleAccessPage();
    const classes = useStyles();

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        orderBy: { field: 'name', asc: true },
        labelIDs: selectedLabels,
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
        filterByStatus: [],
    };

    const usableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState.key === SEARCH_STATE.TRAINING.name
            ? searchState.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        setCustom,
        refreshSearchParams,
        setFilter,
    } = useSearchParamsState(usableParams);
    const ifSearchParamsChanged = useIfChanged(searchParams);
    const ifCompanyChanged = useIfChanged(company && company.id, true);

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const isCheckboxPartiallySelected =
        practisSets &&
        practisSets.items &&
        selectedPractisSets &&
        selectedPractisSets.length < practisSets.items.length;

    const orderBy = searchParams.orderBy;
    const hcName = useOrderBy('name', orderBy, setOrderBy);
    const hcOverdue = useOrderBy('overdue', orderBy, setOrderBy);
    const hcPending = useOrderBy('enrollment_not_started', orderBy, setOrderBy);
    const hcInProgress = useOrderBy(
        'enrollment_in_progress',
        orderBy,
        setOrderBy
    );
    const hcCompleted = useOrderBy('enrollment_completed', orderBy, setOrderBy);
    const hcLastTrainingAt = useOrderBy('last_training', orderBy, setOrderBy);
    const permissions = usePermissionsState();

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: countAppliedFiltersCount(searchParams),
        itemsCount: practisSets?.items.length || 0,
    });

    const handleRedirection = useCallback(
        (id?: number) => {
            if (!id || !team || (team && !team.id)) return;

            if (
                permissions.includes(NEW_PERMISSIONS.VIEW_PRACTIS_SET_PROGRESS)
            ) {
                history.push(
                    ROUTES.TEAMS_PAGES.TRAINING_PAGES.PRACTIS_SETS_DETAILS.replace(
                        ':teamId',
                        team.id ? team.id.toString() : ''
                    ).replace(':practisSetId', id.toString()),
                    {
                        useGoBack: true,
                    }
                );
            }
        },
        [history, permissions, team]
    );

    const handleAssignedLabelDelete = (labelId: number, setId: number) => {
        deleteTeamPractisSetLabel(setId, labelId);
    };

    const handlePractisSetCheck = (e: any, set: TrainingPractisSet) => {
        e.stopPropagation();
        if (!!set.practisSetId) {
            updateSelectedPractisSet(set.practisSetId);
        }
    };

    const updateAllPractisSetsCheck = useCallback(
        (checked: boolean) => {
            const setIds =
                (practisSets &&
                    practisSets.items.map((set: TrainingPractisSet) =>
                        Number(set.practisSetId)
                    )) ||
                [];
            updateAllSelectedPractisSets(
                setIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [practisSets, isCheckboxPartiallySelected, updateAllSelectedPractisSets]
    );

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const onExportSinglePractisClicked = useCallback(
        (practisSetId: any) => {
            if (params && params.teamId) {
                getPractisSetReports(
                    searchParams,
                    [practisSetId],
                    params.teamId
                );
            }
        },
        [getPractisSetReports, params, searchParams]
    );

    const onExportClicked = useCallback(() => {
        if (selectedPractisSets && params && params.teamId) {
            getPractisSetReports(
                isSelectAll
                        ? normalizeSP(searchParams, practisSets?.count)
                        : searchParams,
                !isSelectAll ? selectedPractisSets : null,
                params.teamId
            );
        }
    }, [
        selectedPractisSets,
        params,
        getPractisSetReports,
        isSelectAll,
        searchParams,
        practisSets?.count
    ]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_PRACTIS_SET]);
    }, [canAccessPage]);

    useEffect(() => {
        if (ifSearchParamsChanged) {
            searchParams.limit = ITEMS_PER_PAGE;
            searchPractisSets(searchParams);
        }
    }, [ifSearchParamsChanged, searchParams, searchPractisSets]);

    useEffect(() => {
        if (ifCompanyChanged) {
            history.push(ROUTES.TEAMS);
        }
    }, [ifCompanyChanged, history]);

    useEffect(() => {
        if (updatedProgress) refreshSearchParams();
    }, [updatedProgress, refreshSearchParams]);

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchPractisSets(searchParams);
        setLastRefreshed(new Date());
        updateAllSelectedPractisSets([], true, isCheckboxPartiallySelected);
    }, [
        searchPractisSets,
        searchParams,
        updateAllSelectedPractisSets,
        isCheckboxPartiallySelected,
    ]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (
            selectedPractisSets?.length &&
            selectedPractisSets.length === practisSets?.count
        ) {
            setIsSelectAll(true);
        }
    }, [practisSets?.count, selectedPractisSets]);

    useEffect(() => {
        const filteredPractisSets = practisSets
            ? practisSets.items.map(set => Number(set.practisSetId))
            : [];
        const practisSetsToClear = clearSelectedItemIfNotLoaded(
            selectedPractisSets,
            filteredPractisSets
        );
        if (practisSetsToClear && practisSetsToClear.length > 0) {
            updateAllSelectedPractisSets(practisSetsToClear, false);
        }
    }, [selectedPractisSets, practisSets, updateAllSelectedPractisSets]);

    useEffect(() => {
        return () =>
            updateAllSelectedPractisSets([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedPractisSets([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practisSets]);

    useEffect(() => {
        if (pageIndex > 1) {
            setTimeout(() => {
                setOffset(initialOffset);
            }, 200);
        } else if (pageIndex === 1) {
            setTimeout(() => {
                setOffset(0);
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useEffect(() => {
        selectedLabels && setLabelIDs(selectedLabels);
    }, [selectedLabels, setLabelIDs]);

    useEffect(() => {
        if (updatedLabels && updatedLabels.updateType === 'delete')
            refreshSearchParams();
    }, [updatedLabels, refreshSearchParams]);

    const labelChangeHandler = (ids: number[]) => {
        saveGlobalLabelFilters(ids);
    };

    const filterStatusHandler = (status: EnrollmentStatus[]) => {
        setCustom('filterByStatus', status);
    };

    const filterOverdueHandler = (hasOverdue: boolean) => {
        setFilter({
            field: 'hasOverdue',
            value: hasOverdue,
        });
    };

    const isListEmpty =
        !practisSets || (practisSets && !practisSets.items.length);

    const isFilteredByOverdue = searchParams.filters?.some(
        item => item.field === 'hasOverdue' && item.value === true
    );

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllSelectedPractisSets([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        updateAllPractisSetsCheck(true);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        updateAllPractisSetsCheck(event.target.checked);
        setIsSelectAll(false);
    };

    const isMasterCheckBoxChecked =
        !!selectedPractisSets && !!selectedPractisSets.length;

    const isMasterCheckBoxDisabled =
        !practisSets || (practisSets && practisSets.items.length < 1);

    /**
     * @function onRowClick
     * @param { TrainingPractisSet } set
     * @returns { void }
     */
    const onRowClick = (set: TrainingPractisSet) => {
        handleRedirection(set.practisSetId);
    };

    /**
     * @function checkIsRowChecked
     * @param { TrainingPractisSet } set
     * @returns { boolean }
     */
    const checkIsRowChecked = (set: TrainingPractisSet): boolean =>
        !!selectedPractisSets &&
        !!set.practisSetId &&
        selectedPractisSets.includes(set.practisSetId);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { TrainingPractisSet } set
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, set: TrainingPractisSet) => {
        handlePractisSetCheck(event, set);
        setIsSelectAll(false);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { TrainingPractisSet } set
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (set: TrainingPractisSet): boolean => {
        return labelsOpen === set.practisSetId;
    };

    /**
     * @function onDeleteLabelHandler
     * @param { number } labelId
     * @param { TrainingPractisSet } set
     * @returns { void }
     */
    const onDeleteLabelHandler = (
        labelId: number,
        set: TrainingPractisSet
    ): void => {
        handleAssignedLabelDelete(labelId, set.practisSetId);
    };

    // Listen To Assign Users And Due Date -- Refresh the page after assign users
    useEffect(() => {
        onListenEvent(EventNameList[REFRESH_TRAINING_PAGE], refreshData);

        return () => {
            onRemoveEvent(EventNameList[REFRESH_TRAINING_PAGE], refreshData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainWrapper
            title="Team"
            tabs={<TeamsPageNavigation teamName={team?.name} />}
            subHeader={
                <Subheader
                    practisSetsCount={team?.countPractisSets}
                    usersCount={team?.countMembers}
                    teamLeadersCount={team?.leaders?.length}
                />
            }
            goBackUrl={ROUTES.TEAMS_PAGES.LIST_OF_TEAMS}
            useGoBack={!!history.location.state?.useGoBack}
            subTitle={team?.name}
            htmlPageTitle={`Teams ${team ? `- ${team.name}` : ''} - Practis`}
            dataTest="team"
        >
            {loading && !isSelectAll && <LoadingComponent />}

            <TableWrapper
                tableStates={tableStates}
                data={practisSets?.items}
                selectedLabels={selectedLabels}
                cornered={selectedLabels && !!selectedLabels.length}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: "team-timestamp",
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: practisSets?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: () => {}, // pageIndex change automatically renders next/prev page data
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            countAppliedFiltersCount(searchParams),
                        dataTest: "team-paging",
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled:
                            isListEmpty && !searchParams.searchTerm.length,
                        dataTest: "team-search",
                    },
                    isSelectedItemOptionsVisible:
                        !lodashIsEmpty(selectedPractisSets) &&
                        !lodashIsEmpty(practisSets),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedPractisSets?.length,
                        totalCount: practisSets?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled:
                                !selectedPractisSets ||
                                (selectedPractisSets &&
                                    !selectedPractisSets.length),
                            onExportClicked: onExportClicked,
                            showExportPermissions: [
                                NEW_PERMISSIONS.EXPORT_LIST_PRACTIS_SET,
                            ],
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <TrainingFilters
                                        preSelectedLabels={selectedLabels || []}
                                        onStatusChange={filterStatusHandler}
                                        status={
                                            searchParams.filterByStatus as EnrollmentStatus[]
                                        }
                                        onLabelsChange={labelChangeHandler}
                                        onSuccessApply={() => hide()}
                                        onOverdueChange={filterOverdueHandler}
                                        overdue={isFilteredByOverdue}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={countAppliedFiltersCount(
                                            searchParams
                                        )}
                                        disabled={
                                            (searchParams.searchTerm.length >
                                                0 ||
                                                countAppliedFiltersCount(
                                                    searchParams
                                                ) === 0) &&
                                            isListEmpty
                                        }
                                        dataTest="team-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && practisSets?.items?.length === 0,
                    noEntriesOptions: {
                        icon: NoTrainings,
                        text: 'No Trainings Yet',
                        dataTest: 'no-trainings-yet',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Trainings',
                        dataTest: 'no-trainings-found',
                    },
                    noFilterResultsOptions: {
                        icon: NoTrainingsFiltering,
                        width: '160px',
                        dataTest: 'no-filtering-criteria',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'training-master-checkbox'
                    },
                    columns: [
                        {
                            title: 'Practis Sets',
                            width: 16.5,
                            ...(isListEmpty ? null : hcName),
                            disabled: isListEmpty,
                            dataTest: 'practis-sets-column',
                        },
                        {
                            title: 'Overdue',
                            width: 8,
                            ...(isListEmpty ? null : hcOverdue),
                            disabled: isListEmpty,
                            dataTest: 'overdue-column',
                        },
                        {
                            width: 25,
                            title: 'Team Member Status',
                            multiHeaderContainerClassName:
                                classes.teamMemberHeaderContainer,
                            disabled: isListEmpty,
                            dataTest: 'team-member-status-column',
                            subColumns: [
                                {
                                    title: 'Not Started',
                                    ...(isListEmpty ? null : hcPending),
                                    disabled: isListEmpty,
                                    dataTest: 'not-started-column',
                                    width: 28.5,
                                },
                                {
                                    title: 'In Progress',
                                    ...(isListEmpty ? null : hcInProgress),
                                    disabled: isListEmpty,
                                    dataTest: 'in-progress-column',
                                    width: 28.5,
                                },
                                {
                                    title: 'Completed',
                                    ...(isListEmpty ? null : hcCompleted),
                                    disabled: isListEmpty,
                                    dataTest: 'completed-column',
                                    width: 28.5,
                                },
                            ],
                        },
                        {
                            title: 'Last Training',
                            width: 10.3,
                            ...(isListEmpty ? null : hcLastTrainingAt),
                            disabled: isListEmpty,
                            dataTest: 'last-training-column',
                        },
                        {
                            width: 9,
                        },
                        {
                            width: 3,
                        },
                    ],
                    rowConfig: {
                        onRowClick: onRowClick,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (set: TrainingPractisSet) => ({
                            selectedLabels: set.labels || [],
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_PRACTIS_SET_LABEL,
                            ],
                            onDeleteLabel: (labelId: any) =>
                                onDeleteLabelHandler(labelId, set),
                        }),
                        dataTest: 'practis-set-item',
                        cells: [
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                shouldShowEmptyCell: (
                                    set: TrainingPractisSet
                                ) => !set?.name,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (set: TrainingPractisSet) =>
                                        set.name,
                                    dataTest: 'practis-set-name',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    set: TrainingPractisSet
                                ) => !set.isOverdue,
                                fieldProps: {
                                    renderTitle: (set: TrainingPractisSet) =>
                                        set.isOverdue && <span data-test="overdue-icon"><OverdueFlag /></span>,
                                },
                            },
                            {
                                fieldType: 'CUSTOM_FIELD',
                                getCustomFieldComponent: (
                                    set: TrainingPractisSet
                                ) => (
                                    <WrapperTableCell isTeamMembers={true}>
                                        <TeamMemberStatusCell width={33}>
                                            {set?.pendingEnrollmentCount ? (
                                                <span data-test="not-started-count">
                                                    {
                                                        set?.pendingEnrollmentCount
                                                    }
                                                </span>
                                            ) : (
                                                <EmptyCellDash />
                                            )}
                                        </TeamMemberStatusCell>
                                        <TeamMemberStatusCell width={33}>
                                            {set?.inProgressEnrollmentCount ? (
                                                <span data-test="in-progress-count">
                                                    {
                                                        set?.inProgressEnrollmentCount
                                                    }
                                                </span>
                                            ) : (
                                                <EmptyCellDash />
                                            )}
                                        </TeamMemberStatusCell>
                                        <TeamMemberStatusCell width={33}>
                                            {set?.completedEnrollmentCount ? (
                                                <span data-test="completed-count">
                                                    {
                                                        set?.completedEnrollmentCount
                                                    }
                                                </span>
                                            ) : (
                                                <EmptyCellDash />
                                            )}
                                        </TeamMemberStatusCell>
                                    </WrapperTableCell>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    set: TrainingPractisSet
                                ) => !set.lastTraining,
                                fieldProps: {
                                    renderTitle: (set: TrainingPractisSet) =>
                                        formatDate(set.lastTraining),
                                    dataTest: 'last-training-date',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (
                                        set: TrainingPractisSet
                                    ) => ({
                                        open: labelsOpen === set.practisSetId,
                                        toggleOpen: () =>
                                            handleToggleLabels(
                                                set.practisSetId
                                            ),
                                        selectedLabels: set.labels,
                                        dataTest: 'practis-set-labels',
                                    }),
                                    className: classes.labelsCellStyle,
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (
                                        set: TrainingPractisSet
                                    ) => (
                                        <WithLabelsContext.Provider
                                            value={{
                                                reducerName:
                                                    'practisSetsTraining',
                                                scope: 'assignLabels',
                                            }}
                                        >
                                            <ListActions
                                                practisSet={set}
                                                handleRedirection={
                                                    handleRedirection
                                                }
                                                onExportSinglePractisClicked={
                                                    onExportSinglePractisClicked
                                                }
                                                profile={profile}
                                                team={team}
                                                isHidden={
                                                    !!selectedPractisSets?.length
                                                }
                                            />
                                        </WithLabelsContext.Provider>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

export const PractisSetProgressContainer: FC = () => {
    const state = useTrainerPractisSetState();
    const profile = useSelector(getProfileState);
    const history = useHistory();
    const company = useSelector(getCompanyState);
    const params: { teamId: string } = useParams();
    const searchTrainingPractisSets = useSearchTrainingPractisSets(
        params.teamId
    );
    const { data: teamData } = useTeamMembersState();

    const labels = useLabelsState();
    const deleteTeamPractisSetLabel = useDeleteTeamPractisSetLabelService();
    const updatedProgress = useUpdatedProgressState();
    const updateAllSelectedPractisSets =
        useUpdateAllSelectedPractisSetsStateService();
    const updateSelectedPractisSet = useUpdateSelectedPractisSetStateService();
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const getPractisSetReports = useGetPractisSetsReportService();
    const getTeamInfo = useGetTeamInfoTrainingService();
    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const updatedLabels = useUpdatedLabelsState();
    const searchState = useSelector(getSecondarySearchState);

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'practisSetsTraining',
            }}
        >
            <PractisSetProgress
                profile={profile}
                history={history}
                params={params}
                company={company}
                searchPractisSets={searchTrainingPractisSets}
                practisSets={state.data}
                team={teamData}
                selectedPractisSets={state.selectedPractisSets}
                selectedLabels={labels.selected}
                deleteTeamPractisSetLabel={deleteTeamPractisSetLabel}
                updatedProgress={updatedProgress.data}
                loading={state.loading}
                updateAllSelectedPractisSets={updateAllSelectedPractisSets}
                updateSelectedPractisSet={updateSelectedPractisSet}
                assignedLabels={labels.assignedLabels}
                setAssignLabelsAction={setAssignLabelsAction}
                getPractisSetReports={getPractisSetReports}
                getSingleTeam={getTeamInfo}
                saveGlobalLabelFilters={saveGlobalLabelFilters}
                updatedLabels={updatedLabels}
                searchState={searchState}
            />
        </WithLabelsContext.Provider>
    );
};

export default PractisSetProgressContainer;
