import styled from 'styled-components';

import { ArrowRight } from '../../../icons/ArrowRight';

export const Container = styled.div`
    width: webkit-fill-available;
    background-color: ${props => props.theme.Colors.white};
    display: flex;
    align-items: center;
    margin: 16px 8px;
    border-radius: 16px 16px 0 0;
    height: 32px;
`;

export const StyledLabel = styled.div`
    font-size: 11px;
    color: ${props => props.theme.Colors.black};
    margin-right: 12px;
    width: 64px;
    white-space: nowrap;
`;

export const FilteredByWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow: auto;
`;

export const ScrollIconContainer = styled.div`
    display: flex;
    width: 5px;
    height: 9px;
`;

export const ArrowLeft = styled(ArrowRight)`
    transform: scaleX(-1);
`;

export const ScrollWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 100%;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 0.7;
    }
`;

export const StyledFilteredByContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    ::-webkit-scrollbar {
        height: 0;
    }
`;

export const StyledFilteredByItem = styled.div`
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.darkSkyBlue15};
    font-size: 11px;
    color: ${props => props.theme.Colors.darkSkyBlue};
    border: 1px solid ${props => props.theme.Colors.darkSkyBlue};
    text-align: center;
    padding: 0 12px;
    display: flex;
    height: 32px;
    line-height: 32px;
    text-wrap: nowrap;
    margin: 2px 4px;
`;

export const IconHolderForClose = styled.div<{ scaleX?: number }>`
    width: 8px;
    display: flex;
    cursor: pointer;
    color: ${props => props.theme.Colors.darkSkyBlue};
    flex-shrink: 0;
    margin-left: 8px;
`;

