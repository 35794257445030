import React, { ReactNode } from 'react';
import { Popup, PopupProps, DismissProps } from '../Popup';
import { FiltersButton } from './FiltersButton';

interface Props {
    disabled?: boolean;
    filtersCount?: number;
    children(props: PopupProps, dismissProps: DismissProps): ReactNode;
    dataTest?: string;
}

export const FiltersPopupButton = (props: Props) => {
    const { disabled = false, filtersCount = 0, children, dataTest = 'filters-selector' } = props;

    return (
        <Popup<HTMLButtonElement>
            content={children}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            horizontalOffset={322}
        >
            {(ref, { toggleShown, shown }) => (
                <FiltersButton
                    ref={ref}
                    open={shown}
                    toggleOpen={toggleShown}
                    filtersCount={filtersCount}
                    disabled={disabled}
                    dataTest={dataTest}
                />
            )}
        </Popup>
    );
};
