import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { onListenEvent, onRemoveEvent } from '../../../../helpers/functions/Events';
import { CLEAR_PAGING, EventNameList } from '../../../../helpers/functions/Events/types';

import ArrowRight from '../../../icons/ArrowRight';
import { Button, IconContainer, PagerCount } from './styles';

const qs = require('query-string');

export type PagingProps = {
    totalCount: number | undefined;
    itemsPerPage: number;
    onPageChange: (limit: number, offset: number) => void;
    searchOrFiltersApplied: number | boolean | undefined;
    noUrlChange?: boolean;
    dataTest?: string;
};

export const Paging = ({
    totalCount,
    itemsPerPage,
    onPageChange,
    searchOrFiltersApplied,
    noUrlChange,
    dataTest = 'table-paging'
}: PagingProps) => {
    const location = useLocation();
    const history = useHistory();
    const pageFromUrl = qs.parse(location.search).page;
    let [pageNumber, setPageNumber] = useState<number>(
        parseInt(pageFromUrl) || 1
    );
    const [initialized, setInitialized] = useState<boolean>(false);
    const maxValue = Math.ceil(totalCount! / itemsPerPage);
    const pathString = location.pathname.toString();

    useEffect(() => {
        if (pageNumber > maxValue && !initialized) {
            if (maxValue > 0) {
                history.push(`${pathString}?page=${maxValue}`);
                setPageNumber(maxValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxValue]);

    useEffect(() => {
        if (initialized || pageNumber > 1) {
            const offset =
                pageNumber === 1 ? 0 : (pageNumber - 1) * itemsPerPage;
            const limit =
                pageNumber === 1 ? itemsPerPage : pageNumber * itemsPerPage;
            !noUrlChange && history.push(`${pathString}?page=${pageNumber}`);

            onPageChange(limit, offset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, pageNumber]);

    useEffect(() => {
        if (searchOrFiltersApplied) {
            setPageNumber(1);
            if (pageFromUrl) {
                history.push(`${pathString}?page=1`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount]);

    useEffect(() => {
        if (
            totalCount &&
            itemsPerPage &&
            totalCount! % itemsPerPage === 0 &&
            pageNumber > 1
        ) {
            history.push(`${pathString}?page=${pageNumber - 1}`);
            setPageNumber(pageNumber - 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount, itemsPerPage]);



    useEffect(() => {
        onListenEvent(EventNameList[CLEAR_PAGING], () => setPageNumber(1));
        return () => {
            onRemoveEvent(EventNameList[CLEAR_PAGING], () => setPageNumber(1));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    const handlePageChange = (direction: string) => {
        setInitialized(true);
        if (direction === 'next') {
            pageNumber < maxValue
                ? setPageNumber(pageNumber + 1)
                : setPageNumber(maxValue);
        } else if (direction === 'prev') {
            pageNumber < 1
                ? setPageNumber(1)
                : setPageNumber(pageNumber - 1);
        }
    };

    const renderCount = () => {
        if (!totalCount && !searchOrFiltersApplied) {
            return null;
        } else if (!totalCount && searchOrFiltersApplied) {
            return <PagerCount data-test={dataTest && `${dataTest}-counter`}>0 Items</PagerCount>;
        }

        return (
            <PagerCount data-test={dataTest && `${dataTest}-counter`}>
                {(pageNumber - 1) * itemsPerPage < 1
                    ? 1
                    : (pageNumber - 1) * itemsPerPage + 1}
                -
                {pageNumber * itemsPerPage < totalCount!
                    ? pageNumber * itemsPerPage
                    : totalCount}{' '}
                of {totalCount} Items
            </PagerCount>
        );
    };

    return (
        <>
            {renderCount()}
            <Button
                onClick={() => handlePageChange('prev')}
                disabled={pageNumber <= 1 || !totalCount}
                data-test={dataTest && `${dataTest}-prev`}
            >
                <IconContainer>
                    <ArrowRight className="rotate" />
                </IconContainer>
            </Button>
            <Button
                onClick={() => handlePageChange('next')}
                disabled={pageNumber >= maxValue || !totalCount}
                data-test={dataTest && `${dataTest}-next`}
            >
                <IconContainer>
                    <ArrowRight height={12} width={6} />
                </IconContainer>
            </Button>
        </>
    );
};