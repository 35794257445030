import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { useFetchAllLabelsService } from '../../../features/portableLabels/store/hors/withLabels/services';
import { useSearchTeamsService } from '../../../features/portableTeams/store/hors/withTeams/services';
import { useSetLabelFilterActionService } from '../../../features/labels/store/services';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { useFetchAllPractisSetsService } from '../../../features/portablePractisSets/store/hors/withPractisSets/services';
import { useSessionEffect } from '../../../features/common';
import { useUpdatedLabelsState } from '../../../features/labels/store/states';
import { useUpdateTeamsState } from '../../../features/teams/store/states';
import { useUpdatedLibraryPractisSetsState } from '../../../features/library/store/states';
import { getConfig } from '../../../tools/config';
import Storage from '../../../services/Storage';

const PortableLoaderWrapper: FC = () => {
    const fetchLabels = useFetchAllLabelsService();
    const fetchTeams = useSearchTeamsService();
    const fetchAllPractisSets = useFetchAllPractisSetsService();
    const profile = useSelector(getProfileState);
    const setLabels = useSetLabelFilterActionService();

    const updatedLabels = useUpdatedLabelsState();
    const updatedTeams = useUpdateTeamsState();
    const updatedPractisSets = useUpdatedLibraryPractisSetsState();

    useSessionEffect(() => fetchLabels(), [fetchLabels, updatedLabels?.data]);

    useSessionEffect(() => fetchTeams(), [fetchTeams, updatedTeams?.data]);

    useSessionEffect(() => fetchAllPractisSets(), [
        fetchAllPractisSets,
        updatedPractisSets?.data,
    ]);

    useSessionEffect(() => {
        if (!!profile?.labelFilters?.length) {
            setLabels(profile.labelFilters);
        }
    }, [profile?.labelFilters, setLabels]);

    useSessionEffect(() => {
        const config = getConfig();

        const prevAppVersion = Storage.get('appVersion');
        const newAppVersion = config.APP_VERSION;

        if (!prevAppVersion) {
            Storage.set('appVersion', newAppVersion);
        } else if (!isEqual(prevAppVersion, newAppVersion)) {
            Storage.remove('appVersion');
            window.location.reload();
        }
    }, []);
    

    return null;
};

export default PortableLoaderWrapper;
