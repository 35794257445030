import {
    ACTIONS,
    getOverdueLearnersFailure,
    getOverdueLearnersStart,
    getOverdueLearnersSuccess
} from './actions';
import { OverdueLearnersState } from './states';

type OverdueLearnersActions =
    | ReturnType<typeof getOverdueLearnersStart>
    | ReturnType<typeof getOverdueLearnersSuccess>
    | ReturnType<typeof getOverdueLearnersFailure>;

export const overdueLearnersReducer = (
    state = { isLoading: false },
    action: OverdueLearnersActions
): OverdueLearnersState => {
    switch(action.type) {
        case ACTIONS.GET_OVERDUE_LEARNERS_START:
            return {
                ...state,
                isLoading: true
            };
        case ACTIONS.GET_OVERDUE_LEARNERS_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: ''
            };
        case ACTIONS.GET_OVERDUE_LEARNERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}