import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty as lodashIsEmpty, difference } from 'lodash';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import {
    useAccuracyScoreState,
    useUpdatedSubmissionsState,
    selectSelectedAccuracyScores,
} from '../store/states';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { SubmissionV2 } from '../../../constants/interfaces/Reviews';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import {
    useCheckAllAccuracyScoresService,
    useSearchAccuracyScoreService,
    useCheckSingleAccuracyScoreService,
    useArchiveSubmissionsService,
    useRestoreSubmissionsService,
} from '../store/services';
import { useSelector } from 'react-redux';
import { AccuracyScoreActionsHelper } from '../tools';
import styled from 'styled-components';
import { formatDate } from '../../../helpers/functions/date-convert';
import { useHistory } from 'react-router';
import { History } from 'history';
import ROUTES from '../../../routes/routes';

import {
    UpdatedLabelsState,
    useLabelsState,
    useUpdatedLabelsState,
} from '../../labels/store/states';
import { LoadingComponent } from '../../../ui/components/LoadingCopmonent';
import { ActionButton, ActionItem } from '../../../ui/components/ActionButton';
import { CheckPermission } from '../../permissions';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { useHandleAccessPage } from '../../../helpers/hooks/usePagePermissions';
import { UserProfile } from '../../../constants/interfaces/User';
import { usePermissionsState } from '../../permissions/store/state';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { Popup } from '../../../ui/components/Popup';
import FilterByHandler from '../../../ui/components/Filters/FilterByHandler';
import { useSaveUserFiltersService } from '../../labels/store/services';
import { Team } from '../../../constants/interfaces/Team';
import { useUpdateTeamsState } from '../../teams/store/states';
import { WithLabelsContext } from '../../portableLabels';
import { WithTeamsContext } from '../../portableTeams';
import { AccuracyScoreActions, useAccuracyScoreActionsHelper } from '../tools';
import { useSessionEffect } from '../../common';
import SubmissionsNavigationContainer from '../components/SubmissionsNavigation';
import { AccuracyTestsFilters } from '../components/Filters/AccuracyTestFilters';
import AvatarPlaceholder from '../../../ui/components/AvatarPlaceholder/AvatarPlaceholder';
import Accuracy from '../../../ui/icons/Accuracy';
import { getSearchState } from '../../../features/searchState/store/reducer';
import { isEmpty } from '../../../helpers/functions/object-helpers';
import { SearchStateInterface } from '../../../features/searchState/store/states';
import { SEARCH_STATE } from '../../../features/searchState/constants';
import { TableWrapper } from '../../../ui/components/table-wrapper';
import { useStyles } from './styles';
import { useOrderBy } from '../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { useTableStateHelper } from '../../../ui/components/table-wrapper/helper';
import TableTitleOverflowText from '../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { AccuracyTestSubmissionFilters } from '../../../constants/interfaces/SubmissionSearchFilters';
import { useUpdateSubmissionStatusBulkActionService } from '../services/SubmissionsBulkActionsService';
import { useClearPortableScenarios } from '../../portableScenarios/store/hors/withTeams/services';

const qs = require('query-string');

const ITEMS_PER_PAGE = 20;

const Avatar = styled(AvatarPlaceholder)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

const AvatarTile = styled.div`
    display: flex;
    align-items: center;
    flex: 1;

    & + span {
        transform: translateY(3px);
        display: inline-block;
    }
`;

const StyledFirstTableContent = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    min-width: 200px;
`;

const AccuracyScores: FC<{
    profile?: UserProfile;
    accuracyScores?: ListResult<SubmissionV2>;
    searchSubmissions(
        searchParams: SearchParams,
        filters: AccuracyTestSubmissionFilters
    ): void;
    loading?: boolean;
    updatedSubmissions?: number[];
    history: History<any>;
    selectedLabels?: number[];
    onArchiveSubmission?(submissionIds: number[]): Promise<void>;
    onRestoreSubmission?(submissionIds: number[]): Promise<void>;
    selectedSubmissionIds?: number[];
    selectedAccuracyScores?: SubmissionV2[];
    actionsHelper: AccuracyScoreActionsHelper;
    updateAllSelectedSubmissions(
        submissionIds: number[],
        checked: boolean,
        partial?: boolean
    ): void;
    updateSelectedSubmission(submissionId: number): void;
    saveGlobalLabelFilters: (labels: number[] | null) => void;
    updatedLabels?: UpdatedLabelsState;
    updatedTeams?: Team;
    searchState?: SearchStateInterface;
}> = ({
    profile,
    accuracyScores,
    searchSubmissions,
    loading,
    updatedSubmissions,
    history,
    selectedLabels,
    onArchiveSubmission,
    selectedSubmissionIds,
    selectedAccuracyScores,
    actionsHelper,
    updateAllSelectedSubmissions,
    updateSelectedSubmission,
    onRestoreSubmission,
    saveGlobalLabelFilters,
    searchState,
}) => {
    const classes = useStyles();
    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        orderBy: {},
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const useableParams: SearchParams =
        !isEmpty(searchState) &&
        !!searchState?.params &&
        searchState?.key === SEARCH_STATE.ACCURACY.name
            ? searchState?.params
            : initialSearchParams;

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setOffset,
        refreshSearchParams,
    } = useSearchParamsState(useableParams);

    const [lastRefreshed, setLastRefreshed] = useState(new Date());
    const [labelsOpen, setLabelsOpen] = useState<number | undefined>();
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const initialFilters = searchParams.customFilters as AccuracyTestSubmissionFilters ?? {
        showArchived: false,
        teamIds: [],
        scenarioIds: [],
        labelIds: selectedLabels ?? [],
    };
    const [filters, setFilters] = useState<AccuracyTestSubmissionFilters>(initialFilters);

    const canAccessPage = useHandleAccessPage();
    const clearPortableScenarios = useClearPortableScenarios();

    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page);
    const initialOffset = pageIndex
        ? pageIndex === 1
            ? 0
            : (pageIndex - 1) * ITEMS_PER_PAGE
        : 0;

    const isCheckboxPartiallySelected =
        accuracyScores &&
        accuracyScores.items &&
        selectedSubmissionIds &&
        selectedSubmissionIds.length < accuracyScores.items.length;

    const refreshData = useCallback(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchSubmissions(searchParams, filters);
        setLastRefreshed(new Date());
        updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    }, [
        searchParams,
        initialOffset,
        searchSubmissions,
        updateAllSelectedSubmissions,
        isCheckboxPartiallySelected,
        filters,
    ]);

    const handleViewSubmissionDetails = (submissionId?: number | string) => {
        if (!submissionId) return;

        history.push(
            ROUTES.SUBMISSION_SETTINGS.ACCURACY_TESTS.SINGLE.replace(
                ':submissionId',
                submissionId.toString()
            ),
            {
                useGoBack: true,
            }
        );
    };

    const handleViewPractisSetReport = (
        userId: number,
        enrollmentId: number
    ) => {
        history.push(
            ROUTES.TRAINEE_DETAILS
                .replace(
                    ':userId',
                    userId.toString()
                )
                .replace(
                    ':enrollmentId',
                    enrollmentId.toString()
                ),
            { useGoBack: true }
        );
    };

    const permissions = usePermissionsState();

    const handleViewProfile = (userId: number) => {
        if (permissions.includes(NEW_PERMISSIONS.VIEW_USER_PERFORMANCE)) {
            history.push(
                ROUTES.USER_PERFORMANCE.replace(':userId', userId.toString()),
                { useGoBack: true }
            );
        } else {
            return;
        }
    };

    const archiveSubmission = (submissionIds: number[]) => {
        if (!submissionIds || (!!submissionIds && !submissionIds.length))
            return;

        onArchiveSubmission && onArchiveSubmission(submissionIds);
    };

    const restoreSubmission = (submissionIds: number[]) => {
        if (!submissionIds || (!!submissionIds && !submissionIds.length))
            return;
        onRestoreSubmission && onRestoreSubmission(submissionIds);
    };

    const updateAllSubmissionsCheck = useCallback(
        (checked: boolean) => {
            const submissionIds =
                (accuracyScores &&
                    accuracyScores.items.map((accuracyScore: SubmissionV2) =>
                        Number(accuracyScore.id)
                    )) ||
                [];
            updateAllSelectedSubmissions(
                submissionIds,
                checked,
                isCheckboxPartiallySelected
            );
        },
        [
            accuracyScores,
            isCheckboxPartiallySelected,
            updateAllSelectedSubmissions,
        ]
    );

    const handleSubmissionCheck = (e: any, accuracyScore: SubmissionV2) => {
        e.stopPropagation();
        if (!!accuracyScore.id) {
            updateSelectedSubmission(accuracyScore.id);
        }
    };
    const updateSubmissionStatusBulkActionService =
        useUpdateSubmissionStatusBulkActionService(
            'SCENARIO',
            searchParams,
            filters,
            accuracyScores?.count ?? 0,
            refreshData
        );

    const archiveSubmissions = useCallback(() => {
        if (isSelectAll) {
            updateSubmissionStatusBulkActionService('Archive');
        } else {
            if (
                !selectedSubmissionIds ||
                (!!selectedSubmissionIds && !selectedSubmissionIds.length) ||
                !onArchiveSubmission
            ) {
                return;
            }

            onArchiveSubmission(selectedSubmissionIds).then((result: any) => {
                if (!!result) {
                    updateAllSelectedSubmissions(
                        selectedSubmissionIds,
                        false,
                        false
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSubmissionIds,
        onArchiveSubmission,
        updateAllSelectedSubmissions,
    ]);

    const restoreSubmissions = useCallback(() => {
        if (isSelectAll) {
            updateSubmissionStatusBulkActionService('Restore');
        } else {
            if (
                !selectedSubmissionIds ||
                (!!selectedSubmissionIds && !selectedSubmissionIds.length) ||
                !onRestoreSubmission
            ) {
                return;
            }

            onRestoreSubmission(selectedSubmissionIds).then((result: any) => {
                if (!!result) {
                    updateAllSelectedSubmissions(
                        selectedSubmissionIds,
                        false,
                        false
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedSubmissionIds,
        onRestoreSubmission,
        updateAllSelectedSubmissions,
    ]);

    const handleApplyFilters = useCallback(
        (newFilters: AccuracyTestSubmissionFilters) => {
            saveGlobalLabelFilters(newFilters.labelIds);
            setFilters(newFilters);
        },
        [saveGlobalLabelFilters]
    );

    const orderBy = searchParams.orderBy;
    const sbFirstName = useOrderBy('user_name', orderBy, setOrderBy);
    const sbPractisSetName = useOrderBy('pset_name', orderBy, setOrderBy);
    const sbScenarioTitle = useOrderBy('scenario_title', orderBy, setOrderBy);
    const sbScore = useOrderBy('accuracy', orderBy, setOrderBy);
    const sbSubmittedAt = useOrderBy('submission_date', orderBy, setOrderBy);

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(undefined);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const appliedFiltersCount =
        filters.labelIds.length +
        filters.teamIds.length +
        filters.scenarioIds.length +
        (filters.showArchived ? 1 : 0);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: appliedFiltersCount,
        itemsCount: accuracyScores?.items.length || 0,
        filtersAreAlwaysEnabled: true,
    });

    const bulkActions = useMemo(
        () => actionsHelper.getBulkActions(selectedAccuracyScores),
        [selectedAccuracyScores, actionsHelper]
    );

    useSessionEffect(() => {
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        searchSubmissions(searchParams, filters);
    }, [searchSubmissions, searchParams, filters]);

    useEffect(() => {
        if (updatedSubmissions) refreshSearchParams();
    }, [updatedSubmissions, refreshSearchParams]);

    useEffect(() => {
        if (
            selectedLabels &&
            (selectedLabels.length !== filters.labelIds.length ||
                difference(selectedLabels, filters.labelIds).length > 0)
        ) {
            setFilters({
                ...filters,
                labelIds: selectedLabels,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLabels, setFilters]);

    useEffect(() => {
        if (pageIndex > 1) {
            setOffset(initialOffset);
        } else if (pageIndex === 1) {
            setOffset(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    useEffect(() => {
        return () => {
            updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
            clearPortableScenarios();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accuracyScores]);

    useEffect(() => {
        if (
            selectedAccuracyScores?.length &&
            selectedAccuracyScores.length === accuracyScores?.count
        ) {
            setIsSelectAll(true);
        }
    }, [accuracyScores?.count, selectedAccuracyScores]);

    useEffect(() => {
        canAccessPage([NEW_PERMISSIONS.LIST_SUBMISSION]);
    }, [canAccessPage]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = (offset: number) => {
        setOffset(offset);
    };

    /**
     * @function onClearSelection
     * @returns { void }
     */
    const onClearSelection = () => {
        updateAllSelectedSubmissions([], true, isCheckboxPartiallySelected);
        setIsSelectAll(false);
    };

    /**
     * @function onSelectAll
     * @returns { void }
     */
    const onSelectAll = () => {
        updateAllSubmissionsCheck(true);
        setIsSelectAll(true);
    };

    /**
     * @function onCheckMasterCheckbox
     * @param { any } event
     * @returns { void }
     */
    const onCheckMasterCheckbox = (event: any) => {
        setIsSelectAll(false);
        updateAllSubmissionsCheck(event.target.checked);
    };

    const isMasterCheckBoxChecked =
        !!selectedSubmissionIds && !!selectedSubmissionIds.length;

    const isMasterCheckBoxDisabled =
        !accuracyScores || (accuracyScores && accuracyScores.items.length < 1);

    /**
     * @functiononRowClick
     * @param { SubmissionV2 } accuracyScore
     * @returns { void }
     */
    const onRowClick = (accuracyScore: SubmissionV2) => {
        handleViewSubmissionDetails(accuracyScore.id);
    };

    /**
     * @function checkIsRowChecked
     * @param { SubmissionV2 } challenge
     * @returns { boolean }
     */
    const checkIsRowChecked = (accuracyScore: SubmissionV2): boolean =>
        !!selectedSubmissionIds &&
        !!accuracyScore.id &&
        selectedSubmissionIds.includes(accuracyScore.id);

    /**
     * @function onRowCheckHandler
     * @param event
     * @param { SubmissionV2 } accuracyScore
     * @returns { void }
     */
    const onRowCheckHandler = (event: any, accuracyScore: SubmissionV2) => {
        setIsSelectAll(false);
        handleSubmissionCheck(event, accuracyScore);
    };

    /**
     * @function checkIsLabelTagsOpen
     * @param { SubmissionV2 } accuracyScore
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (accuracyScore: SubmissionV2): boolean => {
        return labelsOpen === accuracyScore.id;
    };

    return (
        <MainWrapper
            subTitle="Feed"
            tabs={<SubmissionsNavigationContainer dataTest="accuracy-nav" />}
            hideDivider={!!selectedAccuracyScores?.length}
            htmlPageTitle="Feed - Practis"
            dataTest="accuracy"
        >
            {loading && !isSelectAll && <LoadingComponent />}
            <TableWrapper
                tableStates={tableStates}
                data={accuracyScores?.items}
                cornered={selectedLabels && !!selectedLabels.length}
                shouldHideTableDivider={!!selectedAccuracyScores?.length}
                selectedLabels={selectedLabels}
                tableRefreshConfigurations={{
                    lastRefreshed: lastRefreshed,
                    refreshData: refreshData,
                    dataTest: 'accuracy-timestamp',
                }}
                tableToolsOptions={{
                    pagingOptions: {
                        totalCount: accuracyScores?.count ?? 0,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onPageChange: handlePageChange,
                        searchOrFiltersApplied:
                            searchParams.searchTerm.length ||
                            tableStates.showNoFilterResultsState,
                        dataTest: 'accuracy-paging',
                    },
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        dataTest: 'accuracy-search-input',
                    },
                    isSelectedItemOptionsVisible:
                        !lodashIsEmpty(selectedAccuracyScores) &&
                        !lodashIsEmpty(accuracyScores),
                    selectedItemOptions: {
                        isSelectAll: isSelectAll,
                        selectedLength: selectedAccuracyScores?.length,
                        totalCount: accuracyScores?.count,
                        itemsPerPage: ITEMS_PER_PAGE,
                        onClearSelection: onClearSelection,
                        onSelectAll: onSelectAll,
                        bulkActionsConfig: {
                            disabled: bulkActions.areBulkActionsDisabled(),
                            disabledLabel: bulkActions.areItemsEmpty()
                                ? undefined
                                : "Bulk actions can't be applied",
                            archivePermissions: bulkActions.isBulkActionVisible(
                                AccuracyScoreActions.ARCHIVE
                            )
                                ? [NEW_PERMISSIONS.ARCHIVE_SUBMISSION]
                                : [],
                            isArchiveDisabled: bulkActions.isBulkActionDisabled(
                                AccuracyScoreActions.ARCHIVE
                            ),
                            onArchiveSubmissionsSubmit: archiveSubmissions,
                            restorePermissions: bulkActions.isBulkActionVisible(
                                AccuracyScoreActions.RESTORE
                            )
                                ? [NEW_PERMISSIONS.RESTORE_SUBMISSION]
                                : [],
                            isRestoreDisabled: bulkActions.isBulkActionDisabled(
                                AccuracyScoreActions.RESTORE
                            ),
                            onRestoreSubmissionsSubmit: restoreSubmissions,
                            isSelectAll: isSelectAll,
                            totalCount: accuracyScores?.count,
                        },
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <AccuracyTestsFilters
                                        filters={filters}
                                        onSuccessApply={newFilters => {
                                            handleApplyFilters(newFilters);
                                            hide();
                                        }}
                                    />
                                )}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                horizontalOffset={256}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        toggleOpen={toggleShown}
                                        filtersCount={appliedFiltersCount}
                                        disabled={
                                            // tableStates.disableFilters
                                            false
                                        }
                                        dataTest="accuracy-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState:
                        !loading && accuracyScores?.items?.length === 0,
                    noEntriesOptions: {
                        icon: Accuracy,
                        text: 'No Accuracy Tests Yet',
                        dataTest: 'accuracy-no-entries',
                    },
                    noSearchResultsOptions: {
                        entityName: <>Accuracy Tests</>,
                        dataTest: 'accuracy-no-search-results',
                    },
                }}
                configurations={{
                    masterCheckBoxConfig: {
                        checked: isMasterCheckBoxChecked,
                        disabled: isMasterCheckBoxDisabled,
                        partial: isCheckboxPartiallySelected || !isSelectAll,
                        handleCheck: onCheckMasterCheckbox,
                        dataTest: 'accuracy-master-checkbox',
                    },
                    columns: [
                        {
                            title: 'Users',
                            width: 24,
                            ...sbFirstName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'accuracy-users-column',
                        },
                        {
                            title: 'Accuracy Test',
                            width: 14,
                            ...sbScore,
                            disabled: tableStates.disableSorting,
                            dataTest: 'accuracy-test-column',
                        },
                        {
                            title: 'Submitted',
                            width: 12,
                            ...sbSubmittedAt,
                            disabled: tableStates.disableSorting,
                            dataTest: 'accuracy-submitted-column',
                        },
                        {
                            title: 'Scenario',
                            width: 10,
                            ...sbScenarioTitle,
                            disabled: tableStates.disableSorting,
                            dataTest: 'accuracy-scenario-column',
                        },

                        {
                            title: 'Practis Set',
                            width: 15,
                            ...sbPractisSetName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'accuracy-practis-set-column',
                        },
                        {
                            width: 2,
                        },
                        {
                            width: 2,
                        },
                    ],
                    rowConfig: {
                        onRowClick: onRowClick,
                        onRowCheckHandler: onRowCheckHandler,
                        isRowChecked: checkIsRowChecked,
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (accuracyScore: SubmissionV2) => ({
                            selectedLabels: accuracyScore.userLabels ?? [],
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_CHALLENGE_LABEL,
                            ],
                        }),
                        dataTest: 'accuracy-item',
                        cells: [
                            {
                                fieldType: 'CUSTOM_FIELD',
                                cellClassName: classes.customTableCellStyle,
                                getCustomFieldComponent: (
                                    accuracyScore: SubmissionV2
                                ) => (
                                    <StyledFirstTableContent>
                                        <AvatarTile>
                                            <Avatar
                                                className="avatar"
                                                customProfile={{
                                                    firstName:
                                                        accuracyScore.user
                                                            .firstName,
                                                    lastName:
                                                        accuracyScore.user
                                                            .lastName,
                                                    picture:
                                                        accuracyScore.user
                                                            .imageUrl,
                                                    status: accuracyScore.user
                                                        .status,
                                                }}
                                                size="sm"
                                                dataTest="accuracy-item-user-avatar"
                                            />
                                            <TableTitleOverflowText
                                                isCurrent={
                                                    accuracyScore.user &&
                                                    profile &&
                                                    accuracyScore.user.id ===
                                                        profile.id
                                                }
                                                dataTest="accuracy-item-user-full-name"
                                            >
                                                {accuracyScore.user &&
                                                    accuracyScore.user
                                                        .firstName +
                                                        ' ' +
                                                        accuracyScore.user
                                                            .lastName}
                                            </TableTitleOverflowText>
                                        </AvatarTile>
                                    </StyledFirstTableContent>
                                ),
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    accuracyScore: SubmissionV2
                                ) => !accuracyScore.accuracy?.accuracy,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        accuracyScore: SubmissionV2
                                    ) =>
                                        `${Math.floor(
                                            accuracyScore.accuracy.accuracy *
                                                100
                                        )}%`,
                                    dataTest: 'accuracy-item-score',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    accuracyScore: SubmissionV2
                                ) => !accuracyScore.createdAt,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        accuracyScore: SubmissionV2
                                    ) => formatDate(accuracyScore.createdAt),
                                    dataTest: 'accuracy-item-submission-date',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                cellClassName: classes.minWidthTableCell,
                                shouldShowEmptyCell: (
                                    accuracyScore: SubmissionV2
                                ) => !accuracyScore.scenario?.title,
                                fieldProps: {
                                    isOverFlowText: true,
                                    className: classes.minWidthTableCell,
                                    renderTitle: (
                                        accuracyScore: SubmissionV2
                                    ) => accuracyScore.scenario?.title,
                                    dataTest: 'accuracy-item-scenario',
                                },
                            },
                            {
                                fieldType: 'TEXT_FIELD',
                                shouldShowEmptyCell: (
                                    accuracyScore: SubmissionV2
                                ) => !accuracyScore.practisSet?.name,
                                fieldProps: {
                                    isOverFlowText: true,
                                    renderTitle: (
                                        accuracyScore: SubmissionV2
                                    ) => accuracyScore.practisSet?.name,
                                    dataTest: 'accuracy-item-practis-set',
                                },
                            },
                            {
                                fieldType: 'LABEL_TAGS',
                                fieldProps: {
                                    getLabelTagsProps: (
                                        accuracyScore: SubmissionV2
                                    ) => ({
                                        open: labelsOpen === accuracyScore.id,
                                        toggleOpen: () =>
                                            handleToggleLabels(
                                                accuracyScore.id
                                            ),
                                        selectedLabels:
                                            accuracyScore.userLabels ?? [],
                                        dataTest: 'accuracy-item-labels',
                                    }),
                                },
                            },
                            {
                                fieldType: 'LIST_ACTIONS',
                                fieldProps: {
                                    getListActionsComponent: (
                                        accuracyScore: SubmissionV2
                                    ) => (
                                        <ActionButton
                                            customWidth={224}
                                            isHidden={
                                                !!selectedAccuracyScores?.length
                                            }
                                            dataTest="accuracy-item-menu"
                                        >
                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_SUBMISSION,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewSubmissionDetails(
                                                            accuracyScore.id
                                                        );
                                                    }}
                                                    dataTest="view-accuracy-test-action"
                                                >
                                                    View Accuracy Test
                                                </ActionItem>
                                            </CheckPermission>

                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_USER_PRACTIS_SET_REPORT,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewPractisSetReport(
                                                            accuracyScore.user
                                                                .id,
                                                            accuracyScore.enrollmentId
                                                        );
                                                    }}
                                                    dataTest="view-practis-set-report-action"
                                                >
                                                    View Practis Set Report
                                                </ActionItem>
                                            </CheckPermission>

                                            <CheckPermission
                                                permissions={[
                                                    NEW_PERMISSIONS.VIEW_USER_PERFORMANCE,
                                                ]}
                                            >
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        handleViewProfile(
                                                            accuracyScore.user
                                                                .id
                                                        );
                                                    }}
                                                    dataTest="view-profile-action"
                                                >
                                                    View Profile
                                                </ActionItem>
                                            </CheckPermission>

                                            {actionsHelper.canArchive(
                                                accuracyScore
                                            ) && (
                                                <ActionItem
                                                    destructive={true}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        archiveSubmission([
                                                            accuracyScore.id,
                                                        ]);
                                                    }}
                                                    dataTest="archive-action"
                                                >
                                                    Archive
                                                </ActionItem>
                                            )}
                                            {actionsHelper.canRestore(
                                                accuracyScore
                                            ) && (
                                                <ActionItem
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        restoreSubmission([
                                                            accuracyScore.id,
                                                        ]);
                                                    }}
                                                    dataTest="restore-action"
                                                >
                                                    Restore
                                                </ActionItem>
                                            )}
                                        </ActionButton>
                                    ),
                                },
                            },
                        ],
                    },
                }}
            />
        </MainWrapper>
    );
};

const AccuracyScoreContainer = () => {
    const history = useHistory();
    const profile = useSelector(getProfileState);
    const accuracyScores = useAccuracyScoreState();
    const loading = useAccuracyScoreState().loading;
    const updatedSubmissions = useUpdatedSubmissionsState();

    const searchAccuracyScores = useSearchAccuracyScoreService();
    const archiveSubmissions = useArchiveSubmissionsService(true);

    const restoreSubmissions = useRestoreSubmissionsService(true);
    const updateAllSelectedSubmissions = useCheckAllAccuracyScoresService();
    const updateSelectedSubmission = useCheckSingleAccuracyScoreService();

    const saveGlobalLabelFilters = useSaveUserFiltersService();
    const updatedLabels = useUpdatedLabelsState();
    const updatedTeamsState = useUpdateTeamsState();
    const actionsHelper = useAccuracyScoreActionsHelper();

    const labels = useLabelsState();
    const searchState = useSelector(getSearchState);

    const selectedAccuracyScores = useMemo(
        () => selectSelectedAccuracyScores(accuracyScores),
        [accuracyScores]
    );

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'submissions' }}>
            <WithTeamsContext.Provider value={{ reducerName: 'submissions' }}>
                <AccuracyScores
                    profile={profile}
                    accuracyScores={accuracyScores.data}
                    selectedSubmissionIds={accuracyScores.checked}
                    selectedAccuracyScores={selectedAccuracyScores}
                    actionsHelper={actionsHelper}
                    loading={loading || labels.saveLoading}
                    searchSubmissions={searchAccuracyScores}
                    updatedSubmissions={updatedSubmissions.data}
                    history={history}
                    selectedLabels={labels.selected}
                    onArchiveSubmission={archiveSubmissions}
                    onRestoreSubmission={restoreSubmissions}
                    updateAllSelectedSubmissions={updateAllSelectedSubmissions}
                    updateSelectedSubmission={updateSelectedSubmission}
                    saveGlobalLabelFilters={saveGlobalLabelFilters}
                    updatedLabels={updatedLabels}
                    updatedTeams={updatedTeamsState.data}
                    searchState={searchState}
                />
            </WithTeamsContext.Provider>
        </WithLabelsContext.Provider>
    );
};

export default AccuracyScoreContainer;