import { forwardRef } from 'react';
import { IconProps } from './types';

export const FlagInactive = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.996 1.982a1.928 1.928 0 0 0-1.182-.425c-.21.014-.415.067-.607.155L2.176 2.843l1.88 5.188 1.182-.446a4.782 4.782 0 0 1 2.319-.27c.783.103 1.53.398 2.178.861.316.222.689.34 1.07.343.222 0 .441-.042.648-.125l2.607-.985-1.779-4.94-.616.24a4.777 4.777 0 0 1-4.669-.727zM4.642.249A3.31 3.31 0 0 1 5.814 0a3.368 3.368 0 0 1 2.142.747 3.32 3.32 0 0 0 2.021.716c.4 0 .797-.075 1.172-.218l2.022-.768L16 8.332l-4.042 1.525a3.306 3.306 0 0 1-1.172.218 3.384 3.384 0 0 1-1.94-.622 3.257 3.257 0 0 0-1.86-.623c-.4.002-.795.073-1.172.208l-1.276.478 1.943 5.394a.785.785 0 0 1-.18.877.745.745 0 0 1-.265.161.792.792 0 0 1-.263.052.745.745 0 0 1-.43-.14.776.776 0 0 1-.277-.369L.054 1.661A.818.818 0 0 1 .24.79.776.776 0 0 1 .498.622a.807.807 0 0 1 .263 0 .753.753 0 0 1 .46.127c.136.091.24.225.298.381l.088.245L4.642.249z"
                    fill="currentColor"
                />
            </svg>
        );
    }
);
        
export default FlagInactive;
