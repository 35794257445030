import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { CheckPermission } from '../../../features/permissions';

export type NavigationHint = (props: { dismiss: () => void }) => ReactNode;

export type MenuInterface = {
    key: string;
    title: string;
    url?: string;
    permissions?: NEW_PERMISSIONS[];
    hint?: NavigationHint;
    hintFirstTime?: boolean;
    notReviewedCount?: number
};

type NavigationInterface = {
    menu: Array<MenuInterface>;
    active?: string;
    setActive?: (key: string) => void;
    height?: number;
    widthPercent?: number;
    url?: string;
    navigationItemStyles?: CSSProperties;
    activeNavigationItemStyles?: CSSProperties;
    disableHoverEffect?: boolean;
    fontSize?: string;
    dataTest?: string;
};

const StyledNavigationContainer = styled.div`
    display: flex;
`;

const Navigation: React.FC<NavigationInterface> = ({
    menu,
    active,
    setActive,
    height,
    widthPercent,
    navigationItemStyles,
    activeNavigationItemStyles,
    disableHoverEffect,
    fontSize,
    dataTest
}) => {
    let menuItems;
    menuItems = menu.map((menu: MenuInterface) => {
        if (!menu.url) {
            return (
                <CheckPermission key={menu.key} permissions={menu.permissions}>
                    <NavigationItem
                        title={menu.title}
                        action={() => setActive && setActive(menu.key)}
                        active={active === menu.key}
                        height={height}
                        widthPercent={widthPercent}
                        navigationItemStyles={navigationItemStyles}
                        activeNavigationItemStyles={activeNavigationItemStyles}
                        disableHoverEffect={disableHoverEffect}
                        fontSize={fontSize}
                        name={menu.key}
                        notReviewedCount={menu.notReviewedCount}
                        dataTest={dataTest}
                    />  
                </CheckPermission>
            );
        } else {
            return (
                <CheckPermission key={menu.key} permissions={menu.permissions}>
                    <NavigationItem
                        title={menu.title}
                        url={menu.url}
                        height={height}
                        navigationItemStyles={navigationItemStyles}
                        activeNavigationItemStyles={activeNavigationItemStyles}
                        disableHoverEffect={disableHoverEffect}
                        fontSize={fontSize}
                        hint={menu.hint}
                        hintFirstTime={menu.hintFirstTime}
                        name={menu.key}
                        notReviewedCount={menu.notReviewedCount}
                        dataTest={dataTest}
                    />
                </CheckPermission>
            );
        }
    });

    return <StyledNavigationContainer>{menuItems}</StyledNavigationContainer>;
};

export default Navigation;
