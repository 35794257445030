import { FC, useState, forwardRef } from 'react';

import Close from '../../icons/Close';
import {
    ClearInput,
    Container,
    Label,
    PasswordEye,
    ShowPassword,
    StyledInput,
} from './style';
import { InputProps } from './type';

const Input: FC<InputProps> = forwardRef(
    (
        {
            type,
            field,
            disabled,
            autoComplete,
            autoFocus,
            height,
            fontSize,
            label,
            value,
            handleChange,
            onBlur,
            maxLength,
            placeholder,
            className,
            colorTheme,
            error,
            readOnly,
            clearInput,
            onKeyPress,
            onKeyDown,
            onFocus,
            labelTopPosition,
            labelLeftPosition,
            inputPadding,
            containerWidth,
            labelFontWeight,
            clearTopPosition,
            labelFontSize,
            fontFamily,
            hideError,
            decreaseLabelFontSize,
            dataTest,
            containerStyle,
            hint,
        },
        ref
    ) => {
        const [showPassword, setShowPassword] = useState(false);
        const clearShown = !!(
            clearInput &&
            value &&
            value.toString().length > 0
        );
        const selectedColorTheme = colorTheme || 'light';
        const inputType = showPassword
            ? true
            : type === 'password-with-eye'
                ? 'password'
                : type;

        return (
            <Container
                colorTheme={selectedColorTheme}
                height={height ? height : '56px'}
                width={containerWidth}
                hideError={hideError}
                error={error}
                className={className ? `${className}-container` : ''}
                style={containerStyle}
            >
                <StyledInput
                    data-test={dataTest}
                    ref={ref}
                    {...field}
                    fontFamily={fontFamily}
                    type={inputType}
                    height={height ? height : '56px'}
                    fontSize={fontSize}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    className={className && (!!error && !hideError ? `${className} ${className}-with-error` : className)}
                    value={value}
                    maxLength={maxLength}
                    onKeyPress={(e: KeyboardEvent) =>
                        onKeyPress && onKeyPress(e)
                    }
                    onKeyDown={(e: KeyboardEvent) =>
                        onKeyDown && onKeyDown(e)
                    }
                    onChange={
                        handleChange
                            ? handleChange
                            : field
                            ? field.onChange
                            : undefined
                    }
                    hideError={hideError}
                    autoFocus={autoFocus}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    colorTheme={selectedColorTheme}
                    error={error}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    padding={inputPadding}
                    title={hint}
                />
                <Label
                    filled={!!value}
                    top={
                        value && decreaseLabelFontSize
                            ? '-6px'
                            : labelTopPosition
                    }
                    left={labelLeftPosition}
                    htmlFor="input"
                    fontWeight={labelFontWeight}
                    disabled={disabled}
                    fontSize={
                        value && decreaseLabelFontSize ? '11px' : labelFontSize
                    }
                    decreaseLabelFontSize={decreaseLabelFontSize}
                    colorTheme={selectedColorTheme}
                    className={(className ? `${className}-label ` : '') + (disabled ? 'is-disabled' : '')}
                >
                    {label}
                </Label>
                <ClearInput
                    colorTheme={selectedColorTheme}
                    shown={clearShown}
                    top={clearTopPosition}
                    onClick={e => {
                        e.stopPropagation();
                        clearInput && clearInput();
                    }}
                    data-test={dataTest && `${dataTest}-clear`}
                >
                    <Close type="thin" />
                </ClearInput>
                {type === 'password' && (
                    <ShowPassword
                        onClick={() => setShowPassword(!showPassword)}
                        fontSize={fontSize}
                        data-test={dataTest ? `${dataTest}-${showPassword ? 'hide' : 'show'}` : null}
                    >
                        {showPassword ? 'Hide' : 'Show'}
                    </ShowPassword>
                )}
                {type === 'password-with-eye' && (
                    <PasswordEye
                        onClick={() => setShowPassword(!showPassword)}
                        shouldShowPassword={showPassword}
                        data-test={dataTest ? `${dataTest}-eye` : null}
                    />
                )}
            </Container>
        );
    }
);

export default Input;
