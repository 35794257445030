import { isEmpty } from 'lodash';
import { EmptyState } from '../../../../ui/components/EmptyStates';
import { FilterSkeleton } from '../../../../ui/components/Skeleton';
import NoSearchResult from '../../../../ui/icons/NoSearchResult';
import { FilterContainer } from '../../filter-container';
import { LabelsFilterViewProps } from './types';
import {
    Container,
    SkeletonContainer,
    EmptyStateContainer,
    ListContainer,
    CounterContainer,
    SelectionCounter,
    ToggleAllSelection,
} from './styles';
import { LabelsFilterItem } from './components/labels-filter-item';
import NoLabels from '../../../../ui/icons/NoLabels';
import { pluralize } from '../../../../helpers/functions/pluralize';
import { SearchableFilterHeaderKey } from '../../searchable-filter-header/store/types';

function LabelsFilterView({
    loading,
    labels,
    allLabels,
    openLabels,
    selectedLabels,
    isAllSelected,
    isInSearch,
    isDisabled,
    onToggleCollapse,
    onSelect,
    onToggleAll,
    onSearch,
}: LabelsFilterViewProps) {
    return (
        <FilterContainer
            headerProps={{
                storeKey: SearchableFilterHeaderKey.LABELS,
                title: 'Labels',
                hasSearch: true,
                isDisabled,
                disableSearch: isEmpty(labels) && !isInSearch,
                onSearch: onSearch,
                isLoading: loading,
            }}
        >
            <Container>
                {loading ? (
                    <SkeletonContainer>
                        <FilterSkeleton />
                    </SkeletonContainer>
                ) : isEmpty(labels) ? (
                    <EmptyStateContainer>
                        <EmptyState
                            fontSize={11}
                            icon={isInSearch ? NoSearchResult : NoLabels}
                            iconStyle={{
                                width: 32,
                                height: 32,
                                marginBottom: 4,
                            }}
                            dataTest="no-labels"
                        >
                            {isInSearch ? 'No Search Results' : 'No Labels yet'}
                        </EmptyState>
                    </EmptyStateContainer>
                ) : (
                    <>
                        <CounterContainer>
                            <SelectionCounter data-test="labels-selection-counter">
                                {selectedLabels?.length
                                    ? `${pluralize(
                                          selectedLabels.length,
                                          'item'
                                      )} selected`
                                    : 'No Items selected'}
                            </SelectionCounter>
                            <ToggleAllSelection
                                onClick={onToggleAll}
                                data-test={
                                    isAllSelected
                                        ? 'labels-unselect-all'
                                        : 'labels-select-all'
                                }
                                isDisabled={isDisabled}
                            >
                                {isAllSelected ? 'Unselect All' : 'Select All'}
                            </ToggleAllSelection>
                        </CounterContainer>
                        <ListContainer>
                            {labels.map(label => {
                                return (
                                    <LabelsFilterItem
                                        allLabels={allLabels}
                                        key={label.id}
                                        label={label}
                                        openLabels={openLabels}
                                        selectedLabels={selectedLabels}
                                        isDisabled={isDisabled}
                                        onSelect={onSelect}
                                        onToggleCollapse={onToggleCollapse}
                                    />
                                );
                            })}
                        </ListContainer>
                    </>
                )}
            </Container>
        </FilterContainer>
    );
}

export default LabelsFilterView;