import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 25px;
    max-width: 1450px;
`;

export const Item = styled.div`
    display: inline-block;
    padding-right: 8px;
    padding-bottom: 8px;
`