import { FC, memo } from 'react';

import { TableStatusCell } from './table-status-cell';
import { BadgeFieldProps } from './types';

const BadgeField: FC<BadgeFieldProps> = props => {
    const { row, renderTitle, getBadgeFieldProps } = props;
    return getBadgeFieldProps ? (
        <TableStatusCell {...getBadgeFieldProps(row)}>
            {renderTitle?.(row)}
        </TableStatusCell>
    ) : (
        <></>
    );
};

export default memo(BadgeField);

