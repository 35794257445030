import YearLeftArrow from '../../../../../../ui/icons/YearLeftArrow';
import YearRightArrow from '../../../../../../ui/icons/YearRightArrow';
import {
    Container,
    ArrowContainer,
    Year,
} from './styles';
import { YearSelectorViewProps } from './types';

function YearSelectorView({
    selectedYear,
    isNextAvailable,
    isPrevAvailable,
    isDisabled,
    onNext,
    onPrev
}: YearSelectorViewProps) {
    return (
        <Container>
            <ArrowContainer
                isAvailable={isPrevAvailable}
                onClick={onPrev}
                data-test="prev-year-button"
            >
                <YearLeftArrow />
            </ArrowContainer>
            <Year data-test="current-year-text" isDisabled={isDisabled}>{selectedYear}</Year>
            <ArrowContainer
                isAvailable={isNextAvailable}
                onClick={onNext}
                data-test="next-year-button"
            >
                <YearRightArrow />
            </ArrowContainer>
        </Container>
    );
}

export default YearSelectorView;