import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const StopCircle = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="7.5" stroke="#4AA9E2"/>
                <rect x="5" y="5" width="6" height="6" rx="1" fill="#4AA9E2"/>
            </svg>
        );
    }
);

export default StopCircle;
