import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ScriptLine } from '../../../constants/interfaces/ScriptLine';
import { useCompanyVoiceSettingsState } from '../../../pages/CompanySettings/store/states';
import { showModalDialog, useHideModalDialog } from '../../../ui/components/ModalDialogs/store/actions';
import { ChunkRequestActionInterface } from '../../../services/ChunkRequestService/hooks/types';
import { TextToSpeechChunkRequestParameters } from './types';
import { GeneralBulkActionModalInterface } from '../../../ui/components/ModalDialogs/types';
import { useChunkRequestsService } from '../../../services/ChunkRequestService/hooks';
import { getTextToSpeechSettingsOrDefault } from '../helpers';
import { useTextToSpeechForScriptLine } from './helpers';

/**
 * @function useTextToSpeechBulkActionService
 * @description Custom hook to generate speech for script lines with chunk service
 * @param { ScriptLine[] } lines
 * @param { string } featureName
 * @param { Function } onSuccess
 * @param { Function } onError
 * @returns { CallableFunction }
 */
export function useTextToSpeechBulkActionService(
    lines: ScriptLine[],
    featureName: string,
    onSuccess?: () => void,
    onError?: () => void
) {
    const dispatch = useDispatch();
    const voiceSettings = useCompanyVoiceSettingsState().data;
    const textToSpeechForScriptLine = useTextToSpeechForScriptLine();
    const hideModalDialog = useHideModalDialog();

    const [isRunning, setIsRunning] = useState<boolean>(false);

    const actionList: ChunkRequestActionInterface<TextToSpeechChunkRequestParameters>[] =
        [
            {
                actionName: 'TEXT_TO_SPEECH_ACTION',
                actionFunction: textToSpeechForScriptLine,
                actionFunctionOptions: {
                    parameters: {
                        lines,
                        index: lines.length,
                        featureName,
                        settings: getTextToSpeechSettingsOrDefault(voiceSettings),
                    },
                    fieldName: 'index',
                },
                itemPerChunk: 1,
            },
        ];

    /**
     * @function handleSuccess
     * @returns { void }
     */
    const handleSuccess = useCallback(() => {
        Promise.resolve().then(() => {
            setTimeout(() => {
                hideModalDialog();
            }, 900);

            isRunning && setIsRunning(false);

            onSuccess?.();
        });
    }, [hideModalDialog, isRunning, onSuccess]);

    /**
     * @function handleFailed
     * @returns { void }
     */
    const handleFailed = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_FAILED_MODAL',
                    modalProps: {
                        modalTitle: 'AI Voice Processing',
                        onClose: hideModalDialog,
                    } as GeneralBulkActionModalInterface,
                })
            );

            isRunning && setIsRunning(false);

            onError?.();
        });
    }, [dispatch, hideModalDialog, isRunning, onError]);

    const { setIsStopped } = useChunkRequestsService(
        actionList,
        handleSuccess,
        handleFailed
    );

    /**
     * @function handleStop
     * @returns { void }
     */
    const handleStop = useCallback(() => {
        Promise.resolve().then(() => {
            setIsStopped(true);
            setIsRunning(false);
            hideModalDialog();
            onSuccess?.();
        });
    }, [hideModalDialog, onSuccess, setIsStopped]);

    /**
     * @function handleStart
     * @returns { void }
     */
    const handleStart = useCallback(() => {
        Promise.resolve().then(() => {
            dispatch(
                showModalDialog({
                    modalType: 'BULK_ACTION_PROGRESS_MODAL',
                    modalProps: {
                        modalTitle: 'AI Voice Processing',
                        onStopBulkActionService:
                            handleStop,
                        onClose: hideModalDialog,
                    },
                })
            );

            setIsStopped(false);
        });
    }, [
        dispatch,
        handleStop,
        hideModalDialog,
        setIsStopped,
    ]);

    useEffect(() => {
        if (isRunning) {
            handleStart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning]);

    return useCallback(() => {
        setIsRunning(true);
    }, []);
}