import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledImageContainer = styled.div<{ src?: string, rectStyled?: boolean }>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: none;
    background: var(--ps-graphite-2);
    background-image: ${({ src }) => (src ? `url('${encodeURI(src)}')` : ``)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px 80px;
    cursor: pointer;
    font-size: 32px;
    font-weight: bold;
    color: var(--ps-grey-1);

    ${({ rectStyled, src }) => (rectStyled &&
        `width: 88px;
        height: 88px;
        margin: 5px 51px 25px 19px;
        background-size: 88px 88px;
        padding: 32px;
        border-radius: 4px;
        border: solid 1px var(--ps-grey-2);
        border-width: ${src ? `0px` : `1px`};
        background-color: var(--ps-grey-3);
        color: var(--ps-black-1);
        box-sizing: border-box;`
    )}
`;

export const BadgeContainer = styled.div<{ rectStyled?: boolean }>`
    position: absolute;
    width: 24px;
    height: 24px;
    margin-top: 3rem;
    margin-left: 4rem;
    box-sizing: border-box;
    z-index: 3;
    color: red;
    background: var(--ps-blue-main);
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ rectStyled }) => (rectStyled &&
        `width: 36px;
        height: 36px;
        padding: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
        background-color: var(--ps-grey-2);`
    )}
`;

export const TrashContainer = styled.div`
    position: absolute;
    margin-top: 3rem;
    margin-left: 4rem;
    z-index: 3;
    color: var(--ps-white-1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
    background-color: var(--ps-blue-main);
`;

export const LoadingContainer = styled.div`
    position: absolute;
`;