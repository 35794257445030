export interface ReportItem {
    id: number;
    type: ReportType;
}

export enum ReportType {
    PRACTIS_SET_SUMMARY = 'practis_set_summary',
    USERS_ACTIVITY = 'users_activity',
    TEAM_LEADER_ACTIVITY = 'team_leader_activity',
    BILLING = 'billing',
    TRAINING_TIME = 'training_time',
}