import { useMemo } from "react";
import { Challenge, ChallengeStatuses } from "../../../constants/interfaces/Challenge";
import { UserProfile } from "../../../constants/interfaces/User";
import { useLoggedInUserProfileState } from "../../../features/users/store/states";
import { hasPermission, NEW_PERMISSIONS } from "../../../features/users/tools";
import { getBulkActionAvailabilityItem, getBulkActionsAvailability } from "../../../tools/bulkActions";

export enum ChallengeActions {
    EDIT,
    ASSIGN_LABELS,
    DUPLICATE,
    ARCHIVE,
    RESTORE,
    DELETE,
}

function createChallengeActionsHelper(loggedInUser: UserProfile | undefined) {
    const canEdit = (challenge: Challenge) =>
        challenge.status !== ChallengeStatuses.DELETED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.UPDATE_CHALLENGE);
    
    const canAssignLabels = (challenge: Challenge) =>
        challenge.status !== ChallengeStatuses.DELETED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.ASSIGN_CHALLENGE_LABEL);
    
    const canDuplicate = (challenge: Challenge) =>
        (challenge.status === ChallengeStatuses.DRAFT || challenge.status === ChallengeStatuses.ACTIVE)
            && hasPermission(loggedInUser, NEW_PERMISSIONS.COPY_CHALLENGE);
    
    const canArchive = (challenge: Challenge) =>
        (challenge.status === ChallengeStatuses.DRAFT || challenge.status === ChallengeStatuses.ACTIVE)
            && hasPermission(loggedInUser, NEW_PERMISSIONS.ARCHIVE_CHALLENGE);
    
    const canRestore = (challenge: Challenge) =>
        challenge.status === ChallengeStatuses.ARCHIVED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.RESTORE_CHALLENGE);
    
    const canDelete = (challenge: Challenge) =>
        challenge.status === ChallengeStatuses.ARCHIVED
            && hasPermission(loggedInUser, NEW_PERMISSIONS.DELETE_CHALLENGE);

    const getBulkActions = (challenges: Challenge[] | undefined) =>
        getBulkActionsAvailability(
            challenges,
            [
                getBulkActionAvailabilityItem(
                    ChallengeActions.ASSIGN_LABELS,
                    challenges,
                    canAssignLabels
                ),
                getBulkActionAvailabilityItem(
                    ChallengeActions.DUPLICATE,
                    challenges,
                    canDuplicate
                ),
                getBulkActionAvailabilityItem(
                    ChallengeActions.ARCHIVE,
                    challenges,
                    canArchive
                ),
                getBulkActionAvailabilityItem(
                    ChallengeActions.RESTORE,
                    challenges,
                    canRestore
                ),
                getBulkActionAvailabilityItem(
                    ChallengeActions.DELETE,
                    challenges,
                    canDelete
                ),
            ]
        );

    return {
        canEdit,
        canAssignLabels,
        canDuplicate,
        canArchive,
        canRestore,
        canDelete,
        getBulkActions,
    };
}

export type ChallengeActionsHelper = ReturnType<typeof createChallengeActionsHelper>;

export function useChallengeActionsHelper() {
    const loggedInUser = useLoggedInUserProfileState();
    return useMemo(
        () => createChallengeActionsHelper(loggedInUser),
        [loggedInUser]
    );
}