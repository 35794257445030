import { forwardRef } from 'react';
import { IconProps } from './types';

export const CheckCircle = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.78 6.28a.75.75 0 0 0-1.06-1.06L6.75 9.19 5.28 7.72a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l4.5-4.5z"
                    fill="#15CBA9"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm1.5 0a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0z"
                    fill="#15CBA9"
                />
            </svg>
        );
    }
);

export default CheckCircle;
