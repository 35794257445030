export enum DueDateFilters {
    NO_DUEDATE = 'NO_DUEDATE',
    OVERDUE = 'OVERDUE',
    ASSIGNED = 'ASSIGNED',
}

export type DueDateType = {
    [id: number]: string | null;
};

export const DATE_FORMAT = 'YYYY-MM-DD';
