import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { History } from 'history';
import ROUTES from '../../../routes/routes';
import Storage from '../../../services/Storage';
import { UrlBuilder, UrlBuilderContext } from '../../../tools/url/UrlBuilder';
import { UserInterface, UserV2 } from '../../../constants/interfaces/User';
import {
    setAuthToken,
    redirectAfterLogin,
    isAuthorized,
} from '../../../helpers/functions/auth-helpers';
import { getUserFeaturesState } from '../../../features/users/store/reducers';
import {
    isPractisAdminRole,
    isCompanyAdminRole,
    isTeamLeader,
} from '../../../constants/enums';
import { useGetProfileService } from '../../UserProfile/store/services';
import { getProfileState } from '../../UserProfile/store/reducers';
import { OnboardingRole } from '../Onboarding/types';
import AddMobileNumberView from './view';
import { MobileNumberVerificationStep } from './types';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';

function AddMobileNumberController() {
    const history: History<any> = useHistory();
    const userFeatures = useSelector(getUserFeaturesState);
    const profile = useSelector(getProfileState);
    const getProfile = useGetProfileService();
    const user =
        (history.location.state?.user as UserInterface | UserV2) ?? profile;
    const isNewUser = history.location.state?.isNewUser ?? false;
    const initialVerificationStep =
        history.location.state?.verificationStep ??
        MobileNumberVerificationStep.ENTER;
    const from = history.location.state?.from as Location;
    const [mobileNumber, setMobileNumber] = useState<string>(
        user?.phoneNumber ?? ''
    );
    const [currentStep, setCurrentStep] =
        useState<MobileNumberVerificationStep>(initialVerificationStep);
    const [canSkip, setCanSkip] = useState<boolean>(false);

    /**
     * @function finalizeVerification - redirects to a proper page after verification process is completed or skipped
     * @param { UrlBuilder } urlBuilder
     * @returns { void }
     */
    const finalizeVerification = useCallback(
        (urlBuilder: UrlBuilder) => {
            const tempToken = Storage.get('temp_token');
            if (tempToken) {
                Storage.remove('temp_token');
                setAuthToken(user, tempToken);
                if (isNewUser) {
                    const role =
                        isCompanyAdminRole(user.role?.name) ||
                        isPractisAdminRole(user.role?.name)
                            ? OnboardingRole.ADMIN
                            : isTeamLeader(user.role?.name)
                            ? OnboardingRole.TEAM_LEAD
                            : OnboardingRole.USER;
                    history.push(
                        ROUTES.AUTH_PAGES.ONBOARDING.replace(':role', role)
                    );
                } else {
                    redirectAfterLogin(
                        history,
                        urlBuilder,
                        user,
                        tempToken,
                        from
                    );
                }
            } else {
                redirectAfterLogin(
                    history,
                    urlBuilder,
                    user,
                    Storage.get('token')!,
                    from
                );
            }
        },
        [user, history, isNewUser, from]
    );

    /**
     * @function handleSendCode - switches to code verification view
     * @returns { void }
     */
    const handleSendCode = () =>
        setCurrentStep(MobileNumberVerificationStep.VERIFY);

    /**
     * @function handleBackFromVerify - switches to mobile number view
     * @returns { void }
     */
    const handleBackFromVerify = () => {
        setCurrentStep(MobileNumberVerificationStep.ENTER);
    };

    useEffect(() => {
        setCanSkip(userFeatures?.phoneSkip?.allow ?? false);
    }, [userFeatures]);

    // to prevent browser back button
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);

        window.addEventListener('popstate', function (event) {
            window.history.pushState(
                null,
                document.title,
                window.location.href
            );
        });
    }, []);

    useEffect(() => {
        if (!user?.id && isAuthorized()) {
            getProfile();
        }
    }, [user?.id, getProfile]);

    useEffect(() => {
        if (user?.id) {
            setMobileNumber(user.phoneNumber ?? '');
        }
    }, [user?.id, user.phoneNumber]);

    useHtmlPageTitle('Add Mobile Number');

    return (
        <UrlBuilderContext.Consumer>
            {urlBuilder => (
                <AddMobileNumberView
                    userId={user?.id}
                    mobileNumber={mobileNumber}
                    canSkip={canSkip}
                    currentStep={currentStep}
                    onMobileNumberChange={setMobileNumber}
                    onSendCode={handleSendCode}
                    onSkip={() => finalizeVerification(urlBuilder)}
                    onVerify={() => finalizeVerification(urlBuilder)}
                    onBackFromVerify={handleBackFromVerify}
                />
            )}
        </UrlBuilderContext.Consumer>
    );
}

export default AddMobileNumberController;