import { VerifyMobileNumberProps } from './types';
import { useConfirmUserPhoneNumber } from '../../../../../api';
import { useUpdateUserPhoneNumber } from '../../../../../api';
import CheckVerificationCode from '../CheckVerificationCode';
import { useCallback } from 'react';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

function VerifyMobileNumberController({
    userId,
    mobileNumber,
    onBack,
    onVerify,
}: VerifyMobileNumberProps) {
    const confirmUserPhoneNumber = useConfirmUserPhoneNumber();
    const updateUserPhoneNumber = useUpdateUserPhoneNumber();

    useHtmlPageTitle('Verify Mobile Number');

    /**
     * @function handleVerify
     * @description calls API to verify entered code
     * @param { string } code
     * @returns { Promise }
     */
    const handleVerify = useCallback((code: string) => {
        if (!userId)
            return Promise.reject({ message: 'User is not defined' });
        return confirmUserPhoneNumber(userId, +code);
    }, [userId, confirmUserPhoneNumber]);

    /**
     * @function handleResend 
     * @description calls API to resend a verification code
     * @returns { Promise }
     */    
    const handleResend = useCallback(() => {
        if (!userId)
            return Promise.reject({ message: 'User is not defined' });
        return updateUserPhoneNumber(userId, mobileNumber);
    }, [userId, mobileNumber, updateUserPhoneNumber]);

    return (
        <CheckVerificationCode
            mobileNumber={mobileNumber}
            onBack={onBack}
            onVerify={handleVerify}
            onVerifySuccess={onVerify}
            onResend={handleResend}
        />
    );
}


export default VerifyMobileNumberController;