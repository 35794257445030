import styled from 'styled-components';

import { COLORS } from './constants';
import { Theme, Variant } from './type';

export const StyledButtonContent = styled.div<{
    height?: string;
    width?: string;
    fontSize?: number;
    borderRadius?: string;
    hasIcon?: boolean;
    color: Theme;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '13px')};
    font-weight: 500;
    flex: 1;
    padding: 0 16px;
    height: ${props => (props.height ? props.height : '40px')};
    min-width: ${props => (props.width ? props.width : '100px')};
    border-radius: ${props =>
        props.hasIcon ? '0' : props.borderRadius ? props.borderRadius : '4px'};

    border-top-left-radius: ${props =>
        props.borderRadius ? props.borderRadius : '4px'};
    border-bottom-left-radius: ${props =>
        props.borderRadius ? props.borderRadius : '4px'};
    box-sizing: border-box;
`;

export const StyledButtonIconContainer = styled.div<{
    height?: string;
    color: Theme;
    onlyIcon?: boolean;
    borderRadius?: string;
}>`
    display: flex;
    width: 40px;
    height: ${props => (props.height ? props.height : '40px')};
    justify-content: center;
    border-radius: ${props =>
        props.onlyIcon
            ? props.borderRadius
                ? props.borderRadius
                : '4px'
            : '0'};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: ${props => COLORS[props.color].light};
    align-items: center;
    & img {
        height: 20px;
    }
`;

export const StyledButtonIcon = styled.div<{ buttonSize?: number }>`
    height: ${props => (props.buttonSize ? props.buttonSize : 20)}px;
    width: ${props => (props.buttonSize ? props.buttonSize : 20)}px;
    display: flex;
    justify-content: center;
`;

export const StyledButton = styled.button<{
    color: Theme;
    customColor?: string;
    variant?: Variant;
    width?: string;
    buttonSize?: number;
    borderRadius?: string;
    customDisabledColor?: string;
    buttonWidth?: string;
}>`
    display: flex;
    border: none;
    cursor: pointer;
    padding: 0;
    border-radius: ${props =>
        props.borderRadius ? props.borderRadius : '4px'};
    font-family: Manrope, serif !important;
    height: fit-content;
    ${props => !!props.buttonWidth && `width: ${props.buttonWidth};`}
    &.primary {
        background: ${props =>
            !!props.customColor
                ? props.customColor
                : COLORS[props.color].light};
        color: ${props => COLORS[props.color].text};
        &:hover {
            background: ${props => COLORS[props.color].normal};
        }
        &:active {
            background: ${props => COLORS[props.color].dark};
        }
        &:disabled {
            cursor: default;
            background: ${props =>
                !!props.customDisabledColor
                    ? props.customDisabledColor
                    : props.theme.Colors.cloudyBlue};
        }
    }

    &.inverse {
        background: transparent;
        color: ${props =>
            !!props.customColor
                ? props.customColor
                : COLORS[props.color].light};
        box-shadow: inset 0 0 0 1px
            ${props =>
                !!props.customColor
                    ? props.customColor
                    : COLORS[props.color].light};
        &:hover {
            background: ${props => COLORS[props.color].light};
            color: ${props => COLORS[props.color].text};
        }
        &:active {
            background: ${props => COLORS[props.color].normal};
            color: ${props => COLORS[props.color].text};
        }
        &:disabled {
            background: transparent;
            color: ${props => props.theme.Colors.cloudyBlue};
            cursor: default;
            box-shadow: inset 0 0 0 1px
                ${props => props.theme.Colors.cloudyBlue};
        }
    }

    &.transparent {
        font-weight: bold;
        background: transparent;
        color: ${props => COLORS[props.color].light};
        flex-direction: row-reverse;
        display: flex;
        align-items: center;
        ${StyledButtonContent} {
            padding: 0;
            justify-content: flex-start;
            margin: 0 8px;
            min-width: ${props => (props.width ? props.width : '100px')};
            height: 24px;
        }

        ${StyledButtonIconContainer} {
            background: transparent;
            width: ${props => (props.buttonSize ? props.buttonSize : 20)}px;
            height: auto;
            color: ${props => COLORS[props.color].light};
        }
        &:hover,
        &:hover ${StyledButtonIconContainer} {
            color: ${props => COLORS[props.color].normal};
        }
        &:active,
        :active ${StyledButtonIconContainer} {
            color: ${props => COLORS[props.color].dark};
        }
        &:disabled,
        &:disabled ${StyledButtonIconContainer} {
            color: ${props => props.theme.Colors.blueyGray};
        }
    }

    &.with-icon {
        background: ${props => COLORS[props.color].normal};
        color: ${props => COLORS[props.color].text};
        &:hover ${StyledButtonIconContainer} {
            background: ${props => COLORS[props.color].normal};
        }
        &:active,
        :active ${StyledButtonIconContainer} {
            background: ${props => COLORS[props.color].dark};
        }
        &:disabled {
            background: ${props => props.theme.Colors.blueyGray};
            cursor: default;
        }
        &:disabled ${StyledButtonIconContainer} {
            background: ${props => props.theme.Colors.cloudyBlue};
            cursor: default;
        }
    }
`;

