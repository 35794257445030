import { compose, Reducer } from 'redux';
import {
    AccuracyScoreState,
    SubmissionPaginationState,
    SubmissionDetailsState,
    SubmissionsState,
    UpdatedSubmissionsState,
} from './states';
import {
    ACTIONS,
    checkAllSubmissions,
    checkSingleSubmission,
    getSubmissionDetailsFailure,
    getSubmissionDetailsStart,
    getSubmissionDetailsSuccess,
    getSubmissionReviewSuccess,
    modifyScore,
    searchChallengeSubmissionsFailure,
    searchChallengeSubmissionsStart,
    searchChallengeSubmissionsSuccess,
    searchAccuracyScoreStart,
    searchAccuracyScoreFailure,
    searchAccuracyScoreSuccess,
    updateChallengeSubmissionFailure,
    updateChallengeSubmissionStart,
    updateChallengeSubmissionSuccess,
    updateSubmissionDeleteLabelSuccess,
    checkAllAccuracyScores,
    checkSingleAccuracyScore,
    searchSubmissionPaginationStart,
    searchSubmissionPaginationSuccess,
    searchSubmissionPaginationFailure,
} from './actions';
import { SubmissionV2 } from '../../../constants/interfaces/Reviews';
import {
    withLabelsInitialState,
    withLabelsReducer,
} from '../../portableLabels/store/hors/withLabels';
import {
    withTeamsInitialState,
    withTeamsReducer,
} from '../../portableTeams/store/hors/withTeams';
import { withScenariosInitialState, withScenariosReducer } from '../../../features/portableScenarios/store/hors/withTeams';

const initialSubmissionsState: SubmissionsState = withLabelsInitialState(
    withTeamsInitialState(
        {
            checked: [],
        },
        'submissions'
    ),
    'submissions',
);

const initialAccuracyScoreState: AccuracyScoreState = withLabelsInitialState(
    withTeamsInitialState(
        withScenariosInitialState(
            {
                checked: [],
            },
            'submissions'
        ),
        'submissions'
    ),
    'submissions'
);


type SubmissionsAction =
    | ReturnType<typeof searchChallengeSubmissionsStart>
    | ReturnType<typeof searchChallengeSubmissionsSuccess>
    | ReturnType<typeof searchChallengeSubmissionsFailure>
    | ReturnType<typeof checkAllSubmissions>
    | ReturnType<typeof checkSingleSubmission>
    | ReturnType<typeof updateSubmissionDeleteLabelSuccess>;

const submissionsBaseReducer: Reducer<SubmissionsState, SubmissionsAction> = (
    state = initialSubmissionsState,
    action: SubmissionsAction
): SubmissionsState => {
    switch (action.type) {
        case ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_SUCCESS:
            const prevItems = state.data?.items;
            return {
                ...state,
                data:
                    !prevItems
                        ? action.submissions
                        : {
                              ...action.submissions,
                              items: action.submissions.items,
                          },
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_CHALLENGE_SUBMISSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.CHECK_SINGLE_SUBMISSION: {
            const selectedIds = [...state.checked];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                checked: selectedIds,
            };
        }
        case ACTIONS.CHECK_ALL_SUBMISSIONS:
            return {
                ...state,
                checked:
                    action.checked || action.partial ? action.submissionId : [],
            };
        case ACTIONS.UPDATE_SUBMISSION_DELETE_LABEL_SUCCESS:
            if (state.data) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        items: state.data.items.map((submission: SubmissionV2) =>
                            submission.id === action.id
                                ? {
                                      ...submission,
                                      userLabels: submission.userLabels?.filter(
                                          labelId => labelId !== action.labelId
                                    )
                                  }
                                : submission
                        ),
                    },
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export const submissionsReducer = compose<Reducer<SubmissionsState, any>>(
    withLabelsReducer({ reducerName: 'submissions' }),
    withTeamsReducer({ reducerName: 'submissions' })
)(submissionsBaseReducer);

export type UpdatedSubmissionsAction =
    | ReturnType<typeof updateChallengeSubmissionStart>
    | ReturnType<typeof updateChallengeSubmissionSuccess>
    | ReturnType<typeof updateChallengeSubmissionFailure>;

export const updatedSubmissionsReducer: Reducer<
    UpdatedSubmissionsState,
    UpdatedSubmissionsAction
> = (state = {}, action: UpdatedSubmissionsAction): UpdatedSubmissionsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_CHALLENGE_SUBMISSION_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_CHALLENGE_SUBMISSION_SUCCESS:
            return {
                ...state,
                data: action.submissionIds,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_CHALLENGE_SUBMISSION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export type SubmissionDetailsAction =
    | ReturnType<typeof getSubmissionDetailsStart>
    | ReturnType<typeof getSubmissionDetailsSuccess>
    | ReturnType<typeof getSubmissionDetailsFailure>
    | ReturnType<typeof getSubmissionReviewSuccess>
    | ReturnType<typeof modifyScore>;

export const submissionDetailsReducer: Reducer<
    SubmissionDetailsState,
    SubmissionDetailsAction
> = (state = {}, action: SubmissionDetailsAction): SubmissionDetailsState => {
    switch (action.type) {
        case ACTIONS.GET_SUBMISSION_DETAILS_START:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case ACTIONS.GET_SUBMISSION_DETAILS_SUCCESS:
            return {
                ...state,
                details: {
                    ...action.submission,
                },
                review: {
                    ...action.review
                },
                loading: false,
                error: ''
            };
        case ACTIONS.GET_SUBMISSION_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.GET_SUBMISSION_REVIEW_SUCCESS:
            return {
                ...state,
                details: state.details ? {
                    ...state.details,
                    reviewedAt: action.review.date
                } : state.details,
                review: {
                    ...action.review
                },
                loading: false,
                error: ''
            };
        case ACTIONS.MODIFY_SCORE:
            return {
                ...state,
                review: {
                    ...state.review,
                    [action.field]: action.value,
                },
            };
        default:
            return state;
    }
};

export type AccuracyScoresAction =
    | ReturnType<typeof checkAllAccuracyScores>
    | ReturnType<typeof checkSingleAccuracyScore>
    | ReturnType<typeof searchAccuracyScoreStart>
    | ReturnType<typeof searchAccuracyScoreSuccess>
    | ReturnType<typeof searchAccuracyScoreFailure>;


// Accuracy Scores
const accuracyScoresBaseReducer: Reducer<AccuracyScoreState, AccuracyScoresAction> = (
    state = initialAccuracyScoreState,
    action: AccuracyScoresAction
): any => {
    switch (action.type) {
        case ACTIONS.SEARCH_ACCURACY_SCORE_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_ACCURACY_SCORE_SUCCESS:
            const prevItems = state.data?.items;
       
            return {
                ...state,
                data: !prevItems
                    ? action.accuracyScores
                    : {
                          ...action.accuracyScores,
                          items: action.accuracyScores.items,
                      },
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_ACCURACY_SCORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.CHECK_SINGLE_ACCURACY_SCORE: {
            
            const selectedIds = [...state.checked];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                checked: selectedIds,
            };
        }
        case ACTIONS.CHECK_ALL_ACCURACY_SCORES:
            return {
                ...state,
                checked:
                    action.checked || action.partial ? action.submissionId : [],
            };
        default:
            return state;
    }
};

export const accuracyScoreReducer = compose<Reducer<any, any>>(
    withLabelsReducer({ reducerName: 'accuracyscore' }),
    withTeamsReducer({ reducerName: 'accuracyscore' }),
    withScenariosReducer({reducerName: 'accuracyscore'})

)(accuracyScoresBaseReducer);

export type SubmissionPaginationAction =
    | ReturnType<typeof searchSubmissionPaginationStart>
    | ReturnType<typeof searchSubmissionPaginationSuccess>
    | ReturnType<typeof searchSubmissionPaginationFailure>;

export const submissionPaginationReducer: Reducer<
    SubmissionPaginationState,
    SubmissionPaginationAction
> = (state = {}, action: SubmissionPaginationAction): SubmissionPaginationState => {
    switch (action.type) {
        case ACTIONS.SEARCH_SUBMISSION_PAGINATION_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.SEARCH_SUBMISSION_PAGINATION_SUCCESS:
            return {
                ...state,
                submissionIds: action.submissionIds,
                totalCount: action.totalCount,
                loading: false,
            };
        case ACTIONS.SEARCH_SUBMISSION_PAGINATION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};