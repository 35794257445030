import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useWithTeamsContext } from '../context/useWithTeamsContext';
import { encapsulateAction } from '../../../../../../store/helpers/encapsulateAction';
import { ActionTeam } from '../actions';
import { DEFAULT_TEAMS_SCOPE, Scope, WithTeamsScopes } from '../scopes';

export const useEncapsulatedDispatch = () => {
    const dispatch = useDispatch();
    const { reducerName, scope } = useWithTeamsContext();

    if (reducerName === undefined) {
        throw new Error(
            '`Reducer name` cannot be an `undefined`. Please check WithTeamsContext.Provider props'
        );
    }

    const targetScope = (scope || DEFAULT_TEAMS_SCOPE) as Scope;
    if (
        targetScope !== DEFAULT_TEAMS_SCOPE &&
        !WithTeamsScopes[reducerName]?.includes(targetScope)
    ) {
        throw new Error(
            `Undefined "${targetScope}" scope of portableTeams. Please check config (WithTeamsScopes) for "${reducerName}" reducer`
        );
    }

    return useCallback(
        <T extends ActionTeam>(action: T) => {
            action._scope = targetScope;
            dispatch(encapsulateAction(`${reducerName}/teams/`, action));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );
};
