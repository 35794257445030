import { Reducer } from 'redux';

import { AppState } from '../../../../store/reducers';
import { ModalDialogActions } from './actions';
import {
    HIDE_MODAL,
    initialState,
    SHOW_MODAL,
    UPDATE_LOADING_STATUS,
} from './constants';
import { ModalDialogInterface } from './types';

const modalDialogsReducer: Reducer<ModalDialogInterface, ModalDialogActions> = (
    state = initialState,
    action
): ModalDialogInterface => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...action?.modalOptions,
            };
        case HIDE_MODAL:
            return initialState;

        case UPDATE_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};

export const getModalDialogsState = (state: AppState) => state.modalDialogs;

export default modalDialogsReducer;