import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Files = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 20 20"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M18.75 20H1.25C.56 20 0 19.44 0 18.75v-7.5L2.5 0h15L20 11.25v7.5c0 .69-.56 1.25-1.25 1.25zm-2.5-18.75H3.75l-2.5 10h5.625v2.5h6.25v-2.5h5.625l-2.5-10zm2.5 11.875c0-.345-.28-.625-.625-.625h-3.75V15h-8.75v-2.5h-3.75c-.345 0-.625.28-.625.625v5c0 .346.28.625.625.625h16.25c.345 0 .625-.28.625-.626v-4.999zM14.375 8.75h-8.75C5.28 8.75 5 8.47 5 8.125s.28-.625.625-.625h8.75c.345 0 .625.28.625.625s-.28.625-.625.625zM13.125 5h-6.25c-.345 0-.625-.28-.625-.626 0-.344.28-.624.625-.624h6.25c.345 0 .625.28.625.624 0 .346-.28.626-.625.626z"
                />
            </svg>
        );
    }
);

export default Files;
