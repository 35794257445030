import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const CompletedIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 72 72"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g>
                    <clipPath id="cp0">
                        <path
                            transform="matrix(1,0,0,-1,0,72)"
                            d="M 71.34928 54.7223 L 39.92813 23.47684 C 39.88086 23.41609 39.90112 23.33396 39.84709 23.27883 C 39.39687 22.83105 38.80595 22.63529 38.21728 22.64992 C 37.61736 22.62854 37.01856 22.81643 36.56046 23.2732 C 36.49405 23.33733 36.51881 23.43746 36.46141 23.50722 L 23.17079 36.72455 C 22.30073 37.5886 22.30073 38.99269 23.17079 39.86124 C 24.04085 40.72755 25.45343 40.72755 26.32461 39.86124 L 38.2004 28.04913 L 68.18758 57.86687 C 69.06102 58.73542 70.47697 58.73542 71.35041 57.86687 C 72.22272 56.99944 72.22272 55.58972 71.34928 54.7223 Z M 35.99318 67.50197 C 18.59539 67.50197 4.490987 53.39809 4.490987 36 C 4.490987 18.60079 18.59539 4.500281 35.99318 4.500281 C 53.39096 4.500281 67.49536 18.60079 67.49536 36 C 67.49536 36.68067 67.33666 37.31633 67.29501 37.98462 L 71.44383 42.01688 C 71.77362 40.05475 71.98522 38.0555 71.98522 36 C 71.98522 16.11551 55.8717 0 35.99205 0 C 16.11465 0 0 16.11551 0 36 C 0 55.88449 16.11352 72 35.99205 72 C 45.11472 72 53.41798 68.57979 59.76276 62.98369 L 56.45587 59.76936 C 50.93949 64.52391 43.84621 67.50197 35.99318 67.50197 Z "
                        />
                    </clipPath>
                    <g clipPath="url(#cp0)">
                        <clipPath id="cp1">
                            <path
                                transform="matrix(1,0,0,-1,0,72)"
                                d="M -152 -636 L 223 -636 L 223 176 L -152 176 Z "
                            />
                        </clipPath>
                        <g clipPath="url(#cp1)">
                            <path
                                transform="matrix(1,0,0,-1,0,72)"
                                d="M -5 77 L 77.0045 77 L 77.0045 -5 L -5 -5 Z "
                                fill="currentColor"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default CompletedIcon;
