import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const KeywordIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {!!shadow && (
                    <defs>
                        <filter
                            width="134.3%"
                            height="102.1%"
                            x="-14.3%"
                            y="-1.1%"
                            filterUnits="objectBoundingBox"
                        >
                            <feOffset
                                dx="-2"
                                in="SourceAlpha"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                                stdDeviation="1"
                            />
                            <feColorMatrix
                                in="shadowBlurOuter1"
                                result="shadowMatrixOuter1"
                                values="0 0 0 0 0.161525996 0 0 0 0 0.18496362 0 0 0 0 0.213088768 0 0 0 0.3 0"
                            />
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                )}
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <path
                            d="M1155.998 494.994v-1H1165v1h-9.002zm3.971-11.125l-.364.616-5.276 8.916-1.11-.625-2.205 2.224-1.776-1 .872-2.975-1.11-.626 5.64-9.532-.888-.5-2.564 4.333-.888-.5 3.077-5.2.888.5.888.5.514-.866 5.329 3.002-1.027 1.733zm-8.886 10.014l1.321-1.566-1.48-.834-.73 1.9.889.5zm3.932-11.649l-4.614 7.799 1.565.882-.677-.381 1.776 1 .888.5 4.615-7.799-3.553-2zm1.026-1.733l-.513.866 3.553 2.002.513-.867-3.553-2z"
                            transform="translate(-1149 -479)"
                        />
                    </g>
                </g>
            </svg>
        );
    }
);

export default KeywordIcon;
