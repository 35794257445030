import LoginAuthWrapper from '../../../ui/wrapper/LoginAuthWrapper';
import EnterMobileNumberController from './components/EnterMobileNumber';
import VerifyMobileNumberController from './components/VerifyMobileNumber';
import { AddMobileNumberViewProps, MobileNumberVerificationStep } from './types';

function AddMobileNumberView({
    userId,
    mobileNumber,
    canSkip,
    currentStep,
    onMobileNumberChange,
    onSendCode,
    onSkip,
    onVerify,
    onBackFromVerify
}: AddMobileNumberViewProps) {
    return (
        <LoginAuthWrapper contentWidth={343} heightToShowScroll={500}>
            {currentStep === MobileNumberVerificationStep.ENTER && (
                <EnterMobileNumberController
                    userId={userId}
                    mobileNumber={mobileNumber}
                    canSkip={canSkip}
                    onMobileNumberChange={onMobileNumberChange}
                    onSendCode={onSendCode}
                    onSkip={onSkip}
                />
            )}
            {currentStep === MobileNumberVerificationStep.VERIFY && !!mobileNumber && (
                <VerifyMobileNumberController
                    userId={userId}
                    mobileNumber={mobileNumber}
                    onBack={onBackFromVerify}
                    onVerify={onVerify}
                />
            )}
        </LoginAuthWrapper>
    );
}

export default AddMobileNumberView;