import styled from 'styled-components';

export const Title = styled.div<{
    color?: string;
}>`
    font-size: 20px;
    font-weight: 800;
    width: 100%;
    color: ${props => (!!props.color ? props.color : 'var(--ps-black-main)')};
`;

export const StyledConfirmModalTitle = styled.div<{ marginBottom?: number }>`
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0.5px;
    color: var(--ps-black-main);
    text-align: left;
    margin-bottom: ${props =>
        !!props.marginBottom ? `${props.marginBottom}px` : '14px'};
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
`;

export const StyledConfirmModalDescription = styled.div<{
    marginBottom?: number;
}>`
    color: var(--ps-grey-1);
    font-size: 13px;
    text-align: left;
    margin-bottom: ${props =>
        !!props.marginBottom ? `${props.marginBottom}px` : '37px'};
    white-space: break-spaces;
`;

export const ProgressBarContainer = styled.div`
    margin: 1rem 0 1.5rem 0;
`;

export const ActionsContainer = styled.div<{
    justify?: string;
}>`
    display: flex;
    align-items: center;
    justify-content: ${props => (!!props.justify ? props.justify : 'flex-end')};
`;

export const ActionWarningContainer = styled.div`
    display: flex;
`;

export const WarningText = styled.div`
    font-size: 13px;
    color: var(--ps-grey-1);
    max-width: 165px;
    margin-left: 10px;
`;

export const Description = styled.div`
    font-size: 13px;
    color: var(--ps-grey-1);
    margin: 10px 0 20px 0;
`;
