import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const Cross = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                className={className}
                width="100%"
                height="100%"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.650152 0.649946C0.825927 0.474384 1.06421 0.375771 1.31265 0.375771C1.56109 0.375771 1.79936 0.474384 1.97515 0.649946L6.00015 4.67495L10.0252 0.649946C10.111 0.557834 10.2145 0.483959 10.3295 0.432721C10.4445 0.381484 10.5687 0.353934 10.6945 0.351709C10.8204 0.349484 10.9454 0.372646 11.0622 0.419796C11.1789 0.466946 11.2849 0.537121 11.3739 0.626146C11.463 0.715171 11.5332 0.821221 11.5803 0.937946C11.6274 1.05468 11.6507 1.17972 11.6484 1.3056C11.6462 1.43148 11.6187 1.55562 11.5674 1.67062C11.5162 1.78562 11.4423 1.88912 11.3502 1.97495L7.32515 5.99995L11.3502 10.0249C11.4423 10.1108 11.5162 10.2143 11.5674 10.3293C11.6187 10.4443 11.6462 10.5684 11.6484 10.6943C11.6507 10.8202 11.6274 10.9452 11.5803 11.0619C11.5332 11.1787 11.463 11.2847 11.3739 11.3737C11.2849 11.4628 11.1789 11.5329 11.0622 11.5801C10.9454 11.6272 10.8204 11.6504 10.6945 11.6482C10.5687 11.6459 10.4445 11.6184 10.3295 11.5672C10.2145 11.5159 10.111 11.4421 10.0252 11.3499L6.00015 7.32495L1.97515 11.3499C1.79743 11.5156 1.56236 11.6057 1.31949 11.6014C1.07661 11.5971 0.844889 11.4987 0.673114 11.3269C0.501352 11.1552 0.402964 10.9234 0.398677 10.6806C0.394389 10.4377 0.484552 10.2027 0.650152 10.0249L4.67515 5.99995L0.650152 1.97495C0.474589 1.79917 0.375977 1.56088 0.375977 1.31245C0.375977 1.06401 0.474589 0.825734 0.650152 0.649946Z" fill="currentColor"/>
            </svg>
        );
    }
);
