import { useState, useCallback, FC, ChangeEvent, memo, useMemo } from 'react';
import { debounce } from 'lodash';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../../../constants/variables/component-settings';

import {
    Container,
    Root,
    Icon,
    InputWrapper,
    SearchIcon,
    StyledInput,
} from './style';
import { TableSearchInputProps } from './types';

const TableSearchInput: FC<TableSearchInputProps> = memo(props => {
    const { placeholder, initialValue, onChange, disabled, dataTest, debounceDelay } = props;
    const dataTestOrDefault = dataTest || 'table-search-input';

    const [value, setValue] = useState(initialValue || '');

    const onChangeDebounced = useMemo(
        () => onChange ? debounce(onChange, debounceDelay ?? SEARCH_INPUT_DEBOUNCE_DELAY) : undefined,
        [onChange, debounceDelay]
    );

    /**
     * @function changeHandler
     * @returns { void }
     */
    const changeHandler = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const value = e.target.value;
            setValue(prevState => {
                onChangeDebounced?.(value);
                return value;
            });
        },
        [onChangeDebounced]
    );

    /**
     * @function clearHandler
     * @returns { void }
     */
    const clearHandler = () => {
        setValue('');
        onChange && onChange('');
    };

    return (
        <Root data-test={dataTest}>
            <Container>
                <Icon data-test={`${dataTestOrDefault}-icon`}>
                    <SearchIcon disabled={disabled} />
                </Icon>
                <InputWrapper>
                    <StyledInput
                        placeholder={placeholder}
                        handleChange={changeHandler}
                        value={value}
                        fontSize="13px"
                        clearInput={clearHandler}
                        height="38px"
                        disabled={disabled}
                        dataTest={dataTestOrDefault}
                    />
                </InputWrapper>
            </Container>
        </Root>
    );
});

export default TableSearchInput;
