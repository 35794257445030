import { FC, useEffect, useRef } from 'react';
import ModalPage, {
    ModalPageContainerProps,
} from '../../../../ui/components/ModalPage/ModalPage';
import styled from 'styled-components';
import Header from './components/Header/Header';
import Actions from './components/Actions/Actions';
import AllUsers from './tabs/AllUsers/AllUsers';
import TeamMembers from './tabs/TeamMembers/TeamMembers';
import DefaultTeamMembers from './tabs/DefaultTeamMembers/DefaultTeamMembers';
import {
    useGetSingleTeamService,
    useResetSingleTeamPageService,
    useUpdateTeamNameService,
} from '../../store/services';
import { useTeamEditState } from '../../store/states';
import { TeamV2 } from '../../../../constants/interfaces/Team';
import { useParams } from 'react-router';
import { useHistory } from '../../../../tools/router';
import { WithLabelsContext } from '../../../portableLabels';
import { TableDivider } from '../../../../ui/components/table-wrapper/table-divider';
import { TeamUpdateStatus } from '../../store/states';
import { TeamMembersExternalInterface } from './tabs/TeamMembers/types';
import { AllUsersExternalInterface } from './tabs/AllUsers/types';
import { onTriggerEvent } from '../../../../helpers/functions/Events';
import {
    EventNameList,
    REFRESH_TEAMS_LIST,
    REFRESH_TEAM_CONTROLLER,
    REFRESH_TEAM_MEMBERS,
} from '../../../../helpers/functions/Events/types';

const StyledModalPage = styled(ModalPage)<{ isDefaultTeam: boolean }>`
    width: ${props => (props.isDefaultTeam ? '50%' : '65%')};
    min-width: 780px;
    ${props =>
        props.isDefaultTeam &&
        `
            @media (max-width: ${props.theme.BreakPoints.responsiveSm}px) {
                left: 47px;
                width: unset;
                right: 0
            }

    `}
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: ${props => props.theme.Colors.white};
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

const Body = styled.div`
    margin: 15px 0 24px 0;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    overflow: auto;
    min-height: 350px;
`;

const DefaultBody = styled.div`
    margin: 0 0 24px 0;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    overflow: auto;
    min-height: 350px;
`;

const ManageTeam: FC<{
    team?: TeamV2;
    handleUpdateTeamName: (value: string) => Promise<void>;
    handleCancel: () => void;
    isDefaultTeam: boolean;
    updateStatus?: TeamUpdateStatus;
    getSingleTeam: (teamId: number) => void;
}> = ({
    team,
    handleUpdateTeamName,
    handleCancel,
    isDefaultTeam,
    updateStatus,
    getSingleTeam,
}) => {
    const teamMembersRef = useRef<TeamMembersExternalInterface>();
    const handleAddTeamMembersSuccess = () => {
        teamMembersRef.current?.refreshData();
    };

    const allUsersRef = useRef<AllUsersExternalInterface>();
    const handleRemoveTeamMembersSuccess = () => {
        allUsersRef.current?.refreshData();
        getSingleTeam(team!.id);
    };

    return (
        <Container>
            <Header
                handleCancel={handleCancel}
                lastUpdated={team?.updatedAt}
                updateStatus={updateStatus}
            />
            {!isDefaultTeam && (
                <>
                    <TableDivider />
                    <Actions
                        team={team}
                        handleUpdateTeamName={handleUpdateTeamName}
                    />
                    <TableDivider />
                </>
            )}
            {isDefaultTeam ? (
                <DefaultBody>
                    <DefaultTeamMembers />
                </DefaultBody>
            ) : (
                <Body>
                    <AllUsers
                        ref={allUsersRef}
                        onSuccessAddUsersBulkActionCallback={
                            handleAddTeamMembersSuccess
                        }
                    />
                    <TeamMembers
                        ref={teamMembersRef}
                        onSuccessRemoveUsersBulkActionCallback={
                            handleRemoveTeamMembersSuccess
                        }
                    />
                </Body>
            )}
        </Container>
    );
};

const ManageTeamContainer: FC<ModalPageContainerProps> = ({
    closePath,
    closeGoingBack,
}) => {
    const history = useHistory();
    const teamState = useTeamEditState();

    const teamStateData = teamState && teamState.data;
    const resetPage = useResetSingleTeamPageService();
    const getSingleTeam = useGetSingleTeamService();
    const params: {
        teamId?: string | number;
        isDefault?: string;
    } = useParams();
    const teamId = params.teamId!;
    const isDefaultParam = history?.location?.state?.data?.isDefaultTeam;

    const isDefaultTeam =
        isDefaultParam || (teamStateData && teamStateData.isDefault) || false;
    const updateTeamName = useUpdateTeamNameService(teamId);

    const handleGoBack = () => {
        history.goBack();
    };

    useEffect(() => {
        if (params.teamId) {
            getSingleTeam(Number(params.teamId));
        }
    }, [getSingleTeam, params.teamId]);

    useEffect(() => {
        return () => {
            resetPage();

            // refresh TeamController && TeamMembers Page
            onTriggerEvent(EventNameList[REFRESH_TEAM_MEMBERS]);
            onTriggerEvent(EventNameList[REFRESH_TEAM_CONTROLLER]);
            onTriggerEvent(EventNameList[REFRESH_TEAMS_LIST]);
        };
    }, [resetPage]);

    return (
        <WithLabelsContext.Provider value={{ reducerName: 'teamEdit' }}>
            <StyledModalPage
                isDefaultTeam={isDefaultTeam}
                closePath={closePath}
                closeGoingBack={closeGoingBack}
                handleManualClose={handleGoBack}
            >
                <ManageTeam
                    team={teamStateData}
                    handleUpdateTeamName={updateTeamName}
                    handleCancel={handleGoBack}
                    isDefaultTeam={isDefaultTeam}
                    updateStatus={teamState.updateStatus}
                    getSingleTeam={getSingleTeam}
                />
            </StyledModalPage>
        </WithLabelsContext.Provider>
    );
};

export default ManageTeamContainer;
