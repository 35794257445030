import { Switch } from 'react-router-dom';
import PrivateRoute from '../../routes/PrivateRouter';
import { ModalWithCross } from '../../ui/components/ModalWithCross';
import DetailsNav from '../../ui/icons/DetailsNav';
import Seats from '../../ui/icons/Seats';
import LogoNav from '../../ui/icons/LogoNav';
import Voice from '../../ui/icons/Voice';
import ROUTES from '../../routes/routes';
import { CompanySettingsViewProps } from './types';
import {
    CompanyName,
    ModalContent,
    ModalSidebar,
    ModalTitle,
    NavigationIcon,
    NavigationItem,
    TabContent
} from './styles';
import { CompanyDetails } from './components/CompanyDetails';
import { CompanyUserLimit } from './components/CompanyUserLimit';
import { CompanyUpdateLogo } from './components/CompanyUpdateLogo';
import { CompanyVoice } from './components/CompanyVoice';

function CompanySettingsView({
    company,
    onClose,
    onCompanyUpdate,
    createNavigationDescriptor,
}: CompanySettingsViewProps) {
    const isCompanyLoaded = !!company?.id;

    return (
        <ModalWithCross
            titleContent={
                <ModalTitle>
                    <span data-test="company-settings-modal-title">Company Settings</span>
                    {isCompanyLoaded &&
                        <CompanyName>
                            &nbsp;•&nbsp;
                            <span data-test="company-name-modal-title">{company!.name}</span>
                        </CompanyName>
                    }
                </ModalTitle>
            }
            onClose={onClose}
            width={790}
            dataTest="company-settings-modal"
        >
            <ModalContent>
                <ModalSidebar>
                    <NavigationItem
                        to={createNavigationDescriptor(ROUTES.COMPANY_SETTINGS.ROOT)}
                        activeClassName={'is-active'}
                        exact
                        data-test="details-tab"
                    >
                        <NavigationIcon><DetailsNav /></NavigationIcon>
                        Details
                    </NavigationItem>
                    <NavigationItem
                        to={createNavigationDescriptor(ROUTES.COMPANY_SETTINGS.LOGO)}
                        activeClassName={'is-active'}
                        data-test="logo-tab"
                    >
                        <NavigationIcon><LogoNav /></NavigationIcon>
                        Logo
                    </NavigationItem>
                    <NavigationItem
                        to={createNavigationDescriptor(ROUTES.COMPANY_SETTINGS.USER_LIMIT)}
                        activeClassName={'is-active'}
                        data-test="licensed-seats-tab"
                    >
                        <NavigationIcon><Seats /></NavigationIcon>
                        Licensed Seats
                    </NavigationItem>
                    <NavigationItem
                        to={createNavigationDescriptor(ROUTES.COMPANY_SETTINGS.VOICE)}
                        activeClassName={'is-active'}
                        data-test="voice-tab"
                    >
                        <NavigationIcon><Voice /></NavigationIcon>
                        AI Voice
                    </NavigationItem>
                </ModalSidebar>
                <TabContent>
                    <Switch>
                        <PrivateRoute
                            path={ROUTES.COMPANY_SETTINGS.ROOT}
                            exact
                        >
                            {isCompanyLoaded &&
                                <CompanyDetails
                                    company={company!}
                                    onCompanyUpdate={onCompanyUpdate}
                                />
                            }
                        </PrivateRoute>
                        <PrivateRoute
                            path={ROUTES.COMPANY_SETTINGS.LOGO}
                            exact
                        >
                            {isCompanyLoaded &&
                                <CompanyUpdateLogo
                                    company={company!}
                                    onCompanyUpdate={onCompanyUpdate}
                                />
                            }
                        </PrivateRoute>
                        <PrivateRoute
                            path={ROUTES.COMPANY_SETTINGS.USER_LIMIT}
                            exact
                        >
                            {isCompanyLoaded && (
                                <CompanyUserLimit
                                    company={company!}
                                />
                            )}
                        </PrivateRoute>
                        <PrivateRoute
                            path={ROUTES.COMPANY_SETTINGS.VOICE}
                            exact
                        >
                            {isCompanyLoaded && (
                                <CompanyVoice
                                    companyId={company!.id!}
                                />
                            )}
                        </PrivateRoute>
                    </Switch>
                </TabContent>
            </ModalContent>
        </ModalWithCross>
    );
}

export default CompanySettingsView;