import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    customTableColumnStyle: {
        minWidth: 120,
    },

    customTableCellStyle: {
        padding: 0,
    },

    teamsTableCellStyle: {
        width: 85,
    },
}));

