import { FC, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';
import { useTeamEditState } from '../../../../store/states';
import {
    useSearchSingleTeamMembersService,
    useTogglePromoteTeamLeader,
    useDeleteSingleTeamMemberLabelService
} from '../../../../store/services';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../../../../constants/interfaces/filters';
import { ListResult } from '../../../../../../constants/interfaces/PaginationResult';
import { UserProfile } from '../../../../../../constants/interfaces/User';
import { TeamMember } from '../../../../../../constants/interfaces/TeamMember';
import SwitchToggle from '../../../../../../ui/components/SwitchToggle/SwitchToggle';
import Crown from '../../../../../../ui/icons/Crown';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../../../../pages/UserProfile/store/reducers';
import { handleGenerateTeamLeadersCountText } from '../../tools';
import { useLabelsState } from '../../../../../portableLabels/store/hors/withLabels/states';
import {
    useSaveLabels,
    useSelectLabels,
} from '../../../../../portableLabels/store/hors/withLabels/services';
import { WithLabelsContext } from '../../../../../portableLabels';
import { Popup } from '../../../../../../ui/components/Popup';
import { AllUsersFilter } from '../../components/Filters/AllUsersFilters';
import FilterByHandler from '../../../../../../ui/components/Filters/FilterByHandler';
import {
    isCompanyAdminRole,
    isPractisAdminRole,
} from '../../../../../../constants/enums';
import { listToTree } from '../../../../../../helpers/functions/list-to-tree';
import { useHandleSelectLabels } from '../../../../../labels/tools';
import { useIfChanged } from '../../../../../../helpers/hooks/usePreviousData';
import { Label } from '../../../../../../constants/interfaces/Label';
import { Tooltip } from '../../../../../../ui/components/Tooltip';
import User from '../../../../../../ui/icons/User';
import { NEW_PERMISSIONS } from '../../../../../../constants/enums/permissions';
import { useUpdateUsersState } from '../../../../../users/store/states';
import { useSearchDebounced } from '../../../../../../helpers/hooks/useSearch';
import { usePortableLabelsState } from '../../../../../portableLabels/store/states';
import {
    TableStateValues,
    TableWrapper,
} from '../../../../../../ui/components/table-wrapper';
import {
    Avatar,
    AvatarTile,
    Container,
    Header,
    StyledFirstTableContent,
    TeamLeaderIcon,
    TeamLeaders,
    Title,
    ToggleWrapper,
    useStyles,
} from './styles';
import { TableHeaderCellProps } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/type';
import { TableDivider } from '../../../../../../ui/components/table-wrapper/table-divider';
import { TableLabelTags } from '../../../../../../ui/components/table-wrapper/table/TableLabelTags';
import TableTitleOverflowText from '../../../../../../ui/components/table-wrapper/table/TableTitleOverflowText/TableTitleOverflowText';
import { useTableStateHelper } from '../../../../../../ui/components/table-wrapper/helper';
import { useOrderBy } from '../../../../../../ui/components/table-wrapper/table/table-header/table-header-cell/hook';
import { TableViewMode } from '../../../../../../ui/components/table-wrapper/table/type';

const DefaultTeamMembers: FC<{
    users?: ListResult<TeamMember>;
    profile?: UserProfile;
    searchTerm?: string;
    searchParams: SearchParams;
    setSearchTerm: (searchTerm: string) => void;
    orderByUserName: Partial<TableHeaderCellProps>;
    orderByTeamLeader: Partial<TableHeaderCellProps>;
    handleTeamLeaderChange: (
        teamUserId: number,
        isTeamLead: boolean,
    ) => void;
    setOffset: (offset: number) => void;
    labelIDs: number[];
    setLabelIDs: (labelIds: number[]) => void;
    totalCount?: number;
    leadersCount?: number;
    labelsOpen?: number | null;
    handleToggleLabels: (open: number) => void;
    tableStates: TableStateValues;
    registrationStatusHandler?: (status: string[]) => void;
    deleteMemberLabel: (userId: number, labelId: number) => void;
}> = ({
    users,
    profile,
    searchParams,
    setSearchTerm,
    orderByUserName,
    orderByTeamLeader,
    handleTeamLeaderChange,
    setOffset,
    labelIDs,
    setLabelIDs,
    totalCount,
    leadersCount,
    labelsOpen,
    handleToggleLabels,
    tableStates,
    deleteMemberLabel,
    registrationStatusHandler,
}) => {
    const classes = useStyles();
    const labels = useLabelsState();
    const labelsList = usePortableLabelsState();
    const setSelectLabels = useSelectLabels();
    const saveLabels = useSaveLabels();
    const labelsTree = listToTree(labelsList.data.items);
    const handleSelectLabels = useHandleSelectLabels();
    const ifLabelsChanged = useIfChanged(labelIDs);

    const onRemoveLabel = (label: Label) => {
        setLabelIDs(handleSelectLabels(label, labelIDs, labelsTree));
    };

    useEffect(() => {
        if (ifLabelsChanged) {
            setSelectLabels(labelIDs);
            saveLabels();
        }
    }, [labelIDs, ifLabelsChanged, setSelectLabels, saveLabels]);

    //TODO: Refactor/remove from every page
    const handleAssignedLabelDelete = (labelId: number, userId: number) => {
        deleteMemberLabel(userId, labelId);
    };

    const revokeLeaderDisabled = !(
        isPractisAdminRole(profile?.role?.name) ||
        isCompanyAdminRole(profile?.role?.name)
    );

    const scrollableRef = useRef<HTMLDivElement>(null);
    const filterByRegistrationLength: any =
        searchParams?.filterByRegistrationStatus?.length;

    /**
     * @function checkIsLabelTagsOpen
     * @param { TeamMember } member
     * @returns { boolean }
     */
    const checkIsLabelTagsOpen = (member: TeamMember): boolean => {
        return labelsOpen === member.user.id;
    };

    return (
        <Container>
            <Header>
                <Title data-test="all-members-title">All Members</Title>
                <TeamLeaders data-test="team-leaders-count">
                    {handleGenerateTeamLeadersCountText(leadersCount ?? 0)}
                </TeamLeaders>
            </Header>
            <TableDivider />
            <TableWrapper
                tableStates={tableStates}
                data={users?.items}
                viewMode={TableViewMode.INFINITE_SCROLL}
                headerFilterPosition="BOTTOM"
                tableWrapperClassName={classes.tableWrapperStyle}
                tableContainerClassName={classes.tableBodyContainerStyle}
                scrollRef={scrollableRef}
                scrollPaginationProps={{
                    itemsPerPage: ITEMS_PER_PAGE,
                    itemCount: users ? users.items.length : 0,
                    totalCount: totalCount,
                    onOffsetChange: setOffset,
                    scrollableRef: scrollableRef,
                    hideLoading: false,
                    height: 300,
                    className: classes.scrollWrapperStyle,
                }}
                selectedLabels={labelIDs}
                onRemoveLabel={
                    labels.saved.length > 0 ? onRemoveLabel : undefined
                }
                tableToolsOptions={{
                    searchInputOptions: {
                        initialValue: searchParams.searchTerm,
                        onSearchChange: setSearchTerm,
                        isSearchInputDisabled: tableStates.disableSearch,
                        className: classes.searchInputStyle,
                        dataTest: 'all-members-search',
                    },
                    filterOptions: {
                        filterComponent: (
                            <Popup<HTMLButtonElement>
                                content={({ hide }) => (
                                    <AllUsersFilter
                                        onLabelsChange={setLabelIDs}
                                        onSuccessApply={() => hide()}
                                        currentLabelIds={labelIDs}
                                        registrationStatus={
                                            searchParams.filterByRegistrationStatus ||
                                            []
                                        }
                                        onRegistrationStatusChange={
                                            registrationStatusHandler
                                        }
                                    />
                                )}
                            >
                                {(ref, { toggleShown, shown }) => (
                                    <FilterByHandler
                                        ref={ref}
                                        open={shown}
                                        disabled={tableStates.disableFilters}
                                        toggleOpen={toggleShown}
                                        filtersCount={
                                            labelIDs.length +
                                            filterByRegistrationLength
                                        }
                                        dataTest="all-members-filters"
                                    />
                                )}
                            </Popup>
                        ),
                    },
                    itemCount: users?.count
                        ? `${users?.count} ${
                              users?.count === 1 ? 'item' : 'items'
                          }`
                        : '',
                    itemCountDataTest: 'all-members-count',
                    customization: {
                        wrapperClassName: classes.tableToolsWrapperStyle,
                        tableToolsLeftClassName:
                            classes.tableToolsLeftClassName,
                        rootClassName: classes.tableToolsRootStyle,
                    },
                }}
                tableEmptyStateConfigurations={{
                    shouldShowEmptyState: true,
                    noEntriesOptions: {
                        icon: User,
                        text: 'Add Members',
                        dataTest: 'no-all-members',
                    },
                    noSearchResultsOptions: {
                        entityName: 'Members',
                        dataTest: 'no-found-all-members',
                    },
                }}
                configurations={{
                    columns: [
                        {
                            title: 'Users',
                            width: 97,
                            ...orderByUserName,
                            disabled: tableStates.disableSorting,
                            dataTest: 'users-column'
                        },
                        {
                            title: 'Team Leader',
                            width: 2,
                            ...orderByTeamLeader,
                            disabled: tableStates.disableSorting,
                            dataTest: 'team-leader-column'
                        },
                    ],

                    rowConfig: {
                        isLabelTagsOpen: checkIsLabelTagsOpen,
                        getLabelTagsProps: (member: TeamMember) => ({
                            selectedLabels: member.labels,
                            deletePermissions: [
                                NEW_PERMISSIONS.REMOVE_USER_LABEL,
                            ],
                            onDeleteLabel: (labelId: number) =>
                                handleAssignedLabelDelete(
                                    labelId,
                                    member.user.id
                                ),
                        }),
                        dataTest: 'all-members-item',

                        cells: [
                            {
                                fieldType: 'CUSTOM_FIELD',
                                cellClassName: classes.customCellStyle,
                                getCustomFieldComponent: (
                                    member: TeamMember
                                ) => (
                                    <StyledFirstTableContent>
                                        <TableLabelTags
                                            open={labelsOpen === member.user.id}
                                            toggleOpen={() =>
                                                handleToggleLabels(
                                                    member.user.id
                                                )
                                            }
                                            selectedLabels={member.labels}
                                            dataTest="member-labels"
                                        />
                                        <AvatarTile>
                                            <Avatar
                                                customProfile={{
                                                    firstName:
                                                        member.user.firstName,
                                                    lastName:
                                                        member.user.lastName,
                                                    picture:
                                                        member.user.imageUrl,
                                                    status: member.userStatus,
                                                }}
                                                size="sm"
                                                dataTest="member-avatar"
                                            />
                                            <TableTitleOverflowText
                                                isCurrent={
                                                    profile &&
                                                    profile.id ===
                                                        member.user.id
                                                }
                                                renderIcon={
                                                    member.isTeamLead && (
                                                        <TeamLeaderIcon data-test="team-leader-icon">
                                                            <Crown />
                                                        </TeamLeaderIcon>
                                                    )
                                                }
                                                dataTest="member-name"
                                            >
                                                {`${member.user.firstName} ${member.user.lastName}`}
                                            </TableTitleOverflowText>
                                        </AvatarTile>
                                    </StyledFirstTableContent>
                                ),
                            },

                            {
                                fieldType: 'CUSTOM_FIELD',
                                getCustomFieldComponent: (
                                    member: TeamMember
                                ) => (
                                    <Tooltip
                                        label="Only admin can unassign team leader"
                                        position="center-right--bottom"
                                        disabled={
                                            !(
                                                revokeLeaderDisabled &&
                                                !!member.isTeamLead
                                            )
                                        }
                                    >
                                        <ToggleWrapper>
                                            <SwitchToggle
                                                index={member.user.id}
                                                checked={member.isTeamLead}
                                                onChange={e => {
                                                    handleTeamLeaderChange(
                                                        member.id,
                                                        e.target.checked
                                                    );
                                                }}
                                                height={24}
                                                width={42}
                                                bulletRadius={16}
                                                disabled={
                                                    revokeLeaderDisabled &&
                                                    member.isTeamLead
                                                }
                                                dataTest="team-leader-toggle"
                                            />
                                        </ToggleWrapper>
                                    </Tooltip>
                                ),
                            },
                        ],
                    },
                }}
            />
        </Container>
    );
};

const ITEMS_PER_PAGE = 20;

const DefaultTeamMembersContainer = () => {
    const params: { teamId: string } = useParams();
    const teamEditState = useTeamEditState();
    const profile = useSelector(getProfileState);
    const teamId = params.teamId;
    const searchTeamMembers = useSearchSingleTeamMembersService(teamId);
    const searchTeamMembersDebounced = useSearchDebounced(searchTeamMembers);
    const handleTableStates = useTableStateHelper();
    const deleteLabelFromUser = useDeleteSingleTeamMemberLabelService(teamId);
    const updatedUsersState = useUpdateUsersState();

    const initialSearchParams: SearchParams = {
        searchTerm: '',
        filters: [],
        labelIDs: [],
        orderBy: null,
        limit: ITEMS_PER_PAGE,
        offset: 0,
        totalCount: 0,
        numberOfPages: 0,
    };

    const {
        searchParams,
        setSearchTerm,
        setOrderBy,
        setLabelIDs,
        setOffset,
        setCustom,
    } = useSearchParamsState(initialSearchParams);

    useEffect(() => {
        searchTeamMembersDebounced(searchParams);
    }, [searchParams, searchTeamMembersDebounced, updatedUsersState.data]);

    const orderBy = searchParams.orderBy;
    const orderByUserName = useOrderBy('name', orderBy, setOrderBy);
    const orderByTeamLeader = useOrderBy(
        'is_team_lead',
        orderBy,
        setOrderBy
    );

    const handleTeamLeaderChange = useTogglePromoteTeamLeader();

    const [labelsOpen, setLabelsOpen] = useState<null | undefined | number>(
        null
    );

    const registrationStatusHandler = (values: string[]) => {
        setCustom('filterByRegistrationStatus', values);
    };

    const handleToggleLabels = (itemId?: number) => {
        if (!itemId) return;
        if (labelsOpen === itemId) {
            setLabelsOpen(null);
        } else {
            setLabelsOpen(itemId);
        }
    };

    const tableStates = handleTableStates({
        searchTerm: searchParams.searchTerm,
        appliedFilters: searchParams.labelIDs?.length,
        itemsCount: teamEditState?.teamMembers?.count || 0,
    });

    return (
        <WithLabelsContext.Provider
            value={{
                reducerName: 'teamEdit',
                scope: 'defaultTeamMembers',
            }}
        >
            <DefaultTeamMembers
                users={teamEditState.teamMembers}
                profile={profile}
                searchTerm={searchParams.searchTerm}
                setSearchTerm={setSearchTerm}
                orderByUserName={orderByUserName}
                orderByTeamLeader={orderByTeamLeader}
                handleTeamLeaderChange={handleTeamLeaderChange}
                setOffset={setOffset}
                labelIDs={searchParams.labelIDs || []}
                setLabelIDs={setLabelIDs}
                totalCount={teamEditState.teamMembers?.count}
                leadersCount={teamEditState?.data?.leaders?.length}
                labelsOpen={labelsOpen}
                handleToggleLabels={handleToggleLabels}
                tableStates={tableStates}
                deleteMemberLabel={deleteLabelFromUser}
                searchParams={searchParams}
                registrationStatusHandler={registrationStatusHandler}
            />
        </WithLabelsContext.Provider>
    );
};

export default DefaultTeamMembersContainer;
