import React, { FC } from 'react';
import styled from 'styled-components';

import EmptyBox from '../../icons/EmptyBox';
import { Loading } from '../LoadingCopmonent';
import NoSearchResult from '../../icons/NoSearchResult';

interface Props {
    itemsName: string;
    loading?: boolean;
    empty?: boolean;
}

export const FiltersNoItems: FC<Props> = props => {
    const { itemsName, loading, empty } = props;

    if (loading) {
        return (
            <Root>
                <Loading />
            </Root>
        );
    }

    return (
        <Root data-test="filters-no-item-container">
            <Column>
                <IconWrapper data-test="no-search-result">
                    {empty ? <EmptyBox /> : <NoSearchResult />}
                </IconWrapper>
                <NoItemsTitle data-test='no-items-found'>
                    {empty ? `No ${itemsName} yet` : `No ${itemsName} found`}
                </NoItemsTitle>
            </Column>
        </Root>
    );
};

const Root = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const Column = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoItemsTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;
