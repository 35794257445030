import { FC } from 'react';

import {
    EmptyState,
    NoFilterResults,
    NoSearchResults,
    NoCalendarResults
} from '../../../EmptyStates';
import { EmptyStateContainer } from '../../../EmptyStates/components/EmptyStateContainer';
import EmptyStateTableWrapper from '../../../EmptyStates/components/EmptyStateTableWrapper';
import { EmptyStateWrapperProps } from './type';

const EmptyStateWrapper: FC<EmptyStateWrapperProps> = props => {
    const {
        tableStates,
        noEntriesOptions,
        noSearchResultsOptions,
        noFilterResultsOptions,
        noCalendarResultsOptions,
    } = props;

    return (
        <EmptyStateTableWrapper>
            <EmptyStateContainer>
                {tableStates.showNoEntriesState && (
                    <EmptyState icon={noEntriesOptions?.icon} width={noEntriesOptions?.width} dataTest={noEntriesOptions?.dataTest}>
                        {noEntriesOptions?.text}
                    </EmptyState>
                )}
                {tableStates.showNoSearchResultsState && (
                    <NoSearchResults {...noSearchResultsOptions} />
                )}
                {tableStates.showNoFilterResultsState && (
                    <NoFilterResults {...noFilterResultsOptions} />
                )}
                {tableStates.showNoCalendarResultsState && (
                    <NoCalendarResults {...noCalendarResultsOptions} />
                )}
            </EmptyStateContainer>
        </EmptyStateTableWrapper>
    );
};

export default EmptyStateWrapper;
