import { useCallback, useEffect, useMemo } from 'react';

import { useGenerateReportService, useGetReportAvailabilityService } from '../../../features/filters/filters-sidebar/services';
import { ReportingControlPanelMode } from '../../../ui/components/ReportingControlPanel/types';
import BillingReportView from './view';
import { ReportType } from '../../../constants/interfaces/ReportItem';
import { useHistory } from '../../../tools/router';
import ROUTES from '../../../routes/routes';
import { useMonthFilterState } from '../../../features/filters/dynamic-filter/month-filter/store/reducers';
import { useClearYearAndMonth, useDisableMonthFilter } from '../../../features/filters/dynamic-filter/month-filter/store/services';
import { useSelector } from 'react-redux';
import { getCompanyState } from '../../CompanySettings/store/reducers';
import dayjs from 'dayjs';
import { DATE_FORMAT } from './constants';

function BillingReportController() {
    const { isGenerating, remainingTimeout, generateReport } = useGenerateReportService();
    const { isAvailable, getReportAvailability } = useGetReportAvailabilityService();
    const { selectedYear, selectedMonth } = useMonthFilterState();
    const clearYearAndMonth = useClearYearAndMonth();
    const disableMonthFilter = useDisableMonthFilter();
    const company = useSelector(getCompanyState);
    const history = useHistory();

    const handleClearFilters = useCallback(() => {
        clearYearAndMonth();
        history.replace(ROUTES.REPORTS.BILLING_REPORT);
    }, [clearYearAndMonth, history]);

    const controlPanelMode: ReportingControlPanelMode = useMemo(() => {
        if (remainingTimeout) {
            return 'timeout';
        }
        
        if (isGenerating) {
            return 'loading';
        }

        if (typeof selectedMonth === 'undefined') {
            return 'disabled';
        }

        if (!isAvailable) {
            return 'noData';
        }

        return 'enabled';
    }, [remainingTimeout, isGenerating, selectedMonth, isAvailable]);

    const getReportParams = useCallback(() => {
        if (typeof selectedMonth === 'undefined') return;
        const dateStart = dayjs(new Date(selectedYear!, selectedMonth, 1));
        const dateEnd = dateStart.add(1, 'month');
        if (!company?.id) return;
        return {
            companyId: company.id,
            dateStart: dateStart.format(DATE_FORMAT),
            dateEnd: dateEnd.format(DATE_FORMAT),
        };
    }, [company?.id, selectedMonth, selectedYear]);

    const handleGenerateReport = useCallback(() => {
        const params = getReportParams();
        if (params) {
            generateReport(ReportType.BILLING, params);
        }
    }, [generateReport, getReportParams]);

    useEffect(() => {
        return () => {
            clearYearAndMonth()
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const params = getReportParams();
        if (params) {
            getReportAvailability(ReportType.BILLING, params);
        }
    }, [getReportAvailability, getReportParams]);

    useEffect(() => {
        disableMonthFilter(isGenerating);
    }, [disableMonthFilter, isGenerating]);

    return (
        <BillingReportView
            onClearFilters={handleClearFilters}
            onGenerateReport={handleGenerateReport}
            controlPanelMode={controlPanelMode}
            remainingTimeout={remainingTimeout}
        />
    );
}

export default BillingReportController;