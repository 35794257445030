import React, { FC } from 'react';
import styled from 'styled-components';

const StyledSelect = styled.div<{
    height?: number;
    padding?: string;
    fontSize?: string;
}>`
    width: 100%;
    height: 100%;
    padding: ${props =>
        !!props.padding
            ? props.padding
            : `${props.height ? props.height / 2 - 4 : 29}px 24px
        15px 24px;`};
    box-sizing: border-box;
    font-size: ${props => (!!props.fontSize ? props.fontSize : '13px')};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;

const StyledSelectValue = styled.div<{
    color?: string;
    disabled?: boolean;
}>`
    overflow: hidden;
    color: ${props => props.disabled ? props.theme.Colors.cloudyBlue : (props.color ?? props.theme.Colors.steelGrey)};
    text-overflow: ellipsis;
    padding-right: 4px;
`;

export const SelectInput: FC<{
    children?: any;
    items?: any;
    value?: string | number;
    height?: number;
    padding?: string;
    toggleSelect?: (e?: any) => void;
    fontSize?: string;
    disabled?: boolean;
    color?: string;
    className?: string;
    dataTest?: string;
}> = ({ value, toggleSelect, height, padding, fontSize, disabled, color, className, dataTest }) => {
    return (
        <StyledSelect
            height={height}
            onClick={toggleSelect}
            padding={padding}
            fontSize={fontSize}
            className={className}
            data-test={dataTest}
        >
            <StyledSelectValue color={color} disabled={disabled}>{value}</StyledSelectValue>
        </StyledSelect>
    );
};
