import useWindowDimensions from '../../helpers/hooks/useWindowDimensions';
import { Variables } from '../../theme/variables';
import LandingCard1 from '../../assets/images/LandingCard1.png';
import LandingCard2 from '../../assets/images/LandingCard2.png';
import LandingCard3 from '../../assets/images/LandingCard3.png';

export enum Platform {
    DESKTOP = 'DESKTOP',
    LAPTOP = 'LAPTOP',
    TABLET = 'TABLET',
    MOBILE = 'MOBILE',
}

const CardWidth = {
    [Platform.DESKTOP]: 448,
    [Platform.LAPTOP]: 350,
    [Platform.TABLET]: 304,
    [Platform.MOBILE]: 289,
};

const CardHeight = {
    [Platform.DESKTOP]: 592,
    [Platform.LAPTOP]: 480,
    [Platform.TABLET]: 424,
    [Platform.MOBILE]: 304,
};

export const useGetPlatform = (): string => {
    const windowWidth = useWindowDimensions().width;

    if (windowWidth > Variables.BreakPoints.mid) {
        return Platform.DESKTOP;
    } else if (windowWidth > Variables.BreakPoints.small) {
        return Platform.LAPTOP;
    } else if (windowWidth > Variables.BreakPoints.smaller) {
        return Platform.TABLET;
    } else {
        return Platform.MOBILE;
    }
};

export const useDynamicCardWidth = (): number => {
    const windowWidth = useWindowDimensions().width;

    if (windowWidth > Variables.BreakPoints.mid) {
        return CardWidth[Platform.DESKTOP];
    } else if (windowWidth > Variables.BreakPoints.small) {
        return CardWidth[Platform.LAPTOP];
    } else if (windowWidth > Variables.BreakPoints.smaller) {
        return CardWidth[Platform.TABLET];
    } else {
        return CardWidth[Platform.MOBILE];
    }
};

export const useDynamicCardHeight = (): number => {
    const windowWidth = useWindowDimensions().width;

    if (windowWidth > Variables.BreakPoints.mid) {
        return CardHeight[Platform.DESKTOP];
    } else if (windowWidth > Variables.BreakPoints.small) {
        return CardHeight[Platform.LAPTOP];
    } else if (windowWidth > Variables.BreakPoints.smaller) {
        return CardHeight[Platform.TABLET];
    } else {
        return CardHeight[Platform.MOBILE];
    }
};

export const CARDS = [
    {
        description: 'Are you a sales organization with a distributed team?',
        image: LandingCard1,
    },
    {
        description:
            'Do you rely on consistency of message to optimize your close rate?',
        image: LandingCard2,
    },
    {
        description:
            'Do you have a hard time monitoring capability and compliance?',
        image: LandingCard3,
    },
];
