import mainImageDesktop from '../../../../../assets/images/Learner-Desktop_img.png';
import mainImageDesktop2x from '../../../../../assets/images/Learner-Desktop_img@2x.png';
import mainImageDesktop3x from '../../../../../assets/images/Learner-Desktop_img@3x.png';
import mainImageTablet from '../../../../../assets/images/Learner-Tablet_img.png';
import mainImageTablet2x from '../../../../../assets/images/Learner-Tablet_img@2x.png';
import mainImageTablet3x from '../../../../../assets/images/Learner-Tablet_img@3x.png';
import mainImagePhone from '../../../../../assets/images/Learner-Phone_img.png';
import mainImagePhone2x from '../../../../../assets/images/Learner-Phone_img@2x.png';
import mainImagePhone3x from '../../../../../assets/images/Learner-Phone_img@3x.png';
import appStoreImage from '../../../../../assets/images/button-app-store.png'
import googlePlayImage from '../../../../../assets/images/button-google-play.png'
import useWindowDimensions from '../../../../../helpers/hooks/useWindowDimensions';
import LoginAuthWrapper from '../../../../../ui/wrapper/LoginAuthWrapper';
import { Variables } from '../../../../../theme/variables';
import {
    OnboardingContent,
    OnboardingText,
    OnboardingGreeting,
    OnboardingTitle,
    OnboardingDescription,
    DownloadButtons
} from './styles';
import useHtmlPageTitle from '../../../../../helpers/hooks/useHtmlPageTitle';

function UserOnboardingView() {
    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.small;
    const isTablet = !isMobile && windowDimensions.width < Variables.BreakPoints.responsiveSm;
    const isDesktop= windowDimensions.width >= Variables.BreakPoints.responsiveSm;

    useHtmlPageTitle(
        "You're now registered! Download the Practis mobile app to continue"
    );

    return (
        <LoginAuthWrapper heightToShowScroll={700}>
            <OnboardingContent>
            <OnboardingText>
                <OnboardingGreeting>You're now registered!</OnboardingGreeting>
                <OnboardingTitle>Download the Practis mobile app to continue</OnboardingTitle>
                <OnboardingDescription>
                    We are a mobile-native platform. All the learning happens on our mobile app. Once you download it, log in with the credentials you just created to start practicing.
                </OnboardingDescription>
                <DownloadButtons>
                    <a href="https://apps.apple.com/us/app/practis/id1499329539" target="_blank" rel="noreferrer">
                        <img src={appStoreImage} width="139px" height="40px" alt="Download on the App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.gopractis.android" target="_blank" rel="noreferrer">
                        <img src={googlePlayImage} width="139px" height="40px" alt="Get it on Google Play" />
                    </a>
                </DownloadButtons>
            </OnboardingText>
            {isMobile && (
                <img
                    src={mainImagePhone}
                    srcSet={`${mainImagePhone2x} 2x, ${mainImagePhone3x} 3x`}
                    alt=""
                />
            )}
            {isTablet && (
                <img
                    src={mainImageTablet}
                    srcSet={`${mainImageTablet2x} 2x, ${mainImageTablet3x} 3x`}
                    alt=""
                />
            )}
            {isDesktop && (
                <img
                    src={mainImageDesktop}
                    srcSet={`${mainImageDesktop2x} 2x, ${mainImageDesktop3x} 3x`}
                    alt=""
                />
            )}
            </OnboardingContent>
        </LoginAuthWrapper>
    )
}

export default UserOnboardingView;