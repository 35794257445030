import { useEffect, useRef } from 'react';
import equal from 'deep-equal';

/**
 * @function usePreviousData
 * @param { T } value
 * @returns { T }
 */
export function usePreviousData<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

/**
 * @function useIfChanged
 * @param { T } value
 * @param { boolean } dontCheckEmpty
 * @returns { boolean }
 */
export function useIfChanged<T>(value: T, dontCheckEmpty?: boolean) {
    const previousValue = usePreviousData(value);

    if (!previousValue && dontCheckEmpty) {
        return false;
    }
    return !equal(value, previousValue && previousValue);
}
