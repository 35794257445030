import React, { FC } from 'react';
import styled from 'styled-components';
import Next from '../../../ui/icons/Next';

const Label = styled.div`
    width: 104px;
    height: 48px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.Colors.white};
    user-select: none;
`;

const IconSection = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.Colors.fadedRedTwoMobile};
`;

const StyledButton = styled.div`
    height: 48px;
    display: inline-flex;
    align-items: center;
    background: ${props => props.theme.Colors.fadedRedMobile};
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    &:hover ${IconSection} {
        background: ${props => props.theme.Colors.fadedRedMobile};
    }
    &:active ${Label} {
        background: ${props => props.theme.Colors.fadedRed};
    }
`;

const IconWrapper = styled.div`
    width: 14px;
    height: 12px;
    display: flex;
    color: ${props => props.theme.Colors.white};
`;

const Button: FC<{ onClick(): void }> = ({ onClick }) => {
    return (
        <StyledButton onClick={onClick}>
            <Label>Next</Label>
            <IconSection>
                <IconWrapper>
                    <Next />
                </IconWrapper>
            </IconSection>
        </StyledButton>
    );
};

export default Button;
