import { FC } from 'react';
import { VolumeUp, VolumeOff } from '@material-ui/icons';
import styled from 'styled-components';

import PlayIcon from '../../../icons/PlayIcon';
import PauseIcon from '../../../icons/PauseIcon';
import VideoRangeSlider from '../components/VideoRangeSlider';
import DownloadIcon from '../..//../icons/DownloadIcon';
import FullScreenIcon from '../..//../icons/FullScreenIcon';
import VolumeRangeSlider from '../components/VolumeRangeSlider';
import CopyLink from '../../../icons/CopyLink';
import { Loading } from '../../LoadingCopmonent';

const StyledVideoPlayerControls = styled.div`
    width: 100%;
`;

const ControlsWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 24px 21px 24px;
    box-sizing: content-box;
    z-index: 10;
`;

const Controls = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 4px;
`;

const IconContainerSpacer = styled.div`
    margin: 0 8px;
    display: flex;
    align-items: center;
`;

const VolumeRangeContainer = styled.div`
    width: 50px;
`;

const IconContainer = styled.div<{ disabled?: boolean }>`
    height: 16px;
    width: 16px;
    color: ${props => props.theme.Colors.white};
    border-radius: 100%;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        opacity: 0.9;
        box-shadow: 0 0 0 6px ${props => props.theme.Colors.hoverGray};
        background: ${props => props.theme.Colors.hoverGray};
    }
    ${props =>
        !props.disabled &&
        `
        &:active {
        opacity: 0.7;
        box-shadow: 0 0 0 8px ${props.theme.Colors.hoverGray};
        background: ${props.theme.Colors.hoverGray};
    }
    `}
`;

const LeftControls = styled.div`
    width: 16px;
    margin-right: 18px;
`;

const RangeControls = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const InfoControls = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
`;

const StyledTimer = styled.div`
    font-size: 13px;
    color: ${props => props.theme.Colors.white};
    width: 40px;
    display: flex;
    align-items: center;
`;

const StyledVolume = styled.div`
    display: flex;
`;

const StyledInfoTools = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const VideoPlayerControls: FC<{
    playing: boolean;
    muted: boolean;
    volume: number;
    played: number;
    elapsedTime: string;
    totalDuration?: string;
    setPlaying(): void;
    setMuted(): void;
    onRewind(): void;
    onVolumeChange(e: any, newValue: any): void;
    onVolumeSeekDown(e: any, newValue: any): void;
    onToggleFullScreen(): void;
    onSeek(e: any, newValue: any): void;
    onSeekMouseDown(e: any): void;
    onSeekMouseUp(e: any, newValue: any): void;
    onDownloadVideo?(): void;
    onCopyLink?(): void;
    videoLoading?: boolean;
}> = ({
    playing,
    muted,
    volume,
    played,
    setPlaying,
    setMuted,
    onVolumeChange,
    onVolumeSeekDown,
    onToggleFullScreen,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    onDownloadVideo,
    onCopyLink,
    videoLoading,
    elapsedTime,
}) => {
    return (
        <StyledVideoPlayerControls>
            <ControlsWrapper
                onClick={e => {
                    e.stopPropagation();
                }}
                onDoubleClick={e => {
                    e.stopPropagation();
                }}
            >
                <Controls>
                    <LeftControls>
                        <IconContainer onClick={setPlaying}>
                            {playing ? <PauseIcon /> : <PlayIcon />}
                        </IconContainer>
                    </LeftControls>
                    <RangeControls>
                        <VideoRangeSlider
                            played={played}
                            onChange={onSeek}
                            onMouseDown={onSeekMouseDown}
                            onChangeCommitted={onSeekMouseUp}
                        />
                    </RangeControls>
                </Controls>
                <Controls>
                    <LeftControls />
                    <InfoControls>
                        <StyledInfoTools>
                            <StyledTimer>{elapsedTime}</StyledTimer>
                            <StyledVolume>
                                <IconContainerSpacer>
                                    {muted ? (
                                        <IconContainer onClick={setMuted}>
                                            <VolumeOff fontSize={'small'} />
                                        </IconContainer>
                                    ) : (
                                        <IconContainer onClick={setMuted}>
                                            <VolumeUp fontSize={'small'} />
                                        </IconContainer>
                                    )}
                                </IconContainerSpacer>
                                <VolumeRangeContainer>
                                    <VolumeRangeSlider
                                        volume={volume}
                                        onChange={onVolumeChange}
                                        onChangeCommitted={onVolumeSeekDown}
                                    />
                                </VolumeRangeContainer>
                            </StyledVolume>
                        </StyledInfoTools>
                        <StyledInfoTools>
                            {onCopyLink && (
                                <IconContainerSpacer>
                                    <IconContainer
                                        onClick={onCopyLink}
                                        title="Copy link"
                                    >
                                        <CopyLink />
                                    </IconContainer>
                                </IconContainerSpacer>
                            )}
                            {onDownloadVideo && (
                                <IconContainerSpacer>
                                    {videoLoading ? (
                                        <IconContainer disabled={true}>
                                            <Loading size={16} />
                                        </IconContainer>
                                    ) : (
                                        <IconContainer
                                            onClick={onDownloadVideo}
                                            title="Download"
                                        >
                                            <DownloadIcon />
                                        </IconContainer>
                                    )}
                                </IconContainerSpacer>
                            )}
                            <IconContainerSpacer>
                                <IconContainer
                                    onClick={onToggleFullScreen}
                                    title="Full screen mode"
                                >
                                    <FullScreenIcon />
                                </IconContainer>
                            </IconContainerSpacer>
                        </StyledInfoTools>
                    </InfoControls>
                </Controls>
            </ControlsWrapper>
        </StyledVideoPlayerControls>
    );
};
