import styled from 'styled-components';

export const STATUS_COLORS: any = {
    ACTIVE: {
        text: 'var(--ps-green-1)',
        background: 'var(--ps-green-2)',
    },
    DELETED: {
        text: 'var(--ps-black-main)',
        background: 'var(--ps-grey-4)',
    },
    PENDING: {
        text: 'var(--ps-orange-1)',
        background: 'var(--ps-orange-2)',
    },
    DRAFT: {
        text: 'var(--ps-blue-1)',
        background: 'var(--ps-white-6)',
    },
    ARCHIVED: {
        text: '#3a3a3a',
        background: '#ebebeb',
    },
};

export const Td = styled.td<{
    width?: number;
    clickable?: boolean;
    flex?: { display: string; justifyContent: string; alignItems: string };
}>`
    height: 55px;
    font-size: 13px;
    font-weight: 400;
    padding: 4px 8px 4px 0px;
    ${props => (props.width ? `width: ${props.width}%` : '')};
    &:hover {
        opacity: ${props => (props.clickable ? 0.7 : 1)};
    }
    ${props =>
        props.clickable &&
        `
        cursor: pointer;
    `}
    &:nth-child(1) {
        padding-left: 16px;
    }
`;

export const Text = styled.div<{
    status?: any;
}>`
    display: inline-block;
    padding: 4px 12px;
    background: ${props =>
        props.status
            ? STATUS_COLORS[props.status.toUpperCase()].background
            : STATUS_COLORS['ARCHIVED'].background};
    color: ${props =>
        props.status
            ? STATUS_COLORS[props.status.toUpperCase()].text
            : STATUS_COLORS['ARCHIVED'].text};
    font-size: 13px;
    border-radius: 100px;
`;