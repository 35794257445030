const ContentContainerId = 'MainScrollableContainer';

export const getPortalElement = (
    triggerElement: HTMLElement | null,
    type: 'tooltip' | 'popup'
): HTMLElement => {
    let portal = document.querySelector('body') as HTMLElement;

    if (type === 'tooltip') {
        return portal;
    }

    const contentContainer = document.getElementById(ContentContainerId);

    if (contentContainer && triggerElement?.closest(`#${ContentContainerId}`)) {
        portal = contentContainer;
    }
    return portal;
};
