import React, { FC } from 'react';
import styled from 'styled-components';

import { Button } from '../../../../../ui/components/Button';

const StyledTutorialContent = styled.div`
    width: 288px;
`;

const StyledTitle = styled.div`
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    font-family: ${props => props.theme.Fonts.manrope};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.black};
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledDescription = styled.div`
    font-size: 13px;
    margin-top: 18px;
    width: 100%;
    font-family: ${props => props.theme.Fonts.manrope};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.Colors.steelGrey};
`;

const StyledActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-self: flex-end;
`;

const StyledButtonContainer = styled(Button)`
    width: 120px;
    height: 40px;
`;

export const TrainingTutorial: FC<{
    onDismiss: () => void;
    teamName?: string;
}> = ({ onDismiss, teamName }) => {
    return (
        <StyledTutorialContent>
            <StyledTitle data-test="training-tutorial-title">
                Keep track of members' training progress
            </StyledTitle>
            <StyledContent>
                <StyledDescription data-test="training-tutorial-description">
                    See the progress of all Practis Sets being worked on by
                    {` ${teamName} `} team and access detailed reports for each
                    Practis Set
                </StyledDescription>
            </StyledContent>
            <StyledActions>
                <StyledButtonContainer>
                    <Button
                        width="120px"
                        height="40px"
                        action={() => onDismiss()}
                        dataTest="got-it-button"
                    >
                        Got it
                    </Button>
                </StyledButtonContainer>
            </StyledActions>
        </StyledTutorialContent>
    );
};
