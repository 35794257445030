export interface InviteTextParams {
    name: string;
    inviter: string;
    companyName: string;
    registrationUrl: string;
}

/**
 * @function buildUserInviteText - builds invite text of regular user
 * @param { InviteTextParams } params
 * @returns { string }
 */
export const buildUserInviteText = (params: InviteTextParams): string => `
Hi ${ params.name }
 
${ params.inviter } at ${ params.companyName } invited you to join the Practis learning platform.
 
Join Now: ${ params.registrationUrl }
 
What is Practis?
Practis is a role-playing app that prepares you for customer interactions by simulating a variety of real-world situations. Level up your communication skills and sales aptitude. We'll see you at the top!
`;
 
/**
 * @function buildAdminInviteText - builds invite text of admin user
 * @param { InviteTextParams } params
 * @returns { string }
 */
export const buildAdminInviteText = (params: InviteTextParams): string => `
Hi ${ params.name }
 
${ params.inviter } at ${ params.companyName } invited you to join Practis as an Administrator. 
 
As an Administrator, you will have full access to the Practis Admin Portal on the web, where learners, content, and training are managed. Note that the learners will only have access to the Practis mobile app, where all the training takes place.
 
Join Now: ${ params.registrationUrl }
 
What is Practis?
Practis combines your company's "best practice" messaging with automated role-play and learning science to help customer-facing teams ramp up quickly, retain what they learn, and more consistently deliver results.
`;