import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { AiLogItemInterface } from '../../../constants/interfaces/AiItem';
import { createSelectorContext } from '../../../tools/redux';

export type AiLogsState = {
    data?: ListResult<AiLogItemInterface>;
    loading: boolean;
    loadingFlag: boolean;
    error: string | null;
};

export const AiLogsStateSelectorContext = createSelectorContext<AiLogsState>();

export function useAiLogsState() {
    return useSelector(useContext(AiLogsStateSelectorContext));
}
