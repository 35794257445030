import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const SendActive = forwardRef<SVGSVGElement, IconProps>(
    ({className, dataTest}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} data-test={dataTest}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="m3.333 2.1 18.438 9.219a.92.92 0 0 1 0 1.645L3.333 22.183c-.733.366-1.543-.336-1.284-1.113l2.67-8.009h7.663a.92.92 0 0 0 0-1.839H4.72L2.05 3.213C1.79 2.436 2.6 1.733 3.333 2.1z" fill="#4AA9E2" />
            </svg>);
    }
);

export default SendActive;
