export const totalTeamMembersCountText = (
    listCount: number,
    unsavedListCount: number
) => {
    const totalCount = listCount + unsavedListCount;

    if (totalCount < 1) {
        return 'No Members';
    } else if (totalCount === 1) {
        return '1 Member';
    } else {
        return `${totalCount} Members`;
    }
};

/**
 * @function handleGenerateTeamLeadersCountText
 * @param { number } leadersCount
 * @returns { string }
 */
export const handleGenerateTeamLeadersCountText = (
    leadersCount: number
): string => {
    if (!leadersCount || leadersCount < 1) {
        return 'No Team Leaders';
    } else if (leadersCount === 1) {
        return '1 Team Leader';
    } else {
        return `${leadersCount} Team Leaders`;
    }
};