import { isEmpty } from 'lodash';

import { validEmailRegex, validEmailTLDs } from './constants';

/**
 * @description to validate an email address and check if it has a valid domain.
 * @function validateEmail
 * @param  { string } email
 * @returns { boolean }
 */
export function validateEmail(email: string) {
    if (isEmpty(email)) {
        return true;
    }

    if (validEmailRegex.test(email)) {
        // Check if the email domain has a valid TLD
        const domain = email.split('@')[1];
        if (isValidDomain(domain)) {
            return true;
        }
    }
    return false;
}

/**
 * @function isValidDomain
 * @param { string } domain
 * @returns { boolean }
 */
export function isValidDomain(domain: string) {
    for (let i = 0; i < validEmailTLDs.length; i++) {
        if (domain.endsWith(validEmailTLDs[i])) {
            return true;
        }
    }
    return false;
}

