import React from 'react';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';

export type MenuInterface = {
    key: string;
    title: string;
    space?: boolean;
    url?: string;
};

const StyledNavigationContainer = styled.div`
    display: flex;
`;

const Spacer = styled.div`
    width: 5px;
    height: 100%;
`;

const Navigation: React.FC<{
    menu: Array<MenuInterface>;
    active?: string;
    setActive?: (key: string) => void;
    dataTest: string;
}> = ({ menu, active, setActive, dataTest }) => {
    let menuItems;
    menuItems = menu.map((menu: MenuInterface) => {
        return (
            <React.Fragment key={menu.key}>
                <NavigationItem
                    title={menu.title}
                    action={() => setActive && setActive(menu.key)}
                    active={active === menu.key}
                    dataTest={`${dataTest}-${menu.key}`}
                />
                {menu.space && <Spacer />}
            </React.Fragment>
        );
    });

    return <StyledNavigationContainer>{menuItems}</StyledNavigationContainer>;
};

export default Navigation;
