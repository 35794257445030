import Calendar from 'react-calendar';
import styled from 'styled-components';

export const StyledCalendar = styled(Calendar)`
    width: 100%;
    .react-calendar__navigation {
        height: 32px;
        margin-bottom: 22px;
        justify-content: center;

        .react-calendar__navigation__label {
            font-size: 20px;
            font-weight: bold;
            background: ${props => props.theme.Colors.white};
            border: none;
            width: 200px;
            flex-grow: 0 !important;
            font-family: 'Manrope';
            pointer-events: none;
        }
        .react-calendar__navigation__arrow {
            background: ${props => props.theme.Colors.white};
            border: none;
            cursor: pointer;

            &[disabled] {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }

    .react-calendar__month-view__weekdays {
        margin-bottom: 12px;

        .react-calendar__month-view__weekdays__weekday {
            background: ${props => props.theme.Colors.white};
            color: ${props => props.theme.Colors.blackThree};
            border: none;
            width: 25px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            font-family: 'Manrope';
            abbr[title] {
                text-decoration: none;
            }
        }
    }

    .react-calendar__month-view__days {
        .react-calendar__month-view__days__day {
            background: ${props => props.theme.Colors.white};
            border: none;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            font-size: 16px;
            font-weight: 500;
            font-family: 'Manrope';
            padding: 0;
            abbr {
                text-decoration: none;
                color: ${props => props.theme.Colors.cloudyBlue};
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            &:disabled {
                background: ${props => props.theme.Colors.lighterGray};
                cursor: default;
                pointer-events: none;
            }
        }
        .react-calendar__tile--active {
            abbr {
                border-radius: 2px;
                background: ${props => props.theme.Colors.softBlue};
                color: ${props => props.theme.Colors.white};
            }
        }
        .react-calendar__tile--range {
            background: ${props => props.theme.Colors.softerBlue};
            abbr {
                border-radius: 2px;
                background: ${props => props.theme.Colors.softerBlue};
                color: ${props => props.theme.Colors.white};
            }
        }
        .react-calendar__tile--rangeStart {
            background: ${props => props.theme.Colors.white};
            abbr {
                border-radius: 2px;
                background: ${props => props.theme.Colors.darkSkyBlue};
                color: ${props => props.theme.Colors.white};
            }
        }
        .react-calendar__tile--rangeEnd {
            background: ${props => props.theme.Colors.white};
            abbr {
                border-radius: 2px;
                background: ${props => props.theme.Colors.darkSkyBlue};
                color: ${props => props.theme.Colors.white};
            }
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            visibility: hidden;
            pointer-events: none;
        }
    }
`;

export const IconHolder = styled.div<{ scaleX?: number }>`
    width: 7px;
    height: 14px;
    color: ${props => props.theme.Colors.blackThree};
    transform: scaleX(${props => props.scaleX || 1});
`;