import {
    PractisSetContent,
    PractisSets,
    PractisSetStatuses,
} from '../../../../constants/interfaces/PractisSets';
import { Scenario } from '../../../../constants/interfaces/Scenario';
import { Challenge } from '../../../../constants/interfaces/Challenge';
import { AppState } from '../../../../store/reducers';
import {
    ACTIONS,
    getPractisChallengesCountSuccess,
    getPractisScenarioCountSuccess,
    getSinglePractisSetFailure,
    getSinglePractisSetStart,
    getSinglePractisSetSuccess,
    modifyPractisSetAction,
    practisContentRepCountDecrease,
    practisContentRepCountIncrease,
    resetPractisSetsAction,
    restorePractisSetFromTempAction,
    searchPractisChallengesFailure,
    searchPractisChallengesStart,
    searchPractisChallengesSuccess,
    searchPractisScenariosFailure,
    searchPractisScenariosStart,
    searchPractisScenariosSuccess,
    storePractisSetTempCopyAction,
    updatePractisContentAction,
} from './actions';
import {
    ACTIONS as LIBRARY_ACTIONS,
    updateLibraryPractisSetSuccess,
} from '../../../../features/library/store/actions';
import {
    calculatePractisSetTotalDuration,
    PractisSetEntityTypes,
} from '../tools';
import {
    withLabelsInitialState,
    withLabelsReducer,
} from '../../../../features/portableLabels/store/hors/withLabels';
import { compose, Reducer } from 'redux';

export interface PractisSetInterface {
    info: PractisSets;
    temp_info?: PractisSets;
    scenarios: Scenario[];
    totalScenarios?: number;
    challenges: Challenge[];
    totalChallenges?: number;
    case: 'init' | 'created' | 'modified' | 'loaded' | 'updated' | 'error';
    isScenariosLoading: boolean;
    isChallengesLoading: boolean;
    isLoading: boolean;
    errors: string;
}

export const initialPractisSetsState: PractisSetInterface = withLabelsInitialState(
    {
        info: {
            name: '',
            description: '',
            scenarioCount: 0,
            challengeCount: 0,
            pacingId: null,
            status: PractisSetStatuses.DRAFT,
            content: [],
            totalDuration: null,
        },
        scenarios: [],
        totalScenarios: undefined,
        challenges: [],
        totalChallenges: undefined,
        isLoading: false,
        isScenariosLoading: false,
        isChallengesLoading: false,
        case: 'init',
        errors: '',
    },
    'practisSet'
);

type PractisSetActions =
    | ReturnType<typeof getSinglePractisSetStart>
    | ReturnType<typeof getSinglePractisSetSuccess>
    | ReturnType<typeof getSinglePractisSetFailure>
    | ReturnType<typeof modifyPractisSetAction>
    | ReturnType<typeof searchPractisScenariosStart>
    | ReturnType<typeof searchPractisScenariosSuccess>
    | ReturnType<typeof searchPractisScenariosFailure>
    | ReturnType<typeof searchPractisChallengesStart>
    | ReturnType<typeof searchPractisChallengesSuccess>
    | ReturnType<typeof searchPractisChallengesFailure>
    | ReturnType<typeof getPractisChallengesCountSuccess>
    | ReturnType<typeof getPractisScenarioCountSuccess>
    | ReturnType<typeof resetPractisSetsAction>
    | ReturnType<typeof updatePractisContentAction>
    | ReturnType<typeof practisContentRepCountIncrease>
    | ReturnType<typeof practisContentRepCountDecrease>
    | ReturnType<typeof updateLibraryPractisSetSuccess>
    | ReturnType<typeof storePractisSetTempCopyAction>
    | ReturnType<typeof restorePractisSetFromTempAction>;

export const practisSetBaseReducer = (
    state = initialPractisSetsState,
    action: PractisSetActions
): PractisSetInterface => {
    switch (action.type) {
        case ACTIONS.GET_SINGLE_PRACTIS_SET_START:
            return {
                ...state,
                isLoading: true,
            };
        case ACTIONS.GET_SINGLE_PRACTIS_SET_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    ...action.data,
                },
                isLoading: false,
                case: 'created',
                errors: '',
            };
        case ACTIONS.GET_SINGLE_PRACTIS_SET_FAILURE:
            return {
                ...state,
                isLoading: false,
                case: 'error',
                errors: action.error,
            };
        case ACTIONS.MODIFY_PRACTIS_SET:
            const newPractisSetsInfo: any = { ...state.info };
            newPractisSetsInfo[action.field] = action.data;
            return {
                ...state,
                info: {
                    ...newPractisSetsInfo,
                },
                case: action.silent ? state.case : 'modified',
            };
        case ACTIONS.SEARCH_PRACTIS_SCENARIOS_START:
            return {
                ...state,
                isScenariosLoading: true,
            }
        case ACTIONS.SEARCH_PRACTIS_SCENARIOS_SUCCESS:
            return {
                ...state,
                scenarios: [
                    ...action.data.map((scenario: Scenario) => {
                        scenario.type = 'SCENARIO';
                        scenario.minRepsCount = 1;
                        return scenario;
                    }),
                ],
                isScenariosLoading: false,
                errors: '',
            };
        case ACTIONS.SEARCH_PRACTIS_SCENARIOS_FAILURE:
            return {
                ...state,
                case: 'error',
                isScenariosLoading: false,
                errors: action.error,
            };
        case ACTIONS.SEARCH_PRACTIS_CHALLENGES_START:
            return {
                ...state,
                isChallengesLoading: true,
            }
        case ACTIONS.SEARCH_PRACTIS_CHALLENGES_SUCCESS:
            return {
                ...state,
                challenges: [
                    ...action.data.map((challenge: Challenge) => {
                        challenge.type = 'CHALLENGE';
                        return challenge;
                    }),
                ],
                isChallengesLoading: false,
                errors: '',
            };
        case ACTIONS.SEARCH_PRACTIS_CHALLENGES_FAILURE:
            return {
                ...state,
                case: 'error',
                isChallengesLoading: false,
                errors: action.error,
            };
        case ACTIONS.GET_PRACTIS_SCENARIOS_COUNT_SUCCESS:
            return {
                ...state,
                errors: '',
                totalScenarios: +action.count,
            };
        case ACTIONS.GET_PRACTIS_CHALLENGES_COUNT_SUCCESS:
            return {
                ...state,
                errors: '',
                totalChallenges: +action.count,
            };
        case ACTIONS.UPDATE_PRACTIS_CONTENT:
            return {
                ...state,
                info: {
                    ...state.info,
                    totalDuration: calculatePractisSetTotalDuration(
                        action.data
                    ),
                    content: [...action.data],
                },
                case: 'modified',
            };
        case ACTIONS.PRACTIS_CONTENT_REP_COUNT_INCREASE: {
            const newContent = state.info.content.map(
                (content: PractisSetContent) => {
                    const isScenario =
                        content.type === PractisSetEntityTypes.SCENARIO;

                    const contentId = isScenario
                        ? content.scenario!.id
                        : content.challenge!.id;    

                    if (contentId === action.itemId && isScenario) {
                        return {
                            ...content,
                            minRepsCount:
                                content.minRepsCount &&
                                content.minRepsCount + 1,
                        };
                    } else {
                        return content;
                    }
                }
            );
            return {
                ...state,
                info: {
                    ...state.info,
                    totalDuration: calculatePractisSetTotalDuration(newContent),
                    content: newContent,
                },
                case: 'modified',
            };
        }
        case ACTIONS.PRACTIS_CONTENT_REP_COUNT_DECREASE: {
            const newContent = state.info.content.map(
                (content: PractisSetContent) => {
                    const isScenario =
                        content.type === PractisSetEntityTypes.SCENARIO;

                    const contentId = isScenario
                        ? content.scenario!.id
                        : content.challenge!.id;
                    if (contentId === action.itemId && isScenario) {
                        return {
                            ...content,
                            minRepsCount: content.minRepsCount
                                ? content.minRepsCount === 1
                                    ? 1
                                    : content.minRepsCount - 1
                                : 1,
                        };
                    } else {
                        return content;
                    }
                }
            );
            return {
                ...state,
                info: {
                    ...state.info,
                    totalDuration: calculatePractisSetTotalDuration(newContent),
                    content: newContent,
                },
                case: 'modified',
            };
        }
        case LIBRARY_ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_SUCCESS:
            return {
                ...state,
                case: 'updated',
            };
        case ACTIONS.STORE_PRACTIS_SET_TEMP_COPY:
            return {
                ...state,
                temp_info: JSON.parse(JSON.stringify(state.info)),
            };
        case ACTIONS.RESTORE_PRACTIS_SET_FROM_TEMP:
            return {
                ...state,
                info: JSON.parse(JSON.stringify(state.temp_info)),
                case: 'init',
            };
        case ACTIONS.RESET_PRACTIS_SETS:
            return {
                ...initialPractisSetsState,
                info: {
                    ...initialPractisSetsState.info,
                    content: [],
                },
            };
        default:
            return state;
    }
};

export const practisSetReducer = compose<
    Reducer<PractisSetInterface, PractisSetActions>
>(withLabelsReducer({ reducerName: 'practisSet' }))(practisSetBaseReducer);

export const getPractisSetState = (state: AppState) => state.practisSet;
