import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    InviteUsersApiParams,
    ResendInvitationApiParams,
    TestInvitationApiParams,
    UserPerformanceApiParams,
} from './types';

/**
 * @function useUserPerformanceApi
 * @returns { UserPerformanceApiParams }
 */
export function useUserPerformanceApi(): UserPerformanceApiParams {
    const http = useHttp();
    return useCallback(
        (userId, isInvitation) => {
            return isInvitation
                ? http.get(`invitations/${userId}/preview`)
                : http.get(`users/${userId}`, undefined, undefined, true);
        },
        [http]
    );
}

/**
 * @function useTestInvitationApi
 * @returns { TestInvitationApiParams }
 */
export function useTestInvitationApi(): TestInvitationApiParams {
    const http = useHttp();
    return useCallback(
        (code: string): any => http.get(`invitations/${code}`),
        [http]
    );
}

/**
 * @function useInviteUsers
 * @returns { InviteUsersApiParams }
 */
export function useInviteUsers(): InviteUsersApiParams {
    const http = useHttp();
    return useCallback(
        users => http.post(`/users/invite`, users, true),
        [http]
    );
}

/**
 * @function useResendInvitationApi
 * @returns { ResendInvitationApiParams }
 */
 export function useResendInvitationApi(): ResendInvitationApiParams {
    const http = useHttp();
    return useCallback(
        invitationIds => http.post('/users/invite/resend', invitationIds, true),
        [http]
    );
}

