import React, { FC } from 'react';
import styled from 'styled-components';
import LinkText from '../../../../ui/components/LinkText/link-text';

const StyledEmptyScriptContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
`;

const ScriptAction: FC<{
    handleAdd: any;
}> = ({ handleAdd }) => {
    return (
        <StyledEmptyScriptContainer>
            <LinkText
                onClick={() => handleAdd('CUSTOMER')}
                dataTest='add-challenge-customer-line'>
                    + Add a customer line
            </LinkText>
        </StyledEmptyScriptContainer>
    );
};

export default ScriptAction;
