import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const EmptyBox = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                height="100%"
                viewBox="0 0 32 32"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="currentColor" fillRule="nonzero">
                        <g>
                            <path
                                d="M101 12.801v17.066c0 1.177-.895 2.133-2 2.133H75c-1.105 0-2-.956-2-2.133V12.8c-1.105 0-2-.956-2-2.134V2.133C71 .955 71.895 0 73 0h28c1.105 0 2 .955 2 2.133v8.534c0 1.179-.896 2.134-2 2.134zM75 28.8c0 .59.448 1.067 1 1.067h22c.552 0 1-.478 1-1.067V13.866c0-.588-.448-1.064-1-1.064h-5v4.265c0 1.179-.895 2.133-2 2.133h-8c-1.105 0-2-.954-2-2.133v-4.265h-5c-.552 0-1 .476-1 1.064V28.8zM91 16v-3.198h-8V16c0 .588.448 1.066 1 1.066h6c.552 0 1-.478 1-1.066zm10-12.8c0-.588-.448-1.067-1-1.067H74c-.552 0-1 .478-1 1.067v6.4c0 .59.448 1.067 1 1.067h26c.552 0 1-.478 1-1.066V3.2z"
                                transform="translate(-424 -1816) translate(353 1816)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
);

export default EmptyBox;
