import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div<{ hasFooter: boolean }>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    height: ${props => props.hasFooter ? 'calc(100% - 65px)' : '100%'};
`;

export const InfoText = styled.div`
  font-family: Manrope;
  font-size: 13px;
  font-weight: normal;
  margin-right: 30px;
`;

export const LinkInfo = styled.a`
font-family: Manrope;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  color: var(--ps-blue-main);
  text-decoration: none;
  margin-right: 34px;
`;

export const useStyles = makeStyles(() => ({
    companyLogoUploader: {
        marginBottom: '60px'
    }
}));


export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--ps-grey-4);
    padding: 23px 5px 0 5px;
    width: 100%;
    height: 64px;
    align-items: center;
    padding: 0 24px 0 0;
    box-sizing: border-box;
`;

export const NextButton = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid var(--ps-blue-main);
    margin-left: 16px;
    cursor: pointer;
`;

export const NextButtonIcon = styled.div`
    width: 9px;
    height: 14px;
    color: var(--ps-blue-main);
    position: relative;
    display: flex;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;