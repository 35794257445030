import { forwardRef } from 'react';
import { IconProps } from './types';

export const Support = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                className={className}
                ref={ref}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 3h12a1 1 0 0 1 .97.753L8.831 7.844a1.5 1.5 0 0 1-1.664 0L1.031 3.753A1 1 0 0 1 2 3zM1 4.934V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4.934L9.387 8.676a2.5 2.5 0 0 1-2.774 0L1 4.934zM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z"
                    fill="#6D7F8C"
                />
            </svg>
        );
    }
);

export default Support;