import { useCallback } from 'react';

type Entity = {
    id: number;
    teamIDs: number[];
    roleId: number;
};

export const useCalculatePreSelectedTeams = <T extends Entity>() => {
    return useCallback((selectedItems: number[], entity: T[]) => {
        let countSelectedItems: Record<number, number> = {};
        let outputArray: number[] = [];

        const filteredEntities = entity.filter(entityItem => {
            return selectedItems.includes(entityItem.id);
        });
        for (let entity of filteredEntities) {
            for (let teamId of entity.teamIDs) {
                countSelectedItems[teamId] =
                    countSelectedItems[teamId] + 1 || 1;
            }
        }

        for (const key in countSelectedItems) {
            if (countSelectedItems[key] === selectedItems.length) {
                outputArray.push(Number(key));
            } else {
                outputArray.push(-Number(key));
            }
        }

        return outputArray;
    }, []);
};

export const useCalculateDeletedTeams = () => {
    return useCallback((assignedTeams, initialTeams) => {
        const deletedTeams: number[] = [];

        for (let team of initialTeams) {
            if (
                !assignedTeams.includes(team) &&
                !assignedTeams.includes(-team)
            ) {
                deletedTeams.push(team < 0 ? -team : team);
            }
        }

        return deletedTeams;
    }, []);
};
