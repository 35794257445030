import styled from 'styled-components';
import {
    SingleTextLine,
    Icon,
} from '../../../ui/components/Skeleton/styles';
import Search from '../../../ui/icons/Search';

export const Container = styled.div`
    width: 100%;
    height: 40px;
    border-bottom: 1px solid var(--ps-grey-4);
    background: var(--ps-grey-3);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.div<{ isDisabled?: boolean }>`
    padding-left: 24px;
    font-size: 13px;
    line-height: 18px;
    color: ${props => props.isDisabled ? 'var(--ps-grey-1)' : 'var(--ps-black-main)'};
    font-weight: 800;
`;

export const ShowSearch = styled.div`
    margin-right: 24px;
    width: 14px;
    height: 100%;
`;

export const SearchIcon = styled(Search)<{ isDisabled?: boolean}>`
    color: var(${props => props.isDisabled ? "--ps-grey-2" : "--ps-grey-1"});
    cursor: ${props => props.isDisabled ? "default" : "pointer"};
`;

export const TitleSkeleton = styled(SingleTextLine)`
    margin-left: 24px;
    background-color: var(--ps-grey-2);
`;

export const IconSkeleton = styled(Icon)`
    margin-right: 16px;
    background-color: var(--ps-grey-2);
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`;

export const SearchButtons = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    width: 80px;
`;

export const ClearButton = styled.div`
    font-size: 11px;
    color: var(--ps-blue-main);
    cursor: pointer;
`;

export const ClearSeparator = styled.div`
    width: 1px;
    height: 17px;
    margin: 0 8px;
    background-color: var(--ps-grey-4);
`

export const SearchClose = styled.div`
    display: flex;
    flex-basis: 12px;
    flex-shrink: 0;
    align-items: center;
    height: 100%;
    padding-right: 20px;
    cursor: pointer;
    box-sizing: content-box;
    color: var(--ps-black-main);
`