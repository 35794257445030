import { forwardRef } from 'react';

import { IconProps } from './types';

export const BrokenVideo = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="73"
                height="72"
                viewBox="0 0 73 72"
                fill="none"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#dopteucapa)" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="m68.66 60.75-7.6-7.6 7.6-7.61-3.54-3.53-7.6 7.6-7.6-7.6-3.53 3.53 7.6 7.61-7.6 7.6 3.53 3.53 7.6-7.6 7.6 7.6 3.54-3.53z" fill="#EC513D"/>
                    <path d="M27.21 42.42V27.15c0-1.12 1.22-1.82 2.18-1.25l13.09 7.64c.96.56.96 1.95 0 2.51l-13.09 7.64c-.97.56-2.18-.13-2.18-1.25v-.02zm34.31-7.63h5.39c-.02-5.83-.42-11.72-1.22-17.32h.03l-.15-.85h-.01v-.03c-.06-.21-.11-.42-.19-.69-.91-3.03-2.96-5.37-5.57-6.28C54.92 8.02 36.04 8 35.24 8c-.8 0-19.63.02-24.57 1.62-1.05.36-2.02.97-2.88 1.79l-.13.14c-.19.19-.38.39-.56.6-.16.19-.31.38-.51.66l-.1.14c-.75 1.09-1.3 2.32-1.61 3.64l-.02.1c-1.8 11.32-1.82 24.66-.05 36.6l.03.22c.03.17.08.33.13.49l.07.23c.91 3.03 2.96 5.37 5.57 6.28 4.87 1.59 23.75 1.61 24.55 1.61.25 0 2.27 0 5.08-.05V56.7c-.75.01-1.44.02-2.02.03-.94.01-1.71.02-2.24.02h-1.64c-.53 0-1.3 0-2.25-.02-1.72-.02-4.35-.07-7.14-.17-3.1-.11-5.76-.26-7.9-.46-2.27-.2-4.06-.46-4.8-.7-.77-.3-1.75-1.27-2.17-3.04-1.63-11.04-1.64-23.45-.04-34.11.04-.2.07-.41.13-.62.2-.78.52-1.45.96-1.99.37-.42.79-.74 1.17-.88.41-.13 1.67-.45 4.63-.72l.44-.04c.21-.02.43-.04.61-.06l.43.09.03-.12c1.94-.15 4.15-.27 6.59-.35 2.79-.1 5.41-.15 7.12-.17.94-.01 1.71-.02 2.24-.02h1.64c.53 0 1.3 0 2.25.02 1.72.02 4.35.07 7.14.17 3.11.11 5.77.27 7.9.46 2.28.2 4.06.46 4.8.7.77.3 1.75 1.27 2.17 3.04.81 5.48 1.2 11.28 1.21 17.03h.02z" fill="#fff"/>
                </g>
                <defs>
                    <clipPath id="dopteucapa">
                        <path fill="#fff" transform="translate(3.5 8)" d="M0 0h65.16v56.28H0z"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
);

export default BrokenVideo;

