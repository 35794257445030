import { countParents } from '../../features/labels/config';

export const listToTree = <
    T extends {
        id: number;
        children?: T[];
        path?: string;
        parentsCount: number;
        parentId: number | null;
    }
>(
    list?: T[]
): T[] => {
    if (!list) {
        return [];
    }

    const localList: T[] = JSON.parse(JSON.stringify(list));
    const map: Record<number, number> = {};
    const roots = [];
    let node: T;

    for (let i = 0; i < localList.length; i += 1) {
        map[localList[i].id] = i;
        localList[i].children = [];
    }
    for (let i = 0; i < localList.length; i += 1) {
        localList[i].parentsCount = countParents(list[i].path || '');
        node = localList[i];

        if (node.parentId && localList[map[node.parentId]]) {
            const children = localList[map[node.parentId]].children;
            if (children) {
                children.push(node);
            }
        } else {
            roots.push(node);
        }
    }

    return roots;
};
