import { Reducer } from 'redux';
import { cloneDeep } from 'lodash';

import { Config } from './types';
import { Actions } from './actions';
import { decapsulateAction } from '../../../../../store/helpers/decapsulateAction';
import { PaginationResult } from '../../../../../constants/interfaces/PaginationResult';
import { AppState } from '../../../../../store/reducers';
import { DEFAULT_TEAMS_SCOPE, Scope, WithTeamsScopes } from './scopes';
import { Team } from '../../../../../constants/interfaces/Team';
import { toggleItemInArray } from '../../../../../helpers/functions/array-helpers';

//region Reducer state
export type StateWithTeams = {
    _teams: Record<Scope, TeamsState>;
};

export type TeamsState = {
    data: PaginationResult<Team>;
    selected: number[];
    saved: number[];
    loading: boolean;
    error: string | null;
};

const initialState: TeamsState = {
    data: {
        items: [],
        limit: 0,
        offset: 0,
        numberOfPages: 0,
        totalCount: 0,
        lastUpdated: undefined,
    },
    selected: [],
    saved: [],
    loading: false,
    error: null,
};

export const withTeamsInitialState = <T>(
    baseInitialState: T,
    reducerName: keyof AppState
): T & StateWithTeams => {
    const scopes =
        WithTeamsScopes[reducerName] === undefined
            ? [DEFAULT_TEAMS_SCOPE]
            : [
                  ...(WithTeamsScopes[reducerName] as string[]),
                  DEFAULT_TEAMS_SCOPE,
              ];

    return {
        ...baseInitialState,
        _teams: scopes.reduce((acc: Record<string, TeamsState>, scope) => {
            acc[scope] = cloneDeep(initialState);
            return acc;
        }, {}),
    };
};
//endregion

//region HOR
export const withTeamsReducer = <S extends StateWithTeams>(config: Config) => (
    baseReducer: Reducer<S>
) => (state: S, nativeAction: Actions): S => {
    let newState = state;
    const { reducerName } = config;
    const action = decapsulateAction(`${reducerName}/teams/`, nativeAction);
    const scope = action._scope;

    if (!scope) {
        return baseReducer(newState, action);
    }

    switch (action.type) {
        case 'clearTeams':
            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: [],
                        partial: [],
                    },
                },
            };
            break;
        case 'saveTeams':
            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        saved: [...state._teams[scope].selected],
                    },
                },
            };
            break;
        case 'selectTeams':
            let updatedSelectedState = [...state._teams[scope].selected];

            updatedSelectedState = toggleItemInArray(
                updatedSelectedState,
                action.id
            );

            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: updatedSelectedState,
                    },
                },
            };
            break;
        case 'selectMultipleTeams': {
            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: action.ids,
                    },
                },
            };
            break;
        }
        case 'selectAllTeams':
            const newSelectedList = action.allTeams;

            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: newSelectedList,
                    },
                },
            };
            break;
        case 'deselectAllTeams':
            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: [],
                    },
                },
            };
            break;
        case 'resetSelectedTeams': {
            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: state._teams[scope].saved,
                    },
                },
            };
            break;
        }
        case 'resetTeams':
            newState = {
                ...newState,
                _teams: {
                    ...state._teams,
                    [scope]: {
                        ...state._teams[scope],
                        selected: [],
                        saved: [],
                        collapsed: [],
                    },
                },
            };
            break;
    }

    return baseReducer(newState, action);
};
//endregion
