import { useEffect } from 'react';
import { useGetReportsService } from './store/services';
import { useReportsState } from './store/states';
import ReportsView from './view';

function ReportsController() {
    const getReports = useGetReportsService();
    const reportsState = useReportsState();

    useEffect(() => {
        getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ReportsView
        isLoading={reportsState.isLoading}
        data={reportsState.data}
    />
}

export default ReportsController;