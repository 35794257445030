import { useCallback, useEffect, useMemo, useState } from 'react';
import { pushModal, useHistory } from '../../../tools/router';
import { TableWrapper } from '../../../ui/components/table-wrapper';
import { useTableStateHelper } from '../../../ui/components/table-wrapper/helper';
import { QuestionTooltip } from '../../../ui/components/Tooltip/QuestionTooltip';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import { QuestionContainer, TooltipContainer, TootlipContent, TootlipTitle } from '../../CompanySettings/components/CompanyVoice/components/PartialVoiceSettings/styles';
import { initialSearchParams, ITEMS_PER_PAGE } from './constants';
import { Container, Footer, NameContainer, NoRoleplayLink, OwnerName, RoleplayCard, SkeletonContainer, Subheader, Text, Title } from './styles';
import {
    SearchParams,
    useSearchParamsState,
} from '../../../constants/interfaces/filters';
import SidebarRoleplaysLarge from '../../../ui/icons/SidebarRoleplaysLarge';
import dayjs from 'dayjs';
import { useGetRoleplaysService } from './store/services';
import { getProfileState } from '../../UserProfile/store/reducers';
import { useSelector } from 'react-redux';
import { useRoleplaysState } from './store/states';
import PlusInCircleIcon from '../../../ui/icons/PlusInCircleIcon';
import { Button } from '../../../ui/components/Button';
import ROUTES from '../../../routes/routes';
import { isPractisAdminRole, isAdminRole } from '../../../constants/enums';
import { ActionButton, ActionItem } from '../../../ui/components/ActionButton';
import { useDeleteRoleplayApi, useDuplicateRoleplayApi } from '../../../api/roleplay';
import { generateCopyOfEntityName } from '../../../services/GeneralBulkActionServices/helpers';
import { useShowConfirmModalDialog } from '../../../ui/components/ModalDialogs/store/actions';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import RoleplaySceleton from '../components/Skeletons/RoleplaySkeleton';
import useGoogleAnalyticsWithRoleplay from '../GoogleAnalytics';
import { debounce } from 'lodash';

const qs = require('query-string');

function RoleplaysController() {
    const getRoleplays = useGetRoleplaysService();
    const duplicateRoleplay = useDuplicateRoleplayApi();
    const deleteRoleplay = useDeleteRoleplayApi();
    const showConfirmationModalDialog = useShowConfirmModalDialog();
    const showMessage = useShowMessage();
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();

    const profile = useSelector(getProfileState);
    const { isLoading, data: roleplays } = useRoleplaysState();
    const [lastRefreshed, setLastRefreshed] = useState(new Date());

    const history = useHistory();
    const location = history.location;
    const pageIndex = parseInt(qs.parse(location.search).page) || 1;

    const initialOffset = (pageIndex - 1) * ITEMS_PER_PAGE;

    const usableParams: SearchParams = initialSearchParams;
    const infoPanel = localStorage.getItem('infoPanel');

    const { searchParams, setSearchTerm, setOffset } =
        useSearchParamsState(usableParams);

    const handleTableStates = useTableStateHelper();
    const tableStates = handleTableStates({
        searchTerm: searchParams?.searchTerm,
        itemsCount: roleplays?.items?.length || 0,
    });

    useEffect(() => {
        profile?.companyId && getRoleplays({ ...searchParams, companyId: profile?.companyId });
        setLastRefreshed(new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.companyId]);

    /**
     * @function handlePageChange
     * @param { number } limit
     * @param { number } offset
     * @returns { void }
     */
    const handlePageChange = useCallback(
        (_, offset: number): void => {
            setOffset(offset);
            profile?.companyId && getRoleplays({ ...searchParams, offset, companyId: profile?.companyId });
        },
        [searchParams, getRoleplays, setOffset, profile?.companyId]
    );

    const isDotMenuVisible = useMemo(() => {
        return isAdminRole(profile?.role?.name) || isPractisAdminRole(profile?.role?.name)
    }, [profile?.role?.name]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roleplaySeachTrackEvent = useCallback(
        debounce(() => trackEventWithRoleplay('roleplays_screen_search'), 500),
        []
    );

    useEffect(() => {
        searchParams.searchTerm && roleplaySeachTrackEvent?.();
    }, [searchParams.searchTerm, roleplaySeachTrackEvent])

    useEffect(() => {
        trackEventWithRoleplay('roleplays_screen_open');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**
     * @function refreshData
     * @returns { void }
     */
    const refreshData = useCallback(() => {
        trackEventWithRoleplay('roleplay_list_refresh')
        searchParams.limit = ITEMS_PER_PAGE;
        searchParams.offset = initialOffset;
        profile?.companyId && getRoleplays({ ...searchParams, companyId: profile?.companyId });
        setLastRefreshed(new Date());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialOffset, searchParams, getRoleplays, profile?.companyId]);

    /**
     * @function handleSearchChange
     * @param { string } searchTerm
     */
    const handleSearchChange = useCallback(
        (searchTerm: string) => {
            setSearchTerm(searchTerm)
            profile?.companyId && getRoleplays({ ...searchParams, searchTerm, companyId: profile?.companyId });
        },
        [searchParams, getRoleplays, setSearchTerm, profile?.companyId]
    );

    const handleCreateRoleplay = () => { pushModal(history, ROUTES.ROLEPLAYS.NEW_ROLEPLAY); trackEventWithRoleplay('roleplays_screen_new') };
    const handleDuplicate = (id: number, title: string) => {
        duplicateRoleplay({
            roleplayId: id.toString(),
            title: generateCopyOfEntityName(title)
        }).then((roleplay) => {
            showMessage('Roleplay has been duplicated', 'success');
            trackEventWithRoleplay('roleplays_screen_duplicate')
            history.push(`${ROUTES.ROLEPLAYS.SINGLE.replace(
                ':roleplayId',
                roleplay.id.toString()
            )}`)
            infoPanel === null && localStorage.setItem(
                'infoPanel',
                'true'
            );
        })
    };

    const handleDeleteRoleplay = useCallback(
        (event: React.MouseEvent<HTMLElement>, roleplayId: number) => {
            event.stopPropagation();
            showConfirmationModalDialog({
                modalTitle: 'Delete Roleplay?',
                description:
                    'It will be permanently deleted from the collection. This action cannot be undone.',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                onConfirm: () => {
                    deleteRoleplay([roleplayId.toString()]).then((data) => {
                        trackEventWithRoleplay('roleplays_screen_delete');
                        showMessage('Roleplay has been deleted', 'success');
                        refreshData();
                    })
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showConfirmationModalDialog, deleteRoleplay, refreshData, showMessage]
    );    

    
    const canCreateRoleplay = profile && (isAdminRole(profile.role?.name) || isPractisAdminRole(profile.role?.name));

    const NoEntryText = <NoRoleplayLink>
        <Text>{canCreateRoleplay ? 'No Roleplays Yet' : 'No Roleplays Yet. Only Admins can create them.'}</Text>
        <Button disabled={!canCreateRoleplay} fontSize={15} style={{ fontWeight: 'bold', cursor: canCreateRoleplay ? 'pointer' : 'default' }} variant="transparent" action={handleCreateRoleplay} type="button" dataTest="roleplay-create-new">Create Roleplay</Button>
    </NoRoleplayLink>;

    return <MainWrapper subTitle="Roleplay AI" htmlPageTitle="Roleplay AI - Practis" contentSize={false} dataTest="roleplays" subHeader={
        <Subheader data-test="roleplay-subheader"><div>Collection of AI-powered sales situations</div>
            <QuestionContainer>
                <QuestionTooltip
                    action={() => trackEventWithRoleplay('roleplays_screen_hint')}
                    text={<TooltipContainer>
                        <TootlipTitle>Discover a variety of sales situations processed by advanced AI</TootlipTitle>
                        <TootlipContent> Each situation realistically simulates interactions between salespeople and customers. These roleplays are designed to enhance your sales skills through practical, interactive examples, incorporating the best sales methodologies.</TootlipContent>
                    </TooltipContainer>}
                    isHtmlContent={true}
                    dataTest='roleplay-tooltip'
                />
            </QuestionContainer>
        </Subheader>
    }>
        <TableWrapper
            tableStates={tableStates}
            data={roleplays?.items}
            tableRefreshConfigurations={{
                lastRefreshed: lastRefreshed,
                refreshData: refreshData,
                dataTest: 'roleplays-timestamp',
                buttons: [
                    {
                        name: 'Create Roleplay',
                        icon: <PlusInCircleIcon />,
                        onPress: handleCreateRoleplay,
                        dataTest: 'create-roleplay-btn',
                        disabled: !canCreateRoleplay
                    },
                ]
            }}
            tableToolsOptions={{
                pagingOptions: {
                    totalCount: roleplays?.count,
                    itemsPerPage: ITEMS_PER_PAGE,
                    onPageChange: handlePageChange,
                    searchOrFiltersApplied: false,
                    dataTest: 'roleplays-paging'
                },
                searchInputOptions: {
                    initialValue: searchParams.searchTerm,
                    onSearchChange: handleSearchChange,
                    dataTest: 'roleplays-search',
                    isSearchInputDisabled:
                        !roleplays?.items.length &&
                        !searchParams.searchTerm.length,
                },
            }}
            tableEmptyStateConfigurations={{
                shouldShowEmptyState:
                    !isLoading && roleplays?.items?.length === 0,
                noEntriesOptions: {
                    icon: SidebarRoleplaysLarge,
                    text: NoEntryText,
                    dataTest: 'no-results',
                },
                noSearchResultsOptions: {
                    entityName: 'Roleplays',
                    dataTest: 'no-roleplays-found',
                },
            }}
            configurations={{
                columns: [],
                rowConfig: {
                    dataTest: 'roleplay-item',
                    cells: []
                },
            }}
        />
        {
            isLoading ?
                <SkeletonContainer>
                    <RoleplaySceleton/>
                </SkeletonContainer> :
                <Container>
                    {!!roleplays?.items?.length && roleplays?.items.map((roleplay) => {
                        return <RoleplayCard data-test="roleplay-card" onClick={(e) => {
                            e.stopPropagation();
                            history.push(ROUTES.ROLEPLAYS.SINGLE.replace(
                                ':roleplayId',
                                roleplay.id.toString()
                            ))
                            trackEventWithRoleplay('roleplay_screen_open_edit');
                        }} isDotMenuVisible={isDotMenuVisible}>
                            <Title data-test="roleplay-title">{roleplay.title}</Title>
                            <Footer>
                                <NameContainer>
                                    <OwnerName data-test="roleplay-owner-name">{roleplay?.owner?.firstName + ' ' + roleplay?.owner?.lastName}</OwnerName> • <span data-test="roleplay-createdAt">{dayjs(roleplay.dateCreated).format('MMM D, YY hh:mm A')}
                                    </span>
                                </NameContainer>
                                <div className='dot-container'>
                                <ActionButton customWidth={200} isHidden={false} dataTest="roleplay-item" customLeft={5}>
                                    <ActionItem dataTest="duplicate-action" onClick={() => handleDuplicate(roleplay.id, roleplay.title)}>
                                        Duplicate
                                    </ActionItem>
                                    <ActionItem dataTest="delete-action" destructive={true} onClick={(e) => handleDeleteRoleplay(e, roleplay.id)}>
                                        Delete
                                    </ActionItem>
                                </ActionButton>
                                </div>
                            </Footer>
                        </RoleplayCard>
                    })}
                </Container>
        }
    </MainWrapper>
}

export default RoleplaysController;