import { FC, useEffect, useRef } from 'react';
import { ClickAwayListener, Grow, MenuList, Popper } from '@material-ui/core';

import Dots from '../../../ui/icons/Dots';
import { LabelListActionsProps } from './types';
import { DotsIconContainer, useStyles } from './styles';

const LabelListActions: FC<LabelListActionsProps> = props => {
    const {
        children,
        isMenuListOpen,
        handleOpen,
        handleClose,
        customWidth,
        marginTop,
        disabled
    } = props;

    const classes = useStyles({ customWidth, marginTop });
    const menuListAnchorRef = useRef<HTMLDivElement>(null);

    // to close the menu while scrolling
    useEffect(() => {
        const labelsScrollableContainer = document.getElementById(
            'labels-scrollable-container'
        );

        labelsScrollableContainer?.addEventListener('scroll', event => {
            handleClose();
        });

        return () => {
            labelsScrollableContainer?.removeEventListener('scroll', event => {
                handleClose();
            });
        };
    }, [handleClose]);

    return (
        <DotsIconContainer
            ref={menuListAnchorRef}
            id={`label-list-action-button`}
            aria-controls={
                isMenuListOpen ? `label-list-action-menu` : undefined
            }
            aria-expanded={isMenuListOpen ? 'true' : undefined}
            aria-haspopup="true"
            disabled={disabled}
            onClick={handleOpen}
        >
            <Dots />
            {isMenuListOpen && (
                <Popper
                    open={isMenuListOpen}
                    anchorEl={menuListAnchorRef.current}
                    className={classes.popper}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={isMenuListOpen}
                                    id={`label-list-action-menu`}
                                    aria-labelledby={`label-list-action-button`}
                                    className={classes.menuListClassName}
                                >
                                    {children}
                                </MenuList>
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            )}
        </DotsIconContainer>
    );
};

export default LabelListActions;
