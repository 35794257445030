import { createContext, useContext } from 'react';

export type HttpMethod = 'get' | 'post' | 'delete' | 'put' | 'patch';

export interface Http {
    request(method: HttpMethod, path: string, data?: any): Promise<any>;
    get(path: string, data?: any, responseType?: 'blob', isV2?: boolean): Promise<any>;
    post(path: string, data?: any, isV2?: boolean): Promise<any>;
    delete(path: string, data?: any, isV2?: boolean): Promise<any>;
    put(path: string, data?: any, isV2?: boolean): Promise<any>;
    patch(path: string, data?: any, isV2?: boolean): Promise<any>;
}

export const HttpContext = createContext<Http>(undefined!);

export function useHttp() {
    return useContext(HttpContext);
}
