import React, { FC, MouseEvent, useContext } from 'react';
import styled from 'styled-components';

import { CommentContext } from './Comment';

//region Styles
const Description = styled.div<{
    reverse?: boolean;
    disabled?: boolean;
}>`
    padding: 16px 32px 12px 24px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const StyledRemoveIcon = styled.div`
    font-size: 11px;
    color: ${props => props.theme.Colors.fadedRed};
    user-select: none;
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;
    font-weight: bold;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.7;
    }
`;
//endregion

export const CommentDescription: FC<{
    reverse?: boolean;
    onDelete?: (e: MouseEvent<HTMLDivElement>) => void;
    hideRemoveIcon?: boolean;
    dataTest?: string;
}> = ({ children, onDelete, hideRemoveIcon, dataTest }) => {
    const params = useContext(CommentContext);

    return (
        <Description reverse={!params.reverse} disabled={params.disabled}>
            {!hideRemoveIcon && (
                <StyledRemoveIcon onClick={onDelete} data-test={dataTest}>Delete</StyledRemoveIcon>
            )}
            {children}
        </Description>
    );
};
