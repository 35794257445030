import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { kebabCase } from 'lodash';

import { TreeDfsNameMapper } from '../../../../../helpers/classes/TreeDfsNameMapper';
import { CheckPermission } from '../../../../../features/permissions';
import { NEW_PERMISSIONS } from '../../../../../constants/enums/permissions';
import Close from '../../../../icons/Close';
import { usePortableLabelsState } from '../../../../../features/portableLabels/store/states';

const Container = styled.div`
    width: 100%;
    background-color: ${props => props.theme.Colors.white};
    display: flex;
    align-items: center;
    padding-left: 43px;
    min-height: 32px;
`;

const FilteredByWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow: auto;
`;

const StyledFilteredByContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    overflow: auto;
    flex-wrap: wrap;
    ::-webkit-scrollbar {
        height: 0;
    }
`;

const StyledFilteredByItem = styled.div`
    border-radius: 4px;
    background-color: ${props => props.theme.Colors.darkSkyBlue15};
    font-size: 11px;
    color: ${props => props.theme.Colors.darkSkyBlue};
    border: 1px solid ${props => props.theme.Colors.darkSkyBlue};
    text-align: center;
    padding: 2px 12px;
    display: flex;
    min-height: 32px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 2px 8px 2px 0;
    align-items: center;
`;

const IconHolderForClose = styled.div<{ scaleX?: number }>`
    width: 8px;
    display: flex;
    cursor: pointer;
    color: ${props => props.theme.Colors.darkSkyBlue};
    margin-left: 24px;
`;

const AssignedLabelList: FC<{
    selectedLabels?: number[];
    onDeleteLabel?(id: number): void;
    hideDeleteButton?: boolean;
    padding?: string;
    deletePermissions?: NEW_PERMISSIONS[];
    colSpan?: number;
    className?: string;
}> = ({
    selectedLabels,
    onDeleteLabel,
    hideDeleteButton,
    deletePermissions,
    className,
}) => {
    const [assignedLabelNames, setAssignedLabelNames] = useState<any>([]);
    const labels = usePortableLabelsState().data;
    useEffect(() => {
        const selectedItems = (selectedLabels || []).map(item =>
            item >= 0 ? item : Math.abs(item)
        );

        if (!!labels && labels.items.length > 0 && selectedItems) {
            const treeDfsNameMapper = new TreeDfsNameMapper(
                labels.items,
                selectedItems
            );
            const names = treeDfsNameMapper.getNames();
            setAssignedLabelNames(names);
        }
    }, [labels, selectedLabels]);

    return (
        <Container className={className} data-test='table-labels-list'>
            <FilteredByWrapper>
                <StyledFilteredByContainer>
                    {assignedLabelNames &&
                        assignedLabelNames.map((item: any, index: number) => (
                            <StyledFilteredByItem key={index} data-test={`table-labels-item-${kebabCase(item.title)}`}>
                                {item.title}
                                {!hideDeleteButton && onDeleteLabel && (
                                    <CheckPermission
                                        permissions={deletePermissions}
                                    >
                                        <IconHolderForClose
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                onDeleteLabel &&
                                                    onDeleteLabel(item.id);
                                            }}
                                        >
                                            <Close />
                                        </IconHolderForClose>
                                    </CheckPermission>
                                )}
                            </StyledFilteredByItem>
                        ))}
                </StyledFilteredByContainer>
            </FilteredByWrapper>
        </Container>
    );
};

export default AssignedLabelList;
