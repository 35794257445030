import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { PaginationResult } from '../../../../constants/interfaces/PaginationResult';
import { DraftUser } from '../../../../constants/interfaces/Draft';
import { StateWithLabels } from '../../../../features/portableLabels/store/hors/withLabels';

export type DraftEditBaseState = {
    data?: PaginationResult<DraftUser>;
    loading: boolean;
    updateData?: Partial<DraftUser>;
    updateType?:
        | 'draft-created'
        | 'draft-updated'
        | 'draft-deleted'
        | 'user-invited';
    loadingUpdate?: boolean;
    error?: string;
};

export type DraftEditState = DraftEditBaseState & StateWithLabels;

export type DraftEditStateSelector = (state: any) => DraftEditState;

export const DraftEditStateSelectorContext = React.createContext<DraftEditStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useDraftEditState(): DraftEditState {
    return useSelector(useContext(DraftEditStateSelectorContext));
}
