import React, { FC, memo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyStateContainer: FC = memo(({ children }) => (
    <Container>{children}</Container>
));
