import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CompanyItem } from './types';
import PractisIcon from '../../../../ui/icons/PractisIcon';

const Container = styled.div`
    height: 100%;
    width: 272px;
    padding: 24px 16px 16px 16px;
`;

const Root = styled.div`
    height: 40px;
    width: 272px;
    color: ${props => props.theme.Colors.dark};
    display: flex;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    border-radius: 4px;
    &:hover {
        background: ${props => props.theme.Colors.whiteTwo};
    }
    &:active {
        opacity: 0.7;
    }
`;

const Logo = styled.div<{ practis: boolean }>`
    width: 40px;
    height: 40px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Divider = styled.div`
    margin: 0 24px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    background: ${props => props.theme.Colors.paleGrey};
`;

const StyledLogo = styled(PractisIcon)`
    height: 24px;
`;

const Title = styled.div`
    color: ${props => props.theme.Colors.dark};
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 600;
`;

export const PractisDropdownItem: React.FC<{
    company: CompanyItem;
    onClick(company: CompanyItem): void;
}> = ({ company, onClick }) => {
    const handleClick = useCallback(() => onClick(company), [company, onClick]);
    return (
        <>
            <Container>
                <Root onClick={handleClick} data-test="practisAdminItemContainer">
                    <Logo practis={!!company.practis} data-test="practisAdminItemLogo">
                        <StyledLogo />
                    </Logo>
                    <Title data-test="practisAdminItemTitle">Practis Admin</Title>
                </Root>
            </Container>
            <Divider />
        </>
    );
};
