import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { Input } from '../../../ui/components/input';
import Checkbox from '../../../ui/components/Checkbox';
import { Label } from '../../../constants/interfaces/Label';
import TriangleDown from '../../../ui/icons/TriangleDown';
import TriangleRight from '../../../ui/icons/TriangleRight';
import { useHandleSelectLabels } from '../../labels/tools';

const StyledLabelListItemContainer = styled.div``;

const ChildLabelsContainer = styled.div<{
    expanded: boolean;
}>`
    display: ${props => (props.expanded ? 'block' : 'none')};
    margin-left: 8px;
`;

const LabelItemActions = styled.div<{ menuOpen?: boolean }>`
    display: flex;
    user-select: none;
    height: 100%;
    width: 40px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const StyledCheckbox = styled(Checkbox)`
    height: 100%;
`;

const LabelNameContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    &:active {
        opacity: 0.7;
    }
`;

const ArrowContainer = styled.div`
    width: 8px;
    margin-right: 4px;
    cursor: pointer;
    color: ${props => props.theme.Colors.steelGrey};
    pointer-events: none;
`;

const CollapseArrow = styled.div`
    height: 8px;
    display: flex;
`;

const LabelListItemInputContainer = styled.div`
    flex: 1;
`;

const LabelListItemInput = styled(Input)<{ ref?: any; hasChildren?: boolean; hint?: string; }>`
    height: 100%;
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
    font-weight: 500;
    padding: 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: ${props => props.hasChildren ? 'pointer' : 'default'};
`;

const StyledLabelListItem = styled.div<{
    selected?: boolean;
    modified?: boolean;
    searched?: boolean;
}>`
    height: 32px;
    color: ${props => props.theme.Colors.steelGrey};
    border-radius: 4px;
    font-size: 13px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    ${LabelListItemInput}, ${ArrowContainer} {
        color: ${props =>
            props.selected || props.modified || props.searched
                ? props.theme.Colors.black
                : 'unset'};
    }
    &:hover ${LabelListItemInput}, :hover ${ArrowContainer} {
        color: ${props => props.theme.Colors.black};
    }
    &:hover {
        background: ${props => props.theme.Colors.whiteTwo};
    }
`;

export const PortableLabelListItem: FC<{
    label: Label;
    allLabels?: Label[];
    openActions: boolean;
    toggleOpenActions: (open: boolean) => void;
    toggleCollapse: (labelId: number) => void;
    labelsCollapseState: number[];
    selectedLabels: number[];
    setLabelFilters?: (labelIds: number[]) => void;
    disableLabels?: boolean;
}> = ({
    label,
    allLabels,
    openActions,
    toggleCollapse,
    labelsCollapseState,
    setLabelFilters,
    selectedLabels,
    disableLabels,
}) => {
    const isLabelSelected = selectedLabels.includes(label.id);
    const isLabelPartiallySelected = selectedLabels.includes(-label.id);
    const isExpanded = labelsCollapseState.includes(label.id);
    const inputRef = useRef<any>(null);

    const hasChildren = (label?: Label) => {
        return label && label.children && label.children.length > 0;
    };

    const handleSelectLabels = useHandleSelectLabels();

    const handleLabelSelection = () => {
        if (setLabelFilters) {
            setLabelFilters(
                handleSelectLabels(label, selectedLabels, allLabels)
            );
        }
    };

    return (
        <>
            <StyledLabelListItemContainer data-test="label-item-container">
                <StyledLabelListItem
                    selected={
                        isLabelSelected ||
                        isLabelPartiallySelected ||
                        openActions
                    }
                    modified={false}
                    searched={false}
                >
                    <LabelNameContainer
                        onClick={e => {
                            e.stopPropagation();
                            toggleCollapse(label.id);
                        }}
                        data-test="label-item-name-container"
                    >
                        <ArrowContainer data-test="label-item-arrow-container">
                            {hasChildren(label) && (
                                <CollapseArrow>
                                    {labelsCollapseState.includes(label.id) ? (
                                        <TriangleDown />
                                    ) : (
                                        <TriangleRight />
                                    )}
                                </CollapseArrow>
                            )}
                        </ArrowContainer>
                        <LabelListItemInputContainer data-test="label-item-input-container">
                            <LabelListItemInput
                                ref={inputRef}
                                height={'auto'}
                                value={label.name}
                                colorTheme="transparent"
                                maxLength={144}
                                readOnly={true}
                                hint={label.name}
                                hasChildren={hasChildren(label)}
                            />
                        </LabelListItemInputContainer>
                    </LabelNameContainer>
                    <LabelItemActions menuOpen={openActions}>
                        <StyledCheckbox
                            checked={
                                isLabelSelected || isLabelPartiallySelected
                            }
                            partial={isLabelPartiallySelected}
                            handleChange={handleLabelSelection}
                            size={12}
                            disabled={disableLabels}
                            border="var(--ps-grey-2)"
                            dataTest="label-item-checkbox"
                        />
                    </LabelItemActions>
                </StyledLabelListItem>
            </StyledLabelListItemContainer>
            {hasChildren(label) && (
                <ChildLabelsContainer
                    expanded={isExpanded}
                >
                    {label.children!.map((childLabel: Label) => (
                        <PortableLabelListItemContainer
                            key={childLabel.id}
                            label={childLabel}
                            allLabels={allLabels}
                            labelsCollapseState={labelsCollapseState}
                            selectedLabels={selectedLabels}
                            siblingIds={
                                label.children &&
                                label.children.map(label => label.id)
                            }
                            disableLabels={disableLabels}
                            toggleCollapse={toggleCollapse}
                            setLabelFilters={setLabelFilters}
                        />
                    ))}
                </ChildLabelsContainer>
            )}
        </>
    );
};

const PortableLabelListItemContainer: FC<{
    label: Label;
    allLabels?: Label[];
    labelsCollapseState: number[];
    selectedLabels: number[];
    siblingIds?: number[];
    disableLabels?: boolean;
    toggleCollapse(labelId: number): void;
    setLabelFilters?: (labelIds: number[]) => void;
}> = ({
    label,
    allLabels,
    labelsCollapseState,
    selectedLabels,
    disableLabels,
    toggleCollapse,
    setLabelFilters,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <PortableLabelListItem
            label={label}
            allLabels={allLabels}
            openActions={open}
            toggleOpenActions={setOpen}
            toggleCollapse={toggleCollapse}
            labelsCollapseState={labelsCollapseState}
            setLabelFilters={setLabelFilters}
            selectedLabels={selectedLabels}
            disableLabels={disableLabels}
        />
    );
};

export default PortableLabelListItemContainer;
