import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const ConfirmCheckMark = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className={className}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                    d="m.98 9.024 4.044 4.04L15.099 2.996"
                    stroke="currentColor"
                    stroke-width="1.3"
                    stroke-linecap="round"
                />
            </svg>
        );
    }
);

export default ConfirmCheckMark;
