import React, { FC } from 'react';
import styled from 'styled-components';

const MenuItem = styled.div<{ selected?: boolean }>`
    min-height: 40px;
    color: ${props => props.theme.Colors.black};
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    padding: 0 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    word-break: break-word;
    background: ${props =>
        props.selected
            ? props.theme.Colors.whiteTwo
            : props.theme.Colors.white};
    &:hover {
        background: ${props => props.theme.Colors.whiteTwo};
    }
    &:active {
        opacity: 0.7;
    }
`;

export const SelectMenuItem: FC<{
    forwardedRef?: any;
    name?: string;
    value: string | number;
    children: string | JSX.Element;
    onClick: any;
    hideAction: () => void;
    selected?: boolean;
    multiSelect?: boolean;
    dataTest?: string;
}> = ({
    children,
    onClick,
    value,
    name,
    hideAction,
    selected,
    forwardedRef,
    multiSelect,
    dataTest
}) => {
    const handleSelectItem = () => {
        onClick({ target: { value: value, name: name } });
        if (!multiSelect) hideAction();
    };

    return (
        <MenuItem
            onClick={handleSelectItem}
            selected={selected}
            ref={forwardedRef}
            className={selected ? 'selected-item' : undefined}
            data-test={dataTest}
        >
            {children}
        </MenuItem>
    );
};
