import { SearchParams } from '../../../../constants/interfaces/filters';

export const initialSearchParams: SearchParams = {
    searchTerm: '',
    orderBy: { field: 'name', asc: true },
    limit: 100000,
    offset: 0,
    totalCount: 0,
    numberOfPages: 0,
    filterByStatus: [],
};

export const PRACTIS_SET_QUERY_PARAM_NAME = 'practisSet';