import { FormikActions } from 'formik';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ROUTES from '../../../routes/routes';
import Storage from '../../../services/Storage';
import { LocationState } from '../../../tools/router';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { useUploadPhotoService } from '../../UserProfile/store/services';
import {
    useResetProfileService,
    useTestInvitationService,
} from '../store/services';
import { ErrorMessages } from '../tools';
import {
    RegisterFormValuesType,
    UserInfoType,
} from './types';
import RegistrationView from './view';
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { Variables } from '../../../theme/variables';
import { useRedirectToPhoneVerification } from '../../../helpers/hooks/useRedirectToPhoneVerification';
import { isTeamLeader } from '../../../constants/enums';
import { useRegistrationApi } from '../../../api';
import { clearAuthData } from '../../../helpers/functions/auth-helpers';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';

const qs = require('query-string');

function RegistrationController() {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const windowDimensions = useWindowDimensions();
    const isMobile = windowDimensions.width < Variables.BreakPoints.small;

    useHtmlPageTitle('Create an account');


    const formRef = useRef(null);
    const handleTestInvitation = useTestInvitationService();
    const handleResetProfile = useResetProfileService();
    const handleFileUpload = useUploadPhotoService();
    const showMessage = useShowMessage();
    const redirectToPhoneVerification = useRedirectToPhoneVerification();
    const register = useRegistrationApi();
    /**
     * @function handleSubmitError
     * @param { ErrorResult } error
     * @returns { void }
     */
    const handleSubmitError = useCallback(
        (error: ErrorResult) => {
            if (error.code === 403) {
                showMessage(ErrorMessages.NO_REGISTER_PERMISSION, 'error');
            } else if (error.code === 404) {
                showMessage(ErrorMessages.INVITATION_NOT_FOUND, 'error');
            } else if (error.code === 400 && error.details) {
                const details = error.details;
                const checkEmail = details.find(
                    (item: any) => item.field === 'email'
                );

                let message = '';
                if (checkEmail) {
                    message = ErrorMessages.WRONG_EMAIL_FORMAT;
                } else {
                    message = details[0].message as string;
                }

                showMessage(message, 'error');
            } else {
                showMessage(error.message, 'error');
            }
        },
        [showMessage]
    );

    /**
     * @function handleUploadProfilePicture
     * @returns { Promise<void> }
     */
    const handleUploadProfilePicture = useCallback(
        async (userId: number) => {
            if (profileImage) {
                handleFileUpload(userId, profileImage);
            }
        },
        [handleFileUpload, profileImage]
    );

    /**
     * @function handleSubmit
     * @param { RegisterFormValuesType } values
     * @param { FormikActions<RegisterFormValuesType> } formikActions
     */
    const handleSubmit = useCallback(
        (
            values: RegisterFormValuesType,
            formikActions: FormikActions<RegisterFormValuesType>
        ) => {
            const { setSubmitting } = formikActions;

            const registerValues = {
                firstName: userInfo?.firstName || '',
                lastName: userInfo?.lastName || '',
                email: userInfo?.email || '',
                password: values.password,
                code: qs.parse(location.search).token ?? '',
                phoneNumber: null,
            };

            register(registerValues)
                .then((response: any) => {
                    Storage.set('temp_token', response.token);
                    handleUploadProfilePicture(response.user.id);
                    const isTeamLead = isTeamLeader(response.user.role.name);
                    const user = Object.assign({ isTeamLead }, response.user);
                    redirectToPhoneVerification({
                        user,
                        isNewUser: true,
                    });
                })
                .catch((error: ErrorResult) => {
                    setSubmitting(false);
                    handleSubmitError(error);
                });
        },
        [
            handleUploadProfilePicture,
            location,
            userInfo,
            handleSubmitError,
            redirectToPhoneVerification,
            register,
        ]
    );

    /**
     * @function handleToggleTerms
     * @returns { void }
     */
    const handleToggleTerms = useCallback(() => {
        setIsTermsAccepted(prev => !prev);
    }, []);

    /**
     * @function handleRedirectToInvalidInvitationPage
     * @returns { void }
     */
    const handleRedirectToInvalidInvitationPage = useCallback(() => {
        history.push(ROUTES.AUTH_PAGES.INVALID_INVITE);
    }, [history]);

    useEffect(() => {
        const invitationToken = qs.parse(location.search).token ?? null;

        if (!invitationToken) {
            handleRedirectToInvalidInvitationPage();
        }

        clearAuthData();

        handleTestInvitation(
            invitationToken,
            handleRedirectToInvalidInvitationPage
        ).then(response => {
            if (response) {
                setUserInfo(response);
            }
        });
    }, [
        handleRedirectToInvalidInvitationPage,
        handleTestInvitation,
        location.search,
    ]);

    useEffect(() => {
        return () => {
            handleResetProfile();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RegistrationView
            formRef={formRef}
            isMobile={isMobile}
            userInfo={userInfo}
            setProfileImage={setProfileImage}
            handleSubmit={handleSubmit}
            isTermsAccepted={isTermsAccepted}
            handleToggleTerms={handleToggleTerms}
        />
    );
}

export default memo(RegistrationController);