import { useDispatch } from 'react-redux'
import { useCallback } from 'react';
import { useGetReportsApi } from '../../../api/reports';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ListResult } from '../../../constants/interfaces/PaginationResult';
import { ReportItem } from '../../../constants/interfaces/ReportItem';
import {
    getReportsStart,
    getReportsSuccess,
    getReportsFailure,
} from './actions';
import { ErrorResult } from '../../../constants/interfaces/ErrorResult';

/**
 * @function useGetReportsService
 * @returns { CallableFunction }
 */
export const useGetReportsService = () => {
    const dispatch = useDispatch();
    const getReportsApi = useGetReportsApi();
    const showMessage = useShowMessage();

    /**
     * @function callbackFunction
     * @returns { Promise }
     */
    return useCallback(() => {
        dispatch(getReportsStart());
        return getReportsApi()
            .then((data: ListResult<ReportItem>) => {
                dispatch(getReportsSuccess(data));
            })
            .catch((error: ErrorResult) => {
                dispatch(getReportsFailure(error.message));
                showMessage(error.message, 'error');
            });
    }, [dispatch, getReportsApi, showMessage]);
}