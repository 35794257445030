import {
    PractisSetContent,
    PractisSetStatuses,
} from '../../../constants/interfaces/PractisSets';
import { Variables } from '../../../theme/variables';

export enum PractisSetEntityTypes {
    CHALLENGE = 'CHALLENGE',
    SCENARIO = 'SCENARIO',
}

const PractisSetEntityTypeNames = {
    [PractisSetEntityTypes.CHALLENGE as string]: 'Challenge',
    [PractisSetEntityTypes.SCENARIO as string]: 'Scenario',
};

export function practisSetEntityTypeName(type?: string): string {
    return (type && PractisSetEntityTypeNames[type]) || type || '';
}

const practisSetEntityTypeColors = {
    [PractisSetEntityTypes.CHALLENGE as string]: Variables.Colors.coral,
    [PractisSetEntityTypes.SCENARIO as string]: Variables.Colors.softBlue,
};

export function practisSetEntityTypeColor(type?: string): string {
    return (
        (type && practisSetEntityTypeColors[type]) || Variables.Colors.softBlue
    );
}

const PractisSetStatusNames = {
    [PractisSetStatuses.ACTIVE as string]: 'Active',
    [PractisSetStatuses.ARCHIVED as string]: 'Archived',
    [PractisSetStatuses.DRAFT as string]: 'Draft',
    [PractisSetStatuses.DELETED as string]: 'Deleted',
};

export function PractisSetStatusName(status?: string): string {
    return (status && PractisSetStatusNames[status]) || status || '';
}

export function calculatePractisSetTotalDuration(data: any) {
    const newDurations = data
        .filter(
            (content: PractisSetContent) =>
                content.type === PractisSetEntityTypes.SCENARIO &&
                content.scenario?.script?.totalDuration
        )
        .map((content: PractisSetContent) => {
            if (content?.scenario?.script?.totalDuration && content.minRepsCount) {
                return content.scenario.script.totalDuration* content.minRepsCount;
            } else {
                return content.scenario?.script?.totalDuration;
            }
        });

    return newDurations.length > 0
        ? newDurations.reduce((a: number, b: number) => a! + b!)
        : 0;
}