import { Roleplay } from "../../../../constants/interfaces/Roleplay";

export enum ACTIONS {
    GET_ROLEPLAY_START = 'GET_ROLEPLAY_START',
    GET_ROLEPLAY_SUCCESS = 'GET_ROLEPLAY_SUCCESS',
    GET_ROLEPLAY_FAILURE = 'GET_ROLEPLAY_FAILURE',
    UPDATE_ROLEPLAY_START = 'UPDATE_ROLEPLAY_START',
    UPDATE_ROLEPLAY_SUCCESS = 'UPDATE_ROLEPLAY_SUCCESS',
    UPDATE_ROLEPLAY_FAILURE = 'UPDATE_ROLEPLAY_FAILURE',
    CLEAR_ROLEPLAY = 'CLEAR_ROLEPLAY'
}

export function getRoleplayStart() {
    return {
        type: ACTIONS.GET_ROLEPLAY_START as ACTIONS.GET_ROLEPLAY_START,
    };
}

export function getRoleplaySuccess(data: Roleplay) {
    return {
        type: ACTIONS.GET_ROLEPLAY_SUCCESS as ACTIONS.GET_ROLEPLAY_SUCCESS,
        data
    };
}

export function getRoleplayFailure(error: string) {
    return {
        type: ACTIONS.GET_ROLEPLAY_FAILURE as ACTIONS.GET_ROLEPLAY_FAILURE,
        error
    };
}

export function updateRoleplayStart() {
    return {
        type: ACTIONS.UPDATE_ROLEPLAY_START as ACTIONS.UPDATE_ROLEPLAY_START,
    };
}

export function updateRoleplaySuccess(data: Roleplay) {
    return {
        type: ACTIONS.UPDATE_ROLEPLAY_SUCCESS as ACTIONS.UPDATE_ROLEPLAY_SUCCESS,
        data
    };
}

export function updateRoleplayFailure(error: string) {
    return {
        type: ACTIONS.UPDATE_ROLEPLAY_FAILURE as ACTIONS.UPDATE_ROLEPLAY_FAILURE,
        error
    };
}

export function clearRoleplay() {
    return {
        type: ACTIONS.CLEAR_ROLEPLAY as ACTIONS.CLEAR_ROLEPLAY,
    };
}