import { PaginationResult, ListResult } from '../../../constants/interfaces/PaginationResult';
import { UserInterface, UserV2 } from '../../../constants/interfaces/User';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { PerformanceTrainee } from '../../../constants/interfaces/PerformanceTrainee';
import { PractisSets, EnrollmentPractisSet } from '../../../constants/interfaces/PractisSets';
import { Draft, DraftUser } from '../../../constants/interfaces/Draft';
import { StateWithLabels } from '../../portableLabels/store/hors/withLabels';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { selectItemsByIds } from '../../../tools/redux';
import { UserFeatures } from '../../../constants/interfaces/UserFeatures';
import { StateWithPractisSets } from '../../portablePractisSets/store/hors/withPractisSets';
import { EnrollmentInterface } from '../../../constants/interfaces/Enrollments';
import { UserPreference } from '../../../constants/interfaces/UserPreference';


export function useLoggedInUserProfileState() {
    return useSelector(getProfileState);
}

export interface UsersState {
    list: ListResult<UserV2>;
    selectedUsers?: number[];
    loading?: boolean;
    error?: string;
}

export type UsersStateSelector = (state: any) => UsersState;

export const UsersStateSelectorContext = React.createContext<UsersStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUsersState(): UsersState {
    return useSelector(useContext(UsersStateSelectorContext));
}

export interface UpdateUsersState {
    data?: UserInterface;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdateUsersStateStateSelector = (state: any) => UpdateUsersState;

export const UpdateUsersStateStateSelectorContext = React.createContext<UpdateUsersStateStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdateUsersState(): UpdateUsersState {
    return useSelector(useContext(UpdateUsersStateStateSelectorContext));
}

export interface InvitationsState {
    data?: ListResult<UserV2>;
    selectedInvitations?: number[];
    loading?: boolean;
    error?: string;
}

export type InvitationsStateSelector = (state: any) => InvitationsState;

export const InvitationsStateSelectorContext = React.createContext<InvitationsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function selectSelectedInvitations(state: InvitationsState): UserV2[] {
    return selectItemsByIds<UserV2>(state?.selectedInvitations, state?.data?.items);
}

export function useInvitationsState(): InvitationsState {
    return useSelector(useContext(InvitationsStateSelectorContext));
}

export interface UpdateInvitationState {
    data?: { id: number };
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdateInvitationStateStateSelector = (
    state: any
) => UpdateInvitationState;

export const UpdateInvitationStateStateSelectorContext = React.createContext<UpdateInvitationStateStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdateInvitationsState(): UpdateInvitationState {
    return useSelector(useContext(UpdateInvitationStateStateSelectorContext));
}

export interface DraftsState {
    data?: PaginationResult<Draft>;
    draftedUsers?: PaginationResult<DraftUser>;
    selectedDrafts?: number[];
    createdByUsers?: DraftUser[];
    editedByUsers?: DraftUser[];
    loading?: boolean;
    loadingCreatedBy: boolean,
    loadingEditedBy: boolean,
    error?: string;
}

export type DraftsStateSelector = (state: any) => DraftsState;

export const DraftsStateSelectorContext = React.createContext<DraftsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useDraftsState(): DraftsState {
    return useSelector(useContext(DraftsStateSelectorContext));
}

export interface UpdatedDraftsState {
    data?: Draft;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdateDraftsStateStateSelector = (state: any) => UpdatedDraftsState;

export const UpdateDraftsStateStateSelectorContext = React.createContext<UpdateDraftsStateStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdatedDraftsState(): UpdatedDraftsState {
    return useSelector(useContext(UpdateDraftsStateStateSelectorContext));
}

export type PerformanceState = {
    data?: PerformanceTrainee;
    practisSets?: ListResult<EnrollmentInterface>;
    selectedPractisSets?: number[];
    loading?: boolean;
    practisSetsLoading?: boolean;
    error?: string;
    portablePractisSets?: EnrollmentPractisSet[];
    portablePractisSetsLoading?: boolean;
};

export type UserPerformanceState = PerformanceState & StateWithLabels & StateWithPractisSets;

export type UserPerformanceStateSelector = (state: any) => UserPerformanceState;

export const UserPerformanceStateSelectorContext = React.createContext<UserPerformanceStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUserPerformanceState(): UserPerformanceState {
    return useSelector(useContext(UserPerformanceStateSelectorContext));
}

export interface UpdatedUserPractisSetsState {
    data?: PractisSets;
    updateType?: 'create' | 'update' | 'delete' | 'labels';
    loading?: boolean;
}

export type UpdatedUserPractisSetsStateSelector = (
    state: any
) => UpdatedUserPractisSetsState;

export const UpdatedUserPractisSetsStateSelectorContext = React.createContext<UpdatedUserPractisSetsStateSelector>(
    () => {
        throw new Error('Not implemented');
    }
);

export function useUpdatedUserPractisSetsState(): UpdatedUserPractisSetsState {
    return useSelector(useContext(UpdatedUserPractisSetsStateSelectorContext));
}

export interface UserFeaturesState {
    data?: UserFeatures;
    loading?: boolean;
    error?: string;
}

export interface UserPreferencesState {
    data?: UserPreference[];
    pendingValues?: { key: string, value: unknown }[];
    loading: boolean;
    error?: string;
}