import React, { FC } from 'react';
import styled from 'styled-components';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import NoSearchResult from '../../../ui/icons/NoSearchResult';

const Container = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
`;

const SmallContainer = styled.div`
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledEmptyContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledEmptyContentSmall = styled(StyledEmptyContent)`
    flex-direction: row;
    align-items: center;
    margin: 8px 0;
`;

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-bottom: 8px;
`;

const NoLabelsTitle = styled.div`
    font-family: ${props => props.theme.Fonts.manrope};
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    text-align: center;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const StyledEmptyLabelsContent = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export type NoResultType = 'default' | 'min';

const NoResults: FC<{ loading?: boolean; version: NoResultType }> = ({
    loading,
    version,
}) => {
    if (version === 'min') {
        return (
            <SmallContainer>
                {loading ? (
                    <Loading />
                ) : (
                    <StyledEmptyContentSmall>
                        <StyledEmptyLabelsContent>
                            <IconWrapper data-test="labels-searchbox-empty-result-icon">
                                <NoSearchResult />
                            </IconWrapper>
                            <NoLabelsTitle data-test="labels-searchbox-empty-result-label">No Labels found</NoLabelsTitle>
                        </StyledEmptyLabelsContent>
                    </StyledEmptyContentSmall>
                )}
            </SmallContainer>
        );
    }

    return (
        <Container>
            {loading ? (
                <Loading />
            ) : (
                <StyledEmptyContent>
                    <IconWrapper data-test="labels-searchbox-empty-result-icon">
                        <NoSearchResult />
                    </IconWrapper>
                    <NoLabelsTitle data-test="labels-searchbox-empty-result-label">No Labels found</NoLabelsTitle>
                </StyledEmptyContent>
            )}
        </Container>
    );
};

export default NoResults;
