import { useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';

import useQueryParamListener from '../../../../helpers/hooks/useQueryParamListener';
import { useSetQueryParam } from '../../../../helpers/hooks/useSetQueryParam';
import { useSearchTrainingPractisSets } from '../../../teams/store/services';
import { useSingleTeamFilterState } from '../single-team-filter/store/reducers';
import { initialSearchParams, PRACTIS_SET_QUERY_PARAM_NAME } from './constants';
import {
    useClearSelectedPractisSet,
    useSelectPractisSet,
} from './store/services';
import PractisSetFilterView from './view';
import { useTrainerPractisSetState } from '../../../teams/store/states';
import { usePractisSetFilterState } from './store/reducers';
import { usePreviousData } from '../../../../helpers/hooks/usePreviousData';
import { useSetFilterHeaderSearchActive } from '../../searchable-filter-header/store/services';
import { useFilterHeaderState } from '../../searchable-filter-header/store/reducers';
import { SearchableFilterHeaderKey } from '../../searchable-filter-header/store/types';

function PractisSetFilterController() {
    const { selectedTeamId } = useSingleTeamFilterState();
    const { data, loading } = useTrainerPractisSetState();
    const prevSelectedTeamId = usePreviousData(selectedTeamId);

    const { selectedPractisSetId, isDisabled } = usePractisSetFilterState();
    const { searchTerm } = useFilterHeaderState()[SearchableFilterHeaderKey.PRACTIS_SET] ?? {}
    const setSearchActive = useSetFilterHeaderSearchActive(SearchableFilterHeaderKey.PRACTIS_SET);
    const clearSelectedPractisSet = useClearSelectedPractisSet();
    const onSelectPractisSet = useSelectPractisSet();
    const setQueryParam = useSetQueryParam();

    const isNoSearchResultState = useMemo(
        () => isEmpty(data?.items) && !!searchTerm && searchTerm.length > 0,
        [data?.items, searchTerm]
    );

    const setSelectedPractisSetInStore = useCallback((practisSetId?: number | null) => {
        if (practisSetId) {
            onSelectPractisSet(practisSetId);
        } else {
            clearSelectedPractisSet();
        }
    }, [clearSelectedPractisSet, onSelectPractisSet]);

    const setSelectedPractisSet = useCallback((practisSetId?: number) => {
        setQueryParam(PRACTIS_SET_QUERY_PARAM_NAME, practisSetId?.toString() ?? '');
        setSelectedPractisSetInStore(practisSetId);
    }, [setQueryParam, setSelectedPractisSetInStore]);

    useQueryParamListener(PRACTIS_SET_QUERY_PARAM_NAME, practisSetId => {
        const id = practisSetId ? +practisSetId : null;
        const isEqual = (!selectedPractisSetId && !id) || selectedPractisSetId === id;
        if (!isEqual) {
            setSelectedPractisSetInStore(id);
        }
    });

    const searchTrainingPractisSets = useSearchTrainingPractisSets(
        (selectedTeamId || 0).toString()
    );

    const searchByTeamId = useMemo(
        () => selectedTeamId ? searchTrainingPractisSets : null,
        [searchTrainingPractisSets, selectedTeamId]
    );

    const handleSearch = useCallback((searchTerm) => {
        searchByTeamId?.({
            ...initialSearchParams,
            searchTerm,
        });
    }, [searchByTeamId]);

    useEffect(() => {
        if (selectedTeamId) {
            // do not clear selected practis set on page load (prevSelectedTeamId is empty)
            // as initially it's set from query parameter
            if (prevSelectedTeamId) {
                setSelectedPractisSet(undefined);
                setSearchActive(false);
            }
            searchByTeamId?.(initialSearchParams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeamId]);

    return (
        <PractisSetFilterView
            practisSetData={data}
            loading={loading}
            isNoSearchResultState={isNoSearchResultState}
            selectedTeamId={selectedTeamId}
            selectedPractisSetId={selectedPractisSetId}
            isDisabled={isDisabled}
            onPractisSetSearch={handleSearch}
            handleSelectPractisSet={setSelectedPractisSet}
        />
    );
}

export default PractisSetFilterController;