import React, { memo } from 'react';
import styled from 'styled-components';

import Check from '../../icons/Check';
import Error from '../../icons/Error';
import { Variables } from '../../../theme/variables';
import { ErrorMessagesInterface } from '../ErrorMessages/ErrorMessages';

export const variantIcon = {
    success: Check,
    error: Error,
};

const styleProperties = {
    success: {
        backgroundColor: Variables.Colors.topaz,
    },
    error: {
        backgroundColor: Variables.Colors.tomato,
    },
};

export interface Props {
    className?: string;
    message: ErrorMessagesInterface['message'];
    handleClose?: () => void;
    open: boolean;
    variant: keyof typeof variantIcon;
}

const SnackbarContainer = styled.div<{
    backgroundColor: string;
    open?: boolean;
}>`
    box-sizing: border-box;
    min-width: 280px;
    max-width: 550px;
    min-height: 64px;
    background-color: ${props => props.backgroundColor};
    position: absolute;
    left: 24px;
    bottom: 24px;
    display: flex;
    align-items: center;
    visibility: ${props => (props.open ? 'visible' : 'hidden')};
    transition: visibility 1s linear;
    z-index: 99999;
    padding: 20px 24px;
    border-radius: 4px;

    @media(max-width:600px) {
        max-width: calc(100vw - 48px);
        height: auto;
        min-height: 64px;
        padding: 3px 18px;
    }
`;

const IconWrapper = styled.div`
    width: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
`;

const StyledIcon = styled.div`
    height: 16px;
    width: 16px;
    color: ${props => props.theme.Colors.white};
    flex: 1;
`;

const StyledText = styled.div``;

const StyledMessageContainer = styled.div`
    font-size: 15px;
    color: ${props => props.theme.Colors.white};
`;

export const InfoSnackbar = memo((props: Props) => {
    const { message, variant, open } = props;
    const Icon = variantIcon[variant];

    let content;

    if (typeof message === 'string') {
        content = message;
    } else if (Array.isArray(message)) {
        content = message.map((text, index) => (
            <StyledText key={index}>{text}</StyledText>
        ));
    } else {
        content = 'unknown message';
    }

    return (
        <SnackbarContainer
            backgroundColor={styleProperties[variant].backgroundColor}
            open={open}
            data-test="message-box"
        >
            <IconWrapper>
                <StyledIcon>
                    <Icon />
                </StyledIcon>
            </IconWrapper>
            <StyledMessageContainer>{content}</StyledMessageContainer>
        </SnackbarContainer>
    );
});
