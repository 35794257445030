import { ListResult } from "../../../../constants/interfaces/PaginationResult";
import { Roleplay } from "../../../../constants/interfaces/Roleplay";

export enum ACTIONS {
    GET_ROLEPLAYS_START = 'GET_ROLEPLAYS_START',
    GET_ROLEPLAYS_SUCCESS = 'GET_ROLEPLAYS_SUCCESS',
    GET_ROLEPLAYS_FAILURE = 'GET_ROLEPLAYS_FAILURE',
}

export function getRoleplaysStart() {
    return {
        type: ACTIONS.GET_ROLEPLAYS_START as ACTIONS.GET_ROLEPLAYS_START,
    };
}

export function getRoleplaysSuccess(data: ListResult<Roleplay>) {
    return {
        type: ACTIONS.GET_ROLEPLAYS_SUCCESS as ACTIONS.GET_ROLEPLAYS_SUCCESS,
        data
    };
}

export function getRoleplaysFailure(error: string) {
    return {
        type: ACTIONS.GET_ROLEPLAYS_FAILURE as ACTIONS.GET_ROLEPLAYS_FAILURE,
        error
    };
}