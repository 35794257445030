import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { CircularProgress } from '@material-ui/core';
import CheckMark from '../../icons/CheckMark';

const Blacker = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1200;
    background: ${props => rgba(props.theme.Colors.blackTwo, 0.5)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`

.MuiCircularProgress-determinate{
    position: absolute;
    color:  ${props => props.theme.Colors.mainBlue} !important;
}

.completed{
    color: ${props => props.theme.Colors.topaz} !important;

    .check{
        transform: scale(1.8);
        
        path{
            fill: ${props => props.theme.Colors.topaz};
        }
    }

    .MuiCircularProgress-determinate{
        color:  ${props => props.theme.Colors.topaz} !important;
    }
}

`;

const FilledCircle = styled.div`
    width: 78px;
    height: 78px;
    border-radius: 50%;
    border: 2px solid #eef0f0;
    position: absolute;
`

const Content = styled.div`
    background-color: #fff;
    width: 120px;
    height: 120px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 8px;
`;

export const ProgressModal = ({ open, progressValue, onClose }: any) => {
    // const [open, setOpen] = useState(false);

    return (
        <Wrapper>
            {open &&
                <Blacker>
                    <Content className={progressValue < 100 ? '' : 'completed'}>
                        <FilledCircle />
                        <CircularProgress thickness={2} size={`80px`} variant={'determinate'} value={progressValue} />{progressValue < 100 ? `${progressValue}%` : <CheckMark className='check' /> }
                    </Content>
                </Blacker>
            }
        </Wrapper>
    );
};
