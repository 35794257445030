import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { APP_CONFIG } from './app.config';
import {
    contextItem,
    MultiContextProvider,
    MultiContextProviderItem,
} from './ui/components/MultiContextProvider';
import { configServices } from './config';
import { createContexts } from './config/context';
import { configStorage } from './config/storage';
import * as serviceWorker from './serviceWorker';
import rootReducer from './store/reducers';
import { GlobalStyle } from './theme/GlobalStyle';
import { Variables } from './theme/variables';
import { WEB_SITE_ID } from './tools/constants/WebSiteId';
import { HttpContext } from './tools/http/Http';
import PortableLoaderWrapper from './ui/wrapper/PortableLoaderWrapper/PortableLoaderWrapper';

configStorage();
const {
    store,
    webSiteId,
    urlBuilder,
    http,
    localStorage,
    newRelicApi,
} = configServices(WEB_SITE_ID.COMPANY_PORTAL, APP_CONFIG, rootReducer);
const rootElement = document.getElementById('root');
const contextItems: MultiContextProviderItem[] = createContexts(
    webSiteId,
    urlBuilder,
    localStorage,
    newRelicApi
).map(c => contextItem(c.context, c.value));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <GlobalStyle />
            <ThemeProvider theme={Variables}>
                <MultiContextProvider items={contextItems}>
                    <HttpContext.Provider value={http}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <PortableLoaderWrapper />
                                <App />
                            </MuiPickersUtilsProvider>
                    </HttpContext.Provider>
                </MultiContextProvider>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
