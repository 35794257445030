import { ListResult } from "../../../../../constants/interfaces/PaginationResult";
import { UserV2 } from "../../../../../constants/interfaces/User";

export enum ACTIONS {
    GET_OVERDUE_LEARNERS_START = 'GET_OVERDUE_LEARNERS_START',
    GET_OVERDUE_LEARNERS_SUCCESS = 'GET_OVERDUE_LEARNERS_SUCCESS',
    GET_OVERDUE_LEARNERS_FAILURE = 'GET_OVERDUE_LEARNERS_FAILURE',
}

export function getOverdueLearnersStart() {
    return {
        type: ACTIONS.GET_OVERDUE_LEARNERS_START as ACTIONS.GET_OVERDUE_LEARNERS_START,
    };
}

export function getOverdueLearnersSuccess(data: ListResult<UserV2>) {
    return {
        type: ACTIONS.GET_OVERDUE_LEARNERS_SUCCESS as ACTIONS.GET_OVERDUE_LEARNERS_SUCCESS,
        data
    };
}

export function getOverdueLearnersFailure(error: string) {
    return {
        type: ACTIONS.GET_OVERDUE_LEARNERS_FAILURE as ACTIONS.GET_OVERDUE_LEARNERS_FAILURE,
        error
    };
}