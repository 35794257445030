import { FiltersSidebar } from '../../../features/filters/filters-sidebar';
import ROUTES from '../../../routes/routes';
import MainWrapper from '../../../ui/wrapper/MainWrapper/MainWrapper';
import { TrainingTimeReportFiltersConfig } from './constants';
import { SubHeader } from './styles';
import { TrainingTimeReportViewProps } from './types';

function TrainingTimeReportView({
    onClearFilters, onGenerateReport, controlPanelMode, remainingTimeout
}: TrainingTimeReportViewProps) {
    return (
        <MainWrapper
            title="Reports"
            subTitle="Training Time Report"
            subHeader={
                <SubHeader data-test="training-time-report-page-description">
                    Tracking overall training time by learners by date range.
                </SubHeader>
            }
            htmlPageTitle="Reports - Practis"
            goBackUrl={ROUTES.REPORTS.LIST_OF_REPORTS}
            headerPadding="0 24px"
            headerMinHeight="124px"
            contentPadding="0"
            isScrollable={false}
            contentSize={false}
            dataTest="training-time-report"
        >
            <FiltersSidebar
                filtersConfig={TrainingTimeReportFiltersConfig}
                onClearFilters={onClearFilters}
                onGenerateReport={onGenerateReport}
                controlPanelMode={controlPanelMode}
                remainingTimeout={remainingTimeout}
                headerHeight="124px"
            />
        </MainWrapper>
    );
}

export default TrainingTimeReportView;