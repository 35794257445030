import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSetFilterHeaderSearchActive } from '../../../searchable-filter-header/store/services';
import { SearchableFilterHeaderKey } from '../../../searchable-filter-header/store/types';

import { disablePractisSetFilter, selectPractisSet } from './actions';

export const useSelectPractisSet = () => {
    const dispatch = useDispatch();

    return useCallback(
        (selectedPractisSetId: number) => {
            dispatch(selectPractisSet(selectedPractisSetId));
        },
        [dispatch]
    );
};

export const useClearSelectedPractisSet = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(selectPractisSet(null));
    }, [dispatch]);
};

export const useDisablePractisSetFilter = () => {
    const dispatch = useDispatch();

    return useCallback((isDisabled: boolean) => {
        dispatch(disablePractisSetFilter(isDisabled));
    }, [dispatch]);
}

export const useClearPractisSetFilter = () => {
    const clearSelectedPractisSet = useClearSelectedPractisSet();
    const setPractisSetSearchActive = useSetFilterHeaderSearchActive(SearchableFilterHeaderKey.PRACTIS_SET);

    return useCallback(() => {
        clearSelectedPractisSet();
        setPractisSetSearchActive(false);
    }, [clearSelectedPractisSet, setPractisSetSearchActive]);
};
