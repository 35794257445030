import React from 'react';

export enum WEB_SITE_ID {
    ADMIN_PORTAL = 'ADMIN_PORTAL',
    COMPANY_PORTAL = 'COMPANY_PORTAL',
}

export const WebSiteIdContext = React.createContext<WEB_SITE_ID>(
    '' as WEB_SITE_ID
);
