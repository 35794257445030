export enum ACTIONS {
    SOCKET_PROGRESS_SHOW = 'SOCKET_PROGRESS_SHOW',
    SOCKET_PROGRESS_HIDE = 'SOCKET_PROGRESS_HIDE',
}

export function SocketProgressShow() {
    return {
        type: ACTIONS.SOCKET_PROGRESS_SHOW as ACTIONS.SOCKET_PROGRESS_SHOW,
    };
}

export function SocketProgressHide() {
    return {
        type: ACTIONS.SOCKET_PROGRESS_HIDE as ACTIONS.SOCKET_PROGRESS_HIDE,
    };
}
