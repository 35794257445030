import styled from 'styled-components';

export const Wrapper = styled.div<{ headerHeight: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360px;
    height: calc(100vh - ${props => props.headerHeight});
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--ps-white-1);
`;

export const DynamicFiltersContainer = styled.div`
    padding: 24px 24px 0 24px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const DynamicFilterWrapper = styled.div`
    margin-bottom: 12px;
`;

export const StyledDivider = styled.hr`
    margin-bottom: 12px;
    background-color: var(--ps-grey-4);
    width: 100%;
    height: 1px;
    border: none;
`;

export const ControlPanelContainer = styled.div`
    padding: 0 24px 12px 24px;
`;