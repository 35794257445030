import { TextLine } from './styles';

function FilterSkeletonView() {
    return (
        <>
            <TextLine width={81} />
            <TextLine width={149} />
            <TextLine width={120} />
        </>
    );
}

export default FilterSkeletonView;