import CheckCircle from '../../../../../../ui/icons/CheckCircle';
import Warning from '../../../../../../ui/icons/Warning';
import Saving from '../../../../../../ui/icons/Saving';
import { formatDate } from '../../../../../../helpers/functions/date-convert';
import { TeamUpdateStatus } from '../../../../store/states';
import { UpdateStatusViewProps } from './types';
import {
    StatusText,
    LastUpdatedText,
    StatusIcon
} from './styles';

function UpdateStatusView({
    updateStatus,
    lastUpdated
}: UpdateStatusViewProps) {
    switch(updateStatus) {
        case TeamUpdateStatus.IN_PROGRESS:
            return <>
                <StatusIcon data-test="team-saving-changes-icon"><Saving /></StatusIcon>
                <StatusText data-test="team-saving-changes-text">Saving Changes...</StatusText>
            </>;
        case TeamUpdateStatus.COMPLETED:
            return <>
                <StatusIcon data-test="team-changes-saved-icon"><CheckCircle /></StatusIcon>
                <StatusText data-test="team-changes-saved-text">Changes Saved</StatusText>
            </>;
        case TeamUpdateStatus.ERROR:
            return <>
                <StatusIcon data-test="team-changes-not-saved-icon"><Warning /></StatusIcon>
                <StatusText data-test="team-changes-not-saved-text">Changes weren't saved. Try it again.</StatusText>
            </>;
        default:
            if (!lastUpdated)
                return <></>;
            return <LastUpdatedText data-test='team-timestamp'>
                Last Updated {formatDate(lastUpdated)}
            </LastUpdatedText>
    }
}

export default UpdateStatusView;