import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const TeamMembersIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ className }, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 16 16"
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.077 4.086A2.062 2.062 0 0 1 5.52 3.5c.54 0 1.06.21 1.443.586.382.375.597.883.597 1.414 0 .53-.215 1.04-.597 1.414A2.062 2.062 0 0 1 5.52 7.5c-.542 0-1.06-.21-1.443-.586A1.98 1.98 0 0 1 3.479 5.5c0-.53.215-1.04.598-1.414zm-1.7-.248a3.445 3.445 0 0 0 .696 4.212 5.596 5.596 0 0 0-2.058 1.67A5.453 5.453 0 0 0 0 12h1.568a3.991 3.991 0 0 1 1.304-2.041 4.133 4.133 0 0 1 2.648-.955c.97 0 1.908.338 2.647.955A3.991 3.991 0 0 1 9.47 12h1.568a5.45 5.45 0 0 0-1.014-2.28 5.595 5.595 0 0 0-2.058-1.671c.43-.398.754-.894.941-1.444.187-.55.232-1.138.13-1.71a3.467 3.467 0 0 0-.715-1.564 3.564 3.564 0 0 0-1.387-1.044 3.637 3.637 0 0 0-3.378.288c-.495.318-.9.753-1.182 1.263zM10.34 13h-.067a.75.75 0 0 0 .067 0zM.754 13H.712h.042zm14.594 0h-.139a.779.779 0 0 0 .14 0zM16 12h-1.64a3.566 3.566 0 0 0-2.387-1.902.767.767 0 0 1-.422-.266.74.74 0 0 1-.164-.464v-.352c0-.14.04-.277.115-.395a.76.76 0 0 1 .31-.277c.309-.15.557-.399.704-.705.148-.306.185-.652.106-.981a1.496 1.496 0 0 0-.54-.833 1.551 1.551 0 0 0-.95-.325.773.773 0 0 1-.541-.22.742.742 0 0 1 0-1.06.773.773 0 0 1 .54-.22c.594 0 1.174.169 1.671.486.497.317.89.769 1.13 1.3.24.532.317 1.12.222 1.694a2.976 2.976 0 0 1-.757 1.538 5.102 5.102 0 0 1 1.622 1.243c.442.51.776 1.101.981 1.739z"
                    fill="currentColor"
                />
            </svg>
        );
    }
);

export default TeamMembersIcon;
