import { useContext } from 'react';

import { WithLabelsContextProps, WithLabelsContext } from './context';

export const useWithLabelsContext = (): WithLabelsContextProps => {
    const context = useContext(WithLabelsContext);

    if (!context) {
        throw new Error(
            'Cannot find WithLabels context provider. Please make a sure that you are located on the children of WithLabelsProvider'
        );
    }
    return context;
};
