import { useCallback } from 'react';

import { useHttp } from '../../tools/http/Http';
import {
    AssignLinesToScenarioApiParams,
    CreateNewScenarioApiParams,
    DeleteScenariosApiParams,
    DownloadScenarioReportApiParams,
    GetScenarioApiParams,
    SearchScenariosApiParams,
    UpdateScenarioApi,
    UpdateScenarioLabelApi,
    UpdateScenarioStatusApiParams,
} from './types';


/**
 * @function useSearchScenariosApi
 * @returns { SearchScenariosApiParams }
 */
export const useSearchScenariosApi = (): SearchScenariosApiParams => {
    const http = useHttp();
    return useCallback(
        searchParams => http.get('scenarios', searchParams, undefined, true),
        [http]
    );
};

/**
 * @function useDeleteScenariosApi
 * @returns { DeleteScenariosApiParams }
 */
 export const useDeleteScenariosApi = (): DeleteScenariosApiParams => {
    const http = useHttp();
    return useCallback(
        (scenarioIds) =>
            http.delete('scenarios/delete', scenarioIds, true),
        [http]
    );
};

/**
 * @function useDownloadScenarioReportApi
 * @returns { DownloadScenarioReportApiParams }
 */
export const useDownloadScenarioReportApi = (): DownloadScenarioReportApiParams => {
    const http = useHttp();
    return useCallback(
        scenarioId => http.get(`scenarios/report/${scenarioId}`, null, 'blob'),
        [http]
    );
};

/**
 * @function useGetScenarioApi
 * @returns { GetScenarioApiParams }
 */
export function useGetScenarioApi(): GetScenarioApiParams {
    const http = useHttp();
    return useCallback(
        scenarioId =>
            http.get(`scenarios/${scenarioId}`, undefined, undefined, true),
        [http]
    );
}

/**
 * @function useUpdateScenarioApi
 * @returns { UpdateScenarioApi }
 */
export function useUpdateScenarioApi(): UpdateScenarioApi {
    const http = useHttp();
    return useCallback(
        (scenarioId, data) => http.put(`scenarios/${scenarioId}`, data, true),
        [http]
    );
}

/**
 * @function useAssignScenarioLabelApi
 * @returns { UpdateScenarioLabelApi }
 */
 export function useAssignScenarioLabelApi(): UpdateScenarioLabelApi {
     const http = useHttp();
     return useCallback(
         labels => http.post(`scenarios/labels`, labels, true),
         [http]
     );
 }

 /**
 * @function useDeleteScenarioLabelApi
 * @returns { UpdateScenarioLabelApi }
 */
  export function useDeleteScenarioLabelApi(): UpdateScenarioLabelApi {
    const http = useHttp();
    return useCallback(
        labels => http.delete(`scenarios/labels`, labels, true),
        [http]
    );
}

 /**
 * @function useCreateScenarioApi
 * @returns { CreateNewScenarioApiParams }
 */
  export function useCreateScenarioApi(): CreateNewScenarioApiParams {
    const http = useHttp();
    return useCallback(
        (scenarioInfo) => http.post(`scenarios`, scenarioInfo, true),
        [http]
    );
}

 /**
 * @function useAssignLinesToScenarioApi
 * @returns { AssignLinesToScenarioApiParams }
 */
  export function useAssignLinesToScenarioApi(): AssignLinesToScenarioApiParams {
    const http = useHttp();
    return useCallback(
        (scenarioId, lines) => http.post(`scenarios/${scenarioId}/lines`, lines, true),
        [http]
    );
}

/**
 * @function useUpdateScenarioStatusApi
 * @returns { UpdateScenarioStatusApiParams }
 */
 export function useUpdateScenarioStatusApi(): UpdateScenarioStatusApiParams {
    const http = useHttp();
    return useCallback(
        (status, ScenarioIds) =>
            http.put(`scenarios/${status}`, ScenarioIds, true),
        [http]
    );
}