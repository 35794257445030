import { FC } from 'react';

import TableTitleOverflowText from '../../../TableTitleOverflowText/TableTitleOverflowText';
import { StyledFirstTableContent } from './styles';
import { OverflowTextFieldProps } from './types';

const OverFlowTextField: FC<OverflowTextFieldProps> = props => {
    const { row, renderTitle, getTextFieldProps, className, dataTest } = props;

    return (
        <StyledFirstTableContent className={className}>
            <TableTitleOverflowText {...getTextFieldProps?.(row)} dataTest={dataTest}>
                {renderTitle?.(row)}
            </TableTitleOverflowText>
        </StyledFirstTableContent>
    );
};

export default OverFlowTextField;

