import { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
    Challenge,
    ChallengeStatuses,
    Status,
} from '../../../../constants/interfaces/Challenge';
import { useVoiceGenerationState } from '../../../textToSpeech/store/states';
import { CompanyVoiceSettings } from '../../../../constants/interfaces/CompanyVoiceSettings';
import { AudioInterface, getAudioState } from '../../../player/store/reducers';
import {
    useRestoreChallengeFromTempActionService,
    useStoreChallengeTempCopyService,
} from '../../store/services';
import { EditModeContext, LoadingSaveContext } from '../../pages/EditChallenge';
import { useCompanyVoiceSettingsState } from '../../../../pages/CompanySettings/store/states';
import { buildPageTitle } from '../../../../helpers/functions/page-title-helpers';
import { EditModeValues } from '../../../../constants/enums/EditModeValues';
import { formatDate } from '../../../../helpers/functions/date-convert';
import { Button } from '../../../../ui/components/Button';
import { TableDivider } from '../../../../ui/components/table-wrapper/table-divider';
import { useHandleRestoreArchivedChallenge } from '../../services';
import { StatusModalConfirmation } from '../../../../pages/ActionPages/StatusModalConfirmation/StatusModalConfirmation';

const StyledCustomWrapper = styled.div``;

const StyledCustomHeader = styled.div`
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 103px;
`;

const StyledHeaderInput = styled.div`
    flex: 1;
    min-width: 150px;
    margin-right: 16px;
`;

const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${props => props.theme.Colors.black};
`;

const StyledHeaderActions = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap;
`;

const StyledHeaderActionItemsWrapper = styled.div`
    display: flex;
`;

const StyledHeaderActionItems = styled.div`
    margin-left: 16px;
`;

const PublishedDate = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.theme.Colors.cloudyBlue};
    margin-right: 16px;
`;

const EDIT_MODAL_INFO = {
    id: 'challenge_edit_message',
    message: 'Are you sure you want to edit Challenge.',
    buttonText: 'Proceed',
    cancelButtonText: 'Go Back',
    title: 'Are you sure?',
    notShow: false,
    danger: true,
};

const CANCEL_EDIT_MODAL_INFO = {
    id: 'challenge_cancel_edit_message',
    message: 'Do you want to discard changes?',
    buttonText: 'Discard',
    cancelButtonText: 'No',
    title: 'Discard Changes?',
    notShow: false,
    danger: true,
};

const MODAL_INFO = {
    DELETED: {
        message: 'Are you sure you want to delete?',
        buttonText: 'Yes, Delete',
        title: 'Delete Challenge',
        notShow: false,
        danger: false,
    },
    DRAFT: {
        message: 'Are you sure you want to archive?',
        buttonText: 'Yes, Archive',
        title: 'Archive Challenge',
        notShow: false,
        danger: false,
    },
    ARCHIVED: {
        message:
            'Removing the active items will effectively RESET progress for related Practis Sets for all currently enrolled Trainees. They will receive a notification and will be asked to start again.',
        buttonText: 'Yes, Archive',
        title: 'Are You Sure?',
        notShow: false,
        danger: true,
    },
    ACTIVE: {
        message: 'Are you sure you want to archive?',
        buttonText: 'Yes, Archive',
        title: 'Archive Challenge',
        notShow: false,
        danger: false,
    },
};

const EDIT_VIEW_STATUS = [ChallengeStatuses.ACTIVE];

const Header: FC<{
    handleUpdateChallenge: (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: Status,
        keepModal?: boolean
    ) => any;
    storeChallengeTemp: () => void;
    restoreChallengeFromTemp: () => void;
    challenge: Challenge;
    voiceSettings?: CompanyVoiceSettings[];
    player: AudioInterface;
    modified?: boolean;
    isViewMode?: boolean;
}> = ({
    challenge,
    handleUpdateChallenge,
    player,
    modified,
    storeChallengeTemp,
    restoreChallengeFromTemp,
    isViewMode,
}) => {
    const handleRestoreArchivedChallenge = useHandleRestoreArchivedChallenge();
    const voice = useVoiceGenerationState().challenges;
    const generating = voice && voice.generating;
    const [publishModal, setPublishModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [cancelEditModal, setCancelEditModal] = useState(false);
    const [saveState, setSaveState] = useState<Status>(
        ChallengeStatuses.ACTIVE
    );

    const handleSaveWithModalAction = (status: ChallengeStatuses) => {
        setSaveState(status);
        setPublishModal(true);
    };

    const loadingSave = useContext(LoadingSaveContext);
    const edit = useContext(EditModeContext);

    const cancelEditing = () => {
        if (modified) {
            setCancelEditModal(true);
        } else {
            restoreChallengeFromTemp();
            edit.action(EditModeValues.VIEW);
        }
    };
    const disableRestore = loadingSave || isViewMode

    return (
        <StyledCustomWrapper>
            {publishModal && (
                <StatusModalConfirmation
                    onCancel={() => {
                        setPublishModal(false);
                    }}
                    onConfirm={() => {
                        setPublishModal(false);
                        handleUpdateChallenge(null, saveState);
                    }}
                    title={MODAL_INFO[saveState].title}
                    confirmButtonText={MODAL_INFO[saveState].buttonText}
                    displayDontShow={MODAL_INFO[saveState].notShow}
                    danger={MODAL_INFO[saveState].danger}
                >
                    {MODAL_INFO[saveState].message}
                </StatusModalConfirmation>
            )}
            {editModal && (
                <StatusModalConfirmation
                    onCancel={() => {
                        setEditModal(false);
                    }}
                    onConfirm={() => {
                        setEditModal(false);
                        storeChallengeTemp();
                        edit.action(EditModeValues.EDIT);
                    }}
                    title={EDIT_MODAL_INFO.title}
                    confirmButtonText={EDIT_MODAL_INFO.buttonText}
                    cancelButtonText={EDIT_MODAL_INFO.cancelButtonText}
                    displayDontShow={EDIT_MODAL_INFO.notShow}
                    dontShowHandle={EDIT_MODAL_INFO.id}
                    danger={EDIT_MODAL_INFO.danger}
                >
                    {EDIT_MODAL_INFO.message}
                </StatusModalConfirmation>
            )}
            {cancelEditModal && (
                <StatusModalConfirmation
                    onCancel={() => {
                        setCancelEditModal(false);
                    }}
                    onConfirm={() => {
                        setCancelEditModal(false);
                        restoreChallengeFromTemp();
                        edit.action(EditModeValues.VIEW);
                    }}
                    title={CANCEL_EDIT_MODAL_INFO.title}
                    confirmButtonText={CANCEL_EDIT_MODAL_INFO.buttonText}
                    cancelButtonText={CANCEL_EDIT_MODAL_INFO.cancelButtonText}
                    displayDontShow={CANCEL_EDIT_MODAL_INFO.notShow}
                    dontShowHandle={CANCEL_EDIT_MODAL_INFO.id}
                    danger={CANCEL_EDIT_MODAL_INFO.danger}
                >
                    {CANCEL_EDIT_MODAL_INFO.message}
                </StatusModalConfirmation>
            )}
            <StyledCustomHeader>
                <StyledHeaderInput>
                    <HeaderTitle data-test="challenge-page-title">
                        {buildPageTitle('Challenge', edit.mode as EditModeValues, !challenge.id)}
                    </HeaderTitle>
                </StyledHeaderInput>
                <StyledHeaderActions>
                    <StyledHeaderActionItems>
                        <PublishedDate>
                            {challenge.status === ChallengeStatuses.ACTIVE &&
                                edit.mode === EditModeValues.VIEW && (
                                    <span data-test="challenge-publish-date">
                                        Published{' '}
                                        {formatDate(challenge.updatedAt, true)}
                                    </span>
                                )}
                            {challenge.status === ChallengeStatuses.DRAFT && (
                                <span data-test="challenge-not-published-yet">
                                    Not Published Yet
                                </span>
                            )}
                        </PublishedDate>
                    </StyledHeaderActionItems>
                    {EDIT_VIEW_STATUS.includes(challenge.status) ? (
                        edit.mode === EditModeValues.VIEW ? (
                            <StyledHeaderActionItemsWrapper>
                                <StyledHeaderActionItems>
                                    <Button
                                        width="144px"
                                        height="40px"
                                        disabled={disableRestore}
                                        action={() =>
                                            handleSaveWithModalAction(
                                                ChallengeStatuses.ARCHIVED
                                            )
                                        }
                                        variant="inverse"
                                        dataTest="archive-challenge"
                                    >
                                        Archive
                                    </Button>
                                </StyledHeaderActionItems>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave || isViewMode}
                                        action={() => setEditModal(true)}
                                        dataTest="edit-challenge"
                                    >
                                        Edit
                                    </Button>
                                </StyledHeaderActionItems>
                            </StyledHeaderActionItemsWrapper>
                        ) : (
                            <StyledHeaderActionItemsWrapper>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="144px"
                                        disabled={loadingSave}
                                        action={() => cancelEditing()}
                                        variant="inverse"
                                        dataTest="cancel-edit-challenge"
                                    >
                                        Cancel
                                    </Button>
                                </StyledHeaderActionItems>
                                <StyledHeaderActionItems>
                                    <Button
                                        height="40px"
                                        width="128px"
                                        disabled={loadingSave}
                                        action={() =>
                                            handleUpdateChallenge(null)
                                        }
                                        dataTest="save-challenge"
                                    >
                                        Save
                                    </Button>
                                </StyledHeaderActionItems>
                            </StyledHeaderActionItemsWrapper>
                        )
                    ) : challenge.status === ChallengeStatuses.ARCHIVED ? (
                        <StyledHeaderActionItemsWrapper>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="128px"
                                    disabled={disableRestore}
                                    action={() =>
                                        handleRestoreArchivedChallenge(
                                            challenge.id
                                        )
                                    }
                                    dataTest="restore-challenge"
                                >
                                    Restore
                                </Button>
                            </StyledHeaderActionItems>
                        </StyledHeaderActionItemsWrapper>
                    ) : (
                        <StyledHeaderActionItemsWrapper>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="144px"
                                    disabled={
                                        player.status !== 'off' ||
                                        !!player.recording ||
                                        generating ||
                                        loadingSave ||
                                        isViewMode
                                    }
                                    action={() =>
                                        handleUpdateChallenge(
                                            null,
                                            ChallengeStatuses.DRAFT
                                        )
                                    }
                                    variant="inverse"
                                    dataTest="save-challenge-as-draft"
                                >
                                    Save as Draft
                                </Button>
                            </StyledHeaderActionItems>
                            <StyledHeaderActionItems>
                                <Button
                                    height="40px"
                                    width="128px"
                                    disabled={
                                        player.status !== 'off' ||
                                        !!player.recording ||
                                        generating ||
                                        loadingSave ||
                                        isViewMode
                                    }
                                    action={() =>
                                        handleUpdateChallenge(
                                            null,
                                            ChallengeStatuses.ACTIVE
                                        )
                                    }
                                    dataTest="publish-challenge"
                                >
                                    Publish
                                </Button>
                            </StyledHeaderActionItems>
                        </StyledHeaderActionItemsWrapper>
                    )}
                </StyledHeaderActions>
            </StyledCustomHeader>
            <TableDivider />
        </StyledCustomWrapper>
    );
};

const HeaderContainer: FC<{
    handleUpdateChallenge: (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: Status,
        keepModal?: boolean
    ) => any;
    challenge: Challenge;
    modified?: boolean;
    isViewMode?: boolean;
}> = ({ challenge, handleUpdateChallenge, modified, isViewMode }) => {
    const player = useSelector(getAudioState);
    const voiceSettings = useCompanyVoiceSettingsState();
    const storeChallengeTemp = useStoreChallengeTempCopyService();
    const restoreChallengeFromTemp = useRestoreChallengeFromTempActionService();

    return (
        <Header
            player={player}
            handleUpdateChallenge={handleUpdateChallenge}
            modified={modified}
            storeChallengeTemp={storeChallengeTemp}
            restoreChallengeFromTemp={restoreChallengeFromTemp}
            challenge={challenge}
            voiceSettings={voiceSettings.data}
            isViewMode={isViewMode}
        />
    );
};

export default HeaderContainer;
