import React, { FC, memo } from 'react';
import styled from 'styled-components';

const Root = styled.div`
    display: flex;
`;

export const TableToolsLeft: FC<{
    className?: string;
}> = memo(({ children, className }) => (
    <Root className={className}>{children}</Root>
));
