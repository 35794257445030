import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Dialog from '../DialogWrapper/DialogWrapper';
import { useHideModalDialog } from './store/actions';
import { getModalDialogsState } from './store/reducers';
import { ConfirmationModalViewProps } from './types';

function ConfirmationModalView(props: ConfirmationModalViewProps) {
    const {
        modalTitle,
        description,
        onConfirm,
        cancelButtonText,
        confirmButtonText,
        customization,
        dialogType,
        dataTest = 'confirmation-modal',
    } = props;

    const onClose = useHideModalDialog();

    const { isLoading } = useSelector(getModalDialogsState);

    const handleConfirm = useCallback(() => {
        onConfirm?.();

        setTimeout(() => {
            onClose();
        }, 300);
    }, [onClose, onConfirm]);

    return (
        <Dialog
            modalTitle={modalTitle}
            description={description}
            cancelButtonText={cancelButtonText}
            confirmButtonText={confirmButtonText}
            onConfirm={handleConfirm}
            isLoading={isLoading}
            onCancel={onClose}
            customization={customization}
            dialogType={dialogType}
            dataTest={dataTest}
        />
    );
}

export default ConfirmationModalView;