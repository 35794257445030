import {
    Container,
    Input,
    Counter,
    Suffix,
    Label,
    Error,
    SuffixContainer,
} from './styles';
import { encodeStringForAttribute } from './tools';
import { CustomizedInputViewProps } from './types';

function CustomizedInputView({
    field,
    inputRef,
    remainingLength,
    isMaxLengthExceeded,
    suffix,
    label,
    showError,
    error,
    disabled,
    onChange,
    dataTest,
}: CustomizedInputViewProps) {
    const hasCounter = typeof remainingLength === 'number';
    return (
        <Container>
            <Input
                {...field}
                ref={inputRef}
                counter={remainingLength}
                suffix={suffix}
                showError={showError}
                disabled={disabled}
                onChange={onChange}
                data-test={dataTest && `${dataTest}-input`}
            />
            <SuffixContainer>
                {hasCounter && <Counter
                    isMaxLengthExceeded={isMaxLengthExceeded}
                    hasSuffix={!!suffix}
                    data-test={dataTest && `${dataTest}-counter`}
                >
                    {remainingLength}
                </Counter>
                }
                {suffix && (
                    <Suffix
                        isMaxLengthExceeded={isMaxLengthExceeded}
                        data-test={dataTest && `${dataTest}-suffix`}
                    >
                        {suffix}
                    </Suffix>
                )}
            </SuffixContainer>
            {showError && (
                <Error data-test={`${encodeStringForAttribute(error)}-error`}>{error}</Error>
            )}
            {label && !showError && (
                <Label data-test={dataTest && `${dataTest}-label`}>{label}</Label>
            )}
        </Container>
    );
}

export default CustomizedInputView;