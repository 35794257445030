import React, { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Variables } from '../../../theme/variables';
import { compose } from 'redux';
import { Inviter } from '../../../constants/interfaces/Inviter';
import EmptyInviters from './EmptyInviters';
import { PortableInvitersList } from './PortableInvitersList';
import { InvitersResult } from '../../inviters/interfaces';
import { filterInviters } from '../tools';
import { TableSearchInput } from '../../../ui/components/table-wrapper/table-tools/table-search-input';

//region Types
type Action = 'clear' | 'select';

export type PortableInvitersProps = {
    inviters?: InvitersResult<Inviter>;
    selectedList: number[];
    loading?: boolean;
    onSelectMultiply?: (ids: number[]) => void;
    onSelectAll?: () => void;
    onDeselectAll?: () => void;
    filtersTitle?: string;
    disabled?: boolean;
    showSelectActions?: boolean;
    showSearch?: boolean;
};
//endregion

//region Styles
const StyledCreateInviters = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 300px;
`;

const CreateInvitersBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const FiltersHeader = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: center;
    margin-bottom: 8px;
    flex-shrink: 0;
`;

const SelectActions = styled.div`
    font-size: 11px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;
`;

const PlainAction = styled.span<{
    disabled?: boolean;
    color: string;
    colorHover: string;
    colorActive: string;
}>`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled
            ? css`
                  color: ${props.theme.Colors.cloudyBlue};
                  pointer-events: none;
              `
            : css`
                  color: ${props.color};
              `}

    &:hover {
        color: ${({ colorHover }) => colorHover};
    }
    &:active {
        color: ${({ colorActive }) => colorActive};
    }
    &:not(:last-of-type) {
        margin-right: 8px;
    }
`;

const SelectedCaption = styled.span`
    color: ${props => props.theme.Colors.steelGrey};
`;

const FilterTitle = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.Colors.black};
    font-weight: 600;
`;

const SearchWrapper = styled.div`
    margin-bottom: 16px;
`;
//endregion

const PortableInviters: FC<PortableInvitersProps> = ({
    inviters,
    selectedList,
    loading,
    onSelectMultiply,
    onSelectAll,
    onDeselectAll,
    filtersTitle,
    showSelectActions,
    showSearch,
    disabled,
}) => {
    const actionConfig: Record<
        Action,
        {
            color: string;
            colorHover: string;
            colorActive: string;
        }
    > = {
        clear: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
        select: {
            color: Variables.Colors.darkSkyBlue,
            colorHover: Variables.Colors.lightBlue,
            colorActive: Variables.Colors.windowsBlue,
        },
    };

    const isAllSelected = useMemo(
        () => selectedList.length === inviters?.items.length,
        [selectedList, inviters]
    );

    const clearHandler = useCallback(() => {
        onDeselectAll?.();
    }, [onDeselectAll]);

    const deSelectAllHandler = useCallback(() => {
        if (isAllSelected && onDeselectAll) {
            onDeselectAll();
        } else if (onSelectAll) {
            onSelectAll();
        }
    }, [onDeselectAll, onSelectAll, isAllSelected]);

    const [searchTerm, setSearchTerm] = useState('');
    const searchHandler = (value: string) => setSearchTerm(value);
    const [processedSelectedList, setProcessedSelectedList] = useState<
        number[]
    >([]);

    const getPrecessedInviters = useMemo(() => {
        if (inviters) {
            const result = compose<Inviter[]>(filterInviters(searchTerm))(
                inviters.items
            );

            if (result.length > 0) {
                const processedDataIds = result.map(item => item.id!);
                setProcessedSelectedList(
                    selectedList.filter(item => processedDataIds.includes(item))
                );
            }

            return result;
        } else {
            return [];
        }
    }, [searchTerm, inviters, selectedList]);

    const showNoItems = !inviters?.items.length;
    const showEmptyState = !getPrecessedInviters.length || loading;
    const selectedInvitersLength = processedSelectedList.filter(id => id > 0)
        .length;

    return (
        <StyledCreateInviters>
            <CreateInvitersBody>
                {filtersTitle && (
                    <FiltersHeader>
                        <FilterTitle>{filtersTitle}</FilterTitle>
                    </FiltersHeader>
                )}
                {showSearch && (
                    <SearchWrapper>
                        <TableSearchInput
                            onChange={searchHandler}
                            disabled={showNoItems}
                            dataTest="inviters-search"
                        />
                    </SearchWrapper>
                )}
                {showSelectActions && !showNoItems && (
                    <SelectActions>
                        <div>
                            <SelectedCaption data-test="inviters-selected-text">
                                {selectedInvitersLength > 0
                                    ? `${selectedInvitersLength} ${
                                          selectedInvitersLength > 1
                                              ? ' Inviters selected'
                                              : ' Inviter selected'
                                      }`
                                    : 'No Inviters selected'}
                            </SelectedCaption>
                        </div>
                        <div>
                            {isAllSelected ? (
                                <PlainAction
                                    disabled={
                                        processedSelectedList.length === 0 ||
                                        showEmptyState
                                    }
                                    color={actionConfig.clear.color}
                                    colorActive={actionConfig.clear.colorActive}
                                    colorHover={actionConfig.clear.colorHover}
                                    onClick={clearHandler}
                                    data-test="inviters-unselect-all"
                                >
                                    Unselect All
                                </PlainAction>
                            ) : (
                                <PlainAction
                                    disabled={showEmptyState}
                                    color={actionConfig.select.color}
                                    colorActive={
                                        actionConfig.select.colorActive
                                    }
                                    colorHover={actionConfig.select.colorHover}
                                    onClick={deSelectAllHandler}
                                    data-test="inviters-select-all"
                                >
                                    Select All
                                </PlainAction>
                            )}
                        </div>
                    </SelectActions>
                )}
                {showEmptyState ? (
                    <EmptyInviters
                        loading={loading}
                        isEmpty={!inviters?.items.length}
                    />
                ) : (
                    <>
                        <PortableInvitersList
                            inviters={getPrecessedInviters}
                            selectedInviters={selectedList}
                            disabled={disabled}
                            setInvitersFilters={onSelectMultiply}
                        />
                    </>
                )}
            </CreateInvitersBody>
        </StyledCreateInviters>
    );
};

export { PortableInviters };
