import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { useCreateNewTeamService } from '../../store/services';
import { useSearchTeamsService } from '../../../portableTeams/store/hors/withTeams/services';
import { TeamV2 } from '../../../../constants/interfaces/Team';
import { replaceModal } from '../../../../tools/router';
import ROUTES from '../../../../routes/routes';
import { ModalPageContainerProps } from '../../../../ui/components/ModalPage/ModalPage';
import CreateTeamView from './view';
import useHtmlPageTitle from '../../../../helpers/hooks/useHtmlPageTitle';

function CreateTeamController({
    closePath,
    closeGoingBack,
}: ModalPageContainerProps) {
    const [name, setName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasError, setHasError] = useState(false);
    const history = useHistory() as History<any>;
    const createNewTeam = useCreateNewTeamService();
    const searchTeamsForFilters = useSearchTeamsService();
    useHtmlPageTitle('Create New Team');

    /**
     * @description saves changed name to state and resets error
     * @function handleChangeName
     * @param { string } value
     * @returns { void }
     */    
    const handleChangeName = (value: string) => {
        setName(value);
        setHasError(false);
    }

    /**
     * @description redirects to previous page
     * @function handleCancel
     * @returns { void }
     */    
     const handleCancel = useCallback(
        () => history.goBack(),
        [history]
    )

    /**
     * @description sends API request to create a new team.
     * If a request is succeeded, then it redicts to manage team modal. Otherwise - shows error.
     * @function handleSave
     * @returns { void }
     */    
    const handleSave = () => {
        setIsSubmitting(true);
        createNewTeam(name)
            .then((team: TeamV2) => {
                setTimeout(searchTeamsForFilters, 500);
                replaceModal(
                    history,
                    ROUTES.TEAMS_PAGES.MANAGE_TEAM.replace(':teamId', team.id.toString()),
                    { isDefaultTeam: false }
                )
            })
            .catch(() => {
                Promise.resolve().then(() => {
                    setIsSubmitting(false);
                    setHasError(true);
                });
            });
    };

    return <CreateTeamView
        closePath={closePath}
        closeGoingBack={closeGoingBack}
        name={name}
        isSubmitting={isSubmitting}
        hasError={hasError}
        onChangeName={handleChangeName}
        onCancel={handleCancel}
        onSave={handleSave}
    />
}

export default CreateTeamController;