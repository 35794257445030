import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { usePortableLabelsState } from '../../../../../features/portableLabels/store/states';
import { AssignLabels } from '../../../../../features/portableLabels/templates/AssignLabels';
import { useLabelsState } from '../../../../../features/portableLabels/store/hors/withLabels/states';
import {
    useResetLabels,
    useSaveLabels,
    useSelectLabels,
} from '../../../../../features/portableLabels/store/hors/withLabels/services';
import { Popup } from '../../../../../ui/components/Popup';
import { Tooltip } from '../../../../../ui/components/Tooltip';
import UpArrow from '../../../../../ui/icons/UpArrow';
import DownArrow from '../../../../../ui/icons/DownArrow';

const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

const SelectLabelWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const SelectLabel = styled.div<{ disabled?: boolean }>`
    font-size: 13px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.lightBlueGray
            : props.theme.Colors.black};
`;

const IconHolder = styled.div<{ disabled?: boolean }>`
    width: 8px;
    height: 100%;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${props =>
        props.disabled
            ? props.theme.Colors.lightBlueGray
            : props.theme.Colors.black};
`;

const EditAssignLabels: FC<{
    selectedLabels: number[];
    onSelectLabels(labelIds?: number[]): void;
    modified: boolean;
    setModified(modified: boolean): void;
    dataTest: string;
}> = ({ selectedLabels, onSelectLabels, modified, setModified, dataTest }) => {
    const { saved } = useLabelsState();
    const { data } = usePortableLabelsState();
    const saveLabels = useSaveLabels();
    const selectLabels = useSelectLabels();
    const resetLabels = useResetLabels();

    useEffect(() => {
        selectLabels(selectedLabels);
        saveLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectLabels, saveLabels]);

    useEffect(() => {
        return () => {
            resetLabels();
        };
    }, [resetLabels]);

    useEffect(() => {
        onSelectLabels(saved);
    }, [onSelectLabels, saved]);

    const labelsDisabled = !data || data.items.length < 1;
    const selectedUserLabelsCount = selectedLabels.filter(id => id > 0).length;

    return (
        <Container>
            <Popup<HTMLDivElement>
                content={({ hide }) => (
                    <AssignLabels
                        onSuccessApply={() => {
                            hide();
                        }}
                    />
                )}
            >
                {(ref, { toggleShown, shown }) => (
                    <span ref={ref}>
                        <Tooltip
                            label={'No labels added yet'}
                            preset={'dropdown'}
                            disabled={!labelsDisabled}
                        >
                            <SelectLabelWrapper
                                onClick={() => {
                                    if (!modified && !labelsDisabled) {
                                        setModified(true);
                                    }
                                    !labelsDisabled && toggleShown();
                                }}
                                disabled={labelsDisabled}
                                data-test={dataTest}
                            >
                                <>
                                    <SelectLabel disabled={labelsDisabled}>
                                        {selectedUserLabelsCount < 1
                                            ? 'Labels'
                                            : `${selectedUserLabelsCount} ${
                                                  selectedUserLabelsCount > 1
                                                      ? 'Labels'
                                                      : 'Label'
                                              }`}
                                    </SelectLabel>
                                    <IconHolder disabled={labelsDisabled}>
                                        {shown ? <UpArrow /> : <DownArrow />}
                                    </IconHolder>
                                </>
                            </SelectLabelWrapper>
                        </Tooltip>
                    </span>
                )}
            </Popup>
        </Container>
    );
};

export default EditAssignLabels;
