import { FC, Fragment, useState } from 'react';
import { Menu } from '@material-ui/core';

import { ActionItem } from '../../../../../../../ui/components/ActionButton';

import Dots from '../../../../../../../ui/icons/Dots';
import { CheckPermission } from '../../../../../../../features/permissions';
import { ActionMenuHandler, IconContainer, menuPaperStyle } from './styles';
import { DueDateListActionProps } from './contants';

const uuid = require('uuid/v1');

const DueDateListAction: FC<DueDateListActionProps> = props => {
    const { actions } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isVisible = Boolean(anchorEl);

    /**
     * @function handleToggle
     * @param { React.MouseEvent<HTMLElement> } event
     * @returns { void }
     */
    const handleToggle = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * @function handleClose
     * @returns { void }
     */
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconContainer onClick={handleToggle} data-test="due-date-actions-button">
                <ActionMenuHandler open={isVisible} hasCustomComponent={false}>
                    <Dots />
                </ActionMenuHandler>
            </IconContainer>
            {isVisible && (
                <Menu
                    id="Due-Date-Menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={isVisible}
                    onClose={handleClose}
                    PaperProps={{
                        style: menuPaperStyle,
                    }}
                >
                    {actions.map(action => {
                        const { title, permissions, onClick, dataTest } = action;

                        return (
                            <Fragment key={uuid()}>
                                {permissions ? (
                                    <CheckPermission permissions={permissions}>
                                        <ActionItem
                                            onClick={() => {
                                                onClick();
                                                handleClose();
                                            }}
                                            dataTest={dataTest}
                                        >
                                            {title}
                                        </ActionItem>
                                    </CheckPermission>
                                ) : (
                                    <ActionItem
                                        onClick={() => {
                                            onClick();
                                            handleClose();
                                        }}
                                    >
                                        {title}
                                    </ActionItem>
                                )}
                            </Fragment>
                        );
                    })}
                </Menu>
            )}
        </>
    );
};

export default DueDateListAction;
