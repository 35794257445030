import { FC, memo } from 'react';

import DownArrow from '../../../../../icons/DownArrow';
import UpArrow from '../../../../../icons/UpArrow';
import { QuestionTooltip } from '../../../../Tooltip/QuestionTooltip';
import {
    Content,
    EmptyIcon,
    IconContainer,
    IconWrapper,
    QuestionIconWrapper,
    Th,
    Text,
} from './style';
import { TableHeaderCellProps } from './type';

const TableHeaderCellSingleView: FC<TableHeaderCellProps> = props => {
    const {
        width,
        asc,
        disabled,
        onClick,
        children,
        className,
        tooltipText,
        withTooltip,
        isHtmlTooltip,
        dataTest
    } = props;

    /**
     * @function renderArrow
     * @param { boolean | undefined } asc
     * @returns { JSX.Element }
     */
    const renderArrow = (asc?: boolean): JSX.Element => {
        if (typeof asc === 'boolean') {
            return asc ? (
                <IconContainer clickable={true}>
                    <IconWrapper data-test="table-column-sorting">
                        <UpArrow />
                    </IconWrapper>
                </IconContainer>
            ) : (
                <IconContainer clickable={true}>
                    <IconWrapper data-test="table-column-sorting">
                        <DownArrow />
                    </IconWrapper>
                </IconContainer>
            );
        }

        return (
            <IconContainer clickable={false}>
                <EmptyIcon />
            </IconContainer>
        );
    };
    return (
        <Th
            width={width}
            clickable={!!onClick}
            className={className}
            disabled={disabled}
            data-test={dataTest}
        >
            <Content
                clickable={!!onClick && !disabled}
                onClick={() => {
                    if (!disabled && onClick) {
                        onClick();
                    }
                }}
            >
                <Text clickable={!!onClick && !disabled} data-test={dataTest && `${dataTest}-text`}>{children}</Text>
                {withTooltip && tooltipText && (
                    <QuestionIconWrapper>
                        <QuestionTooltip
                            isHtmlContent={isHtmlTooltip}
                            text={tooltipText}
                            dataTest={dataTest && `${dataTest}-tooltip`}
                        />
                    </QuestionIconWrapper>
                )}

                {!!onClick && renderArrow(asc)}
            </Content>
        </Th>
    );
};

export default memo(TableHeaderCellSingleView);
