import {
    ACTIONS,
    getRoleplayFailure,
    getRoleplayStart,
    getRoleplaySuccess,
    updateRoleplayStart,
    updateRoleplaySuccess,
    updateRoleplayFailure,
    clearRoleplay
} from './actions';
import { RoleplayState } from './states';

type RoleplayActions =
    | ReturnType<typeof getRoleplayStart>
    | ReturnType<typeof getRoleplaySuccess>
    | ReturnType<typeof getRoleplayFailure>
    | ReturnType<typeof updateRoleplayStart>
    | ReturnType<typeof updateRoleplaySuccess>
    | ReturnType<typeof updateRoleplayFailure>
    | ReturnType<typeof clearRoleplay>;

export const roleplayReducer = (
    state = { isLoading: false },
    action: RoleplayActions
): RoleplayState => {
    switch (action.type) {
        case ACTIONS.GET_ROLEPLAY_START:
            return {
                ...state,
                isLoading: true
            };
        case ACTIONS.GET_ROLEPLAY_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: ''
            };
        case ACTIONS.GET_ROLEPLAY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case ACTIONS.UPDATE_ROLEPLAY_START:
            return {
                ...state,
                isLoading: false
            };
        case ACTIONS.UPDATE_ROLEPLAY_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: ''
            };
        case ACTIONS.UPDATE_ROLEPLAY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case ACTIONS.CLEAR_ROLEPLAY:
            return {
                isLoading: false,
                data: undefined,
                error: ''
            };
        default:
            return state;
    }
}