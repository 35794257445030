import { useContext } from 'react';

import { WithTeamsContextProps, WithTeamsContext } from './context';

export const useWithTeamsContext = (): WithTeamsContextProps => {
    const context = useContext(WithTeamsContext);

    if (!context) {
        throw new Error(
            'Cannot find WithTeams context provider. Please make a sure that you are located on the children of WithTeamsProvider'
        );
    }
    return context;
};
