import { Container } from '@material-ui/core';
import styled from 'styled-components';

export const WrapperContainer = styled.div`
    display: flex;
    height: 100vh;
`;

export const Content = styled.main<{
    height?: string;
    contentPadding?: string;
}>`
    padding: ${props =>
        !!props.contentPadding ? props.contentPadding : '0 24px'};

    flex: 1;
    min-width: 511px;
    ${props => !!props.height && `height: ${props.height};`};
`;

export const StyledTest = styled.div<{ isScrollable: boolean }>`
    flex: 1;
    ${props => !!props.isScrollable && `overflow-y: scroll;`};
    position: relative;
`;

export const StyledContainer = styled(Container)<{ height?: string, marginBottom?: string }>`
    padding: 0 !important;
    margin-bottom:  ${props => props.marginBottom || '72px' };
    ${props => !!props.height && `height: ${props.height};`};
`;