import React, { forwardRef } from 'react';
import { IconProps } from './types';

export const FileDownload = forwardRef<SVGSVGElement, IconProps>(
    ({ className, shadow }, ref) => {
        return (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 18 18"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g fill="currentColor" fillRule="evenodd">
                    <path
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth=".1"
                        d="M13.082 1c.772 0 1.39.637 1.417 1.422l.001.055v5.428c0 .272-.22.492-.49.492-.26 0-.472-.2-.49-.455l-.002-.037V5.75H10V16.5h-.153v.008c0 .26-.2.472-.454.49L9.356 17H2.418c-.772 0-1.39-.637-1.417-1.422L1 2.477C1 1.686 1.6 1.03 2.365 1L2.418 1h10.664zM14 10c.276 0 .5.22.5.49v4.853l1.667-1.633c.19-.187.5-.187.69 0 .19.187.19.49 0 .676l-2.469 2.42c-.003.005-.009.006-.012.01-.01.013-.006.032-.019.044-.018.018-.046.013-.066.027-.074.058-.16.099-.26.105-.009 0-.015.006-.024.006l-.003.001h-.008c-.01 0-.014-.004-.02-.004-.12-.006-.226-.052-.31-.125-.006-.006-.017-.004-.023-.01-.007-.007-.004-.017-.01-.024-.004-.004-.003-.012-.007-.017l-2.483-2.433c-.19-.186-.19-.49 0-.677.19-.186.5-.186.69 0l1.667 1.634V10.49c0-.27.224-.49.5-.49zM9 16.015V5.75H5.75v10.265h3.249zM4.75 5.75H1.981v9.773c0 .265.184.472.404.49l.033.002H4.75V5.75zm8.332-3.765H2.418c-.223 0-.417.194-.435.453l-.001.039L1.98 4.75h11.537V2.477c0-.265-.183-.472-.403-.49l-.033-.002z"
                    />
                </g>
            </svg>
        );
    }
);

export default FileDownload;
