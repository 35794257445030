import {
    createContext,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { isEmpty } from 'lodash';

import { ModalPageContainer } from '../../../ui/components/ModalPage';
import styled from 'styled-components';
import HeaderContainer from '../components/Header';
import {
    Challenge,
    ChallengeStatuses,
    Status,
} from '../../../constants/interfaces/Challenge';
import { ModifyCase, useChallengeState } from '../store/states';
import {
    useCreatePlaylistService,
    useResetAudioService,
} from '../../player/store/services';
import {
    useCreateChallengeScriptLineService,
    useGetChallengeService,
    useModifyChallengeService,
    useResetChallengeService,
} from '../store/services';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useHistory, useParams } from 'react-router';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { validationService } from '../tools';
import { buildPageTitle } from '../../../helpers/functions/page-title-helpers';
import { EditModeValues } from '../../../constants/enums/EditModeValues';
import ChallengeBody from '../components/ChallengeBody';
import {
    ScriptLine,
    ScriptLineSpeaker,
} from '../../../constants/interfaces/ScriptLine';
import { Loading } from '../../../ui/components/LoadingCopmonent';
import { useLoadVoiceSettingsService } from '../../../pages/CompanySettings/store/services';
import ActionPanel from '../components/ActionPanel/ActionPanel';
import { useLabelsState } from '../../labels/store/states';
import {
    useSetAssignLabelsActionService,
    useSetPreviouslyAssignedLabelsAction,
} from '../../labels/store/services';
import {
    calculateAssignedLabels,
    calculateRemovedLabels,
    useCalculatePreSelectedLabelsForSingleItem,
} from '../../labels/tools';
import { isTeamLeader } from '../../../constants/enums';
import { usePortableLabelsState } from '../../portableLabels/store/states';
import { useSelector } from 'react-redux';
import { getProfileState } from '../../../pages/UserProfile/store/reducers';
import { UserProfile } from '../../../constants/interfaces/User';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { useLoggedInUserPermissionsHelper } from '../../users/tools';
import { TableDivider } from '../../../ui/components/table-wrapper/table-divider';
import {
    useGenerateCreateDuplicateChallengeActionList,
    useGenerateEditChallengeActionList,
} from '../../library/services/LibraryBulkActionsService/helpers';
import { useCreateEditDuplicateLibraryBulActionService } from '../../library/services/LibraryBulkActionsService';
import {
    CreateNewChallengeInfo,
    EditChallengeInfo,
} from '../../library/services/LibraryBulkActionsService/types';
import {
    useCreateEditChallengeFailedCallback,
    useCreateEditChallengeSuccessCallback,
    useHandleArchiveChallenge,
} from '../services';
import DialogWrapper from '../../../ui/components/DialogWrapper/DialogWrapper';
import useHtmlPageTitle from '../../../helpers/hooks/useHtmlPageTitle';
import { useGetRoleplayForScenario } from '../../../pages/ActionPages/NewScenario/store/services';

const uuid = require('uuid/v1');
const qs = require('query-string');

export const StyledContainer = styled.div`
    overflow: auto;
    height: 100%;
    display: block;
    padding: 0 32px;
    background: ${props => props.theme.Colors.white};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

const StyledLoadingContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const CreatedByWrapper = styled.div`
    padding: 0 16px 10px;
    color: ${props => props.theme.Colors.steelGrey};
    font-size: 13px;
`;

export const StatusItems: Array<{
    value: string;
    name: string;
    confirm: boolean;
    message: string;
}> = [
    {
        value: 'DRAFT',
        name: 'Draft',
        message: 'Challenge saved as Draft',
        confirm: false,
    },
    {
        value: 'ACTIVE',
        name: 'Active',
        message: 'Challenge published',
        confirm: false,
    },
    {
        value: 'ARCHIVED',
        name: 'Archived',
        message: 'Challenge archived',
        confirm: true,
    },
    {
        value: 'DELETED',
        name: 'Deleted',
        message: 'Challenge deleted',
        confirm: true,
    },
];

export const LoadingSaveContext = createContext(false);
export const ChallengeContainerContext = createContext<any>(null);
export const PlayAudioContext = createContext<any>(null);
export const EditModeContext = createContext<{
    mode: string;
    action: (mode: EditModeValues) => void;
}>({
    mode: 'view',
    action: () => {},
});

const EditChallenge: FC<{
    challenge: Challenge;
    modified: ModifyCase;
    loading?: boolean;
    history?: any;
    profile?: UserProfile;
    challengeId?: number | string;
    getChallenge: (challengeId: number | string) => void;
    handleCreateScriptLine: (
        data: Partial<ScriptLine>,
        silent?: boolean
    ) => any;
    handleResetChallenge: () => void;
    handleCreatePlaylist: (data: any) => void;
    handleResetPlaylist: () => void;
    handleLoadVoiceSettings: () => void;
    showMessage: (
        message: string | string[],
        status: 'error' | 'success'
    ) => void;
    closePath: string;
    closeGoingBack: boolean;
    isNew: boolean;
}> = ({
    closeGoingBack,
    closePath,
    isNew,
    loading,
    modified,
    getChallenge,
    challenge,
    challengeId,
    handleCreateScriptLine,
    handleResetChallenge,
    handleCreatePlaylist,
    handleResetPlaylist,
    handleLoadVoiceSettings,
    showMessage,
    history,
    profile,
}) => {
    const [editMode, setEditMode] = useState<EditModeValues>(
        EditModeValues.VIEW
    );

    const createEditChallengeSuccessCallback =
        useCreateEditChallengeSuccessCallback();
    const createEditChallengeFailedCallback =
        useCreateEditChallengeFailedCallback();
    const handleArchiveChallenge = useHandleArchiveChallenge();
    useHtmlPageTitle(
        buildPageTitle('Challenge', editMode, isNew),
        [challenge.title]
    );
    const getRoleplayForScenario = useGetRoleplayForScenario();
    const location = history.location;

    const [loadingSave, setLoadingSave] = useState(false);
    const isTeamLead = isTeamLeader(profile?.role?.name);
    const parentRef = useRef(null);
    const [titleError, setTitleError] = useState(false);
    const isLoaded = !!challenge?.id;

    const roleplayId = qs.parse(location.search)?.roleplayId;
    const contentId = qs.parse(location.search)?.contentId;
    const modifyChallenge = useModifyChallengeService();

    const createScriptLine = useCallback(
        (speaker: ScriptLineSpeaker, silent?: boolean, text?: string) => {
            const sendValues = {
                id: `temp_${uuid()}`,
                text: text ?? '',
                speaker: speaker,
                position: challenge.script
                    ? challenge.script.lines.length + 1
                    : 1,
                allowedResponseTime:
                    speaker === ScriptLineSpeaker.REP ? 45 : null,
                scriptId: challenge.scriptId,
            };

            handleCreateScriptLine(sendValues, silent);
        },
        [challenge.script, challenge.scriptId, handleCreateScriptLine]
    );

    useEffect(() => {
        if (roleplayId && contentId) {
            getRoleplayForScenario(roleplayId, contentId).then(({ aiLines, title, description }) => {
                aiLines.forEach(line => line.speaker === 'CUSTOMER' && createScriptLine(line.speaker, false, line.text));
                modifyChallenge(title || '', 'title');
                modifyChallenge(description || '', 'description');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleplayId, contentId, getRoleplayForScenario])

    useEffect(() => {
        if (
            !history?.location?.state?.prevLocation?.pathname?.includes(
                '/submissions/challenges/'
            )
        ) {
            handleResetChallenge();
            handleResetPlaylist();

            if (!isNew && challengeId) {
                getChallenge(challengeId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getChallenge,
        challengeId,
        isNew,
        handleResetPlaylist,
        handleResetChallenge,
    ]);

    useEffect(() => {
        return () => {
            handleResetPlaylist();
        };
    }, [handleResetPlaylist]);

    useEffect(() => {
        setEditMode(
            (isLoaded || isNew) && challenge.status === ChallengeStatuses.DRAFT && !isTeamLead
                ? EditModeValues.EDIT
                : EditModeValues.VIEW
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, isNew, challenge.status, setEditMode]);

    const labels = useLabelsState();
    const labelsList = usePortableLabelsState().data;
    const setAssignLabelsAction = useSetAssignLabelsActionService();
    const setPreviouslyAssignedLabelsAction =
        useSetPreviouslyAssignedLabelsAction();
    const calculatePreSelectedLabels =
        useCalculatePreSelectedLabelsForSingleItem();
    const loggedInActionsHelper = useLoggedInUserPermissionsHelper();

    useEffect(() => {
        if (challenge.labels && challenge.labels.length > 0) {
            const selectedLabelIds = challenge.labels;
            const preAssignedLabels = calculatePreSelectedLabels(
                selectedLabelIds,
                labelsList
            );
            setAssignLabelsAction(preAssignedLabels);
        } else {
            setAssignLabelsAction([]);
        }
        return () => {
            setAssignLabelsAction([]);
        };
    }, [
        challenge.labels,
        labelsList,
        calculatePreSelectedLabels,
        setAssignLabelsAction,
    ]);

    //initially set previousAssignedLabels
    useEffect(() => {
        if (!isEmpty(challenge.labels)) {
            const selectedLabelIds = challenge.labels ?? [];
            const preAssignedLabels = calculatePreSelectedLabels(
                selectedLabelIds,
                labelsList
            );
            setPreviouslyAssignedLabelsAction(preAssignedLabels);
        } else {
            setPreviouslyAssignedLabelsAction([]);
        }
        return () => {
            setPreviouslyAssignedLabelsAction([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challenge.labels]);

    const generateCreateChallengeActionList =
        useGenerateCreateDuplicateChallengeActionList();

        const generateEditChallengeActionList =
        useGenerateEditChallengeActionList();

    const createLibraryBulkActionService =
        useCreateEditDuplicateLibraryBulActionService(
            isNew ? 'create' : 'update',
            'Challenge',
            responses =>
                createEditChallengeSuccessCallback(setLoadingSave, responses),
            (error, completedResponses) =>
                createEditChallengeFailedCallback(
                    setLoadingSave,
                    error,
                    completedResponses
                )
        );

    const handleUpdateChallenge = (
        callbacks: { onConfirm: () => void; onCancel: () => void } | null,
        status?: Status
    ) => {
        setLoadingSave(true);
        const response = validationService(
            challenge,
            status ? status : challenge.status
        );
        if (!response.valid) {
            callbacks && callbacks.onCancel();
            showMessage(response.message, 'error');
            setLoadingSave(false);

            setTitleError(response.name === 'name');
            return;
        } else {
            setTitleError(false);
        }

        const newChallengeInfo: CreateNewChallengeInfo = {
            title: challenge.title,
            description: challenge.description,
            sourceScenarioId: challenge.sourceScenarioId ?? 0,
            labelIds: calculateAssignedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            lines:
                challenge.script?.lines?.filter(line => line.text.length > 0) ??
                [],
            status: status ?? challenge.status,
            tryLimit: challenge.tryLimit,
        };

        const editChallengeInfo: EditChallengeInfo = {
            challengeId: challenge.id ?? 0,
            title: challenge.title,
            description: challenge.description,
            addedLabelIds: calculateAssignedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            deletedLabelIds: calculateRemovedLabels(
                labels.assignedLabels,
                labels.previouslyAssignedLabels ?? []
            ),
            lines:
                challenge.script?.lines?.filter(line => line.text.length > 0) ??
                [],
            status: status ?? challenge.status,
            tryLimit: challenge.tryLimit,
        };

        const createChallengeActionList =
            generateCreateChallengeActionList(newChallengeInfo);

        const editChallengeActionList =
            generateEditChallengeActionList(editChallengeInfo);

        if (status !== ChallengeStatuses.ARCHIVED) {
            if (!challenge.id) {
                createLibraryBulkActionService([createChallengeActionList]);
            } else {
                createLibraryBulkActionService(editChallengeActionList);
            }
        } else {
            challenge?.id && handleArchiveChallenge(challenge.id);
        }
    };

    

    const playAllAudio = () => {
        handleResetPlaylist();
        const filterItems = challenge.script.lines.filter(
            (script: ScriptLine) => script.audioUrl
        );
        const playlistItems = filterItems
            .filter(script => script.speaker === ScriptLineSpeaker.CUSTOMER)
            .map((script: ScriptLine) => script.id);
        setTimeout(() => {
            handleCreatePlaylist(playlistItems);
        }, 150);
    };

    useEffect(() => {
        if (
            loggedInActionsHelper.hasActionPermission(
                NEW_PERMISSIONS.VIEW_TTS_SETTINGS
            )
        ) {
            handleLoadVoiceSettings();
        }
    }, [handleLoadVoiceSettings, loggedInActionsHelper]);

    useEffect(() => {
        if (isNew && challenge.script.lines.length < 1 && !roleplayId) {
            createScriptLine(ScriptLineSpeaker.CUSTOMER, true);
        }
    }, [challenge.script.lines.length, createScriptLine, isNew, roleplayId]);


    if (!isNew && loading) {
        return (
            <ModalPageContainer
                closePath={closePath}
                closeGoingBack={closeGoingBack}
            >
                <StyledLoadingContainer>
                    <Loading />
                </StyledLoadingContainer>
            </ModalPageContainer>
        );
    }

    return (
        <ModalPageContainer
            closePath={closePath}
            closeGoingBack={closeGoingBack}
        >
            <StyledContainer ref={parentRef}>
                <EditModeContext.Provider
                    value={{ mode: editMode, action: setEditMode }}
                >
                    <LoadingSaveContext.Provider value={loadingSave}>
                        <HeaderContainer
                            challenge={challenge}
                            handleUpdateChallenge={handleUpdateChallenge}
                            modified={modified === 'modified'}
                            isViewMode={isTeamLead}
                        />
                        <ActionPanel
                            challenge={challenge}
                            titleError={titleError}
                        />
                        {challenge.creator && (
                            <CreatedByWrapper data-test="challenge-creator-text">
                                Created by {challenge.creator?.firstName}{' '}
                                {challenge.creator?.lastName}
                            </CreatedByWrapper>
                        )}
                        <TableDivider />
                        <ChallengeContainerContext.Provider value={parentRef}>
                            <PlayAudioContext.Provider value={playAllAudio}>
                                <ChallengeBody
                                    challenge={challenge}
                                    createScriptLine={createScriptLine}
                                />
                            </PlayAudioContext.Provider>
                        </ChallengeContainerContext.Provider>
                    </LoadingSaveContext.Provider>
                </EditModeContext.Provider>
                <NavigationPrompt when={modified === 'modified'}>
                    {({ onConfirm, onCancel }) => (
                        <DialogWrapper
                            modalTitle={'Discard changes?'}
                            description={
                                'Do you want to save or discard changes?'
                            }
                            cancelButtonText={'Discard'}
                            confirmButtonText={'Save'}
                            onCancel={onConfirm}
                            onConfirm={() => {
                                handleUpdateChallenge({ onConfirm, onCancel });
                            }}
                            dataTest="confirmation-modal"
                        />
                    )}
                </NavigationPrompt>
            </StyledContainer>
        </ModalPageContainer>
    );
};

export const EditChallengeContainer: FC<{
    closePath: string;
    closeGoingBack: boolean;
}> = ({ closePath, closeGoingBack }) => {
    const challenge = useChallengeState().data;
    const modified = useChallengeState().case;
    const loading = useChallengeState().loading;
    const history = useHistory();
    const profile = useSelector(getProfileState);

    const params: { challengeId?: string | number } = useParams();

    const getChallenge = useGetChallengeService();
    const resetChallenge = useResetChallengeService();
    const createScriptLine = useCreateChallengeScriptLineService();

    const createPlaylist = useCreatePlaylistService();
    const resetPlaylist = useResetAudioService();
    const showMessage = useShowMessage();

    const loadVoiceSettings = useLoadVoiceSettingsService();
    const handleLoadVoiceSettings = useCallback(
        () => profile?.companyId && loadVoiceSettings(profile?.companyId),
        [loadVoiceSettings, profile?.companyId]
    );

    return (
        <EditChallenge
            challenge={challenge}
            challengeId={params.challengeId}
            modified={modified}
            loading={loading}
            isNew={params.challengeId === 'new'}
            closeGoingBack={closeGoingBack}
            closePath={closePath}
            history={history}
            profile={profile}
            getChallenge={getChallenge}
            handleCreateScriptLine={createScriptLine}
            handleResetChallenge={resetChallenge}
            handleCreatePlaylist={createPlaylist}
            handleResetPlaylist={resetPlaylist}
            handleLoadVoiceSettings={handleLoadVoiceSettings}
            showMessage={showMessage}
        />
    );
};

export default EditChallengeContainer;