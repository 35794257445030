import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ModalTitle = styled.div`
    padding: 8px 50px 8px 24px;
    font-size: 18px;
    font-weight: 800;
    color: var(--ps-black-main);
`;

export const CompanyName = styled.span`
    font-weight: 400;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 215px;
`;

export const ModalSidebar = styled.div`
    width: 190px;
    background-color: var(--ps-grey-3);
    border-right: 1px solid var(--ps-grey-4);
    border-bottom-left-radius: 8px;
`;

export const NavigationItem = styled(NavLink)`
    margin-top: 18px;
    padding-left: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--ps-grey-1);
    display: flex;
    text-decoration: none;
    position: relative;

    &:first-child {
        margin-top: 24px;
    }

    &.is-active {
        font-weight: bold;
        color: var(--ps-black-main);

        &::before {
            width: 4px;
            height: 24px;
            background: var(--ps-black-main);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0 10px 10px 0;
        }

        div {
            color: var(--ps-black-main);
        }
    }
`;

export const NavigationIcon = styled.div`
    width: 16px;
    height: 16px;
    color: var(--ps-grey-1);
    margin-right: 12px;
`;

export const TabContent = styled.div`
    width: 600px;
    min-height: 630px;
`;