import React, { FC, useEffect } from 'react';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import RadioButton from '../../ui/components/RadioButton';
import { useUserRolesState } from '../roles/store/states';
import { useCalculatePreSelectedUserRoles } from './tools';
import { DraftUser } from '../../constants/interfaces/Draft';
import { PaginationResult } from '../../constants/interfaces/PaginationResult';
import { filterInvitationRolesByPermissions } from '../../pages/ActionPages/NewUser/tools';
import { usePermissionsState } from '../permissions/store/state';

const Container = styled.div`
    width: 100%;
`;

const PortableRoles: FC<{
    users?: PaginationResult<DraftUser>;
    selectedUsers?: number[];
    selectedRoles: number[];
    setSelectedRoles(value: number[]): void;
}> = ({ users, selectedUsers, selectedRoles, setSelectedRoles }) => {
    const permissions = usePermissionsState();
    const userRoles = useUserRolesState();
    const roles = filterInvitationRolesByPermissions(
        userRoles.list,
        permissions
    );
    const calculatePreSelectedUserRoles = useCalculatePreSelectedUserRoles();

    useEffect(() => {
        if (users && users.items && selectedUsers) {
            const preSelectedUserRoles = calculatePreSelectedUserRoles(
                selectedUsers,
                users.items as any // TODO: ask
            );

            setSelectedRoles(preSelectedUserRoles);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatePreSelectedUserRoles, selectedUsers, users]);

    return (
        <Container>
            {roles.map(item => {
                return (
                    <RadioButton
                        key={item.value}
                        handleChange={() => setSelectedRoles([item.value])}
                        checked={selectedRoles.includes(item.value)}
                        partial={selectedRoles.length > 1}
                        size={12}
                        label={item.name}
                        dataTest={`${kebabCase(item.name.toLowerCase())}-role-radio`}
                    />
                );
            })}
        </Container>
    );
};

export default PortableRoles;
