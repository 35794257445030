import VerificationCodeInput from '../../../../../ui/components/VerificationCodeInput/VerificationCodeInput';
import { Button } from '../../../../../ui/components/Button';
import { LinkText } from '../../../../../ui/components/LinkText/link-text';
import BackArrowImage from '../../../../../assets/images/icons/back-arrow.svg';
import { CheckVerificationCodeViewProps } from './types';
import {
    Title,
    Description,
    MobileNumber,
    BackArrow,
    VerificationCodeContainer,
    ButtonContainer,
    FooterText,
    SentLabel,
    CountdownTimer,
    VerificationError
} from './styles';

function CheckVerificationCodeView({
    mobileNumber,
    isVerifying,
    verificationError,
    resendStatus,
    remainingTime,
    isCodeValid,
    showSent,
    showCountDown,
    onCodeChange,
    onBack,
    onVerify,
    onResend
}: CheckVerificationCodeViewProps) {
    return <>
        <Title>
            <BackArrow src={BackArrowImage} onClick={onBack} />
            <span data-test="verify-mobile-number">Verify Mobile Number</span>
        </Title>
        <Description data-test="instructions-text">Enter the 5-digit code we sent to:</Description>
        <MobileNumber data-test="mobile-number">{mobileNumber}</MobileNumber>
        <VerificationCodeContainer className={!!verificationError ? 'has-error' : undefined}>
            <VerificationCodeInput
                onChange={onCodeChange}
            />
            {!!verificationError && (<VerificationError data-test="verification-error">{verificationError}</VerificationError>)}
        </VerificationCodeContainer>
        <ButtonContainer>
            <Button
                height='56px'
                width='100%'
                buttonWidth='100%'
                fontSize={15}
                loading={isVerifying}
                disabled={!isCodeValid}
                action={onVerify}
                dataTest="verify-button"
            >
                Verify
            </Button>
        </ButtonContainer>
        <FooterText>
            <span data-test="didnt-receive-code-text">Didn't receive the code?</span> {showSent ? (
                    <SentLabel data-test="sent-text">Sent!</SentLabel>
                ) : showCountDown ? (
                    <CountdownTimer data-test="time-to-resend">
                        Resend in{' '}
                        {`0:${
                            remainingTime < 10 ? '0' : ''
                        }${remainingTime}`}
                    </CountdownTimer>
                ) : (
                    <LinkText
                        onClick={onResend}
                        disabled={resendStatus === 'inProgress'}
                        dataTest="resend-button"
                    >
                        Resend
                    </LinkText>
                )
            }
        </FooterText>
    </>
}

export default CheckVerificationCodeView;