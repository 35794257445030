import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Variables } from '../../../theme/variables';

const Root = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    @media (max-width: ${Variables.BreakPoints.responsiveSmPlus}px) {
        flex-direction: column;
    }
`;

export const TableToolsWrapper: FC<{
    className?: string;
}> = memo(({ children, className }) => (
    <Root className={className}>{children}</Root>
));
