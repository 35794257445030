
import { SearchParams } from '../../../../constants/interfaces/filters';
import { UserInterface } from '../../../../constants/interfaces/User';

import {
    fetchAllUsers,
    fetchAllUsersFailure,
    fetchAllUsersSuccess,
    updateAllSelectedUsersState,
    updateInvitationSuccess,
    updateSelectedUserState,
    updateUserActionFailure,
    updateUserActionStart,
    updateUserActionSuccess,
    fetchPortablePractisSetsFailure,
    fetchPortablePractisSetsStart,
    fetchPortablePractisSetsSuccess,
    removeUserLabel,
    removePendingUserLabel,
    clearAllUsers,
} from '../actions';
import { useDispatch } from 'react-redux';
import { useShowMessage } from '../../../../ui/components/ErrorMessages/ErrorMessages';
import { useCallback } from 'react';
import { ErrorResult } from '../../../../constants/interfaces/ErrorResult';
import {
    useDeleteUserApi,
    useGetUserReportApi,
    useSearchUsersApi,
    useAssignTeamsToUserApi,
    useDeleteUserLabelsApi,
    useSearchUserEnrollmentsApi,
} from '../../../../api';
import { setSearchState } from '../../../searchState/store/actions';
import { SEARCH_STATE } from '../../../searchState/constants';
import { SearchUsersParams, ReportUsersParams } from '../../../../api/users/types';
import { usePortableLabelsState } from '../../../portableLabels/store/states';
import { findAllChildIds } from '../../../../helpers/functions/tree-helpers';
import { getSearchSortingValue } from '../../../../helpers/functions/search-params-helpers';
import { UserStatus } from '../costants';
import { NudgeData } from '../../../../api/alert/types';
import { useNudgeUsersApi } from '../../../../api/alert';
import { transformEnrollmentsToPractisSets } from '../helpers';

export const useSearchUsersService = () => {
    const dispatch = useDispatch();
    const searchUsersApi = useSearchUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (
            p: SearchParams,
            userStatuses?: (keyof typeof UserStatus)[],
            companyId?: number,
            shouldSetStore = true
        ) => {
            shouldSetStore && dispatch(fetchAllUsers());
            const params: SearchUsersParams = {
                status: userStatuses?.join(',') || UserStatus.ACTIVE,
                roles: p.roleIDs?.join(','),
                teams: p.teamIds?.join(','),
                labels: p.labelIDs?.join(','),
                companies: companyId ? companyId.toString() : '',
                limit: p.limit ?? 20,
                offset: p.offset ?? 0,
                query: p.searchTerm,
                sort: getSearchSortingValue(p.orderBy, 'name', true),
            };
            return searchUsersApi(params)
                .then(data => {
                    if (shouldSetStore) {
                        dispatch(
                            setSearchState(
                                SEARCH_STATE.ACTIVE_USERS.name,
                                SEARCH_STATE.ACTIVE_USERS.childrenKeys,
                                p
                            )
                        );
                        dispatch(fetchAllUsersSuccess(data));
                    }

                    return data.items;
                })
                .catch((error: ErrorResult) => {
                    if (shouldSetStore) {
                        dispatch(fetchAllUsersFailure(error.message));
                        showMessage(error.message, 'error');
                    }
                });
        },
        [dispatch, searchUsersApi, showMessage]
    );
};

export const useClearAllUsersService = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(clearAllUsers());
    }, [dispatch]);
};

export const useDeleteUserService = () => {
    const dispatch = useDispatch();
    const deleteUserApi = useDeleteUserApi();
    const showMessage = useShowMessage();
    return useCallback(
        (userIds: number[], search?: SearchParams, selectAll?: boolean, successCallback?: () => void) => {
            dispatch(updateUserActionStart());
            return deleteUserApi(userIds)
                .then(data => {
                    dispatch(
                        updateUserActionSuccess(
                            { id: Math.random() } as UserInterface,
                            'delete'
                        )
                    );
                    
                    showMessage(
                        `${
                            userIds.length > 1
                                ? `${selectAll ? search?.limit : userIds.length} Users have been deactivated`
                                : '1 User has been deactivated'
                        }`,
                        'success'
                    );

                    successCallback?.();
                    return data;
                })
                .catch((error: ErrorResult) => {
                    dispatch(updateUserActionFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, deleteUserApi, showMessage]
    );
};

export const usePractisSetsEnrollmentsService = (userId: string) => {
    const dispatch = useDispatch();
    const searchUserPractisSetsApi = useSearchUserEnrollmentsApi();
    const showMessage = useShowMessage();
    return useCallback(
        (successCallback?: () => void) => {
            dispatch(fetchPortablePractisSetsStart());
            searchUserPractisSetsApi(userId)
                .then(data => {
                    const practisSets = transformEnrollmentsToPractisSets(data);
                    dispatch(fetchPortablePractisSetsSuccess(practisSets));
                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    dispatch(fetchPortablePractisSetsFailure(error.message));
                    showMessage(error.message, 'error');
                });
        },
        [dispatch, searchUserPractisSetsApi, showMessage, userId]
    );
};

export const useAssignTeamsToUsersService = () => {
    const dispatch = useDispatch();
    const assignTeamsApi = useAssignTeamsToUserApi();
    const showMessage = useShowMessage();
    return useCallback(
        (
            userId: number,
            teams: number[],
            deleted: number[],
            hideMessage = false,
            successCallback?: () => void
        ) => {
            assignTeamsApi(userId, teams, deleted)
                .then(() => {
                    dispatch(
                        updateUserActionSuccess(
                            { id: userId } as UserInterface,
                            'update'
                        )
                    );

                    if (!hideMessage) {
                        showMessage('Changes have been saved', 'success');
                    }

                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [showMessage, assignTeamsApi, dispatch]
    );
};

export const useNudgeTraineesService = () => {
    const nudgeTraineesApi = useNudgeUsersApi();
    const showMessage = useShowMessage();
    return useCallback(
        (nudgeUserData: NudgeData[], successCallback?: () => void) => {
            nudgeTraineesApi(nudgeUserData)
                .then(() => {
                    const numberOfUsers = nudgeUserData.length;
                    const message =
                        numberOfUsers > 1
                            ? `${numberOfUsers} Messages were sent successfully`
                            : 'Message was sent successfully';

                    showMessage(message, 'success');
                    successCallback?.();
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [showMessage, nudgeTraineesApi]
    );
};

export const useGetUsersReportService = () => {
    const getUserReportApi = useGetUserReportApi();
    const showMessage = useShowMessage();
    return useCallback(
        (p: SearchParams, companyId: number, userIds?: number[], totalCount?: number) => {
            if (!userIds && !totalCount)
                throw new Error('Either select users or total count should be specified for report');
            const params: ReportUsersParams = {
                status: UserStatus.ACTIVE,
                roles: p.roleIDs?.join(','),
                teams: p.teamIds?.join(','),
                labels: p.labelIDs?.join(','),
                companies: companyId.toString(),
                users: userIds?.join(','),
                limit: userIds?.length ?? totalCount!,
                offset: 0,
                query: p.searchTerm,
                sort: getSearchSortingValue(p.orderBy, 'name', true)
            };
            return getUserReportApi(params)
                .then((data: any) => {
                    const url = window.URL.createObjectURL(
                        new Blob([data as any])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `report.csv`); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    return data;
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [getUserReportApi, showMessage]
    );
};

/**
 * @description deletes user label including children labels
 * @function useDeleteUserLabelService
 * @param { number } userId
 * @param { number } labelId
 * @param { Function } successCallback
 * @returns { void }
 */
 export const useDeleteUserLabelService = (isPending: boolean = false) => {
    const deleteUserLabelsApi = useDeleteUserLabelsApi();
    const showMessage = useShowMessage();
    const labels = usePortableLabelsState();
    const dispatch = useDispatch();
    return useCallback(
        (userId: number, labelId: number, successCallback?: () => void) => {
            const payload: { userId: number; labelId: number }[] = [
                { userId, labelId },
            ];
            const children: any[] = [];
            findAllChildIds(labels.data.items, labelId, children);
            if (children.length > 0)
                children.forEach(item =>
                    payload.push({ userId, labelId: item.id })
                );
            return deleteUserLabelsApi(payload)
                .then(() => {
                    if (isPending) {
                        dispatch(removePendingUserLabel(userId, labelId));
                        dispatch(
                            updateInvitationSuccess({ id: labelId }, 'labels')
                        );
                    } else {
                        dispatch(removeUserLabel(userId, labelId));
                        dispatch(
                            updateUserActionSuccess({ id: labelId }, 'labels')
                        );
                    }

                    successCallback?.();
                    showMessage('Label removed successfully', 'success');
                })
                .catch((error: ErrorResult) => {
                    showMessage(error.message, 'error');
                });
        },
        [isPending, labels, showMessage, deleteUserLabelsApi, dispatch]
    );
};

export const useUpdateAllSelectedUsersStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (userIds: number[], checked: boolean, partial?: boolean) => {
            dispatch(updateAllSelectedUsersState(userIds, checked, partial));
        },
        [dispatch]
    );
};

export const useUpdateSelectedUserStateService = () => {
    const dispatch = useDispatch();
    return useCallback(
        (userId: number) => {
            dispatch(updateSelectedUserState(userId));
        },
        [dispatch]
    );
};