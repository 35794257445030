import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { SubmissionReview } from '../../../constants/interfaces/Reviews';
import { Variables } from '../../../theme/variables';
import { SelectBox } from '../../../ui/components/SelectBox/SelectBox';
import TextAreaBox from '../../../ui/components/TextAreaBox/TextAreaBox';
import { Button } from '../../../ui/components/Button';
import { formatDate } from '../../../helpers/functions/date-convert';
import { ButtonArrow } from '../../../ui/components/ButtonArrow';
import { CheckPermission } from '../../permissions';
import { NEW_PERMISSIONS } from '../../../constants/enums/permissions';
import { calculateScoreValue, generateScoreItemArray } from '../tools';

const StyledDetailsSubmissionReview = styled.div`
    margin: 8px 24px;
    flex: 54;
`;

const StyledDetailsBody = styled.div`
    background: ${Variables.Colors.white};
    border-radius: 8px;
    border: solid 1px ${props => props.theme.Colors.paleGrey};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 41px 0 32px 32px;
`;

const Divider = styled.div<{ margin?: number }>`
    height: 1px;
    width: 94%;
    border: 1px solid ${Variables.Colors.paleGrey};
    background: ${Variables.Colors.paleGrey};
    margin-bottom: 9px;
`;

const ScoreRow = styled.div<{ padding?: string; margin?: string }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: ${props => props.padding || '0 40px 0 16px'};
    margin: ${props => props.margin || '0 0 0 0'};
    align-items: center;
`;

const ActionRow = styled.div`
    display: flex;
    width: 100%;
    margin: 24px 0;
    flex-direction: row-reverse;
`;

const Title = styled.div`
    font-size: 17px;
    font-weight: bold;
`;

const ExampleContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    justify-content: flex-end;
    margin-top: 8px;
`;

const Example = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${Variables.Colors.cloudyBlue};
    margin: 0 5px;
`;

const Field = styled.div`
    font-size: 15px;
    color: ${Variables.Colors.dark};
    font-weight: 600;
    min-width: 110px;
`;

const StyledSelectBox = styled(SelectBox)`
    flex: 1;
`;

const Value = styled.div<{ empty?: boolean }>`
    font-size: 40px;
    color: ${props =>
        props.empty
            ? Variables.Colors.cloudyBlue
            : Variables.Colors.darkSkyBlue};
    line-height: 1.06;
    font-weight: bold;
`;

const Description = styled.div<{ marginRight?: string }>`
    color: ${props => props.theme.Colors.steelGrey};
    padding-left: 14px;
    font-size: 15px;
    font-weight: 500;
    margin-right: ${props => props.marginRight || '0px'};
`;

const StyledSelectBoxHeader = styled.div`
    display: flex;
    margin: 0 27px 9px 173px;
`;

const StyledSelectBoxHeaderItem = styled.div<{ widthPercent?: number }>`
    font-size: 15px;
    font-weight: 500;
    width: ${props => props.widthPercent || 23}%;
    text-align: right;
    color: ${props => props.theme.Colors.cloudyBlue};
`;

const StyledDetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 8px;
`;

const StyledDetailsArrowsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledLeftArrowContainer = styled.div`
    margin-right: 8px;
`;

const StyledButtonContainer = styled.div`
    margin-right: 24px;
`;

const StyledRightArrowContainer = styled.div``;

const SCORE_ITEMS = [1, 2, 3, 4, 5];

const DetailsSubmissionReview: FC<{
    mode?: 'edit' | 'view';
    review?: Partial<SubmissionReview>;
    submitted?: string;
    canRedo: boolean;
    modifyScore: (value: string | number, field?: string) => void;
    onCompleteReviewClicked: () => void;
    changeSubmission: (next: boolean) => void;
    itemsTotalCount?: number;
    currentItemPosition: number;
    requestRedoClicked?: () => void;
    reviewPermissionDenied?: boolean;
}> = ({
    mode,
    review,
    submitted,
    canRedo,
    modifyScore,
    onCompleteReviewClicked,
    changeSubmission,
    itemsTotalCount,
    currentItemPosition,
    requestRedoClicked,
    reviewPermissionDenied,
}) => {
    const reviewValue = useMemo(() => {
        if (!review)
            return 0;
        if (review.value)
            return review.value;
        return calculateScoreValue(
            generateScoreItemArray(review, [
                'clarity',
                'knowledge',
                'confidence',
                'enthusiasm',
                'goalAchieved',
            ])
        );
    }, [review]);

    return (
        <StyledDetailsSubmissionReview>
            <StyledDetailsHeader>
                <Description>
                    Submitted {formatDate(submitted, true)}
                </Description>
                {itemsTotalCount && (
                    <StyledDetailsArrowsContainer>
                        <Description marginRight="16px">
                            {currentItemPosition + 1} of {itemsTotalCount}
                        </Description>
                        <StyledLeftArrowContainer>
                            <ButtonArrow
                                left
                                action={() => changeSubmission(false)}
                            />
                        </StyledLeftArrowContainer>
                        <StyledRightArrowContainer>
                            <ButtonArrow action={() => changeSubmission(true)} />
                        </StyledRightArrowContainer>
                    </StyledDetailsArrowsContainer>
                )}
            </StyledDetailsHeader>
            <StyledDetailsBody>
                <ScoreRow>
                    <Title>Challenge Score</Title>
                </ScoreRow>
                <ScoreRow
                    style={{
                        alignItems: 'flex-start',
                        paddingTop: '9px',
                        paddingLeft: '14px',
                    }}
                >
                    <Value empty={!(reviewValue > 0)}>
                        {reviewValue.toFixed(1)}
                    </Value>
                    <ExampleContent>
                        <Example>1 - Poor;</Example>
                        <Example>3 - Average;</Example>
                        <Example>5 - Excellent</Example>
                    </ExampleContent>
                </ScoreRow>
                <StyledSelectBoxHeader>
                    {SCORE_ITEMS.map(item => (
                        <StyledSelectBoxHeaderItem
                            key={item}
                            widthPercent={item === 1 ? 1 : 23}
                        >
                            {item}
                        </StyledSelectBoxHeaderItem>
                    ))}
                </StyledSelectBoxHeader>
                <Divider />
                <ScoreRow>
                    <Field>Clarity</Field>
                    <StyledSelectBox
                        color={Variables.Colors.darkSkyBlue50}
                        items={SCORE_ITEMS}
                        name={'clarity'}
                        isRound={true}
                        spacing={'3px'}
                        value={review?.clarity}
                        handleSelect={modifyScore}
                        readOnly={mode !== 'edit'}
                    />
                </ScoreRow>
                <ScoreRow>
                    <Field>Knowledge</Field>
                    <StyledSelectBox
                        color={Variables.Colors.darkSkyBlue50}
                        items={SCORE_ITEMS}
                        name={'knowledge'}
                        isRound={true}
                        spacing={'3px'}
                        value={review?.knowledge}
                        handleSelect={modifyScore}
                        readOnly={mode !== 'edit'}
                    />
                </ScoreRow>
                <ScoreRow>
                    <Field>Confidence</Field>
                    <StyledSelectBox
                        color={Variables.Colors.darkSkyBlue50}
                        items={SCORE_ITEMS}
                        name={'confidence'}
                        isRound={true}
                        spacing={'3px'}
                        value={review?.confidence}
                        handleSelect={modifyScore}
                        readOnly={mode !== 'edit'}
                    />
                </ScoreRow>
                <ScoreRow>
                    <Field>Enthusiasm</Field>
                    <StyledSelectBox
                        color={Variables.Colors.darkSkyBlue50}
                        items={SCORE_ITEMS}
                        name={'enthusiasm'}
                        isRound={true}
                        spacing={'3px'}
                        value={review?.enthusiasm}
                        handleSelect={modifyScore}
                        readOnly={mode !== 'edit'}
                    />
                </ScoreRow>
                <ScoreRow margin="0 0 14px 0">
                    <Field>Goal Achieved</Field>
                    <StyledSelectBox
                        color={Variables.Colors.darkSkyBlue50}
                        items={SCORE_ITEMS}
                        name={'goalAchieved'}
                        isRound={true}
                        spacing={'3px'}
                        value={review?.goalAchieved}
                        handleSelect={modifyScore}
                        readOnly={mode !== 'edit'}
                    />
                </ScoreRow>
                <TextAreaBox
                    value={review?.note ?? ''}
                    name="notes"
                    placeholder="Write review notes…"
                    minRows={3}
                    onChange={(event: { target: HTMLInputElement }) =>
                        modifyScore(event.target.value, 'note')
                    }
                    background={Variables.Colors.whiteTwo}
                    disabled={mode !== 'edit'}
                    max={500}
                    marginRight="31px"
                    fontSize="15px"
                    counterFontSize="13px"
                />
            </StyledDetailsBody>
            <ActionRow>
                <CheckPermission
                    permissions={[NEW_PERMISSIONS.REVIEW_SUBMISSION]}
                >
                    <Button
                        height="40px"
                        width="176px"
                        disabled={
                            !review ||
                            !(
                                review.knowledge &&
                                review.clarity &&
                                review.confidence &&
                                review.enthusiasm &&
                                review.goalAchieved
                            ) ||
                            mode === 'view' ||
                            reviewPermissionDenied
                        }
                        customDisabledColor={Variables.Colors.cloudyBlue}
                        action={onCompleteReviewClicked}
                    >
                        {mode === 'view'
                            ? 'Review Completed'
                            : 'Complete Review'}
                    </Button>
                </CheckPermission>
                {canRedo && !reviewPermissionDenied && (
                    <CheckPermission
                        permissions={[NEW_PERMISSIONS.REQUEST_SUBMISSION_REDO]}
                    >
                        <StyledButtonContainer>
                            <Button
                                height="40px"
                                width="160px"
                                action={requestRedoClicked}
                                color="warning-inverse"
                                variant="inverse"
                            >
                                Request Redo
                            </Button>
                        </StyledButtonContainer>
                    </CheckPermission>
                )}
            </ActionRow>
        </StyledDetailsSubmissionReview>
    );
};

export default DetailsSubmissionReview;
