import {
    LibraryChallengesState,
    LibraryPractisSetsState,
    LibraryScenariosState,
    UpdatedLibraryChallengesState,
    UpdatedLibraryPractisSetsState,
    UpdatedLibraryScenariosState,
} from './states';
import { compose, Reducer } from 'redux';
import {
    ACTIONS,
    getFullLibraryPractisSetsFailure,
    getFullLibraryPractisSetsStart,
    getFullLibraryPractisSetsSuccess,
    getTotalLibraryChallengesCountFailure,
    getTotalLibraryChallengesCountStart,
    getTotalLibraryChallengesCountSuccess,
    searchLibraryChallengesFailure,
    searchLibraryChallengesStart,
    searchLibraryChallengesSuccess,
    searchLibraryPractisSetsFailure,
    searchLibraryPractisSetsStart,
    searchLibraryPractisSetsSuccess,
    searchLibraryScenariosFailure,
    searchLibraryScenariosStart,
    searchLibraryScenariosSuccess,
    setSearchTermForLibraryPractisSet,
    updateAllChallengesCheckedState,
    updateAllPractisSetsCheckedState,
    updateAllScenarioCheckedState,
    updateAssignedLibraryPractisSet,
    updateChallengeDeleteLabelSuccess,
    updateLibraryChallengeCheckedState,
    updateLibraryChallengeFailure,
    updateLibraryChallengeStart,
    updateLibraryChallengeSuccess,
    updateLibraryPractisSetCheckedState,
    updateLibraryPractisSetFailure,
    updateLibraryPractisSetStart,
    updateLibraryPractisSetSuccess,
    updateLibraryScenarioCheckedState,
    updateLibraryScenarioFailure,
    updateLibraryScenarioStart,
    updateLibraryScenarioSuccess,
    updatePractisSetDeleteLabelSuccess,
    updateScenarioDeleteLabelSuccess,
    updateSeparateAssignedLibraryPractisSet,
} from './actions';
import { Label } from '../../../constants/interfaces/Label';
import { PractisSets } from '../../../constants/interfaces/PractisSets';
import { Scenario } from '../../../constants/interfaces/Scenario';
import { Challenge } from '../../../constants/interfaces/Challenge';
import {
    withLabelsInitialState,
    withLabelsReducer,
} from '../../portableLabels/store/hors/withLabels';

const initialLibraryPractisSetsState: LibraryPractisSetsState = withLabelsInitialState<
    LibraryPractisSetsState
>({}, 'libraryPractisSets');

export type LibraryPractisSetsAction =
    | ReturnType<typeof searchLibraryPractisSetsStart>
    | ReturnType<typeof searchLibraryPractisSetsSuccess>
    | ReturnType<typeof searchLibraryPractisSetsFailure>
    | ReturnType<typeof getFullLibraryPractisSetsStart>
    | ReturnType<typeof getFullLibraryPractisSetsSuccess>
    | ReturnType<typeof getFullLibraryPractisSetsFailure>
    | ReturnType<typeof updateAssignedLibraryPractisSet>
    | ReturnType<typeof updateSeparateAssignedLibraryPractisSet>
    | ReturnType<typeof setSearchTermForLibraryPractisSet>
    | ReturnType<typeof updateAllPractisSetsCheckedState>
    | ReturnType<typeof updateLibraryPractisSetCheckedState>
    | ReturnType<typeof updatePractisSetDeleteLabelSuccess>;

const libraryPractisSetsBaseReducer: Reducer = (
    state = initialLibraryPractisSetsState,
    action: LibraryPractisSetsAction
): LibraryPractisSetsState => {
    switch (action.type) {
        case ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_SUCCESS:
            const prevItems = state.data?.items;
            return {
                ...state,
                data:
                    !prevItems
                        ? action.practisSets
                        : {
                              ...action.practisSets,
                              items: action.practisSets.items,
                                
                          },
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_LIBRARY_PRACTIS_SETS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_SUCCESS:
            return {
                ...state,
                fullList: action.practisSets,
                loading: false,
                error: '',
            };
        case ACTIONS.GET_FULL_LIBRARY_PRACTIS_SETS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.UPDATE_ASSIGNED_LIBRARY_PRACTIS_SET:
            return {
                ...state,
                assignedPractisSets: action.assignedPractisSets,
            };
        case ACTIONS.UPDATE_SEPARATE_ASSIGNED_LIBRARY_PRACTIS_SET:
            return {
                ...state,
                separateAssignedPractisSets: action.assignedPractisSets,
            };
        case ACTIONS.SET_SEARCH_TERM_FOR_LIBRARY_PRACTIS_SET:
            return {
                ...state,
                searchTerm: action.value,
            };
        case ACTIONS.UPDATE_SELECTED_PRACTIS_SET_STATE:
            const selectedIds = [...(state.selectedPractisSets || [])];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                selectedPractisSets: selectedIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_PRACTIS_SETS_STATE:
            return {
                ...state,
                selectedPractisSets:
                    action.checked || action.partial
                        ? action.selectedPractisSetIds
                        : [],
            };
        case ACTIONS.UPDATE_PRACTIS_SET_DELETE_LABEL_SUCCESS:
            return {
                ...state,
                data: {
                    items: state.data.items.map((item: PractisSets) =>
                        item.id === action.id
                            ? {
                                  ...item,
                                  labels:
                                      item.labels &&
                                      item.labels.filter(
                                          (label: Label) =>
                                              label.id !== action.labelId
                                      ),
                              }
                            : item
                    ),
                },
            };
        default:
            return state;
    }
};

export const libraryPractisSetsReducer = compose<
    Reducer<LibraryPractisSetsState, any>
>(withLabelsReducer({ reducerName: 'libraryPractisSets' }))(
    libraryPractisSetsBaseReducer
);

type UpdatedLibraryPractisSetsAction =
    | ReturnType<typeof updateLibraryPractisSetStart>
    | ReturnType<typeof updateLibraryPractisSetSuccess>
    | ReturnType<typeof updateLibraryPractisSetFailure>;

export const updatedLibraryPractisSetsReducer: Reducer<
    UpdatedLibraryPractisSetsState,
    UpdatedLibraryPractisSetsAction
> = (
    state = {},
    action: UpdatedLibraryPractisSetsAction
): UpdatedLibraryPractisSetsState => {
    switch (action.type) {
        case ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_SUCCESS:
            return {
                ...state,
                data: action.updatedPractisSet,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_LIBRARY_PRACTIS_SET_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialLibraryScenariosState: LibraryScenariosState = withLabelsInitialState<
    LibraryScenariosState
>({}, 'libraryScenarios');

type LibraryScenariosAction =
    | ReturnType<typeof searchLibraryScenariosStart>
    | ReturnType<typeof searchLibraryScenariosSuccess>
    | ReturnType<typeof searchLibraryScenariosFailure>
    | ReturnType<typeof updateLibraryScenarioCheckedState>
    | ReturnType<typeof updateAllScenarioCheckedState>
    | ReturnType<typeof updateScenarioDeleteLabelSuccess>;

const libraryScenariosBaseReducer: Reducer = (
    state = initialLibraryScenariosState,
    action: LibraryScenariosAction
): LibraryScenariosState => {
    switch (action.type) {
        case ACTIONS.SEARCH_LIBRARY_SCENARIOS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_LIBRARY_SCENARIOS_SUCCESS:
            return {
                ...state,
                data: action.scenarios,
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_LIBRARY_SCENARIOS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.UPDATE_SELECTED_SCENARIO_STATE:
            const selectedIds = [...(state.selectedScenarios || [])];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                selectedScenarios: selectedIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_SCENARIOS_STATE:
            return {
                ...state,
                selectedScenarios:
                    action.checked || action.partial
                        ? action.selectedScenarioIds
                        : [],
            };
        case ACTIONS.UPDATE_SCENARIOS_DELETE_LABEL_SUCCESS:
            return {
                ...state,
                data: {
                    items: state.data.items.map((item: Scenario) =>
                        item.id === action.id
                            ? {
                                  ...item,
                                  labels:
                                      item.labels &&
                                      item.labels.filter(
                                          (label: Label) =>
                                              label.id !== action.labelId
                                      ),
                              }
                            : item
                    ),
                },
            };
        default:
            return state;
    }
};

export const libraryScenariosReducer = compose<
    Reducer<LibraryScenariosState, any>
>(withLabelsReducer({ reducerName: 'libraryScenarios' }))(
    libraryScenariosBaseReducer
);

type UpdatedLibraryScenariosAction =
    | ReturnType<typeof updateLibraryScenarioStart>
    | ReturnType<typeof updateLibraryScenarioSuccess>
    | ReturnType<typeof updateLibraryScenarioFailure>;

export const updatedLibraryScenariosReducer: Reducer<
    UpdatedLibraryScenariosState,
    UpdatedLibraryScenariosAction
> = (
    state = {},
    action: UpdatedLibraryScenariosAction
): UpdatedLibraryScenariosState => {
    switch (action.type) {
        case ACTIONS.UPDATE_LIBRARY_SCENARIO_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_LIBRARY_SCENARIO_SUCCESS:
            return {
                ...state,
                data: action.updatedScenario,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_LIBRARY_SCENARIO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

const initialLibraryChallengesState: LibraryChallengesState = withLabelsInitialState<
    LibraryChallengesState
>({}, 'libraryChallenges');

type LibraryChallengesAction =
    | ReturnType<typeof searchLibraryChallengesStart>
    | ReturnType<typeof searchLibraryChallengesSuccess>
    | ReturnType<typeof searchLibraryChallengesFailure>
    | ReturnType<typeof getTotalLibraryChallengesCountStart>
    | ReturnType<typeof getTotalLibraryChallengesCountSuccess>
    | ReturnType<typeof getTotalLibraryChallengesCountFailure>
    | ReturnType<typeof updateLibraryChallengeCheckedState>
    | ReturnType<typeof updateAllChallengesCheckedState>
    | ReturnType<typeof updateChallengeDeleteLabelSuccess>;

const libraryChallengesBaseReducer: Reducer = (
    state = initialLibraryChallengesState,
    action: LibraryChallengesAction
): LibraryChallengesState => {
    switch (action.type) {
        case ACTIONS.SEARCH_LIBRARY_CHALLENGES_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_LIBRARY_CHALLENGES_SUCCESS:
            return {
                ...state,
                data: action.challenges,
                loading: false,
                error: '',
            };
        case ACTIONS.SEARCH_LIBRARY_CHALLENGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_START:
            return {
                ...state,
            };
        case ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_SUCCESS:
            return {
                ...state,
                totalCount: action.challenges.totalCount,
            };
        case ACTIONS.GET_TOTAL_LIBRARY_CHALLENGES_COUNT_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ACTIONS.UPDATE_SELECTED_CHALLENGE_STATE:
            const selectedIds = [...(state.selectedChallenges || [])];

            const selectedIdIndex = selectedIds.findIndex(
                (item: number) => item === action.id
            );
            if (selectedIdIndex > -1) {
                selectedIds.splice(selectedIdIndex, 1);
            } else {
                selectedIds.push(action.id);
            }

            return {
                ...state,
                selectedChallenges: selectedIds,
            };
        case ACTIONS.UPDATE_ALL_SELECTED_CHALLENGES_STATE:
            return {
                ...state,
                selectedChallenges:
                    action.checked || action.partial
                        ? action.selectedChallengeIds
                        : [],
            };
        case ACTIONS.UPDATE_CHALLENGES_DELETE_LABEL_SUCCESS:
            return {
                ...state,
                data: {
                    items: state.data.items.map((item: Challenge) =>
                        item.id === action.id
                            ? {
                                  ...item,
                                  labels:
                                      item.labels &&
                                      item.labels.filter(
                                          (label: Label) =>
                                              label.id !== action.labelId
                                      ),
                              }
                            : item
                    ),
                },
            };
        default:
            return state;
    }
};

export const libraryChallengesReducer = compose<
    Reducer<LibraryChallengesState, any>
>(withLabelsReducer({ reducerName: 'libraryChallenges' }))(
    libraryChallengesBaseReducer
);

type UpdatedLibraryChallengesAction =
    | ReturnType<typeof updateLibraryChallengeStart>
    | ReturnType<typeof updateLibraryChallengeSuccess>
    | ReturnType<typeof updateLibraryChallengeFailure>;

export const updatedLibraryChallengesReducer: Reducer<
    UpdatedLibraryChallengesState,
    UpdatedLibraryChallengesAction
> = (
    state = {},
    action: UpdatedLibraryChallengesAction
): UpdatedLibraryChallengesState => {
    switch (action.type) {
        case ACTIONS.UPDATE_LIBRARY_CHALLENGES_START:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.UPDATE_LIBRARY_CHALLENGES_SUCCESS:
            return {
                ...state,
                data: action.updatedChallenge,
                updateType: action.updateType,
                loading: false,
            };
        case ACTIONS.UPDATE_LIBRARY_CHALLENGES_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
