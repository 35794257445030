import { getIn } from 'formik'
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { CustomizedInputProps } from './types';
import CustomizedInputView from './view';

function CustomizedInput({
    form,
    field,
    maxLength,
    autoFocus,
    suffix,
    label,
    disabled,
    transformValue,
    dataTest,
}: CustomizedInputProps) {
    const error = getIn(form.errors, field.name);
    const isTouched = !!getIn(form.touched, field.name);
    const remainingLength = maxLength ? Math.max(maxLength - field.value?.length, 0) : undefined;
    const isMaxLengthExceeded = !!maxLength && maxLength < field.value?.length;
    const showError = (isTouched || isMaxLengthExceeded) && !!error;
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target?.value;
        const value = transformValue ? transformValue(targetValue) : targetValue;
        form.setFieldValue(field.name, value);
    }, [field.name, form, transformValue]);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    return (
        <CustomizedInputView
            remainingLength={remainingLength}
            field={field}
            inputRef={inputRef}
            suffix={suffix}
            label={label}
            isMaxLengthExceeded={isMaxLengthExceeded}
            showError={showError}
            error={error}
            disabled={disabled}
            onChange={handleChange}
            dataTest={dataTest}
        />
    );
}

export default CustomizedInput;