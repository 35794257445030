import { NEW_PERMISSIONS } from '../../constants/enums/permissions';
import { usePermissionsState } from '../../features/permissions/store/state';
import { useHistory } from '../../tools/router';
import ROUTES from '../../routes/routes';
import { useCallback } from 'react';

export function useHandleAccessPage() {
    const permissionList = usePermissionsState();
    const history = useHistory();
    return useCallback(
        (permissions: NEW_PERMISSIONS[]) => {
            if (permissionList.length < 1) return true;
            const hasPermission = permissions.some(r =>
                permissionList.includes(r)
            );
            if (hasPermission) {
                return true;
            } else {
                history.push(ROUTES.PROFILE.replace(':userId', 'me'));
            }
        },
        [permissionList, history]
    );
}
