import { Reducer } from 'redux';
import {
    ACTIONS,
    searchLogsDispose,
    searchLogsFailure,
    searchLogsStart,
    searchLogsSuccess,
} from './actions';
import { LogsState } from './states';

type CompaniesAction =
    | ReturnType<typeof searchLogsStart>
    | ReturnType<typeof searchLogsSuccess>
    | ReturnType<typeof searchLogsFailure>
    | ReturnType<typeof searchLogsDispose>;

export const logsReducer: Reducer<LogsState, CompaniesAction> = (
    state = {},
    action
): LogsState => {
    switch (action.type) {
        case ACTIONS.SEARCH_LOGS_START:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case ACTIONS.SEARCH_LOGS_SUCCESS: {
            const prevItems = state.data && state.data.items;
            return {
                data:
                    action.logs.offset === 0 || !prevItems
                        ? action.logs
                        : {
                              ...action.logs,
                              items: action.logs.items,
                                  
                          },
                loading: false,
                error: '',
            };
        }
        case ACTIONS.SEARCH_LOGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ACTIONS.SEARCH_LOGS_DISPOSE: {
            return {
                data: undefined,
                loading: false,
                error: '',
            };
        }
        default:
            return state;
    }
};
