import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateRoleplayApi } from '../../../api/roleplay';
import ROUTES from '../../../routes/routes';
import { useHistory } from '../../../tools/router';
import { Button } from '../../../ui/components/Button';
import { useShowMessage } from '../../../ui/components/ErrorMessages/ErrorMessages';
import { ModalWithCross } from '../../../ui/components/ModalWithCross';
import { ModalTitle } from '../../../ui/components/ModalWithCross/styles';
import useGoogleAnalyticsWithRoleplay from '../GoogleAnalytics';
import { initialRolePlayData, keysForGA, requiredContextParams, routeComponents, RouteSettings } from './constants';
import { Footer, ModalContent, ModalSidebar, NavigationIcon, NavigationItem, TabContent, Title } from './styles';
import { RolePlayContextParams, RoleplayCreateProps, RoleplayData, RoleplayRouteTypes } from './types';

function RoleplayCreate({
    closeGoingBack,
    closePath
}: RoleplayCreateProps) {
    const [activePage, setActivePage] = useState<RoleplayRouteTypes>('situation');
    const [roleplayData, setRoleplayData] = useState<RoleplayData>(initialRolePlayData);
    const [emptyRequiredProps, setEmptyRequiredProps] = useState<RolePlayContextParams[]>();
    const trackEventWithRoleplay = useGoogleAnalyticsWithRoleplay();
    const infoPanel = localStorage.getItem('infoPanel');

    const history = useHistory();
    const showMessage = useShowMessage();

    const createRoleplay = useCreateRoleplayApi();
    const prevLocation = history.location.state?.prevLocation ?? history.location.state?.background;
    const handleChange = (key: RolePlayContextParams, value: string) => {
        setRoleplayData(roleplayData ? { ...roleplayData, [key]: value } : { [key]: value })
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => trackEventWithRoleplay('context_wizard_screen_open'), []);

    const routes = Object.keys(routeComponents);
    const handleClose = useCallback(() => {
        if (closeGoingBack && prevLocation) {
            history.replace(prevLocation.pathname);
        } else {
            history.replace(closePath);
        }
        trackEventWithRoleplay('context_wizard_screen_close');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeGoingBack, closePath, history, prevLocation]);

    const isValidForm = () => {
        const arr: RolePlayContextParams[] = [];
        requiredContextParams.forEach((item) => {
            if (item === activePage && roleplayData?.[item] === '') {
                arr.push(item)
            }
        });

        if (arr?.length) {
            setEmptyRequiredProps(arr?.length ? arr : undefined);
            return false;
        } else {
            setEmptyRequiredProps(undefined);
        }
        return true;
    }

    const handlePrevNext = (step: number) => {
        if (!isValidForm()) {
            return;
        }

        const index = routes.indexOf(activePage);
        setActivePage(routes[index + step] as RoleplayRouteTypes);
    }

    const saveRolePlay = () => {
        roleplayData && createRoleplay(roleplayData).then((roleplay) => {
            showMessage('Roleplay has been created', 'success');

            trackEventWithRoleplay('context_wizard_screen_create');
            trackEventWithRoleplay('roleplay_screen_open_new');

            roleplayData && Object.entries(roleplayData).forEach(([key, value]) => {
                if (value !== initialRolePlayData[key as keyof typeof initialRolePlayData]) {
                    const keyGA = keysForGA[key as keyof typeof keysForGA];
                    keyGA && trackEventWithRoleplay(`context_wizard_screen_${keyGA}_filled`);
                }
            });

            history.push(`${ROUTES.ROLEPLAYS.SINGLE.replace(
                ':roleplayId',
                roleplay.id.toString()
            )}`)
            infoPanel === null && localStorage.setItem(
                'infoPanel',
                'true'
            );
        }).catch((e) => {
            showMessage(e.message, 'error');
        });
    }

    const Component = useMemo(() => routeComponents[activePage], [activePage]);

    return (<ModalWithCross
        titleContent={
            <ModalTitle>
                <Title data-test="roleplay-create-modal-title">New Roleplay AI</Title>
            </ModalTitle>
        }
        onClose={handleClose}
        width={790}
        dataTest="roleplay-create-modal"
    >
        <ModalContent>
            <ModalSidebar>
                <div>
                    {RouteSettings.map((route) => {
                        const isActive = activePage === route.name;
                        return <NavigationItem
                            data-test={`${route.name}-tab`}
                            onClick={() => {
                                if (!isValidForm()) {
                                    return;
                                }
                                setActivePage(route.name);
                            }}
                            isActive={isActive}
                            key={route.name}
                        >
                            <NavigationIcon><route.iconActive className="act-icon" /><route.icon className="icon" /></NavigationIcon>
                            {route.text}
                        </NavigationItem>
                    })}
                </div>
            </ModalSidebar>
            <TabContent>
                <Component handleChange={handleChange} roleplayData={roleplayData} autoFocus={true} isEditMode={true} emptyRequiredProps={emptyRequiredProps} analyticsKey={'context_wizard_screen'} />
                <Footer>
                    {routes.indexOf(activePage) !== 0 && <Button
                        width="130px"
                        height="40px"
                        loadingColor="white"
                        loadingSize={24}
                        dataTest="previous-button"
                        variant='inverse'
                        style={{ marginRight: '10px' }}
                        action={() => handlePrevNext(-1)}
                    >
                        Previous
                    </Button>}
                    {routes.indexOf(activePage) < routes.length - 1 && <Button
                        width="130px"
                        height="40px"
                        loadingColor="white"
                        loadingSize={24}
                        dataTest="next-button"
                        action={() => handlePrevNext(1)}
                    >
                        Next
                    </Button>}
                    {routes.indexOf(activePage) === routes.length - 1 && <Button
                        width="130px"
                        height="40px"
                        loadingColor="white"
                        loadingSize={24}
                        dataTest="create-button"
                        action={saveRolePlay}
                    >
                        Create
                    </Button>}
                </Footer>
            </TabContent>
        </ModalContent>
    </ModalWithCross>)
}

export default RoleplayCreate;