import { combineReducers } from 'redux';
import userProfileReducer, {
    UserProfileState,
} from '../../pages/UserProfile/store/reducers';
import companyProfileReducer, {
    CompanyProfileInterface,
    companyVoiceSettingsReducer,
    companyAdminsReducer,
    companyUserStatsReducer,
} from '../../pages/CompanySettings/store/reducers';
import {
    allUsersReducer,
    AllUsersInterface,
    userReducer,
    invitationsReducer,
    updateInvitationsReducer,
    userPerformanceReducer,
    updatedUserReducer,
    updatedUserPractisSetsReducer,
    draftsReducer,
    updatedDraftsReducer,
    userFeaturesReducer,
    UserState,
    userPreferencesReducer,
} from '../../features/users/store/reducers';
import { USER_LOGOUT } from '../../pages/Auth/store/actions';
import {
    AllCompaniesInterface,
    allCompaniesReducer,
} from '../../features/companies/store/reducers';
import {
    ScenarioInterface,
    scenarioReducer,
} from '../../pages/ActionPages/NewScenario/store/reducers';
import globalReducers, { GlobalReducerInterface } from './globals';
import {
    PractisSetInterface,
    practisSetReducer,
} from '../../pages/ActionPages/PractisSets/store/reducers';
import {
    AssignUsersState,
    PractisSetsTrainingState,
    ProgressState,
    UpdateMembersState,
} from '../../features/teams/store/states';
import {
    assignUsersReducer,
    progressReducer,
    trainerPractisSetsReducer,
    updatedMemberReducer,
} from '../../features/teams/store/reducers';
import { PacingsState } from '../../features/pacings/store/states';
import { pacingsReducer } from '../../features/pacings/store/reducers';
import { VoiceGenerationState } from '../../features/textToSpeech/store/states';
import { voiceGenerationReducer } from '../../features/textToSpeech/store/reducers';
import {
    DraftsState,
    InvitationsState,
    UpdatedDraftsState,
    UpdatedUserPractisSetsState,
    UpdateInvitationState,
    UpdateUsersState,
    UserPerformanceState,
    UserFeaturesState,
    UserPreferencesState,
} from '../../features/users/store/states';
import {
    AudioInterface,
    audioReducer,
} from '../../features/player/store/reducers';
import { ChallengeState } from '../../features/challenges/store/states';
import { challengeReducer } from '../../features/challenges/store/reducers';
import {
    AccuracyScoreState,
    SubmissionDetailsState,
    SubmissionsState,
    SubmissionPaginationState,
    UpdatedSubmissionsState,
} from '../../features/submissions/store/states';
import {
    accuracyScoreReducer,
    submissionPaginationReducer,
    submissionDetailsReducer,
    submissionsReducer,
    updatedSubmissionsReducer,
} from '../../features/submissions/store/reducers';
import {
    CompanyVoiceSettingsState,
    CompanyAdminsState,
    CompanyUserStatsState,
} from '../../pages/CompanySettings/store/states';
import {
    LabelsConfigState,
    LabelsState,
    UpdatedLabelsState,
} from '../../features/labels/store/states';
import {
    labelsConfigReducer,
    labelsReducer,
    updatedLabelsReducer,
} from '../../features/labels/store/reducers';
import { logsReducer } from '../../features/logs/store/reducers';
import { LogsState } from '../../features/logs/store/states';
import { AuthInterface, authReducer } from '../../pages/Auth/store/reducers';
import { TelepromterState } from '../../features/telepromter/store/state';
import { telepromterReducer } from '../../features/telepromter/store/reducers';
import { aiLogsReducer } from '../../features/assessment-logs/store/reducers';
import { AiLogsState } from '../../features/assessment-logs/store/states';
import { DraftEditState } from '../../pages/ActionPages/NewUser/store/states';
import { draftEditReducer } from '../../pages/ActionPages/NewUser/store/reducers';
import {
    allTeamsReducer,
    teamEditReducer,
    teamMembersReducer,
    updatedTeamReducer,
} from '../../features/teams/store/reducers';
import {
    TeamMembersState,
    TeamEditState,
    TeamsState,
    UpdateTeamsState,
} from '../../features/teams/store/states';
import { portableLabelsReducer } from '../../features/portableLabels/store/reducer';
import { PortableLabelsState } from '../../features/portableLabels/store/states';
import { portableTeamsReducer } from '../../features/portableTeams/store/reducer';
import { PortableTeamsState } from '../../features/portableTeams/store/states';
import { UserRolesState } from '../../features/roles/store/states';
import { PortableScenariosState } from '../../features/portableScenarios/store/states';
import { SocketProgressReducer } from '../../features/socketProgress(api1)/store/reducer';
import { SocketProgressStateInterface } from '../../features/socketProgress(api1)/store/states';
import { userRolesReducer } from '../../features/roles/store/reducer';
import { PortablePractisSetsState } from '../../features/portablePractisSets/store/states';
import { portablePractisSetsReducer } from '../../features/portablePractisSets/store/reducer';
import {
    LibraryChallengesState,
    LibraryPractisSetsState,
    LibraryScenariosState,
    UpdatedLibraryChallengesState,
    UpdatedLibraryPractisSetsState,
    UpdatedLibraryScenariosState,
} from '../../features/library/store/states';
import {
    libraryChallengesReducer,
    libraryPractisSetsReducer,
    libraryScenariosReducer,
    updatedLibraryChallengesReducer,
    updatedLibraryPractisSetsReducer,
    updatedLibraryScenariosReducer,
} from '../../features/library/store/reducers';
import { InvitersState } from '../../features/inviters/store/states';
import { invitersReducer } from '../../features/inviters/store/reducers';
import { portableScenariosReducer } from '../../features/portableScenarios/store/reducer';
import { SearchStateReducer } from '../../features/searchState/store/reducer';
import { SearchStateInterface } from '../../features/searchState/store/states';
import { SecondarySearchStateInterface } from '../../features/searchState/store/states';
import { secondarySearchStateReducer } from '../../features/searchState/store/reducer';
import { ErrorMessagesInterface } from '../../ui/components/ErrorMessages/ErrorMessages';
import messageReducer from '../../ui/components/ErrorMessages/store/reducers';
import { ModalDialogInterface } from '../../ui/components/ModalDialogs/store/types';
import modalDialogsReducer from '../../ui/components/ModalDialogs/store/reducers';
import chunkServiceReducer from '../../services/ChunkRequestService/store/reducers';
import { ChunkServiceInterface } from '../../services/ChunkRequestService/store/types';
import { reportsReducer } from '../../pages/Reports/store/reducers';
import { ReportsState } from '../../pages/Reports/store/states';
import { SingleTeamFilterState } from '../../features/filters/dynamic-filter/single-team-filter/store/types';
import { singleTeamFilterReducer } from '../../features/filters/dynamic-filter/single-team-filter/store/reducers';
import { PractisSetFilterState } from '../../features/filters/dynamic-filter/practis-set-filter/store/types';
import { practisSetFilterReducer } from '../../features/filters/dynamic-filter/practis-set-filter/store/reducers';
import { overdueLearnersReducer } from '../../features/teams/pages/OverdueLearners/store/reducers';
import { MultipleTeamsFilterState } from '../../features/filters/dynamic-filter/multiple-teams-filter/store/types';
import { multipleTeamsFilterReducer } from '../../features/filters/dynamic-filter/multiple-teams-filter/store/reducers';
import { labelsFilterReducer } from '../../features/filters/dynamic-filter/labels-filter/store/reducers';
import { LabelsFilterState } from '../../features/filters/dynamic-filter/labels-filter/store/types';
import { OverdueLearnersState } from '../../features/teams/pages/OverdueLearners/store/states';
import { SearchableFilterHeaderState } from '../../features/filters/searchable-filter-header/store/types';
import { filterHeaderReducer } from '../../features/filters/searchable-filter-header/store/reducers';
import { MonthFilterState } from '../../features/filters/dynamic-filter/month-filter/store/types';
import { monthFilterReducer } from '../../features/filters/dynamic-filter/month-filter/store/reducers';
import { ScenarioRepsState } from '../../features/teams/pages/Training/ScenarioReps/store/states';
import { scenarioRepsReducer } from '../../features/teams/pages/Training/ScenarioReps/store/reducers';
import { DateRangeFilterState } from '../../features/filters/dynamic-filter/date-filter/store/types';
import { dateRangeFilterReducer } from '../../features/filters/dynamic-filter/date-filter/store/reducers';
import { RoleplaysState } from '../../pages/Roleplays/RoleplaysList/store/states';
import { roleplaysReducer } from '../../pages/Roleplays/RoleplaysList/store/reducers';
import { RoleplayState } from '../../pages/Roleplays/RoleplayPage/store/states';
import { roleplayReducer } from '../../pages/Roleplays/RoleplayPage/store/reducers';

export interface AppState {
    profile: UserProfileState;
    companies: AllCompaniesInterface;
    company: CompanyProfileInterface;
    chunkService: ChunkServiceInterface;
    users: AllUsersInterface;
    auth: AuthInterface;
    updatedUsers: UpdateUsersState;
    updatedMembers: UpdateMembersState;
    updatedTeams: UpdateTeamsState;
    invitations: InvitationsState;
    performance: UserPerformanceState;
    updatedUserPractisSets: UpdatedUserPractisSetsState;
    updatedInvitations: UpdateInvitationState;
    user: UserState;
    scenario: ScenarioInterface;
    challenge: ChallengeState;
    practisSet: PractisSetInterface;
    player: AudioInterface;
    messages: ErrorMessagesInterface;
    modalDialogs: ModalDialogInterface;
    libraryPractisSets: LibraryPractisSetsState;
    libraryUpdatedPractisSets: UpdatedLibraryPractisSetsState;
    libraryScenarios: LibraryScenariosState;
    libraryUpdatedScenarios: UpdatedLibraryScenariosState;
    libraryChallenges: LibraryChallengesState;
    libraryUpdatedChallenges: UpdatedLibraryChallengesState;
    global: GlobalReducerInterface;
    progress: ProgressState;
    assignUsers: AssignUsersState;
    inviters: InvitersState;
    pacings: PacingsState;
    submissions: SubmissionsState;
    submissionDetails: SubmissionDetailsState;
    submissionPagination: SubmissionPaginationState;
    accuracyscore: AccuracyScoreState;
    updatedSubmissions: UpdatedSubmissionsState;
    voiceGeneration: VoiceGenerationState;
    companyVoiceSettings: CompanyVoiceSettingsState;
    labels: LabelsState;
    labelsConfig: LabelsConfigState;
    portableLabels: PortableLabelsState;
    portableTeams: PortableTeamsState;
    portablePractisSets: PortablePractisSetsState;
    updatedLabels: UpdatedLabelsState;
    logs: LogsState;
    telepromter: TelepromterState;
    aiLogs: AiLogsState;
    drafts: DraftsState;
    draftEdit: DraftEditState;
    updatedDrafts: UpdatedDraftsState;
    teams: TeamsState;
    teamMembers: TeamMembersState;
    teamEdit: TeamEditState;
    practisSetsTraining: PractisSetsTrainingState;
    roles: UserRolesState;
    portableScenarios: PortableScenariosState;
    socketProgress: SocketProgressStateInterface;
    searchState: SearchStateInterface;
    secondarySearchState: SecondarySearchStateInterface;
    userFeatures: UserFeaturesState;
    companyAdmins: CompanyAdminsState;
    companyUserStats: CompanyUserStatsState;
    userPreferences: UserPreferencesState;
    reports: ReportsState;
    roleplays: RoleplaysState;
    roleplay: RoleplayState;
    singleTeamFilterReducer: SingleTeamFilterState;
    practisSetFilterReducer: PractisSetFilterState;
    multipleTeamsFilter: MultipleTeamsFilterState;
    labelsFilter: LabelsFilterState;
    overdueLearners: OverdueLearnersState;
    filterHeader: SearchableFilterHeaderState;
    monthFilter: MonthFilterState;
    scenarioReps: ScenarioRepsState;
    dateRange: DateRangeFilterState;
}

const appReducer = combineReducers<AppState>({
    profile: userProfileReducer,
    companies: allCompaniesReducer,
    company: companyProfileReducer,
    users: allUsersReducer,
    auth: authReducer,
    updatedUsers: updatedUserReducer,
    updatedMembers: updatedMemberReducer,
    updatedTeams: updatedTeamReducer,
    user: userReducer,
    invitations: invitationsReducer,
    performance: userPerformanceReducer,
    updatedInvitations: updateInvitationsReducer,
    updatedUserPractisSets: updatedUserPractisSetsReducer,
    scenario: scenarioReducer,
    challenge: challengeReducer,
    practisSet: practisSetReducer,
    player: audioReducer,
    messages: messageReducer,
    modalDialogs: modalDialogsReducer,
    chunkService: chunkServiceReducer,
    libraryPractisSets: libraryPractisSetsReducer,
    libraryUpdatedPractisSets: updatedLibraryPractisSetsReducer,
    libraryScenarios: libraryScenariosReducer,
    libraryUpdatedScenarios: updatedLibraryScenariosReducer,
    libraryChallenges: libraryChallengesReducer,
    libraryUpdatedChallenges: updatedLibraryChallengesReducer,
    global: globalReducers,
    progress: progressReducer,
    assignUsers: assignUsersReducer,
    inviters: invitersReducer,
    pacings: pacingsReducer,
    submissions: submissionsReducer,
    submissionDetails: submissionDetailsReducer,
    submissionPagination: submissionPaginationReducer,
    accuracyscore: accuracyScoreReducer,
    updatedSubmissions: updatedSubmissionsReducer,
    voiceGeneration: voiceGenerationReducer,
    companyVoiceSettings: companyVoiceSettingsReducer,
    labels: labelsReducer,
    labelsConfig: labelsConfigReducer,
    portableLabels: portableLabelsReducer,
    portableTeams: portableTeamsReducer,
    portablePractisSets: portablePractisSetsReducer,
    updatedLabels: updatedLabelsReducer,
    logs: logsReducer,
    telepromter: telepromterReducer,
    drafts: draftsReducer,
    draftEdit: draftEditReducer,
    updatedDrafts: updatedDraftsReducer,
    aiLogs: aiLogsReducer,
    teams: allTeamsReducer,
    teamMembers: teamMembersReducer,
    teamEdit: teamEditReducer,
    practisSetsTraining: trainerPractisSetsReducer,
    roles: userRolesReducer,
    portableScenarios: portableScenariosReducer,
    socketProgress: SocketProgressReducer,
    searchState: SearchStateReducer,
    secondarySearchState: secondarySearchStateReducer,
    userFeatures: userFeaturesReducer,
    companyAdmins: companyAdminsReducer,
    companyUserStats: companyUserStatsReducer,
    userPreferences: userPreferencesReducer,
    reports: reportsReducer,
    roleplays: roleplaysReducer,
    roleplay: roleplayReducer,
    singleTeamFilterReducer: singleTeamFilterReducer,
    practisSetFilterReducer: practisSetFilterReducer,
    multipleTeamsFilter: multipleTeamsFilterReducer,
    labelsFilter: labelsFilterReducer,
    overdueLearners: overdueLearnersReducer,
    filterHeader: filterHeaderReducer,
    monthFilter: monthFilterReducer,
    scenarioReps: scenarioRepsReducer,
    dateRange: dateRangeFilterReducer
});

const rootReducer = (state: AppState | undefined, action: any) => {
    if (action.type === USER_LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;