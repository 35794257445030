import logoPractis from '../../../../assets/images/practis-icon.svg';
import { CompanyInterface } from '../../../../constants/interfaces/Company';

export interface CompanyItem {
    id?: number;
    name?: string;
    logo?: string;
    logoUrl?: string;
    subTitle?: string;
    practis?: boolean;
}

export const practisCompanyItem: CompanyItem = {
    name: 'Practis',
    logo: logoPractis,
    subTitle: '(Admin)',
    practis: true,
};

export interface CompaniesDropdownButtonProps {
    company: CompanyItem;
    updatedCompany: CompanyInterface;
    onChangeCompany(company: CompanyItem): void;
}
