interface ErrorResultDetails {
    message: string;
    field: string;
}

interface ErrorResultValidation {
    [key: string]: string;
}

export interface ErrorResult {
    code: number;
    errorCode?: number;
    message: string;
    status?: string;
    details?: ErrorResultDetails[];
    validation?: ErrorResultValidation;
}

export function parseValidation(
    details?: ErrorResultDetails[]
): ErrorResultValidation | undefined {
    if (details && details.reduce) {
        return details.reduce<ErrorResultValidation>(
            (result, detail) =>
                detail.field && detail.message
                    ? { ...result, [detail.field]: detail.message }
                    : result,
            {}
        );
    }
    return;
}
