import { ScriptLine, ScriptLineSpeaker } from '../../../constants/interfaces/ScriptLine';
import { Scenario } from '../../../constants/interfaces/Scenario';

export const validationService = (scenario: Scenario, status: string) => {
    const output = {
        valid: true,
        message: '',
        name: '',
    };

    switch (status) {
        case 'DELETED':
            output.valid = true;
            break;
        case 'ACTIVE':
            if (!scenario.title?.trim()) {
                output.valid = false;
                output.message = 'Title required';
                output.name = 'name';
            } else if (!scenario.script.lines.length) {
                output.valid = false;
                output.message = 'Scenario should have at least one line';
            } else if (!scenario.script.lines.find(line => line.speaker === ScriptLineSpeaker.REP)) {
                output.valid = false;
                output.message = 'Rep line required!';
            } else if (
                !scenario.script.totalDuration ||
                scenario.script.totalDuration < 0.1
            ) {
                output.valid = false;
                output.message = 'Audio records required';
            }
            scenario.script.lines.map((line: ScriptLine) => {
                if (!line.audioUrl) {
                    output.valid = false;
                    output.message = 'Audio records required';
                } else if (line.audioUrl && line.text.length === 0) {
                    output.valid = false;
                    output.message = 'Text is required';
                } 
                return output;
            });
            break;
        case 'DRAFT':
            if (!scenario.title?.trim()) {
                output.valid = false;
                output.message = 'Title required';
                output.name = 'name';
            } else if (scenario.script.lines.some(line => !line.audioId)) {
                output.valid = false;
                output.message = 'Audio records required';
            }
            break;
        case 'ARCHIVED':
        default:
            if (scenario.title.length < 1) {
                output.valid = false;
                output.message = 'Title required';
            }
    }
    return output;
};
